import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Fade } from '@mui/material';
import useWindowDimensions from '../../../../hooks/widthHook';
import {
  getPostInterests, setChoosenTags,
} from '../../../../redux/slices/postsSlice';
import { AppDispatch } from '../../../../redux/store';
import { HtmlTooltip } from '../../SetReactions/SetReactions';
import './InterestArea.scss';

interface InterestAreaProps {
  post: Post | BookmarkedPost;
  insideModal?: boolean;
  isReport?: boolean;
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
  marginTop?: number;
}

function InterestArea({ post, insideModal, isReport, placement, marginTop }: InterestAreaProps) {
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch<AppDispatch>();
  const lan = localStorage.getItem('lang') || 'en';

  useEffect(() => {
    if (post.counter_interests !== 0 && !post.interests) {
      dispatch(getPostInterests(post.id));
    }
  }, [dispatch, post.counter_interests, post.id, post.interests]);

  return (
    <HtmlTooltip
      placement={placement}
      TransitionComponent={Fade}
      title={
        <ul>
          {post.interests
            ?.filter((interes: Chip) => !interes.isArchived)
            ?.sort((a: Chip, b: Chip) => a.title.localeCompare(b.title))
            ?.map((interes: Chip) => (
              <li className="post__tooltip-row" key={interes.id}>
                <img
                  src={interes?.avatar || interes?.url}
                  alt="interest"
                  style={{
                    marginRight: '6px',
                    width: '20px',
                    height: '20px',
                  }}
                />
                <p>{interes?.title}</p>
              </li>
            ))}
        </ul>
      }
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, marginTop],
            },
          },
        ],
      }}
      enterDelay={400}
      TransitionProps={{ timeout: 600 }}
      disableHoverListener={post.interests
        ?.filter((interes: Chip) => !interes.isArchived)?.length ?
        width < 1100 : true}
    >
      <div style={{
        width: (width <= 1440 && lan === 'ar') ? '100%' : 'fit-content',
        display: 'flex',
        flexDirection: (width <= 1440 && lan === 'ar') ? 'row-reverse' : 'row'
      }}>
        <button
          type="button"
          className="InterestArea"
          onClick={() => {
            post.counter_interests !== 0 &&
              dispatch(getPostInterests(post.id));

            return width < 1100 ?
              dispatch(setChoosenTags(true)) :
              null;
          }}
          disabled={!post.counter_interests}
          style={{
            marginLeft: width <= 1440 || insideModal || isReport ? '0' : 'auto',
            marginTop: width <= 1440 || insideModal || isReport ? '-12px' : '0',
            marginBottom: width <= 1440 || insideModal || isReport ? '5px' : '0',
            paddingRight: width > 1440 ? (lan === 'ar' ? '0' : '16px') : '0',
            paddingLeft: width > 1440 ? (lan === 'ar' ? '16px' : '0') : '0',
            fontSize: width <= 1100 ? '13px' : '14px',
          }}
        >
          <span className="InterestArea__count">
            {post.counter_interests}
          </span>
          <span className="InterestArea__text">
            {formatMessage({ id: 'tag-sChosen' })}
          </span>
        </button>
      </div>
    </HtmlTooltip>
  );
}

export default InterestArea;
