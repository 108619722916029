import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './ModalWrapper.scss';

const modalRoot = document.getElementById('modal-root')!;

interface ModalWrapperProps {
  children: ReactNode;
  width?: string | number;
  higher?: boolean;
  higherZIndex?: boolean;
  isMobile?: boolean;
  positionUnset?: boolean
}


function ModalWrapper({ children, width, higher, higherZIndex, isMobile, positionUnset }: ModalWrapperProps) {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible'
      document.documentElement.style.overflow = 'visible';
    };
  }, [])

  return (
    createPortal(
      <div className={`Backdrop${higherZIndex ?
        ' Backdrop--higherZIndex' : ''}${isMobile ?
          ' Backdrop--mobile' : ''}`}>
        <div
          className={`ModalWrapper${higher ?
            ' ModalWrapper--higher' : ''}${isMobile ?
              ' ModalWrapper--mobile' : ''}${positionUnset ? ' ModalWrapper--unset' : ''}`}
          style={{ width: width }}
        >
          <div className={`ModalInner${isMobile ? ' ModalInner--mobile' : ''}`}>
            {children}
          </div>
        </div>
      </div>,
      modalRoot
    )
  );
}

export default ModalWrapper;
