import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../../../redux/store';
import {
  getUser,
  setResponseMessage,
} from '../../../../redux/slices/authSlice';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import ShowPassword from '../../../../components/shared/CustomInput/ShowPassword/ShowPassword';
// import { setIsAuth } from '../../../../redux/slices/authSlice';
import './SignInForm.scss';

export const SignInForm: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, touchedFields },
  } = useForm({ mode: 'all' });

  const { responseMessage } = useSelector((state: RootState) => state.auth);
  const lan = localStorage.getItem('lang') || 'en';

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  // show password
  const [isVisiblePassword, setVisibilityPassword] = useState(false);

  const dataToGetUser = {
    email: loginEmail,
    password: loginPassword,
  };

  const onHandleSubmit = () => {
    dispatch(getUser(dataToGetUser));
  };

  useEffect(() => {
    if (responseMessage) {
      toast.error(responseMessage);
    }
    dispatch(setResponseMessage(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseMessage]);

  return (
    <form className="authForm" onSubmit={handleSubmit(onHandleSubmit)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="createAccountWrapper">
          <span className="createAccount">
            {formatMessage({ id: 'loginTo' })}{' '}
          </span>{' '}
          <span className="wizwork">
            <b>WizWork</b>
          </span>
        </div>
        <div className="signUpLinkWrapper">
          <span>{formatMessage({ id: 'dontHaveAnAccountYet' })}</span>{' '}
          <b className="signUpLink">
            <Link style={{ color: 'inherit' }} to="/sign-up">
              {formatMessage({ id: 'createAccount' })}
            </Link>
          </b>
        </div>
        <div style={{ marginTop: 16 }}>
          <CustomInput
            placeholder={formatMessage({ id: 'emailPlaceholder' })}
            label={formatMessage({ id: 'emailLabel' })}
            inputName="email"
            errors={errors}
            touchedFields={touchedFields}
            onChangeFunc={(value: any) => {
              setLoginEmail(value);
              setValue('email', value);
            }}
            width="100%"
            inputProps={{
              ...register('email', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: formatMessage({ id: 'incorrectEmail' }),
                },
              }),
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left',
              },
            }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <CustomInput
            placeholder={formatMessage({ id: 'passwordPlaceholder' })}
            label={formatMessage({ id: 'passwordLabel' })}
            inputName="password"
            errors={errors}
            touchedFields={touchedFields}
            width="100%"
            onChangeFunc={(value: any) => {
              setLoginPassword(value);
              setValue('password', value);
            }}
            inputProps={{
              ...register('password', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                // pattern: {
                //   value:
                //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*():;,\-_=+`~\[\]{}?|]).{8,20}.[^<>]+$/,
                //   message: formatMessage({ id: 'incorrectPassword' }),
                // },
              }),
              type: isVisiblePassword ? 'text' : 'password',
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left',
              },
            }}
            InputProps={{
              endAdornment: (
                <ShowPassword
                  onClick={() => {
                    setVisibilityPassword(!isVisiblePassword);
                  }}
                  isVisible={isVisiblePassword}
                />
              ),
            }}
          />
        </div>
        <div className="forgotPasswordLink">
          <b style={{ color: 'inherit' }}>
            <Link style={{ color: 'inherit' }} to="/forgot-password">
              {formatMessage({ id: 'forgotPassword' })}
            </Link>
          </b>
        </div>
        <MainButton
          text={formatMessage({ id: 'login' })}
          type="submit"
          disabled={!isValid}
        />
      </div>
    </form>
  );
};

export default SignInForm;
