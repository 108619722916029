import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import './TabPanel.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  type?: string;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, type, ...other } = props;
  const { activity } = useSelector((state: RootState) => state.posts);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={`${(type === 'notify' && activity .length !== 0) ? 'notification' : ''}`}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};