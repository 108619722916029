import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { RootState } from '../../../redux/store';
import { deleteUserAttachment, getUserPhotos } from '../../../api/userMedia';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ModalWrapper from '../../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../../ModalConstructor/ModalFooter/ModalFooter';
import SecondaryButton from '../../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../../shared/Buttons/MainButton/MainButton';
import MediaViewModal from '../../MediaViewModal/MediaViewModal';
import ShowMoreAds from '../../ShowMoreAds/ShowMoreAds';
import './Photos.scss';

export const Photos = () => {
  const { formatMessage } = useIntl();
  const { id } = useSelector((state: RootState) => state.profile);
  const [renderedPhotos, setRenderedPhotos] = useState<MediaItem[] | []>([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [indexToDelete, setIndexToDelete] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPhotos, setTotalPhotos] = useState(0);

  const { someUserId } = useParams();
  const someUserInfo = useSelector((state: RootState) => state.someUser.someUser);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [renderedPhotos]);

  useEffect(() => {
    (async () => {
      // const photos = await getUserPhotos(id, currentPage);
      const photos = await getUserPhotos((someUserId && someUserInfo ? someUserId : id), currentPage);
      setTotalPhotos(photos.data.meta.totalItems)
      if (photos.data.items.length) {
        setRenderedPhotos((prev: MediaItem[]) => prev.concat(...photos.data.items))
      }
    })()
  }, [currentPage])

  const handleScroll = () => {
    const bottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;

    if (bottom && renderedPhotos.length < totalPhotos) {
      setCurrentPage(prev => prev + 1)
    }
  };

  return (
    <div className='photo-tab'>
      <ShowMoreAds type={'profile'} />
      <h2 className="photo-tab__title">
        {formatMessage({ id: 'photos' })}
      </h2>

      {renderedPhotos.length ? (
        <>
          <div className='photo-tab__grid'>
            {renderedPhotos.map((item, index) => (
              <div
                className='photo-tab__grid-item'
                key={index}
                onClick={() => { setPhotoIndex(index); setViewModal(true) }}
              >

                {someUserId === id && (
                  <div
                    className='photo-tab__grid-item-btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModal(true);
                      setIndexToDelete(index)
                    }}
                  >
                    <DeleteTwoToneIcon />
                  </div>
                )}

                <img className='photo-tab__grid-item-img' src={item.url} alt='user-photos' />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className='photo-tab__block'>{formatMessage({ id: 'noPhoto' })}</div>
      )}


      {deleteModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              smallCross
              textHeader={formatMessage({ id: 'deletePhotoConfirm' })}
              onClose={() => setDeleteModal(false)}
            />
            <ModalFooter>
              <SecondaryButton
                text={formatMessage({ id: 'delete' })}
                onClick={() => {
                  setDeleteModal(false);
                  setRenderedPhotos(prev => {
                    const newArr = [...prev];
                    newArr.splice(indexToDelete, 1)
                    return newArr;
                  });
                  deleteUserAttachment(renderedPhotos[indexToDelete].id);
                }}
                bigPadding
              />
              <MainButton
                text={formatMessage({ id: 'cancel' })}
                onClick={() => {
                  setDeleteModal(false);
                  setIndexToDelete(0)
                }}
                bigPadding
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}

      {viewModal && (
        <MediaViewModal
          mediaArr={renderedPhotos}
          setMediaArr={setRenderedPhotos}
          mediaIndex={photoIndex}
          setMediaIndex={setPhotoIndex}
          onClose={() => { setViewModal(false) }}
        />
      )}
    </div>
  )
}
