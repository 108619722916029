import { processTextInLink } from './linksProcessing'

export const parseLineBreak = (text: string | null) => {

  const arr = text?.replaceAll(',', '/').split(/\r?\n/) as string[];

  return arr;
};

export const parsePrivacyText = (array: string[] | null) => {

  const newArr = array?.map((string: string) => {
    let str = string.split(' ').map((word: string) => {
      if (word.includes('https://') || word.includes('http://') || word.includes('www.')) {
        return word;
      } else {
        return word.replaceAll('/', ',');
      }
    }).join(' ');

    if (str === '') {
      return (<div><br /></div>)
    } else {
      if (str === str.toUpperCase()) {
        return <p><b>{str}</b></p>;
      } else {
        return processTextInLink(str);
      }
    }
  })

  return <div>{newArr}</div>
}

export const addBrTagInLine = (str: string) => {
  const arr = str?.split('\n')
  const result = arr?.map(item => `${item} <br/> `).join('')

  return result
}