import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './TippyComponent.scss';

type Props = {
  object: JSX.Element;
  text?: JSX.Element;
  title?: string;
  thema?: string;
  placement: "top" | "bottom" | "right" | "left";
  maxWidth: number;

}

const TippyComponent: React.FC<Props> = ({
  object,
  text,
  title,
  thema = "middle-gray",
  placement,
  maxWidth
}) => {
  return (
    <Tippy
      className='tippy'
      theme="middle-gray"
      content={
        <div className="TippyComponent TippyComponent__content">
          <div className="TippyComponent__title">
            { title }
          </div>
          <div className="TippyComponent__main">
            { text }
          </div>
          <div className="TippyComponent__note">
          </div>
        </div>
      }
      placement={placement}
      duration={300}
      maxWidth={`${maxWidth}px`}
      interactive={true}
    >
      {object}
    </Tippy>
  );
};

export default TippyComponent;