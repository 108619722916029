import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tab,
  Tabs,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material';
import {
  getMusicGenres,
  getPlaylistsByGenre,
  getTopPlaylistsByCountry,
} from '../../../../redux/slices/relaxSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import {
  defaultPlaylist,
  iframeAllows,
  IFrame_API,
} from '../../../../constants/relaxRoom';
import useWindowDimensions from '../../../../hooks/widthHook';
import { Loader } from '../../../../components/Loader';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ModalContainer from '../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import WhatshotTwoToneIcon from '@mui/icons-material/WhatshotTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './MusicModal.scss';

interface MusicModalProps {
  setMusicModal: Dispatch<SetStateAction<boolean>>;
  randomPlaylist: string;
}

function MusicModal({ setMusicModal, randomPlaylist }: MusicModalProps) {
  const [tabsValue, setTabsValue] = useState<number | null>(0);
  const [selectedTopId, setSelectedTopId] = useState<string>('');
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>('');
  const [selectedTop, setSelectedTop] = useState<PlaylistData | null>(null);
  const [selectedGenre, setSelectedGenre] = useState<MusicGenreData | null>(
    null,
  );
  const { status, musicGenres, playlistsByGenre, topPlaylistsByCountry } =
    useSelector((state: RootState) => state.relax);

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  useEffect(() => {
    dispatch(getTopPlaylistsByCountry());
    dispatch(getMusicGenres());
  }, [dispatch]);

  useEffect(() => {
    if (selectedGenre) {
      dispatch(getPlaylistsByGenre(selectedGenre.id));
    }
  }, [dispatch, selectedGenre]);

  useEffect(() => {
    if (selectedTop) {
      setSelectedTopId(selectedTop.id);
    }
  }, [selectedTop]);

  const onPlaylistChange = (_: SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  return (
    <ModalWrapper higherZIndex>
      <ModalContainer
        styles={{
          backgroundColor: '#FBF5FF',
          borderRadius: '12px',
          padding: '0',
          // width: width < 1200 ? '1040px' : '1140px',
          width: width < 1200 ? '325px' : '1140px',
        }}
      >
        <ModalHeader
          mainColor
          withPadding
          textHeader={formatMessage({ id: 'songs' })}
          onClose={() => setMusicModal(false)}
        />
        <ErrorBoundary forRelaxPage>
          <div className="MusicModal__container">
            <div className="MusicModal__topic MusicModal__top50">
              <div className="MusicModal__title">
                <TravelExploreTwoToneIcon className="planet" />
                <p className="MusicModal__title-text">
                  {formatMessage({ id: 'topSongs' })}
                </p>
              </div>
              <div className="select">
                <Autocomplete
                  id="customSelect"
                  disablePortal
                  popupIcon={<ExpandMoreIcon />}
                  options={topPlaylistsByCountry}
                  filterOptions={() => topPlaylistsByCountry}
                  getOptionLabel={(topPlaylistsByCountry: PlaylistData) =>
                    topPlaylistsByCountry.name
                  }
                  onChange={(
                    e: SyntheticEvent<Element | Event>,
                    value: PlaylistData | null,
                  ) => setSelectedTop(value)}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <CustomInput
                      {...params}
                      placeholder={formatMessage({ id: 'selectByCountry' })}
                      width={width >= 1100 ? 380 : 300}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontFamily:
                            lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                        },
                      }}
                    />
                  )}
                />
              </div>
              {selectedTopId ? (
                <iframe
                  className="MusicModal__iframe MusicModal__iframe-top50"
                  title="top50"
                  src={`${IFrame_API}playlist/${selectedTopId}?utm_source=generator`}
                  allow={iframeAllows}
                  loading="lazy"
                ></iframe>
              ) : (
                <iframe
                  className="MusicModal__iframe MusicModal__iframe-top50"
                  title="top50"
                  src={`${IFrame_API}playlist/${defaultPlaylist.byCountry}?utm_source=generator`}
                  allow={iframeAllows}
                  loading="lazy"
                ></iframe>
              )}
            </div>
            <div className="MusicModal__topic MusicModal__trends">
              <div className="MusicModal__title">
                <WhatshotTwoToneIcon className="fire" />
                <p className="MusicModal__title-text">
                  {formatMessage({ id: 'trends' })}
                </p>
              </div>
              <iframe
                className="MusicModal__iframe MusicModal__iframe-trends"
                title="trends"
                src={`${IFrame_API}playlist/${randomPlaylist}?utm_source=generator`}
                allow={iframeAllows}
                loading="lazy"
              ></iframe>
            </div>
            <div className="MusicModal__topic MusicModal__choice">
              <div className="MusicModal__title">
                <RocketLaunchTwoToneIcon className="rocket" />
                <p className="MusicModal__title-text">
                  {formatMessage({ id: 'hereYouWillFindPlaylists' })}
                </p>
              </div>
              <div className="MusicModal__filters">
                <div className="select">
                  <Autocomplete
                    id="customSelect"
                    disablePortal
                    popupIcon={<ExpandMoreIcon />}
                    options={musicGenres}
                    filterOptions={() => musicGenres}
                    getOptionLabel={(musicGenres: MusicGenreData) =>
                      musicGenres.name
                    }
                    onChange={(
                      e: SyntheticEvent<Element | Event>,
                      value: MusicGenreData | null,
                    ) => setSelectedGenre(value)}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <CustomInput
                        {...params}
                        placeholder={formatMessage({ id: 'selectGanre' })}
                        width={width >= 1100 ? 712 : 300}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontFamily:
                              lan === 'ar' || lan === 'hi'
                                ? 'Hind'
                                : 'Open Sans',
                          },
                        }}
                      />
                    )}
                  />
                </div>
                {selectedGenre && status !== 'loading' ? (
                  <Tabs
                    value={tabsValue}
                    onChange={onPlaylistChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{
                      minWidth: '652px',
                      maxWidth: '712px',
                      width: '100%',
                      height: '174px',
                    }}
                  >
                    {playlistsByGenre?.map(
                      (playlist: PlaylistData, index: number) =>
                        playlist && playlist.images && playlist?.name ? (
                          <Tab
                            key={index}
                            className="PlaylistView"
                            icon={
                              <img
                                className="PlaylistView__cover"
                                src={playlist.images[0].url}
                                alt={playlist.name}
                              />
                            }
                            onClick={() => setSelectedPlaylist(playlist.id)}
                          />
                        ) : null,
                    )}
                  </Tabs>
                ) : (
                  <div className="emptyPlaylist">
                    {selectedGenre && status === 'loading' ? (
                      <Loader
                        withoutPosition
                        style={{
                          position: 'static',
                          width: '5%',
                          height: '45%',
                        }}
                      />
                    ) : (
                      <p className="emptyPlaylist__text">
                        {formatMessage({ id: 'seeSelectedPlaylist' })}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <iframe
                className="MusicModal__iframe MusicModal__iframe-choice"
                title="user-choice"
                src={`${IFrame_API}playlist/${
                  selectedPlaylist ? selectedPlaylist : defaultPlaylist.byGanre
                }?utm_source=generator`}
                allow={iframeAllows}
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </ErrorBoundary>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default MusicModal;
