import { Slider } from '@mui/material';
import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { useIntl } from 'react-intl';
import { Cloudinary_PRESET } from '../../constants'
import useWindowDimensions from '../../hooks/widthHook';
import getCroppedImg from '../helpers/cropImage'
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer'
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter'
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader'
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper'
import MainButton from '../shared/Buttons/MainButton/MainButton'
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import './ImageCropModal.scss';

interface Props {
  image: string;
  cropShape: 'rect' | 'round';
  onClose: () => void;
  onSave: (file: any, url?: any) => void;
}

export const ImageCropModal = ({ image, cropShape, onClose, onSave }: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [])

  return (
    <ModalWrapper>
      <ModalContainer>
        <ModalHeader textHeader='Crop the Image' onClose={onClose} />
        <div style={{
          width: width < 600 ? 290 : 500,
          height: 400,
          position: 'relative',
        }}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={cropShape === 'round' ? 1 / 1 : 4 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={cropShape}
          />
        </div>
        <div className='controls'>
          <Slider
            aria-label="Temperature"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, value) => {
              if(typeof value == 'number'){
                setZoom(value)
              }
            }}
            color="secondary"
          />
        </div>
        <ModalFooter>
          <SecondaryButton
            text={formatMessage({ id: 'cancel' })}
            onClick={onClose}
          />
          <MainButton
            text={formatMessage({ id: 'saveChanges' })}
            onClick={() => {
              (async () => {
                const { file, url } = await getCroppedImg(
                  image,
                  croppedAreaPixels,
                  // rotation
                );
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', Cloudinary_PRESET);

                onSave(formData, url)
                onClose()
              })()
            }}
          />
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  )
}
