import React, { CSSProperties } from 'react';
import { SxProps } from '@mui/material';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './ModalHeader.scss';

interface ModalHeaderProps {
  textHeader: string,
  subText?: string,
  onClose?: () => void,
  smallCross?: boolean,
  postCreation?: boolean,
  mainColor?: boolean,
  withPadding?: boolean,
  withoutBorder?: boolean,
  withCloseButton?: boolean,
  arrow?: boolean,
  textStyles?: CSSProperties;
  subTextStyles?: CSSProperties;
  closeSx?: SxProps<any>;
  children?: any
}

const ModalHeader = (({
  textHeader,
  subText,
  onClose,
  smallCross = false,
  postCreation = false,
  mainColor = false,
  withPadding = false,
  withoutBorder = false,
  withCloseButton = true,
  arrow,
  textStyles,
  subTextStyles,
  closeSx,
}: ModalHeaderProps) => {
  const { formatMessage } = useIntl();

  const classNames = `ModalHeader` +
    `${smallCross ? ' ModalHeader__smallCross' : ''}` +
    `${postCreation ? ' ModalHeader__postCreation' : ''}` +
    `${mainColor ? ' ModalHeader--mainColor' : ''}` +
    `${arrow ? ' ModalHeader__arrow' : ' ModalHeader__cross'}` +
    `${withPadding ? ' ModalHeader--withPadding' : ''}` +
    `${withoutBorder ? ' ModalHeader--withPadding--withoutBorder' : ''}`;

  return (
    <div className={classNames}>
      <div className={`ModalHeader__header`}>
        {arrow &&
          <ArrowBackIcon onClick={onClose} />}
        <p style={textStyles}>
          {textHeader === 'Crop the Image' ? formatMessage({ id: 'CropImage' }) : textHeader}
          <span style={subTextStyles}>{subText}</span>
        </p>
      </div>
      {(withCloseButton && !arrow) &&
        <CloseIcon sx={closeSx} onClick={onClose} />}
    </div>
  );
});

export default ModalHeader;
