import axios from 'axios';
import { toast } from 'react-toastify';
import { resetStore } from '../redux/store';
import { intl } from '../localization';

// export const API = 'https://dev.wizwork.net'; //dev-staging
// export const API = 'https://mobile.wizwork.net'; //mobile-staging
// export const API = 'https://stable.wizwork.net'; //stable-staging
export const API = 'https://wizwork.net'; //prod-staging
// export const API = 'http://localhost:5555'; //local

let token = localStorage.getItem('token');

export const customAxios = axios.create();

customAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
customAxios.defaults.headers.common['Cache-Control'] = 'no-cache';
customAxios.defaults.headers.common['Pragma'] = 'no-cache';
customAxios.defaults.headers.common['Expires'] = '0';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const setToken = (newToken: string) => {
  localStorage.setItem('token', newToken);
  token = newToken;
  customAxios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
};

let lastErrorMessage = '';

customAxios.interceptors.response.use(
  response => {
    const newToken = response.headers['monkey'];
    token = newToken;
    if (newToken) {
      customAxios.defaults.headers.common['Authorization'] =
        'Bearer ' + newToken;
      localStorage.setItem('token', newToken);
    }

    return response;
  },
  error => {
    const message = intl.intl().formatMessage({ id: 'serverError' });

    if (error?.response?.data?.message) {
      if (lastErrorMessage !== error.response.data.message) {
        toast.error(
          intl.intl().formatMessage({ id: error.response.data.message }),
        );
        lastErrorMessage = error.response.data.message;
      } else {
        setTimeout(() => {
          lastErrorMessage = '';
        }, 2000);
      }
    } else {
      if (lastErrorMessage !== message) {
        toast.error(message);
        lastErrorMessage = message;
      } else {
        setTimeout(() => {
          lastErrorMessage = '';
        }, 2000);
      }
    }

    if (error.response.status === 401) {
      resetStore();
    }
    return error.response;
  },
);
