import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import sprite from '../../../images/sprite.svg';
import './TermsOfUsePage.scss';
import SecondaryButton from '../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import useWindowDimensions from '../../../hooks/widthHook';
import WestIcon from '@mui/icons-material/West';
import { useIntl } from 'react-intl';

const TermsOfUsePage = () => {
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <AuthContainer step={2} language={width > 1100 ? true : false}>
      <div className="TermsOfUsePageWrapper">
        {width < 1100 && (
          <div className="backArrow">
            <WestIcon onClick={handleClick} />
          </div>
        )}

        {width < 1100 && (
          <div className="TermsOfUseLogoWrapper">
            <svg className="logoWizwork" width={158} height={30}>
              <use href={sprite + '#icon-wizwork'}></use>
            </svg>
          </div>
        )}
        <div className="TermsOfUsePageWrapperShadow">
          {width > 1100 && (
            <div className="TermsOfUseLogoWrapper">
              <svg className="logoWizwork" width={260} height={50}>
                <use href={sprite + '#icon-wizwork'}></use>
              </svg>
            </div>
          )}
          <div className="TermsOfUseTextWrapper">
            <h3 className="TermsOfUseTitle">
              {formatMessage({ id: 'termsOfUse' })}
            </h3>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_1' })}</p>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_2' })}</p>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_3' })}</p>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_4' })}</p>
            <h3 className="TermsOfUseTitle">
              {formatMessage({ id: 'termsTitle' })}
            </h3>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_5' })}</p>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_6' })}</p>
            <p className="TermsOfUseText">{formatMessage({ id: 'terms_7' })}</p>
          </div>

          {width > 1100 && (
            <SecondaryButton text="Back" onClick={handleClick} />
          )}
        </div>
      </div>
    </AuthContainer>
  );
};

export default TermsOfUsePage;
