import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ColorRing } from  'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SentimentSatisfiedAltTwoToneIcon from '@mui/icons-material/SentimentSatisfiedAltTwoTone';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import { RootState } from '../../../../redux/store';
import { Cloudinary_NAME, Cloudinary_PRESET, uploadIconFormats } from '../../../../constants';
import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';
import { useSocket } from '../../../../App';
import "./MessagesFooter.scss";

export default function MessagesFooter() {
  const [searchParams] = useSearchParams() || '';
  const chatId = searchParams.get('chatId') || '';

  const lan = localStorage.getItem('lang') || 'en';

  const [value, setValue] = useState("");
  const [imagesLoading, setImagesLoading] = useState(false);
  const [showSmiles, setShowSmiles] = useState(false);
  const [showDeleteCross, setShowDeleteCross] = useState("");
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const socket = useSocket();

  const { formatMessage } = useIntl();

  const { id } = useSelector((state: RootState) => state.profile);
  const { someUser } = useSelector((state: RootState) => state.someUser);
  const { messageList } = useSelector((state: RootState) => state.chat);

  useEffect(() => {
    setValue("");
    setSelectedImages([]);
  }, [chatId]);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const removeImg = (img: string) => {
    const arr = selectedImages?.filter((item: string) => item !== img);
    setSelectedImages(arr);
  };

  const sendMessageKeyboard = (e: any) => {
    if (e.key === 'Enter' && e.shiftKey){
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;
      const newText = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd);
      setValue(newText);
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (value || selectedImages.length) {
        const obj = {
          text: value,
          images: selectedImages,
          ownerId: id,
          userIds: [id, someUser?.user?.id],
          chatId
        };
  
        if (socket) {
          socket.emit('createMessage', obj);
        }
        setValue("");
        setSelectedImages([]);
      };
      e.preventDefault();
    }
  }

  const sendMessage = () => {
    if (value || selectedImages.length) {
      const obj = {
        text: value,
        images: selectedImages,
        ownerId: id,
        userIds: [id, someUser?.user?.id],
        chatId
      };

      if (socket) {
        socket.emit('createMessage', obj);
      }
      setValue("");
      setSelectedImages([]);
    };
  }

  const onAddImage = async (
    filesRes: FileList | null,
  ) => {
    setImagesLoading(true);
    if (filesRes === null) return;

    const images = [...Array.from(filesRes)];

    const imageSize = images[0].size / (1024 ** 2);
    if (imageSize > 5) {
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }

    let files: string[] = [];

    if (images) {
      for (let i = 0; i < images.length; i++) {
        const formData = new FormData();
        formData.append('file', images[i]);
        formData.append('upload_preset', Cloudinary_PRESET);

        await axios
          .post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, formData)
          .then((response: AxiosResponse<any>) => {
            files.push(response?.data?.secure_url);
          })
          .catch(() => {
            setImagesLoading(false);
            toast.error(formatMessage({ id: 'errorCreateOrEditPost' }));
          });
      }
    }

    setSelectedImages(files);
    setImagesLoading(false);
  };

  return (
    <div className="MessagesFooter">
      <textarea
        placeholder={messageList?.length
        ? formatMessage({ id: 'chatPlaceholder' })
        : formatMessage({ id: 'chatPlaceholderEmpty' })}
        className={`MessagesFooter__textarea ${lan === 'ar' && "MessagesFooter__textarea--ar"}`}
        value={value}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
        onKeyDown={sendMessageKeyboard}
      />
      <div className={`MessagesFooter__inputArea-footer ${lan === 'ar' && "MessagesFooter__inputArea-footer--ar"}`}>
        <div className="MessagesFooter__buttons">
          <button
            type="button"
            className="MessagesFooter__btn"
            onClick={handleButtonClick}
          >
            <ImageOutlinedIcon sx={{fontSize: 24}}/>
            <input
              multiple
              name="uploadedImg"
              type="file"
              ref={fileInputRef}
              accept={uploadIconFormats}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onAddImage(e.target.files);
                e.target.value = "";
              }}
              style={{ display: 'none' }}
            />
          </button>
          <button
            type="button"
            className="MessagesFooter__btn"
            onClick={() => setShowSmiles(!showSmiles)}
          >
            <SentimentSatisfiedAltTwoToneIcon sx={{fontSize: 24}}/>
          </button>
          {showSmiles && <div className={`MessagesFooter__picker ${lan === 'ar' && "MessagesFooter__picker--ar"}`}>
            <EmojiPicker
              height={350} 
              width={window.innerWidth < 410 ? 320 : 400}
              onEmojiClick={(emojiData: EmojiClickData) => {
                setValue(value + " " + emojiData.emoji);
                setShowSmiles(false);
              }}
            />
          </div>}
        </div>
        <MainButton 
          text={formatMessage({ id: 'Send' })}
          type="button"
          disabled={!value && !selectedImages?.length}
          onClick={sendMessage}
        />
      </div>
      {imagesLoading && (
        <div>
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#FBF5FF', '#791186', '#FBF5FF', '#791186', '#FBF5FF']}
          />
        </div>
      )}
      {!!selectedImages.length && (
        <div className={`MessagesFooter__images ${lan === 'ar' && "MessagesFooter__images--ar"}`}>
          {selectedImages.map((imageLink: string, i: number) => (
            <div
              className="MessagesFooter__img"
              key={i}
              onMouseEnter={() =>setShowDeleteCross(imageLink)}
              onMouseLeave={() =>setShowDeleteCross("")}
            >
              <img
                className="MessagesFooter__selectImages"
                src={imageLink}
                alt="selectedImage" 
              />
              {showDeleteCross === imageLink && (
                <div 
                  className="MessagesFooter__delete-img"
                  onClick={() => removeImg(imageLink)}
                >
                  <CancelRoundedIcon sx={{fontSize: 16}}/>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

