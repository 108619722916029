import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import MainButton from '../shared/Buttons/MainButton/MainButton';
import './DataPicker.scss';

const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  fontFamily: 'Open Sans',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '19px',
  borderRadius: '9px',
  marginBottom: '12px'
};

type Props = {
  setScheduleTime: (time: number) => void;
  onSubmit: () => void;
  setOpen: (open: boolean) => void;
  disabled?: boolean;
};

function DataPicker({ setScheduleTime, onSubmit, setOpen, disabled }: Props) {
  const { formatMessage } = useIntl();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const nowMs = new Date().getTime();
  const [sheduleStatus, setSheduleStatus] = useState<string>("");
  const [close, setClose] = useState<boolean>(false);

  useEffect(() => {
    !selectedTime.length && setSelectedTime(new Date().toLocaleTimeString());
  }, [new Date(), selectedTime]);

  useEffect(() => {
    if (disabled || close) {
      setSheduleStatus('');
      setSelectedDate(new Date());
      setSelectedTime(new Date().toLocaleTimeString());
      setOpen(false);
    }
    setClose(false);
  }, [disabled, close, setOpen]);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time: any) => {
    if (time) {
      setSelectedTime(time);
    }
  };

  const futureDateTime = new Date(selectedDate);
  const [hour, minute] = selectedTime.split(":");
  futureDateTime.setHours(+hour);
  futureDateTime.setMinutes(+minute);

  function formatDate(dateString: Date) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    // @ts-ignore
    return date.toLocaleDateString("en-US", options);
  };

  function formatDateAndTime(dateString: Date) {
    const options = { month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: true };
    const date = new Date(dateString);
    // @ts-ignore
    return date.toLocaleDateString("en-US", options);
  }


  // Calculate the difference in milliseconds
  const diffMs = +futureDateTime - nowMs;

  return (
    <>
      <div
        className="DataPicker"
        onClick={() => {
          if (!disabled) {
            !sheduleStatus && setSheduleStatus("Date");
            setOpen(true);
          }
        }}
      >
        <MainButton
          disabled={disabled}
          text={formatMessage({ id: 'schedulePost' })}
        />
      </div>
      {sheduleStatus && (
        <>
          {sheduleStatus === "Date" ? (
            <div className="DataPicker__container">
              <div
                className="DataPicker__btn-close"
                onClick={() => setClose(true)}
              >
              </div>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()}
                inline
              />
              <div
                onClick={() => setSheduleStatus("Time")}
              >
                <MainButton
                  text={formatMessage({ id: 'Confirm' })}
                  styles={buttonStyle}
                />
              </div>
            </div>
          ) : (
            <>
              {sheduleStatus === "Time" ? (
                <div className="DataPicker__container DataPicker__container--time">
                  <div className="DataPicker__header">
                      {formatDate(selectedDate)}
                      <div
                        className="DataPicker__btn-close"
                        onClick={() => setClose(true)}
                      >
                      </div>
                  </div>
                  <TimePicker
                    value={selectedTime}
                    onChange={handleTimeChange}
                    disableClock={true}
                    hour12={true}
                    format={"h:mm a"}
                    locale={"en-US"}
                  />
                  <div
                    onClick={() => setSheduleStatus("End")}
                  >
                    <MainButton
                      text={formatMessage({ id: 'Confirm' })}
                      styles={{
                        ...buttonStyle,
                        marginTop: '28px'
                      }}
                    />
                </div>
              </div>
                ) : (
                  <div className="DataPicker__container DataPicker__container--time">
                    <div className="DataPicker__header">
                        Your post will be published
                        <div
                          className="DataPicker__btn-close"
                          onClick={() => setClose(true)}
                        >
                        </div>
                    </div>
                    <p>{formatDateAndTime(futureDateTime)}</p>
                    <div
                      onClick={() => {   
                        setScheduleTime(diffMs);
                        onSubmit();
                        setOpen(false);
                      }}
                      >
                      <MainButton
                        text={formatMessage({ id: 'Ok' })}
                        styles={{
                          ...buttonStyle,
                          marginTop: '21px'
                        }}
                      />
                    </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default DataPicker;
