import { ReactComponent as EnglishIcon } from '../images/languagesIcons/English.svg';
import { ReactComponent as FrenchIcon } from '../images/languagesIcons/France.svg';
import { ReactComponent as SpanishIcon } from '../images/languagesIcons/Spain.svg';
import { ReactComponent as SwahiliIcon } from '../images/languagesIcons/Swahili.svg';
import { ReactComponent as PortugueseIcon } from '../images/languagesIcons/Portuguese.svg';
import { ReactComponent as ArabicIcon } from '../images/languagesIcons/Arabic.svg';
import { ReactComponent as HindiIcon } from '../images/languagesIcons/Hindi.svg';
import { intl } from '../localization';

export const defaultLanguage: LanguageType =
  (localStorage.getItem('lang') as LanguageType) || 'en';

intl.setLocale(defaultLanguage);

export const languages: Language[] = [
  {
    id: 1,
    code: 'en',
    label: 'English',
    fullName: 'English',
    shortName: 'Eng',
    icon: <EnglishIcon />,
  },
  {
    id: 2,
    code: 'fr',
    label: 'French',
    fullName: 'French',
    shortName: 'Fre',
    icon: <FrenchIcon />,
  },
  {
    id: 3,
    code: 'es',
    label: 'Spanish',
    fullName: 'Spanish',
    shortName: 'Spa',
    icon: <SpanishIcon />,
  },
  {
    id: 4,
    code: 'sw',
    label: 'Swahili',
    fullName: 'Swahili',
    shortName: 'Swa',
    icon: <SwahiliIcon />,
  },
  {
    id: 5,
    code: 'pt',
    label: 'Portuguese',
    fullName: 'Portuguese',
    shortName: 'Por',
    icon: <PortugueseIcon />,
  },
  {
    id: 6,
    code: 'ar',
    label: 'Arabic',
    fullName: 'Arabic',
    shortName: 'Ara',
    icon: <ArabicIcon />,
  },
  {
    id: 7,
    code: 'hi',
    label: 'Hindi',
    fullName: 'Hindi',
    shortName: 'Hin',
    icon: <HindiIcon />,
  },
];
