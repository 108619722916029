import '../MyProfilePage/MyProfilePage.scss';
import '../MyProfilePage/MainContainer.scss';
import { NavSide } from '../../components/NavSide/NavSide';
import { Wall } from '../../components/Wall/Wall';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getGroupsProfileInfo, setTypeId } from '../../redux/slices/groupsSlice';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';

const GroupsPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams] = useSearchParams() || '';

  const typeId = searchParams.get('typeId') || '';
  const interestId = searchParams.get('interestId') || '';
  const groupId = searchParams.get('groupId') || '';

  const id = useMemo(() => typeId || interestId || groupId , [groupId, interestId, typeId])

  const name = useMemo(() => typeId !== '' ? 'typeId' : groupId !== '' ? 'groupId' : 'interestId',
   [typeId, groupId])

  useEffect(() => {
    dispatch(setTypeId(name))
    dispatch(getGroupsProfileInfo({ [name]: id + '' }))
    window.scroll(0, 0)
  }, [dispatch, id, name])

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className="main-page">
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner">
                <NavSide />
                <Wall otherUser groupsView />
                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default GroupsPage;
