import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import useWindowDimensions from '../../hooks/widthHook';
import Header from '../../components/Header/Header';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { ContactUsFaq } from './ContactUsFaq/ContactUsFaq';
import { TermsOfUse } from './TermsOfUseFaq/TermsOfUseFaq';
import { PrivacyPolicyFaq } from './PrivacyPolicyFaq/PrivacyPolicyFaq';
import { getPrivPolicy } from '../../redux/slices/adminSlice';
import { getTermsOU } from '../../redux/slices/adminSlice';
// import { LoggingInFaq } from './LoggingInFaq/LoggingInFaq';
// import { NewsfeedFaq } from './NewsfeedFaq/NewsfeedFaq';
// import { TimelineFaq } from './TimelineFaq/TimelineFaq';
// import { AccountFaq } from './AccountFaq/AccountFaq';
// import { BlockingFaq } from './BlockingFaq/BlockingFaq';
// import { TimelineFaq } from './TimelineFaq/TimelineFaq';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './FaqPage.scss';

const FaqPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState('terms of use');
  const [isSelected, setIsSelected] = useState(true);
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();


  const routes = [
    //TODO use later
    // {
    //   name: 'Account',
    //   tab: 'account',
    //   element: <AccountFaq />
    // },
    // {
    //   name: 'Newsfeed',
    //   tab: 'newsfeed',
    //   element: <NewsfeedFaq />
    // },
    // {
    //   name: 'Blocking',
    //   tab: 'blocking',
    //   element: <BlockingFaq />
    // },
    // {
    //   name: 'Logging In',
    //   tab: 'logging in',
    //   element: <LoggingInFaq />
    // },
    // {
    //   name: 'Timeline',
    //   tab: 'timeline',
    //   element: <TimelineFaq />
    // },
    {
      name: formatMessage({ id: 'termsOfUse' }),
      tab: 'terms of use',
      element: <TermsOfUse />
    },
    {
      name: formatMessage({ id: 'privacyPolicy' }),
      tab: 'privacy policy',
      element: <PrivacyPolicyFaq />
    },
    {
      name: formatMessage({ id: 'contactUs' }),
      tab: 'contact us',
      element: <ContactUsFaq />
    },
  ];

  return (
    <div
      className="bg-container"
      style={{
        overflow: 'hidden',
        height: '90vh'
      }}
    >
      <Header />
      <div className="page-container">
        <div className='faq-page'>
          {width > 1100 && (
            <>
              <h2 className='faq-page__title'>
                {formatMessage({ id: 'faq' })}
              </h2>
              <div className='faq-page__subtitle'>
                {formatMessage({ id: 'faqDescription' })}
              </div>
            </>
          )}
          {width < 1100 ? (
            <div className='faq-page__inner'>
              <div
                className='faq-page__container'
                style={{
                  left: (isSelected || selectedTab === 'deactivate') ? '0' : '-100%'
                }}
              >
                <h2 className='faq-page__mobile-title'>
                  {formatMessage({ id: 'faq' })}
                </h2>
                <Sidebar
                  mobile={width < 1100}
                  routes={routes}
                  selectedTab={''}
                  setSelectedTab={setSelectedTab}
                  setIsSelected={setIsSelected}
                />
              </div>
              <div className='faq-page__wrap'>
                <button
                  className='faq-page__button-back'
                  onClick={() => setIsSelected(true)}
                >
                  <div className='faq-page__back-icon'>
                    <KeyboardBackspaceIcon />
                  </div>
                  <span className='faq-page__button-back-text'>
                    {routes.find(item => item.tab === selectedTab)?.name}
                  </span>
                </button>
                {routes.find(item => item.tab === selectedTab)?.element}
              </div>
            </div>
          ) : (
            <div className='faq-page__inner'>
              <Sidebar routes={routes} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              {routes.find(item => item.tab === selectedTab)?.element}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
