import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import {
  passwordChange,
  setPassword,
  setResponseMessage,
} from '../../../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import ShowPassword from '../../../../components/shared/CustomInput/ShowPassword/ShowPassword';
import './NewPasswordForm.scss';

const NewPasswordForm: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lan = localStorage.getItem('lang') || 'en';

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid, touchedFields },
  } = useForm({ mode: 'onChange' });

  const { responseMessage } = useSelector((state: RootState) => state.auth);

  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [email, setEmail] = useState<string | null>('');
  useEffect(() => {
    setEmail(queryParams.get('email'));
  }, []);

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  // show password
  const [isVisiblePassword, setVisibilityPassword] = useState(false);
  const [isVisibleConfirmPassword, setVisibilityConfirmPassword] =
    useState(false);

  const dataToSend = {
    email: email,
    password: newPassword,
  };

  useEffect(() => {
    if (responseMessage) {
      toast.error(responseMessage);
    }
    dispatch(setResponseMessage(''));
  }, [responseMessage]);

  const onHandleSubmit = () => {
    dispatch(passwordChange(dataToSend));
    dispatch(setPassword(newPassword));
    if (responseMessage === '') {
      toast.success(formatMessage({ id: 'passwordChanged' }));
    }

    if (!responseMessage) {
      setTimeout(() => {
        navigate('/sign-in');
      }, 2000);
    }
  };

  return (
    <>
      <form className="authForm" onSubmit={handleSubmit(onHandleSubmit)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="newPasswordWrapper">
            <span className="newPassword">{formatMessage({ id: 'setNewPassword' })}</span>{' '}
          </div>
          <div className="newPasswordText">
            {formatMessage({ id: 'createNewPasswordFor' })} <b>{email}</b>
          </div>
          <div style={{ marginTop: 16 }}>
            <CustomInput
              placeholder={formatMessage({ id: 'passwordPlaceholder' })}
              label={formatMessage({ id: 'passwordLabel' })}
              inputName="password"
              errors={errors}
              touchedFields={touchedFields}
              onChangeFunc={value => {
                setNewPassword(value);
                setValue('password', value);
              }}
              inputProps={{
                ...register('password', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  pattern: {
                    value:
                    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-\+={}\[\]'";:?.>\|,<\\])[A-Za-z\d`~!@#$%^&*()_\-\+={}\[\]'";:?.>\|,<\\]{8,}/i,
                    message: formatMessage({ id: 'weakPassword' }),
                  },
                }),
                type: isVisiblePassword ? 'text' : 'password',
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  textAlign: lan === 'ar' ? 'right' : 'left'
                }
              }}
              InputProps={{
                endAdornment: (
                  <ShowPassword
                    onClick={() => {
                      setVisibilityPassword(!isVisiblePassword);
                    }}
                    isVisible={isVisiblePassword}
                  />
                ),
              }}
            />
          </div>
          <div style={{ marginTop: 16, marginBottom: 24 }}>
            <CustomInput
              placeholder={formatMessage({ id: 'passwordPlaceholder' })}
              label={formatMessage({ id: 'confirmPasswordLabel' })}
              inputName="confirm password"
              errors={errors}
              touchedFields={touchedFields}
              onChangeFunc={value => {
                setConfirmNewPassword(value);
                setValue('confirm password', value);
              }}
              inputProps={{
                ...register('confirm password', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  validate: value =>
                    value === getValues('password') ||
                    formatMessage({ id: 'unmatchingPassword' }),
                }),
                type: isVisibleConfirmPassword ? 'text' : 'password',
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  textAlign: lan === 'ar' ? 'right' : 'left'
                }
              }}
              InputProps={{
                endAdornment: (
                  <ShowPassword
                    onClick={() => {
                      setVisibilityConfirmPassword(!isVisibleConfirmPassword);
                    }}
                    isVisible={isVisibleConfirmPassword}
                  />
                ),
              }}
            />
          </div>
          <MainButton
            text={formatMessage({ id: 'savePassword' })}
            type="submit"
            disabled={!isValid}
          />
        </div>
      </form>
    </>
  );
};

export default NewPasswordForm;
