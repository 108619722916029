import React, { useState } from 'react';
import useWindowDimensions from '../../hooks/widthHook';
import { processTextInLink } from '../helpers/linksProcessing';
import { PostMediaContent } from '../PostMediaContent/PostMediaContent';
import './PostText.scss';
import { useIntl } from 'react-intl';
import { addBrTagInLine } from '../helpers/parseLineBreak';

interface Props {
  post: Post;
  shared?: boolean;
  sharedModal?: boolean;
}

export const PostText = ({ post, shared, sharedModal }: Props) => {
  const { formatMessage } = useIntl();
  const [seeMore, setSeeMore] = useState(false);
  let widthOfScreenMobile = useWindowDimensions().width;
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <>
      <div
        className="post__body-text"
        style={{
          textAlign: (lan === 'ar') ? 'right' : 'left'
        }}
      >
        {seeMore && post?.description
          ? processTextInLink(addBrTagInLine(post?.description))
          : processTextInLink(
              `${addBrTagInLine(post?.description?.slice(0, 300))}${
                post?.description?.length > 300 ? '' : ''
              }`,
            )}
      </div>

      {post?.description?.length > 300 && (
        <div className="post__body-text-more-container">
          <span
            className="post__body-text-more"
            onClick={() => {
              setSeeMore(!seeMore);
            }}
          >
            {seeMore
              ? formatMessage({ id: 'seeLess' })
              : formatMessage({ id: 'seeMore' })}
          </span>
        </div>
      )}

      {post?.attachments?.length > 0 && (
        <div
          className="post__media-container"
          style={{
            marginBottom: `${post?.type === 'SURVEY' && '12px'}`,
            gap: `${
              (sharedModal && post?.attachments?.length === 2 && '2%') ||
              (post?.attachments?.length === 2 && '2%') ||
              (post?.attachments?.length >= 3 &&
                widthOfScreenMobile < 1100 &&
                '2%')
            }`,
          }}
        >
          <PostMediaContent
            post={post}
            shared={shared}
            sharedModal={sharedModal}
          />
        </div>
      )}
    </>
  );
};
