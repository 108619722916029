import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from '../../../../redux/store';
import "./Message.scss";
import { processTextInLink } from '../../../../components/helpers/linksProcessing';

export default function Message({ message }: any) {
  const { formatMessage } = useIntl();
  const [ownerPost, setOwnerPost] = useState<any>({});
  const lan = localStorage.getItem('lang') || 'en';

  const { someUser } = useSelector((state: RootState) => state.someUser);

  const { id, profileAvatar } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (message?.ownerId === id) {
      setOwnerPost({
        user: {
          name: "Me",
          profileAvatar: profileAvatar
        }
      });
    } else {
      setOwnerPost(someUser);
    }
  }, [someUser]);

  return (
    <div className={`Message ${lan === 'ar' && "Message--ar"}`}>
      <img 
        className="Message__avatar" 
        src={ownerPost?.user?.profileAvatar} 
        alt="avatar"
      />
      <div className={`Message__main ${lan === 'ar' && "Message__main--ar"}`}>
        <p className="Message__name">
          {(ownerPost?.user?.name && ownerPost?.user?.name === "Me") 
            ? formatMessage({ id: 'Me' }) 
            : (ownerPost?.user?.firstName
              ? `${ownerPost?.user?.firstName} ${ownerPost?.user?.lastName}`
              : ownerPost?.user?.organizationName)
          }
        </p>
        {message?.text && <div className="Message__text">{processTextInLink(message.text)}</div>}
        {message?.images[0] && (
          <div className="Message__images">
            {message.images?.map((image: string) => (
              <img key={image} className="Message__image" src={image} alt="img" />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
