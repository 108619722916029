import React, { useState } from 'react';
import ModalContainer from '../../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../Buttons/MainButton/MainButton';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton';
import CustomInput from '../CustomInput/CustomInput';
import { useIntl } from 'react-intl';
import { reportComment, reportPost } from '../../../api/posts';
import { removePost, removePostFeed, removePostGroups, removeReportPosts } from '../../../redux/slices/postsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { reportUser } from '../../../api/someUser';

type Props = {
  commentId?: number;
  postId?: number;
  someUserId?: string;
  setReportModal: (value: boolean) => void;
}

const ReportModal: React.FC<Props> = ({
  commentId,
  postId,
  someUserId,
  setReportModal
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const [reportReason, setReportReason] = useState('');
  const lan = localStorage.getItem('lang') || 'en';

  const handleReport = () => {
    if (commentId) {
      reportComment({commentId, reason: reportReason});
    }
    if (postId) {
      reportPost({ postId, reason: reportReason })
        .then((res) => {
            // dispatch(removePost(postId));
            // dispatch(removePostFeed(postId));
            // dispatch(removePostGroups(postId));
          dispatch(removeReportPosts(res?.data?.owner?.id));
        })
    }
    if (someUserId) {
      reportUser({ userId: someUserId || '', reason: reportReason })
    }
    setReportModal(false);
  };

  return (
    <ModalWrapper width={350}>
      <ModalContainer>
        <ModalHeader
          textHeader={formatMessage({ id: 'reportConfirm' })}
          smallCross
          onClose={() => setReportModal(false)}
        />
        <CustomInput
          width="100%"
          placeholder={formatMessage({ id: 'reportReason' })}
          label={formatMessage({ id: 'reportReasonLabel' })}
          onChangeFunc={(value: string) => {
            setReportReason(value);
          }}
          inputProps={{
            style: {
              fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
              textAlign: lan === 'ar' ? 'right' : 'left'
            }
          }}
        />
        <ModalFooter>
          <SecondaryButton
            text={formatMessage({ id: 'report' })}
            onClick={handleReport}
            bigPadding
            disabled={!reportReason}
          />
          <MainButton
            text={formatMessage({ id: 'cancel' })}
            onClick={() => setReportModal(false)}
            bigPadding
          />
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ReportModal;