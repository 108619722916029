import React from 'react';
import { useIntl } from 'react-intl';
import useWindowDimensions from '../../../hooks/widthHook';
import newLogo from '../../../images/newLogo.png';
import './AuthLogo.scss';

const AuthLogo = () => {
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';
  const { width } = useWindowDimensions();

  return (
    <div
      className="authContainerLogoWrapper"
      style={{
        alignItems: width > 1100 ?
          (lan === 'ar' ? 'flex-end' : 'flex-start') :
          'center',
      }}
    >
      <div className="authContainerLogo">
        <img
          className="authNewlogoWizwork"
          src={newLogo}
          alt="WizWork-logo"
        />
      </div>
      <div
        className="authContainerText"
        dir={lan === 'ar' ? 'rtl' : 'ltr'}
      >
        {formatMessage({ id: 'connectWithWomen' })}
      </div>
    </div>
  );
};

export default AuthLogo;
