import { translateText } from "../../api/posts";
import { detectLang } from "./datectLang";

function sliceIntoChunks(arr: string[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};


export const startTranslate = async (text: string, lang: string) => {
  let res = "";

  // eslint-disable-next-line no-useless-escape
  const regExp = /((?:http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\/\S*)?)/g;
  const arr = text.split(regExp);
  const textParts = [] as string[];

  arr.forEach((el: string) => {
    if (el.includes('https://') || el.includes('http://') || el.includes('www.')) {
      textParts.push(el);
    } else {
      const newEls = el.split(/\r\n|\r|\n/g);

      newEls.forEach((a: string) => {
        if (a === "") {
          textParts.push("\n");
        } else {
          textParts.push(a);
        };
      });
    }
  })


  for (const items of textParts) {
    if (items.includes('https://') || items.includes('http://') || items.includes('www.') || items === " " || items === "\n") {
      res += ' ' + items + ' ';
    } else {
      const textToArr = items.split(/\s+/);

      if (textToArr.length > 70) {
        const slicedArr = sliceIntoChunks(textToArr, 70);

        const processArray = async (array: any) => {
          let textJoined = "" as string;

          for (const item of array) {
            const customData: any = {
              "text": item.join(' '),
              "source": "",
              "target": detectLang(lang)
            };

            const posts = await translateText(customData);
            console.log(posts.data.translation_text);
            textJoined += posts.data.translation_text;
          }

          return textJoined;
        }

        res += await processArray(slicedArr);
      } else {

        const customData: any = {
          "text": items,
          "source": "",
          "target": detectLang(lang)
        };

        const posts = await translateText(customData);
        res += posts.data.translation_text;
      }
    }
  }

  return res;
};
