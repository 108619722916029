import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import useWindowDimensions from '../../hooks/widthHook';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { isAndroid, isBrowser } from 'react-device-detect';
import './PetitionContact.scss';

interface Props {
  email: string;
  isSharing?: boolean;
}

export const PetitionContact: React.FC<Props> = ({ email, isSharing }) => {
  const [isVisibleEmail, setIsVisibleEmail] = useState(false);
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <>
      {email && (
        <div
          className="post__petition-email-container"
          style={{
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
          }}
        >
          <p
            className="post__petition-email-title"
            dir={lan === 'ar' ? 'rtl' : 'ltr'}
            style={{ textAlign: lan === 'ar' ? 'right' : 'left' }}
          >
            {formatMessage({ id: 'contact' })}:
          </p>
          <p className={`post__petition-email${width < 1200 ?
            ' post__petition-email-mob' : ''}`}>
            {isVisibleEmail ?
              email : `${'*'.repeat(email.split('@')[0].length)}@${email.split('@')[1]}`}
          </p>
          <button
            className="post__petition-email-visibility"
            type="button"
            onClick={() => setIsVisibleEmail(!isVisibleEmail)}
          >
            {isVisibleEmail ?
              <VisibilityTwoToneIcon /> :
              <VisibilityOffTwoToneIcon />}
          </button>
          {!isSharing &&
            <button
              className="post__petition-copy-container"
              type="button"
              onClick={() => {
                toast.info(formatMessage({ id: 'textCopied' }));
                if (!navigator?.clipboard) {
                  const textarea = document.createElement('textarea');
                    textarea.textContent = email;
                    document.body.appendChild(textarea);
                    textarea.select();
                    try {
                      document.execCommand('copy');
                      // toast.info(formatMessage({ id: 'textCopied' }));
                    } catch(e) {
                      console.log('Something went wrong')
                    } finally {
                      
                    document.body.removeChild(textarea);
                  }
                } else {
                  navigator.clipboard.writeText(email);
                  // toast.info(formatMessage({ id: 'textCopied' }));
                }

                //@ts-ignore
                window.ReactNativeWebView.postMessage(email);
              }}
              style={{ flexDirection: lan === 'ar' ? 'row-reverse' : 'row' }}
            >
              <ContentCopyTwoToneIcon />
              <span>{formatMessage({ id: 'copy' })}</span>
            </button>}
        </div>
      )}
    </>
  )
}
