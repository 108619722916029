import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import CloseIcon from '@mui/icons-material/Close';
import './ConfirmModal.scss';
import { Loader } from '../Loader';

interface ConfirmModalProps {
  contentId: string;
  loader: boolean;
  secondaryButtonTextId?: string;
  secondaryButtonAction?: () => void;
  mainButtonTextId?: string;
  mainButtonAction?: () => void;
  withCloseIcon?: boolean;
  divStyles?: CSSProperties;
  pStyles?: CSSProperties;
}

function ConfirmModal({
  loader,
  withCloseIcon,
  divStyles,
  pStyles,
  contentId,
  mainButtonTextId,
  mainButtonAction,
  secondaryButtonTextId,
  secondaryButtonAction,
}: ConfirmModalProps) {
  const { formatMessage } = useIntl();
  return (
    <>
      {loader ?
        <Loader /> :
        <ModalWrapper>
          <ModalContainer>
            <div className="ConfirmModal" style={divStyles}>
              {withCloseIcon &&
                <CloseIcon
                  className="ConfirmModal__cross"
                  onClick={mainButtonAction}
                />}
              <p className="ConfirmModal__text" style={pStyles}>
                {formatMessage({ id: contentId })}
              </p>
            </div>
            <ModalFooter>
              <SecondaryButton
                text={secondaryButtonTextId ?
                  formatMessage({ id: secondaryButtonTextId }) :
                  formatMessage({ id: 'create' })}
                onClick={secondaryButtonAction}
              />
              <MainButton
                text={mainButtonTextId ?
                  formatMessage({ id: mainButtonTextId }) :
                  formatMessage({ id: 'cancel' })}
                onClick={mainButtonAction}
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>}
    </>
  )
}

export default ConfirmModal;
