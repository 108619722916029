import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUserEmail } from '../../../redux/slices/authSlice';
import { setSignUpStep } from '../../../redux/slices/appSlice';
import { AppDispatch, RootState } from '../../../redux/store';
// import useWindowDimensions from '../../../hooks/widthHook';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import { defaultAvatars } from '../../../constants/accountSettings';
import Onboarding from '../Onboarding/Onboarding';
import SignUpForm from './SignUpForm/SignUpForm';
import AuthLogo from '../AuthLogo/AuthLogo';
import './SignUpEmailPage.scss';

const SignUpEmailPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { step } = useSelector((state: RootState) => state.app);
  const { language } = useSelector((state: RootState) => state.profile);

  const { email, password } = useSelector((state: RootState) => state.auth);

  const updateStep = (step: number) => {
    dispatch(setSignUpStep(step));
  };
  const onSubmitSignUp = (email: string, password: string) => {
    const newObj = {
      email: email,
      password: password,

      isOrganization: false,
      organizationName: '',
      firstName: email.split('@')[0],
      lastName: email.split('@')[0],
      birthYear: '1980',

      country: 'Canada',
      region: '',

      titles: ['title1'],
      otherTitle: '',

      interests: [],
      profileAvatar: '',
      about: 'No information provided.',

      notification: {
        posts: true,
        activity: true,
        additions: true,
        surveys: true,
      },
      ads: false,
      language,
    };

    newObj.profileAvatar = defaultAvatars[0];

    dispatch(addNewUserEmail(newObj));
    return;
  };

  return (
    <AuthContainer step={step} language={true}>
      <div className="authContainerWrapper-signUp">
        
        <div className='ellipse'></div>
        <div className='authWrapper'>
          {step === 1 && <AuthLogo />}
          {step === 1 && (
            <div className="authForm">
              <div className="signUpFormWrapper">
                <SignUpForm onSubmitSignUp={onSubmitSignUp} />
                {/* <div style={{ marginTop: 16, textAlign: 'center' }}>
                  <span>{formatMessage({ id: 'alreadyHaveAccount' })}</span>{' '}
                  <b>
                    <Link to="/sign-in">{formatMessage({ id: 'login' })}</Link>
                  </b>
                </div> */}
                <div className="signUpTextWrapper">
                  <span className="signUpText">
                    {formatMessage({ id: 'agreeToOur' })}
                  </span>{' '}
                  <b className="signUpLink">
                    <a
                      style={{ color: '#363636' }}
                      href={formatMessage({ id: 'termsOfUseFile' })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage({ id: 'termsOfUse' })}
                    </a>
                  </b>{' '}
                  <span className="signUpText">
                    {' '}
                    {formatMessage({ id: 'and' })}{' '}
                  </span>{' '}
                  <b className="signUpLink">
                    <a
                      style={{ color: '#363636' }}
                      href={formatMessage({ id: 'privacyPolicyFile' })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage({ id: 'privacyPolicy' })}
                    </a>
                  </b>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* {step === 2 && (
          <Onboarding setStep={updateStep} onSubmitSignUp={onSubmitSignUp} signUp />
        )} */}
      </div>
    </AuthContainer>
  );
};

export default SignUpEmailPage;
