import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllMessages, getChatList, getNewMessages } from '../../api/chat';

interface InitialState {
  loading: boolean,
  chatsList: any[],
  messageList: any[],
  isMenuOpened: boolean,
  msgCount: string[],
  hasMore: boolean,
  hasBottomScroll: false,
  selectId: number,
  chatId: number | null,
}

const initialState: InitialState = {
  loading: false,
  chatsList: [],
  messageList: [],
  isMenuOpened: false,
  msgCount: [],
  hasMore: true,
  hasBottomScroll: false,
  selectId: 0,
  chatId: null
};

export const getAllChats = createAsyncThunk(
  'chat/getAllChats',
  async function (_, { rejectWithValue, dispatch }) {
    // dispatch(showLoader(true));
    try {
      const response = await getChatList();

      dispatch(showLoader(false));
      if (response.data) return response.data;
    } catch (error: any) {
      // dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

export const getMessages = createAsyncThunk(
  'chat/getMessages',
  async function (data: {id: string, offset: number}, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const response = await getAllMessages(data);
      dispatch(showLoader(false));
      if (response.data) return response.data;
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

export const getMsgCount = createAsyncThunk(
  'chat/getMsgCount',
  async function (_, { rejectWithValue, dispatch }) {
    try {
      const response = await getNewMessages();

      dispatch(showLoader(false));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    showLoader(state, action) {
      state.loading = action.payload;
    },
    addNewMessage(state, action) {
      let selectedChat = state.chatsList.find((chat: any) => +chat.id === +action.payload.chatId);

      if (state.chatId && +state.chatId === +action.payload.chatId) {
        state.messageList = [...state.messageList, action.payload];
      };
      selectedChat.text = action.payload.text ? action.payload.text : '\t';
      selectedChat.date = action.payload.date; 
      const newChatsState = state.chatsList.filter((chat: any) => +chat.id !== +action.payload.chatId);
      newChatsState.unshift(selectedChat);
      state.chatsList = newChatsState;
    },
    cleanMsgs(state) {
      state.messageList = [];
      state.chatsList = state.chatsList?.filter((a: any) => +a?.id);
    },
    increaseCountOfMsgs(state, action) {
      if ((state.chatId && +state.chatId !== +action.payload.chatId) || !state.chatId) {
        state.msgCount.push(action.payload.chatId);
      }
    },
    setMenuOpenStatus(state, action) {
      state.isMenuOpened = action.payload;
    },
    createNewChat(state, action) {
      state.chatsList.unshift(action.payload);
    },
    cleanHasMore(state) {
      state.hasMore = true;
      state.messageList = [];
    },
    bottomScroll(state, action) {
      state.hasBottomScroll = action.payload;
    },
    removeChat(state, action) {
      state.chatsList = state.chatsList.filter((item: any) => item.id !== action.payload);
    },
    setChatId(state, action) {
      state.chatId = action.payload;
    },
    markChatMsgs(state, action) {
      state.msgCount = [...state.msgCount].filter((a: any) => +a !== +action.payload);
    },
    cleanAllChatVaues(state) {
      state.loading = false;
      state.chatsList = [];
      state.messageList = [];
      state.isMenuOpened = false;
      state.msgCount = [];
      state.hasMore = true;
      state.hasBottomScroll = false;
      state.selectId = 0;
      state.chatId = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllChats.fulfilled, (state, action) => {
        state.chatsList = action.payload.map((a: any) => {
          if (a.text) {
            return a;
          } else {
            return {
              ...a,
              text: '\t'
            }
          }
        });
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.hasMore = action.payload.hasMore;

        [...state.messageList]?.forEach((a: any, i: number) => {
          if (i === 0) {
            state.selectId = a.id;
          }
        });

        const arr = [] as number[];

        const filtredArr = [...action.payload.messages, ...state.messageList]?.map((a: any) => {
          if (!arr.includes(a?.id)) {
            arr.push(a?.id);
            if (a.id === state.selectId) {
              return {
                ...a,
                isFirst: true
              }
            } else {
              return {
                ...a,
                isFirst: false
              }
            }
          }
        }).filter((a: any) => a?.id);

        state.messageList = filtredArr;
      })
      .addCase(getMsgCount.fulfilled, (state, action) => {
        state.msgCount = action.payload;
      })
  },
});

export const {
  showLoader,
  addNewMessage,
  setMenuOpenStatus,
  increaseCountOfMsgs,
  createNewChat,
  cleanMsgs,
  cleanHasMore,
  bottomScroll,
  removeChat,
  setChatId,
  markChatMsgs,
  cleanAllChatVaues
} = chatSlice.actions;
export default chatSlice.reducer;