import React, { Dispatch, SetStateAction } from 'react';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './PlayGame.scss';
import useWindowDimensions from '../../../../hooks/widthHook';

interface BookReaderProps {
  gameId: number | string;
  gameName: string;
  setPlayGame: Dispatch<SetStateAction<boolean>>;
  setGamesModal: Dispatch<SetStateAction<boolean>>;
}

function PlayGame({
  gameId,
  gameName,
  setPlayGame,
  setGamesModal,
}: BookReaderProps) {
  const { width } = useWindowDimensions();

  return (
    <>
      <ModalHeader
        mainColor
        withPadding
        textHeader={gameName}
        textStyles={{
          paddingLeft: '36px',
        }}
        onClose={() => {
          setGamesModal(false);
          setPlayGame(false);
        }}
      />
      <div className="PlayGame__container">
        <ArrowBackIcon
          className="arrowBack"
          onClick={() => setPlayGame(false)}
        />
        {typeof gameId === 'number' ? (
          <iframe
            className="PlayGame__iframe"
            title={gameName}
            src={`https://www.addictinggames.com/embed/html5-games/${gameId}`}
            scrolling="no"
          />
        ) : (
          <iframe
            className="PlayGame__iframe"
            title={gameName}
            src={`https://playpager.com/embed/${gameId}/index.html`}
            scrolling="no"
          />
        )}
      </div>
    </>
  );
}

export default PlayGame;
