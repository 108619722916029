import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';

import { getAllChats, setChatId, setMenuOpenStatus } from '../../redux/slices/chatSlice';
import { setOnRelaxRoom } from '../../redux/slices/relaxSlice';

import Header from '../../components/Header/Header';
import ErrorBoundary from '../../components/ErrorBoundary';
import useWindowDimensions from '../../hooks/widthHook';
import Messages from './Messages';
import ChatList from './ChatList';

import relaxRoom from '../../images/relax-room.png';
import './Chat.scss';
import { getSomeUserInfo } from '../../redux/slices/someUserSlice';

export default function Chat() {
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams() || '';
  const userId = searchParams.get('id') || '';

  const { isMenuOpened, chatsList } = useSelector((state: RootState) => state.chat);

  useEffect(() => {
    dispatch(getSomeUserInfo(userId))

    return () => {
      dispatch(setChatId(null));
      dispatch(setMenuOpenStatus(false));
    }
  }, []);

  useEffect(() => {
    if (!userId) {
      dispatch(getAllChats()); 
    }
  }, [userId])

  return (
    <div className="bg-container">
      <Header />
      <div 
        className="page-container"
        style={{
          maxWidth: '100%',
          height: '100vh',
        }}
      >
        <section 
          className='main-page'
          style={{
            height: '100%'
          }}
        >
            <ErrorBoundary forHomePage>
              <div className="Chat__main" style={{
                visibility: chatsList?.length ? 'visible' : 'hidden'
              }}>
                <div 
                  className={`Chat__chatList-wrapper ${isMenuOpened && 'Chat__chatList-wrapper--open'}`}
                >
                  <ChatList/>
                </div>
                <Messages/>
                {width >= 1100 && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    replace={false}
                    to="/relax-room"
                    onClick={() => dispatch(setOnRelaxRoom(true))}
                  >
                    <img src={relaxRoom} alt="relax-room" />
                  </Link>
                )}
              </div>
              <div 
                className="Chat__empty" 
                style={{
                  visibility: chatsList?.length ? 'hidden' : 'visible'
                }}
              >
                <h2 className="Chat__empty-title">You have no chats yet</h2>
              </div>
            </ErrorBoundary>
        </section>
      </div>
    </div>
  )
}
