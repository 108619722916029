import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import useWindowDimensions from '../../../hooks/widthHook';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import {
  getGroupsProfileInfo,
  setTypeId,
} from '../../../redux/slices/groupsSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { useNavigateToTop } from '../../helpers/useNavigateToTop';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import './AccordionRow.scss';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square {...props}
  />
))(({ theme }) => ({
  borderBottom: "0px solid #E9E9E9",
  "&:last-child": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(135deg)"
  },
  // "& .MuiAccordionSummary-content": {
  // marginLeft: theme.spacing(1)
  // paddingLeft: theme.spacing(1)
  // }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

interface Details {
  id: string,
  title: string,
  url: string,
}

interface Options {
  id: number,
  title: string,
  details: Details[] | Chip[] | string,
  img?: string,
  typeId?: string,
}

type Props = {
  inpData: Options[],
  titlePadLeft: number,
  titleFSZ: string,
  titleFW: string,
  titleColor: string,
  detailsFSZ: string,
  detailsColor: string,
  deteilsPad: string,
  isWithBorder?: boolean,
  setAddNewTag?: React.Dispatch<React.SetStateAction<SelectedInterestType>>;
  setArchiveTag?: React.Dispatch<React.SetStateAction<TagForArchive>>;
  setEditTag?: React.Dispatch<React.SetStateAction<TagForEdit>>;
  expandedDefault?: boolean,
  eyeIcon?: boolean,
  setShowBurger?: (arg: boolean) => any
}

export const AccordionRow: React.FC<Props> = ({
  expandedDefault,
  inpData,
  titlePadLeft,
  titleFSZ,
  titleFW,
  titleColor,
  detailsFSZ,
  detailsColor,
  deteilsPad,
  isWithBorder,
  eyeIcon,
  setEditTag = () => { },
  setAddNewTag = () => { },
  setArchiveTag = () => { },
  setShowBurger
}: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>('');
  const { isAdmin } = useSelector((state: RootState) => state.profile);
  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const { width: windowWidth } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  const [expandedArray, setExpandedArray] = React.useState(inpData.map(item => item.id));

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    if (expandedDefault) {
      const arr = expandedArray
      const filteredArray = arr.filter(item => item !== +panel)
      setExpandedArray(newExpanded ? [...arr, +panel] : filteredArray)
    } else {
      setExpanded(newExpanded ? panel : false);
    }
  };

  return (
    <div
      className={`accordion-main${isWithBorder ? ' accordion-main--border' : ''}`}
    >
      {inpData.map((option: Options) => (
        <div
          className="accordion-main-rows"
          key={option.id}
        >
          <Accordion
            expanded={expandedDefault ? expandedArray.includes(option.id) : expanded === `${option.id}`}
            onChange={handleChange(`${option.id}`)}
          >
            <AccordionSummary
              sx={{
                paddingLeft: `${option.img ? '0px' : '16px'}`,

                "& .MuiAccordionSummary-content": {
                  flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                },
              }}
            >
              <div
                className="accordion-main__icon-container"
                style={{
                  left: windowWidth >= 1100 ? (lan === 'ar' ? '18px' : 'none') : (lan === 'ar' ? '30px' : ''),
                  right: windowWidth >= 1100 ? (lan === 'ar' ? 'none' : '10px') : (lan === 'ar' ? '' : '30px')
                }}
              >
                {eyeIcon ? (
                  <div className="accordion-main__icon">
                    <VisibilityOffIcon
                      style={{
                        opacity: `${expandedDefault ? expandedArray.includes(option.id) ? 1 : 0 : +expanded !== option.id ? 0 : 1}`,
                      }}
                    />
                    <VisibilityIcon 
                      style={{
                        opacity: `${expandedDefault ? expandedArray.includes(option.id) ? 0 : 1 : +expanded !== option.id ? 1 : 0}`,
                      }}
                    />
                  </div>
                ) : (
                  <div className="accordion-main__icon"
                    style={{
                      transform: `rotateZ(${(expandedDefault ? expandedArray.includes(option.id) : +expanded === option.id) ? '180deg' : '0'})`
                    }}
                  >
                    <RemoveIcon
                      style={{
                        opacity: `${expandedDefault ? expandedArray.includes(option.id) ? 1 : 0 : +expanded !== option.id ? 0 : 1}`,
                      }}
                    />

                    <AddIcon
                      style={{
                        opacity: `${expandedDefault ? expandedArray.includes(option.id) ? 0 : 1 : +expanded !== option.id ? 1 : 0}`,
                      }}
                    />
                  </div>
                )}

              </div>

              {option.img &&
                <img
                  src={option.img}
                  alt="img"
                  style={{ paddingLeft: '6px' }}
                />}

              <div
                className="accordion-main__title-container"
                style={{
                  maxWidth: (windowWidth >= 1100 && windowWidth < 1440) ? '180px' : 'none'
                }}
              >
                <Typography
                  sx={{
                    paddingLeft: `${titlePadLeft}px`,
                    fontWeight: `${titleFW}`,
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                    fontSize: `${titleFSZ}`,
                    lineHeight: '25px',
                    color: `${titleColor}`,
                    textAlign: lan === 'ar' ? 'right' : 'left',
                    padding: windowWidth > 1100 ? (lan === 'ar' ? '0 8px 0 0' : '0 0 0 8px') : (lan === 'ar' ? '0 8px 0 40px' : '0 40px 0 8px')
                  }}
                >
                  {option.title}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: `${deteilsPad}`,
                fontSize: `${detailsFSZ}`,
                lineHeight: '130%',
                color: `${detailsColor}`,
                fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
              }}
            >
              {(isAdmin && (option.typeId === 'interestId')) ?
                <SecondaryButton
                  text={formatMessage({ id: 'addNewTag' })}
                  onClick={() => setAddNewTag({
                    type: option.title === 'Interest Areas' ?
                      'DEFAULT' : 'REGIONAL',
                    status: true,
                  })}
                /> : null}
              <ul>
                {(typeof option.details === 'object') ? (
                  option.details
                    .map((item: any) => (
                      <li
                        className='accordion-main__item'
                        key={item.id}
                        onClick={() => {
                          // dispatch(setGroups(item))
                          dispatch(setTypeId(option.typeId))
                          dispatch(getGroupsProfileInfo({ [option.typeId || 'interestId']: item.id + '' }))
                          navigateToTop(`/groups?${option.typeId}=${item.id}`)
                          if (setShowBurger) {
                            setShowBurger(false);
                          }
                        }}
                        style={{
                          display: 'flex',
                          flexDirection: (lan === 'ar') ? 'row-reverse' : 'row',
                          textAlign: (lan === 'ar') ? 'right' : 'left',
                          paddingRight: (isAdmin && (option.typeId === 'interestId')) ?
                            '70px' : '40px',
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'contain',
                            width: '28px',
                            height: '28px',
                          }}
                          src={item.avatar || item.url}
                          alt="img"
                        />
                        <Typography
                          sx={{
                            fontSize: `${detailsFSZ}`,
                            lineHeight: '130%',
                            color: `${detailsColor}`,
                            fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                          }}
                        >
                          {item.title}
                        </Typography>
                        {(isAdmin && (option.typeId === 'interestId')) ?
                          <button
                            className="accordion-main__button-archive"
                            onClick={(e) => {
                              e.stopPropagation();
                              setArchiveTag({
                                tagId: item.id,
                                status: true,
                              })
                            }}>
                            <ArchiveTwoToneIcon />
                          </button>
                          : null}

                        {(isAdmin && (option.typeId === 'typeId' ?
                          item.id == 1 || item.id == 2 || item.id == 3 || item.id == 4 ? false : true
                          : true)) ?
                          <button
                            className="accordion-main__button-edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (option?.typeId !== undefined) {
                                console.log({
                                  tagId: item.id,
                                  status: true,
                                  type: option.typeId
                                })
                                setEditTag({
                                  tagId: item.id,
                                  status: true,
                                  type: option.typeId
                                })
                              }
                            }}>
                            <EditTwoToneIcon />
                          </button>
                          : null}

                      </li>
                    ))
                ) : (
                  <Typography>
                    {option.details}
                  </Typography>
                )}
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      ))
      }
    </div >
  );
}
