import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../hooks/widthHook';
import { useNavigateToTop } from '../../../components/helpers/useNavigateToTop';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import {
  followUserById,
  setBlockedUsers,
} from '../../../redux/slices/profileSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { useIntl } from 'react-intl';
import { isUserBlocked } from '../../../components/helpers/isUserBlocked';
import { getBlockedUsersAPI, UnBlockUser } from '../../../api/user';

interface Props {
  follower: {
    id: string;
    profileAvatar: string;
    firstName: string;
    lastName: string;
    organizationName: string;
    about: string;
    country: string;
    region: string;
    showLocation: boolean;
  };
}

const SearchedFollower = ({ follower }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigateToTop = useNavigateToTop();
  const {
    following,
    id: profileId,
    blockedUsers,
  } = useSelector((state: RootState) => state.profile);
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  // ===== to follow/unfollow user =====
  const followUserFunc = (id: any) => {
    dispatch(followUserById(id));
  };

  // console.log('1', follower);
  // console.log(following);

  return (
    <>
      <div
        className="following__wrapper"
        key={follower.id}
        style={{
          flexDirection:
            width < 1100 ? 'column' : lan === 'ar' ? 'row-reverse' : 'row',
          alignItems:
            width < 1100
              ? lan === 'ar'
                ? 'flex-end'
                : 'flex-start'
              : 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
          }}
        >
          <div
            className="followers__following-avatar-container"
            style={{
              margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0',
            }}
          >
            <img
              className="followers__following-avatar"
              src={follower?.profileAvatar}
              alt=""
            />
          </div>

          <div className="following__titleWrapper">
            <div onClick={() => navigateToTop(`/profile/${follower.id}`)}>
              <h2 className="following__title">
                {`${follower?.organizationName}` ||
                  `${follower?.firstName} ${follower?.lastName}`}
              </h2>
            </div>
            {follower?.showLocation && (
              <h4 className="following__subtitle">
                {follower?.region.trim().length > 0 && follower?.region !== ''
                  ? `${follower?.region}, `
                  : ''}
                {follower?.country}
              </h4>
            )}
          </div>
        </div>

        {follower.id !== profileId &&
          !isUserBlocked(blockedUsers, follower.id) && (
            <div className="following__btnWrapper">
              {following?.following?.some((item: any) => item.id === follower.id) ? (
                <div
                  className="follower__btnCheckWrapper"
                  style={{
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                    gap: lan === 'ar' ? '50px' : '0',
                  }}
                >
                  <button
                    className="follower__btnCheck follower__btnCheckAfter"
                    style={{
                      right: lan === 'ar' && width < 1100 ? 'none' : '10px',
                      left: lan === 'ar' && width < 1100 ? '10px' : 'none',
                    }}
                  >
                    {formatMessage({ id: 'following' })}
                  </button>
                  <SecondaryButton
                    text={formatMessage({ id: 'unfollow' })}
                    type="button"
                    onClick={() => {
                      followUserFunc(follower);
                    }}
                  />
                </div>
              ) : (
                <MainButton
                  text={formatMessage({ id: 'follow' })}
                  type="button"
                  onClick={() => {
                    followUserFunc(follower);
                  }}
                />
              )}
            </div>
          )}

        {isUserBlocked(blockedUsers, follower.id) && (
          <div className="unblock-btn search">
            <SecondaryButton
              text={formatMessage({ id: 'unblock' })}
              onClick={() => {
                UnBlockUser({ userId: follower.id }).then(() => {
                  getBlockedUsersAPI().then(res =>
                    dispatch(setBlockedUsers(res.data)),
                  );
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchedFollower;
