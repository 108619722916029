import React from 'react';
import SignInPage from '../pages/auth/SignInPage/SignInPage';
import SignUpPage from '../pages/auth/SignUpPage/SignUpPage';
import SignUpEmailPage from '../pages/auth/SignUpEmailPage/SignUpEmailPage';
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage/ForgotPasswordPage';
import NewPasswordPage from '../pages/auth/NewPasswordPage/NewPasswordPage';
import TermsOfUsePage from '../pages/auth/TermsOfUsePage/TermsOfUsePage';
import PrivacyPolicyPage from '../pages/auth/PrivacyPolicyPage/PrivacyPolicyPage';
import HomePage from '../pages/HomePage/HomePage';
import MyProfilePage from '../pages/MyProfilePage/MyProfilePage';
import FaqPage from '../pages/FaqPage/FaqPage';
import ActivityLogPage from '../pages/ActivityLogPage/ActivityLogPage';
import AccountSettingsPage from '../pages/AccountSettingsPage/AccountSettingsPage';
import GroupsPage from '../pages/GroupsPage/GroupsPage';
import SearchPage from '../pages/SearchPage/SearchPage';
import RelaxRoomPage from '../pages/RelaxRoomPage/RelaxRoomPage';
import { ProfilesPage } from '../pages/ProfilesPage/ProfilesPage';
import { PostReportPage } from '../pages/PostReportPage/PostReportPage';
import SearchUsersPage from '../pages/SearchUsersPage/SearchUsersPage';
import ReportedCommentsPage from '../pages/ReportedCommentsPage/ReportedCommentsPage';
import PostPage from '../pages/PostPage/PostPage';
import Chat from '../pages/Chat';

export const privateMainRoutes = [
  {
    name: 'home',
    path: '/home',
    element: <HomePage />,
  },
  {
    name: 'profile',
    path: '/profile/:someUserId',
    element: <MyProfilePage />,
  },
  {
    name: 'post',
    path: '/post/:somePostId',
    element: <PostPage />,
  },
  {
    name: 'chat',
    path: '/chat',
    element: <Chat />,
  },
  {
    name: 'activity-log',
    path: '/activity-log',
    element: <ActivityLogPage />,
  },
  {
    name: 'faq',
    path: '/faq',
    element: <FaqPage />,
  },
  {
    name: 'account-settings',
    path: '/account-settings',
    element: <AccountSettingsPage />,
  },
  {
    name: 'groups',
    path: '/groups',
    element: <GroupsPage />,
  },
  {
    name: 'search',
    path: '/search',
    element: <SearchPage />,
  },
  {
    name: 'relax-room',
    path: '/relax-room',
    element: <RelaxRoomPage />,
  },
];

export const publicMainRoutes = [
  {
    name: 'sign-in',
    path: '/sign-in',
    element: <SignInPage />,
  },
  {
    name: 'sign-up',
    path: '/sign-up',
    element: <SignUpEmailPage />,
  },
  {
    name: 'sign-up-email',
    path: '/sign-up-email',
    element: <SignUpPage />,
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    name: 'password-recovery',
    path: '/auth/password-recovery/*',
    element: <NewPasswordPage />,
  },
  {
    name: 'terms-of-use',
    path: '/terms-of-use',
    element: <TermsOfUsePage />,
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />,
  },
];

export const adminMainRoutes = [
  {
    name: 'home',
    path: '/home',
    element: <HomePage />,
  },
  {
    name: 'profile',
    path: '/profile/:someUserId',
    element: <MyProfilePage />,
  },
  {
    name: 'profiles',
    path: '/profiles',
    element: <ProfilesPage />,
  },
  {
    name: 'posts',
    path: '/posts',
    element: <PostReportPage />,
  },
  {
    name: 'search',
    path: '/search-users-page',
    element: <SearchUsersPage />,
  },
  {
    name: 'chat',
    path: '/chat',
    element: <Chat />,
  },
  {
    name: 'faq',
    path: '/faq',
    element: <FaqPage />,
  },
  {
    name: 'account-settings',
    path: '/account-settings',
    element: <AccountSettingsPage />,
  },
  {
    name: 'groups',
    path: '/groups',
    element: <GroupsPage />,
  },
  {
    name: 'reropted-comments',
    path: '/reropted-comments',
    element: <ReportedCommentsPage />
  },
  {
    name: 'relax-room',
    path: '/relax-room',
    element: <RelaxRoomPage />,
  },
];
