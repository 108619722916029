import { IconButton, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './MoreCommentsButton.scss';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FlagTwoToneIcon from '@mui/icons-material/FlagTwoTone';
import ModalWrapper from '../../../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../../../ModalConstructor/ModalFooter/ModalFooter';
import { deleteComment, getTranslation } from '../../../../redux/slices/postsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import SecondaryButton from '../../../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../../../shared/Buttons/MainButton/MainButton';
import { getCommentsAPI, getPostApi, reportComment } from '../../../../api/posts';
import CustomInput from '../../../shared/CustomInput/CustomInput';
import { useIntl } from 'react-intl';
import ReportModal from '../../../shared/ReportModal/ReportModal';

interface Props {
  comment: Comment;
  translatedText: boolean;
  setTranslatedText: (arg: boolean) => any,
  setEditingComment: (editComment: boolean) => void;
}

export const MoreCommentsButton = ({
  comment,
  translatedText,
  setTranslatedText,
  setEditingComment = () => {},
}: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { id: userId, anotherUserId } = useSelector((state: RootState) => state.profile);

  // ========Same user check===========
  const [sameUserId, setSameUserId] = useState(false);

  useEffect(() => handleClose(), [translatedText]);
  useEffect(() => {
    if (comment?.owner?.id === userId || comment.owner.id === anotherUserId.user) {
      setSameUserId(true);
    }
  }, [anotherUserId, userId]);

  // ==========Report==================
  const [reportModal, setReportModal] = useState(false);
  // const [reportReason, setReportReason] = useState('');

  // const handleReport = () => {
  //   reportComment({ commentId: comment.id, reason: reportReason });
  //   setReportModal(false);
  // };

  // ============Popover================
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const htmlEl = document.getElementsByTagName("html")[0];

  useEffect(() => {
    if (anchorEl && htmlEl) {
      htmlEl.style.overflow = 'hidden';
    } else {
      htmlEl.style.overflow = 'auto';
    }
  }, [anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // ============Edit=================
  const handleEditComment = () => {
    setEditingComment(true);
  };

  // ============Delete===============
  const bodyDelete = {
    commentId: +comment.id,
  } as any;

  const handleDeleteComment = () => {
    setDeleteModal(false);

    if (anotherUserId.user) {
      bodyDelete.anotherUserId = anotherUserId.user
    };

    dispatch(deleteComment(bodyDelete));
  };

  return (
    <>
      <div
        className="post__header-more-btn-container"
        aria-describedby={id}
        onClick={ev => {
          setAnchorEl(ev.currentTarget);
        }}
      >
        <IconButton color="secondary">
          <MoreHorizIcon className="post__header-more-btn" />
        </IconButton>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <div>
          <div className="post__popover-container">
            {sameUserId ? (
              <>
                <div className="post__popover-row" onClick={handleEditComment}>
                  <EditTwoToneIcon
                    className="post__popover-img"
                    color="action"
                  />
                  <span>{formatMessage({id: 'edit'})}</span>
                </div>

                <div
                  className="post__popover-row"
                  onClick={e => {
                    e.stopPropagation();
                    handleClose();
                    setDeleteModal(true);
                  }}
                >
                  <svg
                    className="post__popover-img"
                    width="24"
                    height="24"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      opacity="0.3"
                      d="M6 7.25H12V14.75H6V7.25Z"
                      fill="#6C757D"
                    />
                    <path d="M11.625 3.5L10.875 2.75H7.125L6.375 3.5H3.75V5H14.25V3.5H11.625ZM4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6 7.25H12V14.75H6V7.25Z" />
                  </svg>
                  <span>{formatMessage({id: 'delete'})}</span>
                </div>
              </>
            ) : (
              <div
                className="post__popover-row"
                onClick={() => {
                  handleClose();
                  setReportModal(true);
                }}
              >
                <FlagTwoToneIcon className="post__popover-img" color="action" />
                <span>{formatMessage({id: 'report'})}</span>
              </div>
            )}
            <div
              className="post__popover-row"
              onClick={() => {setTranslatedText(!translatedText)}}
            >
              <LanguageTwoToneIcon className="post__popover-img" color='action' />
              <span>{translatedText ? formatMessage({id: 'origText'}) : formatMessage({id: 'translate'})}</span>
            </div>
          </div>
        </div>
      </Popover>

      {deleteModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              textHeader={formatMessage({id: 'deleteCommentConfirm'})}
              smallCross
              onClose={() => setDeleteModal(false)}
            />
            <ModalFooter>
              <SecondaryButton
                text={formatMessage({id: 'delete'})}
                onClick={handleDeleteComment}
                bigPadding
              />
              <MainButton
                text={formatMessage({id: 'cancel'})}
                onClick={() => setDeleteModal(false)}
                bigPadding
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}
      {reportModal && (
        <ReportModal
          commentId={comment.id}
          setReportModal={setReportModal}
        />
        // <ModalWrapper width={350}>
        //   <ModalContainer>
        //     <ModalHeader
        //       textHeader={formatMessage({id: 'reportConfirm'})}
        //       smallCross
        //       onClose={() => setReportModal(false)}
        //     />
        //     <CustomInput
        //       width="100%"
        //       placeholder={formatMessage({id: 'reportReason'})}
        //       label={formatMessage({id: 'reportReasonLabel'})}
        //       onChangeFunc={(value: string) => {
        //         setReportReason(value);
        //       }}
        //     />
        //     <ModalFooter>
        //       <SecondaryButton
        //         text={formatMessage({id: 'report'})}
        //         onClick={handleReport}
        //         bigPadding
        //       />
        //       <MainButton
        //         text={formatMessage({id: 'cancel'})}
        //         onClick={() => setReportModal(false)}
        //         bigPadding
        //       />
        //     </ModalFooter>
        //   </ModalContainer>
        // </ModalWrapper>
      )}
    </>
  );
};
