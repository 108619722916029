import { createIntl } from 'react-intl';
import en from './languages/en.json';
import fr from './languages/fr.json';
import es from './languages/es.json';
import sw from './languages/sw.json';
import pt from './languages/pt.json';
import ar from './languages/ar.json';
import hi from './languages/hi.json';

interface Localization {
  [key: string]: Record<string, string>;
}

const localization: Localization = {
  en,
  fr,
  es,
  sw,
  pt,
  ar,
  hi,
};

export default localization;

export const intl = (() => {
  const initialLocale = 'en';
  let intl = createIntl({
    locale: initialLocale,
    messages: localization[initialLocale],
  });

  return {
    setLocale(locale: string) {
      intl = createIntl({ locale, messages: localization[locale] });
    },
    intl() {
      return intl;
    },
  };
})();
