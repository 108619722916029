export const getBrowserName = (userAgent) => {
  if (userAgent.includes('Firefox')) {
    return 'Mozilla Firefox';
  } else if (userAgent.includes('SamsungBrowser')) {
    return 'Samsung Internet';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    return 'Opera';
  } else if (userAgent.includes('Trident')) {
    return 'Microsoft Internet Explorer';
  } else if (userAgent.includes('Edge')) {
    return 'Microsoft Edge (Legacy)';
  } else if (userAgent.includes('Edg')) {
    return 'Microsoft Edge (Chromium)';
  } else if (userAgent.includes('Chrome')) {
    return 'Google Chrome or Chromium';
  } else if (userAgent.includes('Safari')) {
    return 'Apple Safari';
  } else {
    return 'unknown';
  }
}
