import { Following } from './../../components/Wall/Following/Following';
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setToken } from '../../api/api';
import { showLoader } from './appSlice';
import { defaultLanguage } from '../../constants/language';
import { toast } from 'react-toastify';
import { intl } from '../../localization';
// import { getSomeUserApi } from '../../api/someUser';
import {
  changeLanguageAPI,
  deactivateAccountAPI,
  followUserByIdAPI,
  getFollowersAPI,
  getFollowingAPI,
  getUserInfoAPI,
} from '../../api/user';
import {
  addSomeUserFollower,
  decreaseFollowing,
  increaseFollowing,
  removeSomeUserFollower,
  // setFollowersSomeUser,
  // setSearchValue,
  // setUserInfo,
  increaseSomeUserFollow,
  decreaseSomeUserFollow,
  remoweWallFollower,
  addWallFollower,
  // updateSomeUserFollowers,
} from './someUserSlice';

interface IProfileState {
  id: string;
  language: LanguageType;

  email: string;
  isAuth: boolean;
  isAdmin: boolean;

  isOrganization: false;
  organizationName: string;
  firstName: string;
  lastName: string;
  birthYear: string;

  showLocation: boolean;
  country: string;
  region: string;

  titles: Array<string>;
  otherTitle: string;

  interests: Chip[];
  profileAvatar: string;
  profileBg: string;
  aboutMe: string;

  notification: {
    posts: boolean;
    activity: boolean;
    additions: boolean;
    surveys: boolean;
  };

  activityLog: {
    showComments: boolean;
    showMentions: boolean;
    showReactions: boolean;
  };

  privacy: {
    showFollowers: boolean;
    showFollowing: boolean;
    showInterestedTags: boolean;
  };

  ads: boolean;

  followers: {
    followers: Followers[];
    isLocked: boolean;
  };

  following: {
    following: Following[];
    isLocked: boolean;
  };

  blockedUsers: { id: string }[];

  anotherUserId: any;

  onlineStatus: any[];
}

// =============Followers==================================
export const getFollowers = createAsyncThunk(
  'profile/getFollowers',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const response = await getFollowersAPI(data);
      const { skip } = data;
      const newData = {
        skip,
        response: response.data.followers,
      };
      dispatch(setFollowers(newData));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// =============Following==================================
export const getFollowing = createAsyncThunk(
  'profile/getFollowing',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const response = await getFollowingAPI(data);
      const { skip } = data;
      const newData = {
        skip,
        response: response.data.following,
      };
      dispatch(setFollowing(newData));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// ===== to follow / unfollow user by id =====
export const followUserById = createAsyncThunk(
  'profile/followUserById',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));

    try {
      const response = await followUserByIdAPI(data.id);
      if (response.status === 200) {
        if (response.data === 'Remove') {
          if (data.page && data.page === 'someProfileWall') {
            dispatch(remoweWallFollower(data.personalId));
            dispatch(removeFollowing(data.id));
          } else if (data.page && data.page === 'someProfile') {
            dispatch(decreaseSomeUserFollow(data.id));
          } else {
            dispatch(removeFollowing(data.id));
            dispatch(decreaseFollowing(data.id));
            
            dispatch(removeSomeUserFollower(data.id));
          }
        } else {
          if (data.page && data.page === 'someProfileWall') {
            dispatch(addWallFollower(data.personalId));
            dispatch(addFollowing(data));
          } else if (data.page && data.page === 'someProfile') {
            dispatch(increaseSomeUserFollow(data.id));
          } else {
            dispatch(addFollowing(data));
            dispatch(increaseFollowing(data.id));
            dispatch(addSomeUserFollower(data));
          }
        }
        // const userInfo = await getSomeUserApi(data);
        // dispatch(setUserInfo(userInfo));
        // dispatch(setSearchValue(data));
        // const responseFollowers = await getFollowersAPI(data);
        // // dispatch(setFollowersSomeUser(responseFollowers.data));
        getUserInfoAPI().then(res => {
          if (res.status === 200) {
            dispatch(
              setAccountSettings({
                ...res.data._user,
                following: {
                  following: res.data.following?.map(
                    (item: any) => item.following,
                  ),
                  isLocked: false,
                },
              }),
            );
          }
        });
      }
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// ===== change user language =====
export const changeLanguage = createAsyncThunk(
  'profile/changeLanguage',
  async function (language: string, { rejectWithValue, dispatch }) {
    const { formatMessage } = intl.intl();

    try {
      if (language as LanguageType) {
        const response = await changeLanguageAPI(language);

        if (response.status !== 201) {
          toast.error(formatMessage({ id: 'serverError' }));
        }
        return response.data;
      }
      return 'en';
    } catch (error: any) {
      toast.error(formatMessage({ id: 'serverError' }));
    }
  },
);

// ===== deactivate acount =====
export const deactivateAccount = createAsyncThunk(
  'profile/deactivateAccount',
  async function (_, { rejectWithValue, dispatch }) {
    const { formatMessage } = intl.intl();

    try {
      const response = await deactivateAccountAPI();
      if (response.status === 200) {
        toast.success(formatMessage({ id: 'deactivateAccountRequest' }));
      }
      return response.data;
    } catch (error: any) {
      toast.error(formatMessage({ id: 'serverError' }));
    }
  },
);

const initialState: IProfileState = {
  id: '',
  language: defaultLanguage,

  email: '',
  isAuth: false,
  isAdmin: false,

  isOrganization: false,
  organizationName: '',

  firstName: '',
  lastName: '',

  aboutMe: '',
  birthYear: '',

  showLocation: false,
  country: '',
  region: '',

  titles: [],
  otherTitle: '',

  interests: [],

  profileAvatar: '',
  profileBg: '',

  notification: {
    posts: false,
    activity: false,
    additions: false,
    surveys: false,
  },

  activityLog: {
    showComments: false,
    showMentions: false,
    showReactions: false,
  },

  privacy: {
    showFollowers: true,
    showFollowing: true,
    showInterestedTags: true,
  },

  ads: false,

  followers: {
    followers: [],
    isLocked: false,
  },

  following: {
    following: [],
    isLocked: false,
  },

  blockedUsers: [],

  anotherUserId: {},

  onlineStatus: [],
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setAccountSettings(state, action) {

      const data = action.payload;
      state.id = data.id;

      if (data?.language) {
        state.language = data.language || 'en';
        localStorage.setItem('lang', data.language || 'en');
        // document.dir = data.language === 'ar' ? 'rtl' : 'ltr'; //TODO
        intl.setLocale(data.language || 'en');
      }

      state.email = data.email;
      state.isAuth = true;
      state.isAdmin = data.isAdmin; // <<<-------------------------------------

      if (data.token) {
        setToken(data.token);
      }

      state.isOrganization = data.isOrganization;
      state.organizationName = data.organizationName;
      state.firstName = data.firstName;
      state.lastName = data.lastName;

      state.birthYear = data.year;
      state.showLocation = data.showLocation;
      state.country = data.country;
      state.region = data.region;

      state.titles = data.titles;
      state.otherTitle = data.otherTitle;

      if (data?.interests) {
        state.interests = data.interests;
      }

      state.profileAvatar = data.profileAvatar;
      state.profileBg = data.profileBg;

      state.aboutMe = data.about;

      state.notification = data.notification;
      state.activityLog = data.activityLog;
      state.privacy.showFollowers = data.privacy?.showFollowers;
      state.privacy.showFollowing = data.privacy?.showFollowing;
      state.privacy.showInterestedTags = data.privacy.showInterestedTags || data.privacy?.showInterested;
      state.ads = data?.ads;
      if (data?.followers) {
        state.followers = data?.followers;
      }
      if (data.following) {
        state.following = data?.following;
      }
    },
    profileLogout(state) {
      state.isAuth = false;
      state.isAdmin = false;
      localStorage.removeItem('token');
      state.anotherUserId = {};
    },
    setAnotherUserId(state, action) {
      state.anotherUserId = action.payload;
    },
    setFollowers(state, action) {
      const { skip, response } = action.payload;

      console.log(response);
      if (skip === 0) {
        state.followers.followers = response;
      } else {
        state.followers.followers = [...state.followers.followers, ...response];
      }
    },
    setFollowing(state, action) {
      const { skip, response } = action.payload;
      if (skip === 0) {
        state.following.following = response;
      } else {
        state.following.following = [...state.following.following, ...response];
      }
    },
    addFollowing(state, action) {
      const newState = state?.followers?.followers?.map(a => {
        if (a.id === action.payload.id) {
          return {
            ...a,
            ifFollow: true,
          };
        } else {
          return a;
        }
      });

      state.followers.followers = newState;
      state.following.following = [
        ...state.following.following,
        action.payload,
      ];
    },
    removeFollowing(state, action) {
      const newState = state.followers.followers?.map(a => {
        if (a.id === action.payload) {
          return {
            ...a,
            ifFollow: false,
          };
        } else {
          return a;
        }
      });
      state.followers.followers = newState;
      state.following.following = state.following.following.filter(
        el => el.id !== action.payload,
      );
    },
    onChangeLanguage(state: IProfileState, action) {
      state.language = action.payload;
      localStorage.setItem('lang', action.payload);
      // document.dir = action.payload === 'ar' ? 'rtl' : 'ltr'; //TODO
      intl.setLocale(action.payload);
    },
    setBlockedUsers(state, action) {
      state.blockedUsers = action.payload;
    },
    setOnlineStatus(state, action) {
      state.onlineStatus = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      changeLanguage.fulfilled,
      (state: IProfileState, action: AnyAction) => {
        state.language = action.payload.language;
        localStorage.setItem('lang', action.payload.language);
        // document.dir = action.payload.language === 'ar' ? 'rtl' : 'ltr'; // TODO
        intl.setLocale(action.payload.language);
      },
    );
  },
});

export const {
  setAccountSettings,
  profileLogout,
  setFollowers,
  setFollowing,
  addFollowing,
  removeFollowing,
  onChangeLanguage,
  setBlockedUsers,
  setAnotherUserId,
  setOnlineStatus,
} = profileSlice.actions;

export default profileSlice.reducer;
