import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import {
  getComments,
  postReaction,
  getPostShared,
  getPostReactions,
} from '../../../redux/slices/postsSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { reactions } from '../../../constants/reactions';
import useWindowDimensions from '../../../hooks/widthHook';
import { useNavigateToTop } from '../../helpers/useNavigateToTop';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import InsertCommentTwoToneIcon from '@mui/icons-material/InsertCommentTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplyIcon from '@mui/icons-material/Reply';
import './SetReactions.scss';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 15px #F2F2F2',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#363636',
  },
}));

const HtmlTooltipShares = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '6px 12px',
    backgroundColor: '#6C757D',
    boxShadow: '0px 4px 15px #F2F2F2',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#fff',
  },
}));

interface Props {
  smallReactions?: boolean;
  onlyComments?: boolean;
  post?: Post;
  reactionWidth?: number;
  writeComment?: boolean;
  footer?: boolean;
  insideModal?: boolean;
  page?: 'home' | 'profile' | 'saved' | 'groups' | 'report' | 'single';
}

export const SetReactions = ({
  smallReactions,
  onlyComments,
  post,
  reactionWidth,
  writeComment,
  footer,
  page,
  insideModal,
}: Props) => {
  const [selectedReaction, setSelectedReaction] = useState('');
  const [reactionsPeoples, setReactionsPeoples] = useState(false);
  const [shares, setShares] = useState(false);
  const { id, organizationName, firstName, lastName, profileAvatar, anotherUserId } =
    useSelector((state: RootState) => state.profile);

  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();

  const handleClick = async (elType: any, postId: number) => {
    setSelectedReaction(selectedReaction !== elType ? elType : '');
    let obj = {
      elType,
      postId,
      fromUser: {
        id: anotherUserId?.user ? anotherUserId?.user : id,
        organizationName: organizationName,
        firstName: firstName,
        lastName: lastName,
        profileAvatar: profileAvatar,
      },

    } as any;

    if (anotherUserId?.idOfPost === postId && anotherUserId?.user) {
      obj.anotherUserId = anotherUserId?.user
    };

    dispatch(
      postReaction(obj),
    );
  };

  useEffect(() => {
    post?.reactions &&
      post?.reactions?.forEach(reaction => {
        if (anotherUserId?.user?.length ? anotherUserId?.user === reaction.fromUser?.id : reaction.fromUser?.id === id) {
          setSelectedReaction(reaction.type);
        }
      });
    if (!post?.reactions?.length) {
      setSelectedReaction('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  return (
    <>
      <div className="post__set-reactions">
        {reactions.map(el => (
          <div
            key={el.id}
            className={`post__set-reaction-container${selectedReaction === el.type ?
              ' post__set-reaction-container--active' : ''}${smallReactions ?
                ' post__set-reaction-container--small' : ''}`}
            onClick={() => post ? handleClick(el.type, post?.id) : null}
          >
            <img className="post__set-reaction" src={el.img} alt="reaction" />
            <span className="post__set-reaction-text">
              {formatMessage({ id: el.nameKey })}
            </span>
          </div>
        ))}
      </div>

      <div className="post__cooperation">
        <div className="post__reactions">
          {post?.counter_reactions === 0 ? (
            <span className="post__no-reactions">
              {formatMessage({ id: 'noReactions' })}
            </span>
          ) : (
            <>
              <div
                className="post__reactions-images"
                onClick={() => setReactionsPeoples(true)}
              >
                {/* {post?.reactions[0] && post && post?.reactions?.length >= 1 && (
                  <div className="post__reactions-img-container">
                    <img
                      className="post__reactions-img"
                      src={`${reactions.find(el => el.type === post.reactions[0].type)
                        ?.img
                        }`}
                      alt=""
                    />
                  </div>
                )} */}

                {/* {post && post?.reactions?.length >= 2 && location.pathname !== '/home' && (
                  <div className="post__reactions-img-container">
                    <img
                      className="post__reactions-img"
                      src={`${reactions.find(el => el.type === post.reactions[1].type)
                        ?.img
                        }`}
                      alt=""
                    />
                  </div>
                )} */}

                {/* {post && post?.reactions?.length >= 3 && location.pathname !== '/home' && (
                  <div className="post__reactions-img-container">
                    <img
                      className="post__reactions-img"
                      src={`${reactions.find(el => el.type === post.reactions[2].type)
                        ?.img
                        }`}
                      alt=""
                    />
                  </div>
                )} */}
              </div>

              {post && post.counter_reactions > 0 && (
                <>
                  <HtmlTooltip
                    onFocus={() => { }}
                    onMouseEnter={(e: any) => {
                      if (
                        e.relatedTarget.window === undefined &&
                        // && (location.pathname === '/home' || location.pathname === '/groups')
                        post?.counter_reactions !== 0
                      ) {
                        dispatch(getPostReactions({
                          postId: post.id,
                          mobile: false
                        }));
                      }
                    }}
                    title={
                      <>
                        {post?.reactions?.length &&
                          post.reactions?.map((reaction, index) => (
                            <div className="post__tooltip-row" key={index}>
                              <img
                                src={`${reactions.find(
                                  el => el.type === reaction.type,
                                )?.img
                                  }`}
                                alt=""
                                style={{
                                  marginRight: '6px',
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                              <div>
                                {`${reaction?.fromUser?.organizationName}` ||
                                  `${reaction?.fromUser?.firstName} ${reaction?.fromUser?.lastName}`}
                              </div>
                            </div>
                          ))}
                      </>
                    }
                    // followCursor
                    enterDelay={1000}
                    // leaveDelay={400}
                    disableHoverListener={
                      post?.counter_reactions !== 0 ? width < 1100 : true
                    }
                  >
                    <span className="post__reactions-peoples">
                      {/* {post &&
                        post?.reactions?.length === 1 &&
                        location.pathname !== '/home' &&
                        (`${post.reactions[0].fromUser?.organizationName}` ||
                          `${post.reactions[0].fromUser?.firstName} ${post.reactions[0].fromUser?.lastName}`)}
                      {post &&
                        post?.reactions?.length === 2 &&
                        location.pathname !== '/home' &&
                        `${`${post.reactions[0].fromUser?.organizationName}` ||
                        `${post.reactions[0].fromUser?.firstName} ${post.reactions[0].fromUser?.lastName}`} ` +
                        `${formatMessage({ id: 'and' })} ${`${post.reactions[1].fromUser?.organizationName}` ||
                        `${post.reactions[1].fromUser?.firstName} ${post.reactions[1].fromUser?.lastName}`}`}
                      {post &&
                        post?.reactions?.length > 2 &&
                        location.pathname !== '/home' &&
                        `${`${post.reactions[0].fromUser?.organizationName}` ||
                        `${post.reactions[0].fromUser?.firstName} ${post.reactions[0].fromUser?.lastName}`}, ` +
                        `${`${post.reactions[1].fromUser?.organizationName}` ||
                        `${post.reactions[1].fromUser?.firstName} ${post.reactions[1].fromUser?.lastName}`}` +
                        ` ${formatMessage({ id: 'and' })} ${post.reactions?.length - 2} ${formatMessage({ id: 'others' })}`} */}

                      {post &&
                        post.counter_reactions >= 0 &&
                        `${post.counter_reactions} ${formatMessage({ id: 'reaction-s' })}`}

                      {/* {post &&
                        post.counter_reactions === 1 &&
                        // location.pathname === '/home' &&
                        (`${post.reactions[0].fromUser?.organizationName}` ||
                          `${post.reactions[0].fromUser?.firstName} ${post.reactions[0].fromUser?.lastName}`)}
                      {post &&
                        post.counter_reactions > 1 &&
                        // location.pathname === '/home' &&
                        `${`${post.reactions[0].fromUser?.organizationName}` ||
                        `${post.reactions[0].fromUser?.firstName} ${post.reactions[0].fromUser?.lastName}`} ` +
                        ` ${formatMessage({ id: 'and' })} ${post.counter_reactions - 1} ${formatMessage({ id: 'others' })}`} */}
                    </span>
                  </HtmlTooltip>

                  <>
                    {/* <span
                      className="post__reactions-peoples--mobile"
                      onClick={() => setReactionsPeoples(true)}
                    >
                      {post?.reactions?.length > 0 ? post?.reactions?.length + " " + formatMessage({ id: 'reaction-s' }) : formatMessage({ id: 'noReactions' })}
                    </span> */}
                    <span
                      className="post__reactions-peoples--mobile"
                      onClick={() => {
                        if (post?.counter_reactions !== 0) {
                          dispatch(getPostReactions({
                            postId: post.id,
                            mobile: true,
                          }));
                        }
                        setReactionsPeoples(true)
                      }}
                    >
                      {post &&
                        post.counter_reactions >= 0 &&
                        `${post.counter_reactions} ${formatMessage({ id: 'reaction-s' })}`}
                    </span>

                    <div
                      className={`reactions-peoples${reactionsPeoples ? ' reactions-peoples--visible' : ''
                        }${insideModal ? ' reactions-peoples--in-modal' : ''}`}
                    >
                      <div className="reactions-peoples__container">
                        <div className="reactions-peoples__title">
                          <ArrowBackIcon
                            onClick={() => setReactionsPeoples(false)}
                          />
                          {formatMessage({ id: 'whoReacted' })}
                        </div>
                        <ul>
                          {post?.reactions?.map(reaction => (
                            <li
                              onClick={() =>
                                navigateToTop(
                                  `/profile/${reaction.fromUser?.id}`,
                                )
                              }
                              key={reaction.fromUser?.id}
                            >
                              <div className="post__reactions-img-container">
                                <img
                                  className="post__reactions-img"
                                  src={`${reactions.find(
                                    el => el.type === reaction.type,
                                  )?.img
                                    }`}
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                  }}
                                  alt=""
                                />
                              </div>

                              <span>
                                {`${reaction?.fromUser?.organizationName}` ||
                                  `${reaction?.fromUser?.firstName} ${reaction?.fromUser?.lastName}`}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                </>
              )}
            </>
          )}
        </div>

        <div className="post__tag-comment-share">
          <span className="post__tag-comment-share-item">
            <InsertCommentTwoToneIcon />
            {/* {`${(location.pathname === '/home'
              || location.pathname === '/groups'
              || location.pathname.includes('/post')
            ) ? post?.counter_comments : post?.comments?.length}`} */}
            {post?.counter_comments}
            <span
              onClick={() =>
                dispatch(getComments({ postId: post?.id, take: 5, skip: 0 }))
              }
              className="post__tag-comment-share-item-text"
            >
              {formatMessage({ id: 'comment-s' })}
            </span>
          </span>

          {/* shares ================================================================================== */}
          {/* shares ================================================================================== */}
          <HtmlTooltipShares
            onMouseEnter={() => {
              // (location.pathname === '/home' || location.pathname === '/groups') &&
              post &&
                !post?.shares &&
                post?.counter_shares !== 0 &&
                dispatch(getPostShared(post.id));
            }}
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {post &&
                  post.shares?.map(share => (
                    <span key={share.id}>
                      {`${share?.owner?.organizationName}` ||
                        `${share?.owner?.firstName} ${share?.owner?.lastName}`}
                    </span>
                  ))}
              </div>
            }
            // followCursor
            enterDelay={1500}
            // leaveDelay={400}
            disableHoverListener={
              post?.shares?.length ? width < 1100 : true
            }
          >
            {onlyComments ? (
              <></>
            ) : (
              <span
                className={`post__tag-comment-share-item ${post?.counter_shares > 0 ? 'hover' : ''}`}
                onClick={() => {
                  if (width < 1100) {
                    setShares(true);
                  }
                }}
              >
                <ReplyIcon />
                {/* {`${(location.pathname === '/home' || location.pathname === '/groups')
                  ? post?.counter_shares
                    : post?.shares?.length}`} */}
                {post?.counter_shares}
                <span className="post__tag-comment-share-item-text">
                  {formatMessage({ id: 'share-s' })}
                </span>
              </span>
            )}
          </HtmlTooltipShares>
          <>
            <div
              className={`reactions-peoples${shares ? ' reactions-peoples--visible' : ''
                }${insideModal ? ' reactions-peoples--in-modal' : ''}`}
            >
              <div className="reactions-peoples__container">
                <div className="reactions-peoples__title">
                  <ArrowBackIcon onClick={() => setShares(false)} />
                  {`${post?.shares?.length || 0} ${formatMessage({
                    id: 'share-s',
                  })}`}
                </div>
                <ul>
                  {post &&
                    post.shares?.map((share, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          navigateToTop(`/profile/${share?.owner?.id}`)
                        }
                      >
                        <div
                          className="post__header-avatar-container"
                          style={{
                            width: '32px',
                            height: '32px',
                          }}
                        >
                          <img
                            className="post__header-avatar"
                            src={share?.owner?.profileAvatar}
                            alt=""
                          />
                        </div>

                        <span>
                          {`${share?.owner?.organizationName}` ||
                            `${share?.owner?.firstName} ${share?.owner?.lastName}`}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
