import { toast } from 'react-toastify';
import { API, customAxios } from '../api/api';

// ==============================================================
const SignIn_API = `${API}/api/auth/sign-in`; //post
const SignUp_API = `${API}/api/auth/sign-up`; //post
const SignUpEmail_API = `${API}/api/auth/sign-up-email`; //post
const CheckExist_API = `${API}/api/auth/check-exist`; //post
const PasswordReset_API = `${API}/api/auth/reset-password`; //post
const ChangePassword_API = `${API}/api/auth/change-password`; //post
const RecoveryPassword_API = `${API}/auth/password-recovery/{token}`; //get
const UpdateOnboardingData_API = `${API}/api/user/update-user-onboarding-data`; //post
const UpdateEmail_API = `${API}/api/user/change-email`; //post
const ConfirmEmail_API = `${API}/api/user/confirm-email`; //post
const UpdatePrivacy_API = `${API}/api/user/update-user-privacy`; //post
const UpdateNotification_API = `${API}/api/user/update-user-notification`; //post
const getFollowers_API = `${API}/api/user/followers?userId=`; //get
const getFollowing_API = `${API}/api/user/following?userId=`; //get
const followUserById_API = `${API}/api/user/follow-user?userId=`; //post
const userChangePassword_API = `${API}/api/user/change-password`; //post
const GetLastActivity_API = `${API}/api/user/get-last-activity`; //post
const ads_API = `${API}/api/adv`; //get

const GetReportedProfiles_API = `${API}/api/admin/get-reported-profiles`; //get
const GetDeactiveProfiles_API = `${API}/api/admin/get-deactive-profiles`; //get
const DeactiveProfile_API = `${API}/api/admin/deactive-profile`; //post
const ChangeLanguage_API = `${API}/api/user/change-user-language`; //post
const GetUserInfo_API = `${API}/api/user/get-user-info`; //get

// ===SIGN-IN===================================================
export async function getUserAPI(data: any) {
  return await customAxios.post(SignIn_API, data);
}
// ===SIGN-UP===================================================
export async function addUserAPI(data: any) {
  return await customAxios.post(SignUp_API, data);
}
// ===SIGN-UP-EMAIL=============================================
export async function addUserEmailAPI(data: any) {
  return await customAxios.post(SignUpEmail_API, data);
}
// ==============================================================
export async function CheckExistUserAPI(data: any) {
  return await customAxios.post(CheckExist_API, data);
}
// ==============================================================
export async function passwordResetAPI(data: any) {
  return await customAxios.post(PasswordReset_API, data);
}
// ==============================================================
export async function getPasswordChangeAPI(data: any) {
  return await customAxios.post(ChangePassword_API, data);
}
// ==============================================================
export async function getRecoveryPasswordAPI() {
  return await customAxios.get(RecoveryPassword_API);
}
// ==============================================================

export async function updateUserOnboardingData(data: any) {
  return await customAxios.post(UpdateOnboardingData_API, data)
}

export async function updateUserEmail(data: any) {
  await customAxios.post(UpdateEmail_API, data)
    .then(res => {
      if (res.status === 200) {
        toast.success('Email has been sent');
      }
      return res
    })
}

export async function confirmUserEmail(data: any) {
  return await customAxios.post(ConfirmEmail_API, data)
}

export async function updateUserPassword(data: any) {
  return await customAxios.post(userChangePassword_API, data)
}

export async function updateUserPrivacy(data: any) {
  return await customAxios.post(UpdatePrivacy_API, data)
}

export const _updateUserNotification = (data: any) => customAxios.post(UpdateNotification_API, data)
export async function updateUserNotification(data: any) {
  return await customAxios.post(UpdateNotification_API, data)
}

// =========Followers===========================================
export async function getFollowersAPI(data: any) {
  //   return await customAxios.get(getFollowers_API + data, data);
  const { id, skip } = data;
  return await customAxios.get(`${API}/api/user/followers?userId=${id}&take=5&skip=${skip || 0}`)
}
// =========Following===========================================
export async function getFollowingAPI(data: any) {
  // return await customAxios.get(getFollowing_API + data, data);
  const { id, skip } = data;
  return await customAxios.get(`${API}/api/user/following?userId=${id}&take=5&skip=${skip || 0}`)

}
// ==========Follow or Unfollow User============================
export async function followUserByIdAPI(data: any) {
  return await customAxios.post(followUserById_API + data, data);
}

export async function getLastActivityAPI(data: any) {
  return await customAxios.post(GetLastActivity_API, data)
}

export async function getAds() {
  return await customAxios.get(`${ads_API}/get-ads`)
}

// export async function postAds(data: any) {
//   return await customAxios.post(ads_API, data)
// }

export async function getReportedProfiles() {
  return await customAxios.post(GetReportedProfiles_API);
}

export async function getDeactiveProfiles() {
  return await customAxios.post(GetReportedProfiles_API);
}

export async function changeLanguageAPI(language: string) {
  return await customAxios.post(ChangeLanguage_API, { language });
}

export async function deactivateAccountAPI() {
  return await customAxios.post(`${API}/api/user/deactivate-account`);
}

export async function counterAdsAPI(data: any) {
  return await customAxios.post(`${API}/api/feed/click-counter`, data);
}

export async function getUserInfoAPI() {
  return await customAxios.post(`${GetUserInfo_API}`);
}

export async function getBlockedUsersAPI() {
  return await customAxios.get(`${API}/api/user/blocked-users`);
}

export async function BlockUserById(data: { userId: string }) {
  return await customAxios.post(`${API}/api/user/block-user`, data);
}

export async function UnBlockUser(data: { userId: string }) {
  return await customAxios.post(`${API}/api/user/unblock-user`, data);
}

export async function readNotifications() {
  return await customAxios.post(`${API}/api/user/read-activity`)
}

export async function getNotificationsCounter() {
  return await customAxios.post(`${API}/api/user/den-counter`)
}

export async function sendInviteEmail(email: string) {
  return await customAxios.post(`${API}/api/mail-helper/invite`, { to: email})
}

