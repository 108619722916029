import React, { Dispatch, SetStateAction, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useIntl } from 'react-intl';
import CreateBook from '../CreateBook/CreateBook';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import SecondaryButton from '../../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import ModalContainer from '../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalWrapper from '../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import './BookReader.scss';
import useWindowDimensions from '../../../../hooks/widthHook';

interface BookReaderProps {
  asAdmin: boolean;
  book: Book;
  setBookReader: Dispatch<SetStateAction<boolean>>;
  setBooksModal: Dispatch<SetStateAction<boolean>>;
}

function BookReader({
  asAdmin,
  book,
  setBookReader,
  setBooksModal,
}: BookReaderProps) {
  const [createBook, setCreateBook] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const { width } = useWindowDimensions();

  return (
    <ErrorBoundary>
      {!createBook ? (
        <ModalWrapper higherZIndex>
          <ModalContainer
            styles={{
              backgroundColor: '#FBF5FF',
              borderRadius: '12px',
              padding: '0',
            }}
          >
            <ModalHeader
              mainColor
              withoutBorder
              textHeader={book.title}
              subText={book.author}
              textStyles={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
              subTextStyles={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '19px',
                color: '#6C757D',
              }}
              closeSx={{ mt: 1 }}
              onClose={() => {
                setBookReader(false);
                setBooksModal(false);
              }}
            />
            <div className="BookReader__container">
              {width < 1100 && (
                <div className="BookReader__mobile-arrow-back">
                  <ArrowBackIcon onClick={() => setBookReader(false)} />
                </div>
              )}
              <div className="BookReader__top">
                <h3 className="BookReader__title">
                  {formatMessage({ id: 'review' })}
                </h3>
                {asAdmin && (
                  <SecondaryButton
                    text="Edit"
                    onClick={() => setCreateBook(true)}
                  />
                )}
                <a
                  className="BookReader__link"
                  href={book.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{formatMessage({ id: 'buyBook' })}</span>
                </a>
                {width >= 1100 && (
                  <ArrowBackIcon onClick={() => setBookReader(false)} />
                )}
              </div>
              <p
                className="BookReader__review"
                dangerouslySetInnerHTML={{
                  __html: book.description.replace(/\n/g, '<br />'),
                }}
              />
            </div>
          </ModalContainer>
        </ModalWrapper>
      ) : (
        <CreateBook
          book={book}
          setCreateBook={setCreateBook}
          setBookReader={setBookReader}
        />
      )}
    </ErrorBoundary>
  );
}

export default BookReader;
