import React, { useEffect } from 'react'
import './BurgerMenu.scss'
import { Link, useLocation } from 'react-router-dom';
import { useNavigateToTop } from '../helpers/useNavigateToTop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { NavSide } from '../NavSide/NavSide';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useIntl } from 'react-intl';
import { readNotifications } from '../../api/user';
import { setNotificationCounter } from '../../redux/slices/appSlice';
import { useDispatch } from 'react-redux';
import { setOnRelaxRoom } from '../../redux/slices/relaxSlice';
import { setMenuOpenStatus } from '../../redux/slices/chatSlice';

interface Props {
  showBurger: boolean;
  setShowBurger: (el: boolean) => void;
}

export const BurgerMenu = ({ showBurger, setShowBurger }: Props) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl()
  let location = useLocation();
  const navigateToTop = useNavigateToTop();
  const { id } = useSelector((state: RootState) => state.profile);
  const { activity } = useSelector((state: RootState) => state.posts);
  const { msgCount } = useSelector((state: RootState) => state.chat);
  const { notificationsCounter } = useSelector((state: RootState) => state.app);

  const lan = localStorage.getItem('lang') || 'en';
  
  useEffect(() => {
    if (location.pathname === '/activity-log') {
      readNotifications()
      dispatch(setNotificationCounter(0))
    }
  }, [activity?.length])

  return (
    <div className={`burger ${showBurger && 'burger--visible'}`}>

      <ul className="header-navigation burger__header-navigation">
        <li
          className={`header-navigation__item burger__navigation-item ${location.pathname === "/home" ? 'header-navigation__item--checked' : ''}`}
          onClick={() => {
            setShowBurger(false);
          }}
        >
          <Link
            className="burger__navigation-link "
            to="/home"
            onClick={() => navigateToTop('/home')}
            style={{
              display: 'flex',
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
              gap: lan === 'ar' ? '10px' : '0'
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.2604 4.74745C12.1906 4.68069 12.0978 4.64343 12.0012 4.64343C11.9046 4.64343 11.8118 4.68069 11.742 4.74745L3.1123 12.9914C3.07566 13.0264 3.0465 13.0685 3.0266 13.1152C3.0067 13.1618 2.99647 13.212 2.99652 13.2628L2.99512 21C2.99512 21.3978 3.15315 21.7793 3.43446 22.0606C3.71576 22.3419 4.09729 22.5 4.49512 22.5H8.9998C9.19872 22.5 9.38948 22.4209 9.53013 22.2803C9.67079 22.1396 9.7498 21.9489 9.7498 21.75V15.375C9.7498 15.2755 9.78931 15.1801 9.85964 15.1098C9.92997 15.0395 10.0253 15 10.1248 15H13.8748C13.9743 15 14.0696 15.0395 14.14 15.1098C14.2103 15.1801 14.2498 15.2755 14.2498 15.375V21.75C14.2498 21.9489 14.3288 22.1396 14.4695 22.2803C14.6101 22.4209 14.8009 22.5 14.9998 22.5H19.5026C19.9004 22.5 20.282 22.3419 20.5633 22.0606C20.8446 21.7793 21.0026 21.3978 21.0026 21V13.2628C21.0027 13.212 20.9924 13.1618 20.9725 13.1152C20.9526 13.0685 20.9235 13.0264 20.8868 12.9914L12.2604 4.74745Z" fill="#6C757D" />
              <path d="M23.0114 11.4445L19.5052 8.09016V3C19.5052 2.80109 19.4262 2.61032 19.2855 2.46967C19.1449 2.32902 18.9541 2.25 18.7552 2.25H16.5052C16.3063 2.25 16.1155 2.32902 15.9748 2.46967C15.8342 2.61032 15.7552 2.80109 15.7552 3V4.5L13.0402 1.90406C12.7861 1.64719 12.4083 1.5 12 1.5C11.5931 1.5 11.2163 1.64719 10.9622 1.90453L0.991893 11.4436C0.70033 11.7248 0.663768 12.1875 0.92908 12.4922C0.995704 12.5691 1.07728 12.6316 1.16885 12.676C1.26041 12.7204 1.36005 12.7457 1.46169 12.7503C1.56334 12.755 1.66487 12.7389 1.7601 12.7031C1.85533 12.6672 1.94228 12.6124 2.01564 12.5419L11.7422 3.2475C11.812 3.18074 11.9048 3.14348 12.0014 3.14348C12.098 3.14348 12.1909 3.18074 12.2606 3.2475L21.9881 12.5419C22.1314 12.6793 22.3234 12.7543 22.5219 12.7504C22.7204 12.7465 22.9092 12.6641 23.047 12.5212C23.3349 12.2231 23.311 11.7309 23.0114 11.4445Z" fill="#6C757D" />
            </svg>
            <div>{formatMessage({id: 'home'})}</div>
          </Link>
        </li>

        <li
          className={`header-navigation__item burger__navigation-item ${location.pathname === `/profile/${id}` ? 'header-navigation__item--checked' : ''}`}
          onClick={() => {
            setShowBurger(false);
          }}
        >
          <Link
            className="burger__navigation-link"
            to={`/profile/${id}`}
            onClick={() => navigateToTop(`/profile/${id}`)}
            style={{
              display: 'flex',
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
              gap: lan === 'ar' ? '10px' : '0'
            }}
          >
            <AccountCircleIcon />
            <div>{formatMessage({id: 'myProfile'})}</div>
          </Link>
        </li>

        <li
          className={`header-navigation__item burger__navigation-item ${location.pathname === "/home" ? 'header-navigation__item--checked' : ''}`}
          onClick={() => {
            setShowBurger(false);
          }}
        >
          <Link
            className="burger__navigation-link "
            to="/chat"
            onClick={() => dispatch(setMenuOpenStatus(true))}
            style={{
              display: 'flex',
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
              gap: lan === 'ar' ? '10px' : '0'
            }}
          >
            <MailOutlineIcon/>
            <div>{formatMessage({id: 'Chat'})} {!!msgCount?.length && `(${msgCount?.length})`}</div>
          </Link>
        </li>

        <li
          className={`header-navigation__item burger__navigation-item ${location.pathname === "/activity-log" ? 'header-navigation__item--checked' : ''}`}
          onClick={() => {
            setShowBurger(false);
          }}
        >
          <Link
            className="burger__navigation-link"
            to="/activity-log"
            onClick={() => navigateToTop('/activity-log')}
            style={{
              display: 'flex',
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
              gap: lan === 'ar' ? '10px' : '0'
            }}
          >
            <NotificationsIcon />
            <div>{formatMessage({ id: 'notifications' })} ({notificationsCounter})</div>
          </Link>
        </li>

        <li
          className={`header-navigation__item burger__navigation-item ${location.pathname === "/activity-log" ? 'header-navigation__item--checked' : ''}`}
          onClick={() => {
            setShowBurger(false);
          }}
        >
          <Link
            className="burger__navigation-link"
            to="/relax-room"
            onClick={() => dispatch(setOnRelaxRoom(true))}
            style={{
              display: 'flex',
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
              gap: lan === 'ar' ? '10px' : '0'
            }}
          >
            <div className="burger__relax-room"/>
            <div>{formatMessage({ id: 'relaxRoom' })}</div>
          </Link>
        </li>

      </ul>

      <NavSide setShowBurger={setShowBurger} />
  
    </div>
  )
}
