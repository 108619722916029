import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { RootState } from '../../../../redux/store';
import useWindowDimensions from '../../../../hooks/widthHook';
import { defaultAvatars } from '../../../../constants/accountSettings';
import allCities from '../../../../constants/allCities';
import SecondaryButton from '../../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import UploadButton from '../../../../components/shared/Buttons/UploadButton/UploadButton';
import { TextButton } from '../../../../components/shared/Buttons/TextButton/TextButton';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import CheckBoxLabel from '../../../../components/shared/CheckBoxLabel/CheckBoxLabel';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import CustomChip from '../../../../components/shared/CustomChip/CustomChip';
import AvatarChip from '../../../../components/shared/AvatarChip/AvatarChip';
import TextArea from '../../../../components/shared/TextArea/TextArea';
import Select from '../../../../components/shared/Select/Select';
import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone';
import './OnboardingForm.scss';
import { useDispatch } from 'react-redux';
import { showLoader } from '../../../../redux/slices/appSlice';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../../../constants';
import { ImageCropModal } from '../../../../components/ImageCropModal/ImageCropModal';

type Props = {
  setStep: (step: number) => void;
  onSubmitSignUp: (onboarding: any) => void;
  signUp?: boolean;
};

type CheckBoxesType = {
  forOrganization: boolean;
  otherTitle: boolean;
  titles: string[];
  notification: {
    posts: boolean;
    activity: boolean;
    additions: boolean;
    surveys: boolean;
  };
  advertisements: boolean;
};

type CountryItem = {
  country: string;
  cities: string[];
};

const now = new Date().getUTCFullYear();
const yearsToSelect = Array(now - (now - 100))
  .fill({ label: '' })
  .map((obj, idx) => (obj.year = `${now - idx}`));

const countryToSelect = allCities
  .map((item: CountryItem) => item.country)
  .sort();

const OnboardingForm = ({ setStep, onSubmitSignUp, signUp }: Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { allInterests } = useSelector((state: RootState) => state.app);
  const lan = localStorage.getItem('lang') || 'en';
  const { width: windowWidth } = useWindowDimensions();

  const identifyList: string[] = [
    formatMessage({ id: 'title1' }),
    formatMessage({ id: 'title2' }),
    formatMessage({ id: 'title3' }),
    formatMessage({ id: 'title4' }),
    formatMessage({ id: 'title5' }),
    formatMessage({ id: 'title6' }),
    formatMessage({ id: 'title7' }),
  ];

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onChange' });

  const [organizationName, setOrganizationName] = useState('');
  const [otherTitle, setOtherTitle] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [year, selectYear] = useState('');
  const [country, selectCountry] = useState('');
  const [region, selectRegion] = useState<string | null>('');
  const [aboutMe, setAboutMe] = useState('');
  const [checkBoxes, setCheckBoxes] = useState<CheckBoxesType>({
    forOrganization: false,
    otherTitle: false,
    titles: [],
    notification: {
      posts: false,
      activity: false,
      additions: false,
      surveys: false,
    },
    advertisements: true,
  });

  const [regionToSelect, setRegionToSelect] = useState<string[]>([]);

  const [selectedChips, setSelectedChips] = useState<
    { title: string; url: string; id: string; isArchived: boolean }[]
  >([]);
  const [btnSelectChecked, SetBtnSelectChecked] = useState(false);

  const [exampleAvatar, setExampleAvatar] = useState(defaultAvatars[0]);
  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const [tempFileData, setTempFileData] = useState<FormData | undefined>();
  const [uploadDragover, setUploadDragover] = useState(false);

  useEffect(() => {
    if (btnSelectChecked) {
      setSelectedChips([...allInterests.filter(item => !item.isArchived)]);
    } else {
      setSelectedChips([]);
    }
  }, [btnSelectChecked]);

  // useEffect(() => {
  //   const currentCountry = allCities.find(
  //     (item: CountryItem) => item.country === country,
  //   );

  //   if (currentCountry !== undefined) {
  //     const newRegionToSelect = currentCountry.cities;
  //     setRegionToSelect(newRegionToSelect);

  //     if (!newRegionToSelect.includes(region + '')) {
  //       selectRegion(null);
  //     }
  //   } else {
  //     setRegionToSelect([]);
  //     selectRegion(null);
  //   }
  // }, [country]);

  const uploadImage = (files: FileList | null) => {
    if (files === null) return;
    const tempFile = files[0];

    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(tempFile);
    img.src = objectUrl;
    img.onload = () => {
      const formData = new FormData();
      formData.append('file', tempFile);
      formData.append('upload_preset', Cloudinary_PRESET);

      setCropModal({
        open: true,
        image: objectUrl,
        cropShape: 'round',
        formData,
        imgType: 'avatar',
        onSave: (file = formData, url: any) => {
          setTempFileData(file);
          setExampleAvatar('');
          setUploadedAvatar(url);
        },
      });
    };
  };

  const [cropModal, setCropModal] = useState<any>({
    open: false,
    image: '',
    cropShape: 'round',
    formData: '',
    imgType: 'avatar',
    onSave: () => {},
  });

  const onSubmit = async () => {
    dispatch(showLoader(true));
    let uploadedImg = '';

    if (tempFileData) {
      await axios
        .post(
          `https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`,
          tempFileData,
        )
        .then(response => {
          uploadedImg = response.data.secure_url;
        });
    }

    onSubmitSignUp({
      organizationName: checkBoxes.forOrganization ? organizationName : '',
      firstName: !checkBoxes.forOrganization ? firstName : '',
      lastName: !checkBoxes.forOrganization ? lastName : '',
      otherTitle,
      year,
      country,
      region,
      aboutMe,
      exampleAvatar,
      uploadedAvatar: uploadedImg,
      checkBoxes,
      selectedChips,
    });
  };

  const changeOneCheckbox = (
    checkboxType: 'forOrganization' | 'advertisements' | 'otherTitle',
  ) => {
    const newObj = JSON.parse(JSON.stringify(checkBoxes));
    newObj[checkboxType] = !checkBoxes[checkboxType];
    setCheckBoxes(newObj);
  };

  const changeCheckboxTitles = (label: string, checked?: boolean) => {
    const newObj = JSON.parse(JSON.stringify(checkBoxes));
    if (checked) {
      newObj.titles.push(label);
    } else {
      newObj.titles.splice(newObj.titles.indexOf(label, 0), 1);
    }
    setCheckBoxes(newObj);
  };

  const changeCheckboxNotifications = (
    notificationType: 'posts' | 'activity' | 'additions' | 'surveys',
  ) => {
    const newObj = JSON.parse(JSON.stringify(checkBoxes));
    newObj.notification[notificationType] =
      !checkBoxes.notification[notificationType];
    setCheckBoxes(newObj);
  };

  return (
    <form className="onboardingForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="onboardingForm-header">
        {formatMessage({ id: 'createYour' })}{' '}
        <span className="onboardingForm-header__accent">WizWork</span>{' '}
        {formatMessage({ id: 'account' }).toLowerCase()}
      </div>

      <div className="onboardingForm-text">
        <p style={{ fontWeight: 600 }}>
          {formatMessage({ id: 'dearest' })}{' '}
          <span style={{ color: '#791186', lineHeight: '140%' }}>WizWork</span>
          {formatMessage({ id: 'newerShare' })}
        </p>
      </div>

      <div
        className="onboardingForm-block"
        style={{
          flexDirection:
            windowWidth >= 1100
              ? lan === 'ar'
                ? 'row-reverse'
                : 'row'
              : 'column',
        }}
      >
        <CheckBoxLabel
          label={formatMessage({ id: 'useOrganizationAccount' })}
          setValue={() => changeOneCheckbox('forOrganization')}
        />
      </div>

      {!checkBoxes.forOrganization ? (
        <div
          className="onboardingForm-block"
          style={{
            flexDirection:
              windowWidth >= 1100
                ? lan === 'ar'
                  ? 'row-reverse'
                  : 'row'
                : 'column',
            gap: lan === 'ar' && windowWidth >= 1100 ? '15px' : '0',
          }}
        >
          <CustomInput
            width={270}
            placeholder={formatMessage({ id: 'firstNamePlaceholder' })}
            label={formatMessage({ id: 'firstNameLabel' })}
            touchedFields={touchedFields}
            inputName="firstName"
            errors={errors}
            onChangeFunc={value => {
              setFirstName(value);
              setValue('firstName', value);
            }}
            inputProps={{
              ...register('firstName', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                maxLength: {
                  value: 30,
                  message: formatMessage({ id: 'nameMaxLength' }),
                },
              }),
              value: firstName,
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left',
              },
            }}
          />
          <CustomInput
            width={270}
            placeholder={formatMessage({ id: 'lastNamePlaceholder' })}
            label={formatMessage({ id: 'lastNameLabel' })}
            touchedFields={touchedFields}
            inputName="lastName"
            errors={errors}
            onChangeFunc={value => {
              setLastName(value);
              setValue('lastName', value);
            }}
            inputProps={{
              ...register('lastName', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                maxLength: {
                  value: 30,
                  message: formatMessage({ id: 'nameMaxLength' }),
                },
              }),
              value: lastName,
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left',
              },
            }}
          />
        </div>
      ) : (
        <div
          className="onboardingForm-block"
          style={{
            flexDirection:
              windowWidth >= 1100
                ? lan === 'ar'
                  ? 'row-reverse'
                  : 'row'
                : 'column',
          }}
        >
          <CustomInput
            width={270}
            placeholder={formatMessage({ id: 'organizationNamePlaceholder' })}
            label={formatMessage({ id: 'organizationNameLabel' }) + '*'}
            touchedFields={touchedFields}
            inputName="organizationName"
            errors={errors}
            onChangeFunc={value => {
              setOrganizationName(value);
              setValue('organizationName', value);
            }}
            inputProps={{
              ...register('organizationName', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                maxLength: {
                  value: 30,
                  message: formatMessage({ id: 'nameMaxLength' }),
                },
              }),
              value: organizationName,
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left',
              },
            }}
          />
        </div>
      )}

      {!signUp && <div
        className="onboardingForm-block"
        style={{
          flexDirection:
            windowWidth >= 1100
              ? lan === 'ar'
                ? 'row-reverse'
                : 'row'
              : 'column',
        }}
      >
        <Select
          width={140}
          options={yearsToSelect}
          label={formatMessage({id: 'yearOfBirth'})}
          onChange={selectYear}
          inputName="year"
        />
      </div>}

      <div
        className="onboardingForm-block"
        style={{
          flexDirection:
            windowWidth >= 1100
              ? lan === 'ar'
                ? 'row-reverse'
                : 'row'
              : 'column',
          gap: lan === 'ar' && windowWidth >= 1100 ? '15px' : '0',
        }}
      >
        <Select
          width={270}
          options={countryToSelect}
          label={formatMessage({ id: 'countryLabel' })}
          value={country}
          placeholder={formatMessage({ id: 'countryPlaceholder' })}
          onChange={selectCountry}
          inputName="country"
          validation={{
            errors,
            touchedFields,
            register,
            clearErrors,
            setError,
            setValue,
          }}
        />

        {!signUp && <CustomInput
          width={windowWidth > 1100 ? 270 : '100%'}
          placeholder={formatMessage({id: 'regionPlaceholder'})}
          label={formatMessage({id: 'regionLabel'})}
          touchedFields={touchedFields}
          inputName="region"
          errors={errors}
          onChangeFunc={value => {
            // setEmail(value);
            selectRegion(value);
            // setValue('email', value);
          }}
          inputProps={{
            ...register('region', {
              // required: formatMessage({ id: 'fieldIsEmpty' }),
            }),
            style: {
              fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
              textAlign: lan === 'ar' ? 'right' : 'left',
            },
          }}
        />}

        {/* <Select
          width={270}
          options={regionToSelect}
          label={formatMessage({ id: 'regionLabel' })}
          value={region}
          placeholder={formatMessage({ id: 'regionPlaceholder' })}
          onChange={selectRegion}
          inputName="region"
          validation={{
            errors,
            touchedFields,
            register,
            clearErrors,
            setError,
            setValue,
          }}
        /> */}
      </div>

      { !signUp && <>
        <div
          className="onboardingForm-bold"
          style={{
            flexDirection:
              windowWidth >= 1100
                ? lan === 'ar'
                  ? 'row-reverse'
                  : 'row'
                : 'column',
          }}
        >
          <div>{formatMessage({ id: 'useTitleForYourself' })}</div>
        </div>

        <ul className="onboardingForm-list">
          {identifyList.map((identify: string, index: number) => (
            <li key={index} className="onboardingForm-list-item">
              <CheckBoxLabel
                label={identify}
                setValue={(checked: boolean) => {
                  changeCheckboxTitles(identify, checked);
                }}
              />
            </li>
          ))}
          <li
            className={
              lan === 'ar'
                ? 'onboardingForm-list-item'
                : 'onboardingForm-list__other'
            }
            style={{
              flexDirection:
                windowWidth > 1100
                  ? lan === 'ar'
                    ? 'row-reverse'
                    : 'row'
                  : 'column',
            }}
          >
            <CheckBoxLabel
              label="Other"
              setValue={() => {
                changeOneCheckbox('otherTitle');
              }}
            />
            {checkBoxes.otherTitle && (
              <CustomInput
                width={270}
                placeholder="e.g.: Nanny"
                onChangeFunc={value => {
                  setOtherTitle(value);
                }}
                label={' '}
                inputProps={{
                  value: otherTitle,
                  style: {
                    fontFamily:
                      lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                  },
                }}
              />
            )}
          </li>
        </ul>

        <div
          className="onboardingForm-bold"
          style={{
            flexDirection:
              windowWidth >= 1100
                ? lan === 'ar'
                  ? 'row-reverse'
                  : 'row'
                : 'column',
          }}
        >
          <div
            style={{
              marginBottom: windowWidth < 1100 ? '12px' : '0',
            }}
          >
            {formatMessage({ id: 'followingInterests' })}
            <p className="onboardingForm__interest-text">
              {formatMessage({ id: 'followingInterestsSub' })}
            </p>
          </div>
          <CustomChip
            label={
              btnSelectChecked
                ? formatMessage({ id: 'resetAll' })
                : formatMessage({ id: 'selectAll' })
            }
            src=""
            id=""
            isArchived
            onClick={() => {
              SetBtnSelectChecked(!btnSelectChecked);
            }}
          />
        </div>

        <div className="onboardingForm-chips">
          {allInterests
            .filter((item: Chip) => !item.isArchived)
            .map((item: Chip, index: number) => (
              <CustomChip
                key={index}
                label={item.title}
                src={item.avatar || item.url}
                id={item.id}
                isArchived={item.isArchived}
                defaultChecked={
                  !!(
                    1 + selectedChips.findIndex(chip => chip.title === item.title)
                  )
                }
                setSelectedChips={setSelectedChips}
              />
            ))}
        </div>
      </>}

      <div
        className="onboardingForm-bold"
        style={{
          flexDirection:
            windowWidth >= 1100
              ? lan === 'ar'
                ? 'row-reverse'
                : 'row'
              : 'column',
        }}
      >
        <div>{formatMessage({ id: 'selectProfilePhoto' })}</div>
      </div>

      <div
        className="onboardingForm-photos"
        style={{
          flexDirection:
            windowWidth >= 1100
              ? lan === 'ar'
                ? 'row-reverse'
                : 'row'
              : 'row',
        }}
      >
        {defaultAvatars.map((avatar: string, index: number) => (
          <AvatarChip
            key={index}
            src={avatar}
            setSelectedSrc={(src: string) => {
              setExampleAvatar(src);
              setUploadedAvatar('');
              setTempFileData(undefined);
            }}
            selectedSrc={exampleAvatar}
          />
        ))}
      </div>

      <div
        className="onboardingForm-upload"
        style={{
          flexDirection:
            windowWidth >= 1100
              ? lan === 'ar'
                ? 'row-reverse'
                : 'row'
              : 'row',
          gap: windowWidth >= 1100 && lan === 'ar' ? '15px' : '0',
        }}
      >
        {uploadedAvatar ? (
          <img
            className="onboardingForm-upload__avatar"
            src={uploadedAvatar}
            alt="upload avatar"
          />
        ) : (
          <div
            className={`onboardingForm-upload__img${
              uploadDragover ? ' onboardingForm-upload__img--drag' : ''
            }`}
            onDragStart={e => {
              e.preventDefault();
              setUploadDragover(true);
            }}
            onDragLeave={e => {
              e.preventDefault();
              setUploadDragover(false);
            }}
            onDragOver={e => {
              e.preventDefault();
              setUploadDragover(true);
            }}
            onDrop={e => {
              e.preventDefault();
              setUploadDragover(false);
              uploadImage(e.dataTransfer.files);
            }}
          >
            <AddAPhotoTwoToneIcon />
          </div>
        )}

        <div
          className={
            uploadedAvatar
              ? 'onboardingForm-upload__row'
              : 'onboardingForm-upload__column'
          }
        >
          <UploadButton uploadImage={uploadImage} />
          {uploadedAvatar ? (
            <>
              {windowWidth > 1100 && (
                <SecondaryButton
                  text={formatMessage({ id: 'delete' })}
                  onClick={() => {
                    setUploadedAvatar('');
                    setTempFileData(undefined);
                    setExampleAvatar(defaultAvatars[0]);
                  }}
                />
              )}

              {windowWidth < 1100 && (
                <TextButton
                  text={formatMessage({ id: 'delete' })}
                  onClick={() => {
                    setUploadedAvatar('');
                    setTempFileData(undefined);
                    setExampleAvatar(defaultAvatars[0]);
                  }}
                />
              )}
            </>
          ) : (
            <p
              className="onboardingForm-upload__text"
              style={{ marginTop: '16px', width: '100%' }}
            >
              <span style={{ marginTop: '0' }}>
                {formatMessage({ id: 'photoFormat' })}
              </span>
              <span style={{ marginTop: '0' }}>
                {formatMessage({ id: 'photoMaxSize' })}
              </span>
            </p>
          )}
        </div>
      </div>

      {!signUp && <>
        <TextArea
            label={formatMessage({id: 'aboutMe'})}
            maxChar={255}
            value={aboutMe}
            setValue={setAboutMe}
        />

          <div
            className="onboardingForm-bold"
            style={{
              flexDirection:
                windowWidth >= 1100
                  ? lan === 'ar'
                    ? 'row-reverse'
                    : 'row'
                  : 'column',
            }}
          >
            <div>{formatMessage({id: 'emailNotifySettings'})}</div>
          </div>

          <div className="onboardingForm-list">
            <CheckBoxLabel
              label={formatMessage({id: 'commentsNotify'})}
              setValue={() => {
                changeCheckboxNotifications('posts');
              }}
            />
            <br />
            <CheckBoxLabel
              label={formatMessage({id: 'popularActivityNotify'})}
              setValue={() => {
                changeCheckboxNotifications('activity');
              }}
            />
            <br />
            <CheckBoxLabel
              label={formatMessage({id: 'emailNotify'})}
              setValue={() => {
                changeCheckboxNotifications('additions');
              }}
            />
            <br />
            <CheckBoxLabel
              label={formatMessage({id: 'inviteNotify'})}
              setValue={() => {
                changeCheckboxNotifications('surveys');
              }}
            />
          </div>

          <div
            className="onboardingForm-bold"
            style={{
              flexDirection:
                windowWidth >= 1100
                  ? lan === 'ar'
                    ? 'row-reverse'
                    : 'row'
                  : 'column',
            }}
          >
            <div>{formatMessage({id: 'otherNotify'})}</div>
          </div>

          <CheckBoxLabel
            label={formatMessage({id: 'seeAdvertisements'})}
            defaultChecked
            setValue={() => changeOneCheckbox('advertisements')}
          />
      </>}

      <div
        className="onboardingForm-footer"
        style={{
          justifyContent: lan === 'ar' ? 'center' : 'flex-start',
        }}
      >
        <SecondaryButton
          text={formatMessage({ id: 'back' })}
          onClick={() => {
            setStep(1);
          }}
        />
        <MainButton
          text={formatMessage({ id: 'createAccount' })}
          type="submit"
        />
      </div>

      {cropModal.open && (
        <ImageCropModal
          image={cropModal.image}
          cropShape={cropModal.cropShape}
          onClose={() =>
            setCropModal({
              open: false,
              image: '',
              cropShape: 'round',
              formData: '',
            })
          }
          onSave={cropModal.onSave}
        />
      )}
    </form>
  );
};

export default OnboardingForm;
