import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { updateUserNotification } from '../../../api/user';
import ErrorBoundary from '../../../components/ErrorBoundary';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import CheckBoxLabel from '../../../components/shared/CheckBoxLabel/CheckBoxLabel';
import { setAccountSettings } from '../../../redux/slices/profileSlice';
import { RootState } from '../../../redux/store';
import useWindowDimensions from '../../../hooks/widthHook';
import './NotificationSettings.scss';

export const NotificationSettings = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { notification, ads, activityLog } = useSelector(
    (state: RootState) => state.profile,
  );

  const [showComments, setShowComments] = useState(activityLog.showComments);
  const [showMentions, setShowMentions] = useState(activityLog.showMentions);
  const [showReactions, setShowReactions] = useState(activityLog.showReactions);

  const [posts, setPosts] = useState(notification.posts);
  const [activity, setActivity] = useState(notification.activity);
  const [additions, setAdditions] = useState(notification.additions);
  const [surveys, setSurveys] = useState(notification.surveys);

  const [advertising, setAdvertising] = useState(ads);
  const lan = localStorage.getItem('lang') || 'en';

  const { width } = useWindowDimensions();

  return (
    <div className="accountSettings notificationSettings">
      <h2 className="accountSettings-title">
        {formatMessage({ id: 'notificationSettings' })}
      </h2>
      <ErrorBoundary>
        <div className="accountSettings-bold">
          <p>{formatMessage({ id: 'activityLog' })}:</p>
        </div>

        <div
          className={
            `accountSettings-list ` +
            `accountSettings-list--small notificationSettings-list`
          }
          style={{
            alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
          }}
        >
          <CheckBoxLabel
            label={formatMessage({ id: 'showComments' })}
            defaultChecked={activityLog.showComments}
            setValue={setShowComments}
          />
          <CheckBoxLabel
            label={formatMessage({ id: 'showReactions' })}
            defaultChecked={activityLog.showReactions}
            setValue={setShowReactions}
          />
        </div>

        <div className="accountSettings-bold">
          <p>{formatMessage({ id: 'emailNotifications' })}</p>
        </div>

        <div
          className={
            `accountSettings-list ` +
            `accountSettings-list--small notificationSettings-list`
          }
          style={{
            alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
          }}
        >
          <CheckBoxLabel
            label={formatMessage({ id: 'commentsNotify' })}
            defaultChecked={notification.posts}
            setValue={setPosts}
          />
          <CheckBoxLabel
            label={formatMessage({ id: 'popularActivityNotify' })}
            defaultChecked={notification.activity}
            setValue={setActivity}
          />
          <CheckBoxLabel
            label={formatMessage({ id: 'emailNotify' })}
            defaultChecked={notification.additions}
            setValue={setAdditions}
          />
          <CheckBoxLabel
            label={formatMessage({ id: 'inviteNotify' })}
            defaultChecked={notification.surveys}
            setValue={setSurveys}
          />
        </div>

        <div className="accountSettings-bold">
          <p>{formatMessage({ id: 'advertisingNotify' })}:</p>
        </div>

        <div
          className={
            `accountSettings-list ` +
            `accountSettings-list--small notificationSettings-list` +
            `notificationSettings-list-last`
          }
          style={{
            alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
          }}
        >
          <CheckBoxLabel
            label={formatMessage({ id: 'seeAdvertisements' })}
            defaultChecked={ads}
            setValue={setAdvertising}
          />
        </div>

        <div className="accountSettings-footer notificationSettings-footer">
          <MainButton
            text={formatMessage({ id: 'saveChanges' })}
            type="submit"
            styles={
              width < 1100
                ? {
                    width: '90%',
                    marginRight: '0',
                    position: 'absolute',
                    bottom: '110px',
                    left: '24px',
                  }
                : {}
            }
            onClick={() => {
              updateUserNotification({
                posts,
                activity,
                additions,
                surveys,
                showComments,
                showMentions,
                showReactions,
                advertising,
              }).then(res => {
                if (res.status === 200) {
                  toast.success(
                    formatMessage({ id: 'notificationSettingsChanged' }),
                  );
                  dispatch(setAccountSettings(res.data));
                }
              });
            }}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};
