import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGroupsAPI, getInterestsAPI, getPostsTypeAPI } from '../../api/iterests';
export interface AppState {
  isLoading: boolean;
  isLoadingDone: boolean;
  step: number;

  history: string[];
  notificationsCounter: number;
  ads: any;

  allInterests: Chip[];
  groups: any;
  postTypes: any;
  countFeedSkip: number;
}

const initialState: AppState = {
  isLoading: false,
  isLoadingDone: false,
  step: 1,
  history: [],

  notificationsCounter: 0,
  ads: [],

  allInterests: [],
  groups: [],
  postTypes: [],
  countFeedSkip: 0,
};

export const getAllInterest = createAsyncThunk(
  'app/getAllInterest',
  async function (_, { rejectWithValue }) {
    try {
      const post = await getInterestsAPI();
      return post.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getAllGroups = createAsyncThunk(
  'app/getAllGroups',
  async function (_, { rejectWithValue }) {
    try {
      const post = await getGroupsAPI();

      return post.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getPostTypes = createAsyncThunk(
  'app/getPostTypes',
  async function (_, { rejectWithValue }) {
    try {
      const post = await getPostsTypeAPI();

      return post.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);


// export const getTypeOfPost = createAsyncThunk(
//   'app/getTypeOfPost',
//   async function (_, { rejectWithValue }) {
//     try {
//       const types = await getTypeOfPostAPI();

//       if (!types.data) {
//         throw new Error("Can't get types. Server error");
//       }

//       return types.data;
//     } catch (error: any) {
//       return rejectWithValue(error.message);
//     }
//   },
// );

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showLoader(state: AppState, action) {
      state.isLoading = action.payload;
    },
    hideLoader(state: AppState, action) {
      state.isLoadingDone = action.payload;
      // console.log('hide hide')
    },
    setSignUpStep(state: AppState, action) {
      state.step = action.payload;
    },
    setAds(state, action) {
      state.ads = action.payload
    },
    setHistory(state, action){
      if (state.history) {
        state.history = [...state.history, action.payload]
      } else state.history = [action.payload]

    },
    popHistory(state, action){
      const arr = [...state.history];
      arr.pop()
      state.history = arr
    },
    setNotificationCounter(state, action){
      state.notificationsCounter = action.payload
    },
    incrementNotificationCounter(state){
      state.notificationsCounter = state.notificationsCounter + 1
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllInterest.fulfilled, (state: AppState, action: AnyAction) => {
        state.allInterests = action.payload;
      })
      .addCase(getAllGroups.fulfilled, (state: AppState, action: AnyAction) => {
        state.groups = action.payload;
      })
      .addCase(getPostTypes.fulfilled, (state: AppState, action: AnyAction) => {
        state.postTypes = action.payload;
      });
  },

});

export const {
  showLoader,
  hideLoader,
  setSignUpStep,
  setAds,
  setHistory,
  popHistory,
  setNotificationCounter,
  incrementNotificationCounter,
} = appSlice.actions;

export default appSlice.reducer;
