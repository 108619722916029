import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { changeLanguage } from '../../../redux/slices/profileSlice';
import { languages } from '../../../constants/language';
import ErrorBoundary from '../../../components/ErrorBoundary';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import { SelectWithIcons } from '../../../components/shared/SelectWithIcons/SelectWithIcons';
import useWindowDimensions from '../../../hooks/widthHook';

export const LanguageSettings = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { language: lang } = useSelector((state: RootState) => state.profile);
  const lan = localStorage.getItem('lang') || 'en';
  const [currentLang, setCurrentLang] = useState<string>(
    languages?.filter((language: Language) => language.code === lang)[0]?.label,
  );
  const { width } = useWindowDimensions();

  const onSaveLanguage = () => {
    const selectedLanguage = languages.filter(
      (language: Language) => language?.label === currentLang,
    )[0];
    dispatch(changeLanguage(selectedLanguage.code));
  };

  return (
    <div className="accountSettings">
      <div className="accountSettings-title">
        {formatMessage({ id: 'changeLang' })}
      </div>
      <ErrorBoundary>
        <div
          className="accountSettings-list accountSettings-list--small"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: lan === 'ar' ? 'end' : 'start',
          }}
        >
          <p style={{ marginBottom: '8px' }}>
            {formatMessage({ id: 'changeLangLabel' })}
          </p>
          <SelectWithIcons
            options={languages}
            value={currentLang}
            setValue={setCurrentLang}
          />
        </div>
        <div className="accountSettings-footer">
          <MainButton
            text={formatMessage({ id: 'saveChanges' })}
            type="button"
            onClick={onSaveLanguage}
            styles={
              width < 1100
                ? {
                    width: '90%',
                    marginRight: '0',
                    position: 'absolute',
                    bottom: '110px',
                    left: '24px',
                  }
                : {}
            }
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};
