import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  deleteBook,
  getAllBooks,
  resetBookStatus,
} from '../../../redux/slices/relaxSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import CreateBook from './CreateBook/CreateBook';
import BookReader from './BookReader/BookReader';
import ModalWrapper from '../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../components/ModalConstructor/ModalHeader/ModalHeader';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './BooksModal.scss';

interface Props {
  setBooksModal: Dispatch<SetStateAction<boolean>>;
}

function BooksModal({ setBooksModal }: Props) {
  const [asAdmin, setAsAdmin] = useState<boolean>(false);
  const [book, setBook] = useState<Book>();
  const [bookReader, setBookReader] = useState<boolean>(false);
  const [createBook, setCreateBook] = useState<boolean>(false);
  const { allBooks, bookStatus } = useSelector(
    (state: RootState) => state.relax,
  );
  const { isAdmin } = useSelector((state: RootState) => state.profile);

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (bookStatus === 'success') {
      dispatch(getAllBooks());
      dispatch(resetBookStatus());
    }
  }, [dispatch, bookStatus]);

  useEffect(() => {
    if (isAdmin) {
      setAsAdmin(true);
    } else {
      setAsAdmin(false);
    }
  }, [isAdmin]);

  return (
    <>
      {!createBook && !bookReader && (
        <ModalWrapper>
          <ModalContainer
            styles={{
              backgroundColor: '#FBF5FF',
              borderRadius: '12px',
              padding: '0',
            }}
          >
            <ModalHeader
              mainColor
              withPadding
              textHeader={formatMessage({ id: 'bookReviewsTitle' })}
              onClose={() => setBooksModal(false)}
            />
            <div className="BooksModal">
              <div className="BooksModal__container">
                {allBooks.length ? (
                  <>
                    {allBooks.map((book: Book) => (
                      <div key={book.id} className="Book">
                        {asAdmin && (
                          <button
                            className="Book__deleteBtn"
                            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                              e.stopPropagation();
                              dispatch(deleteBook(book.id));
                            }}
                          >
                            <DeleteOutlineIcon />
                          </button>
                        )}
                        <button
                          className="Book__container"
                          type="button"
                          onClick={() => {
                            setBook(book);
                            setBookReader(true);
                          }}
                        >
                          <img
                            className="Book__cover"
                            src={
                              book.attachments && book.attachments.length
                                ? book.attachments[0].url
                                : ''
                            }
                            alt={book.title}
                            width="210px"
                            height="292px"
                          />
                          <div className="Book__info">
                            <p className="Book__title">{book.title}</p>
                            <p className="Book__writer">{book.author}</p>
                          </div>
                        </button>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="BooksModal__noBooks">
                    {formatMessage({ id: 'bookNoAdded' })}
                  </p>
                )}
              </div>
            </div>
            {asAdmin && (
              <div className="BooksModal__buttons">
                <MainButton
                  text={formatMessage({ id: 'addBook' })}
                  onClick={() => setCreateBook(true)}
                />
              </div>
            )}
          </ModalContainer>
        </ModalWrapper>
      )}
      {createBook && <CreateBook setCreateBook={setCreateBook} />}
      {book && bookReader && (
        <BookReader
          asAdmin={asAdmin}
          book={book}
          setBookReader={setBookReader}
          setBooksModal={setBooksModal}
        />
      )}
    </>
  );
}

export default BooksModal;
