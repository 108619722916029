import React, { useState, useEffect } from 'react';
import { AppDispatch, RootState } from '../../../redux/store';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import useWindowDimensions from '../../../hooks/widthHook';
import { MoreCommentsButton } from './MoreCommentsButton/MoreCommentsButton';
import TextAreaComments from '../../shared/TextAreaComments/TextAreaComments';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSubcomment,
  editComment,
  getSubComments,
  likeComment,
} from '../../../redux/slices/postsSlice';
import Subcomment from '../Subcomment/Subcomment';
import './Comment.scss';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { processTextInLinkForComment } from '../../helpers/linksProcessing';
import { useNavigateToTop } from '../../helpers/useNavigateToTop';
import { showLoader } from '../../../redux/slices/appSlice';
import { startTranslate } from '../../helpers/getTraslate';
// import likesvg from '../../../images/like-filled.svg';
// import ReactLinkify from 'react-linkify';

type Props = {
  comment: Comment;
};

const Comment: React.FC<Props> = ({ comment }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch<AppDispatch>();
  const navigateToTop = useNavigateToTop();
  const [translatedText, setTranslatedText] = useState(false);
  const [commentText, setCommentText] = useState("");
  const { id: userId, profileAvatar, anotherUserId } = useSelector((state: RootState) => state.profile);
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  const [skipSubcomments, setSkipSubcomments] = useState(0)

  const [writeSubcomment, setWriteSubcomment] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  // ===== TRANSLATE TEXT =====

  useEffect(() => {
    const getTranslateText = async (text: string, lang: string) => {
      if (translatedText) {
        dispatch(showLoader(true));

        const posts = await startTranslate(text, lang);
        dispatch(showLoader(false));

        setCommentText(posts);
      } else {
        setCommentText(text);
      }
    };

    getTranslateText(comment?.text, lan);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translatedText, comment]);

  // ===== EDIT COMMENT =====
  const [editingCommentText, setEditingCommentText] = useState(
    comment?.text || '',
  );
  const editCommentFunc = (id: number) => {
    if (editingCommentText?.trim()?.length === 0) {
      toast.info(formatMessage({ id: 'commentCantBeEmpty' }));
      return;
    }

    const objToEdit = {
      id: id,
      text: editingCommentText.trim(),
    } as any;


    if (anotherUserId.user && anotherUserId?.idOfPost === comment?.toPost?.id) {
      objToEdit.anotherUserId = anotherUserId.user
    };

    dispatch(
      editComment(objToEdit),
    );
    setEditingComment(false);
  };

  // ===== CREATE SUBCOMMENT =====
  const [showSubcomments, setShowSubcomments] = useState(false);
  const [subcommentText, setSubcommentText] = useState('');

  const createSubcommentFunc = (id: number) => {
    if (subcommentText?.trim()?.length === 0) {
      toast.info(formatMessage({ id: 'commentCantBeEmpty' }));
      return;
    }

    const objToCreate = {
      id: id,
      text: subcommentText.trim(),
    } as any;

    if (anotherUserId.user && anotherUserId?.idOfPost === comment?.toPost?.id) {
      objToCreate.anotherUserId = anotherUserId.user
    };

    dispatch(
      createSubcomment(objToCreate),
    );
    setSubcommentText('');
    setShowSubcomments(true);
    setWriteSubcomment(false);
  };

  // ===== LIKE === DISLIKE =====
  const likingFunc = (id: number) => {

    console.log(comment);
    const objToLike = {
      id: id,
      type: 'LIKE',
      ownerId: anotherUserId?.user ? anotherUserId?.user : userId,
      isSubcomment: false
    } as any;

    if (anotherUserId.user) {
      objToLike.anotherUserId = anotherUserId.user
    };

    dispatch(likeComment(objToLike));
  };
  const dislikingFunc = (id: number) => {
    const objToDislike = {
      id: id,
      type: 'DISLIKE',
      ownerId: anotherUserId?.user ? anotherUserId?.user : userId,
      isSubcomment: false
    } as any;

    if (anotherUserId.user) {
      objToDislike.anotherUserId = anotherUserId.user;
    };

    dispatch(likeComment(objToDislike));
  };

  // ===== DATE CONVERT =====
  const dateConvert = (created: string | undefined) => {
    if (created) {
      const date = new Date(created).toString().split(' ')[2];
      const month = new Date(created).toString().split(' ')[1];
      const time = new Date(created).toString().split(' ')[4].slice(0, 5);

      return `${date} ${month} at ${time}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <div
        className="comments-container"
        style={{
          flexDirection: (lan === 'ar' ? 'row-reverse' : 'row')
        }}
      >
        {editingComment ? (
          <>
            <div className="comments-avatar-container">
              <img className="comments-avatar" src={profileAvatar} alt="" />
            </div>
            <div className="comments-input-container">
              <TextAreaComments
                placeholder={formatMessage({ id: 'writeComment' })}
                defaultValue={comment?.text}
                value={editingCommentText}
                setValue={setEditingCommentText}
                createComment={() => editCommentFunc(comment.id)}
              />
              <SendTwoToneIcon
                className="comments-send"
                onClick={() => {
                  editCommentFunc(comment.id);
                }}
                style={{
                  right: lan === 'ar' ? 'none' : '17px',
                  left: lan === 'ar' ? '17px' : 'none',
                  transform: 'translateY(-65%)'
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="comments-avatar-container" onClick={() => navigateToTop(`/profile/${comment?.owner?.id}`)} style={{ cursor: "pointer" }}>
              <img
                className="comments-avatar"
                // width="32"
                // height="32"
                src={comment?.owner?.profileAvatar}
                alt="avatar"
              />
            </div>
              <div
                className="comments-wrapper"
                style={{
                  margin: width >= 380 ? (lan === 'ar' ? '0 16px 0 16px' : '0 0 0 16px') : (lan === 'ar' ? '0 13px 0 13px' : '0 0 0 13px'),
                  alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
                }}
              >
              <div className="comments-content">
                <div
                    className="comments-name-date-container"
                    style={{
                      flexDirection: width < 1100 ? 'column' : (lan === 'ar' ? 'row-reverse' : 'row'),
                      alignItems: width < 1100 ? (lan === 'ar' ? 'flex-end' : 'flex-start') : 'center',
                      gap: width < 1100 ? '0' : (lan === 'ar' ? '15px' : '0')
                    }}
                >
                  <div onClick={() => navigateToTop(`/profile/${comment?.owner?.id}`)} style={{ cursor: "pointer" }}>
                    <h2 className="comments-name">
                      {comment?.owner?.organizationName !== ''
                        ? `${comment?.owner?.organizationName}`
                        : `${comment?.owner?.firstName} ${comment?.owner?.lastName}`}
                    </h2>
                  </div>
                  <span className="comments-date">
                    {dateConvert(comment?.created)}
                  </span>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: processTextInLinkForComment(commentText?.replaceAll('\n', ' <br/> ')),
                  }}
                  className="comments-text"
                  style={{
                    textAlign: (lan === 'ar') ? 'right' : 'left',
                    width: '100%'
                  }}
                >
                </p>
              </div>

              <div className="comments-likes-container">
                <button
                  className={`comments-likes-text-wrapper ${comment?.liking?.filter(
                    like =>
                      like.fromUser?.id === userId && like.type === 'LIKE',
                  ).length
                    ? 'sameUser'
                    : ''
                    }`}
                  onClick={() => {
                    likingFunc(comment.id);
                  }}
                >
                  <svg
                    className="comments-likes-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.8422 8.33917C14.1047 7.9923 14.25 7.5673 14.25 7.12511C14.25 6.42355 13.8578 5.75948 13.2266 5.38917C13.0641 5.29385 12.879 5.24368 12.6906 5.24386H8.94375L9.0375 3.32355C9.05938 2.85948 8.89531 2.41886 8.57656 2.08292C8.42013 1.91734 8.23141 1.7856 8.02206 1.69584C7.8127 1.60607 7.58716 1.5602 7.35938 1.56105C6.54688 1.56105 5.82812 2.10792 5.6125 2.89073L4.27031 7.75011H4.26562V14.4376H11.6453C11.7891 14.4376 11.9297 14.4095 12.0594 14.3532C12.8031 14.036 13.2828 13.3095 13.2828 12.5032C13.2828 12.3064 13.2547 12.1126 13.1984 11.9251C13.4609 11.5782 13.6062 11.1532 13.6062 10.711C13.6062 10.5142 13.5781 10.3204 13.5219 10.1329C13.7844 9.78605 13.9297 9.36105 13.9297 8.91886C13.9266 8.72198 13.8984 8.52667 13.8422 8.33917ZM1.75 8.25011V13.9376C1.75 14.2142 1.97344 14.4376 2.25 14.4376H3.26562V7.75011H2.25C1.97344 7.75011 1.75 7.97355 1.75 8.25011Z" />
                  </svg>
                  <span className="comments-likes-text">
                    {formatMessage({ id: 'like' })} (
                    {
                      comment?.liking?.filter(like => like.type === 'LIKE')
                        .length
                    }
                    )
                  </span>
                </button>

                <div
                  className={`comments-likes-text-wrapper ${comment?.liking?.filter(
                    like =>
                      like.fromUser?.id === userId && like.type === 'DISLIKE',
                  ).length
                    ? 'sameUser'
                    : ''
                    }`}
                  onClick={() => {
                    dislikingFunc(comment.id);
                  }}
                >
                  <svg
                    className="comments-likes-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.8422 8.32744C13.8984 8.13994 13.9266 7.94619 13.9266 7.74932C13.9266 7.30713 13.7812 6.88213 13.5188 6.53525C13.575 6.34775 13.6031 6.154 13.6031 5.95713C13.6031 5.51494 13.4578 5.08994 13.1953 4.74307C13.2516 4.55557 13.2797 4.36182 13.2797 4.16494C13.2797 3.35869 12.8 2.63213 12.0562 2.31494C11.9255 2.25858 11.7845 2.22985 11.6422 2.23057H4.26562V8.91807H4.27031L5.61094 13.7743C5.82656 14.5571 6.54531 15.104 7.35781 15.104C7.82188 15.104 8.25469 14.9196 8.575 14.5821C8.89531 14.2462 9.05937 13.8056 9.03594 13.3415L8.94219 11.4212H12.6906C12.8797 11.4212 13.0641 11.3712 13.2266 11.2759C13.8578 10.9087 14.25 10.2431 14.25 9.5415C14.25 9.09932 14.1047 8.67432 13.8422 8.32744ZM1.75 2.729V8.4165C1.75 8.69307 1.97344 8.9165 2.25 8.9165H3.26562V2.229H2.25C1.97344 2.229 1.75 2.45244 1.75 2.729Z" />
                  </svg>

                  <span className="comments-likes-text">
                    {formatMessage({ id: 'dislike' })} (
                    {
                      comment?.liking?.filter(like => like.type === 'DISLIKE')
                        .length
                    }
                    )
                  </span>
                </div>
                <div
                  className="comments-likes-text-wrapper"
                  onClick={() => setWriteSubcomment(!writeSubcomment)}
                >
                  <svg
                    className="comments-likes-icon"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.66667 6.00016V3.3335L2 8.00016L6.66667 12.6668V9.9335C10 9.9335 12.3333 11.0002 14 13.3335C13.3333 10.0002 11.3333 6.66683 6.66667 6.00016Z" />
                  </svg>

                  <span className="comments-likes-text">{formatMessage({ id: 'reply' })}</span>
                </div>
              </div>
            </div>
              <div
                className="comments-MoreHorizIcon-wrapper"
                style={{

                }}
              >
              <MoreCommentsButton
                comment={comment}
                translatedText={translatedText}
                setTranslatedText={setTranslatedText}
                setEditingComment={setEditingComment}
              />
            </div>
          </>
        )}
      </div>

      {writeSubcomment && (
        <div
          className="write-subcomment"
          style={{
            margin: lan === 'ar' ? '0 60px 0 0' : '0 0 0 60px',
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
          }}
        >
          <div className="write-subcomment-avatar-container">
            <img
              className="write-subcomment-avatar"
              src={anotherUserId?.image ? anotherUserId?.image : profileAvatar}
              alt=""
            />
          </div>

          <div className="write-subcomment-input-container">
            <TextAreaComments
              placeholder={formatMessage({ id: 'writeReply' })}
              value={subcommentText}
              setValue={setSubcommentText}
              createComment={() => createSubcommentFunc(comment.id)}
              subcomment={true}
            />
            <SendTwoToneIcon
              className="write-subcomment-send"
              onClick={() => createSubcommentFunc(comment.id)}
              style={{
                right: lan === 'ar' ? 'none' : '17px',
                left: lan === 'ar' ? '17px' : 'none',
                transform: 'translateY(-65%)'
              }}
            />
          </div>
        </div>
      )}

      {comment?.counter === 0 && !comment?.subcomments?.length ?
        <div
          className="subcommentShow-show-subcomments-text"
          style={{
            textAlign: lan === 'ar' ? 'right' : 'left',
            margin: lan === 'ar' ? '0 60px 0 0' : '0 0 0 60px'
          }}
        >
          {formatMessage({ id: 'noReplies' })}
        </div>
        : (

          <div
            className="subcommentShow"
            onClick={() => {
              setShowSubcomments(!showSubcomments);
              setSkipSubcomments(0)
              dispatch(getSubComments({ commentId: comment.id, take: 5, skip: 0, postId: comment.toPost?.id }));
            }}
          >
            <div
              className="subcommentShow-show-subcomments-btn"
              style={{
                cursor: `${comment?.subcomments && 'pointer'}`,
                textAlign: lan === 'ar' ? 'right' : 'left',
                margin: lan === 'ar' ? '0 60px 0 0' : '0 0 0 60px'

              }}
            >
              {!showSubcomments && formatMessage({ id: 'showReplies' })}
              {showSubcomments && formatMessage({ id: 'hideReplies' })}
            </div>
          </div>
        )}


      {comment?.subcomments?.length > 0 && showSubcomments && (
        <>
          {comment?.subcomments.map((subcomment, index) => (
            <Subcomment
              subcomment={subcomment}
              key={index}
            />
          ))}

          <div
            className="subcommentShow"
            onClick={() => {
              dispatch(getSubComments({ commentId: comment.id, take: 5, skip: skipSubcomments + 5, postId: comment.toPost?.id }));
              setSkipSubcomments(prev => prev += 5)
            }}
          >
            <div
              className="subcommentShow-show-subcomments-text"
              style={{
                cursor: `${comment?.subcomments && 'pointer'}`,
                textAlign: lan === 'ar' ? 'right' : 'left'
              }}
            >
              {comment?.subcomments?.length >= 5
                && comment?.subcomments?.length < (comment?.counter || 0)
                && formatMessage({ id: 'showMore' })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Comment;
