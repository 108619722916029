import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { MoreSubcommentsButton } from './MoreSubcommentsButton/MoreSubcommentsButton ';
import { processTextInLinkForComment } from '../../helpers/linksProcessing';
import { useNavigateToTop } from '../../helpers/useNavigateToTop';
import { startTranslate } from '../../helpers/getTraslate';
import TextAreaComments from '../../shared/TextAreaComments/TextAreaComments';
import ReportModal from '../../shared/ReportModal/ReportModal';
import { editComment, likeComment } from '../../../redux/slices/postsSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { showLoader } from '../../../redux/slices/appSlice';
import useWindowDimensions from '../../../hooks/widthHook';
import './Subcomment.scss';

// import ModalContainer from '../../ModalConstructor/ModalContainer/ModalContainer';
// import ModalWrapper from '../../ModalConstructor/ModalWrapper/ModalWrapper';
// import ModalHeader from '../../ModalConstructor/ModalHeader/ModalHeader';
// import ModalFooter from '../../ModalConstructor/ModalFooter/ModalFooter';
// import SecondaryButton from '../../shared/Buttons/SecondaryButton/SecondaryButton';
// import MainButton from '../../shared/Buttons/MainButton/MainButton';
// import CustomInput from '../../shared/CustomInput/CustomInput';
// import { reportComment } from '../../../api/posts';

interface Props {
  subcomment: Subcomment;
}

const Subcomment: FC<Props> = ({ subcomment }) => {
  const { formatMessage } = useIntl();
  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();
  const [translatedSubText, setTranslatedSubText] = useState(false);
  const [subcommentText, setSubcommentText] = useState("");
  const lan = localStorage.getItem('lang') || 'en';
  const { width } = useWindowDimensions();

  const { id: userId } = useSelector((state: RootState) => state.profile);
  const { profileAvatar, anotherUserId } = useSelector((state: RootState) => state.profile);

  // ===== TRANSLATE TEXT =====

  useEffect(() => {
    const getTranslateText = async (text: string, lang: string) => {
      if (translatedSubText) {
        dispatch(showLoader(true));

        const posts = await startTranslate(text, lang);
        dispatch(showLoader(false));

        setSubcommentText(posts);
      } else {
        setSubcommentText(text);
      }
    };

    getTranslateText(subcomment?.text, lan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translatedSubText, subcomment]);

  // ========Same user check===========
  const [sameUserId, setSameUserId] = useState(false);
  useEffect(() => {
    if (subcomment.owner.id === userId) {
      setSameUserId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ===== EDIT COMMENT =====
  const [editingSubcomment, setEditingSubcomment] = useState(false);
  const [editingSubcommentText, setEditingSubcommentText] = useState(subcomment?.text || '');
  const editCommentFunc = (id: number) => {
    if (editingSubcommentText?.trim()?.length === 0) {
      toast.info(formatMessage({ id: 'commentCantBeEmpty' }));
      return;
    }
    const objToEdit = {
      id: id,
      text: editingSubcommentText.trim(),
    } as any;


    if (anotherUserId.user) {
      objToEdit.anotherUserId = anotherUserId.user
    };

    dispatch(
      editComment(objToEdit),
    );

    setEditingSubcomment(false);
  }

  // ===== LIKE === DISLIKE =====
  const likingFunc = (id: number) => {
    dispatch(
      likeComment({
        ownerId: userId,
        id: id,
        type: 'LIKE',
        isSubcomment: true,
      }),
    );
  };
  const dislikingFunc = (id: number) => {
    dispatch(
      likeComment({
        id: id,
        type: 'DISLIKE',
        ownerId: userId,
        isSubcomment: true
      }),
    );
  };

  // ===== DATE CONVERT =====
  const dateConvert = (created: string | undefined) => {
    if (created) {
      const date = new Date(created).toString().split(' ')[2];
      const month = new Date(created).toString().split(' ')[1];
      const time = new Date(created).toString().split(' ')[4].slice(0, 5);

      return `${date} ${month} at ${time}`;
    } else {
      return '';
    }
  };

  // ==========Report===========
  const [reportModal, setReportModal] = useState(false);

  // const [reportReason, setReportReason] = useState('');
  // const handleReport = () => {
  //   reportComment({ commentId: subcomment.id, reason: reportReason });
  //   setReportModal(false);
  // };

  return (
    <div
      className="subcomments-container"
      style={{
        flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
        margin: width >= 380 ? (lan === 'ar' ? '0 45px 0 0' : '0 0 0 45px') : (lan === 'ar' ? '0 30px 0 0' : '0 0 0 30px'),
      }}
    >
      {editingSubcomment ? (
        <>
          <div className="comments-avatar-container" onClick={() => navigateToTop(`/profile/${subcomment?.owner?.id}`)} style={{ cursor: "pointer" }}>
            <img
              className="comments-avatar"
              src={profileAvatar}
              alt="avatar"
            />
          </div>
          <div className="comments-input-container">
            <TextAreaComments
              placeholder={formatMessage({ id: 'writeComment' })}
              defaultValue={subcomment?.text}
              value={editingSubcommentText}
              setValue={setEditingSubcommentText}
              createComment={() => editCommentFunc(subcomment.id)}
            />
            <SendTwoToneIcon
              className="comments-send"
              onClick={() => {
                editCommentFunc(subcomment.id);
              }}
              style={{
                right: lan === 'ar' ? 'none' : '17px',
                left: lan === 'ar' ? '17px' : 'none',
                transform: 'translateY(-65%)'
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="subcomments-avatar-container" onClick={() => navigateToTop(`/profile/${subcomment?.owner?.id}`)} style={{ cursor: "pointer" }}>
            <img
              className="subcomments-avatar"
              src={subcomment?.owner?.profileAvatar}
              alt="avatar"
            />
          </div>

          <div
            className="subcomments-wrapper"
            style={{
              margin: width >= 380 ? (lan === 'ar' ? '0 16px 0 16px' : '0 0 0 16px') : (lan === 'ar' ? '0 13px 0 13px' : '0 0 0 13px'),
              alignItems: lan === 'ar' ? 'flex-end' : 'flex-start',
              flexGrow: 1
            }}
          >
            <div className="subcomments-content">
              <div className="subcomments-name-date-container"
                style={{
                  flexDirection: width < 1100 ? 'column' : (lan === 'ar' ? 'row-reverse' : 'row'),
                  alignItems: width < 1100 ? (lan === 'ar' ? 'flex-end' : 'flex-start') : 'center',
                  gap: width < 1100 ? '0' : (lan === 'ar' ? '15px' : '0')
                }}
              >
                <div
                  onClick={() => navigateToTop(`/profile/${subcomment?.owner?.id}`)} style={{ cursor: "pointer" }}
                >
                  <h2 className="subcomments-name">
                    {subcomment?.owner?.organizationName !== ""
                      ? `${subcomment?.owner?.organizationName}`
                      : `${subcomment?.owner?.firstName} ${subcomment?.owner?.lastName}`}
                  </h2>
                </div>
                <span
                  className="subcomments-date"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end'
                  }}
                >
                  {dateConvert(subcomment?.created)}
                </span>
              </div>
              <p
                className="comments-text"
                dangerouslySetInnerHTML={{ __html: processTextInLinkForComment(subcommentText?.replaceAll("\n", " <br/> ")) }}
                style={{
                  textAlign: (lan === 'ar') ? 'right' : 'left',
                  width: '100%'
                }}
              />
            </div>

            <div className="subcomments-likes-container">
              <div className={`subcomments-likes-text-wrapper${subcomment?.liking?.filter((like: Liking) =>
                like.fromUser?.id === userId && like.type === 'LIKE').length ?
                ' sameUser' : ''}`}
                onClick={() => {
                  likingFunc(subcomment.id);
                }}
              >
                <svg
                  className="subcomments-likes-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.8422 8.33917C14.1047 7.9923 14.25 7.5673 14.25 7.12511C14.25 6.42355 13.8578 5.75948 13.2266 5.38917C13.0641 5.29385 12.879 5.24368 12.6906 5.24386H8.94375L9.0375 3.32355C9.05938 2.85948 8.89531 2.41886 8.57656 2.08292C8.42013 1.91734 8.23141 1.7856 8.02206 1.69584C7.8127 1.60607 7.58716 1.5602 7.35938 1.56105C6.54688 1.56105 5.82812 2.10792 5.6125 2.89073L4.27031 7.75011H4.26562V14.4376H11.6453C11.7891 14.4376 11.9297 14.4095 12.0594 14.3532C12.8031 14.036 13.2828 13.3095 13.2828 12.5032C13.2828 12.3064 13.2547 12.1126 13.1984 11.9251C13.4609 11.5782 13.6062 11.1532 13.6062 10.711C13.6062 10.5142 13.5781 10.3204 13.5219 10.1329C13.7844 9.78605 13.9297 9.36105 13.9297 8.91886C13.9266 8.72198 13.8984 8.52667 13.8422 8.33917ZM1.75 8.25011V13.9376C1.75 14.2142 1.97344 14.4376 2.25 14.4376H3.26562V7.75011H2.25C1.97344 7.75011 1.75 7.97355 1.75 8.25011Z" />
                </svg>
                <span className="subcomments-likes-text">
                  {formatMessage({ id: 'like' })} (
                  {
                    subcomment?.liking?.filter((like: Liking) => like.type === 'LIKE')
                      .length
                  }
                  )
                </span>
              </div>

              <div
                className={`subcomments-likes-text-wrapper${subcomment?.liking?.filter((like: Liking) =>
                  like.fromUser?.id === userId && like.type === 'DISLIKE').length ?
                  ' sameUser' : ''}`}
                onClick={() => {
                  dislikingFunc(subcomment.id);
                }}
              >
                <svg
                  className="subcomments-likes-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.8422 8.32744C13.8984 8.13994 13.9266 7.94619 13.9266 7.74932C13.9266 7.30713 13.7812 6.88213 13.5188 6.53525C13.575 6.34775 13.6031 6.154 13.6031 5.95713C13.6031 5.51494 13.4578 5.08994 13.1953 4.74307C13.2516 4.55557 13.2797 4.36182 13.2797 4.16494C13.2797 3.35869 12.8 2.63213 12.0562 2.31494C11.9255 2.25858 11.7845 2.22985 11.6422 2.23057H4.26562V8.91807H4.27031L5.61094 13.7743C5.82656 14.5571 6.54531 15.104 7.35781 15.104C7.82188 15.104 8.25469 14.9196 8.575 14.5821C8.89531 14.2462 9.05937 13.8056 9.03594 13.3415L8.94219 11.4212H12.6906C12.8797 11.4212 13.0641 11.3712 13.2266 11.2759C13.8578 10.9087 14.25 10.2431 14.25 9.5415C14.25 9.09932 14.1047 8.67432 13.8422 8.32744ZM1.75 2.729V8.4165C1.75 8.69307 1.97344 8.9165 2.25 8.9165H3.26562V2.229H2.25C1.97344 2.229 1.75 2.45244 1.75 2.729Z" />
                </svg>

                <span className="subcomments-likes-text">
                  {formatMessage({ id: 'dislike' })} (
                  {
                    subcomment?.liking?.filter(like => like.type === 'DISLIKE')
                      .length
                  }
                  )
                </span>
              </div>

              <div
                className={`subcomments-likes-text-wrapper${sameUserId ? ' sameUserId' : ''}`}
                style={{
                  display: width >= 1100 ? 'flex' : 'none'
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M8.24033 4H4.66699V8H9.49366L9.76033 9.33333H12.0003V5.33333H8.50699L8.24033 4Z"
                    fill="#363636"
                  />
                  <path
                    d="M9.59967 3.99984L9.33301 2.6665H3.33301V13.9998H4.66634V9.33317H8.39967L8.66634 10.6665H13.333V3.99984H9.59967ZM11.9997 9.33317H9.75967L9.49301 7.99984H4.66634V3.99984H8.23967L8.50634 5.33317H11.9997V9.33317Z"
                    fill="#6C757D"
                  />
                </svg>

                <span
                  className="subcomments-likes-text"
                  onClick={() => {
                    setReportModal(true);
                  }}>
                  {formatMessage({ id: 'report' })}
                </span>
              </div>

            </div>
          </div>

          <div
            className="subcomments-MoreHorizIcon-wrapper"
            style={{
              margin: lan === 'ar' ? '0 12px 0 0' : '0 0 0 12px'
            }}
          >
            <MoreSubcommentsButton
              subcomment={subcomment}
              translatedText={translatedSubText}
              setTranslatedText={setTranslatedSubText}
              setEditingSubcomment={setEditingSubcomment}
            />
          </div>
        </>
      )}
      {reportModal && (
        <ReportModal
          commentId={subcomment.id}
          setReportModal={setReportModal}
        />
        // <ModalWrapper width={350}>
        //   <ModalContainer>
        //     <ModalHeader
        //       textHeader={formatMessage({ id: 'reportConfirm' })}
        //       smallCross
        //       onClose={() => setReportModal(false)}
        //     />
        //     <CustomInput
        //       width='100%'
        //       placeholder={formatMessage({ id: 'reportReason' })}
        //       label={formatMessage({ id: 'reportReasonLabel' })}
        //       onChangeFunc={(value: string) => { setReportReason(value) }}
        //     />
        //     <ModalFooter>
        //       <SecondaryButton
        //         text={formatMessage({ id: 'report' })}
        //         onClick={handleReport}
        //         bigPadding
        //       />
        //       <MainButton
        //         text={formatMessage({ id: 'cancel' })}
        //         onClick={() => setReportModal(false)}
        //         bigPadding
        //       />
        //     </ModalFooter>
        //   </ModalContainer>
        // </ModalWrapper>
      )}
    </div>
  );
};

export default Subcomment;
