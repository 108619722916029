import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { setLastActivity } from './redux/slices/postsSlice';
import { IntlProvider } from 'react-intl';
import localization from './localization';
import {
  getAllGroups,
  getAllInterest,
  getPostTypes,
  incrementNotificationCounter,
  setNotificationCounter,
} from './redux/slices/appSlice';
import { API } from './api/api';
import { AppDispatch, RootState } from './redux/store';
import { Loader } from './components/Loader';
import Main from './components/Main/Main';
import BackToTop from './components/BackToTop/BackToTop';
import Notify from './components/shared/Notify/Notify';

import { getBlockedUsersAPI, getNotificationsCounter, getUserInfoAPI } from './api/user';
import { setAccountSettings, setBlockedUsers, setOnlineStatus } from './redux/slices/profileSlice';
import { addNewMessage, bottomScroll, getAllChats, increaseCountOfMsgs } from './redux/slices/chatSlice';

import './App.scss';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showButton, setShowButton] = useState(false);
  const { isLoading } = useSelector((state: RootState) => state.app);
  const { id, language: lang, isAuth } = useSelector((state: RootState) => state.profile);
  const lan = localStorage.getItem('lang') || 'en';

  const [newSocket, setNewSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (lan === 'ar' || lan === 'hi') {
      document.body.style.fontFamily = 'Hind, sans-serif';
      if (lan === 'ar') {
        document.body.style.textAlign = 'right';
      } else {
        document.body.style.textAlign = 'left';
      }
    } else {
      document.body.style.fontFamily = 'Open Sans, sans-serif';
      document.body.style.textAlign = 'left';
    }
  }, [lan]);

  useEffect(() => {
    dispatch(getAllInterest());
    dispatch(getAllGroups());
    dispatch(getPostTypes());

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  useEffect(() => {
    let token = localStorage.getItem('token');

    if (id && isAuth) {
      const socket = io(API, {
        query: {
          id: id,
        },
        secure: true,
        reconnectionAttempts: 4,
        transports: ['websocket']
      });

      setNewSocket(socket);

      socket.on('connection', (socket: any) => {
        console.log('yahoo')
      })

      socket.on('set_reaction', (data) => {
        dispatch(incrementNotificationCounter())
        dispatch(setLastActivity(data));
      });

      socket.on('create_comment', (data) => {
        dispatch(incrementNotificationCounter())
        dispatch(setLastActivity(data));
      });

      socket.on('online', (data) => {
        dispatch(setOnlineStatus(data.users));
      });


      socket.on('newMessage', (data: any) => {
        if (data?.ownerId !== id) {
          dispatch(increaseCountOfMsgs(data));
        }

        if (data?.chatId) {
          dispatch(addNewMessage(data));
          dispatch(bottomScroll(true));
        }
      })

      socket.on('newChat', (data: any) => {
        dispatch(getAllChats());
      })
    };

    if (token) {
      getUserInfoAPI().then(res => {
        if (res.status === 200) {
          dispatch(setAccountSettings({
            ...res.data._user,
            following: {
              following: res.data.following?.map((item: any) => item.following),
              isLocked: false
            }
          }))
        }
      });
      getBlockedUsersAPI().then(res => {
        if (res.status === 200) {
          dispatch(setBlockedUsers((res.data)))
        }
      })
      getNotificationsCounter().then(
        res => {
          if(typeof res.data === 'number'){
            dispatch(setNotificationCounter(res.data))
          }
        } 
      )
    };
  }, [dispatch, id, isAuth]);

  return (
    <IntlProvider
      messages={localization[lang]}
      locale={lang}
    >
      <BrowserRouter>
        {isLoading && <Loader />}
        <div style={{ position: 'relative' }}>
          <SocketContext.Provider value={newSocket}>
            <Main />
          </SocketContext.Provider>
          {showButton && <BackToTop />}
        </div>
        <Notify />
      </BrowserRouter>
    </IntlProvider>
  );
};

export default App;
