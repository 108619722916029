import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import {
  addNewPostType,
  resetNewPostTypeStatus,
} from '../../redux/slices/adminSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { Cloudinary_NAME, Cloudinary_PRESET, defaultBackground, uploadIconFormats, uploadImageFormats } from '../../constants';
import { tippyIconDescriptionKeys, tippyImageDescriptionKeys } from '../../constants/notifyText';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import TippyComponent from '../shared/TippyComponent/TippyComponent';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import CustomInput from '../shared/CustomInput/CustomInput';
import { Loader } from '../Loader';
import { ReactComponent as AddMedia } from '../../images/add-photo-video.svg';
import './AddNewPostTypeModal.scss';
interface AddNewPostTypeModalProps {
  close: Dispatch<SetStateAction<boolean>>;
}
interface InputForm {
  newPostTypeName: string;
}

function AddNewPostTypeModal({ close }: AddNewPostTypeModalProps) {
  const [newPostTypeText, setNewPostTypeText] = useState<string>('');
  const [newPostTypeImage, setNewPostTypeImage] = useState<string>('https://res.cloudinary.com/dsypundib/image/upload/v1666348695/static%20images/zk5ocpflmcxxejyjjvd8_wvk1xd.png');
  const [newPostTypeBackground, setNewPostTypeBackground] = useState<string>(defaultBackground);
  const [loader, setLoader] = useState<boolean>(false);
  const { newPostTypeStatus } = useSelector((state: RootState) => state.admin);
  const lan = localStorage.getItem('lang') || 'en';

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const {
    register,
    resetField,
    handleSubmit,
    formState: {
      isValid,
      errors,
    }
  } = useForm<InputForm>({ mode: 'onChange' });

  useEffect(() => {
    if (newPostTypeStatus === 'loading') {
      setLoader(true);
    }
    if (newPostTypeStatus === 'success') {
      setNewPostTypeText('');
      setNewPostTypeImage('');
      resetField('newPostTypeName');
      close(false);
      setLoader(false);
      dispatch(resetNewPostTypeStatus());
    }
    if (newPostTypeStatus === 'rejected') {
      setLoader(false);
      dispatch(resetNewPostTypeStatus());
    }
  }, [close, dispatch, newPostTypeStatus, resetField]);

  const onAddImage = async (
    image: FileList | null,
    setImage: Dispatch<SetStateAction<string>>,
  ) => {
    if (image === null) return;

    const imageSize = image[0].size / (1024 ** 2);
    if (imageSize > 5) {
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }
    const formData = new FormData();
    formData.append('file', image[0]);
    formData.append('upload_preset', Cloudinary_PRESET);

    await axios
      .post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, formData)
      .then((response: AxiosResponse<any>) => {
        setImage(response.data.secure_url);
      })
      .catch(() => {
        toast.error(formatMessage({ id: 'errorCreateOrEditPost' }));
        setImage('');
      });
  };

  const onSubmit = (data: InputForm) => {
    dispatch(addNewPostType({
      typeName: data.newPostTypeName,
      url: newPostTypeImage,
      avatar: newPostTypeImage,
      background: newPostTypeBackground,
    }));
  };

  return (
    <>
      {!loader ?
        <ModalWrapper>
          <ModalContainer>
            <ModalHeader
              textHeader={formatMessage({ id: 'createNewPostType' })}
              onClose={() => close(false)}
            />
            <form
              className="AddNewPostTypeModal"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CustomInput
                placeholder={formatMessage({ id: 'writeNameHere' })}
                label={formatMessage({ id: 'nameOfNewPostType' })}
                inputName="newPostTypeName"
                onChangeFunc={(value: string) => setNewPostTypeText(value)}
                errors={errors}
                inputProps={{
                  ...register('newPostTypeName', {
                    required: formatMessage({ id: 'fieldIsEmpty' }),
                    maxLength: 50,
                  }),
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <TippyComponent
                object={
                  <label className="AddNewPostTypeModal__uploadBtn">
                    <AddMedia className="AddNewPostTypeModal__uploadBtn-icon" />
                    <span className="AddNewPostTypeModal__uploadBtn-text">
                      {newPostTypeImage ?
                        formatMessage({ id: 'changePicturePostTypeTag' }) :
                        formatMessage({ id: 'addPicturePostTypeTag' })}
                    </span>
                    <input
                      multiple
                      name="uploadedImg"
                      type="file"
                      accept={uploadIconFormats}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onAddImage(e.target.files, setNewPostTypeImage);
                      }}
                      style={{ display: 'none' }}
                    />
                  </label>
                }
                text={
                  <ul>
                    {tippyIconDescriptionKeys
                      .map((item: string, index: number) => (
                        <li key={index}>{formatMessage({ id: item })}</li>
                      ))}
                  </ul>
                }
                placement="left"
                maxWidth={300}
              />
              {(newPostTypeImage || newPostTypeText) ?
                <div className="AddNewPostTypeModal__preview">
                  <h4 className="AddNewPostTypeModal__preview-title">
                    {formatMessage({ id: 'newTagWillLook' })}
                  </h4>
                  <div className="AddNewPostTypeModal__preview-content">
                    {newPostTypeImage ?
                      <img
                        className="AddNewPostTypeModal__preview-img"
                        src={newPostTypeImage}
                        alt="preview"
                      /> :
                      <div className="AddNewPostTypeModal__preview-img" />}
                    <span className="AddNewPostTypeModal__preview-text">
                      {newPostTypeText}
                    </span>
                  </div>
                </div> : null}
              <TippyComponent
                object={
                  <label className="AddNewPostTypeModal__uploadBtn">
                    <AddMedia className="AddNewPostTypeModal__uploadBtn-icon" />
                    <span className="AddNewPostTypeModal__uploadBtn-text">
                      {formatMessage({ id: 'changeBackgroundPicture' })}
                    </span>
                    <input
                      multiple
                      name="uploadedImg"
                      type="file"
                      accept={uploadImageFormats}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onAddImage(e.target.files, setNewPostTypeBackground);
                      }}
                      style={{ display: 'none' }}
                    />
                  </label>
                }
                text={
                  <ul>
                    {tippyImageDescriptionKeys
                      .map((item: string, index: number) => (
                        <li key={index}>{formatMessage({ id: item })}</li>
                      ))}
                  </ul>
                }
                placement="left"
                maxWidth={300}
              />
              <img
                className="AddNewPostTypeModal__background"
                src={newPostTypeBackground}
                alt="Background"
              />
              <ModalFooter>
                <MainButton
                  type="submit"
                  disabled={(!isValid && newPostTypeImage) ? true : false}
                  text={formatMessage({ id: 'createPostType' })}
                />
              </ModalFooter>
            </form>
          </ModalContainer>
        </ModalWrapper> :
        <Loader />}
    </>
  )
}

export default AddNewPostTypeModal;
