import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useNavigateToTop } from '../../../components/helpers/useNavigateToTop';
import ModalContainer from '../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../../components/ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import {
  activeProfile,
  activeProfileOnCommentPage,
  deactiveProfile,
  deactiveProfileOnCommentPage,
  deleteReportedComment,
  skipReportToComment,
} from '../../../redux/slices/adminSlice';
import { CommentReportReasons } from '../CommentReportReasons/CommentReportReasons';
import { AppDispatch, RootState } from '../../../redux/store';
import { processTextInLinkForComment } from '../../../components/helpers/linksProcessing';
import { useDispatch } from 'react-redux';
import './ReportedComment.scss';
import { toast } from 'react-toastify';

type Props = {
  reportedComment: ReportedComment;
};

const ReportedComment: React.FC<Props> = ({ reportedComment }) => {
  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();

  const [commentReportReasonsModal, setCommentReportReasonsModal] = useState<
    number | null
  >(null);
  const [disableUserModal, setDisableUserModal] = useState<boolean | null>(
    null,
  );
  const [deleteCommentModal, setDeleteCommentModal] = useState<boolean | null>(
    null,
  );
  const [skipReportModal, setSkipReportModal] = useState<boolean | null>(null);
  const [reportedCommentId, setReportedCommentId] = useState<number | null>(
    null,
  );

  // console.log('reportedCommentId', reportedCommentId)
  const reportedCommentRequestBody = {
    commentId: reportedCommentId,
  };

  const handleSkipReport = () => {
    if (reportedCommentId) {
      dispatch(skipReportToComment(reportedCommentRequestBody));
      setSkipReportModal(null);
    }
    setSkipReportModal(null);
  };

  const handleDeleteReportedComment = () => {
    if (reportedCommentId) {
      dispatch(deleteReportedComment(reportedCommentRequestBody));
      setDeleteCommentModal(null);
    }
    setDeleteCommentModal(null);
  };

  // ===== DATE CONVERT =====
  const dateConvert = (created: string | undefined) => {
    if (created) {
      const date = new Date(created).toString().split(' ')[2];
      const month = new Date(created).toString().split(' ')[1];
      const time = new Date(created).toString().split(' ')[4].slice(0, 5);

      return `${date} ${month} at ${time}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <div className="reportedComments-container">
        <div style={{ display: 'flex' }}>
          <div
            className="reportedComments-avatar-container"
            onClick={() =>
              navigateToTop(`/profile/${reportedComment?.reportedUser?.id}`)
            }
            style={{ cursor: 'pointer' }}
          >
            <img
              className="reportedComments-avatar"
              // width="32"
              // height="32"
              src={reportedComment?.reportedUser?.profileAvatar}
              alt="avatar"
            />
          </div>

          <div className="reportedComments-wrapper">
            <div className="reportedComments-content">
              <div className="reportedComments-name-date-container">
                <div
                  onClick={() =>
                    navigateToTop(
                      `/profile/${reportedComment?.reportedUser?.id}`,
                    )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <h2 className="reportedComments-name">
                    {reportedComment?.reportedUser?.organizationName !== ''
                      ? `${reportedComment?.reportedUser?.organizationName}`
                      : `${reportedComment?.reportedUser?.firstName} ${reportedComment?.reportedUser?.lastName}`}
                  </h2>
                </div>
                <span className="reportedComments-date">
                  {dateConvert(reportedComment?.comment?.created)}
                </span>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: processTextInLinkForComment(reportedComment?.comment?.text?.replaceAll(
                    '\n',
                    '<br/>',
                  )),
                }}
                className="reportedComments-text"
              ></p>
            </div>

            <div className="reportedComments-likes-container">
              <div className="reportedComments-likes-text-wrapper">
                <svg
                  className="reportedComments-likes-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.8422 8.33917C14.1047 7.9923 14.25 7.5673 14.25 7.12511C14.25 6.42355 13.8578 5.75948 13.2266 5.38917C13.0641 5.29385 12.879 5.24368 12.6906 5.24386H8.94375L9.0375 3.32355C9.05938 2.85948 8.89531 2.41886 8.57656 2.08292C8.42013 1.91734 8.23141 1.7856 8.02206 1.69584C7.8127 1.60607 7.58716 1.5602 7.35938 1.56105C6.54688 1.56105 5.82812 2.10792 5.6125 2.89073L4.27031 7.75011H4.26562V14.4376H11.6453C11.7891 14.4376 11.9297 14.4095 12.0594 14.3532C12.8031 14.036 13.2828 13.3095 13.2828 12.5032C13.2828 12.3064 13.2547 12.1126 13.1984 11.9251C13.4609 11.5782 13.6062 11.1532 13.6062 10.711C13.6062 10.5142 13.5781 10.3204 13.5219 10.1329C13.7844 9.78605 13.9297 9.36105 13.9297 8.91886C13.9266 8.72198 13.8984 8.52667 13.8422 8.33917ZM1.75 8.25011V13.9376C1.75 14.2142 1.97344 14.4376 2.25 14.4376H3.26562V7.75011H2.25C1.97344 7.75011 1.75 7.97355 1.75 8.25011Z" />
                </svg>
                <span className="reportedComments-likes-text">
                  Like ({reportedComment?.comment?.counterLike})
                </span>
              </div>

              <div className="reportedComments-likes-text-wrapper">
                <svg
                  className="reportedComments-likes-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.8422 8.32744C13.8984 8.13994 13.9266 7.94619 13.9266 7.74932C13.9266 7.30713 13.7812 6.88213 13.5188 6.53525C13.575 6.34775 13.6031 6.154 13.6031 5.95713C13.6031 5.51494 13.4578 5.08994 13.1953 4.74307C13.2516 4.55557 13.2797 4.36182 13.2797 4.16494C13.2797 3.35869 12.8 2.63213 12.0562 2.31494C11.9255 2.25858 11.7845 2.22985 11.6422 2.23057H4.26562V8.91807H4.27031L5.61094 13.7743C5.82656 14.5571 6.54531 15.104 7.35781 15.104C7.82188 15.104 8.25469 14.9196 8.575 14.5821C8.89531 14.2462 9.05937 13.8056 9.03594 13.3415L8.94219 11.4212H12.6906C12.8797 11.4212 13.0641 11.3712 13.2266 11.2759C13.8578 10.9087 14.25 10.2431 14.25 9.5415C14.25 9.09932 14.1047 8.67432 13.8422 8.32744ZM1.75 2.729V8.4165C1.75 8.69307 1.97344 8.9165 2.25 8.9165H3.26562V2.229H2.25C1.97344 2.229 1.75 2.45244 1.75 2.729Z" />
                </svg>

                <span className="reportedComments-likes-text">
                  Dislike ({reportedComment?.comment?.counterDislike})
                </span>
              </div>
            </div>
            <div className="reportedComments-lower-buttons-wrapper">
              {reportedComment?.reportedUser?.isDeleted ? (
                <SecondaryButton
                  text="Enable User"
                  onClick={() => {
                    dispatch(activeProfileOnCommentPage(reportedComment?.reportedUser?.id));
                    toast.success('User is enabled.');
                  }}
                />
              ) : (
                <MainButton
                  text="Disable User"
                  onClick={() => {
                    setDisableUserModal(true);
                  }}
                />
              )}
              <div className="reportedComments-delete-comment-btn">
                <SecondaryButton
                  text="Delete Comment"
                  onClick={() => {
                    setDeleteCommentModal(true);
                    setReportedCommentId(reportedComment?.comment?.id);
                  }}
                />
              </div>
              <div
                className="reportedComments-report-reasons-modal"
                onClick={() => {
                  /* @ts-ignore */
                  setCommentReportReasonsModal(true);
                }}
              >
                (reported {reportedComment?.comment?.counter} times)
              </div>
            </div>
          </div>
        </div>

        {commentReportReasonsModal && (
          <CommentReportReasons
            reportedComment={reportedComment}
            // @ts-ignore
            setCommentReportReasonsModal={setCommentReportReasonsModal}
          />
        )}


        <div
          className="post__header-more-btn-container"
          onClick={() => {
            setSkipReportModal(true);
            setReportedCommentId(reportedComment?.comment?.id);
          }}
        >
          <IconButton color="secondary">
            <div className="post__report-delete-btn">
              <svg
                className="post__popover-img"
                width="24"
                height="24"
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M6 7.25H12V14.75H6V7.25Z"
                  fill="#6C757D"
                />
                <path d="M11.625 3.5L10.875 2.75H7.125L6.375 3.5H3.75V5H14.25V3.5H11.625ZM4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6 7.25H12V14.75H6V7.25Z" />
              </svg>
            </div>
          </IconButton>
        </div>
      </div>

      {disableUserModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              textHeader="Are you sure you want to disable this user?"
              smallCross
              onClose={() => setDisableUserModal(null)}
            />
            <ModalFooter>
              <SecondaryButton
                text="Disable"
                onClick={() => {
                  dispatch(deactiveProfileOnCommentPage(reportedComment?.reportedUser?.id));
                  toast.success('User is disabled.');
                  setDisableUserModal(null);
                }}
                bigPadding
              />
              <MainButton
                text="Cancel"
                onClick={() => setDisableUserModal(null)}
                bigPadding
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}

      {deleteCommentModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              textHeader="Are you sure you want to delete the comment?"
              smallCross
              onClose={() => setDeleteCommentModal(null)}
            />
            <ModalFooter>
              <SecondaryButton
                text="Delete"
                onClick={handleDeleteReportedComment}
                bigPadding
              />
              <MainButton
                text="Cancel"
                onClick={() => setDeleteCommentModal(null)}
                bigPadding
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}

      {skipReportModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              textHeader="Are you sure you want to delete the report?"
              smallCross
              onClose={() => setSkipReportModal(null)}
            />
            <ModalFooter>
              <SecondaryButton
                text="Delete"
                onClick={handleSkipReport}
                bigPadding
              />
              <MainButton
                text="Cancel"
                onClick={() => setSkipReportModal(null)}
                bigPadding
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};

export default ReportedComment;
