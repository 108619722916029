import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation, useHref } from 'react-router-dom';
import { getAds } from '../../api/user';
import { setAds, setHistory } from '../../redux/slices/appSlice';
import { getFollowing } from '../../redux/slices/profileSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { getMsgCount } from '../../redux/slices/chatSlice';
import { ContactUsFaq } from '../../pages/FaqPage/ContactUsFaq/ContactUsFaq';
import {
  publicMainRoutes,
  privateMainRoutes,
  adminMainRoutes,
} from '../../routes/mainRoutes';
import { Loader } from '../Loader';
import './Approuter.scss';

const Approuter = () => {
  const { isAuth, id, isAdmin } = useSelector(
    (state: RootState) => state.profile,
  );
  const history = useSelector((state: RootState) => state.app.history || []);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isAuth) {
      dispatch(getMsgCount());
      (async () => {
        const res = await getAds();
        if (res.status === 200) {
          dispatch(setAds(res.data));
        }
      })();
    }
  }, [isAuth]);

  let location = useLocation();

  useEffect(() => {
    let path = location.pathname + location.search;
    const lastPage = [...history].pop()
    if (lastPage !== path && isAuth && !location.search.includes("skip") && !location.search.includes("create-post")) {
      if (path.includes('/chat')) {
        path = lastPage || '/home';
      };
      dispatch(setHistory(path))
    }
    
  }, [location.pathname, location.search])

  // console.log('localStorage', localStorage);
  // console.log(
  //   'localStorage.getItem(persist:root)?.length',
  //   localStorage.getItem('persist:root')?.length,
  // );
  // const isPersistRootEmpty = localStorage.getItem('persist:root')?.length;
  // console.log('isPersistRootEmpty', isPersistRootEmpty);
  const isUserSignedUp = localStorage.getItem('signedUp');
  // console.log('isUserSignedUp', isUserSignedUp);

  return (
    <Suspense fallback={<Loader />}>
      {isAuth ? (
        isAdmin ? (
          <Routes>
            {adminMainRoutes.map((route: Route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.name}
              />
            ))}
            <Route path="*" element={<Navigate to={`profile/${id}`} />} />
          </Routes>
        ) : (
          <Routes>
            {privateMainRoutes.map((route: Route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.name}
              />
            ))}
            <Route
              path="support"
              element={
                <div className="support-container">
                  <ContactUsFaq />
                </div>
              }
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        )
      ) : isUserSignedUp === null ? (
        <Routes>
          {publicMainRoutes.map((route: Route) => (
            <Route path={route.path} element={route.element} key={route.name} />
          ))}
          <Route
            path="support"
            element={
              <div className="support-container">
                <ContactUsFaq />
              </div>
            }
          />
          <Route path="/" element={<Navigate to="/sign-up" />} />
          <Route path="*" element={<Navigate to="/sign-up" />} />
        </Routes>
      ) : (
        <Routes>
          {publicMainRoutes.map((route: Route) => (
            <Route path={route.path} element={route.element} key={route.name} />
          ))}
          <Route
            path="support"
            element={
              <div className="support-container">
                <ContactUsFaq />
              </div>
            }
          />
          <Route path="/" element={<Navigate to="/sign-in" />} />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      )}
    </Suspense>
  );
};

export default Approuter;
