import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import useWindowDimensions from '../../hooks/widthHook';
import { AppDispatch, RootState } from '../../redux/store';
import SearchedFollower from './SearchedFollower/SearchedFollower';
import SearchNavSide from './SearchNavSide/SearchNavSide';
import './SearchPage.scss';
import { useDispatch } from 'react-redux';
import { searchUsers, setFoundedUsers } from '../../redux/slices/someUserSlice';
import Spinner from '../../components/Spinner';

const SearchPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const { id: profileId } = useSelector((state: RootState) => state.profile);
  const { foundedUsers, showFilters, searchedValueFilter, searchedInterests, searchedCountry, statusSearching, overLimit } = useSelector(
    (state: RootState) => state.someUser,
  );
  const { width } = useWindowDimensions();

  const [noFoundedUsers, setNoFoundedUsers] = useState(false);
  const [skipCounter, setSkipCounter] = useState(0);

  useEffect(() => {

    return () => {
      dispatch(setFoundedUsers({users: []}))
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundedUsers, statusSearching]);

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const windowHeight = document.body.offsetHeight;

    let bottom = Math.ceil(scrollPosition + 5) >= windowHeight;

    if (bottom && !statusSearching && !overLimit) {
      dispatch(searchUsers(
        {
          part: searchedValueFilter,
          interests: searchedInterests,
          country: searchedCountry,
          skip: skipCounter + 20,
          foundedUsers
        }
      ))

      setSkipCounter(skipCounter + 20)
    }
  }

  // const [reset, setReset] = useState(false);
  // useEffect(() => {
  //   console.log('useEffect in SearchPage')
  //   setReset(prev=>!prev);
  //   setFoundedUsers([])
  //   console.log('reset', reset)
  // }, [noFoundedUsers]);

  // console.log('noFoundedUsers', noFoundedUsers);
  // console.log('foundedUsers', foundedUsers);
  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className="main-page">
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner search-filters">
                {width < 1100 ? (
                  <div
                    className={
                      showFilters ? 'filters-visible' : 'filters-invisible'
                    }
                  >
                    <SearchNavSide setNoFoundedUsers={setNoFoundedUsers} />
                  </div>
                ) : (
                  <SearchNavSide setNoFoundedUsers={setNoFoundedUsers} />
                )}
                <div className="search-wall">
                  {foundedUsers?.length ? (
                    <div className="followingFrame">
                      {foundedUsers
                        ?.filter((item: any) => item?.isDeleted === false)
                        ?.map((follower: any, index) => (
                          <SearchedFollower
                            follower={follower}
                            key={index}
                          />
                        ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: width < 1100 ? 0 : 190,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <h2
                        style={{
                          marginBottom: width < 1100 ? 12 : 0,
                          marginTop: width < 1100 ? 12 : 0,
                        }}
                      >
                        {formatMessage({ id: 'noUserFound' })}
                      </h2>
                      <h3 style={{ marginBottom: width < 1100 ? 12 : 0 }}>
                        {formatMessage({ id: 'makeSure' })}{' '}
                        <br
                          style={{ display: width < 1100 ? 'block' : 'none' }}
                        />{' '}
                        {formatMessage({ id: 'tryDifferent' })}
                      </h3>
                    </div>
                  )}
                  {!overLimit && <Spinner />}
                </div>
                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SearchPage;
