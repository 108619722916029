import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { setOnRelaxRoom } from '../../redux/slices/relaxSlice';
import { useNavigateToTop } from '../helpers/useNavigateToTop';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import errorIndicator from '../../images/errorIndicator.svg';
import './ErrorIndicator.scss';
import { useIntl } from 'react-intl';

interface ErrorIndicatorProps {
  forHomePage: boolean;
  forRelaxPage: boolean;
  withGoButton: boolean;
}

function ErrorIndicator({ forHomePage, withGoButton, forRelaxPage }: ErrorIndicatorProps) {
  const {formatMessage} = useIntl()
  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (forRelaxPage) {
      dispatch(setOnRelaxRoom(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forRelaxPage]);

  return (
    <div className="ErrorIndicator">
      <h2 className="ErrorIndicator__title">{formatMessage({id: 'serverError'})}</h2>
      <img src={errorIndicator} alt="Error" width="64" height="64" />
      <p className="ErrorIndicator__text">
        {formatMessage({id: 'contactAdmin'})}
      </p>
      {withGoButton &&
        <MainButton
          text={forHomePage ? formatMessage({id: 'goToProfile'}) : formatMessage({id: 'goHome'})}
          onClick={() => navigateToTop(forHomePage ? '/my-profile' : '/home')}
        />}
    </div>
  );
}

export default ErrorIndicator;
