import './BackToTop.scss';

const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
      <button className="BackToTopButton" onClick={scrollToTop} type="button">
        <svg
          className="arrowIcon"
          viewBox="12 2 21 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 15L6.7625 16.7625L13.75 9.7875V25H16.25V9.7875L23.225 16.775L25 15L15 5L5 15Z"
            fill="#791186"
          />
        </svg>
      </button>
  );
};

export default BackToTop;
