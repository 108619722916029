import React, { useState } from 'react';
import { Popover, TextareaAutosize, Tooltip } from '@mui/material';
import useWindowDimensions from '../../../hooks/widthHook';
// import Picker from 'emoji-picker-react';
import './TextAreaComments.scss';
import { useIntl } from 'react-intl';

let keysPressed: any = {};

interface Props {
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  setValue?: (value: string) => void;
  createComment: () => void;
  subcomment?: boolean;
}

const TextAreaComments = ({
  placeholder,
  value,
  defaultValue = '',
  setValue = () => { },
  createComment,
  subcomment
}: Props) => {

  const [restriction, setRestriction] = useState(false);
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';
  const { width } = useWindowDimensions();
  // =============popover==================
  // const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open;

  // // =============emoji==================
  // const [chosenEmoji, setChosenEmoji] = useState<any>(null);
  // // console.log('chosenEmoji', chosenEmoji);
  // const onEmojiClick = (event: any, emojiObject: any) => {
  //   setChosenEmoji(emojiObject);
  // };

  // console.log('value', value)
  // console.log('value.length', value?.length)
  const onHandleChange = (e: any) => {
    // if (chosenEmoji) {
    // console.log('chosenEmoji.emoji', chosenEmoji.emoji);
    // let hex = chosenEmoji.emoji.codePointAt(0).toString(16);
    // let emo: any = String('U+' + hex);
    // console.log('emo', emo);
    // setValue(emo);
    //   setValue(chosenEmoji.emoji);
    //   setChosenEmoji(null);
    // } else {
    //   console.log('e.target.value', e.target.value);

    // var keyCode = (window.event) ? e.which : e.keyCode;
    // console.log('keyCode', keyCode);
    // if(keyCode === 'enter' || keyCode === 13) {
    // setValue((prev:any) => {prev + "\n"})
    // '\\n'
    // '\r\n'
    // }
    setValue(e.target.value);
    // }
  };

  return (
    <>
      <div className="TextareaAutosizeWrapper">
        <TextareaAutosize
          className="TextareaAutosize"
          style={{
            overflow: "auto",
            textAlign: lan === 'ar' ? 'right' : 'left',
            padding: width >= 380 ? (lan === 'ar' ? '12px 16px 12px 72px' : '12px 72px 12px 16px') : (lan === 'ar' ? '12px 10px 12px 50px' : '12px 50px 12px 10px'),
          }}
          aria-label="comment"
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onFocus={() => { setRestriction(true) }}
          onBlur={() => { setRestriction(false) }}
          onChange={event => onHandleChange(event)}
          maxLength={1000}
          onKeyDown={e => {
            keysPressed[e.key] = true;
            
            if(keysPressed['Shift'] && e.key == 'Enter'){
            } else if (e.key === 'Enter') {
              createComment();
              e.preventDefault();
            }
          }}
          onKeyUp={e => {
            delete keysPressed[e.key];
          }}
        />
        {restriction &&
          <div
            className={value?.length === 1000 ?
              "TextareaAutosizeRestrictionRed" : "TextareaAutosizeRestrictionGrey"}
            style={{
              left: lan === 'ar' ? '10px' : 'none',
              right: lan === 'ar' ? 'none' : '10px'
            }}
          >
            {formatMessage({ id: 'format1000' })}
          </div>}
        {/* <div
          className="emoji"
          onClick={ev => {
            setAnchorEl(ev.currentTarget);
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12ZM8.5 8C9.33 8 10 8.67 10 9.5C10 10.33 9.33 11 8.5 11C7.67 11 7 10.33 7 9.5C7 8.67 7.67 8 8.5 8ZM12 18C9.72 18 7.78 16.34 7 14H17C16.22 16.34 14.28 18 12 18ZM15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5C17 10.33 16.33 11 15.5 11Z"
              fill="#323232"
            />
            <path
              d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z"
              fill="#323232"
            />
            <path
              d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z"
              fill="#323232"
            />
            <path
              d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
              fill="#323232"
            />
            <path
              d="M12 18C14.28 18 16.22 16.34 17 14H7C7.78 16.34 9.72 18 12 18Z"
              fill="#323232"
            />
          </svg>
        </div>*/}
      </div>
      {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <div>
          {chosenEmoji ? (
        <span>You chose: {chosenEmoji.emoji}</span>
      ) : (
        <span>No emoji Chosen</span>
      )} 
           <Picker onEmojiClick={onEmojiClick} />
        </div>
      </Popover>  */}
    </>
  );
};

export default TextAreaComments;
