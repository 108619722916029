const postDateConvert = (created: string) => {
  if (created) {
    const date = new Date(created).toString().split(' ')[2];
    const month = new Date(created).toString().split(' ')[1];
    const time = new Date(created).toString().split(' ')[4].slice(0, 5);

    return `${date} ${month} at ${time}`
  }
  else {
    return ''
  }
}

export default postDateConvert;
