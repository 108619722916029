import React, { FC } from 'react';
import './AuthContainer.scss';
import ellipse1 from '../../../images/ellipses/ellipse-1.png';
import ellipse2 from '../../../images/ellipses/ellipse-2.png';
import ellipse3 from '../../../images/ellipses/ellipse-3.png';
import ellipse4 from '../../../images/ellipses/ellipse-4.png';
import LanguageSelector from '../../../components/LanguageSelector/LanguageSelector';

interface Props {
  children?: React.ReactNode;
  step?: number;
  language?: boolean;
}

export const AuthContainer: FC<Props> = ({ children, step, language }) => (
  <>
    <div
      className={step  === 2 ? "container-root" : "container-root container-root-bg"}
      style={{ height: step === 2 ? '100%' : '100vh' }}
    >
      {language && <LanguageSelector />}
      {/* <img className="ellipse1" src={ellipse1} alt="ellipse1" />
      <img className="ellipse2" src={ellipse2} alt="ellipse2" />
      <img className="ellipse3" src={ellipse3} alt="ellipse3" />
      <img className="ellipse4" src={ellipse4} alt="ellipse4" /> */}
      <div className="container-content">{children}</div>
    </div>
  </>
);
