import React from 'react';

export const getIcon = (src: string, alt: string, size: string = '24') => {
  return (
    <img
      src={src}
      width={size}
      height={size}
      alt={alt}
    />
  );
};
