import React from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import './AvatarChip.scss';
interface Props {
  setSelectedSrc: (src: string) => void;
  src: string;
  selectedSrc: string;
}

//avatars
const AvatarChip = ({ src, setSelectedSrc, selectedSrc }: Props) => {
  return (
    <label
      className={`avatarRadio${
        selectedSrc === src ? ' avatarRadio--selected' : ''
      }`}
      onClick={() => setSelectedSrc(src)}
    >
      <span>
        <img src={src} alt="avatar" />
      </span>

      {selectedSrc === src && (
        <div className="avatarRadio-check">
          <CheckRoundedIcon />
        </div>
      )}
    </label>
  );
};

export default AvatarChip;
