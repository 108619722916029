import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import {
  followUserById,
  getFollowers,
  getFollowing,
} from '../../../redux/slices/profileSlice';
import useWindowDimensions from '../../../hooks/widthHook';
import { getFollowersSomeUser, getSomeUserInfo } from '../../../redux/slices/someUserSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { useNavigateToTop } from '../../helpers/useNavigateToTop';
import ShowMoreAds from '../../ShowMoreAds/ShowMoreAds';
import SecondaryButton from '../../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../../shared/Buttons/MainButton/MainButton';
import './Followers.scss';

export const Followers = () => {
  const { formatMessage } = useIntl();
  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();
  const {
    followers,
    following,
    id: profileId,
  } = useSelector((state: RootState) => state.profile);
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  const profile = useSelector((state: RootState) => state.profile);
  const { someUserFolowers, someUser } = useSelector(
    (state: RootState) => state.someUser,
  );

  const { someUserId } = useParams();
  const [followersToRender, setFollowersToRender] = useState<any>([]);
  const [skip, setSkip] = useState(0);

  const handleMoreFollowersFunc = () => {
    setSkip(prev => prev + 5);    
  };
  // // ===== get followers users =====
  const dataToGetFollowers = {
    skip: skip,
    id: profileId,
  };

  // ===== get followers someUser =====
  const dataToGetFollowersSomeUser = {
    skip: skip,
    id: someUserId,
  };

  // ===== to follow/unfollow user =====
  const followUserFunc = (id: any) => {
    if (profileId === someUserId) {
      dispatch(followUserById(id));
    } else {
      dispatch(followUserById({ ...id, page: 'someProfile' }));
    } 
  };

  useEffect(() => {
    if (profileId === someUserId) {
      dispatch(getFollowers(dataToGetFollowers));
    } else {
      dispatch(getFollowersSomeUser(dataToGetFollowersSomeUser));
    }
  }, [someUserId, skip]);

  useEffect(() => {
    if (profileId === someUserId && followers) {
      setFollowersToRender(followers.followers);
    } else {
      let JustOneFollowingArr = [] as any;
      someUserFolowers?.followers.forEach(a => {
        if (JustOneFollowingArr.every((b: any) => b.id !== a.id)) {
          JustOneFollowingArr.push(a);
        }
      })
      setFollowersToRender(JustOneFollowingArr);
    }
  }, [someUserFolowers, followers]);

  console.log(followersToRender);

  return (
    <div className="followersWrapper">
      <ShowMoreAds type={'profile'} />
      <h2 className="followersMainTitle">
        {formatMessage({ id: 'followers' })} ({someUser?.followers})
      </h2>

      {followersToRender?.length > 0 && (
        <div className="followersFrame">
          {followersToRender
            ?.filter((item: any) => item?.isDeleted === false)
            ?.map((follower: any, index: number) => (
              <div
                className="follower__wrapper"
                key={follower?.id}
                style={{
                  flexDirection: (width < 1100) ? 'column' : (lan === 'ar' ? 'row-reverse' : 'row'),
                  alignItems: (width < 1100) ? (lan === 'ar' ? 'flex-end' : 'flex-start') : 'flex-start'
                }}
              >
                <div
                  className="followers__content"
                  style={{
                    display: 'flex',
                    flexDirection: (lan === 'ar' ? 'row-reverse' : 'row')
                  }}
                >
                  <div
                    className="followers__following-avatar-container"
                    style={{
                      margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0'
                    }}
                  >
                    <img
                      className="followers__following-avatar"
                      src={follower?.profileAvatar}
                      alt="profile avatar"
                    />
                  </div>

                  <div className="follower__titleWrapper">
                    <div
                      onClick={() => navigateToTop(`/profile/${follower.id}`)}
                    >
                      <h2 className="follower__title">
                        {`${follower?.organizationName}` ||
                          `${follower?.firstName} ${follower?.lastName}`}
                      </h2>
                    </div>

                    <h4 className="follower__subtitle">{follower?.about}</h4>
                  </div>
                </div>
                <div className="follower__btnWrapper">
                  {follower?.id !== profileId && (
                    <>
                      {follower?.ifFollow ? (
                        <div
                          className="follower__btnCheckWrapper"
                          style={{
                            flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                            gap: lan === 'ar' ? '30px' : '0'
                          }}
                        >
                          <button
                            className="follower__btnCheck"
                            style={{
                              left: lan === 'ar' ? '10px' : 'none',
                              right: lan === 'ar' ? 'none' : '10px',
                            }}
                          >
                            {formatMessage({ id: 'following' })}
                          </button>
                          <SecondaryButton
                            text={formatMessage({ id: 'unfollow' })}
                            type="button"
                            onClick={() => {
                              followUserFunc(follower);
                            }}
                          />
                        </div>
                      ) : (
                        <MainButton
                          text={formatMessage({ id: 'follow' })}
                          type="button"
                          onClick={() => {
                            followUserFunc(follower);

                            // followUserFunc({
                            //   about: profile.aboutMe,
                            //   firstName: profile.firstName,
                            //   id: profile.id,
                            //   isDeleted: false,
                            //   lastName: profile.lastName,
                            //   organizationName: profile.organizationName,
                            //   profileAvatar: profile.profileAvatar
                            // });
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {/* MORE Followers BUTTON */}
      {(someUser && someUser.followers > skip + 5 && someUser.user?.privacy?.showFollowing) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <SecondaryButton
            text={formatMessage({ id: 'showMore' })}
            onClick={handleMoreFollowersFunc}
          />
        </div>
      )}
    </div>
  );
};
