export const tippyContentDescriptionKeys: string[] = [
  'maximumAllowableVolume',
  'maximumAllowablePhotoSize',
  'maximumAllowableVideoSize',
  'allowablePhotoFormat',
  'allowableVideoFormat',
];

export const tippyImageDescriptionKeys: string[] = [
  'maximumAllowablePhotoSize',
  'allowablePhotoFormat',
];

export const tippyIconDescriptionKeys: string[] = [
  'maximumAllowablePhotoSize',
  'allowableIconFormat',
];
