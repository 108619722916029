import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/widthHook';
import { RootState } from '../../redux/store';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import { addBrTagInLine } from '../helpers/parseLineBreak';
import { processTextInLink } from '../helpers/linksProcessing';
import { counterAdsAPI } from '../../api/user';

type Props = {
  type: 'home' | 'profile' | 'saved' | 'groups';
};

const ShowMoreAds = ({ type }: Props) => {
  const { formatMessage } = useIntl();
  const { ads: adsConfig, language } = useSelector(
    (state: RootState) => state.profile,
  );
  const { ads } = useSelector((state: RootState) => state.app);
  const lan = localStorage.getItem('lang') || 'en';

  const { width: windowWidth } = useWindowDimensions();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [adsToShow, setAdsToShow] = useState(
    ads?.length > 0 &&
      ads?.filter(
          (ad: any) =>
            ad.isDisabled !== true &&
            ad.addedLanguages.find((item: any) => item.code === language),
        )?.slice(0, 3),
  );

  useEffect(() => {
    let adsArray = []
    if(ads?.length > 0){
      adsArray = ads?.filter(
        (ad: any) =>
          ad.isDisabled !== true &&
          ad.addedLanguages.find((item: any) => item.code === language),
      )?.slice(0, 3)
    }
    setAdsToShow(adsArray)
  }, [ads])

  return (
    <>
      {windowWidth < 1100 && adsConfig && type !== 'saved' && (
        <div
          className="my-feed__dropdown"
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
          style={{
            background: openDropdown ? '#FBF5FF' : '#fff',
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
          }}
        >
          <div>{formatMessage({ id: 'youMightBeInterested' })}</div>

          {!openDropdown ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </div>
      )}

      {windowWidth < 1100 && openDropdown && adsConfig && (
        <div className="my-feed__ads">
          {adsToShow?.map((ad: any, index: number) => (
            <a
              className="aside__section-news-card"
              key={index}
              href={ad.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => counterAdsAPI({ adsId: ad.id })}
            >
              <h2 className="aside__section-title">
                {
                  ad.addedLanguages.find((item: any) => item.code === language)
                    ?.title
                }
              </h2>
              <div className="aside__section-poster-container">
                <img
                  className="aside__section-news-poster"
                  src={ad.img}
                  alt=""
                />
              </div>

              <p className="aside__section-details">
                {processTextInLink(
                  addBrTagInLine(
                    ad.addedLanguages.find(
                      (item: any) => item.code === language,
                    )?.descr,
                  ),
                )}
              </p>

              <span
                className="aside__section-link"
                // href={ad.url}
                // target="_blank"
                // rel="noopener noreferrer"
              >
                {ad.url.slice(0, 25) + '...'}
              </span>
            </a>
          ))}
        </div>
      )}

      {windowWidth < 1100 &&
        adsConfig &&
        openDropdown &&
        ads?.filter(
          (ad: any) =>
            ad.isDisabled !== true &&
            ad.addedLanguages.find((item: any) => item.code === language),
        ).length !== adsToShow.length && (
          <div className="my-feed__ads-more">
            <SecondaryButton
              text={formatMessage({ id: 'showMore' })}
              onClick={() => {
                setAdsToShow(
                  ads
                    ?.filter(
                      (ad: any) =>
                        ad.isDisabled !== true &&
                        ad.addedLanguages.find(
                          (item: any) => item.code === language,
                        ),
                    )
                    .slice(0, adsToShow.length + 3),
                );
              }}
              styles={{ width: 150 }}
            />
          </div>
        )}
    </>
  );
};

export default ShowMoreAds;
