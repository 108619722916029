import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import { AppDispatch, RootState } from '../../../../redux/store';
import { showLoader } from '../../../../redux/slices/appSlice';
import {
  passwordReset,
  setResponseMessage,
  setSendPassword,
} from '../../../../redux/slices/authSlice';
import './ForgotPasswordForm.scss';

type Props = {
  // setSendPassword: (sendPassword: boolean) => void;
  setResetPasswordEmail: (sendPassword: string) => void;
  resetPasswordEmail: string;
};

const ForgotPasswordForm = ({
  // setSendPassword,
  setResetPasswordEmail,
  resetPasswordEmail,
}: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  // const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const lan = localStorage.getItem('lang') || 'en';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid, touchedFields },
  } = useForm({ mode: 'all' });

  const { responseMessage } = useSelector((state: RootState) => state.auth);

  const dataToSend = {
    email: resetPasswordEmail,
  };

  const onHandleSubmit = () => {
    dispatch(showLoader(true));
    dispatch(passwordReset(dataToSend));
    // dispatch(showLoader(false));
  };

  useEffect(() => {
    if (responseMessage) {
      // toast.error(responseMessage);
      setSendPassword(false);
      dispatch(setResponseMessage(''));
    }
    // if (!responseMessage) {
    //   setSendPassword(true);
    // }
    // setSendPassword(true);
  }, [responseMessage]);

  return (
    <form className="authForm" onSubmit={handleSubmit(onHandleSubmit)}>
      <div
        style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}
      >
        <div className="forgotPasswordWrapper">
          <span className="forgotPassword">
            {formatMessage({ id: 'forgotPassword' })}{' '}
          </span>
        </div>
        <div className="forgotPasswordText">
          {formatMessage({ id: 'noWorries' })}
        </div>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <CustomInput
            placeholder={formatMessage({ id: 'emailPlaceholder' })}
            label={formatMessage({ id: 'emailLabel' })}
            inputName="email"
            errors={errors}
            touchedFields={touchedFields}
            onChangeFunc={value => {
              setResetPasswordEmail(value);
              setValue('email', value);
            }}
            inputProps={{
              ...register('email', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: formatMessage({ id: 'incorrectEmail' }),
                },
              }),
              style: {
                fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left'
              }
            }}
          />
        </div>
        <MainButton
          text={formatMessage({ id: 'resetPassword' })}
          type="submit"
          disabled={!isValid}
        />
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
