import { intl } from '../localization';

export const defaultAvatars: string[] = [
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970082/static%20images/Frame_38_ttzy32.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970083/static%20images/Frame_45_piirky.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970086/static%20images/Frame_54_zqzxve.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970082/static%20images/Frame_40_mfsjse.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970083/static%20images/Frame_48_am2wni.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970082/static%20images/Frame_37_w6ajhx.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970083/static%20images/Frame_46_ws5tsv.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970081/static%20images/Frame_42_ytrmci.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970084/static%20images/Frame_52_wezn6p.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970084/static%20images/Frame_49_b5lkmw.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970083/static%20images/Frame_47_erak9i.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970084/static%20images/Frame_51_u9v2cr.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970084/static%20images/Frame_50_tbnx1l.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970085/static%20images/Frame_55_lbl1vb.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970084/static%20images/Frame_53_aq9cqb.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970082/static%20images/Frame_39_jzrorh.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970081/static%20images/Frame_41_zqqgza.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970085/static%20images/Frame_56_f3txfg.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970083/static%20images/Frame_44_jw0yah.png',
  'https://res.cloudinary.com/dsypundib/image/upload/v1673970083/static%20images/Frame_43_v8yix0.png',

  // OLD
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005334/gzhiugjfkrz1n3ojfkst.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005334/lwgozkohzgjhl3ojd0lc.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005332/syxp4ahpnytcyppuefv3.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005332/bzw1m28z4l0gvwexoad4.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005332/kjuahj1hmztupelvdcka.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005332/niizachflhkrolqlpiih.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005332/yyeoqh6rgzr4j6emumzs.svg',
  // 'https://res.cloudinary.com/tensionx/image/upload/v1659005332/q25lxlcbs7rgf5z32hxt.svg',

  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563441/static%20images/avatar_9_y9j28v.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563443/static%20images/avatar_10_a38bru.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563443/static%20images/avatar_11_wwfgqi.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563440/static%20images/avatar_12_p4fk3m.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563441/static%20images/avatar_13_jnvz87.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563441/static%20images/avatar_14_o4dbhk.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563441/static%20images/avatar_15_utsuml.svg',
  // 'https://res.cloudinary.com/dsypundib/image/upload/v1664563441/static%20images/avatar_16_fqyahm.svg',
];
