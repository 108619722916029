import './Wall.scss';
import wallBg from '../../images/wall-background.png';
import groupsLogo from '../../images/groupsLogo.png';
import io from 'socket.io-client';
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MyFeed } from './MyFeed/MyFeed';
import { Photos } from './Photos/Photos';
import { Videos } from './Videos/Videos';
import { Followers } from './Followers/Followers';
import { Following } from './Following/Following';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import { a11yProps } from '../helpers/a11yProps';
import { TabPanel } from '../shared/TabPanel/TabPanel';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import { useNavigate, useParams } from 'react-router';
import {
  activateProfile,
  deactivateProfile,
  getSomeUserInfo,
  resetUserProfile,
  // setUserInfo,
} from '../../redux/slices/someUserSlice';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import {
  followUserById,
  setAccountSettings,
  setBlockedUsers,
} from '../../redux/slices/profileSlice';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import {
  followGroupThunk,
  getGroupsProfileInfo,
} from '../../redux/slices/groupsSlice';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import UploadButton from '../shared/Buttons/UploadButton/UploadButton';
import {
  getAllGroups,
  getAllInterest,
  getPostTypes,
  showLoader,
} from '../../redux/slices/appSlice';
import axios from 'axios';
import {
  getBlockedUsersAPI,
  getUserInfoAPI,
  UnBlockUser,
  updateUserOnboardingData,
} from '../../api/user';
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone';
import useWindowDimensions from '../../hooks/widthHook';
import PanoramaTwoToneIcon from '@mui/icons-material/PanoramaTwoTone';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../constants';
import { ImageCropModal } from '../ImageCropModal/ImageCropModal';
import { EditTagsAndInterests } from '../../api/groups';
import { isUserBlocked } from '../helpers/isUserBlocked';
import { ReportBlockModal } from '../shared/ReportBlockModal/ReportBlockModal';
import { API } from '../../api/api';
import { cleanMsgs } from '../../redux/slices/chatSlice';
import InviteModal from '../InviteModal';

// prepared for logick of closed followers/following
const isLocked = (show: boolean = true) => {
  if (!show) {
    return {
      icon: <LockTwoToneIcon />,
    };
  }

  return {};
};

// type CheckBoxesType = {
//   forOrganization: boolean;
//   otherTitle: boolean;
//   showLocation: boolean;
//   titles: string[];
// };

interface Props {
  groupsView?: boolean;
  otherUser?: boolean;
}

export const Wall = ({ groupsView, otherUser }: Props) => {
  const { someUserId } = useParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [openInvite, setOpenInvite] = useState(false);

  const [value, setValue] = React.useState(0);
  const lan = localStorage.getItem('lang') || 'en';

  const someUserInfo = useSelector(
    (state: RootState) => state.someUser.someUser,
  );

  const {
    id: profileId,
    firstName,
    lastName,
    profileAvatar,
    organizationName,
    aboutMe,
    profileBg,
    isAdmin,
    followers,
    interests,
    // following,
    country,
    region,
    blockedUsers,
    onlineStatus
  } = useSelector((state: RootState) => state.profile);

  const {
    name,
    avatar,
    background,
    followers: groupFollowers,
    isFollow,
    id,
    filterIdName,
    typeName,
  } = useSelector((state: RootState) => state.groups);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // ===== to follow/unfollow user =====
  const followUserFunc = (id: any) => {
    const myProfile = {
      id: profileId,
      about: '',
      firstName,
      ifFollow: false,
      lastName,
      organizationName,
      profileAvatar,
      isDeleted: false,
    };

    dispatch(
      followUserById({ ...id, page: 'someProfileWall', personalId: myProfile }),
    );
  };

  useEffect(() => {
    if (someUserId) {
      setValue(0);
      dispatch(showLoader(true));
      (async () => {
        dispatch(resetUserProfile());
        dispatch(getSomeUserInfo(someUserId));
        // const userInfo = await getSomeUserApi(someUserId);
        // dispatch(setUserInfo(userInfo));
        dispatch(showLoader(false));
      })();
    }
  }, [someUserId]);

  const [reportModal, setReportModal] = useState(false);

  const [exampleAvatar, setExampleAvatar] = useState('');
  const [uploadedAvatar, setUploadedAvatar] = useState(
    groupsView ? avatar : profileAvatar,
  );
  const [uploadedBg, setUploadedBg] = useState(
    groupsView ? background : profileBg,
  );

  const uploadImage = (files: FileList | null, imgType?: string) => {
    if (files === null) return;
    const tempFile = files[0];

    // Check is image max size
    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(files[0]);
    const maxSize = 5242880;
    img.src = objectUrl;

    if (files[0].size > maxSize) {
      // setUploadBgError('Please select an image not more than 5MB');
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }

    img.onload = function () {
      const formData = new FormData();
      formData.append('file', tempFile);
      formData.append('upload_preset', Cloudinary_PRESET);

      setCropModal({
        open: true,
        image: objectUrl,
        cropShape: imgType == 'bg' ? 'rect' : 'round',
        formData,
        imgType,
        onSave: (file = formData) => {
          // if (imgType === 'avatar') {
          //   setExampleAvatar('');
          //   setUploadedAvatar(img.src);
          // } else if (imgType === 'bg') {
          //   setUploadedBg(img.src);
          // }

          sendRequestToBack(file, imgType);
        },
      });
    };
  };

  const sendRequestToBack = async (formData: FormData, imgType: any) => {
    dispatch(showLoader(true));
    let uploadedAvatarCloudly = uploadedAvatar;
    let uploadedBgCloudly = uploadedBg;

    if (imgType === 'avatar') {
      try {
        uploadedAvatarCloudly = (
          await axios.post(
            `https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`,
            formData,
          )
        ).data.secure_url;
      } catch (error) {}
    }

    if (imgType === 'bg') {
      try {
        uploadedBgCloudly = (
          await axios.post(
            `https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`,
            formData,
          )
        ).data.secure_url;
      } catch (error) {}
    }

    // console.log('uploadedAvatarCloudly', uploadedAvatarCloudly)
    // console.log('uploadedBgCloudly', uploadedBgCloudly)

    if (isAdmin && groupsView) {
      const objToSend: any = {
        avatar: uploadedAvatarCloudly,
        background: uploadedBgCloudly,
        [filterIdName]: id,
      };

      await EditTagsAndInterests(objToSend).then(res => {
        if (res.status === 200) {
          toast.success('Image successfully changed');
          dispatch(getGroupsProfileInfo({ [typeName]: id }));
          dispatch(getAllInterest());
          dispatch(getAllGroups());
          dispatch(getPostTypes());
        }
      });
    } else {
      const objToSend: any = {
        country: country,
        region: region,
        interests: interests.map(item => item.id),

        profileAvatar: uploadedAvatarCloudly || exampleAvatar,
        profileBg: uploadedBgCloudly,
      };

      await updateUserOnboardingData(objToSend).then(res => {
        if (res.status === 200) {
          toast.success(formatMessage({ id: 'accountSettingsChanged' }));
          dispatch(setAccountSettings(res.data));
        }
      });
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (groupsView) {
      setUploadedAvatar(avatar);
      setUploadedBg(background);
    } else {
      setUploadedAvatar(profileAvatar);
      setUploadedBg(profileBg);
    }
  }, [id, filterIdName, profileAvatar, profileBg, avatar, background]);

  const width = useWindowDimensions().width;

  const [cropModal, setCropModal] = useState<any>({
    open: false,
    image: '',
    cropShape: 'rect',
    formData: '',
    imgType: 'bg',
    onSave: () => {},
  });

  return (
    <div className="wall">
      <div className="wall__header">
        <div className="wall__header-bg-container">
          {profileId === someUserId ? (
            <>
              {width >= 1100 ? (
                <div className="wall__header-bg__btns">
                  <UploadButton
                    imgType="bg"
                    uploadImage={uploadImage}
                    text={formatMessage({ id: 'changeImage' })}
                  />
                </div>
              ) : (
                <div className="wall__header-bg-btn-container">
                  <div className="wall__header-bg-btn-uploader">
                    <UploadButton
                      text=" "
                      imgType="bg"
                      uploadImage={uploadImage}
                    />
                  </div>
                  <PanoramaTwoToneIcon color="action" />
                </div>
              )}

              <img
                className="wall__header-bg"
                src={uploadedBg || wallBg}
                alt="background"
              />
            </>
          ) : (
            <>
              {someUserInfo?.user?.isDeleted && !groupsView ? (
                <div className="wall__header-disabled-message">
                  {formatMessage({ id: 'profileIsDeactivated' })}
                </div>
              ) : (
                isUserBlocked(blockedUsers, someUserId) && (
                  <div className="wall__header-disabled-message">
                    {formatMessage({ id: 'blockedUserText' })}
                  </div>
                )
              )}

              {isAdmin && groupsView && (
                <>
                  {width >= 1100 ? (
                    <div className="wall__header-bg__btns">
                      <UploadButton
                        imgType="bg"
                        uploadImage={uploadImage}
                        text={formatMessage({ id: 'changeImage' })}
                      />
                    </div>
                  ) : (
                    <div className="wall__header-bg-btn-container">
                      <div className="wall__header-bg-btn-uploader">
                        <UploadButton
                          text=" "
                          imgType="bg"
                          uploadImage={uploadImage}
                        />
                      </div>
                      <PanoramaTwoToneIcon color="action" />
                    </div>
                  )}
                </>
              )}

              <img
                className="wall__header-bg"
                src={
                  someUserId && someUserInfo
                    ? someUserInfo?.user?.profileBg || wallBg
                    : !groupsView
                    ? profileBg
                    : uploadedBg || wallBg
                }
                alt="wall-backgound"
              />
            </>
          )}
        </div>

        {/* ---------------------> <------------------------------------ */}

        <div className="wall__header-user-row">
          {profileId === someUserId ? (
            <>
              <div className="wall__header-avatar-container">
                <img
                  className="wall__header-avatar"
                  src={uploadedAvatar}
                  alt="avatar"
                />
              </div>

              <div className="wall__header-avatar-btn">
                <div className="wall__header-avatar-btn-uploader">
                  <UploadButton
                    text=" "
                    imgType="avatar"
                    uploadImage={uploadImage}
                  />
                </div>
                <PhotoCameraTwoToneIcon color="action" />
              </div>
            </>
          ) : (
            <>
              <div className="wall__header-avatar-container">
                <img
                  className="wall__header-avatar"
                  src={
                    someUserId && someUserInfo
                      ? someUserInfo?.user?.profileAvatar || groupsLogo
                      : !groupsView
                      ? profileAvatar
                      : uploadedAvatar || groupsLogo
                  }
                  alt="avatar"
                />
              </div>

              {isAdmin &&
                groupsView &&
                (typeName === 'typeId' &&
                (id == 1 || id == 2 || id == 3 || id == 4)
                  ? false
                  : true) && (
                  <div className="wall__header-avatar-btn">
                    <div className="wall__header-avatar-btn-uploader">
                      <UploadButton
                        text=" "
                        imgType="avatar"
                        uploadImage={uploadImage}
                      />
                    </div>
                    <PhotoCameraTwoToneIcon color="action" />
                  </div>
                )}
            </>
          )}

          <div className="wall__header-user-container">
            <div className="wall__header-user-name">
              {someUserId && someUserInfo
                ? `${someUserInfo?.user?.organizationName}` ||
                  `${someUserInfo?.user?.firstName} ${someUserInfo?.user?.lastName}`
                : !groupsView
                ? `${firstName} ${lastName}` || organizationName
                  : name || typeName}
              {(someUserId
                && someUserInfo
                && (someUserInfo?.user?.organizationName || someUserInfo?.user?.firstName || organizationName))
                && <div
                className="wall__online-status"
                style={{
                  backgroundColor: onlineStatus?.some((a: string) => a === someUserId)
                    ? '#28A745'
                    : '#FD2551',
                }}
              ></div>}
            </div>

            {someUserId && someUserInfo ? (
              <p className="wall__header-user-subtitle">
                {someUserInfo?.user?.about || ''}
              </p>
            ) : !groupsView ? (
              <p className="wall__header-user-subtitle">{aboutMe || ''}</p>
            ) : (
              <p className="wall__header-user-subtitle">
                <span className="wall__header-user-followers">
                  {!groupsView ? followers?.followers?.length : groupFollowers}
                </span>{' '}
                {formatMessage({ id: '#OfFollowers' })}
              </p>
            )}
          </div>

          {someUserId && someUserId !== profileId && someUserInfo ? (
            <div className="wall__header-edit-btn-container">
              {isAdmin ? (
                <>
                  {!someUserInfo?.user?.isDeleted && (
                    <>
                      {someUserInfo?.user?.ifFollow ? (
                        <SecondaryButton
                          text={formatMessage({ id: 'unfollow' })}
                          onClick={() => {
                            followUserFunc(someUserInfo?.user);
                          }}
                        />
                      ) : (
                        <MainButton
                          text={formatMessage({ id: 'follow' })}
                          onClick={() => {
                            followUserFunc(someUserInfo?.user);
                          }}
                        />
                      )}
                    </>
                  )}

                  <div>
                    <button
                      type="button"
                      className="message-btn"
                      onClick={() => {
                        navigate(`/chat?id=${someUserId}`);
                        dispatch(cleanMsgs());
                      }}
                    >
                      {formatMessage({ id: 'message' })}
                    </button>
                  </div>

                  {someUserInfo?.user?.isDeleted ? (
                    <SecondaryButton
                      text="Enable"
                      onClick={() => {
                        dispatch(activateProfile(someUserId));
                      }}
                    />
                  ) : (
                    <MainButton
                      text="Disable"
                      onClick={() => {
                        dispatch(deactivateProfile(someUserId));
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {!someUserInfo?.user?.isDeleted &&
                    !isUserBlocked(blockedUsers, someUserId) && (
                      <>
                        {/* <div className="block-btn">
                          <SecondaryButton
                            text={formatMessage({ id: 'block' })}
                            onClick={() => {
                              BlockUserById({ userId: someUserId }).then(() => {
                                getBlockedUsersAPI().then(res => dispatch(setBlockedUsers((res.data))));
                              })
                            }}
                          />
                        </div>

                        <div className="report-btn">
                          <SecondaryButton
                            text={formatMessage({ id: 'report' })}
                            onClick={() => {
                              setReportModal(true);
                            }}
                          />
                        </div> */}

                        <div className="report-btn">
                          <SecondaryButton
                            text={formatMessage({ id: 'report/block' })}
                            onClick={() => {
                              setReportModal(true);
                            }}
                          />
                        </div>

                        <div>
                          <button
                            type="button"
                            className="message-btn"
                            onClick={() => {
                              navigate(`/chat?id=${someUserId}`);
                              dispatch(cleanMsgs());
                            }}
                          >
                            {formatMessage({ id: 'message' })}
                          </button>
                        </div>

                        <div className="follow-btn">
                          {someUserInfo?.user?.ifFollow ? (
                            <SecondaryButton
                              text={formatMessage({ id: 'unfollow' })}
                              onClick={() => {
                                followUserFunc(someUserInfo?.user);
                              }}
                            />
                          ) : (
                            <MainButton
                              text={formatMessage({ id: 'follow' })}
                              onClick={() => {
                                followUserFunc(someUserInfo?.user);
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}

                  {isUserBlocked(blockedUsers, someUserId) && (
                    <div className="unblock-btn">
                      <SecondaryButton
                        text={formatMessage({ id: 'unblock' })}
                        onClick={() => {
                          UnBlockUser({ userId: someUserId }).then(() => {
                            getBlockedUsersAPI().then(res =>
                              dispatch(setBlockedUsers(res.data)),
                            );
                          });
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ) : 
          // !otherUser ? (

                // <>
                //   <div className="wall__header-edit-btn-container">
                //     <div className="report-btn">
                //       <MainButton
                //         text={formatMessage({ id: 'Invite' })}
                //         onClick={() => setOpenInvite(true)}
                //       />
                //     </div>
                //     <Link
                //       to="/account-settings"
                //       className="wall__header-edit-btn"
                //     >
                //       <SecondaryButton
                //         text={formatMessage({ id: 'editAccount' })}
                //         onClick={() => { } } />
                //     </Link>
                //   </div>
                // </>
  
          // ) : (
            <>
              <div className="wall__header-edit-btn-container">
                {isFollow ? (
                  <SecondaryButton
                    text={formatMessage({ id: 'unfollow' })}
                    onClick={() => {
                      dispatch(followGroupThunk({ [filterIdName]: `${id}` }));
                    }}
                  />
                ) : (
                  <MainButton
                    text={formatMessage({ id: 'follow' })}
                    onClick={() => {
                      dispatch(followGroupThunk({ [filterIdName]: `${id}` }));
                    }}
                  />
                )}
              </div>
            </>
          }
        </div>
      </div>

      {(profileId === someUserId ||
        !someUserInfo?.user?.isDeleted ||
        groupsView) &&
        !isUserBlocked(blockedUsers, someUserId) && (
          <>
            {!groupsView ? (
              <div className="wall__links">
                <Box
                  sx={{
                    padding: width < 1100 ? '0 16px' : undefined,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: lan === 'ar' ? 'flex-end' : 'flex-start',
                  }}
                >
                  <Tabs
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab
                      label={
                        someUserId === profileId
                          ? isAdmin
                            ? formatMessage({ id: 'feed' })
                            : formatMessage({ id: 'myFeed' })
                          : formatMessage({ id: 'feed' })
                      }
                      {...a11yProps(0)}
                    />

                    {/*<Tab*/}
                    {/*  label={formatMessage({ id: 'aboutMe' })}*/}
                    {/*  {...a11yProps(1)}*/}
                    {/*/>*/}
                    <Tab
                      label={formatMessage({ id: 'photos' })}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={formatMessage({ id: 'videos' })}
                      {...a11yProps(2)}
                    />

                    <Tab
                      label={`${formatMessage({ id: 'followers' })} ${
                        someUserId &&
                        someUserInfo &&
                        someUserInfo?.user?.privacy?.showFollowers
                          ? `(${someUserInfo?.followers})`
                          : ''
                      }`}
                      {...a11yProps(3)}
                      {...isLocked(someUserInfo?.user?.privacy?.showFollowers)}
                      iconPosition="end"
                    />

                    <Tab
                      label={`${formatMessage({ id: 'following' })} ${
                        someUserId &&
                        someUserInfo &&
                        someUserInfo?.user?.privacy?.showFollowing
                          ? // ? `(${someUserId === profileId
                            //   ? following?.following?.length
                            //   : someUserInfo?.following
                            // })`
                            `(${someUserInfo?.following})`
                          : ''
                      }`}
                      {...a11yProps(4)}
                      {...isLocked(someUserInfo?.user?.privacy?.showFollowing)}
                      iconPosition="end"
                    />
                  </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                  <MyFeed type="profile" />
                </TabPanel>

                {/*<TabPanel value={value} index={1}>*/}
                {/*  <AboutMe />*/}
                {/*</TabPanel>*/}

                <TabPanel value={value} index={1}>
                  <Photos />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <Videos />
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <Followers />
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <Following />
                </TabPanel>
              </div>
            ) : (
              <MyFeed type="groups" />
            )}
          </>
        )}

      {reportModal && (
        <ReportBlockModal
          someUserId={someUserId}
          setReportModal={setReportModal}
        />
      )}

      {cropModal.open && (
        <ImageCropModal
          image={cropModal.image}
          cropShape={cropModal.cropShape}
          onClose={() =>
            setCropModal({
              open: false,
              image: '',
              cropShape: 'rect',
              formData: '',
            })
          }
          onSave={cropModal.onSave}
        />
      )}

      {openInvite && <InviteModal setOpenInvite={setOpenInvite}/>}
    </div>
  );
};

