import React, { useEffect, useState } from 'react';
import './ProfilesPageTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigateToTop } from '../helpers/useNavigateToTop';
import ShowMoreAds from '../ShowMoreAds/ShowMoreAds';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import { useSearchParams } from 'react-router-dom';
import { ReportReasons } from '../ReportReasons/ReportReasons';
import {
  activeProfile,
  deactiveProfile,
  getDeactiveProfiles,
  getReportedProfiles,
  getReportedUserReasons,
} from '../../redux/slices/adminSlice';
import { useIntl } from 'react-intl';

type Props = {
  tab: 'reportedOn' | 'disabledRequest';
};

export const ProfilesPageTab: React.FC<Props> = ({ tab }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigateToTop = useNavigateToTop();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams() || '';
  const reportReasons = searchParams.get('report-reasons') || '';

  const [profiles, setProfiles] = useState<any>([]);
  const [skip, setSkip] = useState(0);

  const { id: profileId } = useSelector((state: RootState) => state.profile);
  const { reportedProfiles, deactiveProfiles } = useSelector(
    (state: RootState) => state.admin,
  );

  const openReportReasons = (userId: string) => {
    dispatch(getReportedUserReasons(userId));
    searchParams.set('report-reasons', 'on');

    if (reportReasons === 'on') {
      searchParams.delete('report-reasons');
    } else {
      searchParams.set('report-reasons', 'on');
    }

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  useEffect(() => {
    dispatch(getReportedProfiles(skip));
    dispatch(getDeactiveProfiles(skip));
  }, [dispatch, skip, tab]);

  useEffect(() => {
    setProfiles(tab === 'reportedOn' ? reportedProfiles : deactiveProfiles);
  }, [tab, reportedProfiles, deactiveProfiles]);

  const handleMoreReportedCommentsFunc = () => {
    setSkip(prev => prev + 10);
  };

  return (
    <div className="ProfilesPageTab">
      <ShowMoreAds type={'profile'} />
      <h2 className="ProfilesPageTab__main-title">
        {`Profiles That Have Been ` +
          `${tab === 'reportedOn' ? 'Reported On' : 'Disabled'}`}
      </h2>
      <div className="ProfilesPageTab__list-container">
        {profiles
          ?.filter((item: any) => {
            if (
              (tab === 'reportedOn'
                ? item?.reportedUser?.id
                : item?.user?.id) === profileId
            ) {
              return false;
            }
            return true;
          })
          ?.map((profile: any) => (
            <div
              className="ProfilesPageTab__wrapper"
              key={
                tab === 'reportedOn' ? profile?.reportedUser?.id : profile.user?.id
              }
            >
              <div style={{ display: 'flex' }}>
                <div className="followers__following-avatar-container">
                  <img
                    className="followers__following-avatar"
                    src={
                      tab === 'reportedOn'
                        ? profile.reportedUser?.profileAvatar
                        : profile.user?.profileAvatar
                    }
                    alt="profile avatar"
                  />
                </div>

                <div className="ProfilesPageTab__title-wrapper">
                  <div
                    onClick={() =>
                      navigateToTop(
                        `/profile/${
                          tab === 'reportedOn'
                            ? profile.reportedUser?.id
                            : profile.user?.id
                        }`,
                      )
                    }
                  >
                    <h2 className="ProfilesPageTab__title">
                      {`${
                        tab === 'reportedOn'
                          ? profile.reportedUser?.organizationName
                          : profile.user?.organizationName
                      }` ||
                        `${
                          tab === 'reportedOn'
                            ? profile.reportedUser?.firstName
                            : profile.user?.firstName
                        } ${
                          tab === 'reportedOn'
                            ? profile.reportedUser?.lastName
                            : profile.user?.lastName
                        }`}
                    </h2>
                  </div>
                  <h4 className="ProfilesPageTab__subtitle">
                    {tab === 'reportedOn'
                      ? profile?.reportedUser?.region?.trim().length > 0 &&
                        profile?.reportedUser?.region !== ''
                        ? `${profile?.reportedUser?.region}, `
                        : ''
                      : profile?.user?.region.trim()?.length > 0 &&
                        profile?.user?.region !== ''
                      ? `${profile?.user?.region}, `
                      : ''}
                    {tab === 'reportedOn'
                      ? profile?.reportedUser?.country
                      : profile?.user?.country}
                    {/* {`${tab === 'reportedOn' ? profile.reportedUser.region : profile.user.region},`} {`${tab === 'reportedOn' ?
                    profile.reportedUser?.country : profile.user?.country}`} */}
                  </h4>
                  {tab === 'reportedOn' && (
                    <div className="ProfilesPageTab__container-show-reasons">
                      <h4 className="ProfilesPageTab__subtitle">
                        Reported {profile.reportedUser?.counter} times
                      </h4>
                      <h4
                        onClick={() =>
                          openReportReasons(profile?.reportedUser?.id)
                        }
                        className="ProfilesPageTab__show-reasons"
                      >
                        Show reasons
                      </h4>
                    </div>
                  )}
                </div>
              </div>

              <div className="ProfilesPageTab__btnWrapper">
                {(tab === 'reportedOn'
                  ? profile?.reportedUser?.id
                  : profile?.user?.id) !== profileId && (
                  <>
                    {(
                      tab === 'reportedOn'
                        ? profile?.reportedUser?.isDeleted
                        : profile?.user?.isDeleted
                    ) ? (
                      <SecondaryButton
                        text="Enable"
                        type="button"
                        onClick={() => {
                          dispatch(
                            activeProfile(
                              tab === 'reportedOn'
                                ? profile?.reportedUser?.id
                                : profile?.user?.id,
                            ),
                          );
                        }}
                      />
                    ) : (
                      <MainButton
                        text="Disable"
                        type="button"
                        onClick={() => {
                          dispatch(
                            deactiveProfile(
                              tab === 'reportedOn'
                                ? profile?.reportedUser?.id
                                : profile?.user?.id,
                            ),
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
      </div>

      {/* MORE COMMENT BUTTON */}
      {((tab === 'reportedOn' && skip + 10 <= reportedProfiles?.length) ||
        (tab === 'disabledRequest' &&
          skip + 10 <= deactiveProfiles?.length)) && (
        <div className="ProfilesPageTab__more-btn">
          <SecondaryButton
            text={formatMessage({ id: 'showMore' })}
            onClick={handleMoreReportedCommentsFunc}
          />
        </div>
      )}

      {reportReasons && <ReportReasons />}
    </div>
  );
};
