import { toast } from 'react-toastify';
import { API, customAxios } from '../api/api';
import { intl } from '../localization'

const SomeUserApi = `${API}/api/user/get-user-profiles?findUserId=`;
const searchUsers_API = `${API}/api/user/find`; //get

export const getSomeUserApi = (userId: string) => {
  return customAxios
    .get(`${SomeUserApi}${userId}`)
    .then(response => {
      return response.data;
    })
};

export async function reportUser(data: { userId: string; reason: string }) {
  return await customAxios.post(`${API}/api/user/report-user`, data)
    .then(res => {
      const message = intl.intl().formatMessage({ id: 'dataSent' })
      toast.success(message)
    });
}

// ==========Search Users=======================================
// https://wizwork-dev.herokuapp.com/api/user/find?part=a&interests=4&country=Ukraine
export async function searchUsersAPI(filters: any) {
  const link = new URL(searchUsers_API);
  if (filters.part) {
    link.searchParams.set("part", filters.part);
  }
  if (filters.interests && filters.interests.length > 0) {
    link.searchParams.set("interests", filters.interests.join(','));
  }
  if (filters.country) {
    link.searchParams.set("country", filters.country);
  }

  link.searchParams.set("take", "20")
  link.searchParams.set("skip", filters?.skip || "0")

  return await customAxios.get(link.href)
}

