import React, { FC } from 'react';
import './Container.scss';

interface Props {
  children?: React.ReactNode;
}

const Container: FC<Props> = props => <>{props.children}</>;

export default Container;
