import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import {
  addNewInterest,
  editTag,
  resetNewInterestStatus,
} from '../../redux/slices/adminSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { Cloudinary_NAME, Cloudinary_PRESET, defaultBackground, uploadIconFormats, uploadImageFormats } from '../../constants';
import { tippyIconDescriptionKeys, tippyImageDescriptionKeys } from '../../constants/notifyText';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import TippyComponent from '../shared/TippyComponent/TippyComponent';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import CustomInput from '../shared/CustomInput/CustomInput';
import { Loader } from '../Loader';
import { ReactComponent as AddMedia } from '../../images/add-photo-video.svg';
import './AddNewInterestModal.scss';
import { getAllGroups, getAllInterest, getPostTypes } from '../../redux/slices/appSlice';
import { getGroupsProfileInfo } from '../../redux/slices/groupsSlice';

interface AddNewInterestModalProps {
  addNewTag: SelectedInterestType;
  setAddNewTag: Dispatch<SetStateAction<SelectedInterestType>>;
  selectedTag?: any;
  setEditTag?: (obj: any) => void
}

interface InputForm {
  newInterestName: string;
}

function AddNewInterestModal({ addNewTag, setAddNewTag, selectedTag, setEditTag = () => { } }: AddNewInterestModalProps) {
  const [newInterestText, setNewInterestText] = useState<string>(selectedTag?.title || selectedTag?.typeName || '');
  const [newInterestImage, setNewInterestImage] = useState<string>(selectedTag?.avatar || selectedTag?.url ||
    'https://res.cloudinary.com/dsypundib/image/upload/v1666348695/static%20images/zk5ocpflmcxxejyjjvd8_wvk1xd.png');
  const [newInterestBackground, setNewInterestBackground] = useState<string>(selectedTag?.background || defaultBackground);
  const [loader, setLoader] = useState<boolean>(false);
  const { newInterestStatus } = useSelector((state: RootState) => state.admin);
  const lan = localStorage.getItem('lang') || 'en';

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const {
    register,
    resetField,
    handleSubmit,
    formState: {
      isValid,
      errors,
    }
  } = useForm<InputForm>({ mode: 'onChange' });

  useEffect(() => {
    if (newInterestStatus === 'loading') {
      setLoader(true);
    }
    if (newInterestStatus === 'success') {
      setNewInterestText('');
      setNewInterestBackground('');
      setNewInterestImage('');
      resetField('newInterestName');
      setAddNewTag({
        type: 'DEFAULT',
        status: false,
      });
      setLoader(false);
      dispatch(resetNewInterestStatus());
    }
    if (newInterestStatus === 'rejected') {
      setLoader(false);
      dispatch(resetNewInterestStatus());
    }
  }, [setAddNewTag, dispatch, newInterestStatus, resetField]);

  const onAddImage = async (
    image: FileList | null,
    setImage: Dispatch<SetStateAction<string>>,
  ) => {
    if (image === null) return;

    const imageSize = image[0].size / (1024 ** 2);
    if (imageSize > 5) {
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }
    const formData = new FormData();
    formData.append('file', image[0]);
    formData.append('upload_preset', Cloudinary_PRESET);

    await axios
      .post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, formData)
      .then((response: AxiosResponse<any>) => {
        setImage(response.data.secure_url);
      })
      .catch(() => {
        toast.error(formatMessage({ id: 'errorCreateOrEditPost' }));
        setImage('');
      });
  };

  const onSubmit = (data: InputForm) => {
    if (selectedTag) {
      dispatch(editTag({
        [selectedTag.typeId]: selectedTag.id,
        typeName: data.newInterestName,
        title: data.newInterestName,
        // url: newInterestImage,
        avatar: newInterestImage,
        background: newInterestBackground,
      }));

      setEditTag({
        tagId: 0,
        status: false,
        type: '',
      })
    } else {
      dispatch(addNewInterest({
        type: addNewTag.type,
        alias: 'string',
        title: data.newInterestName,
        url: newInterestImage,
        avatar: newInterestImage,
        background: newInterestBackground,
      }));
    }
  };

  return (
    <>
      {!loader ?
        <ModalWrapper>
          <ModalContainer>
            <ModalHeader
              textHeader={selectedTag ? 'Edit Tag' : formatMessage({ id: 'createNewTag' })}
              onClose={() => {
                setAddNewTag({
                  type: 'DEFAULT',
                  status: false,
                })

                setEditTag({
                  tagId: 0,
                  status: false,
                  type: '',
                })
              }}
            />
            <form
              className="AddNewInterestModal"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CustomInput
                placeholder={formatMessage({ id: 'writeNameHere' })}
                label={formatMessage({ id: 'nameLabel' })}
                inputName="newInterestName"
                onChangeFunc={(value: string) => setNewInterestText(value)}
                errors={errors}
                defaultValue={newInterestText}
                inputProps={{
                  ...register('newInterestName', {
                    required: formatMessage({ id: 'fieldIsEmpty' }),
                    maxLength: 70,
                  }),
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <TippyComponent
                object={
                  <label className="AddNewInterestModal__uploadBtn">
                    <AddMedia className="AddNewInterestModal__uploadBtn-icon" />
                    <span className="AddNewInterestModal__uploadBtn-text">
                      {newInterestImage ?
                        formatMessage({ id: 'changeTagPicture' }) :
                        formatMessage({ id: 'addTagPicture' })}
                    </span>
                    <input
                      multiple
                      name="uploadedImg"
                      type="file"
                      accept={uploadIconFormats}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onAddImage(e.target.files, setNewInterestImage);
                      }}
                      style={{ display: 'none' }}
                    />
                  </label>
                }
                text={
                  <ul>
                    {tippyIconDescriptionKeys
                      .map((item: string, index: number) => (
                        <li key={index}>{formatMessage({ id: item })}</li>
                      ))}
                  </ul>
                }
                placement="left"
                maxWidth={300}
              />
              {(newInterestImage || newInterestText) ?
                <div className="AddNewInterestModal__preview">
                  <h4 className="AddNewInterestModal__preview-title">
                    {selectedTag ? 'The Tag Will Look Like This' : formatMessage({ id: 'newTagWillLook' })}
                  </h4>
                  <div className="AddNewInterestModal__preview-content">
                    {newInterestImage ?
                      <img
                        className="AddNewInterestModal__preview-img"
                        src={newInterestImage}
                        alt="preview"
                      /> :
                      <div className="AddNewInterestModal__preview-img" />}
                    <span className="AddNewInterestModal__preview-text">
                      {newInterestText}
                    </span>
                  </div>
                </div> : null}
              <TippyComponent
                object={
                  <label className="AddNewInterestModal__uploadBtn">
                    <AddMedia className="AddNewInterestModal__uploadBtn-icon" />
                    <span className="AddNewInterestModal__uploadBtn-text">
                      {formatMessage({ id: 'changeBackgroundPicture' })}
                    </span>
                    <input
                      multiple
                      name="uploadedImg"
                      type="file"
                      accept={uploadImageFormats}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onAddImage(e.target.files, setNewInterestBackground);
                      }}
                      style={{ display: 'none' }}
                    />
                  </label>
                }
                text={
                  <ul>
                    {tippyImageDescriptionKeys
                      .map((item: string, index: number) => (
                        <li key={index}>{formatMessage({ id: item })}</li>
                      ))}
                  </ul>
                }
                placement="left"
                maxWidth={300}
              />
              <img
                className="AddNewInterestModal__background"
                src={newInterestBackground}
                alt="Background"
              />
              <ModalFooter>
                <MainButton
                  type="submit"
                  disabled={(!isValid && newInterestImage) ? true : false}
                  text={selectedTag ? "Save Changes" : formatMessage({ id: 'createTag' })}
                />
              </ModalFooter>
            </form>
          </ModalContainer>
        </ModalWrapper> :
        <Loader />}
    </>
  )
}

export default AddNewInterestModal;
