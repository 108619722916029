import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { purple } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import { RootState } from '../../../redux/store';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import CustomInput from '../../../components/shared/CustomInput/CustomInput';
import TextArea from '../../../components/shared/TextArea/TextArea';
import useWindowDimensions from '../../../hooks/widthHook';
import clinch from '../../../images/clinch.svg';
import './ContactUsFaq.scss';

export const ContactUsFaq: React.FC = () => {
  const [attachedFiles, setAttachedFiles] = useState<any>([]);
  const lan = localStorage.getItem('lang') || 'en';

  const { width } = useWindowDimensions();

  const {
    register,
    reset,
    formState: { errors, isValid, touchedFields },
  } = useForm({ mode: 'onBlur' });

  const form: any = useRef();
  const { formatMessage } = useIntl();

  const attachFiles = (files: FileList | null) => {
    if (files) {
      setAttachedFiles([...attachedFiles, ...Array.from(files)]);
    }
  };

  const userData = useSelector((state: RootState) => state.profile);

  const sendEmail = (e: any) => {
    emailjs.sendForm('service_f9d8aoh', 'template_7cjs4od', form.current, '7tUP71gqJDMamsYAA')
      .then(
        (result: any) => {
          toast.success(formatMessage({ id: 'dataSent' }));
        }
      );
  };

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[500],
      },
    },
  });

  return (
    <div className="faq">
      <div className="faq-title">{formatMessage({ id: 'askQuestion' })}</div>

      <form
        ref={form}
        onSubmit={e => {
          e.preventDefault();
          sendEmail(e);
          setAttachedFiles([]);
          reset();
        }}
      >
        <div className="faq-contact-us-row">
          <div
            className="faq-contact-us-inp-container"
            style={{
              marginRight: width > 1100 ? '16px' : '0'
            }}
          >
            <CustomInput
              width={width > 1100 ? 270 : '100%'}
              placeholder={formatMessage({ id: 'firstNamePlaceholder' })}
              label={formatMessage({ id: 'nameLabel' })}
              inputName="Name"
              touchedFields={touchedFields}
              errors={errors}
              inputProps={{
                ...register('Name', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                }),
                defaultValue: userData.firstName,
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                }
              }}
            />
          </div>

          <div
            className="faq-contact-us-inp-container"
            style={{
              marginRight: width > 1100 ? '16px' : '0',
              marginTop: width > 1100 ? '0' : '12px',
            }}
          >
            <CustomInput
              width={width > 1100 ? 270 : '100%'}
              placeholder={formatMessage({ id: 'emailPlaceholder' })}
              label={formatMessage({ id: 'emailLabel' })}
              touchedFields={touchedFields}
              inputName="email"
              errors={errors}
              // onChangeFunc={(value) => { setValue('email', value); }}
              inputProps={{
                ...register('email', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  // minLength: 10,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: formatMessage({ id: 'invalidEmail' }),
                  },
                }),
                defaultValue: userData.email,
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                }
              }}
            />
          </div>
        </div>

        <div className="faq-contact-us-row">
          <CustomInput
            width="100%"
            label={formatMessage({ id: 'subject' })}
            inputName="Subject"
            errors={errors}
            touchedFields={touchedFields}
            inputProps={{
              ...register('Subject', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
              }),
              style: {
                fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
              }
            }}
          />
        </div>

        <div className="faq-contact-us-row">
          <TextArea name="Message" register={register} errors={errors} />

          <ThemeProvider theme={theme} >
            <Button
              className="faq-contact-us-submit"
              variant="text"
              component="label"
              style={{
                position: 'absolute',
                borderRadius: '8px'
              }}
            >
              <span className="faq-contact-us-submit-text">
                {formatMessage({ id: 'attachFiles' })}
              </span>
              <input
                name="file"
                type="file"
                hidden
                multiple
                onChange={e => {
                  attachFiles(e.target.files);
                }}
              />
              <img src={clinch} alt="button" />
            </Button>
          </ThemeProvider>
        </div>

        {attachedFiles.length > 0 && (
          <div className="faq-contact-us-attached forMobile">
            {attachedFiles.map((file: any, index: number) => (
              <div className="faq-contact-us-attached-row" key={index}>
                <span>{file.name}</span>
                <AddIcon
                  onClick={() => {
                    setAttachedFiles(
                      attachedFiles.filter((el: any, i: number) => index !== i),
                    );
                  }}
                  style={{
                    transform: 'rotateZ(45deg)',
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {/* <div className='faq-btn-label'>
          {formatMessage({id: 'contactUsButtonLabel' })}
        </div> */}

        <MainButton
          type="submit"
          text={formatMessage({ id: 'submit' })}
          disabled={!isValid} />
      </form>
    </div>
  );
};
