import React, { PropsWithChildren } from 'react';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';
import './CheckBox.scss';

type Props = {
  value?: boolean | undefined,
  onChange?: (data: any) => void,
  checked?: boolean | undefined,
  defaultChecked?: any,
  isParent?: boolean,
  data?: any,
};

export const CheckBox: React.FC<Props> = ({ onChange, defaultChecked, isParent}: PropsWithChildren<Props>) => {

  return (
    <Checkbox
      // {...label}
      icon={<CheckBoxOutlineBlankTwoToneIcon sx={{ fontSize: '16px', }} />}
      checkedIcon={!isParent
        ? <CheckBoxTwoToneIcon sx={{ color: "#791186", fontSize: '16px', }} />
        : <IndeterminateCheckBoxTwoToneIcon sx={{ color: "#791186", fontSize: '16px', }} />
      }
      disableRipple={true}
      sx={{
        color: "#6C757D",
        fontSize: '16px',
        "&.MuiButtonBase-root:hover": {
          color: "#791186"
        }
      }}
      onChange={onChange}
      defaultChecked={defaultChecked}
    />
  );
};
