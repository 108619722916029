import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import './UploadButton.scss';

interface Props {
  uploadImage: (files: FileList | null, imgType?: string) => void;
  transparent?: boolean;
  text?: string;
  paddingNone?: boolean;
  imgType?: string;
}

const UploadButton: FC<Props> = ({
  uploadImage,
  paddingNone,
  transparent,
  text,
  imgType = '',
}: Props) => {
  const { formatMessage } = useIntl();
  const labelClass = `uploadBtn${transparent ?
    ' uploadBtn--transparent' : ''}${paddingNone ?
      ' uploadBtn--paddingNone' : ''}`;

  return (
    <label className={labelClass}>
      {text ? text : formatMessage({ id: 'uploadNewPhoto' })}
      <input
        name="uploadedImg"
        type="file"
        accept=".png, .jpg, .jpeg"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          uploadImage(e.target.files, imgType);
          e.target.value = '';
        }}
        style={{ display: 'none' }}
      />
    </label>
  );
};

export default UploadButton;
