import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/slices/appSlice';
import { AppDispatch, RootState } from '../../redux/store';
import './Loader.scss';

interface LoaderProps {
  withoutPosition?: boolean;
  style?: CSSProperties;
}

export const Loader = ({ withoutPosition = false, style }: LoaderProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoadingDone } = useSelector((state: RootState) => state.app);
  const [extraClassName, setExtraClassName] = useState(false);

  // useEffect(() => {
  //   if (isLoadingDone) {
  //     // setTimeout(async () => {
  //     // setExtraClassName(true);
  //     // setTimeout(() => {
  //       // setExtraClassName(true);
  //     dispatch(hideLoader(false));
  //     dispatch(showLoader(false));
  //     // }, 1000);
  //     // }, 3000);
  //   }
  // }, [isLoadingDone]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
      document.documentElement.style.overflow = 'visible';
    };
  }, [])

  return (
    <>
      {withoutPosition ?
        <div
          className={`loader ${extraClassName ? 'done' : ''}`}
          style={style}
        >
          <span className="loader-done"></span>
          <span className="circle c1"></span>
          <span className="circle c2"></span>
          <span className="circle c3"></span>
        </div> :
        <div className="loader_backDrop">
          <div className="loader-container">
            <div className={`loader ${extraClassName ? 'done' : ''}`}>
              <span className="loader-done"></span>
              <span className="circle c1"></span>
              <span className="circle c2"></span>
              <span className="circle c3"></span>
            </div>
          </div>
        </div>}
    </>
  );
};
