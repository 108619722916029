import React from 'react';
// import logo from '../../../images/logo.svg';
// import wizwork from '../../../images/wizwork.svg';
import wizworkLogo from '../../../images/WizWorkLogo.png';
import useWindowDimensions from '../../../hooks/widthHook';
import OnboardingForm from './OndoardingForm/OnboardingForm';
import './Onboarding.scss';

type Props = {
  setStep: (step: number) => void;
  onSubmitSignUp: (onboarding: any) => void;
  signUp?: boolean
};

const Onboarding = ({ setStep, onSubmitSignUp, signUp }: Props) => {
  const { width } = useWindowDimensions();
  return (
    <>
      <div className="onboarding">
        {width >= 1100 && (
          <div className="onboarding-header">
            <img src={wizworkLogo} alt="logo-text" />
          </div>
        )}
        <OnboardingForm setStep={setStep} onSubmitSignUp={onSubmitSignUp} signUp/>
      </div>
    </>
  );
};

export default Onboarding;
