import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getIcon } from '../helpers/getIcon';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import useWindowDimensions from '../../hooks/widthHook';
import partyPopper from '../../images/party-popper.png';
import { setSignUpSuccessModal } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import './SignUpSuccessModal.scss';

function SignUpSuccessModal() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useSelector((state: RootState) => state.profile);

  const { width } = useWindowDimensions();
  const iconWidth = width > 1100 ? '56' : '32';
  const modalWidth = width > 1100 ? '500px' : '327px';

  return (
    <ModalWrapper width={modalWidth}>
      <ModalContainer congrats>
        <div className="signUp-success">
          {getIcon(partyPopper, 'congrats!', iconWidth)}
          {language === 'ar' ?
            <>
              <h3 className="signUp-success__title" dir="rtl">
                {formatMessage({ id: 'congrats' })}
              </h3>
              <p className="signUp-success__subtitle" dir="rtl">
                {formatMessage({ id: 'justJoined' })}
                {' '}
                <span>WizWork</span>
              </p>
              <p className="signUp-success__description" dir="rtl">
                {formatMessage({ id: 'welcomeToCommunity' })}
              </p>
            </> :
            <>
              <h3 className="signUp-success__title">
                {formatMessage({ id: 'congrats' })}
              </h3>
              <p className="signUp-success__subtitle">
                {formatMessage({ id: 'justJoined' })}
                {' '}
                <span>WizWork</span>
              </p>
              <p className="signUp-success__description">
                {formatMessage({ id: 'welcomeToCommunity' })}
              </p>
            </>}
          <ModalFooter>
            <MainButton
              type="button"
              text={formatMessage({ id: 'enjoy' })}
              onClick={() => dispatch(setSignUpSuccessModal(false))}
            />
          </ModalFooter>
        </div>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default SignUpSuccessModal;
