import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import FilterIcon from '@mui/icons-material/Filter';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useSocket } from '../../../App';
import { AppDispatch, RootState } from '../../../redux/store';
import { createNewChat, setMenuOpenStatus } from '../../../redux/slices/chatSlice';
import ChatListSearch from './ChatListSearch';
import './ChatList.scss';

export default function ChatList() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams() || '';
  const socket = useSocket();

  const userId = searchParams.get('id') || '';
  const chatId = searchParams.get('chatId') || '';
  const lan = localStorage.getItem('lang') || 'en';

  const [chatName, setChatName] = useState("");

  const { id, onlineStatus } = useSelector((state: RootState) => state.profile);
  const { chatsList, msgCount } = useSelector((state: RootState) => state.chat);
  const { someUser } = useSelector((state: RootState) => state.someUser);

  useEffect(() => {
    if (!chatId && !userId && chatsList?.length) {
      navigate(`/chat?chatId=${chatsList[0]?.id}`)
    }
  }, [chatsList.length])

  useEffect(() => {
    if (socket) {
      if (userId) {
        const isChatExist = chatsList?.some((chat: any) => chat?.userIds.includes(userId));
  
        if (!isChatExist && userId) {     
          dispatch(createNewChat({
            id: 0,
            userIds: [id, userId],
            created_at: Date.now(),
            text: "",
            date: Date.now(),
            avatar: someUser?.user.profileAvatar,
            name: someUser?.user?.firstName
            ? `${someUser?.user?.firstName} ${someUser?.user?.lastName}`
            : someUser?.user?.organizationName
          }));
        }

        if (userId && isChatExist) {
          const findedChat = chatsList?.find((chat: any) => chat?.userIds.includes(userId));
          navigate(`/chat?chatId=${findedChat.id}`)
        }
  
        socket.on('newChat', (data: any) => {
          navigate(`/chat?chatId=${data.id}`)
        })
      }
    }
  }, [socket]);

  const convertDate = (dateStr: string) => {
    const dateObj = new Date(dateStr);
    return dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }

  const selectChat = (id: number) => {
    dispatch(setMenuOpenStatus(false));
    navigate(`/chat?chatId=${id}`)
  };

  return (
    <div className="ChatList">
      <div className="ChatList__header">
        <h2 className="ChatList__title">{formatMessage({ id: 'Messaging' })}</h2>
        <ChatListSearch chatName={chatName} setChatName={setChatName}/>
      </div>
      <div className="ChatList__main">
        {chatsList?.filter(
          (a: any) => a?.name?.toLowerCase()?.includes(chatName.toLowerCase())
          )?.map((item: any) => (
          <div 
            key={item?.id}
            className={`ChatList__item ${lan === 'ar' && "ChatList__item--ar"} ${msgCount.includes(item?.id) && "ChatList__item--unread"}`}
            onClick={() => selectChat(item?.id)}
          >
            <div className="ChatList__photo-wrapper">
              <img 
                src={item?.avatar} 
                alt="avatar" 
                className={`ChatList__photo ${lan === 'ar' && "ChatList__photo--ar"}`}
              />
              <div className={`ChatList__online-status ${lan === 'ar' && "ChatList__online-status--ar"}`}>
                <div 
                  className="ChatList__online-status-color"
                  style={{
                    backgroundColor: onlineStatus?.some((a: string) => 
                      a === (item?.userIds?.find((user: string) => user !== id))
                    )
                      ? '#28A745'
                      : '#FD2551',
                  }}
                />
              </div>
            </div>
            <div className="ChatList__nameContainer">
              <p className="ChatList__name">{item?.name}</p>
              {item?.text
                ? (item.text === '\t'
                  ? <div className="ChatList__icon">
                    <FilterIcon sx={{fontSize: 16}}/>
                  </div> 
                  : <p className="ChatList__msg">
                    {item?.text.length > 30 
                      ? item?.text.slice(0, 30) + '...'
                      : item?.text
                    }
                  </p>)
                : ""
              }
            </div>
            <p className={`ChatList__date ${lan === 'ar' && "ChatList__date--ar"}`}>
              {convertDate(item?.date)}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
