import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { updateUserPassword } from '../../../api/user';
import ErrorBoundary from '../../../components/ErrorBoundary';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import CustomInput from '../../../components/shared/CustomInput/CustomInput';
import ShowPassword from '../../../components/shared/CustomInput/ShowPassword/ShowPassword';
import useWindowDimensions from '../../../hooks/widthHook';

export const PasswordSettings = () => {
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onBlur' });

  const [isVisibleCurrentPassword, setVisibilityCurrentPassword] = useState(false);
  const [isVisibleNewPassword, setVisibilityNewPassword] = useState(false);
  const [isVisibleConfirmPassword, setVisibilityConfirmPassword] = useState(false);

  const { width } = useWindowDimensions();

  const onHandleSubmit = async (data: any) => {
    const res = await updateUserPassword({
      oldPassword: data.password,
      newPassword: data.newPassword
    })

    if (res.status === 200) {
      toast.success(formatMessage({ id: 'passwordSuccessfullyChanged' }));
    }
    reset();
  };

  //hello mark

  return (
    <div className='accountSettings' >
      <ErrorBoundary>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <div className='accountSettings-title'>
            {formatMessage({ id: 'changePassword' })}
          </div>

          <div
            className='accountSettings-block'
            style={{
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
            }}
          >
            <CustomInput
              width={width > 1100 ? 270 : '100%'}
              label={formatMessage({ id: 'currentPassword' })}
              inputName="password"
              errors={errors}
              touchedFields={touchedFields}
              onChangeFunc={value => {
                // setNewPassword(value);
                setValue('password', value);
              }}
              inputProps={{
                ...register('password', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  pattern: {
                    value:
                      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-\+={}\[\]'";:?.>\|,<\\])[A-Za-z\d`~!@#$%^&*()_\-\+={}\[\]'";:?.>\|,<\\]{8,}/i,
                    message: formatMessage({ id: 'weakPassword' }),
                  },
                }),
                type: isVisibleCurrentPassword ? 'text' : 'password',
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                }
              }}
              InputProps={{
                endAdornment: (
                  < ShowPassword
                    onClick={() => {
                      setVisibilityCurrentPassword(!isVisibleCurrentPassword);
                    }}
                    isVisible={isVisibleCurrentPassword}
                  />
                )
              }}
            />
          </div>

          <div
            className='accountSettings-block'
            style={{
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
            }}
          >
            <CustomInput
              width={width > 1100 ? 270 : '100%'}
              label={formatMessage({ id: 'newPassword' })}
              inputName="newPassword"
              errors={errors}
              touchedFields={touchedFields}
              onChangeFunc={value => {
                // setNewPassword(value);
                setValue('newPassword', value);
              }}
              inputProps={{
                ...register('newPassword', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  pattern: {
                    value:
                      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-\+={}\[\]'";:?.>\|,<\\])[A-Za-z\d`~!@#$%^&*()_\-\+={}\[\]'";:?.>\|,<\\]{8,}/i,
                    message: formatMessage({ id: 'weakPassword' }),
                  },
                  validate: value => value !== getValues('password') ||
                    formatMessage({ id: 'samePassword' }),
                }),
                type: isVisibleNewPassword ? 'text' : 'password',
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                }
              }}
              InputProps={{
                endAdornment: (
                  <ShowPassword
                    onClick={() => {
                      setVisibilityNewPassword(!isVisibleNewPassword);
                    }}
                    isVisible={isVisibleNewPassword}
                  />
                )
              }}
            />
          </div>

          <div
            className='accountSettings-block'
            style={{
              flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
            }}
          >
            <CustomInput
              width={width > 1100 ? 270 : '100%'}
              label={formatMessage({ id: 'confirmNewPassword' })}
              inputName="confirmPassword"
              errors={errors}
              touchedFields={touchedFields}
              onChangeFunc={value => {
                // setNewPassword(value);
                setValue('confirmPassword', value);
              }}
              inputProps={{
                ...register('confirmPassword', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  validate: value => value === getValues('newPassword') ||
                    formatMessage({ id: 'unmatchingPassword' }),
                }),
                type: isVisibleConfirmPassword ? 'text' : 'password',
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                }
              }}
              InputProps={{
                endAdornment: (
                  <ShowPassword
                    onClick={() => {
                      setVisibilityConfirmPassword(!isVisibleConfirmPassword);
                    }}
                    isVisible={isVisibleConfirmPassword}
                  />
                )
              }}
            />
          </div>

          <div className='accountSettings-footer'>
            <MainButton
              type="submit"
              text={formatMessage({ id: 'changePassword' })}
              styles={width < 1100 ? {
                width: '100%',
                marginRight: '0',
                bottom: '110px',
                left: '24px',
              } : {}}
            />
          </div>
        </form>

      </ErrorBoundary>
    </div>
  );
};
