import React from 'react';
import { useIntl } from 'react-intl';
import ModalContainer from '../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../../../components/ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import { deactivateAccount } from '../../../../redux/slices/profileSlice';
import { AppDispatch } from '../../../../redux/store';
import { useDispatch } from 'react-redux';

type Props = {
  showModal: boolean,
  setShowModal: (visible: boolean) => void,
};

export const DeactivateModal: React.FC<Props> = ({ showModal, setShowModal }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      {showModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              smallCross
              textHeader={formatMessage({ id: 'deactivateConfirm' })}
              onClose={() => setShowModal(false)}
            />
            <ModalFooter>
              <SecondaryButton
                text={formatMessage({ id: 'remove' })}
                styles={{ width: 143 }}
                onClick={() => {
                  dispatch(deactivateAccount());
                  setShowModal(false);
                }}
              />
              <MainButton
                text={formatMessage({ id: 'cancel' })}
                onClick={() => setShowModal(false)}
                styles={{ width: 143 }}
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>)}
    </>
  );
};
