import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ConsoleView } from 'react-device-detect';
import { activeProfileAPI, deactiveProfileAPI } from '../../api/admin';
import { getSomeUserApi, searchUsersAPI } from '../../api/someUser';
import { getFollowersAPI, getFollowingAPI } from '../../api/user';
import { hideLoader, showLoader } from './appSlice';

interface SomeUserInfo {
  followers: number;
  following: number;
  user: {
    about: string;
    country: string;
    firstName: string;
    region: string;
    id: string;
    interests: Chip[];
    isDeleted: boolean;
    ifFollow: boolean;
    lastName: string;
    profileAvatar: string;
    profileBg: string;
    titles: string[];
    otherTitle: string;
    organizationName: string;
    showLocation: boolean;
    privacy: {
      showFollowers: boolean;
      showFollowing: boolean;
      showInterestedTags: boolean;
    };
  };
}

interface InitialState {
  someUser: SomeUserInfo | null;
  // someUserFolowing: Following[];

  someUserFolowing: {
    following: Following[];
    isLocked: boolean;
  };

  someUserFolowers: {
    followers: Followers[];
    isLocked: boolean;
  };
  // someUserFolowers: Followers[];

  showFilters: boolean;
  searchValue: string;

  overLimit: boolean;
  statusSearching: boolean;
  searchedValueFilter: string;
  searchedInterests: number[];
  searchedCountry: null | string;
  foundedUsers: any[];
}

const initialState: InitialState = {
  someUser: null,
  someUserFolowing: {
    following: [],
    isLocked: false,
  },
  someUserFolowers: {
    followers: [],
    isLocked: false,
  },
  showFilters: false,
  searchValue: '',

  overLimit: false,
  statusSearching: false,
  searchedValueFilter: '',
  searchedInterests: [],
  searchedCountry: null,
  foundedUsers: [],
};

export const getSomeUserInfo = createAsyncThunk(
  'users/getSomeUserInfo',
  async function (id: string, { dispatch }) {
    dispatch(showLoader(true));
    try {
      const userInfo = await getSomeUserApi(id);
      dispatch(setUserInfo(userInfo));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      if (error.response) {
        throw new Error('not working');
      }
    }
  },
);

// =============Following==================================
export const getFollowingSomeUser = createAsyncThunk(
  'users/getFollowingSomeUser',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const response = await getFollowingAPI(data);
      const { skip } = data;
      const newData = {
        skip,
        response: response.data.following
      }
      dispatch(setFollowingSomeUser(newData));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// =============Followers==================================
export const getFollowersSomeUser = createAsyncThunk(
  'users/getFollowersSomeUser',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const response = await getFollowersAPI(data);
      const { skip } = data;
      const newData = {
        skip,
        response: response.data.followers
      }
      dispatch(setSearchValue(data));
      dispatch(setFollowersSomeUser(newData));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// =========== Search ====================================
export const searchUsers = createAsyncThunk(
  'profile/searchUsers',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      dispatch(setSearchedValueFilter(data.part))
      const response = await searchUsersAPI(data);
      if (response.status !== 200) {
        throw new Error();
      }
      dispatch(showLoader(false));
      console.log('test')

      const overLimit = data.skip > 0 && response.data.length === 0
      dispatch(setFoundedUsers({ users: response.data, overLimit, skip: data.skip }));


      return;
    } catch (error: any) {
      dispatch(showLoader(false));
      console.log('test')
      return rejectWithValue(error);
    }
  },
);

export const deactivateProfile = createAsyncThunk(
  'someUser/deactivateProfile',
  async function (data: string, { rejectWithValue, dispatch }) {
    try {
      const response = await deactiveProfileAPI(data);

      if (response.status === 201) {
        dispatch(deactivateUser());
      }

    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const activateProfile = createAsyncThunk(
  'someUser/activateProfile',
  async function (data: string, { rejectWithValue, dispatch }) {
    try {
      const response = await activeProfileAPI(data);

      if (response.status === 201) {
        dispatch(activateUser());
      }

    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const someUserSlice = createSlice({
  name: 'someUser',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      // if (state.someUser){
      // state.someUser.followers = action.payload.followers;
      // state.someUser.following = action.payload.following;
      // state.someUser.user = action.payload.user;
      state.someUser = action.payload;
      if ((state.someUser !== null) && !state.someUser.user.isDeleted) {
        state.someUser.user.privacy.showInterestedTags = action.payload.user?.privacy.showInterestedTags || action.payload.user?.privacy.showInterested;
      }
      // }
    },
    addSomeUserFollower(state, action) {
      state.someUserFolowers.followers = [...state.someUserFolowers.followers, action.payload]
    },
    removeSomeUserFollower(state, action) {
      state.someUserFolowers.followers = state.someUserFolowers.followers.filter(el => el.id !== action.payload)
    },
    increaseSomeUserFollow(state, action) {
      const newState = state.someUserFolowers.followers.map(a => {
        if (a.id === action.payload) {
          console.log(a);
          return {
            ...a,
            ifFollow: true
          }
        } else {
          return a
        }
      });

      state.someUserFolowers.followers = newState;

      const newStateFolowing = state.someUserFolowing.following.map(a => {
        if (a.id === action.payload) {
          console.log(a);
          return {
            ...a,
            ifFollow: true
          }
        } else {
          return a
        }
      })

      state.someUserFolowing.following = newStateFolowing;
    },
    decreaseSomeUserFollow(state, action) {
      const newState = state.someUserFolowers.followers.map(a => {
        if (a.id === action.payload) {
          return {
            ...a,
            ifFollow: false
          }
        } else {
          return a
        }
      })

      state.someUserFolowers.followers = newState;
      const newStateFolowing = state.someUserFolowing.following.map(a => {
        if (a.id === action.payload) {
          console.log(a);
          return {
            ...a,
            ifFollow: false
          }
        } else {
          return a
        }
      })

      state.someUserFolowing.following = newStateFolowing;
    },
    // setFollowingSomeUser(state, action) {
    //   state.someUserFolowing = action.payload;
    // },
    setFollowingSomeUser(state, action) {
      const { skip, response } = action.payload;
      if (skip === 0) {
        state.someUserFolowing.following = response;
      } else {
        state.someUserFolowing.following = [...state.someUserFolowing.following, ...response];
      }
    },
    // setFollowersSomeUser(state, action) {
    //   state.someUserFolowers = action.payload;
    // },
    setFollowersSomeUser(state, action) {
      const { skip, response } = action.payload;
      if (skip === 0) {
        state.someUserFolowers.followers = response;
      } else {
        state.someUserFolowers.followers = [...state.someUserFolowers.followers, ...response];
      }
    },
    setShowFilters(state, action) {
      state.showFilters = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setSearchedValueFilter(state, action) {
      state.searchedValueFilter = action.payload;
    },
    setSearchedInterests(state, action) {
      state.searchedInterests = action.payload;
    },
    setSearchedCountry(state, action) {
      state.searchedCountry = action.payload;
    },
    setFoundedUsers(state, action) {
      const { overLimit, users, skip } = action.payload 

      console.log(skip)

      if(overLimit) {
        state.overLimit = true
        return
      } 

      state.overLimit = false;
      
      if(skip === undefined || skip === 0){
        state.foundedUsers = users
      } else if (users?.length){
        state.foundedUsers = [...state.foundedUsers, ...users];
      } else {
        state.foundedUsers = []
      }

    },
    deactivateUser(state) {
      if (state.someUser) {
        state.someUser.user.isDeleted = true;
      }
    },
    activateUser(state) {
      if (state.someUser) {
        state.someUser.user.isDeleted = false;
      }
    },
    resetUserProfile(state) {
      if (state.someUser) {
        state.someUser.user.profileBg = ''
        state.someUser.user.profileAvatar = ''
        state.someUser.user.firstName = ''
        state.someUser.user.lastName = ''
        state.someUser.user.organizationName = ''
        state.someUser.user.about = ''
        state.someUser.followers = 0
        state.someUser.following = 0
      }
    },
    toggleFoundedUsers(state, action) {
      state.foundedUsers?.forEach((item: any, index: number) => {
        if (item.id === action.payload) {
          state.foundedUsers[index].isDeleted = !state.foundedUsers[index].isDeleted
        }
      })
    },
    increaseFollowing(state, action) {
      if (state.someUser) {
        state.someUser.following = state.someUser?.following + 1;
      };
    },
    decreaseFollowing(state, action) {
      if (state.someUser && state.someUser.following) {
        state.someUser.following = state.someUser?.following - 1;
      };
    },
    remoweWallFollower(state, action) {
      if (state.someUser && state.someUser.user) {
        const newState = {
          ...state.someUser,
          followers: state.someUser.followers - 1,
          user: {
            ...state.someUser.user,
            ifFollow: false
          }
        }
        state.someUser = newState;
      };

      const newArr = state.someUserFolowers.followers.filter(a => a.id !== action.payload.id);
      state.someUserFolowers = {
        ...state.someUserFolowers,
        followers: newArr
      };
    },
    addWallFollower(state, action) {
      if (state.someUser && state.someUser.user) {

        const newState = {
          ...state.someUser,
          followers: state.someUser.followers + 1,
          user: {
            ...state.someUser.user,
            ifFollow: true
          }
        }
        state.someUser = newState;
      };

      if (state.someUserFolowers.followers.every(a => a.id !== action.payload.id)) {
        const newArr = [...state.someUserFolowers.followers, action.payload];
        state.someUserFolowers = {
          ...state.someUserFolowers,
          followers: newArr
        }
      };
    },
    // updateSomeUserFollowers(state, action){
    //   state.someUserFolowers = action.payload;
    // }
  },
  extraReducers: builder => {
    builder
      .addCase(searchUsers.pending, (state) => {
        state.statusSearching = true;
      })
      .addCase(searchUsers.fulfilled, (state) => {
        state.statusSearching = false;
      })
  },
});

export const {
  addSomeUserFollower,
  removeSomeUserFollower,
  setUserInfo,
  setFollowingSomeUser,
  setFollowersSomeUser,
  setShowFilters,
  setSearchValue,
  setSearchedValueFilter,
  setSearchedInterests,
  setSearchedCountry,
  setFoundedUsers,
  deactivateUser,
  activateUser,
  resetUserProfile,
  toggleFoundedUsers,
  // updateSomeUserFollowers
  increaseFollowing,
  decreaseFollowing,
  increaseSomeUserFollow,
  decreaseSomeUserFollow,
  remoweWallFollower,
  addWallFollower
} = someUserSlice.actions;
export default someUserSlice.reducer;
