import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewUser } from '../../../redux/slices/authSlice';
import { setSignUpStep } from '../../../redux/slices/appSlice';
import { AppDispatch, RootState } from '../../../redux/store';
// import useWindowDimensions from '../../../hooks/widthHook';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import Onboarding from '../Onboarding/Onboarding';
import SignUpForm from './SignUpForm/SignUpForm';
import AuthLogo from '../AuthLogo/AuthLogo';
import './SignUpPage.scss';

const SignUpPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { step } = useSelector((state: RootState) => state.app);
  const { language } = useSelector((state: RootState) => state.profile);

  const { email, password } = useSelector((state: RootState) => state.auth);

  const updateStep = (step: number) => {
    dispatch(setSignUpStep(step));
  };
  const onSubmitSignUp = (onboarding: any) => {
    const newObj = {
      email: '',
      password: '',

      isOrganization: false,
      organizationName: '',
      firstName: '',
      lastName: '',
      birthYear: '',

      country: '',
      region: '',

      titles: [],
      otherTitle: '',

      interests: [],
      profileAvatar: '',
      about: '',

      notification: {
        posts: true,
        activity: true,
        additions: true,
        surveys: true,
      },
      ads: false,
      language,
    };

    newObj.password = password;
    newObj.email = email;

    newObj.isOrganization = onboarding.checkBoxes.forOrganization;

    if (onboarding.checkBoxes.forOrganization) {
      newObj.organizationName = onboarding.organizationName;
    } else {
      newObj.firstName = onboarding.firstName;
      newObj.lastName = onboarding.lastName;
    }
    newObj.birthYear = onboarding.year;

    newObj.country = onboarding.country;
    newObj.region = onboarding.region;

    newObj.titles = onboarding.checkBoxes.titles?.map((item: string) => {
      if (item === formatMessage({ id: 'title1' })) {
        return 'title1';
      } else if (item === formatMessage({ id: 'title2' })) {
        return 'title2';
      } else if (item === formatMessage({ id: 'title3' })) {
        return 'title3';
      } else if (item === formatMessage({ id: 'title4' })) {
        return 'title4';
      } else if (item === formatMessage({ id: 'title5' })) {
        return 'title5';
      } else if (item === formatMessage({ id: 'title6' })) {
        return 'title6';
      } else if (item === formatMessage({ id: 'title7' })) {
        return 'title7';
      } else {
        return null;
      }
    });

    newObj.otherTitle = onboarding.otherTitle;
    newObj.interests = onboarding.selectedChips.map(
      (item: SelectedChip) => +item.id,
    );

    newObj.profileAvatar =
      onboarding.uploadedAvatar || onboarding.exampleAvatar;
    newObj.about = onboarding.aboutMe;

    newObj.notification = onboarding.checkBoxes.notification;
    newObj.ads = onboarding.checkBoxes.advertisements;

    dispatch(addNewUser(newObj));
  };

  return (
    <AuthContainer step={step} language={true}>
      <div className="authContainerWrapper-signUp">
        
        <div className='ellipse'></div>
        <div className='authWrapper'>
          {step === 1 && <AuthLogo />}
          {step === 1 && (
            <div className="authForm">
              <div className="signUpFormWrapper">
                <SignUpForm setStep={updateStep} />
                {/* <div style={{ marginTop: 16, textAlign: 'center' }}>
                  <span>{formatMessage({ id: 'alreadyHaveAccount' })}</span>{' '}
                  <b>
                    <Link to="/sign-in">{formatMessage({ id: 'login' })}</Link>
                  </b>
                </div> */}
                <div className="signUpTextWrapper">
                  <span className="signUpText">
                    {formatMessage({ id: 'agreeToOur' })}
                  </span>{' '}
                  <b className="signUpLink">
                    <a
                      style={{ color: '#363636' }}
                      href={formatMessage({ id: 'termsOfUseFile' })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage({ id: 'termsOfUse' })}
                    </a>
                  </b>{' '}
                  <span className="signUpText">
                    {' '}
                    {formatMessage({ id: 'and' })}{' '}
                  </span>{' '}
                  <b className="signUpLink">
                    <a
                      style={{ color: '#363636' }}
                      href={formatMessage({ id: 'privacyPolicyFile' })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage({ id: 'privacyPolicy' })}
                    </a>
                  </b>
                </div>
              </div>
            </div>
          )}
        </div>
        {step === 2 && (
          <Onboarding setStep={updateStep} onSubmitSignUp={onSubmitSignUp} signUp />
        )}
      </div>
    </AuthContainer>
  );
};

export default SignUpPage;
