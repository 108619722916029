import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useWindowDimensions from '../../../../hooks/widthHook';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CheckBoxLabel from '../../../../components/shared/CheckBoxLabel/CheckBoxLabel';
import Select from '../../../../components/shared/Select/Select';
import { allCountries } from 'country-region-data';
import './SearchAccordion.scss';
import { useIntl } from 'react-intl';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: '0px solid #E9E9E9',
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: '#fff',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(135deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

type Props = {
  inpData?: any;
  countries?: boolean;
  setChosenInterestList?: React.Dispatch<React.SetStateAction<number[]>>;
  setChosenRegionsList?: React.Dispatch<React.SetStateAction<number[]>>;
  setChosenCountry?: React.Dispatch<React.SetStateAction<any>>;
  reset?: boolean;
};

export const SearchAccordion: React.FC<Props> = ({
  inpData,
  countries,
  setChosenInterestList = () => {},
  setChosenRegionsList = () => {},
  setChosenCountry = () => {},
  reset,
}) => {
  const { formatMessage } = useIntl();
  const [expanded, setExpanded] = React.useState<string | false>('');
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const [searchInterestsById, setSearchInterestsById] = useState<Array<number>>(
    [],
  );
  const [searchRegionsById, setSearchRegionsById] = useState<Array<number>>([]);
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  let newInterestsArr: any[] = [];
  let newRegionsArr: any[] = [];

  const changeSearchData = (id: number, checked?: boolean, type?: string) => {
    if (checked && type === 'DEFAULT') {
      newInterestsArr.push(id);
      setSearchInterestsById((prev: Array<number>) => {
        const interestsData = prev.concat(newInterestsArr);
        setChosenInterestList(interestsData);
        return interestsData;
      });
    } else if (!checked && type === 'DEFAULT') {
      setSearchInterestsById((prev: Array<number>) => {
        const interestsData = prev.filter((pr: number) => pr !== id);
        setChosenInterestList(interestsData);
        return interestsData;
      });
    } else if (checked && type === 'REGIONAL') {
      newRegionsArr.push(id);
      setSearchRegionsById((prev: Array<number>) => {
        const regionsData = prev.concat(newRegionsArr);
        setChosenRegionsList(regionsData);
        return regionsData;
      });
    } else if (!checked && type === 'REGIONAL') {
      setSearchRegionsById((prev: Array<number>) => {
        const regionsData = prev.filter((pr: number) => pr !== id);
        setChosenRegionsList(regionsData);
        return regionsData;
      });
      return;
    }
  };

  const countryToSelect = allCountries.map(item => item[0]).sort();
  const [country, selectCountry] = React.useState(null);
  React.useEffect(() => {
    setChosenCountry(country);
  }, [country]);

  return (
    <div className="search-accordion-main">
      <div className="search-accordion-main-rows" key={inpData.id}>
        <Accordion
          expanded={expanded === `${inpData.id}`}
          onChange={handleChange(`${inpData.id}`)}
        >
          <AccordionSummary
            sx={{
              paddingLeft: '12px',

              '& .MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content':
                {
                  flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                },
            }}
          >
            <div
              className="search-accordion-main__icon-container"
              style={{
                right:
                  width > 1100
                    ? lan === 'ar'
                      ? 'none'
                      : '40px'
                    : lan === 'ar'
                    ? 'none'
                    : '30px',
                left:
                  width > 1100
                    ? lan === 'ar'
                      ? '18px'
                      : 'none'
                    : lan === 'ar'
                    ? '30px'
                    : 'none',
              }}
            >
              <div
                className="search-accordion-main__icon"
                style={{
                  transform: `rotateZ(${
                    +expanded === inpData.id ? '180deg' : '0'
                  })`,
                }}
              >
                <RemoveIcon
                  style={{
                    opacity: `${+expanded !== inpData.id ? 0 : 1}`,
                  }}
                />

                <AddIcon
                  style={{
                    opacity: `${+expanded !== inpData.id ? 1 : 0}`,
                  }}
                />
              </div>
            </div>

            <img
              className="search-accordion-main__image"
              src={inpData.img}
              alt="search variant"
              style={{
                paddingLeft: lan === 'ar' ? '0' : '6px',
              }}
            />

            <div className="search-accordion-main__title-container">
              <Typography
                sx={{
                  paddingLeft: '0px',
                  fontWeight: 600,
                  fontFamily:
                    lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                  fontSize: '16px',
                  lineHeight: '25px',
                  color: '#363636',
                  textAlign: lan === 'ar' ? 'right' : 'left',
                  padding:
                    width > 1100
                      ? lan === 'ar'
                        ? '0 8px 0 0'
                        : '0 0 0 8px'
                      : lan === 'ar'
                      ? '0 8px 0 40px'
                      : '0 40px 0 8px',
                }}
              >
                {inpData.title}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              padding: 0,
              fontSize: '16px',
              lineHeight: '130%',
              color: '#363636',
              fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
            }}
          >
            {countries && (
              <div className="search-nav-side__country-select">
                <Select
                  width={280}
                  options={countryToSelect}
                  value={country}
                  placeholder={formatMessage({ id: 'selectCountry' })}
                  onChange={selectCountry}
                  inputName="country"
                />
              </div>
            )}

            <ul>
              {inpData?.details?.map((item: any) => (
                <li
                  className="search-accordion-main__item"
                  key={item.id}
                  style={{
                    backgroundColor: 'transparent',
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                  }}
                >
                  <CheckBoxLabel
                    label={
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                          gap: lan === 'ar' ? '10px' : '0',
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'contain',
                            width: '28px',
                            height: '28px',
                          }}
                          src={item?.avatar || item.url}
                          alt="img"
                        />
                        <div
                          className="search-accordion-main__innerinfo-name"
                          style={{
                            maxWidth: lan === 'ar' ? '150px' : '100%',
                            overflowWrap: 'break-word',
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    }
                    setValue={checked => {
                      changeSearchData(item.id, checked, item.type);
                    }}
                  />
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
