import React, { useEffect, useState } from 'react';
import { AppDispatch } from '../../redux/store';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import { useDispatch } from 'react-redux';
import { skipPostFromReport, removePostFromReport } from '../../redux/slices/postsSlice';
import { useIntl } from 'react-intl';

type Props = {
  setDeleteModal: (arg: number | null) => any,
  id?: number,
  action: 'delete' | 'skip'
};

const ActionModal: React.FC<Props> = ({setDeleteModal, id, action}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const actionFunc = async (id: any) => {
    setDeleteModal(null);
    if (action === 'delete') {
      await dispatch(removePostFromReport({ postId: id }))
    } else {
      await dispatch(skipPostFromReport({ postId: id }))
    }
  }

  return (
    <ModalWrapper width={350}>
      <ModalContainer>
        <ModalHeader
          textHeader={formatMessage({ id: action === 'delete' ? 'deletePostConfirm' : 'skipPostConfirm' })}
          smallCross onClose={() => setDeleteModal(null)}
        />
        <ModalFooter>
          <SecondaryButton
            text={formatMessage({ id: action })}
            onClick={() => actionFunc(id)}
            bigPadding
          />
          <MainButton text={formatMessage({ id: 'cancel' })} onClick={() => setDeleteModal(null)} bigPadding />
        </ModalFooter>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ActionModal;