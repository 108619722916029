import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import SignInForm from './SignInForm/SignInForm';
import AuthLogo from '../AuthLogo/AuthLogo';
import './SignInPage.scss';

const SignInPage: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <AuthContainer step={1} language={true}>
      <div className="authContainerWrapper-signIn">
        <div className='ellipse'></div>
        <div  className='authWrapper'>
          <AuthLogo />
          <div className="authForm">
            <div className="signInFormWrapper">
              <SignInForm />
              {/* <div className="signUpLinkWrapper">
                <span>{formatMessage({ id: 'notAMember' })}</span>{' '}
                <b className="signUpLink">
                  <Link style={{ color: 'inherit' }} to="/sign-up">
                    {formatMessage({ id: 'createAccount' })}
                  </Link>
                </b>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default SignInPage;
