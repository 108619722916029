export const getPagePosts = (state: any) => {
  if (window.location.href.includes('/home')) {
    return state.feedPosts;
  }
  if (window.location.href.includes('/group')) {
    return state.groupPosts;
  }
  if (window.location.href.includes('/profile')) {
    return state.userPosts;
  }
  if (window.location.href.includes('/activity-log')) {
    return state.bookmarkedPosts;
  }
  if (window.location.href.includes('/posts')) {
    return state.reportsPosts;
  }
  if(window.location.href.includes('/post')){
    return state.singlePost;
  }
};