import indignant from '../images/Indignant.svg';
import care from '../images/Care.svg';
import goodMedicine from '../images/Good_Medicine.svg';
import love from '../images/Love.svg';
import letsAct from '../images/Lets_Act.svg';
import solidarity from '../images/Solidarity.svg';

export const reactions = [
  {
    id: 1,
    name: 'Solidarity',
    nameKey: 'solidarityReaction',
    img: solidarity,
    type: 'SOLIDARITY',
  },
  {
    id: 2,
    name: 'Love',
    nameKey: 'loveReaction',
    img: love,
    type: 'LOVE',
  },
  {
    id: 3,
    name: 'Let’s act!',
    nameKey: 'actReaction',
    img: letsAct,
    type: 'LETS_ACT',
  },
  {
    id: 4,
    name: 'Good medicine',
    nameKey: 'goodMedicineReaction',
    img: goodMedicine,
    type: 'GOOD_MEDICINE',
  },
  {
    id: 5,
    name: 'Care',
    nameKey: 'careReaction',
    img: care,
    type: 'CARE',
  },
  {
    id: 6,
    name: 'Indignant',
    nameKey: 'indignantReaction',
    img: indignant,
    type: 'INDIGNANT',
  },
];
