export function showSpiner() {
  if (document) {
    document.querySelector('#spiner')?.classList.add('spiner');
    document.querySelector('#spiner-content')?.classList.add('spiner-content');
  }
}

export function hideSpiner() {
  if (document) {
    document.querySelector('#spiner')?.classList.remove('spiner');
    document.querySelector('#spiner-content')?.classList.remove('spiner-content');
  }
}