import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import { a11yProps } from '../../components/helpers/a11yProps';
import Notifications from '../../components/Notifications/Notifications';
import SavedPosts from '../../components/SavedPosts/SavedPosts';
import { TabPanel } from '../../components/shared/TabPanel/TabPanel';
import useWindowDimensions from '../../hooks/widthHook';
import './ActivityLogPage.scss';

const ActivityLogPage = () => {
  const { formatMessage } = useIntl();
  const [value, setValue] = React.useState(0);
  const { width } = useWindowDimensions();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <ErrorBoundary>
          <div className="ActivityLogPage">
            <div className="my-feed">
              <div className="ActivityLogPage__section ActivityLogPage__section--notofications">
                <div className="ActivityLogPage__links ActivityLogPage__links--activity-log">
                  <Box sx={{ width: '100%' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                    >
                      <Tab
                        label={formatMessage({ id: 'notifications' })}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={formatMessage({ id: 'savedPosts' })}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0} type="notify">
                    <Notifications />
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <SavedPosts />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
          {width < 1100 && (
            <div
              style={{
                height: '90px',
              }}
            ></div>
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default ActivityLogPage;
