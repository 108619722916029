import { API, customAxios } from '../api/api';

const CHAT_API = `${API}/api/chat`

export async function getChatList() {
  return await customAxios.get(`${CHAT_API}/user-chats`)
}

export async function getAllMessages(data: {id: string, offset: number}) {
  const {id, offset} = data;
  return await customAxios.get(`${CHAT_API}/chat-messages?chatId=${id}&offset=${offset}`)
}

export async function deleteChat(id: string) {
  return await customAxios.delete(`${CHAT_API}/delete-chat?chatId=${id}`)
}

export async function getNewMessages() {
  return await customAxios.get(`${CHAT_API}/unread-messages`)
}