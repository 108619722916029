import React, { ReactNode } from 'react';
import './TextButton.scss';

interface Props {
  text: string;
  icon?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export const TextButton = ({ text, icon, onClick, disabled }: Props) => {
  return (
    <button className='textButton' onClick={onClick} disabled={disabled}>
      {icon}
      {text}
    </button>
  )
}
