import React, { ReactNode } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import './Sidebar.scss';
interface Props {
  routes: {
    name: string;
    tab: string;
    element: ReactNode;
  }[],
  mobile?: boolean,
  setSelectedTab: (tab: string) => void;
  setIsSelected?: (tab: boolean) => void;
  setShowModal?: (tab: boolean) => void;
  selectedTab: string;
}

export const Sidebar = ({
  routes,
  setSelectedTab,
  selectedTab,
  setIsSelected,
  setShowModal,
  mobile
}: Props) => {

  const { isAdmin } = useSelector((state: RootState) => state.profile);

  const selectTab = (tab: string) => {
    setSelectedTab(tab);
    setIsSelected && setIsSelected(false);
    (tab === 'deactivate' && setShowModal) && setShowModal(true);
  };



  return (
    <div className='sidebar'>
      {routes.filter(a => isAdmin ? (a.tab !== 'contact us') : a).map((item, index) => (
        <div
          className={`sidebar-item${selectedTab === item.tab ?
            ' sidebar-item--active' : ''}${item.tab === 'deactivate' ?
              ' sidebar-item--mob-deactivate' : ''}`}
          key={index}
          onClick={() => selectTab(item.tab)}
        >
          {item.name}
          {(mobile && item.tab !== 'deactivate') && (
            <div className='sidebar-item__icon'>
              <KeyboardArrowRightIcon />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
