import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../../../redux/store';
import {
  getNextOrPrevEpisodes,
  getShowEpisodes,
  getShows,
  resetShowsData,
} from '../../../../redux/slices/relaxSlice';
import {
  iframeAllows,
  IFrame_API,
  topPodcasts,
} from '../../../../constants/relaxRoom';
import useWindowDimensions from '../../../../hooks/widthHook';
import { Loader } from '../../../../components/Loader';
import ModalContainer from '../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import SecondaryButton from '../../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import SearchInput from '../../../../components/shared/SearchInput/SearchInput';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import WhatshotTwoToneIcon from '@mui/icons-material/WhatshotTwoTone';
import EastIcon from '@mui/icons-material/East';
import './PodcastsModal.scss';

interface PodcastsModalProps {
  setPodcastsModal: Dispatch<SetStateAction<boolean>>;
  trends: number[];
}

function PodcastsModal({ setPodcastsModal, trends }: PodcastsModalProps) {
  const [tabsValue, setTabsValue] = useState<number | null>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedShow, setSelectedShow] = useState<string>('');
  const [selectedShowName, setSelectedShowName] = useState<string>('');
  const [listenPodcast, setListenPodcast] = useState<boolean>(false);
  const { status, shows, showEpisodes, pageWithEpisodesChange } = useSelector(
    (state: RootState) => state.relax,
  );

  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (searchValue.length) {
      dispatch(getShows(searchValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (selectedShow.length) {
      dispatch(getShowEpisodes(selectedShow));
      setSelectedShow('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShow]);

  const onShowsChange = (_: SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };
  return (
    <ModalWrapper higherZIndex>
      <ModalContainer
        styles={{
          backgroundColor: '#FBF5FF',
          borderRadius: '12px',
          padding: '0',
          // width: width < 1200 ? '1040px' : '1140px',
          // width: width < 1200 ? '325px' : '1140px',
          width: width < 1100 ? '325px' : '1140px',
          // marginBottom: '16px',
        }}
      >
        <ModalHeader
          mainColor
          withPadding
          textHeader={formatMessage({ id: 'podcastsNshows' })}
          onClose={() => {
            setSelectedShow('');
            dispatch(resetShowsData());
            setPodcastsModal(false);
          }}
        />
        <div className="PodcastsModal__container">
          <div className="PodcastsModal__topic PodcastsModal__choice">
            <div className="PodcastsModal__title">
              <RocketLaunchTwoToneIcon className="rocket" />
              <p className="PodcastsModal__title-text">
                {formatMessage({ id: 'hereYouWillFind' })}
              </p>
            </div>
            {listenPodcast ? (
              <div className="PodcastsModal__backButton">
                <SecondaryButton
                  smallPadding
                  text={formatMessage({ id: 'chooseAnotherPodcast' })}
                  onClick={() => setListenPodcast(false)}
                />
              </div>
            ) : (
              <div className="PodcastsModal__selectShow">
                <div className="PodcastsModal__actions">
                  {(selectedShowName || selectedShow) && (
                    <SecondaryButton
                      smallPadding
                      text={formatMessage({ id: 'listenChosenOne' })}
                      onClick={() => setListenPodcast(true)}
                    />
                  )}
                  <SearchInput
                    placeholder={formatMessage({ id: 'searchPodcast' })}
                    setSearchValue={setSearchValue}
                    top={width >= 1100 ? '10px' : '6px'}
                  />
                </div>
                {shows?.length && status !== 'loading' ? (
                  <Tabs
                    value={tabsValue}
                    onChange={onShowsChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    sx={{
                      minWidth: width >= 1100 ? '325px' : '632px',
                      maxWidth: width >= 1100 ? '325px' : '712px',
                    }}
                  >
                    {shows?.map((show: PlaylistData, index: number) =>
                      show && show.images && show?.name ? (
                        <Tab
                          key={index}
                          className="ShowView"
                          icon={
                            <img
                              className="ShowView__cover"
                              src={show.images[0].url}
                              alt={show.name}
                            />
                          }
                          label={<p className="ShowView__name">{show.name}</p>}
                          onClick={() => {
                            setSelectedShow(show.id);
                            setSelectedShowName(show.name);
                            setListenPodcast(true);
                          }}
                        />
                      ) : null,
                    )}
                  </Tabs>
                ) : (
                  <div className="emptyShow">
                    {shows?.length && status === 'loading' ? (
                      <Loader
                        withoutPosition
                        style={{
                          position: 'static',
                          width: '5%',
                          height: '44%',
                        }}
                      />
                    ) : (
                      <p className="emptyShow__text">
                        {shows?.length === 0 &&
                        searchValue.length &&
                        status !== 'loading'
                          ? formatMessage({ id: 'noSearchResult' })
                          : formatMessage({ id: 'searchedResult' })}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {showEpisodes && showEpisodes.items?.length && (
              <div className="PodcastsModal__episodes">
                <div className="EpisodesInfo">
                  <SecondaryButton
                    smallPadding
                    iconLeft
                    icon={<EastIcon sx={{ transform: 'rotate(180deg)' }} />}
                    text={''}
                    disabled={showEpisodes.previous === null}
                    onClick={() => {
                      if (typeof showEpisodes.previous === 'string') {
                        dispatch(getNextOrPrevEpisodes(showEpisodes.previous));
                      }
                    }}
                  />
                  <div className="EpisodesInfo__podcastInfo">
                    <p className="EpisodesInfo__podcastName">
                      {selectedShowName}
                    </p>
                    <p className="EpisodesInfo__total">
                      <span>{formatMessage({ id: 'totalEpisodes' })} </span>
                      <span className="count">{showEpisodes.total}</span>
                    </p>
                  </div>
                  <SecondaryButton
                    smallPadding
                    iconRight
                    icon={<EastIcon />}
                    text={''}
                    disabled={showEpisodes.next === null}
                    onClick={() => {
                      if (typeof showEpisodes.next === 'string') {
                        dispatch(getNextOrPrevEpisodes(showEpisodes.next));
                      }
                    }}
                  />
                </div>
                {pageWithEpisodesChange ? (
                  <Loader
                    withoutPosition
                    style={{
                      position: 'relative',
                      top: '80px',
                      minWidth: '670px',
                      maxWidth: '750px',
                      height: '254px',
                    }}
                  />
                ) : (
                  <ul
                    className="PodcastsModal__episodes-list"
                    style={{
                      height: listenPodcast ? '462px' : '254px',
                    }}
                  >
                    {showEpisodes.items?.map((episode: PlaylistData) => {
                      return (
                        <li
                          key={episode.id}
                          className="PodcastsModal__episodes-item"
                        >
                          <iframe
                            className="PodcastsModal__iframe PodcastsModal__iframe-episode"
                            title="user-choice"
                            src={`${IFrame_API}episode/${episode.id}?utm_source=generator`}
                            allow={iframeAllows}
                            loading="lazy"
                          ></iframe>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
          <div className="PodcastsModal__topic PodcastsModal__trends">
            <div className="PodcastsModal__title">
              <WhatshotTwoToneIcon className="fire" />
              <p className="PodcastsModal__title-text">
                {formatMessage({ id: 'trendingPodcasts' })}
              </p>
            </div>
            {trends.map((number: number) => (
              <div className="PodcastsModal__trends-item">
                <iframe
                  key={number}
                  className="PodcastsModal__iframe PodcastsModal__iframe-trends"
                  title="trends"
                  src={`${IFrame_API}show/${topPodcasts[number]}?utm_source=generator`}
                  allow={iframeAllows}
                  loading="lazy"
                ></iframe>
                <SecondaryButton
                  smallPadding
                  text={formatMessage({ id: 'listenToOthers' })}
                  onClick={() => {
                    dispatch(getShowEpisodes(topPodcasts[number]));
                    setSelectedShowName(
                      formatMessage({ id: 'trendingPodcasts' }),
                    );
                    setListenPodcast(true);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default PodcastsModal;
