import React, { FC } from 'react';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import NewPasswordForm from './NewPasswordForm/NewPasswordForm';
import AuthLogo from '../AuthLogo/AuthLogo';
import './NewPasswordPage.scss';

const NewPasswordPage: FC = () => {
  return (
    <>
      <AuthContainer step={1} language={true}>
        <div className="authContainerWrapper-newPassword">
          <AuthLogo />
          <div className="authForm">
            <div className="newPasswordFormWrapper">
              <NewPasswordForm />
            </div>
          </div>
        </div>
      </AuthContainer>
    </>
  );
};

export default NewPasswordPage;
