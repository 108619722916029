import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  archiveInterest,
  resetArchiveInterestStatus,
} from '../../redux/slices/adminSlice';
import { AppDispatch, RootState } from '../../redux/store';
import ErrorBoundary from '../ErrorBoundary';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { AccordionRow } from '../shared/AccordionRow/AccordionRow';
import AddNewInterestModal from '../AddNewInterestModal/AddNewInterestModal';
import callToAction from '../../images/call-to-action.svg';
import advocacy from '../../images/advocacy-events.svg';
import petition from '../../images/petition.svg';
import involved from '../../images/involved.svg';
import poll from '../../images/poll.svg';
import interest from '../../images/Interest.svg';
import regional from '../../images/Regional.svg';
import srategies from '../../images/Srategies.svg';
import voices from '../../images/Voices.svg';
import './NavSide.scss';

interface INewPostType {
  id: number;
  title: string;
  url: string;
}

const newPostTypes: INewPostType[] = [
  {
    id: 1,
    title: "Advocacy Events",
    url: advocacy,
  },
  {
    id: 2,
    title: "Calls to Action",
    url: callToAction,
  },
  {
    id: 3,
    title: "Petitions",
    url: petition,
  },
  {
    id: 4,
    title: "Survey/Polls",
    url: poll,
  },
];

type Props = {
  setShowBurger?: (arg: boolean) => any
}

export const NavSide:React.FC<Props> = ({ setShowBurger }) => {
  const [customPostTypes, setCustomPostTypes] = useState<INewPostType[]>([]);
  const [tagLoader, setTagLoader] = useState<boolean>(false);
  const [archiveTag, setArchiveTag] = useState<TagForArchive>({
    tagId: 0,
    status: false,
  });
  const [editTag, setEditTag] = useState<TagForEdit>({
    tagId: 0,
    status: false,
    type: '',
  });
  const [addNewTag, setAddNewTag] = useState<SelectedInterestType>({
    type: 'DEFAULT',
    status: false,
  });
  const lan = localStorage.getItem('lang') || 'en';

  const {
    groups,
    postTypes,
  } = useSelector((state: RootState) => state.app);
  const {
    archiveInterestStatus,
  } = useSelector((state: RootState) => state.admin);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (postTypes) {
      setCustomPostTypes(postTypes
        .slice(6)
        .filter((postType: any) => !postType.isArchived)
        .map((postType: any) => ({
          id: postType.id,
          title: postType.typeName,
          url: postType.url,
          avatar: postType.avatar
        })));
    }
  }, [postTypes]);

  useEffect(() => {
    if (archiveInterestStatus === 'loading') {
      setTagLoader(true);
    }
    if (archiveInterestStatus === 'success') {
      setTagLoader(false);
      dispatch(resetArchiveInterestStatus());
      setArchiveTag({
        tagId: 0,
        status: false,
      });
    }
    if (archiveInterestStatus === 'rejected') {
      setTagLoader(false);
      dispatch(resetArchiveInterestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveInterestStatus]);

  const interrest = [
    {
      id: 1,
      typeId: 'typeId',
      title: formatMessage({ id: 'getInvolved' }),
      details: [...newPostTypes, ...customPostTypes],
      img: involved,
    },
    {
      id: 2,
      typeId: 'interestId',
      title: formatMessage({ id: 'interestAreas' }),
      details: groups?.interests
        ?.filter((item: any) => item.type === 'DEFAULT' && !item.isArchived)
        ?.sort((a: any, b: any) => a.title.localeCompare(b.title)),
      img: interest,
    },
    {
      id: 3,
      typeId: 'interestId',
      title: formatMessage({ id: 'regionalActivism' }),
      details: groups?.interests
        ?.filter((item: any) => item.type === 'REGIONAL' && !item.isArchived)
        ?.sort((a: any, b: any) => a.title.localeCompare(b.title)),
      img: regional,
    },
  ];

  const groupsItems = [
    {
      id: 1,
      typeId: 'groupId',
      title: formatMessage({ id: 'solutions' }),
      details: groups?.groups?.filter((item: any) =>
        item.type === 'Strategies, Solutions, Networking Solutions')
        ?.sort((a: any, b: any) => a.title.localeCompare(b.title)),
      img: srategies,
    },
    {
      id: 2,
      typeId: 'groupId',
      title: formatMessage({ id: 'voicesAmplified' }),
      details: groups?.groups?.filter((item: any) => item.type === 'Voice Amplified')
        ?.sort((a: any, b: any) => a.title.localeCompare(b.title)),
      img: voices,
    },
  ];

  const onArchivedTag = () => {
    dispatch(archiveInterest(archiveTag.tagId));
  };

  return (
    <ErrorBoundary>
      <nav className="nav-side">
        <section className="nav-side__section">
          <h2
            className="nav-side__title"
            style={{
              textAlign: (lan === 'ar') ? 'right' : 'left'
            }}
          >
            {formatMessage({ id: 'typesOfInterests' })}
          </h2>

          <div className="nav-side__section-info">
            <AccordionRow
              eyeIcon
              expandedDefault
              inpData={interrest}
              titlePadLeft={8}
              titleFSZ="16px"
              titleFW="400"
              titleColor="#363636"
              detailsFSZ="14px"
              detailsColor="#363636"
              deteilsPad="0"
              setEditTag={setEditTag}
              setAddNewTag={setAddNewTag}
              setArchiveTag={setArchiveTag}
              setShowBurger={setShowBurger}
            />
          </div>
        </section>

        <section className="nav-side__section">
          <h2
            className="nav-side__title"
            style={{
              textAlign: (lan === 'ar') ? 'right' : 'left'
            }}
          >
            {formatMessage({ id: 'groups' })}
          </h2>
          <div className="nav-side__section-info">
            <AccordionRow
              eyeIcon
              expandedDefault
              inpData={groupsItems}
              titlePadLeft={8}
              titleFSZ="16px"
              titleFW="400"
              titleColor="#363636"
              detailsFSZ="14px"
              detailsColor="#363636"
              deteilsPad="0"
              setEditTag={setEditTag}
              setShowBurger={setShowBurger}
            />
          </div>
        </section>
      </nav>

      {addNewTag.status &&
        <AddNewInterestModal
          addNewTag={addNewTag}
          setAddNewTag={setAddNewTag}
        />}

      {editTag.status &&
        <AddNewInterestModal
          addNewTag={addNewTag}
          setAddNewTag={setAddNewTag}
          setEditTag={setEditTag}
          selectedTag={
            editTag.type === "interestId" ? { ...groups.interests.find((item: any) => item.id === editTag.tagId), typeId: editTag.type }
              : editTag.type === "typeId" ? { ...postTypes.find((item: any) => item.id === editTag.tagId), typeId: editTag.type }
                : editTag.type === "groupId" ? { ...groups.groups.find((item: any) => item.id === editTag.tagId), typeId: editTag.type }
                  : undefined
          }
        />}

      {archiveTag.status &&
        <ConfirmModal
          withCloseIcon
          contentId="archiveInterest"
          secondaryButtonTextId="archive"
          secondaryButtonAction={() => onArchivedTag()}
          mainButtonAction={() => setArchiveTag({
            tagId: 0,
            status: false,
          })}
          pStyles={{ width: '260px', margin: '0 21px' }}
          loader={tagLoader}
        />}
    </ErrorBoundary>
  )
}
