import React, { useRef, useState } from 'react'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useParams } from 'react-router';

interface Props {
  index: number;
  item: string;
  setVideoIndex: (index: number) => void;
  setViewModal: (viewModal: boolean) => void;
  setDeleteModal: (deleteModal: boolean) => void;
  setIndexToDelete: (index: number) => void;
}

export const VideoItem = ({ index, setVideoIndex, setViewModal, setDeleteModal, item, setIndexToDelete }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { someUserId } = useParams();
  const { id } = useSelector((state: RootState) => state.profile);


  const [duration, setDuration] = useState('0:00');

  const convertToSeconds = (time: number) => {
    const mind = time % (60 * 60);
    const minutes = Math.floor(mind / 60);

    const secd = mind % 60;
    const seconds = Math.ceil(secd);

    setDuration(minutes + ':' + seconds)
  }

  return (
    <div className='photo-tab__grid-item' onClick={() => { setVideoIndex(index); setViewModal(true) }}>

      {someUserId === id && (
        <div className='photo-tab__grid-item-btn' onClick={(e) => { e.stopPropagation(); setDeleteModal(true); setIndexToDelete(index) }}>
          <DeleteTwoToneIcon />
        </div>
      )}


      <div className='photo-tab__grid-item-time'>{duration}</div>
      <video
        className='photo-tab__grid-item-img'
        src={item} ref={videoRef}
        onLoadedData={(e: any) => convertToSeconds(Math.round(e.target.duration))}
        playsInline
      />
    </div>
  )
}
