import React, { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import {
  tippyContentDescriptionKeys,
  tippyImageDescriptionKeys,
} from '../../../constants/notifyText';
import { TextareaAutosize } from '@mui/material';
import TippyComponent from '../TippyComponent/TippyComponent';
import { uploadContentFormats, uploadImageFormats } from '../../../constants';
import { ReactComponent as AddMedia } from '../../../images/add-photo-video-bw.svg';
import addMediaDisabled from '../../../images/flat-color-icons_stack-of-photos.svg';
import addMedia from '../../../images/add-photo-video.svg';
import 'tippy.js/dist/tippy.css';
import './TextArea.scss';

interface Props {
  setValue?: (value: string) => void;
  value?: string;
  maxChar?: number;
  label?: string;
  name?: string;
  register?: UseFormRegister<FieldValues> | any;
  errors?: FieldErrorsImpl<DeepRequired<FieldValues>> | any;
  placeholder?: string;
  type?: string;
  uploadImage?: (files: FileList | null, imgType?: string) => void;
  defaultValue?: string;
  isDisabledBtn?: boolean;
}

const TextArea = ({
  label,
  maxChar,
  setValue = () => { },
  value,
  name = '',
  register = () => { },
  errors,
  placeholder,
  type,
  uploadImage,
  defaultValue,
  isDisabledBtn
}: Props) => {
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';

  const getHight = () => {
    if (type === 'postCreation' || type === 'adsCreation') {
      return '133px';
    } else if (type === 'privacy') {
      return '50vh';
    } else {
      return '';
    }
  };

  return (
    <div
      className={`customTextarea${errors?.[name] ?
        ' customTextarea--errors' : ''}${type === 'postCreation' || 'bookCover' || 'adsCreation' ?
          ' customTextarea-postCreation' : ''}`}
      style={{
        marginBottom: type === 'privacy' ? '24px' : '12px',
      }}
    >
      <div
        className="customTextarea-footer"
        style={{
          flexDirection: (lan === 'ar') ? 'row-reverse' : 'row'
        }}
      >
        {errors?.[name] && (
          <span className="errorMessage">
            {errors?.[name]?.message || 'Error'}
          </span>
        )}
        {maxChar && (
          <div className="customTextarea-max">
            {formatMessage({ id: 'maxCharacters' })} {maxChar}
          </div>
        )}
      </div>

      {type !== 'bookCover' ?
        <textarea
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
          maxLength={maxChar}
          value={value}
          name={name}
          placeholder={placeholder}
          style={{
            height: getHight(),
            paddingBottom: '40px',
            textAlign: (lan === 'ar') ? 'right' : 'left',
          }}
          {...register(name, {
            required: formatMessage({ id: 'fieldIsEmpty' }),
            onChange: (e: any) => setValue(e.target.value)
          })}
          defaultValue={defaultValue}
        /> :
        <TextareaAutosize
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
          value={value}
          name={name}
          minRows={2}
          placeholder={placeholder}
          style={{
            textAlign: (lan === 'ar') ? 'right' : 'left',
            paddingTop: '16px',
            paddingBottom: '50px',
            overflow: 'initial',
            fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
          }}
          {...register(name, {
            required: formatMessage({ id: 'fieldIsEmpty' }),
          })}
          defaultValue={defaultValue}
        />}

      <label style={{
        color: value ? '#363636' : '',
        flexDirection: (lan === 'ar') ? 'row-reverse' : 'row'
      }}>
        {label}
      </label>

      {type === 'postCreation' && uploadImage && (
        <div
          className="customTextarea-buttonContainer"
          style={{
            flexDirection: (lan === 'ar') ? 'row-reverse' : 'row'
          }}
        >
          <TippyComponent
            object={
              <label className={`customTextarea-button customTextarea-button${isDisabledBtn ?
                '--disabled' : '--active'}`}
                style={{
                  flexDirection: (lan === 'ar') ? 'row-reverse' : 'row'
                }}
              >
                <img src={isDisabledBtn ? addMediaDisabled : addMedia} alt="" />
                {formatMessage({ id: 'addAttachment' })}
                <input
                  multiple
                  name="uploadedImg"
                  type={`${!isDisabledBtn && 'file'}`}
                  accept={uploadContentFormats}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    uploadImage(e.target.files);
                    e.target.value = '';
                  }}
                  style={{
                    display: 'none'
                  }}
                />
              </label>
            }
            title={formatMessage({ id: 'addAttachment' })}
            text={
              <ul>
                {tippyContentDescriptionKeys
                  .map((item: string, index: number) => (
                    <li key={index}>{formatMessage({ id: item })}</li>
                  ))}
              </ul>
            }
            placement="top"
            maxWidth={300}
          />
        </div>
      )}

      {type === 'bookCover' && uploadImage && (
        <div className="customTextarea-buttonContainer">
          <TippyComponent
            object={
              <label className="customTextarea-button">
                <AddMedia />
                <span>{formatMessage({ id: 'addBookCover' })}</span>
                <input
                  name="uploadedImg"
                  type="file"
                  accept={uploadImageFormats}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    uploadImage(e.target.files);
                  }}
                  style={{ display: 'none' }}
                />
              </label>
            }
            title={formatMessage({ id: 'addBookCover' })}
            text={
              <ul>
                {tippyImageDescriptionKeys
                  .map((item: string, index: number) => (
                    <li key={index}>{formatMessage({ id: item })}</li>
                  ))}
              </ul>
            }
            placement="top"
            maxWidth={300}
          />
        </div>
      )}

      {type === 'adsCreation' && uploadImage && (
        <div className="customTextarea-buttonContainer">
          <TippyComponent
            object={
              <label className="customTextarea-button">
                <AddMedia />
                <span>{formatMessage({ id: 'addPhoto' })}</span>
                <input
                  name="uploadedImg"
                  type="file"
                  accept={uploadImageFormats}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    uploadImage(e.target.files);
                    e.target.value = '';
                  }}
                  style={{ display: 'none' }}
                />
              </label>
            }
            title={formatMessage({ id: 'addPhoto' })}
            text={
              <ul>
                {tippyImageDescriptionKeys
                  .map((item: string, index: number) => (
                    <li key={index}>{formatMessage({ id: item })}</li>
                  ))}
              </ul>
            }
            placement="top"
            maxWidth={300}
          />
        </div>
      )}
    </div>
  );
};

export default TextArea;
