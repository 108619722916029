import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import CloseIcon from '@mui/icons-material/Close';
import { sendInviteEmail } from '../../api/user';

import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import invite from '../../images/invite.svg';
import mail from '../../images/inviteEmail.svg';
import oops from '../../images/oops.svg';

import './InviteModal.scss';
import EmailTooltip from '../Tooltips/EmailTooltip/EmailTooltip';

const emailSchema = z.string().regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, {
  message: 'Invalid email address',
});

type Props = {
  setOpenInvite: (arg: boolean) => void
}

export default function InviteModal({ setOpenInvite }: Props) {
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';

  const [value, setValue] = useState("");
  const [email, setEmail] = useState("");
  const [sended, setSended] = useState(false);
  const [emailError, setEmailError] = useState<string>("");
  const [isExist, setExist] = useState(false);

  const width = window.innerWidth;

  const handleChange = (e: any) => {
    const inputValue = e.currentTarget.value;
    setValue(inputValue);
    setEmail(inputValue);
    setEmailError("");
  };

  const checkEmail = () => {
    try {
      emailSchema.parse(email);
      setEmailError("");
    } catch (error: any) {
      setEmailError(error.message);
    }
  }

  const sendInvite = async() => {   
    if (!emailError) {
      const res = await sendInviteEmail(email);

      if (res?.data === 'User already exists') {
        setExist(true);
        setSended(true);
      } else {
        setSended(true);
      }
    }
  };

  return (
    <ModalWrapper width={width < 1100 ? 327 : 596}>
      <div className="InviteModal">
        {!sended ? (
          <>
            <div className="InviteModal__close">
              <CloseIcon sx={{}} onClick={() => setOpenInvite(false)} />
            </div>
            <img src={invite} alt="invite" className="InviteModal__invite-img"/>
            <div className="InviteModal__main-info">
              <h2>{formatMessage({ id: 'inviteTitleText' })} <span>WizWork</span></h2>
              <label
                className="InviteModal__label"
                style={{
                  textAlign: lan === 'ar' ? 'right' : 'left'
                }}
              >
                {formatMessage({ id: 'inviteEmailLabel' })}
                {(emailError && width >= 1100) && <EmailTooltip />}
                <input
                  type="text"
                  placeholder="Email"
                  value={value}
                  onChange={handleChange}
                  onBlur={checkEmail}
                  className={`InviteModal__input 
                  ${emailError && "InviteModal__input--error"}
                  ${lan === 'ar' && "InviteModal__input--ar"} 
                  ${(emailError && lan === 'ar') && "InviteModal__input--ar-error"}`
                }
                />
                {emailError && <div 
                  className={lan === 'ar' 
                  ? "InviteModal__error-ar" 
                  : "InviteModal__error errorMessage"}
                >
                  {formatMessage({ id: 'incorrectEmail' })}
                </div>}
              </label>
            </div>
            <MainButton
              text={formatMessage({ id: 'Invite' })}
              onClick={() => sendInvite()}
              disabled={(!email?.length || !!emailError?.length)}
              styles={{
                width: width < 1100 ? "100%" : "fit-content"
              }}
            />
          </>
        ) : (
          isExist
          ? <>
              <img src={oops} alt="mail" className="InviteModal__oops-img"/>
              <h2 className="InviteModal__oops">{formatMessage({ id: 'serverError' }).slice(0, 4)}!</h2>
              <div className="InviteModal__main-info">
                <h2>{formatMessage({ id: 'inviteExist' })} <span>WizWork!</span></h2>
              </div>
              <MainButton
                text={formatMessage({ id: 'Ok' })}
                onClick={() => setOpenInvite(false)}
                styles={{
                  width: width < 1100 ? "100%" : "fit-content"
                }}
              />
            </>
          : <>
              <img src={mail} alt="mail" className="InviteModal__mail-img"/>
              <h2 className="InviteModal__congrats">{formatMessage({ id: 'congrats' })}</h2>
              <div className="InviteModal__main-info">
                <h2>{formatMessage({ id: 'inviteHasSent' })} <span>{email}</span></h2>
              </div>
              <MainButton
                text={formatMessage({ id: 'Ok' })}
                onClick={() => setOpenInvite(false)}
                styles={{
                  width: width < 1100 ? "100%" : "fit-content"
                }}
              />
            </>
        )}
      </div>
    </ModalWrapper>
  )
}
