import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postVote } from '../../redux/slices/postsSlice';
import { AppDispatch, RootState } from '../../redux/store';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import './PostSurveys.scss';
import { useIntl } from 'react-intl';

interface Props {
  post: Post;
  sharedModal?: boolean;
}

export const PostSurveys: React.FC<Props> = ({ post, sharedModal }) => {
  const {formatMessage} = useIntl()
  const { id } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch<AppDispatch>();
  const { userPosts } = useSelector((state: RootState) => state.posts);
  const [surveyValue, setSurveyValue] = useState<number | null>(null);
  const [choosenAnswer, setChoosenAnswer] = useState('');
  const [voted, setVoted] = useState(false);
  const [votes, setVotes] = useState(0);
  const lan = localStorage.getItem('lang') || 'en';

  useEffect(() => {
    let countOfWotes = 0;
    let theChoosenAnswer = '';
    let didMeVote = false;

    post?.answers?.forEach((el: Answer) => {
      countOfWotes += el.answers.length;

      if (el?.answers.some(element => element === id)) {
        didMeVote = true
        theChoosenAnswer = el.title;
      } 
    });

    if(didMeVote){
      setVoted(true);
    } else {
      setVoted(false);
    }

    setChoosenAnswer(theChoosenAnswer);
    setVotes(countOfWotes);
  }, [userPosts, post]);


  return (
    <div className="post__survey">
      <div
        className="post__survey-container"
        style={{
          textAlign: lan === 'ar' ? 'right' : 'left'
        }}
      >
        <h2 className="post__survey-title">{post?.title}</h2>
        <span className="post__survey-subtitle">
          {post.answers ? formatMessage({id: 'results'}) : formatMessage({id: 'selectAnswer'})}
        </span>

        {/* =========================================================================================== */}

        {voted ? (
          <div className="post__vote-chart-container">
            {post?.answers?.length > 0 &&
              post?.answers?.map(element => (
                <div className="post__vote-container" key={element.id}>
                  <div className="post__vote-title">
                    <span
                      style={{
                        fontWeight: `${element.answers?.length === 0 && '400'}`,
                      }}
                    >
                      {`${Math.round((100 / votes) * element.answers?.length)}%`}
                    </span>
                    &ensp; {element.title}
                  </div>
                  <div
                    className="post__vote-line-container"
                    style={{
                      flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                    }}
                  >
                    <div className="post__vote-line-img">
                      <CheckCircleTwoToneIcon
                        color="secondary"
                        style={{
                          display: `${choosenAnswer !== element.title && 'none'
                            }`,
                        }}
                      />
                    </div>

                    <div
                      className="post__vote-line"
                      style={{
                        width: `${Math.round(
                          (100 / votes) * element.answers?.length,
                        )}%`,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="post__survey-radio-container">
            <FormControl>
              <RadioGroup
                id="radioGroupSurvey"
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={surveyValue}
                onChange={e => {
                  setSurveyValue(+e.target.value);
                }}
              >
                {post?.answers?.length > 0 &&
                  post.answers.map((element: Answer) => (
                    <FormControlLabel
                      id={`radioButton${element}`}
                      key={element.id}
                      value={element.id}
                      control={<Radio color="secondary" />}
                      label={element.title}
                      sx={{
                        backgroundColor: `${surveyValue === element.id ? '#FBF5FF' : null}`
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                      }}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {/* =========================================================================================== */}
        
        {/* ======================= use for checking ============================================= */}
        <>
          {/* <div className="post__survey-radio-container">
            <FormControl>
              <RadioGroup
                id="radioGroupSurvey"
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={surveyValue}
                onChange={(e) => {
                  setSurveyValue(+e.target.value);
                }}
              >
                {post.answers.length > 0 && post.answers.map(element => (
                  <FormControlLabel
                    id={`radioButton${element}`}
                    key={element.id}
                    value={element.id}
                    control={<Radio color="secondary" />}
                    label={element.title}
                    sx={{
                      backgroundColor: `${surveyValue === element.id ? '#FBF5FF' : null}`,
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div> */}
        </>
        {/* ========================= use for checking ======================================================= */}
        {/* =========================================================================================== */}
      </div>

      {!sharedModal && (
        <div
          className="post__survey-votes-container"
          style={{
            display: 'flex',
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
          }}
        >
          <MainButton
            text={formatMessage({id: 'vote'})}
            disabled={surveyValue === null}
            onClick={async function () {
              if (surveyValue) {
                // postVoteApi(surveyValue) // from - api/;
                // setAnswerId(surveyValue); // id for postVoteApi
                await dispatch(postVote({ answerId: surveyValue, postId: post.id })); // --- createAsyncThunk;
                setSurveyValue(null); // set choosen id to null
              }
            }}
          />
          <span className="post__survey-votes">{`${votes} ${formatMessage({id: 'voted'})}`}</span>
        </div>
      )}
    </div>
  );
};
