import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  addUserAPI,
  getUserAPI,
  passwordResetAPI,
  getPasswordChangeAPI,
  CheckExistUserAPI,
  addUserEmailAPI,
} from '../../api/user';
import { setToken } from '../../api/api';
import { showLoader, setSignUpStep } from './appSlice';
import { setAccountSettings } from './profileSlice';
import { toast } from 'react-toastify';
import { intl } from '../../localization';
interface AuthState {
  email: string;
  password: string;
  responseMessage: string;
  sendPassword: boolean;
  signUpSuccessModal: boolean;
  signUpMailModal: boolean;
  signUpSettingModal: boolean;
}

let initialState: AuthState = {
  email: '',
  password: '',
  responseMessage: '',
  sendPassword: false,
  signUpSuccessModal: false,
  signUpMailModal: false,
  signUpSettingModal: false,
};

// sign-In
export const getUser = createAsyncThunk(
  'auth/getUser',
  async function (dataToSend: any, { rejectWithValue, dispatch }) {
    const { formatMessage } = intl.intl();
    dispatch(showLoader(true));
    try {
      const user = await getUserAPI(dataToSend);

      if (user.status === 200) {
        if (user?.data.isDeleted) {
          toast.info(formatMessage({ id: 'profileIsDeactivated' }));
        } else {
          dispatch(setAccountSettings(user.data));
          setToken(user.data.token);
          localStorage.setItem('signedUp', 'true');
        }
      }
      dispatch(showLoader(false));
      return user.data;
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.response.data.statusCode);
    }
  },
);

// Sign-Up
export const addNewUser = createAsyncThunk(
  'auth/addNewUser',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const user = await addUserAPI(data);

      if (user.status === 201) {
        dispatch(setAccountSettings(user.data));
        dispatch(showLoader(false));
        dispatch(setSignUpStep(1));
        localStorage.setItem('signedUp', 'true');
      }

      dispatch(showLoader(false));
      dispatch(setSignUpSuccessModal(true));
      return user.data.response;
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.response.data.statusCode);
    }
  },
);

// Sign-Up-Email
export const addNewUserEmail = createAsyncThunk(
  'auth/addNewUserEmail',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const user = await addUserEmailAPI(data);

      if (user.status === 201) {
        dispatch(setAccountSettings(user.data));
        dispatch(showLoader(false));
        dispatch(setSignUpStep(1));
        localStorage.setItem('signedUp', 'true');
      }

      dispatch(showLoader(false));
      dispatch(setSignUpSuccessModal(true));
      dispatch(setSignUpMailModal(true));
      dispatch(setSignUpSettingModal(true));
      return user.data.response;
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.response.data.statusCode);
    }
  },
);

// Check-Exist
export const checkExistUser = createAsyncThunk(
  'auth/check-exist',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const user = await CheckExistUserAPI(data);
      if (user.status === 200) {
        dispatch(setSignUpStep(2));
      }
      dispatch(showLoader(false));
      return user.data.response;
    } catch (error: any) {
      dispatch(showLoader(false));
    }
  },
);

// Password Reset
export const passwordReset = createAsyncThunk(
  'auth/passwordReset',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      const user = await passwordResetAPI(data);

      if (user.status === 200) {
        toast.success(`${user.data}`);
        dispatch(setSendPassword(true));
      }

      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

// Password Change
export const passwordChange = createAsyncThunk(
  'auth/passwordChange',
  async function (data: any, { rejectWithValue, dispatch }) {
    dispatch(showLoader(true));
    try {
      await getPasswordChangeAPI(data);
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
    },
    setSendPassword(state, action) {
      state.sendPassword = action.payload;
    },
    setSignUpSuccessModal(state, action) {
      state.signUpSuccessModal = action.payload;
    },
    setSignUpMailModal(state, action) {
      state.signUpMailModal = action.payload;
    },
    setSignUpSettingModal(state, action) {
      state.signUpSettingModal = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(getUser.fulfilled, (state: AuthState, action) => {
      state.email = action.payload.email;
    });
  },
});

export const {
  setEmail,
  setPassword,
  setResponseMessage,
  setSendPassword,
  setSignUpSuccessModal,
  setSignUpMailModal,
  setSignUpSettingModal,
} = authSlice.actions;

export default authSlice.reducer;
