import React from 'react';
import { useIntl } from 'react-intl';
import useWindowDimensions from '../../../hooks/widthHook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigateToTop } from '../../../components/helpers/useNavigateToTop';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  activeProfile,
  deactiveProfile,
} from '../../../redux/slices/adminSlice';

interface Props {
  foundedUser: {
    id: string;
    profileAvatar: string;
    firstName: string;
    lastName: string;
    organizationName: string;
    about: string;
    country: string;
    region: string;
    showLocation: boolean;
    isDeleted: boolean;
  };
}

const SearchedUser = ({ foundedUser }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigateToTop = useNavigateToTop();

  const { id: profileId } = useSelector((state: RootState) => state.profile);

  const { reportedProfiles } = useSelector((state: RootState) => state.admin);
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <>
      <div
        className="following__wrapper"
        key={foundedUser.id}
        style={{
          flexDirection:
            width < 1100 ? 'column' : lan === 'ar' ? 'row-reverse' : 'row',
          alignItems:
            width < 1100
              ? lan === 'ar'
                ? 'flex-end'
                : 'flex-start'
              : 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
          }}
        >
          <div
            className="followers__following-avatar-container"
            style={{
              margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0',
            }}
          >
            <img
              className="followers__following-avatar"
              src={foundedUser?.profileAvatar}
              alt=""
            />
          </div>

          <div className="following__titleWrapper">
            <div onClick={() => navigateToTop(`/profile/${foundedUser.id}`)}>
              <h2 className="following__title">
                {`${foundedUser?.organizationName}` ||
                  `${foundedUser?.firstName} ${foundedUser?.lastName}`}
              </h2>
            </div>
            {foundedUser?.showLocation && (
              <h4 className="following__subtitle">
                {foundedUser?.region.trim().length > 0 &&
                foundedUser?.region !== ''
                  ? `${foundedUser?.region}, `
                  : ''}
                {foundedUser?.country}
              </h4>
            )}
          </div>
        </div>
        {foundedUser.id !== profileId && (
          <div className="following__btnWrapper">
            {foundedUser?.isDeleted ? (
              <div className="follower__btnCheckWrapper">
                <SecondaryButton
                  text={formatMessage({ id: 'enable' })}
                  type="button"
                  onClick={() => {
                    dispatch(activeProfile(foundedUser?.id));
                  }}
                />
              </div>
            ) : (
              <MainButton
                text={formatMessage({ id: 'disable' })}
                type="button"
                onClick={() => {
                  dispatch(deactiveProfile(foundedUser?.id));
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchedUser;
