import React from 'react';
import icon_check from '../../../../images/icon-check.svg';

type Props = {
  item?: any;
};

const SearchChosenItem: React.FC<Props> = ({ item }) => {
  const lan = localStorage.getItem('lang') || 'en';
  return (
    <li
      className="search-nav-side__section-list-item"
      style={{
        flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
      }}
    >
      <img
        className="search-nav-side__section-checkicon"
        src={icon_check}
        alt="check"
        style={{
          marginLeft: lan === 'ar' ? '13px' : '0'
        }}
      />
      <img
        className="search-nav-side__section-icon"
        src={item?.avatar || item?.url}
        alt="tag"
        style={{
          objectFit: 'contain',
          width: '28px',
          height: '28px',
        }}
      />
      <span className="search-nav-side__section-text">{item?.title}</span>
    </li>
  )
}

export default SearchChosenItem;
