import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material';
import SearchInput from '../shared/SearchInput/SearchInput'
import './MobileSearch.scss'
import WestIcon from '@mui/icons-material/West';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { searchUsers, setShowFilters } from '../../redux/slices/someUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigateToTop } from '../helpers/useNavigateToTop';
import CloseIcon from '@mui/icons-material/Close';


interface Props {
  showMobileSearch: boolean;
  setShowMobileSearch: (el: boolean) => void;
}

export const MobileSearch = ({ showMobileSearch, setShowMobileSearch }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigateToTop = useNavigateToTop();
  const { searchedInterests, searchedCountry, showFilters } = useSelector(
    (state: RootState) => state.someUser,
  );
  const { isAdmin } = useSelector(
    (state: RootState) => state.profile,
  );
  const [searchValue, setSearchValue] = useState('');

const checkFilter = () =>{
  dispatch(setShowFilters(!showFilters));
}

  useEffect(() => {
    if (searchValue.length) {

      dispatch(
        searchUsers({
          part: searchValue,
          interests: searchedInterests,
          country: searchedCountry,
        }),
      );
      if(isAdmin){
        navigateToTop('/search-users-page')
      }else{
      navigateToTop('/search')
    }
      setSearchValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchedInterests, searchedCountry]);

return (
  <div className={`mobile-search ${showMobileSearch && 'mobile-search--visible'}`}>
      <div
      style={{display:"flex", alignItems: "center"}}
      onClick={() => {
        setShowMobileSearch(false);
      }}
      >
        <WestIcon className="mobile-search__back-icon" />
      </div>

      <SearchInput setSearchValue={setSearchValue} />

      <IconButton className="mobile-search__filter-button" onClick={checkFilter}>
        {/* <FilterAltTwoToneIcon  /> */}
        {!showFilters && <FilterAltTwoToneIcon  color="secondary" />}
        {showFilters && <CloseIcon color="secondary" />}
      </IconButton>
    </div>
  )
}
