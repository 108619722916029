import { API, customAxios } from '../api/api';

const GetPhotos_API = `${API}/api/user/photos`;
const GetVideos_API = `${API}/api/user/videos`;
const DeleteAttachment_API = `${API}/api/user/attachment`

export async function getUserPhotos(userId: string, page: number, limit = 20) {
  return await customAxios.get(GetPhotos_API, { params: { userId, page, limit } });
}

export async function getUserVideos(userId: string, page: number, limit = 20) {
  return await customAxios.get(GetVideos_API, { params: { userId, page, limit } });
}

export async function deleteUserAttachment(attachmentId: number) {
  return await customAxios.delete(DeleteAttachment_API, { data: { attachmentId }})
}
