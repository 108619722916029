import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { deleteUserAttachment, getUserVideos } from '../../../api/userMedia';
import { RootState } from '../../../redux/store';
import ModalWrapper from '../../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../../ModalConstructor/ModalFooter/ModalFooter';
import SecondaryButton from '../../shared/Buttons/SecondaryButton/SecondaryButton';
import MainButton from '../../shared/Buttons/MainButton/MainButton';
import MediaViewModal from '../../MediaViewModal/MediaViewModal';
import ShowMoreAds from '../../ShowMoreAds/ShowMoreAds';
import { VideoItem } from './VideoItem/VideoItem';
import './Videos.scss';

export const Videos = () => {
  const { formatMessage } = useIntl();
  const { id } = useSelector((state: RootState) => state.profile);
  const [renderedVideos, setRenderedVideos] = useState<MediaItem[] | []>([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const [videoIndex, setVideoIndex] = useState(0);
  const [indexToDelete, setIndexToDelete] = useState(0);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalVideos, setTotalVideos] = useState(0);

  const { someUserId } = useParams();
  const someUserInfo = useSelector((state: RootState) => state.someUser.someUser);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [renderedVideos]);

  useEffect(() => {
    (async () => {
      const videos = await getUserVideos((someUserId && someUserInfo ?
        someUserId : id), currentPage);
      setTotalVideos(videos.data.meta.totalItems)
      if (videos.data.items.length) {
        setRenderedVideos((prev: MediaItem[]) => prev.concat(...videos.data.items))
      }
    })()
  }, [currentPage])

  const handleScroll = () => {
    const bottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
    if (bottom && renderedVideos.length < totalVideos) {
      setCurrentPage(prev => prev + 1)
    }
  };

  return (
    <div className='photo-tab'>
      <ShowMoreAds type={'profile'} />
      <h2 className="photo-tab__title">
        {formatMessage({ id: 'videos' })}
      </h2>

      {renderedVideos.length ? (
        <>
          <div className='photo-tab__grid'>
            {renderedVideos.map((item, index) => (
              <VideoItem
                key={index}
                item={item.url}
                index={index}
                setVideoIndex={setVideoIndex}
                setViewModal={setViewModal}
                setDeleteModal={setDeleteModal}
                setIndexToDelete={setIndexToDelete}
              />
            ))}
          </div>
        </>
      ) : (
        <div className='photo-tab__block'>{formatMessage({ id: 'noVideo' })}</div>
      )
      }

      {deleteModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              smallCross
              textHeader={formatMessage({ id: 'deleteVideoConfirm' })}
              onClose={() => setDeleteModal(false)}
            />
            <ModalFooter>
              <SecondaryButton
                text={formatMessage({ id: 'delete' })}
                onClick={() => {
                  setDeleteModal(false)
                  setRenderedVideos(prev => {
                    const newArr = [...prev];
                    newArr.splice(indexToDelete, 1)
                    return newArr;
                  });
                  deleteUserAttachment(renderedVideos[indexToDelete].id);
                }}
                bigPadding
              />
              <MainButton
                bigPadding
                text={formatMessage({ id: 'cancel' })}
                onClick={() => setDeleteModal(false)}
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}

      {viewModal && (
        <MediaViewModal
          isVideoView
          mediaArr={renderedVideos}
          setMediaArr={setRenderedVideos}
          mediaIndex={videoIndex}
          setMediaIndex={setVideoIndex}
          onClose={() => { setViewModal(false) }}
        />
      )}
    </div>
  )
}
