import React from 'react';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import { NavSide } from '../../components/NavSide/NavSide';
import { MyFeed } from '../../components/Wall/MyFeed/MyFeed';

const HomePage: React.FC = () => {

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className='main-page'>
          <div className="main-container">
            <ErrorBoundary forHomePage>
              <div className="main-page__inner">
                <NavSide />
                <div className='wall'>
                  <MyFeed type='home' />
                </div>
                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
