import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import useWindowDimensions from '../../hooks/widthHook';
import { RootState } from '../../redux/store';
import SearchedUser from './SearchedUser/SearchedUser';
import SearchNavSide from './SearchNavSide/SearchNavSide';
import './SearchUsersPage.scss';

const SearchUsersPage = () => {
  const { formatMessage } = useIntl();
  const { foundedUsers, showFilters } = useSelector(
    (state: RootState) => state.someUser,
  );
  const { width } = useWindowDimensions();
  const [noFoundedUsers, setNoFoundedUsers] = useState(false);

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className="main-page">
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner search-filters">
                {width < 1100 ? (
                  <div
                    className={
                      showFilters ? 'filters-visible' : 'filters-invisible'
                    }
                  >
                    <SearchNavSide setNoFoundedUsers={setNoFoundedUsers} />
                  </div>
                ) : (
                  <SearchNavSide setNoFoundedUsers={setNoFoundedUsers} />
                )}
                <div className="search-wall">
                  {foundedUsers?.length ? (
                    <div className="followingFrame">
                      {foundedUsers
                        // ?.filter((item: any) => item.id !== profileId)
                        ?.map((foundedUser: any) => (
                          <SearchedUser
                            foundedUser={foundedUser}
                            key={foundedUser.id}
                          />
                        ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: width < 1100 ? 0 : 190,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <h2
                        style={{
                          marginBottom: width < 1100 ? 12 : 0,
                          marginTop: width < 1100 ? 12 : 0,
                        }}
                      >
                        {formatMessage({ id: 'noUserFound' })}
                      </h2>
                      <h3 style={{ marginBottom: width < 1100 ? 12 : 0 }}>
                        {formatMessage({ id: 'makeSure' })}{' '}
                        <br
                          style={{ display: width < 1100 ? 'block' : 'none' }}
                        />{' '}
                        {formatMessage({ id: 'tryDifferent' })}
                      </h3>
                    </div>
                  )}
                </div>
                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SearchUsersPage;
