import { useNavigate } from "react-router-dom";

export const useNavigateToTop = () => {
  const navigate = useNavigate();

  const navigateAndReset = (to: string) => {
    window.scrollTo(0, 0);
    navigate(to, { replace: true });
  };

  return navigateAndReset;
};
