import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../redux/store';
import { reactions } from '../../constants/reactions';
import useWindowDimensions from '../../hooks/widthHook';
import commentIcon from '../../images/comment-post.svg';
import { setLastActivityArray } from '../../redux/slices/postsSlice';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import { processTextInLink } from '../helpers/linksProcessing';
import './Notifications.scss';

const Notifications: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams() || '';
  const skipPosts = searchParams.get('skip') || '0';
  const { width } = useWindowDimensions();
  const { activity } = useSelector(
    (state: RootState) => state.posts,
  );
  const [sortedActivities, setSortedActivities] = useState<any>({});
  const lan = localStorage.getItem('lang') || 'en';
  console.log(lan);

  useEffect(() => {
    searchParams.set('skip', `0`);

    navigate(`?${searchParams.toString()}`, { replace: true });
  }, []);

  useEffect(() => {
    dispatch(
      setLastActivityArray({
        take: '10',
        skip: `${skipPosts}`,
      }),
    );
  }, [skipPosts]);

  // const identifyList: string[] = [
  //   formatMessage({ id: 'title1' }),
  //   formatMessage({ id: 'title2' }),
  //   formatMessage({ id: 'title3' }),
  //   formatMessage({ id: 'title4' }),
  //   formatMessage({ id: 'title5' }),
  //   formatMessage({ id: 'title6' }),
  // ];

  const getDescLength = (item: any) => {
    let aditionalText = '';
    if (item.type) {
      aditionalText = formatMessage({ id: 'reactedYourPost' });
    }
    if (item.postId && item.text) {
      aditionalText = formatMessage({ id: 'commentedYourPost' });
    }
    if (item.commentId && item.text) {
      aditionalText = formatMessage({ id: 'commentedYourComment' });
    }
    if (item.description) {
      aditionalText = formatMessage({ id: 'sharedYourPost' });
    }

    return `${item.firstName} ${item.lastName} ${item.type} ${item.title} ${item.text} ${aditionalText}`;
  };

  const descForReactionsLength = width > 1100 ? 80 : 50;

  const getReactionSrc = (type: string | undefined) => {
    if (type) {
      for (const reaction of reactions) {
        if (reaction.type === type) {
          return reaction.img;
        }
      }
    }
  };

  const getReactionName = (type: string | undefined) => {
    if (type) {
      for (const reaction of reactions) {
        if (reaction.type === type) {
          return formatMessage({ id: reaction.nameKey });
        }
      }
    }
  };

  const dateConvert = (created: string | undefined) => {
    if (created) {
      const date = new Date(created).toString().split(' ')[2];
      const month = new Date(created).toString().split(' ')[1];
      const year = new Date(created).toString().split(' ')[3];

      return `${month} ${date}, ${year}`;
    } else {
      return '';
    }
  };

  const navigateAndReset = (to: string) => {
    navigate(to, { replace: true });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const sortByDate = activity.map(item => item);
    sortByDate.sort((pre: any, next: any) => next.created - pre.created);
    if (activity.length !== 0) {
      let key = dateConvert(sortByDate[0].created);
      let activities: { [x: string]: [] | LastActivity[] } = {
        [key]: [],
      };
      for (const item of sortByDate) {
        if (dateConvert(item.created) === key) {
          if (activities[key].length === 0) {
            activities[key] = [item];
          } else {
            activities[key] = [...activities[key], item];
          }
        } else {
          key = dateConvert(item.created);
          activities = {
            ...activities,
            [key]: [item],
          };
        }
      }
      setSortedActivities(activities);
    }
  }, [activity]);

  const morePosts = () => {
    searchParams.set('skip', `${+skipPosts + 5}`);

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  return (
    <>
      {activity.length === 0 ? (
        <span className="ActivityLogPage__without-activity">
          {formatMessage({ id: 'withoutNotifications' })}
        </span>
      ) : (
        <ul>
          {/* {for (const index in sortedActivities) {}} */}
          {Object.keys(sortedActivities).map(key => (
            <li className="Notifications__activityData" key={key}>
              <span className="Notifications__title">{key}</span>
              <ul>
                {sortedActivities[key].map((item: any) => (
                  <li
                    className="Notifications__items"
                    key={item.id}
                    style={{
                      flexDirection: width < 1100
                        ? 'column'
                        : (lan === 'ar' ? 'row-reverse': 'row'),
                      gap: width < 1100 ? '5px' : '0'
                    }}
                  > 
                    <div
                      style={{
                        width: width < 1100 ? '100%' : 'fit-content',
                        flexDirection: lan === 'ar' ? 'row-reverse': 'row',
                      }}
                    >
                      <img
                        className="Notifications__avatar"
                        src={item.userFrom?.profileAvatar}
                        alt=""
                      />
                      <div
                        className="Notifications__reaction-container"
                        style={{
                          transform: lan === 'ar' ?
                            'translateX(-170%) translateY(70%)' :
                            'translateX(175%) translateY(100%)',
                        }}
                      >
                        <img
                          className="Notifications__reaction"
                          src={
                            item.type ? getReactionSrc(item.type) : commentIcon
                          }
                          alt="comment type"
                        />
                      </div>
                      <div className="Notifications__description">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: width < 1100 ? 'column' : (lan === 'ar' ? 'row-reverse' : 'row'),
                            alignItems: width < 1100 ? (lan === 'ar' ? 'flex-end' : 'flex-start') : 'flex-end',
                            gap: '3px',
                          }}
                        >
                          <span
                            onClick={() =>
                              navigateAndReset(`/profile/${item.userFrom.id}`)
                            }
                            className="Notifications__description--bold Notifications__username"
                          >
                            {item.userFrom?.organizationName === ''
                              ? `${item.userFrom?.firstName} ${item.userFrom.lastName} `
                              : `${item.userFrom?.organizationName} `}
                          </span>
                          <span>
                            {(item.postId || item.post?.id || item.commentId) &&
                              item.text &&
                              !item.title &&
                              !item?.post?.title &&
                              lan === 'ar' &&
                              ':'}
                            {item.type && formatMessage({ id: 'reactedWith' })}
                            {(item.postId || item.post?.id) &&
                              item.text && (item.replyDen !== '1' && !item?.comment?.parent?.id) &&
                              formatMessage({ id: 'commentedYourPost' })}
                            {(item.commentId || item.comment?.id) &&
                              item.text && (item.replyDen == '1' || item?.comment?.parent?.id) &&
                              formatMessage({ id: 'commentedYourComment' })}
                            {(item.postId || item.post?.id || item.commentId) &&
                              item.text &&
                              !item.title &&
                              !item?.post?.title &&
                              lan !== 'ar' &&
                              ':'}
                            <span className="Notifications__description--bold">
                              {' '}
                              {item.type && `'${getReactionName(item.type)}' `}{' '}
                            </span>
                            {item.type && formatMessage({ id: 'toYourPost' })}
                          </span>
                        </div>

                        {item?.type !== null && !item.text.length ?
                          <div>"{item?.post?.description &&
                            item?.post?.description.length > descForReactionsLength ?
                            <>
                              {item?.post?.description.slice(0, descForReactionsLength)}...
                            </> :
                            item?.post?.description}
                            "</div> :
                          null}
                        {width < 1100 && item.text
                          ? getDescLength(item).length < 75
                            ? processTextInLink(item.text)
                            : processTextInLink(
                              ` ${item.text.slice(
                                0,
                                75 -
                                (getDescLength(item).length -
                                  item.text.length),
                              )} `,
                              true,
                            )
                          : processTextInLink(item.text)}
                        <span className="Notifications__description--dots">
                          {width < 1100 &&
                            getDescLength(item).length > 75 &&
                            '...'}
                        </span>
                      </div>
                    </div>
                    <MainButton
                      text={formatMessage({ id: 'showPost' })}
                      onClick={() => navigateAndReset(`/post/${item?.post?.id}`)}
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
      {activity?.length >= +skipPosts + 10 && activity?.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            background: '#F5F5F5',
            paddingTop: '20px',
          }}
          onClick={morePosts}
        >
          <MainButton
            text={formatMessage({ id: 'showMore' })}
            type="submit"
            styles={
              width < 1100
                ? {
                  width: '90%',
                  marginRight: '0',
                  position: 'absolute',
                  bottom: '-40px',
                  left: '24px',
                  background: '#E9E9E9',
                  color: '#791186',
                }
                : {
                  width: '400px',
                  background: '#E9E9E9',
                  color: '#791186',
                }
            }
          />
        </div>
      )}
    </>
  );
};

export default Notifications;
