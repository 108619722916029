import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Message from './Message/Message';
import { useIntl } from 'react-intl';
import { useSocket } from '../../../App';
import { 
  bottomScroll, 
  cleanHasMore, 
  getMessages, 
  getMsgCount, 
  markChatMsgs, 
  removeChat, 
  setChatId, 
  setMenuOpenStatus,
} from '../../../redux/slices/chatSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { getSomeUserInfo } from '../../../redux/slices/someUserSlice';

import MessagesFooter from './MessagesFooter';
import './Messages.scss';

export default function Messages() {
  const [name, setName] = useState("");
  const lan = localStorage.getItem('lang') || 'en';
  const socket = useSocket();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams() || '';
  const chatId = searchParams.get('chatId') || '';
  const bottomContainerRef = useRef<any>(null);
  const messagesContainerRef = useRef<any>(null);
  const topContainerRef = useRef<any>(null);

  const [canScrollBottom, setCanScrollBottom] = useState(true);

  const { messageList, chatsList, hasMore, hasBottomScroll } = useSelector((state: RootState) => state.chat);
  const { id, onlineStatus } = useSelector((state: RootState) => state.profile);
  const { someUser } = useSelector((state: RootState) => state.someUser);

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    const scrollHeight = event.currentTarget.scrollHeight;
    const clientHeight = event.currentTarget.clientHeight;

    if ((scrollHeight - scrollTop - clientHeight) > 445) {
      setCanScrollBottom(false);
    } else {
      setCanScrollBottom(true);
    }

    if (scrollTop === 0 && hasMore && +chatId) {
      dispatch(getMessages({
        id: chatId,
        offset: messageList?.length
      }));
    }
  };

  useEffect(() => {
    if (chatsList) {
      if (chatId) {
        const item = chatsList?.find((item: any) => item?.id === +chatId);

        const someUserId = item?.userIds?.find((user: string) => user !== id);
        
        if (someUserId) {
          dispatch(getSomeUserInfo(someUserId));
        }
  
        setName(item?.name);
      } else {
        setName(chatsList[0]?.name);
      }
    }

    if (socket && chatId) {
      socket.emit('markAsRead', { 
        chatId,
        userId: id,
      });
    }

    dispatch(getMsgCount());
  }, [chatId, chatsList]);

  useEffect(() => {
    dispatch(setChatId(chatId));
    dispatch(markChatMsgs(chatId));
    if (chatsList) {
      if (+chatId) {
        dispatch(getMessages({
          id: chatId,
          offset: 0
        }));

        chatsList?.forEach((item: any) => {
          if (!item?.data && !item?.text && item.id !== +chatId) {
            dispatch(removeChat(item?.id));
          }
        });
      }
    }

    dispatch(cleanHasMore());
  }, [chatId])

  useEffect(() => {
    if (bottomContainerRef.current && messageList.length <= 15 && canScrollBottom) {
      bottomContainerRef.current?.scrollIntoView({ behavior: 'auto' });
    }

    if (topContainerRef.current && messageList.length > 15) {
      topContainerRef.current.scrollIntoView();
    }
  }, [messageList?.length]);

  useEffect(() => {
    if (hasBottomScroll && canScrollBottom) {
      bottomContainerRef.current?.scrollIntoView({ behavior: 'auto' });
      dispatch(bottomScroll(false));
    }
  }, [hasBottomScroll, canScrollBottom]);

  const formatDate = (date: number) => {
    return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
  };

  return (
    <div className="Messages">
      <div className={`Messages__header ${lan === 'ar' && "Messages__header--ar"}`}>
        <div 
          className="Messages__mobile-open-btn"
          onClick={() => dispatch(setMenuOpenStatus(true))}
        >
          <ArrowBackIosIcon sx={{ fontSize: 20 }}/>
        </div>
        <h2 className="Messages__name">
          {name}
        </h2>
        {name && <div 
          className="Messages__status-indicator"
          style={{
            backgroundColor: onlineStatus?.some((a: string) => 
              a === someUser?.user?.id
            )
              ? '#28A745'
              : '#FD2551',
          }}
        />}
      </div>
      <div className="Messages__main-wrapper">
        <div className="Messages__main" ref={messagesContainerRef} onScroll={handleScroll}>
          {!!messageList?.length ? messageList?.map((message: any, i: number) =>  {
              const currentDate = new Date(+message.date);
              const prevMessage = messageList[i - 1];
              const prevDate = prevMessage ? new Date(+prevMessage.date) : null;

              const currentDay = currentDate.getDate();
              const prevDay = prevDate ? prevDate.getDate() : null;

              const shouldRenderDate = prevDay === null || currentDay !== prevDay;

              return (
                <div 
                  key={message?.id} 
                  ref={message?.isFirst ? topContainerRef : null}
                  className='Messages__wrapper'
                >
                  {(shouldRenderDate && i !== 0) ? <div className="Messages__date">
                    <div className="Messages__date-line"/>
                    <p>{formatDate(message?.date)}</p>
                    <div className="Messages__date-line"/>
                  </div> : <div className="Messages__date-line"/>}
                  <Message message={message}/>
                </div>
              )
            }) : (
              <div className="Messages__empty-container">
                <p>{formatMessage({ id: 'emptyChatMsgs' })}</p>
              </div>
            )}
          <div ref={bottomContainerRef}/>
        </div>
        <MessagesFooter/>
      </div>
    </div>
  )
}
