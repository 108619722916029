import React from 'react';
import Header from '../../components/Header/Header';
import { Aside } from '../../components/Aside/Aside';
import { NavSide } from '../../components/NavSide/NavSide';
import ErrorBoundary from '../../components/ErrorBoundary';
import './PostPage.scss';
import Posts from '../../components/Posts/Posts';

const PostPage = () => {

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className='main-page'>
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner">
                <NavSide />

                <div className="wall">
                  <Posts type={'single'} />
                </div>

                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PostPage;


