import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { followGroup, getGroupProfile, getPostsWithGroup } from '../../api/groups';
import { showLoader } from './appSlice';
import { concatGroupsPosts, setGroupPosts, setTotalPosts } from './postsSlice';
import { intl } from '../../localization';

import icon1 from "../../images/advocacy-events.svg"
import icon2 from "../../images/call-to-action.svg"
import icon3 from "../../images/petition.svg"
import icon4 from "../../images/poll.svg"

export const renderGroupPosts = createAsyncThunk(
  'groups/render-posts',
  async function (data: any, { rejectWithValue, dispatch }) {
    try {
      const posts = await getPostsWithGroup(data);
      dispatch(setTotalPosts(posts.data[1]));

      if (data.skip === 0) {
        dispatch(setGroupPosts(posts.data[0]));
      } else {
        dispatch(concatGroupsPosts(posts.data[0]));
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.statusCode);
    }
  },
);

export const getGroupsProfileInfo = createAsyncThunk(
  'groups/get-profile',
  async function (data: any, { rejectWithValue, dispatch }) {
    const {formatMessage} = intl.intl()
    dispatch(showLoader(true));
    try {
      const profile = await getGroupProfile(data);

      if (Object.keys(data)[0] === 'typeId') {
        const postTypeProfile = profile.data.profile[0];

        if (postTypeProfile.id === 1) {
          postTypeProfile.title = formatMessage({id: 'advocacyEvent'})
          postTypeProfile.url = icon1
        } else if (postTypeProfile.id === 2) {
          postTypeProfile.url = icon2
          postTypeProfile.title = formatMessage({id: 'callToAction'})
        } else if (postTypeProfile.id === 3) {
          postTypeProfile.url = icon3
          postTypeProfile.title = formatMessage({id: 'petition'})
        } else if (postTypeProfile.id === 4) {
          postTypeProfile.title = formatMessage({id: 'surveyPoll'})
          postTypeProfile.url = icon4
        } else {
          postTypeProfile.title = profile.data.profile[0].typeName;
        }

        dispatch(setGroupProfile({
          profile: postTypeProfile,
          followersCount: profile.data.countOfFollowers,
          isFollow: profile.data.ifFollow,
          typeName: Object.keys(data)[0]
        }));
      } else {
        dispatch(setGroupProfile({
          profile: profile.data.profile,
          followersCount: profile.data.countOfFollowers,
          isFollow: profile.data.ifFollow,
          typeName: Object.keys(data)[0]
        }));
      }

      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.response.data.statusCode);
    }
  },
);

export const followGroupThunk = createAsyncThunk(
  'groups/follow-group',
  async function (data: any, { rejectWithValue, dispatch, getState }) {
    dispatch(showLoader(true));
    try {
      const res = await followGroup(data);

      if(res.data.id){
        dispatch(setIsFollow(true))
      } else {
        dispatch(setIsFollow(false))
      }

      dispatch(getGroupsProfileInfo(data))
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.response.data.statusCode);
    }
  },
);

interface GroupsState {
  id: number;
  name: string;
  followers: number;
  avatar: string;
  background: string;
  filterIdName: string;
  isArchived: boolean;
  isFollow: boolean;
  typeName: string;
}

const initialState: GroupsState = {
  id: 0,
  name: '',
  followers: 0,
  avatar: '',
  filterIdName: '',
  background: '',
  isArchived: false,
  isFollow: false,
  typeName: '',
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroupProfile(state, action) {
      const { profile, followersCount, isFollow, typeName} = action.payload;
      // console.log(profile)
      state.id = profile.id;
      state.name = profile.title;
      state.avatar = profile.avatar || profile.url;
      state.background = profile.background;
      state.followers = followersCount;
      state.isFollow = isFollow;
      state.typeName = typeName;
    },
    setTypeId(state, action) {
      state.filterIdName = action.payload
    },
    setIsFollow(state, action){
      state.isFollow = action.payload
    }
  },
});

export const { setGroupProfile, setTypeId, setIsFollow } = groupsSlice.actions;

export default groupsSlice.reducer;
