
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllGroups, getAllInterest, getPostTypes, hideLoader, showLoader } from './appSlice';
import {
  activeProfileAPI,
  archiveInterestAPI,
  archivePostTypeAPI,
  createNewInterestAPI,
  createNewPostTypeAPI,
  deactiveProfileAPI,
  getDeactiveProfilesAPI,
  deleteReportedCommentAPI,
  getReportedCommentsAPI,
  getReportedProfilesAPI,
  getReportedReasonsToCommentAPI,
  getReportedUserReasonsAPI,
  postPrivacyPolicy,
  postTermsOfUse,
  skipReportToCommentAPI,
  getPrivacyPolicy,
  getTermsOfUse,
  editInterestAPI,
  getAllFakeAccs,
  deleteFakeAccs
} from '../../api/admin';
import { toggleFoundedUsers } from './someUserSlice';
import { getGroupsProfileInfo } from './groupsSlice';
import { setAnotherUserId } from './profileSlice';

interface AdminState {
  newPostTypeStatus: string;
  archivePostTypeStatus: string;
  newInterestStatus: string;
  archiveInterestStatus: string;
  reportedProfiles: any[] | [];
  deactiveProfiles: any[] | [];
  reportedUserReasons: ReportedUserReason[] | [];
  reportedCommentsInfo: ReportedComment[] | [];
  reportedCommentsReasonsInfo: ReportedCommentReason[] | [];
  privacy: string[];
  terms: string[];
  fakeAccounts: any[];
  onlineStatus: boolean;
}

const initialState: AdminState = {
  newPostTypeStatus: '',
  archivePostTypeStatus: '',
  newInterestStatus: '',
  archiveInterestStatus: '',
  reportedProfiles: [],
  deactiveProfiles: [],
  reportedUserReasons: [],
  reportedCommentsInfo: [],
  reportedCommentsReasonsInfo: [],
  privacy: [],
  terms: [],
  fakeAccounts: [],
  onlineStatus: false,
};

// ========== New Post Type Actions ========== //

export const addNewPostType = createAsyncThunk(
  'admin/addNewPostType',
  async function (
    data: NewPostTypeParams,
    { rejectWithValue, dispatch }) {

    try {
      const response = await createNewPostTypeAPI(data);
      if (!response.data) {
        toast.error('New post type has not been added');
        return;
      }
      toast.success('New post type successfully added');
      dispatch(getPostTypes());
      return response?.data;
    } catch (error: any) {
      toast.error('New post type has not been added');
      rejectWithValue(error);
    }
  }
);

export const archivePostType = createAsyncThunk(
  'admin/archivePostType',
  async function (
    postTypeId: number,
    { rejectWithValue, dispatch }) {

    try {
      const response = await archivePostTypeAPI(postTypeId);
      if (!response.data) {
        toast.error('Post type failed to archive')
        return;
      }
      toast.success('Post type archived successfully');
      dispatch(getPostTypes());
      return response?.data;
    } catch (error: any) {
      toast.error('Post type failed to archive');
      rejectWithValue(error);
    }
  }
);

// ======= Reported On & Disable Request ======== //

export const getReportedProfiles = createAsyncThunk(
  'admin/getReportedProfiles',
  async function (skip: number, { dispatch, rejectWithValue }) {
    try {
      const profiles = await getReportedProfilesAPI(skip);

      const newDate = {
        skip,
        data: profiles?.data[0]
      };
      dispatch(setReportedProfiles(newDate));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDeactiveProfiles = createAsyncThunk(
  'admin/getDeactiveProfiles',
  async function (skip: number, { dispatch, rejectWithValue }) {
    try {
      const profiles = await getDeactiveProfilesAPI(skip);

      const newData = {
        skip,
        data: profiles?.data[0]
      }
      dispatch(setDeactiveProfiles(newData));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getReportedComments = createAsyncThunk(
  'admin/getReportedComments',
  async function (skip: number, { dispatch, rejectWithValue }) {
    dispatch(showLoader(true));
    try {
      const comments = await getReportedCommentsAPI(skip);

      const newData = {
        skip,
        response: comments.data
      }
      dispatch(setReportedCommentsInfo(newData));
      dispatch(showLoader(false));
    } catch (error: any) {
      dispatch(showLoader(false));
      return rejectWithValue(error.message);
    }
  }
);

export const getReportedReasonsToComment = createAsyncThunk(
  'admin/getReportedReasonsToComment',
  async function (commentId: number, { dispatch, rejectWithValue }) {
    try {
      const reasons = await getReportedReasonsToCommentAPI(commentId);
      dispatch(setReportedCommentsReasonsInfo(reasons.data));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const skipReportToComment = createAsyncThunk(
  'admin/skipReportToComment',
  async function (commentId: any, { dispatch, rejectWithValue }) {
    try {
      const response = await skipReportToCommentAPI(commentId);
      if (response.status === 200) {
        dispatch(setSkipReportToComment(commentId));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteReportedComment = createAsyncThunk(
  'admin/deleteReportedComment',
  async function (commentId: any, { dispatch, rejectWithValue }) {
    try {
      const response = await deleteReportedCommentAPI(commentId);
      if (response.status === 200) {
        dispatch(setDeleteReportedComment(commentId));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const deactiveProfileOnCommentPage = createAsyncThunk(
  'admin/deactiveProfileOnCommentPage',
  async function (userId: string, { dispatch, rejectWithValue }) {
    try {

      const response = await deactiveProfileAPI(userId);
      if (response.status === 201) {
        dispatch(setDeactiveOrActivateProfileOnCommentsPage(userId));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const activeProfileOnCommentPage = createAsyncThunk(
  'admin/activeProfileOnCommentPage',
  async function (userId: string, { dispatch, rejectWithValue }) {
    try {
      const response = await activeProfileAPI(userId);
      if (response.status === 201) {
        dispatch(setDeactiveOrActivateProfileOnCommentsPage(userId));
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


export const getReportedUserReasons = createAsyncThunk(
  'admin/getReportedUserReasons',
  async function (userId: string, { dispatch, rejectWithValue }) {
    try {
      const resons = await getReportedUserReasonsAPI(userId);
      dispatch(setReportedUserReasons(resons.data));
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deactiveProfile = createAsyncThunk(
  'admin/deactiveProfile',
  async function (userId: string, { dispatch, rejectWithValue }) {
    try {
      const response = await deactiveProfileAPI(userId);
      if (response.status === 201) {
        dispatch(toggleDeactivatedUsers(userId))
        dispatch(toggleFoundedUsers(userId))
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const activeProfile = createAsyncThunk(
  'admin/activeProfile',
  async function (userId: string, { dispatch, rejectWithValue }) {
    try {
      const response = await activeProfileAPI(userId);
      if (response.status === 201) {
        dispatch(toggleDeactivatedUsers(userId))
        dispatch(toggleFoundedUsers(userId))
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// ========== New Interests Actions ========== //

export const addNewInterest = createAsyncThunk(
  'admin/addNewInterest',
  async function (
    data: NewInterestParams,
    { rejectWithValue, dispatch }) {

    try {
      const response = await createNewInterestAPI(data);
      if (!response.data) {
        toast.error('New interest area has not been added');
        return;
      }
      toast.success('New interest area successfully added');
      dispatch(getAllGroups());
      dispatch(getAllInterest());
      return response.data;
    } catch (error: any) {
      toast.error('New interest area has not been added');
      rejectWithValue(error);
    }
  }
);

export const archiveInterest = createAsyncThunk(
  'admin/archiveInterest',
  async function (
    interestId: number,
    { rejectWithValue, dispatch }) {

    try {
      const response = await archiveInterestAPI(interestId);
      if (!response.data) {
        toast.error('Interest failed to archive')
        return;
      }
      toast.success('Interest archived successfully');
      dispatch(getAllGroups());
      dispatch(getAllInterest());
      return response.data;
    } catch (error: any) {
      toast.error('Interest failed to archive');
      rejectWithValue(error);
    }
  }
);

export const editTag = createAsyncThunk(
  'admin/editInterest',
  async function (
    data: any,
    { rejectWithValue, dispatch }) {

    const typeId = Object.keys(data)[0]
    const id = Object.values(data)[0]

    try {
      const response = await editInterestAPI(data);
      if (!response.data) {
        toast.error('Interest failed to archive')
        return;
      }
      toast.success('Interest area edited successfully');
      dispatch(getAllGroups());
      dispatch(getAllInterest());
      dispatch(getGroupsProfileInfo({ [typeId]: id }));
      dispatch(getPostTypes());
      return response.data;
    } catch (error: any) {
      toast.error('Interest area failed to edit');
      rejectWithValue(error);
    }
  }
);

// ========== FAQ ========== //

export const getPrivPolicy = createAsyncThunk(
  'admin/getPrivPolicy',
  async function (
    _,
    { dispatch, rejectWithValue }) {
    try {
      const { data } = await getPrivacyPolicy();
      dispatch(getPrivacy(data?.text));
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const getTermsOU = createAsyncThunk(
  'admin/getTermsOU',
  async function (
    _,
    { dispatch, rejectWithValue }) {
    try {
      const { data } = await getTermsOfUse();
      dispatch(getTerms(data?.text));

    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const addPrivacyPolicy = createAsyncThunk(
  'admin/addPrivacyPolicy',
  async function (
    data: any,
    { dispatch, rejectWithValue }) {


    try {
      await postPrivacyPolicy(data);
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const addTermsOfUse = createAsyncThunk(
  'admin/addTermsOfUse',
  async function (
    data: any,
    { dispatch, rejectWithValue }) {

    try {
      await postTermsOfUse(data);
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

// ========== fake accounts ========== //

export const getAllFakes = createAsyncThunk(
  'admin/getAllFakes',
  async function (_,
    { dispatch, rejectWithValue }) {

    try {
      const res = await getAllFakeAccs();

      return res.data;
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const deleteFake = createAsyncThunk(
  'admin/deleteFake',
  async function (id: string,
    { dispatch, rejectWithValue }) {

    try {
      await deleteFakeAccs({ id });

      dispatch(setAnotherUserId({}))

      return id;
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetNewPostTypeStatus(state: AdminState) {
      state.newPostTypeStatus = '';
    },
    resetArchivePostTypeStatus(state: AdminState) {
      state.archivePostTypeStatus = '';
    },
    resetNewInterestStatus(state: AdminState) {
      state.newInterestStatus = '';
    },
    resetArchiveInterestStatus(state: AdminState) {
      state.archiveInterestStatus = '';
    },
    setReportedProfiles(state: AdminState, action) {
      const { data, skip } = action.payload;

      if (skip === 0) {
        state.reportedProfiles = data;
      } else {
        state.reportedProfiles = [...state.reportedProfiles, ...data];
      }
    },
    setDeactiveProfiles(state: AdminState, action) {
      const { skip, data } = action.payload;

      if (skip === 0) {
        state.deactiveProfiles = data;
      } else {
        state.deactiveProfiles = [...state.deactiveProfiles, ...data];
      }
    },
    setReportedCommentsInfo(state: AdminState, action) {
      const { skip, response } = action.payload;
      if (skip === 0) {
        state.reportedCommentsInfo = response;
      } else {
        state.reportedCommentsInfo = [...state.reportedCommentsInfo, ...response];
      }
    },
    setReportedCommentsReasonsInfo(state: AdminState, action) {
      state.reportedCommentsReasonsInfo = action.payload;
    },
    setSkipReportToComment(state: AdminState, action) {
      const Arr = JSON.parse(JSON.stringify(state.reportedCommentsInfo));
      state.reportedCommentsInfo = Arr.filter((comment: ReportedComment) => comment.comment.id !== action.payload.commentId);
    },
    setDeleteReportedComment(state: AdminState, action) {
      const Arr = JSON.parse(JSON.stringify(state.reportedCommentsInfo));
      state.reportedCommentsInfo = Arr.filter((comment: ReportedComment) => comment.comment.id !== action.payload.commentId);
    },
    setDeactiveOrActivateProfileOnCommentsPage(state: AdminState, action) {
      for (const index in state.reportedCommentsInfo) {
        const profile = state.reportedCommentsInfo[index].reportedUser;

        if (profile.id === action.payload) {
          state.reportedCommentsInfo[index].reportedUser = {
            ...state.reportedCommentsInfo[index].reportedUser,
            isDeleted: !profile.isDeleted,
          }
          break;
        }
      }
    },
    setReportedUserReasons(state: AdminState, action) {
      state.reportedUserReasons = action.payload;
    },
    getPrivacy(state: AdminState, action) {
      state.privacy = action.payload;
    },
    getTerms(state: AdminState, action) {
      state.terms = action.payload;
    },
    setDeactiveProfile(state: AdminState, action) {
      state.deactiveProfiles = state.deactiveProfiles.filter(profile => (
        profile.id !== action.payload
      ));

      for (const index in state.reportedProfiles) {
        const profile = state.reportedProfiles[index];

        if (profile.id === action.payload) {
          state.reportedProfiles[index] = {
            ...state.reportedProfiles[index],
            isDeleted: !profile.isDeleted,
          }
          break;
        }
      }
    },
    toggleDeactivatedUsers(state, action) {
      state.reportedProfiles?.forEach((item: any, index: number) => {
        if (item.reportedUser.id === action.payload) {
          state.reportedProfiles[index].reportedUser.isDeleted = !state.reportedProfiles[index].reportedUser.isDeleted
        }
      })

      state.deactiveProfiles?.forEach((item: any, index: number) => {
        if (item.user.id === action.payload) {
          state.deactiveProfiles[index].user.isDeleted = !state.deactiveProfiles[index].user.isDeleted
        }
      })
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<AdminState>) => {
    builder
      .addCase(addNewPostType.pending, (state: AdminState) => {
        state.newPostTypeStatus = 'loading';
      })
      .addCase(addNewInterest.pending, (state: AdminState) => {
        state.newInterestStatus = 'loading';
      })
      .addCase(archivePostType.pending, (state: AdminState) => {
        state.archivePostTypeStatus = 'loading';
      })
      .addCase(archiveInterest.pending, (state: AdminState) => {
        state.archiveInterestStatus = 'loading';
      })
      .addCase(addNewPostType.fulfilled, (state: AdminState) => {
        state.newPostTypeStatus = 'success';
      })
      .addCase(getAllFakes.fulfilled, (state: AdminState, action: any) => {
        state.fakeAccounts = action.payload;
      })
      .addCase(deleteFake.fulfilled, (state: AdminState, action: any) => {
        const array = state.fakeAccounts.filter((a: any) => a.id !== action.payload);
        state.fakeAccounts = array;
      })
      .addCase(addNewInterest.fulfilled, (state: AdminState) => {
        state.newInterestStatus = 'success';
      })
      .addCase(archivePostType.fulfilled, (state: AdminState) => {
        state.archivePostTypeStatus = 'success';
      })
      .addCase(archiveInterest.fulfilled, (state: AdminState) => {
        state.archiveInterestStatus = 'success';
      })

      .addCase(addNewPostType.rejected, (state: AdminState) => {
        state.newPostTypeStatus = 'rejected';
      })
      .addCase(addNewInterest.rejected, (state: AdminState) => {
        state.newInterestStatus = 'rejected';
      })
      .addCase(archivePostType.rejected, (state: AdminState) => {
        state.archivePostTypeStatus = 'rejected';
      })
      .addCase(archiveInterest.rejected, (state: AdminState) => {
        state.archiveInterestStatus = 'rejected';
      });
  },
})

export const {
  resetNewPostTypeStatus,
  resetNewInterestStatus,
  resetArchiveInterestStatus,
  resetArchivePostTypeStatus,
  setReportedProfiles,
  setReportedCommentsInfo,
  setReportedCommentsReasonsInfo,
  setSkipReportToComment,
  setDeleteReportedComment,
  setDeactiveOrActivateProfileOnCommentsPage,
  setReportedUserReasons,
  setDeactiveProfile,
  setDeactiveProfiles,
  getPrivacy,
  getTerms,
  toggleDeactivatedUsers,
} = adminSlice.actions;

export default adminSlice.reducer;
