import { MenuItem, Select } from '@mui/material';
import React, { ReactNode } from 'react';
import './SelectWithIcons.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type optionProps = {
  label: string,
  icon: ReactNode,
  code: string,
};

interface selectProps {
  options: optionProps[],
  setValue?: (option: string) => void;
  value?: string;
  width?: number;
}

export const SelectWithIcons = ({ options, setValue = () => { }, value, width = 200 }: selectProps) => {
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <div className='selectWithIcons' style={{ width: width }}>
      <Select
        MenuProps={{
          elevation: 0, PopoverClasses: { paper: 'MuiPaper-selectWithInputs' }
        }}
        labelId="select-with-icons"
        id="select-with-icons"
        value={value}
        defaultValue={options[0].label}
        IconComponent={ExpandMoreIcon}
        onChange={(e) => { setValue(e.target.value) }}
      >
        {options?.map((item, index) => (
          <MenuItem value={item.label} key={index}>
            <div
              className='option'
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
              }}
            >
              {item.icon}
              {item.label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};