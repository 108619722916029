import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {useIntl} from 'react-intl';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getSpotifyToken } from '../../../redux/slices/relaxSlice';
import { topPlaylist } from '../../../constants/relaxRoom';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { getRandomInts } from '../../../components/helpers/getRandomInts';
import ModalWrapper from '../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../components/ModalConstructor/ModalHeader/ModalHeader';
import PodcastsModal from './PodcastsModal/PodcastsModal';
import MusicModal from './MusicModal/MusicModal';
import podcast from '../../../images/podcast.png';
import music from '../../../images/music.png';
import './SpotifyModal.scss';

interface SpotifyModalProps {
  setSpotifyModal: Dispatch<SetStateAction<boolean>>;
}

function SpotifyModal({ setSpotifyModal }: SpotifyModalProps) {
  const {formatMessage} = useIntl();
  const [musicModal, setMusicModal] = useState<boolean>(false);
  const [podcastsModal, setPodcastsModal] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getSpotifyToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const randomPlaylist = useMemo(() => topPlaylist[randomNumber], []);
  const randomNumbersForPodcasts = useMemo(() => getRandomInts(4, 19), []);

  return (
    <ModalWrapper higher>
      <ErrorBoundary forRelaxPage>
        {(musicModal || podcastsModal) ?
          <>
            {musicModal &&
              <MusicModal
                setMusicModal={setMusicModal}
                randomPlaylist={randomPlaylist}
              />}
            {podcastsModal &&
              <PodcastsModal
                setPodcastsModal={setPodcastsModal}
                trends={randomNumbersForPodcasts}
              />}
          </> :
          <ModalContainer
            styles={{
              backgroundColor: '#FBF5FF',
              borderRadius: '12px',
              padding: '0',
            }}>
            <ModalHeader
              mainColor
              withPadding
              textHeader={formatMessage({id: 'clickToListen'})}
              onClose={() => setSpotifyModal(false)}
            />
            <div className="SpotifyModal__container">
              <button
                className="SpotifyModal__button"
                type="button"
                onClick={() => setPodcastsModal(true)}
              >
                <img
                  className="SpotifyModal__button-image"
                  src={podcast}
                  alt="Podcast"
                  width={100}
                  height={100}
                />
                <span className="SpotifyModal__button-text">
                  {formatMessage({id: 'podcast'})}
                </span>
              </button>
              <button
                className="SpotifyModal__button"
                type="button"
                onClick={() => setMusicModal(true)}
              >
                <img
                  className="SpotifyModal__button-image"
                  src={music}
                  alt="Podcast"
                  width={100}
                  height={100}
                />
                <span className="SpotifyModal__button-text">
                  {formatMessage({id: 'music'})}
                </span>
              </button>
            </div>
          </ModalContainer>}
      </ErrorBoundary>
    </ModalWrapper>
  );
}

const randomNumber = Math.floor(Math.random() * 6);

export default SpotifyModal;
