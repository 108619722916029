import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import useWindowDimensions from '../../hooks/widthHook';
import { languages } from '../../constants/language';
import { onChangeLanguage } from '../../redux/slices/profileSlice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './LanguageSelector.scss';

const LanguageSelector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useWindowDimensions();
  const { language: lang } = useSelector((state: RootState) => state.profile);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    {
      id: 1,
      code: 'en',
      label: 'Select language',
      fullName: 'Select language',
      shortName: 'Select language',
      icon: <></>,
    },
  );

  const onChangeCurrentLanguage = (selectedLang: Language) => {
    setCurrentLanguage(selectedLang);
    dispatch(onChangeLanguage(selectedLang.code));
  };

  return (
    <div className="container" id="languageContainer">
      <div className="currentLanguageWrapper">
        {currentLanguage.fullName === 'Select language' ? (
          <div className="currentLanguageName">{currentLanguage.fullName}</div>
        ) : (
          <>
            <div className="currentLanguageIcon">{currentLanguage.icon}</div>
            {width >= 1100 && (
              <div className="currentLanguageName">{currentLanguage.fullName}</div>
            )}
          </>
        )}

        <ArrowDropDownIcon className="arrowDown" />
      </div>
      {width < 1100 && (
        <div className="optionsContainer">
          {languages.map((lang: Language) => (
            <button
              key={lang.id}
              type="button"
              className="optionWrapper"
              onClick={() => onChangeCurrentLanguage(lang)}
            >
              <div className="optionIcon">{lang.icon}</div>
              <p
                className={`optionText${
                  lang.id === currentLanguage.id ? ' selected' : ''
                }`}
              >
                {lang.shortName}
              </p>
            </button>
          ))}
        </div>
      )}
      {width >= 1100 && (
        <div className="optionsContainer">
          {languages.map((language: Language) => (
            <button
              key={language.id}
              className="optionWrapper"
              onClick={() => onChangeCurrentLanguage(language)}
            >
              <div className="optionIcon">{language.icon}</div>
              <p
                className={`optionText${
                  language.id === currentLanguage.id ? ' selected' : ''
                }`}
              >
                {language.fullName}
              </p>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
