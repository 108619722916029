import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { AppDispatch, RootState } from '../../redux/store';
import postDateConvert from '../helpers/postDateConvert';
import { useNavigateToTop } from '../helpers/useNavigateToTop';
import Comments from '../Comments/Comments';
import { PostText } from '../PostText/PostText';
import ActionModal from '../ActionModal/ActionModal';
import PostCreation from '../PostCreation/PostCreation';
import { PostSurveys } from '../PostSurveys/PostSurveys';
import { PetitionContact } from '../PetitionContact/PetitionContact';
import InterestArea from '../shared/Buttons/InterestArea/InterestArea';
import { SetReactions } from '../shared/SetReactions/SetReactions';
import { MoreButton } from '../shared/Buttons/MoreButton/MoreButton';
import { Bookmark } from '../shared/Buttons/Bookmark/Bookmark';
import { processTextInLink } from '../helpers/linksProcessing';
import useWindowDimensions from '../../hooks/widthHook';
import {
  setChoosenTags, setSelectedInterests, setSinglePost, setEditedPost
} from '../../redux/slices/postsSlice';
import {
  CommentReportReasons,
} from '../../pages/ReportedCommentsPage/CommentReportReasons/CommentReportReasons';
import { getPostApi } from '../../api/posts';
import advocacy from '../../images/advocacy-events.svg';
import callToAction from '../../images/call-to-action.svg';
import petition from '../../images/petition.svg';
import poll from '../../images/poll.svg';
import './Posts.scss';
import DropdownAccounts from '../DropdownAccounts/DropdownAccounts';

type Props = {
  type: 'home' | 'profile' | 'saved' | 'groups' | 'report' | 'single';
};

export const Posts: React.FC<Props> = ({ type }: Props) => {
  const [deleteModal, setDeleteModal] = useState<number | null>(null);
  const [skipModal, setSkipModal] = useState<number | null>(null);
  const [posts, setPosts] = useState<Post[] | BookmarkedPost[]>([]);
  const { id, isAdmin, profileAvatar, onlineStatus } = useSelector((state: RootState) => state.profile);
  const [commentReportReasonsModal, setCommentReportReasonsModal] = useState<boolean | null>(null);
  const [reportId, setReportId] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();
  const lan = localStorage.getItem('lang') || 'en';

  const navigate = useNavigate();
  const [searchParams] = useSearchParams() || '';
  const setPostEdit = searchParams.get('edit-post') || '';
  const {
    userPosts,
    feedPosts,
    bookmarkedPosts,
    groupPosts,
    reportsPosts,
    postReportReasons,
    singlePost,
    choosenTags,
    selectedInterests,
  } = useSelector((state: RootState) => state.posts);

  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const { someUserId, somePostId } = useParams();
  const navigateToTop = useNavigateToTop();

  useEffect(() => {
    if (type === 'single') {
      if (somePostId) {
        (async () => {
          const post = await getPostApi(somePostId);

          if (post?.statusCode !== 400) {
            dispatch(setSinglePost([post]));
          }

        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    switch (type) {
      case 'home':
        setPosts(feedPosts);
        break;
      case 'profile':
        setPosts(userPosts);
        break;
      case 'saved':
        setPosts(bookmarkedPosts);
        break;
      case 'groups':
        setPosts(groupPosts);
        break;
      case 'report':
        const onlyPosts = [...reportsPosts]?.map(report => {
          const newObj = {
            ...report.post,
            reportId: report.id,
            counter: report.counter,
            reason: report.reason
          };

          return newObj;
        });

        setPosts(onlyPosts);
        break;
      case 'single':
        setPosts(singlePost);

        break;
      default:
        setPosts(userPosts);
        return;
    }
  }, [
    feedPosts,
    userPosts,
    bookmarkedPosts,
    type,
    someUserId,
    somePostId,
    groupPosts,
    reportsPosts,
    dispatch,
    postReportReasons,
    singlePost
  ]);

  const closePostCreation = (typeOfAction: string) => {
    searchParams.delete(`${typeOfAction}-post`);
    navigate(`?${searchParams.toString()}`, { replace: true });
    dispatch(setEditedPost(null));
  };

  return (
    <>
      {somePostId ?
        '' :
        <>
          {(type !== 'saved' && type !== 'report') &&
            <h2 className="my-feed__posts-title">
              {formatMessage({ id: 'posts' })}
            </h2>}
        </>}

      {posts ? (
        posts.map((post: Post | BookmarkedPost) => (
          <div className="post" key={post?.id}>
            {/* ===================== post header ========================== */}
            {(post.types?.typeName !== 'SURVEY/POLLS'
              && post.types?.typeName !== 'CALLS_TO_ACTION'
              && post.types?.typeName !== 'PETITIONS'
              && post.types?.typeName !== 'EVENTS'
              && post.types?.typeName !== 'DEFAULT'
              && post.type === 'DEFAULT') ||
              (post.originalPost
                && post.type === 'SHARED'
                && (post?.originalPost?.types?.typeName !== 'DEFAULT'
                  && post?.types?.typeName !== 'SURVEY/POLLS'
                  && post?.types?.typeName !== 'CALLS_TO_ACTION'
                  && post?.types?.typeName !== 'PETITIONS'
                  && post.types?.typeName !== 'EVENTS')) ?
              <div className="post__type">
                <h2 className="post__type-title">
                  {post.types?.typeName}
                </h2>
              </div> : null}

            {(post.types?.typeName === 'SURVEY/POLLS'
              && post.type === 'DEFAULT') ||
              (post.originalPost
                && post.originalPost?.types?.typeName === 'SURVEY/POLLS'
                && post.type === 'SHARED') ?
              <div className="post__type">
                <img className="post__type-img" src={poll} alt="" />
                <h2 className="post__type-title">
                  {formatMessage({ id: 'surveyPoll' }).toUpperCase()}
                </h2>
              </div> : null}

            {(post.types?.typeName === 'CALLS_TO_ACTION'
              && post.type === 'DEFAULT') ||
              (post.originalPost
                && post?.originalPost.types?.typeName === 'CALLS_TO_ACTION'
                && post.type === 'SHARED') ?
              <div className="post__type">
                <img className="post__type-img" src={callToAction} alt="" />
                <h2 className="post__type-title">
                  {formatMessage({ id: 'callToAction' }).toUpperCase()}
                </h2>
              </div> : null}

            {(post.types?.typeName === 'EVENTS'
              && post.type === 'DEFAULT') ||
              (post.originalPost
                && post.originalPost.types?.typeName === 'EVENTS'
                && post.type === 'SHARED') ?
              <div className="post__type">
                <img className="post__type-img" src={advocacy} alt="" />
                <h2 className="post__type-title">
                  {formatMessage({ id: 'advocacyEvent' }).toUpperCase()}
                </h2>
              </div> : null}

            {(post.types?.typeName === 'PETITIONS'
              && post.type === 'DEFAULT') ||
              (post.originalPost
                && post.originalPost.types?.typeName === 'PETITIONS'
                && post.type === 'SHARED') ?
              <div className="post__type">
                <img className="post__type-img" src={petition} alt="" />
                <h2 className="post__type-title">
                  {formatMessage({ id: 'petition' }).toUpperCase()}
                </h2>
              </div> : null}

            {post.type === 'SHARED' ? (
              <div
                className="post__container"
                style={{
                  textAlign: 'left'
                }}
              >
                {post.priority == 1 && (
                  <div
                    className="post__header-admin-note-wrapper"
                    style={{
                      display: 'flex',
                      flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                    }}
                  >
                    <div
                      className="post__header-admin-note-container"
                      style={{
                        textAlign: lan === 'ar' ? 'right' : 'left'
                      }}
                    >
                      {`WizWork ${formatMessage({ id: 'moderators' })}`}
                    </div>
                  </div>
                )}
                <div
                  className="post__header"
                  style={{
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                  }}
                >
                  <div
                    className="post__header-user-container"
                    style={{
                      flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                      marginRight: lan === 'ar' ? '0' : 'auto',
                      marginLeft: lan === 'ar' ? 'auto' : '0'
                    }}
                  >
                    <div
                      className='post__header-avatar-container post__header-avatar-container--shared'
                      style={{
                        margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0'
                      }}
                    >
                      <img
                        className="post__header-avatar"
                        src={post.owner?.id === id ? profileAvatar : post.owner?.profileAvatar}
                        alt=""
                      />
                    </div>

                    <div
                      className="post__header-date-container"
                      style={{
                        alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
                      }}
                    >
                      <div onClick={() => navigateToTop(`/profile/${post.owner?.id}`)}>
                        <div className="post__header-name">
                          {`${post.owner?.organizationName}` ||
                            `${post.owner?.firstName} ${post.owner?.lastName}`}
                          <div
                            style={{
                              backgroundColor: onlineStatus?.some((a: string) => a === post.owner?.id)
                                ? '#28A745'
                                : '#FD2551',
                            }}
                          ></div>
                        </div>
                      </div>

                      <span className="post__header-date">
                        {`${formatMessage({ id: 'sharedIn' })} ${postDateConvert(post.created)}`}
                      </span>
                    </div>
                  </div>

                  {isAdmin && <DropdownAccounts post={post} />}

                  {type === 'report' ? (
                    <div className="post__report-btns">
                      <button
                        className="reportedComments-report-reasons-modal"
                        onClick={() => {
                          setCommentReportReasonsModal(true);
                          setReportId(post.id);
                        }}
                      >
                        Report Reasons
                      </button>
                      <button
                        className="post__report-skip-btn"
                        onClick={() => post.reportId && setSkipModal(post.id)}
                      >
                        {formatMessage({ id: 'skip' })}
                      </button>
                      <div
                        className="post__header-more-btn-container"
                        aria-describedby={id}
                      >
                        <IconButton color="secondary">
                          <div
                            className="post__report-delete-btn"
                            onClick={() => setDeleteModal(post.id)}
                          >
                            <svg className="post__popover-img" width="24" height="24" viewBox="0 0 18 19" fill="none">
                              <path opacity="0.3" d="M6 7.25H12V14.75H6V7.25Z" fill="#6C757D" />
                              <path d="M11.625 3.5L10.875 2.75H7.125L6.375 3.5H3.75V5H14.25V3.5H11.625ZM4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6 7.25H12V14.75H6V7.25Z" />
                            </svg>
                          </div>
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="post__header-more-btns-container"
                      style={{
                        flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                      }}
                    >
                      {!isAdmin ?
                        <Bookmark
                          defaultState={post.isBookmarked}
                          postId={post?.id}
                          type={type}
                        /> : null}
                      {/* <ShareButton post={post} /> */}
                      {type !== 'saved' && <MoreButton post={post} />}
                    </div>
                  )}
                </div>

                {/* ================== /post header ==================== */}
                <PostText post={post} />
                <div
                  className="post"
                  style={{
                    marginBottom: '12px',
                    border: '1px solid #E9E9E9',
                    borderRadius: '8px',
                  }}
                >
                  <div
                    className="post__container"
                    style={{ textAlign: 'left' }}
                  >
                    {post.originalPost && (
                      <>
                        {post.originalPost?.priority == 1 && (
                          <div
                            className="post__header-admin-note-wrapper"
                            style={{
                              display: 'flex',
                              flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                            }}
                          >
                            <div
                              className="post__header-admin-note-container"
                              style={{
                                textAlign: lan === 'ar' ? 'right' : 'left'
                              }}
                            >
                              {`WizWork ${formatMessage({ id: 'moderators' })}`}
                            </div>
                          </div>
                        )}

                        <div className="post__body">
                          <div
                            className="post__body-text-container"
                            style={{
                              marginBottom: post.originalPost.owner?.id !== id ? '0' : '12px',
                              borderBottom: post.originalPost.owner?.id !== id ?
                                'none' : '1px solid #E9E9E9',
                            }}
                          >
                            <div
                              className="post__header"
                              style={{
                                flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                              }}
                            >
                              <div
                                className="post__header-user-container"
                                style={{
                                  flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                                  marginRight: lan === 'ar' ? '0' : 'auto',
                                  marginLeft: lan === 'ar' ? 'auto' : '0'
                                }}
                              >
                                <div
                                  className="post__header-avatar-container post__header-avatar-container-inside"
                                  style={{
                                    margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0'
                                  }}
                                >
                                  <img
                                    className="post__header-avatar"
                                    src={post?.originalPost?.owner?.id === id ?
                                      profileAvatar : post?.originalPost?.owner?.profileAvatar}
                                    alt=""
                                  />
                                </div>

                                <div
                                  className="post__header-date-container"
                                  style={{
                                    alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
                                  }}
                                >
                                  <div onClick={() => navigateToTop(`/profile/${post?.originalPost?.owner?.id}`)}>
                                    <div className="post__header-name">
                                      {`${post.originalPost?.owner?.organizationName}` ||
                                        `${post.originalPost?.owner?.firstName} ${post?.originalPost?.owner?.lastName}`}
                                      <div
                                        style={{
                                          backgroundColor: onlineStatus?.some((a: string) => a === post?.originalPost?.owner?.id)
                                            ? '#28A745'
                                            : '#FD2551',
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <span className="post__header-date">
                                    {postDateConvert(post.originalPost?.created)}
                                  </span>
                                </div>
                              </div>
                              {width > 1440 &&
                                <InterestArea
                                  post={post}
                                  marginTop={-26}
                                  placement="bottom-end"
                                />}
                            </div>
                            {width <= 1440 && <InterestArea post={post} />}
                            {(post.originalPost?.types?.typeName !== "DEFAULT") && (
                              <h2
                                className="post__advocacy-title"
                                style={{
                                  textAlign: (lan === 'ar') ? 'right' : 'left'
                                }}
                              >
                                {processTextInLink(post.originalPost?.title)}
                              </h2>
                            )}

                            <PostText post={post.originalPost} shared />

                            {post.originalPost?.types?.typeName === 'SURVEY/POLLS' && (
                              <PostSurveys post={post.originalPost} />
                            )}

                            {post.originalPost?.types?.typeName === 'PETITIONS' && (
                              <PetitionContact
                                email={post.originalPost.email}
                              />
                            )}
                          </div>

                          <SetReactions
                            post={post.originalPost}
                            reactionWidth={114}
                            writeComment={true}
                            footer={true}
                          />
                        </div>
                      </>
                    )}

                    <Comments post={post.originalPost} />

                  </div>{' '}
                </div>
              </div>) : (
              <div
                className="post__container"
                style={{ textAlign: 'left' }}
              >
                {post.priority == 1 && (
                  <div
                    className="post__header-admin-note-wrapper"
                    style={{
                      display: 'flex',
                      flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                    }}
                  >
                    <div
                      className="post__header-admin-note-container"
                      style={{
                        textAlign: lan === 'ar' ? 'right' : 'left'
                      }}
                    >
                      {`WizWork ${formatMessage({ id: 'moderators' })}`}
                    </div>
                  </div>
                )}
                <div
                  className="post__header"
                  style={{
                    flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                  }}
                >
                  <div
                    className="post__header-user-container"
                    style={{
                      flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
                      marginRight: lan === 'ar' ? '0' : 'auto',
                      marginLeft: lan === 'ar' ? 'auto' : '0'
                    }}
                  >
                    <div
                      className={`post__header-avatar-container${post?.type === 'SHARED' ?
                        ' post__header-avatar-container--shared' : ''}`}
                      style={{
                        margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0'
                      }}
                    >
                      <img
                        className="post__header-avatar"
                        src={post.owner?.id === id ?
                          profileAvatar : post.owner?.profileAvatar}
                        alt=""
                      />
                    </div>

                    <div
                      className="post__header-date-container"
                      style={{
                        alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
                      }}
                    >
                      <div onClick={() => navigateToTop(`/profile/${post?.owner?.id}`)}>
                        <div className="post__header-name">
                          {`${post.owner?.organizationName}` ||
                              `${post.owner?.firstName} ${post?.owner?.lastName}`}
                          <div
                            style={{
                              backgroundColor: onlineStatus?.some((a: string) => a === post.owner?.id)
                                ? '#28A745'
                                : '#FD2551',
                            }}
                          ></div>
                        </div>
                      </div>

                      <span className="post__header-date">
                        {postDateConvert(post.created)}
                      </span>
                    </div>
                  </div>
                  
                  {width > 1440 && type !== 'report' &&
                      <InterestArea post={post} marginTop={-24} />}

                  {isAdmin && <DropdownAccounts post={post} />}

                  {type === 'report' ? (
                    <div className="post__report-btns">
                      <button
                        className="reportedComments-report-reasons-modal"
                        onClick={() => {
                          /* @ts-ignore */
                          setCommentReportReasonsModal(true);
                          setReportId(post.id);
                        }}
                      >
                        Report Reasons
                      </button>
                      <button
                        className="post__report-skip-btn"
                        onClick={() => post?.reportId && setSkipModal(post.id)}
                      >
                        {formatMessage({ id: 'skip' })}
                      </button>
                      <div
                        className="post__header-more-btn-container"
                        aria-describedby={id}
                      >
                        <IconButton color="secondary">
                          <div
                            className="post__report-delete-btn"
                            onClick={() => setDeleteModal(post?.id)}
                          >
                            <svg className="post__popover-img" width="24" height="24" viewBox="0 0 18 19" fill="none">
                              <path opacity="0.3" d="M6 7.25H12V14.75H6V7.25Z" fill="#6C757D" />
                              <path d="M11.625 3.5L10.875 2.75H7.125L6.375 3.5H3.75V5H14.25V3.5H11.625ZM4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6 7.25H12V14.75H6V7.25Z" />
                            </svg>
                          </div>
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="post__header-more-btns-container"
                      style={{
                        flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
                      }}
                    >
                      {!isAdmin ?
                        <Bookmark
                          defaultState={post?.isBookmarked}
                          postId={post?.id}
                          type={type}
                        /> : null}

                      {type !== 'saved' && <MoreButton post={post} />}
                    </div>
                  )}
                </div>

                {(width <= 1440 || type === 'report') &&
                  <InterestArea
                    isReport
                    post={post}
                    marginTop={-8}
                    placement="bottom-start"
                  />}

                {/* ====================== /post header ================== */}
                <div className="post__body">
                  {(post.types?.typeName !== "DEFAULT") && (
                    <h2
                      className="post__advocacy-title"
                      style={{
                        textAlign: (lan === 'ar') ? 'right' : 'left'
                      }}
                    >
                      {processTextInLink(post?.title)}
                    </h2>
                  )}
                  <div className="post__body-text-container">
                    <PostText post={post} />
                    {post.types?.typeName === 'SURVEY/POLLS' && <PostSurveys post={post} />}
                    {post.types?.typeName === 'PETITIONS' && post?.email && (
                      <PetitionContact email={post?.email} />
                    )}
                  </div>

                  {/* ==================== reactions ======================== */}
                  <SetReactions
                    post={post}
                    writeComment={true}
                    footer={true}
                    page={type}
                  />
                  <Comments post={post} showShareBtn={true} />
                </div>
              </div>)}
          </div>
        ))) : (
        <>
          {somePostId ?
            '' :
            <h2 className="my-feed__no-posts">
              {formatMessage({ id: 'noPosts' })}
            </h2>}
        </>)}

      <div
        className={`reactions-peoples${choosenTags ?
          ' reactions-peoples--visible' : ''}`}
      >
        <div className="reactions-peoples__container">
          <div className="reactions-peoples__title">
            <ArrowBack
              onClick={() => {
                if (selectedInterests) {
                  dispatch(setSelectedInterests([]));
                }
                dispatch(setChoosenTags(false));
              }}
            />
            {formatMessage({ id: 'tag-sChosen' })}
          </div>
          <ul>
            {selectedInterests
              ?.filter((interes: Chip) => !interes.isArchived)
              ?.sort((a: any, b: any) => a?.title.toLowerCase().localeCompare(b?.title.toLowerCase()))
              ?.map((interes: Chip) => (
                <li key={interes.id}>
                  <div className="post__reactions-img-container">
                    <img
                      className="post__reactions-img"
                      style={{
                        width: '32px',
                        height: '32px',
                      }}
                      src={interes?.avatar || interes?.url}
                      alt=""
                    />
                  </div>
                  <span>{interes?.title}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>

      {commentReportReasonsModal && (
        // postReportReasons.length === 0 ? <Loader /> :
        <CommentReportReasons
          reportedComment={reportId}
          // @ts-ignore 
          setCommentReportReasonsModal={setCommentReportReasonsModal}
        />
      )}

      {skipModal && (
        <ActionModal
          action={'skip'}
          setDeleteModal={setSkipModal}
          id={skipModal}
        />
      )}

      {deleteModal && (
        <ActionModal
          action={'delete'}
          setDeleteModal={setDeleteModal}
          id={deleteModal}
        />
      )}

      {setPostEdit && (
        <PostCreation close={closePostCreation} type={'edit'} />
      )}
    </>
  );
};

export default Posts;
