import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FilledInputProps,
  InputBaseComponentProps,
  TextField,
} from '@mui/material';
import {
  FieldErrorsImpl,
  DeepRequired,
  FieldValues,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import EmailTooltip from '../../Tooltips/EmailTooltip/EmailTooltip';
import PasswordTooltip from '../../Tooltips/PasswordTooltip/PasswordTooltip';
import ExistingEmailTooltip from '../../Tooltips/ExistingEmailTooltip/ExistingEmailTooltip';
import useWindowDimensions from '../../../hooks/widthHook';
import './CustomInput.scss';

type Props = {
  onChangeFunc?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  width?: number | string;
  inputName?: string;
  inputProps?: InputBaseComponentProps;
  errors?: FieldErrorsImpl<DeepRequired<FieldValues>> | any;
  touchedFields?: { [x: string]: any };
  InputProps?: Partial<FilledInputProps>;
  defaultValue?: string;
  control?: any;
  ref?: any;
  style?: any;
  key?: string;
  select?: boolean;
  handleError?: boolean;
};

const CustomInput = ({
  onChangeFunc = () => { },
  touchedFields = {},
  placeholder,
  disabled,
  label = 'l',
  width,
  inputProps,
  InputProps,
  errors,
  inputName = '',
  defaultValue,
  control,
  ref,
  key,
  style,
  select,
  handleError,
  ...props
}: Props) => {
  const [checkedLabel, setCheckedlabel] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [focusedInput, setFocusedInput] = useState(false);
  // const [isPrinting, setIsPrinting] = useState(true);
  const location = useLocation()?.pathname;

  const lan = localStorage.getItem('lang') || 'en';
  const { width: windowWidth } = useWindowDimensions();
  const { formatMessage } = useIntl();

  useEffect(() => {
    //  @ts-ignore: Object is possibly 'null'.
    if (windowWidth < 1100) {
      errors?.[inputName]?.message
        ? setShowTooltip(true)
        : setShowTooltip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors?.[inputName]?.message, inputName, windowWidth]);

  return (
    <div
      className={`customInput${disabled ?
        ' customInput--disabled' : ''}${errors !== undefined
          && Object.keys(errors)?.includes(inputName) ?
          ` customInput--errors${lan === 'ar' ? ' ar' : ''}` : ''}${!handleError
            && errors !== undefined
            && !Object.keys(errors)?.includes(inputName)
            && Object.keys(touchedFields).includes(inputName) ?
            ` customInput--noErrors${lan === 'ar' ? ' ar' : ''}${select ?
              ' forSelect' : ''}` : ''}${handleError ?
                ' customInput--errors' : ''}`}
      style={{
        width: width,
        fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
        ...style,
      }}
    >
      {label && (
        <label
          className={`customInput-label${checkedLabel ? ' --focused' : ''
            }`}
          style={
            label === 'l' ? { visibility: 'hidden' } : (label === ' ' ? {
              maxHeight: '0',
              marginBottom: '0'
            } : undefined)
          }
        >
          {label}
        </label>
      )}
      <div
        className={`customInput-inner${focusedInput ? ' customInput-inner--focused' : ''
          }${checkedLabel ? ' customInput-adornment--checked' : ''}`}
        dir={lan === 'ar' ? 'rtl' : 'ltr'}
        style={{
          width: (windowWidth >= 1100 && windowWidth < 1440 && location === '/search') ? '230px' : '100%',
        }}
      >
        {showTooltip &&
          inputName === 'email' &&
          errors?.[inputName]?.message ===
          formatMessage({ id: 'incorrectEmail' }) && <EmailTooltip />}

        {showTooltip &&
          inputName === 'email' &&
          errors?.[inputName]?.message ===
          formatMessage({ id: 'emailAlreadyRegistered' }) && (
            <ExistingEmailTooltip />
          )}
        {showTooltip &&
          (inputName === 'password' ||
            inputName === 'newPassword' ||
            inputName === 'confirmPassword') &&
          errors?.password?.message ===
          formatMessage({ id: 'weakPassword' }) && <PasswordTooltip />}
        <TextField
          {...props}
          dir={lan === 'ar' ? 'rtl' : 'ltr'}
          inputProps={inputProps}
          disabled={disabled}
          onChange={e => {
            onChangeFunc(e.target.value);
          }}
          onBlur={e => {
            !e.target.value && setCheckedlabel(false);
            setFocusedInput(false);
            //  @ts-ignore: Object is possibly 'null'.
            if (windowWidth >= 1100) {
              setShowTooltip(false);
            }
          }}
          onFocus={() => {
            setCheckedlabel(true);
            setFocusedInput(true);
            //  @ts-ignore: Object is possibly 'null'.
            if (windowWidth >= 1100) {
              setShowTooltip(true);
            }
          }}
          size="small"
          placeholder={placeholder}
          InputProps={InputProps}
          defaultValue={defaultValue}
        />
      </div>
      {errors?.[inputName] && errors?.[inputName]?.type !== 'maxLength' && (
        <span className="errorMessage">
          {errors?.[inputName]?.message || 'Error'}
        </span>
      )}
      {errors?.[inputName]?.type === 'maxLength' && (
        <span className="errorMessage">
          {formatMessage({ id: 'maxLengthError' })}
        </span>
      )}

      {windowWidth < 1100 &&
        showTooltip &&
        inputName === 'email' &&
        errors?.[inputName]?.message ===
        formatMessage({ id: 'incorrectEmail' }) && (
          <div style={{ height: 100 }}></div>
        )}

      {windowWidth < 1100 &&
        showTooltip &&
        inputName === 'email' &&
        errors?.[inputName]?.message ===
        formatMessage({ id: 'emailAlreadyRegistered' }) && (
          <div style={{ height: 70 }}></div>
        )}

      {windowWidth < 1100 &&
        showTooltip &&
        (inputName === 'password' || inputName === 'newPassword') &&
        errors?.[inputName]?.message ===
        formatMessage({ id: 'weakPassword' }) && (
          <div style={{ height: 120 }}></div>
        )}
    </div>
  );
};

export default CustomInput;
