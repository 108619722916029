import React from 'react';
import Container from '../container/Container';
import Approuter from '../router/Approuter';

const Main = () => {
  return (
    <>
      <Container>
        <Approuter />
      </Container>
    </>
  );
};

export default Main;
