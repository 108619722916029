import React, { CSSProperties, ReactNode } from 'react';
import './ModalContainer.scss';

interface ChildrenProps {
  children?: ReactNode;
  styles?: CSSProperties;
  isMobile?: boolean;
  congrats?: boolean;
}

const ModalContainer = ({ children, styles, isMobile, congrats }: ChildrenProps) => {

  return (
    <div
      className={`ModalContainer${isMobile ?
        ' ModalContainer--mobile' : ''}${congrats ?
          ' ModalContainer--congrats' : ''}`}
      style={styles}
    >
      {children}
    </div>
  )
};

export default ModalContainer;
