import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import axios from 'axios';
import AdsItem from './AdsItem';
import { RootState } from '../../redux/store';
import { languages } from '../../constants/language';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import { SelectWithIcons } from '../shared/SelectWithIcons/SelectWithIcons';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import CustomInput from '../shared/CustomInput/CustomInput';
import CustomChip from '../shared/CustomChip/CustomChip';
import TextArea from '../shared/TextArea/TextArea';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import interestsTag from '../../images/interest-areas.svg';
import './AdsEditModal.scss';
import { useDispatch } from 'react-redux';
import { hideLoader, setAds, showLoader } from '../../redux/slices/appSlice';
// import { postAds } from '../../api/user';
import { toast } from 'react-toastify';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../constants';
import { createAd, editAd, updateAdsPositon } from '../../api/admin';
import { getAds } from '../../api/user';


export const reorder = (
  list: any,
  startIndex: number,
  endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  close: () => void;
}

const AdsEditModal = ({ close }: Props) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    formState: { errors, isValid, },
  } = useForm({ mode: 'all' });

  const dispatch = useDispatch();
  const { allInterests: chips, ads } = useSelector((state: RootState) => state.app);
  const [duplicateError, setDuplicateError] = useState(false);
  const lan = localStorage.getItem('lang') || 'en';

  const [tempFileData, setTempFileData] = useState<FormData | undefined>();
  // const [chosenImg, setChosenImg] = useState('');

  const { formatMessage } = useIntl();
  const [itemsArray, setItemsArray] = useState<any>(ads);

  const [modalStep, setModalStep] = useState<number>(0);
  const [selectedAdIndex, setSelectedAdIndex] = useState<number | undefined>();

  const [isOpenInterestsList, setIsOpenInterestsList] = useState<boolean>(false);
  const [selectedChips, setSelectedChips] = useState<SelectedChip[] | Chip[] | []>([]);
  const [btnSelectChecked, setBtnSelectChecked] = useState<boolean>(false);
  const [addedLanguages, setAddedLanguages] = useState([
    {
      code: 'en',
      title: '',
      descr: '',
    }
  ]);

  useEffect(() => {
    if (typeof selectedAdIndex === 'number') {
      const newArr = JSON.parse(JSON.stringify(itemsArray));
      newArr[selectedAdIndex].interests = selectedChips;
      newArr[selectedAdIndex].selectAll = btnSelectChecked;
      setItemsArray(newArr)
    }
  }, [selectedChips])

  useEffect(() => {
    setItemsArray(ads);
  }, [ads])

  // Mark bandit

  useEffect(() => {
    const newArr = JSON.parse(JSON.stringify(addedLanguages)).map((item: any) => item.code);
    const arrayOfDuplicates = newArr
      .filter((item: any, index: number, arr: any) => arr.indexOf(item) !== index);

    if (arrayOfDuplicates.length !== 0) {
      setDuplicateError(true)
    } else {
      setDuplicateError(false)
    }

    if (typeof selectedAdIndex === 'number') {
      const newArray = JSON.parse(JSON.stringify(itemsArray));;
      newArray[selectedAdIndex].addedLanguages = addedLanguages;
      setItemsArray(newArray)
    }

  }, [addedLanguages])

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = reorder(itemsArray, source.index, destination.index);
    setItemsArray(newItems);
  };

  const uploadImage = (files: FileList | null) => {
    if (files === null) return;
    const tempFile = files[0];

    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(tempFile);
    img.src = objectUrl;
    img.onload = () => {
      const formData = new FormData();
      formData.append('file', tempFile);
      formData.append('upload_preset', Cloudinary_PRESET);

      setTempFileData(formData);

      const newArr = itemsArray;
      if (typeof selectedAdIndex === 'number') {
        newArr[selectedAdIndex].img = img.src
      }
      setItemsArray(newArr);
    };
  };

  useEffect(() => {
    if (typeof selectedAdIndex !== 'number') {
      setAddedLanguages([
        {
          code: 'en',
          title: '',
          descr: '',
        }
      ]);
      const newArr = itemsArray;
      // newArr[selectedAdIndex || 0].img = ''
      setItemsArray(newArr);
      setSelectedChips([])
      setBtnSelectChecked(false)
    }
  }, [selectedAdIndex])

  const onSubmit = async () => {
    dispatch(showLoader(true))

    if (tempFileData) {
      await axios.post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, tempFileData)
        .then(response => {
          const newArr = itemsArray;
          newArr[selectedAdIndex || 0].img = response.data.secure_url
          setItemsArray(newArr);
        })
    }

    if (typeof selectedAdIndex === 'number' && modalStep === 1) {
      const dataToEdit = itemsArray[selectedAdIndex];
      dataToEdit.interests = selectedChips
      await editAd(dataToEdit)
        .then(res => {
          if (res.status === 200) {
            reset();
            setModalStep(0);
            setSelectedAdIndex(undefined)
            setTempFileData(undefined)
          }
        })
        .finally(() => {
          dispatch(showLoader(false))
        })
    } else {
      const dataToEdit = itemsArray[itemsArray.length - 1];
      dataToEdit.interests = selectedChips.map((item: any) => item.id + "")

      await createAd(itemsArray[itemsArray.length - 1])
        .then(res => {
          if (res.status === 201) {
            reset();
            setModalStep(0);
            setSelectedAdIndex(undefined)
            setTempFileData(undefined)
          }
        })
        .finally(() => {
          dispatch(showLoader(false))
        })
    }

    getAds().then((res: any) => {
      if (res.status === 200) {
        dispatch(setAds(res.data));
      }
    })
  }

  return (
    <div className='AdsModal'>
      <ModalWrapper width={623} positionUnset>
        <ModalContainer>
          <ModalHeader
            textHeader={modalStep === 0 ?
              formatMessage({ id: 'adsSettings' }) : (
                modalStep === 1 ? formatMessage({ id: 'editAd' }) : formatMessage({ id: 'createAd' })
              )}
            onClose={close}
          />

          <div className='AdsModal-line'></div>

          {modalStep === 0 ? (
            <div className='AdsModal-main'>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-list">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {itemsArray.map((item: any, index: number) => (
                        <AdsItem
                          setAddedLanguages={setAddedLanguages}
                          setSelectedChips={setSelectedChips}
                          item={item}
                          index={index}
                          key={item.id}
                          setModalStep={setModalStep}
                          setItemsArray={setItemsArray}
                          itemsArray={itemsArray}
                          setSelectedAdIndex={setSelectedAdIndex}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>

                {addedLanguages.map((item, index) => (
                  <div key={index}>
                    <div className="adsCreation__lang">
                      <SelectWithIcons
                        width={170}
                        options={languages}
                        value={languages.find(lang => lang.code === item.code)?.label}
                        setValue={(option) => {
                          const newArr = JSON.parse(JSON.stringify(addedLanguages));
                          newArr[index].code = languages.find(lang => lang.label === option)?.code
                          setAddedLanguages(newArr);
                        }} />

                      {index !== 0 && (
                        <CloseIcon onClick={() => {
                          const newArr = JSON.parse(JSON.stringify(addedLanguages));
                          newArr.splice(index, 1)
                          clearErrors()
                          setAddedLanguages(newArr)
                        }} />
                      )}
                    </div>

                    <div className="postCreation__inputItem">
                      <CustomInput
                        placeholder={formatMessage({ id: 'writeTitleHere' })}
                        label={formatMessage({ id: 'title' })}
                        errors={errors}
                        inputName={`title_${index}`}
                        inputProps={{
                          ...register(`title_${index}`, {
                            required: formatMessage({ id: 'fieldIsEmpty' })
                          }),
                          defaultValue: item.title,
                          style: {
                            fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                          }
                        }}
                        onChangeFunc={(value) => {
                          const oldAddedLang = JSON.parse(JSON.stringify(addedLanguages));
                          oldAddedLang[index].title = value
                          setAddedLanguages(oldAddedLang)
                        }}
                      />
                    </div>

                    <TextArea
                      label={formatMessage({ id: 'description' })}
                      placeholder={formatMessage({ id: 'writeDescription' })}
                      setValue={(value) => {
                        const oldAddedLang = JSON.parse(JSON.stringify(addedLanguages));
                        oldAddedLang[index].descr = value
                        setAddedLanguages(oldAddedLang)
                      }}
                      type="adsCreation"
                      uploadImage={index === 0 ? uploadImage : undefined}
                      name={`description_${index}`}
                      register={register}
                      errors={errors}
                      defaultValue={item?.descr}
                    />
                  </div>
                ))}

                <div className="adsCreation__lang">
                  <SecondaryButton
                    text={formatMessage({ id: 'addLanguage' })}
                    disabled={addedLanguages.length === 7}
                    onClick={() => {
                      setAddedLanguages((prev) =>
                        [...prev, JSON.parse(JSON.stringify({
                          code: languages[0].code,
                          title: '',
                          descr: '',
                        }))])
                    }}
                  />
                </div>

                <div className="postCreation__inputItem">
                  <CustomInput
                    placeholder={'http://example/websitename.com'}
                    label={'URL'}
                    inputProps={{
                      ...register('adURL', {
                        required: false,
                      }),
                      style: {
                        fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                      },
                      defaultValue: `${typeof selectedAdIndex === 'number' ?
                        itemsArray[selectedAdIndex || 0]?.url : ''}`
                    }}
                    onChangeFunc={(value) => itemsArray[selectedAdIndex || 0].url = value}
                  />
                </div>

                {itemsArray[selectedAdIndex || 0].img && (
                  <div className='adsCreation__img-wrapper'>
                    <img className='adsCreation__img' src={itemsArray[selectedAdIndex || 0].img} alt="ads-img" />
                    <CloseIcon onClick={() => {
                      const newArr = JSON.parse(JSON.stringify(itemsArray));
                      newArr[selectedAdIndex || 0].img = ''
                      setItemsArray(newArr);
                      setTempFileData(undefined)
                    }} />
                  </div>
                )}

                <div className="postCreation__divider"></div>

                <div
                  className="postCreation__interestsInfo"
                  onClick={() => setIsOpenInterestsList(!isOpenInterestsList)}
                >
                  <div
                    className={`postCreation__interestsBtn${itemsArray[selectedAdIndex || 0]?.interests.length !== 0 || isOpenInterestsList
                      ? ' postCreation__interestsBtn--selected'
                      : ''}`}
                  >
                    <img src={interestsTag} alt="interests tag icon" />
                    {formatMessage({ id: 'selectAreasToShowAd' })}
                  </div>
                  {!isOpenInterestsList ? (
                    <ArrowDropDownIcon style={{ color: '#6C757D' }} />
                  ) : (
                    <ArrowDropUpIcon style={{ color: '#6C757D' }} />
                  )}
                </div>

                {!isOpenInterestsList && selectedChips.length !== 0 && (
                  <div className="postCreation__countSelectedChips">
                    {`${selectedChips.filter((chip: any) => !chip.isArchived)
                      .length} ${formatMessage({ id: 'tagSelected' })}`}
                  </div>
                )}

                {isOpenInterestsList && (
                  <div className="postCreation__interestsList">
                    <div className="postCreation__btnSelectAll">
                      <CustomChip
                        label={btnSelectChecked ?
                          formatMessage({ id: 'resetAll' }) :
                          formatMessage({ id: 'selectAll' })}
                        src=""
                        id=""
                        isArchived
                        defaultChecked={btnSelectChecked}
                        onClick={() => {
                          setSelectedChips(!btnSelectChecked ? [...chips.filter(item => !item.isArchived)] : []);
                          setBtnSelectChecked(!btnSelectChecked);
                        }}
                      />
                    </div>

                    <div className="postCreation__chips">
                      {chips
                        .filter((item: Chip) => !item.isArchived)
                        .map((item: Chip, index: number) => (
                          <CustomChip
                            key={index}
                            label={item.title}
                            src={item.avatar || item.url}
                            id={item.id.toString()}
                            isArchived={item.isArchived}
                            defaultChecked={
                              selectedChips.some(chip => chip.id == item.id) &&
                              true
                            }
                            setSelectedChips={setSelectedChips}
                          />
                        ))}
                    </div>
                  </div>
                )}

                {duplicateError ? (
                  <div className="adsCreation__error">
                    {formatMessage({ id: 'twoLanguagesSelected' })}
                    <br />
                    {formatMessage({ id: 'deleteDuplicatesLanguage' })}
                  </div>
                ) : ''}

                <ModalFooter>
                  <SecondaryButton
                    text={formatMessage({ id: 'back' })}
                    onClick={() => {
                      setModalStep(0);
                      clearErrors();
                      reset();
                      setDuplicateError(false)
                      setSelectedAdIndex(undefined)

                      if (modalStep === 2) {
                        const newArr = itemsArray;
                        newArr.pop()
                        setItemsArray(newArr);
                      }

                      setAddedLanguages([
                        {
                          code: languages[0].code,
                          title: '',
                          descr: '',
                        }
                      ]);
                    }} />
                  <MainButton
                    text={modalStep === 1 ?
                      formatMessage({ id: 'saveChanges' }) :
                      formatMessage({ id: 'createAd' })}
                    type='submit'
                    disabled={!isValid || duplicateError}
                  />
                </ModalFooter>
              </form>
            </div>
          )}

          {modalStep === 0 && (
            <>
              <SecondaryButton
                text={formatMessage({ id: 'addNew' })}
                onClick={() => {
                  setItemsArray([...itemsArray, {
                    addedLanguages: [
                      {
                        code: 'en',
                        title: '',
                        descr: '',
                      },
                    ],
                    interests: [],
                    selectAll: false,
                    isDisabled: false,
                    url: '',
                    img: '',
                  }]);
                  setSelectedAdIndex(itemsArray.length);
                  setModalStep(2)
                }} />

              <ModalFooter>
                <MainButton
                  text={formatMessage({ id: 'saveChanges' })}
                  onClick={() => {
                    dispatch(showLoader(true))
                    // setModalStep(0);
                    // setSelectedAdIndex(undefined);

                    // (async () => {
                    //   if (tempFileData) {
                    //     await axios.post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, tempFileData)
                    //       .then(response => {
                    //         const newArr = itemsArray;
                    //         newArr[selectedAdIndex || 0].img = response.data.secure_url
                    //         setItemsArray(newArr);
                    //       })
                    //   }
                    // })()

                    // setTempFileData(undefined)
                    // const newArr = JSON.parse(JSON.stringify(itemsArray));
                    // newArr.map((item: any) => {
                    //   const newItem = item;

                    //   // delete newItem.id;
                    //   delete newItem.counter;
                    //   newItem.interests = newItem.interests.map((interes: any) => interes.id)

                    //   return newItem
                    // })

                    updateAdsPositon({ idS: itemsArray.map((item: any) => item.id) })
                      .then(res => {
                        if (res.status === 200) {
                          toast.success('Ads updated')
                          // dispatch(setAds(res.data));
                        }
                      })
                      .finally(() => {
                        dispatch(showLoader(false))
                        close()
                        getAds().then((res: any) => {
                          if (res.status === 200) {
                            dispatch(setAds(res.data));
                          }
                        })
                      })

                    // postAds({ ads: newArr })
                    //   .then(res => {
                    //     if (res.status === 201) {
                    //       toast.success('Ads updated')
                    //       dispatch(setAds(res.data));
                    //     }
                    //     dispatch(showLoader(false))
                    //     close()
                    //   })
                    //   .catch(() => {
                    //     close();
                    //     dispatch(showLoader(false))
                    //   })
                  }}
                />
              </ModalFooter>
            </>
          )}

        </ModalContainer>
      </ModalWrapper>
    </div>
  )
}

export default AdsEditModal;


// const positions = [123, 23, 13, 05, 123]
// Change Positions - Save Changes OUTSIDE

// Edit One Add - Save Changes OUTSIDE/INSIDE

// Delete One Add - Save Changes OUTSIDE

// Create One Add - Save Changes INSIDE

// Get Ads