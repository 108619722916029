import React, { useEffect, useState } from 'react';
import MediaViewModal from '../MediaViewModal/MediaViewModal';
import './PostMediaContent.scss';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import useWindowDimensions from '../../hooks/widthHook';
import { getVideoPoster } from '../helpers/getVideoPoster';

interface Props {
  post: Post;
  shared?: boolean;
  sharedModal?: boolean;
}

export const PostMediaContent = ({ post, shared, sharedModal }: Props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [mediaToModal, setMediaToModal] = useState<MediaItem[]>([]);
  const [mobileAttachments, setMobileAttachments] = useState<MediaItem[]>([]);

  let widthOfScreenMobile = useWindowDimensions().width;

  useEffect(() => {
    if (post?.attachments.length >= 3) {
      setMobileAttachments(post.attachments.slice(1));
    }

    setMediaToModal(
      post.attachments.map(element => ({
        id: element.id,
        toPost: post,
        type: element.type,
        url: element.url,
      })),
    );
  }, [post]);

  return (
    <>
      {useWindowDimensions().width < 1100 && post.attachments.length >= 3 ? (
        <>
          <div
            onClick={() => {
              if (sharedModal !== true) {
                setPhotoIndex(0);
                setViewModal(true);
              }
            }}
            className="post__attachment-container"
            style={{
              marginBottom: '2%',
              width: `100%`,
              height: `auto`,
              maxHeight: `384px`,
            }}
          >
            <img
              className="post__attachment-item"
              src={post.attachments[0].url}
              alt=""
            />
          </div>

          {post.attachments[0].type === 'PHOTO' &&
            mobileAttachments.length > 1 &&
            mobileAttachments?.map((el, index) => (
              <React.Fragment key={index}>
                <div
                  onClick={() => {
                    if (sharedModal !== true) {
                      setPhotoIndex(index + 1);
                      setViewModal(true);
                    }
                  }}
                  className="post__attachment-container"
                  style={{
                    marginBottom: '2%',
                    width: `49%`,
                    height: `185px`,
                  }}
                >
                  <img className="post__attachment-item" src={el.url} alt="" />
                </div>

                {viewModal && (
                  <MediaViewModal
                    mediaArr={mediaToModal}
                    mediaIndex={photoIndex}
                    setMediaIndex={setPhotoIndex}
                    onClose={() => {
                      setViewModal(false);
                    }}
                  />
                )}
              </React.Fragment>
            ))}
        </>
      ) : (
        <>
          {post.attachments[0].type === 'PHOTO' &&
            post.attachments.map((el, index) => (
              <React.Fragment key={index}>
                <div
                  onClick={() => {
                    if (sharedModal !== true) {
                      setPhotoIndex(index);
                      setViewModal(true);
                    }
                  }}
                  className="post__attachment-container"
                  style={{
                    marginBottom: '2%',

                    width: `${
                      (sharedModal && post.attachments.length > 2 && '31.6%') ||
                      (sharedModal && post.attachments.length === 2 && '49%') ||
                      (post.attachments.length === 2 && '49%') ||
                      (post.attachments.length === 1 && '100%')
                    }`,

                    height: `${
                      (sharedModal && post.attachments.length > 2 && '229px') ||
                      (sharedModal &&
                        post.attachments.length === 2 &&
                        widthOfScreenMobile < 1100 &&
                        '185px') ||
                      (sharedModal &&
                        post.attachments.length === 2 &&
                        '324px') ||
                      (shared &&
                        post.attachments.length === 2 &&
                        widthOfScreenMobile < 1100 &&
                        '185px') ||
                      (shared && post.attachments.length === 2 && '449px') ||
                      (shared && post.attachments.length > 2 && '320px') ||
                      (post.attachments.length === 2 &&
                        widthOfScreenMobile < 1100 &&
                        '185px') ||
                      (post.attachments.length === 2 && '485px') ||
                      (post.attachments.length === 1 && 'auto')
                    }`,

                    maxHeight: `${post.attachments.length === 1 && '600px'}`,
                  }}
                >
                  <img className={`post__attachment-item ${post.attachments.length > 1 && 'post__attachment-item--group'}`} src={el.url} alt="" />
                </div>

                {viewModal && (
                  <MediaViewModal
                    mediaArr={mediaToModal}
                    mediaIndex={photoIndex}
                    setMediaIndex={setPhotoIndex}
                    onClose={() => {
                      setViewModal(false);
                    }}
                  />
                )}
              </React.Fragment>
            ))}
        </>
      )}

      {post.attachments[0].type === 'VIDEO' && (
        <>
          <div
            onClick={() => {
              if (sharedModal !== true) {
                setViewModal(true);
              }
            }}
            className="post__attachment-container"
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              // maxHeight: '837px',
            }}
          >
            <video
              poster={getVideoPoster(post.attachments[0].url)}
              src={post.attachments[0].url}
              style={{
                width: '100%',
              }}
              playsInline
            />

            <PlayCircleRoundedIcon
              sx={{
                height: '88px',
                width: '88px',
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate3d(-50%, -50%, 0)',
                fill: '#fff',
                boxShadow: '2px 2px 50px 0px rgba(0,0,0,0.1)',
                backgroundColor: '#00000008',
                borderRadius: '50%',

                transition: '0.2s linear',
                '&:hover': {
                  height: '95px',
                  width: '95px',
                },
              }}
            />
          </div>

          {viewModal && (
            <MediaViewModal
              mediaArr={mediaToModal}
              mediaIndex={photoIndex}
              setMediaIndex={setPhotoIndex}
              onClose={() => {
                setViewModal(false);
              }}
              isVideoView
            />
          )}
        </>
      )}
    </>
  );
};
