import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import './MainButton.scss';

interface Props {
  text: string;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  onClick?: () => void;
  bigPadding?: boolean;
  styles?: CSSProperties;
}

const MainButton = ({
  text,
  disabled,
  type = 'button',
  onClick,
  bigPadding,
  styles,
}: Props) => {
  const { language } = useSelector((state: RootState) => state.profile);

  return (
    <button
      className={`mainButton ${bigPadding ? 'mainButton--bigPadding' : ''}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={styles}
    >
      <span dir={language === 'ar' ? 'rtl' : 'ltr'}>
        {text}
      </span>
    </button>
  );
};

export default MainButton;
