import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { fetchPostsReports } from '../../redux/slices/postsSlice';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import { NavSide } from '../../components/NavSide/NavSide';
import Posts from '../../components/Posts/Posts';
import SecondaryButton from '../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import { useIntl } from 'react-intl';
import './PostReportPage.scss';

export const PostReportPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<any>([]);
  const [searchParams] = useSearchParams() || '';
  const skipPosts = searchParams.get('skip') || '';

  useEffect(() => {
    searchParams.set('skip', `0`);
    navigate(`?${searchParams.toString()}`, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchPostsReports(+skipPosts));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipPosts]);

  const { reportsPosts } = useSelector((state: RootState) => state.posts);

  useEffect(() => {
    setPosts([...posts, ...reportsPosts]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsPosts]);

  const morePosts = () => {
    searchParams.set('skip', `${+skipPosts + 5}`);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className='main-page'>
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner">
                <NavSide />

                <div className="post-report-page">
                  <h2 className="post-report-page__title">
                    Posts That Have Been Reported On
                  </h2>
                  <div className="post-report-page__container">
                    <Posts type={'report'} />
                  </div>

                  {reportsPosts?.length >= +skipPosts + 5 && (
                    <div className="ProfilesPageTab__more-btn">
                      <SecondaryButton
                        text={formatMessage({ id: 'showMore' })}
                        onClick={morePosts}
                      />
                    </div>
                  )}
                </div>

                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
}
