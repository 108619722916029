const httpsStart = 'https://';
const httpStart = 'http://';
const wwwStart = 'www.';

const isWrightURL = (str: string) => {
  return str?.slice(0, 8) !== httpsStart ? `https://${str}` : str;
};

const cutString = (str: string) => {
  let httpInd = str?.indexOf(httpsStart) || str?.indexOf(httpStart);
  if (!httpInd) {
    httpInd = str?.indexOf(wwwStart);
  }
  const text = str?.slice(0, httpInd);
  const link = str?.slice(httpInd, str.length);

  return <>
    {text}
    <a href={isWrightURL(link.replace(/,/, ''))} target="_blank">{link}</a>{' '}
  </>
};

export const processTextInLink = (text: string, space?: boolean) => {
  const arr = text?.split('\n').join(" \n")?.split(' ');
  const result = arr?.map((str: string) => {
    const ultimate = str?.includes(httpsStart) || str?.includes(wwwStart) || str?.includes(httpStart);
    const ultimateTwo = str?.slice(0, 8) === httpsStart || str?.slice(0, 4) === wwwStart || str?.slice(0, 7) === httpStart;

    if (ultimate) {
      return ultimateTwo ? (
        <>
          <a href={isWrightURL(str.replace(/,/, ''))} target="_blank">{str}</a>{' '}
        </>
      ) : (
        cutString(str)
      )
    } else {
      return str === '<br/>' ? <br/> : str + ' ';
    }
  });

  return <p>{space && ' '}{result}{space && ' '}</p>;
};


export const processTextInLinkForComment = (text: string) => {
  const arr = text?.split(' ');
  const result = [] as string[];

  arr?.forEach((str: string) => {
    const ultimate = str?.includes(httpsStart) || str?.includes(wwwStart) || str?.includes(httpStart);
  
    if (ultimate) {
      result.push(`<a href=${isWrightURL(str.replace(/,/, ''))} target="_blank">${str}</a> `);
    } else {
      result.push(str);
    }
  })

  return result.join(' ');
};