import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../../hooks/widthHook';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import {
  followUserById,
  getFollowing,
} from '../../../redux/slices/profileSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { useNavigateToTop } from '../../helpers/useNavigateToTop';
import { getFollowingSomeUser } from '../../../redux/slices/someUserSlice';
import MainButton from '../../shared/Buttons/MainButton/MainButton';
import SecondaryButton from '../../shared/Buttons/SecondaryButton/SecondaryButton';
import ShowMoreAds from '../../ShowMoreAds/ShowMoreAds';
import './Following.scss';

export const Following = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigateToTop = useNavigateToTop();
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  const { following, id: profileId } = useSelector((state: RootState) => state.profile);
  const { someUserFolowing, someUser } = useSelector((state: RootState) => state.someUser);
  const { someUserId } = useParams();
  const [followingToRender, setFollowingToRender] = useState<Following[]>([]);
  const [skip, setSkip] = useState(0);

  const handleMoreFollowingFunc = () => {
    setSkip(prev => prev + 5);
  };

  const followUserFunc = (id: any) => {
    if (profileId === someUserId) {
      dispatch(followUserById(id));
    } else {
      dispatch(followUserById({ ...id, page: 'someProfile' }));
    } 
  };

  // ===== get following users =====
  const dataToGetFollowing = {
    skip: skip,
    id: profileId,
  };

  // ===== get following someUser =====
  const dataToGetFollowingSomeUser = {
    skip: skip,
    id: someUserId,
  };

  useEffect(() => {
    if (profileId === someUserId) {
      dispatch(getFollowing(dataToGetFollowing));
    } else {
      dispatch(getFollowingSomeUser(dataToGetFollowingSomeUser));
    }
  }, [someUserId, skip]);

  useEffect(() => {
    if (profileId === someUserId && following) {
      setFollowingToRender(following.following)
    } else {
      setFollowingToRender(someUserFolowing.following)
    }
  }, [someUserFolowing, following]);

  console.log(followingToRender);

  return (
    <div className="followingWrapper">
      <ShowMoreAds type={'profile'} />
      <h2 className="followingMainTitle">
        {formatMessage({ id: 'following' })} ({someUser?.following})
      </h2>

      {followingToRender?.length > 0 && (
        <div className="followingFrame">
          {followingToRender?.map((follower: any, index: number) => (
            <div
              className="following__wrapper"
              key={follower?.id}
              style={{
                flexDirection: (width < 1100) ? 'column' : (lan === 'ar' ? 'row-reverse' : 'row'),
                alignItems: (width < 1100) ? (lan === 'ar' ? 'flex-end' : 'flex-start') : 'flex-start'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: (lan === 'ar' ? 'row-reverse' : 'row')
                }}
              >
                <div
                  className="followers__following-avatar-container"
                  style={{
                    margin: lan === 'ar' ? '0 0 0 12px' : '0 12px 0 0'
                  }}
                >
                  <img
                    className="followers__following-avatar"
                    src={follower?.profileAvatar}
                    alt="profile avatar"
                  />
                </div>

                <div className="following__titleWrapper">
                  <div onClick={() => navigateToTop(`/profile/${follower?.id}`)}>
                    <h2 className="following__title">
                      {`${follower?.organizationName}` || `${follower?.firstName} ${follower?.lastName}`}
                    </h2>
                  </div>
                  <h4 className="following__subtitle">{follower?.about}</h4>
                </div>
              </div>
              <div className="following__btnWrapper">

                {follower?.id !== profileId && (
                  <>
                    {follower?.ifFollow ? (
                        <SecondaryButton
                          text={formatMessage({ id: 'unfollow' })}
                          type="button"
                          onClick={() => {
                            followUserFunc(follower);
                          }}
                        />
                      ) : (
                        <MainButton
                          text={formatMessage({ id: 'follow' })}
                          type="button"
                          onClick={() => {
                            followUserFunc(follower);
                          }}
                        />
                      )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
       {/* MORE Following BUTTON */}
       {(someUser && someUser.following > skip + 5 && someUser.user?.privacy?.showFollowing) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <SecondaryButton
            text={formatMessage({ id: 'showMore' })}
            onClick={handleMoreFollowingFunc}
          />
        </div>
      )}
    </div>
  );
};
