import React, { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { games } from '../../../constants/relaxRoom';
import PlayGame from './PlayGame/PlayGame';
import ModalWrapper from '../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../components/ModalConstructor/ModalHeader/ModalHeader';
import checkersImg from '../../../images/gameCovers/checkers.png';
import reversiImg from '../../../images/gameCovers/reversi.png';
import chessImg from '../../../images/gameCovers/chess.png';
import './GamesModal.scss';
import useWindowDimensions from '../../../hooks/widthHook';

interface Props {
  setGamesModal: Dispatch<SetStateAction<boolean>>;
}

interface SelectedGame {
  gameId: number | string;
  gameName: string;
}

function GamesModal({ setGamesModal }: Props) {
  const [playGame, setPlayGame] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<SelectedGame>({
    gameId: 0,
    gameName: '',
  });
  const { formatMessage } = useIntl();

  const getCoverForGame = (id: string) => {
    let srcImg = '';
    if (id === 'reversi') srcImg = reversiImg;
    if (id === 'chess') srcImg = chessImg;
    if (id === 'checkers') srcImg = checkersImg;

    return (
      <img
        className="Game__preview"
        src={srcImg}
        width={235}
        height={148}
        alt={id}
      />
    );
  };

  const { width } = useWindowDimensions();

  return (
    <ModalWrapper higher={width < 1100 && playGame ? true : false}>
      <ModalContainer
        styles={{
          backgroundColor: '#FBF5FF',
          borderRadius: '12px',
          padding: '0',
          width: width < 1100 ? (playGame ? '100vw' : '325px') : '1080px',
        }}
      >
        {!playGame ? (
          <>
            <ModalHeader
              mainColor
              withPadding
              textHeader={formatMessage({ id: 'gamesTitle' })}
              onClose={() => setGamesModal(false)}
            />
            <div className="GamesModal">
              <div className="GamesModal__container">
                {games.map((game: Game) => (
                  <button
                    key={game.id}
                    className="Game__container"
                    type="button"
                    onClick={() => {
                      setSelectedGame({
                        gameId: game.id,
                        gameName: game.name,
                      });
                      setPlayGame(true);
                    }}
                  >
                    {typeof game.id === 'number' ? (
                      <video
                        className="Game__preview"
                        onMouseOver={(e: MouseEvent<HTMLVideoElement>) =>
                          e.currentTarget.play()
                        }
                        onMouseOut={(e: MouseEvent<HTMLVideoElement>) =>
                          e.currentTarget.pause()
                        }
                        muted
                        loop
                        playsInline
                        src={game.preview}
                      />
                    ) : (
                      <>{getCoverForGame(game.id)}</>
                    )}
                    <p className="Game__name">{game.name}</p>
                  </button>
                ))}
              </div>
            </div>
          </>
        ) : (
          <PlayGame
            gameId={selectedGame.gameId}
            gameName={selectedGame.gameName}
            setPlayGame={setPlayGame}
            setGamesModal={setGamesModal}
          />
        )}
      </ModalContainer>
    </ModalWrapper>
  );
}

export default GamesModal;
