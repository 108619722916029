import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { updateUserPrivacy } from '../../../api/user';
import ErrorBoundary from '../../../components/ErrorBoundary';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import CheckBoxLabel from '../../../components/shared/CheckBoxLabel/CheckBoxLabel';
import { setAccountSettings } from '../../../redux/slices/profileSlice';
import { RootState } from '../../../redux/store';
import useWindowDimensions from '../../../hooks/widthHook';
import './PrivacySettings.scss';

export const PrivacySettings = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { privacy } = useSelector((state: RootState) => state.profile);

  const [showFollowers, setShowFollowers] = useState(privacy.showFollowers);
  const [showFollowing, setShowFollowing] = useState(privacy.showFollowing);
  const [showInterested, setShowInterested] = useState(
    privacy.showInterestedTags,
  );

  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <div className="accountSettings">
      <div className="accountSettings-title privacy">
        {formatMessage({ id: 'privacy' })}
      </div>
      <p className="accountSettings-subtitle privacySettings-subtitle">
        {formatMessage({ id: 'privacySubtitle' })}
      </p>
      <ErrorBoundary>
        <div
          className="accountSettings-list privacySettings-list"
          style={{
            alignItems: lan === 'ar' ? 'flex-end' : 'flex-start'
          }}
        >
          <CheckBoxLabel
            label={formatMessage({ id: 'showFollowers' })}
            setValue={setShowFollowers}
            defaultChecked={privacy.showFollowers}
          />
          <CheckBoxLabel
            label={formatMessage({ id: 'showFollowing' })}
            setValue={setShowFollowing}
            defaultChecked={privacy.showFollowing}
          />
          <CheckBoxLabel
            label={formatMessage({ id: 'showInterests' })}
            setValue={setShowInterested}
            defaultChecked={privacy.showInterestedTags}
          />
        </div>
        <div className="accountSettings-footer">
          <MainButton
            text={formatMessage({ id: 'saveChanges' })}
            type="submit"
            styles={
              width < 1100
                ? {
                    width: '90%',
                    marginRight: '0',
                    position: 'absolute',
                    bottom: '110px',
                    left: '24px',
                  }
                : {}
            }
            onClick={() => {
              updateUserPrivacy({
                showFollowers,
                showFollowing,
                showInterested,
              }).then(res => {
                if (res.status === 200) {
                  toast.success(
                    formatMessage({ id: 'privacySettingsChanged' }),
                  );
                  dispatch(setAccountSettings(res.data));
                }
              });
            }}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};
