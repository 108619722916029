import React, { useEffect, useMemo, useState } from 'react'
import './GoToPrevPage.scss'
import { useNavigate } from 'react-router';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SecondaryButton from '../shared/Buttons/SecondaryButton/SecondaryButton';
import useWindowDimensions from '../../hooks/widthHook';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { popHistory } from '../../redux/slices/appSlice';

export const GoToPrevPage = () => {
    const navigate = useNavigate();

    const { history } = useSelector((state: RootState) => state.app);
    const dispatch = useDispatch<AppDispatch>();

    const width = useWindowDimensions().width;
    const isMobile = width < 1100

    const styles = isMobile ? {
        borderRadius: '50%',
        padding: "3px",
        marginRight: '10px'
    } : {
        padding: "12px 4px"
    }

    const handleGoBack = () => {
        const arr = [...history];
        arr.pop()

        const prevPage =  arr.pop() || "/home";
        navigate(prevPage);
    
        dispatch(popHistory(window.location.origin + prevPage))
    }

    return (
        <SecondaryButton
            onClick={handleGoBack}
            iconLeft
            text=''
            styles={styles}
            icon={<ArrowBackIosNewIcon style={{ color: "#791186" }} />}
        />
    )
}
