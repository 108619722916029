import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

type Props = {
  onClick: () => void;
  isVisible: boolean;
};

const ShowPassword = ({ onClick, isVisible }: Props) => {
  return (
    <>
      <InputAdornment position="end">
        <IconButton aria-label="toggle password visibility" onClick={onClick}>
          {isVisible ? (
            <VisibilityOutlinedIcon />
          ) : (
            <VisibilityOffOutlinedIcon />
          )}
        </IconButton>
      </InputAdornment>
    </>
  );
};

export default ShowPassword;
