import React, { useState } from 'react';
import classNames from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import { useIntl } from 'react-intl';

type Props = {
  chatName: string,
  setChatName: (arg: string) => void
}

export default function ChatListSearch({chatName, setChatName}: Props) {
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';

  const [openInput, setOpenInput] = useState(false);
  const [canBeClose, setCanBeClose] = useState(true);

  return (
    <div
      onClick={() => setOpenInput(true)}
      onMouseEnter={() => setOpenInput(true)}
      onMouseLeave={() => {
        if (!chatName && canBeClose) {
          setOpenInput(false);
        } 
      }}
      className={classNames("ChatList__chat-search", {
        "ChatList__chat-search--active": openInput,
        "ChatList__chat-search--ar": lan === 'ar',
      })}
      onFocus={() => setCanBeClose(false)}
      onBlur={() => {
        setCanBeClose(true);
        if (!chatName) {
          setOpenInput(false);
        }
      }}
    >
      <SearchIcon
        className="searchInputIcon"
        style={{
          fontSize: 20,
          color: '#6C757D'
        }}
      />
      <input
        placeholder={formatMessage({ id: 'searchChat' })}
        type="text"
        value={chatName}
        onChange={(e) => setChatName(e.currentTarget.value)}
      />
    </div>
  )
}
