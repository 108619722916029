import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../../../redux/store';
// import {
//   hideLoader,
//   showLoader,
//   setSignUpStep,
// } from '../../../../redux/slices/appSlice';
import {
  checkExistUser,
  setEmail,
  setPassword,
  setResponseMessage,
} from '../../../../redux/slices/authSlice';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import ShowPassword from '../../../../components/shared/CustomInput/ShowPassword/ShowPassword';
import './SignUpForm.scss';
import { Link } from 'react-router-dom';

type Props = {
  onSubmitSignUp?: (email: string, password: string) => void;
};

export const SignUpForm: React.FC<Props> = ({ onSubmitSignUp = () => {} }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const lan = localStorage.getItem('lang') || 'en';

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid, touchedFields },
  } = useForm({ mode: 'all' });

  const { responseMessage } = useSelector((state: RootState) => state.auth);

  const [registerEmail, setRegisterEmail] = useState('');
  const [confirmRegisterPassword, setConfirmRegisterPassword] = useState('');

  // show password
  const [isVisiblePassword, setVisibilityPassword] = useState(false);
  const [isVisibleConfirmPassword, setVisibilityConfirmPassword] =
    useState(false);

  const dataToSend = {
    email: registerEmail,
  };

  useEffect(() => {
    if (responseMessage) {
      toast.error(responseMessage);
      reset();
      setRegisterEmail('');
      setConfirmRegisterPassword('');
      dispatch(setResponseMessage(''));
    }
  }, [responseMessage]);

  function generatePassword(length = 12) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }

  // onHandleSubmit
  const onHandleSubmit = () => {
    const newPassword = generatePassword(10);
    onSubmitSignUp(registerEmail, newPassword);
  };

  return (
    <form className="authForm" onSubmit={handleSubmit(onHandleSubmit)}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="createAccountWrapper">
          <span className="createAccount">
            {formatMessage({ id: 'createAccountIn' })}
          </span>{' '}
          <span className="wizwork">
            <b>WizWork</b>
          </span>
        </div>
        <div style={{ marginTop: 16, textAlign: 'center' }}>
          <span>{formatMessage({ id: 'alreadyHaveAccount' })}</span>{' '}
          <b>
            <Link to="/sign-in">{formatMessage({ id: 'login' })}</Link>
          </b>
        </div>
        <div style={{ marginTop: 16, marginBottom: 20 }}>
          <CustomInput
            placeholder={formatMessage({ id: 'emailPlaceholder' })}
            label={formatMessage({ id: 'emailLabel' })}
            inputName="email"
            errors={errors}
            touchedFields={touchedFields}
            onChangeFunc={value => {
              setRegisterEmail(value);
              setValue('email', value);
            }}
            width="100%"
            inputProps={{
              ...register('email', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message:
                    responseMessage === ''
                      ? formatMessage({ id: 'incorrectEmail' })
                      : formatMessage({ id: 'emailAlreadyRegistered' }),
                },
              }),
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                textAlign: lan === 'ar' ? 'right' : 'left',
              },
            }}
          />
        </div>
        <MainButton
          text={formatMessage({ id: 'createAccount' })}
          type="submit"
          disabled={!isValid}
        />
      </div>
    </form>
  );
};

export default SignUpForm;
