import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from '../../redux/store';
import useWindowDimensions from '../../hooks/widthHook';
import Header from '../../components/Header/Header';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import ErrorBoundary from '../../components/ErrorBoundary';
import AccountSettingsForm from './AccountSettingsForm/AccountSettingsForm';
import { DeactivateModal } from './DeactivateSettings/DeactivateModal/DeactivateModal';
import { LanguageSettings } from './LanguageSettings/LanguageSettings';
import { NotificationSettings } from './NotificationSettings/NotificationSettings';
import { PasswordSettings } from './PasswordSettings/PasswordSettings';
import { PrivacySettings } from './PrivacySettings/PrivacySettings';
import { DeactivateSettings } from './DeactivateSettings/DeactivateSettings';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './AccountSettingsPage.scss';

const AccountSettingsPage = () => {
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState('general');
  const [isSelected, setIsSelected] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useSelector((state: RootState) => state.profile);

  const routes = [
    {
      name: formatMessage({ id: 'account' }),
      tab: 'general',
      element: <AccountSettingsForm />,
    },
    {
      name: formatMessage({ id: 'passwordLabel' }),
      tab: 'password',
      element: <PasswordSettings />,
    },
    {
      name: formatMessage({ id: 'privacy' }),
      tab: 'privacy',
      element: <PrivacySettings />,
    },
    {
      name: formatMessage({ id: 'notification' }),
      tab: 'notification',
      element: <NotificationSettings />,
    },
    {
      name: formatMessage({ id: 'language' }),
      tab: 'language',
      element: <LanguageSettings />,
    },
    {
      name: formatMessage({ id: 'deactivateAccount' }),
      tab: 'deactivate',
      element: <DeactivateSettings />,
    },
  ];

  const adminRoutes = routes.filter((route: any) => route.tab !== 'language');

  return (
    <div
      className="bg-container"
      style={
        {
          // overflowY: selectedTab === 'general' ? 'visible' : 'hidden',
          // maxHeight: selectedTab === 'general' ? '100vh' : '90vh',
          // overflowX: 'hidden'
        }
      }
    >
      <Header />
      {/* {width > 1100 && (
          <div className="onboarding-header">

            <img src={logo} alt="logo" />
            <img src={wizwork} alt="logo-text" />
          </div>
        )} */}
      <div className="page-container">
        <div className="accountSettings-page">
          {width > 1100 && (
            <>
              <h2 className="accountSettings-page__title">
                {formatMessage({ id: 'settingsNprivacy' })}
              </h2>
              <div className="accountSettings-page__subtitle">
                {formatMessage({ id: 'changeProfileAndSettings' })}
              </div>
            </>
          )}

          <ErrorBoundary>
            {width < 1100 ? (
              <div className="accountSettings-page__inner">
                <div
                  className="accountSettings-page__container"
                  style={{
                    left:
                      isSelected || selectedTab === 'deactivate'
                        ? '0'
                        : '-100%',
                  }}
                >
                  <h2 className="accountSettings-page__mobile-title">
                    {formatMessage({ id: 'settingsNprivacy' })}
                  </h2>
                  <Sidebar
                    mobile={width < 1100}
                    routes={isAdmin ? adminRoutes : routes}
                    setShowModal={setShowModal}
                    selectedTab={''}
                    setSelectedTab={setSelectedTab}
                    setIsSelected={setIsSelected}
                  />
                </div>
                <div className="accountSettings-page__wrap">
                  {selectedTab === 'deactivate' ? (
                    <DeactivateModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  ) : (
                    <>
                      <button
                        className="accountSettings-page__button-back"
                        onClick={() => setIsSelected(true)}
                      >
                        <div className="accountSettings-page__back-icon">
                          <KeyboardBackspaceIcon />
                        </div>
                        <span className="accountSettings-page__button-back-text">
                          {routes.find(item => item.tab === selectedTab)?.name}
                        </span>
                      </button>
                      {routes.find(item => item.tab === selectedTab)?.element}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="accountSettings-page__inner">
                <Sidebar
                  routes={isAdmin ? adminRoutes : routes}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
                {routes.find(item => item.tab === selectedTab)?.element}
              </div>
            )}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsPage;
