import React from 'react';
import useWindowDimensions from '../../../hooks/widthHook';
import './EmailTooltip.scss';
import { useIntl } from 'react-intl';

const EmailTooltip = () => {
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();
  return (
    <>
      <div
        className={
          width >= 1100 ? 'tooltip-email-right' : 'tooltip-email-bottom'
        }
      >
        {width < 1100 && (
          <div className="infoIconWrapper">
            <svg
              className="infoIcon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33337 11.333H8.66671V7.33301H7.33337V11.333ZM8.00004 1.33301C4.32004 1.33301 1.33337 4.31967 1.33337 7.99967C1.33337 11.6797 4.32004 14.6663 8.00004 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8.00004 1.33301ZM8.00004 13.333C5.06004 13.333 2.66671 10.9397 2.66671 7.99967C2.66671 5.05967 5.06004 2.66634 8.00004 2.66634C10.94 2.66634 13.3334 5.05967 13.3334 7.99967C13.3334 10.9397 10.94 13.333 8.00004 13.333ZM7.33337 5.99967H8.66671V4.66634H7.33337V5.99967Z"
                fill="#363636"
              />
            </svg>
          </div>
        )}
        <p style={{ fontWeight: 600 }}>{formatMessage({ id: 'noEmail' })}</p>
        <p className="infoTooltipText">
          {formatMessage({ id: 'incorrectEmail' })}
          <br />
          {formatMessage({ id: 'tryEmail' })}
        </p>
      </div>
    </>
  );
};

export default EmailTooltip;
