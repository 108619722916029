export const detectLang = (text: string) => {
  switch (text) {
    case 'en':
      return 'eng_Latn';
    case 'ar':
      return 'arb_Arab';
    case 'es':
      return 'spa_Latn';
    case 'fr':
      return 'fra_Latn';
    case 'hi':
      return 'hin_Deva';
    case 'pt':
      return 'por_Latn';
    case 'sw':
      return 'swh_Latn';
  }
};