import React, { CSSProperties } from 'react';
import './SecondaryButton.scss';

interface Props {
  text: string;
  icon?: JSX.Element;
  iconLeft?: boolean;
  iconRight?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  onClick?: () => void;
  bigPadding?: boolean;
  smallPadding?: boolean;
  styles?: CSSProperties;
}

const SecondaryButton = ({
  text,
  icon,
  iconLeft,
  iconRight,
  disabled,
  type = 'button',
  onClick,
  bigPadding,
  smallPadding,
  styles,
}: Props) => {
  const classes = `secondaryButton${bigPadding ?
    ' secondaryButton--bigPadding' : ''}${smallPadding ?
      ' secondaryButton--smallPadding' : ''}`;

  return (
    <button
      className={classes}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={styles}
    >
      {icon && iconLeft && icon}
      {text}
      {icon && iconRight && icon}
    </button>
  );
};

export default SecondaryButton;