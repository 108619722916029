import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import CustomInput from '../CustomInput/CustomInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Select.scss';
interface Props {
  options: any; // ['', '', '']
  onChange: (label: any) => void;
  value?: string | null;
  label?: string;
  width?: number | string;
  placeholder?: string;
  inputName: string;

  validation?: {
    errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
    touchedFields: { [x: string]: any; };
    register: UseFormRegister<FieldValues>;
    setError: UseFormSetError<FieldValues>;
    clearErrors: UseFormClearErrors<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
  }
}

const Select = ({
  options,
  label,
  width,
  placeholder,
  onChange,
  value,
  inputName,
  validation,
}: Props) => {
  const { formatMessage } = useIntl();
  const [touchedInput, setTouchedInput] = useState(false);
  const lan = localStorage.getItem('lang') || 'en';

  return (
    <div
      className={`select 
      ${validation?.errors !== undefined && Object.keys(validation?.errors)?.includes(inputName)
          ? 'select--errors'
          : ''
        }
      ${validation?.errors !== undefined && !Object.keys(validation?.errors)?.includes(inputName) && touchedInput
          ? 'select--noErrors'
          : ''
        }`}
      onBlur={() => value ? setTouchedInput(true) : validation?.setError(inputName, { type: 'required', message: formatMessage({ id: 'fieldIsEmpty' }) })}
    >
      <Autocomplete
        value={value}
        id="customSelect"
        popupIcon={<ExpandMoreIcon />}
        options={options}
        ChipProps={{
          style: {
            fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
            display: 'flex',
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
          }
        }}
        onChange={(e, value) => {
          onChange(value);
          if (value) {
            validation?.setValue(inputName, value);
            validation?.clearErrors(inputName);

            // if (inputName === 'country') {
            //   validation?.setValue('region', null);
            //   validation?.setError('region', { type: 'required', message: formatMessage({ id: 'fieldIsEmpty' }) });
            // }
          } else {
            validation?.setValue(inputName, '');
            validation?.setError(inputName, { type: 'required', message: formatMessage({ id: 'fieldIsEmpty' }) });

            // if (inputName === 'country') {
            //   validation?.setValue('region', null);
            //   validation?.setError('region', { type: 'required', message: formatMessage({ id: 'fieldIsEmpty' }) });
            // }
          }
        }}
        renderInput={(params) =>
          <CustomInput
            {...params}
            label={label}
            placeholder={placeholder}
            width={width}
            errors={validation?.errors}
            inputName={inputName}
            touchedFields={!touchedInput ? validation?.touchedFields : { ...validation?.touchedFields, [inputName]: true }}
            inputProps={
              {
                ...validation?.register(inputName, {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                }),
                ...params.inputProps,
                style: {
                  fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  textAlign: lan === 'ar' ? 'right' : 'left'
                }
              }
            }
          />}
      />
    </div>
  );
};

export default Select;
