import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getIcon } from '../helpers/getIcon';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import useWindowDimensions from '../../hooks/widthHook';
import gears from '../../images/gears.png';
import { setSignUpSettingModal } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import './SignUpSettingModal.scss';

function SignUpSettingModal() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useSelector((state: RootState) => state.profile);

  const { width } = useWindowDimensions();
  const iconWidth = width > 1100 ? '56' : '32';
  const modalWidth = width > 1100 ? '698px' : '327px';

  return (
    <ModalWrapper width={modalWidth}>
      <ModalContainer congrats>
        <ModalHeader textHeader='' smallCross onClose={() => dispatch(setSignUpSettingModal(false))}></ModalHeader>
        <div className="signUp-mail">
          {getIcon(gears, 'congrats!', iconWidth)}
          {language === 'ar' ?
            <>
              <h3 className="signUp-mail__title" dir="rtl">
                {formatMessage({ id: 'modalSettingsHeader' })}
              </h3>
              <MainButton
                type="button"
                text={formatMessage({ id: 'clickHere' })}
                onClick={() => {
                  dispatch(setSignUpSettingModal(false))
                  navigate('/account-settings')
                }}
                styles={{marginBottom: '20px'}}
              />
              <p className="signUp-mail__subtitle" dir="rtl">
                {formatMessage({ id: 'modalSettingsText' })}
              </p>
            </> :
            <>
              <h3 className="signUp-mail__title">
                  {formatMessage({ id: 'modalSettingsHeader' })}
              </h3>
              <MainButton
                type="button"
                text={formatMessage({ id: 'clickHere' })}
                onClick={() => {
                  dispatch(setSignUpSettingModal(false))
                  navigate('/account-settings')
                }}
                styles={{marginBottom: '20px'}}
              />
              <p className="signUp-mail__subtitle">
                {formatMessage({ id: 'modalSettingsText' })}
              </p>
            </>
          }
          {/* <ModalFooter>
            <MainButton
              type="button"
              text={formatMessage({ id: 'ok' })}
              onClick={() => dispatch(setSignUpSettingModal(false))}
            />
          </ModalFooter> */}
        </div>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default SignUpSettingModal;
