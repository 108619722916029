import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../../redux/store';
import useWindowDimensions from '../../../hooks/widthHook';
import heart from '../../../images/icon_heart-fill.svg';
import earth from '../../../images/icon_earth.svg';
import flag from '../../../images/icon_flag.svg';
import icon_check from '../../../images/icon-check.svg';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { SearchAccordion } from './SearchAccordion/SearchAccordion';
import SearchChosenItem from './SearchChosenItem/SearchChosenItem';
import SecondaryButton from '../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import {
  searchUsers,
  setSearchedCountry,
  setSearchedInterests,
  setFoundedUsers
} from '../../../redux/slices/someUserSlice';
import './SearchNavSide.scss';

interface Props {
  setNoFoundedUsers?: Dispatch<SetStateAction<boolean>>;
}

const SearchNavSide = ({ setNoFoundedUsers = () => { } }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const ref = useRef<any>();
  const { allInterests } = useSelector((state: RootState) => state.app);
  const { width } = useWindowDimensions();

  const interestsDetailsArr = allInterests
    ?.filter((int: Chip) => int.type === 'DEFAULT' && !int.isArchived)
    ?.map((interest: Chip) => ({
      id: interest.id,
      title: interest.title,
      url:  interest.avatar || interest.url,
      type: interest.type,
    }));
  const regionsDetailsArr = allInterests
    ?.filter((int: Chip) => int.type === 'REGIONAL' && !int.isArchived)
    ?.map((region: Chip) => ({
      id: region.id,
      title: region.title,
      url: region.avatar || region.url,
      type: region.type,
    }));

  const interestsData = {
    id: 1,
    title: formatMessage({ id: 'interestsAreas' }),
    img: heart,
    details: interestsDetailsArr,
  };

  const regionsData = {
    id: 2,
    title: formatMessage({ id: 'regions' }),
    img: earth,
    details: regionsDetailsArr,
  };

  const countriesData = {
    id: 3,
    title: formatMessage({ id: 'countries' }),
    img: flag,
  };

  const [chosenFilteredInterestList, setChosenFilteredInterestList] = useState<
    Array<any>
  >([]);
  const [chosenInterestList, setChosenInterestList] = useState<Array<any>>([]);

  useEffect(() => {
    setChosenFilteredInterestList(
      allInterests.filter(int => chosenInterestList.includes(+int.id)),
    );
  }, [chosenInterestList]);

  const [chosenFilteredRegionsList, setChosenFilteredRegionsList] = useState<
    Array<any>
  >([]);
  const [chosenRegionsList, setChosenRegionsList] = useState<Array<any>>([]);

  useEffect(() => {
    setChosenFilteredRegionsList(
      allInterests.filter(int => chosenRegionsList.includes(+int.id)),
    );
  }, [chosenRegionsList]);

  const [chosenCountry, setChosenCountry] = useState(null);

  useEffect(() => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
    ref.current = setTimeout(() => {
      const intIds = chosenFilteredInterestList.map(int => int.id);
      const regIds = chosenFilteredRegionsList.map(int => int.id);
      const allIds = [...intIds, ...regIds];
      const querySettings: UserSearch = {};
      if (allIds.length > 0 && allIds !== undefined) {
        querySettings.interests = allIds;
      }
      if (chosenCountry !== null) {
        querySettings.country = chosenCountry;
      }
      dispatch(setSearchedInterests(allIds));
      dispatch(setSearchedCountry(chosenCountry));

      // console.log('Object.keys(querySettings)', Object.keys(querySettings))
      if (Object.keys(querySettings).length) {
        dispatch(searchUsers(querySettings));
        setNoFoundedUsers(false);
      } else {
        setNoFoundedUsers(true);
      }
    }, 300)

  }, [chosenCountry, chosenFilteredInterestList, chosenFilteredRegionsList]);

  const [reset, setReset] = useState(false);
  function handleClear() {
    setReset(prev => !prev);
    setChosenInterestList([]);
    setChosenRegionsList([]);
    setChosenCountry(null);
    dispatch(setSearchedInterests([]));
    dispatch(setSearchedCountry(null));
    dispatch(setFoundedUsers([]));
  };

  // console.log('chosenCountry', chosenCountry)
  return (
    <nav className="search-nav-side" key={`${reset}`}>
      <ErrorBoundary>
        <section className="search-nav-side__section">
          <p className="search-nav-side__section-title">{formatMessage({ id: 'youChose' })}:</p>
          {!!chosenFilteredInterestList.length && (
            <ul className="search-nav-side__section-list">
              {chosenFilteredInterestList?.map((item, index) => (
                <SearchChosenItem item={item} key={index} />
              ))}
            </ul>
          )}
          {!!chosenFilteredRegionsList.length && (
            <ul className="search-nav-side__section-list">
              {chosenFilteredRegionsList?.map((item, index) => (
                <SearchChosenItem item={item} key={index} />
              ))}
            </ul>
          )}
          {chosenCountry && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 16,
                marginBottom: 8,
              }}
            >
              <img
                className="search-nav-side__section-checkicon"
                src={icon_check}
                alt="check"
              />
              <span className="search-nav-side__section-text">
                {chosenCountry}
              </span>
            </div>
          )}
          <SecondaryButton
            text={formatMessage({ id: 'reset' })}
            onClick={handleClear}
            bigPadding
            styles={{
              width: (width >= 1100 && width < 1440) ? 245 : 300,
              marginLeft: "auto",
              marginRight: "auto"
            }}
          />
        </section>
        <section className="search-nav-side__section">
          <h2 className="search-nav-side__title">
            {formatMessage({ id: 'reset' })}
          </h2>
          <SearchAccordion
            inpData={interestsData}
            setChosenInterestList={setChosenInterestList}
            reset={reset} 
          />
          <SearchAccordion
            inpData={regionsData}
            setChosenRegionsList={setChosenRegionsList}
            reset={reset}
          />
          <SearchAccordion
            inpData={countriesData}
            countries={true}
            setChosenCountry={setChosenCountry}
          />
        </section>
      </ErrorBoundary>
    </nav>
  );
};

export default SearchNavSide;
