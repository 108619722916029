import React from 'react';
import { MyFeed } from '../Wall/MyFeed/MyFeed';

const SavedPosts = () => {
  
  return (
      <MyFeed type='saved' />
  )
};

export default SavedPosts;