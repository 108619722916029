import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { reportUser } from '../../../api/someUser'
import { BlockUserById, getBlockedUsersAPI } from '../../../api/user'
import { setBlockedUsers } from '../../../redux/slices/profileSlice'
import ModalContainer from '../../ModalConstructor/ModalContainer/ModalContainer'
import ModalFooter from '../../ModalConstructor/ModalFooter/ModalFooter'
import ModalHeader from '../../ModalConstructor/ModalHeader/ModalHeader'
import ModalWrapper from '../../ModalConstructor/ModalWrapper/ModalWrapper'
import MainButton from '../Buttons/MainButton/MainButton'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton'
import CheckBoxLabel from '../CheckBoxLabel/CheckBoxLabel';
import CustomInput from '../CustomInput/CustomInput'
import './ReportBlockModal.scss'

type Props = {
    someUserId?: string;
    setReportModal: (value: boolean) => void;
}


export const ReportBlockModal = ({ someUserId, setReportModal }: Props) => {
    const dispatch = useDispatch();

    const { formatMessage } = useIntl();
    const [checkReport, setCheckReport] = useState(false);
    const [checkBlock, setCheckBlock] = useState(false);
    const [reportReason, setReportReason] = useState('');
    const lan = localStorage.getItem('lang') || 'en';

    const sendReportForm = () => {
        if (someUserId && checkBlock) {
            BlockUserById({ userId: someUserId }).then(() => {
                getBlockedUsersAPI().then(res => {
                    if (res.status = 200) {
                        dispatch(setBlockedUsers((res.data)))
                    }
                }
                );
            })
        }

        if (checkReport && someUserId) {
            reportUser({ userId: someUserId || '', reason: reportReason })
        }

        setReportModal(false);
    }

    return (
        <ModalWrapper width={350}>
            <ModalContainer>
                <ModalHeader
                    textHeader={formatMessage({ id: 'whatAction' })}
                    smallCross
                    onClose={() => setReportModal(false)}
                />

                <div className='label-rows'>
                    <div>
                        <CheckBoxLabel
                            label={formatMessage({ id: 'report' })}
                            setValue={() => {
                                setCheckReport(!checkReport);
                            }}
                        />

                        {checkReport && (
                            <CustomInput
                                width="100%"
                                placeholder={formatMessage({ id: 'reportReason' })}
                                label={formatMessage({ id: 'reportReasonLabel' })}
                                onChangeFunc={(value: string) => {
                                    setReportReason(value);
                                }}
                                inputProps={{
                                    style: {
                                        fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                                        textAlign: lan === 'ar' ? 'right' : 'left'
                                    }
                                }}
                            />
                        )}
                    </div>

                    <CheckBoxLabel
                        label={formatMessage({ id: 'block' })}
                        setValue={() => {
                            setCheckBlock(!checkBlock);
                        }}
                    />
                </div>

                <ModalFooter>
                    <SecondaryButton
                        text={formatMessage({ id: 'cancel' })}
                        onClick={() => setReportModal(false)}
                        bigPadding
                    />
                    <MainButton
                        text={formatMessage({ id: 'submit' })}
                        onClick={sendReportForm}
                        bigPadding
                        disabled={!((checkReport && reportReason !== "") || (checkReport && checkBlock) ? reportReason !== "" : checkBlock)}
                    />
                </ModalFooter>
            </ModalContainer>
        </ModalWrapper>
    )
}
