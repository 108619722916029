export const getTitleTypeOfPost = (typeOfPost: string) => {
  switch (typeOfPost) {
    case 'CALLS_TO_ACTION':
      return 'callToAction';
    case 'PETITIONS':
      return 'petition';
    case 'EVENTS':
      return 'advocacyEvent';
    case 'SURVEY/POLLS':
      return 'surveyPoll';
    default:
      return `${typeOfPost}`;
  }
};
