import { FormControlLabel } from '@mui/material';
import React, { ReactNode, useRef, useState } from 'react';
import { CheckBox } from '../CheckBox/CheckBox';

interface Props {
  label: string | ReactNode;
  setValue: (value: boolean) => void;
  defaultChecked?: boolean;
  // onChange?: (...event: any[]) => void;
  // ref?: any;
}

const CheckBoxLabel = ({ label, defaultChecked, setValue }: Props) => {
  const [checked, setChecked] = useState(defaultChecked);

  const lan = localStorage.getItem('lang') || 'en';

  return (
    <FormControlLabel
      control={
        <CheckBox
          defaultChecked={defaultChecked}
          onChange={() => {
            setChecked(!checked);
            setValue(!checked);
          }}
        />}
      label={label}
      sx={{
        flexDirection: lan === 'ar' ? 'row-reverse' : 'row',

        background: checked ? '#FBF5FF' : '',
        padding: lan === 'ar' ? '0 5px 0 14px' : '0 14px 0 5px',
        borderRadius: '8px',
        margin: 0,
       
        ".MuiFormControlLabel-label": {
          fontSize: '14px !important',
          fontFamily: 'inherit'
        },
        "&.MuiFormControlLabel-root:hover": {
          color: "#791186",
          background: '#FBF5FF',
          transition: 'all .3s',
        },
        "&.MuiFormControlLabel-root>span.Mui-checked + span": {
          color: "#791186",
          fontWeight: 600,
        },
        "&.MuiFormControlLabel-root:hover svg": {
          color: "#791186",
        }
      }}
    />
  );
};

export default CheckBoxLabel;

