import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOnRelaxRoom } from '../../redux/slices/relaxSlice';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import relaxRoom from '../../images/relax-room.png';
import './Aside.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import AdsEditModal from '../AdsEditModal/AdsEditModal';
import { addBrTagInLine } from '../helpers/parseLineBreak';
import { processTextInLink } from '../helpers/linksProcessing';
import { counterAdsAPI } from '../../api/user';

export const Aside = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch();
  const { ads } = useSelector((state: RootState) => state.app);
  const { isAdmin, ads: showAds, language } = useSelector((state: RootState) => state.profile);
  const [showAdsModal, setShowAdsModal] = useState(false);

  return (
    <aside className='aside'>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        replace={false}
        to="/relax-room"
        onClick={() => dispatch(setOnRelaxRoom(true))}
      >
        <img src={relaxRoom} alt="relax-room" />
      </Link>

      {showAds && ((ads.length > 0 && ads?.filter((post: any) => post.isDisabled !== true)?.length) || isAdmin) && (
        <div className="aside__section">
          <div className="aside__section-main-title-container">
            {isAdmin ? (
              <div className="aside__section-main-btn" onClick={() => { setShowAdsModal(true) }}>
                <h2 className="aside__section-main-title">
                  Ads Settings
                </h2>

                <EditTwoToneIcon />
              </div>
            ) : (
              <h2 className="aside__section-main-title">
                {formatMessage({ id: 'youMightBeInterested' })}
              </h2>
            )}
          </div>

          <div className="aside__section-news-container">
            {showAds && ads.length > 0 && ads
              ?.filter((post: any) => post.isDisabled !== true && post.addedLanguages.find((item: any) => item.code === language))
              ?.map((post: any, index: number) => (
                <a
                  href={post.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="aside__section-news-card"
                  key={index}
                  onClick={() => counterAdsAPI({adsId: post.id})}
                >
                  <h2 className="aside__section-title">
                    {post.addedLanguages.find((item: any) => item.code === language)?.title}
                  </h2>
                  <div className="aside__section-poster-container">
                    <img className="aside__section-news-poster" src={post.img} alt="" />
                  </div>

                  <p className="aside__section-details">
                    {
                      processTextInLink(addBrTagInLine(
                        post.addedLanguages.find((item: any) => item.code === language)?.descr
                      ))
                    }
                  </p>

                  <span
                    className="aside__section-link"
                  // href={post.url}
                  // target="_blank"
                  // rel="noopener noreferrer"
                  >
                    {post.url.slice(0, 25) + '...'}
                  </span>
                </a>
              ))}
          </div>
        </div>
      )}

      {showAdsModal && (
        <AdsEditModal close={() => { setShowAdsModal(false) }} />
      )}
    </aside>
  )
}
