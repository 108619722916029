import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Box, Tab, Tabs } from '@mui/material';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import Header from '../../components/Header/Header';
import { a11yProps } from '../../components/helpers/a11yProps';
import { NavSide } from '../../components/NavSide/NavSide';
import { ProfilesPageTab } from '../../components/ProfilesPageTab/ProfilesPageTab';
import { TabPanel } from '../../components/shared/TabPanel/TabPanel';
import { getAllUsersEmails } from '../../api/admin';
import './ProfilesPage.scss';

export const ProfilesPage = () => {
  let location = useLocation();
  const [value, setValue] = React.useState(0);
  const [usersArray, setUsersArray] = React.useState<any>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const downloadFile = async () => {
    const res = await getAllUsersEmails();
    if (res?.data?.length) {
      const csvData = res.data.map((user: any) => user?.email + '\n');

      const csvContent = csvData.join('');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = 'data.csv';
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setUsersArray([]);
      }, 0);
    };
  };

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className='main-page'>
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner">
                <NavSide />

                <div className="profiles-page">
                  <div className="my-feed">
                    <div className={`ActivityLogPage__section${location.pathname === '/activity-log' ?
                      ' ActivityLogPage__section--notofications' : ''}`}>
                      <div className={`ActivityLogPage__links ActivityLogPage__links--profiles`}>
                        <div className="export-email">
                          <div
                            className="mainButton"
                            onClick={downloadFile}
                          >
                            Export users emails
                          </div>
                        </div>
                        <Box sx={{ width: '100%' }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                          >
                            <Tab label="Reported On" {...a11yProps(0)} />
                            <Tab label="Disable Requests" {...a11yProps(1)} />
                          </Tabs>
                        </Box>
                        <TabPanel
                          value={value}
                          index={0}
                          type="reportedOn"
                        >
                          <ProfilesPageTab tab={'reportedOn'} />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                          <ProfilesPageTab tab={'disabledRequest'} />
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </div>

                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
}
