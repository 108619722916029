import React from 'react';
import { Wall } from '../../components/Wall/Wall';
import Header from '../../components/Header/Header';
import { Aside } from '../../components/Aside/Aside';
import { NavSide } from '../../components/NavSide/NavSide';
import ErrorBoundary from '../../components/ErrorBoundary';
import './MyProfilePage.scss';
import './MainContainer.scss';

const MyProfilePage = () => {

  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className='main-page'>
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner">
                <NavSide />
                <Wall />
                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MyProfilePage;


