import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { RootState } from '../../../redux/store';
import { setAccountSettings } from '../../../redux/slices/profileSlice';
import {
  getAds,
  updateUserEmail,
  updateUserOnboardingData,
} from '../../../api/user';
import useWindowDimensions from '../../../hooks/widthHook';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Select from '../../../components/shared/Select/Select';
import TextArea from '../../../components/shared/TextArea/TextArea';
import AvatarChip from '../../../components/shared/AvatarChip/AvatarChip';
import CustomChip from '../../../components/shared/CustomChip/CustomChip';
import CustomInput from '../../../components/shared/CustomInput/CustomInput';
import CheckBoxLabel from '../../../components/shared/CheckBoxLabel/CheckBoxLabel';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import { TextButton } from '../../../components/shared/Buttons/TextButton/TextButton';
import UploadButton from '../../../components/shared/Buttons/UploadButton/UploadButton';
import SecondaryButton from '../../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import ModalWrapper from '../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../../../components/ModalConstructor/ModalFooter/ModalFooter';
import { defaultAvatars } from '../../../constants/accountSettings';
import allCities from '../../../constants/allCities';
import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import './AccountSettingsForm.scss';
import { setAds, showLoader } from '../../../redux/slices/appSlice';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../../constants';
import { ImageCropModal } from '../../../components/ImageCropModal/ImageCropModal';

type CheckBoxesType = {
  forOrganization: boolean;
  otherTitle: boolean;
  showLocation: boolean;
  titles: string[];
};

type CountryItem = {
  country: string;
  cities: string[];
};

const countryToSelect = allCities
  .map((item: CountryItem) => item.country)
  .sort();

const AccountSettingsForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.profile);
  const { allInterests } = useSelector((state: RootState) => state.app);
  const { width } = useWindowDimensions();
  const lan = localStorage.getItem('lang') || 'en';

  const identifyList: string[] = [
    formatMessage({ id: 'title1' }),
    formatMessage({ id: 'title2' }),
    formatMessage({ id: 'title3' }),
    formatMessage({ id: 'title4' }),
    formatMessage({ id: 'title5' }),
    formatMessage({ id: 'title6' }),
    formatMessage({ id: 'title7' }),
  ];

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onBlur' });

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState(userData.email);
  const [organizationName, setOrganizationName] = useState(
    userData.organizationName,
  );
  const [otherTitle, setOtherTitle] = useState(userData.otherTitle);

  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);
  const [country, selectCountry] = useState(userData.country);
  const [region, selectRegion] = useState<string | null>(userData.region);
  const [aboutMe, setAboutMe] = useState(userData.aboutMe);
  const [checkBoxes, setCheckBoxes] = useState<CheckBoxesType>({
    forOrganization: userData.isOrganization,
    showLocation: userData.showLocation,
    otherTitle: !!userData.otherTitle,
    titles: userData.titles,
  });

  useEffect(() => {
    setEmail(userData.email);
    setOrganizationName(userData.organizationName);
    setOtherTitle(userData.otherTitle);
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    selectCountry(userData.country);
    selectRegion(userData.region);
    setAboutMe(userData.aboutMe);
    setCheckBoxes({
      forOrganization: userData.isOrganization,
      showLocation: userData.showLocation,
      otherTitle: !!userData.otherTitle,
      titles: userData.titles,
    });
    setSelectedChips(userData.interests);
    setUploadedAvatar(userData.profileAvatar);
    setUploadedBg(userData.profileBg);

    setValue('country', userData.country);
    setValue('region', userData.region);
    setValue('email', userData.email);
    if (userData.isOrganization) {
      setValue('organizationName', userData.organizationName);
      setValue('firstName', userData.firstName);
    } else {
      setValue('lastName', userData.lastName);
    }
  }, [userData]);

  const [selectedChips, setSelectedChips] = useState<SelectedChip[]>(
    userData.interests,
  );
  const [btnSelectChecked, SetBtnSelectChecked] = useState(false);

  const [exampleAvatar, setExampleAvatar] = useState('');

  const [uploadedAvatar, setUploadedAvatar] = useState(userData.profileAvatar);
  const [uploadedBg, setUploadedBg] = useState(userData.profileBg);

  const [tempAvatarFileData, setTempAvatarFileData] = useState<
    FormData | undefined
  >();
  const [tempBgFileData, setTempBgFileData] = useState<FormData | undefined>();

  const [uploadAvatarDragover, setUploadAvatarDragover] = useState(false);
  const [uploadBgDragover, setUploadBgDragover] = useState(false);
  const [uploadBgError, setUploadBgError] = useState('');

  // useEffect(() => {
  //   const currentCountry = allCities.find(
  //     (item: CountryItem) => item.country === country,
  //   );

  //   if (currentCountry !== undefined) {
  //     const newRegionToSelect = currentCountry.cities;
  //     setRegionToSelect(newRegionToSelect);

  //     if (!newRegionToSelect.includes(region + '')) {
  //       selectRegion(null);
  //     }
  //   } else {
  //     setRegionToSelect([]);
  //     selectRegion(null);
  //   }
  // }, [country]);

  const uploadImage = (files: FileList | null, imgType?: string) => {
    if (files === null) return;
    const tempFile = files[0];
    // Check is image max size
    const img = new Image();
    const _URL = window.URL || window.webkitURL;
    const objectUrl = _URL.createObjectURL(files[0]);
    // const maxWeight = imgType === 'bg' ? 1520 : 400;
    // const maxHeight = imgType === 'bg' ? 300 : 400;
    const maxSize = 5242880;
    img.src = objectUrl;

    if (files[0].size > maxSize) {
      // setUploadBgError('Please select an image not more than 5MB');
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }

    img.onload = function () {
      // if (img.height > maxHeight || img.width > maxWeight || (img.width < 1200 && imgType === 'bg')) {
      //   if (imgType === 'avatar') {
      //     if (maxWeight < img.width) {
      //       alert('Please select an image not more than 300 px wide');
      //     } else if (maxHeight < img.height) {
      //       alert('Please select an image not more than 300 px height');
      //     }
      //   }

      //   if (imgType === 'bg') {
      //     if (maxWeight < img.width) {
      //       setUploadBgError('Please select an image not more than 1520 px wide');
      //     } else if (img.width < 1200) {
      //       setUploadBgError('Please select an image at least 1200 px wide');
      //     } else if (maxHeight < img.height) {
      //       setUploadBgError('Please select an image not more than 300 px height');
      //     }
      //   }
      // } else {
      //   const formData = new FormData();
      //   formData.append('file', tempFile);
      //   formData.append('upload_preset', 'ml_default');

      //   if (imgType === 'avatar') {
      //     setTempAvatarFileData(formData);
      //     setExampleAvatar('');
      //     setUploadedAvatar(img.src);
      //   } else if (imgType === 'bg') {
      //     setTempBgFileData(formData);
      //     setUploadedBg(img.src);
      //     setUploadBgError('');
      //   }
      // }

      const formData = new FormData();
      formData.append('file', tempFile);
      formData.append('upload_preset', Cloudinary_PRESET);

      setCropModal({
        open: true,
        image: objectUrl,
        cropShape: imgType == 'bg' ? 'rect' : 'round',
        formData,
        imgType,
        onSave: (file = formData, url: any) => {
          if (imgType === 'avatar') {
            setTempAvatarFileData(file);
            setExampleAvatar('');
            setUploadedAvatar(url);
          } else if (imgType === 'bg') {
            setTempBgFileData(file);
            setUploadedBg(url);
            setUploadBgError('');
          }
        },
      });
    };
  };

  const onSubmit = async () => {
    sendRequestToBack();

    if (email !== userData.email) {
      setShowEmailModal(true);
    }
  };

  const [cropModal, setCropModal] = useState<any>({
    open: false,
    image: '',
    cropShape: 'rect',
    formData: '',
    imgType: 'bg',
    onSave: () => {},
  });

  const sendRequestToBack = async () => {
    dispatch(showLoader(true));
    let uploadedAvatarCloudly = uploadedAvatar;
    let uploadedBgCloudly = uploadedBg;

    if (tempAvatarFileData) {
      await axios
        .post(
          `https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`,
          tempAvatarFileData,
        )
        .then(response => {
          uploadedAvatarCloudly = response.data.secure_url;
        });
    }

    if (tempBgFileData) {
      await axios
        .post(
          `https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`,
          tempBgFileData,
        )
        .then(response => {
          uploadedBgCloudly = response.data.secure_url;
        });
    }

    //add other title to titles array
    // const newCheckBoxes = JSON.parse(JSON.stringify(checkBoxes));
    // if (checkBoxes.otherTitle && otherTitle) {
    //   newCheckBoxes.titles.push(otherTitle);
    // }

    const objToSend: any = {
      country,
      region,
      showLocation: checkBoxes.showLocation,
      about: aboutMe,
      otherTitle: checkBoxes.otherTitle ? otherTitle : '',

      titles: checkBoxes.titles.map(item => {
        if (item === formatMessage({ id: 'title1' })) {
          return 'title1';
        } else if (item === formatMessage({ id: 'title2' })) {
          return 'title2';
        } else if (item === formatMessage({ id: 'title3' })) {
          return 'title3';
        } else if (item === formatMessage({ id: 'title4' })) {
          return 'title4';
        } else if (item === formatMessage({ id: 'title5' })) {
          return 'title5';
        } else if (item === formatMessage({ id: 'title6' })) {
          return 'title6';
        } else if (item === formatMessage({ id: 'title7' })) {
          return 'title7';
        } else {
          return item;
        }
      }),

      interests: selectedChips.map(item => item.id),

      profileAvatar: uploadedAvatarCloudly || exampleAvatar,
      profileBg: uploadedBgCloudly,
    };

    if (checkBoxes.forOrganization) {
      objToSend.organizationName = organizationName;
    } else {
      objToSend.firstName = firstName;
      objToSend.lastName = lastName;
    }

    await updateUserOnboardingData(objToSend).then(res => {
      if (res.status === 200) {
        toast.success(formatMessage({ id: 'accountSettingsChanged' }));
        dispatch(setAccountSettings(res.data));
      }
    });

    const res = await getAds();
    if (res.status === 200) {
      dispatch(setAds(res.data));
    }

    dispatch(showLoader(false));
  };

  const changeOneCheckbox = (checkboxType: 'otherTitle' | 'showLocation') => {
    const newObj = JSON.parse(JSON.stringify(checkBoxes));
    newObj[checkboxType] = !checkBoxes[checkboxType];
    setCheckBoxes(newObj);
  };

  const changeCheckboxTitles = (label: string, checked?: boolean) => {
    const newObj = JSON.parse(JSON.stringify(checkBoxes));
    if (checked) {
      newObj.titles.push(label);
    } else {
      newObj.titles.splice(newObj.titles.indexOf(label, 0), 1);
    }
    setCheckBoxes(newObj);
  };

  return (
    <ErrorBoundary>
      {/* {width > 1100 ? : } */}
      <form className="accountSettings" onSubmit={handleSubmit(onSubmit)}>
        <div className="accountSettings-title">
          {formatMessage({ id: 'generalInfo' })}
        </div>

        {!checkBoxes.forOrganization ? (
          <div
            className="accountSettings-block"
            style={{
              flexDirection:
                width < 1100 ? 'column' : lan === 'ar' ? 'row-reverse' : 'row',
              alignItems: lan === 'ar' ? 'baseline' : 'flex-start',
              gap: lan === 'ar' ? '15px' : '0',
              width: width < 1100 ? '100%' : 'none',
            }}
          >
            <div
              className="accountSettings-block__wrap"
              style={{
                width: width < 1100 ? '100%' : 'none',
              }}
            >
              <CustomInput
                width={width > 1100 ? 270 : '100%'}
                placeholder={formatMessage({ id: 'firstNamePlaceholder' })}
                label={formatMessage({ id: 'firstNameLabel' })}
                touchedFields={touchedFields}
                inputName="firstName"
                errors={errors}
                onChangeFunc={value => {
                  setFirstName(value);
                  setValue('firstName', value);
                }}
                inputProps={{
                  ...register('firstName', {
                    required: formatMessage({ id: 'fieldIsEmpty' }),
                    maxLength: {
                      value: 30,
                      message: formatMessage({ id: 'nameMaxLength' }),
                    },
                  }),
                  value: firstName,
                  style: {
                    fontFamily:
                      lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                  },
                }}
              />
            </div>
            <div
              className="accountSettings-block__wrap"
              style={{
                width: width < 1100 ? '100%' : 'none',
              }}
            >
              <CustomInput
                width={width >= 1100 ? 270 : '100%'}
                placeholder={formatMessage({ id: 'lastNamePlaceholder' })}
                label={formatMessage({ id: 'lastNameLabel' })}
                touchedFields={touchedFields}
                inputName="lastName"
                errors={errors}
                onChangeFunc={value => {
                  setLastName(value);
                  setValue('lastName', value);
                }}
                inputProps={{
                  ...register('lastName', {
                    required: formatMessage({ id: 'fieldIsEmpty' }),
                    maxLength: {
                      value: 30,
                      message: formatMessage({ id: 'nameMaxLength' }),
                    },
                  }),
                  value: lastName,
                  style: {
                    fontFamily:
                      lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                  },
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="accountSettings-block"
            style={{
              justifyContent: lan === 'ar' ? 'flex-end' : 'flex-start',
            }}
          >
            <CustomInput
              width={width >= 1100 ? 270 : '100%'}
              placeholder={formatMessage({ id: 'organizationNamePlaceholder' })}
              label={formatMessage({ id: 'organizationNameLabel' })}
              touchedFields={touchedFields}
              inputName="organizationName"
              errors={errors}
              onChangeFunc={value => {
                setOrganizationName(value);
                setValue('organizationName', value);
              }}
              inputProps={{
                ...register('organizationName', {
                  required: formatMessage({ id: 'fieldIsEmpty' }),
                  maxLength: {
                    value: 30,
                    message: formatMessage({ id: 'nameMaxLength' }),
                  },
                }),
                value: organizationName,
                style: {
                  fontFamily:
                    lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                },
              }}
            />
          </div>
        )}

        <div
          className="accountSettings-block"
          style={{
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
          }}
        >
          <CustomInput
            width={width >= 1100 ? 270 : '100%'}
            placeholder={formatMessage({ id: 'emailPlaceholder' })}
            label={formatMessage({ id: 'emailLabel' }) + '*'}
            touchedFields={touchedFields}
            inputName="email"
            errors={errors}
            onChangeFunc={value => {
              setEmail(value);
              setValue('email', value);
            }}
            inputProps={{
              ...register('email', {
                required: formatMessage({ id: 'fieldIsEmpty' }),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: formatMessage({ id: 'invalidEmail' }),
                },
              }),
              value: email,
              style: {
                fontFamily: lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
              },
            }}
          />
        </div>

        <div className="accountSettings-row">
          <div
            className="accountSettings-block__selects"
            style={{
              flexDirection:
                width < 1100 ? 'column' : lan === 'ar' ? 'row-reverse' : 'row',
              gap: lan === 'ar' ? '15px' : '0',
            }}
          >
            <div className="accountSettings-wrapper">
              <Select
                width={width >= 1100 ? 270 : '100%'}
                options={countryToSelect}
                label={formatMessage({ id: 'countryLabel' })}
                placeholder={formatMessage({ id: 'countryPlaceholderShort' })}
                value={country}
                onChange={selectCountry}
                inputName="country"
                validation={{
                  errors,
                  touchedFields,
                  register,
                  clearErrors,
                  setError,
                  setValue,
                }}
              />
            </div>

            <CustomInput
              width={width >= 1100 ? 270 : '100%'}
              placeholder={formatMessage({ id: 'regionPlaceholder' })}
              label={formatMessage({ id: 'regionLabel' })}
              touchedFields={touchedFields}
              inputName="region"
              errors={errors}
              onChangeFunc={value => {
                // setEmail(value);
                selectRegion(value);
                // setValue('email', value);
              }}
              inputProps={{
                ...register('region', {
                  // required: formatMessage({ id: 'fieldIsEmpty' }),
                }),
                style: {
                  fontFamily:
                    lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                },
              }}
            />

            {/* <Select
              width={width > 1100 ? 270 : '100%'}
              options={regionToSelect}
              label={formatMessage({ id: 'regionLabel' })}
              value={region}
              placeholder={formatMessage({ id: 'regionPlaceholder' })}
              onChange={selectRegion}
              inputName="region"
              validation={{
                errors,
                touchedFields,
                register,
                clearErrors,
                setError,
                setValue,
              }}
            /> */}
          </div>
        </div>
        <CheckBoxLabel
          label={formatMessage({ id: 'showLocation' })}
          setValue={() => {
            changeOneCheckbox('showLocation');
          }}
          defaultChecked={checkBoxes.showLocation}
        />
        <TextArea
          label={formatMessage({ id: 'aboutMeLabel' })}
          maxChar={255}
          value={aboutMe}
          setValue={setAboutMe}
        />

        <div className="accountSettings-bold">
          <div>{formatMessage({ id: 'selectIdentify' })}</div>
        </div>

        <ul className="accountSettings-list">
          {identifyList.map((identify: string, index: number) => (
            <li key={index} className="accountSettings-list-item">
              <CheckBoxLabel
                defaultChecked={
                  !!checkBoxes.titles?.filter(
                    (item: any) => formatMessage({ id: item }) === identify,
                  )?.length
                }
                label={identify}
                setValue={(checked: boolean) => {
                  changeCheckboxTitles(identify, checked);
                }}
              />
            </li>
          ))}
          <li
            className={
              lan === 'ar'
                ? 'accountSettings-list'
                : 'accountSettings-list__other'
            }
            style={{
              flexDirection:
                width > 1100
                  ? lan === 'ar'
                    ? 'row-reverse'
                    : 'row'
                  : 'column',
            }}
          >
            <CheckBoxLabel
              label={formatMessage({ id: 'other' })}
              defaultChecked={checkBoxes.otherTitle}
              setValue={() => {
                changeOneCheckbox('otherTitle');
              }}
            />
            {checkBoxes.otherTitle && (
              <CustomInput
                width={width >= 1100 ? 270 : '100%'}
                placeholder={formatMessage({ id: 'identifyPlaceholder' })}
                onChangeFunc={value => {
                  setOtherTitle(value);
                }}
                style={{
                  marginLeft: lan === 'ar' ? 'auto' : 'initial',
                  marginTop: '8px',
                }}
                label={' '}
                inputProps={{
                  value: otherTitle,
                  style: {
                    fontFamily:
                      lan === 'ar' || lan === 'hi' ? 'Hind' : 'Open Sans',
                  },
                }}
              />
            )}
          </li>
        </ul>

        <div
          className="accountSettings-bold accountSettings-bold--button-all"
          style={{
            flexDirection:
              lan === 'ar' && width > 1100
                ? 'row-reverse'
                : lan !== 'ar' && width > 1100
                ? 'row'
                : 'column',
            alignItems: width > 1100 ? 'flex-end' : 'flex-start',
            gap: '12px',
          }}
        >
          <div style={{
            marginTop: '15px'
          }}>
            {formatMessage({ id: 'followingInterests' })}
            <p className="accountSettings__interest-text">
              {formatMessage({ id: 'followingInterestsSub' })}
            </p>
          </div>

          <div className="accountSettings-bold__wrap">
            <CustomChip
              mobile={width < 1100}
              label={
                btnSelectChecked
                  ? formatMessage({ id: 'resetAll' })
                  : formatMessage({ id: 'selectAll' })
              }
              src=""
              id=""
              isArchived
              onClick={checked => {
                if (checked) {
                  setSelectedChips([
                    ...allInterests.filter(item => !item.isArchived),
                  ]);
                } else {
                  setSelectedChips([]);
                }
                SetBtnSelectChecked(!btnSelectChecked);
              }}
            />
          </div>
        </div>

        <div className="accountSettings-chips">
          {allInterests
            ?.filter((item: Chip) => !item.isArchived)
            ?.map((item: Chip, index: number) => (
              <CustomChip
                key={index}
                label={item.title}
                src={item.avatar || item.url}
                id={item.id}
                isArchived={item.isArchived}
                defaultChecked={
                  !!(
                    1 +
                    selectedChips.findIndex(chip => chip.title === item.title)
                  )
                }
                setSelectedChips={setSelectedChips}
              />
            ))}
        </div>

        <div className="accountSettings-bold">
          {formatMessage({ id: 'changeProfilePhoto' })}
          <div className="accountSettings-bold__subtitle">
            {formatMessage({ id: 'selectProfilePhoto' })}
          </div>
        </div>

        <div className="accountSettings-bold">
          <div>{formatMessage({ id: 'avatar' })}</div>
        </div>

        <div
          className="accountSettings-photos"
          style={{
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
          }}
        >
          {defaultAvatars?.map((avatar: string, index: number) => (
            <AvatarChip
              key={index}
              src={avatar}
              setSelectedSrc={(src: string) => {
                setExampleAvatar(src);
                setUploadedAvatar('');
                setTempAvatarFileData(undefined);
              }}
              selectedSrc={exampleAvatar}
            />
          ))}
        </div>

        <div className="accountSettings-bold">
          <div>{formatMessage({ id: 'myPhoto' })}</div>
        </div>

        <div
          className="accountSettings-upload"
          style={{
            flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
          }}
        >
          {uploadedAvatar ? (
            <img
              className="accountSettings-upload__avatar"
              src={uploadedAvatar}
              alt="avatar"
              style={{
                margin:
                  width > 1100
                    ? lan === 'ar'
                      ? '0 0 0 16px'
                      : '0 16px 0 0'
                    : lan === 'ar'
                    ? '0 0 0 8px'
                    : '0 8px 0 0',
                width: width > 1100 ? '110px' : width < 375 ? '85px' : '100px',
                height: width > 1100 ? '110px' : width < 375 ? '85px' : '100px',
              }}
            />
          ) : (
            <div
              className={`accountSettings-upload__img${
                uploadAvatarDragover ? ' accountSettings-upload__img--drag' : ''
              }`}
              onDragStart={e => {
                e.preventDefault();
                setUploadAvatarDragover(true);
              }}
              onDragLeave={e => {
                e.preventDefault();
                setUploadAvatarDragover(false);
              }}
              onDragOver={e => {
                e.preventDefault();
                setUploadAvatarDragover(true);
              }}
              onDrop={e => {
                e.preventDefault();
                setUploadAvatarDragover(false);
                uploadImage(e.dataTransfer.files, 'avatar');
              }}
              style={{
                margin: lan === 'ar' ? '0 0 0 16px' : '0 16px 0 0',
              }}
            >
              <AddAPhotoTwoToneIcon />
            </div>
          )}

          <div
            className={
              uploadedAvatar
                ? 'accountSettings-upload__row'
                : 'accountSettings-upload__column'
            }
            style={{
              flexDirection:
                lan === 'ar' && uploadedAvatar ? 'inherit' : undefined,
            }}
          >
            <UploadButton imgType="avatar" uploadImage={uploadImage} />
            {uploadedAvatar ? (
              <div className="accountSettings-upload__button-cont">
                {(width >= 1100 && (
                  <SecondaryButton
                    text={formatMessage({ id: 'delete' })}
                    onClick={() => {
                      setUploadedAvatar('');
                      setTempAvatarFileData(undefined);
                      setExampleAvatar(defaultAvatars[0]);
                    }}
                  />
                )) || (
                  <TextButton
                    text={formatMessage({ id: 'delete' })}
                    onClick={() => {
                      setUploadedAvatar('');
                      setTempAvatarFileData(undefined);
                      setExampleAvatar(defaultAvatars[0]);
                    }}
                  />
                )}
              </div>
            ) : (
              <p
                className="onboardingForm-upload__text"
                style={{ marginTop: '4px', justifyContent: 'center' }}
              >
                <span style={{ marginTop: '0' }}>
                  {formatMessage({ id: 'photoFormat' })}
                </span>
                <span style={{ marginTop: '0' }}>
                  {formatMessage({ id: 'photoMaxSize' })}
                </span>
              </p>
            )}
          </div>
        </div>

        <div className="accountSettings-bold">
          <div>{formatMessage({ id: 'changeBackground' })}</div>
        </div>

        <div className="accountSettings-bg__wrapper">
          {uploadedBg ? (
            <img src={uploadedBg} alt="background" />
          ) : (
            <div
              className={`accountSettings-bg${
                uploadBgDragover ? ' accountSettings-bg--drag' : ''
              }${uploadBgError ? ' accountSettings-bg--error' : ''}`}
              onDragStart={e => {
                e.preventDefault();
                setUploadBgDragover(true);
              }}
              onDragLeave={e => {
                e.preventDefault();
                setUploadBgDragover(false);
              }}
              onDragOver={e => {
                e.preventDefault();
                setUploadBgDragover(true);
              }}
              onDrop={e => {
                e.preventDefault();
                setUploadBgDragover(false);
                uploadImage(e.dataTransfer.files, 'bg');
              }}
            >
              {uploadBgError && (
                <div className="accountSettings-bg__error">
                  <WarningAmberOutlinedIcon />
                  {uploadBgError}
                </div>
              )}
              <CloudUploadTwoToneIcon />
              <div>
                {formatMessage({ id: 'dargNdrop' })}
                <br />
                {formatMessage({ id: 'or' })}
              </div>
              <UploadButton
                imgType="bg"
                uploadImage={uploadImage}
                transparent
                paddingNone
                text={formatMessage({ id: 'selectFile' })}
              />
              <p className="accountSettings-bg__info">
                <span style={{ marginTop: '0' }}>
                  {formatMessage({ id: 'photoFormat' })}
                </span>
                <span style={{ marginTop: '0' }}>
                  {formatMessage({ id: 'photoMaxSize' })}
                </span>
              </p>
            </div>
          )}

          {uploadedBg && (
            <div
              className="accountSettings-bg__btns"
              style={{
                flexDirection: lan === 'ar' ? 'row-reverse' : 'row',
              }}
            >
              <UploadButton
                imgType="bg"
                uploadImage={uploadImage}
                text={formatMessage({ id: 'changeImage' })}
              />
              <SecondaryButton
                text={formatMessage({ id: 'delete' })}
                onClick={() => {
                  setUploadedBg('');
                  setTempBgFileData(undefined);
                }}
                styles={{
                  marginRight: lan === 'ar' ? '10px' : '0',
                }}
              />
            </div>
          )}
        </div>

        <div className="accountSettings-footer">
          <MainButton
            text={formatMessage({ id: 'saveChanges' })}
            type="submit"
            styles={width < 1100 ? { width: '100%', marginRight: '0' } : {}}
          />
        </div>
      </form>

      {cropModal.open && (
        <ImageCropModal
          image={cropModal.image}
          cropShape={cropModal.cropShape}
          onClose={() =>
            setCropModal({
              open: false,
              image: '',
              cropShape: 'rect',
              formData: '',
            })
          }
          onSave={cropModal.onSave}
        />
      )}

      {showEmailModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              smallCross
              textHeader={formatMessage({ id: 'changeEmailConfirm' })}
              onClose={() => setShowEmailModal(false)}
            />
            <div style={{ textAlign: 'center' }}>
              {formatMessage({ id: 'confirmEmail' })}
            </div>
            <ModalFooter>
              <SecondaryButton
                text={formatMessage({ id: 'sendEmail' })}
                onClick={() => {
                  setShowEmailModal(false);
                  updateUserEmail({ newEmail: email });
                }}
                styles={{ width: 143 }}
              />
              <MainButton
                text={formatMessage({ id: 'cancel' })}
                onClick={() => {
                  setShowEmailModal(false);
                }}
                styles={{ width: 143 }}
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}
    </ErrorBoundary>
  );
};

export default AccountSettingsForm;
