import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import appSlice from './slices/appSlice';
import profileSlice, { profileLogout } from './slices/profileSlice';
import authSlice from './slices/authSlice';
import adminSlice from './slices/adminSlice';
import postsSlice from './slices/postsSlice';
import relaxSlice from './slices/relaxSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import groupsSlice from './slices/groupsSlice';
import someUserSlice from './slices/someUserSlice';
import chatSlice from './slices/chatSlice';

export const rootReducer = combineReducers({
  app: appSlice,
  admin: adminSlice,
  profile: profileSlice,
  auth: authSlice,
  posts: postsSlice,
  relax: relaxSlice,
  groups: groupsSlice,
  someUser: someUserSlice,
  chat: chatSlice,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['posts'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PURGE, PERSIST, REGISTER],
      },
    }),
});

export const resetStore = async () => {
  await persistor.purge();
  store.dispatch(profileLogout());
  await persistor.flush();
};

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
