import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAnotherUserId } from '../../redux/slices/profileSlice';
import { AppDispatch, RootState } from '../../redux/store';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { deleteFake } from '../../redux/slices/adminSlice';
import './DropdownAccounts.scss';

function replaceLongWords(str: string) {
  if (str?.length) {
    const words = str.split(' ');
    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 15) {
        words[i] = '...';
      }
    }
    return words?.join(' ');
  }
};

type Props = {
  post: any
}

export default function DropdownAccounts({ post }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [owner, setOwner] = useState<any | null>(null);
  const [showOptions, setShowOptions] = useState(false);

  const {
    id,
    firstName,
    lastName,
    profileAvatar,
    organizationName,
    anotherUserId
  } = useSelector((state: RootState) => state.profile);

  const { fakeAccounts } = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(owner);
    };
  }, [selectedOption, owner]);

  useEffect(() => {
    const obj = {
      idOfPost: post?.originalPost?.id ? post?.originalPost?.id : post?.id,
      user: selectedOption?.id || '',
      image: selectedOption?.profileAvatar || '',
      id
    };
    dispatch(setAnotherUserId(obj));
  }, [dispatch, selectedOption, post, id]);

  useEffect(() => {
    if (profileAvatar) {
      const obj = {
        profileAvatar,
        organizationName: firstName ? firstName + " " + lastName : organizationName,
      }

      setOwner(obj);
      setSelectedOption(obj);
    }
  }, [firstName, lastName, organizationName, profileAvatar]);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setShowOptions(false);
  }

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  }

  return (
    <div className="DropdownAccounts">
      <div className="DropdownAccounts__selected-option" onClick={toggleOptions}>
        <img
          className="DropdownAccounts__selected-img"
          src={(anotherUserId?.image
            && (anotherUserId?.idOfPost === post?.originalPost?.id || anotherUserId?.idOfPost === post?.id))
            ? anotherUserId?.image
            : profileAvatar}
          alt="logo"
        />
        <div className="DropdownAccounts__arrow">
          {showOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {showOptions && (
        <ul className="DropdownAccounts__options">
          {[owner, ...fakeAccounts].map((acc: any, i: number) => (
            <li
              className="DropdownAccounts__row"
              key={acc?.id}
              onClick={() => handleOptionClick(acc)}
            >
              <img
                className="DropdownAccounts__selected-img"
                src={acc?.profileAvatar}
                alt="any"
              />
              <div className="DropdownAccounts__info">
                <p>{replaceLongWords((acc?.firstName
                  ? `${acc?.firstName} ${acc?.lastName}`
                  : acc?.organizationName))}
                </p>
                <p>{acc?.country}</p>
              </div>
              <div>
                {selectedOption?.id === acc?.id && (
                  <div className="DropdownAccounts__check">
                    <DoneIcon
                      sx={{
                        color: "#28A745",
                        fontSize: 16
                      }}
                    />
                  </div>
                )}

                {i !== 0 && <div>
                  <button
                    className="DropdownAccounts__delete"
                    onClick={() => {
                      setSelectedOption(owner);
                      dispatch(deleteFake(acc?.id))
                    }}
                  >
                    <HighlightOffIcon
                      sx={{
                        fontSize: 16
                      }}
                    />
                  </button>
                </div>}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
};
