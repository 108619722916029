import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { IconButton, Popover } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FlagTwoToneIcon from '@mui/icons-material/FlagTwoTone';
import MainButton from '../MainButton/MainButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import ReportModal from '../../ReportModal/ReportModal';
import ModalWrapper from '../../../ModalConstructor/ModalWrapper/ModalWrapper';
import ModalContainer from '../../../ModalConstructor/ModalContainer/ModalContainer';
import ModalHeader from '../../../ModalConstructor/ModalHeader/ModalHeader';
import ModalFooter from '../../../ModalConstructor/ModalFooter/ModalFooter';
import {
  deletePost,
  getTranslation,
  setEditedPost,
  setTypeOfNewPost,
} from '../../../../redux/slices/postsSlice';
import { getPostApi } from '../../../../api/posts';
import { getPostInterestsAPI } from '../../../../api/iterests';
import { AppDispatch, RootState } from '../../../../redux/store';
import './MoreButton.scss';
import {
  followUserById,
  getFollowing,
  setAccountSettings,
} from '../../../../redux/slices/profileSlice';
import someUserSlice, { getFollowingSomeUser, getSomeUserInfo } from '../../../../redux/slices/someUserSlice';
import { cleanMsgs } from '../../../../redux/slices/chatSlice';
// import { postShareAPI } from '../../../../api/posts';
// import { SharePostModal } from '../../SharePostModal/SharePostModal';
// import { reportPost } from '../../../../api/posts';
// import CustomInput from '../../CustomInput/CustomInput';

interface Props {
  post: Post;
}

export const MoreButton = ({ post }: Props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { someUserId } = useParams();
  const [searchParams] = useSearchParams() || '';
  const setPostCreation = searchParams.get('edit-post') || '';
  const lan = localStorage.getItem('lang') || 'en';

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [translateText, setTranslateText] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const userId = useSelector((state: RootState) => state.profile.id);
  const { isAdmin } = useSelector((state: RootState) => state.profile);

  const htmlEl = document.getElementsByTagName('html')[0];

  useEffect(() => {
    if (anchorEl && htmlEl) {
      htmlEl.style.overflow = 'hidden';
      dispatch(getSomeUserInfo(post.owner.id))
    } else {
      htmlEl.style.overflow = 'auto';
    }
  }, [anchorEl]);

  // const [showSharePostModal, setShowSharePostModal] = useState(false);
  // const [reportReason, setReportReason] = useState('');

  useEffect(() => {
    if (translateText) {
      const objToTranslate = {
        type: 'Post',
        lang: lan,
        originalPost: {},
        translate: translateText,
      } as any;

      if (post) {
        objToTranslate.postId = post.id;
        if (post.description?.length > 0) {
          objToTranslate.description = post.description;
        }
        if (post.title?.length > 0) {
          objToTranslate.title = post.title;
        }
        if (post.originalPost?.title?.length > 0) {
          objToTranslate.originalPost.title = post.originalPost.title;
        }
        if (post.originalPost?.description?.length > 0) {
          objToTranslate.originalPost.description =
            post.originalPost.description;
        }
        if (post.answers?.length > 0) {
          objToTranslate.answers = post.answers;
        }
      }

      dispatch(getTranslation(objToTranslate));
    } else {
      (async () => {
        const singlPost = await getPostApi(`${post.id}`);

        if (singlPost?.statusCode !== 400) {
          const objToTranslate = {
            type: 'Post',
            lang: lan,
            originalPost: {},
            translate: translateText,
          } as any;

          if (singlPost) {
            objToTranslate.postId = singlPost.id;
            if (singlPost.description?.length > 0) {
              objToTranslate.description = singlPost.description;
            }
            if (singlPost.title?.length > 0) {
              objToTranslate.title = singlPost.title;
            }
            if (singlPost.originalPost?.title?.length > 0) {
              objToTranslate.originalPost.title = singlPost.originalPost.title;
            }
            if (singlPost.originalPost?.description?.length > 0) {
              objToTranslate.originalPost.description =
                singlPost.originalPost.description;
            }
            if (singlPost.answers?.length > 0) {
              objToTranslate.answers = singlPost.answers;
            }
          }

          dispatch(getTranslation(objToTranslate));
        }
      })();
    }
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translateText]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const editPost = async (
    typeOfPost: TypeOfPost,
    typeOfAction: string,
    post: Post,
  ) => {
    const interests = await getPostInterestsAPI(post.id);
    dispatch(setEditedPost({ ...post, interests: interests.data }));

    searchParams.set(`${typeOfAction}-post`, 'on');

    if (setPostCreation === 'on') {
      searchParams.delete(`${typeOfAction}-post`);
    } else {
      searchParams.set(`${typeOfAction}-post`, 'on');
    }

    navigate(`?${searchParams.toString()}`, { replace: true });
    dispatch(setTypeOfNewPost(typeOfPost));

    handleClose();
  };

  // FOLLOW - UNFOLLOW USER FUNC
  const profileData = useSelector((state: RootState) => state.profile);
  const {
    firstName,
    id: profileId,
    lastName,
    organizationName,
    profileAvatar
  } = profileData;

  const user  = useSelector((state: RootState) => state.someUser.someUser);

  const alreadyFollowingThisUser = user?.user.ifFollow

  function followUserFunc(id: any) {
    const myProfile = {
      id: profileId,
      about: '',
      firstName,
      ifFollow: false,
      lastName,
      organizationName,
      profileAvatar,
      isDeleted: false,
    };

    dispatch(
      followUserById({ ...id, page: 'someProfileWall', personalId: myProfile }),
    );
  }

  return (
    <>
      <div
        className="post__header-more-btn-container"
        aria-describedby={id}
        onClick={ev => {
          setAnchorEl(ev.currentTarget);
        }}
      >
        <IconButton color="secondary">
          <MoreHorizIcon className="post__header-more-btn" />
        </IconButton>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <div>
          <div className="post__popover-container">
            {(post?.owner?.id === userId || isAdmin) && (
              <div
                className="post__popover-row"
                onClick={() => {
                  editPost(
                    { id: post.types?.id, typeName: post.types?.typeName },
                    'edit',
                    post,
                  );
                }}
              >
                <EditTwoToneIcon className="post__popover-img" color="action" />
                <span>{formatMessage({ id: 'edit' })}</span>
              </div>
            )}

            {post?.owner?.id !== userId &&
              <div
                className="post__popover-row"
                onClick={() => {
                  navigate(`/chat?id=${post?.owner?.id}`);
                  dispatch(cleanMsgs());
                  handleClose();
                }}
              >
                <MailOutlineIcon />
                <span>{formatMessage({ id: 'Chat' })}</span>
              </div>
            }

            {/* FOLLOW USER */}
            {post?.owner?.id !== userId &&
              // post?.owner?.id !== someUserId && //to turn it off on someUser page
              !alreadyFollowingThisUser && (
                <div
                  className="post__popover-row"
                  onClick={() => {
                    followUserFunc(post?.owner);
                    handleClose();
                  }}
                >
                  <svg
                    className="post__popover-img"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path d="M15.424 14.096c3.648 0 6.656-2.976 6.656-6.656 0-3.648-2.976-6.656-6.656-6.656-3.648 0-6.656 2.976-6.656 6.656 0 3.648 3.008 6.656 6.656 6.656zM15.424 2.64c2.656 0 4.8 2.144 4.8 4.8s-2.144 4.8-4.8 4.8c-2.656 0-4.8-2.144-4.8-4.8s2.144-4.8 4.8-4.8z"></path>
                    <path d="M19.52 25.136v2.080h4v4h2.048v-4h4v-2.080h-4v-3.968h-2.048v3.968z"></path>
                    <path d="M10.272 16.656c0.576-0.32 1.344-0.096 2.464 0.288 0.8 0.288 1.728 0.608 2.72 0.608s1.888-0.32 2.72-0.608c1.12-0.384 1.824-0.608 2.432-0.288 0.928 0.512 1.664 1.152 2.272 2.144h2.272c-0.896-1.76-2.112-2.912-3.648-3.744-1.344-0.736-2.72-0.256-3.936 0.192-0.768 0.256-1.44 0.512-2.112 0.512s-1.376-0.224-2.112-0.512c-1.248-0.448-2.592-0.928-3.968-0.192-3.616 1.984-5.504 5.824-5.504 11.104v0.928h13.44v-1.824h-11.584c0.224-5.152 2.56-7.52 4.544-8.608z"></path>
                  </svg>
                  <span>{formatMessage({ id: 'follow' })}</span>
                </div>
              )}

            {/* UNFOLLOW USER */}
            {post?.owner?.id !== userId &&
              // post?.owner?.id !== someUserId && //to turn it off on someUser page
              alreadyFollowingThisUser && (
                <div
                  className="post__popover-row"
                  onClick={() => {
                    followUserFunc(post?.owner);
                    handleClose();
                  }}
                >
                  <svg
                    className="post__popover-img"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path d="M9.2 6.3c-2.6 2.7-2.8 5.4-0.6 8.5 1.4 2 1.4 2.3-0.1 2.8-2.7 1.1-5.8 8.4-3.5 8.4 0.6 0 1-0.7 1-1.5 0-2.7 4.7-6.5 7.9-6.5 3 0 3.1 0 2.5 4.4-0.6 3.9-0.3 4.8 1.9 7 3.4 3.4 7.9 3.4 11.2 0.1s3.3-7.8-0.1-11.2c-2.2-2.2-3.1-2.5-7-1.9-4.5 0.6-4.5 0.6-2.9-1.6 2.1-3.2 1.9-6.1-0.8-8.6-3-2.8-6.8-2.8-9.5 0.1zM17.4 7.6c3.1 3 0.9 8.4-3.4 8.4-2.4 0-5-2.6-5-5s2.6-5 5-5c1 0 2.6 0.7 3.4 1.6zM28 20c1.1 1.1 2 2.9 2 4 0 2.6-3.4 6-6 6s-6-3.4-6-6c0-2.6 3.4-6 6-6 1.1 0 2.9 0.9 4 2z"></path>
                    <path d="M20 24c0 0.5 1.8 1 4 1s4-0.5 4-1c0-0.6-1.8-1-4-1s-4 0.4-4 1z"></path>{' '}
                  </svg>
                  <span>{formatMessage({ id: 'unfollow' })}</span>
                </div>
              )}

            {post?.owner?.id !== userId && post?.priority.toString() !== '1' && (
              <div
                className="post__popover-row"
                onClick={() => {
                  handleClose();
                  setReportModal(true);
                }}
              >
                <FlagTwoToneIcon className="post__popover-img" color="action" />
                <span>{formatMessage({ id: 'report' })}</span>
              </div>
            )}

            <div
              className="post__popover-row"
              onClick={() => {
                setTranslateText(!translateText);
              }}
            >
              <LanguageTwoToneIcon
                className="post__popover-img"
                color="action"
              />
              <span>
                {translateText
                  ? formatMessage({ id: 'origText' })
                  : formatMessage({ id: 'translate' })}
              </span>
            </div>

            {(post?.owner?.id === userId || isAdmin) && (
              <div
                className="post__popover-row"
                onClick={e => {
                  e.stopPropagation();
                  setDeleteModal(true);
                  handleClose();
                }}
              >
                <svg
                  className="post__popover-img"
                  width="24"
                  height="24"
                  viewBox="0 0 18 19"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M6 7.25H12V14.75H6V7.25Z"
                    fill="#6C757D"
                  />
                  <path d="M11.625 3.5L10.875 2.75H7.125L6.375 3.5H3.75V5H14.25V3.5H11.625ZM4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6 7.25H12V14.75H6V7.25Z" />
                </svg>
                <span>{formatMessage({ id: 'delete' })}</span>
              </div>
            )}
          </div>
        </div>
      </Popover>

      {deleteModal && (
        <ModalWrapper width={350}>
          <ModalContainer>
            <ModalHeader
              smallCross
              textHeader={formatMessage({ id: 'deletePostConfirm' })}
              onClose={() => setDeleteModal(false)}
            />
            <ModalFooter>
              <SecondaryButton
                text={formatMessage({ id: 'delete' })}
                onClick={async () => {
                  setDeleteModal(false);
                  await dispatch(deletePost({ postId: post.id }));
                }}
                bigPadding
              />
              <MainButton
                bigPadding
                text={formatMessage({ id: 'cancel' })}
                onClick={() => setDeleteModal(false)}
              />
            </ModalFooter>
          </ModalContainer>
        </ModalWrapper>
      )}
      {/* {showSharePostModal && <SharePostModal post={post} setShowSharePostModal={setShowSharePostModal} />} */}
      {reportModal && (
        <ReportModal
          postId={post.id}
          setReportModal={setReportModal}
        />
        // <ModalWrapper width={350}>
        //   <ModalContainer>
        //     <ModalHeader
        //       textHeader={formatMessage({ id: 'reportConfirm' })}
        //       smallCross
        //       onClose={() => setReportModal(false)} />
        //     <CustomInput
        //       width='100%'
        //       placeholder={formatMessage({ id: 'reportReason' })}
        //       label={formatMessage({ id: 'reportReasonLabel' })}
        //       onChangeFunc={(value: string) => { setReportReason(value) }}
        //     />
        //     <ModalFooter>
        //       <SecondaryButton
        //         text={formatMessage({ id: 'report' })}
        //         onClick={() => {
        //           setReportModal(false);
        //           reportPost({ postId: post.id, reason: reportReason })
        //           .then((res) => {
        //             if(res.status === 400){
        //               dispatch(removePost(post.id));
        //               dispatch(removePostFeed(post.id));
        //             }
        //           })
        //         }}
        //         bigPadding
        //       />
        //       <MainButton text={formatMessage({ id: 'cancel' })} onClick={() => setReportModal(false)} bigPadding />
        //     </ModalFooter>
        //   </ModalContainer>
        // </ModalWrapper>
      )}
    </>
  );
};
