import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { NavSide } from '../../components/NavSide/NavSide';
import { Aside } from '../../components/Aside/Aside';
import ErrorBoundary from '../../components/ErrorBoundary';
import ReportedComment from './ReportedComment/ReportedComment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getReportedComments } from '../../redux/slices/adminSlice';
import { useIntl } from 'react-intl';
import SecondaryButton from '../../components/shared/Buttons/SecondaryButton/SecondaryButton';
import './ReportedCommentsPage.scss';

const ReportedCommentsPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { reportedCommentsInfo } = useSelector(
    (state: RootState) => state.admin,
  );
  const [skip, setSkip] = useState(0);

  const handleMoreReportedCommentsFunc = () => {
    setSkip(prev => prev + 5);
  };

  useEffect(() => {
    dispatch(getReportedComments(skip));
  }, [skip, dispatch]);

  // console.log('skip', skip);
  // console.log('reportedCommentsInfo', reportedCommentsInfo);
  return (
    <div className="bg-container">
      <Header />
      <div className="page-container">
        <section className="main-page">
          <div className="main-container">
            <ErrorBoundary>
              <div className="main-page__inner">
                <NavSide />

                <div className="comments-report-page">
                  <h2>Comments That Have Been Reported On</h2>
                  {reportedCommentsInfo?.length ? (
                    reportedCommentsInfo?.map((reportedComment, index) => (
                      <ReportedComment
                        reportedComment={reportedComment}
                        key={index}
                      />
                    ))
                  ) : (
                    <div>There are no reported comments</div>
                  )}

                  {/* MORE COMMENT BUTTON */}
                  {skip <= reportedCommentsInfo?.length && (
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 20,
                      }}
                    >
                      <SecondaryButton
                        text={formatMessage({ id: 'showMore' })}
                        onClick={handleMoreReportedCommentsFunc}
                      />
                    </div>
                  )}
                </div>

                <Aside />
              </div>
            </ErrorBoundary>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReportedCommentsPage;
