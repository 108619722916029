import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { createBook, editBook } from '../../../../redux/slices/relaxSlice';
import { initialBookCover } from '../../../../constants/relaxRoom';
import ModalContainer from '../../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../../../components/ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../../../../components/shared/Buttons/MainButton/MainButton';
import CustomInput from '../../../../components/shared/CustomInput/CustomInput';
import TextArea from '../../../../components/shared/TextArea/TextArea';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { Loader } from '../../../../components/Loader';
import CloseIcon from '@mui/icons-material/Close';
import './CreateBook.scss';
import { Cloudinary_NAME, Cloudinary_PRESET } from '../../../../constants';

interface CreateBookProps {
  book?: Book;
  setCreateBook: Dispatch<SetStateAction<boolean>>;
  setBookReader?: Dispatch<SetStateAction<boolean>>;
}

interface CreateBookData {
  title: string;
  author: string;
  link: string;
  review: string;
}

function CreateBook({ book, setCreateBook, setBookReader }: CreateBookProps) {
  const {
    setValue,
    register,
    handleSubmit,
  } = useForm<CreateBookData>({
    defaultValues: {
      title: book ? book.title : '',
      author: book ? book.author : '',
      link: book ? book.link : '',
      review: book ? book.description : '',
    }
  });
  const [bookCover, setBookCover] = useState<AttachmentBook>(initialBookCover);
  const [bookLoader, setBookLoader] = useState<boolean>(false);
  const [onSubmitLoader, setOnSubmitLoader] = useState<boolean>(false);
  const { bookStatus } = useSelector((state: RootState) => state.relax);
  const lan = localStorage.getItem('lang') || 'en';

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (book && book.attachments[0]) {
      setBookCover({
        type: book.attachments[0].type,
        url: book.attachments[0].url,
      });
    }
  }, [book]);

  useEffect(() => {
    if (bookStatus === 'loading' && setBookReader) {
      setBookReader(false);
    }
    if (bookStatus === 'success') {
      setCreateBook(false);
      setOnSubmitLoader(false);
    }
    if (bookStatus === 'rejected') {
      setOnSubmitLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookStatus]);

  const onAddBookCover = async (image: FileList | null) => {
    if (image === null) return;

    const imageSize = image[0].size / (1024 ** 2);
    if (imageSize > 5) {
      toast.error(formatMessage({ id: 'correctPhotosWeight' }));
      return;
    }
    setBookLoader(true);
    const formData = new FormData();
    formData.append('file', image[0]);
    formData.append('upload_preset', Cloudinary_PRESET);

    await axios
      .post(`https://api.cloudinary.com/v1_1/${Cloudinary_NAME}/image/upload`, formData)
      .then((response: AxiosResponse<any>) => {
        setBookCover({
          type: 'PHOTO',
          url: response.data.secure_url,
        });
        setBookLoader(false);
      })
      .catch(() => {
        toast.error(formatMessage({ id: 'errorCreateOrEditPost' }));
        setBookCover(initialBookCover);
        setBookLoader(false);
      });
  };

  const onSubmit = (data: CreateBookData) => {
    const { title, author, link, review: description } = data;
    book ?
      dispatch(editBook({
        bookId: book.id,
        title,
        author,
        link,
        description,
        attachment: [bookCover],
      })) :
      dispatch(createBook({
        title,
        author,
        link,
        description,
        attachment: [bookCover],
      }));
    setOnSubmitLoader(true);
  };

  return (
    <ErrorBoundary>
      {onSubmitLoader ?
        <Loader /> :
        <ModalWrapper>
          <ModalContainer>
            <ModalHeader
              textHeader={book ?
                formatMessage({ id: 'editBook' }) :
                formatMessage({ id: 'createNewBook' })}
              onClose={() => setCreateBook(false)}
            />
            <form
              className="CreateBook"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CustomInput
                label={formatMessage({ id: 'title' })}
                width="100%"
                onChangeFunc={(value: string) => setValue('title', value)}
                defaultValue={book ? book.title : undefined}
                inputProps={{
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <CustomInput
                label={formatMessage({ id: 'author' })}
                width="100%"
                onChangeFunc={(value: string) => setValue('author', value)}
                defaultValue={book ? book.author : undefined}
                inputProps={{
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <CustomInput
                label={formatMessage({ id: 'bookLink' })}
                width="100%"
                onChangeFunc={(value: string) => setValue('link', value)}
                defaultValue={book ? book.link : undefined}
                inputProps={{
                  style: {
                    fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
                  }
                }}
              />
              <TextArea
                type="bookCover"
                label={formatMessage({ id: 'description' })}
                uploadImage={onAddBookCover}
                register={register}
                name="review"
                defaultValue={book ? book.description : undefined}
              />

              {bookCover.url ?
                <div className="CreateBook__cover">
                  <CloseIcon
                    className="CreateBook__cover-remove"
                    onClick={() => setBookCover(initialBookCover)}
                  />
                  <img
                    className="CreateBook__cover-img"
                    src={bookCover.url}
                    alt="Book cover"
                  />
                </div> :
                <div className="CreateBook__cover--empty">
                  {bookLoader ? <Loader withoutPosition /> : null}
                </div>}

              <ModalFooter>
                <MainButton
                  text={book ?
                    formatMessage({ id: 'saveChanges' }) :
                    formatMessage({ id: 'createBook' })}
                  type="submit"
                />
              </ModalFooter>
            </form>
          </ModalContainer>
        </ModalWrapper>
      }
    </ErrorBoundary>
  );
}

export default CreateBook;
