import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import SearchIcon from '@mui/icons-material/Search';
import './SearchInput.scss';

interface Props {
  placeholder?: string;
  setSearchValue: (value: string) => void;
  top?: string;
}

const SearchInput = ({
  setSearchValue,
  placeholder,
  top = '6px',
}: Props) => {

  const [currentValue, setCurrentValue] = useState('');
  const { formatMessage } = useIntl();
  const lan = localStorage.getItem('lang') || 'en';

  const doneTypingInterval = 1500;
  let typingTimer: NodeJS.Timeout;

  const doneTypingFunc = (value: string) => {
    setSearchValue(value);
    setCurrentValue('')
  };

  return (
    <label className={`searchInput-wrapper${currentValue ? ' searchInput-wrapper--filled ' : ''}`}>
      <SearchIcon
        className="searchInputIcon"
        style={{
          left: (lan === 'ar') ? '-30px' : '10px',
          top,
        }}
      />
      <input
        className="searchInput"
        placeholder={placeholder ?
          placeholder : formatMessage({ id: 'search' })}
        value={currentValue}
        onChange={(e) => setCurrentValue(e.target.value)}
        onKeyUp={(e) => {
          clearTimeout(typingTimer);
          typingTimer = (setTimeout(() => {
            //@ts-ignore
            doneTypingFunc(e.target.value);
          }, doneTypingInterval));
        }}
        onKeyDown={() => {
          clearTimeout(typingTimer);
        }}
        style={{
          fontFamily: (lan === 'ar' || lan === 'hi') ? 'Hind' : 'Open Sans',
          textAlign: (lan === 'ar') ? 'right' : 'left',
          padding: (lan === 'ar') ? '0 38px 0 0' : '0 0 0 34px',
        }}
      />
    </label>
  );
};

export default SearchInput;
