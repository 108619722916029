import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import useWindowDimensions from '../../../../hooks/widthHook';
import { SharePostModal } from '../../SharePostModal/SharePostModal';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

interface Props {
    post: Post | BookmarkedPost
}

export const ShareButton = ({ post }: Props) => {
    const { formatMessage } = useIntl();
    const [showSharePostModal, setShowSharePostModal] = useState(false);
    const { width: windowWidth } = useWindowDimensions();

    return (

        <>
            {/* <div className='post__header-more-btn-container' onClick={() => {setShowSharePostModal(true)}}>
                <svg className="post__popover-img" width="24" height="24" viewBox="0 0 24 24">
                    <path fill="#9c27b0" d="M14 9V5L21 12L14 19V14.9C9 14.9 5.5 16.5 3 20C4 15 7 10 14 9Z" />
                </svg>
            </div> */}
            <SecondaryButton
                text={formatMessage({ id: 'sharePost' })}
                onClick={() => { setShowSharePostModal(true) }}
                styles={windowWidth < 1100 ? { width: '100%' } : {display: 'block'}}
            />

            {showSharePostModal && <SharePostModal post={post} setShowSharePostModal={setShowSharePostModal} />}
        </>
    )
}
