const allCities = [
  {
    country: 'Andorra',
    cities: [
      'Sant Julià de Lòria',
      'Pas de la Casa',
      'Ordino',
      'les Escaldes',
      'la Massana',
      'Encamp',
      'Canillo',
      'Arinsal',
      'Andorra la Vella',
    ],
  },
  {
    country: 'United Arab Emirates',
    cities: [
      'Umm Al Quwain City',
      'Ras Al Khaimah City',
      'Muzayri‘',
      'Murbaḩ',
      'Maşfūţ',
      'Zayed City',
      'Khawr Fakkān',
      'Dubai',
      'Dibba Al-Fujairah',
      'Dibba Al-Hisn',
      'Sharjah',
      'Ar Ruways',
      'Al Manāmah',
      'Al Fujairah City',
      'Al Ain City',
      'Ajman City',
      'Adh Dhayd',
      'Abu Dhabi',
      'Khalifah A City',
      'Bani Yas City',
      'Musaffah',
      'Al Shamkhah City',
      'Reef Al Fujairah City',
      'Al Wiqan',
    ],
  },
  {
    country: 'Afghanistan',
    cities: [
      'Zōr Kōṯ',
      'Wulêswālī Bihsūd',
      'Kuhsān',
      'Lāsh',
      'Tukzār',
      'Bati',
      'Mīray',
      'Āq Kupruk',
      'Zurmat',
      'Zaybāk',
      'Zīārat-e Shāh Maqşūd',
      'Zindah Jān',
      'Zarghūn Shahr',
      'Zaṟah Sharan',
      'Zaranj',
      'Zamtō Kêlay',
      'Yangī Qal‘ah',
      'Bāzār-e Yakāwlang',
      'Yaḩyá Khēl',
      'Wāshēr',
      'Tōrmay',
      'Tūlak',
      'Tītān',
      'Tīr Pul',
      'Taywarah',
      'Bāzār-e Tashkān',
      'Tarinkot',
      'Taloqan',
      'Tagāw-Bāy',
      'Tagāb',
      'Markaz-e Ḩukūmat-e Sulţān-e Bakwāh',
      'Spīn Bōldak',
      'Spērah',
      'Sōzmah Qal‘ah',
      'Siyāhgird',
      'S̲h̲ēwah',
      'Shīnḏanḏ',
      'Shaykh Amīr Kêlay',
      'Qāshqāl',
      'Shibirghān',
      'Shwāk',
      'Shahr-e Şafā',
      'Shahrān',
      'Shahrak',
      '‘Alāqahdārī Shāh Jōy',
      'Wulêswālī Sayyid Karam',
      'Markaz-e Sayyidābād',
      'Şayād',
      'Sidqābād',
      'Sāyagaz',
      'Sar-e Tayghān',
      'Sarōbī',
      'Sar Kāṉī',
      'Sarfirāz Kalā',
      'Sar-e Pul',
      'Sar Chakān',
      'Sangīn',
      'Sang-e Māshah',
      'Sang-e Chārak',
      'Sang Atesh',
      'Sangar Sarāy',
      'Aībak',
      'Rū-ye Sang',
      'Rūdbār',
      'Rustāq',
      'Rabāţ-e Sangī-ye Pā’īn',
      'Rāmak',
      'Qurghān',
      'Quchanghī',
      'Qaīşār',
      'Qarqīn',
      'Qarghah’ī',
      'Qarchī Gak',
      'Qarāwul',
      'Qarah Bāgh',
      'Qala i Naw',
      'Qal‘ah-ye Kūf',
      'Qal‘ah-ye Kuhnah',
      'Qal‘ah-ye Shahr',
      'Qalāt',
      'Qal‘ah-ye Shāhī',
      'Qādis',
      'Pārūn',
      'Pul-e Khumrī',
      'Pul-e ‘Alam',
      'Pasnay',
      'Pāshmūl',
      'Pasāband',
      'Panjāb',
      'Paghmān',
      'Ōmnah',
      'Ōbêh',
      'Uruzgān',
      'Urgun',
      'Nūsay',
      'Nūrgal',
      'Now Zād',
      'Nīlī',
      'Nayak',
      'Nāyak',
      'Now Dahānak',
      'Ōkak',
      'Nāṟay',
      'Narang',
      'Nīkêh',
      'Nahrīn',
      'Ḩukūmat-e Nād ‘Alī',
      'Mūsá Qal‘ah',
      'Bala Murghab',
      'Muqêr',
      'Muḩammad Āghah Wuluswālī',
      'Mīzān ‘Alāqahdārī',
      'Mīr Bachah Kōṯ',
      'Mīrān',
      'Mīrābād',
      'Maymana',
      'Maīdān Khūlah',
      'Mingajik',
      'Mehtar Lām',
      'Mazār-e Sharīf',
      'Māymay',
      'Mutā Khān',
      'Mashhad',
      'Mardīān',
      'Mandōl',
      'Māmā Khēl',
      'Lashkar Gāh',
      'Lāsh-e Juwayn',
      'Larkird',
      'La‘l',
      'Kushk',
      'Kōṯowāl',
      'Kushk-e Kuhnah',
      'Kuran wa Munjan',
      'Kunduz',
      'Khōshī',
      'Khōshāmand',
      'Khwājah Ghār',
      'Khwājah Dū Kōh',
      'Deh Khwāhān',
      'Khulbisāt',
      'Khūgyāṉī',
      'Khōst',
      'Khulm',
      'Khudāydād Khēl',
      'Khinjān',
      'Khinj',
      'Khāsh',
      'Khān Neshīn',
      'Khānaqāh',
      'Chahār Bāgh',
      'Khandūd',
      'Khanabad',
      'Khamyāb',
      'Khākirān',
      'Kaz̲h̲ah',
      'Kishk-e Nakhūd',
      'Karukh',
      'Kanḏay',
      'Kandahār',
      'Kalān Deh',
      'Kalakān',
      'Kalafgān',
      'Kajrān',
      'Kā’ī',
      'Kabul',
      'Jurm',
      'Jawand',
      'Jānī Khēl',
      'Jalrēz',
      'Jalālābād',
      'Jabal os Saraj',
      'Ḩukūmat-e Shīnkaī',
      'Herāt',
      'Ḩājī Khēl',
      'Ḩāfiz̧ Moghul',
      'Ḩāfiz̧ān',
      'Guz̄arah',
      'Gōshtah',
      'Gōmal Kêlay',
      '‘Alāqahdārī Gēlān',
      'Ghōriyān',
      'Ghormach',
      'Ghurayd Gharamē',
      'Ghazni',
      'Gereshk',
      'Gardez',
      'Fayzabad',
      'Faīẕābād',
      'Fayẕābād',
      'Qal‘ah-ye Fārsī',
      'Farkhār',
      'Farah',
      'Istālif',
      'Kafir Qala',
      'Injīl',
      'Imām Şāḩib',
      'Dōshī',
      'Dowlatyār',
      'Dowlat Shāh',
      'Dowlatābād',
      'Dū Qal‘ah',
      'Dūāb',
      'Dê Nārkhēl Kêlay',
      'Dehī',
      'Deh-e Şalāḩ',
      'Deh-e Now',
      'Dehdādī',
      'Dwah Manḏay',
      'Dasht-e Qal‘ah',
      'Dasht-e Archī',
      'Darzāb',
      'Markaz-e Ḩukūmat-e Darwēshān',
      'Darqad',
      'Darāyim',
      'Dāngām',
      'Ḏanḏar',
      'Ḩukūmatī Dahanah-ye Ghōrī',
      'Tsowkêy',
      'Chīras',
      'Chisht-e Sharīf',
      'Chinār',
      'Chimtāl',
      'Charkh',
      'Charikar',
      'Dowr-e Rabāţ',
      'Tsapêraī',
      'Tsamkanī',
      'Chakaray',
      'Chākarān',
      'Chahār Qal‘ah',
      'Chahār Burj',
      'Chāh Āb',
      'Fayrōz Kōh',
      'Bulōlah',
      'Bal Chirāgh',
      'Bāzār-e Tālah',
      'Bāsawul',
      'Būrkah',
      'Barg-e Matāl',
      'Baraki Barak',
      'Banū',
      'Bāmyān',
      'Balkh',
      'Bahārak',
      'Bagrāmī',
      'Baghlān',
      'Ḩukūmatī Azrah',
      'Ārt Khwājah',
      '‘Alāqahdārī Aṯghar',
      'Āsmār',
      'Ashkāsham',
      'Asadābād',
      'Āqchah',
      'Andkhōy',
      '’Unābah',
      'Anār Darah',
      'Amānzī',
      '‘Alāqahdārī-ye Almār',
      '‘Alī Shēr ‘Alāqahdārī',
      'Wuluswālī ‘Alīngār',
      '‘Alī Khēl',
      '‘Alāqahdārī Yōsuf Khēl',
      '‘Alāqahdārī Dīshū',
      'Alah Sāy',
      'Pachīr wa Āgām',
      'Āfāqī',
      'Adraskan',
      'Āb-e Kamarī',
      'Khadīr',
      'Ghulām ‘Alī',
      'Qarah Bāgh Bāzār',
      'Zargarān',
      'Surkh Bilandī',
      'Pul-e Sangī',
      'Langar',
      'Bāgh-e Maīdān',
      'Ibrāhīm Khān',
      'Qaranghū Tōghaī',
      'Bāzārak',
      'Shērwānī-ye Bālā',
      'Kirāmān',
      '‘Alāqahdārī Saṟōbī',
      'Zīṟūk ‘Alāqahdārī',
      'Kushkak',
      'Khayr Kōṯ',
      'Chowṉêy',
      'Wuṯahpūr',
      'Karbōṟī',
      'Sulţānpūr-e ‘Ulyā',
      'Bābā Şāḩib',
      'Chandal Bā’ī',
      'Dahan-e Jarf',
      'Maydanshakhr',
      'Dū Laīnah',
      'Qaram Qōl',
      'Pul-e Ḩişār',
      'Lab-Sar',
      'Ţāqchah Khānah',
      'March',
      'Zīrakī',
      '‘Alīābād',
      'Pas Pul',
      'Qal‘ah-ye Na‘īm',
      'Markaz-e Woluswalī-ye Āchīn',
      'Māṉōgay',
      'Stêr Giyān',
      'Sharan',
      'Salām Khēl',
    ],
  },
  {
    country: 'Antigua and Barbuda',
    cities: [
      'Saint John’s',
      'Potters Village',
      'Piggotts',
      'Parham',
      'Liberta',
      'Falmouth',
      'Codrington',
      'Bolands',
      'All Saints',
    ],
  },
  {
    country: 'Anguilla',
    cities: [
      'West End Village',
      'The Valley',
      'The Quarter',
      'Farrington',
      'Stoney Ground',
      'South Hill Village',
      'Sandy Ground Village',
      'North Side',
      'North Hill Village',
      'Island Harbour',
      'George Hill',
      'East End Village',
      'Blowing Point Village',
      'Sandy Hill',
    ],
  },
  {
    country: 'Albania',
    cities: [
      'Xarrë',
      'Sarandë',
      'Mesopotam',
      'Markat',
      'Livadhja',
      'Konispol',
      'Kakavijë',
      'Finiq',
      'Dhivër',
      'Delvinë',
      'Aliko',
      'Buçimas',
      'Zhepë',
      'Zerqan',
      'Zavalinë',
      'Zapod',
      'Zall-Reç',
      'Zall-Dardhë',
      'Xibër-Murrizë',
      'Vreshtas',
      'Voskopojë',
      'Voskop',
      'Vithkuq',
      'Vërtop',
      'Vergo',
      'Vendresha e Vogël',
      'Velçan',
      'Ujmisht',
      'Udënisht',
      'Tunjë',
      'Tregan',
      'Trebisht-Muçinë',
      'Trebinjë',
      'Topojan',
      'Tomin',
      'Tepelenë',
      'Surroj',
      'Sult',
      'Sukë',
      'Suç',
      'Stravaj',
      'Steblevë',
      'Sllovë',
      'Skore',
      'Skënderbegas',
      'Shushicë',
      'Shupenzë',
      'Shtiqën',
      'Shishtavec',
      'Shirgjan',
      'Shëngjin',
      'Shënmëri',
      'Selishtë',
      'Saraqinishtë',
      'Rukaj',
      'Rajcë',
      'Roshnik',
      'Qukës-Skënderbe',
      'Qestorat',
      'Qafëmal',
      'Përrenjas-Fshat',
      'Përrenjas',
      'Proptisht',
      'Progër',
      'Potom',
      'Poroçan',
      'Polis-Gostimë',
      'Poliçan',
      'Pojan',
      'Pogradec',
      'Pishaj',
      'Pirg',
      'Gjinkar',
      'Petran',
      'Peshkopi',
      'Përmet',
      'Ostreni i Math',
      'Orosh',
      'Orenjë',
      'Odrie',
      'Novoselë',
      'Picar',
      'Mollas',
      'Mollaj',
      'Miras',
      'Melan',
      'Mborje',
      'Martanesh',
      'Maqellarë',
      'Maliq',
      'Macukull',
      'Lunik',
      'Llugaj',
      'Lis',
      'Liqenas',
      'Librazhd',
      'Libonik',
      'Libohovë',
      'Leskovik',
      'Leshnjë',
      'Lenias',
      'Lekas',
      'Lazarat',
      'Labinot-Mal',
      'Labinot-Fushë',
      'Kushovë',
      'Kurbnesh',
      'Kukur',
      'Kukës',
      'Kthella e Epërme',
      'Krumë',
      'Korçë',
      'Kolsh',
      'Kodovjat',
      'Klos',
      'Këlcyrë',
      'Kastriot',
      'Kardhiq',
      'Kalis',
      'Iballë',
      'Hotolisht',
      'Hoçisht',
      'Gramsh',
      'Gostimë',
      'Golaj',
      'Gjorica e Sipërme',
      'Gjirokastër',
      'Gjinar',
      'Gjinaj',
      'Gjergjan',
      'Gjegjan',
      'Fushë-Muhurr',
      'Fushë-Lurë',
      'Fushë-Çidhnë',
      'Fushë-Bulqizë',
      'Fushë-Arrëz',
      'Frashër',
      'Fierzë',
      'Fajzë',
      'Ersekë',
      'Elbasan',
      'Drenovë',
      'Dishnicë',
      'Derjan',
      'Gjerbës',
      'Çorovodë',
      'Çlirim',
      'Cerava',
      'Çepan',
      'Çarshovë',
      'Kokaj',
      'Buz',
      'Bushtricë',
      'Burrel',
      'Bulqizë',
      'Bujan',
      'Bradashesh',
      'Blinisht',
      'Blerim',
      'Bilisht',
      'Bicaj',
      'Dardhas',
      'Barmash',
      'Ballaban',
      'Arrën',
      'Arras',
      'Bytyç',
      'Librazhd-Qendër',
      'Funarë',
      'Moglicë',
      'Bogovë',
      'Piskovë',
      'Lukovë',
      'Ksamil',
      'Zharrëz',
      'Zejmen',
      'Zall-Herr',
      'Zall-Bastar',
      'Xhafzotaj',
      'Vukatanë',
      'Vranisht',
      'Vorë',
      'Vlorë',
      'Velipojë',
      'Velabisht',
      'Vaqarr',
      'Ura Vajgurore',
      'Ungrej',
      'Ulëz',
      'Topojë',
      'Tirana',
      'Thumanë',
      'Tërbuf',
      'Synej',
      'Sukth',
      'Strum',
      'Sinjë',
      'Sinaballaj',
      'Shkodër',
      'Shijak',
      'Shezë',
      'Shënkoll',
      'Shëngjergj',
      'Shalës',
      'Nicaj-Shalë',
      'Sevaster',
      'Selitë',
      'Valbonë',
      'Selenicë',
      'Ruzhdie',
      'Rubik',
      'Rrogozhinë',
      'Rrëshen',
      'Rrashbull',
      'Rrasa e Sipërme',
      'Rrapë',
      'Roskovec',
      'Remas',
      'Qerret',
      'Qelëz',
      'Pukë',
      'Progonat',
      'Prezë',
      'Poshnje',
      'Portëz',
      'Peza e Madhe',
      'Petrelë',
      'Përparim',
      'Perondi',
      'Peqin',
      'Patos Fshat',
      'Patos',
      'Paskuqan',
      'Papër',
      'Pajovë',
      'Otllak',
      'Orikum',
      'Nikël',
      'Nicaj-Shosh',
      'Ngraçan',
      'Ndroq',
      'Milot',
      'Memaliaj',
      'Mbrostar-Urë',
      'Manzë',
      'Mamurras',
      'Maminas',
      'Luzi i Vogël',
      'Lushnjë',
      'Luftinjë',
      'Libofshë',
      'Lezhë',
      'Levan',
      'Lekbibaj',
      'Lekaj',
      'Laç',
      'Kutë',
      'Kutalli',
      'Kurjan',
      'Kuman',
      'Kuçovë',
      'Kuç',
      'Kryevidh',
      'Krutja e Poshtme',
      'Krujë',
      'Krrabë',
      'Krahës',
      'Kozarë',
      'Kotë',
      'Koplik',
      'Kombësi',
      'Kolonjë',
      'Kolç',
      'Kavajë',
      'Katundi i Ri',
      'Kastrat',
      'Kashar',
      'Karinë',
      'Karbunara e Vogël',
      'Kamëz',
      'Kallmeti i Madh',
      'Kallmet',
      'Kalenjë',
      'Kajan',
      'Kaçinar',
      'Ishëm',
      'Hysgjokaj',
      'Hot',
      'Himarë',
      'Helmas',
      'Hekal',
      'Hasan',
      'Hajmel',
      'Gurra e Vogël',
      'Guri i Zi',
      'Gruemirë',
      'Greshicë',
      'Grekan',
      'Gradishtë',
      'Gracen',
      'Grabjan',
      'Gosë e Madhe',
      'Golem',
      'Gjoçaj',
      'Gjepalaj',
      'Fushëkuqe',
      'Fushë-Krujë',
      'Fratar',
      'Frakulla e Madhe',
      'Fier-Shegan',
      'Fier-Çifçi',
      'Fier',
      'Farka e Madhe',
      'Durrës',
      'Divjakë',
      'Dhërmi',
      'Dërmënas',
      'Vau i Dejës',
      'Dajt',
      'Dajç',
      'Cukalat',
      'Cudhi Zall',
      'Cërrik',
      'Cakran',
      'Bushat',
      'Bubullimë',
      'Bubq',
      'Brataj',
      'Bërzhitë',
      'Bërxull',
      'Bërdica e Madhe',
      'Berat',
      'Belsh',
      'Baz',
      'Banaj',
      'Ballsh',
      'Ballagat',
      'Baldushk',
      'Armen',
      'Aranitas',
      'Allkaj',
      'Balldreni i Ri',
      'Grudë-Fushë',
      'Dushk',
      'Vllahinë',
      'Bajram Curri',
    ],
  },
  {
    country: 'Armenia',
    cities: [
      'Zarrit’ap’',
      'Zangakatun',
      'Goravan',
      'Yeghegnavan',
      'Yeghegnadzor',
      'Vostan',
      'Vosketap’',
      'Vernashen',
      'Verishen',
      'Vedi',
      'Vayk’',
      'Tegh',
      'Surenavan',
      'Sisavan',
      'Shinuhayr',
      'Lusarrat',
      'Shahumyan',
      'Shatin',
      'Shaghat',
      'Rrind',
      'Noyakert',
      'Mrgavan',
      'Meghri',
      'Malishka',
      'Khndzoresk',
      'Kapan',
      'Jermuk',
      'Goris',
      'Gladzor',
      'Getap’',
      'Dzorastan',
      'Dalar',
      'Burastan',
      'Akner',
      'Brrnakot’',
      'Berk’anush',
      'Aygezard',
      'Aygepat',
      'Aygavan',
      'Avshar',
      'Hats’avan',
      'Artashat',
      'Yeghegis',
      'Armash',
      'Areni',
      'Ararat',
      'Angeghakot’',
      'Aghavnadzor',
      'Agarakadzor',
      'Agarak',
      'Aralez',
      'Horrom',
      'Margara',
      'Zovuni',
      'Zovaber',
      'Zorak',
      'Zarr',
      'Yerevan',
      'Yerazgavors',
      'Yeraskhahun',
      'Yeranos',
      'Yeghegnut',
      'Vagharshapat',
      'Yeghvard',
      'Voskevaz',
      'Voskevan',
      'Voskehask',
      'Verin Getashen',
      'Verin Dvin',
      'Verin Artashat',
      'Akht’ala',
      'Varser',
      'Vardenis',
      'Vardenik',
      'Vahan',
      'Vaghashen',
      'Vahagni',
      'Ushi',
      'Urrut',
      'Tsovinar',
      'Tsovazard',
      'Tsovak',
      'Tsovagyugh',
      'Tsaghkunk’',
      'Tsaghkahovit',
      'Tsaghkaber',
      'Tsaghkadzor',
      'Tsovasar',
      'Tashir',
      'Tandzut',
      'T’alin',
      'Stepanavan',
      'Spitak',
      'Spandaryan',
      'Alashkert',
      'Solak',
      'Sis',
      'Shnogh',
      'Shirak',
      'Shenavan',
      'Sevan',
      'Sarukhan',
      'Sarigyugh',
      'Saratak',
      'Saramej',
      'Sarahart’',
      'Geghakert',
      'Ptghni',
      'Ptghunk’',
      'P’shatavan',
      'Prroshyan',
      'P’ok’r Mant’ash',
      'Zoravan',
      'Pemzashen',
      'Parravak’ar',
      'Oshakan',
      'Sardarapat',
      'Odzun',
      'Noyemberyan',
      'Nor Gyugh',
      'Nor Geghi',
      'Norashen',
      'Nor Armavir',
      'Noramarg',
      'Norakert',
      'Noratus',
      'Nerkin Getashen',
      'Navur',
      'Nalbandyan',
      'Basen',
      'Hovtamej',
      'Mrgashen',
      'Mrgashat',
      'Mrganush',
      'Mosesgegh',
      'Mets Parni',
      'Mets Masrik',
      'Mets Mant’ash',
      'Metsavan',
      'Metsamor',
      'Merdzavan',
      'Meghrashen',
      'Meghradzor',
      'Mayakovski',
      'Masis',
      'Martuni',
      'Marmarashen',
      'Marmashen',
      'Margahovit',
      'Maralik',
      'Mayisyan',
      'Madina',
      'Lukashin',
      'Lorut',
      'Lichk’',
      'Lerrnavan',
      'Lerrnapat',
      'Lerrnants’k’',
      'Lerrnanist',
      'Lerrnakert',
      'Khoronk’',
      'Lchashen',
      'Lchap',
      'Lanjaghbyur',
      'Kotayk’',
      'Kosh',
      'Vanadzor',
      'Hnaberd',
      'Khasht’arrak',
      'Kasakh',
      'Karchaghbyur',
      'Karbi',
      'Karanlukh',
      'Kaputan',
      'K’anak’erravan',
      'Kamo',
      'Gavar',
      'Ijevan',
      'Hrazdan',
      'Armavir',
      'Hayanist',
      'Haghartsin',
      'Gyumri',
      'Gyulagarak',
      'Ghukasavan',
      'Geghamasar',
      'Gugark',
      'Tsiatsan',
      'Goght’',
      'Gogaran',
      'Getazat',
      'Getashen',
      'Geghanist',
      'Geghamavan',
      'Garrni',
      'Gagarin',
      'Fantan',
      'Fioletovo',
      'Dzoraghbyur',
      'Dzoragyugh',
      'Dzit’hank’ov',
      'Jrashen',
      'Jrahovit',
      'Janfida',
      'Dvin',
      'Dsegh',
      'Drakhtik',
      'Doghs',
      'Dilijan',
      'Ddmashen',
      'Darpas',
      'Dalarik',
      'Chochkan',
      'Ch’arents’avan',
      'Chambarak',
      'Bjni',
      'Byureghavan',
      'Byurakan',
      'Buzhakan',
      'Berdavan',
      'Berd',
      'Bazum',
      'Byuravan',
      'Bambakashat',
      'Balahovit',
      'Bagratashen',
      'Azatan',
      'Haykavan',
      'Aygestan',
      'Aygeshat',
      'Aknalich',
      'Aygehovit',
      'Artimet',
      'Astghadzor',
      'Ashtarak',
      'Ashnak',
      'Arzni',
      'Arzakan',
      'Arteni',
      'Arshaluys',
      'Argel',
      'Argavand',
      'Arevshat',
      'Arevik',
      'Arevashogh',
      'Arevashat',
      'Archis',
      'Arbat’',
      'Arazap’',
      'Arrap’i',
      'Aramus',
      'Arak’s',
      'Aragats',
      'Aparan',
      'Apaga',
      'Anushavan',
      'Amasia',
      'Alaverdi',
      'Akunk’',
      'Akhuryan',
      'Aghavnatun',
      'Agarakavan',
      'Abovyan',
      'Vardablur',
      'Bardzrashen',
      'Nshavan',
      'Norabats’',
      'Azatamut',
      'Artsvaberd',
      'Nor Yerznka',
      'Sasunik',
      'Gandzak',
      'Myasnikyan',
      'Lenughi',
      'Gay',
      'Baghramyan',
      'Musalerr',
      'Darakert',
      'Dashtavan',
      'Nizami',
      'Vardadzor',
      'Taronik',
      'Aknashen',
      'Haykashen',
      'Hovtashat',
      'Sayat’-Nova',
      'Voskehat',
      'Griboyedov',
      'Gmbet’',
      'Aygek',
      'Hovtashen',
      'Dimitrov',
      'Mrgavet',
      'Arevabuyr',
    ],
  },
  {
    country: 'Angola',
    cities: [
      'Saurimo',
      'Lucapa',
      'Dundo',
      'Lumeji',
      'Luau',
      'Léua',
      'Cazaji',
      'Uíge',
      'Soio',
      "N'zeto",
      'N’dalatando',
      'Mbanza Kongo',
      'Malanje',
      'Luanda',
      'Caxito',
      'Camabatela',
      'Cabinda',
      'Uacu Cungo',
      'Sumbe',
      'Quibala',
      'Ondjiva',
      'Moçâmedes',
      'Menongue',
      'Luena',
      'Lubango',
      'Longonjo',
      'Lobito',
      'Cuito',
      'Huambo',
      'Chissamba',
      'Catumbela',
      'Catabola',
      'Camacupa',
      'Caconda',
      'Caála',
      'Benguela',
      'Chela',
      'Chinjenje',
    ],
  },
  { country: 'Antarctica', cities: ['McMurdo Station'] },
  {
    country: 'Argentina',
    cities: [
      'Zárate',
      'Yataity Calle',
      'Yapeyú',
      'Wanda',
      'Villa Paranacito',
      'Villa Ortúzar',
      'Villa Ocampo',
      'Villa María Grande',
      'Villa Mantero',
      'Villa Lugano',
      'Villa Hernandarias',
      'Villaguay',
      'Villa Gesell',
      'Villa Escolar',
      'Villa Elisa',
      'Villa de Mayo',
      'Villa del Rosario',
      'Verónica',
      'Veinticinco de Mayo',
      'Urdinarrain',
      'Ubajay',
      'Tres Capones',
      'Tortuguitas',
      'Tigre',
      'Tandil',
      'Tacuarendí',
      'Tabossi',
      'Suipacha',
      'Sauce de Luna',
      'Sauce',
      'San Vicente',
      'Santo Tomé',
      'Santos Lugares',
      'Santo Pipó',
      'Santa Rosa',
      'Santa María',
      'Santa Lucía',
      'Santa Elena',
      'Santa Anita',
      'Santa Ana',
      'San Salvador',
      'San Roque',
      'San Pedro',
      'San Miguel del Monte',
      'San Miguel',
      'San Luis del Palmar',
      'San Lorenzo',
      'San Justo',
      'San José de Feliciano',
      'San José',
      'San Javier',
      'San Isidro',
      'San Ignacio',
      'San Gustavo',
      'San Francisco de Laishí',
      'San Cosme',
      'San Clemente del Tuyú',
      'San Cayetano',
      'San Carlos',
      'San Antonio de Areco',
      'San Andrés de Giles',
      'Saladillo',
      'Saladas',
      'Ruiz de Montoya',
      'Rosario del Tala',
      'Roque Pérez',
      'Riachuelo',
      'Riacho Eh-Eh',
      'Retiro',
      'Resistencia',
      'Reconquista',
      'Rauch',
      'Ranchos',
      'Quilmes',
      'Puerto Yeruá',
      'Puerto Vilelas',
      'Puerto Tirol',
      'Puerto Rico',
      'Puerto Piray',
      'Puerto Libertad',
      'Puerto Leoni',
      'Puerto Iguazú',
      'Puerto Ibicuy',
      'Puerto Esperanza',
      'Puerto Eldorado',
      'Puerto Bermejo',
      'Pueblo Libertador',
      'Pronunciamiento',
      'Presidencia Roca',
      'Presidencia de la Plaza',
      'Posadas',
      'Pontevedra',
      'Pirané',
      'Pinamar',
      'Pilar',
      'Pila',
      'Piedras Blancas',
      'Picada Gobernador López',
      'Perugorría',
      'Pedro R. Fernández',
      'Paso de los Libres',
      'Paso de la Patria',
      'Panambí',
      'Pampa del Indio',
      'Pampa Almirón',
      'Palo Santo',
      'Palmar Grande',
      'Olivos',
      'Oberá',
      'Nueve de Julio',
      'Nuestra Señora del Rosario de Caa Catí',
      'Nogoyá',
      'Necochea',
      'Navarro',
      'Muñiz',
      'Morón',
      'Monte Caseros',
      'Montecarlo',
      'Mojón Grande',
      'Mocoretá',
      'Miramar',
      'Merlo',
      'Mercedes',
      'Mburucuyá',
      'Mártires',
      'Mariano I. Loza',
      'Margarita Belén',
      'Mar del Tuyú',
      'Mar del Plata',
      'Marcos Paz',
      'Malabrigo',
      'Makallé',
      'Maipú',
      'Magdalena',
      'Maciá',
      'Luján',
      'Lucas González',
      'Los Polvorines',
      'Los Helechos',
      'Los Conquistadores',
      'Los Charrúas',
      'Loreto',
      'Lomas de Zamora',
      'Lomas de Vallejos',
      'Lobos',
      'Lobería',
      'Libertad',
      'Leandro N. Alem',
      'La Verde',
      'Las Toscas',
      'Las Garcitas',
      'Las Flores',
      'Larroque',
      'La Plata',
      'La Paz',
      'Lapachito',
      'Lanús',
      'La Leonesa',
      'Laguna Naick-Neck',
      'Laguna Limpia',
      'La Escondida',
      'La Eduvigis',
      'La Cruz',
      'La Criolla',
      'Juan Pujol',
      'Jardín América',
      'Ituzaingó',
      'Itatí',
      'Itá Ibaté',
      'Ibarreta',
      'Hurlingham',
      'Herrera',
      'Herradura',
      'Herlitzka',
      'Hasenkamp',
      'Guaraní',
      'Gualeguaychú',
      'Gualeguay',
      'Goya',
      'Gobernador Roca',
      'Gobernador Mansilla',
      'Gobernador Juan E. Martínez',
      'Gobernador Virasora',
      'General Vedia',
      'General San Martín',
      'General Rodríguez',
      'General Pacheco',
      'General Lavalle',
      'General Las Heras',
      'General Juan Madariaga',
      'General José de San Martín',
      'General Guido',
      'General Galarza',
      'General Conesa',
      'General Campos',
      'General Belgrano',
      'General Alvear',
      'Garupá',
      'Garuhapé',
      'Garruchos',
      'Formosa',
      'Fontana',
      'Florentino Ameghino',
      'Florencio Varela',
      'Felipe Yofré',
      'Federal',
      'Federación',
      'José María Ezeiza',
      'Esquina',
      'Espinillo',
      'Ensenada',
      'Empedrado',
      'El Soberbio',
      'Eldorado',
      'El Colorado',
      'El Alcázar',
      'Dos de Mayo',
      'Dos Arroyos',
      'Don Torcuato',
      'Domínguez',
      'Dolores',
      'Curuzú Cuatiá',
      'Cruz de los Milagros',
      'Coté-Lai',
      'Corrientes',
      'Coronel Vidal',
      'Conscripto Bernardi',
      'Concordia',
      'Concepción del Uruguay',
      'Concepción de la Sierra',
      'Concepción',
      'Comandante Fontana',
      'Colonias Unidas',
      'Colonia Elisa',
      'Colonia Elía',
      'Colonia Benítez',
      'Colonia Aurora',
      'Colón',
      'Colegiales',
      'Clorinda',
      'Ciervo Petiso',
      'Chavarría',
      'Chascomús',
      'Charadai',
      'Chajarí',
      'Cerro Corá',
      'Cerro Azul',
      'Ceibas',
      'Castelli',
      'Caseros',
      'Carmen de Areco',
      'Caraguatay',
      'Capitán Solari',
      'Capitán Sarmiento',
      'Capioví',
      'Capilla del Señor',
      'Cañuelas',
      'Candelaria',
      'Campo Viera',
      'Campo Ramón',
      'Campo Grande',
      'Campana',
      'Buenos Aires',
      'Brandsen',
      'Bovril',
      'Bonpland',
      'Boedo',
      'Berón de Astrada',
      'Bernardo de Irigoyen',
      'Berisso',
      'Berazategui',
      'Benito Juárez',
      'Bella Vista',
      'Belgrano',
      'Belén de Escobar',
      'Basail',
      'Barranqueras',
      'Barracas',
      'Baradero',
      'Balcarce',
      'Azul',
      'Azara',
      'Ayacucho',
      'Avellaneda',
      'Arroyo del Medio',
      'Aristóbulo del Valle',
      'Apóstoles',
      'Alvear',
      'Almafuerte',
      'Aldea San Antonio',
      'Alba Posse',
      'Guernica',
      'Villa Basilio Nievas',
      'Zapala',
      'Yuto',
      'Yerba Buena',
      'Yacimiento Río Turbio',
      'Winifreda',
      'Wenceslao Escalante',
      'Vista Alegre',
      'Vinchina',
      'Villa Valeria',
      'Villa Urquiza',
      'Villa Unión',
      'Villa Tulumba',
      'Villa Trinidad',
      'Villa Santa Rosa',
      'Villa Rumipal',
      'Villa Regina',
      'Villa Reducción',
      'Villa Paula de Sarmiento',
      'Villa Ojo de Agua',
      'Villa Nueva',
      'Villa Mugueta',
      'Villa Media Agua',
      'Villa María',
      'Villa Las Rosas',
      'Villa La Angostura',
      'Villa Krause',
      'Villa Huidobro',
      'Villa Giardino',
      'Villa General Roca',
      'Villa General Mitre',
      'Villa General Belgrano',
      'Villa Dolores',
      'Villa de Soto',
      'Villa de María',
      'Villa del Totoral',
      'Villa del Salvador',
      'Villa del Dique',
      'Villa Cura Brochero',
      'Villa Constitución',
      'Villa Concepción del Tío',
      'Chañar Ladeado',
      'Villa Castelli',
      'Villa Carlos Paz',
      'Villa Cañás',
      'Villa Bustos',
      'Villa Berthet',
      'Villa Atamisqui',
      'Villa Ascasubi',
      'Villa Ángela',
      'Villa Allende',
      'Villa Aberastain',
      'Viedma',
      'Vicuña Mackenna',
      'Victorica',
      'Victoria',
      'Viamonte',
      'Viale',
      'Vera',
      'Venado Tuerto',
      '28 de Noviembre',
      'Vedia',
      'Valle Hermoso',
      'Valle Grande',
      'Valcheta',
      'Ushuaia',
      'Uriburu',
      'Unquillo',
      'Unión',
      'Ulapes',
      'Ucacha',
      'Tupungato',
      'Tunuyán',
      'Tumbaya',
      'Trevelin',
      'Tres Lomas',
      'Tres Isletas',
      'Tres Arroyos',
      'Tres Algarrobos',
      'Trenque Lauquen',
      'Trenel',
      'Trelew',
      'Trancas',
      'Totoras',
      'Tostado',
      'Tornquist',
      'Toledo',
      'Toay',
      'Tío Pujio',
      'Tintina',
      'Tinogasta',
      'Tilisarao',
      'Tilcara',
      'Ticino',
      'Termas de Río Hondo',
      'Telsen',
      'Telén',
      'Tecka',
      'Tartagal',
      'Tapalqué',
      'Tancacha',
      'Tamberías',
      'Tama',
      'Tafí Viejo',
      'Tafí del Valle',
      'Taco Pozo',
      'Susques',
      'Suncho Corral',
      'Sunchales',
      'Sumampa',
      'Simoca',
      'Sierra Grande',
      'Sierra Colorada',
      'Serrezuela',
      'Serrano',
      'Senillosa',
      'Selva',
      'Seguí',
      'Sebastián Elcano',
      'Saujil',
      'Saturnino M. Laspiur',
      'Sastre',
      'Sarmiento',
      'Santiago Temple',
      'Santiago del Estero',
      'Santa Victoria',
      'Santa Sylvina',
      'Santa Rosa de Tastil',
      'Santa Rosa de Río Primero',
      'Santa Rosa del Conlara',
      'Santa Rosa de Calamuchita',
      'Santa Magdalena',
      'Santa Isabel',
      'Santa Fe',
      'Santa Eufemia',
      'Santa Clara',
      'Santa Catalina',
      'San Salvador de Jujuy',
      'San Ramón de la Nueva Orán',
      'San Rafael',
      'San Pedro de Guasayán',
      'San Pedro de Jujuy',
      'Sañogasta',
      'San Nicolás de los Arroyos',
      'San Miguel de Tucumán',
      'San Martín de los Andes',
      'San Martín',
      'San Luis',
      'San Juan',
      'San José de la Dormida',
      'San José de Jáchal',
      'San Jorge',
      'San Francisco del Monte de Oro',
      'San Francisco del Chañar',
      'San Francisco',
      'Catamarca',
      'San Cristóbal',
      'San Carlos de Bolívar',
      'San Carlos Centro',
      'San Blas de los Sauces',
      'San Bernardo',
      'San Benito',
      'San Basilio',
      'San Antonio Oeste',
      'San Antonio de los Cobres',
      'San Antonio de Litín',
      'San Antonio',
      'San Agustín de Valle Fértil',
      'San Agustín',
      'Samuhú',
      'Sampacho',
      'Salto',
      'Salta',
      'Salsipuedes',
      'Salsacate',
      'Salliqueló',
      'Saldán',
      'Sacanta',
      'Rufino',
      'Rosario de Lerma',
      'Rosario de la Frontera',
      'Rosario',
      'Roldán',
      'Rojas',
      'Rodeo',
      'Rivadavia',
      'Río Tercero',
      'Río Segundo',
      'Río Primero',
      'Río Pico',
      'Río Mayo',
      'Río Grande',
      'Río Gallegos',
      'Río Cuarto',
      'Río Colorado',
      'Río Ceballos',
      'Rinconada',
      'Recreo',
      'Realicó',
      'Rawson',
      'Rancul',
      'Ramallo',
      'Rafaela',
      'Rada Tilly',
      'Quitilipi',
      'Quimilí',
      'Quilino',
      'Quemú Quemú',
      'Punta Alta',
      'Puerto Santa Cruz',
      'Puerto San Julián',
      'Puerto Madryn',
      'Puerto Deseado',
      'Puerta de Corral Quemado',
      'Puelches',
      'Puan',
      'Presidencia Roque Sáenz Peña',
      'Pozo Hondo',
      'Pozo del Tigre',
      'Pozo del Molle',
      'Porteña',
      'Pomán',
      'Pocito',
      'Plottier',
      'Plaza Huincul',
      'Piquillín',
      'Pilcaniyeu',
      'Pigüé',
      'Piedra del Águila',
      'Picún Leufú',
      'Pico Truncado',
      'Perito Moreno',
      'Pergamino',
      'Pérez',
      'Pellegrini',
      'Pehuajó',
      'Patquía',
      'Paso de Indios',
      'Pasco',
      'Pascanas',
      'Parera',
      'Paraná',
      'Pampa de los Guanacos',
      'Pampa del Infierno',
      'Palpalá',
      'Palma Sola',
      'Oro Verde',
      'Ordóñez',
      'Oncativo',
      'Olta',
      'Oliva',
      'Olavarría',
      'Obispo Trejo',
      'Nueva Esperanza',
      'Ñorquinco',
      'Nono',
      'Noetinger',
      'Neuquén',
      'Naschel',
      'Napenay',
      'Mutquín',
      'Morteros',
      'Morrison',
      'Monteros',
      'Monte Quemado',
      'Monte Maíz',
      'Monte Hermoso',
      'Monte Cristo',
      'Monte Buey',
      'Molinos',
      'Mina Clavero',
      'Milagro',
      'Miguel Riglos',
      'Metán',
      'Mendoza',
      'Mendiolaza',
      'Melincué',
      'Médanos',
      'Mattaldi',
      'Marull',
      'Mariano Moreno',
      'Marcos Juárez',
      'Maquinchao',
      'Malvinas Argentinas',
      'Malargüe',
      'Malanzán',
      'Malagueño',
      'Mainque',
      'Maimará',
      'Machagai',
      'Macachín',
      'San Isidro de Lules',
      'Luján de Cuyo',
      'Los Varela',
      'Los Telares',
      'Los Surgentes',
      'Los Menucos',
      'Los Juríes',
      'Los Frentones',
      'Los Cóndores',
      'Los Cocos',
      'Los Antiguos',
      'Los Altos',
      'Lonquimay',
      'Londres',
      'Loncopué',
      'Lincoln',
      'Limay Mahuida',
      'Libertador General San Martín',
      'Leones',
      'Leleque',
      'La Viña',
      'La Toma',
      'La Tigra',
      'Las Varillas',
      'Las Varas',
      'Las Rosas',
      'Las Plumas',
      'Las Perdices',
      'Las Parejas',
      'Las Ovejas',
      'Las Lomitas',
      'Las Lajitas',
      'Las Lajas',
      'Las Junturas',
      'Las Higueras',
      'Las Heras',
      'Las Coloradas',
      'Las Breñas',
      'Las Acequias',
      'La Rioja',
      'La Quiaca',
      'La Puerta de San José',
      'La Puerta',
      'Laprida',
      'La Poma',
      'La Playosa',
      'La Para',
      'La Merced',
      'La Mendieta',
      'La Maruja',
      'Lamarque',
      'Laguna Yema',
      'Laguna Paiva',
      'Laguna Larga',
      'La Granja',
      'Lago Puelo',
      'La Francia',
      'La Falda',
      'La Cumbre',
      'La Consulta',
      'La Cocha',
      'La Clotilde',
      'La Cesira',
      'La Carlota',
      'La Candelaria',
      'La Cañada',
      'La Calera',
      'La Caldera',
      'Laboulaye',
      'Laborde',
      'La Banda',
      'Justo Daract',
      'Justiniano Posse',
      'Junín de los Andes',
      'Junín',
      'José de San Martín',
      'Joaquín V. González',
      'Jesús María',
      'James Craik',
      'Jacinto Arauz',
      'Italó',
      'Isla Verde',
      'Iruya',
      'Intendente Alvear',
      'Inriville',
      'Ingenio La Esperanza',
      'Ingeniero Luis A. Huergo',
      'Ingeniero Luiggi',
      'Ingeniero Jacobacci',
      'Ingeniero Guillermo N. Juárez',
      'Idiazábal',
      'Icaño',
      'Humahuaca',
      'Huinca Renancó',
      'Huillapima',
      'Huerta Grande',
      'Huanchillas',
      'Hualfín',
      'Hoyo de Epuyén',
      'Hersilia',
      'Hernando',
      'Hernández',
      'Hermoso Campo',
      'Henderson',
      'Helvecia',
      'Guatraché',
      'Guatimozín',
      'Guaminí',
      'Guachipas',
      'Graneros',
      'Granadero Baigorria',
      'Godoy Cruz',
      'Gobernador Gregores',
      'Gobernador Gálvez',
      'Gobernador Costa',
      'General Villegas',
      'General Viamonte',
      'General Roca',
      'General Ramírez',
      'General Pinto',
      'General Pinedo',
      'General Pico',
      'General Martín Miguel de Güemes',
      'General Manuel J. Campos',
      'General Levalle',
      'General La Madrid',
      'General Fernández Oro',
      'General Enrique Mosconi',
      'General Mosconi',
      'General Enrique Godoy',
      'General Cabrera',
      'General Baldissera',
      'General Arenales',
      'General Acha',
      'Gastre',
      'Garza',
      'Gancedo',
      'Gálvez',
      'Gaimán',
      'Funes',
      'Frías',
      'Fray Luis Beltrán',
      'Fray Luis A. Beltrán',
      'Fraile Pintado',
      'Firmat',
      'Fiambalá',
      'Fernández',
      'Famatina',
      'Famaillá',
      'Etruria',
      'Estanislao del Campo',
      'Esquel',
      'Esperanza',
      'Embarcación',
      'Embalse',
      'Embajador Martini',
      'El Trébol',
      'El Tío',
      'El Simbolar',
      'El Rodeo',
      'El Quebrachal',
      'El Maitén',
      'El Huecú',
      'El Hoyo',
      'El Galpón',
      'Elena',
      'El Cuy',
      'El Carril',
      'El Carmen',
      'El Calafate',
      'El Bolsón',
      'El Arañado',
      'El Alto',
      'El Aguilar',
      'Eduardo Castex',
      'Dolavón',
      'Doblas',
      'Diamante',
      'Devoto',
      'Despeñaderos',
      'Del Campillo',
      'Deán Funes',
      'Darwin',
      'Dalmacio Vélez Sársfield',
      'Daireaux',
      'Cutral-Có',
      'Cuchillo Có',
      'Cruz del Eje',
      'Cruz Alta',
      'Crespo',
      'Costa Sacate',
      'Cosquín',
      'Corzuela',
      'Corralito',
      'Corral de Bustos',
      'Coronel Suárez',
      'Coronel Pringles',
      'Coronel Moldes',
      'Coronel Du Graty',
      'Coronel Dorrego',
      'Coronel Belisle',
      'Coronel Baigorria',
      'Coronda',
      'Córdoba',
      'Contraalmirante Cordero',
      'Concepción del Bermejo',
      'Concarán',
      'Comodoro Rivadavia',
      'Comandante Luis Piedra Buena',
      'Comallo',
      'Colonia San Bartolomé',
      'Colonia La Tordilla',
      'Colonia Dora',
      'Colonia Barón',
      'Clodomira',
      'Cipolletti',
      'Cintra',
      'Cinco Saltos',
      'Chumbicha',
      'Chos Malal',
      'Chorotis',
      'Choele Choel',
      'Chivilcoy',
      'Chimpay',
      'Chimbas',
      'Chilecito',
      'Chicoana',
      'Chichinales',
      'Chepes',
      'Chazón',
      'Charras',
      'Charata',
      'Chamical',
      'Chacabuco',
      'Cervantes',
      'Cerrillos',
      'Ceres',
      'Centenario',
      'Cavanagh',
      'Caucete',
      'Catriló',
      'Catriel',
      'Castro Barros',
      'Casilda',
      'Carrilobo',
      'Carnerillo',
      'Carmen de Patagones',
      'Carlos Tejedor',
      'Carlos Casares',
      'Carhué',
      'Carcarañá',
      'Capitán Bermúdez',
      'Capilla del Monte',
      'Capayán',
      'Canals',
      'Cañada de Luque',
      'Cañada de Gómez',
      'Campo Quijano',
      'Campo Largo',
      'Campo Gallo',
      'Camilo Aldao',
      'Camarones',
      'Calingasta',
      'Calilegua',
      'Caleufú',
      'Caleta Olivia',
      'Calchaquí',
      'Caimancito',
      'Cafayate',
      'Cachí',
      'Buta Ranquil',
      'Burruyacú',
      'Buena Esperanza',
      'Buchardo',
      'Brinkmann',
      'Brea Pozo',
      'Bragado',
      'Berrotarán',
      'Bernasconi',
      'Bernardo Larroudé',
      'Beltrán',
      'Bell Ville',
      'Belén',
      'Barrancas',
      'Bandera',
      'Banda del Río Salí',
      'Bañado de Ovanta',
      'Balnearia',
      'Bahía Blanca',
      'Aviá Terai',
      'Arrufó',
      'Arroyo Seco',
      'Arroyo Cabral',
      'Arroyito',
      'Arrecifes',
      'Arraga',
      'Armstrong',
      'Arias',
      'Arauco',
      'Arata',
      'Aranguren',
      'Apolinario Saravia',
      'Antofagasta de la Sierra',
      'Anguil',
      'Añelo',
      'Andalgalá',
      'Andacollo',
      'Ancasti',
      'Añatuya',
      'Aminga',
      'Aluminé',
      'Altos de Chipión',
      'Alto Río Senguer',
      'Alta Italia',
      'Alta Gracia',
      'Alpachiri',
      'Allen',
      'Algarrobo del Águila',
      'Alejo Ledesma',
      'Alejandro Roca',
      'Alderetes',
      'Alberti',
      'Albardón',
      'Aimogasta',
      'Aguilares',
      'Agua de Oro',
      'Adelia María',
      'Achiras',
      'Abra Pampa',
      'Balvanera',
      'Villa Santa Rita',
      'Bella Italia',
      'Cariló',
      'Villa Mercedes',
      'La Punta',
      'Villa Berna',
      'Cuesta Blanca',
      'San Carlos de Bariloche',
      'Santa Catalina - Dique Lujan',
      'Río Turbio',
      'Adrogué',
      'Los Laureles',
      'Gato Colorado',
      'Tolhuin',
      'Villa General Guemes',
      'Ingeniero Pablo Nogués',
      'Rincón de Los Sauces',
      'La Adela',
      'José C. Paz',
    ],
  },
  {
    country: 'American Samoa',
    cities: [
      'Aūa',
      'Vaitogi',
      'Vailoatai',
      'Taulaga',
      'Ta`ū',
      'Tāfuna',
      'Faleniu',
      'Fagatogo',
      'Faleāsao',
      'Leone',
      'Malaeimi',
      'Mapusagafou',
      'Ofu',
      'Pago Pago',
    ],
  },
  {
    country: 'Austria',
    cities: [
      'Neu-Guntramsdorf',
      'Kleinarl',
      'Edelstauden',
      'Neuhaus am Klausenbach',
      'Millstatt',
      'Zwölfaxing',
      'Zwettl Stadt',
      'Zwettl an der Rodl',
      'Zwentendorf',
      'Zurndorf',
      'Zöblen',
      'Zöbern',
      'Zistersdorf',
      'Zirl',
      'Zillingtal',
      'Zillingdorf',
      'Ziersdorf',
      'Zeutschach',
      'Zettling',
      'Zerlach',
      'Zeltweg',
      'Zellerndorf',
      'Zellberg',
      'Zell an der Pram',
      'Zell am Ziller',
      'Zell am See',
      'Zell am Pettenfirst',
      'Zell am Moos',
      'Zell',
      'Zeiselmauer',
      'Zeillern',
      'Zederhaus',
      'Zams',
      'Zagersdorf',
      'Ybbsitz',
      'Ybbs an der Donau',
      'Würmla',
      'Würflach',
      'Wundschuh',
      'Wullersdorf',
      'Wulkaprodersdorf',
      'Wörth an der Lafnitz',
      'Wörterberg',
      'Wörschach',
      'Wörgl',
      'Wördern',
      'Wöllersdorf',
      'Wolkersdorf im Weinviertel',
      'Wolfurt',
      'Wolfsthal',
      'Wolfsgraben',
      'Wolfsegg am Hausruck',
      'Wolfsberg im Schwarzautal',
      'Wolfsberg',
      'Wolfsbach',
      'Wolfpassing',
      'Wölfnitz',
      'Wolfau',
      'Wippenham',
      'Winzendorf',
      'Winklern bei Oberwölz',
      'Winklern',
      'Winklarn',
      'Windischgarsten',
      'Windigsteig',
      'Windhag',
      'Windhaag bei Perg',
      'Windhaag bei Freistadt',
      'Winden am See',
      'Wimpassing an der Leitha',
      'Wimpassing',
      'Wimpassing im Schwarzatale',
      'Wilten',
      'Willendorf am Steinfelde',
      'Wilhelmsburg',
      'Wilfleinsdorf',
      'Wilfersdorf',
      'Wildon',
      'Wildermieming',
      'Wildalpen',
      'Wiesmath',
      'Wiesing',
      'Wiesfleck',
      'Wiesen',
      'Wieselburg',
      'Wies',
      'Wienersdorf',
      'Wiener Neustadt',
      'Wiener Neudorf',
      'Vienna',
      'Weyregg',
      'Weyer',
      'Wetzelsdorf',
      'Wettmannstätten',
      'Westendorf',
      'Wernstein am Inn',
      'Wernersdorf',
      'Werndorf',
      'Wernberg',
      'Werfenweng',
      'Werfen',
      'Weppersdorf',
      'Wenns',
      'Weng im Innkreis',
      'Wendling',
      'Wels',
      'Weiz',
      'Weitra',
      'Weitersfelden',
      'Weitersfeld',
      'Weitensfeld',
      'Weiten',
      'Weistrach',
      'Weißkirchen in Steiermark',
      'Weisskirchen an der Traun',
      'Weißenkirchen in der Wachau',
      'Weißenbach bei Liezen',
      'Weissenbach an der Triesting',
      'Weissenbach am Lech',
      'Weissach',
      'Weinzierl bei Krems',
      'Weinzierl am Walde',
      'Weingraben',
      'Weinburg am Saßbach',
      'Weinburg',
      'Weiler',
      'Weilbach',
      'Weikersdorf am Steinfelde',
      'Weikendorf',
      'Weigelsdorf',
      'Weidling',
      'Weiden bei Rechnitz',
      'Weiden am See',
      'Weichselbaum',
      'Weibern',
      'Weer',
      'Wattens',
      'Wattenberg',
      'Wartmannstetten',
      'Warth',
      'Wartberg ob der Aist',
      'Wartberg an der Krems',
      'Wartberg',
      'Wängle',
      'Wang',
      'Waltendorf',
      'Wals',
      'Walpersbach',
      'Wallsee',
      'Wallern im Burgenland',
      'Wallern an der Trattnach',
      'Waldzell',
      'Waldprechting',
      'Waldneukirchen',
      'Waldkirchen an der Thaya',
      'Waldkirchen am Wesen',
      'Walding',
      'Wald im Pinzgau',
      'Waldhausen',
      'Waldenstein',
      'Waldegg',
      'Wald am Schoberpaß',
      'Walchsee',
      'Walchen',
      'Waizenkirchen',
      'Waiern',
      'Waidring',
      'Waidmannsfeld',
      'Waidhofen an der Ybbs',
      'Waidhofen an der Thaya',
      'Wagram',
      'Wagrain',
      'Wagnitz',
      'Wagna',
      'Vösendorf',
      'Murau',
      'Vorderthiersee',
      'Vordernberg',
      'Vorderhornbach',
      'Vorchdorf',
      'Vorau',
      'Vomp',
      'Völs',
      'Völkermarkt',
      'Völkendorf',
      'Voldöpp',
      'Volders',
      'Voitsberg',
      'Vöcklamarkt',
      'Vöcklabruck',
      'Vitis',
      'Virgen',
      'Vils',
      'Villach',
      'Viktring',
      'Viktorsberg',
      'Bad Vigaun',
      'Viehofen',
      'Viehhofen',
      'Viehhausen',
      'Viechtwang',
      'Velm',
      'Velden am Wörthersee',
      'Veitsch',
      'Utzenaich',
      'Uttendorf',
      'Utschtal',
      'Unterwart',
      'Unterwaltersdorf',
      'Unterwagram',
      'Untertauern',
      'Unterstinkenbrunn',
      'Untersiebenbrunn',
      'Rohrbach an der Gölsen',
      'Unterpremstätten',
      'Unterperfuss',
      'Unterlangkampfen',
      'Unterlamm',
      'Unterkohlstätten',
      'Grünburg',
      'Unterfrauenhaid',
      'Unterfladnitz',
      'Untere Fellach',
      'Unterbergla',
      'Unterauersbach',
      'Unken',
      'Ungerdorf',
      'Ungenach',
      'Umhausen',
      'Ulrichskirchen',
      'Ulrichsberg',
      'Uderns',
      'Übersbach',
      'Übersaxen',
      'Übelbach',
      'Tweng',
      'Türnitz',
      'Turnau',
      'Tulln',
      'Tulfes',
      'Tulbing',
      'Tschanigraben',
      'Tschagguns',
      'Trumau',
      'Trössing',
      'Trofaiach',
      'Tristach',
      'Trins',
      'Triebendorf',
      'Trieben',
      'Tribuswinkel',
      'Treibach',
      'Treglwang',
      'Trebesing',
      'Trautmannsdorf in Oststeiermark',
      'Trautmannsdorf an der Leitha',
      'Trausdorf an der Wulka',
      'Bad Traunstein',
      'Traunkirchen',
      'Traun',
      'Trattenbach',
      'Traismauer',
      'Traiskirchen',
      'Traisen',
      'Trahütten',
      'Tragwein',
      'Traboch',
      'Tobaj',
      'Tobadill',
      'Timelkam',
      'Tieschen',
      'Tiefgraben',
      'Thüringen',
      'Thumersbach',
      'Thörl',
      'Thomatal',
      'Thomasberg',
      'Theresienfeld',
      'Thaya',
      'Thaur',
      'Thalheim bei Wels',
      'Thalgau',
      'Teufenbach',
      'Ternitz',
      'Ternberg',
      'Terfens',
      'Telfs',
      'Telfes im Stubai',
      'Teesdorf',
      'Taxenbach',
      'Taxach',
      'Tauplitz',
      'Taufkirchen an der Trattnach',
      'Tattendorf',
      'Tarrenz',
      'Tannheim',
      'Tamsweg',
      'Taiskirchen im Innkreis',
      'Tadten',
      'Sulzberg',
      'Sulz',
      'Sulztal an der Weinstraße',
      'Stummerberg',
      'Stumm',
      'Stuhlfelden',
      'Studenzen',
      'Stubenberg',
      'Stronsdorf',
      'Strobl',
      'Strengen',
      'Strengberg',
      'Strem',
      'Stratzing',
      'Strasswalchen',
      'Straß in Steiermark',
      'Strasshof an der Nordbahn',
      'Strassham',
      'Straßgang',
      'Strassengel',
      'Strassen',
      'Straßburg-Stadt',
      'Strass im Zillertal',
      'Strass',
      'Strallegg',
      'Straden',
      'Stotzing',
      'Stössing',
      'Stoob',
      'Stollhofen',
      'Stockerau',
      'Stockenboi',
      'Stiwoll',
      'Steinbrunn',
      'Stinatz',
      'Steyregg',
      'Steyr',
      'Steuerberg',
      'Stetten',
      'Stetteldorf am Wagram',
      'Stephanshart',
      'Stenzengreith',
      'Steinhaus',
      'Steinfeld',
      'Steinerkirchen an der Traun',
      'Steindorf am Ossiacher See',
      'Steinbach am Attersee',
      'Stein an der Donau',
      'Steinakirchen am Forst',
      'Steinach am Brenner',
      'Steinabrückl',
      'Stein',
      'Stegersbach',
      'Steeg',
      'Statzendorf',
      'Stattersdorf',
      'Stattegg',
      'Stanz bei Landeck',
      'Stanzach',
      'Stans',
      'Stams',
      'Stambach',
      'Stallhofen',
      'Stallhof',
      'Stallehr',
      'Stall',
      'Stainz bei Straden',
      'Stainz',
      'Stainach',
      'Stadtschlaining',
      'Stadl-Traun',
      'Stadl-Paura',
      'Stadl-Hausruck',
      'Stadl an der Mur',
      'Staatz',
      'Spratzern',
      'Spitz',
      'Spittal an der Drau',
      'Spital am Semmering',
      'Spital am Pyhrn',
      'Spillern',
      'Spielfeld',
      'Spielberg bei Knittelfeld',
      'Spatenhof',
      'Spannberg',
      'Sooss',
      'Sonntagberg',
      'Sommerein',
      'Sollenau',
      'Söll',
      'Sölden',
      'Hall in Tirol',
      'Södingberg',
      'Söchau',
      'Soboth',
      'Sitzendorf an der Schmida',
      'Sittersdorf',
      'Sistrans',
      'Sinabelkirchen',
      'Simmering',
      'Silz',
      'Sillian',
      'Silbertal',
      'Sigmundsherberg',
      'Sigless',
      'Siezenheim',
      'Sierning',
      'Sierndorf',
      'Sieghartskirchen',
      'Sieggraben',
      'Siegersdorf bei Herberstein',
      'Siegendorf im Burgenland',
      'Sibratsgfäll',
      'Seyring',
      'Serfaus',
      'Senftenberg',
      'Semriach',
      'Semmering',
      'Selzthal',
      'Seitenstetten Markt',
      'Seiersberg',
      'Seibersdorf',
      'Seggauberg',
      'Seewalchen',
      'Seekirchen am Wallersee',
      'Seeham',
      'Seefeld in Tirol',
      'Seeboden',
      'Seebenstein',
      'Seebach',
      'See',
      'Seckau',
      'Sebersdorf',
      'Schwoich',
      'Schwertberg',
      'Schwendt',
      'Schwendau',
      'Schweinbach',
      'Schweiggers',
      'Schwechat',
      'Schwaz',
      'Schwarzenbach an der Pielach',
      'Schwarzenbach',
      'Schwarzenau',
      'Schwarzau im Schwarzautal',
      'Schwarzau im Gebirge',
      'Schwarzau am Steinfeld',
      'Schwarzach im Pongau',
      'Schwarzach',
      'Schwanenstadt',
      'Schwanberg',
      'Schwadorf',
      'Schützen am Gebirge',
      'Schruns',
      'Schrems bei Frohnleiten',
      'Schrems',
      'Schrattenthal',
      'Schrattenberg',
      'Schrattenbach',
      'Schottwien',
      'Schörfling',
      'Schoppernau',
      'Schönwies',
      'Schönering',
      'Schöndorf',
      'Schönberg im Stubaital',
      'Schönbach',
      'Schönau an der Triesting',
      'Schöder',
      'Schnifis',
      'Schnepfau',
      'Schmirn',
      'Schlüsslberg',
      'Schlitters',
      'Schlins',
      'Schlierbach',
      'Schleinbach',
      'Schleedorf',
      'Schlatt',
      'Schladming',
      'Schildorn',
      'Schiefling am See',
      'Schenkenfelden',
      'Scheifling',
      'Scheibbs',
      'Scheffau am Wilden Kaiser',
      'Scheffau am Tennengebirge',
      'Schattwald',
      'Schattendorf',
      'Scharnstein',
      'Scharnitz',
      'Scharndorf',
      'Schärding',
      'Schardenberg',
      'Schandorf',
      'Schalchham',
      'Schalchen',
      'Schafwiesen',
      'Schäffern',
      'Schachendorf',
      'Sautens',
      'Bad Sauerbrunn',
      'Sattledt',
      'Satteins',
      'Sarleinsbach',
      'Sankt Wolfgang im Salzkammergut',
      'Sankt Veit in Defereggen',
      'Sankt Veit im Pongau',
      'Sankt Veit an der Gölsen',
      'Sankt Veit an der Glan',
      'Sankt Veit am Vogau',
      'Sankt Valentin',
      'Sankt Ulrich bei Steyr',
      'Sankt Ulrich am Waasen',
      'Sankt Ulrich am Pillersee',
      'Sankt Stefan ob Stainz',
      'Sankt Stefan ob Leoben',
      'Sankt Stefan im Rosental',
      'Sankt Stefan',
      'Sankt Ruprecht an der Raab',
      'Sankt Ruprecht',
      'Sankt Roman',
      'Sankt Radegund bei Graz',
      'Sankt Pölten',
      'Sankt Peter ob Judenburg',
      'Sankt Peter in der Au Markt',
      'Sankt Peter im Sulmtal',
      'Sankt Peter-Freienstein',
      'Sankt Peter am Ottersbach',
      'Sankt Peter am Kammersberg',
      'Sankt Peter',
      'Sankt Peter am Hart',
      'Sankt Paul im Lavanttal',
      'Sankt Pantaleon',
      'Sankt Oswald bei Plankenwarth',
      'Sankt Oswald bei Freistadt',
      'Sankt Nikolai ob Draßling',
      'Sankt Nikolai im Sölktal',
      'Sankt Nikolai im Sausal',
      'Sankt Michael in Obersteiermark',
      'Sankt Michael im Lungau',
      'Sankt Michael im Burgenland',
      'Sankt Martin im Sulmtal',
      'Sankt Martin im Mühlkreis',
      'Sankt Martin im Innkreis',
      'Sankt Martin bei Lofer',
      'Sankt Martin an der Raab',
      'Sankt Martin am Ybbsfelde',
      'Sankt Martin am Grimming',
      'Sankt Martin',
      'Sankt Marien',
      'Sankt Margareten im Rosental',
      'Sankt Margarethen im Lungau',
      'Sankt Margarethen im Burgenland',
      'Sankt Margarethen bei Knittelfeld',
      'Sankt Margarethen an der Sierning',
      'Sankt Margarethen an der Raab',
      'Sankt Marein im Mürztal',
      'Sankt Marein bei Neumarkt',
      'Sankt Marein bei Knittelfeld',
      'Sankt Marein bei Graz',
      'Sankt Magdalen',
      'Sankt Lorenzen im Mürztal',
      'Sankt Lorenzen bei Scheifling',
      'Sankt Lorenzen bei Knittelfeld',
      'Sankt Lorenzen am Wechsel',
      'Sankt Lorenz',
      'Sankt Leonhard am Hornerwald',
      'Sankt Leonhard am Forst',
      'Sankt Leonhard',
      'Sankt Lambrecht',
      'Sankt Kathrein am Offenegg',
      'Sankt Kathrein am Hauenstein',
      'Sankt Katharein an der Laming',
      'Sankt Josef (Weststeiermark)',
      'Sankt Johann in Tirol',
      'Sankt Johann in der Haide',
      'Sankt Johann im Saggautal',
      'Sankt Johann im Pongau',
      'Sankt Johann bei Herberstein',
      'Sankt Johann am Tauern',
      'Sankt Jakob im Walde',
      'Sankt Jakob-Breitenau',
      'Sankt Ilgen',
      'Sankt Gotthard im Mühlkreis',
      'Sankt Gilgen',
      'Sankt Georgen ob Murau',
      'Sankt Georgen ob Judenburg',
      'Sankt Georgen im Attergau',
      'Sankt Georgen bei Obernberg am Inn',
      'Sankt Georgen an der Stiefing',
      'Sankt Georgen an der Leys',
      'Sankt Georgen an der Gusen',
      'Sankt Georgen am Ybbsfelde',
      'Sankt Georgen am Leithagebirge',
      'Sankt Georgen am Fillmannsbach',
      'Sankt Gallenkirch',
      'Sankt Gallen',
      'Sankt Dionysen',
      'Sankt Corona am Wechsel',
      'Sankt Blasen',
      'Sankt Bartholomä',
      'Sankt Anton im Montafon',
      'St Anton am Arlberg',
      'Sankt Anna am Aigen',
      'Sankt Andrä vor dem Hagenthale',
      'Sankt Andrä im Lungau',
      'Sankt Andrä am Zicksee',
      'Sankt Andrä',
      'Sankt Aegyd am Neuwalde',
      'Sankt Aegidi',
      'Sammersdorf',
      'Salzburg',
      'Sallingberg',
      'Salla',
      'Sachsenburg',
      'Saalfelden am Steinernen Meer',
      'Saalbach',
      'Rutzenham',
      'Rüstorf',
      'Rust',
      'Ruprechtshofen',
      'Rum',
      'Rufling',
      'Rudersdorf',
      'Ruden',
      'Rottenmann',
      'Röthis',
      'Röthelstein',
      'Rotenturm an der Pinka',
      'Rosenau am Sonntagberg',
      'Rosegg',
      'Röschitz',
      'Roppen',
      'Röns',
      'Roitham',
      'Rohr im Gebirge',
      'Rohr im Burgenland',
      'Röhrenbach',
      'Rohrbach in Oberösterreich',
      'Rohrbach bei Mattersburg',
      'Rohrbach an der Lafnitz',
      'Rohrau',
      'Ritzing',
      'Rinn',
      'Riezlern',
      'Rietz',
      'Ries',
      'Riegersburg',
      'Riefensberg',
      'Riedlingsdorf',
      'Ried in der Riedmark',
      'Ried im Traunkreis',
      'Ried im Oberinntal',
      'Ried im Innkreis',
      'Reutte',
      'Reuthe',
      'Retznei',
      'Retz',
      'Rettenschöss',
      'Rettenegg',
      'Rennweg am Katschberg',
      'Reith bei Seefeld',
      'Reith bei Kitzbühel',
      'Reith im Alpbachtal',
      'Reiterndorf',
      'Reisenberg',
      'Reinsberg',
      'Reingers',
      'Reifling',
      'Reichraming',
      'Reichenthal',
      'Reichenfels',
      'Reichendorf',
      'Reichenau im Mühlkreis',
      'Reichenau an der Rax',
      'Rehberg',
      'Redlham',
      'Reding',
      'Rechnitz',
      'Raxendorf',
      'Ravelsbach',
      'Rauris',
      'Rauchenwarth',
      'Rattenberg',
      'Ratten',
      'Ratschendorf',
      'Ratsch an der Weinstraße',
      'Rastenfeld',
      'Rassach',
      'Rappottenstein',
      'Ranten',
      'Ranshofen',
      'Rannersdorf',
      'Rankweil',
      'Raning',
      'Ranggen',
      'Rangersdorf',
      'Randegg',
      'Ramsau',
      'Ramsau am Dachstein',
      'Ramsau im Zillertal',
      'Ramingstein',
      'Rainbach im Mühlkreis',
      'Raiding',
      'Ragnitz',
      'Raggal',
      'Raffelstetten',
      'Radstadt',
      'Bad Radkersburg',
      'Radfeld',
      'Radenthein',
      'Rachau',
      'Rabenwald',
      'Rabenstein an der Pielach',
      'Rabensburg',
      'Raasdorf',
      'Raach am Hochgebirge',
      'Raabs an der Thaya',
      'Raabau',
      'Raaba',
      'Raab',
      'Pyhra',
      'Pusterwald',
      'Purkersdorf',
      'Purgstall',
      'Purbach am Neusiedler See',
      'Puntigam',
      'Pulkau',
      'Pühret',
      'Pucking',
      'Puchheim',
      'Puchenstuben',
      'Puchenau',
      'Puchberg am Schneeberg',
      'Puch bei Weiz',
      'Puch bei Hallein',
      'Prutz',
      'Pruggern',
      'Proleb',
      'Prinzersdorf',
      'Prigglitz',
      'Hofamt Priel',
      'Pressbaum',
      'Prellenkirchen',
      'Preitenegg',
      'Pregarten',
      'Preding',
      'Pramet',
      'Prambachkirchen',
      'Pram',
      'Prägraten',
      'Pradl',
      'Poysdorf',
      'Potzneusiedl',
      'Pöttsching',
      'Pottschach',
      'Pötting',
      'Pottenstein',
      'Pottendorf',
      'Pottenbrunn',
      'Pöttelsdorf',
      'Pörtschach am Wörthersee',
      'Poppendorf',
      'Pöls',
      'Polling in Tirol',
      'Pöllauberg',
      'Pöllau',
      'Pöggstall',
      'Poggersdorf',
      'Podersdorf am See',
      'Pöchlarn',
      'Plesching',
      'Plankenau',
      'Plainfeld',
      'Pitzenberg',
      'Pitten',
      'Pitschgau',
      'Pistorf',
      'Pischelsdorf in der Steiermark',
      'Pirka',
      'Piringsdorf',
      'Pirching am Traubenberg',
      'Pinsdorf',
      'Pinkafeld',
      'Pinggau',
      'Pillichsdorf',
      'Pill',
      'Pilgersdorf',
      'Piesendorf',
      'Pierbach',
      'Pichling bei Köflach',
      'Pichl bei Wels',
      'Pichl',
      'Piberegg',
      'Pians',
      'Pfunds',
      'Pfons',
      'Pflach',
      'Pfarrkirchen bei Bad Hall',
      'Pfaffstätten',
      'Pfaffing',
      'Pfaffenhofen',
      'Peuerbach',
      'Petzenkirchen',
      'Pettneu',
      'Pettenbach',
      'Petronell-Carnuntum',
      'Perwang am Grabensee',
      'Pertlstein',
      'Persenbeug',
      'Pernitz',
      'Pernersdorf',
      'Pernegg an der Mur',
      'Pernegg',
      'Perlsdorf',
      'Perg',
      'Perchtoldsdorf',
      'Perchau am Sattel',
      'Penz',
      'Pennewang',
      'Peggau',
      'Payerbach',
      'Paudorf',
      'Pattigham',
      'Patsch',
      'Paternion',
      'Passail',
      'Pasching',
      'Parschlug',
      'Parndorf',
      'Parbasdorf',
      'Pamhagen',
      'Pama',
      'Palting',
      'Palfau',
      'Paldau',
      'Pack',
      'Pabneukirchen',
      'Oeynhausen',
      'Oetz',
      'Otterthal',
      'Ottenthal',
      'Ottensheim',
      'Ottenschlag im Mühlkreis',
      'Ottenschlag',
      'Ottakring',
      'Osterwitz',
      'Ostermiething',
      'Ossiach',
      'Oslip',
      'Ort im Innkreis',
      'Orth an der Donau',
      'Ortgraben',
      'Opponitz',
      'Oppenberg',
      'Ollersdorf im Burgenland',
      'Olbendorf',
      'Ohlsdorf',
      'Oggau',
      'Offenhausen',
      'Oepping',
      'Oehling',
      'Oedt',
      'Öblarn',
      'Oberzeiring',
      'Oberwölz Stadt',
      'Oberwölbling',
      'Oberweg',
      'Oberwart',
      'Oberwang',
      'Oberwaltersdorf',
      'Oberwagram',
      'Obervogau',
      'Obervellach',
      'Obertrum am See',
      'Obertilliach',
      'Oberstorcha',
      'Obersiebenbrunn',
      'Obersdorf',
      'Oberschützen',
      'Oberrettenbach',
      'Oberpullendorf',
      'Oberpremstätten',
      'Pettnau',
      'Oberneukirchen',
      'Oberndorf in Tirol',
      'Oberndorf bei Schwanenstadt',
      'Oberndorf bei Salzburg',
      'Oberndorf an der Melk',
      'Oberndorf in der Ebene',
      'Obernberg am Inn',
      'Obermieming',
      'Oberloisdorf',
      'Oberlienz',
      'Oberkurzheim',
      'Oberhofen im Inntal',
      'Oberhofen am Irrsee',
      'Oberhausen',
      'Oberhart',
      'Oberhaag',
      'Ober-Grafendorf',
      'Oberdrauburg',
      'Oberdorf im Burgenland',
      'Oberdorf am Hochegg',
      'Aurach',
      'Oberau',
      'Oberalm',
      'Oberaich',
      'Obdach',
      'Nüziders',
      'Nußdorf am Haunsberg',
      'Nöchling',
      'Nitscha',
      'Nikolsdorf',
      'Niklasdorf',
      'Nikitsch',
      'Niederwölz',
      'Niederthalheim',
      'Niederöblarn',
      'Niedernsill',
      'Niederneukirchen',
      'Niederndorf',
      'Niederleis',
      'Niederhollabrunn',
      'Niederdorf',
      'Niederbreitenbach',
      'Niederau',
      'Niederalm',
      'Nickelsdorf',
      'Neuzeug',
      'Neutillmitsch',
      'Neutal',
      'Neustift im Stubaital',
      'Neustift bei Güssing',
      'Neustift an der Lafnitz',
      'Neusiedl an der Zaya',
      'Neusiedl am See',
      'Neusiedl',
      'Neunkirchen',
      'Neumarkt in Steiermark',
      'Neumarkt im Mühlkreis',
      'Neumarkt im Hausruckkreis',
      'Neumarkt an der Ybbs',
      'Neumarkt am Wallersee',
      'Neulengbach',
      'Neukirchen bei Lambach',
      'Neukirchen an der Vöckla',
      'Neukirchen am Walde',
      'Neukirchen am Großvenediger',
      'Neukirchen',
      'Neuhofen an der Ybbs',
      'Neuhofen an der Krems',
      'Neuhaus',
      'Neufeld an der Leitha',
      'Neudörfl',
      'Neudorf bei Staatz',
      'Neudau',
      'Neuberg an der Mürz',
      'Nestelbach im Ilztal',
      'Nestelbach bei Graz',
      'Nesselwängle',
      'Nenzing',
      'Neidling',
      'Neckenmarkt',
      'Nauders',
      'Natters',
      'Natternbach',
      'Nassereith',
      'Namlos',
      'Naas',
      'Naarn im Machlande',
      'Mutters',
      'Musau',
      'Mürzzuschlag',
      'Mürzsteg',
      'Mürzhofen',
      'Mureck',
      'Münzkirchen',
      'Münzbach',
      'Muntlix',
      'Münster',
      'Munderfing',
      'Münchendorf',
      'Müllendorf',
      'Muhr',
      'Mühlgraben',
      'Mühlen',
      'Mühldorf bei Feldbach',
      'Mühldorf',
      'Mühlbach am Hochkönig',
      'Mühlau',
      'Muggendorf',
      'Muckendorf an der Donau',
      'Mötz',
      'Moschendorf',
      'Mörtschach',
      'Mortantsch',
      'Mörbisch am See',
      'Mooskirchen',
      'Moosdorf',
      'Moosburg',
      'Moosbrunn',
      'Mönichwald',
      'Mönichkirchen',
      'Mondsee',
      'Mönchhof',
      'Molln',
      'Möllersdorf',
      'Möllbrücke',
      'Mölbling',
      'Möggers',
      'Mogersdorf',
      'Modriach',
      'Mödling',
      'Mittertreffling',
      'Mittersill',
      'Mitterpullendorf',
      'Mitterndorf im Steirischen Salzkammergut',
      'Mitterndorf an der Fischa',
      'Mitterlabill',
      'Mitterkirchen im Machland',
      'Mitterhofen',
      'Mitterdorf im Mürztal',
      'Mitterdorf an der Raab',
      'Mitterberghütten',
      'Mitterbach am Erlaufsee',
      'Mittelberg',
      'Mistelbach',
      'Mischendorf',
      'Mils bei Solbad Hall',
      'Mils bei Imst',
      'Miesenbach',
      'Miesenbach bei Birkfeld',
      'Mieming',
      'Mieders',
      'Michelhausen',
      'Micheldorf in Oberösterreich',
      'Micheldorf',
      'Michaelnbach',
      'Michaelerberg',
      'Mettersdorf am Saßbach',
      'Metnitz',
      'Merkendorf',
      'Mellach',
      'Melk',
      'Meiningen',
      'Meidling',
      'Mehrnbach',
      'Meggenhofen',
      'Medraz',
      'Mayrhofen',
      'Mautern in Steiermark',
      'Mauterndorf',
      'Mautern',
      'Mauerkirchen',
      'Mauer bei Amstetten',
      'Mauerbach',
      'Matzendorf',
      'Matzen',
      'Mattsee',
      'Mattighofen',
      'Mattersburg',
      'Matrei in Osttirol',
      'Matrei am Brenner',
      'Marz',
      'Martinsberg',
      'Markt Sankt Martin',
      'Sankt Florian',
      'Bad Pirawarth',
      'Markt Piesting',
      'Markt Neuhodis',
      'Markt Allhau',
      'Markgrafneusiedl',
      'Markersdorf an der Pielach',
      'Mariazell',
      'Maria Wörth',
      'Mariatrost',
      'Mariatal',
      'Maria Taferl',
      'Mariastein',
      'Mariasdorf',
      'Maria Schmolln',
      'Maria Saal',
      'Maria Rain',
      'Mariapfarr',
      'Maria Neustift',
      'Maria Lanzendorf',
      'Maria Lankowitz',
      'Maria Laach am Jauerling',
      'Mariahof',
      'Maria Enzersdorf',
      'Maria Ellend',
      'Maria-Anzbach',
      'Maria Alm am Steinernen Meer',
      'Margarethen am Moos',
      'Marchtrenk',
      'Marchegg',
      'Marbach an der Donau',
      'Mannswörth',
      'Mannsdorf an der Donau',
      'Manning',
      'Mannersdorf an der Rabnitz',
      'Mannersdorf am Leithagebirge',
      'Mank',
      'Maissau',
      'Maishofen',
      'Mailberg',
      'Maierdorf',
      'Mäder',
      'Lutzmannsburg',
      'Lustenau',
      'Lunz am See',
      'Luftenberg an der Donau',
      'Ludmannsdorf',
      'Ludesch',
      'Losenstein',
      'Lorüns',
      'Loretto',
      'Loosdorf',
      'Loipersdorf bei Fürstenfeld',
      'Loipersbach im Burgenland',
      'Loich',
      'Lofer',
      'Lödersdorf',
      'Lochau',
      'Litzelsdorf',
      'Litschau',
      'Linz',
      'Lilienfeld',
      'Ligist',
      'Liezen',
      'Liesingtal',
      'Lienz',
      'Lieboch',
      'Liebenau',
      'Lichtenwörth',
      'Lichtenegg',
      'Leutschach',
      'Lermoos',
      'Leopoldsdorf im Marchfelde',
      'Leopoldsdorf',
      'Leonstein',
      'Leonding',
      'Leogang',
      'Leobersdorf',
      'Leobendorf',
      'Leoben',
      'Lenzing',
      'Lengfelden',
      'Lengenfeld',
      'Lendorf',
      'Lend',
      'Lembach im Mühlkreis',
      'Leitzersdorf',
      'Leitring',
      'Leithaprodersdorf',
      'Leitersdorf im Raabtal',
      'Leitendorf',
      'Leisach',
      'Leibnitz',
      'Leiben',
      'Ledenitzen',
      'Lechaschau',
      'Lech',
      'Laxenburg',
      'Tullnerbach-Lawies',
      'Lavant',
      'Lavamünd',
      'Lauterach',
      'Laussa',
      'Launsdorf',
      'Lassnitzhöhe',
      'Lassing',
      'Lassee',
      'Lanzenkirchen',
      'Lans',
      'Lannach',
      'Langschlag',
      'Langenzersdorf',
      'Langenwang',
      'Langenstein',
      'Langenrohr',
      'Langenlois',
      'Langen',
      'Langegg bei Graz',
      'Langau',
      'Lang',
      'Landskron',
      'Landl',
      'Landegg',
      'Landeck',
      'Lamprechtshausen',
      'Lambach',
      'Lafnitz',
      'Ladis',
      'Ladendorf',
      'Lackendorf',
      'Lackenbach',
      'Labuch',
      'Laakirchen',
      'Laab im Walde',
      'Laa an der Thaya',
      'Kundl',
      'Kumberg',
      'Kulm am Zirbitz',
      'Kukmirn',
      'Kühnsdorf',
      'Kufstein',
      'Kuchl',
      'Krusdorf',
      'Krumpendorf',
      'Krumnussbaum',
      'Krumegg',
      'Krumbach Markt',
      'Krumbach',
      'Krumau am Kamp',
      'Krottendorf bei Ligist',
      'Krottendorf',
      'Kronstorf',
      'Kritzendorf',
      'Krispl',
      'Krieglach',
      'Bad Kreuzen',
      'Krensdorf',
      'Kremsmünster',
      'Kremsdorf',
      'Krems an der Donau',
      'Kraubath an der Mur',
      'Kramsach',
      'Krakauschatten',
      'Krakauhintermühlen',
      'Krakaudorf',
      'Köttmannsdorf',
      'Kottingbrunn',
      'Kötschach',
      'Köstendorf',
      'Kössen',
      'Korneuburg',
      'Koppl',
      'Kopfing im Innkreis',
      'Königswiesen',
      'Königstetten',
      'Königsdorf',
      'Kolsassberg',
      'Kohlschwarz',
      'Kohlberg',
      'Kohfidisch',
      'Köflach',
      'Koblach',
      'Kobersdorf',
      'Kobenz',
      'Knittelfeld',
      'Klosterneuburg',
      'Klösterle',
      'Kloster',
      'Klöch',
      'Klingenbach',
      'Kleinzell',
      'Kleinsölk',
      'Kleinsöding',
      'Klein Sankt Paul',
      'Klein-Pöchlarn',
      'Kleinmürbisch',
      'Kleinlobming',
      'Kleinhöflein im Burgenland',
      'Klausen-Leopoldsdorf',
      'Klaus',
      'Klagenfurt am Wörthersee',
      'Klaffer am Hochficht',
      'Kitzeck im Sausal',
      'Kitzbühel',
      'Kittsee',
      'Kirnberg an der Mank',
      'Kirchstetten',
      'Kirchschlag in der Buckligen Welt',
      'Kirchschlag bei Linz',
      'Kirchschlag',
      'Kirchdorf in Tirol',
      'Kirchdorf an der Krems',
      'Kirchberg ob der Donau',
      'Kirchberg in Tirol',
      'Kirchberg an der Raab',
      'Kirchberg an der Pielach',
      'Kirchberg am Wechsel',
      'Kirchberg am Walde',
      'Kirchberg am Wagram',
      'Kirchbach in Steiermark',
      'Kirchbach',
      'Kindberg',
      'Kilb',
      'Kierling',
      'Keutschach am See',
      'Kennelbach',
      'Kemeten',
      'Kematen in Tirol',
      'Kematen an der Krems',
      'Kematen an der Ybbs',
      'Kautzen',
      'Kauns',
      'Kaumberg',
      'Katzelsdorf',
      'Katsdorf',
      'Kasten bei Böheimkirchen',
      'Kartitsch',
      'Karrösten',
      'Karres',
      'Karlstetten',
      'Kaprun',
      'Kappl',
      'Kappel am Krappfeld',
      'Kapfing',
      'Kapfenstein',
      'Kapfenberg',
      'Kapelln',
      'Kapellen',
      'Kammern im Liesingtal',
      'Kalwang',
      'Kaltenleutgeben',
      'Kaltenbach',
      'Kalsdorf bei Graz',
      'Kaisersdorf',
      'Kaindorf an der Sulm',
      'Kaindorf',
      'Kainbach',
      'Kainach bei Voitsberg',
      'Kaibing',
      'Jungholz',
      'Judendorf',
      'Warmbad-Judendorf',
      'Judenburg',
      'Jois',
      'Johnsbach',
      'Jochberg',
      'Jerzens',
      'Jennersdorf',
      'Jenbach',
      'Jedenspeigen',
      'Japons',
      'Jaidhof',
      'Jagerberg',
      'Jabing',
      'Ysper',
      'Ischgl',
      'Irschen',
      'Irrsdorf',
      'Irdning',
      'Inzing',
      'Inzersdorf im Kremstal',
      'Inzenhof',
      'Innsbruck',
      'Weerberg',
      'Innervillgraten',
      'Innerschwand',
      'Innere Stadt',
      'Innerbraz',
      'Imsterberg',
      'Imst',
      'Ilz',
      'Illmitz',
      'Hüttschlag',
      'Hüttenberg',
      'Hutten',
      'Hüttau',
      'Hürm',
      'Hundsheim',
      'Hötting',
      'Hörtendorf',
      'Hörsching',
      'Hornstein',
      'Horn',
      'Horitschon',
      'Hörbranz',
      'Hopfgarten im Brixental',
      'Hopfgarten in Defereggen',
      'Hönigsberg',
      'Holzhausen',
      'Hollersbach im Pinzgau',
      'Hollenthon',
      'Hollenstein an der Ybbs',
      'Hollenegg',
      'Hollabrunn',
      'Hohenweiler',
      'Hohenthurn',
      'Hohentauern',
      'Hohenruppersdorf',
      'Hohenems',
      'Hoheneich',
      'Hohenberg',
      'Hohenau an der Raab',
      'Hohenau',
      'Hofstetten',
      'Hofstätten an der Raab',
      'Höflein',
      'Hofkirchen im Traunkreis',
      'Höfen',
      'Hof bei Straden',
      'Hof bei Salzburg',
      'Hof am Leithaberge',
      'Hof',
      'Hochwolkersdorf',
      'Hochtregist',
      'Höchst',
      'Hochfilzen',
      'Hochburg-Ach',
      'Hitzendorf',
      'Hirtenberg',
      'Hirschegg',
      'Hirschbach',
      'Hirnsdorf',
      'Hirm',
      'Hippach',
      'Hintersee',
      'Hinterbrühl',
      'Himmelreich',
      'Himmelberg',
      'Himberg',
      'Hietzing',
      'Hieflau',
      'Heugraben',
      'Hetzendorf',
      'Herzogsdorf',
      'Herzogenburg',
      'Herrnbaumgarten',
      'Hernstein',
      'Hernals',
      'Hermagor',
      'Hennersdorf',
      'Henndorf am Wallersee',
      'Hengsberg',
      'Hellmonsödt',
      'Heiterwang',
      'Heiligenkreuz im Lafnitztal',
      'Heiligenkreuz am Waasen',
      'Heiligenkreuz',
      'Heiligeneich',
      'Heiligenbrunn',
      'Heiligenblut',
      'Heidenreichstein',
      'Hausmening',
      'Hausmannstätten',
      'Hausleiten',
      'Hauskirchen',
      'Hausbrunn',
      'Haus',
      'Haunoldstein',
      'Haugsdorf',
      'Haugschlag',
      'Hatzendorf',
      'Hatting',
      'Haslach an der Mühl',
      'Häselgehr',
      'Haselbach',
      'Hartmannsdorf',
      'Hartl',
      'Hartkirchen',
      'Hart im Zillertal',
      'Hartheim',
      'Hartberg',
      'Hart',
      'Harmannsdorf',
      'Harland',
      'Haringsee',
      'Bad Häring',
      'Hardegg',
      'Hard',
      'Hannersdorf',
      'Handenberg',
      'Hallwang',
      'Hallein',
      'Halbturn',
      'Halbenrain',
      'Hainzenberg',
      'Hainsdorf im Schwarzautal',
      'Hainfeld',
      'Hainersdorf',
      'Hainburg an der Donau',
      'Haiming',
      'Haidershofen',
      'Haiden',
      'Haid',
      'Haibach im Mühlkreis',
      'Hagenbrunn',
      'Hagenberg im Mühlkreis',
      'Hafnerbach',
      'Hafendorf',
      'Hadres',
      'Hadersdorf am Kamp',
      'Hackerberg',
      'Habach',
      'Haag am Hausruck',
      'Haag',
      'Güttenbach',
      'Guttaring',
      'Gutenstein',
      'Gutenbrunn',
      'Gutau',
      'Güssing',
      'Gurk',
      'Guntramsdorf',
      'Guntersdorf',
      'Gunskirchen',
      'Günselsdorf',
      'Gundersdorf',
      'Gumpoldskirchen',
      'Gugging',
      'Gschwandt',
      'Grünbach am Schneeberg',
      'Grünau im Almtal',
      'Großweikersdorf',
      'Grosssulz',
      'Großsölk',
      'Groß-Siegharts',
      'Groß-Schweinbarth',
      'Groß Sankt Florian',
      'Großrußbach',
      'Großriedenthal',
      'Grossraming',
      'Grosspetersdorf',
      'Großmugl',
      'Großlobming',
      'Großkrut',
      'Großklein',
      'Grosshöflein',
      'Großhofen',
      'Heimschuh',
      'Großharras',
      'Grossgmain',
      'Groß-Gerungs',
      'Groß-Enzersdorf',
      'Groß-Engersdorf',
      'Großebersdorf',
      'Großdorf',
      'Grossarl',
      'Grödig',
      'Gröbming',
      'Grins',
      'Grimmenstein',
      'Griffen',
      'Grieskirchen',
      'Gries im Sellrain',
      'Gries am Brenner',
      'Gresten',
      'Gressenberg',
      'Greisdorf',
      'Greinsfurth',
      'Grein',
      'Greifenburg',
      'Graz',
      'Gratwein',
      'Gratkorn',
      'Grän',
      'Grambach',
      'Gramatneusiedl',
      'Gramastetten',
      'Gramais',
      'Grafenwörth',
      'Grafenstein',
      'Grafenschlag',
      'Grafenschachen',
      'Grafenegg',
      'Grafendorf bei Hartberg',
      'Grabersdorf',
      'Götzis',
      'Götzens',
      'Götzendorf an der Leitha',
      'Göttelsberg',
      'Göstling an der Ybbs',
      'Gösting',
      'Gössendorf',
      'Gossendorf',
      'Gössenberg',
      'Göss',
      'Gosdorf',
      'Gosau',
      'Göpfritz an der Wild',
      'Gols',
      'Golling an der Salzach',
      'Göllersdorf',
      'Goldwörth',
      'Goldegg',
      'Going',
      'Göfis',
      'Gniebing',
      'Gnesau',
      'Gneixendorf',
      'Gnas',
      'Gnadendorf',
      'Gmunden',
      'Gmünd',
      'Glojach',
      'Gloggnitz',
      'Glödnitz',
      'Globasnitz',
      'Glinzendorf',
      'Gleisdorf',
      'Gleinstätten',
      'Glasenbach',
      'Glanegg',
      'Gießhübl',
      'Gföhl',
      'Gersdorf an der Feistritz',
      'Gerolding',
      'Gerersdorf',
      'Gerasdorf bei Wien',
      'Geras',
      'Georgenberg',
      'Geistthal',
      'Gedersdorf',
      'Geboltskirchen',
      'Gaweinstal',
      'Gaubitsch',
      'Gattendorf',
      'Gastern',
      'Gaspoltshofen',
      'Gasen',
      'Gaschurn',
      'Garsten',
      'Gars am Kamp',
      'Garanas',
      'Ganz',
      'Gänserndorf',
      'Bad Gams',
      'Gams bei Hieflau',
      'Gampern',
      'Gamlitz',
      'Gaming',
      'Galtür',
      'Gallzein',
      'Gallspach',
      'Gallneukirchen',
      'Gallmannsegg',
      'Gallizien',
      'Gaißau',
      'Gainfarn',
      'Gailitz',
      'Gaflenz',
      'Gablitz',
      'Gabersdorf',
      'Gaal',
      'Gaaden',
      'Fußach',
      'Fuschl am See',
      'Furth an der Triesting',
      'Furth bei Göttweig',
      'Fürstenfeld',
      'Fürnitz',
      'Fulpmes',
      'Fügenberg',
      'Fügen',
      'Frohsdorf',
      'Frohnleiten',
      'Fritzens',
      'Friesach',
      'Friedberg',
      'Fresach',
      'Freistadt',
      'Freindorf',
      'Freiland bei Deutschlandsberg',
      'Freidorf an der Laßnitz',
      'Fraxern',
      'Frauenkirchen',
      'Frauenberg',
      'Frannach',
      'Frankenmarkt',
      'Frankenfels',
      'Frankenburg',
      'Forstau',
      'Fornach',
      'Forchtenstein',
      'Forchach',
      'Fohnsdorf',
      'Floridsdorf',
      'Floing',
      'Flirsch',
      'Fliess',
      'Flaurling',
      'Flattach',
      'Flatschach',
      'Fladnitz im Raabtal',
      'Flachau',
      'Fiss',
      'Fisching',
      'Fischbach',
      'Fischamend-Markt',
      'Finkenstein',
      'Finkenberg',
      'Filzmoos',
      'Fiecht',
      'Fieberbrunn',
      'Ferschnitz',
      'Fernitz',
      'Ferndorf',
      'Ferlach',
      'Fendels',
      'Fels am Wagram',
      'Felixdorf',
      'Feldkirchen in Kärnten',
      'Feldkirchen bei Graz',
      'Feldkirchen an der Donau',
      'Feldkirch',
      'Feldbach',
      'Feld am See',
      'Feistritz ob Bleiburg',
      'Feistritz im Rosental',
      'Feistritz bei Knittelfeld',
      'Feistritz an der Gail',
      'Feistritz an der Drau',
      'Feistritz am Wechsel',
      'Fehring',
      'Farrach',
      'Fallbach',
      'Falkenstein',
      'St. Ruprecht-Falkendorf',
      'Falkenburg',
      'Faistenau',
      'Faak am See',
      'Euratsfeld',
      'Eugendorf',
      'Eschenau',
      'Erpfendorf',
      'Erpersdorf',
      'Ernsthofen',
      'Ernstbrunn',
      'Erlauf',
      'Bad Erlach',
      'Erl',
      'Enzersfeld',
      'Enzersdorf an der Fischa',
      'Enzenreith',
      'Ennsdorf',
      'Enns',
      'Engerwitzdorf',
      'Engelhartstetten',
      'Endach',
      'Empersdorf',
      'Emmersdorf an der Donau',
      'Eltendorf',
      'Elsbethen',
      'Elmen',
      'Ellmau',
      'Elixhausen',
      'Elbigenalp',
      'Eisgarn',
      'Eisenstadt',
      'Eisenerz',
      'Eisbach',
      'Eidenberg',
      'Eichgraben',
      'Eichfeld',
      'Eichenberg',
      'Eichberg',
      'Eibiswald',
      'Ehrenhausen',
      'Ehenbichl',
      'Eggersdorf bei Graz',
      'Eggern',
      'Eggenburg',
      'Eggenberg',
      'Egg',
      'Eferding',
      'Edt bei Lambach',
      'Edlitz',
      'Edelstal',
      'Edelsgrub',
      'Edelschrott',
      'Edelsbach bei Feldbach',
      'Eckartsau',
      'Echsenbach',
      'Ebreichsdorf',
      'Eberstein',
      'Eberstalzell',
      'Ebersdorf',
      'Eberschwang',
      'Eberndorf',
      'Ebergassing',
      'Eberau',
      'Ebenthal',
      'Ebensee',
      'Eben im Pongau',
      'Ebenfurth',
      'Ebenau',
      'Eben am Achensee',
      'Ebbs',
      'Dürnstein in der Steiermark',
      'Dürnstein',
      'Dürnkrut',
      'Düns',
      'Droß',
      'Drösing',
      'Drassburg',
      'Drasenhofen',
      'Dornbirn',
      'Dorfstetten',
      'Dorfgastein',
      'Dorfbeuern',
      'Doren',
      'Doppl',
      'Donnerskirchen',
      'Donnersbach',
      'Donawitz',
      'Dölsach',
      'Döbriach',
      'Dobersberg',
      'Diex',
      'Dietersdorf am Gnasbach',
      'Dietersdorf',
      'Dietachdorf',
      'Dietach',
      'Dienten am Hochkönig',
      'Dienersdorf',
      'Diemlach',
      'Deutsch-Wagram',
      'Deutschlandsberg',
      'Deutschkreutz',
      'Deutsch Kaltenbrunn',
      'Deutsch Jahrndorf',
      'Deutsch Griffen',
      'Deutsch Goritz',
      'Deutschfeistritz',
      'Deuchendorf',
      'Desselbrunn',
      'Dellach im Drautal',
      'Dellach',
      'Dechantskirchen',
      'Debant',
      'Dalaas',
      'Bürs',
      'Bürmoos',
      'Burgfried',
      'Burgau',
      'Buchkirchen',
      'Buch in Tirol',
      'Buchbach',
      'Brunn an der Wild',
      'Brunn an der Schneebergbahn',
      'Brunn am Gebirge',
      'Brunn',
      'Bruckneudorf',
      'Brückl',
      'Bruck an der Mur',
      'Bruck an der Leitha',
      'Bruck an der Großglocknerstraße',
      'Bruck am Ziller',
      'Bromberg',
      'Brixlegg',
      'Brixen im Thale',
      'Bretstein',
      'Breitenwang',
      'Breitenfurt bei Wien',
      'Breitenfeld an der Rittschein',
      'Breitenfeld am Tannenriegel',
      'Breitenbrunn',
      'Breitenbach am Inn',
      'Breitenau',
      'Bregenz',
      'Braunau am Inn',
      'Brandenberg',
      'Bramberg am Wildkogel',
      'Böheimkirchen',
      'Bodensdorf',
      'Bocksdorf',
      'Bockfließ',
      'Bad Blumau',
      'Bludesch',
      'Bludenz',
      'Blons',
      'Blindenmarkt',
      'Bleiburg/Pliberk',
      'Blaindorf',
      'Bizau',
      'Bischofstetten',
      'Bischofshofen',
      'Bisamberg',
      'Birkfeld',
      'Birgitz',
      'Bildstein',
      'Bierbaum am Auersbach',
      'Biedermannsdorf',
      'Bichlbach',
      'Biberwier',
      'Biberbach',
      'Bezau',
      'Berwang',
      'Bernstein',
      'Bernhardsthal',
      'Berndorf bei Salzburg',
      'Berndorf',
      'Bergheim',
      'Bergham',
      'Berg',
      'Behamberg',
      'Baumkirchen',
      'Baumgarten bei Gnas',
      'Batschuns',
      'Barwies',
      'Bartholomäberg',
      'Bärnkopf',
      'Baldramsdorf',
      'Bairisch Kölldorf',
      'Wimsbach',
      'Bad Vöslau',
      'Bad Tatzmannsdorf',
      'Bad Schönau',
      'Bad Schallerbach',
      'Bad Sankt Leonhard im Lavanttal',
      'Bad Ischl',
      'Bad Hofgastein',
      'Bad Hall',
      'Bad Goisern',
      'Bad Gleichenberg',
      'Bad Gastein',
      'Bad Fischau',
      'Badersdorf',
      'Baden',
      'Bad Deutsch-Altenburg',
      'Bad Aussee',
      'Bachmanning',
      'Axams',
      'Außervillgraten',
      'Ausserbraz',
      'Aurolzmünster',
      'Aurach am Hongar',
      'Auersthal',
      'Auersbach',
      'Audorf',
      'Au an der Traun',
      'Au am Leithaberge',
      'Au',
      'Atzesberg',
      'Atzenbrugg',
      'Atzbach',
      'Attnang',
      'Attersee',
      'Attendorf',
      'Asten',
      'Asperhofen',
      'Asparn an der Zaya',
      'Aspang Markt',
      'Aschbach Markt',
      'Aschau',
      'Aschach an der Steyr',
      'Aschach an der Donau',
      'Arzl im Pitztal',
      'Arzl',
      'Arzberg',
      'Arriach',
      'Arnreit',
      'Arnoldstein',
      'Arnfels',
      'Ardning',
      'Ardagger Stift',
      'Arbesbach',
      'Apfelberg',
      'Apetlon',
      'Anthering',
      'Antau',
      'Ansfelden',
      'Anras',
      'Annabichl',
      'Annaberg',
      'Anif',
      'Angern an der March',
      'Anger',
      'Angath',
      'Andorf',
      'Andlersdorf',
      'Andelsbuch',
      'Andau',
      'Amstetten',
      'Amras',
      'Ampflwang',
      'Ampass',
      'Amlach',
      'Altmünster',
      'Altmelon',
      'Altlichtenwarth',
      'Altlichtenberg',
      'Altlengbach',
      'Althofen',
      'Altheim',
      'Altenmarkt im Pongau',
      'Altenmarkt bei Sankt Gallen',
      'Altenmarkt bei Fürstenfeld',
      'Altenmarkt an der Triesting',
      'Altenfelden',
      'Altendorf',
      'Altenburg',
      'Altenberg bei Linz',
      'Altaussee',
      'Altach',
      'Allhartsberg',
      'Allhaming',
      'Allerheiligen im Mürztal',
      'Allerheiligen im Mühlkreis',
      'Allerheiligen bei Wildon',
      'Allentsteig',
      'Alland',
      'Alkoven',
      'Aldrans',
      'Albersdorf',
      'Alberndorf in der Riedmark',
      'Aistersheim',
      'Ainet',
      'Aigen im Mühlkreis',
      'Aigen im Ennstal',
      'Bad Kleinkirchheim',
      'Aichkirchen',
      'Aich',
      'Aibl',
      'Ahorn',
      'Aggsbach',
      'Aflenz Kurort',
      'Adnet',
      'Admont',
      'Adlwang',
      'Aderklaa',
      'Achenkirch',
      'Achau',
      'Abwinden',
      'Abtissendorf',
      'Abtenau',
      'Absam',
      'Abfaltersbach',
      'Schlaiten',
      'Thurn',
      'Untertilliach',
      'Finkenstein am Faaker See',
      'Sankt Jakob in Defereggen',
      'Attnang-Puchheim',
      'Obernberg am Brenner',
      'Mühlbachl',
      'Ötztal-Bahnhof',
      'Leutasch',
      'Maurach',
      'Gschnitz',
      'Navis',
      'Ellbögen',
      'Sellrain',
      'Oberperfuss',
      'Igls',
      'Obsteig',
      'Kolsass',
      'Gnadenwald',
      'Ehrwald',
      'Neue Heimat',
      'Gaumberg',
      'Lanzendorf',
      'Walserfeld',
      'Lehen',
      'Heinfels',
      'Kals am Großglockner',
      'Gerlosberg',
      'Gerlos',
      'Krimml',
      'Rohrberg',
      'Ried im Zillertal',
      'Hinterglemm',
      'Alpbach',
      'Itter',
      'Brand',
      'Rain',
      'Kleinedling',
      'Liebenfels',
      'Frauenstein',
      'Eppenstein',
      'Hochleithen',
      'Höf',
      'Niederschöckl',
      'Sankt Oswald ob Eibiswald',
      'Wildschönau',
      'Langkampfen',
      'Hart bei Graz',
      'Berg im Drautal',
      'Lockenhaus',
      'St. Georgen am Längsee',
      'St. Martin of Tennengebirge',
      'Afiesl',
      'Großkirchheim',
      'Damüls',
      'Bad Waltersdorf',
      'Bad Zell',
      'Auen',
      'Bad Leonfelden',
      'Pfarrwerfen',
      'Thal',
      'Bürserberg',
      'Holzgau',
      'Schröcken',
      'Nebelberg',
      'Ottnang am Hausruck',
      'Prottes',
      'Favoriten',
      'Sonnberg im Mühlkreis',
      'Unternberg',
      'Alberschwende',
      'Mellau',
      'Frastanz',
      'Kirchbichl',
      'Baierdorf-Umgebung',
      'Sonntag',
      'Bärnbach',
      'Längenfeld',
      'Haus im Ennstal',
      'Hall bei Admont',
      'Vandans',
      'Ertl',
      'Schwarzenberg',
      'Hittisau',
      'Micheldorf in Kärnten',
      'Schärding Vorstadt',
      'Neuberg im Burgenland',
      'Schönkirchen-Reyersdorf',
      'Klein-Neusiedl',
      'Eggendorf',
      'Seitenberg',
      'Dünserberg',
      'Aschau im Zillertal',
      'Assling',
      'Thiersee',
      'Mitterndorf',
      'Grinzens',
      'Mauer',
      'Mautbrücken',
      'Fresen',
      'Draschen',
      'Hinterberg',
      'Tillmitsch Links der Laßnitz',
      'Kirchenviertel',
      'Taugl',
      'Sticklberg',
      'Klaffenegg',
      'Weißenkirchen im Attergau',
      'Nöstlbach',
      'Klausner',
      'Puchkirchen am Trattberg',
      'Wagnergraben',
      'Illitsch',
      'Diesseits',
      'Wasserburg',
      'Loitzenberg',
      'Unterjosefstal',
      'Wolfern',
      'Ratzersdorf an der Traisen',
      'Regau',
      'Bernardin',
      'Brandln',
      'Dickerldorf',
      'Laahen',
      'Oberhaid',
      'Gralla',
      'Wispl',
      'Haidl',
      'Tiefenbach',
      'Gries',
      'Priel',
      'Kreuzstetten',
      'Langholzfeld',
      'Neufurth',
      'Villacher Vorstadt',
      'Völkermarkter Vorstadt',
      'St. Veiter Vorstadt',
      'Lerchenfeld',
      'Reinbach',
      'Niederstraß',
      'Langenlebarn-Oberaigen',
      'Lind',
      'Zell-Arzberg',
      'Zell-Markt',
      'Esch',
      'Villach-Innere Stadt',
      'Schachen bei Vorau',
      'Obergäu',
      'Eching',
      'Untereisenfeld',
      'Hochpoint',
      'Dörfl',
      'Dürnau',
      'Neualm',
      'Neu-Feffernitz',
      'Braunau Neustadt',
      'Schleben',
      'Wudmath',
      'Bergern',
      'Höfling',
      'Vasoldsberg',
      'St. Agathen und Perau',
      'Laab',
      'Absdorf',
      'Rohrendorf bei Krems',
      'Tux',
      'Bergland',
      'Rosental an der Kainach',
      'Ufer',
      'Lingenau',
      'Tillmitsch',
      'Donaustadt',
      'Jakomini',
      'Geidorf',
      'Andritz',
      'Viktringer Vorstadt',
      'Angerberg',
      'Rohr im Kremstal',
      'Langenegg',
      'Vogau',
      'Reitberg',
      'Föderlach I',
      'Vals',
      'Hinterleiten',
      'Neubau',
    ],
  },
  {
    country: 'Australia',
    cities: [
      'York',
      'Yanchep',
      'Yallingup',
      'Wundowie',
      'Wooroloo',
      'Woodville',
      'Woodside',
      'Wiluna',
      'Willunga',
      'Williamstown',
      'Willaston',
      'Whyalla',
      'Wembley',
      'Wellard',
      'Wayville',
      'Waterloo Corner',
      'Waroona',
      'Wanneroo',
      'Wallaroo',
      'Waikerie',
      'Wagin',
      'Waggrakine',
      'Virginia',
      'Victor Harbor',
      'Vasse',
      'Utakarra',
      'Unley',
      'Two Wells',
      'Tumby Bay',
      'Trigg',
      'Toodyay',
      'Thebarton',
      'Tennant Creek',
      'Tea Tree Gully',
      'Tanunda',
      'Tailem Bend',
      'Swan View',
      'Swanbourne',
      'Subiaco',
      'Sturt',
      'Streaky Bay',
      'Strathalbyn',
      'Stoneville',
      'Stirling',
      'Spearwood',
      'South Perth',
      'Solomontown',
      'Smithfield',
      'Denham',
      'Serpentine',
      'Semaphore',
      'Seaton',
      'Seaford',
      'Scarborough',
      'Salisbury',
      'Safety Bay',
      'Roxby Downs',
      'Roleystone',
      'Rockingham',
      'Robe',
      'Maida Vale',
      'Reynella',
      'Quorn',
      'Quindalup',
      'Prospect',
      'Port Willunga',
      'Port Pirie',
      'Port Noarlunga',
      'Moonta Bay',
      'Port Lincoln',
      'Port Hedland',
      'Port Elliot',
      'Port Broughton',
      'Port Augusta West',
      'Port Augusta',
      'Port Adelaide',
      'Plympton',
      'Pinjarra',
      'Peterborough',
      'Perth',
      'Pemberton',
      'Pearce',
      'Parkerville',
      'Parap',
      'Onslow',
      'One Tree Hill',
      'O’Halloran Hill',
      'Nuriootpa',
      'Nullagine',
      'North Fremantle',
      'Northfield',
      'North Beach',
      'Northam',
      'Normanville',
      'Old Noarlunga',
      'Nhulunbuy',
      'Newman',
      'Nedlands',
      'Narrogin',
      'Nairne',
      'Mylor',
      'Murray Bridge',
      'Mundijong',
      'Mundaring',
      'Mount Isa',
      'Mount Helena',
      'Mount Compass',
      'Mount Barker',
      'Mosman Park',
      'Morphett Vale',
      'Moora',
      'Modbury',
      'Moana',
      'Mitcham',
      'Minlaton',
      'Midland',
      'Middleton',
      'Middle Swan',
      'Merredin',
      'Meningie',
      'Medina',
      'Meadows',
      'McLaren Vale',
      'McLaren Flat',
      'Maylands',
      'Marion',
      'Marino',
      'Margaret River',
      'Marble Bar',
      'Mannum',
      'Manning',
      'Manjimup',
      'Maningrida',
      'Mandurah',
      'Maitland',
      'Maddington',
      'Macclesfield',
      'Lyndoch',
      'Lower Chittering',
      'Lobethal',
      'Little Hampton',
      'Lewiston',
      'Leonora',
      'Laverton',
      'Largs Bay',
      'Kwinana',
      'Kununurra',
      'Kojonup',
      'Kingston South East',
      'Kingscote',
      'Kersbrook',
      'Kenwick',
      'Kelmscott',
      'Katherine',
      'Katanning',
      'Karnup',
      'Kapunda',
      'Kalgoorlie',
      'Kalbarri',
      'Kadina',
      'Jarrahdale',
      'Jamestown',
      'Ingle Farm',
      'Hope Valley',
      'Hindmarsh Island',
      'Herne Hill',
      'Harvey',
      'Happy Valley',
      'Hamersley',
      'Halls Creek',
      'Hahndorf',
      'Hackham',
      'Guildford',
      'Greenock',
      'Greenmount',
      'Grasmere',
      'Grange',
      'Gosnells',
      'Gooseberry Hill',
      'Goolwa',
      'Golden Grove',
      'Glenelg',
      'Gilles Plains',
      'Geraldton',
      'Gawler',
      'Fremantle',
      'Freeling',
      'Fitzroy Crossing',
      'Findon',
      'Esperance',
      'Encounter Bay',
      'Echunga',
      'Dunsborough',
      'Donnybrook',
      'Dongara',
      'Chidlow',
      'Derby',
      'Denmark',
      'Port Denison',
      'Darwin',
      'Darlington',
      'Dampier',
      'Crystal Brook',
      'Craigburn Farm',
      'Crafers',
      'Cowell',
      'Cowaramup',
      'Cottesloe',
      'Coromandel Valley',
      'Coogee',
      'Coober Pedy',
      'Collie',
      'Cleve',
      'Clare',
      'Cheltenham',
      'Ceduna',
      'Caversham',
      'Carnarvon',
      'Cardup',
      'Capel',
      'Cannington',
      'Campbelltown',
      'Byford',
      'Busselton',
      'Burra',
      'Bunbury',
      'Bullsbrook',
      'Broome',
      'Brighton',
      'Bridgewater',
      'Bridgetown',
      'Boyanup',
      'Boulder',
      'Boddington',
      'Blackwood',
      'Birkenhead',
      'Birdwood',
      'Beverley',
      'Bentley',
      'Bellevue',
      'Belair',
      'Bedford Park',
      'Bedfordale',
      'Beaumont',
      'Bassendean',
      'Balhannah',
      'Balaklava',
      'Bakers Hill',
      'Australind',
      'Augusta',
      'Armadale',
      'Ardrossan',
      'Applecross',
      'Angle Vale',
      'Angaston',
      'Alice Springs',
      'Aldgate',
      'Albany',
      'Adelaide',
      'Eaton',
      'Binningup',
      'Seacliff',
      'Clapham',
      'Two Rocks',
      'Quinns Rocks',
      'East Fremantle',
      'Peppermint Grove',
      'Claremont',
      'Bicton',
      'Attadale',
      'Shenton Park',
      'Dalkeith',
      'Jurien Bay',
      'Drummond Cove',
      'Warnbro',
      'Mullaloo',
      'Exmouth',
      'Alyangula',
      'Stuart Park',
      'Larrakeyah',
      'Leanyer',
      'Ludmilla',
      'Fannie Bay',
      'Nightcliff',
      'Glen Iris',
      'Lower King',
      'Yungaburra',
      'Young',
      'Yoogali',
      'Yerrinbool',
      'Yeppoon',
      'Yenda',
      'Yea',
      'Yatala',
      'Yass',
      'Yarrawonga',
      'Yarraville',
      'Yarraman',
      'Yarram',
      'Yarra Junction',
      'Yarragon',
      'Yarra Glen',
      'Yandina',
      'Yamba',
      'Yackandandah',
      'Wy Yung',
      'Wyreema',
      'Wyongah',
      'Wyong',
      'Wynyard',
      'Wynnum',
      'Wyee',
      'Wurruk',
      'Wulkuraka',
      'Woy Woy',
      'Worrigee',
      'Worongary',
      'Woree',
      'Woori Yallock',
      'Woorim',
      'Woonona',
      'Woombye',
      'Woolloomooloo',
      'Woolgoolga',
      'Woodridge',
      'Woodgate',
      'Woodford',
      'Woodend',
      'Wonthaggi',
      'Wonga Park',
      'Wondai',
      'Wollongbar',
      'Wollert',
      'Wodonga',
      'Witta',
      'Winton',
      'Wingham',
      'Windsor',
      'Windaroo',
      'Windang',
      'Winchelsea',
      'Wilton',
      'Williamtown',
      'Wilberforce',
      'Whittlesea',
      'White Rock',
      'Whitebridge',
      'Wheelers Hill',
      'West Wyalong',
      'West Wallsend',
      'West Tamworth',
      'West Pymble',
      'Westmead',
      'West Hoxton',
      'Westcourt',
      'Westbury',
      'Westbrook',
      'Wesburn',
      'Werris Creek',
      'Werribee',
      'Wentworthville',
      'Wentworth Falls',
      'Wentworth',
      'Wendouree',
      'Wellington Point',
      'Wellington',
      'Weipa',
      'Wee Waa',
      'Weetangera',
      'Waverton',
      'Waurn Ponds',
      'Wauchope',
      'Wattleglen',
      'Watsonia',
      'Warwick',
      'Warrnambool',
      'Warrimoo',
      'Warren',
      'Warrawee',
      'Warrane',
      'Warrandyte',
      'Warragul',
      'Warracknabeal',
      'Warialda',
      'Warburton',
      'Wantirna South',
      'Wantirna',
      'Wangi Wangi',
      'Wangaratta',
      'Wandong',
      'Wandoan',
      'Wandin North',
      'Wamuran',
      'Wamberal',
      'Wallsend',
      'Walloon',
      'Wallington',
      'Wallerawang',
      'Wallan',
      'Wallalong',
      'Wallacia',
      'Walkerston',
      'Walgett',
      'Walcha',
      'Waitara',
      'Wahroonga',
      'Wahgunyah',
      'Wagga Wagga',
      'Wacol',
      'Vineyard',
      'Villawood',
      'Victoria Point',
      'Vermont',
      'Vaucluse',
      'Valley Heights',
      'Valla Beach',
      'Urunga',
      'Urraween',
      'Urangan',
      'Uralla',
      'Upwey',
      'Upper Coomera',
      'Upper Caboolture',
      'Unanderra',
      'Ulverstone',
      'Ulladulla',
      'Tyabb',
      'Tweed Heads',
      'Turramurra',
      'Tuross Head',
      'Tuncurry',
      'Tumut',
      'Tumbi Vmbi',
      'Tumbarumba',
      'Tully',
      'Tullamarine',
      'Tugun',
      'Truganina',
      'Trinity Beach',
      'Trentham',
      'Traralgon',
      'Trangie',
      'Trafalgar',
      'Townsville',
      'Toukley',
      'Torquay',
      'Toowoomba',
      'Toowong',
      'Toorak',
      'Tooradin',
      'Toongabbie',
      'Toogoom',
      'Toogoolawah',
      'Tongala',
      'Tomerong',
      'Tomakin',
      'Tolga',
      'Tocumwal',
      'Tinonee',
      'Tin Can Bay',
      'Timboon',
      'Thornton',
      'Thornleigh',
      'Thornlands',
      'Thornbury',
      'Thomastown',
      'Thirroul',
      'Thirlmere',
      'The Rocks',
      'The Rock',
      'The Patch',
      'The Oaks',
      'The Entrance',
      'The Basin',
      'Thabeban',
      'Tewantin',
      'Terrigal',
      'Terry Hills',
      'Terranora',
      'Terang',
      'Teralba',
      'Tenterfield',
      'Tenambit',
      'Templestowe Lower',
      'Templestowe',
      'Tempe',
      'Temora',
      'Telarah',
      'Teesdale',
      'Tea Gardens',
      'Tatura',
      'Tathra',
      'Tarro',
      'Tarragindi',
      'Taroona',
      'Tarneit',
      'Taree',
      'Tara',
      'Tamworth',
      'Tamborine',
      'Tallebudgera',
      'Tallangatta',
      'Tahmoor',
      'Table Top',
      'Sydney',
      'Sydenham',
      'Swansea',
      'Swan Hill',
      'Sutton',
      'Sutherland',
      'Surry Hills',
      'Surrey Hills',
      'Surfers Paradise',
      'Sunshine',
      'Sunnybank',
      'Sunbury',
      'Strathpine',
      'Strathmerton',
      'Strathfieldsaye',
      'Strathfield',
      'Stratford',
      'Stockton',
      'Stawell',
      'Stanwell Park',
      'Stanthorpe',
      'Stanmore',
      'Springwood',
      'Springvale South',
      'Springvale',
      'Springsure',
      'Spring Hill',
      'Spreyton',
      'Spotswood',
      'South Yarra',
      'Werribee South',
      'Southport',
      'South Melbourne',
      'South Grafton',
      'Sorrento',
      'Sorell',
      'Somerville',
      'Somerset',
      'Somersby',
      'Somers',
      'Soldiers Point',
      'Snug',
      'Smythesdale',
      'Smythes Creek',
      'Smithton',
      'Slacks Creek',
      'Singleton',
      'Silvan',
      'Shorncliffe',
      'Shepparton',
      'Shellharbour',
      'Sheffield',
      'Sharon',
      'Seymour',
      'Seville',
      'Seven Hills',
      'Selby',
      'Sebastopol',
      'Scottsdale',
      'Scoresby',
      'Scone',
      'Schofields',
      'Scarness',
      'Sawtell',
      'Sassafras',
      'Sarina',
      'San Remo',
      'Sandringham',
      'Sandgate',
      'Sandford',
      'Salt Ash',
      'Sale',
      'Saint Peters',
      'Saint Leonards',
      'Saint Kilda',
      'Saint Ives',
      'St Helens',
      'Saint Helena',
      'Saint Andrews',
      'Saint Albans',
      'Rye',
      'Ryde',
      'Rydalmere',
      'Rutherglen',
      'Rutherford',
      'Rushworth',
      'Rowville',
      'Ross Creek',
      'Rosewood',
      'Roseville',
      'Rosehill',
      'Rosedale',
      'Rosebud',
      'Rosanna',
      'Rooty Hill',
      'Romsey',
      'Roma',
      'Rokeby',
      'Rocklea',
      'Rockhampton',
      'Rockdale',
      'Rockbank',
      'Rochester',
      'Rochedale',
      'Robertson',
      'Riverview',
      'Riverstone',
      'Ripponlea',
      'Ringwood North',
      'Ringwood East',
      'Ringwood',
      'Richmond',
      'Rhodes',
      'Revesby',
      'Reservoir',
      'Research',
      'Renmark West',
      'Renmark',
      'Regents Park',
      'Redlynch',
      'Redland Bay',
      'Red Hill',
      'Redhead',
      'Redfern',
      'Red Cliffs',
      'Redbank',
      'Raymond Terrace',
      'Raworth',
      'Ravenshoe',
      'Rathmines',
      'Ranelagh',
      'Raglan',
      'Quirindi',
      'Queenstown',
      'Queenscliff',
      'Queanbeyan',
      'Quakers Hill',
      'Pyrmont',
      'Pymble',
      'Puckapunyal',
      'Proserpine',
      'Preston',
      'Prahran',
      'Potts Point',
      'Port Sorell',
      'Port Melbourne',
      'Port Macquarie',
      'Portland',
      'Port Kembla',
      'Port Hacking',
      'Port Fairy',
      'Port Douglas',
      'Portarlington',
      'Pomona',
      'Pokolbin',
      'Point Vernon',
      'Point Lonsdale',
      'Point Cook',
      'Point Clare',
      'Plenty',
      'Plainland',
      'Pitt Town',
      'Pittsworth',
      'Pine Mountain',
      'Pimpama',
      'Picton',
      'Pialba',
      'Phillip',
      'Petrie',
      'Penshurst',
      'Penrith',
      'Penola',
      'Pennant Hills',
      'Penguin',
      'Pendle Hill',
      'Pearcedale',
      'Peak Hill',
      'Peachester',
      'Paynesville',
      'Pascoe Vale',
      'Parkville',
      'Park Ridge',
      'Parkhurst',
      'Parkes',
      'Parkdale',
      'Panton Hill',
      'Palmwoods',
      'Palm Beach',
      'Pakenham Upper',
      'Pakenham',
      'Oxley',
      'Ouyen',
      'Ormond',
      'Ormiston',
      'Ormeau',
      'Orbost',
      'Orange',
      'Oonoonba',
      'One Mile',
      'Olinda',
      'Lancefield',
      'Old Beach',
      'Old Bar',
      'Officer',
      'Barwon Heads',
      'Oberon',
      'Oatley',
      'Oakleigh East',
      'Oakleigh',
      'Oakhurst',
      'Oakey',
      'Oakdale',
      'Nyora',
      'Nyngan',
      'Nunawading',
      'Numurkah',
      'Nowra',
      'Notting Hill',
      'North Wonthaggi',
      'North Turramurra',
      'North Sydney',
      'North Melbourne',
      'Northmead',
      'North Mackay',
      'North Haven',
      'Fitzroy North',
      'Northcote',
      'Normanton',
      'Normanhurst',
      'Noosaville',
      'Noosa Heads',
      'Nome',
      'Noble Park',
      'Nimbin',
      'Nicholson',
      'Nhill',
      'Newtown',
      'Newstead',
      'Newport',
      'New Norfolk',
      'Newnham',
      'Newmarket',
      'Newington',
      'Newcastle',
      'Nerang',
      'Nelson Bay',
      'Neerim South',
      'Nebo',
      'Narromine',
      'Narre Warren North',
      'Narre Warren',
      'Narrandera',
      'Narrabri',
      'Narrabeen',
      'Narooma',
      'Narellan',
      'Narara',
      'Narangba',
      'Naracoorte',
      'Nanango',
      'Nana Glen',
      'Nambucca Heads',
      'Nambucca',
      'Nambour',
      'Nagambie',
      'Nabiac',
      'Myrtleford',
      'Muswellbrook',
      'Murwillumbah',
      'Murrurundi',
      'Murrumbeena',
      'Murrumbateman',
      'Murgon',
      'Mundubbera',
      'Mulwala',
      'Mullumbimby',
      'Mulgrave',
      'Mulgoa',
      'Mudgeeraba',
      'Mudgee',
      'Moura',
      'Mount Waverley',
      'Mount Pleasant',
      'Mount Morgan',
      'Mount Martha',
      'Mount Macedon',
      'Mount Gravatt',
      'Mount Gambier',
      'Mount Evelyn',
      'Mount Eliza',
      'Mount Duneed',
      'Mount Druitt',
      'Mount Dandenong',
      'Mount Crosby',
      'Mount Cotton',
      'Mount Colah',
      'Mount Clear',
      'Moss Vale',
      'Mossman',
      'Mosman',
      'Morwell',
      'Moruya',
      'Mortlake',
      'Mortdale',
      'Mornington',
      'Morisset',
      'Moree',
      'Mordialloc',
      'Morayfield',
      'South Morang',
      'Mooroopna',
      'Mooroolbark',
      'Moorooduc',
      'Moorabbin',
      'Moonee Ponds',
      'Moonee Beach',
      'Moonbi',
      'Moonah',
      'Mooloolaba',
      'Moolap',
      'Montville',
      'Montrose',
      'Monto',
      'Montmorency',
      'Mont Albert',
      'Monbulk',
      'Mona Vale',
      'Monash',
      'Molong',
      'Moggill',
      'Moe',
      'Moama',
      'Mittagong',
      'Mitchelton',
      'Mitchell',
      'Mirboo North',
      'Mirani',
      'Miranda',
      'Minto',
      'Miners Rest',
      'Minden',
      'Milton',
      'Millthorpe',
      'Mill Park',
      'Millmerran',
      'Millicent',
      'Millgrove',
      'Miles',
      'Mildura',
      'Metung',
      'Merrylands',
      'Merriwa',
      'Merimbula',
      'Mernda',
      'Merbein',
      'Mentone',
      'Menangle',
      'Menai',
      'Melton South',
      'Melton',
      'Melbourne',
      'Medowie',
      'McKinnon',
      'Maudsland',
      'Matraville',
      'Mascot',
      'Maryborough',
      'Marulan',
      'Marrickville',
      'Maroubra',
      'Marong',
      'Maribyrnong',
      'Marian',
      'Margate',
      'Mareeba',
      'Mapleton',
      'Mansfield',
      'Manly',
      'Manilla',
      'Malvern',
      'Mallacoota',
      'Maleny',
      'Maldon',
      'Malanda',
      'Malabar',
      'Maffra',
      'Maddingley',
      'Macleod',
      'Maclean',
      'Macksville',
      'Mackay',
      'Macedon',
      'Lysterfield',
      'Luddenham',
      'Lucknow',
      'Loxton',
      'Lowood',
      'Lower Plenty',
      'Lovely Banks',
      'Lorne',
      'Lorn',
      'Longwarry',
      'Longreach',
      'Long Jetty',
      'Longford',
      'Logan Village',
      'Loganlea',
      'Loganholme',
      'Lockhart',
      'Liverpool',
      'Little River',
      'Lithgow',
      'Lismore',
      'Lisarow',
      'Lindisfarne',
      'Lindfield',
      'Lilydale',
      'Lightning Ridge',
      'Lidcombe',
      'Leura',
      'Leumeah',
      'Leopold',
      'Leongatha',
      'Leichhardt',
      'Legana',
      'Leeton',
      'Lawson',
      'Lawrence',
      'Lawnton',
      'Lavington',
      'Camden Haven',
      'Launceston',
      'Lauderdale',
      'Latrobe',
      'Largs',
      'Lara',
      'Langwarrin',
      'Lang Lang',
      'Lane Cove',
      'Landsborough',
      'Lalor',
      'Lake Haven',
      'Lakes Entrance',
      'Lakemba',
      'Lake Illawarra',
      'Lake Cargelligo',
      'Lake Albert',
      'Laidley',
      'Kyogle',
      'Kyneton',
      'Kyabram',
      'Kurri Kurri',
      'Kurrajong Heights',
      'Kurnell',
      'Kuranda',
      'Kotara',
      'Korumburra',
      'Koroit',
      'Koo-Wee-Rup',
      'Kootingal',
      'Kogarah',
      'Kleinton',
      'Kirribilli',
      'Kingston Beach',
      'Kingston',
      'Kingsthorpe',
      'Kingscliff',
      'Kinglake West',
      'Kinglake',
      'Kingaroy',
      'Kilsyth',
      'Kilmore',
      'Kilcoy',
      'Kiama',
      'Keysborough',
      'Kew',
      'Kerang',
      'Kenthurst',
      'Kensington',
      'Kennington',
      'Kendall',
      'Kempsey',
      'Kelso',
      'Keith',
      'Keiraville',
      'Keilor',
      'Kawungan',
      'Katoomba',
      'Karumba',
      'Karuah',
      'Kanwal',
      'Kangaroo Flat',
      'Kandos',
      'Kalorama',
      'Kallista',
      'Kalimna',
      'Kalbar',
      'Kahibah',
      'Junee',
      'Julatten',
      'Jindera',
      'Jindabyne',
      'Jimboomba',
      'Jilliby',
      'Jerilderie',
      'Jannali',
      'Jandowae',
      'Jamberoo',
      'Ivanhoe East',
      'Irymple',
      'Ipswich',
      'Inverloch',
      'Inverleigh',
      'Inverell',
      'Innisfail',
      'Ingleburn',
      'Ingham',
      'Iluka',
      'Hurstville Grove',
      'Hurstbridge',
      'Huonville',
      'Huntly',
      'Hughesdale',
      'Hughenden',
      'Howlong',
      'Howard',
      'Horsham',
      'Hornsby',
      'Home Hill',
      'Homebush',
      'Holsworthy',
      'Holmesville',
      'Holbrook',
      'Hobart',
      'Hill Top',
      'Hillston',
      'Highett',
      'Heywood',
      'Henty',
      'Helidon',
      'Helensburgh',
      'Heidelberg West',
      'Heidelberg',
      'Heddon Greta',
      'Heathmont',
      'Heatherton',
      'Heathcote',
      'Healesville',
      'Hazelbrook',
      'Hay Point',
      'Haymarket',
      'Hay',
      'Hawthorn South',
      'Hawthorn',
      'Hawks Nest',
      'Hatton Vale',
      'Hastings',
      'Harristown',
      'Harrington',
      'Harlaxton',
      'Hanwood',
      'Hampton Park',
      'Hampton',
      'Hamilton',
      'Hallam',
      'Hadspen',
      'Haddon',
      'Haberfield',
      'Gympie',
      'Guyra',
      'Gunnedah',
      'Gundaroo',
      'Gundagai',
      'Gulgong',
      'Grovedale',
      'Griffith',
      'Greta',
      'Grenfell',
      'Greenwell Point',
      'Greenvale',
      'Greensborough',
      'Green Point',
      'Greenbank',
      'Granville',
      'Granton',
      'Grafton',
      'Gracemere',
      'Gowrie Junction',
      'Goulburn',
      'Gosford',
      'Gorokan',
      'Gordonvale',
      'Gordon',
      'Goonellabah',
      'Goondiwindi',
      'Goodna',
      'Gooburrum',
      'Gol Gol',
      'Gold Coast',
      'Gloucester',
      'Glen Waverley',
      'Glenview',
      'Glenroy',
      'Glenorie',
      'Glenorchy',
      'Glen Innes',
      'Glen Huntly',
      'Glenhaven',
      'Glengarry',
      'Glenfield',
      'Glenferrie',
      'Glenella',
      'Gleneagle',
      'Glenbrook',
      'Glebe',
      'Glass House Mountains',
      'Gladstone',
      'Gisborne',
      'Gin Gin',
      'Gilston',
      'Gilgandra',
      'Gerringong',
      'George Town',
      'Gembrook',
      'Geeveston',
      'Geelong West',
      'Geelong',
      'Gaythorne',
      'Gayndah',
      'Gatton',
      'Garfield',
      'Galston',
      'Freshwater',
      'Frederickton',
      'Frankston East',
      'Frankston',
      'Franklin',
      'Foster',
      'Forster',
      'Forest Hill',
      'Forbes',
      'Footscray',
      'Fitzroy',
      'Finley',
      'Ferny Creek',
      'Fernvale',
      'Fawkner',
      'Faulconbridge',
      'Fairfield',
      'Evans Head',
      'Evandale',
      'Euroa',
      'Eumundi',
      'Eulomogo',
      'Eudlo',
      'Ettalong Beach',
      'Ettalong',
      'Essendon',
      'Esk',
      'Erina',
      'Epsom',
      'Epping',
      'Engadine',
      'Emu Plains',
      'Emu Park',
      'Empire Bay',
      'Emerald',
      'Eltham North',
      'Eltham',
      'Elsternwick',
      'Elliott Heads',
      'Elliminyt',
      'Elimbah',
      'Elderslie',
      'Eimeo',
      'Eight Mile Plains',
      'Eglinton',
      'Edmonton',
      'Edithvale',
      'Edge Hill',
      'Edgecliff',
      'Eden Park',
      'Eden',
      'Echuca',
      'Eastwood',
      'East Maitland',
      'East Hills',
      'East Gosford',
      'East Ballina',
      'Earlwood',
      'Eagle Point',
      'Eaglehawk',
      'Eagleby',
      'Dural',
      'Dungog',
      'Dunedoo',
      'Dubbo',
      'Drysdale',
      'Drouin',
      'Dromana',
      'Drayton',
      'Douglas Park',
      'Dorrigo',
      'Doreen',
      'Dora Creek',
      'Doonside',
      'Donvale',
      'Doncaster East',
      'Doncaster',
      'Donald',
      'Dingley Village',
      'Dimbulah',
      'Dimboola',
      'Diggers Rest',
      'Diamond Creek',
      'Devonport',
      'Devon Meadows',
      'Derrimut',
      'Dennington',
      'Denman',
      'Deniliquin',
      'Deloraine',
      'Deer Park',
      'Deception Bay',
      'Deagon',
      'Daylesford',
      'Dayboro',
      'Davistown',
      'Davidson',
      'Darra',
      'Darlington Point',
      'Darlinghurst',
      'Darley',
      'Dapto',
      'Dandenong',
      'Dalby',
      'Dakabin',
      'D’Aguilar',
      'Daceyville',
      'Cygnet',
      'Currie',
      'Curra',
      'Cunnamulla',
      'Cundletown',
      'Cumbalum',
      'Culcairn',
      'Croydon North',
      'Croydon',
      'Crows Nest',
      'Crookwell',
      'Cronulla',
      'Cringila',
      'Crib Point',
      'Creswick',
      'Cressy',
      'Crescent Head',
      'Cremorne',
      'Cranley',
      'Cranbourne',
      'Craigieburn',
      'Cowra',
      'Cowes',
      'Coutts Crossing',
      'Corryong',
      'Corrimal',
      'Corowa',
      'Cornubia',
      'Corio',
      'Corinda',
      'Coraki',
      'Cootamundra',
      'Coorparoo',
      'Cooroy',
      'Cooranbong',
      'Coonamble',
      'Coonabarabran',
      'Coominya',
      'Cooma',
      'Coolum Beach',
      'Coolangatta',
      'Coolamon',
      'Coolah',
      'Cooktown',
      'Coniston',
      'Condobolin',
      'Concord',
      'Como',
      'Colo Vale',
      'Collinsville',
      'Collingwood',
      'Collaroy',
      'Coledale',
      'Coldstream',
      'Colac',
      'Cohuna',
      'Coffs Harbour',
      'Cockatoo',
      'Coburg',
      'Cobram',
      'Cobden',
      'Cobbitty',
      'Cobar',
      'Clyde North',
      'Clyde',
      'Clunes',
      'Cloncurry',
      'Clifton Springs',
      'Clifton Hill',
      'Clifton',
      'Cleveland',
      'Clermont',
      'Clayton South',
      'Clayton',
      'Clarinda',
      'Clarence Town',
      'Wollongong',
      'Churchill',
      'Chipping Norton',
      'Chippendale',
      'Chinderah',
      'Chinchilla',
      'Chiltern',
      'Childers',
      'Chewton',
      'Chelsea Heights',
      'Chelsea',
      'Chatswood',
      'Charters Towers',
      'Charlton',
      'Charleville',
      'Charlestown',
      'Cessnock',
      'Cedar Grove',
      'Caulfield North',
      'Caulfield East',
      'Caulfield',
      'Castlemaine',
      'Castle Hill',
      'Castlecrag',
      'Casterton',
      'Casino',
      'Carrum Downs',
      'Carrum',
      'Carnegie',
      'Carlton North',
      'Carlton',
      'Carlingford',
      'Carisbrook',
      'Caringbah',
      'Cardwell',
      'Cardiff',
      'Carbrook',
      'Capella',
      'Capalaba',
      'Canungra',
      'Canowindra',
      'Cannonvale',
      'Canberra',
      'Canadian',
      'Camp Mountain',
      'Camperdown',
      'Campbells Creek',
      'Campbellfield',
      'Campbell',
      'Camden',
      'Cambridge',
      'Cambooya',
      'Camberwell',
      'Cambewarra Village',
      'Caloundra',
      'Calliope',
      'Cairns',
      'Caboolture',
      'Cabarlah',
      'Bywong',
      'Byron Bay',
      'Buxton',
      'Burwood East',
      'Burwood',
      'Burradoo',
      'Burpengary',
      'Burnie',
      'Burnett Heads',
      'Burleigh Heads',
      'Bunyip',
      'Buninyong',
      'Bungendore',
      'Bundanoon',
      'Bundamba',
      'Bundaberg',
      'Bulli',
      'Bulleen',
      'Bulahdelah',
      'Budgewoi',
      'Buderim',
      'Buccan',
      'Bucca',
      'Bucasia',
      'Brunswick West',
      'Brunswick Heads',
      'Brunswick East',
      'Brunswick',
      'Brown Hill',
      'Broulee',
      'Brookfield',
      'Broken Hill',
      'Broadmeadows',
      'Broadford',
      'Broadbeach',
      'Brisbane',
      'Bringelly',
      'Bright',
      'Bridport',
      'Briar Hill',
      'Briagolong',
      'Brewarrina',
      'Branxton',
      'Brandon',
      'Braidwood',
      'Boyne Island',
      'Box Hill',
      'Bowraville',
      'Bowral',
      'South Bowenfels',
      'Bowen',
      'Bourke',
      'Botany',
      'Boronia',
      'Bordertown',
      'Boorowa',
      'Boondall',
      'Boonah',
      'Booker Bay',
      'Bonville',
      'Bongaree',
      'Bombala',
      'Bomaderry',
      'Boggabri',
      'Boambee',
      'Bli Bli',
      'Blayney',
      'Blaxland',
      'Blair Athol',
      'Blackwater',
      'Blackwall',
      'Blacktown',
      'Blacks Beach',
      'Blackmans Bay',
      'Black Hill',
      'Blackheath',
      'Blackall',
      'Bittern',
      'Birkdale',
      'Bingara',
      'Biloela',
      'Bilinga',
      'Bexley',
      'Beveridge',
      'Berwick',
      'Berry',
      'Berrigan',
      'Berriedale',
      'Berridale',
      'Berri',
      'Berowra',
      'Bermagui',
      'Berkeley Vale',
      'Berkeley',
      'Beresfield',
      'Bentleigh East',
      'Bentleigh',
      'Bensville',
      'Benowa',
      'Bendigo',
      'Benaraby',
      'Benalla',
      'Belrose',
      'Belmont',
      'Bellingen',
      'Bellerive',
      'Bellbird',
      'Bellambi',
      'Belgrave South',
      'Belgrave Heights',
      'Belgrave',
      'Belgian Gardens',
      'Belconnen',
      'Bega',
      'Beerwah',
      'Beenleigh',
      'Beechworth',
      'Beauty Point',
      'Beaumaris',
      'Beaufort',
      'Beaudesert',
      'Beaconsfield Upper',
      'Beaconsfield',
      'Beachmere',
      'Bay View',
      'Bayswater',
      'Baxter',
      'Baulkham Hills',
      'Batlow',
      'Bathurst',
      'Batemans Bay',
      'Bass Hill',
      'Barraba',
      'Barooga',
      'Barnsley',
      'Barmera',
      'Barham',
      'Bargo',
      'Bargara',
      'Barcaldine',
      'Baranduda',
      'Banora Point',
      'Bannockburn',
      'Bankstown',
      'Bangalow',
      'Bamaga',
      'Balwyn',
      'Balranald',
      'Balnarring',
      'Balmain',
      'Ballina',
      'Ballarat East',
      'Ballarat',
      'Ballan',
      'Balgownie',
      'Balgowlah',
      'Balaclava',
      'Bakers Creek',
      'Bairnsdale',
      'Bahrs Scrub',
      'Bagdad',
      'Badger Creek',
      'Bacchus Marsh',
      'Babinda',
      'Ayr',
      'Avoca Beach',
      'Avoca',
      'Avenel',
      'Austins Ferry',
      'Austinmer',
      'Aurukun',
      'Auburn',
      'Atherton',
      'Asquith',
      'Aspley',
      'Aspendale',
      'Ashgrove',
      'Ashfield',
      'Ashburton',
      'Ascot Vale',
      'Ascot',
      'Artarmon',
      'Armidale',
      'Arcadia',
      'Ararat',
      'Appin',
      'Apollo Bay',
      'Annandale',
      'Anna Bay',
      'Anglesea',
      'Andergrove',
      'Alton Downs',
      'Altona',
      'Alstonville',
      'Alphington',
      'Allora',
      'Allansford',
      'Alfredton',
      'Alexandria',
      'Alexandra',
      'Aldershot',
      'Aldavilla',
      'Albury',
      'Albion Park',
      'Albion',
      'Albany Creek',
      'Aitkenvale',
      'Acacia Ridge',
      'Abermain',
      'Aberglasslyn',
      'Aberdeen',
      'Aberdare',
      'Abbotsford',
      'Morpeth',
      'Lambton',
      'New Lambton',
      'Mayfield',
      'Invermay',
      'Gymea',
      'Kirrawee',
      'Loftus',
      'Woolooware',
      'Lilli Pilli',
      'Burraneer',
      'Bundeena',
      'Yowie Bay',
      'Gymea Bay',
      'Yarrawarrah',
      'Woronora',
      'Bangor',
      'Kareela',
      'Sylvania',
      'Sylvania Waters',
      'Taren Point',
      'Bonnet Bay',
      'Oyster Bay',
      'Grays Point',
      'Little Bay',
      'Brighton-Le-Sands',
      'Monterey',
      'Ramsgate',
      'Sans Souci',
      'Blakehurst',
      'Connells Point',
      'Illawong',
      'Lugarno',
      'Peakhurst',
      'Padstow',
      'Riverwood',
      'Goodwood',
      'Lutana',
      'New Town',
      'Lake Heights',
      'Warrawong',
      'Sandy Bay',
      'Tarrawanna',
      'Fern Hill',
      'Towradgi',
      'Fairy Meadow',
      'Mount Ousley',
      'Mount Keira',
      'Gwynneville',
      'Figtree',
      'Mangerton',
      'Mount Saint Thomas',
      'Palm Cove',
      'Yorkeys Knob',
      'Norlane',
      'Bulimba',
      'New Farm',
      'Norman Park',
      'Hawthorne',
      'East Brisbane',
      'South Brisbane',
      'Maroochydore',
      'Slade Point',
      'Bellara',
      'Holloways Beach',
      'Bonbeach',
      'McCrae',
      'North Brighton',
      'Bellevue Hill',
      'Rose Bay',
      'Paddington',
      'Ultimo',
      'Lilyfield',
      'Drummoyne',
      'Greenwich',
      'Hunters Hill',
      'Longueville',
      'Cammeray',
      'Northbridge',
      'Seaforth',
      'Balgowlah Heights',
      'Clontarf',
      'Neutral Bay',
      'Point Piper',
      'Darling Point',
      'Dover Heights',
      'Randwick',
      'Clovelly',
      'Bronte',
      'Bondi',
      'Gladesville',
      'Forestville',
      'Brookvale',
      'Curl Curl',
      'Dee Why',
      'Umina Beach',
      'Blairgowrie',
      'Flinders',
      'Airport West',
      'Niddrie',
      'Gladstone Park',
      'Palmerston',
      'Tom Price',
      'Airlie Beach',
      'Hervey Bay',
      'Yulara',
      'Moranbah',
      'Heyfield',
      'Sydney Central Business District',
      'Kaleen',
      'Karratha',
      'Biggera Waters',
      'Enfield',
      'Hillarys',
      'Kingsford',
      'Klemzig',
      'Maidstone',
      'Osborne Park',
      'Shoal Bay',
      'Seven Mile Beach',
      'Woollahra',
      'Agnes Water',
      'Salamander Bay',
      'Kooralbyn',
      'Wolli Creek',
      'Corlette',
      'Burswood',
      'Howrah',
      'Clifton Beach',
      'Springfield Lakes',
      'Attwood',
      'Alexandra Headland',
      'North Narrabeen',
      'Joondalup',
      'Arncliffe',
      'Middlemount',
      'East Lismore',
      'Mindarie',
      'Clarkson',
      'Kinross',
      'Currambine',
      'Heathridge',
      'Paradise Point',
      'Girraween',
      'Tieri',
      'Mount Buller',
      'Kingsbury',
      'Herston',
      'Kelvin Grove',
      'Bowen Hills',
      'Taringa',
      'Chapel Hill',
      'Kenmore',
      'Inala',
      'Mount Lawley',
      'Main Beach',
      'Paradise',
      'Athelstone',
      'Nundah',
      'Lutwyche',
      'Wooloowin',
      'Banyo',
      'Nudgee',
      'Zillmere',
      'Taigum',
      'Carseldine',
      'Bracken Ridge',
      'Brendale',
      'Kallangur',
      'Mango Hill',
      'North Lakes',
      'West End',
      'Highgate Hill',
      'Woolloongabba',
      'Dutton Park',
      'Auchenflower',
      'Indooroopilly',
      'Mount Ommaney',
      'Macgregor',
      'Fortitude Valley',
      'Clayfield',
      'Chermside',
      'Ferny Grove',
      'Keperra',
      'The Gap',
      'Vincentia',
      'Fitzgibbon',
      'Bridgeman Downs',
      'Zilzie',
      'Canada Bay',
      'Canterbury',
      'Fairfield West',
      'Willoughby',
      'Forresters Beach',
      'Wongawallan',
      'Tamborine Mountain',
      'East Melbourne',
      'Rainbow Beach',
      'Peregian Beach',
      'Medindie',
      'Salisbury East',
      'Goolwa Beach',
      'North Ryde',
      'Parkside',
      'Caroline Springs',
      'Hoppers Crossing',
      'West Melbourne',
      'Rouse Hill',
      'Logan City',
      'Carindale',
      'Parramatta',
      'Macquarie',
      'Ferntree Gully',
      'Kewarra Beach',
      'Doonan',
      'Fullarton',
      'Smiths Lake',
      'Mollymook Beach',
      'Shell Cove',
      'Burrill Lake',
      'Glenning Valley',
      'Dawesville',
      'Jabiru',
      'Macarthur',
      'Phillip Island',
      'Adelaide Hills',
      'Erskine',
      'Canning Vale',
      'Varsity Lakes',
      'Roselands',
      'Oxenford',
      'Toormina',
      'Glenmore Park',
      'Southbank',
      'Magnetic Island',
      'St Kilda East',
      'Golden Beach',
      'Sanctuary Point',
      'Docklands',
      'Hollywell',
      'St. Georges Basin',
      'Shoalhaven Heads',
      'Mermaid Beach',
      'Helensvale',
      'West Gosford',
      'Suffolk Park',
      'Mount Annan',
      'Watanobbi',
      'Miami',
      'Shoalwater',
      'Melton West',
      'Kurunjang',
      'Ashwood',
      'Elwood',
      'Box Hill South',
      'Blackburn',
      'Hawthorn East',
      'Kew East',
      'Balwyn North',
      'Mont Albert North',
      'Box Hill North',
      'Blackburn North',
      'Blackburn South',
      'Chadstone',
      'Malvern East',
      'Oakleigh South',
      'Caulfield South',
      'Brighton East',
      'Hampton East',
      'Black Rock',
      'Aspendale Gardens',
      'Booval',
      'North Booval',
      'North Ipswich',
      'Brassall',
      'Silkstone',
      'Eastern Heights',
      'Raceview',
      'Flinders View',
      'Deebing Heights',
      'Yamanto',
      'Redbank Plains',
      'Collingwood Park',
      'Woody Point',
      'Kippa-Ring',
      'Rothwell',
      'Kurwongbah',
      'Labrador',
      'Ballarat Central',
      'Golden Point',
      'Delacombe',
      'Mount Helen',
      'St Albans',
      'Rozelle',
      'St Leonards',
      'Mount Coolum',
      'St Kilda West',
      'Eleebana',
      'Summer Hill',
      'The Entrance North',
      'Caravonica',
      'Basin View',
      'Alligator Creek',
      'Bogangar',
      'Bohle Plains',
      'Bonny Hills',
      'Booral',
      'Burrum Heads',
      'Bushland Beach',
      'Callala Bay',
      'Cooloola Cove',
      'Craiglie',
      'Craignish',
      'Highfields',
      'Deeragun',
      'Dodges Ferry',
      'Dundowran Beach',
      'Emerald Beach',
      'Estella',
      'Gagebrook',
      'Galiwinku',
      'Hope Vale',
      'Innes Park',
      'Jacobs Well',
      'Jensen',
      'Karalee',
      'Karana Downs',
      'Launching Place',
      'Long Beach',
      'Marcoola',
      'Maslin Beach',
      'McGraths Hill',
      'Old Erowal Bay',
      'Tannum Sands',
      'River Heads',
      'Sandy Beach',
      'Silverdale',
      'Sippy Downs',
      'Saint Andrews Beach',
      'Tura Beach',
      'Turners Beach',
      'Waterview Heights',
      'Withcott',
      'Wongaling Beach',
      'Yaroomba',
      'Murarrie',
      'North Perth',
      'West Perth',
      'Altona North',
      'Balcatta',
      'North Parramatta',
      'Chain Valley Bay',
      'Cherbourg',
      'Coleambally',
      'Bateau Bay',
      'Alice River',
      'Bonnells Bay',
      'Bouldercombe',
      'Bowen Mountain',
      'Buronga',
      'Cooran',
      'Dalmeny',
      'Doomadgee',
      'Dysart',
      'Ellalong',
      'Fingal Bay',
      'Freemans Reach',
      'Gelorup',
      'Gillieston Heights',
      'Glossodia',
      'Gulmarrad',
      'Gwandalan',
      'Mount Low',
      'Hope Island',
      'Howard Springs',
      'Indented Head',
      'Lake Cathie',
      'Lake Munmorah',
      'Leinster',
      'Lemon Tree Passage',
      'Lennox Head',
      'Leschenault',
      'Little Grove',
      'Londonderry',
      'Malua Bay',
      'Mannering Park',
      'Macmasters Beach',
      'Milingimbi',
      'Mudjimba',
      'Nathalia',
      'Nelly Bay',
      'Ngukurr',
      'Ningi',
      'Ocean Shores',
      'Sussex Inlet',
      'Palm Island',
      'Paraburdoo',
      'Pottsville Beach',
      'Robinvale',
      'Sandstone Point',
      'Sellicks Beach',
      'South West Rocks',
      'Thursday Island',
      'Warragamba',
      'Wickham',
      'East Perth',
      'Leederville',
      'Doveton',
      'Endeavour Hills',
      'Murray',
      'Sefton',
      'Moorebank',
      'Turrella',
      'Montello',
      'Thurgoona',
      'Calala',
      'Acacia Gardens',
      'Annangrove',
      'Glenfield Park',
      'Adamstown Heights',
      'Alfords Point',
      'Argenton',
      'Surrey Downs',
      'Myrtle Bank',
      'Ashbury',
      'Spalding',
      'Nailsworth',
      'Holt',
      'Ross',
      'Hocking',
      'Batehaven',
      'Peterhead',
      'Tascott',
      'Shailer Park',
      'Ashcroft',
      'Ashmont',
      'Tennyson Point',
      'Glenalta',
      'Blue Haven',
      'Bonnyrigg Heights',
      'Tighes Hill',
      'Bossley Park',
      'White Gum Valley',
      'Bullaburra',
      'Wonthella',
      'Clarendon Vale',
      'Woorree',
      'Kellyville',
      'Yakamia',
      'Yalyalup',
      'Gunbalanya',
      'Catherine Field',
      'Cook',
      'Llandilo',
      'Tapping',
      'Seabrook',
      'Wellesley Islands',
      'Casuarina',
      'Canley Vale',
      'Wadalba',
      'Daw Park',
      'Whitsundays',
      'Denistone East',
      'Cromer',
      'Bourkelands',
      'St Helens Park',
      'St Huberts Island',
      'St Ives Chase',
      'Winmalee',
      'Blackalls Park',
      'Horsley',
      'East Killara',
      'East Kurrajong',
      'Erskine Park',
      'Oakdowns',
      'Craigmore',
      'Bow Bowing',
      'Berrimah',
      'Anula',
      'Breakfast Point',
      'Frenchs Forest',
      'Larapinta',
      'Girards Hill',
      'Wanguri',
      'Moulden',
      'Idalia',
      'Googong',
      'Torrens Park',
      'Stretton',
      'Grose Vale',
      'Harrington Park',
      'Tiwi',
      'Allenstown',
      'Cumberland Park',
      'Busby',
      'Abbey',
      'Kings Park',
      'Marks Point',
      'Geebung',
      'Metford',
      'Maryland',
      'Taylors Lakes',
      'Banksia Park',
      'Kosciuszko National Park',
      'Rapid Creek',
      'Gnangara',
      'Macquarie Park',
      'Maraylya',
      'Centenary Heights',
      'Woodroffe',
      'Calwell',
      'Pagewood',
      'Kuluin',
      'City',
      'Farrer',
      'Cherrybrook',
      'Evanston',
      'Forde',
      'Tatton',
      'Lyneham',
      'Kensington Park',
      'Woodcroft',
      'Menora',
      'Russell Lea',
      'Daisy Hill',
      'Pooraka',
      'Bokarina',
      'Chifley',
      'Ashby',
      'Naremburn',
      'Woodvale',
      'Plumpton',
      'Bellfield',
      'North Rocks',
      'Roxburgh Park',
      'Old Guildford',
      'Orangeville',
      'Ourimbah',
      'Underwood',
      'Mitchell Park',
      'Waratah West',
      'Jamboree Heights',
      'Eumemmerring',
      'Prairiewood',
      'Prestons',
      'Wurrumiyanga',
      'Razorback',
      'Netley',
      'Reedy Creek',
      'Wamboin',
      'Voyager Point',
      'Warwick Farm',
      'Warriewood',
      'Russell Vale',
      'Moil',
      'Warilla',
      'Shortland',
      'Beeliar',
      'Cowandilla',
      'Dharruk',
      'Tanilba Bay',
      'Werrington',
      'Winston Hills',
      'Wetherill Park',
      'Ardeer',
      'Tennyson',
      'Southside',
      'Yarravel',
      'St George',
      'Westleigh',
      'Farrar',
      'Whalan',
      'Edensor Park',
      'Elanora Heights',
      'Avondale Heights',
      'Lynbrook',
      'Hunterview',
      'Wentworth Point',
      'Fern Bay',
      'West Beach',
      'Payneham South',
      'Wagaman',
      'Norman Gardens',
      'Ridgehaven',
      'Bayview Heights',
      'Kurralta Park',
      'Toorak Gardens',
      'Hmas Cerberus',
      'Windradyne',
      'Invermay Park',
      'Brentwood',
      'Ivanhoe',
      'Woodberry',
      'Woongarrah',
      'Hillcrest',
      'Fairview Park',
      'Kangaroo Ground',
      'Croydon Park',
      'Kialla',
      'Kapooka',
      'Drewvale',
      'Westlake',
      'Collinswood',
      'Manifold Heights',
      'Mickleham',
      'Junction Hill',
      'Greenacres',
      'Botanic Ridge',
      'Turner',
      'Oakden',
      'Colonel Light Gardens',
      'King Creek',
      'Nichols Point',
      'Jackass Flat',
      'Henley Beach South',
      'Avenell Heights',
      'Davoren Park',
      'Redan',
      'Glenside',
      'Banksia Grove',
      'Bluff Point',
      'Calista',
      'Orelia',
      'Macquarie Hills',
      'Waterways',
      'Golden Bay',
      'Gwelup',
      'Merewether',
      'Jolimont',
      'Magill',
      'Wyndham Vale',
      'Brinkin',
      'Ballajura',
      'Maryville',
      'Wynn Vale',
      'Ardross',
      'Noranda',
      'Cambridge Gardens',
      'Bateman',
      'Piara Waters',
      'Dulwich',
      'Long Gully',
      'College Grove',
      'Willetton',
      'Booie',
      'Princes Hill',
      'Green Valley',
      'Prospect Vale',
      'Bungalow',
      'Youngtown',
      'East Side',
      'Cashmere',
      'Fadden',
      'Tarcoola Beach',
      'Page',
      'Cotswold Hills',
      'Teneriffe',
      'Thomson',
      'Bulgarra',
      'St Albans Park',
      'Kooringal',
      'Glenwood',
      'Heathwood',
      'Mira Mar',
      'Glen Eden',
      'Highbury',
      'Highland Park',
      'Pemulwuy',
      'Silverwater',
      'Exeter',
      'Petersham',
      'The Range',
      'Hyde Park',
      'Heritage Park',
      'Travancore',
      'Manoora',
      'Panorama',
      'Raby',
      'St Andrews',
      'Carramar',
      'Springdale Heights',
      'Macleay Island',
      'Baynton',
      'Menzies',
      'Newborough',
      'Kalkie',
      'Mission River',
      'Lake Gardens',
      'Ascot Park',
      'Newcomb',
      'Para Hills',
      "O'Connor",
      'Rushcutters Bay',
      'Edwardstown',
      'Andrews Farm',
      'Railway Estate',
      "O'Sullivan Beach",
      'Bellevue Heights',
      'Broadview',
      'Petrie Terrace',
      'Clearview',
      'Regency Downs',
      'Rasmussen',
      'Rosemount',
      'Sheldon',
      'Boronia Heights',
      'Glen Osmond',
      'Usher',
      'Sunshine Bay',
      'Seaholme',
      'Surfside',
      'Black Forest',
      'Tamarama',
      'Rockville',
      'Ashford',
      'Athol Park',
      'Brahma Lodge',
      'Dover Gardens',
      'Bundall',
      'Hermit Park',
      'South Kingsville',
      'Yennora',
      'Aberfeldie',
      'Onkaparinga Hills',
      'Merrimac',
      'Rosslyn Park',
      'Seacombe Gardens',
      'Pimlico',
      'Molendinar',
      'Stonyfell',
      'Currajong',
      'St Agnes',
      'Douglas',
      'Millbank',
      'Burnside',
      'Safety Beach',
      'Rostrevor',
      'Kanimbla',
      'Blind Bight',
      'Alexander Heights',
      'Lamington',
      'Carlisle',
      'Coodanup',
      'Dampier Peninsula',
      'Ottoway',
      'Atwell',
      'Aveley',
      'Piccadilly',
      'Mount Warren Park',
      'Balga',
      'Helena Valley',
      'Cape Woolamai',
      'Ridgewood',
      'Pioneer',
      'Greenwood',
      'Augustine Heights',
      'Lakelands',
      'Dallas',
      'Leeming',
      'Delahey',
      'Parkwood',
      'Downer',
      'St Marys',
      'Munno Para West',
      'Wilson',
      'Ridleyton',
      'Beresford',
      'North Lake',
      'Upper Kedron',
      'Wishart',
      'Depot Hill',
      'Butler',
      'South Hedland',
      'Darch',
      'Riverside',
      'Geilston Bay',
      'Trevallyn',
      'Osborne',
      'St Georges',
      'Shorewell Park',
      'Rosetta',
      'Tranmere',
      'Geographe',
      'Herbert',
      'Pasadena',
      'Parrearra',
      'Kambah',
      'Reid',
      'Watson',
      'Yarralumla',
      'Queens Park',
      'Edens Landing',
      'Redwood Park',
      'Gilberton',
      'Banks',
      'Koondoola',
      'Innaloo',
      'Cannon Hill',
      'Park Holme',
      'Paringa',
      'Carina Heights',
      'Pennington',
      'Dickson',
      'Darling Downs',
      'Madeley',
      'Booragoon',
      'Glynde',
      'Christie Downs',
      'Abbotsbury',
      'Rossmoyne',
      'Abercrombie',
      'Airds',
      'Allawah',
      'Doubleview',
      'Netherby',
      'Ambarvale',
      'Adamstown',
      'Austral',
      'Theodore',
      'Bonnyrigg',
      'Mundingburra',
      'Seville Grove',
      'Barden Ridge',
      'Birmingham Gardens',
      'Canton Beach',
      'Bardwell Park',
      'Beacon Hill',
      'Ironbark',
      'Wondunna',
      'Belmore',
      'Woodpark',
      'Berkshire Park',
      'Beverly Hills',
      'Murrumba Downs',
      'Glendalough',
      'Blakeview',
      'Birchgrove',
      'Ellen Grove',
      'Carrara',
      'Georgetown',
      'Booragul',
      'North Ward',
      'Lota',
      'Underdale',
      'Cabramatta',
      'Everton Hills',
      'Cameron Park',
      'Buttaba',
      'Elanora',
      'Meadow Springs',
      'Campsie',
      'Crestwood',
      'Catalina',
      'Canley Heights',
      'Carss Park',
      'Cartwright',
      'Chelmer',
      'Arundel',
      'Warradale',
      'Castlereagh',
      'Casula',
      'Bella Vista',
      'Carwoola',
      'Caves Beach',
      'Bilingurr',
      'Cecil Hills',
      'Beechboro',
      'Chester Hill',
      'Knoxfield',
      'Kent Town',
      'Chambers Flat',
      'Chiswick',
      'Claremont Meadows',
      'Ashmore',
      'Colyton',
      'Kin Kora',
      'Banksia Beach',
      'Barellan Point',
      'Copacabana',
      'Hawthorndene',
      'Cranebrook',
      'Hebersham',
      'Dean Park',
      'Chuwar',
      'Coes Creek',
      'Champion Lakes',
      'Blue Bay',
      'Aroona',
      'Double Bay',
      'Birrong',
      'Dudley',
      'Kensington Grove',
      'Bligh Park',
      'Eagle Vale',
      'Brookdale',
      'Marrara',
      'Little Mountain',
      'Bardon',
      'Edgeworth',
      'Wakerley',
      'Elizabeth Bay',
      'Emerton',
      'New Beith',
      'Erskineville',
      'Eschol Park',
      'Pacific Paradise',
      'Ainslie',
      'Farmborough Heights',
      'Five Dock',
      'Gailes',
      'Eastlakes',
      'Rosslea',
      'Holland Park West',
      'Huntingdale',
      'Gaven',
      'Tanawha',
      'Glendenning',
      'Tanah Merah',
      'Wulguru',
      'Yarrabah',
      'Greenacre',
      'Greenfield Park',
      'Beulah Park',
      'Eden Hills',
      'Glandore',
      'Greystanes',
      'Halekulani',
      'Hammondville',
      'Morphettville',
      'Newton',
      'Horningsea Park',
      'Hoxton Park',
      'Ellenbrook',
      'Hurlstone Park',
      'Islington',
      'Castletown',
      'Connolly',
      'Lockridge',
      'Jerrabomberra',
      'Alderley',
      'Glen Forrest',
      'Kanahooka',
      'Greenfields',
      'Hamilton Hill',
      'Gray',
      'Karabar',
      'Norville',
      'Kariong',
      'Alexandra Hills',
      'Kilaben Bay',
      'Jamisontown',
      'Nollamara',
      'Kingsgrove',
      'Taranganba',
      'Hobartville',
      'Sinagra',
      'Liberty Grove',
      'Jandakot',
      'Withers',
      'Lalor Park',
      'Langford',
      'Bellbowrie',
      'Lathlain',
      'Leda',
      'Leonay',
      'Dernancourt',
      'Lethbridge Park',
      'Callaghan',
      'Fraser',
      'Mount Tarcoola',
      'Mount Richon',
      'Leppington',
      'Millers Point',
      'Bakewell',
      'Padbury',
      'Macquarie Fields',
      'Pearsall',
      'Beachlands',
      'Park Grove',
      'Lynwood',
      'Marayong',
      'Alawa',
      'Mardi',
      'Holtze',
      'Johnston',
      'East Victoria Park',
      'McMahons Point',
      'Willagee',
      'Giralang',
      'Milperra',
      'Soldiers Hill',
      'Middle Cove',
      'Mawson',
      'Tecoma',
      'Minchinbury',
      'Eli Waters',
      'Mount Kuring-Gai',
      'Mount Pritchard',
      'Telina',
      'Palmyra',
      'Narrawallee',
      'Brockman',
      'Mount Lofty',
      'Mount Vernon',
      'Middle Park',
      'Niagara Park',
      'Stratton',
      'North Avoca',
      'Oxley Park',
      'Oxley Vale',
      'Bentley Park',
      'North St Marys',
      'Mayfield East',
      'Oakville',
      'Berserker',
      'Oak Flats',
      'Birtinya',
      'Narwee',
      'Panania',
      'Point Frederick',
      'Mile End',
      'Primbee',
      'Claymore',
      'Putney',
      'Tootgarook',
      'Condell Park',
      'Berrinba',
      'Sadleir',
      'Ropes Crossing',
      'Rosemeadow',
      'Silver Sands',
      'Ruse',
      'Spencer Park',
      'Saratoga',
      'Viveash',
      'Kawana',
      'Kedron',
      'Burns Beach',
      'Brinsmead',
      'South Hurstville',
      'Glengowrie',
      'Wyoming',
      'Branyan',
      'Speers Point',
      'Firle',
      'Summerland Point',
      'Seaview Downs',
      'Hewett',
      'Calamvale',
      'Burdell',
      'Yagoona',
      'Zetland',
      'Machans Beach',
      'Shelly Beach',
      'Jubilee Pocket',
      'Bell Park',
      'Bell Post Hill',
      'Enoggera',
      'Aubin Grove',
      'Quarry Hill',
      'Albanvale',
      'Battery Hill',
      'Coombabah',
      'Coral Cove',
      'Gillen',
      'Park Avenue',
      'Currumbin Valley',
      'Gunn',
      'Turvey Park',
      'Park Orchards',
      'Hayborough',
      'Heckenberg',
      'Hove',
      'Tregear',
      'Flora Hill',
      'Earlville',
      'Golden Square',
      'Annerley',
      'Haven',
      'Anstead',
      'Broadwater',
      'Currans Hill',
      'Durack',
      'Highton',
      'Kealba',
      'Hotham Heights',
      'St Morris',
      'Jacana',
      'Jan Juc',
      'Warners Bay',
      'Junortoun',
      'Dulwich Hill',
      'Yarrambat',
      'Sinnamon Park',
      'Wiley Park',
      'Westdale',
      'Hillvue',
      'Mount Sheridan',
      'Viewbank',
      'Vale Park',
      'Bellbird Park',
      'Bellmere',
      'Gulliver',
      'Albert Park',
      'Garbutt',
      'Brookwater',
      'Buddina',
      'Nakara',
      'Crestmead',
      'Doolandella',
      'Fletcher',
      'Fig Tree Pocket',
      'Munruben',
      'Forestdale',
      'Frenchville',
      'Griffin',
      'Highvale',
      'Joyner',
      'Kepnock',
      'West Croydon',
      'Kuraby',
      'Craigie',
      'Condon',
      'Koongal',
      'Logan Central',
      'McDowall',
      'Lake Macdonald',
      'Maroochy River',
      'Marsden',
      'Gowrie',
      'Meadowbrook',
      'Minyama',
      'Mooroobool',
      'Manunda',
      'Forest Lodge',
      'Chirnside Park',
      'Moorooka',
      'Mundoolun',
      'Coolaroo',
      'New Auckland',
      'Karawara',
      'Georges Hall',
      'Ninderry',
      'Dinner Plain',
      'Risdon Park',
      'Eaglemont',
      'Wandina',
      'Pacific Pines',
      'Rosewater',
      'Pelican Waters',
      'Robina',
      'Runaway Bay',
      'Runcorn',
      'Rural View',
      'Junction Village',
      'Sunrise Beach',
      'Sunset',
      'The Gemfields',
      'Tinana',
      'Tingalpa',
      'Hamlyn Terrace',
      'Hillsdale',
      'Hampstead Gardens',
      'Warana',
      'Warner',
      'Maiden Gully',
      'Willowbank',
      'Wilsonton',
      'Wandi',
      'Meadow Heights',
      'Cooee Bay',
      'Sun Valley',
      'Torrens',
      'Wurtulla',
      'Kearns',
      'Algester',
      'Kings Langley',
      'Fulham Gardens',
      'Flagstaff Hill',
      'Flinders Park',
      'Greenwith',
      'Gulfview Heights',
      'Huntfield Heights',
      'Whitfield',
      'Kidman Park',
      'Lockleys',
      'McCracken',
      'Willow Vale',
      'Westminster',
      'Paralowie',
      'Lansvale',
      'Winston',
      'Sheidow Park',
      'Stirling North',
      'West Lakes',
      'Tallai',
      'Westbourne Park',
      'Strathdale',
      'Baldivis',
      'Bertram',
      'Banjup',
      'Bayonet Head',
      'Beckenham',
      'Bibra Lake',
      'Cable Beach',
      'Driver',
      'Cloverdale',
      'Camillo',
      'Carey Park',
      'Parkinson',
      'Carine',
      'Cockburn Central',
      'Girrawheen',
      'Churchlands',
      'Coolbellup',
      'Cooloongup',
      'Lurnea',
      'Dalyellup',
      'Dianella',
      'Duncraig',
      'Djugun',
      'Edgewater',
      'Forrestfield',
      'Falcon',
      'Forrestdale',
      'Gidgegannup',
      'Hammond Park',
      'Halls Head',
      'Hannans',
      'Hillman',
      'High Wycombe',
      'Kingsley',
      'Yallambie',
      'Kardinya',
      'Karrinyup',
      'Madora Bay',
      'Kewdale',
      'Kiara',
      'Lesmurdie',
      'Martin',
      'Millars Well',
      'Melrose Park',
      'Morley',
      'Mount Hawthorn',
      'Mount Melville',
      'Mount Nasura',
      'Murdoch',
      'Ocean Reef',
      'Pegs Creek',
      'Samson',
      'Port Kennedy',
      'Rangeway',
      'Secret Harbour',
      'Salter Point',
      'St George Ranges',
      'Success',
      'Sunset Beach',
      'Tuart Hill',
      'Walkervale',
      'Thornlie',
      'Braitling',
      'Wandal',
      'Waikiki',
      'Wannanup',
      'Winthrop',
      'Mount Hutton',
      'Yangebup',
      'Yokine',
      'Battery Point',
      'Blackstone Heights',
      'Chigwell',
      'Mount Austin',
      'Mount Riverview',
      'Herdsmans Cove',
      'Kings Meadows',
      'Lenah Valley',
      'Kilburn',
      'Mount Nelson',
      'Rocherlea',
      'Coconut Grove',
      'Shearwater',
      'Kilkenny',
      'Humpty Doo',
      'Jingili',
      'Malak',
      'Millner',
      'Lyons',
      'Casey',
      'Felixstow',
      'Barton',
      'Bonython',
      'Braddon',
      'Chapman',
      'Conder',
      'Coppabella',
      'Charnwood',
      'Curtin',
      'Deakin',
      'Dunlop',
      'Florey',
      'Evatt',
      'Greenway',
      'Landsdale',
      'Gungahlin',
      'Harrison',
      'Hackett',
      'Higgins',
      'Isaacs',
      'Isabella Plains',
      'McKellar',
      'Nicholls',
      'Melba',
      'Ngunnawal',
      'Richardson',
      'Rivett',
      'Wanniassa',
      'Waramanga',
      'Centennial Park',
      'Bidwill',
      'Blackbutt',
      'Bradbury',
      'Kearneys Spring',
      'Cabarita',
      'Clinton',
      'Northgate',
      'Hinchinbrook',
      'Kirkwood',
      'Meadowbank',
      'Llanarth',
      'Oatlands',
      'Bull Creek',
      'Punchbowl',
      'Valentine',
      'Bray Park',
      'Queenton',
      'Woodbine',
      'Lockyer',
      'Shalvey',
      'Parafield Gardens',
      'Brooklyn',
      'Camira',
      'Hillside',
      'Kangaroo Point',
      'Redcliffe',
      'Millstream',
      'Kings Beach',
      'Wavell Heights',
      'Heatley',
      'Fulham',
      'Jindalee',
      'Riverton',
      'Shelley',
      'Waterford',
      'Wakeley',
      'Wattle Grove',
      'Araluen',
      'Miandetta',
      'Mowbray',
      'Norwood',
      'The Vines',
      'Waverley',
      'Ravenswood',
      'Cossack',
      'Bruce',
      'Chisholm',
      'Fisher',
      'Flynn',
      'Holder',
      'Latham',
      'Bonogin',
      'Spence',
      'Kamerunga',
      'Ooralea',
      'Glenvale',
      'St Johns Park',
      'Rangewood',
      'St Clair',
      'Mawson Lakes',
      'Scullin',
      'The Ponds',
      'Tolland',
      'Amaroo',
      'Holden Hill',
      'Aranda',
      'Vincent',
      'Willmot',
      'Holmview',
      'Graceville',
      'Hemmant',
      'Woodrising',
      'Bunya',
      'Hendra',
      'Middle Ridge',
      'Crace',
      'Kirwan',
      'McKail',
      'Valley View',
      'Beldon',
      'St James',
      'Floreat',
      'Braybrook',
      'Hillbank',
      'Currimundi',
      'Jane Brook',
      'Rosebery',
      'Highgate',
      'Cairnlea',
      'California Gully',
      'Bundoora',
      'Hawker',
      'Parramatta Park',
      'Melville',
      'Munster',
      'Sadadeen',
      'Burton',
      'Weston',
      'Patterson Lakes',
      'Gilmore',
      'Inglewood',
      'Mountain Creek',
      'Wulagi',
      'Cranbrook',
      'Millbridge',
      'Marmion',
      'Orana',
      'Lammermoor',
      'Duffy',
      'Southern River',
      'Joslin',
      'Mansfield Park',
      'Taperoo',
      'Narrabundah',
      'Lakewood',
      'Skye',
      'St Peters',
      'Dudley Park',
      'Mirrabooka',
      'Hughes',
      'Mission Beach',
      'City of Cockburn',
      'Beaumont Hills',
      'Warabrook',
      'North Adelaide',
      'Victoria Park',
      'Milsons Point',
      'Forrest',
      'Whyalla Playford',
      'Whyalla Norrie',
      'Whyalla Stuart',
      'Whyalla Jenkins',
      'Aldinga Beach',
      'Cranbourne South',
      'Kambalda West',
      'North Richmond',
      'Windsor Downs',
      'Wyee Point',
      'Yallourn North',
      'Bondi Junction',
      'Bald Hills',
      'Jesmond',
      'Falls Creek',
      'Risdon Vale',
      'Mulambin',
      'Bluewater',
      'Kiama Downs',
      'Blacksmiths',
      'East Geelong',
      'Beecroft',
      'Williams Landing',
      'Bindoon',
      'Nickol',
      'North Willoughby',
      'Willoughby East',
      'Lane Cove North',
      'Meridan Plains',
      'Largs North',
      'Christies Beach',
      'Wadeye',
      'Carrolls Creek',
      'Seaford Meadows',
      'Seaford Rise',
      'South Kolan',
      'Boambee East',
      'Rose Park',
      'Nathan',
      'Chermside West',
      'Everton Park',
      'Gordon Park',
      'Stafford',
      'Stafford Heights',
      'Wilston',
      'Burbank',
      'Coopers Plains',
      'Forest Lake',
      'Greenslopes',
      'Mackenzie',
      'Mount Gravatt East',
      'Richlands',
      'Seventeen Mile Rocks',
      'Sunnybank Hills',
      'Upper Mount Gravatt',
      'Yeerongpilly',
      'Yeronga',
      'Balmoral',
      'Camp Hill',
      'Chandler',
      'Gumdale',
      'Manly West',
      'Morningside',
      'Wynnum West',
      'Kenmore Hills',
      'Pullenvale',
      'Riverhills',
      'East Ipswich',
      'Sadliers Crossing',
      'Springfield',
      'Tivoli',
      'Cedar Vale',
      'Logan Reserve',
      'North Maclean',
      'Park Ridge South',
      'Rochedale South',
      'South Maclean',
      'Waterford West',
      'Yarrabilba',
      'Russell Island',
      'Thorneside',
      'Arana Hills',
      'Caboolture South',
      'Eatons Hill',
      'Ferny Hills',
      'Delaneys Creek',
      'Samford Valley',
      'Flemington',
      'Heidelberg Heights',
      'Watsonia North',
      'Westmeadows',
      'Essendon North',
      'Essendon West',
      'Keilor East',
      'Coburg North',
      'Gowanbrae',
      'Hadfield',
      'Oak Park',
      'Pascoe Vale South',
      'North Warrandyte',
      'Bayswater North',
      'Croydon Hills',
      'Croydon South',
      'Kilsyth South',
      'Warranwood',
      'Vermont South',
      'Cranbourne East',
      'Cranbourne North',
      'Cranbourne West',
      'Narre Warren South',
      'Dandenong North',
      'Noble Park North',
      'Frankston North',
      'Frankston South',
      'Sandhurst',
      'Langwarrin South',
      'Rosebud West',
      'Keilor Downs',
      'Keilor Lodge',
      'Keilor Park',
      'Sunshine North',
      'Sunshine West',
      'Altona Meadows',
      'Williamstown North',
      'Kingsville',
      'Seddon',
      'West Footscray',
      'Burnside Heights',
      'Taylors Hill',
      'Ravenhall',
      'Eynesbury',
      'Allambie Heights',
      'Balmain East',
      'Banksia',
      'Bardwell Valley',
      'Belfield',
      'Berala',
      'Berowra Heights',
      'Beverley Park',
      'Bexley North',
      'Bilgola Plateau',
      'Blackett',
      'Bondi Beach',
      'Cabramatta West',
      'Cambridge Park',
      'Camden South',
      'Caringbah South',
      'Carnes Hill',
      'Castle Cove',
      'Chatswood West',
      'Clemton Park',
      'Colebee',
      'Collaroy Plateau',
      'Concord West',
      'Constitution Hill',
      'Cremorne Point',
      'Denham Court',
      'Denistone',
      'Dolls Point',
      'Dundas Valley',
      'East Lindfield',
      'East Ryde',
      'Edmondson Park',
      'Emu Heights',
      'Ermington',
      'Fairfield East',
      'Fairfield Heights',
      'Fairlight',
      'Glen Alpine',
      'Gregory Hills',
      'Guildford West',
      'Harris Park',
      'Hassall Grove',
      'Homebush West',
      'Hornsby Heights',
      'Horsley Park',
      'Hurstville',
      'Kellyville Ridge',
      'Kemps Creek',
      'Killara',
      'Killarney Heights',
      'Kingswood Park',
      'Renown Park',
      'Kogarah Bay',
      'Brompton',
      'Kurraba Point',
      'Kyle Bay',
      'Koomooloo',
      'Lane Cove West',
      'Lewisham',
      'Macquarie Links',
      'Marsfield',
      'Merrylands West',
      'Middleton Grange',
      'Miller',
      'Mount Lewis',
      'Narellan Vale',
      'Narraweena',
      'North Balgowlah',
      'North Bondi',
      'North Curl Curl',
      'North Epping',
      'North Manly',
      'North Strathfield',
      'North Wahroonga',
      'Old Toongabbie',
      'Oran Park',
      'Orchard Hills',
      'Padstow Heights',
      'Parklea',
      'Peakhurst Heights',
      'Picnic Point',
      'Ramsgate Beach',
      'Revesby Heights',
      'Rodd Point',
      'Roseville Chase',
      'South Coogee',
      'South Penrith',
      'South Turramurra',
      'South Wentworthville',
      'South Windsor',
      'Spring Farm',
      'Stanhope Gardens',
      'Strathfield South',
      'Sydney Olympic Park',
      'Telopea',
      'Wareemba',
      'Werrington County',
      'Werrington Downs',
      'West Pennant Hills',
      'West Ryde',
      'Wheeler Heights',
      'Wollstonecraft',
      'Woronora Heights',
      'Muirhead',
      'Karama',
      'Bellamack',
      'Erindale',
      'Glenunga',
      'Hazelwood Park',
      'Kensington Gardens',
      'Leabrook',
      'Linden Park',
      'Tusmore',
      'Wattle Park',
      'Hectorville',
      'Allenby Gardens',
      'Henley Beach',
      'Royal Park',
      'Semaphore Park',
      'West Hindmarsh',
      'West Lakes Shore',
      'Woodville North',
      'Woodville Park',
      'Woodville South',
      'Woodville West',
      'Evanston Gardens',
      'Evanston Park',
      'Gawler East',
      'Gawler South',
      'Glenelg East',
      'Glenelg North',
      'Seacliff Park',
      'Somerton Park',
      'South Brighton',
      'Clovelly Park',
      'Hallett Cove',
      'Oaklands Park',
      'Plympton Park',
      'Seacombe Heights',
      'South Plympton',
      'Trott Park',
      'Clarence Gardens',
      'Crafers West',
      'Lower Mitcham',
      'Marden',
      'Payneham',
      'Royston Park',
      'Trinity Gardens',
      'Aberfoyle Park',
      'Hackham West',
      'Noarlunga Downs',
      'Old Reynella',
      'Port Noarlunga South',
      'Reynella East',
      'Elizabeth Downs',
      'Elizabeth East',
      'Elizabeth Grove',
      'Elizabeth North',
      'Elizabeth Park',
      'Elizabeth South',
      'Elizabeth Vale',
      'Munno Para',
      'Smithfield Plains',
      'Alberton',
      'Angle Park',
      'Ethelton',
      'Ferryden Park',
      'Semaphore South',
      'Walkley Heights',
      'Windsor Gardens',
      'Woodville Gardens',
      'Sefton Park',
      'Rosenthal Heights',
      'Para Hills West',
      'Para Vista',
      'Salisbury Downs',
      'Salisbury Heights',
      'Salisbury North',
      'Salisbury Park',
      'Salisbury Plain',
      'Modbury Heights',
      'Modbury North',
      'Clarence Park',
      'Everard Park',
      'Millswood',
      'Unley Park',
      'Brooklyn Park',
      'Camden Park',
      'Marleston',
      'North Plympton',
      'Novar Gardens',
      'Torrensville',
      'Acton',
      'Bonner',
      'Coombs',
      'Wright',
      'Garran',
      'Alfred Cove',
      'Alkimos',
      'Bedford',
      'Brabham',
      'City Beach',
      'Coolbinia',
      'Crawley',
      'Daglish',
      'Dayton',
      'East Cannington',
      'Eden Hill',
      'Embleton',
      'Ferndale',
      'Harrisdale',
      'Henley Brook',
      'Hilbert',
      'Hilton',
      'Joondanna',
      'Kallaroo',
      'Midvale',
      'Myaree',
      'Oakford',
      'Parmelia',
      'Rivervale',
      'South Fremantle',
      'South Guildford',
      'South Lake',
      'Watermans Bay',
      'Wembley Downs',
      'West Leederville',
      'Woodbridge',
      'Acton Park',
      'West Moonah',
      'Dynnyrne',
      'Mount Stuart',
      'North Hobart',
      'South Hobart',
      'West Hobart',
      'East Bunbury',
      'Broadbeach Waters',
      'Burleigh Waters',
      'Clear Island Waters',
      'Currumbin Waters',
      'Lower Beechmont',
      'Mermaid Waters',
      'Mount Nathan',
      'Ormeau Hills',
      'Tallebudgera Valley',
      'East Mackay',
      'Mackay City',
      'South Mackay',
      'West Mackay',
      'South Yunderup',
      'Arcadia vale',
      'Buff Point',
      'Charmhaven',
      'Chittaway Bay',
      'Noraville',
      'Tuggerawong',
      'Bar Beach',
      'Broadmeadow',
      'Carrington',
      'Cooks Hill',
      'Elermore Vale',
      'The Junction',
      'Mayfield West',
      'Merewether Heights',
      'New Lambton Heights',
      'Newcastle East',
      'North Lambton',
      'Rankin Park',
      'Waratah',
      'Belmont North',
      'Belmont South',
      'Bolton Point',
      'Cardiff Heights',
      'Cardiff South',
      'Coal Point',
      'Fennell Bay',
      'Floraville',
      'Garden Suburb',
      'Gateshead',
      'Glendale',
      'Jewells',
      'Tingira Heights',
      'Windale',
      'Toronto',
      'Ashtonfield',
      'Bolwarra Heights',
      'West Rockhampton',
      'Black Mountain',
      'Caloundra West',
      'Dicky Beach',
      'Diddillibah',
      'Forest Glen',
      'Moffat Beach',
      'Peregian Springs',
      'West Woombye',
      'Mount Louisa',
      'South Townsville',
      'Stuart',
      'Black River',
      'Albion Park Rail',
      'Avondale',
      'Barrack Heights',
      'Cordeaux Heights',
      'East Corrimal',
      'Koonawarra',
      'West Wollongong',
      'North Wollongong',
      'Mount Warrigal',
      'Lake Wendouree',
      'White Hills',
      'Nulsen',
      'Armstrong Creek',
      'Hamlyn Heights',
      'North Geelong',
      'Wandana Heights',
      'Whittington',
      'Chatsworth',
      'Monkland',
      'East Launceston',
      'South Launceston',
      'Summerhill',
      'West Launceston',
      'Bilambil Heights',
      'Tweed Heads West',
      'Rangeville',
      'Darling Heights',
      'East Toowoomba',
      'Hodgson Vale',
      'North Toowoomba',
      'South Toowoomba',
      'Wilsonton Heights',
      'East Tamworth',
      'North Tamworth',
      'South Tamworth',
      'Cooroibah',
      'East Albury',
      'North Albury',
      'South Albury',
      'West Albury',
      'East Innisfail',
      'Garden Island',
      'Sunshine Coast',
      'Roebuck',
      'East Carnarvon',
      'Marangaroo',
      'Innisfail Estate',
      'Katherine South',
      'Katherine East',
      'Lismore Heights',
      'South Lismore',
      'Melbourne City Centre',
      'Brisbane central business district',
      'Perth city centre',
      'Adelaide city centre',
      'Newcastle city centre',
      'Hobart city centre',
      'Maitland city centre',
      'Geelong city centre',
      'Geraldton city centre',
      'Launceston city centre',
      'Bathurst city centre',
      'Bendigo city centre',
      'Wollongong city centre',
      'Mandurah city centre',
      'Yeppoon city centre',
      'Rockingham city centre',
      'Coomera',
      'West Wodonga',
      'Kalamunda',
      'Currumbin',
      'Strathmore',
      'West Busselton',
      'Kalinga',
      'Bardia',
      'Cairns City',
      'Charters Towers City',
      'South Bunbury',
      'Tweed Heads South',
      'Cairns North',
      'Burpengary East',
      'Pottsville',
      'Queanbeyan East',
      'Manly Vale',
      'Killarney Vale',
      'Gladstone Central',
      'West Gladstone',
      'Bundaberg North',
      'Jordan Springs',
      'North Nowra',
      'Culburra Beach',
      'East Devonport',
      'West Kempsey',
      'North Bendigo',
      'South Kalgoorlie',
      'South Gladstone',
      'North Gosford',
      'Singleton Heights',
      'Ballarat North',
      'West Ulverstone',
      'Bundaberg South',
      'Port Pirie West',
      'East Bendigo',
      'Mount Claremont',
      'West Ballina',
      'Queanbeyan West',
      'Svensson Heights',
      'Bundaberg West',
      'Bundaberg East',
      'Moore Park Beach',
      'North Coogee',
      'West Bathurst',
      'South Nowra',
      'Sapphire Beach',
      'Bennett Springs',
      'The Hill',
      'Glenelg South',
      'South Kempsey',
      'Midway Point',
      'North Boambee Valley',
      'Twin Waters',
      'Trinity Park',
      'Korora',
      'New Gisborne',
      'Risdon Park South',
      'Upper Burnie',
      'St Helena',
      'Mollymook',
      'Townview',
      'South Murwillumbah',
      'Elizabeth Hills',
      'South Bathurst',
      'Bungarribee',
      'Meringandan West',
      'Healy',
      'Romaine',
      'Corindi Beach',
      'East Branxton',
      'Barney Point',
      'Zuccoli',
      'Desert Springs',
      'East Bairnsdale',
      'East Kempsey',
      'Holroyd',
      'West Lamington',
      'Hazelwood North',
      'Manningham',
      'Skennars Head',
      'West Nowra',
      'West Haven',
      'South Carnarvon',
      'Trunding',
      'East Jindabyne',
      'Pie Creek',
      'Nowra Hill',
      'Caddens',
      'Shepparton East',
      'Kambalda East',
      'Wattle Ponds',
      'Kotara South',
      'Nanum',
      'Mount Kembla',
      'Moorak',
      'Bowenfels',
      'Sunshine Beach',
      'Port Pirie South',
      'Enmore',
      'South Granville',
      'Holland Park',
      'Ocean Grove',
      'Albany city centre',
      'Busselton city cenre',
      'Rossmore',
    ],
  },
  {
    country: 'Aruba',
    cities: [
      'Tanki Leendert',
      'San Nicolas',
      'Santa Cruz',
      'Savaneta',
      'Paradera',
      'Oranjestad',
    ],
  },
  {
    country: 'Azerbaijan',
    cities: [
      'Sharur City',
      'Geytepe',
      'Zangilan',
      'Yaycı',
      'Yardımlı',
      'Novyy Karanlug',
      'Xankandi',
      'Əhmədbəyli',
      'Şuşa',
      'Salyan',
      'Şahbuz',
      'Sedarak',
      'Sumbatan-diza',
      'Saatlı',
      'Qubadlı',
      'Qaraçala',
      'Orjonikidze',
      'Ordubad',
      'Neftçala',
      'Nakhchivan',
      'Mincivan',
      'Masally',
      'Lerik',
      'Lankaran',
      'Laçın',
      'Qıvraq',
      'Qırmızı Bazar',
      'Kizhaba',
      'Xocalı',
      'Xıllı',
      'Qalaqayın',
      'Imishli',
      'Horadiz',
      'Haftoni',
      'Hadrut',
      'Fizuli',
      'Jebrail',
      'Dünyamalılar',
      'Deste',
      'Culfa',
      'Jalilabad',
      'Cahri',
      'Boradigah',
      'Pushkino',
      'Beylagan',
      'Severo-Vostotchnyi Bank',
      'Tazakend',
      'Astara',
      'Askyaran',
      'Şirvan',
      'Ağdam',
      'Sovetabad',
      'Avşar',
      'Birinci Aşıqlı',
      'Yuxarı Aran',
      'Çalxanqala',
      'Oğlanqala',
      'Zyrya',
      'Zardob',
      'Zaqatala',
      'Zabrat',
      'Yevlakh',
      'Yanıqlı',
      'Xudat',
      'Göygöl',
      'Xaçmaz',
      'Verkhniy Dashkesan',
      'Vank',
      'Ujar',
      'Türkan',
      'Tovuz',
      'Terter',
      'Sumqayıt',
      'Kyzyl-Burun',
      'Şuraabad',
      'Qaraçuxur',
      'Saray',
      'Sanqaçal',
      'Samur',
      'Shamkhor',
      'Shamakhi',
      'Saloğlu',
      'Sheki',
      'Samux',
      'Sabunçu',
      'Sabirabad',
      'Qusar',
      'Quba',
      'Qobustan',
      'Hacıqabul',
      'Qazax',
      'Qax',
      'Qasım İsmayılov',
      'Qutqashen',
      'Puta',
      'Oğuz',
      'Novosaratovka',
      'Hacı Zeynalabdin',
      'Nardaran',
      'Naftalan',
      'Mingelchaur',
      'Maştağa',
      'Mardakan',
      'Mamrux',
      'Lokbatan',
      'Qızılhacılı',
      'Kyurdarmir',
      'Qobu',
      'Kilyazi',
      'Xızı',
      'Khirdalan',
      'Khojasan',
      'Korgöz',
      'Qarayeri',
      'Qaramanlı',
      'Qaraxanlı',
      'Qandax',
      'Vurğun',
      'Kerbakhiar',
      'Qala',
      'Qax İngiloy',
      'Qaxbaş',
      'İsmayıllı',
      'Yeni Suraxanı',
      'Gyuzdek',
      'Göyçay',
      'Hövsan',
      'Goranboy',
      'Gilgilçay',
      'Ganja',
      'Kyadabek',
      'Faldarlı',
      'Corat',
      'Ceyranbatan',
      'Dzagam',
      'Dondar Quşçu',
      'Digah',
      'Divichibazar',
      'Yukhary-Dashkesan',
      'Dolyar',
      'Çobansığnaq',
      'Çinarlı',
      'Çatax',
      'Buzovna',
      'Binagadi',
      'Biny Selo',
      'Bilajer',
      'Basqal',
      'Baş Göynük',
      'Barda',
      'Belokany',
      'Balakhani',
      'Bilajari',
      'Baku',
      'Arıqıran',
      'Arıqdam',
      'Pirallahı',
      'Amirdzhan',
      'Altıağac',
      'Aliabad',
      'Aghsu',
      'Aghstafa',
      'Ağdaş',
      'Martakert',
      'Agdzhabedy',
      'Böyük Qaramurad',
      'Mughan',
      'Qabaqçöl',
      'Bakıxanov',
      'Qırmızı Samux',
      'Hacıhüseynli',
      'Aran',
      'Samuxlu',
      'Ramana',
      'Badamdar',
      'Heydarabad',
    ],
  },
  {
    country: 'Bosnia and Herzegovina',
    cities: [
      'Zvornik',
      'Živinice',
      'Žepče',
      'Zenica',
      'Željezno Polje',
      'Zborište',
      'Zavidovići',
      'Zabrišće',
      'Vukovije Donje',
      'Vrnograč',
      'Vozuća',
      'Voljevac',
      'Vogošća',
      'Vlasenica',
      'Vitina',
      'Vitez',
      'Visoko',
      'Višegrad',
      'Vidoši',
      'Velika Obarska',
      'Velika Kladuša',
      'Velagići',
      'Varoška Rijeka',
      'Vareš',
      'Ustikolina',
      'Ugljevik',
      'Tuzla',
      'Turbe',
      'Tržačka Raštela',
      'Trnovo',
      'Trn',
      'Trebinje',
      'Travnik',
      'Tojšići',
      'Teslić',
      'Tešanj',
      'Tasovčići',
      'Svodna',
      'Šumatac',
      'Suho Polje',
      'Šturlić',
      'Stupari',
      'Stolac',
      'Stjepan-Polje',
      'Stijena',
      'Srebrenik',
      'Srebrenica',
      'Srbac',
      'Solina',
      'Sokolac',
      'Sladna',
      'Kneževo',
      'Šipovo',
      'Šerići',
      'Šekovići',
      'Sarajevo',
      'Sapna',
      'Sanski Most',
      'Sanica',
      'Rumboci',
      'Rudo',
      'Rogatica',
      'Rodoč',
      'Ravno',
      'Radišići',
      'Puračić',
      'Novi Travnik',
      'Prozor',
      'Prnjavor',
      'Prijedor',
      'Priboj',
      'Pribinić',
      'Prača',
      'Potoci',
      'Posušje',
      'Popovi',
      'Polje-Bijela',
      'Podzvizd',
      'Podhum',
      'Petkovci',
      'Pelagićevo',
      'Pećigrad',
      'Peći',
      'Pazarić',
      'Pale',
      'Otoka',
      'Ostrožac',
      'Oštra Luka',
      'Osmaci',
      'Orguz',
      'Orašje',
      'Orašac',
      'Orahovica Donja',
      'Omarska',
      'Omanjska',
      'Olovo',
      'Odžak',
      'Obudovac',
      'Novo Selo',
      'Novi Šeher',
      'Nevesinje',
      'Neum',
      'Mrkonjić Grad',
      'Mramor',
      'Mostar',
      'Mionica',
      'Milići',
      'Međugorje',
      'Matuzići',
      'Maslovare',
      'Marićka',
      'Mala Kladuša',
      'Mahala',
      'Maglajani',
      'Maglaj',
      'Lukavica',
      'Lukavac',
      'Lopare',
      'Lokvine',
      'Ljubuški',
      'Ljubinje',
      'Livno',
      'Široki Brijeg',
      'Laktaši',
      'Kupres',
      'Krupa na Vrbasu',
      'Teočak',
      'Kreševo',
      'Kotor Varoš',
      'Kopači',
      'Kočerin',
      'Kobilja Glava',
      'Klokotnica',
      'Ključ',
      'Kladanj',
      'Kiseljak',
      'Karadaglije',
      'Kalinovik',
      'Kalesija',
      'Kalenderovci Donji',
      'Kakanj',
      'Jezerski',
      'Jezero',
      'Janja',
      'Jajce',
      'Izačić',
      'Ilijaš',
      'Ilidža',
      'Hreša',
      'Han Pijesak',
      'Hadžići',
      'Grude',
      'Gromiljak',
      'Gradačac',
      'Gračanica',
      'Gostovići',
      'Gornji Vakuf',
      'Gornji Ribnik',
      'Gornje Živinice',
      'Gornje Moštre',
      'Gornja Tuzla',
      'Gornja Koprivna',
      'Gorica',
      'Goražde',
      'Gnojnica',
      'Glamoč',
      'Gacko',
      'Fojnica',
      'Foča',
      'Tomislavgrad',
      'Dubravica',
      'Dubrave Gornje',
      'Dubrave Donje',
      'Drvar',
      'Drinovci',
      'Drinić',
      'Donji Žabar',
      'Donji Vakuf',
      'Donji Dubovik',
      'Donja Međiđa',
      'Donja Mahala',
      'Donja Dubica',
      'Domaljevac',
      'Dobrljin',
      'Dobrinje',
      'Doboj',
      'Divičani',
      'Derventa',
      'Čitluk',
      'Cim',
      'Čelinac',
      'Čelić',
      'Čečava',
      'Cazin',
      'Čapljina',
      'Čajniče',
      'Bužim',
      'Busovača',
      'Bugojno',
      'Bronzani Majdan',
      'Brka',
      'Breza',
      'Brčko',
      'Bratunac',
      'Petrovo',
      'Bosansko Grahovo',
      'Šamac',
      'Bosanski Petrovac',
      'Novi Grad',
      'Brod',
      'Bosanska Krupa',
      'Kostajnica',
      'Gradiška',
      'Kozarska Dubica',
      'Blatnica',
      'Blagaj',
      'Bileća',
      'Bila',
      'Bijeljina',
      'Bihać',
      'Berkovići',
      'Banovići',
      'Banja Luka',
      'Balatun',
      'Koran',
      'Jelah',
      'Stanari',
      'Dobratići',
      'Podbrdo',
      'Malešići',
      'Svojat',
      'Dvorovi',
      'Knežica',
      'Pjanići',
      'Polje',
      'Ilići',
      'Careva Ćuprija',
      'Starcevica',
      'Hiseti',
      'Piskavica',
      'Lamovita',
      'Tešanjka',
      'Kovači',
      'Liješnica',
      'Ćoralići',
      'Crnići',
      'Barice',
      'Hotonj',
      'Skokovi',
      'Drežnica',
      'Kačuni',
      'Pajić Polje',
      'Gornja Breza',
      'Todorovo',
      'Konjic',
      'Jablanica',
      'Bijela',
      'Mejdan - Obilićevo',
    ],
  },
  {
    country: 'Barbados',
    cities: [
      'Welchman Hall',
      'Crane',
      'Speightstown',
      'Oistins',
      'Holetown',
      'Greenland',
      'Four Cross Roads',
      'Checker Hall',
      'Bridgetown',
      'Bathsheba',
    ],
  },
  {
    country: 'Bangladesh',
    cities: [
      'Thākurgaon',
      'Teknāf',
      'Tungi',
      'Sylhet',
      'Dohār',
      'Jamālpur',
      'Shibganj',
      'Sātkhira',
      'Sirajganj',
      'Netrakona',
      'Narsingdi',
      'Sandwīp',
      'Shāhzādpur',
      'Rāmganj',
      'Rājshāhi',
      'Purbadhala',
      'Pirojpur',
      'Panchagarh',
      'Patiya',
      'Parbatipur',
      'Nārāyanganj',
      'Nālchiti',
      'Nāgarpur',
      'Nageswari',
      'Mymensingh',
      'Muktāgācha',
      'Mirzāpur',
      'Maulavi Bāzār',
      'Morrelgonj',
      'Mehendiganj',
      'Mathba',
      'Lalmonirhat',
      'Lākshām',
      'Comilla',
      'Rangpur',
      'Kushtia',
      'Kālīganj',
      'Jhingergācha',
      'Joypur Hāt',
      'Ishurdi',
      'Habiganj',
      'Gaurnadi',
      'Gafargaon',
      'Feni',
      'Rāipur',
      'Sarankhola',
      'Dhaka',
      'Chilmāri',
      'Chhāgalnāiya',
      'Lālmohan',
      'Khagrachhari',
      'Chhātak',
      'Bhātpāra Abhaynagar',
      'Bherāmāra',
      'Bhairab Bāzār',
      'Bāndarban',
      'Kālia',
      'Baniachang',
      'Bājitpur',
      'Bagerhat',
      'Badarganj',
      'Narail',
      'Tungipāra',
      'Sonārgaon',
      'Sarishābāri',
      'Sakhipur',
      'Raojān',
      'Phultala',
      'Pālang',
      'Pār Naogaon',
      'Nabīnagar',
      'Ramnagar',
      'Lakshmīpur',
      'Kesabpur',
      'Jahedpur',
      'Hājīganj',
      'Farīdpur',
      'Dinājpur',
      'Uttar Char Fasson',
      'Chattogram',
      'Char Bhadrāsan',
      'Bera',
      'Burhānuddin',
      'Sātkania',
      'Cox’s Bāzār',
      'Khulna',
      'Bhola',
      'Barishal',
      'Jessore',
      'Pābna',
      'Tāngāil',
      'Bogra',
      'Pīrgaaj',
      'Nawābganj',
      'Madaripur',
      'Sherpur',
      'Kishorganj',
      'Manikchari',
      'Bhāndāria',
      'Bibir Hat',
      'Saidpur',
      'Puthia',
      'Natore',
      'Ujalpur',
      'Nowlamary',
      'Nilphamari',
      'Magura',
      'Azimpur',
      'Gaibandha',
      'Paotana Hat',
      'Mahasthangarh',
      'Paltan',
      'Parvez Ali Hossain',
      'Khanbaniara',
      'Char Golora',
      'Parvez Ali',
      'Joymontop',
      'Sayani',
      'Kashimnagar',
      'Swarpur',
    ],
  },
  {
    country: 'Belgium',
    cities: [
      'Zwijndrecht',
      'Zwijnaarde',
      'Zwevezele',
      'Zwevegem',
      'Zutendaal',
      'Zulte',
      'Zuienkerke',
      'Zoutleeuw',
      'Zottegem',
      'Zonnebeke',
      'Zonhoven',
      'Zomergem',
      'Zolder',
      'Zoersel',
      'Zoerle-Parwijs',
      'Zingem',
      'Zillebeke',
      'Zichen-Zussen-Bolder',
      'Zichem',
      'Zevergem',
      'Zeveneken',
      'Zétrud-Lumay',
      'Zerkegem',
      'Zepperen',
      'Zemst',
      'Zelzate',
      'Zellik',
      'Zelem',
      'Zele',
      'Zeebrugge',
      'Zedelgem',
      'Zaventem',
      'Zarren',
      'Zarlardinge',
      'Zandvoorde',
      'Zandhoven',
      'Zandbergen',
      'Zaffelare',
      'Yvoir',
      'Yves-Gomezée',
      'Xhoris',
      'Xhendremael',
      'Xhendelesse',
      'Wuustwezel',
      'Woumen',
      'Wortel',
      'Wortegem',
      'Wondelgem',
      'Wonck',
      'Wommersom',
      'Wommelgem',
      'Wolvertem',
      'Woluwe-Saint-Lambert',
      'Wolkrange',
      'Woesten',
      'Wodecq',
      'Winksele',
      'Wingene',
      'Winenne',
      'Wilsele',
      'Wilrijk',
      'Willemeau',
      'Willebroek',
      'Wilderen',
      'Wijtschate',
      'Wijshagen',
      'Wijnegem',
      'Wijer',
      'Wijchmaal',
      'Wihogne',
      'Wihéries',
      'Wieze',
      'Wiers',
      'Wierde',
      'Wielsbeke',
      'Wiekevorst',
      'Wichelen',
      'Wezembeek-Oppem',
      'Wezemaal',
      'Wevelgem',
      'Wetteren',
      'Westvleteren',
      'Westrozebeke',
      'Westouter',
      'Westmeerbeek',
      'Westmalle',
      'Westkerke',
      'Westkapelle',
      'Westerlo',
      'Westende',
      'Wespelaar',
      'Wervik',
      'Werchter',
      'Wépion',
      'Wenduine',
      'Wemmel',
      'Wellin',
      'Wellen',
      'Welle',
      'Welkenraedt',
      'Welden',
      'Wegnez',
      'Weerde',
      'Weelde',
      'Wechelderzande',
      'Webbekom',
      'Ways',
      'Wavreille',
      'Wavre',
      'Wauthier-Braine',
      'Waudrez',
      'Watou',
      'Watervliet',
      'Watermael-Boitsfort',
      'Waterloo',
      'Wasseiges',
      'Wasmuel',
      'Wasmes',
      'Warsage',
      'Warneton',
      "Waret-l'Évêque",
      'Waret-la-Chaussée',
      'Waremme',
      'Waregem',
      'Wardin',
      'Warcoing',
      'Warchin',
      'Wanzele',
      'Wanze',
      'Wangenies',
      'Wanfercée-Baulet',
      'Wandre',
      'Wambeek',
      'Waltwilder',
      "Houtain-l'Évêque",
      'Walsbets',
      'Walhorn',
      'Walhain-Saint-Paul',
      'Walem',
      'Walcourt',
      'Wakken',
      'Waimes',
      'Waha',
      'Wachtebeke',
      'Waasmunster',
      'Waarschoot',
      'Waarloos',
      'Waardamme',
      'Waanrode',
      'Vurste',
      'Vucht',
      'Vroenhoven',
      'Vreren',
      'Vremde',
      'Vrasene',
      'Vottem',
      'Vossem',
      'Vosselare',
      'Vosselaar',
      'Vorst',
      'Vorselaar',
      'Voroux-lez-Liers',
      'Voorde',
      'Vollezele',
      'Vlissegem',
      'Vlimmeren',
      'Vlijtingen',
      'Vlierzele',
      'Vliermaalroot',
      'Vliermaal',
      'Vlezenbeek',
      'Vlamertinge',
      'Vladslo',
      'Vivegnis',
      'Vitrival',
      'Vissenaken',
      'Visé',
      'Virton',
      'Virginal-Samme',
      'Vinkt',
      'Vinderhoute',
      'Vinalmont',
      'Vilvoorde',
      'Ville-sur-Haine',
      'Villers-Poterie',
      'Villers-Perwin',
      "Villers-l'Évêque",
      'Villers-le-Temple',
      'Villers-le-Bouillet',
      'Villers-la-Ville',
      'Vieux-Genappe',
      'Viesville',
      'Vierset-Barse',
      'Viersel',
      'Vielsalm',
      'Vichte',
      'Viane',
      'Vezon',
      'Vezin',
      'Veurne',
      'Verviers',
      'Vertrijk',
      'Verrebroek',
      'Verlaine',
      'Velzeke-Ruddershove',
      'Veltem-Beisem',
      'Velm',
      'Vellereille-les-Brayeux',
      'Veldwezelt',
      'Veldegem',
      'Velaines',
      'Velaine',
      'Veerle',
      'Vedrin',
      'Vaux-sur-Sûre',
      'Vaux-sous-Chèvremont',
      'Vaux-et-Borset',
      'Vaulx-lez-Tournai',
      'Varsenare',
      'Vance',
      'Val-Meer',
      'Ursel',
      'Uitkerke',
      'Uitbergen',
      'Uikhoven',
      'Uccle',
      'Turnhout',
      'Tubize',
      'Trooz',
      'Trois-Ponts',
      'Trivières',
      'Tremelo',
      'Trembleur',
      'Trazegnies',
      'Tournai',
      'Tourinnes-Saint-Lambert',
      'Tourinnes-la-Grosse',
      'Torhout',
      'Tongrinne',
      'Tongerlo',
      'Tongeren',
      'Tollembeek',
      'Tohogne',
      'Toernich',
      'Tisselt',
      'Tintigny',
      'Tinlot',
      'Tilly',
      'Tilleur',
      'Tilff',
      'Tildonk',
      'Tihange',
      'Tienen',
      'Tielt',
      'Tielrode',
      'Tielen',
      'Tiegem',
      'Thy-le-Château',
      'Thulin',
      'Thuin',
      'Thuillies',
      'Thorembais-Saint-Trond',
      'Thommen',
      'Thisnes',
      'Thimister',
      'Thiméon',
      'Thieusies',
      'Thieu',
      'Thiaumont',
      'Theux',
      'Testelt',
      'Tessenderlo',
      'Tervuren',
      'Tertre',
      'Ternat',
      'Terhagen',
      'Teralfene',
      'Tenneville',
      'Temse',
      'Temploux',
      'Templeuve',
      'Tellin',
      'Tavier',
      'Tarcienne',
      'Tamines',
      'Taintignies',
      'Suarlée',
      'Strombeek-Bever',
      'Strijpen',
      'Strépy-Bracquegnies',
      'Strée-lez-Huy',
      'Strée',
      'Stoumont',
      'Stokrooie',
      'Stokkem',
      'Stevoort',
      'Sterrebeek',
      'Stene',
      'Stembert',
      'Stekene',
      'Steenokkerzeel',
      'Steenhuize-Wijnhuize',
      'Steenhuffel',
      'Steendorp',
      'Stavelot',
      'Stambruges',
      'Staden',
      'Stabroek',
      'Spy',
      'Sprimont',
      'Spiere',
      'Spalbeek',
      'Spa',
      'Soye',
      'Soy',
      'Souvret',
      'Soumagne',
      'Sougné-Remouchamps',
      'Somzée',
      'Somme-Leuze',
      'Sombreffe',
      'Solre-sur-Sambre',
      'Soignies',
      'Snellegem',
      'Snaaskerke',
      'Smetlede',
      'Slins',
      'Sleidinge',
      'Sivry',
      'Sirault',
      'Sint-Ulriks-Kapelle',
      'Sint-Truiden',
      'Sint-Stevens-Woluwe',
      'Sint-Pieters-Rode',
      'Sint-Pieters-Leeuw',
      'Sint-Pieters-Kapelle',
      'Sint-Pauwels',
      'Sint-Niklaas',
      'Sint-Michiels',
      'Sint-Martens-Lierde',
      'Sint-Martens-Lennik',
      'Sint-Martens-Leerne',
      'Sint-Martens-Latem',
      'Sint-Martens-Bodegem',
      'Sint-Martens',
      'Sint-Maria-Oudenhove',
      'Sint-Maria-Lierde',
      'Sint-Maria-Horebeke',
      'Sint-Lievens-Houtem',
      'Sint-Lievens-Esse',
      'Sint-Lenaarts',
      'Sint-Laureins',
      'Sint-Lambrechts-Herk',
      'Sint-Kwintens-Lennik',
      'Sint-Kruis',
      'Sint-Katherina-Lombeek',
      'Sint-Katelijne-Waver',
      'Sint-Joris-Winge',
      'Sint-Joris-Weert',
      'Sint-Joris',
      "Sint-Job-in-'t-Goor",
      'Sint-Jan-in-Eremo',
      'Sint-Jan',
      'Sint-Huibrechts-Lille',
      'Sint-Gillis-Waas',
      'Sint-Gillis-bij-Dendermonde',
      'Sint-Genesius-Rode',
      'Sint-Eloois-Winkel',
      'Sint-Eloois-Vijve',
      'Sint-Denijs-Westrem',
      'Sint-Denijs',
      'Sint-Baafs-Vijve',
      'Sint-Antonius',
      'Sint-Andries',
      'Sint-Amandsberg',
      'Sint-Amands',
      'Sint-Agatha-Rode',
      'Sinaai',
      'Silly',
      'Sijsele',
      'Sibret',
      "'s-Gravenwezel",
      'Serskamp',
      'Serinchamps',
      'Seraing',
      'Seneffe',
      'Semmerzake',
      'Seloignes',
      'Seilles',
      'Sclayn',
      'Schulen',
      'Schuiferskapelle',
      'Schriek',
      'Schoten',
      'Schorisse',
      'Schoonaarde',
      'Schilde',
      'Scherpenheuvel',
      'Schepdaal',
      'Schendelbeke',
      'Schellebelle',
      'Schelle',
      'Scheldewindeke',
      'Schelderode',
      'Schaltin',
      'Schaffen',
      'Schaerbeek',
      'Sauvenière',
      'Sart-Saint-Laurent',
      'Sart-Dames-Avelines',
      'Sart-Bernard',
      'Saive',
      'Saint-Yvon',
      'Saint-Vith',
      'Saint-Vaast',
      'Saint-Symphorien',
      'Saint-Servais',
      'Saint-Sauveur',
      'Saint-Remy',
      'Saint-Pierre',
      'Saint-Nicolas',
      'Saint-Mard',
      'Saint-Marc',
      'Saint-Léger',
      'Saint-Josse-ten-Noode',
      'Saint-Hubert',
      'Saint-Gilles',
      'Saint-Ghislain',
      'Saint-Gérard',
      'Saint-Georges-sur-Meuse',
      'Saintes',
      'Sainte-Ode',
      'Sainte-Marie-sur-Semois',
      'Saint-Denis',
      'Saint-Amand',
      'Rupelmonde',
      'Rumst',
      'Rummen',
      'Rumillies',
      'Rumes',
      'Rumbeke',
      'Rulles',
      'Ruiselede',
      'Ruisbroek',
      'Ruien',
      'Ruddervoorde',
      'Roux',
      'Rouvroy',
      'Rouvreux',
      'Rotselaar',
      'Rotheux-Rimière',
      'Rotem',
      'Rosières',
      'Roselies',
      'Roosbeek',
      'Ronse',
      'Ronquières',
      'Rongy',
      'Romsée',
      'Rollegem-Kapelle',
      'Rollegem',
      'Roksem',
      'Roisin',
      'Le Roeulx',
      'Roeselare',
      'Roesbrugge-Haringe',
      'Rocourt',
      'Roclenge-sur-Geer',
      'Rocherath',
      'Rochefort',
      'Robertville',
      'Rixensart',
      'Rillaar',
      'Rijmenam',
      'Rijkhoven',
      'Rijkevorsel',
      'Riemst',
      'Richelle',
      'Rhisnes',
      'Rèves',
      'Retinne',
      'Retie',
      'Ressegem',
      'Ressaix',
      'Reppel',
      'Reningelst',
      'Reninge',
      'Rendeux',
      'Remicourt',
      'Relegem',
      'Rekkem',
      'Rekem',
      'Reet',
      'Recogne',
      'Recht',
      'Rebecq-Rognon',
      'Ravels',
      'Ranst',
      'Ransart',
      'Rance',
      'Ramsel',
      'Ramsdonk',
      'Ramillies',
      'Raeren',
      'Racour',
      'Quiévrain',
      'Quévy-le-Petit',
      'Quevaucamps',
      'Queue-du-Bois',
      'Quenast',
      'Quaregnon',
      'Puurs',
      'Putte',
      'Pulle',
      'Pulderbos',
      'Proven',
      'Profondeville',
      'Presles',
      'Prayon',
      'Poulseur',
      'Pottes',
      'Poppel',
      'Poperinge',
      'Pont-de-Loup',
      'Pont-à-Celles',
      'Pondrôme',
      'Pommeroeul',
      'Polleur',
      'Pollare',
      'Poelkapelle',
      'Poederlee',
      'Plombières',
      'Ploegsteert',
      'Plancenoit',
      'Plainevaux',
      'Pittem',
      'Pironchamps',
      'Pipaix',
      'Piétrebais',
      'Piétrain',
      'Piéton',
      'Philippeville',
      'Peutie',
      'Petit-Rechain',
      'Lettelingen',
      'Petigny',
      'Petegem-aan-de-Leie',
      'Petegem',
      'Perwez',
      'Péruwelz',
      'Perre',
      'Péronnes',
      'Perk',
      'Pepinster',
      'Pellenberg',
      'Peer',
      'Pecq',
      'Pâturages',
      'Passendale',
      'Papignies',
      'Pamel',
      'Paliseul',
      'Paal',
      'Overpelt',
      'Overmere',
      'Overijse',
      'Overboelare',
      'Ouwegem',
      'Outrijve',
      'Outer',
      'Oupeye',
      'Ougrée',
      'Ouffet',
      'Oud-Turnhout',
      'Oud-Heverlee',
      'Oudenburg',
      'Oudenaarde',
      'Oudegem',
      'Ottignies',
      'Ottenburg',
      'Othée',
      'Otegem',
      'Orp-le-Grand',
      'Ormeignies',
      'Orgeo',
      'Oreye',
      'Orcq',
      'Orbais',
      'Opwijk',
      'Opvelp',
      'Oppuurs',
      'Opprebais',
      'Opoeteren',
      'Oplinter',
      'Opitter',
      'Ophoven',
      'Ophasselt',
      'Ophain-Bois-Seigneur-Isaac',
      'Opgrimbie',
      'Opglabbeek',
      'Opdorp',
      'Opbrakel',
      'Oostvleteren',
      'Oostrozebeke',
      'Oostnieuwkerke',
      'Oostmalle',
      'Oostkamp',
      'Oostham',
      'Oosterzele',
      'Ostend',
      'Oosteeklo',
      'Oostduinkerke',
      'Oostakker',
      'Oordegem',
      'Oorbeek',
      'Ooigem',
      'Onze-Lieve-Vrouw-Waver',
      'Onkerzele',
      'Onhaye',
      'On',
      'Olsene',
      'Olne',
      'Olmen',
      'Ollignies',
      'Oleye',
      'Olen',
      'Okegem',
      'Oisquercq',
      'Ohey',
      'Ohain',
      'Oevel',
      'Oetingen',
      'Oeselgem',
      'Oelegem',
      'Oekene',
      'Oedelem',
      'Odeur',
      'Ochamps',
      'Obourg',
      'Obaix',
      'Nukerke',
      'Noville-les-Bois',
      'Noville',
      'Nossegem',
      'Noorderwijk',
      'Noiseux',
      'Noduwez',
      'Nobressart',
      'Nivelles',
      'Nismes',
      'Ninove',
      'Nimy',
      'Nil-Saint-Vincent-Saint-Martin',
      'Nijlen',
      'Nieuwrode',
      'Nieuwpoort',
      'Nieuwkerken-Waas',
      'Nieuwkerke',
      'Nieuwerkerken',
      'Nieuwenrode',
      'Niel-bij-As',
      'Niel',
      'Nevele',
      'Neuville-en-Condroz',
      'Neuville',
      'Neu-Moresnet',
      'Neufvilles',
      'Neufchâteau',
      'Nethen',
      'Neerwinden',
      'Neerpelt',
      'Neeroeteren',
      'Neerlinter',
      'Neerijse',
      'Neerheylissem',
      'Neerharen',
      'Nederokkerzeel',
      'Nederhasselt',
      'Brakel',
      'Nederboelare',
      'Néchin',
      'Nazareth',
      'Natoye',
      'Nassogne',
      'Naninne',
      'Namur',
      'Namêche',
      'Nalinnes',
      'Naast',
      'Mussy-la-Ville',
      'Musson',
      'Munsterbilzen',
      'Munkzwalm',
      'Muizen',
      'Moustier-sur-Sambre',
      'Mouscron',
      'Mourcourt',
      'Mortsel',
      'Mortier',
      'Mornimont',
      'Morlanwelz-Mariemont',
      'Morkhoven',
      'Morialmé',
      'Moresnet',
      'Mopertingen',
      'Moortsele',
      'Moorslede',
      'Moorsele',
      'Moorsel',
      'Montzen',
      'Mont-sur-Marchienne',
      'Mont-Saint-Guibert',
      'Mont-Sainte-Aldegonde',
      'Montignies-sur-Sambre',
      'Montigny-le-Tilleul',
      'Montenaken',
      'Montegnée',
      'Mons-lez-Liège',
      'Mons',
      'Monceau-sur-Sambre',
      'Momignies',
      'Momalle',
      'Mollem',
      'Molenstede',
      'Molenbeersel',
      'Molenbeek-Wersbeek',
      'Molenbeek-Saint-Jean',
      'Mol',
      'Moignelée',
      'Moha',
      'Moerzeke',
      'Moerkerke',
      'Moere',
      'Moerbeke',
      'Moen',
      'Modave',
      'Minderhout',
      'Milmort',
      'Millen',
      'Mignault',
      'Mielen-boven-Aalst',
      'Middelkerke',
      'Micheroux',
      'Michelbeke',
      'Meyerode',
      'Meux',
      'Meulebeke',
      'Mettet',
      'Messelbroek',
      'Messancy',
      "Meslin-l'Évêque",
      'Merksplas',
      'Merksem',
      'Merkem',
      'Merendree',
      'Merelbeke',
      'Mere',
      'Merchtem',
      'Merbes-le-Château',
      'Menen',
      'Membach',
      'Melsen',
      'Melsele',
      'Melsbroek',
      'Mellet',
      'Melle',
      'Mélin',
      'Melen',
      'Meldert',
      'Meix-devant-Virton',
      'Meise',
      'Meeuwen',
      'Meerle',
      'Meerhout',
      'Meerdonk',
      'Meerbeke',
      'Meerbeek',
      'Meer',
      'Meensel-Kiezegem',
      'Mechelen-Bovelingen',
      'Mechelen-aan-de-Maas',
      'Mechelen',
      'Mazy',
      'Mazenzele',
      'Maurage',
      'Maubray',
      'Mater',
      'Massenhoven',
      'Massemen',
      'Masnuy-Saint-Jean',
      'Martelange',
      'Marquain',
      'Marke',
      'Mariembourg',
      'Mariekerke',
      'Mariakerke',
      'Maria Aalter',
      'Marenne',
      'Mark',
      'Marcinelle',
      'Marchin',
      'Marchienne-au-Pont',
      'Marche-lez-Écaussinnes',
      'Marche-les-Dames',
      'Marche-en-Famenne',
      'Marbais',
      'Manhay',
      'Manderfeld',
      'Manage',
      'Malonne',
      'Malmédy',
      'Malderen',
      'Maldegem',
      'Mal',
      'Maisières',
      'Mainvault',
      'Maillen',
      'Magnée',
      'Maffle',
      'Machelen-aan-de-Leie',
      'Machelen',
      'Maasmechelen',
      'Maaseik',
      'Luttre',
      'Lustin',
      'Lummen',
      'Luingne',
      'Lubbeek',
      'Loyers',
      'Loverval',
      'Lovenjoel',
      'Lovendegem',
      'Louveigné',
      'Louvain-la-Neuve',
      'Loupoigne',
      'Lotenhulle',
      'Lot',
      'Loppem',
      'Loonbeek',
      'Lontzen',
      'Longvilly',
      'Longlier',
      'Longchamps',
      'Londerzeel',
      'Loncin',
      'Lommersweiler',
      'Lommel',
      'Lombardsijde',
      'Lokeren',
      'Loenhout',
      'Lodelinsart',
      'Lochristi',
      'Lobbes',
      'Lo',
      'Lixhe',
      'Lissewege',
      'Lippelo',
      'Lint',
      'Linkhout',
      'Linkebeek',
      'Linden',
      'Lincent',
      'Limerlé',
      'Limelette',
      'Limbourg',
      'Limal',
      'Lillois-Witterzée',
      'Lille',
      'Ligny',
      'Liezele',
      'Liers',
      'Lier',
      'Lierneux',
      'Liège',
      'Liedekerke',
      'Lichtervelde',
      'Lichtaart',
      'Libramont',
      'Libin',
      'Liberchies',
      'Leval-Trahegnies',
      'Leuze',
      'Leuven',
      'Leut',
      'Letterhoutem',
      'Lesve',
      'Lessines',
      'Deux-Acren',
      'Le Roux',
      'Leopoldsburg',
      'Lens-sur-Geer',
      'Lens-Saint-Remy',
      'Lens',
      'Lendelede',
      'Lemberge',
      'Lembeke',
      'Lembeek',
      'Leke',
      'Leignon',
      'Léglise',
      'Leffinge',
      'Leeuwergem',
      'Leest',
      'Leers-Nord',
      'Leernes',
      'Leerbeek',
      'Leefdaal',
      'Ledegem',
      'Ledeberg',
      'Lede',
      'Lebbeke',
      'Lauwe',
      'Lauw',
      'Latour',
      'La Roche-en-Ardenne',
      'La Reid',
      'Laplaigne',
      'Lanklaar',
      'Langemark',
      'Langdorp',
      'Laneffe',
      'Landenne',
      'Landen',
      'Landelies',
      'Landegem',
      'Lanaken',
      'Lambusart',
      'Lambermont',
      'La Louvière',
      'La Hulpe',
      'La Hestre',
      'La Gleize',
      'La Calamine',
      'Labuissière',
      'La Bruyère',
      'La Bouverie',
      'Laarne',
      'Kwaadmechelen',
      'Kuurne',
      'Kuringen',
      'Kumtich',
      'Kruishoutem',
      'Kruibeke',
      'Kraainem',
      'Kozen',
      'Kortrijk-Dutsel',
      'Kortrijk',
      'Kortessem',
      'Kortenberg',
      'Kortenaken',
      'Kortemark',
      'Korbeek-Lo',
      'Koolskamp',
      'Koolkerke',
      'Kontich',
      'Koninksem',
      'Koningshooikt',
      'Koksijde',
      'Koersel',
      'Koekelberg',
      'Koekelare',
      'Knokke-Heist',
      'Knokke',
      'Knesselare',
      'Klerken',
      'Klemskerke',
      'Kleine-Spouwen',
      'Kleine-Brogel',
      'Kinrooi',
      'Kieldrecht',
      'Kettenis',
      'Kester',
      'Kessenich',
      'Kessel-Lo',
      'Kessel',
      'Kersbeek-Miskom',
      'Kermt',
      'Kerksken',
      'Kerkom',
      'Kerkhove',
      'Kemzeke',
      'Kemmel',
      'Keiem',
      'Keerbergen',
      'Kaulille',
      'Kasterlee',
      'Kaprijke',
      'Kapelle-op-den-Bos',
      'Kapellen',
      'Kanne',
      'Kanegem',
      'Kampenhout',
      'Kalmthout',
      'Kallo',
      'Kalken',
      'Kain',
      'Kaggevinne',
      'Kachtem',
      'Jurbise',
      'Juprelle',
      'Jupille-sur-Meuse',
      'Jumet',
      'Jodoigne',
      'Jeuk',
      'Jette',
      'Jemeppe-sur-Meuse',
      'Jemeppe-sur-Sambre',
      'Jemelle',
      'Jemappes',
      'Jehay',
      'Jauche',
      'Jandrain-Jandrenouille',
      'Jamoigne',
      'Jamioulx',
      'Jambes',
      'Jalhay',
      'Jabbeke',
      'Izel',
      'Izegem',
      'Ixelles',
      'Ivoz-Ramet',
      'Ittre',
      'Itterbeek',
      'Itegem',
      'Isières',
      'Irchonwelz',
      'Ingooigem',
      'Ingelmunster',
      'Incourt',
      'Impe',
      'Ieper',
      'Idegem',
      'Iddergem',
      'Ichtegem',
      'Hyon',
      'Huy',
      'Huppaye',
      'Humbeek',
      'Hulste',
      'Hulshout',
      'Huldenberg',
      'Huizingen',
      'Huissignies',
      'Huise',
      'Hoves',
      'Hove',
      'Houyet',
      'Houwaart',
      'Houtvenne',
      'Houthulst',
      'Houthem',
      'Houthalen',
      'Houtain-Saint-Siméon',
      'Housse',
      'Houffalize',
      'Houdeng-Goegnies',
      'Houdeng-Aimeries',
      'Hotton',
      'Horrues',
      'Hornu',
      'Horion-Hozémont',
      'Hoogstraten',
      'Hooglede',
      'Hombourg',
      'Hombeek',
      'Holsbeek',
      'Hollogne-aux-Pierres',
      'Hollain',
      'Hognoul',
      'Hofstade',
      'Hoevenen',
      'Hoeselt',
      'Hoepertingen',
      'Hoeleden',
      'Hoeilaart',
      'Hoegaarden',
      'Hodeige',
      'Hoboken',
      'Hingene',
      'Hillegem',
      'Hévillers',
      'Heverlee',
      'Hever',
      'Heusy',
      'Heusden',
      'Heure-le-Romain',
      'Heule',
      'Herzele',
      'Herve',
      'Hertsberge',
      'Herstal',
      'Herselt',
      'Herseaux',
      'Héron',
      'Herne',
      'Hermée',
      'Hermalle-sous-Huy',
      'Hermalle-sous-Argenteau',
      'Herk-de-Stad',
      'Hérinnes',
      'Hergenrath',
      'Herfelingen',
      'Herenthout',
      'Herentals',
      'Herent',
      'Herdersem',
      'Herderen',
      'Herchies',
      'Herbeumont',
      'Heppignies',
      'Heppenbach',
      'Heppen',
      'Hensies',
      'Henri-Chapelle',
      'Hennuyères',
      'Hemiksem',
      'Hellebecq',
      'Heldergem',
      'Helchteren',
      'Hekelgem',
      'Heist-op-den-Berg',
      'Heist',
      'Heinsch',
      'Heffen',
      'Heestert',
      'Heers',
      'Hechtel',
      'Havré',
      'Havinnes',
      'Havelange',
      'Hautrage',
      'Hauset',
      'Haulchin',
      'Hastière-Lavaux',
      'Hasselt',
      'Harzé',
      'Harelbeke',
      'Harchies',
      'Hanzinelle',
      'Han-sur-Lesse',
      'Hansbeke',
      'Hanret',
      'Hannut',
      'Haneffe',
      'Handzame',
      'Ham-sur-Sambre',
      'Ham-sur-Heure',
      'Hamont',
      'Hamois',
      'Hamoir',
      'Hamme-Mille',
      'Hamme',
      'Hamipré',
      'Haltinne',
      'Halle-Booienhoven',
      'Halle',
      'Hallaar',
      'Halen',
      'Halanzy',
      'Hakendover',
      'Haine-Saint-Pierre',
      'Haine-Saint-Paul',
      'Haillot',
      'Haccourt',
      'Habay-la-Vieille',
      'Habay-la-Neuve',
      'Haasrode',
      'Haasdonk',
      'Haaltert',
      'Haacht',
      'Gullegem',
      'Guigoven',
      'Gruitrode',
      'Grote-Spouwen',
      'Grotenberge',
      'Grote-Brogel',
      'Groot-Bijgaarden',
      'Grobbendonk',
      'Grivegnée',
      'Grimbergen',
      'Grez-Doiceau',
      'Grembergen',
      'Grand-Reng',
      'Grand-Rechain',
      'Grand-Leez',
      'Grandhan',
      'Grand-Halleux',
      'Grand-Hallet',
      'Grammene',
      'Grâce-Berleur',
      'Gozée',
      'Gouy-lez-Piéton',
      'Gouvy',
      'Goutroux',
      'Gougnies',
      'Gottignies',
      'Gosselies',
      'Gooik',
      'Goetsenhoven',
      'Goeferdinge',
      'Godveerdegem',
      'Godinne',
      'Godarville',
      'Glons',
      'Gleixhe',
      'Glain',
      'Glabbeek',
      'Givry',
      'Gits',
      'Gistel',
      'Gingelom',
      'Gilly',
      'Gijzegem',
      'Gierle',
      'Ghoy',
      'Ghlin',
      'Gesves',
      'Geraardsbergen',
      'Genval',
      'Gentbrugge',
      'Gent',
      'Genly',
      'Genk',
      'Genappe',
      'Gemmenich',
      'Gembloux',
      'Geluwe',
      'Geluveld',
      'Gelrode',
      'Gellik',
      'Gelinden',
      'Gelbressée',
      'Geetbets',
      'Geest-Gérompont-Petit-Rosière',
      'Geer',
      'Geel',
      'Gedinne',
      'Gavere',
      'Gaurain-Ramecroix',
      'Ganshoren',
      'Galmaarden',
      'Froyennes',
      'Froidchapelle',
      'Frasnes-lez-Gosselies',
      'Frasnes-lez-Buissenal',
      'Frasnes',
      'Franière',
      'Francorchamps',
      'Frameries',
      'Fraire',
      'Fraipont',
      'Sint-Pieters-Voeren',
      "'s-Gravenvoeren",
      'Fosses-la-Ville',
      'Fosse',
      'Forville',
      'Forrières',
      'Forges',
      'Forêt',
      'Forest',
      'Forchies-la-Marche',
      'Fontaine-Valmont',
      "Fontaine-l'Évêque",
      'Floriffoux',
      'Florenville',
      'Florennes',
      'Floreffe',
      'Flobecq',
      'Fleurus',
      'Fléron',
      'Flénu',
      'Flémalle-Haute',
      'Flémalle-Grande',
      'Flawinne',
      'Flamierge',
      'Fexhe-Slins',
      'Fexhe-le-Haut-Clocher',
      'Ferrières',
      'Feluy',
      'Fayt-lez-Manage',
      'Fauvillers',
      'Faulx-Les Tombes',
      'Farciennes',
      'Falisolle',
      'Faimes',
      'Eynatten',
      'Evrehailles',
      'Evregnies',
      'Evergem',
      'Evere',
      'Everberg',
      'Everbeek',
      'Eupen',
      'Eugies',
      'Etterbeek',
      'Ettelgem',
      'Etikhove',
      'Éthe',
      'Étalle',
      'Estinnes-au-Val',
      'Estinnes-au-Mont',
      'Estaimpuis',
      'Estaimbourg',
      'Essene',
      'Essen',
      'Esneux',
      'Esen',
      'Escanaffles',
      'Erwetegem',
      'Ertvelde',
      'Erquelinnes',
      'Erps-Kwerps',
      'Erpent',
      'Erpe',
      'Erondegem',
      'Ernage',
      'Érezée',
      'Erembodegem',
      'Erbisoeul',
      'Eppegem',
      'Épinois',
      'Ensival',
      'Engis',
      'Enghien',
      'Émines',
      'Embourg',
      'Emblem',
      'Elversele',
      'Elverdinge',
      'Elst',
      'Elsenborn',
      'Elsegem',
      'Élouges',
      'Ellikom',
      'Ellignies-Sainte-Anne',
      'Ellezelles',
      'Elewijt',
      'Elene',
      'Elen',
      'Eksel',
      'Eksaarde',
      'Ekeren',
      'Eke',
      'Eisden',
      'Eine',
      'Eindhout',
      'Eigenbilzen',
      'Éghezée',
      'Egem',
      'Eernegem',
      'Eeklo',
      'Edegem',
      'Écaussinnes-Lalaing',
      "Écaussinnes-d'Enghien",
      'Eben-Emael',
      'Dworp',
      'Durnal',
      'Durbuy',
      'Duisburg',
      'Duffel',
      'Dudzele',
      'Drongen',
      'Drogenbos',
      'Drieslinter',
      'Dour',
      'Dottignies',
      'Donk',
      'Donceel',
      'Dolembreux',
      'Doische',
      'Dison',
      'Dion-le-Mont',
      'Dinant',
      'Dilsen',
      'Dilbeek',
      'Diksmuide',
      'Dikkelvenne',
      'Dikkebus',
      'Diest',
      'Diepenbeek',
      'Diegem',
      'Dhuy',
      'Deurne',
      'Deurle',
      'Desteldonk',
      'Destelbergen',
      'Desselgem',
      'Dessel',
      'Dergneau',
      'De Pinte',
      'De Panne',
      'Dentergem',
      'Denderwindeke',
      'Dendermonde',
      'Denderleeuw',
      'Denderhoutem',
      'Denderbelle',
      'De Klinge',
      'Deinze',
      'De Haan',
      'Deftinge',
      'Deerlijk',
      'Daverdisse',
      'Dave',
      'Dampremy',
      'Damme',
      'Dalhem',
      'Dadizele',
      'Cul-des-Sarts',
      'Cuesmes',
      'Crombach',
      'Crisnée',
      'Couvin',
      'Couture-Saint-Germain',
      'Couthuin',
      'Court-Saint-Étienne',
      'Courrière',
      'Courcelles',
      'Couillet',
      'Cortil-Noirmont',
      'Corswarem',
      'Corroy-le-Grand',
      'Corroy-le-Château',
      'Corbais',
      'Comines',
      'Comblain-Fairon',
      'Comblain-au-Pont',
      'Colfontaine',
      'Clermont',
      'Clermont-sous-Huy',
      'Clavier',
      'Clabecq',
      'Ciplet',
      'Ciney',
      'Chiny',
      'Chimay',
      'Chièvres',
      'Cheratte',
      'Chênée',
      'Chaumont-Gistoux',
      'Chaudfontaine',
      'Châtelineau',
      'Châtelet',
      'Chastre-Villeroux-Blanmont',
      'Chasse Royale',
      'Charneux',
      'Charleroi',
      'Lasne-Chapelle-Saint-Lambert',
      'Chapelle-lez-Herlaimont',
      'Champlon',
      'Champion',
      'Chaineux',
      'Céroux-Mousty',
      'Cerfontaine',
      'Cerexhe-Heuseux',
      'Celles',
      'Casteau',
      'Carnières',
      'Cambron-Saint-Vincent',
      'Buzet',
      'Buvrinnes',
      'Butgenbach',
      'Burst',
      'Burdinne',
      'Burcht',
      'Bunsbeek',
      'Bullange',
      'Buizingen',
      'Buissonville',
      'Buggenhout',
      'Budingen',
      'Brussels',
      'Brustem',
      'Brussegem',
      'Brunehault',
      'Brugge',
      'Brugelette',
      'Broechem',
      'Bressoux',
      'Breendonk',
      'Bree',
      'Bredene',
      'Brecht',
      'Bray',
      'Brasschaat',
      'Bras',
      'Braives',
      'Braine-le-Comte',
      'Braine-le-Château',
      "Braine-l'Alleud",
      'Bracquegnies',
      'Bovigny',
      'Bovekerke',
      'Bouwel',
      'Boutersem',
      'Bousval',
      'Boussu',
      'Boussoit',
      'Bouillon',
      'Bouge',
      'Bouffioulx',
      'Bottelare',
      'Bost',
      'Bossut-Gottechain',
      'Bossière',
      'Borsbeke',
      'Borsbeek',
      'Bornem',
      'Borgloon',
      'Borgerhout',
      'Borchtlombeek',
      'Boortmeerbeek',
      'Boorsem',
      'Boom',
      'Booischot',
      'Bon-Secours',
      'Bonneville',
      'Bonnert',
      'Bonlez',
      'Bonheiden',
      'Boncelles',
      'Bomal',
      "Bois-d'Haine",
      'Bois-de-Villers',
      'Bois-de-Lessines',
      'Boezinge',
      'Boekhoute',
      'Boechout',
      'Bocholt',
      'Bléharies',
      'Blégny',
      'Blauwput',
      'Blaugies',
      'Blaton',
      'Blankenberge',
      'Blanden',
      'Blaasveld',
      'Bissegem',
      'Bioul',
      'Binkom',
      'Binche',
      'Bilzen',
      'Bilstain',
      'Bihain',
      'Biez',
      'Bièvre',
      'Biesme',
      'Bierset',
      'Bierghes',
      'Bierges',
      'Biercée',
      'Bierbeek',
      'Beyne-Heusay',
      'Beverst',
      'Beverlo',
      'Beveren',
      'Beveren-Leie',
      'Bevere',
      'Bévercé',
      'Bever',
      'Bevel',
      'Beuzet',
      'Betekom',
      'Beselare',
      'Bertrix',
      'Bertogne',
      'Bertem',
      'Bernissart',
      'Berloz',
      'Berlare',
      'Berlaar',
      'Beringen',
      'Berg',
      'Berchem-Sainte-Agathe',
      'Berchem',
      'Berbroek',
      'Ben-Ahin',
      'Belsele',
      'Beloeil',
      'Bellevaux-Ligneuville',
      'Bellem',
      'Bellegem',
      'Bellefontaine',
      'Bellecourt',
      'Bellaire',
      'Belgrade',
      'Bekkevoort',
      'Bekegem',
      'Beigem',
      'Begijnendijk',
      'Beez',
      'Beerzel',
      'Beervelde',
      'Beerst',
      'Beersel',
      'Beerse',
      'Beernem',
      'Beek',
      'Beclers',
      'Beauvechain',
      'Beauraing',
      'Beaumont',
      'Beaufays',
      'Bazel',
      'Bavikhove',
      'Bavegem',
      'Baudour',
      'Battice',
      'Bas-Warneton',
      'Bastogne',
      'Bassilly',
      'Bassevelde',
      'Bassenge',
      'Basse Lasne',
      'Bas-Oha',
      'Basècles',
      'Barvaux',
      'Barry',
      'Barchon',
      'Bambrugge',
      'Balen',
      'Balegem',
      'Baisy-Thy',
      'Baileux',
      'Baelen',
      'Bachte-Maria-Leerne',
      'Baasrode',
      'Baarle-Hertog',
      'Baardegem',
      'Baal',
      'Aywaille',
      'Ayeneux',
      'Aye',
      'Awirs',
      'Awans',
      'Averbode',
      'Avelgem',
      'Auvelais',
      'Autre-Église',
      'Autelbas',
      'Aulnois',
      'Auderghem',
      'Aubel',
      'Aubange',
      'Attert',
      'Attenrode',
      'Attenhoven',
      'Athus',
      'Ath',
      'Astene',
      'Assesse',
      'Assent',
      'Assenois',
      'Assenede',
      'Assebroek',
      'Asse',
      'Asper',
      'Aspelare',
      'As',
      'Arville',
      'Arsimont',
      'Arquennes',
      'Arlon',
      'Argenteau',
      'Arendonk',
      'Ardooie',
      'Archennes',
      'Appelterre-Eichem',
      'Appels',
      'Anzegem',
      'Anvaing',
      'Antwerpen',
      'Antoing',
      'Anthisnes',
      'Antheit',
      'Anseroeul',
      'Anseremme',
      'Ans',
      'Anhée',
      'Angleur',
      'Andrimont',
      'Anderlues',
      'Anderlecht',
      'Andenne',
      'Ampsin',
      'Amougies',
      'Amblève',
      'Amay',
      'Alveringem',
      'Alsemberg',
      'Alleur',
      'Alken',
      'Aiseau',
      'Aische-en-Refail',
      'Agimont',
      'Adinkerke',
      'Adegem',
      'Acoz',
      'Achêne',
      'Achel',
      'Aartselaar',
      'Aarsele',
      'Aarschot',
      'Aalter',
      'Aalst',
      'Aalbeke',
      'Aaigem',
      'Jandrain',
      'Reuland',
      'Aartrijke',
      'Petegem-aan-de-Schelde',
      'Terkoest',
    ],
  },
  {
    country: 'Burkina Faso',
    cities: [
      'Zorgo',
      'Zitenga',
      'Ziniaré',
      'Yako',
      'Tougan',
      'Toma',
      'Toéghin',
      'Titao',
      'Tenkodogo',
      'Tanghin-Dassouri',
      'Sourgoubila',
      'Salanso',
      'Sindou',
      'Siglé',
      'Sebba',
      'Sapouy',
      'Saponé',
      'Saaba',
      'Réo',
      'Nagréongo',
      'Pô',
      'Pitmoaga',
      'Pama',
      'Pabré',
      'Oursi',
      'Ourgou-Manéga',
      'Ouargaye',
      'Ouahigouya',
      'Ouagadougou',
      'Niou',
      'Nouna',
      'Koubri',
      'Manga',
      'Mané',
      'Loumbila',
      'Léo',
      'Laye',
      'Koupéla',
      'Koudougou',
      'Korsimoro',
      'Kongoussi',
      'Komsilga',
      'Komki-Ipala',
      'Kombissiri',
      'Kokologo',
      'Kayao',
      'Kaya',
      'Houndé',
      'Gourcy',
      'Goulouré',
      'Gorom-Gorom',
      'Gayéri',
      'Garango',
      'Gaongo',
      "Fada N'gourma",
      'Dori',
      'Djibo',
      'Diébougou',
      'Diapaga',
      'Déou',
      'Dédougou',
      'Dapélogo',
      'Dano',
      'Boussouma',
      'Boussé',
      'Boulsa',
      'Boromo',
      'Bogandé',
      'Bobo-Dioulasso',
      'Bingo',
      'Bilanga',
      'Batié',
      'Barani',
      'Banfora',
      'Absouya',
      'Tin-Akoff',
      'Mani',
      'Boundoré',
    ],
  },
  {
    country: 'Bulgaria',
    cities: [
      'Beloslav',
      'Zlatograd',
      'Zlatitsa',
      'Zlataritsa',
      'Zemen',
      'Zelenikovo',
      'Zavet',
      'Yambol',
      'Yakoruda',
      'Yakimovo',
      'Yablanitsa',
      'Varshets',
      'Varbitsa',
      'Valchidol',
      'Valchedram',
      'Vratsa',
      'Sveti Vlas',
      'Vidin',
      'Vetrino',
      'Vetovo',
      'Venets',
      'Velingrad',
      'Veliko Tŭrnovo',
      'Varna',
      'Ugarchin',
      'Tvarditsa',
      'Tutrakan',
      'Targovishte',
      'Tsenovo',
      'Tryavna',
      'Tran',
      'Troyan',
      'Topolovo',
      'Topolovgrad',
      'Dobrich',
      'Tochilari',
      'Teteven',
      'Tervel',
      'Batanovtsi',
      'Svoge',
      'Svishtov',
      'Svilengrad',
      'Suvorovo',
      'Sarnitsa',
      'Sungurlare',
      'Suhindol',
      'Saedinenie',
      'Strumyani',
      'Strelcha',
      'Strazhitsa',
      'Straldzha',
      'Stara Zagora',
      'Stara Kresna',
      'Dupnitsa',
      'Stamboliyski',
      'Spasovo',
      'Sozopol',
      'Sofia',
      'Smyadovo',
      'Smolyan',
      'Slivo Pole',
      'Slivnitsa',
      'Sliven',
      'Slavyanovo',
      'Sitovo',
      'Simitli',
      'Simeonovgrad',
      'Silistra',
      'Shumen',
      'Shipka',
      'Shabla',
      'Sevlievo',
      'Septemvri',
      'Senovo',
      'Satovcha',
      'Sarafovo',
      'Sapareva Banya',
      'Sandanski',
      'Samuil',
      'Samokov',
      'Sadovo',
      'Ruzhintsi',
      'Ruse',
      'Ruen',
      'Rudozem',
      'Roman',
      'Rila',
      'Razlog',
      'Razgrad',
      'Ravda',
      'Rakovski',
      'Rakitovo',
      'Radomir',
      'Radnevo',
      'Parvomaytsi',
      'Parvomay',
      'Provadia',
      'Primorsko',
      'Veliki Preslav',
      'Pravets',
      'Pordim',
      'Popovo',
      'Pomorie',
      'Polski Trambesh',
      'Plovdiv',
      'Pliska',
      'Pleven',
      'Pirdop',
      'Petrich',
      'Peshtera',
      'Perushtitsa',
      'Pernik',
      'Iskar',
      'Pazardzhik',
      'Pavlikeni',
      'Pavel Banya',
      'Panagyurishte',
      'Oryahovo',
      'Opaka',
      'Omurtag',
      'Obzor',
      'Novo Selo',
      'Novi Pazar',
      'Nova Zagora',
      'Nikopol',
      'Nikolaevo',
      'Nevestino',
      'Nesebar',
      'Nedelino',
      'Rupite',
      'Maglizh',
      'Mizia',
      'Mirkovo',
      'Mineralni Bani',
      'Montana',
      'Tsarevo',
      'Mezdra',
      'Medovene',
      'Medkovets',
      'Malko Tarnovo',
      'Makresh',
      'Madzharovo',
      'Madan',
      'Lyubimets',
      'Lyaskovets',
      'Lukovit',
      'Laki',
      'Loznitsa',
      'Lovech',
      'Lom',
      'Levski',
      'Letnitsa',
      'Lesichovo',
      'Lakatnik',
      'Kyustendil',
      'Kardzhali',
      'Kula',
      'Kubrat',
      'Krushari',
      'Krumovgrad',
      'Krivodol',
      'Krichim',
      'Kresna',
      'Kozloduy',
      'Koynare',
      'Kotel',
      'Kostinbrod',
      'Koprivshtitsa',
      'Kolarovo',
      'Kocherinovo',
      'Knezha',
      'Klisura',
      'Kiten',
      'Kirkovo',
      'Kilifarevo',
      'Tsar Kaloyan',
      'Hisarya',
      'Hayredin',
      'Haskovo',
      'Harmanli',
      'Hadzhidimovo',
      'Kermen',
      'Kazanlak',
      'Kaynardzha',
      'Kavarna',
      'Kaspichan',
      'Karnobat',
      'Karlovo',
      'Kaolinovo',
      'Kameno',
      'Kaloyanovo',
      'Kalofer',
      'Ivaylovgrad',
      'Ivanovo',
      'Isperih',
      'Ihtiman',
      'Garmen',
      'Gurkovo',
      'Gulyantsi',
      'Gŭlŭbovo',
      'Sredets',
      'Gramada',
      'Gyovren',
      'Gotse Delchev',
      'Gorna Oryahovitsa',
      'Gorna Malina',
      'Gorichevo',
      'Godech',
      'Glavinitsa',
      'Dolni Chiflik',
      'General Toshevo',
      'Gara Hitrino',
      'Gabrovo',
      'Etropole',
      'Elkhovo',
      'Elhovo',
      'Elin Pelin',
      'Elena',
      'Dzhebel',
      'Dve Mogili',
      'Dunavtsi',
      'Dulovo',
      'Dalgopol',
      'Dryanovo',
      'Drenovets',
      'Dragoman',
      'Dospat',
      'Dolni Dabnik',
      'Dolna Mitropolia',
      'Dolna Banya',
      'Dimovo',
      'Dimitrovgrad',
      'Devnya',
      'Devin',
      'Debelets',
      'Chuprene',
      'Chirpan',
      'Chiprovtsi',
      'Cherven Bryag',
      'Chernomorets',
      'Chepelare',
      'Chelopech',
      'Chavdar',
      'Byala Slatina',
      'Byala Cherkva',
      'Byala',
      'Burgas',
      'Buhovo',
      'Brusartsi',
      'Brezovo',
      'Breznik',
      'Bregovo',
      'Bratsigovo',
      'Bozhurishte',
      'Boynitsa',
      'Boychinovtsi',
      'Bov',
      'Botevgrad',
      'Borovo',
      'Borovan',
      'Borino',
      'Bolyarovo',
      'Bobov Dol',
      'Boboshevo',
      'Blagoevgrad',
      'Berkovitsa',
      'Belovo',
      'Belogradchik',
      'Belitsa',
      'Belene',
      'Batak',
      'Bata',
      'Bansko',
      'Banite',
      'Balchik',
      'Aytos',
      'Asparuhovo',
      'Asenovgrad',
      'Asen',
      'Ardino',
      'Apriltsi',
      'Antonovo',
      'Anton',
      'Alfatar',
      'Aksakovo',
      'Ahtopol',
      'Zlatni Pyasatsi',
      'Aheloy',
      'Balgarevo',
    ],
  },
  {
    country: 'Bahrain',
    cities: [
      'Sitrah',
      'Madīnat ‘Īsá',
      'Jidd Ḩafş',
      'Madīnat Ḩamad',
      'Dār Kulayb',
      'Al Muharraq',
      'Manama',
      'Al Ḩadd',
      'Ar Rifā‘',
    ],
  },
  {
    country: 'Burundi',
    cities: [
      'Makamba',
      'Bururi',
      'Rumonge',
      'Mwaro',
      'Muzinda',
      'Isale',
      'Bujumbura',
      'Muramvya',
      'Gitega',
      'Ruyigi',
      'Cankuzo',
      'Karuzi',
      'Bubanza',
      'Cibitoke',
      'Buganda',
      'Ngozi',
      'Kayanza',
      'Muyinga',
      'Kirundo',
      'Rutana',
    ],
  },
  {
    country: 'Benin',
    cities: [
      'Zagnanado',
      'Tchaourou',
      'Tanguiéta',
      'So-Ava',
      'Semi',
      'Sé',
      'Savé',
      'Savalou',
      'Sakété',
      'Porto-Novo',
      'Pobé',
      'Parakou',
      'Pahou',
      'Ouinhi',
      'Ouidah',
      'Nikki',
      'Natitingou',
      'Malanville',
      'Lokossa',
      'Kétou',
      'Kandi',
      'Hinvi',
      'Hévié',
      'Guilmaro',
      'Grand-Popo',
      'Goumori',
      'Dogbo',
      'Djougou',
      'Dassa-Zoumé',
      'Cové',
      'Cotonou',
      'Comé',
      'Bohicon',
      'Bétérou',
      'Bembèrèkè',
      'Bassila',
      'Banikoara',
      'Banigbé',
      'Avakpa',
      'Aplahoué',
      'Angara-Débou',
      'Allada',
      'Adjohon',
      'Abomey-Calavi',
      'Abomey',
      'Houinvigue',
      'Kpomassè',
    ],
  },
  { country: 'Saint Barthélemy', cities: ['Gustavia'] },
  { country: 'Bermuda', cities: ['Saint George', 'Hamilton'] },
  {
    country: 'Brunei',
    cities: [
      'Tutong',
      'Seria',
      'Kuala Belait',
      'Mentiri',
      'Kapok',
      'Bangar',
      'Bandar Seri Begawan',
    ],
  },
  {
    country: 'Bolivia',
    cities: [
      'San Pedro',
      'San Matías',
      'Roboré',
      'Puerto Quijarro',
      'Nueva Manoa',
      'Yamparáez',
      'Yacuiba',
      'Warnes',
      'Vinto',
      'Viloco',
      'Villazón',
      'Villa Yapacaní',
      'Villa Serrano',
      'Villamontes',
      'Villa Charcas',
      'Viacha',
      'Vallegrande',
      'Uyuni',
      'Urubichá',
      'Tupiza',
      'Trinidad',
      'Totoral',
      'Totora',
      'Eucaliptus',
      'Tiquipaya',
      'Tiahuanaco',
      'Tarija',
      'Tarata',
      'Tarabuco',
      'Sucre',
      'Sorata',
      'Sipe Sipe',
      'Santiago del Torno',
      'Santa Rosa del Sara',
      'Santa Rosa',
      'Santa Rita',
      'Santa Cruz de la Sierra',
      'Santa Bárbara',
      'Santa Ana de Yacuma',
      'San Ramón',
      'San Pablo',
      'San Julian',
      'San Juan del Surutú',
      'San Ignacio de Velasco',
      'San Carlos',
      'Samaipata',
      'Sacaba',
      'Rurrenabaque',
      'Riberalta',
      'Reyes',
      'Quime',
      'Quillacollo',
      'Punata',
      'Puesto de Pailas',
      'Potosí',
      'Portachuelo',
      'Poopó',
      'Paurito',
      'Patacamaya',
      'Pailón',
      'Padilla',
      'Oruro',
      'Okinawa Número Uno',
      'Montero',
      'Monteagudo',
      'Mizque',
      'Mineros',
      'Mecapaca',
      'Mapiri',
      'Mairana',
      'Machacamarca',
      'Los Negros',
      'Llallagua',
      'Limoncito',
      'La Paz',
      'Laja',
      'Lahuachaca',
      'La Bélgica',
      'Jorochito',
      'Yumani',
      'Irpa Irpa',
      'Independencia',
      'Huatajata',
      'Huarina',
      'Huanuni',
      'Huachacalla',
      'Guayaramerín',
      'Guanay',
      'Entre Ríos',
      'Curahuara de Carangas',
      'Cotoca',
      'Coroico',
      'Coripata',
      'Concepción',
      'Comarapa',
      'Colquiri',
      'Colquechaca',
      'Colomi',
      'Colchani',
      'Colcapirhua',
      'Cochabamba',
      'Cobija',
      'Cliza',
      'Chulumani',
      'Chimoré',
      'Charagua',
      'Challapata',
      'Caranavi',
      'Capinota',
      'Camiri',
      'Camargo',
      'Buena Vista',
      'Boyuibe',
      'Betanzos',
      'Bermejo',
      'Batallas',
      'Atocha',
      'Ascensión',
      'Arani',
      'Andamarca',
      'Amarete',
      'Aiquile',
      'Achocalla',
      'Achacachi',
      'Abapó',
      'Cruz de Machacamarca',
      'Ascención de Guarayos',
      'Santa Fe',
      'Puearto Pailas',
      'San Borja',
    ],
  },
  {
    country: 'Bonaire',
    cities: [
      "Upper Hell's Gate",
      'The Bottom',
      'Oranjestad',
      'Kralendijk',
      'Dorp Tera Kora',
      'Dorp Rincón',
      'Dorp Antriol',
    ],
  },
  {
    country: 'Brazil',
    cities: [
      'Vitorino Freire',
      'Vitória do Mearim',
      'Vitória de Santo Antão',
      'Viseu',
      'Conde',
      'Vigia',
      'Viçosa do Ceará',
      'Viçosa',
      'Vicência',
      'Viana',
      'Várzea Alegre',
      'Varjota',
      'Vargem Grande',
      'Valença do Piauí',
      'Urucurituba',
      'Uruçuí',
      'Urucará',
      'Uruburetama',
      'Urbano Santos',
      'Upanema',
      'União dos Palmares',
      'União',
      'Umirim',
      'Umbuzeiro',
      'Umarizal',
      'Uiraúna',
      'Ubajara',
      'Uauá',
      'Tutóia',
      'Turiaçu',
      'Tupanatinga',
      'Tuntum',
      'Tucuruí',
      'Tucumã',
      'Triunfo',
      'Trindade',
      'Trairi',
      'Traipu',
      'Touros',
      'Toritama',
      'Tomé Açu',
      'Timon',
      'Timbiras',
      'Timbaúba',
      'Tianguá',
      'Terra Santa',
      'Teresina',
      'Teixeira',
      'Tavares',
      'São João dos Inhamuns',
      'Taquaritinga do Norte',
      'Taquarana',
      'Taperoá',
      'Tangará',
      'Tamboril',
      'Tamandaré',
      'Taipu',
      'Tabira',
      'Surubim',
      'Sumé',
      'Sousa',
      'Soure',
      'Soledade',
      'Solânea',
      'Sobral',
      'Sirinhaém',
      'Simplício Mendes',
      'Simões',
      'Severiano Melo',
      'Sertânia',
      'Serra Talhada',
      'Serra Branca',
      'Senador Pompeu',
      'Senador José Porfírio',
      'Satuba',
      'São Tomé',
      'São Sebastião',
      'São Raimundo Nonato',
      'São Raimundo das Mangabeiras',
      'São Pedro do Piauí',
      'São Paulo do Potengi',
      'São Miguel do Tapuio',
      'São Miguel dos Campos',
      'São Miguel do Guamá',
      'São Miguel',
      'São Mateus do Maranhão',
      'São Luís do Quitunde',
      'São Luís',
      'São Lourenço da Mata',
      'São José do Egito',
      'São José do Campestre',
      'São José do Belmonte',
      'São José de Ribamar',
      'São José de Piranhas',
      'São José de Mipibu',
      'São José da Tapera',
      'São José da Laje',
      'São José da Coroa Grande',
      'São Joaquim do Monte',
      'São João dos Patos',
      'São João do Piauí',
      'São João de Pirabas',
      'São João Batista',
      'São João',
      'São Gonçalo do Amarante',
      'São Geraldo do Araguaia',
      'São Félix do Xingu',
      'São Domingos do Maranhão',
      'São Caetano de Odivelas',
      'São Bernardo',
      'São Bento',
      'São Benedito do Rio Preto',
      'Santo Antônio do Tauá',
      'Santo Antônio',
      'Santa Rita',
      'Santarém',
      'Santa Quitéria do Maranhão',
      'Santa Quitéria',
      'Santana do Mundaú',
      'Santana do Matos',
      'Santana do Ipanema',
      'Santana do Acaraú',
      'Santa Maria do Pará',
      'Santa Maria da Boa Vista',
      'Santa Luzia',
      'Santa Inês',
      'Santa Helena',
      'Santa Cruz do Capibaribe',
      'Santa Cruz',
      'Salinópolis',
      'Salgueiro',
      'Salgado de São Félix',
      'Russas',
      'Rio Tinto',
      'Rio Largo',
      'Rio Formoso',
      'Ribeirão',
      'Riachão',
      'Reriutaba',
      'Remígio',
      'Regeneração',
      'Redenção',
      'Recife',
      'Raposa',
      'Quixeré',
      'Quixeramobim',
      'Quixadá',
      'Quipapá',
      'Quebrangulo',
      'Puxinanã',
      'Princesa Isabel',
      'Presidente Dutra',
      'Prainha',
      'Santana',
      'Porto Franco',
      'Porto de Moz',
      'Porto da Folha',
      'Porto Calvo',
      'Porto',
      'Portel',
      'Pombos',
      'Pombal',
      'Poço das Trincheiras',
      'Poço Branco',
      'Pocinhos',
      'Poção de Pedras',
      'Pitimbu',
      'Pirpirituba',
      'Piripiri',
      'Pirapemas',
      'Piracuruca',
      'Pio XII',
      'Pio IX',
      'Pinheiro',
      'Pindaré Mirim',
      'Pimenteiras',
      'Pilar',
      'Picuí',
      'Picos',
      'Piancó',
      'Petrolina',
      'Jatobá',
      'Pesqueira',
      'Pentecoste',
      'Pendências',
      'Penalva',
      'Pedro Velho',
      'Pedro II',
      'Pedras de Fogo',
      'Pedra Branca',
      'Pedra',
      'Paulo Ramos',
      'Paulo Afonso',
      'Paulista',
      'Patu',
      'Patos',
      'Pastos Bons',
      'Passira',
      'Passagem Franca',
      'Parnarama',
      'Parnamirim',
      'Parnaíba',
      'Parintins',
      'Parelhas',
      'Parambu',
      'Paraipaba',
      'Paraibano',
      'Paragominas',
      'Paracuru',
      'Pão de Açúcar',
      'Palmeirais',
      'Palmares',
      'Pacatuba',
      'Pacajus',
      'Ouricuri',
      'Ourém',
      'Orós',
      'Orobó',
      'Oriximiná',
      'Olivença',
      'Olinda',
      'Oeiras do Pará',
      'Oeiras',
      'Ocara',
      'Óbidos',
      'Novo Oriente',
      'Novo Lino',
      'Nova Russas',
      'Nova Olinda do Norte',
      'Nova Floresta',
      'Nova Cruz',
      'Nísia Floresta',
      'Nhamundá',
      'Nazaré da Mata',
      'Natuba',
      'Natal',
      'Murici',
      'Mulungu',
      'Muaná',
      'Morros',
      'Moreno',
      'Morada Nova',
      'Montes Altos',
      'Monteiro',
      'Monte Alegre',
      'Montanhas',
      'Monsenhor Gil',
      'Monção',
      'Mombaça',
      'Moju',
      'Mogeiro',
      'Mossoró',
      'Mocajuba',
      'Missão Velha',
      'Mirador',
      'Miracema do Tocantins',
      'Minador do Negrão',
      'Milagres',
      'Miguel Alves',
      'Messias',
      'Mazagão',
      'Maués',
      'Matriz de Camaragibe',
      'Matinha',
      'Matias Olímpio',
      'Mata Grande',
      'Massaranduba',
      'Maribondo',
      'Mari',
      'Marechal Deodoro',
      'Marco',
      'Marapanim',
      'Maraial',
      'Maragogi',
      'Maracanaú',
      'Maracanã',
      'Maracaçumé',
      'Marabá',
      'Manaíra',
      'Mamanguape',
      'Major Isidoro',
      'Maceió',
      'Macau',
      'Macaparana',
      'Macapá',
      'Macaíba',
      'Luzilândia',
      'Luís Correia',
      'Limoeiro do Norte',
      'Limoeiro do Ajuru',
      'Limoeiro de Anadia',
      'Limoeiro',
      'Lavras da Mangabeira',
      'Lajedo',
      'Lago da Pedra',
      'Lagoa Seca',
      'Lagoa Nova',
      'Lagoa do Itaenga',
      'Lagoa da Canoa',
      'Juruti',
      'Juru',
      'Juripiranga',
      'Junqueiro',
      'Jundiá',
      'Jucurutu',
      'Jucás',
      'Juazeiro do Norte',
      'Juàzeirinho',
      'Juá dos Vieiras',
      'José de Freitas',
      'Joaquim Nabuco',
      'João Pessoa',
      'João Câmara',
      'João Alfredo',
      'Girau do Ponciano',
      'Jardim do Seridó',
      'Jardim de Piranhas',
      'Jardim',
      'Jaicós',
      'Jaguaruana',
      'Jaguaribe',
      'Jacareacanga',
      'Jacaraú',
      'Jaboatão',
      'Itupiranga',
      'Itaueira',
      'Itatuba',
      'Itaquitinga',
      'Itacoatiara',
      'Itaporanga',
      'Itapissuma',
      'Itapipoca',
      'Itapecuru Mirim',
      'Itapagé',
      'Itamaracá',
      'Itaituba',
      'Itaitinga',
      'Itainópolis',
      'Itaíba',
      'Itabaiana',
      'Irituia',
      'Irauçuba',
      'Ipueiras',
      'Ipubi',
      'Ipu',
      'Ipojuca',
      'Ipanguaçu',
      'Inhuma',
      'Inhapi',
      'Ingá',
      'Independência',
      'Imperatriz',
      'Imaculada',
      'Iguatu',
      'Igarapé Miri',
      'Igarapé Açu',
      'Igarassu',
      'Igaci',
      'Icó',
      'Icatu',
      'Ibimirim',
      'Ibiapina',
      'Ibateguara',
      'Humberto de Campos',
      'Horizonte',
      'Hidrolândia',
      'Gurupá',
      'Gurinhém',
      'Guaraciaba do Norte',
      'Guarabira',
      'Guajará',
      'Guaiúba',
      'Guabiraba',
      'Gravatá',
      'Granja',
      'Grajaú',
      'Governador Dix Sept Rosado',
      'Goianinha',
      'Goiana',
      'Glória do Goitá',
      'Gilbués',
      'Gararu',
      'Garanhuns',
      'Gameleira',
      'Fortuna',
      'Fortaleza',
      'Forquilha',
      'Floriano',
      'Floresta',
      'Flores',
      'Flexeiras',
      'Feira Nova',
      'Farias Brito',
      'Fagundes',
      'Exu',
      'Eusébio',
      'Extremoz',
      'Estreito',
      'Esperantinópolis',
      'Esperantina',
      'Esperança',
      'Escada',
      'Elesbão Veloso',
      'Dona Inês',
      'Dom Pedro',
      'Dois Riachos',
      'Desterro',
      'Demerval Lobão',
      'Delmiro Gouveia',
      'Custódia',
      'Cururupu',
      'Curuçá',
      'Currais Novos',
      'Curaçá',
      'Cupira',
      'Cumaru',
      'Cuité',
      'Cruz do Espírito Santo',
      'Cruz',
      'Crato',
      'Crateús',
      'Coroatá',
      'Coremas',
      'Coreaú',
      'Condado',
      'Conceição do Araguaia',
      'Colônia Leopoldina',
      'Colinas',
      'Coité do Nóia',
      'Coelho Neto',
      'Codó',
      'Cocal',
      'Chorozinho',
      'Chapadinha',
      'Chã Grande',
      'Cerro Corá',
      'Cedro',
      'Ceará Mirim',
      'Caxias',
      'Caucaia',
      'Catolé do Rocha',
      'Catende',
      'Castelo do Piauí',
      'Castanhal',
      'Cascavel',
      'Carutapera',
      'Caruaru',
      'Carpina',
      'Carolina',
      'Carnaíba',
      'Caririaçu',
      'Cariré',
      'Careiro da Várzea',
      'Caraúbas',
      'Capoeiras',
      'Capitão Poço',
      'Capela',
      'Capanema',
      'Canto do Buriti',
      'Cantanhede',
      'Canindé de São Francisco',
      'Canindé',
      'Canhotinho',
      'Canguaretama',
      'Cândido Mendes',
      'Campos Sales',
      'Campo Maior',
      'Campo Alegre',
      'Campina Grande',
      'Camocim de São Félix',
      'Camocim',
      'Cametá',
      'Cajueiro',
      'Cajazeiras',
      'Caicó',
      'Caetés',
      'Cacimbinhas',
      'Cacimba de Dentro',
      'Cachoeirinha',
      'Cabrobó',
      'Cabo',
      'Cabedelo',
      'Caaporã',
      'Buriti dos Lopes',
      'Buriti Bravo',
      'Bujaru',
      'Buíque',
      'Breves',
      'Brejo Santo',
      'Brejo da Madre de Deus',
      'Brejo',
      'Brejinho',
      'Bragança',
      'Borba',
      'Boqueirão',
      'Bonito',
      'Bom Jesus',
      'Bom Jardim',
      'Bom Conselho',
      'Boa Viagem',
      'Bezerros',
      'Bequimão',
      'Benevides',
      'Beneditinos',
      'Belo Jardim',
      'Belém do Brejo do Cruz',
      'Belém de São Francisco',
      'Belém',
      'Bela Cruz',
      'Beberibe',
      'Bayeux',
      'Baturité',
      'Batalha',
      'Barroquinha',
      'Barro',
      'Barreiros',
      'Barreirinhas',
      'Barreirinha',
      'Barras',
      'Barra do Corda',
      'Barra de Santo Antônio',
      'Barcarena',
      'Barbalha',
      'Baraúna',
      'Bananeiras',
      'Balsas',
      'Baião',
      'Bacabal',
      'Autazes',
      'Aurora',
      'Augusto Corrêa',
      'Atalaia',
      'Assaré',
      'Aroeiras',
      'Arês',
      'Areia Branca',
      'Areia',
      'Arcoverde',
      'Araruna',
      'Araripina',
      'Araripe',
      'Arari',
      'Arara',
      'Arapiraca',
      'Araioses',
      'Araguaína',
      'Araçoiaba',
      'Aracoiaba',
      'Aracati',
      'Aquiraz',
      'Apodi',
      'Angicos',
      'Ananindeua',
      'Anajatuba',
      'Anadia',
      'Amontada',
      'Amarante do Maranhão',
      'Amarante',
      'Amaraji',
      'Altos',
      'Alto Longá',
      'Altinho',
      'Altamira',
      'Almeirim',
      'Alhandra',
      'Alexandria',
      'Alenquer',
      'Alcântara',
      'Alagoinha',
      'Alagoa Nova',
      'Alagoa Grande',
      'Águas Belas',
      'Água Preta',
      'Água Branca',
      'Agrestina',
      'Afuá',
      'Afonso Bezerra',
      'Afogados da Ingazeira',
      'Açu',
      'Acopiara',
      'Acaraú',
      'Acará',
      'Abreu e Lima',
      'Abaetetuba',
      'Xique Xique',
      'Chavantes',
      'Xanxerê',
      'Votuporanga',
      'Votorantim',
      'Volta Redonda',
      'Vitória da Conquista',
      'Vitória',
      'Visconde do Rio Branco',
      'Viradouro',
      'Vinhedo',
      'Vila Velha',
      'Videira',
      'Vianópolis',
      'Viamão',
      'Vespasiano',
      'Veranópolis',
      'Vera Cruz',
      'Wenceslau Braz',
      'Venâncio Aires',
      'Vazante',
      'Vassouras',
      'Varzelândia',
      'Várzea Paulista',
      'Várzea Grande',
      'Várzea da Palma',
      'Varginha',
      'Vargem Grande do Sul',
      'Vargem Alta',
      'Valparaíso',
      'Valinhos',
      'Valente',
      'Valença',
      'Vacaria',
      'Urussanga',
      'Urupês',
      'Uruguaiana',
      'Uruçuca',
      'Uruana',
      'Uruaçu',
      'União da Vitória',
      'Unaí',
      'Una',
      'Umuarama',
      'Umbaúba',
      'Uberlândia',
      'Uberaba',
      'Ubatuba',
      'Ubatã',
      'Ubaitaba',
      'Ubaíra',
      'Ubá',
      'Turmalina',
      'Tupi Paulista',
      'Tupanciretã',
      'Tupaciguara',
      'Tupã',
      'Tucano',
      'Tubarão',
      'Três Rios',
      'Três Pontas',
      'Três Passos',
      'Três Lagoas',
      'Três de Maio',
      'Três Coroas',
      'Três Corações',
      'Três Barras',
      'Tremembé',
      'Tremedal',
      'Tramandaí',
      'Torres',
      'Tomar do Geru',
      'Toledo',
      'Tocantins',
      'Tobias Barreto',
      'Tiradentes',
      'Timóteo',
      'Timbó',
      'Tijucas',
      'Tietê',
      'Tibagi',
      'Teutônia',
      'Terra Roxa',
      'Terra Rica',
      'Terra Nova',
      'Terra Boa',
      'Teresópolis',
      'Terenos',
      'Teófilo Otoni',
      'Teodoro Sampaio',
      'Telêmaco Borba',
      'Taubaté',
      'Tatuí',
      'Tarumã',
      'Taquarituba',
      'Taquaritinga',
      'Taquari',
      'Taquara',
      'Tapiramutá',
      'Tapes',
      'Tapejara',
      'Tanhaçu',
      'Tanguá',
      'Tanabi',
      'Tambaú',
      'Taiobeiras',
      'Taguatinga',
      'Taboão da Serra',
      'Tabatinga',
      'Suzano',
      'Sumaré',
      'Sorocaba',
      'Sombrio',
      'Socorro',
      'Sobradinho',
      'Siqueira Campos',
      'Simão Dias',
      'Silvânia',
      'Silva Jardim',
      'Sidrolândia',
      'Siderópolis',
      'Severínia',
      'Sete Lagoas',
      'Sertãozinho',
      'Sertanópolis',
      'Serro',
      'Serrinha',
      'Serra Negra',
      'Serrana',
      'Serra',
      'Seropédica',
      'Senhor do Bonfim',
      'Sengés',
      'Senador Canedo',
      'Seabra',
      'Schroeder',
      'Saubara',
      'Sarzedo',
      'Sarandi',
      'Saquarema',
      'Sapucaia',
      'Sapiranga',
      'São Vicente',
      'São Simão',
      'São Sepé',
      'São Sebastião do Passé',
      'São Sebastião do Paraíso',
      'São Sebastião do Caí',
      'São Roque',
      'São Pedro do Sul',
      'São Pedro da Aldeia',
      'São Pedro',
      'São Paulo',
      'São Miguel do Iguaçu',
      'São Miguel do Araguaia',
      'São Mateus do Sul',
      'São Mateus',
      'São Marcos',
      'São Manuel',
      'São Luiz Gonzaga',
      'São Luís de Montes Belos',
      'São Lourenço do Sul',
      'São Lourenço do Oeste',
      'São Lourenço da Serra',
      'São Lourenço',
      'São Leopoldo',
      'São José dos Pinhais',
      'São José dos Campos',
      'São José do Rio Preto',
      'São José do Rio Pardo',
      'São José do Calçado',
      'São José',
      'São Joaquim de Bicas',
      'São Joaquim da Barra',
      'São Joaquim',
      'São João Nepomuceno',
      'São João Evangelista',
      'São João do Paraíso',
      'São João de Meriti',
      'São João del Rei',
      'São João da Ponte',
      'São João da Boa Vista',
      'São João da Barra',
      'São Jerônimo',
      'São Gotardo',
      'São Gonçalo dos Campos',
      'São Gonçalo do Sapucaí',
      'São Gonçalo',
      'São Gabriel',
      'São Francisco do Sul',
      'São Francisco do Conde',
      'São Francisco de Paula',
      'São Francisco de Assis',
      'São Francisco',
      'São Fidélis',
      'São Felipe',
      'São Domingos do Prata',
      'São Domingos',
      'São Desidério',
      'São Cristóvão',
      'São Carlos',
      'São Caetano do Sul',
      'São Borja',
      'São Bernardo do Campo',
      'São Bento do Sul',
      'Santos Dumont',
      'Santos',
      'Santo Estêvão',
      'Santo Augusto',
      'Santo Antônio do Sudoeste',
      'Santo Antônio do Monte',
      'Santo Antônio do Leverger',
      'Santo Antônio do Amparo',
      'Santo Antônio de Posse',
      'Santo Antônio de Pádua',
      'Santo Antônio de Jesus',
      'Santo Antônio da Platina',
      'Santo Ângelo',
      'Santo André',
      'Santo Anastácio',
      'Santo Amaro das Brotas',
      'Santo Amaro da Imperatriz',
      'Santo Amaro',
      'Santiago',
      'Santa Vitória do Palmar',
      'Santa Vitória',
      'Santa Terezinha',
      'Santa Teresa',
      'Santa Rosa de Viterbo',
      'Santa Rosa',
      'Santa Rita do Sapucaí',
      'Santa Rita do Passa Quatro',
      'Santana do Paraíso',
      'Santana do Livramento',
      'Santana de Parnaíba',
      'Santa Maria do Suaçuí',
      'Santa Maria da Vitória',
      'Santa Maria',
      'Santaluz',
      'Santa Leopoldina',
      'Santa Isabel',
      'Santa Helena de Goiás',
      'Santa Gertrudes',
      'Santa Fé do Sul',
      'Santa Cruz do Sul',
      'Santa Cruz do Rio Pardo',
      'Santa Cruz das Palmeiras',
      'Santa Cruz Cabrália',
      'Santa Cecília',
      'Santa Branca',
      "Santa Bárbara d'Oeste",
      'Santa Adélia',
      'Sananduva',
      'Salvador',
      'Salto de Pirapora',
      'Salto',
      'Salinas',
      'Salgado',
      'Sacramento',
      'Sabinópolis',
      'Ruy Barbosa',
      'Rubiataba',
      'Rosário Oeste',
      'Rosário do Sul',
      'Rondonópolis',
      'Rolante',
      'Rolândia',
      'Rodeio',
      'Rio Verde de Mato Grosso',
      'Rio Verde',
      'Rio Tavares',
      'Rio Real',
      'Rio Pomba',
      'Rio Piracicaba',
      'Rio Pardo de Minas',
      'Rio Pardo',
      'Rio Novo do Sul',
      'Rio Novo',
      'Rio Negro',
      'Rio Negrinho',
      'Riolândia',
      'Rio Grande da Serra',
      'Rio Grande',
      'Rio do Sul',
      'Rio de Janeiro',
      'Rio das Pedras',
      'Rio das Ostras',
      'Rio Claro',
      'Rio Casca',
      'Rio Brilhante',
      'Rio Branco do Sul',
      'Rio Bonito',
      'Rio Bananal',
      'Ribeirópolis',
      'Ribeirão Preto',
      'Ribeirão Pires',
      'Ribeirão das Neves',
      'Ribeirão da Ilha',
      'Ribeirão Branco',
      'Ribeirão Bonito',
      'Ribeira do Pombal',
      'Ribas do Rio Pardo',
      'Rialma',
      'Riacho de Santana',
      'Riachão do Jacuípe',
      'Riachão do Dantas',
      'Riachão das Neves',
      'Resplendor',
      'Reserva',
      'Resende',
      'Registro',
      'Regente Feijó',
      'Recreio',
      'Realeza',
      'Raposos',
      'Rancharia',
      'Quirinópolis',
      'Quijingue',
      'Queimados',
      'Queimadas',
      'Quatro Barras',
      'Quatis',
      'Quatá',
      'Quaraí',
      'Prudentópolis',
      'Propriá',
      'Promissão',
      'Presidente Venceslau',
      'Presidente Prudente',
      'Presidente Olegário',
      'Presidente Epitácio',
      'Presidente Bernardes',
      'Prata',
      'Praia Grande',
      'Pradópolis',
      'Prado',
      'Poxoréo',
      'Pouso Alegre',
      'Posse',
      'Porto União',
      'Porto Seguro',
      'Porto Real do Colégio',
      'Porto Real',
      'Porto Murtinho',
      'Porto Ferreira',
      'Porto Feliz',
      'Porto Belo',
      'Pôrto Barra do Ivinheima',
      'Porto Alegre',
      'Porteirinha',
      'Portão',
      'Porecatu',
      'Porciúncula',
      'Porangatu',
      'Porangaba',
      'Ponto Novo',
      'Pontes e Lacerda',
      'Ponte Nova',
      'Ponta Porã',
      'Pontalina',
      'Pontal',
      'Ponta Grossa',
      'Pompéu',
      'Pompéia',
      'Pomerode',
      'Poço Verde',
      'Poços de Caldas',
      'Poconé',
      'Poções',
      'Poá',
      'Planaltina',
      'Piúma',
      'Piuí',
      'Pitangui',
      'Pitangueiras',
      'Pitanga',
      'Piritiba',
      'Pires do Rio',
      'Pirenópolis',
      'Piraquara',
      'Pirapozinho',
      'Pirapora do Bom Jesus',
      'Pirapora',
      'Piranhas',
      'Pirajuí',
      'Piraju',
      'Piraí do Sul',
      'Piraí',
      'Pirassununga',
      'Piracicaba',
      'Piracanjuba',
      'Piracaia',
      'Piquete',
      'Pinheiros',
      'Pinheiro Machado',
      'Pinheiral',
      'Pinhão',
      'Espírito Santo do Pinhal',
      'Pindorama',
      'Pindobaçu',
      'Pindamonhangaba',
      'Pilar do Sul',
      'Piedade',
      'Piaçabuçu',
      'Petrópolis',
      'Petrolina de Goiás',
      'Peruíbe',
      'Pereira Barreto',
      'Perdões',
      'Penha',
      'Penedo',
      'Penápolis',
      'Pelotas',
      'Pedro Leopoldo',
      'Pedreira',
      'Pedregulho',
      'Pedra Azul',
      'Pederneiras',
      'Peçanha',
      'Peabiru',
      'Paulínia',
      'Pau Brasil',
      'Patrocínio',
      'Patos de Minas',
      'Pato Branco',
      'Paty do Alferes',
      'Posto da Mata',
      'Passos',
      'Passo Fundo',
      'Passa Quatro',
      'Parobé',
      'Pariquera Açu',
      'Paripiranga',
      'Paraúna',
      'Paratinga',
      'Paraty',
      'Paranhos',
      'Paranavaí',
      'Paranapanema',
      'Paranaíba',
      'Paranaguá',
      'Paramirim',
      'Paraisópolis',
      'Paraíso',
      'Paraíso das Águas',
      'Paraíba do Sul',
      'Paraguaçu Paulista',
      'Paraguaçu',
      'Pará de Minas',
      'Paracatu',
      'Paracambi',
      'Papagaios',
      'Pantano do Sul',
      'Panorama',
      'Pancas',
      'Panambi',
      'Palotina',
      'Palmital',
      'Palmeiras de Goiás',
      'Palmeira das Missões',
      'Palmeira',
      'Palmas de Monte Alto',
      'Palmas',
      'Palmares do Sul',
      'Palhoça',
      'Paiçandu',
      'Padre Paraíso',
      'Padre Bernardo',
      'Pacaembu',
      'Ouro Preto',
      'Ouro Branco',
      'Ourinhos',
      'Otacílio Costa',
      'Osvaldo Cruz',
      'Osório',
      'Osasco',
      'Ortigueira',
      'Orleans',
      'Orlândia',
      'Orizona',
      'Oliveira dos Brejinhos',
      'Oliveira',
      'Olindina',
      'Olímpia',
      'Nuporanga',
      'Novo Horizonte',
      'Novo Hamburgo',
      'Novo Cruzeiro',
      'Nova Viçosa',
      'Nova Venécia',
      'Nova Soure',
      'Nova Prata',
      'Nova Petrópolis',
      'Nova Olímpia',
      'Nova Odessa',
      'Nova Londrina',
      'Nova Lima',
      'Nova Iguaçu',
      'Nova Granada',
      'Nova Friburgo',
      'Nova Era',
      'Nova Aurora',
      'Nossa Senhora do Socorro',
      'Nossa Senhora das Dores',
      'Nossa Senhora da Glória',
      'Nortelândia',
      'Nobres',
      'Niterói',
      'Niquelândia',
      'Nilópolis',
      'Nhandeara',
      'Nerópolis',
      'Nepomuceno',
      'Neópolis',
      'Nazaré',
      'Naviraí',
      'Navegantes',
      'Natividade',
      'Não Me Toque',
      'Nanuque',
      'Muzambinho',
      'Mutum',
      'Mutuípe',
      'Muritiba',
      'Muriaé',
      'Muniz Freire',
      'Mundo Novo',
      'Mucurici',
      'Mucuri',
      'Mozarlândia',
      'Morro do Chapéu',
      'Morro da Fumaça',
      'Morro Agudo',
      'Morrinhos',
      'Moreira Sales',
      'Monte Sião',
      'Montes Claros',
      'Monte Santo de Minas',
      'Monte Santo',
      'Montenegro',
      'Monte Mor',
      'Monte Carmelo',
      'Monte Azul Paulista',
      'Monte Azul',
      'Monte Aprazível',
      'Monte Alto',
      'Monte Alegre de Sergipe',
      'Monte Alegre de Minas',
      'Montanha',
      'Mongaguá',
      'Moita Bonita',
      'Mogi Mirim',
      'Mogi Guaçu',
      'Mogi das Cruzes',
      'Mococa',
      'Mirante do Paranapanema',
      'Mirandopólis',
      'Miranda',
      'Miraí',
      'Miracema',
      'Miracatu',
      'Mirabela',
      'Mineiros',
      'Minas Novas',
      'Mimoso do Sul',
      'Miguel Pereira',
      'Miguelópolis',
      'Miguel Calmon',
      'Mendes',
      'Medina',
      'Medianeira',
      'Medeiros Neto',
      'Mauá',
      'Matozinhos',
      'Mato Verde',
      'Matipó',
      'Matias Barbosa',
      'Mateus Leme',
      'Matelândia',
      'Matão',
      'Mata de São João',
      'Mascote',
      'Maruim',
      'Martinópolis',
      'Martinho Campos',
      'Maringá',
      'Marília',
      'Marilândia',
      'Maricá',
      'Mariana',
      'Marialva',
      'Marechal Floriano',
      'Marechal Cândido Rondon',
      'Mar de Espanha',
      'Marau',
      'Maraú',
      'Marataizes',
      'Maragogipe',
      'Maracás',
      'Maracaju',
      'Maracaí',
      'Mantenópolis',
      'Manhumirim',
      'Manhuaçu',
      'Mangaratiba',
      'Manga',
      'Mandaguari',
      'Mandaguaçu',
      'Malhador',
      'Malacacheta',
      'Mairiporã',
      'Mairinque',
      'Mairi',
      'Mafra',
      'Madre de Deus',
      'Machado',
      'Macaúbas',
      'Macatuba',
      'Macaé',
      'Luziânia',
      'Luz',
      'Lucélia',
      'Lucas',
      'Louveira',
      'Lorena',
      'Londrina',
      'Loanda',
      'Livramento do Brumado',
      'Lins',
      'Linhares',
      'Limeira',
      'Lima Duarte',
      'Leopoldina',
      'Lençóis Paulista',
      'Leme',
      'Lavras',
      'Lauro Muller',
      'Laranjeiras do Sul',
      'Laranjeiras',
      'Laranjal Paulista',
      'Laranja da Terra',
      'Lapão',
      'Lapa',
      'Lambari',
      'Lajinha',
      'Lages',
      'Lajeado',
      'Laje',
      'Laguna',
      'Lagoa Vermelha',
      'Lagoa Santa',
      'Lagoa Formosa',
      'Lagoa da Prata',
      'Lagoa',
      'Lagarto',
      'Ladário',
      'Juquitiba',
      'Juquiá',
      'Junqueirópolis',
      'Jundiaí',
      'Júlio de Castilhos',
      'Juiz de Fora',
      'Juatuba',
      'José Bonifácio',
      'Joinville',
      'João Pinheiro',
      'João Neiva',
      'João Monlevade',
      'Joanópolis',
      'Joaíma',
      'Joaçaba',
      'Jitaúna',
      'Jetibá',
      'Jerônimo Monteiro',
      'Jeremoabo',
      'Jequitinhonha',
      'Jequié',
      'Jaú',
      'Jataizinho',
      'Jataí',
      'Jarinu',
      'Jardinópolis',
      'Jaraguá do Sul',
      'Jaraguá',
      'Japoatã',
      'Japeri',
      'Japaratuba',
      'Januária',
      'Jandira',
      'Jandaia do Sul',
      'Janaúba',
      'Jales',
      'Jaguaruna',
      'Jaguariúna',
      'Jaguariaíva',
      'Jaguarari',
      'Jaguarão',
      'Jaguaquara',
      'Jacutinga',
      'Jacupiranga',
      'Jacobina',
      'Jaciara',
      'Jacarezinho',
      'Jacareí',
      'Jaboticabal',
      'Ivoti',
      'Iúna',
      'Ituverava',
      'Iturama',
      'Itupeva',
      'Itumbiara',
      'Ituiutaba',
      'Ituberá',
      'Ituaçu',
      'Itu',
      'Itororó',
      'Itiruçu',
      'Itirapina',
      'Itinga',
      'Itaúna',
      'Itaú de Minas',
      'Itatinga',
      'Itatiba',
      'Itatiaia',
      'Itararé',
      'Itarantim',
      'Itarana',
      'Itaqui',
      'Itaquaquecetuba',
      'Itapuranga',
      'Itapuí',
      'Itápolis',
      'Itapirapuã',
      'Itapira',
      'Itapicuru',
      'Itapevi',
      'Itapeva',
      'Itapetininga',
      'Itapetinga',
      'Itaperuna',
      'Itaperuçu',
      'Itapemirim',
      'Itapema',
      'Itapecerica da Serra',
      'Itapecerica',
      'Itaparica',
      'Itapaci',
      'Itaocara',
      'Itanhém',
      'Itanhandu',
      'Itanhaém',
      'Itambé',
      'Itambacuri',
      'Itamarandiba',
      'Itamaraju',
      'Itajuípe',
      'Itajubá',
      'Itajobi',
      'Itagibá',
      'Itajaí',
      'Itaí',
      'Itaguaí',
      'Itaguaçu',
      'Itagi',
      'Itacaré',
      'Itacarambi',
      'Itabuna',
      'Itaboraí',
      'Itabirito',
      'Itabira',
      'Itaberaí',
      'Itaberaba',
      'Itaberá',
      'Itabaianinha',
      'Irupi',
      'Irecê',
      'Irati',
      'Irará',
      'Iraquara',
      'Iracemápolis',
      'Ipuã',
      'Iporã',
      'Iporá',
      'Ipirá',
      'Ipiaú',
      'Iperó',
      'Ipauçu',
      'Ipatinga',
      'Ipameri',
      'Ipaba',
      'Inhumas',
      'Inhapim',
      'Inhambupe',
      'Indiaroba',
      'Indaiatuba',
      'Indaial',
      'Imbituva',
      'Imbituba',
      'Ilhéus',
      'Ilha Solteira',
      'Ilhabela',
      'Ijuí',
      'Iguatemi',
      'Iguape',
      'Iguaí',
      'Iguaba Grande',
      'Igrejinha',
      'Igreja Nova',
      'Igarapé',
      'Igarapava',
      'Igaraçu do Tietê',
      'Iconha',
      'Içara',
      'Ibotirama',
      'Ibiúna',
      'Ibitinga',
      'Ibirubá',
      'Ibirité',
      'Ibirataia',
      'Ibirapitanga',
      'Ibirama',
      'Ibiraçu',
      'Ibiporã',
      'Ibipeba',
      'Ibicuí',
      'Ibicaraí',
      'Ibiá',
      'Ibatiba',
      'Ibaté',
      'Ibaiti',
      'Iaçu',
      'Iaciara',
      'Hortolândia',
      'Horizontina',
      'Herval',
      'Gurupi',
      'Guiratinga',
      'Guia Lopes da Laguna',
      'Guaxupé',
      'Guarulhos',
      'Guarujá',
      'Guariba',
      'Guaratuba',
      'Guaratinguetá',
      'Guaratinga',
      'Guararema',
      'Guararapes',
      'Guarapuava',
      'Guarapari',
      'Guaraniaçu',
      'Guaranésia',
      'Guaramirim',
      'Guará',
      'Guaporé',
      'Guapó',
      'Guapimirim',
      'Guapiaçu',
      'Guanhães',
      'Guanambi',
      'Guaíra',
      'Guaçuí',
      'Gravataí',
      'Governador Valadares',
      'Goiatuba',
      'Goiás',
      'Goianira',
      'Goiânia',
      'Goianésia',
      'Goianápolis',
      'Giruá',
      'General Salgado',
      'Gaspar',
      'Garibaldi',
      'Garça',
      'Gandu',
      'Fundão',
      'Frutal',
      'Frei Paulo',
      'Frederico Westphalen',
      'Franco da Rocha',
      'Francisco Sá',
      'Francisco Morato',
      'Francisco Beltrão',
      'Franca',
      'Foz do Iguaçu',
      'Forquilhinha',
      'Formosa do Rio Preto',
      'Formosa',
      'Formiga',
      'Paulista Flórida',
      'Florianópolis',
      'Florestópolis',
      'Flores da Cunha',
      'Firminópolis',
      'Ferraz de Vasconcelos',
      'Fernandópolis',
      'Feira de Santana',
      'Faxinal',
      'Fartura',
      'Farroupilha',
      'Extrema',
      'Eunápolis',
      'Euclides da Cunha',
      'Estrela',
      'Esteio',
      'Estância Velha',
      'Estância',
      'Espumoso',
      'Esplanada',
      'Espinosa',
      'Espera Feliz',
      'Esmeraldas',
      'Erechim',
      'Entre Rios',
      'Engenheiro Beltrão',
      'Encruzilhada do Sul',
      'Encruzilhada',
      'Encantado',
      'Embu Guaçu',
      'Embu',
      'Elói Mendes',
      'Elias Fausto',
      'Eldorado',
      'Edéia',
      'Ecoporanga',
      'Duque de Caxias',
      'Duartina',
      'Dourados',
      'Dores do Indaiá',
      'Dom Pedrito',
      'Domingos Martins',
      'Dois Vizinhos',
      'Dois Córregos',
      'Divinópolis',
      'Divino das Laranjeiras',
      'Diamantino',
      'Diamantina',
      'Diadema',
      'Descalvado',
      'Curvelo',
      'Curitibanos',
      'Curitiba',
      'Cunha',
      'Cuiabá',
      'Cubatão',
      'Cruzília',
      'Cruzeiro do Oeste',
      'Cruzeiro',
      'Cruz das Almas',
      'Cruz Alta',
      'Crixás',
      'Cristinápolis',
      'Cristalina',
      'Criciúma',
      'Cravinhos',
      'Coxim',
      'Cotia',
      'Costa Rica',
      'Cosmópolis',
      'Coruripe',
      'Corupá',
      'Corumbá',
      'Correntina',
      'Correia Pinto',
      'Coronel Vivida',
      'Coronel Fabriciano',
      'Coromandel',
      'Cornélio Procópio',
      'Corinto',
      'Cordeirópolis',
      'Cordeiro',
      'Corbélia',
      'Coração de Maria',
      'Coração de Jesus',
      'Contagem',
      'Conselheiro Pena',
      'Conselheiro Lafaiete',
      'Congonhas',
      'Concórdia',
      'Conchas',
      'Conchal',
      'Conceição do Rio Verde',
      'Conceição do Mato Dentro',
      'Conceição do Coité',
      'Conceição do Castelo',
      'Conceição do Almeida',
      'Conceição do Jacuípe',
      'Conceição das Alagoas',
      'Conceição da Feira',
      'Conceição da Barra',
      'Colorado',
      'Colombo',
      'Colatina',
      'Coaraci',
      'Clevelândia',
      'Cláudio',
      'Cipó',
      'Cidreira',
      'Cícero Dantas',
      'Cianorte',
      'Chuí',
      'Chopinzinho',
      'Nova Xavantina',
      'Charqueadas',
      'Charqueada',
      'Chapecó',
      'Chapada dos Guimarães',
      'Cerro Largo',
      'Cerquilho',
      'Cerqueira César',
      'Ceres',
      'Centralina',
      'Centenário do Sul',
      'Celso Ramos',
      'Caxias do Sul',
      'Caxambu',
      'Cavalcante',
      'Catu',
      'Catanduva',
      'Catalão',
      'Cataguases',
      'Castro Alves',
      'Castro',
      'Castilho',
      'Castelo',
      'Cassilândia',
      'Cássia',
      'Casimiro de Abreu',
      'Casa Branca',
      'Carmo do Rio Verde',
      'Carmo do Rio Claro',
      'Carmo do Paranaíba',
      'Carmo do Cajuru',
      'Carmo',
      'Carlos Chagas',
      'Carlos Barbosa',
      'Carira',
      'Carinhanha',
      'Cardoso',
      'Carazinho',
      'Caratinga',
      'Caarapó',
      'Carapicuíba',
      'Carangola',
      'Carandaí',
      'Carambeí',
      'Caraí',
      'Caraguatatuba',
      'Capivari',
      'Capitólio',
      'Capinzal',
      'Capinópolis',
      'Capim Grosso',
      'Capelinha',
      'Capela do Alto',
      'Capão da Canoa',
      'Capâo Bonito',
      'Cantagalo',
      'Canoinhas',
      'Canoas',
      'Canguçu',
      'Canela',
      'Cândido Mota',
      'Cândido de Abreu',
      'Candelária',
      'Canavieiras',
      'Canarana',
      'Cananéia',
      'Campo Verde',
      'Campos Novos',
      'Campos Gerais',
      'Campos do Jordão',
      'Campos Belos',
      'Campos Altos',
      'Campos dos Goytacazes',
      'Campo Mourão',
      'Campo Largo',
      'Campo Grande',
      'Campo Formoso',
      'Campo do Brito',
      'Campo Belo',
      'Campina Verde',
      'Campinas',
      'Campina Grande do Sul',
      'Campestre',
      'Campanha',
      'Cambuquira',
      'Cambuí',
      'Cambuci',
      'Cambé',
      'Cambará',
      'Camaquã',
      'Camapuã',
      'Camanducaia',
      'Camamu',
      'Camaçari',
      'Caldas Novas',
      'Cajuru',
      'Cajati',
      'Cajamar',
      'Caieiras',
      'Caiapônia',
      'Cafelândia',
      'Cafarnaum',
      'Caetité',
      'Caeté',
      'Caculé',
      'Caçu',
      'Caconde',
      'Cachoeiro de Itapemirim',
      'Cachoeiras de Macacu',
      'Cachoeira do Sul',
      'Cachoeira',
      'Cacequi',
      'Caçapava do Sul',
      'Caçapava',
      'Caçador',
      'Cabreúva',
      'Cabo Frio',
      'Butiá',
      'Buritizeiro',
      'Buritis',
      'Buriti Alegre',
      'Buritama',
      'Buri',
      'Boquim',
      'Buerarema',
      'Bueno Brandão',
      'Brusque',
      'Brumado',
      'Brumadinho',
      'Brotas',
      'Brodósqui',
      'Brasília de Minas',
      'Brasília',
      'Bragança Paulista',
      'Braço do Norte',
      'Botucatu',
      'Botelhos',
      'Borrazópolis',
      'Borda da Mata',
      'Borborema',
      'Boracéia',
      'Boquira',
      'Bom Sucesso',
      'Bom Jesus dos Perdões',
      'Bom Jesus do Itabapoana',
      'Bom Jesus do Galho',
      'Bom Jesus da Lapa',
      'Bom Despacho',
      'Boituva',
      'Bocaiúva',
      'Boa Esperança do Sul',
      'Boa Esperança',
      'Blumenau',
      'Biritiba Mirim',
      'Birigui',
      'Biguaçu',
      'Bicas',
      'Betim',
      'Bertioga',
      'Bernardino de Campos',
      'Bento Gonçalves',
      'Belo Oriente',
      'Belo Horizonte',
      'Belmonte',
      'Belford Roxo',
      'Bela Vista do Paraíso',
      'Bela Vista de Goiás',
      'Bela Vista',
      'Bebedouro',
      'Bauru',
      'Batatais',
      'Bataiporã',
      'Bastos',
      'Barueri',
      'Barroso',
      'Barro Alto',
      'Barrinha',
      'Barretos',
      'Barreiro do Jaíba',
      'Barreiras',
      'Barra Velha',
      'Barra Mansa',
      'Barra dos Coqueiros',
      'Barra do Piraí',
      'Barra do Garças',
      'Barra do Bugres',
      'Barra de São Francisco',
      'Barra da Estiva',
      'Barra Bonita',
      'Barra',
      'Bariri',
      'Barbacena',
      'Barão de Melgaço',
      'Barão de Cocais',
      'Bandeirantes',
      'Bambuí',
      'Balneário Camboriú',
      'Baixo Guandu',
      'Baixa Grande',
      'Bagé',
      'Baependi',
      'Bady Bassitt',
      'Avaré',
      'Auriflama',
      'Atibaia',
      'Astorga',
      'Astolfo Dutra',
      'Assis',
      'Assaí',
      'Arujá',
      'Artur Nogueira',
      'Arroio Grande',
      'Arroio dos Ratos',
      'Arroio do Meio',
      'Arraias',
      'Arraial do Cabo',
      'Armação de Búzios',
      'Armação',
      'Arinos',
      'Arenápolis',
      'Areal',
      'Areado',
      'Arcos',
      'Araxá',
      'Araucária',
      'Arauá',
      'Araruama',
      'Araras',
      'Araraquara',
      'Araranguá',
      'Arapongas',
      'Araguari',
      'Araguaiana',
      'Aragarças',
      'Araçuaí',
      'Aracruz',
      'Araçoiaba da Serra',
      'Araci',
      'Araçatuba',
      'Aracaju',
      'Aquidauana',
      'Aquidabã',
      'Apucarana',
      'Apiaí',
      'Aparecida do Taboado',
      'Aparecida',
      'Antonina',
      'Anitápolis',
      'Anicuns',
      'Anguera',
      'Angra dos Reis',
      'Angatuba',
      'Andrelândia',
      'Andradina',
      'Andradas',
      'Anastácio',
      'Anápolis',
      'Anagé',
      'Ampére',
      'Amparo',
      'Américo Brasiliense',
      'Americana',
      'América Dourada',
      'Amargosa',
      'Alvorada',
      'Alvinópolis',
      'Álvares Machado',
      'Alto Piquiri',
      'Alto Paraná',
      'Altãnia',
      'Alto Araguaia',
      'Altinópolis',
      'Alterosa',
      'Alpinópolis',
      'Almirante Tamandaré',
      'Almenara',
      'Alfredo Chaves',
      'Alfenas',
      'Além Paraíba',
      'Alegrete',
      'Alegre',
      'Alagoinhas',
      'Aimorés',
      'Águia Branca',
      'Agudos',
      'Águas Vermelhas',
      'Águas Formosas',
      'Águas de Lindóia',
      'Aguaí',
      'Afonso Cláudio',
      'Adamantina',
      'Açucena',
      'Acajutiba',
      'Abaeté',
      'Abadiânia',
      'Guaíba',
      'Pontal do Paraná',
      'Uarini',
      'Tonantins',
      'Tefé',
      'Marechal Thaumaturgo',
      'Tarauacá',
      'Sena Madureira',
      'São Paulo de Olivença',
      'São Gabriel da Cachoeira',
      'Santo Antônio do Içá',
      'Santa Isabel do Rio Negro',
      'Rio Branco',
      'Porto Walter',
      'Porto Velho',
      'Pauini',
      'Novo Aripuanã',
      'Maraã',
      'Manoel Urbano',
      'Manicoré',
      'Mâncio Lima',
      'Manaus',
      'Manaquiri',
      'Manacapuru',
      'Jutaí',
      'Humaitá',
      'Fonte Boa',
      'Feijó',
      'Envira',
      'Eirunepé',
      'Cruzeiro do Sul',
      'Codajás',
      'Coari',
      'Carauari',
      'Canutama',
      'Boca do Acre',
      'Boa Vista',
      'Benjamin Constant',
      'Barcelos',
      'Ariquemes',
      'Aripuanã',
      'Anori',
      'Alvarães',
      'Iranduba',
      'Xapuri',
      'Vilhena',
      'Senador Guiomard',
      'Presidente Médici',
      'Pôsto Fiscal Rolim de Moura',
      'Plácido de Castro',
      'Pimenta Bueno',
      'Ouro Preto do Oeste',
      'Ji Paraná',
      'Jaru',
      'Guajará Mirim',
      'Cacoal',
      'Brasiléia',
      'Alta Floresta',
      'Aparecida de Goiânia',
      'Araguanã',
      'Campo Limpo Paulista',
      'Fernando de Noronha (Distrito Estadual)',
      'Jaboatão dos Guararapes',
      'Lago dos Rodrigues',
      'Lauro de Freitas',
      'Lucas do Rio Verde',
      'Minaçu',
      'Monte Verde',
      'Ourilândia do Norte',
      'Parauapebas',
      'Pinhais',
      'Portelândia',
      'Rio Preto da Eva',
      'Santa Maria de Jetibá',
      'Simões Filho',
      'Sinop',
      'Teixeira de Freitas',
      'Vargem Grande Paulista',
      'Xinguara',
      'Cambebba',
      'Jardim Paulista',
      'Itacorubi',
      'Corrego Grande',
      'Santa Monica',
      'Carvoeira',
      'Morro da Cruz',
      'Saco dos Limoes',
      'Pantanal',
      'Costeira do Pirajubae',
      'Carianos',
      'Tapera',
      'Freguesia do Ribeirao da Ilha',
      'Atins',
      'Residencia Moacir PU5BHV',
      'Beira Rio',
      'Butia Inferior',
      'Nova Olina do Marnhao',
    ],
  },
  {
    country: 'Bahamas',
    cities: [
      'West End',
      'Spanish Wells',
      'San Andros',
      'Port Nelson',
      'Nassau',
      'Matthew Town',
      'Masons Bay',
      'Marsh Harbour',
      'Lucaya',
      'High Rock',
      'Hard Bargain',
      'Governor’s Harbour',
      'George Town',
      'Freeport',
      'Dunmore Town',
      'Duncan Town',
      'Cooper’s Town',
      'Colonel Hill',
      'Cockburn Town',
      'Clarence Town',
      'Black Point',
      'Arthur’s Town',
      'Andros Town',
      'Alice Town',
      'Abraham’s Bay',
    ],
  },
  {
    country: 'Bhutan',
    cities: [
      'Wangdue Phodrang',
      'Trongsa',
      'Thimphu',
      'Trashi Yangtse',
      'Trashigang',
      'Daga',
      'Shemgang',
      'Sarpang',
      'Punākha',
      'Phuntsholing',
      'Paro',
      'Pajo',
      'Mongar',
      'Lhuentse',
      'Ha',
      'Gasa',
      'Daphu',
      'Tsirang',
      'Tsimasham',
      'Jakar',
      'Panbang',
      'Samtse',
      'Pemagatshel',
      'Samdrup Jongkhar',
      'Laya',
      'Lungtenzampa',
    ],
  },
  {
    country: 'Botswana',
    cities: [
      'Werda',
      'Tshabong',
      'Tsau',
      'Tonota',
      'Tobane',
      'Thamaga',
      'Tamasane',
      'Sua',
      'Shakawe',
      'Serowe',
      'Selebi-Phikwe',
      'Sekoma',
      'Sehithwa',
      'Sefophe',
      'Sebina',
      'Ratholo',
      'Ramotswa',
      'Ramokgonami',
      'Rakops',
      'Pilikwe',
      'Pilane',
      'Pandamatenga',
      'Palapye',
      'Otse',
      'Orapa',
      'Nokaneng',
      'Nata',
      'Mosopa',
      'Mosetse',
      'Mopipi',
      'Mookane',
      'Maunatlala',
      'Molepolole',
      'Makobeng',
      'Mogapinyana',
      'Moijabana',
      'Mogoditshane',
      'Mogapi',
      'Mochudi',
      'Mmathubudukwane',
      'Maun',
      'Mathakola',
      'Mathathane',
      'Mathambgwane',
      'Masunga',
      'Manyana',
      'Makwata',
      'Makoba',
      'Makaleng',
      'Mahalapye',
      'Magothla',
      'Machaneng',
      'Mabuli',
      'Maapi',
      'Lokwabe',
      'Lobatse',
      'Letlhakeng',
      'Letlhakane',
      'Lenchwe Le Tau',
      'Lehututu',
      'Letsheng',
      'Kurametsi',
      'Kopong',
      'Khudumelapye',
      'Khakhea',
      'Kasane',
      'Kanye',
      'Kang',
      'Kalamare',
      'Janeng',
      'Hukuntsi',
      'Gweta',
      'Gobojango',
      'Ghanzi',
      'Gaborone',
      'Gabane',
      'Francistown',
      'Dutlwe',
      'Dukwe',
      'Dekar',
      'Botlhapatlou',
      'Bokaa',
      'Gaphatshwe',
      'Metsemotlhaba',
      'Mmopone',
      'Nkoyaphiri',
      'Jwaneng',
    ],
  },
  {
    country: 'Belarus',
    cities: [
      'Indura',
      'Skidel’',
      'Znamenka',
      'Horad Zhodzina',
      'Zhlobin',
      'Zhytkavichy',
      'Zhirovichi',
      'Zhaludok',
      'Zhdanovichy',
      'Zhabinka',
      'Zel’va',
      'Zaslawye',
      'Zamostochye',
      'Yel’sk',
      'Yalizava',
      'Vysokaye',
      'Voranava',
      'Vitebsk',
      'Vishow',
      'Vishnyeva',
      'Vilyeyka',
      'Vidzy',
      'Veyno',
      'Vyetka',
      'Vyerkhnyadzvinsk',
      'Volkovysk',
      'Vasilyevichy',
      'Valozhyn',
      'Uzda',
      'Ushachy',
      'Urechcha',
      'Turaw',
      'Talachyn',
      'Tsimkavichy',
      'Tsyelyakhany',
      'Svislach',
      'Svir',
      'Svyetlahorsk',
      'Stowbtsy',
      'Stolin',
      'Staryya Darohi',
      'Starobin',
      'Stan’kava',
      'Sasnovy Bor',
      'Soly',
      'Snow',
      'Smarhoń',
      'Smaliavičy',
      'Smilavičy',
      'Slutsk',
      'Slonim',
      'Slabada',
      'Slawharad',
      'Shumilina',
      'Shklow',
      'Shchuchyn',
      'Sharkawshchyna',
      'Syanno',
      'Syenitsa',
      'Syomkava',
      'Sarachy',
      'Samakhvalavichy',
      'Salihorsk',
      'Ruzhany',
      'Rudzyensk',
      'Rasony',
      'Ross’',
      'Rahachow',
      'Rechytsa',
      'Rakaw',
      'Radaškovičy',
      'Puchavičy',
      'Pruzhany',
      'Prawdzinski',
      'Polatsk',
      'Plyeshchanitsy',
      'Pinsk',
      'Pyatryshki',
      'Pyetrykaw',
      'Pastavy',
      'Parychy',
      'Azyartso',
      'Asvieja',
      'Ostryna',
      'Astravyets',
      'Astrashytski Haradok',
      'Asnyezhytsy',
      'Asipovichy',
      'Orsha',
      'Aktsyabrski',
      'Novy Svyerzhan’',
      'Kadino',
      'Novosel’ye',
      'Novolukoml’',
      'Novaya Huta',
      'Nasilava',
      'Nyasvizh',
      'Nyakhachava',
      'Navapolatsk',
      'Novogrudok',
      'Narowlya',
      'Narach',
      'Myshkavichy',
      'Myadzyel',
      'Mscislau',
      'Motal’',
      'Mosar',
      'Mir',
      'Myory',
      'Minsk',
      'Mikashevichy',
      'Myazhysyatki',
      'Mazyr',
      'Mosty',
      'Mar’’ina Horka',
      'Malaryta',
      'Maladziečna',
      'Mahilyow',
      'Machulishchy',
      'Lyubcha',
      'Lyuban’',
      'Lyntupy',
      'Lyepyel’',
      'Lyakhavichy',
      'Luninyets',
      'Luhavaya Slabada',
      'Loyew',
      'Loshnitsa',
      'Lahoysk',
      'Lyozna',
      'Lida',
      'Lyel’chytsy',
      'Lahishyn',
      'Krychaw',
      'Krupki',
      'Kruhlaye',
      'Kryvichy',
      'Kreva',
      'Krasnyy Bereg',
      'Krasnaye',
      'Krasnosel’skiy',
      'Krasnapollye',
      'Chyrvonaya Slabada',
      'Kazhan-Haradok',
      'Kastsyukowka',
      'Kastsyukovichy',
      'Kosava',
      'Korolëv Stan',
      'Karma',
      'Karanyowka',
      'Karelichy',
      'Kapyl’',
      'Konstantinovo',
      'Kalodzishchy',
      'Kokhanava',
      'Kobryn',
      'Klimavichy',
      'Kličaŭ',
      'Klyetsk',
      'Kirawsk',
      'Khoyniki',
      'Khotsimsk',
      'Kholopenichi',
      'Khodosy',
      'Khal’ch',
      'Kamyanyuki',
      'Kamyennyya Lavy',
      'Kamyanyets',
      'Kalinkavichy',
      'Iwye',
      'Ivyanyets',
      'Ivatsevichy',
      'Ivanava',
      'Iĺja',
      'Hrodna',
      'Horki',
      "Homyel'",
      'Hlybokaye',
      'Hantsavichy',
      'Hotsk',
      'Gorodok',
      'Haradzishcha',
      'Haradzyeya',
      'Hal’shany',
      'Hlusk',
      'Hlusha',
      'Hyeranyony',
      'Hatava',
      'Dzyarzhynsk',
      'Dyatlovo',
      'Dukora',
      'Dubrowna',
      'Drybin',
      'Druja',
      'Drahichyn',
      'Dowsk',
      'Damachava',
      'Dokshytsy',
      'Dobrush',
      'Dzisna',
      'Davyd-Haradok',
      'Dashkawka',
      'Červień',
      'Charnawchytsy',
      'Cherykaw',
      'Chachersk',
      'Chavusy',
      'Chashniki',
      'Bykhaw',
      'Byaroza',
      'Buynichy',
      'Buda-Kashalyova',
      'Brest',
      'Braslaw',
      'Brahin',
      'Turets-Bayary',
      'Baruny',
      'Vyaliki Trastsyanets',
      'Vyalikaya Byerastavitsa',
      'Bobr',
      'Blon’',
      'Byarozawka',
      'Bierazino',
      'Byalynichy',
      'Byelaazyorsk',
      'Byahoml’',
      'Barysaw',
      'Baranovichi',
      'Baran’',
      'Babruysk',
      'Ashmyany',
      'Antopal’',
      'Balbasava',
      'Ramanavichy',
      'Palykavichy Pyershyya',
      'Horad Kobryn',
      'Horad Luninyets',
      'Horad Pinsk',
      'Horad Orsha',
      'Horad Rechytsa',
      'Horad Krychaw',
      'Fanipol',
      'Khatsyezhyna',
      'Schomyslitsa',
      'Yubilyeyny',
      'Atolina',
      'Pryvol’ny',
      'Enyerhyetykaw',
      'Druzhny',
      'Peramoga',
      'Borovlyany',
      'Chyst’',
      'Lyeskawka',
      'Lyasny',
      'Bal’shavik',
      'Usiazh',
      'Октябрьский',
      'Michanovichi',
      'Posëlok Voskhod',
    ],
  },
  {
    country: 'Belize',
    cities: [
      'Xaibe',
      'Shipyard',
      'Sarteneja',
      'Santa Familia',
      'San Pedro',
      'San Narciso',
      'San Jose',
      'San Ignacio',
      'San Felipe',
      'San Antonio',
      'Punta Gorda',
      'Progresso',
      'Placencia',
      'Orange Walk',
      'Little Belize',
      'Ladyville',
      'Independence and Mango Creek',
      'Esperanza',
      'Dangriga',
      'Corozal',
      'Caye Caulker',
      'Carmelita',
      'Benque Viejo el Carmen',
      'Belmopan',
      'Belize City',
      'Valley of Peace',
    ],
  },
  {
    country: 'Canada',
    cities: [
      '100 Mile House',
      'Abbotsford',
      'Acton Vale',
      'Adstock',
      'Agassiz',
      'Agincourt North',
      'Airdrie',
      'Ajax',
      'Albanel',
      'Alberton',
      'Alderwood',
      'Alliston',
      'Alma',
      'Altona',
      'Amherst',
      'Amherstburg',
      'Amigo Beach',
      'Amos',
      'Ange-Gardien',
      'Angus',
      'Anmore',
      'Antigonish',
      'Arbutus Ridge',
      'Armstrong',
      'Arnprior',
      'Arviat',
      'Asbestos',
      'Ashcroft',
      'Assiniboia',
      'Athabasca',
      'Atikokan',
      'Attawapiskat',
      'Aurora',
      'Aylmer',
      'Azilda',
      'Baie-Comeau',
      "Baie-D'Urfé",
      'Ballantrae',
      'Bancroft',
      'Banff',
      'Barraute',
      'Barrhead',
      'Barrie',
      'Barrière',
      'Bassano',
      'Bath',
      'Bay Roberts',
      'Bay St. George South',
      'Bayview Village',
      'Beaconsfield',
      'Beauharnois',
      'Beaumont',
      'Beausejour',
      'Beaverlodge',
      'Bécancour',
      'Bedford',
      'Belfast',
      'Belleville',
      'Bells Corners',
      'Belmont',
      'Beloeil',
      'Bendale',
      'Berthierville',
      'Berwick',
      'Biggar',
      'Binbrook',
      'Black Diamond',
      'Blackfalds',
      'Blainville',
      'Bluewater',
      'Boisbriand',
      'Bois-des-Filion',
      'Boissevain',
      'Bon Accord',
      'Bonavista',
      'Bonnyville',
      'Botwood',
      'Boucherville',
      'Bouctouche',
      'Bourget',
      'Bowen Island',
      'Bow Island',
      'Bracebridge',
      'Brampton',
      'Brandon',
      'Brant',
      'Brantford',
      'Bridgewater',
      'Brockville',
      'Bromont',
      'Brooks',
      'Brossard',
      'Brownsburg-Chatham',
      'Brussels',
      'Burford',
      'Burgeo',
      'Burlington',
      'Burnaby',
      'Burns Lake',
      'Cache Creek',
      'Caledon',
      'Calgary',
      'Calmar',
      'Cambridge',
      'Cambridge Bay',
      'Camlachie',
      'Campbell River',
      'Camrose',
      'Candiac',
      'Canmore',
      'Canora',
      'Cantley',
      'Cap-Chat',
      'Cape Dorset',
      'Capreol',
      'Carberry',
      'Carbonear',
      'Cardston',
      'Carignan',
      'Carleton Place',
      'Carlyle',
      'Carman',
      'Carstairs',
      'Casselman',
      'Castlegar',
      'Cedar',
      'Chambly',
      'Chambord',
      'Channel-Port aux Basques',
      'Chapais',
      'Charlemagne',
      'Charlottetown',
      'Chase',
      'Châteauguay',
      'Chatham',
      'Chemainus',
      'Chertsey',
      'Chester',
      'Chestermere',
      'Chetwynd',
      'Chibougamau',
      'Chilliwack',
      'Chute-aux-Outardes',
      'Clarence-Rockland',
      'Clarenville-Shoal Harbour',
      'Claresholm',
      'Cliffcrest',
      'Clinton',
      'Clyde River',
      'Coaldale',
      'Coalhurst',
      'Coaticook',
      'Cobourg',
      'Cochrane',
      'Cold Lake',
      'Collingwood',
      'Colwood',
      'Conception Bay South',
      'Concord',
      'Constance Bay',
      'Contrecoeur',
      'Cookshire',
      'Cookshire-Eaton',
      'Cookstown',
      'Coombs',
      'Coquitlam',
      'Corner Brook',
      'Cornwall',
      'Corunna',
      'Coteau-du-Lac',
      'Côte-Saint-Luc',
      'Courtenay',
      'Cowansville',
      'Cow Bay',
      'Crabtree',
      'Cranbrook',
      'Creston',
      'Crossfield',
      'Cumberland',
      'Dalmeny',
      'Danville',
      'Dartmouth',
      'Dauphin',
      'Daveluyville',
      'Dawson Creek',
      'Deep River',
      'Deer Lake',
      'Delaware',
      'Deloraine',
      'Delson',
      'Delta',
      'Denman Island',
      'Deseronto',
      'Deux-Montagnes',
      'Devon',
      'Didsbury',
      'Dieppe',
      'Digby',
      'Disraeli',
      'Dolbeau-Mistassini',
      'Dollard-Des Ormeaux',
      'Dorchester',
      'Dorset Park',
      'Dorval',
      'Dowling',
      'Drayton Valley',
      'Drummondville',
      'Dryden',
      'Duncan',
      'Dunham',
      'East Angus',
      'East Gwillimbury',
      'East Wellington',
      'Edmonton',
      'Edson',
      'Elkford',
      'Elk Point',
      'Elliot Lake',
      'Ellison',
      'Elmvale',
      'Humbermede',
      'Enderby',
      'Englehart',
      'English Corner',
      'Espanola',
      'Esquimalt',
      'Essex',
      'Esterhazy',
      'Estevan',
      'Etobicoke West Mall',
      'Fairview',
      'Falher',
      'Fall River',
      'Farnham',
      'Ferme-Neuve',
      'Fermont',
      'Fernie',
      'Flemingdon Park',
      'Flin Flon',
      'Foam Lake',
      'Fort-Coulonge',
      'Fort Erie',
      'Fort Frances',
      'Fort Macleod',
      'Fort McMurray',
      'Fort Nelson',
      'Fort Saskatchewan',
      'Fort Smith',
      'Fort St. John',
      'Fox Creek',
      'Franklin',
      'Fredericton',
      'French Creek',
      'Fruitvale',
      'Gambo',
      'Gananoque',
      'Gander',
      'Ganges',
      'Gaspé',
      'Gatineau',
      'Gibbons',
      'Gibsons',
      'Gimli',
      'Gjoa Haven',
      'Glace Bay',
      'Glencoe',
      'Godefroy',
      'Goderich',
      'Golden',
      'Goulds',
      'Granby',
      'Grand Bank',
      'Grande Cache',
      'Grande-Digue',
      'Grande Prairie',
      'Grand Falls-Windsor',
      'Grand Forks',
      'Greater Sudbury',
      'Gravelbourg',
      'Gravenhurst',
      'Greater Napanee',
      'Greenstone',
      'Greenwood',
      'Grimsby',
      'Grimshaw',
      'Grunthal',
      'Guelph',
      'Guildwood',
      'Hamilton',
      'Hampstead',
      'Hampton',
      'Hanceville',
      'Hanna',
      'Hanover',
      'Hantsport',
      'Happy Valley-Goose Bay',
      'Harbour Breton',
      'Harriston',
      'Havre-Saint-Pierre',
      'Hawkesbury',
      'Hay River',
      'Headingley',
      'Hearst',
      'Henry Farm',
      'Highland Creek',
      'High Level',
      'High Prairie',
      'High River',
      'Hillcrest Village',
      'Hinton',
      'Hope',
      'Hornby Island',
      'Hornepayne',
      'Houston',
      'Hudson',
      'Hudson Bay',
      'Humber Summit',
      'Humboldt',
      'Huntingdon',
      'Huntsville',
      'Huron East',
      'Igloolik',
      'Ile des Chênes',
      'Indian Head',
      'Ingersoll',
      'Innisfil',
      'Inuvik',
      'Invermere',
      'Ionview',
      'Iqaluit',
      'Iroquois Falls',
      'Irricana',
      'Jarvis',
      'Jasper Park Lodge',
      'Joliette',
      'Kamloops',
      'Kamsack',
      'Kanata',
      'Kapuskasing',
      'Kelowna',
      'Kennedy Park',
      'Kenora',
      'Kensington',
      'Kentville',
      'Kerrobert',
      'Keswick',
      'Keswick Ridge',
      'Killam',
      'Killarney',
      'Kimberley',
      'Kincardine',
      'Kindersley',
      'King',
      'Kingsey Falls',
      'Kingston',
      'Kirkland',
      'Kirkland Lake',
      'Kitchener',
      'Kitimat',
      'Kugluktuk',
      'Labelle',
      'Labrador City',
      'La Broquerie',
      'Lac-Alouette',
      'Lac-Brome',
      'Lac-Connelly',
      'Lac du Bonnet',
      'Lachute',
      'Lac La Biche',
      'Lac-Lapierre',
      'Lac-Mégantic',
      'Lacolle',
      'Lacombe',
      'La Conception',
      'Lac-Simon',
      'Ladysmith',
      'Lake Cowichan',
      'Lake Echo',
      'Lakefield',
      'Lambton Shores',
      'La Minerve',
      'Lamont',
      'Landmark',
      'Langenburg',
      'Langford',
      'Langham',
      'Langley',
      'Lanigan',
      'Lanoraie',
      'Lantz',
      'Lappe',
      'La Prairie',
      'La Ronge',
      'La Sarre',
      "L'Ascension-de-Notre-Seigneur",
      "L'Assomption",
      'La Tuque',
      'Laurentides',
      'Laval',
      'Lavaltrie',
      'Leamington',
      'Lebel-sur-Quévillon',
      'Leblanc',
      'Leduc',
      "L'Épiphanie",
      'Les Cèdres',
      'Les Coteaux',
      'Lethbridge',
      'Lewisporte',
      "L'Île-Perrot",
      'Limoges',
      'Lincoln',
      'Lindsay',
      'Linière',
      'Lions Bay',
      'Listowel',
      'Little Current',
      'Lively',
      'Lloydminster',
      'Logan Lake',
      'London',
      'Longueuil',
      'Lorette',
      'Lorraine',
      'Louiseville',
      'Lucan',
      'Lumby',
      'Lumsden',
      'Lunenburg',
      'Lutes Mountain',
      'Macamic',
      'Mackenzie',
      'Macklin',
      'Madoc',
      'Magog',
      'Magrath',
      'Malartic',
      'Malvern',
      'Mandeville',
      'Manitouwadge',
      'Maniwaki',
      'Manning',
      'Maple Creek',
      'Maple Ridge',
      'Marieville',
      'Markdale',
      'Markham',
      'Markland Wood',
      'Martensville',
      'Marystown',
      'Mascouche',
      'Maskinongé',
      'Matagami',
      'Mattawa',
      'Mayerthorpe',
      'Meadow Lake',
      'Meaford',
      'Medicine Hat',
      'Melfort',
      'Melita',
      'Melocheville',
      'Melville',
      'Mercier',
      'Merritt',
      'Métabetchouan',
      'Metcalfe',
      'Metchosin',
      'Middleton',
      'Midland',
      'Mildmay',
      'Millbrook',
      'Millet',
      'Milliken',
      'Milton',
      'Mimico',
      'Minnedosa',
      'Mirabel',
      'Miramichi',
      'Mission',
      'Mississauga',
      'Mississauga Beach',
      'Mistissini',
      'Moncton',
      'Montague',
      'Mont-Laurier',
      'Montréal',
      'Montréal-Est',
      'Montréal-Ouest',
      'Mont-Royal',
      'Mont-Saint-Grégoire',
      'Mont-Saint-Hilaire',
      'Mont-Tremblant',
      'Moose Factory',
      'Moose Jaw',
      'Moose Lake',
      'Moosomin',
      'Moosonee',
      'Morden',
      'Morin-Heights',
      'Morinville',
      'Morris',
      'Morrisburg',
      'Mount Albert',
      'Mount Brydges',
      'Mount Dennis',
      'Mount Pearl',
      'Nahanni Butte',
      'Nakusp',
      'Nanaimo',
      'Nanton',
      'Napanee',
      'Napierville',
      'Neebing',
      'Neepawa',
      'Nelson',
      'Nepean',
      'New Glasgow',
      'New Hamburg',
      'Newmarket',
      'New Maryland',
      'New Toronto',
      'New Westminster',
      'Niagara Falls',
      'Niagara-on-the-Lake',
      'Nicolet',
      'Nipawin',
      'Niverville',
      'Noonan',
      'Norfolk County',
      'Normandin',
      'Norman Wells',
      'North Battleford',
      'North Bay',
      'North Cowichan',
      'North Perth',
      'North Saanich',
      'North Vancouver',
      'Norwood',
      'Notre-Dame-de-Grâce',
      "Notre-Dame-de-l'Île-Perrot",
      'Notre-Dame-des-Prairies',
      'Notre-Dame-du-Lac',
      'Notre-Dame-du-Mont-Carmel',
      'Oak Bay',
      'Oakridge',
      'Oakville',
      'Oakwood Village',
      'Oka',
      'Okanagan Falls',
      'Okotoks',
      'Olds',
      'Oliver',
      'Orangeville',
      'Orillia',
      'Ormstown',
      'Oromocto',
      'Osgoode',
      'Oshawa',
      'Osoyoos',
      'Ottawa',
      'Otterburn Park',
      'Outlook',
      'Owen Sound',
      'Oxbow',
      'Oxford',
      'Paisley',
      'Pangnirtung',
      'Papineauville',
      'Parkhill',
      'Parksville',
      'Parrsboro',
      'Parry Sound',
      'Pasadena',
      'Peace River',
      'Peachland',
      'Pelican Narrows',
      'Pemberton',
      'Pembroke',
      'Penhold',
      'Penticton',
      'Perth',
      'Petawawa',
      'Peterborough',
      'Petrolia',
      'Pickering',
      'Picton',
      'Pictou',
      'Picture Butte',
      'Piedmont',
      'Pierreville',
      'Pilot Butte',
      'Pincher Creek',
      'Pincourt',
      'Pitt Meadows',
      'Plantagenet',
      'Plattsville',
      'Pointe-Calumet',
      'Pointe-Claire',
      'Pointe-du-Lac',
      'Pond Inlet',
      'Ponoka',
      'Pont Rouge',
      'Portage la Prairie',
      'Port Alberni',
      'Port-Cartier',
      'Port Colborne',
      'Port Coquitlam',
      'Port Hawkesbury',
      'Port Hope',
      'Port McNeill',
      'Port Moody',
      'Port Rowan',
      'Port Stanley',
      'Port Williams',
      'Powassan',
      'Powell River',
      'Preeceville',
      'Prescott',
      'Prévost',
      'Prince Albert',
      'Prince Edward',
      'Prince George',
      'Prince Rupert',
      'Princeton',
      'Princeville',
      'Provost',
      'Puntledge',
      'Queenswood Heights',
      'Quesnel',
      'Quinte West',
      'Gamèti',
      'Rankin Inlet',
      'Rawdon',
      'Raymond',
      'Rayside-Balfour',
      'Red Deer',
      'Red Lake',
      'Regent Park',
      'Regina',
      'Regina Beach',
      'Renfrew',
      'Repentigny',
      'Revelstoke',
      'Richelieu',
      'Richibucto',
      'Richmond',
      'Richmond Hill',
      'Rideau Park',
      'Ridgetown',
      'Rigaud',
      'Rimbey',
      'Rivers',
      'Rivière-Rouge',
      'Roberval',
      'Roblin',
      'Rock Forest',
      'Rockwood',
      'Rocky Mountain House',
      'Rosemère',
      'Rosetown',
      'Rossland',
      'Rosthern',
      'Rouge',
      'Rougemont',
      'Rouyn-Noranda',
      'Royston',
      'Russell',
      'Sackville',
      'Saguenay',
      "Saint-Adolphe-d'Howard",
      'Saint-Alexandre',
      'Saint-Amable',
      'Saint-Ambroise',
      'Saint-André-Avellin',
      'Saint Andrews',
      'Saint-Antoine',
      'Saint-Augustin',
      'Saint-Barnabé-Sud',
      'Saint-Basile-le-Grand',
      'Saint-Boniface',
      'Saint-Bruno',
      'Saint-Bruno-de-Guigues',
      'Saint-Bruno-de-Montarville',
      'Saint-Canut',
      'Saint-Césaire',
      'Saint-Charles',
      'Saint-Colomban',
      'Saint-Côme--Linière',
      'Saint-Constant',
      'Saint-Cyrille-de-Wendover',
      'Saint-Damase',
      'Saint-Denis-sur-Richelieu',
      'Saint-Donat-de-Montcalm',
      'Sainte-Adèle',
      'Sainte-Agathe-des-Monts',
      'Sainte-Anne-de-Bellevue',
      'Sainte-Anne-des-Monts',
      'Sainte-Anne-des-Plaines',
      'Sainte-Béatrix',
      'Sainte-Catherine',
      'Sainte-Croix',
      'Saint-Édouard',
      'Sainte-Julie',
      'Sainte-Julienne',
      'Saint-Elzéar',
      'Sainte-Madeleine',
      'Sainte-Marthe-sur-le-Lac',
      'Sainte-Martine',
      'Saint-Éphrem-de-Beauce',
      'Sainte-Sophie',
      'Sainte-Thècle',
      'Sainte-Thérèse',
      'Saint-Eustache',
      'Saint-Félicien',
      'Saint-Félix-de-Valois',
      'Saint-Gabriel',
      'Saint-Gédéon',
      'Saint-Germain-de-Grantham',
      'Saint-Hippolyte',
      'Saint-Honoré',
      'Saint-Hyacinthe',
      'Saint-Isidore',
      'Saint-Isidore-de-Laprairie',
      'Saint-Jacques',
      'Saint-Jacques-le-Mineur',
      'Saint-Jean-Baptiste',
      'Saint-Jean-sur-Richelieu',
      'Saint-Jérôme',
      'Saint John',
      'Saint-Joseph',
      'Saint-Joseph-de-Coleraine',
      'Saint-Joseph-du-Lac',
      'Saint-Laurent',
      'Saint-Lazare',
      'Saint-Léonard',
      "Saint-Léonard-d'Aston",
      'Saint-Liboire',
      'Saint-Lin-Laurentides',
      'Saint-Marc-des-Carrières',
      'Saint-Mathieu',
      'Saint-Michel',
      'Saint-Michel-des-Saints',
      'Saint-Nazaire',
      'Saint-Philippe-de-La Prairie',
      'Saint-Pie',
      'Saint-Pie-V',
      'Saint-Pierre-les-Becquets',
      'Saint-Polycarpe',
      'Saint-Prime',
      'Saint-Raphaël',
      'Saint-Raymond',
      'Saint-Rémi',
      'Saint-Rémi-de-Tingwick',
      'Saint-Sauveur',
      'Saint-Sauveur-des-Monts',
      'Saint-Thomas',
      'Saint-Tite',
      'Saint-Victor',
      'Saint-Zotique',
      'Salaberry-de-Valleyfield',
      'Salisbury',
      'Salluit',
      'Salmo',
      'Salmon Arm',
      'Saltair',
      'Sarnia',
      'Saskatoon',
      'Sault Ste. Marie',
      'Scarborough Village',
      'Seaforth',
      'Sechelt',
      'Selkirk',
      'Senneterre',
      'Sept-Îles',
      'Sexsmith',
      'Shaunavon',
      'Shawinigan',
      'Shawville',
      'Shediac',
      'Shelburne',
      'Shellbrook',
      'Sherbrooke',
      'Sherwood Park',
      'Sicamous',
      'Simcoe',
      'Sioux Lookout',
      'Slave Lake',
      'Smithers',
      'Smiths Falls',
      'Smoky Lake',
      'Sooke',
      'Sorel-Tracy',
      'Souris',
      'South Huron',
      'South River',
      'Sparwood',
      'Spirit River',
      'Springdale',
      'Springhill',
      'Spruce Grove',
      'Squamish',
      'St. Adolphe',
      'St. Albert',
      'St. Anthony',
      'St. Catharines',
      'Steeles',
      'Steinbach',
      'Stephenville',
      'Stephenville Crossing',
      'Stettler',
      'Stirling',
      'Stonewall',
      'Stoney Point',
      'Stony Plain',
      'St. Paul',
      'Stratford',
      'Strathmore',
      'St. Stephen',
      'St. Thomas',
      'Summerland',
      'Summerside',
      'Sundre',
      'Surrey',
      'Sussex',
      'Sutton',
      'Swan Hills',
      'Swan River',
      'Swift Current',
      'Sylvan Lake',
      'Taber',
      "Tam O'Shanter-Sullivan",
      'Tavistock',
      'Tecumseh',
      'Témiscaming',
      'Temiskaming Shores',
      'Terrace',
      'Terrasse-des-Pins',
      'Terrebonne',
      'The Beaches',
      'The Pas',
      'Thessalon',
      'Thompson',
      'Thorold',
      'Three Hills',
      'Thunder Bay',
      'Thurso',
      'Timmins',
      'Tisdale',
      'Tobermory',
      'Tofield',
      'Torbay',
      'Toronto',
      'Tottenham',
      'Trail',
      'Trois-Rivières',
      'Truro',
      'Tsawwassen',
      'Tumbler Ridge',
      'Tweed',
      'Two Hills',
      'Ucluelet',
      'Unity',
      'Upper Island Cove',
      'Uxbridge',
      'Valcourt',
      'Val-David',
      'Val-des-Monts',
      "Val-d'Or",
      'Valleyview',
      'Val-Morin',
      'Vancouver',
      'Vanderhoof',
      'Vanier',
      'Varennes',
      'Vaudreuil-Dorion',
      'Vaughan',
      'Vauxhall',
      'Vegreville',
      'Venise-en-Québec',
      'Verchères',
      'Vermilion',
      'Vernon',
      'Vernon Lake',
      'Victoria',
      'Victoriaville',
      'Viking',
      'Ville-Marie',
      'Vineland',
      'Virden',
      'Virgil',
      'Vulcan',
      'Wabana',
      'Wadena',
      'Wainwright',
      'Wakefield',
      'Walpole Island',
      'Warman',
      'Warwick',
      'Wasaga Beach',
      'Waskaganish',
      'Waswanipi',
      'Waterford',
      'Waterloo',
      'Watford',
      'Watrous',
      'Watson Lake',
      'Wawa',
      'Welcome Beach',
      'Welland',
      'Wellesley',
      'Wells',
      'Wembley',
      'West End',
      'West Hill',
      'Westlock',
      'West Lorne',
      'Westmount',
      'Weston',
      'Westville',
      'Wetaskiwin',
      'Weyburn',
      'Whistler',
      'White City',
      'Whitecourt',
      'Whitehorse',
      'White Rock',
      'Wilkie',
      'Williams Lake',
      'Winchester',
      'Windsor',
      'Wingham',
      'Winkler',
      'Winnipeg',
      'Woburn',
      'Wolfville',
      'Woodstock',
      'Wychwood',
      'Wynyard',
      'Yamachiche',
      'Yarmouth',
      'Yellowknife',
      'Yorkton',
      'Beauceville',
      'Halifax',
      "St. John's",
      'Beaupré',
      'Château-Richer',
      'Québec',
      "L'Ange-Gardien",
      'Lévis',
      'Montmagny',
      'Neuville',
      'Portneuf',
      'Rimouski',
      'Rivière-du-Loup',
      'Sydney',
      'Tofino',
      'Jasper',
      "L'Ancienne-Lorette",
      'Edmundston',
      'St-Jean-Port-Joli',
      'Saint-Georges',
      'Sainte Catherine de la Jacques Cartier',
      'Baie-Saint-Paul',
      'Carleton-sur-Mer',
      'Cacouna',
      'Campbellton',
      'Bathurst',
      'Matane',
      'Paris',
      'Buckingham',
      'Amqui',
      'Dawson City',
      'Weymontachie',
      'Chandler',
      'Bonaventure',
      'New-Richmond',
      'Thetford-Mines',
      'New Carlisle',
      'Carleton',
      'Cabano',
      'Sacré-Coeur',
      'Forestville',
      'Mont-Joli',
      'Lillooet',
      'Saint-Anselme',
      'La Malbaie',
      'Sainte-Marie',
      'Plessisville',
      'Maliotenam',
      'Les Escoumins',
      'Fossambault-sur-le-Lac',
      'Cole Harbour',
      'Okanagan',
      'West Kelowna',
      'St. George',
      'Tracadie-Sheila',
      'Sydney Mines',
      'Jonquière',
      'Cap-Santé',
      'Saint-Augustin-de-Desmaures',
      'Donnacona',
      'La Pocatière',
      'Saint-Joseph-de-Beauce',
      'Metabetchouan-Lac-a-la-Croix',
      'Ladner',
      'Walnut Grove',
      'Aldergrove',
      'Omemee',
      'Cowichan Bay',
      'Moss Park',
      'Casa Loma',
      'High Park North',
      'Roncesvalles',
      'Dufferin Grove',
      'Little Portugal',
      'Niagara',
      'Trinity-Bellwoods',
      'Napanee Downtown',
      'Skatepark',
      'Long Branch',
      'Morningside',
      'Eglinton East',
      'Woodbine Corridor',
      'Greenwood-Coxwell',
      'Woodbine-Lumsden',
      'Pleasant View',
      'Breakeyville',
      'Saint-Antoine-de-Tilly',
      'Ancaster',
      'Shannon',
      'Saint-Henri',
      'Saint-Lambert-de-Lauzon',
      'Pohénégamook',
      'Pont-Rouge',
      'West Vancouver',
      'Saint-Siméon',
      "L'Orignal",
      'Willowdale',
      'Florenceville-Bristol',
      'Saint-Pascal',
      'Vallée-Jonction',
      'East Broughton',
      'Maria',
      'Shilo',
      'Saint-Antonin',
      'Lower Sackville',
      'le Plateau',
      'Wild Rose',
      'Silver Berry',
      'Laurel',
      'Tamarack',
      'Larkspur',
      'Hérouxville',
      'Saint-Norbert',
      'Sainte-Élisabeth',
      'Shippagan',
      'Mill Woods Town Centre',
      'Fogo Island',
      'Behchokǫ̀',
      'Wendover',
      'La Haute-Saint-Charles',
      'Salt Spring Island',
      'Fallingbrook',
      'Springbrook',
      'Westlake',
      'Grand Centre',
      'Heritage Pointe',
      'Aldergrove East',
      'Duck Lake',
      'Fairwinds',
      'Cross Lake 19A',
      'McEwen',
      'Starlight Village',
      'Hayes Subdivision',
      'Eskasoni 3',
      'Valley East',
      'Haliburton Village',
      'Luceville',
      'Manawan',
      'Hauterive',
      'Le Bic',
      'Parc-Boutin',
      'North Oyster/Yellow Point',
      'Baie Ste. Anne',
      'South Pender Harbour',
      'Fundy Bay',
      'Denman Island Trust Area',
      'Shediac Bridge-Shediac River',
      'Saint-Pacôme',
      'Weedon Centre',
      'Six Mile',
      'Greater Lakeburn',
      'York University Heights',
      'Parkwoods-Donalda',
      'South Parkdale',
      'South Riverdale',
      'North St.James Town',
      'Waterfront Communities-The Island',
      'West Humber-Clairville',
      'Banbury-Don Mills',
      'Birchcliffe-Cliffside',
      'Islington-City Centre West',
      'Centennial Scarborough',
      'Mount Pleasant West',
      'Humewood-Cedarvale',
      'Beechborough-Greenbrook',
      'Blake-Jones',
      'Forest Hill North',
      'Corso Italia-Davenport',
      'Danforth',
      'Eringate-Centennial-West Deane',
      'Junction Area',
      'University',
      'Dovercourt-Wallace Emerson-Junction',
      'Edenbridge-Humber Valley',
      'Newtonbrook East',
      'Yonge-St.Clair',
      'Newtonbrook West',
      "L'Amoreaux",
      'Yorkdale-Glen Park',
      'Taylor-Massey',
      'Lambton Baby Point',
      'Lawrence Park North',
      'Palmerston-Little Italy',
      'Lansing-Westgate',
      'Lawrence Park South',
      'Leaside-Bennington',
      'Rexdale-Kipling',
      'Mount Olive-Silverstone-Jamestown',
      'Mount Pleasant East',
      'North Riverdale',
      'Old East York',
      'Willowdale West',
      'Black Creek',
      'Rockcliffe-Smythe',
      'Pelmo Park-Humberlea',
      'Playter Estates-Danforth',
      'Rosedale-Moore Park',
      'Runnymede-Bloor West Village',
      'Rustic',
      'Stonegate-Queensway',
      'Briar Hill-Belgravia',
      'Danforth East York',
      'St.Andrew-Windfields',
      'Broadview North',
      'Thistletown-Beaumond Heights',
      'Annex',
      'Thorncliffe Park',
      'Victoria Village',
      'Caledonia-Fairbank',
      'Wexford/Maryvale',
      'Weston-Pellam Park',
      'Westminster-Branson',
      'Willowdale East',
      'Yonge-Eglinton',
      'Maple Leaf',
      'Willowridge-Martingrove-Richview',
      'Church-Yonge Corridor',
      'Brookhaven-Amesbury',
      "O'Connor-Parkview",
      'Princess-Rosethorn',
      'Kensington-Chinatown',
      'Bay Street Corridor',
      'Agincourt South-Malvern West',
      'Bayview Woods-Steeles',
      'East End-Danforth',
      'Cabbagetown-South St.James Town',
      'Keelesdale-Eglinton West',
      'Englemount-Lawrence',
      'Forest Hill South',
      'Humber Heights-Westmount',
      'Bedford Park-Nortown',
      'Bathurst Manor',
      'Clanton Park',
      'Clairlea-Birchmount',
      'Bridle Path-Sunnybrook-York Mills',
      'Don Valley Village',
      'Elms-Old Rexdale',
      'Downsview-Roding-CFB',
      'Glenfield-Jane Heights',
      'High Park-Swansea',
      'Kingsview Village-The Westway',
      'Kingsway South',
      'Trent Lakes',
    ],
  },
  { country: 'Cocos [Keeling] Islands', cities: ['West Island'] },
  {
    country: 'Democratic Republic of the Congo',
    cities: [
      'Yangambi',
      'Watsa',
      'Wamba',
      'Uvira',
      'Tshikapa',
      'Sake',
      'Mwene-Ditu',
      'Mweka',
      'Mbuji-Mayi',
      'Lusambo',
      'Luebo',
      'Lubao',
      'Lodja',
      'Lisala',
      'Kongolo',
      'Kisangani',
      'Kindu',
      'Kasongo',
      'Kananga',
      'Kampene',
      'Kamina',
      'Kalemie',
      'Kabinda',
      'Kabare',
      'Kabalo',
      'Isiro',
      'Ilebo',
      'Goma',
      'Gbadolite',
      'Gandajika',
      'Demba',
      'Butembo',
      'Buta',
      'Businga',
      'Bunia',
      'Bumba',
      'Bukavu',
      'Bukama',
      'Bongandanga',
      'Bondo',
      'Boende',
      'Beni',
      'Basoko',
      'Aketi',
      'Lubumbashi',
      'Likasi',
      'Kolwezi',
      'Kipushi',
      'Kambove',
      'Tshela',
      'Nioki',
      'Mushie',
      'Moanda',
      'Mbanza-Ngungu',
      'Mbandaka',
      'Matadi',
      'Mangai',
      'Lukolela',
      'Libenge',
      'Kinshasa',
      'Kikwit',
      'Kasongo-Lunda',
      'Kasangulu',
      'Inongo',
      'Gemena',
      'Bulungu',
      'Bosobolo',
      'Boma',
      'Bolobo',
      'Bandundu',
      'Masina',
    ],
  },
  {
    country: 'Central African Republic',
    cities: [
      'Zemio',
      'Ouango',
      'Ouadda',
      'Obo',
      'Ndélé',
      'Mobaye',
      'Kembé',
      'Ippy',
      'Gambo',
      'Bria',
      'Birao',
      'Bangassou',
      'Bambari',
      'Alindao',
      'Sibut',
      'Paoua',
      'Nola',
      'Mongoumba',
      'Mbaïki',
      'Kouango',
      'Kaga Bandoro',
      'Kabo',
      'Gamboula',
      'Damara',
      'Carnot',
      'Bozoum',
      'Bouca',
      'Bouar',
      'Bossangoa',
      'Boda',
      'Boali',
      'Bimbo',
      'Berbérati',
      'Batangafo',
      'Baoro',
      'Bangui',
    ],
  },
  {
    country: 'Republic of the Congo',
    cities: [
      'Sibiti',
      'Sémbé',
      'Pointe-Noire',
      'Owando',
      'Ouésso',
      'Mossendjo',
      'Makoua',
      'Madingou',
      'Dolisie',
      'Loango',
      'Loandjili',
      'Kinkala',
      'Kayes',
      'Impfondo',
      'Gamboma',
      'Ewo',
      'Djambala',
      'Brazzaville',
      'Nkayi',
    ],
  },
  {
    country: 'Switzerland',
    cities: [
      'Zwingen',
      'Zweisimmen',
      'Zuzwil',
      'Bad Zurzach',
      'Zürich',
      'Zuoz',
      'Zunzgen',
      'Zumikon',
      'Zug',
      'Zuchwil',
      'Zollikon',
      'Zollikofen',
      'Zofingen',
      'Zizers',
      'Zernez',
      'Zermatt',
      'Zell',
      'Zäziwil',
      'Yvonand',
      'Yverdon-les-Bains',
      'Wyssachen',
      'Wynigen',
      'Wynau',
      'Würenlos',
      'Würenlingen',
      'Worben',
      'Worb',
      'Wollerau',
      'Wolhusen',
      'Wolfwil',
      'Wölflinswil',
      'Wolfenschiessen',
      'Wohlen',
      'Wittnau',
      'Wittenbach',
      'Zürich (Kreis 7) / Witikon',
      'Winterthur',
      'Windisch',
      'Wimmis',
      'Willisau',
      'Wildhaus',
      'Wilderswil',
      'Wilchingen',
      'Wila',
      'Wil',
      'Wikon',
      'Wigoltingen',
      'Wiesendangen / Wiesendangen (Dorf)',
      'Wiedlisbach',
      'Zürich (Kreis 3) / Alt-Wiedikon',
      'Wetzikon',
      'Wettingen',
      'Werthenstein',
      'Welschenrohr',
      'Weisslingen',
      'Weiningen',
      'Weinfelden',
      'Weggis',
      'Wegenstetten',
      'Weesen',
      'Wauwil',
      'Wattwil',
      'Wattenwil',
      'Wängi',
      'Wangen an der Aare',
      'Wangen',
      'Walzenhausen',
      'Waltenschwil',
      'Wallisellen',
      'Walenstadt',
      'Walkringen',
      'Waldstatt',
      'Waldkirch',
      'Waldenburg',
      'Wald',
      'Walchwil',
      'Wahlen',
      'Wagenhausen',
      'Wädenswil',
      'Vuadens',
      'Vouvry',
      'Vorderthal',
      'Vordemwald',
      'Volketswil / Volketswil (Dorf)',
      'Vitznau',
      'Visperterminen',
      'Visp',
      'Vionnaz',
      'Villnachern',
      'Villmergen',
      'Villigen',
      'Villeneuve',
      'Villaz-Saint-Pierre',
      'Villars-sur-Ollon',
      'Villars-sur-Glâne',
      'Viganello',
      'Vicques',
      'Veyrier',
      'Vex',
      'Vevey',
      'Vétroz',
      'Versoix',
      'Verscio',
      'Vernier',
      'Vernayaz',
      'Verbier',
      'Veltheim (Kreis 5)',
      'Veltheim',
      'Vechigen',
      'Varen',
      'Vandœuvres',
      'Vals Platz',
      'Vallorbe',
      'Uzwil',
      'Uznach',
      'Utzenstorf',
      'Uttwil',
      'Uttigen',
      'Uster',
      'Urtenen',
      'Urnäsch',
      'Wetzikon / Unter-Wetzikon',
      'Untervaz',
      'Untersiggenthal',
      'Unterseen',
      'Unterlunkhofen',
      'Unterkulm',
      'Illnau / Unter-Illnau',
      'Unteriberg',
      'Unterengstringen',
      'Ehrendingen',
      'Unterägeri',
      'Uitikon',
      'Uhwiesen',
      'Uetikon',
      'Uetendorf',
      'Uerkheim',
      'Uerikon',
      'Ueberstorf',
      'Udligenswil',
      'Turtmann',
      'Turgi',
      'Turbenthal',
      'Tuggen',
      'Trun',
      'Trubschachen',
      'Trub',
      'Troistorrents',
      'Trogen',
      'Trimmis',
      'Trimbach',
      'Triengen',
      'Treyvaux',
      'Trélex',
      'Travers',
      'Tramelan',
      'Trachselwald',
      'Töss (Kreis 4)',
      'Toffen',
      'Tiefencastel',
      'Thusis',
      'Thundorf',
      'Thun',
      'Thierachern',
      'Therwil',
      'Thayngen',
      'Thalwil',
      'Teufenthal',
      'Teufen',
      'Tesserete',
      'Tegerfelden',
      'Tavannes',
      'Täuffelen',
      'Tann',
      'Tanay',
      'Tamins',
      'Tägerwilen',
      'Tafers',
      'Sursee',
      'Sumiswald',
      'Sulz',
      'Sulgen',
      'Suhr',
      'Subingen',
      'Strengelbach',
      'Stettlen',
      'Stettfurt',
      'Steinhausen',
      'Steinen',
      'Stein am Rhein',
      'Stein',
      'Steffisburg',
      'Steckborn',
      'Staufen',
      'Stansstad',
      'Stans',
      'Stalden',
      'Staffelbach',
      'Stäfa',
      'Stabio',
      'Spreitenbach',
      'Spiez',
      'Speicher',
      'Sottens',
      'Sorengo',
      'Solothurn',
      'Sissach',
      'Sirnach',
      'Sitten',
      'Sins',
      'Silvaplana',
      'Silenen',
      'Sigriswil',
      'Signau',
      'Sierre',
      'Siebnen',
      'Sevelen',
      'Seuzach / Seuzach (Dorf)',
      'Seon',
      'Sennwald',
      'Sempach',
      'Sementina',
      'Selzach',
      'Seltisberg',
      'Seftigen',
      'Seengen',
      'Seedorf',
      'Seeberg',
      'Zürich (Kreis 11) / Seebach',
      'Scuol',
      'Schwyz',
      'Schwellbrunn',
      'Schwarzenberg',
      'Schwanden',
      'Schwaderloch',
      'Schüpfheim',
      'Schüpfen',
      'Schübelbach',
      'Schötz',
      'Schönenwerd',
      'Schönenbuch',
      'Schöftland',
      'Schmitten',
      'Schmerikon',
      'Schlieren',
      'Schleitheim',
      'Schinznach Dorf',
      'Schinznach Bad',
      'Schindellegi',
      'Schiers',
      'Schenkon',
      'Schattdorf',
      'Scharans',
      'Schänis',
      'Schafisheim',
      'Schaffhausen',
      'Saxon',
      'Savigny',
      'Savièse',
      'Sattel',
      'Satigny',
      'Sarnen',
      'Sarmenstorf',
      'Sargans',
      'Santa Maria in Calanca',
      'Sankt Stephan',
      'Sankt Peterzell',
      'Sankt Niklaus',
      'St. Moritz',
      'Sankt Margrethen',
      'Sankt Gallenkappel',
      'Sankt Gallen',
      'Sankt Antoni',
      'Samstagern',
      'Samedan',
      'Salvan',
      'Salmsach',
      'Salgesch',
      'Sâles',
      'Saint-Prex',
      'Saint-Maurice',
      'Saint-Livres',
      'Saint-Léonard',
      'Saint-Imier',
      'Sainte-Croix',
      'Saint-Cergue',
      'Saint-Blaise',
      'Saint-Aubin-Sauges',
      'Saillon',
      'Saignelégier',
      'Safenwil',
      'Sachseln',
      'Saas-Grund',
      'Saas-Fee',
      'Saanen',
      'Rüti',
      'Rüthi',
      'Ruswil',
      'Russikon',
      'Rüschlikon',
      'Rüschegg',
      'Rupperswil',
      'Rümlang',
      'Rüeggisberg',
      'Rue',
      'Rudolfstetten',
      'Rüderswil',
      'Rubigen',
      'Rotkreuz',
      'Rothrist',
      'Rothenthurm',
      'Röschenz',
      'Rorschach',
      'Rorbas',
      'Root',
      'Romont',
      'Römerswil',
      'Romanshorn',
      'Romanel-sur-Lausanne',
      'Rolle',
      'Rohrbach',
      'Rohr',
      'Roggwil',
      'Rodersdorf',
      'Riva San Vitale',
      'Ringgenberg',
      'Effretikon / Rikon',
      'Riggisberg',
      'Riedholz',
      'Riddes',
      'Rickenbach bei Wil',
      'Richterswil',
      'Riaz',
      'Rheinfelden',
      'Rheineck',
      'Rhäzüns',
      'Renens',
      'Reinach',
      'Reigoldswil',
      'Reiden',
      'Reichenburg',
      'Rehetobel',
      'Regensdorf',
      'Reconvilier',
      'Rechthalten',
      'Rebstein',
      'Elsau-Räterschen / Räterschen',
      'Raron',
      'Rapperswil',
      'Randogne',
      'Ramsen',
      'Rafz',
      'Radelfingen',
      'Quarten',
      'Pura',
      'Pully',
      'Puidoux',
      'Promontogno',
      'Prilly',
      'Pregassona',
      'Pratteln',
      'Praroman',
      'Prangins',
      'Poschiavo',
      'Porrentruy',
      'Pontresina',
      'La Punt Chamues-ch',
      'Plan-les-Ouates',
      'Plaffeien',
      'Pieterlen',
      'Pfyn',
      'Pfungen',
      'Pfaffhausen',
      'Pfeffingen',
      'Pfaffnau',
      'Pfäffikon',
      'Peseux',
      'Péry',
      'Perroy',
      'Penthalaz',
      'Payerne',
      'Paudex',
      'Pampigny',
      'Palézieux',
      'Pailly',
      'Ottenbach',
      'Othmarsingen',
      'Otelfingen',
      'Ostermundigen',
      'Orvin',
      'Orsières',
      'Orpund',
      'Oron-la-Ville',
      'Ormalingen',
      'Orbe',
      'Opfikon',
      'Onex',
      'Olten',
      'Ollon',
      'Oftringen',
      'Oetwil',
      'Zürich (Kreis 11) / Oerlikon',
      'Oensingen',
      'Obfelden',
      'Oberwil',
      'Wetzikon / Ober-Wetzikon',
      'Oberuzwil',
      'Urdorf',
      'Obersiggenthal',
      'Oberschrot',
      'Oberrüti',
      'Oberriet',
      'Oberrieden',
      'Oberlunkhofen',
      'Oberkirch',
      'Oberhelfenschwil',
      'Obergösgen',
      'Oberglatt',
      'Oberentfelden',
      'Oberengstringen',
      'Endingen',
      'Oberegg',
      'Oberdorf',
      'Oberdiessbach',
      'Oberburg',
      'Oberbuchsiten',
      'Oberbipp',
      'Nyon',
      'Nürensdorf',
      'Nunningen',
      'Novazzano',
      'Nottwil',
      'Wichtrach',
      'Niederurnen',
      'Niederrohrdorf',
      'Niederlenz',
      'Niederhelfenschwil',
      'Niedergösgen',
      'Erlinsbach',
      'Niederbüren',
      'Niederbipp',
      'Nidau',
      'Neunkirch',
      'Neuhausen',
      'Neuenkirch',
      'Neuenhof',
      'Neudorf',
      'Neuchâtel',
      'Netstal',
      'Neftenbach / Dorf Neftenbach',
      'Nebikon',
      'Naters',
      'Nänikon',
      'Näfels',
      'Muttenz',
      'Murten/Morat',
      'Muri',
      'Murgenthal',
      'Muotathal',
      'Muolen',
      'Müntschemier',
      'Münsterlingen',
      'Münsingen',
      'Münchwilen',
      'Münchenstein',
      'Münchenbuchsee',
      'Mumpf',
      'Müllheim',
      'Mühlethurnen',
      'Mühleberg',
      'Muhen',
      'Moutier',
      'Moudon',
      'Mosnang',
      'Mörschwil',
      'Morges',
      'Morbio Inferiore',
      'Montreux',
      'Monthey',
      'Monte Carasso',
      'Montana',
      'Montagny',
      'Montagnola',
      'Mönchaltorf',
      'Mollis',
      'Mollens',
      'Möhlin',
      'Mogelsberg',
      'Minusio',
      'Mézières',
      'Meyrin',
      'Mettmenstetten',
      'Messen',
      'Mesocco',
      'Merenschwand',
      'Menznau',
      'Menzingen',
      'Menziken',
      'Mendrisio',
      'Mellingen',
      'Melide',
      'Melchnau',
      'Melano',
      'Meisterschwanden',
      'Meiringen',
      'Meinisberg',
      'Meilen',
      'Meierskappel',
      'Meggen',
      'Mauensee',
      'Matzingen',
      'Matzendorf',
      'Matten',
      'Massagno',
      'Martigny-Ville',
      'Martigny-Combe',
      'Marthalen',
      'Märstetten-Dorf',
      'Marsens',
      'Marly',
      'Männedorf',
      'Malvaglia',
      'Malters',
      'Malleray',
      'Malans',
      'Maienfeld',
      'Magden',
      'Magadino',
      'Madiswil',
      'Lyssach',
      'Lyss',
      'Luzern',
      'Luzein',
      'Lützelflüh',
      'Lutry',
      'Lütisburg',
      'Luthern',
      'Luterbach',
      'Lungern',
      'Lumino',
      'Lugano',
      'Luchsingen',
      'Lucens',
      'Lotzwil',
      'Lostorf',
      'Losone',
      'Lodrino',
      'Locarno',
      'Littau',
      'Linthal',
      'Linden',
      'Ligornetto',
      'Liestal',
      'Liesberg',
      'Lichtensteig',
      'Leytron',
      'Leysin',
      'Le Vaud',
      'Leuzigen',
      'Leukerbad',
      'Leuk',
      'Les Ponts-de-Martel',
      'Les Geneveys-sur-Coffrane',
      'Les Breuleux',
      'Les Brenets',
      'Les Bois',
      'Lenzerheide',
      'Lenzburg',
      'Lens',
      'Le Noirmont',
      'Lenk',
      'Lengnau',
      'Le Mont-sur-Lausanne',
      'Le Locle',
      'Le Landeron',
      'Leibstadt',
      'Le Chenit',
      'Lavertezzo',
      'Lauterbrunnen',
      'Lausen',
      'Lausanne',
      'Lauperswil',
      'Laupersdorf',
      'Laupen',
      'Laufenburg',
      'Laufen',
      'Läufelfingen',
      'La Tour-de-Trême',
      'La Tour-de-Peilz',
      'La Sarraz',
      'La Roche',
      'Langrickenbach',
      'Langnau / Langnau (Dorf)',
      'Langnau',
      'Langenthal',
      'Langenbruck',
      'La Neuveville',
      'Landquart',
      'St-Légier-La Chiésaz',
      'Lachen',
      'La Chaux-de-Fonds',
      "L'Abbaye",
      'Laax',
      'Küttigen',
      'Küssnacht',
      'Küsnacht',
      'Künten',
      'Krummenau',
      'Kriens',
      'Kriegstetten',
      'Kreuzlingen',
      'Krauchthal',
      'Koppigen',
      'Konolfingen',
      'Köniz',
      'Kölliken',
      'Kollbrunn',
      'Koblenz',
      'Knutwil',
      'Knonau',
      'Kloten',
      'Klosters Serneus',
      'Klingnau',
      'Kleinlützel',
      'Kirchlindach',
      'Kirchberg',
      'Killwangen',
      'Kilchberg',
      'Kerzers',
      'Kerns',
      'Kehrsatz',
      'Kappelen',
      'Kandersteg',
      'Kaltbrunn',
      'Kallnach',
      'Kaisten',
      'Kaiseraugst',
      'Jussy',
      'Jonschwil',
      'Jonen',
      'Jona',
      'Jenaz',
      'Jegenstorf',
      'Pfäffikon / Irgenhausen',
      'Inwil',
      'Interlaken',
      'Ins',
      'Ingenbohl',
      'Illnau',
      'Ilanz',
      'Igis',
      'Ibach',
      'Hüttwilen',
      'Huttwil',
      'Hünenberg',
      'Horw',
      'Horn',
      'Horgen',
      'Zürich (Kreis 10) / Höngg',
      'Homburg',
      'Hombrechtikon',
      'Hölstein',
      'Hohenrain',
      'Hochwald',
      'Hochdorf',
      'Hitzkirch',
      'Hittnau / Hittnau (Dorf)',
      'Hinwil',
      'Hinterrhein',
      'Hindelbank',
      'Himmelried',
      'Hilterfingen',
      'Hildisrieden',
      'Hettlingen',
      'Herzogenbuchsee',
      'Herrliberg',
      'Hermiswil',
      'Herisau',
      'Hergiswil',
      'Hérémence',
      'Henggart',
      'Heitenried',
      'Heimiswil',
      'Heimberg',
      'Heiden',
      'Oberwinterthur (Kreis 2) / Hegi',
      'Hausen am Albis / Hausen (Dorf)',
      'Haslen',
      'Hasle',
      'Hallau',
      'Hägglingen',
      'Häggenschwil',
      'Hägendorf',
      'Güttingen',
      'Gunzwil',
      'Gunzgen',
      'Guggisberg',
      'Gstaad',
      'Gryon',
      'Gruyères',
      'Grüsch',
      'Grosswangen',
      'Andelfingen',
      'Grossaffoltern',
      'Grône',
      'Grolley',
      'Grindelwald',
      'Grimisuat',
      'Grenchen',
      'Grellingen',
      'Greifensee',
      'Gravesano',
      'Gränichen',
      'Grandson',
      'Grand-Savagnier',
      'Le Grand-Saconnex',
      'Grancy',
      'Grächen',
      'Grabs',
      'Gottlieben',
      'Gossau',
      'Gorgier',
      'Gordola',
      'Gontenschwil',
      'Gonten',
      'Gommiswald',
      'Goldingen',
      'Goldau',
      'Goldach',
      'Glovelier',
      'Glattfelden',
      'Glattbrugg',
      'Glarus',
      'Gland',
      'Giubiasco',
      'Giswil',
      'Gipf-Oberfrick',
      'Gimel',
      'Giffers',
      'Geuensee',
      'Gersau',
      'Gerlafingen',
      'Genolier',
      'Genève',
      'Gelterkinden',
      'Gebenstorf',
      'Ganterschwil',
      'Gams',
      'Gampel',
      'Gais',
      'Gachnang',
      'Fully',
      'Füllinsdorf',
      'Fulenbach',
      'Frutigen',
      'Froideville',
      'Frick',
      'Fribourg',
      'Frenkendorf',
      'Freienbach',
      'Frauenkappelen',
      'Frauenfeld',
      'Fraubrunnen',
      'Founex',
      'Fontenais',
      'Fontainemelon',
      'Flurlingen',
      'Flums',
      'Flühli',
      'Flüelen',
      'Flims',
      'Fleurier',
      'Flawil',
      'Flamatt',
      'Fischingen',
      'Fiesch',
      'Feusisberg',
      'Feuerthalen',
      'Ferenbalm',
      'Felsberg',
      'Feldmeilen',
      'Fehraltorf',
      'Fällanden',
      'Faido',
      'Evolène',
      'Evionnaz',
      'Evilard',
      'Ettiswil',
      'Ettingen',
      'Estavayer-le-Lac',
      'Escholzmatt',
      'Eschlikon',
      'Eschenz',
      'Eschenbach',
      'Erstfeld',
      'Ersigen',
      'Ermatingen',
      'Erlenbach im Simmental',
      'Erlenbach',
      'Erlen',
      'Erlach',
      'Eriswil',
      'Ependes',
      'Epalinges',
      'Entlebuch',
      'Ennetbürgen',
      'Ennenda',
      'Engelberg',
      'Emmetten',
      'Emmen',
      'Embrach',
      'Elgg',
      'Einsiedeln',
      'Eiken',
      'Eichberg',
      'Eich',
      'Egnach',
      'Egliswil',
      'Eggiwil',
      'Eggersriet',
      'Egg',
      'Egerkingen',
      'Effretikon',
      'Ecublens',
      'Echallens',
      'Ebnat-Kappel',
      'Ebikon',
      'Dürrenroth',
      'Dürrenäsch',
      'Dürnten',
      'Düdingen',
      'Dübendorf',
      'Dottikon',
      'Dornach',
      'Domdidier',
      'Dombresson',
      'Domat',
      'Disentis',
      'Dietlikon / Dietlikon (Dorf)',
      'Dietikon',
      'Diessenhofen',
      'Diepoldsau',
      'Diemtigen',
      'Dielsdorf',
      'Diegten',
      'Derendingen',
      'Densbüren',
      'Delémont',
      'Deitingen',
      'Degersheim',
      'Davos',
      'Därligen',
      'Dardagny',
      'Dallenwil',
      'Dagmersellen',
      'Dachsen',
      'Cully',
      'Cugy',
      'Cugnasco',
      'Crissier',
      'Couvet',
      'Courtételle',
      'Courtelary',
      'Court',
      'Courroux',
      'Courrendlin',
      'Courgevaux',
      'Courgenay',
      'Courfaivre',
      'Cossonay',
      'Cortaillod',
      'Corsier',
      'Cornaux',
      'Corminboeuf',
      'Corgémont',
      'Coppet',
      'Conthey',
      'Confignon',
      'Comano',
      'Collombey',
      'Claro',
      'Churwalden',
      'Chur',
      'Chippis',
      'Chiasso',
      'Chézard-Saint-Martin',
      'Chexbres',
      'Cheseaux',
      "Chermignon-d'en Haut",
      'Chêne-Bourg',
      'Chavornay',
      'Chavannes',
      'Châtel-Saint-Denis',
      "Château-d'Oex",
      'Charrat',
      'Charmey',
      'Chardonne',
      'Chancy',
      'Champéry',
      'Chamoson',
      'Cham',
      'Chalais',
      'Cevio',
      'Cernier',
      'Celerina',
      'Cazis',
      'Caux',
      'Cauco',
      'Castel San Pietro',
      'Caslano',
      'Carouge',
      'Canobbio',
      'Camorino',
      'Cadro',
      'Cadenazzo',
      'Cadempino',
      'Buus',
      'Buttwil',
      'Buttisholz',
      'Bütschwil',
      'Bussigny',
      'Büsserach',
      'Buseno',
      'Büron',
      'Bürglen',
      'Burgistein',
      'Burgdorf',
      'Buochs',
      'Bulle',
      'Bülach',
      'Bühler',
      'Buchs',
      'Buchrain',
      'Buchholterberg',
      'Bubikon',
      'Bubendorf',
      'Brüttisellen',
      'Brütten',
      'Brusio',
      'Brugg',
      'Brügg',
      'Bronschhofen',
      'Broc',
      'Brittnau',
      'Brissago',
      'Brislach',
      'Brig',
      'Brienz',
      'Bremgarten',
      'Breitenbach',
      'Breíl',
      'Bowil',
      'Boudry',
      'Bottmingen',
      'Bottighofen',
      'Bottens',
      'Bösingen',
      'Bonstetten',
      'Boniswil',
      'Bönigen',
      'Boncourt',
      'Bonaduz',
      'Boltigen',
      'Bolligen',
      'Bodio',
      'Blumenstein',
      'Blonay',
      'Birsfelden',
      'Birr',
      'Birmenstorf',
      'Birmensdorf',
      'Bioggio',
      'Binningen',
      'Bilten',
      'Biglen',
      'Bière',
      'Biel/Bienne',
      'Biberist',
      'Biasca',
      'Bex',
      'Bévilard',
      'Bevaix',
      'Bettlach',
      'Bettingen',
      'Beromünster',
      'Bernex',
      'Bern',
      'Beringen',
      'Berikon',
      'Berg',
      'Benken',
      'Belp',
      'Belmont-sur-Lausanne',
      'Bellmund',
      'Bellinzona',
      'Bellevue',
      'Belfaux',
      'Begnins',
      'Beckenried',
      'Beatenberg',
      'Bavois',
      'Bauma',
      'Bauen',
      'Bätterkinden',
      'Bassersdorf',
      'Basse-Nendaz',
      'Bassecourt',
      'Basel',
      'Bäriswil',
      'Bäretswil',
      'Baltschieder',
      'Balsthal',
      'Ballwil',
      'Ballens',
      'Balgach',
      'Balerna',
      'Bagnes',
      'Bad Ragaz',
      'Baden',
      'Bachenbülach',
      'Bäch',
      'Baar',
      'Ayent',
      'Avry-sur-Matran',
      'Avenches',
      'Auw',
      'Auvernier',
      'Zürich (Kreis 4) / Aussersihl',
      'Aubonne',
      'Au',
      'Attiswil',
      'Attinghausen',
      'Attalens',
      'Ascona',
      'Arzo',
      'Arzier',
      'Arvigo',
      'Arth',
      'Arosa',
      'Arlesheim',
      'Aristau',
      'Arisdorf',
      'Ardon',
      'Arch',
      'Arbon',
      'Apples',
      'Appenzell',
      'Anières',
      'Andwil',
      'Andermatt',
      'Amriswil',
      'Amden',
      'Altstätten',
      'Altnau',
      'Altishofen',
      'Alterswil',
      'Altendorf',
      'Altdorf',
      'Alpnach',
      'Allschwil',
      'Alle',
      'Airolo',
      'Aigle',
      'Agno',
      'Affoltern am Albis',
      'Affeltrangen',
      'Aeschi b. Spiez',
      'Aesch',
      'Adliswil',
      'Adligenswil',
      'Adelboden',
      'Acquarossa',
      'Aarwangen',
      'Aarburg',
      'Aarberg',
      'Aarau',
      'Aadorf',
      'Riehen',
      'Chêne-Bougeries',
      'Meinier',
      'Puplinge',
      'Thônex',
      'Wettswil / Wettswil (Dorf)',
      'Sellenbüren',
      'Itingen',
      'Affoltern / Hasenbüel',
      'Affoltern / Oberdorf',
      'Affoltern / Unterdorf',
      'Affoltern / Sonnenberg',
      'Schachen',
      'Hedingen',
      'Obfelden / Oberlunnern',
      'Obfelden / Toussen',
      'Wettswil / Ausser-Dorf',
      'Kleinandelfingen',
      'Bülach / Soligänter',
      'Bülach / Gstückt',
      'Bülach / Seematt',
      'Dietlikon / Eichwiesen',
      'Embrach / Embrach (Dorfkern)',
      'Embrach / Kellersacker',
      'Freienstein',
      'Hochfelden',
      'Höri',
      'Kloten / Kloten (Zentrum)',
      'Kloten / Freienberg (Chanzler-Chlini Chaseren)',
      'Kloten / Horainli',
      'Kloten / Geissberg',
      'Kloten / Hostrass',
      'Kloten / Holberg',
      'Kloten / Balsberg',
      'Kloten / Spitz',
      'Kloten / Rütlen',
      'Birchwil',
      'Glattbrugg / Wydacker/Bettacker/Lättenwiesen',
      'Grossacker/Opfikon',
      'Glattbrugg / Rohr/Platten-Balsberg',
      'Wallisellen / Wallisellen-West',
      'Wallisellen / Wallisellen-Ost',
      'Wallisellen / Rieden',
      'Dällikon / Dällikon (Dorf)',
      'Neerach',
      'Niederglatt / Niederglatt (Dorfkern)',
      'Niederhasli',
      'Oberglatt / Oberglatt (Dorfkern)',
      'Oberglatt / Bahnhofquartier',
      'Hofstetten',
      'Sonnhalde',
      'Regensdorf / Hofacher-Geeren',
      'Regensdorf / Feldblumen-Riedthofstrasse',
      'Regensdorf / Obstgarten',
      'Rümlang / Rümlang (Dorfkern)',
      'Tann / Tann (Dorfkern)',
      'Grüt',
      'Binzikon',
      'Hadlikon',
      'Rüti / Westlicher Dorfteil',
      'Rüti / Dorfzentrum, Südl. Teil',
      'Rüti / Oberdorf',
      'Wetzikon / Robenhausen',
      'Wetzikon / Kempten',
      'Adliswil / Adliswil (Stadtkern)',
      'Adliswil / Oberleimbach',
      'Adliswil / Sood',
      'Adliswil / Hündli-Zopf',
      'Adliswil / Sonnenberg',
      'Adliswil / Tal',
      'Horgen / Horgen (Dorfkern)',
      'Horgen / Allmend',
      'Horgen / Scheller-Stockerstrasse',
      'Horgen / Oberdorf',
      'Kilchberg / Bächler-Stocken',
      'Kilchberg / Kilchberg (Dorfkern)',
      'Langnau / Vitaquartier',
      'Mühlehalde',
      'Oberrieden / Mitte',
      'Oberrieden / Berg',
      'Richterswil / Dorfkern',
      'Richterswil / Richterswil (Dorfkern)',
      'Richterswil / Burghalde',
      'Thalwil / Dorfkern',
      'Thalwil / Berg',
      'Thalwil / Nord',
      'Thalwil / Süd',
      'Gattikon',
      'Wädenswil / Dorf (Wädenswil)',
      'Au / Unter-Dorf',
      'Au / Mittel-Dorf',
      'Wädenswil / Hangenmoos',
      'Wädenswil / Büelen',
      'Wädenswil / Untermosen-Fuhr',
      'Wädenswil / Leihof-Mühlebach',
      'Wädenswil / Eichweid',
      'Wädenswil / Boller-Giessen',
      'Erlenbach / rechts des Dorfbachs oberhalb Bahnlinie',
      'Erlenbach / links des Dorfbachs oberhalb Bahnlinie',
      'Küsnacht / Dorf',
      'Küsnacht / Goldbach',
      'Küsnacht / Itschnach',
      'Küsnacht / Schiedhalden',
      'Küsnacht / Heslibach',
      'Männedorf / Dorfkern',
      'Männedorf / Ausserfeld',
      'Dorf',
      'Uetikon / Grossdorf',
      'Zollikerberg',
      'Effretikon / Watt',
      'Effretikon / Rappenhalde-Bannhalde',
      'Tagelswangen',
      'Pfäffikon / Pfäffikon (Dorfkern)',
      'Dübendorf / Vogelquartier',
      'Dübendorf / Wasserfurren',
      'Dübendorf / Sonnenberg',
      'Dübendorf / Kunklerstrasse',
      'Hinteregg',
      'Esslingen',
      'Benglen',
      'Greifensee / Müllerwis / Seilerwis',
      'Greifensee / Pfisterhölzli',
      'Binz',
      'Mönchaltorf / Dorf',
      'Schwerzenbach / Chimli',
      'Schwerzenbach / Blatten',
      'Uster / Kirch-Uster',
      'Uster / Nieder-Uster',
      'Uster / Gschwader',
      'Uster / Ober-Uster',
      'Uster / Nossikon',
      'Nänikon / Nänikon (Dorfkern)',
      'Kindhausen / Kindhausen (Dorf)',
      'Hegnau / Sunnebüel-Eich',
      'Hegnau / Dammboden-Grindel',
      'Gutenswil',
      'Elgg / Städtchen und Umgebung',
      'Seuzach Dorf / Breite-Weid',
      'Stadt Winterthur (Kreis 1) / Altstadt',
      'Stadt Winterthur (Kreis 1) / Lind',
      'Stadt Winterthur (Kreis 1) / Heiligberg',
      'Stadt Winterthur (Kreis 1) / Tössfeld',
      'Stadt Winterthur (Kreis 1) / Brühlberg',
      'Stadt Winterthur (Kreis 1) / Neuwiesen',
      'Oberwinterthur (Kreis 2) / Talacker',
      'Oberwinterthur (Kreis 2) / Guggenbühl',
      'Oberwinterthur (Kreis 2) / Zinzikon',
      'Seen (Kreis 3) / Waser',
      'Seen (Kreis 3) / Büelwiesen',
      'Seen (Kreis 3) / Waldegg',
      'Seen (Kreis 3) / Ganzenbühl',
      'Seen (Kreis 3) / Oberseen',
      'Töss (Kreis 4) / Vorder-Dättnau',
      'Töss (Kreis 4) / Schlosstal',
      'Töss (Kreis 4) / Eichliacker',
      'Veltheim (Kreis 5) / Rosenberg',
      'Veltheim (Kreis 5) / Blumenau',
      'Wülflingen (Kreis 6) / Oberfeld',
      'Wülflingen (Kreis 6) / Lindenplatz',
      'Wülflingen (Kreis 6) / Niederfeld',
      'Wülflingen (Kreis 6) / Härti',
      'Mattenbach (Kreis 7) / Deutweg',
      'Mattenbach (Kreis 7) / Gutschick',
      'Mattenbach (Kreis 7) / Endliker',
      'Kollbrunn / Kollbrunn (Dorfkern)',
      'Dietikon / Vorstadt',
      'Dietikon / Almend',
      'Dietikon / Oberdorf',
      'Dietikon / Kreuzacker',
      'Dietikon / Guggenbühl',
      'Dietikon / Hofacker',
      'Dietikon / Schönenwerd',
      'Oberengstringen / Zentrum',
      'Oberengstringen / Sonnenberg',
      'Oberengstringen / Rauchacher',
      'Schlieren / Zentrum',
      'Schlieren / Engstringerquartier',
      'Schlieren / Spital',
      'Schlieren / Freiestrasse',
      'Schlieren / Kamp',
      'Schlieren / Boden',
      'Urdorf / Bodenfeld',
      'Urdorf / Moos',
      'Urdorf / Oberurdorf',
      'Zürich (Kreis 12) / Auzelg',
      'Zürich (Kreis 11) / Schwandenholz',
      'Zürich (Kreis 2) / Mittel-Leimbach',
      'Zürich (Kreis 2) / Unter-Leimbach',
      'Zürich (Kreis 10) / Wipkingen',
      'Zürich (Kreis 10) / Rütihof',
      'Zürich (Kreis 1) / Rathaus',
      'Zürich (Kreis 11) / Affoltern',
      'Zürich (Kreis 12) / Saatlen',
      'Zürich (Kreis 12) / Schwamendingen-Mitte',
      'Zürich (Kreis 12) / Hirzenbach',
      'Zürich (Kreis 1) / Lindenhof',
      'Zürich (Kreis 1) / City',
      'Zürich (Kreis 2) / Wollishofen',
      'Zürich (Kreis 2) / Enge',
      'Zürich (Kreis 3) / Friesenberg',
      'Zürich (Kreis 3) / Sihlfeld',
      'Zürich (Kreis 4) / Werd',
      'Zürich (Kreis 4) / Langstrasse',
      'Zürich (Kreis 4) / Hard',
      'Zürich (Kreis 5) / Gewerbeschule',
      'Zürich (Kreis 5) / Escher-Wyss',
      'Zürich (Kreis 6) / Unterstrass',
      'Zürich (Kreis 6) / Oberstrass',
      'Zürich (Kreis 7) / Fluntern',
      'Zürich (Kreis 7) / Hottingen',
      'Zürich (Kreis 7) / Hirslanden',
      'Zürich (Kreis 8) / Seefeld',
      'Zürich (Kreis 8) / Mühlebach',
      'Zürich (Kreis 8) / Weinegg',
      'Zürich (Kreis 9) / Albisrieden',
      'Zürich (Kreis 9) / Altstetten',
      'Schwerzenbach',
      'Steinmaur',
      'Hegnau',
      'Gockhausen',
      'Stadt Winterthur (Kreis 1)',
      'Zürich (Kreis 5)',
      'Dänikon',
      'Zürich (Kreis 12)',
      'Wülflingen (Kreis 6)',
      'Seglingen',
      'Niederglatt',
      'Ebmatingen',
      'Seen (Kreis 3)',
      'Zürich (Kreis 3)',
      'Zürich (Kreis 11)',
      'Zürich (Kreis 9)',
      'Oberwinterthur (Kreis 2)',
      'Geroldswil',
      'Zürich (Kreis 10)',
      'Zürich (Kreis 2)',
      'Zürich (Kreis 8)',
      'Wolfhausen',
      'Seuzach Dorf',
      'Zürich (Kreis 1)',
      'Mattenbach (Kreis 7)',
      'Zürich (Kreis 7)',
      'Obermeilen',
      'Zürich (Kreis 6)',
      'Elsau-Räterschen',
      'Thalwil / See',
      'Rottenschwil',
      'Thal',
      'Préverenges',
      'Langnau am Albis',
      'Langendorf',
      'Troinex',
      'Lancy',
      'Muralto',
      'Münchenwiler',
      'Stetten',
      'Löhningen',
      'Lauffohr (Brugg)',
      'Jongny',
      'Haag (Rheintal)',
      'Givisiez',
      'Aire-la-Ville',
      'Hausen',
      'Veyras',
      'Känerkinden',
      'Oberurnen',
      'Saint Saphorin',
      'Cuarnens',
      'Les Avanchets',
      'Bünzen',
      'Biberstein',
      'Braggio',
      'Saint-George',
      'Saubraz',
      'Berolle',
      'Mauraz',
      'Chavannes-le-Veyron',
      'La Chaux',
      'Chevilly',
      'Moiry',
      'Ferreyres',
      'Orny',
      'Pompaples',
      'Penthéréaz',
      'Vuarrens',
      'Rueyres',
      'Bas-Vully',
      'Marin-Epagnier',
      'Tägertschi',
      'Oberrohrdorf',
      'Kirchleerau',
      'Matran',
      'Blécherette',
      'Oberhof',
      'Besenbüren',
      'Tenniken',
      'Gansingen',
      'Vuisternens-devant-Romont',
      'Wettswil',
      'Oberkulm',
      'Savosa',
      'Aeugst am Albis',
      'Pont-la-Ville',
      'Servion',
      'Chéserex',
      'Hohentannen',
      'Valeyres-sous-Montagny',
      'Bedigliora',
      'Uebeschi',
      'Kleinbösingen',
      'Niedermuhlern',
      'Cheseaux-Noréaz',
      'Rochefort',
      'Corcelles-le-Jorat',
      'Rüegsau',
      'Auswil',
      'Rümligen',
      'Donat',
      'Surpierre',
      'Oberhünigen',
      'Les Clées',
      'Busswil bei Melchnau',
      'Walliswil bei Niederbipp',
      'Rüti bei Lyssach',
    ],
  },
  {
    country: 'Ivory Coast',
    cities: [
      'Zuénoula',
      'Zoukougbeu',
      'Zouan-Hounien',
      'Yamoussoukro',
      'Yakassé-Attobrou',
      'Ouaninou',
      'Vavoua',
      'Transua',
      'Toumodi',
      'Toulépleu Gueré',
      'Toulépleu',
      'Touba',
      'Tengréla',
      'Tiébissou',
      'Tiassalé',
      'Téhini',
      'Tanda',
      'Taï',
      'Tabou',
      'Sipilou',
      'Sinfra',
      'Sinématiali',
      'Sikensi',
      'Séguélon',
      'Sassandra',
      'San-Pédro',
      'Sandégué',
      'Samatiguila',
      'Sakassou',
      'Prikro',
      'Oumé',
      'Ouangolodougou',
      'Odienné',
      'Niakaramandougou',
      'Nassian',
      'M’Bengué',
      'M’Batto',
      'M’Bahiakro',
      'Mankono',
      'Minignan',
      'Man',
      'Madinani',
      'Lakota',
      'Kouto',
      'Kounahiri',
      'Koun-Fao',
      'Kouibly',
      'Koro',
      'Korhogo',
      'Kong',
      'Kouassi-Kouassikro',
      'Katiola',
      'Kaniasso',
      'Kani',
      'Jacqueville',
      'Issia',
      'Guitry',
      'Guiglo',
      'Guibéroua',
      'Guéyo',
      'Grand-Lahou',
      'Grand-Bassam',
      'Affery',
      'Gbéléban',
      'Gagnoa',
      'Fresco',
      'Ferkessédougou',
      'Facobly',
      'Dianra',
      'Duekoué',
      'Divo',
      'Dimbokro',
      'Dikodougou',
      'Djékanou',
      'Didiévi',
      'Daoukro',
      'Danané',
      'Daloa',
      'Dabou',
      'Dabakala',
      'Buyo',
      'Boundiali',
      'Bouna',
      'Bouaké',
      'Bouaflé',
      'Botro',
      'Bonoua',
      'Bongouanou',
      'Bondoukou',
      'Bocanda',
      'Bloléquin',
      'Bingerville',
      'Biankouma',
      'Béttié',
      'Béoumi',
      'Bangolo',
      'Ayamé',
      'Attiégouakro',
      'Arrah',
      'Anyama',
      'Alépé',
      'Akoupé',
      'Agnibilékrou',
      'Agboville',
      'Adzopé',
      'Adiaké',
      'Aboisso',
      'Abobo',
      'Abidjan',
      'Abengourou',
      'Séguéla',
      'Soubré',
      'Tiapoum',
      'Taabo',
    ],
  },
  { country: 'Cook Islands', cities: ['Avarua'] },
  {
    country: 'Chile',
    cities: [
      'Yumbel',
      'Viña del Mar',
      'Villarrica',
      'Villa Alemana',
      'Vilcún',
      'Vicuña',
      'Victoria',
      'Valparaíso',
      'Vallenar',
      'Valdivia',
      'Traiguén',
      'Tomé',
      'Tocopilla',
      'Teno',
      'Temuco',
      'Taltal',
      'Talcahuano',
      'Talca',
      'Talagante',
      'San Vicente de Tagua Tagua',
      'San Vicente',
      'Santiago',
      'Santa Cruz',
      'San Pedro de Atacama',
      'San Javier',
      'San Felipe',
      'San Clemente',
      'San Carlos',
      'San Bernardo',
      'San Antonio',
      'Salamanca',
      'Río Bueno',
      'Rengo',
      'Rauco',
      'Rancagua',
      'Quirihue',
      'Quilpué',
      'Quillota',
      'Puyehue',
      'Putre',
      'Purranque',
      'Punta Arenas',
      'Puerto Williams',
      'Puerto Varas',
      'Quellón',
      'Puerto Natales',
      'Puerto Montt',
      'Puerto Cisnes',
      'Puerto Aysén',
      'Puente Alto',
      'Pucón',
      'Providencia',
      'Pozo Almonte',
      'Porvenir',
      'Pitrufquén',
      'Pica',
      'Penco',
      'Peñaflor',
      'Parral',
      'Panguipulli',
      'Palena',
      'Paine',
      'Ovalle',
      'Osorno',
      'Ollagüe',
      'María Elena',
      'Nueva Imperial',
      'Nacimiento',
      'Mulchén',
      'Monte Patria',
      'Molina',
      'Melipilla',
      'Mejillones',
      'Machalí',
      'Lota',
      'Los Ángeles',
      'Los Andes',
      'Longaví',
      'Loncoche',
      'Llaillay',
      'Linares',
      'Limache',
      'Lebu',
      'Lautaro',
      'La Unión',
      'La Tirana',
      'La Serena',
      'Lampa',
      'La Ligua',
      'Laja',
      'La Ensenada',
      'Iquique',
      'Illapel',
      'Huara',
      'Hacienda La Calera',
      'Graneros',
      'Futaleufú',
      'Freire',
      'El Tabo',
      'El Monte',
      'Diego de Almagro',
      'Dalcahue',
      'Cuya',
      'Curicó',
      'Curanilahue',
      'Corral',
      'Coronel',
      'Coquimbo',
      'Copiapó',
      'Constitución',
      'Concepción',
      'Collipulli',
      'Colina',
      'Colchane',
      'Colbún',
      'Coihueco',
      'Coyhaique',
      'Cochrane',
      'Chonchi',
      'Chimbarongo',
      'Chillán',
      'Chile Chico',
      'Chiguayante',
      'Chicureo Abajo',
      'Chañaral',
      'Chaitén',
      'Cavancha',
      'Cauquenes',
      'Castro',
      'Cartagena',
      'Carahue',
      'Cañete',
      'Camiña',
      'Cámeron',
      'Calbuco',
      'Calama',
      'Cabrero',
      'Bulnes',
      'Buin',
      'Baquedano',
      'Arica',
      'Arauco',
      'Antofagasta',
      'Angol',
      'Ancud',
      'Alto Hospicio',
      'Puerto Chacabuco',
      'La Junta',
      'Hanga Roa',
      'Las Gaviotas',
      'Las Animas',
      'La Pintana',
      'Lo Prado',
      'Villa Presidente Frei',
      'Barrio Industrial',
      'Puerto',
      'Arturo Prat',
      'Hospital',
      'Parque Balmaceda',
      'Goméz Carreño',
      'Playa Brava',
      'Caupolicán',
    ],
  },
  {
    country: 'Cameroon',
    cities: [
      'Yoko',
      'Yokadouma',
      'Yaoundé',
      'Yagoua',
      'Yabassi',
      'Wum',
      'Tonga',
      'Tiko',
      'Tignère',
      'Tibati',
      'Tcholliré',
      'Sangmélima',
      'Saa',
      'Rey Bouba',
      'Poli',
      'Pitoa',
      'Penja',
      'Ombésa',
      'Okola',
      'Okoa',
      'Obala',
      'Ntui',
      'Nkoteng',
      'Nkongsamba',
      'Njinikom',
      'Nguti',
      'Ngoro',
      'Ngomedzap',
      'Ngaoundéré',
      'Ngambé',
      'Ndikiniméki',
      'Ndelele',
      'Nanga Eboko',
      'Mvangué',
      'Muyuka',
      'Mutengene',
      'Mundemba',
      'Mouanko',
      'Mora',
      'Mokolo',
      'Mme-Bafumen',
      'Minta',
      'Mindif',
      'Melong',
      'Meïganga',
      'Mbouda',
      'Mbengwi',
      'Mbankomo',
      'Mbanga',
      'Mbang',
      'Mbandjok',
      'Mbalmayo',
      'Maroua',
      'Manjo',
      'Mamfe',
      'Makary',
      'Loum',
      'Lolodorf',
      'Limbe',
      'Lagdo',
      'Kumbo',
      'Kumba',
      'Kribi',
      'Koza',
      'Kousséri',
      'Kontcha',
      'Bankim',
      'Kaélé',
      'Jakiri',
      'Guider',
      'Garoua Boulaï',
      'Garoua',
      'Fundong',
      'Foumbot',
      'Foumban',
      'Fontem',
      'Évodoula',
      'Essé',
      'Eséka',
      'Edéa',
      'Ébolowa',
      'Dschang',
      'Doumé',
      'Douala',
      'Djohong',
      'Dizangué',
      'Dimako',
      'Dibombari',
      'Diang',
      'Buea',
      'Bonabéri',
      'Bogo',
      'Bétaré Oya',
      'Bertoua',
      'Belo',
      'Bélel',
      'Bélabo',
      'Bekondo',
      'Bazou',
      'Batouri',
      'Batibo',
      'Banyo',
      'Bansoa',
      'Ngou',
      'Bangangté',
      'Bandjoun',
      'Bana',
      'Bamusso',
      'Bamendjou',
      'Bamenda',
      'Bali',
      'Bafoussam',
      'Bafia',
      'Bafang',
      'Babanki',
      'Ambam',
      'Akonolinga',
      'Akono',
      'Akom II',
      'Abong Mbang',
      'Ndom',
      'Somié',
    ],
  },
  {
    country: 'China',
    cities: [
      'Zongga',
      'Zito',
      'Zhowagoin',
      'Zhefang',
      'Zhedao',
      'Zhangfeng',
      'Zhamog',
      'Zhadong',
      'Zepu',
      'Zangguy',
      'Zaindainxoi',
      'Zibihu',
      'Yopurga',
      'Yingpan',
      'Yeniugou',
      'Yanduo',
      'Qaghiliq',
      'Xuelin',
      'Xireg',
      'Xinyuan',
      'Rikaze',
      'Xêgar',
      'Xarsingma',
      'Xangda',
      'Ulughchat',
      'Wendong',
      'Waxxari',
      'Wangda',
      'Tungdor',
      'Darya Boyi',
      'Toding',
      'Tengyue',
      'Tashqorghan',
      'Jiuquan',
      'Songmai',
      'Shule',
      'Shiquanhe',
      'Shimen',
      'Shigu',
      'Dianyang',
      'Shengping',
      'Shangyun',
      'Shangpa',
      'Shache',
      'Segong',
      'Sajia',
      'Rutog',
      'Ruoqiang',
      'Ruidian',
      'Racaka',
      'Quxar',
      'Qushi',
      'Qümong',
      'Qira',
      'Qiemo',
      'Qarek',
      'Qiangqinxue',
      'Qamdo',
      'Jiajuedibu',
      'Qagan Us',
      'Pubu',
      'Guma Baziri',
      'Yingjiang',
      'Kequ',
      'Pagnag',
      'Oytograk',
      'Luobuqiongzi',
      'Ngamring',
      'Geji',
      'Nangxian',
      'Namling',
      'Nagqu',
      'Qaraqash',
      'Niya',
      'Mengsuo',
      'Mengmeng',
      'Mengmao',
      'Menglang',
      'Mengdong',
      'Motuo',
      'Mekit',
      'Mangshi',
      'Maindong',
      'Machali',
      'Luzhang',
      'Luring',
      'Lop',
      'Longshan',
      'Liuku',
      'Lhasa',
      'Laojunmiao',
      'Bonan',
      'Langru',
      'Kokyar',
      'Kashgar',
      'Karaki',
      'Kaqun',
      'Kangmar',
      'Judian',
      'Jomda',
      'Jinta',
      'Jinhua',
      'Jinding',
      'Jietou',
      'Jiayuguan',
      'Jiashi',
      'Houqiao',
      'Hotan',
      'Hongwansi',
      'Heshun',
      'Gyigang',
      'Jiangzi',
      'Gyangkar',
      'Gyamotang',
      'Gudong',
      'Golmud',
      'Gengqing',
      'Gengma',
      'Jiding',
      'Gartog',
      'Gaotai Chengguanzhen',
      'Kambaxoi',
      'Nansan',
      'Fengshan',
      'Elixku',
      'Domartang',
      'Dêqên',
      'Delingha',
      'Dedang',
      'Dartang',
      'Dangchengwan',
      'Chongdui',
      'Coka',
      'Cikai',
      'Youdian',
      'Burang',
      'Molo',
      'Langgar',
      'Baoshan',
      'Baohe',
      'Baima',
      'Aqqan',
      'Atush',
      'Layka',
      'Saybag',
      'Arix',
      'Xambabazar',
      'Yengibag',
      'Zhaosu',
      'Yumen',
      'Yuli',
      'Yuanquan',
      'Yining',
      'Yanqi',
      'Xinhe',
      'Shayar',
      'Wushi',
      'Onsu',
      'Wusu',
      'Ürümqi',
      'Urho',
      'Turpan',
      'Toli',
      'Toksun',
      'Temeke',
      'Tekes',
      'Tacheng',
      'Shihezi',
      'Shanshan',
      'Sandaohezi',
      'Qongkur',
      'Qinghe',
      'Qapqal',
      'Qahurt',
      'Nilka',
      'Luntai',
      'Kuytun',
      'Kürti',
      'Kuqa',
      'Korla',
      'Hom',
      'Koktokay',
      'Karamay',
      'Kalpin',
      'Jimsar',
      'Jelilyüzi',
      'Yili',
      'Khutubi',
      'Hoxut',
      'Hoxtolgay',
      'Hejing',
      'Hami',
      'Qaba',
      'Tokkuztara',
      'Fuyun',
      'Fukang',
      'Emin',
      'Dushanzi',
      'Dure',
      'Dunhuang',
      'Dulayti',
      'Dabancheng',
      'Changji',
      'Burqin',
      'Bohu',
      'Barkol',
      'Baijiantan',
      'Baicheng',
      'Aykol',
      'Awat',
      'Aral',
      'Altay',
      'Aksu',
      'Aqchi',
      'Shuimogou',
      'Sishilichengzi',
      'Topterek',
      'Oymak',
      'Egiztobe',
      'Kostik',
      'Alakak',
      'Turgun',
      'Kax',
      'Baytokay',
      'Yengitam',
      'Dongmazar',
      'Kipekyüzi',
      'Dadamtu',
      'Qulukay',
      'Yuqunweng',
      'Panjim',
      'Xindu',
      'Beigang',
      'Liangji',
      'Lanshan',
      'Qing’an',
      'Malingshan',
      'Chenlou',
      'Hegou',
      'Gangshang',
      'Zouzhuang',
      'Tangzhang',
      'Hanwang',
      'Yitang',
      'Xuzhuang',
      'Nianzhuang',
      'Tashan',
      'Zizhuang',
      'Liuji',
      'Jiangzhuang',
      'Daizhuang',
      'Zhangzhuang',
      'Xinglou',
      'Huzhai',
      'Heqiao',
      'Fanlou',
      'Hekou',
      'Songlou',
      'Sunlou',
      'Zhuzhai',
      'Zhaozhuang',
      'Anguo',
      'Shizhai',
      'Youyupu',
      'Zhujiachang',
      'Xujiaba',
      'Qinglong',
      'Sanchahe',
      'Guandou',
      'Yushan',
      'Yaxing',
      'Xianshui',
      'Lintan Chengguanzhen',
      'Mengjin Chengguanzhen',
      'Liaoyang',
      'Zunyi',
      'Zuitou',
      'Zuitai',
      'Zoucheng',
      'Zongyang',
      'Nima',
      'Ziyang Chengguanzhen',
      'Ziyang',
      'Tangdong',
      'Wenchang',
      'Zitong',
      'Zishui',
      'Ziketan',
      'Zigong',
      'Zhuzhoujiang Miaozuxiang',
      'Zhuzhou',
      'Zhuyuan',
      'Zhuyang',
      'Zhuxi Chengguanzhen',
      'Zhuxi',
      'Zhushan Chengguanzhen',
      'Jiahe Chengguanzhen',
      'Zhuqi',
      'Zhuoyang',
      'Zhuoshui',
      'Zhumadian',
      'Jiangpu',
      'Zhujiajiao',
      'Shangqiu',
      'Zhuji',
      'Zhouqu Chengguanzhen',
      'Zhushi',
      'Zhucheng',
      'Zhuangtou',
      'Zhuanghe',
      'Zhouzhuang',
      'Zhouxi',
      'Zhoukou',
      'Zhoujia',
      'Zhoucun',
      'Yuxi',
      'Zhongzhou',
      'Zhongzhai',
      'Ningxiang',
      'Zhongxinqiao',
      'Zhongxing',
      'Zhongxin',
      'Zhongwei',
      'Zhongshu',
      'Zhongshan',
      'Jinzhong',
      'Ning’an',
      'Zhongmu Chengguanzhen',
      'Kaijiang',
      'Zhonghe',
      'Zhongfang',
      'Zhongduo',
      'Zhongdeng',
      'Gusong',
      'Zhongchao',
      'Zhongba',
      'Zhong’ao',
      'Zhong’an',
      'Zhijin',
      'Zhijiang',
      'Zhigou',
      'Zhifang',
      'Zhicheng',
      'Zhenzhou',
      'Zhenyuan Chengguanzhen',
      'Wufeng',
      'Zhenxi',
      'Zhenping Chengguanzhen',
      'Zhenjiang',
      'Zhaobaoshan',
      'Zhenhaicun',
      'Zhengzhou',
      'Zhenyang',
      'Zhengxing',
      'Zhengji',
      'Zhengding',
      'Zhelou',
      'Zhecheng Chengguanzhen',
      'Zhaxi',
      'Qianyou',
      'Zhaozhen',
      'Xinghua',
      'Zhaotong',
      'Zhaoren',
      'Zhaoqing',
      'Zhaoguli',
      'Zhaogezhuang',
      'Chaoge',
      'Zhaodun',
      'Zhaoyuan',
      'Xiping',
      'Zhanjiang',
      'Zhanhe',
      'Danzhu',
      'Zhangzhou',
      'Zhangzhai',
      'Zhangye',
      'Zhangwan',
      'Zhangjiapan',
      'Zhangjiachuan',
      'Zhangji',
      'Zhangguizhuang',
      'Zhanggu',
      'Zibo',
      'Anyang',
      'Yongtai',
      'Zhan’erxiang',
      'Zhancheng',
      'Zhaishi Miaozu Dongzuxiang',
      'Zhabei',
      'Zengjia',
      'Zaozhuang',
      'Zaoyang',
      'Zaojiacheng',
      'Zhamashi',
      'Zagunao',
      'Yuzhong Chengguanzhen',
      'Yuyue',
      'Yuyao',
      'Xiushui',
      'Yingchuan',
      'Yuxia',
      'Yutian',
      'Jicheng',
      'Kunshan',
      'Yuping',
      'Yunyang',
      'Junyang',
      'Yunxian Chengguanzhen',
      'Aihua',
      'Yunxixian Chengguanzhen',
      'Yunxi',
      'Yuntai',
      'Yunshan',
      'Yunnanyi',
      'Yunmeng Chengguanzhen',
      'Yunmen',
      'Yunling',
      'Jinghong',
      'Yunjin',
      'Pizhou',
      'Yunhe',
      'Yunfu',
      'Yuncheng',
      'Yulinshi',
      'Yulin',
      'Yulei',
      'Yujin',
      'Wulingyuan',
      'Yujia',
      'Yuguzhuang',
      'Yueyang',
      'Yuexi',
      'Yuecheng',
      'Yuelai',
      'Jiulong',
      'Yudong',
      'Yuci',
      'Yucheng',
      'Yuanyang',
      'Huaiyang',
      'Yuanshang',
      'Yuanshan',
      'Yuanping',
      'Yuanmou',
      'Yuanling',
      'Qianjiang',
      'Yuanjiazhuang',
      'Heyuan',
      'Yuanba',
      'Youxian Chengguanzhen',
      'Youxi',
      'Youting',
      'Youguzhuang',
      'Yongzhou',
      'Yongxing',
      'Yongxing Chengguanzhen',
      'Yongxin',
      'Yongqing',
      'Yongning',
      'Yongjia',
      'Yonghe',
      'Zhihe',
      'Yongfu',
      'Yongfeng',
      'Yongding',
      'Yongdeng Chengguanzhen',
      'Yongcong',
      'Yongchun',
      'Yongchang Chengguanzhen',
      'Yongchuan',
      'Yongbei',
      'Yong’an',
      'Yizhuang',
      'Yi Xian',
      'Yizhang Chengguanzhen',
      'Yiyang Chengguanzhen',
      'Yitiaoshan',
      'Yisuhe',
      'Yishui',
      'Yishi',
      'Yishan',
      'Yisa',
      'Yinying',
      'Yinma',
      'Yinliu',
      'Eling',
      'Zhongxiang',
      'Yingxian',
      'Yingtan',
      'Yingshang Chengguanzhen',
      'Yinggen',
      'Yingdu',
      'Yingcheng',
      'Chengzhong',
      'Yinchuan',
      'Yima',
      'Yijing',
      'Yigou',
      'Qingzhou',
      'Yichun',
      'Yichuan Chengguanzhen',
      'Tangxing',
      'Yicheng',
      'Yixing',
      'Yichang',
      'Yibin',
      'Yezhou',
      'Yeyuan',
      'Kunyang',
      'Yeliguan',
      'Yatunpu',
      'Yatou',
      'Yashan',
      'Yaozhou',
      'Yaowan',
      'Yaoshi',
      'Yaoji',
      'Yaohua',
      'Yaodu',
      'Yanzibu',
      'Yanzhou',
      'Yantian',
      'Yantan',
      'Yantai',
      'Yansi',
      'Yanshan',
      'Yanmen',
      'Anling',
      'Yanling',
      'Yanliang',
      'Yankou',
      'Yanjing',
      'Yanjin Chengguanzhen',
      'Heyou',
      'Yanjia',
      'Yangshi',
      'Yangzhou',
      'Yangzhong',
      'Yangxin',
      'Yuanyang Chengguanzhen',
      'Yanguan',
      'Yangtun',
      'Yangtou',
      'Yangshuo',
      'Zhangjiagang',
      'Yangsha',
      'Yangri',
      'Yangquan',
      'Yangpu',
      'Yangliuqing',
      'Yangliujie',
      'Yangling',
      'Yangjinzhuang',
      'Yangjiapo',
      'Yangjiao',
      'Yangdian',
      'Yanghou',
      'Yanghe',
      'Yanggu',
      'Yangezhuang',
      'Yangcun',
      'Yangcheng',
      'Yandong',
      'Huamachi',
      'Qihe',
      'Yancheng Chengguanzhen',
      'Yancheng',
      'Yan’an',
      'Yakoucun',
      'Yajiang',
      'Yacheng',
      'Yacha',
      "Ya'an",
      'Tongshan',
      'Xuyong',
      'Xuyang',
      'Xucheng',
      'Shangrao',
      'Xunyi Chengguanzhen',
      'Xunyang',
      'Xun Xian Chengguanzhen',
      'Xunsi',
      'Xunchang',
      'Xujiang',
      'Xuhui',
      'Funan Chengguanzhen',
      'Mingxi',
      'Xuchang',
      'Xuantan',
      'Zhushan',
      'Xuanzhou',
      'Xiyan',
      'Leping',
      'Xixiang',
      'Longquan',
      'Xi Xian Chengguanzhen',
      'Zijin',
      'Xixi',
      'Xiuying',
      'Xiuwu Chengguanzhen',
      'Xiushan',
      'Xiuqi',
      'Xiulin',
      'Xiugu',
      'Xituo',
      'Xitangtuo',
      'Xishan',
      'Xisa',
      'Xiongzhou',
      'Guixi',
      'Xiongshan',
      'Xinzhou',
      'Xinzhi',
      'Xinhualu',
      'Xinzheng',
      'Xinzhan',
      'Xinzhai',
      'Xinyu',
      'Hancheng',
      'Xinyang',
      'Xinxing',
      'Xincheng',
      'Xinxiang',
      'Xinji',
      'Xintian',
      'Xintai',
      'Xinshiba',
      'Xinshi',
      'Xinshan',
      'Fusui',
      'Xinmin',
      'Xinmiao',
      'Xinmian',
      'Xinling',
      'Xinli',
      'Xinkaikou',
      'Jingxi',
      'Longxing',
      'Xining',
      'Xinhuang',
      'Shangmei',
      'Xinhua',
      'Suohe',
      'Weifen',
      'Zhongcheng',
      'Xingtai',
      'Xingren',
      'Xinglong',
      'Xingguo',
      'Ankang',
      'Xing’an',
      'Xindou',
      'Xindian',
      'Xindi',
      'Feicheng',
      'Gutian',
      'Gulü',
      'Xin’anjiang',
      'Xin’an Chengguanzhen',
      'Xinyi',
      'Xin’an',
      'Xinan',
      'Sanshui',
      'Ximeicun',
      'Xiling',
      'Xihuachi',
      'Wacheng',
      'Xihe',
      'Xigang',
      'Nanjie',
      'Xiema',
      'Xiedian',
      'Xidu',
      'Xiditou',
      'Xichang',
      'Xipu',
      'Xibing',
      'Xiazhuang',
      'Xiazhen',
      'Xiayi Chengguanzhen',
      'Yaofeng',
      'Xiawuqi',
      'Xiasi',
      'Haining',
      'Gongyi',
      'Xiaoxita',
      'Xiaoxi',
      'Xiaoweizhai',
      'Xiaoshan',
      'Xiaoshajiang',
      'Xiaomian',
      'Xiaolongmen',
      'Xiaolingwei',
      'Xiaojiang',
      'Xiaohenglong',
      'Xiaogang',
      'Xiaogan',
      'Xiaodongzhuang',
      'Xiaodian',
      'Xiaochuan',
      'Xianyang',
      'Xianxi',
      'Xiantao',
      'Xiantan',
      'Xianshuigu',
      'Xianrenwan',
      'Xiannü',
      'Xianning',
      'Xianju',
      'Xiangzhou',
      'Zhuhai',
      'Xiangcheng',
      'Guhan',
      'Wenxing',
      'Xiangxiang',
      'Xiangtan',
      'Xiangshui',
      'Menghai',
      'Changning',
      'Xiangkou',
      'Xianghu',
      'Xiangyang',
      'Xiangdong',
      'Xiangcheng Chengguanzhen',
      'Xianfeng',
      'Xiancun',
      'Xi’an',
      'Xiamen',
      'Xialiang',
      'Kajiaman',
      'Xiajin',
      'Xiajiang',
      'Xiahu',
      'Xiagezhuang',
      'Xiacang',
      'Xiabaishi',
      'Wuzhou',
      'Wuzhong',
      'Wuzhen',
      'Wuyun',
      'Wuyuan',
      'Wuyucun',
      'Wuquan',
      'Wuyang',
      'Wujin',
      'Wuxue',
      'Wuxuan',
      'Fengzhou',
      'Wushan',
      'Wuxi',
      'Wutongshu',
      'Tongxiang',
      'Taicheng',
      'Wuqiao',
      'Wuqi',
      'Dongyang',
      'Wuming',
      'Wuma',
      'Huaibin Chengguanzhen',
      'Wuling',
      'Changde',
      'Wujing',
      'Wujiashan',
      'Wujia',
      'Wuhu',
      'Wuhe Chengguanzhen',
      'Wuhan',
      'Wuhai',
      'Wugui',
      'Yakou',
      'Wugang',
      'Wudong',
      'Wuda',
      'Laocheng',
      'Wucheng',
      'Wenzhou',
      'Wenquan',
      'Wenxian Chengguanzhen',
      'Tongcheng',
      'Fengcheng',
      'Wenshui',
      'Wenshang',
      'Wenping',
      'Wenling',
      'Wenlin',
      'Wenjiang',
      'Wenheng',
      'Wenfeng',
      'Tianfu',
      'Weizhou',
      'Weiyuan',
      'Qingyuan',
      'Mingzhou',
      'Weixian',
      'Weituo',
      'Weitang',
      'Weishi Chengguanzhen',
      'Weishan',
      'Chang’an',
      'Weinan',
      'Weimiao',
      'Weiji',
      'Weihai',
      'Weifang',
      'Wayaobu',
      'Wayao',
      'Wanzhi',
      'Wanxian',
      'Wantouqiao',
      'Wanshun',
      'Wanshui',
      'Wansheng',
      'Wanshan',
      'Wanning',
      'Wanmao',
      'Wangguan',
      'Wangwu',
      'Wangu',
      'Wangqinzhuang',
      'Wangpu',
      'Leiyang',
      'Wangji',
      'Yinzhu',
      'Wangfen',
      'Wangbuzhuang',
      'Anping',
      'Wanchao',
      'Waina',
      'Wafangdian',
      'Ulan',
      'Tuzhai',
      'Tuxiang',
      'Tushan',
      'Tuokou',
      'Tuojiang',
      'Tuodian',
      'Huangshan',
      'Linjiang',
      'Tuncheng',
      'Tubu',
      'Tuanfeng',
      'Touying',
      'Tongzi',
      'Loushanguan',
      'Tongzhou',
      'Tongyang',
      'Tongxu Chengguanzhen',
      'Yuhai',
      'Tongxi',
      'Pingxiang',
      'Tongwan',
      'Fuding',
      'Tongren',
      'Tongquan',
      'Tongqian',
      'Tongling',
      'Leye',
      'Tongjing',
      'Tonggu',
      'Tongchuan',
      'Tongbai Chengguanzhen',
      'Tingjiang',
      'Tingdong',
      'Tieqiao',
      'Tiefu',
      'Tiaoshi',
      'Tianzhuang',
      'Tianzhou',
      'Tiantang',
      'Tiantai Chengguanzhen',
      'Qincheng',
      'Tianshui',
      'Tianpeng',
      'Tianliu',
      'Tianjin',
      'Wuxue Shi',
      'Tiandiba',
      'Tiandeng',
      'Tianchi',
      'Tiancheng',
      'Tianchang',
      'Chengtangcun',
      'Tengzhou',
      'Temuli',
      'Taozhuang',
      'Taozhou',
      'Taoyuan',
      'Zhangjiang',
      'Taoyang',
      'Taopingxiang',
      'Taoluo',
      'Taolin',
      'Taohuajiang',
      'Taohong',
      'Daxin',
      'Tanwan',
      'Tantou',
      'Tanjia',
      'Tangzhai',
      'Tangyang Chengguanzhen',
      'Tangwu',
      'Tangshan',
      'Tangping',
      'Tangkou',
      'Tangjiazhuang',
      'Tangjiafang',
      'Binhe',
      'Tanggu',
      'Tangdukou',
      'Tangba',
      'Tancheng',
      'Guanghe Chengguanzhen',
      'Taizhou',
      'Taiyuan',
      'Taixing',
      'Taiping',
      'Taipingchang',
      'Taikang Chengguanzhen',
      'Jinxi',
      'Shanghe',
      'Taihe Chengguanzhen',
      'Taihe',
      'Mingxing',
      'Taishan',
      'Taicang',
      'Tai’an',
      'Suzhou',
      'Suyangshan',
      'Fuyu',
      'Suqian',
      'Suozhen',
      'Suonan',
      'Suizhou',
      'Sui Xian Chengguanzhen',
      'Suixi',
      'Suicheng',
      'Suishan',
      'Quyang',
      'Suining',
      'Jianning',
      'Sui’an',
      'Songyuan',
      'Songyang',
      'Song Xian Chengguanzhen',
      'Songkan',
      'Songjiang',
      'Songcheng',
      'Songbai',
      'Siyang',
      'Shangsi',
      'Sicheng',
      'Sitou',
      'Sishui',
      'Simenqian',
      'Simen',
      'Simao',
      'Dachang Shandao',
      'Sihu',
      'Sigaozhuang',
      'Si’en',
      'Lingyun',
      'Shucheng',
      'Shuozhou',
      'Shunhua',
      'Shunhe',
      'Shuizhai',
      'Shuitu',
      'Shuitou',
      'Shuidatian',
      'Shuikou',
      'Shuijiang',
      'Laixi',
      'Shuidong',
      'Shuiche',
      'Shuibian',
      'Shucheng Chengguanzhen',
      'Shuangzhong',
      'Shuangxi',
      'Shuangshu',
      'Shuangshipu',
      'Longbo',
      'Shuanglu',
      'Shuanglong',
      'Dongsheng',
      'Shuangjiang',
      'Shuanghe',
      'Shuanghekou',
      'Shuanggou',
      'Shuanggang',
      'Zherong',
      'Chaoyang',
      'Shouchun',
      'Shouxian',
      'Shoushan',
      'Shouguang',
      'Shizuishan',
      'Shizilu',
      'Shiyan',
      'Shijiang',
      'Shixia',
      'Shiwan',
      'Shituo',
      'Shitanjing',
      'Qili',
      'Shishi',
      'Shiquan',
      'Shiqiaozi',
      'Shiqiao',
      'Shiqian',
      'Shiqi',
      'Yilong',
      'Xingye Xian',
      'Chujiang',
      'Shima',
      'Lingquan',
      'Xianglong',
      'Shilong',
      'Shilu',
      'Tongchuanshi',
      'Shilaoren Shequ',
      'Shikang',
      'Shijing',
      'Shijiazhuang',
      'Shijiao',
      'Shijia',
      'Shiji',
      'Shihui',
      'Shifang',
      'Shidui',
      'Gaolan Chengguanzhen',
      'Shichuan',
      'Zhouning',
      'Shibuzi',
      'She Xian',
      'Shetan',
      'Shedian',
      'Shenzhen',
      'Yanta',
      'Chentuan',
      'Dongcheng',
      'Shenjiamen',
      'Shanhu',
      'Shengrenjian',
      'Shazikou',
      'Shazi',
      'Shayang',
      'Yunlong',
      'Shawan',
      'Rensha',
      'Shashi',
      'Shaping',
      'Shaoxing',
      'Shaowu',
      'Shaoguan',
      'Shaodian',
      'Shanyang',
      'Shancheng',
      'Shanwei',
      'Shantou',
      'Shanting',
      'Shanmen',
      'Shankou',
      'Shanji',
      'Shanhe',
      'Shangluo',
      'Shangtang',
      'Shangpai',
      'Shangli',
      'Shangkou',
      'Jiyuanlu',
      'Shangjie',
      'Shanghang',
      'Shanghai',
      'Shangde',
      'Shangcheng Chengguanzhen',
      'Shangcang',
      'Chongyang',
      'Qingquan',
      'Taining',
      'Shaliuhe',
      'Shajiang',
      'Shaji',
      'Shahe',
      'Shahejie',
      'Shahecheng',
      'Shagang',
      'Sailaitang',
      'Seke',
      'Sanzhuang',
      'Sanyuan Chengguanzhen',
      'Sanyuan',
      'Sanya',
      'Sanshan',
      'Sanqu',
      'Sanming',
      'Sanmenxia',
      'Sanmao',
      'Sanjiaocheng',
      'Sanjiao',
      'Sanjiang',
      'Sanhui',
      'Xiaodu',
      'Sanhe',
      'Sangzi',
      'Sangzhe',
      'Sangyuan',
      'Sangmu',
      'Sangke',
      'Sanchakou',
      'Changsha',
      'Ruyang Chengguanzhen',
      'Ruoshui',
      'Rende',
      'Runing',
      'Rulong',
      'Rulin',
      'Guwei',
      'Rui’an',
      'Rucheng',
      'Rucheng Chengguanzhen',
      'Rongwo',
      'Rongshui',
      'Rongmei',
      'Ronglong',
      'Rongjiawan',
      'Rongjiang',
      'Rongcheng',
      'Fuqing',
      'Jieyang',
      'Rongxian',
      'Rizhuang',
      'Rifeng',
      'Renzhao',
      'Renyi',
      'Renxian',
      'Renqiu',
      'Renhua',
      'Renhe',
      'Renfengzhuang',
      'Quzhou',
      'Lechang',
      'Qushui',
      'Qihama',
      'Qujing',
      'Qujiang',
      'Qufu',
      'Panlong',
      'Quanzhou',
      'Quanjiang',
      'Zhaoyu',
      'Qi Xian Chengguanzhen',
      'Zhuangyuan',
      'Qiuji',
      'Qishan',
      'Qiongshan',
      'Qiongxi',
      'Qionghu',
      'Qinzhou',
      'Qinhe',
      'Tanbei',
      'Dingchang',
      'Qinting',
      'Longgang',
      'Qinnan',
      'Qinhuangdao',
      'Yizhou',
      'Jinjiang',
      'Qinglang',
      'Qingxi',
      'Qingshuping',
      'Chengguan',
      'Qingshizui',
      'Qingshanquan',
      'Qingping',
      'Qingkou',
      'Qingjiangqiao',
      "Huai'an",
      'Qinghua',
      'Qingguang',
      'Qingfu',
      'Qingfeng Chengguanzhen',
      'Qingdao',
      'Qina',
      'Qimeng',
      'Qilidian',
      'Qidu',
      'Hongqiao',
      'Qibu',
      'Qiaozhuang',
      'Qiaoxu',
      'Qiaowa',
      'Qiaotou',
      'Qiaojiang',
      'Qiaoguan',
      'Qiaogu',
      'Qianqiu',
      'Qianliu',
      'Qianling',
      'Qiancheng',
      'Qiagai',
      'Qabqa',
      'Puzi',
      'Puyang Chengguanzhen',
      'Puyang',
      'Pucheng',
      'Puting',
      'Putian',
      'Puji',
      'Puqi',
      'Pulandian',
      'Pukou',
      'Puhechang',
      'Pucheng Chengguanzhen',
      'Pu’an',
      'Poyang',
      'Potou',
      'Wangge’ertang',
      'Pitong',
      'Pisha',
      'Pingzhai',
      'Pingyuan',
      'Guhuai',
      'Pingyin',
      'Pingyi',
      'Gutao',
      'Pingtan',
      'Qingyang',
      'Pingshan',
      'Pingqiao',
      'Pingnan',
      'Tiandong',
      'Pingluo Chengguanzhen',
      'Pingluo',
      'Pingliang',
      'Pingli',
      'Pingle',
      'Pingjin',
      'Hanchang',
      'Pingjiang',
      'Pingdu',
      'Pingdingshan',
      'Guanshan',
      'Wuping',
      'Pingba',
      'Pianjiao',
      'Xinguan',
      'Longcheng',
      'Baiyang',
      'Penguanzhuang',
      'Penglai',
      'Pengcheng',
      'Peicheng',
      'Picheng',
      'Panzhuang',
      'Dadukou',
      'Panlian',
      'Panghai',
      'Panggezhuang',
      'Pandu',
      'Pan’an',
      'Paipu',
      'Nuojiang',
      'Nuanquan',
      'Guang’an',
      'Niyang',
      'Niushan',
      'Niujiapai',
      'Niudaokou',
      'Ningzhou',
      'Shunling',
      'Ningyang',
      'Yutan',
      'Xinning',
      'Fenghuang',
      'Hanyuan',
      'Ningling Chengguanzhen',
      'Ningjin',
      'Ninghe',
      'Ninghai',
      'Ning’er',
      'Ningchegu',
      'Ningbo',
      'Niangxi',
      'Nianbo',
      'Oula',
      'Neixiang Chengguanzhen',
      'Neijiang',
      'Neihuang Chengguanzhen',
      'Napo Chengxiangzhen',
      'Nanzhou',
      'Nanzhao Chengguanzhen',
      'Nanzhao',
      'Nanzhang Chengguanzhen',
      'Nanyang',
      'Nanxun',
      'Nanxi',
      'Nantong',
      'Nanshu',
      'Nansha',
      'Nanrenfu',
      'Yongle',
      'Nanping',
      'Nanpi',
      'Nanning',
      'Nanmuping',
      'Nanmaizhu',
      'Nanma',
      'Nanlong',
      'Jishan',
      'Nanliang Nongchang',
      'Nanle Chengguanzhen',
      'Nankang',
      'Nanjing',
      'Nanjiang',
      'Nanjian',
      'Nanhu',
      'Guantao',
      'Nangong',
      'Nanfeng',
      'Duji',
      'Nandu',
      'Nanding',
      'Nandan',
      'Nancun',
      'Nanchong',
      'Nanchangshan',
      'Nanchang',
      'Nancang',
      'Nancaicun',
      'Nanbin',
      'Nan’an',
      'Gaoyao',
      'Naliang',
      'Nada',
      'Muxi',
      'Mole',
      'Mu’er',
      'Mudong',
      'Mucheng',
      'Miyang',
      'Chengjiao Chengguanzhen',
      'Minquan Chengguanzhen',
      'Sanlei',
      'Hongshui',
      'Minjian',
      'Mingyu',
      'Mingtong',
      'Mingshui',
      'Mingguang',
      'Minggang',
      'Mingfeng',
      'Mingcun',
      'Min’an',
      'Min’ancun',
      'Miluo Chengguanzhen',
      'Micheng',
      'Miaoyu',
      'Jiannan',
      'Mianyang',
      'Chengxiang',
      'Mianchi Chengguanzhen',
      'Mentougou',
      'Wenlan',
      'Mengyin',
      'Mengyan',
      'Huichang',
      'Mengshan',
      'Mengquan',
      'Menglie',
      'Mengla',
      'Mengcheng Chengguanzhen',
      'Meizhou',
      'Meixing',
      'Meishan',
      'Wuchuan',
      'Meilin',
      'Meijiang',
      'Meicheng',
      'Minqing',
      'Meichang',
      'Mazhuang',
      'Mazhang',
      'Mayanhe',
      'Mawu',
      'Xinmatou',
      'Pingguo',
      'Mapo',
      'Maoping',
      'Maoming',
      'Haimen',
      'Maogong',
      'Maocun',
      'Malu',
      'Malin',
      'Malianzhuang',
      'Mali',
      'Majie',
      'Majiadian',
      'Maima',
      'Magitang',
      'Macheng',
      'Maba',
      'Ma’an',
      'Ma’anshan',
      'Ma’ai',
      'Luzhou',
      'Luzhai',
      'Luyuan',
      'Xiguan',
      'Luyang',
      'Binyang',
      'Luxia',
      'Luxi',
      'Luwan',
      'Lüshun',
      'Lushi Chengguanzhen',
      'Lushar',
      'Lushan',
      'Luqiao',
      'Luoyang',
      'Luoxiong',
      'Luoshan Chengguanzhen',
      'Luorong',
      'Luoqiaocun',
      'Luoqi',
      'Luoping',
      'Luoning Chengguanzhen',
      'Luomen',
      'Luolong',
      'Luojiu',
      'Luojiang',
      'Luohuang',
      'Luohe',
      'Luocheng',
      'Lufu',
      'Lulou',
      'Lukou',
      'Lujiao',
      'Lucheng',
      'Luhua',
      'Lufeng',
      'Lüeyang Chengguanzhen',
      'Daxing',
      'Kangding',
      'Lubu',
      'Luanzhou',
      'Luanchuan Chengguanzhen',
      'Luancheng',
      'Lu’an',
      'Loufan',
      'Loudi',
      'Longzhou',
      'Longyan',
      'Longxu',
      'Longxian Chengguanzhen',
      'Longtou’an',
      'Longtan',
      'Longshui',
      'Longshi',
      'Longsheng',
      'Longnan',
      'Longmen',
      'Longhua',
      'Longlin',
      'Longlisuo',
      'Longju',
      'Longjin',
      'Longgu',
      'Longfeng',
      'Long’e',
      'Longde Chengguanzhen',
      'Longchuan',
      'Longchang',
      'Long’an',
      'Lizi',
      'Lizhuangzi',
      'Liyuan',
      'Liyang',
      'Licheng',
      'Lixiqiao',
      'Lixin',
      'Lixin Chengguanzhen',
      'Lixian',
      'Liuzikou',
      'Liuyin',
      'Guankou',
      'Liuxin',
      'Liuwudiancun',
      'Liutuan',
      'Puning',
      'Liuquan',
      'Tian’e',
      'Liulin',
      'Liukuaizhuang',
      'Liujiaxia',
      'Liujiaping',
      'Liuduzhai',
      'Liucheng',
      'Liuba',
      'Liu’anzhuang',
      'Lishui',
      'Lishi',
      'Lintong',
      'Lishan',
      'Liquan Chengguanzhen',
      'Liuzhou',
      'Linyi',
      'Linquan',
      'Kaiyuan',
      'Linxia Chengguanzhen',
      'Linxi',
      'Linwu Chengguanzhen',
      'Lintingkou',
      'Linshui',
      'Linshi',
      'Ruzhou',
      'Linquan Chengguanzhen',
      'Linqu',
      'Linqiong',
      'Qingnian',
      'Linping',
      'Linli',
      'Linkou',
      'Linjiacun',
      'Linhai',
      'Lingcheng',
      'Xiayang',
      'Lingxi',
      'Lingwu Nongchang',
      'Dongta',
      'Cuifeng',
      'Lingjiang',
      'Linghe',
      'Chongwen',
      'Beiliu',
      'Lincheng',
      'Linfeng',
      'Linfen',
      'Lincang',
      'Lin’an',
      'Lijiang',
      'Liji',
      'Ligezhuang',
      'Lidu',
      'Lichuan',
      'Lihou',
      'Lipu',
      'Licha',
      'Liaolan',
      'Liaogao',
      'Liaocheng',
      'Lianzhou',
      'Hepu',
      'Anning',
      'Lianhecun',
      'Wuwei',
      'Liangzhai',
      'Liangyaping',
      'Liangxiang',
      'Liangshui',
      'Jiangbei',
      'Lianghekou',
      'Liangdang Chengguanzhen',
      'Liangcunchang',
      'Liancheng',
      'Lianjiang',
      'Leshan',
      'Lengshuitan',
      'Lengshuijiang',
      'Lengshuiguan',
      'Leling',
      'Tianlin',
      'Leizhou',
      'Lecheng',
      'Lazikou',
      'Laohekou',
      'Lanzhou',
      'Lanxi',
      'Lianyuan',
      'Lantian',
      'Tafeng',
      'Lanli',
      'Languan',
      'Langmusi',
      'Langfang',
      'Langao Chengguanzhen',
      'Lankao Chengguanzhen',
      'Laizhou',
      'Laiyang',
      'Laiwu',
      'Xiangfeng',
      'Laibin',
      'Labuleng',
      'Liujiang',
      'Kunming',
      'Kuangyuan',
      'Kuangshi',
      'Kou’an',
      'Kongtan',
      'Kocê',
      'Kengyuan',
      'Lanyi',
      'Fucheng',
      'Kangle',
      'Kaiping',
      'Kaili',
      'Kaihua Chengguanzhen',
      'Kaihua',
      'Kaifeng',
      'Juye',
      'Junxi',
      'Junlian',
      'Juexi',
      'Juegang',
      'Juanshui',
      'Juancheng',
      'Jiyuan',
      'Jiyang',
      'Jizhou',
      'Jichang',
      'Chengjiao',
      'Huayuan',
      'Jiuxian',
      'Jiushan',
      'Ningshan Chengguanzhen',
      'Yangjiaping',
      'Lianzhu',
      'Jiujiang',
      'Jiudian',
      'Jiuchenggong',
      'Jishui',
      'Qianzhou',
      'Jifeng',
      'Jinzhou',
      'Jinyuan',
      'Jinxiu',
      'Jinxiang',
      'Jinshiqiao',
      'Jinshi',
      'Jinshan',
      'Jinsha',
      'Jinping',
      'Jinhe',
      'Jinjing',
      'Jinji',
      'Jining',
      'Jingzhou',
      'Jingzhi',
      'Wulan',
      'Jingyang',
      'Xiluodu',
      'Jingchuan',
      'Jingui',
      'Jingping',
      'Jingning Chengguanzhen',
      'Jingmen',
      'Jingling',
      'Echeng',
      'Jingjiang',
      'Jinghai',
      'Jingguan',
      'Jinggan',
      'Jingdezhen',
      'Jingchuan Chengguanzhen',
      'Zhangping',
      'Jing’an',
      'Jin’e',
      'Jinchang',
      'Jinchuan',
      'Jincheng',
      'Jinbi',
      'Jinan',
      'Jin’an',
      'Jimo',
      'Jiming',
      'Daqinglu',
      'Jijiang',
      'Jiexiu',
      'Jieshou',
      'Jieshi',
      'Jielong',
      'Jiekou',
      'Jiehu',
      'Jiazi',
      'Jiayue',
      'Jiaxing',
      'Jiaxiang',
      'Jiaxian',
      'Jiawang',
      'Jiasi',
      'Jiaozuo',
      'Jiaoshi',
      'Jiaocheng',
      'Jiaokui',
      'Jiaogong',
      'Tianning',
      'Jiaozhou',
      'Ningde',
      'Jiancheng',
      'Jian’ou',
      'Tongzilin',
      'Jianjun',
      'Jianjiang',
      'Xiaopu',
      'Jiangyan',
      'Gujiang',
      'Jiangwakou',
      'Jianguang',
      'Jiangshi',
      'Jiangshan',
      'Jiangping',
      'Jiangna',
      'Jiangmen',
      'Jiangluo',
      'Jiangkou',
      'Jiangkouxu',
      'Jiangfang',
      'Jiangjia',
      'Gangdong',
      'Yangjiang',
      'Jiang’an Qu',
      'Jiang’an',
      'Jianchang',
      'Ji’an',
      'Jiamaying',
      'Guangyuan',
      'Qionghai',
      'Jiading',
      'Jiachuan',
      'Huzhou',
      'Ganting',
      'Hutang',
      'Cixi',
      'Huozhuangzi',
      'Huotong',
      'Huoqiu Chengguanzhen',
      'Huomachong',
      'Huolu',
      'Huojia Chengguanzhen',
      'Huogezhuang',
      'Humen',
      'Huliao',
      'Huizhou',
      'Huixian Chengguanzhen',
      'Huishi',
      'Huilong',
      'Huili Chengguanzhen',
      'Huidong',
      'Huicheng',
      'Huguo',
      'Hufeng',
      'Huazhou',
      'Huazangsi',
      'Shuanghejiedao',
      'Huayin',
      'Huayang',
      'Huashan',
      'Huarong',
      'Huarong Chengguanzhen',
      'Huaqiu',
      'Huaqiao',
      'Huantuo',
      'Huankou',
      'Huangzhuang',
      'Huangzhou',
      'Huangzhai',
      'Huangyuan Chengguanzhen',
      'Huangyangtan',
      'Huangxikou',
      'Donglai',
      'Huangtukuang',
      'Huangshi',
      'Huangqiao',
      'Dasha',
      'Huangpi',
      'Huangmei',
      'Huangmaoyuan',
      'Huanglong',
      'Huangjinjing',
      'Huangjia',
      'Huangji',
      'Huanghua',
      'Huanggang',
      'Dingcheng',
      'Huangcaotuo',
      'Xingyi',
      'Hong’an Chengguanzhen',
      'Huanfeng',
      'Huaiyuan Chengguanzhen',
      'Huaiyang Chengguanzhen',
      'Yunzhong',
      'Huainan',
      'Yanshi Chengguanzhen',
      'Huaicheng',
      'Huaihua',
      'Huaidian',
      'Huaibei',
      'Huagai',
      'Huafeng',
      'Huacheng',
      'Houzhen',
      'Houxiang',
      'Liangshan',
      'Hongzhou',
      'Hongyang',
      'Dahuaishu',
      'Hongsipu',
      'Hongkou',
      'Donghe',
      'Hongjiang',
      'Honghe',
      'Hongchuan',
      'Hezuo',
      'Hezheng Chengguanzhen',
      'Hezhang',
      'Heze',
      'Heyin',
      'Hechuan',
      'Hexiangqiao',
      'Hetoudian',
      'Hetang',
      'Jianshe',
      'Yiyang',
      'Wenbi',
      'Hepo',
      'Hepingyizu',
      'Heping',
      'Heng',
      'Hengyang',
      'Hengshui',
      'Kaiyun',
      'Hengdong Chengguanzhen',
      'Hengbei',
      'Hengbanqiao',
      'Helixi',
      'Helin',
      'Helie',
      'Hejiang',
      'Heilangkou',
      'Hefei',
      'Hede',
      'Hecun',
      'Hecheng',
      'Hebian',
      'Hebi',
      'Hebeitun',
      'Heba',
      'Haoxue',
      'Haomen',
      'Haojiaqiao',
      'Hanzhong',
      'Hanyin Chengguanzhen',
      'Fu’an',
      'Hanting',
      'Hanshou',
      'Hanjiashu',
      'Hanjiang',
      'Hanjia',
      'Hanji',
      'Hangzhou',
      'Hangu',
      'Hangou',
      'Guangze',
      'Hanfeng',
      'Changzhi',
      'Handian',
      'Handan',
      'Hanchuan',
      'Haizhou',
      'Haiyang',
      'Haitou',
      'Haishiwan',
      'Jiaojiang',
      'Haikou',
      'Haicheng',
      'Haidian',
      'Hai’an',
      'Guzhen Chengguanzhen',
      'Guyuan',
      'Jiangle',
      'Guye',
      'Guyang',
      'Guxian',
      'Guxi',
      'Yutai',
      'Gushi Chengguanzhen',
      'Gupi',
      'Guozhen',
      'Guoyang Chengguanzhen',
      'Lingbao Chengguanzhen',
      'Guofu',
      'Gunan',
      'Gulin',
      'Guli',
      'Gulaobei',
      'Gulang',
      'Guiyang',
      'Guiyang Chengguanzhen',
      'Guishan',
      'Guiren',
      'Guiping',
      'Guilin',
      'Guidong Chengguanzhen',
      'Guigang',
      'Gufeng',
      'Gucheng Chengguanzhen',
      'Guanzhuang',
      'Guanyin',
      'Guanyang',
      'Guancheng',
      'Guantou',
      'Guanting',
      'Guanhu',
      'Guangzhou',
      'Guangshun',
      'Guangshui',
      'Guangrao',
      'Guangming',
      'Huquan',
      'Guandu',
      'Gongping',
      'Gongmen',
      'Gongjing',
      'Gongjiang',
      'Gonghe',
      'Gongguan',
      'Gongcheng',
      'Gongba',
      'Gelan',
      'Gejiu',
      'Gedong',
      'Gecheng',
      'Ganzi',
      'Gaozuo',
      'Gaozhuang',
      'Gaozhou',
      'Gaozeng',
      'Gaoyou',
      'Gaotangling',
      'Gaotang',
      'Gaoqiao',
      'Gaoyi',
      'Gaoping',
      'Gaoniang',
      'Gaomi',
      'Gaoliu',
      'Gaoleshan',
      'Gaojingzhuang',
      'Gaojia',
      'Gaohe',
      'Gaoguan',
      'Gaogu',
      'Gaogou',
      'Gaocun',
      'Gaocheng',
      'Gao’an',
      'Ganzhou',
      'Gantang',
      'Lingchuan',
      'Ganshui',
      'Ganjia',
      'Gangu Chengguanzhen',
      'Gangtou',
      'Gahai',
      'Gabasumdo',
      'Fuzhuang',
      'Fuzhou',
      'Fujia',
      'Fuyang',
      'Fuchuan',
      'Wofo',
      'Tiantan',
      'Furong',
      'Fulu',
      'Fuling',
      'Fuliang',
      'Fushi',
      'Fuji',
      'Fugou Chengguanzhen',
      'Fufeng',
      'Fubao',
      'Foshan',
      'Fenyi',
      'Fenshui',
      'Fengyi',
      'Fengyang Fuchengzhen',
      'Fengtai',
      'Fengtai Chengguanzhen',
      'Fengrun',
      'Fengqiu Chengguanzhen',
      'Fengpu',
      'Fengming',
      'Fenglu',
      'Fengkou',
      'Fengjia',
      'Fenggao',
      'Fenggang',
      'Shuangzhucun',
      'Fengdie',
      'Fengchuan',
      'Anxi',
      'Feiluan',
      'Fanshi',
      'Fanzhuang',
      'Fangxian Chengguanzhen',
      'Fangting',
      'Fangshan',
      'Fangjiazhuang',
      'Fangcun',
      'Fangcheng Chengguanzhen',
      'Fanyang',
      'Ezhou',
      'Erwangzhuang',
      'Erqu',
      'Erlang',
      'Dawu Chengguanzhen',
      'Enshi',
      'Enle',
      'Enjiang',
      'Encheng',
      'Eman',
      'Ehen Hudag',
      'Duzhou',
      'Duyun',
      'Dushi',
      'Dushan',
      'Duru',
      'Duobao',
      'Dunhou',
      'Doucun',
      'Ducheng',
      'Duchang',
      'Duoba',
      'Douhudi',
      'Douba',
      'Dongzhaogezhuang',
      'Dongyuan',
      'Donglan',
      'Dongying',
      'Dongxing',
      'Xuanhan',
      'Dongxi',
      'Dongtai',
      'Dongtazhuang',
      'Dongshan',
      'Dongshan Dongzuxiang',
      'Dongping',
      'Dongnigu',
      'Dongming Chengguanzhen',
      'Dongling',
      'Dongkou',
      'Dongkan',
      'Dongjituo',
      'Donghuang',
      'Donghua',
      'Donghai',
      'Dongguang',
      'Dongguan',
      'Dong’erying',
      'Dongdu',
      'Dongditou',
      'Dongdai',
      'Dongcun',
      'Dongchuan',
      'Dongchongcun',
      'Xinle',
      'Diping',
      'Dingzhou',
      'Dingxi',
      'Dingtao',
      'Dingping',
      'Dingjia',
      'Dinghaicun',
      'Dingbian',
      'Dianbu',
      'Dezhou',
      'Deyang',
      'Deqing',
      'Decheng',
      'Dengzhou',
      'Dengjiapu',
      'Degan',
      'Liping',
      'Debao',
      'Dazuocun',
      'Dazhou',
      'Dazhongzhuang',
      'Dazhong',
      'Dazhe',
      'Dayou',
      'Zhangjiajie',
      'Daying',
      'Daye',
      'Dayanggezhuang',
      'Daxie',
      'Daxi',
      'Dawukou',
      'Dawu',
      'Dawangtai',
      'Datun',
      'Datong',
      'Datianzhuang',
      'Datangzhuang',
      'Dasi',
      'Dashun',
      'Dashentang',
      'Dashan',
      'Darong',
      'Darenzhuang',
      'Daqiao',
      'Fenghua',
      'Dabaozi',
      'Daotian',
      'Daokou',
      'Daojiang',
      'Danyang',
      'Danshui',
      'Danjiangkou',
      'Xinshui',
      'Gushu',
      'Dangcheng',
      'Dangjiang',
      'Tanchang Chengguanzhen',
      'Danfeng',
      'Danxi',
      'Daming',
      'Damiao',
      'Damai',
      'Dalu',
      'Daliang',
      'Dalian',
      'Dali',
      'Dala',
      'Dakoutun',
      'Dajin',
      'Dajie',
      'Dajianchang',
      'Dajia',
      'Daiyue',
      'Shangguan',
      'Daixi',
      'Daishan',
      'Dahuangshan',
      'Dahuangpu',
      'Dahua',
      'Ziyuan',
      'Daguan',
      'Dazhasi',
      'Dafengdong',
      'Shanglin',
      'Dadunqiu',
      'Xinshancun',
      'Dadeng',
      'Dachang',
      'Dabizhuang',
      'Dabao’anzhen',
      'Dabaizhuang',
      'Shuren',
      'Dabachang',
      'Cuntan',
      'Ninghua',
      'Cuijiamatou',
      'Cuijiaji',
      'Cuihuangkou',
      'Cuihua',
      'Cizhu',
      'Cili',
      'Chuzhou',
      'Chunxi',
      'Chunhua',
      'Yangchun',
      'Chumi',
      'Chuimatan',
      'Zhiqing Songduo',
      'Yiwu',
      'Chongxing',
      'Chongru',
      'Chongqing',
      'Chonglong',
      'Chongkan',
      'Chizhou',
      'Chixi',
      'Chiping',
      'Chikan',
      'Chitu',
      'Chicheng',
      'Chiba',
      'Chenzhou',
      'Chenying',
      'Chenyang',
      'Chengyang',
      'Chengxian Chengguanzhen',
      'Chengxi',
      'Chengwu',
      'Chengtougu',
      'Langchi',
      'Chengjiang',
      'Jiangyin',
      'Chenggu',
      'Chengdu',
      'Chenghua',
      'Chefushan',
      'Chatian',
      'Chaozhou',
      'Chaotian',
      'Chaohu',
      'Changzhou',
      'Puxi',
      'Changyuan',
      'Changyi',
      'Changxin',
      'Changtun',
      'Wu’an',
      'Changqing',
      'Changqiao',
      'Changpu',
      'Changli',
      'Changleng',
      'Changle',
      'Changdian',
      'Changchi',
      'Changcheng',
      'Changba',
      'Rong’an',
      'Chaling Chengguanzhen',
      'Chaigou',
      'Chaicun',
      'Chahe',
      'Chadian',
      'Chabaihu',
      'Ceyu',
      'Cairima',
      'Cenxi',
      'Caoqiao',
      'Caohe',
      'Weining',
      'Cangzhou',
      'Caiyuan',
      'Caijiapu',
      'Caijia',
      'Caidian',
      'Buhe',
      'Bozhou',
      'Boyang',
      'Botou',
      'Boshan',
      'Baise',
      'Bola',
      'Bobai',
      'Biyong',
      'Bishui',
      'Biyang',
      'Bishan',
      'Binzhou',
      'Bikou',
      'Bijie',
      'Biaokou',
      'Bianzhuang',
      'Biantang',
      'Bengbu',
      'Benchu',
      'Bencheng',
      'Beiwenquan',
      'Beiwangli',
      'Beitan',
      'Beisu',
      'Beimeng',
      'Beilun',
      'Beilizigu',
      'Beijing',
      'Beihuaidian',
      'Beihai',
      'Beidao',
      'Beidaihehaibin',
      'Beicang',
      'Beicaicun',
      'Beibei',
      'Bazhong',
      'Bazhai',
      'Bayiji',
      'Bayan Hot',
      'Batang',
      'Basuo',
      'Bashan',
      'Barkam',
      'Baoyou',
      'Baoying',
      'Muping',
      'Baocheng',
      'Shaoyang',
      'Langzhong',
      'Baoluan',
      'Baokang Chengguanzhen',
      'Baojia',
      'Baofeng Chengguanzhen',
      'Baoding',
      'Bao’an',
      'Bangjun',
      'Bangdong',
      'Bamencheng',
      'Bama',
      'Balu',
      'Baizi',
      'Baiyun',
      'Baiyin',
      'Baiyashi',
      'Baixi',
      'Baituo',
      'Baitu',
      'Baitao',
      'Baishi',
      'Mashan',
      'Baisha',
      'Baimajing',
      'Bailu',
      'Bailin',
      'Baijian',
      'Baihua',
      'Baihecun',
      'Shangyu',
      'Baichihe',
      'Bahuang',
      'Badu',
      'Badaogu',
      'Xilin',
      'Bachuan',
      'Babu',
      'Qilian',
      'Aoyang',
      'Aoxi',
      'Aoshi',
      'Anyuan',
      'Du’an',
      'Anxiang',
      'Anshun',
      'Anren Chengguanzhen',
      'Anqiu',
      'Anqing',
      'Mabai',
      'Anmenkou',
      'Anlu',
      'Anlong',
      'Anliu',
      'Anju',
      'Anjiang',
      'Anhua',
      'Anfu',
      'Naxi',
      'Andongwei',
      'Anbu',
      'Ala',
      'Ahu',
      'Aba',
      'Langxia',
      'Jiangyou',
      'Zhoushan',
      'Huaihelu',
      'Dacheng',
      'Zhongzhan',
      'Wuduan',
      'Mudu',
      'Songling',
      'Fulilu',
      'Sancha',
      'Zhagulu',
      'Xipo',
      'Dahedian',
      'Tielou',
      'Xiliguantun',
      'Lüfeng',
      'Shouqiao',
      'Xinsheng',
      'Shuangshan',
      'Xiaolin',
      'Guoliang',
      'Tuqiao',
      'Yongxi',
      'Shiyu',
      'Pulü',
      'Caijiagang',
      'Gaosheng',
      'Tieshan',
      'Yuanjue',
      'Guansheng',
      'Hebao',
      'Baoxing',
      'Huaxing',
      'Guangpu',
      'Zhisheng',
      'Wutan',
      'Kaitang',
      'Runsong',
      'Bibo',
      'Dehua',
      'Luoli',
      'Jiuchao',
      'Koujiang',
      'Dundong',
      'Zhaoxing',
      'Deshun',
      'Gaowu',
      'Baijiawan',
      'Baliwan',
      'Dashi',
      'Dazhuang',
      'Gucheng',
      'Guochuan',
      'Gupo',
      'Jutou',
      'Kuanchuan',
      'Leiwang',
      'Liuping',
      'Luoba',
      'Mahe',
      'Qianhu',
      'Suhe',
      'Tumen',
      'Wanghe',
      'Wangyao',
      'Wangyin',
      'Wujiahe',
      'Xingfeng',
      'Yebao',
      'Yongping',
      'Yuanmen',
      'Yanhe',
      'Banan',
      'Dianjun',
      'Zhongyuanlu',
      'Yangying',
      'Nanhai',
      'Zhize',
      'Fengxiang',
      'Yangba',
      'Conglin',
      'Ciyun',
      'Xiaba',
      'Shentong',
      'Shitan',
      'Anlan',
      'Nanpeng',
      'Huimin',
      'Ersheng',
      'Fengsheng',
      'Yufengshan',
      'Tongjiaxi',
      'Fuguo',
      'Dasheng',
      'Tuchang',
      'Honghu',
      'Lanyang',
      'Guangcun',
      'Sandu',
      'Mutang',
      'Heqing',
      'Wuzhishan',
      'Duohu',
      'Huangpu',
      'Luogang',
      'Lianghu',
      'Zhoucheng',
      'Xinluoqu',
      'Xunzhong',
      'Kerencun',
      'Wubao',
      'Tatou',
      'Yinglin',
      'Mapingcun',
      'Neikeng',
      'Baiqi',
      'Shanxia',
      'Xiangyun',
      'Jingfeng',
      'Dalianwan',
      'Xiaochangshan',
      'Zhangzi',
      'Wuyishan',
      'Xiaping',
      'Xingsha',
      'Heshan',
      'Liulinzhou',
      'Bojia',
      'Santangpu',
      'Qiandengyingcun',
      'Kutao',
      'Beizhai',
      'Qingshancun',
      'Xinzhuang',
      'Weiwangzhuang',
      'Goutou',
      'Meipucun',
      'Kuai’an',
      'Chengmen',
      'Hongtang',
      'Huai’an Shequ',
      'Donghu',
      'Houyu',
      'Jitoucun',
      'Beishancun',
      'Huangtian',
      'Dingtoucun',
      'Miaoba',
      'Zhaojia',
      'Dong’an',
      'Zouma',
      'Miaoya',
      'Sanmiao',
      'Ouyang',
      'Dengyuantai',
      'Dazhaicun',
      'Hedong',
      'Yunxing',
      'Zunhua',
      'Zhuozishan',
      'Zhuolu',
      'Mishan',
      'Zhenlai',
      'Shuangliao',
      'Zhaozhou',
      'Zhaodong',
      'Zhangjiakou',
      'Zhangbei',
      'Zhalantun',
      'Yushu',
      'Huachuan',
      'Youyi',
      'Youhao',
      'Yitong',
      'Yingshouyingzi',
      'Yingkou',
      'Yilan',
      'Yi’an',
      'Yebaishou',
      'Yantongshan',
      'Yanshou',
      'Yanqing',
      'Yanji',
      'Yakeshi',
      'Xuanhua',
      'Chongli',
      'Xiuyan',
      'Xinqing',
      'Xinlin',
      'Xinhui',
      'Xinglongshan',
      'Xinghe Chengguanzhen',
      'Xingcheng',
      'Xin Bulag',
      'Mohe',
      'Xilin Hot',
      'Xifeng',
      'Xicheng',
      'Xiaoshi',
      'Xiahuayuan',
      'Wumahe',
      'Wudan',
      'Wudalianchi',
      'Wuchang',
      'Weichang',
      'Wangqing',
      'Hepingjie',
      'Wangkui',
      'Uliastai',
      'Ulan Hua',
      'Ulanhot',
      'Tuquan',
      'Tongliao',
      'Tonghua',
      'Tonghe',
      'Togrog Ul',
      'Tieling',
      'Tieli',
      'Yuquan',
      'Tianyi',
      'Tianshan',
      'Taonan',
      'Tangyuan',
      'Tailai',
      'Dorbod',
      'Tahe',
      'Sunwu',
      'Sunjia Buzi',
      'Sujiatun',
      'Suizhong',
      'Suiling',
      'Suihua',
      'Suifenhe',
      'Songjianghe',
      'Siping',
      'Sifangtai',
      'Shunyi',
      'Shulinzhao',
      'Shulan',
      'Shuangyashan',
      'Shuangyang',
      'Shuangtashan',
      'Shuangcheng',
      'Shiguai',
      'Shenyang',
      'Shanhecun',
      'Shanhaiguan',
      'Shangzhi',
      'Qitai',
      'Shacheng',
      'Sanchazi',
      'Salaqi',
      'Saihan Tal',
      'Raohe',
      'Fendou',
      'Qitaihe',
      'Qiqihar',
      'Qinggang',
      'Xunke',
      'Qiezihe',
      'Qian’an',
      'Qasq',
      'Ming’antu',
      'Pingzhuang',
      'Pingquan',
      'Pingfang',
      'Pingdingbu',
      'Panshi',
      'Panshan',
      'Nong’an',
      'Nianzishan',
      'Nenjiang',
      'Nehe',
      'Nantai',
      'Nanpiao',
      'Lianhe',
      'Mulan',
      'Mujiayingzi',
      'Mudanjiang',
      'Antu',
      'Meixi',
      'Meilisi',
      'Meihekou',
      'Manzhouli',
      'Mandalt',
      'Lubei',
      'Luanping',
      'Longjing',
      'Longjiang',
      'Liuhe',
      'Lishu',
      'Lingyuan',
      'Lingdong',
      'Lindong',
      'Lindian',
      'Liaozhong',
      'Liaoyuan',
      'Daihai',
      'Biancheng',
      'Langxiang',
      'Langtoucun',
      'E’erguna',
      'Kuandian',
      'Kuancheng',
      'Kouqian',
      'Kongjiazhuang',
      'Keshan',
      'Kangping',
      'Kangbao',
      'Kaitong',
      'Kailu',
      'Jixi',
      'Jiutai',
      'Jiupu',
      'Jishu',
      'Lianshan',
      'Jingyu',
      'Jingpeng',
      'Jilin',
      'Jidong',
      'Jiaohe',
      'Jiamusi',
      'Jalai Nur',
      'Jiagedaqi',
      'Inder',
      'Hushitai',
      'Kulun',
      'Hunchun',
      'Khuma',
      'Huludao',
      'Hulan Ergi',
      'Hulan',
      'Huinan',
      'Huanren',
      'Huangnihe',
      'Huanan',
      'Huadian',
      'Changshun',
      'Hongshan',
      'Honggang',
      'Hohhot',
      'Hoh Ereg',
      'Hobor',
      'Hengshan',
      'Helong',
      'Heishan',
      'Heihe',
      'Hegang',
      'Harbin',
      'Haliut',
      'Hailun',
      'Hailin',
      'Hailar',
      'Gongzhuling',
      'Gongchangling',
      'Genhe',
      'Gannan',
      'Gaizhou',
      'Fuyuan',
      'Fuxin',
      'Fusong',
      'Fushun',
      'Jixian',
      'Fujin',
      'Beichengqu',
      'Luobei',
      'Fangzheng',
      'Erenhot',
      'Erdaojiang',
      'Duolun',
      'Dunhua',
      'Dongning',
      'Dongfeng',
      'Didao',
      'Dehui',
      'Dawa',
      'Dashitou',
      'Dashiqiao',
      'Daqin Tal',
      'Daqing',
      'Dandong',
      'Linghai',
      'Dalain Hob',
      'Dalai',
      'Dailing',
      'Fengning',
      'Daban',
      'Shuguang',
      'Chifeng',
      'Chengzihe',
      'Chengde',
      'Changtu',
      'Changping',
      'Changling',
      'Changchun',
      'Changbai',
      'Chaihe',
      'Huai’an',
      'Boli',
      'Bin Xian',
      'Benxi',
      'Beipiao',
      'Bei’an',
      'Bayan Tuohai',
      'Baiyin Chagan',
      'Bayan Huxu',
      'Bayan',
      'Baotou',
      'Baoqing',
      'Baokang',
      'Muling',
      'Baishishan',
      'Baiquan',
      'Bailingmiao',
      'Xiqu',
      'Baishan',
      'Anshan',
      'Ang’angxi',
      'Anda',
      'Amgalang',
      'Oroqen Zizhiqi',
      'Acheng',
      'Ulashan',
      'Quchi',
      'Shigang',
      'Tailong',
      'Xikou',
      'Xihai',
      'Mositai',
      'Hanbin',
      'E’zhou',
      'Fula’erji',
      'Jinniu',
      'Jiantang',
      'Nayun',
      'Liangshi',
      'Yunji',
      'Jianghai',
      'Leicheng',
      'Dongzhen',
      'Luozhou',
      'Hexi',
      'Xiangjiaba',
      'Yongyang',
      'Shilin',
      'Huohua',
      'Bowangshan',
      'Shunde',
      'Xinfa',
      'Panzhihua',
      'Haikuotiankong',
      'Xilinji',
      'Gufu',
      'Hongmiao',
      'Shirong',
      'Dachuan',
      'Xiwai',
      'Taifu',
      'Xia’er',
      'Chengnan',
      'Yongchang',
      'Jinjiazhuang',
      'Jinghu',
      'Yijiang',
      'Weicheng',
      'Qindu',
      'Weiyang',
      'Beilin',
      'Lianhu',
      'Changshu',
      'Shixing',
      'Jiashan',
      'Dehang',
      'Luoda',
      'Nanhe',
      'Hejiabao',
      'Jiahe',
      'Jiangtai',
      'Chela',
      'Xinchengzi',
      'Ganjiangtou',
      'Haoti',
      'Baiguan',
      'Dengbu',
      'Zhaoxian',
      'Xiaoliang',
      'Leiba',
      'Wangba',
      'Tanping',
      'Baihe',
      'Sanyu',
      'Caoping',
      'Shizi',
      'Quwa',
      'Bazang',
      'Lijie',
      'Dayu',
      'Hanban',
      'Pingding',
      'Jiangpan',
      'Nanyu',
      'Chagang',
      'Baleng',
      'Boyu',
      'Sheshu',
      'Jianshan',
      'Baoziba',
      'Shijiba',
      'Koutouba',
      'Pingya',
      'Puchi',
      'Hanlin',
      'Maying',
      'Jiegan',
      'Moba',
      'Sangba',
      'Dianga',
      'Yiwa',
      'Niba',
      'Dianzi',
      'Guzhan',
      'Lexiu',
      'Azitan',
      'Changchuan',
      'Zhuoluo',
      'Shencang',
      'Taoyan',
      'Zangbawa',
      'Shaowa',
      'Zuogaimanma',
      'Wuku',
      'Dongjiang',
      'Shajin',
      'Wuying',
      'Lianhua',
      'Weidian',
      'Ha’ergai Dadui',
      'Shibao',
      'Sancang',
      'Fengxiang Chengguanzhen',
      'Zhongshan Donglu',
      'Jinling',
      'Yuhe',
      'Luotang',
      'Lianghe',
      'Yuezhao',
      'Sanheba',
      'Pipa',
      'Yuhuang',
      'Nianba',
      'Huangping',
      'Guohe',
      'Dananyu',
      'Foya',
      'Ganquan',
      'Dabao',
      'Sitai',
      'Yulong',
      'Douping',
      'Chanhe',
      'Miba',
      'Huangchen',
      'Songping',
      'Taishi',
      'Longba',
      'Suochi',
      'Suyuan',
      'Taishihe',
      'Paosha',
      'Shaba',
      'Xigaoshan',
      'Diancun',
      'Chenyuan',
      'Haolin',
      'Liuxiang',
      'Yinxingshu',
      'Luoyu',
      'Wangmo',
      'Shili',
      'Huangzhu',
      'Shaijing',
      'Jiangxi',
      'Luhe',
      'Xiyu',
      'Mayuan',
      'Shaoyu',
      'Mangqu',
      'Yuguan',
      'Jialing',
      'Shuiyang',
      'Xianlong',
      'Jindong',
      'Kajiadao',
      'Tangga’an',
      'Madang',
      'Qu’ao',
      'Qianyang Chengguanzhen',
      'Yangyong',
      'Wangzang',
      'Ni’ao',
      'Kaba',
      'Daogao',
      'Nalang',
      'Chubu',
      'Liushun',
      'Nawu',
      'Bajiao',
      'Kangduo',
      'Zuogaiduoma',
      'Shangping',
      'Hadapu Zhen',
      'Lichuan Zhen',
      'Pangjia',
      'Bali',
      'Yan’anxiang',
      'Longtai',
      'Tange',
      'Shandan',
      'Gaolou',
      'Hualin',
      'Yupan',
      'Saga',
      'Hezhou',
      'Zhu Cheng City',
      'Nanzheng Chengguanzhen',
      'Yelin',
      'Yuanzhou',
      'Xinchenglu',
      'Fangsong',
      'Biru',
      'Biruxong',
      'Moluo',
      'Qianquan',
      'Shangri-La',
      'Jishi',
      'Dingqing',
      'Konggar',
      'Laxi',
      'Mainling',
      'Renwu',
      'Samdo',
      'Xuejiawan',
      'Galutu',
      'Aletengxire',
      'Dabagou',
      'Julun',
      'Qianfeng',
      'Dongqu',
      'Bala Ga’er Gaole',
      'Bieligutai',
      'Tianjiao',
      'Shangdu',
      'Tongyangdao',
      'Bayinsai',
      'Xingong',
      'Xiritala',
      'Xarlung',
      'Xoixar',
      'Naidong',
      'Dongjie',
      'Zhengdonglu',
      'Shouxihu',
      'Hanshang',
      'Wenhe',
      'Wulaocun',
      'Qinhong',
      'Binhu',
      'Meiyuan Xincun',
      'Nanchansi',
      'Beidajie',
      'Chong’ansi',
      'Songlong',
      'Yuanhe',
      'Shishan',
      'Shuangta',
      'Pingjianglu',
      'Ordos',
      'Lanjiang',
      'Jiefanglu',
      'Ningnan',
      'Chengbei',
      'Lugu',
      'Jingshan',
      'Jinrongjie',
      'Chaowai',
      'Gongchen',
      'Beiyuan',
      'Shengli',
      'Gulou',
      'Hehai',
      'Daocheng',
      'Zuolan',
      'Gaonan',
      'Beiping',
      'Heyu',
      'Longtian',
      'Gaoyan',
      'Xianyi',
      'Houping',
      'Mingzhong',
      'Liaozi',
      'Jianping',
      'Duping',
      'Baolong',
      'Hongchun',
      'Fuying',
      'Dangyang',
      'Zhuxian',
      'Sanxi',
      'Liangping',
      'Pinghe',
      'Longxi',
      'Futian',
      'Ping’an',
      'Dashu',
      'Hongtu',
      'Yanwan',
      'Fenhe',
      'Baidi',
      'Caotang',
      'Jiagao',
      'Yunwu',
      'Longqiao',
      'Fengping',
      'Qinglian',
      'Guanmian',
      'Manyue',
      'Heqian',
      'Maliu',
      'Heyan',
      'Baiqiao',
      'Guojia',
      'Dunhao',
      'Tianhe',
      'Sanhuikou',
      'Yihe',
      'Jiulongshan',
      'Zhen’an',
      'Dade',
      'Fengle',
      'Zhendong',
      'Houba',
      'Jinfeng',
      'Qukou',
      'Nanmen',
      'Wutong',
      'Nanya',
      'Zhugang',
      'Houshan',
      'Sunjia',
      'Lihe',
      'Tiefeng',
      'Xiongjia',
      'Zhoujiaba',
      'Taibai',
      'Gaoliang',
      'Zhonggulou',
      'Jiuchi',
      'Pailou',
      'Chenjiaba',
      'Bai’anba',
      'Danzi',
      'Huangbai',
      'Changtan',
      'Dibao',
      'Henghe',
      'Luotian',
      'Guocun',
      'Rangdu',
      'Longsha',
      'Ganning',
      'Gaofeng',
      'Xiaozhou',
      'Hezui',
      'Wangjia',
      'Fengmu',
      'Lengshui',
      'Yuchi',
      'Yanxi',
      'Wangchang',
      'Lichang',
      'Huangshui',
      'Zhongyi',
      'Sanyi',
      'Xixin',
      'Huanghe',
      'Sanxing',
      'Xialu',
      'Liutang',
      'Caohui',
      'Changlong',
      'Pushun',
      'Gangjia',
      'Huangsha',
      'Peixing',
      'Baijia',
      'Shi’an',
      'Zizhao',
      'Tiemen',
      'Yinping',
      'Jindai',
      'Jukui',
      'Qixing',
      'Ansheng',
      'Mingda',
      'Lirang',
      'Hucheng',
      'Wenhua',
      'Fuping',
      'Hexing',
      'Xumingsi',
      'Dongjia',
      'Shuanglongchang',
      'Shizhi',
      'Huwei',
      'Zhanpu',
      'Sanba',
      'Longkong',
      'Jiangchi',
      'Longhe',
      'Taipingba',
      'Jilong',
      'Nantianhu',
      'Sanjian',
      'Dudu',
      'Apengjiang',
      'Echi',
      'Mala',
      'Shuishi',
      'Taiji',
      'Wuli',
      'Pengdong',
      'Shuitian',
      'Zhengyang',
      'Zhoubai',
      'Heixi',
      'Zhongtang',
      'Xiaonanhai',
      'Shanling',
      'Huangxi',
      'Daya',
      'Runxi',
      'Longtang',
      'Langxi',
      'Shipan',
      'Dianshui',
      'Shangan',
      'Lin’e',
      'Anzi',
      'Meiziya',
      'Zhufo',
      'Tonglou',
      'Xiaochang',
      'Qiaozi',
      'Wanzu',
      'Luming',
      'Longshe',
      'Lutang',
      'Ditang',
      'Changhongjie',
      'Wanghailou',
      'Xiyuzhuang',
      'Dawangzhuang',
      'Dayingmen',
      'Xiaobailou',
      'Guoyuan Xincun',
      'Wenfu',
      'Fenglai',
      'Tiekuang',
      'Huangying',
      'Canggou',
      'Tudi',
      'Tongle',
      'Zengfu',
      'Lizhi',
      'Wulingshan',
      'Damu',
      'Luoyun',
      'Nantuo',
      'Baisheng',
      'Dandu',
      'Changshouhu',
      'Haitang',
      'Jiangnan',
      'Bake',
      'Dawan',
      'Gulu',
      'Jindaoxia',
      'Lijia',
      'Shuangfengqiao',
      'Huixing',
      'Cuiyun',
      'Fuxing',
      'Shijialiang',
      'Shuanghuai',
      'Shuangfeng',
      'Xiaojia',
      'Laitan',
      'Shayu',
      'Qiantang',
      'Yanwo',
      'Diaoyucheng',
      'Nanjin',
      'Mixin',
      'Qunli',
      'Huayan',
      'Biekou',
      'Jijia',
      'Gulong',
      'Zhifeng',
      'Tongqiao',
      'Longtanzi',
      'Tangxiang',
      'Qingliu',
      'Longji',
      'Guchang',
      'Qingjiang',
      'Qingsheng',
      'Zhongshanlu',
      'Weixin',
      'Shaoyun',
      'Erping',
      'Weilong',
      'Jianlong',
      'Shuangfu',
      'Zhiping',
      'Huancuilou',
      'Jimai',
      'Youganning',
      'Awancang',
      'Oula Xiuma',
      'Muxihe',
      'Wenshan City',
      'Jianhua',
      'Sa’ertu',
      'Muyu',
      'Tuanbao',
      'Xiaoduchuan',
      'Bole',
      'Alashankou',
      'Xuri',
      'Jiao',
      'Qianjin',
      'Nanshan',
      'Gongnong',
      'Xingshan',
      'Jiguan',
      'Aimin',
      'Yangming',
      'Qilong',
      'Zhongqiao',
      'Nanzhushan',
      'Shiqiang',
      'Fengyan',
      'Fushou',
      'Shixi',
      'Hetu',
      'Muliang',
      'Minzhu',
      'Guhua',
      'Toudu',
      'Delong',
      'Sanquan',
      'Zhonghualu',
      'Liupanshui',
      'Qinjiang',
      'Yanqiao',
      'Meixian',
      'Beijinglu',
      'Chengqu',
      'Louqiao',
      'Haokou',
      'Shazhou',
      'Jiayin',
      'Nanchuan',
      'Bianjiang',
      'Dongtundu',
      'Furong Beilu',
      'Guitang',
      'Wangyue',
      'Damatou',
      'Kalabulegen',
      'Kalaxilike',
      'Anwen',
      'Guanba',
      'Jinqiao',
      'Maliuzui',
      'Tieganlike',
      'Hongliuwan',
      'Taobei',
      'Meilu',
      'Aihui',
      'Yinla',
      'Beizheng',
      'Bailuquan',
      'Beitanzhuang',
      'Mugala',
      'Ailan Mubage',
      'Xinjia',
      'Yanglong',
      'Nierong',
      'Xingning',
      'Anning Xilu',
      'Zhangyelu',
      'Xihu',
      'Dongdajie',
      'Renminlu',
      'Xingpinglu',
      'Guangzhoulu',
      'Beijie',
      'Sangpi',
      'Qintang',
      'Tianmen',
      'Damoujia',
      'Changjianglu Jiedao',
      'Jianshelu',
      'Ganhechen',
      'Dizhuang',
      'Gangba',
      'Jibenggang',
      "Alatan'molezhen",
      'Nu’erbage',
      'Bageqi',
      'Tawakule',
      'Han’airike',
      'Ying’awati',
      'Rizhao',
      'Shanwang',
      'Mudan',
      'Fan Xian Chengguanzhen',
      'Gumudi',
      'Huoche Xizhan',
      'Xingfulu',
      'Yangzi Jianglu',
      'Baluntaicun',
      'Taxkowrük',
      'Turpanyüzi',
      'Sadikyüzi',
      'Onyar',
      'Laba',
      'Wuzhi',
      'Luolun',
      'Fudong',
      'Jianshui',
      'Beidaihe',
      'Tuantian',
      'Liuzhi',
      'Wanghong',
      'Yuquanying',
      'Hongguang Qidui',
      'Linhe',
      'Sansheng',
      'Changyuan County',
      'Gongqingcheng',
      'Huchuan',
      'Xuzhou',
      'Jicang',
      'Chuanwang',
      'Xichuan',
      'Lianyungang',
      'Rugao',
      'Baoji',
      'Qipan',
      'Renhuai',
      'Huocheng',
      'Bayan Nur',
      'Huoshilafu',
      "Yak'airike",
      'Gulebage',
      'Sandaoling Lutiankuang Wuqi Nongchang',
      'Siyeke',
      'Qiongkulecun',
      'Katang Ailieke',
      'Jiawa Airikecun',
      'Yingwusitangcun',
      'Tumxuk',
      'Xiannüshan',
      'Wulong',
      'Chongzuo',
      'Jiangzhou',
      'Yanping',
      'Fengfengkuang',
      'Shunchang',
      'Pinggui',
      'Wanxiu',
      'Cangwu',
      'Qinnanqu',
      'Qinbeiqu',
      'Zhakou',
      'Fangchenggang',
      'Gangkou',
      'Liangqing',
      'Qingxiu',
      'Xixiangtang',
      'Liunan',
      'Yufeng',
      'Liubei',
      'Dali Old Town',
      'Haidong Zhen',
      'Yinqiao Zhen',
      'Wanqiao Zhen',
      'Shuangtang',
      'Dapeng',
      'Daxu',
      'Liguo',
      'Jiawang Zhen',
      'Dashahe',
      'Wanggou',
      'Yinhai',
      'Tieshangang',
      'Rencheng',
      'Xingbin',
      'Gangnan',
      'Youjiang',
      "Jing'an",
      'Yingqiu',
      'Liushan',
      'Huiqu',
      'Jinzhongzi',
      'Zheshan',
      'Buzhuang',
      'Longchi',
      'Xiaying',
      'Jinggou',
      'Kanjia',
      'Huanglou',
      'Yidu',
      'Dongxia',
      'Heguan',
      'Miaozi',
      'Mihe',
      'Shaozhuang',
      'Tanfang',
      'Hualong',
      'Jitai',
      'Taitou',
      'Yingli',
      'Siwei',
      'Ligang',
      'Lingwu',
      'Baitugang',
      'Ningdong',
      'Wangyuan',
      'Minning',
      'Lijun',
      'Xiangshan',
      'Xiufeng',
      'Diecai',
      'Leidong',
      'Shangchong',
      'Sankeshu',
      'Hechi',
      'Xingdaohu',
      'Shatian',
      'Quzhang',
      'Donghui',
      'Fazhanhe',
      'Fubang',
      'Jiujing',
      'Mujia',
      'Nanling',
      'Nuofu',
      'Nuozhadu',
      'Zhutang',
      'Menghuan',
      'Nongzhang',
      'Hehua',
      'Mangbang',
      'Mazhan',
      'Puchuan',
      'Qingshui',
      'Wuhe',
      'Miaojie',
      'Yongjian',
      'Yousuo',
      'Sanchuan',
      'Chenghai',
      'Shunzhou',
      'Jiuhe',
      'Xinyingpan',
      'Shilongba',
      'Wanzhou',
      'Peishi',
      'Zhuyi',
      'Yintai',
      'Laiwu Qu',
      'Qiushan',
      'Awu',
      'Danbao',
      'Fanba',
      'Zhongmiao',
      'Changdao',
      'Liufeng',
      'Xiejiawan',
      'Caochuanpu',
      'Fengwang',
      'Hongbao',
      'Huangmen',
      'Longdong',
      'Songshu',
      'Dayang',
      'Lianwu',
      'Liubao',
      'Maguan',
      'Muhe',
      "Ping'an",
      'Zhangmianyi',
      'Axia',
      "Duo'er",
      'Taobin',
      'Wangqi',
      'Dongchang',
      'Larenguan',
      'Shuangcha',
      'Xicang',
      'Amuquhu',
      'Zhayou',
      'Guoye',
      'Qugaona',
      "Ka'erqin",
      'Sansha',
      'Arou',
      'Quankou',
      'Taoshan',
      'Kezilexilike',
      'Aketikandun',
      'Bingtuan Sanshiliu Tuan',
      'Luobupo',
      'Wutamu',
      'Alamaiti',
      'Arele',
      'Awati',
      'Azhatebage',
      "Bage'awati",
      'Baishikante',
      'Damusi',
      'Dunbage',
      'Huangdi',
      'Kalasu',
      "Kuoshi'airike",
      'Mixia',
      'Paikeqi',
      "Qia'erbage",
      "Tage'erqi",
      'Tuomuwusitang',
      'Wudalike',
      "Yigai'erqi",
      'Yingwusitang',
      'Yishikuli',
      'Zirefuxiati',
      'Bayandai',
      "Ka'erdun",
      'Hudiyuzi',
      'Arewusitang',
      'Awuliya',
      'Kalayagaqi',
      'Mazha',
      'Daxing’anling',
      'Shangyi',
      'Samuyuzi',
      "Weiwu'eryuqiwen",
      'Jiayi',
      'Tuogerigaizi',
      'Xiwule',
      'Buzhake',
      'Kashitashi',
      'Segezikule',
      "Ying'airike",
      "Yisilamu'awati",
      'Akesalayi',
      'Jiahanbage',
      "Ka'ersai",
      'Kawake',
      'Kuiya',
      'Kuoyiqi',
      'Manglai',
      'Puqiakeqi',
      'Tuohula',
      'Tuwaite',
      "Wu'erqi",
      'Yawa',
      "Yingye'er",
      'Zhawa',
      'Dazeshan',
      'Dongwenquan',
      'Tianxingsi',
      'Qitang',
      'Shuanglonghu',
      'Guangxing',
      'Jiaping',
      'Simianshan',
      'Tanghe',
      'Mingshan',
      'Aqto',
      'Xiangfang',
      'Daoli',
      'Daowai',
      'Songbei',
      'Tiedong',
      'Tiexi',
      'Shuangluan',
      'Shuangqiao',
      'Ningjiang',
      'Hefeng',
      'Caojie',
      'Ganfeng',
      'Lixianghu',
      'Shanwangping',
      'Shilian',
      'Changsheng',
      'Shiliu',
      'Dingshan',
      'Guanglu',
      'Fuhuan',
      'Shihao',
      'Yongcheng',
      'Zhongfeng',
      'Zhuantang',
      'Dazhou District',
      'Guiyang, Guizhou',
      'Longling County',
      'Mianzhu, Deyang, Sichuan',
      'Pingwu County',
      'Wanling',
      'Jinzhu',
      'Tianjia',
      'Tukan',
      'Dengjia',
    ],
  },
  {
    country: 'Colombia',
    cities: [
      'Zipaquirá',
      'Zipacón',
      'Zetaquira',
      'Zarzal',
      'Zaragoza',
      'Zapatoca',
      'Zambrano',
      'Yuto',
      'Yumbo',
      'Yotoco',
      'Yopal',
      'Yondó',
      'Yolombó',
      'Yarumal',
      'Yalí',
      'Yaguará',
      'Yacuanquer',
      'Yacopí',
      'Viterbo',
      'Vistahermosa',
      'Viracachá',
      'Viotá',
      'Villeta',
      'Villavieja',
      'Villavicencio',
      'Villarrica',
      'Villa Rica',
      'Villa del Rosario',
      'Villapinzón',
      'Villanueva',
      'Villamaría',
      'Villahermosa',
      'Villagómez',
      'Villagarzón',
      'Villa de Leyva',
      'Villa Caro',
      'Vijes',
      'Vigía del Fuerte',
      'La Victoria',
      'Victoria',
      'Vianí',
      'Vetas',
      'Versalles',
      'Vergara',
      'Ventaquemada',
      'Venecia',
      'Venadillo',
      'Vélez',
      'Valparaíso',
      'Valledupar',
      'Valle de San Juan',
      'Valle de San José',
      'Valencia',
      'Valdivia',
      'Útica',
      'Usiacurí',
      'Urumita',
      'Urrao',
      'Uribia',
      'Uribe',
      'San José de Uré',
      'Uramita',
      'Unguía',
      'Une',
      'Úmbita',
      'Ulloa',
      'Villa de San Diego de Ubaté',
      'Ubaque',
      'Ubalá',
      'Tutazá',
      'Tuta',
      'Turmequé',
      'Turbo',
      'Turbaná',
      'Turbaco',
      'Túquerres',
      'Tununguá',
      'Tunja',
      'Tumaco',
      'Tuluá',
      'Tuchín',
      'Tubará',
      'Trujillo',
      'Trinidad',
      'Tres Esquinas',
      'Totoró',
      'Tota',
      'Toro',
      'Toribío',
      'Topaipí',
      'Tópaga',
      'Tona',
      'Tolú Viejo',
      'Santiago de Tolú',
      'Toledo',
      'Togüí',
      'Tocancipá',
      'Tocaima',
      'Toca',
      'Titiribí',
      'Tipacoque',
      'Tinjacá',
      'Timbiquí',
      'Timbío',
      'Timaná',
      'Tierralta',
      'Tibú',
      'Tibirita',
      'Tibasosa',
      'Tibaná',
      'Tibacuy',
      'Tesalia',
      'Teruel',
      'Teorama',
      'Tenza',
      'Tenjo',
      'Tenerife',
      'Tena',
      'Tello',
      'Tausa',
      'Tauramena',
      'Tasco',
      'Tarso',
      'Tarqui',
      'Tarazá',
      'Tarapacá',
      'Tangua',
      'Taminango',
      'Támesis',
      'Tame',
      'Támara',
      'Tamalameque',
      'Talaigua Viejo',
      'Talaigua Nuevo',
      'Tadó',
      'Tabio',
      'Sutatenza',
      'Sutatausa',
      'Sutamarchán',
      'Susacón',
      'Susa',
      'Suratá',
      'Supía',
      'Supatá',
      'Suesca',
      'Sucre',
      'Subachoque',
      'Suaza',
      'Suárez',
      'Suan',
      'Suaita',
      'Sotomayor',
      'Sotaquirá',
      'Soracá',
      'Sora',
      'Sopó',
      'Soplaviento',
      'Sonsón',
      'Somondoco',
      'Soledad',
      'Solano',
      'Sogamoso',
      'Socotá',
      'Socorro',
      'Socha Viejo',
      'Soatá',
      'Soacha',
      'Sitionuevo',
      'Sipí',
      'Sincelejo',
      'San Luis de Sincé',
      'Simití',
      'Simijaca',
      'Simacota',
      'Silvia',
      'Silvania',
      'Silos',
      'Sibundoy',
      'Sibaté',
      'Siachoque',
      'Prado-Sevilla',
      'Sevilla',
      'Sesquilé',
      'Segovia',
      'Sativasur',
      'Sativanorte',
      'Sasaima',
      'Sardinata',
      'Saravena',
      'Sapuyes',
      'San Zenón',
      'San Vicente del Caguán',
      'San Vicente de Chucurí',
      'San Vicente',
      'Santuario',
      'Santo Tomás',
      'Santo Domingo',
      'Santiago',
      'Santa Sofía',
      'Santa Rosalia',
      'Santa Rosa de Viterbo',
      'Santa Rosa de Osos',
      'Santa Rosa de Cabal',
      'Santa Rosa',
      'Santa Rita',
      'Santander de Quilichao',
      'Santana',
      'Santa Marta',
      'Santa María',
      'Santa Lucía',
      'Santa Isabel',
      'Santa Catalina',
      'Santa Bárbara',
      'Santa Ana',
      'San Sebastián de Buenavista',
      'San Sebastián',
      'San Roque',
      'San Rafael',
      'San Pelayo',
      'San Pedro de Urabá',
      'San Pedro',
      'San Pablo de Borbur',
      'San Pablo',
      'San Onofre',
      'San Miguel de Sema',
      'San Miguel',
      'La Dorada',
      'San Mateo',
      'San Martín',
      'San Marcos',
      'San Luis de Palenque',
      'San Luis de Gaceno',
      'Cubarral',
      'San Luis',
      'San Lorenzo',
      'San Juan Nepomuceno',
      'San Juanito',
      'San Juan de Urabá',
      'San Juan de Rioseco',
      'San Juan del Cesar',
      'San Juan de Betulia',
      'San Juan de Arama',
      'San José de Pare',
      'San José de Miranda',
      'San José del Palmar',
      'San José de la Montaña',
      'Isnos',
      'San José',
      'San Joaquín',
      'San Jerónimo',
      'San Jacinto del Cauca',
      'San Jacinto',
      'San Gil',
      'San Francisco',
      'San Fernando',
      'San Estanislao',
      'San Eduardo',
      'Sandoná',
      'San Diego',
      'San Cristóbal',
      'San Cayetano',
      'San Carlos de Guaroa',
      'San Carlos',
      'San Calixto',
      'San Bernardo del Viento',
      'San Bernardo',
      'San Benito Abad',
      'San Benito',
      'San Antonio del Tequendama',
      'San Antonio',
      'San Antero',
      'San Ángel',
      'San Andrés de Sotavento',
      'San Andrés',
      'San Alberto',
      'San Agustín',
      'Sampués',
      'Samaniego',
      'Samacá',
      'Salgar',
      'Salento',
      'Saldaña',
      'Salamina',
      'Saladoblanco',
      'Sahagún',
      'Sáchica',
      'Sácama',
      'Saboyá',
      'Sabaneta',
      'Sabanalarga',
      'Sabanagrande',
      'Sabana de Torres',
      'Rovira',
      'Rosas',
      'Rondón',
      'Roncesvalles',
      'Roldanillo',
      'Rivera',
      'Risaralda',
      'Río Viejo',
      'Riosucio',
      'Rionegro',
      'Riohacha',
      'Riofrío',
      'Río de Oro',
      'Rioblanco',
      'Ricaurte',
      'Retiro',
      'Restrepo',
      'Repelón',
      'Remolino',
      'Remedios',
      'Regidor',
      'Recetor',
      'Ráquira',
      'Ramiriquí',
      'Ragonvalia',
      'Apulo',
      'Quipile',
      'Quinchía',
      'Quimbaya',
      'Quibdó',
      'Quetame',
      'Quebradanegra',
      'Purísima de la Concepción',
      'Purificación',
      'Pupiales',
      'Punta de Piedras',
      'Pulí',
      'Puerto Wilches',
      'Puerto Triunfo',
      'Puerto Tejada',
      'Puerto Santander',
      'Puerto Salgar',
      'Puerto Rondón',
      'Puerto Yuca',
      'Puerto Rico',
      'Puerto Parra',
      'Puerto Nariño',
      'Puerto Meluk',
      'Puerto López',
      'Puerto Lleras',
      'Puerto Leguízamo',
      'Inírida',
      'Puerto Escondido',
      'Puerto Colombia',
      'Puerto Carreño',
      'Puerto Boyacá',
      'Puerto Bogotá',
      'Puerto Berrío',
      'Puerto Asís',
      'Puerres',
      'Puente Nacional',
      'Puebloviejo',
      'Pueblorrico',
      'Pueblo Rico',
      'Pueblo Nuevo',
      'Pueblo Bello',
      'Providencia',
      'Prado',
      'Pradera',
      'Potosí',
      'Pore',
      'Popayán',
      'Ponedera',
      'Polonuevo',
      'Policarpa',
      'Playarrica',
      'Planeta Rica',
      'Planadas',
      'Pizarro',
      'Salahonda',
      'Pivijay',
      'Pitalito',
      'Pital',
      'Pisba',
      'Piojó',
      'Santa Bárbara de Pinto',
      'Pinillos',
      'Pinchote',
      'Pijiño del Carmen',
      'Pijao',
      'Piedras',
      'Piedrancha',
      'Pie de Pató',
      'Piedecuesta',
      'Pesca',
      'Pereira',
      'Peque',
      'Pensilvania',
      'Pelaya',
      'Pedraza',
      'Paz de Río',
      'Paz de Ariporo',
      'Payandé',
      'Payán',
      'Paya',
      'Pauna',
      'Patía',
      'Pasto',
      'Pasca',
      'Páramo',
      'Panqueba',
      'Pandi',
      'Pamplonita',
      'Pamplona',
      'Palocabildo',
      'Palmito',
      'Palmira',
      'Palmas del Socorro',
      'Palmar de Varela',
      'Palmar',
      'Palestina',
      'Palermo',
      'Pajarito',
      'Paispamba',
      'Paipa',
      'Paime',
      'Paimadó',
      'Pailitas',
      'Paicol',
      'Páez',
      'Belalcázar',
      'Padua',
      'Padilla',
      'Pácora',
      'Pacho',
      'Pachavita',
      'Ovejas',
      'Otanche',
      'Ospina',
      'Ortega',
      'Orocué',
      'Oporapa',
      'Onzaga',
      'Olaya Herrera',
      'Olaya',
      'Oicatá',
      'Oiba',
      'Ocaña',
      'Ocamonte',
      'Obando',
      'Nuquí',
      'Nunchía',
      'Nuevo Colón',
      'Nueva Granada',
      'Nóvita',
      'Norosí',
      'Norcasia',
      'Nocaima',
      'Nobsa',
      'Nimaima',
      'Nilo',
      'Nemocón',
      'Neiva',
      'Neira',
      'Necoclí',
      'Nechí',
      'Natagaima',
      'Nátaga',
      'Nariño',
      'Muzo',
      'Mutiscua',
      'Mutatá',
      'Murindó',
      'Murillo',
      'Mountain',
      'Motavita',
      'Mosquera',
      'Morroa',
      'Morelia',
      'Morales',
      'Montería',
      'Montenegro',
      'Montelíbano',
      'Montecristo',
      'Montebello',
      'Moñitos',
      'Moniquirá',
      'Monguí',
      'Mongua',
      'Mompós',
      'Momil',
      'Molagavita',
      'Mogotes',
      'Mocoa',
      'Mitú',
      'Mistrató',
      'Miranda',
      'Miraflores',
      'Milán',
      'Mercaderes',
      'Melgar',
      'Medina',
      'Medellín',
      'Matanza',
      'Marulanda',
      'Marsella',
      'Marquetalia',
      'Marmato',
      'San Sebastián de Mariquita',
      'Maripí',
      'Marinilla',
      'María la Baja',
      'Margarita',
      'Manzanares',
      'Manta',
      'Manizales',
      'Maní',
      'Manaure',
      'Manatí',
      'Managrú',
      'Malambo',
      'Málaga',
      'Majagual',
      'Maicao',
      'Mahates',
      'Magangué',
      'Madrid',
      'Machetá',
      'Maceo',
      'Macaravita',
      'Macanal',
      'Luruaco',
      'Lourdes',
      'Los Santos',
      'Los Patios',
      'Los Palmitos',
      'Los Córdobas',
      'Lorica',
      'López',
      'Lloró',
      'Linares',
      'Liborina',
      'Líbano',
      'Leticia',
      'Lérida',
      'Lenguazaque',
      'Leiva',
      'Lebrija',
      'La Virginia',
      'La Vega',
      'La Uvita',
      'La Unión',
      'La Tebaida',
      'La Sierra',
      'Ánimas',
      'La Salina',
      'La Primavera',
      'La Playa',
      'La Plata',
      'La Pintada',
      'La Peña',
      'La Paz',
      'La Palma',
      'Landázuri',
      'La Montañita',
      'La Mesa',
      'La Merced',
      'La Llanada',
      'La Jagua del Pilar',
      'La Jagua de Ibirico',
      'La Gloria',
      'La Florida',
      'La Estrella',
      'La Esperanza',
      'La Cumbre',
      'La Cruz',
      'La Chamba',
      'La Celia',
      'La Ceja',
      'La Capilla',
      'La Calera',
      'Labranzagrande',
      'La Belleza',
      'La Argentina',
      'Juradó',
      'Junín',
      'Juan de Acosta',
      'Jordán',
      'Jesús María',
      'Jerusalén',
      'Jericó',
      'Jenesano',
      'Jardín',
      'Jamundí',
      'Jambaló',
      'Iza',
      'Ituango',
      'Itagüí',
      'Istmina',
      'Iscuandé',
      'Íquira',
      'Ipiales',
      'Inzá',
      'Imués',
      'Iles',
      'Icononzo',
      'Ibagué',
      'Honda',
      'Hobo',
      'Hispania',
      'Herveo',
      'Herrán',
      'Heliconia',
      'Municipio Hato Corozal',
      'Hato',
      'Hatillo de Loba',
      'Hacarí',
      'Gutiérrez',
      'Güicán',
      'Güepsa',
      'Guayatá',
      'Guayabetal',
      'Guayabal de Síquima',
      'Guavatá',
      'Guática',
      'Guateque',
      'Guatavita',
      'Guataquí',
      'Guatapé',
      'Guasca',
      'Guarne',
      'Guaranda',
      'Guapotá',
      'Guapí',
      'Guamo',
      'Guamal',
      'Gualmatán',
      'Guaitarilla',
      'Guaduas',
      'Guadalupe',
      'Guachucal',
      'Guachetá',
      'Guachené',
      'Guachavés',
      'Guacarí',
      'Guacamayas',
      'Guaca',
      'Granada',
      'Gramalote',
      'González',
      'Gómez Plata',
      'Girón',
      'Girardota',
      'Girardot City',
      'Giraldo',
      'Ginebra',
      'Gigante',
      'Génova',
      'Garzón',
      'Garagoa',
      'Gámeza',
      'Gámbita',
      'Gamarra',
      'Gama',
      'Galapa',
      'Galán',
      'Gaitania',
      'Gachantivá',
      'Gachancipá',
      'Gachalá',
      'Fusagasugá',
      'Fúquene',
      'Funza',
      'Funes',
      'Fundación',
      'Fuente de Oro',
      'Frontino',
      'Frías',
      'Fresno',
      'Fosca',
      'Fortul',
      'Fonseca',
      'Fómeque',
      'Floridablanca',
      'Florida',
      'Florián',
      'Floresta',
      'Florencia',
      'Flandes',
      'Firavitoba',
      'Filandia',
      'Filadelfia',
      'Falan',
      'Facatativá',
      'Espinal',
      'Envigado',
      'Entrerríos',
      'Enciso',
      'Encino',
      'El Zulia',
      'El Tambo',
      'El Tablón',
      'El Rosario',
      'El Rosal',
      'El Roble',
      'El Retén',
      'El Piñón',
      'El Peñón',
      'El Peñol',
      'El Paujíl',
      'El Paso',
      'El Molino',
      'Elías',
      'El Guamo',
      'El Guacamayo',
      'El Espino',
      'El Dovio',
      'El Doncello',
      'El Difícil',
      'El Copey',
      'El Cocuy',
      'El Charco',
      'El Cerrito',
      'El Castillo',
      'El Carmen de Bolívar',
      'El Carmen',
      'El Carmen de Chucurí',
      'El Calvario',
      'El Cairo',
      'El Bordo',
      'El Banco',
      'El Bagre',
      'Algarrobo',
      'El Águila',
      'Ebéjico',
      'Durania',
      'Duitama',
      'Dosquebradas',
      'Donmatías',
      'Doima',
      'Distracción',
      'Dibulla',
      'Darien',
      'Dagua',
      'Dabeiba',
      'Curumaní',
      'Curití',
      'Cunday',
      'Cumbitara',
      'Cumbal',
      'Cumaribo',
      'Cumaral',
      'Cuítiva',
      'Cucutilla',
      'Cúcuta',
      'Cucunubá',
      'Cucaita',
      'Cubará',
      'Cruces de Anorí',
      'Cravo Norte',
      'Coyaima',
      'Coveñas',
      'Cotorra',
      'Cota',
      'Corrales',
      'Corozal',
      'Coromoro',
      'Corinto',
      'Córdoba',
      'Coper',
      'Copacabana',
      'Convención',
      'Contratación',
      'Contadero',
      'Consacá',
      'Confines',
      'Condoto',
      'Concordia',
      'Concepción',
      'Combita',
      'Cómbita',
      'Colosó',
      'Colón',
      'Colombia',
      'Cogua',
      'Coello',
      'Cocorná',
      'Coconuco',
      'Clemencia',
      'Cisneros',
      'Circasia',
      'Cimitarra',
      'Ciénega',
      'Ciénaga de Oro',
      'Ciénaga',
      'Cicuco',
      'Chocontá',
      'Choachí',
      'Chivolo',
      'Chivatá',
      'Chitagá',
      'Chita',
      'Chiscas',
      'Chiriguaná',
      'Chíquiza',
      'Chiquinquirá',
      'Chipatá',
      'Chipaque',
      'Chinú',
      'Chinchiná',
      'Chinavita',
      'Chinácota',
      'Chimichagua',
      'Chimá',
      'Chima',
      'Chigorodó',
      'Chicoral',
      'Chía',
      'Charta',
      'Charalá',
      'Chaparral',
      'Chámeza',
      'Chalán',
      'Chaguaní',
      'Chachagüí',
      'Cértegui',
      'Cerro de San Antonio',
      'Cerrito',
      'Cerinza',
      'Cereté',
      'Cepitá',
      'Caucasia',
      'Castilla La Nueva',
      'Casabianca',
      'Cartago',
      'Cartagena',
      'Carolina',
      'Carmen de Viboral',
      'Carmen de Carupa',
      'Carmen de Apicalá',
      'Carlosama',
      'Carcasí',
      'Caramanta',
      'Caracolí',
      'Cáqueza',
      'Capitanejo',
      'Caparrapí',
      'Cantagallo',
      'Candelaria',
      'Cañasgordas',
      'Canalete',
      'Campohermoso',
      'Campo de la Cruz',
      'Campoalegre',
      'Campamento',
      'Caloto',
      'Calimita',
      'California',
      'Cali',
      'Caldono',
      'Caldas',
      'Calarcá',
      'Calamar',
      'Cajicá',
      'Cajibío',
      'Cajamarca',
      'Caimito',
      'Caicedonia',
      'Caicedo',
      'Cácota',
      'Cáchira',
      'Cachipay',
      'Cáceres',
      'Cabuyaro',
      'Cabrera',
      'Busbanzá',
      'Bugalagrande',
      'Guadalajara de Buga',
      'Buesaco',
      'Buenos Aires',
      'Buenavista',
      'Buenaventura',
      'Bucarasica',
      'Bucaramanga',
      'Briceño',
      'Boyacá',
      'Bosconia',
      'Bolívar',
      'Bojacá',
      'Bogotá',
      'Bochalema',
      'Bocas de Satinga',
      'Curbaradó',
      'Boavita',
      'Bituima',
      'Betulia',
      'Betéitiva',
      'Beté',
      'Betania',
      'Arboleda',
      'Berbeo',
      'Beltrán',
      'Belmira',
      'Bello',
      'Belén de Umbría',
      'Belén de los Andaquíes',
      'Belén',
      'Becerril',
      'Barranquilla',
      'Barranco de Loba',
      'Barrancas',
      'Barranca de Upía',
      'Barrancabermeja',
      'Barichara',
      'Barbosa',
      'Barbacoas',
      'Baraya',
      'Baranoa',
      'Balboa',
      'Bahía Solano',
      'Bagadó',
      'Ayapel',
      'Ataco',
      'Astrea',
      'Arroyohondo',
      'Guayabal',
      'Armenia',
      'Arjona',
      'Ariguaní',
      'Argelia',
      'Arenal',
      'Arcabuco',
      'Arboletes',
      'Arboledas',
      'Arbeláez',
      'Arauquita',
      'Arauca',
      'Aratoca',
      'Aranzazu',
      'Aracataca',
      'Aquitania',
      'Apía',
      'Apartadó',
      'Anzoátegui',
      'Anzá',
      'Santa Fe de Antioquia',
      'Ansermanuevo',
      'Anserma',
      'Anorí',
      'Anolaima',
      'Angostura',
      'Angelópolis',
      'Andes',
      'Andalucía',
      'Ancuya',
      'Anapoima',
      'Anaime',
      'Ambalema',
      'Amalfi',
      'Amagá',
      'Alvarado',
      'Altamira',
      'Almeida',
      'Almaguer',
      'Algeciras',
      'Alejandría',
      'Aldana',
      'Alcalá',
      'Albania',
      'Albán',
      'Aipe',
      'Aguazul',
      'Agua de Dios',
      'Aguadas',
      'Aguachica',
      'Agrado',
      'Achí',
      'Acevedo',
      'Acandí',
      'Acacías',
      'Abriaquí',
      'Ábrego',
      'Abejorral',
      'Lejanías',
      'El Dorado',
      'Curillo',
      'Laureles',
      'Santacruz',
      'Campo Alegre',
      'Santiago Pérez',
      'El Tarra',
      'Labateca',
      'La Hormiga',
      'Mesetas',
      'Bellavista',
      'Chivor',
      'Monterrey',
      'Paratebueno',
      'Puerto Gaitán',
      'Quípama',
      'Fredonia',
      'Gachetá',
      'Buriticá',
      'Carepa',
      'Ciudad Bolívar',
      'Vegachí',
      'Altos del Rosario',
      'Santa Rosa del Sur',
      'Tiquisio',
      'Covarachía',
      'Cartagena del Chairá',
      'Agustín Codazzi',
      'Manaure Balcón del Cesar',
      'El Cantón de San Pablo',
      'El Retorno',
      'Plato',
      'Mapiripán',
      'Puerto Concordia',
      'La Tola',
      'Puerto Guzmán',
      'El Playón',
      'Santa Helena del Opón',
      'Carurú',
      'Santa Genoveva de Docordó',
      'Aguada',
      'La Macarena',
      'Valle del Guamuez',
      'San José del Fragua',
      'San José del Guaviare',
      'Orito',
      'Puerto Caicedo',
      'Solita',
      'Samaná',
      'Piamonte',
      'Alpujarra',
      'Dolores',
      'Piendamo',
      'Barrio San Luis',
      'Armero-Guyabal',
      'Sopetrán',
      'Socha',
      'Chitaraque',
      'San Martín de Loba',
      'El Carmen de Atrato',
      'Puerto Libertador',
      'La Apartada',
      'El Colegio',
      'Santa Cruz del Islote',
    ],
  },
  {
    country: 'Costa Rica',
    cities: [
      'Zarcero',
      'Zapotal',
      'Upala',
      'Turrialba',
      'Tucurrique',
      'Tres Ríos',
      'Tobosi',
      'Tilarán',
      'Tejar',
      'Sixaola',
      'Siquirres',
      'Sardinal',
      'Sarchí',
      'San Vito',
      'San Vicente',
      'Santo Domingo',
      'Santiago',
      'Santa Cruz',
      'Santa Ana',
      'San Ramón',
      'San Rafael Arriba',
      'San Rafael Abajo',
      'San Rafael',
      'San Pedro',
      'San Pablo',
      'San Miguel',
      'San Marcos',
      'San Juan de Dios',
      'San Juan',
      'San Josecito',
      'San José',
      'San Isidro',
      'San Ignacio',
      'San Francisco',
      'San Felipe',
      'San Diego',
      'San Antonio',
      'Sámara',
      'Salitrillos',
      'Salitral',
      'Sabanilla',
      'Sabalito',
      'Roxana',
      'Río Segundo',
      'Quesada',
      'Quepos',
      'Purral',
      'Puntarenas',
      'Limón',
      'Poco Sol',
      'Pocora',
      'Pital',
      'Pejibaye',
      'Patarrá',
      'Parrita',
      'Paraíso',
      'Paquera',
      'Palmichal',
      'Pacayas',
      'Orotina',
      'Orosi',
      'Nicoya',
      'Naranjo',
      'Nandayure',
      'Monterrey',
      'Miramar',
      'Mercedes Norte',
      'Mercedes',
      'Matina',
      'Los Chiles',
      'Llorente',
      'Liberia',
      'La Virgen',
      'La Suiza',
      'La Cruz',
      'La Asunción',
      'Juntas',
      'Jacó',
      'Ipís',
      'Hojancha',
      'Heredia',
      'Guápiles',
      'Guadalupe',
      'Guácimo',
      'Granadilla',
      'Golfito',
      'La Fortuna',
      'Esquipulas',
      'Esparza',
      'Escazú',
      'Desamparados',
      'Daniel Flores',
      'Curridabat',
      'Cot',
      'Corredor',
      'Concepción',
      'Colón',
      'Colima',
      'Puerto Cortés',
      'Chacarita',
      'Cartago',
      'Carrillos',
      'Canoas',
      'Cañas',
      'Calle Blancos',
      'Buenos Aires',
      'Bijagua',
      'Belén',
      'Bejuco',
      'Batán',
      'Barrio Jesús',
      'Bagaces',
      'Atenas',
      'Aserrí',
      'Ángeles',
      'Alajuelita',
      'Alajuela',
      'San Vicente de Moravia',
      'Tamarindo',
      'Cariari',
      'Dulce Nombre de Jesus',
    ],
  },
  {
    country: 'Cuba',
    cities: [
      'Zaza del Medio',
      'Yara',
      'Yaguajay',
      'Viñales',
      'Vertientes',
      'Venezuela',
      'Varadero',
      'San Germán',
      'Unión de Reyes',
      'Trinidad',
      'Topes de Collantes',
      'Taguasco',
      'Soroa',
      'Sibanicú',
      'Santo Domingo',
      'Santiago de las Vegas',
      'Santiago de Cuba',
      'Santa Cruz del Sur',
      'Santa Cruz del Norte',
      'Santa Clara',
      'San Nicolás de Bari',
      'San Miguel del Padrón',
      'San Luis',
      'San Juan y Martínez',
      'San José de las Lajas',
      'San Diego de Los Baños',
      'Sancti Spíritus',
      'San Cristobal',
      'San Antonio del Sur',
      'San Antonio de los Baños',
      'Sagua la Grande',
      'Sagua de Tánamo',
      'Rodas',
      'Río Guayabal de Yateras',
      'Río Cauto',
      'Remedios',
      'Regla',
      'Ranchuelo',
      'Rancho Veloz',
      'Santa Lucía',
      'Quivicán',
      'Quemado de Güines',
      'Puerto Padre',
      'Primero de Enero',
      'Placetas',
      'Pinar del Río',
      'Pilón',
      'Perico',
      'Pedro Betancourt',
      'Palmira',
      'Palma Soriano',
      'Palenque',
      'Nuevitas',
      'Nueva Paz',
      'Nueva Gerona',
      'Niquero',
      'Najasa',
      'Morón',
      'Moa',
      'Minas de Matahambre',
      'Minas',
      'Melena del Sur',
      'Media Luna',
      'Mayarí',
      'Matanzas',
      'Martí',
      'Mariel',
      'Manzanillo',
      'Mantua',
      'Manicaragua',
      'Manguito',
      'Manatí',
      'Majagua',
      'Maisí',
      'Madruga',
      'Los Palacios',
      'Los Arabos',
      'Limonar',
      'Las Tunas',
      'La Sierpe',
      'La Salud',
      'La Palma',
      'Lajas',
      'Havana',
      'Puerto Esperanza',
      'Jovellanos',
      'Jobabo',
      'Jiguaní',
      'Jesús Menéndez',
      'Jatibonico',
      'Jaruco',
      'Jamaica',
      'Jagüey Grande',
      'Isabela de Sagua',
      'Imías',
      'Holguín',
      'Guisa',
      'Güira de Melena',
      'Güines',
      'Guayos',
      'Guara',
      'Guantánamo',
      'Guane',
      'Guanajay',
      'Guanabacoa',
      'Guáimaro',
      'Mañalich',
      'Gibara',
      'Gaspar',
      'Fomento',
      'Florida',
      'Florencia',
      'Esperanza',
      'Esmeralda',
      'Encrucijada',
      'El Cobre',
      'El Caney',
      'Cumanayagua',
      'Cueto',
      'Cruces',
      'Corralillo',
      'Contramaestre',
      'Consolación del Sur',
      'Condado',
      'Colón',
      'Colombia',
      'Ciro Redondo',
      'Cifuentes',
      'Cienfuegos',
      'Ciego de Ávila',
      'Chambas',
      'Cerro',
      'Cayo Mambí',
      'Cauto Cristo',
      'Carlos Manuel de Céspedes',
      'Cárdenas',
      'Candelaria',
      'Campechuela',
      'Camajuaní',
      'Camagüey',
      'Calixto',
      'Calimete',
      'Calabazar de Sagua',
      'Caimito',
      'Caibarién',
      'Cacocum',
      'Cabañas',
      'Cabaiguán',
      'Buey Arriba',
      'Bolondrón',
      'Bolivia',
      'Bejucal',
      'Bayamo',
      'Bauta',
      'Batabanó',
      'Bartolomé Masó',
      'Baraguá',
      'Baracoa',
      'Banes',
      'Bahía Honda',
      'Báguanos',
      'Artemisa',
      'Arroyo Naranjo',
      'Antilla',
      'Amancio',
      'Alquízar',
      'Alamar',
      'Alacranes',
      'Aguada de Pasajeros',
      'Abreus',
      'Ciudad Camilo Cienfuegos',
      'Centro Habana',
      'La Habana Vieja',
      'Jimaguayú',
      'Boyeros',
      'Diez de Octubre',
      'Segundo Frente',
      'Cubitas',
      'La Máquina',
    ],
  },
  {
    country: 'Cape Verde',
    cities: [
      'Nova Sintra',
      'Vila do Maio',
      'Ribeira Brava',
      'Tarrafal de São Nicolau',
      'Tarrafal',
      'São Filipe',
      'São Domingos',
      'Santa Maria',
      'Santa Cruz',
      'Sal Rei',
      'Ribeira Grande',
      'Praia',
      'Porto Novo',
      'Igreja',
      'Ponta do Sol',
      'Pombas',
      'Picos',
      'Mindelo',
      'Cova Figueira',
      'Cidade Velha',
      'Calheta',
      'Assomada',
      'João Teves',
      'Pedra Badejo',
      'Espargos',
    ],
  },
  {
    country: 'Curacao',
    cities: ['Willemstad', 'Sint Michiel Liber', 'Dorp Soto', 'Barber'],
  },
  { country: 'Christmas Island', cities: ['Flying Fish Cove'] },
  {
    country: 'Cyprus',
    cities: [
      'Protaras',
      'Ýpsonas',
      'Geroskípou (quarter)',
      'Géri',
      'Xylotymbou',
      'Xylofágou',
      'Tséri',
      'Voróklini',
      'Tsáda',
      'Troúlloi',
      'Tróodos',
      'Tríkomo',
      'Tersefánou',
      'Tála',
      'Stróvolos',
      'Sotíra',
      'Rizokárpaso',
      'Pyrgos',
      'Pýla',
      'Psimolofou',
      'Psevdás',
      'Pólis',
      'Polémi',
      'Pissoúri',
      'Frénaros',
      'Pégeia',
      'Perivólia',
      'Peristeróna',
      'Pérgamos',
      'Péra',
      'Peléndri',
      'Parekklisha',
      'Paralímni',
      'Paphos',
      'Páno Polemídia',
      'Páno Defterá',
      'Páchna',
      'Nicosia',
      'Mouttagiáka',
      'Mosfilotí',
      'Mórfou',
      'Mesógi',
      'Meneou',
      'Meládeia',
      'Mámmari',
      'Lythrodóntas',
      'Lýmpia',
      'Livádia',
      'Liopétri',
      'Limassol',
      'Leonárisso',
      'Lefkónoiko',
      'Léfka',
      'Larnaca',
      'Lápithos',
      'Kyrenia',
      'Kyperoúnta',
      'Kórnos',
      'Kofínou',
      'Konia',
      'Kolossi',
      'Koloni',
      'Kokkinotrimithiá',
      'Klírou',
      'Kíti',
      'Kissonerga',
      'Chlórakas',
      'Káto Pýrgos',
      'Káto Defterá',
      'Kakopetriá',
      'Famagusta',
      'Erími',
      'Emba',
      'Dhromolaxia',
      'Derýneia',
      'Dáli',
      'Ágios Týchon',
      'Ayia Napa',
      'Avgórou',
      'Áchna',
      'Athíenou',
      'Astromerítis',
      'Ergátes',
      'Argáka',
      'Aradíppou',
      'Aredioú',
      'Alámpra',
      'Acherítou',
      'Akáki',
      'Méniko',
      'Geroskipou',
      'Tsada - Tremithousa - Tala (Borders)',
    ],
  },
  {
    country: 'Czech Republic',
    cities: [
      'Dvůr Králové nad Labem',
      'Zvole',
      'Žulová',
      'Zubří',
      'Zruč nad Sázavou',
      'Znojmo',
      'Žlutice',
      'Žlutava',
      'Zlonice',
      'Zliv',
      'Zlín',
      'Zlechov',
      'Žleby',
      'Zlaté Hory',
      'Žiželice',
      'Žitenice',
      'Žirovnice',
      'Žihle',
      'Židlochovice',
      'Žeravice',
      'Želiv',
      'Železný Brod',
      'Železnice',
      'Železná Ruda',
      'Želetava',
      'Želešice',
      'Zeleneč',
      'Žebrák',
      'Zdounky',
      'Zdíkov',
      'Zdice',
      'Zdiby',
      'Žďár nad Sázavou',
      'Žďár',
      'Ždánice',
      'Zbýšov',
      'Zbůch',
      'Zbraslavice',
      'Zbraslav',
      'Zborovice',
      'Zbiroh',
      'Zátor',
      'Žatec',
      'Zastávka',
      'Zašová',
      'Zásmuky',
      'Žandov',
      'Žamberk',
      'Zákupy',
      'Zaječov',
      'Zaječí',
      'Záhorovice',
      'Zadní Mostek',
      'Žacléř',
      'Zabrušany',
      'Zábřeh',
      'Žabčice',
      'Vyšší Brod',
      'Vysoké nad Jizerou',
      'Vysoké Mýto',
      'Vysočany',
      'Vyškov',
      'Vsetín',
      'Všetaty',
      'Všestary',
      'Všeruby',
      'Všenory',
      'Všemina',
      'Vroutek',
      'Vřesina',
      'Vrdy',
      'Vrchlabí',
      'Vrbovec',
      'Vrbno pod Pradědem',
      'Vrbice',
      'Vrbátky',
      'Vratimov',
      'Vranovice',
      'Vrané nad Vltavou',
      'Vracov',
      'Votice',
      'Volyně',
      'Volary',
      'Vojkovice',
      'Vodňany',
      'Vnorovy',
      'Vlkoš',
      'Vlčnov',
      'Vlašim',
      'Vladislav',
      'Vlachovo Březí',
      'Vlachovice',
      'Vizovice',
      'Vítkov',
      'Višňové',
      'Višňova',
      'Vintířov',
      'Viničné Šumice',
      'Vinařice',
      'Vimperk',
      'Vilémov',
      'Vikýřovice',
      'Vidnava',
      'Vidče',
      'Veverská Bítýška',
      'Větřní',
      'Vestec',
      'Veselí nad Moravou',
      'Veselí nad Lužnicí',
      'Veřovice',
      'Věrovany',
      'Verneřice',
      'Vendryně',
      'Velvary',
      'Veltrusy',
      'Veltruby',
      'Velký Újezd',
      'Velký Týnec',
      'Velký Šenov',
      'Velký Osek',
      'Velký Beranov',
      'Velké Svatoňovice',
      'Velké Přílepy',
      'Velké Poříčí',
      'Velké Popovice',
      'Velké Pavlovice',
      'Velké Opatovice',
      'Velké Němčice',
      'Velké Meziříčí',
      'Velké Losiny',
      'Velké Karlovice',
      'Velké Hoštice',
      'Velké Heraltice',
      'Velké Hamry',
      'Velké Březno',
      'Velké Bílovice',
      'Velká Polom',
      'Velká nad Veličkou',
      'Velká Hleďsebe',
      'Velká Dobrá',
      'Velká Bystřice',
      'Velká Bíteš',
      'Velim',
      'Velešín',
      'Velemín',
      'Velehrad',
      'Vejprty',
      'Vejprnice',
      'Včelná',
      'Varnsdorf',
      'Vápenná',
      'Vamberk',
      'Valtice',
      'Valdice',
      'Valašské Meziříčí',
      'Valašské Klobouky',
      'Valašská Polanka',
      'Valašská Bystřice',
      'Vacov',
      'Václavovice',
      'Vacenovice',
      'Úvaly',
      'Ústí nad Orlicí',
      'Ústí nad Labem',
      'Úštěk',
      'Úsov',
      'Určice',
      'Úpice',
      'Uničov',
      'Unhošť',
      'Únanov',
      'Újezd',
      'Uhlířské Janovice',
      'Uherský Ostroh',
      'Uherský Brod',
      'Uherské Hradiště',
      'Údlice',
      'Týn nad Vltavou',
      'Týniště nad Orlicí',
      'Týnec nad Sázavou',
      'Týnec nad Labem',
      'Týnec',
      'Tvrdonice',
      'Tvarožná',
      'Turnov',
      'Tupesy',
      'Tuchoměřice',
      'Tuchlovice',
      'Trutnov',
      'Tršice',
      'Troubsko',
      'Troubky',
      'Troubelice',
      'Trojanovice',
      'Trnava',
      'Trmice',
      'Třinec',
      'Trhový Štěpánov',
      'Trhové Sviny',
      'Třešť',
      'Třemošnice',
      'Třemošná',
      'Třebotov',
      'Třeboň',
      'Třebíč',
      'Třebenice',
      'Třebechovice pod Orebem',
      'Traplice',
      'Tovačov',
      'Toužim',
      'Topolná',
      'Tlumačov',
      'Tlučná',
      'Tišnov',
      'Tišice',
      'Tichá',
      'Těšetice',
      'Těšany',
      'Terezín',
      'Teplice nad Metují',
      'Teplice',
      'Telnice',
      'Telč',
      'Tečovice',
      'Tasovice',
      'Tanvald',
      'Tachov',
      'Tábor',
      'Svratka',
      'Svoboda nad Úpou',
      'Svitavy',
      'Svitávka',
      'Švihov',
      'Sviadnov',
      'Světlá nad Sázavou',
      'Svatava',
      'Sušice',
      'Šumperk',
      'Šumice',
      'Sudoměřice',
      'Sudkov',
      'Suchohrdly',
      'Suchdol nad Lužnicí',
      'Suchdol',
      'Suchá Loz',
      'Studénka',
      'Studenec',
      'Studená',
      'Strunkovice nad Blanicí',
      'Strmilov',
      'Střížkov',
      'Stříbro',
      'Střelice',
      'Stráž pod Ralskem',
      'Strážov',
      'Strážnice',
      'Stráž nad Nisou',
      'Strážná',
      'Stráž',
      'Strašice',
      'Strání',
      'Strančice',
      'Štramberk',
      'Strakonice',
      'Stonava',
      'Štoky',
      'Stod',
      'Stochov',
      'Štíty',
      'Štítná nad Vláří',
      'Štítina',
      'Stěžery',
      'Štětí',
      'Šternberk',
      'Štěpánov',
      'Štěpánkovice',
      'Štěnovice',
      'Štěchovice',
      'Stěbořice',
      'Starý Plzenec',
      'Starý Kolín',
      'Starý Jičín',
      'Starý Bohumín',
      'Staříč',
      'Staré Nechanice',
      'Staré Město',
      'Staré Křečany',
      'Staré Hradiště',
      'Stařeč',
      'Stará Ves nad Ondřejnicí',
      'Stará Paka',
      'Stará Huť',
      'Staňkov',
      'Šťáhlavy',
      'Stachy',
      'Srubec',
      'Spytihněv',
      'Špindlerův Mlýn',
      'Spálené Poříčí',
      'Solnice',
      'Sokolov',
      'Sokolnice',
      'Sobotka',
      'Sobotín',
      'Soběslav',
      'Smržovka',
      'Smržice',
      'Smiřice',
      'Smidary',
      'Smečno',
      'Slušovice',
      'Šluknov',
      'Slavonice',
      'Slavkov u Brna',
      'Slavkov',
      'Slavičín',
      'Slatinice',
      'Slatiňany',
      'Šlapanice',
      'Slaný',
      'Skuteč',
      'Skuhrov nad Bělou',
      'Skrbeň',
      'Skalná',
      'Šitbořice',
      'Šilheřovice',
      'Sezimovo Ústí',
      'Sezemice',
      'Ševětín',
      'Šestajovice',
      'Sepekov',
      'Šenov',
      'Senice na Hané',
      'Semily',
      'Sedlnice',
      'Sedliště',
      'Sedlice',
      'Sedlec',
      'Sedlčany',
      'Seč',
      'Sázava',
      'Šatov',
      'Satalice',
      'Šardice',
      'Šanov',
      'Samotíšky',
      'Šakvice',
      'Sadská',
      'Sadov',
      'Rýmařov',
      'Rychvald',
      'Rychnov nad Kněžnou',
      'Rybitví',
      'Rybí',
      'Rumburk',
      'Rudolfov',
      'Rudník',
      'Rudná',
      'Ruda nad Moravou',
      'Rtyně v Podkrkonoší',
      'Roztoky',
      'Rožnov pod Radhoštěm',
      'Rožmitál pod Třemšínem',
      'Rožďalovice',
      'Rovensko pod Troskami',
      'Rousínov',
      'Roudnice nad Labem',
      'Rouchovany',
      'Rotava',
      'Rosice',
      'Ropice',
      'Ronov nad Doubravou',
      'Rokytnice v Orlických Horách',
      'Rokytnice nad Jizerou',
      'Rokytnice',
      'Rokycany',
      'Rohatec',
      'Říčany',
      'Řež',
      'Řevničov',
      'Řevnice',
      'Řepiště',
      'Řehlovice',
      'Řečany nad Labem',
      'Ratíškovice',
      'Ratiboř',
      'Rataje',
      'Raspenava',
      'Raškovice',
      'Rapotín',
      'Rakvice',
      'Rakovník',
      'Rajhradice',
      'Rajhrad',
      'Ráječko',
      'Rájec-Jestřebí',
      'Radvanice',
      'Radslavice',
      'Radonice',
      'Radomyšl',
      'Radnice',
      'Radiměř',
      'Pyšely',
      'Pustiměř',
      'Pustá Polom',
      'Ptení',
      'Psáry',
      'Prusinovice',
      'Prušánky',
      'Průhonice',
      'Protivín',
      'Protivanov',
      'Prostřední Bečva',
      'Prostějov',
      'Prosek',
      'Proseč',
      'Proboštov',
      'Příšovice',
      'Přimda',
      'Příkazy',
      'Přibyslav',
      'Příbram',
      'Příbor',
      'Přeštice',
      'Přerov nad Labem',
      'Přerov',
      'Přemyslovice',
      'Přelouč',
      'Předměřice nad Labem',
      'Předklášteří',
      'Pražmo',
      'Přáslavice',
      'Prague',
      'Prachovice',
      'Prachatice',
      'Pozořice',
      'Pozlovice',
      'Povrly',
      'Potštát',
      'Postupice',
      'Postřelmov',
      'Postřekov',
      'Postoloprty',
      'Poříčí nad Sázavou',
      'Poříčany',
      'Popovice',
      'Poniklá',
      'Pomezí',
      'Polná',
      'Polička',
      'Police nad Metují',
      'Polešovice',
      'Polepy',
      'Pohořelice',
      'Podolí',
      'Podivín',
      'Poděbrady',
      'Podbořany',
      'Počátky',
      'Poběžovice',
      'Pilsen',
      'Plumlov',
      'Plesná',
      'Plavy',
      'Plasy',
      'Plánice',
      'Plaňany',
      'Planá nad Lužnicí',
      'Planá',
      'Píšť',
      'Písek',
      'Písečná',
      'Pilníkov',
      'Petřvald',
      'Petrovice u Karviné',
      'Petrovice',
      'Petrov',
      'Peruc',
      'Perštejn',
      'Pěnčín',
      'Pelhřimov',
      'Pečky',
      'Pecka',
      'Pchery',
      'Pavlíkov',
      'Paskov',
      'Paseka',
      'Pardubice',
      'Palkovice',
      'Pacov',
      'Otrokovice',
      'Otnice',
      'Otice',
      'Otaslavice',
      'Ostrožská Nová Ves',
      'Ostrožská Lhota',
      'Ostrov u Macochy',
      'Ostrov',
      'Ostroměř',
      'Ostravice',
      'Ostrava',
      'Ostopovice',
      'Osoblaha',
      'Oslavany',
      'Oskava',
      'Osík',
      'Osek nad Bečvou',
      'Osek',
      'Osečná',
      'Orlová',
      'Ořechov',
      'Opočno',
      'Opava',
      'Opatovice nad Labem',
      'Opatov',
      'Opařany',
      'Ondřejov',
      'Olšany',
      'Oloví',
      'Olomouc',
      'Olešnice',
      'Oldřišov',
      'Olbramovice',
      'Okrouhlice',
      'Okříšky',
      'Ohrazenice',
      'Odry',
      'Odolena Voda',
      'Obrnice',
      'Obecnice',
      'Nýrsko',
      'Nýřany',
      'Nymburk',
      'Nýdek',
      'Nový Malín',
      'Nový Knín',
      'Nový Jičín',
      'Nový Hrozenkov',
      'Nový Bydžov',
      'Nový Bor',
      'Novosedly',
      'Novosedlice',
      'Nové Veselí',
      'Nové Syrovice',
      'Nové Strašecí',
      'Nové Sedlo',
      'Nové Město pod Smrkem',
      'Nové Město na Moravě',
      'Nové Město nad Metují',
      'Nové Hrady',
      'Nová Včelnice',
      'Nová Role',
      'Nová Paka',
      'Nová Cerekev',
      'Nová Bystřice',
      'Nosislav',
      'Nižbor',
      'Nivnice',
      'Nezvěstice',
      'Nezamyslice',
      'Neveklov',
      'Netvořice',
      'Netolice',
      'Nesovice',
      'Neratovice',
      'Nepomuk',
      'Němčice nad Hanou',
      'Nemanice',
      'Nelahozeves',
      'Nejdek',
      'Nehvizdy',
      'Nedvědice',
      'Nedašov',
      'Nedakonice',
      'Nechanice',
      'Návsí u Jablunkova',
      'Nasavrky',
      'Napajedla',
      'Náměšť na Hané',
      'Náměšť nad Oslavou',
      'Náklo',
      'Náchod',
      'Načeradec',
      'Mýto',
      'Mutěnice',
      'Mukařov',
      'Mšeno',
      'Mrákov',
      'Moutnice',
      'Mosty u Jablunkova',
      'Mostkovice',
      'Mostek',
      'Most',
      'Mořkov',
      'Moravský Žižkov',
      'Moravský Písek',
      'Moravský Krumlov',
      'Moravský Beroun',
      'Moravské Budějovice',
      'Moravská Třebová',
      'Moravská Nová Ves',
      'Morávka',
      'Moravičany',
      'Moravany',
      'Mokré Lazce',
      'Mokrá Hora',
      'Mohelno',
      'Mohelnice',
      'Modřice',
      'Modřany',
      'Mníšek pod Brdy',
      'Mníšek',
      'Mnichovo Hradiště',
      'Mnichovice',
      'Mladé Buky',
      'Mladá Vožice',
      'Mladá Boleslav',
      'Mistřice',
      'Mirovice',
      'Mirotice',
      'Mirošov',
      'Miroslav',
      'Miřetice',
      'Mimoň',
      'Milovice',
      'Milotice',
      'Milín',
      'Milíkov',
      'Milevsko',
      'Mikulovice',
      'Mikulov',
      'Mikulčice',
      'Mikulášovice',
      'Meziměstí',
      'Meziboři',
      'Metylovice',
      'Město Touškov',
      'Město Libavá',
      'Město Albrechtice',
      'Teplá',
      'Městec Králové',
      'Městečko Trnávka',
      'Měšice',
      'Merklín',
      'Měřín',
      'Měnín',
      'Mělník',
      'Medlov',
      'Meclov',
      'Měčín',
      'Měcholupy',
      'Markvartovice',
      'Mariánské Lázně',
      'Manětín',
      'Malšice',
      'Malonty',
      'Malé Svatoňovice',
      'Malá Strana',
      'Malá Skála',
      'Majetín',
      'Machov',
      'Lysice',
      'Lysá nad Labem',
      'Lužná',
      'Lužice',
      'Lužec nad Vltavou',
      'Luže',
      'Lutín',
      'Luštěnice',
      'Lukov',
      'Lukavice',
      'Lukavec',
      'Luka nad Jihlavou',
      'Luhačovice',
      'Ludgeřovice',
      'Lučany nad Nisou',
      'Luby',
      'Lubenec',
      'Lovosice',
      'Louny',
      'Loučovice',
      'Loučná nad Desnou',
      'Loučeň',
      'Loštice',
      'Lom u Mostu',
      'Lomnice nad Popelkou',
      'Lomnice nad Lužnicí',
      'Lomnice',
      'Loket',
      'Loděnice',
      'Lochovice',
      'Litvínovice',
      'Litvínov',
      'Litovel',
      'Litomyšl',
      'Litoměřice',
      'Lišov',
      'Lipůvka',
      'Liptál',
      'Lipovec',
      'Lipov',
      'Lipník nad Bečvou',
      'Lípa',
      'Líně',
      'Lidečko',
      'Lichnov',
      'Lichnov (o. Nový Jičín)',
      'Libušín',
      'Libouchec',
      'Libochovice',
      'Libiš',
      'Libice nad Cidlinou',
      'Líbeznice',
      'Liběšice',
      'Liberec',
      'Libeň',
      'Libčice nad Vltavou',
      'Libavské Údolí',
      'Libáň',
      'Lhenice',
      'Letovice',
      'Letonice',
      'Letohrad',
      'Letňany',
      'Leština',
      'Lešná',
      'Lenešice',
      'Lelekovice',
      'Lednice',
      'Ledenice',
      'Ledeč nad Sázavou',
      'Lázně Kynžvart',
      'Lázně Bělohrad',
      'Lanžhot',
      'Lány',
      'Lanškroun',
      'Kynšperk nad Ohří',
      'Kyjov',
      'Kvasiny',
      'Kvasice',
      'Kutná Hora',
      'Kuřim',
      'Kunžak',
      'Kunvald',
      'Kunštát',
      'Kunovice',
      'Kunín',
      'Kunčina',
      'Kunčice pod Ondřejníkem',
      'Kryry',
      'Krupka',
      'Krumvíř',
      'Krouna',
      'Kroměříž',
      'Krnov',
      'Krmelín',
      'Krucemburk',
      'Křižanov',
      'Křinec',
      'Křešice',
      'Křepice',
      'Křenovice',
      'Křemže',
      'Kravaře',
      'Krásná Lípa',
      'Krásná Hora nad Vltavou',
      'Kraslice',
      'Králův Dvůr',
      'Kralupy nad Vltavou',
      'Kralovice',
      'Králíky',
      'Kralice na Hané',
      'Kozmice',
      'Kozlovice',
      'Kožlany',
      'Kovářská',
      'Kovářov',
      'Kout na Šumavě',
      'Kouřim',
      'Kostice',
      'Kostelec na Hané',
      'Kostelec nad Orlicí',
      'Kostelec nad Labem',
      'Kostelec nad Černými Lesy',
      'Košťany',
      'Košťálov',
      'Kosova Hora',
      'Kosmonosy',
      'Koryčany',
      'Kořenov',
      'Kopřivnice',
      'Kopidlno',
      'Konice',
      'Komorní Lhotka',
      'Komárov',
      'Kolinec',
      'Kolín',
      'Kokory',
      'Kojetín',
      'Kobylí',
      'Kobeřice',
      'Kněžpole',
      'Kněžmost',
      'Kněžice',
      'Kněždub',
      'Klobuky',
      'Klobouky',
      'Klimkovice',
      'Klenčí pod Čerchovem',
      'Klecany',
      'Klatovy',
      'Klášterec nad Ohří',
      'Klášter',
      'Kladruby',
      'Kladno',
      'Kelč',
      'Kdyně',
      'Kbely',
      'Kaznějov',
      'Katovice',
      'Kašperské Hory',
      'Kasejovice',
      'Karviná',
      'Karolinka',
      'Karlovy Vary',
      'Karlovice',
      'Karlín',
      'Kardašova Řečice',
      'Kaplice',
      'Kamenný Újezd',
      'Kamenný Přívoz',
      'Kamenné Žehrovice',
      'Kamenický Šenov',
      'Kamenice nad Lipou',
      'Kamenice',
      'Kájov',
      'Kadaň',
      'Kačice',
      'Josefův Důl',
      'Jistebník',
      'Jistebnice',
      'Jirny',
      'Jirkov',
      'Jiříkov',
      'Jindřichův Hradec',
      'Jindřichov',
      'Jince',
      'Jimramov',
      'Jílové u Prahy',
      'Jílové',
      'Jilemnice',
      'Jihlava',
      'Jičín',
      'Jevišovice',
      'Jevíčko',
      'Jeseník nad Odrou',
      'Jeseník',
      'Jesenice',
      'Jeneč',
      'Jemnice',
      'Jedovnice',
      'Jedlová',
      'Javorník',
      'Jarošov nad Nežárkou',
      'Jaroslavice',
      'Jaroměřice nad Rokytnou',
      'Jaroměřice',
      'Jaroměř',
      'Janov nad Nisou',
      'Janovice nad Úhlavou',
      'Janovice',
      'Jalubí',
      'Jakartovice',
      'Jáchymov',
      'Jablunkov',
      'Jablůnka',
      'Jablonné v Podještědí',
      'Jablonné nad Orlicí',
      'Jablonec nad Nisou',
      'Jablonec nad Jizerou',
      'Ivanovice na Hané',
      'Ivančice',
      'Hýskov',
      'Hvozdná',
      'Hustopeče Nad Bečvou',
      'Hustopeče',
      'Husinec',
      'Humpolec',
      'Hulín',
      'Hukvaldy',
      'Hudlice',
      'Hrušovany u Brna',
      'Hrušovany nad Jevišovkou',
      'Hrušky',
      'Hroznová Lhota',
      'Hroznětín',
      'Hrotovice',
      'Hronov',
      'Hromnice',
      'Hrochův Týnec',
      'Hrob',
      'Hřebeč',
      'Hrdějovice',
      'Hranice',
      'Hradištko',
      'Hrádek nad Nisou',
      'Hrádek',
      'Hradec nad Svitavou',
      'Hradec Králové',
      'Hradec nad Moravicí',
      'Hrabyně',
      'Hovorčovice',
      'Hovorany',
      'Hovězí',
      'Hostouň',
      'Hostomice',
      'Hošťka',
      'Hostivice',
      'Hostinné',
      'Hostěradice',
      'Hostavice',
      'Hošťálková',
      'Horšovský Týn',
      'Hořovice',
      'Horoměřice',
      'Horní Těrlicko',
      'Horní Suchá',
      'Horní Stropnice',
      'Horní Štěpánov',
      'Horní Sloupnice',
      'Horní Slavkov',
      'Horní Počernice',
      'Horní Počaply',
      'Horní Planá',
      'Horní Moštěnice',
      'Horní Město',
      'Horní Lideč',
      'Horní Jiřetín',
      'Horní Jelení',
      'Horní Čermná',
      'Horní Cerekev',
      'Horní Bříza',
      'Horní Branná',
      'Horní Bludovice',
      'Horní Benešov',
      'Horní Bečva',
      'Horka nad Moravou',
      'Hořice',
      'Horažďovice',
      'Holýšov',
      'Holoubkov',
      'Holice',
      'Holešov',
      'Holasovice',
      'Hodslavice',
      'Hodonín',
      'Hodonice',
      'Hodkovičky',
      'Hodkovice nad Mohelkou',
      'Hnojník',
      'Hněvotín',
      'Hněvošice',
      'Hluk',
      'Hlučín',
      'Hluboká nad Vltavou',
      'Hlubočky',
      'Hlohovec',
      'Hlinsko',
      'Hevlín',
      'Heřmanův Městec',
      'Heřmanova Huť',
      'Herálec',
      'Hejnice',
      'Hazlov',
      'Havlíčkův Brod',
      'Havířov',
      'Hať',
      'Hartmanice',
      'Harrachov',
      'Hanušovice',
      'Halenkovice',
      'Halenkov',
      'Háj ve Slezsku',
      'Habry',
      'Habartov',
      'Grygov',
      'Golčův Jeníkov',
      'Fulnek',
      'Fryšták',
      'Frymburk',
      'Frýdlant nad Ostravicí',
      'Frýdlant',
      'Frýdek-Místek',
      'Fryčovice',
      'Frenštát pod Radhoštěm',
      'Františkovy Lázně',
      'Francova Lhota',
      'Dýšina',
      'Dvorce',
      'Duchcov',
      'Dubné',
      'Dubňany',
      'Dub nad Moravou',
      'Dubicko',
      'Dubí',
      'Dubá',
      'Drnovice',
      'Drnholec',
      'Dřiteň',
      'Dřevohostice',
      'Drásov',
      'Drahanovice',
      'Doudleby nad Orlicí',
      'Doubravice nad Svitavou',
      'Doubrava',
      'Domažlice',
      'Domanín',
      'Doloplazy',
      'Dolní Životice',
      'Dolní Žandov',
      'Dolní Újezd',
      'Dolní Studénky',
      'Dolní Sloupnice',
      'Dolní Rychnov',
      'Dolní Roveň',
      'Dolní Poustevna',
      'Dolní Podluží',
      'Dolní Počernice',
      'Dolní Němčí',
      'Dolní Lutyně',
      'Dolní Loućky',
      'Dolní Lhota',
      'Dolní Kounice',
      'Dolní Dvořiště',
      'Dolní Dunajovice',
      'Dolní Dobrouč',
      'Dolní Černilov',
      'Dolní Čermná',
      'Dolní Cerekev',
      'Dolní Bukovsko',
      'Dolní Břežany',
      'Dolní Bousov',
      'Dolní Bojanovice',
      'Dolní Bohdíkov',
      'Dolní Beřkovice',
      'Dolní Benešov',
      'Dolní Bečva',
      'Dolany',
      'Doksy',
      'Dobšice',
      'Dobruška',
      'Dobrovice',
      'Dobronín',
      'Dobroměřice',
      'Dobřív',
      'Dobříš',
      'Dobřichovice',
      'Dobratice',
      'Dobřany',
      'Dobrá',
      'Dlouhá Třebová',
      'Divišov',
      'Dětmarovice',
      'Desná',
      'Děčín',
      'Davle',
      'Dašice',
      'Darkovice',
      'Dambořice',
      'Dalovice',
      'Dačice',
      'Cvikov',
      'Čkyně',
      'Čížkovice',
      'Cítov',
      'Církvice',
      'Čimelice',
      'Chyšky',
      'Chýnov',
      'Chyňava',
      'Chvalšiny',
      'Chvaletice',
      'Chvalčov',
      'Chuchelná',
      'Chrudim',
      'Chroustovice',
      'Chropyně',
      'Chřibská',
      'Chrastava',
      'Chrast',
      'Chrást',
      'Chotoviny',
      'Chotěšov',
      'Chotěbuz',
      'Chotěboř',
      'Chomutov',
      'Chodová Planá',
      'Chodov',
      'Choceň',
      'Chlum u Třeboně',
      'Chlumec nad Cidlinou',
      'Chlumec',
      'Chlumčany',
      'Chlebičov',
      'Cheb',
      'Chabařovice',
      'Český Těšín',
      'Český Rudolec',
      'Český Krumlov',
      'Český Dub',
      'Český Brod',
      'České Velenice',
      'České Meziříčí',
      'České Budějovice',
      'Česká Ves',
      'Česká Třebová',
      'Česká Skalice',
      'Česká Lípa',
      'Česká Kamenice',
      'Červený Kostelec',
      'Červenka',
      'Červené Pečky',
      'Červená Voda',
      'Černožice',
      'Černovice',
      'Černošín',
      'Černošice',
      'Černilov',
      'Černčice',
      'Černá Hora',
      'Cerhenice',
      'Čerčany',
      'Čelechovice na Hané',
      'Čelákovice',
      'Čeladná',
      'Čejkovice',
      'Čejč',
      'Čechtice',
      'Čebín',
      'Častolovice',
      'Čáslav',
      'Bzenec',
      'Bystřice pod Hostýnem',
      'Bystřice nad Pernštejnem',
      'Bystřice',
      'Bystřec',
      'Bystré',
      'Bystřany',
      'Býšť',
      'Byšice',
      'Buštěhrad',
      'Bukovec',
      'Bukovany',
      'Budyně nad Ohří',
      'Budišov nad Budišovkou',
      'Budišov',
      'Bučovice',
      'Buchlovice',
      'Brušperk',
      'Bruntál',
      'Brumovice',
      'Brtnice',
      'Broumov',
      'Brodek u Přerova',
      'Brno',
      'Brniště',
      'Brněnec',
      'Brloh',
      'Břidličná',
      'Březová nad Svitavou',
      'Březová',
      'Březolupy',
      'Březno',
      'Březnice',
      'Březí',
      'Břeclav',
      'Břasy',
      'Brantice',
      'Braník',
      'Brandýs nad Orlicí',
      'Brandýs nad Labem-Stará Boleslav',
      'Brandýsek',
      'Braňany',
      'Božice',
      'Bouzov',
      'Bošovice',
      'Boskovice',
      'Boršice',
      'Borovany',
      'Borová Lada',
      'Borohrádek',
      'Bořitov',
      'Bořetice',
      'Borek',
      'Bor',
      'Bolatice',
      'Bojkovice',
      'Bohutín',
      'Bohušovice nad Ohří',
      'Bohuslavice',
      'Bohuňovice',
      'Bohumín',
      'Bohdalov',
      'Bochov',
      'Bludov',
      'Blučina',
      'Blovice',
      'Blížkovice',
      'Blížejov',
      'Blažovice',
      'Blatná',
      'Blansko',
      'Bílovice nad Svitavou',
      'Bílovice',
      'Bílovec',
      'Bílina',
      'Bílá Třemešná',
      'Bílá Lhota',
      'Bezdružice',
      'Beroun',
      'Bernartice',
      'Benešov nad Ploučnicí',
      'Benešov nad Černou',
      'Benešov',
      'Benecko',
      'Benátky nad Jizerou',
      'Bělotín',
      'Bělá pod Bezdězem',
      'Bělá nad Radbuzou',
      'Bedihošť',
      'Bečov',
      'Bechyně',
      'Bechlín',
      'Bavorov',
      'Batelov',
      'Baška',
      'Bartošovice',
      'Bánov',
      'Bakov nad Jizerou',
      'Babice',
      'Aš',
      'Albrechtice nad Orlicí',
      'Albrechtice',
      'Adamov',
      'Abertamy',
      'Lázně Bohdaneč',
      'Černý Most',
      'Lučina',
      'Vysehrad',
      'Provodov-Šonov',
    ],
  },
  {
    country: 'Germany',
    cities: [
      'Zwota',
      'Zwönitz',
      'Zwochau',
      'Zwingenberg',
      'Zwiesel',
      'Zwiefalten',
      'Zwickau',
      'Zwenkau',
      'Zweiflingen',
      'Zweibrücken',
      'Zuzenhausen',
      'Züssow',
      'Zusmarshausen',
      'Zusamaltheim',
      'Zurow',
      'Zülpich',
      'Zschortau',
      'Zschornewitz',
      'Zschorlau',
      'Zschopau',
      'Zscherndorf',
      'Zscherben',
      'Zschepplin',
      'Zschadrass',
      'Zossen',
      'Zöschen',
      'Zornheim',
      'Zorneding',
      'Zorge',
      'Zörbig',
      'Zons',
      'Zolling',
      'Zöblitz',
      'Zittau',
      'Zirndorf',
      'Ostseebad Zinnowitz',
      'Zinna',
      'Zingst',
      'Zimmern ob Rottweil',
      'Ziltendorf',
      'Ziesendorf',
      'Ziesar',
      'Ziertheim',
      'Zierenberg',
      'Ziemetshausen',
      'Zielitz',
      'Zeven',
      'Zeuthen',
      'Zeulenroda',
      'Zetel',
      'Zernien',
      'Zerf',
      'Zerbst',
      'Zenting',
      'Zemmer',
      'Zell unter Aichelberg',
      'Zellingen',
      'Zell im Wiesental',
      'Zell am Main',
      'Zella-Mehlis',
      'Zell im Fichtelgebirge',
      'Zell',
      'Zeitz',
      'Zeitlofs',
      'Zeitlarn',
      'Zeithain',
      'Zeiskam',
      'Zeilarn',
      'Zeil',
      'Zehlendorf',
      'Zehdenick',
      'Zarrentin',
      'Zarrendorf',
      'Zarpen',
      'Zappendorf',
      'Zapfendorf',
      'Zangberg',
      'Zandt',
      'Zaisenhausen',
      'Zahna',
      'Zachenberg',
      'Zaberfeld',
      'Zabeltitz',
      'Xanten',
      'Wyk auf Föhr',
      'Wyhl',
      'Wutöschingen',
      'Wustrow',
      'Wusterwitz',
      'Wustermark',
      'Wusterhusen',
      'Wusterhausen',
      'Wüstenrot',
      'Wurzen',
      'Würzburg',
      'Wurzbach',
      'Würselen',
      'Wurmsham',
      'Wurmlingen',
      'Wurmberg',
      'Wurmannsquick',
      'Wuppertal',
      'Wunstorf',
      'Wunsiedel',
      'Wünschendorf',
      'Bad Wünnenberg',
      'Wulsbüttel',
      'Wülknitz',
      'Wulkenzin',
      'Wulften',
      'Wulfsen',
      'Wülfrath',
      'Wülfershausen',
      'Wulfen',
      'Wrist',
      'Wriezen',
      'Wriedel',
      'Wrestedt',
      'Wremen',
      'Wössingen',
      'Wörthsee',
      'Wörth an der Isar',
      'Wörth an der Donau',
      'Wörth am Rhein',
      'Wörth am Main',
      'Wört',
      'Wörrstadt',
      'Worpswede',
      'Worms',
      'Wörlitz',
      'Woringen',
      'Worbis',
      'Wonsees',
      'Wonfurt',
      'Woltersdorf',
      'Wolsdorf',
      'Wölpinghausen',
      'Wolpertswende',
      'Wolpertshausen',
      'Wolnzach',
      'Wolmirstedt',
      'Wolmirsleben',
      'Wöllstein',
      'Wollmatingen',
      'Wollbach',
      'Wolkramshausen',
      'Wolkenstein',
      'Wolken',
      'Wolgast',
      'Wolfstein',
      'Wolfsegg',
      'Wolfschlugen',
      'Wolfsburg',
      'Wolfratshausen',
      'Wolframs-Eschenbach',
      'Wölfis',
      'Wolfhagen',
      'Wolfertschwenden',
      'Wolferstadt',
      'Wölfersheim',
      'Wolfersdorf',
      'Wolferode',
      'Wolfenbüttel',
      'Wolfen',
      'Wolfegg',
      'Wolfach',
      'Woldegk',
      'Wöhrden',
      'Wohnbach',
      'Wohltorf',
      'Wohldorf-Ohlstedt',
      'Witzmannsberg',
      'Witzhave',
      'Witzenhausen',
      'Wittstock',
      'Wittorf',
      'Wittnau',
      'Wittmund',
      'Wittmar',
      'Wittlich',
      'Wittislingen',
      'Wittingen',
      'Wittichenau',
      'Wittgensdorf',
      'Witterda',
      'Wittenhagen',
      'Wittenförden',
      'Wittenburg',
      'Wittenberge',
      'Wittenau',
      'Witten',
      'Wittelshofen',
      'Wistedt',
      'Wissen',
      'Wismar',
      'Wischhafen',
      'Wirsberg',
      'Wirges',
      'Wirdum',
      'Wippra',
      'Wipperfürth',
      'Wipperdorf',
      'Wipfeld',
      'Winzer',
      'Wintersdorf',
      'Winterlingen',
      'Winterhude',
      'Winterhausen',
      'Winterberg',
      'Winterbach',
      'Winsen',
      'Winnweiler',
      'Winningen',
      'Winnenden',
      'Winklarn',
      'Winkelhaid',
      'Winhöring',
      'Wingst',
      'Wingerode',
      'Windsbach',
      'Windischleuba',
      'Windischeschenbach',
      'Windhausen',
      'Windhagen',
      'Windesheim',
      'Winden',
      'Windelsbach',
      'Windeby',
      'Windberg',
      'Windach',
      'Wincheringen',
      'Wimsheim',
      'Wimmelburg',
      'Wiltingen',
      'Wilthen',
      'Wilsum',
      'Wilster',
      'Wilstedt',
      'Wilsdruff',
      'Wilnsdorf',
      'Wilmersdorf',
      'Willstätt',
      'Willmering',
      'Willingshausen',
      'Willingen',
      'Willich',
      'Willebadessen',
      'Willanzheim',
      'Wilkau-Haßlau',
      'Wilhermsdorf',
      'Wilhelmsthal',
      'Wilhelmstadt',
      'Wilhelmsruh',
      'Wilhelmshaven',
      'Wilhelmsfeld',
      'Wilhelmsdorf',
      'Wilhelmsburg',
      'Wilgartswiesen',
      'Wildsteig',
      'Wildpoldsried',
      'Wildflecken',
      'Wildeshausen',
      'Wildenhain',
      'Wildenfels',
      'Wildenberg',
      'Wildemann',
      'Wildberg',
      'Bad Wildbad',
      'Wilburgstetten',
      'Wiggensbach',
      'Wietzendorf',
      'Wietzen',
      'Wietze',
      'Wietmarschen',
      'Wiesthal',
      'Wiesmoor',
      'Wiesloch',
      'Wiesentheid',
      'Wiesenthau',
      'Wiesent',
      'Wiesensteig',
      'Wiesenfelden',
      'Wiesenburg',
      'Wiesenbach',
      'Wiesenau',
      'Wiesen',
      'Wiesbaden',
      'Wiesau',
      'Wiesa',
      'Wiernsheim',
      'Wieren',
      'Wienhausen',
      'Wiemersdorf',
      'Wiek',
      'Wiehl',
      'Wiehe',
      'Wiefelstede',
      'Wiednitz',
      'Wiederstedt',
      'Wiedergeltingen',
      'Wiedensahl',
      'Wiedemar',
      'Wieda',
      'Widdern',
      'Wickede',
      'Weyhausen',
      'Weyerbusch',
      'Weyarn',
      'Wewelsfleth',
      'Wetzlar',
      'Wetzendorf',
      'Wettringen',
      'Wettin',
      'Wetterzeube',
      'Wetter',
      'Wetschen',
      'Westhofen',
      'Westheim',
      'Westhausen',
      'Westerstetten',
      'Westerstede',
      'Westerrönfeld',
      'Wester-Ohrstedt',
      'Westerland',
      'Westerkappeln',
      'Westerhorn',
      'Westerholt',
      'Westerheim',
      'Westerhausen',
      'Westergellersen',
      'Westeregeln',
      'Westerburg',
      'Westensee',
      'Westendorf',
      'Westend',
      'Weste',
      'Wessobrunn',
      'Weßling',
      'Wesseln',
      'Wesseling',
      'Wesselburen',
      'Wesendorf',
      'Wesenberg',
      'Weselberg',
      'Wesel',
      'Wertingen',
      'Werther',
      'Wertheim',
      'Wertach',
      'Wersten',
      'Werpeloh',
      'Wernshausen',
      'Wernigerode',
      'Werneuchen',
      'Wernersberg',
      'Werneck',
      'Werne',
      'Wernberg-Köblitz',
      'Wernau',
      'Wermsdorf',
      'Wermelskirchen',
      'Werlte',
      'Werl',
      'Werftpfuhl',
      'Werdohl',
      'Werder',
      'Werdau',
      'Werda',
      'Werben',
      'Werbach',
      'Wenzendorf',
      'Wenzenbach',
      'Wentorf bei Hamburg',
      'Wennigsen',
      'Weng',
      'Wendtorf',
      'Wendorf',
      'Wendlingen am Neckar',
      'Wendisch Rietz',
      'Wendisch Evern',
      'Wenden',
      'Wendelstein',
      'Wendelsheim',
      'Wendeburg',
      'Wemding',
      'Welzow',
      'Welzheim',
      'Welver',
      'Welsleben',
      'Welschbillig',
      'Wellingsbüttel',
      'Wellendingen',
      'Wellen',
      'Welle',
      'Welden',
      'Weitramsdorf',
      'Weitnau',
      'Weiterstadt',
      'Weitersburg',
      'Weitenhagen',
      'Weiten',
      'Weitefeld',
      'Weisweil',
      'Weißwasser',
      'Weißkeißel',
      'Weißig',
      'Weißenthurm',
      'Weißenstadt',
      'Weißensee',
      'Weißensberg',
      'Weißenohe',
      'Weißenhorn',
      'Weißenfels',
      'Weißenburg in Bayern',
      'Weißenbrunn',
      'Weißenborn-Lüderode',
      'Weißenborn',
      'Weißenberg',
      'Weißdorf',
      'Weißbach',
      'Weißandt-Gölzau',
      'Weissach',
      'Weismain',
      'Weiskirchen',
      'Weisendorf',
      'Weisenbach',
      'Weisen',
      'Weisel',
      'Weischlitz',
      'Weinstadt-Endersbach',
      'Weinsheim',
      'Weinsberg',
      'Weinheim',
      'Weingarten',
      'Weinböhla',
      'Weinbach',
      'Weimar',
      'Weiltingen',
      'Weilmünster',
      'Weil im Schönbuch',
      'Weilheim an der Teck',
      'Weilheim',
      'Weilerswist',
      'Weilerbach',
      'Weiler',
      'Weil der Stadt',
      'Weilburg',
      'Weilbach',
      'Weil am Rhein',
      'Weil',
      'Weikersheim',
      'Weihmichl',
      'Weiherhammer',
      'Weihenzell',
      'Weigendorf',
      'Weiding',
      'Weidhausen bei Coburg',
      'Weidenthal',
      'Weidenstetten',
      'Weidenbach',
      'Weiden',
      'Weida',
      'Weichs',
      'Weichering',
      'Weibersbrunn',
      'Weibern',
      'Wehringen',
      'Wehrheim',
      'Wehrda',
      'Wehr',
      'Wehingen',
      'Wegscheid',
      'Wegeleben',
      'Wegberg',
      'Weferlingen',
      'Wefensleben',
      'Weeze',
      'Wees',
      'Weener',
      'Wedel',
      'Weddingstedt',
      'Wedding',
      'Weddersleben',
      'Weddelbrook',
      'Wechselburg',
      'Wechingen',
      'Waxweiler',
      'Wattmannshagen',
      'Wattenheim',
      'Wattenbek',
      'Wathlingen',
      'Watenbüttel',
      'Wasungen',
      'Wassertrüdingen',
      'Wasserlosen',
      'Wasserliesch',
      'Wasserleben',
      'Wasserburg am Inn',
      'Wasserburg',
      'Wassenberg',
      'Wassenach',
      'Wäschenbeuren',
      'Wasbüttel',
      'Wasbek',
      'Wartmannsroth',
      'Warthausen',
      'Wartenberg',
      'Warstein',
      'Warnow',
      'Warngau',
      'Warnemünde',
      'Warmsen',
      'Warmensteinach',
      'Warin',
      'Warendorf',
      'Waren',
      'Wardow',
      'Wardenburg',
      'Warburg',
      'Wanzleben',
      'Wansleben',
      'Wannweil',
      'Wannsee',
      'Wanna',
      'Wankendorf',
      'Wangerooge',
      'Wangen',
      'Wangels',
      'Wang',
      'Wanfried',
      'Wandlitz',
      'Wanderup',
      'Wandersleben',
      'Waltrop',
      'Waltershausen',
      'Waltenhofen',
      'Walsrode',
      'Walsdorf',
      'Walschleben',
      'Walpertskirchen',
      'Wallwitz',
      'Wallmerod',
      'Wallmenroth',
      'Wallhausen',
      'Wallgau',
      'Wallertheim',
      'Wallerstein',
      'Wallersdorf',
      'Wallerfing',
      'Wallerfangen',
      'Wallenhorst',
      'Wallenfels',
      'Wallendorf',
      'Walldürn',
      'Walldorf',
      'Walkertshofen',
      'Walkenried',
      'Walheim',
      'Waldthurn',
      'Waldstetten',
      'Waldsieversdorf',
      'Waldshut-Tiengen',
      'Waldsee',
      'Waldsassen',
      'Waldrach',
      'Waldmünchen',
      'Waldmohr',
      'Wald-Michelbach',
      'Waldkraiburg',
      'Waldkirchen',
      'Waldkirch',
      'Waldkappel',
      'Waldheim',
      'Waldfischbach-Burgalben',
      'Waldfeucht',
      'Waldershof',
      'Walderbach',
      'Waldenburg',
      'Waldenbuch',
      'Waldeck',
      'Waldbüttelbrunn',
      'Waldburg',
      'Waldbrunn',
      'Waldbröl',
      'Waldbreitbach',
      'Waldböckelheim',
      'Waldaschaff',
      'Waldalgesheim',
      'Wald',
      'Walchum',
      'Waischenfeld',
      'Wain',
      'Waigolshausen',
      'Waidhofen',
      'Waidhaus',
      'Waibstadt',
      'Waiblingen',
      'Wahrenholz',
      'Wahn-Heide',
      'Wahlstedt',
      'Wahlitz',
      'Waging am See',
      'Waghäusel',
      'Waggum',
      'Wagenhoff',
      'Wagenfeld',
      'Waffenbrunn',
      'Wadgassen',
      'Wadersloh',
      'Wadern',
      'Wackersdorf',
      'Wackersberg',
      'Wackerow',
      'Wackernheim',
      'Wacken',
      'Wächtersbach',
      'Wachtendonk',
      'Wachtberg',
      'Wachenroth',
      'Wachenheim',
      'Wachau',
      'Wabern',
      'Waal',
      'Waakirchen',
      'Waake',
      'Waabs',
      'Vrees',
      'Vreden',
      'Vorwerk',
      'Vörstetten',
      'Vorra',
      'Vordorf',
      'Vorbach',
      'Volxheim',
      'Voltlage',
      'Völpke',
      'Vollersode',
      'Volkstedt',
      'Volksdorf',
      'Volkmarsen',
      'Volkmarode',
      'Völklingen',
      'Volkertshausen',
      'Völkershausen',
      'Volkenschwand',
      'Völkenrode',
      'Volkach',
      'Voigtstedt',
      'Vöhringen',
      'Vöhrenbach',
      'Vöhl',
      'Vohenstrauß',
      'Vohburg an der Donau',
      'Vogtareuth',
      'Vogt',
      'Vögelsen',
      'Voerde',
      'Vockerode',
      'Vlotho',
      'Visselhövede',
      'Visbek',
      'Viöl',
      'Vinningen',
      'Vilshofen',
      'Vilsheim',
      'Vilseck',
      'Vilsbiburg',
      'Villmar',
      'Villingen-Schwenningen',
      'Villingendorf',
      'Villenbach',
      'Vilgertshofen',
      'Viersen',
      'Viernheim',
      'Viernau',
      'Vierkirchen',
      'Viereck',
      'Vienenburg',
      'Viechtach',
      'Vettweiß',
      'Vettelschoß',
      'Vetschau',
      'Vestenbergsgreuth',
      'Versmold',
      'Verl',
      'Veringenstadt',
      'Verden',
      'Venusberg',
      'Veltheim',
      'Veltenhof',
      'Velten',
      'Velpke',
      'Vellberg',
      'Velgast',
      'Velen',
      'Velden',
      'Velburg',
      'Velbert',
      'Veitshöchheim',
      'Veitsbronn',
      'Veilsdorf',
      'Vegesack',
      'Veckenstedt',
      'Vechta',
      'Vechelde',
      'Vaterstetten',
      'Varrel',
      'Varel',
      'Valley',
      'Vallendar',
      'Vaihingen an der Enz',
      'Vagen',
      'Vachendorf',
      'Vacha',
      'Vaale',
      'Üxheim',
      'Utting am Ammersee',
      'Uttenweiler',
      'Uttenreuth',
      'Uthlede',
      'Uthleben',
      'Uetersen',
      'Ustersbach',
      'Uslar',
      'Usingen',
      'Usedom',
      'Urspringen',
      'Ursensollen',
      'Ursberg',
      'Urmitz',
      'Urbar',
      'Urbach-Überdorf',
      'Urbach',
      'Bad Urach',
      'Upgant-Schott',
      'Untrasried',
      'Unterwössen',
      'Unterwellenborn',
      'Unterthingau',
      'Untersteinach',
      'Untersiemau',
      'Unterschneidheim',
      'Unterschleißheim',
      'Unterreit',
      'Unterreichenbach',
      'Unterpleichfeld',
      'Unterneukirchen',
      'Untermünkheim',
      'Untermerzbach',
      'Untermeitingen',
      'Untermaßfeld',
      'Unterlüß',
      'Unterleinleiter',
      'Unterkirnach',
      'Unterjettingen',
      'Unterhausen',
      'Unterhaching',
      'Untergruppenbach',
      'Untergriesbach',
      'Unterföhring',
      'Unterensingen',
      'Untereisesheim',
      'Unteregg',
      'Unterdietfurt',
      'Unterdießen',
      'Unterbreizbach',
      'Unterkrozingen',
      'Unterammergau',
      'Unter-Abtsteinach',
      'Unseburg',
      'Unnau',
      'Unna',
      'Unlingen',
      'Unkel',
      'Ungerhausen',
      'Undenheim',
      'Ummern',
      'Ummendorf',
      'Umkirch',
      'Ulrichstein',
      'Ulmen',
      'Ulm',
      'Uichteritz',
      'Uhyst',
      'Uehrde',
      'Uehlfeld',
      'Uhingen',
      'Uftrungen',
      'Uffing',
      'Uffenheim',
      'Uetze',
      'Uettingen',
      'Uerdingen',
      'Uenglingen',
      'Uelzen',
      'Uelversheim',
      'Uelsen',
      'Uedem',
      'Ueckermünde',
      'Uebigau',
      'Üdersdorf',
      'Uder',
      'Udenheim',
      'Udenhausen',
      'Uchtspringe',
      'Üchtelhausen',
      'Uchte',
      'Übersee',
      'Überlingen',
      'Überherrn',
      'Übach-Palenberg',
      'Tschernitz',
      'Tyrlaching',
      'Twistringen',
      'Tutzing',
      'Tuttlingen',
      'Tüttendorf',
      'Tutow',
      'Tüßling',
      'Tussenhausen',
      'Türnich',
      'Türkheim',
      'Türkenfeld',
      'Tuntenhausen',
      'Tuningen',
      'Tungendorf',
      'Tülau',
      'Tuchenbach',
      'Tucheim',
      'Tübingen',
      'Trusetal',
      'Trunkelsberg',
      'Trulben',
      'Trostberg an der Alz',
      'Tröstau',
      'Trossingen',
      'Trossin',
      'Trollenhagen',
      'Troisdorf',
      'Trogen',
      'Trochtelfingen',
      'Trittenheim',
      'Trittau',
      'Triptis',
      'Trippstadt',
      'Trinwillershagen',
      'Triftern',
      'Trierweiler',
      'Trier',
      'Triebes',
      'Tribsees',
      'Triberg',
      'Treuenbrietzen',
      'Treuen',
      'Treuchtlingen',
      'Alt-Treptow',
      'Trendelburg',
      'Tremsbüttel',
      'Treis-Karden',
      'Treia',
      'Treffurt',
      'Treffelstein',
      'Trechtingshausen',
      'Trebur',
      'Trebsen',
      'Trebitz',
      'Trebgast',
      'Trebendorf',
      'Trebbin',
      'Travemünde',
      'Trautskirchen',
      'Trausnitz',
      'Traunstein',
      'Traunreut',
      'Trassem',
      'Trappstadt',
      'Trappenkamp',
      'Traitsching',
      'Train',
      'Trabitz',
      'Traben-Trarbach',
      'Tostedt',
      'Tornesch',
      'Torgelow',
      'Torgau',
      'Toppenstedt',
      'Töpen',
      'Tönning',
      'Tönisvorst',
      'Tollwitz',
      'Tolk',
      'Töging am Inn',
      'Todtnau',
      'Todtmoos',
      'Todtenweis',
      'Todesfelde',
      'Todendorf',
      'Todenbüttel',
      'Titz',
      'Tittmoning',
      'Tittling',
      'Titisee-Neustadt',
      'Tirschenreuth',
      'Tirpersdorf',
      'Tinnum',
      'Timmerlah',
      'Timmenrode',
      'Timmendorfer Strand',
      'Timmaspe',
      'Tiergarten',
      'Tiefenort',
      'Tiefenbronn',
      'Tiefenbach',
      'Tiddische',
      'Thyrnau',
      'Thurnau',
      'Thurmansbang',
      'Thür',
      'Thüngersheim',
      'Thüngen',
      'Thune',
      'Thundorf in Unterfranken',
      'Thum',
      'Thuine',
      'Thomm',
      'Thomasburg',
      'Tholey',
      'Thierstein',
      'Thiersheim',
      'Thierhaupten',
      'Thiendorf',
      'Theuma',
      'Themar',
      'Theißen',
      'Theisseil',
      'Theilheim',
      'Thedinghausen',
      'Tharandt',
      'Thanstein',
      'Thannhausen',
      'Thalmässing',
      'Thalmassing',
      'Thallwitz',
      'Thalheim',
      'Thaleischweiler-Fröschen',
      'Thale',
      'Thal',
      'Teutschenthal',
      'Teuschnitz',
      'Teupitz',
      'Teunz',
      'Teugn',
      'Teuchern',
      'Teuchel',
      'Teublitz',
      'Tettnang',
      'Tettenweis',
      'Tettau',
      'Teterow',
      'Tessin',
      'Tespe',
      'Tennenbronn',
      'Teningen',
      'Tengen',
      'Templin',
      'Tempelhof',
      'Teltow',
      'Tellingstedt',
      'Telgte',
      'Teistungen',
      'Teisnach',
      'Teising',
      'Teisendorf',
      'Teichwolframsdorf',
      'Teicha',
      'Tegernsee',
      'Tegernheim',
      'Tegel',
      'Tecklenburg',
      'Tawern',
      'Tautenhain',
      'Tauscha',
      'Taura',
      'Taunusstein',
      'Taufkirchen',
      'Tauche',
      'Taucha',
      'Tauberbischofsheim',
      'Tating',
      'Markt Taschendorf',
      'Tarp',
      'Tarnow',
      'Tarmstedt',
      'Tappenbeck',
      'Tapfheim',
      'Tannheim',
      'Tannhausen',
      'Tännesberg',
      'Tannenbergsthal',
      'Tannenberg',
      'Tanna',
      'Tann',
      'Tangstedt',
      'Tangermünde',
      'Tangerhütte',
      'Tamm',
      'Tambach-Dietharz',
      'Talheim',
      'Tagmersheim',
      'Täferrot',
      'Taching am See',
      'Tacherting',
      'Tabarz',
      'Syrau',
      'Syke',
      'Sustrum',
      'Süstedt',
      'Süßen',
      'Süsel',
      'Surwold',
      'Surberg',
      'Süpplingen',
      'Süplingen',
      'Sundern',
      'Sünching',
      'Sulzheim',
      'Sulzfeld am Main',
      'Sulzfeld',
      'Sulzemoos',
      'Sulzdorf',
      'Sulzburg',
      'Sulzberg',
      'Sulzbach-Rosenberg',
      'Sulzbach an der Murr',
      'Sulzbach am Main',
      'Sulzbach',
      'Sulz am Neckar',
      'Sulingen',
      'Sülfeld',
      'Sukow',
      'Sülstorf',
      'Suhlendorf',
      'Suhl',
      'Sugenheim',
      'Sudwalde',
      'Südlohn',
      'Süderstapel',
      'Süderlügum',
      'Südergellersen',
      'Suderburg',
      'Süderbrarup',
      'Suddendorf',
      'Stützerbach',
      'Stützengrün',
      'Stuttgart',
      'Stulln',
      'Stuhr',
      'Stühlingen',
      'Stubenberg',
      'Struvenhütten',
      'Struppen',
      'Strullendorf',
      'Stromberg',
      'Ströbeck',
      'Strehla',
      'Straußfurt',
      'Strausberg',
      'Straupitz',
      'Straubing',
      'Straßkirchen',
      'Straßberg',
      'Strasburg',
      'Strande',
      'Stralsund',
      'Stralendorf',
      'Straelen',
      'Stöttwang',
      'Stötten am Auerberg',
      'Stößen',
      'Stoßdorf',
      'Störnstein',
      'Storkow',
      'Stolzenau',
      'Stolpen',
      'Stolpe',
      'Stollberg',
      'Stolberg',
      'Stödtlen',
      'Stockstadt am Main',
      'Stockstadt am Rhein',
      'Stöckse',
      'Stockheim',
      'Stöckheim',
      'Stockelsdorf',
      'Stockach',
      'Stimpfach',
      'Steyerberg',
      'Stettfeld',
      'Stetten am Kalten Markt',
      'Stetten',
      'Sterup',
      'Sternenfels',
      'Sternberg',
      'Stephansposching',
      'Stephanskirchen',
      'Stendal',
      'Stelzenberg',
      'Stellingen',
      'Stelle',
      'Steißlingen',
      'Steinwiesen',
      'Steinwenden',
      'Steinweiler',
      'Steinsfeld',
      'Steinmauern',
      'Steinkirchen',
      'Steinigtwolmsdorf',
      'Steinhorst',
      'Steinhöring',
      'Steinhöfel',
      'Steinheim an der Murr',
      'Steinheim am Albuch',
      'Steinheim',
      'Steinheid',
      'Steinhagen',
      'Steingaden',
      'Steinfurt',
      'Steinfeld',
      'Steinenbronn',
      'Steinen',
      'Steinbergkirche',
      'Steinberg',
      'Stein',
      'Steinbach-Hallenberg',
      'Steinbach am Taunus',
      'Steinbach',
      'Steinau an der Straße',
      'Steinach',
      'Steina',
      'Steimel',
      'Steimbke',
      'Steilshoop',
      'Stiefenhofen',
      'Steglitz',
      'Stegen',
      'Stegaurach',
      'Steeden',
      'Stedten',
      'Stedesdorf',
      'Staufenberg',
      'Staufen',
      'Staudernheim',
      'Staudach-Egerndach',
      'Stauchitz',
      'Staßfurt',
      'Starnberg',
      'Stapelfeld',
      'Stapelburg',
      'Stamsried',
      'Stammham',
      'Stammbach',
      'Stallwang',
      'Staig',
      'Stahnsdorf',
      'Bad Staffelstein',
      'Stadum',
      'Stadt Wehlen',
      'Stadtsteinach',
      'Stadtroda',
      'Stadtprozelten',
      'Stadtoldendorf',
      'Stadtlohn',
      'Stadtlengsfeld',
      'Stadtlauringen',
      'Stadtkyll',
      'Stadtilm',
      'Stadthagen',
      'Stadtbergen',
      'Stadtallendorf',
      'Stadensen',
      'Stadelhofen',
      'Stade',
      'Stäbelow',
      'Staaken',
      'Staad',
      'Sprockhövel',
      'Springe',
      'Sprendlingen',
      'Spreenhagen',
      'Sprakensehl',
      'Spraitbach',
      'Spornitz',
      'Spiesheim',
      'Spiesen-Elversberg',
      'Spiegelberg',
      'Speyer',
      'Spergau',
      'Sperenberg',
      'Spenge',
      'Spelle',
      'Speinshart',
      'Speichersdorf',
      'Speicher',
      'Spechbach',
      'Sparneck',
      'Spardorf',
      'Spangenberg',
      'Spalt',
      'Spaichingen',
      'Spabrücken',
      'Soyen',
      'Sottrum',
      'Sosa',
      'Sörup',
      'Sörgenloch',
      'Sontra',
      'Sonthofen',
      'Sontheim an der Brenz',
      'Sontheim',
      'Sonsbeck',
      'Sonnewalde',
      'Sonnen',
      'Sonnefeld',
      'Sonneborn',
      'Sonneberg',
      'Sondheim vor der Rhön',
      'Sondershausen',
      'Sommersdorf',
      'Sommerkahl',
      'Sommerhausen',
      'Sömmerda',
      'Sommerach',
      'Soltendieck',
      'Soltau',
      'Solnhofen',
      'Sollstedt',
      'Solingen',
      'Sölden',
      'Sohren',
      'Söhlde',
      'Sohland am Rotstein',
      'Sohland',
      'Sögel',
      'Soest',
      'Soderstorf',
      'Södel',
      'Söchtenau',
      'Sitzendorf',
      'Sittensen',
      'Sipplingen',
      'Sippersfeld',
      'Sinzing',
      'Sinzig',
      'Sinzheim',
      'Sinsheim',
      'Sinnersdorf',
      'Sinn',
      'Singhofen',
      'Singen',
      'Sindelsdorf',
      'Sindelfingen',
      'Simmozheim',
      'Simmersfeld',
      'Simmern',
      'Simmertal',
      'Simmerath',
      'Simmelsdorf',
      'Simbach am Inn',
      'Simbach',
      'Sillenstede',
      'Silberstedt',
      'Sigmarszell',
      'Sigmaringendorf',
      'Sigmaringen',
      'Siggelkow',
      'Sieverstedt',
      'Sievershütten',
      'Siersleben',
      'Siershahn',
      'Siersdorf',
      'Sierksdorf',
      'Siemensstadt',
      'Sielenbach',
      'Siek',
      'Siegsdorf',
      'Siegenburg',
      'Siegen',
      'Siegelsbach',
      'Siegburg',
      'Siefersheim',
      'Langenhorn',
      'Siedenburg',
      'Siebeldingen',
      'Sickenhausen',
      'Sibbesse',
      'Seyda',
      'Seybothenreuth',
      'Sexau',
      'Seulingen',
      'Seukendorf',
      'Seubersdorf',
      'Seth',
      'Seßlach',
      'Sersheim',
      'Serrig',
      'Sennfeld',
      'Sennewitz',
      'Sengenthal',
      'Senftenberg',
      'Sendenhorst',
      'Senden',
      'Sembach',
      'Selzen',
      'Selters',
      'Selsingen',
      'Selmsdorf',
      'Selm',
      'Sellin',
      'Seligenstadt',
      'Selent',
      'Selbitz',
      'Selb',
      'Seinsheim',
      'Seifhennersdorf',
      'Seibersbach',
      'Sehnde',
      'Sehlen',
      'Sehlem',
      'Sehlde',
      'Seggebruch',
      'Seevetal',
      'Seeshaupt',
      'Seesen',
      'Seelze',
      'Seelow',
      'Seelitz',
      'Seelingstädt',
      'Seelbach',
      'Seehof',
      'Seehausen am Staffelsee',
      'Seehausen',
      'Seeg',
      'Seefeld',
      'Seedorf',
      'Seeburg',
      'Seebergen',
      'Seebad Heringsdorf',
      'Seebad Bansin',
      'Seebach',
      'Seckach',
      'Seck',
      'Sebnitz',
      'Schwörstadt',
      'Schwindegg',
      'Schwieberdingen',
      'Schwetzingen',
      'Schwerte',
      'Schwerin',
      'Schwepnitz',
      'Schwenningen',
      'Schwendi',
      'Schwelm',
      'Schweitenkirchen',
      'Schweinfurt',
      'Schweina',
      'Schweich',
      'Schwegenheim',
      'Schwedt (Oder)',
      'Schwedelbach',
      'Schwebheim',
      'Schwarzhofen',
      'Schwarzheide',
      'Schwarzenfeld',
      'Schwarzenbruck',
      'Schwarzenborn',
      'Schwarzenberg',
      'Schwarzenbek',
      'Schwarzenbach an der Saale',
      'Schwarzenbach',
      'Spiegelau',
      'Schwarzach',
      'Schwarza',
      'Schwarmstedt',
      'Schwarme',
      'Schwangau',
      'Schwanfeld',
      'Schwanewede',
      'Schwanebeck',
      'Schwandorf in Bayern',
      'Schwalmtal',
      'Schwalmstadt',
      'Schwallungen',
      'Schwalbach',
      'Schwalbach am Taunus',
      'Schwaikheim',
      'Schwaigern',
      'Schwaig',
      'Schwaförden',
      'Schwabstedt',
      'Schwabsoien',
      'Schwabmünchen',
      'Schwäbisch Hall',
      'Schwäbisch Gmünd',
      'Kleinschwabhausen',
      'Schwabenheim',
      'Schwabach',
      'Schwaan',
      'Schüttorf',
      'Schutterwald',
      'Schuttertal',
      'Bad Schussenried',
      'Schulzendorf',
      'Schülp',
      'Schuby',
      'Schrozberg',
      'Schrobenhausen',
      'Schriesheim',
      'Schrecksbach',
      'Schraplau',
      'Schramberg',
      'Schotten',
      'Schortens',
      'Schornsheim',
      'Schorndorf',
      'Schöppingen',
      'Schöppenstedt',
      'Schopp',
      'Schopfloch',
      'Schopfheim',
      'Schönwalde am Bungsberg',
      'Schönwald',
      'Schonungen',
      'Schönthal',
      'Schonstett',
      'Schönsee',
      'Schönkirchen',
      'Schöningen',
      'Schönheide',
      'Schönhausen',
      'Schöngeising',
      'Schongau',
      'Schönfeld',
      'Schönewalde',
      'Schöneiche',
      'Schönefeld',
      'Schönecken',
      'Schöneck',
      'Schöneberg',
      'Schönebeck',
      'Schondra',
      'Schönburg',
      'Schönborn',
      'Schönberg',
      'Schönbach',
      'Schönau-Berzdorf',
      'Schönau',
      'Schönau im Schwarzwald',
      'Schönau am Königssee',
      'Schönaich',
      'Schonach im Schwarzwald',
      'Schömberg',
      'Schöllnach',
      'Schöllkrippen',
      'Schöfweg',
      'Schochwitz',
      'Schobüll',
      'Schnürpflingen',
      'Schneverdingen',
      'Schnelsen',
      'Schnelldorf',
      'Schneizlreuth',
      'Schneidlingen',
      'Schnega',
      'Schneeberg',
      'Schneckenlohe',
      'Schnaittenbach',
      'Schnaittach',
      'Schnaitsee',
      'Schmölln',
      'Schmöckwitz',
      'Schmitten',
      'Schmiedefeld am Rennsteig',
      'Schmiedefeld',
      'Schmiedeberg',
      'Schmiechen',
      'Schmidmühlen',
      'Schmidgaden',
      'Schmelz',
      'Schmargendorf',
      'Schmallenberg',
      'Schmalkalden',
      'Schmalfeld',
      'Schlüsselfeld',
      'Schlüchtern',
      'Schluchsee',
      'Schlotheim',
      'Schloßvippach',
      'Schlitz',
      'Schliersee',
      'Schlierbach',
      'Schlier',
      'Schliengen',
      'Schlieben',
      'Schleusingen',
      'Schlettau',
      'Schleswig',
      'Bad Schlema',
      'Schleiz',
      'Schleife',
      'Schleiden',
      'Schleid',
      'Schlehdorf',
      'Schlegel',
      'Schleching',
      'Schlat',
      'Schlangenbad',
      'Schlangen',
      'Schlaitz',
      'Schlaitdorf',
      'Schlagsdorf',
      'Schladen',
      'Schkopau',
      'Schkölen',
      'Schkeuditz',
      'Schirnding',
      'Schirmitz',
      'Schirgiswalde',
      'Schipkau',
      'Schiltberg',
      'Schiltach',
      'Schillingsfürst',
      'Schillingen',
      'Schillig',
      'Schilksee',
      'Schildau',
      'Schiffweiler',
      'Schifferstadt',
      'Schiffdorf',
      'Schierstein',
      'Schierling',
      'Scheyern',
      'Scheuring',
      'Scheuerfeld',
      'Scheßlitz',
      'Scherstetten',
      'Schernberg',
      'Schermen',
      'Schermbeck',
      'Schenklengsfeld',
      'Schenkenzell',
      'Schenkendöbern',
      'Schenefeld',
      'Schellhorn',
      'Schellerten',
      'Schelklingen',
      'Scheinfeld',
      'Scheidegg',
      'Scheibenberg',
      'Scheeßel',
      'Scheer',
      'Schechingen',
      'Schechen',
      'Schaufling',
      'Schauenstein',
      'Schashagen',
      'Scharnebeck',
      'Scharfenstein',
      'Scharbeutz',
      'Schapen',
      'Schallstadt',
      'Schalksmühle',
      'Schalkau',
      'Schäftlarn',
      'Schafstädt',
      'Schafflund',
      'Schacht-Audorf',
      'Schaalby',
      'Schaafheim',
      'Sayda',
      'Saulgrub',
      'Bad Saulgau',
      'Sauldorf',
      'Sauerlach',
      'Sauensiek',
      'Satteldorf',
      'Satrup',
      'Satow-Oberhagen',
      'Sassnitz',
      'Sassenberg',
      'Sasel',
      'Sasbachwalden',
      'Sasbach',
      'Sarstedt',
      'Sankt Wolfgang',
      'Sankt Wendel',
      'Sankt Sebastian',
      'Sankt Peter-Ording',
      'Sankt Peter',
      'Sankt Michaelisdonn',
      'Sankt Martin',
      'Sankt Märgen',
      'Sankt Margarethen',
      'Sankt Kilian',
      'Sankt Julian',
      'Sankt Johann',
      'Sankt Ingbert',
      'Sankt Goarshausen',
      'Sankt Goar',
      'Sankt Georgen im Schwarzwald',
      'St. Georg',
      'Sankt Gangloff',
      'Sankt Egidien',
      'Sankt Blasien',
      'Sankt Augustin',
      'Sankt Andreasberg',
      'Sankelmark',
      'Alt-Sanitz',
      'Sangerhausen',
      'Sandstedt',
      'Sandhausen',
      'Sandesneben',
      'Sandersleben',
      'Sandersdorf',
      'Sande',
      'Sandberg',
      'Sandau',
      'Sand',
      'Samtens',
      'Samswegen',
      'Samerberg',
      'Salzweg',
      'Salzwedel',
      'Salzmünde',
      'Salzkotten',
      'Salzhemmendorf',
      'Salzhausen',
      'Salzgitter',
      'Salzbergen',
      'Salz',
      'Sallgast',
      'Salgen',
      'Saldenburg',
      'Salching',
      'Salach',
      'Sagard',
      'Saffig',
      'Saerbeck',
      'Sachsenkam',
      'Sachsenhausen',
      'Sachsenhagen',
      'Sachsen bei Ansbach',
      'Saarwellingen',
      'Saarlouis',
      'Saarhölzbach',
      'Saarburg',
      'Saarbrücken',
      'Saara',
      'Saalfeld',
      'Saal an der Saale',
      'Saal',
      'Rüthen',
      'Rutesheim',
      'Rust',
      'Rüsselsheim',
      'Ruppichteroth',
      'Ruppertsweiler',
      'Ruppertshofen',
      'Ruppertsberg',
      'Runkel',
      'Rüningen',
      'Runding',
      'Rümpel',
      'Rümmingen',
      'Rümmelsheim',
      'Rummelsburg',
      'Rülzheim',
      'Rullstorf',
      'Ruhwinkel',
      'Ruhstorf',
      'Ruhpolding',
      'Ruhmannsfelden',
      'Ruhland',
      'Ruhla',
      'Rühen',
      'Rügland',
      'Rugendorf',
      'Rudow',
      'Rudolstadt',
      'Rüdnitz',
      'Rüdesheim am Rhein',
      'Rüdesheim',
      'Ruderting',
      'Rudersberg',
      'Ruderatshofen',
      'Rudelzhausen',
      'Rückersdorf',
      'Rübeland',
      'Roxheim',
      'Rövershagen',
      'Rötz',
      'Rottweil',
      'Rottleberode',
      'Röttingen',
      'Rotthalmünster',
      'Rottendorf',
      'Rottenburg an der Laaber',
      'Rottenburg',
      'Rottenbuch',
      'Rottenbach',
      'Röttenbach',
      'Rottenacker',
      'Rottach-Egern',
      'Rott',
      'Röthlein',
      'Rotherbaum',
      'Rothenstein',
      'Rothenfels',
      'Rothenburg ob der Tauber',
      'Rothenburg',
      'Rothenbuch',
      'Rothenbergen',
      'Rothenberg',
      'Röthenbach an der Pegnitz',
      'Röthenbach',
      'Rötha',
      'Roth',
      'Rötgesbüttel',
      'Roetgen',
      'Rotenburg an der Fulda',
      'Rotenburg',
      'Rot am See',
      'Rot',
      'Rostock',
      'Roßwein',
      'Roßtal',
      'Roßleben',
      'Roßlau',
      'Roßla',
      'Roßhaupten',
      'Roßdorf',
      'Rossbach',
      'Rösrath',
      'Roskow',
      'Rositz',
      'Rosenthal',
      'Rosenow',
      'Rosenheim',
      'Rosenfeld',
      'Rosenberg',
      'Rosdorf',
      'Rosche',
      'Rosbach vor der Höhe',
      'Ronshausen',
      'Ronsberg',
      'Ronnenberg',
      'Ronneburg',
      'Romrod',
      'Rommerskirchen',
      'Römhild',
      'Röllbach',
      'Roitzsch',
      'Roigheim',
      'Rohrsen',
      'Röhrnbach',
      'Röhrmoos',
      'Rohrenfels',
      'Rohrdorf',
      'Rohrbach',
      'Rohr',
      'Roggentin',
      'Roggendorf',
      'Roggenburg',
      'Rogätz',
      'Röfingen',
      'Rodleben',
      'Rödinghausen',
      'Roding',
      'Rodewisch',
      'Rodenberg',
      'Rodenbach',
      'Roden',
      'Rödelsee',
      'Rodalben',
      'Rockenhausen',
      'Rockenberg',
      'Rochlitz',
      'Röblingen am See',
      'Röbel',
      'Rittersgrün',
      'Rittersdorf',
      'Ritterhude',
      'Rissen',
      'Riol',
      'Rinteln',
      'Ringsheim',
      'Ringleben',
      'Ringelai',
      'Rinchnach',
      'Rimsting',
      'Rimschweiler',
      'Rimpar',
      'Rimbach',
      'Rietz Neuendorf',
      'Rietschen',
      'Riethnordhausen',
      'Rietberg',
      'Rieste',
      'Riestedt',
      'Rieseby',
      'Riesa',
      'Riepsdorf',
      'Rieneck',
      'Rielasingen-Worblingen',
      'Riehl',
      'Riegsee',
      'Riegelsberg',
      'Riegel',
      'Riedlingen',
      'Riedering',
      'Riederich',
      'Rieder',
      'Riedenburg',
      'Rieden an der Kötz',
      'Rieden',
      'Riede',
      'Ried',
      'Rickling',
      'Rickert',
      'Rickenbach',
      'Richtenberg',
      'Ribnitz-Damgarten',
      'Ribbesbüttel',
      'Rhumspringe',
      'Rhodt unter Rietburg',
      'Rhinow',
      'Rheurdt',
      'Rhens',
      'Rheinzabern',
      'Rheinsberg',
      'Rheinhausen',
      'Rheinfelden',
      'Rheine',
      'Rheinbrohl',
      'Rheinbreitbach',
      'Rheinböllen',
      'Rheinberg',
      'Rheinbach',
      'Rheinau',
      'Rheden',
      'Rhede',
      'Rheda-Wiedenbrück',
      'Rhaunen',
      'Rhade',
      'Reutlingen',
      'Reuth',
      'Reut',
      'Reußen',
      'Retzstadt',
      'Rettenberg',
      'Rettenbach',
      'Retschow',
      'Rethen',
      'Rethem',
      'Retgendorf',
      'Reppenstedt',
      'Rentweinsdorf',
      'Renningen',
      'Rennertshofen',
      'Rennerod',
      'Rengsdorf',
      'Rendsburg',
      'Renchen',
      'Remse',
      'Remscheid',
      'Remptendorf',
      'Remlingen',
      'Remagen',
      'Rellingen',
      'Reit im Winkl',
      'Reiskirchen',
      'Reischach',
      'Reisbach',
      'Reinstorf',
      'Reinsfeld',
      'Reinsdorf',
      'Reinsberg',
      'Reinickendorf',
      'Reinheim',
      'Reinhausen',
      'Reinhardtsgrimma',
      'Reinhardshausen',
      'Reinfeld',
      'Reinberg',
      'Reinbek',
      'Reimlingen',
      'Reilingen',
      'Reil',
      'Reichling',
      'Reichertshofen',
      'Reichertsheim',
      'Reichertshausen',
      'Reichersbeuern',
      'Reichenwalde',
      'Reichenschwand',
      'Reichenberg',
      'Reichenbach-Steegen',
      'Reichenbach an der Fils',
      'Reichenbach',
      'Reichenbach/Vogtland',
      'Reichelsheim',
      'Reichartshausen',
      'Reichardtswerben',
      'Rehna',
      'Rehling',
      'Rehfelde',
      'Rehe',
      'Rehden',
      'Rehburg-Loccum',
      'Rehau',
      'Regnitzlosau',
      'Regis-Breitingen',
      'Regesbostel',
      'Regenstauf',
      'Regensburg',
      'Regen',
      'Reeßum',
      'Rees',
      'Redwitz an der Rodach',
      'Rednitzhembach',
      'Recklinghausen',
      'Recke',
      'Rechtsupweg',
      'Rechtmehring',
      'Rechtenbach',
      'Rechlin',
      'Rechenberg-Bienenmühle',
      'Rechberghausen',
      'Ravensburg',
      'Rautheim',
      'Rauschenberg',
      'Raunheim',
      'Rauenberg',
      'Rauen',
      'Raubling',
      'Raubach',
      'Ratzeburg',
      'Rattiszell',
      'Rattenberg',
      'Rattelsdorf',
      'Ratingen',
      'Rathmannsdorf',
      'Rathenow',
      'Rath',
      'Ratekau',
      'Rastow',
      'Rastenberg',
      'Rastede',
      'Rastatt',
      'Raßnitz',
      'Rasdorf',
      'Raschau',
      'Rantrum',
      'Ranstadt',
      'Rannungen',
      'Ranis',
      'Rangsdorf',
      'Rangendingen',
      'Randersacker',
      'Ramsthal',
      'Ramstein-Miesenbach',
      'Ramsin',
      'Ramsen',
      'Ramsau',
      'Rammingen',
      'Rammenau',
      'Rammelsbach',
      'Ramerberg',
      'Rambin',
      'Ramberg',
      'Ralingen',
      'Raitenbuch',
      'Raisting',
      'Raisdorf',
      'Rain',
      'Rahnsdorf',
      'Rahlstedt',
      'Rahden',
      'Raguhn',
      'Raesfeld',
      'Radolfzell',
      'Radis',
      'Radibor',
      'Radevormwald',
      'Radegast',
      'Radeburg',
      'Radebeul',
      'Radeberg',
      'Raddestorf',
      'Radbruch',
      'Rackwitz',
      'Räckelwitz',
      'Raben Steinfeld',
      'Rabenau',
      'Quitzdorf',
      'Quierschied',
      'Quickborn',
      'Quern',
      'Querfurt',
      'Quellendorf',
      'Queis',
      'Queidersbach',
      'Quedlinburg',
      'Quarnbek',
      'Quakenbrück',
      'Pyrbaum',
      'Putzbrunn',
      'Püttlingen',
      'Putlitz',
      'Putbus',
      'Puschwitz',
      'Puschendorf',
      'Pürgen',
      'Pullenreuth',
      'Pullach im Isartal',
      'Pulheim',
      'Puderbach',
      'Puchheim',
      'Püchersreuth',
      'Prutting',
      'Prüm',
      'Prötzel',
      'Prosselsheim',
      'Pronstorf',
      'Prohn',
      'Probstzella',
      'Probsteierhagen',
      'Pritzwalk',
      'Prittriching',
      'Prittitz',
      'Prisdorf',
      'Priestewitz',
      'Priesendorf',
      'Prien am Chiemsee',
      'Prichsenstadt',
      'Preußisch Oldendorf',
      'Pretzschendorf',
      'Pretzsch',
      'Pretzier',
      'Pretzfeld',
      'Prettin',
      'Pressig',
      'Presseck',
      'Pressath',
      'Prenzlauer Berg',
      'Prenzlau',
      'Premnitz',
      'Preetz',
      'Prebitz',
      'Pratau',
      'Prackenbach',
      'Pracht',
      'Poxdorf',
      'Pouch',
      'Pottum',
      'Pöttmes',
      'Pottenstein',
      'Potsdam',
      'Postmünster',
      'Postau',
      'Pößneck',
      'Poseritz',
      'Porz am Rhein',
      'Porta Westfalica',
      'Porschdorf',
      'Pörnbach',
      'Poppenricht',
      'Poppenhausen',
      'Poppenbüttel',
      'Ponitz',
      'Pommersfelden',
      'Pommelsbrunn',
      'Pölzig',
      'Polsingen',
      'Polling',
      'Pollhagen',
      'Polleben',
      'Polle',
      'Poll',
      'Pölitz',
      'Polch',
      'Poing',
      'Pöhla',
      'Podelzig',
      'Pocking',
      'Pöcking',
      'Pockau',
      'Pobershau',
      'Pluwig',
      'Plüderhausen',
      'Plötzky',
      'Plötzkau',
      'Plößberg',
      'Plön',
      'Plochingen',
      'Pliezhausen',
      'Pliening',
      'Pleystein',
      'Plettenberg',
      'Plessa',
      'Pleiskirchen',
      'Pleinfeld',
      'Pleidelsheim',
      'Plauen',
      'Plaue',
      'Plau am See',
      'Plattling',
      'Plattenburg',
      'Plate',
      'Plankstadt',
      'Planegg',
      'Plaidt',
      'Pittenhart',
      'Pirna',
      'Pirmasens',
      'Pirk',
      'Pinzberg',
      'Pinnow',
      'Pinneberg',
      'Pilsting',
      'Pilsach',
      'Pillnitz',
      'Piesteritz',
      'Piesport',
      'Pielenhofen',
      'Piding',
      'Philippsthal',
      'Philippsburg',
      'Pfungstadt',
      'Pfullingen',
      'Pfullendorf',
      'Pfronten',
      'Pfronstetten',
      'Pfreimd',
      'Pforzheim',
      'Pforzen',
      'Pfofeld',
      'Pfeffenhausen',
      'Pfeffelbach',
      'Pfedelbach',
      'Pfatter',
      'Pfarrweisach',
      'Pfarrkirchen',
      'Pfalzgrafenweiler',
      'Pfakofen',
      'Pfaffroda',
      'Pfaffing',
      'Pfaffenweiler',
      'Pfaffen-Schwabenheim',
      'Pfaffenhofen an der Roth',
      'Pfaffenhofen an der Ilm',
      'Pfaffenhofen',
      'Pfaffenhausen',
      'Pettstadt',
      'Petting',
      'Pettendorf',
      'Petershausen-Ost',
      'Petershausen',
      'Petershagen',
      'Petersdorf',
      'Petersaurach',
      'Perlesreut',
      'Perleberg',
      'Perkam',
      'Perach',
      'Penzlin',
      'Penzing',
      'Penzberg',
      'Pentling',
      'Pennigsehl',
      'Penkun',
      'Penig',
      'Pemfling',
      'Pelm',
      'Pellworm',
      'Pellingen',
      'Peitz',
      'Peiting',
      'Peißenberg',
      'Peißen',
      'Peine',
      'Pegnitz',
      'Pegau',
      'Pechbrunn',
      'Pausa',
      'Paunzhausen',
      'Paulinenaue',
      'Pattensen',
      'Patersdorf',
      'Pastetten',
      'Passau',
      'Pasing',
      'Pasewalk',
      'Partenstein',
      'Partenheim',
      'Parsberg',
      'Parsau',
      'Parkstetten',
      'Parkstein',
      'Parchim',
      'Kleinschmalkalden',
      'Pappenheim',
      'Papendorf',
      'Papenburg',
      'Panschwitz-Kuckau',
      'Pankow',
      'Panker',
      'Pampow',
      'Palzem',
      'Palling',
      'Painten',
      'Pahlen',
      'Pähl',
      'Paderborn',
      'Padenstedt',
      'Oyten',
      'Owschlag',
      'Owingen',
      'Owen',
      'Oeversee',
      'Overath',
      'Ovelgönne',
      'Ötzingen',
      'Otzing',
      'Ottweiler',
      'Ottrau',
      'Ottobrunn',
      'Ottobeuren',
      'Otterwisch',
      'Ottersweier',
      'Otterstadt',
      'Ottersheim',
      'Ottersberg',
      'Otterndorf',
      'Otterfing',
      'Otterberg',
      'Otterbach',
      'Otter',
      'Ottenstein',
      'Ottensoos',
      'Ottensen',
      'Ottenhofen',
      'Ottendorf-Okrilla',
      'Ottenbach',
      'Ötisheim',
      'Ötigheim',
      'Othmarschen',
      'Oststeinbek',
      'Ostseebad Prerow',
      'Ostseebad Kühlungsborn',
      'Seeheilbad Graal-Müritz',
      'Ostseebad Göhren',
      'Ostseebad Dierhagen',
      'Ostseebad Boltenhagen',
      'Ostseebad Binz',
      'Ostritz',
      'Östringen',
      'Ostrhauderfehn',
      'Ostrau',
      'Ostrach',
      'Osthofen',
      'Ostheim',
      'Osterwieck',
      'Osterspai',
      'Osterrönfeld',
      'Osterode am Harz',
      'Osternienburg',
      'Osterholz-Scharmbeck',
      'Osterhofen',
      'Osterhausen',
      'Osterfeld',
      'Ostercappeln',
      'Osterburken',
      'Osterburg',
      'Ostenfeld',
      'Osten',
      'Ostelsheim',
      'Osteel',
      'Ostbevern',
      'Oßmannstedt',
      'Oßling',
      'Ossendorf',
      'Osnabrück',
      'Osloß',
      'Osdorf',
      'Oschersleben',
      'Oschatz',
      'Osburg',
      'Ortrand',
      'Ortenburg',
      'Ortenberg',
      'Orsingen-Nenzingen',
      'Orscholz',
      'Ornbau',
      'Oerlenbach',
      'Orlamünde',
      'Orenhofen',
      'Oerel',
      'Oranienburg',
      'Oranienbaum',
      'Oppurg',
      'Oppum',
      'Oppin',
      'Oppenweiler',
      'Oppenheim',
      'Oppenau',
      'Oppach',
      'Opladen',
      'Öpfingen',
      'Opfenbach',
      'Olsbrücken',
      'Olsberg',
      'Ölper',
      'Olpe',
      'Oelixdorf',
      'Olfen',
      'Oldisleben',
      'Oldenswort',
      'Oldendorf',
      'Oldenburg in Holstein',
      'Oldenburg',
      'Olching',
      'Olbersdorf',
      'Olbernhau',
      'Öhringen',
      'Ohorn',
      'Öhningen',
      'Ohmden',
      'Ohlstadt',
      'Ohlsdorf',
      'Ohlsbach',
      'Oftersheim',
      'Ofterschwang',
      'Ofterdingen',
      'Offstein',
      'Offingen',
      'Offenhausen',
      'Offenburg',
      'Offenberg',
      'Offenbach an der Queich',
      'Offenbach',
      'Offenau',
      'Oettingen in Bayern',
      'Oerlinghausen',
      'Oer-Erkenschwick',
      'Oelsnitz',
      'Oelde',
      'Oedheim',
      'Oederan',
      'Oebisfelde',
      'Oederquart',
      'Odernheim',
      'Oderberg',
      'Odenthal',
      'Odelzhausen',
      'Ockenheim',
      'Ockenfels',
      'Ochtrup',
      'Ochtendung',
      'Ochsenwerder',
      'Ochsenhausen',
      'Ochsenfurt',
      'Obrigheim',
      'Obing',
      'Obhausen',
      'Oberzissen',
      'Oberwolfach',
      'Oberwiera',
      'Oberwesel',
      'Oberweißbach',
      'Heidstock',
      'Oberviechtach',
      'Oberursel',
      'Obertshausen',
      'Obertrubach',
      'Obertraubling',
      'Oberthulba',
      'Oberthal',
      'Oberteuringen',
      'Obertaufkirchen',
      'Obersüßbach',
      'Oberstreu',
      'Oberstenfeld',
      'Oberstdorf',
      'Oberstaufen',
      'Oberstadion',
      'Obersontheim',
      'Obersöchering',
      'Obersinn',
      'Oberseifersdorf',
      'Oberschweinbach',
      'Oberschwarzach',
      'Oberschöneweide',
      'Oberschöna',
      'Oberschneiding',
      'Oberschleißheim',
      'Oberscheinfeld',
      'Ober-Saulheim',
      'Oberrot',
      'Oberröblingen',
      'Oberriexingen',
      'Oberrieden',
      'Oberried',
      'Oberreute',
      'Oberreichenbach',
      'Ober-Ramstadt',
      'Oberpöring',
      'Oberpleichfeld',
      'Oberpframmern',
      'Oberottmarshausen',
      'Oberotterbach',
      'Oberostendorf',
      'Ober-Olm',
      'Obernzenn',
      'Obernzell',
      'Obernkirchen',
      'Obernheim-Kirchenarnbach',
      'Obernheim',
      'Obernfeld',
      'Oberndorf',
      'Obernburg am Main',
      'Obernbreit',
      'Obernbeck',
      'Obermoschel',
      'Ober-Mörlen',
      'Obermichelbach',
      'Obermeitingen',
      'Obermehler',
      'Obermaßfeld-Grimmenthal',
      'Obermarchtal',
      'Oberlungwitz',
      'Oberlichtenau',
      'Oberleichtersbach',
      'Langfurth',
      'Oberkotzau',
      'Oberkochen',
      'Oberkirch',
      'Oberjettingen',
      'Oberhof',
      'Oberhausen-Rheinhausen',
      'Oberhausen',
      'Oberharmersbach',
      'Oberhaid',
      'Oberhaching',
      'Obergurig',
      'Obergünzburg',
      'Obergriesbach',
      'Ober-Flörsheim',
      'Oberfell',
      'Oberelsbach',
      'Oberelbert',
      'Oberdorla',
      'Oberdischingen',
      'Oberding',
      'Oberderdingen',
      'Oberdachstetten',
      'Obercunnersdorf',
      'Oberboihingen',
      'Oberbiel',
      'Oberbergkirchen',
      'Oberaula',
      'Oberaudorf',
      'Oberasbach',
      'Oberammergau',
      'Nützen',
      'Nußloch',
      'Nusse',
      'Nußdorf am Inn',
      'Nußdorf',
      'Nürtingen',
      'Nürnberg',
      'Nünchritz',
      'Nümbrecht',
      'Nufringen',
      'Nüdlingen',
      'Nudersdorf',
      'Nübbel',
      'Notzingen',
      'Nottuln',
      'Nottensdorf',
      'Nostorf',
      'Nossen',
      'Nörvenich',
      'Nortrup',
      'Nortorf',
      'Nortmoor',
      'Northeim',
      'Nörtershausen',
      'Nörten-Hardenberg',
      'Norheim',
      'Nordwalde',
      'Nordstemmen',
      'Nordrach',
      'Nördlingen',
      'Nordleda',
      'Nordkirchen',
      'Nordhorn',
      'Nordholz',
      'Nordheim',
      'Nordhausen',
      'Nordhastedt',
      'Nordhalben',
      'Norderstedt',
      'Norderney',
      'Nordenham',
      'Nordendorf',
      'Norden',
      'Norddeich',
      'Nonnweiler',
      'Nonnenhorn',
      'Nohra',
      'Nohfelden',
      'Nobitz',
      'Nöbdenitz',
      'Nittendorf',
      'Nittenau',
      'Nittel',
      'Nister',
      'Nippes',
      'Nindorf',
      'Nikolassee',
      'Niesky',
      'Nierstein',
      'Niepars',
      'Nienstedten',
      'Nienstädt',
      'Nienhagen',
      'Niendorf',
      'Nienburg',
      'Nienburg/Saale',
      'Niemegk',
      'Niemberg',
      'Nieheim',
      'Niederzissen',
      'Niederzimmern',
      'Niederzier',
      'Niederwinkling',
      'Niederwiesa',
      'Niederwerth',
      'Niederwerrn',
      'Niederwangen',
      'Niederviehbach',
      'Niedertaufkirchen',
      'Niederstriegis',
      'Niederstotzingen',
      'Niederstetten',
      'Niederschönhausen',
      'Niederschöneweide',
      'Niederschönenfeld',
      'Niederschöna',
      'Niedersachswerfen',
      'Niederroßla',
      'Niederrieden',
      'Niederorschel',
      'Nieder-Olm',
      'Niedernwöhren',
      'Niedernhausen',
      'Niedernhall',
      'Niederneisen',
      'Niederndodeleben',
      'Niedernberg',
      'Niedermurach',
      'Niedermohr',
      'Niedermerz',
      'Niederlauer',
      'Niederlangen',
      'Niederkrüchten',
      'Niederklein',
      'Niederkirchen bei Deidesheim',
      'Niederkirchen',
      'Niederkassel',
      'Nieder-Ingelheim',
      'Nieder-Gründau',
      'Niedergörsdorf',
      'Niederfüllbach',
      'Niederfrohna',
      'Nieder-Florstadt',
      'Niederfischbach',
      'Niederfell',
      'Niedereschach',
      'Niedererbach',
      'Niederelbert',
      'Niederdürenbach',
      'Niederdreisbach',
      'Niederdorla',
      'Niederdorfelden',
      'Niederdorf',
      'Niedercunnersdorf',
      'Niederbreitbach',
      'Niederbiel',
      'Niederbergkirchen',
      'Niederaula',
      'Niederau',
      'Aschau im Chiemgau',
      'Niederalteich',
      'Niederaichbach',
      'Niedenstein',
      'Niebüll',
      'Nideggen',
      'Nidderau',
      'Nidda',
      'Nickenich',
      'Newel',
      'Neverin',
      'Neuzelle',
      'Neu Zauche',
      'Neu Wulmstorf',
      'Neuwittenbek',
      'Neuwied',
      'Neuweiler',
      'Neu-Ulm',
      'Neutrebbin',
      'Neutraubling',
      'Neustrelitz',
      'Neustadt in Holstein',
      'Neustadt-Glewe',
      'Neustadt bei Coburg',
      'Neustadt',
      'Neustadt an der Waldnaab',
      'Neustadt an der Donau',
      'Neustadt an der Aisch',
      'Neustadt am Rübenberge',
      'Neustadt am Rennsteig',
      'Neustadt am Main',
      'Neue Neustadt',
      'Neustadt in Sachsen',
      'Neustadt (Hessen)',
      'Neustadt Vogtland',
      'Neuss',
      'Neusorg',
      'Neusitz',
      'Neuschönau',
      'Neusalza-Spremberg',
      'Neuruppin',
      'Neuried',
      'Neureichenau',
      'Neupotz',
      'Neuötting',
      'Neunkirchen am Sand',
      'Neunkirchen am Main',
      'Neunkirchen',
      'Neunkhausen',
      'Neundorf',
      'Neunburg vorm Wald',
      'Neumünster',
      'Neumühle',
      'Neumarkt-Sankt Veit',
      'Neumarkt in der Oberpfalz',
      'Neumark',
      'Neulußheim',
      'Neulewin',
      'Neuler',
      'Neuland',
      'Neukölln',
      'Neukloster',
      'Neukirchen beim Heiligen Blut',
      'Neukirchen-Balbini',
      'Neukirchen',
      'Neukirch',
      'Neukirch/Lausitz',
      'Neukieritzsch',
      'Neu Kaliß',
      'Neukalen',
      'Neu-Isenburg',
      'Neuhütten',
      'Neuhofen',
      'Neuhof an der Zenn',
      'Neuhof',
      'Neuhaus-Schierschnitz',
      'Neuhausen auf den Fildern',
      'Neuhausen',
      'Neuhäusel',
      'Neuhaus an der Pegnitz',
      'Neuhaus an der Oste',
      'Neuhaus am Rennweg',
      'Neuhaus am Inn',
      'Neuhaus',
      'Neuharlingersiel',
      'Neuhardenberg',
      'Neugraben-Fischbek',
      'Neugersdorf',
      'Neufraunhofen',
      'Neufra',
      'Neuffen',
      'Neufahrn bei Freising',
      'Neufahrn',
      'Neuerburg',
      'Neuental',
      'Neuenstein',
      'Neuenstadt am Kocher',
      'Neuensalz',
      'Neuenrade',
      'Neuenmarkt',
      'Neuenkirchen',
      'Neuenhagen',
      'Neuenfelde',
      'Neuendettelsau',
      'Neuenbürg',
      'Neuenburg am Rhein',
      'Neudrossenfeld',
      'Neudorf-Bornstein',
      'Neudietendorf',
      'Neudenau',
      'Neu Darchau',
      'Neuburg an der Donau',
      'Neuburg',
      'Neubulach',
      'Neubukow',
      'Neubrunn',
      'Neubrück',
      'Neubrandenburg',
      'Neubörger',
      'Neubiberg',
      'Neubeuern',
      'Neuberend',
      'Neu-Anspach',
      'Neualbenreuth',
      'Netzschkau',
      'Nettetal',
      'Nettersheim',
      'Netphen',
      'Nesselwang',
      'Neschwitz',
      'Nersingen',
      'Neresheim',
      'Nerchau',
      'Nentershausen',
      'Nennslingen',
      'Nennhausen',
      'Nellingen',
      'Neinstedt',
      'Neidlingen',
      'Neidenstein',
      'Nehren',
      'Negernbötel',
      'Neckarzimmern',
      'Neckarwestheim',
      'Neckartenzlingen',
      'Neckartailfingen',
      'Neckarsulm',
      'Neckarsteinach',
      'Neckargerach',
      'Neckargemünd',
      'Neckarbischofsheim',
      'Nebra',
      'Nebelschütz',
      'Nauwalde',
      'Nauroth',
      'Nauort',
      'Naunhof',
      'Naundorf',
      'Naumburg',
      'Nauendorf',
      'Nauen',
      'Nattheim',
      'Nastätten',
      'Nassau',
      'Narsdorf',
      'Nanzdietschweiler',
      'Nandlstadt',
      'Namborn',
      'Nalbach',
      'Naila',
      'Nahrendorf',
      'Nahe',
      'Nagold',
      'Nagel',
      'Nackenheim',
      'Nachterstedt',
      'Nachrodt-Wiblingwerde',
      'Nabburg',
      'Mylau',
      'Mutzschen',
      'Mutterstadt',
      'Mutlangen',
      'Muschwitz',
      'Müschenbach',
      'Murrhardt',
      'Murr',
      'Murnau am Staffelsee',
      'Murg',
      'Münzenberg',
      'Münster-Sarmsheim',
      'Münstermaifeld',
      'Münsterhausen',
      'Münsterdorf',
      'Munster',
      'Münster',
      'Münsingen',
      'Münsing',
      'Munningen',
      'Münnerstadt',
      'Munkbrarup',
      'Munderkingen',
      'Hannoversch Münden',
      'Mundelsheim',
      'Münchweiler an der Rodalb',
      'Münchweiler an der Alsenz',
      'Münchsteinach',
      'Münchsmünster',
      'Münchhausen',
      'Münchenbernsdorf',
      'Munich',
      'Müncheberg',
      'Münchberg',
      'Mülsen',
      'Müllrose',
      'Müllheim',
      'Mülheim',
      'Mulfingen',
      'Muldenstein',
      'Mulda',
      'Mühltroff',
      'Mühl Rosin',
      'Mühlingen',
      'Mühlheim am Bach',
      'Mühlheim am Main',
      'Stuttgart Mühlhausen',
      'Mühlhausen',
      'Mühlen Eichsen',
      'Mühlenbach',
      'Mühldorf',
      'Mühlberg',
      'Mühlau',
      'Mühlacker',
      'Muggensturm',
      'Müggelheim',
      'Mügeln',
      'Mudersbach',
      'Müden',
      'Mudau',
      'Mücka',
      'Mücheln',
      'Much',
      'Mötzingen',
      'Mötzing',
      'Möttingen',
      'Motten',
      'Mössingen',
      'Möser',
      'Mosbach',
      'Morsum',
      'Morsbach',
      'Mörlenbach',
      'Moritzburg',
      'Moringen',
      'Morbach',
      'Moosthenning',
      'Moosinning',
      'Moosburg',
      'Moosbach',
      'Moosach',
      'Moos',
      'Moorrege',
      'Moorfleet',
      'Moorenweis',
      'Monzingen',
      'Monzelfeld',
      'Montabaur',
      'Monsheim',
      'Mönsheim',
      'Monschau',
      'Mönkeberg',
      'Monheim am Rhein',
      'Monheim',
      'Mönchweiler',
      'Mönchsroth',
      'Mönchsdeggingen',
      'Mönchhagen',
      'Mönchenholzhausen',
      'Mönchengladbach',
      'Mönchberg',
      'Mommenheim',
      'Mömlingen',
      'Mömbris',
      'Molschleben',
      'Mölln',
      'Möllenhagen',
      'Molfsee',
      'Molbergen',
      'Moisburg',
      'Möhrendorf',
      'Mohlsdorf',
      'Möhlau',
      'Möglingen',
      'Mögglingen',
      'Mogendorf',
      'Moers',
      'Mödingen',
      'Mockrehna',
      'Möckmühl',
      'Möckern',
      'Mochau',
      'Moabit',
      'Mixdorf',
      'Mitwitz',
      'Mittweida',
      'Mitterteich',
      'Mitterskirchen',
      'Mitterfels',
      'Mittenwalde',
      'Mittenwald',
      'Mittenaar',
      'Mittelstetten',
      'Mittelschöntal',
      'Mittelneufnach',
      'Mittelhof',
      'Mittelherwigsdorf',
      'Mittel-Gründau',
      'Mitteleschenbach',
      'Mittelbiberach',
      'Mittegroßefehn',
      'Mistelgau',
      'Mirow',
      'Mintraching',
      'Minfeld',
      'Minden',
      'Mindelheim',
      'Milz',
      'Groß Miltzow',
      'Miltenberg',
      'Miltach',
      'Milmersdorf',
      'Mildstedt',
      'Mildenau',
      'Mihla',
      'Mietingen',
      'Mieste',
      'Miesbach',
      'Mielkendorf',
      'Miehlen',
      'Midlum',
      'Mickten',
      'Mickhausen',
      'Michendorf',
      'Michelstadt',
      'Michelsneukirchen',
      'Michelfeld',
      'Michelbach an der Bilz',
      'Michelau',
      'Meyenburg',
      'Meuselwitz',
      'Meuselbach',
      'Meudt',
      'Metzingen',
      'Mettmann',
      'Mettlach',
      'Mettingen',
      'Mettenheim',
      'Mettendorf',
      'Metten',
      'Metelen',
      'Meßstetten',
      'Meßkirch',
      'Messingen',
      'Messel',
      'Mesekenhagen',
      'Meschede',
      'Merzig',
      'Merzhausen',
      'Merzen',
      'Merzalben',
      'Merxheim',
      'Mertloch',
      'Mertingen',
      'Mertesdorf',
      'Merseburg',
      'Merklingen',
      'Merkendorf',
      'Merkenbach',
      'Mering',
      'Merenberg',
      'Merdingen',
      'Merchweiler',
      'Merching',
      'Meppen',
      'Menteroda',
      'Menslage',
      'Mengkofen',
      'Mengerskirchen',
      'Mengersgereuth-Hämmern',
      'Mengenich',
      'Mengen',
      'Mendig',
      'Menden',
      'Memmingerberg',
      'Memmingen',
      'Memmelsdorf',
      'Melverode',
      'Melsungen',
      'Melsdorf',
      'Melsbach',
      'Mellrichstadt',
      'Mellinghausen',
      'Mellingen',
      'Mellenbach-Glasbach',
      'Melle',
      'Meldorf',
      'Melchow',
      'Melbeck',
      'Melbach',
      'Meitzendorf',
      'Meitingen',
      'Meißenheim',
      'Meissen',
      'Meisenheim',
      'Meiningen',
      'Meinerzhagen',
      'Meinersen',
      'Meiderich',
      'Mehrstetten',
      'Mehringen',
      'Mehring',
      'Mehrhoog',
      'Mehren',
      'Mehltheuer',
      'Mehlmeisel',
      'Mehlingen',
      'Mehlbach',
      'Meersburg',
      'Meerdorf',
      'Meerbusch',
      'Meerbeck',
      'Meerane',
      'Meeder',
      'Medebach',
      'Meddersheim',
      'Mecklenburg',
      'Meckesheim',
      'Meckenheim',
      'Meckenbeuren',
      'Mechterstädt',
      'Mechernich',
      'Mayen',
      'Maxsain',
      'Maxhütte-Haidhof',
      'Maxdorf',
      'Mauth',
      'Maulburg',
      'Maulbronn',
      'Mauerstetten',
      'Mauern',
      'Mauer',
      'Mastershausen',
      'Maßweiler',
      'Massing',
      'Masserberg',
      'Massenbachhausen',
      'Maßbach',
      'Maselheim',
      'Mascherode',
      'Masburg',
      'Marzling',
      'Marzahn',
      'Marxheim',
      'Marxen',
      'Martinshöhe',
      'Martinsheim',
      'Martinhagen',
      'Martfeld',
      'Martensrade',
      'Marsberg',
      'Marquartstein',
      'Marpingen',
      'Maroldsweisach',
      'Marnheim',
      'Marlow',
      'Marloffstein',
      'Marl',
      'Marktzeuln',
      'Markt Wald',
      'Marktsteft',
      'Markt Schwaben',
      'Marktschorgast',
      'Marktschellenberg',
      'Markt Rettenbach',
      'Marktredwitz',
      'Marktoffingen',
      'Marktoberdorf',
      'Markt Nordheim',
      'Marktleuthen',
      'Marktleugast',
      'Marktl',
      'Markt Indersdorf',
      'Marktheidenfeld',
      'Marktgraitz',
      'Markt Erlbach',
      'Marktbreit',
      'Markt Bibart',
      'Markt Berolzheim',
      'Marktbergel',
      'Marksuhl',
      'Markranstädt',
      'Markneukirchen',
      'Marklohe',
      'Marklkofen',
      'Markkleeberg',
      'Märkisches Viertel',
      'Markgröningen',
      'Markersdorf',
      'Markersbach',
      'Markdorf',
      'Maring-Noviand',
      'Marienwerder',
      'Mariental',
      'Marienrachdorf',
      'Marienheide',
      'Marienhafe',
      'Marienfelde',
      'Mariendorf',
      'Marienberg',
      'Mariaposching',
      'Margetshöchheim',
      'Marburg an der Lahn',
      'Marbach am Neckar',
      'Mantel',
      'Mansfeld',
      'Manschnow',
      'Mannheim',
      'Manderscheid',
      'Manching',
      'Mamming',
      'Mammendorf',
      'Mammelzen',
      'Malterdingen',
      'Malsfeld',
      'Malschwitz',
      'Malsch',
      'Malliß',
      'Malgersdorf',
      'Malente',
      'Malchow',
      'Malching',
      'Malchin',
      'Malborn',
      'Maitenbeth',
      'Maisach',
      'Mainzweiler',
      'Mainz',
      'Maintal',
      'Mainstockheim',
      'Mainleus',
      'Mainhardt',
      'Mainburg',
      'Mainbernheim',
      'Mainaschaff',
      'Maikammer',
      'Maihingen',
      'Maierhöfen',
      'Mähring',
      'Mahlsdorf',
      'Mahlberg',
      'Magstadt',
      'Magdeburg',
      'Magdala',
      'Mackenbach',
      'Machern',
      'Lychen',
      'Lützow',
      'Lutzerath',
      'Lützen',
      'Lützelbach',
      'Lutter am Barenberge',
      'Lütjensee',
      'Lütjenburg',
      'Lustadt',
      'Lurup',
      'Lürschau',
      'Lupburg',
      'Lunzenau',
      'Lunestedt',
      'Lünen',
      'Lüneburg',
      'Lunden',
      'Luisenthal',
      'Luhe-Wildenau',
      'Luhden',
      'Lügde',
      'Lugau',
      'Ludwigsstadt',
      'Ludwigslust',
      'Ludwigshafen am Rhein',
      'Ludwigsfelde',
      'Ludwigsburg',
      'Lüdinghausen',
      'Lüdersfeld',
      'Lüdersdorf',
      'Lüderitz',
      'Lüder',
      'Lüdenscheid',
      'Luckenwalde',
      'Luckau',
      'Lucka',
      'Lüchow',
      'Lübz',
      'Lübtheen',
      'Lübstorf',
      'Lübow',
      'Lubmin',
      'Lübeck',
      'Lubnjow',
      'Lubin',
      'Lübbecke',
      'Lübars',
      'Loxstedt',
      'Löwenstein',
      'Lottstetten',
      'Lotte',
      'Lostau',
      'Lößnitz',
      'Loßburg',
      'Losheim',
      'Loschwitz',
      'Lörzweiler',
      'Lorup',
      'Lorsch',
      'Lörrach',
      'Lorch',
      'Lonsee',
      'Lonnig',
      'Lonnerstadt',
      'Löningen',
      'Longuich',
      'Longkamp',
      'Lommatzsch',
      'Lollar',
      'Loitz',
      'Loiching',
      'Lohsa',
      'Lohra',
      'Lohr am Main',
      'Lohne',
      'Löhne',
      'Löhnberg',
      'Lohmen',
      'Lohmar',
      'Lohfelden',
      'Lohberg',
      'Löffingen',
      'Loffenau',
      'Löf',
      'Loddin',
      'Löcknitz',
      'Löchgau',
      'Lochau',
      'Loburg',
      'Löbtau',
      'Lobstädt',
      'Löbnitz',
      'Löbichau',
      'Löberitz',
      'Bad Lobenstein',
      'Löbejün',
      'Löbau',
      'Litzendorf',
      'Litzelstetten',
      'List',
      'Lissendorf',
      'Lippstadt',
      'Linz am Rhein',
      'Lintig',
      'Linnich',
      'Linn',
      'Lingenfeld',
      'Lingen',
      'Lindwedel',
      'Lindow',
      'Lindlar',
      'Lindhorst',
      'Lindewitt',
      'Lindenfels',
      'Lindenberg',
      'Linden',
      'Lindberg',
      'Lindau',
      'Linau',
      'Limburgerhof',
      'Limburg an der Lahn',
      'Limbach-Oberfrohna',
      'Limbach',
      'Lilienthal',
      'Lieskau',
      'Lieser',
      'Lienen',
      'Liebstadt',
      'Lieblos',
      'Lieberose',
      'Liebenwalde',
      'Liebenburg',
      'Liebenau',
      'Lichterfelde',
      'Lichtentanne',
      'Lichtenstein',
      'Lichtenrade',
      'Lichtenfels',
      'Lichtenberg',
      'Lichtenau',
      'Lichte',
      'Lich',
      'Leverkusen',
      'Leutkirch',
      'Leutesdorf',
      'Leutershausen',
      'Leutersdorf',
      'Leutenberg',
      'Leutenbach',
      'Leupoldsgrün',
      'Leuna',
      'Leun',
      'Leuchtenberg',
      'Leubsdorf',
      'Leubnitz',
      'Leuben',
      'Letzlingen',
      'Letschin',
      'Leopoldshöhe',
      'Leonberg',
      'Lenzkirch',
      'Lenzen',
      'Lentföhrden',
      'Lensahn',
      'Lennestadt',
      'Lenggries',
      'Lengerich',
      'Lengenwang',
      'Lengenfeld',
      'Lengefeld',
      'Lengede',
      'Lengdorf',
      'Lemwerder',
      'Lemsahl-Mellingstedt',
      'Lemgo',
      'Lemförde',
      'Lemberg',
      'Leiwen',
      'Leitzkau',
      'Leißling',
      'Leisnig',
      'Leipzig',
      'Leipheim',
      'Leinzell',
      'Leinfelden-Echterdingen',
      'Leinefelde-Worbis',
      'Leinburg',
      'Leimersheim',
      'Leimen',
      'Leimbach',
      'Leiferde',
      'Leidersbach',
      'Leichlingen',
      'Leiblfing',
      'Leibertingen',
      'Lehrte',
      'Lehrensteinsfeld',
      'Lehre',
      'Lehrberg',
      'Lehndorf',
      'Lehmkuhlen',
      'Lehmen',
      'Lehesten',
      'Lehe',
      'Legden',
      'Legau',
      'Leezen',
      'Leezdorf',
      'Leese',
      'Leer',
      'Leegebruch',
      'Leck',
      'Lechbruck',
      'Lebus',
      'Lebach',
      'Lawalde',
      'Lautrach',
      'Lautertal',
      'Lauterhofen',
      'Lauterecken',
      'Lauterbach',
      'Lauterbach/Schwarzwald',
      'Lauter',
      'Lautenbach',
      'Laußnitz',
      'Laußig',
      'Lauscha',
      'Laupheim',
      'Lauingen',
      'Laugna',
      'Lauffen am Neckar',
      'Laufenburg',
      'Laufen',
      'Laufdorf',
      'Lauf an der Pegnitz',
      'Laufach',
      'Lauf',
      'Lauenhagen',
      'Lauenburg',
      'Lauenbrück',
      'Lauenau',
      'Laudenbach',
      'Lauchröden',
      'Lauchheim',
      'Lauchhammer',
      'Laucha',
      'Lauben',
      'Laubach',
      'Lathen',
      'Lastrup',
      'Lassan',
      'Lasbek',
      'Lappersdorf',
      'Lanstrop',
      'Lankwitz',
      'Lankow',
      'Langweid',
      'Langwedel',
      'Langula',
      'Langsur',
      'Langstedt',
      'Langquaid',
      'Langlingen',
      'Langgöns',
      'Langewiesen',
      'Langerwehe',
      'Langerringen',
      'Langeoog',
      'Langenzenn',
      'Langenwetzendorf',
      'Langenstein',
      'Langensendelbach',
      'Langenselbold',
      'Langenpreising',
      'Langenorla',
      'Langenneufnach',
      'Langenmosen',
      'Langenlonsheim',
      'Langenhahn',
      'Langenhagen',
      'Langenfeld',
      'Langenenslingen',
      'Langeneichstädt',
      'Langendorf',
      'Langenburg',
      'Langenbogen',
      'Langenbernsdorf',
      'Langenberg',
      'Langenbach bei Marienberg',
      'Langenbach',
      'Langenau',
      'Langenargen',
      'Langenaltheim',
      'Langen',
      'Langelsheim',
      'Langeln',
      'Langballig',
      'Landstuhl',
      'Landshut',
      'Landscheid',
      'Landsberied',
      'Landsberg',
      'Landsberg am Lech',
      'Landolfshausen',
      'Landesbergen',
      'Landau in der Pfalz',
      'Landau an der Isar',
      'Lamstedt',
      'Lamspringe',
      'Lampertswalde',
      'Lampertheim',
      'Lamme',
      'Lamerdingen',
      'Lambsheim',
      'Lambrechtshagen',
      'Lambrecht',
      'Lam',
      'Lalling',
      'Lalendorf',
      'Laichingen',
      'Lahr',
      'Lahnstein',
      'Lähden',
      'Lägerdorf',
      'Lage',
      'Bad Laer',
      'Laer',
      'Ladenburg',
      'Ladelund',
      'Ladbergen',
      'Lachendorf',
      'Lachen',
      'Laboe',
      'Laberweinting',
      'Laatzen',
      'Bad Laasphe',
      'Laar',
      'Laage',
      'Kyritz',
      'Kyllburg',
      'Kutzenhausen',
      'Kutenholz',
      'Kusterdingen',
      'Küsten',
      'Kusey',
      'Kusel',
      'Kürten',
      'Seiffen',
      'Kurort Oybin',
      'Kurort Oberwiesenthal',
      'Kurort Jonsdorf',
      'Kurort Gohrisch',
      'Kürnbach',
      'Kürnach',
      'Küps',
      'Kuppenheim',
      'Kupferzell',
      'Kupferberg',
      'Künzing',
      'Künzelsau',
      'Künzell',
      'Kunreuth',
      'Kümmersbruck',
      'Kummerfeld',
      'Kumhausen',
      'Külsheim',
      'Kulmbach',
      'Kulmain',
      'Küllstedt',
      'Kührstedt',
      'Kühndorf',
      'Kühbach',
      'Kuhardt',
      'Kuddewörde',
      'Kuchen',
      'Kubschütz',
      'Krün',
      'Krumpa',
      'Krummesse',
      'Krummennaab',
      'Krumbach',
      'Kruft',
      'Kröv',
      'Krostitz',
      'Kröslin',
      'Kropstädt',
      'Kroppenstedt',
      'Kröppelshagen-Fahrendorf',
      'Kropp',
      'Kröpelin',
      'Kronshagen',
      'Kronburg',
      'Kronberg',
      'Kronau',
      'Kronach',
      'Krombach',
      'Krölpa',
      'Kritzmow',
      'Kriftel',
      'Kriegsfeld',
      'Kriebstein',
      'Kriebitzsch',
      'Krickenbach',
      'Kreuzwertheim',
      'Kreuztal',
      'Kreuzberg',
      'Kreuzau',
      'Kreuth',
      'Kretzschau',
      'Kressbronn am Bodensee',
      'Kremperheide',
      'Krempe',
      'Kremmen',
      'Kreischa',
      'Kreiensen',
      'Krefeld',
      'Krebeck',
      'Krautheim',
      'Krauthausen',
      'Krauschwitz',
      'Krauchenwies',
      'Kranichfeld',
      'Kranenburg',
      'Kramerhof',
      'Kralenriede',
      'Krakow am See',
      'Krailling',
      'Kraiburg am Inn',
      'Kraftsdorf',
      'Kötzting',
      'Kötzschau',
      'Kottweiler-Schwanden',
      'Kottenheim',
      'Köthen',
      'Kößlarn',
      'Kossa',
      'Koserow',
      'Kosel',
      'Korschenbroich',
      'Körperich',
      'Kornwestheim',
      'Korntal',
      'Körner',
      'Körle',
      'Kordel',
      'Korbach',
      'Korb',
      'Köpenick',
      'Berlin Köpenick',
      'Konzell',
      'Konz',
      'Konstanz',
      'Konradsreuth',
      'Konradshöhe',
      'Könnern',
      'Könitz',
      'Königs Wusterhausen',
      'Königswinter',
      'Königswartha',
      'Königswalde',
      'Königstein im Taunus',
      'Königstein',
      'Königslutter am Elm',
      'Bad Königshofen im Grabfeld',
      'Königshain',
      'Königsfeld',
      'Königsfeld im Schwarzwald',
      'Königsee',
      'Königsdorf',
      'Königsbrunn',
      'Königsbrück',
      'Königsbronn',
      'Königheim',
      'Köngernheim',
      'Köngen',
      'Köln',
      'Kölln-Reisiek',
      'Kollnburg',
      'Kollmar',
      'Kölleda',
      'Kolkwitz',
      'Kolitzheim',
      'Kölbingen',
      'Kolbingen',
      'Kolbermoor',
      'Kohlberg',
      'Köfering',
      'Ködnitz',
      'Köditz',
      'Kodersdorf',
      'Kochel',
      'Koblenz',
      'Knittlingen',
      'Knittelsheim',
      'Knetzgau',
      'Klütz',
      'Klüsserath',
      'Kluse',
      'Klotzsche',
      'Klötze',
      'Klotten',
      'Klostermansfeld',
      'Klosterlechfeld',
      'Klitten',
      'Klipphausen',
      'Klink',
      'Klingenthal',
      'Klingenstein',
      'Klingenmünster',
      'Klingenberg am Main',
      'Klietz',
      'Klieken',
      'Kleve',
      'Klettbach',
      'Kleinzschocher',
      'Klein-Winternheim',
      'Kleinwenden',
      'Klein Wanzleben',
      'Kleinwallstadt',
      'Kleinsendelbach',
      'Klein Schwülper',
      'Klein Rönnau',
      'Klein Rogahn',
      'Kleinrinderfeld',
      'Klein Reken',
      'Kleinostheim',
      'Klein Nordende',
      'Kleinmaischeid',
      'Kleinmachnow',
      'Kleinlangheim',
      'Kleinkahl',
      'Kleinheubach',
      'Klein Gusborn',
      'Kleinfurra',
      'Kleinblittersdorf',
      'Klein Berßen',
      'Kleinaitingen',
      'Klausen',
      'Klausdorf',
      'Kladow',
      'Kitzscher',
      'Kitzingen',
      'Kitzen',
      'Kist',
      'Kißlegg',
      'Kissing',
      'Kissenbrück',
      'Kisdorf',
      'Kirtorf',
      'Kirschweiler',
      'Kirschau',
      'Kirrweiler',
      'Kirn',
      'Kirkel',
      'Kirchzell',
      'Kirchzarten',
      'Kirchworbis',
      'Kirchwerder',
      'Kirchweidach',
      'Kirchwalsede',
      'Kirchtimke',
      'Kirchseeon',
      'Kirchseelte',
      'Kirchroth',
      'Kirchlinteln',
      'Kirchlengern',
      'Kirchlauter',
      'Kirchhundem',
      'Kirchheim unter Teck',
      'Kirchheimbolanden',
      'Kirchheim bei München',
      'Kirchheim an der Weinstraße',
      'Kirchheim am Ries',
      'Kirchheim am Neckar',
      'Kirchheim',
      'Kirchhaslach',
      'Kirchham',
      'Kirchhain',
      'Kirchgellersen',
      'Kirchenthumbach',
      'Kirchentellinsfurt',
      'Kirchensittenbach',
      'Kirchenpingarten',
      'Kirchenlamitz',
      'Kirchen',
      'Eiselfing',
      'Kirchehrenbach',
      'Kirchdorf im Wald',
      'Kirchdorf am Inn',
      'Kirchdorf',
      'Kirchbrak',
      'Kirchberg an der Murr',
      'Kirchberg an der Jagst',
      'Kirchberg an der Iller',
      'Kirchberg',
      'Kirchardt',
      'Kirchanschöring',
      'Kippenheim',
      'Kindsbach',
      'Kinderbeuern',
      'Kindenheim',
      'Kindelbrück',
      'Kieselbronn',
      'Kierspe',
      'Kienberg',
      'Kiel',
      'Kiefersfelden',
      'Kiedrich',
      'Kiebitzreihe',
      'Kevelaer',
      'Ketzin',
      'Kettig',
      'Kettershausen',
      'Kettenkamp',
      'Ketsch',
      'Kessin',
      'Kessenich',
      'Kerzenheim',
      'Kerpen',
      'Kenzingen',
      'Kenn',
      'Kempten (Allgäu)',
      'Kempenich',
      'Kempen',
      'Kemnitz',
      'Kemnath',
      'Kemmern',
      'Kemberg',
      'Kelsterbach',
      'Kellmünz',
      'Kellinghusen',
      'Kellenhusen',
      'Kell',
      'Kelkheim',
      'Kelheim',
      'Kelbra',
      'Kelberg',
      'Keitum',
      'Kehrig',
      'Kehl',
      'Kefenrod',
      'Kayna',
      'Kayhude',
      'Kavelstorf',
      'Kaulsdorf',
      'Kaufering',
      'Kaufbeuren',
      'Kaub',
      'Katzweiler',
      'Katzhütte',
      'Katzenelnbogen',
      'Katharinenberg',
      'Kastorf',
      'Kastl',
      'Kastellaun',
      'Kastel',
      'Kassel',
      'Kasseedorf',
      'Kasendorf',
      'Kasel',
      'Karstädt',
      'Karsdorf',
      'Karsbach',
      'Karow',
      'Karlstadt',
      'Karlsruhe',
      'Karlskron',
      'Karlshuld',
      'Karlshorst',
      'Karlshagen',
      'Bad Karlshafen',
      'Karlsfeld',
      'Karben',
      'Karbach',
      'Kapsweyer',
      'Kappelrodeck',
      'Kappeln',
      'Kannawurf',
      'Kandern',
      'Kandel',
      'Kamsdorf',
      'Kamp-Lintfort',
      'Kamp-Bornhofen',
      'Kammerstein',
      'Kamenz',
      'Kamen',
      'Kaltenwestheim',
      'Kaltennordheim',
      'Kaltenkirchen',
      'Kaltenengers',
      'Kallstadt',
      'Kallmünz',
      'Kall',
      'Kalkhorst',
      'Kalkar',
      'Kalefeld',
      'Kalchreuth',
      'Kalbe',
      'Kakenstorf',
      'Kaisheim',
      'Kaiserslautern',
      'Kaisersesch',
      'Kaisersbach',
      'Kahl am Main',
      'Kahla',
      'Kadenbach',
      'Kaarst',
      'Jüterbog',
      'Jürgenshagen',
      'Jünkerath',
      'Jungingen',
      'Jülich',
      'Julbach',
      'Juist',
      'Jühnde',
      'Jugenheim',
      'Judenbach',
      'Jüchsen',
      'Jüchen',
      'Jübek',
      'Jork',
      'Jördenstorf',
      'Jöhstadt',
      'Jöhlingen',
      'Johannisthal',
      'Johanniskirchen',
      'Johanngeorgenstadt',
      'Johannesberg',
      'Jockgrim',
      'Joachimsthal',
      'Jever',
      'Jevenstedt',
      'Jetzendorf',
      'Jestetten',
      'Jesteburg',
      'Jeßnitz',
      'Jessen',
      'Jesewitz',
      'Jesenwang',
      'Jesberg',
      'Jerxheim',
      'Jersbek',
      'Jerrishoe',
      'Jerichow',
      'Jengen',
      'Jena',
      'Jemgum',
      'Jembke',
      'Jävenitz',
      'Jatznick',
      'Jarmen',
      'Jänschwalde',
      'Jandelsbrunn',
      'Jameln',
      'Jacobsdorf',
      'Jahnsdorf',
      'Jagstzell',
      'Jagsthausen',
      'Itzstedt',
      'Itzehoe',
      'Ittlingen',
      'Itterbeck',
      'Issum',
      'Isselburg',
      'Ispringen',
      'Isny',
      'Ismaning',
      'Isernhagen Farster Bauerschaft',
      'Iserlohn',
      'Isenbüttel',
      'Isen',
      'Irxleben',
      'Irsee',
      'Irschenberg',
      'Irsch',
      'Irrel',
      'Irlbach',
      'Irchenrieth',
      'Ipsheim',
      'Ippesheim',
      'Iphofen',
      'Inzlingen',
      'Inzigkofen',
      'Inzell',
      'Insingen',
      'Insheim',
      'Inning am Holz',
      'Inning am Ammersee',
      'Innernzell',
      'Ingolstadt',
      'Ingoldingen',
      'Ingersleben',
      'Ingelheim am Rhein',
      'Ingelfingen',
      'Inden',
      'Inchenhofen',
      'Imsbach',
      'Immenstadt im Allgäu',
      'Immenstaad am Bodensee',
      'Immenreuth',
      'Immenhausen',
      'Immendingen',
      'Immelborn',
      'Ilvesheim',
      'Ilshofen',
      'Ilsfeld',
      'Ilsenburg',
      'Ilmmünster',
      'Ilmenau',
      'Illschwang',
      'Illmensee',
      'Illingen',
      'Illertissen',
      'Illerrieden',
      'Ilfeld',
      'Ilbesheim',
      'Ilberstedt',
      'Ihrlerstein',
      'Ihringen',
      'Ihlienworth',
      'Iggingen',
      'Iggensbach',
      'Igersheim',
      'Igensdorf',
      'Igel',
      'Ifta',
      'Iffezheim',
      'Iffeldorf',
      'Idstein',
      'Idar-Oberstein',
      'Icking',
      'Ichtershausen',
      'Ichenhausen',
      'Ibbenbüren',
      'Huy-Neinstedt',
      'Hüttlingen',
      'Hüttisheim',
      'Hutthurm',
      'Hüttenrode',
      'Hütschenhausen',
      'Husum',
      'Hürup',
      'Hürth',
      'Hurlach',
      'Hünxe',
      'Hungen',
      'Hünfeld',
      'Hundsangen',
      'Hunding',
      'Hundeshagen',
      'Hunderdorf',
      'Hummelsbüttel',
      'Humboldtkolonie',
      'Hülsede',
      'Hüllhorst',
      'Hülben',
      'Huisheim',
      'Huglfing',
      'Hügelsheim',
      'Hüfingen',
      'Hüffenhardt',
      'Hüffelsheim',
      'Hude',
      'Hüde',
      'Hückeswagen',
      'Hückelhoven',
      'Hoym',
      'Hoyerswerda',
      'Hoya',
      'Höxter',
      'Hövelhof',
      'Höttingen',
      'Hötensleben',
      'Höslwang',
      'Hosenfeld',
      'Hösbach',
      'Horstmar',
      'Hörstel',
      'Horstedt',
      'Horst',
      'Hörselgau',
      'Horrenberg',
      'Hornstorf',
      'Hörnsheim',
      'Hornhausen',
      'Horneburg',
      'Hornburg',
      'Hornberg',
      'Hornbach',
      'Horn',
      'Hormersdorf',
      'Horka',
      'Horhausen',
      'Hörgertshausen',
      'Horgenzell',
      'Horgau',
      'Hördt',
      'Hörden',
      'Hordel',
      'Horben',
      'Horb am Neckar',
      'Höpfingen',
      'Hopferau',
      'Hoogstede',
      'Hondelage',
      'Homburg',
      'Homberg',
      'Holzwickede',
      'Holzweißig',
      'Holzminden',
      'Holzmaden',
      'Holzkirchen',
      'Holzheim',
      'Holzhausen an der Haide',
      'Bad Holzhausen',
      'Holzgünz',
      'Holzgerlingen',
      'Holzdorf',
      'Holzappel',
      'Holtsee',
      'Holtland',
      'Holm',
      'Hollstadt',
      'Hollingstedt',
      'Hollfeld',
      'Holler',
      'Hollenstedt',
      'Hollenbach',
      'Holleben',
      'Holle',
      'Holdorf',
      'Hoisdorf',
      'Höingen',
      'Höhr-Grenzhausen',
      'Hohnstein',
      'Höhnstedt',
      'Hohnhorst',
      'Hohndorf',
      'Hohn',
      'Hohenwestedt',
      'Hohenwarthe',
      'Hohenwarth',
      'Hohenwarsleben',
      'Hohenthurm',
      'Hohenthann',
      'Hohentengen',
      'Hohenstein-Ernstthal',
      'Hohenpolding',
      'Hohenpeißenberg',
      'Hohen Neuendorf',
      'Hohenmölsen',
      'Hohenlockstedt',
      'Hohenlinden',
      'Hohenleuben',
      'Hohenleipisch',
      'Hohenkammer',
      'Hohenhameln',
      'Hohenfurch',
      'Hohenfels',
      'Hohenfelde',
      'Hohendodeleben',
      'Hohenburg',
      'Hohenbrunn',
      'Hohenbocka',
      'Hohenberg an der Eger',
      'Höhenberg',
      'Hohenaspe',
      'Höheischweiler',
      'Höheinöd',
      'Hohburg',
      'Hohberg',
      'Hofstetten',
      'Hofkirchen',
      'Hofheim in Unterfranken',
      'Hofheim am Taunus',
      'Hofgeismar',
      'Höfen an der Enz',
      'Hofbieber',
      'Hof',
      'Hodenhagen',
      'Hockenheim',
      'Höckendorf',
      'Höchst im Odenwald',
      'Höchstädt bei Thiersheim',
      'Höchstädt an der Donau',
      'Hochstadt am Main',
      'Höchstadt an der Aisch',
      'Hochspeyer',
      'Hochkirch',
      'Hochheim am Main',
      'Höchheim',
      'Hochfeld',
      'Höchenschwand',
      'Hochdorf',
      'Hochdonn',
      'Höchberg',
      'Hitzhusen',
      'Hitzacker',
      'Hirzenhain',
      'Hirschhorn',
      'Hirschfelde',
      'Hirschfeld',
      'Hirschbach',
      'Hirschau',
      'Hirschaid',
      'Hirrlingen',
      'Hipstedt',
      'Hinterzarten',
      'Hinterweidenthal',
      'Hinterschmiding',
      'Hinte',
      'Himmelstadt',
      'Himmelpforten',
      'Himmelkron',
      'Himbergen',
      'Hilzingen',
      'Hiltpoltstein',
      'Hilter',
      'Hiltenfingen',
      'Hilpoltstein',
      'Hillscheid',
      'Hillesheim',
      'Hillerse',
      'Hille',
      'Hilgertshausen-Tandern',
      'Hilgert',
      'Hilgermissen',
      'Hildrizhausen',
      'Hildesheim',
      'Hilders',
      'Hilden',
      'Hildburghausen',
      'Hilchenbach',
      'Hilbersdorf',
      'Hiddenhausen',
      'Heyersum',
      'Heyerode',
      'Heusweiler',
      'Heustreu',
      'Heusenstamm',
      'Heuerßen',
      'Heudeber',
      'Heuchlingen',
      'Heuchelheim bei Frankenthal',
      'Heuchelheim',
      'Heubach',
      'Hetzles',
      'Hetzerath',
      'Hettstedt',
      'Hettstadt',
      'Hettingen',
      'Hettenshausen',
      'Hettenleidelheim',
      'Hetlingen',
      'Hessisch Oldendorf',
      'Hessisch Lichtenau',
      'Hessigheim',
      'Heßheim',
      'Heßdorf',
      'Hespe',
      'Hesel',
      'Herzogenrath',
      'Herzogenaurach',
      'Herzlake',
      'Herzhorn',
      'Herzberg am Harz',
      'Herzberg',
      'Herxheim am Berg',
      'Herten',
      'Herschweiler-Pettersheim',
      'Herscheid',
      'Herschdorf',
      'Hersbruck',
      'Herrsching am Ammersee',
      'Herrnhut',
      'Herrngiersdorf',
      'Herrischried',
      'Herrieden',
      'Herrenberg',
      'Heroldsberg',
      'Heroldsbach',
      'Herne',
      'Hermsdorf',
      'Hermeskeil',
      'Hermersberg',
      'Hermaringen',
      'Herrmannshöhe',
      'Hermannsburg',
      'Herleshausen',
      'Heringsdorf',
      'Heringen',
      'Hergisdorf',
      'Hergensweiler',
      'Herforst',
      'Herford',
      'Heretsried',
      'Herdorf',
      'Herdecke',
      'Herbstein',
      'Herbsleben',
      'Herbrechtingen',
      'Herborn',
      'Herbolzheim',
      'Herbertingen',
      'Hepstedt',
      'Heppenheim an der Bergstrasse',
      'Hennweiler',
      'Hennstedt',
      'Hennigsdorf',
      'Hennef',
      'Hengersberg',
      'Henfenfeld',
      'Hendungen',
      'Hemslingen',
      'Hemsbünde',
      'Hemsbach',
      'Hemmoor',
      'Hemmingstedt',
      'Hemmingen',
      'Hemhofen',
      'Hemer',
      'Hemdingen',
      'Hemau',
      'Heltersberg',
      'Helsa',
      'Helpsen',
      'Helmstedt',
      'Helmstadt',
      'Helmbrechts',
      'Hellwege',
      'Hellingen',
      'Hellersdorf',
      'Hellerau',
      'Hellenthal',
      'Hellenhahn-Schellenberg',
      'Helferskirchen',
      'Heldrungen',
      'Helbra',
      'Heitersheim',
      'Heistenbach',
      'Heist',
      'Heinsen',
      'Heinsberg',
      'Heiningen',
      'Heinersdorf',
      'Heinbockel',
      'Heinade',
      'Heimsheim',
      'Heimertingen',
      'Heimenkirch',
      'Heimbuchenthal',
      'Heimbach',
      'Heilsbronn',
      'Heiligkreuzsteinach',
      'Heiligenstedten',
      'Heilbad Heiligenstadt',
      'Heiligenstadt',
      'Heiligensee',
      'Heiligenroth',
      'Heiligenhaus',
      'Heiligenhafen',
      'Heiligengrabe',
      'Heiligenberg',
      'Heilbronn',
      'Heikendorf',
      'Heigenbrücken',
      'Heidgraben',
      'Heidesheim',
      'Heidenheim an der Brenz',
      'Heidenheim',
      'Heidenau',
      'Heiden',
      'Heidelberg',
      'Heideck',
      'Heide',
      'Hehlen',
      'Heeßen',
      'Heeslingen',
      'Heemsen',
      'Heek',
      'Heede',
      'Hedersleben',
      'Heddesheim',
      'Hecklingen',
      'Hechthausen',
      'Hechingen',
      'Hebertshausen',
      'Hebertsfelden',
      'Hayingen',
      'Hawangen',
      'Havixbeck',
      'Haverlah',
      'Havelberg',
      'Hauzenberg',
      'Hauswalde',
      'Hausham',
      'Häusern',
      'Hausen',
      'Hausbruch',
      'Hausach',
      'Hauptstuhl',
      'Haunsheim',
      'Haundorf',
      'Hauenstein',
      'Hatzfeld',
      'Hatzenbühl',
      'Hattstedt',
      'Hattorf',
      'Hattingen',
      'Hattersheim',
      'Hattenhofen',
      'Hatten',
      'Haste',
      'Haßmersheim',
      'Haßloch',
      'Haßleben',
      'Haßfurt',
      'Hassendorf',
      'Hasselfelde',
      'Hassel',
      'Haßbergen',
      'Hasloh',
      'Hasloch',
      'Haslach',
      'Haselünne',
      'Haselhorst',
      'Haseldorf',
      'Haselbach',
      'Haselau',
      'Hasel',
      'Hasbergen',
      'Harzgerode',
      'Harxheim',
      'Harvestehude',
      'Hartmannsdorf',
      'Hartheim',
      'Harthausen',
      'Hartha',
      'Hartenstein',
      'Hartenholm',
      'Harsum',
      'Harsleben',
      'Harsewinkel',
      'Harsefeld',
      'Harsdorf',
      'Harrislee',
      'Harpstedt',
      'Hargesheim',
      'Haren',
      'Hardt',
      'Hardheim',
      'Hardegsen',
      'Harburg',
      'Harbke',
      'Happurg',
      'Hanstedt Eins',
      'Hanstedt',
      'Hannover',
      'Hankensbüttel',
      'Hänigsen',
      'Hanhofen',
      'Hangard',
      'Hanerau-Hademarschen',
      'Handorf',
      'Handewitt',
      'Handeloh',
      'Hanau am Main',
      'Hammoor',
      'Hamminkeln',
      'Hammersbach',
      'Hammerbrücke',
      'Hammelburg',
      'Hammah',
      'Hamm',
      'Hamm (Sieg)',
      'Hameln',
      'Hamdorf',
      'Wandsbek',
      'Marienthal',
      'Hamburg-Mitte',
      'Eimsbüttel',
      'Altona',
      'Hamburg',
      'Hambühren',
      'Hambrücken',
      'Hambergen',
      'Hamberge',
      'Halver',
      'Haltern am See',
      'Halstenbek',
      'Halsenbach',
      'Halsbrücke',
      'Hallstadt',
      'Hallerndorf',
      'Hallenberg',
      'Halle',
      'Halle (Saale)',
      'Hallbergmoos',
      'Halfing',
      'Halensee',
      'Haldenwang',
      'Haldensleben I',
      'Halblech',
      'Halberstadt',
      'Halbemond',
      'Halbe',
      'Hakenfelde',
      'Haiterbach',
      'Hainsfarth',
      'Hainichen',
      'Hain-Gründau',
      'Hainewalde',
      'Haina',
      'Haiming',
      'Haimhausen',
      'Haigerloch',
      'Haiger',
      'Haidmühle',
      'Haibach',
      'Hahnstätten',
      'Hähnichen',
      'Hahnheim',
      'Hahnbach',
      'Hagnau',
      'Hagenow',
      'Hagen im Bremischen',
      'Hagenburg',
      'Hagenbüchach',
      'Hagenbach',
      'Hagen',
      'Hagelstadt',
      'Hage',
      'Hafenlohr',
      'Hadmersleben',
      'Hadamar',
      'Hackenheim',
      'Hachenburg',
      'Habach',
      'Haarbach',
      'Haar',
      'Haan',
      'Haag in Oberbayern',
      'Haag an der Amper',
      'Gyhum',
      'Guxhagen',
      'Gützkow',
      'Guttau',
      'Gütersloh',
      'Gutenstetten',
      'Gutenberg',
      'Gütenbach',
      'Gutach im Breisgau',
      'Gutach',
      'Güstrow',
      'Gusterath',
      'Güster',
      'Güsten',
      'Gusenburg',
      'Gunzenhausen',
      'Günzburg',
      'Günzach',
      'Günthersdorf',
      'Güntersleben',
      'Guntersblum',
      'Gundremmingen',
      'Gundersheim',
      'Gundelsheim',
      'Gundelfingen',
      'Gumtow',
      'Gummersbach',
      'Gülzow',
      'Güglingen',
      'Gudow',
      'Guderhandviertel',
      'Gudensberg',
      'Gückingen',
      'Guben',
      'Gstadt am Chiemsee',
      'Gschwend',
      'Grünwald',
      'Grünstadt',
      'Grünsfeld',
      'Grünkraut',
      'Grünheide',
      'Grünhainichen',
      'Grünhain',
      'Grunewald',
      'Grünendeich',
      'Grünenbach',
      'Grünberg',
      'Grünbach',
      'Grünau',
      'Gruibingen',
      'Grube',
      'Grub',
      'Großzschocher',
      'Groß-Zimmern',
      'Großwoltersdorf',
      'Groß Wokern',
      'Groß Wittensee',
      'Großweitzschen',
      'Großweil',
      'Großwallstadt',
      'Groß-Umstadt',
      'Groß Twülpstedt',
      'Großthiemig',
      'Großsolt',
      'Großschweidnitz',
      'Großschönau',
      'Großschirma',
      'Groß Santersleben',
      'Großrudestedt',
      'Großrückerswalde',
      'Großrosseln',
      'Groß Rosenburg',
      'Großröhrsdorf',
      'Groß-Rohrheim',
      'Groß Rodensleben',
      'Großrinderfeld',
      'Groß Rheide',
      'Großreuth bei Schweinau',
      'Großräschen',
      'Groß Quenstedt',
      'Budestecy',
      'Großpösna',
      'Groß Pankow',
      'Großostheim',
      'Großörner',
      'Großolbersdorf',
      'Groß Oesingen',
      'Großniedesheim',
      'Groß Nemerow',
      'Großnaundorf',
      'Groß Munzel',
      'Großmonra',
      'Großmaischeid',
      'Großlittgen',
      'Groß Lindow',
      'Großlehna',
      'Großlangheim',
      'Groß Laasch',
      'Groß Kummerfeld',
      'Großkugel',
      'Großkrotzenburg',
      'Groß Kreutz',
      'Groß Köris',
      'Großkorbetha',
      'Großkmehlen',
      'Groß Kiesow',
      'Großkayna',
      'Großkarolinenfeld',
      'Großkarlbach',
      'Groß Ippener',
      'Großholbach',
      'Großheubach',
      'Großhennersdorf',
      'Großheirath',
      'Großhartmannsdorf',
      'Großharthau',
      'Großhansdorf',
      'Großhabersdorf',
      'Groß Grönau',
      'Großgoltern',
      'Groß-Gerau',
      'Groß Flottbek',
      'Großerlach',
      'Großenwiehe',
      'Großenstein',
      'Großenseebach',
      'Großensee',
      'Großenlüder',
      'Großenkneten',
      'Großenhain',
      'Großengottern',
      'Großenehrich',
      'Grossenbrode',
      'Großenaspe',
      'Grosselfingen',
      'Großeibstadt',
      'Großdubrau',
      'Groß Düben',
      'Großbreitenbach',
      'Großbottwar',
      'Großbothen',
      'Groß Borstel',
      'Groß Börnecke',
      'Großbodungen',
      'Groß-Bieberau',
      'Großbettlingen',
      'Großbeeren',
      'Großbartloff',
      'Großbardorf',
      'Großbardau',
      'Großauheim',
      'Groß Ammensleben',
      'Großalmerode',
      'Großaitingen',
      'Grönwohld',
      'Gröningen',
      'Gronau',
      'Grömitz',
      'Grolsheim',
      'Groitzsch',
      'Gröden',
      'Gröbzig',
      'Gröbers',
      'Gröbenzell',
      'Grimmen',
      'Grimma',
      'Griesstätt',
      'Griesheim',
      'Bad Griesbach',
      'Gries',
      'Grevesmühlen',
      'Grevenbroich',
      'Greven',
      'Greußenheim',
      'Greußen',
      'Grettstadt',
      'Greppin',
      'Grenzach-Wyhlen',
      'Gremsdorf',
      'Gremersdorf',
      'Gremberghoven',
      'Greiz',
      'Greimerath',
      'Greiling',
      'Greifswald',
      'Greifenstein',
      'Greifenberg',
      'Grefrath',
      'Greffern',
      'Greding',
      'Grebin',
      'Grebenstein',
      'Grebenhain',
      'Grebenau',
      'Grävenwiesbach',
      'Grattersdorf',
      'Grassau',
      'Grasleben',
      'Grasbrunn',
      'Grasberg',
      'Gransee',
      'Granschütz',
      'Gramzow',
      'Gramkow',
      'Grainet',
      'Grainau',
      'Grafrath',
      'Grafing bei München',
      'Grafhorst',
      'Grafenwöhr',
      'Grafenwiesen',
      'Gräfenroda',
      'Grafenrheinfeld',
      'Grafenhausen',
      'Gräfenhainichen',
      'Gräfenhain',
      'Grafengehaig',
      'Gräfendorf',
      'Gräfenberg',
      'Grafenau',
      'Gräfelfing',
      'Grabsleben',
      'Grabowhöfe',
      'Grabow',
      'Grabenstetten',
      'Grabenstätt',
      'Graben',
      'Gottmadingen',
      'Göttingen',
      'Gottfrieding',
      'Gotteszell',
      'Gottenheim',
      'Gotha',
      'Goßwitz',
      'Gößweinstein',
      'Gößnitz',
      'Gössenheim',
      'Goslar',
      'Gosheim',
      'Goseck',
      'Görzke',
      'Görzig',
      'Görwihl',
      'Görsbach',
      'Gornsdorf',
      'Gornau',
      'Görmin',
      'Görlitz',
      'Görisried',
      'Gorbitz',
      'Göppingen',
      'Gönnheim',
      'Gondelsheim',
      'Gommersheim',
      'Gommern',
      'Gomaringen',
      'Gomadingen',
      'Golzow',
      'Golßen',
      'Golmbach',
      'Göllheim',
      'Goldkronach',
      'Goldenstedt',
      'Goldberg',
      'Goldbeck',
      'Goldbach',
      'Gohlis',
      'Göhl',
      'Göggingen',
      'Göda',
      'Gochsheim',
      'Goch',
      'Gnutz',
      'Gnoien',
      'Gnarrenburg',
      'Gmund am Tegernsee',
      'Glückstadt',
      'Glücksburg',
      'Glowe',
      'Glött',
      'Glöthe',
      'Glonn',
      'Glindenberg',
      'Glinde',
      'Gliesmarode',
      'Glienicke',
      'Gleichamberg',
      'Glauchau',
      'Glauburg',
      'Glaubitz',
      'Glatten',
      'Glattbach',
      'Glashütten',
      'Glashütte',
      'Glan-Münchweiler',
      'Glandorf',
      'Gladenbach',
      'Gladbeck',
      'Gittelde',
      'Girod',
      'Ginsheim-Gustavsburg',
      'Gingst',
      'Gingen an der Fils',
      'Gimbsheim',
      'Gilten',
      'Gilserberg',
      'Gillenfeld',
      'Gilching',
      'Gifhorn',
      'Gießen',
      'Giersleben',
      'Giengen an der Brenz',
      'Gielow',
      'Giekau',
      'Gieboldehausen',
      'Giebelstadt',
      'Geyer',
      'Gevelsberg',
      'Geusa',
      'Gettorf',
      'Gesundbrunnen',
      'Gestratz',
      'Gessertshausen',
      'Geslau',
      'Geseke',
      'Gesees',
      'Geschwenda',
      'Gescher',
      'Gerzen',
      'Gerwisch',
      'Gerswalde',
      'Gerstungen',
      'Gersthofen',
      'Gerstetten',
      'Gersten',
      'Gersheim',
      'Gersfeld',
      'Gersdorf',
      'Gerolzhofen',
      'Gerolstein',
      'Gerolsheim',
      'Gerolsbach',
      'Gerolfingen',
      'Geroldshausen',
      'Geroldsgrün',
      'Gernsheim',
      'Gernsbach',
      'Gernrode',
      'Germersheim',
      'Germering',
      'Gerlingen',
      'Geringswalde',
      'Gerhardshofen',
      'Geretsried',
      'Gerdau',
      'Gerbstedt',
      'Gerbrunn',
      'Gerach',
      'Gerabronn',
      'Geraberg',
      'Gera',
      'Georgsmarienhütte',
      'Georgsdorf',
      'Georgenthal',
      'Georgensgmünd',
      'Georgenberg',
      'Genthin',
      'Gensingen',
      'Gengenbach',
      'Genderkingen',
      'Gemünden an der Wohra',
      'Gemünden am Main',
      'Gemünden',
      'Gemmrigheim',
      'Gemmingen',
      'Gelting',
      'Geltendorf',
      'Gelsenkirchen',
      'Gelnhausen',
      'Gelenau',
      'Geldersheim',
      'Geldern',
      'Gelbensande',
      'Geithain',
      'Geitelde',
      'Geismar',
      'Geislingen an der Steige',
      'Geislingen',
      'Geisleden',
      'Geisingen',
      'Geising',
      'Geisenheim',
      'Geisenhausen',
      'Geisenfeld',
      'Geiselwind',
      'Geiselhöring',
      'Geiselbach',
      'Geisa',
      'Geilenkirchen',
      'Geiersthal',
      'Gehren',
      'Gehrden',
      'Gehrde',
      'Gefrees',
      'Geesthacht',
      'Geeste',
      'Gedern',
      'Gechingen',
      'Gebsattel',
      'Gebhardshain',
      'Gebesee',
      'Gauting',
      'Gau-Odernheim',
      'Gaukönigshofen',
      'Gau-Bischofsheim',
      'Gau-Bickelheim',
      'Gau-Algesheim',
      'Gatow',
      'Gatersleben',
      'Garz',
      'Gartz',
      'Gärtringen',
      'Gartow',
      'Garstedt',
      'Gars',
      'Garrel',
      'Garmisch-Partenkirchen',
      'Garlstorf',
      'Garding',
      'Gardelegen',
      'Garching',
      'Garching an der Alz',
      'Garbsen',
      'Gangloffsömmern',
      'Gangkofen',
      'Gangelt',
      'Ganderkesee',
      'Gammertingen',
      'Gammelshausen',
      'Gammelsdorf',
      'Gaißach',
      'Gaimersheim',
      'Gailingen',
      'Gaildorf',
      'Gaienhofen',
      'Gaiberg',
      'Gaggenau',
      'Gägelow',
      'Gadheim',
      'Gadeland',
      'Gadebusch',
      'Gachenbach',
      'Gablingen',
      'Gablenz',
      'Bad Füssing',
      'Fußgönheim',
      'Füssen',
      'Furtwangen',
      'Furth im Wald',
      'Fürthen',
      'Fürth',
      'Furth',
      'Fürstenzell',
      'Fürstenwalde',
      'Fürstenstein',
      'Fürstenhausen',
      'Fürstenfeldbruck',
      'Fürsteneck',
      'Fürstenberg',
      'Fürstenau',
      'Fürfeld',
      'Fünfstetten',
      'Fuldatal',
      'Fulda',
      'Fuhlsbüttel',
      'Fuchsstadt',
      'Fuchsmühl',
      'Frose',
      'Frontenhausen',
      'Fronhausen',
      'Fröndenberg',
      'Frohnau',
      'Frohburg',
      'Fritzlar',
      'Frittlingen',
      'Friolzheim',
      'Friesoythe',
      'Friesenried',
      'Friesenhagen',
      'Friesdorf',
      'Friesack',
      'Friemar',
      'Frielendorf',
      'Friedrichsthal',
      'Friedrichstadt',
      'Friedrichskoog',
      'Friedrichshain',
      'Friedrichshagen',
      'Friedrichshafen',
      'Friedrichsfelde',
      'Friedrichsdorf',
      'Friedrichsbrunn',
      'Friedrichroda',
      'Friedland',
      'Gemeinde Friedland',
      'Friedewald',
      'Friedersdorf',
      'Friedenweiler',
      'Friedenfels',
      'Friedenau',
      'Friedelsheim',
      'Friedeburg',
      'Friedberg',
      'Fridolfing',
      'Fridingen an der Donau',
      'Frickingen',
      'Frickenhausen',
      'Freyung',
      'Freystadt',
      'Freyburg',
      'Freudental',
      'Freudenstadt',
      'Freudenburg',
      'Freudenberg',
      'Freren',
      'Frensdorf',
      'Fremdingen',
      'Freital',
      'Freising',
      'Freisen',
      'Freisbach',
      'Freinsheim',
      'Frei-Laubersheim',
      'Freilassing',
      'Freihung',
      'Freienwill',
      'Freiensteinau',
      'Freienbessingen',
      'Freiburg',
      'Freiburg/Elbe',
      'Freiberg am Neckar',
      'Freiberg',
      'Freden',
      'Bad Fredeburg',
      'Freckenfeld',
      'Frechen',
      'Fraureuth',
      'Fraunberg',
      'Frauenwald',
      'Frauenstein',
      'Frauenprießnitz',
      'Frauenneuharting',
      'Frauenau',
      'Frasdorf',
      'Franzburg',
      'Frankleben',
      'Fränkisch-Crumbach',
      'Frankfurt am Main',
      'Frankfurt (Oder)',
      'Frankenwinheim',
      'Frankenthal',
      'Frankenstein',
      'Frankenheim',
      'Frankenberg',
      'Frankenau',
      'Frammersbach',
      'Framersheim',
      'Forstinning',
      'Forst',
      'Föritz',
      'Förderstedt',
      'Forchtenberg',
      'Forchheim',
      'Forbach',
      'Föhren',
      'Fockbek',
      'Flossenbürg',
      'Floß',
      'Flörsheim',
      'Flonheim',
      'Flomborn',
      'Flöha',
      'Flintsbach',
      'Flintbek',
      'Flieden',
      'Flessau',
      'Flensburg',
      'Flein',
      'Fleckeby',
      'Flechtingen',
      'Flammersfeld',
      'Fladungen',
      'Flacht',
      'Flachslanden',
      'Fischerbach',
      'Fischeln',
      'Fischbachau',
      'Fischbach',
      'Fischach',
      'Fintel',
      'Finsterwalde',
      'Finsterbergen',
      'Finsing',
      'Finnentrop',
      'Filsum',
      'Fichtenwalde',
      'Fichtenberg',
      'Fichtelberg',
      'Stuttgart Feuerbach',
      'Feuchtwangen',
      'Feucht',
      'Ferdinandshof',
      'Felsberg',
      'Felm',
      'Fellheim',
      'Fellbach',
      'Fell',
      'Feldkirchen-Westerham',
      'Feldkirchen',
      'Felde',
      'Feldberg',
      'Feldafing',
      'Feilitzsch',
      'Feilbingert',
      'Fehrbellin',
      'Fedderwarden',
      'Faulbach',
      'Faßberg',
      'Farnstädt',
      'Farchant',
      'Fambach',
      'Bad Fallingbostel',
      'Falkenstein',
      'Falkensee',
      'Falkenhain',
      'Falkenfels',
      'Falkenberg',
      'Falkenau',
      'Faid',
      'Fahrenzhausen',
      'Fahrenkrug',
      'Fahrenbach',
      'Fahrdorf',
      'Fachbach',
      'Eystrup',
      'Eyendorf',
      'Eydelstedt',
      'Eving',
      'Evessen',
      'Everswinkel',
      'Eutingen an der Enz',
      'Eutin',
      'Eußenheim',
      'Euskirchen',
      'Eurasburg',
      'Euerdorf',
      'Euerbach',
      'Etzenricht',
      'Etzelwang',
      'Etzbach',
      'Ettringen',
      'Ettlingen',
      'Ettenheim',
      'Estorf',
      'Esthal',
      'Esterwegen',
      'Estenfeld',
      'Esslingen',
      'Essingen',
      'Essing',
      'Essenheim',
      'Essenbach',
      'Essen',
      'Esselbach',
      'Essel',
      'Espenhain',
      'Espenau',
      'Espelkamp',
      'Eslohe',
      'Eslarn',
      'Esens',
      'Eschweiler',
      'Eschwege',
      'Eschlkam',
      'Eschershausen',
      'Eschenlohe',
      'Eschenbach',
      'Eschelbronn',
      'Eschede',
      'Escheburg',
      'Eschborn',
      'Eschau',
      'Eschach',
      'Erzhausen',
      'Erxleben',
      'Erwitte',
      'Ertingen',
      'Ersingen',
      'Erpolzheim',
      'Erpel',
      'Erolzheim',
      'Ernstroda',
      'Ernsgaden',
      'Erndtebrück',
      'Ermlitz',
      'Erligheim',
      'Erlensee',
      'Erlenmoos',
      'Erlenbach am Main',
      'Erlenbach',
      'Erlbach',
      'Erlau',
      'Erlangen',
      'Erlabrunn',
      'Erkrath',
      'Erkner',
      'Erkheim',
      'Erkerode',
      'Erkenbrechtsweiler',
      'Erkelenz',
      'Eriskirch',
      'Ering',
      'Ergoldsbach',
      'Ergolding',
      'Ergersheim',
      'Erfweiler',
      'Erfurt',
      'Erftstadt',
      'Erfde',
      'Eresing',
      'Erdweg',
      'Erdmannhausen',
      'Erding',
      'Erbes-Büdesheim',
      'Erbendorf',
      'Erbach',
      'Eppstein',
      'Eppishausen',
      'Eppingen',
      'Eppertshausen',
      'Eppendorf',
      'Eppenbrunn',
      'Eppelsheim',
      'Eppelheim',
      'Eppelborn',
      'Epfendorf',
      'Epfenbach',
      'Enzklösterle',
      'Ensdorf',
      'Ennigerloh',
      'Ennetach',
      'Ennepetal',
      'Enkirch',
      'Enkenbach-Alsenborn',
      'Eningen unter Achalm',
      'Sankt Englmar',
      'Enger',
      'Engen',
      'Engelthal',
      'Engelskirchen',
      'Engelsbrand',
      'Engelsberg',
      'Engeln',
      'Bad Endorf',
      'Endingen',
      'Endenich',
      'Bad Endbach',
      'Emtmannsberg',
      'Emtinghausen',
      'Emstek',
      'Emskirchen',
      'Emsdetten',
      'Emsbüren',
      'Empfingen',
      'Emmering',
      'Emmerich',
      'Emmendingen',
      'Emmelshausen',
      'Emlichheim',
      'Emkendorf',
      'Emersacker',
      'Emden',
      'Embsen',
      'Elze',
      'Elzach',
      'Elz',
      'Elxleben',
      'Eltville',
      'Eltmann',
      'Elterlein',
      'Elstra',
      'Elsterwerda',
      'Elstertrebnitz',
      'Elsterberg',
      'Elster',
      'Elsnig',
      'Elsfleth',
      'Elsenfeld',
      'Elsendorf',
      'Elsdorf-Westermühlen',
      'Elsdorf',
      'Elmstein',
      'Elmshorn',
      'Elmenhorst',
      'Ellzee',
      'Ellwangen',
      'Ellrich',
      'Ellingen',
      'Ellhofen',
      'Ellgau',
      'Ellerstadt',
      'Ellerhoop',
      'Ellerbek',
      'Ellerau',
      'Ellenberg',
      'Ellefeld',
      'Elkenroth',
      'Elgersburg',
      'Elfershausen',
      'Eldingen',
      'Eldena',
      'Elbingerode',
      'Eitting',
      'Eitorf',
      'Eiterfeld',
      'Eitelborn',
      'Eißendorf',
      'Eislingen',
      'Eisingen',
      'Eisfeld',
      'Eisenhüttenstadt',
      'Eisenburg',
      'Eisenberg',
      'Eisenbach',
      'Eisenach',
      'Eisdorf am Harz',
      'Einhausen',
      'Einfeld',
      'Markt Einersheim',
      'Einbeck',
      'Eimke',
      'Eimen',
      'Eimeldingen',
      'Eime',
      'Eilsleben',
      'Eilenburg',
      'Eilbek',
      'Eil',
      'Eigeltingen',
      'Eicklingen',
      'Eickendorf',
      'Eichwalde',
      'Eichstetten',
      'Eichstätt',
      'Eichigt',
      'Eichenzell',
      'Eichenried',
      'Eichendorf',
      'Eichenbühl',
      'Eichenbarleben',
      'Eichenau',
      'Eibenstock',
      'Eibelstadt',
      'Eibau',
      'Ehringshausen',
      'Ehrenfriedersdorf',
      'Ehrenburg',
      'Ehningen',
      'Ehlscheid',
      'Ehingen',
      'Ehekirchen',
      'Egmating',
      'Egloffstein',
      'Egling',
      'Eging',
      'Eggstätt',
      'Eggolsheim',
      'Egglkofen',
      'Egglham',
      'Eggesin',
      'Eggersdorf',
      'Eggenthal',
      'Eggenstein-Leopoldshafen',
      'Eggenfelden',
      'Eggebek',
      'Egg',
      'Egestorf',
      'Egenhofen',
      'Egenhausen',
      'Egelsbach',
      'Egeln',
      'Efringen-Kirchen',
      'Effeltrich',
      'Effelder',
      'Edingen-Neckarhausen',
      'Edewecht',
      'Edesheim',
      'Edersleben',
      'Ederheim',
      'Edenkoben',
      'Edemissen',
      'Edelsfeld',
      'Edderitz',
      'Eddelak',
      'Eckersdorf',
      'Eckernförde',
      'Eckartsberga',
      'Echzell',
      'Eching',
      'Echem',
      'Ebstorf',
      'Ebringen',
      'Ebnath',
      'Ebhausen',
      'Ebertsheim',
      'Eberswalde',
      'Eberstadt',
      'Ebersdorf',
      'Ebersberg',
      'Ebersbach an der Fils',
      'Ebersbach',
      'Ebernhahn',
      'Ebermannstadt',
      'Ebermannsdorf',
      'Eberhardzell',
      'Ebergötzen',
      'Eberdingen',
      'Eberbach',
      'Ebenweiler',
      'Ebensfeld',
      'Ebendorf',
      'Ebelsbach',
      'Ebeleben',
      'Duvenstedt',
      'Dußlingen',
      'Düsseldorf',
      'Dürrwangen',
      'Dürrröhrsdorf',
      'Dürrlauingen',
      'Dürrhennersdorf',
      'Dürnau',
      'Durmersheim',
      'Dürmentingen',
      'Durlangen',
      'Düren',
      'Dürbheim',
      'Durbach',
      'Durach',
      'Dunum',
      'Dünsen',
      'Dunningen',
      'Düngenheim',
      'Dummerstorf',
      'Dümmer',
      'Dülmen',
      'Duisburg',
      'Duingen',
      'Duggendorf',
      'Duderstadt',
      'Dudenhofen',
      'Dudeldorf',
      'Ducherow',
      'Drübeck',
      'Droyßig',
      'Drolshagen',
      'Drochtersen',
      'Driedorf',
      'Dresden',
      'Drentwede',
      'Drensteinfurt',
      'Drelsdorf',
      'Dreisen',
      'Dreis',
      'Dreierwalde',
      'Dreieich',
      'Dreetz',
      'Drebkau',
      'Drebach',
      'Dranske',
      'Dransfeld',
      'Drangstedt',
      'Drakenburg',
      'Drage',
      'Drachselsried',
      'Dotzheim',
      'Dotternhausen',
      'Dötlingen',
      'Dossenheim',
      'Dörzbach',
      'Dörverden',
      'Dorum',
      'Dortmund',
      'Dorsten',
      'Dörrenbach',
      'Dörpen',
      'Dornum',
      'Dornstetten',
      'Dornstadt',
      'Dornhan',
      'Dorndorf',
      'Dormitz',
      'Dormettingen',
      'Dormagen',
      'Dorfprozelten',
      'Dorfhain',
      'Dorfen',
      'Dorfchemnitz',
      'Dörentrup',
      'Donzdorf',
      'Donnersdorf',
      'Donauwörth',
      'Donaustauf',
      'Donaueschingen',
      'Domsühl',
      'Dommitzsch',
      'Dommershausen',
      'Dömitz',
      'Domersleben',
      'Dombühl',
      'Döllstädt',
      'Döllnitz',
      'Dollerup',
      'Dollern',
      'Dollbergen',
      'Dölbau',
      'Dohna',
      'Dohma',
      'Döhlau',
      'Dogern',
      'Dobien',
      'Dobersdorf',
      'Doberschütz',
      'Doberschau',
      'Döbernitz',
      'Doberlug-Kirchhain',
      'Döbeln',
      'Dobel',
      'Dobbertin',
      'Ditzingen',
      'Dittenheim',
      'Dittelbrunn',
      'Ditfurt',
      'Dissen',
      'Dischingen',
      'Dirmstein',
      'Dirlewang',
      'Dippoldiswalde',
      'Dipperz',
      'Dippach',
      'Dinslaken',
      'Dinklage',
      'Dinkelscherben',
      'Dinkelsbühl',
      'Dingolshausen',
      'Dingolfing',
      'Dingelstädt',
      'Dingelsdorf',
      'Dillingen an der Donau',
      'Dillingen',
      'Dillenburg',
      'Diez',
      'Dietzenbach',
      'Dietramszell',
      'Dietmannsried',
      'Dietingen',
      'Dietfurt',
      'Dieterskirchen',
      'Dietersheim',
      'Dietersburg',
      'Dietenhofen',
      'Dietenheim',
      'Dießen am Ammersee',
      'Diespeck',
      'Dieskau',
      'Diesdorf',
      'Dierdorf',
      'Diepholz',
      'Dienheim',
      'Dielheim',
      'Diekholzen',
      'Diedorf',
      'Dieburg',
      'Dieblich',
      'Diebach',
      'Didderse',
      'Dibbesdorf',
      'Dexheim',
      'Deutzen',
      'Deutz',
      'Deutsch Evern',
      'Deuna',
      'Deuerling',
      'Deuben',
      'Dettum',
      'Dettingen unter Teck',
      'Dettingen an der Erms',
      'Dettingen',
      'Dettighofen',
      'Dettenhausen',
      'Dettelbach',
      'Detmold',
      'Detmerode',
      'Detern',
      'Destedt',
      'Dessau',
      'Dersum',
      'Dersekow',
      'Derschen',
      'Dernbach',
      'Dernau',
      'Dermbach',
      'Derenburg',
      'Denzlingen',
      'Dentlein am Forst',
      'Dennheritz',
      'Denklingen',
      'Denkingen',
      'Denkendorf',
      'Demmin',
      'Demitz-Thumitz',
      'Demen',
      'Delmenhorst',
      'Delligsen',
      'Dellfeld',
      'Delitzsch',
      'Delingsdorf',
      'Delbrück',
      'Deizisau',
      'Deißlingen',
      'Deisenhausen',
      'Deinste',
      'Deiningen',
      'Deining',
      'Deilingen',
      'Deidesheim',
      'Dehrn',
      'Deggingen',
      'Deggendorf',
      'Deensen',
      'Dedelstorf',
      'Deckenpfronn',
      'Dausenau',
      'Daun',
      'Dauchingen',
      'Dattenberg',
      'Datteln',
      'Dassow',
      'Dassendorf',
      'Dassel',
      'Dasing',
      'Darmstadt',
      'Darlingerode',
      'Dargun',
      'Dannewerk',
      'Dannenberg',
      'Danndorf',
      'Dankmarshausen',
      'Dänischenhagen',
      'Damp',
      'Damme',
      'Dalheim',
      'Daisendorf',
      'Dahn',
      'Dahme',
      'Dahlenwarsleben',
      'Dahlenburg',
      'Dahlen',
      'Dahlem',
      'Dägeling',
      'Dachwig',
      'Dachsenhausen',
      'Dachau',
      'Dabel',
      'Daaden',
      'Cuxhaven',
      'Cunewalde',
      'Crottendorf',
      'Crostwitz',
      'Crostau',
      'Crivitz',
      'Crinitz',
      'Crimmitschau',
      'Creuzburg',
      'Creußen',
      'Cremlingen',
      'Creglingen',
      'Crawinkel',
      'Crailsheim',
      'Cottbus',
      'Coswig',
      'Cornberg',
      'Coppenbrügge',
      'Contwig',
      'Colmberg',
      'Colditz',
      'Colbitz',
      'Cölbe',
      'Coesfeld',
      'Cochstedt',
      'Cochem',
      'Coburg',
      'Cloppenburg',
      'Clingen',
      'Clenze',
      'Cleebronn',
      'Clausthal-Zellerfeld',
      'Claußnitz',
      'Clausen',
      'Chorin',
      'Chieming',
      'Chemnitz',
      'Charlottenburg',
      'Chamerau',
      'Cham',
      'Celle',
      'Cavertitz',
      'Castrop-Rauxel',
      'Casekow',
      'Carpin',
      'Carlsberg',
      'Carlow',
      'Cappeln',
      'Camburg',
      'Bad Camberg',
      'Calw',
      'Calvörde',
      'Callenberg',
      'Calden',
      'Calberlah',
      'Calbe',
      'Kalawa',
      'Cadolzburg',
      'Cadenberge',
      'Buxtehude',
      'Buxheim',
      'Bützow',
      'Butzbach',
      'Büttstedt',
      'Buttstädt',
      'Buttlar',
      'Bütthard',
      'Buttenwiesen',
      'Buttenheim',
      'Buttelstedt',
      'Büttelborn',
      'Büsum',
      'Büsingen',
      'Busenberg',
      'Busdorf',
      'Burtenbach',
      'Bürstadt',
      'Burscheid',
      'Burow',
      'Burladingen',
      'Burkhardtsdorf',
      'Burkau',
      'Burkardroth',
      'Burk',
      'Burgwindheim',
      'Burgwerben',
      'Burgthann',
      'Burg Stargard',
      'Burgstädt',
      'Bürgstadt',
      'Burgsolms',
      'Burgsinn',
      'Burgschwalbach',
      'Burgsalach',
      'Burgrieden',
      'Burgpreppach',
      'Burgoberbach',
      'Burglengenfeld',
      'Burglauer',
      'Burgkunstadt',
      'Burgkirchen an der Alz',
      'Burgheim',
      'Burghausen',
      'Burghaun',
      'Burghaslach',
      'Burggen',
      'Bürgel',
      'Burgebrach',
      'Burgdorf',
      'Burgbrohl',
      'Burgbernheim',
      'Burgberg',
      'Burgau',
      'Burg auf Fehmarn',
      'Burg',
      'Burg bei Magdeburg',
      'Büren',
      'Burbach',
      'Bundenthal',
      'Bundenbach',
      'Bunde',
      'Bünde',
      'Bullay',
      'Bühren',
      'Bühlerzell',
      'Bühlertann',
      'Bühlertal',
      'Bühl',
      'Buggingen',
      'Bufleben',
      'Büdingen',
      'Budenheim',
      'Büdelsdorf',
      'Buckow',
      'Buckenhof',
      'Bücken',
      'Bückeburg',
      'Buchloe',
      'Büchlberg',
      'Buchholz in der Nordheide',
      'Französisch Buchholz',
      'Buchholz',
      'Buchheim',
      'Büchenbeuren',
      'Buchenberg',
      'Büchenbach',
      'Buchenbach',
      'Büchen',
      'Buchen in Odenwald',
      'Büchel',
      'Buchdorf',
      'Buchbrunn',
      'Buchbach',
      'Buch am Buchrain',
      'Buch am Ahorn',
      'Bucha',
      'Buch',
      'Bubsheim',
      'Bubesheim',
      'Bubenreuth',
      'Brüssow',
      'Brüsewitz',
      'Brunsbüttel',
      'Brunnthal',
      'Brunnen',
      'Brunn',
      'Brumby',
      'Brühl',
      'Brüggen',
      'Brügge',
      'Brüel',
      'Bruckmühl',
      'Bruck',
      'Brücken',
      'Bruckberg',
      'Brück',
      'Bruchweiler-Bärenbach',
      'Bruchsal',
      'Bruchköbel',
      'Bruchhausen-Vilsen',
      'Brotterode',
      'Bromskirchen',
      'Brome',
      'Brokstedt',
      'Brokdorf',
      'Broitzem',
      'Broderstorf',
      'Brockum',
      'Brockel',
      'Britz',
      'Britten',
      'Brilon',
      'Brietlingen',
      'Brieskow-Finkenheerd',
      'Briesen',
      'Brieselang',
      'Briedel',
      'Brey',
      'Breuna',
      'Bretzfeld',
      'Bretzenheim',
      'Bretten',
      'Brensbach',
      'Brennberg',
      'Bremervörde',
      'Bremerhaven',
      'Bremen',
      'Breklum',
      'Brekendorf',
      'Breitungen',
      'Breitscheidt',
      'Breitscheid',
      'Breitnau',
      'Breitenworbis',
      'Breitenthal',
      'Breitengüßbach',
      'Breitenfelde',
      'Breitenfeld',
      'Breitenburg',
      'Breitenbrunn',
      'Breitenborn',
      'Breitenberg',
      'Breitenbach',
      'Breitbrunn',
      'Breisach am Rhein',
      'Breiholz',
      'Breidenbach',
      'Brehna',
      'Brehme',
      'Breese',
      'Bredstedt',
      'Bredenbek',
      'Breddorf',
      'Breddin',
      'Breckerfeld',
      'Braunschweig',
      'Braunsbedra',
      'Braunsbach',
      'Bräunlingen',
      'Braunlage',
      'Braunfels',
      'Brauneberg',
      'Braubach',
      'Braschwitz',
      'Brannenburg',
      'Brandshagen',
      'Brandis',
      'Brand-Erbisdorf',
      'Brandenburg an der Havel',
      'Brande-Hörnerkirchen',
      'Brand',
      'Bramstedt',
      'Bramsche',
      'Brakel',
      'Brake (Unterweser)',
      'Brahmenau',
      'Brackenheim',
      'Brackel',
      'Brachbach',
      'Boxberg',
      'Bovenden',
      'Bovenau',
      'Bous',
      'Bötzingen',
      'Bottrop',
      'Böttingen',
      'Bothel',
      'Bötersen',
      'Bösingen',
      'Bösenbrunn',
      'Bösel',
      'Bösdorf',
      'Bosau',
      'Börtlingen',
      'Borstendorf',
      'Borstel-Hohenraden',
      'Borstel',
      'Börßum',
      'Borsdorf',
      'Borrentin',
      'Börnsen',
      'Börnichen',
      'Bornich',
      'Bornhöved',
      'Bornheim',
      'Borne',
      'Borna',
      'Born',
      'Borkwalde',
      'Borkum',
      'Borkheide',
      'Borken',
      'Borgstedt',
      'Borgholzhausen',
      'Borgfelde',
      'Börger',
      'Borgentreich',
      'Bordesholm',
      'Boppard',
      'Bopfingen',
      'Boostedt',
      'Boos',
      'Bonstetten',
      'Bönningstedt',
      'Bönnigheim',
      'Bonndorf',
      'Bonn',
      'Bönen',
      'Bonefeld',
      'Bönebüttel',
      'Bondorf',
      'Bomlitz',
      'Bolsterlang',
      'Bollschweil',
      'Bollingstedt',
      'Bollendorf',
      'Boll',
      'Bolheim',
      'Bolanden',
      'Bokholt-Hanredder',
      'Bokel',
      'Boizenburg',
      'Bohnsdorf',
      'Bohmte',
      'Böhmenkirch',
      'Böhlen',
      'Bogenhausen',
      'Bogen',
      'Boffzen',
      'Bodolz',
      'Bodnegg',
      'Bodenwöhr',
      'Bodenwerder',
      'Bodenmais',
      'Bodenkirchen',
      'Bodenheim',
      'Bodenfelde',
      'Bodelshausen',
      'Bockum',
      'Bockhorst',
      'Bockhorn',
      'Bockenheim',
      'Bockenem',
      'Bockenau',
      'Bockelwitz',
      'Bockau',
      'Bochum',
      'Bocholt',
      'Böbrach',
      'Böblingen',
      'Bobitz',
      'Bobingen',
      'Böbing',
      'Bobenheim-Roxheim',
      'Bobbau',
      'Blumberg',
      'Blowatz',
      'Blomberg',
      'Blindheim',
      'Blieskastel',
      'Bliesdorf',
      'Bliedersdorf',
      'Blender',
      'Blekendorf',
      'Bleicherode',
      'Bleialf',
      'Bleckede',
      'Blaufelden',
      'Blaubeuren',
      'Blasewitz',
      'Blankensee',
      'Blankenrath',
      'Blankenheim',
      'Blankenhain',
      'Blankenfelde',
      'Blankenese',
      'Blankenburg',
      'Blaichach',
      'Blaibach',
      'Bitz',
      'Bitterfeld-Wolfen',
      'Bitburg',
      'Bissingen an der Teck',
      'Bissingen',
      'Bissendorf',
      'Bispingen',
      'Bismark',
      'Bisingen',
      'Bischweier',
      'Bischofswiesen',
      'Bischofswerda',
      'Bischofsmais',
      'Bischofsheim in der Rhön',
      'Bischofsheim',
      'Bischofsgrün',
      'Bischofferode',
      'Bischoffen',
      'Bischbrunn',
      'Bischberg',
      'Birstein',
      'Birresborn',
      'Bad Birnbach',
      'Birlenbach',
      'Birkenwerder',
      'Birkenheide',
      'Birkenfeld',
      'Birkenau',
      'Birgte',
      'Birenbach',
      'Bippen',
      'Binzen',
      'Binswangen',
      'Binsfeld',
      'Binnen',
      'Bingen am Rhein',
      'Bingen',
      'Bindlach',
      'Binau',
      'Bilshausen',
      'Billwerder',
      'Billstedt',
      'Billigheim',
      'Billerbeck',
      'Billbrook',
      'Bietigheim-Bissingen',
      'Bietigheim',
      'Biessenhofen',
      'Biesenthal',
      'Biesdorf',
      'Bierstadt',
      'Biere',
      'Bienrode',
      'Bienenbüttel',
      'Biendorf',
      'Bielefeld',
      'Biederitz',
      'Biedenkopf',
      'Biebrich',
      'Biebesheim',
      'Biebelried',
      'Bidingen',
      'Bickenbach',
      'Bichl',
      'Biburg',
      'Biblis',
      'Biberbach',
      'Biberach an der Riß',
      'Biberach',
      'Bexbach',
      'Beverungen',
      'Beverstedt',
      'Bevern',
      'Bad Bevensen',
      'Bevenrode',
      'Beutelsbach',
      'Beuren',
      'Beuna',
      'Beuern',
      'Betzigau',
      'Betzendorf',
      'Betzdorf',
      'Bettingen',
      'Betheln',
      'Bestwig',
      'Bestensee',
      'Besigheim',
      'Berumbur',
      'Berthelsdorf',
      'Berstadt',
      'Bersenbrück',
      'Bernstadt',
      'Bernsdorf',
      'Bernsbach',
      'Bernried',
      'Bernkastel-Kues',
      'Bernitt',
      'Bernhardswald',
      'Berngau',
      'Bernburg',
      'Bernbeuren',
      'Bernau am Chiemsee',
      'Bernau bei Berlin',
      'Bernau',
      'Bermatingen',
      'Berlstedt',
      'Berlingerode',
      'Berlin',
      'Berkheim',
      'Berkenthin',
      'Berkenbrück',
      'Bergwitz',
      'Bergtheim',
      'Bergstedt',
      'Bergrheinfeld',
      'Bergneustadt',
      'Berglern',
      'Bergkirchen',
      'Bergkamen',
      'Bergisch Gladbach',
      'Berg im Gau',
      'Berghülen',
      'Bergheim',
      'Berghaupten',
      'Bergen auf Rügen',
      'Bergen an der Dumme',
      'Bergen',
      'Berge',
      'Bergatreute',
      'Berga',
      'Berg',
      'Berchtesgaden',
      'Berching',
      'Beratzhausen',
      'Bentzin',
      'Bentwisch',
      'Bensheim',
      'Benshausen',
      'Bennungen',
      'Bennstedt',
      'Benningen am Neckar',
      'Benningen',
      'Bennewitz',
      'Benneckenstein',
      'Benndorf',
      'Benediktbeuern',
      'Bendorf',
      'Bendestorf',
      'Bempflingen',
      'Bad Belzig',
      'Beltheim',
      'Belm',
      'Bellheim',
      'Bellenberg',
      'Belleben',
      'Bell',
      'Belgershain',
      'Belgern',
      'Beindersheim',
      'Beimerstetten',
      'Beilstein',
      'Beilrode',
      'Beiersdorf',
      'Beierfeld',
      'Bei der Höhne',
      'Behringen',
      'Beetzendorf',
      'Beesten',
      'Beeskow',
      'Beesenstedt',
      'Beesenlaublingen',
      'Beerfelden',
      'Beelitz',
      'Beelen',
      'Beedenbostel',
      'Bedburg',
      'Beckum',
      'Beckingen',
      'Beckedorf',
      'Beckdorf',
      'Bechtsrieth',
      'Bechtolsheim',
      'Bechtheim',
      'Bechhofen',
      'Bebra',
      'Bayrischzell',
      'Bayreuth',
      'Bayerisch Gmain',
      'Bayerisch Eisenstein',
      'Bayerbach',
      'Bayenthal',
      'Bawinkel',
      'Bautzen',
      'Bausendorf',
      'Baunatal',
      'Baunach',
      'Baumschulenweg',
      'Baumholder',
      'Baudenbach',
      'Battenberg',
      'Bastorf',
      'Bastheim',
      'Bassum',
      'Bassenheim',
      'Basdahl',
      'Barwedel',
      'Barver',
      'Baruth',
      'Barum',
      'Bartholomä',
      'Barth',
      'Barßel',
      'Barsinghausen',
      'Barsbüttel',
      'Barntrup',
      'Barnstorf',
      'Barnstädt',
      'Bärnau',
      'Barmstedt',
      'Barleben',
      'Barkelsby',
      'Bark',
      'Bargteheide',
      'Bargstedt',
      'Bargfeld-Stegen',
      'Bärenstein',
      'Barendorf',
      'Barenburg',
      'Bardowick',
      'Barchfeld',
      'Barbing',
      'Banzkow',
      'Banteln',
      'Bannewitz',
      'Bann',
      'Bammental',
      'Bamenohl',
      'Bamberg',
      'Balzhausen',
      'Balzfeld',
      'Balve',
      'Baltmannsweiler',
      'Ballenstedt',
      'Balje',
      'Balingen',
      'Balge',
      'Bakum',
      'Baisweil',
      'Baindt',
      'Baiersdorf',
      'Baiersbronn',
      'Baierbrunn',
      'Baienfurt',
      'Bahrenborstel',
      'Bahrdorf',
      'Bahlingen',
      'Baesweiler',
      'Bad Zwischenahn',
      'Bad Wurzach',
      'Bad Wörishofen',
      'Bad Windsheim',
      'Bad Wimpfen',
      'Bad Wilsnack',
      'Bad Wildungen',
      'Bad Wiessee',
      'Bad Waldsee',
      'Bad Vilbel',
      'Bad Überkingen',
      'Bad Tölz',
      'Bad Tennstedt',
      'Bad Sülze',
      'Bad Sulza',
      'Bad Suderode',
      'Bad Steben',
      'Bad Sooden-Allendor',
      'Bad Soden-Salmünster',
      'Bad Soden am Taunus',
      'Bad Segeberg',
      'Bad Schwartau',
      'Bad Schwalbach',
      'Bad Schmiedeberg',
      'Bad Schandau',
      'Bad Sassendorf',
      'Bad Salzungen',
      'Bad Salzuflen',
      'Bad Salzschlirf',
      'Bad Salzdetfurth',
      'Bad Säckingen',
      'Bad Sachsa',
      'Bad Saarow',
      'Bad Rothenfelde',
      'Bad Reichenhall',
      'Bad Rappenau',
      'Bad Pyrmont',
      'Bad Orb',
      'Bad Oldesloe',
      'Bad Oeynhausen',
      'Bad Neustadt an der Saale',
      'Bad Neuenahr-Ahrweiler',
      'Bad Nenndorf',
      'Bad Nauheim',
      'Bad Muskau',
      'Bad Münstereifel',
      'Bad Münster am Stein-Ebernburg',
      'Bad Münder am Deister',
      'Bad Mergentheim',
      'Bad Meinberg',
      'Bad Marienberg',
      'Bad Lippspringe',
      'Bad Liebenzell',
      'Bad Liebenwerda',
      'Bad Liebenstein',
      'Bad Lauterberg im Harz',
      'Bad Lausick',
      'Bad Lauchstädt',
      'Bad Langensalza',
      'Bad Kreuznach',
      'Bad Köstritz',
      'Bad Kösen',
      'Bad König',
      'Bad Kohlgrub',
      'Bad Klosterlausnitz',
      'Bad Kleinen',
      'Bad Kissingen',
      'Bad Iburg',
      'Bad Hönningen',
      'Bad Honnef',
      'Bad Homburg vor der Höhe',
      'Bad Hersfeld',
      'Bad Herrenalb',
      'Bad Heilbrunn',
      'Bad Harzburg',
      'Bad Grund',
      'Bad Gandersheim',
      'Bad Freienwalde',
      'Bad Frankenhausen',
      'Bad Feilnbach',
      'Bad Essen',
      'Badenweiler',
      'Badenhausen',
      'Baden-Baden',
      'Bad Ems',
      'Badem',
      'Bad Elster',
      'Bad Eilsen',
      'Bad Dürrheim',
      'Bad Dürrenberg',
      'Bad Dürkheim',
      'Bad Düben',
      'Bad Driburg',
      'Bad Doberan',
      'Bad Ditzenbach',
      'Baddeckenstedt',
      'Bad Buchau',
      'Bad Brückenau',
      'Bad Breisig',
      'Bad Bramstedt',
      'Bad Brambach',
      'Bad Bocklet',
      'Bad Blankenburg',
      'Bad Bibra',
      'Bad Berneck im Fichtelgebirge',
      'Bad Berleburg',
      'Bad Berka',
      'Bad Bergzabern',
      'Badbergen',
      'Bad Bentheim',
      'Bad Bellingen',
      'Bad Aibling',
      'Bad Abbach',
      'Backnang',
      'Bächingen an der Brenz',
      'Bachhagel',
      'Bacharach',
      'Bach',
      'Babensham',
      'Babenhausen',
      'Baalberge',
      'Aystetten',
      'Ayl',
      'Aying',
      'Axstedt',
      'Außernzell',
      'Ausleben',
      'Aurich',
      'Aura im Sinngrund',
      'Auma',
      'Aulendorf',
      'Auleben',
      'Au in der Hallertau',
      'Auhausen',
      'Auhagen',
      'Augustusburg',
      'Augustdorf',
      'Augsburg',
      'Auggen',
      'Aufseß',
      'Aufhausen',
      'Auerbach',
      'Aue',
      'Aub',
      'Au am Rhein',
      'Au',
      'Atzendorf',
      'Atting',
      'Attenweiler',
      'Attenkirchen',
      'Attenhofen',
      'Attendorn',
      'Aßling',
      'Aßlar',
      'Asselfingen',
      'Assamstadt',
      'Asperg',
      'Asendorf',
      'Aschheim',
      'Aschersleben',
      'Ascheberg',
      'Aschau am Inn',
      'Aschaffenburg',
      'Ascha',
      'Asbach',
      'Arzfeld',
      'Arzberg',
      'Arzbach',
      'Artlenburg',
      'Artern',
      'Arrach',
      'Arpke',
      'Bad Arolsen',
      'Arnstorf',
      'Arnstein',
      'Arnstadt',
      'Arnschwang',
      'Arnsberg',
      'Arneburg',
      'Arnbruck',
      'Armsheim',
      'Argenthal',
      'Aresing',
      'Arenshausen',
      'Luftkurort Arendsee',
      'Arberg',
      'Appenheim',
      'Appen',
      'Appel',
      'Apollensdorf',
      'Apolda',
      'Aplerbeck',
      'Apfelstädt',
      'Apensen',
      'Apen',
      'Apelern',
      'Anzing',
      'Antdorf',
      'Ansbach',
      'Anrode',
      'Anröchte',
      'Annweiler am Trifels',
      'Annaburg',
      'Annaberg-Buchholz',
      'Ankum',
      'Anklam',
      'Anhausen',
      'Angersdorf',
      'Angern',
      'Angermünde',
      'Andernach',
      'Andechs',
      'Amtzell',
      'Amstetten',
      'Ampfing',
      'Amöneburg',
      'Ammerndorf',
      'Amerang',
      'Amendingen',
      'Amelinghausen',
      'Amberg',
      'Alzey',
      'Alzenau in Unterfranken',
      'Alveslohe',
      'Alt Wallmoden',
      'Altusried',
      'Alt Tucheband',
      'Hamburg-Altstadt',
      'Altshausen',
      'Altrip',
      'Altrich',
      'Altötting',
      'Altomünster',
      'Altmittweida',
      'Alt Meteln',
      'Altlußheim',
      'Altleiningen',
      'Altlandsberg',
      'Altkirchen',
      'Althütte',
      'Althengstett',
      'Althegnenberg',
      'Altglienicke',
      'Altfraunhofen',
      'Altentreptow',
      'Altenthann',
      'Altensteig',
      'Altenstadt',
      'Altenriet',
      'Altenpleen',
      'Altenmünster',
      'Altenmedingen',
      'Altenmarkt',
      'Altenkunstadt',
      'Altenkrempe',
      'Altenkirchen',
      'Altenholz',
      'Altengottern',
      'Altenglan',
      'Altenfeld',
      'Altendorf',
      'Altendiez',
      'Alten Buseck',
      'Altenburg',
      'Altenbüren',
      'Altenberge',
      'Altenberg',
      'Altenbeken',
      'Altenau',
      'Altenahr',
      'Altena',
      'Alteglofsheim',
      'Altefähr',
      'Alt Duvenstedt',
      'Altdorf bei Nurnberg',
      'Altdorf',
      'Altdöbern',
      'Altbach',
      'Ammerthal',
      'Alsterdorf',
      'Alsleben',
      'Alsheim',
      'Alsfeld',
      'Alsenz',
      'Alsdorf',
      'Alpirsbach',
      'Alpenrod',
      'Alpen',
      'Allstedt',
      'Allmersbach im Tal',
      'Allmendingen',
      'Allmannsdorf',
      'Alling',
      'Allershausen',
      'Allersberg',
      'Allermöhe',
      'Allensbach',
      'Allendorf an der Lahn',
      'Allendorf',
      'Alheim',
      'Algermissen',
      'Alfter',
      'Alfhausen',
      'Alfeld',
      'Alfdorf',
      'Bad Alexandersbad',
      'Aletshausen',
      'Alesheim',
      'Alerheim',
      'Aldingen',
      'Aldersbach',
      'Aldenhoven',
      'Albstadt',
      'Albshausen',
      'Albisheim',
      'Albig',
      'Albertstadt',
      'Albertshofen',
      'Albersweiler',
      'Albershausen',
      'Albersdorf',
      'Albbruck',
      'Albaching',
      'Aken',
      'Aitrang',
      'Aitrach',
      'Aiterhofen',
      'Aislingen',
      'Ainring',
      'Aindling',
      'Aiglsbach',
      'Aidlingen',
      'Aidhausen',
      'Aidenbach',
      'Aichstetten',
      'Aichhalden',
      'Aichen',
      'Aichelberg',
      'Aichach',
      'Ahsen-Oetzen',
      'Ahrensburg',
      'Ahrensbök',
      'Ahorn',
      'Aholming',
      'Aholfing',
      'Ahnsen',
      'Ahnsbeck',
      'Ahlsdorf',
      'Ahlerstedt',
      'Ahlen',
      'Ahlden',
      'Ahlbeck',
      'Ahausen',
      'Ahaus',
      'Aham',
      'Aglasterhausen',
      'Agathenburg',
      'Affing',
      'Affalterbach',
      'Aerzen',
      'Adorf',
      'Adlkofen',
      'Adlershof',
      'Adenstedt',
      'Adendorf',
      'Adenbüttel',
      'Adenau',
      'Adelzhausen',
      'Adelsried',
      'Adelshofen',
      'Adelsheim',
      'Adelsdorf',
      'Adelmannsfelden',
      'Adelheidsdorf',
      'Adelebsen',
      'Adelberg',
      'Achtrup',
      'Achstetten',
      'Achslach',
      'Achim',
      'Achern',
      'Abtsgmünd',
      'Abtsdorf',
      'Abstatt',
      'Absberg',
      'Abensberg',
      'Abenberg',
      'Abbesbüttel',
      'Aalen',
      'Aach',
      'Eggermühlen',
      'Thalfang',
      'Erdeborn',
      'Barby',
      'Hohenau',
      'Apfeldorf',
      'Höhenkirchen-Siegertsbrunn',
      'Missen-Wilhams',
      'Oy-Mittelberg',
      'Straßlach-Dingharting',
      'Sankt Leonhard am Wonneberg',
      'Seeon-Seebruck',
      'Weiler-Simmerberg',
      'Bodman-Ludwigshafen',
      'Eggingen',
      'Emmingen-Liptingen',
      'Herdwangen-Schönach',
      'Lauchringen',
      'Mühlhausen-Ehingen',
      'Uhldingen-Mühlhofen',
      'Ühlingen-Birkendorf',
      'Altes Lager',
      'Aspach',
      'Bad Peterstal-Griesbach',
      'Bad Rippoldsau-Schapbach',
      'Bad Teinach-Zavelstein',
      'Böbingen an der Rems',
      'Burgstetten',
      'Gutenzell-Hürbel',
      'Königsbach-Stein',
      'Niefern-Öschelbronn',
      'Ölbronn-Dürrn',
      'Rietheim-Weilheim',
      'Seitingen-Oberflacht',
      'Giesen',
      'Mallersdorf-Pfaffenberg',
      'Muhr am See',
      'Postbauer-Heng',
      'Viereth-Trunstadt',
      'Weilersbach',
      'Katlenburg-Lindau',
      'Kromsdorf',
      'Schönstedt',
      'Vellmar',
      'Scheden',
      'Möhnesee',
      'Schieder-Schwalenberg',
      'Rödental',
      'Röslau',
      'Bruchmühlbach-Miesau',
      'Gossersweiler-Stein',
      'Hochstetten-Dhaun',
      'Hoppstädten-Weiersbach',
      'Neumagen-Dhron',
      'Offenbach-Hundheim',
      'Osann-Monzel',
      'Rieschweiler-Mühlbach',
      'Salmtal',
      'Schönenberg-Kübelberg',
      'Schweigen-Rechtenbach',
      'Zeltingen-Rachtig',
      'Ahrbrück',
      'Birken-Honigsessen',
      'Bruttig-Fankel',
      'Dreikirchen',
      'Ediger-Eller',
      'Gondershausen',
      'Hattert',
      'Höhn',
      'Kasbach-Ohlenberg',
      'Katzwinkel',
      'Kirchwald',
      'Kobern-Gondorf',
      'Mülheim-Kärlich',
      'Ruppach-Goldhausen',
      'Sankt Katharinen',
      'Spay',
      'Blankenbach',
      'Riedenberg',
      'Sailauf',
      'Florstadt',
      'Liederbach',
      'Schöffengrund',
      'Waldems',
      'Walluf',
      'Klein Offenseth-Sparrieshoop',
      'Asbach-Bäumenheim',
      'Baar-Ebenhausen',
      'Finningen',
      'Kottgeisering',
      'Medlingen',
      'Schondorf am Ammersee',
      'Wörth',
      'Dohren',
      'Lünne',
      'Neukamperfehn',
      'Aukrug',
      'Bordelum',
      'Emmelsbüll-Horsbüll',
      'Helgoland',
      'Jarplund-Weding',
      'Lohe-Rickelshof',
      'Mohrkirch',
      'Risum-Lindholm',
      'Büddenstedt',
      'Elbe',
      'Flöthe',
      'Heere',
      'Ilsede',
      'Kanzlerfeld',
      'Sassenburg',
      'Sickte',
      'Bensdorf',
      'Mühlanger',
      'Nessa',
      'Marschacht',
      'Admannshagen-Bargeshagen',
      'Bartenshagen-Parkentin',
      'Börgerende-Rethwisch',
      'Gattendorf',
      'Rossau',
      'Kappel-Grafenhausen',
      'Oberrotweil',
      'Hiddensee',
      'Ebersbach-Musbach',
      'Reichenau',
      'Vogtsburg',
      'Brohl-Lützing',
      'Marktrodach',
      'Triebel',
      'Ransbach-Baumbach',
      'Ludweiler-Warndt',
      'Aachen',
      'Ubstadt-Weiher',
      'Stadecken-Elsheim',
      'Dannstadt-Schauernheim',
      'Sankt Leon-Rot',
      'Rödersheim-Gronau',
      'Karlsdorf-Neuthard',
      'Graben-Neudorf',
      'Mörfelden-Walldorf',
      'Linkenheim-Hochstetten',
      'Billigheim-Ingenheim',
      'Böhl-Iggelheim',
      'Hochdorf-Assenheim',
      'Alsbach-Hähnlein',
      'Dittelsheim-Heßloch',
      'Riedstadt',
      'Hochstadt',
      'Kranichstein',
      'Leingarten',
      'Lauda-Königshofen',
      'Altenbuch',
      'Mespelbrunn',
      'Filderstadt',
      'Ostfildern',
      'Rodgau',
      'Butjadingen',
      'Kaufungen',
      'Jettingen-Scheppach',
      'Gropiusstadt',
      'Seeheim-Jugenheim',
      'Charlottenburg-Nord',
      'Mitte',
      'Plänterwald',
      'Dallgow-Döberitz',
      'Bertsdorf-Hörnitz',
      'Rheinstetten',
      'Münstertal/Schwarzwald',
      'Salem',
      'Altstadt Sud',
      'Altstadt Nord',
      'Neuehrenfeld',
      'Bilderstöckchen',
      'Neunkirchen am Brand',
      'Rothenburgsort',
      'Stuttgart-Ost',
      'Bochum-Hordel',
      'St. Pauli',
      'Schwanau',
      'Gallus',
      'Eidelstedt',
      'Schelfstadt',
      'Werdervorstadt',
      'Altstadt',
      'Feldstadt',
      'Paulsstadt',
      'Weststadt',
      'Lewenberg',
      'Eisleben Lutherstadt',
      'Halle Neustadt',
      'Bergedorf',
      'Spandau',
      'Berlin Treptow',
      'Niederrad',
      'Haselbachtal',
      'Wittenberg',
      'Äußere Neustadt',
      'Garbsen-Mitte',
      'Barmbek-Nord',
      'Barmbek-Süd',
      'Hammerbrook',
      'HafenCity',
      'Farmsen-Berne',
      'Kleiner Grasbrook',
      'Fehmarn',
      'Stadtrandsiedlung Malchow',
      'Hansaviertel',
      'Falkenhagener Feld',
      'Neu-Hohenschönhausen',
      'Waidmannslust',
      'Alt-Hohenschönhausen',
      'Fennpfuhl',
      'Neustadt an der Orla',
      'Hamburg-Nord',
      'Burg Unter-Falkenstein',
      'Neu-Pattern',
      'Neustadt/Nord',
      'Neustadt/Süd',
      'Buchforst',
      'Kalk',
      'Rodenkirchen',
      'Gartenstadt',
      'Lachen-Speyerdorf',
      'Osternienburger Land',
      'Auf der Horst',
      'Dierkow-Neu',
      'Dierkow-West',
      'Kröpeliner-Tor-Vorstadt',
      'Solms',
      'Röchling-Höhe',
      'Westhagen',
      'Düsseldorf-Pempelfort',
      'Burglesum',
      'Südstadt',
      'Heidberg',
      'Sülldorf',
      'Faldera',
      'Böcklersiedlung-Bugenhagen',
      'Altona-Altstadt',
      'Altona-Nord',
      'Sternschanze',
      'Veddel',
      'Dulsberg',
      'Hoheluft-Ost',
      'Helmstadt-Bargen',
      'Fronreute',
      'Hirschberg an der Bergstraße',
      'Kraichtal',
      'Langenbrettach',
      'Lenningen',
      'Lobbach',
      'Sachsenheim',
      'Paradies',
      'Petershausen-West',
      'Kronberg Tal',
      'Schuntersdlg - West',
      'Altewiek',
      'Altes Hochschulviertel',
      'Rühme - Lincolnsdlg.',
      'Petritor - West',
      'Siegfriedviertel',
      'Neues Hochschulviertel',
      'Alt - Lehndorf',
      'Hauptbahnhof',
      'Am Hagenring',
      'Petritor - Ost',
      'Querum -  Wabenkamp',
      'Bürgerpark',
      'Weinberg',
      'Hohetor',
      'Nordbahnhof',
      'Wilhelmitor - Süd',
      'Pappelberg',
      'Stadtkern',
      'Schwarzer Berg',
      'Prinzenpark',
      'Viewegs Garten',
      'Petritor - Nord',
      'Querumer Forst',
      'Rühme - Vorwerksdlg.',
      'Bebelhof',
      'Wilhelmitor - Nord',
      'Remseck am Neckar',
      'Brachenfeld-Ruthenberg',
      'Industriegebiet',
      'Königsbau',
      'Kelkheim-Mitte',
      'Oberzent',
    ],
  },
  {
    country: 'Djibouti',
    cities: [
      'Tadjourah',
      'Obock',
      'Loyada',
      'Holhol',
      'Dorra',
      'Djibouti',
      'Dikhil',
      'Arta',
      "'Ali Sabieh",
      'Alaïli Ḏaḏḏa‘',
      'Goubétto',
      'Gâlâfi',
    ],
  },
  {
    country: 'Denmark',
    cities: [
      'Vrå',
      'Vordingborg',
      'Vorbasse',
      'Vojens',
      'Vodskov',
      'Vissenbjerg',
      'Virklund',
      'Vipperød',
      'Vindinge',
      'Vinderup',
      'Vindeby',
      'Vildbjerg',
      'Videbæk',
      'Viby',
      'Viborg',
      'Væggerløse',
      'Vester-Skerninge',
      'Vester Hassing',
      'Vestbjerg',
      'Vemb',
      'Veksø',
      'Vejle',
      'Vejen',
      'Varde',
      'Vanløse',
      'Vamdrup',
      'Vallensbæk',
      'Valby',
      'Vadum',
      'Undløse',
      'Ulstrup',
      'Ullerslev',
      'Ulfborg',
      'Tylstrup',
      'Tune',
      'Trørød',
      'Troense',
      'Trige',
      'Tranebjerg',
      'Tranbjerg',
      'Tørring',
      'Tønder',
      'Tølløse',
      'Tommerup Stationsby',
      'Tommerup',
      'Toftlund',
      'Tjæreborg',
      'Tisvilde',
      'Tistrup',
      'Tinglev',
      'Thyborøn',
      'Thurø By',
      'Thorsø',
      'Thisted',
      'Them',
      'Taulov',
      'Taastrup',
      'Tårs',
      'Tårnby',
      'Tarm',
      'Svogerslev',
      'Svinninge',
      'Svenstrup',
      'Svendborg',
      'Svejbæk',
      'Svebølle',
      'Svaneke',
      'Sunds',
      'Sulsted',
      'Stubbekøbing',
      'Struer',
      'Strøby Egede',
      'Strib',
      'Strandby',
      'Storvorde',
      'Store Merløse',
      'Store Heddinge',
      'Støvring',
      'Stoholm',
      'Stilling',
      'Stige',
      'Stensved',
      'Stenløse',
      'Stenlille',
      'Stege',
      'Stavtrup',
      'Stavnsholt',
      'Starup',
      'Spentrup',
      'Sorø',
      'Søndersø',
      'Sønderborg',
      'Sønder Bjert',
      'Søllested',
      'Søften',
      'Solrød Strand',
      'Solrød',
      'Solbjerg',
      'Snoghøj',
      'Snejbjerg',
      'Smørumnedre',
      'Slangerup',
      'Slagelse',
      'Skævinge',
      'Skaerbaek',
      'Skovby',
      'Skørping',
      'Skodsborg',
      'Skælskør',
      'Skjern',
      'Skive',
      'Skibby',
      'Skanderborg',
      'Skagen',
      'Sindal',
      'Silkeborg',
      'Seden',
      'Sæby',
      'Sakskøbing',
      'Sabro',
      'Ryslinge',
      'Ryomgård',
      'Ry',
      'Rudkøbing',
      'Roskilde',
      'Rørvig',
      'Rønnede',
      'Rønne',
      'Rønde',
      'Rødvig',
      'Rødovre',
      'Rødekro',
      'Rødding',
      'Rødbyhavn',
      'Rødby',
      'Ringsted',
      'Ringkøbing',
      'Ringe',
      'Ribe',
      'Randers',
      'Ramløse',
      'Præstø',
      'Pandrup',
      'Padborg',
      'Outrup',
      'Over Holluf',
      'Otterup',
      'Osted',
      'Ørum',
      'Ørslev',
      'Ørbæk',
      'Ølstykke',
      'Ølsted',
      'Ølgod',
      'Oksbøl',
      'Odense',
      'Odder',
      'Nysted',
      'Nyråd',
      'Nykøbing Falster',
      'Nykøbing Sjælland',
      'Nykøbing Mors',
      'Nyborg',
      'Næstved',
      'Nordby',
      'Nordborg',
      'Nørresundby',
      'Nørre Nebel',
      'Nørre Broby',
      'Nørre Bindslev',
      'Nørre Alslev',
      'Nørre Åby',
      'Nørager',
      'Nødebo',
      'Nivå',
      'Nibe',
      'Nexø',
      'Neder Vindinge',
      'Neder Holluf',
      'Nakskov',
      'Munkebo',
      'Middelfart',
      'Marstal',
      'Mårslet',
      'Maribo',
      'Mariager',
      'Måløv',
      'Malling',
      'Lystrup',
      'Lynge',
      'Lunderskov',
      'Løsning',
      'Løkken',
      'Løjt Kirkeby',
      'Løgumkloster',
      'Løgten',
      'Løgstrup',
      'Løgstør',
      'Liseleje',
      'Lisbjerg',
      'Lind',
      'Værløse',
      'Lillerød',
      'Lemvig',
      'Lejre',
      'Låsby',
      'Langeskov',
      'Langå',
      'Kvistgård',
      'Kruså',
      'Korsør',
      'Køge',
      'Copenhagen',
      'Kongens Lyngby',
      'Kolt',
      'Kolding',
      'Kokkedal',
      'Knabstrup',
      'Klitmøller',
      'Klarup',
      'Kjellerup',
      'Kirke Hvalsø',
      'Kirke-Esbønderup',
      'Kibæk',
      'Kerteminde',
      'Kås',
      'Karup',
      'Kalundborg',
      'Jyllinge',
      'Jyderup',
      'Juelsminde',
      'Jægerspris',
      'Jelling',
      'Ishøj',
      'Ikast',
      'Hvidovre',
      'Hvide Sande',
      'Hurup',
      'Hundested',
      'Humlebæk',
      'Horsens',
      'Hornsyld',
      'Hornslet',
      'Hornbæk',
      'Hørve',
      'Høruphav',
      'Hørsholm',
      'Hørning',
      'Høng',
      'Højby',
      'Holte',
      'Holsted',
      'Holstebro',
      'Holeby',
      'Holbæk',
      'Hobro',
      'Hjortshøj',
      'Hjordkær',
      'Hjørring',
      'Hjallerup',
      'Hirtshals',
      'Hinnerup',
      'Hillerød',
      'Herning',
      'Herlev',
      'Helsingør',
      'Helsinge',
      'Hellebæk',
      'Hedensted',
      'Hedehusene',
      'Havdrup',
      'Hatting',
      'Hasselager',
      'Haslev',
      'Harlev',
      'Hårlev',
      'Haarby',
      'Harboøre',
      'Hanstholm',
      'Hammerum',
      'Hammel',
      'Hals',
      'Hadsund',
      'Hadsten',
      'Haderslev',
      'Gundsømagle',
      'Guderup',
      'Græsted',
      'Grindsted',
      'Greve',
      'Grenaa',
      'Gråsten',
      'Gram',
      'Gørlev',
      'Glumsø',
      'Glostrup',
      'Glamsbjerg',
      'Gjern',
      'Gjellerup',
      'Give',
      'Gistrup',
      'Gilleleje',
      'Gevninge',
      'Ganløse',
      'Gandrup',
      'Galten',
      'Gadstrup',
      'Fuglebjerg',
      'Frejlev',
      'Frederiksværk',
      'Frederikssund',
      'Frederikshavn',
      'Frederiksberg',
      'Fredericia',
      'Fredensborg',
      'Framlev',
      'Forlev',
      'Fløng',
      'Fjerritslev',
      'Fensmark',
      'Farum',
      'Farsø',
      'Faxe Ladeplads',
      'Faxe',
      'Faaborg',
      'Espergærde',
      'Eskilstrup',
      'Esbjerg',
      'Elling',
      'Ejby',
      'Egtved',
      'Ebeltoft',
      'Dybbøl',
      'Dronninglund',
      'Dragør',
      'Dianalund',
      'Christianshavn',
      'Christiansfeld',
      'Charlottenlund',
      'Byrum',
      'Bullerup',
      'Buddinge',
      'Brovst',
      'Brørup',
      'Brønderslev',
      'Brøndbyvester',
      'Broager',
      'Brenderup',
      'Brejning',
      'Bredsten',
      'Brædstrup',
      'Brande',
      'Bramming',
      'Borup',
      'Bording Kirkeby',
      'Børkop',
      'Bogense',
      'Blovstrød',
      'Bække',
      'Bjæverskov',
      'Bjerringbro',
      'Birkerød',
      'Billund',
      'Bellinge',
      'Beder',
      'Ballerup',
      'Avlum',
      'Auning',
      'Augustenborg',
      'Assentoft',
      'Assens',
      'Asnæs',
      'Årup',
      'Årslev',
      'Aars',
      'Århus',
      'Arden',
      'Ålsgårde',
      'Allingåbro',
      'Ålestrup',
      'Aalborg',
      'Ålbæk',
      'Albertslund',
      'Åkirkeby',
      'Ærøskøbing',
      'Åbybro',
      'Aabenraa',
      'Sundby',
      'Christiansø',
    ],
  },
  {
    country: 'Dominica',
    cities: [
      'Woodford Hill',
      'Wesley',
      'Soufrière',
      'Salisbury',
      'Saint Joseph',
      'Roseau',
      'Rosalie',
      'Portsmouth',
      'Pont Cassé',
      'Pointe Michel',
      'Marigot',
      'Mahaut',
      'La Plaine',
      'Colihaut',
      'Castle Bruce',
      'Calibishie',
      'Berekua',
    ],
  },
  {
    country: 'Dominican Republic',
    cities: [
      'Yayas de Viajama',
      'Yamasá',
      'Yaguate',
      'Villa Vásquez',
      'Villa Tapia',
      'Villarpando',
      'Villa Riva',
      'Villa Jaragua',
      'Villa Isabela',
      'Villa González',
      'Villa Francisca',
      'Villa Elisa',
      'Villa Consuelo',
      'Villa Bisonó',
      'Villa Altagracia',
      'Vicente Noble',
      'Veragua Arriba',
      'Vallejuelo',
      'Tireo Arriba',
      'Tenares',
      'Tamboril',
      'Tamayo',
      'Tábara Arriba',
      'Sosúa',
      'San Víctor Arriba',
      'Santo Domingo',
      'Santiago de los Caballeros',
      'Santa Cruz de El Seibo',
      'Santa Cruz de Barahona',
      'Samaná',
      'San Rafael del Yuma',
      'San Pedro de Macorís',
      'San Juan de la Maguana',
      'San José de Ocoa',
      'San José de Las Matas',
      'San Gregorio de Nigua',
      'San Francisco de Macorís',
      'San Fernando de Monte Cristi',
      'Puerto Plata',
      'Sánchez',
      'San Carlos',
      'Salvaleón de Higüey',
      'Salsipuedes',
      'Salcedo',
      'Sabaneta',
      'Sabana Yegua',
      'Sabana Iglesia',
      'Sabana Grande de Palenque',
      'Sabana Grande de Boyá',
      'Sabana del Puerto',
      'Sabana de la Mar',
      'Sabana Buey',
      'Río Verde Arriba',
      'Río San Juan',
      'Río Limpio',
      'Río Grande',
      'Rincón',
      'Restauración',
      'Ramón Santana',
      'Quisqueya',
      'Punta Cana',
      'Pueblo Viejo',
      'Postrer Río',
      'Polo',
      'Pizarrete',
      'Pimentel',
      'Piedra Blanca',
      'Pescadería',
      'Peralta',
      'Pepillo Salcedo',
      'Pedro Santana',
      'Pedro Sánchez',
      'Pedro García',
      'Pedro Corto',
      'Pedernales',
      'Paya',
      'Partido',
      'Paraíso',
      'Palmar de Ocoa',
      'Palmar Arriba',
      'Padre Las Casas',
      'Oviedo',
      'Otra Banda',
      'Nizao',
      'Neiba',
      'Nagua',
      'Monte Plata',
      'Monte Llano',
      'Monción',
      'Moca',
      'Miches',
      'Mella',
      'Matayaya',
      'Matanzas',
      'Mao',
      'Majagual',
      'Maizal',
      'Luperón',
      'Los Ríos',
      'Los Llanos',
      'Los Hidalgos',
      'Los Botados',
      'Loma de Cabrera',
      'Licey al Medio',
      'La Uvilla',
      'Las Terrenas',
      'Las Salinas',
      'Las Matas de Santa Cruz',
      'Las Matas de Farfán',
      'Las Guáranas',
      'Las Charcas',
      'La Romana',
      'La Julia',
      'Laguna Salada',
      'La Entrada',
      'La Descubierta',
      'La Ciénaga',
      'La Caya',
      'La Canela',
      'La Agustina',
      'Juncalito Abajo',
      'Juan Santiago',
      'Juan López Abajo',
      'Juan de Herrera',
      'Juancho',
      'Juan Adrián',
      'Joba Arriba',
      'Jimaní',
      'Jima Abajo',
      'Jicomé',
      'Jarabacoa',
      'Jaquimeyes',
      'Jamao al Norte',
      'Jaibón',
      'Imbert',
      'Hostos',
      'Hondo Valle',
      'Hato Mayor del Rey',
      'Hatillo Palma',
      'Guerra',
      'Guaymate',
      'Guayabo Dulce',
      'Guayabal',
      'Guatapanal',
      'Guananico',
      'Gonzalo',
      'Gaspar Hernández',
      'Galván',
      'Fundación',
      'Fantino',
      'Estero Hondo',
      'Estebanía',
      'Esperanza',
      'Esperalvillo',
      'Ensanche Luperón',
      'Enriquillo',
      'El Valle',
      'El Puerto',
      'El Pino',
      'El Peñón',
      'El Palmar',
      'El Llano',
      'El Guayabal',
      'El Factor',
      'El Carril',
      'El Cacao',
      'Duvergé',
      'Don Juan',
      'Dajabón',
      'Cristo Rey',
      'Cristóbal',
      'Cotuí',
      'Constanza',
      'Concepción de La Vega',
      'Comendador',
      'Ciudad Nueva',
      'Cevicos',
      'Cercado Abajo',
      'Cayetano Germosén',
      'Castillo',
      'Castañuelas',
      'Canoa',
      'Cana Chapetón',
      'Cambita Garabitos',
      'Cachón',
      'Cabrera',
      'Cabral',
      'Cabarete',
      'Bonao',
      'Bohechío',
      'Boca de Yuma',
      'Boca Chica',
      'San Cristóbal',
      'Bella Vista',
      'Bayaguana',
      'Bánica',
      'Baní',
      'Bajos de Haina',
      'Baitoa',
      'Azua',
      'Arroyo Salado',
      'Arenoso',
      'Amina',
      'Altamira',
      'Agua Santa del Yuna',
      'Santo Domingo Oeste',
      'Santo Domingo Este',
      'Villa La Mata',
    ],
  },
  {
    country: 'Algeria',
    cities: [
      'Boumerdas',
      'Zeribet el Oued',
      'Zeralda',
      'Zemoura',
      'Touggourt',
      'Tolga',
      'Tlemcen',
      'Tizi Rached',
      'Tizi Ouzou',
      'Tizi-n-Tleta',
      'Tizi Gheniff',
      'Tissemsilt',
      'Tirmitine',
      'Tipaza',
      'Tindouf',
      'Timizart',
      'Timimoun',
      'el hed',
      'Tiaret',
      'Theniet el Had',
      'Thenia',
      'Telerghma',
      'Tébessa',
      'Tebesbest',
      'Tazoult-Lambese',
      'Tamanrasset',
      'Tamalous',
      'Taïbet',
      'Tadmaït',
      'Sour el Ghozlane',
      'Souma',
      'Lardjem',
      'Souk Ahras',
      'Sougueur',
      'Skikda',
      'Sig',
      'Sidi Okba',
      'Sidi Moussa',
      'Sidi Mérouane',
      'Sidi Khaled',
      'Sidi ech Chahmi',
      'Sidi Bel Abbès',
      'Sidi Amrane',
      'Sidi Akkacha',
      'Sidi Aïssa',
      'Sidi Abdelli',
      'Sfizef',
      'Sétif',
      'Sedrata',
      'Seddouk',
      'Sebdou',
      'Saoula',
      'Salah Bey',
      'Saïda',
      'Rouissat',
      'Rouiba',
      'Rouached',
      'Robbah',
      'Remchi',
      'Relizane',
      'Reguiba',
      'Reghaïa',
      'Reggane',
      'Râs el Oued',
      'Râs el Aïoun',
      'Oum el Bouaghi',
      'Oumache',
      'Ouled Moussa',
      'Ouled Mimoun',
      'Oued Sly',
      'Oued Rhiou',
      'Oued Fodda',
      'Oued el Alleug',
      'Oued el Abtal',
      'Ouargla',
      'Oran',
      'Nedroma',
      'Naciria',
      'Naama',
      'M’sila',
      'Mouzaïa',
      'Mostaganem',
      'Mila',
      'Metlili Chaamba',
      'Messaad',
      'Meskiana',
      'Mers el Kebir',
      'Merouana',
      'Melouza',
      'Mekla',
      'Mehdia daira de meghila',
      'Megarine',
      'Meftah',
      'Médéa',
      'Draa Klalouche',
      'Mazouna',
      'Mascara',
      'Mansourah',
      'Mansoûra',
      'Makouda',
      'L’Arbaa Naït Irathen',
      'Larbaâ',
      'Lakhdaria',
      'Laghouat',
      'Ksar el Boukhari',
      'Ksar Chellala',
      'Kolea',
      'Khenchela',
      'Khemis Miliana',
      'Khemis el Khechna',
      'Kerkera',
      'Jijel',
      'Djidiouia',
      'Illizi',
      'Ighram',
      'Hennaya',
      'Héliopolis',
      'Hassi Messaoud',
      'Hammamet',
      'Hammam Bou Hadjar',
      'Hamma Bouziane',
      'Hadjout',
      'Guelma',
      'Ghardaïa',
      'Frenda',
      'Freha',
      'Feraoun',
      'Es Senia',
      'El Tarf',
      'El Oued',
      'El Meghaïer',
      'El Malah',
      'El Kseur',
      'El Khroub',
      'El Kala',
      'El Idrissia',
      'El Hadjira',
      'El Hadjar',
      'El Eulma',
      'El Bayadh',
      'El Attaf',
      'Chlef',
      'El Aouinet',
      'El Amria',
      'El Affroun',
      'El Achir',
      'El Abiodh Sidi Cheikh',
      'El Abadia',
      'Ech Chettia',
      'Drean',
      'Draa el Mizan',
      'Draa Ben Khedda',
      'Douera',
      'Djelfa',
      'Djamaa',
      'Didouche Mourad',
      'Dellys',
      'Debila',
      'Dar el Beïda',
      'Dar Chioukh',
      'Constantine',
      'Collo',
      'Chorfa',
      'Chiffa',
      'Chetouane',
      'Cheria',
      'Cheraga',
      'Chemini',
      'Chelghoum el Aïd',
      'Chebli',
      'Charef',
      'Chabet el Ameur',
      'Brezina',
      'Bou Tlelis',
      'Boumagueur',
      'Boumahra Ahmed',
      'Boukadir',
      'Bou Ismaïl',
      'Bouïra',
      'Bouinan',
      'Bou Hanifia el Hamamat',
      'Bougara',
      'Bougaa',
      'Boufarik',
      'Boudouaou',
      'Boudjima',
      'Boû Arfa',
      'Bordj Zemoura',
      'Bordj Ghdir',
      'Bordj el Kiffan',
      'Bordj Bou Arréridj',
      'Boghni',
      'Blida',
      'Biskra',
      'Birkhadem',
      'Birine',
      'Bir el Djir',
      'Bir el Ater',
      'Besbes',
      'Berrouaghia',
      'Berriane',
      'Berrahal',
      'Bensekrane',
      'Ben Mehidi',
      'Beni Saf',
      'Beni Mester',
      'Beni Mered',
      'Beni Douala',
      'Beni Amrane',
      'Bejaïa',
      'Béchar',
      'Batna',
      'Barika',
      'Barbacha',
      'Baraki',
      'Bab Ezzouar',
      'Azzaba',
      'Azazga',
      'Arris',
      'Arhribs',
      'Arbatache',
      'Aoulef',
      'Annaba',
      'Ammi Moussa',
      'Amizour',
      'Algiers',
      'Akbou',
      'Aïn Touta',
      'Aïn Temouchent',
      'Aïn Taya',
      'Aïn Smara',
      'Aïn Sefra',
      'Aïn Oussera',
      '’Aïn Merane',
      'Aïn Kercha',
      'Aïn Fakroun',
      '’Aïn el Turk',
      '’Aïn el Melh',
      '’Aïn el Hammam',
      '‘Aïn el Hadjel',
      '’Aïn el Hadjar',
      'Aïn el Bya',
      '’Aïn el Berd',
      '’Aïn el Bell',
      '’Aïn Deheb',
      'Aïn Defla',
      '’Aïn Boucif',
      'Aïn Bessem',
      '’Aïn Benian',
      'Aïn Beïda',
      'Aïn Arnat',
      '’Aïn Abid',
      'Aflou',
      'Adrar',
      'Abou el Hassan',
      'Abadla',
      'BABOR - VILLE',
      'In Salah',
      'Smala',
      'Djebilet Rosfa',
      'Ait Yahia',
      'Sidi Senoussi سيدي سنوسي',
      'District of Taher',
    ],
  },
  {
    country: 'Ecuador',
    cities: [
      'Zaruma',
      'Zamora',
      'Yantzaza',
      'Yaguachi Nuevo',
      'Vinces',
      'Ventanas',
      'Velasco Ibarra',
      'Valdez',
      'Tulcán',
      'Tosagua',
      'Tena',
      'Taisha',
      'Sucúa',
      'Sucre',
      'Saquisilí',
      'San Vicente',
      'Santo Domingo de los Colorados',
      'Santa Rosa',
      'Santa Lucía',
      'Santa Elena',
      'Santa Clara',
      'Santa Ana',
      'San Miguel de Salcedo',
      'San Miguel',
      'San Lorenzo de Esmeraldas',
      'Sangolquí',
      'San Gabriel',
      'Samborondón',
      'Salinas',
      'Rosa Zarate',
      'Rocafuerte',
      'Riobamba',
      'Quito',
      'Quevedo',
      'Quero',
      'Puyo',
      'Pujilí',
      'Puerto Villamil',
      'Puerto Francisco de Orellana',
      'Puerto Bolívar',
      'Puerto Baquerizo Moreno',
      'Puerto Ayora',
      'Portoviejo',
      'Portovelo',
      'Playas',
      'Piñas',
      'Pimampiro',
      'Píllaro',
      'Pelileo',
      'Pedro Carbo',
      'Pedernales',
      'Pasaje',
      'Palora',
      'Palestina',
      'Palenque',
      'Paján',
      'Otavalo',
      'Nulti',
      'Naranjito',
      'Naranjal',
      'Muisne',
      'Montecristi',
      'Montalvo',
      'Milagro',
      'Manta',
      'Machala',
      'Machachi',
      'Macas',
      'Macará',
      'Lomas de Sargentillo',
      'Loja',
      'Llacao',
      'La Unión',
      'La Troncal',
      'Latacunga',
      'La Maná',
      'La Libertad',
      'Nueva Loja',
      'Junín',
      'Jipijapa',
      'Ibarra',
      'Huaquillas',
      'Guayaquil',
      'Guaranda',
      'Guano',
      'Gualaquiza',
      'Gualaceo',
      'Esmeraldas',
      'El Triunfo',
      'Eloy Alfaro',
      'El Ángel',
      'Cuenca',
      'Cube',
      'Cotacachi',
      'Coronel Marcelino Maridueña',
      'Colimes',
      'Chone',
      'Celica',
      'Cayambe',
      'Catarama',
      'Catamayo',
      'Catacocha',
      'Cariamanga',
      'Cañar',
      'Calceta',
      'Pampanal de Bolívar',
      'Boca Suno',
      'Baños',
      'Balzar',
      'Baláo',
      'Bahía de Caráquez',
      'Babahoyo',
      'Azogues',
      'Atuntaqui',
      'Archidona',
      'Ambato',
      'Alfredo Baquerizo Moreno',
      'Alausí',
      'Gonzalo Pizarro',
      'Wilfrido Loor Moreira',
      'Tres Postes',
      'Oyambarillo',
    ],
  },
  {
    country: 'Estonia',
    cities: [
      'Vändra',
      'Võru',
      'Võhma',
      'Vinni',
      'Viljandi',
      'Viiratsi',
      'Viimsi',
      'Värska',
      'Vana-Antsla',
      'Valga',
      'Väike-Maarja',
      'Vaida',
      'Uulu',
      'Uuemõisa',
      'Ülenurme',
      'Türi',
      'Turba',
      'Tõrva',
      'Toila',
      'Tehumardi',
      'Tartu',
      'Tapa',
      'Tamsalu',
      'Tallinn',
      'Taebla',
      'Tabasalu',
      'Suure-Jaani',
      'Sindi',
      'Sillamäe',
      'Sauga',
      'Saue',
      'Särevere',
      'Saku',
      'Rummu',
      'Ruhnu',
      'Rõuge',
      'Riisipere',
      'Rapla',
      'Räpina',
      'Rakvere',
      'Rakke',
      'Raasiku',
      'Puhja',
      'Pringi',
      'Põlva',
      'Põltsamaa',
      'Pärnu-Jaagupi',
      'Pärnu',
      'Paldiski',
      'Pajusti',
      'Paikuse',
      'Paide',
      'Otepää',
      'Orissaare',
      'Karksi-Nuia',
      'Nõo',
      'Narva-Jõesuu',
      'Narva',
      'Mustvee',
      'Märjamaa',
      'Maardu',
      'Luunja',
      'Loo',
      'Loksa',
      'Linaküla',
      'Liiva',
      'Lihula',
      'Kuusalu',
      'Kuressaare',
      'Kurepalu',
      'Kunda',
      'Kose',
      'Kõrveküla',
      'Kohtla-Järve',
      'Kohtla-Nõmme',
      'Kohila',
      'Koeru',
      'Kiviõli',
      'Kilingi-Nõmme',
      'Kiili',
      'Keila',
      'Kehtna',
      'Kehra',
      'Kärdla',
      'Kanepi',
      'Kadrina',
      'Jüri',
      'Jõhvi',
      'Jõgeva',
      'Järvakandi',
      'Järva-Jaani',
      'Iisaku',
      'Hullo',
      'Haljala',
      'Haapsalu',
      'Haabneeme',
      'Elva',
      'Audru',
      'Aseri',
      'Aruküla',
      'Antsla',
      'Alatskivi',
      'Abja-Paluoja',
      'Vaiatu',
      'Sõmeru',
      'Harku',
      'Laagri',
    ],
  },
  {
    country: 'Egypt',
    cities: [
      'Zefta',
      'Ţūkh',
      'Ţimā',
      'Ţanţā',
      'Ţāmiyah',
      'Ţalkhā',
      'Talā',
      'Ţahţā',
      'Sumusţā as Sulţānī',
      'Sohag',
      'Siwa Oasis',
      'Sinnūris',
      'Sīdī Sālim',
      'Sīdī Barānī',
      'Shubrā al Khaymah',
      'Shirbīn',
      'Shibīn al Qanāṭir',
      'Shibīn al Kawm',
      'Sharm el-Sheikh',
      'Samannūd',
      'Samālūţ',
      'Rosetta',
      'Ras Gharib',
      'Rafaḩ',
      'Quwaysinā',
      'Quţūr',
      'Qūş',
      'Qinā',
      'Qaşr al Farāfirah',
      'Qalyūb',
      'Nuwaybi‘a',
      "Naja' Ḥammādī",
      'Mīt Ghamr',
      'Minyat an Naşr',
      'Munūf',
      'Maţāy',
      'Mashtūl as Sūq',
      'Mersa Matruh',
      'Marsa Alam',
      'Manfalūţ',
      'Mallawī',
      'Maghāghah',
      'Madīnat Sittah Uktūbar',
      'Al ‘Āshir min Ramaḑān',
      'Ash Shaykh Zuwayd',
      'Kirdāsah',
      'Kawm Umbū',
      'Kawm Ḩamādah',
      'Kafr Şaqr',
      'Kafr az Zayyāt',
      'Kafr ash Shaykh',
      'Kafr ad Dawwār',
      'Juhaynah',
      'Girga',
      '‘Izbat al Burj',
      'Iţsā',
      'Isnā',
      'Idkū',
      'Idfū',
      'Ibshawāy',
      'Ḩalwān',
      'Hihyā',
      'Ḩawsh ‘Īsá',
      'Fuwwah',
      'Farshūţ',
      'Fāraskūr',
      'Fāqūs',
      'Damietta',
      'Diyarb Najm',
      'Disūq',
      'Dishnā',
      'Dikirnis',
      'Dahab',
      'Dayrūţ',
      'Dayr Mawās',
      'Damanhūr',
      'Būsh',
      'Port Said',
      'Safaga',
      'Bilqās',
      'Bilbays',
      'Basyūn',
      'Banī Suwayf',
      'Banī Mazār',
      'Banhā',
      'Zagazig',
      'Awsīm',
      'El-Tor',
      'At Tall al Kabīr',
      'Asyūţ',
      'Aswan',
      'Suez',
      'As Sinbillāwayn',
      'As Sallūm',
      'Aş Şaff',
      'Ash Shuhadā’',
      'Ashmūn',
      'Al Wāsiţah',
      'Luxor',
      'Al Qūşīyah',
      'Al Quşayr',
      'Al Qurayn',
      'Al Qanāyāt',
      'Al Qanāţir al Khayrīyah',
      'Cairo',
      'Al Minyā',
      'Al Maţarīyah',
      'Al Manzalah',
      'Al Manşūrah',
      'Al Minshāh',
      'Al Maḩallah al Kubrá',
      'Al Khuşūş',
      'Al Khārjah',
      'Al Khānkah',
      'Giza',
      'Al Jammālīyah',
      'Ismailia',
      'Alexandria',
      'Al Ibrāhīmīyah',
      'Al Ḩawāmidīyah',
      'Al Ḩāmūl',
      'Hurghada',
      'Al Fayyūm',
      'Al Fashn',
      'Al Bawīţī',
      'Al Balyanā',
      'Al Bājūr',
      'Al Badārī',
      'Al ‘Ayyāţ',
      'Arish',
      'Al ‘Alamayn',
      'Akhmīm',
      'Ajā',
      'Ad Dilinjāt',
      'Abū Tīj',
      'Abu Simbel',
      'Abū Qurqāş',
      'Abū Kabīr',
      'Abū al Maţāmīr',
      'Abnūb',
      'Bi’r al ‘Abd',
      'Az Zarqā',
      'El Gouna',
      'Makadi Bay',
      'Ain Sukhna',
      'New Cairo',
      'Saint Catherine',
      'Munshāt ‘Alī Āghā',
      "Al-'Ubūr",
    ],
  },
  {
    country: 'Western Sahara',
    cities: ['Laayoune Plage', 'Laayoune', 'Dakhla'],
  },
  {
    country: 'Eritrea',
    cities: [
      'Teseney',
      'Massawa',
      'Keren',
      'Edd',
      'Dek’emhāre',
      'Barentu',
      'Asmara',
      'Assab',
      'Ak’ordat',
      'Mendefera',
      'Adi Keyh',
      'Mai-Mne',
    ],
  },
  {
    country: 'Spain',
    cities: [
      'Zurgena',
      'Zújar',
      'Zuheros',
      'Zufre',
      'Zubia',
      'Zorita',
      'Zarza la Mayor',
      'Zarza de Montánchez',
      'Zarza de Alange',
      'Zarra',
      'Zalamea la Real',
      'Zalamea de la Serena',
      'Zahinos',
      'Zahara de los Atunes',
      'Zahara',
      'Zagra',
      'Zafra de Záncara',
      'Zafra',
      'Zafarraya',
      'Yunquera',
      'Yeste',
      'Yepes',
      'Yémeda',
      'Yecla',
      'Yátova',
      'Yaiza',
      'Víznar',
      'Viveros',
      'Viver',
      'Viso del Marqués',
      'Viñuela',
      'Vinalesa',
      'Villena',
      'Villavieja',
      'Villaviciosa de Córdoba',
      'Villaverde y Pasaconsol',
      'Villaverde del Río',
      'Villaverde de Guadalimar',
      'Villavaliente',
      'Villatoya',
      'Villatobas',
      'Villasequilla de Yepes',
      'Villaseca de la Sagra',
      'Villarta de San Juan',
      'Villarta de los Montes',
      'Villarta',
      'Villarrubio',
      'Villarrubia de Santiago',
      'Villarrubia de los Ojos',
      'Villarrubia',
      'Villarrobledo',
      'Villarrasa',
      'Villargordo del Cabriel',
      'Villares del Saz',
      'Villarejo-Periesteban',
      'Villarejo de Montalbán',
      'Villarejo de Fuentes',
      'Vila-real',
      'Villardompardo',
      'Villar de Rena',
      'Villar del Rey',
      'Villar del Pozo',
      'Villar del Pedroso',
      'Villar del Humo',
      'Villar del Arzobispo',
      'Villar de la Encina',
      'Villar de Cañas',
      'Villaralto',
      'Villapalacios',
      'Villanueva de Tapia',
      'Villanueva de San Juan',
      'Villanueva de San Carlos',
      'Villanueva de Mesía',
      'Villanueva del Trabuco',
      'Villanueva del Rosario',
      'Villanueva del Río y Minas',
      'Villanueva de los Castillejos',
      'Villanueva del Fresno',
      'Villanueva del Duque',
      'Villanueva de las Torres',
      'Villanueva de la Serena',
      'Villanueva de las Cruces',
      'Villanueva del Arzobispo',
      'Villanueva del Ariscal',
      'Villanueva de la Reina',
      'Villanueva de la Jara',
      'Villanueva de la Fuente',
      'Villanueva de Córdoba',
      'Villanueva de Castellón',
      'Villanueva de Bogas',
      'Villanueva de Algaidas',
      'Villanueva de Alcardete',
      'Villamuelas',
      'Villaminaya',
      'Villamiel de Toledo',
      'Villamesías',
      'Villamayor de Santiago',
      'Villamayor de Calatrava',
      'Villamartín',
      'Vilamarxant',
      'Villamanrique de la Condesa',
      'Villamanrique',
      'Villamalur',
      'Villamalea',
      'Villaluenga del Rosario',
      'Villalpardo',
      'Villalonga',
      'Villalgordo del Marquesado',
      'Villalba de los Barros',
      'Villalba del Alcor',
      'Villajoyosa',
      'Villahermosa',
      'Villaharta',
      'Villagordo del Júcar',
      'Villagonzalo',
      'Villagarcía del Llano',
      'Villagarcía de la Torre',
      'Villafranca de los Caballeros',
      'Villafranca de los Barros',
      'Villafranca de Córdoba',
      'Vilafranca de Bonany',
      'Villa de Ves',
      'Villa del Río',
      'Villa del Rey',
      'Villa de Don Fadrique',
      'Villacarrillo',
      'Es Castell',
      'Villacañas',
      'Villablanca',
      'Vilches',
      'Vilaflor',
      'Vícar',
      'Viator',
      'Vergel',
      'Vera',
      'Ventas de Huelma',
      'Ventas con Peña Aguilera',
      'Venta del Moro',
      'Velez Rubio',
      'Vélez-Málaga',
      'Vélez de Benaudalla',
      'Vélez-Blanco',
      'Velefique',
      'Velada',
      'Vejer de la Frontera',
      'Vega de San Mateo',
      'Vara de Rey',
      'Valverdejo',
      'Valverde de Mérida',
      'Valverde de Llerena',
      'Valverde de Leganés',
      'Valverde del Camino',
      'Valverde de Júcar',
      'Valverde de Burguillos',
      'Valverde',
      'Valsequillo de Gran Canaria',
      'Válor',
      'Valleseco',
      'Vallés',
      'Vallehermosa',
      'Valle de Santa Ana',
      'Valle de Matamoros',
      'Valle de la Serena',
      'Valldemossa',
      'Vall de Ebo',
      'Vall de Almonacid',
      'Vallada',
      'Valhermoso de la Fuente',
      'Valenzuela de Calatrava',
      'Valenzuela',
      'Valencina de la Concepción',
      'Valencia del Ventoso',
      'Valencia del Mombuey',
      'Valencia de las Torres',
      'Valencia de Alcántara',
      'Valencia',
      'Valdeverdeja',
      'Valdetorres',
      'Valdés',
      'Valderrubio',
      'Valdepeñas de Jaén',
      'Valdepeñas',
      'Valdemorales',
      'Valdemanco del Esteras',
      'Valdelarco',
      'Valdelacasa de Tajo',
      'Valdehúncar',
      'Valdeganga',
      'Valdefuentes',
      'Valdecañas de Tajo',
      'Valdecaballeros',
      'Utrera',
      'Utiel',
      'Usagre',
      'Urrácal',
      'Urda',
      'Umbrete',
      'Uleila del Campo',
      'Ulea',
      'Ugíjar',
      'Uclés',
      'Ubrique',
      'Úbeda',
      'Turrillas',
      'Turre',
      'Turleque',
      'Turís',
      'Tuineje',
      'Tuéjar',
      'Trujillo',
      'Trujillanos',
      'Trigueros',
      'Tribaldos',
      'Trevélez',
      'Tresjuncos',
      'Trebujena',
      'Trasierra',
      'Tous',
      'Totanés',
      'Totana',
      'Torrubia del Castillo',
      'Torrubia del Campo',
      'Torrox',
      'Torrijos',
      'Torrico',
      'Torrevieja',
      'Torres',
      'Torrequemada',
      'Torreperogil',
      'Torre-Pacheco',
      'Torreorgaz',
      'Torrenueva',
      'Torrent',
      'Torremolinos',
      'Torremocha',
      'Torremegía',
      'Torremayor',
      'Torrella',
      'Torrejón el Rubio',
      'Torrejoncillo del Rey',
      'Torrejoncillo',
      'Torredonjimeno',
      'Torre de Santa María',
      'Torre de Miguel Sesmero',
      'Torre del Mar',
      'Torre del Campo',
      'Torre de la Horadada',
      'Torre de Juan Abad',
      'Torrecillas de la Tiesa',
      'Torrecilla de la Jara',
      'Torre-Cardela',
      'Torrecampo',
      'Torreblascopedro',
      'Torre Alháquime',
      'Torralba de Oropesa',
      'Torralba del Pinar',
      'Torralba de Calatrava',
      'Tormos',
      'Toril',
      'Torás',
      'Tomelloso',
      'Tomares',
      'Tolox',
      'Tollos',
      'Toledo',
      'Tocina',
      'Tobarra',
      'Titaguas',
      'Tinajo',
      'Tíjola',
      'Tijarafe',
      'Tibi',
      'Tías',
      'Teulada',
      'Terrinches',
      'Terrateig',
      'Terque',
      'Teror',
      'Tembleque',
      'Telde',
      'Tejeda',
      'Teguise',
      'Tegueste',
      'Tébar',
      'Teba',
      'Tazacorte',
      'Tarifa',
      'Tarazona de la Mancha',
      'Tanque',
      'Tamurejo',
      'Táliga',
      'Tales',
      'Talayuelas',
      'Talayuela',
      'Talavera La Real',
      'Talavera de la Reina',
      'Talaván',
      'Talarrubias',
      'Tahal',
      'Tacoronte',
      'Tavernes de la Valldigna',
      'Tavernes Blanques',
      'Tabernas',
      'Sumacàrcer',
      'Suflí',
      'Sueca',
      'Sot de Ferrer',
      'Sot de Chera',
      'Sorvilán',
      'Sorihuela del Guadalimar',
      'Sorbas',
      'Soportújar',
      'Son Servera',
      'Sonseca',
      'Soneja',
      'Somontín',
      'Sóller',
      'Sollana',
      'Solana de los Barros',
      'Socuéllamos',
      'Socovos',
      'Sisante',
      'Siruela',
      'Sineu',
      'Sinarcas',
      'Simat de la Valldigna',
      'Silla',
      'Siles',
      'Siete Aguas',
      'Sierro',
      'Sierra de Yeguas',
      'Sierra de Fuentes',
      'Sevilleja de la Jara',
      'Sevilla',
      'Setenil de las Bodegas',
      'Ses Salines',
      'Serrejón',
      'Serrato',
      'Serradilla',
      'Serra',
      'Serón',
      'Senija',
      'Senyera',
      'Sempere',
      'Selva',
      'Sellent',
      'Sella',
      'Segura de León',
      'Segura de la Sierra',
      'Segorbe',
      'Segart',
      'Sedaví',
      'Sayalonga',
      'Sax',
      'Sauzal',
      'Saucedilla',
      'San Vicent del Raspeig',
      'San Vicente de Alcántara',
      'Santo Tomé',
      'Santomera',
      'Santisteban del Puerto',
      'Santiponce',
      'Santiago del Teide',
      'Santiago del Campo',
      'Santiago de la Ribera',
      'Santiago de Calatrava',
      'Es Migjorn Gran',
      'Santa Úrsula',
      'Santa Pola',
      'Santa Olalla del Cala',
      'Santanyí',
      'Santa Marta de Magasca',
      'Santa Marta',
      'Santa María del Campo Rus',
      'Santa Maria del Camí',
      'Santa Margalida',
      'Santa Lucía',
      'Santa Fe de Mondújar',
      'Santafé',
      'Santa Eulària des Riu',
      'Santa Eugènia',
      'Santa Eufemia',
      'Santaella',
      'Santa Elena',
      'Santa Cruz de Tenerife',
      'Santa Cruz de Mudela',
      'Santa Cruz de Moya',
      'Santa Cruz de los Cáñamos',
      'Santa Cruz de la Zarza',
      'Santa Cruz de la Sierra',
      'Santa Cruz de la Palma',
      'Santa Brígida',
      'Santa Bárbara de Casa',
      'Santa Ana la Real',
      'Santa Ana de Pusa',
      'Santa Ana',
      'Santa Amalia',
      'San Silvestre de Guzmán',
      'San Sebastián de los Ballesteros',
      'San Sebastián de la Gomera',
      'San Roque',
      'San Pedro de Mérida',
      'San Pedro del Pinatar',
      'San Pedro de Alcántara',
      'San Pedro',
      'San Nicolás del Puerto',
      'San Nicolás',
      'San Miguel de Salinas',
      'San Miguel De Abona',
      'San Martín de Pusa',
      'San Martín de Montalbán',
      'San Martín de Boniches',
      'Sant Lluís',
      'Sanlúcar la Mayor',
      'Sanlúcar de Guadiana',
      'Sanlúcar de Barrameda',
      'San Lorenzo de la Parrilla',
      'Sant Llorenç des Cardassar',
      'San Lorenzo de Calatrava',
      'San Juan del Puerto',
      'San Juan de la Rambla',
      'San Juan de Énova',
      'San Juan de Aznalfarache',
      'San Juan de Alicante',
      'Sant Joan de Labritja',
      'Sant Joan',
      'Playa de San Juan',
      'Sant Josep de sa Talaia',
      'San José',
      'San Javier',
      'San Isidro',
      'Sangonera la Verde',
      'Sant Francesc de Formentera',
      'San Fernando',
      'Sanet y Negrals',
      'La Laguna',
      'San Clemente',
      'Sencelles',
      'San Carlos del Valle',
      'Sant Carles de Peralta',
      'San Bartolomé de Tirajana',
      'San Bartolomé de la Torre',
      'San Bartolomé de las Abiertas',
      'San Bartolomé',
      'Sant Antoni de Portmany',
      'Salvatierra de Santiago',
      'Salvatierra de los Barros',
      'Salvaleón',
      'Salteras',
      'Salorino',
      'Salobreña',
      'Salobre',
      'Salinas',
      'Salares',
      'Santa Cruz',
      'Salar',
      'Sagunto',
      'Sagra',
      'Saelices',
      'Saceruela',
      'Sacañet',
      'Sabiote',
      'Rute',
      'Rus',
      'Ruidera',
      'Rugat',
      'Rubite',
      'Ruanes',
      'Rozalén del Monte',
      'Rótova',
      'Rotglá y Corbera',
      'Rota',
      'Rosalejo',
      'Rosal de la Frontera',
      'Roquetas de Mar',
      'Ronda',
      'Romangordo',
      'Rojales',
      'Rocafort',
      'Robledollano',
      'Robledo',
      'Robledillo de Trujillo',
      'Riópar',
      'Ríolobos',
      'Riola',
      'Rioja',
      'Ríogordo',
      'Rincón de la Victoria',
      'Rielves',
      'Ricote',
      'Ribera del Fresno',
      'Ribarroja del Turia',
      'Retuerta de Bullaque',
      'Requena',
      'Rena',
      'Relleu',
      'Reina',
      'Reíllo',
      'Redován',
      'Realejo Alto',
      'Real de Montroi',
      'Real de Gandía',
      'Rágol',
      'Ráfol de Salem',
      'Rafelguaraf',
      'Rafelcofer',
      'Rafal',
      'Rada de Haro',
      'Quintanar del Rey',
      'Quintanar de la Orden',
      'Quintana de la Serena',
      'Quesada',
      'Quesa',
      'Quero',
      'Quéntar',
      'Puçol',
      'Purullena',
      'Purchena',
      'Puntallana',
      'Puntagorda',
      'Punta de Mujeres',
      'Pulpí',
      'Pulianas',
      'Pulgar',
      'Pujerra',
      'Puigpunyent',
      'Puig',
      'Puerto Serrano',
      'Puerto Real',
      'Puertollano',
      'Puerto Lápice',
      'Puerto de San Vicente',
      'Puerto de Santa Cruz',
      'Puerto del Rosario',
      'Puerto de la Cruz',
      'Puente-Genil',
      'Puente de Génave',
      'La Pobla Llarga',
      'La Pobla de Vallbona',
      'Puebla de Sancho Pérez',
      'Puebla de Obando',
      'Puebla del Salvador',
      'Puebla del Prior',
      'Puebla del Príncipe',
      'Puebla del Maestre',
      'Puebla de la Reina',
      'Puebla de la Calzada',
      'Puebla de Guzmán',
      'Puebla de Don Rodrigo',
      'Puebla de Don Fadrique',
      'Puebla de Almenara',
      'Puebla de Alcocer',
      'Pruna',
      'Priego de Córdoba',
      'Prado del Rey',
      'Pozuelo de Calatrava',
      'Pozuelo',
      'Pozorrubio',
      'Pozohondo',
      'Pozo-Cañada',
      'Pozoblanco',
      'Pozoamargo',
      'Pozo Alcón',
      'Povedilla',
      'Potríes',
      'Posadas',
      'Porzuna',
      'Pórtugos',
      'Porto Cristo',
      'Portocolom',
      'Portezuelo',
      'Portaje',
      'Porreres',
      'Porcuna',
      'Polopos',
      'Polop',
      'Pollença',
      'Polinyà de Xúquer',
      'Polícar',
      'Polán',
      'Poblete',
      'Pliego',
      'Playa Blanca',
      'Plasenzuela',
      'Planes',
      'Pizarra',
      'Piqueras del Castillo',
      'Pinos Puente',
      'Pinoso',
      'Pinos Genil',
      'Pinet',
      'Pinarejo',
      'Piñar',
      'Piles',
      'Pilas',
      'Pilar de la Horadada',
      'Piedras Albas',
      'Piedrabuena',
      'Picón',
      'Picassent',
      'Picanya',
      'Pétrola',
      'Petrés',
      'Petra',
      'Pescueza',
      'Periana',
      'Peraleda de San Román',
      'Peraleda de la Mata',
      'Peñas de San Pedro',
      'Peñarroya-Pueblonuevo',
      'Peñalsordo',
      'Peñaflor',
      'Peligros',
      'Pegalajar',
      'Pedroso de Acim',
      'Pedro Muñoz',
      'Pedro Martínez',
      'Pedroche',
      'Pedro Abad',
      'Pedrera',
      'Pedreguer',
      'Pedralba',
      'Pechina',
      'Peal de Becerro',
      'Paymogo',
      'Pavías',
      'Paterna de Rivera',
      'Paterna del Río',
      'Paterna del Madera',
      'Paterna del Campo',
      'Paterna',
      'Partaloa',
      'Parcent',
      'Parauta',
      'Paradas',
      'Paracuellos',
      'Pampaneira',
      'Palos de la Frontera',
      'Palomas',
      'Palomares del Río',
      'Palomares del Campo',
      'Palmera',
      'Palma',
      'Palma del Río',
      'Palma de Gandía',
      'Palenciana',
      'Pajaroncillo',
      'Pajarón',
      'Pájara',
      'Paiporta',
      'Padules',
      'Padul',
      'Otura',
      'Otos',
      'Otívar',
      'Otero',
      'Osuna',
      'Ossa de Montiel',
      'Osa de la Vega',
      'Oropesa',
      'Órgiva',
      'Orihuela',
      'Oria',
      'Orgaz',
      'Orellana la Vieja',
      'Orcheta',
      'Orcera',
      'Orce',
      'Orba',
      'Ontur',
      'Ontígola',
      'Ontinyent',
      'Onil',
      'Ondara',
      'Onda',
      'Olvera',
      'Olula del Río',
      'Olula de Castro',
      'Olocau',
      'Olmedilla de Alarcón',
      'Olmeda del Rey',
      "L'Olleria",
      'Olivares de Júcar',
      'Olivares',
      'Oliva de Mérida',
      'Oliva de la Frontera',
      'Oliva',
      'Olías del Rey',
      'Ojós',
      'Ojén',
      'Ohanes',
      'Ogíjares',
      'Ocaña',
      'Obejo',
      'Nules',
      'Nueva-Carteya',
      'Novelda',
      'Nogales',
      'Noez',
      'Noblejas',
      'Noalejo',
      'Nívar',
      'Níjar',
      'Nigüelas',
      'Niebla',
      'Nerva',
      'Nerpio',
      'Nerja',
      'Nazaret',
      'Navezuelas',
      'Navas del Madroño',
      'Navas de Jorquera',
      'Navas de Estena',
      'Navarrés',
      'Navalvillar de Pela',
      'Navalvillar de Ibor',
      'Navalpino',
      'Navalmoralejo',
      'Navalmoral de la Mata',
      'Navajas',
      'Navahermosa',
      'Narboneta',
      'Náquera',
      'Nambroca',
      'Museros',
      'Murtas',
      'Muro del Alcoy',
      'Muro',
      'Murla',
      'Murcia',
      'Munera',
      'Mula',
      'Mutxamel',
      'Moya',
      'Motril',
      'Motilleja',
      'Motilla del Palancar',
      'Mota del Cuervo',
      'Mota de Altarejos',
      'Morón de la Frontera',
      'Moraira',
      'Moratalla',
      'Moraleda de Zafayona',
      'Moral de Calatrava',
      'Mora',
      'Monturque',
      'Montuïri',
      'Montroy',
      'Montoro',
      'Montizón',
      'Montillana',
      'Montilla',
      'Montijo',
      'Montiel',
      'Montichelvo',
      'Montesa',
      'Monterrubio de la Serena',
      'Montemolín',
      'Montemayor',
      'Montellano',
      'Montejicar',
      'Montejaque',
      'Montefrío',
      'Montecorto',
      'Montearagón',
      'Montealegre del Castillo',
      'Monteagudo de las Salinas',
      'Montánchez',
      'Montalvos',
      'Montalbo',
      'Montalbanejo',
      'Montalbán de Córdoba',
      'Montaberner',
      'Monserrat',
      'Monreal del Llano',
      'Monóvar',
      'Monforte del Cid',
      'Monesterio',
      'Monda',
      'Moncofa',
      'Moncada',
      'Monachil',
      'Molvízar',
      'Mollina',
      'Molinicos',
      'Es Molinar',
      'Molina de Segura',
      'Mojacar',
      'Mohedas de la Jara',
      'Moguer',
      'Mogente',
      'Mogán',
      'Moclinejo',
      'Moclín',
      'Mocejón',
      'Mislata',
      'Mirandilla',
      'Miramar',
      'Mirabel',
      'Mira',
      'Minglanilla',
      'Minaya',
      'Millena',
      'Millares',
      'Millanes',
      'Mijas',
      'Miguelturra',
      'Miguel Esteban',
      'Miajadas',
      'Mestanza',
      'Mesas de Ibor',
      'Mérida',
      'Mercadal',
      'Mengibar',
      'Mengabril',
      'Menasalbas',
      'Membrilla',
      'Melilla',
      'Meliana',
      'Medina Sidonia',
      'Medina de las Torres',
      'Medellín',
      'Mazo',
      'Mazarrón',
      'Mazarambroz',
      'Mazagón',
      'Matet',
      'Mata de Alcántara',
      'Maspalomas',
      'Masegoso',
      'Mascaraque',
      'Massamagrell',
      'Masalavés',
      'Martos',
      'Martín de la Jara',
      'Marratxí',
      'Marmolejo',
      'Marjaliza',
      'Marines',
      'Marinaleda',
      'Maria de la Salut',
      'María',
      'Marchena',
      'Marchal',
      'Marbella',
      'Maracena',
      'Manzanilla',
      'Manzaneque',
      'Manzanares',
      'Manuel',
      'Manises',
      'Manilva',
      'Mancor de la Vall',
      'Manchita',
      'Mancha Real',
      'Manacor',
      'Malpica',
      'Malpartida de Plasencia',
      'Malpartida de la Serena',
      'Malpartida de Cáceres',
      'Malcocinado',
      'Malagón',
      'Málaga',
      'Majadas',
      'Mairena del Aljarafe',
      'Mairena del Alcor',
      'Mahora',
      'Maó',
      'Maguilla',
      'Magán',
      'Magacela',
      'Madroñera',
      'Madrigueras',
      'Madrigalejo',
      'Madridejos',
      'Mácher',
      'Macastre',
      'Macael',
      'Luque',
      'Lupión',
      'Puerto Lumbreras',
      'Lújar',
      'Lugros',
      'Llocnou de Sant Jeroni',
      'Lucillos',
      'Llutxent',
      'Lucena del Puerto',
      'Lucena',
      'Lúcar',
      'Lucainena de las Torres',
      'Lubrín',
      'Los Yébenes',
      'Los Villares',
      'Los Silos',
      'Los Santos de Maimona',
      'Los Palacios y Villafranca',
      'Los Navalucillos',
      'Los Navalmorales',
      'Los Montesinos',
      'Los Molares',
      'Los Martínez',
      'Los Llanos de Aridane',
      'Los Hinojosos',
      'Los Gabatos',
      'Los Corrales',
      'Los Barrios',
      'Los Alcázares',
      'Losa del Obispo',
      'Lorquí',
      'Loriguilla',
      'Lorca',
      'Lora del Río',
      'Lora de Estepa',
      'Lopera',
      'Lo Pagán',
      'Lomo de Arico',
      'Loja',
      'Logrosán',
      'Lobras',
      'Lobón',
      'Llucmajor',
      'Llubí',
      'Lloseta',
      'Llosa de Ranes',
      'Lloret de Vistalegre',
      'Llombai',
      'Llerena',
      'Llera',
      'Llaurí',
      'Llanera de Ranes',
      'Llíria',
      'Linares de la Sierra',
      'Linares',
      'Lillo',
      'Liétor',
      'Librilla',
      'Lezuza',
      'Letur',
      'Lepe',
      'Lentegí',
      'Ledaña',
      'Lebrija',
      'Layos',
      'La Yesa',
      'La Victoria de Acentejo',
      'La Victoria',
      'La Unión',
      'Laujar de Andarax',
      'Las Torres de Cotillas',
      'Las Pedroñeras',
      'Las Palmas de Gran Canaria',
      'La Solana',
      'Las Mesas',
      'Las Cabezas de San Juan',
      'Larva',
      'Laroya',
      'La Romana',
      'La Roda de Andalucía',
      'La Roda',
      'La Roca de la Sierra',
      'La Rinconada',
      'La Rambla',
      'La Puerta de Segura',
      'La Pueblanueva',
      'La Puebla de Montalbán',
      'La Puebla del Río',
      'La Puebla de los Infantes',
      'La Puebla de Cazalla',
      'La Puebla de Almoradiel',
      'sa Pobla',
      'La Parra',
      'La Palma del Condado',
      'La Orotava',
      'La Oliva',
      'la Nucia',
      'Lanteira',
      'Lanjarón',
      'Landete',
      'La Mojonera',
      'La Matanza de Acentejo',
      'La Luisiana',
      'La Línea de la Concepción',
      'La Lantejuela',
      'La Iruela',
      'La Herradura',
      'La Haba',
      'La Guardia de Jaén',
      'La Guardia',
      'La Guancha',
      'La Gineta',
      'Lagartera',
      'La Garrovilla',
      "L'Eliana",
      'La Coronada',
      'La Codosera',
      'Láchar',
      'La Carolina',
      'La Carlota',
      'La Campana',
      'La Calzada de Calatrava',
      'La Antilla',
      'La Algaba',
      'La Albuera',
      'La Alberca de Záncara',
      'Júzcar',
      'Juviles',
      'Jun',
      'Jumilla',
      'Jubrique',
      'Jorquera',
      'Jódar',
      'Jimera de Líbar',
      'Jimena de la Frontera',
      'Jimena',
      'Xixona',
      'Jérica',
      'Jerez de los Caballeros',
      'Jerez de la Frontera',
      'Jérez del Marquesado',
      'Xeresa',
      'Jayena',
      'Javea',
      'Xàtiva',
      'Jaraicejo',
      'Jarafuel',
      'Xeraco',
      'Jamilena',
      'Jalón',
      'Jalance',
      'Jaén',
      'Jacarilla',
      'Jabugo',
      'Jabalquinto',
      'Iznatoraf',
      'Iznate',
      'Iznalloz',
      'Iznájar',
      'Ítrabo',
      'Istán',
      'Isla Cristina',
      'Instinción',
      'Iniesta',
      'Ingenio',
      'Infantes',
      'Inca',
      'Illora',
      'Illar',
      'Illán de Vacas',
      'Igualeja',
      'Icod de los Vinos',
      'Ibros',
      'Ibiza',
      'Ibi',
      'Ibahernando',
      'Humilladero',
      'Huétor Vega',
      'Huétor-Tájar',
      'Huétor Santillán',
      'Huéscar',
      'Huesa',
      'Huerta de Valdecarábanos',
      'Huerta de la Obispalía',
      'Huércal-Overa',
      'Huércal de Almería',
      'Huéneja',
      'Huelva',
      'Huelma',
      'Huélago',
      'Huécija',
      'Hoya-Gonzalo',
      'Hornachuelos',
      'Hornachos',
      'Horcajo de Santiago',
      'Hontecillas',
      'Hontanaya',
      'Hontanar',
      'Honrubia',
      'Hondón de los Frailes',
      'Hondón de las Nieves',
      'Holguera',
      'Hinojosas de Calatrava',
      'Hinojosa del Valle',
      'Hinojosa del Duque',
      'Hinojos',
      'Hinojares',
      'Hinojales',
      'Hinojal',
      'Higueruelas',
      'Higueruela',
      'Higueras',
      'Higuera la Real',
      'Higuera de Vargas',
      'Higuera de la Sierra',
      'Higuera de la Serena',
      'Higuera de Calatrava',
      'Higuera de Arjona',
      'Higuera',
      'Herreruela de Oropesa',
      'Herrera del Duque',
      'Herrera de Alcántara',
      'Herrera',
      'Hermigua',
      'Herguijuela',
      'Herencia',
      'Henarejos',
      'Hellín',
      'Haría',
      'Güimar',
      'Guillena',
      'Guía de Isora',
      'Guia',
      'Güevéjar',
      'Güéjar-Sierra',
      'Guarromán',
      'Guaro',
      'Guareña',
      'Guardamar del Segura',
      'Gualchos',
      'Guadix',
      'Guadassuar',
      'Guadasequies',
      'Guadamur',
      'Guadalupe',
      'Guadalmez',
      'Guadalcázar',
      'Guadalcanal',
      'Guadahortuna',
      'Grazalema',
      'Granja de Torrehermosa',
      'Granja de Rocamora',
      'Granadilla de Abona',
      'Granada',
      'Graja de Iniesta',
      'Graja de Campalbo',
      'Gorga',
      'Gor',
      'Golosalvo',
      'Gójar',
      'Godelleta',
      'Godella',
      'Gobernador',
      'Gines',
      'Gilet',
      'Gilena',
      'Gibraleón',
      'Gestalgar',
      'Gerindote',
      'Gérgal',
      'Gerena',
      'Genovés',
      'Génave',
      'Genalguacil',
      'Gelves',
      'Geldo',
      'Gaucín',
      'Gata de Gorgos',
      'Garvín',
      'Garrucha',
      'Garrovillas',
      'Garlitos',
      'Garbayuela',
      'Garafía',
      'Garachico',
      'Garaballa',
      'Gandia',
      'Gálvez',
      'Galisteo',
      'Galera',
      'Gáldar',
      'Galaroza',
      'Gaibiel',
      'Gádor',
      'Gavarda',
      'Gabaldón',
      'Fuerte del Rey',
      'Fuente Vaqueros',
      'Fuente-Tójar',
      'Fuentes de León',
      'Fuentes de Andalucía',
      'Fuentes',
      'Fuenterrobles',
      'Fuente Palmera',
      'Fuente Obejuna',
      'Fuentelespino de Moya',
      'Fuentelespino de Haro',
      'Fuente la Lancha',
      'La Font de la Figuera',
      'Fuenteheridos',
      'Fuente el Fresno',
      'Fuente de Piedra',
      'Fuente de Pedro Naharro',
      'Fuente del Maestre',
      'Fuente de Cantos',
      'Fuentealbilla',
      'Fuente-Álamo de Murcia',
      'Fuente-Álamo',
      'Fuensanta de Martos',
      'Fuensanta',
      'Fuenllana',
      'Fuenlabrada de los Montes',
      'Fuengirola',
      'Fuencaliente de la Palma',
      'Fuencaliente',
      'Frontera',
      'Frigiliana',
      'Fresnedoso de Ibor',
      'Fresneda de Altarejos',
      'Freila',
      'Fregenal de la Sierra',
      'Frailes',
      'Foios',
      'Fortuna',
      'Fortaleny',
      'Fornalutx',
      'Formentera de Segura',
      'Fontanars dels Alforins',
      'Fontanarejo',
      'Fonelas',
      'Fondón',
      'Firgas',
      'Finestrat',
      'Fines',
      'Fiñana',
      'Ferreries',
      'Ferreira',
      'Fernán-Núñez',
      'Feria',
      'Férez',
      'Felanitx',
      'Favara',
      'Faura',
      'Fasnia',
      'Faraján',
      'Famorca',
      'Facheca',
      'Estubeny',
      'Estivella',
      'Estepona',
      'Estepa',
      'Estellencs',
      'Esporles',
      'Espinoso del Rey',
      'Espiel',
      'Espera',
      'Espelúy',
      'Espejo',
      'Espartinas',
      'Esparragosa de la Serena',
      'Esparragosa de Lares',
      'Esparragalejo',
      'Esparragal',
      'Eslida',
      'Escúzar',
      'Escurial',
      'Escorca',
      'Escañuela',
      'Escalonilla',
      'Escacena del Campo',
      'Erustes',
      'Entrín Bajo',
      'Enix',
      'Enguídanos',
      'Enguera',
      'Encinas Reales',
      'Encinasola',
      'Emperador',
      'El Viso del Alcor',
      'El Viso',
      'El Varadero',
      'El Toboso',
      'El Saucejo',
      'El Rubio',
      'El Ronquillo',
      'El Rompido',
      'El Robledo',
      'El Puerto de Santa María',
      'El Puente del Arzobispo',
      'El Provencio',
      'Port de Sóller',
      'El Plan',
      'El Perelló',
      'El Pedernoso',
      'El Paso',
      'El Grao',
      'El Gastor',
      'El Ejido',
      'Elda',
      'El Cuervo',
      'El Coronil',
      'Elche de la Sierra',
      'Elche',
      'El Cerro de Andévalo',
      'El Castillo de las Guardas',
      'El Casar',
      'El Carpio de Tajo',
      'El Carpio',
      'El Bosque',
      'El Bonillo',
      "s'Arenal",
      'El Arahal',
      'El Altet',
      'Écija',
      'Dúrcal',
      'Dúdar',
      'Dos Torres',
      'Dos Hermanas',
      'Dosbarrios',
      'Dos Aguas',
      'Don Benito',
      'Doña Mencía',
      'Don Álvaro',
      'Domingo Pérez',
      'Domeño',
      'Dolores',
      'Dílar',
      'Diezma',
      'Deià',
      'Denia',
      'Deleitosa',
      'Deifontes',
      'Dehesas Viejas',
      'Dehesas de Guadix',
      'Daya Vieja',
      'Daya Nueva',
      'Darro',
      'Dalías',
      'Daimús',
      'Daimiel',
      'Cútar',
      'Cumbres Mayores',
      'Cullera',
      'Cúllar-Vega',
      'Cuevas de San Marcos',
      'Cuevas del Campo',
      'Cuevas del Becerro',
      'Cuevas del Almanzora',
      'Cuevas Bajas',
      'Cuerva',
      'Quatretonda',
      'Quartell',
      'Quart de Poblet',
      'Cristina',
      'Crevillente',
      'Cózar',
      'Cox',
      'Cotillas',
      'Cotes',
      'Costitx',
      'Cortes de Pallás',
      'Cortes de la Frontera',
      'Cortes de Baza',
      'Cortelazor',
      'Cortegana',
      'Corte de Peleas',
      'Corteconcepción',
      'Corral-Rubio',
      'Corralejo',
      'Corral de Calatrava',
      'Corral de Almaguer',
      'Coripe',
      'Coria del Río',
      'Coria',
      'Cordobilla de Lácara',
      'Córdoba',
      'Corbera',
      'Consuegra',
      'Constantina',
      'Consell',
      'Conquista de la Sierra',
      'Conquista',
      'Conil de la Frontera',
      'Confrides',
      'Cómpeta',
      'Comares',
      'Colònia de Sant Jordi',
      'Colomera',
      'Colmenar',
      "Coll d'en Rabassa",
      'Coín',
      'Cogollos de Guadix',
      'Cofrentes',
      'Cocentaina',
      'Cobisa',
      'Cóbdar',
      'Cobatillas',
      'Ciudad Real',
      'Ciruelos',
      'Cijuela',
      'Cieza',
      'Churriana de la Vega',
      'Chumillas',
      'Chulilla',
      'Chueca',
      'Chucena',
      'Chóvar',
      'Chiva',
      'Xirivella',
      'Chirivel',
      'Chipiona',
      'Chinchilla de Monte Aragón',
      'Chimeneas',
      'Chilluévar',
      'Chillón',
      'Chilches',
      'Chiclana de Segura',
      'Chiclana de la Frontera',
      'Cheste',
      'Chercos',
      'Chera',
      'Chelva',
      'Chella',
      'Cheles',
      'Chauchina',
      'Ceuti',
      'Cervera del Llano',
      'Cerdà',
      'Cenizate',
      'Cenes de la Vega',
      'Cehegín',
      'Cedillo',
      'Ceclavín',
      'Cebolla',
      'Cazorla',
      'Cazalla de la Sierra',
      'Cazalilla',
      'Caudete de las Fuentes',
      'Caudete',
      'Catral',
      'Catarroja',
      'Catadau',
      'Castuera',
      'Castro del Río',
      'Castro de Filabres',
      'Castril',
      'Castillo de Locubín',
      'Castillo de Garcimuñoz',
      'Castillejo de Iniesta',
      'Castilléjar',
      'Castilleja del Campo',
      'Castilleja de la Cuesta',
      'Castilleja de Guzmán',
      'Castilblanco de los Arroyos',
      'Castilblanco',
      'Castellonet de la Conquesta',
      'Castelló de Rugat',
      'Castelló de la Plana',
      'Castellnovo',
      'Castell de Ferro',
      'Castell de Castells',
      'Castellar de Santisteban',
      'Castellar de Santiago',
      'Castellar de la Frontera',
      'Cástaras',
      'Castañar de Ibor',
      'Castalla',
      'Casinos',
      'Casillas de Coria',
      'Casatejada',
      'Casasimarro',
      'Casas Ibáñez',
      'Casas de Ves',
      'Casas de Reina',
      'Casas de Miravete',
      'Casas de Millán',
      'Casas de los Pinos',
      'Casas de Lázaro',
      'Casas de Juan Núñez',
      'Casas de Haro',
      'Casas de Guijarro',
      'Casas de Garcimolina',
      'Casas de Fernando Alonso',
      'Casas de Don Pedro',
      'Casas de Don Antonio',
      'Casas de Benítez',
      'Casasbuenas',
      'Casariche',
      'Casares',
      'Casar de Cáceres',
      'Casarabonela',
      'Casabermeja',
      'Cartaya',
      'Estación de Cártama',
      'Cártama',
      'Cartajima',
      'Cartagena',
      'Carrizosa',
      'Carrizal',
      'Carrión de los Céspedes',
      'Carrión de Calatrava',
      'Carrícola',
      'Carriches',
      'Carratraca',
      'Carrascosa de Haro',
      'Carrascalejo',
      'Carmonita',
      'Carmona',
      'Carmena',
      'Carlet',
      'Cardenete',
      'Cardeña',
      'Càrcer',
      'Carcelén',
      'Carcaixent',
      'Carcabuey',
      'Carboneros',
      'Carboneras de Guadazaón',
      'Carboneras',
      'Carbajo',
      'Caravaca',
      'Carataunas',
      'Capilla',
      'Capileira',
      'Capdepera',
      'Cantoria',
      'Cantillana',
      'Can Picafort',
      'Canjáyar',
      'Canillas de Albaida',
      'Canillas de Aceituno',
      'Caniles',
      'Cañete la Real',
      'Cañete de las Torres',
      "Canet d'En Berenguer",
      'Canena',
      'Candelaria',
      'Cañaveral de León',
      'Cañaveral',
      'Cáñar',
      'Cañamero',
      'Canals',
      'Cañada Rosal',
      'Cañada del Hoyo',
      'Cañada de Calatrava',
      'Cañada',
      'Camuñas',
      'Campotéjar',
      'Campos del Río',
      'Campos',
      'Camporrobles',
      'Campofrío',
      'Campo de Criptana',
      'Campiña',
      'Campillos-Paravientos',
      'Campillos',
      'Campillo de Llerena',
      'Campillo de Deleitosa',
      'Campillo de Arenas',
      'Campillo de Altobuey',
      'El Campello',
      'Campanet',
      'Campanario',
      'Cambil',
      'Camas',
      'Calvià',
      'Calp',
      'Calonge',
      'Callosa de Segura',
      "Callosa d'En Sarrià",
      'Calles',
      'Calicasas',
      'Caleruela',
      'Calera y Chozas',
      'Calera de León',
      'Calasparra',
      'Cala Rajada',
      'Calañas',
      'Calamonte',
      'Cala',
      'Cájar',
      'Cadiz',
      'Cádiar',
      'Cachorrilla',
      'Cáceres',
      'Cabra del Santo Cristo',
      'Cabra',
      'Cabezas Rubias',
      'Cabezarados',
      'Cabezamesada',
      'Cabeza la Vaca',
      'Cabeza del Buey',
      'Cabañas de Yepes',
      'Cabañas del Castillo',
      'Busquístar',
      'Busot',
      'Burujón',
      'Burriana',
      'Burjassot',
      'Burguillos de Toledo',
      'Burguillos del Cerro',
      'Burguillos',
      'Bunyola',
      'Buñol',
      'Bullas',
      'Bujalance',
      'Búger',
      'Bugarra',
      'Bufali',
      'Buenavista del Norte',
      'Buenache de Alarcón',
      'Bubión',
      'Brozas',
      'Brenes',
      'Breña Baja',
      'Breña Alta',
      'Brazatortas',
      'Botija',
      'Bornos',
      'Bormujos',
      'Bonrepòs i Mirambell',
      'Boniches',
      'Bonete',
      'Bonares',
      'Bolulla',
      'Bollullos par del Condado',
      'Bollullos de la Mitación',
      'Bolbaite',
      'Bolaños de Calatrava',
      'Bohonal de Ibor',
      'Bogarra',
      'Bodonal de la Sierra',
      'Bocairent',
      'Blanca',
      'Binissalem',
      'Bigastro',
      'Bienvenida',
      'Bienservida',
      'Bicorp',
      'Biar',
      'Bétera',
      'Berrocalejo',
      'Berrocal',
      'Berlanga',
      'Berja',
      'Bérchules',
      'Benzú',
      'Bentarique',
      'Benquerencia',
      'Benizalón',
      'Benitagla',
      'Benitachell',
      'Benisuera',
      'Benissoda',
      'Benisanó',
      'Benissa',
      'Benirredrà',
      'Beniparrell',
      'Benimuslem',
      'Benimodo',
      'Benimeli',
      'Benimassot',
      'Benimarfull',
      'Benimantell',
      'Benillup',
      'Benilloba',
      'Benijofar',
      'Benigànim',
      'Beniflá',
      'Benifaió',
      'Benifallim',
      'Benifairó de les Valls',
      'Beniel',
      'Benidorm',
      'Benidoleig',
      'Benicolet',
      'Beniatjar',
      'Beniarrés',
      'Beniarjó',
      'Beniardá',
      'Beniarbeig',
      'Benferri',
      'Benetússer',
      'Benejúzar',
      'Beneixama',
      'Beneixida',
      'Benavites',
      'Benatae',
      'Benasau',
      'Benarrabá',
      'Benaoján',
      'Benaocaz',
      'Benamocarra',
      'Benamejí',
      'Benamaurel',
      'Benamargosa',
      'Benalúa de las Villas',
      'Benalúa de Guadix',
      'Benalmádena',
      'Benalauría',
      'Benahavís',
      'Benahadux',
      'Benaguasil',
      'Benagéber',
      'Benacazón',
      'Belvis de la Jara',
      'Belmontejo',
      'Belmonte',
      'Bélmez de la Moraleda',
      'Bélmez',
      'Bellús',
      'Bellreguard',
      'Bèlgida',
      'Belalcázar',
      'Beires',
      'Begíjar',
      'Bédar',
      'Betxí',
      'Beas de Segura',
      'Beas de Guadix',
      'Beas de Granada',
      'Beas',
      'Baza',
      'Bayarque',
      'Bayárcal',
      'Baterno',
      'Barrio de la Concepción',
      'Barrax',
      'Barlovento',
      'Barx',
      'Bargas',
      'Barcience',
      'Barchín del Hoyo',
      'Barxeta',
      'Barcarrota',
      'Barbate',
      'Baños de la Encina',
      'Banyalbufar',
      'Balsa de Ves',
      'Balones',
      'Balazote',
      'Bailén',
      'Baeza',
      'Baena',
      'Badolatosa',
      'Badajoz',
      'Azután',
      'Azuébar',
      'Azuaga',
      'Aznalcóllar',
      'Aznalcázar',
      'Ayora',
      'Ayódar',
      'Ayna',
      'Aielo de Malferit',
      'Ayamonte',
      'Atarfe',
      'Atamaría',
      'Atalaya del Cañavate',
      'Atalaya',
      'Atajate',
      'Aspe',
      'Arucas',
      'Artenara',
      'Artana',
      'Artà',
      'Arroyomolinos de León',
      'Arroyo de San Serván',
      'Arroyo del Ojanco',
      'Arroyo de la Miel',
      'Arroyo de la Luz',
      'Arriate',
      'Arrecife',
      'Arquillos',
      'Arona',
      'Aroche',
      'Armuña de Almanzora',
      'Armilla',
      'Arjonilla',
      'Arjona',
      'Cruce de Arinaga',
      'Ariany',
      'Arguisuelas',
      'Argamasilla de Calatrava',
      'Argamasilla de Alba',
      'Arenas de San Juan',
      'Arenas del Rey',
      'Arenas',
      'Ardales',
      'Arcos de las Salinas',
      'Arcos de la Frontera',
      'Archidona',
      'Árchez',
      'Archena',
      'Arboleas',
      'Arafo',
      'Aracena',
      'Antigua',
      'Antequera',
      'Antella',
      'Antas',
      'Añover de Tajo',
      'Añora',
      'Anna',
      'Andújar',
      'Andratx',
      'Andilla',
      'Anchuras',
      'Altura',
      'Altea',
      'Altarejos',
      'Alsodux',
      'Alquife',
      "L'Alqueria de la Comtessa",
      'Alpuente',
      'Alpera',
      'Alpandeire',
      'Alozaina',
      'Alosno',
      'Alora',
      'Almussafes',
      'Almuñécar',
      'Almudaina',
      'Almoradí',
      'Almonte',
      'Almonacid del Marquesado',
      'Almoines',
      'Almoharín',
      'Almogía',
      'Almodóvar del Río',
      'Almodóvar del Pinar',
      'Almodóvar del Campo',
      'Almócita',
      'Almiserà',
      'Almería',
      'Almensilla',
      'Almendricos',
      'Almendralejo',
      'Almendral',
      'Almenara',
      'Almegíjar',
      'Almedinilla',
      'Almedina',
      'Almedíjar',
      'Almassora',
      'Almargen',
      'Almaraz',
      'Almansa',
      'Almagro',
      'Almadén de la Plata',
      'Almadén',
      'Almáchar',
      'Aljucén',
      'Aljaraque',
      'Aliseda',
      'Alicún de Ortega',
      'Alicún',
      'Alicante',
      'Aliaguilla',
      'Alía',
      'Alhendín',
      'Alhaurín el Grande',
      'Alhaurín de la Torre',
      'Alhambra',
      'Alhama de Murcia',
      'Alhama de Granada',
      'Alhama de Almería',
      'Alhabia',
      'Algueña',
      'Alguazas',
      'Algorfa',
      'Algodonales',
      'Alginet',
      'Algimia de Almonacid',
      'Algimia de Alfara',
      'Algemesí',
      'Algeciras',
      'Algatocín',
      'Algarrobo',
      'Algarra',
      'Algarinejo',
      'Algar',
      'Algámitas',
      'Algaida',
      'Alfondeguilla',
      'Alfarrasí',
      'Alfarp',
      'Alfarnatejo',
      'Alfarnate',
      'Alfara del Patriarca',
      'Alfara de Algimia',
      'Alfauir',
      'Alfafara',
      'Alfafar',
      'Alfacar',
      'Aledo',
      'Aldeire',
      'Aldeaquemada',
      'Aldeanueva de San Bartolomé',
      'Aldeanueva de Barbarroya',
      'Aldea del Rey',
      'Aldea del Cano',
      'Aldeacentenera',
      'Aldaia',
      'Alcuéscar',
      'Alcudia de Veo',
      'Alcudia de Monteagud',
      "L'Alcúdia de Crespìns",
      "L'Alcúdia",
      'Alcúdia',
      'Alcublas',
      'Alcubillas',
      'Alcoy',
      'Alcóntar',
      'Alconera',
      'Alconchel de la Estrella',
      'Alconchel',
      'Alcollarín',
      'Alcolea de Tajo',
      'Alcolea del Río',
      'Alcolea de Calatrava',
      'Alcolea',
      'Alcocer de Planes',
      'Alcoba',
      'Alzira',
      'Alcázar de San Juan',
      'Alcaudete',
      'Alcaucín',
      'Alcaraz',
      'Alcaracejos',
      'Alcantarilla',
      'Alcàntera de Xúquer',
      'Alcántara',
      'Alcañizo',
      'Alcalalí',
      'Alcalá la Real',
      'Alcalá del Valle',
      'Alcalá de los Gazules',
      'Alcalá del Júcar',
      'Alcalá de Guadaira',
      'Alcadozo',
      'Alcàsser',
      'Alcabón',
      'Alburquerque',
      'Albuñuelas',
      'Albuñol',
      'Albuñán',
      'Albuixech',
      'Albudeite',
      'Albox',
      'Alborea',
      'Alboraya',
      'Alborache',
      'Albondón',
      'Albolote',
      'Alberic',
      'Albatera',
      'Albatana',
      'Albarreal de Tajo',
      'Albanchez',
      'Albalat dels Tarongers',
      'Albalat dels Sorells',
      'Albalat de la Ribera',
      'Albaladejo del Cuende',
      'Albaladejo',
      'Albalá',
      'Albal',
      'Albaida del Aljarafe',
      'Albaida',
      'Albacete',
      'Alaior',
      'Alatoz',
      'Alaró',
      'Alarcón',
      'Alanís',
      'Alange',
      'Alamillo',
      'Alamedilla',
      'Alameda',
      'Alajeró',
      'Alájar',
      'Alaquàs',
      'Ajofrín',
      'Ahillones',
      'Agulo',
      'Agullent',
      'Agüimes',
      'Águilas',
      'Aguilar',
      'Agudo',
      'Aigües',
      'Aguadulce',
      'Agrón',
      'Agres',
      'Agost',
      'Agaete',
      "Atzeneta d'Albaida",
      'Adsubia',
      'Adra',
      'Ador',
      'Adeje',
      'Adamuz',
      'Aceuchal',
      'Acedera',
      'Alcalá del Río',
      'Abrucena',
      'Abla',
      'Abertura',
      'Abenójar',
      'Abengibre',
      'Abarán',
      'Abanilla',
      'Punta Umbría',
      'Grao de Murviedro',
      'Zúñiga',
      'Zuñeda',
      'Zumaia',
      'Zumarraga',
      'Zugarramurdi',
      'Zuera',
      'Zucaina',
      'Zubieta',
      'Zotes del Páramo',
      'Zorraquín',
      'Zorita de los Canes',
      'Zorita del Maestrazgo',
      'Zorita de la Frontera',
      'Zazuar',
      'Zas',
      'Zarzuela del Pinar',
      'Zarzuela del Monte',
      'Zarzuela de Jadraque',
      'Zarzuela',
      'Zarzosa',
      'Zarzalejo',
      'Zarza de Tajo',
      'Zarza de Granadilla',
      'Zarratón',
      'Zarautz',
      'Zaratán',
      'Zaratamo',
      'Zarapicos',
      'Zaragoza',
      'Zapardiel de la Ribera',
      'Zapardiel de la Cañada',
      'Zaorejas',
      'Zamudio',
      'Zamora',
      'Zambrana',
      'Zamayón',
      'Zamarra',
      'Zaldibia',
      'Zaldibar',
      'Zaidín',
      'Zafrilla',
      'Zael',
      'Iurreta',
      'Yunquera de Henares',
      'Yuncos',
      'Yuncler',
      'Yésero',
      'Yesa',
      'Yelo',
      'Yeles',
      'Yélamos de Arriba',
      'Yélamos de Abajo',
      'Yecla de Yeltes',
      'Yebra de Basa',
      'Yebra',
      'Yebes',
      'Yanguas de Eresma',
      'Yanguas',
      'Igantzi',
      'Wamba',
      'Vozmediano',
      'Vizmanos',
      'Vizcaínos',
      'Viveiro',
      'Vivel del Río Martín',
      'Biure',
      'Gasteiz / Vitoria',
      'Vitigudino',
      'Vita',
      'Vistabella del Maestrazgo',
      'Vistabella',
      'Visiedo',
      'Vinuesa',
      'Viñuelas',
      'Viniegra de Arriba',
      'Viniegra de Abajo',
      'Viñegra de Moraña',
      'Vinebre',
      'Vindel',
      'Viñas',
      'Vinaròs',
      'Vinaixa',
      'Vimianzo',
      'Vimbodí',
      'Vilviestre del Pinar',
      'Vilvestre',
      "Vilobí d'Onyar",
      'Vilobí del Penedès',
      'Viloria de Rioja',
      'Viloria',
      'Vilopriu',
      'Villovieco',
      'Villota del Páramo',
      'Villoslada de Cameros',
      'Villoruela',
      'Villoruebo',
      'Villoría',
      'Villores',
      'Villoldo',
      'Villodrigo',
      'Villodre',
      'Villel de Mesa',
      'Villel',
      'Villeguillo',
      'Villegas',
      'Villazopeque',
      'Villazanzo de Valderaduey',
      'Villazala',
      'Vilaión',
      'Villaviudas',
      'Villavieja de Yeltes',
      'Villavieja del Lozoya',
      'Villaviciosa de Odón',
      'Villaviciosa',
      'Villavicencio de los Caballeros',
      'Villaveza de Valverde',
      'Villaveza del Agua',
      'Villaverde-Mogina',
      'Villaverde de Rioja',
      'Villaverde de Montejo',
      'Villaverde de Medina',
      'Villaverde del Monte',
      'Villaverde de Guareña',
      'Villaverde',
      'Villavendimio',
      'Villavellid',
      'Villavelayo',
      'Villavaquerín',
      'Villaumbrales',
      'Villaturiel',
      'Villaturde',
      'Villatuerta',
      'Villatuelda',
      'Villatoro',
      'Villastar',
      'Villasrubias',
      'Villasila de Valdavia',
      'Villasexmir',
      'Villaselán',
      'Villaseco de los Reyes',
      'Villaseco de los Gamitos',
      'Villaseca de Uceda',
      'Villaseca de Henares',
      'Villaseca de Arciel',
      'Villasdardo',
      'Villasbuenas de Gata',
      'Villasbuenas',
      'Villasayas',
      'Villasarracino',
      'Villasandino',
      'Villasana de Mena',
      'Villasabariego',
      'Villarta-Quintana',
      'Villarroya de los Pinares',
      'Villarroya del Campo',
      'Villarroya de la Sierra',
      'Villarroya',
      'Villarrín de Campos',
      'Urretxu',
      'Villarreal de Huerva',
      'Villarramiel',
      'Villarrabé',
      'Villarquemado',
      'Areatza',
      'Villarmuerto',
      'Villarmentero de Esgueva',
      'Villarmentero de Campos',
      'Villarmayor',
      'Villarluengo',
      'Villarino de los Aires',
      'Vilariño',
      'Villariezo',
      'Villares de Yeltes',
      'Villares de Órbigo',
      'Villares de la Reina',
      'Villares de Jadraque',
      'Villarejo de Salvanés',
      'Villarejo de Órbigo',
      'Villarejo del Valle',
      'Villarejo de la Peñuela',
      'Villarejo',
      'Villardondiego',
      'Villárdiga',
      'Villardiegua de la Ribera',
      'Villar de Torre',
      'Villar de Samaniego',
      'Villar de Plasencia',
      'Villar de Peralonso',
      'Villar de Olalla',
      'Villar del Salz',
      'Villar del Río',
      'Villar de los Navarros',
      'Villar del Olmo',
      'Villar del Infantado',
      'Villar del Cobo',
      'Villar del Campo',
      'Villar del Buey',
      'Villar de la Yegua',
      'Villar del Ala',
      'Villar de Gallimazo',
      'Villardefrades',
      'Villar de Fallaves',
      'Villar de Domingo García',
      'Villar de Corneja',
      'Villardeciervos',
      'Villar de Ciervo',
      'Villaralbo',
      'Villaquirán de los Infantes',
      'Villaquirán de la Puebla',
      'Villaquilambre',
      'Villaquejida',
      'Villaprovedo',
      'Villaobispo de Otero',
      'Villanuño de Valdavia',
      'Vilanova i la Geltrú',
      'Villanueva de Viver',
      'Villanueva de Valdegovía',
      'Villanueva de Teba',
      'Villanueva de Sigena',
      'Villanueva de San Mancio',
      'Villanueva de Perales',
      'Villanueva de Oscos',
      'Villanueva del Rebollar de la Sierra',
      'Villanueva del Rebollar',
      'Villanueva del Pardillo',
      'Villanueva de los Caballeros',
      'Villanueva del Conde',
      'Villanueva del Campo',
      'Villanueva del Campillo',
      'Villanueva de la Vera',
      'Villanueva de la Torre',
      'Villanueva de las Peras',
      'Villanueva de las Manzanas',
      'Villanueva de la Sierra',
      'Villanueva de la Condesa',
      'Villanueva del Aceral',
      'Villanueva de la Cañada',
      'Villanueva de Jiloca',
      'Villanueva de Gumiel',
      'Villanueva de Gormaz',
      'Villanueva de Gómez',
      'Villanueva de Gállego',
      'Villanueva de Duero',
      'Villanueva de Carazo',
      'Villanueva de Campeán',
      'Villanueva de Cameros',
      'Villanueva de Azoague',
      'Vilanova de Arousa',
      'Villanueva de Argecilla',
      'Villanueva de Argaño',
      'Villanueva de Alcorón',
      'Villanubla',
      'Villanúa',
      'Villangómez',
      'Villán de Tordesillas',
      'Villanázar',
      'Villamuriel de Cerrato',
      'Villamuriel de Campos',
      'Villamuera de la Cueza',
      'Villamoronta',
      'Villamor de los Escuderos',
      'Villamoratiel de las Matas',
      'Villamontán de la Valduerna',
      'Villamol',
      'Villamiel de la Sierra',
      'Villamiel',
      'Villameriel',
      'Villamejil',
      'Villamedianilla',
      'Villamediana de Iregua',
      'Villamediana',
      'Villamayor de Treviño',
      'Villamayor de Monjardín',
      'Villamayor de los Montes',
      'Villamayor de Campos',
      'Villamayor',
      'Villamartín de Don Sancho',
      'Villamartín de Campos',
      'Villamantilla',
      'Villamanta',
      'Villamanrique de Tajo',
      'Villamanín',
      'Villamandos',
      'Villamañán',
      'Villaluenga de la Vega',
      'Villalube',
      'Villalpando',
      'Villalonso',
      'Villalón de Campos',
      'Villalobos',
      'Villalobón',
      'Villalobar de Rioja',
      'Villalmanzo',
      'Villalengua',
      'Villaldemiro',
      'Villalcón',
      'Villalcázar de Sirga',
      'Villalcampo',
      'Villalbilla de Gumiel',
      'Villalbilla de Burgos',
      'Villalbilla',
      'Villalbarba',
      'Villalba de Rioja',
      'Villalba de Perejil',
      'Villalba del Rey',
      'Villalba de los Llanos',
      'Vilalba dels Arcs',
      'Villalba de los Alcores',
      'Villalba de la Sierra',
      'Villalba de la Loma',
      'Villalba de la Lampreana',
      'Villalba de Guardo',
      'Villalba de Duero',
      'Vilalba',
      'Villalazán',
      'Villalar de los Comuneros',
      'Villalán de Campos',
      'Villalaco',
      'Vilaxoán',
      'Villahoz',
      'Villaherreros',
      'Villahermosa del Río',
      'Villahermosa del Campo',
      'Villahán',
      'Villagonzalo-Pedernales',
      'Villagonzalo de Tormes',
      'Villagómez la Nueva',
      'Villageriz',
      'Villagatón',
      'Villagarcía de Campos',
      'Vilagarcía de Arousa',
      'Villagalijo',
      'Villafufre',
      'Villafuerte',
      'Villafruela',
      'Villafrechós',
      'Ordizia',
      'Vilafranca del Penedès',
      'Villafranca del Cid',
      'Villafranca del Campo',
      'Villafranca del Bierzo',
      'Villafranca de la Sierra',
      'Villafranca de Ebro',
      'Villafranca de Duero',
      'Villafranca',
      'Villafrades de Campos',
      'Villaflores',
      'Villaflor',
      'Villaferrueña',
      'Villafeliche',
      'Villafamés',
      'Villafáfila',
      'Villaespasa',
      'Villaescusa la Sombría',
      'Villaescusa de Roa',
      'Villaescusa',
      'Villaeles de Valdavia',
      'Villadoz',
      'Villadiego',
      'Villadepera',
      'Villademor de la Vega',
      'Villa del Prado',
      'Villa del Campo',
      'Villadecanes',
      'Villadangos del Páramo',
      'Villada',
      'Villaconejos de Trabaque',
      'Villaconejos',
      'Villaconancio',
      'Villaco',
      'Villaciervos',
      'Villacid de Campos',
      'Villacidaler',
      'Villacastín',
      'Villacarriedo',
      'Villacarralón',
      'Villabuena del Puente',
      'Eskuernaga / Villabuena de Álava',
      'Villabrázaro',
      'Villabraz',
      'Villabrágima',
      'Villabona',
      'Villablino',
      'Villabaruz de Campos',
      'Villabáñez',
      'Atarrabia',
      'Vileña',
      'Vila-seca',
      'Vilaseca',
      'Vilasantar',
      'Vila-sacra',
      'Vilaplana',
      'Vilanova de Sau',
      'Vilanova de Prades',
      'Vilanova del Camí',
      "Vilanova d'Escornalbou",
      'Vilanova de Bellpuig',
      'Vilanant',
      'Vilamaniscle',
      'Vilamalla',
      'Vilamacolum',
      'Vilajuïga',
      'Vilafant',
      'Viladrau',
      'Viladasens',
      'Vilademuls',
      'Viladecans',
      'Viladecavalls',
      'Vilada',
      'Vilablareix',
      'Vilabertran',
      'Vilabella',
      'Viguera',
      'Vigo',
      'Vierlas',
      'Vielha',
      'Vidreres',
      'Vidrà',
      'Videmala',
      'Vidayanes',
      'Vic',
      'Vicálvaro',
      'Viandar de la Vera',
      'Viana de Jadraque',
      'Viana de Duero',
      'Viana de Cega',
      'Viana',
      'Vezdemarbán',
      'Vertavillo',
      'Verín',
      'Verges',
      'Bergara',
      'Verea',
      'Verdú',
      'Vera de Moncayo',
      'Bera',
      'Venturada',
      'Ventrosa',
      'Ventosa del Río Almar',
      'Ventosa de la Cuesta',
      'Ventosa',
      'Ventalló',
      'Venta de Baños',
      'Venialbo',
      'El Vendrell',
      'Velliza',
      'Vellisca',
      'Velilla de San Antonio',
      'Velilla del Río Carrión',
      'Velilla de los Ajos',
      'Velilla de Jiloca',
      'Velilla de Ebro',
      'Velilla',
      'Velayos',
      'Velascálvaro',
      'Velamazán',
      'Veguillas de la Sierra',
      'Vegas de Matute',
      'Vegaquemada',
      'Veganzones',
      'Vegalatrave',
      'Vega de Villalobos',
      'Vega de Valdetronco',
      'Vega de Valcarce',
      'Vega de Tirados',
      'Vega de Tera',
      'Vega de Santa María',
      'Vega de Ruiponce',
      'Vega de Pas',
      'Vegadeo',
      'Vega de Infanzones',
      'Vega de Espinareda',
      'Vegacervera',
      'Vedra',
      'Vecinos',
      'Valverdón',
      'Valverde-Enrique',
      'Valverde de Valdelacasa',
      'Valverde de los Arroyos',
      'Valverde del Majano',
      'Valverde del Fresno',
      'Valverde de la Virgen',
      'Valverde de la Vera',
      'Valverde de Campos',
      'Valverde de Alcalá',
      'Valtorres',
      'Valtierra',
      'Valtiendas',
      'Valtajeros',
      'Valtablado del Río',
      'Valseca',
      'Valsalobre',
      'Valsalabroso',
      'Valpalmas',
      'Valoria la Buena',
      'Valmojado',
      'Balmaseda',
      'Valmala',
      'Valmadrid',
      'Valluércanes',
      'Valls',
      'Vallromanes',
      'Vallmoll',
      'Vallirana',
      'Vallibona',
      'Vallgorguina',
      'Vallfogona de Riucorb',
      'Vallfogona de Balaguer',
      'Valles de Palenzuela',
      'Vallesa de la Guareña',
      'Valleruela de Sepúlveda',
      'Valleruela de Pedraza',
      'Vallelado',
      'Vallejera de Riofrío',
      'Valle de Tabladillo',
      'Valle de Cerrato',
      'Vallecillo',
      'Vallclara',
      'Vallcebre',
      'Vallbona de les Monges',
      'Vallarta de Bureba',
      'Vallanca',
      'Valladolid',
      'Valjunquera',
      'Valhermoso',
      'Valgañón',
      'Valga',
      'Valfermoso de Tajuña',
      'Valfarta',
      'Valero',
      'Valencia de Don Juan',
      'Valdunquillo',
      'Valdunciel',
      'Valdoviño',
      'Valdorros',
      'Valdilecha',
      'Valdezate',
      'Valdevimbre',
      'Valdevacas de Montejo',
      'Valdetorres de Jarama',
      'Valdestillas',
      'Valdesotos',
      'Valdescorriel',
      'Val de San Martín',
      'Val de San Lorenzo',
      'Valdesamario',
      'Valderrueda',
      'Valderrodrigo',
      'Valderrodilla',
      'Valderrobres',
      'Valderrey',
      'Valderrebollo',
      'Valderrábano',
      'Valderas',
      'Valdeprados',
      'Valdeprado',
      'Valdepolo',
      'Valdepiélagos',
      'Valdepiélago',
      'Valdepeñas de la Sierra',
      'Valdeolmos',
      'Valdeolmillos',
      'Valdeolivas',
      'Valdeobispo',
      'Valdenebro de los Valles',
      'Valdenebro',
      'Valdemoro-Sierra',
      'Valdemoro',
      'Valdemorillo de la Sierra',
      'Valdemorillo',
      'Valdemora',
      'Valdemierque',
      'Valdemeca',
      'Valdemaqueda',
      'Valdemanco',
      'Valdemaluque',
      'Valdemadera',
      'Valdeltormo',
      'Valdelosa',
      'Valdelinares',
      'Valdelcubo',
      'Valdelagua del Cerro',
      'Valdelageve',
      'Valdelacasa',
      'Valdehorna',
      'Valdehijaderos',
      'Valdegrudas',
      'Valdefuentes de Sangusín',
      'Valdefuentes del Páramo',
      'Valdefresno',
      'Valdefinjas',
      'Valdecuenca',
      'Valdeconcha',
      'Valdecasa',
      'Valdecarros',
      'Valdeaveruelo',
      'Valdeavero',
      'Valdeavellano de Tera',
      'Valdeavellano',
      'Valdearenas',
      'Valdearcos de la Vega',
      'Valdeande',
      'Valdealgorfa',
      'Valdastillas',
      'Valdarachas',
      'Valdaracete',
      'Valcabado',
      'Valbuena de Pisuerga',
      'Valbuena de Duero',
      'Valbona',
      'Valacloche',
      'Vadocondes',
      'Vadillo de la Sierra',
      'Vadillo de la Guareña',
      'Vadillo',
      'Rivas-Vaciamadrid',
      'Utrillas',
      'Uterga',
      'Utebo',
      'Utande',
      'Usurbil',
      'Used',
      'Urzainqui',
      'Uruñuela',
      'Urueñas',
      'Urueña',
      'Urriés',
      'Urrea de Jalón',
      'Urones de Castroponce',
      'Urnieta',
      'Urdiales del Páramo',
      'Urdazubi / Urdax',
      'Unzué',
      'Undués de Lerda',
      'Uncastillo',
      'Uña de Quintana',
      'Uña',
      'Umbrías',
      'Ultramort',
      'Ullastret',
      'Ulldemolins',
      'Ulldecona',
      'Ullastrell',
      'Ullà',
      'Ujué',
      'Ujados',
      'Ugena',
      'Ucero',
      'Uceda',
      'Tui',
      'Turégano',
      'Turcia',
      'Tulebras',
      'Tudelilla',
      'Tudela de Duero',
      'Tudela',
      'Tubilla del Lago',
      'Tubilla del Agua',
      'Truchas',
      'Tronchón',
      'Triollo',
      'Trillo',
      'Trijueque',
      'Trigueros del Valle',
      'Tricio',
      'Treviana',
      'Tresviso',
      'Trespaderne',
      'Trescasas',
      'Tremp',
      'Tremedal de Tormes',
      'Trefacio',
      'Trazo',
      'Traspinedo',
      'Trasobares',
      'Trasmoz',
      'Trasmiras',
      'Tramaced',
      'Tramacastilla',
      'Tramacastiel',
      'Traiguera',
      'Trabazos',
      'Trabanca',
      'Trabadelo',
      'Trabada',
      'Touro',
      'Tossa de Mar',
      'Tosos',
      'Toses',
      'Tosantos',
      'Tortuero',
      'Tortuera',
      'Tortosa',
      'Tórtoles',
      'Tórtola de Henares',
      'Torrubia de Soria',
      'Torrubia',
      'Torroella de Montgrí',
      'Torroella de Fluvià',
      'Torrijo del Campo',
      'Torrijo de la Cañada',
      'Torrijas',
      'Torrevelilla',
      'Torre Val de San Pedro',
      'Torresmenudas',
      'Torres de Segre',
      'Torres del Río',
      'Torres del Carrizal',
      'Torres de la Alameda',
      'Torres de Berrellén',
      'Torres de Barbués',
      'Torres de Alcanadre',
      'Torres de Albarracín',
      'Torrescárcela',
      'Torresandino',
      'Torremormojón',
      'Torremontalbo',
      'Torremochuela',
      'Torremocha del Pinar',
      'Torremocha del Campo',
      'Torremocha de Jarama',
      'Torremocha de Jadraque',
      'Torremenga',
      'Torre los Negros',
      'Torrelodones',
      'Torrelobatón',
      'Torrelles de Llobregat',
      'Torrellas',
      'Torrelavega',
      'Torrelara',
      'Torrelapaja',
      'Torrelaguna',
      'Torrejón de Velasco',
      'Torrejón del Rey',
      'Torrejón de la Calzada',
      'Torrejón de Ardoz',
      'Torreiglesias',
      'Torrehermosa',
      'Torregamones',
      'Torregalindo',
      'Torrefarrera',
      'Torre en Cameros',
      'Torre de Peñafiel',
      'Torredembarra',
      'Torre del Compte',
      'Torre del Burgo',
      'Torre del Bierzo',
      'Torre de las Arcas',
      'Torre de Esgueva',
      'Torre de Don Miguel',
      'Torre de Arcas',
      'Torrecuadradilla',
      'Torrecuadrada de Molina',
      'Torrecilla sobre Alesanco',
      'Torrecilla en Cameros',
      'Torrecilla del Rebollar',
      'Torrecilla del Pinar',
      'Torrecilla de los Ángeles',
      'Torrecilla del Monte',
      'Torrecilla de la Torre',
      'Torrecilla de la Orden',
      'Torrecilla de la Abadesa',
      'Torrecilla de Alcañiz',
      'Torrechiva',
      'Torrecaballeros',
      'Torreblanca',
      'Torreblacos',
      'Torrebesses',
      'Torreadrada',
      'Torralbilla',
      'Torralba de Ribota',
      'Torralba de los Sisones',
      'Torralba de los Frailes',
      'Torralba de Aragón',
      'Torralba',
      'Torquemada',
      'Toro',
      'Tornos',
      'Tornavacas',
      'Tornadizos de Ávila',
      'Tornabous',
      'Tormón',
      'Tormellas',
      'Tormantos',
      'Torlengua',
      'Torija',
      'Toreno',
      'Torelló',
      'Tordómar',
      'Tordillos',
      'Tordesilos',
      'Tordesillas',
      'Tordera',
      'Tordelrábano',
      'Tordellego',
      'Tordehumos',
      'Toral de los Guzmanes',
      'Torà de Riubregós',
      'Topas',
      'Tona',
      'Tomiño',
      'Tolosa',
      'Toloriu',
      'Tolocirio',
      'Tolbaños',
      'Toga',
      'Toén',
      'Todolella',
      'Tobía',
      'Tobed',
      'Tobar',
      'Tivissa',
      'Tivenys',
      'Tiurana',
      'Titulcia',
      'Tirgo',
      'Tirapu',
      'Tiñosillos',
      'Tineo',
      'Tinajas',
      'Tierzo',
      'Tierz',
      'Tielmes',
      'Tiedra',
      'Tiana',
      'Teià',
      'Tetuán de las Victorias',
      'Terzaga',
      'Teruel',
      'Terroba',
      'Terriente',
      'Terrer',
      'Terradillos de Esgueva',
      'Terradillos',
      'Terrades',
      'Térmens',
      'Teo',
      'Tenebrón',
      'Tendilla',
      'Tejeda y Segoyuela',
      'Tejeda de Tiétar',
      'Tejado',
      'Tejadillos',
      'Tejada',
      'Tavertet',
      'Tauste',
      'Tartanedo',
      'Tàrrega',
      'Terrassa',
      'Tarragona',
      'Taroda',
      'Tardienta',
      'Tardelcuende',
      'Tardajos',
      'Tardáguila',
      'Tarazona de Guareña',
      'Tarazona',
      'Taravilla',
      'Tarancón',
      'Taramundi',
      'Taragudo',
      'Taradell',
      'Tapioles',
      'Tapia de Casariego',
      'Tamarón',
      'Tamariz de Campos',
      'Tamarit de Llitera / Tamarite de Litera',
      'Tamames',
      'Tamajón',
      'Talamantes',
      'Talamanca de Jarama',
      'Talamanca',
      'Tajueco',
      'Tajahuerce',
      'Tagamanent',
      'Tafalla',
      'Tabuenca',
      'Taboadela',
      'Taboada',
      'Tabera de Abajo',
      'Tábara',
      'Tabanera la Luenga',
      'Tabanera de Valdavia',
      'Tabanera de Cerrato',
      'Susqueda',
      'Susinos del Páramo',
      'Súria',
      'Sunyer',
      'Sunbilla',
      'Suellacabras',
      'Subirats',
      'Suances',
      'Sotresgudo',
      'Sotragero',
      'Soto y Amío',
      'Sotoserrano',
      'Sotosalbos',
      'Soto en Cameros',
      'Sotodosos',
      'Soto del Barco',
      'Soto de la Vega',
      'Soto de Cerrato',
      'Sotobañado y Priorato',
      'Sotillo del Rincón',
      'Sotillo de las Palomas',
      'Sotillo de la Ribera',
      'Sotillo de la Adrada',
      'Sotillo',
      'Sotalbo',
      'Soses',
      'Sos del Rey Católico',
      'Sorzano',
      'Sort',
      'Sorlada',
      'Sorihuela',
      'Soria',
      'Sordillos',
      'Sora',
      'Sopela',
      'Porto do Son',
      'Somozas',
      'Somosierra',
      'Somolinos',
      'Solsona',
      'Solosancho',
      'Solórzano',
      'Soliedra',
      'Solarana',
      'Solanillos del Extremo',
      'Sojuela',
      'Sobrado',
      'Sobradillo',
      'Sobradiel',
      'Sobradelo',
      'Sober',
      'Sitges',
      'Sisamón',
      'Sinlabajos',
      'Singra',
      'Simancas',
      'Sils',
      'Silleda',
      'Sigüés',
      'Sigüenza',
      'Sigeres',
      'Siete Iglesias de Trabancos',
      'Siétamo',
      'Sierra-Engarcerán',
      'Sierra de Luna',
      'Sienes',
      'Sidamon',
      'Sevilla La Nueva',
      'Seva',
      'Setiles',
      'Sesué',
      'Sestrica',
      'Sestao',
      'Sesma',
      'Seseña',
      'Sesa',
      'Serranillos del Valle',
      'Serranillos',
      'Serradilla del Llano',
      'Serradilla del Arroyo',
      'Serra de Daró',
      'Serrada',
      'Seròs',
      'Sequeros',
      'Sequera de Fresno',
      'Sepúlveda',
      "La Seu d'Urgell",
      'Seno',
      'Sentmenat',
      'Senés de Alcubierre',
      'Senan',
      'Sena de Luna',
      'Semillas',
      'Selaya',
      'Selas',
      'Seira',
      'Segurilla',
      'Segura de Toro',
      'Segura de los Baños',
      'Segura',
      'Segovia',
      'Sediles',
      'Secastilla',
      'Sebúlcor',
      'Sayatón',
      'Sauquillo de Cabezas',
      'Saucelle',
      'Saúca',
      'Sástago',
      'Sasamón',
      'Sartajada',
      'Sartaguda',
      'Sarrión',
      'Sarrià de Ter',
      'Sarria',
      'Sarreaus',
      'Sarratella',
      'Sarracín',
      'Saro',
      'Sariñena',
      'Sariegos',
      'Sariego',
      'Sargentes de la Lora',
      'Sardón de los Frailes',
      'Sardón de Duero',
      'Cerdanyola del Vallès',
      'Sanzoles',
      'San Vitero',
      'Sant Vicenç de Montalt',
      'San Vicente del Valle',
      'Sant Vicenç dels Horts',
      'San Vicente del Palacio',
      'San Vicente de la Sonsierra',
      'San Vicente de la Cabeza',
      'San Vicente de la Barquera',
      'Sant Vicenç de Castellet',
      'Barakaldo',
      'San Vicente de Arévalo',
      'Santurdejo',
      'Santurtzi',
      'Sants',
      'Santoyo',
      'Santovenia de Pisuerga',
      'Santovenia',
      'Santo Tomé de Zabarcos',
      'San Torcuato',
      'Santorcaz',
      'Santoña',
      'Santo Domingo de Silos',
      'Santo Domingo de Pirón',
      'Santo Domingo de las Posadas',
      'Santo Domingo de la Calzada',
      'Sant Just Desvern',
      'Sant Julià de Vilatorta',
      'Sant Joan les Fonts',
      'Sant Joan de Vilatorrada',
      'Santiz',
      'Santiuste de San Juan Bautista',
      'Santiuste',
      'Santiurde de Toranzo',
      'Santiurde de Reinosa',
      'Santiso',
      'Santillana',
      'Santibáñez el Bajo',
      'Santibáñez el Alto',
      'Santibáñez de Vidriales',
      'Santibáñez de Valcorba',
      'Santibáñez de Tera',
      'Santibáñez del Val',
      'Santibáñez de la Sierra',
      'Santibáñez de la Peña',
      'Santibáñez de Ecla',
      'Santibáñez de Béjar',
      'Santiago Millas',
      'Santiago del Collado',
      'Santiago de la Puebla',
      'Santiago de Compostela',
      'Doneztebe-Santesteban',
      'Santervás de la Vega',
      'Santervás de Campos',
      'Santed',
      'Santa Susanna',
      'Santas Martas',
      'Santa Perpètua de Mogoda',
      'Santa Pau',
      'Santa Oliva',
      'Santa Olalla de Bureba',
      'Santa Olalla',
      'Sant Aniol de Finestres',
      'Santander',
      'Santa Marta de Tormes',
      'Santa Marta de Ortigueira',
      'Santa Marta del Cerro',
      'Santa Marina del Rey',
      'Santa María la Real de Nieva',
      'Santa María de Valverde',
      'Santa María de Sando',
      'Santa Maria de Palautordera',
      'Santa María de Ordás',
      "Santa Maria d'Oló",
      'Santa María del Val',
      'Santa María del Páramo',
      'Santa María de los Caballeros',
      'Santa María del Monte de Cea',
      'Santa María del Invierno',
      'Santa María del Campo',
      'Santa María del Berrocal',
      'Santa María de la Vega',
      'Santa María de las Hoyas',
      'Santa María de la Isla',
      'Santa María de la Alameda',
      'Santa María de Huerta',
      'Santa Maria de Corcó',
      'Santa María de Cayón',
      'Barberà del Vallès',
      'Santa Magdalena de Pulpis',
      'Santa Inés',
      'Santa Gadea del Cid',
      'Santa Eulàlia de Ronçana',
      'Santa Eulalia de Oscos',
      'Santa Eulalia de Gállego',
      'Santa Eulalia Bajera',
      'Santa Eulalia',
      'Santa Eugènia de Berga',
      'Santa Uxía de Ribeira',
      'Santa Eufemia del Barco',
      'Santa Eufemia del Arroyo',
      'Santa Elena de Jamuz',
      'Santa Cruz de Yanguas',
      'Santa Cruz de Pinares',
      'Santa Cruz de Paniagua',
      'Santa Cruz de Nogueras',
      'Santa Cruz de Moncayo',
      'Santa Cruz del Valle Urbión',
      'Santa Cruz del Valle',
      'Santa Cruz del Retamar',
      'Santa Cruz de la Serós',
      'Santa Cruz de la Salceda',
      'Santa Cruz de Grío',
      'Santa Cruz de Boedo',
      'Santa Cruz de Bezana',
      'Santa Croya de Tera',
      'Santa Cristina de Valmadrigal',
      'Santa Cristina de la Polvorosa',
      "Santa Cristina d'Aro",
      'Santa Comba',
      'Santa Colomba de Somoza',
      'Santa Colomba de las Monjas',
      'Santa Colomba de Curueño',
      'Santa Coloma de Queralt',
      'Santa Coloma de Gramenet',
      'Santa Coloma de Farners',
      'Santa Coloma de Cervelló',
      'Santa Coloma',
      'Santa Clara de Avedillo',
      'Santa Cecília de Voltregà',
      'Santa Cecilia del Alcor',
      'Santa Cecilia',
      'Santacara',
      'Santa Bárbara',
      'Sansol',
      'San Sebastián de los Reyes',
      'Donostia / San Sebastián',
      'San Sadurniño',
      'San Salvador',
      "Sant Sadurní d'Anoia",
      'San Román de la Cuba',
      'San Román de Hornija',
      'San Román de Cameros',
      'San Román',
      'Sant Quirze Safaja',
      'Sant Quirze del Vallès',
      'Sant Pol de Mar',
      'San Pelayo de Guareña',
      'San Pelayo',
      'Sant Pere Pescador',
      'San Pedro Palmiches',
      'San Pedro Manrique',
      'Sant Pere de Vilamajor',
      'San Pedro de Rozados',
      'Sant Pere de Riudebitlles',
      'Sant Pere de Ribes',
      'Premià de Dalt',
      'San Pedro del Valle',
      'San Pedro del Romeral',
      'San Pedro de Latarce',
      'San Pedro del Arroyo',
      'San Pedro de Gaíllos',
      'San Pedro de Ceque',
      'San Pedro Bercianos',
      'San Pascual',
      'San Pablo de la Moraleja',
      'San Muñoz',
      'Sant Mori',
      'San Morales',
      'San Millán de Yécora',
      'San Millán de los Caballeros',
      'San Millán de Lara',
      'San Millán de la Cogolla',
      'San Miguel de Valero',
      'San Miguel de Serrezuela',
      'San Miguel de Meruelo',
      'San Miguel del Pino',
      'San Miguel del Arroyo',
      'San Miguel de la Ribera',
      'Sant Miquel de Fluvià',
      'San Miguel de Corneja',
      'Sant Miquel de Campmajor',
      'San Miguel de Aguayo',
      'San Mateo de Gállego',
      'Sant Martí Vell',
      'Sant Martí Sarroca',
      'San Martín de Valvení',
      'San Martín de Valderaduey',
      'San Martín de Valdeiglesias',
      'San Martín de Unx',
      'San Martín de Trevejo',
      'Sant Martí de Tous',
      'San Martín de Rubiales',
      'San Martín de Oscos',
      'San Martín del Río',
      'San Martín del Pimpollar',
      'San Martín del Castañar',
      'San Martín de la Vega del Alberche',
      'San Martín de la Vega',
      'San Martín de Elines',
      'Sant Martí de Centelles',
      'San Mamés de Campos',
      'San Mamés de Burgos',
      'San Lorenzo de Tormes',
      'Sant Llorenç de la Muga',
      "Sant Llorenç d'Hortons",
      'San Lorenzo de El Escorial',
      'San Llorente',
      'San Leonardo de Yagüe',
      'San Justo de la Vega',
      'San Justo',
      'San Julián de Muskiz',
      'Sant Julià de Cerdanyola',
      'Vilassar de Mar',
      'Sant Joan Despí',
      'San Juan de Moró',
      'Sant Joan de Mollet',
      'San Juan del Monte',
      'San Juan del Molinillo',
      'Sant Joan de les Abadesses',
      'San Juan de la Nava',
      'San Juan de la Encinilla',
      'Sant Jordi',
      'Sant Jordi Desvalls',
      'Sant Jaume dels Domenys',
      'San Ildefonso',
      'Sant Hilari Sacalm',
      'Sangüesa/Zangoza',
      'Sant Gregori',
      'Sanxenxo',
      'Sangarrén',
      'San García de Ingelmos',
      'Sangarcía',
      'Sant Fruitós de Bages',
      'Sant Ferriol',
      'San Fernando de Henares',
      'Sant Feliu Sasserra',
      'Sant Feliu de Pallerols',
      'Sant Feliu de Llobregat',
      'Sant Feliu de Guíxols',
      'Sant Feliu de Codines',
      'San Felices de los Gallegos',
      'San Felices',
      'San Esteban de Nogales',
      'San Esteban del Valle',
      'San Esteban de los Patos',
      'San Esteban del Molar',
      'San Esteban de la Sierra',
      'San Esteban de Gormaz',
      'San Emiliano',
      'Sando',
      'Sant Cugat del Vallès',
      'San Cristóbal de Segovia',
      'San Cristóbal de la Vega',
      'San Cristóbal de la Polantera',
      'San Cristóbal de la Cuesta',
      'San Cristóbal de Entreviñas',
      'San Cristóbal de Cuéllar',
      'San Cristóbal de Boedo',
      'Sanchotello',
      'Sanchorreja',
      'Sanchonuño',
      'Sanchón de la Sagrada',
      'Sanchón de la Ribera',
      'Sanchidrián',
      'Sant Celoni',
      'Sancedo',
      'San Cebrián de Mudá',
      'San Cebrián de Mazote',
      'San Cebrián de Castro',
      'San Cebrián de Campos',
      'Sant Carles de la Ràpita',
      'Sant Boi de Lluçanès',
      'Sant Boi de Llobregat',
      'San Bartolomé de Corneja',
      'San Bartolomé de Béjar',
      'San Asensio',
      'San Antolín',
      'Sant Andreu Salou',
      'Sant Andreu',
      'San Andrés del Rey',
      'San Andrés del Rabanedo',
      'Sant Andreu de Llavaneres',
      'San Andrés del Congosto',
      'Sant Andreu de la Barca',
      'San Amaro',
      'San Agustín del Pozo',
      'San Agustín del Guadalix',
      'San Agustín',
      'San Adrián del Valle',
      'San Adrián de Juarros',
      'Sant Adrià de Besòs',
      'San Adrián',
      'Sant Iscle de Vallalta',
      'Samper del Salz',
      'Samper de Calanda',
      'Santpedor',
      'Samos',
      'Samir de los Caños',
      'Samboal',
      'Samaniego',
      'Sama',
      'Salvatierra de Tormes',
      'Salvatierra de Miño',
      'Salvatierra de Esca',
      'Agurain / Salvatierra',
      'Salvador de Zapardiel',
      'Salvadiós',
      'Salvacañete',
      'Salt',
      'Salou',
      'Salomó',
      'Salobral',
      'Salmoral',
      'Salmerón',
      'Sallent de Gállego',
      'Sallent',
      'Salinillas de Bureba',
      'Salinas de Pisuerga',
      'Salinas de Oro',
      'Salinas del Manzano',
      'Leintz-Gatzaga',
      'Salillas de Jalón',
      'Salillas',
      'Salduero',
      'Saldón',
      'Saldías',
      'Saldes',
      'Saldeana',
      'Saldaña de Burgos',
      'Saldaña',
      'Salcedillo',
      'Salce',
      'Salas de los Infantes',
      'Sales de Llierca',
      'Salas de Bureba',
      'Salas Bajas',
      'Salas Altas',
      'Salas',
      'Salamanca',
      'Sajazarra',
      'Sahagún',
      'Sagàs',
      'Saelices el Chico',
      'Saelices de Mayorga',
      'Saelices de la Sal',
      'Sádaba',
      'Sada',
      'Sacramenia',
      'Sacedón',
      'Saceda-Trasierra',
      'Sacecorbo',
      'Sabiñánigo',
      'Sabero',
      'Sabadell',
      'Rupià',
      'Ruesga',
      'Ruesca',
      'Ruente',
      'Rueda de la Sierra',
      'Rueda',
      'Rucandio',
      'Rublacedo de Abajo',
      'Rubielos de Mora',
      'Rubielos de la Cérida',
      'Rubí de Bracamonte',
      'Rubiales',
      'Rubí',
      'Rubena',
      'Rozas de Puerto Real',
      'Royuela de Río Franco',
      'Royuela',
      'Roturas',
      'Roses',
      'O Rosal',
      'Roperuelos del Páramo',
      'Romanos',
      'Romanones',
      'Romanillos de Atienza',
      'Rollán',
      'Rollamienta',
      'Rojas',
      'Rois',
      'Rodezno',
      'Ródenas',
      'Rodeiro',
      'Roda de Eresma',
      'Roda de Barà',
      'Robres del Castillo',
      'Robres',
      'Robregordo',
      'Robliza de Cojos',
      'Robledo de Corpes',
      'Robledo de Chavela',
      'Robledillo de Mohernando',
      'Robledillo de la Vera',
      'Robledillo de la Jara',
      'Robledillo de Gata',
      'Robleda-Cervantes',
      'Robleda',
      'Robladillo',
      'Roales',
      'Roa',
      'Rivilla de Barajas',
      'Riumors',
      'Riudoms',
      'Riudellots de la Selva',
      'Riudecols',
      'Riudecanyes',
      'Riudarenes',
      'Ripollet',
      'Ripoll',
      'Ríotorto',
      'Riós',
      'Ríofrío de Aliste',
      'Riodeva',
      'Riocavado de la Sierra',
      'Rincón de Soto',
      'Rillo de Gallo',
      'Rillo',
      'Errigoiti',
      'Riells i Viabrea',
      'Riego de la Vega',
      'Ricla',
      'Ribota',
      'Ribesalbes',
      'Riberos de la Cueza',
      'Ribeira',
      'Ribatejada',
      'Luintra',
      'Ribes de Freser',
      'Ribas de Campos',
      'Ribaforada',
      'Ribadumia',
      'Ribadesella',
      'Riba de Saelices',
      'Ribadeo',
      'Ribadavia',
      'Riaza',
      'Riaño',
      'Rianxo',
      'Riaguas de San Bartolomé',
      'Reznos',
      'Rezmondo',
      'Reyero',
      'Revillarruz',
      'Revilla del Campo',
      'Revilla de Collazos',
      'Revenga de Campos',
      'Revellinos',
      'Reus',
      'Retuerta',
      'Retortillo de Soria',
      'Retortillo',
      'Retiendas',
      'Retascón',
      'Respenda de la Peña',
      'Requena de Campos',
      'Requejo',
      'Reocín',
      'Errenteria',
      'Renieblas',
      'Renera',
      'Renedo de la Vega',
      'Renau',
      'Remondo',
      'Remolinos',
      'Rello',
      'Reinoso de Cerrato',
      'Reinoso',
      'Reinosa',
      'Regumiel de la Sierra',
      'Regueras de Arriba',
      'Errezil',
      'Regencós',
      'Redueña',
      'Redondela',
      'Redecilla del Campo',
      'Redecilla del Camino',
      'Recuerda',
      'Recas',
      'Rebollosa de Jadraque',
      'Rebollo',
      'Rebolledo de la Torre',
      'Rasueros',
      'Rasquera',
      'Rasines',
      'Rascafría',
      'Rapariegos',
      'Ranón',
      'Ramiro',
      'Ramales de la Victoria',
      'Rajadell',
      'Rairiz de Veiga',
      'Rágama',
      'Ráfales',
      'Rabós',
      'Rabé de las Calzadas',
      'Rábanos',
      'Rábano de Aliste',
      'Rábano',
      'Rabanera del Pinar',
      'Rabanera',
      'Rabanales',
      'Rábade',
      'Quismondo',
      'Quiruelas de Vidriales',
      'Quiroga',
      'Quinto',
      'Quintela de Leirado',
      'Quintanilla de Urz',
      'Quintanilla de Trigueros',
      'Quintanilla de Onsoña',
      'Quintanilla de Onésimo',
      'Quintanilla del Olmo',
      'Quintanilla del Monte',
      'Quintanilla del Molar',
      'Quintanilla del Coco',
      'Quintanilla de la Mata',
      'Quintanilla de Arriba',
      'Quintana y Congosto',
      'Quintanavides',
      'Quintanas de Gormaz',
      'Quintana Redonda',
      'Quintanar de la Sierra',
      'Quintanapalla',
      'Quintanaortuño',
      'Quintanaélez',
      'Quintana del Puente',
      'Quintana del Pidio',
      'Quintana del Marco',
      'Quintana del Castillo',
      'Quintanabureba',
      'Quijorna',
      'Quicena',
      'Querol',
      'Quer',
      'Quemada',
      'Quel',
      "Quart d'Onyar",
      'Purujosa',
      'Puras',
      'Punxín',
      'Pujalt',
      'Puig-reig',
      'Puigpelat',
      'Puigcerdà',
      'Pueyo de Santa Cruz',
      'Pueyo',
      'Puerto Seguro',
      'Puertomingalvo',
      'Portomarín',
      'Puértolas',
      'Puerto de Béjar',
      'Puerto Castilla',
      'Puertas',
      'Puente Viesgo',
      'As Pontes de García Rodríguez',
      'Puente Nuevo',
      'Puente la Reina',
      'Puentedura',
      'Puente de Vallecas',
      'Puente del Congosto',
      'Puente de Domingo Flórez',
      'Ponte Caldelas',
      'Ponteareas',
      'Pueblica de Valverde',
      'Puebla de Yeltes',
      'Pobra de Trives',
      'Puebla de San Miguel',
      'Puebla de San Medel',
      'Puebla de Sanabria',
      'Puebla de Pedraza',
      'Puebla de Lillo',
      'A Pobra do Caramiñal',
      'A Pobra do Brollon',
      'Puebla de Beleña',
      'Puebla de Azaba',
      'Puebla de Arenoso',
      'Puebla de Alfindén',
      'Puebla de Albortón',
      'Proaza',
      'Prioro',
      'Priego',
      'Priaranza del Bierzo',
      'Presencio',
      'Premià de Mar',
      'Préjano',
      'Pravia',
      'Prats de Lluçanès',
      'Prados Redondos',
      'Pradosegar',
      'Pradoluengo',
      'Prado de la Guzpeña',
      'Prado',
      'Pradillo',
      'Pradilla de Ebro',
      'Prades',
      'Prádena del Rincón',
      'Prádena de Atienza',
      'Prádena',
      'Pradejón',
      'Prádanos de Ojeda',
      'Prádanos de Bureba',
      'Pradales',
      'Pozuelo de Zarzón',
      'Pozuelo de Tábara',
      'Pozuelo del Rey',
      'Pozuelo del Páramo',
      'Pozuelo de la Orden',
      'Pozuelo de Aragón',
      'Pozuelo de Alarcón',
      'Pozuel del Campo',
      'Pozuel de Ariza',
      'Pozos de Hinojo',
      'Pozondón',
      'Pozo de Urama',
      'Pozo de Guadalajara',
      'Pozo de Almoguera',
      'Pozoantiguo',
      'Pozán de Vero',
      'Pozanco',
      'Pozalmuro',
      'Pozal de Gallinas',
      'Poza de la Vega',
      'Poza de la Sal',
      'Poio',
      'Poyales del Hoyo',
      'Poveda de la Sierra',
      'Poveda de las Cintas',
      'Poveda',
      'Potes',
      'Posada de Valdeón',
      'Portugalete',
      'Portonovo',
      'Porto',
      'Portillo de Toledo',
      'Portillo de Soria',
      'Portillo',
      'Portilla',
      'Portell de Morella',
      'Portbou',
      'Portas',
      'Porriño',
      'Porqueres',
      'Porqueira',
      'Pontós',
      'Pontils',
      'Pontevedra',
      'el Pont de Suert',
      'Pont de Molins',
      'Ponts',
      'Ponferrada',
      'Pomer',
      'Pomar de Valdivia',
      'Pollos',
      'Polinyà',
      'Polentinos',
      'Poleñino',
      'Pola de Somiedo',
      'Pola de Siero',
      'Pola de Lena',
      'Pola de Laviana',
      'Pola de Allande',
      'Pol',
      'Pobladura de Valderaduey',
      'Pobladura de Pelayo García',
      'Pobladura del Valle',
      'la Pobla de Massaluca',
      'Población de Cerrato',
      'Población de Campos',
      'Población de Arroyo',
      'el Poal',
      'Plou',
      'Plentzia',
      'Plenas',
      'Pleitas',
      'Plasencia de Jalón',
      'Plasencia',
      'Planoles',
      'Plan',
      'el Pla del Penedès',
      'El Pla de Santa Maria',
      'Pizarral',
      'Pitillas',
      'Pitiegua',
      'Pitarque',
      'Piracés',
      'Piqueras',
      'Pioz',
      'Piornal',
      'Pinto',
      'Pinseque',
      'Piñor',
      'Pinofranqueado',
      'Pino del Río',
      'Pinillos',
      'Pinilla de Toro',
      'Pinilla de Molina',
      'Pinilla del Valle',
      'Pinilla de los Moros',
      'Pinilla de los Barruecos',
      'Pinilla del Campo',
      'Pinilla de Jadraque',
      'Piñel de Arriba',
      'Piñel de Abajo',
      'Pinedas',
      'Pineda de la Sierra',
      'Pineda de Gigüela',
      'Pineda de Mar',
      'Pinarnegrillo',
      'Pinarejos',
      'Pina de Montalgrao',
      'Piña de Esgueva',
      'Pina de Ebro',
      'Piña de Campos',
      'Piloña',
      'Piérnigas',
      'Piera',
      'Piedratajada',
      'Piedras Blancas',
      'Piedramillera',
      'Piedralaves',
      'Piedrahita de Castro',
      'Pías',
      'Pezuela de las Torres',
      'Petín',
      'Petilla de Aragón',
      'Pesquera',
      'Pesoz',
      'Pertusa',
      'Perosillo',
      'Peromingo',
      'Perilla de Castro',
      'Pereruela',
      'Peralada',
      'Pereiro de Aguiar',
      'Perdiguera',
      'Peranzanes',
      'Peralveche',
      'Peraltilla',
      'Peralta',
      'Perales de Tajuña',
      'Perales del Puerto',
      'Perales del Alfambra',
      'Perales',
      'Peralejos de las Truchas',
      'Peralejos de Arriba',
      'Peralejos de Abajo',
      'Peralejos',
      'Peral de Arlanza',
      'Peracense',
      'Peque',
      'Peníscola',
      'Penelles',
      'Peñausende',
      'Peñarandilla',
      'Peñaranda de Duero',
      'Peñaranda de Bracamonte',
      'Peñaparda',
      'Peñalver',
      'Peñalén',
      'Peñalba de Ávila',
      'Peñalba',
      'Penagos',
      'Peñaflor de Hornija',
      'Peñafiel',
      'Peñacaballera',
      'Peleas de Abajo',
      'Peleagonzalo',
      'Pelayos del Arroyo',
      'Pelayos de la Presa',
      'Pelayos',
      'Pelarrodríguez',
      'Pelahustán',
      'Pelabravo',
      'Peguerinos',
      'Pedroso',
      'Pedrosillo el Ralo',
      'Pedrosillo de los Aires',
      'Pedrosillo de Alba',
      'Pedrosa del Rey',
      'Pedrosa del Príncipe',
      'Pedrosa del Páramo',
      'Pedrosa de la Vega',
      'Pedrosa de Duero',
      'Pedrola',
      'Pedro Bernardo',
      'Pedrezuela',
      'Pedraza de Campos',
      'Pedraza de Alba',
      'Pedraza',
      'Pedrajas de San Esteban',
      'Pazuengos',
      'Payo de Ojeda',
      'Pau',
      'Patones',
      'Pastrana',
      'Pastoriza',
      'Pastores',
      'Pascualcobo',
      'Parrillas',
      'Parres',
      'Parlavà',
      'Parla',
      'Parets del Vallès',
      'Pareja',
      'Paredes de Sigüenza',
      'Paredes de Nava',
      'Paredes de Escalona',
      'Paredes',
      'Pardos',
      'Pardilla',
      'Páramo del Sil',
      'Páramo de Boedo',
      'O Páramo',
      'Paradinas de San Juan',
      'Paradela',
      'Parada de Rubiales',
      'Parada de Arriba',
      'Paracuellos de la Ribera',
      'Paracuellos de Jiloca',
      'Paracuellos de Jarama',
      'Papatrigo',
      'Pantón',
      'Pantoja',
      'Paniza',
      'Pancrudo',
      'Pancorbo',
      'Pamplona',
      'Pampliega',
      'Pals',
      'Palomero',
      'Palomeque',
      'Palomar de Arroyos',
      'Palol de Revardit',
      'Palo',
      'Palmeira',
      'Pálmaces de Jadraque',
      'Pallejà',
      'els Pallaresos',
      'Palenzuela',
      'Palencia de Negrilla',
      'Palencia',
      'Palazuelos de Muñó',
      'Palazuelos de la Sierra',
      'Palazuelos de Eresma',
      'Palazuelo de Vedija',
      'Palau-sator',
      'Palanques',
      'Palamós',
      'Palafrugell',
      'Palafolls',
      'Palaciosrubios',
      'Palacios de Sanabria',
      'Palacios del Sil',
      'Palacios del Pan',
      'Palacios de la Valduerna',
      'Palacios de la Sierra',
      'Palacios del Arzobispo',
      'Palacios de Goda',
      'Pajares de los Oteros',
      'Pajares de la Lampreana',
      'Pajares de la Laguna',
      'Pajares de Adaja',
      'Pajarejos',
      'Padrones de Bureba',
      'Padrón',
      'Padrenda',
      'Padilla de Arriba',
      'Padilla de Abajo',
      'Padiernos',
      'Paderne',
      'Oion / Oyón',
      'Oia',
      'Oviedo',
      'Outes',
      'Outeiro',
      'Ourol',
      'Outeiro de Rei',
      'Otero de Herreros',
      'Otero de Bodas',
      'Oteiza',
      'Ossó de Sió',
      'Osornillo',
      'Oseja de Sajambre',
      'Oseja',
      'Os de Balaguer',
      'Ortuella',
      'Ortigosa de Pestaño',
      'Ortigosa del Monte',
      'Ortigosa',
      'Òrrius',
      'Orrios',
      'Orpí',
      'Oroso',
      'Oropesa del Mar',
      'Oronz',
      'Ormaiztegi',
      'Oristà',
      'Orísoain',
      'Orís',
      'Orio',
      'Orihuela del Tremedal',
      'Orés',
      'Orera',
      'Ourense',
      'Orendain',
      'Orexa',
      'Orea',
      'Urduña / Orduña',
      'Ordis',
      'Ordes',
      'Orkoien',
      'Orcajo',
      'Orbita',
      'Orbara',
      'Oquillas',
      'Onzonilla',
      'Ontiñena',
      'Ondarroa',
      'Oncala',
      'Oñati',
      'Oña',
      'Oltza',
      'Olvés',
      'Olvega',
      'Olot',
      'Olost',
      'Olóriz',
      'Olombrada',
      'Olocau del Rey',
      'Olmos de Peñafiel',
      'Olmos de Ojeda',
      'Olmos de Esgueva',
      'Olmillos de Muñó',
      'Olmillos de Castro',
      'Olmedo de Camaces',
      'Olmedo',
      'Olmedillo de Roa',
      'Olmedilla de Eliz',
      'Olmeda de la Cuesta',
      'Olmeda de Cobeta',
      'Olloniego',
      'Ollauri',
      'Olivella',
      'Olivares de Duero',
      'Oliva de Plasencia',
      'Olius',
      'Olite',
      'Oliete',
      'Oliana',
      'Olesa de Montserrat',
      'Olesa de Bonesvalls',
      'Olejua',
      'Oleiros',
      'Olea de Boedo',
      'Olvan',
      'Olba',
      'Olazagutía',
      'Olaberria',
      'Ojos Negros',
      'Ojos-Albos',
      'Ojacastro',
      'Oitz',
      'Oimbra',
      'Oencia',
      'Odón',
      'Òdena',
      'Ocón',
      'Oco',
      'Ochánduri',
      'Otxandio',
      'Ocentejo',
      'Obón',
      'Obanos',
      'Nuñomoral',
      'Nuño Gómez',
      'Numancia de la Sagra',
      'Nuez de Ebro',
      'Nuevo Baztán',
      'Nueva Villa de las Torres',
      'Nuévalos',
      'Nueno',
      'Noia',
      'Novillas',
      'Noviercas',
      'Novallas',
      'Novales',
      'Noreña',
      'Nonaspe',
      'Nombrevilla',
      'Nombela',
      'Nolay',
      'Noja',
      'Nogueruelas',
      'Nogueras',
      'Nogueira de Ramuín',
      'Nogal de las Huertas',
      'Niharra',
      'Nigüella',
      'Nigrán',
      'Nieva de Cameros',
      'Nieva',
      'Nestares',
      'Nepas',
      'Neila de San Miguel',
      'Neila',
      'Negrilla de Palencia',
      'Negreira',
      'Negredo',
      'Nebreda',
      'Nazar',
      'Navianos de Valverde',
      'Navia de Suarna',
      'Navia',
      'Navatejares',
      'Navatalgordo',
      'Navata',
      'Navasfrías',
      'Navas de Oro',
      'Navas del Rey',
      'Navas de Bureba',
      'Navascués',
      'Navàs',
      'Navarrevisca',
      'Navarrete',
      'Navarredondilla',
      'Navarredonda de la Rinconada',
      'Navaridas',
      'Navares de las Cuevas',
      'Navares de Enmedio',
      'Navares de Ayuso',
      'Navardún',
      'Navarcles',
      'Navaquesera',
      'Navamorcuende',
      'Navamorales',
      'Navaluenga',
      'Navalquejigo',
      'Navalperal de Tormes',
      'Navalperal de Pinares',
      'Navalosa',
      'Navalmoral de Béjar',
      'Navalmoral',
      'Navalmanzano',
      'Navalilla',
      'Navales',
      'Navaleno',
      'Navalcarnero',
      'Navalcán',
      'Navalagamella',
      'Navalafuente',
      'Navalacruz',
      'Naval',
      'Navajún',
      'Navahondilla',
      'Navafría',
      'Navaescurial',
      'Navadijos',
      'Nava de Sotrobal',
      'Nava de Roa',
      'Nava del Rey',
      'Nava del Barco',
      'Nava de la Asunción',
      'Nava de Francia',
      'Nava de Béjar',
      'Nava de Arévalo',
      'Navaconcejo',
      'Navacerrada',
      'Navacepedilla de Corneja',
      'Navacarros',
      'Nava',
      'Narros de Saldueña',
      'Narros de Matalayegua',
      'Narros del Puerto',
      'Narros del Castillo',
      'Narros',
      'Narrillos del Rebollar',
      'Narrillos del Álamo',
      'Narón',
      'Nalec',
      'Nalda',
      'Nájera',
      'Nafría de Ucero',
      'Mutiloa',
      'Muruzábal',
      'Muros de Nalón',
      'Muros',
      'Muro en Cameros',
      'Muro de Aguas',
      'Murillo el Fruto',
      'Murillo de Río Leza',
      'Murieta',
      'Muriel Viejo',
      'Muriel de la Fuente',
      'Murias de Paredes',
      'Murgia',
      'Murero',
      'Murchante',
      'Muras',
      'Mura',
      'Muñoveros',
      'Muñotello',
      'Muñosancho',
      'Muñopepe',
      'Muñopedro',
      'Muñomer del Peco',
      'Muñogrande',
      'Muñogalindo',
      'Munilla',
      'Muniesa',
      'Muñico',
      'Mungia',
      'Munébrega',
      'Mundaka',
      'Muñana',
      'Muiños',
      'Mugia',
      'Mugardos',
      'Muga de Sayago',
      'Muelas de los Caballeros',
      'Muel',
      'Muduex',
      'Mudá',
      'Mucientes',
      'Mozota',
      'Mozoncillo',
      'Mozárbez',
      'Moyuela',
      'Moià',
      'Mutriku',
      'Mota del Marqués',
      'Móstoles',
      'Mosqueruela',
      'Moscardón',
      'Mos',
      'Moros',
      'Moronta',
      'Morón de Almazán',
      'Moriscos',
      'Morille',
      'Morés',
      'Moreruela de Tábara',
      'Moreruela de los Infanzones',
      'Morentin',
      'Morenilla',
      'Morella',
      'Moreda Araba / Moreda de Álava',
      'Morcillo',
      'Moratinos',
      'Moratilla de los Meleros',
      'Moratalaz',
      'Morata de Tajuña',
      'Morata de Jiloca',
      'Morata de Jalón',
      'Morasverdes',
      'Moraña',
      'Moralzarzal',
      'Moralina',
      'Morales de Valverde',
      'Morales de Toro',
      'Morales del Vino',
      'Morales de Campos',
      'Moraleja de Sayago',
      'Moraleja de Matacabras',
      'Moraleja del Vino',
      'Moraleja de las Panaderas',
      'Moraleja de Enmedio',
      'Moraleja',
      'Moral de Sayago',
      'Moral de la Reina',
      'Moradillo de Roa',
      'Mora de Rubielos',
      'Monzón de Campos',
      'Monzón',
      'Mont-roig del Camp',
      'Mont-ral',
      'Montornès del Vallès',
      'Montorio',
      'Montón',
      'Montmeló',
      'Montferri',
      'Montesquiu',
      'Montesclaros',
      'Monterrubio de la Sierra',
      'Monterrubio de Armuña',
      'Monterrubio',
      'Monterroso',
      'Monterde de Albarracín',
      'Monterde',
      'Montenegro de Cameros',
      'Montemayor de Pililla',
      'Montemayor del Río',
      'Montejo de Tiermes',
      'Montejo de la Vega de la Serrezuela',
      'Montejo de la Sierra',
      'Montejo de Arévalo',
      'Montejo',
      'Montehermoso',
      'Montederramo',
      'Monteagudo del Castillo',
      'Monteagudo de las Vicarías',
      'Monteagudo',
      'Montblanc',
      'Montarrón',
      'Muntanyola',
      'Montanejos',
      'Montán',
      'Montamarta',
      'Montalbán',
      'Monsalupe',
      'Monsagro',
      'Monroyo',
      'Monreal del Campo',
      'Monreal de Ariza',
      'Monreal',
      'Monleras',
      'Monleón',
      'Monistrol de Montserrat',
      'Montgat',
      'Monforte de Moyuela',
      'Monforte de Lemos',
      'Monforte de la Sierra',
      'Monfero',
      'Monfarracinos',
      'Moneva',
      'Monegrillo',
      'Arrasate / Mondragón',
      'Mondoñedo',
      'Mondéjar',
      'Mondariz-Balneario',
      'Mondariz',
      'Moncalvillo',
      'Montcada i Reixac',
      'Monasterio de Vega',
      'Monasterio de Rodilla',
      'Monasterio de la Sierra',
      'Monasterio',
      'Mombuey',
      'Momblona',
      'Mombeltrán',
      'Mollet de Peralada',
      'Mollet del Vallès',
      'Mollerussa',
      'Molledo',
      'Molins de Rei',
      'Molinos de Duero',
      'Molinos',
      'Molinillo',
      'Molinaseca',
      'Molina de Aragón',
      'Molezuelas de la Carballeda',
      'Molacillos',
      'Mojados',
      'Mohernando',
      'Mogarraz',
      'Moeche',
      'Modúbar de la Emparedada',
      'Mochales',
      'Moaña',
      'Mironcillo',
      'Miravet',
      'Miraveche',
      'Miranda del Castañar',
      'Miranda de Ebro',
      'Miranda de Azán',
      'Miranda de Arga',
      'Mirambel',
      'Miralrío',
      'Miralcamp',
      'Mirafuentes',
      'Miraflores de la Sierra',
      'Mirabueno',
      'Miño de San Esteban',
      'Miño',
      'Mingorría',
      'Milmarcos',
      'Milles de la Polvorosa',
      'Millana',
      'Milagros',
      'Milagro',
      'Mijares',
      'Migueláñez',
      'Mieza',
      'Mieres',
      'Miengo',
      'Miedes de Atienza',
      'Micieces de Ojeda',
      'Micereces de Tera',
      'Mianos',
      'Mezquita de Jarque',
      'Mezalocha',
      'Metauten',
      'Mesones de Isuela',
      'Mesia',
      'Mesegar de Corneja',
      'Mequinensa / Mequinenza',
      'Méntrida',
      'Mengamuñoz',
      'Meneses de Campos',
      'Mendigorría',
      'Mendavia',
      'Mendaro',
      'Membrillera',
      'Membribe de la Sierra',
      'Membibre de la Hoz',
      'Melque de Cercos',
      'Melón',
      'Melide',
      'Mélida',
      'Melgar de Yuso',
      'Melgar de Tera',
      'Melgar de Fernamental',
      'Melgar de Arriba',
      'Melgar de Abajo',
      'Mejorada del Campo',
      'Mejorada',
      'Meis',
      'Meira',
      'Megina',
      'Megeces',
      'Medrano',
      'Medranda',
      'Mediona',
      'Medinilla',
      'Medina de Ríoseco',
      'Medina de Pomar',
      'Medina del Campo',
      'Medinaceli',
      'Mediana de Voltoya',
      'Meco',
      'Mecerreyes',
      'Meaño',
      'Mazuela',
      'Mazuecos de Valdeginate',
      'Mazuecos',
      'Mazariegos',
      'Mazaricos',
      'Mazarete',
      'Mazaleón',
      'Mayorga',
      'Maials',
      'Mayalde',
      'Matute',
      'Matillas',
      'Matilla la Seca',
      'Matilla de los Caños del Río',
      'Matilla de los Caños',
      'Matilla de Arzón',
      'Matarrubia',
      'Mataró',
      'Matapozuelos',
      'Mataporquera',
      'Matamala de Almazán',
      'Matallana de Torío',
      'Matalebreras',
      'Matadepera',
      'Matadeón de los Oteros',
      'Mata de Cuéllar',
      'Matabuena',
      'Masueco',
      'Maçanet de la Selva',
      'Maçanet de Cabrenys',
      'Massanes',
      'el Masroig',
      'Masquefa',
      'El Masnou',
      'Maside',
      'Masegoso de Tajuña',
      'Masegosa',
      'Masdenverge',
      'Mas de las Matas',
      'Mas de Barberans',
      'Masarac',
      'Marzales',
      'Marugán',
      'Martorell',
      'Martín Muñoz de las Posadas',
      'Martín Muñoz de la Dehesa',
      'Martín Miguel',
      'Martínez',
      'Martín de Yeltes',
      'Martín del Río',
      'Martinamor',
      'Martiherrero',
      'Martiago',
      'Marracos',
      'Markina-Xemein',
      'Marín',
      'Mariana',
      'María de Huerva',
      'Margalef',
      'Marcilla de Campos',
      'Marcilla',
      'Marchamalo',
      'Marchagaz',
      'Marazuela',
      'Marazoleja',
      'Marañón',
      'Meranges',
      'Maranchón',
      'Maraña',
      'Mara',
      'Maqueda',
      'Manzanillo',
      'Manzanera',
      'Manzaneda',
      'Manzanares el Real',
      'Manzanares de Rioja',
      'Manzanal de los Infantes',
      'Manzanal del Barco',
      'Manzanal de Arriba',
      'Mantinos',
      'Mantiel',
      'Mansilla Mayor',
      'Mansilla de las Mulas',
      'Manresa',
      'Manquillos',
      'Mañón',
      'Manlleu',
      'Manjarrés',
      'Manjabálago',
      'Manganeses de la Polvorosa',
      'Manganeses de la Lampreana',
      'Mañeru',
      'Mandayona',
      'Manciles',
      'Manchones',
      'Mancera de Arriba',
      'Mancera de Abajo',
      'Mañaria',
      'Mamolar',
      'Mambrillas de Lara',
      'Mambrilla de Castrejón',
      'Mamblas',
      'Malva',
      'Maluenda',
      'Malpartida de Corneja',
      'Malpartida',
      'Malón',
      'Mallén',
      'Malgrat de Mar',
      'Maleján',
      'Malanquilla',
      'Malaguilla',
      'Málaga del Fresno',
      'Maján',
      'Majaelrayo',
      'Majadahonda',
      'Maire de Castroponce',
      'Mainar',
      'Maicas',
      'Mahide',
      'Mahamud',
      'Magaz de Cepeda',
      'Magaña',
      'Magallón',
      'Maello',
      'Maella',
      'Madroñal',
      'Madrigalejo del Monte',
      'Madrigal del Monte',
      'Madrigal de la Vera',
      'Madrigal de las Altas Torres',
      'Madridanos',
      'Madrid',
      'Madremanya',
      'Maderuelo',
      'Macotera',
      'Machacón',
      'Maceda',
      'Luzón',
      'Luzmela',
      'Luzaga',
      'Luyego',
      'Lupiana',
      'Luna',
      'Lumpiaque',
      'Lumbreras',
      'Lumbrales',
      'Lumbier',
      'Lugones',
      'Lugo',
      'Luesma',
      'Luesia',
      'Luelmo',
      'Ludiente',
      'Luceni',
      'Lucena del Cid',
      'Lucena de Jalón',
      'Lubián',
      'Luanco',
      'Lozoya',
      'Lobios',
      'Lousame',
      'Los Santos de la Humosa',
      'Los Molinos',
      'Loscos',
      'Los Corrales de Buelna',
      'Loscorrales',
      'Losar de la Vera',
      'Los Arcos',
      'Losacio',
      'Losacino',
      'Loranca de Tajuña',
      'Loporzano',
      'Longás',
      'Longares',
      'Lominchar',
      'Lomas',
      'Logroño',
      'Loeches',
      'Lodosa',
      'Lobera de Onsella',
      'Loarre',
      'Lloret de Mar',
      'Laudio / Llodio',
      'Llívia',
      'Lliçà de Vall',
      "Lliçà d'Amunt",
      'Llinars del Vallès',
      'Llimiana',
      'Lles de Cerdanya',
      'Llers',
      'Lledó',
      'Llançà',
      'Llano de Olmedo',
      'Llano de Bureba',
      'Llanes',
      'Llanera',
      'Llambilles',
      'Llamas de la Ribera',
      'Llagostera',
      'Lladó',
      'Lizoáin',
      'Lizartza',
      'Lituénigo',
      'Litago',
      'Linyola',
      'Linares de Riofrío',
      'Linares de Mora',
      'Limpias',
      'Liédena',
      'Lidón',
      'Liceras',
      'Libros',
      'Lezo',
      'Lezáun',
      'Lezama',
      'Leza de Río Leza',
      'Leza',
      'Letux',
      'Lesaka',
      'Les',
      'Lerma',
      'Lerín',
      'Lleida',
      'Lerga',
      'Lekeitio',
      'León',
      'Leioa',
      'Leitza',
      'Leiva',
      'Legorreta',
      'Legazpi',
      'Legaria',
      'Legarda',
      'Leganiel',
      'Leganés',
      'Ledrada',
      'Ledigos',
      'Ledesma de la Cogolla',
      'Ledesma',
      'Ledanca',
      'Lekunberri',
      'Leciñena',
      'Lechón',
      'Lécera',
      'Leache',
      'Leaburu',
      'Lazkao',
      'Lazagurría',
      'Laza',
      'Layana',
      'La Torre de Esteban Hambrán',
      "la Tallada d'Empordà",
      'Las Ventas de Retamosa',
      'Lastras del Pozo',
      'Lastras de Cuéllar',
      'Las Rozas de Madrid',
      'Laspuña',
      "les Planes d'Hostoles",
      'Laspaúles',
      'Las Navas del Marqués',
      'Las Matas',
      'les Llosses',
      'La Selva del Camp',
      'la Cellera de Ter',
      'La Secuita',
      'La Seca',
      'Lascuarre',
      "Les Cases d'Alcanar",
      'Lasarte',
      'La Sagrera',
      'A Rúa',
      'Larrodrigo',
      'Larraul',
      'Larraona',
      'Larraga',
      'La Roca del Vallès',
      'La Robla',
      'Sant Pere, Santa Caterina i La Ribera',
      'Laredo',
      'Lardero',
      'Laracha',
      'Lapuebla de Labarca',
      'La Pola de Gordón',
      'la Pobla de Mafumet',
      'La Pobla de Claramunt',
      'Lapoblación',
      'La Plaza',
      'La Pineda',
      'La Pesga',
      'Laperdiguera',
      'La Pedraja de Portillo',
      'Lanzuela',
      'Lantz',
      'Lantadilla',
      'Languilla',
      'Langayo',
      'Langa del Castillo',
      'Langa de Duero',
      'Langa',
      'Lanestosa',
      'Láncara',
      'Lanaja',
      'La Muela',
      'la Morera de Montsant',
      'Lama',
      'Lalueza',
      'Laluenga',
      'La Llagosta',
      'Lalín',
      'La Lastrilla',
      'la Jonquera',
      'La Iglesuela del Cid',
      'Polanco',
      'Lagunilla del Jubera',
      'Lagunilla',
      'Lagunaseca',
      'Laguna de Negrillos',
      'Laguna del Marquesado',
      'Laguna de Duero',
      'Laguna de Contreras',
      'Laguna de Cameros',
      'Laguna Dalga',
      'Lagueruela',
      'Laguardia',
      'A Guarda',
      'La Granada',
      'Lagrán',
      'La Ginebrosa',
      'Laxe',
      'Lagata',
      'Lagartos',
      'la Garriga',
      'La Fuliola',
      'La Fuente de San Esteban',
      'La Frontera',
      'A Estrada',
      "l'Escala",
      'Ladrillar',
      'Lakuntza',
      'La Cuesta',
      'A Coruña',
      'La Carrera',
      'La Canonja',
      'La Cabrera',
      'Labuerda',
      'La Bouza',
      "la Bisbal d'Empordà",
      'Bastida / Labastida',
      'La Bañeza',
      'A Baña',
      'Labajos',
      'San Juan de la Arena',
      "L'Ampolla",
      "l'Ametlla de Mar",
      "L'Ametlla del Vallès",
      'La Almunia de Doña Godina',
      'La Alberca',
      'La Alameda de la Sagra',
      'La Adrada',
      'Juzbado',
      'Juià',
      'Justel',
      'Juneda',
      'Juncosa',
      'Junciana',
      'Juarros de Voltoya',
      'Xove',
      'Josa',
      'Jorcas',
      'Jorba',
      'Joarilla de las Matas',
      'Jirueque',
      'Jerte',
      'Javier',
      'Jaulín',
      'Jatiel',
      'Jasa',
      'Jarque de la Val',
      'Jarque',
      'Jarilla',
      'Jarandilla de la Vera',
      'Jaramillo Quemado',
      'Jaramillo de la Fuente',
      'Jaraíz de la Vera',
      'Jaraba',
      'Jambrina',
      'Jalón de Cameros',
      'Jafre',
      'Jadraque',
      'Jaca',
      'Jabaloyas',
      'Izurtza',
      'Izagre',
      'Iturmendi',
      'Ituren',
      'Ituero y Lama',
      'Ituero de Azaba',
      'Itero del Castillo',
      'Itero de la Vega',
      'Isuerre',
      'Isòvol',
      'Illa de Arousa',
      'Iscar',
      'Itsasondo',
      'Isar',
      'Irurtzun',
      'Irura',
      'Irun',
      'Irueste',
      'Iruelos',
      'Irixoa',
      'Irañeta',
      'Iniéstola',
      'Infiesto',
      'O Incio',
      'Illueca',
      'Illescas',
      'Illas',
      'Illano',
      'Illana',
      'Ilche',
      'Igúzquiza',
      'Igüeña',
      'Igualada',
      'Igriés',
      'Iglesias',
      'Iglesiarrubia',
      'Igea',
      'Idiazabal',
      'Ikaztegieta',
      'Ibrillos',
      'Ivorra',
      'Ibieca',
      'Ibeas de Juarros',
      'Ibdes',
      "Ivars d'Urgell",
      'Ibarrangelu',
      'Ibarra',
      'Husillos',
      'Hurtumpascual',
      'Hurones',
      'Humanes de Madrid',
      'Humanes',
      'Humada',
      'Hueva',
      'Huete',
      'Huesca',
      'Huesa del Común',
      'Huerto',
      'Huertahernando',
      'Huerta del Rey',
      'Huerta del Marquesado',
      'Huerta de Arriba',
      'Huerta',
      'Huérmeces del Cerro',
      'Huérmeces',
      'Huérguina',
      'Huércanos',
      'Huelves',
      'Huélamo',
      'Huélaga',
      'Huecas',
      'Huarte-Uharte',
      'Hoz de Jaca',
      'Hoyos de Miguel Muñoz',
      'Hoyos del Espino',
      'Hoyos del Collado',
      'Hoyos',
      'Hoyorredondo',
      'Hoyo de Manzanares',
      'Hoyocasero',
      'Hoyales de Roa',
      'Hostalric',
      "L'Hospitalet de Llobregat",
      'Hospital de Órbigo',
      'Hortigüela',
      'Hortaleza',
      'Horta de Sant Joan',
      'Horta',
      'Hornos de Moncalvillo',
      'Hornillos del Camino',
      'Hornillos de Cerrato',
      'Hornillos de Cameros',
      'Hormilleja',
      'Hormilla',
      'Hormigos',
      'Horche',
      'Horcajuelo de la Sierra',
      'Horcajo de Montemayor',
      'Horcajo de las Torres',
      'Horcajo de la Sierra',
      'Hontoria de Valdearados',
      'Hontoria del Pinar',
      'Hontoria de la Cantera',
      'Hontoria de Cerrato',
      'Hontoba',
      'Hontangas',
      'Hontanas',
      'Hontanares de Eresma',
      'Hontalbilla',
      'Honrubia de la Cuesta',
      'Hombrados',
      'Hita',
      'Hinojosa de San Vicente',
      'Hinojosa del Campo',
      'Hinojosa de Jarque',
      'Hinojosa de Duero',
      'Hijes',
      'Híjar',
      'Higuera de las Dueñas',
      'Hiendelaencina',
      'Hervías',
      'Hervás',
      'Herrín de Campos',
      'Herreros de Suso',
      'Herrería',
      'Herrera de Valdecañas',
      'Herrera de Soria',
      'Herrera de Pisuerga',
      'Herrera de los Navarros',
      'Herramélluri',
      'Hernialde',
      'Hernansancho',
      'Hernán-Pérez',
      'Hernani',
      'Hermisende',
      'Hérmedes de Cerrato',
      'Herguijuela del Campo',
      'Herguijuela de la Sierra',
      'Herguijuela de Ciudad Rodrigo',
      'Herce',
      'Herbés',
      'Henche',
      'Echo',
      'Hazas de Cesto',
      'Haza',
      'Haro',
      'Hacinas',
      'Gutierre-Muñoz',
      'Gusendos de los Oteros',
      'Gurrea de Gállego',
      'Guntín',
      'Guitiriz',
      'Guisando',
      'Guirguillano',
      'Guimerà',
      'Guils de Cerdanya',
      'Guijuelo',
      'Guijo de Santa Bárbara',
      'Guijo de Granadilla',
      'Guijo de Galisteo',
      'Guijo de Coria',
      'Guijo de Ávila',
      'Getaria',
      'Gernika-Lumo',
      'Güeñes',
      'Getxo',
      'Gúdar',
      'Guaza de Campos',
      'Guardo',
      'Guardiola de Berguedà',
      'Sant Salvador de Guardiola',
      'Gualta',
      'Guadramiro',
      'Guadarrama',
      'Guadalix de la Sierra',
      'Guadalaviar',
      'Guadalajara',
      'O Grove',
      'Grisén',
      'Grisel',
      'Grisaleña',
      'Griñón',
      'Grijota',
      'Grijalba',
      'Griegos',
      'Grávalos',
      'Graus',
      'Granucillo',
      'Grañón',
      'Granollers',
      'Granja de Moreruela',
      "la Granja d'Escarp",
      'Granera',
      'Grañén',
      'Grandas de Salime',
      'la Granadella',
      'Grajera',
      'Grajal de Campos',
      'Grado',
      'Gradefes',
      'Gràcia',
      'Gotor',
      'Gotarrendura',
      'Gósol',
      'Gormaz',
      'Elexalde',
      'Gordoncillo',
      'Gordaliza del Pino',
      'Goñi',
      'Gondomar',
      'Gomecello',
      'Gómara',
      'Golpejas',
      'Golmés',
      'Golmayo',
      'Goizueta',
      'Godojos',
      'Gistaín',
      'Gironella',
      'Xinzo de Limia',
      'Ginestar',
      'Gimileo',
      'Gimialcón',
      'Gijón',
      'Getafe',
      'Girona',
      'Geria',
      'Ger',
      'Genevilla',
      'Gemuño',
      'Gema',
      'Gelsa',
      'Gelida',
      'Gejuelo del Barro',
      'Gea de Albarracín',
      'Gaztelu',
      'Gabiria',
      'Gavilanes',
      'Gavà',
      'Gatón de Campos',
      'Gatika',
      'Gata',
      'Gascueña de Bornova',
      'Gascueña',
      'Gascones',
      'Garriguella',
      'Garrigoles',
      'Garrigàs',
      'Garray',
      'Garralda',
      'Garrafe de Torío',
      'Garínoain',
      'Gargüera',
      'Gargantilla',
      'Garganta la Olla',
      'Garganta del Villar',
      'Garganta de los Montes',
      'Gargallo',
      'Garde',
      'Garcirrey',
      'Garcillán',
      'Garcihernández',
      'Garcibuey',
      'Garcia',
      'Garaioa',
      'Gandesa',
      'Gamones',
      'Gamonal',
      'Galve de Sorbe',
      'Galve',
      'Gallur',
      'Gallocanta',
      'Gallipienzo',
      'Gallinero de Cameros',
      'Gallifa',
      'Gallegos de Sobrinos',
      'Gallegos del Río',
      'Gallegos del Pan',
      'Gallegos de Hornija',
      'Gallegos de Argañán',
      'Gallegos',
      'Galisancho',
      'Galinduste',
      'Galindo y Perahuy',
      'Galilea',
      'Galende',
      'Galdakao',
      'Galbárruli',
      'Galbarros',
      'Galar',
      'Galápagos',
      'Galapagar',
      'Gajates',
      'Gajanejos',
      'Gaintza',
      'Fustiñana',
      'Funes',
      'Fulleda',
      'Fuertescusa',
      'Fuentidueña de Tajo',
      'Fuentidueña',
      'Fuentestrún',
      'Fuentespreadas',
      'Fuentespina',
      'Fuentespalda',
      'Fuentesoto',
      'Fuentesecas',
      'Fuentes de Valdepero',
      'Fuentes de Rubielos',
      'Fuentes de Ropel',
      'Fuentes de Oñoro',
      'Fuentes de Nava',
      'Fuentes de Magaña',
      'Fuentes de Jiloca',
      'Fuentes de Ebro',
      'Fuentes de Carbajal',
      'Fuentes de Béjar',
      'Fuentes de Ayódar',
      'Fuentes de Año',
      'Fuentes Claras',
      'Fuentes Calientes',
      'Fuentesaúco de Fuentidueña',
      'Fuentesaúco',
      'Fuenterroble de Salvatierra',
      'Hondarribia',
      'Fuentepinilla',
      'Fuentepiñel',
      'Fuentepelayo',
      'Fuentenovilla',
      'Fuentenebro',
      'Fuentemolinos',
      'Fuentelviejo',
      'Fuentelsaz de Soria',
      'Fuentelsaz',
      'Fuentelisendo',
      'Fuentelencina',
      'Fuentelcésped',
      'Fuente la Reina',
      'Fuentelapeña',
      'Fuentelahiguera de Albatages',
      'Fuenteguinaldo',
      'Fuente Encalada',
      'Fuente el Sol',
      'Fuente el Saz',
      'Fuente el Olmo de Fuentidueña',
      'Fuente de Santa Cruz',
      'Fuentecén',
      'Fuentecantos',
      'Fuentecambrón',
      'Fuentebureba',
      'Fuentearmegil',
      'Fuensalida',
      'Fuensaldaña',
      'Fuenmayor',
      'Fuenlabrada',
      'Fuenferrada',
      'Fuendetodos',
      'Fuendejalón',
      'Fuencemillán',
      'Fuembellida',
      'Frumales',
      'Frómista',
      'Friol',
      'Friera de Valverde',
      'Frías de Albarracín',
      'Frías',
      'Fresno El Viejo',
      'Fresno de Torote',
      'Fresno de Sayago',
      'Fresno de Rodilla',
      'Fresno del Río',
      'Fresno de la Vega',
      'Fresno de la Ribera',
      'Fresno de la Polvorosa',
      'Fresno de la Fuente',
      'Fresno de Caracena',
      'Fresno de Cantespino',
      'Fresnillo de las Dueñas',
      'Fresneña',
      'Fresnedoso',
      'Fresnedillas',
      'Fresnedilla',
      'Fresneda de la Sierra Tirón',
      'Fresneda de la Sierra',
      'Fresneda de Cuéllar',
      'Fréscano',
      'Frechilla de Almazán',
      'Frechilla',
      'Frandovínez',
      'Fraga',
      'Frades de la Sierra',
      'Frades',
      'Foz-Calanda',
      'Foz',
      'Forua',
      'Fortià',
      'Fortanete',
      'Fórnoles',
      'Fornelos de Montes',
      'Fornells de la Selva',
      'Formiche Alto',
      'Forfoleda',
      'Forès',
      'Forcall',
      'Fonzaleche',
      'Fonz',
      'Fontiveros',
      'Fontioso',
      'Fontihoyuelo',
      'Fontellas',
      'Fontcoberta',
      'Fontanilles',
      'Fontanar',
      'Fonsagrada',
      'Fonollosa',
      'Fonfría',
      'Foncea',
      'Fompedraza',
      'Fombuena',
      'Fombellida',
      'Folgoso de la Ribera',
      'Foixà',
      'Fogars de Montclús',
      'Florida de Liébana',
      'Flores de Ávila',
      'Flix',
      'Flaçà',
      'Fitero',
      'Fiscal',
      'Fisterra',
      'Figueruelas',
      'Figueruela de Arriba',
      'Figueroles',
      'Figueres',
      'Ferreruela de Huerva',
      'Ferreruela',
      'Ferreras de Arriba',
      'Ferreras de Abajo',
      'Fermoselle',
      'Fene',
      'Fayón',
      'la Fatarella',
      'Farrera',
      'Farlete',
      'Fariza',
      'Faramontanos de Tábara',
      'Fanzara',
      'Falset',
      'Falces',
      'Fago',
      'Fabero',
      'Fabara',
      'Ezkurra',
      'Ezcaray',
      'Eulate',
      'Etayo',
      'Estriégana',
      'Estremera',
      'Estollo',
      'Estercuel',
      'Estépar',
      'Estepa de San Juan',
      'Estella-Lizarra',
      "l'Estartit",
      'Estadilla',
      'Estada',
      'Establés',
      'Esquivias',
      'Espronceda',
      'Espot',
      'Esponellà',
      'Espolla',
      'Esplús',
      'Esplugues de Llobregat',
      'Esplegares',
      'Espirdo',
      'Espinosa de Villagonzalo',
      'Espinosa de los Monteros',
      'Espinosa del Camino',
      'Espinosa de Henares',
      'Espinosa de Cervera',
      'Espinosa de Cerrato',
      'Espino de la Orbada',
      'Espejón',
      'Espeja de San Marcelino',
      'Espeja',
      'Esparreguera',
      'Espadilla',
      'Espadañedo',
      'Espadaña',
      'Eslava',
      'Esguevillas de Esgueva',
      'Esgos',
      'Escurial de la Sierra',
      'Escucha',
      'Escorihuela',
      'Eskoriatza',
      'Escopete',
      'Escobosa de Almazán',
      'Escobar de Polendos',
      'Escobar de Campos',
      'Escatrón',
      'Escariche',
      'Escarabajosa de Cabezas',
      'Escamilla',
      'Escalona del Prado',
      'Escalona',
      'Escalante',
      'Ermua',
      'Erla',
      'Eratsun',
      'Erandio',
      'Épila',
      'Entrimo',
      'Entrena',
      'Entrambasaguas',
      'Entrala',
      'Enériz',
      'Endrinal',
      'Enciso',
      'Encío',
      'Encinillas',
      'Encinedo',
      'Encinasola de los Comendadores',
      'Encinas de Esgueva',
      'Encinas de Arriba',
      'Encinas de Abajo',
      'Encinas',
      'Encina de San Silvestre',
      'Encinacorba',
      'Embid de Ariza',
      'Embid',
      'El Viso de San Juan',
      'El Vellón',
      'El Tiemblo',
      'El Real de San Vicente',
      'El Prat de Llobregat',
      'El Pardo',
      'Elorz',
      'Elorrio',
      'El Molar',
      'Éller',
      'Eljas',
      'Elizondo',
      'El Hoyo de Pinares',
      'Gurb',
      'Elgeta',
      'Empuriabrava',
      'Elgorriaga',
      'Elgoibar',
      'El Franco',
      'Figaró',
      'Ferrol',
      'El Escorial',
      'Elduain',
      'Zalla',
      'Elciego',
      'El Castellar',
      'El Casar de Escalona',
      'El Burgo de Osma',
      'El Burgo de Ebro',
      'O Barco de Valdeorras',
      'El Barco de Ávila',
      'El Astillero',
      'el Raval',
      'Elantxobe',
      'El Álamo',
      'Ejulve',
      'Ejeme',
      'Ejea de los Caballeros',
      'Eibar',
      'Echarri-Aranaz',
      'Echarri',
      'Etxalar',
      'Ea',
      'Duruelo de la Sierra',
      'Duruelo',
      'Durón',
      'Durango',
      'Dumbría',
      'Dueñas',
      'Driebes',
      'Dozón',
      'Dosrius',
      'Duesaigües',
      'Donvidas',
      'Donjimeno',
      'Doñinos de Salamanca',
      'Doñinos de Ledesma',
      'Donhierro',
      'Domingo García',
      'Dios le Guarde',
      'Dicastillo',
      'Deza',
      'Deba',
      'Destriana',
      'Desojo',
      'Descargamaría',
      'Derio',
      'Dehesa de Romanos',
      'Dehesa de Montejo',
      'Degaña',
      'Das',
      'Daroca',
      'Darnius',
      'Daganzo de Arriba',
      'Cuzcurrita de Río Tirón',
      'Curtis',
      'Curiel de Duero',
      'Cuntis',
      'Cunit',
      'Culleredo',
      'Culla',
      'Cuevas Labradas',
      'Cuevas de Vinromá',
      'Cuevas de San Clemente',
      'Cuevas de Provanco',
      'Cuevas del Valle',
      'Cuevas de Almudén',
      'Cueva del Hierro',
      'Cueva de Ágreda',
      'Cuenca de Campos',
      'Cuenca',
      'Cuéllar',
      'Cuelgamures',
      'Cudillero',
      'Cucalón',
      'Cubo de la Solana',
      'Cubo de Bureba',
      'Cubo de Benavente',
      'Cubla',
      'Cubillos del Sil',
      'Cubillos',
      'Cubillo del Campo',
      'Cubillo',
      'Cubillas de Santa Marta',
      'Cubillas de Rueda',
      'Cubillas de los Oteros',
      'Cubillas de Cerrato',
      'Cubilla',
      'Cubells',
      'Cubelles',
      'Cubel',
      'Cubas',
      'Cuarte de Huerva',
      'Cualedro',
      'Cuadros',
      'Cruïlles',
      'Crivillén',
      'Cristóbal',
      'Crespos',
      'Crespià',
      'Crémenes',
      'Creixell',
      'Cozuelos de Fuentidueña',
      'Covelo',
      'Covarrubias',
      'Covaleda',
      'Cosuenda',
      'Costur',
      'Coslada',
      'Coscurita',
      'Cosa',
      'Coruña del Conde',
      'Corullón',
      'Kortezubi',
      'Cortes de Arenoso',
      'Cortes de Aragón',
      'Cortes',
      'Cortegada',
      'Corçà',
      'Corrales de Duero',
      'Corrales',
      'Corral de Ayllón',
      'Corporales',
      'Corpa',
      'Cornellà del Terri',
      'Cornellà de Llobregat',
      'Cornago',
      'Coristanco',
      'Corgo',
      'Coreses',
      'Corera',
      'Corella',
      'Corduente',
      'Cordovín',
      'Cordovilla la Real',
      'Cordovilla',
      'Corcubión',
      'Corcos',
      'Corbins',
      'Corbillos de los Oteros',
      'Corbera de Llobregat',
      'Corbalán',
      'Copernal',
      'Coomonte',
      'Contreras',
      'Contamina',
      'Constanzana',
      'Constantí',
      'Congostrina',
      'Congosto de Valdavia',
      'Congosto',
      'Conesa',
      'Condemios de Arriba',
      'Condemios de Abajo',
      'Comillas',
      'Combarro',
      'Colungo',
      'Colunga',
      'Colomers',
      'Colmenar Viejo',
      'Colmenarejo',
      'Colmenar de Oreja',
      'Colmenar de Montemayor',
      'Colmenar del Arroyo',
      'Collsuspina',
      'Coll de Nargó',
      'Colldejou',
      'Collbató',
      'Collazos de Boedo',
      'Collado-Villalba',
      'Collado Mediano',
      'Collado Hermoso',
      'Collado del Mirón',
      'Collado de Contreras',
      'Collado',
      'Colindres',
      'Coles',
      'Colera',
      'Coirós',
      'el Cogul',
      'Cogolludo',
      'Cogollos',
      'Cogollor',
      'Cogeces del Monte',
      'Codos',
      'Codorniz',
      'Coca de Alba',
      'Coca',
      'Cobreros',
      'Cobos de Fuentidueña',
      'Cobos de Cerrato',
      'Cobeta',
      'Cobeña',
      'Cobeja',
      'Coaña',
      'el Clot',
      'Clavijo',
      'Clarés de Ribota',
      'Zizur Mayor',
      'Ciutadilla',
      'Siurana',
      'Ciudad Rodrigo',
      'Ciudad Lineal',
      'Ciutadella',
      'Cistierna',
      'Cistérniga',
      'Cistella',
      'Cisneros',
      'Cisla',
      'Cirujales del Río',
      'Cirueña',
      'Ciruelos de Cervera',
      'Ciruelas',
      'Ciriza',
      'Ciria',
      'Cirauqui',
      'Cirat',
      'Cipérez',
      'Ziordia',
      'Cintruénigo',
      'Cinctorres',
      'Cincovillas',
      'Cinco Olivas',
      'Cimballa',
      'Cimanes del Tejar',
      'Cimanes de la Vega',
      'Cilleruelo de San Mamés',
      'Cilleruelo de Arriba',
      'Cilleruelo de Abajo',
      'Cilleros de la Bastida',
      'Cilleros',
      'Cillán',
      'Cihuri',
      'Cihuela',
      'Ciguñuela',
      'Cigudosa',
      'Cigales',
      'Cifuentes',
      'Zierbena',
      'Ciempozuelos',
      'Cidones',
      'Cidamón',
      'Ciadoncha',
      'Chozas de Canales',
      'Chozas de Abajo',
      'Chodes',
      'Chiprana',
      'Chinchón',
      'Chimillas',
      'Chiloeches',
      'Chillarón del Rey',
      'Chillarón de Cuenca',
      'Chía',
      'Chert/Xert',
      'Chequilla',
      'Checa',
      'Chapinería',
      'Chantada',
      'Chañe',
      'Chamartín',
      'Chalamera',
      'Cevico Navero',
      'Cevico de la Torre',
      'Cetina',
      'Cesuras',
      'Zestoa',
      'Cervo',
      'Cervillego de la Cruz',
      'Cervià de Ter',
      'Cerveruela',
      'Cervera de Pisuerga',
      'Cervera del Río Alhama',
      'Cervera de los Montes',
      'Cervera del Maestre',
      'Cervera de la Cañada',
      'Cervera de Buitrago',
      'Cervera',
      'Cervelló',
      'Cervatos de la Cueza',
      'Cerratón de Juarros',
      'Cerralbo',
      'Cernadilla',
      'Cerezo de Río Tirón',
      'Cerezo de Arriba',
      'Cerezo de Abajo',
      'Cerezo',
      'Cerezal de Peñahorcada',
      'Cerecinos del Carrizal',
      'Cerecinos de Campos',
      'Cereceda de la Sierra',
      'Cerdido',
      'Cercedilla',
      'Cerceda',
      'Cerbón',
      'Zerain',
      'Cepeda la Mora',
      'Cepeda',
      'Centenera de Andaluz',
      'Centenera',
      'Centelles',
      'Cenlle',
      'Cenicientos',
      'Cenicero',
      'Cendejas de la Torre',
      'Celrà',
      'Cellorigo',
      'Cella',
      'Celanova',
      'Celada del Camino',
      'Ceinos de Campos',
      'Zegama',
      'Cee',
      'Cedrillas',
      'Cedillo del Condado',
      'Cedillo de la Torre',
      'Cedeira',
      'Cebrones del Río',
      'Cebreros',
      'Cebrecos',
      'Cebanico',
      'Zeanuri',
      'Cea',
      'Cazurra',
      'Cazalegas',
      'Cayuela',
      'Catoira',
      'el Catllar',
      'Catí',
      'Castroverde de Cerrato',
      'Castroverde de Campos',
      'Castroverde',
      'Castro-Urdiales',
      'Castroserracín',
      'Castroserna de Abajo',
      'Castroponce',
      'Castropol',
      'Castropodame',
      'Castronuño',
      'Castronuevo de Esgueva',
      'Castronuevo',
      'Castromonte',
      'Castromocho',
      'Castromembibre',
      'Castrojimeno',
      'Castrogonzalo',
      'Castrodeza',
      'Castro de Rei',
      'Castro de Fuentidueña',
      'Castrocontrigo',
      'Castro Caldelas',
      'Castrocalbón',
      'Castrobol',
      'Castro',
      'Castrillo-Tejeriego',
      'Castrillo de Villavega',
      'Castrillo de Onielo',
      'Castrillo del Val',
      'Castrillo de la Vega',
      'Castrillo de la Valduerna',
      'Castrillo de la Reina',
      'Castrillo de la Guareña',
      'Castrillo de Duero',
      'Castrillo de Don Juan',
      'Castrillo de Cabrera',
      'Castrelo de Miño',
      'Castrejón de la Peña',
      'Castraz',
      'Castilruiz',
      'Castilnuevo',
      'Castillonuevo',
      'Castillo de Villamalefa',
      'Castillo de Bayuela',
      "Castell-Platja d'Aro",
      'Castillo-Albaráñez',
      'Castillejo-Sierra',
      'Castillejo de Robledo',
      'Castillejo de Mesleón',
      'Castillejo de Martín Viejo',
      'Castillazuelo',
      'Castiliscar',
      'Castilfrío de la Sierra',
      'Castilforte',
      'Castilfalé',
      'Castil de Vela',
      'Castil de Peones',
      'Castildelgado',
      'Castigaleu',
      'Castiello de Jaca',
      'Castielfabib',
      'Castelserás',
      'Castelnou',
      'Castellví de Rosanes',
      'Castellterçol',
      'Castellserà',
      'Castellote',
      'Castellolí',
      'Castelló de Farfanya',
      "Castelló d'Empúries",
      'Castellnou de Seana',
      'Castellfollit de Riubregós',
      'Castellfollit del Boix',
      'Castellfort',
      'Castellet',
      'Castelldefels',
      'Castell de Cabres',
      'Castellcir',
      'Castellbisbal',
      'Castellar de la Muela',
      'Castellar del Vallès',
      'Castellanos de Zapardiel',
      'Castellanos de Villiquera',
      'Castellanos de Moriscos',
      'Castellanos de Castro',
      'Castelflorite',
      'Castel de Cabra',
      'Castejón de Valdejasa',
      'Castejón de Tornos',
      'Castejón de Sos',
      'Castejón de Monegros',
      'Castejón del Puente',
      'Castejón de las Armas',
      'Castejón de Henares',
      'Castejón de Alarba',
      'Castejón',
      'Castandiello',
      'Castañares de Rioja',
      'Cassà de la Selva',
      'Caspueñas',
      'Caspe',
      'Casla',
      'Casillas de Flores',
      'Casillas',
      'Caseres',
      'Cáseda',
      'Cascante del Río',
      'Cascante',
      'Cascajares de la Sierra',
      'Cascajares de Bureba',
      'Casbas de Huesca',
      'Casavieja',
      'Casasola de Arión',
      'Casaseca de las Chanas',
      'Casaseca de Campeán',
      'Casas de San Galindo',
      'Casas del Monte',
      'Casas del Castañar',
      'Casas de Don Gómez',
      'Casas Bajas',
      'Casas Altas',
      'Casarrubuelos',
      'Casarrubios del Monte',
      'Casares de las Hurdes',
      'Casarejos',
      'Casar de Palomero',
      'Casalarreina',
      'Casafranca',
      'Casa de Uceda',
      'Carucedo',
      'Cartes',
      'Cartelle',
      'Carrocera',
      'Carrizo de la Ribera',
      'Carrión de los Condes',
      'Carrias',
      'Carreña',
      'Carrascosa de la Sierra',
      'Carrascosa de Abajo',
      'Carrascosa',
      'Carrascal del Río',
      'Carrascal del Obispo',
      'Carrascal de Barregas',
      'Carranque',
      'Carral',
      'Carracedelo',
      'Carpio de Azaba',
      'Carpio',
      'Carnota',
      'Cármenes',
      'Cariño',
      'Cariñena',
      'Carenas',
      'Cardona',
      'Cardiel de los Montes',
      'Cardeñosa de Volpejera',
      'Cardeñosa',
      'Cárdenas',
      'Cardeñajimeno',
      'Cardeñadijo',
      'Cardedeu',
      'Carcedo de Burgos',
      'Carcedo de Bureba',
      'Carcastillo',
      'Cárcar',
      'Carcaboso',
      'Carbonero el Mayor',
      'Carbellino',
      'Carballo',
      'O Carballiño',
      'Carballedo',
      'Carballeda de Avia',
      'Carbajosa de la Sagrada',
      'Carbajales de Alba',
      'Carazo',
      'Caracena',
      'Carabantes',
      'Carabaña',
      'Capmany',
      'Capillas',
      'Capellades',
      'Capella',
      'Capdesaso',
      'Caparroso',
      'Capafonts',
      'Cantiveros',
      'Cantimpalos',
      'Cantavieja',
      'Cantaracillo',
      'Cantalpino',
      'Cantalojas',
      'Cantallops',
      'Cantalejo',
      'Cantalapiedra',
      'Cantagallo',
      'Cantabrana',
      'Canredondo',
      'Cànoves i Samalús',
      'Canovelles',
      'Cañizo',
      'Cañizares',
      'Cañizar del Olivar',
      'Cañizar',
      'Cañizal',
      'Canillas de Río Tuerto',
      'Canillas de Esgueva',
      'Canillas de Abajo',
      'Canicosa de la Sierra',
      'Cangas de Onís',
      'Cangas del Narcea',
      'Cangas do Morrazo',
      'Canfranc',
      'Canet lo Roig',
      'Cañete',
      'Canet de Mar',
      'Canencia',
      'Candín',
      'Candilichera',
      'Candeleda',
      'Candelario',
      'Candasnos',
      'Candás',
      'Cañaveruelas',
      'Cañaveras',
      'Cañas',
      'Cañamares',
      'Cañamaque',
      'Canales de la Sierra',
      'Canales',
      'Canalejas de Peñafiel',
      'Canalejas del Arroyo',
      'Cañada Vellida',
      'Cañada de Benatanduz',
      'Camproviín',
      'Camprodon',
      'Camporrells',
      'Camporredondo',
      'Campo Real',
      'Camponaraya',
      'Campolara',
      'Campo de Villavidel',
      'Campo de San Pedro',
      'Campo de Caso',
      'Campo',
      'Campisábalos',
      'Campins',
      'Campillos-Sierra',
      'Campillo de Ranas',
      'Campillo de Dueñas',
      'Campillo de Azaba',
      'Campillo de Aranda',
      'Campillo de Aragón',
      'Campdevànol',
      'Campazas',
      'Campaspero',
      'Landa',
      'Camós',
      'Caminreal',
      'Caminomorisco',
      'Cambrils',
      'Cambre',
      'Cambados',
      'Camarzana de Tera',
      'Camarma de Esteruelas',
      'Camariñas',
      'Camarillas',
      'Camargo',
      'Camarenilla',
      'Camarena de la Sierra',
      'Camarena',
      'Camarasa',
      'Camañas',
      'Camaleño',
      'Calzadilla de Tera',
      'Calzadilla',
      'Calzada de Valdunciel',
      'Calzada de los Molinos',
      'Calzada del Coto',
      'Calzada de Don Diego',
      'Calvos',
      'Calvarrasa de Arriba',
      'Calvarrasa de Abajo',
      'Caltojar',
      'Calomarde',
      'Calmarza',
      'Càlig',
      'Caleruega',
      'Calella',
      'Caldas de Reis',
      'Caldes de Montbui',
      'Caldes de Malavella',
      "Caldes d'Estrac",
      'Calcena',
      'Calatorao',
      'Calatayud',
      'Calatañazor',
      'Calanda',
      'Calamocha',
      'Calahorra de Boedo',
      'Calahorra',
      'Calafell',
      'Calaf',
      'Calaceite',
      'Cadrete',
      'Cadreita',
      'Cadaqués',
      'Cadalso de los Vidrios',
      'Cadalso',
      'Cacabelos',
      'Cabrils',
      'Cabrillas',
      'Cabrillanes',
      'Cabreros del Río',
      'Cabreros del Monte',
      'Cabrero',
      'Cabrerizos',
      'Cabrera de Mar',
      'Cabrejas del Pinar',
      'Cabrejas del Campo',
      'Cabredo',
      'Cabra de Mora',
      'Cabra del Camp',
      'Cabolafuente',
      'Cabizuela',
      'Cabezuela del Valle',
      'Cabezuela',
      'Cabezón de Valderaduey',
      'Cabezón de Liébana',
      'Cabezón de la Sierra',
      'Cabezón de la Sal',
      'Cabezón de Cameros',
      'Cabezón',
      'Cabezas del Villar',
      'Cabezas del Pozo',
      'Cabezas de Alambre',
      'Cabeza del Caballo',
      'Cabezabellosa de la Calzada',
      'Cabezabellosa',
      'Cabanillas del Campo',
      'Cabanillas de la Sierra',
      'Cabanillas',
      'Cabañes de Esgueva',
      'Cabanes',
      'Cabanelles',
      'Cabañas de Sayago',
      'Cabañas de Polendos',
      'Cabañas de la Sagra',
      'Cabañas de Ebro',
      'Caballar',
      'Busto de Bureba',
      'Bustillo del Páramo de Carrión',
      'Bustillo del Páramo',
      'Bustillo del Oro',
      'Bustillo de la Vega',
      'Bustillo de Chaves',
      'Bustarviejo',
      'Bustares',
      'Burón',
      'Burlata',
      'Burgos',
      'Burgohondo',
      'Burganes de Valverde',
      'Bureta',
      'Burela de Cabo',
      'Burbáguena',
      'Buñuel',
      'Buniel',
      'Bulbuente',
      'Bujaraloz',
      'Bujalaro',
      'Buitrago del Lozoya',
      'Buitrago',
      'Bugedo',
      'Bueu',
      'Buendía',
      'Buenavista de Valdavia',
      'Buenavista',
      'Buenaventura',
      'Buenamadre',
      'Bueña',
      'Budia',
      'Buciegas',
      'Bubierca',
      'Buberos',
      'Brunyola',
      'Brunete',
      'Broto',
      'Bronchales',
      'Briviesca',
      'Briones',
      'Brincones',
      'Briñas',
      'Brime de Urz',
      'Brime de Sog',
      'Brihuega',
      'Brieva de Cameros',
      'Brieva',
      'Bretocino',
      'Bretó',
      'Breda',
      'Brea de Tajo',
      'Brea de Aragón',
      'Brazuelo',
      'Brazacorta',
      'Braojos',
      'Brañosera',
      'Brabos',
      'Bóveda del Río Almar',
      'Bóveda',
      'Botorrita',
      'Botarell',
      'Bot',
      'Borriol',
      'Borrenes',
      'Borredà',
      'Borrassà',
      'Borox',
      'Borobia',
      'les Borges del Camp',
      'Borjabad',
      'Borja',
      'Bordón',
      'Bordils',
      'Bordalba',
      'Borau',
      'Boquiñeni',
      'Bonilla de la Sierra',
      'Bonastre',
      'Boñar',
      'Bonansa',
      'Bolvir',
      'Boltaña',
      'Bolaños de Campos',
      'Boiro',
      'Boimorto',
      'Bohoyo',
      'Bogajo',
      'Boecillo',
      'Bocos de Duero',
      'Bocigas',
      'Boceguillas',
      'Boca de Huérgano',
      'Boborás',
      'Bovera',
      'Bobadilla del Campo',
      'Bobadilla',
      'Boalo',
      'Boal',
      'Boadilla del Monte',
      'Boadilla del Camino',
      'Boada de Campos',
      'Boada',
      'Bliecos',
      'Blesa',
      'Blascosancho',
      'Blascomillán',
      'Blanes',
      'Blancos',
      'Blancas',
      'Blacos',
      'Bisimbre',
      'Biscarrués',
      'la Bisbal del Penedès',
      'Bisaurri',
      'Biota',
      'Binéfar',
      'Binaced',
      'Bimenes',
      'Bilbao',
      'Bijuesca',
      'Bigues i Riells',
      'Biescas',
      'Bierge',
      'Bielsa',
      'Biel',
      'Bezas',
      'Bezares',
      'Beuda',
      'Beteta',
      'Betelu',
      'Betanzos',
      'Bescanó',
      'Besalú',
      'Berzosilla',
      'Berzosa del Lozoya',
      'Berzosa de Bureba',
      'Berrueces',
      'Berrocalejo de Aragona',
      'Berrocal de Salvatierra',
      'Berrocal de Huebra',
      'Berrobi',
      'Berriz',
      'Berriozar',
      'Berriobeiti',
      'Berriatua',
      'Berninches',
      'Bernardos',
      'Bermillo de Sayago',
      'Bermeo',
      'Bermellar',
      'Berlangas de Roa',
      'Berlanga del Bierzo',
      'Berlanga de Duero',
      'Beriáin',
      'Bergondo',
      'Berge',
      'Bergasillas Bajera',
      'Bergasa',
      'Berga',
      'Berdejo',
      'Bercimuel',
      'Bercianos del Real Camino',
      'Bercianos del Páramo',
      'Bercial de Zapardiel',
      'Bercial',
      'Berceruelo',
      'Bercero',
      'Berceo',
      'Berbinzana',
      'Berberana',
      'Berbegal',
      'Beratón',
      'Berastegi',
      'Berantevilla',
      'Berango',
      'Benuza',
      'Benlloch',
      'Benifallet',
      'Benicàssim',
      'Benicarló',
      'Benegiles',
      'Benavides',
      'Benavente',
      'Benasque',
      'Benassal',
      'Benafigos',
      'Benafarces',
      'Benavarri / Benabarre',
      'Bembibre',
      'Belver de los Montes',
      'Belver de Cinca',
      'Belorado',
      'Belmonte de Tajo',
      'Belmonte de Campos',
      'Bellvís',
      'Bellver de Cerdanya',
      'Bellpuig',
      'Bellprat',
      'Bello',
      "Bellcaire d'Urgell",
      'Belinchón',
      'Beleña',
      'Belchite',
      'Belbimbre',
      'Belauntza',
      'Belascoáin',
      'Béjar',
      'Beizama',
      'Beire',
      'Begonte',
      'Begues',
      'Becilla de Valderaduey',
      'Becerril de la Sierra',
      'Becerril de Campos',
      'Becerreá',
      'Becedillas',
      'Becedas',
      'Beasain',
      'Beamud',
      'Beade',
      'Bea',
      'Bayubas de Arriba',
      'Bayubas de Abajo',
      'Baiona',
      'Batres',
      'Batea',
      'Bascuñana de San Pedro',
      'Bascuñana',
      'Báscones de Ojeda',
      'Basconcillos del Tozo',
      'Bàscara',
      'Basardilla',
      "Sant Esteve d'en Bas",
      'Bárzana',
      'Barruelo de Santullán',
      'Barruecopardo',
      'Barromán',
      'Barro',
      'Barrios de Colina',
      'Barriopedro',
      'Barrio de Muñó',
      'Barrado',
      'Barraco',
      'Barrachina',
      'Barracas',
      'Barjas',
      'Barillas',
      'Bargota',
      'Bareyo',
      'Bardallur',
      'Barcones',
      'Barcial del Barco',
      'Barcial de la Loma',
      'Barceo',
      'Bárcena de Pie de Concha',
      'Bárcena de Cicero',
      'Bárcena de Campos',
      'la Barceloneta',
      'Barcelona',
      'Bárcabo',
      'Barca',
      'Barbuñales',
      'Barbués',
      'Barbolla',
      'Bárboles',
      'Barbastro',
      'Barbalos',
      'Barbadillo del Pez',
      'Barbadillo del Mercado',
      'Barbadillo de Herreros',
      'Barásoain',
      'Barañáin',
      'Baralla',
      'Barajas de Melo',
      'Barajas de Madrid',
      'Baquerín de Campos',
      'Bañuelos de Bureba',
      'Bañuelos',
      'Baños de Valdearados',
      'Baños de Tajo',
      'Baños de Río Tobía',
      'Baños de Rioja',
      'Baños de Montemayor',
      'Baños de Molgas',
      'Bañón',
      'Banyoles',
      'Bañobárez',
      'Bande',
      'Banastás',
      'Bañares',
      'Baltar',
      'Baltanás',
      'Balsareny',
      'Ballobar',
      'Baliarrain',
      'Balconchán',
      'Balboa',
      'Balaguer',
      'Bailo',
      'Baides',
      'Bahabón de Esgueva',
      'Bahabón',
      'Begur',
      'Bagüés',
      'Báguena',
      'Bagà',
      'Baells',
      'Badules',
      'Bádenas',
      'Badarán',
      'Badalona',
      'Bakaiku',
      'Babilafuente',
      'Azuqueca de Henares',
      'Azuelo',
      'Azuara',
      'Azpeitia',
      'Azofra',
      'Azlor',
      'Azkoitia',
      'Azara',
      'Azagra',
      'Ayuela',
      'Aitona',
      'Ayoó de Vidriales',
      'Ayllón',
      'Aiguafreda',
      'Ayerbe',
      'Ayegui',
      'Aia',
      'Axpe-San Bartolome',
      'Avión',
      'Avinyonet del Penedès',
      'Avinyó',
      'Avinyonet de Puigventós',
      'Avilés',
      'Ávila',
      'Avià',
      'Avellanosa de Muñó',
      'Avellaneda',
      'Aveinte',
      'Autol',
      'Autillo de Campos',
      'Autilla del Pino',
      'Ausejo de la Sierra',
      'Ausejo',
      'Auñón',
      'Aulesti',
      'Atienza',
      'Ateca',
      'Atea',
      'Ataun',
      'Ataquines',
      'Atapuerca',
      'Asturianos',
      'Astudillo',
      'Astorga',
      'Astigarraga',
      'Asteasu',
      'Aspariegos',
      'Aspa',
      'Ascó',
      'Arzúa',
      'Artieda',
      'Artesa de Segre',
      'Artés',
      'Arteixo',
      'Artazu',
      'Artajona',
      'Arrúbal',
      'Arruazu',
      'Arroyomolinos de la Vera',
      'Arroyomolinos',
      'Arroyo de las Fraguas',
      'Arroyo de la Encomienda',
      'Arróniz',
      'Arrigorriaga',
      'Arredondo',
      'Arraya de Oca',
      'Arrancacepas',
      'Arrabalde',
      'Arquillinos',
      'Arnuero',
      'Arnedo',
      'Arnedillo',
      'Armuña de Tajuña',
      'Armuña',
      'Armiñón',
      'Armenteros',
      'Armañanzas',
      'Armallones',
      'Arlanzón',
      'Ariza',
      'Aribe',
      'Ariño',
      'Arija',
      'Aria',
      'Argujillo',
      'Arguis',
      'Arguedas',
      'Argoños',
      'Argentona',
      'Argente',
      'Argelita',
      'Argelaguer',
      'Argecilla',
      'Argavieso',
      'Arganza',
      'Argañín',
      'Arganda',
      'Arévalo de la Sierra',
      'Arévalo',
      'Arevalillo de Cega',
      'Areso',
      'Ares del Maestre',
      'Ares',
      'Arenzana de Arriba',
      'Arenzana de Abajo',
      'Arenys de Munt',
      'Arenys de Mar',
      'Arenys de Lledó / Arens de Lledó',
      'Arenillas',
      'Arenas de San Pedro',
      'Arenas de Iguña',
      'Arellano',
      'Aretxabaleta',
      'Ardón',
      'Arcos de la Sierra',
      'Arcos de la Polvorosa',
      'Arcos de Jalón',
      'Arcos',
      'Arcones',
      'Arconada',
      'Arcicóllar',
      'Arcenillas',
      'Arcediano',
      'Arbúcies',
      'Arbo',
      'Arbizu',
      'Arbeteta',
      'Arbeca',
      'Arbancón',
      'Araia',
      'Arauzo de Torre',
      'Arauzo de Salce',
      'Arauzo de Miel',
      'Aras',
      'Arapiles',
      'Aranzueque',
      'Arañuel',
      'Arano',
      'Aranjuez',
      'Aranga',
      'Arandilla del Arroyo',
      'Arandilla',
      'Arándiga',
      'Aranda de Moncayo',
      'Aranda de Duero',
      'Arancón',
      'Arantza',
      'Aranarache',
      'Arama',
      'Arahuetes',
      'Aragüés del Puerto',
      'Aoiz',
      'Antzuola',
      'Antillón',
      'Antigüedad',
      'Ansó',
      'Anquela del Pedregal',
      'Anquela del Ducado',
      'Añover de Tormes',
      'Añorbe',
      'Anoeta',
      'Anguix',
      'Anguita',
      'Anguiano',
      'Angüés',
      'Anguciana',
      'Anglesola',
      'Anglès',
      'Anento',
      'Añe',
      'Andosilla',
      'Andorra',
      'Andoain',
      'Ancín',
      'Anchuelo',
      'Anaya de Alba',
      'Anaya',
      'Anadón',
      'Amusquillo',
      'Amusco',
      'Amurrio',
      'Ampuero',
      'Ampudia',
      'Amposta',
      'Amoroto',
      'Amorebieta',
      'Amoeiro',
      'Amieva',
      'Amezketa',
      'Ameyugo',
      'Amés',
      'Ambite',
      'Ambía',
      'Ambel',
      'Amayuelas de Arriba',
      'Amavida',
      'Altzaga',
      'Alustante',
      'Altafulla',
      'Altable',
      'Altsasu',
      'Alkiza',
      'Alquézar',
      'Alpicat',
      'Alpens',
      'Alpeñés',
      'Alpedrete',
      'Alpartir',
      'Alpanseque',
      'Alp',
      'Alovera',
      'Alonsotegi',
      'Alocén',
      'Alobras',
      'Almuniente',
      'Almudévar',
      'Almoster',
      'Almorox',
      'Almonacid de Zorita',
      'Almonacid de la Sierra',
      'Almonacid de la Cuba',
      'Almohaja',
      'Almoguera',
      'Almochuel',
      'Almendral de la Cañada',
      'Almendra',
      'Almenar de Soria',
      'Almenara de Tormes',
      'Almenara de Adaja',
      'Almenar',
      'Almazul',
      'Almazán',
      'Almarza de Cameros',
      'Almarza',
      'Almaraz de Duero',
      'Almanza',
      'Almaluez',
      'Almajano',
      'Almadrones',
      'Almacelles',
      'Allueva',
      'Alloza',
      'Allo',
      'Allepuz',
      'Allariz',
      'Aliud',
      'Alique',
      'Alió',
      'Aliaga',
      'Alhóndiga',
      'Alhama de Aragón',
      'Alguaire',
      'Algorta',
      'Algora',
      'Algodre',
      'Algete',
      'Algerri',
      'Algar de Mesa',
      'Algadefe',
      'Alforque',
      'Alforja',
      'Alfés',
      'Alfarràs',
      'Alfaro',
      'Alfántega',
      'Alfamén',
      'Alfambra',
      'Alfajarín',
      'Alesón',
      'Alesanco',
      'Alerre',
      'Alentisque',
      'Alella',
      'Alegia',
      'Aldeonte',
      'Aldehuela de Yeltes',
      'Aldehuela de Liestos',
      'Aldehuela del Codonal',
      'Aldehuela de la Bóveda',
      'Aldeavieja de Tormes',
      'Aldeatejada',
      'Aldeasoña',
      'Aldeaseca de la Frontera',
      'Aldeaseca de Alba',
      'Aldeaseca',
      'Aldearrubia',
      'Aldearrodrigo',
      'Aldea Real',
      'Aldeanueva de Santa Cruz',
      'Aldeanueva del Codonal',
      'Aldeanueva del Camino',
      'Aldeanueva de la Vera',
      'Aldeanueva de la Sierra',
      'Aldeanueva de Guadalajara',
      'Aldeanueva de Figueroa',
      'Aldeanueva de Ebro',
      'Aldeamayor de San Martín',
      'Aldealseñor',
      'Aldealpozo',
      'Aldealengua de Santa María',
      'Aldealengua',
      'Aldealcorvo',
      'Aldealafuente',
      'Aldea de San Miguel',
      'Aldea del Obispo',
      'Aldea del Fresno',
      'Aldeadávila de la Ribera',
      'Aldeacipreste',
      'Alcubilla de Nogales',
      'Alcubilla de las Peñas',
      'Alcubilla de Avellaneda',
      'Alcubierre',
      'Alcover',
      'Alcoroches',
      'Alcorisa',
      'Alcorcón',
      "l'Alcora",
      'Alconchel de Ariza',
      'Alconada de Maderuelo',
      'Alconada',
      'Alconaba',
      'Alcoletge',
      'Alcolea del Pinar',
      'Alcolea de las Peñas',
      'Alcolea de Cinca',
      'Alcohujate',
      'Alcocero de Mola',
      'Alcocer',
      'Alcocéber',
      'Alcobendas',
      'Alcazarén',
      'Alcázar del Rey',
      'Alcarràs',
      'Alcantud',
      'Alcanó',
      'Alcañiz',
      'Alcañices',
      'Alcanar',
      'Alcanadre',
      'Alcalá de Moncayo',
      'Alcalá del Obispo',
      'Alcalá de la Vega',
      'Alcalá de la Selva',
      'Alcalá de Henares',
      'Alcalá de Gurrea',
      'Alcalá de Ebro',
      'Alcalà de Xivert',
      'Alcaine',
      'Albornos',
      'Alborge',
      'Albons',
      'Albocàsser',
      'Albiztur',
      'Albinyana',
      'Albillos',
      'Albeta',
      'Albesa',
      'Alberuela de Tubo',
      'Albero Bajo',
      'Albero Alto',
      'Alberite de San Juan',
      'Alberite',
      'Albendiego',
      'Albendea',
      'Albelda de Iregua',
      'Albelda',
      'Albarracín',
      'Albares',
      'Albalatillo',
      'Albalate de Zorita',
      'Albalate de las Nogueras',
      'Albalate del Arzobispo',
      'Albalate de Cinca',
      'Alba de Yeltes',
      'Alba de Tormes',
      'Alba de Cerrato',
      'Alba',
      'Alàs i Cerc',
      'Alarilla',
      'Alar del Rey',
      'Alarba',
      'Alaraz',
      'Alaminos',
      'Alameda del Valle',
      'Alagón',
      'Alaejos',
      'Aladrén',
      'Alacón',
      'Ajamil',
      'Ajalvir',
      'Aizarnazabal',
      'Ainzón',
      'Aínsa',
      'Aiguaviva',
      'Ahigal de Villarino',
      'Ahigal de los Aceiteros',
      'Ahigal',
      'Agullana',
      'Aguilón',
      'Aguilar de Segarra',
      'Aguilar del Río Alhama',
      'Aguilar del Alfambra',
      'Aguilar de Codés',
      'Aguilar de Campos',
      'Aguilar de Campoo',
      'Aguilar de Bureba',
      'Aguilafuente',
      'Agüero',
      'Aguaviva',
      'Aguatón',
      'Aguasal',
      'Aguarón',
      'Ágreda',
      'Agramunt',
      'Agoncillo',
      'Agón',
      'Àger',
      'Agallas',
      'Adzaneta',
      'Aduna',
      'Adrados',
      'Adradas',
      'Adrada de Pirón',
      'Adrada de Haza',
      'Adobes',
      'Adiós',
      'Ademuz',
      'Adanero',
      'Adalia',
      'Adahuesca',
      'Aceituna',
      'Acebo',
      'Acebedo',
      'Abusejo',
      'Abrera',
      'Ablitas',
      'Ablanque',
      'Abizanda',
      'Abiego',
      'Abia de las Torres',
      'Abezames',
      'Abella de la Conca',
      'Abejar',
      'Abegondo',
      'Abanto',
      'Abánades',
      'Ábalos',
      'Abaltzisketa',
      'Abajas',
      'Abáigar',
      'Abadín',
      'Abadiño',
      'Abadía',
      'Abades',
      'Ababuj',
      'Nou Barris',
      'Yerri',
      'Blancafort',
      'Pinar de Chamartín',
      'Las Tablas',
      'Santa Ponsa',
      'Valdelacalzada',
      'Areny de Noguera / Arén',
      'Sopeira',
      'el Torricó / Altorricon',
      'Playa del Ingles',
      'Puerto Rico',
      "Cala d'Or",
      'Palmanova',
      'Puerto del Carmen',
      'Magaluf',
      'Can Pastilla',
      'Peguera',
      'Port de Pollença',
      'Camp de Mar',
      'Costa Teguise',
      'La Manga del Mar Menor',
      'Playa de las Américas',
      'Las Rosas',
      'Ceuta',
      'Sopuerta',
      'Riu de Cerdanya',
      'Urús',
      'el Pont de Bar',
      'Coma-ruga',
      'Sant Cristòfol de les Fonts',
      'Alquerías del Niño Perdido',
      "Port d'Alcúdia",
      'Moncloa-Aravaca',
      'Eixample',
      'Les Corts',
      'Sarrià-Sant Gervasi',
      'Horta-Guinardó',
      'Sants-Montjuïc',
      'Sant Martí',
      'Ciutat Vella',
      'Islantilla',
      'Costa Calma',
      'Retamar',
      "S'Agaró",
      'Benalup-Casas Viejas',
      'Vecindario',
      'Ballesteros de Calatrava',
      'Arganzuela',
      'San Blas-Canillejas',
      'Latina',
      'Usera',
      'Chamberí',
      'Carabanchel',
      'Madrid Centro',
      'Retiro',
      'Barri Gòtic',
      'Forcarei',
      'Poble Sec',
      'Hostafrancs',
      'La Bordeta',
      'La Bonanova',
      'Las Tres Torres',
      'Pedralbes',
      'el Coll',
      'el Poblenou',
      'El Carmel',
      'Pego',
      "l'Alfàs del Pi",
      'Deltebre',
      'Sedella',
      'Las Gabias',
      'San José del Valle',
      'Camarles',
      'Vall de Gallinera',
      'Delicias',
      'Almozara',
      'Montecanal',
      'Oliver-Valdefierro',
      'Santutxu',
      'Fort Pienc',
      "Dreta de l'Eixample",
      'Vallcarca',
      'Sagrada Família',
      'Diagonal Mar',
      'Sant Martí de Provençals',
      'Sondika',
      'El Cotillo',
      'Los Gigantes',
      'Los Realejos',
      'Sant Guim de Freixenet',
      'Sant Joan de Mediona',
      'Pasaia',
      "L'Entregu/El Entrego",
      'El Toro',
      'Navas de San Juan',
      'Basauri',
      'Castrillón',
      'Novés',
      'Sahún',
      'Taberno',
      'Llefià',
      'Corvera de Asturias',
      'Vega del Codorno',
      'Barreiros',
      'els Poblets',
      'Navarredonda de Gredos',
      'Tres Cantos',
      'Castellar del Riu',
      'Senterada',
      'Mendexa',
      'Capolat',
      'Totalán',
      'Jete',
      'Atanzón',
      'Etxebarria',
      'Bakio',
      'Iturrama',
      'Ermitagaña',
      'Primer Ensanche',
      'Segundo Ensanche',
      'Pueblonuevo de Miramontes',
      'Les Franqueses del Vallès',
      'San Tirso de Abres',
      'Santiago de Alcántara',
      'Fuencarral-El Pardo',
      'Villa de Vallecas',
      'Moriles',
      'Natahoyo',
      'Castroviejo',
      'Daroca de Rioja',
      'Son Ferrer',
      'Liendo',
      'Villanova',
      'Gordexola',
      'Liérganes',
      'Sant Julià de Ramis',
      'Olivenza',
      'Muxika',
      'Costacabana',
      'Castellnou de Bages',
      'La Pobla de Farnals',
      'Callús',
      'Ubide',
      'Valdezorras',
      'La Gangosa Vistasol',
      'Font-Rubí',
      'Balanegra',
      'Tiétar',
      'Montbau',
      'Sant Genís dels Agudells',
      'Vallvidrera, el Tibidabo i les Planes',
      'la Marina del Prat Vermell',
      'Sarrià',
      "l'Antiga Esquerra de l'Eixample",
      'el Baix Guinardó',
      'el Parc i la Llacuna del Poblenou',
      'la Prosperitat',
      'el Putxet i el Farró',
      'Provenals del Poblenou',
      'Sant Antoni',
      'Can Baró',
      'Baró de Viver',
      'Vallbona',
      'la Vila de Gràcia',
      "la Nova Esquerra de l'Eixample",
      'Porta',
      'la Font de la Guatlla',
      'Sants - Badal',
      'la Maternitat i Sant Ramon',
      'Sant Gervasi - Galvany',
      'Verdun',
      "el Camp d'en Grassot i Gràcia Nova",
      'Can Peguera',
      'la Marina de Port',
      'Vilapicina i la Torre Llobeta',
      "el Camp de l'Arpa del Clot",
      'el Bon Pastor',
      'Canyelles',
      'la Salut',
      "la Font d'en Fargues",
      'el Congrés i els Indians',
      'la Teixonera',
      "la Vall d'Hebron",
      'la Trinitat Nova',
      'Navas',
      'la Guineueta',
      'les Roquetes',
      'Ciutat Meridiana',
      'la Trinitat Vella',
      'la Vila Olímpica del Poblenou',
      'la Verneda i la Pau',
      'el Turó de la Peira',
      'el Besòs i el Maresme',
      'el Guinardó',
      'Torre Baró',
      'Barri de Sant Andreu',
      'Barri de les Corts',
      'Sarriguren',
      'Rayaces',
      'San Enrique de Guadiaro',
      'Asin',
      'Puente la Reina de Jaca',
      'Albentosa',
      'Vilarnaz',
      'Beranuy',
      'Villamayor de Gállego',
      'Acered',
      'Argensola',
      'La Torrecilla',
      'Cazalla',
      'Cami Vell de Sant Mateu',
      'Cap Martinet',
      'Los Morillas',
      'Olabeaga',
      'Blimea',
    ],
  },
  {
    country: 'Ethiopia',
    cities: [
      'Ziway',
      'Yirga ‘Alem',
      'Yabēlo',
      'Werota',
      'Were Īlu',
      'Wenjī',
      'Wendo',
      'Turmi',
      'Tulu Bolo',
      'Tēpī',
      'Sīrē',
      'Shashemenē',
      'Shambu',
      'Shakiso',
      'Sendafa',
      'Sebeta',
      'Robīt',
      'Nejo',
      'Nazrēt',
      'Mojo',
      'Mīzan Teferī',
      'Metu',
      'Metahāra',
      'Mendī',
      "Mek'ele",
      'Mēga',
      'Maych’ew',
      'Lobuni',
      'Leku',
      'Lalībela',
      'Korem',
      'Konso',
      'K’olīto',
      'Kofelē',
      'Kibre Mengist',
      'Kemisē',
      'Kombolcha',
      'Jinka',
      'Jimma',
      'Jijiga',
      'Inda Silasē',
      'Huruta',
      'Hosa’ina',
      'Hīrna',
      'Harar',
      'Hāgere Selam',
      'Hāgere Hiywet',
      'Gorē',
      'Gondar',
      'Goba',
      'Waliso',
      'Ginir',
      'Gimbi',
      'Gīdolē',
      'Gewanē',
      'Genet',
      'Gelemso',
      'Gēdo',
      'Gebre Guracha',
      'Gambēla',
      'Finote Selam',
      'Fichē',
      'Felege Neway',
      'Dubti',
      'Dodola',
      'Dire Dawa',
      'Dīla',
      'Desē',
      'Dembī Dolo',
      'Dejen',
      'Deder',
      'Bishoftu',
      'Debre Werk’',
      'Debre Tabor',
      'Debre Sīna',
      'Debre Mark’os',
      'Debre Birhan',
      'Debark’',
      'Dabat',
      'Butajīra',
      'Burē',
      'Bonga',
      'Bodītī',
      'Bichena',
      'Bedēsa',
      'Bedelē',
      'Batī',
      'Bako',
      'Bahir Dar',
      'Āwash',
      'Hawassa',
      'Āsosa',
      'Āsbe Teferī',
      'Asaita',
      'Āsasa',
      'Āreka',
      'Arba Minch',
      'Axum',
      'Hagere Maryam',
      'Āgaro',
      'Ādīs Zemen',
      'Ādīs ‘Alem',
      'Addis Ababa',
      'Ādīgrat',
      'Ādēt',
      'Abomsa',
      'Semera',
      'Sodo',
    ],
  },
  {
    country: 'Finland',
    cities: [
      'Ytteresse',
      'Ypäjä',
      'Ylöjärvi',
      'Ylivieska',
      'Ylitornio',
      'Ylikiiminki',
      'Yli-Ii',
      'Ylihärmä',
      'Yläne',
      'Ylämaa',
      'Vuosaari',
      'Vuolijoki',
      'Vuokatti',
      'Vörå',
      'Virtasalmi',
      'Virrat',
      'Virolahti',
      'Virojoki',
      'Vinkkilä',
      'Vimpeli',
      'Vilppula',
      'Viljakkala',
      'Viitasaari',
      'Viiala',
      'Vihti',
      'Vihanti',
      'Vieremä',
      'Veteli',
      'Vesilahti',
      'Vesanto',
      'Velkua',
      'Vehmersalmi',
      'Vehmaa',
      'Västanfjärd',
      'Varpaisjärvi',
      'Varkaus',
      'Vantaa',
      'Vampula',
      'Vammala',
      'Valtimo',
      'Valkeakoski',
      'Vahto',
      'Vähäkyrö',
      'Vaasa',
      'Vaala',
      'Uusikaupunki',
      'Uurainen',
      'Utsjoki',
      'Utajärvi',
      'Urjala',
      'Ulvila',
      'Ullava',
      'Tyrnävä',
      'Tuusula',
      'Tuusniemi',
      'Tuupovaara',
      'Tuulos',
      'Turku',
      'Turenki',
      'Töysä',
      'Tornio',
      'Toivakka',
      'Akaa',
      'Toholampi',
      'Tohmajärvi',
      'Teuva',
      'Tervola',
      'Tervo',
      'Tervakoski',
      'Tarvasjoki',
      'Tampere',
      'Tammela',
      'Taivassalo',
      'Taivalkoski',
      'Taipalsaari',
      'Taavetti',
      'Sysmä',
      'Suonenjoki',
      'Suomussalmi',
      'Suomusjärvi',
      'Suolahti',
      'Suodenniemi',
      'Sumiainen',
      'Sulkava',
      'Sotkamo',
      'Sonkajärvi',
      'Somero',
      'Soini',
      'Sodankylä',
      'Smedsby',
      'Siuntio',
      'Simpele',
      'Simo',
      'Broby',
      'Siilinjärvi',
      'Siikajoki',
      'Siikainen',
      'Sievi',
      'Sibbo',
      'Seinäjoki',
      'Säynätsalo',
      'Savukoski',
      'Savonranta',
      'Savonlinna',
      'Savitaipale',
      'Sauvo',
      'Saukkola',
      'Särkisalo',
      'Sandsund',
      'Sammatti',
      'Salo',
      'Salla',
      'Säkylä',
      'Sahalahti',
      'Saarijärvi',
      'Saari',
      'Rymättylä',
      'Ruukki',
      'Rusko',
      'Ruovesi',
      'Ruotsinpyhtää',
      'Ruokolahti',
      'Rovaniemi',
      'Ristijärvi',
      'Ristiina',
      'Riihimäki',
      'Replot',
      'Renko',
      'Reisjärvi',
      'Rautjärvi',
      'Rautavaara',
      'Rautalampi',
      'Rauma',
      'Ranua',
      'Rantsila',
      'Rantasalmi',
      'Raisio',
      'Rääkkylä',
      'Raahe',
      'Pylkönmäki',
      'Pyhtää',
      'Pyhäselkä',
      'Pyhäsalmi',
      'Pyhäranta',
      'Pyhäntä',
      'Pyhäjoki',
      'Pyhäjärvi',
      'Puumala',
      'Puolanka',
      'Punkalaidun',
      'Punkaharju',
      'Pulkkila',
      'Pukkila',
      'Pudasjärvi',
      'Pöytyä',
      'Posio',
      'Pornainen',
      'Pori',
      'Pomarkku',
      'Polvijärvi',
      'Pohja',
      'Pirkkala',
      'Piippola',
      'Piikkiö',
      'Pihtipudas',
      'Pielavesi',
      'Pieksämäki',
      'Petäjävesi',
      'Pertunmaa',
      'Pertteli',
      'Perniö',
      'Pernå',
      'Perho',
      'Pello',
      'Pelkosenniemi',
      'Pedersöre',
      'Parola',
      'Parkano',
      'Parikkala',
      'Pargas',
      'Paltamo',
      'Pälkäne',
      'Paimio',
      'Padasjoki',
      'Outokumpu',
      'Oulunsalo',
      'Oulu',
      'Oulainen',
      'Otaniemi',
      'Orivesi',
      'Oripää',
      'Orimattila',
      'Oravais',
      'Oitti',
      'Nykarleby',
      'Nurmijärvi',
      'Nurmes',
      'Nuijamaa',
      'Nousiainen',
      'Noormarkku',
      'Nokia',
      'Nivala',
      'Nilsiä',
      'Nickby',
      'Nastola',
      'Närpes',
      'Nakkila',
      'Nagu',
      'Naantali',
      'Myrskylä',
      'Mynämäki',
      'Muurla',
      'Muurame',
      'Muonio',
      'Munkkiniemi',
      'Multia',
      'Muhos',
      'Mouhijärvi',
      'Mikkeli',
      'Mietoinen',
      'Miehikkälä',
      'Merimasku',
      'Merikarvia',
      'Merijärvi',
      'Mellunkylä',
      'Mellilä',
      'Masku',
      'Marttila',
      'Mäntyharju',
      'Mänttä',
      'Mäntsälä',
      'Malax',
      'Maaninka',
      'Luvia',
      'Luumäki',
      'Luopioinen',
      'Lumijoki',
      'Luhanka',
      'Lovisa',
      'Loppi',
      'Loimaa',
      'Lohtaja',
      'Lohja',
      'Liperi',
      'Liminka',
      'Liljendal',
      'Lieto',
      'Lieksa',
      'Lestijärvi',
      'Leppävirta',
      'Lemu',
      'Lempäälä',
      'Lemi',
      'Leivonmäki',
      'Lavia',
      'Lauttasaari',
      'Laukaa',
      'Larsmo',
      'Lapua',
      'Lappi',
      'Lappeenranta',
      'Lappajärvi',
      'Lapinlahti',
      'Lapinjärvi',
      'Längelmäki',
      'Lammi',
      'Laitila',
      'Laihia',
      'Lahti',
      'Kyyjärvi',
      'Kyrö',
      'Kirkkonummi',
      'Kylmäkoski',
      'Kuusjoki',
      'Kuusamo',
      'Kustavi',
      'Kuru',
      'Kurikka',
      'Kuortane',
      'Kuopio',
      'Kullaa',
      'Kuivaniemi',
      'Kuhmoinen',
      'Kuhmo',
      'Kuhmalahti',
      'Kronoby',
      'Kristinestad',
      'Köyliö',
      'Kouvola',
      'Kotka',
      'Hämeenkoski',
      'Koski Tl',
      'Kortesjärvi',
      'Korsnäs',
      'Korsholm',
      'Korpilahti',
      'Koria',
      'Kontiolahti',
      'Konnevesi',
      'Kolari',
      'Kokkola',
      'Kokemäki',
      'Klaukkala',
      'Kivijärvi',
      'Kiuruvesi',
      'Kiukainen',
      'Kittilä',
      'Kitee',
      'Kisko',
      'Kinnula',
      'Kimito',
      'Kiiminki',
      'Kiikoinen',
      'Kiikala',
      'Kiihtelysvaara',
      'Kihniö',
      'Keuruu',
      'Kestilä',
      'Kesälahti',
      'Kerimäki',
      'Kerava',
      'Kempele',
      'Kemijärvi',
      'Keminmaa',
      'Kemi',
      'Kellokoski',
      'Keitele',
      'Kaustinen',
      'Kauniainen',
      'Kauhava',
      'Kauhajoki',
      'Kaskinen',
      'Karvia',
      'Karttula',
      'Karstula',
      'Kärsämäki',
      'Kärkölä',
      'Karkkila',
      'Karjalohja',
      'Karis',
      'Karinainen',
      'Karijoki',
      'Karhula',
      'Kannus',
      'Kannonkoski',
      'Kankaanpää',
      'Kangasniemi',
      'Kangaslampi',
      'Kangasala',
      'Kalvola',
      'Kälviä',
      'Kallio',
      'Kållby',
      'Kalajoki',
      'Kajaani',
      'Kaavi',
      'Kaarina',
      'Kaarela',
      'Jyväskylä',
      'Juva',
      'Juupajoki',
      'Juuka',
      'Jurva',
      'Juankoski',
      'Joutseno',
      'Joutsa',
      'Joroinen',
      'Jokioinen',
      'Joensuu',
      'Järvenpää',
      'Järvelä',
      'Jäppilä',
      'Janakkala',
      'Jämsänkoski',
      'Jämsä',
      'Jämijärvi',
      'Jalasjärvi',
      'Jakobstad',
      'Jaala',
      'Ivalo',
      'Isokyrö',
      'Isojoki',
      'Iniö',
      'Ingå',
      'Inari',
      'Imatra',
      'Ilomantsi',
      'Ilmajoki',
      'Ikaalinen',
      'Iitti',
      'Iisalmi',
      'Ii',
      'Hyvinge',
      'Hyrynsalmi',
      'Humppila',
      'Huittinen',
      'Houtskär',
      'Honkajoki',
      'Hollola',
      'Hirvensalmi',
      'Himanka',
      'Helsinki',
      'Heinola',
      'Heinävesi',
      'Hausjärvi',
      'Haukivuori',
      'Haukipudas',
      'Hauho',
      'Hartola',
      'Harjavalta',
      'Hankasalmi',
      'Hanko',
      'Hamina',
      'Hämeenlinna',
      'Hämeenkyrö',
      'Halsua',
      'Halikko',
      'Hailuoto',
      'Haapavesi',
      'Haapajärvi',
      'Gumböle',
      'Forssa',
      'Evijärvi',
      'Eurajoki',
      'Eura',
      'Espoo',
      'Enontekiö',
      'Enonkoski',
      'Eno',
      'Elimäki',
      'Ekenäs',
      'Dragsfjärd',
      'Porvoo',
      'Bennäs',
      'Auttoinen',
      'Aura',
      'Askola',
      'Askainen',
      'Asikkala',
      'Artjärvi',
      'Anjala',
      'Alavus',
      'Alavieska',
      'Alastaro',
      'Alajärvi',
      'Alahärmä',
      'Ähtäri',
      'Äänekoski',
      'Äetsä',
      'Kilo',
      'Koukkuniemi',
      'Ristinummi',
      'Teeriniemi',
      'Hietalahti',
      'Toijala',
      'Väståboland',
      'Älajärvi',
      'Vuores',
    ],
  },
  {
    country: 'Fiji',
    cities: [
      'Suva',
      'Nadi',
      'Levuka',
      'Lautoka',
      'Labasa',
      'Ahau',
      'Tubou',
      'Ba',
    ],
  },
  { country: 'Falkland Islands', cities: ['Stanley'] },
  {
    country: 'Micronesia',
    cities: [
      'Weno',
      'Tofol',
      'Palikir - National Government Center',
      'Kolonia Town',
      'Rumung',
      'Kolonia',
      'Kapingamarangi',
      'Nukuoro',
      'Ngatik',
      'Pingelap',
      'Namoluk',
      'Houk',
      'Polowat',
      'Pulap',
      'Tamatam',
      'Onoun',
      'Pisaras',
      'Onou',
      'Unanu',
      'Makur',
      'Mokil',
      'Nomwin',
      'Fananu',
      'Murilo',
      'Ruo',
      'Nema',
      'Losap',
      'Piis',
      'Ettal',
      'Lukunor',
      'Oneop',
      'Moch',
      'Kuttu',
      'Ta',
      'Satowan',
      'Satawal',
      'Lamotrek',
      'Elato',
      'Faraulep',
      'Ifalik',
      'Eauripik',
      'Sorol',
      'Fais',
      'Ngulu',
      'Colonia',
      'Lelu',
      'Tafunsak',
      'Malem',
      'Utwe',
      'Madolenihm Municipality Government',
      'Tonoas Municipal Building',
    ],
  },
  {
    country: 'Faroe Islands',
    cities: [
      'Viðareiði',
      'Vestmanna',
      'Vágur',
      'Tvøroyri',
      'Tórshavn',
      'Sørvágur',
      'Skúvoy',
      'Skopun',
      'Skálavík',
      'Sandur',
      'Sandavágur',
      'Porkeri',
      'Miðvágur',
      'Kunoy',
      'Kollafjørður',
      'Klaksvík',
      'Kirkja',
      'Hvalba',
      'Hoyvík',
      'Hov',
      'Fuglafjørður',
      'Eiði',
      'Argir',
      'Eystur',
    ],
  },
  {
    country: 'France',
    cities: [
      'Peyrat-le-Château',
      'Blaye',
      'Zuydcoote',
      'Zutkerque',
      'Zonza',
      'Zimmersheim',
      'Zillisheim',
      'Zegerscappel',
      'Yzeures-sur-Creuse',
      'Yzeure',
      'Yzernay',
      'Yvré-le-Pôlin',
      'Yvrac',
      'Yvignac-la-Tour',
      'Yvetot',
      'Yves',
      'Ytrac',
      'Yssingeaux',
      'Yport',
      'Youx',
      'Ymare',
      'Ygos-Saint-Saturnin',
      'Yffiniac',
      'Yèvres',
      'Yerville',
      'Yerres',
      'Yenne',
      'Yébleron',
      'Ydes',
      'Ychoux',
      'Yainville',
      'Xonrupt-Longemer',
      'Xertigny',
      'Woustviller',
      'Wormhout',
      'Wolfisheim',
      'Wolfgantzen',
      'Woippy',
      'Woincourt',
      'Woerth',
      'Wizernes',
      'Wittisheim',
      'Wittenheim',
      'Wittelsheim',
      'Witry-lès-Reims',
      'Wissous',
      'Wissembourg',
      'Wissant',
      'Wisches',
      'Wintzenheim',
      'Winnezeele',
      'Wingles',
      'Wingersheim',
      'Wingen-sur-Moder',
      'Wimmenau',
      'Wimille',
      'Wimereux',
      'Willerwald',
      'Willems',
      'Wihr-au-Val',
      'Wignehies',
      'Wiesviller',
      'Widensolen',
      'Weyersheim',
      'Wettolsheim',
      'Westhoffen',
      'Wervicq-Sud',
      'Weitbruch',
      'Waziers',
      'Wavrin',
      'Wavrechain-sous-Denain',
      'Wavignies',
      'Wattwiller',
      'Wattrelos',
      'Wattignies',
      'Watten',
      'Wassy',
      'Wassigny',
      'Wasselonne',
      'Wasquehal',
      'Warmeriville',
      'Warluis',
      'Warhem',
      'Wargnies-le-Grand',
      'Wardrecques',
      'Warcq',
      'Wangenbourg-Engenthal',
      'Wandignies-Hamage',
      'Wambrechies',
      'Walscheid',
      'Wallers',
      'Walincourt-Selvigny',
      'Waldighofen',
      'Walbourg',
      'Wailly',
      'Wahagnies',
      'Vulaines-sur-Seine',
      'Vue',
      'Vrigne-aux-Bois',
      'Vred',
      'Voves',
      'Vouzon',
      'Vouziers',
      'Vouvray',
      'Voutezac',
      'Vourles',
      'Vourey',
      'Vouneuil-sur-Vienne',
      'Vouneuil-sous-Biard',
      'Voulx',
      'Voulangis',
      'Voujeaucourt',
      'Vouillé',
      'Vougy',
      'Vorey',
      'Voreppe',
      'Vonnas',
      'Volx',
      'Volvic',
      'Volstroff',
      'Volonne',
      'Volmerange-les-Mines',
      'Voisins-le-Bretonneux',
      'Voisenon',
      'Voiron',
      'Void-Vacon',
      'Voglans',
      'Volgelsheim',
      'Vœuil-et-Giget',
      'Vizille',
      'Vix',
      'Vivy',
      'Vivonne',
      'Viviez',
      'Viviers-lès-Montagnes',
      'Viviers',
      'Vivier-au-Court',
      'Viuz-la-Chiésaz',
      'Viuz-en-Sallaz',
      'Vittel',
      'Vitteaux',
      'Vitry-sur-Seine',
      'Vitry-sur-Orne',
      'Vitry-le-François',
      'Vitry-en-Artois',
      'Vitry-aux-Loges',
      'Vitrolles',
      'Vitré',
      'Visan',
      'Viry-Noureuil',
      'Viry-Châtillon',
      'Viry',
      'Viroflay',
      'Viriville',
      'Virieu-le-Grand',
      'Virieu',
      'Viriat',
      'Virey-le-Grand',
      'Vireux-Wallerand',
      'Vireux-Molhain',
      'Vire',
      'Virazeil',
      'Vion',
      'Violès',
      'Violay',
      'Violaines',
      'Vinsobres',
      'Vinon-sur-Verdon',
      'Vinneuf',
      'Vineuil-Saint-Firmin',
      'Vineuil',
      'Vincey',
      'Vincennes',
      'Vinça',
      'Vinay',
      'Vinassan',
      'Vimy',
      'Vimoutiers',
      'Vimory',
      'Vimines',
      'Villiers-sur-Orge',
      'Villiers-sur-Morin',
      'Villiers-sur-Marne',
      'Villiers-sur-Loir',
      'Villiers-Saint-Georges',
      'Villiers-Saint-Fréderic',
      'Villiers-le-Morhier',
      'Villiers-le-Bel',
      'Villiers-le-Bâcle',
      'Villiers-en-Plaine',
      'Villiers-en-Lieu',
      'Villié-Morgon',
      'Villey-Saint-Étienne',
      'Villevocance',
      'Villevieille',
      'Villeveyrac',
      'Villevêque',
      'Villevaudé',
      'Villeurbanne',
      'Villette-de-Vienne',
      'Villetelle',
      'Villetaneuse',
      'Villes-sur-Auzon',
      'Ville-sous-la-Ferté',
      'Ville-sous-Anjou',
      'Villerupt',
      'Villers-sur-Mer',
      'Villers-sur-Coudun',
      'Villers-sous-Saint-Leu',
      'Villers-Semeuse',
      'Villers-Saint-Paul',
      'Villers-Pol',
      'Villers-Outréaux',
      'Villers-lès-Nancy',
      'Villers-le-Lac',
      'Villers-la-Montagne',
      'Villersexel',
      'Villers-en-Cauchies',
      'Villers-Écalles',
      'Villers-Cotterêts',
      'Villers-Bretonneux',
      'Villers-Bocage',
      'Villereversure',
      'Villerest',
      'Villeréal',
      'Villepreux',
      'Villepinte',
      'Villeparisis',
      'Villenoy',
      'Villenouvelle',
      'Villennes-sur-Seine',
      'Villeneuve-Tolosane',
      'Villeneuve-sur-Yonne',
      'Villeneuve-sur-Lot',
      'Villeneuve-sur-Bellot',
      'Villeneuve-Saint-Germain',
      'Villeneuve-Saint-Georges',
      'Villeneuve-Loubet',
      'Villeneuve-les-Sablons',
      'Villeneuve-lès-Maguelone',
      'Villeneuve-lès-Bouloc',
      'Villeneuve-lès-Béziers',
      'Villeneuve-lès-Avignon',
      'Villeneuve-le-Roi',
      'Villeneuve-le-Comte',
      'Villeneuve-la-Rivière',
      'Villeneuve-la-Guyard',
      'Villeneuve-la-Garenne',
      'Villeneuve-la-Comptal',
      "Villeneuve-d'Olmes",
      'Villeneuve-de-Rivière',
      'Villeneuve-de-Marsan',
      'Villeneuve-de-la-Raho',
      'Villeneuve-de-Berg',
      'Villeneuve',
      "Villenave-d'Ornon",
      'Villenauxe-la-Grande',
      'Villemur-sur-Tarn',
      'Villemoustaussou',
      'Villemomble',
      'Villemolaque',
      'Villemoisson-sur-Orge',
      'Villemoirieu',
      'Villemeux-sur-Eure',
      'Villemandeur',
      'Vilallonga dels Monts',
      'Villelongue-de-la-Salanque',
      'Villelaure',
      'Ville-la-Grand',
      'Villejust',
      'Villejuif',
      'Villegouge',
      'Villegailhenc',
      'Villefranque',
      'Villefranche-sur-Saône',
      'Villefranche-sur-Mer',
      'Villefranche-de-Rouergue',
      'Villefranche-de-Lauragais',
      "Villefranche-d'Allier",
      "Villefranche-d'Albigeois",
      'Villefranche-sur-Cher',
      'Villefontaine',
      'Villefagnan',
      'Villedômer',
      'Villedieu-sur-Indre',
      'Villedieu-les-Poêles',
      'Villedieu-la-Blouère',
      "Ville-d'Avray",
      'Villecroze',
      'Villecresnes',
      'Villebret',
      'Villebon-sur-Yvette',
      'Villebois',
      'Villeblevin',
      'Villebernier',
      'Villebarou',
      'Villé',
      'Villaz',
      'Villaudric',
      'Villars-les-Dombes',
      'Villar-Saint-Pancrace',
      'Villars',
      'Villargondran',
      'Villard-de-Lans',
      'Villard-Bonnot',
      'Villandry',
      'Villaines-la-Juhel',
      'Village-Neuf',
      'Villabé',
      'Vihiers',
      'Vigy',
      'Vigny',
      'Vignoux-sur-Barangeon',
      'Vignot',
      'Vignoc',
      'Vigneux-sur-Seine',
      'Vigneux-de-Bretagne',
      'Vigneulles-lès-Hattonchâtel',
      'Vignacourt',
      'Vigeois',
      'Vif',
      'Vieux-Thann',
      'Le Vieux-Marché',
      'Vieux-Condé',
      'Vieux-Charmont',
      'Vieux-Boucau-les-Bains',
      'Vieux-Berquin',
      'Viesly',
      'Vierzon',
      'Vienne-en-Val',
      'Vienne',
      'Viennay',
      'Vielmur-sur-Agout',
      'Vielle-Saint-Girons',
      'Vieillevigne',
      'Vieille-Toulouse',
      'Vieille-Église',
      'Vieille Chapelle',
      'Vieille-Brioude',
      'Vidauban',
      'Vic-sur-Seille',
      'Vic-sur-Cère',
      'Vic-sur-Aisne',
      'Vicq-sur-Breuilh',
      'Vicq',
      'Vic-le-Comte',
      'Vic-la-Gardiole',
      'Vichy',
      'Vic-Fezensac',
      'Vic-en-Bigorre',
      'Vibraye',
      'Vias',
      'Viarmes',
      'Vianne',
      'Vezins',
      'Vezin-le-Coquet',
      'Vézénobres',
      'Vézelise',
      'Veyrier-du-Lac',
      'Veyre-Monton',
      'Veyras',
      'Veyrac',
      'Veynes',
      'Veurey-Voroize',
      'Vétraz-Monthoux',
      'Vestric-et-Candiac',
      'Vesseaux',
      'Vesoul',
      'Vescovato',
      'Verzy',
      'Verzenay',
      'Vervins',
      'Vertus',
      'Vert-Saint-Denis',
      'Vertou',
      'Verton',
      'Vert-le-Petit',
      'Vert-le-Grand',
      'Vertheuil',
      'Vert-en-Drouais',
      'Vertaizon',
      'Ver-sur-Mer',
      'Ver-sur-Launette',
      'Versonnex',
      'Verson',
      'Versailles',
      'Vers-Pont-du-Gard',
      'Verrières-le-Buisson',
      'Verrières',
      'Verquin',
      'Véron',
      'Verny',
      'Vern-sur-Seiche',
      'Vernoux-en-Vivarais',
      'Vernou-la-Celle-sur-Seine',
      'Vernou-sur-Brenne',
      'Vernouillet',
      'Vernosc-lès-Annonay',
      'Vernon',
      'Vernoil-le-Fourrier',
      'Vernioz',
      'Verniolle',
      'Verneuil-sur-Vienne',
      'Verneuil-sur-Seine',
      'Verneuil-sur-Avre',
      'Verneuil-en-Halatte',
      'Vernet-les-Bains',
      'Vernègues',
      "Vern-d'Anjou",
      'Vernantes',
      'Vernaison',
      'Vermenton',
      'Vermelles',
      'Vermand',
      'Verlinghem',
      'Vérines',
      'Vergt',
      'Vergongheon',
      'Vergigny',
      'Vergèze',
      'Verfeil',
      'Véretz',
      'Verdun',
      'Verdun-sur-le-Doubs',
      'Verdun-sur-Garonne',
      'Vercel-Villedieu-le-Camp',
      'Verberie',
      'Ventiseri',
      'Ventabren',
      'Venoy',
      'Vennecy',
      'Venizel',
      'Vénissieux',
      'Veneux-les-Sablons',
      'Venette',
      'Venerque',
      'Venelles',
      'Vénéjan',
      'Vendres',
      'Vendrennes',
      'Vendôme',
      'Vendœuvres',
      'Vendin-le-Vieil',
      'Vendin-lès-Béthune',
      'Vendeville',
      'Vendeuvre-sur-Barse',
      'Vendeuvre-du-Poitou',
      'Vendenheim',
      'Vendegies-sur-Écaillon',
      'Vendays-Montalivet',
      'Vendat',
      'Vendargues',
      'Vence',
      'Venasque',
      'Venarey-les-Laumes',
      'Venansault',
      'Vémars',
      'Velleron',
      'Vélizy-Villacoublay',
      'Vélines',
      'Velaux',
      'Velars-sur-Ouche',
      'Velaines',
      'Velaine-en-Haye',
      'Veigy',
      'Veigné',
      'Vedène',
      'Vecoux',
      'Veauche',
      'Vayres',
      'Vayrac',
      'Vay',
      'Vaux-sur-Seine',
      'Vaux-sur-Mer',
      'Vaux-le-Pénil',
      'Vaux-en-Bugey',
      'Vauvert',
      'Vauréal',
      'Vaumoise',
      'Vaulx-Vraucourt',
      'Vaulx-Milieu',
      'Vaulx-en-Velin',
      'Vaulnaveys-le-Haut',
      'Vaulnaveys-le-Bas',
      'Vaujours',
      'Vauhallan',
      'Vaugrigneuse',
      'Vaugneray',
      'Vaudry',
      'Vaudelnay',
      'Vaucresson',
      'Vaucouleurs',
      'Vauchrétien',
      'Vatan',
      'Vassy',
      'Vasselay',
      'Vasles',
      'Varzy',
      'Vars',
      'Varreddes',
      'Varrains',
      'Varois-et-Chaignot',
      'Varilhes',
      'Varetz',
      'Varennes-sur-Seine',
      'Varennes-sur-Loire',
      'Varennes-sur-Allier',
      'Varennes-Saint-Sauveur',
      'Varennes-le-Grand',
      'Varennes-Jarcy',
      'Varengeville-sur-Mer',
      'Varangéville',
      'Varades',
      'Vanves',
      'Vannes',
      'Vandœuvre-lès-Nancy',
      'Vals-près-le-Puy',
      'Vals-les-Bains',
      'Valros',
      'Valréas',
      'Valras-Plage',
      'Valognes',
      'Valmont',
      'Valmondois',
      "Vallon-Pont-d'Arc",
      'Vallon-en-Sully',
      'Valloire',
      'Valliquerville',
      'Vallières',
      'Vallet',
      'Valleroy',
      'Valleraugue',
      'Valleiry',
      'Vallauris',
      'Vallabrègues',
      'Valframbert',
      'Valfin-lès-Saint-Claude',
      'Valff',
      'Valergues',
      'Valenton',
      'Valentigney',
      'Valensole',
      'Valencin',
      'Valenciennes',
      'Valence-sur-Baïse',
      "Valence-d'Albigeois",
      'Valence',
      'Valençay',
      'Valdoie',
      "Val-d'Isère",
      'Valbonne',
      'Valady',
      'Vaivre-et-Montoille',
      'Vaison-la-Romaine',
      'Vaires-sur-Marne',
      'Vairé',
      'Vailly-sur-Aisne',
      'Vailhauquès',
      'Vaiges',
      'Vagney',
      'Vacquiers',
      'Vacqueyras',
      'Vacon',
      'Vaas',
      'Uzès',
      'Uzerche',
      'Uzemain',
      'Uzein',
      'Uxem',
      'Uxegney',
      'Ustaritz',
      'Usson-en-Forez',
      'Usson-du-Poitou',
      'Ussel',
      'Ussac',
      'Us',
      'Urzy',
      'Urville-Nacqueville',
      'Urt',
      'Urrugne',
      'Urmatt',
      'Uriménil',
      'Urcuit',
      'Upie',
      'Unverre',
      'Unieux',
      'Ungersheim',
      'Ully-Saint-Georges',
      'Ugine',
      'Uffholtz',
      'Uckange',
      'Uchaux',
      'Uchaud',
      'Uberach',
      'Turretot',
      'Turckheim',
      'Tullins',
      'Tulle',
      'Tulette',
      'Tuffé',
      'Tucquegnieux',
      'Truyes',
      'Trun',
      'Truchtersheim',
      'Troyes',
      'Trouy',
      'Trouville-sur-Mer',
      'Trouillas',
      'Trosly-Breuil',
      'Tronville-en-Barrois',
      'Troissereux',
      'Troarn',
      'Trizay',
      'Trith-Saint-Léger',
      'Trilport',
      'Triguères',
      'Trignac',
      'Trieux',
      'Triel-sur-Seine',
      'Trie-Château',
      'Trie-sur-Baïse',
      'Tricot',
      'Triaize',
      'Trévoux',
      'Trévou-Tréguignec',
      'Trévol',
      'Trévé',
      'Trets',
      'Tressin',
      'Tresses',
      'Tresserve',
      'Tressange',
      'Tresques',
      'Tresbœuf',
      'Trept',
      'Tréon',
      'Trémuson',
      'Tréméven',
      'Trémery',
      'Trémentines',
      'Tremblay-en-France',
      'Tremblay',
      'Trélon',
      'Trélivan',
      'Trélissac',
      'Trélévern',
      'Trélazé',
      'Treize-Septiers',
      'Treillières',
      'Treignac',
      'Trégunc',
      'Tréguier',
      'Trégastel',
      'Treffort-Cuisiat',
      'Treffléan',
      'Treffiagat',
      'Trédrez-Locquémeau',
      'Trédarzec',
      'Trébeurden',
      'Trèbes',
      'Trappes',
      'Trans-en-Provence',
      'Trangé',
      'Tramoyes',
      'Traînou',
      'Tracy-le-Mont',
      'Touvre',
      'Touvois',
      'Toutlemonde',
      'Toutainville',
      'Toussieu',
      'Toury',
      'Tourville-sur-Odon',
      'Tourville-sur-Arques',
      'Tourville-la-Rivière',
      'Tourves',
      'Tours-sur-Marne',
      'Tours',
      'Tourrette-Levens',
      'Tourouvre',
      'Tournus',
      'Tournon-Saint-Martin',
      'Tournon-sur-Rhône',
      'Tournes',
      'Tournehem-sur-la-Hem',
      'Tournefeuille',
      'Tournay',
      'Tournan-en-Brie',
      'Tourlaville',
      'Tourrettes-sur-Loup',
      'Tourcoing',
      'Tourbes',
      'Touquin',
      'Touques',
      'Toulouse',
      'Toulouges',
      'Toulon-sur-Arroux',
      'Toulon-sur-Allier',
      'Toulon',
      'Toulenne',
      'Toulaud',
      'Toul',
      'Toufflers',
      'Toucy',
      'Tôtes',
      'Tossiat',
      'Tosse',
      'Torreilles',
      'Torigni-sur-Vire',
      'Torfou',
      'Torcy',
      'Torcé',
      'Tonquédec',
      'Tonnerre',
      'Tonneins',
      'Tonnay-Charente',
      'Tonnay-Boutonne',
      'Tomblaine',
      'Tollevast',
      'Tocane-Saint-Apre',
      'Figari',
      'Tinténiac',
      'Tinqueux',
      'Tinchebray',
      'Tilques',
      'Tilly-sur-Seulles',
      'Tilloy-lès-Mofflaines',
      'Tillières-sur-Avre',
      'Tillières',
      'Tillé',
      'Tigy',
      'Tignieu',
      'Tignes',
      'Tigery',
      'Tiffauges',
      'Tiercé',
      'Thyez',
      'Thury-Harcourt',
      'Thurins',
      'Thuré',
      'Thun-Saint-Amand',
      'Thumeries',
      'Thuir',
      'Thueyts',
      'Thuellin',
      'Thourotte',
      'Thouars',
      'Thouaré-sur-Loire',
      'Thouarcé',
      'Thorigny-sur-Oreuse',
      'Thorigny-sur-Marne',
      'Thorigné-sur-Dué',
      'Thorigné',
      'Thorens-Glières',
      'Thonon-les-Bains',
      'Thônes',
      'Thomery',
      'Thoissey',
      'Thoiry',
      'Thizy-les-Bourgs',
      'Thiviers',
      'Thiverval-Grignon',
      'Thiverny',
      'Thise',
      'Thiron Gardais',
      'Thionville',
      'Thilouze',
      'Thilay',
      'Thil',
      'Thierville-sur-Meuse',
      'Thiers-sur-Thève',
      'Thiers',
      'Thiberville',
      'Thiant',
      'Thiais',
      'Thézan-lès-Béziers',
      'Théza',
      'Theys',
      'Thésée',
      'Thérouanne',
      'Théoule-sur-Mer',
      'Thenon',
      'Thénezay',
      'Thénac',
      'Thélus',
      'Theizé',
      'Theix',
      'Theillay',
      'Théding',
      'Thaon-les-Vosges',
      'Thaon',
      'Thann',
      'Thairé',
      'Teyran',
      'Teting-sur-Nied',
      'Téteghem',
      'Tessy-sur-Vire',
      'Tessé-la-Madeleine',
      'Terville',
      'Terrasson-Lavilledieu',
      'Ternay',
      'Tergnier',
      'Tercis-les-Bains',
      'Tercé',
      'Tenteling',
      'Tennie',
      'Tende',
      'Tencin',
      'Tence',
      'Tenay',
      'Templeuve-en-Pévèle',
      'Templemars',
      'Teloché',
      'Telgruc-sur-Mer',
      'Teillé',
      'Tavers',
      'Taverny',
      'Tavel',
      'Tavaux',
      'Tauxigny',
      'Tauriac',
      'Taupont',
      'Taulignan',
      'Taulé',
      'Tatinghem',
      'Tassin-la-Demi-Lune',
      'Tartas',
      'Tarnos',
      'Targon',
      'Tarbes',
      'Tarascon-sur-Ariège',
      'Tarascon',
      'Tarare',
      'Taradeau',
      'Taponnat',
      'Tanneron',
      'Tanlay',
      'Taninges',
      'Tancarville',
      'Taluyers',
      'Talmas',
      'Talloires',
      'Tallende',
      'Tallard',
      'Talensac',
      'Talence',
      'Talant',
      'Talange',
      'Taissy',
      'Taintrux',
      "Tain-l'Hermitage",
      'Taillecourt',
      'Taillades',
      'Taden',
      'Tacoignières',
      'Tabanac',
      'Suze-la-Rousse',
      'Sussargues',
      'Surzur',
      'Sury-le-Comtal',
      'Survilliers',
      'Surtainville',
      'Surgères',
      'Suresnes',
      'Surbourg',
      'Sundhouse',
      'Sundhoffen',
      'Sumène',
      'Sulniac',
      'Sully-sur-Loire',
      'Suippes',
      'Suèvres',
      'Sucy-en-Brie',
      'Sucé-sur-Erdre',
      'Strasbourg',
      'Stotzheim',
      'Stosswihr',
      'Stiring-Wendel',
      'Still',
      'Stenay',
      'Steinbourg',
      'Steinbach',
      'Steenwerck',
      'Steenvoorde',
      'Steene',
      'Steenbecque',
      'Stains',
      'Staffelfelden',
      'Spycker',
      'Spicheren',
      'Spézet',
      'Spéracèdes',
      'Spay',
      'Soyons',
      'Soyaux',
      'Souvigny',
      'Soustons',
      'Soussans',
      'Sourzac',
      'Sours',
      'Sourdun',
      'Sourdeval',
      'Sourcieux-les-Mines',
      'Souraïde',
      'Souprosse',
      'Souppes-sur-Loing',
      'Soumoulou',
      'Soultz-sous-Forêts',
      'Soultzmatt',
      'Soultz-Haut-Rhin',
      'Soultzeren',
      'Soullans',
      'Souligné-sous-Ballon',
      'Soulgé-sur-Ouette',
      'Soulaire',
      'Soulaines-sur-Aubance',
      'Soulac-sur-Mer',
      'Souillac',
      'Soufflenheim',
      'Souffelweyersheim',
      'Souesmes',
      'Soues',
      'Soudan',
      'Soucy',
      'Soucieu-en-Jarrest',
      'Soucht',
      'Souchez',
      'Soucelles',
      'Soubise',
      'Soual',
      'Sotteville-lès-Rouen',
      'Sottevast',
      'Sospel',
      'Sornay',
      'Sormiou',
      'Sorigny',
      'Sorgues',
      'Sorges',
      'Sorèze',
      'Sorel-Moussel',
      'Sorède',
      'Sorbiers',
      'Soorts',
      'Sonzay',
      'Sonnaz',
      'Sonnay',
      'Songeons',
      'Sonchamp',
      'Sommières',
      'Somain',
      'Solre-le-Château',
      'Solliès-Ville',
      'Solliès-Toucas',
      'Solliès-Pont',
      'Solignac-sur-Loire',
      'Solignac',
      'Soliers',
      'Solgne',
      'Solesmes',
      'Solers',
      'Solaize',
      'Soisy-sur-Seine',
      'Soisy-sur-École',
      'Soisy-sous-Montmorency',
      'Soissons',
      'Soings-en-Sologne',
      'Soignolles-en-Brie',
      'Socx',
      'Sochaux',
      'Smarves',
      'Sizun',
      'Sixt-sur-Aff',
      'Six-Fours-les-Plages',
      'Sisteron',
      'Sissonne',
      'Sisco',
      'Sireuil',
      'Sion-les-Mines',
      'Sin-le-Noble',
      'Sinceny',
      'Simiane-Collongue',
      'Simandres',
      'Simandre',
      'Silly-le-Long',
      'Sillingy',
      'Sillery',
      'Sillé-le-Guillaume',
      'Sillans',
      'Sigolsheim',
      'Signy-le-Petit',
      'Signes',
      'Sigean',
      'Sierentz',
      'Sierck-les-Bains',
      'Sibiril',
      'Sézanne',
      'Seyssuel',
      'Seyssins',
      'Seyssinet-Pariset',
      'Seysses',
      'Seynod',
      'Seyne-les-Alpes',
      'Sevrier',
      'Sevrey',
      'Sèvres-Anxaumont',
      'Sèvres',
      'Sevran',
      'Sévignac',
      'Sévérac-le-Château',
      'Sévérac',
      'Seurre',
      'Seugy',
      'Sète',
      'Sessenheim',
      'Servon-sur-Vilaine',
      'Servon',
      'Servian',
      'Servas',
      'Serris',
      'Ladoix-Serrigny',
      'Serrières-de-Briord',
      'Serrières',
      'Serres-Castet',
      'Serres',
      'Serre-les-Sapins',
      'Serquigny',
      'Serqueux',
      'Serques',
      'Serpaize',
      'Sernhac',
      'Sermoise-sur-Loire',
      'Sermérieu',
      'Sermaize-les-Bains',
      'Sermaises',
      'Sérigné',
      'Sérignan-du-Comtat',
      'Sérignan',
      'Sérifontaine',
      'Sergy',
      'Sergines',
      'Sérézin-du-Rhône',
      'Sérent',
      'Serémange-Erzange',
      'Séreilhac',
      'Seraincourt',
      'Sequedin',
      'Septfonds',
      'Septeuil',
      'Septèmes-les-Vallons',
      'Septème',
      'Sentheim',
      'Sens-de-Bretagne',
      'Sens',
      'Senones',
      'Senonches',
      'Sennecey-lès-Dijon',
      'Sennecey-le-Grand',
      'Senlis',
      'Séné',
      'Sénas',
      'Semussac',
      'Semur-en-Auxois',
      'Semoy',
      'Séméac',
      'Semblançay',
      'Sémalens',
      'Selvigny',
      'Seltz',
      'Selongey',
      'Seloncourt',
      'Selles-sur-Cher',
      'Selles-Saint-Denis',
      'Sélestat',
      'Seissan',
      'Seingbouse',
      'Seine-Port',
      'Seillans',
      'Seilhac',
      'Seilh',
      'Seigy',
      'Seignosse',
      'Seignelay',
      'Seiches-sur-le-Loir',
      'Seichamps',
      'Segré',
      'Segonzac',
      'Ségny',
      'Séez',
      'Sées',
      'Sedan',
      'Secondigny',
      'Seclin',
      'Sebourg',
      'Seboncourt',
      'Sébazac-Concourès',
      'Scy-Chazelles',
      'Scorbé-Clairvaux',
      'Scionzier',
      'Sciez',
      'Schwindratzheim',
      'Schweighouse-sur-Moder',
      'Schœneck',
      'Schnersheim',
      'Schirrhein',
      'Schirmeck',
      'Schiltigheim',
      'Scherwiller',
      'Scey-sur-Saône-et-Saint-Albin',
      'Sceaux',
      'Scaër',
      'Saze',
      'Sayat',
      'Savy-Berlette',
      'Savonnières',
      'Savigny-sur-Orge',
      'Savigny-sur-Braye',
      'Savigny-Lévescault',
      'Savigny-le-Temple',
      'Savigny-lès-Beaune',
      'Savigny-en-Véron',
      'Savigny-en-Sancerre',
      'Savigny',
      'Savigneux',
      'Savigné-sur-Lathan',
      'Savigné',
      'Saverne',
      'Saverdun',
      'Savennières',
      'Savenay',
      'Savasse',
      'Sauzé-Vaussais',
      'Sauzet',
      'Sauxillanges',
      'Sauvigny-les-Bois',
      'Sauviat-sur-Vige',
      'Sauvian',
      'Sauveterre-de-Guyenne',
      'Sauveterre',
      'Sauveterre-de-Béarn',
      'Sauverny',
      'Sauve',
      'Sauvagnon',
      'Sautron',
      'Sausset-les-Pins',
      'Saussay',
      'Saussan',
      'Sausheim',
      'Saumur',
      'Saulzoir',
      'Saulxures-sur-Moselotte',
      'Saulxures-lès-Nancy',
      'Saulx-les-Chartreux',
      'Sault-lès-Rethel',
      'Sault',
      'Sault-Brénaz',
      'Saultain',
      'Saulny',
      'Saulnes',
      'Saulieu',
      'Saulgé',
      'Saulcy-sur-Meurthe',
      'Saulce-sur-Rhône',
      'Saujon',
      'Saugues',
      'Saugnac-et-Cambran',
      'Saucats',
      'Saubrigues',
      'Saubion',
      'Saubens',
      'Satolas-et-Bonce',
      'Satillieu',
      'Sathonay-Village',
      'Sathonay-Camp',
      'Sassenay',
      'Sassenage',
      'Sarzeau',
      'Sartrouville',
      'Sartilly',
      'Sartène',
      'Sars-Poteries',
      'Sarry',
      'Sarrola',
      'Sarrians',
      'Sarrewerden',
      'Sarre-Union',
      'Sarreinsming',
      'Sarreguemines',
      'Sarrebourg',
      'Sarras',
      'Sarralbe',
      'Sarlat-la-Canéda',
      'Sari-Solenzara',
      'Sargé-sur-Braye',
      'Sargé-lès-le-Mans',
      'Sare',
      'Sarcelles',
      'Sarbazan',
      'Saran',
      'Saône',
      'Sanvignes-les-Mines',
      'Santes',
      'Santeny',
      'Santec',
      'Santa-Maria-di-Lota',
      'Santa-Lucia-di-Moriani',
      'Sansac-de-Marmiesse',
      'Sannois',
      'San-Nicolao',
      'Sannerville',
      'San-Martino-di-Lota',
      'Sanguinet',
      'Sangatte',
      'Sandillon',
      'Sand',
      'Sancoins',
      'Sancey-le-Grand',
      'Sancerre',
      'Sancé',
      'Sanary-sur-Mer',
      'Samoreau',
      'Samois-sur-Seine',
      'Samoëns',
      'Sammeron',
      'Samer',
      'Saméon',
      'Samatan',
      'Samadet',
      'Salviac',
      'Salouël',
      'Salon-de-Provence',
      'Salomé',
      'Salles-sur-Mer',
      'Salles-la-Source',
      'Salles-Curan',
      'Salles',
      'Sallertaine',
      'Sallebœuf',
      'Sallaumines',
      'Sallanches',
      'Salins-les-Thermes',
      'Salins-les-Bains',
      'Salins',
      'Salindres',
      'Saligny',
      'Salignac',
      'Salignac-Eyvigues',
      'Salies-du-Salat',
      'Salies-de-Béarn',
      'Saleux',
      'Sales',
      'Salernes',
      'Saleilles',
      'Salses-le-Château',
      'Salbris',
      'Salbert',
      'Salaise-sur-Sanne',
      'Salagnon',
      'Saizerais',
      'Saïx',
      'Saivres',
      'Saint-Zacharie',
      'Saint-Yzan-de-Soudiac',
      'Saint-Yvi',
      'Saint-Yrieix-sur-Charente',
      'Saint-Yrieix-la-Perche',
      'Saint-Yorre',
      'Saint-Yan',
      'Saint-Xandre',
      'Saint-Witz',
      'Saint-Wandrille-Rançon',
      'Saint-Vrain',
      'Saint-Vivien-de-Médoc',
      'Saint-Vite',
      'Saint-Vit',
      'Saint-Vincent-sur-Oust',
      'Saint-Vincent-sur-Graon',
      'Saint-Vincent-de-Tyrosse',
      'Saint-Vincent-des-Landes',
      'Saint-Vincent-de-Paul',
      'Saint-Vincent-de-Mercuze',
      'Saint-Vigor-le-Grand',
      'Saint-Victurnien',
      'Saint-Victor-la-Coste',
      'Saint-Victoret',
      'Saint-Victor-de-Cessieu',
      'Saint-Victor',
      'Saint-Viaud',
      'Saint-Viâtre',
      'Saint-Viance',
      'Saint-Vérand',
      'Saint-Venant',
      'Saint-Vaury',
      'Saint-Varent',
      'Saint-Vallier-de-Thiey',
      'Saint-Vallier',
      'Saint-Valery-sur-Somme',
      'Saint-Valery-en-Caux',
      'Saint-Valérien',
      'Saint-Vaast-la-Hougue',
      'Saint-Uze',
      'Saint-Usuge',
      'Saint-Usage',
      'Saint-Urbain',
      'Saint-Tropez',
      'Saint-Trojan-les-Bains',
      'Saint-Trivier-sur-Moignans',
      'Saint-Trivier-de-Courtes',
      'Saint-Thuriau',
      'Saint-Thurial',
      'Saint-Thonan',
      'Saint-Thibéry',
      'Saint-Thibault-des-Vignes',
      'Saint-Thégonnec',
      'Saint-Symphorien-sur-Coise',
      'Saint-Symphorien-de-Lay',
      'Saint-Symphorien',
      'Saint-Sylvestre-sur-Lot',
      'Pragoulin',
      'Saint-Sylvestre-Cappel',
      'Saint-Sylvain',
      'Saint-Sulpice-sur-Risle',
      'Saint-Sulpice-sur-Lèze',
      'Saint-Sulpice-les-Feuilles',
      'Saint-Sulpice-le-Guérétois',
      'Saint-Sulpice-la-Forêt',
      'Saint-Sulpice',
      'Saint-Sulpice-de-Royan',
      'Saint-Sulpice-de-Faleyrens',
      'Saint-Sulpice-de-Cognac',
      'Saint-Sulpice-de-Pommeray',
      'Saint-Sulpice-la-Pointe',
      'Saint-Soupplets',
      'Saint-Souplet',
      'Saint-Sorlin-en-Valloire',
      'Saint-Sorlin-en-Bugey',
      'Saint-Simon',
      'Saint-Siméon-de-Bressieux',
      'Saint-Sever-Calvados',
      'Saint-Sever',
      "Saint-Seurin-sur-l'Isle",
      'Saint-Sernin-du-Bois',
      'Saint-Sernin',
      'Saint-Senoux',
      'Saint-Senier-sous-Avranches',
      'Saint-Selve',
      'Saint-Sébastien-sur-Loire',
      'Saint-Sébastien-de-Morsent',
      'Saint-Savournin',
      'Saint-Savinien',
      'Saint-Savin',
      'Saint-Sauveur-sur-École',
      'Saint-Sauveur-le-Vicomte',
      'Saint-Sauveur-Lendelin',
      'Saint-Sauveur-en-Rue',
      'Saint-Sauveur-des-Landes',
      'Saint-Sauveur-de-Montagut',
      "Saint-Sauveur-d'Aunis",
      'Saint-Sauveur',
      "Saint-Sauves-d'Auvergne",
      'Saint-Sauvant',
      'Saint-Saulve',
      'Saint-Saturnin-sur-Loire',
      'Saint-Saturnin-lès-Avignon',
      'Saint-Saturnin-lès-Apt',
      'Saint-Saturnin',
      'Saint-Satur',
      'Saint-Samson-sur-Rance',
      'Saint-Saëns',
      'Saints',
      'Saintry-sur-Seine',
      'Saint-Romans',
      'Saint-Romain-sur-Cher',
      'Saint-Romain-le-Puy',
      'Saint-Romain-la-Motte',
      'Saint-Romain-en-Gal',
      'Saint-Romain-de-Popey',
      'Saint-Romain-de-Jalionas',
      'Saint-Romain-de-Colbosc',
      'Saint-Romain-de-Benet',
      'Saint-Rogatien',
      'Saint-Riquier',
      'Saint-Restitut',
      'Saint-Renan',
      'Saint-Rémy-sur-Durolle',
      'Saint-Rémy-sur-Avre',
      'Saint-Rémy-lès-Chevreuse',
      'Saint-Rémy-en-Rollat',
      'Saint-Rémy-en-Mauges',
      'Saint-Rémy-de-Provence',
      'Saint-Rémy',
      'Saint-Rémy-de-Maurienne',
      'Saint-Raphaël',
      'Saint-Rambert-en-Bugey',
      'Saint-Quentin-sur-le-Homme',
      'Saint-Quentin-sur-Isère',
      'Saint-Quentin-la-Poterie',
      'Saint-Quentin-Fallavier',
      'Saint-Quentin-en-Mauges',
      'Saint-Quentin-de-Baron',
      'Saint-Quentin',
      'Saint-Quay-Portrieux',
      'Saint-Quay-Perros',
      'Saint-Python',
      'Saint-Pryvé-Saint-Mesmin',
      'Saint-Prouant',
      'Saint-Projet',
      'Saint-Prix',
      'Saint-Privat-la-Montagne',
      'Saint-Privat-des-Vieux',
      'Saint-Privat',
      'Saint-Priest-Taurion',
      'Saint-Priest-sous-Aixe',
      'Saint-Priest-en-Jarez',
      'Saint-Priest',
      'Saint-Prest',
      'Saint-Pourçain-sur-Sioule',
      'Saint-Pouange',
      'Saint-Porquier',
      'Saint-Porchaire',
      'Saint-Pons-de-Thomières',
      'Saint-Pol-sur-Ternoise',
      'Saint-Pol-sur-Mer',
      'Saint-Pol-de-Léon',
      'Saint-Planchers',
      'Saint-Pierre-sur-Dives',
      'Saint-Pierre-Quiberon',
      'Saint-Pierre-Montlimart',
      'Saint-Pierre-lès-Nemours',
      'Saint-Pierre-lès-Elbeuf',
      'Saint-Pierre-le-Moûtier',
      'Saint-Pierre-la-Palud',
      'Saint-Pierre-la-Cour',
      'Saint-Pierre-en-Val',
      'Saint-Pierre-Église',
      'Saint-Pierre-du-Vauvray',
      'Saint-Pierre-du-Regard',
      'Saint-Pierre-du-Perray',
      'Saint-Pierre-du-Mont',
      'Saint-Pierre-du-Chemin',
      "Saint-Pierre-d'Oléron",
      'Saint-Pierre-de-Varengeville',
      'Saint-Pierre-des-Nids',
      'Saint-Pierre-des-Fleurs',
      'Saint-Pierre-des-Échaubrognes',
      'Saint-Pierre-des-Corps',
      'Saint-Pierre-de-Plesguen',
      'Saint-Pierre-de-Chandieu',
      'Saint-Pierre-de-Bœuf',
      "Saint-Pierre-d'Aurillac",
      'Saint-Piat',
      'Saint-Philibert',
      'Saint-Philbert-du-Peuple',
      'Saint-Philbert-de-Grand-Lieu',
      'Saint-Philbert-de-Bouaine',
      'Saint-Perreux',
      'Saint-Père-sur-Loire',
      'Saint-Père-en-Retz',
      'Saint-Père-Marc-en-Poulet',
      'Saint-Perdon',
      'Saint-Péray',
      'Saint-Pée-sur-Nivelle',
      'Saint-Pé-de-Bigorre',
      'Saint-Paul-Trois-Châteaux',
      'Saint-Paul-lès-Romans',
      'Saint-Paul-lès-Dax',
      'Saint-Paulien',
      'Saint-Paulet-de-Caisson',
      'Saint-Paul-en-Pareds',
      'Saint-Paul-en-Jarez',
      'Saint-Paul-en-Forêt',
      'Saint-Paul',
      'Saint-Paul-en-Chablais',
      'Saint-Paul-de-Varces',
      'Saint-Paul-de-Varax',
      'Saint-Paul-des-Landes',
      'Saint-Paul-de-Jarrat',
      'Saint-Paul-de-Fenouillet',
      'Saint-Paul-Cap-de-Joux',
      'Saint-Paul-de-Vence',
      'Saint-Pathus',
      'Saint-Paterne-Racan',
      'Saint-Paterne',
      'Saint-Parres-aux-Tertres',
      'Saint-Parize-le-Châtel',
      'Saint-Pargoire',
      'Saint-Pardoux-la-Rivière',
      'Saint-Pardoux-Isaac',
      'Saint-Pardoux',
      'Saint-Pantaléon-de-Larche',
      'Saint-Pal-de-Mons',
      'Saint-Palais-sur-Mer',
      'Saint-Palais',
      'Saint-Pair-sur-Mer',
      'Saint-Paër',
      'Saint-Pabu',
      'Saint-Ours',
      "Saint-Ouen-l'Aumône",
      'Saint-Ouen-en-Belin',
      'Saint-Ouen-du-Tilleul',
      'Saint-Ouen-de-Thouberville',
      'Saint-Ouën-des-Toits',
      'Saint-Ouen-des-Alleux',
      'Saint-Ouen',
      'Saint-Orens-de-Gameville',
      'Saint-Omer-en-Chaussée',
      'Saint-Omer',
      'Saint-Nom-la-Bretêche',
      'Saint-Nolff',
      'Saint-Nizier-sous-Charlieu',
      'Saint-Nicolas-du-Pélem',
      'Saint-Nicolas-de-Redon',
      'Saint-Nicolas-de-Port',
      'Saint-Nicolas-de-la-Taille',
      'Saint-Nicolas-de-la-Grave',
      'Saint-Nicolas-de-Bourgueil',
      "Saint-Nicolas-d'Aliermont",
      'Saint-Nicolas',
      "Saint-Nazaire-d'Aude",
      'Saint-Nazaire',
      'Saint-Nazaire-les-Eymes',
      'Saint-Nauphary',
      'Saint-Nabord',
      'Saint-Morillon',
      'Saint-Molf',
      'Saint-Mitre-les-Remparts',
      'Saint-Mihiel',
      'Saint-Michel-sur-Orge',
      'Saint-Michel-sur-Meurthe',
      'Saint-Michel-Mont-Mercure',
      "Saint-Michel-l'Observatoire",
      'Saint-Michel-le-Cloucq',
      'Saint-Michel-de-Maurienne',
      'Saint-Michel-Chef-Chef',
      'Saint-Michel',
      'Saint-Mexant',
      'Saint-Mesmin',
      'Saint-Menges',
      'Saint-Menet',
      'Saint-Memmie',
      'Saint-Même-le-Tenu',
      'Saint-Même-les-Carrières',
      'Saint-Méloir-des-Ondes',
      'Saint-Melaine-sur-Aubance',
      'Saint-Méen-le-Grand',
      'Saint-Médard-sur-Ille',
      'Saint-Médard-en-Jalles',
      "Saint-Médard-d'Eyrans",
      'Saint-Médard-de-Mussidan',
      'Saint-Médard-de-Guizières',
      'Saint-Maxire',
      'Saint-Maximin-la-Sainte-Baume',
      'Saint-Maximin',
      'Saint-Max',
      'Saint-Maurice-sur-Moselle',
      'Saint-Maurice-sur-Fessard',
      'Saint-Maurice-sur-Dargoire',
      'Saint-Maurice-Montcouronne',
      'Saint-Maurice-la-Souterraine',
      'Saint-Maurice-la-Clouère',
      'Saint-Maurice-en-Gourgois',
      'Saint-Maurice-de-Lignon',
      'Saint-Maurice-de-Gourdans',
      'Saint-Maurice-de-Beynost',
      'Saint-Maurice',
      'Saint-Maur-des-Fossés',
      'Saint-Maur',
      'Saint-Mathurin-sur-Loire',
      'Saint-Mathurin',
      'Saint-Mathieu-de-Tréviers',
      'Saint-Mathieu',
      'Saint-Martin-Vésubie',
      'Saint-Martin-sur-Oust',
      'Saint-Martin-sur-Ocre',
      'Saint-Martin sous Mouzeuil',
      'Saint-Martin-Osmonville',
      'Saint-Martin-Longueau',
      'Saint-Martin-le-Vinoux',
      'Saint-Martin-le-Beau',
      'Saint-Martin-la-Plaine',
      'Saint-Martin-Lalande',
      'Saint-Martin-Lacaussade',
      'Saint-Martin-en-Haut',
      'Saint-Martin-en-Campagne',
      'Saint-Martin-en-Bresse',
      'Saint-Martin-du-Vivier',
      'Saint-Martin-du-Var',
      'Saint-Martin-du-Tertre',
      'Saint-Martin-du-Mont',
      'Saint-Martin-du-Manoir',
      'Saint-Martin-du-Frêne',
      'Saint-Martin-du-Fouilloux',
      "Saint-Martin-d'Hères",
      'Saint-Martin-de-Valgalgues',
      'Saint-Martin-de-Valamas',
      'Saint-Martin-des-Noyers',
      'Saint-Martin-de-Seignanx',
      'Saint-Martin-des-Champs',
      'Saint-Martin-des-Besaces',
      'Saint-Martin-de-Ré',
      'Saint-Martin-de-Queyrières',
      'Saint-Martin-de-Nigelles',
      'Saint-Martin-de-Londres',
      'Saint-Martin-de-la-Place',
      'Saint-Martin-de-Landelles',
      'Saint-Martin-de-Hinx',
      'Saint-Martin-de-Fontenay',
      'Saint-Martin-de-Crau',
      'Saint-Martin-de-Boscherville',
      'Saint-Martin-de-Belleville',
      "Saint-Martin-d'Auxigny",
      'Saint-Martin-Boulogne',
      'Saint-Martin-Bellevue',
      'Saint-Martin-Belle-Roche',
      'Saint-Martin-au-Laërt',
      'Saint-Mars-la-Jaille',
      'Saint-Mars-la-Brière',
      'Saint-Mars-du-Désert',
      "Saint-Mars-d'Outillé",
      'Saint-Mars-de-Coutais',
      'Saint-Mariens',
      'Saint-Mard',
      'Saint-Marc-le-Blanc',
      'Saint-Marc-Jaumegarde',
      'Saint-Marcellin-en-Forez',
      'Saint-Marcellin',
      'Saint-Marcel-lès-Valence',
      'Saint-Marcel-lès-Sauzet',
      'Saint-Marcel-lès-Annonay',
      "Saint-Marcel-d'Ardèche",
      'Saint-Marcel',
      'Saint-Marcel-sur-Aude',
      'Saint-Manvieu',
      'Saint-Mandrier-sur-Mer',
      'Saint-Mandé',
      'Saint-Mammès',
      'Saint-Mamet-la-Salvetat',
      'Saint-Mamert-du-Gard',
      'Saint-Malô-du-Bois',
      'Saint-Malo-de-Guersac',
      'Saint-Malo',
      'Saint-Maixant',
      'Saint-Macaire-en-Mauges',
      'Saint-Macaire',
      'Saint-Lys',
      'Saint-Lyphard',
      'Saint-Lyé',
      'Saint-Lupicin',
      'Saint-Lunaire',
      'Saint-Lumine-de-Coutais',
      'Saint-Lumine-de-Clisson',
      'Saint-Lubin-des-Joncherets',
      'Saint-Loup-sur-Semouse',
      'Saint-Loup-Cammas',
      'Saint-Loup',
      'Saint-Louis-de-Montferrand',
      'Saint-Louis',
      'Saint-Loubès',
      'Saint-Lô',
      'Saint-Lizier',
      'Saint-Leu-la-Forêt',
      'Saint-Leu',
      "Saint-Léon-sur-l'Isle",
      'Saint-Léonard-de-Noblat',
      'Saint-Léonard',
      'Saint-Léger-sur-Roanne',
      'Saint-Léger-sur-Dheune',
      'Saint-Léger-sous-Cholet',
      'Saint-Léger-les-Vignes',
      'Saint-Léger-lès-Domart',
      'Saint-Léger-en-Yvelines',
      'Saint-Léger-du-Bourg-Denis',
      'Saint-Léger-des-Vignes',
      'Saint-Léger-des-Bois',
      'Saint-Léger-de-Montbrun',
      'Saint-Laurent-sur-Sèvre',
      'Saint-Laurent-sur-Saône',
      'Saint-Laurent-sur-Gorre',
      'Saint-Laurent-en-Royans',
      'Saint-Laurent-du-Var',
      'Saint-Laurent-du-Pont',
      'Saint-Laurent-du-Pape',
      'Saint-Laurent-en-Grandvaux',
      'Saint-Laurent-Nouan',
      'Saint-Laurent-des-Autels',
      'Saint-Laurent-des-Arbres',
      'Saint-Laurent-de-Mure',
      'Saint-Laurent-de-la-Salanque',
      'Saint-Laurent-de-la-Prée',
      'Saint-Laurent-de-la-Plaine',
      'Saint-Laurent-de-Chamousset',
      'Sant Llorenç de Cerdans',
      'Saint-Laurent-de-Brévedent',
      'Saint-Laurent-Blangy',
      'Saint-Laurent',
      'Saint-Lattier',
      'Saint-Lary-Soulan',
      'Saint-Lambert-la-Potherie',
      'Saint-Lambert-du-Lattay',
      'Saint-Just-Saint-Rambert',
      'Saint-Just-Sauvage',
      'Saint-Just-Malmont',
      'Saint-Just-le-Martel',
      'Saint-Just-la-Pendue',
      'Saint-Just-en-Chevalet',
      'Saint-Just-en-Chaussée',
      'Saint-Just-de-Claix',
      'Saint-Just-Chaleyssin',
      'Saint-Just',
      'Saint-Just-Luzac',
      "Saint-Just-d'Ardèche",
      'Saint-Junien',
      'Saint-Julien-Molin-Molette',
      'Saint-Julien-les-Villas',
      'Saint-Julien-lès-Metz',
      'Saint-Julien-en-Saint-Alban',
      'Saint-Julien-en-Genevois',
      'Saint-Julien-en-Born',
      'Saint-Julien-du-Sault',
      'Saint-Julien-les-Rosiers',
      'Saint-Julien-des-Landes',
      'Saint-Julien-de-Peyrolas',
      'Saint-Julien-de-Concelles',
      'Saint-Julien-Chapteuil',
      'Saint-Julien',
      'Saint-Juéry',
      'Saint-Jouvent',
      'Saint-Jouin-Bruneval',
      'Saint-Jouan-des-Guérets',
      'Saint-Josse',
      'Saint-Joseph-de-Rivière',
      'Saint-Joseph',
      'Saint-Jory',
      'Saint-Jorioz',
      'Saint-Joachim',
      'Saint-Jérôme',
      'Saint-Jeoire',
      'Saint-Jean-sur-Veyle',
      'Saint-Jean-sur-Mayenne',
      'Saint-Jean-sur-Couesnon',
      'St. Jean-Pla-de-Corts',
      'Saint-Jean-Pied-de-Port',
      'Saint-Jeannet',
      'Saint-Jean-le-Vieux',
      'Saint-Jean-les-Deux-Jumeaux',
      'Saint-Jean-le-Blanc',
      'Saint-Jean-la-Poterie',
      'Saint-Jean-en-Royans',
      'Saint-Jean-du-Gard',
      'Saint-Jean-du-Falga',
      'Saint-Jean-du-Cardonnay',
      "Saint-Jean-d'Illac",
      'Saint-Jean-de-Védas',
      'Saint-Jean-de-Thouars',
      'Saint-Jean-de-Soudain',
      'Saint-Jean-des-Mauvrets',
      'Saint-Jean-de-Sixt',
      'Saint-Jean-des-Champs',
      'Saint-Jean-des-Baisants',
      'Saint-Jean-de-Sauves',
      'Saint-Jean-de-Niost',
      'Saint-Jean-de-Muzols',
      'Saint-Jean-de-Monts',
      'Saint-Jean-de-Moirans',
      'Saint-Jean-de-Maurienne',
      'Saint-Jean-de-Luz',
      'Saint-Jean-de-Losne',
      'Saint-Jean-de-Liversay',
      'Saint-Jean-de-la-Ruelle',
      'Saint-Jean-de-Gonville',
      'Saint-Jean-de-Fos',
      'Saint-Jean-de-Braye',
      'Saint-Jean-de-Bournay',
      'Saint-Jean-de-Boiseau',
      "Saint-Jean-d'Aulps",
      "Saint-Jean-d'Angély",
      'Saint-Jean-Cap-Ferrat',
      'Saint-Jean-Brévelay',
      'Saint-Jean-Bonnefonds',
      'Saint-Jean',
      'Saint-Jans-Cappel',
      'Saint-James',
      'Saint-Jacut-les-Pins',
      'Saint-Jacques-sur-Darnétal',
      'Saint-Jacques-de-la-Lande',
      'Saint-Ismier',
      'Saint-Hippolyte-du-Fort',
      'Saint-Hippolyte',
      'Saint-Hilaire-Saint-Mesmin',
      'Saint-Hilaire-Petitville',
      'Saint-Hilaire-lez-Cambrai',
      'Saint-Hilaire-la-Palud',
      'Saint-Hilaire-du-Rosier',
      'Saint-Hilaire-du-Harcouët',
      'Saint-Hilaire-de-Villefranche',
      'Saint-Hilaire de Talmont',
      'Saint-Hilaire-des-Loges',
      'Saint-Hilaire-de-Riez',
      'Saint-Hilaire-de-Loulay',
      'Saint-Hilaire-de-la-Côte',
      'Saint-Hilaire-de-Clisson',
      'Saint-Hilaire-de-Chaléons',
      'Saint-Hilaire-de-Brethmas',
      'Saint-Hilaire',
      'Saint-Herblon',
      'Saint-Herblain',
      'Saint-Henri',
      'Saint-Hélen',
      'Saint-Héand',
      'Saint-Grégoire',
      'Saint-Gratien',
      'Saint-Gonnery',
      'Saint-Gobain',
      'Saint-Girons',
      'Saint-Gilles-Croix-de-Vie',
      'Saint-Gilles',
      'Saint-Gildas-des-Bois',
      'Saint-Gildas-de-Rhuys',
      'Saint-Gervasy',
      'Saint-Gervais-les-Trois-Clochers',
      'Saint-Gervais-les-Bains',
      'Saint-Gervais-la-Forêt',
      'Saint-Gervais-en-Belin',
      "Saint-Gervais-d'Auvergne",
      'Saint-Gervais',
      'Saint-Germer-de-Fly',
      'Saint-Germain-Village',
      'Saint-Germain-sur-Morin',
      'Saint-Germain-sur-Moine',
      'Saint-Germain-Nuelles',
      'Saint-Germain-sur-Avre',
      'Saint-Germain-Lespinasse',
      'Saint-Germain-lès-Corbeil',
      'Saint-Germain-les-Belles',
      'Saint-Germain-lès-Arpajon',
      'Saint-Germain-Lembron',
      'Saint-Germain-Laval',
      'Saint-Germain-Laprade',
      'Saint-Germain-la-Blanche-Herbe',
      'Saint-Germain-en-Laye',
      'Saint-Germain-en-Coglès',
      'Saint-Germain-du-Puy',
      'Saint-Germain-du-Puch',
      'Saint-Germain-du-Plain',
      'Saint-Germain-du-Corbéis',
      'Saint-Germain-du-Bois',
      'Saint-Germain-des-Prés',
      'Saint-Germain-des-Fossés',
      'Saint-Germain-de-Prinçay',
      'Saint-Germain-de-Marencennes',
      'Saint-Germain-de-Lusignan',
      'Saint-Germain-de-la-Grange',
      'Saint-Germain',
      'Saint-Géréon',
      'Saint-Gérand-le-Puy',
      'Saint-Geours-de-Maremne',
      'Saint-Georges-sur-Loire',
      'Saint-Georges-sur-Eure',
      'Saint-Georges-sur-Cher',
      'Saint-Georges-sur-Baulche',
      'Saint-Georges-sur-Allier',
      'Saint-Georges-les-Bains',
      'Saint-Georges-lès-Baillargeaux',
      'Saint-Georges-Haute-Ville',
      'Saint-Georges-du-Bois',
      'Saint-Georges-des-Groseillers',
      'Saint-Georges-des-Coteaux',
      'Saint-Georges-de-Reneins',
      'Saint-Georges-de-Reintembault',
      'Saint-Georges-de-Pointindoux',
      'Saint-Georges-de-Montaigu',
      'Saint-Georges-de-Mons',
      'Saint-Georges-de-Luzençon',
      'Saint-Georges-de-Didonne',
      'Saint-Georges-de-Commiers',
      'Saint-Georges-Buttavent',
      'Saint-Geoire-en-Valdaine',
      'Saint-Genix-sur-Guiers',
      'Saint-Genis-Pouilly',
      'Saint-Genis-les-Ollières',
      'Saint-Genis-Laval',
      'Saint-Genis-de-Saintonge',
      'Saint-Génis-des-Fontaines',
      "Saint-Geniez-d'Olt",
      'Saint-Geniès-de-Fontedit',
      'Saint-Geniès-des-Mourgues',
      'Saint-Geniès-de-Malgoirès',
      'Saint-Geniès-de-Comolas',
      'Saint-Geniès-Bellevue',
      'Saint-Gengoux-le-National',
      'Saint-Genest-Malifaux',
      'Saint-Genest-Lerpt',
      'Saint-Genès-Champanelle',
      'Saint-Gence',
      'Saint-Gély-du-Fesc',
      'Saint-Gelais',
      'Saint-Gaultier',
      'Saint-Gaudens',
      'Saint-Gatien-des-Bois',
      'Saint-Galmier',
      'Saint-Fulgent',
      'Saint-François',
      'Saint-Fort',
      'Saint-Forgeux',
      'Saint-Fons',
      'Saint-Folquin',
      'Saint-Flour',
      'Saint-Florent-sur-Cher',
      'Saint-Florent-sur-Auzonnet',
      'Saint-Florent-le-Vieil',
      'Saint-Florentin',
      'Saint-Florent-des-Bois',
      'Saint-Florent',
      'Saint-Fiacre-sur-Maine',
      'Saint-Félix-Lauragais',
      'Saint-Félix',
      "Saint-Féliu-d'Avall",
      'Saint-Félicien',
      'Saint-Fargeau-Ponthierry',
      'Saint-Fargeau',
      'Sainte-Verge',
      'Saint-Évarzec',
      'Saint-Eustache-la-Forêt',
      'Saint-Eusèbe',
      'Sainte-Tulle',
      'Saint-Étienne-sur-Chalaronne',
      'Saint-Étienne-lès-Remiremont',
      'Saint-Étienne-en-Coglès',
      'Saint-Étienne-du-Rouvray',
      'Saint-Étienne-du-Grès',
      'Saint-Étienne-du-Bois',
      'Saint-Etienne-de-Tulmont',
      'Saint-Étienne-de-Tinée',
      'Saint-Étienne-des-Oullières',
      'Saint-Étienne-de-Saint-Geoirs',
      'Saint-Étienne-de-Montluc',
      'Saint-Étienne-de-Mer-Morte',
      'Saint-Étienne-de-Fontbellon',
      'Saint-Etienne-de-Cuines',
      'Saint-Étienne-de-Crossey',
      'Corcoué-sur-Logne',
      'Saint-Étienne-de-Chigny',
      'Saint-Étienne-de-Baïgorry',
      'Saint-Étienne-au-Mont',
      'Saint-Étienne',
      'Sainte-Terre',
      'Sainte-Suzanne',
      'Saint-Estève',
      'Saint-Estèphe',
      'Sainte-Soulle',
      'Sainte-Solange',
      'Saintes-Maries-de-la-Mer',
      'Sainte-Sigolène',
      'Sainte-Savine',
      'Saintes',
      'Saint-Erme-Outre-et-Ramecourt',
      'Sainte-Reine-de-Bretagne',
      'Saint-Erblon',
      'Sainte-Radegonde',
      'Sainte-Pazanne',
      'Saint-Épain',
      'Saint-Émilion',
      'Sainte-Mère-Église',
      'Sainte-Menehould',
      'Sainte-Maxime',
      'Sainte-Maure-de-Touraine',
      'Sainte-Maure',
      'Sainte-Marthe',
      'Sainte-Marie-Plage',
      'Sainte-Marie-Kerque',
      'Sainte-Marie-des-Champs',
      'Sainte-Marie-de-Ré',
      'Sainte-Marie-de-Gosse',
      'Sainte-Marie-aux-Mines',
      'Sainte-Marie-aux-Chênes',
      'Sainte-Marie',
      'Sainte-Marguerite-sur-Duclair',
      'Sainte-Marguerite',
      'Sainte-Luce-sur-Loire',
      'Saint-Éloy-les-Mines',
      'Saint-Éloy-de-Gy',
      'Saint-Éloi',
      'Sainte-Lizaigne',
      'Sainte-Livrade-sur-Lot',
      'Sainte-Jamme-sur-Sarthe',
      'Sainte-Honorine-du-Fay',
      'Sainte-Hermine',
      'Sainte-Hélène-sur-Isère',
      'Sainte-Hélène',
      'Sainte-Geneviève-sur-Argence',
      'Sainte-Geneviève-des-Bois',
      'Sainte-Geneviève',
      'Sainte-Gemmes-sur-Loire',
      'Sainte-Gemme-la-Plaine',
      'Sainte-Gauburge-Sainte-Colombe',
      'Sainte-Foy-lès-Lyon',
      "Sainte-Foy-l'Argentière",
      'Sainte-Foy-la-Grande',
      'Sainte-Foy-de-Peyrolières',
      'Sainte-Foy',
      'Sainte-Fortunade',
      'Sainte-Florine',
      'Sainte-Flaive-des-Loups',
      'Sainte-Feyre',
      'Sainte-Féréole',
      'Sainte-Euphémie',
      'Sainte-Eulalie',
      'Saint-Égrève',
      'Sainte-Croix-en-Plaine',
      'Sainte-Croix-aux-Mines',
      'Sainte-Consorce',
      'Sainte-Colombe-en-Bruilhois',
      'Sainte-Colombe',
      'Sainte-Cécile-les-Vignes',
      'Sainte-Cécile',
      'Sainte-Catherine',
      'Sainte-Bazeille',
      'Sainte-Anne-sur-Brivet',
      "Sainte-Anne-d'Auray",
      'Sainte-Anne',
      'Sainte-Anastasie-sur-Issole',
      'Sainte-Agnès',
      'Sainte-Adresse',
      'Saint-Dyé-sur-Loire',
      'Saint-Drézéry',
      'Saint-Doulchard',
      'Saint-Donan',
      'Saint-Domineuc',
      'Saint-Dolay',
      'Saint-Dizier',
      'Saint-Dié-des-Vosges',
      'Saint-Didier-sur-Chalaronne',
      'Saint-Didier-sous-Riverie',
      'Saint-Didier-en-Velay',
      'Saint-Didier-de-la-Tour',
      'Saint-Didier-de-Formans',
      'Saint-Didier',
      'Saint-Désir',
      'Saint-Denis-sur-Sarthon',
      'Saint-Denis-les-Ponts',
      'Saint-Denis-lès-Bourg',
      'Saint-Denis-la-Chevasse',
      'Saint-Denis-en-Val',
      'Saint-Denis-en-Bugey',
      "Saint-Denis-d'Oléron",
      'Saint-Denis-de-Pile',
      'Saint-Denis-de-Gastines',
      'Saint-Denis-de-Cabanne',
      'Saint-Denis',
      'Saint-Cyr-sur-Morin',
      'Saint-Cyr-sur-Mer',
      'Saint-Cyr-sur-Menthon',
      'Saint-Cyr-sur-Loire',
      'Saint-Cyr-sur-le-Rhône',
      'Saint-Cyr-sous-Dourdan',
      "Saint-Cyr-l'École",
      'Saint-Cyr-en-Val',
      'Saint-Cyr-en-Bourg',
      'Saint-Cyr',
      'Saint-Cyprien-Plage',
      'Saint-Cyprien',
      'Saint-Crespin-sur-Moine',
      'Saint-Crépin-Ibouvillers',
      'Saint-Coulomb',
      'Saint-Cosme-en-Vairais',
      'Saint-Contest',
      'Saint-Cloud',
      'Saint-Clément-des-Levées',
      'Saint-Clément-de-la-Place',
      'Saint-Clément',
      'Saint-Clément-de-Rivière',
      'Saint-Claude-de-Diray',
      'Saint-Claude',
      'Saint-Claud',
      'Saint-Clair-du-Rhône',
      'Saint-Clair-de-la-Tour',
      'Saint-Ciers-sur-Gironde',
      "Saint-Ciers-d'Abzac",
      'Saint-Christophe-Vallon',
      'Saint-Christophe-sur-le-Nais',
      'Saint-Christophe-du-Ligneron',
      'Saint-Christophe-du-Bois',
      'Saint-Christoly-de-Blaye',
      'Saint-Christol-les-Alès',
      'Saint-Christol',
      'Saint-Christo-en-Jarez',
      'Saint-Chinian',
      'Saint-Chéron',
      "Saint-Chély-d'Apcher",
      'Saint-Chef',
      'Saint-Chaptes',
      'Saint-Chamond',
      'Saint-Chamas',
      'Saint-Chaffrey',
      'Saint-Cézaire-sur-Siagne',
      'Saint-Cernin',
      'Saint-Cergues',
      'Saint-Céré',
      'Saint-Cast-le-Guildo',
      'Saint-Cassien',
      'Saint-Carreuc',
      'Saint-Caradec',
      'Saint-Caprais-de-Bordeaux',
      'Saint-Cannat',
      'Saint-Calais',
      'Saint-Broladre',
      'Saint-Brisson-sur-Loire',
      'Saint-Bris-le-Vineux',
      'Saint-Brieuc',
      'Saint-Brice-sur-Vienne',
      'Saint-Brice-sous-Forêt',
      'Saint-Brice-Courcelles',
      'Saint-Brice-en-Coglès',
      'Saint-Brice',
      'Saint-Briac-sur-Mer',
      'Saint-Brevin-les-Pins',
      'Saint-Brès',
      'Saint-Brandan',
      'Saint-Branchs',
      'Saint-Bon-Tarentaise',
      'Saint-Bonnet-près-Riom',
      'Saint-Bonnet-les-Oules',
      'Saint-Bonnet-le-Château',
      'Saint-Bonnet-de-Mure',
      'Saint-Bonnet-en-Champsaur',
      'Saint-Berthevin',
      'Saint-Béron',
      'Saint-Berain-sous-Sanvignes',
      'Saint-Benoît-sur-Loire',
      'Saint-Benoît-de-Carmaux',
      'Saint-Benoît',
      "Saint-Benin-d'Azy",
      'Saint-Beauzire',
      'Saint-Bauzille-de-Putois',
      'Saint-Baudelle',
      'Saint-Barthélemy-de-Vals',
      'Saint-Barnabé',
      'Saint-Baldoph',
      'Saint-Ay',
      'Saint-Avold',
      'Saint-Avertin',
      'Saint-Avé',
      'Saint-Aupre',
      'Saint-Aunès',
      'Saint-Aulaye',
      'Saint-Augustin',
      'Saint-Aubin-sur-Scie',
      'Saint-Aubin-sur-Mer',
      'Saint-Aubin-sur-Gaillon',
      'Saint-Aubin-Routot',
      'Saint-Aubin-lès-Elbeuf',
      'Saint-Aubin-le-Cloud',
      'Saint-Aubin-en-Bray',
      'Saint-Aubin-du-Cormier',
      'Saint-Aubin-des-Ormeaux',
      'Saint-Aubin-des-Châteaux',
      'Saint-Aubin-des-Bois',
      'Saint-Aubin-de-Médoc',
      'Saint-Aubin',
      'Saint-Aubert',
      'Saint-Astier',
      'Saint-Arnoult-en-Yvelines',
      'Saint-Arnoult',
      'Saint-Armel',
      'Saint-Apollinaire',
      'Saint-Antoine-du-Rocher',
      'Saint-Antoine-de-Breuilh',
      "Saint-Antoine-l'Abbaye",
      'Saint-Antoine',
      'Saint-André-Treize-Voies',
      'Saint-André-sur-Vieux-Jonc',
      'Saint-André-sur-Orne',
      'Saint-André-les-Vergers',
      'Saint-André-le-Puy',
      'Saint-André-le-Gaz',
      'Saint-André-de-Seignanx',
      'Saint-André-des-Eaux',
      'Saint-André-de-Sangonis',
      'Saint-André-de-la-Marche',
      'Saint-André-de-Corcy',
      'Saint-André-lez-Lille',
      'Saint-André-de-la-Roche',
      'Saint-André',
      'Sant Andreu de Sureda',
      'Saint-Andiol',
      'Saint-Andéol-le-Château',
      'Saint-Amour',
      'Saint-Amé',
      'Saint-Ambroix',
      'Saint-Amarin',
      'Saint-Amant-Tallende',
      'Saint-Amant-de-Boixe',
      'Saint-Amans-Valtoret',
      'Saint-Amans-Soult',
      'Saint-Amand-sur-Sèvre',
      'Saint-Amand-Montrond',
      'Saint-Amand-les-Eaux',
      'Saint-Amand-en-Puisaye',
      'Saint-Amand-Longpré',
      'Saint-Amand',
      'Saint-Alexandre',
      'Saint-Alban-sur-Limagnole',
      'Saint-Alban-de-Roche',
      'Saint-Alban',
      'Saint-Alban-Leysse',
      'Saint-Aigulin',
      'Saint-Aignan',
      'Saint-Agrève',
      'Saint-Agnant-de-Versillat',
      'Saint-Agnant',
      'Saint-Agathon',
      'Saint-Affrique',
      'Sains-en-Gohelle',
      'Sains-en-Amiénois',
      'Sains-du-Nord',
      'Sainghin-en-Weppes',
      'Sainghin-en-Mélantois',
      'Sain-Bel',
      'Sail-sous-Couzan',
      'Sailly-sur-la-Lys',
      'Sailly-lez-Lannoy',
      'Sailly-Labourse',
      'Sailly-Flibeaucourt',
      'Saignon',
      'Sahurs',
      'Sagy',
      'Saffré',
      'Sadirac',
      'Sacy-le-Grand',
      'Saclay',
      'Saclas',
      'Saché',
      'Sabres',
      'Sabran',
      'Sablons',
      'Sablonceaux',
      'Sablet',
      'Sablé-sur-Sarthe',
      'Saâcy-sur-Marne',
      'Ruy',
      'Russange',
      'Russ',
      'Rurange-lès-Thionville',
      'Rupt-sur-Moselle',
      'Ruoms',
      'Rungis',
      'Ruminghem',
      'Rumilly-en-Cambrésis',
      'Rumilly',
      'Rumersheim-le-Haut',
      'Rumegies',
      'Rully',
      'Ruitz',
      'Ruillé-sur-Loir',
      'Rugles',
      'Ruffiac',
      'Ruffey-lès-Echirey',
      'Ruffec',
      'Ruelle-sur-Touvre',
      'Ruelisheim',
      'Rueil-Malmaison',
      'Rue',
      'Rubelles',
      'Ruaudin',
      'Roz-sur-Couesnon',
      'Rozoy-sur-Serre',
      'Roz-Landrieux',
      'Rozier-en-Donzy',
      'Rozérieulles',
      'Rozay-en-Brie',
      'Roye',
      'Roybon',
      'Royat',
      'Royan',
      'Rouziers-de-Touraine',
      'Rouxmesnil-Bouteilles',
      'Rouvroy',
      'Routot',
      'Rousson',
      'Roussillon',
      'Rousset',
      'Roussay',
      'Rousies',
      'Roumazières-Loubert',
      'Roumare',
      'Roullet-Saint-Estèphe',
      'Roulans',
      'Roujan',
      'Rouillon',
      'Rouillé',
      'Rouillac',
      'Rouhling',
      'Rougiers',
      'Rougemont-le-Château',
      'Rougemont',
      'Rougé',
      'Rouffignac-Saint-Cernin-de-Reilhac',
      'Rouffiac-Tolosan',
      'Rouffach',
      'Rouen',
      'Roubaix',
      'Rouans',
      'Rots',
      'Rothau',
      'Rosult',
      'Rostrenen',
      'Rosselange',
      'Rosporden',
      'Rospez',
      'Rosny-sur-Seine',
      'Rosny-sous-Bois',
      'Rosières-près-Troyes',
      'Rosières-en-Santerre',
      'Rosières-aux-Salines',
      'Rosières',
      'Rosheim',
      'Rosenau',
      'Roscoff',
      'Roscanvel',
      'Roquevaire',
      'Roquettes',
      'Roquetoire',
      'Roquemaure',
      'Roquefort-la-Bédoule',
      'Roquefort',
      'Roquecourbe',
      'Roquebrune-sur-Argens',
      'Roquebrune-Cap-Martin',
      'Roquebillière',
      'Roost-Warendin',
      'Roncq',
      'Ronchin',
      'Roncherolles-sur-le-Vivier',
      'Ronchamp',
      'Romorantin-Lanthenay',
      'Romilly-sur-Seine',
      'Romilly-sur-Andelle',
      'Romillé',
      'Romenay',
      'Rombas',
      'Romanswiller',
      'Romans-sur-Isère',
      'Romanèche-Thorins',
      'Romainville',
      'Romagny',
      'Romagnieu',
      'Romagné',
      'Romagnat',
      'Rolleville',
      'Rolampont',
      'Roissy-en-France',
      'Roissy-en-Brie',
      'Roisel',
      'Roiffieux',
      'Rohrbach-lès-Bitche',
      'Rohan',
      'Rognonas',
      'Rognes',
      'Rognac',
      'Rogerville',
      'Roézé-sur-Sarthe',
      'Rœux',
      'Rœulx',
      'Roeschwoog',
      'Rodilhan',
      'Rodez',
      'Rocroi',
      'Rocquencourt',
      'Rochetaillée-sur-Saône',
      'Roches-Prémarie-Andillé',
      'Rocheservière',
      'Rochemaure',
      'Roche-lez-Beaupré',
      'Roche-la-Molière',
      'Rochegude',
      'Rochefort-sur-Loire',
      'Rochefort-du-Gard',
      'Rochefort',
      'Rochecorbon',
      'Rochechouart',
      'Roche',
      'Rocbaron',
      'Robion',
      'Robecq',
      'Roanne',
      'Rixheim',
      'Rivière',
      'Rivières',
      'Rivesaltes',
      'Rives',
      'Rivery',
      'Rivedoux-Plage',
      'Rive-de-Gier',
      'Ris-Orangis',
      'Riscle',
      'Riquewihr',
      'Rioz',
      'Riotord',
      'Riorges',
      'Rions',
      'Rion-des-Landes',
      'Riom-ès-Montagnes',
      'Riom',
      'Rinxent',
      'Rimogne',
      'Rilly-la-Montagne',
      'Rillieux-la-Pape',
      'Rilhac-Rancon',
      'Rignac',
      'Riez',
      'Rieux-Minervois',
      'Rieux-en-Cambrésis',
      'Rieux',
      'Rieux-Volvestre',
      'Rieupeyroux',
      'Rieumes',
      'Rieulay',
      'Riedisheim',
      'Riec-sur-Belon',
      'Richwiller',
      'Richemont',
      'Richelieu',
      'Richebourg',
      "Richebourg-l'Avoué",
      'Richardménil',
      'Ribérac',
      'Ribemont',
      'Ribeauvillé',
      'Ribaute-les-Tavernes',
      'Riantec',
      'Rians',
      'Riaillé',
      'Ria-Sirach',
      'Rhinau',
      'Rezé',
      'Reyrieux',
      'Reynès',
      'Rexpoëde',
      'Revin',
      'Réville',
      'Revigny-sur-Ornain',
      'Reventin-Vaugris',
      'Revel',
      'Reuilly',
      'Reugny',
      'Rety',
      'Retournac',
      'Retonfey',
      'Retiers',
      'Rethel',
      'Restinclières',
      'Restigné',
      'Ressons-sur-Matz',
      'Réquista',
      'Requeil',
      'Replonges',
      'Renwez',
      'Rennes',
      'Renescure',
      'Renazé',
      'Renaison',
      'Renage',
      'Remy',
      'Remoulins',
      'Remouillé',
      'Remiremont',
      'Rémilly',
      'Rémelfing',
      'Rémalard',
      'Reiningue',
      'Reims',
      'Reillanne',
      'Reignier-Ésery',
      'Reignac-sur-Indre',
      'Reignac',
      'Reichstett',
      'Reichshoffen',
      'Réhon',
      'Régusse',
      'Réguisheim',
      'Réguiny',
      'Régny',
      'Redon',
      'Réding',
      'Redessan',
      'Rédené',
      'Recquignies',
      'Rebréchien',
      'Rebais',
      'Réalville',
      'Réalmont',
      'Razès',
      "Razac-sur-l'Isle",
      'Ravières',
      'Ravenel',
      'Rauzan',
      'Raon-aux-Bois',
      'Ranville',
      'Rantigny',
      'Rannée',
      'Rang-du-Fliers',
      'Randan',
      'Ranchicourt',
      'Ramonville-Saint-Agne',
      'Ramonchamp',
      'Rambouillet',
      'Rambervillers',
      'Ramatuelle',
      'Rai',
      'Raismes',
      'Raimbeaucourt',
      'Raillencourt-Sainte-Olle',
      'Raedersheim',
      'Radon',
      'Radinghem-en-Weppes',
      'Racquinghem',
      'Râches',
      'Rabastens-de-Bigorre',
      'Rabastens',
      'Quistinic',
      'Quissac',
      'Quintin',
      'Quintenas',
      'Quinssaines',
      'Quinsac',
      'Quingey',
      'Quincy-Voisins',
      'Quincy-sous-Sénart',
      'Quincieux',
      'Quincié-en-Beaujolais',
      'Quincey',
      'Quincampoix',
      'Quimperlé',
      'Quimper',
      'Quilly',
      'Quillebeuf-sur-Seine',
      'Quillan',
      'Quiévy',
      'Quiévrechain',
      'Quiers-sur-Bézonde',
      'Quiberon',
      'Quévert',
      'Quéven',
      'Quevauvillers',
      'Quettreville-sur-Sienne',
      'Quettehou',
      'Quetigny',
      'Questembert',
      'Quessoy',
      'Quesnoy-sur-Deûle',
      'Querrien',
      'Querqueville',
      'Quend',
      'Quemper-Guézennec',
      'Quéménéven',
      'Quelaines-Saint-Gault',
      'Quédillac',
      'Québriac',
      'Quarouble',
      'Quarante',
      'Quaëdypre',
      'Puyoô',
      'Puymoyen',
      'Puyloubier',
      "Puy-l'Évêque",
      'Puylaurens',
      'Puy-Guillaume',
      'Puygouzon',
      'Puteaux',
      'Putanges-Pont-Écrepin',
      'Pussay',
      'Pusignan',
      'Pusey',
      'Pulversheim',
      'Pulnoy',
      'Pulligny',
      'Pujols',
      'Pujaut',
      'Pujaudran',
      'Puisserguier',
      'Puissalicon',
      'Puiseux-en-France',
      'Puiseaux',
      'Puilboreau',
      'Puichéric',
      'Pugnac',
      'Puget-Ville',
      'Puget-Théniers',
      'Puget-sur-Argens',
      'Publier',
      'Pruniers-en-Sologne',
      'Prunelli-di-Fiumorbo',
      'Pruillé-le-Chétif',
      'Provins',
      'Provin',
      'Proville',
      'Prouvy',
      'Propriano',
      'Priziac ( Priziac )',
      'Prix-lès-Mézières',
      'Privas',
      'Prissé',
      'Prinquiau',
      'Pringy',
      'Prigonrieux',
      'Priay',
      'Preuilly-sur-Claise',
      'Précieux',
      'Presles-en-Brie',
      'Préseau',
      'Prémilhat',
      'Prémesques',
      'Prémery',
      'Preignan',
      'Preignac',
      'Préfailles',
      'Pré-en-Pail',
      'Précy-sur-Oise',
      'Précigné',
      'Préchac',
      'Préaux',
      'Praz-sur-Arly',
      'Prayssac',
      'Prats de Molló',
      'Prat',
      'Prahecq',
      'Pradines',
      'Prades-le-Lez',
      'Prades',
      'Pouzauges',
      'Pouzac',
      'Pouxeux',
      'Poussan',
      'Pouru-Saint-Remy',
      'Pourrières',
      'Pourrain',
      'Poulx',
      'Poullaouen',
      'Poullan-sur-Mer',
      'Pouligny-Saint-Pierre',
      'Pouldreuzic',
      'Pouldergat',
      'Poulainville',
      'Pouilly-sur-Loire',
      'Pouilly-sous-Charlieu',
      'Pouilly-les-Nonains',
      'Pouilly-lès-Feurs',
      'Pouilly-en-Auxois',
      'Pouillon',
      'Pouilley-les-Vignes',
      'Pougues-les-Eaux',
      'Pouancé',
      'Potigny',
      'Poses',
      'Portvendres',
      'Port-sur-Saône',
      'Port-Saint-Père',
      'Port-Saint-Louis-du-Rhône',
      'Port-Sainte-Marie',
      'Port-Sainte-Foy-et-Ponchapt',
      'Porto-Vecchio',
      'Port-Louis',
      'Port-La Nouvelle',
      'Portiragnes',
      'Portieux',
      'Portet-sur-Garonne',
      'Portets',
      'Portes-lès-Valence',
      'Port-en-Bessin-Huppain',
      'Portel-des-Corbières',
      'Port-de-Bouc',
      'Port-Brillet',
      'Portbail',
      'Port à Binson',
      'Porspoder',
      'Pornichet',
      'Pornic',
      'Pordic',
      'Porcieu',
      'Porcheville',
      'Porcelette',
      'Pontvallain',
      'Pont-sur-Yonne',
      'Pont-sur-Sambre',
      'Pont-Scorff',
      'Pont-Salomon',
      'Pont-Saint-Vincent',
      'Pont-Saint-Pierre',
      'Pont-Saint-Martin',
      'Pont-Saint-Esprit',
      'Pont-Sainte-Maxence',
      'Pont-Sainte-Marie',
      'Pontrieux',
      'Pont-Remy',
      'Pontpoint',
      'Pontorson',
      "Pontonx-sur-l'Adour",
      'Pontoise',
      'Pontlevoy',
      "Pont-l'Évêque",
      "Pont-l'Abbé-d'Arnoult",
      "Pont-l'Abbé",
      'Pontivy',
      'Ponthierry',
      'Pont-Hébert',
      'Pontfaverger-Moronvilliers',
      'Pont-Évêque',
      'Pontenx-les-Forges',
      'Pont-en-Royans',
      'Ponteilla',
      'Pont-Écrepin',
      'Pont-du-Château',
      'Pont-du-Casse',
      'Pont de Vivaux',
      'Pont-de-Veyle',
      'Pont-de-Vaux',
      'Pont-de-Salars',
      'Pont-de-Roide',
      'Pont-de-Metz',
      'Pont-de-Larn',
      'Pont-de-Chéruy',
      "Pont-d'Ain",
      'Pont-Croix',
      'Pontchâteau',
      'Pontcharra-sur-Turdine',
      'Pontcharra',
      'Pontcarré',
      'Pont-à-Vendin',
      'Pont-Aven',
      'Pont-Audemer',
      'Pontarlier',
      'Pont-à-Mousson',
      'Pont-à-Marcq',
      'Pontailler-sur-Saône',
      'Pontacq',
      'Pons',
      'Poncin',
      'Ponchon',
      'Pomponne',
      'Pompignan',
      'Pompignac',
      'Pompey',
      'Pompertuzat',
      'Pompaire',
      'Pommiers',
      'Pommeuse',
      'Pommerit-le-Vicomte',
      'Pommerit-Jaudy',
      'Pommeret',
      'Pomérols',
      'Pomarez',
      'Polminhac',
      'Pollionnay',
      'Polliat',
      'Pollestres',
      'Poligny',
      'Polignac',
      'Poix-du-Nord',
      'Poix-de-Picardie',
      'Poitiers',
      'Poisy',
      'Poissy',
      'Poisat',
      'Poilly-lez-Gien',
      'Poey-de-Lescar',
      'Podensac',
      'Pocé-sur-Cisse',
      'Pocé-les-Bois',
      'Pluzunet',
      'Pluvigner',
      'Plurien',
      'Pluneret',
      'Plumieux',
      'Plumergat',
      'Plumelin',
      'Pluméliau',
      'Plumelec',
      'Plumaugat',
      'Pluherlin',
      'Pluguffan',
      'Pluduno',
      'Plozévet',
      'Plouzévédé',
      'Plouzané',
      'Plouvorn',
      'Plouvien',
      'Plourivo',
      'Plourin-lès-Morlaix',
      'Plouray',
      'Plounévez-Quintin',
      'Plounévez-Moëdec',
      'Plounévez-Lochrist',
      'Plounévézel',
      'Plounéventer',
      'Plounéour-Trez',
      'Plounéour-Ménez',
      'Ploumoguer',
      'Ploumilliau',
      'Ploumagoar',
      'Plouisy',
      'Plouigneau',
      'Plouider',
      'Plouhinec',
      'Plouharnel',
      'Plouha',
      'Plouguin',
      'Plouguiel',
      'Plouguernével',
      'Plouguerneau',
      'Plouguenast',
      'Plougrescant',
      'Plougourvest',
      'Plougoumelen',
      'Plougoulm',
      'Plougonven',
      'Plougonvelin',
      'Plougastel-Daoulas',
      'Plougasnou',
      'Ploufragan',
      "Plouezoc'h",
      'Plouézec',
      'Plouescat',
      'Plouër-sur-Rance',
      'Plouénan',
      'Plouédern',
      'Plouëc-du-Trieux',
      'Ploudaniel',
      'Ploudalmézeau',
      'Ploubezre',
      'Ploubazlanec',
      'Ploubalay',
      'Plouay',
      'Plouasne',
      'Plouarzel',
      'Plouaret',
      'Plouagat',
      'Plonévez-Porzay',
      'Plonévez-du-Faou',
      'Plonéour-Lanvern',
      'Plonéis',
      'Plomodiern',
      'Plomeur',
      'Plomelin',
      'Plombières-lès-Dijon',
      'Plombières-les-Bains',
      'Plogonnec',
      'Plogoff',
      'Plogastel-Saint-Germain',
      'Ploëzal',
      'Plœuc-sur-Lié',
      'Ploërmel',
      'Ploeren',
      'Ploërdut',
      'Ploemeur',
      'Ploemel',
      'Plobsheim',
      'Plobannalec-Lesconil',
      'Pleyber-Christ',
      'Pleyben',
      'Pleuven',
      'Pleurtuit',
      'Pleumeur-Gautier',
      'Pleumeur-Bodou',
      'Pleumeleuc',
      'Pleumartin',
      'Pleugueneuc',
      'Pleugriffet',
      'Pleudihen-sur-Rance',
      'Pleudaniel',
      'Pleucadeuc',
      'Pleubian',
      'Plestin-les-Grèves',
      'Plestan',
      'Plessé',
      'Plessala',
      'Pleslin-Trigavou',
      'Plescop',
      'Plérin',
      'Plerguer',
      'Pléneuf-Val-André',
      'Plénée-Jugon',
      'Plémy',
      'Plémet',
      'Plélo',
      'Plélan-le-Petit',
      'Plélan-le-Grand',
      'Pleine-Fougères',
      'Fréhel',
      'Pléhédel',
      'Pléguien',
      'Plédran',
      'Plédéliac',
      'Pléchâtel',
      'Pleaux',
      'Plauzat',
      'Plaudren',
      'Plappeville',
      'Planguenoual',
      'Le Plan-de-la-Tour',
      'Plan-de-Cuques',
      "Plan-d'Aups-Sainte-Baume",
      'Plancoët',
      'Plancher-les-Mines',
      'Plancher-Bas',
      'Plaisir',
      'Plaisance-du-Touch',
      'Plaisance',
      'Plaintel',
      'Plainfaing',
      'Plaine-Haute',
      'Plaimpied-Givaudins',
      'Plailly',
      'Plabennec',
      'Pîtres',
      'Pithiviers-le-Vieil',
      'Pithiviers',
      'Pissy-Pôville',
      'Pissotte',
      'Pissos',
      'Pirou',
      'Piriac-sur-Mer',
      'Pirey',
      'Piré-sur-Seiche',
      'Pipriac',
      'Pionsat',
      'Piolenc',
      'Pinsaguel',
      'Pinon',
      'Piney',
      'Pinet',
      'Pignans',
      'Pignan',
      'Pietrosella',
      'Pietranera',
      'Pierry',
      'Pierrevillers',
      'Pierrevert',
      'Pierres',
      'Pierrepont',
      'Pierrelaye',
      'Pierrelatte',
      'Pierrefort',
      'Pierrefontaine-les-Varans',
      'Pierrefonds',
      'Pierrefitte-sur-Seine',
      'Pierrefitte-Nestalas',
      'Pierrefeu-du-Var',
      'Pierre-Châtel',
      'Pierre-Buffière',
      'Pierre-Bénite',
      'Pierre-de-Bresse',
      'Piennes',
      'Piégut-Pluviers',
      'Picquigny',
      'Picauville',
      'Pibrac',
      'Pia',
      'Phalsbourg',
      'Phalempin',
      'Pfulgriesheim',
      'Pfetterhouse',
      'Pfastatt',
      'Pfaffenhoffen',
      'Pfaffenheim',
      'Pézilla-la-Rivière',
      'Pezens',
      'Pézenas',
      'Peyruis',
      'Peyrolles-en-Provence',
      'Peyrins',
      'Peyrilhac',
      'Peyriac-Minervois',
      'Peyriac-de-Mer',
      'Peyrestortes',
      'Peyrehorade',
      'Peyrat-de-Bellac',
      'Peypin',
      'Peynier',
      'Peymeinade',
      'Peujard',
      'Petiville',
      'Petit-Réderching',
      'Petit-Noir',
      'Petit-Mars',
      'Petite-Rosselle',
      'Petite-Forêt',
      'Pessac',
      'Pesmes',
      'Peschadoires',
      'Pertuis',
      'Perthes',
      'Pers-Jussy',
      'Persan',
      'Perrusson',
      'Perros-Guirec',
      'Perrigny-lès-Dijon',
      'Perrigny',
      'Perrignier',
      'Perriers-sur-Andelle',
      'Perreux',
      'Perrecy-les-Forges',
      'Perpignan',
      'Péroy-les-Gombries',
      'Pérouges',
      'Péronne',
      'Péronnas',
      'Péron',
      'Pérols',
      'Pernes-les-Fontaines',
      'Pernes',
      'Périgueux',
      'Périgny',
      'Périgneux',
      'Pérignat-sur-Allier',
      'Pérignat-lès-Sarliève',
      'Pérignac',
      'Périers',
      'Peri',
      'Pérenchies',
      'Percy',
      'Pépieux',
      'Penvénan',
      'Penta-di-Casinca',
      'Pennautier',
      'Pénestin',
      'Pendé',
      'Pencran',
      'Pélussin',
      'Peltre',
      'Pelousey',
      'Pellouailles-les-Vignes',
      'Pellegrue',
      'Pélissanne',
      'Peipin',
      'Peillonnex',
      'Peillon',
      'Peille',
      'Peillac',
      'Pégomas',
      'Pédernec',
      'Pecquencourt',
      'Pechbonnieu',
      'Péchabou',
      'Péaule',
      'Peaugres',
      'Payzac',
      'Payrin-Augmontel',
      'Payns',
      'Pavilly',
      'Pavie',
      'Paulx',
      'Paulhan',
      'Paulhaguet',
      'Paulhac',
      'Pauillac',
      'Pau',
      'Patay',
      'Passy',
      'Pasly',
      'Paslières',
      'Parthenay',
      'Paron',
      'Parmain',
      'Paris',
      'Parigny',
      'Parigné-le-Pôlin',
      'Parigné',
      'Pargny-sur-Saulx',
      'Parentis-en-Born',
      'Parempuyre',
      'Parcé-sur-Sarthe',
      'Parçay-Meslay',
      'Parçay-les-Pins',
      'Paray-Vieille-Poste',
      'Paray-le-Monial',
      'Paradou',
      'Pantin',
      'Pannes',
      'Pannecé',
      'Panissières',
      'Pancé',
      'Panazol',
      'Pamproux',
      'Pamiers',
      'Palinges',
      'Palavas-les-Flots',
      'Palau-del-Vidre',
      'Palaja',
      'Palaiseau',
      'Paimpont',
      'Paimpol',
      'Paimboeuf',
      'Paillet',
      'Pagny-sur-Moselle',
      'Pacy-sur-Eure',
      'Pacé',
      'Pabu',
      'Ozouer-le-Voulgis',
      'Ozoir-la-Ferrière',
      'Oytier-Saint-Oblas',
      'Oyonnax',
      'Oye-Plage',
      'Ouzouer-sur-Trézée',
      'Ouzouer-sur-Loire',
      'Ouzouer-le-Marché',
      'Ouveillan',
      'Outreau',
      'Outarville',
      'Ousse',
      'Ourville-en-Caux',
      'Oursbelille',
      'Ouroux-sur-Saône',
      'Oullins',
      'Oulins',
      'Ouistreham',
      'Ouges',
      'Oudon',
      'Oucques',
      'Ouches',
      'Ottrott',
      'Ottmarsheim',
      'Otterswiller',
      'Ottange',
      'Othis',
      'Ostwald',
      'Ostricourt',
      'Ostheim',
      'Ossun',
      'Osséja',
      'Ossé',
      'Osny',
      'Orvault',
      'Orval',
      'Orthez',
      'Ortaffa',
      'Orsay',
      'Orsan',
      'Orry-la-Ville',
      'Ornex',
      'Ornans',
      'Ornaisons',
      'Ormoy',
      'Ormesson-sur-Marne',
      'Ormes',
      'Orly',
      'Orliénas',
      'Orleix',
      'Orléat',
      'Orléans',
      'Orival',
      'Origny-Sainte-Benoite',
      'Origny-en-Thiérache',
      'Orgueil',
      'Orgon',
      'Orgeval',
      'Orgerus',
      'Orgères-en-Beauce',
      'Orgères',
      'Orgelet',
      'Orcines',
      'Orchies',
      'Orchamps-Vennes',
      'Orchamps',
      'Orcet',
      'Orbey',
      'Orbec',
      'Orange',
      'Oraison',
      'Oradour-sur-Vayres',
      'Oradour-sur-Glane',
      'Oppède le Vieux',
      'Opio',
      'Onzain',
      'Ons-en-Bray',
      'Onnaing',
      'Onesse-Laharie',
      'Ondres',
      'Ondes',
      'Omissy',
      'Oloron-Sainte-Marie',
      'Olonzac',
      'Olonne-sur-Mer',
      'Olmeto',
      'Ollioules',
      'Ollainville',
      'Olivet',
      'Oletta',
      'Olemps',
      'Oisy-le-Verger',
      'Oissery',
      'Oissel',
      'Oisseau',
      'Oisemont',
      'Oinville-sur-Montcient',
      'Oignies',
      'Ohlungen',
      'Ognes',
      'Ogeu-les-Bains',
      'Offranville',
      'Offenheim',
      'Offemont',
      'Oeyreluy',
      'Œting',
      'Oermingen',
      'Odos',
      'Oderen',
      'Octeville-sur-Mer',
      'Octeville',
      'Objat',
      'Oberschaeffolsheim',
      'Obernai',
      'Obermodern-Zutzendorf',
      'Oberhoffen-sur-Moder',
      'Oberhergheim',
      'Oberhausbergen',
      'Oberhaslach',
      'Oberbronn',
      'Betschdorf',
      'Obenheim',
      'Nyons',
      'Nyoiseau',
      'Nuits-Saint-Georges',
      'Nuillé-sur-Vicoin',
      'Nueil-sur-Layon',
      'Nueil-les-Aubiers',
      'Nuaillé',
      'Nozay',
      'Noyon',
      'Noyers-sur-Cher',
      'Noyen-sur-Sarthe',
      'Noyelles-sous-Lens',
      'Noyelles-lès-Vermelles',
      'Noyelles-Godault',
      'Noyarey',
      'Noyant-la-Gravoyère',
      'Noyant',
      'Noyal-sur-Vilaine',
      'Noyal-Pontivy',
      'Noyal-Muzillac',
      'Novillars',
      'Noves',
      'Novéant-sur-Moselle',
      'Novalaise',
      'Nouzonville',
      'Nouzilly',
      'Nouvoitou',
      'Nouvion-sur-Meuse',
      'Nouvion',
      'Nousty',
      'Nouan-le-Fuzelier',
      'Nouaillé-Maupertuis',
      'Notre-Dame-des-Landes',
      'Notre-Dame-de-Sanilhac',
      'Notre-Dame-de-Riez',
      'Notre-Dame-de-Monts',
      'Notre-Dame-de-Mésage',
      'Notre-Dame-de-Gravenchon',
      'Notre-Dame-de-Bondeville',
      'Nostang',
      'Nort-sur-Erdre',
      'Nortkerque',
      'Norroy-lès-Pont-à-Mousson',
      'Norrent-Fontes',
      'Normanville',
      'Nordhouse',
      'Nontron',
      'Nonancourt',
      'Nommay',
      'Nomexy',
      'Nomeny',
      'Nomain',
      'Nolay',
      'Noizay',
      'Noisy-sur-École',
      'Noisy-le-Sec',
      'Noisy-le-Roi',
      'Noisy-le-Grand',
      'Noisiel',
      'Noiseau',
      "Noirmoutier-en-l'Île",
      'Noirétable',
      'Nointot',
      'Noidans-lès-Vesoul',
      'Nohic',
      'Nohanent',
      'Nogent-sur-Vernisson',
      'Nogent-sur-Seine',
      'Nogent-sur-Oise',
      'Nogent-sur-Marne',
      'Nogent-le-Rotrou',
      'Nogent-le-Roi',
      'Nogent-le-Phaye',
      'Nogent-le-Bas',
      "Nogent-l'Artaud",
      'Nogentel',
      'Nogaro',
      'Nœux-les-Mines',
      'Noé',
      'Noailles',
      'Noaillan',
      'Nivolas-Vermelle',
      'Nivillac',
      'Nissan-lez-Enserune',
      'Niort',
      'Nîmes',
      'Nilvange',
      'Niherne',
      'Niévroz',
      'Nieul-sur-Mer',
      'Nieul-lès-Saintes',
      'Nieul-le-Dolent',
      'Nieul',
      'Nieppe',
      'Niederschaeffolsheim',
      'Niedernai',
      'Niederhausbergen',
      'Niederhaslach',
      'Niederbronn-les-Bains',
      'Niderviller',
      'Nice',
      'Neydens',
      'Nexon',
      'Nevoy',
      'Néville',
      'Névian',
      'Névez',
      'Nevers',
      'Neuwiller-lès-Saverne',
      'Neuvy-sur-Loire',
      'Neuvy-sur-Barangeon',
      'Neuvy-Sautour',
      'Neuvy-Saint-Sépulchre',
      'Neuvy-Pailloux',
      'Neuvy-le-Roi',
      'Neuvy',
      'Neuville-sur-Sarthe',
      'Neuville-sur-Saône',
      'Neuville-sur-Oise',
      'Neuville-sur-Escaut',
      'Neuville-sur-Ain',
      'Neuville-Saint-Vaast',
      'Neuville-Saint-Rémy',
      'Neuville-les-Dames',
      'Neuville-en-Ferrain',
      'Neuville-de-Poitou',
      'Neuville-aux-Bois',
      'Neuvic-Entier',
      'Neuvic',
      'Neuves-Maisons',
      'Neuvéglise',
      'Neuvecelle',
      'Neussargues',
      'Neung-sur-Beuvron',
      'Neulliac',
      'Neulise',
      'Neuilly-sur-Seine',
      'Neuilly-sur-Marne',
      'Neuilly-sous-Clermont',
      'Neuilly-Saint-Front',
      'Neuilly-Plaisance',
      'Neuilly-lès-Dijon',
      'Neuilly-le-Réal',
      'Neuilly-en-Thelle',
      'Neuillé-Pont-Pierre',
      'Neufmoutiers-en-Brie',
      'Neufmanil',
      'Neufgrange',
      'Neufchef',
      'Neufchâtel-Hardelot',
      'Neufchâtel-en-Bray',
      'Neufchâteau',
      'Neuf-Brisach',
      'Neuf-Berquin',
      'Nesmy',
      'Nesles-la-Vallée',
      'Nesles-la-Montagne',
      'Nesles',
      'Nesle',
      'Nersac',
      'Nérondes',
      'Néris-les-Bains',
      'Nercillac',
      'Nérac',
      'Néoules',
      'Nemours',
      'Nègrepelisse',
      'Nébian',
      'Neauphle-le-Château',
      'Neaufles-Saint-Martin',
      'Nazelles-Négron',
      'Nay',
      'Naves',
      'Navenne',
      'Naveil',
      'Navarrenx',
      'Navailles-Angos',
      'Naucelles',
      'Naucelle',
      'Nassandres',
      'Narrosse',
      'Nargis',
      'Narbonne',
      'Naours',
      'Nantua',
      'Nantiat',
      'Nanteuil-lès-Meaux',
      'Nanteuil-le-Haudouin',
      'Nanteuil-en-Vallée',
      'Nanteuil',
      'Nantes',
      'Nanterre',
      'Nans-les-Pins',
      'Nangis',
      'Nandy',
      'Nancy',
      'Nancray',
      'Nalliers',
      'Naizin',
      'Naintré',
      'Nailloux',
      'Nages-et-Solorgues',
      'Muzillac',
      'Mutzig',
      'Muttersholtz',
      'Mussy-sur-Seine',
      'Mussig',
      'Mussidan',
      'Mus',
      'Murviel-lès-Montpellier',
      'Murviel-lès-Béziers',
      'Mûrs-Erigné',
      'Muron',
      'Muret',
      'Mur-de-Sologne',
      'Mûr-de-Bretagne',
      'Murat',
      'Munster',
      'Mundolsheim',
      'Munchhouse',
      'Mulsanne',
      'Mulhouse',
      'Muizon',
      'Muides-sur-Loire',
      'Mugron',
      'Mudaison',
      'Mozé-sur-Louet',
      'Mozac',
      'Moyrazès',
      'Moyeuvre-Grande',
      'Moyenmoutier',
      'Moye',
      'Moyaux',
      'Mouzon',
      'Mouzillon',
      'Mouzeil',
      'Mouy',
      'Mouxy',
      'Mouvaux',
      'Moutiers',
      'Moûtiers',
      'Mouthiers-sur-Boëme',
      'Moustoir-Ac',
      'Moussy-le-Vieux',
      'Moussy-le-Neuf',
      'Moussan',
      'Moussac',
      'Mours-Saint-Eusèbe',
      'Mours',
      'Mouroux',
      'Mourmelon-le-Grand',
      'Mouriès',
      'Mouret',
      'Mourenx',
      'Moult',
      'Moulis-en-Médoc',
      'Moulins-lès-Metz',
      'Moulins-Engilbert',
      'Moulins',
      'Mouliets-et-Villemartin',
      'Mouleydier',
      'Moulay',
      'Mouilleron-le-Captif',
      'Mouilleron-en-Pareds',
      'Mouguerre',
      'Mougon',
      'Mougins',
      'Mouen',
      'Mouchin',
      'Mouchard',
      'Mouchamps',
      'Mouans-Sartoux',
      'Morzine',
      'Morvillars',
      'Mortrée',
      'Morteau',
      'Mortcerf',
      'Mortain',
      'Mortagne-sur-Sèvre',
      'Mortagne-sur-Gironde',
      'Mortagne-du-Nord',
      'Mortagne-au-Perche',
      'Morschwiller-le-Bas',
      'Morsbach',
      'Morsang-sur-Orge',
      'Morre',
      'Morosaglia',
      'Mornas',
      'Mornant',
      'Mornac',
      'Mormoiron',
      'Mormant',
      'Morlaix',
      'Morlaàs',
      'Morigny-Champigny',
      'Morières-lès-Avignon',
      'Morienval',
      'Morhange',
      'Morez',
      'Moreuil',
      'Moret-sur-Loing',
      'Morestel',
      'Morée',
      'Moréac',
      'Mordelles',
      'Morcenx',
      'Morbier',
      'Morbecque',
      'Morannes',
      'Morangis',
      'Morancez',
      'Morancé',
      'Morainvilliers',
      'Moosch',
      'Montussan',
      'Montsûrs',
      'Mont-sous-Vaudrey',
      'Montsoult',
      'Montségur-sur-Lauzon',
      'Mont-Saxonnex',
      'Mont-Saint-Martin',
      'Mont-Saint-Éloi',
      'Mont-Saint-Aignan',
      'Monts',
      'Montry',
      'Montrouge',
      'Montrottier',
      'Montrond-les-Bains',
      'Montrodat',
      'Montricoux',
      'Montrichard',
      'Montrevel-en-Bresse',
      'Montrevault',
      'Montreux-Château',
      'Montreuil',
      'Montreuil-sur-Ille',
      'Montreuil-sous-Pérouse',
      'Montreuil-le-Gast',
      'Montreuil-Bellay',
      'Montreuil-aux-Lions',
      'Montrem',
      'Montréjeau',
      'Montredon-Labessonnié',
      'Montredon',
      'Montréal-la-Cluse',
      'Montréal',
      'Montrabé',
      'Mont-près-Chambord',
      'Montpont-en-Bresse',
      'Montpon-Ménestérol',
      'Montpezat-de-Quercy',
      'Montpeyroux',
      'Montpellier',
      'Montournais',
      'Montoison',
      'Montois-la-Montagne',
      'Montoire-sur-le-Loir',
      'Montoir-de-Bretagne',
      'Montmorot',
      'Montmorillon',
      'Montmorency',
      'Montmoreau-Saint-Cybard',
      'Montmirail',
      'Montmeyran',
      'Montmerle-sur-Saône',
      'Montmélian',
      'Montmédy',
      'Montmartin-sur-Mer',
      'Montmarault',
      'Montmain',
      'Montmagny',
      'Montmacq',
      'Montluel',
      'Montluçon',
      'Montlouis-sur-Loire',
      'Montlivault',
      'Montlignon',
      'Montlieu-la-Garde',
      'Montlhéry',
      'Montlebon',
      'Montlaur',
      'Montjoire',
      'Montjoie-en-Couserans',
      'Montjean-sur-Loire',
      'Montivilliers',
      'Montigny-sur-Loing',
      'Montigny-lès-Metz',
      'Montigny-lès-Cormeilles',
      'Val-de-Meuse',
      'Montigny-Lencoup',
      'Montigny-le-Bretonneux',
      'Montigny-en-Ostrevent',
      'Montigny-en-Gohelle',
      'Montigny',
      'Montigné-le-Brillant',
      'Montignac',
      'Montier-en-Der',
      'Montierchaume',
      'Monticello',
      'Monthyon',
      'Monthermé',
      'Montguyon',
      'Montgivray',
      'Montgiscard',
      'Montgeron',
      'Montgermont',
      'Montgaillard',
      'Montfrin',
      'Montfort-sur-Meu',
      'Montfort-le-Gesnois',
      'Montfort-en-Chalosse',
      'Montferrier-sur-Lez',
      'Montferrat',
      'Montferrand-le-Château',
      'Montfermeil',
      'Montfavet',
      'Montfaucon',
      'Montfaucon-Montigné',
      'Montfaucon-en-Velay',
      'Montévrain',
      'Monteux',
      'Montesson',
      'Montesquieu-Volvestre',
      "Montesquiu d'Albera",
      'Montescourt-Lizerolles',
      'Montescot',
      'Monterfil',
      'Montereau-Fault-Yonne',
      'Monterblanc',
      'Montenois',
      'Montendre',
      'Montenay',
      'Montélimar',
      'Montélier',
      'Montéléger',
      'Monteils',
      'Montech',
      'Montebourg',
      'Montdidier',
      'Mont-de-Marsan',
      'Mont-de-Lans',
      'Montcy-Notre-Dame',
      'Montcuq',
      'Montcresson',
      'Montcourt-Fromonville',
      'Montcornet',
      'Montchanin',
      'Montcenis',
      'Montceau-les-Mines',
      'Montcaret',
      'Montbronn',
      'Montbron',
      'Montbrison',
      'Montboucher-sur-Jabron',
      'Montbonnot-Saint-Martin',
      'Montblanc',
      'Montbizot',
      'Montbeton',
      'Montbert',
      'Montberon',
      'Mont-Bernanchon',
      'Montbéliard',
      'Montbazon',
      'Montbazin',
      'Montbazens',
      'Montbard',
      'Montayral',
      'Montauville',
      'Montaut',
      'Montauroux',
      'Montaure',
      'Montauban-de-Bretagne',
      'Montauban',
      'Montataire',
      'Montastruc-la-Conseillère',
      'Montarnaud',
      'Montargis',
      'Montaren-et-Saint-Médiers',
      'Montardon',
      'Montans',
      'Montanay',
      'Montamisé',
      'Montalieu',
      'Montaigut-sur-Save',
      'Montaigut',
      'Montaigu-de-Quercy',
      'Montaigu',
      'Montagny',
      'Montagne',
      'Montagnat',
      'Montagnac',
      'Montady',
      'Mont',
      'Monswiller',
      'Mons-en-Pévèle',
      'Mons-en-Barœul',
      'Monsempron',
      'Monségur',
      'Mons',
      'Monnières',
      'Monnetier-Mornex',
      'Monnaie',
      'Monistrol-sur-Loire',
      'Monflanquin',
      'Monéteau',
      'Monestiés',
      'Monein',
      'Mondragon',
      'Mondoubleau',
      'Mondonville',
      'Mondeville',
      'Mondelange',
      'Moncoutant',
      'Moncontour',
      'Monclar-de-Quercy',
      'Monchy-Saint-Éloi',
      'Monchecourt',
      'Moncé-en-Belin',
      'Monbazillac',
      'Mommenheim',
      'Molsheim',
      'Mollégès',
      'Molinet',
      'Molières-sur-Cèze',
      'Molières',
      'Molac',
      'Moissy-Cramayel',
      'Moisselles',
      'Moissac',
      'Moislains',
      'Moisenay',
      'Moisdon-la-Rivière',
      'Moirans-en-Montagne',
      'Moirans',
      'Moigny-sur-École',
      'Moidieu',
      'Mogneville',
      'Moëlan-sur-Mer',
      'Modane',
      'Mittelhausbergen',
      'Mitry-Mory',
      'Missillac',
      'Misérieux',
      'Miserey-Salines',
      'Miribel-les-Échelles',
      'Miribel',
      'Mireval',
      'Mirepoix',
      'Mirepeix',
      'Miremont',
      'Mirefleurs',
      'Mirecourt',
      'Mirebeau-sur-Bèze',
      'Mirebeau',
      'Miré',
      'Mirandol-Bourgnounac',
      'Mirande',
      'Miramont-de-Guyenne',
      'Mirambeau',
      'Miramas',
      'Mirabel-aux-Baronnies',
      'Mios',
      'Mions',
      'Mionnay',
      'Minihy-Tréguier',
      'Miniac-Morvan',
      'Mimizan',
      'Mimet',
      'Mimbaste',
      'Milly-sur-Thérain',
      'Milly-la-Forêt',
      'Millery',
      'Millau',
      'Millas',
      'Milizac',
      'Milhaud',
      'Migné',
      'Mignaloux-Beauvoir',
      'Migennes',
      'Mieussy',
      'Miélan',
      'Mézy-sur-Seine',
      'Méziré',
      'Mézin',
      'Mézières-sur-Seine',
      'Mézières-en-Drouais',
      'Mézières-en-Brenne',
      'Mézériat',
      'Mézeray',
      'Mezel',
      'Mèze',
      'Meyzieu',
      'Meythet',
      'Meysse',
      'Meyssac',
      'Meyrueis',
      'Meyreuil',
      'Meyrargues',
      'Meynes',
      'Meymac',
      'Meylan',
      'Meyenheim',
      'Mexy',
      'Meximieux',
      'Meursault',
      'Meursac',
      'Meurchin',
      'Meung-sur-Loire',
      'Meulan-en-Yvelines',
      'Meudon',
      'Meucon',
      'Metz-Tessy',
      'Metzervisse',
      'Metzeral',
      'Metz',
      'Mettray',
      'Méteren',
      'Messimy',
      'Messigny-et-Vantoux',
      'Messery',
      'Messeix',
      'Messei',
      'Messein',
      'Messac',
      'Mesquer',
      'Mesnières-en-Bray',
      'Meslay-du-Maine',
      'Meslan',
      'Meschers-sur-Gironde',
      'Mésanger',
      'Méry-sur-Seine',
      'Méry-sur-Oise',
      'Méry',
      'Merxheim',
      'Merville-Franceville-Plage',
      'Merville',
      'Mervent',
      'Mervans',
      'Méru',
      'Mertzwiller',
      'Merten',
      'Mers-les-Bains',
      'Merlimont',
      'Merlevenez',
      'Mérindol',
      'Mérignies',
      'Mérignac',
      'Mériel',
      'Méricourt',
      'Méribel',
      'Méréville',
      'Méreau',
      'Méré',
      'Merdrignac',
      'Mercy-le-Bas',
      'Mercus-Garrabet',
      'Mercury',
      'Mercurol-Veaunes',
      'Mer',
      'Méounes-lès-Montrieux',
      'Menucourt',
      'Menton',
      'Menthon-Saint-Bernard',
      'Mensignac',
      'Mens',
      'Menneval',
      'Mennecy',
      'Ménilles',
      'Ménétrol',
      'Menetou-Salon',
      'Ménestreau-en-Villette',
      'Ménesplet',
      'Ménerbes',
      'Ménéac',
      'Mende',
      'Melun',
      'Melrand',
      'Mellecey',
      'Melle',
      'Mellac',
      'Mélisey',
      'Melgven',
      'Melesse',
      'Melay',
      'Meistratzheim',
      'Meillonnas',
      'Meillac',
      'Meilhan-sur-Garonne',
      'Meilhan',
      'Mehun-sur-Yèvre',
      'Megève',
      'Mées',
      'Médréac',
      'Médis',
      'Médan',
      'Mécleuves',
      'Meaux',
      'Méaulte',
      'Méaudre',
      'Mazingarbe',
      'Mazières-en-Mauges',
      'Mazières-de-Touraine',
      'Mazet-Saint-Voy',
      'Mazères-Lezons',
      'Mazères',
      'Mazé',
      'Mazargues',
      'Mazan',
      'Mazamet',
      'May-sur-Orne',
      'Mayet',
      'Mayenne',
      'Maxilly-sur-Léman',
      'Maxéville',
      'Maxent',
      'Mauzé-Thouarsais',
      'Mauzé-sur-le-Mignon',
      'Mauvezin',
      'Mauves-sur-Loire',
      'Mauves',
      'Maussane-les-Alpilles',
      'Maurs',
      'Mauron',
      'Mauriac',
      'Maurepas',
      'Maureillas-las-Illas',
      'Maureilhan',
      'Maure-de-Bretagne',
      'Maurecourt',
      'Maulévrier',
      'Mauléon-Licharre',
      'Maule',
      'Mauguio',
      'Maubourguet',
      'Maubeuge',
      'Maubec',
      'Matzenheim',
      'Matour',
      'Matignon',
      'Mathieu',
      'Mathay',
      'Matha',
      'Massy',
      'Massongy',
      'Massieux',
      'Massiac',
      'Masseube',
      'Massay',
      'Masny',
      'Masnières',
      'Masevaux',
      'Marzy',
      'Marzan',
      'Mary-sur-Marne',
      'Marvejols',
      'Martres-Tolosane',
      "Les Martres-d'Artière",
      'Martizay',
      'Martinvast',
      'Martin-Église',
      'Martillac',
      'Martigues',
      'Martigné-Ferchaud',
      'Martigné-Briand',
      'Martigné-sur-Mayenne',
      'Martignat',
      'Martignas-sur-Jalle',
      'Marthod',
      'Martel',
      'Marssac-sur-Tarn',
      'Marsilly',
      'Marsillargues',
      'Marseille',
      'Marseillan',
      'Marsat',
      'Marsanne',
      'Marsannay-la-Côte',
      'Marsac-sur-Don',
      'Marsac-en-Livradois',
      'Marquise',
      'Marquion',
      'Marquillies',
      'Marquette-lez-Lille',
      'Marquette-en-Ostrevant',
      'Marquefave',
      'Marpent',
      'Maromme',
      'Marolles-sur-Seine',
      'Marolles-les-Braults',
      'Marolles-en-Hurepoix',
      'Maroilles',
      'Marœuil',
      'Marnes-la-Coquette',
      'Marnaz',
      'Marnay',
      'Marmoutier',
      'Marmande',
      'Marmagne',
      'Marly',
      'Marly-le-Roi',
      'Marly-la-Ville',
      'Marlhes',
      'Marles-les-Mines',
      'Marles-en-Brie',
      'Marlenheim',
      'Marle',
      'Maringues',
      'Marines',
      'Marin',
      'Marigny-les-Usages',
      'Marigny-le-Châtel',
      'Marigny-Brizay',
      'Marigny',
      'Marignier',
      'Marigné-Laillé',
      'Marignane',
      'Marguerittes',
      'Margon',
      'Margny-lès-Compiègne',
      'Margency',
      'Margencel',
      'Margaux',
      'Mareuil-sur-Ourcq',
      'Mareuil-sur-Lay-Dissais',
      'Mareuil-sur-Cher',
      'Mareuil',
      'Mareuil-sur-Ay',
      'Mareuil-lès-Meaux',
      'Mareuil-le-Port',
      'Maretz',
      'Marennes',
      'Mareil-sur-Mauldre',
      'Mareil-Marly',
      'Mareau-aux-Prés',
      'Mardié',
      'Mardeuil',
      'Marcq-en-Barœul',
      'Marcoussis',
      'Marcorignan',
      'Marconnelle',
      'Marconne',
      'Marçon',
      'Marcoing',
      'Marckolsheim',
      'Marck',
      'Marcilly-sur-Tille',
      'Marcilly-sur-Eure',
      'Marcilly-le-Châtel',
      'Marcilly-en-Villette',
      'Marcillac-Vallon',
      'Marcillac',
      'Marcigny',
      'Marciac',
      'Marchiennes',
      'Marcheprime',
      'Marcey-les-Grèves',
      'Marcellaz-Albanais',
      'Marcamps',
      'Marboz',
      'Marboué',
      'Marbache',
      'Maraussan',
      'Marans',
      'Marange-Silvange',
      'Manziat',
      'Manzat',
      'Manthelan',
      'Mantes-la-Ville',
      'Mantes-la-Jolie',
      'Mansle',
      'Mansigné',
      'Mansac',
      'Manosque',
      'Manom',
      'Manneville-sur-Risle',
      'Manéglise',
      'Mane',
      'Manduel',
      'Mandres-les-Roses',
      'Mandeure',
      'Mandelieu-la-Napoule',
      'Mancieulles',
      'Mamirolle',
      'Mametz',
      'Mamers',
      'Malzéville',
      'Malville',
      'Mallemort',
      'Mallemoisson',
      'Malissard',
      'Malintrat',
      'Malijai',
      'Malicorne-sur-Sarthe',
      'Malguénac',
      'Malestroit',
      'Malesherbes',
      'Malemort-sur-Corrèze',
      'Malemort-du-Comtat',
      'Malay-le-Grand',
      'Malaunay',
      'Malaucène',
      'Malataverne',
      'Malansac',
      'Malakoff',
      'Maizières-lès-Metz',
      'Maizières-la-Grande-Paroisse',
      'Maisse',
      'Maisons-Laffitte',
      'Maisons-Alfort',
      'Maisnil-lès-Ruitz',
      'Maisdon-sur-Sèvre',
      'Mainvilliers',
      'Maintenon',
      'Maing',
      'Maincy',
      'Mailly-le-Camp',
      'Maillot',
      'Maillezais',
      'Maillane',
      'Maignelay-Montigny',
      'Maidières',
      'Maîche',
      'Magny-Vernois',
      'Magny-les-Hameaux',
      'Magny-le-Hongre',
      'Magny-le-Désert',
      'Magny-en-Vexin',
      'Magny-Cours',
      'Magné',
      'Magnanville',
      'Magnac-sur-Touvre',
      'Magnac-Laval',
      'Magland',
      'Magescq',
      'Magenta',
      'Magalas',
      'Maffliers',
      'Maël-Carhaix',
      'Mâcot-la-Plagne',
      'Mâcon',
      'Maclas',
      'Macheren',
      'Machecoul',
      'Maché',
      'Macau',
      'Mably',
      'Lys-lez-Lannoy',
      'Lyon',
      'Lyaud',
      'Luzy',
      'Luz-Saint-Sauveur',
      'Luzinay',
      'Luzech',
      'Luzarches',
      'Luynes',
      'Luxeuil-les-Bains',
      'Lutzelhouse',
      'Lutterbach',
      'Lussac-les-Châteaux',
      'Lussac',
      'Lusigny-sur-Barse',
      'Lusigny',
      'Lusignan',
      'Lusanger',
      'Lure',
      'Lurcy-Lévis',
      'Luray',
      "L'Union",
      'Lunéville',
      'Lunery',
      'Luneray',
      'Lunel-Viel',
      'Lunel',
      'Lunay',
      'Lumio',
      'Lumigny-Nesles-Ormeaux',
      'Lumes',
      'Lumbres',
      'Lumbin',
      'Luitré',
      'Luisant',
      'Lugrin',
      'Ludres',
      'Ludon-Médoc',
      'Luc-sur-Mer',
      'Lucq-de-Béarn',
      'Luçon',
      'Lucinges',
      'Luché-Pringé',
      'Lucéram',
      'Lucenay-lès-Aix',
      'Lucenay',
      'Luceau',
      'Lucé',
      'Lucciana',
      'Luçay-le-Mâle',
      'Luc-la-Primaube',
      'Lubersac',
      'Luant',
      'Lozinghem',
      'Lozanne',
      'Loyettes',
      'Loyat',
      'Lovagny',
      'Louzy',
      'Louvroil',
      'Louvres',
      'Louvigny',
      'Louvigné-du-Désert',
      'Louvigné-de-Bais',
      'Louviers',
      'Louvie-Juzon',
      'Louverné',
      'Louveciennes',
      'Loury',
      'Lourmarin',
      'Lourdes',
      'Lourches',
      'Louplande',
      'Loupian',
      'Loupiac',
      'Louhans',
      'Loué',
      'Loudun',
      'Loudéac',
      'Loubert',
      'Louargat',
      'Louannec',
      'Losne',
      'Lorris',
      'Lorrez-le-Bocage-Préaux',
      'Lorquin',
      'Lormont',
      'Lormes',
      'Lormaison',
      'Loriol-sur-Drôme',
      'Loriol-du-Comtat',
      'Lorient',
      'Lorgues',
      'Lorgies',
      'Lorette',
      'Loperhet',
      'Lopérec',
      'Loos-en-Gohelle',
      'Loos',
      'Loon-Plage',
      'Looberghe',
      'Lons-le-Saunier',
      'Lons',
      'Longwy',
      'Longvic',
      'Longuyon',
      'Longueville',
      'Longuenesse',
      'Longueil-Sainte-Marie',
      'Longueil-Annel',
      'Longueau',
      'Longpré-les-Corps-Saints',
      'Longpont-sur-Orge',
      'Longperrier',
      'Longny-au-Perche',
      'Longnes',
      'Longlaville',
      'Longjumeau',
      'Longfossé',
      'Longeville-lès-Saint-Avold',
      'Longeville-lès-Metz',
      'Longeville-en-Barrois',
      'Longeville-sur-Mer',
      'Longecourt-en-Plaine',
      'Longchaumois',
      'Longchamp',
      'Longages',
      'Londinières',
      'Lompret',
      'Lomme',
      'Lombron',
      'Lombez',
      'Loivre',
      'Loison-sous-Lens',
      'Loisin',
      'Loiron',
      'Loire-sur-Rhône',
      'Logonna-Daoulas',
      'Lognes',
      'Lodève',
      'Loctudy',
      'Locquirec',
      'Locquémeau',
      'Locon',
      'Locoal-Mendon',
      'Locmiquélic',
      'Locminé',
      'Locmariaquer',
      'Loches',
      'Llupia',
      'Lizy-sur-Ourcq',
      'Livry-sur-Seine',
      'Livry-Gargan',
      'Livron-sur-Drôme',
      'Livré-sur-Changeon',
      'Livinhac-le-Haut',
      'Livet-et-Gavet',
      'Liverdy-en-Brie',
      'Liverdun',
      'Livarot',
      'Lit-et-Mixe',
      'Listrac-Médoc',
      'Lissieu',
      'Lisses',
      'Lisle-sur-Tarn',
      "L'Isle-sur-la-Sorgue",
      "L'Isle-Jourdain",
      "L'Isle-en-Dodon",
      "L'Isle-d'Abeau",
      "L'Isle-Adam",
      'Lisieux',
      'Liré',
      'Lipsheim',
      'Lion-sur-Mer',
      'Linxe',
      'Linselles',
      'Linguizzetta',
      'Lingolsheim',
      'Linas',
      'Linars',
      'Linards',
      'Limoux',
      'Limours',
      'Limonest',
      'Limoges',
      'Limetz-Villez',
      'Limésy',
      'Limerzel',
      'Limeil-Brévannes',
      'Limay',
      'Limas',
      'Lillers',
      'Lillebonne',
      'Lille',
      "L'Île-Rousse",
      "L'Île-Bouchard",
      'Ligugé',
      'Ligueil',
      'Ligré',
      'Ligny-le-Ribault',
      'Ligny-le-Châtel',
      'Ligny-en-Cambrésis',
      'Ligny-en-Barrois',
      'Lignières',
      'Ligné',
      'Lignan-sur-Orb',
      'Liffré',
      'Liffol-le-Grand',
      'Liévin',
      'Lieu-Saint-Amand',
      'Lieusaint',
      'Lieurey',
      'Lieuran-lès-Béziers',
      'Liesse-Notre-Dame',
      'Liergues',
      'Lièpvre',
      'Licques',
      'Libourne',
      'Libercourt',
      'Liancourt',
      'Hôpital-Camfrout',
      'Lherm',
      "L'Haÿ-les-Roses",
      'Lezoux',
      'Lézigneux',
      'Lézignan-la-Cèbe',
      'Lézignan-Corbières',
      'Lezennes',
      'Lezay',
      'Lézat-sur-Lèze',
      'Lézardrieux',
      'Lézan',
      'Leyment',
      'Leymen',
      'Leyme',
      'Lexy',
      'Lewarde',
      'Levroux',
      'Le Vivier-sur-Mer',
      'Viviers-du-Lac',
      'Lévis-Saint-Nom',
      'Lévignac',
      'Le Vigen',
      'Le Vigan',
      'Levier',
      'Le Vieil-Baugé',
      'Levet',
      'Le Vésinet',
      'Lèves',
      'Le Versoud',
      'Vernet',
      'Le Verdon-sur-Mer',
      'Levens',
      'Le Vaudreuil',
      'Le Val-Saint-Père',
      'Le Val-Saint-Germain',
      'Levallois-Perret',
      'Valdahon',
      'Leval',
      'Le Val',
      'Leuville-sur-Orge',
      'Leudeville',
      'Leucate',
      'Le Trévoux',
      'Le Tréport',
      "L'Étrat",
      'Le Trait',
      'Le Touvet',
      'Le Touquet-Paris-Plage',
      'Le Tignet',
      'Le Thuit-Signol',
      'Le Thou',
      'Le Thoronet',
      'Le Thor',
      'Le Tholy',
      'Le Tholonet',
      'Le Thillot',
      'Le Thillay',
      'Le Theil-de-Bretagne',
      'Le Theil-Bocage',
      'Le Temple-de-Bretagne',
      'Le Teilleul',
      'Le Teil',
      'Le Teich',
      "L'Étang-la-Ville",
      'Le Tallud',
      'Le Taillan-Médoc',
      'Le Syndicat',
      'Les Vans',
      'Lestrem',
      'Les Touches',
      'Lestelle-Bétharram',
      "L'Estaque",
      'Les Sorinières',
      'Lessay',
      'Les Salles-du-Gardon',
      "Les Sables-d'Olonne",
      'Les Rousses',
      'Les Rosiers-sur-Loire',
      'Les Roches-de-Condrieu',
      'Les Riceys',
      'Lesquin',
      'Les Ponts-de-Cé',
      'Lespinasse',
      'Lespignan',
      'Les Pieux',
      'Les Pennes-Mirabeau',
      'Les Peintures',
      'Les Pavillons-sous-Bois',
      'Lesparre-Médoc',
      'Le Sourn',
      'Les Ormes',
      'Les Olives',
      'Le Soler',
      'Les Noës-près-Troyes',
      'Lesneven',
      'Les Mureaux',
      'Moutiers-les-Mauxfaits',
      'Les Moutiers-en-Retz',
      'Les Montils',
      'Les Molières',
      'Les Mées',
      'Les Mathes',
      'Les Matelles',
      'Les Marches',
      'Les Magnils-Reigniers',
      'Les Mages',
      'Les Lucs-sur-Boulogne',
      'Les Loges-Marchis',
      'Les Loges-en-Josas',
      'Les Loges',
      'Les Lilas',
      'Les Landes-Genusson',
      'Lésigny',
      'Les Houches',
      'Les Herbiers',
      'Les Hautes-Rivières',
      'Les Grandes-Ventes',
      'Les Gonds',
      'Les Gets',
      'Les Fourgs',
      'Les Forges',
      'Les Fins',
      'Les Essarts-le-Roi',
      'Les Essarts',
      'Les Epesses',
      'Les Églisottes-et-Chalaures',
      'Les Échelles',
      'Les Contamines-Montjoie',
      'Les Clouzeaux',
      'Les Clayes-sous-Bois',
      'Les Chères',
      'Les Champs-Géraux',
      "L'Escarène",
      'Lescar',
      'Les Camoins',
      "L'Escale",
      'Les Brouzils',
      'Les Baumettes',
      'Les Aygalades',
      'Les Avenières',
      'Les Authieux-sur-le-Port-Saint-Ouen',
      'Les Attaques',
      'Les Artigues-de-Lussac',
      'Les Arcs',
      'Le Sappey-en-Chartreuse',
      'Les Angles',
      'Les Andelys',
      'Les Ancizes',
      'Les Alluets-le-Roi',
      'Les Allues',
      "Les Aix-d'Angillon",
      'Les Ageux',
      'Les Abrets',
      'Léry',
      'Le Russey',
      'Le Rove',
      'Lérouville',
      'Le Rouret',
      'Le Rheu',
      'Le Revest-les-Eaux',
      'Le Relecq-Kerhuon',
      'Le Redon',
      'Léré',
      'Le Raincy',
      'Le Quesnoy',
      'Le Puy-Sainte-Réparade',
      'Le Puy-Notre-Dame',
      'Le Puy-en-Velay',
      'Lepuix',
      'Le Pré-Saint-Gervais',
      'Le Pradet',
      'Le Pouzin',
      'Le Pouliguen',
      'Le Poujol-sur-Orb',
      'Le Pouget',
      'Le Port-Marly',
      'Le Portel',
      'Port-des-Barques',
      'Le Porge',
      'Le Pontet',
      'Le Pont-de-Claix',
      'Le Pont-de-Beauvoisin',
      'Le Poiré-sur-Vie',
      'Le Poinçonnet',
      'Le Plessis-Trévise',
      'Le Plessis-Robinson',
      'Le Plessis-Pâté',
      'Le Plessis-Macé',
      'Le Plessis-Grammoire',
      'Le Plessis-Brion',
      'Le Plessis-Bouchard',
      'Le Plessis-Belleville',
      'Le Pizou',
      'Le Pin-en-Mauges',
      'Le Pin',
      'Le Pian-Médoc',
      'Le Petit-Quevilly',
      'Petit-Couronne',
      'Le Pertre',
      'Le Perrier',
      'Le Perreux-sur-Marne',
      'Le Perréon',
      'Le Perray-en-Yvelines',
      'Le Pellerin',
      'Le Pecq',
      'Le Pêchereau',
      'Le Péage-de-Roussillon',
      'Le Passage',
      'Le Pallet',
      'Le Palais-sur-Vienne',
      'Le Palais',
      'Léon',
      'Léognan',
      'Lentilly',
      'Lentigny',
      'Lent',
      'Lens',
      'Le Nouvion-en-Thiérache',
      'Le Neubourg',
      'Lencloître',
      'Le Muy',
      'Lempdes-sur-Allagnon',
      'Mont-Dore',
      'Le Monêtier-les-Bains',
      'Le Monastier-sur-Gazeille',
      'Le Monastère',
      'Le Molay-Littry',
      'Le Minihic-sur-Rance',
      'Le Meux',
      'Le Mesnil-sur-Oger',
      'Le Mesnil-Saint-Denis',
      'Le Mesnil-le-Roi',
      'Le Mesnil-Esnard',
      'Le Mesnil-en-Vallée',
      'Le Mesnil-en-Thelle',
      'Le Merlan',
      'Le Ménil',
      'Le Mée-sur-Seine',
      'Lembras',
      'Lemberg',
      'Lembach',
      'Le May-sur-Èvre',
      'Le Mayet-de-Montagne',
      "Le Mas-d'Agenais",
      'Le Mans',
      'Le Manoir',
      'Le Malzieu-Ville',
      'Le Lude',
      'Le Luc',
      'Le Luart',
      'Le Louroux Béconnais',
      'Le Loroux-Bottereau',
      'Le Longeron',
      'Le Lavandou',
      'Le Lardin-Saint-Lazare',
      'Le Langon',
      'Le Landreau',
      'Le Kremlin-Bicêtre',
      'Le Houlme',
      'Le Houga',
      'Léhon',
      'Le Havre',
      'Le Haillan',
      'Léguevin',
      'Le Gua',
      'Le Grau-du-Roi',
      'Le Grand-Quevilly',
      'Le Grand-Pressigny',
      'Le Grand-Lucé',
      'Le Grand-Lemps',
      'Le Grand-Bourg',
      'Le Grand-Bornand',
      'Gond-Pontouvre',
      'Legé',
      'Le Gâvre',
      'Le Garric',
      'Le Fuilet',
      'Le Fousseret',
      'Leforest',
      'Le Folgoët',
      'Le Fœil',
      'Le Fleix',
      'Le Fief-Sauvin',
      'Leffrinckoucke',
      'Le Fenouiller',
      'Le Fauga',
      'Le Faouët',
      'Le Faou',
      'Leers',
      'Le Drennec',
      'Le Doulieu',
      'Le Dorat',
      'Le Donjon',
      'Lédignan',
      'Ledeuix',
      'Lédenon',
      'Lectoure',
      'Le Crotoy',
      'Le Croisic',
      'Le Creusot',
      'Le Crest',
      'Le Crès',
      'Lécousse',
      'Le Coudray-Montceaux',
      'Le Coudray',
      'Le Coteau',
      'Le Conquet',
      'Lécluse',
      'Le Cheylas',
      'Le Cheylard',
      'Le Chesnay',
      'Le Châtelet-en-Brie',
      'Le Châtelet',
      'Le Champ-Saint-Père',
      'Le Champ-près-Froges',
      'Le Chambon-sur-Lignon',
      'Le Chambon-Feugerolles',
      'Le Cendre',
      'Le Cellier',
      'Lecelles',
      'Le Castellet',
      "Le Cap d'Agde",
      'Le Cannet-des-Maures',
      'Le Cannet',
      'Le Canet',
      'Le Cailar',
      'Le Buisson-de-Cadouin',
      'Le Bugue',
      'Le Brusquet',
      'Le Broc',
      'Le Breuil-sur-Couze',
      'Le Breuil',
      'Le Breil-sur-Mérize',
      "Le Bousquet-d'Orb",
      'Le Bouscat',
      'Le Bourgneuf-la-Forêt',
      'Le Bourget-du-Lac',
      'Le Bourget',
      "Le Bourg-d'Oisans",
      'Le Boupère',
      'Le Boulou',
      'Le Bosc-Roger-en-Roumois',
      'Bono',
      'Le Bois-Plage-en-Ré',
      'Le Blanc-Mesnil',
      'Le Blanc',
      'Le Bignon',
      'Le Beausset',
      'Le Bar-sur-Loup',
      'Le Barp',
      'Le Barcarès',
      'Lay-Saint-Christophe',
      'Layrac',
      'Laxou',
      'La Wantzenau',
      'La Vraie-Croix',
      'Lavoux',
      'La Voulte-sur-Rhône',
      'Lavit',
      'La Ville-du-Bois',
      'La Villedieu-du-Clain',
      'Lavilledieu',
      'La Ville-aux-Dames',
      'La Ville-aux-Clercs',
      'La Vespière',
      'Lavérune',
      'La Verrière',
      'La Verrie',
      'La Verpillière',
      'Lavernose-Lacasse',
      'Laventie',
      'Lavelanet',
      'Lavaur',
      'La Vaupalière',
      'Lavault-Sainte-Anne',
      'La Varenne',
      'Lavardac',
      'Lavans-lès-Saint-Claude',
      'Laval-Pradel',
      'La Valette-du-Var',
      'Lavalette',
      'La Valentine',
      'Laval',
      'Laval-en-Belledonne',
      'Lauzerte',
      'Lauwin-Planque',
      'Lautrec',
      'Lauterbourg',
      'Lautenbachzell',
      'Lautenbach',
      'Laussonne',
      'Lauris',
      'Laurens',
      'Laure-Minervois',
      'Launaguet',
      'Launac',
      "Laudun-l'Ardoise",
      'La Turbie',
      'La Turballe',
      'Lattes',
      'La Tronche',
      'La Trinité-sur-Mer',
      'La Trinité',
      'Latresne',
      'La Tremblade',
      'La Tranche-sur-Mer',
      'La Tour-sur-Orb',
      'La Tourlandry',
      'La Tour-en-Jarez',
      'La Tour-du-Pin',
      'La Tour-du-Crieu',
      'La Tour-de-Salvagny',
      'Latour-Bas-Elne',
      'La Tour',
      'Latillé',
      'Lathus-Saint-Rémy',
      'La Teste-de-Buch',
      'La Tessoualle',
      'La Terrasse',
      'La Tardière',
      'La Talaudière',
      'La Suze-sur-Sarthe',
      'Lassy',
      'Lassigny',
      'Lasseube',
      'Lassay-les-Châteaux',
      'La Souterraine',
      'La Seyne-sur-Mer',
      'La Sentinelle',
      'La Selle-la-Forge',
      'La Séguinière',
      'La Séauve-sur-Semène',
      'La Sauve',
      'La Saussaye',
      'La Salvetat-Peyralès',
      'La Salvetat-sur-Agout',
      'La Salvetat-Saint-Gilles',
      'La Salle-et-Chapelle-Aubry',
      'Lasalle',
      'Laruscade',
      'Laruns',
      'Larringes',
      'Larressore',
      'La Roquette-sur-Siagne',
      'Laroque-Timbaut',
      "Laroque-d'Olmes",
      'Laroque-des-Albères',
      "La Roque-d'Anthéron",
      'La Roquebrussanne',
      'Laroquebrou',
      'Laroque',
      'La Romagne',
      'La Roche-Vineuse',
      'La Rochette',
      'La Roche-sur-Yon',
      'La Roche-sur-Foron',
      'Laroche-Saint-Cydroine',
      'La Roche-Posay',
      'La Roche-Maurice',
      'La Rochelle',
      'La Rochefoucauld',
      'La Roche-des-Arnauds',
      'La Roche-Derrien',
      'La Roche-de-Glun',
      'La Roche-Chalais',
      'La Roche-Blanche',
      'Larmor-Plage',
      'Larmor-Baden',
      'La Rivière-Saint-Sauveur',
      'La Rivière-de-Corps',
      'La Riche',
      'La Richardais',
      'La Ricamarie',
      'Largentière',
      'La Réole',
      'La Remuée',
      'La Remaudière',
      'La Regrippière',
      'Lardy',
      'Larche',
      'Larchamp',
      'Larçay',
      'La Ravoire',
      'Larajasse',
      'Laragne-Montéglin',
      'La Queue-les-Yvelines',
      'La Queue-en-Brie',
      'Lapugnoy',
      'Lapte',
      'Lapoutroie',
      'La Pouëze',
      'La Possonnière',
      'La Pommeraye',
      'La Pommeraie-sur-Sèvre',
      'La Pomme',
      'Laplume',
      'La Planche',
      'La Plaine-sur-Mer',
      'Lapeyrouse-Fossat',
      'La Peyratte',
      'La Penne-sur-Huveaune',
      'La Panouse',
      'Lapalud',
      'Lapalisse',
      'La Pacaudière',
      'Laon',
      'Lanvollon',
      'Lanvéoc',
      'Lanvénégen',
      'Lanvallay',
      'Lantriac',
      'Lantosque',
      'Lanton',
      'Lantic',
      'Lanta',
      'Lans-en-Vercors',
      'Lansargues',
      'Lanrodec',
      'Lanrivoaré',
      'Lanquetot',
      'Lanouée',
      'Lanouaille',
      'La Norville',
      'Lanobre',
      'Lannoy',
      'Lannion',
      'Lannilis',
      'Lannemezan',
      'Lanmeur',
      'Languidic',
      'Langueux',
      'Langrune-sur-Mer',
      'Langres',
      'Langonnet',
      'Langon',
      'Langoiran',
      'Langogne',
      'Langoat',
      'Langlade',
      'Langeais',
      'Langeac',
      'Laneuveville-devant-Nancy',
      'Lanester',
      'Landunvez',
      'Landudec',
      'Landser',
      'Landrévarzec',
      'Landres',
      'Landrecies',
      'Landivy',
      'Landivisiau',
      'Landiras',
      'Landévant',
      'Landeronde',
      'Landerneau',
      'Landemont',
      'Landeleau',
      'Landéhen',
      'Landéda',
      'Landéan',
      'Landaul',
      'Landas',
      'Lancrans',
      'Lançon-Provence',
      'Lancieux',
      'La Murette',
      'Lamure-sur-Azergues',
      'La Mure',
      'La Mulatière',
      'Lampertheim',
      'Lampaul-Plouarzel',
      'Lampaul-Guimiliau',
      'La Motte-Servolex',
      'La Motte-Saint-Martin',
      'La Motte-Saint-Jean',
      "La Motte-d'Aveillans",
      'Lamotte-Beuvron',
      'La Motte',
      'La Mothe-Saint-Héray',
      'Lamothe-Montravel',
      'La Mothe-Achard',
      'Lamorlaye',
      'Lamonzie',
      'La Montagne',
      'La Monnerie-le-Montel',
      'La Millère',
      'La Milesse',
      'La Mézière',
      'La Ménitré',
      'La Membrolle-sur-Longuenée',
      'La Membrolle-sur-Choisille',
      'La Meilleraye-de-Bretagne',
      'La Meilleraie-Tillay',
      'La Meignanne',
      'La Méaugon',
      'La Meauffe',
      'Lambres-lez-Douai',
      'Lambesc',
      'Lambersart',
      'Lamballe',
      'Lamastre',
      'Lamarque',
      'Lamarche-sur-Saône',
      'Lamarche',
      'Lamanon',
      'Lamalou-les-Bains',
      'La Mailleraye-sur-Seine',
      'Lamagistère',
      'La Madeleine-de-Nonancourt',
      'La Madeleine',
      'La Machine',
      'La Loupe',
      'La Loubière',
      'Laloubère',
      'La Longueville',
      'La Londe-les-Maures',
      'Lallaing',
      'Lalinde',
      'La Limouzinière',
      "Lalevade-d'Ardèche",
      'Lalbenque',
      'La Lande-Patry',
      'La Lande-de-Fronsac',
      'La Jumellière',
      'La Jubaudière',
      'La Jarrie',
      'La Jarne',
      'Laiz',
      'Laissac',
      'Lailly-en-Val',
      'Laillé',
      'Laigneville',
      'Laigné-en-Belin',
      'La Houssaye-en-Brie',
      'Lahonce',
      'La Haye-Pesnel',
      'La Haye-Malherbe',
      'La Haye-du-Puits',
      'Descartes',
      'La Haie-Fouassière',
      'La Guyonnière',
      'Laguiole',
      'La Guérinière',
      'La Guerche-de-Bretagne',
      'Laguenne',
      'La Grigonnais',
      'Lagrave',
      'La Grande-Paroisse',
      'La Grande-Motte',
      'La Grand-Croix',
      'La Gouesnière',
      'La Gorgue',
      'Lagord',
      'Lagorce',
      'Lagor',
      'Lagny-le-Sec',
      'Lagny-sur-Marne',
      'Lagnieu',
      'Lagnes',
      'La Glacerie',
      'La Génétouze',
      'La Gaude',
      'La Gaubretière',
      'Lagarrigue',
      'La Garnache',
      'La Garenne-Colombes',
      'Lagardelle-sur-Lèze',
      'La Garde-Freinet',
      'La Garde-Adhémar',
      'La Garde',
      'La Gacilly',
      'La Frette-sur-Seine',
      'La Fresnais',
      'La Francheville',
      'Lafrançaise',
      'La Fouillouse',
      'La Fouillade',
      'La Forêt-sur-Sèvre',
      'La Forêt-Fouesnant',
      'La Forest-Landerneau',
      'La Force',
      'La Flotte',
      'La Flocellière',
      'La Flèche',
      'La Flamengrie',
      'La Feuillie',
      'La Ferté-sous-Jouarre',
      'La Ferté-Saint-Aubin',
      'La Ferté-Milon',
      'La Ferté-Macé',
      'La Ferté-Imbault',
      'La Ferté-Gaucher',
      'La Ferté-Bernard',
      'La Ferté-Alais',
      'La Ferrière-aux-Étangs',
      'La Ferrière',
      'La Fère',
      'La Farlède',
      'La Fare-les-Oliviers',
      'Ladon',
      'La Dominelais',
      'Ladignac-le-Long',
      'La Destrousse',
      'La Daguenière',
      'Lacrouzette',
      'La Croix-Valmer',
      'Lacroix-Saint-Ouen',
      'La Croix-Rouge',
      'Lacroix-Falgarde',
      'La Croix-en-Touraine',
      'La Crau',
      'La Couture-Boussey',
      'La Couture',
      'La Courtine',
      'La Couronne',
      'La Courneuve',
      'La Couarde-sur-Mer',
      'La Côte-Saint-André',
      'La Coquille',
      'La Colle-sur-Loup',
      'La Cluse-et-Mijoux',
      'La Clusaz',
      'La Clayette',
      'La Ciotat',
      'La Chevrolière',
      'La Chaussée-Saint-Victor',
      'La Châtre',
      'La Châtaigneraie',
      'La Chartre-sur-le-Loir',
      'La Charité-sur-Loire',
      'La Chapelle-Thouarault',
      'La Chapelle-sur-Loire',
      'La Chapelle-sur-Erdre',
      'La Chapelle-sur-Crécy',
      'Lachapelle-sous-Aubenas',
      'La Chapelle-Saint-Ursin',
      'La Chapelle-Saint-Mesmin',
      'La Chapelle-Saint-Luc',
      'La Chapelle-Saint-Laurent',
      'La Chapelle-Saint-Florent',
      'La Chapelle-Saint-Aubin',
      'La Chapelle-Réanville',
      'La Chapelle-Launay',
      'La Chapelle-la-Reine',
      'La Chapelle-Janson',
      'La Chapelle-Heulin',
      'La Chapelle-Gauthier',
      'La Chapelle-en-Serval',
      'La Chapelle-du-Noyer',
      'La Chapelle-du-Genêt',
      'La Chapelle-des-Marais',
      'La Chapelle-des-Fougeretz',
      'La Chapelle-de-la-Tour',
      'La Chapelle-de-Guinchay',
      'La Chapelle-Basse-Mer',
      'Lachapelle-aux-Pots',
      'La Chapelle-Achard',
      'La Chambre',
      'La Chaize-le-Vicomte',
      'La Cerlangue',
      'La Celle-sur-Morin',
      'La Celle-Saint-Cloud',
      'La Celle-Saint-Avant',
      'La Celle',
      'La Cavalerie',
      'Lacaune',
      'Lacasse',
      'Lacapelle-Marival',
      'La Capelle-lès-Boulogne',
      'La Capelle',
      'La Canourgue',
      'Lacanau',
      'La Calmette',
      'La Buisse',
      'Labry',
      'Labruguière',
      'La Bruffière',
      'La Broque',
      'La Bridoire',
      'La Bresse',
      'La Boussac',
      'Labourse',
      'La Bourboule',
      'La Bouilladisse',
      'Labouheyre',
      'La Bouëxière',
      'La Bonneville-sur-Iton',
      'Laboissière-en-Thelle',
      'La Boissière-des-Landes',
      'La Boissière-de-Montaigu',
      'La Boisse',
      'La Bohalle',
      'Lablachère',
      'La Biolle',
      'Labeuvrière',
      'La Bernerie-en-Retz',
      'La Bernardière',
      'Labenne',
      'La Bégude-de-Mazenc',
      'Labégude',
      'Labège',
      'La Bazoge',
      'La Bazoche-Gouet',
      'Labatut',
      'La Bâtie-Neuve',
      'La Bâtie-Montgascon',
      'La Bâthie',
      'Labastidette',
      'Labastide-Saint-Sernin',
      'Labastide-Saint-Pierre',
      'Labastide-Saint-Georges',
      'Labastide-Rouairoux',
      'La Bastide-des-Jourdans',
      'Labastide-Beauvoir',
      'La Bassée',
      'Labarthe-sur-Lèze',
      'Labarthe-Rivière',
      'La Barthe-de-Neste',
      'La Barre-de-Monts',
      'Labaroche',
      'La Barasse',
      'La Balme-de-Sillingy',
      'La Baconnière',
      'Kuntzig',
      'Kunheim',
      'Kruth',
      'Krautergersheim',
      'Kœnigsmacker',
      'Knutange',
      'Kintzheim',
      'Kingersheim',
      'Kilstett',
      'Killem',
      'Keskastel',
      'Kervignac',
      'Kertzfeld',
      'Kersaint-Plabennec',
      'Kernilis',
      'Kerlouan',
      'Kerbach',
      'Kembs',
      'Kédange-sur-Canner',
      'Kaysersberg',
      'Kaltenhouse',
      'Juziers',
      'Juvisy-sur-Orge',
      'Juvigny-sous-Andaine',
      'Juvigné',
      'Juvignac',
      'Jussy',
      'Jussey',
      'Jussac',
      'Jurançon',
      'Jumilhac-le-Grand',
      'Jumièges',
      'Jumelles',
      'Jullouville',
      'Jujurieux',
      'Juilly',
      'Juillan',
      'Juillac',
      'Juigné-sur-Sarthe',
      'Juigné sur Loire',
      'Juigné',
      'Jugon-les-Lacs',
      'Joyeuse',
      'Jouy-sur-Morin',
      'Jouy-le-Potier',
      'Jouy-le-Moutier',
      'Jouy-le-Châtel',
      'Jouy-en-Josas',
      'Jouy-aux-Arches',
      'Jouy',
      'Joux-la-Ville',
      'Jouques',
      'Jougne',
      "Jouet-sur-l'Aubois",
      'Bourg de Joué-sur-Erdre',
      'Joué-lès-Tours',
      'Joudreville',
      'Jouars-Pontchartrain',
      'Jouarre',
      'Josselin',
      'Jonzieux',
      'Jonzac',
      'Jons',
      'Jonquières-Saint-Vincent',
      'Jonquières',
      'Jonquerettes',
      'Jonchery-sur-Vesle',
      'Joncherey',
      'Jonage',
      'Joinville-le-Pont',
      'Joinville',
      'Joigny',
      'Jœuf',
      'Job',
      'Jeumont',
      'Jenlain',
      'Jegun',
      'Jebsheim',
      'Jeanménil',
      'Javené',
      'Jaux',
      'Jausiers',
      'Jaunay-Clan',
      'Jaujac',
      'Jasseron',
      'Jassans-Riottier',
      'Jarzé',
      'Jarville-la-Malgrange',
      'Javron-les-Chapelles',
      'Jarrie',
      'Jarny',
      'Jarnac',
      'Jargeau',
      'Jard-sur-Mer',
      'Jardin',
      'Janzé',
      'Janville-sur-Juine',
      'Janville',
      'Jans',
      'Janneyrias',
      'Jallais',
      'Jacou',
      'Jacob-Bellecombette',
      'Izon',
      'Izernore',
      'Izeaux',
      'Iwuy',
      'Ivry-sur-Seine',
      'Ivry-la-Bataille',
      'Itxassou',
      'Itteville',
      'Ittenheim',
      'Iteuil',
      'Itancourt',
      'Istres',
      'Issy-les-Moulineaux',
      'Is-sur-Tille',
      'Issoudun',
      'Issou',
      'Issoire',
      'Issenheim',
      'Issé',
      'Isques',
      'Isneauville',
      'Isle',
      'Isigny-sur-Mer',
      'Isigny-le-Buat',
      'Isbergues',
      'Irvillac',
      'Irodouër',
      'Irmstett',
      'Irigny',
      'Innenheim',
      'Ingwiller',
      'Inguiniel',
      'Ingré',
      'Ingrandes',
      'Ingersheim',
      'Incheville',
      'Imphy',
      'Illzach',
      'Illkirch-Graffenstaden',
      'Illies',
      'Illfurth',
      'Ille-sur-Têt',
      'Illats',
      'Illange',
      'Igoville',
      'Igon',
      'Igny',
      'Igney',
      'Ifs',
      'Iffendic',
      'Idron',
      'Ibos',
      'Hyères',
      'Huttenheim',
      'Hussigny-Godbrange',
      'Husseren-Wesserling',
      'Hurigny',
      'Huriel',
      'Huningue',
      'Hundling',
      'Hulluch',
      'Huisseau-sur-Mauves',
      'Huisseau-sur-Cosson',
      'Huismes',
      'Huez',
      'Huelgoat',
      'Hoymille',
      'Houssen',
      'Hourtin',
      'Houppeville',
      'Houplines',
      'Houplin-Ancoisne',
      'Houlgate',
      'Houlbec-Cocherel',
      'Houilles',
      'Houdemont',
      'Houdan',
      'Houdain',
      'Hornoy-le-Bourg',
      'Hornaing',
      'Horgues',
      'Hordain',
      'Horbourg-Wihr',
      'Honfleur',
      'Hondschoote',
      'Homécourt',
      'Hombourg-Haut',
      'Homblières',
      'Hombleux',
      'Holving',
      'Holtzwihr',
      'Holtzheim',
      'Holnon',
      'Hœrdt',
      'Hœnheim',
      'Hochstatt',
      'Hochfelden',
      'Hirtzfelden',
      'Hirtzbach',
      'Hirson',
      'Hirsingue',
      'Hirel',
      'Hinx',
      'Hinges',
      'Hindisheim',
      'Hilsenheim',
      'Hillion',
      'Hiersac',
      'Hières-sur-Amby',
      'Heyrieux',
      'Heuringhem',
      'Heugas',
      'Heudreville-sur-Eure',
      'Hettange-Grande',
      'Hésingue',
      'Hesdin',
      'Herzeele',
      'Héry',
      'Hersin-Coupigny',
      'Herserange',
      'Herry',
      'Herrlisheim-près-Colmar',
      'Herrlisheim',
      'Hérouvillette',
      'Hérouville-Saint-Clair',
      'Hermonville',
      'Hermies',
      'Hermes',
      'Hermanville-sur-Mer',
      'Herlies',
      'Hérin',
      'Hérimoncourt',
      'Héricy',
      'Héricourt',
      'Héric',
      'Hergnies',
      'Hérépian',
      'Herblay',
      'Herbitzheim',
      'Herbignac',
      'Herbeys',
      'Herbault',
      'Henvic',
      'Henrichemont',
      'Hénouville',
      'Hénon',
      'Hennebont',
      'Hénin-Beaumont',
      'Hendaye',
      'Hénanbihen',
      'Hem',
      'Helfaut',
      'Hélesmes',
      'Heimsbrunn',
      'Heillecourt',
      'Hégenheim',
      'Hédé-Bazouges',
      'Hazebrouck',
      'Haybes',
      'Hayange',
      'Haverskerque',
      'Haveluy',
      'Hauville',
      'Hautot-sur-Mer',
      'Hautmont',
      'Yutz',
      'Hauteville-Lompnes',
      'Hauteville-lès-Dijon',
      'Haute-Rivoire',
      'Hauterives',
      'Hauterive',
      'Haute-Goulaine',
      'Hautefort',
      'Haussy',
      'Haulchin',
      'Haucourt',
      'Haubourdin',
      'Hatten',
      'Haspres',
      'Hasparren',
      'Hasnon',
      'Harnes',
      'Harly',
      'Hargarten-aux-Mines',
      'Harfleur',
      'Hardricourt',
      'Hardinghen',
      'Harbonnières',
      'Hanvec',
      'Hangenbieten',
      'Hanches',
      'Ham-sous-Varsberg',
      'Hames-Boucres',
      'Hambye',
      'Hambach',
      'Ham',
      'Halluin',
      'Hallines',
      'Hallennes-lez-Haubourdin',
      'Hallencourt',
      'Haisnes',
      'Haillicourt',
      'Haguenau',
      'Hagondange',
      'Hagetmau',
      'Hagenthal-le-Bas',
      'Hadol',
      'Habsheim',
      'Habas',
      'Gy',
      'Guyancourt',
      'Gurgy',
      'Gundershoffen',
      'Gumbrechtshoffen',
      'Gujan-Mestras',
      'Guîtres',
      'Guissény',
      'Guise',
      'Guiscriff',
      'Guiscard',
      'Guipry',
      'Guipel',
      'Guipavas',
      'Guingamp',
      'Guînes',
      'Guilvinec',
      'Guilliers',
      'Guillestre',
      'Guillac',
      'Guilherand-Granges',
      'Guilers',
      'Guignicourt',
      'Guignes',
      'Guignen',
      'Guiclan',
      'Guichen',
      'Guichainville',
      'Guewenheim',
      'Gueux',
      'Gueugnon',
      'Guéthary',
      'Guesnain',
      'Guerville',
      'Guern',
      'Guermantes',
      'Guerlesquin',
      'Guérigny',
      'Guéret',
      'Guéreins',
      'Guérard',
      'Guérande',
      'Guer',
      'Guenrouet',
      'Guénin',
      'Guengat',
      'Guénange',
      'Guémené-sur-Scorff',
      'Guémené-Penfao',
      'Guémar',
      'Guégon',
      'Guécélard',
      'Guebwiller',
      'Guarbecque',
      'Gruson',
      'Gruissan',
      'Gruffy',
      'Gruchet-le-Valasse',
      'Grosseto-Prugna',
      'Gros-Réderching',
      'Groslay',
      'Grosbreuil',
      'Grosbliederstroff',
      'Gron',
      'Groix',
      'Groisy',
      'Groissiat',
      'Groffliers',
      'Grisy-Suisnes',
      'Grisolles',
      'Grimaud',
      'Grillon',
      'Grigny',
      'Grignon',
      'Grignols',
      'Grignan',
      'Griesheim-sur-Souffel',
      'Griesheim-près-Molsheim',
      'Gries',
      'Grièges',
      'Grez-sur-Loing',
      'Grez-Neuville',
      'Grézieu-la-Varenne',
      'Gretz-Armainvilliers',
      'Grésy-sur-Isère',
      'Grésy-sur-Aix',
      'Gresswiller',
      'Gréoux-les-Bains',
      'Grenoble',
      'Grendelbruch',
      'Grenay',
      "Grenade-sur-l'Adour",
      'Grenade',
      'Gréasque',
      'Gray-la-Ville',
      'Gray',
      'Gravigny',
      'Graveson',
      'Gravelines',
      'Graulhet',
      'Gratentour',
      'Grasse',
      'Granville',
      'Grans',
      'Granges-sur-Vologne',
      'Grane',
      'Grandvilliers',
      'Grandvillars',
      'Grandris',
      'Gallargues-le-Montueux',
      'Grandfresnoy',
      'Grand-Fougeray',
      'Grand-Fort-Philippe',
      'Grandfontaine',
      'Grande-Synthe',
      'Grand-Couronne',
      'Grand-Charmont',
      'Grand-Champ',
      'Grandcamp-Maisy',
      'Grambois',
      'Gramat',
      'Grainville-sur-Odon',
      'Grainville-la-Teinturière',
      'Gragnague',
      'Gradignan',
      'Grâces',
      'Graçay',
      'Grabels',
      'Goven',
      'Gouzon',
      'Gouzeaucourt',
      'Gouy-sous-Bellonne',
      'Gouville-sur-Mer',
      'Gouvieux',
      'Gouvernes',
      'Goussainville',
      'Gournay-sur-Marne',
      'Gournay-en-Bray',
      'Gourin',
      'Gourdon',
      'Gourdan-Polignan',
      'Goult',
      'Gouézec',
      'Gouesnou',
      'Gouesnach',
      'Goudelin',
      'Goudargues',
      'Gouarec',
      'Gouaix',
      'Gosné',
      'Gosnay',
      'Gorze',
      'Gorron',
      'Gorges',
      'Gordes',
      'Gorcy',
      'Gorbio',
      'Gontaud-de-Nogaret',
      'Gonneville-la-Mallet',
      'Gonnehem',
      'Gonfaron',
      'Gonesse',
      'Gondrin',
      'Gondreville',
      'Gondrecourt-le-Château',
      'Gondecourt',
      'Goncelin',
      'Gommegnies',
      'Gometz-le-Châtel',
      'Gometz-la-Ville',
      'Golbey',
      'Goincourt',
      'Gœulzin',
      'Goetzenbruck',
      'Gœrsdorf',
      'Godewaersvelde',
      'Goderville',
      'Glomel',
      'Gleizé',
      'Givry',
      'Givrand',
      'Givors',
      'Givet',
      'Givenchy-en-Gohelle',
      'Gisors',
      'Giroussens',
      'Gironde-sur-Dropt',
      'Giromagny',
      'Giraumont',
      'Ginestas',
      'Ginasservis',
      'Gimont',
      'Gilly-sur-Isère',
      'Gillonnay',
      'Gilley',
      'Gilette',
      'Gignac-la-Nerthe',
      'Gignac',
      'Gigean',
      'Gif-sur-Yvette',
      'Gièvres',
      'Gières',
      'Gien',
      'Gidy',
      'Giberville',
      'Ghyvelde',
      'Ghisonaccia',
      'Gex',
      'Gevrey-Chambertin',
      'Gévezé',
      'Geudertheim',
      'Gétigné',
      'Gestel',
      'Gesté',
      'Gespunsart',
      'Gerzat',
      'Gerstheim',
      'Gergy',
      'Gerde',
      'Gerbéviller',
      'Gérardmer',
      'Ger',
      'Gentilly',
      'Gensac-la-Pallue',
      'Gennevilliers',
      'Gennes',
      'Genlis',
      'Génissieux',
      'Génissac',
      'Genillé',
      'Générac',
      'Génelard',
      'Genech',
      'Genay',
      'Genas',
      'Gémozac',
      'Gémenos',
      'Gelos',
      'Gelles',
      'Geispolsheim',
      'Gazeran',
      'Gavray',
      'Gauriaguet',
      'Gauchy',
      'Gattières',
      'Gasville-Oisème',
      'Gassin',
      'Gasny',
      'Garons',
      'Garnay',
      'Garlin',
      'Garidech',
      'Garges-lès-Gonesse',
      'Gargenville',
      'Gargas',
      'Garéoult',
      'Garennes-sur-Eure',
      'Gardouch',
      'Gardonne',
      'Gardanne',
      'Garchizy',
      'Garches',
      'Garat',
      'Garancières',
      'Gap',
      'Gannat',
      'Ganges',
      'Gandrange',
      'Gan',
      'Gambsheim',
      'Gambais',
      'Gamaches',
      'Galluis',
      'Gallardon',
      'Galgon',
      'Gainneville',
      'Gaillon',
      'Gaillefontaine',
      'Gaillard',
      'Gaillan-en-Médoc',
      'Gaillac-Toulza',
      'Gaillac',
      'Gagny',
      'Gagnac-sur-Garonne',
      'Gaël',
      'Gacé',
      'Gabarret',
      'Fuveau',
      'Fussy',
      'Furiani',
      'Furdenheim',
      'Fumel',
      'Fumay',
      'Fublaines',
      'Fruges',
      'Frouzins',
      'Frouard',
      'Frotey-lès-Vesoul',
      'Frossay',
      'Frontonas',
      'Fronton',
      'Frontignan',
      'Frontenex',
      'Frontenay-Rohan-Rohan',
      'Fronsac',
      'Froncles',
      'Fromelennes',
      'Froidfond',
      'Froideconche',
      'Froges',
      'Friville-Escarbotin',
      'Frignicourt',
      'Freyming-Merlebach',
      'Frévent',
      'Fretin',
      'Fréthun',
      'Fresse-sur-Moselle',
      'Fressenneville',
      'Fresnoy-le-Grand',
      'Fresnes-sur-Escaut',
      'Fresnes',
      'Fresnay-sur-Sarthe',
      'Frépillon',
      'Freneuse',
      'Frelinghien',
      'Fréland',
      'Fréjus',
      'Fréjairolles',
      'Freigné',
      'Frasne',
      'Frans',
      'Franois',
      'Frangy',
      'Franconville',
      'Francheville',
      'Francheleins',
      'Fraize',
      'Fraisses',
      'Fraisans',
      'Frahier-et-Chatebier',
      'Foussais-Payré',
      'Fourqueux',
      'Fourques-sur-Garonne',
      'Fourques',
      'Fournes-en-Weppes',
      'Fourmies',
      'Fourchambault',
      'Fouras',
      'Fouquières-lès-Lens',
      'Fouquières-lès-Béthune',
      'Fouquereuil',
      'Foulayronnes',
      'Fouillard',
      'Fougerolles-du-Plessis',
      'Fougerolles',
      'Fougères',
      'Foug',
      'Fouesnant',
      'Foucherans',
      'Foucarmont',
      'Fos-sur-Mer',
      'Fosses',
      'Fortschwihr',
      'Fort-Mardyck',
      'Fort-Mahon-Plage',
      'Fors',
      'Formerie',
      'Forges-les-Eaux',
      'Forges-les-Bains',
      'Forest-sur-Marque',
      'Forcalquier',
      'Forcalqueiret',
      'Forbach',
      'Fontvieille',
      'Font-Romeu-Odeillo-Via',
      'Fontoy',
      "Fontevraud-l'Abbaye",
      'Fontenilles',
      'Fontenay-Trésigny',
      'Fontenay-sur-Loing',
      'Fontenay-sous-Bois',
      'Fontenay-lès-Briis',
      'Fontenay-le-Marmion',
      'Fontenay-le-Fleury',
      'Fontenay-le-Comte',
      'Fontenay-en-Parisis',
      'Fontenay-aux-Roses',
      'Fontenay',
      'Fontcouverte',
      'Fontannes',
      'Fontanil-Cornillon',
      'Fontaines-sur-Saône',
      'Fontaines-Saint-Martin',
      'Fontaines',
      'Fontaine-Notre-Dame',
      'Fontaine-lès-Luxeuil',
      'Fontaine-lès-Dijon',
      'Fontaine-le-Dun',
      'Fontaine-le-Comte',
      'Fontaine-le-Bourg',
      'Fontaine-la-Mallet',
      'Fontaine-la-Guyon',
      'Fontaine-Étoupefour',
      'Fontainebleau',
      'Fontaine-au-Pire',
      'Fontaine',
      'Fonsorbes',
      'Fondettes',
      'Fonbeauzard',
      'Folschviller',
      'Follainville-Dennemont',
      'Folkling',
      'Folembray',
      'Foix',
      'Foissiat',
      'Foëcy',
      'Flourens',
      'Florensac',
      'Florange',
      'Florac',
      'Floirac',
      'Floing',
      'Flogny-la-Chapelle',
      'Flize',
      'Flixecourt',
      'Flins-sur-Seine',
      'Flines-lez-Raches',
      'Flines-lès-Mortagne',
      'Fléville-devant-Nancy',
      'Fleury-sur-Orne',
      'Fleury-sur-Andelle',
      'Fleury-Mérogis',
      'Fleury-les-Aubrais',
      'Fleury-la-Vallée',
      'Fleury',
      'Fleurines',
      'Fleurieu-sur-Saône',
      'Fleurie',
      'Fleurey-sur-Ouche',
      'Fleurbaix',
      'Fleurance',
      'Flesselles',
      'Flers-en-Escrebieux',
      'Flers',
      'Fléac',
      'Flayosc',
      'Flaxlanden',
      'Flavy-le-Martel',
      'Flavin',
      'Flavigny-sur-Moselle',
      'Flaviac',
      'Flassans-sur-Issole',
      'Flamanville',
      'Fitz-James',
      'Fitilieu',
      'Fismes',
      'Firminy',
      'Firmi',
      'Finhan',
      'Fillinges',
      'Fillé',
      'Figeac',
      'Figanières',
      'Feyzin',
      'Feytiat',
      'Feurs',
      'Feuquières-en-Vimeu',
      'Feuquières',
      'Feuchy',
      'Feucherolles',
      'Féternes',
      'Festubert',
      'Fessenheim',
      'Fesches-le-Châtel',
      'Ferrières-en-Brie',
      'Ferrière-la-Grande',
      'Ferrette',
      'Ferrals-les-Corbières',
      'Ferques',
      'Férolles-Attilly',
      'Férolles',
      'Ferney-Voltaire',
      'Fermanville',
      'Férin',
      'Férel',
      'Fère-en-Tardenois',
      'Fère-Champenoise',
      'Fenouillet',
      'Feneu',
      'Fénay',
      'Fenain',
      'Felletin',
      'Fellering',
      'Félines',
      'Feillens',
      'Feignies',
      'Feigères',
      'Fégréac',
      'Fegersheim',
      'Féchain',
      'Fécamp',
      'Fayl-Billot',
      'Fayence',
      'Fay-de-Bretagne',
      'Fay-aux-Loges',
      'Favières',
      'Faverolles-sur-Cher',
      'Faverney',
      'Faverges-de-la-Tour',
      'Faverges',
      'Fauville-en-Caux',
      'Faumont',
      'Faulx',
      'Faulquemont',
      'Farschviller',
      'Farnay',
      'Fargues-Saint-Hilaire',
      'Faremoutiers',
      'Fareins',
      'Farébersviller',
      'Fampoux',
      'Fameck',
      'Famars',
      'Falleron',
      'Falicon',
      'Falck',
      'Falaise',
      'Fains-Véel',
      'Fagnières',
      'Faches-Thumesnil',
      'Fabrezan',
      'Fabrègues',
      'Ézy-sur-Eure',
      'Èze',
      'Ézanville',
      'Eyzin-Pinet',
      'Eyvigues-et-Eybènes',
      'Eysines',
      'Eyragues',
      'Eymoutiers',
      'Eymet',
      'Eyguières',
      'Eygalières',
      'Eybens',
      'Exireuil',
      'Exincourt',
      'Exideuil-sur-Vienne',
      'Excideuil',
      'Évry',
      'Évron',
      'Évreux',
      'Évrecy',
      'Évran',
      'Évires',
      'Évin-Malmaison',
      'Évian-les-Bains',
      'Évenos',
      'Évaux-les-Bains',
      'Euville',
      'Eurville-Bienville',
      'Eurre',
      'Eulmont',
      'Eu',
      'Etzling',
      'Étupes',
      'Étriché',
      'Étreux',
      'Étretat',
      'Étrépagny',
      'Étrembières',
      'Étrelles',
      'Étreillers',
      'Étréchy',
      'Étoile-sur-Rhône',
      'Étival-lès-le-Mans',
      'Étival-Clairefontaine',
      'Étiolles',
      'Éterville',
      'Étel',
      'Etaux',
      'Étauliers',
      'Étaules',
      'Étaples',
      'Étang-sur-Arroux',
      'Étampes-sur-Marne',
      'Étampes',
      'Étalans',
      'Étainhus',
      'Étain',
      'Étagnac',
      'Étables-sur-Mer',
      'Esvres',
      'Estrées-Saint-Denis',
      'Estrées',
      'Estrablin',
      'Estivareilles',
      'Estissac',
      'Estillac',
      'Estevelles',
      'Esternay',
      'Estaires',
      'Estagel',
      'Essômes-sur-Marne',
      'Essigny-le-Grand',
      'Essey-lès-Nancy',
      'Essert',
      'Essars',
      'Esquibien',
      'Esquerdes',
      'Esquelbecq',
      'Esquéhéries',
      'Espéraza',
      'Espelette',
      'Espaly-Saint-Marcel',
      'Espalion',
      'Esnandes',
      'Eslettes',
      'Escoutoux',
      'La Baule-Escoublac',
      'Esches',
      'Eschentzwiller',
      'Eschbach',
      'Eschau',
      'Escautpont',
      'Escaudœuvres',
      'Escaudain',
      'Escalquens',
      'Esbly',
      'Ervy-le-Châtel',
      'Erstein',
      'Erre',
      'Erquy',
      'Erquinghem-Lys',
      'Ernolsheim-Bruche',
      'Ernée',
      'Ermont',
      'Ergué-Gabéric',
      'Erdeven',
      'Ercuis',
      'Ercé-près-Liffré',
      'Ercé-en-Lamée',
      'Erbrée',
      'Erbray',
      'Éragny',
      'Équihen-Plage',
      'Équeurdreville-Hainneville',
      'Équemauville',
      'Épron',
      'Eppeville',
      'Épouville',
      'Épône',
      'Épinouze',
      'Epiniac',
      'Épinay-sur-Seine',
      'Épinay-sur-Orge',
      'Épinay-sous-Sénart',
      'Épinal',
      'Épinac',
      'Épieds-en-Beauce',
      'Epfig',
      'Épervans',
      'Épernon',
      'Épernay',
      'Éperlecques',
      'Épehy',
      'Épaignes',
      'Épagny',
      'Éoures',
      'Envermeu',
      'Enval',
      'Entzheim',
      'Entre-Deux-Guiers',
      'Entraygues-sur-Truyère',
      'Entrange',
      'Entrammes',
      'Entraigues-sur-la-Sorgue',
      'Ensuès-la-Redonne',
      'Ensisheim',
      'Ennezat',
      'Ennevelin',
      'Ennetières-en-Weppes',
      'Ennery',
      'Englefontaine',
      'Enghien-les-Bains',
      'Enchenberg',
      'Emmerin',
      'Émerchicourt',
      'Émerainville',
      'Embrun',
      'Elven',
      'Éloyes',
      'Elne',
      'Elliant',
      'Éleu-dit-Leauwette',
      'Elbeuf',
      'Élancourt',
      'Einville-au-Jard',
      'Éguzon-Chantôme',
      'Eguisheim',
      'Éguilles',
      'Égriselles-le-Bocage',
      'Égreville',
      'Égly',
      'Égletons',
      'Edern',
      'Écully',
      'Écuisses',
      'Écuelles',
      'Écueillé',
      'Écrouves',
      'Écrainville',
      'Ecquevilly',
      'Ecques',
      'Écoyeux',
      'Écourt-Saint-Quentin',
      'Écouflant',
      'Écouen',
      'Écouché',
      'Écommoy',
      'École-Valentin',
      'Eckwersheim',
      'Eckbolsheim',
      'Échirolles',
      'Échiré',
      'Échillais',
      'Échenoz-la-Méline',
      'Échenevex',
      'Échalas',
      'Écaillon',
      'Ébreuil',
      'Ebersheim',
      'Eauze',
      'Eaunes',
      'Eaubonne',
      'Duttlenheim',
      'Dury',
      'Durtol',
      'Durtal',
      'Durrenbach',
      'Larequille',
      'Duras',
      'Duppigheim',
      'Dun-sur-Auron',
      'Dun-le-Palestel',
      'Dunkerque',
      'Dunières',
      'Duisans',
      'Dugny-sur-Meuse',
      'Dugny',
      'Duclair',
      'Ducey',
      'Dry',
      'Drusenheim',
      'Drumettaz',
      'Drulingen',
      'Druelle',
      'Droue-sur-Drouette',
      'Droué',
      'Drocourt',
      'Dreux',
      'Dreuil-lès-Amiens',
      'Dreslincourt',
      'Drémil-Lafage',
      'Drefféac',
      'Draveil',
      'Drap',
      'Drancy',
      'Drain',
      'Draguignan',
      'Dracy-le-Fort',
      'Dozulé',
      'Doyet',
      'Douzy',
      'Douvrin',
      'Douvres-la-Délivrande',
      'Douvaine',
      'Doussard',
      'Dourgne',
      'Dourges',
      'Dourdan',
      'Doullens',
      'Doulaincourt-Saucourt',
      'Doué-la-Fontaine',
      'Doue',
      'Doudeville',
      'Douchy-les-Mines',
      'Douchy',
      'Doubs',
      'Douarnenez',
      'Douai',
      'Dossenheim-sur-Zinsel',
      'Dortan',
      'Dornes',
      'Dormans',
      'Dorlisheim',
      'Dordives',
      'Donzy',
      'Donzère',
      'Donzenac',
      'Donville-les-Bains',
      'Donnery',
      'Donnemarie-Dontilly',
      'Donges',
      'Doncourt-lès-Conflans',
      'Donchery',
      'Don',
      'Dompierre-sur-Yon',
      'Dompierre-sur-Veyle',
      'Dompierre-sur-Mer',
      'Dompierre-sur-Besbre',
      'Domont',
      'Domme',
      'Dommartin-lès-Toul',
      'Dommartin-lès-Remiremont',
      'Dom-le-Mesnil',
      'Domgermain',
      'Domfront',
      'Domessin',
      'Domérat',
      'Domène',
      'Dombasle-sur-Meurthe',
      'Domart-en-Ponthieu',
      'Domarin',
      'Domancy',
      'Domalain',
      'Domagné',
      "Dolus-d'Oléron",
      'Dolomieu',
      'Dollon',
      'Dole',
      'Dol-de-Bretagne',
      'Doingt',
      'Dogneville',
      'Docelles',
      'Dizy',
      'Divonne-les-Bains',
      'Divion',
      'Dives-sur-Mer',
      'Distroff',
      'Distré',
      'Dissay',
      'Dirinon',
      'Dirac',
      'Diou',
      'Dinsheim-sur-Bruche',
      'Dingy-Saint-Clair',
      'Dingsheim',
      'Dingé',
      'Dinéault',
      'Dinard',
      'Dinan',
      'Dijon',
      'Digosville',
      'Digoin',
      'Digne-les-Bains',
      'Dignac',
      'Diges',
      'Dieuze',
      'Dieulouard',
      'Dieulefit',
      'Dieue-sur-Meuse',
      'Dietwiller',
      'Diesen',
      'Dieppe',
      'Diémoz',
      'Diemeringen',
      'Diebling',
      'Die',
      'Didenheim',
      'Dhuizon',
      'Deyvillers',
      'Déville-lès-Rouen',
      'Deville',
      'Devecey',
      'Deûlémont',
      'Deuil-la-Barre',
      'Dettwiller',
      'Desvres',
      'Dessenheim',
      'Désertines',
      'Désaignes',
      'Derval',
      'Déols',
      'Denicé',
      'Denguin',
      'Denée',
      'Denain',
      'Démouville',
      'Demigny',
      'Delle',
      'Decize',
      'Décines-Charpieu',
      'Dechy',
      'Decazeville',
      'Deauville',
      'Dax',
      'Davézieux',
      'Daux',
      'Daumeray',
      'Dauendorf',
      'Dasle',
      'Darvoy',
      'Darnieulles',
      'Darney',
      'Darnétal',
      'Dargnies',
      'Dardilly',
      'Daoulas',
      'Dannes',
      'Dannemarie-sur-Crête',
      'Dannemarie',
      'Danjoutin',
      'Dangé-Saint-Romain',
      'Damville',
      'Damprichard',
      'Dampmart',
      'Dampierre-sur-Salon',
      'Dampierre-les-Bois',
      'Dampierre-en-Burly',
      'Dampierre-en-Yvelines',
      'Dampierre',
      'Damparis',
      'Dammartin-en-Goële',
      'Dammarie-les-Lys',
      'Dammarie',
      'Damigny',
      'Damgan',
      'Damery',
      'Damelevières',
      'Dambach-la-Ville',
      'Damazan',
      'Dallet',
      'Daix',
      'Dainville',
      'Dagneux',
      'Dadonville',
      'Dachstein',
      'Dabo',
      'Cysoing',
      'Cuzieu',
      'Cuxac-Cabardès',
      'Cuverville',
      'Cuttoli-Corticchiato',
      'Cusy',
      'Custines',
      'Cusset',
      'Cussac',
      'Cussac-Fort-Médoc',
      'Cussac-sur-Loire',
      'Curtin',
      'Curgy',
      'Curgies',
      'Cunlhat',
      'Cunac',
      'Culoz',
      'Culhat',
      'Cuisiat',
      'Cuisery',
      'Cuise-la-Motte',
      'Cuiseaux',
      'Cuincy',
      'Cuinchy',
      'Cugnaux',
      'Cuges-les-Pins',
      'Cugand',
      'Cuffy',
      'Cuffies',
      'Cuers',
      'Cucuron',
      'Cucq',
      'Cubzac-les-Ponts',
      'Cublize',
      'Cublac',
      'Crusnes',
      'Cruseilles',
      'Cruet',
      'Cruas',
      'Crozon',
      'Crouy-sur-Ourcq',
      'Crouy-en-Thelle',
      'Crouy',
      'Crottet',
      'Croth',
      'Crossac',
      'Crosne',
      'Crolles',
      'Croix',
      'Croissy-sur-Seine',
      'Croissy-Beaubourg',
      'Croisilles',
      'Crissey',
      'Crisolles',
      "Criquetot-l'Esneval",
      'Criquebeuf-sur-Seine',
      'Criel-sur-Mer',
      'Crézancy',
      'Creysse',
      'Creys-Mépieu',
      'Crevin',
      'Crèvecœur-le-Grand',
      'Creuzier-le-Vieux',
      'Creutzwald',
      'Creully',
      'Créteil',
      'Crest',
      'Cresserons',
      'Crespin',
      'Crespières',
      'Crépy-en-Valois',
      'Crépy',
      'Créon',
      'Creney-près-Troyes',
      'Crémieu',
      'Creissels',
      'Creissan',
      'Creil',
      'Créhen',
      'Créhange',
      'Crégy-lès-Meaux',
      'Crédin',
      'Crécy-sur-Serre',
      'Crécy-en-Ponthieu',
      'Crêches-sur-Saône',
      'Créances',
      'Cravanche',
      'Craponne-sur-Arzon',
      'Craponne',
      'Craon',
      'Cranves-Sales',
      'Cransac',
      'Cran-Gevrier',
      'Crach',
      'Cozes',
      'Coye-la-Forêt',
      "Couzon-au-Mont-d'Or",
      'Couzeix',
      'Coux',
      'Couvron-et-Aumencourt',
      'Coutras',
      'Coutouvre',
      'Coutiches',
      'Couternon',
      'Couterne',
      'Coutances',
      'Coussac-Bonneval',
      'Cousolre',
      'Cousance',
      'Courzieu',
      'Courville-sur-Eure',
      'Courtry',
      'Courtisols',
      'Courthézon',
      'Courtenay',
      'Courteilles',
      'Cours-les-Barres',
      'Courseulles-sur-Mer',
      'Cours-de-Pile',
      'Coursan',
      'Coursac',
      'Cours-la-Ville',
      'Courrières',
      'Courpière',
      'Courpalay',
      'Cournonterral',
      'Cournonsec',
      "Cournon-d'Auvergne",
      'Courmelles',
      'Courlon-sur-Yonne',
      'Courlay',
      'Courdimanche',
      'Courcy',
      'Courcouronnes',
      'Courçon',
      'Courcité',
      'Cour-Cheverny',
      'Courchevel',
      'Courchelettes',
      'Courcelles-sur-Seine',
      'Courcelles-lès-Montbéliard',
      'Courcelles-lès-Lens',
      'Courcelles-Chaussy',
      'Courbevoie',
      'Coupvray',
      'Coulounieix',
      "Coulonges-sur-l'Autize",
      'Coulon',
      'Coulommiers',
      'Coulombs',
      'Coulombiers',
      'Coulogne',
      'Coullons',
      'Coulans-sur-Gée',
      'Coulanges-lès-Nevers',
      'Coulaines',
      'Couiza',
      'Couilly-Pont-aux-Dames',
      'Couhé',
      'Coufouleux',
      'Couffé',
      'Couëron',
      'Coudun',
      'Coudoux',
      'Coudekerque-Branche',
      'Coudekerque-Village',
      'Coucy-le-Château-Auffrique',
      'Couchey',
      'Couches',
      'Coubron',
      'Coubon',
      'Coublevie',
      'Coubert',
      'Cotignac',
      'Cossé-le-Vivien',
      'Cosne-Cours-sur-Loire',
      'Cosnes',
      'Cosnac',
      'Corzé',
      'Corte',
      'Corseul',
      'Corsept',
      'Corrèze',
      'Corquilleroy',
      'Corps-Nuds',
      'Corpeau',
      'Coron',
      'Corny-sur-Moselle',
      'Cornimont',
      'Cornillon-Confoux',
      'Cornil',
      'Cornier',
      'Corneville-sur-Risle',
      'Corneilla-la-Rivière',
      'Corneilla-del-Vercol',
      'Corneilhan',
      'Cornebarrieu',
      'Corné',
      'Cornas',
      'Cormontreuil',
      'Cormicy',
      'Cormery',
      'Corme-Royal',
      'Cormeray',
      'Cormelles-le-Royal',
      'Cormeilles-en-Parisis',
      'Cormeilles',
      'Corlay',
      'Corenc',
      'Cordon',
      'Cordes-sur-Ciel',
      'Cordemais',
      'Corcieux',
      'Corbreuse',
      'Corbigny',
      'Corbie',
      'Corbenay',
      'Corbelin',
      'Corbeilles',
      'Corbeil-Essonnes',
      'Corbehem',
      'Corbas',
      'Corbarieu',
      'Coray',
      'Coquelles',
      'Conty',
      'Contrexéville',
      'Contres',
      'Contes',
      'Contamine-sur-Arve',
      'Conques-sur-Orbiel',
      'Conquereuil',
      'Connerré',
      'Connaux',
      'Connantre',
      'Conlie',
      'Congrier',
      'Congis-sur-Thérouanne',
      'Congénies',
      'Confolens',
      'Conflans-Sainte-Honorine',
      'Conflans-en-Jarnisy',
      'Condrieu',
      'Condom',
      'Condette',
      'Condé-sur-Vire',
      'Condé-sur-Vesgre',
      'Condé-sur-Sarthe',
      'Condé-sur-Noireau',
      "Condé-sur-l'Escaut",
      'Condé-sur-Huisne',
      'Condé-Sainte-Libiaire',
      'Condat-sur-Vienne',
      'Condat',
      'Conches-en-Ouche',
      'Conches-sur-Gondoire',
      'Concarneau',
      'Comps',
      'Compreignac',
      'Compiègne',
      'Compertrix',
      'Communay',
      'Commercy',
      'Commer',
      'Commequiers',
      'Commentry',
      'Commelle',
      'Comines',
      'Combs-la-Ville',
      'Combronde',
      'Combrit',
      'Combrée',
      'Combrand',
      'Combourg',
      'Combloux',
      'Pontault-Combault',
      'Combaillaux',
      'Colpo',
      'Colomiers',
      'Colombiès',
      'Colombiers',
      'Colombier-Fontaine',
      'Colombey-les-Belles',
      'Colombes',
      'Colombelles',
      'Colombe',
      'Colomars',
      'Colmar',
      'Collonges-sous-Salève',
      'Collonges',
      'Collobrières',
      'Collioure',
      'Colleville-Montgomery',
      'Colleret',
      'Collégien',
      'Coligny',
      'Colayrac-Saint-Cirq',
      'Coise',
      'Coincy',
      'Coignières',
      'Cogolin',
      'Cogny',
      'Cognin',
      'Cognac-la-Forêt',
      'Cognac',
      'Coëx',
      'Coëtmieux',
      'Coësmes',
      'Codognan',
      'Cocheren',
      'Coarraze',
      'Cluses',
      'Cluny',
      'Cluis',
      'Cloyes-sur-le-Loir',
      'Clouange',
      'Clonas-sur-Varèze',
      'Clohars-Fouesnant',
      'Clohars-Carnoët',
      'Clisson',
      'Clion',
      'Clinchamps-sur-Orne',
      'Clichy-sous-Bois',
      'Clichy',
      'Cléry-Saint-André',
      'Clerval',
      'Clermont-Ferrand',
      'Clermont-en-Argonne',
      'Clermont-Créans',
      'Clermont',
      'Clérieux',
      'Clères',
      'Cléré-les-Pins',
      'Clérac',
      'Cléon',
      'Cléguérec',
      'Cléguer',
      'Cléder',
      'Cléden-Poher',
      'Clécy',
      'Claye-Souilly',
      'Claville',
      'Clary',
      'Claret',
      'Clarensac',
      'Clapiers',
      'Clamecy',
      'Clamart',
      'Claix',
      'Clairvaux-les-Lacs',
      'Clairoix',
      'Clairac',
      'Claira',
      'Civrieux',
      'Civray-de-Touraine',
      'Civray',
      'Civens',
      'Varennes-Vauzelles',
      'Cissé',
      'Ciry-le-Noble',
      'Cirey-sur-Vezouze',
      'Cires-lès-Mello',
      "Ciré-d'Aunis",
      'Cintré',
      'Cintegabelle',
      'Cinqueux',
      'Cinq-Mars-la-Pile',
      'Ciboure',
      'Chuzelles',
      'Chouzy-sur-Cisse',
      'Chouzé-sur-Loire',
      'Chorges',
      'Chomérac',
      'Cholet',
      'Choisy-le-Roi',
      'Choisy-en-Brie',
      'Choisy-au-Bac',
      'Choisy',
      'Choisey',
      'Chocques',
      'Chitenay',
      'Chissay-en-Touraine',
      'Chiry-Ourscamp',
      'Chirens',
      'Chirac',
      'Chinon',
      'Chindrieux',
      'Chimilin',
      'Chilly-Mazarin',
      'Chilly',
      'Chilleurs-aux-Bois',
      'Chierry',
      'Chiché',
      'Chézy-sur-Marne',
      'Chevry-Cossigny',
      'Chevry',
      'Chevrières',
      'Chevreuse',
      'Chèvremont',
      'Chevilly-Larue',
      'Chevilly',
      'Chevillon-sur-Huillard',
      'Chevillon',
      'Chevigny-Saint-Sauveur',
      'Cheverny',
      'Chevannes',
      'Chevanceaux',
      'Cheval-Blanc',
      'Chevaigné',
      'Cheux',
      'Chessy',
      'Cherveux',
      'Cherves-Richemont',
      'Cherrueix',
      'Cherré',
      'Chéroy',
      'Chermignac',
      'Cherisy',
      'Chéreng',
      'Cherbourg-Octeville',
      'Chéraute',
      'Chérac',
      'Chépy',
      'Cheptainville',
      'Cheny',
      'Chens-sur-Léman',
      'Chenôve',
      'Chenoise',
      'Chennevières-sur-Marne',
      'Cheniménil',
      'Chênehutte-Trèves-Cunault',
      'Chemillé-Melay',
      'Chéméré',
      'Chemazé',
      'Chemaudin',
      'Chelles',
      'Cheillé',
      'Chef-Boutonne',
      'Chécy',
      'Chazey-sur-Ain',
      'Chazelles-sur-Lyon',
      'Chazelles',
      'Chaville',
      'Chavigny',
      'Chavenay',
      'Chavelot',
      'Chavanoz',
      'Chavanod',
      'Chavanay',
      'Chavagnes-en-Paillers',
      'Chavagne',
      'Chauvigny',
      'Chauvé',
      'Chaussin',
      'Chauriat',
      'Chauray',
      'Chauny',
      'Chaunay',
      'Chaumont-sur-Tharonne',
      'Chaumont-sur-Loire',
      'Chaumont-en-Vexin',
      'Chaumontel',
      'Chaumont',
      'Chaumes-en-Brie',
      'Chaulnes',
      'Chaulgnes',
      'Chauffailles',
      'Chaudron-en-Mauges',
      'Chaudon',
      'Chaudes-Aigues',
      'Chauconin-Neufmontiers',
      'Chauché',
      'Chatuzange-le-Goubet',
      'Chatte',
      'Châtres-sur-Cher',
      'Chatou',
      'Châtonnay',
      'Châtillon-sur-Thouet',
      'Châtillon-sur-Seine',
      'Châtillon-sur-Seiche',
      'Châtillon-sur-Marne',
      'Châtillon-sur-Indre',
      'Châtillon-sur-Cluses',
      'Châtillon-sur-Cher',
      'Châtillon-sur-Chalaronne',
      'Châtillon-le-Duc',
      'Châtillon-la-Palud',
      'Châtillon-en-Vendelais',
      'Châtillon-en-Bazois',
      'Châtillon-en-Michaille',
      'Châtillon',
      'Châtillon-Coligny',
      'Châtenoy-le-Royal',
      'Châtenois-les-Forges',
      'Châtenois',
      'Châtenay-sur-Seine',
      'Châtenay-Malabry',
      'Châtel-sur-Moselle',
      'Châtel-Saint-Germain',
      'Châtellerault',
      'Châtel-Guyon',
      'Châtelaillon-Plage',
      'Châtel',
      'Châteauvillain',
      'Château-Thierry',
      'Château-Salins',
      'Châteauroux',
      'Château-Renault',
      'Château-Renard',
      'Châteaurenard',
      'Château-Porcien',
      'Châteauponsac',
      'Châteauneuf-sur-Sarthe',
      'Châteauneuf-sur-Loire',
      'Châteauneuf-sur-Cher',
      'Châteauneuf-sur-Charente',
      'Châteauneuf-les-Martigues',
      'Châteauneuf-le-Rouge',
      'Châteauneuf-la-Forêt',
      'Châteauneuf-en-Thymerais',
      'Châteauneuf-du-Rhône',
      'Châteauneuf-du-Pape',
      'Châteauneuf-du-Faou',
      'Châteauneuf-sur-Isère',
      "Châteauneuf-d'Ille-et-Vilaine",
      'Châteauneuf-de-Galaure',
      'Châteauneuf-de-Gadagne',
      'Châteauneuf',
      'Châteauneuf-Grasse',
      'Châteaumeillant',
      'Châteaulin',
      'Château-la-Vallière',
      'Château-Landon',
      'Château-Guibert',
      'Château-Gontier',
      'Châteaugiron',
      'Châteaugay',
      'Château-Gaillard',
      'Châteaufort',
      'Châteaudun',
      'Château-du-Loir',
      "Château-d'Olonne",
      'Château-Chinon(Ville)',
      'Châteaubriant',
      'Châteaubourg',
      'Châteaubernard',
      'Chassors',
      'Chassieu',
      'Chasse-sur-Rhône',
      'Chasseneuil-du-Poitou',
      'Chasseneuil-sur-Bonnieure',
      'Chasselay',
      'Chassagny',
      'Chasné-sur-Illet',
      'Charvieu-Chavagneux',
      'Chartrettes',
      'Chartres-de-Bretagne',
      'Chartres',
      'Chars',
      'Charroux',
      'Charron',
      'Charquemont',
      'Chârost',
      'Charolles',
      'Charny',
      'Charnècles',
      'Charnay-lès-Mâcon',
      'Charnay',
      'Charmes-sur-Rhône',
      'Charmes',
      'Charly-sur-Marne',
      'Charly',
      'Charlieu',
      'Charleville-Mézières',
      'Charleval',
      'Chargé',
      'Charenton-le-Pont',
      'Charenton-du-Cher',
      'Charentilly',
      'Charentay',
      'Charbuy',
      'Charbonnières-les-Varennes',
      'Charbonnières-les-Bains',
      'Charavines',
      'Charantonnay',
      'Chaptelat',
      'Chappes',
      'Chaponost',
      'Chaponnay',
      'Chapet',
      'Chapdes-Beaufort',
      'Chapareillan',
      'Chaource',
      'Chanu',
      'Chantraine',
      'Chantonnay',
      'Chantôme',
      'Chantilly',
      'Chantepie',
      'Chantenay-Saint-Imbert',
      'Chanteloup-les-Vignes',
      'Chanteloup-en-Brie',
      'Chanteloup',
      'Chantelle',
      'Chanteheux',
      'Chanteau',
      'Chanos-Curson',
      'Chanonat',
      'Chaniers',
      'Changy-les-Bois',
      'Changis-sur-Marne',
      'Changé',
      'Chandon',
      'Chancenay',
      'Chancelade',
      'Chanceaux-sur-Choisille',
      'Chanas',
      'Chanac',
      'Champvans',
      'Champtocé-sur-Loire',
      'Champtoceaux',
      'Champ-sur-Drac',
      'Champs-sur-Tarentaine-Marchal',
      'Champs-sur-Marne',
      'Champsecret',
      'Champs-sur-Yonne',
      'Champniers',
      'Champlitte-la-Ville',
      'Champlitte',
      'Champlan',
      'Champigny',
      'Champigny-sur-Marne',
      'Champigneulles',
      'Champignelles',
      'Champigné',
      'Champier',
      'Champhol',
      'Champforgeuil',
      'Champfleur',
      'Champenoux',
      'Champeix',
      'Champdieu',
      'Champdeniers-Saint-Denis',
      'Champcueil',
      'Champcevinel',
      'Champagnole',
      'Champagnier',
      'Champagney',
      'Champagne-sur-Seine',
      'Champagne-sur-Oise',
      'Champagne-Mouton',
      'Champagné-les-Marais',
      "Champagne-au-Mont-d'Or",
      'Champagné',
      'Champagnac',
      'Chamonix-Mont-Blanc',
      'Chamigny',
      'Chameyrat',
      'Chambretaud',
      'Chambray-lès-Tours',
      'Chambourg-sur-Indre',
      'Chambourcy',
      'Chamboulive',
      'Chambon-sur-Voueize',
      'Chambœuf',
      'Chambly',
      'Chambéry',
      'Chamberet',
      'Chamarandes-Choignes',
      'Chamarande',
      'Chamant',
      'Chamalières',
      'Chamagnieu',
      'Châlus',
      'Chalo-Saint-Mars',
      'Châlonvillars',
      'Chalon-sur-Saône',
      'Châlons-en-Champagne',
      'Chalonnes-sur-Loire',
      'Challuy',
      'Challex',
      'Challes-les-Eaux',
      'Challes',
      'Challans',
      'Chalindrey',
      'Chaligny',
      'Chalifert',
      'Châlette-sur-Loing',
      'Chaleins',
      'Chalampé',
      'Chalamont',
      'Chalais',
      'Chalabre',
      'Chaingy',
      'Chailly-en-Brie',
      'Chailly-en-Bière',
      'Chaillevette',
      'Chailles',
      'Chaillé-les-Marais',
      'Chailland',
      'Chaillac',
      'Chagny',
      'Chadrac',
      'Chacé',
      'Chabris',
      'Chabreloche',
      'Châbons',
      'Chablis',
      'Chabeuil',
      'Chabanais',
      'Cézy',
      'Cézac',
      'Ceyzériat',
      'Ceyreste',
      'Ceyrat',
      'Ceton',
      'Cestas',
      'Cessy',
      'Cesson-Sévigné',
      'Cesson',
      'Cessieu',
      'Cessenon-sur-Orb',
      'Cervione',
      'Certines',
      'Cers',
      'Cérons',
      'Cerny',
      'Cernay-lès-Reims',
      'Cernay-la-Ville',
      'Cernay',
      'Cerizay',
      'Cerisy-la-Salle',
      'Cérilly',
      'Cergy',
      'Ceret',
      'Céreste',
      'Cérences',
      'Cerelles',
      'Cerdon',
      'Cercy-la-Tour',
      'Cercoux',
      'Cervera de la Marenda',
      'Cérans-Foulletourte',
      'Cepoy',
      'Cépet',
      'Cenon-sur-Vienne',
      'Cenon',
      'Cendras',
      'Cénac',
      'Cély',
      'Cellieu',
      'Cellettes',
      'Celles-sur-Durolle',
      'Celles-sur-Belle',
      'Celle-Lévescault',
      'Cébazat',
      'Ceaucé',
      'Cazouls-lès-Béziers',
      'Cazilhac',
      'Cazes-Mondenard',
      "Cazères-sur-l'Adour",
      'Cazères',
      'Cazaubon',
      'Caylus',
      'Cayeux-sur-Mer',
      'Cavignac',
      'Caveirac',
      'Cavan',
      'Cavalaire-sur-Mer',
      'Cavaillon',
      'Caux',
      'Cauville-sur-Mer',
      'Cauvigny',
      'Cauterets',
      'Caussade',
      'Cauro',
      'Caunes-Minervois',
      'Caumont-sur-Durance',
      "Caumont-l'Éventé",
      'Caumont',
      'Caulnes',
      'Cauffry',
      'Caudry',
      'Caudebec-lès-Elbeuf',
      'Caudebec-en-Caux',
      'Caudan',
      'Cauchy-à-la-Tour',
      'Cattenom',
      'Catenoy',
      'Castries',
      'Castres-Gironde',
      'Castres',
      'Castillonnès',
      'Castillon-la-Bataille',
      'Castillon-du-Gard',
      'Castets-en-Dorthe',
      'Castets',
      'Castelsarrasin',
      'Castelnau-le-Lez',
      "Castelnau-d'Estrétefonds",
      'Castelnau-Montratier',
      'Castelnau-de-Médoc',
      'Castelnau-de-Lévis',
      'Castelnau-de-Guers',
      'Castelnaudary',
      'Castelmoron-sur-Lot',
      'Castellane',
      'Casteljaloux',
      'Castelginest',
      'Castelculier',
      'Castanet-Tolosan',
      'Castagniers',
      'Cast',
      'Casson',
      'Cassis',
      'Casseneuil',
      'Cassel',
      'Cassagnes-Bégonhès',
      'Carvin',
      'Cartignies',
      'Carspach',
      'Carsac-Aillac',
      'Cars',
      'Carry-le-Rouet',
      'Carros',
      'Carrières-sur-Seine',
      'Carrières-sous-Poissy',
      'Carqueiranne',
      'Carquefou',
      'Carpiquet',
      'Carpentras',
      'Caromb',
      'Caro',
      'Carnoules',
      'Carnac',
      'Carmaux',
      'Carling',
      'Carlepont',
      'Carignan',
      'Carignan-de-Bordeaux',
      'Carhaix-Plouguer',
      'Cargèse',
      'Carentoir',
      'Carentan',
      'Carcès',
      'Carcassonne',
      'Carcans',
      'Carbonne',
      'Carbon-Blanc',
      'Carantec',
      'Caraman',
      'Capvern',
      'Captieux',
      'Cappelle-la-Grande',
      'Cappelle-en-Pévèle',
      'Capinghem',
      'Capestang',
      'Capendu',
      'Capdenac-Gare',
      "Cap-d'Ail",
      'Capbreton',
      'Cany-Barville',
      'Cantin',
      'Cantenay-Épinard',
      'Cantenac',
      'Canteleu',
      'Cantaron',
      'Canon',
      'Canohès',
      'Cannes-Écluse',
      'Cannes',
      'Cangey',
      'Canet',
      'Canet-en-Roussillon',
      'Canéjan',
      'Candillargues',
      'Candé-sur-Beuvron',
      'Candé',
      'Cancon',
      'Cancale',
      'Camps-la-Source',
      'Campsas',
      'Camphin-en-Pévèle',
      'Camphin-en-Carembault',
      'Campénéac',
      'Campbon',
      'Campan',
      'Campagne-lès-Hesdin',
      'Camors',
      'Camon',
      'Camiers',
      'Cambronne-lès-Ribécourt',
      'Cambronne-lès-Clermont',
      'Cambremer',
      'Cambrai',
      'Cambo-les-Bains',
      'Camblanes',
      'Camblain-Châtelain',
      'Cambes-en-Plaine',
      'Cambes',
      'Camaret-sur-Mer',
      'Camaret-sur-Aigues',
      'Camarès',
      'Calvisson',
      'Calvi',
      'Caluire-et-Cuire',
      'Calonne-sur-la-Lys',
      'Calonne-Ricouart',
      'Calmont',
      'Callian',
      'Callas',
      'Callac',
      'Calenzana',
      'Calais',
      'Cajarc',
      'Caissargues',
      'Cairon',
      'Cailloux-sur-Fontaines',
      'Cahuzac-sur-Vère',
      'Cahors',
      'Cahagnes',
      'Cagny',
      'Cagnes-sur-Mer',
      'Cagnac-les-Mines',
      'Caëstre',
      'Caen',
      'Cadolive',
      'Cadillac',
      'Caderousse',
      'Cadenet',
      'Caden',
      'Cadaujac',
      'Cadalen',
      'Cachan',
      'Cabris',
      'Cabriès',
      'Cabrières',
      'Cabourg',
      'Cabestany',
      'Cabasse',
      'Cabariot',
      'Cabannes',
      'Cabanac-et-Villagrains',
      'Buzet-sur-Tarn',
      'Buzet-sur-Baïse',
      'Buzançais',
      'Buxy',
      'Buxières-les-Mines',
      'Buxerolles',
      'Butry-sur-Oise',
      'Bussy-Saint-Georges',
      'Bussières',
      'Bussière-Poitevine',
      'Bussière-Galant',
      'Bussière-Dunoise',
      'Bussang',
      'Bussac-sur-Charente',
      'Busnes',
      'Busigny',
      'Bury',
      'Buros',
      'Burnhaupt-le-Haut',
      'Burnhaupt-le-Bas',
      'Burlats',
      'Burie',
      'Bures-sur-Yvette',
      'Burbure',
      'Bully-les-Mines',
      'Bully',
      'Bullion',
      'Bulgnéville',
      'Buis-les-Baronnies',
      'Buironfosse',
      'Buhl',
      'Bugeat',
      'Buellas',
      'Bueil',
      'Bucy-le-Long',
      'Bucquoy',
      'Buchy',
      'Buchères',
      'Buchelay',
      'Buc',
      'Bubry',
      'Bû',
      'Bry-sur-Marne',
      'Bruz',
      'Bruyères-sur-Oise',
      'Bruyères-le-Châtel',
      'Bruyères-et-Montbérault',
      'Bruyères',
      'Brunstatt',
      'Brunoy',
      'Brumath',
      'Brûlon',
      'Bruille-Saint-Amand',
      'Bruille-lez-Marchiennes',
      'Bruguières',
      'Brugheas',
      'Bruges',
      "Bruay-sur-l'Escaut",
      'Bruay-la-Buissière',
      'Broût-Vernet',
      'Brou-sur-Chantereine',
      'Brouckerque',
      'Brou',
      'Broons',
      'Bron',
      'Broglie',
      'Brix',
      'Brives-Charensac',
      'Brive-la-Gaillarde',
      'Brissac-Quincé',
      'Brison-Saint-Innocent',
      'Briscous',
      'Briouze',
      'Brioux-sur-Boutonne',
      'Brioude',
      'Brionne',
      'Brion',
      'Briollay',
      'Brinon-sur-Sauldre',
      'Brindas',
      'Briis-sous-Forges',
      'Brigueuil',
      'Brignoles',
      'Brignais',
      'Briey',
      'Brienon-sur-Armançon',
      'Briennon',
      'Brienne-le-Château',
      'Brié-et-Angonnes',
      'Brie-Comte-Robert',
      'Briec',
      'Brie',
      'Bricy',
      'Bricquebec',
      'Briatexte',
      'Briare',
      'Briançon',
      'Brezolles',
      'Brézins',
      'Brézé',
      'Bréviandes',
      'Bréval',
      'Breuschwickersheim',
      'Breuilpont',
      'Breuil-Magné',
      'Breuil-le-Vert',
      'Breuillet',
      'Breuil-le-Sec',
      'Bretteville-sur-Odon',
      'Bretteville-sur-Laize',
      "Bretteville-l'Orgueilleuse",
      'Bretteville-du-Grand-Caux',
      'Bretteville',
      'Brette-les-Pins',
      'Bretoncelles',
      'Brétigny-sur-Orge',
      'Bretignolles-sur-Mer',
      'Breteuil',
      'Bretenoux',
      'Breteil',
      'Brest',
      'Bressuire',
      'Bressols',
      'Bresles',
      'Brens',
      'Brenouille',
      'Brêmes',
      'La Crèche',
      'Breil-sur-Roya',
      'Bréhand',
      'Bréhal',
      'Brech',
      'Brécey',
      'Brécé',
      'Brebières',
      'Bréauté',
      'Bréal-sous-Montfort',
      'Brazey-en-Plaine',
      'Bray-sur-Somme',
      'Bray-sur-Seine',
      'Bray-en-Val',
      'Bray-Dunes',
      'Brax',
      'Braud-et-Saint-Louis',
      'Brassac-les-Mines',
      'Brassac',
      'Brasparts',
      'Brasles',
      'Bras',
      'Brantôme',
      'Branoux-les-Taillades',
      'Branne',
      'Branges',
      'Brando',
      'Brandérion',
      'Bram',
      'Brain-sur-Allonnes',
      'Brains',
      'Braine',
      'Bracieux',
      'Bozouls',
      'Bozel',
      'Boynes',
      'Boyard-Ville',
      'Boves',
      'Bouzy-la-Forêt',
      'Bouzonville',
      'Bouzillé',
      'Bouzigues',
      'Bouxwiller',
      'Bouxières-aux-Dames',
      'Bouxières-aux-Chênes',
      'Bouvron',
      'Bouville',
      'Bouvigny-Boyeffles',
      'Bouvignies',
      'Bouvesse-Quirieu',
      'Bouttencourt',
      'Boutigny-sur-Essonne',
      'Boutiers-Saint-Trojan',
      'Bout-du-Pont-de-Larn',
      'Boussy-Saint-Antoine',
      'Boussois',
      'Boussières',
      'Bousse',
      'Boussay',
      'Boussac',
      'Bousies',
      'Bousbecque',
      'Bourth',
      'Bourron-Marlotte',
      'Bourogne',
      'Bournoncle-Saint-Pierre',
      'Bournezeau',
      'Bourlon',
      'Bourgueil',
      'Bourguébus',
      'Bourgtheroulde-Infreville',
      'Bourg',
      'Bourg-Saint-Maurice',
      'Bourg-Saint-Andéol',
      'Bourgogne',
      'Bourgneuf-en-Retz',
      'Bourgneuf',
      "la Guingueta d'Ix",
      'Bourg-lès-Valence',
      'Bourg-la-Reine',
      'Bourghelles',
      'Bourges',
      'Bourg-en-Bresse',
      'Bourg-de-Thizy',
      'Bourg-des-Comptes',
      'Bourg-de-Péage',
      'Bourg-Blanc',
      'Bourgbarré',
      'Bourg-Argental',
      'Bourganeuf',
      'Bourg-Achard',
      'Bourcefranc-le-Chapus',
      'Bourbriac',
      'Bourbourg',
      'Bourbonne-les-Bains',
      "Bourbon-l'Archambault",
      'Bourbon-Lancy',
      'Bouray-sur-Juine',
      'Boult-sur-Suippe',
      'Bouloire',
      'Boulogne-sur-Mer',
      'Boulogne-sur-Gesse',
      'Boulogne-Billancourt',
      'Bouloc',
      'Boulleret',
      'Bouligny',
      'Boulieu-lès-Annonay',
      'Bouliac',
      'Bouleurs',
      'Boulbon',
      'Boulazac',
      'Boulay-Moselle',
      'Boulange',
      'Boujan-sur-Libron',
      'Bouin',
      'Bouilly',
      'Bouillé-Loretz',
      'Bouillargues',
      'Bouguenais',
      'Bougival',
      'Boufféré',
      'Bouffémont',
      'Boué',
      'Bouchemaine',
      'Bouchain',
      'Bouc-Bel-Air',
      'Boucau',
      'Bouaye',
      'Bouafle',
      "Bosmie-l'Aiguille",
      'Bosdarros',
      'Bosc-le-Hard',
      'Bort-les-Orgues',
      'Bornel',
      'Bormes-les-Mimosas',
      'Borgo',
      'Bords',
      'Bordes',
      'Bordeaux',
      'Boran-sur-Oise',
      'Boos',
      'Boofzheim',
      'Bonson',
      'Bons-en-Chablais',
      'Bonny-sur-Loire',
      'Bonnieux',
      'Bonnières-sur-Seine',
      'Bonneville',
      'Bonneveine',
      'Bonneval',
      'Bonneuil-sur-Marne',
      'Bonneuil-Matours',
      'Bonnétable',
      'Bonne',
      'Bonnes',
      'Bonnemain',
      'Bonnelles',
      'Bonnefamille',
      'Bonnat',
      'Bonnac-la-Côte',
      'Bonifacio',
      'Bon-Encontre',
      'Bondy',
      'Bondues',
      'Bondoufle',
      'Bonchamp-lès-Laval',
      'Bompas',
      'Bologne',
      'Bollwiller',
      'Bollezeele',
      'Bollène',
      'Bolbec',
      'Boissy-sous-Saint-Yon',
      'Boissy-Saint-Léger',
      'Boissy-le-Cutté',
      'Boissy-le-Châtel',
      'Boissise-le-Roi',
      'Boisseuil',
      'Boisset',
      'Boisseron',
      'Boismé',
      'Bois-le-Roi',
      'Bois-Guillaume',
      'Bois-Grenier',
      'Boisgervilly',
      'Bois-de-Céné',
      "Bois-d'Arcy",
      "Bois-d'Amont",
      'Boiscommun',
      'Bois-Colombes',
      'Boigny-sur-Bionne',
      'Bohars',
      'Bohain-en-Vermandois',
      'Boeschepe',
      'Bœrsch',
      'Boën-sur-Lignon',
      'Boeil-Bezing',
      'Boëge',
      'Boé',
      'Bodilis',
      'Bobigny',
      'Blotzheim',
      'Blonville-sur-Mer',
      'Blois',
      'Blodelsheim',
      'Bligny-lès-Beaune',
      'Bliesbruck',
      'Bletterans',
      'Bléré',
      'Blérancourt',
      'Blénod-lès-Toul',
      'Blénod-lès-Pont-à-Mousson',
      'Bléneau',
      'Blendecques',
      'Blaye-les-Mines',
      'Blavozy',
      'Blauzac',
      'Blausasc',
      'Blaringhem',
      'Blanzy',
      'Blanzat',
      'Blanquefort',
      'Blangy-sur-Bresle',
      'Blancafort',
      'Blamont',
      'Blaison-Gohier',
      'Blainville-sur-Orne',
      'Blainville-sur-Mer',
      'Blainville-Crevon',
      'Blaincourt-lès-Précy',
      'Blain',
      'Blagny',
      'Blagnac',
      'Blaesheim',
      'Blacé',
      'Bize-Minervois',
      'Bizanos',
      'Bizanet',
      'Biviers',
      'Bitschwiller-lès-Thann',
      'Bitche',
      'Bischwiller',
      'Bischoffsheim',
      'Bischheim',
      'Biscarrosse',
      'Biot',
      'Bining',
      'Binic',
      'Billy-sur-Aisne',
      'Billy-Montigny',
      'Billy-Berclau',
      'Billom',
      'Billère',
      'Bilieu',
      'Bihorel',
      'Biguglia',
      'Bignoux',
      'Bignan',
      'Biganos',
      'Bièvres',
      'Biéville-Beuville',
      'Biesles',
      'Biesheim',
      'Bierne',
      'Bienville',
      'Bidart',
      'Bidache',
      'Bias',
      'Biars-sur-Cère',
      'Biarritz',
      'Biard',
      'Biache-Saint-Vaast',
      'Bézu-Saint-Éloi',
      'Bezouce',
      'Bezons',
      'Béziers',
      'Bezannes',
      'Beynost',
      'Beynes',
      'Beynat',
      'Beychac',
      'Béville-le-Comte',
      'Beuzeville-la-Grenier',
      'Beuzeville',
      'Beuzec-Cap-Sizun',
      'Beuvry',
      'Beuvrages',
      'Beuvillers',
      'Beuville',
      'Beure',
      'Betz',
      'Betton',
      'Bettancourt-la-Ferrée',
      'Béthune',
      'Bethoncourt',
      'Béthisy-Saint-Pierre',
      'Béthisy-Saint-Martin',
      'Bétheny',
      'Béthencourt-sur-Mer',
      'Bessines-sur-Gartempe',
      'Bessines',
      'Bessières',
      'Besse-sur-Issole',
      'Bessé-sur-Braye',
      'Bessenay',
      'Bessèges',
      'Besse-et-Saint-Anastaise',
      'Bessay-sur-Allier',
      'Bessancourt',
      'Bessan',
      'Besné',
      'Besançon',
      'Béruges',
      'Bertry',
      'Bertrange',
      'Berthecourt',
      'Berteaucourt-les-Dames',
      'Berstett',
      'Berson',
      'Bersée',
      'Berrwiller',
      'Berrien',
      'Berric',
      "Berre-l'Étang",
      'Berre-les-Alpes',
      'Bernis',
      'Bernin',
      'Bernières-sur-Mer',
      'Berneval-le-Grand',
      'Bernes-sur-Oise',
      'Berné',
      'Bernay',
      'Bernaville',
      'Bernardswiller',
      'Berlaimont',
      'Bergues',
      'Bergholtz',
      'Bergheim',
      'Bergerac',
      'Berck',
      'Berck-Plage',
      'Bérat',
      'Benquet',
      'Bénouville',
      'Bénodet',
      'Bennwihr',
      'Bennecourt',
      'Béning-lès-Saint-Avold',
      'Benfeld',
      'Benet',
      'Bénesse-Maremne',
      'Bénéjacq',
      'Belz',
      'Belvès',
      'Belpech',
      'Belmont-sur-Rance',
      'Belmont-de-la-Loire',
      'Belloy-en-France',
      'Belligné',
      'Bellignat',
      'Belley',
      'Belleville-sur-Vie',
      'Belleville',
      'Belleville-sur-Meuse',
      'Belleville-sur-Loire',
      'Bellevaux',
      'Belleu',
      'Bellerive-sur-Allier',
      'Bellengreville',
      'Belleneuve',
      'Bellenaves',
      'Bellême',
      'Belle-Isle-en-Terre',
      'Bellegarde-sur-Valserine',
      'Bellegarde-en-Forez',
      'Bellegarde',
      'Bellaing',
      'Bellac',
      'Belin-Béliet',
      'Béligneux',
      'Belgentier',
      'Belfort',
      'Bélesta',
      'Belcodène',
      'Belbeuf',
      'Belberaud',
      'Bélâbre',
      'Beignon',
      'Behren-lès-Forbach',
      'Bégrolles-en-Mauges',
      'Bègles',
      'Bégard',
      'Béganne',
      'Bégaar',
      'Bédoin',
      'Bédée',
      'Bédarrides',
      'Bédarieux',
      'Bécon-les-Granits',
      'Beauzelle',
      'Beauzac',
      'Beauvoisin',
      'Beauvois-en-Cambrésis',
      'Beauvoir-sur-Niort',
      'Beauvoir-sur-Mer',
      'Beauvoir-de-Marc',
      'Beauvallon',
      'Beauval',
      'Beauvais',
      'Beautor',
      'Beautiran',
      'Beausoleil',
      'Beausemblant',
      'Beaurevoir',
      'Beaurepaire',
      'Beaurainville',
      'Beaurains',
      'Beauquesne',
      'Beaupuy',
      'Beaupréau',
      'Beaune-la-Rolande',
      'Beaune',
      'Beaumont-sur-Sarthe',
      'Beaumont-sur-Oise',
      'Beaumont-sur-Lèze',
      'Beaumont-Monteux',
      'Beaumont-lès-Valence',
      'Beaumont-le-Roger',
      'Beaumont-la-Ronce',
      'Beaumont-Hague',
      'Beaumont-en-Véron',
      'Beaumont-du-Gâtinais',
      'Beaumont-du-Périgord',
      'Beaumont-de-Pertuis',
      'Beaumont-de-Lomagne',
      'Beaumont',
      'Beaumetz-lès-Loges',
      'Beaumes-de-Venise',
      'Beaulon',
      'Beaulieu-sur-Mer',
      'Beaulieu-sur-Layon',
      'Beaulieu-sur-Dordogne',
      'Beaulieu-sous-la-Roche',
      'Beaulieu-lès-Loches',
      'Beaulieu',
      'Beaujeu',
      'Beaugency',
      'Beaufort-en-Vallée',
      'Beaufort',
      'Beaufay',
      'Beaucroissant',
      'Beaucouzé',
      'Beaucourt',
      'Beauchastel',
      'Beauchamps',
      'Beauchamp',
      'Beaucé',
      'Beaucamps-le-Vieux',
      'Beaucaire',
      'Bazouges-sur-le-Loir',
      'Bazouges-la-Pérouse',
      'Bazoges-en-Pareds',
      'Bazoches-les-Gallerandes',
      'Baziège',
      'Bazet',
      'Bazemont',
      'Bazeilles',
      'Bazas',
      'Bazancourt',
      'Bazainville',
      'Bayonne',
      'Bayon',
      'Bayeux',
      'Bavilliers',
      'Bavent',
      'Bavay',
      'Bavans',
      'Bauvin',
      'Bauné',
      'Baume-les-Dames',
      'Baulon',
      'Baulne',
      'Baule',
      'Baugy',
      'Baugé-en-Anjou',
      'Baud',
      'Batz-sur-Mer',
      'Battenheim',
      'Batilly',
      'Bastia',
      'Bassussarry',
      'Bassillac',
      'Bassens',
      'Indre',
      'Basse-Ham',
      'Basse-Goulaine',
      'Bassan',
      'Bas-en-Basset',
      'Bartenheim',
      'Bart',
      'Bar-sur-Seine',
      'Bar-sur-Aube',
      'Barsac',
      'Barraux',
      'Barr',
      'Barneville-Carteret',
      'Barneville-Plage',
      'Barlin',
      'Bar-le-Duc',
      'Barjouville',
      'Barjols',
      'Barjac',
      'Bargemon',
      'Barenton',
      'Barentin',
      'Bardos',
      'Barcelonnette',
      'Barcelonne-du-Gers',
      'Barby',
      'Barbizon',
      'Barbezieux-Saint-Hilaire',
      'Barberaz',
      'Barbentane',
      'Barbechat',
      'Barbazan-Debat',
      'Barbâtre',
      'Barbaste',
      'Baraqueville',
      'Bapaume',
      'Banyuls de la Marenda',
      'Banyuls-dels-Aspres',
      'Bantzenheim',
      'Bannalec',
      'Bandol',
      'Ban-de-Laveline',
      'Balzac',
      'Balma',
      'Ballots',
      'Ballon',
      'Ballan-Miré',
      'Ballancourt-sur-Essonne',
      'Ballaison',
      'Ballainvilliers',
      'Baldersheim',
      'Balbigny',
      'Balazé',
      'Balaruc-le-Vieux',
      'Balaruc-les-Bains',
      'Balan',
      'Balagny-sur-Thérain',
      'Baixas',
      'Baisieux',
      'Bais',
      'Bainville-sur-Madon',
      'Bains-sur-Oust',
      'Bains-les-Bains',
      'Bains',
      'Bain-de-Bretagne',
      'Baincthun',
      'Bailly-Romainvilliers',
      'Bailly-Carrois',
      'Bailly',
      'Bailleval',
      'Bailleul-sur-Thérain',
      'Bailleul-Sir-Berthoult',
      'Bailleul',
      'Baillet-en-France',
      "Bailleau-l'Évêque",
      'Bailleau-le-Pin',
      'Baillargues',
      'Baignes-Sainte-Radegonde',
      'Baho',
      'Baguer-Pican',
      'Baguer-Morvan',
      'Bagnols-sur-Cèze',
      'Bagnols-en-Forêt',
      'Bagnolet',
      "Bagnoles-de-l'Orne",
      'Bagneux',
      'Bagnères-de-Luchon',
      'Bagnères-de-Bigorre',
      'Bagneaux-sur-Loing',
      'Bagnac-sur-Célé',
      'Bages',
      'Bâgé-la-Ville',
      'Bagard',
      'Badonviller',
      'Baden',
      'Bacqueville-en-Caux',
      'Bachy',
      'Bachant',
      'Baccarat',
      'Azille',
      'Azé',
      'Azay-sur-Cher',
      'Azay-le-Rideau',
      'Azay-le-Ferron',
      'Azay-le-Brûlé',
      'Aytré',
      'Ay-sur-Moselle',
      'Ayse',
      'Ayron',
      'Ayguesvives',
      'Ayguemorte-les-Graves',
      'Aydoilles',
      'Aydat',
      'Ay',
      'Ax-les-Thermes',
      'Avrillé',
      'Avrechy',
      'Avranches',
      'Avord',
      'Avon',
      'Avoine',
      'Avize',
      'Aviron',
      'Avion',
      'Avilly-Saint-Léonard',
      'Avignonet-Lauragais',
      'Avignon',
      'Avèze',
      'Avessac',
      'Avesnes-sur-Helpe',
      'Avesnes-le-Sec',
      'Avesnes-les-Aubert',
      'Avesnes-le-Comte',
      'Avesnelles',
      'Avermes',
      'Avensan',
      'Avelin',
      'Aveizieux',
      'Aveize',
      'Avanton',
      'Avanne-Aveney',
      'Avallon',
      'Availles-Limouzine',
      'Auzouer-en-Touraine',
      'Auzielle',
      'Auzeville-Tolosane',
      'Auzebosc',
      'Auzat-la-Combelle',
      'Auzances',
      'Auxy',
      'Auxonne',
      'Auxon-Dessous',
      'Auxi-le-Château',
      'Auxerre',
      'Auvillar',
      'Auvers-sur-Oise',
      'Auvers-Saint-Georges',
      'Auvers-le-Hamon',
      'Autun',
      'Autry-le-Châtel',
      'Autrans',
      'Authon-du-Perche',
      'Authie',
      'Auterive',
      'Aussonne',
      'Aussillon',
      'Auriol',
      'Aurillac',
      'Aurignac',
      'Auribeau-sur-Siagne',
      'Auriac-sur-Vendinelle',
      'Aureille',
      'Aureilhan',
      'Aurec-sur-Loire',
      'Auray',
      'Aups',
      'Auneuil',
      'Auneau',
      'Aunay-sur-Odon',
      'Aunay-sous-Auneau',
      'Aumont-Aubrac',
      'Aumetz',
      'Aumale',
      'Ault',
      'Aulnoye-Aymeries',
      'Aulnoy-lez-Valenciennes',
      'Aulnois-sous-Laon',
      'Aulnay-sur-Mauldre',
      'Aulnay-sous-Bois',
      'Aulnay',
      'Aulnat',
      'Augy',
      'Augny',
      'Augan',
      'Auffay',
      'Auffargis',
      'Audun-le-Tiche',
      'Audun-le-Roman',
      'Audruicq',
      'Audincourt',
      'Audierne',
      'Audenge',
      'Auchy-les-Mines',
      'Auchy-lès-Hesdin',
      'Auchel',
      'Auch',
      'Aucamville',
      'Auby',
      'Aubusson',
      'Aubry-du-Hainaut',
      'Auboué',
      'Aubord',
      'Aubin',
      'Aubigny-sur-Nère',
      'Aubigny-au-Bac',
      'Aubigny-en-Artois',
      'Aubigny',
      'Aubigné-Racan',
      'Aubignan',
      'Aubiet',
      'Aubière',
      'Aubie',
      'Aubevoye',
      'Aube-sur-Rîle',
      'Aubervilliers',
      'Aubers',
      'Auberives-sur-Varèze',
      'Aubergenville',
      'Auberchicourt',
      'Aubenas',
      'Aubais',
      'Aubagne',
      'Atur',
      'Attigny',
      'Attignat',
      'Attichy',
      'Attiches',
      'Attainville',
      'Athis-Mons',
      "Athis-de-l'Orne",
      'Athies-sous-Laon',
      'Athée-sur-Cher',
      'Astaffort',
      'Asson',
      'Assérac',
      'Assat',
      'Assas',
      'Aspremont',
      'Aspiran',
      'Aspet',
      'Aspach-le-Haut',
      'Aspach-le-Bas',
      'Aspach',
      'Asnières-sur-Oise',
      'Asnières-sur-Nouère',
      'Asnières-sur-Seine',
      'Aschères-le-Marché',
      'Ascain',
      'Arzon',
      'Arzens',
      'Arzano',
      'Arveyres',
      'Arvert',
      'Arudy',
      'Art-sur-Meurthe',
      'Artres',
      'Artix',
      'Artigues-près-Bordeaux',
      'Artiguelouve',
      'Arthon-en-Retz',
      'Arthon',
      'Arthez-de-Béarn',
      'Arthès',
      'Arthaz-Pont-Notre-Dame',
      'Artenay',
      'Artemare',
      'Artas',
      'Artannes-sur-Indre',
      'Ars-sur-Moselle',
      'Ars-sur-Formans',
      'Ars-en-Ré',
      'Arsac',
      'Arrou',
      'Arras',
      'Arradon',
      'Arques-la-Bataille',
      'Arques',
      'Arpajon-sur-Cère',
      'Arpajon',
      'Aron',
      'Arnouville',
      'Arnières-sur-Iton',
      'Arnèke',
      'Arnay-le-Duc',
      'Arnas',
      'Arnage',
      'Arnac-Pompadour',
      'Arnac-la-Poste',
      'Armoy',
      'Armissan',
      'Armentières-en-Brie',
      'Armentières',
      'Armenonville-les-Gâtineaux',
      'Armbouts-Cappel',
      'Arleux',
      'Arles',
      'Arlanc',
      'Arinthod',
      'Argent-sur-Sauldre',
      'Argentré-du-Plessis',
      'Argentré',
      'Argenton-sur-Creuse',
      'Argenton-les-Vallées',
      'Argenteuil',
      'Argentat',
      'Argentan',
      'Argences',
      'Argeliers',
      'Argeles',
      'Argelès-Gazost',
      'Argancy',
      'Arette',
      'Arès',
      'Arenthon',
      'Arenc',
      'Ardres',
      'Ardin',
      'Ardentes',
      'Arcueil',
      'Arc-sur-Tille',
      'Arçonnay',
      'Arcis-sur-Aube',
      'Archigny',
      'Archettes',
      'Arches',
      'Archamps',
      'Arcey',
      'Arc-et-Senans',
      'Arcangues',
      'Arcachon',
      'Arc-lès-Gray',
      'Arbus',
      'Arbouans',
      'Arbonne-la-Forêt',
      'Arbonne',
      'Arbois',
      'Arbent',
      'Aramon',
      'Arâches-la-Frasse',
      'Apt',
      'Apremont',
      'Apprieu',
      'Appoigny',
      'Appietto',
      'Aouste-sur-Sye',
      'Aoste',
      'Anzin-Saint-Aubin',
      'Anzin',
      'Antran',
      'Antrain',
      'Antony',
      'Antonne',
      'Antigny',
      'Antibes',
      'Anthy-sur-Léman',
      'Anstaing',
      'Ansouis',
      'Anse',
      'Ansauvillers',
      'Anould',
      'Anor',
      'Annot',
      'Annonay',
      'Annoeullin',
      'Annezin',
      'Anneyron',
      'Annet-sur-Marne',
      'Annesse-et-Beaulieu',
      'Annequin',
      'Annemasse',
      'Annecy-le-Vieux',
      'Annecy',
      'Annay',
      'Anizy-le-Château',
      'Aniche',
      'Aniane',
      'Anhiers',
      'Angy',
      'Angresse',
      'Angres',
      'Angoulins',
      'Angoulême',
      'Anglet',
      'Angles',
      'Angicourt',
      'Angevillers',
      'Angervilliers',
      "Angerville-l'Orcher",
      'Angerville',
      'Angers',
      'Anetz',
      'Anet',
      'Anduze',
      'Andrézieux-Bouthéon',
      'Andrezé',
      'Andrésy',
      'Andrest',
      'Andres',
      'Andouillé',
      'Andolsheim',
      'Andlau',
      'Andilly',
      'Andeville',
      'Andernos-les-Bains',
      'Andelnans',
      'Andé',
      'Andard',
      'Andancette',
      'Andance',
      'Ancy-sur-Moselle',
      'Ancy-le-Franc',
      'Ancône',
      'Ancerville',
      'Ancenis',
      'Ampuis',
      'Amplepuis',
      'Amou',
      'Amnéville',
      'Ammerschwihr',
      'Amilly',
      'Amiens',
      'Amfreville-la-Mi-Voie',
      'Amfreville',
      "els Banys d'Arles",
      'Ambronay',
      'Ambrières-les-Vallées',
      'Ambon',
      'Amboise',
      'Ambleteuse',
      'Ambleny',
      'Amblainville',
      'Ambillou',
      'Ambierle',
      'Ambès',
      'Ambert',
      'Ambérieux-en-Dombes',
      'Ambérieu-en-Bugey',
      'Ambazac',
      'Ambarès-et-Lagrave',
      'Amanvillers',
      'Amanlis',
      'Amancy',
      'Alzonne',
      'Altorf',
      'Altkirch',
      'Althen-des-Paluds',
      'Alsting',
      'Allouagne',
      'Allonzier-la-Caille',
      'Allonnes',
      'Allonne',
      'Allinges',
      'Allières-et-Risset',
      'Allex',
      'Allevard',
      'Allennes-les-Marais',
      'Alleins',
      'Allègre',
      'Allauch',
      'Allassac',
      'Allanche',
      'Allan',
      'Allaire',
      'Alizay',
      'Alixan',
      'Alissas',
      'Alignan-du-Vent',
      'Algrange',
      'Algolsheim',
      'Alfortville',
      'Alès',
      'Aléria',
      'Alénya',
      'Alençon',
      'Alby-sur-Chéran',
      'Albitreccia',
      'Albigny-sur-Saône',
      'Albias',
      'Albi',
      'Albertville',
      'Albert',
      'Albens',
      'Alba-la-Romaine',
      'Alata',
      'Ajain',
      'Ajaccio',
      'Aizenay',
      'Aix-Noulette',
      'Aix-les-Bains',
      'Aixe-sur-Vienne',
      'Aix-en-Provence',
      'Aix-en-Othe',
      'Aiton',
      'Aiserey',
      'Airvault',
      'Aire-sur-la-Lys',
      'Airaines',
      'Ainay-le-Château',
      'Aime',
      'Aimargues',
      'Ailly-sur-Somme',
      'Ailly-sur-Noye',
      'Aillevillers-et-Lyaumont',
      'Aillant-sur-Tholon',
      'Aigurande',
      'Aiguillon',
      'Aigues-Vives',
      'Aigues-Mortes',
      'Aigueperse',
      'Aiguefonde',
      'Aigueblanche',
      'Aigrefeuille-sur-Maine',
      'Aigre',
      'Aigné',
      'Aiglemont',
      'Aiffres',
      'Ahuy',
      'Ahun',
      'Ahuillé',
      'Ahetze',
      'Agon-Coutainville',
      'Agonac',
      'Agny',
      'Agnetz',
      'Agneaux',
      'Agen',
      'Agde',
      'Afa',
      'Acquigny',
      'Acigné',
      'Achiet-le-Grand',
      'Achicourt',
      'Achères-la-Forêt',
      'Achères',
      'Achenheim',
      'Abzac',
      'Abscon',
      'Abrest',
      'Abreschviller',
      'Abondant',
      'Abondance',
      'Ablon-sur-Seine',
      'Ablon',
      'Ablis',
      'Ableiges',
      'Ablain-Saint-Nazaire',
      'Abilly',
      'Abeilhan',
      'Abbeville',
      'Abbaretz',
      "Lugon-et-l'Île-du-Carnay",
      'Guidel-Plage',
      'Les Deux Alpes',
      "Villeneuve-d'Ascq",
      'Lyon 02',
      'Lyon 03',
      'Lyon 04',
      'Lyon 05',
      'Lyon 06',
      'Lyon 07',
      'Lyon 08',
      'Lyon 09',
      'Val Thorens',
      'La Palme',
      "Pont-d'Ouilly",
      'Castelmaurou',
      'Geneston',
      'Le Ban Saint-Martin',
      'Le Sequestre',
      'Les Ulis',
      'Carnoux-en-Provence',
      'Saint-Manvieu-Norrey',
      'Bourgoin-Jallieu',
      "Le Bois-d'Oingt",
      "Les Côtes-d'Arey",
      'Mercurey',
      'Val-de-Reuil',
      'Biol',
      'Bayard-sur-Marne',
      'Belle-Plagne',
      'Lyon 01',
      'Marseille 01',
      'Marseille 02',
      'Marseille 03',
      'Marseille 04',
      'Marseille 05',
      'Marseille 06',
      'Marseille 07',
      'Marseille 08',
      'Marseille 10',
      'Marseille 09',
      'Marseille 11',
      'Marseille 12',
      'Marseille 13',
      'Marseille 14',
      'Marseille 15',
      'Marseille 16',
      'Saint-Mitre',
      'La Defense',
      'Saint-Quentin-en-Yvelines',
      'Cergy-Pontoise',
      'Saint-Giniez',
      'La Page',
      'Rouet',
      'Marseille Endoume',
      'Marseille Saint-Victor',
      'Marseille Bompard',
      'Marseille Roucas-Blanc',
      'Marseille Vauban',
      'Marseille Prefecture',
      'Belsunce',
      'Le Chapitre',
      'Saint-Charles',
      'Opéra',
      'Les Grands Carmes',
      'La Joliette',
      'Saint-Lazare',
      'Saint-Mauront',
      'La Villette',
      'Belle de Mai',
      'Les Chartreux',
      'Chutes-Lavie',
      'Cinq Avenues',
      'La Blancarde',
      'Le Camas',
      'La Conception',
      'Baille',
      'Lodi',
      'Notre-Dame du Mont',
      'Palais de Justice',
      'Le Pharo',
      'Saint-Lambert',
      'La Pointe Rouge',
      'Périer',
      'Le Cabot',
      'La Timone',
      'La Capelette',
      'Menpenti',
      'Saint-Tronc',
      'La Valbarelle',
      'Les Accates',
      'Montolivet',
      'Saint-Jean du Désert',
      'Les Caillols',
      'Les Trois-Lucs',
      'La Fourragère',
      'Les Médecins',
      'La Rose',
      'Malpassé',
      'Palama',
      'Saint-Barthélémy',
      'Bon-Secours',
      'Les Arnavaux',
      'Les Crottes',
      'La Delorme',
      'La Viste',
      'Notre-Dame Limite',
      'Verduron',
      'La Cabucelle',
      'Borel',
      'La Calade',
      'Saint-Pierre',
    ],
  },
  {
    country: 'Gabon',
    cities: [
      'Tchibanga',
      'Port-Gentil',
      'Oyem',
      'Omboué',
      'Okondja',
      'Ntoum',
      'Ndendé',
      'Ndjolé',
      'Mounana',
      'Mouila',
      'Moanda',
      'Mitzic',
      'Mimongo',
      'Mbigou',
      'Mayumba',
      'Makokou',
      'Libreville',
      'Lékoni',
      'Lastoursville',
      'Lambaréné',
      'Koulamoutou',
      'Gamba',
      'Franceville',
      'Fougamou',
      'Cocobeach',
      'Booué',
      'Bitam',
    ],
  },
  {
    country: 'United Kingdom',
    cities: [
      'Ystrad Mynach',
      'Ystradgynlais',
      'Ystalyfera',
      'Yoxall',
      'Youlgreave',
      'York',
      'Yetminster',
      'Yeovil',
      'Yelverton',
      'Yealmpton',
      'Yeadon',
      'Yazor',
      'Yaxley',
      'Yatton',
      'Yate',
      'Yarnton',
      'Yarmouth',
      'Yarm',
      'Yapton',
      'Yalding',
      'Wymeswold',
      'Wylam',
      'Wye',
      'Wroxall',
      'Wroughton',
      'Wrotham',
      'Writtle',
      'Wrington',
      'Wrexham',
      'Wrea Green',
      'Wrawby',
      'Wragby',
      'Wouldham',
      'Wotton-under-Edge',
      'Worton',
      'Wortley',
      'Worthing',
      'Worlaby',
      'Worksop',
      'Workington',
      'Worcester Park',
      'Worcester',
      'Royal Wootton Bassett',
      'Wootton',
      'Woolwich',
      'Woolston',
      'Woolpit',
      'Woolley',
      'Wooler',
      'Wool',
      'Woodstock',
      'Woodsetts',
      'Woodley',
      'Woodhall Spa',
      'Wood Green',
      'Woodford Green',
      'Woodford',
      'Woodcote',
      'Woodchurch',
      'Woodbridge',
      'Woodborough',
      'Wombwell',
      'Wombourn',
      'Wolverhampton',
      'Wolston',
      'Wolsingham',
      'Wollaston',
      'Wold Newton',
      'Woldingham',
      'Wokingham',
      'Woking',
      'Woburn Sands',
      'Woburn',
      'Wivenhoe',
      'Wivelsfield Green',
      'Wiveliscombe',
      'Witton Gilbert',
      'Wittering',
      'Witney',
      'Witley',
      'Withington',
      'Withernsea',
      'Witheridge',
      'Witham',
      'Witchford',
      'Wiston',
      'Wishaw',
      'Wisbech',
      'Wirksworth',
      'Winwick',
      'Winterton',
      'Winterbourne Stoke',
      'Winterbourne',
      'Winston',
      'Winslow',
      'Winsford',
      'Winnersh',
      'Winkleigh',
      'Wingrave',
      'Wingham',
      'Wingfield',
      'Wingerworth',
      'Wingate',
      'Wing',
      'Winford',
      'Windsor',
      'Windlesham',
      'Windermere',
      'Winchester',
      'Winchcombe',
      'Wincanton',
      'Wimborne Minster',
      'Wimblington',
      'Wimbledon',
      'Wilton',
      'Wilstead',
      'Wilsford',
      'Wilsden',
      'Wilmslow',
      'Wilmcote',
      'Williton',
      'Willington',
      'Willingham',
      'Willesden',
      'Willerby',
      'Willenhall',
      'Willaston',
      'Willand',
      'Wilden',
      'Wilburton',
      'Wilberfoss',
      'Wigton',
      'Wigston Magna',
      'Wigmore',
      'Wigginton',
      'Wigan',
      'Widnes',
      'Wickwar',
      'Wickham Market',
      'Wickham Bishops',
      'Wickham',
      'Wickford',
      'Wick',
      'Whyteleafe',
      'Whitworth',
      'Whitwell',
      'Whitton',
      'Whittlesford',
      'Whittlesey',
      'Whittington',
      'Whittingham',
      'Whitstable',
      'Whitley Bay',
      'Whitland',
      'Whitford',
      'White Waltham',
      'Whiteparish',
      'Whitehills',
      'Whitehead',
      'Whitehaven',
      'Whitefield',
      'Whitechapel',
      'Whitchurch',
      'Whitby',
      'Whitburn',
      'Whiston',
      'Whimple',
      'Whickham',
      'Wheldrake',
      'Wheaton Aston',
      'Wheatley',
      'Wheathampstead',
      'Whaplode',
      'Whalley',
      'Whaley Bridge',
      'Weymouth',
      'Weybridge',
      'Wetwang',
      'Wetherby',
      'Wetheral',
      'Westwood',
      'West Wickham',
      'West Wellow',
      'West Walton',
      'West Thurrock',
      'West Rainton',
      'Westonzoyland',
      'Weston Underwood',
      'Weston Turville',
      'Weston-super-Mare',
      'Westoning',
      'Weston',
      'West Molesey',
      'City of Westminster',
      'West Mersea',
      'West Malling',
      'West Linton',
      'West Kirby',
      'West Kilbride',
      'Westhoughton',
      'West Horsley',
      'West Ham',
      'West Hallam',
      'West Haddon',
      'Westgate on Sea',
      'Westerham',
      'West End',
      'West Drayton',
      'Westcott',
      'West Cornforth',
      'West Coker',
      'West Clandon',
      'West Calder',
      'West Byfleet',
      'Westbury',
      'West Bromwich',
      'West Bridgford',
      'West Bergholt',
      'Werrington',
      'Weobley',
      'Wenvoe',
      'Wentworth',
      'Wendron',
      'Wendover',
      'Wemyss Bay',
      'Wembury',
      'Wembley',
      'Wem',
      'Welwyn Garden City',
      'Welwyn',
      'Welton',
      'Welshpool',
      'Wells-next-the-Sea',
      'Wells',
      'Wellow',
      'Wellington',
      'Wellingborough',
      'Welling',
      'Wellesbourne Mountford',
      'Wellesbourne',
      'Welford',
      'Weeting',
      'Weedon Beck',
      'Wednesfield',
      'Wednesbury',
      'Wedmore',
      'Weaverham',
      'Waverton',
      'Wavendon',
      'Watton at Stone',
      'Watton',
      'Wattisham',
      'Watlington',
      'Wath upon Dearne',
      'Watford',
      'Water Orton',
      'Waterlooville',
      'Wateringbury',
      'Waterbeach',
      'Watchet',
      'Washington',
      'Washingborough',
      'Warwick',
      'Warton',
      'Warsop',
      'Warrington',
      'Warrenpoint',
      'Warnham',
      'Warminster',
      'Warlingham',
      'Warkworth',
      'Waringstown',
      'Wargrave',
      'Warfield',
      'Wareham',
      'Ware',
      'Wardle',
      'Warden',
      'Warboys',
      'Wantage',
      'Wanstead',
      'Wansford',
      'Wandsworth',
      'Wanborough',
      'Walworth',
      'Walton-on-Thames',
      'Walton-on-the-Naze',
      'Walthamstow',
      'Waltham Cross',
      'Waltham Abbey',
      'Waltham',
      'Walsden',
      'Walsall',
      'Wallsend',
      'Wallington',
      'Wallingford',
      'Wallasey',
      'Wall',
      'Walkington',
      'Walkern',
      'Walkden',
      'Walford',
      'Wales',
      'Walberton',
      'Wakefield',
      'Wainfleet All Saints',
      'Wadworth',
      'Wadhurst',
      'Wadebridge',
      'Waddington',
      'Waddesdon',
      'Wacton',
      'Verwood',
      'Ventnor',
      'Valley',
      'Uxbridge',
      'Uttoxeter',
      'Usk',
      'Ushaw Moor',
      'Urmston',
      'Upwell',
      'Upton upon Severn',
      'Upton Scudamore',
      'Upton',
      'Uppingham',
      'Upper Poppleton',
      'Upper Norwood',
      'Upper Langwith',
      'Upminster',
      'Upchurch',
      'Uny Lelant',
      'Undy',
      'Ulverston',
      'Ulrome',
      'Ulley',
      'Ullapool',
      'Ulceby',
      'Ufford',
      'Uffculme',
      'Uddingston',
      'Uckfield',
      'Tytherington',
      'Tynemouth',
      'Tyldesley',
      'Tycroes',
      'Tyberton',
      'Twyford',
      'Twickenham',
      'Tuxford',
      'Turriff',
      'Turnastone',
      'Truro',
      'Trowbridge',
      'Troon',
      'Tring',
      'Trimdon Grange',
      'Trimdon',
      'Trewen',
      'Treuddyn',
      'Treorchy',
      'Trelech',
      'Treherbert',
      'Treharris',
      'Tregoney',
      'Tregarth',
      'Tregaron',
      'Trefnant',
      'Treeton',
      'Tredegar',
      'Trawsfynydd',
      'Tranent',
      'Trafford Park',
      'Townhill',
      'Tow Law',
      'Towcester',
      'Totton',
      'Tottington',
      'Totternhoe',
      'Totteridge',
      'Tottenham',
      'Totnes',
      'Totland',
      'Torquay',
      'Torpoint',
      'Torphins',
      'Topsham',
      'Tonyrefail',
      'Tong',
      'Tonbridge',
      'Tolleshunt Knights',
      'Tollesbury',
      'Todwick',
      'Todmorden',
      'Toddington',
      'Tobermory',
      'Tiverton',
      'Titchfield',
      'Tisbury',
      'Tiptree',
      'Tipton',
      'Tinwell',
      'Tingewick',
      'Timsbury',
      'Timperley',
      'Tillicoultry',
      'Tilehurst',
      'Tilbury',
      'Tideswell',
      'Tickton',
      'Tickhill',
      'Tickencote',
      'Ticehurst',
      'Tibshelf',
      'Thwing',
      'Thurston',
      'Thurso',
      'Thurlton',
      'Thurlby',
      'Thruxton',
      'Three Legged Cross',
      'Thrapston',
      'Thorpe le Soken',
      'Thorpe',
      'Thorp Arch',
      'Thornton Heath',
      'Thornton Dale',
      'Thornton',
      'Thornley',
      'Thornhill',
      'Thornhaugh',
      'Thorngumbald',
      'Thorney',
      'Thorner',
      'Thorne',
      'Thornbury',
      'Thornaby-on-Tees',
      'Thirsk',
      'Theydon Bois',
      'Thetford',
      'Theale',
      'Thaxted',
      'Thatcham',
      'Thames Ditton',
      'Thame',
      'Teynham',
      'Tewkesbury',
      'Tetney',
      'Tetbury',
      'Terrington Saint John',
      'Terrington St Clement',
      'Tenterden',
      'Tenby',
      'Tenbury Wells',
      'Tempsford',
      'Templeton',
      'Templepatrick',
      'Templecombe',
      'Teignmouth',
      'Teddington',
      'Tayport',
      'Tavistock',
      'Taunton',
      'Tattenhall',
      'Tarvin',
      'Tarporley',
      'Tarleton',
      'Tarbolton',
      'Tarbert',
      'Tansley',
      'Tangmere',
      'Tanfield',
      'Tandragee',
      'Tamworth',
      'Talgarth',
      'Takeley',
      'Tain',
      'Taibach',
      'Tadworth',
      'Tadley',
      'Tadcaster',
      'Sywell',
      'Syston',
      'Symington',
      'Swinton',
      'Swineshead',
      'Swindon',
      'Swillington',
      'Sway',
      'Swavesey',
      'Swansea',
      'Swanscombe',
      'Swanmore',
      'Swanley',
      'Swanage',
      'Swallowfield',
      'Swaffham',
      'Swadlincote',
      'Sutton upon Derwent',
      'Sutton on Trent',
      'Sutton in Ashfield',
      'Sutton Courtenay',
      'Sutton Coldfield',
      'Sutton Bridge',
      'Sutton Bonington',
      'Sutton Benger',
      'Sutton',
      'Sutterton',
      'Sunninghill',
      'Sunningdale',
      'Sunk Island',
      'Sundridge',
      'Sunderland',
      'Sunbury-on-Thames',
      'Sudbury',
      'Sudbrooke',
      'Sturry',
      'Sturminster Newton',
      'Sturminster Marshall',
      'Studley',
      'Stroud',
      'Strood',
      'Stromness',
      'Stretton',
      'Stretham',
      'Stretford',
      'Strensall',
      'Streetly',
      'Street',
      'Streatley',
      'Streatham',
      'Strathpeffer',
      'Strathblane',
      'Strathaven',
      'Stratford-upon-Avon',
      'Stratford',
      'Stratfield Mortimer',
      'Stranraer',
      'Stradbroke',
      'Strabane',
      'Stow on the Wold',
      'Stowmarket',
      'Stourport-on-Severn',
      'Stourbridge',
      'Stotfold',
      'Storrington',
      'Stornoway',
      'Stony Stratford',
      'Stoney Stanton',
      'Stone',
      'Stonesfield',
      'Stonehouse',
      'Stonehaven',
      'Stondon',
      'Stoke upon Tern',
      'Stokesley',
      'Stoke Prior',
      'Stoke Poges',
      'Stoke-on-Trent',
      'Stokenchurch',
      'Stoke Goldington',
      'Stoke Golding',
      'Stoke Gifford',
      'Stoke Gabriel',
      'Stoke Ferry',
      'Stoke',
      'Upper Stoke',
      'Stockton-on-Tees',
      'Stockton',
      'Stocksfield',
      'Stocksbridge',
      'Stockport',
      'Stock',
      'Stithians',
      'Stirling',
      'Stilton',
      'Stillington',
      'Stickney',
      'Steyning',
      'Stewkley',
      'Stewarton',
      'Stevington',
      'Steventon',
      'Stevenston',
      'Stevenage',
      'Stepps',
      'Steppingley',
      'Stepney',
      'Steeton',
      'Steeple Claydon',
      'Steeple Bumpstead',
      'Staveley',
      'Startforth',
      'Starcross',
      'Staplehurst',
      'Stapleford',
      'Stanwick',
      'Stanwell',
      'Stansted Mountfitchet',
      'Stannington',
      'Stanmore',
      'Stanley',
      'Stanhope',
      'Stanford-le-Hope',
      'Stanford in the Vale',
      'Standon',
      'Standlake',
      'Stanbridge',
      'Stamford Bridge',
      'Stamford',
      'Stalybridge',
      'Stallingborough',
      'Stalham',
      'Stalbridge',
      'Stainton',
      'Stainforth',
      'Staines',
      'Staindrop',
      'Stainburn',
      'Stainborough',
      'Stagsden',
      'Stafford',
      'Sproatley',
      'Springside',
      'Spratton',
      'Spofforth',
      'Spixworth',
      'Spittal',
      'Spitalfields',
      'Spilsby',
      'Spennymoor',
      'Speldhurst',
      'Spalding',
      'Sowerby Bridge',
      'Southwold',
      'South Witham',
      'South Wingfield',
      'Southwick',
      'Southwell',
      'Southwater',
      'Tidworth',
      'South Shields',
      'Southsea',
      'Southport',
      'South Petherton',
      'Southowram',
      'Southorpe',
      'South Ockendon',
      'South Nutfield',
      'South Norwood',
      'South Molton',
      'Southminster',
      'South Milford',
      'South Luffenham',
      'South Littleton',
      'Southill',
      'South Hill',
      'South Hetton',
      'South Hayling',
      'South Harting',
      'Southgate',
      'Southery',
      'Southend-on-Sea',
      'South Elmsall',
      'South Collingham',
      'South Cerney',
      'South Cave',
      'South Brent',
      'South Benfleet',
      'South Bank',
      'Southampton',
      'Southam',
      'Southall',
      'Sonning',
      'Somerton',
      'Somersham',
      'Solihull',
      'Soham',
      'Snodland',
      'Snettisham',
      'Snaresbrook',
      'Snaith',
      'Smethwick',
      'Slough',
      'Slinfold',
      'Sleights',
      'Sleaford',
      'Slamannan',
      'Slaley',
      'Skipton',
      'Skipsea',
      'Skinningrove',
      'Skidby',
      'Skelton',
      'Skelmorlie',
      'Skelmersdale',
      'Skellingthorpe',
      'Skegness',
      'Sittingbourne',
      'Siston',
      'Sissinghurst',
      'Sion Mills',
      'Simpson',
      'Silverton',
      'Silverstone',
      'Silverdale',
      'Silsoe',
      'Silsden',
      'Silloth',
      'Silkstone',
      'Sileby',
      'Sidmouth',
      'Siddington',
      'Sidcup',
      'Sibsey',
      'Sible Hedingham',
      'Shurdington',
      'Shrivenham',
      'Shrewton',
      'Shrewsbury',
      'Shotwick',
      'Shotton',
      'Shotley Gate',
      'Shoreham-by-Sea',
      'Shirley',
      'Shirland',
      'Shirebrook',
      'Shipton under Wychwood',
      'Shipston on Stour',
      'Shipley',
      'Shipham',
      'Shipdham',
      'Shinfield',
      'Shillington',
      'Shillingstone',
      'Shildon',
      'Shilbottle',
      'Shifnal',
      'Shevington',
      'Sherston',
      'Sherington',
      'Sheringham',
      'Sheriff Hutton',
      'Sherburn in Elmet',
      'Sherburn Hill',
      'Sherburn',
      'Sherborne St John',
      'Sherborne',
      'Shepton Mallet',
      'Shepshed',
      'Shepperton',
      'Shepley',
      'Shepherdswell',
      'Shepherds Bush',
      'Shenstone',
      'Shenley Church End',
      'Shenley',
      'Shenfield',
      'Shelley',
      'Shefford',
      'Sheffield',
      'Sheerness',
      'Shawbury',
      'Shaw',
      'Sharpness',
      'Sharnbrook',
      'Shap',
      'Shanklin',
      'Shalfleet',
      'Shalbourne',
      'Shafton',
      'Shaftesbury',
      'Shadoxhurst',
      'Seven Sisters',
      'Sevenoaks',
      'Settle',
      'Sennen',
      'Send',
      'Selsey',
      'Sellack',
      'Selkirk',
      'Selby',
      'Seghill',
      'Seend',
      'Sedgefield',
      'Sedbergh',
      'Seaview',
      'Seaton Delaval',
      'Seaton',
      'Seascale',
      'Seahouses',
      'Seaham',
      'Seaford',
      'Scunthorpe',
      'Scotter',
      'Scotby',
      'Scorton',
      'Scole',
      'Scholes',
      'Scawby',
      'Scarcroft',
      'Scarborough',
      'Scalloway',
      'Scalby',
      'Saxmundham',
      'Saxilby',
      'Sawtry',
      'Sawston',
      'Sawbridgeworth',
      'Saundersfoot',
      'Saughall',
      'Sapcote',
      'Sanquhar',
      'Sandy',
      'Sandwick',
      'Sandwich',
      'Sandown',
      'Sandhurst',
      'Sandford',
      'Sanderstead',
      'Sandbank',
      'Sandbach',
      'Sampford Peverell',
      'Saltford',
      'Saltcoats',
      'Saltburn-by-the-Sea',
      'Saltash',
      'Salisbury',
      'Saline',
      'Salfords',
      'Salford',
      'Sale',
      'Salcombe',
      'Saint Stephen',
      'Saint Peters',
      'Saint Osyth',
      'Saint Neots',
      'Saint Monans',
      'Saint Leonards-on-Sea',
      'St Leonards',
      'St Ives',
      'St Helens',
      'St. Helens',
      'Saintfield',
      'Saint Dennis',
      'St. Day',
      'Saint Davids',
      'Saint Cyrus',
      'Saint Columb Major',
      'Saint Clears',
      'Saint Boswells',
      'Saint Bees',
      'St Austell',
      'Saint Asaph',
      'Saint Andrews',
      'St Albans',
      'Saint Agnes',
      'Saffron Walden',
      'Sacriston',
      'Sabden',
      'Ryton on Dunsmore',
      'Ryton',
      'Ryhope',
      'Ryhill',
      'Ryhall',
      'Rye',
      'Ryde',
      'Ruyton-XI-Towns',
      'Ruthin',
      'Rutherglen',
      'Rustington',
      'Ruskington',
      'Rushden',
      'Rushall',
      'Runcorn',
      'Ruislip',
      'Rugeley',
      'Rugby',
      'Rufford',
      'Rudgwick',
      'Ruddington',
      'Ruabon',
      'Royton',
      'Royston',
      'Roydon',
      'Royal Tunbridge Wells',
      'Roxton',
      'Rowlstone',
      'Rowley Regis',
      'Rowlands Gill',
      'Rowhedge',
      'Rowde',
      'Rottingdean',
      'Rothwell',
      'Rothley',
      'Rothienorman',
      'Rothesay',
      'Rothes',
      'Rotherhithe',
      'Rotherham',
      'Rotherfield Peppard',
      'Rothbury',
      'Rosyth',
      'Rostrevor',
      'Ross on Wye',
      'Rossington',
      'Rossett',
      'Rosneath',
      'Rosehearty',
      'Roos',
      'Romsley',
      'Romsey',
      'Romford',
      'Romaldkirk',
      'Rokeby',
      'Roehampton',
      'Rode Heath',
      'Rode',
      'Rochford',
      'Rochester',
      'Roche',
      'Rochdale',
      'Robertsbridge',
      'Roade',
      'Rishton',
      'Riseley',
      'Risca',
      'Ripponden',
      'Ripon',
      'Ripley',
      'Ringwood',
      'Ringway',
      'Ringstead',
      'Ringmer',
      'Rimswell',
      'Rillington',
      'Ridlington',
      'Ridgmont',
      'Rickmansworth',
      'Rickinghall',
      'Richmond',
      'Riccall',
      'Rhymney',
      'Rhyl',
      'Rhuddlan',
      'Rhu',
      'Rhosneigr',
      'Rhosllanerchrugog',
      'Rhoose',
      'Rhondda',
      'Rhayader',
      'Resolven',
      'Repton',
      'Renton',
      'Renfrew',
      'Rendlesham',
      'Remenham',
      'Reigate',
      'Reepham',
      'Reedham',
      'Redwick',
      'Redruth',
      'Redlynch',
      'Redhill',
      'Redditch',
      'Redcar',
      'Redbourne',
      'Redbourn',
      'Reading',
      'Rayne',
      'Rayleigh',
      'Rawtenstall',
      'Rawmarsh',
      'Rawcliffe',
      'Ravenstone',
      'Raunds',
      'Ratho',
      'Rathfriland',
      'Ranskill',
      'Randalstown',
      'Ramsgate',
      'Ramsey',
      'Ramsbury',
      'Ramsbottom',
      'Rake',
      'Rainworth',
      'Rainhill',
      'Rainham',
      'Rainford',
      'Raglan',
      'Radyr',
      'Radstock',
      'Radley',
      'Radlett',
      'Radcliffe on Trent',
      'Rackheath',
      'Quorndon',
      'Queniborough',
      'Queensferry',
      'Queensbury',
      'Queenborough',
      'Pyrford',
      'Pyle',
      'Pwllheli',
      'Putney',
      'Purton',
      'Purley',
      'Puriton',
      'Purfleet-on-Thames',
      'Pulloxhill',
      'Pulborough',
      'Pudsey',
      'Puddletown',
      'Puckeridge',
      'Publow',
      'Prudhoe',
      'Probus',
      'Priston',
      'Princetown',
      'Princes Risborough',
      'Prestwick',
      'Prestwich',
      'Prestonpans',
      'Preston',
      'Presteigne',
      'Prestbury',
      'Prestatyn',
      'Prescot',
      'Preesall',
      'Prees',
      'Poynton',
      'Poundstock',
      'Poulton-le-Fylde',
      'Pott Shrigley',
      'Potton',
      'Potterspury',
      'Potters Bar',
      'Potterne',
      'Potsgrove',
      'Port Talbot',
      'Portstewart',
      'Portsoy',
      'Portsmouth',
      'Portslade',
      'Portrush',
      'Portree',
      'Portlethen',
      'Portknockie',
      'Portishead',
      'Portscatho',
      'Porthmadog',
      'Porthleven',
      'Porthcawl',
      'Porth',
      'Portglenone',
      'Port Glasgow',
      'Port Erroll',
      'Port Bannatyne',
      'Portavogie',
      'Portaferry',
      'Portadown',
      'Porlock',
      'Poringland',
      'Poplar',
      'Poole',
      'Pool',
      'Pontypridd',
      'Pontypool',
      'Pontycymer',
      'Pontyberem',
      'Pontyates',
      'Ponthir',
      'Pontesbury',
      'Ponteland',
      'Pontefract',
      'Pontarddulais',
      'Ponders End',
      'Polperro',
      'Polmont',
      'Pollington',
      'Polesworth',
      'Podington',
      'Pocklington',
      'Plymstock',
      'Plympton',
      'Plymouth',
      'Plumstead',
      'Plumpton Green',
      'Plean',
      'Pittington',
      'Pittenweem',
      'Pitstone',
      'Pitsea',
      'Pitmedden',
      'Pitlochry',
      'Pirton',
      'Pirbright',
      'Pinxton',
      'Pinner',
      'Pinchbeck',
      'Pimperne',
      'Pilsley',
      'Pilning',
      'Pillaton',
      'Pickworth',
      'Pickering',
      'Pewsey',
      'Pevensey',
      'Petworth',
      'Petton',
      'Petersfield',
      'Peterlee',
      'Peterhead',
      'Peterculter',
      'Peterborough',
      'Perth',
      'Pertenhall',
      'Pershore',
      'Perranwell',
      'Perranporth',
      'Peover Superior',
      'Penzance',
      'Penygroes',
      'Penybont',
      'Pentyrch',
      'Penryn',
      'Penrith',
      'Penrhyndeudraeth',
      'Penmaenmawr',
      'Penllyn',
      'Penkridge',
      'Penistone',
      'Penicuik',
      'Pengam',
      'Pencoyd',
      'Pencoed',
      'Pen-clawdd',
      'Pencaitland',
      'Pencader',
      'Penarth',
      'Penally',
      'Pembury',
      'Pembroke Dock',
      'Pembroke',
      'Pelton',
      'Pelsall',
      'Pegswood',
      'Peebles',
      'Peakirk',
      'Paulton',
      'Paull',
      'Pattingham',
      'Patrington',
      'Patna',
      'Pateley Bridge',
      'Partridge Green',
      'Partington',
      'Parkstone',
      'Parbold',
      'Par',
      'Papworth Everard',
      'Pant',
      'Pannal',
      'Pangbourne',
      'Palmers Green',
      'Paisley',
      'Painswick',
      'Paignton',
      'Padstow',
      'Padiham',
      'Paddock Wood',
      'Oxted',
      'Oxshott',
      'Oxford',
      'Oxenhope',
      'Owston Ferry',
      'Overtown',
      'Overton',
      'Overstrand',
      'Over',
      'Outwell',
      'Oundle',
      'Oughtibridge',
      'Ottringham',
      'Ottery St Mary',
      'Ottershaw',
      'Otterburn',
      'Otley',
      'Otford',
      'Oswestry',
      'Ossett',
      'Orwell',
      'Orsett',
      'Orpington',
      'Ormskirk',
      'Ormiston',
      'Ormesby St Margaret',
      'Orleton',
      'Orgreave',
      'Omagh',
      'Olveston',
      'Olney',
      'Old Windsor',
      'Oldmeldrum',
      'Old Leake',
      'Old Kilpatrick',
      'Oldham',
      'Oldbury',
      'Okehampton',
      'Odiham',
      'Odell',
      'Ocle Pychard',
      'Ochiltree',
      'Oban',
      'Oakley',
      'Oakington',
      'Oakham',
      'Oakengates',
      'Oadby',
      'Nutfield',
      'Nunthorpe',
      'Nuneaton',
      'Notton',
      'Nottingham',
      'Norwich',
      'Norton Canes',
      'Norton',
      'North Wraxall',
      'North Wootton',
      'Northwood',
      'Northwich',
      'North Walsham',
      'North Thoresby',
      'North Tawton',
      'North Sunderland',
      'North Somercotes',
      'North Shields',
      'North Queensferry',
      'North Petherton',
      'Northorpe',
      'Northop',
      'Northolt',
      'North Newbald',
      'North Luffenham',
      'North Leigh',
      'Northleach',
      'Lancing',
      'Northiam',
      'North Hill',
      'North Ferriby',
      'North Elmsall',
      'North Elmham',
      'North Collingham',
      'North Cave',
      'North Bradley',
      'Northborough',
      'North Berwick',
      'Northampton',
      'Northam',
      'Northallerton',
      'Normanton',
      'Normandy',
      'Norbury',
      'Niton',
      'Ninfield',
      'Neyland',
      'New Tredegar',
      'Newtownstewart',
      'Newtown St Boswells',
      'Newtownhamilton',
      'Newtownards',
      'Newtownabbey',
      'Newtown',
      'Newton Stewart',
      'Newton Poppleford',
      'Newtonmore',
      'Newton Mearns',
      'Newton Longville',
      'Newton-le-Willows',
      'Newtonhill',
      'Newton Ferrers',
      'Newton Aycliffe',
      'Newton Abbot',
      'Scone',
      'Newry',
      'New Romney',
      'New Quay',
      'Newquay',
      'Newport Pagnell',
      'Newport-on-Tay',
      'Newport',
      'New Pitsligo',
      'New Milton',
      'Newmilns',
      'New Mills',
      'Newmarket',
      'New Malden',
      'Newmains',
      'Newmacher',
      'Newington',
      'Newick',
      'Newhaven',
      'New Ferry',
      'Newent',
      'New Cumnock',
      'New Cross',
      'Newchurch',
      'Newcastle upon Tyne',
      'Newcastle under Lyme',
      'Newcastle Emlyn',
      'Newbury',
      'Newburn',
      'Newburgh',
      'New Buildings',
      'Newbridge',
      'Newborough',
      'Newbold Verdon',
      'Newbiggin-by-the-Sea',
      'Newbiggin',
      'Newarthill',
      'Newark on Trent',
      'New Alresford',
      'Nefyn',
      'Nevern',
      'Nettleton',
      'Nettleham',
      'Netley',
      'Netherton',
      'Nether Stowey',
      'Nether Poppleton',
      'Netheravon',
      'Neston',
      'Nelson',
      'Neilston',
      'Needingworth',
      'Needham Market',
      'Necton',
      'Neath',
      'Navenby',
      'Nash',
      'Narborough',
      'Narberth',
      'Nantwich',
      'Nairn',
      'Nailsworth',
      'Nailsea',
      'Nafferton',
      'Mytholmroyd',
      'Mytchett',
      'Mylor Bridge',
      'Musselburgh',
      'Murton',
      'Mundford',
      'Mundesley',
      'Mullion',
      'Mulbarton',
      'Muir of Ord',
      'Muirkirk',
      'Muirhead',
      'Muggleswick',
      'Much Wenlock',
      'Much Hadham',
      'Much Birch',
      'Moy',
      'Mountsorrel',
      'Mountain Ash',
      'Moulton Chapel',
      'Moulton',
      'Moulsoe',
      'Mouldsworth',
      'Motherwell',
      'Motcombe',
      'Mostyn',
      'Mossley',
      'Mossend',
      'Moss',
      'Morriston',
      'Morpeth',
      'Morley',
      'Moreton in Marsh',
      'Moretonhampstead',
      'Moreton',
      'Morecambe',
      'Morden',
      'Morcott',
      'Morchard Bishop',
      'Montrose',
      'Monmouth',
      'Monk Fryston',
      'Monifieth',
      'Moneymore',
      'Mollington',
      'Mold',
      'Moira',
      'Moffat',
      'Moelfre',
      'Modbury',
      'Mochdre',
      'Mobberley',
      'Mitchel Troy',
      'Mitcheldean',
      'Mitcham',
      'Mistley',
      'Misterton',
      'Mirfield',
      'Mintlaw',
      'Minster Lovell',
      'Minsterley',
      'Minety',
      'Minehead',
      'Minchinhampton',
      'Milverton',
      'Milton Keynes',
      'Milton Bryan',
      'Milston',
      'Milnthorpe',
      'Milnrow',
      'Milngavie',
      'Milnathort',
      'Milltimber',
      'Millport',
      'Millom',
      'Millisle',
      'Millington',
      'Millbrook',
      'Milford on Sea',
      'Milford Haven',
      'Milford',
      'Mildenhall',
      'Milborne St Andrew',
      'Milborne Port',
      'Midsomer Norton',
      'Midhurst',
      'Middle Winterslow',
      'Middlewich',
      'Middleton',
      'Middlestown',
      'Middlesbrough',
      'Middle Rasen',
      'Mid Calder',
      'Mickle Trafford',
      'Mickleton',
      'Mexborough',
      'Mevagissey',
      'Methven',
      'Methley',
      'Methil',
      'Metheringham',
      'Messingham',
      'Merthyr Tydfil',
      'Merstham',
      'Merrow',
      'Meriden',
      'Mere',
      'Meppershall',
      'Meopham',
      'Menston',
      'Menai Bridge',
      'Melton Mowbray',
      'Meltham',
      'Melrose',
      'Melling',
      'Melksham',
      'Meldreth',
      'Meldon',
      'Melbourne',
      'Melbourn',
      'Meikle Earnock',
      'Measham',
      'Mayland',
      'Mayfield',
      'Maybole',
      'Maulden',
      'Mauchline',
      'Mattishall',
      'Matlock',
      'Mathry',
      'Masham',
      'Maryport',
      'Martock',
      'Martham',
      'Marstow',
      'Marston Moretaine',
      'Marston',
      'Marske-by-the-Sea',
      'Marshfield',
      'Marsden',
      'Marr',
      'Marple',
      'Marnhull',
      'Marlow',
      'Marldon',
      'Marlborough',
      'Markyate',
      'Marks Tey',
      'Markinch',
      'Markfield',
      'Market Weighton',
      'Market Warsop',
      'Market Rasen',
      'Market Overton',
      'Market Lavington',
      'Market Harborough',
      'Market Drayton',
      'Market Deeping',
      'Market Bosworth',
      'Marholm',
      'Margate',
      'Marden',
      'Marchwood',
      'Marchwiel',
      'Marcham',
      'March',
      'Marazion',
      'Mappleton',
      'Manton',
      'Manston',
      'Mansfield Woodhouse',
      'Mansfield',
      'Manorbier',
      'Manningtree',
      'Mangotsfield',
      'Manea',
      'Manchester',
      'Manby',
      'Malton',
      'Maltby',
      'Malpas',
      'Malmesbury',
      'Maldon',
      'Mainstone',
      'Maidstone',
      'Maiden Newton',
      'Maidenhead',
      'Magor',
      'Maghull',
      'Magheralin',
      'Magherafelt',
      'Maghera',
      'Maesycwmmer',
      'Maesteg',
      'Maerdy',
      'Maentwrog',
      'Madeley',
      'Macmerry',
      'Machynlleth',
      'Machen',
      'Macduff',
      'Macclesfield',
      'Mablethorpe',
      'Lytchett Matravers',
      'Lynton',
      'Lyneham',
      'Lyndhurst',
      'Lympstone',
      'Lympne',
      'Lymm',
      'Lymington',
      'Lyminge',
      'Lyme Regis',
      'Lydney',
      'Lydiard Tregoze',
      'Lydiard Millicent',
      'Lydham',
      'Lydd',
      'Lydbrook',
      'Lutterworth',
      'Luton',
      'Lundin Links',
      'Lund',
      'Luncarty',
      'Lugwardine',
      'Ludlow',
      'Ludgershall',
      'Luddenden Foot',
      'Luckington',
      'Lowick',
      'Low Etherley',
      'Lowestoft',
      'Lower Kingswood',
      'Lower Halstow',
      'Lower Bullingham',
      'Lower Brailes',
      'Lowdham',
      'Low Bradley',
      'Low Ackworth',
      'Louth',
      'Loughton',
      'Loughborough',
      'Loudwater',
      'Lostwithiel',
      'Lossiemouth',
      'Longwick',
      'Long Whatton',
      'Longtown',
      'Longton',
      'Long Sutton',
      'Long Stratton',
      'Longstanton',
      'Longridge',
      'Longnor',
      'Longniddry',
      'Long Melford',
      'Long Lawford',
      'Long Itchington',
      'Longhoughton',
      'Longhorsley',
      'Longhope',
      'Longforgan',
      'Longfield',
      'Long Eaton',
      'Long Ditton',
      'Longdendale',
      'Long Crendon',
      'Long Clawson',
      'Long Buckby',
      'Long Bennington',
      'Long Ashton',
      'Londonderry County Borough',
      'Derry',
      'City of London',
      'London',
      'Londesborough',
      'Loftus',
      'Lofthouse',
      'Loddon',
      'Lockington',
      'Locking',
      'Lockerbie',
      'Lochwinnoch',
      'Lochmaben',
      'Lochgilphead',
      'Lochgelly',
      'Locharbriggs',
      'Loanhead',
      'Llysfaen',
      'Llwynypia',
      'Lledrod',
      'Llanwnda',
      'Llanwinio',
      'Llanwern',
      'Llanveynoe',
      'Llanvaches',
      'Llantwit Major',
      'Llantwit Fardre',
      'Llantrisant',
      'Llansteffan',
      'Llansawel',
      'Llansantffraid Glan Conwy',
      'Llansadwrn',
      'Llanrwst',
      'Llanrug',
      'Llanrothal',
      'Llanrhian',
      'Llanllwchaiarn',
      'Llanilar',
      'Llanidloes',
      'Llanharry',
      'Llanharan',
      'Llangynog',
      'Llangynidr',
      'Llangybi',
      'Llangwm',
      'Llangollen',
      'Llangoed',
      'Llangeler',
      'Llangefni',
      'Llangathen',
      'Llangan',
      'Llangain',
      'Llanfynydd',
      'Llanfyllin',
      'Llanfechain',
      'Llanfairpwllgwyngyll',
      'Llanfairfechan',
      'Llanfair Caereinion',
      'Llanfair',
      'Llanfaethlu',
      'Llanfachraeth',
      'Llanerchymedd',
      'Llanelli',
      'Llandysul',
      'Llandybie',
      'Llandudno',
      'Llandrindod Wells',
      'Llandrillo',
      'Llanddowror',
      'Llandovery',
      'Llandeilo',
      'Llandegla',
      'Llanddeusant',
      'Llanddarog',
      'Llancillo',
      'Llanbradach',
      'Llanboidy',
      'Llanberis',
      'Llanbedr',
      'Llanbadoc',
      'Llanarth',
      'Livingston',
      'Liversedge',
      'Liverpool',
      'Little Weighton',
      'Littleport',
      'Little Paxton',
      'Little Lever',
      'Little Hulton',
      'Little Houghton',
      'Littlehampton',
      'Little Eaton',
      'Little Dunmow',
      'Little Clacton',
      'Littlebourne',
      'Littleborough',
      'Little Bookham',
      'Little Barford',
      'Little Amwell',
      'Litherland',
      'Liss',
      'Lisnaskea',
      'Liskeard',
      'Lisburn',
      'Liphook',
      'Linton upon Ouse',
      'Linton',
      'Linthwaite',
      'Linlithgow',
      'Lingwood',
      'Lingfield',
      'Lingen',
      'Lingdale',
      'Lincoln',
      'Limpley Stoke',
      'Limekilns',
      'Limehouse',
      'Limavady',
      'Lifton',
      'Lidlington',
      'Lichfield',
      'Lhanbryde',
      'Leyton',
      'Leysdown-on-Sea',
      'Leyland',
      'Leyburn',
      'Lewes',
      'Leven',
      'Leuchars',
      'Letterston',
      'Letham',
      'Lesmahagow',
      'Leslie',
      'Lesbury',
      'Lerwick',
      'Leominster',
      'Lennoxtown',
      'Lenham',
      'Leiston',
      'Leighton Buzzard',
      'Leigh-on-Sea',
      'Leigh',
      'Leicester',
      'Leeswood',
      'Lee-on-the-Solent',
      'Leek Wootton',
      'Leek',
      'Leeds',
      'Ledston',
      'Ledsham',
      'Ledbury',
      'Leconfield',
      'Lechlade',
      'Leatherhead',
      'Leasingham',
      'Royal Leamington Spa',
      'Lea',
      'Layer de la Haye',
      'Laxton',
      'Law',
      'Lavenham',
      'Lavendon',
      'Laurencekirk',
      'Launceston',
      'Lauder',
      'Lathbury',
      'Latchingdon and Snoreham',
      'Lartington',
      'Larne',
      'Lark Hill',
      'Larkhall',
      'Largs',
      'Larbert',
      'Langtoft',
      'Langport',
      'Langley Park',
      'Langholm',
      'Langho',
      'Langham',
      'Langford',
      'Landrake',
      'Landore',
      'Landewednack',
      'Lanchester',
      'Lancaster',
      'Lanark',
      'Lampeter',
      'Lamlash',
      'Lambourn',
      'Lamberhurst',
      'Lakenheath',
      'Ladybank',
      'Lacock',
      'Laceby',
      'Knutsford',
      'Knowsley',
      'Knowle',
      'Knottingley',
      'Knighton',
      'Knebworth',
      'Knaresborough',
      'Kiveton Park',
      'Kislingbury',
      'Kirton in Lindsey',
      'Kirton',
      'Kirriemuir',
      'Kirkwall',
      'Kirk Sandall',
      'Kirknewton',
      'Kirkliston',
      'Kirkleatham',
      'Kirkintilloch',
      'Kirkham',
      'Kirk Ella',
      'Kirkcudbright',
      'Kirkconnel',
      'Kirkcaldy',
      'Kirkby Stephen',
      'Kirkbymoorside',
      'Kirkby Lonsdale',
      'Kirkby in Ashfield',
      'Kirkby',
      'Kirkburton',
      'Kircubbin',
      'Kirby Underdale',
      'Kirby Muxloe',
      'Kippen',
      'Kippax',
      'Kinvere',
      'Kintore',
      'Kintbury',
      'Kinsham',
      'Kinross',
      'Kinnersley',
      'Kinloss',
      'Kingussie',
      'Kington',
      'Kings Worthy',
      'Kingswood',
      'Kingswinford',
      'Kingston upon Hull',
      'Kingston Seymour',
      'Kingstone',
      'Kingston Bagpuize',
      'Kingsteignton',
      'Kings Sutton',
      "King's Lynn",
      'Kingsley',
      'Kings Langley',
      'Kingsland',
      'Kingskettle',
      'Kingskerswell',
      'Kingsclere',
      'Kingsbury',
      'Kingsbridge',
      'Kinglassie',
      'Kinghorn',
      'Kineton',
      'Kincardine',
      'Kimpton',
      'Kimbolton',
      'Kimberley',
      'Kilwinning',
      'Kilsyth',
      'Kilsby',
      'Kilrea',
      'Kilpin',
      'Kilmington',
      'Kilmaurs',
      'Kilmarnock',
      'Kilmacolm',
      'Killyleagh',
      'Killearn',
      'Killamarsh',
      'Kilkeel',
      'Kilham',
      'Kilgetty',
      'Kilcreggan',
      'Kilburn',
      'Kilbirnie',
      'Kilbarchan',
      'Kidwelly',
      'Kidsgrove',
      'Kidlington',
      'Kidderminster',
      'Kidbrooke',
      'Kibworth Harcourt',
      'Keynsham',
      'Keyingham',
      'Kew',
      'Ketton',
      'Kettering',
      'Keswick',
      'Kessingland',
      'Keresley',
      'Kenton',
      'Kensworth',
      'Kensington',
      'Kennoway',
      'Kennington',
      'Kenn',
      'Kenley',
      'Kenilworth',
      'Kendal',
      'Kemsing',
      'Kempston',
      'Kempsey',
      'Kemnay',
      'Kelvedon Hatch',
      'Kelvedon',
      'Kelty',
      'Kelso',
      'Kelsall',
      'Keith',
      'Keighley',
      'Kegworth',
      'Keele',
      'Keelby',
      'Kedington',
      'Kearsley',
      'Keady',
      'Keadby',
      'Jordanstown',
      'Johnstone',
      'Johnston',
      'Jeffreyston',
      'Jedburgh',
      'Jarrow',
      'Jacobstow',
      'Ixworth',
      'Iwade',
      'Ivybridge',
      'Ivinghoe',
      'Iver',
      'Islington',
      'Isleworth',
      'Isleham',
      'Irvinestown',
      'Irvine',
      'Irthlingborough',
      'Ironbridge',
      'Irlam',
      'Irchester',
      'Ipswich',
      'Ipplepen',
      'Inverurie',
      'Inverness',
      'Inverkip',
      'Inverkeithing',
      'Invergowrie',
      'Invergordon',
      'Inverbervie',
      'Insch',
      'Innerleithen',
      'Inkberrow',
      'Ingrave',
      'Ingoldmells',
      'Ingleton',
      'Ingleby Greenhow',
      'Ingatestone',
      'Inchture',
      'Inchinnan',
      'Ince-in-Makerfield',
      'Ince Blundell',
      'Ince',
      'Immingham',
      'Ilminster',
      'Ilkley',
      'Ilkeston',
      'Ilfracombe',
      'Ilford',
      'Ilchester',
      'Ibstock',
      'Hythe',
      'Hyde',
      'Huyton',
      'Hutton Magna',
      'Hutton',
      'Husbands Bosworth',
      'Hurworth',
      'Hurstpierpoint',
      'Hurst',
      'Hurley',
      'Hunwick',
      'Huntspill',
      'Huntly',
      'Huntley',
      'Huntington',
      'Huntingdon',
      'Hunstanton',
      'Hunmanby',
      'Hungerford',
      'Humbleton',
      'Humberston',
      'Humber',
      'Huish',
      'Hugh Town',
      'Huddersfield',
      'Hucknall',
      'Hoyland Nether',
      'Hoylake',
      'Howwood',
      'Howden',
      'Hove',
      'Houston',
      'Hounslow',
      'Houghton Regis',
      'Houghton on the Hill',
      'Houghton-Le-Spring',
      'Houghton Conquest',
      'Hotham',
      'Horwich',
      'Horton Kirby',
      'Horton',
      'Horsted Keynes',
      'Horsmonden',
      'Horsley',
      'Horsham',
      'Horsforth',
      'Horsford',
      'Horrabridge',
      'Hornsey',
      'Hornsea',
      'Horning',
      'Horndon on the Hill',
      'Horncastle',
      'Horley',
      'Horbury',
      'Horam',
      'Hopton',
      'Hope under Dinmore',
      'Hopeman',
      'Hope',
      'Hook Norton',
      'Hook',
      'Hoo',
      'Honiton',
      'Holywood',
      'Holywell',
      'Holytown',
      'Holyhead',
      'Holwick',
      'Holton le Clay',
      'Holtby',
      'Holt',
      'Holsworthy',
      'Holmwood',
      'Holmpton',
      'Holmfirth',
      'Holmes Chapel',
      'Hollym',
      'Holbrook',
      'Holborn',
      'Holbeach',
      'Hoddesdon',
      'Hockwold cum Wilton',
      'Hockliffe',
      'Hockley',
      'Hitchin',
      'Histon',
      'Hirwaun',
      'Hinton Charterhouse',
      'Hinton',
      'Hingham',
      'Hindon',
      'Hindley',
      'Hindhead',
      'Hinckley',
      'Hilton',
      'Hillside',
      'Hillsborough',
      'Hillingdon',
      'Hill',
      'High Wycombe',
      'Highworth',
      'High Valleyfield',
      'Hightown',
      'High Ongar',
      'Highley',
      'High Legh',
      'High Halstow',
      'High Halden',
      'Highgate',
      'High Etherley',
      'High Coniscliffe',
      'Highclere',
      'Highbury',
      'Highbridge',
      'High Blantyre',
      'High Bentham',
      'Higham Ferrers',
      'Hibaldstow',
      'Heysham',
      'Hexham',
      'Hetton-Le-Hole',
      'Hethersett',
      'Heswall',
      'Heston',
      'Hessle',
      'Heslington',
      'Hertford',
      'Herstmonceux',
      'Herne Bay',
      'Hermitage',
      'Hereford',
      'Herbrandston',
      'Henstridge',
      'Henlow',
      'Henllan',
      'Henley-on-Thames',
      'Henley in Arden',
      'Hengoed',
      'Henfield',
      'Hendon',
      'Hemyock',
      'Hemsworth',
      'Hemsby',
      'Hemingford Grey',
      'Hemingbrough',
      'Hemel Hempstead',
      'Helston',
      'Helsby',
      'Helpston',
      'Helmsley',
      'Hellifield',
      'Helland',
      'Helensburgh',
      'Heighington',
      'Hedon',
      'Heddon on the Wall',
      'Heckmondwike',
      'Heckington',
      'Hebron',
      'Hebden Bridge',
      'Hebburn',
      'Heathfield',
      'Heath and Reach',
      'Heanor',
      'Healing',
      'Healey',
      'Heage',
      'Headcorn',
      'Heacham',
      'Hazel Grove',
      'Haywards Heath',
      'Hayton',
      'Haynes',
      'Hayle',
      'Hayfield',
      'Hayes',
      'Haydon Bridge',
      'Haydock',
      'Hay',
      'Haxey',
      'Haxby',
      'Hawthorn',
      'Haworth',
      'Hawkinge',
      'Hawkhurst',
      'Hawick',
      'Hawarden',
      'Haverigg',
      'Haverhill',
      'Haverfordwest',
      'Havant',
      'Haughley',
      'Hatton',
      'Hathersage',
      'Hathern',
      'Hatherleigh',
      'Hatfield Peverel',
      'Hatfield Heath',
      'Hatfield',
      'Haswell',
      'Hastings',
      'Hassocks',
      'Haslingfield',
      'Haslingden',
      'Haslemere',
      'Harwich',
      'Harwell',
      'Harvington',
      'Hartwell',
      'Hartshill',
      'Hartley Wintney',
      'Hartley',
      'Hartlepool',
      'Hartlebury',
      'Harthill',
      'Hartford',
      'Hartburn',
      'Harston',
      'Harrow on the Hill',
      'Harrow',
      'Harrold',
      'Harrogate',
      'Harrietsham',
      'Harpole',
      'Harpenden',
      'Harlow',
      'Harlington',
      'Harley',
      'Harleston',
      'Harlesden',
      'Harlech',
      'Harefield',
      'Hardingstone',
      'Harbury',
      'Hapton',
      'Hanworth',
      'Hanslope',
      'Hannington',
      'Hankerton',
      'Handcross',
      'Hamsterley',
      'Hampton in Arden',
      'Hampton',
      'Hammersmith',
      'Hamilton',
      'Hambleton',
      'Hamble-le-Rice',
      'Haltwhistle',
      'Halton',
      'Halstead',
      'Halsham',
      'Hallow',
      'Halling',
      'Halkyn',
      'Halkirk',
      'Halifax',
      'Halesworth',
      'Halesowen',
      'Hale',
      'Hailsham',
      'Haigh',
      'Hagley',
      'Hadlow',
      'Hadley',
      'Hadleigh',
      'Haddington',
      'Haddenham',
      'Hackney',
      'Hackleton',
      'Gurnard',
      'Gunnislake',
      'Gunness',
      'Gullane',
      'Guiseley',
      'Guisborough',
      'Guilsfield',
      'Guildford',
      'Grundisburgh',
      'Grove',
      'Grosmont',
      'Groombridge',
      'Griston',
      'Grindon',
      'Grindale',
      'Grimston',
      'Grimsby',
      'Greyabbey',
      'Gretna',
      'Gresford',
      'Greetham',
      'Greenock',
      'Greenisland',
      'Greenhithe',
      'Greenhead',
      'Greenford',
      'Greenfield',
      'Great Yeldham',
      'Great Yarmouth',
      'Great Wyrley',
      'Great Waldingfield',
      'Great Wakering',
      'Great Torrington',
      'Great Sankey',
      'Great Paxton',
      'Great Ness',
      'Great Missenden',
      'Great Malvern',
      'Great Leighs',
      'Great Houghton',
      'Great Horwood',
      'Great Horkesley',
      'Great Harwood',
      'Great Hanwood',
      'Greatham',
      'Great Gransden',
      'Great Gonerby',
      'Great Glen',
      'Great Eccleston',
      'Great Dunmow',
      'Driffield',
      'Great Coates',
      'Great Chesterford',
      'Great Bookham',
      'Great Bentley',
      'Great Bedwyn',
      'Great Barton',
      'Great Barford',
      'Great Bardfield',
      'Great Ayton',
      'Great Amwell',
      'Grays',
      'Gravesend',
      'Gravenhurst',
      'Grassington',
      'Grantown on Spey',
      'Grantham',
      'Grangemouth',
      'Grain',
      'Goxhill',
      'Govilon',
      'Gourock',
      'Goudhurst',
      'Gotham',
      'Gosport',
      'Gosforth',
      'Gosfield',
      'Gosberton',
      'Gorseinon',
      'Gorleston-on-Sea',
      'Goring-by-Sea',
      'Goring',
      'Gorebridge',
      'Goostrey',
      'Goosnargh',
      'Goole',
      'Goodwick',
      'Goodmayes',
      'Golspie',
      'Goldcliff',
      'Golborne',
      'Godstone',
      'Godshill',
      'Godmanchester',
      'Godalming',
      'Gobowen',
      'Gnosall',
      'Glyn-neath',
      'Glyncorrwg',
      'Glusburn',
      'Gloucester',
      'Glossop',
      'Glinton',
      'Glenrothes',
      'Glenfield',
      'Glenboig',
      'Glenavy',
      'Glenariff',
      'Glemsford',
      'Glazebury',
      'Glastonbury',
      'Glasgow',
      'Glanamman',
      'Girvan',
      'Girton',
      'Gilwern',
      'Gillingham',
      'Gilford',
      'Gilfach Goch',
      'Gildersome',
      'Gilberdyke',
      'Giffnock',
      'Germoe',
      'Gelligaer',
      'Gedney Hill',
      'Gedling',
      'Geddington',
      'Gayhurst',
      'Gateshead',
      'Garvagh',
      'Garswood',
      'Garstang',
      'Garsington',
      'Gargrave',
      'Garforth',
      'Garelochhead',
      'Gamlingay',
      'Galston',
      'Galgate',
      'Galashiels',
      'Gainsborough',
      'Gainford',
      'Gaerwen',
      'Fylde',
      'Fyfield',
      'Fulwood',
      'Full Sutton',
      'Fulham',
      'Fulford',
      'Fulbourn',
      'Frome',
      'Frodsham',
      'Frizington',
      'Friston',
      'Friockheim',
      'Frinton-on-Sea',
      'Frimley',
      'Friern Barnet',
      'Freystrop',
      'Freuchie',
      'Freshwater',
      'Freshford',
      'Fremington',
      'Freckleton',
      'Fraserburgh',
      'Frampton on Severn',
      'Framlingham',
      'Fowlmere',
      'Fowey',
      'Four Marks',
      'Four Crosses',
      'Foulsham',
      'Foulridge',
      'Fort William',
      'Fortuneswell',
      'Fortrose',
      'Forth',
      'Forres',
      'Formby',
      'Forfar',
      'Forest Row',
      'Forest Hill',
      'Fordingbridge',
      'Fordham',
      'Ford',
      'Folkestone',
      'Fochriw',
      'Fochabers',
      'Flore',
      'Flookburgh',
      'Flockton',
      'Flitwick',
      'Flint',
      'Flimby',
      'Fleetwood',
      'Fleet',
      'Flamborough',
      'Fivemiletown',
      'Fitzwilliam',
      'Fishtoft',
      'Fishguard',
      'Fishburn',
      'Fishbourne',
      'Fintona',
      'Finningley',
      'Finedon',
      'Findon',
      'Findochty',
      'Findern',
      'Finchley',
      'Finchampstead',
      'Fimber',
      'Filey',
      'Ffestiniog',
      'Ferryhill',
      'Ferrybridge',
      'Fernhurst',
      'Fernhill Heath',
      'Ferndale',
      'Fenwick',
      'Fenstanton',
      'Feltwell',
      'Felton',
      'Feltham',
      'Felling',
      'Felixstowe',
      'Felbridge',
      'Featherstone',
      'Fazeley',
      'Faversham',
      'Fauldhouse',
      'Farnworth',
      'Farnsfield',
      'Farnham Royal',
      'Farnham',
      'Farndon',
      'Farnborough',
      'Faringdon',
      'Fareham',
      'Falmouth',
      'Falkland',
      'Falkirk',
      'Falfield',
      'Fakenham',
      'Fairlight',
      'Fairlie',
      'Fairford',
      'Eyton upon the Weald Moors',
      'Eyton',
      'Eythorne',
      'Eynsham',
      'Eynsford',
      'Eyemouth',
      'Eye',
      'Exton',
      'Exning',
      'Exmouth',
      'Exminster',
      'Exhall',
      'Exeter',
      'Ewyas Harold',
      'Ewhurst',
      'Evington',
      'Evesham',
      'Evesbatch',
      'Everton',
      'Eversholt',
      'Evercreech',
      'Evenwood',
      'Evanton',
      'Euxton',
      'Etwall',
      'Etton',
      'Eton',
      'Eston',
      'Essendine',
      'Esher',
      'Errol',
      'Erlestoke',
      'Erith',
      'Epworth',
      'Epsom',
      'Epping',
      'Enniskillen',
      'Enfield Lock',
      'Enfield Town',
      'Endon',
      'Enderby',
      'Emsworth',
      'Empingham',
      'Emneth',
      'Emley',
      'Embsay',
      'Embleton',
      'Emberton',
      'Ely',
      'Elwick',
      'Elvington',
      'Elton',
      'Eltham',
      'Elswick',
      'Elstree',
      'Elstow',
      'Elstead',
      'Elsenham',
      'Elmswell',
      'Ellon',
      'Ellingham',
      'Ellesmere Port',
      'Ellesmere',
      'Ellerton',
      'Ellerker',
      'Elland',
      'Elgin',
      'Elderslie',
      'Egremont',
      'Eglinton',
      'Egham',
      'Eggleston',
      'Edworth',
      'Edwinstowe',
      'Edmonton',
      'Edith Weston',
      'Edington',
      'Edinburgh',
      'Edgworth',
      'Edgware',
      'Edgmond',
      'Edenfield',
      'Edenbridge',
      'Eckington',
      'Eccleston',
      'Eccleshall',
      'Eccles',
      'Ebbw Vale',
      'Eaton Socon',
      'Eaton Bray',
      'Eaton',
      'Eattington',
      'Eastwood',
      'East Wittering',
      'East Wemyss',
      'East Tilbury',
      'Eastry',
      'Eastrington',
      'Retford',
      'East Peckham',
      'Easton on the Hill',
      'Easton',
      'Eastoft',
      'East Molesey',
      'East Markham',
      'East Linton',
      'Eastleigh',
      'East Leake',
      'East Kilbride',
      'East Horsley',
      'East Harptree',
      'East Harling',
      'East Hanney',
      'East Ham',
      'East Grinstead',
      'East Dulwich',
      'East Dereham',
      'East Dean',
      'Eastchurch',
      'East Chevington',
      'East Calder',
      'East Bridgford',
      'Eastbourne',
      'East Boldon',
      'East Bergholt',
      'Easingwold',
      'Easington',
      'Earlswood',
      'Earlston',
      'Earls Court',
      'Earls Colne',
      'Earls Barton',
      'Earley',
      'Earith',
      'Earby',
      'Ealing',
      'Eaglesham',
      'Dyserth',
      'Dymchurch',
      'Dyce',
      'Duxford',
      'Dursley',
      'Durrington',
      'Durnford',
      'Durham',
      'Dunvant',
      'Duntocher',
      'Dunswell',
      'Dunstable',
      'Duns',
      'Dunoon',
      'Dunnington',
      'Dunloy',
      'Dunlop',
      'Dunkeswell',
      'Dunkeld',
      'Dunipace',
      'Dunholme',
      'Dungiven',
      'Dungannon',
      'Dunfermline',
      'Dundry',
      'Dundrum',
      'Dundonald',
      'Dundee',
      'Dunchurch',
      'Dunblane',
      'Dunbar',
      'Dumfries',
      'Dumbarton',
      'Dulverton',
      'Duloe',
      'Dukinfield',
      'Dufftown',
      'Duffield',
      'Dudley',
      'Ducklington',
      'Drybrook',
      'Drumnadrochit',
      'Droylsden',
      'Dronfield',
      'Dromore',
      'Droitwich',
      'Drighlington',
      'Dreghorn',
      'Draycott',
      'Draperstown',
      'Downton',
      'Downside',
      'Downpatrick',
      'Downham Market',
      'Doveridge',
      'Dovercourt',
      'Dover',
      'Dove Holes',
      'Doune',
      'Douglas',
      'Dorstone',
      'Dorridge',
      'Dornoch',
      'Dormansland',
      'Dorking',
      'Dorchester',
      'Donnington',
      'Donisthorpe',
      'Donington',
      'Doncaster',
      'Donaghadee',
      'Dollar',
      'Dolgellau',
      'Dodworth',
      'Doddington',
      'Dobwalls',
      'Doagh',
      'Ditchling',
      'Ditchingham',
      'Distington',
      'Diss',
      'Disley',
      'Dinton',
      'Dinnington',
      'Dinmore',
      'Dingwall',
      'Dinas Powys',
      'Diggle',
      'Didcot',
      'Dickleburgh',
      'Dewsbury',
      'Devizes',
      'Desford',
      'Desborough',
      'Derwen',
      'Dersingham',
      'Deri',
      'Derby',
      'Denton',
      'Denny',
      'Denholme',
      'Denham',
      'Denby Dale',
      'Denbigh',
      'Delph',
      'Delabole',
      'Deighton',
      'Deganwy',
      'Deepcut',
      'Dedham',
      'Deddington',
      'Debenham',
      'Dearham',
      'Deanshanger',
      'Deal',
      'Dawlish',
      'Daventry',
      'Datchworth',
      'Datchet',
      'Darwen',
      'Darvel',
      'Darton',
      'Dartmouth',
      'Dartford',
      'Darrington',
      'Darlington',
      'Darlaston',
      'Darfield',
      'Daresbury',
      'Darenth',
      'Danbury',
      'Dalton in Furness',
      'Dalston',
      'Dalserf',
      'Dalrymple',
      'Dalry',
      'Dalmellington',
      'Dalkeith',
      'Dale',
      'Dalbeattie',
      'Dagenham',
      'Cwmbran',
      'Cwmafan',
      'Cwm',
      'Cuxton',
      'Cushendall',
      'Curry Rivel',
      'Currie',
      'Cupar',
      'Cumnock',
      'Cumbernauld',
      'Cults',
      'Culmore',
      'Cullybackey',
      'Cullompton',
      'Cullingworth',
      'Cullen',
      'Culcheth',
      'Cuffley',
      'Cudworth',
      'Cuddington',
      'Cuckfield',
      'Crynant',
      'Crymych',
      'Crumlin',
      'Croydon',
      'Croxton',
      'Crowthorne',
      'Crowle',
      'Crowland',
      'Fair Oak',
      'Crowborough',
      'Croston',
      'Crossmaglen',
      'Crosskeys',
      'Crosshouse',
      'Cross Hands',
      'Crossgates',
      'Crossgar',
      'Crossford',
      'Cropwell Bishop',
      'Crook',
      'Crondall',
      'Cromford',
      'Cromer',
      'Crofton',
      'Croft',
      'Cringleford',
      'Crigglestone',
      'Crieff',
      'Cricklewood',
      'Cricklade',
      'Crickhowell',
      'Crick',
      'Criccieth',
      'Crewkerne',
      'Crewe',
      'Creswell',
      'Cresswell',
      'Creech Saint Michael',
      'Crediton',
      'Credenhill',
      'Cray',
      'Crawley',
      'Craven Arms',
      'Cranwell',
      'Cranleigh',
      'Cranford',
      'Cranfield',
      'Cranbrook',
      'Cramlington',
      'Crail',
      'Coylton',
      'Coxhoe',
      'Cowpen',
      'Cowley',
      'Cowie',
      'Cowfold',
      'Cowes',
      'Cowdenbeath',
      'Cowbridge',
      'Cowbit',
      'Coventry',
      'Cove',
      'Coupar Angus',
      'Countesthorpe',
      'Coundon',
      'Coulsdon',
      'Cottingham',
      'Cottesmore',
      'Cottenham',
      'Cotgrave',
      'Costessey',
      'Cosham',
      'Cosby',
      'Corston',
      'Corsley',
      'Corsham',
      'Corsenside',
      'Corse',
      'Corris',
      'Cornholme',
      'Corfe Castle',
      'Corby',
      'Corbridge',
      'Coppull',
      'Copplestone',
      'Copmanthorpe',
      'Cople',
      'Cooling',
      'Cookstown',
      'Cookley',
      'Cookham',
      'Conwy',
      'Consett',
      'Conon Bridge',
      'Connor',
      'Conisbrough',
      'Coningsby',
      'Congresbury',
      'Congleton',
      'Comrie',
      'Compton Martin',
      'Compton Dando',
      'Compton',
      'Comberton',
      'Comber',
      'Combe Martin',
      'Colyton',
      'Colwyn Bay',
      'Colwich',
      'Coltishall',
      'Colsterworth',
      'Colne',
      'Colnbrook',
      'Colmworth',
      'Collington',
      'Collingham',
      'Collingbourne Kingston',
      'Colinton',
      'Colerne',
      'Coleraine',
      'Coleford',
      'Coldstream',
      'Cold Norton',
      'Cold Ashton',
      'Cold Ash',
      'Colchester',
      'Coity',
      'Coggeshall',
      'Cogenhoe',
      'Coedpoeth',
      'Codsall',
      'Codicote',
      'Codford',
      'Coddington',
      'Cockfield',
      'Cockermouth',
      'Cockenzie',
      'Cobham',
      'Coates',
      'Coatbridge',
      'Coalville',
      'Coalisland',
      'Coalburn',
      'Clydebank',
      'Clydach',
      'Clutton',
      'Clowne',
      'Clophill',
      'Clive',
      'Clitheroe',
      'Clipsham',
      'Clifton',
      'Clifford',
      'Cliffe',
      'Cleveleys',
      'Clevedon',
      'Cleobury Mortimer',
      'Clenchwarton',
      'Cleland',
      'Cleethorpes',
      'Cleckheaton',
      'Cleator Moor',
      'Clayton West',
      'Clayton le Moors',
      'Clayton',
      'Claypole',
      'Claydon',
      'Clare',
      'Clarborough',
      'Clapham',
      'Clacton-on-Sea',
      'Clackmannan',
      'Cirencester',
      'Cinderford',
      'Church Stretton',
      'Churchill',
      'Honeybourne',
      'Church Fenton',
      'Churchdown',
      'Church',
      'Chulmleigh',
      'Chudleigh Knighton',
      'Chudleigh',
      'Chryston',
      'Christchurch',
      'Chorleywood',
      'Chorley',
      'Choppington',
      'Cholsey',
      'Chobham',
      'Chiswick',
      'Chislehurst',
      'Chirnside',
      'Chirk',
      'Chipstead',
      'Chipping Sodbury',
      'Chipping Ongar',
      'Chipping Norton',
      'Chipping Campden',
      'Chippenham',
      'Chinnor',
      'Chinley',
      'Chingford',
      'Chilworth',
      'Chilton Foliat',
      'Child Okeford',
      'Chilcompton',
      'Chigwell',
      'Chiddingfold',
      'Chicksands',
      'Chicklade',
      'Chickerell',
      'Chichester',
      'Chicheley',
      'Chew Magna',
      'Chetwynd',
      'Chester-le-Street',
      'Chesterfield',
      'Chester',
      'Chessington',
      'Cheshunt',
      'Chesham',
      'Chertsey',
      'Cherry Burton',
      'Chepstow',
      'Cheltenham',
      'Chelsfield',
      'Chelsea',
      'Chelmsford',
      'Chelford',
      'Cheddleton',
      'Cheddington',
      'Cheddar',
      'Cheam',
      'Cheadle Hulme',
      'Cheadle',
      'Chatteris',
      'Chatham',
      'Chatburn',
      'Chasetown',
      'Chartham',
      'Charmouth',
      'Charminster',
      'Charlton Marshall',
      'Charlton Kings',
      'Charlton',
      'Charlbury',
      'Charing',
      'Charfield',
      'Chard',
      'Chapeltown',
      'Chapel Saint Leonards',
      'Chapelhall',
      'Chapel en le Frith',
      'Chalgrove',
      'Chalford',
      'Chalfont Saint Peter',
      'Chalfont St Giles',
      'Chale',
      'Chagford',
      'Chacewater',
      'Cemaes Bay',
      'Caythorpe',
      'Cawthorne',
      'Cawston',
      'Cawood',
      'Catterick',
      'Catterall',
      'Catrine',
      'Caton',
      'Catford',
      'Caterham',
      'Castor',
      'Castlewellan',
      'Castlethorpe',
      'Castleside',
      'Castlerock',
      'Castlereagh',
      'Castlemartin',
      'Castle Hedingham',
      'Castleford',
      'Castle Douglas',
      'Castle Donington',
      'Castlederg',
      'Castledawson',
      'Castle Cary',
      'Carterton',
      'Carshalton',
      'Carryduff',
      'Carron',
      'Carrington',
      'Carrickfergus',
      'Carnwath',
      'Carnoustie',
      'Carnmoney',
      'Carnlough',
      'Carnforth',
      'Carnaby',
      'Carmunnock',
      'Carmarthen',
      'Carluke',
      'Carlton',
      'Carlisle',
      'Carfin',
      'Cardross',
      'Cardington',
      'Cardigan',
      'Cardiff',
      'Cardenden',
      'Carcroft',
      'Capel Saint Mary',
      'Capel',
      'Cantley',
      'Canterbury',
      'Cannock',
      'Canewdon',
      'Camrose',
      'Campsall',
      'Campbeltown',
      'Camerton',
      'Camelford',
      'Cambuslang',
      'Cambridge',
      'Camborne',
      'Camberley',
      'Calverton',
      'Calne',
      'Callow',
      'Callington',
      'Callander',
      'Caldicot',
      'Caldercruix',
      'Caistor',
      'Caister-on-Sea',
      'Cairnryan',
      'Cairneyhill',
      'Caerwent',
      'Caerphilly',
      'Caernarfon',
      'Caerleon',
      'Caergwrle',
      'Cadnam',
      'Caddington',
      'Byfield',
      'Buxton',
      'Buxted',
      'Butterwick',
      'Butcombe',
      'Bushmills',
      'Bushey',
      'Busby',
      'Bury St Edmunds',
      'Bury',
      'Burwell',
      'Burwash',
      'Burtonwood',
      'Burton upon Trent',
      'Burton upon Stather',
      'Burton Pidsea',
      'Burton on the Wolds',
      'Burton Latimer',
      'Burton Joyce',
      'Burton Constable',
      'Burton',
      'Burstwick',
      'Bursledon',
      'Burscough',
      'Burry Port',
      'Burrington',
      'Burringham',
      'Burntwood',
      'Burntisland',
      'Burnopfield',
      'Burnley',
      'Burniston',
      'Burnham-on-Sea',
      'Burnham-on-Crouch',
      'Burneside',
      'Burley in Wharfedale',
      'Burley',
      'Burham',
      'Burgh le Marsh',
      'Burghead',
      'Burgh by Sands',
      'Burgess Hill',
      'Burford',
      'Bures Saint Mary',
      'Burbage',
      'Buntingford',
      'Bungay',
      'Bunbury',
      'Bulkington',
      'Bulford',
      'Builth Wells',
      'Bugthorpe',
      'Bugle',
      'Bugbrooke',
      'Budleigh Salterton',
      'Bude',
      'Bucknell',
      'Buckley',
      'Buckingham',
      'Buckie',
      'Buckhurst Hill',
      'Buckhaven',
      'Buckfastleigh',
      'Buckden',
      'Bubwith',
      'Brynmawr',
      'Bryneglwys',
      'Brynamman',
      'Bryn',
      'Brymbo',
      'Bruton',
      'Brundall',
      'Broxburn',
      'Broxbourne',
      'Brownhills',
      'Broughton Astley',
      'Broughton',
      'Broughshane',
      'Brough',
      'Brotton',
      'Broseley',
      'Brora',
      'Broomfleet',
      'Brooke',
      'Bromyard',
      'Bromsgrove',
      'Brompton',
      'Bromley',
      'Bromham',
      'Bromfield',
      'Bromborough',
      'Brockley',
      'Brockhampton',
      'Brockenhurst',
      'Broadway',
      'Broadwater',
      'Broadstone',
      'Broadstairs',
      'Broad Blunsdon',
      'Brixworth',
      'Brixton',
      'Brixham',
      'Briton Ferry',
      'Briston',
      'Bristol',
      'Brinscall',
      'Brinklow',
      'Brimscombe',
      'Brill',
      'Brigstock',
      'Brignall',
      'Brighton',
      'Brightlingsea',
      'Brighstone',
      'Brighouse',
      'Brigg',
      'Brierley Hill',
      'Brierfield',
      'Bridport',
      'Bridlington',
      'Bridgwater',
      'Bridgnorth',
      'Bridge of Weir',
      'Bridge of Earn',
      'Bridge of Allan',
      'Bridgend',
      'Bridge',
      'Brewood',
      'Brentwood',
      'Brent Knoll',
      'Brentford',
      'Brent',
      'Brenchley',
      'Bremhill',
      'Bredon',
      'Bredbury',
      'Brecon',
      'Brechin',
      'Bream',
      'Brayton',
      'Bray',
      'Braunton',
      'Braunston',
      'Bratton',
      'Brantingham',
      'Branston',
      'Bransgore',
      'Brandon',
      'Brandesburton',
      'Brampton',
      'Bramley',
      'Bramhope',
      'Bramham',
      'Bramhall',
      'Bramford',
      'Braintree',
      'Bradwell',
      'Bradninch',
      'Bradley',
      'Brading',
      'Bradford-on-Avon',
      'Bradford',
      'Bradfield',
      'Bracknell',
      'Brackley',
      'Bozeat',
      'Boyton',
      'Boynton',
      'Boxted',
      'Boxgrove',
      'Box',
      'Bow Street',
      'Bowes',
      'Bower Chalke',
      'Bowdon',
      'Bow Brickhill',
      'Bow',
      'Bovington Camp',
      'Bovingdon',
      'Bovey Tracey',
      'Bourton on the Water',
      'Bourton',
      'Bournemouth',
      'Bourne End',
      'Bourne',
      'Boughton',
      'Bottisham',
      'Bottesford',
      'Botley',
      'Bothwell',
      'Botesdale',
      'Boston Spa',
      'Boston',
      'Bosham',
      'Borth',
      'Borrowash',
      'Borough Green',
      'Boroughbridge',
      'Borehamwood',
      'Boreham',
      'Bootle',
      'Boosbeck',
      'Bonnyrigg',
      'Bonnybridge',
      'Bonhill',
      'Bo’ness',
      'Bolton upon Dearne',
      'Bolton le Sands',
      'Bolton',
      'Bolsover',
      'Bollington',
      'The Boldons',
      'Bognor Regis',
      'Bodmin',
      'Bodenham',
      'Bodelwyddan',
      'Bodedern',
      'Boddam',
      'Blyton',
      'Blyth',
      'Bluntisham',
      'Blunham',
      'Bloxwich',
      'Bloxham',
      'Blockley',
      'Blisworth',
      'Blindley Heath',
      'Blidworth',
      'Blewbury',
      'Bletchley',
      'Bletchingley',
      'Blean',
      'Bleadon',
      'Blaydon-on-Tyne',
      'Blandford Forum',
      'Blairgowrie',
      'Blagdon',
      'Blaenavon',
      'Blaenau-Ffestiniog',
      'Blackwood',
      'Blackwell',
      'Blackwall',
      'Blackrod',
      'Blackpool',
      'Black Notley',
      'Blackburn',
      'Blaby',
      'Bitton',
      'Bishton',
      'Bishopton',
      'Bishopthorpe',
      'Bishops Waltham',
      'Bishopstrow',
      'Bishopstone',
      'Bishopston',
      'Bishopstoke',
      'Bishopsteignton',
      'Bishops Stortford',
      'Bishops Lydeard',
      'Bishops Cleeve',
      "Bishop's Castle",
      'Bishop Middleham',
      'Bishopbriggs',
      'Bishop Auckland',
      'Bisham',
      'Birtley',
      'Birmingham',
      'Birkenhead',
      'Birdwell',
      'Birdham',
      'Bircotes',
      'Birchington-on-Sea',
      'Bingley',
      'Bingham',
      'Binfield',
      'Bilston',
      'Bilsthorpe',
      'Billingshurst',
      'Billinghay',
      'Billingham',
      'Billinge',
      'Billingborough',
      'Billericay',
      'Bildeston',
      'Biggleswade',
      'Biggin Hill',
      'Biggar',
      'Bierton',
      'Bidford-on-Avon',
      'Bideford',
      'Biddulph',
      'Biddestone',
      'Biddenham',
      'Biddenden',
      'Bicton',
      'Bicknacre',
      'Bickley',
      'Bickenhill',
      'Bicester',
      'Bexleyheath',
      'Bexley',
      'Bexhill-on-Sea',
      'Bewdley',
      'Beverley',
      'Betws-y-Coed',
      'Betws',
      'Betley',
      'Bethesda',
      'Berwick-Upon-Tweed',
      'Berrington',
      'Berkswell',
      'Berkhamsted',
      'Berkeley',
      'Bere Regis',
      'Bere Alston',
      'Bentley',
      'Benson',
      'Bempton',
      'Bembridge',
      'Belvedere',
      'Belton',
      'Belper',
      'Bellshill',
      'Bellingham',
      'Bellaghy',
      'Belford',
      'Belfast',
      'Belbroughton',
      'Beith',
      'Beighton',
      'Beeston',
      'Beeford',
      'Bedworth',
      'Bedwas',
      'Bedlington',
      'Bedford',
      'Bedale',
      'Beckingham',
      'Beckenham',
      'Beccles',
      'Bebington',
      'Beaumaris',
      'Beauly',
      'Bearsden',
      'Beaminster',
      'Beadnell',
      'Beaconsfield',
      'Bayston Hill',
      'Bawtry',
      'Battlesden',
      'Battle',
      'Batley',
      'Bathgate',
      'Bathford',
      'Bath',
      'Baston',
      'Bassingham',
      'Bassingbourn',
      'Baslow',
      'Basingstoke',
      'Old Basing',
      'Basildon',
      'Upper Basildon',
      'Basford',
      'Baschurch',
      'Barton upon Humber',
      'Barton under Needwood',
      'Barton-le-Clay',
      'Barton on Sea',
      'Barston',
      'Barry',
      'Barrow upon Soar',
      'Barrow upon Humber',
      'Barrow in Furness',
      'Barrowford',
      'Barrowby',
      'Barrhead',
      'Barnt Green',
      'Barnstaple',
      'Barnsley',
      'Barnoldswick',
      'Barningham',
      'Barnham',
      'Barnetby le Wold',
      'Barnet',
      'Barnes',
      'Barnburgh',
      'Barnard Castle',
      'Barnack',
      'Barmston',
      'Barmouth',
      'Barmby on the Marsh',
      'Barlestone',
      'Barlborough',
      'Barlaston',
      'Barkisland',
      'Barking',
      'Barkham',
      'Bargoed',
      'Bardsey',
      'Bardon Mill',
      'Bardney',
      'Banwell',
      'Banstead',
      'Bannockburn',
      'Banks',
      'Bankfoot',
      'Banham',
      'Bangor',
      'Banff',
      'Banchory',
      'Banbury',
      'Banbridge',
      'Bampton',
      'Bamburgh',
      'Balsham',
      'Balmullo',
      'Balmedie',
      'Ballywalter',
      'Ballynahinch',
      'Ballymoney',
      'Ballymena',
      'Ballykelly',
      'Ballygowan',
      'Ballyclare',
      'Ballycastle',
      'Balloch',
      'Ballingry',
      'Ballinamallard',
      'Ballater',
      'Balintore',
      'Balham',
      'Balfron',
      'Balerno',
      'Baldock',
      'Balcombe',
      'Bala',
      'Bakewell',
      'Bainton',
      'Baildon',
      'Bagworth',
      'Bagshot',
      'Bagillt',
      'Badsworth',
      'Badsey',
      'Bacup',
      'Bacton',
      'Backworth',
      'Ayr',
      'Aylsham',
      'Aylesford',
      'Aylesbury',
      'Axminster',
      'Axbridge',
      'Awsworth',
      'Aviemore',
      'Avening',
      'Aveley',
      'Avebury',
      'Aughnacloy',
      'Audley',
      'Audlem',
      'Auckley',
      'Auchtermuchty',
      'Auchterarder',
      'Auchinleck',
      'Atworth',
      'Atwick',
      'Attleborough',
      'Atherton',
      'Atherstone',
      'Astwood Bank',
      'Astwood',
      'Astwick',
      'Aston Clinton',
      'Aston',
      'Astley',
      'Aspley Guise',
      'Aspatria',
      'Aslockton',
      'Askham Richard',
      'Askern',
      'Askam in Furness',
      'Ashwell',
      'Ash Vale',
      'Ashurst',
      'Ashton-under-Lyne',
      'Ashton Keynes',
      'Ashton in Makerfield',
      'Ashtead',
      'Ashley',
      'Ashington',
      'Ashill',
      'Ashford',
      'Ashby-de-la-Zouch',
      'Ashburton',
      'Ashbourne',
      'Ash',
      'Ascot',
      'Arundel',
      'Arnside',
      'Arnold',
      'Arne',
      'Armthorpe',
      'Armitage',
      'Armagh',
      'Armadale',
      'Arlesey',
      'Ardrossan',
      'Ardrishaig',
      'Ardingly',
      'Ardglass',
      'Ardersier',
      'Arbroath',
      'Appley Bridge',
      'Appleton',
      'Appledore',
      'Appleby',
      'Antrim',
      'Anstruther',
      'Anstey',
      'Ansty',
      'Annfield Plain',
      'Annan',
      'Annalong',
      'Annahilt',
      'Andover',
      'Ancroft',
      'Ancaster',
      'Ampthill',
      'Ammanford',
      'Amlwch',
      'Amesbury',
      'Amersham',
      'Ambrosden',
      'Ambleside',
      'Amble',
      'Alyth',
      'Alveston',
      'Alveley',
      'Alvechurch',
      'Alva',
      'Altrincham',
      'Alton',
      'Althorne',
      'Alston',
      'Alsager',
      'Alrewas',
      'Alresford',
      'Alnwick',
      'Alnmouth',
      'Alness',
      'Almondsbury',
      'Almondbank',
      'Alloa',
      'Allington',
      'Allhallows',
      'Allanton',
      'Alfreton',
      'Alford',
      'Alexandria',
      'Aldridge',
      'Aldford',
      'Aldershot',
      'Alderley Edge',
      'Alderholt',
      'Alderbury',
      'Aldeburgh',
      'Aldbrough',
      'Aldbourne',
      'Alconbury',
      'Alcester',
      'Albrighton',
      'Airth',
      'Airmyn',
      'Airdrie',
      'Ahoghill',
      'Adwick le Street',
      'Adlington',
      'Addlestone',
      'Addingham',
      'Adderbury',
      'Acton',
      'Acomb',
      'Acocks Green',
      'Acle',
      'Acklington',
      'Accrington',
      'Abram',
      'Aboyne',
      'Abingdon',
      'Aberystwyth',
      'Abertillery',
      'Aberporth',
      'Abernethy',
      'Abernant',
      'Charlestown of Aberlour',
      'Aberlady',
      'Aberkenfig',
      'Abergele',
      'Abergavenny',
      'Aberford',
      'Aberfeldy',
      'Aberfan',
      'Aberdour',
      'Aberdeen',
      'Aberdare',
      'Abercynon',
      'Aberchirder',
      'Abercarn',
      'Abercanaid',
      'Aberaeron',
      'Aber',
      'Abbotts Ann',
      'Abbots Langley',
      'Abbotskerswell',
      'Abbots Bromley',
      'Abbey Dore',
      'Benllech',
      'Crosby',
      'Overcombe',
      'Lynemouth',
      'Hadston',
      'East Cowes',
      'Shiremoor',
      'Looe',
      'Pensilva',
      'Flexbury',
      'Steynton',
      'Hundleton',
      'Glandwr',
      'Trimsaran',
      'Tumble',
      'Crofty',
      'Pennard',
      'Three Crosses',
      'Grovesend',
      'Pontlliw',
      'Ambleston',
      'Scleddau',
      'Croeserw',
      'Pont Rhyd-y-cyff',
      'Price Town',
      'Ogmore Vale',
      'Tonypandy',
      'Clydach Vale',
      'Cefn Cribwr',
      'Merthyr Mawr',
      'Brynna',
      'Abertridwr',
      'Beddau',
      'Cwmbach',
      'Blaengwynfi',
      'New Inn',
      'Rogiet',
      'Eastington',
      'Whitley',
      'Woolavington',
      'Kewstoke',
      'Pucklechurch',
      'Peasedown Saint John',
      'Severn Beach',
      'Easton-in-Gordano',
      'Bishop Sutton',
      'Chapmanslade',
      'Camden Town',
      'Camberwell',
      'Telford',
      'Craigavon',
      'Caol',
      'Wideopen',
      'Hazlerigg',
      'Lamesley',
      'Walton',
      'East Keswick',
      'Shadwell',
      'Barlby',
      'Bar Hill',
      'Camber',
      'Winchelsea Beach',
      'Kesgrave',
      'Blackmoorfoot',
      'Meltham Mills',
      'Polzeath',
      'Rock',
      'Camblesforth',
      'Fontwell',
      'Sharlston',
      'Bayswater',
      'Lambeth',
      'Tywyn',
      'West End of London',
      'Tankerton',
      'Thorpe Hamlet',
      'Cheadle Heath',
      'Cameley',
      'Bradley Cross',
      'South Chailey',
      'Copthorne',
      'Yateley',
      'Oakmere',
      'East Ayton',
      'Westcliff-on-Sea',
      'Didsbury',
      'Hellaby',
      'Langstone',
      'Tintern',
      'Barham',
      'Bowthorpe',
      'Hedge End',
      'Moodiesburn',
      'Virginia Water',
      'Churt',
      'Danby',
      'Wychbold',
      'Gerrards Cross',
      'Maidenbower',
      'Furnace Green',
      'Broadfield',
      'Bewbush',
      'Gossops Green',
      'Langley Green',
      'St. Buryan',
      'Erskine',
      'Bidford-on-avon',
      'Westhill',
      'Clarkston',
      'Smithton',
      'Kings Hill',
      'Crouch End',
      'Clerkenwell',
      'Belsize Park',
      'Chalk Farm',
      "King's Cross",
      'Barnsbury',
      'Little Chalfont',
      'Amersham on the Hill',
      'Barbican',
      'Battersea',
      'Grange Hill',
      'Collier Row',
      'Elm Park',
      'Cranham',
      'Chafford Hundred',
      'Chadwell St Mary',
      'Culverstone Green',
      'Mottingham',
      'Tooting',
      'Tolworth',
      'Kingston upon Thames',
      'Ditton Hill',
      'Hinchley Wood',
      'South Croydon',
      'Hornchurch',
      'Hainault',
      'Surbiton',
      'Ewell',
      'Harold Wood',
      'Chadwell Heath',
      'Becontree',
      'Bethnal Green',
      'Cheetham Hill',
      'Failsworth',
      'Crumpsall',
      'Blackley',
      'Radcliffe',
      'Heywood',
      'Hollingworth',
      'Chorlton cum Hardy',
      'Burnage',
      'Fallowfield',
      'Heaton Chapel',
      'Haughton Green',
      'Hulme',
      'Longsight',
      'Wimbledon Park',
      'Heavitree',
      'Ferndown',
      'Clayton-le-Woods',
      'Portland',
      'Canary Wharf',
      'Coatham Mundeville',
      'Billington',
      'Brixton Hill',
      'Nether Heyford',
      'Belmont',
      'Calcot',
      'Leytonstone',
      'Benwell',
      'Earl Shilton',
      'St. Georges',
      'Carden',
      'Bay Horse',
      'Colden Common',
      'Charvil',
      'Essington',
      'Bordon',
      'Knaphill',
      'Lytham St Annes',
      'Curdworth',
      'Chorlton',
      'Little Hallingbury',
      'Hoole',
      'Eight Ash Green',
      'Peaslake',
      'Woodbury',
      'Larkfield',
      'Catcliffe',
      'Hadley Wood',
      'Greenhill',
      'Crystal Palace',
      'Grappenhall',
      'Prenton',
      'Muswell Hill',
      'Silver End',
      'Great Marton',
      'Eaglescliffe',
      'Mottram St. Andrew',
      'Barnwood',
      'Chapel Allerton',
      'Byram',
      'New Marske',
      'Winscombe',
      'Newcastle',
      'Chiseldon',
      'Milton of Campsie',
      'Southchurch Village',
      'Darras Hall',
      'Bodle Street',
      'Babworth',
      'Appleton Thorn',
      'Guilden Sutton',
      'Blacon',
      'Chelmsley Wood',
      'Crawley Down',
      'Roslin',
      'Grange-over-Sands',
      'Cowplain',
      'Ruardean',
      'Canford Heath',
      'High Barnet',
      'Kensal Green',
      'Balsall Common',
      'Eton Wick',
      "St Mary's",
      'Penparcau',
      'Chalton',
      'Husborne Crawley',
      'Blackheath',
      'Stewartby',
      'Kempston Hardwick',
      'Bilsdale',
      'Hameldon Hill',
      'Mendip',
      'Eversley',
      'Y Felinheli',
      "St Mary's Bay",
      'Aylesham',
      'Burpham',
      'Lightwater',
      'Gartcosh',
      'Kelloe',
      'Fetcham',
      'Easington Colliery',
      'Llanrhaeadr-ym-Mochnant',
      'Lower Earley',
      'Isle Of Mull',
      'Iver Heath',
      'Bartley Green',
      'Grimethorpe',
      'Abbey Wood',
      'Ovington',
      'Summerhouse',
      'Seer Green',
      'Golders Green',
      'Elmstead Market',
      'Danderhall',
      'Denmead',
      'Nanpean',
      'Cross Hills',
      'Canvey Island',
      'Shotts',
      'North Duffield',
      'Earlsfield',
      'Eastriggs',
      'Westergate',
      'Peacehaven',
      'North Baddesley',
      'Bedlinog',
      'Appleby-in-Westmorland',
      'Bracebridge Heath',
      'Glapwell',
      'Asfordby',
      'Letchworth Garden City',
      'Pevensey Bay',
      'Peckham',
      'Cradley Heath',
      'Stockton Heath',
      'Waterloo',
      'Pontyclun',
      'Pentre',
      'Catterick Garrison',
      'Maple Cross',
      'Rossendale',
      'Thornton-Cleveleys',
      'Deeside',
      'High Peak',
      'Romney Marsh',
      'Hayling Island',
      'Isle of Lewis',
      'Isle of Bute',
      'Hope Valley',
      'Isle of Arran',
      'Stoke-sub-Hamdon',
      'Tintagel',
      'Isle of North Uist',
      'Isle of South Uist',
      'Menstrie',
      'Isle of Cumbrae',
      'Isles of Scilly',
      'Rosewell',
      'Isle of Islay',
      'Dyffryn Ardudwy',
      'Merriott',
      'Barra',
      'Benbecula',
      'Holloway',
      'Ballintoy Harbour',
      'Penyffordd',
      'Water Eaton',
      'Harringay',
      'Sonning Common',
      'Osterley',
      'Four Lanes',
      'Dalgety Bay',
      'Tweedbank',
      'Abridge',
      'Lower Broadheath',
      'Chopwell',
      'Cambourne',
      'Moreton Jeffries',
      'Norbiton',
      'Perranarworthal',
      'Great Corby',
      'Mount Hawke',
      'Anna Valley',
      'Hampton Wick',
      'Lee',
      'Stanton Drew',
      'Bishopsworth',
      'Hoveton',
      'Greenham',
      'Bridge Sollers',
      'Deiniolen',
      'Burgh Heath',
      'Mayford',
      'Old Woking',
      'Frimley Green',
      'Sheerwater',
      'Tidbury Green',
      'Dickens Heath',
      'Cheswick Green',
      'Five Oak Green',
      'Highams Park',
      'Town Row',
      'Ynysybwl',
      'Wormley',
      'Windygates',
      'Swarthmoor',
      'Talysarn',
      'Carronshore',
      'West Kingsdown',
      'Bellsbank',
      'Rusthall',
      'Logan',
      'East Rainton',
      'Capel le Ferne',
      'Wood Street Village',
      'Fairlands',
      'Wolvercote',
      'Stratton',
      'Gronant',
      'Shortlands',
      'Hockley Heath',
      'St Just',
      'Fleckney',
      'Aston-on-Trent',
      'Ravenshead',
      'Flimwell',
      'Houghton',
      'Bulphan',
      'Holywell Green',
      'Keyworth',
      'Cockington',
      'Culloden',
      'Greasby',
      'Ailsworth',
      'Monkton Farleigh',
      'Bowburn',
      'Canning Town',
      'Great Haywood',
      'Manor Park',
      'Hyde Heath',
      'Ratby',
      'Tal-y-bont',
      'Stakeford',
      'Linwood',
      'Thamesmead',
      'Ladywell',
      'Crofton Park',
      'Portswood',
      'East Sheen',
      'Mortlake',
      'Coven',
      'Highland',
      'Winchburgh',
      'Addiebrownhill',
      'Ashgill',
      'Banknock',
      'Bargeddie',
      'Blantyre',
      'Brightons',
      'Head of Muir',
      'Calderbank',
      'Blackridge',
      'Salsburgh',
      'Croy',
      'Drongan',
      'Cruden Bay',
      'Fallin',
      'Glenmavis',
      'Hallglen',
      'Kingswells',
      'Milton of Leys',
      'Mossblown',
      'Plains',
      'Ratho Station',
      'Seafield',
      'Stoneyburn',
      'Twechar',
      'Waterfoot',
      'Tullibody',
      'Vale of Leven',
      'Whitecraig',
      'Dennyloanhead',
      'Maryburgh',
      'East Whitburn',
      'Faifley',
      'Laurieston',
      'Lenzie',
      'Maddiston',
      'New Stevenston',
      'Polbeth',
      'Redding',
      'Reddingmuirhead',
      'Stenhousemuir',
      'Thornliebank',
      'Viewpark',
      'Westquarter',
      'Netherlee',
      'Coaltown of Balgonie',
      'Maxwellheugh',
      'Torrance',
      'Shieldhill',
      'Grayshott',
      'Denton Holme',
      'Stamford Hill',
      'West Ilsley',
      'Ballypatrick',
      'Holbeck',
      'Burngreave',
      'Archway',
      'Castle Vale',
      'Old Harlow',
      "King's Clipstone",
      'Sinfin',
      'Hardwick Village',
      'New Basford',
      'Chaddleworth',
      'Clubmoor',
      'Tunstall',
      'Corby Glen',
      'Emerson Park',
      'Gipsy Hill',
      'Perry Vale',
      'Monkhams',
      'Furzedown',
      'Canonbury',
      'Tottenham Hale',
      'Northumberland Park',
      'Barkingside',
      'St. Helier',
      'Stroud Green',
      "St. Ann's",
      'Rastrick',
      'Washwood Heath',
      'Saint Andrews Quay',
      'Basford, Stoke-on-Trent',
      'Rowledge',
      'Cwmtillery',
      'Six Bells',
      'Hale Barns',
    ],
  },
  {
    country: 'Grenada',
    cities: [
      'Victoria',
      'Sauteurs',
      "Saint George's",
      'Saint David’s',
      'Hillsborough',
      'Grenville',
      'Gouyave',
    ],
  },
  {
    country: 'Georgia',
    cities: [
      'Abastumani',
      'Zugdidi',
      'Zhinvali',
      'Zest’aponi',
      'Zahesi',
      'Vani',
      'Vale',
      'Urek’i',
      'Ts’nori',
      'Tsqnet’i',
      'Ts’khinvali',
      'Ts’inandali',
      'Tsikhisdziri',
      'Ts’alk’a',
      'Ts’alenjikha',
      'Ts’aghveri',
      'Tsageri',
      "Tqvarch'eli",
      'T’q’ibuli',
      'Tianeti',
      'Tetri Ts’q’aro',
      'Terjola',
      'Telavi',
      'Tbilisi',
      'Surami',
      'Sokhumi',
      'Sighnaghi',
      'Shuakhevi',
      'Shorap’ani',
      'Shemokmedi',
      'Shaumiani',
      'Senak’i',
      'Sartich’ala',
      'Samtredia',
      'Sagarejo',
      'Sach’khere',
      'Rustavi',
      'Q’vareli',
      'P’ot’i',
      'Pasanauri',
      'Ozurgeti',
      'Orsant’ia',
      'Oni',
      'Ochkhamuri',
      'Och’amch’ire',
      'Akhali Atoni',
      'Ninotsminda',
      'Mtskheta',
      'Mest’ia',
      'Mat’ani',
      'Mart’vili',
      'Mart’q’opi',
      'Marneuli',
      'Manglisi',
      'Makhinjauri',
      'Lent’ekhi',
      'Lanchkhuti',
      'Lagodekhi',
      'Kveda Chkhorots’q’u',
      'Kutaisi',
      'K’urdghelauri',
      'K’ulashi',
      'Kojori',
      'Kobuleti',
      'Khulo',
      'Khoni',
      'Khobi',
      'Khelvachauri',
      'Khashuri',
      'Keda',
      'St’epants’minda',
      'K’atskhi',
      'K’asp’i',
      'K’ardenakhi',
      'Java',
      'Gurjaani',
      'Gulrip’shi',
      'Gudauta',
      'Gudauri',
      'Gori',
      'Gardabani',
      'Gantiadi',
      'Gali',
      'Gagra',
      'Jvari',
      'Dzegvi',
      'Dusheti',
      'Dranda',
      'Jvara',
      'Dmanisi',
      'Diok’nisi',
      'Didi Lilo',
      'Dedoplists’q’aro',
      'Chokhat’auri',
      'Chkhorots’q’u',
      'Ch’lou',
      'Chiat’ura',
      'Chakvi',
      'Borjomi',
      'Bolnisi',
      'Bich’vinta',
      'Batumi',
      'Bak’uriani',
      'Bagdadi',
      'Asp’indza',
      'Anaklia',
      'Ambrolauri',
      'Akhmet’a',
      'Akhaltsikhe',
      'Akhalk’alak’i',
      'Stantsiya Novyy Afon',
      'Akhalgori',
      'Akhaldaba',
      'Aghaiani',
      'Agara',
      'Adigeni',
      'Abasha',
      'Ingiri',
      'P’rimorsk’oe',
      'Ts’q’alt’ubo',
      'Naruja',
      'Bak’urianis Andezit’i',
      'Kelasuri',
      'Narazeni',
      'Kharagauli',
    ],
  },
  {
    country: 'French Guiana',
    cities: [
      'Sinnamary',
      'Saint-Laurent-du-Maroni',
      'Saint-Georges',
      'Roura',
      'Rémire',
      'Régina',
      'Rémire-Montjoly',
      'Matoury',
      'Maripasoula',
      'Mana',
      'Macouria',
      'Kourou',
      'Iracoubo',
      'Grand-Santi',
      'Cayenne',
      'Camopi',
      'Apatou',
    ],
  },
  {
    country: 'Guernsey',
    cities: [
      'Torteval',
      'Saint Sampson',
      'Saint Peter Port',
      'St Anne',
      'Saint Saviour',
      'Castel',
      'Saint Andrew',
      'St Martin',
    ],
  },
  {
    country: 'Ghana',
    cities: [
      'Yendi',
      'Winneba',
      'Sefwi Wiawso',
      'Wankyi',
      'Wa',
      'Teshi Old Town',
      'Tema',
      'Techiman',
      'Tarkwa',
      'Tamale',
      'Takoradi',
      'Tafo',
      'Swedru',
      'Sunyani',
      'Suhum',
      'Shama Junction',
      'Sekondi-Takoradi',
      'Savelugu',
      'Saltpond',
      'Salaga',
      'Prestea',
      'Kasoa',
      'Akim Oda',
      'Obuase',
      'Nungua',
      'Nsawam',
      'Navrongo',
      'Nalerigu',
      'Mumford',
      'Mpraeso',
      'Mampong',
      'Medina Estates',
      'Kumasi',
      'Kpandu',
      'Kpandae',
      'Konongo',
      'Koforidua',
      'Kintampo',
      'Kibi',
      'Kete Krachi',
      'Keta',
      'Japekrom',
      'Hohoe',
      'Ho',
      'Gbawe',
      'Goaso',
      'Foso',
      'Elmina',
      'Ejura',
      'Dunkwa',
      'Dambai',
      'Duayaw-Nkwanta',
      'Dome',
      'Damongo',
      'Cape Coast',
      'Bolgatanga',
      'Bibiani',
      'Berekum',
      'Bekwai',
      'Begoro',
      'Bechem',
      'Bawku',
      'Axim',
      'Asamankese',
      'Apam',
      'Anloga',
      'Akwatia',
      'Akropong',
      'Akim Swedru',
      'Agogo',
      'Aflao',
      'Atsiaman',
      'Accra',
      'Aburi',
      'Aboso',
      'Mamponteng',
      'Wassa-Akropong',
      'Odumase Krobo',
      'Zonno',
    ],
  },
  { country: 'Gibraltar', cities: ['Gibraltar'] },
  {
    country: 'Greenland',
    cities: [
      'Upernavik',
      'Sisimiut',
      'Qasigiannguit',
      'Qaqortoq',
      'Paamiut',
      'Nuuk',
      'Narsaq',
      'Nanortalik',
      'Maniitsoq',
      'Ilulissat',
      'Tasiilaq',
      'Aasiaat',
      'Uummannaq',
    ],
  },
  {
    country: 'Gambia',
    cities: [
      'Wellingara Ba',
      'Wassu',
      'Toniataba',
      'Sutukung',
      'Sutukoba',
      'Sun Kunda',
      'Sukuta',
      'Sudowol',
      'Somita',
      'Soma',
      'Si Kunda',
      'Serekunda',
      'Saruja',
      'Sara Kunda',
      'Sankwia',
      'Sami',
      'Sabi',
      'Saba',
      'Perai',
      'Pateh Sam',
      'Nyamanari',
      'No Kunda',
      'Nioro',
      'Mansa Konko',
      'Lamin',
      'Kunting',
      'Kuntaur',
      'Kumbija',
      'Koina',
      'Kerewan',
      'Keneba',
      'Katchang',
      'Kass Wollof',
      'Karantaba',
      'Kaiaf',
      'Jifarong',
      'Jenoi',
      'Jarreng',
      'Jali',
      'Jakhaly',
      'Gunjur Kuta',
      'Gunjur',
      'Janjanbureh',
      'Galleh Manda',
      'Farafenni',
      'Essau',
      'Diabugu Basilla',
      'Diabugu',
      'Denton',
      'Demba Kunda',
      'Daru Rilwan',
      'Dankunku',
      'Daba Kunda',
      'Chilla',
      'Bureng',
      'Brikama Nding',
      'Brikama',
      'Brifu',
      'Basse Santa Su',
      'Barra',
      'Baro Kunda',
      'Bansang',
      'Banjul',
      'Bambali',
      'Bakau',
      'Bakadagy',
      'Abuko',
    ],
  },
  {
    country: 'Guinea',
    cities: [
      'Youkounkoun',
      'Yomou',
      'Tougué',
      'Tondon',
      'Tokonou',
      'Télimélé',
      'Siguiri',
      'Sanguéya',
      'Pita',
      'Nzérékoré',
      'Mandiana',
      'Mamou',
      'Mali',
      'Macenta',
      'Lola',
      'Labé',
      'Kouroussa',
      'Koundara',
      'Koubia',
      'Kissidougou',
      'Kindia',
      'Kimbo',
      'Kérouané',
      'Kankan',
      'Gueckedou',
      'Gaoual',
      'Fria',
      'Forécariah',
      'Faranah',
      'Dubréka',
      'Dinguiraye',
      'Dalaba',
      'Dabola',
      'Coyah',
      'Conakry',
      'Camayenne',
      'Boké',
      'Boffa',
      'Beyla',
      'Lélouma',
    ],
  },
  {
    country: 'Guadeloupe',
    cities: [
      'Vieux-Habitants',
      'Trois-Rivières',
      'Petites Anses',
      'Saint-François',
      'Sainte-Rose',
      'Sainte-Anne',
      'Saint-Claude',
      'Port-Louis',
      'Pointe-Noire',
      'Pointe-à-Pitre',
      'Petit-Canal',
      'Petit-Bourg',
      'Les Abymes',
      'Le Moule',
      'Le Gosier',
      'Lamentin',
      'Beauséjour',
      'Grand-Bourg',
      'Gourbeyre',
      'Capesterre-Belle-Eau',
      'Bouillante',
      'Basse-Terre',
      'Baillif',
      'Baie-Mahault',
      'Anse-Bertrand',
    ],
  },
  {
    country: 'Equatorial Guinea',
    cities: [
      'Santiago de Baney',
      'San Antonio de Palé',
      'Río Campo',
      'Rebola',
      'Nsok',
      'Nsang',
      'Ncue',
      'Mongomo',
      'Mikomeseng',
      'Mbini',
      'Machinda',
      'Evinayong',
      'Ebebiyin',
      'Cogo',
      'Malabo',
      'Luba',
      'Bitica',
      'Bicurga',
      'Bata',
      'Ayene',
      'Añisoc',
      'Acurenam',
      'Aconibe',
      'Djibloho',
    ],
  },
  {
    country: 'Greece',
    cities: [
      'Zonianá',
      'Zevgolateió',
      'Zarós',
      'Zárkos',
      'Zakynthos',
      'Zacháro',
      'Zagorá',
      'Gýtheio',
      'Yimnón',
      'Giánnouli',
      'Gérgeri',
      'Geráni',
      'Geráki',
      'Géfyra',
      'Xylókastro',
      'Vrontádos',
      'Vrýses',
      'Vraná',
      'Vrachnaíika',
      'Vrakháti',
      'Vounoplagiá',
      'Vouliagméni',
      'Voúla',
      'Vónitsa',
      'Volos',
      'Vokhaïkó',
      'Vlachópoulo',
      'Vlachiótis',
      'Výronas',
      'Vília',
      'Aktaío',
      'Verdikoússa',
      'Vélo',
      'Velestíno',
      'Vágia',
      'Vathí',
      'Vathý',
      'Vasilikón',
      'Vasilikí',
      'Vassilies',
      'Varvásaina',
      'Vartholomió',
      'Varnávas',
      'Vareiá',
      'Vári',
      'Várda',
      'Vanáton',
      'Vamvakoú',
      'Valsamáta',
      'Tsikalariá',
      'Tsarítsani',
      'Trípoli',
      'Tríkeri',
      'Tríkala',
      'Triandaíika',
      'Ialysós',
      'Traganón',
      'Tolón',
      'Týrnavos',
      'Tínos',
      'Tympáki',
      'Tílisos',
      'Thrapsanón',
      'Thouría',
      'Thívai',
      'Firá',
      'Thymianá',
      'Thesprotikó',
      'Thespiés',
      'Thérmo',
      'Terpsithéa',
      'Témeni',
      'Távros',
      'Steíri',
      'Stýpsi',
      'Stylída',
      'Stefanovíkeio',
      'Stavrós',
      'Stavráki',
      'Stános',
      'Stamáta',
      'Stalís',
      'Spétses',
      'Sperchógeia',
      'Spercheiáda',
      'Spáta',
      'Sparta',
      'Soúrpi',
      'Soúda',
      'Sofikón',
      'Sofádes',
      'Skópelos',
      'Skýros',
      'Skiáthos',
      'Schísma Eloúndas',
      'Schimatári',
      'Skarmagkás',
      'Skála Oropoú',
      'Skalánion',
      'Skála',
      'Sitia',
      'Sísion',
      'Synoikismós Chavaríou',
      'Sými',
      'Sylivainiótika',
      'Sykoúrio',
      'Sikyón',
      'Síkinos',
      'Sykiá',
      'Sérifos',
      'Selínia',
      'Savália',
      'Sardínia',
      'Samos',
      'Sámi',
      'Salamína',
      'Royítika',
      'Roviés',
      'Neápolis',
      'Rodotópi',
      'Rododáfni',
      'Rodhítsa',
      'Rizómylos',
      'Rízoma',
      'Río',
      'Rethymno',
      'Rafína',
      'Pteleós',
      'Psychikó',
      'Psachná',
      'Prokópi',
      'Profítis Ilías',
      'Préveza',
      'Prámanta',
      'Pórto Chéli',
      'Portariá',
      'Póros',
      'Politiká',
      'Polichnítos',
      'Polydéndri',
      'Plomári',
      'Platýkampos',
      'Platariá',
      'Plátanos',
      'Pigí',
      'Pithári',
      'Pythagóreio',
      'Pyrgetós',
      'Pýrgos',
      'Piraeus',
      'Pýlos',
      'Pylí',
      'Pýli',
      'Pikérmi',
      'Petroúpolis',
      'Pétra',
      'Péta',
      'Periyiáli',
      'Perivóli',
      'Perivólia',
      'Peristéri',
      'Pérdika',
      'Pérama',
      'Perachóra',
      'Pentéli',
      'Pelópi',
      'Pelasgía',
      'Pediní',
      'Gáïos',
      'Pátra',
      'Pátmos',
      'Patitírion',
      'Pásion',
      'Páros',
      'Párga',
      'Paravóla',
      'Proástio',
      'Parapótamos',
      'Paramythiá',
      'Paralía Avlídhos',
      'Paralía',
      'Pappádos',
      'Pappadátes',
      'Pappadhátai',
      'Pánormos',
      'Panaitólion',
      'Pámfila',
      'Pallíni',
      'Palamás',
      'Pálairos',
      'Palaiópyrgos',
      'Palaió Fáliro',
      'Palaiomonástiro',
      'Palaiókipos',
      'Palaióchora',
      'Palekastro',
      'Psará',
      'Palaiá Fókaia',
      'Palaiá Epídavros',
      'Paianía',
      'Oxílithos',
      'Ovriá',
      'Oropós',
      'Orchomenós',
      'Oreoí',
      'Omvriakí',
      'Oinoússes',
      'Oinófyta',
      'Papágou',
      'Oía',
      'Níkaia',
      'Nerokoúros',
      'Néon Monastírion',
      'Néon Karlovásion',
      'Neochorópoulo',
      'Oichalía',
      'Neochóri',
      'Neochórion',
      'Neméa',
      'Néa Tírins',
      'Néa Stíra',
      'Néa Smýrni',
      'Néa Seléfkeia',
      'Neápoli',
      'Néa Péramos',
      'Néa Palátia',
      'Néa Manoláda',
      'Néa Mákri',
      'Ílion',
      'Néa Lámpsakos',
      'Néa Kíos',
      'Néa Ionía',
      'Néa Filadélfeia',
      'Néa Erythraía',
      'Néa Artáki',
      'Néa Anchiálos',
      'Néa Alikarnassós',
      'Náxos',
      'Náfplio',
      'Náfpaktos',
      'Náousa',
      'Mouzourás',
      'Mouzáki',
      'Mouzaki',
      'Mourniés',
      'Moúlki',
      'Moskháton',
      'Mória',
      'Morfovoúni',
      'Monastiráki',
      'Mólos',
      'Moláoi',
      'Mokhós',
      'Moíres',
      'Mitrópoli',
      'Mytilinioí',
      'Mytilene',
      'Mytikas',
      'Míthymna',
      'Myrsíni',
      'Mýrina',
      'Mindilóglion',
      'Mílos',
      'Mykonos',
      'Megísti',
      'Metsovo',
      'Methóni',
      'Messíni',
      'Mesolóngi',
      'Mesariá',
      'Agía Triáda',
      'Menídi',
      'Melíssia',
      'Meligalás',
      'Mégara',
      'Megalópoli',
      'Megálo Chorió',
      'Megalochóri',
      'Megáli Khóra',
      'Megála Kalývia',
      'Mavrommáti',
      'Mataránga',
      'Martínon',
      'Marmárion',
      'Markópoulo Oropoú',
      'Markópoulo',
      'Marathónas',
      'Marathókampos',
      'Manoláda',
      'Mandráki',
      'Mándra',
      'Mantoúdi',
      'Mantamádos',
      'Mália',
      'Malesína',
      'Malakónta',
      'Makrísia',
      'Makrychóri',
      'Makrakómi',
      'Magoúla',
      'Artémida',
      'Loutráki',
      'Loutrá Aidhipsoú',
      'Loutrá',
      'Loúros',
      'Loukísia',
      'Lixoúri',
      'Livanátes',
      'Lithakiá',
      'Leipsoí',
      'Limnokhórion',
      'Límni',
      'Limín Mesoyaías',
      'Limín Khersonísou',
      'Lykóvrysi',
      'Ligourión',
      'Lidoríki',
      'Lianokládhion',
      'Lefkímmi',
      'Lefkáda',
      'Levídion',
      'Livadeiá',
      'Lepenoú',
      'Leonídio',
      'Leondárion',
      'Lékhaio',
      'Lechainá',
      'Lávrio',
      'Lárisa',
      'Lápas',
      'Langádhia',
      'Lamía',
      'Lálas',
      'Lakkí',
      'Krousón',
      'Krokeés',
      'Taxiárches',
      'Kritsá',
      'Kryonéri',
      'Kríkellos',
      'Kréstena',
      'Kranídi',
      'Kraniá Elassónas',
      'Kouvarás',
      'Koutsopódi',
      'Koutselió',
      'Metamórfosi',
      'Kostakioí',
      'Kos',
      'Koropí',
      'Koróni',
      'Kórinthos',
      'Domvraína',
      'Kompóti',
      'Kolympári',
      'Kokkónion',
      'Kokkíni Cháni',
      'Kokkári',
      'Koilás',
      'Kýthnos',
      'Kýthira',
      'Kíssamos',
      'Kírra',
      'Kyriáki',
      'Kyrás Vrýsi',
      'Kipséli',
      'Kypséli',
      'Kyparissía',
      'Kinéta',
      'Kímolos',
      'Kými',
      'Kyllíni',
      'Kifisiá',
      'Kiáto',
      'Chóra Sfakíon',
      'Chóra',
      'Cholargós',
      'Chios',
      'Khiliomódhi',
      'Chávari',
      'Chaniá',
      'Khalkoútsion',
      'Chalkída',
      'Chalkiádes',
      'Chálki',
      'Chalandrítsa',
      'Khalándrion',
      'Chairóneia',
      'Chaïdári',
      'Keratsíni',
      'Keratéa',
      'Kerasochóri',
      'Kentrí',
      'Kéfalos',
      'Kéa',
      'Kavásila',
      'Katsikás',
      'Katoúna',
      'Káto Tithoréa',
      'Káto Soúlion',
      'Káto Mazaráki',
      'Káto Lekhónia',
      'Katochí',
      'Káto Glykóvrysi',
      'Káto Dhiminió',
      'Káto Asítai',
      'Káto Achaḯa',
      'Katastárion',
      'Kastrí',
      'Kastráki',
      'Kastélli',
      'Kastélla',
      'Karpochóri',
      'Karpenísi',
      'Karpathos',
      'Kárystos',
      'Karyés',
      'Karditsomagoúla',
      'Kardítsa',
      'Kardamýli',
      'Kardamás',
      'Kardámaina',
      'Karátoula',
      'Pefkochóri',
      'Kaparéllion',
      'Kapandríti',
      'Kandíla',
      'Kanaláki',
      'Kanália',
      'Kaména Voúrla',
      'Kamaterón',
      'Kamárai',
      'Kalpáki',
      'Kalloní',
      'Kallíthiro',
      'Kallithéa',
      'Kallifóni',
      'Kalývia Thorikoú',
      'Kalýves',
      'Kálymnos',
      'Kalávryta',
      'Kálamos',
      'Kalamiá',
      'Kalampáka',
      'Kalamata',
      'Álimos',
      'Kaisarianí',
      'Kainoúryion',
      'Itháki',
      'Itéa',
      'Istiaía',
      'Isthmía',
      'Irákleio',
      'Irákleion',
      'Íos',
      'Ioánnina',
      'Igoumenítsa',
      'Ierápetra',
      'Ýdra',
      'Grizáno',
      'Grammenítsa',
      'Grammatikó',
      'Gra Liyiá',
      'Graikochóri',
      'Goúrnes',
      'Goúmero',
      'Gónnoi',
      'Gómfoi',
      'Glyfáda',
      'Gázi',
      'Gavaloú',
      'Gastoúni',
      'Gargaliánoi',
      'Galaxídhion',
      'Galátsi',
      'Galatás',
      'Gaïtánion',
      'Fry',
      'Folégandros',
      'Fyteíes',
      'Filótion',
      'Filothéi',
      'Fílla',
      'Filippiáda',
      'Filiatrá',
      'Filiátes',
      'Fylí',
      'Fíki',
      'Fársala',
      'Fáros',
      'Farkadóna',
      'Fálanna',
      'Examília',
      'Evxinoúpolis',
      'Ermoúpolis',
      'Ermióni',
      'Erythrés',
      'Erétria',
      'Eresós',
      'Epitálio',
      'Ano Arhanes',
      'Emporeío',
      'Émponas',
      'Eloúnda',
      'Ellinikó',
      'Elefsína',
      'Eleoúsa',
      'Eláteia',
      'Elassóna',
      'Ekáli',
      'Voulgaréli',
      'Dhrosiá',
      'Drosiá',
      'Drépanon',
      'Dhráfi',
      'Domokós',
      'Dhokímion',
      'Dístomo',
      'Diónysos',
      'Dimitsána',
      'Dílesi',
      'Dídyma',
      'Deskáti',
      'Delphi',
      'Darátsos',
      'Dhafní',
      'Rodópoli',
      'Ágios Geórgios',
      'Áyios Vasílios',
      'Áyios Thomás',
      'Ágios Stéfanos',
      'Áyios Nikólaos',
      'Arkoúdi',
      'Ágios Nikólaos',
      'Áyios Konstandínos',
      'Agios Kirykos',
      'Ágios Efstrátios',
      'Agios Dimitrios',
      'Ágios Andréas',
      'Áyios Adhrianós',
      'Ágioi Theódoroi',
      'Ágioi Déka',
      'Áyioi Apóstoloi',
      'Agía Varvára',
      'Ayía Triás',
      'Agiásos',
      'Agía Paraskeví',
      'Agía Marína',
      'Agía Galíni',
      'Agiá',
      'Avlónas',
      'Atsipópoulo',
      'Athens',
      'Athíkia',
      'Atalánti',
      'Ástros',
      'Astypálaia',
      'Astakós',
      'Ássos',
      'Asprópyrgos',
      'Asprángeloi',
      'Asopós',
      'Asopía',
      'Asíni',
      'Asímion',
      'Argyroúpoli',
      'Argyropoúli',
      'Artesianó',
      'Árta',
      'Árma',
      'Archontochóri',
      'Archaía Olympía',
      'Arkhaía Kórinthos',
      'Arkalochóri',
      'Áris',
      'Aria',
      'Argostólion',
      'Árgos',
      'Argalastí',
      'Arfará',
      'Aráchova',
      'Anthiró',
      'Anthíli',
      'Anógeia',
      'Áno Sýros',
      'Áno Merá',
      'Áno Liósia',
      'Áno Lekhónia',
      'Kopanáki',
      'Áno Kastrítsi',
      'Áno Kalentíni',
      'Anoixi',
      'Angelókastro',
      'Anéza',
      'Andros',
      'Andravída',
      'Antirrio',
      'Antíparos',
      'Antimácheia',
      'Antikyra',
      'Anávra',
      'Anávyssos',
      'Anatolí',
      'Anáfi',
      'Amorgós',
      'Ámfissa',
      'Amfilochía',
      'Amfíkleia',
      'Ampelóna',
      'Ambelókipoi',
      'Ampelákia',
      'Maroúsi',
      'Amárynthos',
      'Amaliáda',
      'Almyrós',
      'Álli Meriá',
      'Alivéri',
      'Alíartos',
      'Alfeioúsa',
      'Akráta',
      'Akraifnía',
      'Acharnés',
      'Aíyira',
      'Aígio',
      'Aegina',
      'Aitolikó',
      'Aigáleo',
      'Aidipsós',
      'Aiánteio',
      'Agrínio',
      'Agriá',
      'Agnanteró',
      'Afrátion',
      'Afidnés',
      'Adámas',
      'Ródos',
      'Kremastí',
      'Afántou',
      'Archángelos',
      'Lárdos',
      'Zipári',
      'Néos Oropós',
      'Zygós',
      'Zagkliveri',
      'Akrolímni',
      'Giannitsá',
      'Gerakaroú',
      'Genisséa',
      'Xiropótamos',
      'Xinó Neró',
      'Xilópolis',
      'Xylaganí',
      'Xánthi',
      'Vrontoú',
      'Vólakas',
      'Vergína',
      'Véroia',
      'Velventós',
      'Vathýlakkos',
      'Vasiliká',
      'Vamvakófyto',
      'Valteró',
      'Tsotíli',
      'Trílofos',
      'Tycheró',
      'Thessaloníki',
      'Thérmi',
      'Thásos',
      'Terpní',
      'Tagarádes',
      'Svorónos',
      'Strymonikó',
      'Stratónion',
      'Stavroúpoli',
      'Sourotí',
      'Souflí',
      'Sosándra',
      'Sochós',
      'Smínthi',
      'Skoútari',
      'Skotoússa',
      'Skýdra',
      'Leptokaryá',
      'Sitagroí',
      'Síndos',
      'Sykiés',
      'Sidirókastro',
      'Siátista',
      'Sfendámi',
      'Sevastianá',
      'Sérvia',
      'Sérres',
      'Sélero',
      'Sárti',
      'Sápes',
      'Samothráki',
      'Rodolívos',
      'Rizó',
      'Rizómata',
      'Rízia',
      'Rizári',
      'Ritíni',
      'Péfka',
      'Ptolemaḯda',
      'Fteliá',
      'Provatás',
      'Próti',
      'Prosotsáni',
      'Prómachoi',
      'Próchoma',
      'Potamiá',
      'Pontisméno',
      'Polýgyros',
      'Políchni',
      'Polýkastro',
      'Polykárpi',
      'Plagiári',
      'Platý',
      'Platanórevma',
      'Chalástra',
      'Pylaía',
      'Petroússa',
      'Petrochóri',
      'Peteinós',
      'Perivolákion',
      'Perístasi',
      'Peraía',
      'Péplos',
      'Pentaplátano',
      'Pentálofos',
      'Pélla',
      'Patrída',
      'Paranésti',
      'Panórama',
      'Palaió Tsiflíki',
      'Axós',
      'Palaiokómi',
      'Palaiochóri',
      'Palaífyto',
      'Páchni',
      'Ouranoupolis',
      'Ormýlia',
      'Orestiáda',
      'Oraiókastro',
      'Nisí',
      'Níkiti',
      'Nikísiani',
      'Nigríta',
      'Nestório',
      'Néos Skopós',
      'Néos Mylótopos',
      'Néos Marmarás',
      'Néo Soúli',
      'Néo Rýsi',
      'Néo Petrítsi',
      'Néo Agionéri',
      'Neochoroúda',
      'Néoi Epivátes',
      'Néa Zíchni',
      'Néa Výssa',
      'Néa Tríglia',
      'Néa Sánta',
      'Néa Róda',
      'Néa Raidestós',
      'Néa Poteídaia',
      'Néa Plágia',
      'Néa Pélla',
      'Ólynthos',
      'Néa Moudhaniá',
      'Néa Michanióna',
      'Néa Mesimvría',
      'Néa Málgara',
      'Néa Magnisía',
      'Néa Mádytos',
      'Néa Karváli',
      'Néa Karyá',
      'Néa Kallikráteia',
      'Néa Iraklítsa',
      'Néa Fókaia',
      'Néa Éfesos',
      'Néa Apollonía',
      'Mikrópolis',
      'Mikró Monastíri',
      'Mýki',
      'Mesopotamía',
      'Mesiméri',
      'Meneméni',
      'Melíti',
      'Melissochóri',
      'Melíki',
      'Megáli Panagía',
      'Mavrovoúni',
      'Mavrothálassa',
      'Mavrochóri',
      'Marína',
      'Maniákoi',
      'Mándalo',
      'Díon',
      'Makrochóri',
      'Makrýgialos',
      'Loutrós',
      'Lófos',
      'Livádi',
      'Livaderó',
      'Litóchoro',
      'Lití',
      'Limenária',
      'Lýkeio',
      'Lianovérgi',
      'Lefkónas',
      'Lagyná',
      'Lávara',
      'Lagkadás',
      'Lákkoma',
      'Laimós',
      'Lagós',
      'Krókos',
      'Krithiá',
      'Krinídes',
      'Krýa Vrýsi',
      'Kozáni',
      'Kouloúra',
      'Koufália',
      'Korinós',
      'Koryfí',
      'Kopanós',
      'Kónitsa',
      'Kontariótissa',
      'Komotiní',
      'Komniná',
      'Kolchikón',
      'Kolindrós',
      'Kokkinóchoma',
      'Koímisi',
      'Koíla',
      'Kleítos',
      'Kleidí',
      'Kítros',
      'Kýria',
      'Kyprínos',
      'Kimméria',
      'Kýmina',
      'Kilkís',
      'Chrysoúpolis',
      'Chrysó',
      'Chrysochóri',
      'Chrysochórafa',
      'Chrysavgí',
      'Chortiátis',
      'Choristí',
      'Charopó',
      'Chalkidóna',
      'Keramotí',
      'Kavýli',
      'Kavallári',
      'Kavála',
      'Káto Scholári',
      'Káto Nevrokópi',
      'Káto Miliá',
      'Káto Lipochóri',
      'Káto Kamíla',
      'Kateríni',
      'Kastoria',
      'Kastaniés',
      'Kassándreia',
      'Símantra',
      'Karítsa',
      'Karyótissa',
      'Karyes',
      'Kardía',
      'Kampánis',
      'Kamariótissa',
      'Kalós Agrós',
      'Kalochóri',
      'Kallífytos',
      'Kalí Vrýsi',
      'Kalýves Polygýrou',
      'Ágios Spyrídon',
      'Kalývia',
      'Prínos',
      'Kalí',
      'Kalampáki',
      'Kalamariá',
      'Kalá Déndra',
      'Léchovo',
      'Iráklion',
      'Irákleia',
      'Ierissós',
      'Íasmos',
      'Grevená',
      'Gouménissa',
      'Gázoros',
      'Galátista',
      'Galatiní',
      'Galatádes',
      'Fotolívos',
      'Néa Flogitá',
      'Flórina',
      'Filótas',
      'Fillýra',
      'Fíliro',
      'Féres',
      'Exaplátanos',
      'Evropós',
      'Évosmos',
      'Évlalo',
      'Efkarpía',
      'Erátyra',
      'Episkopí',
      'Epanomí',
      'Néa Kerasiá',
      'Empório',
      'Eleftheroúpolis',
      'Elefthério - Kordelió',
      'Eleftherés',
      'Elaiochóri',
      'Echínos',
      'Édessa',
      'Dráma',
      'Drymós',
      'Dravískos',
      'Didymóteicho',
      'Diavatós',
      'Diavatá',
      'Ágios Vasíleios',
      'Ágios Pétros',
      'Ágios Loukás',
      'Ágios Athanásios',
      'Ágio Pnévma',
      'Axioúpoli',
      'Ávato',
      'Áthyra',
      'Asvestochóri',
      'Ássiros',
      'Asproválta',
      'Askós',
      'Arsénio',
      'Arrianá',
      'Árnissa',
      'Arnaía',
      'Arísvi',
      'Aridaía',
      'Argos Orestiko',
      'Aravissós',
      'Áratos',
      'Ápsalos',
      'Mitroúsi',
      'Áno Kómi',
      'Angelochóri',
      'Agkathiá',
      'Anatolikó',
      'Anaráchi',
      'Ammochóri',
      'Amýntaio',
      'Amygdaleónas',
      'Ampeleíes',
      'Alistráti',
      'Alexandroupoli',
      'Alexándreia',
      'Akriní',
      'Aigínio',
      'Aianí',
      'Áfytos',
      'Ádendro',
      'Eirinoúpoli',
      'Ágios Pávlos',
      'Triandría',
      'Melíssi',
      'Virós',
      'Potamós',
      'Kontokáli',
      'Kynopiástes',
      'Corfu',
      'Kanáli',
      'Ágios Matthaíos',
      'Alepoú',
      'Vrilissia',
      'Faliraki',
      'Acharávi',
      'Agios Georgis',
      'Georgioupolis',
      'Ornós',
      'Káto Goúves',
      'Saronída',
      'Exochi',
      'Ymittos',
      'Gérakas',
      'Paralía Ofryníou',
      'Agía Kyriakí',
      'Agía Foteiní',
      'Ilioúpoli',
      'Korydallós',
      'Zográfos',
      'Péfki',
      'Ágioi Anárgyroi',
      'Ampelókipoi',
      'Foúrnoi',
      'Néa Potídhaia',
      'Agios Ioannis Rentis',
      'Violí Charáki',
      'Néa Anatolí',
      'Drapetsóna',
      'Néo Psychikó',
      'Chlói',
      'Varybóbi',
      'Aghios Panteleímon',
      'Anthoúsa',
      'Néa Vrasná',
      'Zefyri',
      'Thrakomakedónes',
      'Néa Pentéli',
      'Néa Chalkidóna',
      'Kítsi',
      'Agios Dimítrios Kropiás',
      'Karellás',
      'Neos Voutzás',
      'Argithéa',
      'Dióni',
      'Vlycháda',
      'Perama',
      'Loutrá Oraías Elénis',
      'Paralía Vérgas',
      'Kardamítsia',
      'Tsiflikópoulo',
      'Eksochí',
      'Pláka Dílesi',
      'Anakasiá',
      'Iliokentima',
      'Neapoli',
      'Nea Lava',
      'Stathmós Mourión',
      'Mosynopolis, Maximianopolis, Μαξιμιανούπολις',
      'Xinos',
      'Palaiokastro',
      'Kallikomo',
    ],
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    cities: ['Grytviken'],
  },
  {
    country: 'Guatemala',
    cities: [
      'Zunilito',
      'Zunil',
      'Zaragoza',
      'Zapotitlán',
      'Zacualpa',
      'Zacapa',
      'Yupiltepeque',
      'Yepocapa',
      'Villa Nueva',
      'Villa Canales',
      'Usumatlán',
      'Uspantán',
      'Unión',
      'Tucurú',
      'Totonicapán',
      'Todos Santos Cuchumatán',
      'Tejutla',
      'Teculután',
      'Tectitán',
      'Tecpán Guatemala',
      'Taxisco',
      'Tamahú',
      'Tajumulco',
      'Tactic',
      'Tacaná',
      'Sumpango',
      'Soloma',
      'Sololá',
      'Siquinalá',
      'Sipacapa',
      'Sibinal',
      'Sibilia',
      'Senahú',
      'Sayaxché',
      'San Vicente Pacaya',
      'Santo Tomás La Unión',
      'Santo Domingo Xenacoj',
      'Santo Domingo Suchitepéquez',
      'Santiago Sacatepéquez',
      'Santiago Chimaltenango',
      'Santiago Atitlán',
      'Santa Rosa de Lima',
      'Santa María Visitación',
      'Santa María Ixhuatán',
      'Santa María de Jesús',
      'Santa María Chiquimula',
      'Santa Lucía Utatlán',
      'Santa Lucía Milpas Altas',
      'Santa Lucia La Reforma',
      'Santa Lucía Cotzumalguapa',
      'Santa Eulalia',
      'Santa Cruz Verapaz',
      'Santa Cruz Naranjo',
      'Santa Cruz Muluá',
      'Santa Cruz La Laguna',
      'Santa Cruz del Quiché',
      'Santa Cruz Balanyá',
      'Santa Clara La Laguna',
      'Santa Catarina Pinula',
      'Santa Catarina Palopó',
      'Santa Catarina Mita',
      'Santa Catarina Ixtahuacán',
      'Santa Catarina Barahona',
      'Santa Bárbara',
      'Santa Apolonia',
      'Santa Ana Huista',
      'Santa Ana',
      'San Sebastián Huehuetenango',
      'San Sebastián Coatán',
      'San Sebastián',
      'Sansare',
      'San Raimundo',
      'San Rafael Pie de la Cuesta',
      'San Rafael Petzal',
      'San Rafael Las Flores',
      'San Rafael La Independencia',
      'San Pedro Sacatepéquez',
      'San Pedro Pinula',
      'San Pedro Necta',
      'San Pedro La Laguna',
      'San Pédro Jocopilas',
      'San Pedro Carchá',
      'San Pedro Ayampuc',
      'San Pablo La Laguna',
      'San Pablo Jocopilas',
      'San Pablo',
      'San Miguel Sigüilá',
      'San Miguel Panán',
      'San Miguel Ixtahuacán',
      'San Miguel Dueñas',
      'San Miguel Chicaj',
      'San Miguel Acatán',
      'San Mateo Ixtatán',
      'San Mateo',
      'San Martín Zapotitlán',
      'San Martín Sacatepéquez',
      'San Martín Jilotepeque',
      'San Marcos La Laguna',
      'San Marcos',
      'San Manuel Chaparrón',
      'San Luis Jilotepeque',
      'San Luis Ixcán',
      'San Luis',
      'San Lucas Tolimán',
      'San Lucas Sacatepéquez',
      'San Lorenzo',
      'San Juan Tecuaco',
      'San Juan Sacatepéquez',
      'San Juan La Laguna',
      'San Juan Ixcoy',
      'San Juan Ermita',
      'San Juan Cotzal',
      'San Juan Chamelco',
      'San Juan Bautista',
      'San Juan Atitán',
      'San José Poaquil',
      'San José Pinula',
      'San José Ojetenán',
      'San José Ojetenam',
      'San José La Arada',
      'San José El Ídolo',
      'San José del Golfo',
      'San José Chacayá',
      'San José Acatempa',
      'San José',
      'San Jorge',
      'San Jerónimo',
      'San Jacinto',
      'San Gaspar Ixchil',
      'San Gabriel',
      'San Francisco Zapotitlán',
      'San Francisco la Unión',
      'San Francisco El Alto',
      'San Francisco',
      'San Felipe',
      'San Diego',
      'San Cristóbal Verapaz',
      'San Cristóbal Totonicapán',
      'San Cristóbal Cucho',
      'San Cristóbal Acasaguastlán',
      'San Carlos Sija',
      'San Carlos Alzatate',
      'San Bernardino',
      'San Benito',
      'San Bartolomé Milpas Altas',
      'San Bartolomé Jocotenango',
      'San Bartolo',
      'Sanarate',
      'San Antonio Suchitepéquez',
      'San Antonio Sacatepéquez',
      'San Antonio Palopó',
      'San Antonio La Paz',
      'San Antonio Ilotenango',
      'San Antonio Huista',
      'San Antonio Aguas Calientes',
      'San Andrés Xecul',
      'San Andrés Villa Seca',
      'San Andrés Semetabaj',
      'San Andrés Sajcabajá',
      'San Andrés Itzapa',
      'San Andrés',
      'San Agustín Acasaguastlán',
      'Samayac',
      'Salcajá',
      'Salamá',
      'Sacapulas',
      'Río Hondo',
      'Río Bravo',
      'Río Blanco',
      'Retalhuleu',
      'Raxruhá',
      'Rabinal',
      'Quezaltepeque',
      'Quetzaltenango',
      'Quesada',
      'Purulhá',
      'Puerto San José',
      'Puerto Barrios',
      'Pueblo Nuevo Viñas',
      'Tiquisate',
      'Pueblo Nuevo',
      'Poptún',
      'Pochuta',
      'Playa Grande',
      'Petapa',
      'Patzún',
      'Patzité',
      'Patzicía',
      'Patulul',
      'Pastores',
      'Pasaco',
      'Parramos',
      'Panzos',
      'Panajachel',
      'Palín',
      'Palestina de los Altos',
      'Palencia',
      'Pajapita',
      'Pachalum',
      'Ostuncalco',
      'Oratorio',
      'Olopa',
      'Olintepeque',
      'Ocós',
      'Nuevo San Carlos',
      'Nuevo Progreso',
      'Nueva Santa Rosa',
      'Nueva Concepción',
      'Nentón',
      'Nebaj',
      'Nahualá',
      'Moyuta',
      'Morazán',
      'Morales',
      'Monjas',
      'Momostenango',
      'Mixco',
      'Mazatenango',
      'Mataquescuintla',
      'Masagua',
      'Malacatancito',
      'Malacatán',
      'Magdalena Milpas Altas',
      'Los Amates',
      'Lívingston',
      'La Unión',
      'La Tinta',
      'La Reforma',
      'Lanquín',
      'La Libertad',
      'La Gomera',
      'La Esperanza',
      'La Democracia',
      'La Blanca',
      'Jutiapa',
      'Joyabaj',
      'Jocotenango',
      'Jocotán',
      'Jerez',
      'Jalpatagua',
      'Jalapa',
      'Jacaltenango',
      'Iztapa',
      'Ixtahuacán',
      'Ixchiguán',
      'Ipala',
      'Huité',
      'Huitán',
      'Huehuetenango',
      'Guazacapán',
      'Guanagazapa',
      'Gualán',
      'Granados',
      'Génova',
      'Fraijanes',
      'Flores Costa Cuca',
      'Flores',
      'Estanzuela',
      'Esquipulas Palo Gordo',
      'Esquipulas',
      'Escuintla',
      'El Tumbador',
      'El Tejar',
      'El Rodeo',
      'El Quetzal',
      'Guastatoya',
      'El Progreso',
      'El Palmar',
      'El Jícaro',
      'El Estor',
      'El Chol',
      'El Chal',
      'El Asintal',
      'El Adelanto',
      'Dolores',
      'Cuyotenango',
      'Cunén',
      'Cuilco',
      'Cuilapa',
      'Cubulco',
      'Conguaco',
      'Concepción Tutuapa',
      'Concepción Las Minas',
      'Concepción Chiquirichapa',
      'Concepción Huista',
      'Concepción',
      'Comitancillo',
      'Comapa',
      'Comalapa',
      'Colotenango',
      'Colomba',
      'Cobán',
      'Coatepeque',
      'Ciudad Vieja',
      'Ciudad Tecún Umán',
      'Melchor de Mencos',
      'Guatemala City',
      'Chuarrancho',
      'Chisec',
      'Chiquimulilla',
      'Chiquimula',
      'Chinique',
      'Chinautla',
      'Chimaltenango',
      'Chichicastenango',
      'Chiché',
      'Chicamán',
      'Chicacao',
      'Chiantla',
      'Champerico',
      'Chajul',
      'Chahal',
      'Catarina',
      'Casillas',
      'Cantel',
      'Canillá',
      'Camotán',
      'Cajolá',
      'Cahabón',
      'Cabricán',
      'Cabañas',
      'Barillas',
      'Barberena',
      'Atescatempa',
      'Asunción Mita',
      'Antigua Guatemala',
      'Amatitlán',
      'Alotenango',
      'Almolonga',
      'Aguacatán',
      'Agua Blanca',
      'Acatenango',
      'San Juan Ostuncalco',
      'La Máquina',
      'Fray Bartolomé de Las Casas',
    ],
  },
  {
    country: 'Guam',
    cities: [
      'Piti Village',
      'Santa Rita Village',
      'Sinajana Village',
      'Talofofo Village',
      'Tamuning-Tumon-Harmon Village',
      'Umatac Village',
      'Yigo Village',
      'Yona Village',
      'Merizo Village',
      'Inarajan Village',
      'Agana Heights Village',
      'Guam Government House',
      'Chalan Pago-Ordot Village',
      'Barrigada Village',
      'Agat Village',
      'Asan-Maina Village',
      'Dededo Village',
      'Hagåtña',
      'Mangilao Village',
      'Mongmong-Toto-Maite Village',
      'Hagåtña Village',
      'Tamuning',
    ],
  },
  {
    country: 'Guinea-Bissau',
    cities: [
      'Quinhámel',
      'Quebo',
      'Pirada',
      'Pitche',
      'Mansôa',
      'Gabú',
      'Fulacunda',
      'Farim',
      'Catió',
      'Canchungo',
      'Cacheu',
      'Bubaque',
      'Buba',
      'Bolama',
      'Bissorã',
      'Bissau',
      'Bafatá',
    ],
  },
  {
    country: 'Guyana',
    cities: [
      'Vreed-en-Hoop',
      'Skeldon',
      'Rosignol',
      'Parika',
      'New Amsterdam',
      'Mahdia',
      'Mahaicony Village',
      'Mahaica Village',
      'Mabaruma',
      'Linden',
      'Lethem',
      'Georgetown',
      'Bartica',
      'Anna Regina',
      'Fort Wellington',
    ],
  },
  {
    country: 'Hong Kong',
    cities: [
      'Tsuen Wan',
      'Yung Shue Wan',
      'Yuen Long Kau Hui',
      'Wong Tai Sin',
      'Wan Chai',
      'Tuen Mun',
      'Tsing Yi Town',
      'Tseung Kwan O',
      'Tin Shui Wai',
      'Tai Po',
      'Tai O',
      'Sok Kwu Wan',
      'Sha Tin',
      'Sham Shui Po',
      'Sai Kung',
      'Pok Fu Lam',
      'Ping Shan',
      'Ngong Ping',
      'Ma On Shan',
      'Kwai Chung',
      'Kowloon',
      'Hong Kong',
      'Aberdeen',
      'Fanling',
      'Victoria',
      'Central',
      'Tung Chung',
    ],
  },
  {
    country: 'Honduras',
    cities: [
      'Puerto Cortez',
      'Zopilotepe',
      'Zamora',
      'Zambrano',
      'Yuscarán',
      'Yoro',
      'Yorito',
      'Yarumela',
      'Yaruchel',
      'Yamaranguila',
      'Yaguacire',
      'Wawina',
      'Villanueva',
      'Villa Nueva',
      'Villa de San Francisco',
      'Villa de San Antonio',
      'Victoria',
      'Veracruz',
      'Valle de Ángeles',
      'Vallecillo',
      'Trujillo',
      'Trojas',
      'Trinidad de Copán',
      'Trinidad',
      'Travesía',
      'Tras Cerros',
      'Toyós',
      'Tornabé',
      'Tocoa',
      'Teupasenti',
      'Tepusteca',
      'Tepanguare',
      'Tela',
      'Tegucigalpa',
      'Teguajinal',
      'Taulabé',
      'Taujica',
      'Taragual',
      'Támara',
      'Talanga',
      'Sulaco',
      'Sula',
      'Subirana',
      'Sinuapa',
      'Silca',
      'Siguatepeque',
      'Sensenti',
      'Savannah Bight',
      'Sabá',
      'San Vicente Centenario',
      'Santiago de Puringla',
      'Santa Rosa de Copán',
      'Santa Rosa de Aguán',
      'Santa Rita',
      'Santa Rita, Copan',
      'Santa María del Real',
      'Santa Lucía',
      'Santa Elena',
      'Santa Cruz de Yojoa',
      'Santa Cruz',
      'Santa Bárbara',
      'Santa Ana de Yusguare',
      'Santa Ana',
      'San Sebastián',
      'San Pedro Zacapa',
      'San Pedro Sula',
      'San Pedro de Tutule',
      'San Nicolás',
      'San Matías',
      'San Marcos de Colón',
      'San Marcos',
      'San Manuel',
      'San Luis de Planes',
      'San Luis',
      'San Lucas',
      'San Lorenzo',
      'San Juan Pueblo',
      'San Juan de Planes',
      'San Juan de Opoa',
      'San Juan de Flores',
      'San José de Tarros',
      'San José de Río Tinto',
      'San José del Potrero',
      'San José del Boquerón',
      'San José',
      'San José de Comayagua',
      'San José de Colinas',
      'San Joaquín',
      'San Jerónimo',
      'San Ignacio',
      'San Francisco de Yojoa',
      'San Francisco del Valle',
      'San Francisco de la Paz',
      'San Francisco de Coray',
      'San Francisco de Cones',
      'San Francisco de Becerra',
      'San Francisco',
      'San Fernando',
      'San Esteban',
      'Sandy Bay',
      'San Diego',
      'San Buenaventura',
      'San Antonio del Norte',
      'San Antonio de la Cuesta',
      'San Antonio de Cortés',
      'San Antonio',
      'San Agustín',
      'Sambo Creek',
      'Salamá',
      'Sabanagrande',
      'Coxen Hole',
      'Río Lindo',
      'Río Esteban',
      'Río Chiquito',
      'Río Bonito',
      'Río Blanquito',
      'Río Abajo',
      'Reitoca',
      'Quimistán',
      'Quezailica',
      'Quebrada Seca',
      'Quebradas',
      'Quebrada Larga',
      'Quebrada de Arena',
      'Punuare',
      'Punta Piedra',
      'Punta Ocote',
      'Puerto Lempira',
      'Puerto Castilla',
      'Puerto Alto',
      'Pueblo Nuevo',
      'Protección',
      'Prieta',
      'Potrerillos',
      'Pinalejo',
      'Pimienta Vieja',
      'Petoa',
      'Pespire',
      'Peña Blanca',
      'Paujiles',
      'Paptalaya',
      'Oropolí',
      'Oropéndolas',
      'Orocuina',
      'Orica',
      'Omoa',
      'Olanchito',
      'Ojos de Agua',
      'Ojojona',
      'Ojo de Agua',
      'Ocote Paulino',
      'Nuevo Chamelecón',
      'Nueva Ocotepeque',
      'Nueva Jalapa',
      'Nueva Esperanza',
      'Nueva Armenia',
      'Nombre de Jesús',
      'Naranjito',
      'Namasigüe',
      'Naco',
      'Nacaome',
      'Morocelí',
      'Morazán',
      'Monterrey',
      'Monjarás',
      'Mojimán',
      'Minas de Oro',
      'Mezapa',
      'Mateo',
      'Mata de Plátano',
      'Marcovia',
      'Marcala',
      'Marale',
      'Manto',
      'Mangulile',
      'Lucerna',
      'Los Tangos',
      'Los Planes',
      'Los Naranjos',
      'Los Llanitos',
      'Los Caminos',
      'Lomitas',
      'Loma Alta',
      'Liure',
      'Lepaterique',
      'Lepaera',
      'Lejamaní',
      'La Zumbadora',
      'La Virtud',
      'La Unión',
      'La Trinidad',
      'Las Vegas',
      'Las Vegas, Santa Barbara',
      'Las Trojes',
      'Las Tejeras',
      'Las Lajas',
      'San José de Las Conchas',
      'La Sarrosa',
      'Las Ánimas',
      'La Sabana',
      'La Rosa',
      'La Playona',
      'La Paz',
      'Langue',
      'La Mina',
      'La Masica',
      'Lamaní',
      'La Lima',
      'La Libertad',
      'La Labor',
      'La Jutosa',
      'La Bueso',
      'Laguna Verde',
      'Laguna Seca',
      'La Guata',
      'La Guama',
      'La Guacamaya',
      'La Flecha',
      'La Estancia',
      'La Esperanza',
      'La Ermita',
      'La Entrada',
      'La Encarnación',
      'La Criba',
      'La Concepción',
      'La Ceiba',
      'La Brea',
      'Jutiquile',
      'Juticalpa',
      'Jutiapa',
      'Joconal',
      'Jocón',
      'Jiquinlaca',
      'Jícaro Galán',
      'Jesús de Otoro',
      'Jericó',
      'Jamalteca',
      'Jacaleapa',
      'Iralaya',
      'Intibucá',
      'Ilama',
      'Güinope',
      'Guayape',
      'Guarizama',
      'Guanaja',
      'Gualjoco',
      'Gualaco',
      'Guaimitas',
      'Guaimaca',
      'Guacamaya',
      'Gracias',
      'Goascorán',
      'French Harbor',
      'Francia',
      'Florida',
      'Flores',
      'Esquías',
      'Erandique',
      'El Zapotal del Norte',
      'El Tular',
      'El Triunfo de la Cruz',
      'El Triunfo',
      'El Tránsito',
      'El Tigre',
      'El Terrero',
      'El Tablón',
      'El Suyatal',
      'El Socorro',
      'El Sauce',
      'El Rusio',
      'El Rosario',
      'El Rincón',
      'El Rancho',
      'Rancho Grande',
      'El Puente',
      'El Progreso',
      'El Porvenir',
      'El Plan',
      'El Pino',
      'El Perico',
      'El Pedernal',
      'El Paraíso',
      'El Olivar',
      'El Ocotón',
      'El Ocote',
      'El Obraje',
      'El Níspero',
      'El Negrito',
      'El Mochito',
      'El Milagro',
      'El Marañón',
      'El Lolo',
      'El Llano',
      'El Juncal',
      'Elíxir',
      'El Guayabito',
      'El Guapinol',
      'El Guantillo',
      'El Guante',
      'El Escaño de Tepale',
      'El Escanito',
      'El Cubolero',
      'El Corpús',
      'Corpus',
      'El Corozal',
      'El Ciruelo',
      'El Chimbo',
      'El Chichicaste',
      'El Benque',
      'El Bálsamo',
      'El Agua Dulcita',
      'El Achiotal',
      'Duyure',
      'Dulce Nombre de Culmí',
      'Dulce Nombre',
      'Dolores',
      'Danlí',
      'Cuyamel',
      'Cuyalí',
      'Cusuna',
      'Cucuyagua',
      'Coyoles Central',
      'Correderos',
      'Corquín',
      'Corozal',
      'Corocito',
      'Copán Ruinas',
      'Concordia',
      'Concepción del Sur',
      'Concepción de la Barranca',
      'Concepción de Guasistagua',
      'Comayagua',
      'Cololaca',
      'Cofradía',
      'Chotepe',
      'Ciudad Choluteca',
      'Choloma',
      'Chivana',
      'Chalmeca',
      'Cerro Grande',
      'Cerro Blanco',
      'Ceguaca',
      'Cedros',
      'Casa Quemada',
      'Carbajales',
      'Cane',
      'Cañaveral',
      'Campamento',
      'Camasca',
      'Camalote',
      'Callejones',
      'Buenos Aires',
      'Brus Laguna',
      'Bonito Oriental',
      'Berlín',
      'Belén Gualcho',
      'Bejuco',
      'Barra Patuca',
      'Baracoa',
      'Bálsamo Oriental',
      'Baja Mar',
      'Azacualpa',
      'Ayapa',
      'Auka',
      'Auas',
      'Atima',
      'Atenas de San Cristóbal',
      'Armenta',
      'Armenia',
      'Arizona',
      'Arimís',
      'Arenal',
      'Araulí',
      'Aramecina',
      'Arada',
      'Antigua Ocotepeque',
      'Amapala',
      'La Alianza',
      'Ajuterique',
      'Aguas del Padre',
      'Agualote',
      'Agua Fría',
      'Agua Caliente',
      'Agua Blanca Sur',
      'Agua Azul Rancho',
      'Agua Azul',
      'Agalteca',
      'Texíguat',
    ],
  },
  {
    country: 'Croatia',
    cities: [
      'Vranjic',
      'Županja',
      'Žumberak',
      'Žrnovo',
      'Žrnovnica',
      'Žminj',
      'Zlatar',
      'Sveti Ivan Zelina',
      'Ždralovi',
      'Zdenci Brdovečki',
      'Zdenci',
      'Zaprešić',
      'Žakanje',
      'Zagvozd',
      'Dubrava',
      'Zagreb',
      'Zadvorsko',
      'Zadvarje',
      'Zadar',
      'Sveti Križ Začretje',
      'Zabok',
      'Sveti Ivan Žabno',
      'Vukovar',
      'Vuka',
      'Vrsi',
      'Vrsar',
      'Vrpolje',
      'Vrhovine',
      'Vrgorac',
      'Vrginmost',
      'Vrbovsko',
      'Vrbovec',
      'Vrbnik',
      'Vrbje',
      'Vrbanja',
      'Vratišinec',
      'Seget Vranjica',
      'Voloder',
      'Vojnić',
      'Vodnjan',
      'Vođinci',
      'Vodice',
      'Voćin',
      'Vladislavci',
      'Vižinada',
      'Visoko',
      'Višnjevac',
      'Visignano',
      'Viškovci',
      'Vis',
      'Virovitica',
      'Virje',
      'Vir',
      'Vinkovci',
      'Vinica',
      'Vinež',
      'Viljevo',
      'Vidovec',
      'Velimirovac',
      'Veliko Trojstvo',
      'Veliko Trgovišće',
      'Veliki Grđevac',
      'Veliki Drvenik',
      'Veliki Bukovec',
      'Velika Trnovitica',
      'Velika Pisanica',
      'Velika Ostrna',
      'Velika Mlaka',
      'Velika Ludina',
      'Velika Kopanica',
      'Velika Gorica',
      'Velika',
      'Vela Luka',
      'Varaždinske Toplice',
      'Varaždin',
      'Valpovo',
      'Unešić',
      'Umag',
      'Ugljan',
      'Udbina',
      'Tužno',
      'Turjaci',
      'Turanj',
      'Tuhelj',
      'Tučepi',
      'Trpinja',
      'Trpanj',
      'Trogir',
      'Trnovec Bartolovečki',
      'Trnava',
      'Trilj',
      'Tribunj',
      'Tovarnik',
      'Tounj',
      'Tordinci',
      'Topusko',
      'Tompojevci',
      'Korenica',
      'Tinjan',
      'Tisno',
      'Tenja',
      'Tar',
      'Svetvinčenat',
      'Sveti Petar u Šumi',
      'Sveti Martin na Muri',
      'Sveti Ilija',
      'Sveti Ðurđ',
      'Sveta Nedjelja',
      'Sveta Marija',
      'Sutivan',
      'Supetarska Draga',
      'Supetar',
      'Sunja',
      'Sukošan',
      'Suhopolje',
      'Sućuraj',
      'Strmec',
      'Strizivojna',
      'Štrigova',
      'Strahoninec',
      'Ston',
      'Stobreč',
      'Štitar',
      'Štefanje',
      'Staro Petrovo Selo',
      'Stari Perkovci',
      'Stari Mikanovci',
      'Stari Jankovci',
      'Starigrad',
      'Stari Grad',
      'Stara Gradiška',
      'Gradina',
      'Stankovci',
      'Srinjine',
      'Srebreno',
      'Sračinec',
      'Split',
      'Špišić-Bukovica',
      'Sopje',
      'Soljani',
      'Solin',
      'Sokolovac',
      'Šodolovci',
      'Smokvica',
      'Slunj',
      'Slobodnica',
      'Slavonski Brod',
      'Požega',
      'Slatine',
      'Slano',
      'Škrljevo',
      'Skradin',
      'Skrad',
      'Škabrnje',
      'Sisak',
      'Široko Polje',
      'Sirač',
      'Sinj',
      'Sikirevci',
      'Sigetec',
      'Sibinj',
      'Šibenik',
      'Sesvete',
      'Šestanovac',
      'Šenkovec',
      'Senj',
      'Semeljci',
      'Selnica',
      'Selce',
      'Selca',
      'Satnica Ðakovačka',
      'Sarvaš',
      'Šandrovac',
      'Samobor',
      'Slavonski Šamac',
      'Sali',
      'Saborsko',
      'Ružić',
      'Ruščica',
      'Runović',
      'Rugvica',
      'Rude',
      'Rubeši',
      'Rovišće',
      'Rovinj',
      'Rokovci',
      'Rogoznica',
      'Rijeka',
      'Ribnik',
      'Rezovac',
      'Retkovci',
      'Rešetari',
      'Repušnica',
      'Remetinec',
      'Reka',
      'Ražanac',
      'Ravna Gora',
      'Rasinja',
      'Raša',
      'Rakov Potok',
      'Rakovica',
      'Rakovec',
      'Rakitje',
      'Radoboj',
      'Rabac',
      'Rab',
      'Pušćine',
      'Punitovci',
      'Punat',
      'Pula',
      'Pučišća',
      'Privlaka',
      'Primošten',
      'Primorski Dolac',
      'Prigorje Brdovečko',
      'Pridraga',
      'Pribislavec',
      'Prgomet',
      'Prelog',
      'Osljak',
      'Pregrada',
      'Predavac',
      'Povljana',
      'Postira',
      'Posedarje',
      'Poreč',
      'Popovača',
      'Popovac',
      'Poljanica Bistranska',
      'Poličnik',
      'Polača',
      'Pokupsko',
      'Pojatno',
      'Podvinje',
      'Podturen',
      'Podstrana',
      'Podravske Sesvete',
      'Slatina',
      'Podhum',
      'Podgorač',
      'Podgora',
      'Podcrkavlje',
      'Ploče',
      'Pleternica',
      'Plaški',
      'Pitomača',
      'Piškorevci',
      'Pisarovina',
      'Pirovac',
      'Pićan',
      'Petrovsko',
      'Petrinja',
      'Petrijevci',
      'Petrijanec',
      'Petlovac',
      'Peteranec',
      'Perušić',
      'Peklenica',
      'Pazin',
      'Pašman',
      'Pakrac',
      'Pakoštane',
      'Pag',
      'Ozalj',
      'Otok',
      'Otočac',
      'Oštarije',
      'Osijek',
      'Oroslavje',
      'Orle',
      'Oriovac',
      'Orešje',
      'Orehovica',
      'Sveti Petar Orehovec',
      'Orebić',
      'Orahovica',
      'Opuzen',
      'Oprtalj',
      'Oprisavci',
      'Opatija',
      'Omišalj',
      'Omiš',
      'Okučani',
      'Oklaj',
      'Ogulin',
      'Odra',
      'Obrovac',
      'Odranski Obrež',
      'Nuštar',
      'Novska',
      'Novo Selo Rok',
      'Novoselec',
      'Novo Čiče',
      'Novi Vinodolski',
      'Novi Marof',
      'Novigrad Podravski',
      'Novigrad',
      'Novi Golubovec',
      'Nova Rača',
      'Novalja',
      'Nova Kapela',
      'Nova Gradiška',
      'Nova Bukovica',
      'Njivice',
      'Nijemci',
      'Nerežišće',
      'Negoslavci',
      'Nedeščina',
      'Nedeljanec',
      'Nedelišće',
      'Našice',
      'Mursko Središće',
      'Murter',
      'Mrkopalj',
      'Mravince',
      'Mraclin',
      'Motovun',
      'Mošćenička Draga',
      'Mošćenica',
      'Molve',
      'Mokošica',
      'Mirkovci',
      'Ðulovac',
      'Milna',
      'Mikleuš',
      'Mihovljan',
      'Mičevec',
      'Metković',
      'Medulin',
      'Matulji',
      'Maruševec',
      'Martin',
      'Martijanec',
      'Markušica',
      'Markovac Našički',
      'Marjanci',
      'Marinići',
      'Marina',
      'Marija Gorica',
      'Marija Bistrica',
      'Marčelji',
      'Marčana',
      'Mali Lošinj',
      'Mali Bukovec',
      'Mala Subotica',
      'Makarska',
      'Mačkovec',
      'Mače',
      'Lužani',
      'Lupoglav',
      'Lumbarda',
      'Lukavec',
      'Lukač',
      'Luka',
      'Ludbreg',
      'Lučko',
      'Sveti Lovreč',
      'Lovreć',
      'Lovran',
      'Lovinac',
      'Lovas',
      'Lopatinec',
      'Lopar',
      'Lonjica',
      'Lokvičič',
      'Lokve',
      'Lobor',
      'Ljubešćica',
      'Ližnjan',
      'Lišane Ostrovičke',
      'Lipik',
      'Martinska Ves',
      'Lički Osik',
      'Levanjska Varoš',
      'Lepoglava',
      'Lekenik',
      'Legrad',
      'Lećevica',
      'Lastovo',
      'Laslovo',
      'Lasinja',
      'Lanišće',
      'Ladimirevci',
      'Labin',
      'Kutjevo',
      'Kutina',
      'Kuševac',
      'Kuršanec',
      'Kumrovec',
      'Kula Norinska',
      'Kuče',
      'Kućan Marof',
      'Kruševo',
      'Kruševica',
      'Kršan',
      'Krnjak',
      'Krk',
      'Križevci',
      'Križ',
      'Kravarsko',
      'Krasica',
      'Krašić',
      'Krapinske Toplice',
      'Krapina',
      'Kraljevica',
      'Kraljevec na Sutli',
      'Kozinščak',
      'Kotoriba',
      'Košute',
      'Kostrena',
      'Majur',
      'Hrvatska Kostajnica',
      'Koška',
      'Korčula',
      'Koprivnica',
      'Končanica',
      'Komletinci',
      'Komiža',
      'Komin',
      'Kolan',
      'Knin',
      'Kneževi Vinogradi',
      'Kloštar Podravski',
      'Kloštar Ivanić',
      'Klis',
      'Klinča Sela',
      'Klenovnik',
      'Klanjec',
      'Klana',
      'Klakar',
      'Kistanje',
      'Kijevo',
      'Kerestinec',
      'Kaštel Sućurac',
      'Kaštel Stari',
      'Kaštel Štafilić',
      'Kaštel Novi',
      'Kaštel Lukšić',
      'Kaštel Kambelovac',
      'Kaštelir',
      'Kaštel Gomilica',
      'Kastav',
      'Kašina',
      'Karojba',
      'Karlobag',
      'Karanac',
      'Kaptol',
      'Kapela',
      'Kanfanar',
      'Kamanje',
      'Kalnik',
      'Kalinovac',
      'Kali',
      'Josipovac',
      'Josipdol',
      'Ježdovec',
      'Jesenice',
      'Jelsa',
      'Jelisavac',
      'Jelenje',
      'Jastrebarsko',
      'Jasenovac',
      'Jarmina',
      'Janjina',
      'Jalžabet',
      'Jalkovec',
      'Jakšić',
      'Jakovlje',
      'Jagodnjak',
      'Jadranovo',
      'Jablanovec',
      'Ivanska',
      'Ivanovec',
      'Ivankovo',
      'Ivanja Reka',
      'Ivanić-Grad',
      'Ivanec',
      'Imotski',
      'Ilok',
      'Hvar',
      'Hum na Sutli',
      'Breznički Hum',
      'Hrvatski Leskovac',
      'Hrvace',
      'Hreljin',
      'Hrašćina',
      'Hrašće Turopoljsko',
      'Horvati',
      'Hodošan',
      'Hlebine',
      'Hercegovac',
      'Gunja',
      'Gundinci',
      'Gudovac',
      'Grubišno Polje',
      'Grožnjan',
      'Gradište',
      'Gradići',
      'Gradec',
      'Gradac',
      'Gračišće',
      'Gračec',
      'Gračac',
      'Gospić',
      'Okrug Gornji',
      'Gornji Mihaljevec',
      'Gornji Kneginec',
      'Gornji Karin',
      'Gornji Bogičevci',
      'Gornje Vratno',
      'Podbablje',
      'Gornje Jesenje',
      'Gornja Vrba',
      'Gornja Stubica',
      'Gornja Bistra',
      'Gorjani',
      'Goričan',
      'Gola',
      'Glina',
      'Generalski Stol',
      'Garešnica',
      'Garčin',
      'Galovac',
      'Galižana',
      'Gala',
      'Fužine',
      'Funtana',
      'Sveti Filip i Jakov',
      'Feričanci',
      'Ferdinandovac',
      'Fažana',
      'Ervenik',
      'Ernestinovo',
      'Erdut',
      'Dvor',
      'Ðurmanec',
      'Đurđevac',
      'Ðurđenovac',
      'Dugo Selo',
      'Dugopolje',
      'Dugi Rat',
      'Duga Resa',
      'Duće',
      'Dubrovnik',
      'Dubravica',
      'Hrvatska Dubica',
      'Drnje',
      'Drniš',
      'Novo Virje',
      'Drenovci',
      'Drenova',
      'Drenje',
      'Dražice',
      'Draž',
      'Draganići',
      'Dragalić',
      'Zemunik Donji',
      'Donji Vidovec',
      'Donji Seget',
      'Donji Proložac',
      'Donji Miholjac',
      'Donji Lapac',
      'Donji Kraljevec',
      'Donji Andrijevci',
      'Donje Ladanje',
      'Donja Zdenčina',
      'Donja Voća',
      'Donja Stubica',
      'Donja Pušća',
      'Donja Motičina',
      'Donja Lomnica',
      'Donja Dubrava',
      'Brela',
      'Donja Bistra',
      'Domašinec',
      'Dobrinj',
      'Dežanovac',
      'Desinić',
      'Delnice',
      'Ðelekovec',
      'Dekanovec',
      'Davor',
      'Daruvar',
      'Darda',
      'Dalj',
      'Đakovo',
      'Crnac',
      'Crikvenica',
      'Cres',
      'Civljane',
      'Cista Provo',
      'Čibača',
      'Cetingrad',
      'Cestica',
      'Cerovlje',
      'Cernik',
      'Cerna',
      'Cerić',
      'Čepin',
      'Čeminac',
      'Čazma',
      'Cavtat',
      'Čavle',
      'Čakovec',
      'Čaglin',
      'Čađavica',
      'Čačinci',
      'Buzet',
      'Bukovlje',
      'Buje',
      'Budrovci',
      'Budinšćina',
      'Budaševo',
      'Brtonigla',
      'Bršadin',
      'Brodski Varoš',
      'Brodski Stupnik',
      'Brod Moravice',
      'Brnaze',
      'Brinje',
      'Bribir',
      'Brezovac',
      'Breznica',
      'Brestovac',
      'Koprivnički Bregi',
      'Bregana',
      'Brdovec',
      'Brckovljani',
      'Bošnjaci',
      'Bosiljevo',
      'Borovo',
      'Bol',
      'Bogdanovci',
      'Bobota',
      'Blato',
      'Bjelovar',
      'Bizovac',
      'Bistrinci',
      'Biskupija',
      'Biograd na Moru',
      'Bilje',
      'Bilice',
      'Bijelo Brdo',
      'Bibinje',
      'Beretinec',
      'Berek',
      'Benkovac',
      'Belišće',
      'Beli Manastir',
      'Belica',
      'Bednja',
      'Bedenica',
      'Bedekovčina',
      'Bebrina',
      'Batrina',
      'Baška Voda',
      'Baška',
      'Barilović',
      'Barban',
      'Banjol',
      'Bale',
      'Bakar',
      'Babino Polje',
      'Babina Greda',
      'Antunovac',
      'Andrijaševci',
      'Gornji Stupnik',
      'Novaki',
      'Jankomir',
      'Bestovje',
      'Novi Zagreb',
      'Brodarica',
      'Zagorska Sela',
      'Buzdohanj',
      'Otrić-Seoci',
      'Donji Vinjani',
      'Netretić',
      'Donji Kukuruzari',
      'Lipovljani',
      'Kukljica',
      'Preko',
      'Koprivnički Ivanec',
      'Farkaševac',
      'Nin',
      'Kampor',
      'Vidovci',
      'Borovo Selo',
      'Kamen',
      'Zmijavci',
      'Greda',
      'Grabovci',
      'Grubine',
      'Vlaka',
      'Brijest',
      'Karlovac',
      'Centar',
      'Stubičke Toplice',
      'Viškovo',
      'Donji Stupnik',
      'Hrašćica',
      'Mihotići',
      'Valbandon',
      'Konjšćina',
      'Parag',
      'Savska Ves',
      'Kopčevec',
      'Veliko Polje',
      'Lukarišće',
    ],
  },
  {
    country: 'Haiti',
    cities: [
      'Verrettes',
      'Trou du Nord',
      'Torbeck',
      'Tiburon',
      'Thomonde',
      'Thomazeau',
      'Thomassique',
      'Thiotte',
      'Saint-Raphaël',
      'Saint-Marc',
      'Saint-Louis du Sud',
      'Saint-Louis du Nord',
      'Roche-à-Bateau',
      'Ranquitte',
      'Quartier Morin',
      'Port-Margot',
      'Port-de-Paix',
      'Port-au-Prince',
      'Port-à-Piment',
      'Plaisance',
      'Plaine du Nord',
      'Pilate',
      'Pignon',
      'Phaëton',
      'Petit Trou de Nippes',
      'Tigwav',
      'Petite Rivière de Nippes',
      'Petite Anse',
      'Pétionville',
      'Perches',
      'Ouanaminthe',
      'Moron',
      'Montòrganize',
      'Môle Saint-Nicolas',
      'Mirebalais',
      'Miragoâne',
      'Milot',
      'Marmelade',
      'Marigot',
      'Mayisad',
      'Limonade',
      'Lenbe',
      'Les Irois',
      'Les Anglais',
      'Léogâne',
      'Borgne',
      'Lascahobas',
      'Kenscoff',
      'Jérémie',
      'Jean-Rabel',
      'Jacmel',
      'Hinche',
      'Gros Morne',
      'Gressier',
      'Grangwav',
      'Grande Saline',
      'Grande Rivière du Nord',
      'Gonaïves',
      'Fort Liberté',
      'Fonds Verrettes',
      'Fond Parisien',
      'Fond des Blancs',
      'Fond Bassin Bleu',
      'Ferrier',
      'Ennery',
      'Dondon',
      'Dessalines',
      'Désarmes',
      'Dérac',
      'Delmas 73',
      'Dame-Marie',
      'Croix-des-Bouquets',
      'Kotdefè',
      'Koto',
      'Cornillon',
      'Corail',
      'Chardonnière',
      'Chantal',
      'Chambellan',
      'Cerca la Source',
      'Cayes-Jacmel',
      'Les Cayes',
      'Cavaillon',
      'Carrefour',
      'Carice',
      'Caracol',
      'Okap',
      'Cabaret',
      'Bombardopolis',
      'Belle-Anse',
      'Baradères',
      'Baie de Henne',
      'Bahon',
      'Arcahaie',
      'Aquin',
      'Anse Rouge',
      'Anse-à-Veau',
      'Anse-à-Pitre',
      'Anse à Galets',
      'Acul du Nord',
      'Les Abricots',
      'Ti Port-de-Paix',
    ],
  },
  {
    country: 'Hungary',
    cities: [
      'Záhony',
      'Zagyvarékas',
      'Vésztő',
      'Verpelét',
      'Vásárosnamény',
      'Vámospércs',
      'Vaja',
      'Újszász',
      'Újkígyós',
      'Újfehértó',
      'Tyukod',
      'Tuzsér',
      'Túrkeve',
      'Tunyogmatolcs',
      'Tótkomlós',
      'Tószeg',
      'Törökszentmiklós',
      'Tornyospálca',
      'Tömörkény',
      'Tolcsva',
      'Tokaj',
      'Tiszavasvári',
      'Tiszaújváros',
      'Tiszaszentimre',
      'Tiszasüly',
      'Tiszaroff',
      'Tiszapüspöki',
      'Tiszanána',
      'Tiszanagyfalu',
      'Tiszalúc',
      'Tiszalök',
      'Tiszakeszi',
      'Tiszakécske',
      'Tiszakarád',
      'Tiszafüred',
      'Tiszaföldvár',
      'Tiszaeszlár',
      'Tiszadob',
      'Tiszadada',
      'Tiszacsege',
      'Tiszabura',
      'Tiszabő',
      'Tiszabezdéd',
      'Tiszabercel',
      'Téglás',
      'Tarpa',
      'Tarnaörs',
      'Tarnalelesz',
      'Tarcal',
      'Tállya',
      'Taktaszada',
      'Taktaharkány',
      'Szolnok',
      'Szirmabesenyő',
      'Szilvásvárad',
      'Szikszó',
      'Szihalom',
      'Szerencs',
      'Szentistván',
      'Szentes',
      'Szendrő',
      'Székkutas',
      'Szegvár',
      'Szeghalom',
      'Szeged',
      'Szatymaz',
      'Szarvas',
      'Szamosszeg',
      'Szakoly',
      'Szajol',
      'Szabadkígyós',
      'Sirok',
      'Sátoraljaújhely',
      'Sárrétudvari',
      'Sárospatak',
      'Sarkad',
      'Sáránd',
      'Sándorfalva',
      'Sály',
      'Sajóvámos',
      'Sajószöged',
      'Sajószentpéter',
      'Sajóörös',
      'Sajólád',
      'Sajókaza',
      'Sajóbábony',
      'Rudabánya',
      'Röszke',
      'Ricse',
      'Recsk',
      'Rákócziújfalu',
      'Rákóczifalva',
      'Rakamaz',
      'Putnok',
      'Pusztaföldvár',
      'Püspökladány',
      'Prügy',
      'Poroszló',
      'Porcsalma',
      'Polgár',
      'Pocsaj',
      'Petneháza',
      'Pétervására',
      'Pátroha',
      'Parád',
      'Ózd',
      'Ostoros',
      'Orosháza',
      'Ópusztaszer',
      'Ópályi',
      'Ónod',
      'Onga',
      'Olaszliszka',
      'Ököritófülpös',
      'Okány',
      'Ófehértó',
      'Öcsöd',
      'Nyírvasvári',
      'Nyírtelek',
      'Nyírtass',
      'Nyírpazony',
      'Nyírmihálydi',
      'Nyírmeggyes',
      'Nyírmártonfalva',
      'Nyírmada',
      'Nyírlugos',
      'Nyírkarász',
      'Nyírgyulaj',
      'Nyíregyháza',
      'Nyírcsaholy',
      'Nyírbogdány',
      'Nyírbogát',
      'Nyírbéltek',
      'Nyírbátor',
      'Nyíradony',
      'Nyíracsád',
      'Nyírábrány',
      'Nyékládháza',
      'Napkor',
      'Nagyszénás',
      'Nagyrábé',
      'Nagykálló',
      'Nagyhalász',
      'Nagyecsed',
      'Nagydobos',
      'Nagycserkesz',
      'Nádudvar',
      'Múcsony',
      'Monostorpályi',
      'Monok',
      'Miskolc',
      'Mindszent',
      'Mikepércs',
      'Mezőzombor',
      'Mezőtúr',
      'Mezőkövesd',
      'Mezőkovácsháza',
      'Mezőkeresztes',
      'Mezőhegyes',
      'Mezőcsát',
      'Mezőberény',
      'Mérk',
      'Méhkerék',
      'Megyaszó',
      'Medgyesegyháza',
      'Mátraderecske',
      'Mátészalka',
      'Maroslele',
      'Máriapócs',
      'Mándok',
      'Mályi',
      'Makó',
      'Maklár',
      'Magyarbánhegyes',
      'Mád',
      'Lőkösháza',
      'Levelek',
      'Létavértes',
      'Kunszentmárton',
      'Kunmadaras',
      'Kunhegyes',
      'Kunágota',
      'Kótaj',
      'Köröstarcsa',
      'Körösladány',
      'Konyár',
      'Kondoros',
      'Kompolt',
      'Komádi',
      'Kocsord',
      'Kiszombor',
      'Kisvárda',
      'Kisújszállás',
      'Kisléta',
      'Kisköre',
      'Kevermes',
      'Kétegyháza',
      'Kerecsend',
      'Kengyel',
      'Kenderes',
      'Kemecse',
      'Kék',
      'Kazincbarcika',
      'Kaszaper',
      'Karcsa',
      'Karcag',
      'Karácsond',
      'Kántorjánosi',
      'Kálmánháza',
      'Kállósemjén',
      'Kál',
      'Kaba',
      'Jászszentandrás',
      'Jászladány',
      'Jászkisér',
      'Jászkarajenő',
      'Jászjákóhalma',
      'Jászdózsa',
      'Jászapáti',
      'Jászalsószentgyörgy',
      'Járdánháza',
      'Jánoshida',
      'Izsófalva',
      'Ibrány',
      'Hosszúpályi',
      'Hortobágy',
      'Hódmezővásárhely',
      'Hodász',
      'Heves',
      'Hernádnémeti',
      'Hejőbába',
      'Harsány',
      'Halmaj',
      'Hajdúszovát',
      'Hajdúszoboszló',
      'Hajdúsámson',
      'Hajdúnánás',
      'Hajdúhadház',
      'Hajdúdorog',
      'Hajdúböszörmény',
      'Hajdúbagos',
      'Gyulaháza',
      'Gyula',
      'Gyomaendrőd',
      'Görbeháza',
      'Gönc',
      'Gesztely',
      'Gégény',
      'Gádoros',
      'Füzesgyarmat',
      'Füzesabony',
      'Földes',
      'Földeák',
      'Fényeslitke',
      'Felsőzsolca',
      'Felsőtárkány',
      'Fehérgyarmat',
      'Fegyvernek',
      'Farkaslyuk',
      'Fábiánsebestyén',
      'Erdőtelek',
      'Encsencs',
      'Encs',
      'Emőd',
      'Elek',
      'Egyek',
      'Egerszalók',
      'Eger',
      'Edelény',
      'Ebes',
      'Domoszló',
      'Dombrád',
      'Dombegyház',
      'Domaszék',
      'Döge',
      'Doboz',
      'Dévaványa',
      'Deszk',
      'Derecske',
      'Demecser',
      'Debrecen',
      'Csorvás',
      'Csongrád',
      'Csökmő',
      'Cserkeszőlő',
      'Csenger',
      'Csanytelek',
      'Csanádpalota',
      'Csanádapáca',
      'Cigánd',
      'Cibakháza',
      'Buj',
      'Bucsa',
      'Borsodnádasd',
      'Boldva',
      'Bököny',
      'Bogács',
      'Bőcs',
      'Biharnagybajom',
      'Biharkeresztes',
      'Besenyszög',
      'Besenyőtelek',
      'Berettyóújfalu',
      'Bélapátfalva',
      'Békésszentandrás',
      'Békéssámson',
      'Békéscsaba',
      'Békés',
      'Bekecs',
      'Battonya',
      'Báránd',
      'Balmazújváros',
      'Balkány',
      'Balástya',
      'Baktalórántháza',
      'Baks',
      'Bagamér',
      'Aszaló',
      'Arnót',
      'Arló',
      'Aranyosapáti',
      'Apátfalva',
      'Apagy',
      'Andornaktálya',
      'Anarcs',
      'Alsózsolca',
      'Alattyán',
      'Ajak',
      'Abony',
      'Abaújszántó',
      'Abasár',
      'Abádszalók',
      'Zugló',
      'Zsombó',
      'Zsámbok',
      'Zsámbék',
      'Zomba',
      'Zirc',
      'Zánka',
      'Zámoly',
      'Zamárdi',
      'Zalaszentgrót',
      'Zalalövő',
      'Zalakomár',
      'Zalaegerszeg',
      'Zákányszék',
      'Zagyvaszántó',
      'Vonyarcvashegy',
      'Visegrád',
      'Villány',
      'Veszprém',
      'Vértesszőlős',
      'Verőce',
      'Veresegyház',
      'Vép',
      'Velence',
      'Vecsés',
      'Vasvár',
      'Vaskút',
      'Várpalota',
      'Városföld',
      'Vámosgyörk',
      'Valkó',
      'Vál',
      'Vajszló',
      'Vácszentlászló',
      'Vác',
      'Üröm',
      'Úrkút',
      'Úri',
      'Üllő',
      'Üllés',
      'Újszilvás',
      'Újhartyán',
      'Türje',
      'Tura',
      'Törtel',
      'Törökbálint',
      'Tompa',
      'Töltéstava',
      'Tolna',
      'Tököl',
      'Tokod',
      'Tóalmás',
      'Tihany',
      'Tét',
      'Tengelic',
      'Telki',
      'Tázlár',
      'Tatabánya',
      'Tata',
      'Tát',
      'Taszár',
      'Tass',
      'Tárnok',
      'Tarján',
      'Tardos',
      'Tar',
      'Tapolca',
      'Táplánszentkereszt',
      'Tápiószőlős',
      'Tápiószentmárton',
      'Tápiószele',
      'Tápiószecső',
      'Sülysáp',
      'Tápióság',
      'Tápiógyörgye',
      'Tápióbicske',
      'Tamási',
      'Taksony',
      'Tahitótfalu',
      'Táborfalva',
      'Tab',
      'Szurdokpüspöki',
      'Szomód',
      'Szombathely',
      'Sződliget',
      'Sződ',
      'Szob',
      'Szigetvár',
      'Szigetújfalu',
      'Szigetszentmiklós',
      'Szigethalom',
      'Szigetcsép',
      'Szentmártonkáta',
      'Szentlőrinckáta',
      'Szentlőrinc',
      'Szentkirályszabadja',
      'Szentkirály',
      'Szentgotthárd',
      'Szentgálpuszta',
      'Szentendre',
      'Szekszárd',
      'Székesfehérvár',
      'Szedres',
      'Szécsény',
      'Százhalombatta',
      'Szászvár',
      'Szárliget',
      'Szany',
      'Szank',
      'Szalkszentmárton',
      'Szada',
      'Szabadszállás',
      'Szabadbattyán',
      'Süttő',
      'Sümeg',
      'Sükösd',
      'Sóskút',
      'Sopron',
      'Soponya',
      'Somoskőújfalu',
      'Somogyvár',
      'Solymár',
      'Soltvadkert',
      'Solt',
      'Siófok',
      'Sióagárd',
      'Simontornya',
      'Siklós',
      'Seregélyes',
      'Sellye',
      'Segesd',
      'Sásd',
      'Sárvár',
      'Sárszentmihály',
      'Sárosd',
      'Sármellék',
      'Sárkeresztúr',
      'Sárisáp',
      'Sárbogárd',
      'Salgótarján',
      'Ruzsa',
      'Rózsaszentmárton',
      'Romhány',
      'Rimóc',
      'Révfülöp',
      'Rétság',
      'Répcelak',
      'Rajka',
      'Ráckeve',
      'Ráckeresztúr',
      'Rácalmás',
      'Rábapatona',
      'Pusztavám',
      'Pusztaszer',
      'Pusztaszabolcs',
      'Pomáz',
      'Polgárdi',
      'Pincehely',
      'Pilisvörösvár',
      'Pilisszentkereszt',
      'Pilisszentiván',
      'Pilisszántó',
      'Pilismarót',
      'Piliscsév',
      'Piliscsaba',
      'Pilisborosjenő',
      'Pilis',
      'Petőfibánya',
      'Pétfürdő',
      'Péteri',
      'Perkáta',
      'Perbál',
      'Pér',
      'Pellérd',
      'Pécsvárad',
      'Pécs',
      'Pécel',
      'Pázmánd',
      'Páty',
      'Pásztó',
      'Parádsasvár',
      'Pápa',
      'Pannonhalma',
      'Pánd',
      'Palotás',
      'Pálmonostora',
      'Paks',
      'Pákozd',
      'Pacsa',
      'Ozora',
      'Öttevény',
      'Ősi',
      'Oroszlány',
      'Örkény',
      'Orgovány',
      'Őrbottyán',
      'Őcsény',
      'Ócsa',
      'Nyúl',
      'Nyergesújfalu',
      'Nyárlőrinc',
      'Nyáregyháza',
      'Neszmély',
      'Németkér',
      'Nemesvámos',
      'Nemesnádudvar',
      'Naszály',
      'dunaújváros',
      'Nagytarcsa',
      'Nagyréde',
      'Nagyoroszi',
      'Nagymaros',
      'Nagymányok',
      'Nagykovácsi',
      'Nagykőrös',
      'Nagykáta',
      'Nagykanizsa',
      'Nagyigmánd',
      'Nagydorog',
      'Nagycenk',
      'Nagybaracska',
      'Nagybajom',
      'Nagyatád',
      'Murakeresztúr',
      'Mosonmagyaróvár',
      'Mórahalom',
      'Mór',
      'Monor',
      'Mohács',
      'Mogyoród',
      'Mocsa',
      'Mihályi',
      'Mezőszilas',
      'Mezőfalva',
      'Mende',
      'Mélykút',
      'Mecseknádasd',
      'Mátraverebély',
      'Mátraterenye',
      'Mátranovák',
      'Martonvásár',
      'Marcali',
      'Mány',
      'Mágocs',
      'Maglód',
      'Madocsa',
      'Madaras',
      'Lovasberény',
      'Lőrinci',
      'Litér',
      'Letenye',
      'Lepsény',
      'Lenti',
      'Lengyeltóti',
      'Lébény',
      'Leányfalu',
      'Lánycsók',
      'Lakitelek',
      'Lajosmizse',
      'Lajoskomárom',
      'Lábod',
      'Lábatlan',
      'Kunszentmiklós',
      'Kunfehértó',
      'Kozármisleny',
      'Kőszeg',
      'Kosd',
      'Környe',
      'Körmend',
      'Kóny',
      'Komló',
      'Komárom',
      'Kóka',
      'Kocsér',
      'Kocs',
      'Kőbánya',
      'Kistelek',
      'Kisszállás',
      'Kispest',
      'Kisláng',
      'Kiskunmajsa',
      'Kiskunlacháza',
      'Kiskunhalas',
      'Kiskunfélegyháza',
      'Kiskőrös',
      'Kisbér',
      'Kincsesbánya',
      'Kimle',
      'Kéthely',
      'Kesztölc',
      'Keszthely',
      'Kistarcsa',
      'Kerekegyháza',
      'Kelebia',
      'Kecskemét',
      'Kecskéd',
      'Kecel',
      'Kazár',
      'Katymár',
      'Kartal',
      'Karancslapujtő',
      'Karancskeszi',
      'Karád',
      'Kapuvár',
      'Kaposvár',
      'Kaposmérő',
      'Kápolnásnyék',
      'Káloz',
      'Kalocsa',
      'Kakucs',
      'Kadarkút',
      'Jobbágyi',
      'Jászszentlászló',
      'Jászberény',
      'Jászárokszállás',
      'Jánossomorja',
      'Jánosháza',
      'Jánoshalma',
      'Fülöpjakab',
      'Ják',
      'Izsák',
      'Iváncsa',
      'Isaszeg',
      'Iregszemcse',
      'Inárcs',
      'Iklad',
      'Hosszúhetény',
      'Hort',
      'Hőgyész',
      'Hidas',
      'Hévízgyörk',
      'Hévíz',
      'Hernád',
      'Herend',
      'Heréd',
      'Hercegszántó',
      'Helvécia',
      'Hatvan',
      'Harta',
      'Harkány',
      'Halásztelek',
      'Halászi',
      'Hajós',
      'Hajmáskér',
      'Győrújbarát',
      'Győrszemere',
      'Győr',
      'Gyönk',
      'Gyöngyöstarján',
      'Gyöngyössolymos',
      'Gyöngyöspata',
      'Gyöngyöshalász',
      'Gyöngyös',
      'Gyömrő',
      'Gyenesdiás',
      'Gyál',
      'Gomba',
      'Gödöllő',
      'Göd',
      'Gencsapáti',
      'Gárdony',
      'Gara',
      'Galgamácsa',
      'Galgahévíz',
      'Fülöpszállás',
      'Fót',
      'Forrópuszta',
      'Forráskút',
      'Fonyód',
      'Fertőszentmiklós',
      'Fertőrákos',
      'Fertőd',
      'Felsőszentiván',
      'Felsőpakony',
      'Fehérvárcsurgó',
      'Farmos',
      'Farád',
      'Fadd',
      'Etyek',
      'Esztergom',
      'Erzsébetváros',
      'Érsekvadkert',
      'Érsekcsanád',
      'Erdőkertes',
      'Érd',
      'Ercsi',
      'Enying',
      'Előszállás',
      'Ecser',
      'Ecséd',
      'Dusnok',
      'Dunavecse',
      'Dunavarsány',
      'Dunaújváros',
      'Dunaszentgyörgy',
      'Dunaszekcső',
      'Dunapataj',
      'Dunakeszi',
      'Dunaharaszti',
      'Dunaföldvár',
      'Dunabogdány',
      'Dunaalmás',
      'Dorog',
      'Dömsöd',
      'Domony',
      'Dombóvár',
      'Döbrököz',
      'Diósjenő',
      'Diósd',
      'Devecser',
      'Délegyháza',
      'Dég',
      'Decs',
      'Dávod',
      'Dány',
      'Dánszentmiklós',
      'Dabas',
      'Csurgó',
      'Csorna',
      'Csopak',
      'Csömör',
      'Csolnok',
      'Csokonyavisonta',
      'Csobánka',
      'Csetény',
      'Cserszegtomaj',
      'Csepreg',
      'Csengőd',
      'Csengele',
      'Csemő',
      'Csávoly',
      'Császártöltés',
      'Császár',
      'Csány',
      'Csákvár',
      'Csabrendek',
      'Celldömölk',
      'Ceglédbercel',
      'Cegléd',
      'Cece',
      'Bükkösd',
      'Bük',
      'Buják',
      'Bugyi',
      'Bugac',
      'Budapest',
      'Budaörs',
      'Budakeszi',
      'Budakalász',
      'Aszód',
      'Bősárkány',
      'Bordány',
      'Bonyhád',
      'Bőny',
      'Bóly',
      'Boldog',
      'Bölcske',
      'Bokod',
      'Böhönye',
      'Bogyiszló',
      'Bodajk',
      'Bicske',
      'Biatorbágy',
      'Berzence',
      'Berhida',
      'Beremend',
      'Bercel',
      'Beled',
      'Becsehely',
      'Bátya',
      'Bátonyterenye',
      'Bátaszék',
      'Báta',
      'Barcs',
      'Baracska',
      'Baracs',
      'Ballószög',
      'Balatonszárszó',
      'Balatonszabadi',
      'Balatonlelle',
      'Balatonkenese',
      'Balatonfüred',
      'Balatonföldvár',
      'Balatonfenyves',
      'Balatonboglár',
      'Balatonberény',
      'Balatonalmádi',
      'Balassagyarmat',
      'Bakonyszentlászló',
      'Bakonycsernye',
      'Bajna',
      'Baja',
      'Baj',
      'Bag',
      'Badacsonytomaj',
      'Bácsbokod',
      'Bácsalmás',
      'Bábolna',
      'Babócsa',
      'Ásványráró',
      'Ásotthalom',
      'Apostag',
      'Apc',
      'Angyalföld',
      'Alsónémedi',
      'Almásfüzitő',
      'Albertirsa',
      'Alap',
      'Akasztó',
      'Ajka',
      'Ágfalva',
      'Ágasegyháza',
      'Adony',
      'Ádánd',
      'Adács',
      'Acsa',
      'Ács',
      'Abda',
      'Aba',
      'Tiszaalpár',
      'Budapest XII. kerület',
      'Budapest XI. kerület',
      'Budapest VIII. kerület',
      'Budapest VI. kerület',
      'Budapest XIII. kerület',
      'Budapest IV. kerület',
      'Budapest XV. kerület',
      'Budapest XVI. kerület',
      'Budapest XIX. kerület',
      'Budapest XVIII. kerület',
      'Budapest XXIII. kerület',
      'Budapest XXII. kerület',
      'Budapest XXI. kerület',
      'Budapest XX. kerület',
      'Budapest XVII. kerület',
      'Budapest III. kerület',
      'Budapest II. kerület',
      'Budapest I. kerület',
      'Algyő',
      'Szelevény',
      'Kismaros',
      'Héhalom',
      'Mosonszentmiklós',
      'Kerepes',
      'Tiszaszőlős',
      'Józsefváros',
    ],
  },
  {
    country: 'Indonesia',
    cities: [
      'Ujung Gading',
      'Ujungbatu',
      'Tualangcut',
      'Tongging',
      'Tomok Bolon',
      'Tiku',
      'Teluk Nibung',
      'Teluk Dalam',
      'Tebingtinggi',
      'Tarutung',
      'Tanjungtiram',
      'Tanjungbalai',
      'Tangse',
      'Talu',
      'Takengon',
      'Susoh',
      'Sunggal',
      'Sei Rampah',
      'Stabat',
      'Sondi',
      'Sipirok',
      'Sipiongot',
      'Sipagimbar',
      'Singkil',
      'Sinabang',
      'Simpang Empat',
      'Sigli',
      'Sidikalang',
      'Sibuhuan',
      'Siborong-Borong',
      'Sibolga',
      'Sibigo',
      'Siabu',
      'Seulimeum',
      'Serawai',
      'Saribudolok',
      'Salak',
      'Sabang',
      'Rikitgaib',
      'Reuleuet',
      'Rantauprapat',
      'Parapat',
      'Pintupadang',
      'Peureulak',
      'Percut',
      'Perbaungan',
      'Penaron',
      'Pematangsiantar',
      'Pematang Raya',
      'Parmonangan',
      'Parlilitan',
      'Panyambungan',
      'Pangururan',
      'Pangkalan Brandan',
      'Pakkat',
      'Padangtiji',
      'Padangsidempuan',
      'Natal',
      'Nainggolan',
      'Muara Soma',
      'Muara Sipongi',
      'Sikabaluan',
      'Muara Siberut',
      'Muara',
      'Montasik',
      'Mogang',
      'Meureubo',
      'Meulaboh',
      'Medan',
      'Lubuk Pakam',
      'Lintongnihuta',
      'Limapuluh',
      'Lhoong',
      'Lhokseumawe',
      'Lhoknga',
      'Lhokkruet',
      'Laweueng',
      'Langsa',
      'Langgapayung',
      'Lampuyang',
      'Lamno',
      'Labuhan Deli',
      'Kuala Bhee',
      'Kruengraya',
      'Krueng Luak',
      'Kisaran',
      'Kabanjahe',
      'Isak',
      'Kotanopan',
      'Gunung Tua',
      'Gunungsitoli',
      'Gosong Telaga',
      'Dolok Sanggul',
      'Deli Tua',
      'Celala',
      'Calang',
      'Blangpidie',
      'Blangkejeren',
      'Bireun',
      'Binjai',
      'Binanga',
      'Berastagi',
      'Belawan',
      'Bayeuen',
      'Batangtoru',
      'Barus',
      'Bandar',
      'Banda Aceh',
      'Balige',
      'Bakungan',
      'Asan Kumbang',
      'Ambarita',
      'Air Bangis',
      'Terangun',
      'Kenyaran',
      'Pining',
      'Yosowilangun',
      'Yogyakarta',
      'Wuluhan',
      'Wotu',
      'Wori',
      'Wonosobo',
      'Wonosari',
      'Wonorejo',
      'Wonopringgo',
      'Wonogiri',
      'Wongsorejo',
      'Wolowaru',
      'Wlingi',
      'Witihama',
      'Wiradesa',
      'Weru',
      'Werinama',
      'Windesi',
      'Weleri',
      'Welahan',
      'Wedi',
      'Wedarijaksa',
      'Weda',
      'Wates',
      'Watansoppeng',
      'Watampone',
      'Waru',
      'Waren',
      'Wardo',
      'Wanurian',
      'Wangon',
      'Wanci',
      'Wanareja',
      'Wanaraja',
      'Wamena',
      'Wajak',
      'Waiwerang',
      'Waisai',
      'Waigete',
      'Waipukang',
      'Waingapu',
      'Waimangura',
      'Waikabubak',
      'Waigama',
      'Waibakul',
      'Wahai',
      'Utan',
      'Uta',
      'Ungaran',
      'Ketapang',
      'Kolaka',
      'Ulu',
      'Ujungpangkah',
      'Makassar',
      'Ujoh Bilang',
      'Ubud',
      'Turen',
      'Tunjungan',
      'Tumpang',
      'Tulungagung',
      'Tulangan Utara',
      'Tuban',
      'Tual',
      'Trucuk',
      'Trenggalek',
      'Terawas',
      'Tongutisungi',
      'Tangga',
      'Tondano',
      'Tomu',
      'Tomohon',
      'Tombatu',
      'Tolitoli',
      'Toho',
      'Tobelo',
      'Tiris',
      'Tiom',
      'Tinabogan',
      'Tembuni',
      'Tilamuta',
      'Tigi',
      'Tideng Pale',
      'Ternate',
      'Terbanggi Besar',
      'Tepas',
      'Tentena',
      'Tenga',
      'Teminabuan',
      'Tembuku',
      'Tembilahan',
      'Temanggung',
      'Telukpakedai',
      'Tekung',
      'Tehoru',
      'Tegalalang',
      'Tegal',
      'Tebing Tinggi',
      'Tayu',
      'Tayan',
      'Tanusan',
      'Tatelu',
      'Tasikmalaya',
      'Tarub',
      'Tarogong',
      'Tarempa',
      'Tarakan',
      'Taraju',
      'Tapalang',
      'Tanjung Selor',
      'Tanjungsakti',
      'Tanjungraja',
      'Tanjung Pinang',
      'Tanjungpati',
      'Tanjung Pandan',
      'Tanjung Lubuk',
      'Bandar Lampung',
      'Tanjunggadang',
      'Tanjungbumi',
      'Tanjungbatu',
      'Tanjungampalu',
      'Tanjungagung',
      'Tanjung',
      'Taniwel',
      'Tongkiling',
      'Tanggulangin',
      'Tanggul',
      'Tanggeung',
      'Tangerang',
      'Tanahwangko',
      'Tana Paser',
      'Tamiang Layang',
      'Tamberu',
      'Tambaksari',
      'Tambak',
      'Tamako',
      'Talun',
      'Teluk Kuantan',
      'Taliwang',
      'Talise',
      'Talawi',
      'Talang Ubi',
      'Talangbetutu',
      'Takokak',
      'Takalar',
      'Tajinan',
      'Tais',
      'Tagolu',
      'Taccipi',
      'Tabing',
      'Tabanan',
      'Susupu',
      'Lasusua',
      'Surulangun Rawas',
      'Surian',
      'Surakarta',
      'Surade',
      'Surabaya',
      'Suban Jeriji',
      'Sungsang',
      'Sungguminasa',
      'Sungaikakap',
      'Sungai Salak',
      'Sungairotan',
      'Sungairaya',
      'Sungai Raya',
      'Sungai Pinyuh',
      'Sungai Penuh',
      'Sungailimau',
      'Sungailiat',
      'Sungaikupang',
      'Sungaigerong',
      'Sungaiduri',
      'Sungaibali',
      'Semurup',
      'Sumpur Kudus',
      'Sumenep',
      'Sumedang Utara',
      'Sumedang',
      'Sumberpucung',
      'Sumbermanjing Wetan',
      'Sumberjati',
      'Sumberjambe',
      'Sumberbaru',
      'Sumberanyar',
      'Sumber',
      'Sumbawa Besar',
      'Sulitair',
      'Sulang Tengah',
      'Sukowono',
      'Sukoharjo',
      'Sukawening',
      'Sukasari',
      'Sukasada',
      'Sokaraja',
      'Sukaraja',
      'Sukanagara',
      'Sukadana',
      'Sukabumi',
      'Subang',
      'Subaim',
      'Stagen',
      'Srono',
      'Srandakan',
      'Sragen',
      'Sorong',
      'Insrom',
      'Sorendiweri',
      'Soreang',
      'Sonder',
      'Somba',
      'Solok',
      'Sokobanah',
      'Soko',
      'Sofifi',
      'Soe',
      'Sodong Hilir',
      'Sluke',
      'Sleman',
      'Slawi',
      'Siwa',
      'Situbondo',
      'Sintang',
      'Sinjai',
      'Singosari',
      'Singojuruh',
      'Singkawang',
      'Singkarak',
      'Sengkang',
      'Singaraja',
      'Singaparna',
      'Sindangbarang',
      'Simpur',
      'Simpangempat',
      'Simpang',
      'Sila',
      'Sijunjung',
      'Sidoarjo',
      'Sidemen',
      'Sidareja',
      'Siak Sri Indrapura',
      'Sewon',
      'Seteluk',
      'Sesayap',
      'Serui',
      'Serpong',
      'Serasan',
      'Serang',
      'Sepatan',
      'Senori',
      'Sengeti',
      'Sangatta',
      'Senduro',
      'Semuda',
      'Semongkat',
      'Semilat',
      'Semboro Lor',
      'Semarang',
      'Semamung',
      'Selorejo',
      'Selong',
      'Selogiri',
      'Selat Panjang',
      'Selajambe',
      'Sekupang',
      'Sekongkang Bawah',
      'Sekayu',
      'Sekernan',
      'Sekampung',
      'Sekadau',
      'Seget',
      'Sedinginan',
      'Sidayu',
      'Sedanau',
      'Sedan',
      'Sebulu',
      'Sebuku',
      'Sawangan',
      'Sawang',
      'Saukorem',
      'Satui',
      'Saruaso',
      'Saronggi',
      'Sarolangun',
      'Sarmi',
      'Sarilamak',
      'Sarebe',
      'Sepulu',
      'Sapeken',
      'Sape',
      'Sapat',
      'Saparua',
      'Saonek',
      'Sausapor',
      'Benua Baru',
      'Sanggau',
      'Kore',
      'Sangasangadalam',
      'Sanana',
      'Sampit',
      'Sampang',
      'Sampalan',
      'Samboja',
      'Sumberbaba',
      'Sambas',
      'Samarinda',
      'Tanjungsamalantakan',
      'Salopa',
      'Salinbatu',
      'Salem',
      'Sale',
      'Salawu',
      'Salatiga',
      'Salakan',
      'Sakra',
      'Saketi',
      'Sagaranten',
      'Sadananya',
      'Sabang Barat',
      'Ruteng',
      'Rumbia',
      'Rubaru',
      'Rokan',
      'Robatal',
      'Riung',
      'Rengat',
      'Rengasdengklok',
      'Rembangan',
      'Rawalo',
      'Ratahan',
      'Rasiei',
      'Rappang',
      'Rantepao',
      'Rantaupulut',
      'Rantau Panjang',
      'Rantau',
      'Ransiki',
      'Rangkasbitung',
      'Randudongkal',
      'Randuagung',
      'Rancah',
      'Ranai',
      'Rambipuji',
      'Rajapolah',
      'Rajadesa',
      'Rainis',
      'Raha',
      'Raduria',
      'Raba',
      'Putussibau',
      'Purworejo',
      'Purwokerto',
      'Purwoharjo',
      'Purwodadi',
      'Purwakarta',
      'Puruk Cahu',
      'Purbalingga',
      'Pundong',
      'Pule',
      'Pulaupinang',
      'Pulau Kijang',
      'Pulauberingin',
      'Pulang Pisau',
      'Puger',
      'Probolinggo',
      'Pringsewu',
      'Prigen',
      'Praya',
      'Candi Prambanan',
      'Pota',
      'Poso',
      'Pontianak',
      'Ponorogo',
      'Polewali',
      'Poigar',
      'Plumbon',
      'Plampang',
      'Piru',
      'Pinrang',
      'Pineleng',
      'Pimpi',
      'Patimuan',
      'Petakbehandang',
      'Peranap',
      'Prabumulih',
      'Peninjawan',
      'Pangaron',
      'Pengandonan',
      'Pendolo',
      'Pembuanghulu',
      'Pemangkat',
      'Pemalang',
      'Pelau',
      'Pelaihari',
      'Pelabuhanratu',
      'Pelabuhan Dagang',
      'Pekanbaru',
      'Pekalongan',
      'Pedamaran',
      'Pecangaan',
      'Payunga',
      'Payakumbuh',
      'Pemenang',
      'Pauh',
      'Pototano',
      'Pati',
      'Pasuruan',
      'Pasongsongan',
      'Passo',
      'Pasir Pengaraian',
      'Pasirian',
      'Pasean',
      'Passi',
      'Paseh',
      'Pasar Wajo',
      'Pasarkuok',
      'Pasarkemis',
      'Pasarbaru',
      'Parung',
      'Parit Malintang',
      'Paringin',
      'Parigi',
      'Pariaman',
      'Parepare',
      'Pare',
      'Panti',
      'Pantaihambawang',
      'Pantai',
      'Panji',
      'Panjang',
      'Panjalu',
      'Pangkalpinang',
      'Pangkalan Kasai',
      'Pangkalanbuun',
      'Pangkalan Bunut',
      'Pangkalan Balai',
      'Pangkajene',
      'Panggungrejo',
      'Panggul',
      'Pandeglang',
      'Pandaan',
      'Pandak',
      'Pancur',
      'Panawangan',
      'Panarukan',
      'Panaragan',
      'Penajam',
      'Pamulang',
      'Pompanua',
      'Pamotan',
      'Pameungpeuk',
      'Pamekasan',
      'Pamarican',
      'Pamanukan',
      'Palu',
      'Palopo',
      'Palimanan',
      'Palembaian',
      'Palembang',
      'Palattae',
      'Palangkaraya',
      'Palakka',
      'Pakuniran',
      'Pakuan Agung',
      'Kuaro',
      'Pakong',
      'Pakisaji',
      'Pakenjeng',
      'Pakel',
      'Painan',
      'Pagerageung',
      'Pagelaran',
      'Pagatan',
      'Pagat',
      'Pagar Alam',
      'Pagak',
      'Paga',
      'Pademawu',
      'Padarincang',
      'Padangulaktanding',
      'Padangtikar',
      'Padangguci',
      'Padangcermin',
      'Padangbatung',
      'Padang',
      'Padaherang',
      'Pacitan',
      'Paciran',
      'Pabuaran',
      'Oransbari',
      'Ondong Siau',
      'Obano',
      'Nyalindung',
      'Noemuti',
      'Nipah Panjang',
      'Nikiniki',
      'Ngunut',
      'Ngrayun',
      'Ngoro',
      'Ngawi',
      'Ngawen',
      'Ngantru',
      'Nganjuk',
      'Ngabang',
      'Negararatu',
      'Negara',
      'Natar',
      'Narmada',
      'Naringgul',
      'Nangaroro',
      'Nangah Pinoh',
      'Nanga Bulik',
      'Namrole',
      'Namlea',
      'Nabire',
      'Muraloka',
      'Muntok',
      'Muntilan',
      'Munjungan',
      'Munduk',
      'Muncar',
      'Muncang',
      'Mulia',
      'Mukomuko',
      'Mujur',
      'Muarauya',
      'Muara Teweh',
      'Muara Tembesi',
      'Muara Tebo',
      'Muara Siau',
      'Muara Sabak',
      'Muararupit',
      'Muarapinang',
      'Muara Limun',
      'Muara Lembu',
      'Muaralakitan',
      'Muaralabuh',
      'Muarakuang',
      'Muarakomam',
      'Muara Enim',
      'Muara Dua',
      'Muara Bungo',
      'Muara Bulian',
      'Muara Beliti',
      'Muarabadak',
      'Muaro',
      'Mranggen',
      'Moro',
      'Munse',
      'Molobulahe',
      'Molibagu',
      'Mojosari',
      'Mojokerto',
      'Mojoagung',
      'Mlonggo',
      'Metro',
      'Mertoyudan',
      'Mersam',
      'Merakurak',
      'Mensalong',
      'Menjalin',
      'Mengkalip',
      'Menggala',
      'Menes',
      'Mempawah',
      'Meliau',
      'Melati',
      'Mega',
      'Borong',
      'Mayang',
      'Mauponggo',
      'Maumere',
      'Matur',
      'Mataram',
      'Masmambang',
      'Masbagik',
      'Masat',
      'Masamba',
      'Martapura',
      'Maros',
      'Buakayu',
      'Marisa',
      'Maria',
      'Margasari',
      'Margahayukencana',
      'Marabahan',
      'Manonjaya',
      'Manokwari',
      'Manna',
      'Manismata',
      'Maninjau',
      'Mangkutana',
      'Manggar',
      'Mangarang',
      'Mangaran',
      'Manganitu',
      'Mandor',
      'Manding',
      'Mandalawangi',
      'Mendaha',
      'Khairiahmandah',
      'Manalu',
      'Manado',
      'Mamuju',
      'Mambi',
      'Mamasa',
      'Malinau',
      'Malili',
      'Malangbong',
      'Malang',
      'Makbon',
      'Makale',
      'Majene',
      'Majenang',
      'Majalengka',
      'Magetan',
      'Magelang',
      'Mafa',
      'Maesan',
      'Madiun',
      'Maba',
      'Luwuk',
      'Luragung',
      'Lumajang',
      'Lubuk Sikaping',
      'Lubuklinggau',
      'Lubuk Jambi',
      'Lubukgadang',
      'Lubuk Basung',
      'Lubukbergalung',
      'Lubukalung',
      'Long Pujungan',
      'Longnawang',
      'Longkali',
      'Longikis',
      'Labuan Lombok',
      'Lolak',
      'Lodoyo',
      'Loakulu',
      'Loa Janan',
      'Liwa',
      'Lirung',
      'Limboto',
      'Likupang',
      'Lewoleba',
      'Lewolaga',
      'Leuwidamar',
      'Letung',
      'Lenteng',
      'Lengkong',
      'Lenangguar',
      'Lemito',
      'Lembar',
      'Lembang',
      'Lemahputih',
      'Lelogama',
      'Leles',
      'Lela',
      'Leksula',
      'Lekebai',
      'Ledokombo',
      'Lebakwangi',
      'Lebaksiu',
      'Lawang',
      'Lasem',
      'Lasehao',
      'Larompong',
      'Larantuka',
      'Lampihung',
      'Langgur',
      'Langnga',
      'Langara',
      'Lamongan',
      'Laiwui',
      'Lais',
      'Laikit, Laikit II (Dimembe)',
      'Lahat',
      'Labuhanmaringgai',
      'Labuan Bajo',
      'Labuan',
      'Labuha',
      'Kwantisore',
      'Kutoarjo',
      'Kuta',
      'Pangkalan Kerinci',
      'Kuningan',
      'Kumurkek',
      'Kumai',
      'Kuma',
      'Kudus',
      'Kubu',
      'Kwandang',
      'Kuala Tungkal',
      'Kualakapuas',
      'Krui',
      'Kroya',
      'Krian',
      'Kresek',
      'Kraksaan',
      'Kragan',
      'Kotawaringin',
      'Kota Tengah',
      'Kota Pinang',
      'Daik Lingga',
      'Kotabunan',
      'Kotabumi',
      'Kotabesi',
      'Kotabaru Hilir',
      'Kotabaru',
      'Kotaagung',
      'Korem',
      'Korido',
      'Kopang Satu',
      'Komodo',
      'Kolonodale',
      'Kokas Kota',
      'Kokar',
      'Koba',
      'Klungkung',
      'Kelua',
      'Klaten',
      'Klangenan',
      'Klampis',
      'Kiruru',
      'Kimaam',
      'Kijang',
      'Ketahun',
      'Ketanggungan',
      'Kesugihan',
      'Kesamben',
      'Kertosono',
      'Kerek',
      'Kerang',
      'Kepoh',
      'Kepi',
      'Kepanjen',
      'Kendari',
      'Kendal',
      'Kencong',
      'Kenali',
      'Kema',
      'Kelapa',
      'Kefamenanu',
      'Kedungwuni',
      'Kedungwaru',
      'Kediri',
      'Kedaton',
      'Kebumen',
      'Kebonarun',
      'Kebomas',
      'Padangtepung',
      'Kayu Agung',
      'Kawangu',
      'Kawangkoan',
      'Kawalu',
      'Kawali',
      'Katobu',
      'Kasui',
      'Kasonawejo',
      'Kasiguncu',
      'Kasarangan',
      'Karawang',
      'Karangsembung',
      'Karangrejo',
      'Karangpawitan',
      'Karangnunggal',
      'Karangintan',
      'Karanggeneng',
      'Amlapura',
      'Karangarjo',
      'Karanganyar',
      'Karanganom',
      'Karangan',
      'Karangampel',
      'Kapongan',
      'Kapan',
      'Kepahiang',
      'Kandangan',
      'Malaju',
      'Kelarik',
      'Hampang',
      'Kampak',
      'Kameri',
      'Kambang',
      'Kamal',
      'Cibaregbeg',
      'Kalipucang',
      'Kalipang',
      'Kaliori',
      'Kalidawir',
      'Kalibunder',
      'Kalianget',
      'Kalianda',
      'Kalangbret',
      'Kalabahi',
      'Kajen',
      'Kairatu',
      'Kaimana',
      'Kahala',
      'Kadupandak',
      'Kadugedong',
      'Kadugede',
      'Kabir',
      'Kabare',
      'Juwana',
      'Dungkek',
      'Jorong',
      'Jombang',
      'Jojogan',
      'Jogonalan',
      'Jimbaran',
      'Jepon',
      'Jepara',
      'Jenggawah',
      'Jeneponto',
      'Jember',
      'Jelbuk',
      'Jekulo',
      'Jebus',
      'Jatiwangi',
      'Jatiroto',
      'Jatirogo',
      'Jatinagara',
      'Jatibarang',
      'Jaten',
      'Japah',
      'Jangkat',
      'Jampang Tengah',
      'Jampang Kulon',
      'Jambula',
      'Jambi City',
      'Jalang',
      'Jakenan',
      'Jakarta',
      'Jailolo',
      'Jabung',
      'Isimu',
      'Ipuh',
      'Indramayu',
      'Indralaya',
      'Serkos',
      'Alung',
      'Hila',
      'Gajrug',
      'Haruyan',
      'Haruai',
      'Hadakewa',
      'Gunungwungkal',
      'Gunung Sugih',
      'Gunungmenang',
      'Gunungmegang Dalam',
      'Gunungkencana',
      'Gunungbatubesar',
      'Gunem',
      'Gulukguluk',
      'Grogol',
      'Gresik',
      'Gorontalo',
      'Gongdanglegi Kulon',
      'Gondang',
      'Gombong',
      'Godo',
      'Godean',
      'Gianyar',
      'Geser',
      'Gerung',
      'Genteng',
      'Glumbang',
      'Gedong Tataan',
      'Gedangan',
      'Gebog',
      'Gayam',
      'Garut',
      'Garawangi',
      'Gapura',
      'Gandusari',
      'Gandrungmangu',
      'Ganding',
      'Gampengrejo',
      'Gampang',
      'Gambiran Satu',
      'Galunggalung',
      'Kalumpang',
      'Galis',
      'Galesong',
      'Fakfak',
      'Essang',
      'Enrekang',
      'Enok',
      'Enemawira',
      'Ende',
      'Enarotali',
      'Elopada',
      'Duri',
      'Durenan',
      'Dumoga',
      'Dumai',
      'Dulanpokpok',
      'Dukuhturi',
      'Dukuhseti',
      'Driyorejo',
      'Kempo',
      'Donomulyo',
      'Dongko',
      'Donggala',
      'Dompu',
      'Dofa',
      'Dobo',
      'Diwek',
      'Depok',
      'Denpasar',
      'Demak',
      'Delanggu',
      'Dayeuhluhur',
      'Dasuk',
      'Daruba',
      'Darma',
      'Dampit',
      'Dalududalu',
      'Dabra',
      'Curup',
      'Curug',
      'Comal',
      'Colomadu',
      'Ciwaru',
      'Citeureup',
      'Cisompet',
      'Cisolok',
      'Cisewu',
      'Cisayong',
      'Cisaga',
      'Ciruas',
      'Cirebon',
      'Ciracap',
      'Ciputat',
      'Cipatujah',
      'Cipari',
      'Ciomas',
      'Cimerak',
      'Cimaragas',
      'Cimanggu',
      'Cimahi',
      'Cileunyi',
      'Cileungsir',
      'Cilegong',
      'Cilawu',
      'Cilacap',
      'Cikoneng',
      'Cikijing',
      'Cikeusik',
      'Cikelet',
      'Cikatomas',
      'Cikarang',
      'Cikampek',
      'Cikalong',
      'Cikajang',
      'Cijulang',
      'Cijeungjing',
      'Cihaurbeuti',
      'Ciemas',
      'Cicurug',
      'Cibinong',
      'Cibingbin',
      'Cibeureum',
      'Cibalong',
      'Cibaliung',
      'Cianjur',
      'Ciamis',
      'Cermee',
      'Cerenti',
      'Cepu',
      'Ceper',
      'Cempaka',
      'Caruban',
      'Caringin',
      'Campurdarat',
      'Campor',
      'Ciampea',
      'Camming',
      'Cakke',
      'Buol',
      'Buntok',
      'Buniseuri',
      'Bungku',
      'Bungkal',
      'Bungbulang',
      'Bunga Mas',
      'Bulukumba',
      'Bulu',
      'Bulakamba',
      'Bula',
      'Bukittinggi',
      'Bukitkemuning',
      'Buduran',
      'Buaran',
      'Brondong',
      'Brebes',
      'Brakas',
      'Boyolangu',
      'Boyolali',
      'Bosnik',
      'Boroko',
      'Bontang',
      'Bunobogu',
      'Bonjol',
      'Bonebone',
      'Bondowoso',
      'Bondokodi',
      'Bolaang',
      'Bolangitang',
      'Bola',
      'Bikondini',
      'Bokat',
      'Bojongmanik Girang',
      'Bojonegoro',
      'Bojonegara',
      'Bogorejo',
      'Bogor',
      'Bobong',
      'Boawae',
      'Bluto',
      'Baluburlimbangan',
      'Blora',
      'Blitar',
      'Baranusa',
      'Bitung',
      'Birayang',
      'Binuang',
      'Bintuni',
      'Bintuhan',
      'Bingintelok',
      'Binangun',
      'Bima',
      'Bilungala',
      'Biha',
      'Biak',
      'Betaf',
      'Besuki Satu',
      'Besuki',
      'Beo',
      'Benteng',
      'Bengkulu',
      'Bengkayang',
      'Bengkalis',
      'Belopa',
      'Belimbing',
      'Blambangan Umpu',
      'Mentarang',
      'Belakangpadang',
      'Bekasi',
      'Bejubang Dua',
      'Bebandem',
      'Bayunglincir',
      'Bayongbong',
      'Batusangkar',
      'Baturiti',
      'Baturaja',
      'Baturaden',
      'Batuputih',
      'Batu Panjang',
      'Batumandi',
      'Batulicin',
      'Batubantar',
      'Batu',
      'Batibati',
      'Batangan',
      'Batauga',
      'Batang',
      'Baso',
      'Baserah',
      'Barru',
      'Barapas',
      'Barabai',
      'Banyuwangi',
      'Banyuresmi',
      'Banyumas',
      'Banyuates',
      'Bantur',
      'Bantul',
      'Bantaeng',
      'Banjarwangi',
      'Banjarsari',
      'Banjarejo',
      'Banjarnegara',
      'Banjarmasin',
      'Banjaran',
      'Banjar',
      'Bangli',
      'Bangko',
      'Bangkinang',
      'Bangkalan',
      'Bangilan',
      'Bangil',
      'Banggai',
      'Bandung',
      'Banding Agung',
      'Elat',
      'Bancar',
      'Bambanglipuro',
      'Balung',
      'Balong',
      'Balikpapan',
      'Balauring',
      'Balapulang',
      'Balai Pungut',
      'Balaiberkuak',
      'Baki',
      'Bajo',
      'Bajawa',
      'Bagan Si Api-api',
      'Baekrajan',
      'Bade',
      'Irarututiga',
      'Babirik',
      'Babat',
      'Babana',
      'Ayamaru',
      'Aitinyo',
      'Atap',
      'Atambua',
      'Astanajapura',
      'Asera',
      'Asembagus',
      'Arosbaya',
      'Armopa',
      'Arjasa',
      'Arjawinangun',
      'Argamakmur',
      'Ansus',
      'Ankola',
      'Anggana',
      'Andoolo',
      'Amuntai',
      'Ampana',
      'Ambuten',
      'Ambon',
      'Ambarawa',
      'Amahai',
      'Aluhaluh',
      'Alahanpanjang',
      'Alabio',
      'Air Molek',
      'Airmadidi',
      'Aimere',
      'Agats',
      'Airbuaya',
      'Aenganyar',
      'Adiwerna',
      'Adipala',
      'Lupak',
      'Tabunganen',
      'Belawang',
      'Tulehu',
      'Meral',
      'Gununganyar',
      'Betun',
      'Naisano Dua',
      'Sasi',
      'Bangunrejo',
      'Punggur',
      'Batanghari',
      'Sokarame',
      'Jangkar',
      'Padalarang',
      'Ciranjang-hilir',
      'Ngamprah',
      'Gunung Kendil',
      'Kali',
      'Lamadong',
      'Kauditan',
      'Ogotua',
      'Sidomulyo',
      'Tompasobaru',
      'Tobadak',
      'Rantepang',
      'Rindingallo',
      'Sukamaju',
      'Lamasi',
      'Walenrang',
      'Malunda',
      'Lampa',
      'Majennang',
      'Uluale',
      'Amparita',
      'Sidenreng',
      'Baranti',
      'Teppo',
      'Baraka',
      'Getengan',
      'Dlemmer',
      'Beringinjaya',
      'Beuma',
      'Padangsappa',
      'Lempokasi',
      'Unaaha',
      'Tancung',
      'Maroanging',
      'Matango',
      'Apala',
      'Kadai',
      'Bulubulu',
      'Bilalang',
      'Modayag',
      'Gubengairlangga',
      'Cikupa',
      'Teluknaga',
      'Gamping Lor',
      'Kasihan',
      'Ngemplak',
      'Kartasura',
      'Gatak',
      'Olafulihaa',
      'Seba',
      'Oelamasi',
      'Oekabiti',
      'Kupang',
      'Kelapalima',
      'Kananggar',
      'Eahun',
      'Camplong',
      'Babau',
      'Baa',
      'Waris',
      'Ubrub',
      'Tanah Merah',
      'Sentani',
      'Sengge',
      'Jayapura',
      'Genyem',
      'Depapre',
      'Demta',
      'Arso',
      'Abepura',
      'Mungkid',
      'Kebon Gunung',
      'Batam',
      'Gambarsari',
      'Klapagada',
      'Rejanegara',
      'Karangbadar Kidul',
      'Gili Air',
      'Senggigi',
      'Lueng Putu',
      'Kuala Tuha',
      'Kebayakan',
      'Simpang Tiga Redelong',
      'Pandan',
      'Tangantangancut',
      'Jambi Baru',
      'Drien Rampak',
      'Angkup',
      'Aek Kanopan',
      'Onolimbu',
      'Kaburon',
      'Bahoi',
      'Tutuyan',
      'Lolayan',
      'Tanggunggunung',
      'Kromengan',
      'Menden',
      'Ngraho',
      'Pengkok',
      'Sambopinggir',
      'Darungan Lor',
      'Pohgending Kawan',
      'Basa',
      'Amahusu',
      'Watulumbung',
      'Duda',
      'Banjar Wangsian',
      'Wonotirto',
      'Sukorambi',
      'Patrang',
      'Kloncing',
      'Pacarkeling',
      'Semarapura',
      'Songgon',
      'Tirtopuro',
      'Olehsari',
      'Krajan Satu',
      'Seririt',
      'Sukamulia',
      'Aikmel',
      'Ulakan',
      'Bedugul',
      'Abang',
      'Subagan',
      'Pauhambar',
      'Sungaisarik',
      'Kampungladang',
      'Kotatua',
      'Guguk',
      'Salimpaung',
      'Sungayang',
      'Simabur',
      'Rambatan',
      'Gunung Talang',
      'Kumanis',
      'Kototujuh',
      'Sirukam',
      'Tanjung Mas',
      'Nusa Dua',
      'Legian',
      'Banjar Mandung',
      'Tambolaka',
      'Ndona',
      'Wolowona',
      'Maurole',
      'Mantang',
      'Sumberrejo',
      'Rowokangkung',
      'Sebaren',
      'Kebonsari',
      'Kebonan',
      'Sulahan',
      'Karang Daye',
      'Naru',
      'Donggo',
      'Tawali',
      'Tangratte',
      'Totumbang',
      'Nggongi',
      'Malahar',
      'Tempurejo',
      'Kalianyar Selatan',
      'Busungbiu',
      'Sikur',
      'Terara',
      'Janapria',
      'Kabukarudi',
      'Allu',
      'Petoosang',
      'Iteng',
      'Detung',
      'Onan Ganjang Satu',
      'Anyar',
      'Kotawaikabubak',
      'Welamosa',
      'Aelande',
      'Ritapiret',
      'Talibura',
      'Boru',
      'Dajan Tangluk',
      'Karyadharma',
      'Jabajero',
      'Cempa Pasar',
      'Pringgabaya',
      'Pekutatan',
      'Lokokrangan',
      'Manola',
      'Werang',
      'Waelengga',
      'Mataloko',
      'Toroloji',
      'Sambelia',
      'Bentengjawa',
      'Waipare',
      'Tamanan',
      'Harapankarya',
      'Belajen',
      'Kamalaputi',
      'Soa',
      'Mbay',
      'Senggapi',
      'Detusoko',
      'Bandaragung',
      'Gadingrejo',
      'Palas',
      'Sidorejo',
      'Penengahan',
      'Kedondong',
      'Mulyosari',
      'Harian',
      'Tuktuk Sonak',
      'Tigarunggu',
      'Sipintuangin',
      'Sarimatondang',
      'Pane Tongah',
      'Cigeulis',
      'Warungbanten',
      'Polos',
      'Pasanggrahan',
      'Panimbang',
      'Sukacai',
      'Gardutanjak',
      'Kopibera',
      'Sajir',
      'Pasirgeleng',
      'Gili Trawangan',
      'Sumandang',
      'Buniasih',
      'Cisiih',
      'Sinarjaya',
      'Sukarama',
      'Kertajadi',
      'Puncaktugu',
      'Sindangratu',
      'Cibungur',
      'Sukoharjo Satu',
      'Kuripan',
      'Kalirejo',
      'Pulaupanggung',
      'Kota Ternate',
      'Gudang',
      'Kebonkai',
      'Parabon',
      'Cibitung',
      'Pakuwon',
      'Bunisari',
      'Pasirluhur',
      'Gununglimbangan',
      'Tanjungjaya',
      'Arjasari',
      'Sukamaju Kidul',
      'Argasari',
      'Nagarasari',
      'Kahuripan',
      'Sindangtamu',
      'Jamanis',
      'Cibongas',
      'Ciriri',
      'Lovina',
      'Cigugur',
      'Limusnunggal',
      'Hegarsari',
      'Ngulakan',
      'Bantengan',
      'Cipadung Timur',
      'Rembang',
      'Sidowayah Kidul',
      'Pananjung',
      'Bangkle',
      'Sinargalih',
      'Jetis',
      'Jamong',
      'Latsari',
      'Gedongombo',
      'Bejagung Kidul',
      'Bakung',
      'Banjaranyar',
      'Solokuro',
      'Prupuh',
      'Gupakan',
      'Sukorejo',
      'Tempursari',
      'Dawang',
      'Krajan A Wonorejo',
      'Sumberan',
      'Bangsalsari',
      'Pasirpanjang',
      'Tebing',
      'Sekotong Tengah',
      'Tlogowungu',
      'Lakbok',
      'Dibee',
      'Glagahwero',
      'Siliragung',
      'Prigi',
      'Sumbergedong',
      'Bendo',
      'Larangan',
      'Woja',
      'Pucanglaban',
      'Muaradua',
      'Bara Datu',
      'Oganlima',
      'Umbulan Kapiak',
      'Ledeunu',
      'Loboae',
      'Bolou',
      'Pragaan',
      'Batutua',
      'Busalangga',
      'Likioen',
      'Momanalu',
      'Talango',
      'Batakte',
      'Uitao',
      'Oebobo',
      'Tarus',
      'Takari',
      'Neonmat',
      'Siso',
      'Oekamusa',
      'Polen',
      'Oeekam',
      'Eban',
      'Panite',
      'Kuanfatu',
      'Kie',
      'Oinlasi',
      'Ayotupas',
      'Biudukfoho',
      'Maritaing',
      'Kemang',
      'Mainang',
      'Apui',
      'Moru',
      'Kampungraja',
      'Wairiang',
      'Watugolok',
      'Waiwadan',
      'Senadan',
      'Loang',
      'Klubagolit',
      'Baniona',
      'Menanga',
      'Ritaebang',
      'Data Dian',
      'Samalantan',
      'Rundeng',
      'Rantau Prapat',
      'Waiklibang',
      'Uwa',
      'Rambangaru',
      'Ngalu',
      'Pekan Bahapal',
      'Mandarahan',
      'Rantau Ambacang',
      'Siulak Deras Mudik',
      'Senayan',
      'Negeriagung',
      'Waghete',
      'Eci',
      'Getentiri',
      'Muting',
      'Mindiptana',
      'Warmare',
      'Kebar',
      'Bomomani',
      'Merdey',
      'Bofuer',
      'Topo',
      'Homeyo',
      'Kumopa',
      'Moanemani',
      'Bilogai',
      'Beoga',
      'Trimuris',
      'Arbais',
      'Samanente',
      'Guay',
      'Banda',
      'Wurigelebur',
      'Karubaga',
      'Sawoi',
      'Kota Agung',
      'Pulosari',
      'Kokop',
      'Bontang Baru',
      'Wasior',
      'Pulau Punjung',
      'Teluk Pinang',
      'Suak Bakung',
      'Paya Dapur',
      'Labuhan Bajau',
      'Luan Balu',
      'Lauke',
      'Kuala Baru',
      'Rimo',
      'Danauparis',
      'Pulo Batal',
      'Pulopaya',
      'Pulo Ie',
      'Terbangan',
      'Samadua',
      'Meukek',
      'Peuleumat',
      'Kota Trieng',
      'Mersak',
      'Seupakat',
      'Bambol',
      'Lawesigalagala Timur',
      'Ngkan',
      'Idi Rayeuk',
      'Idi Cut',
      'Bagok',
      'Beusa Seberang',
      'Buket Teukuh',
      'Seuneubok Bayu',
      'Simpang Ulim',
      'Kota Binjai',
      'Luthu',
      'Indrapuri',
      'Angan',
      'Lampuuk',
      'Sibreh',
      'Mata Ie',
      'Keumala',
      'Geuleumpangminyeuk',
      'Rusip',
      'Kute Panang',
      'Kemil',
      'Pegasing',
      'Kuala Bintang',
      'Jagongjeget',
      'Banda Layung',
      'Keudee Aron',
      'Pantai Ceuremen',
      'Uleeglee',
      'Jumpangdua',
      'Cot Baroh',
      'Adil Makmur',
      'Juli',
      'Alue Glumpang',
      'Blang Ara',
      'Beutong Ateuh',
      'Alue Bilie',
      'Suka Makmue',
      'Blang Mane',
      'Kalde Panga',
      'Lageun',
      'Blang Dalam',
      'Kajeung',
      'Peukanbada',
      'Geumpang',
      'Mane',
      'Tiro',
      'Lampoih',
      'Caloue',
      'Matangkuli',
      'Matangpayang',
      'Seunudon',
      'Bulahblangaro',
      'Babah Rot',
      'Suek',
      'Kutapanjang',
      'Sangir',
      'Sungai Iyu',
      'Karangbaru',
      'Sungi Liput',
      'Tenggulun',
      'Pulau Tiga',
      'Simpang Teritit',
      'Ulee Gle',
      'Trienggadeng',
      'Pante Raja',
      'Lambaro',
      'Pasir Panjang',
      'Lawepakam',
      'Kuala Baru Sungai',
      'South Tangerang',
      'Kotabahagia',
      'Blangpulo',
      'Sarulla',
      'Long Peso',
      'Long Beluah',
      'Long Bang',
      'Sungai Nyamuk',
      'Binalawan',
      'Long Layu',
      'Sungaiboh',
      'Long Loreh',
      'Sekatak Bengara',
      'Tanjung Palas',
      'Kerangagung',
      'Kayan Hulu',
      'Pasarujungbatu',
      'Panyabungan',
      'Sipoholon',
      'Sampuran',
      'Sumbul',
      'Pulau Temiang',
      'Handilenam',
      'Palaran',
      'Batusopang',
      'Awayan',
      'Matangai',
      'Jenamas',
      'Danaupanggang',
      'Telagaselaba',
      'Sungaiturak',
      'Pasararba',
      'Tanta',
      'Tambarangan',
      'Tapintengah',
      'Margasarihilir',
      'Angkimang',
      'Pasingkan',
      'Pendawanbaru',
      'Sungaitatas',
      'Barimba',
      'Anjirserapat',
      'Basarang',
      'Anjirmuara',
      'Sungaigampa',
      'Sungaipuntik',
      'Sungaitabuk',
      'Gambut',
      'Astambul',
      'Kurau',
      'Bakau',
      'Pudi',
      'Putatbasiun',
      'Mungkuruyam',
      'Halong',
      'Manunggulama',
      'Binawara',
      'Lontar',
      'Allealle',
      'Telagalangsat',
      'Juai',
      'Mapurujaya',
      'Woha',
      'Kuala Pembuang',
      'Doom',
      'Aifat',
      'Masohi',
      'Waipia',
      'Petani',
      'Buli',
      'Basiong',
      'Malifud',
      'Guruafin',
      'Dufa-Dufa',
      'Tungoo',
      'Teritip',
      'Terentang',
      'Batuampar',
      'Kenyau',
      'Pontianak Barat',
      'Barikin',
      'Loklaga',
      'Senayang',
      'Matak',
      'Sabang Mawang Barat',
      'Cemaga',
      'Harapan Jaya',
      'Mangupura',
      'Tigaraksa',
      'Tubei',
      'Karang Tinggi',
      'Pulau Pramuka',
      'Suwawa',
      'Siulak',
      'Ngasem',
      'Sendawar',
      'Bandar Seri Bentan',
      'Wiralaga Mulya',
      'Tiakur',
      'Kigamani',
      'Sugapa',
      'Burmeso',
      'Kobakma',
      'Kenyam',
      'Oksibil',
      'Ilaga',
      'Botawa',
      'Sumohai',
      'Elelim',
      'Ullong',
      'Aimas',
      'Fef',
      'Sigi Biromaru',
      'Buranga',
      'Laworo',
      'Tuapejat',
      'Arosuka',
      'Padang Aro',
      'Lotu',
      'Labungkari',
      'Jembrana Subdistrict',
      'Amlapura city',
      'Patipi Pasir',
      'Kramongmongga',
      'Onimsari',
      'Weriagar',
      'Moskona Barat',
      'Meyado',
      'Jagiro',
      'Kucir',
      'Masyeta',
      'Biscoop',
      'Pamolokan',
      'Sitimerto',
      'Krucil Timur',
      'Raas',
      'Tambakrejo',
      'Buduan Ghejeh',
      'Taman',
    ],
  },
  {
    country: 'Ireland',
    cities: [
      'Buncrana',
      'Youghal',
      'Wicklow',
      'Whitegate',
      'Wexford',
      'Westport',
      'Watergrasshill',
      'Waterford',
      'Virginia',
      'Urlingford',
      'Tullyallen',
      'Tullow',
      'Tullamore',
      'Tuam',
      'Trim',
      'Trá Mhór',
      'Tralee',
      'Tower',
      'Toormakeady',
      'Tobercurry',
      'Tipperary',
      'Thurles',
      'Thomastown',
      'Termonfeckin',
      'Terenure',
      'Templemore',
      'Tallaght',
      'Swords',
      'Swinford',
      'Sutton',
      'Stradbally',
      'Stamullin',
      'Sligo',
      'Slane',
      'Skibbereen',
      'Skerries',
      'Sixmilebridge',
      'Shankill',
      'Sandymount',
      'Sallins',
      'Saggart',
      'An Ros',
      'Rosslare',
      'Roscrea',
      'Roscommon',
      'Rochfortbridge',
      'Ringsend',
      'Ratoath',
      'Rathwire',
      'Rathnew',
      'Rathmines',
      'Ramelton',
      'Rathkeale',
      'Rathfarnham',
      'Rathdrum',
      'Rathdowney',
      'Rathcormac',
      'Rathcoole',
      'Rathangan',
      'Raphoe',
      'Raheny',
      'Prosperous',
      'Portumna',
      'Portmarnock',
      'Portlaw',
      'Portlaoise',
      'Portarlington',
      'Piltown',
      'Daingean',
      'Passage West',
      'Oughterard',
      'Oranmore',
      'Old Kilcullen',
      'Oldcastle',
      'Newtown Trim',
      'Newtown Cunningham',
      'New Ross',
      'Newport',
      'Newmarket on Fergus',
      'Newcastle',
      'Newcastle West',
      'Droichead Nua',
      'Nenagh',
      'Navan',
      'Naas',
      'An Muileann gCearr',
      'Mullagh',
      'Muff',
      'Moycullen',
      'Moville',
      'Mountrath',
      'Mountmellick',
      'Moroe',
      'Moone',
      'Mooncoin',
      'Monkstown',
      'Monasterevin',
      'Monaghan',
      'Moate',
      'Mitchelstown',
      'Millstreet',
      'Midleton',
      'Mount Merrion',
      'Maynooth',
      'Marino',
      'Manorhamilton',
      'Mallow',
      'Malahide',
      'Macroom',
      'Lusk',
      'Lucan',
      'Loughrea',
      'Loughlinstown',
      'Longwood',
      'Longford',
      'Listowel',
      'Luimneach',
      'Leifear',
      'Letterkenny',
      'Leixlip',
      'Laytown',
      'Strandhill',
      'Lanesborough',
      'Kinsale',
      'Kinnegad',
      'Kinlough',
      'Kingscourt',
      'Kilworth',
      'Kiltamagh',
      'Kilrush',
      'Kilmallock',
      'Killybegs',
      'Killumney',
      'Killucan',
      'Killorglin',
      'Cill Airne',
      'Killaloe',
      'Kilkenny',
      'Kildare',
      'Kilcullen',
      'Kilcoole',
      'Kilcock',
      'Kilbeggan',
      'Kenmare',
      'Kells',
      'Kanturk',
      'Johnstown',
      'Inishcrone',
      'Howth',
      'Gweedore',
      'Greystones',
      'Graiguenamanagh',
      'Gort',
      'Gorey',
      'Glasnevin',
      'Glanmire',
      'Gaillimh',
      'Foxford',
      'Finglas',
      'Fethard',
      'Ferns',
      'Fermoy',
      'Ferbane',
      'Ennistimon',
      'Enniskerry',
      'Enniscorthy',
      'Ennis',
      'Enfield',
      'Edgeworthstown',
      'Edenderry',
      'Dunshaughlin',
      'Dunmore East',
      'Dunmanway',
      'Dunlewy',
      'Dunleer',
      'Dún Laoghaire',
      'Dungloe',
      'Dungarvan',
      'Dundrum',
      'Dundalk',
      'Dunboyne',
      'Duleek',
      'Dublin',
      'Dromiskin',
      'Drogheda',
      'Douglas',
      'Donnybrook',
      'Donegal',
      'Donabate',
      'Dollymount',
      'Dingle',
      'Derrybeg',
      'Derrinturn',
      'Dalkey',
      'Crumlin',
      'Crossmolina',
      'Crosshaven',
      'Croom',
      'Courtown',
      'Cork',
      'Cootehill',
      'Convoy',
      'Collooney',
      'Cobh',
      'Cloyne',
      'Cluain Meala',
      'Clones',
      'Clondalkin',
      'Clonakilty',
      'Clogherhead',
      'Clifden',
      'Claremorris',
      'Claregalway',
      'Clara',
      'Clane',
      'Cherryville',
      'Charleville',
      'Charlestown',
      'Chapelizod',
      'Celbridge',
      'Cavan',
      'Castlerea',
      'Castlepollard',
      'Castlemartyr',
      'Castleknock',
      'Castleisland',
      'Castledermot',
      'Castlecomer',
      'Castlebridge',
      'Castleblayney',
      'Castlebellingham',
      'Castlebar',
      'Cashel',
      'Carrigtwohill',
      'Carrigaline',
      'Carrick-on-Suir',
      'Carrick-on-Shannon',
      'Carrickmacross',
      'Carnew',
      'Carndonagh',
      'Carlow',
      'Carlingford',
      'Callan',
      'Cahersiveen',
      'Caherconlish',
      'Cahir',
      'Cabinteely',
      'Bundoran',
      'Bunclody',
      'Bray',
      'Boyle',
      'Blessington',
      'Blarney',
      'Blanchardstown',
      'Blackrock',
      'Birr',
      'Belturbet',
      'Belmullet',
      'Bearna',
      'Bantry',
      'Bandon',
      'Banagher',
      'Baltinglass',
      'Balrothery',
      'Ballyshannon',
      'Ballysadare',
      'Ballyragget',
      'Ballymote',
      'Ballymahon',
      'Ballyjamesduff',
      'Ballyhaunis',
      'Ballygar',
      'Ballyconnell',
      'Ballybunnion',
      'Ballybofey',
      'Ballyboden',
      'Ballybay',
      'Ballivor',
      'Ballinrobe',
      'Ballincollig',
      'Ballinasloe',
      'Ballina',
      'Ballaghaderreen',
      'Baldoyle',
      'Balbriggan',
      'Bailieborough',
      'Bagenalstown',
      'Aughrim',
      'Athy',
      'Athgarvan',
      'Athenry',
      'Athboy',
      'Askeaton',
      'Ashbourne',
      'Arklow',
      'Ardnacrusha',
      'Ardee',
      'Aghada',
      'Adare',
      'Abbeyleix',
      'Abbeyfeale',
      'Castletown',
      'Portraine',
      'Ballygerry',
      'Ballinroad',
      'Annacotty',
      'Castleconnell',
      'Nenagh Bridge',
      'Shannon',
      'Athlone',
      'Kill',
      'Ashford',
      'Kilpedder',
      'Valleymount',
      'Kilmacanoge',
      'Eadestown',
      'Little Bray',
      'Firhouse',
      'Sandyford',
      'Foxrock',
      'Sallynoggin',
      'Rathgar',
      'Artane',
      'Beaumont',
      'Drumcondra',
      'Ballymun',
      'Cabra',
      'Walkinstown',
      'Hartstown',
      'Mulhurddart',
      'Ballyfermot',
      'Templeogue',
      'Oldbawn',
      'Cherry Orchard',
      'Rialto',
      'Milltown',
      'Clonskeagh',
      'Booterstown',
      'Ballinteer',
      'Greenhills',
      'Coolock',
      'Bonnybrook',
      'Donaghmede',
      'Darndale',
      'Donnycarney',
      'Bayside',
      'South Dublin',
      'Charlesland',
      'Moyross',
      'Ballisodare',
      'Ballylinan',
      'Castletroy',
      'Fairview',
      'Knocklyon',
      'Palmerstown',
      'Jobstown',
      'Killester',
      'Confey',
      'Kentstown',
      'Kilquade',
      'Newtownmountkennedy',
      'Balally',
      'Kinsealy-Drinan',
      'Courtbrack',
      'Ballybofey-Stranorlar',
    ],
  },
  {
    country: 'Israel',
    cities: [
      'Tel Qatsir',
      'Majdal Shams',
      'Fīq',
      '‘Ein Qunīya',
      'Al Buţayḩah',
      'Al Khushnīyah',
      'El Ghajar',
      'Jerusalem',
      'Har Adar',
      'Naẖal Teqoa‘',
      'Miẕpé Yeriẖo',
      'Modi‘in Makkabbim Re‘ut',
      'Kokhav HaShaẖar',
      'El‘azar',
      'Be’er Toviyya',
      'Tsur Moshe',
      'Tsur Hadassa',
      'Tsofit',
      'Zikhron Ya‘aqov',
      'Zetan',
      'Tsrufa',
      'Tselafon',
      'Zekharya',
      'Safed',
      'Zalafa',
      'Yoqne‘am ‘Illit',
      'Yirkā',
      'Yinnon',
      'Yif‘at',
      'Yesud HaMa‘ala',
      'Yeroẖam',
      'Yehud',
      'Yavne’el',
      'Yavné',
      'Yashresh',
      'Yarẖiv',
      'Yagur',
      'Jaffa',
      'Yāfā',
      'Yad Rambam',
      'Yad Binyamin',
      '‘Uzeir',
      'Umm el Quṭūf',
      'Umm el Faḥm',
      'Udim',
      'Tūbā Zangarīya',
      'Tirat Yehuda',
      'Tirat Karmel',
      'Timrat',
      'Tifraẖ',
      'Tiberias',
      'Tel Yitsẖaq',
      'Tel Mond',
      'Tel Aviv',
      'Tel ‘Adashim',
      'maalot Tarshīhā',
      'Tamra',
      'Tal Shaẖar',
      'Sūlam',
      'Sitriyya',
      'Shoresh',
      'Shtulim',
      'Shlomi',
      'Shefayim',
      'Shefar‘am',
      'Shave Tsiyyon',
      'Bet Yitsẖaq Sha’ar H̱efer',
      'Sha‘ar Efrayim',
      'Sha‘alvim',
      'Sha‘av',
      'Segev',
      'Sdot Yam',
      'Sde Ya‘aqov',
      'Sde Warburg',
      'Sde ‘Uziyyahu',
      'Sderot',
      'Sde Neẖemya',
      'Sde H̱emed',
      'Savyon',
      'Ṣandala',
      'Sakhnīn',
      'Sājūr',
      'Rummāna',
      'Rosh Pinna',
      'Rosh Ha‘Ayin',
      'Rishpon',
      'Rishon LeTsiyyon',
      'Rinnatya',
      'Riẖaniya',
      'Revivim',
      'Reshafim',
      'Rekhasim',
      'Reẖovot',
      'Regba',
      'Ramot Menashe',
      'Ramla',
      'Ramat Yishay',
      'Ramat Yoẖanan',
      'Ramat HaShofet',
      'Ramat HaSharon',
      'Ramat HaKovésh',
      'Ramat Gan',
      'Rama',
      "Ra'anana",
      'Qiryat Ye‘arim',
      'Qiryat Yam',
      'Qiryat Tiv‘on',
      'Qiryat Shmona',
      'Qiryat Motsqin',
      'Qiryat Mal’akhi',
      'Kiryat Gat',
      'Qiryat Bialik',
      'Qiryat Ata',
      'Qidron',
      'Qalansuwa',
      'Poria Illit',
      'Poriyya',
      'Petaẖ Tiqva',
      'Pardesiyya',
      'Or Yehuda',
      'Or Akiva',
      'Ora',
      '‘Omer',
      '‘Olesh',
      'Ofaqim',
      'Nordiyya',
      'Niẕẕané ‘Oz',
      'Nir Tsevi',
      'Nir Gallim',
      'Nevve Yaraq',
      'Nevve Yamin',
      'Netivot',
      'Netanya',
      'Ness Ziona',
      'Nesher',
      'Nes Harim',
      'Nein',
      'Neẖusha',
      'Nehora',
      'Neẖalim',
      'Naẕerat ‘Illit',
      'Nazareth',
      'Nā‘ūra',
      'Naḥf',
      'Nahariyya',
      'Na‘an',
      'Muṣmuṣ',
      'Muqeibila',
      'Motsa ‘Illit',
      'Mitzpe Ramon',
      'Mishmeret',
      'Mishmarot',
      'Mishmar HaNegev',
      'Mishmar Ha‘Emeq',
      'Mikhmoret',
      'Mi‘ilyā',
      'Migdal Ha‘Emeq',
      'Migdal',
      'Mevasseret Tsiyyon',
      'Metulla',
      'Mesillat Tsiyyon',
      'Menaẖemya',
      'Megadim',
      'Mefallesim',
      'Maẕẕuva',
      'Matsliaẖ',
      'Mazkeret Batya',
      'Mash‘én',
      'Ma’or',
      'Majd el Kurūm',
      'Maghār',
      'Maggal',
      'Ma‘galim',
      'Mabbu‘im',
      'Ma‘agan Mikha’el',
      'Lod',
      'Li On',
      'Lahavot H̱aviva',
      'Kokhav Michael Sobell',
      'Meisir',
      'Kfar Yona',
      'Kefar Warburg',
      'Kefar Vitqin',
      'Kfar Tavor',
      'Kefar Sirkin',
      'Kfar Shmaryahu',
      'Kfar Saba',
      'Kfar Rosh HaNiqra',
      'Kfar Pines',
      'Kfar Netter',
      'Kfar Menaẖem',
      'Kfar H̱ittim',
      'Kfar Hess',
      'Kefar HaRo’e',
      'Niẕẕan',
      'Kfar H̱abad',
      'Qiryat ‘Eqron',
      'Kfar Bilu',
      'Kaukab Abū el Hījā',
      'Karmi’el',
      'Kfar Yasif',
      'Kafr Qāsim',
      'Kafr Qari‘',
      'Kafr Miṣr',
      'Kafr Mandā',
      'Kafr Kannā',
      'Kafr Kammā',
      'Kafr Barā',
      'Kābūl',
      'Kabri',
      'Jūlis',
      'Judeida Makr',
      'Jisr ez Zarqā',
      'Jīsh',
      'Jatt',
      'Jaljūlya',
      '‘Isfiyā',
      '‘Ilūṭ',
      'Iksāl',
      'Ibṭīn',
      'I‘billīn',
      'Ḥurfeish',
      'H̱ulda',
      'H̱osen',
      'H̱olon',
      'Hod HaSharon',
      'Herzliya',
      'H̱erut',
      'H̱emed',
      'Haifa',
      'HaZore‘im',
      'H̱atsor HaGelilit',
      'H̱agor',
      'Hadera',
      'Giv‘at Ye‘arim',
      "Giv'at Shmuel",
      'Giv‘ati',
      'Giv‘at H̱ayyim Iẖud',
      'Giv‘at Brenner',
      'Givatayim',
      'Gimzo',
      'Gilat',
      'Geva‘ Karmel',
      'Gesher HaZiv',
      'Gedera',
      'Gan Yavne',
      'Ganei Tikva',
      'Fassūta',
      'Even Yehuda',
      'Even Shemu’el',
      'Eṭ Ṭīra',
      'Eṭ Ṭaiyiba',
      'Taiyiba',
      'Eshta’ol',
      'Esh Sheikh Dannūn',
      'Er Reina',
      '‘En Ya‘aqov',
      'Ein Vered',
      'Ein Sarid',
      '‘En HaMifraẕ',
      '‘En Dor',
      '‘En Boqeq',
      '‘En Ayyala',
      'Elyakhin',
      'Elon',
      'El Mazra‘a',
      'Elishama‘',
      'El Fureidīs',
      'Eilat',
      '‘Ein Rāfa',
      '‘Ein Māhil',
      '‘Eilabun',
      'Dimona',
      'Dvira',
      'Deir Ḥannā',
      'Deir el Asad',
      'Daliyat al Karmel',
      'Dabbūrīya',
      'Bustan HaGalil',
      'Burgata',
      'Buqei‘a',
      'Bu‘eina',
      'Biẕẕaron',
      'Bīr el Maksūr',
      'Binyamina',
      'Bi’na',
      'Bet Zayit',
      'Bet Yitsẖaq',
      'Bet Yehoshua‘',
      'Bet Shemesh',
      'Bet She’an',
      'Bet Rabban',
      'Bet Qama',
      'Bet H̱ilqiyya',
      'Bet HaShitta',
      'Bet Guvrin',
      'Bet Gamli’él',
      'Bet ‘Ezra',
      'Bet El‘azari',
      'Bet Dagan',
      'Bet ‘Arif',
      'Bet Alfa',
      'Berekhya',
      'Ben Zakkay',
      'Bne Re’em',
      'Bne Dror',
      'Moledet',
      'Bnei Brak',
      'Bnei Ayish',
      'Beit Jann',
      'Be’er Ya‘aqov',
      'Beersheba',
      'Be’erotayim',
      'Be’er Ora',
      'Be’eri',
      'Batsra',
      'Bat Yam',
      'Basmat Ṭab‘ūn',
      'Bareqet',
      'Bāqa el Gharbīya',
      'Baẖan',
      '‘Azriqam',
      'Azor',
      'Ayyelet HaShaẖar',
      'Atlit',
      'Ashkelon',
      'Ashdot Ya‘aqov Iẖud',
      'Ashdod',
      '‘Arugot',
      '‘Ar‘ara',
      'Arad',
      '‘Amminadav',
      'Acre',
      'Aẖituv',
      'Aẖisamakh',
      'Aẖi‘ezer',
      'Afula',
      'Afiqim',
      'Abū Sinān',
      'Abū Ghaush',
      'Shibli',
      'Hosha‘ya',
      'Rumat Heib',
      'Raqqefet',
      'Sallama',
      'Yuvallim',
      'Kammon',
      'Kfar Veradim',
      'Bet H̱ashmonay',
      'Mazor',
      'H̱aspin',
      'Katzrin',
      'Lehavim',
      'Midreshet Ben-Gurion',
      'Caesarea',
      'West Jerusalem',
      'Meitar',
      '‘Aramsha',
      'Merkaz Shapira',
      'Aẖuzzat Baraq',
      'Giv‘at Avni',
      'Mitspe Netofa',
      'Kokhav Ya’ir',
      'Bat H̱efer',
      'H̱arish',
      'Zemer',
      'Karmé Yosef',
      '‘Ein Naqūbā',
      'Rahat',
      'Laqiyya',
      'Segev Shalom',
      'Modiin Ilit',
      'Nof Ayalon',
      'Hashmonaim',
      'Lapid',
      'Kfar NaOranim',
      'Beit Horon',
      'Nili',
      "Na'ale",
      "Giv'on HaHadasha",
      'Talmon',
      'Dolev',
      'Ariel',
      'Revava',
      'Tal El',
      'Moreshet',
      'Mitspe Aviv',
      'Zarzir',
      'Shimshit',
      'Giv‘at Ela',
      'Nofit',
      'Ka‘abiyya',
      'Harduf',
      'Allon HaGalil',
      '‘Adi',
      'Bar Yuẖay',
      'H̱amam',
      'Tel Sheva‘',
      'Gilon',
      'Gan Ner',
      'Khirbat Abu Tulūl',
      '‘Ar‘ara BaNegev',
      'H̱ura',
      'Kuseifa',
      'Shoham',
      'El‘ad',
      'Matan',
      'Herzliya Pituah',
      'Kammana',
      'Manshīyet Zabda',
      'Yehud-Monosson',
      'Giv‘at H̱ayyim Me’uẖad',
      "Giv'ot Bar",
      'Kadima Zoran',
      "Bu'ayna-Nujaydat",
      'Yanuah Jat',
      'Yoqneam',
      "Kisra - Sume'a",
      'Maale Iron',
      'Tsur Itshak',
      "Be'er Ganim",
      'Kiryat Ono',
      'Umm Batin',
      'AlSayid Tribe',
      'Bir Hadaj',
      "Binyamina-Giv'at Ada",
      'Drijat',
      'Yanuh-Jat',
      'Tirabin al-Sana',
      'Shibli–Umm al-Ghanam',
      'Basma',
    ],
  },
  {
    country: 'Isle of Man',
    cities: [
      'Santon',
      'Ramsey',
      'Port Saint Mary',
      'Port Erin',
      'Peel',
      'Patrick',
      'Onchan',
      'Maughold',
      'Lezayre',
      'Laxey',
      'Kirkmichael',
      'Kirk Braddan',
      'Jurby',
      'Douglas',
      'Crosby',
      'Castletown',
      'Bride',
      'Ballasalla',
      'Ballabeg',
      'Andreas',
      'Ballaugh',
    ],
  },
  {
    country: 'India',
    cities: [
      'Thang',
      'Pūnch',
      'Keelakarai',
      'Zunheboto',
      'Ziro',
      'Zamānia',
      'Zaidpur',
      'Zahirābād',
      'Zafarābād',
      'Yol',
      'Yeola',
      'Yellāpur',
      'Yellandu',
      'Yelbarga',
      'Yelandūr',
      'Yelahanka',
      'Yavatmāl',
      'Yāval',
      'Yārāda',
      'Yanam',
      'Yamunānagar',
      'Yairipok',
      'Yādgīr',
      'Wokha',
      'Wer',
      'Wellington',
      'Wazīrganj',
      'Wāshīm',
      'Warud',
      'Warora',
      'Wāris Alīganj',
      'Wardha',
      'Wārāseonī',
      'Warangal',
      'Wanparti',
      'Wānkāner',
      'Wani',
      'Wāngjing',
      'Walajapet',
      'Wālājābād',
      'Wai',
      'Waghāi',
      'Wādi',
      'Wadgaon',
      'Vyāra',
      'Vuyyūru',
      'Vrindāvan',
      'Vriddhāchalam',
      'Vizianagaram',
      'Vite',
      'Visnagar',
      'Visakhapatnam',
      'Vīsāvadar',
      'Virudunagar',
      'Virpur',
      'Vadakku Viravanallur',
      'Virajpet',
      'Virār',
      'Vīraganūr',
      'Vinukonda',
      'Vinchia',
      'Villupuram',
      'Vilattikulam',
      'Vikravāndi',
      'Vikārābād',
      'Vijayawada',
      'Vijayapuri',
      'Vijāpur',
      'Vidisha',
      'Vettūr',
      'Vettavalam',
      'Vettaikkaranpudur',
      'Vetapālem',
      'Verāval',
      'Vepagunta',
      'Venkatagiri',
      'Vengurla',
      'Vemalwāda',
      'Velur',
      'Vellore',
      'Velankanni',
      'Vejalpur',
      'Vedasandūr',
      'Vedaraniyam',
      'Vayalār',
      'Vattalkundu',
      'Vāsudevanallūr',
      'Vasind',
      'Vasco da Gama',
      'Vasa',
      'Vartej',
      'Varkala',
      'Varca',
      'Varangaon',
      'Varanasi',
      'Vānsada',
      'Vaniyambadi',
      'Vandavāsi',
      'Vandalūr',
      'Valsād',
      'Valpoy',
      'Valparai',
      'Vallam',
      'Vallabh Vidyanagar',
      'Valavanur',
      'Azhikkal',
      'Valangaiman',
      'Valabhīpur',
      'Vaikam',
      'Vaijāpur',
      'Vāghodia',
      'Vadodara',
      'Vadnagar',
      'Vadlapūdi',
      'Vādippatti',
      'Vadamadurai',
      'Vadakku Valliyūr',
      'Vāda',
      'Ūttukkuli',
      'Uttiramerūr',
      'Uttarkāshi',
      'Uttamapālaiyam',
      'Utraula',
      'Utrān',
      'Usilampatti',
      'Usehat',
      'Uri',
      'Uravakonda',
      'Uran',
      'Uppiliyapuram',
      'Upleta',
      'Uppal Kalan',
      'Unnāo',
      'Unjha',
      'Uniāra',
      'Unhel',
      'Una',
      'Ūn',
      'Un',
      'Umri',
      'Umreth',
      'Umred',
      'Umrāla',
      'Umarkot',
      'Umarkhed',
      'Umaria',
      'Umarga',
      'Ullal',
      'Ulhasnagar',
      'Ukwā',
      'Uklāna',
      'Ujjain',
      'Ujhāni',
      'Ugu',
      'Udumalaippettai',
      'Udpura',
      'Udupi',
      'Udhampur',
      'Udgīr',
      'Udangudi',
      'Udalguri',
      'Udaipura',
      'Udaipur',
      'Udayagiri',
      'Ooty',
      'Bara Uchāna',
      'Turuvekere',
      'Turaiyūr',
      'Tura',
      'Tuni',
      'Tūndla',
      'Tumsar',
      'Tumkūr',
      'Tulsīpur',
      'Tuljāpur',
      'Tufānganj',
      'Tuensang',
      'Tsrār Sharīf',
      'Thiruvananthapuram',
      'Trimbak',
      'Thrissur',
      'Trāl',
      'Toshām',
      'Tori-Fatehpur',
      'Topchānchi',
      'Tonk',
      'Tondi',
      'Tohāna',
      'Todaraisingh',
      'Todabhim',
      'Titron',
      'Titlāgarh',
      'Titāgarh',
      'Titābar',
      'Tisaiyanvilai',
      'Tiruvottiyūr',
      'Thiruvidaimaruthur',
      'Cheyyar',
      'Tiruvannāmalai',
      'Tiruvallur',
      'Tiruvalla',
      'Thiruthani',
      'Tiruttangal',
      'Tirur',
      'Tiruppuvanam',
      'Tiruppur',
      'Tirupattur',
      'Tirupparangunram',
      'Tiruppālaikudi',
      'Tirupati',
      'Tirunelveli',
      'Tirumullaivāsal',
      'Tirumala',
      'Tirumakūdal Narsipur',
      'Tirukkoyilur',
      'Thirukattupalli',
      'Tiruchengode',
      'Tiruchirappalli',
      'Tiruchchendur',
      'Tīrthahalli',
      'Tirodi',
      'Tīra Sujānpur',
      'Tiptūr',
      'Tinsukia',
      'Tinnanūr',
      'Tindwāri',
      'Tindivanam',
      'Tilhar',
      'Tīkri',
      'Tīkamgarh',
      'Tikaitnagar',
      'Tijāra',
      'Thoubāl',
      'Thiruvarur',
      'Thiruvaiyaru',
      'Theog',
      'Thenzawl',
      'Thāsra',
      'Tharād',
      'Thanniyam',
      'Thanna Mandi',
      'Tanjore',
      'Thānesar',
      'Thāne',
      'Thandla',
      'Thāna Bhawan',
      'Thān',
      'Thākurganj',
      'Thakurdwara',
      'Tezu',
      'Tezpur',
      'Terdāl',
      'Teonthar',
      'Thenkasi',
      'Teni',
      'Tendūkheda',
      'Tellicherry',
      'Telhāra',
      'Tekkali',
      'Tekkalakote',
      'Tekāri',
      'Tekanpur',
      'Tehri',
      'Teghra',
      'Tawang',
      'Tāttayyangārpettai',
      'Tāsgaon',
      'Tarn Tāran',
      'Tarikere',
      'Tārāpur',
      'Tharangambadi',
      'Tārānagar',
      'Tarāna',
      'Tāramangalam',
      'Tarakeswar',
      'Tarabha',
      'Tāoru',
      'Tanuku',
      'Tankāra',
      'Tāndūr',
      'Tānda',
      'Tāndā',
      'Tanakpur',
      'Tamlūk',
      'Tambaram',
      'Talwāra',
      'Talwandi Bhai',
      'Taloda',
      'Talni',
      'Talipparamba',
      'Tālīkota',
      'Tālgrām',
      'Talen',
      'Taleigao',
      'Talegaon Dābhāde',
      'Tālcher',
      'Tālbahat',
      'Talāja',
      'Tāl',
      'Tāki',
      'Takhatpur',
      'Takhatgarh',
      'Tājpur',
      'Tādpatri',
      'Tādepallegūdem',
      'Tādepalle',
      'Swāmimalai',
      'Susner',
      'Suriāpet',
      'Suriānwān',
      'Surgāna',
      'Surendranagar',
      'Sūratgarh',
      'Surat',
      'Sūrandai',
      'Surajpur',
      'Sūrajgarh',
      'Supaul',
      'Suntikoppa',
      'Sunel',
      'Sundarnagar',
      'Sundargarh',
      'Sunām',
      'Sumbal',
      'Sulya',
      'Sūlūru',
      'Sulur',
      'Sultanpur',
      'Sultānpur',
      'Sukma',
      'Suket',
      'Sūjāngarh',
      'Suchindram',
      'Suār',
      'Srivilliputhur',
      'Srīvardhan',
      'Srivaikuntam',
      'Srīsailain',
      'Srīrāmnagar',
      'Srīperumbūdūr',
      'Srīnivāspur',
      'Sringeri',
      'Srinagar',
      'Srīnagar',
      'Srīmushnam',
      'Sri Mādhopur',
      'Karanpur',
      'Srikakulam',
      'Sri Dūngargarh',
      'Soygaon',
      'Soron',
      'Soro',
      'Sorbhog',
      'Sorada',
      'Sorab',
      'Sopur',
      'Sonīpat',
      'Songadh',
      'Sonepur',
      'Sonegaon',
      'Rajpur Sonarpur',
      'Sonāri',
      'Sonāmura',
      'Sonāmukhi',
      'Somvārpet',
      'Sompeta',
      'Someshwar',
      'Solim',
      'Solan',
      'Sojītra',
      'Sojat',
      'Sohna',
      'Sohāgpur',
      'Sohāgi',
      'Sodpur',
      'Soalkuchi',
      'Siwāna',
      'Siwān',
      'Sivakasi',
      'Sivagiri',
      'Sivaganga',
      'Siuri',
      'Sitārganj',
      'Sītāpur',
      'Sītāmau',
      'Sītāmarhi',
      'Siswā Bāzār',
      'Sisauli',
      'Sirūr',
      'Sirumugai',
      'Siruguppa',
      'Sirsilla',
      'Sirsi',
      'Sirsāganj',
      'Sirsa',
      'Sirsā',
      'Sirpur',
      'Sironj',
      'Sirohi',
      'Sirmaur',
      'Sīrkāzhi',
      'Sirhind',
      'Sirāthu',
      'Sīra',
      'Sinor',
      'Sinnar',
      'Sini',
      'Singur',
      'Singtam',
      'Singoli',
      'Singarāyakonda',
      'Singapperumālkovil',
      'Singānallūr',
      'Sindi',
      'Sindhnūr',
      'Sindgi',
      'Shimla',
      'Simga',
      'Simdega',
      'Simaria',
      'Silvassa',
      'Sillod',
      'Silchar',
      'Silao',
      'Sīkar',
      'Sikandra Rao',
      'Sikandra',
      'Sikandarpur',
      'Sikandarābād',
      'Sikka',
      'Sijua',
      'Sihorā',
      'Sihor',
      'Sidlaghatta',
      'Sidhpura',
      'Sidhi',
      'Sidhaulī',
      'Siddipet',
      'Siddhapur',
      'Siddāpur',
      'Sibsāgar',
      'Shyamnagar',
      'Shupīyan',
      'Shujālpur',
      'Shrīrangapattana',
      'Shrīrāmpur',
      'Shrīgonda',
      'Srāvana Belgola',
      'Shorāpur',
      'Shōranūr',
      'Sholinghur',
      'Solāpur',
      'Shivrājpur',
      'Shivpuri',
      'Shīshgarh',
      'Shirwal',
      'Shirpur',
      'Shirhatti',
      'Shirgaon',
      'Shirdi',
      'Shimoga',
      'Shillong',
      'Siliguri',
      'Shikohābād',
      'Shikārpūr',
      'Shikārpur',
      'Shiggaon',
      'Shertallai',
      'Sherkot',
      'Sherghāti',
      'Shergarh',
      'Sheopur',
      'Sheohar',
      'Sheoganj',
      'Shegaon',
      'Shāntipur',
      'Shankargarh',
      'Shamsābād',
      'Shāmli',
      'Shāmgarh',
      'Shām Churāsi',
      'Shājāpur',
      'Sheikhpura',
      'Shāhpura',
      'Shāhpur',
      'Shāhkot',
      'Shāhjānpur',
      'Shāhi',
      'Shāhgarh',
      'Shāhganj',
      'Shahdol',
      'Shāhāpur',
      'Shāhāda',
      'Shāhābād',
      'Serula',
      'Serchhīp',
      'Seram',
      'Seorīnārāyan',
      'Seoni Mālwa',
      'Seoni',
      'Seondha',
      'Seohāra',
      'Sendhwa',
      'Sehore',
      'Secunderabad',
      'Sāyla',
      'Sāyalkudi',
      'Sawāi Mādhopur',
      'Sāvda',
      'Savanūr',
      'Sāvantvādi',
      'Sausar',
      'Saurikh',
      'Saundatti',
      'Sathyamangalam',
      'Satwās',
      'Sattur',
      'Sattenapalle',
      'Sathankulam',
      'Satrikh',
      'Satna',
      'Satara',
      'Satānā',
      'Sāsvad',
      'Sāsni',
      'Sarwār',
      'Sāruberā',
      'Sarkhej',
      'Sarka Ghāt',
      'Sarīla',
      'Sargūr',
      'Sardulgarh',
      'Sardhana',
      'Sardārshahr',
      'Sarauli',
      'Sārangpur',
      'Sārangarh',
      'Saraipali',
      'Sarāi Mīr',
      'Saraikela',
      'Sarai Ekdil',
      'Sarāi Ākil',
      'Sarāhan',
      'Sapatgrām',
      'Saoner',
      'Sānwer',
      'Sanvordem',
      'Santokhgarh',
      'Sanquelim',
      'Sānkrāil',
      'Sankheda',
      'Sankeshwar',
      'Sanivārsante',
      'Sanguem',
      'Sangrūr',
      'Sāngola',
      'Sangod',
      'Sāngli',
      'Sangariā',
      'Sangāreddi',
      'Sangamner',
      'Sandūr',
      'Sandīla',
      'Sāndi',
      'Sancoale',
      'Sānchor',
      'Sānchi',
      'Sanāwad',
      'Sanaur',
      'Sānand',
      'Samthar',
      'Samrāla',
      'Samdari',
      'Sāmbhar',
      'Sambhal',
      'Sambalpur',
      'Sāmba',
      'Samāstipur',
      'Sāmalkot',
      'Samālkha',
      'Sālūr',
      'Sālūmbar',
      'Salon',
      'Saligao',
      'Salem',
      'Salāya',
      'Saktī',
      'Sakleshpur',
      'Sakīt',
      'Sāitlaw',
      'Sairang',
      'Saint Thomas Mount',
      'Sainthia',
      'Selu',
      'Sailāna',
      'Saiha',
      'Saidpur',
      'Sāhibganj',
      'Sahāwar',
      'Sahaswān',
      'Sahaspur',
      'Saharsa',
      'Sahāranpur',
      'Sagauli',
      'Saugor',
      'Sāgar',
      'Safīpur',
      'Safidon',
      'Sādri',
      'Sādāt',
      'Sadashivpet',
      'Sadalgi',
      'Sadābād',
      'Sachīn',
      'Sabrūm',
      'Sabāthu',
      'Sabalgarh',
      'Rusera',
      'Rura',
      'Ropar',
      'Rudraprayāg',
      'Rūdarpur',
      'Roorkee',
      'Ron',
      'Rohtak',
      'Rohru',
      'Roha',
      'Robertsonpet',
      'Robertsganj',
      'Risod',
      'Rishra',
      'Rishīkesh',
      'Rīngas',
      'Richha',
      'Riāsi',
      'Rewāri',
      'Rewa',
      'Revelganj',
      'Revadanda',
      'Repalle',
      'Reoti',
      'Renukūt',
      'Renigunta',
      'Rengāli',
      'Remuna',
      'Rehti',
      'Rehli',
      'Rāzole',
      'Rāzām',
      'Raybag',
      'Rāyadrug',
      'Rāyachoti',
      'Rāya',
      'Rāy',
      'Raxaul',
      'Rāwatsār',
      'Rāwatbhāta',
      'Rāver',
      'Raurkela',
      'Ratnagiri',
      'Ratlām',
      'Ratia',
      'Rāth',
      'Ratanpur',
      'Ratangarh',
      'Rasūlābād',
      'Rasrā',
      'Rasipuram',
      'Rāpar',
      'Rānīr Bāzār',
      'Rānīpur',
      'Rānipet',
      'Rānikhet',
      'Rānīganj',
      'Rānībennur',
      'Rānia',
      'Rāni',
      'Rangpo',
      'Rangia',
      'Rangāpāra',
      'Ranchi',
      'Rānāvāv',
      'Rānāpur',
      'Rānāghāt',
      'Rāmtek',
      'Rampur Hat',
      'Rāmpura',
      'Rāmpur',
      'Rāmnagar',
      'Rāmkola',
      'Rāmjībanpur',
      'Rāmgundam',
      'Rāmgarh',
      'Rāmganj Mandi',
      'Rameswaram',
      'Rām Dās',
      'Rambha',
      'Rāmban',
      'Rāmāpuram',
      'Rāmānuj Ganj',
      'Ramanathapuram',
      'Closepet',
      'Rāmamangalam',
      'Rāmachandrapuram',
      'Rājūra',
      'Rājur',
      'Rājula',
      'Rājsamand',
      'Rājpura',
      'Rajpur',
      'Rājpīpla',
      'Rāj-Nāndgaon',
      'Rājnagar',
      'Rājmahal',
      'Rājkot',
      'Rājgurunagar',
      'Rājgīr',
      'Rājgarh',
      'Rajaori',
      'Rāja Sānsi',
      'Rājāpur',
      'Rajapalaiyam',
      'Rāzampeta',
      'Rājaldesar',
      'Rājākhera',
      'Rājahmundry',
      'Rāīwāla Bara',
      'Rāisinghnagar',
      'Raisen',
      'Rāipur',
      'Raipur',
      'Rāikot',
      'Raigarh',
      'Rāiganj',
      'Rāichūr',
      'Raia',
      'Rāhuri',
      'Rāhon',
      'Rahimatpur',
      'Rāhatgarh',
      'Rahā',
      'Raghunāthpur',
      'Raghunathpur',
      'Rāghogarh',
      'Rafiganj',
      'Raebareli',
      'Rādhanpur',
      'Rādhākund',
      'Radaur',
      'Rabūpura',
      'Rabkavi',
      'Kollam',
      'Queula',
      'Quepem',
      'Qāzigund',
      'Kasba',
      'Qādiān',
      'Puttūr',
      'Pushkar',
      'Pusad',
      'Purwā',
      'Purushottampur',
      'Puruliya',
      'Purnia',
      'Pūrna',
      'Puri',
      'Pūranpur',
      'Pupri',
      'Punjai Puliyampatti',
      'Punganūru',
      'Pune',
      'Pūndri',
      'Punāsa',
      'Punalūr',
      'Pūnāhāna',
      'Pulwama',
      'Pullambādi',
      'Puliyūr',
      'Puliyangudi',
      'Pulivendla',
      'Pulgaon',
      'Pukhrāyān',
      'Puduvāyal',
      'Pudūr',
      'Puduppatti',
      'Pudukkottai',
      'Proddatūr',
      'Pratāpgarh',
      'Port Blair',
      'Porsa',
      'Porbandar',
      'Poonamalle',
      'Ponnūru',
      'Ponneri',
      'Ponnāni',
      'Ponnampet',
      'Ponnamarāvati',
      'Puducherry',
      'Ponda',
      'Polūr',
      'Pollachi',
      'Polavaram',
      'Polasara',
      'Pokaran',
      'Pithorāgarh',
      'Pithora',
      'Pithāpuram',
      'Piro',
      'Piriyāpatna',
      'Pirāwa',
      'Piravam',
      'Pīpri',
      'Pipraich',
      'Pippara',
      'Piploda',
      'Pipili',
      'Pīpār',
      'Pīpalkoti',
      'Pinjaur',
      'Pindwāra',
      'Pināhat',
      'Pimpri',
      'Pilkhua',
      'Pīlibhīt',
      'Pilibangan',
      'Pilāni',
      'Pihānī',
      'Phūlpur',
      'Phulpur',
      'Phulera',
      'Phulbāni',
      'Phirangipuram',
      'Phillaur',
      'Phek',
      'Pharihā',
      'Phaphūnd',
      'Phaltan',
      'Phalodi',
      'Phalauda',
      'Phagwāra',
      'Petlāwad',
      'Petlād',
      'Perūr',
      'Perundurai',
      'Perumpāvūr',
      'Pernem',
      'Periyapatti',
      'Periyanegamam',
      'Periyanayakkanpalaiyam',
      'Periyakulam',
      'Perya',
      'Peravurani',
      'Peranāmpattu',
      'Peranamallūr',
      'Perambalur',
      'Peraiyur',
      'Penukonda',
      'Penugonda',
      'Pennāthur',
      'Pennāgaram',
      'Pennādam',
      'Pendra',
      'Pen',
      'Pehowa',
      'Peddāpuram',
      'Peddapalli',
      'Pedana',
      'Payyannūr',
      'Pawāyan',
      'Pawai',
      'Pāvugada',
      'Pauri',
      'Pawni',
      'Pātūr',
      'Pātuli',
      'Pattukkottai',
      'Patti',
      'Pattan',
      'Pātrasāer',
      'Patnāgarh',
      'Patna',
      'Patiāli',
      'Patiāla',
      'Pāthri',
      'Patharia',
      'Pāthardih',
      'Pāthardi',
      'Pathānkot',
      'Pathanāmthitta',
      'Pathalgaon',
      'Pataudi',
      'Patancheru',
      'Pātan',
      'Patāmundai',
      'Pāsighāt',
      'Pasān',
      'Parvatsar',
      'Pārvatipuram',
      'Partūr',
      'Partāpur',
      'Parshādepur',
      'Parola',
      'Parol',
      'Parnera',
      'Parli Vaijnāth',
      'Parlākimidi',
      'Pariyāpuram',
      'Parīchhatgarh',
      'Pārīchha',
      'Pārdi',
      'Parbhani',
      'Paravūr Tekkumbhāgam',
      'Parāsia',
      'Paramagudi',
      'Parādīp Garh',
      'Pāppinisshēri',
      'Pāppārappatti',
      'Pāpireddippatti',
      'Papanasam',
      'Pāonta Sāhib',
      'Panvel',
      'Pānsemāl',
      'Panruti',
      'Panna',
      'Ponmana',
      'Pānīpat',
      'Pānihāti',
      'Panhāla',
      'Pāngāla',
      'Pandua',
      'Pāndoh',
      'Pāndhurnā',
      'Pandharpur',
      'Pandhāna',
      'French Rocks',
      'Pāndātarai',
      'Pandaria',
      'Pānchgani',
      'Panara',
      'Panamaram',
      'Panaji',
      'Panāgar',
      'Palwal',
      'Pāloncha',
      'Palani',
      'Palmaner',
      'Pallippatti',
      'Pallipattu',
      'Pallikondai',
      'Pallevāda',
      'Palle',
      'Pallāvaram',
      'Pallattūr',
      'Pallappatti',
      'Palladam',
      'Pālkonda',
      'Pāliyād',
      'Pālitāna',
      'Paliā Kalān',
      'Pāli',
      'Pālghāt',
      'Pālghar',
      'Palera',
      'Palāsbāri',
      'Palāsa',
      'Pālanpur',
      'Pālampur',
      'Pālamedu',
      'Pālakollu',
      'Pālakkodu',
      'Pakur',
      'Pākāla',
      'Paithan',
      'Pahāsu',
      'Pahlgām',
      'Padrauna',
      'Padra',
      'Padmanābhapuram',
      'Paddhari',
      'Padampur',
      'Padam',
      'Pachperwa',
      'Pāchora',
      'Pachmarhi',
      'Ottappālam',
      'Osmanabad',
      'Orchha',
      'Oran',
      'Orai',
      'Ongole',
      'Omalur',
      'Olpād',
      'Okha',
      'Ozar',
      'Odugattūr',
      'Odlābāri',
      'Obra',
      'Nyāmti',
      'Nūzvīd',
      'Nūrpur Kalān',
      'Nūrpur',
      'Nūrmahal',
      'Numāligarh',
      'Nūh',
      'Nowrangapur',
      'North Vanlaiphai',
      'North Lakhimpur',
      'North Guwāhāti',
      'Nongstoin',
      'Nongpoh',
      'Nokha',
      'Nohar',
      'Noāmundi',
      'Nizāmābād',
      'Niwāri',
      'Nirsā',
      'Nirmāli',
      'Nirmal',
      'Nipāni',
      'Neem ka Thana',
      'Nīmbāhera',
      'Nimāparha',
      'Nīmāj',
      'Nīlokheri',
      'Nīlēshwar',
      'Nilanga',
      'Nilakottai',
      'Nīlgiri',
      'Nihtaur',
      'Needamangalam',
      'Nidadavole',
      'Nichlaul',
      'Neyyāttinkara',
      'Neyveli',
      'New Delhi',
      'Neturhāt',
      'Neral',
      'Nepānagar',
      'Nellore',
      'Nellikkuppam',
      'Nelamangala',
      'Nedumangād',
      'Nāzirā',
      'Nāyudupet',
      'Nayāgarh',
      'Naya Bāzār',
      'Nawanshahr',
      'Nawānshahr',
      'Nawalgarh',
      'Niwai',
      'Nawāda',
      'Nawābganj',
      'Nāwa',
      'Navsari',
      'Navelim',
      'Navalgund',
      'Navadwīp',
      'Nautanwa',
      'Naugachhia',
      'Nāttarasankottai',
      'Nattam',
      'Nāthdwāra',
      'Nasrullāhganj',
      'Nāsriganj',
      'Nāspur',
      'Nasīrābād',
      'Nashik',
      'Narwar',
      'Narwāna',
      'Narsīpatnam',
      'Nārsingi',
      'Narsinghgarh',
      'Narsimhapur',
      'Naroda',
      'Nārnaund',
      'Nārnaul',
      'Narharpur',
      'Nargund',
      'Narendranagar',
      'Narela',
      'Naregal',
      'Nārāyanpet',
      'Narāyangarh',
      'Nārāyangarh',
      'Nārāyanavanam',
      'Nāravārikuppam',
      'Naraura',
      'Narauli',
      'Narasingāpuram',
      'Narasimharājapura',
      'Narasaraopet',
      'Narasapur',
      'Narasannapeta',
      'Naraini',
      'Naraina',
      'Napāsar',
      'Nānpāra',
      'Nannilam',
      'Nanjangūd',
      'Nanguneri',
      'Nāngloi Jāt',
      'Nangavalli',
      'Nangal',
      'Nandyāl',
      'Nandurbar',
      'Nāndūra Buzurg',
      'Nandikotkūr',
      'Nandigāma',
      'Nandgaon',
      'Nāndgaon',
      'Nanded',
      'Nanauta',
      'Nāmrup',
      'Namli',
      'Namchi',
      'Nambutalai',
      'Nambiyūr',
      'Nāmakkal',
      'Nāmagiripettai',
      'Naliya',
      'Nalhāti',
      'Nalgonda',
      'Naldurg',
      'Nālāgarh',
      'Nakūr',
      'Naksalbāri',
      'Nakodar',
      'Najībābād',
      'Najafgarh',
      'Nainwa',
      'Nainpur',
      'Naini Tāl',
      'Naihāti',
      'Naīgarhi',
      'Nahorkatiya',
      'Nāhan',
      'Nagrota',
      'Nagrām',
      'Nagpur',
      'Nāgothana',
      'Nāgod',
      'Nāgireddipalli',
      'Nagīna',
      'Nāgercoil',
      'Nagda',
      'Nāgaur',
      'Nāgar Karnūl',
      'Nagari',
      'Nagar',
      'Negapatam',
      'Nāgamangala',
      'Naduvattam',
      'Naduvannūr',
      'Nadīgaon',
      'Nadiād',
      'Nādbai',
      'Nādaun',
      'Nādāpuram',
      'Nabīnagar',
      'Nābha',
      'Mysore',
      'Muzaffarpur',
      'Muzaffarnagar',
      'Mūvattupula',
      'Muttupet',
      'Mustafābād',
      'Mussoorie',
      'Musiri',
      'Mushābani',
      'Musāfir-Khāna',
      'Murwāra',
      'Murud',
      'Murtajāpur',
      'Murshidābād',
      'Mursān',
      'Murlīganj',
      'Morinda',
      'Muri',
      'Murgūd',
      'Murbād',
      'Muragācha',
      'Murādnagar',
      'Munnar',
      'Munirābād',
      'Monghyr',
      'Mungeli',
      'Mungaoli',
      'Mūndwa',
      'Mundra',
      'Mundi',
      'Mundgod',
      'Mundargi',
      'Multai',
      'Mūlki',
      'Mulgund',
      'Mulbāgal',
      'Muluppilagadu',
      'Mūlanūr',
      'Mūl',
      'Muktsar',
      'Mukher',
      'Mukeriān',
      'Muhammadābād',
      'Mugma',
      'Mughal Sarāi',
      'Mudukulattūr',
      'Mudkhed',
      'Mudgere',
      'Mudhol',
      'Mudgal',
      'Muddebihāl',
      'Mūdbidri',
      'Mubarakpur',
      'Mowād',
      'Mothīhāri',
      'Moth',
      'Morwa',
      'Morsi',
      'Morjim',
      'Morena',
      'Morbi',
      'Morār',
      'Morānha',
      'Moram',
      'Morādābād',
      'Mon',
      'Mokokchūng',
      'Mokameh',
      'Moirāng',
      'Mohpa',
      'Mohiuddinnagar',
      'Mohgaon',
      'Mohanūr',
      'Mohanpur',
      'Mohān',
      'Moga',
      'Modāsa',
      'Meethari Marwar',
      'Misrikh',
      'Mirzāpur',
      'Mirik',
      'Miriālgūda',
      'Mīrganj',
      'Mīrānpur Katra',
      'Mīrānpur',
      'Mīnjūr',
      'Milak',
      'Mihona',
      'Mhāsvād',
      'Mhasla',
      'Mettur',
      'Mettupalayam',
      'Mettuppālaiyam',
      'Merta',
      'Mendarda',
      'Memāri',
      'Melur',
      'Melukote',
      'Mehndāwal',
      'Mehnagar',
      'Mehkar',
      'Meghraj',
      'Meerut',
      'Medinīpur',
      'Medchal',
      'Medak',
      'Mayiladuthurai',
      'Mayāng Imphāl',
      'Māyakonda',
      'Mawāna',
      'Mavoor',
      'Māvelikara',
      'Maurānwān',
      'Maur',
      'Mauganj',
      'Maudaha',
      'Mau Aimma',
      'Mau',
      'Māttūr',
      'Mattanur',
      'Mathura',
      'Mātherān',
      'Mataundh',
      'Mātābhānga',
      'Masinigudi',
      'Masaurhi Buzurg',
      'Mormugao',
      'Mārkāpur',
      'Mariāni',
      'Mariāhu',
      'Marhaura',
      'Margherita',
      'Mārāndahalli',
      'Marakkanam',
      'Marayur',
      'Mārahra',
      'Māpuca',
      'Mānwat',
      'Mānvi',
      'Manthani',
      'Mansar',
      'Mānsa',
      'Mānpur',
      'Manor',
      'Manohar Thāna',
      'Manoharpur',
      'Mannargudi',
      'Mannārakkāt',
      'Manmād',
      'Mankāpur',
      'Mankāchar',
      'Majalgaon',
      'Manjhanpur',
      'Manjēshvar',
      'Manjeri',
      'Manipal',
      'Mānikpur',
      'Manihāri',
      'Maniar',
      'Mangrūl Pīr',
      'Māngrol',
      'Manglaur',
      'Mangawān',
      'Mangan',
      'Mangalore',
      'Mangaldai',
      'Mangalam',
      'Mangalagiri',
      'Maner',
      'Mandya',
      'Māndvi',
      'Mandsaur',
      'Māndleshwar',
      'Mandlā',
      'Mandi',
      'Mandholi Kalān',
      'Mandāwar',
      'Mandasa',
      'Mandapeta',
      'Mandapam',
      'Māndalgarh',
      'Māndal',
      'Mancherāl',
      'Manchar',
      'Manāwar',
      'Manavālakurichi',
      'Mānāvadar',
      'Manāsa',
      'Manapparai',
      'Manamadurai',
      'Manalūrpettai',
      'Manāli',
      'Manali',
      'Mamit',
      'Seven Pagodas',
      'Mālvan',
      'Mālūr',
      'Mālpura',
      'Mālpur',
      'Malpe',
      'Māllūr',
      'Mallasamudram',
      'Mallāpuram',
      'Malkera',
      'Malkāpur',
      'Malakanagiri',
      'Malīhābād',
      'Malhārgarh',
      'Māler Kotla',
      'Mālegaon',
      'Malavalli',
      'Malaut',
      'Malappuram',
      'Mākum',
      'Maksi',
      'Makrāna',
      'Makhu',
      'Mākhjan',
      'Majītha',
      'Majholi',
      'Mairwa',
      'Mairang',
      'Mainpuri',
      'Maindargi',
      'Maināguri',
      'Mailāni',
      'Maihar',
      'Maibong',
      'Mahwah',
      'Mahur',
      'Mahudha',
      'Mahroni',
      'Maholi',
      'Mahobā',
      'Mahmudābād',
      'Mahīshādal',
      'Mahgawān',
      'Maheshwar',
      'Mahendragarh',
      'Mahemdāvād',
      'Mahē',
      'Mahbūbnagar',
      'Mahbūbābād',
      'Mahāsamund',
      'Mahārāganj',
      'Mahārājganj',
      'Bishunpur Urf Mahārājganj',
      'Mahārājgani',
      'Maham',
      'Mahālingpur',
      'Mahād',
      'Mahāban',
      'Mahābaleshwar',
      'Maghar',
      'Māgām',
      'Māgadi',
      'Madurāntakam',
      'Madurai',
      'Madukkūr',
      'Madukkarai',
      'Mādugula',
      'Chennai',
      'Madikeri',
      'Madhyamgram',
      'Madhupur',
      'Madhugiri',
      'Madhubani',
      'Mādhogarh',
      'Madhogarh',
      'Mādhoganj',
      'Madhipura',
      'Madgaon',
      'Maddūr',
      'Madanpur',
      'Madanapalle',
      'Machilīpatnam',
      'Machhlīshahr',
      'Māchhīwāra',
      'Mācherla',
      'Māchalpur',
      'Lunglei',
      'Lūnāvāda',
      'Ludhiāna',
      'Lucknow',
      'Luckeesarai',
      'Losal',
      'Lormi',
      'Loni',
      'Londa',
      'Lonavla',
      'Lonar',
      'Lohogaon',
      'Lohāru',
      'Lohārdagā',
      'Lohaghāt',
      'Lodhīkheda',
      'Lingsugūr',
      'Limbdi',
      'Leteri',
      'Leh',
      'Lāwar Khās',
      'Laungowāl',
      'Latur',
      'Lāthi',
      'Lātehār',
      'Lāsalgaon',
      'Lar',
      'Lansdowne',
      'Lānja',
      'Lālsot',
      'Lālpur',
      'Lalitpur',
      'Lalgudi',
      'Lālgola',
      'Lālganj',
      'Lālam',
      'Lāla',
      'Lakshmeshwar',
      'Lakshettipet',
      'Laksar',
      'Lakhyabad',
      'Lakhtar',
      'Lakhnādon',
      'Lakhnā',
      'Lakhipur',
      'Lakhīmpur',
      'Lākheri',
      'Lāharpur',
      'Lahār',
      'Lādwa',
      'Lādnūn',
      'Lachhmangarh Sīkar',
      'Kyelang',
      'Kuzhithurai',
      'Koothanallur',
      'Kuttampuzha',
      'Kuttālam',
      'Kutiyāna',
      'Kutiatodu',
      'Kushtagi',
      'Kushālnagar',
      'Kushālgarh',
      'Kurud',
      'Kurnool',
      'Kurinjippādi',
      'Kurgunta',
      'Kurduvādi',
      'Kurāra',
      'Kurandvād',
      'Kuppam',
      'Kunnattūr',
      'Kunnamkulam',
      'Kunnamangalam',
      'Kunigal',
      'Kundla',
      'Kundgol',
      'Kundarkhi',
      'Kunda',
      'Kumta',
      'Kumsi',
      'Kūmher',
      'Kumhāri',
      'Kumbhrāj',
      'Kumbakonam',
      'Kumaralingam',
      'Kulu',
      'Kulti',
      'Kulpahār',
      'Kulittalai',
      'Kulgam',
      'Kulattūr',
      'Kukshi',
      'Kūkatpalli',
      'Kuju',
      'Kūdligi',
      'Kudāl',
      'Kudachi',
      'Kūd',
      'Kuchera',
      'Kuchāman',
      'Kuchaiburi',
      'Krishnarājpet',
      'Krishnanagar',
      'Krishnagiri',
      'Kozhikode',
      'Koynanagar',
      'Kovvūr',
      'Kovūr',
      'Kovilpatti',
      'Kovalam',
      'Kotwa',
      'Kottūru',
      'Kottayam',
      'Kottapalli',
      'Kottaiyūr',
      'Kottagūdem',
      'Kotra',
      'Kotputli',
      'Kotma',
      'Kotla',
      'Kotkhai',
      'Kot Īsa Khān',
      'Kothi',
      'Kothāpet',
      'Kotdwāra',
      'Kotār',
      'Kotapārh',
      'Kotamangalam',
      'Kotagiri',
      'Kota',
      'Kotā',
      'Kosigi',
      'Kosi',
      'Kosamba',
      'Korwai',
      'Korukollu',
      'Koregaon',
      'Korba',
      'Koratla',
      'Koratagere',
      'Korāput',
      'Korampallam',
      'Korādi',
      'Koradāchcheri',
      'Koppal',
      'Koppa',
      'Kopargaon',
      'Kopāganj',
      'Konnūr',
      'Konnagar',
      'Konganāpuram',
      'Kondapalle',
      'Kondalwādi',
      'Kondagaon',
      'Konch',
      'Konārka',
      'Konanūr',
      'Kombai',
      'Kolasib',
      'Kollegāl',
      'Kolhāpur',
      'Kolāras',
      'Kolār',
      'Kolanukonda',
      'Colachel',
      'Kokrajhar',
      'Kohīma',
      'Koelwār',
      'Kodungallūr',
      'Kodumudi',
      'Kodoli',
      'Kodlipet',
      'Kodīnar',
      'Kodigenahalli',
      'Kodarmā',
      'Kodār',
      'Kodala',
      'Kodaikānāl',
      'Kuchinda',
      'Koch Bihār',
      'Koāth',
      'Kizhake Chālakudi',
      'Kithor',
      'Kishtwār',
      'Kishni',
      'Kishanpur',
      'Kishangarh',
      'Kishanganj',
      'Kiri Buru',
      'Kīratpur',
      'Kiraoli',
      'Kīranūr',
      'Kirākat',
      'Kinwat',
      'Kilvelur',
      'Kichha',
      'Khūtār',
      'Khusropur',
      'Khurja',
      'Khurda',
      'Khurai',
      'Khunti',
      'Khuldābād',
      'Khujner',
      'Khudāganj',
      'Khowai',
      'Khopoli',
      'Khonsa',
      'Khirkiyān',
      'Khilchipur',
      'Khetri',
      'Khetia',
      'Kheri',
      'Kherālu',
      'Khem Karan',
      'Khekra',
      'Khedbrahma',
      'Kheda',
      'Khed',
      'Khawhai',
      'Khaur',
      'Khātra',
      'Khatīma',
      'Khātegaon',
      'Khatauli',
      'Khārupatia',
      'Kharsia',
      'Kharsāwān',
      'Kharod',
      'Kharkhauda',
      'Kharhiāl',
      'Khargupur',
      'Khargone',
      'Khargāpur',
      'Kharela',
      'Khardah',
      'Kharar',
      'Kharakvasla',
      'Kharagpur',
      'Khāpa',
      'Khānpur',
      'Khanna',
      'Khandwa',
      'Khandela',
      'Khānāpur',
      'Khammam',
      'Khamharia',
      'Khāmgaon',
      'Khambhāt',
      'Khambhāliya',
      'Khamaria',
      'Khamānon Kalān',
      'Khallikot',
      'Khalīlābād',
      'Khajuraho Group of Monuments',
      'Khairāgarh',
      'Khairābād',
      'Khair',
      'Khailār',
      'Khagaul',
      'Khagaria',
      'Khāga',
      'Khadki',
      'Khada',
      'Khāchrod',
      'Kesinga',
      'Keshorai Pātan',
      'Keshod',
      'Kerūr',
      'Kenduadīh',
      'Kendrāparha',
      'Kenda',
      'Kemrī',
      'Kelamangalam',
      'Kekri',
      'Kayattār',
      'Kāyankulam',
      'Kayalpattinam',
      'Kawardha',
      'Kawānt',
      'Kāveripatnam',
      'Kavaratti',
      'Kavalūr',
      'Kāvali',
      'Kāttupputtūr',
      'Kattivākkam',
      'Kattanam',
      'Kātrās',
      'Katra',
      'Katpur',
      'Kātpādi',
      'Kātoya',
      'Kātol',
      'Katihar',
      'Kāti',
      'Kathua',
      'Kāthor',
      'Katghora',
      'Katangi',
      'Kasrāwad',
      'Kashipur',
      'Kāsganj',
      'Kasauli',
      'Kāsaragod',
      'Karwar',
      'Karur',
      'Karumbākkam',
      'Kartārpur',
      'Kārsiyāng',
      'Karrāpur',
      'Karol Bāgh',
      'Karnāl',
      'Karmāla',
      'Kārkala',
      'Karjat',
      'Kariapatti',
      'Karīmpur',
      'Karīmnagar',
      'Karīmganj',
      'Karhal',
      'Kargil',
      'Karera',
      'Kareli',
      'Karauli',
      'Karārī',
      'Kāranja',
      'Karamsad',
      'Karambakkudi',
      'Kāramadai',
      'Kāraikkudi',
      'Kāraikāl',
      'Karād',
      'Kapūrthala',
      'Captainganj',
      'Kāpren',
      'Kapadvanj',
      'Kantilo',
      'Kānth',
      'Kantābānji',
      'Kānt',
      'Kanpur',
      'Kānor',
      'Kānodar',
      'Kannod',
      'Kanniyākumāri',
      'Kannavam',
      'Kannauj',
      'Kānnangād',
      'Kannad',
      'Kānkon',
      'Kankipādu',
      'Kānker',
      'Kānke',
      'Kankauli',
      'Kanigiri',
      'Kāngar',
      'Kāngpokpi',
      'Kangayam',
      'Kandukūr',
      'Kāndri',
      'Kāndra',
      'Kāndla',
      'Kāndi',
      'Kāndhla',
      'Kanchipuram',
      'Kānkānhalli',
      'Kanadukattan',
      'Kamuthi',
      'Kāmthi',
      'Kampli',
      'Kampil',
      'Cumbum',
      'Kāmārhāti',
      'Kāmāreddi',
      'Kāman',
      'Kamalpur',
      'Kamalganj',
      'Kāmalāpuram',
      'Kāmākhyānagar',
      'Kalyani',
      'Kalyān',
      'Kālundri',
      'Kalugumalai',
      'Kālpi',
      'Kalpatta',
      'Kālol',
      'Kālna',
      'Kalmeshwar',
      'Kallupatti',
      'Kallidaikurichi',
      'Kallakkurichchi',
      'Kālka',
      'Kāliyāganj',
      'Kanīna Khās',
      'Kālīnagar',
      'Kālimpong',
      'Kālikāpur',
      'Kalghatgi',
      'Kalavai',
      'Kālāvad',
      'Kalas',
      'Kālānwāli',
      'Kalanaur',
      'Kalānaur',
      'Kalamnūri',
      'Kalamb',
      'Kalakkādu',
      'Kalaīkunda',
      'Kālāgarh Project Colony',
      'Kālādhūngi',
      'Kakrāla',
      'Kākori',
      'Kākināda',
      'Kakdwip',
      'Kakching',
      'Kaithal',
      'Kairāna',
      'Kaintragarh',
      'Kaimori',
      'Kaimganj',
      'Kailāshahar',
      'Kailāras',
      'Kaikalūr',
      'Kāgal',
      'Kadūr',
      'Kadod',
      'Kadiri',
      'Kādīpur',
      'Kadi',
      'Kadaura',
      'Kadambūr',
      'Kadayanallur',
      'Kānchrāpāra',
      'Kachhwa',
      'Kabrāi',
      'Jutogh',
      'Junnar',
      'Jūnāgarh',
      'Jūnāgadh',
      'Jhumri Telaiya',
      'Jalandhar',
      'Shādīpur Julāna',
      'Jugsālai',
      'Jubbal',
      'Jua',
      'Joshīmath',
      'Jorhāt',
      'Jora',
      'Jalārpet',
      'Jogindarnagar',
      'Jogīghopa',
      'Jogbani',
      'Jodiya Bandar',
      'Jodhpur',
      'Jobner',
      'Jobat',
      'Jīran',
      'Jintūr',
      'Jīnd',
      'Jhūsi',
      'Jhunjhunūn',
      'Jhinjhāna',
      'Jhīnjhak',
      'Jharsuguda',
      'Jharia',
      'Jhārgrām',
      'Jhānsi',
      'Jhanjhārpur',
      'Jhālu',
      'Jhālrapātan',
      'Jhalidā',
      'Jhālāwār',
      'Jhajjar',
      'Jhā-Jhā',
      'Jhābua',
      'Jewar',
      'Jevargi',
      'Jetpur',
      'Jetalsar',
      'Jejūri',
      'Jeypore',
      'Jainagar',
      'Jaynagar Majilpur',
      'Jayamkondacholapuram',
      'Jawhār',
      'Iāwar',
      'Jawāla Mukhi',
      'Jāwad',
      'Jaunpur',
      'Jatāra',
      'Jatani',
      'Jaswantnagar',
      'Jasrāsar',
      'Jasrāna',
      'Jaspur',
      'Jasidih',
      'Jashpurnagar',
      'Jasdan',
      'Jarwal',
      'Jaorā',
      'Jānsath',
      'Jānjgīr',
      'Jangipur',
      'Jangaon',
      'Jandiāla Gurū',
      'Jandiāla',
      'Jāmuria',
      'Jamūī',
      'Jāmtāra',
      'Jamshedpur',
      'Jamnagar',
      'Jammu',
      'Jammalamadugu',
      'Jamkhandi',
      'Jambusar',
      'Jamālpur',
      'Jāmai',
      'Jāmadoba',
      'Jalpāiguri',
      'Jalor',
      'Jālna',
      'Jalgaon Jamod',
      'Jalgaon',
      'Jaleshwar',
      'Jalesar',
      'Jālaun',
      'Jalālpur',
      'Jalalpore',
      'Jalālī',
      'Jalālābād',
      'Jalālābad',
      'Jalakandapuram',
      'Jākhal',
      'Jājpur',
      'Jaito',
      'Jaithāri',
      'Jaitāran',
      'Jaisingpur',
      'Jaisinghnagar',
      'Jaisalmer',
      'Jais',
      'Jaipur',
      'Jainpur',
      'Jahāzpur',
      'Jahāngīrpur',
      'Jahāngīrābād',
      'Jahānābād',
      'Jagtiāl',
      'Jagraon',
      'Jagnair',
      'Jaggayyapeta',
      'Jagdīspur',
      'Jagdīshpur',
      'Jagdalpur',
      'Jagatsinghapur',
      'Jagannāthpur',
      'Jagalūr',
      'Jagādhri',
      'Jabalpur',
      'Itimādpur',
      'Itaunja',
      'Itārsi',
      'Itānagar',
      'Islāmpur',
      'Islāmnagar',
      'Isāgarh',
      'Irugūr',
      'Irinjālakuda',
      'Iringal',
      'Ingrāj Bāzār',
      'Indri',
      'Indore',
      'Indi',
      'Indergarh',
      'Indāpur',
      'Imphāl',
      'Iluppūr',
      'Ilkal',
      'Ilampillai',
      'Iklehra',
      'Ikauna',
      'Iglās',
      'Igatpuri',
      'Idukki',
      'Idappadi',
      'Ichhāwar',
      'Ichchāpuram',
      'Ichalkaranji',
      'Hyderābād',
      'Hadagalli',
      'Husainābād',
      'Hunsūr',
      'Hungund',
      'Hukeri',
      'Hugli',
      'Hubli',
      'Howli',
      'Hosūr',
      'Hospet',
      'Hoskote',
      'Hoshiārpur',
      'Hoshangābād',
      'Hosdurga',
      'Hosangadi',
      'Hosanagara',
      'Hosakote',
      'Honnāli',
      'Honavar',
      'Homnābād',
      'Hole Narsipur',
      'Holalkere',
      'Hojāi',
      'Hodal',
      'Hisuā',
      'Hisar',
      'Hiriyūr',
      'Hirekerūr',
      'Hīrāpur Hamesha',
      'Hirānagar',
      'Hīrākud',
      'Hinjilikatu',
      'Hingoli',
      'Hinganghāt',
      'Hindupur',
      'Hindoria',
      'Hindaun',
      'Himatnagar',
      'Hilsa',
      'Hesla',
      'Harbatpur',
      'Heggadadevankote',
      'Hazāribāgh',
      'Hayuliang',
      'Hāveri',
      'Hatta',
      'Hātod',
      'Hāthras',
      'Hātā',
      'Hastināpur',
      'Hassan',
      'Hāsimāra',
      'Hasanpur',
      'Harūr',
      'Harsūd',
      'Harrai',
      'Harpanahalli',
      'Harpālpur',
      'Harnai',
      'Haringhāta',
      'Hārij',
      'Harihar',
      'Haridwar',
      'Hariāna',
      'Harduāganj',
      'Hardoī',
      'Harda Khās',
      'Haraiya',
      'Hāpur',
      'Hāora',
      'Hanumāngarh',
      'Hansot',
      'Hānsi',
      'Hāngal',
      'Handiā',
      'Hampi',
      'Hamīrpur',
      'Halvad',
      'Hālol',
      'Haliyal',
      'Hālīsahar',
      'Haldwani',
      'Haldībāri',
      'Haldaur',
      'Hājo',
      'Hājipur',
      'Hājīpur',
      'Hājan',
      'Hailākāndi',
      'Hāflong',
      'Hadgāon',
      'Hābra',
      'Gyānpur',
      'Gyalshing',
      'Gwalior',
      'Guskhara',
      'Guruvāyūr',
      'Guru Har Sahāi',
      'Gursarāi',
      'Gursahāiganj',
      'Gurmatkāl',
      'Gurh',
      'Gurgaon',
      'Gunupur',
      'Guntur',
      'Guntakal Junction',
      'Gunnaur',
      'Gundlupēt',
      'Guna',
      'Gummidipundi',
      'Gumlā',
      'Gumia',
      'Guledagudda',
      'Gulbarga',
      'Gulāothi',
      'Gulābpura',
      'Guirim',
      'Guhāgar',
      'Gūduvāncheri',
      'Gūdūr',
      'Gudlavalleru',
      'Gudiyatham',
      'Gudivāda',
      'Gudibanda',
      'Gudāri',
      'Gudalur',
      'Gubbi',
      'Gua',
      'Goyerkāta',
      'Govindgarh',
      'Govardhan',
      'Goshaingaon',
      'Goshāīnganj',
      'Goshāinganj',
      'Gosāba',
      'Gorūr',
      'Goregaon',
      'Gorantla',
      'Gorakhpur',
      'Gopināthpur',
      'Gobichettipalayam',
      'Gopāmau',
      'Gopālur',
      'Gopālpur',
      'Gopālganj',
      'Gondiā',
      'Gondal',
      'Gondā City',
      'Gomoh',
      'Golakganj',
      'Gola Gokarannāth',
      'Golāghāt',
      'Gola Bāzār',
      'Gokul',
      'Gokavaram',
      'Gokarna',
      'Gokak',
      'Gohpur',
      'Gohānd',
      'Gohāna',
      'Gohadi',
      'Gogāpur',
      'Godhra',
      'Godda',
      'Gobindpur',
      'Gobārdānga',
      'Goa Velha',
      'Goālpāra',
      'Gīrīdīh',
      'Gingee',
      'Giddarbāha',
      'Giddalūr',
      'Gīdam',
      'Ghugus',
      'Ghoti Budrukh',
      'Ghosī',
      'Ghorāwal',
      'Ghogha',
      'Ghoga',
      'Ghiror',
      'Ghazīpur',
      'Ghāziābād',
      'Ghātsīla',
      'Ghātanji',
      'Ghātampur',
      'Ghātāl',
      'Gharghoda',
      'Gharaunda',
      'Ghansor',
      'Ghanaur',
      'Ghumārwīn',
      'Gevrai',
      'George Town',
      'Gaya',
      'Gawān',
      'Gautampura',
      'Gauripur',
      'Gauribidanur',
      'Gaurela',
      'Guwahati',
      'Garhwa',
      'Garui',
      'Gariadhar',
      'Gariāband',
      'Garhshankar',
      'Garhmuktesar',
      'Garhi Pūkhta',
      'Garhākota',
      'Garhdiwāla',
      'Garautha',
      'Gannavaram',
      'Ganj Murādābād',
      'Ganjām',
      'Ganguvāda',
      'Gangtok',
      'Gangolli',
      'Gangoh',
      'Gangāwati',
      'Gangavalli',
      'Gangārāmpur',
      'Gangāpur',
      'Gangānagar',
      'Gangākher',
      'Gangaikondān',
      'Gāndhī Nagar',
      'Gandhinagar',
      'Gāndhīdhām',
      'Gandevi',
      'Gāndarbal',
      'Gandai',
      'Galiākot',
      'Gajraula',
      'Gajendragarh',
      'Gagret',
      'Gadwāl',
      'Gadhinglaj',
      'Gadhada',
      'Gādarwāra',
      'Gadag',
      'Fort Gloster',
      'Forbesganj',
      'Fīrozpur Jhirka',
      'Ferozepore',
      'Fīrozābād',
      'Ferokh',
      'Fāzilka',
      'Fatwa',
      'Sakharkherda',
      'Fatehpur Sīkri',
      'Fatehpur Chaurāsi',
      'Fatehpur',
      'Fatehgarh Chūriān',
      'Fatehgarh',
      'Fatehganj West',
      'Fatehābād',
      'Farrukhnagar',
      'Farrukhābād',
      'Farīdpur',
      'Farīdnagar',
      'Farīdkot',
      'Faridabad',
      'Farakka',
      'Farah',
      'Fālākāta',
      'Faizpur',
      'Fyzābād',
      'Ettaiyapuram',
      'Etikoppāka',
      'Etāwah',
      'Erumaippatti',
      'Erraguntla',
      'Erode',
      'Erāttupetta',
      'Eraniel',
      'Erandol',
      'Eral',
      'Emmiganūr',
      'Ellore',
      'Elūr',
      'Elumalai',
      'Ellenabad',
      'Elāyirampannai',
      'Elamanchili',
      'Egra',
      'Dwārka',
      'Dwārāhāt',
      'Dūsi',
      'Durgapur',
      'Durgāpur',
      'Durg',
      'Dūngarpur',
      'Dungarpur',
      'Ganj Dundwāra',
      'Dumraon',
      'Dumra',
      'Dumka',
      'Dum Duma',
      'Dam Dam',
      'Duliāgaon',
      'Dugda',
      'Dūdhi',
      'Dudhani',
      'Dubrājpur',
      'Dostpur',
      'Dornakal',
      'Dorāha',
      'Dongargarh',
      'Dongargaon',
      'Dondaicha',
      'Dombivli',
      'Doiwāla',
      'Dohrighāt',
      'Dugadda',
      'Doddaballapura',
      'Doda',
      'Diu',
      'Dispur',
      'Dīsa',
      'Dirba',
      'Diphu',
      'Dīnhāta',
      'Dindori',
      'Dindigul',
      'Dīnānagar',
      'Dimāpur',
      'Diguvametta',
      'Digras',
      'Dīglūr',
      'Dighwāra',
      'Digha',
      'Digboi',
      'Digapahandi',
      'Dīg',
      'Dīdwāna',
      'Dicholi',
      'Dibrugarh',
      'Dibai',
      'Diamond Harbour',
      'Dhuwaran',
      'Dhūri',
      'Dhupgāri',
      'Dhuliān',
      'Dhūlia',
      'Dhuburi',
      'Dhrol',
      'Dhrāngadhra',
      'Dhorāji',
      'Dhone',
      'Dholka',
      'Dhola',
      'Dhing',
      'Dhilwan',
      'Dhenkānāl',
      'Dhemāji',
      'Dhekiajuli',
      'Dhaurahra',
      'Dhaulpur',
      'Dhārūr',
      'Dhāruhera',
      'Dharamsala',
      'Dharmavaram',
      'Dharmapuri',
      'Dharmanagar',
      'Dharmadam',
      'Dharmābād',
      'Dhāriwāl',
      'Dhāri',
      'Dhārchula',
      'Dharapuram',
      'Dharangaon',
      'Dharampuri',
      'Dharampur',
      'Dhār',
      'Dhanwār',
      'Dhanera',
      'Dhandhuka',
      'Dhanbad',
      'Dhanaura',
      'Dhanaula',
      'Dhāna',
      'Dhamtari',
      'Dhāmpur',
      'Dhāmnod',
      'Dhali',
      'Dhāka',
      'Dewas',
      'Dewā',
      'Deoli',
      'Devgarh',
      'Devgadh Bāriya',
      'Devarkonda',
      'Devaprayāg',
      'Devanhalli',
      'Devakottai',
      'Devadānappatti',
      'Deūlgaon Rāja',
      'Desūr',
      'Deshnoke',
      'Dergaon',
      'Dera Gopipur',
      'Derā Nānak',
      'Depālpur',
      'Deori Khās',
      'Deoria',
      'Deori',
      'Deoraniān',
      'Deolāli',
      'Deoghar',
      'Deogarh',
      'Deoband',
      'Denkanikota',
      'Delvāda',
      'Delhi',
      'Dehu',
      'Dehri',
      'Dehra Dūn',
      'Debīpur',
      'Dayāl Bāgh',
      'Davorlim',
      'Davangere',
      'Dausa',
      'Daurāla',
      'Daund',
      'Daulatpur',
      'Daulatābād',
      'Daudnagar',
      'Dattāpur',
      'Datia',
      'Dātāganj',
      'Dasūya',
      'Dāsna',
      'Daryāpur',
      'Dārwha',
      'Darsi',
      'Darlawn',
      'Dārjiling',
      'Dariba',
      'Darbhanga',
      'Dankaur',
      'Dandeli',
      'Dinapore',
      'Damoh',
      'Dāmnagar',
      'Damān',
      'Daltonganj',
      'Dalsingh Sarai',
      'Dalmau',
      'Dalkola',
      'Dalhousie',
      'Dākor',
      'Daitari',
      'Dohad',
      'Dahegām',
      'Dāhānu',
      'Dagshai',
      'Dādri',
      'Dadra',
      'Dabwāli',
      'Dabra',
      'Daboh',
      'Dābhol',
      'Dabhoi',
      'Cuttack',
      'Curti',
      'Curchorem',
      'Cuncolim',
      'Cuddapah',
      'Cuddalore',
      'Cortalim',
      'Coondapoor',
      'Colva',
      'Colovale',
      'Colonelganj',
      'Colgong',
      'Calangute',
      'Coimbatore',
      'Cochin',
      'Clement Town',
      'Chūru',
      'Churāchāndpur',
      'Chunār',
      'Vikāsnagar',
      'Chuāri Khās',
      'Chotila',
      'Chorhat',
      'Chopda',
      'Chopan',
      'Cholapuram',
      'Chodavaram',
      'Rampachodavaram',
      'Chittūr',
      'Chittoor',
      'Chittaurgarh',
      'Chittaranjan',
      'Chittarkonda',
      'Chitradurga',
      'Chītāpur',
      'Chiria',
      'Chirgaon',
      'Chidawa',
      'Chīrāla',
      'Chīpurupalle',
      'Chiplūn',
      'Chintāmani',
      'Chinna Salem',
      'Chinnamanūr',
      'Chincholi',
      'Chinchinim',
      'Chinchani',
      'Chillupār',
      'Chilakalūrupet',
      'Chikodi',
      'Chiknāyakanhalli',
      'Chikmagalūr',
      'Chikitigarh',
      'Chikhli',
      'Chik Ballāpur',
      'Chidambaram',
      'Chicholi',
      'Chichli',
      'Chicalim',
      'Chhuīkhadān',
      'Chhoti Sādri',
      'Chhota Udepur',
      'Chhindwāra',
      'Chhibrāmau',
      'Chhatarpur',
      'Chhātāpur',
      'Chhāta',
      'Chharra',
      'Chhaprauli',
      'Chāpra',
      'Chhāpar',
      'Chhala',
      'Chhachhrauli',
      'Chhabra',
      'Cheyyur',
      'Chettipālaiyam',
      'Chetput',
      'Cherrapunji',
      'Chennimalai',
      'Chengannūr',
      'Chengam',
      'Chengalpattu',
      'Chēlakara',
      'Chechat',
      'Chetwayi',
      'Chaupāl',
      'Chaksu',
      'Chatrapur',
      'Chatrā',
      'Chātakonda',
      'Chās',
      'Charthāwal',
      'Charkhi Dādri',
      'Charkhāri',
      'Chāpar',
      'Channarāyapatna',
      'Channapatna',
      'Channagiri',
      'Changanācheri',
      'Chāndor',
      'Chāndūr Bāzār',
      'Chāndūr',
      'Chāndur',
      'Chānda',
      'Chandrakona',
      'Chāndpur',
      'Chandla',
      'Chāndil',
      'Chandīgarh',
      'Chandia',
      'Chanderi',
      'Chāndbāli',
      'Chanduasi',
      'Chandauli',
      'Chandannagar',
      'Chānasma',
      'Chamrajnagar',
      'Chāmpua',
      'Champawat',
      'Chāmpa',
      'Chamba',
      'Challapalle',
      'Challakere',
      'Chālisgaon',
      'Chalāla',
      'Chākuliā',
      'Chakrāta',
      'Chakradharpur',
      'Chaklāsi',
      'Chākia',
      'Chakia',
      'Chākan',
      'Chail',
      'Chāībāsa',
      'Chābua',
      'Cavelossim',
      'Carapur',
      'Canning',
      'Kannur',
      'Candolim',
      'Kolkata',
      'Byādgi',
      'Buxar',
      'Burla',
      'Būriya',
      'Burhar',
      'Burhānpur',
      'Būndu',
      'Būndi',
      'Buldāna',
      'Bulandshahr',
      'Buguda',
      'Budhlāda',
      'Budhāna',
      'Budaun',
      'Brājarājnagar',
      'Brahmapur',
      'Botād',
      'Borsad',
      'Borivli',
      'Bongaigaon',
      'Bomdila',
      'Mumbai',
      'Bolpur',
      'Bolānīkhodān',
      'Bokāro',
      'Bokākhāt',
      'Bokajān',
      'Boisar',
      'Bodri',
      'Bodināyakkanūr',
      'Buddh Gaya',
      'Bodhan',
      'Bobbili',
      'Bithūr',
      'Biswān',
      'Bissāu',
      'Bishnupur',
      'Bishnāh',
      'Bisenda Buzurg',
      'Bisauli',
      'Bīsalpur',
      'Birūr',
      'Bīrpur',
      'Birmitrapur',
      'Birbhaddar',
      'Beed',
      'Binka',
      'Bindki',
      'Etāwa',
      'Bilthra',
      'Bilsi',
      'Bilsanda',
      'Biloli',
      'Bilkha',
      'Bilimora',
      'Bilhaur',
      'Bilgrām',
      'Bilgi',
      'Bilaspur',
      'Bilāspur',
      'Bīlāspur',
      'Bilāsipāra',
      'Bilariāganj',
      'Bilāri',
      'Bilāra',
      'Bikramganj',
      'Bīkāpur',
      'Bīkaner',
      'Bijrauni',
      'Bijnor',
      'Bijni',
      'Bijbehara',
      'Bijāwar',
      'Bijapur',
      'Bihpuriāgaon',
      'Bihār Sharīf',
      'Bihārīganj',
      'Bīghāpur Khurd',
      'Bidhūna',
      'Bīdar',
      'Biaora',
      'Kīl Bhuvanagiri',
      'Bhusāval',
      'Bhuma',
      'Bhūm',
      'Bhuj',
      'Bhudgaon',
      'Bhubaneshwar',
      'Bhuban',
      'Bhowali',
      'Bhor',
      'Bhopāl',
      'Bhongīr',
      'Bhongaon',
      'Bhulath Gharbi',
      'Bhojudih',
      'Bhogpur',
      'Bhiwāni',
      'Bhiwandi',
      'Bhitarwār',
      'Bhīnmāl',
      'Bhinga',
      'Bhindār',
      'Bhind',
      'Bhīmunipatnam',
      'Bhīm Tāl',
      'Bhīmavaram',
      'Bhīlwāra',
      'Bhilai',
      'Bhīkhi',
      'Bhikangaon',
      'Bhigvan',
      'Bhāyāvadar',
      'Bhayandar',
      'Bhawānipatna',
      'Bhawānīgarh',
      'Bhawāniganj',
      'Bhavnagar',
      'Bhavāni',
      'Bhattiprolu',
      'Bhātpāra',
      'Bhatkal',
      'Bathinda',
      'Bhatgaon',
      'Bhātāpāra',
      'Bhasāwar',
      'Bharwāri',
      'Bharūch',
      'Bharthana',
      'Bharatpur',
      'Bhānvad',
      'Bhānpurī',
      'Bhānpura',
      'Bhanjanagar',
      'Bhānder',
      'Bhandāra',
      'Bhālki',
      'Bhaisa',
      'Bhainsdehi',
      'Bhagwantnagar',
      'Bhāgalpur',
      'Bhadreswar',
      'Bhadrāvati',
      'Bhadrakh',
      'Bhadrāchalam',
      'Bhādra',
      'Bhadohi',
      'Bhadaur',
      'Bhādāsar',
      'Bhadarwāh',
      'Bhachāu',
      'Bhabhua',
      'Bhābhra',
      'Beypore',
      'Bewar',
      'Betūl Bazār',
      'Betūl',
      'Bettiah',
      'Betma',
      'Betamcherla',
      'Beswān',
      'Beri Khās',
      'Berasia',
      'Beohāri',
      'Benīganj',
      'Benaulim',
      'Bemetāra',
      'Belūr',
      'Beltangadi',
      'Belsand',
      'Belonia',
      'Bellūru',
      'Bellary',
      'Beliātor',
      'Belgaum',
      'Beldānga',
      'Belaguntha',
      'Bela',
      'Behror',
      'Behat',
      'Begusarai',
      'Begūn',
      'Begowāl',
      'Begamganj',
      'Bedi',
      'Beāwar',
      'Bāzpur',
      'Bayāna',
      'Bawāna',
      'Bāwal',
      'Baud',
      'Batoti',
      'Batāla',
      'Baswa',
      'Bāsugaon',
      'Bāsudebpur',
      'Bastī',
      'Bāsoda',
      'Basni',
      'Basna',
      'Basmat',
      'Basi',
      'Basavana Bāgevādi',
      'Basavakalyān',
      'Bāsār',
      'Barwāni',
      'Barwāla',
      'Barwādih',
      'Bāruni',
      'Baruipur',
      'Bārsi',
      'Barsāna',
      'Barpeta',
      'Barpathār',
      'Barpāli',
      'Barnāla',
      'Bārmer',
      'Barkot',
      'Barki Saria',
      'Barkhera Kalān',
      'Barkā Kānā',
      'Barjora',
      'Barjala',
      'Bari Sādri',
      'Bariārpur',
      'Bāri',
      'Barhiya',
      'patamda',
      'Barhi',
      'Bārh',
      'Bargi',
      'Barghāt',
      'Bargarh',
      'Barela',
      'Bareilly',
      'Bārdoli',
      'Barddhamān',
      'Bar Bigha',
      'Baraut',
      'Barauli',
      'Bārāsat',
      'Baranagar',
      'Bārān',
      'Bāramūla',
      'Bārāmati',
      'Bārākpur',
      'Barāgaon',
      'Bara Bazar',
      'Bāpatla',
      'Banūr',
      'Bantvāl',
      'Bāntva',
      'Bānswāra',
      'Bānswāda',
      'Bānsi',
      'Bānsgāon',
      'Bānsdīh',
      'Bānsbāria',
      'Bānposh',
      'Bannūr',
      'Banmankhi',
      'Bānkura',
      'Bānki',
      'Bānka',
      'Banjār',
      'Banihāl',
      'Bāngarmau',
      'Bangarapet',
      'Bangaon',
      'Banganapalle',
      'Bengaluru',
      'Banga',
      'Bandora',
      'Bandipura',
      'Bāndīkūi',
      'Bānda',
      'Banda',
      'Bānāvar',
      'Banat',
      'Bānapur',
      'Bāmor Kalān',
      'Bamora',
      'Bamna',
      'Bamboo Flat',
      'Bambolim',
      'Bālurghāt',
      'Bālugaon',
      'Balrāmpur',
      'Bālotra',
      'Baloda Bāzār',
      'Baloda',
      'Balod',
      'Bāli',
      'Ballālpur',
      'Balimila',
      'Bāli Chak',
      'Balasore',
      'Baldeogarh',
      'Baldev',
      'Balarāmpur',
      'Bālāpur',
      'Balāngīr',
      'Bālāghāt',
      'Bālāchor',
      'Bakshwāho',
      'Bakreswar',
      'Bakloh',
      'Bakhtiyārpur',
      'Bakewar',
      'Bakāni',
      'Bājna',
      'Budge Budge',
      'Baisi',
      'Bairāgnia',
      'Byndoor',
      'Bail-Hongal',
      'Baikunthpur',
      'Baihar',
      'Baidyabāti',
      'Bahua',
      'Bahsūma',
      'Bahraigh',
      'Bahjoi',
      'Baheri',
      'Baharampur',
      'Bahādurgarh',
      'Bahādurganj',
      'Bāh',
      'Bagulā',
      'Bagra',
      'Bāgli',
      'Bāghpat',
      'Bagdogra',
      'Bāgha Purāna',
      'Bāg',
      'Bāgeshwar',
      'Bāgepalli',
      'Bagasra',
      'Bagar',
      'Bagalkot',
      'Bagaha',
      'Badvel',
      'Badūria',
      'Badnāwar',
      'Badlapur',
      'Badhni Kalān',
      'Badarwās',
      'Badarpur',
      'Bādāmi',
      'Badagara',
      'Bada Barabīl',
      'Bachhrāwān',
      'Bachhraon',
      'Babugarh',
      'Babrāla',
      'Bābra',
      'Babīna',
      'Baberu',
      'Bābai',
      'Azamgarh',
      'Ayyampettāi',
      'Ajodhya',
      'Ayakudi',
      'Awantipur',
      'Avanigadda',
      'Avinashi',
      'Āvadi',
      'Ausa',
      'Aurās',
      'Aurangābād',
      'Aurangabad',
      'Auraiya',
      'Aurād',
      'Attur',
      'Attingal',
      'Attili',
      'Āttayyāmpatti',
      'Atraulia',
      'Atraulī',
      'Atmakūr',
      'Adirampattinam',
      'Athni',
      'Āthagarh',
      'Ateli Mandi',
      'Atarra',
      'Āsind',
      'Āsika',
      'Asifābād',
      'Ashti',
      'Ashta',
      'Ashoknagar',
      'Asarganj',
      'Āsansol',
      'Āsandh',
      'Ārvi',
      'Aruppukkottai',
      'Arumuganeri',
      'Arumbāvūr',
      'Arukutti',
      'Arsikere',
      'Āron',
      'Norīa',
      'Arki',
      'Arkalgūd',
      'Ariyalūr',
      'Arimalam',
      'Arcot',
      'Aruvankad',
      'Arāria',
      'Arantāngi',
      'Arni',
      'Ārangaon',
      'Arang',
      'Arambol',
      'Arāmbāgh',
      'Arakkonam',
      'Arrah',
      'Aonla',
      'Anūpshahr',
      'Anūppur',
      'Anūpgarh',
      'Antu',
      'Antri',
      'Anta',
      'Anshing',
      'Annur',
      'Annigeri',
      'Annavāsal',
      'Annāmalainagar',
      'Ankola',
      'Ankleshwar',
      'Anjār',
      'Anjangaon',
      'Anjad',
      'Angul',
      'Angamāli',
      'Anekal',
      'Andol',
      'Anthiyur',
      'Āndippatti',
      'Anantnag',
      'Anantapur',
      'Anandpur',
      'Anandnagar',
      'Anand',
      'Anakāpalle',
      'Anamalais',
      'Amudālavalasa',
      'Āmta',
      'Amroli',
      'Amroha',
      'Amritsar',
      'Amreli',
      'Amrāvati',
      'Amod',
      'Ammāpettai',
      'Amloh',
      'Āmli',
      'Āmlāgora',
      'Amla',
      'Amguri',
      'Amethi',
      'Amethī',
      'Amet',
      'Ambur',
      'Ambikāpur',
      'Ambattūr',
      'Ambasamudram',
      'Āmbāsa',
      'Ambāla',
      'Ambājogāi',
      'Ambahta',
      'Ambāh',
      'Ambāgarh Chauki',
      'Ambad',
      'Amarwāra',
      'Amarpur',
      'Amarpātan',
      'Amarnāth',
      'Amarkantak',
      'Amānpur',
      'Amānganj',
      'Amalner',
      'Amalāpuram',
      'Alwaye',
      'Alwa Tirunagari',
      'Alwar',
      'Alūr',
      'Alot',
      'Along',
      'Alnāvar',
      'Almora',
      'Alappuzha',
      'Allāpalli',
      'Allāhganj',
      'Allahābād',
      'Alīpur Duār',
      'Alīpur',
      'Alīgarh',
      'Alīganj',
      'Alībāg',
      'Aldona',
      'Alāwalpur',
      'Ālappākkam',
      'Ālangulam',
      'Ālangudi',
      'Alangāyam',
      'Alangānallūr',
      'Alandur',
      'Alandi',
      'Aland',
      'Alampur',
      'Ālampur',
      'Alagāpuram',
      'Akot',
      'Akola',
      'Akodia',
      'Aklera',
      'Akivīdu',
      'Akhnūr',
      'Akbarpur',
      'Akaltara',
      'Akalkot',
      'Akālgarh',
      'Ajra',
      'Ajnāla',
      'Ajmer',
      'Ajjampur',
      'Ajaigarh',
      'Aizawl',
      'Ahwa',
      'Ahraura',
      'Ahmadpur',
      'Ahmadnagar',
      'Ahmedabad',
      'Ahiri',
      'Agra',
      'Agartala',
      'Agar',
      'Afzalpur',
      'Afzalgarh',
      'Aduthurai',
      'Adūr',
      'Adra',
      'Ādoni',
      'Ādilābād',
      'Addanki',
      'Adalaj',
      'Achhnera',
      'Achalpur',
      'Ābu Road',
      'Ābu',
      'Abohar',
      'Abirāmam',
      'Abhayāpuri',
      'Contai',
      'Haldia',
      'Srirāmpur',
      'Haripur',
      'Kesabpur',
      'Rāmchandrapur',
      'Bāgnān',
      'Sankarpur',
      'Begampur',
      'Parbatipur',
      'Bhandārdaha',
      'Dumjor',
      'Krishnapur',
      'Bankra',
      'Chakapara',
      'Mahiari',
      'Masila',
      'Dhulagari',
      'Pānchla',
      'Sāhāpur',
      'Raghudebbati',
      'Gobindapur',
      'Pujali',
      'Maheshtala',
      'Monoharpur',
      'Solap',
      'Gurdaha',
      'Ula',
      'Soyībug',
      'Jauriān',
      'Chīma',
      'Kheri Sāmpla',
      'Gho Brāhmanān de',
      'Chhutmalpur',
      'Borkhera',
      'Singāpur',
      'Ghatkesar',
      'Chandūr',
      'Vadigenhalli',
      'Adampur',
      'Chinnasekkadu',
      'Porur',
      'Manappakkam',
      'Madipakkam',
      'Perungudi',
      'Vengavasal',
      'Nandambakkam',
      'Madambakkam',
      'Vellānūr',
      'Kupwāra',
      'Powai',
      'Navi Mumbai',
      'Auroville',
      'Puttaparthi',
      'Panchkula',
      'Canacona',
      'Abhaneri',
      'Chowari',
      'Murudeshwara',
      'Shivaji Nagar',
      'Greater Noida',
      'Mohali',
      'Banbasa',
      'vadlamuru',
      'Pithampur',
      'Barbil',
      'Airoli',
      'Aluva',
      'Kotkapura',
      'Muvattupuzha',
      'Perumbavoor',
      'Vapi',
      'Baddi',
      'Noida',
      'Bhiwadi',
      'Parwanoo',
      'Jorethang',
      'Mandideep',
      'Singrauli',
      'Edakkulam',
      'Avanoor',
      'Baga',
      'Birpara',
      'Jaigaon',
      'Palackattumala',
      'Periyapattinam',
      'Pitampura',
      'Vagator',
      'Akkarampalle',
      'Bellampalli',
      'Chemmumiahpet',
      'Gaddi Annaram',
      'Dasnapur',
      'Kanuru',
      'Lal Bahadur Nagar',
      'Malkajgiri',
      'Mandamarri',
      'Chinnachowk',
      'Kyathampalle',
      'Gajuwaka',
      'Manuguru',
      'Kalyandurg',
      'Ponnur',
      'Quthbullapur',
      'Ramanayyapeta',
      'Palwancha',
      'Barpeta Road',
      'Sathupalli',
      'Yanamalakuduru',
      'Morigaon',
      'Naharlagun',
      'Serilingampalle',
      'Silapathar',
      'Sarupathar',
      'Lumding Railway Colony',
      'Aistala',
      'Ashoknagar Kalyangarh',
      'Bahula',
      'Pavuluru',
      'pedda nakkalapalem',
      'Jhulasan',
      'Bhawanipur',
      'Zira',
      'Williamnagar',
      'Nangilickondan',
      'Artist Village',
      'Kumarapalayam',
      'Ramagundam',
      'Govindapuram',
      'Cherpulassery',
      'Biswanath Chariali',
      'Kagaznāgār',
      'Kirandul',
      'Dayapar',
      'Paikpara',
      'Thoothukudi',
      'Kalimpong, Крукети',
      'Shiraguppi',
      'Gadag-Betageri',
      'V.S.K.Valasai (Dindigul-Dist.)',
      'Gangadharpur',
      'Neemuch',
      'Dasarahalli',
      'Neelankarai',
      'Injambakkam',
      'Palavakkam',
      'Mushalpur',
      'Shahbazpur',
      'Bhagirathpur',
      'Inda Chhoi',
      'Defence Colony',
      'Anjani Khurd',
      'Kumbalam',
      'Aroor',
      'Kadakkavoor',
      'Kalavoor',
      'Kalamassery',
      'Akasahebpet',
      'Cherthala',
      'Kunnumma',
      'Azhiyūr',
      'Abrama',
      'Phusro',
      'Hawai',
      'Alangad',
      'Longleng',
      'Savarkundla',
      'Resubelpara',
      'Namsai',
      'Hatsingimari',
      'Kalpeni',
      'Malkapur',
      'Minicoy',
      'Nagarukhra City',
      'Garha Brahman',
      'Sector',
      'Jiribam',
      'Kamjong',
      'Pherzawl',
      'Chandel',
      'Tengnoupal',
      'Garamur',
      'Bishramganj',
      'Bhupalpally',
      'Ampati',
      'Khliehriat',
      'Shahdara',
      'Balrampur',
      'Hamren',
      'Sonari',
      'Pangin',
      'Rohini',
      'Harina Pashdal Bar',
      'Shi Yomi',
      'Pakke Kessang',
      'Mulugu',
      'Kallakurichi',
      'Hnahthial',
      'Madhavkampa',
      'Koonimedu',
      'S. Rayavaram',
      'Reddivaripalle',
      'Seethanagaram',
      'Pedda bhogili',
      'Amaravati',
      'Anjani Budruk',
      'Andrott',
      'Amini',
      'Agatti',
      'Kadmat',
      'Kiltan',
      'Chetlat',
      'Wadgaon Tejan',
      'Walhur',
      'Koyali',
      'Pimpalkhuta',
      'Ajani Khurd',
      'Naigaon Dattapur',
      'Shivani Pisa',
      'Wadgaon Sarhad',
      'Amarāvati',
    ],
  },
  {
    country: 'Iraq',
    cities: [
      'Zāwītah',
      'Zaxo',
      'Umm Qaşr',
      'Ţūz Khūrmātū',
      'Tikrīt',
      'Dāqūq',
      'Tallkayf',
      'Tall ‘Afar',
      'Sūq ash Shuyūkh',
      'Saymayl',
      'Sīnah',
      '‘Ayn Tamr',
      'Ash Sharqāt',
      'Shaqlāwah',
      'Seyid Sadiq',
      'Sāmarrā’',
      'Nāḩīyat Saddat al Hindīyah',
      'Ṟuwandiz',
      'Rāwah',
      'Ṟaniye',
      'Al Qā’im',
      'Al-Hamdaniya',
      'Qeredagh',
      'Qal‘at Şāliḩ',
      'Qeładizê',
      'Mêrgasur',
      'Mawet',
      'Mandalī',
      'Mexmur',
      'Koysinceq',
      'Kifrī',
      'Khānaqīn',
      'Kirkuk',
      'Karbala',
      'Kānī Māsī',
      'Kelar',
      'Al ‘Abbāsī',
      'Erbil',
      'Hīt',
      'Hīrān',
      'Al Ḩaqlānīyah',
      'Ḩalabja',
      'Ḩadīthah',
      'Xebat',
      'Dukan',
      'Soran',
      'Dihok',
      'Dibis',
      'Derkar',
      'Chwarta',
      'Jamjamāl',
      'Al Jabāyish',
      'Bayjī',
      'Batifa',
      'Baqubah',
      'Baynjiwayn',
      'Bāmarnī',
      'Baladrūz',
      'Balad',
      'Beḧirke',
      'Baghdad',
      'Badrah',
      'Az Zubayr',
      'Ash Shaykhān',
      '‘Aynkāwah',
      'Aţ Ţārmīyah',
      'Aş Şuwayrah',
      'As Sulaymānīyah',
      'As Samawah',
      'As Salmān',
      'Nāḩiyat ash Shināfīyah',
      'Ash Shaţrah',
      'Ash Shāmīyah',
      'Ar Ruţbah',
      'Ar Rumaythah',
      'Ar Riyāḑ',
      'Ar Rifā‘ī',
      'Ramadi',
      '‘Aqrah',
      'An Nu‘mānīyah',
      'Nasiriyah',
      'Najaf',
      '‘Anat al Qadīmah',
      'Al Qurnah',
      'Al Qayyārah',
      'Imam Qasim',
      'Al Musayyib',
      'Al Mishkhāb',
      'Al Miqdādīyah',
      'Al Maymūnah',
      'Al Mawşil al Jadīdah',
      'Mosul',
      'Al Maḩmūdīyah',
      'Al Maḩāwīl',
      'Al Madīnah',
      'Al Kūt',
      'Kufa',
      'Al Khāliş',
      'Khāliş',
      'Al Kaḩlā’',
      '‘Alī al Gharbī',
      'Al Hindīyah',
      'Al Ḩillah',
      'Al Ḩayy',
      'Al Hāshimīyah',
      'Al Hārithah',
      'Al Ḩamzah',
      'Al Ḩaḑar',
      'Nahiyat Ghammas',
      'Nāḩiyat al Fuhūd',
      'Al Fāw',
      'Al Fallūjah',
      'Al Ba‘āj',
      'Basrah',
      'Al ‘Azīzīyah',
      'Al ‘Awjah',
      'Al ‘Amārah',
      'Al ‘Amādīyah',
      '‘Afak',
      'Ad Dujayl',
      'Ad Dīwānīyah',
      'Ad Dawr',
      'Al Manādhirah',
      'Abī al Khaşīb',
      'Abū Ghurayb',
      'Derbendîxan',
      'Al Başrah al Qadīmah',
      'Al Ḩawījah',
      'Qushtepe',
      'Dare Tû',
      'Kasnazān',
      'Hajiawa',
      'Choman',
      'Sinjār',
      'Halshaw',
      '‘Anah',
      'Ibrāhīm al Khalīl',
      'Nāḩiyat Hīrān',
      'Nāḩiyat Baḩār',
      'Nāḩiyat ‘Atbah',
      'Nāḩiyat Alī ash Sharqī',
      'Hayraw',
      'Zaxu',
      'Ḩalabjah al Jadīdah',
    ],
  },
  {
    country: 'Iran',
    cities: [
      'Ţāleqān',
      'Alvand',
      'Āzādshahr',
      'Nīr',
      'Qarah Āghāj',
      'Bostānābād',
      'Kahrīz',
      'Nūrābād',
      'Javānrūd',
      'Bozghān',
      'Kalāt-e Nāderī',
      'Īstgāh-e Rāh Āhan-e Garmsār',
      'Qarchak',
      'Shahrak-e Emām Ḩasan',
      'Shahre Jadide Andisheh',
      'Omīdcheh',
      'Āq Qāyeh',
      'Gomīshān',
      'Pā’īn-e Bāzār-e Rūdbār',
      'Khorramdarreh',
      'Pādegān-e Manjīl',
      'Kavār',
      'Yasuj',
      'Ziabar (Gaskar)',
      'Zarrīnābād',
      'Zarand',
      'Zanjān',
      'Yazd',
      'Vasīān',
      'Varazqān',
      'Varāmīn',
      'Tūyserkān',
      'Ţorqabeh',
      'Torbat-e Ḩeydarīyeh',
      'Tonekābon',
      'Tehran',
      'Hashtpar',
      'Ţāleb ābād',
      'Tākestān',
      'Takāb',
      'Tajrīsh',
      'Shahr-e Herāt',
      'Taft',
      'Tafresh',
      'Tabriz',
      'Tabas',
      'Sūrīān',
      'Sūrak',
      'Nūr',
      'Şowme‘eh Sarā',
      'Sorkheh',
      'Sonqor',
      'Solţānābād',
      'Sirjan',
      'Sīāhkal',
      'Shūshtar',
      'Shūsh',
      'Shīrvān',
      'Shiraz',
      'Sharīfābād',
      'Shalamzār',
      'Shahr-e Kord',
      'Shahr-e Bābak',
      'Shaft',
      'Shādegān',
      'Shabestar',
      'Seyah Cheshmeh',
      'Semnan',
      'Semīrom',
      'sedeyen-e Yek',
      'Sāveh',
      'Sarvestān',
      'Sarvābād',
      'Sari',
      'Sardasht',
      'Sarbīsheh',
      'Sarāyān',
      'Sarābleh',
      'Sarāb-e Dūreh',
      'Sarāb',
      'Saqqez',
      'Sanandaj',
      'Salmās',
      'Soleh Bon',
      'Rāmsar',
      'Shāhīn Dezh',
      'Şaḩneh',
      'Sabzevar',
      'Sa‘ādat Shahr',
      'Rūdsar',
      'Narmāshīr',
      'Roshtkhvār',
      'Robāţ Karīm',
      'Reẕvānshahr',
      'Razan',
      'Rāvar',
      'Ravānsar',
      'Rasht',
      'Rāmshīr',
      'Rāmīān',
      'Rāmhormoz',
      'Rafsanjān',
      'Rābor',
      'Qūchān',
      'Qorveh',
      'Qom',
      'Qohūrd-e ‘Olyā',
      'Qīr',
      'Qeshm',
      'Qazvin',
      'Qaşr-e Shīrīn',
      'Qarnābād',
      'Qarah Ẕīā’ od Dīn',
      'Shahr-e Qods',
      'Qal‘eh Ganj',
      'Rūdān',
      'Qal‘eh-ye Khvājeh',
      'Arakvāz-e Malekshāhī',
      'Farrokh Shahr',
      'Qā’en',
      'Sarpol-e Z̄ahāb',
      'Pol-e Sefīd',
      'Poldokhtar',
      'Poldasht',
      'Pīshvā',
      'Piranshahr',
      'Pāveh',
      'Pārsābād',
      'Oskū',
      'Oshnavīyeh',
      'Orūmīyeh',
      'Omīdīyeh',
      'Nowshahr',
      'Noşratābād',
      'Neyshābūr',
      'Neyrīz',
      'Neqāb',
      'Nekā',
      'Naz̧arābād',
      'Naţanz',
      'Nashtārūd',
      'Naqadeh',
      'Namīn',
      'Nā’īn',
      'Nahāvand',
      'Marāveh Tappeh',
      'Mollās̄ānī',
      'Mohr',
      'Moḩammadābād',
      'Mīnūdasht',
      'Mīnāb',
      'Mīāneh',
      'Mīāndoāb',
      'Meybod',
      'Meshgīn Shahr',
      'Mahrīz',
      'Mehrān',
      'Masjed Soleymān',
      'Maşīrī',
      'Bardsīr',
      'Mashhad',
      'Marvdasht',
      'Marīvān',
      'Marand',
      'Marāgheh',
      'Manūjān',
      'Manjīl',
      'Malekān',
      'Malāyer',
      'Malārd',
      'Maku',
      'Māhneshān',
      'Maḩmūdābād',
      'Maḩallāt',
      'Mahābād',
      'Lordegān',
      'Līkak',
      'Shahr-e Qadīm-e Lār',
      'Langarūd',
      'Lamerd',
      'Lālī',
      'Lāhījān',
      'Kūhdasht',
      'Kūhbanān',
      'Kord Kūy',
      'Komījān',
      'Kīsh',
      'Khowy',
      'Khowrmūj',
      'Khūr',
      'Khvānsār',
      'Khorramshahr',
      'Khorramabad',
      'Khonj',
      'Khondāb',
      'Khomeyn',
      'Khomārlū',
      'Kherāmeh',
      'Bandar-e Khamīr',
      'Khalkhāl',
      'Khalīlābād',
      'Kermanshah',
      'Kerman',
      'Kerend-e Gharb',
      'Kāzerūn',
      'Kāshmar',
      'Karaj',
      'Kangāvar',
      'Kangān',
      'Kāmyārān',
      'Kaleybar',
      'Kalāleh',
      'Kujuvar',
      'Kabūdarāhang',
      'Eyvān',
      'Jūybār',
      'Joghtāy',
      'Khārk',
      'Jask',
      'Jongīyeh',
      'Jam',
      'Jājarm',
      'Jahrom',
      'Īlām',
      'Hoveyzeh',
      'Herīs',
      'Hendījān',
      'Hashtgerd',
      'Harsīn',
      'Hamadān',
      'Ḩājjīābād',
      'Haftkel',
      'Gorgān',
      'Gonbad-e Kāvūs',
      'Gonābād',
      'Golpāyegān',
      'Gīlān-e Gharb',
      'Gerāsh',
      'Gāvbandī',
      'Garmsār',
      'Germī',
      'Garmeh',
      'Bandar-e Genāveh',
      'Galūgāh',
      'Gālīkesh',
      'Fūman',
      'Fīrūzkūh',
      'Fīrūzābād',
      'Feyẕābād',
      'Fereydūnshahr',
      'Fereydūn Kenār',
      'Ferdows',
      'Fasā',
      'Fāryāb',
      'Fārūj',
      'Fārsān',
      'Farāshband',
      'Farmahīn',
      'Farīmān',
      'Fannūj',
      'pamas',
      'Fāmenīn',
      'Fahraj',
      'Estahbān',
      'Eslāmābād-e Gharb',
      'Eshtehārd',
      'Esfarāyen',
      'Eqlīd',
      'Shahrud',
      'Dowlatābād',
      'Dorūd',
      'Dogonbadan',
      'Dīvāndarreh',
      'Dezful',
      'Deyr',
      'Delījān',
      'Dehlorān',
      'Dehgolān',
      'Dehdasht',
      'Sīsakht',
      'Şafāshahr',
      'Dehāqān',
      'Sūsangerd',
      'Darreh Shahr',
      'Dargaz',
      'Dārān',
      'Dārāb',
      'Dāmghān',
      'Damāvand',
      'Chenārān',
      'Chelgard',
      'Chālūs',
      'Chādegān',
      'Bushehr',
      'Būkān',
      'Bū’īn Zahrā',
      'Borūjerd',
      'Borūjen',
      'Borāzjān',
      'Shahrak-e Kūlūrī',
      'Bojnūrd',
      'Bīrjand',
      'Bīleh Savār',
      'Bījār',
      'Bonāb',
      'Behshahr',
      'Behbahān',
      'Māsāl',
      'Bastak',
      'Bāsht',
      'Bardaskan',
      'Bāneh',
      'Bandar-e Torkaman',
      'Bandar-e Māhshahr',
      'Bandar-e Lengeh',
      'Bandar-e Gaz',
      'Bandar-e Deylam',
      'Bandar-e Anzalī',
      'Bandar Abbas',
      'Bam',
      'Bajestān',
      'Bahār',
      'Bāgh-e Malek',
      'Bāft',
      'Bāfq',
      'Bābolsar',
      'Bābol',
      'Aznā',
      'Hashtrūd',
      'Shāzand',
      'Āstārā',
      'Āstāneh-ye Ashrafīyeh',
      'Āshtīān',
      'Āshkhāneh',
      'Bandar-e ‘Asalūyeh',
      'Asadīyeh',
      'Asadābād',
      'Arzū’īyeh',
      'Arsanjān',
      'Ardestān',
      'Ardal',
      'Ardakān',
      'Ardabīl',
      'Ārān Bīdgol',
      'Arāk',
      'Ārādān',
      'Anūch',
      'Andīmeshk',
      '‘Anbarābād',
      'Anār',
      'Āmol',
      'Amlash',
      'Shahrīār',
      'Alīgūdarz',
      'Aliabad-e Katul',
      'Alavijeh',
      'Aleshtar',
      'Akbarābād',
      'Ajab Shīr',
      'Ahvaz',
      'Ahram',
      'Ahar',
      'Aghajari',
      'Ābyek',
      'Abhar',
      'Ābdānān',
      "'Abās Ābād",
      'Ābbar',
      'Abarkūh',
      'Abadeh',
      'Abadan',
      'Kūh Sefīd',
      'Showţ',
      'Pāsārgād',
      'Shahrak-e Pābedānā',
      'Sepīdān',
      'Eqbālīyeh',
      'Shāhīn Shahr',
      'Zarrīn Shahr',
      'Tīrān',
      'Rehnān',
      'Shahreẕā',
      'Qahderījān',
      'Najafābād',
      'Mobārakeh',
      'Khomeynī Shahr',
      'Kelīshād va Sūdarjān',
      'Falāvarjān',
      'Isfahan',
      'Dorcheh Pīāz',
      'Abrīsham',
      'Eslāmshahr',
      'Shahrak-e Bākharz',
      'Zābol',
      'Zahedan',
      'Zehak',
      'Zābolī',
      'Torbat-e Jām',
      'Tāybād',
      'Sūrān',
      'Sarakhs',
      'Rāsak',
      'Qaşr-e Qand',
      'Nīkshahr',
      'Nehbandān',
      'Konārak',
      'Khvāf',
      'Khāsh',
      'Iranshahr',
      'Dūst Moḩammad Khān',
      'Chabahar',
      'Māmūnīyeh',
      'Āz̄arshahr',
      'Tāzehābād',
      'Kāshān',
      'Jīroft',
      'Eslāmābād',
      'Famast',
      'Kahnūj',
      'Mahdishahr',
      'Dalgān',
      'Āq Qalā',
      'Bandar-e Emam Khomeyni',
      'Qā’em Shahr',
      'Pākdasht',
      'Boshrūyeh',
      'Gīvī',
      'Rey',
      'Bahābād',
      'Pasragad Branch',
    ],
  },
  {
    country: 'Iceland',
    cities: [
      'Siglufjörður',
      'Sauðárkrókur',
      'Neskaupstaður',
      'Norðurþing',
      'Höfn',
      'Eskifjörður',
      'Egilsstaðir',
      'Dalvík',
      'Reyðarfjörður',
      'Akureyri',
      'Vogar',
      'Vestmannaeyjar',
      'Þorlákshöfn',
      'Stykkishólmur',
      'Selfoss',
      'Sandgerði',
      'Reykjavík',
      'Ólafsvík',
      'Seltjarnarnes',
      'Mosfellsbær',
      'Kópavogur',
      'Keflavík',
      'Ísafjörður',
      'Hvolsvöllur',
      'Hveragerði',
      'Hafnarfjörður',
      'Grindavík',
      'Garður',
      'Garðabær',
      'Álftanes',
      'Borgarnes',
      'Akranes',
      'Laugar',
      'Reykjanesbær',
    ],
  },
  {
    country: 'Italy',
    cities: [
      'Zumpano',
      'Zerfaliu',
      'Zeddiani',
      'Zambrone',
      'Zagarise',
      'Zafferana Etnea',
      'Zaccanopoli',
      'Vizzini',
      'Vittoria',
      'Vita',
      'Villaurbana',
      'Villaspeciosa',
      'Villasor',
      'Villasmundo',
      'Villasimius',
      'Villa San Giovanni',
      'Villasalto',
      'Villarosa',
      'Villaputzu',
      'Villapiana',
      'Villanova Truschedu',
      'Villanovafranca',
      'Villanovaforru',
      'Villamassargia',
      'Villamar',
      'Villalba',
      'Villagrande Strisaili',
      'Villafrati',
      'Villafranca Tirrena',
      'Villafranca Sicula',
      'Villacidro',
      'Villabate',
      'Viggianello',
      'Vicari',
      'Vibo Valentia',
      'Viagrande',
      'Verzino',
      'Verbicaro',
      'Ventimiglia di Sicilia',
      'Venetico Superiore',
      'Vazzano',
      'Varapodio',
      'Vallermosa',
      'Vallelunga Pratameno',
      'Vallelonga',
      'Vallefiorita',
      'Valledolmo',
      'Valguarnera Caropepe',
      'Valdina',
      'Vaccarizzo Albanese',
      'Uta',
      'Ustica',
      'Ussassai',
      'Ussaramanna',
      'Ussana',
      'Usellus',
      'Uras',
      'Umbriatico',
      'San Ciro-Ulmi-Filci',
      'Ulassai',
      'Ugento',
      'Ucria',
      'Tusa',
      'Turri',
      'Tuili',
      'Tropea',
      'Troina / Draginai',
      'Tripi',
      'Tricase',
      'Trenta',
      'Trecastagni',
      'Trebisacce',
      'Tratalias',
      'Trapani',
      'Tramatza',
      'Trabia',
      'Tortorici',
      'Tortora',
      'Tortolì',
      'Torretta',
      'Campora San Giovanni',
      'Torrenova',
      'Torre Melissa',
      'Torregrotta',
      'Torre di Ruggiero',
      'Torano Castello',
      'Tiriolo',
      'Tiggiano',
      'Teulada',
      'Tertenia',
      'Terravecchia',
      'Terrasini',
      'Terranova Sappo Minulio',
      'Terranova da Sibari',
      'Terranova di Pollino',
      'Terralba',
      'Termini Imerese',
      'Taviano',
      'Taverna',
      'Taurisano',
      'Taurianova',
      'Tarsia',
      'Taormina',
      'Sutera',
      'Surdo',
      'Suelli',
      'Strongoli',
      'Stilo',
      'Stignano',
      'Staletti',
      'Staiti',
      'Squillace',
      'Spilinga',
      'Spezzano Piccolo',
      'Spezzano della Sila',
      'Spezzano Albanese',
      'Sperlinga',
      'Specchia',
      "Sparta'",
      'Spadafora',
      'Soveria Simeri',
      'Soveria Mannelli',
      'Soverato Superiore',
      'Soverato Marina',
      'Sortino',
      'Soriano Calabro',
      'Sorianello',
      'Sorbo San Basile',
      'Sommatino',
      'Soleminis',
      'Solarussa',
      'Solarino',
      'Solanas',
      'Siris',
      'Siracusa',
      'Sinopoli',
      'Sinnai',
      'Sini',
      'Sinagra',
      'Simbario',
      'Simaxis',
      'Simala',
      'Silius',
      'Siliqua',
      'Silì',
      'Siderno',
      'Siddi',
      'Siculiana',
      'Sibari',
      'Siapiccia',
      'Siano',
      'Siamanna',
      'Siamaggiore',
      'Sferracavallo',
      'Seulo',
      'Seui',
      'Setzu',
      'Settimo San Pietro',
      'Sestu',
      'Sersale',
      'Serri',
      'Serrenti',
      'Serrata',
      'Serrastretta',
      'Serra San Bruno',
      'Serra Pedace',
      'Serramanna',
      'Serradifalco',
      "Serra d'Aiello",
      'Serdiana',
      'Senorbì',
      'Senis',
      'Seminara',
      'Sellia',
      'Selegas',
      'Selargius',
      'Segariu',
      'Scordia',
      'Scoglitti',
      'Sclafani Bagni',
      'Scillato',
      'Scilla',
      'Scido',
      'Scicli',
      'Sciara',
      'Sciacca',
      'Scarcelli',
      'Scandale',
      'Scaletta Zanclea',
      'Scalea',
      'Scala Coeli',
      'Savelli',
      'Satriano',
      'Sartano',
      'Sarroch',
      'Sardara',
      'Saracena',
      'Saponara',
      'San Vito Sullo Ionio',
      'San Vito Lo Capo',
      'San Vito',
      'San Vincenzo la Costa',
      'Santo Stefano Quisquina',
      'Santo Stefano in Aspromonte',
      'Santo Stefano di Rogliano',
      'Santo Stefano di Camastra',
      'San Sperate',
      "Sant'Onofrio",
      "Sant'Ilario dello Ionio",
      'Santa Eufemia Lamezia',
      "Sant'Eufemia d'Aspromonte",
      'San Teodoro',
      "Sant'Elia",
      'Santa Venerina',
      'Santa Teresa di Riva',
      "Santa Sofia d'Epiro",
      'Santa Severina',
      "Sant'Antioco",
      "Sant'Anna Arresi",
      'Santa Ninfa',
      "Sant'Angelo Muxaro",
      "Sant'Angelo di Brolo",
      'Gerocarne',
      "Sant'Andrea Frius",
      "Sant'Andrea Apostolo dello Ionio",
      'Santa Marina Salina',
      'Santa Maria di Licodia',
      'Santa Margherita di Belice',
      'Santa Lucia del Mela',
      "Sant'Alfio",
      "Sant'Alessio Siculo",
      "Sant'Alessio in Aspromonte",
      'Santa Giusta',
      "Sant'Agata di Militello",
      "Sant'Agata di Esaro",
      'Santa Flavia',
      'Santa Elisabetta',
      'Santa Domenica Vittoria',
      'Santa Domenica Talao',
      'Santa Domenica',
      'Santadi',
      'Santa Croce Camerina',
      'Santa Cristina Gela',
      "Santa Cristina d'Aspromonte",
      'Santa Caterina Villarmosa',
      'Santa Caterina dello Ionio',
      'Santa Caterina Albanese',
      'San Sosti',
      'San Sostene',
      'San Salvatore di Fitalia',
      'San Roberto',
      'San Procopio',
      'Villa San Pietro',
      'San Pietro in Guarano',
      'San Pietro in Amantea',
      'San Pietro Apostolo',
      'San Pietro a Maida',
      'San Piero Patti',
      'San Pier Niceto',
      "San Nicolo'Gerrei",
      "San Nicolò d'Arcidano",
      'San Nicolò',
      "San Nicola dell'Alto",
      'San Nicola da Crissa',
      'San Nicola Arcella',
      'San Michele di Ganzaria',
      'San Mauro Marchesato',
      'San Mauro Castelverde',
      'San Martino di Finita',
      'San Martino',
      "San Marco d'Alunzio",
      'San Marco Argentano',
      "San Mango d'Aquino",
      'Sanluri',
      'San Lucido',
      'San Luca',
      'San Lorenzo del Vallo',
      'San Lorenzo Bellizzi',
      'San Lorenzo',
      'San Leone Mosè',
      "San Gregorio d'Ippona",
      'San Gregorio di Catania',
      'San Giuseppe Jato',
      'San Giovanni Suergiu',
      'Samo',
      'San Giovanni la Punta',
      'San Giovanni in Fiore',
      'San Giovanni Gemini',
      'San Giovanni di Gerace',
      'San Giorgio Morgeto',
      'San Giorgio Albanese',
      'San Giorgio',
      'Sangineto',
      'San Gavino Monreale',
      'San Fratello',
      'San Floro',
      'San Filippo del Mela',
      'San Fili',
      'San Ferdinando',
      'San Donato di Ninea',
      'San Demetrio Corone',
      'San Costantino Calabro',
      'San Cosmo Albanese',
      'San Cono',
      'San Cipirello',
      'San Cataldo',
      'San Carlo-Condofuri Marina',
      'San Calogero',
      'San Biagio Platani',
      'San Benedetto Ullano',
      'San Basilio',
      'San Basile',
      'Samugheo',
      'Sambuca di Sicilia',
      'Sambiase',
      'Samatzai',
      'Samassi',
      'Salve',
      'Saline Ioniche',
      'Salemi',
      'Salaparuta',
      'Sadali',
      'Ruinas',
      'Ruffano',
      'Rovito',
      'Rotonda',
      'Rota Greca',
      'Rossano Stazione',
      'Rossano',
      'Rosolini',
      'Roseto Capo Spulico',
      'Rose',
      'Rosarno',
      'Rombiolo',
      'Rogliano',
      'Roggiano Gravina',
      'Rodì',
      'Roccella Valdemone',
      'Roccella Ionica',
      'Roccavaldina',
      'Roccapalumba',
      'Roccamena',
      'Roccalumera',
      'Roccaforte del Greco',
      'Rocca di Neto',
      'Roccabernarda',
      'Rizziconi',
      'Riposto',
      'Riola Sardo',
      'Riesi',
      'Ricadi',
      'Ribera',
      'Riace Marina',
      'Resuttano',
      'Rende',
      'Reitano',
      'Reggio Calabria',
      'Regalbuto',
      'Realmonte',
      'Ravanusa',
      'Randazzo',
      'Ramacca',
      'Ragusa',
      'Raffadali',
      'Raddusa',
      'Raccuja',
      'Racalmuto',
      'Racale',
      'Quattromiglia',
      "Quartu Sant'Elena",
      'Quartucciu',
      'Pula',
      'Prizzi',
      'Priolo Gargallo',
      'Presicce',
      'Praia a Mare',
      'Pozzallo',
      'Portoscuso',
      'Portopalo di Capo Passero',
      'Porto Empedocle',
      'Portigliola',
      'Portella di Mare',
      'Pompu',
      'Pollina',
      'Polizzi Generosa',
      'Polistena',
      'Polia',
      'Nuovo Centro Urbano Poggioreale',
      'Platì',
      'Platania',
      'Plataci',
      'Placanica',
      'Pizzoni',
      'Pizzo',
      'Piscopio',
      'Piscinas',
      'Piraino',
      'Pioppo',
      'Pimentel',
      'Pietraperzia',
      'Pietrapaola',
      'Pietrafitta',
      'Piedimonte Etneo',
      'Piazza Armerina',
      'Pianopoli',
      'Piane Crati',
      'Piana degli Albanesi',
      'Pettineo',
      'Petrosino',
      'Petronà',
      'Petrizzi',
      'Petralia Sottana',
      'Petralia Soprana',
      'Petilia Policastro',
      'Pernocari-Presinaci',
      'Perdaxius',
      'Perdasdefogu',
      'Pentone',
      'Pellaro',
      'Pedivigliano',
      'Pedara',
      'Pedagaggi',
      'Pedace-Perito',
      'Pazzano',
      'Pavigliana',
      'Pauli Arbarei',
      'Pau',
      'Patù',
      'Patti',
      'Paterno Calabro',
      'Paternò',
      'Partinico',
      'Partanna',
      'Parghelia',
      'Parenti',
      'Paravati',
      'Papasidero',
      'Papanice',
      'Paola',
      'Pantelleria',
      'Pannaconi',
      'Panettieri',
      'Paludi',
      'Palmi',
      'Palmas Arborea',
      'Palma di Montechiaro',
      'Pallagorio',
      'Palizzi Marina',
      'Palizzi',
      'Palermo',
      'Palermiti',
      'Palazzolo Acreide',
      'Palazzo Adriano',
      'Palagonia',
      'Pagliarelle',
      'Pagliara',
      'Pachino',
      'Paceco',
      'Pabillonis',
      'Osini',
      'Ortacesus',
      'Orsomarso',
      'Orroli',
      'Oristano',
      'Albagiara',
      'Oliveri',
      'Olivadi',
      'Nuxis',
      'Nurri',
      'Nureci',
      'Nuraxinieddu',
      'Nuraminis',
      'Nurallao',
      'Nuragus',
      'Nurachi',
      'Nunziata',
      'Novara di Sicilia',
      'Noto',
      'Nocera Terinese',
      'Nizza di Sicilia',
      'Nissoria',
      'Niscemi',
      'Nicotera',
      'Nicosia',
      'Nicolosi',
      'Nicastro',
      'Naso',
      'Naro',
      'Nardodipace',
      'Narcao',
      'Mussomeli',
      'Musei',
      'Muravera',
      "Motta Sant'Anastasia",
      'Motta Santa Lucia',
      'Motta San Giovanni',
      'Mottafollone',
      'Mosorrofa',
      'Mormanno',
      'Morgongiori',
      'Morciano di Leuca',
      'Morano Calabro',
      'Montevago',
      'Montesardo',
      'Montesano Salentino',
      'Monterosso Almo',
      'Monterosso Calabro',
      'Montepaone',
      'Montemaggiore Belsito',
      'Montelepre',
      'Montedoro',
      'Montebello Jonico',
      'Montauro',
      'Montalto Uffugo',
      'Montallegro',
      'Montalbano Elicona',
      'Montagnareale',
      'Monserrato',
      'Monreale',
      'Mongrassano',
      'Mongiuffi Melia',
      'Mongiana',
      'Monforte San Giorgio',
      'Monastir',
      'Monasterace',
      'Molochio',
      'Moio Alcantara',
      'Mogoro',
      'Mogorella',
      'Modica',
      'Mistretta',
      'Misterbianco',
      'Misilmeri',
      'Mirto',
      'Mirabella Imbaccari',
      'Mineo',
      'Milo',
      'Militello Rosmarino',
      'Militello in Val di Catania',
      'Mileto',
      'Milena',
      'Milazzo',
      'Miglierina',
      'Miggiano',
      'Mezzojuso',
      'Messina',
      'Messignadi',
      'Mesoraca',
      'Merì',
      'Menfi',
      'Mendicino',
      'Melito di Porto Salvo',
      'Melissano',
      'Melissa',
      'Melilli',
      'Melicucco',
      'Melicuccà',
      'Meana Sardo',
      "Mazzarrà Sant'Andrea",
      'Mazzarino',
      'Mazara del Vallo',
      'Maracalagonis',
      'Masullas',
      'Mascalucia',
      'Mascali',
      'Masainas',
      'Marzi',
      'Martone',
      'Martirano Lombardo',
      'Martirano',
      'Marsala',
      'Marrubiu',
      'Marittima',
      'Marineo',
      'Marinella',
      'Marina di Schiavonea',
      'Marina di Ragusa',
      'Monasterace Marina',
      'Marina di Gioiosa Ionica',
      'Marina di Fuscaldo',
      'Marina di Camerota',
      'Marianopoli',
      'Marcellinara',
      'Marcedusa',
      'Marausa',
      'Maratea',
      'Marano Principato',
      'Marano Marchesato',
      'Mangone',
      'Mandatoriccio',
      'Mandas',
      'Mandanici',
      'Mammola',
      'Malvito',
      'Malvagna',
      'Malito',
      'Malfa',
      'Maletto',
      'Maierato',
      'Maierà',
      'Maida',
      'Magisano',
      'Luzzi',
      'Lungro',
      'Lunamatrona',
      'Lucugnano',
      'Lucca Sicula',
      'Lotzorai',
      'Longobucco',
      'Longobardi',
      'Longi',
      'Locri',
      'Loceri',
      'Lipari',
      'Linguaglossa',
      'Linera',
      'Limbadi-Caroni',
      'Licodia Eubea',
      'Licata',
      'Librizzi',
      'Leuca',
      'Letojanni',
      'Lercara Friddi',
      'Leonforte',
      'Lentini',
      'Leni',
      'Le Castella',
      'Lazzaro',
      'Laurignano',
      'Laureana di Borrello',
      'Lattarico',
      'Las Plassas',
      'Lascari',
      'Rocca di Capri Leone',
      'Larderia',
      'Lappano',
      'Lanusei',
      'Lampedusa',
      'La Maddalena',
      'Laino Castello-Nuovo Centro',
      'Laino Borgo',
      'Lago',
      'Laganadi',
      'Laconi',
      'Kamma',
      'Itala',
      'Ispica',
      'Isola delle Femmine',
      'Isola di Capo Rizzuto',
      'Isnello',
      'Isili',
      'Isca sullo Ionio',
      'Joppolo Giancaxio',
      'Ilbono',
      'Iglesias',
      'Jerzu',
      'Guspini',
      'Guasila',
      'Aprigliano',
      'Guardia Piemontese',
      'Guardavalle',
      'Guamaggiore',
      'Grotteria',
      'Grotte',
      'Grisolia',
      'Grimaldi',
      'Gratteri',
      'Grammichele',
      'Gonnostramatza',
      'Gonnosnò',
      'Gonnosfanadiga',
      'Gonnoscodina',
      'Gonnesa',
      'Goni',
      'Godrano',
      'Gizzeria',
      'Giuliana',
      'Girifalco',
      'Girasole',
      'Gioiosa Marea',
      'Gioiosa Ionica',
      'Gioia Tauro',
      'Gimigliano',
      'Giffone',
      'Nuova Gibellina',
      'Giba',
      'Giarre',
      'Giarratana',
      'Giardini-Naxos',
      'Giardinello',
      'Giardina Gallotti',
      'Gesturi',
      'Gesico',
      'Gergei',
      'Geraci Siculo',
      'Gerace',
      'Genuri',
      'Genoni',
      'Gemini',
      'Gela',
      'Gasperina',
      'Gangi',
      'Gallodoro',
      'Galatro',
      'Galati Mamertino',
      "Gairo Sant'Elena",
      'Gagliano del Capo',
      'Gagliano Castelferrato',
      'Gaggi',
      'Gadoni',
      'Fuscaldo',
      'Furtei',
      'Furci Siculo',
      'Frazzanò',
      'Frascineto',
      'Francofonte',
      'Francica',
      'Francavilla Marittima',
      'Francavilla di Sicilia',
      'Francavilla Angitola',
      'Fossato Serralta',
      'Fossato Ionico-Fossatello-San Luca Marcelluzzo',
      "Forza d'Agrò",
      'Fordongianus',
      'Fluminimaggiore',
      'Floridia',
      'Floresta',
      'Fiumefreddo Sicilia',
      'Fiumefreddo Bruzio',
      'Fiumedinisi',
      'Firmo',
      'Finale',
      'Filogaso',
      'Filadelfia',
      'Ficarra',
      'Ficarazzi',
      'Ferruzzano',
      'Feroleto della Chiesa',
      'Ferla',
      'Felline',
      'Favignana',
      'Favara',
      'Faro Superiore',
      'Falerna',
      'Falcone',
      'Falconara Albanese',
      'Fagnano Castello',
      'Fabrizia',
      'Esterzili',
      'Escolca',
      'Escalaplano',
      'Erice',
      'Enna',
      'Elmas',
      'Elini',
      'Drapia',
      'Doria',
      'Donorì',
      'Donnici Inferiore',
      'Donnalucata',
      'Donigala Fenugheddu',
      'Domusnovas',
      'Domus de Maria',
      'Domanico',
      'Dolianova',
      'Diso',
      'Dinami',
      'Diamante',
      'Depressa',
      'Delianuova',
      'Delia',
      'Decollatura',
      'Decimoputzu',
      'Decimomannu',
      'Davoli',
      'Dasà',
      'Cutro',
      'Custonaci',
      'Curinga',
      'Curcuris',
      'Crucoli',
      'Crotone',
      'Crosia',
      'Cropani',
      'Cropalati',
      'Crichi',
      'Cotronei',
      'Cosoleto',
      'Cosenza',
      'Cortale',
      'Corsano',
      'Corleone',
      'Corigliano Calabro',
      'Contessa Entellina',
      'Condrò',
      'Condofuri',
      'Comitini',
      'Comiso',
      'Colosimi',
      'Collinas',
      'Collesano',
      'Cleto',
      'Civita',
      'Cittanova',
      'Cittadella del Capo',
      'Cirò Marina',
      'Cirò',
      'Cinque Frondi',
      'Cinisi',
      'Ciminna',
      'Ciminà',
      'Cicala',
      'Ciavolo',
      'Cianciana',
      'Chiusa Sclafani',
      'Chiaravalle Centrale',
      'Chiaramonte Gulfi',
      'Cetraro',
      'Cessaniti',
      'Cesarò',
      'Cerzeto',
      'Cervicati',
      'Cerva',
      'Cerisano',
      'Cerenzia',
      'Cerda',
      'Cerchiara di Calabria',
      'Ceramida-Pellegrina',
      'Cerami',
      'Centuripe',
      'Centrache',
      'Cenadi',
      'Cellara',
      'Celico',
      'Cefalù',
      'Cefalà Diana',
      "Cava d'Aliga",
      'Caulonia',
      'Cattolica Eraclea',
      'Catenanuova',
      'Catanzaro',
      'Catania',
      'Castrovillari',
      'Castroregio',
      'Castroreale',
      'Castronuovo di Sicilia',
      'Castrolibero',
      'Castrofilippo',
      'Castrignano del Capo',
      'Castiglione',
      'Castiglione di Sicilia',
      'Castiglione Cosentino',
      'Castiadas',
      'Castelvetrano',
      'Casteltermini',
      'Castelmola',
      'Castelluzzo',
      "Castell'Umberto",
      'Castelluccio Inferiore',
      'Castellana Sicula',
      'Castellammare del Golfo',
      'Castel di Lucio',
      'Castel di Judica',
      'Casteldaccia',
      'Castelbuono',
      'Castanea delle Furie',
      'Cassibile',
      'Cassaro',
      'Cassano Allo Ionio',
      'Casole Bruzio',
      'Casignana',
      'Casarano',
      'Casalvecchio Siculo',
      'Casabona',
      'Carpanzano',
      'Marina di Caronia',
      'Caronia',
      'Carolei',
      'Carlopoli',
      'Carloforte',
      'Carlentini',
      'Carini',
      'Cariati',
      'Carfizzi',
      'Careri',
      'Cardinale',
      'Cardeto',
      'Carbonia',
      'Caraffa di Catanzaro',
      'Caraffa del Bianco',
      'Capri Leone',
      'Capoterra',
      'Capo Rizzuto',
      "Capo d'Orlando",
      'Dipignano',
      'Capizzi',
      'Capistrano',
      'Capaci',
      'Canolo',
      'Canneto',
      'Canicattini Bagni',
      'Canicattì',
      'Candidoni',
      'Camporeale',
      'Campofranco',
      'Campofiorito',
      'Campofelice di Roccella',
      'Campofelice di Fitalia',
      'Campobello di Mazara',
      'Campobello di Licata',
      'Campana',
      'Cammarata',
      'Camini',
      'Camastra',
      'Caltavuturo',
      'Caltanissetta',
      'Caltagirone',
      'Caltabellotta',
      'Caloveto',
      'Caloppezzati',
      'Calatafimi',
      'Calatabiano',
      'Calasetta',
      'Calascibetta',
      'Calanna',
      'Calamonaci',
      'Cagliari',
      'Caccuri',
      'Caccamo',
      'Cabras',
      'Butera',
      'Buscemi',
      'Burgio',
      'Burcei',
      'Buonvicino',
      'Buggerru',
      'Buccheri',
      'Bruzzano Zeffirio',
      'Brucoli',
      'Bronte',
      'Brolo',
      'Briatico',
      'Brancaleone',
      'Brancaleone-Marina',
      'Bova Marina',
      'Bovalino Superiore',
      'Bovalino',
      'Botricello',
      'Borgia',
      'Borgetto',
      'Bonifati',
      'Bompietro',
      'Bompensiere',
      'Bolognetta',
      'Bocchigliero',
      'Bivongi',
      'Bivona',
      'Bisignano',
      'Bisacquino',
      'Bianco',
      'Bianchi',
      'Biancavilla',
      'Benestare',
      'Belvì',
      'Belvedere',
      'Belvedere Marittimo',
      'Belvedere Spinello',
      'Belsito',
      'Belpasso',
      'Belmonte Mezzagno',
      'Belmonte Calabro',
      'Baucina',
      'Basicò',
      'Barumini',
      'Barrali',
      'Barrafranca',
      'Bari Sardo',
      'Baressa',
      'Barcellona Pozzo di Gotto',
      'Baratili San Pietro',
      'Baradili',
      'Villa Verde',
      'Ballao',
      'Balestrate',
      'Bagnara Calabra',
      'Bagheria',
      'Bagaladi',
      'Badolato Marina',
      'Badolato',
      'Bacu Abis',
      'Avola',
      'Augusta',
      'Atzara',
      'Asuni',
      'Assoro',
      'Assolo',
      'Assemini',
      'Arzana',
      'Armungia',
      'Aritzo',
      'Argusto',
      'Arena',
      'Ardore Marina',
      'Ardore',
      'Arbus',
      'Arborea',
      'Arbatax',
      'Aragona',
      'Antonimina',
      'Antillo',
      'Anoia Superiore',
      'Anoia Inferiore',
      'Andrano',
      'Andali',
      'Amica',
      'Amendolara',
      'Amato',
      'Amaroni',
      'Amantea',
      'Altomonte',
      'Altofonte',
      'Altilia',
      'Altavilla Milicia',
      'Alliste',
      'Allai',
      'Aliminusa',
      'Alimena',
      'Alia',
      'Alì',
      'Alessano',
      'Alessandria della Rocca',
      'Alessandria del Carretto',
      'Ales',
      'Alcara Li Fusi',
      'Alcamo',
      'Albidona',
      'Albi',
      'Aieta',
      'Aiello Calabro',
      'Aidone',
      'Agrigento',
      'Agnana Calabra',
      'Agira',
      'Africo Vecchio',
      'Adrano',
      'Acri',
      'Acquedolci',
      'Acquaviva Platani',
      'Acquaro',
      'Acquarica del Capo',
      'Acquappesa',
      'Acquaformosa',
      "Aci Sant'Antonio",
      'Acireale',
      'Aci Catena',
      'Aci Castello',
      'Aci Bonaccorsi',
      'Acate',
      'Olia Speciosa',
      'Santa Maria Navarrese',
      'Cardedu',
      'Siurgus Donigala',
      'Volpago del Montello',
      "Granarolo dell'Emilia e Viadagola",
      'Zungoli',
      'Zuglio',
      'Zuccarello',
      'Zovencedo',
      'Zoppola',
      'Zollino',
      'Zolla',
      'Zola Predosa',
      'Zogno',
      'Zocca',
      'Zoagli',
      'Zinasco Vecchio',
      'Zimone',
      'Zibido San Giacomo',
      'Zibello',
      'Ziano Piacentino',
      'Ziano di Fiemme',
      'Panchià',
      'Zevio',
      'Zero Branco',
      'Zermeghedo',
      'Zerbolò',
      'Zerbo',
      'Zepponami',
      'Zenson di Piave',
      'Zeme',
      'Zelo Buon Persico',
      'Zelarino',
      'Zavattarello',
      'Zapponeta',
      'Zanica',
      'Zanè',
      'Zandobbio',
      'Zambana',
      'Zagarolo',
      'Vomero',
      'Volvera',
      'Volturino',
      'Volturara Irpina',
      'Volturara Appula',
      'Voltido',
      'Volterra',
      'Voltana',
      'Volta Mantovana',
      'Voltago Agordino',
      'Voltaggio',
      'Volpiano',
      'Volpedo',
      'Volpara',
      'Volongo',
      'Volargne',
      'Volano',
      'Vogogna',
      'Voghera',
      'Vodo',
      'Vobbia',
      'Vobarno',
      'Vizzola Ticino',
      'Vivaro Romano',
      'Vivaro',
      'Viù',
      'Vitulazio',
      'Vitulano',
      'Vittuone',
      'Vittorito',
      'Vittorio Veneto',
      'Vitorchiano',
      'Vitigliano',
      'Viticuso',
      'Viterbo',
      'Vistrorio',
      'Visso',
      'Visciano',
      'Visano',
      'Virle Piemonte',
      'Vipiteno',
      'Vione',
      'Viola',
      'Vinzaglio',
      'Vinci',
      'Vinchiaturo',
      'Vinadio',
      'Vimodrone',
      'Vimercate',
      'Villotta',
      'Lancenigo-Villorba',
      'Villongo',
      'Villimpenta',
      'Villetta Barrea',
      'Villesse',
      'Villefranche',
      'Villa Vicentina',
      'Villa Verucchio',
      'Villaverla',
      'Vergano-Villa',
      'Villavallelonga',
      'Villata',
      'Villastellone',
      'Villa Santo Stefano',
      'Villa Santina',
      'Villa Santa Maria',
      'Villa Santa Lucia degli Abruzzi',
      'Villa Santa Lucia',
      'Villasanta',
      'Villa San Secondo',
      'Villar Perosa',
      'Villar Pellice',
      'Villarotta',
      'Villaricca',
      'Villar Focchiardo',
      'Villareggia',
      'Villarboit',
      'Villa Potenza',
      'Villa Pedergnano',
      'Villanuova sul Clisi',
      'Villanterio',
      'Villanova',
      'Villanova Monteleone',
      'Villanova Monferrato',
      'Villanova Mondovì',
      'Villanova Marchesana',
      'Villanova del Battista',
      "Villanova d'Asti",
      "Villanova d'Ardenghi",
      "Villanova d'Albenga",
      'Villammare',
      'Villa Minozzo',
      'Villamarzana',
      'Villamaina',
      'Villamagna',
      'Villalvernia',
      'Villa Literno',
      'Villalfonsina',
      'Villa Latina',
      'Villalago',
      'Villa Lagarina',
      'Villa Guardia',
      'Villaganzerla',
      'Villaga',
      'Villafranca Piemonte',
      'Villafranca in Lunigiana',
      'Villafranca di Verona',
      "Villafranca d'Asti",
      'Villa Faraldi',
      'Villafalletto',
      'Villa Estense',
      'Villadossola',
      'Villadose',
      'Villa',
      'Villa di Serio',
      'Villa di Chiavenna',
      'Villa del Conte',
      'Villa del Bosco',
      "Villa d'Almè",
      'Villa Cortese',
      'Villachiara',
      'Villa Celiera',
      'Villa Castelli',
      'Villa Caldari',
      'Villabruna-Umin',
      'Villabassa',
      'Villa Basilica',
      'Villa Bartolomea',
      'Villa Carcina',
      'Viguzzolo',
      'Vigonza',
      'Vigonovo-Fontanafredda',
      'Vigonovo',
      'Vigone',
      'Vigolzone',
      'Vigolo Vattaro',
      'Vigolo',
      'Vigo di Fassa',
      'Vigo di Cadore',
      'Vigodarzere',
      'Vignolo',
      'Vignola',
      'Vignate',
      'Vignanello',
      'Vignale Monferrato',
      'Vignacastrisi',
      'Vigliano Biellese',
      'Viggiù',
      'Viggiano',
      'Vigevano',
      'Vigatto',
      'Vigasio',
      'Vigarano Pieve',
      'Vigarano Mainarda',
      'Vietri sul Mare',
      'Vietri di Potenza',
      'Vieste',
      'Vidor',
      'Vidigulfo',
      'Vicovaro',
      'Vicopisano',
      'Vico nel Lazio',
      'Vicomoscano',
      'Vicolungo',
      'Vicoli',
      'Vicofertile',
      'Vico Equense',
      'Vico del Gargano',
      'Vico Canavese',
      'Viciomaggio',
      'Vicenza',
      'Vicchio',
      'Vicarello',
      'Vicalvi',
      'Vibonati',
      'Viareggio',
      'Viano',
      'Viadana',
      'Vezzano sul Crostolo',
      'Vezzano Ligure',
      'Vezzano',
      "Vezza d'Oglio",
      'Vetto',
      'Vetralla',
      'Vestone',
      'Vestignè',
      'Vestenanova',
      'Vessalico',
      'Vespolate',
      'Vesime',
      'Vescovato',
      'Vescovana',
      'Verzuolo',
      'Vervò',
      'Verucchio',
      'Vertova',
      'Vertemate Con Minoprio',
      'Verrone',
      'Verres',
      'Veronella',
      'Verona',
      'Veroli',
      'Verolengo',
      'Verolavecchia',
      'Verolanuova',
      'Vernole',
      'Vernio',
      'Vernazza',
      'Vernate',
      'Vernasca',
      'Vernante',
      'Vermiglio',
      'Vermezzo',
      'Vergiate',
      'Verghereto',
      'Vergato',
      'Verdello',
      'Vercurago',
      'Vercelli',
      'Verceia',
      'Venzone',
      'Venturina',
      'Ventotene',
      'Ventoso',
      'Ventimiglia',
      'Venosa',
      'Venice',
      'Venegono Superiore',
      'Venegono Inferiore',
      'Venasca',
      'Venarotta',
      'Venaria Reale',
      'Venaus',
      'Venafro',
      'Velturno',
      'Vellezzo Bellini',
      'Velletri',
      'Veleso',
      'Vejano',
      'Veglie',
      'Veggiano',
      'Vedeseta',
      'Vedelago',
      'Vedano Olona',
      'Vecchiano-Nodica',
      'Vazzola',
      'Vattaro',
      'Vastogirardi',
      'Vasto',
      'Vasia',
      'Vasanello',
      'Vas',
      'Varzo',
      'Varzi',
      'Varsi',
      'Varna',
      'Varmo',
      'Varese Ligure',
      'Varese',
      'Varenna',
      'Varedo',
      'Varco Sabino',
      'Varazze',
      "Varano De' Melegari",
      'Varallo Pombia',
      'Varallo',
      'Varago',
      "Vaprio D'Agogna",
      "Vaprio d'Adda",
      'Vanzone',
      'Vanzago',
      'Vanzaghello',
      'Vandoies di Sotto',
      'Valvasone',
      'Valva',
      'Valtorta',
      'Valstagna',
      'Valsinni',
      'Valprato Soana',
      'Valperga',
      'Valpelline',
      'Valnegra',
      'Valmontone',
      'Valmadrera-Caserta',
      'Valmacca',
      'Vallo della Lucania',
      'Vallinfreda',
      'Valli del Pasubio',
      'Vallese',
      'Valle San Nicolao',
      'Vallerotonda',
      'Vallerano',
      'Vallepietra',
      'Valle Mosso',
      'Vallemaio',
      'Valle Lomellina',
      'Valle di Maddaloni',
      "Valle dell'Angelo",
      'Vallecupa',
      'Vallecrosia',
      'Vallecorsa',
      'Valle Castellana',
      'Vallebona',
      'Valle Agricola',
      'Vallata',
      "Vall'Alta",
      'Vallà',
      'Valgrana',
      'Valgioie',
      'Valganna',
      'Valfenera',
      'Valfabbrica',
      'Valenzano',
      'Valenza',
      'Valentano',
      'Valeggio sul Mincio',
      'Valeggio',
      'Valduggia',
      'Valdottavo',
      'Valdobbiadene',
      'Valdieri',
      'Valdaora di Mezzo',
      'Valdagno',
      'Bondione',
      'Vairano-Patenora',
      'Vajont',
      'Vailate',
      'Vaiano Cremasco',
      'Vaiano',
      'Vaglio Basilicata',
      'Vaglia',
      'Vado Ligure',
      'Vado',
      'Vadena',
      'Vada',
      'Vacri',
      'Vacone',
      'Uzzano',
      'Usseglio',
      'Usmate-Velate',
      'Usini',
      'Uscio',
      'Urzulei',
      'Ururi',
      'Uri',
      'Urgnano',
      'Urbisaglia',
      'Urbino',
      'Urbania',
      'Umbertide',
      'Uliveto Terme',
      'Ulà Tirso',
      'Uggiate Trevano',
      'Uggiano Montefusco',
      'Uggiano la Chiesa',
      'Udine',
      'Tuscania',
      'Tursi',
      'Turrivalignani',
      'Turriaco',
      'Turi',
      'Turbigo',
      'Turano Lodigiano',
      'Turania',
      'Tuoro sul Trasimeno',
      'Tula',
      'Tuglie',
      'Tufino',
      'Tufillo',
      'Tuenno',
      'Tubre',
      'Truccazzano',
      'Trovo',
      'Troviggiano',
      'Tronzano Vercellese',
      'Tronzano Lago Maggiore',
      'Tromello',
      'Troia',
      'Trofarello',
      'Trodena',
      'Trivigno',
      'Trivignano Udinese',
      'Trivero-Prativero-Ponzone',
      'Trivento',
      'Triuggio',
      'Trissino',
      'Trisobbio',
      'Triora',
      'Trino',
      'Trinitapoli',
      "Trinità d'Agultu e Vignola",
      'Trinità',
      'Trigolo',
      'Triggiano',
      'Trieste',
      'Triei',
      'Tricesimo',
      'Tricerro',
      'Tricarico',
      'Tribiano',
      'Tribano',
      "Trezzo sull'Adda",
      'Trezzano sul Naviglio',
      'Trezzano Rosa',
      'Treviso',
      'Trevi nel Lazio',
      'Trevignano Romano',
      'Trevignano',
      'Treviglio',
      'Trevico',
      'Trevi',
      'Trevenzuolo',
      'Tresnuraghes',
      'Tresigallo-Final di Rero',
      'Trescore Cremasco',
      'Trescore Balneario',
      'Trescine',
      'Tres',
      'Trequanda',
      'Trepuzzi',
      'Treppo Grande',
      'Treponti',
      'Trentola-Ducenta',
      'Trento',
      'Trentinara',
      'Tremignon',
      'Tremezzo',
      'Tremenico',
      'Treia',
      'Tregnago',
      'Treglio',
      'Treggiaia',
      'Tredozio',
      'Trecenta',
      'Trecchina',
      'Trecate',
      'Trecasali',
      'Trebaseleghe',
      'Travo',
      'Travesio',
      'Traversetolo',
      'Traversella',
      'Travedona Monate',
      'Travagliato',
      'Trasaghis',
      'Trasacco',
      'Trarivi',
      'Trarego',
      'Traona',
      'Transacqua',
      'Trani',
      'Trana',
      'Tramutola',
      'Tramonti di Sopra',
      'Tramonti',
      'Tragliatella Campitello',
      'Tradate',
      'Tossicia',
      'Toscolano Maderno',
      'Torviscosa',
      'Tortoreto',
      'Tortorella',
      'Tortona',
      'Torrita Tiberina',
      'Torrita di Siena',
      'Torrioni',
      'Torrile',
      'Torri in Sabina',
      'Torriglia',
      'Torri di Quartesolo',
      'Torri del Benaco',
      'Torricella',
      'Torricella Peligna',
      'Torricella in Sabina',
      'Torricella del Pizzo',
      'Torrice',
      'Torriana',
      'Torrevecchia',
      'Torre Santa Susanna',
      'Torre San Patrizio',
      'Torre Pellice',
      'Torre Orsaia',
      'Torrenieri',
      'Torremaggiore',
      'Torre le Nocelle',
      'Torreglia',
      "Torre d'Isola",
      'Torre di Santa Maria',
      'Torre di Mosto',
      "Torre de' Picenardi",
      "Torre de' Passeri",
      'Torre del Mangano',
      'Torre del Lago Puccini',
      'Torre del Greco',
      "Torre de' Busi",
      'Torrecuso',
      'Torrebruna',
      'Torre Boldone',
      'Torre Beretti',
      'Torrebelvicino',
      'Torreano',
      'Torre Annunziata',
      'Torrazzo',
      'Torrazza Coste',
      'Torralba',
      'Torraca',
      'Torpè',
      'Toro',
      'Tornolo',
      'Torno',
      'Tornata',
      'Tornareccio',
      'Tornaco',
      'Torlino Vimercati',
      'Toritto',
      'Torino di Sangro',
      'Turin',
      'Torgnon',
      'Torgiano',
      'Torella del Sannio',
      'Torella dei Lombardi',
      'Torchiarolo',
      'Torchiara',
      'Torbole sul Garda',
      'Torbole Casaglia',
      'Torano Nuovo',
      'Tora',
      'Tonezza del Cimone',
      'Tonco',
      'Tonara',
      'Tonadico',
      'Tombolo',
      'Tolve',
      'Tolmezzo',
      'Tollo',
      'Tollegno',
      'Tolfa',
      'Tolentino',
      'Toirano',
      'Toffia',
      'Todi',
      'Tocco da Casauria',
      'Tocco Caudio',
      'Toano',
      'Tizzano Val Parma',
      'Tivoli',
      'Tito',
      'Tissi',
      'Tirrenia',
      'Tires',
      'Tirano',
      'Tione di Trento',
      'Tione degli Abruzzi',
      'Tinnura',
      'Tiglieto',
      'Ticineto',
      'Ticengo',
      'Tecchiena',
      'Tiana',
      'Thiesi',
      'Thiene',
      'Tezze',
      'Teti',
      'Testico',
      'Tessera',
      'Tessennano',
      'Tesimo',
      'Tesero',
      'Terzorio',
      'Terzigno',
      'Terruggia',
      'Terrossa',
      'Terricciola',
      'Terrazzo',
      'Terrarossa',
      'Terranuova Bracciolini',
      'Terracina',
      'Terontola',
      'Terni',
      'Ternate',
      'Termoli',
      'Termeno',
      'Terlizzi',
      'Terlano',
      'Terlago',
      'Terenzo',
      'Zugliano-Terenzano-Cargnacco',
      'Terento',
      'Terelle',
      'Terdobbiate',
      'Teramo',
      'Teora',
      'Teor',
      'Tenno',
      'Tencarola',
      'Temù',
      'Tempio Pausania',
      'Telve',
      'Telti',
      'Telgate',
      'Telese',
      'Tegoleto',
      'Teglio Veneto',
      'Teglio',
      'Teggiano-Macchiaroli',
      'Teano',
      'Teana',
      'Tavullia',
      'Tavoleto',
      'Tavernole',
      'Tavernola Bergamasca',
      'Tavernelle',
      "Taverne D'Arbia",
      'Tavenna',
      'Tavazzano',
      'Tavarnuzze',
      'Tavagnasco',
      'Tavagnacco',
      'Taurasi',
      'Taurano',
      'Tassullo',
      'Tarzo',
      'Tarvisio',
      'Tarquinia',
      'Tarcento',
      'Taranto',
      'Tarantasca',
      'Taranta Peligna',
      'Tarano',
      'Taneto',
      'La Saletta-Tamara',
      'Tamai',
      'Talmassons',
      'Talla',
      'Talana',
      'Talamello',
      'Taipana',
      'Taino',
      'Taibon Agordino',
      'Taglio di Po',
      'Taglio',
      'Tagliacozzo',
      'Taggia',
      'Tadasuni',
      'Taceno',
      'Suzzara',
      'Suvereto',
      'Sutri',
      'Sustinente',
      'Susegana',
      'Susa',
      'Surbo',
      'Surano',
      'Supino',
      'Supersano',
      'Suno',
      'Suni',
      'Summonte',
      'Sumirago',
      'Sulzano',
      'Sulmona',
      'Sueglio',
      'Subiaco',
      'Subbiano',
      'Suardi',
      'Sturno',
      'Strudà',
      'Strozza',
      'Stroppo',
      'Stroppiana',
      'Stroncone',
      'Strigno',
      'Striano',
      'Strevi',
      'Strettoia',
      'Stresa',
      'Stregna',
      'Strangolagalli',
      'Strambino',
      'Stradella',
      'Strada in Chianti',
      'Stra',
      'Storo',
      'Stornarella',
      'Stornara',
      'Stio',
      'Stintino',
      'Stimigliano',
      'Stigliano',
      'Stienta',
      'Stia',
      'Stezzano',
      'Sternatia',
      'Stenico',
      'Stelvio',
      'Stellanello',
      'Stella Cilento',
      'Stazzano',
      'Statte',
      'Staranzano',
      'Stanghella',
      'Stanghe',
      'Stagno Lombardo',
      'Staggia',
      'Staffolo',
      'Staffa',
      'Stabbia',
      'Squinzano',
      'Spresiano',
      'Spotorno',
      'Sporminore',
      'Spormaggiore',
      'Spongano',
      'Spoltore',
      'Spoleto',
      'Spirano',
      'Spinoso',
      "Spino d'Adda",
      'Spinetta Marengo',
      'Spinetoli',
      'Spinete',
      'Le Casine-Perignano-Spinelli',
      'Spineda',
      'Spinazzola',
      'Spilimbergo',
      'Spilamberto',
      'Spigno Saturnia Superiore',
      'Spigno Monferrato',
      'Spianate',
      'Sperlonga',
      'Spello',
      'Sparone',
      'Sparanise',
      'Sozzago',
      'Sovicille',
      'Soverzene',
      'Sovere',
      'Sotto il Monte Giovanni XXIII',
      'Sossano',
      'Sospirolo',
      'Sospiro',
      'Sorso',
      'Sorrento',
      'Sorradile',
      'Sormano',
      'Sorisole',
      'Soriano nel Cimino',
      'Sori',
      'Sorgono',
      'Soresina',
      'Sordevolo',
      'Sorbo Serpico',
      'Sorbolo',
      'Sorbara',
      'Sorano',
      'Soragna',
      'Sora',
      'Soprabolzano',
      'Sonnino',
      'Sonico',
      'Sondrio',
      'Sondalo',
      'Soncino',
      'Sona',
      'Somma Vesuviana',
      'Sommariva Perno',
      'Sommariva del Bosco',
      'Somma Lombardo',
      'Sommacampagna',
      'Solto Collina',
      'Solopaca',
      'Solofra',
      'Solignano Nuovo',
      'Soliera',
      'Solferino',
      'Soleto',
      'Solesino',
      'Solero',
      'Soldano',
      'Solbiate Olona',
      'Solbiate Arno',
      'Solarolo Rainerio',
      'Solarolo',
      'Solaro',
      'Solagna',
      'Sogliano Cavour',
      'Sogliano al Rubicone',
      'Soddì',
      'Soci',
      'Soccavo',
      'Soave',
      'Sluderno',
      'Sizzano',
      'Siziano',
      'Siviano',
      'Siusi',
      'Sistiana-Visogliano',
      'Sissa',
      'Siror',
      'Sirolo',
      'Sirmione',
      'Sirignano',
      'Siniscola',
      'Sindia',
      'Sinalunga',
      'Silvi',
      'Silvano Pietra',
      "Silvano d'Orba",
      'Sillavengo',
      'Sillano',
      'Siligo',
      'Silea',
      'Silanus',
      'Silandro',
      'Signa',
      'Sigillo',
      'Siena',
      'Sicignano degli Alburni',
      'Sgurgola',
      'Sgonico',
      'Sforzacosta',
      'Sezze',
      'Seveso',
      'Settimo Vittone',
      'Settimo Torinese',
      'Settimo Rottaro',
      'Settimo',
      'Settefrati',
      'Sestri Levante',
      'Sestriere',
      'Sesto San Giovanni',
      'Sestola',
      'Sesto Imolese',
      'Sesto Fiorentino',
      'Sesto ed Uniti',
      'Sesto Campano',
      'Sesto Calende',
      'Sesto al Reghena',
      'Sesto',
      'Sestino',
      'Sesta Godano',
      'Sessano del Molise',
      'Sessa Cilento',
      'Sessa Aurunca',
      'Servigliano',
      'Serrone',
      'Serre di Rapolano',
      'Serre',
      'Serravalle Sesia',
      'Serravalle Scrivia',
      'Serravalle Pistoiese',
      'Serravalle di Chienti',
      'Serravalle Langhe',
      "Serra Sant'Abbondio",
      'Serra San Quirico',
      'Serra Riccò',
      'Serrara Fontana',
      'Serrapetrona',
      'Serrano',
      'Serramonacesca',
      'Serramezzana',
      'Serramazzoni',
      'Serralunga di Crea',
      "Serra de' Conti",
      'Serracapriola',
      'Serole',
      'Sernaglia della Battaglia',
      'Sermoneta',
      'Sermide',
      'Serle',
      'Serino',
      'Serina',
      'Seriate',
      'Sergnano',
      'Seren del Grappa',
      'Seregno',
      'Seravezza',
      'Sequals',
      'Sepino',
      'Sennori',
      'Sennariolo',
      'Senna Lodigiana',
      'Senise',
      'Senigallia',
      'Seniga',
      'Senerchia',
      'Seneghe',
      'Senale',
      'Senago',
      'Semiana',
      'Semestene',
      'Selvino',
      'Selvazzano Dentro',
      'Selvatelle',
      'Selva di Cadore',
      'Selva dei Molini',
      'Selva',
      'Sellero',
      'Sellano',
      'Selci',
      'Segrate',
      'Segni',
      'Seggiano',
      'Sefro',
      'Sedrina',
      'Sedriano',
      'Sedini',
      'Sedilo',
      'Sedico',
      'Sedegliano',
      'Secugnago',
      'Secondigliano',
      'Seclì',
      'Secinaro',
      'Secchiano',
      'Seborga',
      'Seano',
      'Scurcola Marsicana',
      'Scorzè',
      'Scortichino',
      'Scorrano',
      'Scoppito',
      'Scopello',
      'Scontrone',
      'Scomigo',
      'Sciolze',
      'Naz-Sciaves - Natz-Schabs',
      'Schio',
      'Schilpario',
      'Schiavon',
      'Schiavi di Abruzzo',
      'Gazzada Schianno',
      'Scheggino',
      'Scheggia',
      'Scerni',
      'Scerne',
      'Scarperia',
      'Scarnafigi',
      'Scarmagno',
      'Scarlino',
      'Scario',
      'Scapoli',
      'Scanzano Jonico',
      'Scansano',
      'Scano di Montiferro',
      'Scanno',
      'Scandriglia',
      "Scandolara Ripa d'Oglio",
      'Scandolara Ravara',
      'Scandicci',
      'Scandiano',
      'Scampitella',
      'Scaltenigo',
      'Scalenghe',
      'Scaldasole',
      'Scala',
      'Scafati',
      'Scafa',
      'Savona',
      'Savoia di Lucania',
      "Savogna d'Isonzo",
      'Saviore',
      'Savio',
      'Savigno',
      'Savignano sul Rubicone',
      'Savignano sul Panaro',
      'Savignano Irpino',
      'Savigliano',
      'Saviano',
      'Sava',
      "Sauze d'Oulx",
      'Sauze di Cesana',
      'Sauris di Sotto',
      'Satriano di Lucania',
      'Sassuolo',
      'Sasso Marconi',
      'Sassoferrato',
      'Sassofeltrio',
      'Sasso di Castalda',
      'Sassocorvaro',
      'Sassinoro',
      'Sassetta',
      'Sassello',
      'Sassari',
      'Sassano',
      'Sassa',
      'Sarzana',
      'Sarule',
      'Sartirana Lomellina',
      'Sarteano',
      'Sarsina',
      'Saronno',
      'Sarno',
      'Sarnico',
      'Sarnano',
      'Sarmeola',
      'Sarmede',
      'Sarmato',
      'Sarezzo',
      'Sarezzano',
      'Sarentino',
      'Sarconi',
      'Sarcedo',
      'Saracinesco',
      'Sapri',
      'Sappada',
      'San Zenone al Po',
      'San Zenone al Lambro',
      'San Zenone degli Ezzelini',
      'San Zeno di Montagna',
      'Sanzeno',
      'Sanza',
      'San Vittore del Lazio',
      'San Vittore',
      'San Vito Romano',
      'San Vito di Leguzzano',
      'San Vito di Fagagna',
      'San Vito dei Normanni',
      'San Vito Chietino',
      'San Vito al Tagliamento',
      'San Vincenzo Valle Roveto',
      'San Vincenzo',
      'San Vero Milis',
      'San Vendemiano',
      'San Venanzo',
      'San Valentino Torio',
      'San Valentino in Abruzzo Citeriore',
      'San Valentino',
      'Santu Lussurgiu',
      'San Stino di Livenza',
      'Santo Stefano Ticino',
      'Santo Stefano di Sessanio',
      'Santo Stefano di Magra',
      'Santo Stefano di Cadore',
      'Santo Stefano del Sole',
      "Santo Stefano d'Aveto",
      'Santo Stefano Belbo',
      'Santo Stefano al Mare',
      'Santorso',
      "Sant'Oreste",
      'Santopadre',
      "Sant'Omero",
      'Santomenna',
      'Celat-San Tomaso Agordino',
      "Sant'Ippolito",
      "Sant'Ilario d'Enza",
      'Santi Cosma e Damiano',
      'Santhià',
      "Sant'Eusanio Forconese",
      "Sant'Eusanio del Sangro",
      "Sant'Eufemia a Maiella",
      'Santerno',
      'San Terenzo',
      'San Terenziano',
      'Santeramo in Colle',
      'Sante Marie',
      "Sant'Elpidio a Mare",
      "Sant'Elia Fiumerapido",
      "Sant'Elia a Pianisi",
      "Sant'Elena Sannita",
      "Sant'Elena",
      "Sant'Egidio alla Vibrata",
      'San Vittoria in Matenano',
      "Santa Vittoria d'Alba",
      'Santa Vittoria',
      'Santa Valburga',
      'Santa Teresa Gallura',
      'Santa Tecla-Castelpagano',
      'Santa Sofia',
      'Santa Rufina',
      "Sant'Arsenio",
      "Sant'Arpino",
      "Sant'Arcangelo Trimonte",
      'Santarcangelo',
      "Sant'Arcangelo",
      "Sant'Apollinare",
      'Santa Paolina',
      'Roncofreddo-Santa Paola',
      "Sant'Antonino di Susa",
      "Sant'Antonio Abate",
      "Sant'Antonio",
      "Sant'Antonio di Gallura",
      "Sant'Antimo",
      "Sant'Anna d'Alfaedo",
      "Sant'Angelo Romano",
      "Sant'Angelo Lomellina",
      "Sant'Angelo Lodigiano",
      "Sant'Angelo Limosano",
      "Sant'Angelo le Fratte",
      "Sant'Angelo in Vado",
      "Sant'Angelo in Pontano",
      "Sant'Angelo in Lizzola",
      "Sant'Angelo in Formis",
      "Sant'Angelo di Piove di Sacco",
      "Sant'Angelo del Pesco",
      "Sant'Angelo dei Lombardi",
      "Sant'Angelo d'Alife",
      "Sant'Angelo A Scala",
      "Sant'Angelo A Fasanella",
      "Sant'Angelo",
      "Sant'Andrea in Casale",
      "Sant'Andrea di Conza",
      "Sant'Andrea del Garigliano",
      'Santandrà',
      "Sant'Anatolia di Narco",
      "Sant'Anastasia",
      'San Tammaro',
      "Sant'Ambrogio sul Garigliano",
      "Sant'Ambrogio di Valpollicella",
      "Sant'Ambrogio di Torino",
      'Santa Marinella',
      'Santa Marina',
      'Santa Maria Nuova',
      'Santa Maria Maggiore',
      'Santa Maria Maddalena',
      'Santa Maria la Longa',
      'Santa Maria la Fossa',
      'Santa Maria Imbaro',
      'Venezzano',
      'Santa Maria di Sala',
      'Santa Maria',
      'Santa Maria del Molise',
      'Santa Maria degli Angeli',
      'Santa Maria Codifiume',
      'Santa Maria Capua Vetere',
      'Santa Maria A Vico',
      'Santa Maria a Monte',
      'Santa Maria Coghinas',
      'Santa Margherita Ligure',
      "Santa Margherita d'Adige",
      'Santa Lucia di Serino',
      'Santa Luce',
      "Sant'Alberto",
      "Sant'Albano Stura",
      "Sant'Agostino",
      "Sant'Agnello",
      'Santa Giustina in Colle',
      'Santa Giustina',
      'Santa Giuletta',
      "Sant'Agata sul Santerno",
      "Sant'Agata sui Due Golfi",
      "Sant'Agata Feltria",
      "Sant'Agata di Puglia",
      "Sant'Agata de'Goti",
      "Sant'Agata Bolognese",
      "Sant'Agapito",
      'Santa Fiora',
      "Santa Croce sull'Arno",
      'Santa Croce',
      'Santa Croce di Magliano',
      'Santa Cristina',
      'Santa Cristina Valgardena',
      'Santa Cesarea Terme',
      'Santa Brigida',
      'San Sossio Baronia',
      'San Severo',
      'San Severino Marche',
      'San Severino Lucano',
      'Sansepolcro',
      'San Secondo Parmense',
      'San Secondo',
      'San Sebastiano Curone',
      'San Sebastiano al Vesuvio',
      'San Sebastiano',
      'San Salvo',
      'San Salvatore Telesino',
      'San Salvatore Monferrato',
      'San Rufo',
      'San Romano',
      'San Remo',
      'San Quirino',
      "San Quirico d'Orcia",
      'San Quirico',
      'San Prisco',
      'San Potito Ultra',
      'San Potito Sannitico',
      'San Ponso',
      'San Polomatese',
      "San Polo d'Enza",
      'San Polo dei Cavalieri',
      'San Polo',
      'San Pio delle Camere',
      'San Pietro Viminario',
      'San Pietro Vernotico',
      'San Pietro Mosezzo',
      'San Pietro In Vincoli',
      'San Pietro in Lama',
      'San Pietro in Gu',
      'San Pietro Infine',
      'San Pietro in Casale',
      "San Pietro d'Olba",
      'San Pietro di Feletto',
      'San Pietro di Cadore',
      'San Pietro Capofiume',
      'San Pietro Avellana',
      'San Pietro al Tanagro',
      'San Pietro al Natisone',
      'San Pietro',
      'San Piero in Bagno',
      'San Piero a Sieve',
      "San Pier d'Isonzo",
      'San Pellegrino Terme',
      'San Paolo di Jesi',
      'San Paolo di Civitate',
      'San Paolo Bel Sito',
      'San Paolo Albanese',
      'San Paolo',
      "San Panfilo d'Ocre",
      'San Pancrazio Salentino',
      'San Pancrazio',
      'San Niccolò di Celle',
      'San Nicola Baronia',
      'Sannicola',
      'Sannicandro Garganico',
      'Sannicandro di Bari',
      'San Nazzaro Sesia',
      "Sannazzaro de' Burgondi",
      'San Nazario',
      'San Miniato',
      'San Miniato Basso',
      'San Michele Salentino',
      'San Michele Mondovì',
      'San Michele di Serino',
      'San Michele al Tagliamento',
      "San Michele All'Adige",
      'San Michele di Piave',
      'Olivetta San Michele',
      'Borgo San Michele',
      'San Mauro Torinese',
      'San Mauro Pascoli',
      'San Mauro la Bruca',
      'San Mauro Forte',
      'San Mauro di Saline',
      'San Mauro Cilento',
      'San Mauro',
      "San Maurizio D'Opaglio",
      'San Maurizio',
      'San Massimo',
      'San Marzano sul Sarno',
      'San Marzano di San Giuseppe',
      'San Martino Valle Caudina',
      'San Martino sulla Marrucina',
      'San Martino Siccomario',
      'San Martino Sannita',
      'San Martino in Rio',
      'San Martino in Pensilis',
      'San Martino in Freddana-Monsagrati',
      'San Martino in Campo',
      'San Martino in Argine',
      'San Martino di Venezze',
      'San Martino di Lupari',
      'San Martino del Lago',
      'San Martino della Battaglia',
      "San Martino Dall'Argine",
      "San Martino d'Agri",
      'San Martino Canavese',
      'San Martino Buon Albergo',
      'San Martino al Cimino',
      'San Martino in Passiria',
      'San Marco la Catola',
      'San Marco in Lamis',
      'San Marco Evangelista',
      'San Marco dei Cavoti',
      'San Marcello Pistoiese',
      'San Marcello',
      'San Mango sul Calore',
      'San Mango Piemonte',
      'San Lupo',
      'San Lorenzo Nuovo',
      'San Lorenzo Maggiore',
      'San Lorenzo in Campo',
      'San Lorenzo di Sebato',
      'San Lorenzo al Mare',
      'San Lorenzello',
      'San Leucio del Sannio-Cavuoti',
      'San Leonardo in Passiria',
      'San Leonardo',
      'San Leo',
      'San Lazzaro',
      'Sanguinetto',
      'San Gregorio Magno',
      'San Gregorio da Sassola',
      'San Gregorio Matese',
      'San Godenzo',
      'San Giusto Canavese',
      'San Giustino Valdarno',
      'San Giustino',
      'San Giuseppe Vesuviano',
      'San Giuseppe',
      'San Giuliano Vecchio',
      'San Giuliano Terme',
      'San Giuliano Milanese',
      'San Giuliano di Puglia',
      'San Giuliano del Sannio',
      'San Giovanni Valdarno',
      'San Giovanni Rotondo',
      'San Giovanni Lupatoto',
      'San Giovanni Lipioni',
      'San Giovanni in Persiceto',
      'San Giovanni in Marignano',
      'San Giovanni in Galdo',
      'San Giovanni in Croce',
      'San Giovanni Incarico',
      'San Giovanni Ilarione',
      'San Giovanni del Dosso',
      "San Giovanni d'Asso",
      'San Giovanni Bianco',
      'San Giovanni a Teduccio',
      'San Giovanni A Piro',
      'San Giovanni al Natisone',
      'San Giovanni',
      'San Giorio',
      'San Giorgio Piacentino',
      'San Giorgio Lucano',
      'San Giorgio la Molara',
      'San Giorgio Ionico',
      'San Giorgio in Salici',
      'San Giorgio in Bosco',
      'San Giorgio di Piano',
      'San Giorgio di Pesaro',
      'San Giorgio di Nogaro',
      'San Giorgio di Lomellina',
      'San Giorgio di Livenza',
      'San Giorgio del Sannio',
      'San Giorgio della Richinvelda',
      'San Giorgio Canavese',
      'San Giorgio a Liri',
      'San Giorgio a Cremano',
      'San Ginesio',
      'San Gimignano',
      'San Gillio',
      'San Giacomo delle Segnate',
      'San Giacomo degli Schiavoni',
      'San Giacomo',
      'San Gervasio Bresciano',
      'San Germano Vercellese',
      'San Gennaro Vesuviano',
      'San Genesio ed Uniti',
      'San Genesio Atesino',
      'San Gemini',
      'Sangano',
      'San Gabriele-Mondonuovo',
      'Sanfront',
      'Sanfrè',
      'Aglientu',
      'San Francesco al Campo',
      'San Floriano del Collio',
      'San Floriano',
      'San Fior di Sopra',
      'San Fiorano',
      'San Ferdinando di Puglia',
      'San Felice sul Panaro',
      'San Felice del Molise',
      'San Felice del Benaco',
      'San Felice Circeo',
      'San Felice',
      'San Fele',
      'San Fedele Superiore',
      'Sandrigo',
      'Dolina',
      'San Donato Val di Comino',
      'San Donato Milanese',
      'San Donato di Lecce',
      'San Donà di Piave',
      'San Donaci',
      'Sandigliano',
      "San Demetrio Ne' Vestini",
      'San Daniele del Friuli',
      'San Damiano Macra',
      "San Damiano d'Asti",
      'San Damiano al Colle',
      'Santa Croce del Sannio',
      'San Cristoforo',
      'San Costanzo',
      'San Costantino Albanese',
      'San Colombano al Lambro',
      'San Clemente',
      'San Cipriano Po',
      'San Cipriano Picentino',
      'San Chirico Raparo',
      'San Chirico Nuovo',
      'San Cesario sul Panaro',
      'San Cesario di Lecce',
      'San Cesareo',
      'San Castrese',
      'San Cassiano',
      'San Casciano in Val di Pesa',
      'San Casciano dei Bagni',
      'San Carlo',
      'San Candido',
      'San Buono',
      'San Bonifacio',
      'San Biase',
      'San Biagio Saracinisco',
      'San Biagio di Callalta',
      'San Biagio della Cima',
      'San Biagio',
      'San Benigno Canavese',
      'San Benedetto Val di Sambro',
      'San Benedetto Po',
      'San Benedetto in Perillis',
      'San Benedetto dei Marsi',
      'San Benedetto del Tronto',
      'San Bellino',
      'San Bassano',
      'San Bartolomeo in Galdo',
      'San Bartolomeo In Bosco',
      'Sanarica',
      'Sampeyre',
      'Samone',
      'Sammichele di Bari',
      'Sambuco',
      'Sambuci',
      'Samarate',
      'Salzano',
      'Salza Irpina',
      'Salvitelle',
      'Salvirola',
      'Salvatronda',
      'Salvaterra',
      'Saluzzo',
      'La Salute di Livenza',
      'Salussola',
      'Saluggia',
      'Saludecio',
      'Saltara',
      'Salsomaggiore Terme',
      'Salorno',
      'Salò',
      'Salmour',
      'Salle',
      'Salizzole',
      'Sali Vercellese',
      'Salitto-Valle',
      'Salisano',
      'Salionze',
      'Saline',
      'Saliceto',
      'Salice Terme',
      'Salice Salentino',
      'Salgareda',
      'Saletto-San Bartolomeo',
      'Saletto',
      'Salerno',
      'Salento',
      'Sale Marasino',
      'Sale',
      'Salcito',
      'Salcedo',
      'Salassa',
      'Salara',
      'Salandra',
      'Sala Monferrato',
      'Sala Consilina',
      'Sala Bolognese',
      'Sala Biellese',
      'Salbertrand',
      'Sala Baganza',
      'Sairano',
      'Saint-Vincent',
      'Saint-Pierre',
      'Saint-Oyen',
      'Sagrado',
      'Sagama',
      'Sacrofano',
      'Sacile',
      'Saccolongo',
      'Sacco',
      'La Caletta',
      'Sabbioneta',
      'Sabbio Chiese',
      'Sabbia',
      'Sabaudia',
      'Ruvo di Puglia',
      'Ruvo del Monte',
      'Ruviano',
      'Rutino',
      'Rutigliano',
      'Russi',
      'Ruoti',
      'Rufina',
      'Ruffia',
      'Rudiano',
      'Rubiera',
      'Rubiana',
      'Rubano',
      'Pragelato-Ruà',
      'Rozzano',
      'Rovigo',
      'Roviano',
      'Rovetta',
      'Rovereto',
      'Roveredo in Piano',
      'Roverè della Luna',
      'Roverbella',
      'Rovello Porro',
      'Rovellasca',
      'Rovato',
      'Rovasenda',
      'Rovagnate',
      'Rottofreno',
      'Rottanova',
      'Rotondi',
      'Rotondella',
      'Rotello',
      'Rotella',
      'Rossiglione',
      'Rossano Veneto',
      'Rossana',
      'Rosora',
      'Rosolina',
      'Rosignano Solvay-Castiglioncello',
      'Rosignano Marittimo',
      'Rosia',
      'Roseto Valfortore',
      'Roseto degli Abruzzi',
      'Rosello',
      'Roscigno',
      'Rosciano',
      'Rosate',
      'Rosasco',
      'Rosa',
      'Rosà',
      'Ronzone',
      'Ronta',
      'Ronsecco',
      'Rondissone',
      'Ronco Scrivia',
      'Roncone',
      'Roncofreddo',
      'Roncoferraro',
      'Roncocesi',
      'Ronco Canavese',
      "Ronco All'Adige",
      'Ronciglione',
      'Ronchis',
      'Ronchi dei Legionari',
      'Roncegno',
      'Roncade',
      'Roncà',
      'Romentino',
      'Romeno',
      "Romans d'Isonzo",
      'Romano di Lombardia',
      'Romano Canavese',
      'Romanengo',
      'Romana',
      'Romagnese',
      'Romagnano Sesia',
      'Scalo Romagnano al Monte',
      'Romagnano',
      'Rome',
      'Rolo',
      'La Cretaz-Roisan',
      'Roio del Sangro',
      'Roiate',
      'Rogolo',
      'Rognano',
      'Rofrano',
      'Rodigo',
      'Rodi Garganico',
      'Rodengo',
      'Rodeano',
      'Lucino-Rodano',
      "Rocchetta Sant'Antonio",
      'Rocchetta Nervina',
      'Rocchetta Ligure',
      'Rocchetta di Vara',
      'Rocchetta a Volturno',
      'Rocchetta',
      'Roccavivi',
      'Roccavivara',
      'Roccavione',
      'Susella',
      'Roccastrada',
      'Roccaspinalveti-Santa Giusta',
      'Rocca Sinibalda',
      'Roccasicura',
      'Roccasecca dei Volsci',
      'Roccasecca',
      'Roccascalegna',
      'Rocca San Felice',
      'Rocca San Casciano',
      'Roccaromana',
      'Roccaraso',
      'Roccarainola',
      'Rocca Priora',
      'Roccapiemonte',
      'Rocca Pia',
      'Roccantica',
      'Roccanova',
      'San Rocco',
      'Roccamonfina',
      'Rocca Massima',
      'Roccamandolfi',
      'Roccalbegna',
      'Rocca Imperiale',
      'Roccagloriosa',
      'Roccagiovine',
      'Roccafranca',
      'Roccaforzata',
      'Roccaforte Mondovì',
      'Roccaforte Ligure',
      'Rocca di Papa',
      'Rocca di Mezzo',
      'Rocca di Cave',
      'Rocca di Cambio',
      'Rocca di Botte',
      'Roccadaspide',
      "Rocca d'Arce",
      'Roccacasale',
      'Rocca Canavese',
      'Roccabianca',
      'Robilante',
      'Robecco sul Naviglio',
      "Robecco d'Oglio",
      'Robecchetto Con Induno',
      'Robbio',
      'Robbiate',
      'Robassomero',
      'Roasio',
      'Roana',
      'Ro',
      "Rivolta d'Adda",
      'Rivoli',
      'Rivodutri',
      'Rivignano',
      'Rivergaro',
      'Rivello',
      "Rive d'Arcano",
      'Rive',
      'Rivarolo Mantovano',
      'Rivarolo del Re',
      'Rivarolo Canavese',
      'Rivara',
      'Riva Presso Chieri',
      'Rivanazzano',
      'Rivamonte Agordino',
      'Rivalta di Torino',
      'Riva Ligure',
      'Rivalba',
      'Riva del Garda',
      'Ripi',
      'Ripe San Ginesio',
      'Ripe',
      'Ripatransone',
      'Ripa Teatina',
      'Riparbella',
      'Ripalta Nuova',
      'Ripalimosani',
      'Ripacandida',
      'Ripabottoni',
      'Vignale Riotorto',
      'Rio Saliceto',
      'Rionero Sannitico',
      'Rionero in Vulture',
      'Rio Marina',
      'Riomaggiore',
      'Riolunato',
      'Riolo Terme',
      'Riofreddo',
      'Rio di Pusteria',
      "Rio nell'Elba",
      'Rimini',
      'Rimella',
      'Rimasco',
      'Rima',
      'Rigolato',
      "Rignano sull'Arno",
      'Rignano Garganico',
      'Rignano Flaminio',
      'Scena',
      'Rieti',
      'Riese Pio X',
      'Ricigliano',
      'Riccò del Golfo',
      'Riccione',
      'Riccia',
      'Ribolla',
      'Riardo',
      'Riano',
      'Riale',
      'Rho',
      'Rhemes-Notre-Dame',
      'Rezzoaglio',
      'Rezzo',
      'Rezzato',
      'Rezzago',
      'Revò',
      'Revine',
      'Revigliasco',
      'Revere',
      'Revello',
      'Retorbido',
      'Resiutta',
      'Rescaldina',
      'Resana',
      'Renazzo',
      'Remedello di Sopra',
      'Remanzacco',
      'Reino',
      "Reggio nell'Emilia",
      'Reggiolo',
      'Reggello',
      'Refrontolo',
      'Recoaro Terme',
      'Recetto',
      'Recco',
      'Recanati',
      'Recale',
      'Reano',
      'Re',
      'Raviscanina',
      'Raveo',
      'Ravenna',
      'Ravello',
      'Ravascletto',
      'Ravarino',
      'Rauscedo-Domanins',
      'Rasura',
      'Rassina',
      'Rapone',
      'Rapolla',
      'Rapolano Terme',
      'Rapino',
      'Rapallo',
      'Rapagnano',
      'Ranco',
      'Rancio Valcuvia',
      'Ramon',
      'Ramiseto',
      'Raldon',
      'Raiano',
      'Radicondoli',
      'Radicofani',
      'Radda in Chianti',
      'Racines',
      'Racconigi',
      'Quistello',
      "Quinzano d'Oglio",
      'Quinto Vicentino',
      'Quinto Vercellese',
      'Quinto di Treviso',
      'Quingentole',
      'Quindici',
      'Quincinetto',
      'Quiliano',
      'Quero',
      'Quercianella',
      'Ripa-Pozzi-Querceta-Ponterosso',
      'Quattro Castella',
      'Quattordio',
      'Quassolo',
      'Quasano',
      'Quarto Inferiore',
      "Quarto d'Altino",
      'Quartesana',
      'Quart',
      'Quarrata',
      'Quarona',
      'Quarna Sopra',
      'Quargnento',
      'Quarata',
      'Quarantoli',
      'Qualiano',
      'Quagliuzzo',
      'Quadri',
      'Quadrelle',
      'Putignano',
      'Putifigari',
      'Pusiano',
      "Puos d'Alpago",
      'Punta Marina',
      'Pumenengo',
      'Pulsano',
      'Pulfero',
      'Puianello',
      'Puglianello',
      'Provvidenti',
      'Prossedi',
      'Prosecco-Contovello',
      'Propata',
      'Procida',
      'Proceno',
      'Priverno',
      'Priola',
      'Prignano sulla Secchia',
      'Prignano Cilento',
      'Prezza',
      'Preturo',
      'Pretoro',
      'Pressana',
      'Presenzano',
      'Prè Saint Didier',
      'Preore',
      'Premosello-Chiovenda',
      'Premilcuore',
      'Premeno',
      'Premariacco',
      'Premana',
      'Preganziol',
      'Predosa',
      'Predore',
      'Predoi',
      'Predazzo',
      'Preci',
      'Precenicco',
      'Prazzo Superiore',
      'Pray',
      'Pravisdomini',
      'Pratovecchio',
      'Prato Sesia',
      'Pratola Serra',
      'Pratola Peligna',
      'Prato',
      'Prato Carnico',
      'Prato Allo Stelvio',
      'Pratella',
      'Prata Sannita Centro',
      'Pratantico-Indicatore',
      'Prata di Principato Ultra',
      'Prata di Pordenone',
      "Prata d'Ansidonia",
      'Prata Camportaccio',
      'Prascorsano',
      'Prarolo',
      'Pralungo',
      'Pralormo',
      'Prali',
      'Pralboino',
      'Praiano',
      'Pradleves',
      'Pozzuolo del Friuli',
      'Pozzuolo',
      'Pozzuoli',
      'Pozzonovo',
      'Pozzomaggiore',
      'Pozzolo Formigaro',
      'Pozzoleone',
      'Pozzolengo',
      'Pozzillo',
      'Pozzilli',
      'Pozzaglio ed Uniti',
      'Pozzaglia Sabino',
      'Pozza di Fassa',
      'Pozza',
      'Povoletto',
      'Poviglio',
      'Povegliano Veronese',
      'Potenza Picena',
      'Potenza',
      'Postua',
      'Postioma',
      'Postiglione',
      'Postalesio',
      'Postal',
      'Posta',
      'Posta Fibreno',
      'Possagno',
      'Positano',
      'Posina',
      'Posillipo',
      'Posatora',
      'Posada',
      'Portula',
      'Portovenere',
      'Porto Valtravaglia',
      'Porto Torres',
      'Porto Tolle',
      'Porto Santo Stefano',
      "Porto Sant'Elpidio",
      'Porto San Giorgio',
      'Porto Recanati',
      'Porto Potenza Picena',
      'Portomaggiore',
      'Portogruaro',
      'Porto Garibaldi',
      'Porto Fuori',
      'Portofino',
      'Portoferraio',
      'Porto Corsini',
      'Civitanova Marche',
      'Porto Ceresio',
      'Portocannone',
      'Portobuffolè',
      'Porto Azzurro',
      'Portico di Romagna',
      'Portico di Caserta',
      'Portici',
      'Porto Ercole',
      'Portalbera',
      'Porretta Terme',
      'Porporano',
      'Porpetto',
      'Porotto-Cassama',
      'Pornassio',
      'Porlezza',
      'Pordenone',
      'Porcia',
      'Porcellengo',
      'Porcari',
      'Porano',
      'Poppi',
      'Popoli',
      'Ponzone',
      'Ponzano di Fermo',
      'Ponza',
      'Pont-Saint-Martin',
      'Pontremoli',
      'Pontinvrea',
      'Pontinia',
      'Pontida',
      'Ponticino',
      'Ponticelli',
      'Ponti',
      'Pontevico',
      'Lavena Ponte Tresa',
      'Ponte Taro',
      'Pontestura',
      'Ponte San Pietro',
      'Ponte San Nicolò',
      'Ponte Ronca',
      'Pontenure',
      'Ponte Nossa',
      'Ponte Nizza',
      'Ponte nelle Alpi',
      'Pontelongo',
      'Pontelatone',
      'Pontelandolfo',
      'Ponte Lambro',
      'Ponte Gardena',
      'Ponte Galeria-La Pisana',
      'Ponte di Piave',
      'Ponte di Legno',
      'Ponte di Barbarano',
      'Pontedera',
      "Ponte dell'Olio",
      'Ponte Arche',
      'Ponte della Venturina',
      'Pontedassio',
      'Pontecurone',
      'Pontecorvo',
      'Pontecchio Polesine',
      'Pontecagnano',
      'Ponte Caffaro',
      'Ponte Buggianese',
      'Pontebba',
      'Ponte A Tressa',
      'Giovi-Ponte alla Chiassa',
      'Ponte a Ema',
      'Ponte A Elsa',
      'Ponte',
      'Pont-Canavese',
      'Pontassieve',
      'Ponso',
      'Ponsacco',
      'Ponna Superiore',
      'Poncarale',
      'Pomponesco',
      'Pompiano',
      'Pompeiana',
      'Pompei',
      "Pomigliano d'Arco",
      'Pomezia',
      'Pometo',
      'Pombia',
      'Pomaro Monferrato',
      'Pomarico',
      'Pomaretto',
      'Pomarance',
      'Polvica',
      'Polverigi',
      'Polverara',
      'Ponte nelle Alpi-Polpet',
      'Pollutri',
      'Pollone',
      'Pollica',
      'Pollenza',
      'Pollein',
      'Polla',
      'Polino',
      'Polinago',
      'Polignano a Mare',
      'Policoro',
      'Policastro Bussentino',
      'Poli',
      'Polesine Parmense',
      'Polesella',
      'Polcenigo',
      'Poirino',
      'Poiano',
      'Poiana Maggiore',
      'Pogno',
      'Pognana Lario',
      'Pogliano Milanese',
      'Poggiridenti Alto',
      'Poggio San Vicino',
      'Poggio Sannita',
      'Poggio San Lorenzo',
      'Poggio Rusco',
      'Poggio Renatico',
      'Villa Opicina',
      'Poggioreale',
      'Poggio Picenze',
      'Poggio Nativo',
      'Poggio Moiano',
      'Poggio Mirteto',
      'Poggiomarino',
      'Battifolle-Ruscello-Poggiola',
      'Poggio Imperiale',
      'Poggiofiorito',
      'Poggiodomo',
      'Poggio Catino',
      'Poggio Bustone',
      'Poggio Berni',
      'Poggio A Caiano',
      'Poggibonsi',
      'Poggiardo',
      'Pofi',
      'Podenzano',
      'Podenzana',
      'Pocenia',
      'Ploaghe',
      'Plaus',
      'Pizzone',
      'Pizzoli',
      'Pizzoferrato',
      'Pizzighettone',
      'Piuro',
      'Piumazzo',
      'Piubega',
      'Pitigliano',
      'Piteglio',
      'Pistrino',
      'Pistoia',
      'Pisticci',
      'Pisoniano',
      'Pisogne',
      'Pisignano',
      'Pisciotta',
      'Piscinola',
      'Pisa',
      'Piozzano',
      'Piovene Rocchette',
      'Piove di Sacco-Piovega',
      'Piossasco',
      'Pioraco',
      'Piombino Dese',
      'Piombino',
      'Pioltello',
      'Piode',
      'Piobesi Torinese',
      "Piobesi d'Alba",
      'Piobbico',
      'Pinzolo',
      'Pinzano al Tagliamento',
      'Pino sulla Sponda del Lago Maggiore',
      'Pinocchio di Ancona',
      'Pineto',
      'Pinerolo',
      'Pincara',
      'Pinarella',
      'Pimonte',
      'Pila',
      'Pigra',
      'Pignone',
      'Pignola',
      'Pignataro Maggiore',
      'Pignataro Interamna',
      'Pigna',
      'Piglio',
      'Pieve Vergonte',
      'Pieve Torina',
      'Pieve Tesino',
      'Pieve Santo Stefano',
      'Pieve San Giacomo',
      'Pieve Porto Morone',
      'Pievepelago',
      'Pieve Ligure',
      'Pieve Fosciana',
      "Pieve d'Olmi",
      'Pieve di Teco',
      'Pieve di Soligo',
      'Pieve di Cento',
      'Pieve di Cadore',
      'Pieve del Cairo',
      "Pieve d'Alpago",
      'Pievebovigliana',
      'Pieve Albignola',
      'Pieve',
      'Pietrelcina',
      'Pietravairano',
      'Pietrastornina',
      'Pietrasanta',
      'Pietraporzio',
      'Pietrapertosa',
      'Pietranico',
      'Pietramurata',
      'Pietramontecorvino',
      'Pietramelara',
      'Pietralunga',
      'Pietra Ligure',
      'Pietra la Croce',
      'Pietragalla',
      'Pietraferrazzana',
      "Pietra de' Giorgi",
      'Pietradefusi',
      'Pietracuta',
      'Pietracupa',
      'Pietracatella',
      'Pietracamela',
      'Pietrabruna',
      'Pietrabbondante',
      'Pieris',
      'Pienza',
      'Piegaro',
      'Piedimulera',
      'Piedimonte San Germano',
      'Piedimonte',
      'Piedicavallo',
      "Pie' del Sasso",
      "Pie' del Colle",
      'Piea',
      'Pico',
      'Picinisco',
      'Picerno',
      'Picciano',
      'Piazzola sul Brenta',
      'Piazzatorre',
      'Piazza Brembana',
      'Piazza al Serchio-San Michele',
      'Piazza',
      'Piateda Centro',
      'Piasco',
      'Pianura',
      'Piantedo',
      'Piansano',
      'Pianoro',
      'Pianola',
      'Piano di Sorrento',
      'Piano di Follo',
      "Piano-Molini d'Isola",
      'Piano',
      'Pianillo',
      'Pianiga',
      'Piangipane',
      'Pianezze',
      'Pianezza',
      'Pianello Val Tidone',
      'Pianella',
      'Pian di Scò',
      'Piandimeleto',
      'Piamborno',
      'Piancastagnaio',
      'Pian Camuno',
      'Pianazzo',
      'Piana Crixia',
      'Piana Battolla',
      'Piaggine',
      'Piagge',
      'Piadena',
      "Piacenza d'Adige",
      'Piacenza',
      'Lama Pezzoli',
      'Pezze di Greco',
      'Pezzana',
      'Peveragno',
      'Pettorazza Grimani',
      'Pettorano sul Gizio',
      'Pettoranello del Molise',
      'Pettinengo',
      'Petruro Irpino',
      'Petritoli',
      'Petriolo',
      'Petrignano',
      'Petriano',
      'Petrella Tifernina',
      'Petrella Salto',
      'Petina',
      'Petacciato',
      'Pessione',
      'Pessinetto',
      'Pessina Cremonese',
      'Pescosolido',
      'Pescosansonesco Nuovo',
      'Pesco Sannita',
      'Pescorocchiano',
      'Pescopennataro',
      'Pescopagano',
      'Pescolanciano',
      'Pescocostanzo',
      'Pescina',
      'Pescia Romana',
      'Pescia',
      'Peschiera del Garda',
      'Bettola-Zeloforomagno',
      'Peschici',
      'Pesche',
      'Pescasseroli',
      'Pescarolo',
      'Pescara',
      'Pescantina',
      'Pescaglia',
      'Pesaro',
      'Perugia',
      'Pertosa',
      'Pertengo',
      'Perrero',
      'Perosa Argentina',
      'Pernumia',
      'Pernate',
      'Perito',
      'Perinaldo',
      'Pergola',
      'Pergine Valsugana',
      'Pergine Valdarno',
      'Perfugas',
      'Pereto',
      'Perdifumo',
      'Percoto',
      'Percile',
      'Perca',
      'Perarolo di Cadore',
      'Perano',
      'Penne',
      "Penna Sant'Andrea",
      'Penna San Giovanni',
      'Pennapiedimonte',
      'Penna in Teverina',
      'Pennadomo',
      'Pennabilli',
      'Pellizzano',
      'Pellezzano',
      'Pellestrina',
      'Pellegrino Parmense',
      'Pella',
      'Pelago',
      'Pegolotte',
      'Pegognaga',
      'Peglio',
      'Pedesina',
      'Pederobba',
      'Pedavena',
      'Pedaso',
      'Pecorara',
      'Pecetto di Valenza',
      'Pecetto',
      'Peccioli',
      'Pavullo nel Frignano',
      'Pavone del Mella',
      'Pavone Canavese',
      'Pavona',
      'Pavia',
      'Pavarolo',
      'Paupisi',
      'Paullo',
      'Paulilatino',
      'Paularo',
      'Pattada',
      'Patrica',
      'Patigno',
      'Paternopoli',
      'Paterno',
      'Pastrengo',
      'Pastena',
      'Passo di Treia',
      'Passo di Mirabella-Pianopantano',
      'Passirano',
      'Passignano sul Trasimeno',
      'Passarella',
      'Paspardo',
      'Pasiano',
      'Pasian di Prato',
      'Parzanica',
      'Paruzzaro',
      'Parre',
      'Parrano',
      'Parona',
      'Parolise',
      'Parma',
      'Parella',
      'Parcines',
      'Paratico',
      'Parabita',
      'Parabiago',
      'Papozze',
      'Paolisi',
      'Panzano in Chianti',
      'Pantigliate',
      'Pantalla',
      'Panni',
      'Pannarano',
      'Panicale',
      'Piazza di Pandola',
      'Pandino',
      'Pancarana',
      'Pancalieri',
      'Pamparato',
      'Paluzza',
      'Palù del Fersina',
      'Paludea',
      'Palù',
      'Palosco',
      'Palomonte',
      'Palombaro',
      'Palombara Sabina',
      'Palombaio',
      'Palo del Colle',
      'Palmiano',
      'Palmariggi',
      'Palmanova',
      'Palma Campania',
      'Pallerone',
      "Palazzolo sull'Oglio",
      'Pallare',
      'Pallanzeno',
      'Palinuro',
      'Palidano',
      'Paliano',
      'Palestro',
      'Palestrina',
      'Palena',
      'Palazzuolo sul Senio',
      'Palazzo San Gervasio',
      'Palazzolo Vercellese',
      'Palazzolo dello Stella',
      'Palazzo Canavese',
      'Palazzo',
      'Palau',
      'Palata',
      'Palanzano',
      'Palaia',
      'Palagiano',
      'Palagianello',
      'Palagano',
      'Pago Veiano',
      'Pagnona',
      'Paglieta',
      'Paganico Sabino',
      'Paganica-Tempera',
      'Pagani',
      'Paese',
      'Paesana',
      'Padulle',
      'Paduli',
      'Stazione di Padule',
      'Padula',
      'Padru',
      'Padria',
      'Padova',
      'Padivarma',
      'Paderno Franciacorta',
      'Paderno Dugnano',
      "Paderno d'Adda",
      'Paderno Ponchielli',
      'Paderna',
      'Padergnone',
      'Padenghe sul Garda',
      'Paciano',
      'Pacentro',
      'Ozzero',
      'Ozzano Monferrato',
      'Ozieri',
      'Ozegna',
      'Oyace',
      'Ovodda',
      'Ovindoli',
      'Ovaro',
      'Ovada',
      'Oulx',
      'Ottone',
      'Ottobiano',
      'Ottiglio',
      'Ottaviano',
      'Ottati',
      'Ottana',
      'Otricoli',
      'Otranto',
      'Ostuni',
      'Ostra Vetere',
      'Ostra',
      'Ostiglia',
      'Ostiano',
      'Osteno-Claino',
      'Ostellato',
      'Ossona',
      'Ossi',
      'Ossago Lodigiano',
      'Ospitaletto',
      'Ospitale di Cadore',
      'Ospedalicchio',
      'Ospedaletto Euganeo',
      "Ospedaletto d'Alpinolo",
      'Ospedaletto',
      'Ospedaletti',
      'Osoppo',
      'Osnago',
      'Osmate',
      'Osimo',
      'Osilo',
      'Osiglia',
      'Osidda',
      'Oschiri',
      'Orzivecchi',
      'Orzinuovi',
      'Orvinio',
      'Orvieto',
      'Orune',
      'Ortueri',
      'Ortucchio',
      'Ortovero',
      'Ortona dei Marsi',
      'Ortona',
      'Ortisei',
      'Ortignano Raggiolo',
      'Ortezzano',
      'Ortelle',
      'Orte',
      'Orta San Giulio',
      'Orta Nova',
      'Orsogna',
      'Orsenigo',
      'Orsara di Puglia',
      'Orria',
      'Orotelli',
      'Orosei',
      'Ornica',
      'Ornavasso',
      'Ornago',
      'Ormea',
      'Oriolo Romano',
      'Oriolo',
      'Orio Litta',
      'Orio Canavese',
      'Orio al Serio',
      'Origgio',
      'Oricola',
      'Oria',
      'Orgosolo',
      'Orgiano',
      'Ordona',
      'Orciano di Pesaro',
      'Orcenico Inferiore',
      'Orbetello',
      'Orbassano',
      'Oratino',
      'Cursolo',
      'Orani',
      'Ora',
      'Oppido Lucano',
      'Oppeano',
      'Opi',
      'Opera',
      'Oniferi',
      'Onifai',
      'Oneta',
      'Onano',
      'Onanì',
      'Omignano',
      'Omegna',
      'Olzai',
      'Oltrona di San Mamette',
      'Oltre Il Colle',
      'Olmo al Brembo',
      'Vignole-Olmi',
      'Olmeneta',
      'Olmedo',
      'Ollolai',
      'Oliveto Lucano',
      'Vassena',
      'Oliveto Citra',
      'Oliena',
      'Olginate',
      'Olgiate Olona',
      'Olgiate Comasco',
      'Olevano sul Tusciano',
      'Olevano Romano',
      'Olevano di Lomellina',
      'Oleggio',
      'Oldenico',
      'Olcenengo',
      'Olbia',
      'Ogliastro Cilento',
      'Oglianico',
      'Oggiono',
      'Offlaga',
      'Offida',
      'Offanengo',
      'Offagna',
      'Ofena',
      'Odolo',
      'Oderzo',
      'Occimiano',
      'Occhiobello',
      'Occhieppo Superiore',
      'Occhieppo Inferiore',
      'Nuvolento',
      'Nusco',
      'Nuoro',
      'Numana',
      'Nulvi',
      'Nule',
      'Nughedu Santa Vittoria',
      'Nughedu San Nicolò',
      'Nucetto',
      'Novoli',
      'Novi Velia',
      'Novi Ligure',
      'Novi di Modena',
      'Noventa Vicentina',
      'Noventa di Piave',
      'Novello',
      'Novellara',
      'Nove',
      'Novate Milanese',
      'Novate Mezzola',
      'Nova Siri',
      'Novara',
      'Nova Ponente',
      'Nova Milanese',
      'Nova Levante',
      'Novalesa',
      'Novaledo',
      'Novafeltria',
      'Notaresco',
      'Nosate',
      'Norma',
      'Norcia',
      'Norbello',
      'Noragugume',
      'None',
      'Nonantola',
      'Nomi',
      'Nomaglio',
      'Noli',
      'Nole',
      'Nola',
      'Noicattaro',
      'Noha',
      'Nogarole Rocca',
      'Nogara',
      'Noepoli',
      'Nociglia',
      'Noci',
      'Noceto',
      'Nocera Umbra',
      'Nocera Superiore',
      'Nocera Inferiore',
      'Nocelleto',
      'Nocciano',
      'Nocara',
      'Noasca',
      'Noale',
      'Nizza Monferrato',
      'Nimis',
      'Niella Belbo',
      'Nichelino',
      'Nibbiano',
      'Neviano degli Arduini',
      'Neviano',
      'Nettuno',
      'Netro',
      'Nespolo',
      'Nerviano',
      'Nervesa della Battaglia',
      'Nerola',
      'Nerito',
      'Nereto',
      'Nepi',
      'Nepezzano',
      'Neoneli',
      'Nemoli',
      'Nemi',
      'Nembro',
      'Bricco di Neive',
      'Neirone',
      'Negrar',
      'Conscenti',
      'Nazzano',
      'Navelli',
      'Nave',
      'Naturno',
      'Nasino-Borgo',
      'Narzole',
      'Narni Scalo',
      'Narni',
      'Nardò',
      'Narbolia',
      'Naples',
      'Nalles',
      'Nago-Torbole',
      'Muzzana del Turgnano',
      'San Pietro Mussolino',
      'Musso',
      'Musile di Piave',
      'Musano',
      'Muros',
      'Muro Lucano',
      'Muro Leccese',
      'Murlo',
      'Murisengo',
      'Murialdo',
      'Murazzano',
      'Murano',
      'Mura',
      'Mulazzo',
      'Mugnano di Napoli',
      'Mugnano del Cardinale',
      'Muggiò',
      'Muggia',
      'Muccia',
      'Mozzecane',
      'Mozzate',
      'Mozzanica',
      'Mozzagrogna',
      'Mottola',
      'Motta Visconti',
      'Motta Montecorvino',
      'Mottalciata',
      'Motta di Livenza',
      'Motta Dè Conti',
      'Motta Baluffi',
      'Motta',
      'Mosso Santa Maria',
      'Mossa',
      'Moso in Passiria',
      'Mosnigo',
      'Moscufo',
      "Mosciano Sant'Angelo",
      'Moscazzano',
      'Moruzzo',
      'Morterone',
      'Mortegliano',
      'Mortara',
      'Morsano',
      'Morrovalle',
      'Morro Reatino',
      'Morrone del Sannio',
      "Morro d'Oro",
      "Morro d'Alba",
      'Morra de Sanctis',
      'Morozzo',
      'Morolo',
      'Mornago',
      'Morlupo',
      'Morimondo',
      'Morigerati',
      'Moricone',
      'Moriago della Battaglia',
      'Mori',
      'Morgex',
      'Morfasso',
      'Moretta',
      'Moresco',
      'Mores',
      'Mordano',
      'Morcone',
      'Morciano di Romagna',
      'Morbegno',
      'Morano sul Po',
      'Monzuno',
      'Monzambano',
      'Monza',
      'Montù Beccaria',
      'Montresta',
      'Montottone',
      'Montorso Vicentino',
      'Montorio',
      'Montorio Romano',
      'Montorio Nei Frentani',
      'Montorio al Vomano',
      'Montopoli',
      'Montopoli in Sabina',
      'Montone',
      'Montoggio',
      'Montodine',
      'Montjovet',
      'Montignoso',
      'Montiglio',
      'Montieri',
      'Monticiano',
      'Montichiari',
      'Monticello Conte Otto',
      'Monticelli',
      "Monticelli d'Ongina",
      'Monticchio',
      'Montiano',
      'Monti',
      'Monte Vidon Corrado',
      'Monteviale',
      'Monteverdi Marittimo',
      'Monteverde',
      'Montevarchi',
      'Monteu Roero',
      'Monte Urano',
      'Monteu da Po',
      'Montespertoli',
      'Montesilvano Marina',
      'Montesegale',
      'Montese',
      'Montescudo',
      'Montescaglioso',
      'Montesarchio',
      'Monte San Vito',
      "Monte Sant'Angelo",
      'Monte Santa Maria Tiberina',
      'Monte San Savino',
      'Monte San Pietrangeli',
      'Montesano sulla Marcellana',
      'Monte San Martino',
      'Monte San Giusto',
      'Monte San Giovanni in Sabina',
      'Monte San Giovanni Campano',
      'Monte San Giacomo',
      'Monte San Biagio',
      'Monterubbiano',
      'Monterotondo Marittimo',
      'Monterotondo',
      'Monterosso al Mare',
      'Monterosi',
      'Monteroni di Lecce',
      "Monteroni d'Arbia",
      'Monte Romano',
      'Monteroduni',
      'Monte Roberto',
      'Monte Rinaldo',
      'Monteriggioni',
      'Monterenzio',
      'Montereale Valcellina',
      'Montereale',
      'Monterchi',
      'Monterado',
      'Montepulciano Stazione',
      'Montepulciano',
      'Monteprandone',
      'Monte Porzio Catone',
      'Monteparano',
      'Monteodorisio',
      'Montenero Val Cocchiara',
      'Montenerodomo',
      'Montenero di Bisaccia',
      'Montemurro',
      'Montemurlo',
      'Montemonaco',
      'Montemitro',
      'Montemilone',
      'Montemiletto',
      'Montemesola',
      'Monte Marenzo',
      'Montemarciano',
      'Montemarano',
      'Montemagno',
      'Montemaggiore al Metauro',
      'Montelupone',
      'Montelupo Fiorentino',
      'Montelupo Albese',
      'Montelparo',
      'Montelongo',
      'Monteleone Sabino',
      'Monteleone Rocca Doria',
      "Monteleone d'Orvieto",
      'Monteleone di Spoleto',
      'Monteleone di Puglia',
      'Montelapiano',
      'Montelanico',
      'Montelabbate',
      'Monteiasi',
      'Montegrotto Terme',
      'Montegrosso Pian Latte',
      'Montegrosso',
      'Monte Grimano',
      'Montegranaro',
      'Montegiorgio',
      'Montegiordano',
      'Monte Giberto',
      'Montegaldella',
      'Montegabbione',
      'Montefusco',
      'Montefredane',
      'Montefranco',
      'Montefortino',
      'Monteforte Irpino',
      "Monteforte d'Alpone",
      'Monteforte Cilento',
      'Monteflavio',
      'Montefiorino',
      "Montefiore dell'Aso",
      'Montefiore Conca',
      'Montefino',
      'Montefiascone',
      'Monteferrante',
      'Montefano',
      'Montefalcone di Val Fortore',
      'Montefalcone nel Sannio',
      'Montefalcone Appennino',
      'Montefalco',
      'Montefalcione',
      'Monte di Procida',
      'Montedinove',
      'Monte di Malo',
      'Montecosaro',
      'Montecorvino Rovella',
      'Montecorice',
      'Montecompatri',
      'Montecilfone',
      'Monteciccardo',
      'Montechiarugolo',
      "Montechiaro d'Asti",
      'Monte Cerignone',
      'Montecelio',
      'Montecchio Maggiore-Alte Ceccato',
      'Montecchio Emilia',
      'Montecchio',
      'Montecchia di Crosara',
      'Montecavolo',
      'Montecatini-Terme',
      'Montecatini Val di Cecina',
      'Montecastrilli',
      'Monte Castello di Vibio',
      'Montecassiano',
      'Montecarotto',
      'Montecarlo',
      'Montecalvoli',
      'Montecalvo Irpino',
      'Montecalvo in Foglia',
      'Montebuono',
      'Montebruno',
      'Montebelluna',
      'Montebello Vicentino',
      'Montebello sul Sangro',
      'Montebello di Bertona',
      'Montebello',
      'Montazzoli',
      'Montasola',
      'Montaquila',
      'Montappone',
      'Montano Antilia',
      'Montanaso Lombardo',
      'Montanaro',
      'Montanara',
      'Montalto Pavese',
      'Montalto Ligure',
      'Montalto Dora',
      'Montalto di Castro',
      'Montalto delle Marche',
      'Montalenghe',
      'Montale',
      'Montalcino',
      'Montalbano Jonico',
      'Montaione',
      'Montaguto',
      'Montagnana',
      'Montagna',
      'Montagano',
      'Montà',
      'Monsummano Terme',
      'Monselice',
      'Monsano',
      'Monsampolo del Tronto',
      'Monsampietro Morico',
      'Monopoli',
      'Monno',
      'Moniga del Garda',
      'Monguelfo',
      'Mongrando',
      'Mongiardino Ligure',
      'Monghidoro',
      'Mongardino',
      "Monforte d'Alba",
      'Monfalcone',
      'Monesiglio',
      'Moneglia',
      'Mondragone',
      'Mondovì',
      'Mondolfo',
      'Mondavio',
      'Moncucco Torinese',
      'Monclassico',
      'Monchio delle Corti',
      'Monchiero Borgonuovo',
      'Moncalvo',
      'Moncalieri',
      'Monastier di Treviso',
      'Monasterolo del Castello',
      'Monacilioni',
      'Mompeo',
      'Momo',
      'Mombaroccio',
      'Mombaldone',
      'Molveno',
      'Molteno',
      'Mollia',
      'Moliterno',
      'Molise',
      'Molino del Piano',
      'Molino del Pallone',
      'Mühlen in Taufers',
      'Molini di Triora',
      'Molini',
      'Molinella',
      'Molinara',
      'Molina di Ledro',
      'Molina Aterno',
      'Molfetta',
      'Molazzana',
      'Molare',
      'Mola di Bari',
      'Moio della Civitella-Pellare',
      'Moimacco',
      'Moie',
      'Moiano',
      'Mogliano Veneto',
      'Mogliano',
      'Moglia',
      'Moggio Udinese',
      'Moggio',
      'Moena',
      'Modugno',
      'Modolo',
      'Modigliana',
      'Modena',
      'Missanello',
      'Missaglia',
      'Misinto',
      "Misano di Gera d'Adda",
      'Misano Adriatico',
      'Mirano',
      'Mirandola',
      'Miranda',
      'Miradolo Terme',
      'Mirabello Sannitico',
      'Mirabello Monferrato',
      'Mirabello',
      'Mirabella Eclano',
      'Mira Taglio',
      'Minucciano',
      'Minturno',
      'Minori',
      'Minervino Murge',
      'Minervino di Lecce',
      'Minerbio',
      'Minerbe',
      'Millesimo',
      'Milis',
      'Milano Marittima',
      'Milan',
      'Milanere',
      'Mignano Monte Lungo',
      'Mignanego',
      'Miglionico',
      'Migliaro',
      'Migliarino',
      'Migliarina',
      'Miglianico',
      'Micigliano',
      'Miazzina',
      'Miano',
      'Miane',
      'Mezzomerico',
      'Mezzolombardo',
      'Mezzoldo',
      'Mezzolara',
      'Mezzogoro',
      'Mezzocorona',
      'Mezzano Inferiore',
      'Mezzano',
      'Mezzanino',
      'Mezzanego',
      'Mezzana Rabattone',
      'Mezzana Bigli',
      'Mezzana',
      'Meta',
      'Mestrino',
      'Mestre',
      'Mesola',
      'Mese',
      'Mesagne',
      'Merlara',
      'Merine',
      'Mergozzo',
      'Mergo',
      'Mereto di Tomba',
      'Mercogliano',
      'Mercenasco',
      'Mercato Vecchio',
      'Mercato Saraceno',
      'Mercato San Severino',
      'Mercato',
      'Mercatello sul Metauro',
      'Mercatale',
      'Mercallo',
      'Merate',
      'Merano',
      'Meolo',
      'Mentana',
      'Ruffrè',
      'Menconico',
      'Menaggio',
      'Melzo',
      'Meltina',
      'Melpignano',
      'Mello',
      'Melle',
      'Melizzano',
      'Melito Irpino',
      'Melito di Napoli',
      'Melfi',
      'Roccasecca Stazione',
      'Meleti',
      'Melendugno',
      'Melegnano',
      'Meldola',
      'Melara',
      'Mel',
      'Meina',
      'Megliadino San Vitale',
      'Meggiano',
      'Meduno',
      'Medolla',
      'Medole',
      'Medolago',
      'Mediis',
      'Medicina-Buda',
      'Medesano',
      'Mede',
      'Meda',
      'Mazzo di Valtellina',
      'Mazzin',
      'Mazze',
      'Mazzano Romano',
      'Mattinata',
      'Mattarello',
      'Matrice',
      'Matino',
      'Mathi',
      'Matera',
      'Matelica',
      'Massimeno',
      'Massignano',
      'Masserano',
      'Massenzatico',
      'Massello',
      'Massazza',
      'Massarosa',
      'Massa Martana',
      'Massa Marittima',
      'Massa Lubrense',
      'Massa Lombarda',
      'Massafra',
      'Massa Fiscaglia',
      'Massa Fermana',
      "Massa d'Albe-Corona",
      'Massa',
      'Masone',
      'Maslianico',
      'Masi-Torello',
      'Maserà di Padova',
      'Masera',
      'Maser',
      'Maschito',
      'Marzio',
      'Marzano di Nola',
      'Marzano Appio',
      'Marzabotto',
      'Maruggio',
      'Martis',
      'Martinsicuro',
      'Martiniana Po',
      'Martinengo',
      'Urbe',
      'Martina Franca',
      'Martignano',
      'Martignacco',
      'Marti',
      'Martellago',
      'La Martella',
      'Martano',
      'Marta',
      'Marsicovetere',
      'Marsico Nuovo',
      'Marsia',
      'Marsciano',
      'Marsaglia',
      'Marradi',
      'Marotta',
      'Marostica',
      'Marone',
      'Marola',
      'Marnate',
      'Marmirolo',
      'Marliana',
      'Marlengo',
      'Mariotto',
      'Moriondo Torinese',
      'Marino',
      'Marina di San Vito',
      'Marina di Ravenna',
      'Marina di Pisa',
      'Marina di Montemarciano',
      'Marina di Massa',
      'Marina di Grosseto',
      'Marina di Carrara',
      'Marina di Campo',
      'Marina di Andora',
      'Marigliano',
      'Mariglianella',
      'Mariano del Friuli',
      'Mariano Comense',
      'Margherita di Savoia',
      'Margarita',
      'Marene',
      'Mardimago',
      'Marcon-Gaggio-Colmello',
      'Marco',
      'Marcignana',
      'Marciano della Chiana',
      'Marcianise',
      'Marciana Marina',
      'Marciana',
      'Marcheno',
      'Marcetelli',
      'Marcellina',
      'Marcaria',
      'Marcallo',
      'Marano Vicentino',
      'Marano Ticino',
      'Marano sul Panaro',
      'Marano Lagunare',
      'Maranola-Trivio',
      'Marano Equo',
      'Marano di Valpolicella',
      'Marano di Napoli',
      'Maranello',
      'Mara',
      'Mapello',
      'Manziana',
      'Manzano',
      'Mantova',
      'Mantignana',
      'Manta',
      'Mansuè',
      'Manoppello',
      'Manocalzati',
      'Maniago',
      'Mango',
      'Manfredonia',
      'Manerbio',
      'Manduria',
      'Mandello del Lario',
      'Mandela',
      'Manciano',
      'Mamoiada',
      'Malvicino',
      'Malonno',
      'Malo',
      'Malnate',
      'Malmantile',
      'Malles Venosta',
      'Malgrate',
      'Malesco',
      'Maleo',
      'Malè',
      'Malcesine',
      'Malborghetto',
      'Malalbergo',
      'Maissana',
      'Mairano',
      'Maiori',
      'Serra di Maiolo',
      'Maiolati Spontini',
      'Magugnano',
      'Magreta',
      'Magomadas',
      'Magno',
      'Magnano in Riviera',
      'Magnago',
      'Magnacavallo',
      'Maglie',
      'Magliano Vetere',
      'Magliano Sabina',
      'Magliano Romano',
      'Magliano in Toscana',
      'Magliano di Tenna',
      "Magliano De'Marsi",
      'Magliano Alpi',
      'Magliano',
      'Magione',
      'Magenta',
      'Magasa',
      'Mafalda',
      'Maerne',
      'Maenza',
      "Madonna dell'Acqua",
      'Madignano',
      'Maddaloni',
      'Macugnaga',
      'Macomer',
      'Macere',
      'Macerata Feltria',
      'Macerata Campania',
      'Macerata',
      'Macchia Valfortore',
      'Macchiagodena',
      "Macchia d'Isernia",
      'Maccarese',
      'Maccagno',
      'Maccacari',
      'Luzzara',
      'Luzzano',
      'Luzzana',
      'Lustra',
      'Luson - Luesen',
      'Lusiana',
      'Lusia',
      'Lusevera',
      'Luserna',
      'Lurate Caccivio',
      'Luras',
      'Lurago Marinone',
      "Lurago d'Erba",
      'Lupara',
      'Luogosanto',
      'Luogosano',
      'Lungavilla',
      'Lunano',
      'Lumignano',
      'Lumellogno',
      'Lula',
      'Luino',
      'Lugugnana',
      'Stallavena-Lugo',
      'Lugo',
      'Lugnano in Teverina',
      "Lugagnano Val d'Arda",
      'Lugagnano',
      'Lucrezia',
      'Luco dei Marsi',
      'Lucito',
      'Montano Lucino',
      'Lucinasco',
      'Lucignano',
      'Lucera',
      'Lucca',
      'Lubriano',
      'Lu',
      'Lozzolo',
      'Lozzo di Cadore',
      'Lozzo Atestino',
      'Lovere',
      'Loseto',
      'Lorsica',
      'Loro Piceno',
      'Loro Ciuffenna',
      'Loria Bessica',
      'Loreto Aprutino',
      'Loreto',
      'Loreo',
      'Lorenzana',
      'Lorenzago di Cadore',
      'Loreggiola',
      'Loreggia',
      'Loranzè',
      'Lonigo',
      'Longone Sabino',
      'Longiano',
      'Longastrino',
      'Longarone',
      'Longare',
      'Longano',
      'Londa',
      'Lonato',
      'Lonate Pozzolo',
      'Lonate Ceppino',
      'Lomello',
      'Lombardore',
      'Lomazzo',
      'Lomagna',
      'Loiri Porto San Paolo',
      'Loiano',
      'Lograto',
      'Lodi Vecchio',
      'Lodine',
      'Lodi',
      'Lodè',
      'Loculi',
      'Locorotondo',
      'Locate Varesino',
      'Locate di Triulzi',
      'Locana',
      'Loano',
      'Lizzano in Belvedere',
      'Lizzano',
      'Lizzanello',
      'Livorno Ferraris',
      'Livorno',
      'Livo',
      'Livinallongo del Col di Lana',
      'Livigno',
      'Liveri',
      'Lissone',
      'Liscate',
      'Lioni',
      'Linate',
      'Limosano',
      'Limone sul Garda',
      'Limone Piemonte',
      'Limiti di Greccio',
      'Limite',
      'Limena',
      'Limbiate',
      'Limatola',
      'Lillianes',
      'Ligosullo',
      'Ligonchio',
      'Lignano Sabbiadoro',
      'Lignana',
      'Lierna',
      'Lido di Ostia',
      'Lido di Jesolo',
      'Lido',
      'Licusati',
      'Licenza',
      'Licciana Nardi',
      'Liberi',
      'Lezzeno',
      'Levico Terme',
      'Levice',
      'Leverano',
      'Levanto',
      'Levada',
      'Lettopalena',
      'Lettomanoppello',
      'Lestizza',
      'Lestans',
      'Lessona',
      'Lessolo',
      'Lesina',
      "Lesignano de'Bagni",
      'Lesegno',
      'Lesa',
      'Lerma',
      'Lerici',
      'Lequile',
      'Leporano',
      'Leonessa',
      'Lentigione-Sorbolo a Mane',
      'Lentiai',
      'Lentella',
      'Lentate sul Seveso',
      'Lenta',
      'Lenola',
      'Leno',
      'Lenno',
      'Lenna',
      'Lendinara',
      'Villa Lempa',
      'Lemie',
      'Leini',
      'Lei',
      'Le Grazie di Ancona',
      'Le Grazie',
      'Legnaro',
      'Legnano',
      'Legnago',
      'Leffe',
      'Lecco',
      'Lecce Nei Marsi',
      'Lecce',
      'Lazzeretto',
      'Lazise',
      'Lavis',
      'Laviano',
      'Lavezzola',
      'Lavenone',
      'Laveno',
      'Lavello',
      'La Valle Agordina',
      'Lavagna',
      'Lauzacco',
      'Lauro',
      'Laurito',
      'Laurino',
      'Lauriano',
      'Lauria',
      'Laurenzana',
      'Lauregno',
      'Laureana Cilento',
      'Lauco',
      'Latronico',
      'Latisana',
      'Latina',
      'Latiano',
      'La Thuile',
      'Laterza',
      'Laterina',
      'Latera',
      'Lastra a Signa',
      'Lastebasse',
      'La Spezia',
      'Lasino',
      'La Salle',
      'Lasa',
      'Larino',
      'Lariano',
      'Lari',
      'Lardirago',
      'Lardaro',
      "L'Aquila",
      'Lapio',
      'Lapedona',
      'Panighina',
      'Lanzo Torinese',
      "Lanzo d'Intelvi",
      'Lanuvio',
      'Langosco',
      'Langhirano',
      'Landriano',
      'Landiona',
      'Lanciano',
      'Lana',
      'Lamporo',
      'Lamporecchio',
      'La Morra',
      'Lamon',
      'Lama',
      'La Magdeleine',
      'Lama dei Peligni',
      'Selci-Lama',
      'La Loggia',
      'Lallio',
      'Laives',
      'Laion',
      'Lainate',
      'Laigueglia',
      'Lajatico',
      'Lagundo',
      'Lagosanto',
      'Lagonegro',
      'Laglio',
      'Giudecca',
      'Laghetti',
      'Laerru',
      'Ladispoli',
      'Laces',
      'Lacedonia',
      'Lacco Ameno',
      'Lacchiarella',
      'Casinina',
      'Cappelletta',
      'Labro',
      'Labico',
      'Izano',
      'Ivrea',
      'Ittiri',
      'Ittireddu',
      'Itri',
      'Istrana',
      "Istia D'Ombrone-Le Stiacciole",
      'Issogne',
      'Issime',
      'Ispra',
      'Isorella',
      'Isola Vicentina',
      "Isola Sant'Antonio",
      'Isola Dovarese',
      'Isola del Piano',
      'Isola del Liri',
      'Isola della Scala',
      "Isola del Gran Sasso d'Italia",
      'Isola del Cantone',
      'Isolabona',
      "Isola D'Arbia",
      'Isernia',
      'Iseo',
      'Ischitella',
      'Ischia Porto',
      'Ischia di Castro',
      'Ischia',
      'Irsina',
      'Irgoli',
      'Jolanda di Savoia',
      'Inzago',
      'Invorio',
      'Inveruno',
      'Inverno',
      'Inverigo',
      'Introdacqua',
      'Introbio',
      'Pallanza-Intra-Suna',
      'Ingria',
      'Induno Olona',
      "Incisa in Val d'Arno",
      'Impruneta',
      'Imperia',
      'Imola',
      'Illorai',
      'Illasi',
      'Capitello',
      'Barba',
      'Iesolo',
      'Jesi',
      'Jelsi',
      'Ialmicco',
      'Borgo Hermada',
      'Gussola',
      'Gussago',
      'Guilmi',
      'Guiglia',
      'Guidonia',
      'Guidizzolo',
      'Guglionesi',
      'Gubbio',
      'Guazzora',
      'Guazzino',
      'Guasticce',
      'Guastalla',
      'Guardistallo',
      'Guardia Sanframondi',
      'Guardiaregia',
      'Guardia Perticara',
      'Guardia Lombardi',
      'Guardialfiera',
      'Guardiagrele',
      'Guardea',
      'Guarda Veneta',
      'Guardamiglio',
      'Guarcino',
      'Gualtieri',
      'Gualdo Tadino',
      'Gualdo Cattaneo',
      'Guagnano',
      'Grumo Nevano',
      'Grumolo Pedemonte',
      'Grumolo delle Abbadesse',
      'Grumo Appula',
      'Grumes',
      'Grumento Nova',
      'Grumello del Monte',
      'Grumello Cremonese',
      'Grugliasco',
      'Gruaro',
      'Grottolella',
      'Grottole',
      'Grotte Santo Stefano',
      'Grotte di Castro',
      'Grottazzolina',
      'Grottammare',
      'Grottaminarda',
      'Grottaglie',
      'Grottaferrata',
      'Grosseto',
      'Grosotto',
      'Grosio',
      'Groscavallo',
      'Gropparello',
      'Gropello Cairoli',
      'Grontardo',
      'Gromo',
      'Gromlongo',
      'Grizzana Morandi',
      'Grisignano di Zocco',
      'Grigno',
      'Grignasco',
      'Gricignano di Aversa',
      'Griante',
      'Grezzana',
      'Greve in Chianti',
      'Gressoney-Saint-Jean',
      'Gressoney-La-Trinitè',
      'Gressan',
      'Fontane-Zurane-Gresine',
      'Greggio',
      'Greci',
      'Grazzano Badoglio',
      'Grazzanise',
      'Gravina in Puglia',
      'Gravellona Toce',
      'Gravellona',
      'Gravedona-San Gregorio',
      'Cravagliana',
      'Grauno',
      'Gratacasolo',
      'Grassano',
      'Granze',
      'Grantorto',
      'Granozzo con Monticello',
      'Grandate',
      'Gragnano Trebbiense',
      'Gragnano',
      'Graglia',
      'Graffignano',
      'Gradoli',
      'Grado',
      "Gradisca d'Isonzo",
      'Gradara',
      'Gozzano',
      'Gottolengo',
      'Gossolengo',
      'Gosaldo',
      'Gorzegno',
      'Gorreto',
      'Goro',
      'Gorno',
      'Gorle',
      'Gorla Maggiore',
      'Gorizia',
      'Goriano Sicoli',
      'Gorgonzola',
      'Gorgoglione',
      'Gorga',
      'Gordona',
      'Gonzaga',
      'Gonars',
      'Golfo Aranci',
      'Golasecca',
      'Goito',
      'Godo',
      'Godiasco',
      'Godega',
      'Glorenza',
      'Giussano',
      'Giussago',
      'Giurdignano',
      'Giungano',
      'Giulianova',
      'Giuliano Teatino',
      'Giuliano di Roma',
      'Giulianello',
      'Giugliano in Campania',
      'Giuggianello',
      'Gissi',
      'Giovinazzo',
      'Giove',
      'Gioia Sannitica',
      'Gioia del Colle',
      'Gioia dei Marsi',
      'Gioi',
      'Marina di Ginosa',
      'Ginosa',
      'Ginestra degli Schiavoni',
      'Ginestra Fiorentina',
      'Ginestra',
      'Gildone',
      'Gignese',
      'Giglio Castello',
      'Giffoni Valle Piana',
      'Giavera del Montello',
      'Giaveno',
      'Giave',
      'Giarole',
      'Giano Vetusto',
      "Giano dell'Umbria",
      'Piano di Coreglia-Ghivizzano',
      'Ghislarengo',
      'Ghisalba',
      'Ghilarza',
      'Ghiffa',
      'Ghemme',
      'Ghedi',
      'Gesualdo',
      'Gessopalena',
      'Quartiano',
      'Gerosa',
      'Gerola Alta',
      'Germagnano',
      'Gerenzago',
      'Gerano',
      'Gera Lario',
      'Genzone',
      'Genzano di Roma',
      'Genzano di Lucania',
      'Genoa',
      'Genola',
      'Genivolta',
      'Genga',
      'Genazzano',
      'Gemonio',
      'Gemona',
      'Gemmano',
      'Gazzuolo',
      'Gazzo',
      'Gazzaniga',
      'Gazoldo degli Ippoliti',
      'Gavorrano',
      'Gavoi',
      'Gavirate',
      'Gavignano',
      'Gavi',
      'Gavello',
      'Gavardo-Sopraponte',
      'Gattinara',
      'Gassino Torinese',
      'Garzeno',
      'Garlenda',
      'Garlasco',
      'Gargnano',
      'Gargazzone',
      'Garessio',
      'Gardone Val Trompia',
      'Gardone Riviera',
      'Garda',
      'Garbagnate Milanese',
      'Garaguso',
      'Garadassi',
      'Ganna',
      'Gandino',
      'Ganda',
      'Gambolò',
      'Gambettola',
      'Gamberale',
      'Gambatesa',
      'Gambassi Terme',
      'Gambasca',
      'Gambarana',
      'Gambara',
      'Gamalero',
      'Galzignano',
      'Galugnano',
      'Galtellì',
      'Galluccio',
      'Gallo',
      'Gallo Matese',
      'Gallipoli',
      'Gallio',
      'Gallinaro',
      'Galliera Veneta',
      'Gallicchio',
      'Gallicano nel Lazio',
      'Gallicano',
      'Galliate',
      'Gallese',
      'Gallarate',
      'Galeata',
      'Galbiate',
      'Galatone',
      'Galatina',
      'Gais',
      'Gaiole in Chianti',
      'Gaiola',
      "Gaibanella-Sant'Edigio",
      'Gaiba',
      'Gaiarine',
      'Gagliole',
      'Gagliano Aterno',
      'Galliano',
      'Gaglianico',
      'Gaggio Montano',
      'Gaggio',
      'Gaggiano',
      'Gaeta',
      'Gaby',
      'Gabicce Mare',
      'Gabiano',
      'Futani',
      'Fusine',
      'Fusignano',
      'Furore',
      'Furci',
      'Fuorigrotta',
      'Fumone',
      'Fumane',
      'Fucecchio',
      'Fubine',
      'Frugarolo',
      'Frosolone',
      'Frosinone',
      'Frontone',
      'Frontino',
      'Front',
      'Frisanco',
      'Frisa',
      'Frigento',
      'Fresagrandinaria',
      'Fregona',
      'Fregene',
      'Fratta Todina',
      'Fratta Polesine',
      'Frattaminore',
      'Frattamaggiore',
      'Frasso Telesino',
      'Frasso Sabino',
      'Frassinoro',
      'Frassino',
      'Frassinetto',
      'Frassineto Po',
      'Frassinello Monferrato',
      'Frascati',
      'Frascaro',
      'Francolise',
      'Francolino',
      'Francavilla in Sinni',
      'Francavilla Fontana',
      "Francavilla d'Ete",
      'Francavilla al Mare',
      'Framura',
      'Fraine',
      'Fragneto Monforte',
      "Fragneto L'Abate",
      'Fragagnano',
      'Frabosa Soprana',
      'Foza',
      'Fossombrone',
      'Fossato di Vico',
      'Fossano',
      'San Vendemiano-Fossamerlo',
      'Fossalunga',
      'Fossalto',
      'Fossalta di Portogruaro',
      'Fossalta di Piave',
      'Fossacesia',
      'Fossa',
      'Fosdinovo',
      'Fortunago',
      'Fortezza',
      'Forte dei Marmi',
      'Fornovo San Giovanni',
      'Fornovo di Taro',
      'Forno di Zoldo',
      'Forno Canavese',
      'Forni di Sotto',
      'Forni di Sopra',
      'Forni Avoltri',
      'Fornelli',
      'Fornaci di Barga',
      'Fornaci',
      'Fornacette',
      'Fornacelle',
      'Formignana',
      'Formigliana',
      'Formigine',
      'Formigara',
      'Formicola',
      'Formia',
      'Formello',
      'Formazza',
      'Forlimpopoli',
      'Forlì del Sannio',
      'Forlì',
      'Forio',
      'Forino',
      'Forenza',
      'Forcoli',
      'Forchia',
      'Forano',
      'Foppolo',
      'Fonzaso',
      'Fontignano',
      'Fontevivo',
      'Fontegreca',
      'Fontechiari',
      'Fontecchio',
      'Fonteblanda',
      'Fontaniva',
      'Fontanile',
      'Fontanigorda',
      'Fontanetto Po',
      "Fontaneto D'Agogna",
      'Fontanelle',
      'Fontanellato',
      'Fontanella',
      'Fontanelice',
      'Fontanarosa',
      'Collefontana-Fontana Liri Inferiore',
      'Fontana Nuova-Bevia',
      'Monticelli-Fontana',
      'Fonni',
      'Fondo',
      'Fondi',
      'Fombio',
      'Follonica',
      'Follina',
      'Foligno',
      'Folignano',
      'Folgaria',
      'Foiano di Val Fortore',
      'Foiano della Chiana',
      'Fognano',
      'Foglizzo',
      'Fogliano',
      'Foglianise',
      'Foggia',
      'Fobello',
      'Flussio',
      'Flumeri',
      'Florinas',
      'Flero',
      'Flavon',
      'Flaibano',
      'Fivizzano',
      'Fiumicino',
      'Fiume Veneto',
      'Fiumana',
      'Fiumalbo-Dogana',
      'Fiuggi',
      'Fisciano',
      'Firenzuola',
      'Florence',
      "Fiorenzuola d'Arda",
      'Fiorano',
      'Fino Mornasco',
      'Finale Ligure',
      'Finale Emilia',
      'Filottrano',
      'Filignano',
      'Filighera',
      'Filiano',
      'Filettole',
      'Filetto',
      'Filettino',
      'Filacciano',
      'Figline Valdarno',
      'Fiesso Umbertiano',
      'Fiesse',
      'Fiesole',
      'Fiera di Primiero',
      'Fiè Allo Sciliar',
      'Fidenza',
      'Ficulle',
      'Ficarolo',
      'Fiavè',
      'Trebbio',
      'Fiano Romano',
      'Fiano',
      'Fiamignano',
      'Ferriere',
      'Ferrera Erbognone',
      'Ferrazzano',
      'Ferrara di Monte Baldo',
      'Ferrara',
      'Ferrandina',
      'Ferrada',
      'Ferno',
      'Fermo',
      'Fermignano',
      'Ferentino',
      'Ferentillo',
      'Fenestrelle',
      'Feltre',
      'Felizzano',
      'Felino',
      'Feletto Umberto',
      'Feletto',
      'Felegara',
      'Favria',
      'Favale di Malvaro',
      'Fauglia',
      'Fascia',
      'Fasano',
      'Farra di Soligo',
      "Farra d'Alpago",
      'Farnese',
      'Farini',
      'Farindola',
      'Fardella',
      'Fara Vicentino',
      'Fara San Martino',
      'Fara Novarese',
      'Fara in Sabina',
      "Fara Gera d'Adda",
      'Fara Filiorum Petri',
      'Fanzolo',
      'Fano Adriano',
      'Fano',
      'Fanna',
      'Fanano',
      'Falzes',
      'Falzè di Piave',
      'Falvaterra',
      'Falmenta',
      'Fallo',
      'Falerone',
      'Faleria',
      'Falconara Marittima',
      'Falcade Alto',
      'Fai della Paganella',
      'Faicchio',
      'Faiano',
      'Fagnano Olona-Bergoro',
      'Faggiano',
      'Fagagna',
      'Faeto',
      'Faenza',
      'Faedo Valtellino',
      'Faedis',
      'Fabro',
      'Fabrica di Roma',
      'Fabriano',
      'Fabbrico',
      'Fabbriche di Vallico',
      'Exilles',
      'Etroubles',
      'Este',
      'Esperia',
      'Esino Lario',
      'Esine',
      'Esanatoglia',
      'Erula',
      'Erto',
      'Erli',
      'Ercolano',
      'Erchie',
      'Erbezzo',
      'Erbè',
      'Erba',
      'Eraclea',
      'Episcopia',
      'Envie',
      'Entracque',
      'Enemonzo-Quinis',
      'Endine',
      'Empoli',
      'Emarese',
      'Elice',
      'Egna',
      'Edolo',
      'Eboli',
      'Dusino',
      'Duronia',
      'Durazzano',
      'Dumenza',
      'Duino',
      'Dugenta',
      'Dueville',
      'Dubino',
      'Dualchi',
      'Druogno',
      'Druento',
      'Dronero',
      'Dro',
      'Drena',
      'Dragoni',
      'Dozza',
      'Dovera',
      'Dovadola',
      'Dosson',
      'Dossobuono',
      'Dosso',
      'Dosolo',
      'Dosimo',
      'Dorno',
      'Dorio',
      'Dorgali',
      'Donzella',
      'Dongo',
      'Domodossola',
      'Domicella',
      'Domegge di Cadore',
      'Domaso',
      'Dolo',
      'Dolcedo',
      'Dolceacqua',
      'Dolcè',
      'Dogna',
      'Dogliola',
      'Dogliani',
      'Doberdò del Lago',
      'Dobbiaco',
      'Dizzasco-Biazzeno',
      'Divignano',
      'Dimaro',
      'Dignano',
      'Dicomano',
      'Diano San Pietro',
      'Diano Marina',
      "Diano d'Alba",
      'Diano Castello',
      'Desio',
      'Desenzano del Garda',
      'Desana',
      'Dervio',
      'Deruta',
      'Taio',
      'Denno',
      'Demonte',
      'Dello',
      'Deliceto',
      'Delebio',
      'Deiva Marina',
      'Dego',
      'Decima',
      'Dazio',
      'Darfo',
      'Daone',
      'Danta',
      'Dambel',
      'Dalmine',
      'Dairago',
      'Cutrofiano',
      'Cutigliano',
      'Cusio',
      'Cusercoli',
      'Cusano Mutri',
      'Cusano',
      'Curtarolo',
      'Cursi',
      'Curon Venosta',
      'Curno',
      'Curiglia',
      'Cupramontana',
      'Cupra Marittima',
      'Cupello',
      'Cuorgnè',
      'Cunico',
      'Cuneo',
      'Cumignano sul Naviglio',
      'Cumiana',
      'Cugnoli',
      'Cuglieri',
      'Cuggiono',
      'Cuceglio',
      'Cuccurano',
      'Cuccaro Vetere',
      'Crugnola',
      'Croviana',
      'Crova',
      "Crotta d'Adda",
      'Maglione-Crosa',
      'Crone',
      'Crognaleto',
      'Crodo',
      'Crocetta del Montello',
      'Crocefieschi',
      'Crissolo',
      'Crispiano',
      'Crevalcore',
      'Crevacuore',
      'Cretone',
      'Creto',
      'Cressa',
      'Crespino',
      'Crespina',
      'Crespiatica',
      'Crespellano',
      'Crespano del Grappa',
      'Crespadoro',
      'Crescentino',
      'Cremona',
      'Cremolino',
      'Cremeno',
      'Crema',
      'Credera',
      'Crecchio',
      'Creazzo',
      'Craveggia',
      'Cravanzana',
      "Craco-Sant'Angelo",
      'Cozzo',
      'Cozzana',
      'Covo',
      'Courmayeur',
      'Cottanello',
      'Cotignola',
      'Costigliole Saluzzo',
      "Costigliole d'Asti",
      'Costermano',
      'Costa Volpino',
      'Costa Valle Imagna',
      "Costa Sant'Abramo",
      'Costarainera',
      'Costanzana',
      'Costano',
      'Costa di Rovigo',
      'Costa di Mezzate',
      'Costacciaro',
      'Costabissara',
      'Cossombrato',
      'Cossoine',
      'Cossignano',
      'Cossato',
      'Coseano',
      'Corvaro',
      'Corvara in Badia',
      'Corvara',
      'Cortona',
      "Cortina d'Ampezzo",
      'Cortiglione',
      'Corteolona',
      'Cortemilia',
      'Cortemaggiore',
      'Corte Franca',
      "Corte de' Cortesi",
      'Corte',
      'Cortazzone',
      'Cortanze',
      'Cortandone',
      'Cortaccia sulla Strada del Vino',
      'Corsico',
      'Corropoli',
      'Corridonia',
      'Correzzola',
      'Correggio',
      'Corpolò',
      'Cornuda',
      'Corno Giovine',
      'Corno di Rosazzo',
      'Corniglio',
      'Cornedo Vicentino',
      "Cornate d'Adda",
      'Cornaredo',
      'Cornale',
      'Cornalba',
      'Cornaiano',
      'Cormons',
      'Cormano',
      'Corleto Perticara',
      'Corleto Monforte',
      'Corio',
      'Corinaldo',
      "Corigliano d'Otranto",
      'Coriano',
      'Cori',
      'Corgeno',
      'Corfinio',
      'Coreglia Antelminelli',
      'Coredo',
      'Cordovado',
      'Cordignano',
      'Cordenons',
      'Corcolle',
      'Corciano',
      'Corchiano',
      'Corbola',
      'Corbetta',
      'Corbara',
      'Corbanese',
      'Corato',
      'Corana',
      'Coppito',
      'Copparo',
      'Copiano',
      'Copertino',
      'Nuova Conza della Campania',
      'Conversano',
      'Contursi Terme',
      'Controne',
      'Controguerra',
      'Contrada',
      'Contigliano',
      'Conselve',
      'Conselice',
      'Conscio',
      'Consandolo',
      'Configni',
      'Confienza',
      'Conegliano',
      'Condove',
      'Condino',
      'Concorezzo',
      'Concordia sulla Secchia',
      'Concordia Sagittaria',
      'Conco',
      'Concerviano',
      'Conca della Campania',
      'Conca dei Marini',
      'Conca Casale',
      'Comun Nuovo',
      'Comunanza',
      'Compiobbi',
      'Compiano',
      'Como',
      'Commessaggio',
      'Comiziano',
      'Cizzago-Comezzano',
      'Comerio',
      'Comeglians',
      'Comeana',
      'Comazzo',
      'Comacchio',
      'Comabbio',
      'Colturano',
      'Colorno',
      'Colonnella',
      'Colonna',
      'Colombaro-Timoline',
      'Colombaro',
      'Cologno Monzese',
      'Cologno al Serio',
      'Cologne',
      'Cologna Veneta',
      'Colobraro',
      'Colmurano',
      'Colloredo di Prato',
      'Collobiano',
      'Colli sul Velino',
      'Collio',
      'Colli del Tronto',
      'Colliano',
      'Colli a Volturno',
      'Collevecchio',
      'Colletorto',
      'Colle Santa Lucia',
      'Colle Sannita',
      'Colle San Magno',
      'Collesalvetti',
      'Collepietro',
      'Collepepe',
      'Collepasso',
      'Collepardo',
      'Collemeto',
      'Collelongo',
      'Colle Isarco',
      'Collegno',
      'Collegiove',
      'Colleferro',
      "Colle di Val d'Elsa",
      'Colle di Tora',
      'Colledimezzo',
      'Colledimacine',
      'Colle di Fuori',
      'Colledara',
      "Colle d'Anchise",
      'Collecorvino',
      'Collecchio',
      'Collazzone',
      'Collarmele',
      'Collalto Sabino',
      'Collalbo',
      'Collagna',
      'Colico Piano',
      'Coli',
      'Colere',
      'Coldragone',
      'Colbordolo',
      'Colà',
      'Cogolo',
      'Cogollo del Cengio',
      'Cogoleto',
      'Cogne',
      'Coggiola',
      'Codrongianos',
      'Codroipo',
      'Codogno',
      'Codognè',
      'Codisotto',
      'Codigoro',
      'Codevilla',
      'Codevigo',
      'Codemondo-Quaresimo',
      'Codaruina',
      'Cocullo',
      'Cocquio',
      'Cocconato',
      'Coccaglio',
      'Coazze',
      'Clusone',
      'Clusane',
      'Cles',
      'Claviere',
      'Clauzetto',
      'Classe',
      'Cizzago',
      'Civitella San Paolo',
      'Civitella Roveto',
      'Civitella Messer Raimondo',
      'Civitella Marittima',
      'Civitella in Val di Chiana',
      'Civitella di Romagna',
      'Civitella del Tronto',
      "Civitella d'Agliano",
      'Civitella Casanova',
      'Civitella Alfedena',
      'Civitavecchia',
      'Civitaquana',
      'Civitanova del Sannio',
      'Civitanova Alta',
      'Civitaluparella',
      "Civita d'Antino",
      'Civita Castellana',
      'Civitacampomarano',
      'Cividate Camuno',
      'Cividate al Piano',
      'Cividale del Friuli',
      'Civezzano',
      'Civezza',
      'Cittiglio',
      "Città Sant'Angelo",
      'Cittareale',
      'Cittaducale',
      'Città di Castello',
      'Città della Pieve',
      'Cittadella',
      'Citerna',
      'Cisternino',
      'Cisterna di Latina',
      "Cisterna d'Asti",
      'Cison di Valmarino',
      'Cismon del Grappa',
      'Cisliano',
      'Cislago',
      'Ciserano',
      'Cisano',
      'Cisano Bergamasco',
      'Cirimido',
      'Cirigliano',
      'Ciriè',
      'Circello',
      'Cipressa',
      'Ciorlano',
      'Cinzano',
      'Cinto Caomaggiore',
      'Cinte Tesino',
      'Cintano',
      'Capanne-Prato-Cinquale',
      'Cinisello Balsamo',
      'Cinigiano',
      'Cingoli',
      "Cingia de' Botti",
      'Cineto Romano',
      'Cimolais',
      'Cimitile',
      'Cimego',
      'Cimbergo',
      'Cimadolmo',
      'Cilavegna',
      'Cigole',
      'Cigliano',
      'Cicognolo',
      'Ciciliano',
      'Cicerale',
      'Cicciano',
      'Cicagna',
      'Cibiana',
      "Ciano d'Enza",
      "Sant'Andrea-Pizzone-Ciamprisco",
      'Ciampino',
      'Chivasso',
      'Chiusi della Verna',
      'Chiusi',
      'Chiusdino',
      'Chiusavecchia',
      'Chiusano di San Domenico',
      'Chiusanico',
      'Chiusaforte',
      'Chiusa di Pesio',
      'Chiusa',
      'Chiuro',
      'Chiuppano',
      'Chitignano',
      'Chions',
      'Chiomonte',
      'Chioggia',
      'Chieuti',
      'Chieti',
      'Chiesina Uzzanese',
      'Chiesa in Valmalenco',
      'Gionghi-Cappella',
      'Chieri',
      'Chienes',
      'Chiavenna',
      'Chiavari',
      'Chiauci',
      'Chiaromonte',
      'Chiari',
      'Chiaravalle',
      'Chiarano',
      'Chiaramonti',
      'Chianni',
      'Chianciano Terme',
      'Chianche',
      'Chiampo',
      'Chialamberto',
      'Cheremule',
      'Cherasco',
      'Chatillon',
      'Charvensod',
      'Champdepraz',
      'Chambave',
      'Cevo',
      'Ceva',
      'Cetona',
      'Ceto',
      'Cetara',
      'Coccanile-Cesta',
      'Cessole',
      'Cessapalombo',
      'Cessalto',
      'Cesiomaggiore',
      'Cesio',
      'Cesenatico',
      'Cesena',
      'Cesarolo',
      'Porto Cesareo',
      'Cesara',
      'Cesano Maderno',
      'Cesano',
      'Cesana Torinese',
      'Cesa',
      'Cervo',
      'Cervino',
      'Cervignano del Friuli',
      'Cervia',
      'Cerveteri',
      'Cervesina',
      'Cervere',
      'Cerveno',
      'Cervaro',
      'Cervara di Roma',
      'Certosa di Pavia',
      'Certosa',
      'Certaldo',
      'Cersosimo',
      'Cerro Maggiore',
      'Cerro al Volturno',
      'Cerrione',
      'Cerreto Sannita',
      'Cerreto Laziale',
      'Cerreto Guidi',
      'Cerreto di Spoleto',
      "Cerreto d'Esi",
      'Cerratina',
      'Cernusco sul Naviglio',
      'Cernobbio',
      'Cermignano',
      'Cermes',
      'Cermenate',
      'Cerlongo',
      'Cerignola',
      'Ceriano Laghetto',
      'Ceriana',
      'Ceriale',
      'Cergnago',
      'Cerfignano',
      'Cerete Alto',
      'Ceresole Reale',
      'Cereseto',
      'Cerese',
      'Ceresara',
      'Ceres',
      'Ceregnano',
      'Cerea',
      'Cercola',
      'Cercino',
      'Cerchio',
      'Cercepiccola',
      'Cercemaggiore',
      'Cerbaia',
      'Cerasolo',
      'Ceraso',
      'Cerano',
      'Ceprano',
      'Ceppaloni',
      'Ceparana-Carpena',
      'Cepagatti',
      'Centrale',
      'Centola',
      'Cento',
      'Centa San Nicolò',
      'Centallo',
      'Cengio Alto',
      'Ceneselli',
      'Cene',
      'Cencenighe Agordino',
      'Cenaia',
      'Cembra',
      'Cellole',
      'Cellio',
      'Cellino San Marco',
      'Cellino Attanasio',
      'Celle di San Vito',
      'Cellere',
      'Celleno',
      'Celle Ligure',
      'Celle di Bulgheria',
      'Cellamare',
      'Celenza Valfortore',
      'Celenza sul Trigno',
      'Celano',
      'Ceglie Messapica',
      'Ceggia',
      'Cedrasco',
      'Cedegolo',
      'Cecina',
      'Cecchini',
      'Cecchina',
      'Ceccano',
      "Cazzano Sant'Andrea",
      'Cazzago San Martino-Calino',
      'Cavriglia-Monastero',
      'Cavriana',
      'Cavriago',
      'Cavour',
      'Cavolano-Schiavoi',
      'Cavezzo',
      'Cavenago di Brianza',
      "Cavenago d'Adda",
      'Cavedago',
      'Cave',
      'Cavazzo Carnico',
      'Cavasso Nuovo',
      'Cavarzere',
      'Cavaria Con Premezzo',
      'Cavargna',
      'Cavareno',
      'Cava Manara',
      'Cavallirio',
      'Cavallino',
      'Cavallermaggiore',
      'Cavalese',
      'Cavajon Veronese',
      'Cavaglià',
      'Cava Dè Tirreni',
      'Cavacurta',
      'Cautano',
      'Cattolica',
      'Catignano',
      "Ca' Tiepolo",
      'Cataeggio',
      'Castropignano',
      "Castronuovo di Sant'Andrea",
      'Castronno',
      'Castro dei Volsci',
      'Castrocielo',
      'Castrocaro Terme',
      'Castro',
      "Castrignano De' Greci",
      'Castri di Lecce',
      'Castorano',
      'Casto',
      'Castions di Strada',
      'Castions',
      'Castione della Presolana',
      'Castino',
      'Castilenti',
      'Castignano',
      'Castiglion Fiorentino',
      'Castiglion Fibocchi',
      'Castiglione Olona',
      'Castiglione Messer Raimondo',
      'Castiglione Messer Marino',
      'Castiglione in Teverina',
      "Castiglione d'Orcia",
      "Castiglione d'Intelvi",
      'Castiglione di Garfagnana',
      'Castiglione delle Stiviere',
      'Castiglione della Pescaia',
      'Castiglione del Lago',
      'Castiglione dei Pepoli',
      'Castiglione del Genovesi',
      "Castiglione d'Adda",
      'Castiglione a Casauria',
      'Castenedolo',
      'Castenaso',
      'Castel Volturno',
      'Castel Vittorio',
      'Castel Viscardo',
      'Castelvetro Piacentino',
      'Castelvetro di Modena',
      'Castelvetere sul Calore',
      'Castelvetere in Val Fortore',
      'Castelverrino',
      'Castelverde',
      'Castelvenere',
      'Castelvecchio Subequo',
      'Castelvecchio Calvisio',
      'Casteltodino',
      'Castelseprio',
      'Castelsardo',
      'Castelsaraceno',
      'Castel San Vincenzo',
      "Castel Sant'Elia",
      'Castelsantangelo sul Nera',
      "Castel Sant'Angelo",
      'Castel San Pietro Terme',
      'Castel San Pietro Romano',
      'Castel San Niccolò',
      'Castel San Lorenzo',
      'Castel San Giovanni',
      'Castel San Giorgio',
      'Castelrotto',
      'Castel Ritaldi',
      'Castelraimondo',
      'Castelpoto',
      'Castelplanio',
      'Castelpizzuto',
      'Castelpetroso',
      'Castelpagano',
      'Castelnuovo Scrivia',
      'Castelnuovo Rangone',
      'Castelnuovo Parano',
      'Castelnuovo Nigra',
      'Castelnuovo Don Bosco',
      'Castelnuovo di Val di Cecina',
      'Castelnuovo di Porto',
      'Castelnuovo Magra',
      'Castelnuovo di Garfagnana',
      'Castelnuovo di Farfa',
      'Castelnuovo di Conza',
      'Castelnuovo dei Sabbioni',
      'Castelnuovo della Daunia',
      'Castelnuovo Cilento',
      'Castelnuovo Bormida',
      "Castelnuovo Bocca d'Adda",
      'Castelnuovo Berardenga',
      'Castelnuovo',
      'Castelnuovo Calcea',
      "Castelnovo ne'Monti",
      'Castelnovo di Sotto',
      'Castelnovetto',
      'Castelmezzano',
      'Castel Mella',
      'Castelmauro',
      'Castelmassa',
      'Campomolino',
      'Castel Maggiore',
      'Castel Madama',
      'Castelluccio Valmaggiore',
      'Castelluccio Superiore',
      'Castellucchio',
      'Castello Tesino',
      'Castello di Godego',
      'Castello Molina di Fiemme',
      'Castello di Annone',
      "Castello dell'Acqua",
      'Aviano-Castello',
      "Castello d'Argile",
      'Castello',
      'Castelliri',
      'Castellino del Biferno',
      'Castellina Marittima',
      'Castellina in Chianti',
      'Castelli Calepio',
      'Castelli',
      'Castelletto Uzzone',
      'Castelletto Sopra Ticino',
      'Castelleone',
      'Castellazzo Novarese',
      'Castellazzo Bormida',
      "Castell'Azzara",
      "Castell'Arquato",
      'Castellaro',
      'Castellar Guidobono',
      'Castellarano',
      'Castellanza',
      'Castellaneta',
      'Castellana',
      'Castellamonte',
      'Castellammare di Stabia',
      'Castellalto',
      "Castell'Alfero",
      'Castellafiume',
      'Castelguidone',
      'Castelguglielmo',
      'Castel Guelfo di Bologna',
      'Castelgrande',
      'Castelgomberto',
      'Castel Goffredo',
      'Castel Giorgio',
      'Castel Gandolfo',
      'Castel Gabbiano',
      'Castel Fusano',
      'Castel Frentano',
      'Castelfranco Veneto',
      'Castelfranco in Miscano',
      'Castelfranco Emilia',
      'Castelfranco di Sotto',
      'Castelfranco di Sopra',
      'Castelfranci',
      'Castelforte',
      'Castelfondo',
      'Castelfiorentino',
      'Castelfidardo',
      'Castel di Tora',
      'Castel di Sangro',
      'Castel di Ieri',
      'Casteldidone',
      'Castel di Casio',
      'Castel del Rio',
      'Castel del Piano',
      'Castel del Monte',
      'Castel del Giudice',
      'Casteldelfino',
      "Castel d'Ario",
      "Castel d'Aiano",
      'Castelcucco',
      'Castel Condino',
      'Castelcivita',
      'Castel Chiodato',
      'Castel Campagnano',
      'Castelbottaccio',
      'Castel Bolognese',
      'Castelbello-Ciardes - Kastelbell-Tschars',
      'Castelbellino',
      'Castel Baronia',
      'Castelbaldo',
      'Castegnato',
      'Casteggio',
      'Castano Primo',
      'Castagnole Monferrato',
      'Castagnole delle Lanze',
      'Castagnole',
      'Castagnito',
      'Castagneto Po',
      'Castagneto Carducci',
      'Castagnaro',
      'Cassolnovo',
      'Cassino',
      'Cassine',
      'Cassinasco',
      "Cassina de' Pecchi",
      'Cassiglio',
      'Cassano Spinola',
      'Cassano delle Murge',
      "Cassano d'Adda",
      'Cassacco',
      'Caspoggio',
      'Casperia',
      'Casorzo',
      'Casoria',
      'Casorezzo',
      'Casorate Sempione',
      'Casorate Primo',
      'Casoni',
      'Casoli',
      "Casole d'Elsa",
      'Casola Valsenio',
      'Casola in Lunigiana',
      'Casola di Napoli',
      'Casnigo',
      'Casarile',
      "Casirate d'Adda",
      'Casine',
      'Casina',
      'Caserta',
      'Pieve al Toppo',
      'Caselle Torinese',
      'Caselle Lurani',
      'Caselle in Pittari',
      'Caselle',
      'Casella',
      'Caselette',
      'Casei',
      "Cascinette d'Ivrea",
      'Cascina',
      'Casciana Terme',
      'Cascia',
      'Cascano',
      'Casazza',
      'Casatisma',
      'Casatenovo',
      'Casasco',
      'Casarsa della Delizia',
      'Casargo',
      'Casapulla',
      'Casaprota',
      'Casape',
      'Casanova Elvo',
      'Casanova',
      'Casandrino',
      'Semonte-Casamorcia',
      'Casamassima',
      'Casamassella',
      'Casamarciano',
      'Casalzuigno',
      'Casalvolone',
      'Casalvieri',
      'Casal Velino',
      'Casalvecchio di Puglia',
      'Casaluce',
      'Casalserugo',
      'Casalromano',
      'Casalpusterlengo',
      'Casaloldo',
      'Casalnuovo Monterotaro',
      'Casalnuovo di Napoli',
      'Casalnoceto',
      'Casalmoro',
      'Casalmorano',
      'Casalmaiocco',
      'Casalmaggiore',
      'Casalino',
      'Casalincontrada',
      'Casali',
      'Casalgrasso',
      'Casalgrande',
      'Casalfiumanese',
      'Casaletto Spartano',
      'Casaletto Ceredano',
      'Casale sul Sile',
      'Casaleone',
      'Casale Monferrato',
      'Casale Marittimo',
      'Casaleggio Novara',
      'Casale di Scodosia',
      'Casale Corte Cerro',
      'Casalecchio di Reno',
      'Casalduni',
      'Casal di Principe',
      'Casalciprano',
      'Casalbuttano',
      'Casalbuono',
      'Casalborgone',
      'Casalbore',
      'Casalbordino-Miracoli',
      'Casalattico',
      'Casalanguida',
      'Casacanditella',
      'Casacalenda',
      'Carzago Riviera',
      'Carvico',
      'Carunchio',
      'Carugate',
      'Cartignano',
      'Carsoli',
      'Carrù',
      'Carrosio',
      'Carro',
      'Carrara',
      'Carpinone',
      'Carpino',
      'Carpineto Sinello',
      'Carpineto Romano',
      'Carpineto della Nora',
      'Carpineti',
      'Carpignano Sesia',
      'Carpignano Salentino',
      'Carpiano',
      'Carpi Centro',
      'Carpesica',
      'Carpenedolo',
      'Carpegna',
      'Carpasio',
      'Carpaneto Piacentino',
      'Carovilli',
      'Carovigno',
      'Carosino',
      'Carona',
      'Carnate',
      'Carnago',
      'Carmignano di Brenta',
      'Carmignano',
      'Carmiano',
      'Carmagnola',
      'Carlino',
      'Carlazzo',
      'Carlantino',
      'Carisolo',
      'Carisio',
      'Carinola',
      'Carimate',
      'Carignano',
      'Carife',
      'Cargeghe',
      'Caresana',
      'Carenno',
      'Carema',
      'Corneno-Galliano-Carella Mariaga',
      'Cardito',
      'Cardano',
      'Carcoforo',
      'Carceri',
      'Carcare',
      'Carbonera',
      'Carbone',
      'Carbonara di Po',
      'Carbonara al Ticino',
      'Carbognano',
      'Caravonica',
      'Caravino',
      'Caravate',
      'Caravaggio',
      'Carate Brianza',
      'Carassai',
      'Carasco',
      'Carapelle Calvisio',
      'Carapelle',
      'Carano',
      'Caramanico Terme',
      'Caraglio',
      'Capurso',
      'Capua',
      'Capriolo',
      'Caprino Veronese',
      'Caprino',
      'Capriglia Irpina',
      'Caprie',
      'Capriati A Volturno',
      'Capriate San Gervasio',
      'Capriana',
      'Capri',
      'Caprese Michelangelo',
      'Caprarola',
      'Caprarica di Lecce',
      'Capranica Prenestina',
      'Capranica',
      'Capralba',
      'Capraia Isola',
      'Capracotta',
      'Cappelle sul Tavo',
      'Cappella Maggiore',
      "Cappella de' Picenardi",
      'Cappadocia',
      'Caposele',
      'Caporciano',
      'Capoliveri',
      'Capodrise',
      'Capo di Ponte',
      'Capodimonte',
      'Capodarco',
      'Capitignano',
      'Capistrello',
      'Capestrano',
      'Capergnanica',
      'Capena',
      'Capannori',
      'Capannoli',
      'Capalbio',
      'Capaccio',
      'Caorso',
      'Caorle',
      'Canzo',
      'Canzano',
      'Cantù',
      'Cantoira',
      'Cantiano',
      'Cantalupo nel Sannio',
      'Cantalupo Ligure',
      'Cantalupo in Sabina',
      'Cantalice',
      'Cantagallo',
      'Cansano',
      'Olgiate Molgora',
      'Canosa Sannita',
      'Canosa di Puglia',
      'Cannole',
      'Cannobio',
      "Canneto sull'Oglio",
      'Canneto Pavese',
      'Cannero Riviera',
      'Cannara',
      'Cannalonga',
      'Canna',
      'Canischio',
      'Canino',
      'Caneva',
      'Canepina',
      'Canelli',
      'Canegrate',
      'Candiolo',
      'Candide',
      'Candida',
      'Candiana',
      'Candia Lomellina',
      'Candia Canavese',
      'Candelo',
      'Candela',
      'Cancello-Arnone',
      'Cancellara',
      'Canazei',
      'Canaro',
      'Canal San Bovo',
      'Canale Monterano',
      "Canale d'Agordo",
      'Canale',
      'Camugnano',
      'Camucia-Monsigliolo',
      'Campo Tures',
      'Campotosto',
      'Campo Tizzoro',
      'Campospinoso',
      'Camposanto',
      'Camposano',
      'Campo San Martino',
      'Camposampiero',
      'Camporotondo di Fiastrone',
      'Camporosso',
      'Camporgiano',
      'Campora',
      'Camponogara',
      'Campomorone',
      'Campomarino',
      'Campomaggiore',
      'Campolongo al Torre',
      'Campo Ligure',
      'Campolieto',
      'Campoli del Monte Taburno',
      'Campoli Appennino',
      'Campoleone',
      'Campolattaro',
      'Campo Jemini',
      'Campogalliano',
      'Campoformido',
      'Campofilone',
      'Campodoro',
      'Campodolcino',
      'Campo di Trens',
      'Campodipietra',
      'Campo di Giove',
      'Campo di Carne',
      'Campodenno',
      'Campodarsego',
      'Campochiaro',
      'Campobasso',
      'Campli',
      'Campitello di Fassa',
      'Campitello',
      'Campi Salentina',
      'Campione',
      'Campiglia Marittima',
      'Campiglia dei Berici',
      'Campiglia Cervo',
      'Campi Bisenzio',
      'Campertogno',
      'Campello sul Clitunno',
      'Campegine',
      'Camparada',
      'Campanarello',
      'Campalto',
      'Campagnola Emilia',
      'Campagnola',
      'Campagnatico',
      'Campagnano di Roma',
      'Campagna Lupia',
      'Campagna',
      'Camogli',
      'Camisano Vicentino',
      'Camisano',
      'Camino',
      'Camigliano',
      'Camerota',
      'Camerino',
      'Cameri',
      'Camerata Picena',
      'Camerata Nuova',
      'Camerata Cornello',
      'Camerano',
      'Camburzano',
      'Cambiano',
      'Cambiago',
      'Camalò',
      'Camairago',
      'Camaiore',
      'Calvizzano',
      'Calvisano',
      'Calvignano',
      "Calvi dell'Umbria",
      'Calvera',
      'Calvenzano',
      'Calvene',
      'Calvello',
      'Calvatone',
      'Calvanico',
      'Caluso',
      "Calusco d'Adda",
      'Caltrano',
      'Calto',
      'Caltignaga',
      'Caltana',
      'Calolziocorte',
      'Calò',
      'Calliano',
      'Calizzano',
      'Calitri',
      'Calimera',
      'Calice al Cornoviglio',
      'Calestano',
      'Calenzano',
      'Calendasco',
      'Caldonazzo',
      'Caldogno-Rettorgole-Cresole',
      'Caldes',
      'Calderara di Reno',
      'Caldarola',
      'Caldaro sulla Strada del Vino',
      'Calcio',
      'Calcinelli',
      'Calcinato',
      'Calcinate',
      'Calcinaia',
      'Calciano',
      'Calci',
      'Calcata Nuova',
      'Calcara',
      'Calavino',
      'Calascio',
      'Calangianus',
      'Calamandrana',
      'Calalzo di Cadore',
      'Cala Gonone',
      'Calabritto',
      'Caivano',
      'Cairo Montenotte',
      'Caira',
      'Cairate',
      'Cairano',
      'Caiazzo',
      'Caianello',
      'Cagnano Varano',
      'Caglio',
      'Cagli',
      'Caggiano',
      'Caerano di San Marco',
      'Cadoneghe',
      "Ca' di Sola",
      'Cadine',
      'Caderzone Terme',
      'Cadelbosco di Sotto',
      'Cadelbosco di Sopra',
      "Ca' degli Oppi",
      'Cadè-Gaida',
      'Cabella Ligure',
      'Buttrio',
      'Buttigliera Alta',
      'Buttapietra',
      'Buti',
      'Busto Garolfo',
      'Busto Arsizio',
      'Bussoleno',
      'Bussolengo',
      'Busso',
      'Bussi sul Tirino',
      'Busseto',
      'Buscoldo',
      'Buscate',
      'Busca',
      'Busano',
      'Busana',
      'Busalla',
      'Busachi',
      'Buronzo',
      'Burolo',
      'Buriasco',
      'Burgos',
      'Burano',
      'Buonconvento',
      'Buonalbergo',
      'Buonabitacolo',
      'Bulzi',
      'Bultei',
      'Buia',
      'Buglio in Monte',
      'Budrio',
      'Budoia',
      'Buddusò',
      'Bucine',
      'Buccino',
      'Bucciano',
      'Bucchianico',
      'Bubbio',
      'Brusson',
      'Brusnengo',
      'Brusciano',
      'Brusasco',
      'Bruno',
      'Brunico',
      'Brunate',
      'Bruna',
      'Brumano',
      'Bruino',
      'Brugnera',
      'Brugnato',
      'Brugine',
      'Brugherio',
      'Brosso',
      'Brossasco',
      'Bronzolo',
      'Broni',
      'Brivio',
      'Brittoli',
      'Grand Brissogne',
      'Brissago-Valtravaglia',
      'Brisighella',
      'Brinzio',
      'Brindisi Montagna',
      'Brindisi',
      "Brignano Gera d'Adda",
      'Brignano',
      'Briga Novarese',
      'Brienno',
      'Bricherasio',
      'Brez',
      'Bresso',
      'Bressanvido',
      'Bressanone',
      'Bressana',
      'Brescia',
      'Brescello',
      'Brentonico',
      'Breno',
      'Brennero',
      'Brenna',
      'Brendola',
      'Breme',
      'Brembio',
      'Brembilla',
      'Brembate',
      'Breia',
      'Breguzzo',
      'Breganze',
      'Breda',
      'Brebbia',
      'Braone',
      'Brandizzo',
      'Pregola',
      'Bracigliano',
      'Bracciano',
      'Braccagni',
      'Bra',
      'Bozzolo',
      'Bozzole',
      'Bovolone',
      'Bovolenta',
      'Marchesino-Bovo',
      'Bovisio-Masciago',
      'Bovino',
      'Boville Ernica',
      'Bovezzo',
      'Boves',
      'Bovegno',
      'Bottrighe',
      'Bottidda',
      'Botticino',
      'Bottanuco',
      'Botrugno',
      'Bossolasco',
      'Bosisio Parini',
      'Bosentino-Migazzone',
      'Boscotrecase',
      'Boscoreale',
      'Bosconero',
      'Bosco Marengo',
      'Bosco Chiesanuova',
      'Bosco',
      'Bosco Mesola',
      'Boschetto',
      'Bosaro',
      'Bosa',
      'Borzonasca',
      'Borzano',
      'Borutta',
      'Bortigiadas',
      'Bortigali',
      'Borso del Grappa',
      'Borriana',
      'Borrello',
      'Borore',
      'Boroneddu',
      'Borno',
      'Bornasco',
      'Bormio',
      'Borgo Vercelli',
      'Borgo Velino',
      'Borgo Val di Taro',
      'Borgo Tossignano',
      'Borgo Ticino',
      'Borgosesia',
      'Borgosatollo',
      'Borgo Santa Maria',
      'Borgo San Martino',
      'Borgo San Lorenzo',
      'Borgo San Giacomo',
      'Borgo San Dalmazzo',
      'Borgo Sabotino-Foce Verde',
      'Borgorose',
      "Borgoricco-San Michele delle Badesse-Sant'Eufemia",
      'Borgoratto Mormorolo',
      'Borgoratto Alessandrino',
      'Borgo Priolo',
      'Borgo Podgora',
      'Borgo Pace',
      'Borgonuovo',
      'Borgonovo Valtidone',
      'Borgone Susa',
      'Borgomasino',
      'Borgomaro',
      'Borgomanero',
      'Borgomale',
      'Borgolavezzaro',
      'Borgo Grappa',
      "Borgofranco d'Ivrea",
      'Fosso Ghiaia',
      'Borgoforte',
      'Borgo di Terzo',
      "Borgo d'Ale",
      'Borgo a Mozzano',
      'Borgo a Buggiano',
      'Borgo Valsugana',
      'Borgiallo',
      'Borghi',
      'Borghetto Santo Spirito',
      'Borghetto Lodigiano',
      'Borghetto di Vara',
      "Borghetto d'Arroscia",
      'Borghetto',
      'Fornace Zarattini',
      'Borgaro Torinese',
      'Borgagne',
      'Boretto',
      'Borello',
      'Bore',
      'Bordolano',
      'Bordighera',
      'Borca',
      'Borbona',
      'Bonorva',
      'Bono',
      'Bonnanaro',
      'Bonito',
      'Bonferraro',
      'Bonefro',
      'Bonea',
      'Bondeno',
      'Bonavigo',
      'Bonavicina',
      'Bonate Sopra',
      'Bonassola',
      'Bonarcado',
      'Santo Stefano-Bonaldo',
      'Bomporto',
      'Bomba',
      'Bomarzo',
      'Bolzano Vicentino',
      'Bolzano Novarese',
      'Bolzano',
      'Bolsena',
      'Bolotana',
      'Bolognola',
      'Bolognano',
      'Bologna',
      'Bollengo',
      'Bollate',
      'Bolano',
      'Bojano',
      'Bogliasco',
      'Boffalora Sopra Ticino',
      "Boffalora d'Adda",
      'Bocenago',
      'Boccioleto',
      'Bobbio Pellice',
      'Bobbio',
      'Darfo Boario Terme',
      'Blera',
      'Bitti',
      'Bitritto',
      'Bitonto',
      'Bitetto',
      'Bistagno',
      'Bisenti',
      'Bisegna',
      'Bisceglie',
      'Bisaccia',
      'Birori',
      'Bionaz',
      'Bioglio-Portula-Andrè',
      'Binasco',
      'Gabbioneta',
      'Bigolino',
      'Bientina',
      'Bienno',
      'Biella',
      'Bidonì',
      'Bicinicco',
      'Biccari',
      'Bibione',
      'Bibbona',
      'Bibbiena',
      'Bibbiano',
      'Bibano',
      'Biassono',
      'Bianzone',
      'Bianzè',
      'Biandronno',
      'Biandrate',
      'Bevilacqua',
      'Bevagna',
      'Beura',
      'Bettona',
      'Bettolle',
      'Bettola',
      'Bessude',
      'Besozzo',
      'Besnate',
      'Besenzone',
      'Besenello',
      'Besate',
      'Besano',
      'Besana in Brianza',
      'Berzo Inferiore',
      'Berzo',
      'Berzano di San Pietro',
      'Bertonico',
      'Bertiolo',
      'Bertinoro',
      'Bersezio',
      'Berra',
      'Bernate Ticino',
      'Bernalda',
      'Berlingo',
      'Bergeggi',
      'Bergantino',
      'Bergamo',
      'Bereguardo',
      'Berchidda',
      'Berceto',
      'Berbenno di Valtellina',
      'Bentivoglio',
      'Benna',
      'Benevento',
      'Benevello',
      'Bene Vagienna',
      'Benetutti',
      'Belvedere Ostrense',
      'Belvedere Langhe',
      'Beltiglio-San Giovanni',
      'Belmonte Piceno',
      'Belmonte in Sabina',
      'Belmonte del Sannio',
      'Belmonte Castello',
      'Belluno',
      'Bellosguardo',
      'Bellona',
      'Bellocchi',
      'Bellinzago Novarese',
      'Bellegra',
      'Bellante',
      'Bellano',
      'Bellagio',
      'Bella Farnia',
      'Bella',
      'Belgirate',
      'Belgioioso',
      'Belforte del Chienti',
      'Belforte',
      'Belfiore',
      'Beinette',
      'Beinasco',
      'Begliano',
      'Bedonia',
      'Bazzano',
      'Progetto Case Bazzano',
      'Baveno',
      'Baunei',
      'Bauladu',
      'Battipaglia',
      'Battaglia Terme',
      'Bastiglia',
      "Bastida de' Dossi",
      'Bastia umbra',
      'Bassignana',
      'Bassiano',
      'Bassano Romano',
      'Bassano in Teverina',
      'Bassano del Grappa',
      'Bassano Bresciano',
      'Basilicanova',
      'Basiliano',
      'Baselice',
      'Baselga di Pinè',
      'Basciano',
      'Baschi',
      'Bascapè',
      'Barzanò',
      'Barzana',
      'Giacciano con Baruchella',
      'Barrea',
      'Barra',
      'Baronissi',
      'Barni',
      'Barletta',
      'Barlassina',
      'Barisciano',
      'Barile',
      'Baricella',
      'Bari',
      'Barghe',
      'Barge',
      'Bargagli',
      'Barga',
      'Barete',
      'Bareggio',
      'Bardonecchia',
      'Bardolino',
      'Bardineto',
      'Bardi',
      'Bard',
      'Barco',
      'Barcis',
      'Barbona',
      'Barbianello',
      "Barberino Val d'Elsa",
      'Barberino di Mugello',
      'Barbarasco',
      'Barbarano Vicentino',
      'Barbarano Romano',
      'Barbania',
      'Baranzate',
      "Barano d'Ischia",
      'Baranello',
      'Baragiano',
      'Baone',
      'Banzi',
      'Banari',
      'Balzola',
      'Balzo',
      'Balvano',
      'Balsorano Nuovo',
      'Balocco',
      'Balmuccia',
      'Balme',
      'Ballabio',
      'Balestrino',
      'Baldissero Torinese',
      "Baldichieri d'Asti",
      'Balangero',
      'Baiso',
      'Baiano',
      'Baia',
      'Bagolino',
      'Bagno Roselle',
      'Bagnoregio',
      'Bagnone',
      'Bagnolo San Vito',
      'Bagnolo Piemonte',
      'Bagnolo Mella',
      'Bagnolo in Piano',
      'Bagnolo del Salento',
      'Bagnolo Cremasco',
      'Bagnolo',
      'Bagnoli Irpino',
      'Bagnoli di Sopra',
      'Bagnoli del Trigno',
      'Bagnoli',
      'Bagno a Ripoli',
      'Bagni di Tivoli',
      'Rabbi Fonti',
      'Bagni di Lucca',
      'Bagnasco',
      'Bagnarola',
      'Bagnara di Romagna',
      'Bagnacavallo',
      'Badia Tedalda',
      'Badia Polesine',
      'Badia Pavese',
      'Badia Calavena',
      'Badia',
      'Badesi',
      'Badalucco',
      'Bacoli',
      'Baceno',
      'Azzone',
      'Azzate',
      'Azzano Decimo',
      'Azzanello',
      'Azeglio',
      'Aymavilles',
      'Avio',
      'Avigliano Umbro',
      'Avigliano',
      'Avigliana',
      'Aviano',
      'Avezzano',
      'Avetrana',
      'Aversa',
      'Averara',
      'Avellino',
      'Avella',
      'Avelengo',
      'Austis',
      'Ausonia',
      'Auronzo',
      'Aurisina',
      'Aurigo',
      'Aurelia',
      'Aulla',
      'Auletta',
      'Auditore',
      'Attimis',
      'Attigliano',
      'Atripalda',
      'Atri',
      'Atrani',
      'Atina',
      'Atessa',
      'Atena Lucana',
      'Atella',
      'Ateleta',
      'Asti',
      'Asso',
      'Assisi',
      'Asolo',
      'Asola',
      'Asiago',
      'Ascrea',
      'Ascoli Satriano',
      'Ascoli Piceno',
      'Asciano',
      'Ascea',
      'Arzignano',
      'Arzergrande',
      'Arzene',
      'Arzano',
      'Arzachena',
      'Arvier',
      'Artogne',
      'Artena',
      'Artegna',
      'Arta Terme',
      'Arsoli',
      'Arsita',
      'Arsiero',
      'Arsiè',
      'Arsago Seprio',
      'Arrone',
      'Arre',
      'Arquata Scrivia',
      'Arquata del Tronto',
      'Arquà Petrarca',
      'Arpino',
      'Arpaise',
      'Arpaia',
      'Arosio',
      'Arona',
      'Arola',
      'Arola-Preazzano',
      'Arnesano',
      'Arnara',
      'Armento',
      'Armeno',
      'Arluno',
      'Arlena di Castro',
      'Arignano',
      'Arienzo',
      'Arielli',
      'Ariccia',
      'Ariano',
      'Ariano Irpino-Martiri',
      'Ari',
      'Argenta',
      'Argelato',
      'Argegno',
      'Arezzo',
      'Arese',
      'Arenzano',
      'Arena Po',
      'Ardesio',
      'Ardenno',
      'Ardea',
      'Ardauli',
      'Ardara',
      'Arcugnano-Torri',
      'Arcore',
      'Arconate',
      'Arcole',
      'Arcola',
      'Arco',
      'Arcidosso',
      'Archi',
      'Arcevia',
      'Arceto',
      'Arcene',
      'Arce',
      'Arcade',
      'Arborio',
      'Arba',
      'Aradeo',
      'Aquino',
      'Aquilonia',
      'Aquileia',
      'Aquara',
      'Aprilia',
      'Apricena',
      'Apricale',
      'Aprica',
      'Appignano del Tronto',
      'Appignano',
      'Appiano Gentile',
      'San Michele',
      'Apollosa',
      'Apiro',
      'Apice Vecchio',
      'Apecchio',
      'Aosta',
      "Anzola d'Ossola",
      "Anzola dell'Emilia",
      'Anzio',
      'Bannio',
      'Anzi',
      'Anzano di Puglia',
      'Anzano del Parco',
      'Anversa degli Abruzzi',
      'Antronapiana',
      'Antrodoco',
      'Anticoli Corrado',
      'Antey-Saint-Andrè',
      'Anterivo',
      'Antella',
      'Antegnate',
      'Annone Veneto',
      'Annone di Brianza',
      'Annicco',
      'Anitrella-Chiaiamari',
      'Anguillara Veneta',
      'Anguillara Sabazia',
      'Angri',
      'Angolo Terme',
      'Anghiari',
      'Angera',
      'Anfo',
      'Anela',
      'Anduins',
      'Andria',
      'Andretta-Mattinella',
      'Andreis',
      'Andrate',
      'Andorno Cacciorna',
      'Andezeno',
      'Andalo',
      'Ancona',
      'Anchione',
      'Ancarano',
      'Anagni',
      'Anacapri',
      'Ampezzo',
      'Amorosi',
      'Ameno',
      'Amelia',
      'Ameglia',
      'Ambra',
      'Amatrice',
      'Amaseno',
      'Amaro',
      'Amandola',
      'Amalfi',
      'Alzano Lombardo',
      'Alvito',
      'Alvignano',
      'Alviano',
      'Altopascio',
      'Altino',
      'Altidona',
      'Altedo',
      'Altavilla Vicentina',
      'Altavilla Silentina',
      'Altavilla Irpina',
      'Altare',
      'Altamura',
      'Alseno',
      'Alpignano',
      'Alpette',
      'Almese-Rivera',
      'Almenno San Salvatore',
      'Almenno San Bartolomeo',
      'Almè',
      'Allumiere',
      'Allerona',
      'Alleghe',
      'Alife',
      'Alice Superiore',
      'Alice Castello',
      'Aliano',
      'Alghero',
      'Alfonsine',
      'Alfiano Natta',
      'Alfedena',
      'Alfano',
      'Alezio',
      'Alessandria',
      'Aldeno',
      'Albuzzano',
      'Albonese',
      'Albizzate',
      'Albisola Marina',
      'Albino',
      'Albinea',
      'Albignasego',
      'Albignano',
      'Albiate',
      "Albiano d'Ivrea",
      'Albiano',
      'Albiano Magra',
      'Albettone',
      'Alberona',
      'Alberobello',
      'Albareto',
      'Albenga',
      'Albaredo',
      "Albaredo d'Adige",
      'Albano Vercellese',
      "Albano Sant'Alessandro",
      'Albano Laziale',
      'Albano di Lucania',
      'Albanella',
      'Alba',
      'Alatri',
      'Alassio',
      'Alanno',
      'Alagna Valsesia',
      'Alagna',
      'Ala di Stura',
      'Alà dei Sardi',
      'Ala',
      'Airuno',
      'Airole',
      'Airola',
      'Airasca',
      'Ailoche',
      'Ailano',
      'Aiello del Sabato',
      'Aiello del Friuli',
      'Aielli',
      'Aidomaggiore',
      'Agugliaro',
      'Agugliano',
      'Agropoli',
      'Agrate Brianza',
      'Agosta',
      'Agordo',
      'Agnone',
      'Agnadello',
      'Agna',
      'Agliè',
      'Agliana',
      'Aggius',
      'Agerola',
      'Agazzano',
      'Afragola',
      'Affile',
      'Affi',
      'Adro',
      'Adria',
      'Adelfia',
      'Acuto',
      'Acqui Terme',
      'Acquaviva Picena',
      "Acquaviva d'Isernia",
      'Acquaviva delle Fonti',
      'Acquaviva Collecroce',
      'Acquaviva',
      'Acquasparta',
      'Acquasanta Terme',
      'Acquapendente',
      'Acquanegra sul Chiese',
      'Acquanegra Cremonese',
      'Acqualagna',
      'Acquafredda',
      'Acquafondata',
      'Acilia-Castel Fusano-Ostia Antica',
      'Acerra',
      'Acerno',
      'Acerenza',
      'Accumoli',
      'Acciano',
      'Accettura',
      'Acceglio',
      'Accadia',
      'Abriola',
      'Abetone',
      'Abbiategrasso',
      'Abbazia Pisani',
      'Abbateggio',
      'Abbasanta',
      'Abbadia San Salvatore',
      'Abbadia Lariana',
      'Abano Terme',
      'Li Punti-San Giovanni',
      'Tergu',
      'San Martino dei Mulini',
      "Gatteo-Sant'Angelo",
      'San Mauro a Mare',
      'Gatteo a Mare',
      'Sala',
      'Rivazzurra',
      'Lido Adriano',
      'Marina Romea',
      'Santo Stefano-Carraie',
      'Glorie',
      'Lido degli Estensi',
      'Lido di Pomposa-Lido degli Scacchi',
      'Serravalle',
      'Scardovari',
      'Aurisina Cave',
      'Capriva del Friuli',
      'San Lorenzo Isontino',
      'Ruda',
      'Visco',
      'Sevegliano',
      'San Vito al Torre',
      'Medea',
      'Chiopris',
      "Terzo d'Aquileia",
      "San Canzian d'Isonzo",
      'Pavia di Udine',
      'Bagnaria Arsa',
      'Pertegada',
      'Camino al Tagliamento',
      'Puia-Villanova',
      'Prata di Sotto',
      'Meduna di Livenza',
      'Gorgo al Monticano',
      'Piavon',
      'Corbolone',
      'Pramaggiore Blessaglia',
      'Pramaggiore',
      'Gruaro-Bagnara',
      'Summaga',
      'San Giorgio al Tagliamento-Pozzi',
      'Crepaldo',
      'Pero',
      'Candelù',
      'Ormelle',
      'San Polo di Piave',
      'Lutrano',
      'Maserada sul Piave',
      'Covolo-Levada',
      'Santa Lucia di Piave',
      'Mareno di Piave',
      'Francenigo',
      'Colle Umberto',
      'Cosniga-Zoppè',
      'Orsago',
      'Ciano',
      'Selva del Montello',
      'Povegliano',
      'Falzè-Signoressa',
      'Falze',
      'Ponzano',
      'Mignagola',
      'Casacorba',
      'Padernello',
      'Fossò',
      "Fiesso d'Artico",
      'Marano',
      'Dese',
      'San Liberale',
      'Trivignano',
      'Peseggia-Gardigiano',
      'Torreselle',
      'Badoere',
      'Lughignano',
      'Casier',
      'Biancade',
      'Veternigo',
      'Spinea-Orgnano',
      'Sambruson',
      'Campolongo Maggiore Liettoli',
      'Vallonga',
      'Cercivento',
      'Sutrio',
      'Pieria-Prato Carnico',
      'Granvilla',
      "Sant'Anna",
      'Bortolot',
      'Castion',
      'Trichiana',
      'Limana',
      'Lamosano',
      'Soccher-Paiane-Casan-Arsie',
      'Valle di Cadore',
      'Col San Martino',
      'Savogna',
      'Clodig',
      'Merso di Sopra',
      'Prepotto',
      'Dolegna del Collio',
      'Orsaria',
      'Pradamano',
      'Basaldella',
      'Reana del Roiale',
      'Basiliano-Vissandone',
      'Bressa',
      'Passons',
      'Colugna',
      'Pagnacco',
      'Colloredo di Monte Albano',
      'Colloredo di Monte Albano-Lauzzana',
      'Montenars',
      'Isola',
      'Bordano',
      'Chiaulis',
      'Preone',
      'Forgaria nel Friuli',
      'Tramonti di Sotto',
      'Marsure',
      'San Martino al Tagliamento',
      'Ramuscello',
      'Claut',
      'Bocco',
      'Leivi',
      'San Salvatore',
      'Cogorno',
      'Moraro',
      'Campolongo al Torre-Cavenzano',
      'Sottoselva',
      'Motta Camastra',
      'Brusaporto',
      'Viganella',
      'Fratte Rosa',
      'San Bartolomeo al Mare',
      'Cloz',
      'Verbania',
      'Vacchereccia',
      'Pieve Emanuele',
      'Osio Sotto',
      'Scanzo-Rosciate',
      'Bedizzole',
      'Lumezzane',
      'Mazzano',
      'Beccacivetta-Azzano',
      'Noventa',
      'Castelnuovo del Garda',
      "Romano d'Ezzelino",
      'Saonara',
      'Mediglia',
      'Buccinasco',
      'Monte Argentario',
      'Guidonia Montecelio',
      'Cervinara',
      'Montoro Superiore',
      'Lamezia Terme',
      'Cardano al Campo',
      'Caronno Pertusella',
      'Cassano Magnago',
      'Gerenzano',
      'Uboldo',
      'Pessano Con Bornago',
      'Settimo Milanese',
      'Cassola',
      'Pieve a Nievole',
      'Albisola Superiore',
      'Arcisate',
      'Basiglio',
      'Bussero',
      'Verano Brianza',
      'Treviolo',
      'Concesio',
      'Pino Torinese',
      "San Cipriano d'Aversa",
      'San Felice A Cancello',
      'San Nicola la Strada',
      'Crispano',
      'Pollena Trocchia',
      'Quarto',
      'Tremestieri Etneo',
      'Santena',
      'Vinovo',
      'San Pietro in Cariano',
      "Ozzano dell'Emilia",
      'Casagiove',
      'Frignano',
      'Orta di Atella',
      'San Marcellino',
      'Teverola',
      'Casavatore',
      'Volla',
      'Santa Maria La Carità',
      'Trecase',
      "Sant'Egidio del Monte Albino",
      'Bellizzi',
      'Alba Adriatica',
      'Valderice',
      'Gravina di Catania',
      "Sant'Agata Li Battiati",
      'Lusciano',
      'Parete',
      'Piedimonte Matese',
      'Cesano Boscone',
      'Cesate',
      'Carrara San Giorgio',
      'Porto Viro',
      'Tor Lupara',
      'Bernareggio',
      'Brozolo',
      'Campiglione',
      'Magliano Alfieri',
      'Moiola',
      'Mombarcaro',
      'Mombasiglio',
      'Monasterolo Casotto',
      'Montaldo di Mondovì',
      "Rocca De' Baldi",
      'Coassolo Torinese',
      'Inverso Pinasca',
      'Priero',
      'Roascio',
      'Rocchetta Belbo',
      'Roddi',
      'Roddino',
      'Rodello',
      'Sale delle Langhe',
      'San Benedetto Belbo',
      'Sinio',
      'Mompantero',
      'Pinasca-Dubbione',
      'Pramollo',
      'Prarostino',
      'Belveglio',
      'Cantarana',
      'Capriglio',
      'Castel Boglione',
      'Castellero',
      'Castelletto Molina',
      'Cellarengo',
      'Roure',
      'Salza di Pinerolo',
      'San Raffaele Cimena',
      'Traves',
      'Val della Torre',
      'Cinaglio',
      'Coazzolo',
      'Corsione',
      'Ferrere',
      'Maranzana',
      'Maretto',
      'Mombaruzzo',
      'Mombercelli',
      'Monale',
      'Monastero Bormida',
      'Verrua Savoia',
      'Montabone',
      'Montafia',
      'Montaldo Scarampi',
      'Piovà Massaia',
      'Quaranti',
      'Refrancore',
      'Roatto',
      'Robella',
      "Rocca d'Arazzo",
      'Agrate Conturbia',
      'Castelspina',
      'Cella Monte',
      'Conzano',
      'Oviglio',
      'Pleyne',
      'Briaglia',
      'Briga Alta',
      'Caprauna',
      'Chiesa di Macra',
      'Saint-Denis',
      'Verrayes',
      'Aquila di Arroscia',
      'Monastero di Vasco',
      'Cosio di Arroscia',
      'Diano Arentino',
      'Mendatica',
      'Montezemolo',
      "Monticello d'Alba",
      'Niella Tanaro',
      'Ostana',
      'Perlo',
      'Pontechianale',
      'Rittana',
      'Roccabruna',
      'Cosseria',
      'Giusvalla',
      'Magliolo',
      'Mallare',
      'Scagnello',
      'Torre Mondovì',
      'Valloriate',
      'Agliano Terme',
      'Mioglia',
      'Plodio',
      'Rialto',
      'Casarza Ligure',
      'Davagna',
      'Orero',
      'Beverino',
      'Camerano Casasco',
      'Incisa Scapaccino',
      "Isola d'Asti",
      'Passerano Marmorito',
      'Rondanina',
      'Rovegno',
      'Carezzano Maggiore',
      'Cassinelle-Concentrico',
      'Castelletto Merli',
      'Cerrina',
      'Coniolo Bricco',
      'Lozza',
      'Malgesso',
      'Masciago Primo',
      'Mesenzana',
      'Montegrino Valtravaglia',
      'Orino',
      'Saltrio',
      'Morbello',
      'Odalengo Piccolo',
      'Pozzol Groppo',
      'San Giorgio Monferrato',
      'Bizzarone',
      'Blessagno',
      'Blevio',
      'Bulgarograsso',
      'Cadorago-Caslino al Piano',
      'Carate Urio',
      'Carbonate',
      "Caslino d'Erba",
      'Casnate Con Bernate',
      'Castelnuovo Bozzente',
      'Ayas',
      'Challand-Saint-Anselme',
      'Challand-Saint-Victor',
      'Chamois',
      'Champorcher',
      'Petit Fenis',
      'Introd',
      'Merone',
      'Moltrasio',
      'Montorfano',
      'Nesso',
      'Prelà Castello',
      'Borgo di Ranzo',
      'Andora',
      'Arnasco',
      'Borgio',
      'Bormida',
      'San Siro',
      'Moconesi',
      'Pieve di Zignago',
      'Bodio Lomnago',
      'Brezzo di Bedero',
      'Cadegliano-Viconago',
      'Castelveccana',
      'Novedrate',
      'Ossuccio',
      'Parè',
      'Plesio',
      'Sala Comacina',
      'San Bartolomeo Val Cavargna',
      'San Fermo della Battaglia',
      'San Nazzaro Val Cavargna',
      'Solbiate',
      'Stazzona',
      'Veniano',
      'Gornate Olona',
      'Oggiona-Santo Stefano',
      'Bema',
      'Cino',
      'Sernio',
      'Tresivio',
      'Veddasca',
      'Albavilla',
      'Beregazzo con Figliaro',
      'Bregnano',
      'Casasco Intelvi',
      'Assago',
      'Basiano',
      'Bellusco',
      'Consiglio di Rumo',
      'Cremia',
      'Faggeto Lario',
      'Faloppio',
      'Gironico al Piano',
      'Grandola ed Uniti',
      'Mezzegra',
      'Nobile-Monguzzo',
      'Montemezzo',
      'Pellio Intelvi',
      'Pianello del Lario',
      'Schignano',
      'Trezzone',
      'Valbrona',
      'Val Rezzo',
      'Castione Andevenno',
      "Pozzo d'Adda",
      'Pozzuolo Martesana',
      'Renate',
      'Roncello',
      'Bedulita',
      "Canonica d'Adda",
      'Carobbio degli Angeli',
      'Cenate Sopra',
      'Cenate di Sotto',
      "Moio de' Calvi",
      'Mozzo',
      'Palazzago',
      'Pedrengo',
      'Peia',
      'Pianico',
      'Piario',
      'Piazzolo',
      'Pognano',
      'Ponteranica',
      'Fara Olivana',
      'Foresto Sparso',
      'Isola di Fondra',
      'Locatello',
      'Oltressenda Alta',
      "San Paolo d'Argon",
      'Spinone al Lago',
      "Rota d'Imagna",
      "Sant'Omobono Terme",
      'Taleggio',
      'Ubiale Clanezzo',
      'Scano al Brembo',
      'Valgoglio',
      "Torre de' Roveri",
      'Valleve',
      'Vilminore di Scalve',
      'Bione',
      'Brione',
      'Verdellino',
      'Viadanica',
      'Vigano San Martino',
      'Costa di Serina',
      'Algua',
      'Agnosine',
      'Alfianello',
      'Idro',
      'Lodrino',
      'Lozio',
      'Manerba del Garda',
      'Corvino San Quirico',
      'Inverno e Monteleone',
      'Montecalvo Versiggia',
      'Mornico Losana',
      'Collebeato',
      'Corteno Golgi',
      'Erbusco',
      'Incudine',
      'Irma',
      'Santa Margherita di Staffora',
      'Casa Ponte',
      'Mombelli',
      'Longhena',
      'Losine',
      'Malegno',
      'Milzano',
      'Ome',
      'Cappella Cantone',
      'Casale Cremasco',
      "Corte de' Cortesi con Cignone",
      'Gadesco-Pieve Delmona',
      'Polpenazze del Garda',
      'Prevalle',
      'Persico Dosimo',
      "Urago d'Oglio",
      'Bagnaria',
      'Montescano',
      'Mottella',
      'Bastida Pancarana',
      'La Valle - Wengen',
      'Bleggio Superiore',
      'Bresimo',
      'Cimoneri',
      'Commezzadura',
      'Fierozzo',
      'Garniga Nuova',
      'Giovo',
      'Lona-Lases',
      'Montagne',
      'Redavalle',
      'Robecco Pavese',
      'Roncaro',
      'Rovescala',
      "Sant'Alessio Con Vialone",
      'Santa Maria della Versa',
      'Pieve di Bono',
      'Rumo',
      'Sagron Mis',
      "Sant'Orsola Terme",
      'Terragnolo',
      'Vigo di Ton',
      'Trambileno',
      "Torre de' Negri",
      'Torricella Verzate',
      'Valfloriana',
      'Vallarsa',
      'Villa-Agnedo',
      'Brentino Belluno',
      'Brenzone',
      'Lavagno',
      'Travacò Siccomario',
      'Velezzo Lomellina',
      'Verretto',
      'Verrua Po',
      "Gerre de' Caprioli",
      'Zeccone',
      'Zenevredo',
      'Pedemonte',
      'Ripalta Arpina',
      'Ripalta Guerina',
      'San Daniele Po',
      'San Pietro Valdastico',
      "Velo d'Astico",
      'Pieve di Coriano',
      'Ponti sul Mincio',
      'Redondesco',
      'Serravalle a Po',
      'Bersone',
      'Bolbeno',
      "Massanzago-Ca' Baglioni-San Dono",
      'Megliadino San Fidenzio',
      'Cagnò',
      'Vò',
      'Lisignago',
      'Malosco',
      'Nanno',
      'Nogaredo',
      'Ossana',
      'Ronzo-Chienis',
      'Pelugo',
      'Fiumicello',
      'Sarnonico',
      'Segonzano',
      'Sfruz',
      'Smarano',
      'Reana del Rojale',
      'Soraga',
      'Sover',
      'Tenna',
      'Terzolas',
      'Torcegno',
      'Zuclo',
      'Angiari',
      "Boschi Sant'Anna",
      'Cerro Veronese',
      'Colognola ai Colli',
      'Grancona',
      'Roveredo di Guà',
      'Roverè Veronese',
      'Stazione Valmozzola',
      'Lugo di Vicenza',
      'San Gregorio nelle Alpi',
      'San Nicolò Comelico',
      'Monfumo',
      'Portico e San Benedetto',
      'Villagrande',
      'Monte Grimano Terme',
      'Terrassa Padovana',
      'Valle e Castello',
      'Fluminata',
      'Castel di Lama',
      'Cortino',
      'Zeri',
      'San Romano in Garfagnana',
      'Caminata',
      'Cerignale',
      'Sambuca Pistoiese',
      'Capraia e Limite',
      'Tavarnelle Val di Pesa',
      'Gazzola',
      'Frazione Chiesa',
      'Callabiana - Chiesa',
      'Montegioco',
      'Calvignasco',
      'Sardigliano',
      'Nibionno',
      'Palazzo Pignano',
      'Terranova dei Passerini',
      "Capriata d'Orba",
      "Campo nell'Elba",
      'Montemignaio',
      'Isola del Giglio',
      'Tresana',
      'Pontey',
      'Rhemes-Saint-Georges',
      'Castelbianco',
      'Giustenice',
      'Larciano',
      'Onzo',
      'Orco Feglino',
      'Roccavignale',
      'Osteria dei Cacciatori-Stella',
      'Broccostella',
      'Vendone',
      'Vezzi Portio',
      'Avegno',
      'Tribogna',
      'Molino Vecchio-Scapitola-Baio',
      'Carrodano',
      'Cosio Valtellino',
      'Forcola',
      'Menarola',
      'Castel di Sasso',
      'Castel Morrone',
      'Samolaco',
      'Isolaccia',
      'Valdisotto',
      'Val Masino',
      'Marmentino',
      'Monticelli Brusati',
      'Muscoline',
      'Ossimo Superiore',
      'Paisco Loveno',
      'Pertica Alta',
      'Pertica Bassa',
      'Pezzaze',
      'Preseglie',
      "Provaglio d'Iseo",
      'Provaglio Val Sabbia',
      'Puegnago sul Garda',
      'Remedello',
      'Rodengo-Saiano',
      'Gardola',
      'Vallio Terme',
      'Valvestino',
      'Albaredo Arnaboldi',
      'Castelletto di Branduzzo',
      'Lettere',
      'Sovramonte',
      'Paderno del Grappa',
      'Saint-Christophe',
      'Saint Marcel',
      'Saint-Nicolas',
      'Ville Sur Sarre',
      'Valsavarenche',
      'Valtournenche',
      'Bajardo',
      'Ceranesi',
      'Roè Volciano',
      'Soiano',
      'Aldino',
      'Andriano',
      'Venticano',
      'Barbiano - Barbian',
      'Braies',
      'Brennero - Brenner',
      'Caines - Kuens',
      "Cornedo All'Isarco",
      'Cortina sulla Strada del Vino',
      'Funes - Villnoess',
      'Magrè sulla Strada del Vino',
      'Proves - Proveis',
      'Racines - Ratschings',
      'Rasun Anterselva - Rasen-Antholz',
      'Renon - Ritten',
      'Rodengo - Rodeneck',
      'San Martino in Badia',
      'Senales',
      'Tirolo',
      'Ultimo - Ulten',
      'Prati',
      'Valle Aurina - Ahrntal',
      'Valle di Casies - Gsies',
      'Vandoies - Vintl',
      'Verano - Voeran',
      'Villandro - Villanders',
      'Vallada Agordina',
      'Cavaso del Tomba',
      'Montecorvino Pugliano',
      'Balsorano',
      'Lucoli',
      'Imposte',
      'Isole Tremiti',
      'Calvisi',
      'Conflenti',
      'Africo Nuovo',
      'Fiumara',
      'Roghudi',
      'Fondachelli-Fantina',
      'Terme',
      'Maniace',
      'Valledoria',
      'Camandona',
      'Cerreto Castello',
      'Curino',
      'Mezzana Mortigliengo',
      'Selve Marcone',
      'Soprana',
      'Vallanzengo',
      'Veglio',
      'Castello di Brianza',
      'Colle Brianza',
      'Garbagnate Monastero',
      'Monticello Brianza',
      'Castiraga Vidardo',
      'Jonadi',
      'Antrona Schieranco',
      'Brovello-Carpugnino',
      'Calasca-Castiglione',
      'Cavaglio-Spoccia',
      'Madonna del Sasso',
      'Montescheno',
      'Oggebbio',
      'San Bernardino Verbano',
      'Valstrona',
      'Montemale di Cuneo',
      'Monterosso Grana',
      'Murello',
      'Paroldo',
      'Perletto',
      'Pianfei',
      'Pocapaglia',
      'Polonghera',
      'Torre Bormida',
      'Torre San Giorgio',
      'Verduno',
      "Vezza d'Alba",
      'Villar San Costanzo',
      'Albugnano',
      'Antignano',
      'San Martino Alfieri',
      'San Paolo Solbrito',
      'Soglio',
      'Tigliole',
      'Vaglio Serra',
      'Viarigi',
      'Vinchio',
      'Alice Bel Colle',
      'Altavilla Monferrato',
      'Avolasca',
      'Belforte Monferrato',
      'Bergamasco',
      'Berzano di Tortona',
      'Borghetto di Borbera',
      'Bosio',
      'Carpeneto',
      'Casal Cermelli',
      'Casaleggio Boiro',
      'Costa Vescovato',
      'Cuccaro Monferrato',
      'Fraconalto',
      'Francavilla Bisio',
      'Fresonara',
      'Gavazzana',
      'Grondona',
      'Masio',
      'Molino dei Torti',
      'Mombello Monferrato',
      'Momperone',
      'Moncestino',
      'Montacuto',
      'Montaldeo',
      "Montechiaro d'Acqui",
      'Montemarzino',
      'Morsasco',
      'Odalengo Grande',
      'Olivola',
      'Orsara Bormida',
      'Pietra Marazzi',
      'Piovera',
      'Ponzano Monferrato',
      'Ricaldone',
      'Rosignano Monferrato',
      "Sant'Agata Fossili",
      'Sezzadio',
      'Solonghello',
      'Treville',
      'Arnad',
      'Fontainemore',
      'Hone',
      'Agra',
      'Azzio',
      'Barasso',
      'Bedero Valcuvia',
      'Brenta',
      'Brusimpiano',
      'Caronno Varesino',
      'Castello Cabiaglio',
      'Clivio',
      'Cuasso al Monte',
      'Cugliate-Fabiasco',
      'Cunardo',
      'Daverio',
      'Duno',
      'Gorla Minore',
      'Jerago Con Orago',
      'Leggiuno',
      'Varano Borghi',
      'Albese Con Cassano',
      'Alserio',
      'Cavallasca',
      'Corrido',
      'Fenegrò',
      'Laino',
      'Lasnigo',
      'Albosaggia',
      'Briosco',
      'Cassinetta di Lugagnano',
      'Cogliate',
      'Correzzana',
      'Dresano',
      'Gessate',
      'Gudo Visconti',
      'Lazzate',
      'Lesmo',
      'Macherio',
      'Masate',
      'Mesero',
      'Mezzago',
      'San Vittore Olona',
      'Sulbiate',
      'Veduggio Con Colzano',
      'Vizzolo Predabissi',
      'Adrara San Rocco',
      'Ambivere',
      'Aviatico',
      'Barbata',
      'Bianzano',
      'Boltiere',
      'Bonate Sotto',
      'Bossico',
      "Chignolo d'Isola",
      'Colzate',
      'Corna Imagna',
      'Entratico',
      'Filago',
      'Fiorano al Serio',
      'Fonteno',
      'Fuipiano Valle Imagna',
      'Gandellino',
      'Gaverina Terme',
      'Gorlago',
      'Madone',
      'Premolo',
      'Presezzo',
      'Riva di Solto',
      'Castelcovati',
      'Borgo San Siro',
      'Castana',
      'Chignolo Po',
      "Costa de' Nobili",
      'Frascarolo',
      'Golferenzo',
      'Magherno',
      'Marcignago',
      'Marzano',
      'Campagnola Cremasca',
      'Casaletto Vaprio',
      'Cella Dati',
      'Derovere',
      'Drizzona',
      'Malagnino',
      'Spinadesco',
      'Castelbelforte',
      'Motteggiana',
      'Cunevo',
      'Daiano',
      'Dorsino',
      'Isera',
      'Ronchi Valsugana',
      'Rivoli Veronese',
      'Selva di Progno',
      'Alonte',
      'Altissimo',
      'Asigliano Veneto',
      'Brogliano',
      'Campolongo sul Brenta',
      'Carrè',
      'Enego',
      'Mason Vicentino',
      'Montecchio Precalcino',
      'Montegalda',
      'Mossano',
      'Mussolente',
      'Pove del Grappa',
      'Sarego',
      'Alano di Piave',
      'Altivole',
      'Segusino',
      'Cartura',
      'Urbana',
      "Vighizzolo d'Este",
      'Villafranca Padovana',
      'Arquà Polesine',
      'Castelnovo Bariano',
      'Frassinelle Polesine',
      'Solignano',
      'Capolona',
      'Montecreto',
      'San Prospero',
      'Voghiera',
      'Ragoli',
      'Barchi',
      'Casteldelci',
      'Montefelcino',
      'Gualdo',
      'Maltignano',
      'Monte Vidon Combatte',
      'Smerillo',
      'Comano',
      'Giuncugnano',
      'Montescudaio',
      'Valtopina',
      'Arcinazzo Romano',
      'Canterano',
      'Jenne',
      'Montelibretti',
      'Ponzano Romano',
      'Rocca Canterano',
      'Rocca Santo Stefano',
      'Roccagorga',
      'Coreno Ausonio',
      'Bairo',
      'Baldissero Canavese',
      'Angrogna',
      'Rivalta Bormida',
      'Rivarone',
      'Rocca Grimalda',
      'Spineto Scrivia',
      'Tagliolo Monferrato',
      'Tassarolo',
      'Terzo',
      'Vignole Borbera',
      'Villadeati',
      'Villamiroglio',
      'Villaromagnano',
      'Visone',
      'Volpeglino',
      'Allein',
      'Avise',
      'Donnas',
      'Doues',
      'Gignod',
      'Jovencan',
      'Nus',
      'Ollomont',
      'Perloz',
      'Pont-Bozet',
      'Valgrisenche',
      'Villeneuve',
      'Armo',
      'Boissano',
      'Calice Ligure',
      'Casanova Lerrone',
      'Castelvecchio di Rocca Barbena',
      'Massimino',
      'Tovo San Giacomo',
      'Mele',
      'Savignone',
      'Bardello',
      'Bisuschio',
      'Bregano',
      'Brunello',
      'Buguggiate',
      'Cadrezzate',
      'Cantello',
      'Casale Litta',
      'Cazzago Brabbia',
      'Cremenaga',
      'Crosio della Valle',
      'Cuveglio',
      'Cuvio',
      'Ferrera di Varese',
      'Galliate Lombardo',
      'Germignaga',
      'Grantola',
      'Inarzo',
      'Luvinate',
      'Marchirolo',
      'Monvalle',
      'Morazzone',
      'Sangiano',
      'Albiolo',
      'Alzate Brianza',
      'Capiago-Intimiano-Olmeda',
      'Carugo',
      'Cassina Rizzardi',
      'Castelmarte',
      "Cerano d'Intelvi",
      'Colonno',
      'Cucciago',
      'Cusino',
      'Dosso del Liro',
      'Figino Serenza',
      'Guanzate',
      'Lambrugo',
      'Limido Comasco',
      'Lipomo',
      'Longone al Segrino',
      'Luisago',
      'Magreglio',
      'Proserpio',
      'Mantello',
      'Montagna in Valtellina',
      'Ponte in Valtellina',
      'San Giacomo Filippo',
      'Spriana',
      'Talamona',
      'Tartano',
      "Tovo di Sant'Agata",
      'Vervio',
      'Aicurzio',
      'Albairate',
      'Bellinzago Lombardo',
      'Bubbiano',
      'Burago di Molgora',
      'Busnago',
      'Grezzago',
      'Ronco Briantino',
      'San Giorgio Su Legnano',
      'Settala',
      'Sovico',
      'Vedano al Lambro',
      'Zelo Surrigone',
      'Adrara San Martino',
      "Arzago d'Adda",
      'Azzano San Paolo',
      'Bagnatica',
      'Bariano',
      'Berbenno',
      'Berzo San Fermo',
      'Bolgare',
      'Cavernago',
      'Chiuduno',
      'Cortenuova',
      'Credaro',
      'Dossena',
      'Fino del Monte',
      'Gandosso',
      'Grassobbio',
      'Grone',
      'Isso',
      'Levate',
      'Lurano',
      'Montello',
      'Morengo',
      'Mornico al Serio',
      'Ranzanico',
      'Rogno',
      'Roncobello',
      'Roncola',
      'Songavazzo',
      'Suisio',
      "Terno d'Isola",
      "Villa d'Adda",
      "Villa d'Ogna",
      'Barbariga',
      'Brandico',
      'Caino',
      'Calvagese della Riviera',
      'Capovalle',
      'Capriano del Colle',
      'Castrezzato',
      'Cellatica',
      'Corzano',
      'Gianico',
      'Maclodio',
      'Montirone',
      'Niardo',
      'Nuvolera',
      'Polaveno',
      'Pontoglio',
      'Roncadelle',
      'San Zeno Naviglio',
      'Trenzano',
      'Zone',
      'Battuda',
      'Borgarello',
      'Bosnasco',
      'Casanova Lonati',
      "Castello d'Agogna",
      'Linarolo',
      'Lirio',
      'Monticelli Pavese',
      'Nicorvo',
      'Oliva Gessi',
      'Pinarolo Po',
      'Sommo',
      'Spessa',
      "Torre d'Arese",
      'Torrevecchia Pia',
      'Trivolzio',
      'Valle Salimbene',
      'Villa Biscossi',
      'Vistarino',
      'Bonemerse',
      "Ca' d'Andrea",
      'Casaletto di Sopra',
      'Castelvisconti',
      'Chieve',
      "Corte de' Frati",
      'Cremosano',
      'Fiesco',
      'Gombito',
      'Martignana di Po',
      'Monte Cremasco',
      'Pianengo',
      'Pieranica',
      'Quintano',
      'Ricengo',
      'Borgofranco sul Po',
      'Felonica',
      'Mariana Mantovana',
      'Schivenoglia',
      'Villa Poma',
      'Amblar',
      'Bieno',
      'Bondo',
      'Bondone',
      'Calceranica al Lago',
      'Carzano',
      'Cavedine',
      'Cavizzana',
      'Cis',
      'Don',
      'Faedo',
      'Faver',
      'Fornace',
      'Frassilongo',
      'Giustino',
      'Cantalupa',
      'Cercenasco',
      'Chiesanuova',
      'Chiusa di San Michele',
      'Ciconio',
      'Colleretto Castelnuovo',
      'Colleretto Giacosa',
      'Cossano Canavese',
      'Fiorano Canavese',
      'Givoletto',
      'Grosso',
      'Pratiglione',
      'Ribordone',
      'Salerano Canavese',
      'San Didero',
      'San Germano Chisone',
      'Rossa',
      'Salasco',
      'Bogogno',
      'Briona',
      'Colazza',
      'Comignago',
      'Soriso',
      'Veruno',
      'Aisone',
      'Albaretto della Torre',
      'Succivo',
      'Pietraroja',
      "Sant'Angelo A Cupolo",
      'Casamicciola Terme',
      'Castello di Cisterna',
      'San Vitaliano',
      'Scisciano',
      'Cassano Irpino',
      'Cesinali',
      'Moschiano',
      "Sant'Angelo All'Esca",
      'Sperone',
      'Cagnano Amiterno',
      "Villa Sant'Angelo",
      'Liscia',
      'Tufara',
      'Castelluccio dei Sauri',
      'Binetto',
      'Poggiorsini',
      'Brienza',
      'Figline Vegliaturo',
      'Santa Maria del Cedro',
      'Belcastro',
      'Feroleto Antico',
      'Jacurso',
      'Sellia Marina',
      'Bova',
      'Furnari',
      'Gualtieri Sicaminò',
      "Motta d'Affermo",
      'Roccafiorita',
      'Savoca',
      'Camporotondo Etneo',
      'San Pietro Clarenza',
      'Valverde',
      'Mazzarrone',
      'Ragalna',
      'Desulo',
      'Villaperuccio',
      "Villa Sant'Antonio",
      'Donato',
      'Magnano',
      'Piatto',
      'Ronco Biellese',
      'Sagliano Micca',
      'Sostegno',
      'Tavigliano',
      'Valdengo',
      'Villanova Biellese',
      'Bulciago',
      'Calco',
      'Cassago Brianza',
      'Civate',
      'Cortenova',
      'Costa Masnaga',
      'Erve',
      'Garlate',
      'Imbersago',
      'Parlasco',
      'Pasturo',
      'Perego',
      'Perledo',
      'Pescate',
      'Rogeno',
      'Santa Maria Hoè',
      'Sirtori',
      'Suello',
      'Valgreghentino',
      'Verderio Inferiore',
      'Verderio Superiore',
      'Casaletto Lodigiano',
      "Cervignano d'Adda",
      'Graffignana',
      'Mairago',
      'Ospedaletto Lodigiano',
      'Pieve Fissiraga',
      'San Martino in Strada',
      'Sordio',
      'Valera Fratta',
      'Villanova del Sillaro',
      'Montegridolfo',
      'Castelsilano',
      'Filandari',
      'Stefanaconi',
      'Arizzano',
      'Aurano',
      'Cambiasca',
      'Quarna Sotto',
      'Seppiana',
      'Vignone',
      'Villette',
      'Bibiana',
      'Bruzolo',
      'Cafasse',
      'Castagnole Piemonte',
      'Castiglione Torinese',
      'Chianocco',
      'Chiaverano',
      'Frossasco',
      'Garzigliana',
      'Giaglione',
      'Gravere',
      'Isolabella',
      'Issiglio',
      'La Cassa',
      'Levone',
      'Lugnacco',
      'Lusigliè',
      'Macello',
      'Mattie',
      'Meugliano',
      'Mombello di Torino',
      'Monastero di Lanzo',
      'Moncenisio',
      'Montaldo Torinese',
      'Pecco',
      'Perosa Canavese',
      'Pertusio',
      'Piscina',
      'Piverone',
      'Porte',
      'Rivarossa',
      'Roletto',
      'Rorà',
      'Rosta',
      'Rueglio',
      'San Carlo Canavese',
      'San Colombano Belmonte',
      'Strambinello',
      'Usseaux',
      'Vallo Torinese',
      'Villanova Canavese',
      'Vische',
      'Asigliano Vercellese',
      'Caresanablot',
      'San Giacomo Vercellese',
      'Cervatto',
      'Rassa',
      'Riva Valdobbia',
      'Scopa',
      'Vocca',
      'Barengo',
      'Boca',
      'Casalbeltrame',
      'Cavaglietto',
      "Cavaglio D'Agogna",
      'Cureggio',
      'Dormelletto',
      'Gargallo',
      'Maggiora',
      'Mandello Vitta',
      'Miasino',
      'Nibbiola',
      'Pisano',
      'Alto',
      "Baldissero d'Alba",
      'Barolo',
      'Battifollo',
      'Canosio',
      'Castelletto Stura',
      'Castelnuovo di Ceva',
      'Cavallerleone',
      'Cerreto Langhe',
      'Cervasca',
      'Clavesana',
      'Cossano Belbo',
      'Farigliano',
      'Frabosa Sottana',
      'Guarene',
      'Igliano',
      'Lagnasco',
      'Lequio Berria',
      'Macra',
      'Marmora',
      'Monasterolo di Savigliano',
      'Montaldo Roero',
      'Montanera',
      'Neviglie',
      'Oncino',
      'Pagno',
      'Pezzolo Valle Uzzone',
      'Piozzo',
      'Priocca',
      'Prunetto',
      'Rifreddo',
      'Roaschia',
      'Roburent',
      'Rocca Cigliè',
      'Roccasparvera',
      'Sale San Giovanni',
      'Santo Stefano Roero',
      "Serralunga d'Alba",
      'Somano',
      'Torresina',
      'Treiso',
      'Trezzo Tinella',
      'Valmala',
      'Vicoforte',
      'Villanova Solaro',
      'Vottignasco',
      'Aramengo',
      "Azzano d'Asti",
      "Buttigliera d'Asti",
      'Calosso',
      'Castelnuovo Belbo',
      'Castel Rocchero',
      'Celle Enomondo',
      "Cerreto d'Asti",
      'Cerro Tanaro',
      "Chiusano d'Asti",
      'Frinco',
      'Grana',
      'Loazzolo',
      'Moasca',
      'Moransengo',
      'Olmo Gentile',
      'Penango',
      "Pino d'Asti",
      'Portacomaro',
      "Revigliasco d'Asti",
      'Roccaverano',
      'Rocchetta Palafea',
      'Rocchetta Tanaro',
      'San Giorgio Scarampi',
      'San Marzano Oliveto',
      'Scurzolengo',
      'Sessame',
      'Settime',
      'Tonengo',
      'Viale',
      "Vigliano d'Asti",
      'Albera Ligure',
      'Alzano Scrivia',
      'Basaluzzo',
      'Camagna Monferrato',
      'Carbonara Scrivia',
      'Carentino',
      'Carrega Ligure',
      'Cartosio',
      'Castellania',
      "Castelletto d'Erro",
      "Castelletto d'Orba",
      'Castelletto Monferrato',
      'Cavatore',
      'Cerreto Grue',
      'Denice',
      'Dernice',
      'Garbagna',
      'Gremiasco',
      'Grognardo',
      'Melazzo',
      'Merana',
      'Monleale',
      'Montaldo Bormida',
      'Montecastello',
      'Mornese',
      'Ispani',
      'Pareto',
      'Parodi Ligure',
      'Pasturana',
      'Prasco',
      'Crevoladossola',
      'Germagno',
      'Loreglia',
      'San Lorenzo in Banale',
      'Scurelle',
      'Spera',
      'Spiazzo',
      'Strembo',
      'Telve di Sopra',
      'Terres',
      'Valda',
      'Villa Rendena',
      'Caldiero',
      'Cazzano di Tramigna',
      'Isola Rizza',
      'Mezzane di Sotto',
      'Roverchiara',
      'San Pietro di Morubio',
      'Sorgà',
      'Velo Veronese',
      'Cartigliano',
      'Gambellara',
      'Gambugliano',
      'Laghi',
      'Nogarole Vicentino',
      'Rotzo',
      'San Germano dei Berici',
      'Sovizzo',
      'Zugliano',
      'Rocca Pietore',
      'Tambre',
      'Boara Pisani',
      'Cervarese Santa Croce',
      'Masi',
      'San Giorgio delle Pertiche',
      'Bagnolo di Po',
      'Canda',
      'Majano',
      'Treppo Carnico',
      "Farra d'Isonzo",
      'San Pietro in Cerro',
      'Zerba',
      'San Possidonio',
      'Predappio',
      'Cartoceto',
      'Mercatino Conca',
      'Monte Porzio',
      'Serrungarina',
      'Barbara',
      'Castelleone di Suasa',
      'Poggio San Marcello',
      'Force',
      'Monteleone di Fermo',
      'Filattiera',
      'Careggine',
      'Fosciandora',
      'Villa Collemandina',
      'Orciano Pisano',
      'Semproniano',
      'Lisciano Niccone',
      'Villa San Giovanni in Tuscia',
      'Montenero Sabino',
      'Campodimele',
      'Torre Caietani',
      'Trivigliano',
      'Carinaro',
      'Castello del Matese',
      'Curti',
      'Piana di Monte Verna',
      'Villa di Briano',
      'Falciano del Massico',
      'Casapesenna',
      'Calvi',
      'San Nazzaro',
      'San Nicola Manfredi',
      'Carbonara di Nola',
      'Massa di Somma',
      'Montella',
      'Pago del Vallo di Lauro',
      'Roccabascerana',
      'Tufo',
      'Vallesaccarda',
      'Felitto',
      'Bugnara',
      'Morino',
      'Rivisondoli',
      'Castel Castagna',
      'Roccamorice',
      'Palmoli',
      'Rocca San Giovanni',
      'Gagliato',
      'Settingiano',
      'Campo Calabro',
      'Maropati',
      'Oppido Mamertina',
      'Riace',
      'San Pietro di Caridà',
      "Sant'Agata del Bianco",
      'Buseto Palizzolo',
      'Trappeto',
      'Blufi',
      'Alì Terme',
      'Graniti',
      'Limina',
      'Pace del Mela',
      'Rometta',
      'Esporlatu',
      'Viddalba',
      'Budoni',
      'Villanova Tulo',
      'Ollastra',
      'Casapinta',
      'Castelletto Cervo',
      'Dorzano',
      'Gifflenga',
      'Miagliano',
      'Muzzano',
      'Ponderano',
      'Quaregna',
      'Roppolo',
      'Rosazza',
      'Ternengo',
      'Viverone',
      'Zubiena',
      'Zumaglia',
      'Barzago',
      'Barzio',
      'Cassina Valsassina',
      'Cernusco Lombardone',
      'Cesana Brianza',
      'Crandola Valsassina',
      'Cremella',
      'Dolzago',
      'Ello',
      'Introzzo',
      'Margno',
      'Montevecchia',
      'Primaluna',
      'Sirone',
      'Vendrogno',
      'Vestreno',
      'Viganò',
      'Abbadia Cerreto',
      'Borgo San Giovanni',
      'Caselle Landi',
      'Cornovecchio',
      'Galgagnano',
      'Livraga',
      'Maccastorna',
      'Marudo',
      'Massalengo-Motta Vigana',
      'Merlino',
      'Mulazzano',
      'Salerano sul Lambro',
      'San Rocco al Porto',
      'Santo Stefano Lodigiano',
      'Somaglia',
      'Mondaino',
      'Monte Colombo',
      'Brognaturo',
      'Joppolo',
      'Zungri',
      'Bee',
      'Caprezzo',
      'Ceppo Morelli',
      'Cossogno',
      'Gurro',
      'Intragna',
      'Massiola',
      'Montecrestese',
      'Nonio',
      'Premia',
      'Toceno',
      'Trasquera',
      'Trontano',
      'Zuni',
      'Letino',
      'Pastorano',
      'Coreglia Ligure',
      'Casciago',
      'Cassano Valcuvia',
      'Bene Lario',
      'Binago',
      'Cabiate',
      'Cagno',
      'Ramponio Verna',
      'Rodero',
      'Ronago',
      'San Fedele Intelvi',
      'Senna Comasco',
      'Sorico',
      'Tavernerio',
      'Turate',
      'Valmorea',
      'Vercana',
      'Zelbio',
      'Andalo Valtellino',
      'Caiolo',
      'Colorina',
      'Lanzada',
      'Lovero',
      'Noviglio',
      'Caponago',
      'Cerro al Lambro',
      'Cusago',
      'Blello',
      'Pregnana Milanese',
      'Bracca',
      'Branzi',
      'Brembate di Sopra',
      'Castel Rozzone',
      'Torre Pallavicina',
      'Onore',
      'Osio Sopra',
      'Pagazzano',
      'Paladina',
      'Pontirolo Nuovo',
      'Pradalunga',
      'Ranica',
      'Solza',
      'Azzano Mella',
      'Ono San Pietro',
      'Paitone',
      'Cecima',
      'Ceranova',
      'Ceretto Lomellina',
      'Cigognola',
      'Cura Carpignano',
      'Galliavola',
      'Ruino',
      'Pizzale',
      'Rea',
      'Imer',
      'Nave San Rocco',
      'Pieve di Ledro',
      'Pomarolo',
      'Praso',
      'Prezzo',
      'Banchette',
      'Barone Canavese',
      'Lombriasco',
      'Lusernetta',
      'Maglione',
      'Marentino',
      'Meana di Susa',
      'Mezzenile',
      'Osasco',
      'Osasio',
      'San Pietro Val Lemina',
      'San Sebastiano da Po',
      'San Secondo di Pinerolo',
      'Torrazza Piemonte',
      'Torre Canavese',
      'Trausella',
      'Vaie',
      'Varisella',
      'Vialfrè',
      'Vidracco',
      'Villarbasse',
      'Villar Dora',
      'Civiasco',
      'Guardabosone',
      'Moncrivello',
      'Garbagna Novarese',
      'Gattico',
      'Massino Visconti',
      'Nebbiuno',
      'Oleggio Castello',
      'Pettenasco',
      'Arguello',
      'Barbaresco',
      'Bastia Mondovì',
      'Bergolo',
      'Bernezzo',
      'Bonvicino',
      'Bosia',
      'Brondello',
      'Camo',
      'Caramagna Piemonte',
      'Cardè',
      'Castellar',
      'Castellinaldo',
      'Castellino Tanaro',
      'Castiglione Falletto',
      'Castiglione Tinella',
      'Grinzane Cavour',
      'Ceresole Alba',
      'Cigliè',
      'Cissone',
      "Corneliano d'Alba",
      'Faule',
      'Feisoglio',
      'Gottasecca',
      'Govone',
      'Isasca',
      'Lequio Tanaro',
      'Lisio',
      'Mosso',
      'Spadola',
      'Cavagnolo',
      'Scena - Schenna',
      'Albinia',
      'Cantagrillo-Casalguidi',
      'Sambuca',
      'Luco Mugello',
      'Castellina Scalo',
      'Porto Cervo',
      'Fulgatore-Torretta',
      'La California',
      'Stiava',
      'Chiassa-Tregozzano',
      'Orentano',
      'Aci Trezza',
      'Torre San Giovanni',
      'Tortoreto Lido',
      'Tonnarella',
      'Povo',
      'Torvaianica',
      'Castromediano',
      'Giorgilorio',
      'Sieci',
      'Monticelli Terme',
      'Montemerlo',
      'Vambolieri',
      'Rastignano-Carteria di Sesto',
      'Trebbo',
      'Cazzago-Ex Polo',
      'Cadenabbia',
      "Trinità d'Agultu",
      'Oscano',
      'Corrubbio',
      'Civesio',
      'Noverasco-Sporting Mirasole',
      'Fizzonasco',
      'Caleppio',
      'Vigonzone',
      'Novegro-Tregarezzo-San Felice',
      'Lisanza',
      'Focene',
      'Passoscuro',
      'Marina San Nicola',
      'Marina di Cerveteri',
      'Granarolo',
      'Ara Nova',
      'Calerno',
      'Casal Palocco',
      'Vitinia',
      'Avane',
      'Donoratico',
      'Lodrone-Darzo',
      'Acitrezza',
      'Pianello Vallesina',
      'Nova Siri Scalo',
      'Carruba',
      'Marcelli',
      'Lu Bagnu',
      'Montalbano',
      'Valgatara',
      'Fratta Terme',
      'Triscina',
      'Marina di Vasto',
      'Lavinio',
      'Lisiera',
      'Sandrà',
      'Villa Adriana',
      'Trodica',
      'Valle Santa',
      'Riscone',
      'Ried',
      'Monasterolo',
      'Tuturano',
      'Baggiovara',
      'Bastardo',
      'Ponte San Marco',
      'Vidalengo',
      'Musestre',
      'Pianoconte',
      'Bornato',
      'Caulonia Marina',
      'Cavalcaselle',
      'Arbizzano-Santa Maria',
      'Massa Finalese',
      'Roncanova',
      'Santa Lucia',
      'Grottola',
      'Torchiati',
      'Castelletto',
      'Praticello di Gattatico',
      'Casale di Mezzani',
      'Calderino',
      'Santo Stefano',
      'Cras',
      'Vico',
      'Caseo',
      'Tagliuno',
      'Terraverde-Corte Palasio',
      'Villa Fornace',
      'Miola di Pinè',
      'Roata Rossi',
      'Cancello ed Arnone',
      'Castrocaro Terme e Terra del Sole',
      'Bellaria-Igea Marina',
      "Ca' Savio",
      'Marconia',
      'Corpo Reno',
      'Montepaone Lido',
      'Priula-Colfosco',
      'Sambuceto',
      'Arcavacata',
      'Cona',
      'Basilicagoiano',
      'Ponte Felcino',
      'Margine Coperta-Traversagna',
      'Marina di Ardea-Tor San Lorenzo',
      'Trestina',
      'Marco Simone',
      'Rablà',
      'Latina Scalo',
      'San Mamete',
      'San Bernardo',
      'Tornimparte',
      'Villa Pigna',
      'Piane di Morro',
      'Villaseta',
      'Osteno',
      'Osteria Nuova',
      'Sezze Scalo',
      'Centobuchi',
      'Mantignano-Ugnano',
      'Cortoghiana',
      'Cerbara',
      'Lesna',
      'Tre Fontane',
      'Lido dei Pini',
      'Corsanico-Bargecchia',
      'Bassone',
      'Carraia',
      'Poggetto',
      'Catena',
      'Fossoli',
      'San Bartolomeo',
      'San Marco',
      'San Vigilio',
      'Marina di Casal Velino',
      'Scarlino Scalo',
      'Ponte Pattoli',
      'Cologna Spiaggia',
      'Santa Maria Apparente',
      "Sant'Andrea Ionio Marina",
      'Mezzate',
      'San Bovio-San Felice',
      'Fontana delle Monache',
      'Montignano-Marzocca',
      'Centinarola',
      'Fenile',
      'Weather Station',
      'Cropani Marina',
      'Roccelletta',
      'Monterusciello',
      'Romano Banco',
      'Casa Santa',
      'Paolo VI',
      'Breo',
      'Progresso',
      'Mejaniga',
      'La Massimina-Casal Lumbroso',
      'Chiugiana-La Commenda',
      'San Zeno-San Giuseppe',
      'Spicchio-Sovigliana',
      'Ponte Cingoli',
      "Piattoni-Villa Sant'Antonio",
      'Fiesso',
      'Canalicchio',
      'Colombiera-Molicciara',
      'Selcetta',
      'Andreotta',
      'Casano-Dogana-Isola',
      'Ciaculli',
      "Vill'Albese",
      'Villatora',
      'Pontasserchio',
      'Veggia-Villalunga',
      'Quadrivio',
      "Mandriola-Sant'Agostino",
      'Gerbole',
      'Lancusi-Penta-Bolano',
      'Frescada',
      'San Donnino',
      "Piazza del Galdo-Sant'Angelo",
      'Lanzara',
      'Airali',
      'Vado Centro',
      'Eremo',
      'San Nicolò a Tordino',
      'Villa Raspa',
      'Canova-San Zeno',
      'Santa Margherita',
      'Setteville',
      'Pagliare',
      'Rometta Marea',
      'Piazzola',
      'Roveleto',
      'Colle Verde',
      'San Martino Bassa',
      'Regoledo',
      'Ciconia',
      'Strà-Montanara-Pieve',
      'Valle Martella',
      'Torretta-Scalzapecora',
      'Tivolille Pasquali-Merenzata',
      'Torelli-Torrette',
      'Piazza-Tralia-Pendolo',
      'Villa Rosa',
      'Rogoredo-Valaperta-Rimoldo',
      'Su Planu',
      'Genio Civile',
      'Carobbio',
      'Borgo Stazione',
      'Capaccio Scalo',
      'Piazza Roma',
      'Parco Leonardo',
      'Toscanella',
      'Lunetta-Frassino',
      'Vighignolo',
      'Santa Teresa',
      'Osteria Grande',
      'Roè',
      'Laghetto',
      'Chianchitta-Trappitello',
      'Onè',
      'San Brancato',
      'Bojon-Lova',
      'Grunuovo-Campomaggiore San Luca',
      'Mercatale-San Quirico',
      'Bolladello-Peveranza',
      'Castelnuovo Vomano',
      'Valcanneto',
      'San Floriano-Olmi',
      'Fossignano',
      'Parco Scizzo-Parchitello',
      'Manoppello Scalo',
      'Garino',
      'Annunziata',
      "Villa d'Agri",
      'Torre Caracciolo',
      'Piazza Caduti',
      'Campo Limpido-Favale',
      'Marina di Davoli',
      'Crocetta-Nogarè',
      'Mombretto',
      'Antessano',
      'Bormida-Genepro',
      'Bellante Stazione',
      'Levata',
      'Lido di Fermo',
      'Parmezzana Calzana',
      'Cascine-La Croce',
      "Casette d'Ete",
      'Vigliano-Bettolino',
      'Prato Perillo',
      'Castelnovo',
      'Cava-Cuculera Nobile',
      'Colombella',
      'Roteglia',
      'Carrubazza-Motta',
      'S.P. in Palazzi',
      'Starza Vecchia',
      'Cerza',
      'Ravina',
      'Valleggia',
      'Monticello',
      'Chiesa',
      'Capezzano-Cologna',
      'Ceresane-Curanuova',
      'Marzanello',
      'Limidi',
      'Stazione-Fornola',
      'Stella',
      'Pratole',
      'Ponticella',
      'Le Rughe',
      'Pascarola',
      'Ferriera',
      'San Damaso',
      'Chef-Lieu',
      'San Francesco',
      'Torchione-Moia',
      'Vighizzolo',
      'Brognoligo-Costalunga',
      'Coperchia',
      'Casali-San Potito',
      'Bolognano-Vignole',
      'Taggì',
      'Grignano Polesine',
      'Trecella',
      'Praticello',
      'Passirana',
      'Lamie di Olimpie-Selva',
      'Cantalupo',
      'Albuccione',
      'Sinigo',
      'Mussotto',
      'Robegano',
      'Cintolese',
      'Talponada',
      'Rilievo',
      'Gallo-Tre Re-Mezzana Corti',
      'Osigo',
      'Romito Magra',
      'Casarea',
      'Castelminio',
      'Gaggino',
      'Riozzo',
      'Vidardo',
      'Borgo Santa Maria Immacolata',
      'Cappone',
      'Meledo',
      'Zona 179',
      'Pionca',
      'Stazione',
      'Poggio dei Pini',
      'Lucino',
      'Pezzano-Filetta',
      'Piano dei Geli',
      'Scannabue-Cascine Capri',
      'Monte Migliore La Selvotta',
      'Caldine',
      'Manesseno',
      'Giammoro',
      'Piediripa',
      'Santa Maria di Non',
      'Guardavalle Marina',
      'Gerbido',
      'Selino Basso',
      'Longara',
      'San Gregorio',
      'Capriano',
      'Galta',
      'Montan-Angelin-Arensod',
      'Maugeri',
      'Montedecoro',
      'Dodici Morelli',
      'Carchitti',
      'Carrara-Pontenuovo',
      'Area Produttiva',
      'Formica',
      'Baganzola',
      'Villa Raverio',
      'Saint Maurice',
      'Fabrizio',
      'Pizzano',
      'Bandito',
      'Villa Musone',
      'Bivio Santa Cecilia',
      'Penitro',
      'Zoccorino-Vergo',
      'Pedalino',
      'Alpo',
      'Banzano',
      'Millepini',
      'Tagliaferro',
      'Mulino',
      'Città Giardino',
      'Piano Maglio-Blandino',
      'Bodio',
      'Arino',
      "Sant'Angelo in Villa-Giglio",
      "Sant'Agata Martesana",
      'Santa Monica-Cella',
      'Villa San Giuseppe',
      'Geromina',
      'Roreto',
      'Amodio-Massariola',
      'Faella',
      'San Tommaso Tre Archi',
      'Capezzano Inferiore',
      'Fornase',
      'Nocera Scalo',
      'Tonengo-Casale',
      'Onigo',
      'Villa Ceccolini',
      'Pasta',
      'Villaggio del Pino-Le Ginestre',
      'San Cipriano-S.Barbara-Centinale',
      'Piana San Raffaele',
      'San Michele Tiorre',
      'Piano di Conca',
      'Montelera',
      'Casco',
      'Gello',
      'Drubiaglio-Grangia',
      'Colle Campano-Scrima',
      'Barbisano',
      'Malavicina',
      'Colli di Enea',
      'Tregasio',
      'Macchia',
      'Moniego',
      'Gabbio-Cereda-Ramate',
      'Buffalora-Bettole',
      'Materdomini',
      'Macine-Borgo Loreto',
      'Ottava',
      'Villa Ciambra',
      'Baratte',
      'Metato',
      'Acconia',
      'Quattro Strade',
      'Bottega',
      'Valenzatico',
      'Savonera',
      'Matinella',
      'Montano',
      'San Pierino',
      'Borghetto-Melara',
      'Domio',
      'Canonica',
      'Savarna-Conventello',
      'Sala al Barro',
      'Cà Dè Fabbri',
      'Litta Parodi-Cascinagrossa',
      'Bertipaglia',
      'San Donato',
      'Padiglione',
      'Villair-Amerique',
      'Brenno Useria',
      'Torrion Quartara',
      'Bastia',
      'Borgo Melano',
      'San Giovanni-San Bernardino',
      'Caniezza',
      'Dragonea',
      'Bivio di Capanelle',
      'La Rotta',
      'Sesto Cremonese',
      'Maresso',
      'Campanella-Gianforma',
      'Fonte Umano-San Martino Alta',
      "Ponton dell'Elce",
      'Pagliarone',
      'Guidomandri Marina',
      'Sciarborasca',
      'Cavallina',
      'Sasi',
      'Cuasso al Piano',
      'San Salvo Marina',
      'Battaglione-Bagnara',
      "Ca' Rainati",
      'Tabiago-Cibrone',
      'Bagno',
      'Gavarno-Tribulina',
      'Stazione Montalto-Coretto',
      'Tronca',
      'Grignano',
      'Velasca',
      'Cavino',
      'Asparetto',
      'Locara',
      'Zorlesco',
      'Oltre Brenta',
      'Cepina',
      'Lamie',
      'Pineta',
      'Fénis',
      'Casette Verdini',
      'Pozzale-Case Nuove',
      'San Giuseppe le Prata-Cotropagno',
      'San Michele dei Mucchietti',
      'Casola',
      'Laura',
      'Case Campoli-Panetta',
      'Pontegradella',
      'Montesolaro',
      'Quaderni',
      'Gorgo della Chiesa',
      'Castelceriolo',
      'Girardi-Bellavista-Terrazze',
      'Venegazzù',
      'Palazzolo',
      'Vergnasco',
      'San Nicola',
      'Cologna-Caraverio',
      'Pratissolo',
      'San Fedele-Lusignano',
      'Premenugo',
      'San Martino in Trignano',
      'Figino',
      'Abbazia',
      'Belvedere-Piano Tavola',
      'Manzolino',
      'Fraviano',
      'Baraggia',
      'Cavoni-Ginestreto',
      'Piangaiano',
      'Pulce',
      'Villaguattera',
      'La Botte',
      'Niviano',
      'Strada',
      'Mandrogne',
      'Bellavista',
      'Carifi-Torello-Priscoli',
      'San Jacopo al Girone',
      'Folzano',
      'Piegolelle-San Bartolomeo',
      'Cavazzona',
      'Via Lippia',
      'Torre Colonna-Sperone',
      'La Forma',
      'Ronco',
      'Campocavallo',
      'Camnago-Boscone',
      'San Pietro Belvedere',
      'Piane',
      'Prato di Coppola',
      'Ramiola',
      'Casalazzara',
      'Riva',
      'Moncucco',
      'Raffa',
      'Mantegazza',
      'Felina',
      'Zona Industriale',
      'Villaggio del Sole',
      'Arlate',
      "Madonna Dell'Albero",
      'Country Park',
      'Cereda-Cozza Cornedo',
      'Pianura Vomano',
      'Ponterio-Pian di Porto',
      'Sam Marino',
      'Gorzano',
      'Agliandroni-Paternella',
      'Villa Fontana',
      'Figliaro',
      'Monte Caminetto',
      'Bubano',
      'Costaroni',
      'Colleranesco',
      'Ballò',
      'Negrone',
      'Rio Salso-Case Bernardi',
      'Rivotorto',
      'Rettifilo-Vannullo',
      "Villa d'Asolo",
      'Osteria del Gatto',
      'Musci',
      "Monti d'Arena-Bosco Caggione",
      'Lodetto',
      'Cunettone-Villa',
      'Monticello di Fara',
      'Valle di Vado',
      'Lavinaio-Monterosso',
      'Valmadonna',
      'Carrozziere',
      'Montebonello',
      'Monte San Giovanni',
      'Nigoline-Bonomelli',
      'San Vito al Mantico',
      'Sabbionara',
      'Riccò',
      'Uggia-Pazzera-Bizzarrino',
      'Castelmassimo',
      'Villapinta',
      'Poianella',
      'Casale',
      'Tetti Neirotti',
      'Borgo',
      'Caprara',
      'Cerello-Battuello',
      'Bancali',
      'Sambughe',
      'Ghiare-Madonna',
      'Vigardolo',
      'Manera',
      'Roggione',
      'Giovenzano',
      'Vadue',
      'Casa del Diavolo',
      'Forche',
      'Barbiano',
      'Novagli',
      'Boscochiaro',
      'Villaggio Residenziale',
      'Costa-Barco',
      'Franche',
      'Berzantina',
      'La Rosa',
      'San Vito-Cerreto',
      'Guinzano',
      'Fornole',
      'Campofiorenzo-California',
      'Montelarco',
      'Borgo Massano',
      'Sfaranda',
      'Valle San Bartolomeo',
      'Sacca',
      'Cannizzaro-Favara',
      'Regolelli',
      'Rotta',
      'Squillace Lido',
      'Mazara II',
      'Le Forna',
      'Mimosa-Poggio Verde-Nuova Comunità',
      'Capriglia',
      "Sant'Andrea",
      'Fantasina',
      'Monte San Marino',
      'Marina',
      'Giacalone',
      'Spinimbecco',
      'Monte',
      'Tombelle',
      'Pievedizio',
      'Fuorni',
      'Barberi',
      'Bosco Ex Parmigiano',
      'Campochiesa',
      'Pietre',
      'Masseria Vecchia Ovest',
      'Rosegaferro',
      'Colle Mainello',
      "Sant'Albino",
      'Cantinella',
      'Casone',
      'Prata Centro',
      'Martelli-Laganosa',
      'Chiesino-Collodi',
      'Ricca',
      'Foresta',
      'Solara',
      'Cellore',
      'Isca Marina',
      'Lacugnano',
      'Il Romito',
      'Gabella',
      'Colubro',
      'Badile',
      'Belverde',
      'Furato',
      'Gliaca',
      'Bivio San Polo',
      'Borgo Fornari-Pieve',
      'Marocchi',
      'Pian di Mugnone',
      'Spartimento',
      'Garbagnate',
      'Villafranca',
      'Campo',
      'Brongio',
      'Armetta',
      'Provezze',
      'Gavarno Rinnovata',
      'Casate',
      'Ludriano',
      'Triginto',
      'Argine',
      'Val di Cava',
      'Costa Lambro',
      'Cartiera-Stazione',
      'Osteria',
      'Malche-Santa Croce-Serroni',
      'Pradelle',
      'Valli',
      'Pennisi',
      'Campese',
      'Moggio di Sotto',
      'San Pietro in Volta',
      'Lambrinia',
      'Novoledo',
      'Ozzano Taro',
      'Mantiglia di Ardea',
      'Stazione Masotti',
      'Badia al Pino',
      'Caldierino-Rota',
      'Crispi Cavour',
      'Puginate',
      'Rosario',
      'Ardole San Marino',
      'Terradura',
      'Pezzan',
      'Bora Bassa',
      'San Defendente',
      'Madrano-Canzolino',
      'Fane',
      'Alberi',
      'Capitan Loreto',
      'Nosadello',
      'Stazione di Allerona',
      'Passaggio',
      'Salino',
      'Vaccheria',
      'Villassio',
      'Camilleri-Vallelata',
      'Vigliatore 2',
      'Santa Corinna',
      'Isoverde',
      'Capirro',
      'Pilastro',
      'Molella',
      'Cascinare',
      'Bargano',
      'Molino-Mozzi-Bittarelli',
      'San Giovanni-Patoni',
      'Cerquotti-Madonna del Piano',
      'Pasteria-Lapide',
      'Badalasco',
      'Barone',
      'Duomo',
      'Prepezzano',
      'Gaiano',
      'Sassacci',
      'Tavo',
      'Canavaccio',
      'Cendon',
      'Favari-Avatanei',
      'Santa Croce Scuole',
      'Forette',
      'Buso',
      'Poggio Ellera',
      'San Zeno',
      'Faraldo-Nocelleto',
      'Montalto',
      'Casalini',
      "Porto d'Adda",
      'Villa San Filippo',
      'Cimbro',
      "Cassino d'Alberi",
      'Torrazza dei Mandelli',
      'Bulgorello',
      'Cascina Elisa',
      'Villaggio Montegrappa',
      'Brozzo',
      'Leone-Santa Elisabetta',
      'Brezza',
      'Borgo Lotti',
      'Pugliano',
      'Strada in Casentino',
      'Ronchi di Campanile',
      'Agrate',
      'Cipolleto',
      'Pontelangorino',
      'Laxolo',
      'Calozzo',
      'Stravignino',
      'La Place',
      'San Nico',
      'Arci-Empolitana',
      'Orzignano',
      'Colle del Pino',
      'Fellegara',
      'Colle Spina',
      'Azzano',
      'Giavenale',
      'Pianello',
      'Arlesega',
      'Calmasino',
      'Pitelli',
      'Cominio',
      'Case Nuove',
      'Beregazzo',
      'Navedano',
      'Maccoli-Perrillo',
      'Tonnara di Bonagia',
      'Prosto',
      'Paquier',
      'Rifiano',
      'Nava',
      'Tettorosso',
      'Livera',
      'Braglia',
      'Agliano',
      'Codogna-Cardano',
      'Era',
      'Sottocastello',
      'Occagno',
      'Calchera-Frontale',
      'Vescovado',
      'Verla',
      'Villandro',
      'Pederiva',
      "Sant'Elena Irpina",
      'Castione',
      'Camoneone',
      'Castelletto Po',
      'Crava',
      'Bedero',
      'Rocchetta Nuova',
      'Lassolaz',
      'Berriat',
      'Feglino',
      'Bivio Mortola',
      'Mure',
      'Valzemola',
      'Evangelisti-Rubino',
      'Lases',
      'Rivalta',
      'Colla-Muggiasca',
      'Centro Urbano',
      'Dosso-Ville',
      'Gonte',
      'Villanuova',
      'Cadipietra',
      'Fontanella-Ozino',
      "Plan d'Introd",
      'Covelo',
      'Sirta',
      'San Zenone',
      'Sachet',
      'La Villa',
      'San Genesio',
      'Melia',
      'Rossi',
      'Mornico',
      'Coassolo',
      'Santa Maria dei Sabbioni',
      'Fleccia-Chianavasso',
      'Piccarello',
      'Moscheri',
      'Lanvario',
      'Santino',
      'Chiesa Nuova',
      "Villa d'Oneta",
      'Lemna',
      'Villa-Nabian',
      'Casavecchia',
      'Quincod',
      'Caines',
      'Santa Marta',
      'Zoppè di Cadore',
      'Ariola',
      'Cedessano',
      'Meiern',
      'Roapiana',
      'Antagnod',
      'Fontanafredda',
      'Molino-Pera',
      'Valentino',
      'Aurogna',
      'Servo',
      'Longhi',
      'Mongnod',
      'La Pieve-Molino',
      'Brovello',
      'Pontestazzemese',
      'Barquedo',
      'Montariolo',
      'Serone',
      'Saint-Rhémy',
      'Corigliano Scalo',
      'Camerana',
      'Ghiaie',
      'Sciaves',
      'Voltago',
      'Verano',
      "Sant'Orsola",
      'Grizzana',
      'Campolongo Maggiore',
      'Atina Inferiore',
      'Passo Ripe',
      'Ponte di Nanto',
      'San Lorenzo di Rovetta',
      'Santa Caterina dello Ionio Marina',
      'Loreto Stazione',
      'Progetto Case Coppito 3',
      'Canove di Roana',
      'Natile Nuovo',
      'Piazzolla',
      'Progetto Case Sassa Nsi',
      'Scalo di Baragiano',
      'Poggiridenti Piano',
      "Pie' Falcade",
      'Taverna Ravindola',
      'Borgata Marina',
      'Baruchella',
      'Spigno Saturnia Inferiore',
      'Castelbello',
      'Vauda Canavese Superiore',
      'Pozzaglio',
      'Marciano',
      'Ubiale',
      'Paderno',
      'Botta',
      'Velo',
      'Villa-Borgo',
      'Canistro Inferiore',
      'Vilminore',
      'Prignano',
      'San Fidenzio',
      'Ponte di Castegnero',
      'Piano di Mommio',
      'Padule-San Marco',
      'Orvieto Scalo',
      'Chianchitta-Pallio',
      'Marina di Altidona',
      'Meano',
      'Villapiana Lido',
      'Preara-Moraro-Levà Nord',
      'Codiverno',
      'Rocca Imperiale Marina',
      'Bisaccia Nuova',
      'Neive-Borgonovo',
      'Piedimonte San Germano Alta',
      'Falerna Scalo',
      'San Venanzio',
      'Gazzolo-Volpino',
      'Fabro Scalo',
      'Villanova del Ghebbo Canton',
      'Saponara Marittima',
      'Arbia',
      'Piane di Montegiorgio',
      'Orbetello Scalo',
      'Molinetto',
      'Ponte Sasso',
      'Calvi Risorta',
      'Leporano Marina',
      'Tortora Marina',
      'Santa Maria la Stella',
      'Colleverde II',
      'Chiusi Scalo',
      'Cetraro Marina',
      'Venetico Marina',
      'Orte Scalo',
      'Corcagnano',
      'Due Carrare',
      'Licinella-Torre di Paestum',
      'Torvaianica Alta',
      'Gerbole-Zucche',
      'Nucleo Industriale di Bazzano',
      'Ponte a Poppi',
      'Muzza di Cornegliano Laudense',
      'Poasco-Sorigherio',
      "San Carlo All'Arena",
      'Vago',
      "Ca' Morosini",
      'Dimaro-Folgarida',
      'Mappano',
      'Campo dei Fiori',
      'bishopric of Perugia',
      'Roman catholic diocese of Terni',
      'Chiaia',
      'Chiaiano',
      'Vicaria',
      'Pendino',
      'Arenella',
      'Avvocata',
      'Montecalvario',
      'Scampia',
      'San Pietro a Patierno',
      'Porto',
      'Monte Rosello',
      'Arcella',
      "Sant'Eufemia della Fonte",
      "Casal de' Pazzi",
      'Tor Tre Teste',
      'Tor Vergata',
      'Giardinetti-Tor Vergata',
      'Casal Bertone',
      "Farra d'lsonzo",
    ],
  },
  { country: 'Jersey', cities: ['Saint John', 'Saint Helier', 'Le Hocq'] },
  {
    country: 'Jamaica',
    cities: [
      'Yallahs',
      'Williamsfield',
      'Wakefield',
      'Ulster Spring',
      'Trinity Ville',
      'Stony Hill',
      'Spanish Town',
      'Southfield',
      'Siloah',
      'Seaforth',
      'Savanna-la-Mar',
      'Santa Cruz',
      'Sandy Bay',
      'Saint Ann’s Bay',
      'Runaway Bay',
      'Rocky Point',
      'Rock Spring',
      'Riversdale',
      'Richmond',
      'Race Course',
      'Porus',
      'Port Royal',
      'Portmore',
      'Port Maria',
      'Port Antonio',
      'Point Hill',
      'Petersfield',
      'Oracabessa',
      'Old Harbour Bay',
      'Old Harbour',
      'Ocho Rios',
      'New Kingston',
      'Negril',
      'Nain',
      'Morant Bay',
      'Moore Town',
      'Montego Bay',
      'Moneague',
      'Mona Heights',
      'Mocho',
      'May Pen',
      'Mavis Bank',
      'Maroon Town',
      'Mandeville',
      'Manchioneal',
      'Malvern',
      'Maggotty',
      'Lucea',
      'Lluidas Vale',
      'Lionel Town',
      'Linstead',
      'Lacovia',
      'Kingston',
      'Kellits',
      'Islington',
      'Hope Bay',
      'Hayes',
      'Half Way Tree',
      'Gordon Town',
      'Golden Grove',
      'Gayle',
      'Falmouth',
      'Ewarton',
      'Easington',
      'Duncans',
      'Discovery Bay',
      'Darliston',
      'Dalvey',
      'Constant Spring',
      'Coleyville',
      'Chapelton',
      'Cascade',
      'Cambridge',
      'Bull Savanna',
      'Buff Bay',
      'Bog Walk',
      'Bluefields',
      'Black River',
      'Bethel Town',
      'Bath',
      'Bamboo',
      'Balaclava',
      'Mt Peto',
      'Annotto Bay',
      'Anchovy',
      'Alligator Pond',
      'Alexandria',
      'Albert Town',
      'Limit',
      'Quickstep',
    ],
  },
  {
    country: 'Jordan',
    cities: [
      'Zaḩar',
      'Yarqā',
      'Waqqāş',
      'Wādī Mūsá',
      'Wādī as Sīr',
      'Umm Qays',
      'Umm as Summāq',
      'Umm ar Raşāş',
      'Umm al Qiţţayn',
      'Umm al Jimāl',
      'Umm al Basātīn',
      'Adhruḩ',
      'Tibnah',
      'Sūf',
      'Ash Shūnah al Janūbīyah',
      'Sammā ar Rawsān',
      'Şammā',
      'Samā as Sirḩān',
      'Sāl',
      'Sakib',
      'Şakhrah',
      'Saḩam al Kaffārāt',
      'Saḩāb',
      'Şabḩā',
      'Rujm ash Shāmī al Gharbī',
      'Rehab',
      'Raymūn',
      'Qumaym',
      'Qafqafā',
      'Nā‘ūr',
      'Mulayḩ',
      'Al Mughayyir',
      'Malkā',
      'Mā‘īn',
      'Mādabā',
      "Ma'an",
      'Kurayyimah',
      'Kufrinjah',
      'Al Fayşalīyah',
      'Kitim',
      'Bīrayn',
      'Al Murayghah',
      'Kharjā',
      'Kafr Sawm',
      'Kafr Asad',
      'Kafr Abīl',
      'Juraynah',
      'Judita',
      'Jāwā',
      'Jarash',
      '‘Izrā',
      '‘Irjān',
      'Irbid',
      'Ḩisbān',
      'Ḩawshā',
      'Ḩātim',
      'Ḩalāwah',
      'Ḩakamā',
      'Fuqū‘',
      'Dhībān',
      'Dayr Yūsuf',
      'Dayr ‘Allā',
      'Dayr Abū Sa‘īd',
      'Buşayrā',
      'Burmā',
      'Bayt Yāfā',
      'Bayt Īdis',
      'Balīlā',
      'Bal‘amā',
      'Zarqa',
      'Muthallath al Azraq',
      'Al Azraq ash Shamālī',
      '‘Ayy',
      '‘Ayn Jannā',
      '‘Ayn al Bāshā',
      'Ayl',
      'Aydūn',
      'Aţ Ţurrah',
      'Aţ Ţayyibah',
      'Al Khinzīrah',
      'Aţ Ţafīlah',
      'Aş Şubayḩī',
      'As Salţ',
      'Safi',
      'Ash Shūnah ash Shamālīyah',
      'Ash Shawbak',
      'Ash Shajarah',
      'Ar Ruwayshid',
      'Ar Ruşayfah',
      'Ar Ramthā',
      'Ar Rabbah',
      '‘Anjarah',
      'Amman',
      'Ḩayy al Quwaysimah',
      'Al Quwayrah',
      'Al Qaţrānah',
      'Al Qaşr',
      'Ghawr al Mazra‘ah',
      'Al Mazār ash Shamālī',
      'Al Mazār al Janūbī',
      'Al Maşţabah',
      'Manshīyat Banī Ḩasan',
      'Mafraq',
      '‘Allān',
      'Al Kittah',
      'Al Karāmah',
      'Qīr Moāv',
      'Al Juwayyidah',
      'Al Jubayhah',
      'Al Jīzah',
      'Al Jafr',
      'Al Ḩusaynīyah',
      'Al Ḩişn',
      'Al Hāshimīyah',
      'Al Ḩasā',
      'Al Ḩamrā’',
      'Al Fuḩayş',
      'Ḩayy al Bunayyāt',
      'Al ‘Arīḑ',
      'Aqaba',
      '‘Ajlūn',
      'Adir',
      'Al Khālidīyah',
      'Ar Rīshah',
      'Al Muwaqqar',
      'Karak City',
      'Ad Dīsah',
      'Russeifa',
      'Muthallath Şabḩā',
      'Tala Bay',
      'Muthallath Jāmi‘at Āl al Bayt',
      'Az̧ Z̧ulayl',
      'Al ‘Abdallī',
      'Dayr al Kahf',
      'Rukban',
    ],
  },
  {
    country: 'Japan',
    cities: [
      'Shingū',
      'Atsugi',
      'Akashi',
      'Zushi',
      'Zentsujichó',
      'Zama',
      'Yuzawa',
      'Yuza',
      'Gero',
      'Yunomae',
      'Yukuhashi',
      'Yūki',
      'Yugawara',
      'Yudomari',
      'Yuasa',
      'Yoshino-chō',
      'Yoshikawa',
      'Yoshii',
      'Yoshida-kasugachō',
      'Yorii',
      'Yono',
      'Yonakuni',
      'Yonago',
      'Yonabaru',
      'Yokosuka',
      'Yokohama',
      'Yokkaichi',
      'Youkaichi',
      'Yawata',
      'Honmachi',
      'Yatsuomachi-higashikumisaka',
      'Yasugichō',
      'Yasuda',
      'Yashiro',
      'Yashio',
      'Yaotsu',
      'Yao',
      'Yanaizu',
      'Yanai',
      'Yanagawa',
      'Yamato-Takada',
      'Yamazakichō-nakabirose',
      'Yamakita',
      'Yamaguchi',
      'Yamaga',
      'Yakage',
      'Yaizu',
      'Yaita',
      'Yabu',
      'Wakimachi',
      'Wake',
      'Wakasa',
      'Wajima',
      'Wadomari',
      'Utsunomiya',
      'Uto',
      'Utazu',
      'Usuki',
      'Ushibukamachi',
      'Ureshinomachi-shimojuku',
      'Urayasu',
      'Uozu',
      'Umi',
      'Uken',
      'Ujiie',
      'Uji',
      'Uenohara',
      'Ueno-ebisumachi',
      'Uekimachi-mōno',
      'Ueda',
      'Ube',
      'Tsuyama',
      'Tsushima',
      'Tsurusaki',
      'Tsuruoka',
      'Tsurugi-asahimachi',
      'Tsuruga',
      'Tsunō',
      'Tsunagi',
      'Tsuma',
      'Tsukumiura',
      'Tsukawaki',
      'Tsuiki',
      'Tsubata',
      'Tsubame',
      'Tsu',
      'Toyota',
      'Toyoshina',
      'Toyooka',
      'Toyonaka',
      'Toyokawa',
      'Toyohashi',
      'Toyohama',
      'Toyama',
      'Tottori',
      'Tosu',
      'Tonoshō',
      'Tondabayashichō',
      'Tomioka',
      'Tomigusuku',
      'Tokyo',
      'Tokuyama',
      'Tokushima',
      'Tokorozawa',
      'Tokoname',
      'Toki',
      'Tōkamachi',
      'Togitsu',
      'Tochio-honchō',
      'Tochigi',
      'Toba',
      'Tenri',
      'Tennō',
      'Tawaramoto',
      'Tatsuno',
      'Tatsunochō-tominaga',
      'Tatsugō',
      'Tateyama',
      'Tatebayashi',
      'Tarumizu',
      'Tarui',
      'Tara',
      'Tanushimarumachi-toyoki',
      'Tanuma',
      'Tano',
      'Nishi-Tokyo-shi',
      'Tanashichō',
      'Tanabe',
      'Tamano',
      'Tamana',
      'Tamamura',
      'Taketoyo',
      'Takeo',
      'Takehara',
      'Takefu',
      'Takedamachi',
      'Takayama',
      'Takatsuki',
      'Takasaki',
      'Takarazuka',
      'Takaoka',
      'Takanabe',
      'Takamori',
      'Takamatsu',
      'Takaishi',
      'Takahashi',
      'Takahama',
      'Bungo-Takada-shi',
      'Tajimi',
      'Taira',
      'Tahara',
      'Tagawa',
      'Tadotsu',
      'Tachiarai',
      'Suzuka',
      'Suzaka',
      'Suwa',
      'Susaki',
      'Sumoto',
      'Sukumo',
      'Suita',
      'Suibara',
      'Sugito',
      'Sueyoshichō-ninokata',
      'Sōka',
      'Sōja',
      'Soeda',
      'Sobue',
      'Shōbu',
      'Shōbara',
      'Shizuoka',
      'Shitara',
      'Shirone',
      'Shiraoka',
      'Shirahamachō-usazakiminami',
      'Shirahama',
      'Shiozawa',
      'Shiojiri',
      'Shinshiro',
      'Shin’ichi',
      'Shimo-tsuma',
      'Shimonoseki',
      'Shimonita',
      'Shimoichi',
      'Shimodate',
      'Shimoda',
      'Minato',
      'Nishishinminato',
      'Shimada',
      'Shimabara',
      'Shima',
      'Shiki',
      'Shiida',
      'Shido',
      'Shibushi',
      'Shibukawa',
      'Shibata',
      'Settsu',
      'Seto',
      'Setakamachi-takayanagi',
      'Satsumasendai',
      'Sekimachi',
      'Satte',
      'Sasebo',
      'Sasayama',
      'Sasaguri',
      'Sano',
      'Sanjō',
      'Sandachō',
      'Sakurai',
      'Saku',
      'Sakawa',
      'Sakata',
      'Sakaki',
      'Sakaiminato',
      'Sakaidechō',
      'Sakai-nakajima',
      'Sakai',
      'Sakado',
      'Saka',
      'Saiki',
      'Saijō',
      'Sagara',
      'Saga',
      'Sabae',
      'Ryūō',
      'Ryōtsu-minato',
      'Ōzu',
      'Oyama',
      'Ōyama',
      'Owase',
      'Ōuda',
      'Ōtsuki',
      'Ōtsu',
      'Ōtake',
      'Ōta',
      'Osaka',
      'Onomichi',
      'Onoda',
      'Ono',
      'Ōno-hara',
      'Onna',
      'Ōmuta',
      'Ōmura',
      'Omi',
      'Ōme',
      'Ōmamachō-ōmama',
      'Ōmachi',
      'Ōkuchi-shinohara',
      'Okegawa',
      'Okazaki',
      'Okayama',
      'Okaya',
      'Ōkawa',
      'Ojiya',
      'Ōita',
      'Ōiso',
      'Ōi',
      'Ogunimachi',
      'Ogose',
      'Ogōri-shimogō',
      'Ōgimi',
      'Ogawa',
      'Ogano',
      'Ōgaki',
      'Odawara',
      'Ōdachō-ōda',
      'Ōbu',
      'Obita',
      'Obama',
      'Nyūzen',
      'Numazu',
      'Numata',
      'Nonoichi',
      'Nōgata',
      'Noda',
      'Nobeoka',
      'Nishiwaki',
      'Nishio',
      'Nishinoomote',
      'Nishinomiya-hama',
      'Nirasaki',
      'Ninomiya',
      'Nikkō',
      'Niitsu-honchō',
      'Niimi',
      'Niihama',
      'Niigata',
      'Nichinan',
      'Naze',
      'Narutochō-mitsuishi',
      'Nara-shi',
      'Nanao',
      'Namerikawa',
      'Nambu',
      'Nakatsugawa',
      'Nakatsu',
      'Nakanojōmachi',
      'Nakano',
      'Nakamura',
      'Nakama',
      'Naha',
      'Nagoya',
      'Nago',
      'Nagata',
      'Nagasu',
      'Nagasaki',
      'Nagareyama',
      'Nagaoka',
      'Naganohara',
      'Nagano',
      'Nagahama',
      'Nabari',
      'Musashino',
      'Muroto-misakicho',
      'Muramatsu',
      'Murakami',
      'Mukō',
      'Muikamachi',
      'Motoyama',
      'Morohongō',
      'Moriyama',
      'Moriguchi',
      'Mori',
      'Mizunami',
      'Miyoshi',
      'Miyazu',
      'Miyazaki',
      'Miyanoura',
      'Miyakonojō',
      'Miyajima',
      'Miyada',
      'Miyata',
      'Mitsuke',
      'Mitsukaidō',
      'Mitake',
      'Mishima',
      'Mino',
      'Minano',
      'Minamirinkan',
      'Minamata',
      'Minakuchichō-matoba',
      'Minabe',
      'Mikuni',
      'Miki',
      'Mihara',
      'Mifune',
      'Nagiso',
      'Mibu',
      'Menuma',
      'Matsuzaki',
      'Matsutō',
      'Matsumoto',
      'Matsue',
      'Matsudo',
      'Matsubase',
      'Matsubara',
      'Masuda',
      'Maruoka',
      'Kamimaruko',
      'Marugame',
      'Manazuru',
      'Makurazaki',
      'Makinohara',
      'Maki',
      'Maizuru',
      'Maibara',
      'Maebashi',
      'Maebaru-chūō',
      'Machida',
      'Kyoto',
      'Kushimoto',
      'Kushikino',
      'Kusatsu',
      'Kurume',
      'Kuroda',
      'Kurio',
      'Kurihashi',
      'Kure',
      'Kurayoshi',
      'Kurashiki',
      'Kumamoto',
      'Kumagaya',
      'Kukichūō',
      'Kudamatsu',
      'Kozakai-chō',
      'Koshima',
      'Koshigaya',
      'Koseda',
      'Kōnosu',
      'Kōnan',
      'Komoro',
      'Komono',
      'Komatsushimachō',
      'Komatsu',
      'Komaki',
      'Kokubunji',
      'Kokubu-matsuki',
      'Koga',
      'Kōfu',
      'Kodamachō-kodamaminami',
      'Kōchi',
      'Kochi',
      'Kobe',
      'Kobayashi',
      'Kiyosu',
      'Kitsuki',
      'Kitakyushu',
      'Kitakata',
      'Kitahama',
      'Kitagata',
      'Kishiwada',
      'Kisarazu',
      'Kisai',
      'Kiryū',
      'Kin',
      'Kikuchi',
      'Kazo',
      'Kawasaki',
      'Kawara',
      'Kawanishi',
      'Kawamoto',
      'Kawaminami',
      'Kawaguchi',
      'Kawagoe',
      'Katsuyama',
      'Kasukabe',
      'Kasugai',
      'Kashiwazaki',
      'Kashiwa',
      'Kashima',
      'Kashihara-shi',
      'Kashihara',
      'Kaseda-shirakame',
      'Kasaoka',
      'Kasamatsuchō',
      'Kasagi',
      'Kariya',
      'Kariwa',
      'Karatsu',
      'Kanzakimachi-kanzaki',
      'Kanuma',
      'Kanoya',
      'Kan’onjichō',
      'Kannabechō-yahiro',
      'Kanie',
      'Kanekomachi',
      'Kanda',
      'Kanazawa',
      'Kanaya',
      'Kamojimachō-jōgejima',
      'Kamogatachō-kamogata',
      'Kamo',
      'Kamirenjaku',
      'Kaminokawa',
      'Kamiichi',
      'Kamigōri',
      'Kameyama',
      'Kameshima',
      'Kameoka',
      'Kameda-honchō',
      'Kanbara',
      'Kamakura',
      'Kakogawachō-honmachi',
      'Kakegawa',
      'Kakamigahara',
      'Kashima-shi',
      'Kajiki',
      'Kaizuka',
      'Kainan',
      'Kagoshima',
      'Kadoma',
      'Kadogawa',
      'Kadena',
      'Izumo',
      'Izumisano',
      'Izumiōtsu',
      'Izumi',
      'Izena',
      'Iwatsuki',
      'Iwata',
      'Iwakura',
      'Iwakuni',
      'Iwai',
      'Iwade',
      'Itsukaichi',
      'Itoman',
      'Itoigawa',
      'Itō',
      'Itami',
      'Ishiki',
      'Ishikawa',
      'Ishii',
      'Ishige',
      'Ishigaki',
      'Isesaki',
      'Isehara',
      'Ise',
      'Isawa',
      'Isahaya',
      'Inuyama',
      'Ino',
      'Innoshima',
      'Inazawa',
      'Inami',
      'Ina',
      'Imarichō-kō',
      'Imaichi',
      'Ikoma',
      'Ikeda',
      'Ikedachō',
      'Ijūin',
      'Iizuka',
      'Iiyama',
      'Iijima',
      'Iida',
      'Ichinomiya',
      'Ibusuki',
      'Ibaraki',
      'Ibara',
      'Hotaka',
      'Hōryūji',
      'Honjō',
      'Hondomachi-hondo',
      'Hōfu',
      'Hitoyoshi',
      'Hita',
      'Hisai-motomachi',
      'Hiroshima',
      'Hiratsuka',
      'Hiratachō',
      'Hirara',
      'Hirakata',
      'Hirado',
      'Hioki',
      'Hinokage',
      'Hino',
      'Himimachi',
      'Himeji',
      'Hikone',
      'Hiji',
      'Higashi-ōsaka',
      'Hekinan',
      'Heda',
      'Hayashima',
      'Hayama',
      'Hatsukaichi',
      'Hatogaya-honchō',
      'Hashimoto',
      'Hanyū',
      'Hannō',
      'Handa',
      'Hamanoichi',
      'Hamamatsu',
      'Hamakita',
      'Hamada',
      'Hakui',
      'Hakone',
      'Haibara-akanedai',
      'Hagi',
      'Katsuren-haebaru',
      'Hadano',
      'Hachiōji',
      'Ōmihachiman',
      'Gyōda',
      'Gushikawa',
      'Gōtsuchō',
      'Gotenba',
      'Gosen',
      'Gose',
      'Gojō',
      'Gōdo',
      'Gobō',
      'Ginoza',
      'Ginowan',
      'Gifu-shi',
      'Gamagōri',
      'Futtsu',
      'Honchō',
      'Fukuyama',
      'Fukuroi',
      'Fukura',
      'Fukuoka',
      'Fukumitsu',
      'Nishifukuma',
      'Fukui-shi',
      'Fukuechō',
      'Fukuchiyama',
      'Fukiage-fujimi',
      'Fukayachō',
      'Fujisawa',
      'Fujioka',
      'Fujinomiya',
      'Fujieda',
      'Fuji',
      'Fuchūchō',
      'Enzan',
      'Daitō',
      'Chōfu',
      'Chizu',
      'Chiryū',
      'Chino',
      'China',
      'Chikushino-shi',
      'Chigasaki',
      'Chichibu',
      'Chatan',
      'Beppu',
      'Ayabe',
      'Aya',
      'Awara',
      'Atami',
      'Asuka',
      'Ashiya',
      'Ashikaga',
      'Arita',
      'Arai',
      'Annaka',
      'Anjō',
      'Anan',
      'Anamizu',
      'Amagi',
      'Amagasaki',
      'Ama',
      'Akune',
      'Aki',
      'Aioi',
      'Ageoshimo',
      'Ie',
      'Okinawa',
      'Ōsaki',
      'Kushima',
      'Ojika',
      'Hikari',
      'Seirō',
      'Abu',
      'Nagato',
      'Hasuda',
      'Kamifukuoka',
      'Sayama',
      'Fussa',
      'Asaka',
      'Wako',
      'Shimotoda',
      'Kimitsu',
      'Miura',
      'Wakayama',
      'Iyo',
      'Uwajima',
      'Matsuyama',
      'Masaki-chō',
      'Kawanoechō',
      'Imabari-shi',
      'Hōjō',
      'Fukaura',
      'Yotsukaidō',
      'Yonezawa',
      'Yokote',
      'Yokoshiba',
      'Yōkaichiba',
      'Yanagawamachi-saiwaichō',
      'Yamoto',
      'Yamagata',
      'Yamada',
      'Yachimata',
      'Yabuki',
      'Watari',
      'Wakuya',
      'Wada',
      'Ushiku',
      'Tsukuba',
      'Toride',
      'Tōno',
      'Tomobe',
      'Tomiya',
      'Tōgane',
      'Tendō',
      'Tanagura',
      'Tako',
      'Takahata',
      'Takahagi',
      'Tagajō-shi',
      'Sukagawa',
      'Shizukuishi',
      'Shisui',
      'Shiroishi',
      'Shiroi',
      'Shiogama',
      'Shinjō',
      'Shinmachi',
      'Shibayama',
      'Sendai',
      'Sawara',
      'Sakura',
      'Sagae',
      'Ryūgasaki',
      'Rifu',
      'Ōtsuchi',
      'Ōtawara',
      'Ōtaki',
      'Onagawa Chō',
      'Ōmiya',
      'Omigawa',
      'Ōmagari',
      'Okunoya',
      'Ōkawara',
      'Ōishida',
      'Ōhara',
      'Ōfunato',
      'Obanazawa',
      'Ōarai',
      'Ōami',
      'Nihommatsu',
      'Natori-shi',
      'Narutō',
      'Narita',
      'Namie',
      'Naka',
      'Nagai',
      'Murata',
      'Motomiya',
      'Motegi',
      'Moriya',
      'Morioka',
      'Mooka',
      'Mobara',
      'Mizusawa',
      'Miyako',
      'Mito',
      'Miharu',
      'Matsushima',
      'Mashiko',
      'Marumori',
      'Makabe',
      'Kuroiso',
      'Kōzaki',
      'Kōriyama',
      'Koori',
      'Kogota',
      'Kitakami',
      'Kitaibaraki',
      'Kesennuma',
      'Kemigawa',
      'Kamogawa',
      'Katsuura',
      'Katsuta',
      'Katori-shi',
      'Kasama',
      'Karasuyama',
      'Kaneyama',
      'Kanegasaki',
      'Kaminoyama',
      'Kamaishi',
      'Kakunodatemachi',
      'Kakuda',
      'Iwase',
      'Iwanuma',
      'Iwaki',
      'Iwaizumi',
      'Itako',
      'Ishioka',
      'Ishinomaki',
      'Inawashiro',
      'Iizakamachi',
      'Ichinoseki',
      'Ichihara',
      'Hokota',
      'Hobaramachi',
      'Hitachi',
      'Higashine',
      'Hasaki',
      'Hanawa',
      'Hanamaki',
      'Gojōnome',
      'Furukawa',
      'Funehikimachi-funehiki',
      'Funaishikawa',
      'Funagata',
      'Fukushima',
      'Fujishiro',
      'Edosaki',
      'Date',
      'Daigo',
      'Choshi',
      'Chōnan',
      'Chiba',
      'Asakawa',
      'Asahi',
      'Ami',
      'Akita',
      'Abiko',
      'Ōhira',
      'Motomachi',
      'Yūbari',
      'Wassamu',
      'Wakkanai',
      'Utashinai',
      'Uryū',
      'Urakawa',
      'Tsukigata',
      'Toyoura',
      'Toyotomi',
      'Toyokoro',
      'Tomari',
      'Tomamae',
      'Tomakomai',
      'Tōbetsu',
      'Teshio',
      'Teshikaga',
      'Mutsu',
      'Takinoue',
      'Takikawa',
      'Takanosu',
      'Suttsu',
      'Sunagawa',
      'Sōbetsu',
      'Shosanbetsu',
      'Shizunai-furukawachō',
      'Shiraoi',
      'Shiranuka',
      'Shintoku',
      'Furubira',
      'Shimukappu',
      'Shinshimokawa',
      'Shimo-furano',
      'Shimizu',
      'Shikabe',
      'Minamishibetsuchō',
      'Shibetsu',
      'Nishiokoppe',
      'Sarabetsu',
      'Sapporo',
      'Sai',
      'Rusutsu',
      'Rumoi',
      'Rikubetsu',
      'Rausu',
      'Rankoshimachi',
      'Bifuka',
      'Pippu',
      'Ōwani',
      'Otofuke',
      'Otobe',
      'Otaru',
      'Oshamambe',
      'Ōmu',
      'Ōma',
      'Okoppe',
      'Oketo',
      'Ōdate',
      'Obirachō',
      'Obihiro',
      'Noshiro',
      'Noboribetsu',
      'Niki',
      'Niikappu',
      'Nemuro',
      'Nayoro',
      'Namioka',
      'Nakatonbetsu',
      'Nakashibetsu',
      'Mobetsu',
      'Nakagawa',
      'Naie',
      'Muroran',
      'Mombetsu',
      'Misawa',
      'Yoichi',
      'Mikasa',
      'Makubetsu',
      'Kuzumaki',
      'Kushiro',
      'Kuromatsunai',
      'Kuroishi',
      'Kuji',
      'Koshimizu',
      'Kosaka',
      'Hiranai',
      'Shimokizukuri',
      'Kitami',
      'Kimobetsu',
      'Kikonai',
      'Asahimachi',
      'Kamishihoro',
      'Kaminokuni',
      'Kamikawa',
      'Kamiiso',
      'Iwanai',
      'Iwamizawa',
      'Itayanagi',
      'Ishikari',
      'Imabetsu',
      'Ichinohe',
      'Horonobe',
      'Horokanai',
      'Hokuryū',
      'Kitahiroshima',
      'Hirosaki',
      'Hiroo',
      'Hakodate',
      'Hachinohe',
      'Haboro',
      'Furano',
      'Fukagawa',
      'Fujisaki',
      'Esashi',
      'Kanaura',
      'Ebetsu',
      'Chitose',
      'Chippubetsu',
      'Biratori',
      'Biei',
      'Bibai',
      'Mashike',
      'Ashoro',
      'Ashibetsu',
      'Asahikawa',
      'Aomori',
      'Akkeshi',
      'Akaigawa',
      'Akabira',
      'Ajigasawa',
      'Aibetsu',
      'Abira',
      'Abashiri',
      'Yomogita',
      'Goshogawara',
      'Hamatonbetsu',
      'Aso',
      'Kōya',
      'Kihoku-chō',
      'Nanto-shi',
      'Kawage',
      'Neyagawa',
      'Umeda',
      'Hitachi-Naka',
      'Chikusei',
      'Kasumigaura',
      'Tsuchiura',
      'Tsukubamirai',
      'Omitama',
      'Hitachiomiya',
      'Kamisu',
      'Namegata',
      'Sakuragawa',
      'Bandō',
      'Hitachi-ota',
      'Inashiki',
      'Azumino',
      'Chikuma',
      'Murayama',
      'Minamiashigara',
      'Rittō',
      'Takashima',
      'Kōka',
      'Katsuragi',
      'Kashiba',
      'Nanto',
      'Oyabe',
      'Imizu',
      'Kurobe-shi',
      'Tama',
      'Musashimurayama',
      'Akishima',
      'Higashiyamato',
      'Misato',
      'Niiza',
      'Tonami',
      'Kahoku',
      'Kaga',
      'Suzu',
      'Matsuura',
      'Ōnojō',
      'Kasuga',
      'Aizu-Wakamatsu',
      'Minami-Sōma',
      'Iheya',
      'Kunigami',
      'Urasoe',
      'Uruma',
      'Jōsō',
      'Midori',
      'Tarama',
      'Miyakojima',
      'Hidaka',
      'Higashi-Matsuyama',
      'Iruma',
      'Kitamoto',
      'Tsurugashima',
      'Sōsa',
      'Sanmu',
      'Sōma',
      'Tamura',
      'Kinokawa',
      'Kumano',
      'Inabe',
      'Nasushiobara',
      'Shimotsuke',
      'Nasukarasuyama',
      'Tōmi',
      'Komagane',
      'Inzai',
      'Isumi',
      'Yachiyo',
      'Tomisato',
      'Ōshū',
      'Hachimantai',
      'Ninohe',
      'Higashimatsushima',
      'Kurihara',
      'Izunokuni',
      'Izu',
      'Kikugawa',
      'Kosai',
      'Susono',
      'Omaezaki',
      'Nikaho',
      'Daisen',
      'Oga',
      'Yurihonjō',
      'Katagami',
      'Kita-Akita',
      'Tsugaru',
      'Hirakawa',
      'Towada',
      'Kitanagoya',
      'Nisshin',
      'Toyoake',
      'Chita',
      'Aisai',
      'Hashima',
      'Kaizu',
      'Kani',
      'Minokamo',
      'Motosu',
      'Gujō',
      'Ena',
      'Nanjō',
      'Agano',
      'Jōetsu',
      'Minamiuonuma',
      'Myoko',
      'Sado',
      'Tainai',
      'Uonuma',
      'Fujiidera',
      'Habikino',
      'Hannan',
      'Kawachi-Nagano',
      'Sennan',
      'Shijōnawate',
      'Hokuto',
      'Saitama',
      'Inba',
      'Niseko Town',
      'Higashimurayama',
      'Fujikawaguchiko',
      'Yawatahama',
      'Kirishima',
      'Amami',
      'Rishiri Town',
      'Arashiyama',
      'Oda',
      'Dazaifu',
      'Amakusa',
      'Kamigyō-ku',
      'Buzen',
      'Yoshitomi',
      'Ikata-chō',
      'Inuotose',
      'Furudate',
      'Tsuruta',
      'Inakadate',
      'Hatsudai',
      'Isen',
      'Misasa',
      'Fujimi',
      'Oshino',
      'Katashina',
      'Hakuba',
      'Izumizaki',
      'Nagatoro',
      'Taishi',
      'Kanmaki',
      'Ide',
      'Sue',
      'Kofu',
      'Aka',
      'Fujiyoshida',
      'Shonai',
      'Ozora',
      'Toyone',
      'Yomitan',
      'Motoyoyogichō',
      'Ichikawa-minami',
      'Matsubushi',
      'Motobu',
      'Nishiawakura',
      'Eniwa',
      'Tagami',
      'Minami-Alps',
      'Arao',
      'Miyota',
      'Ukiha',
      'Nagawa',
      'Iga',
      'Kihoku',
      'Kuwana',
      'Matsusaka',
      'Minamiise',
      'Yonedacho Sendo',
      'Fujimino',
      'Himi',
      'Takizawa',
      'Tōon',
      'Yasu',
      'Yame',
      'Yamatokōriyama',
      'Yamato',
      'Yamanashi',
      'Tosashimizu',
      'Toda',
      'Taku',
      'Taketa',
      'Takasago',
      'Tachikawa',
      'Saito',
      'Sagamihara',
      'Ogōri',
      'Ogi',
      'Nagakute',
      'Munakata',
      'Mitoyo',
      'Mimasaka',
      'Kunitachi',
      'Miyake',
      'Hachijō',
      'Ōshima',
      'Ogasawara',
      'Kizugawa',
      'Katano',
      'Hyūga',
      'Higashihiroshima',
      'Higashikurume',
      'Hamura',
      'Funabashi',
      'Fuji-yoshida',
      'Fuchū',
      'Chikugo',
      'Sanuki',
      'Ayase',
      'Arida',
      'Aira',
      'Fukaya',
      'Kodaira',
      'Nishitōkyō',
      'Nanyō',
      'Kakogawa',
      'Kan’onji',
      'Katō',
      'Mine',
      'Hida',
      'Kiyose',
      'Jōyō',
      'Yatomi',
      'Tome',
      'Kamagaya',
      'Ebino',
      'Isa',
      'Ako',
      'Bizen',
      'Higashiōmi',
      'Usa',
      'Etajima',
      'Shimanto',
      'Iki',
      'Tosa',
      'Tsuru',
      'Nankoku',
      'Aki-takata',
      'Seiyo',
      'Awa',
      'Yatsushiro',
      'Kai',
      'Awaji',
      'Warabi',
      'Itoshima',
      'Yufu',
      'Akiruno',
      'Shikokuchūō',
      'Ureshino',
      'Narashino',
      'Minamibōsō',
      'Ōamishirasato',
      'Sodegaura',
      'Echizen',
      'Kama',
      'Miyama',
      'Miyawaka',
      'Shirakawa',
      'Mizuho',
      'Asago',
      'Kasai',
      'Minamiawaji',
      'Sanda',
      'Shisō',
      'Tanba',
      'Nomi',
      'Tōkai',
      'Higashikagawa',
      'Minamikyushu',
      'Minamisatsuma',
      'Soo',
      'Ebina',
      'Kami',
      'Muroto',
      'Kōshi',
      'Uki',
      'Kyōtanabe',
      'Kyōtango',
      'Nantan',
      'Gotō',
      'Minamishimabara',
      'Saikai',
      'Unzen',
      'Bungo-ōno',
      'Akaiwa',
      'Maniwa',
      'Setouchi',
      'Ōsaka-sayama',
      'Imari',
      'Kanzaki',
      'Konan',
      'Yasugi',
      'Mima',
      'Yoshinogawa',
      'Inagi',
      'Koganei',
      'Komae',
      'Sanyōonoda',
      'Shūnan',
      'Chūō',
      'Fuefuki',
      'Kōshū',
      'Kazuno',
      'Unnan',
      'Kunisaki',
      'Ichikikushikino',
      'Asakuchi',
      'Fukutsu',
      'Asakura',
      'Kamiamakusa',
      'Rikuzentakata',
      'Hakusan',
      'Semboku',
      'Tajiri',
      'Mitaka',
      'Kasado',
      'Daitōchō',
    ],
  },
  {
    country: 'Kenya',
    cities: [
      'Yala',
      'Wundanyi',
      'Wote',
      'Witu',
      'Webuye',
      'Wajir',
      'Voi',
      'Thika',
      'Taveta',
      'Takaungu',
      'Sotik',
      'Shimoni',
      'Sawa Sawa',
      'Sagana',
      'Rumuruti',
      'Ruiru',
      'Rongai',
      'Pumwani',
      'Oyugis',
      'Othaya',
      'Ogembo',
      'Nyeri',
      'Nyamira',
      'Nyahururu',
      'Ngong',
      'Naro Moru',
      'Narok',
      'Nanyuki',
      'Nandi Hills',
      'Nambare',
      'Nakuru',
      'Naivasha',
      'Nairobi',
      'Mwingi',
      'Mwatate',
      'Murang’a',
      'Mumias',
      'Muhoroni',
      'Mtito Andei',
      'Moyale',
      'Mombasa',
      'Molo',
      'Migori',
      'Meru',
      'Mbale',
      'Maua',
      'Marsabit',
      'Mariakani',
      'Maralal',
      'Maragua',
      'Mandera',
      'Malindi',
      'Malikisi',
      'Malaba',
      'Makueni Boma',
      'Magadi',
      'Machakos',
      'Kipkelion',
      'Lugulu',
      'Luanda',
      'Londiani',
      'Lokichokio',
      'Lodwar',
      'Litein',
      'Limuru',
      'Lamu',
      'Kwale',
      'Konza',
      'Kitui',
      'Kitale',
      'Kisumu',
      'Kisii',
      'Kipini',
      'Kinango',
      'Kihancha',
      'Kilifi',
      'Kikuyu',
      'Kijabe',
      'Kiambu',
      'Kerugoya',
      'Keroka',
      'Kericho',
      'Karuri',
      'Kapsowar',
      'Kapsabet',
      'Kapenguria',
      'Kangundo',
      'Kangema',
      'Kakamega',
      'Kajiado',
      'Kabarnet',
      'Iten',
      'Isiolo',
      'Homa Bay',
      'Hola',
      'Gazi',
      'Garissa',
      'Embu',
      'Eldoret',
      'Eldama Ravine',
      'Chuka',
      'Chepareria',
      'Butere',
      'Busia',
      'Port Victoria',
      'Bungoma',
      'Bondo',
      'Sotik Post',
      'Baringo',
      'Athi River',
      'Ahero',
      'Siaya',
      'Ol Kalou',
      'Kathwana',
    ],
  },
  {
    country: 'Kyrgyzstan',
    cities: [
      'Tayan',
      'Suluktu',
      'Kyzyl-Eshme',
      'Korgon',
      'Aydarken',
      'Katran',
      'Razzakov',
      'Daroot-Korgon',
      'Andarak',
      'Balykchy',
      'Uch-Korgon',
      'Tyup',
      'Tokmok',
      'Ak-Suu',
      'Tash-Kumyr',
      'Talas',
      'Suzak',
      'Chuy',
      'Sosnovka',
      'Sokuluk',
      'Pokrovka',
      'Kyzyl-Suu',
      'Uzgen',
      'Osh',
      'Orozbekovo',
      'Naryn',
      'Bakai-Ata',
      'Massy',
      'Lebedinovka',
      'Kyzyl-Kyya',
      'Kochkor',
      'Kochkor-Ata',
      'Kyzyl-Adyr',
      'Khalmion',
      'Kazarman',
      'Kerben',
      'Karavan',
      'Kara Suu',
      'Karakol',
      'Kara-Dzhygach',
      'Kara-Balta',
      'Kanysh-Kyya',
      'Kant',
      'Kaindy',
      'Kadzhi-Say',
      'Jalal-Abad',
      'Ivanovka',
      'Ivanovo-Alekseyevka',
      'Iradan',
      'Gul’cha',
      'Nookat',
      'Cholpon-Ata',
      'Chayek',
      'Kemin',
      'Bokombayevskoye',
      'Bishkek',
      'Belovodskoye',
      'Bazar-Korgon',
      'Batken',
      'Baetovo',
      'At-Bashi',
      'Aravan',
      'Alga',
      'Ala-Buka',
      'Beshkent',
      'Toktogul',
      'Pulgon',
      'Üch-Terek',
      'Kara-Dëbë',
      'Kara-Kulja',
      'Chek',
      'Kara-Bak',
      'Samarkandyk',
      'Kyrgyz-Kyshtak',
      'Kyzyl-Bulak',
      'Boz-Adyr',
      'Markaz',
      'Kulundu',
      'Toguz-Bulak',
      'Ak-Tatyr',
      'Ormosh',
      'Ak-Say',
    ],
  },
  {
    country: 'Cambodia',
    cities: [
      'Phnom Penh',
      'Veun Sai',
      'Ta Khmau',
      'Takeo',
      'Svay Rieng',
      'Svay Chek',
      'Suong',
      'Stung Treng',
      'Srae Ambel',
      'Sisophon',
      'Siem Reap',
      'Siem Pang',
      'Sen Monorom',
      'Prey Veng',
      'S’ang',
      'Koah Thum',
      'Pursat',
      'Phumĭ Véal Srê',
      'Bak Khlang',
      'Thma Puok',
      'Ta Loas',
      'Samraong',
      'Sala Krau',
      'Preaek Prasab',
      'Koas Krala',
      'Kampong Seila',
      'Chhuk',
      'Chhloung',
      'Bavel',
      'Banan',
      'Tbeng Meanchey',
      'Paoy Paet',
      'Pailin',
      'Moung Ruessei',
      'Lumphat',
      'Kuleaen',
      'Krong Kep',
      'Koh Kong',
      'Kratié',
      'Batheay',
      'Kampot',
      'Kampong Trach',
      'Kampong Trabaek',
      'Kampong Thom',
      'Kampong Speu',
      'Sihanoukville',
      'Kampong Chhnang',
      'Kampong Cham',
      'Sesan',
      'Banlung',
      'Odongk',
      'Battambang',
      'Baray',
      'Bar Kaev',
      'Pal Hal',
      'Anlong Veaeng',
      'Sampov Lun',
      'Smach Mean Chey',
      'Phnum Proek',
      'Kamrieng',
      'Samlout',
      'Ou Chrov',
      'Veal Veaeng',
      'Aek Phnum',
      'Bat Dambang',
      'Phnum Srok',
      'Kralanh',
      'Mongkol Borei',
      'Preah Netr Preah',
      'Mondul Seima',
      'Smach Meanchey',
      'Botum Sakor',
      'Sangkae',
      'Mittakpheap',
      'Thma Bang',
      'Phnum Kravanh',
      'Srei Snam',
      'Chum Kiri',
      'Damnak Chang’aeur',
      'Phnum Sruoch',
      'Thpong',
      'Soutr Nikom',
      'Trapeang Prasat',
      'Kiri Vongs',
      'Banteay Meas',
      'Kandal Stueng',
      'Tram Kak',
      'Basedth',
      'Mukh Kampul',
      'Ponhea Lueu',
      'Ruessei Kaev',
      'Dangkao',
      'Mean Chey',
      'Kampong Leaeng',
      'Stueng Saen',
      'Stoung',
      'Bourei Cholsar',
      'Preah Sdach',
      'Kang Meas',
      'Kaoh Soutin',
      'Sithor Kandal',
      'Prey Veaeng',
      'Chamkar Leu',
      'Cheung Prey',
      'Prey Chhor',
      'Sandan',
      'Rumduol',
      'Svay Teab',
      'Me Sang',
      'Stueng Trang',
      'Chantrea',
      'Kaev Seima',
      'Ou Chum',
      'Andoung Meas',
      'Ou Ya Dav',
      'Sangkom Thmei',
      'Peam Ro',
      'Kampong Leav',
      'Leuk Daek',
      'Peam Chor',
      'Koun Mom',
      'Pechreada',
      'Prey Nob',
      'Bakan',
      'Angkor Thum',
      'Bakong',
      'Varin',
      'Angkor Chum',
      'Aoral',
      'Baribour',
      'Chi Kraeng',
    ],
  },
  {
    country: 'Kiribati',
    cities: [
      'Teaoraereke Village',
      'Rawannawi Village',
      'Buota Village',
      'Betio Village',
      'Banraeaba Village',
      'Tarawa',
      'Napari Village',
      'London Village',
      'Tabwakea Village',
      'Banana Village',
      'Makin Village',
      'Bairiki Village',
      'Ambo Village',
      'Bonriki Village',
      'Temaiku Village',
      'Nawerewere Village',
      'Bikenibeu Village',
      'Eita Village',
    ],
  },
  {
    country: 'Comoros',
    cities: [
      'Ouanani',
      'Vouani',
      'Vanambouani',
      'Tsimbeo',
      'Singani',
      'Sima',
      'Salimani',
      'Patsi',
      'Ouzini',
      'Oussivo',
      'Ourovéni',
      'Ouellah',
      'Ouani',
      'Ongoni',
      'Ntsoudjini',
      'Ntsaouéni',
      'Nioumachoua',
      'Kyo',
      'Moutsamoudou',
      'Mtsamdou',
      'Mrémani',
      'Mramani',
      'Moya',
      'Moroni',
      'Mohoro',
      'Mjimandra',
      'Mjamaoué',
      'Mitsoudjé',
      'Mitsamiouli',
      'Mbéni',
      'Maraharé',
      'Koni-Djodjo',
      'Koni-Ngani',
      'Koki',
      'Kangani',
      'Ivouani',
      'Itsandra',
      'Héroumbili',
      'Hoani',
      'Foumbouni',
      'Fomboni',
      'Dziani',
      'Dzahadjou',
      'Domoni',
      'Djoyézi',
      'Dembéni',
      'Chironkamba',
      'Chindini',
      'Chezani',
      'Chandra',
      'Chouani',
      'Bouni',
      'Boungouéni',
      'Bimbini',
      'Bandamadji-Domba',
      'Bambao',
      'Assimpao',
      'Antsahé',
      'Mtakoudja',
      'Ziroudani',
      'Kavani',
      'Bandajou',
      'Chitrouni',
      'Hajoho',
      'Barakani',
      'Harembo',
      'Mirontsi',
      'Pajé',
      'Limbi',
      'Lingoni',
      'Magnassini-Nindri',
      'Adda-Douéni',
      'Daji',
      'Ouhozi',
      'Mandza',
      'Douniani',
      'Koua',
      'Hantsindzi',
      'Itsandzéni',
      'Bambadjani',
      'Mnoungou',
      'Vanadjou',
      'Bahani',
      'Tsidjé',
      'Mvouni',
      'Mavingouni',
      'Séléa',
      'Madjeouéni',
      'Nioumamilima',
    ],
  },
  {
    country: 'Saint Kitts and Nevis',
    cities: [
      'Trinity',
      'Sandy Point Town',
      'Saint Paul’s',
      'Nicola Town',
      'Newcastle',
      'Monkey Hill',
      'Middle Island',
      'Market Shop',
      'Fig Tree',
      'Dieppe Bay Town',
      'Cotton Ground',
      'Charlestown',
      'Cayon',
      'Basseterre',
    ],
  },
  {
    country: 'North Korea',
    cities: [
      'Yonggang-ŭp',
      'Yŏngbyŏn',
      'Yŏnan-ŭp',
      'Yŏmju-ŭp',
      'Wŏnsan',
      'T’ongch’ŏn-ŭp',
      'Sŭngho 1-tong',
      'Sunch’ŏn',
      'Sunan',
      'Songnim-ni',
      'Sil-li',
      'Sinmak',
      'Sinanju',
      'Sariwŏn',
      'Sangsŏng-ni',
      'Samho-rodongjagu',
      'Pyongyang',
      'P’yŏngsŏng',
      'Pyŏksŏng-ŭp',
      'Panghyŏn-dong',
      'Ongjin',
      'Namp’o',
      'Kwaksan',
      'Kusŏng',
      'Kujang-ŭp',
      'Kowŏn-ŭp',
      'Kosan',
      'Koksan',
      'Kangdong-ŭp',
      'Kaesŏng',
      'Hwangju-ŭp',
      'Hŭngnam',
      'Hŭkkyo-ri',
      'Hoeyang',
      'Hamhŭng',
      'Haeju',
      'Chunghwa',
      'Chŏngju',
      'Changyŏn',
      'Chaeryŏng-ŭp',
      'Ayang-ni',
      'Anju',
      'Anbyŏn-ŭp',
      'Anak',
      'Sinsang-ni',
      'Yuktae-dong',
      'Ungsang-nodongjagu',
      'Ŭndŏk',
      'Uiju',
      'Ŭijin-dong',
      'Sŭngam-nodongjagu',
      'Sŏnbong',
      'Sinŭiju',
      'Sakchu-ŭp',
      'Puryŏng',
      'Pukchil-lodongjagu',
      'Yŏnggwang-ŭp',
      'Onsŏng',
      'Ranam',
      'Namyang',
      'Rajin',
      'Musan-ŭp',
      'Manp’o',
      'Kyŏngwŏn',
      'Kyŏngsŏng',
      'Kimch’aek-si',
      'Kilju',
      'Kapsan-ŭp',
      'Kanggye',
      'Iwŏn-ŭp',
      'Rimgan-ni',
      'Hyesan-dong',
      'Hyesan',
      'Hŭngju-dong',
      'Hongwŏn',
      'Hoeryŏng',
      'Hoemul-li',
      'Hau-ri',
      'Chongjin',
      'Chasŏng',
      'Aoji',
      'Sŭngjibaegam',
      'Komusan Il-tong',
      'Sŏng-dong',
      'Chungbong',
      'Sech’ŏn',
      'Hahoe-dong',
      'Kogŏnwon',
      'Ryongdang',
      'Sambong',
      'Kwirak',
      'Ungsang',
      'Namsan',
      'P’ungin',
      'Yongha',
      'Nongnim',
      'Rajuk',
    ],
  },
  {
    country: 'South Korea',
    cities: [
      'Heunghae',
      'Yuseong',
      'Yeonil',
      'Neietsu',
      'Eisen',
      'Yonghwa',
      'Yeonggwang',
      'Yeongdong',
      'Yeongdeok',
      'Yongan',
      'Iyang',
      'Yeoncheon',
      'Yeoju',
      'Yesan',
      'Yecheon',
      'Yangsan',
      "Yangp'yŏng",
      'Yangju',
      'Yanggu',
      'Wŏnju',
      'Wanju',
      'Waegwan',
      'Ulsan',
      'Uijeongbu-si',
      'Dongnae',
      'Dongbok',
      'Tangjin',
      'Damyang',
      'Taesal-li',
      'Daejeon',
      'Daegu',
      'Boryeong',
      'T’aebaek',
      'Suwon',
      'Suncheon',
      'Seoul',
      'Seosan',
      'Jenzan',
      'Seonghwan',
      'Sokcho',
      'Sintansin',
      'Simcheon',
      'Sangju',
      'Santyoku',
      'Pyeongchang',
      'Buyeo',
      'Busan',
      'Bucheon-si',
      'Puan',
      'Beolgyo',
      'Pohang',
      'Beobwon',
      'Biin',
      'Osan',
      'Asan',
      'Okcheon',
      'Kosong',
      'Nonsan',
      'Nangen',
      'Naju',
      'Munsan',
      'Mungyeong',
      'Muan',
      'Mokpo',
      'Miryang',
      'Masan',
      'Gyeongsan-si',
      'Gyeongju',
      'Kwangyang',
      'Gwangju',
      'Gwanchon',
      'Guryongpo',
      'Kurye',
      'Guri-si',
      'Kunwi',
      'Gunsan',
      'Gunpo',
      'Kinzan',
      'Gumi',
      'Goyang-si',
      'Goseong',
      'Gongju',
      'Kyosai',
      'Koesan',
      "Koch'ang",
      'Kimje',
      'Kimhae',
      'Gimcheon',
      'Gijang',
      'Gapyeong',
      'Gangneung',
      'Ganghwa-gun',
      'Gampo',
      'Iksan',
      'Incheon',
      'Imsil',
      'Icheon-si',
      'Hyeonpung',
      'Hwasun',
      'Hwaseong-si',
      'Hwacheon',
      'Hongseong',
      'Hongch’ŏn',
      'Hayang',
      'Hancheolli',
      'Haenam',
      'Chupungnyeong',
      'Chungju',
      'Chuncheon',
      'Jumunjin',
      'Cheorwon',
      'Jeonju',
      'Cheongyang',
      'Cheongsong gun',
      'Cheongsan',
      'Cheongpyeong',
      'Jeongok',
      'Cheongju-si',
      'Cheonan',
      'Chinju',
      'Jinjam',
      "Chinch'ŏn",
      'Jinan-gun',
      'Jido',
      'Jeju City',
      'Changwon',
      'Changsu',
      'Jangseong',
      'Changpyeong',
      'Jangheung',
      'Jangan',
      'Anyang-si',
      'Anseong',
      'Ansan-si',
      'Andong',
      'Gaigeturi',
      'Sinhyeon',
      'Iwon',
      'Yeosu',
      'Gwangyang',
      'Illo',
      'Baeksu',
      'Hongnong',
      'Hamyeol',
      'Yŏnmu',
      'Tonghae',
      'Cheongha',
      'Ocheon',
      'Ilgwang',
      'Pubal',
      'Seongnam-si',
      'Hanam',
      'Hwado',
      'Namyangju',
      'Ungsang',
      'Wabu',
      'Naesŏ',
      'Hwawŏn',
      'Gwangmyeong',
      'Paju',
      'Sinan',
      'Seogwipo',
      'Yongsan-dong',
      'Seongsu',
      'Sanseo',
      'Sannae',
      'Singwang',
      'Sangwol',
      'Cheongnam',
      'Jangpyeong',
      'Gyuam',
      'Hoenam',
      'Gunbuk',
      'Gunseo',
      'Dongi',
      'Annae',
      'Annam',
      'Samseung',
      'Cheongseong',
      'Haksan',
      'Seolcheon',
      'Songgangdong',
      'Hwanggan',
      'Yongsan',
      'Hwanam',
      'Seosang',
      'Baekjeon',
      'Beonam',
      'Byeonggok',
      'Daehap',
      'Changnyeong',
      'Songhae',
      'Hajeom',
      'Yangsa',
      'Samsan',
      'Seonwon',
      'Wolgot',
      'Haseong',
      'Tongjin',
      'Gyodong',
      'Gwangtan',
      'Tanhyeon',
      'Gwangjeok',
      'Su-dong',
      'Sindong',
      'Seoseok',
      'Jinjeop',
      'Onam',
      'Haean',
      'Toseong',
      'Sinseo',
      'Gwanin',
      'Bangsan',
      'Dongmyeon',
      'Sejong',
      'Bupyeong',
      'Boseong',
      'Jeongeup',
      'Hwayang',
      'Nammyeon',
      'Mijo',
      'Palgeum',
      'Apae',
      'Bannam',
      'Dopo',
      'Deokjin',
      'Seji',
      'Geumjeong',
      'Sijong',
      'Yeongam',
      'Gangdong',
      'Haeryong',
      'Byeollyang',
      'Gujeong',
      'Yuchi',
      'Cheongpung',
      'Mundeok',
      'Joseong',
      'Oeseo',
      'Yureo',
      'Okgok',
      'Jinwol',
      'Geumseong',
      'Jeongnyang',
      'Jinsang',
      'Bonggang',
      'Ongnyong',
      'Sangsa',
      'Seungju',
      'Juam',
      'Songgwang',
      'Nodong',
      'Miryeok',
      'Bongnae',
      'Gyeombaek',
      'Hadong',
      'Haeje',
      'Yeomsan',
      'Haebo',
      'Nasan',
      'Bulgap',
      'Nagwol',
      'Imja',
      'Sangha',
      'Simwon',
      'Haeri',
      'Gunnam',
      'Beopseong',
      'Gongeum',
      'Daesan',
      'Myoryang',
      'Daema',
      'Ssangchi',
      'Ingye',
      'Gurim',
      'Gangjin',
      'Donggye',
      'Chilbo',
    ],
  },
  {
    country: 'Kuwait',
    cities: [
      'Janūb as Surrah',
      'Ḩawallī',
      'Bayān',
      'Az Zawr',
      'As Sālimīyah',
      'Ash Shāmīyah',
      'Ar Rumaythīyah',
      'Ar Riqqah',
      'Al Wafrah',
      'Al Manqaf',
      'Al Mahbūlah',
      'Kuwait City',
      'Al Jahrā’',
      'Al Funayţīs',
      'Al Faḩāḩīl',
      'Al Finţās',
      'Al Farwānīyah',
      'Al Aḩmadī',
      'Ad Dasmah',
      'Salwá',
      'Ar Rābiyah',
      'Şabāḩ as Sālim',
      'Mubārak al Kabīr',
      'Al-Masayel',
      'Abu Fatira',
      'Abu Al Hasaniya',
    ],
  },
  {
    country: 'Cayman Islands',
    cities: [
      'West Bay',
      'North Side',
      'George Town',
      'East End',
      'Bodden Town',
      'Little Cayman',
    ],
  },
  {
    country: 'Kazakhstan',
    cities: [
      'Zhumysker',
      'Zhetybay',
      'Zhanaozen',
      'Temir',
      'Taūshyq',
      'Shubarshi',
      'Shubarkuduk',
      'Shetpe',
      'Shalqar',
      'Shalkar',
      'Say-Utes',
      'Saykhin',
      'Peremetnoe',
      'Oral',
      'Kandyagash',
      'Munayshy',
      'Miyaly',
      'Martuk',
      'Maqat',
      'Maloye Ganyushkino',
      'Makhambet',
      'Qulsary',
      'Krugloozërnoye',
      'Khromtau',
      'Kaztalovka',
      'Karaton',
      'Karagandy',
      'Tasqala',
      'Inderbor',
      'Fort-Shevchenko',
      'Fyodorovka',
      'Yeraliyev',
      'Embi',
      'Dzhanybek',
      'Dzhangala',
      'Dzhambeyty',
      'Dossor',
      'Chingarlau',
      'Chapayev',
      'Burlin',
      'Beyneu',
      'Bayganin',
      'Baychunas',
      'Bautino',
      'Batamshinskiy',
      'Balykshi',
      'Atyrau',
      'Aktobe',
      'Shevchenko',
      'Aqsay',
      'Algha',
      'Akkol’',
      'Umirzak',
      'Qasym Qaysenov',
      'Altay',
      'Zhosaly',
      'Zholymbet',
      'Zhezqazghan',
      'Zhezkent',
      'Zhitikara',
      'Zhelezinka',
      'Zharyk',
      'Zhangatas',
      'Novokazalinsk',
      'Taraz',
      'Zhalghyztobe',
      'Zhaksy',
      'Zerenda',
      'Zaysan',
      'Zatobyl',
      'Yul’yevka',
      'Yrghyz',
      'Yavlenka',
      'Yanykurgan',
      'Vozvyshenka',
      'Saumalkol’',
      'Arshaly',
      'Verkhniye Kayrakty',
      'Turar Ryskulov',
      'Ūst’-Talovka',
      'Uspenka',
      'Ushtobe',
      'Urzhar',
      'Ul’ba',
      'Tyul’kubas',
      'Turkestan',
      'Turgen',
      'Timiryazevo',
      'Troyebratskiy',
      'Turgay',
      'Tokarevka',
      'Tobol',
      'Terenozek',
      'Temirtau',
      'Temirlanovka',
      'Tekeli',
      'Tasbuget',
      'Ayat',
      'Talshik',
      'Talghar',
      'Taldykorgan',
      'Tayynsha',
      'Suykbulak',
      'Stepnyak',
      'Smirnovo',
      'Myrzakent',
      'Shymkent',
      'Chu',
      'Shortandy',
      'Shemonaikha',
      'Shchuchinsk',
      'Sharbaqty',
      'Shar',
      'Shantobe',
      'Shakhtinsk',
      'Shakhan',
      'Sergeyevka',
      'Äūlīeköl',
      'Semey',
      'Sayram',
      'Sayaq',
      'Sastobe',
      'Saryshaghan',
      'Saryozek',
      'Sarykamys',
      'Saryaghash',
      'Sarkand',
      'Soran',
      'Samarskoye',
      'Saksaul’skiy',
      'Rudnyy',
      'Kyzylorda',
      'Qusmuryn',
      'Kostanay',
      'Qashar',
      'Qarqaraly',
      'Qarazhal',
      'Karaaul',
      'Karatau',
      'Konayev',
      'Tughyl',
      'Presnovka',
      'Petropavl',
      'Pervorossiyskoye',
      'Pervomayka',
      'Pavlodar',
      'Zharkent',
      'Oytal',
      'Ust-Kamenogorsk',
      'Osakarovka',
      'Ordzhonikidze',
      'Ognyovka',
      'Zhabagly',
      'Novodolinskiy',
      'Novaya Shūl’ba',
      'Novaya Bukhtarma',
      'Narynkol',
      'Mynaral',
      'Moyynty',
      'Kurchatov',
      'Sarykemer',
      'Merke',
      'Mayqayyng',
      'Matay',
      'Mamlyutka',
      'Aqmol',
      'Makinsk',
      'Lugovoye',
      'Lugovoy',
      'Lisakovsk',
      'Lepsy',
      'Leninskoye',
      'Leninskiy',
      'Baikonur',
      'Ridder',
      'Leningradskoye',
      'Lenger',
      'Akkuly',
      'Kishkenekol’',
      'Kyzylzhar',
      'Novoishimskoye',
      'Kushoky',
      'Qorghalzhyn',
      'Kurchum',
      'Egindiköl',
      'Aqtoghay',
      'Krasnogorskiy',
      'Taiynsha',
      'Kokshetau',
      'Komsomolets',
      'Kokterek',
      'Koktal',
      'Kokpekty',
      'Kīevka',
      'Kirovskiy',
      'Asyqata',
      'Khantau',
      'Kentau',
      'Kegen',
      'Kazalinsk',
      'Karasu',
      'Karabas',
      'Kantagi',
      'Kalkaman',
      'Terengköl',
      'Esik',
      'Irtyshsk',
      'Atakent',
      'Granitogorsk',
      'Glubokoye',
      'Korday',
      'Kalbatau',
      'Moyynkum',
      'Esil',
      'Aksu',
      'Yereymentau',
      'Otegen Batyra',
      'Ekibastuz',
      'Dzhambul',
      'Dzhalagash',
      'Druzhba',
      'Dolinka',
      'Derzhavinsk',
      'Chulakkurgan',
      'Chiili',
      'Chayan',
      'Chardara',
      'Burunday',
      'Bulayevo',
      'Borovskoy',
      'Būrabay',
      'Borodulikha',
      'Ulken Naryn',
      'Besqaraghay',
      'Boko',
      'Bishkul',
      'Bestobe',
      'Belyye Vody',
      'Belousovka',
      'Koktobe',
      'Belogorskiy',
      'Bayzhansay',
      'Bayanaul',
      'Qogham',
      'Balqash',
      'Balkashino',
      'Bakanas',
      'Ayagoz',
      'Auezov',
      'Atbasar',
      'Atasū',
      'Asubulak',
      'Astrakhanka',
      'Ashchysay',
      'Arys',
      'Arykbalyk',
      'Arkalyk',
      'Aral',
      'Aktau',
      'Aqshataū',
      'Nur-Sultan',
      'Aqadyr',
      'Almaty',
      'Akkol',
      'Marqaköl',
      'Prigorodnoye',
      'Aktogay',
      'Aktas',
      'Aksu-Ayuly',
      'Aksuat',
      'Akbakay',
      'Abay',
      'Shalqīya',
      'Birlestik',
      'Maleyevsk',
      'Belköl',
      'Novoishimskiy',
      'Ozernyy',
      'Chiganak',
      'Shubarköl',
      'Zhana Kiima',
      'Stepnogorsk',
      'Zavodskoy',
      'Ülken',
      'Altayskiy',
      'Chemolgan',
      'Priozersk',
      'Tyuratam',
      'Tasty-Taldy',
    ],
  },
  {
    country: 'Laos',
    cities: [
      'Vientiane',
      'Xam Nua',
      'Savannakhet',
      'Salavan',
      'Phôngsali',
      'Pakse',
      'Pakkading',
      'Ban Xiang-Ngeun',
      'Muang Xay',
      'Sainyabuli',
      'Vangviang',
      'Ban Thatèng',
      'Muang Sing',
      'Muang Sanakham',
      'Phônthong',
      'Muang Phônsavan',
      'Muang Phôn-Hông',
      'Muang Phin',
      'Pakxong',
      'Pakxan',
      'Muang Mok',
      'Muang Long',
      'Ban Pakla',
      'Khoa',
      'Muang Không',
      'Thakhèk',
      'Muang Kènthao',
      'Muang Kasi',
      'Muang Hinboun',
      'Luang Prabang',
      'Luang Namtha',
      'Muang Khamkeut',
      'Ban Dônghén',
      'Champasak',
      'Borikhan',
      'Lamam',
      'Ban Nahin',
      'Ban Houayxay',
      'Ban Hatgnao',
      'Ban Fangdèng',
      'Attapeu',
      'Ban Houakhoua',
      'Ban Namnga',
      'Viangxai',
      'Muang Nalè',
      'Dak Cheung',
      'Sanaxy',
      'Huameung',
      'Viengxay',
      'Xamtay',
      'Viengthong',
      'Xaysetha',
      'Sekong',
    ],
  },
  {
    country: 'Lebanon',
    cities: [
      'Zghartā',
      'Zahlé',
      'Tripoli',
      'Tyre',
      'Sîr ed Danniyé',
      'Sidon',
      'Ra’s Bayrūt',
      'Marjayoûn',
      'Jounieh',
      'Joubb Jannîne',
      'Jbaïl',
      'Jezzîne',
      'Hâsbaïya',
      'Hrajel',
      'Halba',
      'Habboûch',
      'Bcharré',
      'Bent Jbaïl',
      'Bhamdoûn el Mhatta',
      'Bhamdoun',
      'Beït ed Dîne',
      'Beirut',
      'Baalbek',
      'Baabda',
      'Ain Ebel',
      'En Nâqoûra',
      'Nabatîyé et Tahta',
      'Aanjar',
      'Amioûn',
      'Jdaidet el Matn',
      'El Hermel',
      'Ghazieh',
      'Batroûn',
      'Aaley',
      'Râchaïya el Ouadi',
    ],
  },
  {
    country: 'Saint Lucia',
    cities: [
      'Vieux Fort',
      'Soufrière',
      'Praslin',
      'Micoud',
      'Laborie',
      'Gros Islet',
      'Dennery',
      'Choiseul',
      'Castries',
      'Canaries',
      'Bisee',
      'Anse La Raye',
    ],
  },
  {
    country: 'Liechtenstein',
    cities: [
      'Vaduz',
      'Triesenberg',
      'Triesen',
      'Schellenberg',
      'Schaan',
      'Ruggell',
      'Planken',
      'Mauren',
      'Gamprin',
      'Eschen',
      'Balzers',
    ],
  },
  {
    country: 'Sri Lanka',
    cities: [
      'Welisara',
      'Weligama',
      'Wattegama',
      'Wattala',
      'Vavuniya',
      'Valvedditturai',
      'Vakarai',
      'Unawatuna',
      'Trincomalee',
      'Tangalle',
      'Talpe',
      'Talawakele',
      'Sigiriya',
      'Ratnapura',
      'Puttalam',
      'Polonnaruwa',
      'Point Pedro',
      'Pita Kotte',
      'Peliyagoda',
      'Panadura',
      'Nuwara Eliya',
      'Negombo',
      'Mulleriyawa',
      'Dehiwala-Mount Lavinia',
      'Moratuwa',
      'Monaragala',
      'Minuwangoda',
      'Mihintale',
      'Mawalgama',
      'Matara',
      'Matale',
      'Maharagama',
      'Kurunegala',
      'Kuliyapitiya',
      'Sri Jayewardenepura Kotte',
      'Kotikawatta',
      'Kolonnawa',
      'Koggala',
      'Kilinochchi',
      'Kelaniya',
      'Kegalle',
      'Katunayaka',
      'Kataragama',
      'Kandy',
      'Kandana',
      'Kalutara',
      'Kalmunai',
      'Kadugannawa',
      'Jaffna',
      'Ja Ela',
      'Horana South',
      'Homagama',
      'Hikkaduwa',
      'Hendala',
      'Hatton',
      'Haputale',
      'Hanwella Ihala',
      'Gampola',
      'Gampaha',
      'Galle',
      'Eravur Town',
      'Ella Town',
      'Devinuwara',
      'Dambulla',
      'Colombo',
      'Chilaw',
      'Bogahakumbura',
      'Beruwala',
      'Bentota',
      'Batticaloa',
      'Battaramulla South',
      'Badulla',
      'Anuradhapura',
      'Ampara',
      'Ambalangoda',
      'Horawala Junction',
      'Padaviya Divisional Secretariat',
      'Wellawaya',
      'Mirissa city',
    ],
  },
  {
    country: 'Liberia',
    cities: [
      'Zwedru',
      'New Yekepa',
      'Voinjama',
      'Tubmanburg',
      'Fish Town',
      'Sanniquellie',
      'Robertsport',
      'Cestos City',
      'Monrovia',
      'Kakata',
      'Harper',
      'Greenville',
      'Gbarnga',
      'Ganta',
      'Buchanan',
      'Bopolu',
      'Bensonville',
      'Barclayville',
      'Buutuo',
    ],
  },
  {
    country: 'Lesotho',
    cities: [
      'Teyateyaneng',
      'Quthing',
      'Qacha’s Nek',
      'Nako',
      'Mokhotlong',
      'Mohale’s Hoek',
      'Maseru',
      'Maputsoe',
      'Mafeteng',
      'Leribe',
      'Butha-Buthe',
      'Thaba-Tseka',
    ],
  },
  {
    country: 'Lithuania',
    cities: [
      'Žiežmariai',
      'Žemaičių Naumiestis',
      'Zarasai',
      'Žagarė',
      'Visaginas',
      'Vilnius',
      'Vilkija',
      'Vilkaviskis',
      'Vievis',
      'Viekšniai',
      'Venta',
      'Veisiejai',
      'Varėna',
      'Utena',
      'Ukmerge',
      'Tytuvėnėliai',
      'Trakai',
      'Telsiai',
      'Taurage',
      'Šventoji',
      'Švenčionys',
      'Švenčionėliai',
      'Skuodas',
      'Būgai',
      'Skaidiškės',
      'Širvintos',
      'Simnas',
      'Silute',
      'Šilalė',
      'Šiauliai',
      'Senieji Trakai',
      'Šeduva',
      'Seda',
      'Sargėnai',
      'Šalčininkai',
      'Salantai',
      'Šakiai',
      'Rusnė',
      'Rūdiškės',
      'Rokiškis',
      'Rietavas',
      'Raseiniai',
      'Ramygala',
      'Ramučiai',
      'Radviliskis',
      'Prienai',
      'Priekulė',
      'Plunge',
      'Plateliai',
      'Pervalka',
      'Pasvalys',
      'Panevėžys',
      'Palanga',
      'Pakruojis',
      'Pagėgiai',
      'Pabradė',
      'Fabijoniškės',
      'Obeliai',
      'Nida',
      'Neringa',
      'Nemenčinė',
      'Naujoji Vilnia',
      'Naujoji Akmene',
      'Molėtai',
      'Mazeikiai',
      'Matuizos',
      'Marijampolė',
      'Linkuva',
      'Lentvaris',
      'Lazdijai',
      'Kybartai',
      'Kuršėnai',
      'Kupiskis',
      'Kulautuva',
      'Kudirkos Naumiestis',
      'Kretinga',
      'Klaipėda',
      'Kelmė',
      'Kėdainiai',
      'Kazlų Rūda',
      'Kaunas',
      'Karmėlava',
      'Kalvarija',
      'Kaišiadorys',
      'Jurbarkas',
      'Juodupė',
      'Joniškis',
      'Jonava',
      'Jieznas',
      'Ignalina',
      'Grigiškės',
      'Gelgaudiškis',
      'Garliava',
      'Gargždai',
      'Ežerėlis',
      'Elektrėnai',
      'Eišiškės',
      'Druskininkai',
      'Daugai',
      'Birzai',
      'Birštonas',
      'Baltoji Vokė',
      'Ariogala',
      'Anykščiai',
      'Antakalnis',
      'Alytus',
      'Aleksotas',
      'Akmenė',
      'Dainava (Kaunas)',
      'Naujininkai',
      'Karoliniškės',
      'Šilainiai',
      'Eiguliai',
      'Akademija (Kaunas)',
      'Mastaiciai',
      'Pašilaičiai',
      'Pilaitė',
      'Justiniškės',
      'Šeškinė',
      'Lazdynai',
      'Vilkpėdė',
      'Naujamiestis',
      'Rasos',
    ],
  },
  {
    country: 'Luxembourg',
    cities: [
      'Wormeldange',
      'Winseler',
      'Wincrange',
      'Wilwerwiltz',
      'Wiltz',
      'Weiswampach',
      'Weiler-la-Tour',
      'Wasserbillig',
      'Warken',
      'Walferdange',
      'Waldbredimus',
      'Waldbillig',
      'Wahl',
      'Vichten',
      'Vianden',
      'Useldange',
      'Uebersyren',
      'Tuntange',
      'Troisvierges',
      'Tétange',
      'Tandel',
      'Strassen',
      'Steinsel',
      'Steinfort',
      'Stadtbredimus',
      'Soleuvre',
      'Septfontaines',
      'Schuttrange',
      'Schrassig',
      'Schouweiler',
      'Schifflange',
      'Schieren',
      'Schengen',
      'Sanem',
      'Sandweiler',
      'Saeul',
      'Rumelange',
      'Rosport',
      'Rollingen',
      'Roeser',
      'Rodange',
      'Remich',
      'Reisdorf',
      'Redange-sur-Attert',
      'Reckange-sur-Mess',
      'Rambrouch',
      'Putscheid',
      'Pontpierre',
      'Pétange',
      'Olm',
      'Obercorn',
      'Nommern',
      'Niedercorn',
      'Niederanven',
      'Müllendorf',
      'Moutfort',
      'Mondorf-les-Bains',
      'Mondercange',
      'Mompach',
      'Mertzig',
      'Mertert',
      'Mersch',
      'Merl',
      'Medernach',
      'Manternach',
      'Mamer',
      'Luxembourg',
      'Lorentzweiler',
      'Lintgen',
      'Leudelange',
      'Lenningen',
      'Larochette',
      'Lamadelaine',
      'Kopstal',
      'Koerich',
      'Kirchberg',
      'Kehlen',
      'Kayl',
      'Junglinster',
      'Itzig',
      'Hosingen',
      'Hobscheid',
      'Hesperange',
      'Helmsange',
      'Heisdorf',
      'Heffingen',
      'Hautcharage',
      'Grosbous',
      'Grevenmacher',
      'Gonderange',
      'Goesdorf',
      'Garnich',
      'Frisange',
      'Flaxweiler',
      'Fischbach',
      'Niederfeulen',
      'Fentange',
      'Ettelbruck',
      'Esch-sur-Sûre',
      'Esch-sur-Alzette',
      'Erpeldange',
      'Ell',
      'Eischen',
      'Echternach',
      'Dudelange',
      'Dippach',
      'Differdange',
      'Diekirch',
      'Dalheim',
      'Crauthem',
      'Contern',
      'Consdorf',
      'Colmar',
      'Clervaux',
      'Clemency',
      'Capellen',
      'Canach',
      'Bridel',
      'Bous',
      'Bourscheid',
      'Boulaide',
      'Boevange-sur-Attert',
      'Biwer',
      'Bissen',
      'Betzdorf',
      'Bettendorf',
      'Bettembourg',
      'Bettborn',
      'Bertrange',
      'Bergem',
      'Béreldange',
      'Berdorf',
      'Belvaux',
      'Beckerich',
      'Bech',
      'Beaufort',
      'Bavigne',
      'Bascharage',
      'Aspelt',
      'Alzingen',
      'Senningerberg',
    ],
  },
  {
    country: 'Latvia',
    cities: [
      'Valmiera',
      'Zilupe',
      'Zelmeņi',
      'Viļaka',
      'Viļāni',
      'Viesīte',
      'Vecumnieki',
      'Ventspils',
      'Vecpiebalga',
      'Varakļāni',
      'Vangaži',
      'Valka',
      'Valdemārpils',
      'Vaiņode',
      'Ulbroka',
      'Tukums',
      'Tīreļi',
      'Tērvete',
      'Talsi',
      'Strenči',
      'Stende',
      'Stalbe',
      'Smiltene',
      'Smārde',
      'Skrunda',
      'Skrīveri',
      'Sigulda',
      'Seda',
      'Saulkrasti',
      'Saldus',
      'Salaspils',
      'Salacgrīva',
      'Sala',
      'Sabile',
      'Rūjiena',
      'Rucava',
      'Garkalne',
      'Roja',
      'Riga',
      'Riebiņi',
      'Rēzekne',
      'Rauna',
      'Ragana',
      'Priekuļi',
      'Priekule',
      'Preiļi',
      'Pļaviņas',
      'Piņķi',
      'Pāvilosta',
      'Ozolnieki',
      'Olaine',
      'Ogre',
      'Nīca',
      'Nereta',
      'Naukšēni',
      'Mūrmuiža',
      'Mežaparks',
      'Mērsrags',
      'Mazsalaca',
      'Matīši',
      'Madona',
      'Ludza',
      'Lubāna',
      'Līvāni',
      'Limbaži',
      'Līgatne',
      'Liezēre',
      'Liepāja',
      'Lielvārde',
      'Lieģi',
      'Kuldīga',
      'Krustpils',
      'Krāslava',
      'Koknese',
      'Kocēni',
      'Ķekava',
      'Ķegums',
      'Kārsava',
      'Karosta',
      'Kandava',
      'Kalnciems',
      'Jūrmala',
      'Jumprava',
      'Jelgava',
      'Jēkabpils',
      'Jaunpils',
      'Jaunjelgava',
      'Jaunciems',
      'Jaunaglona',
      'Inčukalns',
      'Ilūkste',
      'Ikšķile',
      'Iecava',
      'Gulbene',
      'Grobiņa',
      'Ērgļi',
      'Engure',
      'Dreiliņi',
      'Drabeši',
      'Dobele',
      'Daugavpils',
      'Daugavgrīva',
      'Dārzciems',
      'Dagda',
      'Cesvaine',
      'Cēsis',
      'Brocēni',
      'Bolderaja',
      'Berģi',
      'Bauska',
      'Balvi',
      'Baldone',
      'Auce',
      'Ape',
      'Alūksne',
      'Alsunga',
      'Aloja',
      'Aknīste',
      'Aizpute',
      'Aizkraukle',
      'Ainaži',
      'Ādaži',
      'Baloži',
      'Mārupe',
      'Vecrīga',
    ],
  },
  {
    country: 'Libya',
    cities: [
      'Al Bardīyah',
      'Tobruk',
      'Qaryat Sulūq',
      'Jālū',
      'Darnah',
      'Benghazi',
      'Az Zuwaytīnah',
      'Awjilah',
      'At Tāj',
      'Tūkrah',
      'Al Qubbah',
      'Al Marj',
      'Al Jawf',
      'Al Jaghbūb',
      'Al Bayḑā’',
      'Al Abyār',
      'Ajdabiya',
      'Zuwārah',
      'Zliten',
      'Zalţan',
      'Yafran',
      'Waddān',
      'Tarhuna',
      'Tripoli',
      'Tājūrā’',
      'Sirte',
      'Şurmān',
      'Şabrātah',
      'Sabhā',
      'Nālūt',
      'Murzuq',
      'Mizdah',
      'Mişrātah',
      'Masallātah',
      'Al Burayqah',
      'Marādah',
      'Jādū',
      'Hūn',
      'Zawiya',
      'Ghat',
      'Gharyan',
      'Ghadāmis',
      'Brak',
      'Bani Walid',
      'Zintan',
      'Az Zāwīyah',
      'Ubari',
      'Al Ajaylat',
      'Al Qaţrūn',
      'Al Khums',
      'Al Jumayl',
      'Al Jadīd',
      'Al ‘Azīzīyah',
      'Idrī',
      'Qasr Abu Hadi',
    ],
  },
  {
    country: 'Morocco',
    cities: [
      'Smara',
      'Zoumi',
      'Zinat',
      'Saddina',
      'Setti Fatma',
      'Zawyat ech Cheïkh',
      'Zaïo',
      'Zagora',
      'Zag',
      'Youssoufia',
      'Touama',
      'Tmourghout',
      'Tiztoutine',
      'Tiznit',
      'Tizguine',
      'Tit Mellil',
      'Tissa',
      'Tirhanimîne',
      'Tiout',
      'Tiouli',
      'Tinghir',
      'Timoulilt',
      'Timezgana',
      'Timezgadiouine',
      'Tilmi',
      'Tifni',
      'Tiflet',
      'Tétouan',
      'Temara',
      'Telouet',
      'Tazouta',
      'Taznakht',
      'Tazemmourt',
      'Tazarine',
      'Taza',
      'Tata',
      'Tarsouat',
      'Taroudant',
      'Teroual',
      'Targuist',
      'Tarfaya',
      'Taourirt',
      'Taounza',
      'Taounate',
      'Taouloukoult',
      'Taouima',
      'Tan-Tan',
      'Tangier',
      'Tanalt',
      'Tamorot',
      'Tamanar',
      'Talzemt',
      'Talmest',
      'Taliouine',
      'Tahla',
      'Tafraout',
      'Tadrart',
      'Tabouda',
      'Tabia',
      'Tabant',
      'Souq Larb’a al Gharb',
      'Senada',
      'Skoura',
      'Sidi Yahya Ou Saad',
      'Sidi Yahia El Gharb',
      'Sidi Smai’il',
      'Sidi Slimane',
      'Sidi Redouane',
      'Sidi Rahhal',
      'Sidi Qacem',
      'Sidi Moussa',
      'Sidi Lamine',
      'Sidi Jaber',
      'Sidi Ifni',
      'Sidi Harazem',
      'Sidi Bousber',
      'Sidi Bou Othmane',
      'Sidi Bennour',
      'Sidi Amar',
      'Sidi Abdallah',
      'Settat',
      'Selouane',
      'Sefrou',
      'Sale',
      'Saidia',
      'Safi',
      'Rouadi',
      'Reçani',
      'Rislane',
      'Ghouazi',
      'Reggada',
      'Ratba',
      'Rabat',
      'Ourtzagh',
      'Oulmes',
      'Oulad Teïma',
      'Oulad Tayeb',
      'Oulad Daoud',
      'Oulad Ayad',
      'Oukaïmedene',
      'Oujda-Angad',
      'Ouijjane',
      'Oued Zem',
      'Oued Laou',
      'Oued Amlil',
      'Ouezzane',
      'Ouarzazat',
      'Oualidia',
      'Zawyat an Nwaçer',
      'Nador',
      'Moulay Yacoub',
      'Moulay Brahim',
      'Moulay Bouchta',
      'Moulay Abdallah',
      'Al Aaroui',
      'Mohammedia',
      'Missour',
      'Midelt',
      'Midar',
      'Meknès',
      'Mediouna',
      'Mechraa Bel Ksiri',
      'Mdiq',
      'Matmata',
      'Martil',
      'Marrakesh',
      'Madagh',
      'Ifrane',
      'Larache',
      'Lalla Mimouna',
      'Ksar El Kebir',
      'Khouribga',
      'Khenifra',
      'Kerrouchen',
      'Kenitra',
      'Kasba Tadla',
      'Kariat Arkmane',
      'Karia Ba Mohamed',
      'Jorf',
      'Jerada',
      'Itzer',
      'Isseksi',
      'Inezgane',
      'Imider',
      'Imilchil',
      'Iguidi',
      'Hassi Berkane',
      'Zerkten',
      'Had Kourt',
      'Oulad Frej',
      'Guisser',
      'Guercif',
      'Guenfouda',
      'Guelmim',
      'Goulmima',
      'Galaz',
      'Fnidek',
      'Al Fqih Ben Çalah',
      'Figuig (Centre)',
      'Fifi',
      'Fezna',
      'Fès al Bali',
      'Fès',
      'Ezzhiliga',
      'Essaouira',
      'Arfoud',
      'El Ksiba',
      'Kelaat Mgouna',
      'El Jadid',
      'El Hajeb',
      'Ouaoula',
      'El Aïoun',
      'Imzouren',
      'Derdara',
      'Demnate',
      'Debdou',
      'Riah',
      'Dar Ould Zidouh',
      'Dar Bouazza',
      'Dar Chaoui',
      'Chichaoua',
      'Chefchaouen',
      'Casablanca',
      'Bzou',
      'Brikcha',
      'Bouznika',
      'Bouskoura',
      'Boumia',
      'Boulemane',
      'Boulaouane',
      'Boudinar',
      'Bouarfa',
      'Bouabout',
      'Bigoudine',
      'Bhalil',
      'Berkane',
      'Beni Mellal',
      'Berrechid',
      'Bab Taza',
      'Azrou',
      'Azilal',
      'Azgour',
      'Azemmour',
      'Assoul',
      'Assads',
      'Asilah',
      'Argana',
      'Arbaoua',
      'Arazane',
      'Aourir',
      'Aoulouz',
      'Aoufous',
      'Amouguer',
      'Amizmiz',
      'Amerzgane',
      'Amersid',
      'Amalou',
      'Alnif',
      'Almis Marmoucha',
      'Khemisset',
      'Al Hoceïma',
      'Aknoul',
      'Ajdir',
      'Anergui',
      'Aïn Leuh',
      'Aïn Beni Mathar',
      'Ahfir',
      'Aguelmous',
      'Agdz',
      'Agadir Melloul',
      'Agadir',
      'Adassil',
      'Adar',
      'Skhirate',
      'Abadou',
      'Mellila',
      'Tidili Mesfioua',
      'Mhamid',
      'Taghazout',
      'Tamri',
      'Smimou',
      'Tizgane',
      'Bouarouss',
      'Azla',
      'Boukhalef',
      'Jebel Tiskaouine',
      'Boujniba',
      'Errachidia',
      'Sidi Abdallah Ghiat',
      'Dakhla',
      'Mosquée',
      'Akhfennir',
      'Gueltat Zemmour',
      'Zawit Al Bour',
      'Salé Al Jadida',
      'Cap Negro II',
      'Bni Bouayach',
      'Imlili',
    ],
  },
  {
    country: 'Monaco',
    cities: [
      'Monte-Carlo',
      'Monaco',
      'La Condamine',
      'Fontvieille',
      'Saint-Roman',
      'Moneghetti',
    ],
  },
  {
    country: 'Moldova',
    cities: [
      'Tiraspolul Nou',
      'Edineţ',
      'Iargara',
      'Vulcăneşti',
      'Vişniovca',
      'Varniţa',
      'Vadul lui Vodă',
      'Ustia',
      'Ungheni',
      'Tvardița',
      'Tiraspol',
      'Teleneşti',
      'Taraclia',
      'Sîngera',
      'Ştefan Vodă',
      'Strășeni',
      'Stăuceni',
      'Bilicenii Vechi',
      'Soroca',
      'Şoldăneşti',
      'Slobozia',
      'Saharna',
      'Rîşcani',
      'Rîbniţa',
      'Rezina',
      'Dolna',
      'Pogăneşti',
      'Pervomaisc',
      'Orhei',
      'Ocniţa',
      'Anenii Noi',
      'Nisporeni',
      'Mîndreşti',
      'Merenii Noi',
      'Maiac',
      'Mărculeşti',
      'Leova',
      'Sîngerei',
      'Cricova',
      'Crasnoe',
      'Hînceşti',
      'Cocieri',
      'Chiţcani',
      'Căuşeni',
      'Ialoveni',
      'Hryhoriopol',
      'Glodeni',
      'Ghindești',
      'Floreşti',
      'Fălești',
      'Giurgiuleşti',
      'Dubăsari',
      'Drochia',
      'Donduşeni',
      'Dnestrovsc',
      'Dancu',
      'Criuleni',
      'Congaz',
      'Comrat',
      'Cimişlia',
      'Chisinau',
      'Ceadîr-Lunga',
      'Cantemir',
      'Camenca',
      'Călăraşi',
      'Cahul',
      'Bucovăţ',
      'Bugeac',
      'Briceni',
      'Bender',
      'Basarabeasca',
      'Bălţi',
      'Otaci',
      'Vatra',
      'Ciorescu',
      'Biruinţa',
    ],
  },
  {
    country: 'Montenegro',
    cities: [
      'Rožaje',
      'Žabljak',
      'Ulcinj',
      'Tuzi',
      'Tivat',
      'Sutomore',
      'Stari Bar',
      'Spuž',
      'Šavnik',
      'Risan',
      'Prčanj',
      'Podgorica',
      'Plužine',
      'Pljevlja',
      'Plav',
      'Petrovac na Moru',
      'Nikšić',
      'Mojkovac',
      'Mojanovići',
      'Mataguži',
      'Lipci',
      'Kotor',
      'Kolašin',
      'Berane',
      'Igalo',
      'Herceg Novi',
      'Gusinje',
      'Goričani',
      'Golubovci',
      'Dobrota',
      'Danilovgrad',
      'Cetinje',
      'Budva',
      'Bijelo Polje',
      'Bijela',
      'Bar',
      'Andrijevica',
      'Petnjica',
      'Šušanj',
    ],
  },
  { country: 'Saint Martin', cities: ['Marigot'] },
  {
    country: 'Madagascar',
    cities: [
      'Toamasina',
      'Vondrozo',
      'Vohipaho',
      'Vohimanitra',
      'Vohibinany',
      'Vavatenina',
      'Vangaindrano',
      'Tsiroanomandidy',
      'Tsiombe',
      'Tsaratanana',
      'Tranovaho',
      'Toliara',
      'Tôlanaro',
      'Soavinandriana',
      'Soanindrariny',
      'Soanierana Ivongo',
      'Sitampiky',
      'Savana',
      'Sambava',
      'Sambaina',
      'Sakaraha',
      'Sahavato',
      'Sahatavy',
      'Sadjoavato',
      'Sadabe',
      'Ranohira',
      'Nosy Varika',
      'Nandihizana',
      'Morondava',
      'Moramanga',
      'Miarinarivo',
      'Miandrivazo',
      'Miandrarivo',
      'Miadanandriana',
      'Merikanjaka',
      'Marovoay',
      'Marolambo',
      'Maroantsetra',
      'Mantasoa',
      'Manjakandriana',
      'Manja',
      'Mandritsara',
      'Manato',
      'Mananjary',
      'Mananara',
      'Manakara',
      'Maintirano',
      'Mahavelona',
      'Mahanoro',
      'Mahalina',
      'Mahajanga',
      'Mahabe',
      'Maevatanana',
      'Kopoky',
      'Ikongo',
      'Ikalamavony',
      'Ihosy',
      'Ifatsy',
      'Ifanadiana',
      'Fianarantsoa',
      'Fenoarivo Be',
      'Fenoarivo Atsinanana',
      'Faratsiho',
      'Farafangana',
      'Fandriana',
      'Fandrandava',
      'Betioky',
      'Beteza',
      'Betafo',
      'Beroroha',
      'Bemaitso',
      'Belo sur Tsiribihina',
      'Beloha',
      'Behompy',
      'Bealanana',
      'Arivonimamo',
      'Antsohimbondrona',
      'Antsohihy',
      'Antsiranana',
      'Antsirabe Afovoany',
      'Antsirabe',
      'Antanimena',
      'Antanifotsy',
      'Antananarivo',
      'Antalaha',
      'Antakotako',
      'Ankazondandy',
      'Ankazobe',
      'Ankazoabo',
      'Anjozorobe',
      'Anjepy',
      'Andramasina',
      'Hell-Ville',
      'Andilamena',
      'Andovoranto',
      'Andapa',
      'Anandravy',
      'Anakao',
      'Ampasimanolotra',
      'Amparafaravola',
      'Ampanihy',
      'Ampahana',
      'Ambovombe',
      'Ambositra',
      'Ambohitseheno',
      'Ambohitrolomahitsy',
      'Ambohitrandriamanitra',
      'Ambodivoara',
      'Ambodifotatra',
      'Amboasary',
      'Amboanjo',
      'Ambilobe',
      'Ambatondrazaka',
      'Ambatolaona',
      'Ambatolampy',
      'Ambatofinandrahana',
      'Ambato Boeny',
      'Ambarakaraka',
      'Ambanja',
      'Ambalavao',
      'Ambalasoa',
      'Alarobia',
      'Antsampanimahazo',
    ],
  },
  {
    country: 'Marshall Islands',
    cities: [
      'Likiep',
      'Jabor',
      'Ailuk',
      'Majuro',
      'Taroa',
      'Ebaye',
      'Enewetak',
      'Ebon',
      'Aur',
      'Arno',
      'Airuk',
      'Mili',
      'Lae',
      'Utrik',
      'Ujae',
      'Rongelap',
      'Loen',
      'Namdrik',
      'Mejit',
      'Lib',
      'Kili',
      'Jabat',
      'Wotje',
      'Wotho',
      'RMI Capitol',
    ],
  },
  {
    country: 'North Macedonia',
    cities: [
      'Zrnovci',
      'Zletovo',
      'Žitoše',
      'Zelino',
      'Zelenikovo',
      'Zajas',
      'Vrutok',
      'Vratnica',
      'Vrapčište',
      'Vraneštica',
      'Vladimirovo',
      'Vinica',
      'Vevčani',
      'Veljusa',
      'Velesta',
      'Vataša',
      'Vasilevo',
      'Markov Grad',
      'Valandovo',
      'Vaksince',
      'Usje',
      'Tuin',
      'Topolčani',
      'Veles',
      'Tetovo',
      'Tearce',
      'Sveti Nikole',
      'Sušica',
      'Studeničani',
      'Strumica',
      'Struga',
      'Strelci',
      'Stojakovo',
      'Shtip',
      'Старо Нагоричане',
      'Star Dojran',
      'Dolno Srpci',
      'Sredno Konjari',
      'Srbinovo',
      'Srbica',
      'Sopotnica',
      'Sopište',
      'Слупчане',
      'Slepče',
      'Slatino',
      'Skopje',
      'Šipkovica',
      'Selce',
      'Sekirnik',
      'Sedlarevo',
      'Saraj',
      'Samokov',
      'Rusinovo',
      'Rostusa',
      'Rosoman',
      'Romanovci',
      'Resen',
      'Rankovce',
      'Radovis',
      'Radolista',
      'Radishani',
      'Pršovce',
      'Probishtip',
      'Prilep',
      'Podareš',
      'Plasnica',
      'Pirok',
      'Pirava',
      'Петровец',
      'Pehčevo',
      'Otlja',
      'Orizari',
      'Orashac',
      'Oraovica',
      'Oktisi',
      'Ohrid',
      'Ognjaci',
      'Obršani',
      'Oblesevo',
      'Novo Selo',
      'Novaci',
      'Nerašte',
      'Negotino',
      'Murtino',
      'Demir Hisar',
      'Morani',
      'Mogila',
      'Mislesevo',
      'Miravci',
      'Miletino',
      'Miladinovci',
      'Mešeišta',
      'Matejce',
      'Marino',
      'Lukovo',
      'Lojane',
      'Logovardi',
      'Ljubin',
      'Lisičani',
      'Lipkovo',
      'Lažani',
      'Larce',
      'Labunista',
      'Kumanovo',
      'Kukurečani',
      'Kuklis',
      'Krušopek',
      'Krusevo',
      'Krivogashtani',
      'Kriva Palanka',
      'Kratovo',
      'Krani',
      'Kosel',
      'Kondovo',
      'Konče',
      'Kochani',
      'Kičevo',
      'Kavadarci',
      'Karbinci',
      'Kanatlarci',
      'Kamenjane',
      'Makedonska Kamenica',
      'Kadino',
      'Јурумлери',
      'Jegunovce',
      'Jankovec',
      'Izvor',
      'Ivankovci',
      'Ilovica',
      'Идризово',
      'Gurgurnica',
      'Grnčari',
      'Grčec',
      'Gradsko',
      'Gradec',
      'Gostivar',
      'Gorobinci',
      'Gorno Orizari',
      'Golema Rečica',
      'Gevgelija',
      'Forino',
      'Lozovo',
      'Džepčište',
      'Drugovo',
      'Dračevo',
      'Gjorče Petro',
      'Dolno Palčište',
      'Dolno Orizari',
      'Dolno Količani',
      'Dolneni',
      'Dolna Banjica',
      'Dobruševo',
      'Dobrošte',
      'Dobri Dol',
      'Desovo',
      'Demir Kapija',
      'Delogožda',
      'Delcevo',
      'Debar',
      'Чучер - Сандево',
      'Crnilište',
      'Cresevo',
      'Češinovo',
      'Čelopek',
      'Cegrane',
      'Čaška',
      'Capari',
      'Булачани',
      'Bukovik',
      'Bučin',
      'Brvenica',
      'Makedonski Brod',
      'Bosilovo',
      'Bojane',
      'Bogovinje',
      'Bogomila',
      'Bogdanci',
      'Blatec',
      'Bitola',
      'Bistrica',
      'Berovo',
      'Beranci',
      'Belčišta',
      'Bedinje',
      'Batinci',
      'Арачиново',
      'Poroj',
      'Strimnica',
      'Dobarce',
      'Šuto Orizare',
      'Butel',
      'Čair',
      'Ilinden',
      'Kisela Voda',
      'Centar Župa',
    ],
  },
  {
    country: 'Mali',
    cities: [
      'Youwarou',
      'Yorosso',
      'Yélimané',
      'Yanfolila',
      'Tominian',
      'Timbuktu',
      'Tessalit',
      'Ténenkou',
      'Taoudenni',
      'Sokolo',
      'Sikasso',
      'Sagala',
      'Ségou',
      'San',
      'Sagalo',
      'Ntossoni',
      'Nioro',
      'Niono',
      'Niafunké',
      "N'Débougou",
      'Nara',
      'Mopti',
      'Molodo',
      'Ménaka',
      'Markala',
      'Koutiala',
      'Koulikoro',
      'Koula',
      'Koro',
      'Kolondiéba',
      'Kolokani',
      'Kokofata',
      'Kita',
      'Kidal',
      'Kènènkoun',
      'Kinmparana',
      'Ké-Macina',
      'Kayes',
      'Kati',
      'Kangaba',
      'Kadiolo',
      'Gourma Rharous',
      'Goundam',
      'Gao',
      'Doumba',
      'Douentza',
      'Djénné',
      'Diré',
      'Dioila',
      'Diéma',
      'Bourem',
      'Bougouni',
      'Bla',
      'Barouéli',
      'Bankass',
      'Bandiagara',
      'Banamba',
      'Bamako',
      'Bafoulabé',
      'Araouane',
      'Ansongo',
      'Abeïbara',
      'Inékar',
      'Ti-n-Essako',
      'Leleni',
      'Figuira-Toma',
      'Werekela',
    ],
  },
  {
    country: 'Myanmar [Burma]',
    cities: [
      'Yenangyaung',
      'Nyaungdon',
      'Yamethin',
      'Wakema',
      'Twante',
      'Taungoo',
      'Thongwa',
      'Thayetmyo',
      'Thaton',
      'Tharyarwady',
      'Thanatpin',
      'Dawei',
      'Taunggyi',
      'Taungdwingyi',
      'Tachilek',
      'Syriam',
      'Sittwe',
      'Shwebo',
      'Sagaing',
      'Yangon',
      'Pyu',
      'Pyinmana',
      'Pyay',
      'Pyapon',
      'Bago',
      'Paungde',
      'Pakokku',
      'Hpa-An',
      'Nyaungshwe',
      'Nyaunglebin',
      'Myitkyina',
      'Myingyan',
      'Myawadi',
      'Myanaung',
      'Mudon',
      'Mawlamyinegyunn',
      'Mawlamyine',
      'Monywa',
      'Mogok',
      'Minbu',
      'Mikenaungea',
      'Myeik',
      'Meiktila',
      'Pyin Oo Lwin',
      'Mawlaik',
      'Maubin',
      'Martaban',
      'Mandalay',
      'Magway',
      'Loikaw',
      'Letpandan',
      'Lashio',
      'Kyaukse',
      'Kyaikto',
      'Kyaiklat',
      'Kyaikkami',
      'Kēng Tung',
      'Kayan',
      'Kawthoung',
      'Kanbe',
      'Hinthada',
      'Hakha',
      'Falam',
      'Chauk',
      'Bogale',
      'Bhamo',
      'Pathein',
      'Myaydo',
      'Nay Pyi Taw',
      'Tagondaing',
      'Tamoowoug',
      'Dellok',
      'Pulei',
      'Klonhtoug',
      'Kyain Seikgyi Township',
    ],
  },
  {
    country: 'Mongolia',
    cities: [
      'Üyönch',
      'Uujim',
      'Urdgol',
      'Uliastay',
      'Ulaantolgoy',
      'Ulaangom',
      'Tsetserleg',
      'Tsetsegnuur',
      'Tsenher',
      'Tseel',
      'Tsalgar',
      'Tsahir',
      'Tsagaantüngi',
      'Tsagaan-Olom',
      'Tsagaanchuluut',
      'Tosontsengel',
      'Tooromt',
      'Tolbo',
      'Tögrög',
      'Tögöl',
      'Tegsh',
      'Taygan',
      'Talshand',
      'Tahilt',
      'Shiree',
      'Sharga',
      'Sharbulag',
      'Sayn-Ust',
      'Sangiyn Dalay',
      'Rashaant',
      'Oygon',
      'Övögdiy',
      'Orgil',
      'Ölgii',
      'Nuga',
      'Nogoonnuur',
      'Naranbulag',
      'Namir',
      'Tsagaanhayrhan',
      'Mandal',
      'Jargalant',
      'Ider',
      'Hunt',
      'Hungiy',
      'Höviyn Am',
      'Höshööt',
      'Holboo',
      'Höhtolgoy',
      'Hödrögö',
      'Havtsal',
      'Har-Us',
      'Harmod',
      'Harhiraa',
      'Haliun',
      'Halban',
      'Erdenet',
      'Dzüyl',
      'Dzuunmod',
      'Dzüünhövöö',
      'Dzür',
      'Dzöölön',
      'Dzel',
      'Dzalaa',
      'Dzag',
      'Dzadgay',
      'Duut',
      'Khovd',
      'Tsagaannuur',
      'Delgermörön',
      'Darvi',
      'Chihertey',
      'Chandmanĭ',
      'Buyant',
      'Buyanbat',
      'Bürenhayrhan',
      'Bulgan',
      'Bugat',
      'Buga',
      'Bor-Üdzüür',
      'Bilüü',
      'Bayshint',
      'Bayan-Uhaa',
      'Bayansayr',
      'Bayan-Ovoo',
      'Bayanhoshuu',
      'Bayangol',
      'Bayanbulag',
      'Bayan',
      'Baruunturuun',
      'Balgatay',
      'Altraga',
      'Altay',
      'Altai',
      'Altanteel',
      'Altanbulag',
      'Altan',
      'Aldar',
      'Horgo',
      'Üydzen',
      'Uubulan',
      'Urt',
      'Uldz',
      'Ulaan-Uul',
      'Ulaanjirem',
      'Ulaanhudag',
      'Ulaanhad',
      'Ulaan-Ereg',
      'Ulaandel',
      'Ulan Bator',
      'Turt',
      'Tsul-Ulaan',
      'Tsoohor',
      'Tsomog',
      'Tsengel',
      'Tsant',
      'Tsagaan-Ovoo',
      'Tsagaandörvölj',
      'Tsagaanders',
      'Töhöm',
      'Teshig',
      'Teel',
      'Tavin',
      'Tavanbulag',
      'Dashinchilling',
      'Suugaant',
      'Sümiyn Bulag',
      'Sühbaatar',
      'Sört',
      'Shireet',
      'Sharïngol',
      'Sharhulsan',
      'Sergelen',
      'Senj',
      'Selenge',
      'Saynshand',
      'Övt',
      'Ovoot',
      'Orhontuul',
      'Örgön',
      'Onon',
      'Ongon',
      'Ongi',
      'Öndörhoshuu',
      'Undurkhaan',
      'Öldziyt',
      'Ögöömör',
      'Nüden',
      'Nomgon',
      'Nart',
      'Mörön',
      'Mönhbulag',
      'Modot',
      'Mayhan',
      'Mardzad',
      'Manhan',
      'Mandalgovi',
      'Dzüünkharaa',
      'Maanĭt',
      'Lün',
      'Javhlant',
      'Javarthushuu',
      'Ingettolgoy',
      'Ihsüüj',
      'Ihbulag',
      'Huurch',
      'Hutag',
      'Hüremt',
      'Hulstay',
      'Hujirt',
      'Khujirt',
      'Noyon Suma',
      'Hövsgöl',
      'Hovd',
      'Hotont',
      'Horiult',
      'Tsagaan-Owoo',
      'Bayan Uula Sumu',
      'Hoolt',
      'Hongor',
      'Undur Sume',
      'Höhbürd',
      'Herlen',
      'Haylaastay',
      'Havirga',
      'Hatavch',
      'Hatansuudal',
      'Hashaat',
      'Orhon',
      'Haraat',
      'Hanhöhiy',
      'Hajuu-Us',
      'Erhet',
      'Ergel',
      'Ereencav',
      'Erdenetsogt',
      'Eg-Uur',
      'Enhtal',
      'Eg',
      'Dzüünharaa',
      'Dzüünbulag',
      'Dzogsool',
      'Dzelter',
      'Dzegstey',
      'Dzaanhoshuu',
      'Dund-Urt',
      'Dundbürd',
      'Doloon',
      'Delgerhaan',
      'Dashbalbar',
      'Darhan',
      'Dalay',
      'Dalandzadgad',
      'Choyr',
      'Choibalsan',
      'Chonogol',
      'Burgaltay',
      'Bumbat',
      'Bulagtay',
      'Bulagiyn Denj',
      'Bulag',
      'Bugant',
      'Bornuur',
      'Böhöt',
      'Bodĭ',
      'Bayasgalant',
      'Bayan-Ulaan',
      'Bayantsagaan',
      'Bayantöhöm',
      'Bayanhongor',
      'Bayandelger',
      'Bayanbaraat',
      'Bat-Öldziyt',
      'Baruun-Urt',
      'Baruunsuu',
      'Hushaat',
      'Badrah',
      'Avraga',
      'Avdzaga',
      'Arvayheer',
      'Arhust',
      'Arguut',
      'Argatay',
      'Argalant',
      'Ar-Asgat',
      'Amardalay',
      'Altan-Ovoo',
      'Asgat',
      'Ulaanshiveet',
      'Sümber',
      'Bayantal',
      'Shiveegovĭ',
      'Höshigiyn-Ar',
      'Зуунмод',
      'Kharkhorin',
    ],
  },
  { country: 'Macao', cities: ['Macau'] },
  {
    country: 'Northern Mariana Islands',
    cities: ['San Jose Village', 'Saipan'],
  },
  {
    country: 'Martinique',
    cities: [
      'Saint-Pierre',
      'Saint-Joseph',
      'Sainte-Marie',
      'Sainte-Luce',
      'Rivière-Pilote',
      'Le Vauclin',
      'Les Trois-Îlets',
      'Saint-Esprit',
      'Le Robert',
      'Le Morne-Rouge',
      'Le Marin',
      'Le Lorrain',
      'Le Lamentin',
      'Le François',
      'La Trinité',
      "L'Ajoupa-Bouillon",
      'Le Gros-Morne',
      'Fort-de-France',
      'Ducos',
      'Basse-Pointe',
    ],
  },
  {
    country: 'Mauritania',
    cities: [
      'Zouerate',
      'Tékane',
      'Tidjikja',
      'Sélibaby',
      'Rosso',
      'Nouakchott',
      'Nouadhibou',
      'Néma',
      'Kiffa',
      'Kaédi',
      '’Elb el Jmel',
      'Chingueṭṭi',
      'Barkéwol',
      'Aioun',
      'Atar',
      'Aleg',
      'Akjoujt',
      'Arafat',
      'Tevragh Zeina',
      'Dar Naim',
    ],
  },
  { country: 'Montserrat', cities: ['Saint Peters', 'Plymouth', 'Brades'] },
  {
    country: 'Malta',
    cities: [
      'Marsaxlokk',
      'Żurrieq',
      'Żejtun',
      'Żebbuġ',
      'Haz-Zebbug',
      'Żabbar',
      'Xgħajra',
      'Xewkija',
      'Xagħra',
      'Valletta',
      'Ta’ Xbiex',
      'Tarxien',
      'Sliema',
      'Siġġiewi',
      'Santa Venera',
      'Santa Luċija',
      'San Pawl il-Baħar',
      'Sannat',
      'San Lawrenz',
      'San Ġiljan',
      'Safi',
      'Paola',
      'Victoria',
      'Rabat',
      'Qrendi',
      'Qormi',
      'Qala',
      'Pietà',
      'Naxxar',
      'Nadur',
      'Munxar',
      'Imtarfa',
      'Imsida',
      'Mqabba',
      'Mosta',
      'Mġarr',
      'Imġarr',
      'Mellieħa',
      'Imdina',
      'Marsaskala',
      'Marsa',
      'Luqa',
      'Senglea',
      'L-Iklin',
      'Lija',
      'Kirkop',
      'Kerċem',
      'Kalkara',
      'Swieqi',
      'Gżira',
      'Floriana',
      'Vittoriosa',
      'Ħamrun',
      'Gudja',
      'Għaxaq',
      'Hal Gharghur',
      'Għarb',
      'Għajnsielem',
      'Fgura',
      'Dingli',
      'Cospicua',
      'Birżebbuġa',
      'Birkirkara',
      'Balzan',
      'Attard',
      'San Ġwann',
      'Fontana',
      'Pembroke',
    ],
  },
  {
    country: 'Mauritius',
    cities: [
      'Verdun',
      'Vacoas',
      'Triolet',
      'The Vale',
      'Terre Rouge',
      'Tamarin',
      'Surinam',
      'Souillac',
      'Sebastopol',
      'Saint Pierre',
      'Saint Julien',
      'Saint Hubert',
      'Saint Aubin',
      'Morcellement Saint André',
      'Rose Belle',
      'Roche Terre',
      'Roches Noire',
      'Grande Rivière Noire',
      'Rivière du Rempart',
      'Rivière des Anguilles',
      'Queen Victoria',
      'Quatre Soeurs',
      'Quatre Bornes',
      'Quartier Militaire',
      'Providence',
      'Poste de Flacq',
      'Port Louis',
      'Plaine Magnien',
      'Plaine des Papayes',
      'Petit Raffray',
      'Petite Rivière',
      'Petite Case Noyale',
      'Pamplemousses',
      'Pailles',
      'Olivia',
      'Nouvelle France',
      'Notre Dame',
      'New Grove',
      'Piton',
      'Montagne Blanche',
      'Moka',
      'Midlands',
      'Melrose',
      'Mahébourg',
      'Long Mountain',
      'Lalmatie',
      'Dagotière',
      'Grand Sable',
      'Grande Rivière Sud Est',
      'Grand Gaube',
      'Grand Baie',
      'Grand Bois',
      'Goodlands',
      'Fond du Sac',
      'Flic en Flac',
      'Espérance Trébuchet',
      'Curepipe',
      'Crève Coeur',
      'Clémencia',
      'Chemin Grenier',
      'Chamouny',
      'Centre de Flacq',
      'Cascavelle',
      'Cap Malheureux',
      'Camp Thorel',
      'Camp Diable',
      'Camp de Masque',
      'Brisée Verdière',
      'Bon Accueil',
      'Bois des Amourettes',
      'Bel Air Rivière Sèche',
      'Beau Vallon',
      'Beau Bassin-Rose Hill',
      'Bambous',
      'Albion',
      'Laventure',
      'Cluny',
      'Cottage',
      'Pointe aux Piments',
      'Congomah',
      'Amaury',
      'Plaines des Roches',
      'Mare La Chaux',
      'Quatre Cocos',
      'Camp Ithier',
      'Ecroignard',
      'Bambous Virieux',
      'Ebene',
      'Gros Cailloux',
      'Mapou',
      'Dubreuil',
      'Le Hochet',
      'Calebasses',
      'Arsenal',
      'Port Mathurin',
      'Baie aux Huîtres',
      'Cargados Carajos',
      'Vingt Cinq',
    ],
  },
  {
    country: 'Maldives',
    cities: [
      'Meedhoo',
      'Male',
      'Hithadhoo',
      'Mahibadhoo',
      'Eydhafushi',
      'Kudahuvadhoo',
      'Viligili',
      'Thinadhoo',
      'Fuvahmulah',
      'Dhidhdhoo',
      'Kulhudhuffushi',
      'Naifaru',
      'Muli',
      'Manadhoo',
      'Ugoofaaru',
      'Funadhoo',
      'Veymandoo',
      'Felidhoo',
      'Hulhumale',
      'Fonadhoo',
      'Vilufushi',
      'Madifushi',
      'Guraidhoo',
      'Open Stage',
      'Maafushi',
      'Gadhdhoo',
      'Vaadhoo',
    ],
  },
  {
    country: 'Malawi',
    cities: [
      'Karonga',
      'Chitipa',
      'Zomba',
      'Thyolo',
      'Salima',
      'Rumphi',
      'Phalombe',
      'Ntchisi',
      'Ntcheu',
      'Nsanje',
      'Nkhotakota',
      'Nkhata Bay',
      'Likoma',
      'Neno',
      'Mzuzu',
      'Mzimba',
      'Mwanza',
      'Mulanje',
      'Mponela',
      'Monkey Bay',
      'Mchinji',
      'Mangochi',
      'Machinga',
      'Luchenza',
      'Liwonde',
      'Livingstonia',
      'Lilongwe',
      'Kasungu',
      'Dowa',
      'Dedza',
      'Chiradzulu',
      'Chipoka',
      'Chikwawa',
      'Blantyre',
      'Balaka',
      'Makata',
    ],
  },
  {
    country: 'Mexico',
    cities: [
      'Maclovio Herrera',
      'La Colonia',
      'Esteros',
      'Cuauhtémoc',
      'López Rayón',
      'González',
      'Graciano Sánchez',
      'Nueva Apolonia',
      'Los Aztecas',
      'Nuevo Tantoán',
      'Santa Martha',
      'Primero de Mayo',
      'El Abra',
      'Gómez Farías',
      'Magdaleno Cedillo',
      'Xicoténcatl',
      'Guadalupe Victoria',
      'San Fernando',
      'Alfredo V. Bonfil',
      'Santa Apolonia',
      'Ramírez',
      'El Control',
      'Ciudad Gustavo Díaz Ordaz',
      'Tezapotla',
      'Pemucho',
      'San Francisco',
      'Matlapa',
      'Picholco',
      'Jalpilla',
      'Las Armas',
      'San Francisco Cuayalab',
      'Nueva Primavera',
      'Tamcuime',
      'Tampate',
      'La Subida',
      'Ojo de Agua',
      'Rascón',
      'Higinio Olivo',
      'Plan de Iguala',
      'Nuevo Tampaón',
      'Las Palmas',
      'Laguna del Mante',
      'Ciudad Valles',
      'Damian Carmona',
      'El Naranjo',
      'Zamachihue',
      'Zumpango',
      'Zumpango del Río',
      'Zumpahuacán',
      'Zozocolco de Hidalgo',
      'Zozocolco de Guerrero',
      'Zocea',
      'Zotoluca',
      'Zoquitlán',
      'Zoquiopan',
      'Zoquiapan',
      'Zoogocho',
      'Zontecomatlán de López y Fuentes',
      'Zongozotla',
      'Zongolica',
      'Santa María Zolotepec',
      'Zitlala',
      'Zinacantán',
      'Zimatlán de Álvarez',
      'Zimapan',
      'Zihuateutla',
      'Zempoala',
      'Villa Zempoala',
      'General Zaragoza',
      'Zaragoza',
      'Zapotlán de Juárez',
      'Zapotitlán',
      'Zapotitlán Palmas',
      'El Zapotal',
      'Zapoapan de Cabañas',
      'Zapoapan de Amapan',
      'Zacualtipán',
      'Zacualpan',
      'Zacoalpan',
      'Xacaxomulco',
      'Zacatlán',
      'Zacatla',
      'Zacatepec',
      'Zacapoaxtla',
      'Zacapala',
      'Zacamixtle',
      'Zacacuautla',
      'Villa de Zaachila',
      'Yutanduchi de Guerrero',
      'Yucuyachi',
      'San Pedro Mártir Yucuxaco',
      'Santa María Yucunicoco',
      'Yotholin',
      'Yoloxóchitl',
      'Yolotepec',
      'Santiago Yogana',
      'Magdalena Yodocono de Porfirio Díaz',
      'San José Yocnajab',
      'Yobain',
      'Yextla',
      'San Agustín Yatareni',
      'Yehualtepec',
      'Yecuatla',
      'Yecapixtla',
      'Yaxkukul',
      'Yaxe',
      'Yaxcopoil',
      'Yaxcabá',
      'Yautepec',
      'Yatzachi',
      'Yaonahuac',
      'Santo Domingo Yanhuitlán',
      'Yanga',
      'Santiago Yancuictlalpan',
      'Yalkoba',
      'Yajalón',
      'Yahualica',
      'Xul-Ha',
      'Xul',
      'Xuchapa',
      'X-pichil',
      'Xoxolpa',
      'Xoxocotla',
      'Xopilapa',
      'Xonacatlán',
      'Xometla',
      'Xocotla',
      'Xococapa',
      'Xochitlán',
      'Xochitlan Todos Santos',
      'Xochitepec',
      'Xochistlahuaca',
      'Xochipala',
      'Xochinanacatlán',
      'Xochimilco',
      'Xochiltepec',
      'Xochihuehuetlán',
      'La Concepción Xochicuautla',
      'Xochicoatlán',
      'Xochiatipan de Castillo',
      'Xochiapulco',
      'Xocen',
      'Xocchel',
      'Xoampolco',
      'Xitlama',
      'Xilocuautla',
      'Xilitla',
      'Xicotepec de Juárez',
      'Xico',
      'Xhitey',
      'Xcanatún',
      'X-Cán',
      'Xayacatlán de Bravo',
      'Xaya',
      'Santa María Xadani',
      'Xanabá',
      'Xaltianguis',
      'Xaltepuxtla',
      'Xaltepec',
      'Xalpatlahuac',
      'Xaloztoc',
      'Santa Ana Xalmimilulco',
      'Xalitzintla',
      'Xalitla',
      'Xaaga',
      'El Volador',
      'Vizarrón',
      'Viva México',
      'Vista Hermosa',
      'San José Vista Hermosa',
      'San Antonio Virreyes',
      'Villa Victoria',
      'Camarón de Tejeda',
      'Álvaro Obregón',
      'Villa Mainero',
      'Villa Hidalgo',
      'Villahermosa',
      'Gustavo Adolfo Madero',
      'Villa Guerrero',
      'Villagrán',
      'Villaflores',
      'El Triunfo',
      'Villa del Carbón',
      'Ciudad Cuauhtémoc',
      'Villa Cuauhtémoc',
      'Villa Corzo',
      'Villa Comaltitlán',
      'Tesechoacan',
      'Villa Aldama',
      'Trinidad de Viguera',
      'Verapaz',
      'Veracruz',
      'Venustiano Carranza',
      'Veinte de Noviembre',
      'Vega del Rosario',
      'Vega de Alatorre',
      'Valle Hermoso',
      'Vallecillo',
      'Valladolid',
      'Valadeces',
      'Otatitlán',
      'Úrsulo Galván',
      'Salvador Urbina',
      'Unión Roja',
      'Unión Juárez',
      'Unión Hidalgo',
      'Unión Buenavista',
      'Umán',
      'El Consuelo Ulapa',
      'Ucú',
      'Uci',
      'Uayma',
      'Uayalceh de Peón',
      'Tzucacab',
      'Tzompantepec',
      'Tzinacapan',
      'Tzimol',
      'Tzicatlán',
      'Tzicatlacoyan',
      'Santa Ana Tzacuala',
      'Tuzuapan',
      'San Javier',
      'Tuzantán',
      'Tuzamapan',
      'Tuxtla',
      'Tuxtla Chico',
      'Tuxtilla',
      'Tuxpanguillo',
      'Túxpam de Rodríguez Cano',
      'Tuxpan',
      'Tunkás',
      'Tunititlán',
      'Tumbala',
      'Tulum',
      'Tultitlán de Mariano Escobedo',
      'Tultepec',
      'Tulcingo de Valle',
      'Tulcingo',
      'Tulapam',
      'Tulancingo',
      'Tula de Allende',
      'Ciudad Tula',
      'Trinidad Zaachila',
      'Tres Zapotes',
      'Tres Picos',
      'Santa Rosa Treinta',
      'Trapiche del Rosario',
      'Transfiguración',
      'Tozongo',
      'Tozihuic',
      'Totutla',
      'Totontepec Villa de Morelos',
      'Totomixtlahuaca',
      'Totoltepec de Guerrero',
      'Totolapan',
      'Totolapa',
      'San José Toxi',
      'San Miguel Topilejo',
      'Tonayán',
      'Santo Domingo Tomaltepec',
      'Tonalixco',
      'Tonalapa del Sur',
      'Tonalá',
      'Tomatlán',
      'Toluca',
      'Tolome',
      'Tolimán',
      'Tolcayuca',
      'Tocuila',
      'Tochtepec',
      'Tochimilco',
      'Tocatlán',
      'Tlazala de Fabela',
      'Tlayehualancingo',
      'Tlayacapan',
      'Tlaxocoyucan',
      'Tlaxmalac',
      'Tlaxco',
      'Tlaxcoapan',
      'Tlaxco (Santiago Tlaxco)',
      'Tlaxcalantongo',
      'San Bernardino Tlaxcalancingo',
      'Santiago Tlautla',
      'Tlatlauquitepec',
      'Tlatenchi',
      'Tlaquiltepec',
      'Tlaquiltenango',
      'San Pedro Tlaquilpan',
      'Tlaquilpa',
      'Tlapanaloya',
      'Tlapa de Comonfort',
      'Tlapacoyan',
      'San Pedro Tlaolantongo',
      'Tlanepantla',
      'Tlanchinol',
      'Tlanalapa',
      'Tlanalapan',
      'Tlamixtlahuacan',
      'Tlamatoca',
      'Tlamanca',
      'Tlamacazapa',
      'Tlaltizapán',
      'Tlaltenango',
      'Tlalpan',
      'Tlalnepantla',
      'Tlalnelhuayocan',
      'Tlalmanalco',
      'Tlalixtaquilla',
      'Tlalixtac de Cabrera',
      'Tlalixcoyan',
      'Tlalcozotitlán',
      'San Andrés Tlalamac',
      'Tlahuelompa (San Francisco Tlahuelompa)',
      'Tlahuelilpan',
      'Tláhuac',
      'Tlacuilotepec',
      'San José Tlacotitlán',
      'Tlacotepec',
      'Tlacotepec (San Mateo)',
      'Tlacotepec de Mejía',
      'Tlacotalpan',
      'Tlacolula de Matamoros',
      'Tlacolula',
      'Tlacojalpan',
      'San Jerónimo Tlacochahuaya',
      'Tlacoachistlahuaca',
      'Tlachichuca',
      'Tlachichilco',
      'Tizimín',
      'Tizayuca',
      'San Felipe Tizapa',
      'Tixtla de Guerrero',
      'Tixpéhual',
      'Tixmehuac',
      'Tixkokob',
      'Tixcancal',
      'Tixcacaltuyub',
      'Tixcacalcupul',
      'Tinum',
      'Timucuy',
      'Tilzapotla',
      'Tiltepec',
      'Santiago Tillo',
      'San Juan Tilcuautla',
      'San Martín Tilcajete',
      'Tilapa',
      'San José Tilapa',
      'Santiago Tilapa',
      'Tila',
      'Tikuch',
      'Tihuatlan',
      'Tiholop',
      'Tierra Nueva',
      'Tierra Colorada',
      'Tierra Blanca Booxter',
      'Tierra Blanca',
      'Ticumán',
      'Ticul',
      'Ticopó',
      'Tibolón',
      'Tianguistengo',
      'Santiago Tianguistenco de Galeana',
      'Tezoyuca',
      'Tezontepec de Aldama',
      'Tezontepec',
      'Tezonteopan de Bonilla',
      'Tezoatlán de Segura y Luna',
      'Teziutlan',
      'Teya',
      'Texistepec',
      'Texhuacán',
      'Texcoco de Mora',
      'Texcatepec',
      'Texcapa',
      'Texcalyacac',
      'San Juan Texcalpan',
      'Texcala',
      'Texca',
      'San Gabriel Tetzoyocán',
      'Tetla',
      'Tetiz',
      'Tetipac',
      'Santa María Tatetla',
      'Tetepango',
      'Teteltzingo',
      'Tetelilla',
      'Teteles de Avila Castillo',
      'Tetelco',
      'Tetela de Ocampo',
      'Tetela del Volcán',
      'Tetecalita',
      'Tetecala',
      'Tesoco',
      'Terrenate',
      'Tequixquiac',
      'Tequisquiapan',
      'Tequila',
      'Tequesquitengo',
      'San Martín Tequesquipan (Tequesquipan)',
      'San Juan Tepulco',
      'Cuautitlán Izcalli',
      'Tepoztlán',
      'Tepotzotlán',
      'Santiago Tepeyahualco',
      'Tepeyehualco de Hidalgo',
      'Tepeyahualco',
      'Tepexi',
      'Tepexco',
      'Tepetzintla',
      'Tepetzingo',
      'Tepetlaoxtoc de Hidalgo',
      'Tepetlán',
      'Tepetlacolco',
      'Barrio Tepetitlán Emilio Portes Gil',
      'Santa Cecilia Tepetitlán',
      'Tepetitla',
      'Tepetitán',
      'Tepepa',
      'Tepeojuma',
      'Tepeji del Río de Ocampo',
      'Tepeitic',
      'Tepehuacán de Guerrero',
      'Tepecoacuilco de Trujano',
      'Tepeapulco',
      'Tepeaca',
      'Tepatlaxco de Hidalgo',
      'Tepatlaxco',
      'Tepatepec',
      'Tepapayeca',
      'Tepango',
      'Tepanco',
      'Tepalcingo',
      'Tepakan',
      'Tepakán',
      'Teotitlán del Valle',
      'Teopisca',
      'Teopantlán',
      'Teoloyucan',
      'Teocelo',
      'Tenosique',
      'San Francisco Tenopalco',
      'Tanetze de Zaragoza',
      'Tenenexpan',
      'Tenejapa',
      'Tenango del Aire',
      'Tenango de Doria',
      'Tenango de Arista',
      'Santiago Tenango',
      'Tenango',
      'Tenancingo de Degollado',
      'Tenampulco',
      'Tenampa',
      'Tenabo',
      'Tempoal de Sánchez',
      'Temozon',
      'Temoaya',
      'Temoac',
      'Temixco',
      'Temimilcingo',
      'Temax',
      'Temascalcingo',
      'Temascalapa',
      'Temapache',
      'Temango',
      'Temamatla',
      'Teloloapan',
      'Telixtac',
      'Telchaquillo',
      'Telchac Puerto',
      'Telchac Pueblo',
      'Tekom',
      'Tekit',
      'Tekik de Regil',
      'Tecax',
      'Tekanto',
      'Tekal de Venegas',
      'San Felipe Tejalápam',
      'San Juan Teitipac',
      'Tehuixtla',
      'Tehuipango',
      'Tehuetlán',
      'Tehuacán',
      'San Antonio Tedzidz',
      'Texin',
      'Tecpatán',
      'Tecozautla',
      'San Marcos Tecomaxusco',
      'Tecomatlán',
      'Tecomatepec',
      'Tecolutla',
      'Villa Tecolutilla',
      'Tecoltepec',
      'Tecolapan',
      'Santa María Guadalupe Tecola',
      'Tekoh',
      'Barra de Tecoanapa',
      'San Pedro Techuchulco',
      'Tecaxic',
      'Tecalpulco',
      'Tecamalucan',
      'Tecamachalco',
      'Tecama',
      'Tecali',
      'Tecajec',
      'Teayo',
      'Teapa',
      'Teabo',
      'Taxco el Viejo',
      'Taxco de Alarcón',
      'Tatatila',
      'Tatahuicapan',
      'Tatahuicapá',
      'Tasquillo',
      'Tapotzingo',
      'Tapilula',
      'Tapijulapa',
      'San José del Tapanco',
      'Tapalapa',
      'Tapachula',
      'Tantoyuca',
      'Tantima',
      'Tanute',
      'Tanquián de Escobedo',
      'Tanlajás',
      'Tankuché',
      'Tancoco',
      'Tancazahuela',
      'Tamulte de las Sabanas',
      'Tamuín',
      'Tampiquito',
      'Tampico Alto',
      'Tampico',
      'Tampemoche',
      'Tampamolón Corona',
      'Tampacán',
      'Perseverancia',
      'Tamiahua',
      'Tambaca',
      'Tamazunchale',
      'Tamasopo',
      'Tamápatz',
      'Tamán',
      'Tamalín',
      'Talol',
      'Congregación el Tajín',
      'Tahmek',
      'San Juan Tahitic',
      'Tahdziu',
      'Tahdzibichen',
      'Tacotalpa',
      'Sunuapa',
      'Sumidero',
      'Suma de Hidalgo',
      'Sultepec',
      'Sudzal',
      'Sucopó',
      'Sucilá',
      'Brisas Barra de Suchiate',
      'Suchiapa',
      'Subteniente López',
      'Soyatitán',
      'Soyataco',
      'Soyaniquilpan',
      'Soyaló',
      'Sotuta',
      'Soto la Marina',
      'Zelocotitlán',
      'Soteapan',
      'Sontecomapan',
      'Solosuchiapa',
      'Soltepec',
      'Soledad de Doblado',
      'Soconusco',
      'Socoltenango',
      'Xochiapa',
      'Sochiapa',
      'Sitpach',
      'Santiago Apostol',
      'Sitilpech',
      'Sisal',
      'Singuilucan',
      'Sinanche',
      'Sinaloa',
      'Simojovel de Allende',
      'Siltepec',
      'Sihochac',
      'Sierra Papacal',
      'Sierra de Agua',
      'Seyé',
      'Seybaplaya',
      'Sergio Butrón Casas',
      'Señor',
      'San Sebastián del Monte',
      'Sayula de Alemán',
      'Los Sauces',
      'Sasaltitla',
      'Simón Sarlat',
      'Santa Isabel Xiloxoxtla',
      'San Vicente Tancuayalab',
      'San Vicente Lachixío',
      'San Vicente Coyotepec',
      'San Vicente Coatlán',
      'San Vicente de Juárez',
      'San Vicente',
      'Mazaltepec',
      'Santo Tomás Jalieza',
      'Hueyotlipan',
      'Chautla',
      'Atzingo',
      'Santo Tomas',
      'Santos Reyes Tepejillo',
      'Santos Reyes Pápalo',
      'Santo Domingo Zanatepec',
      'Xagacía',
      'Santo Domingo Tlatayapam',
      'Santo Domingo Tepuxtepec',
      'Santo Domingo Tehuantepec',
      'Santo Domingo Shomege (Shomege)',
      'Santo Domingo Petapa',
      'Santo Domingo Jalieza',
      'Santo Domingo Huehuetlán',
      'Santo Domingo Chihuitán',
      'Santo Domingo Atoyatempan',
      'Albarradas',
      'Santo Domingo de Guzmán',
      'Santo Domingo',
      'Santillán',
      'Santiago Zoochila',
      'Zautla',
      'Santiago Yeché',
      'Santiago Yaitepec',
      'Santiago Tuxtla',
      'Santiago Tlapacoya',
      'Santiago Tezontlale',
      'Texcaltitlán',
      'Santiago Texcalcingo',
      'Santiago Tepatlaxco',
      'Santiago Tepextla',
      'Santiago Suchilquitongo',
      'Pinotepa Nacional',
      'Santiago Oxthoc',
      'Santiago Nuyoó',
      'Santiago Nundíche',
      'Santiago Nejapilla',
      'Santiago Miltepec',
      'Santiago Michac',
      'Miahuatlán',
      'Santiago Matatlán',
      'Santiago Laxopa',
      'Santiago Laollaga',
      'Santiago Lalopa',
      'Santiago Lachiguiri',
      'Santiago Juxtlahuaca',
      'Santiago Jocotepec',
      'Jamiltepec',
      'Santiago Ixcuintepec',
      'Santiago Huauclilla',
      'Santiago Huajolotitlán',
      'Santiago del Monte',
      'Santiago de la Peña',
      'Santiago de Anaya',
      'Santiago Cuaula',
      'Santiago Comaltepec',
      'Santiago Coltzingo',
      'Santiago Choápam',
      'Santiago Chazumba',
      'Santiago Ayuquililla',
      'Santiago Atzitzihuacán',
      'Santiago Astata',
      'Apoala',
      'Santiago Amoltepec',
      'Santiago Alseseca',
      'Santiago Acutzilapan',
      'Santiago Acatlán',
      'Santiago Tulantepec',
      'Santiago',
      'Santiago Tlaltepoxco',
      'Santiago el Pinar',
      'Santa Teresa',
      'Santa Rosa Xajay',
      'Santa Rosalía',
      'Santa Rosa',
      'Santa Rita Tlahuapan',
      'Santa Rita',
      'Santander Jiménez',
      'Santa Mónica',
      'Santa Martha Chichihualtepec',
      'Santa María Zoquitlán',
      'Zaniza',
      'Santa María Yavesía',
      'Yalina',
      'Santa María Xonacatepec',
      'Xadani',
      'Santa María Tonameca',
      'Tlahuitoltepec',
      'Texcalac',
      'Santa María Tepantlali',
      'Santa María Teopoxco',
      'Temaxcaltepec',
      'Tecomavaca',
      'Techachalco',
      'Santa María Sola',
      'Santa María Rayón',
      'Santa María Quelites',
      'Petapa',
      'Nopaltepec',
      'Santa María Nduayaco',
      'Santa María Nativitas',
      'Nativitas',
      'Moyotzingo',
      'Santa María Mazatla',
      'Santa María Tiltepec',
      'Santa María Magdalena Cahuacán',
      'Santa Maria Macua',
      'Santa María la Alta',
      'Santa María la Asunción',
      'Santa María Jicaltepec',
      'Santa María Jacatepec',
      'Santa María Ixtiyucán',
      'Ipalapa',
      'Santa María Huazolotitlán',
      'Santa María Huatulco',
      'Santa María Guenagati',
      'Santa María Ecatepec',
      'Santa María del Tule',
      'Santa María del Monte',
      'Pino Suárez',
      'Coyomeapan',
      'Santa María Cortijo',
      'Coronango',
      'Santa María Colotepec',
      'Santa María Chimalhuacán',
      'Santa María Chilapa de Díaz',
      'Santa María Chicometepec',
      'Santa María Chachoápam',
      'Santa María Camotlán',
      'Santa María Atzompa',
      'Ayoquezco de Aldama',
      'Santa María Atlihuetzian',
      'Santa María Actipac',
      'Atarasquillo',
      'Heroica Ciudad de Tlaxiaco',
      'Santa María Apaxco',
      'Santa María Apazco',
      'Santa María Amajac',
      'Alotepec',
      'Santa María Ajoloapan',
      'Acuexcomac',
      'Santa María Acú',
      'Santa María',
      'Santa María Citendejé',
      'Santa María Huecatitla',
      'Santa María Huamelula',
      'Santa Margarita Huitepec',
      'Santa Lucía',
      'Sanata Lucía',
      'Santa Isabel Ixtapan',
      'Santa Isabel Cholula',
      'Santa Isabel Chalma',
      'Santa Inés Varela la Luz',
      'Tecuexcomac',
      'Santa Inés del Monte',
      'Ahuatempan',
      'El Carrizal',
      'Santa Gertrudis',
      'Santa Fe Tepetlapa',
      'Guillermo Zúñiga',
      'Santa Elena',
      'Huiloapan',
      'Santa Cruz Xoxocotlán',
      'Santa Cruz Xitla',
      'Manzana Tercera de Santa Cruz Tepexpan',
      'Tecámac',
      'Santa Cruz Tayata',
      'Santa Cruz Tacache de Mina',
      'Santa Cruz Ozolotepec',
      'Santa Cruz Nundaco',
      'Santa Cruz Mixtepec',
      'Santa Cruz Lachixolana',
      'Santa Cruz Tlaxcala',
      'Santa Cruz Cuauhtenco',
      'Santa Cruz',
      'Santa Cruz Ex-Hacienda',
      'Santa Cruz Pueblo',
      'Santa Cruz Ayotuxco',
      'Santa Clara',
      'Santa Clara Huitziltepec',
      'Santa Cecilia Jalieza',
      'Santa Catarina Yecahuizotl',
      'Santa Catarina Tlaltempan',
      'Santa Catarina Tayata',
      'Santa Catarina Quiané',
      'Pantelhó',
      'Santa Catarina Otzolotepec',
      'Santa Catarina Minas',
      'Santa Catarina Mechoacán',
      'Santa Catarina Loxicha',
      'Santa Catarina Juquila',
      'Santa Catarina Cuixtla',
      'Santa Catarina Ayotzingo',
      'Santa Catarina',
      'Santa Catarina del Monte',
      'Santa Catalina',
      'Santa Apolonia Teacalco',
      'Santa Ana Tlapacoyan',
      'Santa Ana Tlacotenco',
      'Teloxtoc',
      'Santa Ana Nextlalpan',
      'Santa Ana Necoxtla',
      'Santa Ana Jilotzingo',
      'Santa Anita Huiloac',
      'Santa Ana Hueytlalpan',
      'Santa Ana del Valle',
      'Santa Ana de Allende',
      'Santa Ana Coatepec',
      'Santa Ana Coapan',
      'Santa Ana Chiautempan',
      'Santa Ana Ahuehuepan',
      'Santa Ana Acozautla',
      'Santa Ana Ixtlahuatzingo (Santa Ana)',
      'Santa Ana',
      'Santa Adelaida',
      'San Simonito',
      'San Simón',
      'San Simón Almolongas',
      'Zinacatepec',
      'Villanueva',
      'San Sebastián Tenochtitlán',
      'San Sebastian Teitipac',
      'Tecomaxtlahuaca',
      'San Sebastián Río Hondo',
      'Nopalera',
      'Ixcapa',
      'San Sebastián Etla',
      'San Sebastián de Las Barrancas Sur',
      'San Sebastián Coatlán',
      'San Sebastián Abasolo',
      'San Sebastián',
      'Huixcolotla',
      'San Salvador el Verde',
      'San Salvador El Seco',
      'San Salvador Atenco',
      'San Salvador',
      'San Raymundo Jalpam',
      'San Rafael Zaragoza',
      'San Rafael Ixtapalucan',
      'San Rafael Calería',
      'San Rafael',
      'San Quintín',
      'San Pedro y San Pablo Tequistepec',
      'Teposcolula',
      'Villa de Etla',
      'Ayutla',
      'San Pedro Yólox',
      'San Pedro Totolápam',
      'San Pedro Topiltepec',
      'San Pedro Tlanixco',
      'San Pedro Tidaá',
      'San Juan y San Pedro Tezompa',
      'Teutila',
      'San Pedro Tetitlán',
      'San Pedro Taviche',
      'San Pedro Tapanatepec',
      'San Pedro Quiatoni',
      'San Pedro Pochutla',
      'San Pedro Petlacotla',
      'San Pedro Ocotepec',
      'San Pedro Mixtepec',
      'San Pedro Mártir Quiechapa',
      'San Pedro Mártir',
      'San Pedro Los Baños',
      'San Pedro Juchaltengo',
      'San Pedro Jicayán',
      'Jaltepetongo',
      'San Pedro Ixtlahuaca',
      'San Pedro Itztla',
      'San Pedro Ixcatlán',
      'San Pedro Huilotepec',
      'San Pedro Huaquilpan',
      'San Pedro Huamelula',
      'San Pedro el Alto',
      'San Pedro Ecatepec',
      'San Pedro de los Metates',
      'San Pedro Cuayuca',
      'Cajonos',
      'San Pedro Buenavista',
      'San Pedro Atoyac',
      'Ciudad de Atlixco',
      'San Pedro Arriba',
      'San Pedro Apóstol',
      'San Pedro Tlachichilco',
      'Yaganiza',
      'San Pablo Villa de Mitla',
      'San Pablo',
      'San Pablo Tecalco',
      'San Pablo Macuiltianguis',
      'San Pablo Ixayoc',
      'San Pablo Huixtepec',
      'San Pablo Huitzo',
      'San Pablo Güilá',
      'San Pablo Etla',
      'San Pablo de las Tunas',
      'San Pablo de las Salinas',
      'San Pablo Coatlán',
      'Chalchihuitán',
      'San Pablo Autopan',
      'San Pablo Atlazalpan',
      'Apetatitlán Antonio Carbajal',
      'San Pablo Anicano',
      'San Pablito',
      'Temextla',
      'San Nicolás el Chico',
      'San Nicolás de los Ranchos',
      'San Nicolas Buenos Aires',
      'Coatepec',
      'San Nicolás',
      'Zozutla',
      'Zinacantepec',
      'Zacaola',
      'San Miguel Xoxtla',
      'San Miguel Contla',
      'San Miguel Tlacamama',
      'San Miguel Tilquiapam',
      'San Miguel Tetepelcingo',
      'San Miguel Tenextatiloyan',
      'San Miguel Tenango',
      'San Miguel Tecuitlapa',
      'Tecuanipa',
      'Villa Talea de Castro',
      'San Miguel Suchixtepec',
      'Villa Sola de Vega',
      'San Miguel Quetzaltepec',
      'San Miguel Peras',
      'San Miguel Panixtlahuaca',
      'San Miguel Oxtotilpan',
      'San Miguel Mimlapan',
      'San Miguel Ixitlán',
      'Huautepec',
      'San Miguel el Grande',
      'San Miguel el Grande Villa Juárez',
      'San Miguel Ejutla',
      'San Miguel del Puerto',
      'San Miguel de La Victoria',
      'San Miguel Coatlinchán',
      'San Miguel Coatlán',
      'San Miguel Chimalapa',
      'Chapultepec',
      'San Miguel Canoa',
      'San Miguel Balderas',
      'San Miguel Aloápam',
      'San Miguel Ajusco',
      'San Miguel Achiutla',
      'San Miguel Tenochtitlán',
      'San Miguel Enyege',
      'San Miguel Almoloyan',
      'San Miguel',
      'San Miguel Tecuiciapan',
      'San Matias Tlalancaleca',
      'San Mateo Xoloc',
      'Tlaixpan',
      'San Mateo Texcalyacac',
      'San Mateo Tecalco',
      'Sindihui',
      'San Mateo Piñas',
      'San Mateo Parra',
      'San Mateo Nejápam',
      'Mexicaltzingo',
      'San Mateo Ixtacalco',
      'San Mateo Yoloxochitlán',
      'San Mateo del Mar',
      'San Mateo Coapexco',
      'San Mateo Cajonos',
      'San Mateo Atenco',
      'San Mateo Almomoloha',
      'San Mateo',
      'San Mateo Ixtlahuaca',
      'Xaltocan',
      'Toxpalan',
      'Totoltepec',
      'San Martín Tlapala',
      'San Martin Texmelucan de Labastida',
      'Peras',
      'San Martín Ojo de Agua',
      'San Pedro la Joya',
      'San Martín de los Canseco',
      'San Martín de las Pirámides',
      'San Martín Chalchicuautla',
      'San Martín Cachihuapan',
      'San Martín Tuchicuitlapilco',
      'San Marcos Tlacoyalco',
      'San Marcos Guaquilpan',
      'San Marcos',
      'San Marcos de León',
      'San Marcial Ozolotepec',
      'Teolocholco',
      'Temalacayuca',
      'San Luis Tehuiloyocan',
      'San Luis Ayucán',
      'San Luis Amatlán',
      'San Luis Acatlán',
      'San Lucas el Viejo',
      'Tejaluca',
      'San Lucas Tecopilco',
      'San Lucas Quiavini',
      'San Lucas Ojitlán',
      'San Lucas Camotlán',
      'San Lucas',
      'San Lorenzo Toxico',
      'Texmelucan',
      'San Lorenzo Oyamel',
      'Ometepec',
      'San Lorenzo Cuauhtenco',
      'San Lorenzo Chiautzingo',
      'Almecatla',
      'San Lorenzo Cuaunecuiltitla',
      'San Lorenzo',
      'San Julián',
      'San Juan Zitlaltepec',
      'San Juan Yatzona',
      'San Juan Yaee',
      'San Juan Xiutetelco',
      'San Juan Tuxtepec',
      'San Juan',
      'Tianguismanalco',
      'San Juan Tetla',
      'San Juan Tepa',
      'Teotihuacán',
      'Tejupa',
      'San Juan Tejaluca',
      'San Juan Solís',
      'San Juan Quiotepec',
      'San Juan Quiahue',
      'San Juan Ozolotepec',
      'San Juan Mixtepec',
      'San Juan Mazatlán',
      'San Juan de los Cues',
      'San Juán Lachigalla',
      'San Juan Juquila Vijanos',
      'San Juan Juquila',
      'San Juan Jaltepec',
      'Ixtenco',
      'San Juan Ixtayopan',
      'San Juan Ixcaquixtla',
      'San Juan Ihualtepec',
      'San Juan Guichicovi',
      'San Juan Guelavía',
      'San Juan Evangelista',
      'San Juan Epatlán',
      'San Juan Diuxi',
      'San Juan del Río',
      'San Juan de los Reyes (Luis Valenzuela)',
      'San Juan del Estado',
      'San Juan de las Huertas',
      'San Juan Cuautla',
      'San Juan Cotzocón',
      'San Juan Coatzospam',
      'San Juan Chilateca',
      'San Juan Cacahuatepec',
      'Valle Nacional',
      'Tuxtepec',
      'Tlacoatzintepec',
      'Teacalco',
      'San Juan Bautista Lo de Soto',
      'Cuicatlan',
      'Coixtlahuaca',
      'Animas Trujano',
      'San Juan Atzompa',
      'San Juan Atzingo',
      'San Juan Atepec',
      'San Juan Atenco',
      'San Juan Amecac',
      'San Juan Achiutla',
      'San Acateno',
      'San José Tlacuitlapan',
      'San José Tenango',
      'San José Lachiguirí',
      'Ixtapa',
      'San José Estancia Grande',
      'San José de las Flores',
      'San José de Gracia',
      'San José Corral Blanco',
      'San José Chiltepec',
      'San José las Chicharras',
      'San José Chiapa',
      'San José Ayuquila',
      'San José Atlán',
      'Alchichica',
      'San José Acateno',
      'San José Tzal',
      'San José del Progreso',
      'San Joaquín',
      'San Joaquín Coapango',
      'San Jerónimo Xayacatlán',
      'Tecuanipan',
      'San Jerónimo Tecóatl',
      'San Jerónimo Taviche',
      'San Jerónimo Sosola',
      'San Jerónimo Silacayoapilla',
      'San Jerónimo Ocotitla',
      'Ixtapantongo',
      'San Jerónimo Coatlán',
      'San Jerónimo Axochitlán',
      'San Jerónimo',
      'San Jacinto',
      'San Isidro Apango',
      'San Isidro',
      'San Ildefonso Villa Alta',
      'San Ildefonso Amatlán',
      'San Ildefonso Tultepec',
      'San Ildefonso',
      'San Hipólito',
      'San Gregorio Cuautzingo',
      'San Gregorio Atzompa',
      'San Gregorio',
      'San Germán',
      'San Gaspar Tonatico',
      'San Gabriel Mixtepec',
      'San Gabriel Chilac',
      'San Gabriel',
      'San Gabriel las Palmas',
      'San Francisco Zacacalco',
      'San Francisco Tlalcilalcalpan',
      'San Francisco Tetlanohcan',
      'San Francisco Tepexoxica',
      'San Francisco Telixtlahuaca',
      'San Francisco Sola',
      'San Francisco Ozolotepec',
      'San Francisco Oxtotilpan',
      'Mixtla',
      'San Francisco Logueche',
      'San Francisco Lachigoló',
      'San Francisco Huehuetlán',
      'San Francisco del Mar Viejo',
      'San Francisco Coatlán',
      'San Francisco Chindúa',
      'San Francisco Cajonos',
      'Atexcatzingo',
      'San Francisco Acuautla',
      'Occidente (San Francisco)',
      'San Francisco Ozomatlán',
      'San Félix Hidalgo',
      'San Felipe Usila',
      'Teotlaltzingo',
      'San Felipe Otlaltepec',
      'San Felipe Jalapa de Díaz',
      'Villa Mariano Matamoros',
      'San Felipe del Progreso',
      'San Felipe Coamango',
      'San Felipe',
      'San Esteban Tizatlán',
      'Necoxcalco',
      'Cuecuecuatitla',
      'Cuautempan',
      'San Esteban Atatlahuca',
      'San Esteban',
      'Yauhquemehcan',
      'San Dionisio Ocotlán',
      'San Dionisio Ocotepec',
      'San Dionisio del Mar',
      'Xochimanca',
      'San Diego Linares',
      'San Diego Huehuecalco',
      'Ejido San Diego',
      'Sanctórum',
      'Sanctorum',
      'San Pedro de los Baños',
      'San Cristóbal Lachirioag',
      'San Cristóbal de las Casas',
      'San Cristóbal Amoltepec',
      'San Cristóbal Amatlán',
      'San Cristóbal',
      'San Ciro de Acosta',
      'San Carlos Yautepec',
      'San Carlos',
      'San Caralampio',
      'San Buenaventura',
      'San Bernardo Mixtepec',
      'San Bernardino Lagunas',
      'San Bernardino',
      'Amaxac de Guerrero',
      'San Bartolo Yautepec',
      'San Bartolo Tutotepec',
      'Teontepec',
      'Soyaltepec',
      'San Bartolo Oxtotitlán',
      'San Bartolomé Loxícha',
      'San Bartolomé Ayautla',
      'San Bartolo del Llano',
      'San Bartolo Cuautlalpan',
      'San Bartolome Xicomulco',
      'San Baltazar Loxicha',
      'San Baltazar Guelavila',
      'San Baltazar Chichicapam',
      'Xochitlaxco (San Baltazar)',
      'San Baltazar Atlimeyaya',
      'Zoyatzingo',
      'San Antonio Zaragoza',
      'San Antonio Sahcabchén',
      'San Antonio Rayón',
      'San Antonio Ocotlán',
      'Nanahuatípam',
      'San Antonio de la Cal',
      'Cuaxomulco',
      'San Antonio Cañada',
      'San Antonio',
      'San Antonio el Grande',
      'San Antonio la Isla',
      'San Andrés Zautla',
      'San Andrés Yaá',
      'San Andrés Tuxtla',
      'San Andrés Timilpan',
      'Tepetlapa',
      'San Andrés Tepetitlán',
      'San Andrés Teotilalpam',
      'San Andrés Tenejapan',
      'San Andrés Solaga',
      'San Andrés Sinaxtla',
      'San Andrés Payuca',
      'San Andrés Paxtlán',
      'San Andrés Ixtlahuaca',
      'San Andrés Hueyacatitla',
      'San Andrés Huayápam',
      'San Andrés Huaxpaltepec',
      'San Andrés Calpan',
      'San Andrés Cacaloapan',
      'San Andrés',
      'San Andrés del Pedregal',
      'Sanahcat',
      'San Agustín Zapotlán',
      'San Agustín Tlaxiaca',
      'San Agustín Oapan',
      'San Agustín Loxicha',
      'San Agustín Huixaxtla',
      'San Agustín Etla',
      'San Agustín Amatengo',
      'San Agustin de las Juntas',
      'Samahil',
      'Salto de Agua',
      'Saltillo',
      'Saltabarranca',
      'Salitrillo',
      'Salinas',
      'Salina Cruz',
      'Salazar',
      'Saladero',
      'Sahcaba',
      'Sacalum',
      'Sabidos',
      'Sabanillas',
      'Sabanilla',
      'Sabancuy',
      'Sabanas de Xalostoc',
      'Saban',
      'El Rosario',
      'San Roque',
      'Juan Rodríguez Clara',
      'Rizo de Oro',
      'Rioverde',
      'Río Lagartos',
      'Río Grande',
      'Río Frío de Juárez',
      'Ciudad Río Bravo',
      'Río Blanco',
      'Colonia Rincón Viejo',
      'Rincón Moreno',
      'Rincón de Guadalupe',
      'Rinconada',
      'Ricardo Flores Magón',
      'Reynosa',
      'Reyes Etla',
      'La Resurrección',
      'El Remolino',
      'El Refugio',
      'Reforma de Pineda',
      'Reforma',
      'Rayón',
      'Raymundo Enríquez',
      'Cantón Rancho Nuevo',
      'Ramón F. Balboa',
      'Rafael Lucio',
      'Grajales',
      'Rafael J. García',
      'Rafael Delgado',
      'Rodulfo Figueroa',
      'Quintero',
      'Quintana Roo',
      'Santa Catalina Quieri',
      'Santa María Quiegolani',
      'Santiago Quiavicuzas',
      'Quiamoloapan',
      'Emancipación Quetzalapa',
      'Quetzalapa',
      'Quechultenango',
      'Quechulac',
      'Quecholac',
      'Quebrantadero',
      'Puxcatán',
      'Putla Villa de Guerrero',
      'Pustunich',
      'Purísima de Arista',
      'San Juan Volador',
      'Puerto Madero',
      'Puerto Escondido',
      'Puerto Ángel',
      'Puerta de Palmillas',
      'Puente de Ixtla',
      'Pueblo Nuevo',
      'Santa Cruz Pueblo Nuevo',
      'Pueblillo',
      'Puebla',
      'Pucté',
      'Progreso de Zaragoza',
      'Progreso de Alvaro Obregon',
      'Progreso',
      'Progreso Industrial',
      'Presidio',
      'Pozuelos',
      'Poza Rica de Hidalgo',
      'Potrero Viejo',
      'Potrero del Llano',
      'Potrerillo',
      'Portezuelo',
      'Popola',
      'Pomuch',
      'Polyuc',
      'Polotitlán de la Ilustración',
      'Pololcingo',
      'Polanco',
      'Pocboc',
      'Pluma Hidalgo',
      'Playa Vicente',
      'Playa del Carmen',
      'Platón Sánchez',
      'Los Plátanos',
      'Platanillo',
      'Plan de las Hayas',
      'Plan de Ayala',
      'Plan de Arroyos',
      'Pixoy',
      'Piste',
      'Pisaflores',
      'Pinotepa de Don Luis',
      'Pinal de Amoles',
      'Pijijiapan',
      'Piedras Negras',
      'Piedra Pinta',
      'Piedra Parada',
      'Piedra Labrada',
      'Zamora Pico de Oro',
      'Pichucalco',
      'Pich',
      'Piaxtla',
      'Peto',
      'Petlalcingo',
      'Petaquillas',
      'Petalcingo',
      'Perote',
      'Perla de Acapulco',
      'Pencuyut',
      'Peñamiller',
      'Colonias Pedernales',
      'Paxtepec',
      'Pavencul',
      'Patla',
      'Pathé',
      'Paso Nacional',
      'Paso Hondo',
      'Paso de Ovejas',
      'Paso del Toro',
      'Paso del Macho',
      'Paso del Correo',
      'Parás',
      'Paraiso',
      'Papatlatla',
      'Papantla de Olarte',
      'Santa Cruz Papalutla',
      'Papalotla',
      'Papaloctipan',
      'Pánuco',
      'Pantepec',
      'Panotla',
      'Panales',
      'Panabá',
      'Palomas',
      'Palomares',
      'Palo Blanco',
      'Palo Bendito',
      'Nicolás Bravo',
      'Palmillas',
      'Calichar Palma Sola',
      'Palmas de Abajo',
      'Palmarito Tochapan',
      'El Palmar Grande',
      'Palmar de Bravo',
      'Palizada',
      'Palenque',
      'San Vicente Palapa',
      'Pajapan',
      'Pahuatlán de Valle',
      'Pahuatlán',
      'Pachuquilla',
      'Pachuca de Soto',
      'Pacho Viejo',
      'San Martín Pachivia (Pachivia)',
      'Pachiquita',
      'Pablo L. Sidar',
      'Ozumba de Alzate',
      'Ozuluama de Mascareñas',
      'Santo Domingo Ozolotepec',
      'San Mateo Ozolco',
      'Ozelonacaxtla',
      'Oyameles de Hidalgo',
      'Oxtotipac',
      'San Pablo Oztotepec',
      'Oxolotán',
      'Oxkutzkab',
      'Oxiacaque',
      'Oxcum',
      'Oxchuc',
      'Oviedo',
      'Otumba',
      'Oteapan',
      'Otates',
      'Ostuacán',
      'Oxtotitlán',
      'San Felipe Orizatlán',
      'Orizabita',
      'Orizaba',
      'Orilla del Monte',
      'Oriental',
      'Opichén',
      'Once de Abril',
      'Omoa',
      'Omitlán de Juárez',
      'Omealca',
      'Oluta',
      'Olintla',
      'Olinalá',
      'Olcuatitán',
      'Ohuilapam',
      'Ohuapan',
      'Ucum',
      'Ocuituco',
      'Ocuilan de Arteaga',
      'Ocozocoautla de Espinosa',
      'Ocoyoacac',
      'Ocozotepec',
      'Ocotlán de Morelos',
      'Ocotlán de Betancourt',
      'San Francisco Ocotlán',
      'Ocotlamanic',
      'Santa María Magdalena Ocotitlán',
      'Ocotitlán',
      'Santo Domingo Ocotitlán',
      'Venta de Ocotillos',
      'Ocotepec',
      'Ocotal Chico',
      'Ocosingo',
      'Ocampo',
      'Oaxtepec',
      'Oaxaca',
      'Oacalco',
      'Nunkiní',
      'Osumacinta',
      'Nuevo San Juan Chamula',
      'Nuevo Progreso',
      'Nueva Villa de Padilla',
      'Nuevo Pacayal',
      'Nuevo Morelos',
      'Nuevo Laredo',
      'Nuevo Amatenango',
      'Nueva Palestina',
      'Nueva Libertad',
      'Nueva Independencia',
      'Nueva Colombia',
      'Nueva Ciudad Guerrero',
      'Nueva América',
      'Paraiso Novillero',
      'Nopalucán',
      'Nopalapan',
      'Nopala de Villagran',
      'Nolo',
      'Nogales',
      'Niltepec',
      'Nicolás Ruiz',
      'Nicolás Romero',
      'Netzahualcóyotl',
      'Nepopualco',
      'Nepantla de Sor Juana Inés de la Cruz',
      'Nejapa de Madero',
      'Necaxa',
      'Nealtican',
      'Nauzontla',
      'Nautla',
      'Naupan',
      'Naucalpan de Juárez',
      'Natívitas',
      'Naranjos',
      'Naranjal',
      'Naolinco de Victoria',
      'Nanchital de Lázaro Cárdenas del Río',
      'Ciudad de Nanacamilpa',
      'Nacajuca',
      'Muxupip',
      'Muna',
      'Multé',
      'Mozomboa',
      'Moyotepec',
      'Moyos',
      'Motzorongo',
      'Motul',
      'Motozintla',
      'Motovatha',
      'El Morro',
      'Morelos Cañada',
      'Moralillo',
      'Monterrey',
      'Monte Redondo',
      'Montemorelos',
      'Montecristo',
      'Montecillos',
      'Montecillo',
      'La Laguna y Monte del Castillo',
      'Monte Blanco',
      'Santiago Momoxpan',
      'Moloacán',
      'Molas',
      'Molango',
      'Mohoneras',
      'Mocochá',
      'Mochitlán',
      'Mixtla de Altamirano',
      'Santa María Mixtequilla',
      'San Andrés Mixquic',
      'Mixquiapan',
      'Mixquiahuala de Juarez',
      'Mixcum',
      'Misantla',
      'Miramar',
      'Miquihuana',
      'Minzapan',
      'Mineral del Monte',
      'Minatitlán',
      'Milpa Alta',
      'Mier',
      'Michimaloya',
      'Michac',
      'Miahuatlán de Porfirio Díaz',
      'Miacatlán',
      'Mezcala',
      'Mextepec',
      'Mexcalcuautla',
      'Metztitlán',
      'Mezquititlán',
      'Metlatónoc',
      'Metlapa',
      'Metlaltoyuca',
      'San Juan Metaltepec',
      'Metepec',
      'Metapa',
      'La Mesilla',
      'Mérida',
      'Méndez',
      'Melchor Ocampo',
      'Medias Aguas',
      'Medellín de Bravo',
      'Mecayapan',
      'Mecatlán',
      'San Miguel Mecatepec',
      'Mecatepec',
      'Mecapalapa',
      'Mazatlán Villa de Flores',
      'Mazatlán',
      'Mazatepec',
      'Mazatecochco',
      'Mazatán',
      'Mazapa',
      'Santa Ana Mayorazgo',
      'Mayapan',
      'Mayanalán',
      'Maxcanú',
      'Matzaco',
      'Matlahuacales Aquiles Serdán',
      'Matías Romero',
      'Mata Tenatito (Casco Hacienda)',
      'Heroica Matamoros',
      'Matamoros',
      'Mata Loma',
      'Mazumiapam',
      'Martínez de la Torre',
      'Maitinez',
      'Martinez',
      'Marte R. Gómez',
      'Mariano Escobedo',
      'Maravilla Tenejapa',
      'Mapastepec',
      'Mapachapa',
      'Manuel Lazos',
      'Colonia Manuel González',
      'Ursulo Galván',
      'Reyes Mantecón',
      'Manlio Fabio Altamirano',
      'Mani',
      'Mancuernas',
      'Mamithi',
      'Mama',
      'Maltrata',
      'Malinaltepec',
      'Malinalco',
      'Santa María Malacatepec',
      'Mahuixtlan',
      'Maguey Blanco',
      'Magdalena Tlacotepec',
      'Magdalena Tequisistlán',
      'Magdalena Teitipac',
      'Magdalena Ocotlán',
      'Magdalena Mixtepec',
      'La Magdalena Tetela Morelos',
      'Magdalena Jaltepec',
      'Magdalena Cuayucatepec',
      'La Magdalena Chichicaspa',
      'Magdalena Contreras',
      'Mafafas',
      'Macuspana',
      'Macultepec',
      'Mactún',
      'Luis Gil Pérez',
      'Los Romeros',
      'Reyes Acozac',
      'Los Reyes Acatlixhuayán',
      'Los Reyes',
      'Los Reyes Acaquilpan',
      'Los Reyes de Juárez',
      'Los Ramones',
      'Los Órganos de San Agustín',
      'Los Naranjos',
      'Los Molinos',
      'Los Lirios',
      'Los Ídolos',
      'Valle de Vázquez',
      'Los Herreras',
      'Los Guerra',
      'Los Cerritos',
      'Los Ángeles Tetela',
      'Los Altos',
      'Almagres',
      'Los Aldamas',
      'Lomas del Real',
      'Santa Rosa Loma Larga',
      'Loma Grande',
      'Loma de Sogotegoyo',
      'Las Lomas',
      'Loché',
      'Llera de Canales',
      'Llano de Enmedio',
      'Linares',
      'Limones',
      'Héroes de Chapultepec',
      'Libre Unión',
      'Libres',
      'Libertad Ventanas',
      'Lerma',
      'Lerma de Villada',
      'Lerdo de Tejada',
      'Lepan',
      'Lázaro Cárdenas',
      'Zacualpa',
      'La Victoria',
      'La Ventosa',
      'La Venta',
      'La Trinitaria',
      'La Tortuga',
      'La Tinaja',
      'La Tigrilla',
      'Las Vigas de Ramírez',
      'Las Rosas',
      'Vegas de la Soledad y Soledad Dos',
      'Las Minas',
      'Las Mesas',
      'Las Margaritas',
      'Las Higueras',
      'Las Delicias',
      'Cantón las Delicias',
      'Las Choapas',
      'Las Barrillas',
      'Las Amilpas',
      'Sabaneta',
      'Larráinzar',
      'Congregación Reforma',
      'La Pesca',
      'La Perla',
      'La Pedrera',
      'La Palmilla',
      'La Orduña',
      'Nueva Morelia',
      'Landero y Coss',
      'Landa de Matamoros',
      'Río Verde',
      'La Misión',
      'La Magdalena Yancuitlalpan',
      'Magdalena Atlicpac',
      'La Magdalena',
      'La Loma',
      'La Libertad',
      'La Laja',
      'La Lagunilla',
      'La Laguna',
      'Gilberto Camacho',
      'La Joya',
      'La Isla',
      'Lagunillas',
      'Lagunilla',
      'Laguna del Cofre',
      'Laguna de Farfán',
      'Laguna Chica (Pueblo Nuevo)',
      'La Guadalupe',
      'La Grandeza',
      'La Gloria',
      'La Estancia',
      'La Esperanza',
      'La Defensa',
      'Santa Bárbara de La Cueva',
      'Santo Tomás la Concordia',
      'La Concordia',
      'La Concepción de los Baños',
      'La Concepción Coatipac (La Conchita)',
      'La Concepción',
      'La Sidra',
      'San Martín Lachila',
      'Carboneras',
      'La Cañada',
      'Kopomá',
      'Komchén',
      'Kochol',
      'San Francisco Kobén',
      'Kini',
      'Kinchil',
      'Kimbila',
      'Kaua',
      'Kantunil',
      'Kanasín',
      'Jumiltepec',
      'Juchitepec',
      'Juchitán de Zaragoza',
      'Juchique de Ferrer',
      'Colonia Juárez',
      'Juan Sarabia',
      'Juan Marcos (San José Buenavista)',
      'José Cardel',
      'Joquicingo',
      'Jopala',
      'Jonuta',
      'Jonotla',
      'Jonacatepec',
      'Jonacapa',
      'Jojutla',
      'Jocotitlán',
      'Joaquín Miguel Gutiérrez',
      'Joachín',
      'Jiutepec',
      'Jitotol',
      'Jiquipilco',
      'Jiquipilas',
      'Jilotzingo',
      'Jilotepec de Molina Enríquez',
      'Jilotepec',
      'Jicolapa',
      'Jicayán de Tovar',
      'Jesús Carranza',
      'San Juan Bautista Jayacatlán',
      'Jaumave',
      'Jantetelco',
      'San Leoncio Jamaya',
      'Jamapa',
      'Jaltocan',
      'Jáltipan de Morelos',
      'Jaltepec de Candayoc',
      'Jaltepec',
      'Jaltenco',
      'Jalpan',
      'Jalpa de Méndez',
      'Jalpa',
      'Ex-hacienda de Xalpa',
      'Xalostoc',
      'Jaleaca de Catalán',
      'Jalcomulco',
      'Xalatlaco',
      'Jalapilla',
      'Xalapa de Enríquez',
      'Jalapa',
      'Jalacingo',
      'Jacala',
      'Izúcar de Matamoros',
      '1ra. Sección de Izapa',
      'Izamal',
      'Ixtolco de Morelos',
      'Ixtlán de Juárez',
      'Ixtlahuaca',
      'Ixtlahuaca de Rayón',
      'Ixtepec',
      'Santiago Ixtayutla',
      'Ixtapangajoya',
      'Ixtapan de la Sal',
      'Ixtapaluca',
      'Iztapalapa',
      'Ixtaczoquitlán',
      'Ixtacomitán',
      'Ixtacamaxtitlán',
      'Iztacalco',
      'Ixpila',
      'Ixmiquilpan',
      'Ixhuapán',
      'Ixil',
      'Ixhuatlán de Madero',
      'Ixhuatlán del Café',
      'Ixhuatlancillo',
      'San Francisco Ixhuatan',
      'Ixcatlán',
      'Ixcatepec',
      'Ixcateopan de Cuauhtémoc',
      'Ixcateopan',
      'Ixcapuzalco',
      'Ixcapantla',
      'Ixcuinquitlapilco',
      'Itzamatitlán',
      'Iturbide',
      'Isla Mujeres',
      'Isla Aguada',
      'Ixhuatán',
      'Villa Independencia',
      'Iliatenco',
      'Ilamatlán',
      'Igualapa',
      'Iguala de la Independencia',
      'Ignacio Zaragoza',
      'Ignacio López Rayón',
      'Ignacio de la Llave',
      'Paseos de Itzincab',
      'Hunuku',
      'Hunucmá',
      'Huixtla',
      'Huixtán',
      'Huixquilucan de Degollado',
      'Ciudad de Huitzuco',
      'San Pedro Huitzizilapan',
      'Santa Cruz Huitziltepec',
      'Huitziltepec',
      'Huitzililla',
      'Huitzilan',
      'Huitzilac',
      'Huitzila',
      'San Lucas Huitzilhuacán',
      'Huitiupán',
      'Huitepec',
      'San Antonio Huitepec',
      'Huitchila',
      'Huixtepec',
      'Huimanguillo',
      'San Juan Huiluco',
      'Huiloapan de Cuauhtémoc',
      'Huilacapixtla',
      'Huichihuayan',
      'Huichapan',
      'Huhi',
      'Hueytlalpan',
      'Hueytamalco',
      'Hueypoxtla',
      'Hueycantenango',
      'Hueyapan de Ocampo',
      'Hueyapan',
      'Hueyapa',
      'Huexotitla',
      'El Huexco',
      'Huepalcalco (San Miguel)',
      'Huejutla de Reyes',
      'Huejotzingo',
      'Huejonapan',
      'Huehuetoca',
      'Huehuetlán',
      'Huehuetla',
      'Huehuetán',
      'Huazuntlán',
      'Huazulco',
      'Huazantlán del Río',
      'Huay-Pix',
      'Huayacocotla',
      'Huaxcaleca',
      'Huautla de Jiménez',
      'Huautla',
      'Huauchinango',
      'Huatusco',
      'Huatlatlauca',
      'Huatecalco',
      'Huasca de Ocampo',
      'Huaquechula',
      'Huamuxtitlán',
      'Hualahuises',
      'Ciudad de Huajuapan de León',
      'Huajoyuca',
      'Huajintlán',
      'Huajintepec',
      'Huahuaxtla',
      'Ex-Hacienda el Hospital',
      'Hornitos',
      'Hopelchén',
      'Hool',
      'Homun',
      'Hoctun',
      'Hocaba',
      'Higuerillas',
      'Hidalgotitlán',
      'Hidalgo',
      'Hecelchakán',
      'Hampolol',
      'Halachó',
      'Hacienda Blanca',
      'Gutiérrez Zamora',
      'Guixé',
      'Güémez',
      'Guaytalpa',
      'Guadalupe',
      'Guadalupe Tlachco',
      'Guadalupe Santa Ana',
      'Guadalupe Hidalgo',
      'Guadalupe Grijalva',
      'Nuevo Juan del Grijalva',
      'General Treviño',
      'Ciudad General Terán',
      'Tancanhuitz',
      'General Bravo',
      'San Gabriel Azteca',
      'Fuentezuelas',
      'Frontera Hidalgo',
      'Comalapa',
      'Frontera',
      'Francisco I. Madero',
      'Fortín de las Flores',
      'Fontezuelas',
      'Flor de Cacao',
      'Filomeno Mata',
      'Felipe Carrillo Puerto',
      'Faja de Oro',
      'Ezequiel Montes',
      'Euán',
      'Puntilla Aldama',
      'Estapilla',
      'La Estanzuela',
      'Espita',
      'El Espinal',
      'Esperanza',
      'Españita',
      'Escuintla',
      'Escolín de Olarte',
      'Escárcega',
      'Epazoyucan',
      'Entabladero',
      'Villa Emilio Carranza',
      'Emiliano Zapata',
      'Villa Emiliano Zapata',
      'El Vergel',
      'El Tejar',
      'Sabinalito',
      'El Rodeo',
      'Los Robles',
      'El Rincón',
      'El Realito',
      'El Porvenir de Velasco Suárez',
      'El Portal',
      'El Pital',
      'El Pintor',
      'El Paredón',
      'El Palmar',
      'El Pacayal',
      'San Marcos Eloxochitlán',
      'Eloxochitlán',
      'Eloxochitlán de Flores Magón',
      'Elotepec',
      'El Ocotito',
      'El Nopal',
      'El Molino',
      'El Mirador',
      'Ciudad Mante',
      'El Maguey',
      'El Llano',
      'El Limonar',
      'El Limón',
      'El Laurel',
      'El Juile',
      'El Jobo',
      'El Jagüey',
      'El Humo',
      'El Higo',
      'El Galaneño',
      'El Estudiante',
      'El Cuyo',
      'El Cocuite',
      'El Corte',
      'Corralero',
      'El Ciruelo',
      'El Ciervo',
      'Los Cenotes',
      'El Cazadero',
      'El Castillo',
      'El Carmen Aztama',
      'El Bosque',
      'El Blanco',
      'El Arenal',
      'El Anono',
      'El Águila',
      'Ekpedz',
      'Ejutla de Crespo',
      'Ejido Viejo',
      'Ejido Nuevo',
      'Ecatzingo de Hidalgo',
      'Ecatepec de Morelos',
      'Ébano',
      'Dzununcán',
      'Dzula',
      'Dziuche',
      'Dzoncauich',
      'Dzityá',
      'Dzitnup',
      'Dzitbalché',
      'Dzitás',
      'Dzilam González',
      'Dzilam de Bravo',
      'Dzidzantun',
      'Dzibikak',
      'Dzibalchén',
      'Dzemul',
      'Dzan',
      'Doxhicho',
      'Doxey',
      'Dos Ríos',
      'Dos Bocas',
      'Dos Arroyos',
      'Domingo Arenas',
      'Dolores Jaltenango',
      'Doctor González',
      'Doctor Coss',
      'Villa Díaz Ordaz',
      'Dantzibojay',
      'Cuzama',
      'Cuyoaco',
      'Cupilco',
      'Cunduacán',
      'Cuncunul',
      'Cumpich',
      'Santiago Cuixtla',
      'Cuitláhuac',
      'Cuilapan de Guerrero',
      'Cuichapa',
      'Cuexpala',
      'Santa María Cuevas',
      'Cuetzalan',
      'Cuetzala del Progreso',
      'Paso Morelos',
      'Cuernavaca',
      'Cuentepec',
      'Cucuyulapa Primera Sección',
      'Cuautotola',
      'Cuautlapán',
      'Cuautlancingo',
      'Cuautla',
      'Santiago Cuautlalpan',
      'Cuautitlán',
      'Cuautinchán',
      'Cuautepec de Hinojosa',
      'Cuautepec',
      'Cuauchichinola',
      'Cuatolol',
      'Cuapiaxtla de Madero',
      'Cuapiaxtla',
      'Cuanalá',
      'San José Cuamantzingo',
      'Cualác',
      'Coajomulco',
      'Cuajinicuilapa',
      'Delegación Cuajimalpa de Morelos',
      'Cuacnopalan',
      'Cruz Grande',
      'Cruz del Milagro',
      'Cruz Blanca',
      'Cruillas',
      'Nuevo Crucitas',
      'Cristóbal Obregón',
      'Cozumel',
      'Coyutla',
      'San Luis Coyotzingo',
      'Coyotillos',
      'Coyotepec',
      'Coyoacán',
      'Collantes',
      'Coxquihui',
      'Coxcatlán',
      'Cotaxtla',
      'Cosoleacaque',
      'Cosolapa',
      'Coscomatepec de Bravo',
      'Cosautlán',
      'Cosamaloapan',
      'Corral Nuevo',
      'Corralillos',
      'Corozal',
      'Córdoba',
      'Copoya',
      'Copila',
      'Copanatoyac',
      'Copalillo',
      'Copala',
      'Copainalá',
      'Contla',
      'El Contadero de Matamoros',
      'Constancia del Rosario',
      'Conkal',
      'Conejos',
      'Concepción Capulac (La Ex-Hacienda)',
      'Concá',
      'Comoapan',
      'Comitán',
      'San Pedro Comitancillo',
      'Comapa',
      'Comaltepec',
      'Comales',
      'Comalcalco',
      'Colucan',
      'Colotlipa',
      'Colotepec',
      'Colonia Yucatán',
      'El Venado',
      'Colipa',
      'Colatlán',
      'Cohuecán',
      'Coetzala',
      'Cocula',
      'Cocoyotla',
      'Cocoyol',
      'Cocoyoc',
      'Cocotitlán',
      'Coba',
      'Coatzintla',
      'Coatzingo',
      'Coatzacoalcos',
      'Coatlán del Río',
      'Coatetelco',
      'Coatepec Harinas',
      'Coatepec Costales',
      'Santa Maria Coatepec',
      'Coatecas Altas',
      'Coapilla',
      'San Juan Coajomulco',
      'Coacuilco',
      'Coacoyulichán',
      'Coacoyula de Álvarez',
      'Coacoatzintla',
      'Coacotla',
      'Coacalco',
      'Ciudad Victoria',
      'Ciudad Serdán',
      'Ciudad Sahagun',
      'Ciudad Pemex',
      'Ciudad Nezahualcoyotl',
      'Ciudad Miguel Alemán',
      'Ciudad Mendoza',
      'Ciudad Madero',
      'Ciudad Hidalgo',
      'Ciudad Fernández',
      'Mexico City',
      'Ciudad del Maíz',
      'Ciudad del Carmen',
      'Ciudad Camargo',
      'Citlaltépec',
      'Citilcum',
      'Cintalapa de Figueroa',
      'Cintalapa',
      'Cieneguilla',
      'Ciénega de Zimatlán',
      'Chunhuhub',
      'Chunchucmil',
      'Chunchintok',
      'Chumayel',
      'Chumatlán',
      'Chuburná',
      'Chontla',
      'Chontalpa',
      'Chontalcoatlán',
      'San Pedro Chochula',
      'Cholula',
      'Cholul',
      'Cholul Cantón',
      'Chocholá',
      'Chocamán',
      'Chiquihuitlán de Benito Juárez',
      'Chinantla',
      'Chinampa de Gorostiza',
      'Chinameca',
      'China',
      'Chiná',
      'Santiago Camotlán',
      'San Pedro Chimay',
      'San Francisco Chimalpa',
      'Chiltoyac',
      'Chilpancingo',
      'Chilón',
      'Chilcuautla',
      'Chilapa',
      'Chilapa de Álvarez',
      'Chila de la Sal',
      'Chilacachapa',
      'Chila de Juárez',
      'Chikindzonot',
      'Chinautla',
      'Chignahuapan',
      'Chigmecatitlán',
      'Chietla',
      'Chicxulub Puerto',
      'Chicxulub Pueblo',
      'Chicuasen',
      'Chicualoque',
      'Chicontla',
      'Chicontepec',
      'Chiconquiaco',
      'Chiconcuautla',
      'Chiconcuac',
      'Chiconamel',
      'Chicomuselo',
      'Chicoloapan',
      'Chicola',
      'Chicoasén',
      'Chichiquila',
      'Chichimila',
      'Chichihualco',
      'Chichén-Itzá',
      'Chichatla',
      'Las Chicharras',
      'Chicbul',
      'Chicapa de Castro',
      'San Andrés Chicahuaxtla',
      'Chicavasco',
      'Chiautla de Tapia',
      'Chiautla',
      'Chiaucingo',
      'Chiapilla',
      'Chiapa de Corzo',
      'Chetumal',
      'Chenalhó',
      'Chemax',
      'Chelem',
      'San Agustín Chayuco',
      'Chavarrillo',
      'Chavarría',
      'Chaucingo',
      'Chapultenango',
      'Chapulhuacanito',
      'Chapulhuacán',
      'Chapopote Chico',
      'Chapopote Núñez',
      'Chapantongo',
      'Chapa de Mota',
      'Chapab',
      'Chan Cenote',
      'Chanal',
      'Chamulapita',
      'Chamula',
      'Champotón',
      'Chalmita',
      'Chalma',
      'Chalco',
      'Chalcatzingo',
      'Villa Chalcatongo de Hidalgo',
      'Chahuites',
      'Chahuite',
      'Chacsinkin',
      'Chachahuantla',
      'Chacaltianguis',
      'Chacalapa',
      'San José Chacalapa',
      'El Chacal',
      'Chablekal',
      'Chablé',
      'Cerro Gordo',
      'Cerro Azul',
      'Cerritos',
      'Ciudad Cerralvo',
      'San Miguel Cerezo (El Cerezo)',
      'Cepeda',
      'Cenotillo',
      'Celestún',
      'Cazones de Herrera',
      'Caxhuacán',
      'Los Cavazos',
      'Caucel',
      'Catemaco',
      'Catazajá',
      'Castillo de Teayo',
      'Casitas',
      'Casas',
      'Casahuatlán',
      'El Carrizo',
      'Carrizal',
      'Carrillo Puerto',
      'Carpinteros',
      'Carlos A. Carrillo',
      'Caristay',
      'Cárdenas',
      'Capulhuac de Mirafuentes',
      'Capula',
      'Caobas',
      'Cantamayec',
      'Cansahcab',
      'Candelaria Loxicha',
      'Cancún',
      'Cancuc',
      'Kancabchén',
      'Canalejas',
      'Campeche',
      'Camotepec',
      'Camocuautla',
      'Camargo',
      'Caltimacan',
      'San Martín Caltenco',
      'Calpulalpan',
      'Calotmul',
      'Calnali',
      'Calmeca',
      'Calkiní',
      'Calixtlahuaca',
      'Calimaya',
      'Calihualá',
      'Calderitas',
      'Calcehtok',
      'Calcahualco',
      'Cahuatache',
      'Cadereyta Jiménez',
      'Cadereyta',
      'Cochoapa',
      'Cacaté',
      'San Lorenzo Cacaotepec',
      'Cacao',
      'Santiago Cacaloxtepec',
      'Cacalotenango',
      'Cacaloxúchitl',
      'Cacalomacan',
      'Cacalchen',
      'Cacahuatal',
      'Cacahoatán',
      'Cabeza de Toro',
      'Bustamante',
      'Burgos',
      'Buenavista de Cuéllar',
      'Buenavista de Benito Juárez',
      'Buena Vista',
      'Buenavista',
      'Buctzotz',
      'Boyé',
      'San Vicente Boquerón',
      'Bonifacio García',
      'Bondojito',
      'Bolón',
      'Bokoba',
      'Bochil',
      'Miguel Bocanegra',
      'Boca del Rio',
      'Boca del Monte',
      'Blasillo 1ra. Sección (Nicolás Bravo)',
      'Blanca Espuma',
      'Bienvenido',
      'San Melchor Betaza',
      'Berriozábal',
      'Bernal',
      'Beristain',
      'Benito Juárez',
      'Bella Vista',
      'Belem',
      'Belén',
      'Bejucal de Ocampo',
      'Bécal',
      'Barretal',
      'Barrancas',
      'Barra de Cazones',
      'Banderilla',
      'Nuevo Balsas',
      'Balancán',
      'Bajucu',
      'Bajos del Ejido',
      'Bachajón',
      'Bacalar',
      'Bacabchén',
      'Baca',
      'Azumbilla',
      'Azoyú',
      'Azcapotzalco',
      'Ayutla de los Libres',
      'Ayotzintepec',
      'Ayotzinapa',
      'Ayotuxtla',
      'Ayotoxco de Guerrero',
      'Ayometitla',
      'Ayahualulco',
      'Ayapango',
      'Ayapa',
      'Ciudad Ayala',
      'Ayotla',
      'Axutla',
      'San Miguel Axoxuca',
      'Axochío',
      'Axochiapan',
      'Axixintla',
      'Axapusco',
      'Soledad Atzompa',
      'Atzitzintla',
      'Atzalan',
      'San Matías Atzala',
      'Atzala',
      'Atzacoaloya',
      'Atzacan',
      'Atoyatempan',
      'Atoyac',
      'Atotonilco de Tula',
      'Atotonilco el Grande',
      'San Pablo Atotonilco',
      'San Antonio Atotonilco',
      'Atotonilco',
      'Atlzayanca',
      'Atlixtac',
      'Atlixco',
      'Atliaca',
      'Atlequizayan',
      'Atlautla',
      'Atlatongo',
      'Atlatlahucan',
      'San Bartolomé Atlatlahuca',
      'Atlapexco',
      'Atlahuilco',
      'Atlacomulco de Fabela',
      'Atlacholoaya',
      'Atlacahualoya',
      'Ciudad López Mateos',
      'Atizapán',
      'Atitalaquia',
      'Santa María Atexcac',
      'Atengo',
      'Atencingo',
      'Juan N. Méndez',
      'Atenango del Río',
      'Atempan',
      'Atasta',
      'Atalpan',
      'Asunción Tlacolulita',
      'Asunción Ocotlán',
      'Asunción Nochixtlán',
      'Asunción Ixtaltepec',
      'Astapa',
      'Astacinga',
      'La Ascensión',
      'Arroyo Seco',
      'Arroyo Hondo',
      'Arroyo del Potrero',
      'San Antonio Arrazola',
      'Arenal Santa Ana',
      'Arcelia de Rodríguez',
      'Arbolillo',
      'Aramberri',
      'Aquixtla',
      'Aquismón',
      'Aquiles Serdán',
      'Aquila',
      'Apizaco',
      'Apipilulco',
      'Apazapan',
      'Apaxtla de Castrejón',
      'Apaxco de Ocampo',
      'Apatzingán',
      'San Pedro Apatlaco',
      'Apapantilla',
      'Apango',
      'Apan',
      'Antón Lizardo',
      'Antiguo Morelos',
      'Ángel R. Cabada',
      'Jaltenango de la Paz',
      'Anenecuilco',
      'Anáhuac',
      'Anahuac',
      'San Pedro Amuzgos',
      'Amozoc de Mota',
      'Amixtlán',
      'Amilcingo',
      'San Miguel Ameyalco',
      'Ameluca',
      'Amecameca',
      'Santa Maria Amealco',
      'Amayuca',
      'Amatlán',
      'Amatlán de los Reyes',
      'Amatlán de Quetzalcoatl',
      'Amatitlán de Azueta',
      'Amatitlán',
      'Amatenango del Valle',
      'Amatenango de la Frontera',
      'Amatán',
      'Amacuzac',
      'Colonia Álvaro Obregón',
      'Alvarado',
      'Altotonga',
      'Alto Lucero',
      'Altepexi',
      'Altamirano',
      'Altamira',
      'Alpuyeca',
      'Alpoyeca',
      'Alpatláhuac',
      'San Antonio Alpanocan',
      'Villa de Almoloya de Juárez',
      'Almoloya de Alquisiras',
      'Almoloya',
      'Almoloya del Río',
      'Almolonga',
      'San Miguel Almaya',
      'Ignacio Allende',
      'Allende',
      'Aljojuca',
      'Axtla de Terrazas',
      'Alfajayucan',
      'Aldama',
      'Alcozauca de Guerrero',
      'Alaquines',
      'Álamo',
      'Akumal',
      'Akil',
      'Ajalpan',
      'Ahuajutla',
      'Ahuehuetzingo',
      'Ahuehuetitla',
      'Ahuehuepan',
      'Ahuazotepec',
      'Ahuatlán',
      'Ahuatepec de Camino',
      'Lomas de Ahuatepec',
      'Ahuatepec',
      'Ahuatepec Pueblo',
      'Ahuacuotzingo',
      'Ahuacatlán',
      'Ahuacatitlán Cuarto y Quinto Cuartel',
      'Ahuacatán',
      'Agustín de Iturbide',
      'Agua Zarca',
      'Ahuatitla',
      'Ahuateno',
      'Agualeguas',
      'Agua Dulce',
      'Aguacatenango',
      'El Aguacate',
      'Agua Buena',
      'Acultzingo',
      'Aculco de Espinoza',
      'Acula',
      'Acuitlapilco',
      'Acuitlapan',
      'Actopan',
      'Altzayanca',
      'Acteopan',
      'Acoyotla',
      'Acopinalco del Peñón',
      'San Lorenzo Acopilco',
      'Acolman de Netzahualcóyotl',
      'Achotal de Moreno',
      'San Lorenzo Achiotepec',
      'Achichipico',
      'San Jerónimo Acazulco',
      'Acazónica',
      'Santiago Acayutlán',
      'Acayucan',
      'Acayuca',
      'Acaxtlahuacán de Albino Zertuche',
      'Acaxochitlán',
      'Acatzingo',
      'Acatlán de Pérez Figueroa',
      'Acatlán de Osorio',
      'Acatlán',
      'Acatepec',
      'Acatempan',
      'Acapulco de Juárez',
      'Acapetahua',
      'Acanceh',
      'Acamixtla',
      'Acamilpa',
      'Acambay',
      'Acala',
      'Acajete',
      'Acahuizotla',
      'Acahuasco',
      'San Antonio Acahualco',
      'Acacoyagua',
      'San Miguel Abejones',
      'Abasolo del Valle',
      'Abasolo',
      'Abalá',
      'Talismán',
      'San Nicolás Tecomatlán',
      'Ajacuba',
      'La Pahua',
      'Villa Avila Camacho',
      'San Pablo de los Remedios',
      'Miraflores',
      'San Lorenzo Tlalmimilolpan',
      'Concepción Jolalpan',
      'Valente Diaz',
      'San Miguel Tecomatlán',
      'Chiltepec de Hidalgo',
      'Galeana',
      'Jicarero',
      'Higuerón',
      'Cuauhtempan (San Andrés Cuauhtempan)',
      'Tlaltetelco (San Miguel Tlaltetelco)',
      'Huecahuasco',
      'Santiago Tlacotepec',
      'San Miguel Huautla',
      'Santa María Pápalo',
      'Flor Batavia',
      'La Mina',
      'Pueblo Viejo',
      'Bethania',
      'Arroyo Limón',
      'San José Río Manzo',
      'Montenegro la Lana',
      'Santa María Temaxcalapa',
      'Yucuita',
      'Santa María Puxmetacán',
      'Santiago Malacatepec',
      'Magdalena Apasco',
      'Congregación Calles',
      'Atarjea',
      'Los Corazones',
      'Tapanalá',
      'El Barrio de la Soledad',
      'El Bajío',
      'Río Pachiñe',
      'El Chocolate',
      'Santa María Velato',
      'El Arador',
      'Quinicuena',
      'San Juan Colorado',
      'San Juan Jicayán',
      'San Antonio Tepetlapa',
      'Santiago Llano Grande',
      'Santo Domingo Armenta',
      'José María Morelos',
      'Rancho Viejo',
      'Santiago Tetepec',
      'Tataltepec de Valdés',
      'Tepenixtlahuaca',
      'San Cristóbal Honduras',
      'Santo Tomás Tamazulapam',
      'Sitio de Xitlapehua',
      'San Juan Lachao',
      'Santos Reyes Nopala',
      'Cerro del Aire',
      'Santa Lucía Teotepec',
      'San Marcos Zacatepec',
      'La Luz',
      'Bajos de Chila',
      'La Barra de Colotepec',
      'San Francisco Cozoaltepec',
      'Quelové',
      'San Francisco Loxicha',
      'Santo Domingo de Morelos',
      'San José Piedras Negras',
      'San Miguel Figueroa',
      'San Juan Lagunas',
      'Santiago Huaxolotipac',
      'San Pablo Cuatro Venados',
      'San Miguel Mixtepec',
      'Santiago Yosondúa',
      'San Vicente Piñas',
      'Zocoteaca de León',
      'San Juan Cabeza del Río',
      'Santiago Textitlán',
      'Santa María Lachixío',
      'Teojomulco',
      'La Humedad',
      'La Reforma',
      'Camotinchan',
      'Santa Cruz Yucucani',
      'Santa María Yucuhiti',
      'El Jicaral',
      'San Juan Ñumí',
      'Santa María del Rosario',
      'Santiago Tilantongo',
      'San Pedro Coxcaltepec Cántaros',
      'Magdalena Zahuatlán',
      'San Francisco Jaltepetongo',
      'San Francisco Nuxaño',
      'San Mateo Etlatongo',
      'San Juan Sayultepec',
      'San Vicente Nuñu',
      'Santiago Yolomécatl',
      'San Miguel Tlacotepec',
      'Silacayoápam',
      'Ixpantepec Nieves',
      'San Sebastián Nicananduta',
      'Chicahua',
      'Santo Domingo Yodohino',
      'Santa Cruz de Bravo',
      'Ahuehuetitlán',
      'Tamazola',
      'San Jorge Nuchita',
      'Yetla de Juárez',
      'San Agustín Atenango',
      'Santo Domingo Tonalá',
      'San Marcos Arteaga',
      'San Andrés Dinicuiti',
      'Tamazulapam Villa del Progreso',
      'Texcatitlán',
      'Zapotitlán Lagunas',
      'Mariscala de Juárez',
      'San Lorenzo Vista Hermosa',
      'Teotitlán',
      'Chila',
      'Cosoltepec',
      'San Lucas Zoquiápam',
      'San Pedro Ocopetatillo',
      'Santa Cruz Acatepec',
      'Santa Cruz de Juárez',
      'San Andrés Hidalgo',
      'Xochitonalco',
      'La Junta',
      'Tezonapa',
      'Piedra de Amolar',
      'Temascal',
      'Isla Soyaltepec',
      'Tetela',
      'Vicente Camalote',
      'La Tabaquera',
      'Mazín Grande',
      'Papaloapan',
      'Benemérito Juárez',
      'Macín Chico',
      'Paso Canoa',
      'San Pedro Sochiápam',
      'Arroyo de Banco',
      'San Juan Petlapa',
      'Teococuilco de Marcos Pérez',
      'Santo Domingo Roayaga',
      'San Juan Tabaá',
      'San Miguel del Valle',
      'Tamazulapam',
      'Río Chiquito',
      'María Lombardo de Caso',
      'San Felipe Cihualtepec',
      'El Paraíso',
      'El Porvenir',
      'Constitución Mexicana',
      'Los Ángeles',
      'Donaji',
      'Zarzal',
      'Paso Real de Sarabia',
      'Encinal Colorado',
      'San Sebastián Tutla',
      'Rojas de Cuauhtémoc',
      'San Jacinto Amilpas',
      'Guadalupe Etla',
      'Santa Ana Zegache',
      'San Bartolomé Quialana',
      'San Antonino Castillo Velasco',
      'Santa Inés Yatzeche',
      'Santa María Coyotepec',
      'Santa María Jalapa del Marqués',
      'Zacatal',
      'San Lorenzo Albarradas',
      'Soledad Salinas',
      'Santa María Zapotitlán',
      'Santiago Xanica',
      'Merced del Potrero',
      'El Coyul',
      'La Erradura',
      'Santa María Guelacé',
      'San Nicolás Quialana',
      'Valdeflores',
      'San Pedro Martir',
      'San José la Garzona',
      'La Pe',
      'Agua del Espino',
      'Taniche',
      'El Camarón',
      'La Blanca',
      'General Pascual Fentes',
      'San Blas Atempa',
      'Huamúchil',
      'San Francisco del Mar',
      'La Estrella',
      'Cocohital',
      'Transito Tular',
      'Occidente 4ta. Sección',
      'El Tortuguero',
      'Miguel Hidalgo',
      'Nuevo Torno Largo',
      'Puerto Ceiba',
      'Quintín Arauz',
      'Oriente 1ra. Sección',
      'El Recreo',
      'Chichicapa',
      'Tecoluta 2da. Sección',
      'Gobernador Cruz',
      'Jalapita',
      'Villa Vicente Guerrero',
      'Villa la Venta',
      'Palo Mulato',
      'Poblado C-11 José María Morelos y Pavón',
      'Ignacio Gutiérrez Gómez',
      'Libertad',
      'La Lucha',
      'El Tular',
      'Amado Gómez',
      'Mecoacán',
      'Cúlico 2da. Sección',
      'Jalupa',
      'Rancho Nuevo',
      'Huacapa y Amestoy',
      'Gregorio Méndez',
      'Dos Ceibas',
      'Cumuapa 1ra. Sección',
      'Mazateupa',
      'Tucta',
      'Jiménez',
      'Guatacalca',
      'La Ceiba',
      'Medellín y Madero Segunda Sección',
      'Lomitas',
      'Saloya 2da. Sección',
      'El Cedro',
      'Tres Bocas 1ra. Sección',
      'Huapacal 1ra. Sección',
      'Paso de la Mina 3ra. Sección',
      'Tecominoacán',
      'Arroyo Hondo Abejonal',
      'Ocuapan',
      'Dos Montes',
      'La Palma',
      'Río Viejo Primera Sección',
      'Parilla',
      'Pueblo Nuevo de las Raíces',
      'Playas del Rosario',
      'Río de Teapa',
      'Benito González',
      'Aquiles Serdán 1ra. Sección',
      'Monte Grande',
      'La Curva',
      'La Unión',
      'El Congo',
      'Andrés Quintana Roo',
      'Juan Aldama',
      'Vicente Guerrero',
      'Raya Zaragoza',
      'Arena de Hidalgo',
      'San Juan Ahuehueco',
      'Cuazahuatl',
      'Zacualtipanito',
      'Huitzitzilingo',
      'Pueblo Hidalgo',
      'Chatipán',
      'Cuapaxtitla',
      'Tepexititla',
      'Acoapa',
      'Xiquila',
      'Chalahuiyapa',
      'Ohuatipa',
      'Pahactla',
      'Tecacahuaco',
      'Zoquitipán',
      'Tlalchiyahualica',
      'Texcaco',
      'Acoxcatlán',
      'La Esquina',
      'Bomanxotha',
      'Gandhó',
      'El Bingú',
      'Hualula',
      'Barrio Aztlán',
      'Santa Úrsula',
      'San Pablo el Grande',
      'Los Sabinos',
      'Dajiedi',
      'Caxuxi',
      'Patria Nueva',
      'Julián Villagrán',
      'Teofani',
      'Dengantzha',
      'Santa Ana Batha',
      'Taxadho',
      'Bangandhó',
      'El Nith',
      'Dios Padre',
      'El Tephé',
      'La Sabinita',
      'Zothé',
      'Tlaxcalilla',
      'San Bartolo Ozocalpan',
      'Nantzha',
      'Xiteje de Zapata',
      'General Pedro María Anaya',
      'Santa María Batha',
      'San Juan Achichilco',
      'Mangas',
      'Huitel',
      'Presas',
      'La Cruz',
      'Bomintzha',
      'Cardonal',
      'Tlalminulpa',
      'Teltipán de Juárez',
      'Munitepec de Madero',
      'Cañada',
      'Colonia Teñhe',
      'El Jiadi',
      'El Huixmí',
      'Santa María la Calera',
      'Nuevo Centro de Población Agrícola el Chacón',
      'Camelia (Barrio la Camelia)',
      'San José Ocotillos',
      'Río Seco Puente de Doria',
      'El Susto',
      'Pedregal de San José',
      'Santa María Asunción',
      'El Capulín',
      'El Tepeyac',
      'La Boveda',
      'Santa Elena Paliseca',
      'San Lorenzo Sayula',
      'Chimalpa y Tlalayote',
      'Santa Bárbara',
      'Irolo',
      'Los Cides',
      'La Trinidad',
      'Colonia Benito Juárez',
      'Vicente Guerrero (San Javier)',
      'Tepojaco',
      'Santa María Ilucan',
      'Santa Ana Azcapotzaltongo',
      'Tinajas',
      'Atlangatepec',
      'San José Tepeyahualco',
      'San Lorenzo Sóltepec',
      'Francisco Villa',
      'La Soledad',
      'San Felipe Hidalgo',
      'San Simeón Xipetzingo',
      'La Trinidad Tenexyecac',
      'Los Reyes Quiahuixtlan',
      'Muñoz',
      'Topilco de Juárez',
      'San Simón Tlatlahuquitepec',
      'San Matías Tepetomatitlán',
      'San Benito Xaltocan',
      'Zimatepec',
      'San José Tetel',
      'San Luis Apizaquito',
      'San Lucas Tlacochcalco',
      'Jesús Huitznahuac',
      'San Lorenzo Xaltelulco',
      'San José Teacalco',
      'San Juan Quetzalcoapan',
      'San Andrés Ahuashuatepec',
      'San Pedro Tlacotepec',
      'Colonia Venustiano Carranza',
      'El Carmen Xalpatlahuaya',
      'Toluca de Guadalupe',
      'Santa Cruz Pocitos',
      'Concepción Hidalgo',
      'San José Xicohténcatl',
      'Villa de El Carmen Tequexquitla',
      'San Francisco Cuexcontzi',
      'Huamantla',
      'Zitlaltépec',
      'Los Pilares',
      'Colonia Cuauhtémoc',
      'San José Aztatla',
      'San Pedro Xochiteotla',
      'San Pedro Muñoztla',
      'Tetlanohcán',
      'Acxotla del Monte',
      'Xicohtzinco',
      'Concepción Chimalpa',
      'Acuamanala',
      'San Cosme Atlamaxac',
      'La Aurora',
      'La Magdalena Tlaltelulco',
      'Santa María Ixtulco',
      'Tepeyanco',
      'San Jerónimo Zacualpan',
      'San Juan Huactzinco',
      'Zacatelco',
      'Tetlatlahuca',
      'Santa Cruz Aquiahuac',
      'San Bartolomé Tenango',
      'San Damián Texoloc',
      'Tlaxcala',
      'San Hipólito Chimalpa',
      'Totolac',
      'San Jorge Tezoquipan',
      'San Miguel del Milagro',
      'San Miguel Analco',
      'San Miguel Xochitecatitla',
      'San Rafael Tenanyecac',
      'Santa Ana Nopalucan',
      'Santa Cruz el Porvenir',
      'Xocoyucan',
      'Villa Alta',
      'San Mateo Ayecac',
      'Tenancingo',
      'San Isidro Buen Suceso',
      'Guadalupe Texcalac',
      'Rafael Pascacio Gamboa',
      'Nuevo Volcán Chichonal',
      'Estación San Manuel',
      'Juárez',
      'Nuevo Nicapa',
      'Punta Arena',
      'Loma Bonita',
      'Egipto',
      'Agua Blanca Serranía',
      'Río Chancalá',
      'Nuevo Francisco León',
      'Arroyo Granizo',
      'Ubilio García',
      'Lacandón',
      'El Tumbo',
      'Damasco',
      'San Jerónimo Tulijá',
      'El Mango',
      'Amado Nervo',
      'Hidalgo Joshil',
      'Nuevo Limar',
      'El Limar',
      'Nueva Esperanza',
      'Joljá',
      'Nuevo Sitalá',
      'Tzajalá',
      'Aurora Esquipulas',
      'Maravillas',
      'El Jardín',
      'Zacatonal de Juárez',
      'José María Morelos y Pavón',
      'San Andrés Duraznal',
      'Arroyo Grande',
      'Rincón Chamula',
      'La Competencia',
      'Chapallal Grande',
      'Nuevo Xochimilco',
      'San Miguel la Sardina',
      'Adolfo López Mateos',
      'Luis Espinoza',
      'José María Pino Suárez',
      'Pomposo Castellanos',
      'Alfonso Moguel',
      'Hermenegildo Galeana',
      'Ignacio Zaragoza (El Morro)',
      'Las Maravillas',
      'Viva Cárdenas',
      'Pacú',
      'Gabriel Esquinca',
      'Galecio Narcia',
      'Narciso Mendoza',
      'América Libre',
      'Aztlán',
      'Concepción',
      'El Zapotillo',
      'Yaltem',
      'El Copal',
      'Ajilhó',
      'Patosil',
      'Navenchauc',
      'Pasté',
      'La Candelaria',
      'Las Ollas',
      'Belisario Domínguez',
      'Tzontehuitz',
      'Chalam',
      'Tzajalchén',
      'San Juan Cancuc',
      'Los Pozos',
      'La Floresta',
      'Carmen Yalchuch',
      'Guaquitepec',
      'Sibacá',
      'Justo Sierra',
      'El Censo',
      'Agua Azul',
      'Benemérito de las Américas',
      'Frontera Corozal',
      'Tziscao',
      'San Antonio Buenavista',
      'El Edén',
      'El Porvenir Agrarista',
      'Rafael Ramírez',
      'Jalisco',
      'La Patria',
      'El Progreso',
      'Chihuahua',
      'Francisco Sarabia',
      'La Independencia',
      'Cash',
      'Señor del Pozo',
      'Villahermosa Yaluma',
      'Yasha',
      'Lomantán',
      'Zaragoza la Montaña',
      'Efraín A. Gutiérrez',
      'Los Riegos',
      'Chacaljocóm',
      'Ochusjob',
      'El Puerto',
      'San Francisco Pujiltic',
      'Paraíso del Grijalva',
      'Mariano Matamoros',
      'Nuevo León',
      'Presidente Echeverría (Laja Tendida)',
      'Nuevo Vicente Guerrero',
      'Independencia',
      'Diamante de Echeverría',
      'Jericó',
      'Unión Buena Vista',
      'Julián Grajales',
      'El Parral',
      'Dieciséis de Septiembre',
      'Revolución Mexicana',
      'Manuel Ávila Camacho',
      'Roblada Grande',
      'Jesús María Garza',
      'Calzada Larga',
      'Doctor Domingo Chanona',
      'Nuevo México',
      'Libertad Melchor Ocampo',
      'Agrónomos Mexicanos',
      'Tierra y Libertad',
      'Villamorelos',
      'Arriaga',
      'Azteca',
      'Ignacio Ramírez',
      'Huizachal',
      'San Luqueño',
      'Paredón',
      'La Línea',
      'Doctor Belisario Domínguez (La Barra)',
      'Manuel Ávila Camacho (Ponte Duro)',
      'Tamaulipas',
      'El Palmarcito',
      'El Carmen',
      'Las Brisas',
      'Querétaro',
      'Buenos Aires',
      'El Sacrificio',
      'Texcaltic',
      'Pilcaya',
      'Huitzoltepec',
      'San Gaspar',
      'San Andrés Nicolás Bravo',
      'El Terrero',
      'San Alejo',
      'Llano Grande',
      'San José Chalmita',
      'San Pablo Tejalpa',
      'La Finca',
      'Ahuacatitlán',
      'San Miguel Laderas',
      'Totolmajac',
      'Acatzingo (Acatzingo de la Piedra)',
      'El Carmen (El Desierto del Carmen)',
      'Tepalcatepec',
      'Jesús Carranza (Rancho de Jesús)',
      'Santiago Oxtotitlán',
      'Porfirío Díaz',
      'San Agustín',
      'Texcapilla',
      'El Salitre',
      'Chalchihuapan',
      'Tepoxtepec',
      'San Simón el Alto',
      'San Francisco Tetetla',
      'Santa Cruz Pueblo Nuevo (Pueblo Nuevo)',
      'Cerro La Calera',
      'San Gaspar Tlahuelilpan',
      'Santa Cruz Atizapán',
      'Los Saucos',
      'San Juan Tlacotompa (Tlacotompa)',
      'San Juan Tepecoculco',
      'San Vicente Chimalhuacán',
      'Santiago Mamalhuazuca',
      'Tepetlixpa',
      'San Juan Tehuixtitlán',
      'Popo Park',
      'San Pedro Nexapa',
      'San Matías Cuijingo',
      'Santiago Cuautenco',
      'San Antonio Tlaltecahuacán',
      'Zentlalpan',
      'San Juan Coxtocan',
      'San Andrés Metla',
      'Zula',
      'La Candelaria Tlapala',
      'San Mateo Huitzilzingo',
      'San Lucas Amalinalco',
      'Santa María Huexoculco',
      'San Martín Cuautlalpan',
      'San Marcos Huixtoco',
      'Lomas de San Sebastián',
      'San Sebastián Chimalpa',
      'Tequexquináhuac',
      'Xocotlán',
      'San Miguel Tlaixpan',
      'Santa María Tecuanulco',
      'San Jerónimo Amanalco',
      'San Juan Totolapan',
      'San Andrés de las Peras',
      'San Bernardo Tlalmimilolpan',
      'San Pedro Chiautzingo',
      'La Purificación',
      'Tulantongo',
      'Tlaltecahuacán',
      'Tepetitlán',
      'San Cristóbal Nexquipayac',
      'Tequisistlán',
      'Santiago Tepetitlán',
      'San Pedro Tepetitlán',
      'Tepexpan',
      'San Marcos Nepantla',
      'San Bartolo',
      'San Sebastián Xolalpa',
      'San Miguel Atepoxco',
      'Santiago Tolman',
      'Cuautlacingo',
      'San Francisco Mazapa',
      'San Pablo Ixquitlán',
      'Santa María Palapa',
      'San Antonio de las Palmas',
      'Santiago Zacualuca',
      'San Miguel Atlamajac',
      'Santa Ana Tlachiahualpa',
      'San Luis Tecuhautitlán',
      'San Felipe Teotitlán',
      'Santo Domingo Aztacameca',
      'Ixtlahuaca de Cuauhtémoc',
      'Tianguistongo',
      'Celayita',
      'San Lorenzo Nenamicoyan',
      'El Rosal',
      'La Soledad Barrio',
      'Puentecillas',
      'San Juan Daxthi',
      'San Agustín Buenavista',
      'La Comunidad',
      'Conejeras',
      'Ganzda',
      'Denjhi',
      'Boshindó',
      'Bobashi de Guadalupe',
      'San Luis Anáhuac (Toriles)',
      'Santiago Casandeje',
      'Los Arana',
      'San Lorenzo Malacota',
      'Ejido Loma de Malacota',
      'San José del Sitio',
      'Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)',
      'Santiago Yancuitlalpan',
      'Villa Lázaro Cárdenas',
      'Coronel Tito Hernández',
      'Xolotla',
      'Atla',
      'Icxotitla',
      'Tlaxpanaloya',
      'Tlacomulco',
      'Venta Grande',
      'Cuacuila',
      'Quamila',
      'Tlaltepango',
      'Tlaola',
      'Xochicuautla',
      'Nanacatlán',
      'Hueyapán',
      'Atecax',
      'Guadalupe Sarabia',
      'Xonocuautla',
      'Ciudad de Tlatlauquitepec',
      'Xalacapan de Lucke',
      'Tlatempa',
      'Las Lajas',
      'San Juan Cuauhtémoc',
      'San Agustín Atzompa',
      'San Antonio Tlatenco',
      'Santa María Nepopualco',
      'San Andrés Cholula',
      'Tlacamilco',
      'San Sebastián Teteles',
      'San Mateo Soltepec',
      'Libertad Álvaro Obregón',
      'Máximo Serdán',
      'Mazapiltepec',
      'Emilio Portes Gil',
      'Miravalles',
      'El Fuerte de la Unión',
      'Itzoteno',
      'Chichicuautla',
      'González Ortega',
      'Guadalupe Libertad',
      'San Miguel Zoapan',
      'San Miguel Ocotenco',
      'San Francisco Cuautlancingo',
      'Paso Carretas',
      'El Veladero',
      'Cuesta Blanca',
      'San José Cuyachapa',
      'San Antonio Soledad',
      'Chapulco',
      'Alhuaca',
      'Pericotepec',
      'San José Buenavista',
      'San Martín Esperilla',
      'La Purísima',
      'Guadalupe Enríquez',
      'San Bartolomé',
      'San Pedro Temamatla',
      'Actipan de Morelos',
      'Tepazolco',
      'Tula',
      'Dolores Hidalgo',
      'Santa Cruz Ajajalpan',
      'Concepción Cuautla',
      'San Antonio Juárez',
      'Tepetzitzintla',
      'San Miguel Atlapulco',
      'Santa Lucía Cosamaloapan',
      'San Pablo Ahuatempa',
      'San Isidro Huilotepec',
      'Teacalco de Dorantes (San José Teacalco)',
      'Soledad Morelos',
      'San Juan Raboso',
      'Tlapanalá',
      'San Diego el Organal',
      'Santa Cruz Cuautomatitla',
      'Huehuetlan el Chico',
      'Tlancualpican',
      'Teotlalco',
      'Huachinantla',
      'Ciudad de Chiautla de Tapia',
      'Ixcamilpa',
      'Xicotlán',
      'Tehuitzingo',
      'Francisco Ibarra Ramos',
      'Francisco Ignacio Madero',
      'Necoxtla',
      'Axuxco',
      'Telpatlán',
      'Calipan',
      'Jolalpan',
      'Texcalapa de Juárez',
      'Castamay',
      'Bolonchén de Rejón',
      'Suc-Tuc',
      'Villa Madero',
      'Santo Domingo Kesté',
      'Ukúm',
      'Xmabén',
      'San Pablo Pixtún',
      'Colonia Emiliano Zapata',
      'San Antonio Cárdenas',
      'Abelardo L. Rodríguez',
      'Constitución',
      'Xpujil',
      'Zoh-Laguna',
      'Altamira de Zináparo',
      'División del Norte',
      'Haro',
      'Mamantel',
      'El Aguacatal',
      'Benito Juárez Uno',
      'Michapa',
      'San Juan de Dios (Naranjas de Dios)',
      'Zacacoyuca',
      'San Miguel de las Palmas',
      'Tecuexcontitlán (Tecoescontitlán)',
      'Tequicuilco',
      'Maxela',
      'Tulimán',
      'Carrizalillo',
      'Axaxacualco',
      'El Fresno',
      'Coacoyulillo',
      'Las Compuertas',
      'Julián Blanco (Dos Caminos)',
      'Buena Vista de la Salud',
      'Yetla',
      'El Conchero',
      'Valle del Río',
      'Ocotillo',
      'Tres Palos',
      'San Pedro las Playas',
      'El Bejuco',
      'La Estación',
      'Lomas de Chapultepec',
      'Cerro de Piedra',
      'Llano de la Puerta',
      'Campanario',
      'El Salto',
      'Lomas de San Juan',
      'El Cortés',
      'Pochotillo',
      'Las Cruces',
      'Ocotlán',
      'San Francisco Oxtutla',
      'Tecolcuautla',
      'Zotoltitlán',
      'Yetlancingo',
      'Topiltepec',
      'Oztotitlán',
      'Pochutla',
      'El Durazno',
      'Cuauhtenango',
      'Acalco',
      'San Ángel',
      'San Martín',
      'Los Tepetates',
      'Xalpatláhuac',
      'Colombia de Guadalupe',
      'Tlaxcalixtlahuaca',
      'Horcasitas',
      'Cruz Quemada',
      'Buenavista de Allende',
      'Las Ánimas',
      'Los Saucitos',
      'El Pericón',
      'Huamuchapa',
      'El Mesón',
      'La Dicha',
      'Las Vigas',
      'Marquelia',
      'Coachimalco',
      'Tlatzala',
      'Chiepetepec',
      'Tototepec',
      'Xalatzala',
      'Cuyuxtlahuaca',
      'El Tejocote',
      'Xochapa',
      'Paraje Montero (Paraje Montero de Zaragoza)',
      'Zilacayotitlán',
      'Cochoapa el Grande',
      'San Pablo Atzompa',
      'Rancho Viejo (Rancho Nuevo de la Democracia)',
      'Cuanacaxtitlán',
      'Zoyatlán',
      'Juchitán',
      'El Pitahayo',
      'Las Coloradas',
      'Dzonot Carretero',
      'Ekmul',
      'Popolnah',
      'Texan de Palomeque',
      'Holcá',
      'Nacuche',
      'San Francisco Grande',
      'Cuch Holoch',
      'Tixhualactún',
      'Kanxoc',
      'Kancab',
      'Xohuayan',
      'Kinil',
      'Yaxhachen',
      'Becanchén',
      'Chiquilá',
      'San Angel',
      'Kantunilkín',
      'Leona Vicario',
      'Tihosuco',
      'Tepich',
      'Zacalaca',
      'X Cabil',
      'Huay Max',
      'La Presumida',
      'X-Hazil Sur',
      'Presidente Juárez',
      'Los Divorciados',
      'Carlos A. Madrazo',
      'Jose Narciso Rovirosa',
      'La Union',
      'Isla Holbox',
      'El Escobillo',
      'Los Pescados',
      'El Conejo',
      'Tronconal',
      'Mesa de Guadalupe',
      'El Paraíso (La Charca)',
      'Playa de Chachalacas',
      'Cabezas',
      'El Hatito',
      'Palo Gacho',
      'Plan del Río',
      'Bella Esperanza',
      'Alborada',
      'Estanzuela',
      'El Chico',
      'El Lencero',
      'Pacho Nuevo',
      'Tlaltetela',
      'Tlapala',
      'Colonia Úrsulo Galván',
      'Tonalaco',
      'Ixhuacán de los Reyes',
      'Apantéopan',
      'Xocotepéc',
      'Tlalconteno',
      'Cuiyachapa',
      'Tenixtepec',
      'Excola',
      'Tetlaxco',
      'Ixcatla',
      'San José Tenejapa',
      'Chavaxtla',
      'Mata Obscura',
      'Coyolito',
      'Mata de Indio',
      'Sonora',
      'San Francisco Nacaxtle',
      'Rincón de Barrabás',
      'Cerro Guzmán',
      'Loma de los Carmona',
      'Puente Jula',
      'Vargas',
      'Rancho del Padre',
      'Las Amapolas',
      'Ejidal',
      'La Capilla',
      'Loma Angosta',
      'Mata Naranjo',
      'General Miguel Alemán',
      'Ex-hacienda la Concepción',
      'Manuel León',
      'Peñuela',
      'San Rafael Río Seco',
      'Paraje Nuevo',
      'Trapiche Viejo',
      'Rincón de Buena Vista',
      'San José de Tapia',
      'Villa Unión',
      'Monte Salas',
      'Zapoapan',
      'Campo Grande',
      'Campo Chico',
      'Moyoapan',
      'Tlilapan',
      'La Cuesta',
      'San Isidro el Berro',
      'San José Súchil',
      'Cecilio Terán',
      'Morelos',
      'Ixhuapan',
      'Comején',
      'José F. Gutiérrez',
      'Calzadas',
      'Mundo Nuevo',
      'Guillermo Prieto',
      'Tlacuilolapan',
      'Las Lomas de Tacamichapan',
      'Colonia Lealtad',
      'Congregación Hidalgo',
      'Dehesa',
      'El Tulín',
      'Antonio J Bermúdez',
      'El Pueblito',
      'Cucharas',
      'San Pedro Coyutla',
      'Estero de Milpas',
      'Tetzacual',
      'Tenantitla',
      'La Camelia',
      'Estero del Ídolo',
      'Tincontlán',
      'Mequetla',
      'Doctor Montes de Oca',
      'Zacate Colorado',
      'Acontitla',
      'La Victoria (La Peñita)',
      'Puente de Piedra',
      'Adolfo Ruíz Cortines',
      'Vicente Herrera',
      'Polutla',
      'Hueytepec',
      'María de la Torre',
      'Manantiales',
      'Paso de Valencia',
      'El Panorama',
      'Macedonio Alonso',
      'Coahuitlán',
      'Colonia Libertad',
      'Ahueyahualco',
      'San Miguel Tlalpoalán',
      'Pahua Hueca',
      'Salvador Díaz Mirón',
      'San Marcos Atesquilapan',
      'Monte Verde Chivería',
      'La Sombra',
      'El Huérfano',
      'Palma Sola',
      'Manuel María Contreras',
      'Tapalapan',
      'Chonegal',
      'Zamora',
      'Rodríguez Tejeda',
      'San Agustín del Palmar',
      'Caxapa',
      'Ixtacapa el Chico',
      'Palmar Grande',
      'Presidio (Plan de Libres)',
      'El Potrero',
      'Nuevo San José Independencia',
      'Poblado Tres',
      'Poblado Dos',
      'Novara',
      'Gabino Barreda',
      'Las Sabanetas',
      'Lindavista',
      'Isla',
      'Santa Rosa Abata',
      'Sihuapan',
      'Calería',
      'Xoteapan',
      'Ocelota',
      'Juan Jacobo Torres [Bodega de Totontepec]',
      'Tilapan',
      'Juan Díaz Covarrubias',
      'El Salto de Eyipantla',
      'Chuniapan de Arriba',
      'San Juan Seco de Valencia',
      'Laguneta',
      'Nuevo San Martín',
      'Nuevo Ixcatlán',
      'Estación Juanita',
      'Huayacanes',
      'La Cerquilla',
      'Aguilera',
      'Venustiano Carranza (Peña Blanca)',
      'San Lorenzo Tenochtitlán',
      'La Horqueta (Poblado Doce)',
      'Helio García Alfaro',
      'La Chinantla',
      'Niños Héroes',
      'Poblado Cinco',
      'Hermanos Cedillo',
      'Agua Fría',
      'San Martín Florida',
      'Santa Rosa de Lima',
      'San José de la Laja',
      'Bordo Blanco',
      'Boxasní',
      'Villa Progreso',
      'Visthá',
      'La Llave',
      'Santa Cruz Escandón',
      'Paso de Mata',
      'Yosphí',
      'San Andrés de los Gama',
      'Turcio Segunda Sección',
      'Tejalpa',
      'San Pedro Tejalpa',
      'Cañada de Guadarrama',
      'San Bartolo el Viejo',
      'San Mateo Tlalchichilpan',
      'San Pedro de la Hortaliza (Ejido Almoloyán)',
      'San Nicolás Amealco',
      'Jesús María',
      'Las Peñas',
      'San Marcos de la Loma',
      'La Campanilla',
      'San Joaquín del Monte',
      'Santa Juana Centro',
      'Santa Juana Primera Sección',
      'Mayorazgo de León',
      'San Agustín Citlali',
      'El Rincón de los Perales',
      'Guadalupe Cachi',
      'Emiliano Zapata (Santo Domingo)',
      'San Francisco de Guzmán',
      'Fresno Nichi',
      'Mina Vieja',
      'La Guadalupana',
      'Palos Amarillos (Palos Amarillos Yebuciví)',
      'Cuadrilla Vieja',
      'Barrio de San Isidro',
      'San Antonio de las Huertas',
      'San Juan Cote Ejido',
      'San Jerónimo Bonchete',
      'Santa Cruz Mextepec',
      'Ejido del Tunal Nenaxi',
      'Calvario Buenavista',
      'San Lucas Ocotepec',
      'El Carmen Ocotepec',
      'San Juan Jalpa Centro',
      'San Agustín Mextepec',
      'Santa Ana la Ladera',
      'San Isidro Boxipe',
      'Santa María Endare',
      'Mavoro',
      'San Juan de las Manzanas',
      'La Concepción Enyege',
      'Santiago Citendejé',
      'Cuendo',
      'San Francisco Tepeolulco',
      'Manto del Río Ejido',
      'San Lorenzo Tlacotepec',
      'San Juan de los Jarros',
      'San Luis Boro',
      'Tecoac (Santa María Nativitas)',
      'San Bartolo Lanzados',
      'San José del Tunal',
      'El Rincón de la Candelaria',
      'San Francisco Chalchihuapan',
      'San Antonio Enchisi',
      'San Pedro del Rosal',
      'San Felipe Pueblo Nuevo',
      'Doxtejé Centro',
      'Tixmadeje Chiquito',
      'Detiña (San Antonio Detiña)',
      'San Juanico Sector Uno',
      'La Caridad',
      'San Mateo el Viejo',
      'San Jerónimo de los Jarros',
      'Chosto de los Jarros',
      'Santa Cruz Bombatevi',
      'Tercera Manzana de Zaragoza',
      'Agua Escondida',
      'Las Huertas',
      'Xhimojay',
      'El Magueyal',
      'Las Manzanas',
      'Coscomate del Progreso',
      'El Palmito',
      'Dongu',
      'Tenjay',
      'San Bartolo Morelos',
      'Jalpa de los Baños',
      'San Marcos Tlazalpan',
      'San José Boqui',
      'Huemetla',
      'Barrio de Boyecha',
      'San Sebastián Buenos Aires',
      'Santa Clara de Juárez',
      'San Antonio Nixini',
      'Rancho Alegre',
      'Santa María del Llano',
      'Tlaltenanguito',
      'Barrio de San Pedro la Cabecera',
      'San Miguel Yuxtepec',
      'San José las Lomas',
      'San Antonio Bonixi',
      'Enthavi',
      'Pothé',
      'Calle Real',
      'San José Comalco',
      'Tlachaloya',
      'San Diego Alcalá',
      'San Pedro Abajo',
      'San Mateo Capulhuac',
      'La Concepción de Hidalgo',
      'La Huanica',
      'San Agustín Mimbres',
      'San José Buenavista el Grande',
      'San Mateo Mozoquilpan',
      'Ejido de Dolores',
      'San José Pathé',
      'San Antonio del Puente',
      'Mina México',
      'San Andrés Cuexcontitlán',
      'La Cabecera',
      'San Martín Toltepec',
      'San Marcos Yachihuacaltepec',
      'Santiaguito Tlalcilalcalli',
      'San Mateo Otzacatipan',
      'San Pedro Totoltepec',
      'Santa María Totoltepec',
      'El Cerrillo Vista Hermosa',
      'San Felipe Tlalmimilolpan',
      'San Francisco Cuaxusco',
      'San Miguel Totocuitlapilco',
      'San Pedro Tultepec',
      'San Bartolomé Tlaltelulco',
      'San Juan Tilapa',
      'San Pedro Tlaltizapan',
      'San Marcos de la Cruz',
      'San Bartolito Tlaltelolco',
      'San Andrés Ocotlán',
      'San Francisco Putla',
      'San José',
      'San Diego',
      'San Gabriel Zepayautla',
      'San Pedro Zictepec',
      'San Juan Xochiaca',
      'Maxtleca de Galeana',
      'Santa María Jajalpa',
      'San Juan la Isla',
      'San Bartolo del Progreso',
      'Tlacuitlapa',
      'San Lorenzo Huehuetitlán',
      'Santiago Tílapa',
      'San Felipe el Mirasol',
      'San Nicolás Tlazala',
      'San Pedro Atlapulco',
      'Tres Marías',
      'San Pedro Cholula',
      'San José el Llanito',
      'San Mateo Atarasquíllo',
      'Santiago Analco',
      'San Pedro Atocpan',
      'San Lorenzo Tlacoyucan',
      'San Salvador Cuauhtenco',
      'Benito Juarez',
      'Huixquilucan',
      'San Juan Yautepec',
      'Zacamulpa',
      'San Bartolomé Coatepec',
      'San Nicolás Peralta',
      'Santiago Tejocotillos',
      'San Agustín Huitzizilapan',
      'Santa María Tlalmimilolpan',
      'Zacamulpa Tlalmimilolpan',
      'Santa Cruz Huitzizilapan',
      'San Francisco Xochicuautla',
      'San Miguel Tecpan',
      'Loma del Río',
      'San Miguel Hila',
      'Caja de Agua',
      'San José el Vidrio',
      'San Francisco Magú',
      'Cañada de Cisneros',
      'El Puerto Magú',
      'Loma Alta',
      'Llano de Zacapexco',
      'El Ocotal',
      'La Cruz y Carrizal',
      'San Luis Taxhimay',
      'San Pablo Huantepec',
      'San Ignacio Nopala',
      'Santiago Tlapanaloya',
      'Cantera de Villagrán',
      'Cañada de Madero',
      'Santa María Magdalena',
      'San Miguel Vindho',
      'Vito',
      'Pérez de Galeana',
      'Unidad San Miguel Jagüeyes',
      'Emiliano Zapata (San José Bata)',
      'San Pedro Xalpa',
      'San Juan Pueblo Nuevo',
      'Pueblo Nuevo de Morelos',
      'San Bartolo Tlaxihuicalco',
      'Huilango',
      'Santiago Teyahualco',
      'Tonanitla',
      'Santo Tomás Chiconautla',
      'El Saneal',
      'Bacame Nuevo',
      'San Ignacio Río Muerto',
      'Francisco Javier Mina',
      'Rosario',
      'Vicam',
      'Mi Patria es Primero',
      'Antonio Rosales',
      'Santa María de Guaymas',
      'Miguel Alemán (La Doce)',
      'El Diamante (La Retranca)',
      'Tenayuca',
      'Trinidad García de la Cadena',
      'Florencia',
      'Tlachichila',
      'Santa María de la Paz',
      'Cicacalco',
      'El Plateado de Joaquín Amaro',
      'Tayahua',
      'San José de Castellanos',
      'El Sitio',
      'Jaula de Abajo',
      'El Obraje',
      'El Nigromante',
      'La Pendencia',
      'Estancia de Guadalupe',
      'Pedregoso',
      'Colonia José María Morelos',
      'La Ballena',
      'Granadas',
      'Crisóstomos',
      'Santa María de los Ángeles',
      'San Blas',
      'Piedra Gorda',
      'Genaro Codina',
      'Paso de Méndez',
      'Bajío de San Nicolás',
      'General Pánfilo Natera',
      'Pozo de Jarillas',
      'Cerrito de la Cruz',
      'La Zacatecana',
      'Colonia Felipe Ángeles',
      'Hacienda Nueva',
      'Ermita de Guadalupe',
      'El Lampotal',
      'Casa de Cerros',
      'Chaparrosa',
      'Chupaderos',
      'General Enrique Estrada',
      'Las Catarinas',
      'Chichimequillas',
      'Carrillo',
      'Río Florido',
      'Seis de Enero',
      'Sain Bajo',
      'Cañas',
      'Emilio Carranza',
      'Colonia Veinte de Noviembre',
      'Sánchez',
      'Enramadas',
      'Pardo',
      'Rodrigo',
      'Alberto Carrera Torres',
      'Villa Juárez',
      'El Jaralito',
      'Enrique Estrada',
      'La Mesa de los Conejos',
      'Suspiro Picacho',
      'Monte Obscuro',
      'Palmar Primero',
      'Palma Pegada',
      'Los Zacatones',
      'Dulce Grande',
      'Praxédis Guerrero',
      'Mesa del Huracán',
      'La Constitución',
      'Julimes',
      'José Esteban Coronado',
      'Ejido Benito Juárez',
      'Ojo de Agua de Palmillas',
      'Cristo Rey',
      'Tecualilla',
      'Isla del Bosque',
      'El Huajote',
      'Escamillas',
      'Concordia',
      'Piaxtla de Abajo',
      'El Saladito',
      'Tanques',
      'San Francisco de Tacuichamona',
      'Portaceli',
      'Eldorado',
      'Las Aguamitas',
      'Lo de Jesús (Campo Romero)',
      'El Limón de los Ramos',
      'Dautillos',
      'Gustavo Díaz Ordaz',
      'Leyva Solano',
      'Ranchito de Castro',
      'La Presita',
      'Adolfo Ruiz Cortines',
      'El Huitusi',
      'Cubiri de Portelas',
      'Tres Garantías',
      'Ejido Cinco de Mayo',
      'Carricitos',
      'Benito Juárez (Vinatería)',
      'Chihuahuita',
      'Cihuatlán',
      'Dieciocho de Marzo',
      'San José de los Reynoso',
      'Santa María Transpontina',
      'Paso de Cuarenta',
      'Vaquerías',
      'La Paz',
      'Los Azulitos',
      'Mesón de los Sauces',
      'El Tecuán',
      'Bajío de San José',
      'Betulia',
      'Huachichil',
      'Ventana',
      'Mayran',
      'San Esteban de Abajo',
      'Santa Fé',
      'El Cambio',
      'Jaboncillo',
      'La Florida',
      'La Fe',
      'Ocho de Enero',
      'Lamadrid',
      'La Esmeralda',
      'Minas de Barroterán',
      'Villa Montemorelos',
      'General Lázaro Cárdenas',
      'Jauja',
      'Seis de Octubre',
      'Camalú',
      'Licenciado Gustavo Díaz Ordaz',
      'Francisco Zarco',
      'Primo Tapia',
      'Santa Isabel',
      'Estación Coahuila',
      'Doctor Alberto Oviedo Mota',
      'Estación Pescaderos',
      'Delta',
      'Ejido Sinaloa',
      'Islas Agrarias Grupo B',
      'Islas Agrarias Grupo A',
      'San Martín de Zula',
      'Zoquite',
      'Nuevo Zirosto',
      'Tziritzícuaro',
      'Zirándaro de los Chávez',
      'Zirahuén',
      'Zirahuato de los Bernal',
      'Zipiajo',
      'Zinapécuaro',
      'Zináparo',
      'Ixtapa-Zihuatanejo',
      'Sauta',
      'Villa Zaragoza',
      'Zapotlanejo',
      'Zapotlán del Rey',
      'Zapotitlán de Vadillo',
      'Zapotitán de Hidalgo',
      'Zapotiltic',
      'El Zapote',
      'Zapotanito',
      'Zapotán',
      'Zapopan',
      'Zamorano',
      'Zacoalco de Torres',
      'Zacatula',
      'Zacatecas',
      'Zacapú',
      'Yustis',
      'Yuriria',
      'Yurécuaro',
      'Yerbabuena',
      'Yécora',
      'Yavaros',
      'Yahualica de González Gallo',
      'Yago',
      'Vista Hermosa de Negrete',
      'Virginias',
      'Villa Union',
      'Villa Pesqueira',
      'Villa Ocampo',
      'Cañadas de Obregón',
      'Villa Morelos',
      'Villamar',
      'López',
      'Ciudad de Villaldama',
      'Villa González Ortega',
      'Villa García',
      'Escobedo',
      'Santa Clara del Cobre',
      'Villa de Reyes',
      'Villa de Guadalupe',
      'García',
      'Villa de García Márquez',
      'Villa de Cos',
      'Ciudad de Villa de Álvarez',
      'Coronado',
      'Villa Corona',
      'Villachuato',
      'San José Villa de Allende',
      'Miguel Ahumada',
      'El Vegil',
      'Viesca',
      'Viborillas',
      'Vetagrande',
      'Ejido Venustiano Carranza',
      'Venecia',
      'Venado',
      'Velardeña',
      'Vanegas',
      'Valtierrilla',
      'Valparaíso',
      'Valle de Zaragoza',
      'Valle de Santiago',
      'Valle del Rosario',
      'Valle de Juárez',
      'Valle de Guadalupe',
      'Valle de Bravo',
      'Valle de Banderas',
      'Valle de Allende',
      'Valentín Gómez Farías',
      'Valencia de Cerro Gordo',
      'El Vado de San Pedro',
      'Uzeta',
      'Uspero',
      'Usmajac',
      'Uruapan',
      'Uruachi',
      'Urique',
      'Uricho',
      'Uriangato',
      'Ures',
      'Urén',
      'Unión de Tula',
      'Unión de San Antonio',
      'Ucareo',
      'Tzitzio',
      'Tzintzuntzán',
      'Tzintzingareo',
      'Tuzantla',
      'Tuxpan de Bolaños',
      'Tuxcueca',
      'Tuxcacuesco',
      'Turícuaro',
      'Turicato',
      'Tupataro',
      'Tupátaro',
      'Tumbiscatío de Ruiz',
      'Tubutama',
      'Tres Mezquites',
      'Trejos',
      'Trapiche de Abra',
      'Trancoso',
      'Tototlán',
      'San Martín Totolán',
      'Totatiche',
      'Ejido Tosalibampo',
      'Torreón',
      'San Salvador Torrecillas',
      'Topolobampo',
      'Topia',
      'Tonila',
      'Tonaya',
      'Tomochic',
      'Tomendán',
      'Todos Santos',
      'Tocumbo',
      'Tlazazalca',
      'Tlatlaya',
      'Tlaquepaque',
      'Tlapehuala',
      'Tlalpujahua de Rayón',
      'Tlalchapa',
      'Tlajomulco de Zúñiga',
      'Tlahualilo de Zaragoza',
      'Tlacuitapan',
      'Tlacote el Bajo',
      'Tizapán el Alto',
      'Tixtlancingo',
      'Tirípetio',
      'Tiríndaro',
      'Tiquicheo',
      'Tingüindín',
      'Tingambato',
      'Tijuana',
      'Tierra Fría',
      'Teúl de González Ortega',
      'Teuchitlán',
      'Tetitlán',
      'La Tesorera',
      'Tesistán',
      'Colonia Terrazas',
      'Terminal de Providencia',
      'Teremendo',
      'Tequesquitlán',
      'Tequepexpan',
      'Tepusco',
      'Tepic',
      'Tepezalá',
      'Tepetongo',
      'Tepetixtla',
      'El Tepetate',
      'Tepechitlán',
      'Tepec',
      'Tepatitlán de Morelos',
      'Tepache',
      'Teocuitatlán de Corona',
      'Teocaltiche',
      'Tengüecho',
      'Tenexpa',
      'Tenería del Santuario',
      'Tenamaxtlán',
      'Temósachic',
      'Témoris',
      'Temascaltepec de González',
      'Telcruz',
      'Tejupilco de Hidalgo',
      'Tejocote de Calera',
      'Tecualtitán',
      'Tecuala',
      'Técpan de Galeana',
      'Tecomates',
      'Tecomatán',
      'Tecomán',
      'Tecolots',
      'Tecolotlán',
      'Techaluta de Montenegro',
      'Tecate',
      'Tecario',
      'Tecalitlán',
      'Teacapan',
      'Tazumbo',
      'Tayoltita',
      'Tarimoro',
      'Campestre Tarímbaro',
      'San Antonio Tariácuri',
      'Taretán',
      'Tarejero',
      'Tarécuato',
      'Tarandacuao',
      'Tapeixtles',
      'Tapalpa',
      'Tanhuato de Guerrero',
      'Tanganhuato',
      'Tangancícuaro de Arista',
      'Tancítaro',
      'Tanaquillo',
      'Tanaco',
      'Guadalupe de Tambula',
      'Tamazulita',
      'Tamazula de Gordiano',
      'Tamazula',
      'Tamazula de Victoria',
      'Tamándaro',
      'Talpa de Allende',
      'Tala',
      'Tacuro (Santa María Tacuro)',
      'Tacoaleche',
      'Tacicuaro',
      'Tacátzcuaro',
      'Tacámbaro de Codallos',
      'Tabasco',
      'Susupuato de Guerrero',
      'Susticacán',
      'Suchitlán',
      'Súchil',
      'Suaqui Grande',
      'Soyopa',
      'Soyatlán del Oro',
      'Sotelo',
      'Sonoita',
      'Sombrerete',
      'Solima',
      'Soledad Nueva',
      'Soledad de Graciano Sánchez',
      'Socavón (El Carmen)',
      'Sisoguichi',
      'Siqueros',
      'Sinaloa de Leyva',
      'Sinahuiza',
      'Silao',
      'Sierra Mojada',
      'Zicuicho',
      'Sevina',
      'Serrano',
      'Sentispac',
      'Senguio',
      'Sayulita',
      'Sayulilla',
      'Sayula',
      'Sauz de Calera',
      'Saucillo',
      'Ejido el Saucillo',
      'Sauceda de La Borda',
      'Sáric',
      'Sapioris',
      'San Vicente Ferrer',
      'San Vicente el Alto',
      'Santo Tomás de los Plátanos',
      'Santo Tomás Huatzindeo',
      'Santo Tomás',
      'Santo Niño Aguanaval',
      'Ejido la Pitahaya (Santo Domingo)',
      'Santiaguito (Santiaguito de Velázquez)',
      'Santiaguito',
      'Santiago Undameo',
      'Santiago Tangamandapio',
      'Santiago Papasquiaro',
      'Santiago Oxtempan',
      'Santiago Maravatío',
      'Santiago Ixcuintla',
      'Santiago de Cuenda',
      'Santiago Bayacora',
      'Santiaguillo',
      'Santa Rosa Jauregui',
      'Santa Rosa los Angeles',
      'Laguna de Santa Rita',
      'José María Morelos (Santa Mónica)',
      'Santa Matilde',
      'Otaéz',
      'Santa María del Valle',
      'Santa María del Río',
      'Santa María del Refugio',
      'Santa María del Oro',
      'Santa María del Buáraje',
      'Santa María de Enmedio',
      'Veinte de Noviembre (Santa Lucía)',
      'José Guadalupe Aguilera (Santa Lucía)',
      'Santa Fe',
      'San Tadeo',
      'Santa Cruz el Grande',
      'Santa Cruz del Valle',
      'Santa Cruz del Astillero',
      'Santa Cruz de la Soledad',
      'Santa Cruz de las Flores',
      'Santa Cruz de Bárcenas',
      'Santa Cruz Luján',
      'Santa Clara de Valladares',
      'Santa Catarina de Tepehuanes',
      'Xichú',
      'Santa Anita',
      'Santa Ana Zirosto',
      'Santa Ana Pacueco',
      'Santa Ana Maya',
      'Oscar Soto Maynez',
      'Santa Ana Amatlán',
      'Santa Ana del Pilar',
      'San Simón de la Laguna',
      'San Simón de Guerrero',
      'San Sebastián el Grande',
      'San Sebastián del Oeste',
      'El Salvador',
      'San Roque de Torres',
      'San Pedro',
      'San Pedro Tenango',
      'San Pedro Piedra Gorda',
      'San Pedro Nuevo',
      'San Pedro Lagunillas',
      'San Pedro Jácuaro',
      'San Pedro Itzicán',
      'San Pedro de los Naranjos',
      'San Pedro del Gallo',
      'San Pedro de la Cueva',
      'Apulco',
      'San Pedro Guasave',
      'San Patricio',
      'San Pablo Pejo',
      'Balleza',
      'San Nicolás Tolentino',
      'San Nicolás Solís',
      'San Nicolás de los Garza',
      'San Nicolás del Cármen',
      'Ibarra',
      'San Nicolás de los Agustinos',
      'San Miguel Zapotitlan',
      'San Miguel Zapotitlán',
      'San Miguel Totolapan',
      'San Miguel Octopan',
      'San Miguelito',
      'San Miguel el Alto',
      'San Miguel de la Paz',
      'San Miguel de Horcasitas',
      'San Miguel de Allende',
      'San Miguel de Cruces',
      'San Miguel Xoltepec',
      'San Martín Hidalgo',
      'San Martin',
      'Lo de Marcos',
      'San Luis Soyatlán',
      'San Luis Río Colorado',
      'San Luis Potosí',
      'San Luis de Lozada',
      'San Luis de Letras',
      'San Luis del Cordero',
      'San Luis de la Paz',
      'San Luis de Agua Caliente',
      'San Luis',
      'San Lucas del Pulque',
      'Cabo San Lucas',
      'San Lucas Evangelista',
      'San Lucas Texcaltitlán',
      'San Juan Tecomatlán',
      'San Juan Pan de Arriba',
      'San Juanito',
      'San Juan de Sabinas',
      'San Juan del Rio del Centauro del Norte',
      'San Juan de los Lagos',
      'San Juan de los Arcos',
      'San Juan de la Vega',
      'San Juan de la Vaquería',
      'San Juan de Guadalupe',
      'San Juan de Abajo',
      'San Juan Cosalá',
      'San Juan Bautista',
      'San Juan Atezcapan',
      'Xoconoxtle el Grande',
      'San José Temascatío',
      'San José Iturbide',
      'San José Itho',
      'San José el Alto',
      'San José de Viñedo',
      'San José de Raíces',
      'San José del Castillo',
      'San José del Cabo',
      'Moras',
      'San José de la Paz',
      'San José de Guaymas',
      'San José de Aura',
      'San José Casas Caídas',
      'San José de Bacum',
      'San José de Zaragoza',
      'San Jose de Lourdes',
      'Estación San José',
      'San José de Agua Azul',
      'San Jose Solís',
      'San Joaquín Zorrillos',
      'San Jerónimo Totoltepec',
      'San Jerónimo de Juárez',
      'San Jerónimo de Araceo',
      'San Jerónimo Purenchecuaro',
      'San Jeronimito',
      'San Isidro Mazatepec',
      'San Ignacio de Hidalgo',
      'San Ignacio',
      'San Ignacio Cerro Gordo',
      'San Gabriel y San Ignacio',
      'Doctor Miguel Silva (San Guillermo)',
      'Pichátaro',
      'San Francisco Peribán',
      'San Francisco Javier',
      'San Francisco del Rincón',
      'San Francisco de los Romo',
      'El Oro',
      'San Francisco de Horizonte (Horizonte)',
      'Conchos',
      'San Francisco de Borja',
      'San Francisco de Arriba',
      'San Francisco de Rivas',
      'Curungueo',
      'San Felipe de Híjar',
      'San Felipe de Jesús',
      'San Felipe Aztatán',
      'San Felipe de los Herreros',
      'San Felipe Santiago',
      'San Elías',
      'San Diego de la Unión',
      'San Diego de Alejandría',
      'San Diego de Alcalá',
      'San Cristóbal Zapotitlán',
      'San Cristóbal de la Barranca',
      'San Clemente',
      'Tlaltenango de Sánchez Román',
      'San Cayetano',
      'Puerto San Carlos',
      'San Bernardo',
      'San Benito',
      'San Bartolo de Berrios',
      'Francisco Serrato (San Bartolo)',
      'San Antonio la Labor',
      'San Antonio Guaracha',
      'San Antonio de Peñuelas',
      'San Antonio de los Vázquez',
      'San Antonio de las Alazanas',
      'San Antonio de Fernández',
      'San Antonio del Cipres',
      'San Antonio de Rivas',
      'San Antonio del Rosario',
      'San Andrés Ixtlán',
      'San Andrés Ziróndaro',
      'Sanalona',
      'San Agustín del Pulque',
      'Nicolás R Casillas',
      'San Agustín del Maíz',
      'San Agustín de las Palmas',
      'Samalayuca',
      'Samachique',
      'Salvatierra',
      'Tepuxtepec',
      'Salitral de Carrera',
      'Salinas Victoria',
      'Salinas de Hidalgo',
      'Zalamea',
      'Salamanca',
      'Sain Alto',
      'Sahuayo de Morelos',
      'Sahuaripa',
      'Sahuaral de Otero',
      'El Sahuaral',
      'Sacramento',
      'Ciudad Sabinas Hidalgo',
      'Sabinas',
      'Ruíz',
      'Rosarito',
      'Rosamorada',
      'Rosales',
      'Roque',
      'Romita',
      'Romero de Torres',
      'Romero de Guzmán',
      'San Antonio de Romerillo',
      'Rodrígo M. Quevedo',
      'Rodeo',
      'Rio de Medina',
      'Río Bravo',
      'Rincón de Tamayo',
      'Rincón de Romos',
      'Rincón de Parangueo',
      'Rincón de López',
      'Rincón de Guayabitos',
      'Recoveco',
      'Rayones',
      'Orranteño',
      'Rancho Nuevo de la Luz',
      'Rancho La Gloria',
      'Lázaro Cárdenas (Rancho Grande)',
      'El Coyote',
      'Ramos Arizpe',
      'Villa de Ramos',
      'Quitupan',
      'Quiroga',
      'Quiriego',
      'Quinceo',
      'Quimichis',
      'Quila',
      'Querobabi',
      'Santiago de Querétaro',
      'Estación Queréndaro',
      'Queréndaro',
      'Quetchehueca',
      'Puruarán',
      'Puruándiro',
      'Puroagua',
      'Purísima de Bustos',
      'Purísima de Covarrubias',
      'Purificación',
      'Purépero de Echáiz',
      'Punta de la Vega',
      'Puga',
      'Puerto Vallarta',
      'Puerto Peñasco',
      'Puerto Palomas',
      'Puerto de Nieto',
      'Puerto de Carroza',
      'Puerta de Mangos',
      'Puente de Camotlán',
      'Pueblo Yaqui',
      'Pueblito de Allende',
      'Puácuaro',
      'La Providencia',
      'Presa del Rosario',
      'Praxedis G. Guerrero',
      'Pozos',
      'Pozos de Gamboa',
      'Pozo Dulce',
      'Poturo',
      'Potrerillos',
      'Potrerillo del Norote',
      'Potam',
      'Portugués de Gálvez',
      'Portes Gil',
      'Poncitlán',
      'Pomacuarán',
      'Santiago de Pochotitán',
      'El Pochotal',
      'Plateros',
      'Plan de Ayala (Campo Cinco)',
      'Plancarte',
      'Pitiquito',
      'Pitahayal',
      'Santa María Pipioltepec (Pipioltepec)',
      'Pinzándaro',
      'Pinos',
      'Piñícuaro',
      'Pihuamo',
      'Piedra Pesada',
      'Piscila',
      'Picardías',
      'Petatlán',
      'Petacalco',
      'Pesquería',
      'Pesqueira',
      'Pericos',
      'Peribán de Ramos',
      'Peotillos',
      'José Guadalupe Rodríguez',
      'Peñuelas',
      'Peñón Blanco',
      'Pénjamo',
      'Penjamillo de Degollado',
      'La Peñita de Jaltomba',
      'Peña Blanca',
      'Pegueros',
      'Pedro Escobedo',
      'Pedriceña',
      'Pedernales',
      'Pátzcuaro',
      'Patuán',
      'Patambó',
      'Pastoría',
      'Pastor Rovaix',
      'Pastora',
      'Pastelera',
      'Paso de Hidalgo (Paso de Álamos)',
      'Pasaje',
      'Parras de la Fuente',
      'Páreo',
      'El Paredoncito',
      'Paredón Colorado',
      'Paredes',
      'Parangarico',
      'Parácuaro',
      'Paracuaro',
      'Paracho de Verduzco',
      'El Paracho',
      'Papanoa',
      'Pánuco de Coronado',
      'Pantanal',
      'Panindícuaro',
      'Pamatácuaro',
      'Palos Verdes',
      'Palos Blancos',
      'Palos Altos',
      'Palo Alto',
      'Palmito del Verde',
      'Palmar Chico',
      'Palau',
      'Pajacuarán',
      'Pabellón de Hidalgo',
      'Pabellón de Arteaga',
      'Otatlán',
      'San Juan de Otates',
      'Ortiz',
      'Orduña de Abajo',
      'Oquitoa',
      'Opopeo',
      'Opodepe',
      'Onavas',
      'Ojuelos de Jalisco',
      'Ojo Seco',
      'Ojo de Rana',
      'Ojo de Agua de Ballesteros',
      'Ojo de Agua de Solano',
      'Ojo de Agua de Mendoza',
      'Ojo Caliente',
      'Ojitos',
      'Ojinaga',
      'Ejido Ohuira',
      'Ocumicho',
      'Oconahua',
      'Obrajuelo',
      'Nuxco',
      'Nurío',
      'Numarán',
      'Nuevo Urecho',
      'Nuevo San Juan Parangaricutiro',
      'Nuevo Ideal',
      'Churumuco de Morelos',
      'Nuevo Casas Grandes',
      'Nueva Rosita',
      'Nueva Italia de Ruiz',
      'Norias del Refugio',
      'Noria de Ángeles',
      'Nonoava',
      'Nombre de Dios',
      'Nocupétaro',
      'Nochistlán de Mejía',
      'El Nio',
      'Nieves',
      'Nicolas Bravo',
      'Neutla',
      'Nextipac',
      'Negritas',
      'Nazas',
      'Nazareno',
      'Navolato',
      'Navojoa',
      'Navarrete',
      'Navajas',
      'Nava',
      'Naranjo',
      'El Naranjillo',
      'Naranja de Tapia',
      'Namiquipa',
      'Naica',
      'Nahuatzén',
      'Nadadores',
      'Nacozari Viejo',
      'Nacozari de García',
      'Nácori Chico',
      'Naco',
      'Mulegé',
      'Moyahua de Estrada',
      'Moroncarit',
      'Moroleón',
      'Moris',
      'Morelia',
      'Morales',
      'Monteón',
      'Monteleón',
      'Monte Escobedo',
      'Monclova',
      'Mompaní',
      'Momax',
      'Mojarras',
      'Moctezuma',
      'Mocorito',
      'Mochicahui',
      'Mixtlán',
      'Misión de Chichimecas',
      'San Isidro Miranda',
      'Mineral de Angangueo',
      'Mina',
      'San Bernardino de Milpillas Chico',
      'Milpillas',
      'Capilla de Milpillas',
      'Milpas Viejas',
      'Milagros',
      'Miguel Auza',
      'Mier y Noriega',
      'Las Mieleras',
      'Mechoacanejo',
      'Mezquitic',
      'Mezquital del Oro',
      'San Francisco del Mezquital',
      'Mezcales',
      'Mexticacán',
      'Mexquitic de Carmona',
      'Méxpan',
      'Mexicali',
      'Mesillas',
      'Mesa Grande',
      'Mesa del Nayar',
      'Merino',
      'Meoqui',
      'Menores',
      'San José de Mendoza',
      'Ciudad Melchor Múzquiz',
      'Medina',
      'Mecatán',
      'Mazapil',
      'Mazamitla',
      'Ejido Mayocoba',
      'Matehuala',
      'Matatlán',
      'Matanzas',
      'Matancillas (San Isidro Matancillas)',
      'Matachí',
      'Masiaca',
      'Mascota',
      'Marroquín',
      'Hacienda de Márquez',
      'Maripa',
      'Marín',
      'Jesús Gómez Portugal',
      'Margaritas',
      'Marfil',
      'Marcol',
      'Maravatío de Ocampo',
      'Mapimí',
      'Manzanillo',
      'Rodolfo Sánchez Taboada',
      'Manalisco',
      'El Maluco',
      'Malpaso',
      'Malagana (San Antonio del Monte)',
      'Maguarichi',
      'Magdalena de Kino',
      'Magdalena de Araceo',
      'Magdalena',
      'Madrid',
      'Madera',
      'Macapule',
      'Luis Moya',
      'Luchanas',
      'Lourdes',
      'Los Tepames',
      'Taxtes',
      'Los Rodríguez',
      'Los Reyes de Salgado',
      'Los Remedios',
      'Los Ramírez',
      'Los Pocitos',
      'Placeres del Oro',
      'Los Otates',
      'Los Nogales',
      'Los Mochis',
      'Los Limones',
      'Los Hucuares',
      'Los Hoyos',
      'Los Guajes',
      'Los Gavilanes',
      'Los Galvan',
      'Los Conos',
      'Los Charcos',
      'Estación Capomas',
      'Bejucos',
      'Los Barriles',
      'Los Arquitos',
      'Los Angeles',
      'Los Algodones',
      'Los Álamos',
      'Los Aguajes',
      'Loreto',
      'Lombardía',
      'Loma Tendida',
      'Lomas de Tejeda',
      'Loma Pelada',
      'Loma de Guamúchil',
      'Loma de Bácum',
      'Loma Blanca',
      'Lo de Lamedo',
      'Lobatos',
      'Lo Arado',
      'León Guzmán',
      'León de los Aldama',
      'La Yesca',
      'La Ventilla',
      'La Venta del Astillero',
      'La Vega',
      'La Unión del Cuatro',
      'Unión de Corrientes',
      'San Nicolás de la Torre',
      'La Tijera',
      'Las Varas',
      'Trincheras',
      'San José Tepuzas',
      'Las Taponas',
      'Las Ranas',
      'Las Quemazones',
      'Las Pintas de Arriba',
      'Puerto Adolfo Lopez Mateos',
      'Las Moras',
      'Las Juntas',
      'Jícamas',
      'Higueras de los Natoches',
      'Las Grullas Margen Derecha',
      'Las Grullas Margen Izquierda',
      'Villa Las Esperanzas',
      'Las Cuevas',
      'General Francisco Villa',
      'Las Cieneguitas',
      'Las Cañas',
      'Las Bocas',
      'La Sauceda',
      'Las Arenitas',
      'La Sardina',
      'Rumorosa',
      'Rosa Blanca',
      'La Ribera',
      'La Rinconoda',
      'La Rivera',
      'Quesería',
      'Ejido La Quemada',
      'La Quemada',
      'Purísima',
      'La Punta',
      'La Primavera',
      'La Presa',
      'La Popular',
      'La Pinta',
      'La Pila',
      'La Piedad',
      'La Paz de Ordaz',
      'Villa de la Paz',
      'La Partida',
      'La Orilla',
      'La Ordeña',
      'La Noria',
      'La Noria de San Antonio',
      'La Negreta',
      'Lampazos de Naranjo',
      'La Monja',
      'La Moncada',
      'La Mira Tumbiscatio',
      'La Manzanilla de la Paz',
      'La Manzanilla',
      'La Loma (La Loma de Quila)',
      'La Lira',
      'La Laborcita',
      'La Labor',
      'Ejido la Joya',
      'La Huerta',
      'La Huacana',
      'La Higuera',
      'Ejido Lagunitas',
      'Laguna Seca',
      'Laguna Larga de Cortés',
      'Laguna de San Vicente',
      'Laguna de Guadalupe',
      'San Antonio de la Laguna',
      'La Griega',
      'Lagos de Moreno',
      'La Goma',
      'La Goleta',
      'La Gavia',
      'La Garita',
      'La Fuente',
      'La Fortuna',
      'La Flor',
      'La Estancia de Amezcua',
      'La Estacada',
      'San José de la Era',
      'La Encarnación',
      'La Cruz de Loreto',
      'Constancia',
      'La Concha',
      'La Colorada',
      'La Ciudad',
      'Ciénega de Nuestra Señora de Guadalupe',
      'La Ciénega',
      'La Central',
      'Ceja de Bravo',
      'La Cantera',
      'La Calle',
      'La Calera',
      'Caimanero',
      'La Brecha',
      'Hacienda de la Bolsa',
      'Barra de Navidad',
      'La Barca',
      'Banderas del Águila',
      'Puente Grande',
      'Ascensión',
      'La Angostura',
      'La Aldea',
      'Kilómetro Noventa y Nueve',
      'Juventino Rosas',
      'Juriquilla',
      'Juraré',
      'Jungapeo de Juárez',
      'Jucutacato',
      'Juchitlán',
      'Juchipila',
      'Ciudad Benito Juárez',
      'Pueblo Juárez (La Magdalena)',
      'Juan Martín',
      'Juan Gil Preciado',
      'Juan Escutia (Borbollón)',
      'Juan E. García',
      'Juanacatlán',
      'La Joya de Calvillo',
      'Jomulco',
      'Jocotepec',
      'J. Jesús Díaz Tzirio',
      'Jiquílpan de Juárez',
      'Jiquilpan',
      'Jiménez del Teul',
      'Jilotlán de los Dolores',
      'Jerez de García Salinas',
      'Jerécuaro',
      'Jauregui',
      'Jaral del Progreso',
      'Jarácuaro',
      'Janos',
      'Jamay',
      'Jamaica',
      'Jaluco',
      'Jalostotitlán',
      'Xalisco',
      'Jalipa',
      'Jalcocotán',
      'Jala',
      'Jacona de Plancarte',
      'El Jabalí',
      'Ixtlán del Río',
      'Ixtlán de los Hervores',
      'Ixtlahuacán del Río',
      'Ixtlahuacán de los Membrillos',
      'Ixtlahuacán',
      'Ixtapan del Oro',
      'Ixtapan de la Concepción',
      'San Juanito Itzícuaro',
      'Janitzio',
      'Irapuato',
      'Irámuco',
      'Indé',
      'Indaparapeo',
      'Imuris',
      'Illescas',
      'Ihuatzio',
      'Pozo de Ibarra',
      'San Pedro de Ibarra',
      'Huitrón',
      'Huiramba',
      'Huimilpan',
      'Huetamo de Núñez',
      'Huepaco',
      'Huépac',
      'Huejuquilla el Alto',
      'Huejúcar',
      'Huejotitán',
      'Huatabampo',
      'Huaxtla',
      'Huáscato',
      'Huásabas',
      'Huanusco',
      'Huaniqueo de Morales',
      'Huanímaro',
      'Huandacareo',
      'Huancito',
      'Huajúmbaro',
      'Huajimic',
      'Huajicori',
      'Huachinera',
      'Hostotipaquillo',
      'Hormiguero',
      'Higueras de Abuya',
      'Higueras',
      'El Higueral',
      'Higuera de Zaragoza',
      'Higuera Blanca',
      'Parral',
      'La Herradura',
      'Heróica Zitácuaro',
      'Heroica Caborca',
      'Hernández',
      'Hermosillo',
      'Hacienda Toribio',
      'Tetillas',
      'Guerrero',
      'Heroica Guaymas',
      'Guatimapé',
      'La Guásima',
      'Guasavito',
      'Guasave',
      'Guanajuato',
      'Guanajuatillo',
      'Guanaceví',
      'Guamúchil',
      'Guadalupe y Calvo',
      'Ciudad Guadalupe Victoria',
      'Guadalupe Septién',
      'Guadalupe de Paso Blanco',
      'Guadalupe de Lerma',
      'Guadalupe de Rivera',
      'Guadalcázar',
      'Guadalajara',
      'Guachochi',
      'Guachinango',
      'Villa Gregorio García',
      'Carretas',
      'Granjenal',
      'Granados',
      'Granada',
      'Gómez Palacio',
      'Ejido Gogorrón (Ex-Hacienda de Gogorrón)',
      'Jitzamuri',
      'Gilita',
      'General Zuazua',
      'General Simón Bolívar',
      'Juan José Ríos',
      'Ciudad General Escobedo',
      'General Cepeda',
      'General Calixto Contreras',
      'General Andrés Figueroa',
      'Guaymitas',
      'San Pedro Garza García',
      'Gargantillo',
      'Galindo',
      'Galeras',
      'Gachupines',
      'Fronteras',
      'Fresnillo',
      'Franco',
      'Francisco Sarabia (Cerrito Pelón)',
      'La Plaza del Limón',
      'Colonia Francisco García Salinas',
      'Flor Azul',
      'Felipe Angeles',
      'Etzatlán',
      'Etúcuaro',
      'Etchoropo',
      'Etchojoa',
      'Estrada',
      'Estipa',
      'La Estancia de San José del Carmen',
      'Estancia de Ánimas',
      'Estación Rosales',
      'Las Varas (Estación Babícora)',
      'Esqueda',
      'Esmeralda',
      'Esfuerzos Unidos',
      'Escuinapa',
      'Escuadrón Doscientos Uno',
      'Escolasticas',
      'Escalerillas',
      'Escaleras',
      'Erongarícuaro',
      'Ensenada',
      'Encarnación de Díaz',
      'Empalme Escobedo',
      'Empalme',
      'San Miguel Eménguaro',
      'El Walamo',
      'El Vicarlo',
      'Ejido El Vergel',
      'El Verde',
      'El Tule',
      'El Tuito',
      'El Trapiche',
      'Transporte',
      'El Tobarito',
      'El Tizate',
      'El Tequesquite',
      'El Tecolote',
      'El Tamarindo',
      'El Sauzal de Rodríguez',
      'El Sauz',
      'El Saúz',
      'El Salero',
      'Salado',
      'El Sabino',
      'El Rucio',
      'El Rosario de Arriba',
      'El Roble',
      'El Retiro',
      'Testerazo',
      'El Rayo',
      'El Ranchito',
      'El Quince',
      'El Quelite',
      'El Puesto',
      'El Potrero de Sataya',
      'Catarino Rodríguez',
      'Porvenir',
      'El Platanal',
      'El Plan',
      'El Pinto',
      'El Perú',
      'El Peñasco',
      'Paxtle',
      'El Papayo',
      'El Oro de Hidalgo',
      'El Nilo',
      'El Nayar',
      'El Naranjito',
      'El Nacimiento',
      'Ignacio Pérez',
      'El Mentidero',
      'Purísima Concepción Mayorazgo',
      'El Lucero',
      'El Loreto Occidental',
      'El Lobo',
      'Estación Llano',
      'El Llanito',
      'El Lequeitio',
      'El Jicote',
      'El Jazmín',
      'El Huizache',
      'El Habillal',
      'El Habal',
      'El Guayabo',
      'El Guaco',
      'El Grullo',
      'Golfo de Santa Clara',
      'El Fuerte',
      'El Fortín',
      'El Dorado',
      'El Cuije',
      'Monte Verde',
      'Coyote',
      'El Consuelo',
      'Campo Pesquero el Colorado',
      'El Colorado',
      'El Colomo',
      'El Coacoyul',
      'El Chayote',
      'El Chante',
      'El Ceñidor',
      'Cayaco',
      'Villa Insurgentes (El Calabazal)',
      'El Cabezón',
      'El Burrión',
      'El Botadero',
      'El Barril',
      'El Alvareño',
      'El Aguajito',
      'El Ahuacate',
      'Ejutla',
      'Ejido Yucatán',
      'Ejido Toluca',
      'Ejido Tabasco',
      'Ejido Sonora',
      'Ejido Saltillo',
      'Ejido Quintana Roo',
      'Ejido Plan de Ayala',
      'Ejido Nuevo León',
      'Mochis',
      'Ejido Michoacán de Ocampo',
      'Ejido Lázaro Cárdenas',
      'Ejido Jiquilpan',
      'Islita',
      'Ciudad Insurgentes',
      'Ejido Vicente Guerrero',
      'Gambino',
      'Ejido El Largo',
      'Durango',
      'Ecuandureo',
      'Victoria de Durango',
      'Duarte',
      'Dos de Abril',
      'Doctor Arroyo',
      'Divisaderos',
      'Diez de Octubre',
      'La Despensa',
      'Derramaderos',
      'Degollado',
      'San Miguel Cuyutlán',
      'Cuxpala',
      'Cutzamala de Pinzón',
      'Cusihuiriachi',
      'Curimeo',
      'Cuquío',
      'Cupareo',
      'Cumuato',
      'Cumpas',
      'Culiacancito',
      'Culiacán',
      'Cuitzeo del Porvenir',
      'Cuitzeo (La Estancia)',
      'San Bartolo Cuitareo',
      'Cuisillos',
      'Cuexcomatitlán',
      'Cuerámaro',
      'Cuencamé de Ceniceros',
      'Cucurpe',
      'Cucuchucho',
      'Palmar de Cuautla',
      'Cuauhtemoc',
      'Cuatro Ciénegas de Carranza',
      'Cuaracurío',
      'Cuanajo',
      'Cuamio',
      'El Crucero de Santa María',
      'Primera Fracción de Crespo',
      'Creel',
      'Coyula',
      'Coyuca de Catalán',
      'Coyuca de Benítez',
      'Coyotitán',
      'Coyame',
      'El Coto',
      'Cotija de la Paz',
      'Villa de Costa Rica',
      'Costa Azul',
      'Cosío',
      'Cosalá',
      'Cortazar',
      'Coroneo',
      'Carona',
      'Corerepe',
      'Corcovado',
      'El Corcovado',
      'Coquimatlán',
      'Copándaro de Galeana',
      'Copándaro (Copándaro del Cuatro)',
      'Contepec',
      'Estación Consuelo',
      'Congregación Ortíz',
      'Coneto de Comonfort',
      'Condémbaro',
      'Estación Conchos',
      'Concepción del Oro',
      'Concepción de Buenos Aires',
      'La Concha (La Concepción)',
      'Compuertas',
      'Compostela',
      'Comonfort',
      'Comanjilla',
      'Comanjá',
      'Comala',
      'Comachuén',
      'Colotlán',
      'Colorines',
      'Colonia Plenitud',
      'Colonia Nicolás Bravo (Kilómetro Noventa y Dos)',
      'Ampliación Colonia Lázaro Cárdenas',
      'Jecopaco',
      'Colonia Abraham González (La Quemada)',
      'Colón',
      'Colima',
      'Cojumatlán de Régules',
      'Ejido Cohuibampo',
      'Cofradía de Suchitlán',
      'Cofradía de Navolato (Cofradía de los Rocha)',
      'Cofradia',
      'Cofradía',
      'Coeneo de la Libertad',
      'Cocucho',
      'Corupo',
      'Cocorit',
      'Cuamiles',
      'Coalcomán de Vázquez Pallares',
      'Augusto Gómez Villanueva',
      'Coahuayutla de Guerrero',
      'Coahuayana Viejo',
      'Cloete',
      'Siviral',
      'Ciudad Obregón',
      'Ciudad Manuel Doblado',
      'Ciudad Lerdo',
      'Ciudad Juárez',
      'José Mariano Jiménez',
      'Ciudad Guzmán',
      'Ciudad Delicias',
      'Ciudad Constitución',
      'Ciudad Altamirano',
      'Ciudad Acuña',
      'Citala',
      'Cinco de Mayo',
      'Cinco de Febrero',
      'Cieneguitas',
      'Cieneguillas',
      'Ciénega Grande',
      'Ciénega de Flores',
      'Churintzio',
      'Chucarit',
      'Chucándiro',
      'Choix',
      'Jitonhueca',
      'Churipitzeo',
      'Chiquimitío',
      'Chiquilistlán',
      'Chinobampo',
      'Chínipas',
      'Chinampas',
      'Chilchota',
      'Cherán',
      'Chavinda',
      'Charo',
      'Charco de Pantoja',
      'Charcas',
      'Charay',
      'Charapendo',
      'Charapán',
      'Chaparaco',
      'Chapalilla',
      'Chapala',
      'Chametla',
      'Chamácuaro',
      'Chalchihuites',
      'Chacala',
      'Ceuta',
      'Cerro Santa Fe',
      'Cerro de San Pedro',
      'Cerro de Ortega',
      'Cerro Agudo',
      'Cerrito de Zavala',
      'Cerocahui',
      'Cerano',
      'Cenobio Moreno',
      'Celaya',
      'Cedros',
      'Los Cedros',
      'Cedral',
      'Ceballos',
      'Castro Urdiales',
      'Castaños',
      'La Resolana',
      'Casas Grandes',
      'Casa Blanca',
      'Carranco',
      'Carmen',
      'Carichí',
      'Carapán',
      'Carácuaro',
      'Capulín',
      'El Capomal',
      'Capilla de Guadalupe',
      'Capácuaro',
      'Capacho',
      'Cantuna',
      'Cantabria',
      'Cañitas de Felipe Pescador',
      'Canelas',
      'Candela',
      'Canatlán',
      'Cananea',
      'Cañada de Ramírez',
      'Cañada de Negros',
      'Cañada de Caracheo',
      'Mora Villalobos',
      'Campo Sesenta',
      'Colonia Michoacana',
      'Campo Carretero',
      'Campo Balbuena',
      'Campo Acosta',
      'Camotlán de Miraflores',
      'Camichín de Jauja',
      'Estación Camacho',
      'Calvillo',
      'Caltzontzín',
      'California',
      'Víctor Rosales',
      'Caleras',
      'Calamanda',
      'Cajititlán',
      'Estación Chimaneco',
      'Cacalutla',
      'Cacalotán',
      'Buenavista Tomatlán',
      'Buenavista de Trujillo',
      'Bucerías',
      'Briseñas de Matamoros',
      'Boquillas',
      'Boquilla de las Perlas',
      'San Martín de Bolaños',
      'Bocoyna',
      'Bocas',
      'La Bocana',
      'Bledos',
      'Bimbaletes Aguascalientes (El Álamo)',
      'Betania',
      'Bermejillo',
      'Benjamín Hill',
      'Bellavista',
      'Bellas Fuentes',
      'Belén del Refugio',
      'Buaysiacobe',
      'Bavispe',
      'Baviácora',
      'Batopilas',
      'Basúchil',
      'Basconcobe',
      'Bacobampo',
      'Basaseachic',
      'Barrón',
      'Barrio Nuevo',
      'Barretos',
      'Bariometo',
      'Gonzales Ortega',
      'Banámichi',
      'Bamoa',
      'Bahuichivo',
      'Bahía Tortugas',
      'Bahía de Kino',
      'Bagojo Colectivo',
      'Badiraguato',
      'Bácum',
      'Bacubirito',
      'Bacorehuis',
      'Bacoachi',
      'Bachomobampo Número Dos',
      'Bachoco',
      'Bachíniva',
      'Bachigualatito',
      'Bacerac',
      'Bacanora',
      'Bacadéhuachi',
      'Bacabachi',
      'Baburia',
      'Baborigame',
      'Santiago Azajo',
      'Ayotitlán',
      'Ayotlán',
      'Autlán de Navarro',
      'Aután',
      'Atoyac de Álvarez',
      'Atotonilquillo',
      'Atotonilco el Bajo',
      'Atotonilco el Alto',
      'Atongo',
      'Atonalisco',
      'Atolinga',
      'Atil',
      'Aticama',
      'Atequiza',
      'Atenguillo',
      'Atemajac de Brizuela',
      'Atapaneo',
      'Atapán',
      'Atacheo de Regalado',
      'Atacco',
      'Asientos',
      'Ascención',
      'Arturo Martínez Adame',
      'Arteaga',
      'Arroyos',
      'Villa de Arriaga',
      'Ciudad de Armería',
      'Armadillo de los Infante',
      'Arizpe',
      'Arivechi',
      'Villa de Arista',
      'Ario de Rosales',
      'Ario de Rayón',
      'Arcinas',
      'Arcelia',
      'Aratichanguío',
      'Araró',
      'Aranza',
      'Arantepacua',
      'Arandas',
      'Santa Eulalia',
      'Apozol',
      'Aporo',
      'Apoderado',
      'Ciudad Apodaca',
      'Apo',
      'Apaseo el Grande',
      'Apaseo el Alto',
      'Antúnez',
      'Antonio Escobedo',
      'San Juanito de Escobedo',
      'Antonio Amaro',
      'Ansihuacuaro',
      'Angostura',
      'Angamacutiro de la Unión',
      'Angahuán',
      'Ameche',
      'Ameca',
      'Amealco',
      'Amatlán de Cañas',
      'Amatitán',
      'Amatepec',
      'Amazcala',
      'Amapa',
      'Amanalco de Becerra',
      'Amacueca',
      'Albia',
      'Altata',
      'Altar',
      'Ciudad de Allende',
      'Alista',
      'Alhuey',
      'Alcocer',
      'Alcholoa',
      'Alcaraces',
      'Álamos',
      'Ajuno',
      'Ajuchitlán del Progreso',
      'Ajuchitlancito',
      'Ajuchitlán',
      'Ajijic',
      'Ahuisculco',
      'Ahuirán',
      'Ahualulco de Mercado',
      'Ahualulco del Sonido Trece',
      'Epigmenio González',
      'Ahome',
      'Aguililla',
      'Agua Verde',
      'Aguascalientes',
      'Agua Prieta',
      'Agua Nueva',
      'Agua Gorda',
      'Agua Caliente Grande (De Gastélum)',
      'Agua Caliente',
      'Agostitlán',
      'Agiabampo Uno',
      'Aduana del Sásabe',
      'Adolfo Ruíz Cortínes',
      'Acuítzio del Canje',
      'Aconchi',
      'Acatlán de Juárez',
      'Acatic',
      'Acaponeta',
      'Acapetlahuaya',
      'Acámbaro',
      'Acalpican de Morelos',
      'Acachuén',
      'Ramón Corona',
      'General Juan José Ríos',
      'Camalotita',
      'Tavera',
      'Santa Casilda',
      'Villa Nicolás Bravo',
      'Jaltiche de Arriba',
      'Crucero Las Pilas',
      'La Panadera',
      'Ojocaliente',
      'Centro de Arriba',
      'San Antonio de los Ríos',
      'General Ignacio Zaragoza',
      'San Antonio de los Horcones',
      'Montoro',
      'Jesús Gómez Portugal (Margaritas)',
      'El Refugio de Peñuelas',
      'Arellano',
      'Paseos de la Providencia [Fraccionamiento]',
      'Peñuelas (El Cienegal)',
      'El Refugio de Providencia (Providencia)',
      'Cotorina',
      'La Escondida',
      'Puertecito de la Virgen',
      'Las Norias de Ojocaliente',
      'Norias del Paso Hondo',
      'Jaltomate',
      'Noria del Borrego (Norias)',
      'Guadalupe de Atlas',
      'Ojo de Agua de Crucitas',
      'Guerrero Negro',
      'Bahía Asunción',
      'El Pescadero',
      'Ciudad Satélite del Norte',
      'Rincón de Cano',
      'Victoria',
      'Cuesta de Peñones',
      'Ojo de Agua del Refugio',
      'Doctor Mora',
      'San Sebastián de Salitre',
      'Palo Colorado',
      'San Isidro de la Estacada',
      'La Sabana',
      'Río Laja',
      'El Gallinero',
      'Dolores Hidalgo Cuna de la Independencia Nacional',
      'Los Lorenzos',
      'Mineral de la Luz',
      'San Agustín de las Flores',
      'Providencia de Nápoles',
      'Loza de los Padres',
      'Mezquite de Sotelo',
      'El Refugio de los Sauces',
      'San Francisco de Durán',
      'San José del Potrero',
      'Alfaro',
      'Ibarrilla',
      'Los Arcos',
      'Hacienda Arriba',
      'San José del Torreón',
      'Fábrica de Melchor',
      'El Tejocote (El Domingo)',
      'El Carretón',
      'Chirimoya (Estación Chirimoya)',
      'Estanzuela de Romero',
      'San Juan Jaripeo',
      'San Nicolás de la Condesa',
      'Urireo',
      'Maravatío del Encinal',
      'Potreros',
      'Mezquite de Luna',
      'Zapote de Cestao',
      'Zapote de Barajas',
      'Pastor Ortíz',
      'El Varal',
      'La Lobera',
      'Zapote de Peralta',
      'San Felipe Quiriceo',
      'Labor de Peralta',
      'San Ignacio de San José Parangueo',
      'Puerta del Monte',
      'Providencia',
      'El Sauz (El Sauz de Villaseñor)',
      'Galerade Panales',
      'San Juan Bautista Cacalote',
      'Piedras de Lumbre',
      'La Cuevita',
      'Ojo de Agua de la Trinidad',
      'San Antonio Calichar',
      'El Espejo',
      'San Isidro de Gamboa',
      'El Salto de Espejo',
      'Estancia del Llano',
      'Tenango el Nuevo',
      'La Norita',
      'Caleras de Ameche',
      'El Tunal',
      'Guadalupe del Monte',
      'Jocoqui',
      'El Picacho',
      'Presa Blanca',
      'San Isidro de la Concepción',
      'Gasca',
      'San Antonio de los Morales',
      'San José de Guanajuato',
      'El Caracol',
      'Franco Tavera',
      'Rincón del Centeno',
      'Delgado de Arriba',
      'Delgado de Abajo',
      'Don Diego',
      'San José de las Pilas',
      'Cerrito de Gasca',
      'El Divisador',
      'San José de la Montaña',
      'Sarabia',
      'Mexicanos',
      'Los Prietos',
      'Tomelopitos',
      'San Antonio el Chico',
      'San Vicente de Flores',
      'Valencianita',
      'El Copalillo',
      'Carrizal Grande',
      'El Carrizalito',
      'La Caja',
      'Cuchicuato',
      'Colonia Morelos de Guadalupe de Rivera',
      'San Ignacio de Rivera (Ojo de Agua)',
      'Laguna Larga',
      'Estación Joaquín',
      'Rancho Nuevo de la Cruz',
      'Loma de la Esperanza',
      'Calzada del Tepozán',
      'Frías',
      'Silva',
      'El Mezquitillo',
      'Jesús del Monte',
      'Plan Guanajuato (La Sandía)',
      'Loza de Barrera',
      'El Jaguey',
      'El Escoplo',
      'Gavia de Rionda',
      'Santa Rosa de Rivas',
      'Mezquite Gordo',
      'San José de Llanos',
      'Molino de Santa Ana',
      'San Antonio el Rico',
      'Las Liebres',
      'Capulín de Bustos',
      'Medio Sitio',
      'Los Medranos',
      'Bajío de Bonillas',
      'Colonia Nuevo México',
      'Monte del Coecillo',
      'Cañada de Bustos',
      'San José del Rodeo',
      'Landín',
      'Don Francisco',
      'La Cruz del Palmar',
      'La Cieneguita',
      'Palmillas de San Juan',
      'Corral de Piedras de Arriba',
      'Potrerillos (Guanajal)',
      'La Ortiga',
      'Los Desmontes',
      'Los Fierros',
      'San Antonio Eménguaro',
      'El Moral',
      'Buenavista de Cortés',
      'Colonia Morelos',
      'Los Ocotes',
      'Huitzatarito',
      'Estación Pénjamo',
      'San Juan Grande',
      'San José de Ayala',
      'Peralta',
      'Cerro Colorado',
      'Loma de Zempoala',
      'Santiago Capitiro',
      'Victoria de Cortazar',
      'La Compañía',
      'Zapotillo de Mogotes',
      'El Acebuche',
      'El Cuenqueño',
      'Guándaro',
      'San Miguel Epejan',
      'Ziquítaro',
      'Janamuato',
      'Janambo',
      'San José Huipana',
      'Isaac Arriaga (Santa Ana Mancera)',
      'El Pilar',
      'Dolores',
      'Epitacio Huerta',
      'Chitejé de Garabato',
      'San Antonio Molinos',
      'San Pedro Tarímbaro',
      'Venta de Bravo',
      'Ejido el Rosario',
      'Manzana de San Luis',
      'Irimbo',
      'Uripitio',
      'San Antonio Villalongín',
      'Ojo de Agua de Bucio',
      'Zurumbeneo',
      'Irapeo',
      'Cuparátaro',
      'Cuto del Porvenir',
      'Mesón Nuevo (Cañada de la Magdalena)',
      'Jaripeo',
      'San Nicolás Obispo',
      'La Mojonera',
      'Zopoco',
      'San Andrés Coru',
      'Zirimícuaro',
      'Los Cajones',
      'Santa Juana',
      'Chapa',
      'Parahuén',
      'San Juan de Viña',
      'Chupio',
      'Tafetán',
      'Cerro Colorado de Ocampo',
      'Huanguitío',
      'Ocurio',
      'Donaciano Ojeda',
      'Macho de Agua',
      'Aputzio de Juárez',
      'Parícuaro',
      'Purechucho',
      'Salguero',
      'Zicuirán',
      'Cuatro Caminos',
      'El Letrero',
      'Gambara',
      'El Chauz',
      'Coahuayana de Hidalgo',
      'La Placita de Morelos',
      'Playa Azul',
      'La Mira',
      'Guacamayas',
      'Ciudad Lázaro Cárdenas',
      'Infiernillo (Morelos de Infiernillo)',
      'San Pedro Limón',
      'Santa Ana Zicatecoyan',
      'Rincón de Jaimes',
      'Tenería',
      'San Pedro Tenayac',
      'Zacazonapan',
      'Otzoloapan',
      'Cuadrilla de Dolores',
      'Casas Viejas',
      'San Francisco Mihualtepec',
      'San Antonio Solís',
      'Cerritos de Cárdenas',
      'San Francisco Solis',
      'Santa María Canchesdá',
      'Santiago Coachochitlan',
      'San Nicolás el Oro',
      'Rincón de los Pirules',
      'Dotegiare',
      'San Miguel del Centro',
      'Calvario del Carmen',
      'Cedro de la Manzana',
      'Concepción la Venta',
      'San José del Rincón Centro',
      'Concepción del Monte',
      'Fábrica Concepción',
      'Guadalupe Buenavista',
      'San Miguel Agua Bendita',
      'San Nicolás Guadalupe',
      'Tres Estrellas',
      'La Mesa',
      'San Diego del Cerrito',
      'San Pedro del Rincón',
      'Cerritos del Pilar',
      'La Puerta del Pilar',
      'El Potrero de San Diego',
      'Loma de Juárez',
      'Vare Chiquichuca',
      'Cabecera de Indígenas',
      'Los Berros',
      'San Agustín Berros',
      'San Juan Xoconusco',
      'Barrio de Arriba de San Juan Xoconusco',
      'Santiago Huitlapaltepec',
      'San Gabriel Ixtla',
      'Guayameo',
      'Pantla',
      'San José Ixtapa (Barrio Viejo)',
      'Los Almendros',
      'Zacapuato',
      'San José Poliutla',
      'Morelita',
      'San Jerónimo el Grande',
      'Valle Luz',
      'Coyuquilla Norte',
      'San Luis de La Loma',
      'San Luis San Pedro',
      'El Ticui',
      'Hacienda de Cabañas',
      'Arenal de Álvarez (Arenal de Paco)',
      'Las Tunas',
      'Corral Falso',
      'El Escondido',
      'Aguas Blancas',
      'Los Mogotes',
      'Colonia Luces en el Mar',
      'Cuyutlán',
      'Cofradía de Juárez',
      'Cofradía de Morelos',
      'Llano del Tigre',
      'San José del Valle',
      'Las Jarretaderas',
      'Carbonera',
      'Presa de Rayas',
      'La Gotera',
      'Los Baños',
      'Las Lajitas',
      'Montenegro',
      'San Isidro Buenavista',
      'Pie de Gallo',
      'Jofrito',
      'La Barreta',
      'Cerro de la Cruz',
      'Colonia los Ángeles',
      'Los Olvera',
      'La Solana',
      'Santa María Begoña',
      'Saldarriaga',
      'Noria Nueva',
      'Urecho',
      'El Gallo',
      'Dolores Cuadrilla de Enmedio',
      'Loma Linda',
      'El Organal',
      'La Valla',
      'Arcila',
      'La D',
      'Laguna de Vaquerías',
      'Apapátaro',
      'El Milagro',
      'Joaquín Herrera',
      'Charco Blanco',
      'El Jaral',
      'Bravo',
      'Santiago Mexquititlán Barrio 4to.',
      'Puerto de Aguirre',
      'Nuevo Vallarta',
      'San Juan De Los Platanos',
      'Huapinol',
      'Puerto Aventuras',
      'Zapopan2',
      'Colonia del Valle',
      'Colonia Lindavista',
      'Col. Bosques de las Lomas',
      'Colonia Nativitas',
      'Real de Catorce',
      'Las Higuerillas',
      'El Barrancón del Tío Blas (El Barrancón)',
      'La Isla Km 10',
      'Poblado Lázaro Cárdenas (La Veintiocho)',
      'Ejido Doctor Alberto Oviedo Mota (El Indiviso)',
      'Ejido Javier Rojo Gómez',
      'Ejido Ojo de Agua',
      'La Nueva Victoria',
      'Sinapan',
      'Estación Santa Engracia',
      'Maya Balam',
      'Morocoy',
      'Don Samuel',
      'Xbacab',
      'Aurelio Manrique',
      'Ponciano Arriaga',
      'Guayalejo',
      'Estación Tamuín',
      'Pujal-Coy',
      'Machetla',
      'Chililico',
      'El Zapote Bravo',
      'San Antonio Ocopetlatlán',
      'Ahuaxintitla',
      'El Chote',
      'La Colonia Guadalupe',
      'La Unión Paso Largo',
      'La Isla de Chapachapa',
      'Las Sabinas',
      'El Lindero',
      'Abdenago C. García',
      'Molino de Camou',
      'Estación Zamora',
      'Carbo',
      'Buayums',
      'Hacienda Santa Fe',
      'Fuentes del Valle',
      'San Salvador Tizatlalli',
      'Jardines de la Silla (Jardines)',
      'San Jerónimo Cuatro Vientos',
      'San Martín Azcatepec',
      'Fraccionamiento Real Palmas',
      'Pórticos de San Antonio',
      'Centro Familiar la Soledad',
      'San José Guadalupe Otzacatipan',
      'Las Pintitas',
      'San Antonio Tecómitl',
      'Licenciado Benito Juárez (Campo Gobierno)',
      'Teotihuacán de Arista',
      'San Jorge Pueblo Nuevo',
      'Manuel Ojinaga',
      'Terrazas del Valle',
      'La Ermita',
      'Lomas del Sur',
      'Parque Industrial Ciudad Mitras',
      'Mitras Poniente',
      'Villa del Prado 2da Sección',
      'Tres de Mayo',
      'Don Antonio',
      'La Providencia Siglo XXI',
      'Ampliación San Mateo (Colonia Solidaridad)',
      'Fraccionamiento Ciudad Olmeca',
      'San Rafael Tlanalapan',
      'Tecámac de Felipe Villanueva',
      'Venceremos',
      'Alborada Jaltenco',
      'Crucecita',
      'Palmira',
      'Real del Valle (El Paraíso) [Fraccionamiento]',
      'Villa del Campo',
      'Centro de Readaptación Social',
      'Veintidós de Febrero',
      'Fraccionamiento Geovillas los Pinos',
      'Juan Morales',
      'Santa María Zacatepec',
      'Lomas de San Agustín',
      'Córdoba (Santa Leticia)',
      'San Francisco Tecoxpa',
      'Villas de la Hacienda [Fraccionamiento]',
      'Conjunto Habitacional Villas del Pedregal',
      'Pomoca',
      'Puerto Escondido (Tepeolulco Puerto Escondido)',
      'Unidad Habitacional José María Morelos y Pavón',
      'Las Veredas',
      'Profesor Carlos Hank González',
      'Fraccionamiento la Trinidad',
      'Santa Cruz Amilpas',
      'Paseos de San Juan',
      'Valle Dorado Inn',
      'General Ángel Flores (La Palma)',
      'Brisas de Zicatela',
      'San Antonio del Coyote',
      'Jorge Jiménez Cantú',
      'Galaxia Bonito Jalisco',
      'Antúnez (Morelos)',
      'La Alameda',
      'El Niño',
      'Fraccionamiento Lomas de Ahuatlán',
      'Lomas de Barrillas',
      'Marte R. Gómez (Tobarito)',
      'Fraccionamiento Misión del Valle',
      'Colonia Santa Bárbara',
      'Bosque de Saloya',
      'San Lucas el Grande',
      'San Andrés Azumiatla',
      'Rancho Alegre [Fraccionamiento]',
      'Ex-Rancho San Dimas',
      'Jacarandas',
      'El Pino',
      'Unión y Progreso',
      'Misión San Pablo',
      'Tenextepango',
      'Ejido San Lorenzo Cuauhtenco',
      'Santa Catarina Ayometla',
      'La Selva',
      'Los Olivos',
      'Villa Luvianos',
      'Los Ruiseñores',
      'Barrio de México',
      'Tenango de las Flores',
      'Maclovio Rojas',
      'San Sebastián del Sur',
      'El Súchil',
      'El Campanario y Oradel',
      'El Diez',
      'Villa Alberto Andrés Alvarado Arámburo',
      'San Antonio Mihuacán',
      'Raudales Malpaso',
      'Santa Cruz del Monte',
      'Arenal',
      'San Sebastián de Aparicio',
      'Fraccionamiento Colinas del Sol',
      'La Pradera',
      'Lomas de Santa Anita',
      'Benito García (El Zorrillo)',
      'Lomas Verdes',
      'Vida Mejor I',
      'La Constitución Toltepec',
      'Kilómetro 30',
      'Fraccionamiento los Ángeles',
      'Rincón de Nicolás Romero (Cedros Tercera Manzana)',
      'Campestre Villas del Álamo',
      'Conjunto Urbano la Loma I',
      'Javier Rojo Gómez',
      'Parque Urbano Napateco',
      'El Cóporo',
      'Fraccionamiento Arboledas San Ramón',
      'Fraccionamiento Galaxia Tarímbaro',
      'Real del Sol',
      'Fraccionamiento Metrópolis II',
      'Ciudad de Cuetzalan',
      'Colinas del Roble',
      'Granjas Ampliación Santa Rosa',
      'Héctor Caballero',
      'Quinto Barrio (Ejido Cahuacán)',
      'Valle de Lincoln',
      'Quinta del Cedro',
      'Lomas de Altavista',
      'Unidad Minera 11 de Julio',
      'Galaxia la Noria',
      'Monte Kristal',
      'La Lima',
      'Loma la Paz',
      'Ciudad Coahuila (Kilómetro Cincuenta y Siete)',
      'Pedro Amaro',
      'Adolfo López Mateos (El Tamarindo)',
      'Villas de Alcalá',
      'Calera Chica',
      'El Rincón Citlaltépetl',
      'San Francisco de Asís',
      'Jicaltepec Autopan',
      'Villas de Irapuato',
      'Arbolada los Sauces',
      'Atoluca',
      'Pocitos',
      'Anacleto Canabal 2da. Sección',
      'La Pastoría',
      'Los Tuzos',
      'Ex-Hacienda Santa Inés',
      'San Lorenzo Axocomanitla',
      'Nueva Santa Rosa',
      'Las Torres',
      'San José el Cuartel',
      'Colinas de Plata',
      'Palomar',
      'Unidad Acaquilpan',
      'Paseo del Prado',
      'El Centenario',
      'Fraccionamiento Ocuiltzapotlán Dos',
      'Los Torrentes',
      'Santa Justina Ecatepec',
      'Aquiles Serdán (San Fernando)',
      'Tungareo',
      'Villa Magna',
      'Tianguistengo (La Romera)',
      'Hacienda los Fresnos',
      'El Pedregal de Guadalupe Hidalgo',
      'Campestre las Palomas [Fraccionamiento]',
      'Villa Licenciado Jesús Terán (Calvillito)',
      'Panuaya',
      'Lo de Juárez',
      'Santiago Etla',
      'San Bernabé Temoxtitla',
      'Villas de la Loma',
      'Molino Abajo',
      'Jicaltepec Cuexcontitlán',
      'General Lázaro Cárdenas (El Colorado)',
      'Tatoxcac',
      'Cereso del Hongo',
      'Villa Jiménez',
      'Tacopan',
      'Santa María de Guadalupe',
      '2da. Sección de Medio Monte',
      'San Francisco Tepeyecac',
      'Axotlán',
      'La Galarza',
      'San Buenaventura Tecaltzingo',
      'Campestre Flamboyanes',
      'Villas de la Laguna',
      'Alianza Real',
      'Colonia Revolución',
      'Loma Larga',
      'Nejapa',
      'La Trinidad Chautenco',
      'Real Hacienda (Metrópolis)',
      'C-32 (Licenciado Francisco Trujillo Gurría)',
      'Hércules',
      'Delfino Victoria (Santa Fe)',
      'San Pedro Zacachimalpa',
      'Ichán',
      'Peña Flores (Palo Verde)',
      'Puerto Esmeralda',
      'Colonia Lomas de San Ramón (Triquis)',
      'San Bartolomé Aguas Calientes',
      'General Felipe Ángeles (Los Ángeles)',
      'Colinas del Aeropuerto',
      'San Bartolomé Cuahuixmatlac',
      'Vistas del Maguey [Fraccionamiento]',
      'Barrio Santa Cruz',
      'Santa María Texmelucan',
      'El Saucillo (Fraccionamiento)',
      'Santiago Chimalpa (Chimalpa)',
      'San Esteban (San Miguel Tateposco)',
      'Plutarco Elías Calles (La Y Griega)',
      'Yoshib',
      'Hacienda San Pedro',
      'Forjadores de Pachuca',
      'San Miguel Jaltocan',
      'C-41 (Licenciado Carlos A. Madrazo)',
      'Santiago Puriatzícuaro',
      'Fraccionamiento Costa Dorada',
      'Colonia 2 de Septiembre',
      'Toreo Bajo (El Toreo Bajo)',
      'Santa Rosa (Santa Bárbara)',
      'Villa URBI del Rey',
      'Chalchocoyo',
      'Barrio Cuarto (La Loma)',
      'Cristeros [Fraccionamiento]',
      'Zacango',
      'San Nicolás Zoyapetlayoca',
      'Colonia el Salado',
      'Sosa',
      'San Antonio Portezuelo',
      'Jardines de San Sebastián',
      'Ex-Viñedos Guadalupe',
      'Chipilo de Francisco Javier Mina',
      'Zacualpan de Amilpas',
      'San Francisco Ayotuzco',
      'Alfonso G. Calderón (Poblado Siete)',
      'San Antonio Gallardo',
      'PRI Chacón',
      'Los Tigres (San Marcos)',
      'Villa Santiago',
      'Colonia Santa Cecilia (La Sauceda)',
      'Samarkanda',
      'Genaro Estrada',
      'Colonia Lázaro Cárdenas (Los Hornos)',
      'Atzingo (La Cumbre)',
      'Unidades Habitacionales',
      'Colinas de Santa Cruz Segunda Sección',
      'Oasis',
      'Ocopulco',
      'Poblado 10',
      'Arboledas Paso Blanco [Fraccionamiento]',
      'San Miguel Tianguistenco',
      'Barrio Tlatenco',
      'Amatillo',
      'Colonia Gustavo Baz Prada',
      'Tlamaco (San Gerónimo Tlamaco)',
      'Atecucario de la Constitución (Atecuario)',
      'Agrícola Lázaro Cárdenas',
      'Jagüey de Téllez (Estación Téllez)',
      'Nachig',
      'Arroyo del Maíz Uno',
      'Colonia Nueva Era',
      'Fraccionamiento Valle Dorado',
      'Las Colonias de Hidalgo',
      'San Diego los Padres Cuexcontitlán Sección 5 B',
      'Belén Atzitzimititlán',
      'Unidad Habitacional Rinconada Acolapa',
      'El Saucito (El Horno)',
      'San Isidro Vista Hermosa',
      'Simeprodeso (Colectivo Nuevo)',
      'Corral de Barrancos',
      'San Pedro Benito Juárez',
      'Juárez Coronaco',
      'Los Valles',
      'Pochahuizco',
      'Santo Nombre',
      'Joshil',
      'Tezoquipa',
      'Colonia los Cedros',
      'Prados de San Juan',
      'Changata',
      'San Lucas Pío',
      'Los Cafetales',
      'Arena 1ra. Sección',
      'Colonia Vista Bella (Lomas del Peaje)',
      'Fraccionamiento las Fuentes',
      'Nuevo San Miguel',
      'Xoloateno',
      'El Hielo',
      'Buena Vista 1ra. Sección',
      'Fraccionamiento Cosmópolis Octavo Sector',
      'Cuarta Brigada',
      'Rincones de la Hacienda',
      'Fraccionamiento Real de San Pablo',
      'Colonia las Flores',
      'El Saucillo',
      'Barrio San Joaquín el Junco',
      'Puerto de Buenavista (Lázaro Cárdenas)',
      'Iquinuapa',
      'Valle de Vaquerías',
      'Río Medio [Granja]',
      'Juan Aldama (El Tigre)',
      'Lomas de Río Medio Cuatro',
      'Jardínes del Grijalva',
      'Colonia la Asunción',
      'Bahía de Lobos',
      'San Martín Coapaxtongo',
      'Galaxia la Calera',
      'Zapotlán',
      'Sector H Tres',
      'Tlatempan',
      'Ixtlahuaca Barrio',
      'Jesús Nazareno',
      'Guadalupe Zaragoza',
      'Bosques de la Magdalena',
      'El Huaxtho',
      'Tamazulápam del Espíritu Santo',
      'Poblado C-33 20 de Noviembre',
      'Santiago Acozac',
      'Rancho Verde',
      'Entronque Laredo-Salinas Victoria',
      'San Felipe los Alzati (Colonia Nueva)',
      'Rinconada de los Ángeles',
      'Sitio Ejido',
      'Conjunto Habitacional Ecológico SUTEYM',
      'San José el Saladillo (El Saladillo)',
      'El Huarache (El Guarache)',
      'El Boxtha',
      'Colonia San Luis Rey',
      'Amuco de la Reforma',
      'Colonia Progreso',
      'Los Mangos',
      'Rizos de la Joya (Rizos del Saucillo)',
      'San Pedro Yancuitlalpan',
      'Fraccionamiento San Miguel',
      'Colonia Agrícola México (Palmitas)',
      'Cerrillos (Campo 35)',
      'El Varal (San Sebastián Número Uno)',
      'Portal de las Salinas',
      'Paseo Puente Viejo',
      'Santa Cruz Tetela',
      'Fraccionamiento Misión de San Javier',
      'Zona Urbana Ejido Isla Mujeres',
      'Fraccionamiento los Prados',
      'Laguna del Rey (Químicas del Rey)',
      'Poblado Número Cinco',
      'Capoluca',
      'Chicahuaxtla',
      'Paraje el Mirador',
      'Santa María Aranzazú (Santa María)',
      'Macuilxóchitl de Artigas Carranza',
      'Cuauhtémoc (Campo Cinco)',
      'San Francisco Independencia (Santa María Aserradero)',
      'Fraccionamiento Ex-Hacienda el Refugio',
      'San Juan Benito Juárez (San Juan Tararameo)',
      'San Vicente la Mesilla',
      'Fraccionamiento Rinconada del Valle',
      'Unidad Habitacional Santa Teresa',
      'Prados San Francisco',
      'Hualcaltzinco',
      'Uruétaro',
      'El Ámbar (El Ámbar de Echeverría)',
      'Santa María Huiramangaro (San Juan Tumbio)',
      'Anacleto Canabal 3ra. Sección',
      'Carmen Zacatal',
      'El Cid',
      'San José Tejamanil',
      'San Nicolás Zecalacoayan',
      'Tuncingo',
      'Pueblo Mayo',
      'Coahuixco',
      'Zoatecpan',
      'José María Pino Suárez (Nuevo Nahuapa)',
      'La Planada',
      'Llano de la Y',
      'San Gregorio Aztotoacan',
      'Loma de San José',
      'San Diego la Huerta',
      'Santa María Chimalapa',
      'San Lorenzo Queréndaro',
      'La Ermita (Nueva Jerusalén)',
      'Gabriel Leyva Velázquez',
      'Analco de Ponciano Arriaga (Santa Cruz Analco)',
      'Cala Sur',
      'Viñas del Sol',
      'Kotolte',
      'Apango de Zaragoza',
      'Cartagena [Fraccionamiento]',
      'Bellavista de Victoria (San José Bellavista)',
      'Ninguno [Centro de Readaptación Social de Atlacholoaya]',
      'Suchilapan del Río',
      'Polígonos',
      'San Jerónimo Almoloya',
      'Rinconada la Loma [Fraccionamiento]',
      'Llano Suchiapa',
      'Colonia Paraíso Escondido',
      'Colonia Emiliano Zapata Ejido de Tenancingo',
      'Acxotla del Río',
      'El Rincón (Santa Cruz del Rincón)',
      'El Nabo',
      'Jorobas',
      'Lomas de la Maestranza',
      'Paseos del Pedregal',
      'Las Esperanzas (El Ranchito)',
      'Ejido de Mozoquilpan',
      'Colonia San Isidro',
      'Chiloljá',
      'Pantitlán',
      'San Francisco (El Calvito)',
      'Fraccionamiento Ciudad Yagul',
      'San Miguel del Progreso',
      'El Recuerdo de Ancón (Xoconoxtle de Arriba)',
      'Fraccionamiento Ex-Hacienda Catano',
      'Tlacotepec de José Manzo',
      'San José Tenería (Tenería)',
      'Palma Grande',
      'Colonia las Malvinas (Colonia Antorcha)',
      'Manzana Quinta (La Cañada)',
      'Cherán Atzicuirín (Cheranástico)',
      'Ejido la Soledad',
      'Tehuilotepec',
      'Hacienda la Cruz [Fraccionamiento]',
      'San Salvador (Naranjillo)',
      'Colonia Campesina',
      'San Miguel Tianguizolco',
      'Mismaloya (Fraccionamiento Pedregal de Santa Martha)',
      'La Roca',
      'El Cuervero (Cuerveros)',
      'San Bartolo del Llano (San Isidro)',
      'San Isidro Gallinero (El Gallinero)',
      'Amacuitlapilco',
      'Colonia Río Lerma (Tic Ti)',
      'San Mateo Capultitlán',
      'San Juan Ahuehueyo',
      'San José Carpinteros',
      'Colonia Ricardo Flores Magón',
      'Hermenegildo J. Aldana',
      'Colonia Guadalupe Victoria (La Capilla)',
      'Corral del Risco (Punta de Mita)',
      'El Curtidor',
      'Poblado C-21 Licenciado Benito Juárez García',
      'Cima del Sol (Fraccionamiento Cima del Sol)',
      'Pueblos Unidos',
      'Luis Rodríguez (El Vergel)',
      'San Miguel Espejo',
      'Alfonso G. Calderón Velarde',
      'Colonia San Ramón',
      'Paso de Arena',
      'Tulipanes',
      'San Francisco Temetzontla',
      'Los Laureles',
      'Panales Jamaica (Cañones)',
      'Cereso 14 (El Amate)',
      'Bonifacio Moreno (El Aguaje)',
      'Las Cañadas',
      'Santa Catarina Villanueva',
      'Doctor Rodulfo Figueroa (Tierra Blanca)',
      'Unión Ejidal Tierra y Libertad',
      'El Tigre',
      'Barrio de Centro del Cerrillo',
      'Centro de Readaptación Social Nuevo',
      'San Antonio del Monte',
      'Loma Larga (Barrio de Loma Larga)',
      'Xhixhata',
      'Nuevo Paso Nazareno (Chichicazapa)',
      'Llano Largo',
      'El Encanto del Cerril',
      'La Mesa de Chosto',
      'Magisterio Digno',
      'Pañhé',
      'El Carmen (El Limón)',
      'El Romereño (INFONAVIT)',
      "Tz'Aquiviljok",
      'Tecpantzacoalco',
      'Barrio de Jesús Fracción Primera',
      'Colonia Morelos (El Nueve)',
      'Arboledas',
      'Texcaltitán Xoteapan (Texcaltitán)',
      'Fredepo',
      'Octaviano López',
      'Texalpan de Arriba',
      'Gunyo Poniente (San José Gunyo)',
      'Chulum Juárez',
      'Barrio de Guadalupe',
      'Ladrillera (Entronque Pesquería)',
      'San Francisco (Mata Clara)',
      'Cipreses',
      'Colonia el Renacimiento',
      'Macario J. Gómez [Colonia]',
      'Unidad Habitacional Antonio Osorio de León (Bojay)',
      'Taniperla',
      'San José Monte Chiquito',
      'Xonalpu',
      'Morelos Piedra 3ra. Sección',
      'San Francisco Tlaltica',
      'San Francisco Zacapexpan',
      'Cliserio Alanís (San Gaspar)',
      'Tinajitas',
      'Nuevo Vicente Guerrero (El Chichonal)',
      'San Isidro (El Reservado)',
      'Mariano Balleza',
      'Brisas del Carrizal',
      'Rafael Yáñez Sosa (El Mezquite)',
      'Bocaneo (San Pedro)',
      'CEFERESO Número 3',
      'Tzeltal',
      'Alfredo V. Bonfil (Chacampalco)',
      'San Buenaventura Atempan',
      'Tocob Leglemal',
      'Altus Bosques',
      'Barrio de Nuevo León',
      'Colonia 18 de Marzo',
      'Heriberto Valdez Romero (El Guayabo)',
      'San José Chapayal',
      'Seis de Octubre (Santo Niño)',
      'La Escalera',
      'Papatlazolco',
      'San Francisco (Baños de Agua Caliente)',
      'Ciudad Morelos',
      'El Copalar',
      'San Pedro Xalcaltzinco',
      'Campo Real',
      'Taxco',
      'Sierra de Guadalupe',
      'Ninguno [CERESO]',
      'Acatempa',
      'Chimalapa',
      'Xoxonacatla',
      'Otilpan',
      'Progreso de Juárez',
      'San Rafael Tepatlaxco',
      'Vallecitos de Zaragoza',
      'San José del Cerrito de Camargo',
      'Barrio de España',
      'Techachaltitla',
      'San Francisco Tlacuilohcan',
      'Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]',
      'San Antonio Tecolco',
      'Fraccionamiento Hacienda del Bosque',
      'Oso Viejo',
      'El Cortijo',
      'Colonia Montemariana (Colonia Mariana)',
      'Colonia Latinoamericana',
      'Zahuatlán de Morelos (San José)',
      'El Pozole',
      'Tatauzoquico',
      'Guadalupe Minerva',
      'San Lorenzo Tlaxipehuala',
      'Paseo de las Cañadas',
      'Constancio Farfán (La Pascuala)',
      'Villas Fundadores [Fraccionamiento]',
      'Bella Vista del Río',
      'El Rincón de San Felipe (Tercera Manzana San Felipe)',
      'Fraccionamiento Monte Olivo',
      'Tlamanca de Hernández',
      'Purísima del Progreso',
      'El Jacal',
      'San Pedro de los Sauces',
      'La Azozuca',
      'Colonia Agrícola Álvaro Obregón',
      'Cuatzoquitengo',
      'Lagartera 1ra. Sección',
      'Hacienda Vieja del Castillo (Castillo Viejo)',
      'Ceiba 1ra. Sección (Jahuactal)',
      'San Vicente Zoyatlán',
      'Tenochtitlán',
      'Chichicaxtle',
      'Manuel Villalongín',
      'La Versolilla',
      'Colonia Tulteca Teopan',
      'Fraccionamiento Paseo de las Torres',
      'El Cahulote de Santa Ana',
      'Colonia Agrícola de Ocotepec (Colonia San José)',
      'Apeo',
      'Leacaman',
      'Yashanal',
      'Buenavista de Juárez',
      'Colonia Emiliano Zapata (San Juan Zitácuaro)',
      'Caparroso',
      'Colonia Diana Laura Riojas de Colosio',
      'Tlaxinga (Clatzinga)',
      'San Pedro Nichtalucum',
      'Hermenegildo Galeana 2da. Sección',
      'Hacienda Tecate',
      'San Antonio la Portilla',
      'San Bartolomé Hueyapan',
      'Marcelino Rodríguez (San Ignacio)',
      'San Sebastián [Fraccionamiento]',
      'Fraccionamiento Universo',
      'El Esclavo',
      'Santa Cruz Acapa',
      'San Miguel Monteverde',
      'Ampliación la Hincada',
      'Pueblo Nuevo Tlalmimilolpan',
      'San Atenógenes (La Villita)',
      'Cerro las Iguanas',
      'Texcaltepec',
      'San Roque de Montes',
      'Jardines del Edén',
      'Caserío de Cortés',
      'Alpuyecancingo de las Montañas',
      'Emiliano Zapata (El Ranchito)',
      'Tezhuatepec',
      'Santiago Jaltepec',
      'Olintepec',
      'El Arco',
      'San Francisco de la Palma',
      'Colonia Lázaro Cárdenas',
      'Fraccionamiento Real del Valle',
      'San José del Puente',
      'Bosques de San Pedro',
      'Pejelagartero 1ra. Sección (Plataforma)',
      'Ahuacatlán de Guadalupe',
      'Monte Calvario',
      'San Pedro Atmatla',
      'La Mohonera',
      'Mecoacán 2da. Sección (San Lorenzo)',
      'Guásimas (De Belem)',
      'Colonia Adolfo López Mateos',
      'Colonia el Refugio',
      'Nuevo Milenio Valdivia',
      'Estación Corral',
      'San Pedro Ozumacín',
      'Coamilpa de Juárez',
      'Los Dolores (Las Quince Letras)',
      'Colonia Militar',
      'Coachiti',
      'Vamos Tamaulipas',
      'Loma Alta Taxhimay',
      'Cuaxuxpa',
      'Atenxoxola',
      'San Pedro Arriba 3ra. Sección',
      'Colonia 28 de Mayo (Santa Rosa)',
      'Fraccionamiento y Club de Golf los Encinos',
      'Mezapa la Fábrica',
      'San Jerónimo Yahuiche',
      'Colonia Antorcha Campesina (Santa Rosa)',
      'Manzana Sexta Parte Centro',
      'El Pozo',
      'San Isidro Alta Huerta',
      'Colonia San Francisco de Asís',
      'Fraccionamiento la Florida',
      'Cruztón',
      'Artemio Treviño',
      'La Pimienta',
      'Geovillas Laureles del Campanario',
      'Guadalupe Totoltepec',
      'Plan de Agua Prieta',
      'José María Morelos (La Yegüería)',
      'Ejido Cajón Ojo de Agua Número Dos',
      'Huapacal 2da. Sección (Punta Brava)',
      'Los Parques',
      'Sitalá',
      'Atlamajac',
      'San Antonio Texas',
      'Capitán Felipe Castellanos Díaz (San Pedro)',
      'Cuautotolapan (San José)',
      'La Fundición (Quinta Manzana)',
      'Parque de Poblamiento Solidaridad',
      'Tanamacoyan',
      'El Encanto',
      'San José del Jagüey',
      'Colonia Adolfo Ruiz Cortines (Colonia Obrera)',
      'Colonia Guadalupe Victoria',
      'Barrio Bordo Nuevo',
      'Shoctic',
      'San Pedro Tulixtlahuaca',
      'Santana 2da. Sección B (La Palma)',
      'Zacapalco',
      'Zapotitlán Tablas',
      'Sebastián Lerdo de Tejada',
      'La Cuchilla (Extramuros de Tesistán)',
      'Colonia Agrícola Analco',
      'Rancho Chico',
      'Paso Blanco',
      'Crescencio Morales (San Mateo)',
      'Campo la Arrocera',
      'San Andrés Ocotepec',
      'San José la Hacienda',
      'Los Reyes Tlanechicolpan',
      'Rancho Bellavista [Fraccionamiento]',
      'El Cerrito',
      'Apatauyan',
      'Solalpan 1ra. Sección',
      'San José Neria',
      'Paintla',
      'Colonia Ignacio Allende',
      'Santa Catarina Roatina',
      'San Bernabé de las Canteras',
      'Villa de las Flores',
      'Villas del Campo',
      'El Llano Santa María',
      'Tepetates',
      'La Cabecera Concepción',
      'Loma de San Miguel',
      'Zona Urbana Ejidal',
      'El Porvenir (El Porvenir de Arriba)',
      'San José Atoyatenco',
      'Chula Vista',
      'Galaxias Toluca',
      'Monclova Primer Sector',
      'San Antonio Coyahuacán',
      'Tikinmul',
      'Aguas Calientes',
      'San Martín Ahuatepec',
      'Héroes de Chapultepec (Rodeo de San Antonio)',
      'Barrio San Miguel Dorami',
      'Santa Martha Hidalgo',
      'Cuonetzingo',
      'Ladrilleras del Refugio',
      'Habanero 1ra. Sección (Venustiano Carranza)',
      'Nueva Tenochtitlán (Rizo de Oro)',
      'Buena Vista (Apasco)',
      'Cañada del Tabaco',
      'San Martín Tlamapa',
      'Fraccionamiento Santa Cruz',
      'San Antonio las Palmas',
      'Gobernadores',
      'San José de Cervera',
      'Cristóbal Colón',
      'Los Cues',
      'Estación Obispo',
      'Hueyitlalpan',
      'Colonia Azteca',
      'Colonia Aeropuerto',
      'San Lorenzo Joya de Rodríguez',
      'Joaquín Miguel Gutiérrez (Margaritas)',
      'Guadalupe Coté',
      'Valle Verde',
      'Nueva División del Bayo (Guatemala)',
      'Gómez Poniente',
      'La Trinidad Tianguismanalco',
      'Temalacaco',
      'Chiquinshulum',
      'La Y',
      'Santa Lucía Potrerillo',
      'Chicumtantic',
      'Matzam',
      'Paseos de las Haciendas [Fraccionamiento]',
      'El Tzay',
      'Melitón Albáñez Domínguez',
      'El Muey',
      'La Candelaria Teotlalpan',
      'San Isidro Monjas',
      'Hacienda Sotavento',
      'El CERESO',
      'Texmola',
      'Las Flechas',
      'Llano de la Lima',
      'Colonia Veintitrés de Mayo',
      'Casa Nueva',
      'Ocotlán de Venustiano Carranza',
      'Mezquitic de la Magdalena',
      'Praxedis de Guerrero',
      'Arroyo Choápam',
      'Martínez Domínguez',
      'San Francisco Atotonilco',
      'Laguna de Tabernillas (El Resbaloso)',
      'Istaro',
      'Xocoyolzintla',
      'Camelia Roja',
      'Los Cristales (Talican)',
      'Emilio Carranza (Santa Cruz)',
      'Benito Juárez II (San Martín)',
      'Colonia el Pedregal',
      'Primera de Analco',
      'Ixticpan',
      'Jolotichán',
      'Colonia Doctor Gustavo Baz',
      'Barrio de la Barranca',
      'Tierras Coloradas',
      'Fracción Milpillas',
      'Colonia Pedro María Anaya',
      'Soyatlán de Afuera',
      'La Rosa',
      'Arroyo de Enmedio',
      'Querendas',
      'Santa Cruz Xaltetela',
      'El Chinaco (El Pujido)',
      'Ex-Hacienda de Guadalupe',
      'Unidad Habitacional 10 de Abril',
      'Colonia los Aguiluchos',
      'Miguel Valdez Quintero (El Corazón)',
      'Toxtla',
      'San José Ozumba',
      'San Miguel Papaxtla',
      'Monte Alegre',
      'Salvador Rosas Magallón',
      'Barrio de San Juan',
      'La Loma de los Negritos',
      'Villa Hermosa (Las Pozas)',
      'Cerro de San Francisco',
      'Fraccionamiento la Virgen',
      'Tlaltempanapa',
      'Eureka y Belén',
      'Paseo de San Javier',
      'La Península',
      'El Porvenir I',
      'Ejido Pátzcuaro',
      'Fraccionamiento las Liebres',
      'Chixtontic',
      'Santiago Tepeticpac',
      'Gaviotas Sur (El Cedral)',
      'Lázaro Cárdenas (El Empalme)',
      'Barrio de Canales',
      'Cozotlán Norte',
      'Encrucijada 3ra. Sección (Las Calzadas)',
      'Chichicastle 1ra. Sección',
      'Corriente 1ra. Sección',
      'Tzintzimeo',
      'Francisco Rueda',
      'Tapias de Santa Cruz (Pedro Ruiz González)',
      'Rincón de Cedeños (Rincón de Dolores)',
      'Piletas',
      'El Sauz de Abajo',
      'Villa de los Niños',
      'Norte 1ra. Sección (San Julián)',
      'Santa Teresita de Don Diego',
      'Mexcala',
      'Tlacoapa',
      'Colonia 24 de Febrero',
      'Apas',
      'Ejido del Tejocote',
      'Lipuntahuaca',
      'San Martín Obispo (San Martín San Pedro)',
      'Taza de Agua Ojo Zarco',
      'Los Nicolases',
      'Muquén',
      'Tepechicotlán',
      'El Tepetatal',
      'Valle de los Girasoles [Fraccionamiento]',
      'Colonia Isidro Fabela',
      'El Palmar (San Gabriel)',
      'Primer Cantón',
      'Puxtla',
      'San Juan Pancoac',
      'Purísima de Cubos (La Purísima)',
      'El Sibal',
      'Santa María Urapicho',
      'Colonia Chalchihuecan',
      'Ángel Albino Corzo (Guadalupe)',
      'Entronque de Matehuala (El Huizache)',
      'Estación Mogoñé',
      'San José Cuaro',
      'Chacoma',
      'El Potrerillo (Potrerillo del Rincón)',
      'Gildardo Magaña (Los Ángeles)',
      'Fermín Rabadán Cervantes',
      'Colonia Palma',
      'Goros Número Dos',
      'Barrio los Tules',
      'Majomut',
      'Usipa',
      'Gabriel Leyva Solano (Zapotillo Dos)',
      'Tetecolala',
      'Vista Hermosa (Cuasinada)',
      'Ciudad Satélite',
      'Monte Largo 1ra. Sección',
      'San Francisco Tláloc',
      'Colonia Aviación Autopan',
      'Paisanos',
      'San José Villarreal',
      'Poblado Chulavista (El Chorizo)',
      'Las Trojas',
      'Paraje la Pera',
      'Conjunto Habitacional el Trébol',
      '31 de Octubre',
      'Estación de Apulco',
      'San Jerónimo Coaltepec',
      'Rio de la Soledad',
      'Tacubaya',
      'Benito Juárez (La Playita)',
      'Lázaro Cárdenas (Santana)',
      'Nuevo Pumpuapa (Cereso)',
      'Ex-Hacienda del Copal',
      'Déxtho de Victoria',
      'Juan del Grijalva',
      'Colonia Obrera',
      'San Pablo Huacano',
      'Cantioc',
      'Nuevo Michoacán (Estación Riíto)',
      'Abrevadero',
      'La Magdalena de los Reyes (La Magdalena)',
      'Temastián (La Cantera)',
      'Polvillos (San Bartolo Quinta Sección)',
      'Cuaxoxpan',
      'Fraccionamiento Praderas de la Venta',
      'Sibaniljá Pocolum',
      'Los Condes',
      'La Playa',
      'San Pablo de los Gallos',
      'El Polvorín',
      'Buena Vista (Puxcatán)',
      'San Antonio Xoquitla',
      'El Águila (La Mesa)',
      'Adolfo Moreno',
      'Nuevo Guerrero',
      'Colonia las Arboledas',
      'Cuauhtamingo',
      'Orba (Infiernito)',
      'Paso de la Mina 2da. Sección (Barrial)',
      'Ignacio Manuel Altamirano',
      'Rancho Nuevo de Morelos (De Guadalupe)',
      'San Isidro Monterrosas',
      'Villa Rotaria',
      'La Amistad',
      'Veintiuno de Agosto',
      'Oriente 6ta. Sección (Los Mulatos)',
      'Las Masas (La Luz Masas)',
      'Lomas de Tepemecatl',
      'Residencial las Olas',
      'Zoquiapa',
      'Colonia Reforma Tlalmimilolpan',
      'San Andrés Enguaro',
      'Cebadilla 1ra. Sección',
      'Zaragoza (Puerto de Medina)',
      'San Andrés de la Cal',
      'Las Tazas',
      'Ejido los Huastecos',
      'San José de Abajo',
      'Álamos Tepetitlán',
      'Loma de los Hoyos',
      'Atzalán',
      'Tepeteno de Iturbide',
      'Ciudad Chemuyil',
      'Tandzumadz',
      'Cajelitos',
      'Huitzmaloc',
      'Pezmatlán',
      'El Copetillo',
      'Apalani',
      'Barrio el Vivero',
      'El Embarcadero',
      'Tlacoaxtla',
      'La Higuerita (Colonia San Rafael)',
      'Buen Retiro (El Retiro)',
      'Colonia Gómez Morín',
      'Colinas del Sol',
      'Xochicalco (Cirenio Longares)',
      'Zangarro (Zangarro Nuevo)',
      'Artículo Primero Constitucional',
      'Villa Hermosa',
      'Cala Norte',
      'Colonia la Libertad',
      'Lomas de San Pablo',
      'San Pedro Cacahuatepec',
      'Acachapan y Colmena 3ra. Sección',
      'Ejido Palma (Ejido San Francisco)',
      'Sinahua',
      'Colonia Ecológica Asociación de Lucha Social (Lucha Social)',
      'Coxolico',
      'Saclamantón',
      'San Antonio el Paso',
      'Lázaro Cárdenas (La Purísima)',
      'San Lucas Huarirapeo (La Mesa de San Lucas)',
      'Miguel Hidalgo 2da. Sección B (La Natividad)',
      'San Juan Colón',
      'Alfredo V. Bonfil (Siete Ejidos)',
      'San Juan Hueyapan',
      'Paseos del Marqués',
      'Ixtlilco el Chico',
      'Ocoxaltepec',
      'Felipe Neri (Cuatepec)',
      'Cumbres III',
      'Fraccionamiento Villas de la Llave',
      'San Andrés Playa Encantada (El Podrido)',
      'Barrio de Ensido',
      'Pilotos',
      'Fraccionamiento Villa Jardín',
      'Lomas de San Martín',
      'Tlixco',
      'Santa Clara del Tule',
      'San José Viborillas',
      'Ejido Jesús María',
      'Colonia Sesenta y Dos',
      'El Coloso',
      'San Francisco Jaconá',
      'Barrio de San Miguel',
      'Barrio Chiquichuca',
      'Putlunichuchut (Vista Hermosa)',
      'Doctor Samuel León Brindis',
      'Ahuihuiyuco',
      'Catishtic',
      'San Andrés Cohamiata',
      'Barrio San Diego',
      'Fraccionamiento del Magisterio Tulancinguense',
      'Banus Vallarta (Verde Vallarta)',
      'San Juan Tomasquillo Herradura',
      'Residencial Tajín',
      'Colonia San Francisco (San Francisco)',
      'Fraccionamiento la Noria',
      "Ahuacachahue (Ndog'yo Itún Tichi)",
      'Banderas',
      'Ixtahuiata (La Legua)',
      'Romerillo',
      'San Isidro las Banderas',
      'Tepetitán Reyeshogpan de Hidalgo',
      'La Ibérica (La Gotera)',
      'Colonia San Juan',
      'Álvaro Obregón (Santa Cruz)',
      'Los Bahuises',
      'Jolochero 2da. Sección',
      'La Calera (Nacimientos)',
      'El Triunfo de las Tres Maravillas',
      'San Pedro Huitzapula Norte (Huitzapula Norte)',
      'Tzoeptic',
      'La Unión Ejido Mexcaltepec',
      'Mitzitón',
      'Alcozacán',
      'Granadillas (La Colonia)',
      'Tlahuapa',
      'Plaza Nueva',
      'La Manga 2da. Sección (El Jobal)',
      'Los Mancera',
      'Palo Seco (El Gato)',
      'Colonia Renacimiento',
      'Santa Cruz de la Loma (Tepetates)',
      'Fraccionamiento Laureles Eréndira',
      'Las Nieves',
      'Colonia Bellavista',
      'Nicolás Bravo 5ta. Sección (Punta Brava)',
      'Yibeljoj',
      'San Pedro Matamoros',
      'La Mesa (La Mesa de Cedano)',
      'Ranchería de Pocitos',
      'Patria Nueva (San José el Contento)',
      'San Cristóbal Hidalgo',
      'San Andrés (San Andrés Chichayotla)',
      'San Antonio Chiverías',
      'Colonia 3 de Mayo (La Cruz)',
      'La Glorieta',
      'Ejido San José Xilatzén',
      'Villa Hidalgo (El Cubo)',
      'La Magdalena Tenexpan',
      'Vega del Sol',
      'Tlanipatla',
      'San Martín Itunyoso',
      'Cuchulumtic',
      'Barrio la Tenería',
      'Paso Bonito',
      'Playa Chica',
      'Iztacapa',
      'Acatla',
      'Loma de Guadalupe (La Biznaga)',
      'Metepec Primero',
      'Tanhuixco',
      'El Palacio',
      'Empaque Tarriba',
      'Zapuyo',
      'Cerro Armadillo Grande',
      'Estación Dobladero',
      'Puerto de Providencia',
      'Los Pinos',
      'El Calvario',
      'José Refugio Salcido',
      'José María Morelos (San José)',
      'Los Encinos',
      'Privada del Álamo',
      'Cuapinolito (Azulillo)',
      'Los Dulces Nombres',
      'Sección 23',
      'Mixtlalcingo',
      'Zoyatitla',
      'Tonalapan',
      'Gabriel Hernández (Mancinas)',
      'Higuera de los Vega',
      'San Isidro Xoteapan (San Isidro Texcaltitán)',
      'La Luz de Juárez',
      'Colonia Aquiles Córdoba Morán',
      'Banderas (Guatacalca 2da. Sección)',
      'Ejido Hidalgo',
      'Tepozcuautla',
      'Barrio la Joya',
      'Loma de San Francisco',
      'Arimatea',
      'Las Huertas Tercera Sección',
      'Francisco Primo de Verdad (Ciénega de Mata)',
      'San José el Nuevo',
      'Paso de Núñez (Buenavista)',
      'San Pedro Cuitlapan',
      'Santo Domingo de las Palmas',
      'El Mogote',
      'Colonia Wenceslao Labra',
      'Cementeras del Pital',
      'Tzopilja',
      'Cerro Grande',
      'Oriente 1ra. Sección (Santo Domingo)',
      'Barrio del Cajón',
      'Hidalgo Amajac',
      'Tomaquilapa',
      'Licenciado Gustavo Díaz Ordaz (18 de Marzo)',
      'Zizicazapa',
      'Sergio Villaseñor',
      'San Miguel del Arenal',
      'San José de Abajo [Unidad Habitacional]',
      'Patlicha',
      'San Antonio Xahuento',
      'Colonia Ángel Bocanegra (Adolfo López Mateos)',
      'Colonia Hidalgo (El Tecolote)',
      'Carmen Serdán',
      'Colonia Guadalupe',
      'San Bernardo Peña Blanca',
      'Izotepec',
      'Rancho de las Lomas',
      'Arroyo Chical (Nuevo Arroyo Chicali)',
      'Tres Cerros',
      'Atlamajalcingo del Río',
      'CERESO Nuevo',
      'Pascala del Oro',
      'Ladrilleras de Ocoro',
      'Puerta del Llano',
      'Majastic',
      'Las Letras',
      'Oriente 2da. Sección (Palma Huaca)',
      'Molinos',
      'Maclovio Herrera (Colonia Aviación)',
      'Cumbre de Barranca Honda',
      'Colonia Arboledas (San Andrés)',
      'Fracción San Roque (El Prieto)',
      'San Cristóbal los Nava',
      'Lucio Blanco (Los Gavilanes)',
      'Los Palacios',
      'Oxeloco',
      'El Césped',
      'San Antonio Matlahuacales',
      'Piedra Blanca',
      'Praderas de San Francisco',
      'Alejo González (Bilbao)',
      'Colonia Patria Nueva',
      'Colonia Guadalupana',
      'Xalticpac',
      'Santa Ana Nichi Ejido',
      'Tequila 1ra. Sección (La Aurora)',
      'Primero de Mayo (Chupaderos)',
      'Prados del Rosario',
      'San Antonio Bulujib',
      'Colonia los Remedios',
      'San Antonio de Corrales',
      'El Salvador (Ranchito San José del Carmen)',
      'Potrero de los Sánchez (Estación Techa)',
      'Oriente 2da. Sección (San Cayetano)',
      'Rincón Verde',
      'La Palmita y Anexos (La Presita)',
      'San Carlos Autopan',
      'Zequentic',
      'Eureka de Media Luna (Eureka)',
      'San Vicente (Calpulalpan Tercera Manzana)',
      'Las Yerbitas [Aserradero]',
      'San José de Bernalejo (El Guayabo)',
      'Buenavista (Matasanos)',
      'Río Jordán',
      'Escalerilla Lagunas',
      'Cuesta de Laja',
      'La Sábana',
      'La Trinidad Chimalpa',
      'Playa de la Libertad',
      'Macutzio',
      'Fraccionamiento Riberas de San Jerónimo',
      'El Silencio',
      'Santa Cruz Texmalaquilla',
      'Lomas de Santa Cruz',
      'El Mollejon',
      'Tacuba Nueva',
      'Cálido',
      'Paso de Pirules',
      'Alfonso Garzón [Granjas Familiares]',
      'San José de Durán (Los Troncoso)',
      'Ococh',
      'Boquilla de Babisas (La Boquilla de Conchos)',
      'Colonia Constitución',
      'Tulimanca',
      'Nueva Libertad (El Colorado)',
      'El Colegio',
      'Colonia Jordán',
      'Pechucalco 2da. Sección (Las Cruces)',
      'Xolostitla de Morelos (Xolostitla)',
      'La Independencia (Las Pilas)',
      'Lázaro Cárdenas (Chilil)',
      'Cuesta Amarilla',
      'Ignacio Allende (Concepción)',
      'Ejido General Leandro Valle',
      'El Llano del Compromiso',
      'Arroyo Zapotillo',
      'Tenexio',
      'La Esperanza [Granjas Familiares]',
      'Bautista Chico',
      'Profresor Roberto Barrios',
      'San José de Rancho Nuevo (Los Arrieros)',
      'El Pedregal',
      'Buenavista Tetela',
      'Barrio Nuevo de los Muertos',
      'Progreso (Campo 47)',
      'Colonia el Mirador',
      'Soyata',
      'La Palmita (La Palmita de San Gabriel)',
      'Colonia Francisco Javier Mina',
      'Cupuán del Río',
      'Colonia Miguel Hidalgo',
      'Ignacio Bastida (Santa Catarina Tlayca)',
      'Santiago Ixtaltepec',
      'Rincón del Porvenir',
      'El Escribano',
      'Matacapan',
      'Los Achotes',
      'Pochálcatl',
      'Zolonquiapa',
      'Octeyuco Dos Mil',
      'Santa Ana de Guerrero (El Cascabel)',
      'Arena 6ta. Sección (La Bolsa)',
      'Nuevo Cereso Regional de Altamira',
      'El Ámbar',
      'Fraccionamiento Paraíso Real',
      'El Canario',
      'San Miguel Amazcala',
      'Morelia (Victórico Rodolfo Grajales)',
      'Reforma y Planada',
      'Boquiapa',
      'Colonia de Guadalupe',
      'Jagüey Blanco',
      'Fraccionamiento Colinas Universidad',
      'Estrella de Belén',
      'Jerusalén',
      'Los Caños',
      'Ventoquipa',
      'Colonia Independencia',
      'Fraccionamiento los Álamos',
      'La Isla (Kilómetro 10)',
      'Chipahuatlán',
      'Río Seco 2da. Sección (Santiaguito)',
      'San Cristóbal Chacón',
      'El Ojuelo',
      'Las Mercedes [Agropecuaria]',
      'El Barrido',
      'Nigromante',
      'Colonia el Pirame',
      'San Juan Tezongo',
      'Chimhucum',
      'El Triunfo 1ra. Sección (Cardona)',
      'Estación de San Francisco',
      'Chimalpa Viejo',
      'Pechucalco',
      'San José del Caliche (El Caliche)',
      'Colonia Rincón Villa del Valle',
      'Arroyo Palenque',
      'Aurora Ermita',
      'Coyahualco',
      'Cantinela',
      'Los Naranjos Esquipulas',
      'Las Golondrinas [Fraccionamiento]',
      'Colonia la Central',
      'Turuachi',
      'Paseos de la Pradera',
      'Xiloxochico de Rafael Ávila Camacho',
      'Cerrito de Jaral',
      'El Cuitzillo Grande',
      'Nuevo Xcán',
      'La Albarrada (San Francisco la Albarrada)',
      'Los Garcías',
      'Las Pilas',
      'Montaña Monarca (Punta Altozano)',
      'La Concepción (La Concha)',
      'General Juan José Baz (San José del Corral)',
      'Chulum Cárdenas',
      'Caxitepec',
      'Octavio Paz',
      'Estación la Piedad',
      'Santa Cruz Otlatla',
      'Agua Señora',
      'Ejido la Piña (Maromilla)',
      'Ahuexotitlán',
      'Cerro Quemado',
      'Tentic',
      'Doxteje Centro',
      'Sección del Cerrito',
      'General Lauro G. Caloca (El Rascón)',
      'Tierras Coloradas (San Pedro)',
      'Emiliano Zapata (Casahuates)',
      'Lacapan Camallagne',
      'Jiquilpan (Estación Bonanza)',
      'Nuevo Naranjo',
      'San Pedro Denxhi Centro',
      'Nexticapan',
      'Colonia Padre Hidalgo',
      'San Antonio de los Tepetates',
      'San Felipe Tílpam',
      'San Félix Rijo',
      'Barranca de Otates (Barranca de Otatán)',
      'La Planada (El Arenal)',
      'San Felipe Sultepec',
      'Los Benitos',
      'Las Trancas',
      'Ojite Rancho Nuevo',
      'Santa Fe y la Mar',
      'De Parral',
      'Fraccionamiento Villas de Guanajuato',
      'Barrio el Boncho',
      'Miguel Utrilla (Los Chorros)',
      'Ojo de Agua de Morán',
      'El Bellote (Miguel de la Madrid)',
      'Jomanichim',
      'Tazaquil',
      'San José Yashitinín',
      'Zapote de Palomas',
      'Llano de Agua',
      'Arvenza Uno',
      'Colonia 3 de Mayo',
      'San Miguel Aguacomulican',
      'Cuauhtamazaco',
      'Tzinil',
      'Fraccionamiento Privadas del Sol',
      'Ocotequila',
      'Santa María Xigui',
      'General Alatriste (San Joaquín)',
      'Jolsibaquil',
      'Residencial Arboledas',
      'Paseos del Valle [Fraccionamiento]',
      'Concepción Caro',
      'San Antonio el Llanito',
      'La Higuerilla (Los Lirios)',
      'Chiquinival',
      'Santiago Centro',
      'Guadalupe Calderón',
      'J. Jesús González Ortega (San Mateo)',
      'Zacate Colorado Segundo (Fraternidad)',
      'Vallejo (Porvenir Vallejo)',
      'Los Liros',
      'Nexpan',
      'Ahuehueyo Primero Centro',
      'Barrancas y Amate 3ra. Sección',
      'El Cabi',
      'Agua Blanca',
      'Nicolás Blanco (San Pancho)',
      'Techichili',
      'Peña del Panal',
      'El Pueblito (Garbanzal)',
      'La Corregidora',
      'Bochojbo Alto',
      'San José Manzanitos',
      'Misija',
      'Colonia Fraccionamiento el Puente',
      'Lomas de Romero',
      'Lomas de Tlatelolco',
      'Ciudad Guzmán [CERESO]',
      'Kilómetro 40',
      'Barrio de Guadalupe del Mezquitillo',
      'La Loma Cuexcontitlán',
      'El Metlapil',
      'San Benito Encinal',
      'Santiago Tlacochcalco',
      'Metlac Hernández (Metlac Primero)',
      'Ejido San Cristóbal',
      'Concentración 5 de Febrero',
      'Cerro del Murciélago',
      'Tierra Negra',
      'La Rinconada',
      'Tlatilpa',
      'Nueva Frontera',
      'San Martín Mazateopan',
      'San Cristóbal Xochimilpa',
      'San Isidro Palotal',
      'Sargento López 2da. Sección (El Chuzo)',
      'La Estancia Sector Uno (La Estancia)',
      'Unidad Grajales INFONAVIT',
      'El Tintal',
      'Loma de Yerbabuena',
      'Tutepec',
      'El Capulín (La Nueva Pochota)',
      'San José Buena Vista',
      'Banco Nacional',
      'Rinconadas del Venado I',
      'Rincón de Aguirre',
      'Miravalle',
      'Acuaco',
      'La Presita Segundo Cuartel',
      'Adjuntas del Río',
      'Colonia los Cerritos',
      'San Juan Zapotitlán',
      'Santa Rosa Segundo',
      'Rincón de los Reyes',
      'Praderas del Potrero',
      'Corral de Piedra',
      'Zoyotla',
      'Santa Isabel [Fraccionamiento]',
      'Ejido Netzahualcóyotl',
      'Manzana Segunda',
      'La Palma (Las Palmas)',
      'La Toma',
      'El Olvera',
      'Paraje Trejo (El Chaparral)',
      'Ejido el Castillo',
      'Tocoy',
      'La Nueva Era',
      'Ocuiltzapoyo',
      'La Sala (La Caliente)',
      'Joconoxtle (La Tuna)',
      'Álvaro Obregón 2da. Sección (El Lechugal)',
      'Guadalupe Morelos',
      'La Mezquitera',
      'Unidad Habitacional Mariano Matamoros',
      'Manuel Edgardo Ávalos (San Isidro)',
      'Tinajas 1ra. Sección',
      'El Ramal (Porvenir)',
      'Molino de San José',
      'San José Independencia',
      'Fraccionamiento del Valle',
      'Tierra Larga (Campo Nuevo)',
      'Ignacio Zaragoza 1ra. Sección',
      '10 de Abril',
      'El Espinalillo',
      'Santa Rosa (El Huizache)',
      'Hoya de Cintora (La Hoya de Arriba)',
      'El Recodo',
      'General Luis Felipe Domínguez Suárez',
      'Pugchén Mumuntic',
      'Cantón Villaflor',
      'San Julio',
      'Coahuixtla',
      'El Camalote',
      'Yopi',
      'Tlacuilola',
      'Rinconadas del Bosque',
      'Santa Ana Chapitiro',
      'Loma Chapultepec',
      'Campo de Águila',
      'Buenos Aires (San Isidro)',
      'Jarillas',
      'Manzana la Cofradía',
      'Santiago Chilixtlahuaca',
      'Texocoyohuac',
      'Llano de las Flores (Barrio del Hueso)',
      'Colonia el Mirador (Llano del Ejido)',
      'San José Boxay',
      'Mariscal Subikuski',
      'Lodo Grande',
      'Pintillo',
      'Jesús Tepactepec',
      'Fraccionamiento la Mezquitera',
      'Cuartel la Mesa (El Asoleadero)',
      'Oxinam',
      'El Recodo de San José Axalco',
      'Puentecillas (Tercera Manzana de Zirahuato)',
      'Calpanería Atezquilla',
      'Arroyo San Isidro',
      'La Esperanza (El Zapotal)',
      'Pueblo Madero (El Playón)',
      'Barrio de San Ramón',
      'Santa Fe de la Purísima',
      'El Tigre (Segunda Manzana de Crescencio Morales)',
      'Tetelcingo',
      '18 de Marzo',
      'Ejido la Guayana (Rancho Seco)',
      'Pueblo Benito García',
      'Santa Rosa Caxtlahuaca',
      'Apantla',
      'Barrio de Puentecillas',
      'Colonia San Luis',
      'Corralejo de Arriba',
      'Tonalapa',
      'La Mintzita (Piedra Dura)',
      'Nenehuaca',
      'Aguas Buenas',
      'Colonia Nueva Revolución',
      'El Moreno (San Miguel Moreno)',
      'Derramadero Segundo (Infiernillo)',
      'Las Primaveras [Invernadero]',
      'Miguel Negrete',
      'Camémbaro',
      'San Clemente de Lima',
      'El Siviral (Jigica)',
      'José Colomo',
      'Colonia Rafael Corrales Ayala',
      'Doxteje Barrio Primero',
      'Las Pintas',
      'Villa Tejúpam de la Unión',
      'Jalmolonga (La Hacienda)',
      'Cuto de la Esperanza',
      'El Capulo (La Quebradora)',
      'San Andrés Yahuitlalpan',
      'Colonia Alborada',
      'Ejido Miraflores',
      'Fraccionamiento Carlos Salinas de Gortari',
      'San José Mezapa Sección I',
      'Colonia la Calzada (La Olla)',
      'Unión Agropecuarios Lázaro Cárdenas del Norte',
      'Candelaria Portezuelo',
      'Colonia Palo Prieto (Chipitongo)',
      'Peña Colorada',
      'Tetlatzinga',
      'Rinconadas de San Francisco',
      'Nuevo Carmen Tonapac',
      'Las Plazuelas',
      'El Progreso Hidalgo',
      'Las Torres de Guadalupe',
      'San Miguel Tlamahuco',
      'El Huidero',
      'San José Barbabosa',
      'Rancho Grande',
      'Caobanal 1ra. Sección (Mezcalapa)',
      'Rancho de Guadalupe',
      'Estación Corralejo',
      'Tlacpac',
      'Colonia el Florido',
      'Tlamaya Grande',
      'Loma del Refugio',
      'Unidos Avanzamos',
      'Moyotepec (Moyotepec de Juárez)',
      'Tlaminca',
      'Cenobio Aguilar (La Trinidad)',
      'Cruz Verde',
      'Joltealal',
      'Rancho Nuevo del Llanito',
      'Oriental de Zapata',
      'Colonia San Francisco',
      'Nuevo Refugio de Afuera (Nuevo Refugio)',
      'Iratzio',
      'San Pedro Tlapacoyan',
      'Nueva Ameyalco',
      'Puerto de Chiquihuite',
      'El Bajío (La Laguna)',
      'Estacion Bamoa',
      'Ampliación Tezoyuca',
      'San Simón Atzitzintla',
      'San Juan Tuxco',
      'Maya Tecún I',
      'Daxtha',
      'Teocalco',
      'Honey',
      'Nuevo Necaxa',
      'Espinal',
      'Texalpan de Abajo',
      'Villa Azueta',
      'San Lucas Totolmaloya',
      'San Juan Totolcintla',
      'Juamave',
      'Chimaltitán',
      'San Rafael Tecario',
      'San Antonio Primero',
      'Tulillos de Abajo',
      'San Nicolás Temascatío',
      'San Juan del Llanito',
      'San Jose de los Olvera',
      'La Ceja',
      'San Miguel Totoltepec',
      'Arroyo Vista Hermosa',
      'La Unidad Huitzizilapan',
      'Rincón de Curungueo',
      'San Miguel Ixtapan',
      'Estación Tuzantán',
      'Estación Huehuetán',
      'San Bartolo Coyotespec',
      'Tecoanapa',
      'San Juan Sautla',
      'Santa Lucía Ocotlán',
      'San Francisco Tutla',
      'Santa María Acatepec',
      'San Juan Bautista la Raya',
      'Macvilhó',
      'El Guayabito',
      'El Coecillo',
      'Ejido Zaragoza',
      'Santiago Tepopula',
      'San Lucas Atzala',
      'San Francisco Cuapa',
      'Zacapechpan',
      'San Marcos Contla',
      'Zacapu',
      'Caurio de Guadalupe',
      'Tlacolulan',
      'Cruz de Huanacaxtle',
      'Ejido de San Mateo Coapexco',
      'Ejido de la Finca',
      'San Agustín Tlaxco',
      'Santa María Nenetzintla',
      'San Antonio Tlacamilco',
      'Juan de la Granja',
      'Bara de Chachalacas',
      'San Jerónimo Coyula',
      'San Luis Ajajalpan',
      'Actipan',
      'La Compañia',
      'San Sebastián Alcomunga',
      'Colonia del Sol',
      'San Isidro de los Sauces',
      'Orduña de Arriba',
      'Santiago Jicayán',
      'Nueva Sesecapa',
      'Santiago Pojcol',
      'Yecapixteca',
      'Aquiles Córdova Morán',
      'Nezahualcóyotl',
      'San José las Palmas',
      'Tequisistlán Primero',
      'Prados de Santa Rosa',
      'Monclova Segundo Sector',
      'Parrilla II',
      'San Lorenzo Itzícuaro',
      'Ejido de Coscomate del Progreso',
      'Ejido de Guadalupe',
      'Lomas de Tenopalco',
      'San Martín Ejido',
      'Candelaria',
      'Santa Cruz Quilehtla',
      'Tres Valles',
      'Ixhuatlán del Sureste',
      'Tarímbaro',
    ],
  },
  {
    country: 'Malaysia',
    cities: [
      'Padang Mat Sirat',
      'Ayer Hangat',
      'Kuah',
      'Jerantut',
      'Raub',
      'Batu Pahat',
      'Parit Raja',
      'Pekan Nenas',
      'Pontian Kechil',
      'Kampung Pasir Gudang Baru',
      'Kota Tinggi',
      'Taman Senai',
      'Kulai',
      'Skudai',
      'Johor Bahru',
      'Kluang',
      'Yong Peng',
      'Mersing',
      'Segamat',
      'Tangkak',
      'Muar',
      'Bakri',
      'Labis',
      'Chaah',
      'Kuala Selangor',
      'Batang Berjuntai',
      'Batu Arang',
      'Shah Alam',
      'Klang',
      'Cukai',
      'Kuala Lipis',
      'Papar',
      'Kota Kinabalu',
      'Donggongon',
      'Putatan',
      'Kinarut',
      'Ranau',
      'Semporna',
      'Labuan',
      'Beaufort',
      'Lahad Datu',
      'Sandakan',
      'Keningau',
      'Tawau',
      'Paka',
      'Kertih',
      'Kulim',
      'Bagan Serai',
      'Bedong',
      'Simpang Empat',
      'Taiping',
      'Kuala Kangsar',
      'Ipoh',
      'Gua Musang',
      'Kuala Terengganu',
      'Marang',
      'Tampin',
      'Alor Gajah',
      'Kampung Ayer Keroh',
      'Sungai Udang',
      'Malacca',
      'Pelabuhan Klang',
      'Banting',
      'Jenjarum',
      'Semenyih',
      'Seremban',
      'Port Dickson',
      'Sepang',
      'Bahau',
      'Kuala Pilah',
      'Buloh Kasap',
      'Kampung Tekek',
      'Pekan',
      'Mentekab',
      'Temerluh',
      'Batu Feringgi',
      'Butterworth',
      'Perai',
      'Bukit Mertajam',
      'Nibong Tebal',
      'Parit Buntar',
      'Tasek Glugor',
      'George Town',
      'Kampung Bukit Tinggi, Bentong',
      'Serendah',
      'Rawang',
      'Petaling Jaya',
      'Kuala Lumpur',
      'Ampang',
      'Sabak Bernam',
      'Sungai Besar',
      'Kuantan',
      'Tanah Rata',
      'Batu Gajah',
      'Kampar',
      'Tapah Road',
      'Bidur',
      'Mukah',
      'Lumut',
      'Teluk Intan',
      'Gurun',
      'Sungai Petani',
      'Kepala Batas',
      'Ketereh',
      'Pulai Chondong',
      'Tanah Merah',
      'Kuching',
      'Simanggang',
      'Sarikei',
      'Sibu',
      'Kangar',
      'Kuala Perlis',
      'Jitra',
      'Kuala Kedah',
      'Alor Setar',
      'Tumpat',
      'Pasir Mas',
      'Kota Bharu',
      'Kuala Besut',
      'Jertih',
      'Kudat',
      'Kota Belud',
      'Kapit',
      'Bintulu',
      'Lawas',
      'Limbang',
      'Marudi',
      'Miri',
      'Ulu Tiram',
      'Telaga Batu',
      'Tanjung Tokong',
      'Tanjung Sepat',
      'Sungai Pelek New Village',
      'Pulau Sebang',
      'Permatang Kuching',
      'Peringat',
      'Ladang Seri Kundang',
      'Pantai Remis',
      'Kuang',
      'Kuala Sungai Baru',
      'Klebang Besar',
      'Kelapa Sawit',
      'Kampung Tanjung Karang',
      'Kampung Sungai Ara',
      'Kampung Simpang Renggam',
      'Kampong Pangkal Kalong',
      'Kampong Masjid Tanah',
      'Kampong Kadok',
      'Kampong Dungun',
      'Kampung Bukit Baharu',
      'Kampung Batu Feringgi',
      'Kampung Baru Subang',
      'Kampung Baharu Nilai',
      'Kampong Baharu Balakong',
      'Kampung Ayer Molek',
      'Juru',
      'Bukit Rambai',
      'Bentong Town',
      'Bemban',
      'Batu Berendam',
      'Bagan Pulau Ketam',
      'Putrajaya',
      'Kampung Kilim',
      'Kampung Kok',
      'Kuala Teriang',
      'Kampung Kuala Besut',
      'Kampung Lemal',
      'Bandar Labuan',
      'Data Kakus',
      'Subang Jaya',
      'Taman Rajawali',
      'Lidung Jelo',
      'Long Ampan Aing or Abanang',
      'Putra Heights',
      'Pantai Cenang',
    ],
  },
  {
    country: 'Mozambique',
    cities: [
      'Xai-Xai',
      'Dondo',
      'Chinde',
      'Macia',
      'Tete',
      'Ressano Garcia',
      'Quelimane',
      'Pemba',
      'Nampula',
      'Nacala',
      'Montepuez',
      'Mocímboa',
      'Mozambique',
      'Maxixe',
      'Matola',
      'Maputo',
      'Manhiça',
      'Mandimba',
      'Lichinga',
      'Inhambane',
      'Cuamba',
      'Chokwé',
      'Chimoio',
      'Chibuto',
      'Beira',
      'António Enes',
      'Mutuáli',
      'Bairro 25 de Junho A',
      'Bairro 25 de Junho B',
    ],
  },
  {
    country: 'Namibia',
    cities: [
      'Katima Mulilo',
      'Bagani',
      'Windhoek',
      'Warmbad',
      'Usakos',
      'Tsumeb',
      'Tses',
      'Swakopmund',
      'Rundu',
      'Rehoboth',
      'Outjo',
      'Otjiwarongo',
      'Otjimbingwe',
      'Otavi',
      'Oshikango',
      'Oshakati',
      'Oranjemund',
      'Opuwo',
      'Ongwediva',
      'Ongandjera',
      'Ondangwa',
      'Omuthiya',
      'Omaruru',
      'Okakarara',
      'Okahao',
      'Okahandja',
      'Nkurenkuru',
      'Mariental',
      'Maltahöhe',
      'Lüderitz',
      'Khorixas',
      'Keetmanshoop',
      'Katutura',
      'Karibib',
      'Karasburg',
      'Hoachanas',
      'Henties Bay',
      'Grootfontein',
      'Gobabis',
      'Eenhana',
      'Bethanie',
      'Aranos',
      'Arandis',
      'Walvis Bay',
      'Outapi',
    ],
  },
  {
    country: 'New Caledonia',
    cities: [
      'Yaté-Barrage',
      'Wé',
      'Wala',
      'Voh',
      'Vao',
      'Touho',
      'Thio',
      'Tadine',
      'Poya',
      'Poum',
      'Pouembout',
      'Pouébo',
      'Ponérihouen',
      'Poindimié',
      'Païta',
      'Ouégoa',
      'Nouméa',
      'Mont-Dore',
      'La Foa',
      'Koumac',
      'Koné',
      'Kaala-Gomén',
      'Houaïlou',
      'Hienghène',
      'Fayaoué',
      'Dumbéa',
      'Canala',
      'Bourail',
      'Bouloupari',
    ],
  },
  {
    country: 'Niger',
    cities: [
      'Birnin Gaouré',
      'Birni N Konni',
      'Zinder',
      'Tillabéri',
      'Tibiri',
      'Tessaoua',
      'Téra',
      'Tchintabaraden',
      'Tanout',
      'Tahoua',
      'Say',
      'Ouallam',
      'Niamey',
      'Nguigmi',
      'Mirriah',
      'Mayahi',
      'Matamey',
      'Maradi',
      'Maïné Soroa',
      'Magaria',
      'Madarounfa',
      'Madaoua',
      'Loga',
      'Kollo',
      'Keïta',
      'Kantché',
      'Illéla',
      'Guidan Roumdji',
      'Gouré',
      'Gaya',
      'Filingué',
      'Dosso',
      'Dogondoutchi',
      'Diffa',
      'Dakoro',
      'Bouza',
      'Bilma',
      'Ayorou',
      'Arlit',
      'Alaghsas',
      'Aguié',
      'Agadez',
      'Abalak',
      'Tchirozérine',
    ],
  },
  { country: 'Norfolk Island', cities: ['Kingston'] },
  {
    country: 'Nigeria',
    cities: [
      'Zuru',
      'Zurmi',
      'Zungeru',
      'Zonkwa',
      'Zing',
      'Zaria',
      'Zango',
      'Zalanga',
      'Zakirai',
      'Zaki Biam',
      'Zadawa',
      'Yuli',
      'Yola',
      'Yenagoa',
      'Yelwa',
      'Yashikira',
      'Yandev',
      'Yanda Bayo',
      'Yamrat',
      'Yajiwa',
      'Yabo',
      'Wuyo',
      'Wushishi',
      'Wurno',
      'Wukari',
      'Wudil',
      'Wawa',
      'Wase',
      'Wasagu',
      'Warri',
      'Wannune',
      'Wamba',
      'Wamako',
      'Wagini',
      'Vom',
      'Vandeikya',
      'Uyo',
      'Utu Etim Ekpo',
      'Ozoro',
      'Uselu',
      'Uruobo-Okija',
      'Urualla',
      'Uromi',
      'Uquo',
      'Ukpor',
      'Ungogo',
      'Umuneke-Ngor',
      'Umunede',
      'Umulona',
      'Umuelemai',
      'Umuahia',
      'Ukpo',
      'Ukata',
      'Ughelli',
      'Ugep',
      'Ugba',
      'Uga',
      'Udi',
      'Ubiaja',
      'Uba',
      'Tureta',
      'Tudun Wada',
      'Tsanyawa',
      'Toungo',
      'Toto',
      'Toro',
      'Tokombere',
      'Tofa',
      'Tegina',
      'Tede',
      'Taura',
      'Tambuwal',
      'Talata Mafara',
      'Talasse',
      'Takum',
      'Bunza',
      'Takai',
      'Tafawa Balewa',
      'Suya',
      'Surulere',
      'Sumaila',
      'Sule Tankarkar',
      'Suleja',
      'Song',
      'Sokoto',
      'Soba',
      'Siluko',
      'Silame',
      'Somolu',
      'Shinkafi',
      'Shendam',
      'Shelleng',
      'Share',
      'Shanono',
      'Shani',
      'Shanga',
      'Saki',
      'Shagari',
      'Shagamu',
      'Shaffa',
      'Serti',
      'Sauri',
      'Sarkin Pawa',
      'Sapele',
      'Sankwala',
      'Sankera',
      'Sandamu',
      'Samamiya',
      'Sakaba',
      'Sagbama',
      'Safana',
      'Sade',
      'Sabuwa',
      'Sabongida-Ora',
      'Sabon Birni',
      'Runka',
      'Ruma',
      'Roni',
      'Rogo',
      'Riyom',
      'Riti',
      'Ririwai',
      'Ringim',
      'Rimin Gado',
      'Rimi',
      'Rijau',
      'Ribah',
      'Rano',
      'Rann',
      'Saminaka',
      'Rabah',
      'Potiskum',
      'Port Harcourt',
      'Pindiga',
      'Patigi',
      'Patani',
      'Panyam',
      'Pantisawa',
      'Pankshin',
      'Paiko',
      'Ozubulu',
      'Oyo',
      'Oye-Ekiti',
      'Oyan',
      'Owu-Isin',
      'Owode',
      'Owo',
      'Owerri',
      'Owena',
      'Owa-Oyibu',
      'Otuocha',
      'Otun-Ekiti',
      'Otukpa',
      'Otu-Jeremi',
      'Otor-Udu',
      'Otu',
      'Ota',
      'Otan Ayegbaju',
      'Osu',
      'Osogbo',
      'Oshodi',
      'Afor-Oru',
      'Oron',
      'Orodo',
      'Orlu',
      'Orita-Eruwa',
      'Orerokpe',
      'Ore',
      'Oporoma',
      'Opobo',
      'Ikot Abasi',
      'Opi',
      'Onyedega',
      'Onitsha',
      'Ondo',
      'Omuo-Ekiti',
      'Omu-Aran',
      'Omoku',
      'Olupona',
      'Oleh',
      'Okpoga',
      'Okwe',
      'Okuta',
      'Okrika',
      'Okpoma',
      'Okpo',
      'Okoroete',
      'Okopedi',
      'Oko Ita',
      'Okigwe',
      'Oke-Oyi',
      'Okene',
      'Oke Mesi',
      'Oke Ila',
      'Okeho',
      'Okehi',
      'Oke-Agbe',
      'Okada',
      'Oka',
      'Oju',
      'Ojoto',
      'Oji River',
      'Ohafia-Ifigh',
      'Ogwashi-Uku',
      'Oguta',
      'Ogurugu',
      'Oguma',
      'Ogu',
      'Ogoja',
      'Ogidi',
      'Ogbomoso',
      'Ogbere',
      'Ogbede',
      'Ogaminana',
      'Offa',
      'Odukpani',
      'Odot',
      'Odoro Ikpe',
      'Odogbolu',
      'Odo-Ere',
      'Odeda',
      'Ode',
      'Ode-Ekiti',
      'Ochobo',
      'Obudu',
      'Obubra',
      'Obonoma',
      'Obolo-Eke (1)',
      'Obollo-Afor',
      'Obiaruku',
      'Obi',
      'Nzam',
      'Nung Udoe',
      'Numan',
      'Nto Edino',
      'Nteje',
      'Nsukka',
      'Isiokpo',
      'Nnewi',
      'Nkwerre',
      'Nkpor',
      'Mkpat Enin',
      'Ningi',
      'Nguzu Edda',
      'Nguru',
      'Ngurore',
      'Ngo',
      'Ngala',
      'Wara',
      'New Shagunnu',
      'New Bussa',
      'Neni',
      'Nembe',
      'Ndeaboh',
      'Nasarawa Egon',
      'Nasarawa',
      'Nafada',
      'Mutum Biyu',
      'Mushin',
      'Musawa',
      'Mubi',
      'Moriki',
      'Mopa',
      'Moniya',
      'Monguno',
      'Mangu',
      'Mokwa',
      'Modakeke',
      'Bolorunduro',
      'Misau',
      'Miringa',
      'Minna',
      'Minjibir',
      'Miga',
      'Michika',
      'Imeko',
      'Mgbidi',
      'Mberubu',
      'Mbalano',
      'Mayo-Belwa',
      'Machina',
      'Matazu',
      'Mashi',
      'Maru',
      'Marte',
      'Maradun',
      'Mani',
      'Mando',
      'Malumfashi',
      'Mallam Sidi',
      'Mallammaduri',
      'Makurdi',
      'Makoko',
      'Makera',
      'Makarfi',
      'Maiyama',
      'Maikunkele',
      'Maiha',
      'Maigatari',
      'Maigana',
      'Maiduguri',
      'Mai’Adua',
      'Mahuta',
      'Magumeri',
      'Mafa',
      'Madobi',
      'Madara',
      'Madala',
      'Madagali',
      'Lokoja',
      'Lere',
      'Lemu',
      'Lekki',
      'Lau',
      'Lapai',
      'Langtang',
      'Lamurde',
      'Lame',
      'Lalupon',
      'Lagos',
      'Lafiagi',
      'Lafia',
      'Kwolla',
      'Kwoi',
      'Kwaya Kusar',
      'Kwatarkwashi',
      'Kware',
      'Kwali',
      'Kwale',
      'Kutigi',
      'Kuta',
      'Kusheriki',
      'Kusada',
      'Kurfi',
      'Kura',
      'Kunchi',
      'Kumo',
      'Kumbotso',
      'Kumagunnam',
      'Kukawa',
      'Kuje',
      'Kujama',
      'Kpor',
      'Koton-Karfe',
      'Kosubosu',
      'Kontagora',
      'Konkwesso',
      'Konduga',
      'Koko',
      'Kiyawa',
      'Kisi',
      'Kiru',
      'Karkarna',
      'Kiri Kasamma',
      'Kirfi',
      'Auyo',
      'Kibiya',
      'Kende',
      'Kafin Hausa',
      'Keffi',
      'Kebbe',
      'Keana',
      'Kazaure',
      'Kauru',
      'Kaura Namoda',
      'Kaugama',
      'Katsina-Ala',
      'Katsina',
      'Katcha',
      'Warji',
      'Katagum',
      'Karim Lamido',
      'Kari',
      'Karaye',
      'Kano',
      'Kankia',
      'Kankara',
      'Kamba',
      'Kaltungo',
      'Kalgo',
      'Kajuru',
      'Kaita',
      'Kangiwa',
      'Kaiama',
      'Kagoro',
      'Kagarko',
      'Kagara',
      'Kafur',
      'Kafin Madaki',
      'Kafarati',
      'Kafanchan',
      'Lissam',
      'Kaduna',
      'Kachia',
      'Kabo',
      'Kabba',
      'Jos',
      'Jobele',
      'Jimeta',
      'Jikamshi',
      'Jibia',
      'Jega',
      'Jebba',
      'Angware',
      'Jama’are',
      'Jalingo',
      'Jakusko',
      'Jajimaji',
      'Lajere',
      'Jahun',
      'Jada',
      'Iye-Ekiti',
      'Iyara',
      'Iwo',
      'Iwere-Ile',
      'Itu',
      'Itori',
      'Obarike-Ito',
      'Itigidi',
      'Itas',
      'Ita-Ogbolu',
      'Isu',
      'Issele-Uku',
      'Isiokolo',
      'Isieke',
      'Isua',
      'Iseyin',
      'Ise-Ekiti',
      'Isara',
      'Isanlu-Itedoijowa',
      'Isanlu',
      'Isa',
      'Iresa-Adu',
      'Ode-Irele',
      'Iragbiji',
      'Ipoti',
      'Ipokia',
      'Ipetumodu',
      'Iperu',
      'Iperindo',
      'Inisa',
      'Ingawa',
      'Ilorin',
      'Iloffa',
      'Ilobu',
      'Illushi',
      'Illela',
      'Ilesa',
      'Ile-Oluji',
      'Ilemona',
      'Ile-Ogbo',
      'Ilawe-Ekiti',
      'Ilaro',
      'Ilare',
      'Ila Orangun',
      'Ikoyi-Ile',
      'Ikoyi',
      'Ikotun',
      'Ikot Nakanda',
      'Ikot Ibritam',
      'Ikot Ekpene',
      'Afaha Ikot Ebak',
      'Ikorodu',
      'Ikom',
      'Ikole-Ekiti',
      'Ikirun',
      'Ikire',
      'Ikere-Ekiti',
      'Ikenne',
      'Ikem',
      'Ikeja',
      'Ikare',
      'Ikara',
      'Ikang',
      'Iju',
      'Ijero-Ekiti',
      'Ijebu-Ode',
      'Ijebu-Jesa',
      'Ijebu-Igbo',
      'Ijebu-Ife',
      'Iho',
      'Ihiala',
      'Iguobazuwa',
      'Igumale',
      'Igede-Ekiti',
      'Igbo-Ukwu',
      'Igbor',
      'Igbo-Ora',
      'Igbokoda',
      'Igboho',
      'Igbeti',
      'Igbekebo',
      'Igbara-Oke',
      'Igbara-Odo',
      'Igarra',
      'Igabi',
      'Ifon',
      'Ifo',
      'Ifetedo',
      'Ile-Ife',
      'Ifaki',
      'Idu',
      'Idogbo',
      'Idiroko',
      'Idi-Ayunre',
      'Ido-Ekiti',
      'Ido',
      'Idanre',
      'Idah',
      'Icheu',
      'Ibokun',
      'Iboko',
      'Ibi',
      'Ibeto',
      'Ibagwa-Aka',
      'Ibadan',
      'Hunkuyi',
      'Hong',
      'Holma',
      'Hinna',
      'Hadejia',
      'Gwoza',
      'Ugwolawo',
      'Gwiwa',
      'Gwio Kura',
      'Gwasoro',
      'Gwarzo',
      'Gwaram',
      'Gwantu',
      'Gwandu',
      'Gwagwalada',
      'Gwadabawa',
      'Guyuk',
      'Gusau',
      'Guri',
      'Gummi',
      'Gumel',
      'Gulma',
      'Gulak',
      'Gujba',
      'Gudumbali',
      'Gubio',
      'Goronyo',
      'Gorgoram',
      'Gora',
      'Goniri',
      'Gombi',
      'Gombe',
      'Giwa',
      'Girei',
      'Gidan Madi',
      'Giade',
      'Gezawa',
      'Gembu',
      'Gella',
      'Geidam',
      'Buruku',
      'Gbongan',
      'Gboko',
      'Gbajimba',
      'Gaya',
      'Gassol',
      'Gashua',
      'Garun Malam',
      'Garko',
      'Garki',
      'Garaku',
      'Ganye',
      'Gantsa',
      'Gandi',
      'Gamboru',
      'Gamawa',
      'Gakem',
      'Gajiram',
      'Gagarawa',
      'Gada',
      'Gabarin',
      'Funtua',
      'Fugar',
      'Fufu',
      'Fufore',
      'Fika',
      'Fiditi',
      'Faskari',
      'Ezza-Ohu',
      'Ezzamgbo',
      'Etinan',
      'Ete',
      'Esuk Oron',
      'Eruwa',
      'Epe',
      'Enwang',
      'Umunze',
      'Enugu-Ukwu',
      'Enugu-Ezike',
      'Enugu',
      'Enagi',
      'Emure-Ekiti',
      'Emuoha',
      'Elele',
      'Ekpoma',
      'Eket',
      'Ekeremor',
      'Ejirin',
      'Ejigbo',
      'Ehor',
      'Eha Amufu',
      'Egbeda',
      'Egbe',
      'Effurun',
      'Efon-Alaaye',
      'Effium',
      'Effraya',
      'Ede',
      'Ebute-Metta',
      'Ebute Ikorodu',
      'Eberi',
      'Ebem Ohafia',
      'Dutsin-Ma',
      'Dutsi',
      'Dutsen Wai',
      'Dutse',
      'Malam Madori',
      'Duku',
      'Dukku',
      'Donga',
      'Doma',
      'Disina',
      'Dindima',
      'Dikwa',
      'Dikenafai',
      'Dengi',
      'Demsa',
      'Dekina',
      'Degema',
      'Deba',
      'Dawakin Tofa',
      'Dawakin Kudu',
      'Daura',
      'Darazo',
      'Dapchi',
      'Dan Sadau',
      'Dan Musa',
      'Dankama',
      'Dankalwa',
      'Danja',
      'Dan Gora',
      'Dange',
      'Dandume',
      'Damboa',
      'Dambatta',
      'Dambam',
      'Damaturu',
      'Damasak',
      'Damagum',
      'Dakingari',
      'Dadiya',
      'Dabai',
      'Chibok',
      'Charanchi',
      'Tsafe',
      'Calabar',
      'Bwari',
      'Burutu',
      'Burumburum',
      'Bununu',
      'Dass',
      'Bunkure',
      'Bungudu',
      'Bukuru',
      'Bukkuyum',
      'Buguma',
      'Buga',
      'Briyel',
      'Twon-Brass',
      'Bornu Yassu',
      'Bori',
      'Ugbokpo',
      'Bonny',
      'Bomadi',
      'Bokkos',
      'Bokani',
      'Boju',
      'Boje',
      'Boi',
      'Bogoro',
      'Bodinga',
      'Bode Saadu',
      'Bode Osi',
      'Biu',
      'Mallam Fatori',
      'Birniwa',
      'Birnin Kudu',
      'Birnin Kebbi',
      'Sofo-Birnin-Gwari',
      'Birnin Gwari',
      'Bin Yauri',
      'Binji',
      'Bindawa',
      'Billiri',
      'Bida',
      'Bichi',
      'Besse',
      'Benisheikh',
      'Benin City',
      'Bende',
      'Bena',
      'Beli',
      'Bebeji',
      'Baure',
      'Bauchi',
      'Batsari',
      'Batagarawa',
      'Bassa',
      'Baro',
      'Barkin Ladi',
      'Bara',
      'Bama',
      'Balle',
      'Bakura',
      'Bakori',
      'Bajoga',
      'Baissa',
      'Bagwai',
      'Bagudo',
      'Bugana',
      'Obagaji',
      'Badeggi',
      'Badagry',
      'Babura',
      'Babban Gida',
      'Babana',
      'Azare',
      'Awo-Idemili',
      'Awo',
      'Awka',
      'Awgu',
      'Awe',
      'Auna',
      'Augie',
      'Auchi',
      'Atani',
      'Askira',
      'Asaba',
      'Arochukwu',
      'Argungu',
      'Araromi-Opin',
      'Aramoko-Ekiti',
      'Apomu',
      'Apapa',
      'Ankpa',
      'Anka',
      'Anchau',
      'Anaku',
      'Amper',
      'Amaigbo',
      'Amassoma',
      'Umundugba',
      'Amagunze',
      'Alkaleri',
      'Aliero',
      'Aliade',
      'Albasu',
      'Alapa',
      'Akwukwu-Igbo',
      'Akwete',
      'Akwanga',
      'Akure',
      'Aku',
      'Akodo',
      'Akko',
      'Ake-Eze',
      'Akanran',
      'Akamkpa',
      'Akankpa',
      'Ajingi',
      'Ajaawa',
      'Ajasse Ipo',
      'Ajaokuta',
      'Ajalli',
      'Ajaka',
      'Ayetoro',
      'Ayete',
      'Ahoada',
      'Agwara',
      'Agulu',
      'Aguata',
      'Akpet Central',
      'Ago-Are',
      'Ago-Amodu',
      'Agenebode',
      'Agege',
      'Agbor',
      'Tse-Agberagba',
      'Agbani',
      'Agbabu',
      'Agaie',
      'Afuze',
      'Afon',
      'Afikpo',
      'Afam',
      'Afaha Offiong',
      'Adoru',
      'Ado-Odo',
      'Adogo',
      'Ado-Ekiti',
      'Adikpo',
      'Adani',
      'Achalla',
      'Abuochiche',
      'Abuja',
      'Abudu',
      'Abua',
      'Abraka',
      'Abonnema',
      'Aboh',
      'Abocho',
      'Abigi',
      'Abeokuta',
      'Abejukolo',
      'Abakaliki',
      'Abak',
      'Abaji',
      'Abagana',
      'Aba',
      'Ajegunle',
      'Ojota',
      'Ifako',
      'Degema Hulk',
      'Yana',
      'Buni Yadi',
      'Obangede',
      'Naka',
      'Ezillo',
      'Koguna',
      'Nasko',
      'Oghara',
      'Abat',
      'Urua Inyang',
      'Ibiaku Ntok Okpo',
      'Irrua',
      'Igueben',
      'Umumma',
      'Akinima',
      'Umuguma',
      'Sabon Garin Nangere',
      'Aguobu-Owa',
      'Ugbodo',
      'Onueke',
      'Lessel',
      'Festac Town',
      'Atan',
      'Okuku',
      'Iyana-Ofa',
      'Mashegu',
      'Bangi',
      'Gawu Babangida',
      'Sabon Wuse',
      'Birnin Magaji',
      'Warawa',
      'Kaura',
      'Karu',
      'Akpafa',
      'Nkwo Nike',
      'Isiaka',
      'Obiozara',
      'Onuebonyi Echara',
      'Nwaorieubi',
      'Egbema',
      'Isinweke',
      'Nnenasa',
      'Oke-Ikpe',
      'Osisioma',
      'Omoba',
      'Okpuala-Ngwa',
      'Nkwoagu Isuochi',
      'Isiala Oboro',
      'Ogbia',
      'Upenekang',
      'Ikot Edibon',
      'Ikot Akpa Nkuk',
      'Eyofin',
      'Urue Offong',
      'Mabudi',
      'Tunkus',
      'Baap',
      'Kwal',
      'Boh',
      'Kanamma',
      'Khaddamari',
      'Sunkani',
      'Saakpenwa',
      'Nchia',
      'Rumuodomaya',
      'Chakwama',
      'Eti-Osa',
      'Lagos Island',
    ],
  },
  {
    country: 'Nicaragua',
    cities: [
      'Yalagüina',
      'Wiwilí',
      'Waspán',
      'Waslala',
      'Villa Sandino',
      'Villanueva',
      'Valle San Francisco',
      'LLano de La Cruz',
      'Totogalpa',
      'Tonalá',
      'Tola',
      'Tisma',
      'Tipitapa',
      'Ticuantepe',
      'Teustepe',
      'Terrabona',
      'Telpaneca',
      'Telica',
      'Somoto',
      'Somotillo',
      'Siuna',
      'Sébaco',
      'Santo Tomás del Norte',
      'Santo Tomás',
      'Santo Domingo',
      'Santa Teresa',
      'Santa Rosa del Peñón',
      'Santa María',
      'Santa Lucía',
      'San Sebastián de Yalí',
      'San Ramón',
      'San Rafael del Sur',
      'San Rafael del Norte',
      'San Pedro de Lóvago',
      'San Pedro del Norte',
      'San Nicolás',
      'San Miguelito',
      'San Marcos',
      'San Lucas',
      'San Lorenzo',
      'San Juan de Río Coco',
      'San Juan de Oriente',
      'San Juan del Sur',
      'Greytown',
      'San Juan de Limay',
      'San José de los Remates',
      'San José de Cusmapa',
      'San José de Bocay',
      'San Jorge',
      'San Isidro',
      'San Francisco Libre',
      'San Francisco del Norte',
      'San Fernando',
      'San Dionisio',
      'San Carlos',
      'Rivas',
      'Río Blanco',
      'Rama',
      'Quilalí',
      'Quezalguaque',
      'Puerto Morazán',
      'Puerto Cabezas',
      'Pueblo Nuevo',
      'Prinzapolka',
      'Potosí',
      'Posoltega',
      'El Cuá',
      'Palacagüina',
      'Ocotal',
      'El Tortuguero',
      'Nueva Guinea',
      'Niquinohomo',
      'Nindirí',
      'Nandasmo',
      'Nandaime',
      'Nagarote',
      'Muy Muy',
      'Murra',
      'Mulukukú',
      'Muelle de los Bueyes',
      'Mozonte',
      'Moyogalpa',
      'Morrito',
      'Matiguás',
      'Mateare',
      'Matagalpa',
      'Masaya',
      'Masatepe',
      'Masachapa',
      'Managua',
      'Malpaisillo',
      'Macuelizo',
      'León',
      'La Trinidad',
      'Las Sabanas',
      'Larreynaga',
      'La Paz de Carazo',
      'La Paz Centro',
      'La Libertad',
      'Pearl Lagoon',
      'La Cruz de Río Grande',
      'La Conquista',
      'La Concordia',
      'La Concepción',
      'Kukra Hill',
      'Karawala',
      'Juigalpa',
      'Jiquilillo',
      'Jinotepe',
      'Jinotega',
      'Jalapa',
      'Granada',
      'Estelí',
      'Esquipulas',
      'El Viejo',
      'El Sauce',
      'El Rosario',
      'El Realejo',
      'El Jicaral',
      'El Crucero',
      'El Ayote',
      'El Almendro',
      'Dolores',
      'Diriomo',
      'Diriamba',
      'Diriá',
      'Dipilto',
      'Cuapa',
      'Great Corn Island',
      'Corinto',
      'Condega',
      'Comalapa',
      'El Jícaro',
      'Ciudad Darío',
      'Ciudad Antigua',
      'Cinco Pinos',
      'Chinandega',
      'Chichigalpa',
      'Catarina',
      'Villa El Carmen',
      'Cárdenas',
      'Camoapa',
      'Buenos Aires',
      'Bonanza',
      'Bocay',
      'Bocana de Paiwas',
      'Boca de Sábalos',
      'Boaco',
      'Bluefields',
      'Belén',
      'Altagracia',
      'Acoyapa',
      'Achuapa',
      'Ciudad Sandino',
      'Las Praderas',
      'Santa Maía de Pantasma',
      'Wiwilí de Jinotega',
      'Wiwili',
      'El Coral',
      'La Dalia',
      'Rancho Grande',
    ],
  },
  {
    country: 'Netherlands',
    cities: [
      'Zwolle',
      'Zwijndrecht',
      'Zwaanshoek',
      'De Westereen',
      'Zwaagdijk-Oost',
      'Zutphen',
      'Zundert',
      'Zuilichem',
      'Zuidwijk',
      'Zuid-Scharwoude',
      'Zuidlaren',
      'Zuidhorn',
      'Zuiderburen',
      'Zuidbroek',
      'Zuid-Beijerland',
      'Zoutkamp',
      'Zoutelande',
      'Zonnemaat',
      'Zonderwijk',
      'Zoeterwoude-Dorp',
      'Zoetermeer',
      'Zoelen',
      'Zijtaart',
      'Zierikzee',
      'Zevenhuizen',
      'Zevenhoven',
      'Zevenbergschen Hoek',
      'Zevenaar',
      'Zetten',
      'Zesgehuchten',
      'Zelhem',
      'Zeist',
      'Zeilberg',
      'Zegveld',
      'Zegge',
      'Zeewolde',
      'Zeelst',
      'Zeeland',
      'Zeddam',
      'Zandvoort',
      'Zandberg',
      'Zaltbommel',
      'Zaanstad',
      'Zaandijk',
      'Zaandam',
      'Zaamslag',
      'Yerseke',
      'Wouw',
      'Woudsend',
      'Woudrichem',
      'Woudenberg',
      'Woubrugge',
      'Workum',
      'Wooldrik',
      'Woolde',
      'Wommels',
      'Wolvega',
      'Wolphaartsdijk',
      'Wolfsbos',
      'Wolfheze',
      'Wolder',
      'Woerden',
      'Woenselse Heide',
      'Woensdrecht',
      'Witmarsum',
      'Wissenkerke',
      'Wisch',
      'Wirdum',
      'Winterswijk',
      'Winsum',
      'Winschoten',
      'Winkel',
      'Wilnis',
      'Wilhelminadorp',
      'Wildervank',
      'Wijnjewoude',
      'Wijnandsrade',
      'Wijlre',
      'Wijk bij Duurstede',
      'Wijk aan Zee',
      'Wijhe',
      'Wijdenes',
      'Wijchen',
      'Wijbosch',
      'Wieringerwerf',
      'Wieringerwaard',
      'Wierden',
      'Westwoud',
      'West-Terschelling',
      'West-Souburg',
      'Westmaas',
      'Westlaren',
      'Westkapelle',
      'Westerzicht',
      'Westervoort',
      'Westerlee',
      'Westerhoven',
      'Westerhaar-Vriezenveensewijk',
      'Westerbork',
      'Westeneng',
      'Westeinde',
      'Westdorpe',
      'Wessem',
      'Wernhout',
      'Werkhoven',
      'Werkendam',
      'Wemeldinge',
      'Wellerlooi',
      'Well',
      'Welberg',
      'Wekerom',
      'Weiteveen',
      'Weijpoort',
      'Weesp',
      'Weert',
      'Weerestein',
      'Waubach',
      'Waterakkers',
      'Wassenaar',
      'Waspik',
      'Warnsveld',
      'Warmond',
      'Warga',
      'Warffum',
      'Wapenveld',
      'Wanssum',
      'Wanroij',
      'Wageningen',
      'Wagenborgen',
      'Wagenberg',
      'Waddinxveen',
      'Waarland',
      'Waarder',
      'Waardenburg',
      'Waarde',
      'Waalwijk',
      'Waalre',
      'Vuren',
      'Vught',
      'Vroomshoop',
      'Vriezenveen',
      'Vries',
      'Vrieheide',
      'Vreewijk',
      'Vreeswijk',
      'Vredenburg',
      'Vorstenbosch',
      'Vorden',
      'Voorthuizen',
      'Voorst',
      'Voorschoten',
      'Voorhout',
      'Voorburg',
      'Vollenhove',
      'Volkel',
      'Volendam',
      'Voldijn',
      'Vogelwijk',
      'Vogelenzang',
      'Voerendaal',
      'Vlokhoven',
      'Vlodrop',
      'Vlissingen',
      'Vlietwijk',
      'Vlierden',
      'Vliedberg',
      'Vleuten',
      'Vledder',
      'Vlagtwedde',
      'Vlaardingen',
      'Vinkeveen',
      'Vijlen',
      'Vijfhuizen',
      'Vierpolders',
      'Vianen',
      'Venray',
      'Venlo',
      'Venhorst',
      'Ven',
      'Veltum',
      'Velsen-Zuid',
      'Velp',
      'Veldhuizen',
      'Veldhoven',
      'Veghel',
      'Feanwâlden',
      'Veenoord',
      'Veenendaal',
      'Veendam',
      'Veen',
      'Varsseveld',
      'Valthermond',
      'Valthe',
      'Valkenswaard',
      'Valkenburg',
      'Valendries',
      'Valburg',
      'Vaassen',
      'Vaartbroek',
      'Vaals',
      'Utrecht',
      'Usquert',
      'Urmond',
      'Urk',
      'Ureterp',
      'Ulvenhout',
      'Ulrum',
      'Ulft',
      'Ulestraten',
      'Uithuizermeeden',
      'Uithuizen',
      'Uithoorn',
      'Uitgeest',
      'Ugchelen',
      'Uffelte',
      'Udenhout',
      'Uden',
      'Uddel',
      'Ubachsberg',
      'Tzummarum',
      "'t Zand",
      'Tynaarlo',
      'Twisk',
      'Twijzelerheide',
      'Twijzel',
      'Twello',
      'Twekkelerveld',
      'Tweede Exloërmond',
      'Tuk',
      'Tuindorp',
      'Tuikwerd',
      'Tubbergen',
      'Tricht',
      'Tongelre',
      'Tolkamer',
      'Tivoli',
      'Tinga',
      'Tilburg',
      'Tijnje',
      'Tytsjerk',
      'Tienray',
      'Tiel',
      'Thorn',
      'Tholen',
      "'t Hofke",
      'Theereheide',
      'Teteringen',
      'Terwolde',
      'Terwinselen',
      'Terschuur',
      'Terneuzen',
      'Ternaard',
      'Terheijden',
      'Terbregge',
      'Terborg',
      'Ter Apel',
      'Ten Boer',
      'Tegelen',
      'Surhuisterveen',
      'Strijp',
      'Strijen',
      'Stramproy',
      'Stompetoren',
      'Stolwijk',
      'Stiens',
      'Stepekolk',
      'Stein',
      'Steyl',
      'Stegeslag',
      'Steenwijkerwold',
      'Steenwijk',
      'Steensel',
      'Steenderen',
      'Steenbergen',
      'Stavenisse',
      'Statenkwartier',
      'Staphorst',
      'Standdaarbuiten',
      'Stampersgat',
      'Stadskanaal',
      'Stadsfenne',
      'Stadbroek',
      'Sprundel',
      'Spoorwijk',
      'Spijkenisse',
      'Spijk',
      'Spierdijk',
      'Spekholzerheide',
      'Spaubeek',
      'Spangen',
      'Spakenburg',
      'Son',
      'Someren-Eind',
      'Someren',
      'Soestdijk',
      'Soest',
      'Soerendonk',
      'Sneek',
      'Sluiskil',
      'Sluis',
      'Slootdorp',
      'Slochteren',
      'Slikkerveer',
      'Sliedrecht',
      'Sleeuwijk',
      'Sleen',
      'Sittard',
      'Sint Willebrord',
      'Sint Philipsland',
      'Sint Pancras',
      'Sint-Oedenrode',
      'Sint Odiliënberg',
      'Sint Nicolaasga',
      'Sint-Michielsgestel',
      'Sint Laurens',
      'Sint Joost',
      'Sintjohannesga',
      'Sint Jansklooster',
      'Sint Jacobiparochie',
      'Sint Anthonis',
      'Sint Annaparochie',
      'Simpelveld',
      'Silvolde',
      'Siddeburen',
      'Sibbe',
      "'s-Hertogenbosch",
      "'s-Heerenberg",
      "'s-Gravenzande",
      "'s Gravenmoer",
      'The Hague',
      'Sexbierum',
      'Sevenum',
      'Serooskerke',
      'Selwerd',
      'Sellingen',
      'Schutsboom',
      'Schoonrewoerd',
      'Schoonoord',
      'Schoonhoven',
      'Schoonebeek',
      'Schoondijke',
      'Schipluiden',
      'Schinveld',
      'Schinnen',
      'Schimmert',
      'Schildwolde',
      'Schilberg',
      'Schijndel',
      'Schiermonnikoog',
      'Schiedam',
      'Schiebroek',
      'Scheveningen',
      'Scherpenzeel',
      'Scherpenisse',
      'Schermerhorn',
      'Schelluinen',
      'Scheemda',
      'Scharnegoutum',
      'Scharn',
      'Scharendijke',
      'Schalkhaar',
      'Schaijk',
      'Schagerbrug',
      'Schagen',
      'Sas van Gent',
      'Sassenheim',
      'Sappemeer',
      'Ruurlo',
      'Ruinerwold',
      'Ruinen',
      'Rugge',
      'Rucphen',
      'Rozendaal',
      'Rozenburg',
      'Rottevalle',
      'Rotterdam',
      'Rothem',
      'Rossum',
      'Roosteren',
      'Roosendaal',
      'Rolde',
      'Roermond',
      'Roelofarendsveen',
      'Roden',
      'Rockanje',
      'Rijswijk',
      'Rijsoord',
      'Rijsbergen',
      'Rijpwetering',
      'Rijnsburg',
      'Rijnsaterwoude',
      'Rijen',
      'Riethoven',
      'Ridderkerk',
      'Rhoon',
      'Rhenen',
      'Rheden',
      'Reuver',
      'Reusel',
      'Renswoude',
      'Renkum',
      'Renesse',
      'Reeuwijk',
      'Reek',
      'Ravenstein',
      'Raamsdonksveer',
      'Raamsdonk',
      'Raam',
      'Raalte',
      'Quirijnstok',
      'Putten',
      'Putte',
      'Puth',
      'Purmerend',
      'Puiflijk',
      'Prinsenbeek',
      'Princenhage',
      'Pottenberg',
      'Posterholt',
      'Poortvliet',
      'Poeldijk',
      'Pijnacker',
      'Piershil',
      'Pierik',
      'Petten',
      'Pernis',
      'Pendrecht',
      'Peize',
      'Peij',
      'Peelo',
      'Pathmos',
      'Paterswolde',
      'Passart',
      'Papenveer',
      'Papendrecht',
      'Panningen',
      'Pannerden',
      'Palenstein',
      'Overveen',
      'Overschie',
      'Overloon',
      'Overhoven',
      'Overberg',
      'Overasselt',
      'Oud-Vossemeer',
      'Oud-Loosdrecht',
      'Oudkarspel',
      'Oud Gastel',
      'Oude Wetering',
      'Oudewater',
      'Oudeschoot',
      'Oudeschild',
      'Ouderkerk aan den IJssel',
      'Ouderkerk aan de Amstel',
      'Oude Pekela',
      'Oudemirdum',
      'Oudehaske',
      'Oudega',
      'Ouddorp',
      'Oud-Caberg',
      'Oud-Beijerland',
      'Ottersum',
      'Otterlo',
      'Othene',
      'Ossendrecht',
      'Oss',
      'Ospel',
      'Orthen',
      'Orden',
      'Oranjewoud',
      'Opperdoes',
      'Oppenhuizen',
      'Opmeer',
      'Opijnen',
      'Ophoven',
      'Opheusden',
      'Ophemert',
      'Opeinde',
      'Ooyerhoek',
      'Ootmarsum',
      'Oostvoorne',
      'Oost-Vlieland',
      'Oostrum',
      'Oostkapelle',
      'Oosthuizen',
      'Oosterzij',
      'Oosterwolde',
      'Oosterpark',
      'Eastermar',
      'Oostermeenthe',
      'Oosterland',
      'Oosterhout',
      'Oosterhoogebrug',
      'Oosterholt',
      'Oosterhesselen',
      'Oosterblokker',
      'Oosterbeek',
      'Oostendorp',
      'Oostelbeers',
      'Oosteinde',
      'Oosteind',
      'Oostdorp',
      'Oostburg',
      'Ooy',
      'Ooij',
      'Oog in Al',
      'Onstwedde',
      'Onderdijk',
      'Ommoord',
      'Ommen',
      'Ommelanderwijk',
      'Olst',
      'Oldenzaal',
      'Oldemarkt',
      'Oldehove',
      'Oldebroek',
      'Aldeboarn',
      'Oldeberkoop',
      'Oisterwijk',
      'Oirschot',
      'Oirsbeek',
      'Oirlo',
      'Offenbeek',
      'Oerle',
      'Oentsjerk',
      'Oegstgeest',
      'Odoorn',
      'Odiliapeel',
      'Odijk',
      'Ochten',
      'Obergum',
      'Obdam',
      'Obbicht',
      'Nuth',
      'Nunspeet',
      'Numansdorp',
      'Nuenen',
      'Norg',
      'Noordwolde',
      'Noordwijkerhout',
      'Noordwijk-Binnen',
      'Noord-Scharwoude',
      'Noordhorn',
      'Noord-Hofland',
      'Noordhoek',
      'Noordgeest',
      'Noordeloos',
      'Noordeinde',
      'Noordbroek',
      'Noardburgum',
      'Noordbarge',
      'Nistelrode',
      'Nispen',
      'Nijrees',
      'Nijnsel',
      'Nijmegen',
      'Nijkerkerveen',
      'Nijkerk',
      'Nijenheim',
      'Nieuw-Vossemeer',
      'Nieuwveen',
      'Nieuw-Roden',
      'Nieuwpoort',
      'Nieuwoord',
      'Nieuwolda',
      'Nieuw-Namen',
      'Nieuw-Lotbroek',
      'Nieuw-Loosdrecht',
      'Nieuw-Lekkerland',
      'Nieuwland',
      'Nieuw- en Sint Joosland',
      'Nieuwkuijk',
      'Nieuwkoop',
      'Nieuwerkerk',
      'Nieuwe Pekela',
      'Nieuwe-Niedorp',
      'Nieuwenhoorn',
      'Nieuwendijk',
      'Nieuwehorne',
      'Nieuwegein',
      'Nieuwdorp',
      'Nieuw-Dordrecht',
      'Nieuw-Buinen',
      'Nieuw-Beijerland',
      'Niekerk',
      'Nes',
      'Neerkant',
      'Neerbeek',
      'Andel',
      'Neede',
      'Nederwoud',
      'Nederweert',
      'Nederhemert-Noord',
      'Nederhemert',
      'Neder-Hardinxveld',
      'Naastenbest',
      'Naarden',
      'Naaldwijk',
      'Munstergeleen',
      'Muiderberg',
      'Muiden',
      'Mook',
      'Montfort',
      'Montfoort',
      'Monster',
      'Monnickendam',
      'Molenhoek',
      'Moesel',
      'Moerdijk',
      'Minnertsga',
      'Milsbeek',
      'Milheeze',
      'Mijnsheerenland',
      'Mijdrecht',
      'Mierlo',
      'Midwoud',
      'Midwolda',
      'Middenmeer',
      'Middenbeemster',
      'Middelstum',
      'Middelsluis',
      'Middelrode',
      'Middelharnis',
      'Middelburg',
      'Middelbeers',
      'Meteren',
      'Merum',
      'Merselo',
      'Merkelbeek',
      'Merenwijk',
      'Meppel',
      'Menaam',
      'Melle',
      'Meliskerke',
      'Melick',
      'Melderslo',
      'Mekkelholt',
      'Meijel',
      'Megen',
      'Meezenbroek',
      'Meerveldhoven',
      'Meerssen',
      'Meerlo',
      'Meeden',
      'Medemblik',
      'Mechelen',
      'Maurik',
      'Marum',
      'Marsum',
      'Marrum',
      'Markelo',
      'Mariarade',
      'Mariahout',
      'Margraten',
      'Mantgum',
      'Malta',
      'Malburgen West',
      'Malberg',
      'Makkum',
      'Magele',
      'Made',
      'Maastricht',
      'Maassluis',
      'Maasland',
      'Maaskantje',
      'Maasdijk',
      'Maasbree',
      'Maasbracht',
      'Maartensdijk',
      'Maarssen',
      'Maarn',
      'Maarheeze',
      'Luyksgestel',
      'Lutjebroek',
      'Lunteren',
      'Loven',
      'Lottum',
      'Losser',
      'Loppersum',
      'Lopik',
      'Loosbroek',
      'Loon op Zand',
      'Lombardijen',
      'Loenen',
      'Lochem',
      'Lobith',
      'Lith',
      'Lisserbroek',
      'Lisse',
      'Linschoten',
      'Linne',
      'Lindenholt',
      'Lindenheuvel',
      'Limmen',
      'Limmel',
      'Limbricht',
      'Lievendaal',
      'Liesveld',
      'Liessel',
      'Lieshout',
      'Lierop',
      'Lienden',
      'Liempde',
      'Lichtenvoorde',
      'Lichtenberg',
      'Leuth',
      'Leusden',
      'Leunen',
      'Leuken',
      'Lepelstraat',
      'Lent',
      'Lemmer',
      'Lelystad',
      'Leimuiden',
      'Leiderdorp',
      'Leiden',
      'Leeuwen',
      'Leeuwarden',
      'Leesten',
      'Leest',
      'Leersum',
      'Leerdam',
      'Leens',
      'Leende',
      'Leek',
      'Lauradorp',
      'Laren',
      'Langweer',
      'Langenoord',
      'Langenboom',
      'Langeheit',
      'Landsmeer',
      'Lammerenburg',
      'Lage Mierde',
      'Kwintsheul',
      'Kunrade',
      'Kudelstaart',
      'Kruisland',
      'Kruisberg',
      'Krooswijk',
      'Krimpen aan den IJssel',
      'Krakeel',
      'Koudum',
      'Koudekerke',
      'Korvel',
      'Kortenhoef',
      'Korrewegwijk',
      'Kootwijkerbroek',
      'Kootstertille',
      'Koningslust',
      'Koningsbosch',
      'Kollumerzwaag',
      'Kollum',
      'Koewacht',
      'Koekange',
      'Klundert',
      'Kloetinge',
      'Klimmen',
      'Klein-Zundert',
      'Klein Driene',
      'Klazienaveen',
      'Klarenbeek',
      'Klaaswaal',
      'Kijkduin',
      'Kesteren',
      'Kessel',
      'Kerschoten',
      'Kerkrade',
      'Kerkelanden',
      'Kerkehout',
      'Kerkdriel',
      'Marken',
      'Kerensheide',
      'Kerckebosch',
      'Keijenborg',
      'Keent',
      'Kedichem',
      'Katwijk aan Zee',
      'Katwijk aan den Rijn',
      'Katendrecht',
      'Kamperland',
      'Kampen',
      'Kalsdonk',
      'Kakert',
      'Kadoelen',
      'Kaalheide',
      'Joure',
      'Jagershoef',
      'Jirnsum',
      'Ingen',
      'Ilpendam',
      'IJzendijke',
      'Ysselsteyn',
      'IJsselstein',
      'IJmuiden',
      'IJlst',
      'Husken',
      'Hummelo',
      'Hulst',
      'Hulsberg',
      'Huizen',
      'Huizum',
      'Huijbergen',
      'Houthem',
      'Houten',
      'Horssen',
      'Hopel',
      'Hoorn',
      'Hoogwoud',
      'Hoogvliet',
      'Hoogmade',
      'Hoogland',
      'Hooge Zwaluwe',
      'Hoogezand',
      'Hoogeveen',
      'Hoogerheide',
      'Hooge Mierde',
      'Hoogeloon',
      'Hoogblokland',
      'Hoofddorp',
      'Honselersdijk',
      'Holz',
      'Holwerd',
      'Holtum',
      'Hollum',
      'Hollandsche Rading',
      'Hoge Mors',
      'Hoge Vucht',
      'Hofgeest',
      'Hoeven',
      'Hoensbroek',
      'Hoek van Holland',
      'Hippolytushoef',
      'Hilversumse Meent',
      'Hilversum',
      'Hilvarenbeek',
      'Hillegom',
      'Heythuysen',
      'Heusdenhout',
      'Heusden',
      'Heumen',
      'Heugem',
      'Het Oostrik',
      'Het Loo',
      'Heteren',
      'Herwijnen',
      'Herten',
      'Herpen',
      'Herkenbosch',
      'Hensbroek',
      'Hengstdal',
      'Hengevelde',
      'Hengelo',
      'Hendrik-Ido-Ambacht',
      'Hem',
      'Helvoirt',
      'Helpman',
      'Helmond',
      'Hellevoetsluis',
      'Helden',
      'Heksenberg',
      'Heino',
      'Heiloo',
      'Heiligerlee',
      'Heijplaat',
      'Heijen',
      'Heide',
      'Hegelsom',
      'Heeze',
      'Heeswijk-Dinther',
      'Heesterakker',
      'Heesch',
      'Hees',
      'Heerlen',
      'Heerle',
      'Heerjansdam',
      'Heerhugowaard',
      'Heerewaarden',
      'Heerenveen',
      'Heerde',
      'Heer',
      'Heemstede',
      'Heemskerk',
      'Heelsum',
      'Heel',
      'Heeg',
      'Heechterp',
      'Hedel',
      'Havelte',
      'Haulerwijk',
      'Hattemerbroek',
      'Hattem',
      'Hatert',
      'Hasselt',
      'Harskamp',
      'Harlingen',
      'Harkstede',
      'Harkema',
      'Harenkarspel',
      'Haren',
      'Harderwijk',
      'Hardenberg',
      'Hurdegaryp',
      'Haps',
      'Hapert',
      'Hank',
      'Handel',
      'Halsteren',
      'Hallum',
      'Halfweg',
      'Hagestein',
      'Haelen',
      'Haastrecht',
      'Haarsteeg',
      'Haarlem',
      'Haarle',
      'Haaren',
      'Haanrade',
      'Haamstede',
      'Haalderen',
      'Haaksbergen',
      'Haaften',
      'Guttecoven',
      'Gulpen',
      'Grou',
      'Groot IJsselmonde',
      'Grootegast',
      'Groot-Ammers',
      'Gronsveld',
      'Groningen',
      'Groessen',
      'Groesbeek',
      'Groenewoud',
      'Groenekan',
      'Grijpskerke',
      'Grijpskerk',
      'Griffioen',
      'Grevenbicht',
      'Grave',
      'Grashoek',
      'Grafhorst',
      'Gracht',
      'Gouwsluis',
      'Goutum',
      'Goudswaard',
      'Gouderak',
      'Gouda',
      'Gorssel',
      'Gorredijk',
      'Gorinchem',
      'Goor',
      'Goirle',
      'Goes',
      'Goedereede',
      'Glimmen',
      'Ginneken',
      'Gilze',
      'Giethoorn',
      'Gieten',
      'Giessendam',
      'Giessenburg',
      'Giessen',
      'Giesbeek',
      'Gytsjerk',
      'Gerwen',
      'Genoenhuis',
      'Gennep',
      'Gendt',
      'Gendringen',
      'Genderen',
      'Gemonde',
      'Gemert',
      'Geleen',
      'Geldrop',
      'Geldermalsen-West',
      'Geldermalsen',
      'Geitenkamp',
      'Geffen',
      'Geertruidenberg',
      'Geenhoven',
      'Gasselternijveen',
      'Gasselte',
      'Garyp',
      'Garderen',
      'Gameren',
      'Gageldonk',
      'Frankhuis',
      'Franeker',
      'Flevowijk',
      'Fijnaart',
      'Ferwert',
      'Feijenoord',
      'Farmsum',
      'Exloo',
      'Everdingen',
      'Etten',
      'Essesteijn',
      'Esch',
      'Erp',
      'Ermelo',
      'Epse',
      'Epe',
      'Enschot',
      'Enschede',
      'Ens',
      'Enkhuizen',
      'Emst',
      'Emmerschans',
      'Emmermeer',
      'Emmerhout',
      'Emmer-Erfscheidenveen',
      'Emmer-Compascuum',
      'Emmen',
      'Emmeloord',
      'Elst',
      'Elspeet',
      'Elsloo',
      'Elshout',
      'Ellecom',
      'Elden',
      'Elburg',
      'Ekenrooi',
      'Einighausen',
      'Eindhoven',
      'Eijsden',
      'Eibergen',
      'Egmond-Binnen',
      'Egmond aan Zee',
      'Egchel',
      'Eexta',
      'Eext',
      'Eersel',
      'Eerschot',
      'Eerde',
      'Eerbeek',
      'Eenrum',
      'Eelde',
      'Eefde',
      'Ederveen',
      'Ede',
      'Edam',
      'Eckart',
      'Echtenerbrug',
      'Dwingeloo',
      'Dussen',
      'Duizel',
      'Duivendrecht',
      'Duiven',
      'Duinzigt',
      'Duindorp',
      'Druten',
      'Drumpt',
      'Dronten',
      'Dronryp',
      'Drogeham',
      'Driemond',
      'Driemanspolder',
      'Driel',
      'Driehuizen',
      'Driebruggen',
      'Driebergen-Rijsenburg',
      'Drachtstercompagnie',
      'Drachten',
      'Dorst',
      'Dordrecht',
      'Doorwerth',
      'Doornsteeg',
      'Doornspijk',
      'Doornenburg',
      'Doorn',
      'Doonheide',
      'Donkerbroek',
      'Donk',
      'Dongen',
      'Dommelen',
      'Domburg',
      'Dokkum',
      'Doetinchem',
      'Doesburg',
      'Doenrade',
      'Dirksland',
      'Dirkshorn',
      'Dinteloord',
      'Diever',
      'Diessen',
      'Dieren',
      'Diepenheim',
      'Diemen',
      'De Wijk',
      'De Wijert',
      'Deventer',
      'Deuteren',
      'De Uithof',
      'De Rompert',
      'De Rijp',
      'De Reit',
      'De Peulen',
      'De Noord',
      'Den Oever',
      'Den Helder',
      'Den Ham',
      'Den Dungen',
      'Den Burg',
      'De Mheen',
      'De Meern',
      'De Maer',
      'De Loo',
      'De Lier',
      'Delfzijl',
      'Delft',
      'Delfshaven',
      'Delden',
      'De Kwakel',
      'De Kruiskamp',
      'De Koog',
      'De Kieviet',
      'Deil',
      'De Hoven',
      'De Heeg',
      'De Hagen',
      'De Greiden',
      'De Goorn',
      'De Glip',
      'Deest',
      'De Drait',
      'De Doornakkers',
      'De Domp',
      'De Blaak',
      'De Bilt',
      'Dauwendaele',
      'Damwâld',
      'Dalfsen',
      'Dalen',
      'Culemborg',
      'Cuijk',
      'Cranendonck',
      'Cothen',
      'Commandeurs',
      'Colijnsplaat',
      'Coevorden',
      'Coevering',
      'Coendersborg',
      'Chaam',
      'Castricum',
      'Carnisse',
      'Capelle-West',
      'Capelle aan den IJssel',
      'Callantsoog',
      'Bussum',
      'Bunschoten',
      'Bunnik',
      'Bunde',
      'Buitenpost',
      'Budschop',
      'Budel-Schoot',
      'Budel-Dorplein',
      'Budel',
      'Buchten',
      'Brunssum',
      'Brunnepe',
      'Brummen',
      'Brukske',
      'Bruinisse',
      'Bruchem',
      'Brouwershaven',
      'Broeksterwâld',
      'Broeksittard',
      'Broek op Langedijk',
      'Broek in Waterland',
      'Broekhoven',
      'Broekhem',
      'Brinkhorst',
      'Brielle',
      'Breukelen',
      'Breugel',
      'Breskens',
      'Breezand',
      'Bredeweg',
      'Bredevoort',
      'Breda',
      'Brand',
      'Brakkenstein',
      'Brakel',
      'Brachterbeek',
      'Brabander',
      'Boxtel',
      'Boxmeer',
      'Boven-Hardinxveld',
      'Bosschenhoofd',
      'Boskoop',
      'Boskamp',
      'Bosch en Duin',
      'Borssele',
      'Borne',
      'Born',
      'Borgharen',
      'Borger',
      'Borgele',
      'Borculo',
      'Boornbergum',
      'Bolsward',
      'Bolnes',
      'Boekel',
      'Bodegraven',
      'Bocholtz',
      'Blokzijl',
      'Bloemhof',
      'Bloemendaal',
      'Blitterswijck',
      'Blijham',
      'Bleskensgraaf',
      'Bleiswijk',
      'Bleijerheide',
      'Blaricum',
      'Bladel',
      'Blaarthem',
      'Bitswijk',
      'Burdaard',
      'Binnenhof',
      'Bilgaard',
      'Bijvanck',
      'Biesland',
      'Biesdonk',
      'Biddinghuizen',
      'Beverwijk',
      'Beusichem',
      'Beuningen',
      'Best',
      'Besoijen',
      'Berltsum',
      'Berlicum',
      'Berkum',
      'Berkhout',
      'Berkenwoude',
      'Beringe',
      'Burgum',
      'Bergstoep',
      'Bergschenhoek',
      'Berghem',
      'Bergharen',
      'Bergeijk',
      'Bergen op Zoom',
      'Berg en Dal',
      'Berg en Bos',
      'Bergen',
      'Berg',
      'Benthuizen',
      'Bennekom',
      'Bennebroek',
      'Bemmel',
      'Beltrum',
      'Bellingwolde',
      'Belfort',
      'Belcrum',
      'Beilen',
      'Beetsterzwaag',
      'Beesel',
      'Beesd',
      'Beersdal',
      'Beers',
      'Beekbergen',
      'Beek gem Montferland',
      'Beek',
      'Beegden',
      'Bedum',
      'Bavel',
      'Barneveld',
      'Barger-Oosterveld',
      'Bargeres',
      'Barendrecht',
      'Banholt',
      'Bangert',
      'Ballast',
      'Balk',
      'Bakkeveen',
      'Bakhuizen',
      'Bakenberg',
      'Bakel',
      'Baflo',
      'Babberich',
      'Baarn',
      'Baarlo',
      'Baarle-Nassau',
      'Baardwijk',
      'Baambrugge',
      'Baalder',
      'Axel',
      'Avenhorn',
      'Austerlitz',
      'Augustinusga',
      'Asten',
      'Assendelft',
      'Assen',
      'Arnhem',
      'Arnemuiden',
      'Arkel',
      'Arcen',
      'Appingedam',
      'Appelscha',
      'Apeldoorn',
      'Annen',
      'Anklaar',
      'Ankeveense Rade',
      'Angerlo',
      'Angeren',
      'Angelslo',
      'Andelst',
      'Amsterdam',
      'Amstenrade',
      'Amstelveen',
      'Ammerzoden',
      'Ammerstol',
      'Amersfoort',
      'Amerongen',
      'America',
      'Ameide',
      'Amby',
      'Alverna',
      'Alphen aan den Rijn',
      'Alphen',
      'Almkerk',
      'Almere Stad',
      'Almelo',
      'Alkmaar',
      'Alblasserdam',
      'Akkrum',
      'Akert',
      'Afferden',
      'Aduard',
      'Adegeest',
      'Abdissenbosch',
      'Abcoven',
      'Abcoude',
      'Abbekerk',
      'Aardenburg',
      'Aalten',
      'Aalst',
      'Aalsmeer',
      'Aalden',
      'Aalburg',
      'Aagtekerke',
      'Camminghaburen',
      'Amsterdam-Zuidoost',
      'Zuid-Berghuizen',
      'Chevremont',
      'Welgelegen',
      'Matenveld',
      'Matenhoeve',
      'Matendonk',
      'Matenhorst',
      'Matendreef',
      'Matengaarde',
      'Rivierenkwartier',
      'Staatsliedenkwartier',
      'Brummelhof',
      'Vogelkwartier',
      'Componistenkwartier',
      'Woudhuis',
      'Sluisoord',
      'Sprenkelaar',
      'De Haven',
      'Binnenstad',
      'Sprengenweg-Noord',
      'Sprengenbos',
      'Spainkbos',
      'De Heeze',
      'Westenenk',
      'Winkewijert',
      'De Bouwhof',
      'Speelheide',
      'Salderes',
      'Jubbega',
      'Meerhoven',
      'Grasrijk',
      'Hoek',
      'De Knipe',
      'Lunetten',
      'Aarle-Rixtel',
      'Kelpen-Oler',
      'Muschberg en Geestenberg',
      'Villapark',
      'Lakerlopen',
      'Berkel en Rodenrijs',
      'Reitdiep',
      'Ypenburg',
      'Aldlân-Oost',
      'Oranjewijk',
      'Vondelwijk',
      'Groenswaard',
      'Randenbroek',
      'Corlaer',
      'Kapelle',
      'Hoogkamp',
      'Gulden Bodem',
      'Sterrenberg',
      'Burgemeesterswijk',
      'Kop van Zuid',
      "'s-Gravenland",
      'Spechtenkamp',
      'Besterd',
      'Wisselaar',
      'Waterdonken',
      'Hunnerberg',
      'Son en Breugel',
      'Marsdijk',
      'Op Buuren',
      'Zandweg-Oostwaard',
    ],
  },
  {
    country: 'Norway',
    cities: [
      'Vardø',
      'Vuonnabahta',
      'Vadsø',
      'Storslett',
      'Skjervøy',
      'Olderdalen',
      'Øksfjord',
      'Mehamn',
      'Lyngseidet',
      'Lakselv',
      'Kvalsund',
      'Kjøllefjord',
      'Kirkenes',
      'Kautokeino',
      'Kárášjohka',
      'Honningsvåg',
      'Ávanuorri',
      'Hammerfest',
      'Burfjord',
      'Bjørnevatn',
      'Berlevåg',
      'Båtsfjord',
      'Alta',
      'Rypefjord',
      'Strai',
      'Skålevik',
      'Ytre Arna',
      'Voss',
      'Volda',
      'Vinstra',
      'Vikøyri',
      'Vikeså',
      'Vikersund',
      'Vik',
      'Vigrestad',
      'Vigeland',
      'Vevelstad',
      'Vestbygd',
      'Vestby',
      'Verdal',
      'Vennesla',
      'Vatne',
      'Varhaug',
      'Vanse',
      'Vangsvika',
      'Valle',
      'Valldal',
      'Våler',
      'Vågåmo',
      'Utsira',
      'Ulvik',
      'Ulsteinvik',
      'Ulefoss',
      'Uggdal',
      'Tysvær',
      'Tysse',
      'Tynset',
      'Tveit',
      'Tvedestrand',
      'Innbygda',
      'Trondheim',
      'Tromsø',
      'Trofors',
      'Treungen',
      'Tretten',
      'Tranby',
      'Tonstad',
      'Tønsberg',
      'Tomter',
      'Tomra',
      'Tolga',
      'Tofte',
      'Tjøme',
      'Tjeldstø',
      'Tingvatn',
      'Terråk',
      'Tau',
      'Tangvall',
      'Tananger',
      'Syfteland',
      'Svolvær',
      'Svelvik',
      'Svelgen',
      'Sveio',
      'Sunndalsøra',
      'Stryn',
      'Straumen',
      'Straume',
      'Stranda',
      'Storsteinnes',
      'Storebø',
      'Stokmarknes',
      'Stokke',
      'Stjørdalshalsen',
      'Steinshamn',
      'Steinkjer',
      'Stavern',
      'Stavanger',
      'Stange',
      'Spydeberg',
      'Spetalen',
      'Sørumsand',
      'Sortland',
      'Sørland',
      'Solfjellsjøen',
      'Sola',
      'Søgne',
      'Sogndalsfjøra',
      'Snåase',
      'Slemmestad',
      'Skui',
      'Skudeneshavn',
      'Skreia',
      'Skotterud',
      'Skoppum',
      'Skogn',
      'Skoger',
      'Skodje',
      'Skjeberg',
      'Skjærhalden',
      'Skien',
      'Ski',
      'Skaun',
      'Skarnes',
      'Skaland',
      'Sjøvegan',
      'Skjønhaug',
      'Sjøholt',
      'Sistranda',
      'Siljan',
      'Setermoen',
      'Sem',
      'Selvik',
      'Seljord',
      'Sauda',
      'Sarpsborg',
      'Sandvika',
      'Sandnessjøen',
      'Sandnes',
      'Sandefjord',
      'Sande',
      'Sandane',
      'Sand',
      'Sagvåg',
      'Sæveland',
      'Sætre',
      'Rygge',
      'Rubbestadneset',
      'Røyrvik',
      'Røyken',
      'Rotnes',
      'Røst',
      'Rosendal',
      'Rørvik',
      'Røros',
      'Rollag',
      'Rognan',
      'Roa',
      'Rjukan',
      'Risør',
      'Rindal',
      'Rena',
      'Reinsvoll',
      'Rensvik',
      'Reine',
      'Raufoss',
      'Ranemsletta',
      'Randaberg',
      'Ramberg',
      'Rakkestad',
      'Råholt',
      'Prestfoss',
      'Prestestranda',
      'Porsgrunn',
      'Øystese',
      'Farnes',
      'Otta',
      'Osøyro',
      'Oslo',
      'Os',
      'Ørnes',
      'Orkanger',
      'Ørje',
      'Oppdal',
      'Ølen',
      'Odda',
      'Notodden',
      'Norheimsund',
      'Noresund',
      'Nordfjordeid',
      'Nodeland',
      'Nesna',
      'Nesbyen',
      'Naustdal',
      'Narvik',
      'Namsskogan',
      'Namsos',
      'Nærbø',
      'Mysen',
      'Myre',
      'Myra',
      'Mosterhamn',
      'Moss',
      'Mosjøen',
      'Moldjord',
      'Molde',
      'Fyresdal',
      'Mo i Rana',
      'Moi',
      'Moen',
      'Moelv',
      'Mo',
      'Mjøndalen',
      'Midsund',
      'Meråker',
      'Melsomvik',
      'Melhus',
      'Melbu',
      'Maura',
      'Masfjorden',
      'Manger',
      'Mandal',
      'Malvik',
      'Måløy',
      'Malm',
      'Lysaker',
      'Lyngdal',
      'Lunde',
      'Lundamo',
      'Løten',
      'Løpsmarka',
      'Lonevåg',
      'Fossbergom',
      'Lødingen',
      'Løding',
      'Lindås',
      'Lillestrøm',
      'Lillesand',
      'Lillehammer',
      'Liknes',
      'Levanger',
      'Lervik',
      'Lensvik',
      'Lena',
      'Leland',
      'Leknes',
      'Leirvik',
      'Leirsund',
      'Lauvsnes',
      'Larvik',
      'Larsnes',
      'Larkollen',
      'Langevåg',
      'Langesund',
      'Lærdalsøyri',
      'Kyrksæterøra',
      'Kviteseid',
      'Krokstadøra',
      'Kristiansund',
      'Kristiansand',
      'Kragerø',
      'Korgen',
      'Koppang',
      'Kopervik',
      'Konsmo',
      'Kongsvinger',
      'Kongsberg',
      'Kolvereid',
      'Kolbotn',
      'Knarvik',
      'Knappstad',
      'Knappskog',
      'Kløfta',
      'Kleppestø',
      'Kjøpsvik',
      'Kirkenær',
      'Karlshus',
      'Kabelvåg',
      'Judaberg',
      'Jørpeland',
      'Jondal',
      'Jevnaker',
      'Jessheim',
      'Jaren',
      'Isdalstø',
      'Inndyr',
      'Hyllestad',
      'Hylkje',
      'Hvittingfoss',
      'Hundorp',
      'Høyanger',
      'Hovden',
      'Hov',
      'Horten',
      'Hopen',
      'Hønefoss',
      'Hommelvik',
      'Holmestrand',
      'Hol',
      'Hokksund',
      'Hjelset',
      'Herre',
      'Hermansverk',
      'Hemsedal',
      'Hemnesberget',
      'Heggenes',
      'Hauknes',
      'Haugesund',
      'Hauge i Dalane',
      'Harstad',
      'Hareid',
      'Hardbakke',
      'Hansnes',
      'Hamnvik',
      'Hamar',
      'Halden',
      'Hagavik',
      'Gvarv',
      'Gullhaug',
      'Gryllefjord',
      'Grua',
      'Grong',
      'Grimstad',
      'Gravdal',
      'Gratangen',
      'Granvin',
      'Gol',
      'Glomfjord',
      'Gladstad',
      'Justvik',
      'Gjøvik',
      'Gjerstad',
      'Geilo',
      'Frogner',
      'Frekhaug',
      'Fredrikstad',
      'Fossby',
      'Fosnavåg',
      'Førde',
      'Folldal',
      'Florø',
      'Flekkefjord',
      'Flateby',
      'Flå',
      'Fitjar',
      'Finnsnes',
      'Fillan',
      'Fevik',
      'Fetsund',
      'Fedje',
      'Fauske',
      'Farsund',
      'Fagerstrand',
      'Fagernes',
      'Evjen',
      'Evje',
      'Evenskjer',
      'Etne',
      'Espeland',
      'Elverum',
      'Eivindvik',
      'Eikelandsosen',
      'Eike',
      'Eidsvoll',
      'Eidsvåg',
      'Eidfjord',
      'Eide',
      'Egersund',
      'Drøbak',
      'Drammen',
      'Dovre',
      'Dombås',
      'Dokka',
      'Dalen',
      'Dale',
      'Bygland',
      'Bryne',
      'Brumunddal',
      'Bruflat',
      'Brønnøysund',
      'Svortland',
      'Brekstad',
      'Brattvåg',
      'Botngård',
      'Borkenes',
      'Bogen',
      'Bodø',
      'Bø',
      'Blakstad',
      'Bjørkelangen',
      'Bjerkvik',
      'Birkeland',
      'Berkåk',
      'Berger',
      'Bergen',
      'Batnfjordsøra',
      'Barkåker',
      'Ballangen',
      'Balestrand',
      'Bagn',
      'Austrheim',
      'Aursmoen',
      'Aure',
      'Auli',
      'Askim',
      'Asker',
      'Ask',
      'Åsgårdstrand',
      'Aas',
      'Ås',
      'Åros',
      'Årnes',
      'Indre Arna',
      'Arendal',
      'Årdalstangen',
      'Åneby',
      'Andenes',
      'Åndalsnes',
      'Åmot',
      'Åmli',
      'Alvdal',
      'Ålgård',
      'Ålesund',
      'Ålen',
      'Ål',
      'Åkrehamn',
      'Ågotnes',
      'Å i Åfjord',
      'Vormedal',
      'Tingvoll',
      'Oppeid',
      'Hommersåk',
      'Vikevåg',
      'Årøysund',
      'Nesoddtangen',
      'Askøy',
      'Ørsta',
      'Ryggebyen',
      'Vedavågen',
      'Vestnes',
      'Fjellfoten',
      'Neskollen',
      'Kinsarvik',
      'Sjølyststranda',
      'Skei',
      'Ytrebygda',
      'Sandsli',
      'Frydenberg',
      'Stjørdal',
      'Frosta',
      'Sogndal',
      'Rømskog',
      'Meieribyen',
      'Kirkebygda',
      'Elvestad',
      'Forsand',
      'Fjerdingby',
      'Kjenn',
      'Teigebyen',
      'Hurdal',
      'Flisa',
      'Bergset',
      'Engerdal',
      'Lesja',
      'Bismo',
      'Ringebu',
      'Segalstad bru',
      'Slidre',
      'Vang',
      'Trøim',
      'Lierbyen',
      'Lampeland',
      'Rødberg',
      'Revetal',
      'Andebu',
      'Borgheim',
      'Sauland',
      'Birketveit',
      'Helland',
      'Kyrkjebygda',
      'Kleppe',
      'Hjelmelandsvågen',
      'Ydstebøhamn',
      'Bokn',
      'Aksdal',
      'Skogsvågen',
      'Aurlandsvangen',
      'Gaupne',
      'Askvoll',
      'Hornindal',
      'Fiskå',
      'Stordal',
      'Sykkylven',
      'Valderøy',
      'Falkhytta',
      'Elnesvågen',
      'Bruhagen',
      'Surnadal',
      'Liabøen',
      'Rissa',
      'Roan',
      'Steinsdalen',
      'Meldal',
      'Støren',
      'Børsa',
      'Klæbu',
      'Mebonden',
      'Leksvik',
      'Namdalseid',
      'Høylandet',
      'Jøa',
      'Silvalen',
      'Hattfjelldal',
      'Lurøy',
      'Husøya',
      'Vågaholmen',
      'Leinesfjorden',
      'Tennevoll',
      'Sørreisa',
      'Brøstadbotn',
      'Hatteng',
      'Breivikbotn',
      'Tana bru',
      'Ulsteinvik weather pws station',
      'Billingstad',
      'Nordstranda',
      'Lyefjell',
      'Ensjø',
    ],
  },
  {
    country: 'Nepal',
    cities: [
      'Wāliṅ',
      'Tulsīpur',
      'Ṭikāpur',
      'Tānsen',
      'Siraha',
      'Salyān',
      'Rāmechhāp',
      'Rājbirāj',
      'Pyūthān',
      'Pokhara',
      'Pātan',
      'Panauti̇̄',
      'Namche Bazar',
      'Nagarkot',
      'Malaṅgawā',
      'Mahendranagar',
      'Lobuche',
      'Lamjung',
      'Lahān',
      'Kodāri̇̄',
      'Kirtipur',
      'Khā̃dbāri̇̄',
      'Kathmandu',
      'Jumla',
      'Janakpur',
      'Jaleshwar',
      'Īṭahari̇̄',
      'Ilām',
      'Hetauda',
      'Gulariyā',
      'Gaur',
      'Dhulikhel',
      'Dharān',
      'Dhankutā',
      'Dhangaḍhi̇̄',
      'Dārchulā',
      'Dailekh',
      'Dadeldhurā',
      'Butwāl',
      'Birgañj',
      'Biratnagar',
      'Bhojpur',
      'Bharatpur',
      'Bhaktapur',
      'Siddharthanagar',
      'Bhadrapur',
      'Bardiyā',
      'Banepā',
      'Bāglung',
      'Achhām',
      'Besisahar',
      'Birendranagar',
      'Dipayal',
      'Nepalgunj',
      'Chitre',
      'Surkhet',
      'Panauti',
      'Inaruwa',
      'kankrabari Dovan',
      'Triyuga',
      'Madhyapur Thimi',
      'Hari Bdr Tamang House',
      'Bhattarai Danda',
      'Dihi',
    ],
  },
  {
    country: 'Nauru',
    cities: [
      'Yangor',
      'Uaboe',
      'Baiti',
      'Ijuw',
      'Anibare',
      'Anabar',
      'Yaren',
      'Arijejen',
      'Menen',
      'Denigomodu',
    ],
  },
  { country: 'Niue', cities: ['Alofi'] },
  {
    country: 'New Zealand',
    cities: [
      'Woodend',
      'Wigram',
      'Whitianga',
      'Whitford',
      'Whangarei Heads',
      'Whangamata',
      'Whakarongo',
      'Weston',
      'West Melton',
      'West Eyreton',
      'Wellsford',
      'Wellington',
      'Washdyke',
      'Warkworth',
      'Whanganui',
      'Wallaceville',
      'Wakefield',
      'Waiuku',
      'Waitoa',
      'Waitakere',
      'Wairau Valley',
      'Waipu',
      'Wainuiomata',
      'Wainui',
      'Waimate North',
      'Waikowhai',
      'Waikiwi',
      'Waikawa',
      'Waikanae',
      'Waihi Beach',
      'Waiatarua',
      'Waharoa',
      'Turangi',
      'Tokoroa',
      'Titahi Bay',
      'Tinwald',
      'Tiniroto',
      'Timaru',
      'Templeton',
      'Te Kauwhata',
      'Te Anau',
      'Tawa',
      'Taupo',
      'Taupiri',
      'Tangiteroria',
      'Taneatua',
      'Takaka',
      'Tairua',
      'Taipa',
      'Tahunanui',
      'Stoke',
      'Spreydon',
      'Silverstream',
      'Silverdale',
      'Seatoun',
      'Ruawai',
      'Roslyn',
      'Rolleston',
      'Riverhead',
      'Richmond',
      'Renwick',
      'Redcliffs',
      'Rawene',
      'Ravensbourne',
      'Raupunga',
      'Raumati Beach',
      'Rapaura',
      'Rangiriri',
      'Ramarama',
      'Rakaia',
      'Rahotu',
      'Raglan',
      'Pukerua Bay',
      'Pukekohe East',
      'Prebbleton',
      'Portobello',
      'Motueka',
      'Porirua',
      'Ponsonby',
      'Pokeno',
      'Point Chevalier',
      'Plimmerton',
      'Pleasant Point',
      'Pirongia',
      'Wanaka',
      'Pauatahanui',
      'Pauanui',
      'Parnell',
      'Paremoremo',
      'Paremata',
      'Paraparaumu',
      'Parakai',
      'Papatowai',
      'Papanui',
      'Palmerston North',
      'Paihia',
      'Paekakariki',
      'Oxford',
      'Otumoetai',
      'Otorohanga',
      'Otatara',
      'Oropi',
      'Orewa',
      'Oratia',
      'Opua',
      'Opaheke',
      'Onerahi',
      'Onekawa',
      'Omokoroa',
      'Ohinemutu',
      'Oakura',
      'North Shore',
      'Northland',
      'Normanby',
      'Ngunguru',
      'Ngongotaha',
      'Ngatea',
      'Ngaio',
      'New Plymouth',
      'New Brighton',
      'Nelson',
      'Napier',
      'Murupara',
      'Muriwai Beach',
      'Moturoa',
      'Mornington',
      'Moerewa',
      'Methven',
      'Maungaturoto',
      'Maungatapere',
      'Maramarua',
      'Mapua',
      'Manutuke',
      'Manukau City',
      'Mangorei',
      'Mangere',
      'Mangatainoka',
      'Maketu',
      'Lower Hutt',
      'Loburn',
      'Lincoln',
      'Leigh',
      'Leeston',
      'Ladbrooks',
      'Kumeu',
      'Kopuaranga',
      'Kihikihi',
      'Khandallah',
      'Kerikeri',
      'Kelvin Grove',
      'Kelburn',
      'Kawerau',
      'Kawakawa',
      'Kaukapakapa',
      'Katikati',
      'Karori',
      'Kaharoa',
      'Judgeford',
      'Jerusalem',
      'Invercargill',
      'Howick',
      'Homai',
      'Hicks Bay',
      'Heretaunga',
      'Haumoana',
      'Hastings',
      'Hanmer Springs',
      'Hamurana',
      'Hamilton',
      'Halswell',
      'Halfway Bush',
      'Greerton',
      'Frankton Junction',
      'Frankton',
      'Foxton Beach',
      'Fitzroy',
      'Fendalton',
      'Favona',
      'Edgecumbe',
      'East Taieri',
      'Ealing',
      'Dunedin',
      'Drury',
      'Dargaville',
      'Darfield',
      'Dairy Flat',
      'Coromandel',
      'Colville',
      'Clive',
      'Clevedon',
      'Christchurch',
      'Castlepoint',
      'Campbells Bay',
      'Burnham',
      'Bulls',
      'Brooklyn',
      'Brightwater',
      'Bethlehem',
      'Bell Block',
      'Belfast',
      'Beachlands',
      'Bay View',
      'Awakeri',
      'Avondale',
      'Auckland',
      'Atawhai',
      'Ashhurst',
      'Arthurs Point',
      'Amberley',
      'Albany',
      'Ahipara',
      'Opunake',
      'Eltham',
      'Hawera',
      'Patea',
      'Foxton',
      'Levin',
      'Otaki',
      'Eastbourne',
      'Wairoa',
      'Gisborne',
      'Waipawa',
      'Masterton',
      'Hokitika',
      'Greymouth',
      'Westport',
      'Bluff',
      'Riverton',
      'Winton',
      'Clifton',
      'Mission Bay',
      'Belmont',
      'Takapuna',
      'Tauranga',
      'Waitara',
      'Port Chalmers',
      'Milton',
      'Balclutha',
      'Paeroa',
      'Waihi',
      'Papakura',
      'Whakatane',
      'Opotiki',
      'Thames',
      'Waitangi',
      'Gore',
      'Queenstown',
      'Rosedale',
      'Hawthorndale',
      'Strathern',
      'Arrowtown',
      'East Gore',
      'Mataura',
      'Cromwell',
      'Twizel',
      'Maryhill',
      'South Dunedin',
      'Shiel Hill',
      'Andersons Bay',
      'Musselburgh',
      'Caversham',
      'Corstorphine',
      'Saint Kilda',
      'Green Island',
      'Brockville',
      'Maori Hill',
      'Pine Hill',
      'Opoho',
      'Waverley',
      'Sawyers Bay',
      'Karoro',
      'Geraldine',
      'Waimate',
      'Oamaru',
      'Palmerston',
      'Ashburton',
      'Sockburn',
      'Avonhead',
      'Burnside',
      'Upper Riccarton',
      'Ilam',
      'Riccarton',
      'Bryndwr',
      'Bishopdale',
      'Redwood',
      'Mairehau',
      'Shirley',
      'Dallington',
      'Avonside',
      'Wainoni',
      'Aranui',
      'Waimairi Beach',
      'Parklands',
      'Bexley',
      'Rangiora',
      'Kaiapoi',
      'Opawa',
      'Hillsborough',
      'Waltham',
      'Saint Martins',
      'Somerfield',
      'Beckenham',
      'Cashmere',
      'Hoon Hay',
      'Southshore',
      'Welbourn',
      'Frankleigh Park',
      'Hurdon',
      'Westown',
      'Spotswood',
      'Lynmouth',
      'Vogeltown',
      'Merrilands',
      'Inglewood',
      'Stratford',
      'Camborne',
      'Awapuni',
      'Cloverlea',
      'Carterton',
      'Pahiatua',
      'Hokowhitu',
      'West End',
      'Terrace End',
      'Milson',
      'Takaro',
      'Kaitaia',
      'Otangarei',
      'Tikipunga',
      'Whangarei',
      'Raumanga',
      'Morningside',
      'Ruakaka',
      'Helensville',
      'Takanini',
      'Red Hill',
      'Browns Bay',
      'Rothesay Bay',
      'Murrays Bay',
      'Forrest Hill',
      'Greenhithe',
      'Milford',
      'Mangere East',
      'Sunnyvale',
      'Wattle Downs',
      'Konini',
      'Papatoetoe',
      'Otahuhu',
      'Manurewa East',
      'Wiri',
      'Manurewa',
      'Glendowie',
      'Otara',
      'Pakuranga Heights',
      'Botany Downs',
      'Shelly Park',
      'Cockle Bay',
      'Mellons Bay',
      'Pakuranga',
      'Highland Park',
      'Farm Cove',
      'Eastern Beach',
      'Bucklands Beach',
      'Beach Haven',
      'Birkdale',
      'Chatswood',
      'Birkenhead',
      'Hillcrest',
      'Hauraki',
      'Narrow Neck',
      'Saint Marys Bay',
      'Freemans Bay',
      'Waterview',
      'Blockhouse Bay',
      'New Lynn',
      'Green Bay',
      'Lynfield',
      'Te Papapa',
      'Royal Oak',
      'Oranga',
      'Panmure',
      'Tamaki',
      'Ellerslie',
      'Epsom',
      'Balmoral',
      'Three Kings',
      'Sandringham',
      'New Windsor',
      'Owairaka',
      'Westmere',
      'Grey Lynn',
      'Kingsland',
      'Eden Terrace',
      'Waima',
      'Titirangi',
      'Kohimarama',
      'Kelston',
      'Glen Innes',
      'Saint Johns',
      'Meadowbank',
      'Remuera',
      'McLaren Park',
      'West Harbour',
      'Massey East',
      'Te Atatu Peninsula',
      'Te Atatu South',
      'Henderson',
      'Western Heights',
      'Woodlands Park',
      'Massey',
      'Glendene',
      'Newmarket',
      'Grafton',
      'Oneroa',
      'Ostend',
      'Matamata',
      'Acacia Bay',
      'Mangapapa',
      'Te Hapara',
      'Whataupoko',
      'Tamarau',
      'Kaiti',
      'Cambridge',
      'Solway',
      'Rotorua',
      'Monaco',
      'Enner Glynn',
      'Moana',
      'Nelson South',
      'Marybank',
      'Picton',
      'Blenheim',
      'Springlands',
      'Mayfield',
      'Redwoodtown',
      'Witherlea',
      'Snells Beach',
      'Island Bay',
      'Houghton Bay',
      'Strathmore Park',
      'Lyall Bay',
      'Melrose',
      'Owhiro Bay',
      'Mount Cook',
      'Hataitai',
      'Maupuia',
      'Mount Victoria',
      'Roseneath',
      'Homedale',
      'Days Bay',
      'Glendale',
      'Parkway',
      'Arakura',
      'Moera',
      'Waiwhetu',
      'Woburn',
      'Petone',
      'Waterloo',
      'Epuni',
      'Naenae',
      'Stokes Valley',
      'Avalon',
      'Taita',
      'Kelson',
      'Pinehaven',
      'Brown Owl',
      'Maoribank',
      'Totara Park',
      'Upper Hutt',
      'Normandale',
      'Maungaraki',
      'Waitangirua',
      'Whitby',
      'Papakowhai',
      'Cannons Creek',
      'Grenada North',
      'Paparangi',
      'Newlands',
      'Churton Park',
      'Boulcott',
      'Onepoto',
      'Crofton Downs',
      'Wilton',
      'Dannevirke',
      'Mahora',
      'Maraenui',
      'Taradale',
      'Raureka',
      'Flaxmere',
      'Ngaruawahia',
      'Temple View',
      'Sunnyhills',
      'Wakari',
      'Hamilton East',
      'Hamilton West',
      'Alicetown',
      'Riverstone Terraces',
      'Pegasus',
      'Rosebank',
      'Te Aro',
      'Karaka Bays',
      'Woodridge',
      'Aro Valley',
      'Oriental Bay',
      'Wellington Central',
      'Aidanfield',
      'Kennedys Bush',
      'Strowan',
      'Linton Military Camp',
    ],
  },
  {
    country: 'Oman',
    cities: [
      'Sur',
      'Sohar',
      'Sufālat Samā’il',
      'Shināş',
      'Şalālah',
      'Şaḩam',
      'Nizwá',
      'Muscat',
      'Khasab',
      'Izkī',
      '‘Ibrī',
      'Ibrā’',
      'Haymā’',
      'Dib Dibba',
      'Bidbid',
      'Bawshar',
      'Barkā’',
      'Bahlā’',
      'Badīyah',
      'As Suwayq',
      'Seeb',
      'Rustaq',
      'Al Qābil',
      'Liwá',
      'Al Khābūrah',
      'Al Buraymī',
      'Adam',
      'Madḩā’ al Jadīdah',
      'Yanqul',
      'Bayt al ‘Awābī',
      'Al Mazyūnah',
      'Oman Smart Future City',
    ],
  },
  {
    country: 'Panama',
    cities: [
      'Zapotillo',
      'Las Zangüengas',
      'Yaviza',
      'Yape',
      'Volcán',
      'Vista Hermosa',
      'Vista Alegre',
      'Villa Rosario',
      'Villa Carmen',
      'Viguí',
      'Viento Frío',
      'Veracruz',
      'Veladero',
      'Vallerriquito',
      'Valle Rico',
      'Utirá',
      'Ustupo',
      'Tucutí',
      'Tubualá',
      'Tres Quebradas',
      'Toza',
      'Tonosí',
      'Tolé',
      'Tocumen',
      'Tobobe',
      'Toabré',
      'Tinajas',
      'Tijeras',
      'Tebario',
      'Pueblo Nuevo',
      'Sortová',
      'Sorá',
      'Soná',
      'Soloy',
      'Sitio Prado',
      'Sioguí Arriba',
      'Sioguí Abajo',
      'Setegantí',
      'Sardinilla',
      'San Vicente de Bique',
      'San Vicente',
      'Santo Tomás',
      'Santo Domingo',
      'Santiago de Veraguas',
      'Santa Rosa Número Uno',
      'Santa Rosa',
      'Santa Rita',
      'Santa Marta',
      'Santa María',
      'Santa Isabel',
      'Santa Fé',
      'Santa Fe',
      'Santa Cruz',
      'Santa Clara',
      'Santa Catalina',
      'Santa Ana Arriba',
      'San Pedro del Espino',
      'San Pablo Viejo Abajo',
      'San Pablo Nuevo',
      'San Miguelito',
      'San Miguel',
      'San Marcelo',
      'San Lorenzo',
      'San Juan',
      'San José',
      'San Ignacio de Tupile',
      'San Francisco',
      'San Félix',
      'San Felipe',
      'San Carlos',
      'San Bartolo',
      'San Antonio',
      'San Andrés',
      'Salud',
      'Sabanitas',
      'Sabana Grande',
      'Rovira Arriba',
      'Rodeo Viejo',
      'Valle de Riscó',
      'Río Sereno',
      'Río Rita',
      'Río Hondo',
      'Río Hato',
      'Río Grande',
      'Gatún',
      'Río Duque',
      'Río de Jesús',
      'Río Congo',
      'Río Alejandro',
      'Río Abajo',
      'Rincón Hondo',
      'Rincón',
      'Remedios',
      'Panama City',
      'Querévalo',
      'Quebrada de Piedra',
      'Quebrada de Oro',
      'Quebrada del Rosario Arriba',
      'Quebrada de Loro',
      'Canoa',
      'Quebrada Bonita Adentro',
      'Punta Róbalo',
      'Punta Laurel',
      'Punta Peña',
      'Burica',
      'Puerto Vidal',
      'Puerto Pilón',
      'Puerto Obaldía',
      'Puerto Escondido',
      'Puerto Caimito',
      'Puerto Armuelles',
      'Pueblo Viejo',
      'Púcuro',
      'Progreso',
      'Potuga',
      'Potrero Grande',
      'Potrero de Caña',
      'Potrerillos Arriba',
      'Potrerillos Abajo',
      'Portobelo',
      'Portobelillo',
      'Ponuga',
      'Pocrí',
      'Plaza de Caisán',
      'Playón Chico',
      'Playa Leona',
      'Playa Chiquita',
      'Plan de Chorcha',
      'Pinogana',
      'Piedras Gordas',
      'Piedra Roja',
      'Pesé',
      'Perales',
      'Penonomé',
      'Peñas Chatas',
      'Peña Blanca',
      'Pedregal',
      'Pedasí',
      'Paya',
      'Parque Lefevre',
      'Paritilla',
      'Parita',
      'París',
      'Paraíso',
      'Panamá',
      'Palo Grande',
      'Palmira Centro',
      'Palmira',
      'Palmas Bellas',
      'Palenque',
      'Paja de Sombrero',
      'Pacora',
      'Olá',
      'Ocú',
      'Nuevo Vigía',
      'Nuevo San Juan',
      'Nuevo Emperador',
      'Nuevo Chagres',
      'Nuevo Arraiján',
      'Nueva Providencia',
      'Nueva Gorgona',
      'Nueva Arenosa',
      'Nuestro Amo',
      'Nuario',
      'Nombre de Dios',
      'Natá',
      'Narganá',
      'Mulatupo',
      'Mortí',
      'Montijo',
      'Monte Lirio',
      'La Montañuela',
      'Monjarás',
      'Monagrillo',
      'Mogollón',
      'Miramar',
      'Miguel de La Borda',
      'Metetí',
      'Mendoza',
      'Mata del Nance',
      'María Chiquita',
      'Mariabé',
      'Margarita',
      'Maraca',
      'Macaracas',
      'Los Valles',
      'Los Santos',
      'Los Pozos',
      'Los Olivos',
      'Los Naranjos',
      'Los Lotes',
      'Los Llanitos',
      'Los Higos',
      'Los Corotúes',
      'Los Cerros de Paja',
      'Los Cerritos',
      'Los Castillos',
      'Los Canelos',
      'Los Asientos',
      'Los Ángeles',
      'Los Anastacios',
      'Los Algarrobos',
      'Lolá',
      'Buabidi',
      'Llano Ñopo',
      'Llano Marín',
      'Llano Largo',
      'Llano Grande',
      'Llano de Piedra',
      'Llano de La Cruz',
      'Llano Bonito',
      'Llano Abajo',
      'Limones',
      'Limón de Tijeras',
      'Lídice',
      'Leones Arriba',
      'La Yeguada',
      'La Tronosa',
      'La Trinidad',
      'La Tiza',
      'Las Uvas',
      'Las Trancas',
      'Las Tablas',
      'Las Sabanas',
      'Las Palmitas',
      'Las Palmas',
      'Las Ollas Arriba',
      'La Soledad',
      'Las Minas',
      'Las Marias',
      'Las Margaritas',
      'Las Lomas',
      'Lajas de Tolé',
      'Las Lajas',
      'Las Huacas del Quije',
      'Las Huacas',
      'Las Guabas',
      'Las Cumbres',
      'Altos de San Francisco',
      'Las Cruces',
      'Las Colinas',
      'Las Cabras',
      'La Raya de Santa María',
      'La Raya de Calobre',
      'Gobernadora',
      'Pitaloza Arriba',
      'La Pintada',
      'La Peña',
      'La Pasera',
      'La Palma',
      'La Mitra',
      'La Miel',
      'La Mesa',
      'La Loma',
      'Quebro',
      'La Laja',
      'La Laguna',
      'Lajas Adentro',
      'Lajamina',
      'La Herradura',
      'La Guinea',
      'Lagarterita',
      'La Garceana',
      'La Estrella',
      'La Espigadilla',
      'La Esperanza',
      'La Esmeralda',
      'La Ermita',
      'La Ensenada',
      'La Enea',
      'La Encantada',
      'La Concepción',
      'La Colorada',
      'La Chorrera',
      'La Carrillo',
      'La Cabuya',
      'La Cabima',
      'La Boca del Guásimo',
      'La Arena',
      'Juay',
      'Juan Díaz',
      'Jaramillo Arriba',
      'Jaqué',
      'Horconcitos',
      'Hato Pilón',
      'Hato Montaña',
      'Hato Julí',
      'Hato Jobo',
      'Hato Culantro',
      'Hato Corotú',
      'Hato Chamí',
      'Guzmán',
      'Las Guías Abajo',
      'Guayabito',
      'Guayabal',
      'Guarumal',
      'Guararé Arriba',
      'Guararé',
      'Gualaca',
      'Guadalupe',
      'Guacá Arriba',
      'Guabito',
      'Guabal',
      'Gonzalo Vásquez',
      'Gómez',
      'Gobea',
      'Gatuncillo',
      'Gatú',
      'Puerto Lindo',
      'Garachiné',
      'Flores',
      'Finca Blanco',
      'Espavé',
      'Escobal',
      'El Valle de La Unión',
      'El Uvito',
      'El Toro',
      'El Tejar',
      'El Silencio',
      'El Sesteadero',
      'El Roble',
      'El Rincón',
      'El Retiro',
      'El Quiteño',
      'El Progreso',
      'El Prado',
      'El Potrero',
      'El Porvenir',
      'El Piro',
      'Pilón',
      'El Picador',
      'El Peñón',
      'El Pedregoso',
      'El Pantano',
      'El Pájaro',
      'El Nancito',
      'El Muñoz',
      'El María',
      'El Marañón',
      'El Manantial',
      'Salto Dupí',
      'El Macano',
      'El Limón',
      'Líbano',
      'El Higo',
      'El Hato',
      'El Guásimo',
      'El Guabo',
      'El Giral',
      'Farallón',
      'El Espino de Santa Rosa',
      'El Espino Amarillo',
      'El Espino',
      'El Espinal',
      'Entradero',
      'El Empalme',
      'El Ejido',
      'El Cristo',
      'El Cortezo',
      'El Copé',
      'El Coco',
      'El Cocla',
      'El Cocal',
      'El Chorrillo',
      'El Chirú',
      'El Cedro',
      'El Carate',
      'El Capurí',
      'El Caño',
      'El Cañafístulo',
      'El Calabacito',
      'Cacao',
      'El Cacao',
      'El Bebedero',
      'El Barrito',
      'El Barrero',
      'Cerro Banco',
      'El Bale',
      'El Alto',
      'Dos Ríos',
      'Dolega District',
      'Divalá',
      'David',
      'Kusapín',
      'Curundú',
      'Llano Culebra',
      'Cuipo',
      'Cucunatí',
      'Cuango',
      'Cristóbal',
      'Corozal',
      'Cordillera',
      'Colón',
      'Coclé del Norte',
      'Coclecito',
      'Coclé',
      'Cochea Abajo',
      'Ciricito de Los Sotos',
      'Churuquita Grande',
      'Churuquita Chiquita',
      'Chupampa',
      'Chupá',
      'Chumical',
      'Barrio Guadalupe',
      'Chitré',
      'Chiriquí Grande',
      'Chiriquí',
      'Chimán',
      'Chilibre',
      'Chiguirí Arriba',
      'Chigoré',
      'Chichica',
      'Chepo',
      'Chepillo',
      'Chepigana',
      'Changuinola',
      'Chame',
      'Chacarero',
      'Cerro Viejo',
      'Cerro Venado',
      'Cerro Silvestre',
      'Cerro Punta',
      'Cerro Plata',
      'Cerro Largo',
      'Cerro Iglesia',
      'Cerro Caña',
      'Cerro Cama',
      'Cerro Azul',
      'Cermeño',
      'Celmira',
      'Cauchero',
      'Cativá',
      'Cascabel',
      'Cartí Sugtupu',
      'Capira',
      'Capellanía',
      'San Isidro',
      'Canto del Llano',
      'Kankintú',
      'Cañita',
      'Cañazas',
      'Cañaveral',
      'Cañas Gordas',
      'Cañas',
      'Cambutal',
      'Camarón Arriba',
      'Calzada Larga',
      'Calovébora',
      'Calobre',
      'Calidonia',
      'Caldera',
      'Calabacito',
      'Caimito',
      'Caimitillo',
      'Cacique',
      'Cabuya',
      'Cabra Número Uno',
      'Caballero',
      'Burunga',
      'Bugabita Arriba',
      'Bugaba',
      'Buenos Aires',
      'Buena Vista',
      'Brujas',
      'Breñón',
      'Boró',
      'Boquete',
      'Boquerón',
      'Bocas del Toro',
      'Boca de Parita',
      'Boca de Río Indio',
      'Boca de Río Chiriquí',
      'Boca del Monte',
      'Boca de Cupe',
      'Boca Chica',
      'Bisvalles',
      'Bisira',
      'Bijagual',
      'Betania',
      'Bella Vista',
      'Bejuco',
      'Bayano',
      'Isla Bastimentos',
      'Bastimentos',
      'Barranco Colorado',
      'Barranco',
      'Barnizal',
      'Bajos de Güera',
      'Bajo Corral',
      'Bajo Boquete',
      'Bahía Honda',
      'Bahía Azul',
      'Bágala',
      'Ave María',
      'Atalaya',
      'Aserrío de Gariché',
      'Arraiján',
      'Arosemena',
      'La Arenosa',
      'Arenas',
      'Antón',
      'Ancón',
      'Altos de Güera',
      'Alto del Espino',
      'Alto de La Estancia',
      'Alto Caballero',
      'Alto Boquete',
      'Almirante',
      'Alcalde Díaz',
      'Alanje',
      'Ailigandí',
      'Aguas Blancas',
      'Aguadulce',
      'Agua de Salud',
      'Agua Buena',
      'Achutupo',
      'Achiote',
      'Pásiga',
      'Jingurudo',
      'Santa Rita Arriba',
      'Lajas Blancas',
      'Unión Chocó',
      'Howard',
      'Villa Lourdes',
      'Cedro Arriba',
      'San Juan Bautista',
      'Oria Arriba',
      'Villa Unida',
      'Gonzalillo',
      'Nuevo Belén',
      'Nueva Esperanza',
      'Unión de Azuero',
      'Tortí',
      'Barriales',
      'Platanillo',
      'Camogantí',
      'Agua Fría Número Uno',
      'Bayamón',
      'Unión Santeña',
      'Contadora',
      'Norteño',
      'Sieyik',
      'Barranco Adentro',
      'Quebrada del Rosario',
      'Paso Canoas Arriba',
      'El Palmar',
      'Manaca Norte',
      'Manaca Civil',
      'Los Algarrobos Arriba',
      'Nuevo San Carlitos',
      'Rambala',
      'Valle de Las Minas',
      'Quebrada Tula',
      'Boca de Balsa',
      'Coronte',
      'Oma',
      'Cabecera de Cerro Puerco',
      'Santa Lucía',
      'El Puerto',
      'Mirones',
      'Bajo Solís',
      'Cerro Pelado',
      'Lajero',
      'Loma Yuca',
      'Los Pollos',
      'San Juan de Dios',
      'Río Luís',
      'Güibale',
      'Paredón Arriba',
      'Alto de Jesús',
      'La Pesa',
      'Ciricito Abajo',
    ],
  },
  {
    country: 'Peru',
    cities: [
      'Zorritos',
      'Yurimaguas',
      'Yuracyacu',
      'Yungay',
      'Yungar',
      'Yorongos',
      'Yauya',
      'Yanas',
      'Yanama',
      'Yanac',
      'Yambrasbamba',
      'Yamango',
      'Vista Alegre',
      'Virú',
      'Vice',
      'Uticyacu',
      'Usquil',
      'Ucuncha',
      'Uco',
      'Uchiza',
      'Tunal',
      'Tumbes',
      'Túcume',
      'Trujillo',
      'Trompeteros',
      'Trinidad',
      'Tres Unidos',
      'Tournavista',
      'Totora',
      'Tocmoche',
      'Tocache',
      'Tingo María',
      'Tingo',
      'Tinco',
      'Ticapampa',
      'Tembladera',
      'Tayabamba',
      'Taurija',
      'Tauca',
      'Tarica',
      'Tarapoto',
      'Tantamayo',
      'Tambo Grande',
      'Tamarindo',
      'Tamanco',
      'Talara',
      'Tacabamba',
      'Tablazo Norte',
      'Tabalosos',
      'Tabaconas',
      'Suyo',
      'Sullana',
      'Sucre',
      'Succha',
      'Sorochuco',
      'Soritor',
      'Sondorillo',
      'Sondor',
      'Soloco',
      'Sojo',
      'Socota',
      'Sitabamba',
      'Sinsicap',
      'Singa',
      'Sinchao',
      'Simbal',
      'Sillapata',
      'Sihuas',
      'Shupluy',
      'Shunqui',
      'Shucushuyacu',
      'Shirac',
      'Shilla',
      'Shapaja',
      'Shamboyacu',
      'Sexi',
      'Sechura',
      'Sayapullo',
      'Sauce',
      'Sartimbamba',
      'Saquena',
      'Saposoa',
      'Sapillica',
      'Sapalache',
      'Santo Domingo',
      'Santiago de Chuco',
      'Santiago de Cao',
      'Santa Rosa',
      'Santa Isabel',
      'Santa Elena',
      'Santa Cruz De Succhabamba',
      'Santa Cruz',
      'Santa',
      'San Rafael',
      'San Pedro de Lloc',
      'San Pablo',
      'San Marcos',
      'San Luis',
      'San Lucas ( Pueblo Nuevo de Colan)',
      'San Lorenzo',
      'San Juan',
      'Sisa',
      'San José',
      'San Jacinto',
      'San Ignacio',
      'San Gregorio',
      'San Felipe',
      'San Carlos',
      'San Bernardino',
      'San Benito',
      'San Antonio',
      'Saña',
      'Samanco',
      'Salpo',
      'Sallique',
      'Salitral',
      'Salinera Colán',
      'Guadalupito',
      'Salaverry',
      'Salas',
      'Sacanche',
      'Roque',
      'Rondos',
      'Rioja',
      'Requena',
      'Reque',
      'Recuay',
      'Ranrahirca',
      'Ramón Castilla',
      'Quivilla',
      'Quiruvilca',
      'Quinuabamba',
      'Quinjalca',
      'Quillo',
      'Quiches',
      'Querocoto',
      'Querocotillo',
      'Querecotillo',
      'Puños',
      'Punchao',
      'Puerto Santa',
      'Puerto Rico',
      'Puerto Inca',
      'Puerto Galilea',
      'Puerto Casma',
      'Chao',
      'Pueblo Nuevo',
      'Pucallpa',
      'Hacienda Pucala',
      'Pucacaca',
      'Posic',
      'Poroto',
      'Pomalca',
      'Pomahuaca',
      'Pomabamba',
      'Piura',
      'Piscoyacu',
      'Pira',
      'Pimentel',
      'Pilluana',
      'Picsi',
      'Picota',
      'Pelejo',
      'Pebas',
      'Paucas',
      'Paltashaco',
      'Pátapo',
      'Parinari',
      'Papayal',
      'Pantoja',
      'Panao',
      'Pampas',
      'Pamparomas',
      'Pampa Hermosa',
      'Pallasca',
      'Paita',
      'Paimas',
      'Paiján',
      'Pacora',
      'Paclas',
      'Pachas',
      'Paccha',
      'Pacasmayo',
      'Pacanga',
      'Pacaipampa',
      'Oyotún',
      'Oxamarca',
      'Otuzco',
      'Orellana',
      'Omia',
      'Olto',
      'Olmos',
      'Olleros',
      'Obas',
      'Nueva Arica',
      'Niepos',
      'Nepeña',
      'Negritos',
      'Navarro',
      'Nauta',
      'Nanchoc',
      'Namora',
      'Namballe',
      'Moyobamba',
      'Motupe',
      'Morropón',
      'Mórrope',
      'Moro',
      'Montevideo',
      'Montero',
      'Monsefú',
      'Mollepata',
      'Mocupe',
      'Mochumí',
      'Moche',
      'Miraflores',
      'Miracosta',
      'Milpuc',
      'Mendoza',
      'Matapalo',
      'La Matanza',
      'Masisea',
      'Marcavelica',
      'Marcara District',
      'Marcabal',
      'Mancos',
      'Máncora',
      'Malvas',
      'Maino',
      'Magdalena',
      'Mache',
      'Macate',
      'Luya',
      'Lucmapampa',
      'Lucma',
      'Los Organos',
      'Lonya Grande',
      'Lonya Chico',
      'Longuita',
      'Longotea',
      'Longar',
      'Lobitos',
      'Lluchubamba',
      'Llata',
      'Llapa',
      'Llama',
      'Llacanora',
      'Licupis',
      'Levanto',
      'La Unión',
      'Las Palmas',
      'Las Lomas',
      'Laredo',
      'La Peca',
      'La Pampa',
      'Lamud',
      'La Merced',
      'Lambayeque',
      'Lamas',
      'La Legua - San Jacinto',
      'Lajas',
      'La Jalca',
      'Huangala',
      'La Huaca',
      'Lagunas',
      'La Grama',
      'La Esperanza',
      'La Cuesta',
      'Lacabamba',
      'La Arena',
      'Juanjuí',
      'Juan Guerra',
      'Jilili',
      'Jepelacio',
      'Jeberos',
      'Jayanca',
      'Jangas',
      'Jamalca',
      'Jaén',
      'Iquitos',
      'Iparia',
      'Inguilpata',
      'Incahuasi',
      'Inahuaya',
      'Iberia',
      'Huicungo',
      'Huaylillas',
      'Huata',
      'Huarmaca',
      'Huaripampa',
      'Huari',
      'Huaraz',
      'Huaranchal',
      'Huánuco',
      'Huandoval',
      'Huanchay',
      'Huanchaco',
      'Huancas',
      'Huancabamba',
      'Huambos',
      'Huamachuco',
      'Huallanca',
      'Hualgayoc',
      'Huacrachuco',
      'Huachis',
      'Huacchis',
      'Huacaybamba',
      'Huacaschuque',
      'Huacapampa',
      'Huacachi',
      'Honoria',
      'Habana',
      'Guzmango',
      'Guadalupe',
      'Granada',
      'Frias',
      'La Florida',
      'Florida ( Pomacochas)',
      'Ferreñafe',
      'Eten',
      'San Antonio del Estrecho',
      'Encañada',
      'El Prado',
      'El Porvenir',
      'El Parco District',
      'El Alto',
      'Dos Pueblos',
      'Cutervo',
      'Curgos',
      'Cumba',
      'La Caleta Culebras',
      'Cujillo',
      'Cuispes',
      'Corongo',
      'Coris',
      'Contamana',
      'Concordia',
      'Conchucos',
      'Colcamar',
      'Colcabamba',
      'Colasay',
      'Coishco',
      'Cochabamba',
      'Cocabamba',
      'Churuja',
      'Churubamba',
      'Chuquis',
      'Chuquibamba',
      'Chumuch',
      'Chulucanas',
      'Chugur',
      'Chugay',
      'Chota',
      'Choros',
      'Chongoyape',
      'Chocope',
      'Chisquilla',
      'Chirinos',
      'Chiriaco',
      'Chingas',
      'Chimbote',
      'Chillia',
      'Chilete',
      'Chiguirip',
      'Chiclayo',
      'Chicama',
      'Chetilla',
      'Chepén',
      'Chavinillo',
      'Chazuta',
      'Charat',
      'Chalaco',
      'Chaglla',
      'Chachapoyas',
      'Chacas',
      'Celendín',
      'Cauday',
      'Catache',
      'Catacaos',
      'Catac',
      'Caspisapa',
      'Casma',
      'Cascas',
      'Cascapara',
      'Casa Grande',
      'Carhuaz',
      'Caraz',
      'Carabamba',
      'Canchaque',
      'Cancas',
      'Cañaveral',
      'Cañaris',
      'Calzada',
      'Caleta Cruz',
      'Cajaruro',
      'Cajamarquilla',
      'Cajamarca',
      'Cajabamba',
      'Cahuac',
      'Cachachi',
      'Cabana',
      'Caballococha',
      'Buenos Aires',
      'Breu',
      'Bretaña',
      'Bolognesi',
      'Bernal',
      'Bellavista',
      'Barranquita',
      'Barranca',
      'Bambas',
      'Bambamarca',
      'Balsas',
      'Bagua Grande',
      'Bagua',
      'Ayabaca',
      'Aucayacu',
      'Ascope',
      'Aricapampa',
      'Arenal',
      'Arancay',
      'Aramango',
      'Anta',
      'Angasmarca',
      'Amotape',
      'Amashca',
      'Aija',
      'Aguas Verdes',
      'Agua Blanca',
      'Agallpampa',
      'Aczo',
      'Acopampa',
      'Acomayo',
      'Aco',
      'Puerto Callao',
      'Campo Verde',
      'Nueva Requena',
      'La Coipa',
      'La Sacilia',
      'Alianza Cristiana',
      'Utco',
      'Las Pirias',
      'Zapatero',
      'Cuñumbuqui',
      'Utcurarca',
      'Manuel Antonio Mesones Muro',
      'La Breita',
      'Zarumilla',
      'Belen',
      'Zurite',
      'Zepita',
      'Yura',
      'Yunguyo',
      'Yunga',
      'Yucay',
      'Yauyos',
      'Yaurisque',
      'Yauri',
      'Yauli',
      'Yauca',
      'Yarabamba',
      'Yanque',
      'Yangas',
      'Yanaquihua',
      'Yanaoca',
      'Yanahuaya',
      'Yanahuanca',
      'Yanacancha',
      'Yanaca',
      'Vizcachane',
      'Vitis',
      'Vitarte',
      'Vischongo',
      'Viraco',
      'Vinchos',
      'Viñac',
      'Vilque Chico',
      'Vilque',
      'Tupac Amaru',
      'Villa Rica',
      'Vilcanchos',
      'Vilcabamba',
      'Vilca',
      'Vilavila',
      'Ventanilla',
      'Velinga',
      'Velille',
      'Végueta',
      'Usicayos',
      'Urubamba',
      'Uripa',
      'Urcos',
      'Urasqui',
      'Upahuacho',
      'Uñon',
      'Umachiri',
      'Uchumayo',
      'Ubinas',
      'Tuti',
      'Turpo',
      'Turpay',
      'Tupe',
      'Tungasuca',
      'Totos',
      'Totoral',
      'Toro',
      'Toraya',
      'Torata',
      'Tomepampa',
      'Tomay Kichwa',
      'Tomas',
      'Tocota',
      'Tibillo',
      'Tisco',
      'Tirapata',
      'Tiquillaca',
      'Tinyahuarco',
      'Tintay',
      'Tinta',
      'Ticrapo',
      'Ticllos',
      'Ticllas',
      'Ticaco',
      'Tiabaya',
      'Taurisma',
      'Tauripampa',
      'Tauria',
      'Tarucani',
      'Tarucachi',
      'Tarma',
      'Tarata',
      'Taraco',
      'Taquile',
      'Tapo',
      'Tapay',
      'Tapairihua',
      'Tapacocha',
      'Tanta',
      'Tamburco',
      'Tambo Quemado',
      'Tambopata',
      'Tambobamba',
      'Tambo',
      'Tambillo',
      'Talavera',
      'Tacna',
      'Susapaya',
      'Surcubamba',
      'Santiago de Surco',
      'Surco',
      'Supe',
      'Sunampe',
      'Sumbilca',
      'Suitucancha',
      'Subtanjalla',
      'Soraya',
      'Soras',
      'Socos',
      'Socabaya',
      'Sitajara',
      'Sincos',
      'Sina',
      'Sicuani',
      'Sicaya',
      'Sibayo',
      'Sepahua',
      'Saylla',
      'Sayán',
      'Saurama',
      'Satipo',
      'Sarhua',
      'Sapallanga',
      'San Vicente de Cañete',
      'Santiago',
      'Santa Teresa',
      'Santa Rita de Siguas',
      'Santa María',
      'Santa Lucía',
      'Santa Eulalia',
      'Santa Ana',
      'San Salvador',
      'San Ramón',
      'San Pedro de Cajas',
      'San Pedro',
      'San Miguel de Cauri',
      'San Miguel',
      'San Mateo',
      'San Juan Bautista',
      'San Jerónimo',
      'San Jeronimo De Tunan',
      'San Isidro',
      'Sangallaya',
      'San Francisco',
      'Sandia',
      'San Clemente',
      'San Buenaventura',
      'San Bartolo',
      'Sañayca',
      'San Agustin',
      'Samegua',
      'Sama Grande',
      'Salcabamba',
      'Sacsamarca',
      'Sabandia',
      'Rosaspata',
      'Río Grande',
      'Ricardo Palma',
      'Ranracancha',
      'Quito-Arma',
      'Quiquijana',
      'Quinua',
      'Quiñota',
      'Quinocay',
      'Quinistaquillas',
      'Quince Mil',
      'Quilmaná',
      'Quillabamba',
      'Quilcata',
      'Quilca',
      'Quilahuani',
      'Quicacha',
      'Quiaca',
      'Queropalca',
      'Querobamba',
      'Quequeña',
      'Quellouno',
      'Quehue',
      'Quebrada Honda',
      'Putina',
      'Pusi',
      'Puquio',
      'Puquina',
      'Punta Hermosa',
      'Punta de Bombón',
      'Puno',
      'Pullo',
      'Pujocucho',
      'Puyca',
      'Supe Puerto',
      'Puerto Ocopa',
      'Puerto Maldonado',
      'Pucyura',
      'Pucusana',
      'Pucara',
      'Pozuzo',
      'Potoni',
      'Poroy',
      'Pomata',
      'Pomacocha',
      'Pomacanchi',
      'Pomacancha',
      'Pocsi',
      'Pocollay',
      'Pocohuanca',
      'Pitumarca',
      'Pisco',
      'Pisac',
      'Pirca',
      'Pilpichaca',
      'Pillpinto',
      'Pillcopata',
      'Pilcuyo',
      'Pilcomayo',
      'Pilchaca',
      'Pichirhua',
      'Pichigua',
      'Pichari',
      'Phara',
      'Perené',
      'Pazos',
      'Pausa',
      'Paucartambo',
      'Paucarcolla',
      'Paucarbamba',
      'Paucar',
      'Pativilca',
      'Pataypampa',
      'Patambuco',
      'Paruro',
      'Parco',
      'Paratia',
      'Paramonga',
      'Paracas',
      'Pangoa',
      'Pancan',
      'Pampas Chico',
      'Pampamarca',
      'Pampa de Tate',
      'Pampacolca',
      'Pampachiri',
      'Pampa Cangallo',
      'Palpacachi',
      'Palpa',
      'Pallanchacra',
      'Palcamayo',
      'Palca',
      'Paico',
      'Pacucha',
      'Pacocha',
      'Pacobamba',
      'Pachaconas',
      'Paccho',
      'Paccaritambo',
      'Pacaycasa',
      'Pacaraos',
      'Pacapausa',
      'Paca',
      'Oyón',
      'Oyolo',
      'Oxapampa',
      'Otoca',
      'Orurillo',
      'Oropesa',
      'Orcotuna',
      'Orcopampa',
      'Ongoy',
      'Ondores',
      'Omate',
      'Omas',
      'Omacha',
      'Ollantaytambo',
      'Ollachea',
      'Ocuviri',
      'Ocucaje',
      'Ocros',
      'Ocoyo',
      'Ocoruro',
      'Ocongate',
      'Ocoña',
      'Ocobamba',
      'Ocaña',
      'Nuñoa',
      'Nuevo Imperial',
      'Ninacaca',
      'Asentamiento Humano Nicolas de Pierola',
      'Nicasio',
      'Nazca',
      'Muquiyauyo',
      'Muqui',
      'Mungui',
      'Muñani',
      'Mosca',
      'Morococha',
      'Morcolla',
      'Moquegua',
      'Mollepampa',
      'Mollendo',
      'Mollebamba',
      'Molinos',
      'Moho',
      'Mazo Cruz',
      'Mazamari',
      'Mayocc',
      'Matucana',
      'Matalaque',
      'Mariposa',
      'Mariatana',
      'Margos',
      'Minas de Marcona',
      'Marco',
      'Marcas',
      'Marcapomacocha',
      'Marcapata',
      'Marcabamba',
      'Marca',
      'Maray',
      'Maras',
      'Maranura',
      'Maranganí',
      'Mara',
      'Mangas',
      'Mañazo',
      'Mamara',
      'Mala',
      'Madrigal',
      'Madean',
      'Macusani',
      'Machupicchu',
      'Macari',
      'Macachacra',
      'Maca',
      'Luricocha',
      'Lucre',
      'Lucanas',
      'Los Aquijes',
      'Locumba',
      'Locroja',
      'Lluta',
      'Llusco',
      'Lloque',
      'Llocllapampa',
      'Llipata',
      'Llipa',
      'Llauta',
      'Llalli',
      'Llacllin',
      'Llaclla',
      'Livitaca',
      'Lircay',
      'Lincha',
      'Limbani',
      'Limatambo',
      'Lima',
      'Leticia',
      'Layo',
      'La Tinguiña',
      'Las Yaras',
      'Lari',
      'Lares',
      'Laraqueri',
      'Laraos',
      'Laramate',
      'La Oroya',
      'Lanlacuni Bajo',
      'Langui',
      'Lampian',
      'Lampa',
      'La Molina',
      'Lambrama',
      'Lamay',
      'Lahuaytambo',
      'La Esmeralda',
      'La Curva',
      'Lachaqui',
      'La Capilla',
      'Kishuara',
      'Junín',
      'Juliaca',
      'Juli',
      'Julcan',
      'Julcamarca',
      'Jivia',
      'Jauja',
      'Jatun Orcochiri',
      'Jarpa',
      'Jucul',
      'Izcuchaca',
      'Islay (Matarani)',
      'Iray',
      'Iquipi',
      'Ingenio',
      'Independencia',
      'Incuyo',
      'Inchupalla',
      'Iñapari',
      'Imperial',
      'Ilo',
      'Ilave',
      'Ilabaya',
      'Ihuari',
      'Ichupampa',
      'Ichuña',
      'Ica',
      'Humay',
      'Umamarca',
      'Huayucachi',
      'Huayrapata',
      'Huayllay',
      'Huayllapampa',
      'Huayllahuara',
      'Huayllabamba',
      'Huayana',
      'Huayacundo Arma',
      'Huaura',
      'Huasta',
      'Huasahuasi',
      'Huaros',
      'Huarocondo',
      'Huaro',
      'Huarmey',
      'Huaribamba',
      'Huariaca',
      'Huaral',
      'Huaquirca',
      'Huanza',
      'Huanuara',
      'Huanta',
      'Huanoquite',
      'Huanipaca',
      'Huangascar',
      'Huancayo',
      'Huancaya',
      'Huancavelica',
      'Huancarqui',
      'Huancaray',
      'Huancarama',
      'Huancapi',
      'Huancano',
      'Hacienda Huancane',
      'Huampara',
      'Huambo',
      'Huambalpa',
      'Huamatambo',
      'Huamantanga',
      'Huamanquiquia',
      'Huamanguilla',
      'Huamali',
      'Hualmay',
      'Huacrapuquio',
      'Huachos',
      'Huachocolpa',
      'Huacho',
      'Huacar',
      'Huacaña',
      'Hongos',
      'Haquira',
      'Goyllarisquizga',
      'Gorgor',
      'Estique',
      'El Tambo',
      'Progreso',
      'El Ingenio',
      'El Descanso',
      'El Carmen',
      'El Cardo',
      'Desaguadero',
      'Cuyocuyo',
      'Cusipata',
      'Cusco',
      'Curibaya',
      'Curahuasi',
      'Cupi',
      'Cullhuas',
      'Cuenca',
      'Cuchumbaya',
      'Cruz Blanca',
      'Crucero',
      'Coyllurqui',
      'Coya',
      'Cotaruse',
      'Cotaparaco',
      'Cotahuasi',
      'Cotabambas',
      'Corpanqui',
      'Corire',
      'Corculla',
      'Corani',
      'Coracora',
      'Coporaque',
      'Copa',
      'Conima',
      'Congas',
      'Condoroma',
      'Concho',
      'Conchamarca',
      'Concepción',
      'Combapata',
      'Comas',
      'Colta',
      'Colquepata',
      'Colquemarca',
      'Colpas',
      'Colonia',
      'Colcha',
      'Colca',
      'Cojata',
      'Cochas',
      'Cocharcas',
      'Cocas',
      'Cocachacra',
      'Coayllo',
      'Coata',
      'Coasa',
      'Coalaque',
      'Circa',
      'Cieneguilla',
      'Chuschi',
      'Churcampa',
      'Chuquibambilla',
      'Chupamarca',
      'Chupaca',
      'Chupa',
      'Chungui',
      'Chumpi',
      'Chucatamani',
      'Chosica',
      'Chontabamba',
      'Chojata',
      'Chocos',
      'Choco',
      'Chivay',
      'Chipispaya',
      'Chipao',
      'Chincho',
      'Chincheros',
      'Chinchero',
      'Chinchaypujio',
      'Chincha Baja',
      'Chincha Alta',
      'Chilcas',
      'Chilca',
      'Chiguata',
      'Chicla',
      'Chichas',
      'Chicche',
      'Chiara',
      'Checca',
      'Checacupe',
      'Chaviña',
      'Chaupimarca',
      'Chasquitambo',
      'Charcana',
      'Characato',
      'Chapimarca',
      'Changuillo',
      'Chancay',
      'Chambara',
      'Chamaca',
      'Challhuahuacho',
      'Challabamba',
      'Chalhuanca',
      'Chalcos',
      'Chala',
      'Chaclacayo',
      'Chaccrampa',
      'Chacapampa',
      'Chacapalpa',
      'Cerro de Pasco',
      'Ccorca',
      'Ccapi',
      'Cayna',
      'Cayarani',
      'Cayara',
      'Cauri',
      'Ccatca',
      'Catahuasi',
      'Castrovirreyna',
      'Carumas',
      'Carmen Alto',
      'Carhuanca',
      'Carhuamayo',
      'Carapo',
      'Carania',
      'Carampoma',
      'Caraybamba',
      'Caracoto',
      'Carac',
      'Carabayllo',
      'Capaya',
      'Capacmarca',
      'Capachica',
      'Canta',
      'Canis',
      'Cangallo',
      'Candarave',
      'Canchayllo',
      'Canaria',
      'Caminaca',
      'Camilaca',
      'Camaná',
      'Callao',
      'Callanmarca',
      'Callalli',
      'Callahuanca',
      'Caleta de Carquín',
      'Calca',
      'Calapuja',
      'Calango',
      'Calana',
      'Calacoa',
      'Cajacay',
      'Caja',
      'Cairani',
      'Caicay',
      'Cahuacho',
      'Cacra',
      'Cachora',
      'Cachimayo',
      'Cabanilla',
      'Cabanaconde',
      'Baños',
      'Azángaro',
      'Ayrampuni',
      'Ayo',
      'Ayna',
      'Ayaviri',
      'Ayacucho',
      'Aucallama',
      'Atuncolla',
      'Atiquipa',
      'Atico',
      'Ataura',
      'Asquipata',
      'Asillo',
      'Asia',
      'Arma',
      'Arequipa',
      'Arapa',
      'Arahuay',
      'Aquia',
      'Apongo',
      'Aplao',
      'Apata',
      'Antauta',
      'Antaparco',
      'Antabamba',
      'Aniso',
      'Andaray',
      'Andarapa',
      'Andamarca',
      'Andajes',
      'Andahuaylillas',
      'Andahuaylas',
      'Anchonga',
      'Ancahuasi',
      'Ananea',
      'Ambo',
      'Ambar',
      'Alis',
      'El Algarrobal',
      'Alca',
      'Ajoyani',
      'Ayrihuanca',
      'Ahuac',
      'Acos',
      'Acoria',
      'Acolla',
      'Acocro',
      'Acobambilla',
      'Acobamba',
      'Accocunca',
      'Achoma',
      'Achaya',
      'Achanizo',
      'Accomarca',
      'Accha',
      'Acas',
      'Acarí',
      'Abancay',
      'Mosoc Llacta',
      'Taray',
      'Huayllati',
      'Sabaino',
      'Ayahuay',
      'Huayllo',
      'Santo Tomas',
      'Curpahuasi',
      'Jacobo Hunter',
      'Huarancante',
      'Ccolo',
      'Huarichancara',
      'Llongasora',
      'Jayune',
      'Chuquitira',
      'Callanca',
      'Cullcuyre',
      'Cahuanuyo',
      'Ccaquiracunca',
      'Chignayhua',
      'Ccuntuma',
      'Lima Pampa',
      'Jayobamba',
      'Queromarca',
      'Qquea',
      'Conchopata',
      'Mosoc Cancha',
      'Huayna Alcalde',
      'Aucara',
      'Caujul',
      'Bajo Pichanaqui',
      'Huaccana',
      'Echarate',
      'Genaro Herrera',
      'Coviriali',
      'Kelluyo',
      'Providencia',
      'Chalamarca',
      'Choropampa',
      'Collonce',
      'Santa Catalina',
      'Duraznopampa',
      'Matacoto',
      'Carhuac',
      'Cheto',
      'Daguas',
      'Cochamal',
      'Parcoy',
      'Urpay',
      'Ullulluco',
      'Challas',
      'Huancaspata',
      'Naranjos',
      'Bajo Naranjillo',
      'Segunda Jerusalén - Azunguillo',
      'Nueva Cajamarca',
      'Saramiriza',
      'Nuevo Progreso',
      'Huabal',
      'Yuyapichis',
      'El Triunfo ( El Cruce)',
      'Jacas Grande',
      'Cayhuayna',
      'Huancapallac',
      'El Rosario',
      'Santa Rosa de Sacco',
      'Masma Chicche',
      'Sausa',
      'Huertas',
      'Mucllo',
      'Masma',
      'Matahuasi',
      'Hualhuas',
      'Saño',
      'Quilcas',
      'Quichuay',
      'Rapayan',
      'Anra',
      'San Pedro De Cachora',
      'Ledoy',
      'Pajarillo',
      'Shatoja',
      'La Rinconada',
      'Pedro Ruiz Gallo',
      'Kimbiri',
      'Urb. Santo Domingo',
      'Indiana',
      'Salvación',
      'Bolívar',
      'José Leonardo Ortiz',
      'Mariano Melgar',
      'Santa Anita - Los Ficus',
      'San Martin',
      'San Francisco De Borja',
      'Ciudad Satelite',
      'San Fernando',
      'Jesus Maria',
      'Wanchaq',
      'Centenario',
      'Cono Norte',
      'Carmen De La Legua Reynoso',
      'La Banda',
      'El Pedregal',
      'Las Nazarenas',
      'La Rinconada Ana Maria (La Rinconada)',
      'La Pradera',
      'Miramar',
      'Ascencion',
      'Puente Viru',
      'Victor Raul',
      'Horacio Zeballos Gamez',
      'Huanchaquito Alto',
      'Selva Alegre',
      'Tabaloslos',
      'Fonavi',
      'San Jose De Sisa',
      'Paragsha- San Andres - Jose Carlos Mariategui',
      'Nuevo Sullana',
      'Alto Salaverry',
      'Huepetuhe',
      'Palianñuñas',
      'Huamancaca Chico',
      'Mazuko',
      'San Alejandro',
      'Cerro Verde',
      'Limoncarro',
      'Breña',
      'Chupuro',
      'Pampas Grande',
      'Puerto Rosario de Laberinto',
      'Soplín Vargas',
    ],
  },
  {
    country: 'French Polynesia',
    cities: [
      'Atuona',
      'Rikitea',
      'Vaitape',
      'Vairao',
      'Uturoa',
      'Tohautu',
      'Tiarei',
      'Tevaitoa',
      'Teavaro',
      'Teahupoo',
      'Tautira',
      'Taunoa',
      'Punaauia',
      'Pueu',
      'Pirae',
      'Papetoai',
      'Papeete',
      'Papao',
      'Paopao',
      'Paea',
      'Otutara',
      'Moerai',
      'Mahina',
      'Hitiaa',
      'Haapiti',
      'Fitii',
      'Fare',
      'Faone',
      'Faanui',
      'Faaa',
      'Arue',
      'Anau',
      'Afareaitu',
      'Afaahiti',
      'Taiohae',
      'Tapuarava',
    ],
  },
  {
    country: 'Papua New Guinea',
    cities: [
      'Wewak',
      'Wau',
      'Wabag',
      'Vanimo',
      'Tari',
      'Rabaul',
      'Port Moresby',
      'Porgera',
      'Popondetta',
      'Panguna',
      'Mount Hagen',
      'Morehead',
      'Minj',
      'Mendi',
      'Madang',
      'Lorengau',
      'Lae',
      'Kwikila',
      'Kurumul',
      'Kundiawa',
      'Kokopo',
      'Kokoda',
      'Kiunga',
      'Kimbe',
      'Kieta',
      'Kerema',
      'Kavieng',
      'Kandrian',
      'Kainantu',
      'Ihu',
      'Ialibu',
      'Goroka',
      'Finschhafen',
      'Daru',
      'Bulolo',
      'Arawa',
      'Angoram',
      'Ambunti',
      'Aitape',
      'Samarai',
      'Alotau',
      'Buka',
      'Rauna',
    ],
  },
  {
    country: 'Philippines',
    cities: [
      'Zumarraga',
      'Zarraga',
      'Zaragoza',
      'Zamboanguita',
      'Zamboanga',
      'Yumbing',
      'Yubo',
      'Yook',
      'Ilaya',
      'Yeban Norte',
      'Wright',
      'Wines',
      'Wawa',
      'Wao',
      'Wañgan',
      'Vizal Santo Niño',
      'Vizal San Pablo',
      'Vito',
      'Vitali',
      'Vista Alegre',
      'Viriato',
      'Virac',
      'Vinzons',
      'Vintar',
      'Villaviciosa',
      'Villa Verde',
      'Villasis',
      'Villarosa',
      'Villareal',
      'Villanueva',
      'Villamor',
      'Villa Isla',
      'Villahermosa',
      'Villaba',
      'Villa Aglipay',
      'Vigo',
      'Viga',
      'Vigan',
      'Biga',
      'Victorias',
      'Victoria',
      'Biay',
      'Veruela',
      'Vargas',
      'Vallehermoso',
      'Valle Hermoso',
      'Valladolid',
      'Valenzuela',
      'Valencia',
      'East Valencia',
      'NIA Valencia',
      'Valderrama',
      'Uyugan',
      'Uyong',
      'Uyaan',
      'Uson',
      'Usab',
      'Urdaneta',
      'Urbiztondo',
      'Upper San Mateo',
      'Upper Klinan',
      'Upig',
      'Upi',
      'Unzad',
      'Unisan',
      'Union',
      'Unidos',
      'Unidad',
      'Ungca',
      'Umiray',
      'Umingan',
      'Umanday Centro',
      'Umaganhan',
      'Umabay',
      'Uling',
      'Ula',
      'Ugad',
      'Ugac Sur',
      'Uddiawan',
      'Odala',
      'Ubay',
      'Ualog',
      'Uacon',
      'Tuyum',
      'Tuyan',
      'Tuy',
      'Tutubigan',
      'Tutay',
      'Tupsan',
      'Tupi',
      'Tupang',
      'Tuod',
      'Tungol',
      'Tungawan',
      'Tungao',
      'Tunga',
      'Tumcon Ilawod',
      'Tumbagaan',
      'Tumauini',
      'Tumarbong',
      'Tumalim',
      'Tumalaytay',
      'Tulunan',
      'Tuli',
      'Tulay na Lupa',
      'Tulay',
      'Tucuran',
      'Tuhian',
      'Tuguegarao',
      'Tugos',
      'Tugdan',
      'Tugbong',
      'Tuganay',
      'Tuding',
      'Tudela',
      'Tucdao',
      'Tuburan',
      'Tubungan',
      'Tubod-dugoan',
      'Tubod',
      'Tubli',
      'Tublay',
      'Tibigan',
      'Tubigan',
      'Tubigagmanoc',
      'Tubay',
      'Tubaran',
      'Tubao',
      'Tuban',
      'Tubalan',
      'Tubajon',
      'Tuba',
      'Tuao',
      'Sabang',
      'Trinidad',
      'Trento',
      'Trapiche',
      'Tranca',
      'Tran',
      'Totolan',
      'Tortosa',
      'Torrijos',
      'Toritori',
      'Topdac',
      'Toong',
      'Tongouson',
      'Tondol',
      'Tondod',
      'Tominhao',
      'Tomingad',
      'Tombod',
      'Tamayong',
      'Tomas Oppus',
      'Tomado',
      'Tolosa',
      'Toledo',
      'Tocok',
      'Tococ East',
      'Tobuan',
      'Toboy',
      'Toboso',
      'Tiwi',
      'Titay',
      'Tiring',
      'Tipo-Tipo',
      'Tipolo',
      'Tipaz',
      'Tiparak',
      'Tinutulan',
      'Tinongan',
      'Tinubuan',
      'Tinoto',
      'Tinogboc',
      'Tiniguiban',
      'Brgy. Tinigao, Kalibo',
      'Tingloy',
      'Tinglayan',
      'Tindog',
      'Tinawagan',
      'Tinaogan',
      'Tinang',
      'Tinampo',
      'Tinambacan',
      'Tinambac',
      'Tinalmud',
      'Tinago',
      'Tinagacan',
      'Tinaan',
      'Timpas',
      'Timonan',
      'Tiling',
      'Tilik',
      'Tikiw',
      'Ticala-an',
      'Tigum',
      'Tiguisan',
      'Tiguion',
      'Tiguib',
      'Tigui',
      'Tigtabon',
      'Tignoan',
      'Tignapalan',
      'Tiglauigan',
      'Tigbinan',
      'Tigbauan',
      'Tigbao',
      'Tigbaw',
      'Tigaon',
      'Tigao',
      'Tiep',
      'Tidman',
      'Tiblawan',
      'Tibiao',
      'Tibanbang',
      'Tibagon',
      'Tiaong',
      'Ternate',
      'Teresita',
      'Teresa',
      'Telbang',
      'Telafas',
      'Telabastagan',
      'Tebag East',
      "T'boli",
      'Taywanak Ilaya',
      'Tayum',
      'Tayug',
      'Tayud',
      'Taytayan',
      'Taytay',
      'Taysan',
      'Tayasan',
      'Tayaman',
      'Tayabo',
      'Tayabas',
      'Tawan tawan',
      'Tawagan',
      'Taviran',
      'Taungoh',
      'Tawala',
      'Tarusan',
      'Tartaro',
      'Tarragona',
      'Tarong',
      'Tarlac City',
      'Tariric',
      'Tariji',
      'Tarangnan',
      'Tara',
      'Tapon',
      'Tapilon',
      'Tapikan',
      'Tapia',
      'Tapel',
      'Tapas',
      'Tapayan',
      'Tanza',
      'Tanudan',
      'Tantangan',
      'Tangke',
      'Tanolong',
      'Tanlad',
      'Tanjay',
      'Tangub',
      'Tañgo',
      'Tangnan',
      'Tangalan',
      'Tangal',
      'Tandoc',
      'Tandayag',
      'Tandag',
      'Tanay',
      'Tanauan',
      'Tamugan',
      'Tampocon',
      'Tampilisan',
      'Tampayan',
      'Tamparan',
      'Tampakan',
      'Tamorong',
      'Tamontaka',
      'Tamnag',
      'Tamlang',
      'Tamiso',
      'Tamisan',
      'Tambulig',
      'Tambalan',
      'Tambongon',
      'Tombongon',
      'Tambong',
      'Tambo',
      'Tambilil',
      'Tambalisa',
      'Tambac',
      'Tambak',
      'Tamayo',
      'Taluya',
      'Talusan',
      'Taluksangay',
      'Talugtug',
      'Taltal',
      'Taloy',
      'Platagata',
      'Talospatang',
      'Talon',
      'Talomo',
      'Talokgañgan',
      'Taloc',
      'Talubatib',
      'Talitay',
      'Talisayan',
      'Talisay',
      'Talipao',
      'Talipan',
      'Tal I Mun Doc',
      'Taligaman',
      'Talibon',
      'Talayan',
      'Talavera',
      'Talangnan',
      'Talang',
      'Talalora',
      'Talakag',
      'Talaibon',
      'Talahib Payap',
      'Talahiban I',
      'Talaga',
      'Talacogon',
      'Talaban',
      'Tala',
      'Tacub',
      'Takub',
      'Tajao',
      'Tagusao',
      'Tagum Norte',
      'Magugpo Poblacion',
      'Taguisa',
      'Taguing',
      'Taguig',
      'Tagudin',
      'Tagasilay',
      'Taypano',
      'Tagoytoy',
      'Tagoloan',
      'Tago',
      'Patikul',
      'Tagkawayan Sabang',
      'Tagdanua',
      'Tagcatong',
      'Tagburos',
      'Tagbubungang Diot',
      'Tagbina',
      'Tagbilaran City',
      'Tagbacan Ibaba',
      'Tagbak',
      'Tagaytay',
      'Tugaya',
      'Tagas',
      'Tagapul-an',
      'Taganak',
      'Tagana-an',
      'Tagakpan',
      'Tayabas Ibaba',
      'Taft',
      'Tadian',
      'Tacurong',
      'Taclobo',
      'Tacloban',
      'Tacligan',
      'Tacadang',
      'Tagbita',
      'Tabonok',
      'Tabuk',
      'Tabug',
      'Tabuelan',
      'Tabuc Pontevedra',
      'Tabuating',
      'Tabuan',
      'Tabu',
      'Tabontabon',
      'Tabunok',
      'Tabonoc',
      'Tabon',
      'Tabogon',
      'Taboc',
      'Tablac',
      'Tabio',
      'Tabing',
      'Tabinay',
      'Tabina',
      'Tabid',
      'Tabiauan',
      'Tabango',
      'Tabalong',
      'Tabaco',
      'Tabaan',
      'Taal',
      'Suyo',
      'Suyan',
      'Suso',
      'Surup',
      'Surigao',
      'Surallah',
      'Sungai',
      'Sumpong',
      'Sumisip',
      'Sumilao',
      'Sumagui',
      'Sumag',
      'Sumabnit',
      'Sulucan',
      'Sultan Kudarat',
      'Sulop',
      'Sulit',
      'Sulat',
      'Sulangan',
      'Sula',
      'Sugpon',
      'Sugod',
      'Sugcad',
      'Sugbongkogon',
      'Sugal',
      'Sudipen',
      'Suklayin',
      'Subusub',
      'Subic',
      'Saaban',
      'Suba',
      'Suay',
      'Suz-on',
      'Sual',
      'Soyung',
      'South Upi',
      'Sorsogon',
      'Songculan',
      'Solsona',
      'Solo',
      'Soledad',
      'Solano',
      'Solana',
      'Sogod',
      'Socorro',
      'Sitangkai',
      'Sison',
      'Siruma',
      'Sirib',
      'Siraway',
      'Siquijor',
      'Sipocot',
      'Sipalay',
      'Siocon',
      'Sinuknipan',
      'Sinubong',
      'Sinonoc',
      'Sinolon',
      'Sinisian',
      'Siniloan',
      'Sinilian First',
      'Sindon',
      'Sindangan',
      'Sinayawan',
      'Sinawilan',
      'Sinamar',
      'Sinala',
      'Sinait',
      'Sinacaban',
      'Simunul',
      'Simimbaan',
      'Simanu Sur',
      'Simala',
      'Silway 7',
      'Silvino Lobos',
      'Silongin',
      'Sillon',
      'Sillawit',
      'Silanga',
      'Silang',
      'Silago',
      'Silae',
      'Silab',
      'Sikatuna',
      'Sigma',
      'Sigay',
      'Cigaras',
      'Sierra Bullones',
      'Siempre Viva',
      'Sico Uno',
      'Siclong',
      'Sibutao',
      'Sibulan',
      'Sibul',
      'Sibuco',
      'Sibucao',
      'Sibonga',
      'Sibalom',
      'Sibaguan',
      'Sibagat',
      'Siayan',
      'Siay',
      'Siaton',
      'Siasi',
      'Siari',
      'Sexmoan',
      'Sevilla',
      'Sergio Osmeña Sr',
      'Seres',
      'Salunayan',
      'Semut',
      'Semirara',
      'Siguinon',
      'Lake Sebu',
      'Sebaste',
      'Saysain',
      'Satan',
      'Sarrat',
      'Sariaya',
      'Saraza',
      'Sarangani',
      'Sara',
      'Sapu Padidu',
      'Sapol',
      'Sapian',
      'Sapang Dalaga',
      'Sapang Buho',
      'Sapang',
      'Sapadun',
      'Sapad',
      'Sapa',
      'San Vicente',
      'San Vincente',
      'Santo Tomas',
      'Santo Rosario',
      'Santor',
      'Santo Niño',
      'Santa Nino',
      'Santol',
      'Santo Domingo',
      'Santo Cristo',
      'Santa Angel',
      'Santisimo Rosario',
      'Santiago',
      'San Teodoro',
      'Santa Teresita',
      'Santa Teresa First',
      'Santa Teresa',
      'Santa Rosa Sur',
      'Hacienda Santa Rosa',
      'Santa Rosa',
      'Santa Rita',
      'Santa Praxedes',
      'Santa Paz',
      'Santander Poblacion',
      'Santa Monica',
      'Santa Maria',
      'Santa Margarita',
      'Santa Marcela',
      'Santa Magdalena',
      'Santa Lucia',
      'Santa Justina',
      'Santa Juliana',
      'Santa Josefa',
      'Santa Ines West',
      'Santa Ignacia',
      'Santa Filomena',
      'Santa Fe',
      'Santa Elena',
      'Santa Cruz',
      'Santa Clara',
      'Santa Cecilia',
      'Santa Catalina Sur',
      'Santa Catalina Norte',
      'Santa Catalina',
      'Santa Brigida',
      'Santa Barbara',
      'Santa Ana',
      'Santa',
      'San Simon',
      'San Sebastian',
      'San Roque Dau First',
      'San Roque',
      'San Ricardo',
      'San Remigio',
      'San Ramon',
      'San Rafael',
      'San Quintin',
      'Sonquil',
      'San Policarpo',
      'San Policarpio',
      'San Pedro Apartado',
      'San Pedro',
      'San Pedro One',
      'San Patricio',
      'Lloren',
      'San Pascual',
      'San Pablo',
      'San Nicolas',
      'San Narciso',
      'San Miguel',
      'San Mateo',
      'San Martin',
      'San Mariano',
      'San Marcelino',
      'San Manuel',
      'San Luis',
      'San Lucas',
      'San Lorenzo',
      'Sanlibo',
      'San Leonardo',
      'Sankanan',
      'San Julian',
      'San Juan de Mata',
      'San Juan',
      'Malino',
      'San Jose del Monte',
      'San Jose de Buan',
      'San Jose',
      'Tabacao',
      'Agcogon',
      'San Jorge',
      'San Joaquin',
      'San Jacinto',
      'Catigbian',
      'Laug',
      'San Isidro',
      'Batobato',
      'San Ildefonso',
      'San Ignacio',
      'San Guillermo',
      'San Gregorio',
      'Sanghan',
      'Sangay',
      'Sangat',
      'San Gabriel',
      'San Gabriel First',
      'Del Monte',
      'San Francisco',
      'Aurora',
      'San Fernando',
      'San Felipe',
      'San Felipe Old',
      'Poblacion, San Felipe',
      'San Fabian',
      'San Eugenio',
      'San Esteban',
      'San Enrique',
      'San Emilio',
      'San Eduardo',
      'Sandolot',
      'San Dionisio',
      'San Diego',
      'Sandiat Centro',
      'Sandayong Sur',
      'San Cristobal',
      'San Clemente',
      'San Celestio',
      'San Casimiro',
      'San Carlos',
      'San Bernardo',
      'San Benito',
      'San Basilio',
      'San Aquilino',
      'San Antonio',
      'San Anton',
      'San Andres',
      'San Alfonso',
      'San Alejandro',
      'San Agustin',
      'Sampiro',
      'Sampao',
      'Sampaloc',
      'Sampagar',
      'Samon',
      'Sambuluan',
      'Samboan',
      'Samal',
      'San Salvador',
      'Salvador',
      'Salvacion I',
      'Salvacion',
      'Salug',
      'Salogon',
      'Salinungan Proper',
      'Salingogan',
      'Salinas',
      'Salimbao',
      'Salimbalan',
      'Salcedo',
      'Salaza',
      'Salay',
      'Salawagan',
      'Salapungan',
      'Salamanca',
      'Saint Bernard',
      'Sagurong',
      'Sagunto',
      'Saguing',
      'Saguiaran',
      'Sagud-Bahley',
      'Saguday',
      'Sagrada Familia',
      'Sagrada',
      'Sagpon',
      'Sagnay',
      'Sagbayan',
      'Sagay',
      'Sagasa',
      'Sagang',
      'Sagana',
      'Sagada',
      'Sadsalan',
      'Sadsadan',
      'Sadanga',
      'Sabtang',
      'Sablig',
      'Sablayan',
      'Sablan',
      'Sabangan',
      'Sabang Indan',
      'Saavedra',
      'Roxas',
      'Roxas City',
      'Rotonda',
      'Rosario',
      'Rosales',
      'Ronda',
      'Rominimbang',
      'Romblon',
      'Rogongon',
      'Rizal',
      'Liwan',
      'Rissing',
      'Rio Tuba',
      'Rimus',
      'Rimpeso',
      'Reina Mercedes',
      'Hacienda Refugio',
      'Recodo',
      'Robonkon',
      'Real',
      'Rapu-Rapu',
      'Ransang',
      'Ranao',
      'Ramos West',
      'Ramos',
      'Ramon Magsaysay',
      'Ramon',
      'Ramain',
      'Rajal Norte',
      'Ragay',
      'Ragan Norte',
      'Ragandang',
      'Quitang',
      'Quisao',
      'Quirino',
      'Quipot',
      'Quintong',
      'Quinipot',
      'Kinatakutan',
      'Quinarayan',
      'Quinapundan',
      'Quinagaringan',
      'Quinabigan',
      'Quilo-quilo',
      'Quiling',
      'Quibal',
      'Quiapo',
      'Quezon City',
      'Quezon',
      'Putol',
      'Putlod',
      'Puting Kahoy',
      'Putingkahoy',
      'Putiao',
      'Putat',
      'Puro Pinget',
      'Puro',
      'Puricay',
      'Porais',
      'Purac',
      'Pura',
      'Pontian',
      'Punta Silum',
      'Paddaya',
      'Punta',
      'Punolu',
      'Pongol',
      'Puncan',
      'Punay',
      'Punao',
      'Punang',
      'Pulupandan',
      'Pulung Santol',
      'Pulungmasle',
      'Pulong Santa Cruz',
      'Pulong Sampalok',
      'Pulong Gubat',
      'Pulo',
      'Pulilan',
      'Pulangbato',
      'Pugo',
      'Puerto Princesa',
      'Puerto Galera',
      'Puerto Bello',
      'Puelay',
      'Pudoc',
      'Pudoc North',
      'Pualas',
      'Prosperidad',
      'Progreso',
      'Prinza',
      'Prieto Diaz',
      'President Roxas',
      'President Quirino',
      'Pitogo',
      'Parabcan',
      'Prado Siongco',
      'Pozorrubio',
      'Pototan',
      'Potot',
      'Patong',
      'Potia',
      'Port Barton',
      'Port Area',
      'Poro',
      'Porac',
      'Poona-Piagapo',
      'Pontevedra',
      'Ponso',
      'Ponot',
      'Ponong',
      'Pung-Pang',
      'Pantukan',
      'Pondaguitan',
      'Puloypuloy',
      'Polopina',
      'Polonoling',
      'Polong Norte',
      'Polong',
      'Polomolok',
      'Polo',
      'Polloc',
      'Polillo',
      'Polangui',
      'Polañge',
      'Polanco',
      'Polahongon',
      'Pola',
      'Pogonsili',
      'Poctol',
      'Plaridel',
      'Placer',
      'Limbuhan',
      'Pio Duran',
      'Pio',
      'Pinukpuk',
      'Pinugay',
      'Pintuyan',
      'Pinoma',
      'Pinokawan',
      'Pinit',
      'Pinili',
      'Pines',
      'Pandasan',
      'Pindangan Centro',
      'Pinayagan Norte',
      'Pinaring',
      'Pinamungahan',
      'Pinamopoan',
      'Pinambaran',
      'Pinamalayan',
      'Piñahan',
      'Pinagsibaan',
      'Pinagsabangan',
      'Panadtaban',
      'Piña',
      'Pimbalayan',
      'Pililla',
      'Pilig',
      'Pili',
      'Pilar',
      'Pila',
      'Pikit',
      'Pigcawayan',
      'Pidsandawan',
      'Pidigan',
      'Piddig',
      'Piat',
      'Pias',
      'Piape I',
      'Perrelos',
      'Perez',
      'Peñarrubia',
      'Peñaranda',
      'Peñablanca',
      'Peña',
      'Pedtad',
      'Paypay',
      'Payocpoc Sur',
      'Payapa',
      'Payao',
      'Pawing',
      'Pawili',
      'Pawican',
      'Pawak',
      'Pawa',
      'Pavia',
      'Paulba',
      'Pau',
      'Patuto',
      'Patonan',
      'Pattao',
      'Patrocinio',
      'Patria',
      'Patpata Segundo',
      'Pato-o',
      'Patnongon',
      'Patnanungan',
      'Patindeguen',
      'Patin-ay',
      'Patique',
      'Pateros',
      'Patayac',
      'Patawag',
      'Patao',
      'Patabog',
      'Pata',
      'Pasuquin',
      'Pastrana',
      'Passi',
      'Pasong Kawayan Primero',
      'Pasil',
      'Pasibi West',
      'Pasian',
      'Pasay',
      'Pasacao',
      'Parista',
      'Parioc Segundo',
      'Paringao',
      'Parian Dakula',
      'Parion',
      'Parangan',
      'Parang',
      'Paranaque City',
      'Paraiso',
      'Paradahan',
      'Paracelis',
      'Paracale',
      'Papaya',
      'Paombong',
      'Paoay',
      'Panukulan',
      'Panognawan',
      'Pantubig',
      'Pantijan No 2',
      'Pantay Na Matanda',
      'Pantar',
      'Pantao-Ragat',
      'Pantao',
      'Pantabangan',
      'Pansoy',
      'Pansol',
      'Panlinlang',
      'Panlaitan',
      'Panitan',
      'Panitian',
      'Paniqui',
      'Panique',
      'Panikihan',
      'Panikian',
      'Pangyan',
      'Simbahan',
      'Pangulayan',
      'Panguiranan',
      'Pangpang',
      'Pañgobilian',
      'Panglao',
      'Pangil',
      'Pangian',
      'Pangdan',
      'Pangascasan',
      'Pangapisan',
      'Pangao',
      'Pangantocan',
      'Panganiban',
      'Pangal Sur',
      'Pangabuan',
      'Pang',
      'Pando',
      'Pandi',
      'New Pandanon',
      'Pandan Niog',
      'Pandan',
      'Pandacaqui',
      'Pancol',
      'Pance',
      'Panaytayon',
      'Panayacan',
      'Panay',
      'Pan-an',
      'Pananaw',
      'Panan',
      'Panalo-on',
      'Panalipan',
      'Panalingaan',
      'Panalanoy',
      'Panacan',
      'Panabuan',
      'Panabo',
      'Panabingan',
      'Pamplona',
      'Pambujan',
      'Pambuhan',
      'Pambisan',
      'Pamatawan',
      'Pamantingan',
      'Palusapis',
      'Paluan',
      'Palsong',
      'Palompon',
      'Palomoc',
      'Palo',
      'Palma Gil',
      'Palkan',
      'Palimbang',
      'Palian',
      'Palhi',
      'Palguyod',
      'Palestina',
      'Paldit',
      'Palayan City',
      'Palauig',
      'Palaroo',
      'Palapag',
      'Palanit',
      'Palangue',
      'Palanas',
      'Palampas',
      'Palali',
      'Palahanan Uno',
      'Palagao Norte',
      'Palacpalac',
      'Pakil',
      'Pakiad',
      'Pajo',
      'Paitan Este',
      'Paitan Norte',
      'Paitan',
      'Paiisa',
      'Pagudpud',
      'Pagsanjan',
      'Pagsanghan',
      'Pagsañgahan',
      'Pagsanahan Norte',
      'Pagsabangan',
      'Pagbilao',
      'Pagatin',
      'Pag-asa',
      'Pagangan',
      'Pagaluñgan',
      'Pagalungan',
      'Pagadian',
      'Paete',
      'Padre Zamora',
      'Padre Garcia',
      'Padre Burgos',
      'Padong',
      'Padapada',
      'Padang',
      'Padada',
      'Paco Roman',
      'Pacol',
      'Paco',
      'Paclolo',
      'Paatan',
      'Paagahan',
      'Ozamiz City',
      'Owak',
      'Oton',
      'Utabi',
      'Osmeña',
      'Oslob',
      'Osias',
      'Osiao',
      'Oroquieta',
      'Orong',
      'Ormoc',
      'Orion',
      'Oras',
      'Orani',
      'Oracon',
      'Opol',
      'Ondoy',
      'Olutanga',
      'Olongapo',
      'Olingan',
      'Olea',
      'Okiot',
      'Ogtongon',
      'Ogod',
      'Odiongan',
      'Odiong',
      'Odicon',
      'Ocoy',
      'Ochanado',
      'Ocaña',
      'Ocampo',
      'Obong',
      'Obando',
      'Oaqui',
      'Numancia',
      'Noling',
      'Nugas',
      'Nueva Vida Sur',
      'Nueva Valencia',
      'Nueva Fuerza',
      'Nueva Era',
      'Noveleta',
      'Novallas',
      'Norzagaray',
      'Norala',
      'Nuing',
      'Niugan',
      'Ninoy',
      'Nilombot',
      'Nieves',
      'Nibaliw Central',
      'New Washington',
      'New Visayas',
      'New Sibonga',
      'New Panay',
      'New Panamao',
      'Lucena',
      'New Leyte',
      'New Lagao',
      'New Iloilo',
      'New Corella',
      'New Cebu',
      'New Bohol',
      'New Batu Batu',
      'Bantacan',
      'New Agutaya',
      'Nenita',
      'Nena',
      'Navotas',
      'Navatat',
      'Naval',
      'Naujan',
      'Natalungan',
      'Natubleng',
      'Natonin',
      'Nato',
      'Natividad',
      'Nattapian',
      'Nasugbu',
      'Masonogan',
      'Nasipit',
      'Narvacan',
      'Narra',
      'Naro',
      'Marawis',
      'Napuro',
      'Napoles',
      'Napnapan',
      'Napalitan',
      'Nanyo',
      'Nangka',
      'Nañgka',
      'Nangan',
      'Nangalisan',
      'Nancamarinan',
      'Nancalobasaan',
      'Namuac',
      'Nampicuan',
      'Namboongan',
      'Nambalan',
      'Nama',
      'Nalus',
      'Nalundan',
      'Nalsian Norte',
      'Brgy. Nalook, kalibo',
      'Naisud',
      'Nailong',
      'Naili',
      'Naic',
      'Nahawan',
      'Naguilian',
      'Naguilayan',
      'Naguelguel',
      'Nagtipunan',
      'Nagsaing',
      'Nagrumbuan',
      'Nagpandayan',
      'Nagiba',
      'Naghalin',
      'Nagcarlan',
      'Nagbukel',
      'Nagbalaye',
      'Nagbacalan',
      'Naagas',
      'Naga',
      'Nabunturan',
      'Nabulao',
      'Nabannagan West',
      'Nabua',
      'Nabas',
      'Nabangig',
      'Naawan',
      'Muti',
      'Muricay',
      'Murcia',
      'Muntinlupa',
      'Muñoz East',
      'Muñoz',
      'Mungo',
      'Munai',
      'Mulauin',
      'Mulanay',
      'Muladbucad',
      'Mozzozzin Sur',
      'Mozon',
      'Motiong',
      'Morong',
      'Morobuan',
      'Moriones',
      'Morales',
      'Moog',
      'Montilla',
      'Montevista',
      'Montecillo',
      'Montaneza',
      'Rodriguez',
      'Monreal',
      'Monpon',
      'Monkayo',
      'Mondragon',
      'Moncada',
      'Monbon',
      'Monamon',
      'Molugan',
      'Molave',
      'Moises Padilla',
      'Mogpog',
      'Mobo',
      'Moalboal',
      'Miranda',
      'Minuyan',
      'Minuri',
      'Minolos',
      'Minlagas',
      'Minien East',
      'Minglanilla',
      'Mindupok',
      'Minapasoc',
      'Minapan',
      'Minante Segundo',
      'Minanga Norte',
      'Minallo',
      'Minalin',
      'Minalabac',
      'Mina',
      'Mileb',
      'Milaor',
      'Milagros',
      'Midsayap',
      'Midsalip',
      'Miaray',
      'Mianay',
      'Miagao',
      'Miaga',
      'Meycauayan',
      'Mexico',
      'Merida',
      'Mercedes',
      'Mendez-Nuñez',
      'Medina',
      'Medellin',
      'McKinley',
      'Maypangdan',
      'Mayorga',
      'Mayo',
      'Mayngaran',
      'Maygatasan',
      'Maydolong',
      'Mayapusi',
      'Mayantoc',
      'Mayana',
      'Mayabon',
      'Maya',
      'Maxingal',
      'Mawab',
      'Mauraro',
      'Maulawin',
      'Mauhao',
      'Maugat West',
      'Mauban',
      'Maturanoc',
      'Matungao',
      'Matti',
      'Matnog',
      'Matlang',
      'Matiompong',
      'Matingain',
      'Malinao',
      'Maticmatic',
      'Matiao',
      'Mati',
      'Mat-i',
      'Mataywanac',
      'Matayumtayum',
      'Matanog',
      'Matangad',
      'Matanao',
      'Matalom',
      'Matalam',
      'Matala',
      'Matagbak',
      'Matacon',
      'Matabao',
      'Mataas Na Kahoy',
      'Masoli',
      'Maslog',
      'Masiu',
      'Masipi West',
      'Masinloc',
      'Masiga',
      'Masbate',
      'Masaya Sur',
      'Masaya',
      'Masarayao',
      'Masaraway',
      'Masapang',
      'Masantol',
      'Masalukot Uno',
      'Masalipit',
      'Masaling',
      'Masaguisi',
      'Masaba',
      'Marupit',
      'Marogong',
      'Mariveles',
      'Miliroc',
      'Maripipi',
      'Marintoc',
      'Marilao',
      'Marihatag',
      'Maricalom',
      'Maricaban',
      'Maribong',
      'Maribojoc',
      'Mariano Marcos',
      'Mariano',
      'Maria Cristina',
      'Maria Aurora',
      'Maria',
      'Margosatubig',
      'Margen',
      'Marcos',
      'Marbel',
      'Marawi City',
      'Marawa',
      'Maravilla',
      'Marao',
      'Marantao',
      'Maramag',
      'Maragondon',
      'Makiwalo',
      'Maquiling',
      'Maquiapo',
      'Maputi',
      'Mapulot',
      'Mapulo',
      'Mapolopolo',
      'Mapili',
      'Mapaniqui',
      'Mapandan',
      'Mapanas',
      'Mapalad',
      'Mapalacsiao',
      'Maonon',
      'Manup',
      'Manuk Mangkaw',
      'Manukan',
      'Manuel Roxas',
      'Manubul',
      'Manuangan',
      'Mantiquil',
      'Manticao',
      'Mantang',
      'Mantampay',
      'Mantalongon',
      'Mansilingan',
      'Mansalay',
      'Manolo Fortich',
      'Manoc-Manoc',
      'Maño',
      'Manlucahoc',
      'Malilinao',
      'Manito',
      'Maninihon',
      'Manila',
      'Manikling',
      'Manika',
      'Manicahan',
      'Manibaug Pasig',
      'Manjoy',
      'Manquiring',
      'Mangoso',
      'Mangili',
      'Mangero',
      'Mangatarem',
      'Mangas',
      'Mangarine',
      'Mangaldan',
      'Manaloal',
      'Manggahan',
      'Manga',
      'Mangga',
      'Mandili',
      'Mandih',
      'Mandaue City',
      'Mandaon',
      'Mandangoa',
      'Mandaluyong City',
      'Mancilang',
      'Mankayan',
      'Manay',
      'Manaulanan',
      'Manaul',
      'Manatal',
      'Manat',
      'Manaring',
      'Manapla',
      'Manapa',
      'Manaoag',
      'Mananum',
      'Manamrag',
      'Manalongon',
      'Manalad',
      'Manogpi',
      'Managok',
      'Managa',
      'Manacsac',
      'Manabo',
      'Mamonit',
      'Mampurog',
      'Mambusao',
      'Mamburao',
      'Mambulo',
      'Mambog',
      'Mambayaan',
      'Mambatangan',
      'Mambajao',
      'Mabahin',
      'Mambago',
      'Mambagatan',
      'Mamatid',
      'Mamali',
      'Mamala',
      'Malvar',
      'Maluso',
      'Malusac',
      'Maluno Sur',
      'Maluñgun',
      'Maluko',
      'Maluid',
      'Malway',
      'Maluanluan',
      'Maltana',
      'Maloma',
      'Malolos',
      'Maloh',
      'Maloco',
      'Malocloc',
      'Mallig',
      'Malitubog',
      'Malitbog',
      'Malita',
      'Malisbeng',
      'Malinta',
      'Malingin',
      'Malinaw',
      'Malinao Ilaya',
      'Malimono',
      'Malilipot',
      'Maliig',
      'Maligaya',
      'Malidong',
      'Malicboy',
      'Malibong East',
      'Malibago',
      'Malhiao',
      'Malbug',
      'Malbang',
      'Malaybalay',
      'Malayal',
      'Malaya',
      'Malay',
      'Malawag',
      'Malawa',
      'Malatap',
      'Malasugui',
      'Malasiqui',
      'Malasin',
      'Malasila',
      'Malaruhatan',
      'Malapatan',
      'Malapag',
      'Malangas',
      'Malangabang',
      'Malanday',
      'Malanay',
      'Malamote',
      'Malamig',
      'Malalag',
      'Malainen Luma',
      'Malaiba',
      'Malajog',
      'Malayo-an',
      'Malagos',
      'Malaga',
      'Malacampa',
      'Malabuyoc',
      'Malabugas',
      'Malabor',
      'Malabonot',
      'Malabon',
      'Malabog',
      'Malabang',
      'Malabanban Norte',
      'Malabanan',
      'Malabago',
      'Malabag',
      'Makilala',
      'Makati City',
      'Majayjay',
      'Maitum',
      'Mainit Norte',
      'Mainit',
      'Maindang',
      'Maimbung',
      'Mailag',
      'Maigo',
      'Maibu',
      'Mahinog',
      'Mahayag',
      'Mahatao',
      'Mahaplag',
      'Mahanob',
      'Mahala',
      'Mahabang Parang',
      'Mahaba',
      'Maguyam',
      'Maguling',
      'Maguinao',
      'Maguilling',
      'Magtangol',
      'Magtaking',
      'Magsingal',
      'Magsaysay',
      'Magsalangi',
      'Magpet',
      'Magnaga',
      'Maglamin',
      'Magdug',
      'Magdiwang',
      'Magdalena',
      'Magbay',
      'Magay',
      'Magatos',
      'Magarao',
      'Magapit',
      'Magapit, Aguiguican',
      'Maganoy',
      'Magallon Cadre',
      'Magallanes',
      'Magalang',
      'Magalalag',
      'Madulao',
      'Madridejos',
      'Madrid',
      'Maddela',
      'Maddarulug Norte',
      'Madamba',
      'Madalum',
      'Madalag',
      'Macrohon',
      'Maco',
      'Macayug',
      'Makato',
      'Macatbong',
      'MacArthur',
      'Macarse',
      'Macapsing',
      'Macalva Norte',
      'Macalong',
      'Macalelon',
      'Macalaya',
      'Macalamcam A',
      'Macabugos',
      'Macabuboni',
      'Macabebe',
      'Macaas',
      'Mabuttal East',
      'Mabusag',
      'Mabunga',
      'Mabuhay',
      'Mabua',
      'Mabiton',
      'Mabitac',
      'Mabini',
      'Mabinay',
      'Mabilog',
      'Brgy. Mabilo, New Washington',
      'Mabilbila Sur',
      'Mabilao',
      'Mabilang',
      'Mabayo',
      'Mabay',
      'Mabasa',
      'Mabalacat City',
      'Mababanaba',
      'Maayong Tubig',
      'Maayon',
      'Maasin',
      'Maasim',
      'Maao',
      'Maanas',
      'Maan',
      'Maagnas',
      'Lusong',
      'Luzon',
      'Luuk Datan',
      'Lu-uk',
      'Lut-od',
      'Lutayan',
      'Kabayan',
      'Lusacan',
      'Lurugan',
      'Lupon',
      'Lupi Viejo',
      'Lupi',
      'Lapase',
      'Lupao',
      'Luntal',
      'Lun Pequeño',
      'Lungog',
      'Lungaog',
      'Lunen',
      'Lunec',
      'Lunas',
      'Lunao',
      'Luna',
      'Lumuyon',
      'Lumil',
      'Lumbia',
      'Lumbayao',
      'Lumbayanague',
      'Lumbayan',
      'Lumbatan',
      'Lumbangan',
      'Lumbang',
      'Lumatil',
      'Lumazal',
      'Lumangbayan',
      'Luksuhin',
      'Luklukan',
      'Luisiana',
      'Lugus',
      'Lugui',
      'Lugo',
      'Lugait',
      'Lucsuhin',
      'Locmayan',
      'Lucero',
      'Lucban',
      'Lukatan',
      'Lucapon',
      'Lucap',
      'Lubuagan',
      'Lubigan',
      'Lubao',
      'Lubang',
      'Luba',
      'Loyola',
      'Lourdes',
      'Los Baños',
      'Los Arcos',
      'Los Angeles',
      'Loreto',
      'Lupo',
      'Lopez Jaena',
      'Lopez',
      'Lope de Vega',
      'Loon',
      'Lookan',
      'Looc',
      'Lono',
      'Lonoy',
      'Longos',
      'Lomboy',
      'Lombog',
      'Lombocan',
      'Loma de Gato',
      'Loqueb Este',
      'Logon',
      'Lobong',
      'Loboc',
      'Lobo',
      'Loay',
      'Loacan',
      'Llorente',
      'Llanera',
      'Little Baguio',
      'Litayan',
      'Lipayran',
      'Lipay',
      'Lipahan',
      'Lipa City',
      'Liozon',
      'Lintangan',
      'Linoan',
      'Linmansangan',
      'Lingion',
      'Lingig',
      'Lingayen',
      'Lingating',
      'Lingasan',
      'Linay',
      'Linaon',
      'Linao',
      'Linan',
      'Linamon',
      'Linabuan Sur',
      'Linabuan',
      'Linabo',
      'Limulan',
      'Limpapa',
      'Limot',
      'Limon',
      'Limanancong',
      'Limbo',
      'Limbalod',
      'Limbaan',
      'Limay',
      'Limaong',
      'Limao',
      'Liloy',
      'Liloan',
      'Lilio',
      'Liliongan',
      'Lila',
      'Ligayan',
      'Ligaya',
      'Ligao',
      'Lidlidda',
      'Licuan',
      'Liciada',
      'Licab',
      'Libungan',
      'Libuganon',
      'Liboro',
      'Liboran',
      'Libona',
      'Libon',
      'Libog',
      'Libmanan',
      'Libjo',
      'Liberty',
      'Libertad',
      'Libato',
      'Libas',
      'Libagon',
      'Libacao',
      'Lianga',
      'Lian',
      'Leyte',
      'Leuteboro',
      'Lepanto',
      'Lepa',
      'Leones East',
      'Leon',
      'Lennec',
      'Lemery',
      'Legaspi',
      'Legrada',
      'Leganes',
      'Lebak',
      'Lazi',
      'Layog',
      'Laylay',
      'Laya',
      'Lawang Kupang',
      'Lawa-an',
      'Lavezares',
      'Laurel',
      'Laur',
      'La Union',
      'Lawigan',
      'Latung',
      'La Trinidad',
      'Las Piñas',
      'Las Navas',
      'Lasang',
      'Lasam',
      'La Roxas',
      'Larion Alto',
      'Larena',
      'Larap',
      'Lapuz',
      'Lapuyan',
      'Lapu-Lapu City',
      'Lapuan',
      'Lapolapo',
      'Lapinigan',
      'Lapinig',
      'Lapi',
      'La Paz',
      'De la Paz',
      'Lapaz',
      'Laoang',
      'Laoag',
      'Lao',
      'Lanuza',
      'Tairan Camp',
      'Lantapan',
      'Lantangan',
      'Lanot',
      'Lanna',
      'Lanigay',
      'Languyan',
      'Lañgub',
      'Langob',
      'Langtad',
      'Langpas',
      'Langiden',
      'Langcangan',
      'Langatian',
      'Lanat',
      'Lanas',
      'Lanao',
      'Lamut',
      'Lampitak',
      'Lampari',
      'Lamitan City',
      'Lamitan',
      'Laminusa',
      'Lamian',
      'Lambunao',
      'Lambayong',
      'Lambakin',
      'Lamba',
      'Lamak',
      'Lallayug',
      'Lalig',
      'La Libertad',
      'Lalawigan',
      'Lalagsan',
      'Lalab',
      'Lala',
      'Rancheria Payau',
      'Lajong',
      'Laiya',
      'Lais',
      'La Hacienda',
      'Laguitas',
      'Laguit Centro',
      'Lagindingan',
      'Laguilayan',
      'La Granja',
      'Lagonoy',
      'Lagonglong',
      'Lagayan',
      'Lagawe',
      'Lagasit',
      'Lagangilang',
      'La Fortuna',
      'La Curva',
      'Lacson',
      'Lacdayan',
      'La Castellana',
      'Lacaron',
      'La Carlota',
      'Lacag',
      'Labu-o',
      'Labuñgan',
      'Labuan',
      'Labrador',
      'Labog',
      'Labo',
      'Labnig',
      'Labney',
      'Labayug',
      'Labason',
      'Labasan',
      'Labangan',
      'Kumalisquis',
      'Kumalarang',
      'Cumadcad',
      'Kulempang',
      'Culit',
      'Kulay-Kulay',
      'Kolumbug',
      'Kulaman',
      'Kudanding',
      'Kuanos',
      'Koronadal',
      'Comagascas',
      'Kolape',
      'Kolambugan',
      'Cogon',
      'Klinan',
      'Kiupo',
      'Kitobo',
      'Kitcharao',
      'Kitapak',
      'Kitaotao',
      'Kitango',
      'Kisolon',
      'Kisante',
      'Kipit',
      'Kipalbig',
      'Kinogitan',
      'Kinangan',
      'Kinamayan',
      'Kinalansan',
      'Kinalaglagan',
      'Kinablangan',
      'Kinabhangan',
      'Kimaya',
      'Kimanuit',
      'Kiloloran',
      'Kilim',
      'Kidapawan',
      'Kibureau',
      'Kibungan',
      'Kibonsod',
      'Kiblawan',
      'Kibawe',
      'Kibangay',
      'Kiangan',
      'Kiamba',
      'Kaytitinga',
      'Kayapa',
      'Kawit',
      'Kawayan',
      'Cawayan',
      'Kauswagan',
      'Kauran',
      'Katuli',
      'Katipunan',
      'Katidtuan',
      'Katubao',
      'Katangawan',
      'Kasibu',
      'Casay',
      'Karungdong',
      'Caromatan',
      'Kaputian',
      'Kapingkong',
      'Kapatan',
      'Kapatalan',
      'Kapatagan',
      'Kapangan',
      'Kaongkod',
      'Kansipati',
      'Kanluran',
      'Kanlagay',
      'Kananya',
      'Kampokpok',
      'Kambing',
      'Kamanga',
      'Kalugmanan',
      'Calumboyan',
      'Kaliling',
      'Kalilangan',
      'Kalibo (poblacion)',
      'Kalian',
      'Kalbugan',
      'Kalbay',
      'Kalayaan',
      'Kalawit',
      'Kalaisan',
      'Kalanganan',
      'Kalandagan',
      'Kalamansig',
      'Kalamangog',
      'Kagay',
      'Kadingilan',
      'Kabuynan',
      'Kabungahan',
      'Kabulusan',
      'Kabulohan',
      'Kabugao',
      'Kabasalan',
      'Kabankalan',
      'Kabalen',
      'Kabalantian',
      'Kabacan',
      'Casuguran',
      'Julita',
      'Jugno',
      'Tugas',
      'Jubasan',
      'Juban',
      'Jovellar',
      'Jose Pañganiban',
      'Josefina',
      'Joroan',
      'Jordan',
      'Jones',
      'Jolo',
      'Hipadpad',
      'Jimenez',
      'Jimalalud',
      'Jibao-an',
      'Jiabong',
      'Jetafe',
      'Jayubó',
      'Bugho',
      'Javier',
      'Javalera',
      'Jasaan',
      'Jaro',
      'Japitan',
      'Jantianon',
      'Janopol',
      'Janiuay',
      'Jandayan Norte',
      'Janagdong',
      'Jampang',
      'Hamoraon',
      'Jamindan',
      'Jamabalod',
      'Jalaud',
      'Jalajala',
      'Jagupit',
      'Jaguimitan',
      'Jagna',
      'Jaena',
      'Jaen',
      'Jaclupan',
      'Jabonga',
      'Ivisan',
      'Ivana',
      'Itogon',
      'Itbayat',
      'Isulan',
      'Isugod',
      'Isla',
      'Isabela',
      'City of Isabela',
      'Isabel',
      'Isabang',
      'Irosin',
      'Irirum',
      'Iriga City',
      'Iraya',
      'Irasan',
      'Iraray',
      'Irahuan',
      'Iraga',
      'Ipilan',
      'Ipil',
      'Inobulan',
      'Intampilan',
      'Initao',
      'Inicbulan',
      'Infanta',
      'Ineangan',
      'Indulang',
      'Indang',
      'Inayauan',
      'Inayagan',
      'Inawayan',
      'Inapatan',
      'Inangatan',
      'Inabanga',
      'Inabaan Sur',
      'Imus',
      'Impalutao',
      'Imelda',
      'Imbatug',
      'Imbang',
      'Iloilo',
      'Ilog',
      'Ilioilio',
      'Ilihan',
      'Iligan City',
      'Iligan',
      'Ilangay',
      'Ilagan',
      'Iguig',
      'Igpit',
      'Ignit',
      'Igmaya-an',
      'Igcocolo',
      'Igbon',
      'Igbaras',
      'Igang',
      'Idtig',
      'Idio',
      'Ichon',
      'Ibarra',
      'Ibajay',
      'Yuni',
      'Ibabang Tayuman',
      'Ibaan',
      'Iba',
      'Hukay',
      'Hondagua',
      'Hobo',
      'Hipasngo',
      'Hipona',
      'Hinundayan',
      'Hinunangan',
      'Hinlayagan Ilaud',
      'Hinigaran',
      'Hingatungan',
      'Hindang',
      'Hinatuan',
      'Hinapalanan',
      'Himensulan',
      'Himaya',
      'Gimampang',
      'Himamaylan',
      'Himaao',
      'Hilotongan',
      'Hilongos',
      'Hiju, Maco',
      'Hibaiyo',
      'Herrera',
      'Hernani',
      'Hermosa',
      'Harrison',
      'Jarigue',
      'Hapao',
      'Hamtic',
      'Halog West',
      'Haligue',
      'Halayhayin',
      'Halayhay',
      'Halapitan',
      'Haguimit',
      'Hagonoy',
      'Hagnaya',
      'Hagdan',
      'Dolores',
      'Hacienda',
      'Guyong',
      'Guyam Malaki',
      'Gutalac',
      'Gutad',
      'Guruyan',
      'Gupitan',
      'Gumian',
      'Gumaus',
      'Gumalang',
      'Gumaca',
      'Gulod',
      'Guiwanon',
      'Guiuan',
      'Guisijan',
      'Guisguis',
      'Guiset East',
      'Guirang',
      'Guipos',
      'Guiong',
      'Giawang',
      'Guintubhan',
      'Guinticgan',
      'Guinisiliban',
      'Guinsang-an',
      'Guinsadan',
      'Guinobatan',
      'Guinlo',
      'Guindulman',
      'Guindarohan',
      'Guindapunan',
      'Guinoaliuan',
      'Guinayangan, Fourth District of Quezon',
      'Guinabsan',
      'Guinacotan',
      'Guimbal',
      'Guimba',
      'Guiljungan',
      'Guiling',
      'Guijalo',
      'Guihulñgan',
      'Guihing Proper',
      'Guiguinto',
      'Guiddam',
      'Guibodangan',
      'Gueset',
      'Gubat',
      'Guba',
      'Guagua',
      'Guadalupe',
      'Gregorio del Pilar',
      'Granada',
      'Sigaboy',
      'Gonzaga',
      'Goma',
      'Gocoton',
      'Goa',
      'Gloria',
      'Glan Peidu',
      'Glan',
      'Glamang',
      'Glad',
      'Gitagum',
      'Giporlos',
      'Gines-Patay',
      'Ginatilan',
      'Ginabuyan',
      'Hilantagaan',
      'Gigmoto',
      'Gigaquit',
      'Gibong',
      'Gibgos',
      'Gibato',
      'Getulio',
      'Gerona',
      'General Trias',
      'General Tinio',
      'General Santos',
      'General Nakar',
      'General Mamerto Natividad',
      'General MacArthur',
      'General Luna',
      'General Emilio Aguinaldo',
      'Gayaman',
      'Gattaran',
      'Gatbo',
      'Gasan',
      'Garcia Hernandez',
      'Garchitorena',
      'Gappal',
      'Gapan',
      'Ganyangan',
      'Gansing',
      'Gang',
      'Gandara',
      'Ganassi',
      'Gamut',
      'Gamu',
      'Gammad',
      'Gambang',
      'Gamay',
      'Galimuyod',
      'Gainza',
      'Gadung',
      'Gadu',
      'Gabuyan',
      'Gabi',
      'Gabawan',
      'Gabas',
      'Gabao',
      'Gabaldon',
      'Furao',
      'Fuerte',
      'Floridablanca',
      'Flora',
      'Ferrol',
      'Feliciano',
      'Famy',
      'Fabrica',
      'Evangelista',
      'Eustaquio Lopez',
      'Estrella',
      'Estipona',
      'Estefania',
      'Estancia',
      'Estacion',
      'Estaca',
      'Espiritu',
      'Esperanza East',
      'Esperanza',
      'España',
      'Esmeralda',
      'Escalante',
      'Ermita',
      'Erenas',
      'Eraan',
      'Entablado',
      'Enrique Villanueva',
      'Saravia',
      'Enrile',
      'El Salvador',
      'El Pardo',
      'El Nido',
      'Eguia',
      'Egaña',
      'Eden',
      'Ebcor Town',
      'Echague',
      'Duran',
      'Dunguan',
      'Dumpay',
      'Dumlan',
      'Dumingag',
      'Dumarao',
      'Dumaran',
      'Dumarais',
      'Dumanjog',
      'Dumangas',
      'Dumalneg',
      'Dumalinao',
      'Dumalaguing',
      'Dumalag',
      'Dumaguil',
      'Dumaguete',
      'Dumabato',
      'Doljo',
      'Dulig',
      'Dulao',
      'Dulangan',
      'Dulag',
      'Dukay',
      'Dugongan',
      'Dugcal',
      'Duero',
      'Dueñas',
      'Dualing',
      'Doyong',
      'Dos Hermanas',
      'Dorongan Punta',
      'Doos',
      'Doong',
      'Donsol',
      'Don Pedro',
      'Talagutong',
      'Dungon',
      'Don Carlos',
      'Doña Remedios Trinidad',
      'Domampot',
      'Domalanoan',
      'Dologon',
      'Dolo',
      'Dodan',
      'Dobdoban',
      'Divisoria',
      'Ditsaan',
      'Disod',
      'Dipolog',
      'Diplahan',
      'Dipolo',
      'Dingras',
      'Dingle',
      'Dinganen',
      'Dingalan',
      'Dinalupihan',
      'Dinalongan',
      'Dinaig',
      'Dinahican',
      'Dinagat',
      'Dimiao',
      'Dimayon',
      'Dimataling',
      'Dimasalang',
      'Dimaluna',
      'Diliman Primero',
      'Dilan',
      'Bonifacio',
      'Digos',
      'Digkilaan',
      'Digdig',
      'Digal',
      'Diffun',
      'Dicayong',
      'Dicamay',
      'Dicabisagan',
      'Dibuluan',
      'Diaz',
      'Dian-ay',
      'Diamantina',
      'Diadi',
      'Del Rosario',
      'Del Pilar',
      'Daliciasao',
      'Del Gallego',
      'Del Carmen',
      'Del Carmen, Surigao del Norte',
      'Daykitin',
      'Dayapan',
      'Dayap',
      'Dawa-Dawa',
      'Davila',
      'Davao',
      'Dauis',
      'Dauin',
      'Davan',
      'Datu Piang',
      'Datu Paglas',
      'Datagon',
      'Dassun',
      'Dasol',
      'Dasmariñas',
      'Darapidap',
      'Daram',
      'Daraitan',
      'Daragutan',
      'Daraga',
      'Dapitan',
      'Dapdapan',
      'Dapdap',
      'Dapawan',
      'Dapa',
      'Dao',
      'Tobias Fornier',
      'Dansuli',
      'Dorsalanam',
      'Danglas',
      'Dangcalan',
      'Dancagan',
      'Dancalan',
      'Danao',
      'Damulog',
      'Dampol',
      'Damortis',
      'Damolog',
      'Damilag',
      'Damayan',
      'Damawato',
      'Damatulan',
      'Dalwangan',
      'Dalupirip',
      'Dalupaon',
      'Dalumangcob',
      'Dalorong',
      'Dalirig',
      'Dalipuga',
      'Dalipey',
      'Dalican',
      'Daliao',
      'Dalaoig',
      'Dalaguete',
      'Dahay',
      'Dagupan',
      'Dagup',
      'Daguit',
      'Daguioman',
      'Dagumba-an',
      'Dagohoy',
      'Dagatan',
      'Dagami',
      'Daet',
      'Dadus',
      'Dacudao',
      'Da-an Sur',
      'Viejo Daan Banua',
      'Daanbantayan',
      'Cuyo',
      'Cuyapo',
      'Cut-cut Primero',
      'Curuan',
      'Curry',
      'Currimao',
      'Culubasa',
      'Cullalabo del Sur',
      'Culianin',
      'Culianan',
      'Culasi',
      'Culaman',
      'Culaba',
      'Cuevas',
      'Cuenca',
      'Cuartero',
      'Cuambog',
      'Cotmon',
      'Kotkot',
      'Cotabato',
      'Cosina',
      'Cortez',
      'Cortes',
      'Corrooy',
      'Coronon',
      'Coron',
      'Corella',
      'Cordova',
      'Cordon',
      'Corcuera',
      'Conversion',
      'Consuelo',
      'Consuegra',
      'Constancia',
      'Consolacion',
      'Conner',
      'Conel',
      'Conduaga',
      'Concordia',
      'Concepcion',
      'Concepcion Ibaba',
      'Compostela',
      'Communal',
      'Comillas Norte',
      'Comillas',
      'Columbio',
      'Colonia',
      'Colongulo',
      'Culasian',
      'Culacling',
      'Cogtong',
      'Cogon Cruz',
      'Codcod',
      'Claveria',
      'Claver',
      'Clarin',
      'Chambrey',
      'Cervantes',
      'Cebuano',
      'Cebu City',
      'Cayungnan',
      'Cayhagan',
      'Cayanguan',
      'Cayanga',
      'Cayang',
      'Kauit',
      'Cawayan Bugtong',
      'Cavite',
      'Cavite City',
      'Cavinti',
      'Causwagan',
      'Causip',
      'Cauayan',
      'Catungawan Sur',
      'Catungan',
      'Catuday',
      'Catubig',
      'Cato',
      'Catmondaan',
      'Catmon',
      'Caticugan',
      'Katico',
      'Caticlan',
      'Caterman',
      'Cateel',
      'Catbalogan',
      'Catayauan',
      'Catarman',
      'Catanauan',
      'Cataingan',
      'Catablan',
      'Catabangan',
      'Castillo',
      'Castillejos',
      'Castilla',
      'Castañas',
      'Casisang',
      'Casiguran',
      'Casian',
      'Cassanayan',
      'Casambalangan',
      'Casala-an',
      'Caruray',
      'Caronoan West',
      'Cartagena',
      'Carriedo',
      'Carrascal',
      'Carranglan',
      'Carpenter Hill',
      'Carot',
      'Carusucan',
      'Carmona',
      'Carmen',
      'Cogan',
      'Carmen Grande',
      'Carmelo',
      'Carles',
      'Karligan',
      'Carigara',
      'Carig',
      'Caridad',
      'Cardona',
      'Carcar',
      'Caraycayon',
      'Caraycaray',
      'Carasi',
      'Caranan',
      'Caramutan',
      'Caramoran',
      'Caramoan',
      'Caramay',
      'Caraga',
      'Caracal',
      'Carabalan',
      'Capuy',
      'Capuluan',
      'Capulaan',
      'Capul',
      'Capucnasan',
      'Capoocan',
      'Capissayan Sur',
      'Capas',
      'Capandanan',
      'Capalonga',
      'Capalayan',
      'Capaga',
      'Caoayan',
      'Canubing No 2',
      'Canturay',
      'Cantoria',
      'Cantilan',
      'Cantel',
      'Cantapoy',
      'Cantao-an',
      'Cansolungon',
      'Cansuje',
      'Canroma',
      'Canomoy',
      'Corocotan',
      'Canmaya Diot',
      'Canlaon',
      'Canjulao',
      'Caningay',
      'Canhaway',
      'Canhandugan',
      'Candoni',
      'Candon',
      'Candijay',
      'Candiis',
      'Candelaria',
      'Candating',
      'Candabong',
      'Kandabong',
      'Candaba',
      'Cansilayan',
      'Canayan',
      'Canauay',
      'Can-Avid',
      'Can-asujan',
      'Ganapi',
      'Canaoalan',
      'Canan Norte',
      'Canaman',
      'Camohaguin',
      'Campoyo',
      'Campusong',
      'Camingawan',
      'Camindangan',
      'Caminauit',
      'Camiling',
      'Camias',
      'Camp Flora',
      'Cambuga',
      'Cambanay',
      'Gambalidio',
      'Camangcamang',
      'Camandag',
      'Camambugan',
      'Camalobalo',
      'Camaligan',
      'Camalig',
      'Camaley',
      'Camalaniugan',
      'Cama Juan',
      'Camachile',
      'Caluya',
      'Calumpit',
      'Calumpang',
      'Calomboyan',
      'Calubian',
      'Calubcub Dos',
      'Calsib',
      'Caloocan City',
      'Calongbuyan',
      'Calolbon',
      'Calog Norte',
      'Caloc-an',
      'Callaguip',
      'Calizo',
      'Calituban',
      'Colipapa',
      'Calintaan',
      'Calinog',
      'Calingcuan',
      'Calinaoan Malasin',
      'Calilayan',
      'Calidñgan',
      'Calibutbut',
      'Calibungan',
      'Calero',
      'Calepaan',
      'Calbiga',
      'Calbayog City',
      'Calayaan',
      'Calaya',
      'Calauan',
      'Calauag',
      'Calatugas',
      'Calatrava',
      'Calatagan',
      'Calasiao',
      'Calasgasan',
      'Calape',
      'Calapan',
      'Calaoagan',
      'Calantas',
      'Calantac',
      'Calanogas',
      'Calangain',
      'Calancuasan Norte',
      'Calanasan',
      'Calampisauan',
      'Calamba',
      'Calamagui East',
      'Calachuchi',
      'Calaca',
      'Calabugao',
      'Calabasa',
      'Calabanga',
      'Calabaca',
      'Calaba',
      'Cajimos',
      'Cajidiocan',
      'Cainta',
      'Caigangan',
      'Caibiran',
      'Cagwait',
      'Cagsiay',
      'Cagmanaba',
      'Kaliliog',
      'Cagdianao',
      'Cagbang',
      'Cagayan',
      'Cagayan de Oro',
      'Cagayancillo',
      'Cagamotan',
      'Cafe',
      'Cadlan',
      'Cadiz',
      'Caditaan',
      'Cadagmayan Norte',
      'Cabuyao',
      'Cabusao',
      'Caburan',
      'Cabungan',
      'Cabulay',
      'Cabul-an',
      'Cabugao',
      'Cabucgayan',
      'Cabra',
      'Cabog',
      'Kablalan',
      'Cabittaogan',
      'Cabitan',
      'Cabiraoan',
      'Cabinuangan',
      'Cabilauan',
      'Kabilauan',
      'Cabilao',
      'Cabiguan',
      'Cabadiangan',
      'Cabiao',
      'Cabcaben',
      'Cabcab',
      'Cabayaoasan',
      'Cabayangan',
      'Cabay',
      'Cabatuan',
      'Cabatang',
      'Cabarroguis',
      'Cabaritan East',
      'Cabano',
      'Cabannungan Second',
      'Cabanglasan',
      'Cabangan',
      'Cabangahan',
      'Cabanbanan',
      'Cabanatuan City',
      'Cabaluay',
      'Cabalitian',
      'Cabalawan',
      'Cabalaoangan',
      'Kabac',
      'Cabognon',
      'Cabagan',
      'Cabodiongan',
      'Cabadbaran',
      'Cabacuñgan',
      'Cabacungan',
      'Cabacao',
      'Caba',
      'Caabiangan',
      'Buyuan',
      'Buyo',
      'Buyabod',
      'Buug',
      'Butulan',
      'Butubut Norte',
      'Butuan',
      'Butigui',
      'Butig',
      'Butazon',
      'Butag',
      'Busuanga',
      'Brgy. New Buswang, Kalibo',
      'Bustos',
      'Busing',
      'Busilak',
      'Busdi',
      'Busay',
      'Boroon',
      'Barurao',
      'Buruanga',
      'Burirao',
      'Burias',
      'Burgos',
      'Burdeos',
      'Buray',
      'Burauen',
      'Buracan',
      'Burabod',
      'Bukid',
      'Bunol',
      'Bunog',
      'Bungoy',
      'Bunguiao',
      'Bungahan',
      'Bunga',
      'Bundoc',
      'Buawan',
      'Bunawan',
      'Bumbaran',
      'Brgy. Bulwang, Numancia',
      'Bulusan',
      'Buluangan',
      'Buluang',
      'Buluan',
      'Bulualto',
      'Bulu',
      'Bulog',
      'Bulod',
      'Buliwao',
      'Bulit',
      'Buliran Segundo',
      'Buliran',
      'Buliok',
      'Bulihan',
      'Buldon',
      'Bulaon',
      'Bulatukan',
      'Bulata',
      'Bulasa',
      'Bularit',
      'Bolanon',
      'Bulan',
      'Bulalacao',
      'Bulad',
      'Bulacnin',
      'Bulacan',
      'Bula',
      'Bukal Sur',
      'Bukal',
      'Buhi',
      'Buhatan',
      'Buhangin',
      'Buguias',
      'Buguey',
      'Bugsoc',
      'Bugo',
      'Bugko',
      'Bugcaon',
      'Bugasong',
      'Bugasan',
      'Bugas',
      'Bugang',
      'Bugallon',
      'Bugaan',
      'Buga',
      'Buensuseso',
      'Buenlag',
      'Buenavista',
      'Bued',
      'Budta',
      'Bucloc',
      'Buclad',
      'Bukay Pait',
      'Bucay',
      'Babug',
      'Buayan',
      'Buanoy',
      'Buang',
      'Bualan',
      'Bual',
      'Buagsong',
      'Buadiposo-Buntong',
      'Boton',
      'Botolan',
      'Botigues',
      'Botao',
      'Boston',
      'Bosdak',
      'Borongan',
      'Yapak',
      'Borbon',
      'Boot',
      'Bood',
      'Bontoc',
      'Bongued',
      'Bungsuan',
      'Bongao',
      'Boñgalon',
      'Bungabon',
      'Bongabon',
      'Bonga',
      'Bone South',
      'Bonbon',
      'Bonawon',
      'Bombon',
      'Bolong',
      'Bolo Bolo',
      'Bolo',
      'Bulo',
      'Boljoon',
      'Bolitoc',
      'Bolisong',
      'Bolingit',
      'Boliney',
      'Bolinao',
      'Bolilao',
      'Bolila',
      'Bolboc',
      'Bulawin',
      'Bolaoit',
      'Bokod',
      'Bogtong',
      'Bogo',
      'Bodega',
      'Bocos',
      'Bocaue',
      'Bocana',
      'Bobon Second',
      'Bobonan',
      'Bobon',
      'Buan',
      'Boac',
      'Blinsung',
      'Blingkong',
      'Biwang',
      'Bituñgol',
      'Biton',
      'Bitoon',
      'Bitin',
      'Bitaogan',
      'Bitanjuan',
      'Bitangan',
      'Bitag Grande',
      'Bitadtun',
      'Bislig',
      'Biri',
      'Binantocan',
      'Binulasan',
      'Binucayan',
      'Binubusan',
      'Binuangan',
      'Bintawan',
      'Bintacay',
      'Binonga',
      'Binondo',
      'Binon-an',
      'Binodegahan',
      'Binmaley',
      'Binlod',
      'Binitinan',
      'Binitayan',
      'Binidayan',
      'Binguang',
      'Bingawan',
      'Payabon',
      'Binday',
      'Bincoñgan',
      'Binay',
      'Binanwanaan',
      'Binangonan',
      'Biñan',
      'Binalonan',
      'Binalbagan',
      'Binalan',
      'Binahaan',
      'Binabalian',
      'Binabaan',
      'Bilwang',
      'Bilog-Bilog',
      'Bil-Loca',
      'Biliran',
      'Bilaran',
      'Bilar',
      'Bilao',
      'Bilad',
      'Biking',
      'Bignay Uno',
      'Bigaan',
      'Bigaa',
      'Bien Unido',
      'Bicos',
      'Bibiclat',
      'Bical Norte',
      'Biasong',
      'Biao',
      'Bialong',
      'Biabas',
      'Betwagan',
      'Besao',
      'Benito Soliven',
      'Belison',
      'Belance',
      'Begang',
      'Beddeng',
      'Becerril',
      'Beberon',
      'Bayuin',
      'Bayugan',
      'Bayombong',
      'Bayog',
      'Baylo',
      'Baybayin',
      'Baybay',
      'Bayawan',
      'Bayasong',
      'Bayas',
      'Bayaoas',
      'Bay-ang',
      'Bayang',
      'Bayanan',
      'Bayambang',
      'Bayabas',
      'Bay',
      'Bawison',
      'Bautista',
      'Baunu-Timbangan',
      'Bauko',
      'Baugo',
      'Bauang',
      'Bauan',
      'Bau',
      'Batutitik',
      'Batuan',
      'Battung',
      'Batobalane',
      'Bato',
      'Batitang',
      'Batiano',
      'Bateria',
      'Batasan Bata',
      'Batasan',
      'Batas',
      'Batarasa',
      'Batangas',
      'Batana',
      'Batan',
      'Batal',
      'Bataquil',
      'Batad',
      'Batac City',
      'Basud',
      'Basista',
      'Basing',
      'Basilisa',
      'Basicao Coastal',
      'Basiao',
      'Basiad',
      'Basey',
      'Basdiot',
      'Basco',
      'Bascaron',
      'Basay',
      'Basak',
      'Basag',
      'Basa',
      'Barugo',
      'Barucboc Norte',
      'Barra',
      'Baroy',
      'Barotac Viejo',
      'Barotac Nuevo',
      'Barongis',
      'Barong Barong',
      'Barong',
      'Barobo',
      'Baro',
      'Barlig',
      'Bariw',
      'Barira',
      'Barili',
      'Barcelona',
      'Barbaza',
      'Barayong',
      'Baras',
      'Barangobong',
      'Barahan',
      'Baracatan',
      'Baquero Norte',
      'Baon',
      'Baud',
      'Bantuanon',
      'Bantogon',
      'Bantog',
      'Bantilan',
      'Bantiqui',
      'Bantayan',
      'Bantay',
      'Bansud',
      'Bansalan',
      'Bangkirohan',
      'Banquero',
      'Banos',
      'Banog Sur',
      'Banugao',
      'Banocboc',
      'Bankaw',
      'Banisilan',
      'Banilad',
      'Bani',
      'Banhigan',
      'Bangui',
      'Bangued',
      'Bangonay',
      'Bangkal',
      'Bangar',
      'Bangao',
      'Bangan-Oda',
      'Banganan',
      'Bangahan',
      'Bangad',
      'Bañga',
      'Banga',
      'Bancasi',
      'Bancal',
      'Banayoyo',
      'Banaybanay',
      'Banaue',
      'Banawang',
      'Banawa',
      'Banate',
      'Banalo',
      'Banag',
      'Banaba',
      'Bambang',
      'Bamban',
      'Baluyot',
      'Balutu',
      'Balutakay',
      'Balut',
      'Balungao',
      'Balugo',
      'Balud',
      'Balucuc',
      'Balsic',
      'Baloy',
      'Bal-os',
      'Mamungan',
      'Balogo',
      'Balocawehay',
      'Balucawi',
      'Baloc',
      'Ballesteros',
      'Baliwagan',
      'Baliuag Nuevo',
      'Baliuag',
      'Baliton',
      'Balitoc',
      'Balite Segundo',
      'Balite',
      'Balinsacayao',
      'Balingueo',
      'Balingoan',
      'Balingcanaway',
      'Balingasay',
      'Balingasag',
      'Balindong',
      'Balinad',
      'Balimbing',
      'Balilihan',
      'Balili',
      'Balila',
      'Baligang',
      'Balibago',
      'Balibagan Oeste',
      'Balete',
      'Calamundingan',
      'Baler',
      'Baleno',
      'Balele',
      'Balbalan',
      'Balayong',
      'Balayang',
      'Balayan',
      'Balatero',
      'Balatan',
      'Balasing',
      'Balasan',
      'Balas',
      'Balaogan',
      'Balaoang',
      'Balaoan',
      'Balangonan',
      'Balangkayan',
      'Balangiga',
      'Balangbalang',
      'Balanga',
      'Balanacan',
      'Balamban',
      'Balagunan',
      'Balagui',
      'Balagtas',
      'Balagtasin',
      'Balagon',
      'Balading',
      'Balabagan',
      'Balabag',
      'Balabac',
      'Bakung',
      'Bakun',
      'Bachauan',
      'Brgy. Bachaw Norte, Kalibo',
      'Baka',
      'Bais',
      'Bairan',
      'Bailan',
      'Bail',
      'Baikingon',
      'Baheli',
      'Bah-Bah',
      'Bahay Pare',
      'Bahay',
      'Bao',
      'Bagupaye',
      'Bagombong',
      'Bagumbayan',
      'Bagulin',
      'Baguio',
      'Baguinge',
      'Baguer',
      'Bagu',
      'Bagtic',
      'Bagroy',
      'Bagontapay',
      'Bagong Tanza',
      'Bagong-Sikat',
      'Bagong Sikat',
      'Bagong Barrio',
      'Bago City',
      'Bagay',
      'Baganga',
      'Bayanga',
      'Bagan',
      'Bagamanoc',
      'Bagalangit',
      'Bagakay',
      'Bagahanlad',
      'Bagacay',
      'Bagac',
      'Bagabag',
      'Baggabag B',
      'Buadtasan',
      'Badoc',
      'Badlan',
      'Badiangan',
      'Badian',
      'Bacuyangan',
      'Bacungan',
      'Bacundao Weste',
      'Baculongan',
      'Bakulong',
      'Baculin',
      'Bacuag',
      'Bactad Proper',
      'Bacsay',
      'Bacoor',
      'Leon Postigo',
      'Bacong',
      'Bacon',
      'Bacolor',
      'Bacolod City',
      'Bacolod',
      'Baco',
      'Bacnotan',
      'Bacnor East',
      'Bacnar',
      'Baclayon',
      'New Baclayon',
      'Bacayawan',
      'Bacarra',
      'Bacalan',
      'Bacag',
      'Bacacay',
      'Bacabac',
      'Babo-Pangulo',
      'Babatngon',
      'Babak',
      'Babag',
      'Baay',
      'Baao',
      'Azagra',
      'Ayusan Uno',
      'Ayungon',
      'Ayugan',
      'Aya',
      'Awang',
      'Awallan',
      'Avila',
      'Aurelliana',
      'Aumbay',
      'Atulayan',
      'Atop-atop',
      'Atok',
      'Atipuluhan',
      'Atimonan',
      'Atabayan',
      'Cambanugoy',
      'Asturias',
      'Asturga',
      'Astorga',
      'Asingan',
      'Asia',
      'Arteche',
      'Aroroy',
      'Arminia',
      'Armenia',
      'Aritao',
      'Aringay',
      'Argao',
      'Arenas',
      'Arcangel',
      'Arayat',
      'Aras-asan',
      'Aranda',
      'Aranas Sur',
      'Aramawayan',
      'Aramayuan',
      'Araceli',
      'Araal',
      'Aquino',
      'Apurawan',
      'Apud',
      'Apoya',
      'Aplaya',
      'Santa Rita Aplaya',
      'Apitong',
      'Aparri',
      'Apalit',
      'Apad',
      'Anuling',
      'Anulid',
      'Antipolo',
      'Anticala',
      'Antequera',
      'Antagan Segunda',
      'Anoring',
      'Anopog',
      'Anonang',
      'Anito',
      'Aanislag',
      'Anini-y',
      'Anilao',
      'Ani-e',
      'Anibongan',
      'Angono',
      'Angeles City',
      'Angatel',
      'Angat',
      'Angadanan',
      'Angad',
      'Andres Bonifacio',
      'Andili',
      'Anda',
      'Anao',
      'Anambongan',
      'Anakan',
      'Anahawan',
      'Amuñgan',
      'Amulung',
      'Amucao',
      'Ampusungan',
      'Ampatuan',
      'Amlimay',
      'Amio',
      'Amdos',
      'Ambulong',
      'Ambuclao',
      'Amas',
      'Amagbagan',
      'Amaga',
      'Amadeo',
      'Amacalan',
      'Alupay',
      'Alugan',
      'Alubijid',
      'Alua',
      'Altavas',
      'Aloleng',
      'Aloguinsan',
      'Almeria',
      'Almendras',
      'Almaguer North',
      'Almagro',
      'Allen',
      'Allangigan Primero',
      'Allacapan',
      'Alitagtag',
      'Alimono',
      'Alimodian',
      'Alemanguan',
      'Alim',
      'Alilem',
      'Alijis',
      'Alicia',
      'Alicante',
      'Alibunan',
      'Alibug',
      'Alibago',
      'Aliang',
      'Aliaga',
      'Algeciras',
      'Alfonso',
      'Alpaco',
      'Alejal',
      'Alegria',
      'Alcoy',
      'Alcantara',
      'Alcala',
      'Alburquerque',
      'Albuera',
      'Alayao',
      'Alannay',
      'Alanib',
      'Alangilanan',
      'Alangilan',
      'Alangalang',
      'Alaminos',
      'Alamada',
      'Alae',
      'Alad',
      'Alacaygan',
      'Alac',
      'Alabug',
      'Alabel',
      'Alabat',
      'Akle',
      'Ajuy',
      'Agutaya',
      'Agusan',
      'Agupit',
      'Aguisan',
      'Aguining',
      'Aguilar',
      'Aguada',
      'Agsungot',
      'Agpangi',
      'Agos',
      'Agoo',
      'Agoncillo',
      'Agno',
      'Aglipay',
      'Aglayan',
      'Aglalana',
      'Aggugaddah',
      'Agdangan',
      'Agbannawag',
      'Agay',
      'Aganan',
      'Ag-ambulong',
      'Aga',
      'Afusing Centro',
      'Adtugan',
      'Adlay',
      'Adlaon',
      'Adela',
      'Adams',
      'Acli',
      'Accusilian',
      'Acao',
      'Abuyon',
      'Abuyog',
      'Abut',
      'Abulug',
      'Abucayan',
      'Abucay',
      'Abra de Ilog',
      'Aborlan',
      'Abis',
      'Abilay',
      'Abiera',
      'Abangay',
      'Abaca',
      'Sangali',
      'Lamisahan',
      'Dulian',
      'Landang Laum',
      'Talabaan',
      'Tablas',
      'Mangusu',
      'Sibulao',
      'Batu',
      'Laparay',
      'Monching',
      'Dalangin',
      'Tigpalay',
      'Tunggol',
      'Marsada',
      'Talipaw',
      'Sanga-Sanga',
      'Ungus-Ungus',
      'Binuang',
      'Pandakan',
      'Mauboh',
      'Kulase',
      'Kalang',
      'Andalan',
      'Buansa',
      'Kajatian',
      'Bato Bato',
      'Sionogan',
      'Payuhan',
      'Kungtad',
      'La Dicha',
      'Lumbog',
      'Tiguha',
      'Sagacad',
      'East Migpulao',
      'Binuatan',
      'Guiniculalay',
      'Danlugan',
      'Kagawasan',
      'Lapining',
      'Margos',
      'Taguitic',
      'Sumalig',
      'Gubaan',
      'Lanipao',
      'Maranding',
      'Rebe',
      'Panubigan',
      'Malim',
      'Kapalong',
      'Andop',
      'Kaligutan',
      'Tagnanan',
      'Camudmud',
      'Maduao',
      'Magliman',
      'Motrico',
      'Capitan Ramon',
      'Malusay',
      'Badak',
      'Bagong Pagasa',
      'Lim-oo',
      'Ibung',
      'Damabalas',
      'Makir',
      'Nunguan',
      'Basiawan',
      'Lumbac',
      'Malandag',
      'Malingao',
      'Dado',
      'Molundo',
      'Simuay',
      'Mataya',
      'Nuyo',
      'Tuka',
      'Busok',
      'Lambontong',
      'Daguma',
      'Kapaya',
      'Simod',
      'Pasig City',
      'Tubo',
      "Sambayanihan People's Village",
      'Subic Bay Freeport Zone',
      'Cambarus',
      'Hanggan',
      'City of Ligao',
      'Paranas',
      'Apas',
      'Roseller Lim',
    ],
  },
  {
    country: 'Pakistan',
    cities: [
      'Chuhar Jamali',
      'Timargara',
      'Rawalakot',
      'Pir Jo Goth',
      'Khairpur Mir’s',
      'Adilpur',
      'Ziarat',
      'Zhob',
      'Zaida',
      'Zahir Pir',
      'Zafarwal',
      'Yazman',
      'Wazirabad',
      'Chak Five Hundred Seventy-five',
      'Warah',
      'Wana',
      'Vihari',
      'Utmanzai',
      'Uthal',
      'Usta Muhammad',
      'Umarkot',
      'Ubauro',
      'Turbat',
      'Tordher',
      'Topi',
      'Tolti',
      'Toba Tek Singh',
      'Thul',
      'Thatta',
      'Tharu Shah',
      'Taunsa',
      'Tank',
      'Tangwani',
      'Tangi',
      'Tando Muhammad Khan',
      'Tando Mitha Khan',
      'Tando Jam',
      'Tando Bago',
      'Tando Allahyar',
      'Tando Adam',
      'Tandlianwala',
      'Talhar',
      'Talamba',
      'Talagang',
      'Thal',
      'Swabi',
      'Surkhpur',
      'Surab',
      'Sukkur',
      'Sukheke Mandi',
      'Sohbatpur',
      'Sodhri',
      'Sobhodero',
      'Skardu',
      'Sita Road',
      'Sinjhoro',
      'Sillanwali',
      'Sibi',
      'Sialkot',
      'Shujaabad',
      'Shorkot',
      'Shinpokh',
      'Shikarpur',
      'Shigar',
      'Shekhupura',
      'Sharqpur Sharif',
      'Shakargarh',
      'Shahr Sultan',
      'Shahpur Chakar',
      'Shahpur',
      'Shahkot',
      'Shahdadpur',
      'Shahdad Kot',
      'Shabqadar',
      'Sehwan',
      'Sargodha',
      'Sarai Sidhu',
      'Sarai Naurang',
      'Sarai Alamgir',
      'Sann',
      'Sanjwal',
      'Sangla Hill',
      'Sanghar',
      'Sambrial',
      'Samaro',
      'Sakrand',
      'Saidu Sharif',
      'Sahiwal',
      'Saddiqabad',
      'Rustam',
      'Rojhan',
      'Rohri',
      'Renala Khurd',
      'Rawalpindi',
      'Ratodero',
      'Rasulnagar',
      'Ranipur',
      'Rajo Khanani',
      'Rajanpur',
      'Raja Jang',
      'Raiwind',
      'Rahim Yar Khan',
      'Goth Radhan',
      'Chenab Nagar',
      'Quetta',
      'Qila Saifullah',
      'Qila Abdullah',
      'Kambar',
      'Qadirpur Ran',
      'Pithoro',
      'Pishin',
      'Pir Mahal',
      'Pindi Gheb',
      'Pindi Bhattian',
      'Pind Dadan Khan',
      'Goth Phulji',
      'Phalia',
      'Peshawar',
      'Pattoki',
      'Patan',
      'Pasrur',
      'Pasni',
      'Parachinar',
      'Pano Aqil',
      'Panjgur',
      'Pallandri',
      'Pakpattan',
      'Paharpur',
      'Pad Idan',
      'Pabbi',
      'Ormara',
      'Okara',
      'Nushki',
      'Nowshera',
      'New Mirpur',
      'Nawabshah',
      'Naushahro Firoz',
      'Naushahra Virkan',
      'Naudero',
      'Nasirabad',
      'Narowal',
      'Narang Mandi',
      'Naukot',
      'Nankana Sahib',
      'Nagir',
      'Nabisar',
      'Muzaffargarh',
      'Muzaffarābād',
      'Mustafabad',
      'Musa Khel Bazar',
      'Murree',
      'Muridke',
      'Multan',
      'Moro',
      'Mithi',
      'Mitha Tiwana',
      'Mirwah Gorchani',
      'Mirpur Sakro',
      'Mirpur Mathelo',
      'Mirpur Khas',
      'Mirpur Bhtoro',
      'Miro Khan',
      'Miran Shah',
      'Mingora',
      'Minchianabad',
      'Mianwali',
      'Mian Channun',
      'Mehrabpur',
      'Mehar',
      'Matli',
      'Matiari',
      'Mastung',
      'Mardan',
      'Mansehra',
      'Mankera',
      'Mangla',
      'Mandi Bahauddin',
      'Mananwala',
      'Mamu Kanjan',
      'Malir Cantonment',
      'Malakwal',
      'Malakand',
      'Mailsi',
      'Madeji',
      'Mach',
      'Loralai',
      'Lodhran',
      'Liliani',
      'Layyah',
      'Larkana',
      'Landi Kotal',
      'Lalian',
      'Lala Musa',
      'Lakki',
      'Lakhi',
      'Lahore',
      'Ladhewala Waraich',
      'Lachi',
      'Kunri',
      'Kunjah',
      'Kundian',
      'Kulachi',
      'Kot Sultan',
      'Kot Samaba',
      'Kotri',
      'Kot Rajkour',
      'Kot Radha Kishan',
      'Kot Mumin',
      'Kot Malik Barkhurdar',
      'Kotli Loharan',
      'Kotli',
      'Kot Ghulam Muhammad',
      'Kot Diji',
      'Kot Addu',
      'Kohlu',
      'Kohat',
      'Khuzdar',
      'Khushāb',
      'Khurrianwala',
      'Khewra',
      'Kharian',
      'Kharan',
      'Karak',
      'Khaplu',
      'Khanpur',
      'Khanpur Mahar',
      'Khangarh',
      'Khangah Dogran',
      'Khanewal',
      'Khandowa',
      'Khalabat',
      'Khairpur Nathan Shah',
      'Khairpur Tamewah',
      'Khairpur',
      'Khadro',
      'Keti Bandar',
      'Keshupur',
      'Kasur',
      'Kashmor',
      'Karor',
      'Kario Ghanwar',
      'Karaundi',
      'Karachi',
      'Kanganpur',
      'Kandiaro',
      'Kandiari',
      'Kandhkot',
      'Kamra',
      'Kamoke',
      'Chak One Hundred Twenty Nine Left',
      'Kamar Mushani',
      'Kamalia',
      'Kalur Kot',
      'Kallar Kahar',
      'Kaleke Mandi',
      'Kalat',
      'Kalaswala',
      'Kalabagh',
      'Kahuta',
      'Kahror Pakka',
      'Kahna Nau',
      'Kadhan',
      'Kabirwala',
      'Johi',
      'Jiwani',
      'Chak Jhumra',
      'Jhol',
      'Jhelum',
      'Jhawarian',
      'Dera Allahyar',
      'Jhang Sadr',
      'Jauharabad',
      'Jatoi Shimali',
      'Jati',
      'Jaranwala',
      'Jandiala Sher Khan',
      'Jand',
      'Jamshoro',
      'Jampur',
      'Jalalpur Pirwala',
      'Jalalpur Jattan',
      'Jahanian Shah',
      'Jacobabad',
      'Islamkot',
      'Islamabad',
      'Hyderabad',
      'Hujra Shah Muqim',
      'Hingorja',
      'Hazro City',
      'Havelian',
      'Haveli Lakha',
      'Hasilpur',
      'Chak Thirty-one -Eleven Left',
      'Harunabad',
      'Harnoli',
      'Harnai',
      'Haripur',
      'Hangu',
      'Hala',
      'Hafizabad',
      'Hadali',
      'Gwadar',
      'Gujrat',
      'Gujranwala',
      'Gujar Khan',
      'Gojra',
      'Gilgit',
      'Ghotki',
      'Ghauspur',
      'Gharo',
      'Garh Maharaja',
      'Garhiyasin',
      'Garhi Khairo',
      'Goth Garelo',
      'Gandava',
      'Gambat',
      'Gahkuch',
      'Gadani',
      'Fort Abbas',
      'Fazilpur',
      'Chak Two Hundred Forty-nine Thal Development Authority',
      'Faruka',
      'Faqirwali',
      'Faisalabad',
      'Eminabad',
      'Dunyapur',
      'Dunga Bunga',
      'Dullewala',
      'Duki',
      'Dokri',
      'Doaba',
      'Upper Dir',
      'Diplo',
      'Dipalpur',
      'Dinan Bashnoian Wala',
      'Dinga',
      'Dijkot',
      'Digri',
      'Dhoro Naro',
      'Dhaunkal',
      'Dhanot',
      'Dera Ismail Khan',
      'Dera Ghazi Khan',
      'Dera Bugti',
      'Daur',
      'Daultala',
      'Daulatpur',
      'Daud Khel',
      'Daska Kalan',
      'Darya Khan Marri',
      'Darya Khan',
      'Daromehar',
      'Dalbandin',
      'Dajal',
      'Daira Din Panah',
      'Daggar',
      'Dadu',
      'Dadhar',
      'Chunian',
      'Chuhar Kana',
      'Chhor',
      'Choa Saidan Shah',
      'Chitral',
      'Chishtian',
      'Chiniot',
      'Chilas',
      'Chichawatni',
      'Cherat Cantonement',
      'Chawinda',
      'Charsadda',
      'Chamber',
      'Chaman',
      'Chakwal',
      'Chak Azam Sahu',
      'Chak',
      'Burewala',
      'Bulri',
      'Bozdar Wada',
      'Bhopalwala',
      'Bhit Shah',
      'Bhiria',
      'Bhimber',
      'Bhera',
      'Bhawana',
      'Bhan',
      'Bhalwal',
      'Bhakkar',
      'Mianke Mor',
      'Bhag',
      'Berani',
      'Bela',
      'Begowala',
      'Bat Khela',
      'Battagram',
      'Basirpur',
      'Barkhan',
      'Bannu',
      'Bandhi',
      'Bakhri Ahmad Khan',
      'Bahawalpur',
      'Bahawalnagar',
      'Bagh',
      'Bagarji',
      'Baffa',
      'Badin',
      'Baddomalhi',
      'Awaran',
      'Attock City',
      'Arifwala',
      'Aman Garh',
      'Alizai',
      'Alipur',
      'Aliabad',
      'Akora',
      'Ahmadpur East',
      'Abbottabad',
      'Jhang City',
      'Gulishah Kach',
      'Nowshera Cantonment',
      'Alik Ghund',
      'Khadan Khak',
      'Ahmadpur Sial',
      'New Badah',
      'Dandot RS',
      'Dera Murad Jamali',
      'Jām Sāhib',
      'Chowki Jamali',
      'Setharja Old',
      'Sook Kalan',
      'Risalpur Cantonment',
      'Noorabad',
      'Alpurai',
      'Shingli Bala',
      'Malakwal City',
      'Mehmand Chak',
      'Basti Dosa',
      'Moza Shahwala',
      'Muhammad Moryal Sahito Jo Goth',
      'Dambudas',
      'Eidghah',
      'Dasu',
      'Barishal',
      'Athmuqam',
      'Nazir Town',
      'Amirabad',
      'Kakad Wari Dir Upper',
      'Hattian Bala',
      'Ashanagro Koto',
      'Athhmuqam',
      'Mughalabad',
    ],
  },
  {
    country: 'Poland',
    cities: [
      'Żyrzyn',
      'Żyrardów',
      'Żyraków',
      'Zwoleń',
      'Zwierzyniec',
      'Żurowa',
      'Żurawiczki',
      'Żurawica',
      'Żołynia',
      'Żółkiewka',
      'Żoliborz',
      'Zmiennica',
      'Złota',
      'Zielonka',
      'Zgłobień',
      'Żelechów',
      'Żelechlinek',
      'Żegocina',
      'Zbuczyn',
      'Zbójna',
      'Zawichost',
      'Zatory',
      'Zarzecze',
      'Zarszyn',
      'Żarnów',
      'Zamość',
      'Zamch',
      'Zambrów',
      'Załuski',
      'Zaleszany',
      'Zalesie Górne',
      'Zalesie',
      'Zakrzówek',
      'Zakrzew',
      'Zakroczym',
      'Zaklików',
      'Zakliczyn',
      'Zahutyń',
      'Zagórzyce',
      'Zagórz',
      'Zagnańsk',
      'Żabno',
      'Zabłudów',
      'Ząbki',
      'Zabierzów Bocheński',
      'Żabieniec',
      'Żabia Wola',
      'Wyszogród',
      'Wyszków',
      'Wyszki',
      'Wysokie Mazowieckie',
      'Wysokie',
      'Wysoka Strzyżowska',
      'Wysoka Głogowska',
      'Wysoka',
      'Wyśmierzyce',
      'Wydminy',
      'Wołomin',
      'Wólka Tanewska',
      'Wólka Podleśna',
      'Wólka Pełkińska',
      'Wólka Niedźwiedzka',
      'Wola Żarczycka',
      'Wola Zabierzowska',
      'Wola Uhruska',
      'Wola Sernicka',
      'Wola Rębkowska',
      'Wolanów',
      'Wola Jachowa',
      'Wola Dębińska',
      'Wola Batorska',
      'Wola',
      'Wojsławice',
      'Wojnicz',
      'Wojcieszków',
      'Wojciechowice',
      'Wojciechów',
      'Wojaszówka',
      'Wohyń',
      'Wodzisław',
      'Wodynie',
      'Włodawa',
      'Włochy',
      'Wizna',
      'Wisznice',
      'Wiśniowa',
      'Wiśniew',
      'Wiślica',
      'Wiskitki',
      'Winnica',
      'Wilków',
      'Wilkołaz',
      'Wilga',
      'Wilczyce',
      'Wilanów',
      'Wietrzychowice',
      'Wierzchosławice',
      'Wierzbno',
      'Wierzbna',
      'Wierzbica',
      'Wierzawice',
      'Wieniawa',
      'Wielopole Skrzyńskie',
      'Wielkie Oczy',
      'Wielka Wieś',
      'Wieliszew',
      'Wieliczka',
      'Wielbark',
      'Wieczfnia Kościelna',
      'Wiązownica',
      'Wesoła',
      'Werbkowice',
      'Węgrzce Wielkie',
      'Węgrów',
      'Węgorzewo',
      'Węglówka',
      'Wawrzeńczyce',
      'Wąwolnica',
      'Wawer',
      'Wąsosz',
      'Waśniów',
      'Wasilków',
      'Warsaw',
      'Warka',
      'Jodłówka-Wałki',
      'Waksmund',
      'Wadowice Górne',
      'Wąchock',
      'Ustrzyki Dolne',
      'Uścimów Stary',
      'Uście Gorlickie',
      'Urzejowice',
      'Urzędów',
      'Urszulin',
      'Ursus',
      'Ulhówek',
      'Ułęż',
      'Ulanów',
      'Uherce Mineralne',
      'Tyszowce',
      'Tyrawa Wołoska',
      'Tymbark',
      'Tylicz',
      'Tykocin',
      'Tyczyn',
      'Turze Pole',
      'Turośń Kościelna',
      'Turobin',
      'Tuczna',
      'Tuczępy',
      'Tuchów',
      'Trzydnik Duży',
      'Trzemeśnia',
      'Trzebownisko',
      'Trzebieszów',
      'Trzcinica',
      'Trzciana',
      'Tryńcza',
      'Truskaw',
      'Troszyn',
      'Trojanów',
      'Trawniki',
      'Trablice',
      'Trąbki',
      'Tomaszów Mazowiecki',
      'Tomaszów Lubelski',
      'Tłuszcz',
      'Tereszpol',
      'Terespol',
      'Teresin',
      'Telatyn',
      'Tczów',
      'Tarnowiec',
      'Tarnów',
      'Tarnogród',
      'Tarnobrzeg',
      'Tarnawatka',
      'Tarłów',
      'Targowisko',
      'Targówek',
      'Tarczyn',
      'Szynwałd',
      'Szydłowo',
      'Szydłowiec',
      'Szydłów',
      'Szumowo',
      'Szreńsk',
      'Szerzyny',
      'Szepietowo',
      'Szelków',
      'Szczytno',
      'Szczurowa',
      'Szczuczyn',
      'Szczucin',
      'Szczebrzeszyn',
      'Szczawnica',
      'Szastarka',
      'Szarów',
      'Szaflary',
      'Sypniewo',
      'Świlcza',
      'Swiętajno',
      'Świętajno',
      'Świdnik',
      'Suwałki',
      'Susiec',
      'Suraż',
      'Supraśl',
      'Sułów',
      'Sułkowice',
      'Sulejówek',
      'Suchożebry',
      'Suchowola',
      'Suchedniów',
      'Sucha',
      'Stupsk',
      'Stubno',
      'Strzyżowice',
      'Strzyżów',
      'Strzegowo',
      'Stromiec',
      'Strawczyn',
      'Straszydle',
      'Strachówka',
      'Strachocina',
      'Stopnica',
      'Stoczek Łukowski',
      'Stoczek',
      'Stężyca',
      'Sterdyń',
      'Stawiski',
      'Stawiguda',
      'Staszów',
      'Stary Zamość',
      'Stary Wiśnicz',
      'Stary Sącz',
      'Stare Miasto',
      'Stare Juchy',
      'Stare Babice',
      'Stara Wieś',
      'Starachowice',
      'Stąporków',
      'Stanisławów Pierwszy',
      'Stanisławów',
      'Stanisławice',
      'Stalowa Wola',
      'Srokowo',
      'Śródmieście',
      'Spiczyn',
      'Sosnówka',
      'Sosnowica',
      'Sośnica',
      'Sońsk',
      'Sonina',
      'Solec-Zdrój',
      'Solec Nad Wisłą',
      'Sokoły',
      'Sokołów Podlaski',
      'Sokołów Małopolski',
      'Sokolniki',
      'Sokółka',
      'Sochocin',
      'Sochaczew',
      'Sobolew',
      'Sobków',
      'Sobienie Jeziory',
      'Śniadowo',
      'Smyków',
      'Smęgorzów',
      'Słupno',
      'Słupiec',
      'Słupia',
      'Słotowa',
      'Słopnice',
      'Słomniki',
      'Sławno',
      'Sławatycze',
      'Skrzyszów',
      'Skrzydlna',
      'Skórzec',
      'Skołyszyn',
      'Skierniewice',
      'Skierbieszów',
      'Skarżysko Kościelne',
      'Skarżysko-Kamienna',
      'Skaryszew',
      'Skalbmierz',
      'Sitno',
      'Osiedle-Nowiny',
      'Sietesz',
      'Sieradza',
      'Sienno',
      'Siennica',
      'Sieniawa',
      'Siemień',
      'Siemiechów',
      'Siemiatycze',
      'Siemiątkowo',
      'Siedliska',
      'Siedleczka',
      'Siedlce',
      'Serokomla',
      'Serock',
      'Serniki',
      'Sępopol',
      'Sękowa',
      'Sejny',
      'Sędziszów Małopolski',
      'Sędziszów',
      'Sawin',
      'Sarnaki',
      'Sanok',
      'Sandomierz',
      'Samborzec',
      'Sadowne',
      'Sadowie',
      'Sadkowice',
      'Sabnie',
      'Rzezawa',
      'Rzewnie',
      'Rzeszów',
      'Rzepiennik Strzyżewski',
      'Rzepedź',
      'Rzekuń',
      'Rzeczyca',
      'Rzeczniów',
      'Rząśnik',
      'Rytwiany',
      'Rytro',
      'Ryn',
      'Rymanów',
      'Ryki',
      'Ryglice',
      'Ryczywół',
      'Rybno',
      'Rybie',
      'Rybczewice',
      'Rutki',
      'Rusinów',
      'Rudnik',
      'Rudnik nad Sanem',
      'Rudna Wielka',
      'Rudna Mała',
      'Rudka',
      'Ruda Maleniecka',
      'Ruciane-Nida',
      'Rozogi',
      'Rozbórz',
      'Siennica Różana',
      'Różan',
      'Rossosz',
      'Ropczyce',
      'Ropa',
      'Rokitno',
      'Rokietnica',
      'Rogóźno',
      'Rogów',
      'Reszel',
      'Repki',
      'Rembertów',
      'Rejowiec Fabryczny',
      'Rejowiec',
      'Regnów',
      'Regimin',
      'Rawa Mazowiecka',
      'Raszyn',
      'Raniżów',
      'Rakszawa',
      'Raków',
      'Rajgród',
      'Radzyń Podlaski',
      'Radzymin',
      'Radziłów',
      'Radziejowice',
      'Radzanów',
      'Radymno',
      'Radoszyce',
      'Radomyśl Wielki',
      'Radomyśl',
      'Radom',
      'Radłów',
      'Radgoszcz',
      'Radecznica',
      'Raczki',
      'Racławice',
      'Raciąż',
      'Rachanie',
      'Pysznica',
      'Puszcza Mariańska',
      'Purda',
      'Puńsk',
      'Pułtusk',
      'Puławy',
      'Puchaczów',
      'Przytyk',
      'Przytoczno',
      'Przysucha',
      'Przysietnica',
      'Przyłęk',
      'Przybyszówka',
      'Przyborów',
      'Przeworsk',
      'Przesmyki',
      'Przemyśl',
      'Przędzel',
      'Przedmieście Dubieckie',
      'Przecław',
      'Przasnysz',
      'Pruszków',
      'Pruchnik',
      'Proszowice',
      'Prostki',
      'Promna',
      'Prażmów',
      'Pozezdrze',
      'Potworów',
      'Potok Wielki',
      'Potok Górny',
      'Poświętne',
      'Poronin',
      'Poręba Spytkowska',
      'Poręba',
      'Poraż',
      'Porąbka Uszewska',
      'Poniatowa',
      'Pomiechówek',
      'Połomia',
      'Policzna',
      'Połaniec',
      'Polańczyk',
      'Pokrzywnica',
      'Pokrówka',
      'Pogórska Wola',
      'Podłęże',
      'Podkowa Leśna',
      'Podegrodzie',
      'Podedwórze',
      'Pniewy',
      'Płońsk',
      'Płoniawy-Bramura',
      'Pleśna',
      'Piwniczna-Zdrój',
      'Piszczac',
      'Pisz',
      'Piskorowice',
      'Pionki',
      'Pińczów',
      'Pilzno',
      'Pilawa',
      'Pierzchnica',
      'Pieniężno',
      'Piekoszów',
      'Piecki',
      'Piątnica',
      'Piastów',
      'Piaski',
      'Piaseczno',
      'Perlejewo',
      'Pawłów',
      'Pawłosiów',
      'Pawlikowice',
      'Pasym',
      'Parysów',
      'Parczew',
      'Paradyż',
      'Paprotnia',
      'Pantalowice',
      'Padew Narodowa',
      'Pacanów',
      'Ożarów Mazowiecki',
      'Ożarów',
      'Owczarnia',
      'Otwock',
      'Otrębusy',
      'Ostrowsko',
      'Ostrów Mazowiecka',
      'Ostrów Lubelski',
      'Ostrowiec Świętokrzyski',
      'Ostrówek',
      'Ostrów',
      'Ostrołęka',
      'Osiek Jasielski',
      'Osiek',
      'Osieczany',
      'Osieck',
      'Orzysz',
      'Orzechówka',
      'Orońsko',
      'Orneta',
      'Orły',
      'Orla',
      'Opole Lubelskie',
      'Opoczno',
      'Opinogóra Górna',
      'Opatowiec',
      'Opatów',
      'Olsztynek',
      'Olsztyn',
      'Olszówka',
      'Olszanica',
      'Olszana',
      'Ołpiny',
      'Oleszyce',
      'Olesno',
      'Oleśnica',
      'Olecko',
      'Oksa',
      'Okocim',
      'Ojrzeń',
      'Odrzywół',
      'Odrzykoń',
      'Ochotnica Dolna',
      'Ochota',
      'Obsza',
      'Obryte',
      'Obrazów',
      'Nurzec-Stacja',
      'Nur',
      'Nozdrzec',
      'Nowy Żmigród',
      'Nowy Wiśnicz',
      'Nowy Targ',
      'Nowy Sącz',
      'Nowy Korczyn',
      'Nowy Dwór Mazowiecki',
      'Nowosielce-Gniewosz',
      'Nowosielce',
      'Nowogród',
      'Nowodwór',
      'Nowe Sioło',
      'Nowe Miasto nad Pilicą',
      'Nowe Miasto',
      'Nowe Brzesko',
      'Nowa Słupia',
      'Nowa Sarzyna',
      'Nowa Dęba',
      'Niwiska',
      'Nisko',
      'Nieporęt',
      'Niepołomice',
      'Nienadowa',
      'Niemce',
      'Nielisz',
      'Niedźwiedź',
      'Niedźwiada',
      'Niedzica',
      'Niedrzwica Duża',
      'Niedomice',
      'Niechobrz',
      'Niebylec',
      'Nieborów',
      'Niebocko',
      'Niebieszczany',
      'Nidzica',
      'Nawojowa',
      'Nasielsk',
      'Naruszewo',
      'Narol',
      'Narew',
      'Nałęczów',
      'Nagłowice',
      'Nadarzyn',
      'Myszyniec',
      'Mysiadło',
      'Muszyna',
      'Mszczonów',
      'Mszana Górna',
      'Mszana Dolna',
      'Mrozy',
      'Mrągowo',
      'Moszczenica',
      'Mordy',
      'Morawica',
      'Mońki',
      'Mokotów',
      'Mokobody',
      'Mogielnica',
      'Modliborzyce',
      'Mniszków',
      'Mniów',
      'Młynarze',
      'Młodzieszyn',
      'Mława',
      'Mirzec',
      'Mirów',
      'Mirocin',
      'Mircze',
      'Mińsk Mazowiecki',
      'Milejów',
      'Milejczyce',
      'Milanówek',
      'Milanów',
      'Miłakowo',
      'Mikołajki',
      'Miętne',
      'Mielec',
      'Miejsce Piastowe',
      'Międzyrzec Podlaski',
      'Miedzna',
      'Miedziana Góra',
      'Miechów',
      'Michów',
      'Michałowo',
      'Michałowice',
      'Michałów-Reginów',
      'Michałów',
      'Miastków Kościelny',
      'Miączyn',
      'Mełgiew',
      'Medyka',
      'Mędrzechów',
      'Maszkienice',
      'Masłów',
      'Markuszów',
      'Markowa',
      'Marki',
      'Maniowy',
      'Manasterz',
      'Mały Płock',
      'Małogoszcz',
      'Małkinia Górna',
      'Maków Mazowiecki',
      'Maków',
      'Majdan Królewski',
      'Maciejowice',
      'Jeziorzany',
      'Łyse',
      'Łużna',
      'Lutowiska',
      'Lutoryż',
      'Łukowica',
      'Łukowa',
      'Łuków',
      'Ludwin',
      'Lubycza Królewska',
      'Lubomino',
      'Lubomierz',
      'Lubochnia',
      'Łubnice',
      'Lublin',
      'Lubenia',
      'Lubartów',
      'Lubaczów',
      'Łososina Dolna',
      'Łosice',
      'Łopuszno',
      'Łopuszna',
      'Łopuszka Wielka',
      'Łopiennik Górny',
      'Łoniów',
      'Łomża',
      'Łomianki',
      'Łomazy',
      'Łochów',
      'Liw',
      'Lisia Góra',
      'Lipsko',
      'Lipsk',
      'Lipowiec Kościelny',
      'Lipinki',
      'Lipnik',
      'Lipnica Wielka',
      'Lipnica',
      'Nowe Lipiny',
      'Limanowa',
      'Lidzbark Warmiński',
      'Leżajsk',
      'Łętownia',
      'Lesznowola',
      'Leszno',
      'Leśnica',
      'Leśna Podlaska',
      'Lesko',
      'Lelkowo',
      'Łęki Górne',
      'Łęki Dolne',
      'Łęki',
      'Legionowo',
      'Łęczna',
      'Lecka',
      'Łaziska',
      'Latowicz',
      'Laszki',
      'Łaszczów',
      'Laskowa',
      'Łaskarzew',
      'Łapy',
      'Łapsze Niżne',
      'Łapczyca',
      'Łapanów',
      'Łańcut',
      'Łajski',
      'Łagów',
      'Łączna',
      'Łącko',
      'Łabunie',
      'Łabowa',
      'Kuźnica',
      'Kuryłówka',
      'Kurów',
      'Kunów',
      'Kuczbork-Osada',
      'Księżpol',
      'Księżomierz',
      'Krzywda',
      'Krzywcza',
      'Krzynowłoga Mała',
      'Krzeszów',
      'Krzemienica',
      'Krzeczowice',
      'Krzeczów',
      'Krynki',
      'Krynice',
      'Krynica-Zdrój',
      'Kruklanki',
      'Krosno',
      'Krośnica',
      'Krościenko Wyżne',
      'Krempna',
      'Krasocin',
      'Krasnystaw',
      'Krasnosielc',
      'Krasnopol',
      'Krasnobród',
      'Kraśnik',
      'Kraśniczyn',
      'Krasne',
      'Krasiczyn',
      'Kramarzówka',
      'Kraczkowa',
      'Kozubszczyzna',
      'Kozłowo',
      'Kozłów',
      'Kozienice',
      'Kowiesy',
      'Kowale Oleckie',
      'Kowala',
      'Kotuń',
      'Koszyce',
      'Kostomłoty Pierwsze',
      'Kostomłoty Drugie',
      'Kosów Lacki',
      'Kosina',
      'Korzenna',
      'Korytnica',
      'Kortowo',
      'Korsze',
      'Stara Kornica',
      'Korczyna',
      'Korczew',
      'Koprzywnica',
      'Konstantynów',
      'Konstancin-Jeziorna',
      'Końskowola',
      'Końskie',
      'Konieczkowa',
      'Kończyce',
      'Komorów',
      'Komarówka Podlaska',
      'Komarów-Osada',
      'Komańcza',
      'Kolno',
      'Kolbuszowa',
      'Kołbiel',
      'Kołaczyce',
      'Kokotów',
      'Kodeń',
      'Kock',
      'Kobyłka',
      'Kobylanka',
      'Knyszyn',
      'Klwów',
      'Kluszkowce',
      'Kłoczew',
      'Klimontów',
      'Kleszczele',
      'Klembów',
      'Kłaj',
      'Kije',
      'Kiełpin',
      'Kielce',
      'Kielanówka',
      'Kętrzyn',
      'Kępie Żaleszańskie',
      'Kazimierz Dolny',
      'Kazimierza Wielka',
      'Kazanów',
      'Kawęczyn Nowy',
      'Kasina Wielka',
      'Karniewo',
      'Karczmiska',
      'Karczew',
      'Kaniów',
      'Kańczuga',
      'Kampinos',
      'Kamionka Wielka',
      'Kamionka',
      'Kamienica',
      'Kamień',
      'Kałuszyn',
      'Kadzidło',
      'Kabaty',
      'Józefów',
      'Józefów nad Wisłą',
      'Józefosław',
      'Jonkowo',
      'Joniec',
      'Jodłówka',
      'Jodłowa',
      'Jeżowe',
      'Jeziorany',
      'Jedwabno',
      'Jedwabne',
      'Jędrzejów',
      'Jednorożec',
      'Jedlnia-Letnisko',
      'Jedlińsk',
      'Jedlicze',
      'Jawornik Polski',
      'Jawornik',
      'Jastrzębia',
      'Jastrząb',
      'Jastków',
      'Jasło',
      'Jaśliska',
      'Jasionów',
      'Jasieniec',
      'Jasienica Rosielna',
      'Jasienica',
      'Jasień',
      'Jarosław',
      'Jarocin',
      'Jarczów',
      'Janów Podlaski',
      'Janów Lubelski',
      'Janowiec',
      'Janowice',
      'Janów',
      'Jakubowice Murowane',
      'Jakubów',
      'Jagiełła',
      'Jadowniki Mokre',
      'Jadowniki',
      'Jadów',
      'Jabłonna Lacka',
      'Jabłonna',
      'Jabłonka',
      'Jabłonica Polska',
      'Jabłoń',
      'Izbica',
      'Iwonicz-Zdrój',
      'Iwkowa',
      'Iwierzyce',
      'Iwaniska',
      'Inowłódz',
      'Imielno',
      'Iłża',
      'Iłów',
      'Hyżne',
      'Husów',
      'Humniska',
      'Hrubieszów',
      'Horyniec-Zdrój',
      'Horodło',
      'Hornówek',
      'Harasiuki',
      'Hańsk',
      'Handzlówka',
      'Halinów',
      'Hajnówka',
      'Haczów',
      'Gzy',
      'Gwoźnica Górna',
      'Guzów',
      'Grzybowa Góra',
      'Grzęska',
      'Grybów',
      'Gruszów Wielki',
      'Grudusk',
      'Grudki',
      'Gromnik',
      'Grójec',
      'Grodzisko Górne',
      'Grodzisko Dolne',
      'Grodzisk Mazowiecki',
      'Grębów',
      'Gręboszów',
      'Grębków',
      'Granica',
      'Grajewo',
      'Grabownica Starzeńska',
      'Grabowiec',
      'Gózd',
      'Goworowo',
      'Gowarczów',
      'Goszczyn',
      'Gościeradów',
      'Gorzyce',
      'Górzno',
      'Gorzków',
      'Górowo Iławeckie',
      'Górno',
      'Gorliczyna',
      'Gorlice',
      'Górki',
      'Góra Kalwaria',
      'Goraj',
      'Goniadz',
      'Gołymin-Ośrodek',
      'Gołdap',
      'Golcowa',
      'Godziszów',
      'Godzianów',
      'Gnojno',
      'Gnojnik',
      'Głuchów',
      'Głogów Małopolski',
      'Glinojeck',
      'Giżycko',
      'Gietrzwałd',
      'Gierzwałd',
      'Gierłoż',
      'Gielniów',
      'Giedlarowa',
      'Gdów',
      'Gąsocin',
      'Garwolin',
      'Garbów',
      'Garbatka-Letnisko',
      'Gać',
      'Frysztak',
      'Frydman',
      'Fredropol',
      'Frampol',
      'Firlej',
      'Filipów',
      'Fałków',
      'Fajsławice',
      'Ełk',
      'Dzwola',
      'Dźwierzuty',
      'Dzików Stary',
      'Dzikowiec',
      'Dziewin',
      'Dzierzkowice',
      'Dzierzgowo',
      'Dzierzążnia',
      'Dziekanów Leśny',
      'Działoszyce',
      'Działdowo',
      'Dziadkowice',
      'Dywity',
      'Dynów',
      'Dydnia',
      'Dwikozy',
      'Dukla',
      'Duczki',
      'Dubienka',
      'Dubiecko',
      'Drzewica',
      'Drohiczyn',
      'Drelów',
      'Dorohusk',
      'Domaszowice',
      'Domaradz',
      'Domanice',
      'Dołhobyczów',
      'Dobrzyniewo Duże',
      'Dobre Miasto',
      'Dobre',
      'Dobra',
      'Dobczyce',
      'Długosiodło',
      'Długie',
      'Dębowiec',
      'Dębowa Kłoda',
      'Dębów',
      'Dębno',
      'Dęblin',
      'Dębica',
      'Dębe Wielkie',
      'Daleszyce',
      'Dąbrówno',
      'Dąbrówka',
      'Dąbrowa Tarnowska',
      'Dąbrowa Białostocka',
      'Czyżew',
      'Czudec',
      'Czerwonka',
      'Czerwińsk Nad Wisłą',
      'Czerwin',
      'Czerniewice',
      'Czernice Borowe',
      'Czermin',
      'Czeremcha',
      'Czemierniki',
      'Czchów',
      'Czaszyn',
      'Czarnocin',
      'Czarnochowice',
      'Czarnia',
      'Czarna Góra',
      'Czarna Białostocka',
      'Czarna',
      'Cyców',
      'Cmolas',
      'Ćmielów',
      'Cisna',
      'Ciężkowice',
      'Cieszanów',
      'Ciepielów',
      'Cielądz',
      'Ciechanowiec',
      'Ciechanów',
      'Chynów',
      'Chwałowice',
      'Chrzanów',
      'Chorzele',
      'Choroszcz',
      'Chorkówka',
      'Chodel',
      'Chmielnik',
      'Chlewiska',
      'Chełmiec',
      'Chełm',
      'Chęciny',
      'Ceranów',
      'Celestynów',
      'Cegłów',
      'Bystra',
      'Bychawa',
      'Busko-Zdrój',
      'Bulkowo',
      'Bukowsko',
      'Bukowina Tatrzańska',
      'Brzyska',
      'Brzozów',
      'Brzóza Stadnicka',
      'Brzóza Królewska',
      'Brzostek',
      'Brzeźnica',
      'Brzeziny',
      'Brzesko',
      'Brzączowice',
      'Brwinów',
      'Brok',
      'Brody',
      'Brochów',
      'Brańszczyk',
      'Brańsk',
      'Borzęcin',
      'Borzechów',
      'Borowie',
      'Borowa',
      'Borkowice',
      'Borki',
      'Borek',
      'Bolimów',
      'Bojanów',
      'Boguty-Pianki',
      'Boguchwała',
      'Bogoria',
      'Bodzentyn',
      'Bodzechów',
      'Bodzanów',
      'Boćki',
      'Bochnia',
      'Bobrowniki',
      'Bobowa',
      'Stara Błotnica',
      'Błonie',
      'Bliżyn',
      'Blizne',
      'Błędów',
      'Błażowa',
      'Bisztynek',
      'Biszcza',
      'Biskupiec',
      'Biskupice Radłowskie',
      'Bircza',
      'Binarowa',
      'Biłgoraj',
      'Bieniewice',
      'Bielsk Podlaski',
      'Bieliny',
      'Bielany',
      'Biecz',
      'Białystok',
      'Biały Dunajec',
      'Białowieża',
      'Białołeka',
      'Białobrzegi',
      'Białka Tatrzańska',
      'Biała Rawska',
      'Biała Podlaska',
      'Biała Piska',
      'Białaczów',
      'Besko',
      'Bemowo',
      'Bełżyce',
      'Belsk Duży',
      'Bejsce',
      'Batorz',
      'Bartoszyce',
      'Barczewo',
      'Barciany',
      'Baranów Sandomierski',
      'Baranowo',
      'Baranów',
      'Baniocha',
      'Banie Mazurskie',
      'Bałtów',
      'Baligród',
      'Baćkowice',
      'Baboszewo',
      'Babica',
      'Augustów',
      'Annopol',
      'Aleksandrów',
      'Albigowa',
      'Adamówka',
      'Adamów',
      'Abramów',
      'Żywiec',
      'Żychlin',
      'Zwierzyń',
      'Żuromin',
      'Żukowo',
      'Zubrzyca Górna',
      'Zubrzyca Dolna',
      'Żory',
      'Żórawina',
      'Żnin',
      'Żmigród',
      'Złoty Stok',
      'Złotów',
      'Złotoryja',
      'Złotniki Kujawskie',
      'Złotniki',
      'Złoczew',
      'Złocieniec',
      'Zławieś Wielka',
      'Zielonki',
      'Zielona Góra',
      'Ziębice',
      'Zgorzelec',
      'Zgierz',
      'Żerniki Wrocławskie',
      'Żernica',
      'Żerków',
      'Zembrzyce',
      'Zelów',
      'Żelistrzewo',
      'Żelazków',
      'Zebrzydowice',
      'Zębowice',
      'Zdzieszowice',
      'Zduny',
      'Zduńska Wola',
      'Zbytków',
      'Zbrosławice',
      'Zbójno',
      'Zblewo',
      'Zbąszynek',
      'Zbąszyń',
      'Zawoja',
      'Zawiercie',
      'Zawidz',
      'Zawidów',
      'Zawadzkie',
      'Zator',
      'Żary',
      'Żarów',
      'Żarki',
      'Zapolice',
      'Zaniemyśl',
      'Zamarski',
      'Zalewo',
      'Zalas',
      'Zakrzów',
      'Zakrzewo',
      'Zakopane',
      'Zagrodno',
      'Zagórze',
      'Zagórów',
      'Zagórnik',
      'Żagań',
      'Zadzim',
      'Zabrzeg',
      'Zabrze',
      'Zaborze',
      'Zabór',
      'Żabnica',
      'Zabłocie',
      'Ząbkowice Śląskie',
      'Zabierzów',
      'Wyrzysk',
      'Wyry',
      'Wymiarki',
      'Wschowa',
      'Września',
      'Wrząsowice',
      'Wronki',
      'Wrocław',
      'Wróblew',
      'Wręczyca Wielka',
      'Wożniki',
      'Woźniki',
      'Wolsztyn',
      'Wołowice',
      'Wołów',
      'Wolin',
      'Wołczyn',
      'Wolbrom',
      'Wolbórz',
      'Wola Radziszowska',
      'Wola Krzysztoporska',
      'Wola Filipowska',
      'Wojkowice',
      'Wojcieszów',
      'Wodzisław Śląski',
      'Wodzierady',
      'Włoszczowa',
      'Włoszakowice',
      'Włosienica',
      'Włodowice',
      'Włocławek',
      'Wleń',
      'Władysławowo',
      'Władysławów',
      'Witonia',
      'Witnica',
      'Witkowo',
      'Witkowice',
      'Witaszyce',
      'Witanowice',
      'Wisła',
      'Wińsko',
      'Wilkowice',
      'Wilczyn',
      'Wilczogóra',
      'Wilcza',
      'Wilamowice',
      'Wijewo',
      'Wieszowa',
      'Wierzchucino',
      'Wierzchowo',
      'Wierzchlas',
      'Wierzbinek',
      'Wieruszów',
      'Wieprz',
      'Wieluń',
      'Wielowieś',
      'Wielka Nieszawka',
      'Wielichowo',
      'Wielgie',
      'Wieleń',
      'Większyce',
      'Więcbork',
      'Widuchowa',
      'Widawa',
      'Wiązów',
      'Wejherowo',
      'Węgry',
      'Węgorzyno',
      'Węgliniec',
      'Węgierska Górka',
      'Wartkowice',
      'Warta',
      'Warnice',
      'Warlubie',
      'Wapno',
      'Wąpielsk',
      'Walim',
      'Walichnowy',
      'Wałcz',
      'Walce',
      'Wałbrzych',
      'Wągrowiec',
      'Waganiec',
      'Wadowice',
      'Wąbrzeźno',
      'Ustronie Morskie',
      'Ustroń',
      'Ustka',
      'Unisław',
      'Uniejów',
      'Ujsoły',
      'Ujście',
      'Ujazd',
      'Udanin',
      'Uciechów',
      'Tyniec Mały',
      'Tychy',
      'Tychowo',
      'Tworóg',
      'Tworków',
      'Twardogóra',
      'Twardawa',
      'Tuszyn',
      'Turza Śląska',
      'Turek',
      'Tuplice',
      'Tułowice',
      'Tuliszków',
      'Tuczno',
      'Tuchomie',
      'Tuchola',
      'Trzemeszno',
      'Trzebunia',
      'Trzebnica',
      'Trzebinia',
      'Trzebielino',
      'Trzebiel',
      'Trzebiechów',
      'Trzebiatów',
      'Trzcińsko Zdrój',
      'Trzciel',
      'Trzcianka',
      'Truskolasy',
      'Trąbki Wielkie',
      'Toszek',
      'Torzym',
      'Toruń',
      'Toporzysko',
      'Topólka',
      'Tomice',
      'Tolkmicko',
      'Tokarnia',
      'Tłuchowo',
      'Tenczynek',
      'Tczew',
      'Tarnowskie Góry',
      'Tarnów Opolski',
      'Tarnowo Podgórne',
      'Tarnówka',
      'Targanice',
      'Szubin',
      'Sztum',
      'Szprotawa',
      'Szlichtyngowa',
      'Szlachta',
      'Szklarska Poręba',
      'Szemud',
      'Szczytna',
      'Szczyrk',
      'Szczutowo',
      'Szczerców',
      'Szczepanów',
      'Szczekociny',
      'Szczecinek',
      'Szczecin',
      'Szczawno-Zdrój',
      'Szczaniec',
      'Szamotuły',
      'Szamocin',
      'Szadek',
      'Syrynia',
      'Syców',
      'Świnoujście',
      'Świnna',
      'Świnice Warckie',
      'Świętochłowice',
      'Święta Katarzyna',
      'Świerzno',
      'Świerzawa',
      'Świerklany Górne',
      'Świerklany Dolne',
      'Świerklaniec',
      'Świerczów',
      'Świeradów-Zdrój',
      'Świekatowo',
      'Świedziebnia',
      'Świebodzin',
      'Świecie nad Osą',
      'Święciechowa',
      'Świecie',
      'Świebodzice',
      'Świdwin',
      'Świdnica',
      'Świątniki Górne',
      'Swarzędz',
      'Suszec',
      'Susz',
      'Sułoszowa',
      'Sulmierzyce',
      'Borne Sulinowo',
      'Sulików',
      'Sulejów',
      'Sulęczyno',
      'Sulęcin',
      'Sulechów',
      'Suchy Las',
      'Suchy Dąb',
      'Suchań',
      'Sucha Beskidzka',
      'Subkowy',
      'Studzionka',
      'Studzienice',
      'Strzelno',
      'Strzelin',
      'Strzeleczki',
      'Strzelce Wielkie',
      'Strzelce Opolskie',
      'Strzelce Krajeńskie',
      'Strzelce',
      'Strzegom',
      'Strzebiń',
      'Strzałkowo',
      'Stryszów',
      'Stryszawa',
      'Stryków',
      'Strumień',
      'Stróża',
      'Stronie Śląskie',
      'Stronie',
      'Straszyn',
      'Stoszowice',
      'Stęszew',
      'Stepnica',
      'Stegna',
      'Stawiszyn',
      'Stary Targ',
      'Staroźreby',
      'Starowa Góra',
      'Starokrzepice',
      'Starogard Gdański',
      'Stargard',
      'Stare Pole',
      'Stare Kurowo',
      'Stare Czarnowo',
      'Stare Bogaczowice',
      'Starcza',
      'Stara Kiszewa',
      'Stara Kamienica',
      'Stara Dąbrowa',
      'Stanowice',
      'Stanisław Górny',
      'Stanisław Dolny',
      'Środa Wielkopolska',
      'Środa Śląska',
      'Śrem',
      'Spytkowice',
      'Sosnowiec',
      'Sosnowice',
      'Sośno',
      'Sośnie',
      'Sośnicowice',
      'Sopotnia Wielka',
      'Sopot',
      'Sompolno',
      'Somonino',
      'Solec Kujawski',
      'Szczerbice',
      'Sól',
      'Sobótka',
      'Smolec',
      'Smołdzino',
      'Śmiłowo',
      'Śmigiel',
      'Smętowo Graniczne',
      'Słupsk',
      'Słupia pod Kępnem',
      'Słupca',
      'Słubice',
      'Słońsk',
      'Śliwice',
      'Ślesin',
      'Ślemień',
      'Sławoborze',
      'Sławków',
      'Sławięcice',
      'Sława',
      'Skwierzyna',
      'Skulsk',
      'Skrwilno',
      'Skoroszyce',
      'Skorogoszcz',
      'Skórcz',
      'Skomlin',
      'Skomielna Czarna',
      'Skoki',
      'Skoczów',
      'Skępe',
      'Skawinki',
      'Skawina',
      'Skawica',
      'Skarszewy',
      'Skąpe',
      'Skalmierzyce',
      'Skała',
      'Siewierz',
      'Sierpc',
      'Sieroszewice',
      'Sierakowice',
      'Sieraków',
      'Sieraków Śląski',
      'Sieradz',
      'Siepraw',
      'Sieniawa Żarska',
      'Siemkowice',
      'Siemianowice Śląskie',
      'Siekierczyn',
      'Siedlisko',
      'Siedlec',
      'Siechnice',
      'Sidzina',
      'Sicienko',
      'Sianów',
      'Sępólno Krajeńskie',
      'Sędziejowice',
      'Secemin',
      'Ścinawa',
      'Sarnów',
      'Sąspów',
      'Santok',
      'Sanniki',
      'Sanka',
      'Sadlinki',
      'Sadki',
      'Sączów',
      'Rzyki',
      'Rzozów',
      'Rzgów Pierwszy',
      'Rzeszotary',
      'Rzepin',
      'Rzeczenica',
      'Rząska',
      'Rypin',
      'Rymań',
      'Ryjewo',
      'Rydzyna',
      'Rydułtowy',
      'Ryczów',
      'Rychwał',
      'Rychtal',
      'Rycerka Górna',
      'Rycerka Dolna',
      'Rybnik',
      'Rybarzowice',
      'Rusocice',
      'Rusiec',
      'Rumia',
      'Rudziniec',
      'Rudziczka',
      'Rudy',
      'Rudniki',
      'Rudna',
      'Rudawa',
      'Ruda Śląska',
      'Rozwadza',
      'Rozprza',
      'Rozdrażew',
      'Rościszewo',
      'Rokitno Szlacheckie',
      'Rokiciny',
      'Rogoźno',
      'Rogoźnik',
      'Rogowo',
      'Rogalinek',
      'Roczyny',
      'Rewal',
      'Resko',
      'Reńska Wieś',
      'Rekowo Dolne',
      'Regulice',
      'Rędziny',
      'Reda',
      'Ręczno',
      'Recz',
      'Rawicz',
      'Ratowice',
      'Raszowa',
      'Raszków',
      'Raszczyce',
      'Rakoniewice',
      'Rajsko',
      'Rajcza',
      'Radzyń Chełmiński',
      'Radziszów',
      'Radzionków',
      'Radziejów',
      'Radziechowy',
      'Radzanowo',
      'Radowo Małe',
      'Radostowice',
      'Radomsko',
      'Radomin',
      'Radocza',
      'Radlin',
      'Radków',
      'Rączna',
      'Racławice Śląskie',
      'Racibórz',
      'Raciążek',
      'Rabka-Zdrój',
      'Rąbino',
      'Raba Wyżna',
      'Pyzdry',
      'Pyskowice',
      'Pyrzyce',
      'Puszczykowo',
      'Puńców',
      'Puck',
      'Pszów',
      'Pszczyna',
      'Pszczółki',
      'Pszczew',
      'Psary',
      'Przywidz',
      'Przytoczna',
      'Przytkowice',
      'Przyszowice',
      'Przystajń',
      'Przyrów',
      'Przykona',
      'Przygodzice',
      'Przybiernów',
      'Przodkowo',
      'Przewóz',
      'Przeworno',
      'Przemków',
      'Przemęt',
      'Przelewice',
      'Przeginia',
      'Przedecz',
      'Przedbórz',
      'Przeciszów',
      'Przecieszyn',
      'Przechlewo',
      'Pruszcz',
      'Pruszcz Gdański',
      'Prusice',
      'Prudnik',
      'Pruchna',
      'Prószków',
      'Prochowice',
      'Praszka',
      'Prabuty',
      'Poznań',
      'Powidz',
      'Potok Złoty',
      'Potęgowo',
      'Poręba Wielka',
      'Cynków',
      'Poraj',
      'Porąbka',
      'Popów',
      'Popielów',
      'Ponikiew',
      'Poniec',
      'Polska Cerekiew',
      'Polkowice',
      'Police',
      'Połczyn-Zdrój',
      'Polanów',
      'Polanka Wielka',
      'Polanica-Zdrój',
      'Połajewo',
      'Pokój',
      'Pogwizdów',
      'Pogrzebień',
      'Pogorzyce',
      'Pogorzela',
      'Pogórze',
      'Podwilk',
      'Podgórzyn',
      'Poddębice',
      'Pobierowo',
      'Pobiedziska',
      'Płużnica',
      'Płoty',
      'Płock',
      'Plewiska',
      'Pleszew',
      'Płaza',
      'Pławno',
      'Pisarzowice',
      'Piotrków Trybunalski',
      'Piotrków Kujawski',
      'Pilica',
      'Pilchowice',
      'Piława Górna',
      'Piła',
      'Pietrzykowice',
      'Pietrowice Wielkie',
      'Pieszyce',
      'Pierściec',
      'Pieńsk',
      'Pielgrzymowice',
      'Piekielnik',
      'Piekary Śląskie',
      'Piekary',
      'Piechowice',
      'Piątek',
      'Piasek',
      'Pewel Wielka',
      'Pewel Ślemieńska',
      'Perzów',
      'Pępowo',
      'Pelplin',
      'Pełczyce',
      'Pęczniew',
      'Pecna',
      'Pcim',
      'Pawonków',
      'Pawłowiczki',
      'Pawłowice',
      'Pątnów',
      'Paszowice',
      'Pasłęk',
      'Parzęczew',
      'Parchowo',
      'Panki',
      'Paniówki',
      'Pamiątkowo',
      'Palcza',
      'Pakosławice',
      'Pakosław',
      'Pakość',
      'Pajęczno',
      'Paczków',
      'Pacyna',
      'Pabianice',
      'Ozorków',
      'Ozimek',
      'Ożarowice',
      'Otyń',
      'Otmuchów',
      'Oświęcim',
      'Ostrzeszów',
      'Ostrowy',
      'Ostrowy nad Okszą',
      'Ostrów Wielkopolski',
      'Ostrowite',
      'Ostroszowice',
      'Ostroróg',
      'Ostróda',
      'Ostrężnica',
      'Ostaszewo',
      'Ośno Lubuskie',
      'Osjaków',
      'Osina',
      'Osielsko',
      'Osielec',
      'Osiek nad Notecią',
      'Osiek Mały',
      'Osieczna',
      'Osięciny',
      'Osie',
      'Orzesze',
      'Orzech',
      'Ornontowice',
      'Orle',
      'Orchowo',
      'Oporów',
      'Opole',
      'Opatówek',
      'Opalenica',
      'Olza',
      'Olszyna',
      'Olszanka',
      'Olkusz',
      'Oława',
      'Kołaczkowo',
      'Okonek',
      'Ogrodzieniec',
      'Odolanów',
      'Ochojno',
      'Ochla',
      'Ochaby',
      'Obrzycko',
      'Obrowo',
      'Oborniki Śląskie',
      'Oborniki',
      'Nysa',
      'Nowy Tomyśl',
      'Nowy Staw',
      'Nowy Dwór Gdański',
      'Nowy Duninów',
      'Nowogrodziec',
      'Nowogródek Pomorski',
      'Nowogród Bobrzański',
      'Nowogard',
      'Nowe Warpno',
      'Nowe Skalmierzyce',
      'Nowe Miasto nad Wartą',
      'Nowe Miasto Lubawskie',
      'Nowe Miasteczko',
      'Nowe',
      'Nowa Wieś Wielka',
      'Nowa Wieś Lęborska',
      'Nowa Wieś',
      'Nowa Sól',
      'Nowa Ruda',
      'Nowa Góra',
      'Nieszawa',
      'Niemodlin',
      'Niemcza',
      'Niegowonice',
      'Niedobczyce',
      'Niechanowo',
      'Nidek',
      'Nekla',
      'Nędza',
      'Naprawa',
      'Namysłów',
      'Nakło nad Notecią',
      'Nakło',
      'Myszków',
      'Mysłowice',
      'Myślibórz',
      'Myślenice',
      'Mysłakowice',
      'Myślachowice',
      'Mykanów',
      'Murowana Goślina',
      'Murów',
      'Murcki',
      'Mszana',
      'Mstów',
      'Mrzeżyno',
      'Mrzezino',
      'Mrozów',
      'Mrocza',
      'Moszczanka',
      'Mosty',
      'Mosina',
      'Moryń',
      'Morąg',
      'Mokrsko',
      'Mogilno',
      'Mogilany',
      'Modlniczka',
      'Modlnica',
      'Mochowo',
      'Mników',
      'Mnich',
      'Młynary',
      'Mirsk',
      'Mirosławiec',
      'Milówka',
      'Miłosław',
      'Miłoradz',
      'Miłomłyn',
      'Miłkowice',
      'Milicz',
      'Mikstat',
      'Mikołów',
      'Mikołajki Pomorskie',
      'Mieszkowice',
      'Mieścisko',
      'Mierzęcice',
      'Mieroszów',
      'Mielno',
      'Mieleszyn',
      'Miękinia',
      'Miejska Górka',
      'Międzyzdroje',
      'Międzyrzecz',
      'Międzylesie',
      'Międzychód',
      'Międzybrodzie Bialskie',
      'Międzybórz',
      'Miedzno',
      'Miedźna',
      'Miedzichowo',
      'Miechów Charsznica',
      'Miastko',
      'Miasteczko Śląskie',
      'Miasteczko Krajeńskie',
      'Mętków',
      'Mazańcowice',
      'Maszewo Duże',
      'Maszewo',
      'Markłowice',
      'Marianowo',
      'Margonin',
      'Marciszów',
      'Małomice',
      'Malec',
      'Małdyty',
      'Malczyce',
      'Malbork',
      'Malanów',
      'Maków Podhalański',
      'Łyszkowice',
      'Łysomice',
      'Lyski',
      'Lwówek Śląski',
      'Lwówek',
      'Luzino',
      'Lututów',
      'Lutomiersk',
      'Lutocin',
      'Luszowice',
      'Ludźmierz',
      'Ludwikowice Kłodzkie',
      'Lubsza',
      'Lubsko',
      'Lubrza',
      'Lubraniec',
      'Łubowo',
      'Lubowidz',
      'Luboń',
      'Lubomia',
      'Lubniewice',
      'Łubniany',
      'Lubliniec',
      'Lublewo Gdańskie',
      'Lubiszyn',
      'Lubin',
      'Lubiewo',
      'Lubień Kujawski',
      'Lubień',
      'Lubichowo',
      'Łubianka',
      'Lubawka',
      'Lubawa',
      'Lubasz',
      'Lubanie',
      'Lubań',
      'Łowicz',
      'Łosiów',
      'Łódź',
      'Łodygowice',
      'Łobżenica',
      'Łobodno',
      'Łobez',
      'Lniano',
      'Liszki',
      'Lisków',
      'Lisewo Malborskie',
      'Lisewo',
      'Lipusz',
      'Lipowa',
      'Lipno',
      'Lipnica Mała',
      'Lipka',
      'Lipinki Łużyckie',
      'Lipie',
      'Lipiany',
      'Lipce Reymontowskie',
      'Liniewo',
      'Linia',
      'Ligota',
      'Lidzbark',
      'Licheń Stary',
      'Libiąż',
      'Libertów',
      'Lgota Wielka',
      'Łęczyce',
      'Lewin Kłodzki',
      'Lewin Brzeski',
      'Leśna',
      'Leńcze',
      'Lelów',
      'Łęknica',
      'Łęki Szlacheckie',
      'Łękawica',
      'Łęka Opatowska',
      'Łęgowo',
      'Legnickie Pole',
      'Legnica',
      'Lędziny',
      'Łęczyca',
      'Lębork',
      'Łeba',
      'Łazy',
      'Łaziska Górne',
      'Łask',
      'Łasin',
      'Lasek',
      'Łanięta',
      'Lanckorona',
      'Łambinowice',
      'Laliki',
      'Łąka Prudnicka',
      'Łagiewniki',
      'Lądek-Zdrój',
      'Lądek',
      'Łączany',
      'Łąck',
      'Lachowice',
      'Łabiszyn',
      'Kwilcz',
      'Kwidzyn',
      'Kwaczała',
      'Kuźnia Raciborska',
      'Kutno',
      'Kuślin',
      'Kurzętnik',
      'Kup',
      'Kunice Żarskie',
      'Kunice',
      'Kuków',
      'Kudowa-Zdrój',
      'Książ Wielkopolski',
      'Książki',
      'Książenice',
      'Ksawerów',
      'Krzyżowice',
      'Krzyżowa',
      'Krzyżanowice',
      'Krzyżanów',
      'Krzyż Wielkopolski',
      'Krzywiń',
      'Krzywaczka',
      'Krzyszkowice',
      'Krzymów',
      'Krzykosy',
      'Krzeszyce',
      'Krzeszowice',
      'Krzepice',
      'Krzemieniewo',
      'Krzęcin',
      'Krzczonów',
      'Krzanowice',
      'Kryspinów',
      'Kryry',
      'Krynica Morska',
      'Kruszwica',
      'Krupski Młyn',
      'Krotoszyn',
      'Krosno Odrzańskie',
      'Krośniewice',
      'Krośnice',
      'Krokowa',
      'Kroczyce',
      'Krobia',
      'Kraszewice',
      'Krapkowice',
      'Kramsk',
      'Kraków',
      'Krajenka',
      'Kozy',
      'Kożuchów',
      'Koźminek',
      'Koźmin Wielkopolski',
      'Koźle',
      'Kozielice',
      'Koziegłowy',
      'Kowary',
      'Kowalewo Pomorskie',
      'Kowale',
      'Kowal',
      'Kotlin',
      'Kotla',
      'Koszarawa',
      'Koszalin',
      'Kostrzyn nad Odrą',
      'Kostrzyn',
      'Koszęcin',
      'Kościerzyna',
      'Kościelisko',
      'Kościelec',
      'Kościan',
      'Kosakowo',
      'Koronowo',
      'Kornowac',
      'Kórnik',
      'Korfantów',
      'Korbielów',
      'Konstantynów Łódzki',
      'Konotop',
      'Konopiska',
      'Konin',
      'Koniecpol',
      'Koniaków',
      'Kończyce Wielkie',
      'Kończyce Małe',
      'Konary',
      'Komprachcice',
      'Komorniki',
      'Koluszki',
      'Kolsko',
      'Kolonowskie',
      'Kołobrzeg',
      'Koło',
      'Koleczkowo',
      'Kołczygłowy',
      'Kolbudy',
      'Kołbaskowo',
      'Koczała',
      'Kochanowice',
      'Kobylnica',
      'Kobylin',
      'Kobyla Góra',
      'Kobiór',
      'Kobierzyce',
      'Kobiernice',
      'Knurów',
      'Kluki',
      'Klucze',
      'Kluczbork',
      'Klonowa',
      'Kłomnice',
      'Kłodzko',
      'Kłodawa',
      'Kłobuck',
      'Klikuszowa',
      'Kleszczów',
      'Kleszczewo',
      'Klenica',
      'Kleczew',
      'Klecza Dolna',
      'Kłecko',
      'Kiszkowo',
      'Kisielice',
      'Kikół',
      'Kietrz',
      'Kiernozia',
      'Kiełczów',
      'Kęty',
      'Kęsowo',
      'Kępno',
      'Kępice',
      'Kędzierzyn-Koźle',
      'Kcynia',
      'Kaźmierz',
      'Kazimierz Biskupi',
      'Kawęczyn',
      'Kąty Wrocławskie',
      'Katowice',
      'Kaszów',
      'Kartuzy',
      'Karsin',
      'Karpacz',
      'Karnice',
      'Karlino',
      'Kargowa',
      'Kamyk',
      'Kamieńsk',
      'Kamień Pomorski',
      'Kamiennik',
      'Kamienna Góra',
      'Kamień Krajeński',
      'Kamieniec Ząbkowicki',
      'Kamieniec Wrocławski',
      'Kamieniec',
      'Kamienica Polska',
      'Kamesznica',
      'Kalwaria Zebrzydowska',
      'Kalisz Pomorski',
      'Kalisz',
      'Kaliska',
      'Kalety',
      'Kalej',
      'Kaczyce',
      'Kaczory',
      'Wielki Kack',
      'Jutrosin',
      'Juszczyna',
      'Juszczyn',
      'Jugów',
      'Jordanów Śląski',
      'Jordanów',
      'Jeżów Sudecki',
      'Jeżów',
      'Jeziora Wielkie',
      'Jeżewo',
      'Jerzmanowice',
      'Jenin',
      'Jemielno',
      'Jemielnica',
      'Jeleśnia',
      'Jelenia Góra',
      'Jelcz',
      'Jejkowice',
      'Jedlina-Zdrój',
      'Jaworzynka',
      'Jaworzyna Śląska',
      'Jaworzno',
      'Jaworze',
      'Jawor',
      'Jastrzębie Zdrój',
      'Jastrzębia Góra',
      'Jastrowie',
      'Jastarnia',
      'Jaroszowice',
      'Jaraczewo',
      'Januszkowice',
      'Janowiec Wielkopolski',
      'Janowice Wielkie',
      'Janków Przygodzki',
      'Jankowice Rybnickie',
      'Jankowice',
      'Janikowo',
      'Jabłonowo Pomorskie',
      'Izdebnik',
      'Izbicko',
      'Izbica Kujawska',
      'Istebna',
      'Inwałd',
      'Ińsko',
      'Inowrocław',
      'Imielin',
      'Iłowa',
      'Iława',
      'Huta Stara B',
      'Herby',
      'Henryków',
      'Hel',
      'Hażlach',
      'Harbutowice',
      'Gubin',
      'Grzmiąca',
      'Grzegorzew',
      'Grzechynia',
      'Gryfów Śląski',
      'Gryfino',
      'Gryfice',
      'Gruta',
      'Grudziądz',
      'Gronowo Górne',
      'Gronowo Elbląskie',
      'Gromadka',
      'Grojec',
      'Grodzisk Wielkopolski',
      'Grodziec',
      'Grodków',
      'Grębocice',
      'Granowo',
      'Grabowo Kościerskie',
      'Grabów nad Prosną',
      'Grabów',
      'Grabica',
      'Gozdowo',
      'Gozdnica',
      'Goszczanów',
      'Gostynin',
      'Gostyń',
      'Gostycyn',
      'Gościno',
      'Gościcino',
      'Gorzyczki',
      'Gorzyce Wielkie',
      'Górzyca',
      'Gorzów Wielkopolski',
      'Gorzów Śląski',
      'Gorzkowice',
      'Górki Wielkie',
      'Gorenice',
      'Górażdże',
      'Góra Świętej Małgorzaty',
      'Góra',
      'Gomunice',
      'Gołuchów',
      'Golub-Dobrzyń',
      'Gołkowice',
      'Golina',
      'Goleszów',
      'Goleniów',
      'Golczewo',
      'Gołańcz',
      'Gogolin',
      'Godziszka',
      'Godów',
      'Goczałkowice Zdrój',
      'Gniezno',
      'Gnieżdżewo',
      'Gniewkowo',
      'Gniewino',
      'Gniew',
      'Gniechowice',
      'Gniazdów',
      'Głuszyca Górna',
      'Głuszyca',
      'Głuchołazy',
      'Głubczyce',
      'Głowno',
      'Główczyce',
      'Głogówek',
      'Głogów',
      'Głogoczów',
      'Gliwice',
      'Gizałki',
      'Gilowice',
      'Gierałtowice',
      'Cielmice',
      'Giebułtów',
      'Gidle',
      'Gdynia',
      'Gdańsk',
      'Gaworzyce',
      'Gaszowice',
      'Gąsawa',
      'Gardeja',
      'Gardawice',
      'Garcz',
      'Gałków Mały',
      'Galewice',
      'Gąbin',
      'Frydrychowice',
      'Frydek',
      'Frombork',
      'Fordon',
      'Filipowice',
      'Fabianki',
      'Elbląg',
      'Dziećmorowice',
      'Dziwnów',
      'Dzierżoniów',
      'Dzierzgoń',
      'Dziergowice',
      'Dziemiany',
      'Dzięgielów',
      'Działoszyn',
      'Dziadowa Kłoda',
      'Dygowo',
      'Duszniki-Zdrój',
      'Duszniki',
      'Drzycim',
      'Drużbice',
      'Drogomyśl',
      'Drobin',
      'Drezdenko',
      'Drawsko Pomorskie',
      'Drawsko',
      'Drawno',
      'Doruchów',
      'Dopiewo',
      'Dominowo',
      'Domaniewice',
      'Dolsk',
      'Dolice',
      'Dobrzyń nad Wisłą',
      'Dobrzyca',
      'Dobrzeń Wielki',
      'Dobrzany',
      'Dobroszyce',
      'Dobroń',
      'Dobrodzień',
      'Dobieszowice',
      'Dobiegniew',
      'Dmosin',
      'Dłutów',
      'Długołęka',
      'Długomiłowice',
      'Deszczno',
      'Debrzno',
      'Dębowa Łąka',
      'Dębnica Kaszubska',
      'Daszyna',
      'Daszewice',
      'Darłowo',
      'Damnica',
      'Damasławek',
      'Dalików',
      'Dalachów',
      'Dąbrowice',
      'Dąbrowa Zielona',
      'Dąbrowa Górnicza',
      'Dąbrowa Chełmińska',
      'Dąbrowa',
      'Dąbie',
      'Czyżowice',
      'Czułów',
      'Człuchów',
      'Człopa',
      'Częstochowa',
      'Czerwonak',
      'Czerwionka-Leszczyny',
      'Czerwieńsk',
      'Czersk',
      'Czernikowo',
      'Czerniejewo',
      'Czernichów',
      'Czernica',
      'Czempiń',
      'Czeladź',
      'Czechowice-Dziedzice',
      'Czastary',
      'Czarny Dunajec',
      'Czarny Bór',
      'Czarnożyły',
      'Czarnowąsy',
      'Czarnków',
      'Czarne',
      'Czarna Woda',
      'Czarków',
      'Czapury',
      'Czaplinek',
      'Czaniec',
      'Czajków',
      'Cybinka',
      'Cisownica',
      'Cisiec',
      'Cisek',
      'Cieszyn',
      'Cieszków',
      'Ciepłowody',
      'Cieplice Śląskie Zdrój',
      'Cięcina',
      'Ciechów',
      'Ciechocinek',
      'Ciechocin',
      'Ciasna',
      'Chyżne',
      'Chybie',
      'Chwaszczyno',
      'Chrzypsko Wielkie',
      'Chrząstowice',
      'Chruszczobród',
      'Chrostkowo',
      'Chrościna',
      'Chróścice',
      'Choszczno',
      'Chorzów',
      'Choroń',
      'Chmielowice',
      'Chojnów',
      'Chojnice',
      'Chojna',
      'Chodzież',
      'Chodów',
      'Chodecz',
      'Chocznia',
      'Choczewo',
      'Chocz',
      'Chociwel',
      'Chocianów',
      'Choceń',
      'Chmielno',
      'Chłapowo',
      'Chełmża',
      'Chełm Śląski',
      'Chełmno',
      'Chełmek',
      'Chąśno',
      'Chałupki',
      'Cewice',
      'Cerkwica',
      'Cekcyn',
      'Cedynia',
      'Cedry Wielkie',
      'Bytów',
      'Bytoń',
      'Bytom Odrzański',
      'Bytom',
      'Bytnica',
      'Bystrzyca Kłodzka',
      'Bystrzyca',
      'Bydgoszcz',
      'Byczyna',
      'Burzenin',
      'Bulowice',
      'Bukowno',
      'Bukowiec',
      'Buk',
      'Bujaków',
      'Budzyń',
      'Budzów',
      'Budziszewice',
      'Buczkowice',
      'Buczek',
      'Brzuze',
      'Brzozie',
      'Brzeźnio',
      'Brzeziny Śląskie',
      'Brzezinka',
      'Brzeszcze',
      'Brześć Kujawski',
      'Brzeg Dolny',
      'Brzeg',
      'Brusy',
      'Brudzew',
      'Brudzeń Duży',
      'Bronów',
      'Brojce',
      'Brójce',
      'Brodnica',
      'Brodła',
      'Brenno',
      'Brenna',
      'Brdów',
      'Brąszewice',
      'Braniewo',
      'Branice',
      'Bralin',
      'Bozkow',
      'Borzytuchom',
      'Borzęta',
      'Borucin',
      'Borowno',
      'Boronów',
      'Borek Wielkopolski',
      'Boniewo',
      'Bolszewo',
      'Bolków',
      'Boleszkowice',
      'Bolesławiec',
      'Bolesław',
      'Bolęcin',
      'Bolechowice',
      'Bojszowy Nowe',
      'Bojszowy',
      'Bojanowo Stare',
      'Bojanowo',
      'Bojano',
      'Bojadła',
      'Boguszów-Gorce',
      'Bogdaniec',
      'Bogatynia',
      'Bobrowo',
      'Bobrowice',
      'Bobowo',
      'Bobolice',
      'Blizanów',
      'Bledzew',
      'Błaszki',
      'Blachownia',
      'Bieżuń',
      'Bierzwnik',
      'Bierutów',
      'Bieruń',
      'Bierdzany',
      'Bierawa',
      'Bieńkówka',
      'Bielsko-Biala',
      'Bielsk',
      'Bielice',
      'Bielawy',
      'Bielawa',
      'Bielany Wrocławskie',
      'Biały Bór',
      'Białośliwie',
      'Białogard',
      'Białe Błota',
      'Biała',
      'Białka',
      'Bestwinka',
      'Bestwina',
      'Bełsznica',
      'Bełk',
      'Bełchatów',
      'Będzin',
      'Bedlno',
      'Będków',
      'Bęczarka',
      'Bębło',
      'Bażanowice',
      'Barwice',
      'Barwałd Średni',
      'Barwałd Górny',
      'Baruchowo',
      'Barlinek',
      'Bardo',
      'Barcin',
      'Banino',
      'Banie',
      'Balin',
      'Balice',
      'Bąków',
      'Bachowice',
      'Baborów',
      'Babimost',
      'Babice',
      'Babiak',
      'Andrychów',
      'Andrespol',
      'Alwernia',
      'Aleksandrów Łódzki',
      'Aleksandrów Kujawski',
      'Ursynów',
      'Praga Północ',
      'Praga Południe',
      'Krościenko nad Dunajcem',
      'Grzybowo',
      'Rzgów',
      'Jelcz Laskowice',
      'Brody-Parcele',
      'Sztutowo',
      'Żarki-Letnisko',
      'Podebłocie',
      'Sulbiny Górne',
      'Ruda-Huta',
      'Iłowo -Osada',
      'Koszyce Wielkie',
      'Radwanice',
      'Rutki-Kossaki',
      'Bartniczka',
      'Lubicz Dolny',
      'Lubicz Górny',
      'Tomaszkowice',
      'Chotcza',
      'Mała Wieś',
      'Wiśniewo',
      'Izabelin',
      'Świercze',
      'Broniszewice',
      'Czarna Dąbrówka',
      'Skarbimierz Osiedle',
      'Bełżec',
      'Godziszów Pierwszy',
      'Rokiciny-Kolonia',
      'Nowe Ostrowy',
      'Kocierzew Południowy',
      'Zdziechowice Drugie',
      'Chróścina',
      'Mirków',
      'Rotmanka',
      'Nowe Grocholice',
      'Łęki Dukielskie',
      'Rejon ulicy Klęczkowskiej',
      'Jarnołtów-Jerzmanowo',
      'Widawa-Lipa Piotrowska-Polanowice',
      'Rejon alei Kromera',
      'Strachocin-Wojnów',
      'Leśnica-Ratyń-Pustki',
      'Osiedle Kosmonautów',
      'Sępolno',
      'Rejon ulicy Saperów',
      'Rejon ulicy Mieleckiej',
      'Rejon placu Grunwaldzkiego',
      'Maślice Małe',
      'Zacisze',
      'Grabiszyn',
      'Rejon ulicy Traugutta',
      'Grabiszynek',
      'Pilczyce',
      'Biskupin',
      'Pracze Odrzańskie-Janówek',
      'Marszowice',
      'Gądów Mały',
      'Kozanów',
      'Sołtysowice',
      'Stabłowice',
      'Zgorzelisko',
      'Zalesie i Stadion',
      'Maślice Wielkie',
      'Rejon placu Świętego Macieja',
      'Kowale-Popiele',
      'Swojczyce',
      'Pawłowice-Kłokoczyce',
      'Partynice',
      'Różanka-Polanka',
      'Osiedle Henrykowskie',
      'Powstańców Śląskich Zachód-Centrum Południow',
      'Psie Pole Południe-Kiełczów',
      'Krzyki',
      'Karłowice',
      'Księże Małe-Księże Wielkie',
      'Rejon ulicy Borowskiej-Południe',
      'Powstańców Śląskich Wschód',
      'Wojszyce',
      'Lipkowo',
      'Biała Róża',
    ],
  },
  {
    country: 'Saint Pierre and Miquelon',
    cities: ['Saint-Pierre', 'Miquelon'],
  },
  { country: 'Pitcairn Islands', cities: ['Adamstown'] },
  {
    country: 'Puerto Rico',
    cities: [
      'Aceitunas',
      'Adjuntas',
      'Aguada',
      'Aguadilla',
      'Aguas Buenas',
      'Aguas Claras',
      'Aguilita',
      'Aibonito',
      'Añasco',
      'Animas',
      'Antón Ruiz',
      'Arecibo',
      'Arroyo',
      'Bairoa',
      'Bajadero',
      'Bajandas',
      'Barahona',
      'Barceloneta',
      'Barranquitas',
      'Bartolo',
      'Bayamón',
      'Bayamon',
      'Benitez',
      'Boquerón',
      'Boqueron',
      'Brenas',
      'Buena Vista',
      'Bufalo',
      'Caban',
      'Cabo Rojo',
      'Cacao',
      'Caguas',
      'Campanilla',
      'Campo Rico',
      'Camuy',
      'Candelaria',
      'Candelaria Arenas',
      'Candelero Arriba',
      'Canovanas',
      'Capitanejo',
      'Carolina',
      'Carrizales',
      'Cataño',
      'Cayey',
      'Cayuco',
      'Ceiba',
      'Celada',
      'Central Aguirre',
      'Ciales',
      'Cidra',
      'Coamo',
      'Coco',
      'Comerío',
      'Comunas',
      'Coquí',
      'Corazón',
      'Corcovado',
      'Corozal',
      'Coto Laurel',
      'Coto Norte',
      'Culebra',
      'Daguao',
      'Dorado',
      'Duque',
      'El Mangó',
      'El Negro',
      'El Ojo',
      'Emajagua',
      'Esperanza',
      'Espino',
      'Estancias de Florida',
      'Fajardo',
      'Florida',
      'Franquez',
      'Fuig',
      'G. L. Garcia',
      'Galateo',
      'Garrochales',
      'Guánica',
      'Guayabal',
      'Guayama',
      'Guayanilla',
      'Guaynabo',
      'Gurabo',
      'H. Rivera Colon',
      'Hatillo',
      'Hato Candal',
      'Hormigueros',
      'Imbery',
      'Indios',
      'Ingenio',
      'Humacao',
      'Isabel Segunda',
      'Isabela',
      'Jagual',
      'Jauca',
      'Jayuya',
      'Jobos',
      'Juana Díaz',
      'Juncal',
      'Juncos',
      'La Alianza',
      'La Dolores',
      'La Fermina',
      'La Luisa',
      'La Parguera',
      'La Playa',
      'La Plena',
      'Lajas',
      'Lamboglia',
      'Lares',
      'Las Marias',
      'Las Marías',
      'Las Ochenta',
      'Las Ollas',
      'Las Piedras',
      'Levittown',
      'Liborio Negron Torres',
      'Lluveras',
      'Loíza',
      'Los Llanos',
      'Luis Llorens Torres',
      'Luis M. Cintron',
      'Luquillo',
      'Luyando',
      'Magas Arriba',
      'Manatí',
      'Maria Antonia',
      'Mariano Colón',
      'Maricao',
      'Martorell',
      'Maunabo',
      'Miranda',
      'Moca',
      'Monserrate',
      'Monte Grande',
      'Mora',
      'Morovis',
      'Mucarabones',
      'Mayagüez',
      'Naguabo',
      'Naranjito',
      'Olimpo',
      'Orocovis',
      'Pájaros',
      'Palmarejo',
      'Palmas',
      'Palmer',
      'Palo Seco',
      'Palomas',
      'Parcelas La Milagrosa',
      'Parcelas Nuevas',
      'Parcelas Peñuelas',
      'Pastos',
      'Patillas',
      'Peña Pobre',
      'Peñuelas',
      'Piedra Gorda',
      'Playa Fortuna',
      'Playita',
      'Playita Cortada',
      'Pole Ojea',
      'Ponce',
      'Potala Pastillo',
      'Pueblito del Rio',
      'Puerto Real',
      'Punta Santiago',
      'Quebrada',
      'Quebradillas',
      'Rafael Capo',
      'Rafael Gonzalez',
      'Rafael Hernandez',
      'Ramos',
      'Rincón',
      'Río Blanco',
      'Río Cañas Abajo',
      'Río Grande',
      'Río Lajas',
      'Rosa Sanchez',
      'Sabana',
      'Sabana Eneas',
      'Sabana Grande',
      'Sabana Hoyos',
      'Sabana Seca',
      'Salinas',
      'San Antonio',
      'San Germán',
      'San Isidro',
      'San José',
      'San Juan',
      'San Lorenzo',
      'San Sebastián',
      'Santa Barbara',
      'Santa Clara',
      'Santa Isabel',
      'Santo Domingo',
      'Stella',
      'Suárez',
      'Sumidero',
      'Tallaboa',
      'Tallaboa Alta',
      'Tierras Nuevas Poniente',
      'Toa Alta',
      'Toa Baja',
      'Trujillo Alto',
      'Utuado',
      'Vázquez',
      'Vega Alta',
      'Vega Baja',
      'Vieques',
      'Villalba',
      'Yabucoa',
      'Yauco',
      'Yaurel',
    ],
  },
  {
    country: 'Palestine',
    cities: [
      'Shūkat aş Şūfī',
      'Rafaḩ',
      'An Nuşayrāt',
      'Khuzā‘ah',
      'Khān Yūnis',
      'Jabālyā',
      'Gaza',
      'Dayr al Balaḩ',
      'Bayt Lāhyā',
      'Bayt Ḩānūn',
      'Banī Suhaylā',
      'Al Fukhkhārī',
      'Al Burayj',
      '‘Abasān al Kabīrah',
      'Zubūbah',
      'Zaytā',
      'Zaytā Jammā‘īn',
      'Zawātā',
      'Za‘tarah',
      'Alei Zahav',
      'Yiẕhar',
      'Yaţţā',
      'Yatmā',
      'Yāsūf',
      'Yāşīd',
      'Yaqqir',
      'Ya‘bad',
      'Wādī Fūkīn',
      '‘Ūrīf',
      'Ūdalah',
      'Turmus‘ayyā',
      'Ţūlkarm',
      'Ţūbās',
      'Tayāsīr',
      'Tarqūmyā',
      'Ţammūn',
      'Ţallūzah',
      'Till',
      'Talfīt',
      'Taffūḩ',
      'Suseya',
      'Surif City',
      'Surdā',
      'Sīrīs',
      'Sinjil',
      'Silwād',
      'Sīlat az̧ Z̧ahr',
      'Sīlat al Ḩārithīyah',
      'Sa‘īr',
      'Shuqbā',
      'Shūfah',
      'Şaydā',
      'Şarţah',
      'Şarrah',
      'Şānūr',
      'Sannīryā',
      'Sal‘it',
      'Sālim',
      'Salfīt',
      'Şaffā',
      'Sabasţīyah',
      'Rummanah',
      'Rūjayb',
      'Rās Karkar',
      'Rantīs',
      'Rammūn',
      'Rāmīn',
      'Ramallah',
      'Rāfāt',
      'Rābā',
      'Quşrah',
      'Qūşīn',
      'Qīrah',
      'Qibyah',
      'Qedumim',
      'Qedar',
      'Qaţanah',
      'Qaryūt',
      'Qarne Shomeron',
      'Qarāwat Banī Zayd',
      'Qarāwat Banī Ḩasan',
      'Qalqīlyah',
      'Qalandiyā',
      'Qaffīn',
      'Qabāţīyah',
      'Qabalān',
      'Pesagot',
      'Pedu’el',
      'Ofra',
      'Nūbā',
      'Ni‘līn',
      'Neve Daniel',
      'Nazlat ‘Īsá',
      'Naḩḩālīn',
      "Bat A'in",
      'Nablus',
      'Mikhmās',
      'Mislīyah',
      'Mirkah',
      'Mevo H̱oron',
      'Banī Zayd ash Shārqīyah',
      'Maythalūn',
      'Masḩah',
      'Mardā',
      'Majdal Banī Fāḑil',
      'Mādamā',
      'Ma‘ale Mikhmas',
      'Ma‘ale Efrayim',
      'Ma‘ale Adummim',
      'Al Lubban ash Sharqīyah',
      'Kufayrit',
      'Kokhav Ya‘aqov',
      'Kifil Ḩāris',
      'Ar Ramāḑīn',
      'Qalqas',
      'Imrīsh',
      'Marāḩ Rabbāḩ',
      'Dayr al ‘Asal al Fawqā',
      'Bayt Ta‘mar',
      'Bayt Ḩasan',
      'Bayt ‘Awwā',
      'Jannātah',
      'Ash Shaykh Sa‘d',
      'An Naşşārīyah',
      'Kharbathā al Mişbāḩ',
      'Al Majd',
      'Karmah',
      'Al Burj',
      'Shuyūkh al ‘Arrūb',
      'Khirbat Abū Falāḩ',
      'Kharbathā Banī Ḩārith',
      'Khārās',
      'Khallat al Mayyah',
      'Kefar ‘Eẕyon',
      'Kefar Adummim',
      'Kūbar',
      'Karme Ẕur',
      'Kafr Zībād',
      'Kafr Thulth',
      'Kafr Şūr',
      'Kafr Rā‘ī',
      'Kafr Qūd',
      'Kafr Qallīl',
      'Kafr Qaddūm',
      'Kafr Mālik',
      'Kafr Jammāl',
      'Kafr Dān',
      'Kafr ‘Ayn',
      'Kafr al Labad',
      'Kafr ad Dīk',
      'Kafr ‘Abbūsh',
      'Jūrīsh',
      'Jūrat ash Sham‘ah',
      'Al Judayyidah',
      'Jīt',
      'Jifnā',
      'Jayyūs',
      'Janīn',
      'Jammā‘īn',
      'Jalqamūs',
      'Jalbūn',
      'Al Jalamah',
      'Jaba‘',
      'Immātīn',
      '‘Immanu‘el',
      '‘Illār',
      'Iktābah',
      'Idhnā',
      'Ḩuwwārah',
      'Ḩūsān',
      'Ḩizmā',
      'H̱innanit',
      'Ḩāris',
      'Har Gillo',
      'H̱allamish',
      'Ḩalḩūl',
      'Ḩajjah',
      'Ḩablah',
      'Far‘ūn',
      'Farkhah',
      'Faqqū‘ah',
      'Faḩmah',
      '‘Ez Efrayim',
      'Elqana',
      'Efrata',
      'Dūrā al Qar‘',
      'Dūrā',
      'Dūmā',
      'Dhannābah',
      'Dayr Sharaf',
      'Dayr Qiddīs',
      'Dayr Jarīr',
      'Dayr Istiyā',
      'Dayr Ibzī‘',
      'Dayr Dibwān',
      'Dayr Ballūţ',
      'Dayr as Sūdān',
      'Dayr al Ḩaţab',
      'Dayr al Ghuşūn',
      'Dayr Abū Mash‘al',
      'Dayr Abū Ḑa‘īf',
      'Brūqīn',
      'Birqīn',
      'Burqah',
      'Būrīn',
      'Budrus',
      'Bīr Zayt',
      'Bīr Nabālā',
      'Bil‘īn',
      'Biddū',
      'Bidyā',
      'Bet Arye',
      'Bazzāryah',
      'Bayt Wazan',
      'Bayt ‘Ūr at Taḩtā',
      'Baytūnyā',
      'Bayt Imrīn',
      'Bayt Sūrīk',
      'Bayt Sīrā',
      'Bayt Sāḩūr',
      'Bayt Qād',
      'Bayt Liqyā',
      'Bayt Līd',
      'Bethlehem',
      'Bayt Kāḩil',
      'Bayt Jālā',
      'Bayt ‘Anān',
      'Baytīn',
      'Bayt Ūmmar',
      'Al Ittiḩād',
      'Bayt Iksā',
      'Bayt Ībā',
      'Bayt Fūrīk',
      'Bayt Fajjār',
      'Bayt Duqqū',
      'Bayt Dajan',
      'Bayt Ūlā',
      'Baytā al Fawqā',
      'Battir',
      'Battīr',
      'Barţa‘ah ash Sharqīyah',
      'Barqan',
      'Bardalah',
      'Bāqah ash Sharqīyah',
      'Bāqat al Ḩaţab',
      'Banī Na‘īm',
      'Balāţah',
      'Bal‘ā',
      '‘Azzūn',
      'Az Zāwiyah',
      'Az̧ Z̧āhirīyah',
      'Az Zabābidah',
      '‘Azmūţ',
      '‘Ayn Yabrūd',
      '‘Ayn ‘Arīk',
      '‘Aynabūs',
      'Ūşarīn',
      '‘Awartā',
      'Aţ Ţīrah',
      '‘Attīl',
      'Aţ Ţaybah',
      '‘Aţārah',
      '‘Aţārūt',
      'As Sāwiyah',
      'As Samū‘',
      '‘Aşīrah ash Shamālīyah',
      '‘Aşīrah al Qiblīyah',
      'Ash Shuyūkh',
      'Ash Shuhadā’',
      'Arţās',
      'Ar Rīḩīyah',
      '‘Arrānah',
      'Ar Rām wa Ḑāḩiyat al Barīd',
      '‘Arrābah',
      'Jericho',
      'Al ‘Araqa',
      '‘Aqrabā',
      '‘Anzah',
      'An Nuway‘imah',
      'An Nazlah ash Sharqīyah',
      'An Nāqūrah',
      'An Nabī Ilyās',
      '‘Anīn',
      '‘Anātā',
      '‘Anabtā',
      'Al Yāmūn',
      'Al Qubaybah',
      'Al Mughayyir',
      '‘Almon',
      'Al Midyah',
      'Al Mazra‘ah ash Sharqīyah',
      'Az Zaytūnīyah',
      'Al Lubban al Gharbī',
      'Al Khaḑir',
      'Hebron',
      'Al Judayrah',
      'Al Jīb',
      'Al Jāniyah',
      'Al Hāshimīyah',
      'Alfe Menashe',
      'Al Faşāyil',
      'Al Fandaqūmīyah',
      'Al Bīrah',
      'Al ‘Ayzarīyah',
      'Al ‘Awjā',
      'Al ‘Aţţārah',
      '‘Ajjūl',
      '‘Ajjah',
      '‘Ibwayn',
      'Abū Qashsh',
      'Abū Dīs',
      '‘Ābūd',
      'Old City',
      'An Naşr',
      '‘Abasān al Jadīdah',
      'Al Qarārah',
      'Umm an Naşr',
      'Juḩr ad Dīk',
      'Az Zuwāydah',
      'Al Muşaddar',
      'Wādī as Salqā',
      'Al Mughrāqah',
      'East Jerusalem',
      'Karney Shomron',
      'al-Kum',
      'Azun Atme',
      'Jinşāfūţ',
      'Banī Zayd',
      'Az Za‘ayyim',
      'Al ‘Ubaydīyah',
      'Khallat an Nu‘mān',
      'Khallat Ḩamāmah',
      'Ad Dawḩah',
      'Wādī Raḩḩāl',
      '‘Arab ar Rashāydah',
      'Bayt ‘Īnūn',
      'Bayt Maqdum',
      'Al Buq‘ah',
      'Khallat ad Dār',
      'Dayr Sāmit',
      'Al Ḩīlah',
      'Aş Şūrrah',
      'Bayt ‘Amrah',
      'Rābūd',
      'Al Karmil',
      'Khallat Şāliḩ',
      'Bīr al Bāshā',
      'Al Bādhān',
      '‘Ayn al Bayḑā',
      'Az Zubaydāt',
      'Al Jiftlik',
      'Khursā',
      'Givat Zeev',
      'Oranit',
      'Itamar',
      'Alon shvut',
      'Bruchin',
      'Geva Binyamin',
      'Otniel',
      'Beit El',
      "Ganei Modi'in",
    ],
  },
  {
    country: 'Portugal',
    cities: [
      'Zambujeira do Mar',
      'Vimeiro',
      'Vila Viçosa',
      'Vila Verde',
      'Vila Velha de Ródão',
      'Vila Real de Santo António',
      'Vila Nova de Milfontes',
      'Vila Nova da Barquinha',
      'Vila Franca de Xira',
      'Vila Flor',
      'Vila do Bispo',
      'Vila de Rei',
      'Vila Chã de Ourique',
      'Vila Baleira',
      'Vieira de Leiria',
      'Vidigueira',
      'Vidais',
      'Viana do Alentejo',
      'Vialonga',
      'Vestiaria',
      'Ventosa',
      'Vendas Novas',
      'Venda do Pinheiro',
      'Vale de Santarém',
      'Vale de Figueira',
      'Vale da Amoreira',
      'Valado de Frades',
      'Urra',
      'Unhos',
      'Turquel',
      'Tunes',
      'Tramagal',
      'Trafaria',
      'Torres Vedras',
      'Torres Novas',
      'Tomar',
      'Terrugem',
      'Tavira',
      'Souto da Carpalhosa',
      'Sobreda',
      'Sobralinho',
      'Sobral de Monte Agraço',
      'Sintra',
      'Sines',
      'Silves',
      'Silveira',
      'Sesimbra',
      'Setúbal',
      'Sertã',
      'Serra de El-Rei',
      'Serpa',
      'Senhora da Luz',
      'Seixal',
      'Sarilhos Pequenos',
      'Sardoal',
      'São Vicente do Paul',
      'São Vicente',
      'São Teotónio',
      'São Roque',
      'São Pedro da Cadeira',
      'São Miguel de Rio Torto',
      'São Martinho do Porto',
      'São Martinho',
      'São Marcos da Serra',
      'São Luis',
      'São Julião',
      'São Jorge',
      'São João dos Montes',
      'São João da Talha',
      'São João das Lampas',
      'São Domingos de Rana',
      'São Brás de Alportel',
      'São Bartolomeu de Messines',
      'São Bartolomeu',
      'Santo António da Charneca',
      'Santo Antão do Tojal',
      'Santo André',
      'Santiago do Cacém',
      'Santarém',
      'Santana',
      'Santa Margarida da Coutada',
      'Santa Luzia',
      'Santa Iria da Azóia',
      'Santa Cruz',
      'Santa Catarina da Serra',
      'Santa Bárbara de Nexe',
      'Samouco',
      'Samora Correia',
      'Salvaterra de Magos',
      'Salvada',
      'Salir de Matos',
      'Sagres',
      'Sacavém',
      'Rosairinho',
      'Rio Maior',
      'Rio de Mouro',
      'Ribeira Brava',
      'Riachos',
      'Reguengos de Monsaraz',
      'Reguengo',
      'Redondo',
      'Ramada',
      'Quinta do Anjo',
      'Queluz',
      'Queijas',
      'Quarteira',
      'Proença-a-Nova',
      'Praia do Ribatejo',
      'Pragal',
      'Póvoa e Meadas',
      'Póvoa de Santo Adrião',
      'Póvoa de Santa Iria',
      'Porto Salvo',
      'Porto Moniz',
      'Porto de Mós',
      'Porto da Cruz',
      'Porto Covo',
      'Portimão',
      'Portel',
      'Portalegre',
      'Porches',
      'Pontinha',
      'Pontével',
      'Ponte de Sôr',
      'Ponta do Sol',
      'Pombal',
      'Poceirão',
      'Pinheiro Grande',
      'Pinhal Novo',
      'Piedade',
      'Pero Pinheiro',
      'Pêra',
      'Peniche',
      'Penedo Gordo',
      'Pego',
      'Pedrógão Grande',
      'Pataias',
      'Parede',
      'Parchal',
      'Palmela',
      'Paialvo',
      'Paderne',
      'Paço de Arcos',
      'Ourique',
      'Ourém',
      'Ota',
      'Olival Basto',
      'Olivais',
      'Olhos de Água',
      'Olho Marinho',
      'Olhão',
      'Oleiros',
      'Oeiras',
      'Odivelas',
      'Odemira',
      'Óbidos',
      'Nossa Senhora do Monte',
      'Nisa',
      'Neves',
      'Nazaré',
      'Nadadouro',
      'Mourão',
      'Moura',
      'Moscavide e Portela',
      'Mora',
      'Montijo',
      'Monte Redondo',
      'Monte Real',
      'Montemor-o-Novo',
      'Monte Gordo',
      'Monte Estoril',
      'Montargil',
      'Montalvo',
      'Montalvinho',
      'Monsanto',
      'Monforte',
      'Monchique',
      'Moncarapacho',
      'Moita dos Ferreiros',
      'Moita',
      'Mira',
      'Minde',
      'Minas de São Domingos',
      'Milharado',
      'Milagres',
      'Mexilhoeira Grande',
      'Mértola',
      'Mem Martins',
      'Meia Via',
      'Meca',
      'Massamá',
      'Marvão',
      'Marmelete',
      'Marinhais',
      'Marinha Grande',
      'Manta Rota',
      'Malveira',
      'Mafra',
      'Madalena',
      'Machico',
      'Maceirinha',
      'Maceira',
      'Mação',
      'Luz',
      'Lourinhã',
      'Louriçal',
      'Loures',
      'Loureira',
      'Loulé',
      'Lisbon',
      'Linda-a-Velha',
      'Leiria',
      'Lavradio',
      'Laranjeiro',
      'Lagos',
      'Lagoa',
      'Juncal',
      'Idanha-a-Nova',
      'Guia',
      'Grândola',
      'Golegã',
      'Gavião',
      'Fuzeta',
      'Funchal',
      'Fronteira',
      'Foz do Arelho',
      'Fortios',
      'Fontanelas',
      'Figueiró dos Vinhos',
      'Ferrel',
      'Ferreiras',
      'Ferreira do Zêzere',
      'Ferreira do Alentejo',
      'Ferragudo',
      'Fazendas de Almeirim',
      'Fátima',
      'Faro',
      'Famões',
      'Famalicão',
      'Fajã da Ovelha',
      'Faial',
      'Évora',
      'Estremoz',
      'Estreito da Calheta',
      'Estoril',
      'Estômbar',
      'Estói',
      'Ericeira',
      'Entroncamento',
      'Elvas',
      'Curral das Freiras',
      'Cuba',
      'Costa de Caparica',
      'Coruche',
      'Corroios',
      'Constância',
      'Conceição',
      'Comporta',
      'Colares',
      'Charneca',
      'Charneca de Caparica',
      'Cercal',
      'Cela',
      'Caxias',
      'Castro Verde',
      'Castro Marim',
      'Castelo de Vide',
      'Castelo Branco',
      'Castanheira do Ribatejo',
      'Castanheira de Pêra',
      'Cascais',
      'Carvoeiro',
      'Cartaxo',
      'Carreira',
      'Carregado',
      'Carnaxide',
      'Carcavelos',
      'Caranguejeira',
      'Caparica',
      'Caniço',
      'Caniçal',
      'Canhas',
      'Caneças',
      'Campo Maior',
      'Campelos',
      'Campanário',
      'Camarate',
      'Câmara de Lobos',
      'Camacha',
      'Calheta',
      'Caldas da Rainha',
      'Cadaval',
      'Cadafais',
      'Cacilhas',
      'Cacém',
      'Cabeça Gorda',
      'Cabanas de Torres',
      'Bucelas',
      'Borba',
      'Bombarral',
      'Boliqueime',
      'Bobadela',
      'Boavista dos Pinheiros',
      'Boa Vista',
      'Boaventura',
      'Beringel',
      'Bensafrim',
      'Benedita',
      'Benavente',
      'Bemfica',
      'Benfica',
      'Belas',
      'Beja',
      'Beato António',
      'Batalha',
      'Barreiro',
      'Barrancos',
      'Barcarena',
      'Azinhaga',
      'Azambuja',
      'Avis',
      'Avelar',
      'Aveiras de Cima',
      'Atouguia da Baleia',
      'Atalaia',
      'Arruda dos Vinhos',
      'Arronches',
      'Arrentela',
      'Arranhó',
      'Arraiolos',
      'Armação de Pêra',
      'Arco da Calheta',
      'Apelação',
      'Ansião',
      'Amora',
      'Amor',
      'Amiães de Baixo',
      'Amadora',
      'Alvorninha',
      'Alvor',
      'Alvito',
      'Alvaiázere',
      'Altura',
      'Alter do Chão',
      'Alqueidão da Serra',
      'Alpiarça',
      'Almograve',
      'Almodôvar',
      'Almeirim',
      'Almargem',
      'Almancil',
      'Almada',
      'Aljustrel',
      'Aljubarrota',
      'Aljezur',
      'Alhos Vedros',
      'Alhandra',
      'Algueirão',
      'Algoz',
      'Algés',
      'Alferrarede',
      'Alfeizerão',
      'Alenquer',
      'Alegrete',
      'Aldeia Nova',
      'Aldeia de Paio Pires',
      'Alcoutim',
      'Alcoentre',
      'Alcochete',
      'Alcobaça',
      'Alcantarilha',
      'Alcanhões',
      'Alcanena',
      'Alcanede',
      'Alcains',
      'Alcácer do Sal',
      'Alcabideche',
      'Albufeira',
      'Alandroal',
      'Alagoa',
      'Água de Pena',
      'A dos Francos',
      'A dos Cunhados',
      'Abrigada',
      'Abrantes',
      'Vouzela',
      'Vizela',
      'Viseu',
      'Vinhais',
      'Vimioso',
      'Vilela',
      'Vila Seca',
      'Vilarinho',
      'Vila Real',
      'Vilar do Paraíso',
      'Vilar de Figos',
      'Vilar de Andorinho',
      'Vila Praia de Âncora',
      'Vila Pouca de Aguiar',
      'Vila Nova de Paiva',
      'Vila Nova de Gaia',
      'Vila Nova de Foz Côa',
      'Vila Nova de Famalicão',
      'Vila Nova da Telha',
      'Vila Nova de Cerveira',
      'Vila Meã',
      'Vila Frescainha',
      'Vila do Conde',
      'Vila Cova',
      'Vila Chã',
      'Vilaça',
      'Vieira do Minho',
      'Viana do Castelo',
      'Várzea',
      'Valpaços',
      'Valongo',
      'Valença',
      'Válega',
      'Vale de Cambra',
      'Valbom',
      'Valadares',
      'Vairão',
      'Vagos',
      'Urgeses',
      'Trofa',
      'Treixedo',
      'Travanca',
      'Trancoso',
      'Torre de Moncorvo',
      'Tondela',
      'Tocha',
      'Terras de Bouro',
      'Telões',
      'Teixoso',
      'Tebosa',
      'Tavarede',
      'Tarouca',
      'Tabuaço',
      'Tábua',
      'Souto',
      'Soure',
      'Sobreira',
      'Sobrado',
      'Cinfães',
      'Silvalde',
      'Sever do Vouga',
      'Serzedo',
      'Serzedelo',
      'Seroa',
      'Sernancelhe',
      'Sermonde',
      'Sequeira',
      'Senhora da Hora',
      'Sendim',
      'Semelhe',
      'Selho',
      'Seia',
      'Sátão',
      'Coronado',
      'São Romão',
      'São Pedro do Sul',
      'São Pedro de Alva',
      'São Pedro da Cova',
      'São Miguel do Couto',
      'São Mamede de Infesta',
      'São João de Ver',
      'São João de Areias',
      'São João da Pesqueira',
      'São João da Madeira',
      'São João',
      'São Félix da Marinha',
      'Santo Tirso',
      'Santo Izidoro',
      'Santo António dos Olivais',
      'Santa Marta de Penaguião',
      'Santa Cruz do Bispo',
      'Santa Comba Dão',
      'Santa Clara',
      'Sangalhos',
      'Sanfins',
      'Sandim',
      'Sande',
      'Samil',
      'Salreu',
      'Sabugal',
      'Sabrosa',
      'Ronfe',
      'Rio Tinto',
      'Rio Meão',
      'Rio Mau',
      'Rio de Loba',
      'Ribeirão',
      'Ribeira de Pena',
      'Riba de Ave',
      'Resende',
      'Rendufe',
      'Refojos de Basto',
      'Recarei',
      'Rebordosa',
      'Rebordões',
      'Real',
      'Prado',
      'Póvoa de Varzim',
      'Póvoa de Lanhoso',
      'Porto',
      'Ponte de Lima',
      'Ponte da Barca',
      'Ponte',
      'Poiares',
      'Pinhel',
      'Peso da Régua',
      'Perozinho',
      'Perelhal',
      'Perafita',
      'Penela',
      'Penedono',
      'Penamacor',
      'Penafiel',
      'Penacova',
      'Pedrouços',
      'Pedroso',
      'Paredes de Coura',
      'Paredes',
      'Pardilhó',
      'Pampilhosa',
      'Pampilhosa da Serra',
      'Paços de Ferreira',
      'Paços de Brandão',
      'Ovar',
      'Oliveirinha',
      'Oliveira do Mondego',
      'Oliveira do Hospital',
      'Oliveira do Douro',
      'Oliveira do Bairro',
      'Oliveira de Frades',
      'Oliveira de Azemeis',
      'Oliveira',
      'Olival',
      'Oiã',
      'Nogueira da Regedoura',
      'Nogueira',
      'Nelas',
      'Negrelos',
      'Negreiros',
      'Murtosa',
      'Murça',
      'Mozelos',
      'Mosteirô',
      'Mortágua',
      'Moreira de Conegos',
      'Moreira',
      'Montemor-o-Velho',
      'Montalegre',
      'Mondim de Basto',
      'Monção',
      'Moimenta da Beira',
      'Mogadouro',
      'Mirandela',
      'Miranda do Douro',
      'Miranda do Corvo',
      'Mindelo',
      'Milheirós de Poiares',
      'Milheirós',
      'Mesão Frio',
      'Merelim',
      'Melres',
      'Meinedo',
      'Mêda',
      'Mealhada',
      'Meadela',
      'Matosinhos',
      'Mariz',
      'Marinhas',
      'Margaride',
      'Marco de Canavezes',
      'Manteigas',
      'Mangualde',
      'Maia',
      'Macieira de Rates',
      'Macieira de Cambra',
      'Macedo de Cavaleiros',
      'Lustosa',
      'Luso',
      'Lousada',
      'Lousã',
      'Lourosa',
      'Lorvão',
      'Loriga',
      'Lordelo',
      'Lobão',
      'Leça do Bailio',
      'Leça da Palmeira',
      'Lavra',
      'Lavos',
      'Lamego',
      'Lamas',
      'Lago',
      'Jovim',
      'Joane',
      'Ílhavo',
      'Gulpilhares',
      'Guisande',
      'Guimarães',
      'Guifões',
      'Gueral',
      'Guarda',
      'Grijó',
      'Gondomar',
      'Gondizalves',
      'Góis',
      'Godim',
      'Gemunde',
      'Gandra',
      'Galegos',
      'Gafanha',
      'Gafanha da Encarnação',
      'Fundão',
      'Frossos',
      'Freixo de Espada à Cinta',
      'Freamunde',
      'Frazão',
      'Fradelos',
      'Foz do Sousa',
      'Foz do Douro',
      'Fornos de Algodres',
      'Figueiró',
      'Figueira de Castelo Rodrigo',
      'Figueira da Foz',
      'Fiães',
      'Ferreiros',
      'Ferreira',
      'Felgueiras',
      'Feira',
      'Favaios',
      'Faria',
      'Apúlia e Fão',
      'Fânzeres',
      'Fafe',
      'Estela',
      'Estarreja',
      'Esposende',
      'Esporões',
      'Espinho',
      'Espargo',
      'Esmoriz',
      'Esgueira',
      'Ervedosa do Douro',
      'Ermesinde',
      'Eixo',
      'Darque',
      'Custoias',
      'Cucujães',
      'Cristelo',
      'Covilhã',
      'Cortegaça',
      'Condeixa-a-Nova',
      'Coimbra',
      'Chaves',
      'Chavão',
      'Celorico de Basto',
      'Celorico da Beira',
      'Castro Daire',
      'Castelões de Cepeda',
      'Castelo de Paiva',
      'Carvalhosa',
      'Carregal do Sal',
      'Carrazeda de Anciães',
      'Carapinheira',
      'Cantanhede',
      'Canidelo',
      'Canelas',
      'Candoso',
      'Canas de Senhorim',
      'Campo de Besteiros',
      'Campo',
      'Calendário',
      'Caldelas',
      'Caldas de Vizela',
      'Taipas',
      'Cabeceiras de Basto',
      'Cabanas de Viriato',
      'Buarcos',
      'Brito',
      'Branca',
      'Bragança',
      'Braga',
      'Bougado',
      'Boticas',
      'Belver',
      'Belmonte',
      'Beiriz de Baixo',
      'Beduido',
      'Barrosas',
      'Barqueiros',
      'Barcelos',
      'Baltar',
      'Balazar',
      'Baião',
      'Baguim do Monte',
      'Azenha',
      'Avintes',
      'Aves',
      'Aver-o-Mar',
      'Aveleda',
      'Aveiro',
      'Avanca',
      'Assafarge',
      'Árvore',
      'Arrifana',
      'Arouca',
      'Arões',
      'Armamar',
      'Argoncilhe',
      'Argivai',
      'Arganil',
      'Areosa',
      'Arcozelo',
      'Arcos de Valdevez',
      'Arcos',
      'Arazede',
      'Aradas',
      'Apúlia',
      'Anta',
      'Ançã',
      'Anadia',
      'Amorim',
      'Amares',
      'Amarante',
      'Alpendurada',
      'Alijó',
      'Alhadas',
      'Alfena',
      'Alfarelos',
      'Alfândega da Fé',
      'Aldeia de Joanes',
      'Albergaria-a-Velha',
      'Aguiar da Beira',
      'Águeda',
      'Aguçadoura',
      'Águas Santas',
      'Água Longa',
      'Aguada de Cima',
      'Adaúfe',
      'Abraveses',
      'Vila Franca do Campo',
      'Velas',
      'Senhora do Rosário',
      'São Sebastião',
      'São Roque do Pico',
      'São Mateus',
      'Santa Cruz das Flores',
      'Santa Cruz da Graciosa',
      'Santa Bárbara',
      'Rosto de Cão',
      'Ribeirinha',
      'Ribeira Seca',
      'Ribeira Grande',
      'Relva',
      'Rabo de Peixe',
      'Praia da Vitória',
      'Povoação',
      'Porto Martins',
      'Porto Judeu',
      'Ponta Garça',
      'Mosteiros',
      'Lajes',
      'Lajes das Flores',
      'Horta',
      'Furnas',
      'Fonte Bastardo',
      'Feteira',
      'Fenais da Luz',
      'Fenais da Ajuda',
      'Fajã de Baixo',
      'Cais do Pico',
      'Cabouco',
      'Biscoitos',
      'Arrifes',
      'Angústias',
      'Angra do Heroísmo',
      'Água de Pau',
      'Vilamoura',
      'Vila Nova De Cacela',
      'Quinta Do Conde',
      'Prior Velho',
      'Valenza',
      'Alfragide',
      'Alvalade',
      'Ponta Delgada',
      'Santos-o-Velho',
      'Vila',
      'Cabanas de Tavira',
      'Santo Isidoro',
      'Azeitão',
    ],
  },
  {
    country: 'Palau',
    cities: [
      'Koror',
      'Ulimang',
      'Melekeok Village',
      'Ngereklmadel',
      'Imeong Hamlet',
      'Ngchesar Hamlet',
      'Ngerkeai',
      'Mengellang',
      'Angaur State',
      'Tobi Village',
      'Sonsorol Village',
      'Kayangel',
      'Kloulklubed',
      'Ngetkib',
      'Ngchemiangel',
      'Ngardmau',
      'Koror Town',
      'Ngerulmud',
    ],
  },
  {
    country: 'Paraguay',
    cities: [
      'Yuty',
      'Ypacarai',
      'Yhú',
      'Villa Ygatimí',
      'Fulgencio Yegros',
      'Ybycuí',
      'Yataity del Norte',
      'Yaguarón',
      'Villarrica',
      'Villa Hayes',
      'Villa Florida',
      'Villa Elisa',
      'Unión',
      'Trinidad',
      'Tobatí',
      'Tacuatí',
      'Sapucaí',
      'Santiago',
      'Santa Rosa',
      'Santa Rita',
      'Santa María',
      'Santa Elena',
      'San Pedro de Ycuamandiyú',
      'San Pedro del Paraná',
      'San Patricio',
      'San Miguel',
      'San Lorenzo',
      'San Juan Nepomuceno',
      'San Juan del Paraná',
      'San Juan Bautista',
      'San José de los Arroyos',
      'San Joaquín',
      'San Isidro de Curuguaty',
      'San Cosme y Damián',
      'San Bernardino',
      'San Antonio',
      'San Alberto',
      'Salto del Guairá',
      'Repatriación',
      'Quiindy',
      'Puerto Rosario',
      'Puerto Mayor Otaño',
      'Presidente Franco',
      'Piribebuy',
      'Pirayú',
      'Pirapó',
      'Pilar',
      'Pedro Juan Caballero',
      'Paraguarí',
      'Obligado',
      'Nueva Italia',
      'Colonia Nueva Germania',
      'Nemby',
      'Natalio',
      'Natalicio Talavera',
      'Naranjal',
      'Nanawa',
      'Mbuyapey',
      'Mbutuý',
      'Mbocayaty',
      'Los Cedrales',
      'Limpio',
      'Lima',
      'La Paloma del Espíritu Santo',
      'Lambaré',
      'La Colmena',
      'Jesús',
      'Iturbe',
      'Itauguá',
      'Itakyry',
      'Itapé',
      'Itacurubí del Rosario',
      'Itacurubí de la Cordillera',
      'Itá',
      'Isla Pucú',
      'Independencia',
      'Hohenau',
      'Guajayvi',
      'Guarambaré',
      'General José Eduvigis Díaz',
      'General Higinio Morínigo',
      'General Elizardo Aquino',
      'General José María Delgado',
      'General Artigas',
      'Fuerte Olimpo',
      'Fram',
      'Fernando de la Mora',
      'Eusebio Ayala',
      'Encarnación',
      'Emboscada',
      'Edelira',
      'Doctor Juan Manuel Frutos',
      'Doctor Juan León Mallorquín',
      'Doctor Cecilio Báez',
      'Corpus Christi',
      'Coronel Oviedo',
      'Coronel Martínez',
      'Concepción',
      'Yguazú',
      'Minga Porá',
      'Colonia Menno',
      'Colonia Mauricio José Troche',
      'Mariano Roque Alonso',
      'Colonia General Alfredo Stroessner',
      'Katueté',
      'Ciudad del Este',
      'Chore',
      'Cerrito',
      'Carmen del Paraná',
      'Carlos Antonio López',
      'Carayaó',
      'Carapeguá',
      'Caraguatay',
      'Capitán Miranda',
      'Capitán Bado',
      'Capiibary',
      'Capiatá',
      'Caazapá',
      'Caapucú',
      'Caaguazú',
      'Caacupé',
      'Buena Vista',
      'Benjamín Aceval',
      'Bella Vista',
      'Belén',
      'Ayolas',
      'Atyrá',
      'Asunción',
      'Arroyos y Esteros',
      'Tomás Romero Pereira',
      'Areguá',
      'Antequera',
      'Altos',
      'Acahay',
      'Abaí',
      'Horqueta',
      'Filadelfia',
      'Colonia Neuland',
      'Capitán Pablo Lagerenza',
      'Nueva Esperanza',
      'Colonia San Lorenzo',
    ],
  },
  {
    country: 'Qatar',
    cities: [
      'Umm Şalāl Muḩammad',
      'Umm Şalāl ‘Alī',
      'Musay‘īd',
      'Umm Bāb',
      'Madīnat ash Shamāl',
      'Dukhān',
      'Az̧ Z̧a‘āyin',
      'Ash Shīḩānīyah',
      'Ar Ruways',
      'Ar Rayyān',
      'Al Wukayr',
      'Al Wakrah',
      'Al Khawr',
      'Al Jumaylīyah',
      'Al Ghuwayrīyah',
      'Doha',
      'Fuwayriţ',
    ],
  },
  {
    country: 'Réunion',
    cities: [
      'Les Trois-Bassins',
      'Salazie',
      'Saint-Pierre',
      'Saint-Philippe',
      'Saint-Paul',
      'Saint-Louis',
      'Saint-Leu',
      'Saint-Joseph',
      'Sainte-Suzanne',
      'Sainte-Rose',
      'Sainte-Marie',
      'Saint-Denis',
      'Saint-Benoît',
      'Saint-André',
      'Petite-Île',
      'Le Tampon',
      'Les Avirons',
      'Le Port',
      'La Possession',
      'Entre-Deux',
      'Cilaos',
      'Bras-Panon',
      'Piton Saint-Leu',
    ],
  },
  {
    country: 'Romania',
    cities: [
      'Ghioca',
      'Zvoriştea',
      'Zorlenţu Mare',
      'Zorleni',
      'Zorile',
      'Zorești',
      'Zmeu',
      'Zlătunoaia',
      'Zlatna',
      'Zlătărei',
      'Zizin',
      'Zimnicele',
      'Zimnicea',
      'Zâmbreasca',
      'Zimbor',
      'Zimandu Nou',
      'Zidurile',
      'Ziduri',
      'Zetea',
      'Zerind',
      'Zencani',
      'Zemeş',
      'Zebil',
      'Zdrapți',
      'Zăvoiu',
      'Zăvoi',
      'Zăvoaia',
      'Zăval',
      'Zau de Câmpie',
      'Zăuan',
      'Zătreni',
      'Zărneștii de Slănic',
      'Zărnești',
      'Zărneşti',
      'Zărand',
      'Zăpodeni',
      'Zănoaga',
      'Zăneşti',
      'Zamostea',
      'Zam',
      'Zalha',
      'Zalău',
      'Zagra',
      'Zagon',
      'Zagavia',
      'Zagăr',
      'Zădăreni',
      'Zăbrani',
      'Zăbala',
      'Vutcani',
      'Vurpăr',
      'Vulturu',
      'Vultureşti',
      'Vulturești',
      'Vultureni',
      'Vulpeni',
      'Vulcăneasa',
      'Vulcana-Pandele',
      'Vulcana de Sus',
      'Vulcana Băi',
      'Vulcan',
      'Vrâncioaia',
      'Vrata',
      'Vrănești',
      'Voșlăbeni',
      'Vorţa',
      'Vorona Teodoru',
      'Vorona',
      'Vorniceni',
      'Voluntari',
      'Volovăţ',
      'Voloiac',
      'Voivozi',
      'Voivodeni',
      'Voievodeasa',
      'Voitinel',
      'Voiteștii din Vale',
      'Voiteg',
      'Voineşti',
      'Voinești',
      'Voineasa',
      'Voila',
      'Voetin',
      'Voiceşti',
      'Vlăsineşti',
      'Vlăsceni',
      'Vlașca',
      'Vlăhiţa',
      'Vlăduleni',
      'Vlad Ţepeş',
      'Vlad Țepeș',
      'Vladimirescu',
      'Vlădila',
      'Vlădești',
      'Vlădeşti',
      'Vlădeni-Deal',
      'Vlădeni',
      'Vlădaia',
      'Vizurești',
      'Viziru',
      'Vizantea-Mânăstirească',
      'Vitomireşti',
      'Vitănești',
      'Vităneşti',
      'Viștea de Jos',
      'Vişineşti',
      'Vișina Nouă',
      'Vişina',
      'Vişeu de Sus',
      'Vișeu de Mijloc',
      'Vişeu de Jos',
      'Viscri',
      'Vişani',
      'Vârvoru de Jos',
      'Vârtop',
      'Vârtoapele de Sus',
      'Vârtoapele de Jos',
      'Vârteşcoiu',
      'Vârteju',
      'Vârşolţ',
      'Vârlezi',
      'Vârghiş',
      'Vârfurile',
      'Vârfuri',
      'Vârfu Câmpului',
      'Vârciorova',
      'Vârciorog',
      'Vipereşti',
      'Vintileasca',
      'Vintileanca',
      'Vintilă Vodă',
      'Vintere',
      'Vânju-Mare',
      'Vânjuleţ',
      'Vinga',
      'Vinerea',
      'Vinderei',
      'Vânători-Neamţ',
      'Vânătorii Mici',
      'Vânătorii Mari',
      'Vânători',
      'Vima Mică',
      'Vâlcelele de Sus',
      'Vâlcelele',
      'Vâlcele',
      'Vâlcăneşti',
      'Viișoara',
      'Viişoara',
      'Viile Satu Mare',
      'Viile',
      'Viforâta',
      'Vieru',
      'Vierșani',
      'Vidra',
      'Videle',
      'Victor Vlad Delamarina',
      'Victoria',
      'Vicovu de Sus',
      'Vicovu de Jos',
      'Veza',
      'Vetiş',
      'Veţel',
      'Veţca',
      'Veștem',
      'Veseuș',
      'Verșești',
      'Verșeni',
      'Verneşti',
      'Vermești',
      'Vermeş',
      'Verguleasa',
      'Vereşti',
      'Verendin',
      'Verbiţa',
      'Vela',
      'Vedea',
      'Vatra Moldoviţei',
      'Vatra Dornei',
      'Vătava',
      'Vaţa de Jos',
      'Vaslui',
      'Umbrărești-Deal',
      'Vasilaţi',
      'Vaşcău',
      'Vășad',
      'Vărşag',
      'Varnița',
      'Variaş',
      'Vărgata',
      'Vărbilău',
      'Vărbila',
      'Văratec',
      'Vărăşti',
      'Vărăști',
      'Vărădia de Mureş',
      'Vărădia',
      'Vama Buzăului',
      'Vama',
      'Valu lui Traian',
      'Văliug',
      'Vălişoara',
      'Văleni-Podgoria',
      'Vălenii Șomcutei',
      'Vălenii de Mureș',
      'Vălenii de Munte',
      'Văleni-Dâmbovița',
      'Văleni',
      'Valea Vișeului',
      'Valea Vinului',
      'Valea Viilor',
      'Valea Ursului',
      'Valea Târsei',
      'Valea Teilor',
      'Valea Strâmbă',
      'Valea Stanciului',
      'Valea Șoșii',
      'Valea Seacă',
      'Ştefan Cel Mare',
      'Valea Sării',
      'Valea Salciei',
      'Valea Roșie',
      'Valea Râmnicului',
      'Valea Rece',
      'Valea Popii',
      'Valea Părului',
      'Valea Nucarilor',
      'Valea Moldovei',
      'Valea Merilor',
      'Valea Mărului',
      'Valea Mare Pravăț',
      'Valea Mare-Podgoria',
      'Valea Mare',
      'Valea Măcrișului',
      'Valea Lupului',
      'Valea Lungă Ogrea',
      'Valea Lungă-Cricov',
      'Valea Lungă Alba Romania',
      'Valea lui Mihai',
      'Valea lui Ion',
      'Valea Leurzii',
      'Valea Largă',
      'Valea Izvoarelor',
      'Valea Ierii',
      'Valea Iaşului',
      'Valea Grecului',
      'Valea Dulce',
      'Valea Dragului',
      'Valea Danului',
      'Valea Dacilor',
      'Valea Cucului',
      'Valea Crişului',
      'Valea Cireșului',
      'Valea Ciorii',
      'Valea Cânepii',
      'Valea Chioarului',
      'Valea Caselor',
      'Valea Călugărească',
      'Valea Borcutului',
      'Valea Bolvașnița',
      'Valea Arinilor',
      'Valea Arini',
      'Valea Argovei',
      'Vale',
      'Valcău de Jos',
      'Vălcani',
      'Vaideeni',
      'Vaida-Cămăraș',
      'Văgiuleşti',
      'Vadu Sorești',
      'Vadu Săpat',
      'Vadu Roșca',
      'Vădurele',
      'Vadu Paşii',
      'Vadu Părului',
      'Vadu Moţilor',
      'Vadu Moldovei',
      'Vadu Izei',
      'Vadu Crişului',
      'Vădeni',
      'Vădăstriţa',
      'Vădastra',
      'Vad',
      'Văculeşti',
      'Văcăreşti',
      'Văcăreni',
      'Uzunu',
      'Utvin',
      'Ususău',
      'Urzicuţa',
      'Urziceni',
      'Urziceanca',
      'Urși',
      'Urseni',
      'Ursa',
      'Urmeniş',
      'Urleta',
      'Urleasca',
      'Urlaţi',
      'Uriu',
      'Uricani',
      'Urecheşti',
      'Urecheni',
      'Urdari',
      'Urca',
      'Unţeni',
      'Unirea',
      'General Berthelot',
      'Unguriu',
      'Arini',
      'Ungureni-Jianu',
      'Ungureni',
      'Unguraş',
      'Ungra',
      'Ungheni',
      'Umbrărești',
      'Umbrăreşti',
      'Ulmu',
      'Ulmi',
      'Ulmeni',
      'Ulma',
      'Ulieşti',
      'Uivar',
      'Uioara de Jos',
      'Udupu',
      'Udeşti',
      'Uda-Paciurea',
      'Uda-Clocociov',
      'Uda',
      'Ucea de Sus',
      'Ucea de Jos',
      'Tuzla',
      'Tutova',
      'Ţuţora',
      'Tutana',
      'Tuta',
      'Tușnadu Nou',
      'Tuşnad',
      'Turulung',
      'Turţ',
      'Turnu Ruieni',
      'Turnu Roşu',
      'Turnu Măgurele',
      'Turnu',
      'Turluianu',
      'Turia',
      'Tureni',
      'Tureac',
      'Turdaş',
      'Turda',
      'Turcoaia',
      'Turcineşti',
      'Turcești',
      'Turceni',
      'Turburea',
      'Tur',
      'Tupilaţi',
      'Tunelu-Teliu',
      'Tunari',
      'Tuluceşti',
      'Tulnici',
      'Tulgheş',
      'Tulcea',
      'Tulca',
      'Ţuglui',
      'Tufeşti',
      'Tufeni',
      'Tudor Vladimirescu',
      'Suceveni',
      'Tudora',
      'Truşeşti',
      'Troianul',
      'Trivalea-Moşteni',
      'Tritenii de Sus',
      'Tritenii de Jos',
      'Trip',
      'Trifeşti',
      'Treznea',
      'Trestieni',
      'Trei Sate',
      'Trăisteni',
      'Traian Vuia',
      'Traian',
      'Totoești',
      'Toteşti',
      'Tortoman',
      'Tormac',
      'Topraisar',
      'Toporu',
      'Topoloveni',
      'Topolovăţu Mare',
      'Topolog',
      'Topolița',
      'Topliţa',
      'Toplița',
      'Topliceni',
      'Topleţ',
      'Topile',
      'Topana',
      'Topalu',
      'Tomşani',
      'Tomnatic',
      'Tomeşti',
      'Tomești',
      'Țolici',
      'Tohanu Nou',
      'Toflea',
      'Todireşti',
      'Todireni',
      'Tocileni',
      'Toboliu',
      'Tiur',
      'Titu',
      'Titești',
      'Titeşti',
      'Tismana',
      'Tisa',
      'Târşolţel',
      'Târşolţ',
      'Târnova',
      'Târnăveni',
      'Târnava',
      'Târlişua',
      'Târgu Trotuş',
      'Târguşor',
      'Târgu Secuiesc',
      'Târgu Ocna',
      'Târgu Neamţ',
      'Târgu-Mureş',
      'Târgu Lăpuş',
      'Târgu Jiu',
      'Târgu Frumos',
      'Târgu Cărbuneşti',
      'Târgşoru Vechi',
      'Târgovişte',
      'Tiream',
      'Țipar',
      'Ţinteşti',
      'Țintea',
      'Tântava',
      'Ţânţăreni',
      'Tinosu',
      'Tânganu',
      'Tinca',
      'Tâmna',
      'Timişoara',
      'Timişeşti',
      'Tâmboeşti',
      'Tilişca',
      'Tileagd',
      'Tihău',
      'Tiha Bârgăului',
      'Tigveni',
      'Ţigăneşti',
      'Ţigănaşi',
      'Ţifeşti',
      'Ticvaniu Mare',
      'Ticuşu Vechi',
      'Ţicleni',
      'Tichileşti',
      'Ţibucani',
      'Țibeni',
      'Ţibăneşti',
      'Ţibana',
      'Tia Mare',
      'Tetoiu',
      'Tețcoiu',
      'Teslui',
      'Teșila',
      'Terpeziţa',
      'Teremia Mare',
      'Teregova',
      'Terebeşti',
      'Ţepu',
      'Țelna',
      'Teliucu Inferior',
      'Teliu',
      'Teleşti',
      'Teleormanu',
      'Telega',
      'Telec',
      'Telciu',
      'Telcișor',
      'Teiuş',
      'Teiu',
      'Teişani',
      'Teiș',
      'Tecuci',
      'Techirghiol',
      'Teasc',
      'Teaca',
      'Tazlău',
      'Tăuții-Măgherăuș',
      'Tăuții de Sus',
      'Tăuteu',
      'Tăut',
      'Tauţ',
      'Tăureni',
      'Tătuleşti',
      'Tătăruşi',
      'Tătaru',
      'Tătărăștii de Sus',
      'Tătărăștii de Jos',
      'Tătărăști',
      'Tătăranu',
      'Tătărăni',
      'Tătărani',
      'Tăşnad',
      'Taşca',
      'Tășad',
      'Tărtăşeşti',
      'Tărlungeni',
      'Tariverde',
      'Tăriceni',
      'Tarcea',
      'Tarcău',
      'Tărcaia',
      'Tansa',
      'Ţăndărei',
      'Tănăsoaia',
      'Tanacu',
      'Tamaşi',
      'Tamași',
      'Tămășeu',
      'Tămăşeni',
      'Tămașda',
      'Tămădău Mare',
      'Talpoș',
      'Tălpigi',
      'Talpa-Trivalea',
      'Tălpaș',
      'Talpa-Ogrăzile',
      'Tălmaciu',
      'Tălmăcel',
      'Talea',
      'Ţaga',
      'Tăcuta',
      'Tăbărăști',
      'Tabăra',
      'Sviniţa',
      'Sutești',
      'Şuţeşti',
      'Suseni Socetu',
      'Susenii Bârgăului',
      'Suseni',
      'Şuşani',
      'Surdulești',
      'Surduc',
      'Surdila-Greci',
      'Surdila-Găiseanca',
      'Șurdești',
      'Surani',
      'Şura Mică',
      'Şura Mare',
      'Suraia',
      'Supuru de Jos',
      'Suplacu de Barcău',
      'Suplac',
      'Șupitca',
      'Şuncuiuş',
      'Sultana',
      'Sulița',
      'Sulina',
      'Şuletea',
      'Şuici',
      'Suhurlui',
      'Suhuleț',
      'Suharău',
      'Suhaia',
      'Şugag',
      'Sudiți',
      'Sudiţi',
      'Suciu de Sus',
      'Suciu de Jos',
      'Suceviţa',
      'Suceava',
      'Suceagu',
      'Subcetate',
      'Suatu',
      'Stulpicani',
      'Studinița',
      'Studina',
      'Strunga',
      'Strugari',
      'Stroiești',
      'Stroești',
      'Stroeşti',
      'Trestiana',
      'Strâmtura',
      'Strâmbeni',
      'Stremţ',
      'Strejnicu',
      'Strejeștii de Sus',
      'Strejeşti',
      'Strehaia',
      'Străteni',
      'Străoane',
      'Straja',
      'Storobăneasa',
      'Stornești',
      'Storeşti',
      'Stolnici',
      'Stolniceni-Prăjescu',
      'Stolniceni',
      'Stoina',
      'Stoileşti',
      'Stoicăneşti',
      'Stoeneşti',
      'Stoenești',
      'Ştiuca',
      'Ştiubieni',
      'Stârciu',
      'Stângăceaua',
      'Stâncești',
      'Stânceni',
      'Stânca',
      'Stâna',
      'Stâlpu',
      'Stâlpeni',
      'Sticlăria',
      'Sterpoaia',
      'Stelnica',
      'Stejaru',
      'Stejari',
      'Steierdorf',
      'Ştefeşti',
      'Ştefan Vodă',
      'Ştefăneşti-Sat',
      'Ştefăneştii de Jos',
      'Ștefănești',
      'Ştefăneşti',
      'Ştefan cel Mare',
      'Ștefan cel Mare',
      'Stăvaru',
      'Stăuceni',
      'Starchiojd',
      'Stăniţa',
      'Stănişeşti',
      'Stănileşti',
      'Stăneşti',
      'Stăncuţa',
      'Stăncești',
      'Stancea',
      'Stamate',
      'Spulber',
      'Şpring',
      'Sprâncenata',
      'Spiru Haret',
      'Spinuş',
      'Spermezeu',
      'Speriețeni',
      'Spătărei',
      'Spanţov',
      'Sovata',
      'Şovarna',
      'Şotrile',
      'Şotânga',
      'Şopotu Nou',
      'Sopot',
      'Soporu de Câmpie',
      'Şopârliţa',
      'Şona',
      'Somușca',
      'Somova',
      'Someş-Odorhei',
      'Şomcuta Mare',
      'Solovăstru',
      'Solonţ',
      'Soloneț',
      'Soleşti',
      'Şoldanu',
      'Solca',
      'Şoimuş',
      'Şoimi',
      'Şoimari',
      'Sohodol',
      'Sohatu',
      'Şofronea',
      'Sodomeni',
      'Socond',
      'Socol',
      'Socodor',
      'Soci',
      'Socetu',
      'Şoarş',
      'Snagov',
      'Smulţi',
      'Smirna',
      'Smârdioasa',
      'Smârdan',
      'Smeeni',
      'Slon',
      'Slobozia Moara',
      'Slobozia-Mândra',
      'Slobozia Conachi',
      'Slobozia-Ciorăşti',
      'Slobozia-Câmpineanca',
      'Slobozia Bradului',
      'Slobozia',
      'Slivileşti',
      'Slimnic',
      'Slăveni',
      'Slava Rusă',
      'Slava Cercheză',
      'Slătioara',
      'Slatina-Timiş',
      'Slatina',
      'Slănic-Moldova',
      'Slănic',
      'Șivița',
      'Sita Buzăului',
      'Şiştarovăţ',
      'Șisești',
      'Şişeşti',
      'Şirna',
      'Şirineasa',
      'Şiria',
      'Sireţel',
      'Siret',
      'Sârbii-Măgura',
      'Sârbi',
      'Sârbeni',
      'Șipotu',
      'Şipote',
      'Sânzieni',
      'Sântion',
      'Sântimbru',
      'Şinteu',
      'Sintești',
      'Şintereag',
      'Sintea Mare',
      'Sântandrei',
      'Sântana de Mureş',
      'Sântana',
      'Sântămăria-Orlea',
      'Santa Mare',
      'Sânsimion',
      'Sânpetru Mare',
      'Sânpetru German',
      'Sânpetru de Câmpie',
      'Sânpetru',
      'Sânpaul',
      'Sânnicolau Român',
      'Sânnicolau Mare',
      'Sânnicoară',
      'Sânmihaiu Român',
      'Sânmihaiu de Câmpie',
      'Sânmihaiu Almaşului',
      'Sânmărtin',
      'Sînmartin',
      'Sânmartin',
      'Sânmărghita',
      'Singureni',
      'Sângeru',
      'Sânger',
      'Sângeorz-Băi',
      'Sângeorgiu de Pădure',
      'Sângeorgiu de Mureş',
      'Sineşti',
      'Sinești',
      'Șindrilari',
      'Sândominic',
      'Sâncraiu de Mureş',
      'Sâncraiu',
      'Sâncrai',
      'Sâncrăieni',
      'Sâncel',
      'Şinca Veche',
      'Șinca Nouă',
      'Şincai',
      'Sânandrei',
      'Sinaia',
      'Simoneşti',
      'Șimon',
      'Şimnicu de Sus',
      'Şimleu Silvaniei',
      'Șimișna',
      'Siminoc',
      'Siminicea',
      'Şimian',
      'Simeria',
      'Sâmbureşti',
      'Sâmbotin',
      'Sâmbăteni',
      'Sâmbăta de Sus',
      'Sâmbăta',
      'Şimand',
      'Silivașu de Câmpie',
      'Siliștea Snagovului',
      'Siliștea Gumești',
      'Siliştea Crucii',
      'Siliștea',
      'Siliştea',
      'Șilindru',
      'Şilindia',
      'Sihlea',
      'Sighetu Marmaţiei',
      'Sâg',
      'Şieuţ',
      'Şieu-Odorhei',
      'Şieu-Măgheruş',
      'Șieu',
      'Şieu',
      'Siculeni',
      'Şicula',
      'Șiclău',
      'Sicheviţa',
      'Sic',
      'Şibot',
      'Sibiu',
      'Sfârcea',
      'Sfântu Gheorghe',
      'Sfinţeşti',
      'Serdanu',
      'Şercaia',
      'Șerboeni',
      'Șerbăuți',
      'Ștefan Vodă',
      'Șerbănești',
      'Şerbăneşti',
      'Șepreuș',
      'Şendriceni',
      'Şendreni',
      'Semlac',
      'Şelimbăr',
      'Seleuș',
      'Seleuş',
      'Şelaru',
      'Şeitin',
      'Seini',
      'Seimeni',
      'Şeica Mică',
      'Şeíca Mare',
      'Segarcea Vale',
      'Segarcea-Deal',
      'Segarcea',
      'Secusigiu',
      'Secui',
      'Secuieni',
      'Secu',
      'Seciu',
      'Secaş',
      'Secăria',
      'Sebiş',
      'Sebeșel',
      'Sebeş',
      'Seaca de Pădure',
      'Seaca de Câmp',
      'Seaca',
      'Scutelnici',
      'Scurtu Mare',
      'Scurtești',
      'Scurta',
      'Scundu',
      'Sculia',
      'Scrioaştea',
      'Scorţoasa',
      'Scorţeni',
      'Scorțaru Vechi',
      'Scorţaru Nou',
      'Scorniceşti',
      'Scobinţi',
      'Scoarţa',
      'Scânteia',
      'Scânteiești',
      'Schiulești',
      'Schitu-Goleşti',
      'Schitu Frumoasa',
      'Schitu-Duca',
      'Schitu',
      'Schela',
      'Scheiu de Sus',
      'Scheiu de Jos',
      'Scheia',
      'Şcheia',
      'Scărişoara',
      'Scăeşti',
      'Săvârşin',
      'Săvineşti',
      'Săveni',
      'Săvădisla',
      'Şăulia',
      'Săuleşti',
      'Săucești',
      'Săuca',
      'Sauca',
      'Sat-Șugatag',
      'Satu Nou Calopăr',
      'Satu Nou',
      'Satu Mare',
      'Satulung',
      'Sătmărel',
      'Săteni',
      'Satchinez',
      'Sălcioara',
      'Sascut-Sat',
      'Sascut',
      'Săsciori',
      'Saschiz',
      'Sasca Montană',
      'Săsar',
      'Sărulești-Gară',
      'Săruleşti',
      'Şaru Dornei',
      'Șaroș pe Târnave',
      'Sarmizegetusa',
      'Sărmaşu',
      'Şărmăşag',
      'Sărmaş',
      'Sarichioi',
      'Șard',
      'Saravale',
      'Sărăuad',
      'Sărățeni',
      'Sărata',
      'Sarasău',
      'Saraiu',
      'Sarafinești',
      'Săpunari',
      'Săpoca',
      'Săpânţa',
      'Santău',
      'Șanț',
      'Sanislău',
      'Sănduleşti',
      'Sănduleni',
      'Şandra',
      'Şamşud',
      'Samarineşti',
      'Salva',
      'Sălsig',
      'Salonta',
      'Săliştea de Sus',
      'Săliştea-Deal',
      'Săliștea',
      'Sălişte',
      'Saligny',
      'Sălcuța',
      'Sălcuţa',
      'Sălciua de Sus',
      'Sălciile',
      'Salcia Tudor',
      'Salcia',
      'Salcea',
      'Sălătrucu',
      'Sălătrucel',
      'Sălăţig',
      'Sălaşu de Sus',
      'Sălard',
      'Sălacea',
      'Săhăteni',
      'Şagu',
      'Sagna',
      'Săgeata',
      'Şag',
      'Șaeș',
      'Saelele',
      'Sadu',
      'Sadova',
      'Sadina',
      'Săcuieu',
      'Săcueni',
      'Sacu',
      'Sacoşu Turcesc',
      'Sacoșu Mare',
      'Săceni',
      'Săcelu',
      'Săcele',
      'Săcel',
      'Săcăşeni',
      'Săcălaz',
      'Săcălășeni',
      'Săcădat',
      'Sabasa',
      'Săbăreni',
      'Săbăoani',
      'Ruşii Munţi',
      'Ruşeţu',
      'Ruseni',
      'Ruscova',
      'Rusca Montană',
      'Rusca',
      'Rusăneşti',
      'Rus',
      'Rupea',
      'Runcu Salvei',
      'Runcu',
      'Ruja',
      'Ruginoasa',
      'Rugineşti',
      'Rugășești',
      'Rudeni',
      'Rucăreni',
      'Rucăr',
      'Rubla',
      'Roznov',
      'Rozavlea',
      'Pecica',
      'Rovine',
      'Rovinari',
      'Rotunda',
      'Roșu',
      'Roșiorii de Vede',
      'Roșiori',
      'Roşiori',
      'Roşiile',
      'Roşieşti',
      'Roșia Montană',
      'Roşia de Secaş',
      'Roşia de Amaradia',
      'Roşia',
      'Roseţi',
      'Roșcani',
      'Rona de Sus',
      'Rona de Jos',
      'Romuli',
      'Romos',
      'Români',
      'Româneşti',
      'Românești',
      'Românaşi',
      'Romanu',
      'Roman',
      'Roma',
      'Rojiște',
      'Rogoz',
      'Rogova',
      'Roeşti',
      'Rodna',
      'Rociu',
      'Robeasca',
      'Roata de Jos',
      'Râu Sadului',
      'Râu de Mori',
      'Râu Alb de Jos',
      'Râşnov',
      'Râșești',
      'Râşca',
      'Râșca',
      'Ripiceni',
      'Rânzești',
      'Râncăciov',
      'Râmnicu Vâlcea',
      'Râmnicu Sărat',
      'Râmniceni',
      'Râmnicelu',
      'Rimetea',
      'Râmești',
      'Râfov',
      'Rieni',
      'Râciu',
      'Râca',
      'Ribiţa',
      'Reviga',
      'Retevoiești',
      'Reteag',
      'Reşiţa',
      'Repedea',
      'Remuș',
      'Remeţi',
      'Remetea Mare',
      'Remetea Chioarului',
      'Remetea',
      'Reghiu',
      'Reghin-Sat',
      'Reghin',
      'Rediu',
      'Redea',
      'Reci',
      'Recea Cristur',
      'Recea',
      'Recaş',
      'Rebrişoara',
      'Rebricea',
      'Rebra',
      'Răzvani',
      'Răzvad',
      'Războienii de Jos',
      'Războieni-Cetate',
      'Războieni',
      'Răuseni',
      'Răuceşti',
      'Răteşti',
      'Răsuceni',
      'Rast',
      'Răstoliţa',
      'Răstoaca',
      'Rasova',
      'Răsmireşti',
      'Răşinari',
      'Rași',
      'Răscruci',
      'Răscăeți',
      'Rasa',
      'Rapoltu Mare',
      'Răpănaşu',
      'Ramna',
      'Rafaila',
      'Radu Vodă',
      'Răducăneni',
      'Radovanu',
      'Radovan',
      'Radomir',
      'Radomireşti',
      'Rădoiești-Deal',
      'Radna',
      'Rădeşti',
      'Rădești',
      'Rădeni',
      'Rădăuți-Prut',
      'Rădăuți',
      'Rădăşeni',
      'Racu',
      'Racşa',
      'Racoviţeni',
      'Racoviţa',
      'Racovița',
      'Racova',
      'Răcoasa',
      'Raciu',
      'Gârlenii de Sus',
      'Răchitova',
      'Răchitoasa',
      'Răchiţi',
      'Răchiteni',
      'Răchita',
      'Rachieri',
      'Rachelu',
      'Răcăuți',
      'Răcăşdia',
      'Răcarii de Sus',
      'Răcari',
      'Răcăciuni',
      'Răbăgani',
      'Puțuri',
      'Putna',
      'Putineiu',
      'Pustiana',
      'Pusta',
      'Pușcași',
      'Purcăreni',
      'Stănești',
      'Purani',
      'Punghina',
      'Pungeşti',
      'Puieşti',
      'Pui',
      'Pufeşti',
      'Puieștii de Jos',
      'Puieni',
      'Pucioasa',
      'Puchenii Moșneni',
      'Puchenii Mari',
      'Pucheni',
      'Prunişor',
      'Prundu Bârgăului',
      'Prundu',
      'Prundeni',
      'Proviţa de Sus',
      'Proviţa de Jos',
      'Prohozești',
      'Progresu',
      'Produleşti',
      'Probota',
      'Pristol',
      'Priseaca',
      'Prisăcani',
      'Priponeşti',
      'Prigoria',
      'Prigor',
      'Pricaz',
      'Priboiu',
      'Priboieni',
      'Pribești',
      'Preuteşti',
      'Prelipca',
      'Prejmer',
      'Predeşti',
      'Predeal',
      'Preajba',
      'Prapor',
      'Prajila',
      'Prăjești',
      'Prăjeni',
      'Prăjani',
      'Praid',
      'Potoceni',
      'Potlogi',
      'Potlogeni',
      'Potigrafu',
      'Potelu',
      'Potcoava Fălcoeni',
      'Potcoava',
      'Potău',
      'Poşta Câlnău',
      'Poseștii-Pământeni',
      'Poşaga de Sus',
      'Posada',
      'Porumbești',
      'Porumbenii Mari',
      'Porumbacu de Jos',
      'Poroschia',
      'Poroina Mare',
      'Popricani',
      'Popoveni',
      'Poporogi',
      'Poplaca',
      'Popeşti-Leordeni',
      'Popeşti',
      'Popești',
      'Popeni',
      'Ponor',
      'Ponoarele',
      'Pomârla',
      'Pomi',
      'Pomezeu',
      'Polovragi',
      'Poloboc',
      'Pojorâta',
      'Pojogeni',
      'Pojejena',
      'Poienile-Mogoş',
      'Poienile Izei',
      'Poienile de sub Munte',
      'Poienile',
      'Poieneşti',
      'Poienari',
      'Poiana Vadului',
      'Poiana Teiului',
      'Poiana Țapului',
      'Poiana Stampei',
      'Poiana Sibiului',
      'Poiana Mărului',
      'Poiana Mare',
      'Poiana Lacului',
      'Poiana Ilvei',
      'Poiana Cristei',
      'Poiana Crăcăoani',
      'Poiana Copăceni',
      'Poiana Codrului',
      'Poiana Câmpina',
      'Poiana Blenchii',
      'Poiana',
      'Poian',
      'Pogonești',
      'Pogonele',
      'Pogoanele',
      'Pogănești',
      'Pogana',
      'Pogăceaua',
      'Poieni-Solca',
      'Poieni',
      'Poeni',
      'Poienarii Apostoli',
      'Poienarii Burchii',
      'Podu Turcului',
      'Poduri',
      'Podu Iloaiei',
      'Podolenii de Sus',
      'Podoleni',
      'Podgoria',
      'Podenii Vechi',
      'Podenii Noi',
      'Podeni',
      'Podari',
      'Pocruia',
      'Pocola',
      'Pociovaliștea',
      'Pochidia',
      'Poboru',
      'Poarta Albă',
      'Plugova',
      'Plugari',
      'Plutonița',
      'Ploștina',
      'Ploscuțeni',
      'Ploscoș',
      'Plosca',
      'Plopu',
      'Plopşoru',
      'Plopșoru',
      'Plopiş',
      'Plopii Slăviţeştí',
      'Plopeni',
      'Plopana',
      'Ploieşti',
      'Ploieștiori',
      'Plevna',
      'Pleşoiu',
      'Pleșoi',
      'Pleşcuţa',
      'Pleniţa',
      'Pleașov',
      'Pleașa',
      'Plăvălari',
      'Platonești',
      'Plătăreşti',
      'Piteşti',
      'Piteasca',
      'Pitaru',
      'Piscu Vechi',
      'Piscu Sadovei',
      'Piscu Nou',
      'Piscu',
      'Pişcolt',
      'Piscoiu',
      'Pişchia',
      'Pârşcoveni',
      'Pârscov',
      'Pârjol',
      'Pârâu',
      'Pârgăreşti',
      'Pârcovaci',
      'Pâraie',
      'Pir',
      'Pipirig',
      'Pângăraţi',
      'Pângărăcior',
      'Pâncota',
      'Pânceşti',
      'Pilu',
      'Pildești',
      'Pietrosu',
      'Pietroşiţa',
      'Pietroşani',
      'Pietroșani',
      'Pietroasele',
      'Pietroasa',
      'Pietrișu',
      'Pietreni',
      'Pietrele',
      'Pietrarii de Sus',
      'Pietrari',
      'Pieleştí',
      'Picior de Munte',
      'Piatra Şoimului',
      'Piatra Olt',
      'Piatra Neamţ',
      'Piatra',
      'Pianu de Sus',
      'Pianu de Jos',
      'Petrova',
      'Petroșnița',
      'Petroşani',
      'Petriș',
      'Petriş',
      'Petrila',
      'Pietriceaua',
      'Petricani',
      'Petreu',
      'Petreştii de Jos',
      'Petreşti',
      'Petrești',
      'Petrăchioaia',
      'Petelea',
      'Peştişu Mic',
      'Peștișu Mare',
      'Peştişani',
      'Peștiș',
      'Peştera',
      'Peșteana Jiu',
      'Peșteana de Jos',
      'Pesceana',
      'Glăvile',
      'Coronini',
      'Pesac',
      'Perșunari',
      'Perșinari',
      'Perșani',
      'Perișoru',
      'Perişoru',
      'Perișor',
      'Perişani',
      'Periş',
      'Perii Broșteni',
      'Perieţi',
      'Perieni',
      'Pericei',
      'Periam',
      'Peretu',
      'Peregu Mic',
      'Peregu Mare',
      'Peciu Nou',
      'Pecineaga',
      'Pechea',
      'Peceneaga',
      'Peceiu',
      'Păuşeşti-Măglaşi',
      'Păușești',
      'Păuşeşti',
      'Păuneşti',
      'Păuliş',
      'Păuleşti',
      'Păulești',
      'Păuleni-Ciuc',
      'Păuca',
      'Pătuleni',
      'Pătulele',
      'Patru Frați',
      'Pătrăuţi',
      'Pătârlagele',
      'Păstrăveni',
      'Păsăreni',
      'Parva',
      'Părteştii de Jos',
      'Parța',
      'Parincea',
      'Părhăuți',
      'Parepa-Rușani',
      'Pardoşi',
      'Pardina',
      'Parava',
      'Papiu Ilarian',
      'Păpăuți',
      'Panticeu',
      'Pantelimon de Jos',
      'Pantelimon',
      'Pantazi',
      'Pănet',
      'Panciu',
      'Pănceşti',
      'Păncești',
      'Pănătău',
      'Panaci',
      'Păltinoasa',
      'Păltiniş',
      'Păltineni',
      'Paltin',
      'Paleu',
      'Palazu Mare',
      'Pălatca',
      'Palanca',
      'Pădureni',
      'Pădurea Neagră',
      'Padina Mică',
      'Padina Matei',
      'Padina',
      'Padeş',
      'Padea',
      'Păcureţi',
      'Ozun',
      'Ovidiu',
      'Oveselu',
      'Otopeni',
      'Otetelișu',
      'Oteşani',
      'Oţelu Roşu',
      'Oţeleni',
      'Otelec',
      'Ostrovu Mare',
      'Ostrovu',
      'Ostroveni',
      'Ostrov',
      'Ostra',
      'Oșorhei',
      'Osoi',
      'Osica de Sus',
      'Osica de Jos',
      'Oşeşti',
      'Osebiți',
      'Orţişoara',
      'Orşova',
      'Oroftiana',
      'Orodel',
      'Ormeniş',
      'Orleşti',
      'Orlea Nouă',
      'Orlea',
      'Orlat',
      'Orevița Mare',
      'Oreavu',
      'Orbeni',
      'Orbeasca de Sus',
      'Orbeasca de Jos',
      'Oraviţa',
      'Oraşu Nou',
      'Orăştioara de Sus',
      'Orăştie',
      'Orășeni-Deal',
      'Orășa',
      'Oradea',
      'Optaşi',
      'Oprişor',
      'Oporelu',
      'Oniceni',
      'Oneaga',
      'Oncești',
      'Onceşti',
      'Oltina',
      'Olteniţa',
      'Olteni',
      'Olteneşti',
      'Olteanca',
      'Ologeni',
      'Oleșești',
      'Olcea',
      'Olari',
      'Olanu',
      'Olănești',
      'Ojdula',
      'Oituz',
      'Oinacu',
      'Ohaba Lungă',
      'Ohaba',
      'Ogrezeni',
      'Ogretin',
      'Ograda',
      'Ogra',
      'Oglinzi',
      'Oeștii Pământeni',
      'Odorheiu Secuiesc',
      'Odoreu',
      'Odobești',
      'Odobeşti',
      'Odăile',
      'Odaia Turcului',
      'Odaia Manolache',
      'Ocoliş',
      'Ocnița',
      'Ocniţa',
      'Ocna Şugatag',
      'Ocna Sibiului',
      'Ocna Mureş',
      'Ocna de Sus',
      'Ocna Dejului',
      'Ocna de Jos',
      'Ocland',
      'Ocina de Sus',
      'Obrejița',
      'Obreja',
      'Oboga',
      'Obârşia-Cloşani',
      'Obârșia de Câmp',
      'Obârşia',
      'Oarţa de Jos',
      'Oarja Sat',
      'Oarda',
      'Oar',
      'Oanțu',
      'Oancea',
      'Nuşfalău',
      'Nuşeni',
      'Nuntași',
      'Nufăru',
      'Nucşoara',
      'Nuci',
      'Nucet',
      'Novaci',
      'Nou',
      'Noşlac',
      'Nojorid',
      'Nocrich',
      'Niţchidorf',
      'Nistru',
      'Nistoreşti',
      'Nisiporești',
      'Nisipari',
      'Nireș',
      'Nimigea de Sus',
      'Nimigea de Jos',
      'Niculiţel',
      'Niculești',
      'Nicşeni',
      'Nicoreşti',
      'Nicolești',
      'Nicolae Titulescu',
      'Nicolae Bălcescu',
      'Neudorf',
      'Nereju Mic',
      'Nereju',
      'Nerău',
      'Nepos',
      'Nenciulești',
      'Nemțișor',
      'Nehoiu',
      'Negru Vodă',
      'Negrilești',
      'Negrileasa',
      'Negri',
      'Negreşti-Oaş',
      'Negrești',
      'Negreşti',
      'Negreni',
      'Negraşi',
      'Negostina',
      'Negomir',
      'Negoi',
      'Negoiești',
      'Negoești',
      'Nedelea',
      'Nedeia',
      'Necşeşti',
      'Neaua',
      'Neagra Șarului',
      'Nazna',
      'Năvodari',
      'Năsturelu',
      'Năsăud',
      'Năruja',
      'Năpradea',
      'Nanov',
      'Năneşti',
      'Nana',
      'Nămoloasa-Sat',
      'Nămoloasa',
      'Nalbant',
      'Naipu',
      'Naidăș',
      'Năeni',
      'Nădrag',
      'Nădlac',
      'Nadeş',
      'Nadăș',
      'Nadab',
      'Mușătești',
      'Muşeteşti',
      'Muşeniţa',
      'Murighiol',
      'Murgeşti',
      'Murgeni',
      'Murgași',
      'Murfatlar',
      'Muntenii de Sus',
      'Muntenii de Jos',
      'Munteni Buzău',
      'Munteni',
      'Muntele Mic',
      'Muncelu de Sus',
      'Mugeni',
      'Muereasca',
      'Mozăceni',
      'Moviliţa',
      'Movileni',
      'Movila Miresii',
      'Movila Banului',
      'Movila',
      'Motru',
      'Motoşeni',
      'Moţca',
      'Moţăţei',
      'Moțăieni',
      'Moșteni',
      'Moşoaia',
      'Moşniţa Nouă',
      'Moșnița Veche',
      'Moșneni',
      'Moşna',
      'Moscu',
      'Morunglav',
      'Morteni',
      'Moroeni',
      'Moreni',
      'Moraviţa',
      'Morăreşti',
      'Monor',
      'Moneasa',
      'Moldoviţa',
      'Moldoveni',
      'Moldoveneşti',
      'Moldova Suliţa',
      'Moldova Nouă',
      'Moișeni',
      'Moisei',
      'Moineşti',
      'Mogoşoaia',
      'Mogoşeşti-Siret',
      'Mogoşeşti',
      'Mogoşani',
      'Mogoș',
      'Moftinu Mic',
      'Moftinu Mare',
      'Mofleni',
      'Moeciu de Jos',
      'Modelu',
      'Mociu',
      'Moceşti',
      'Moara Vlăsiei',
      'Moara Nica',
      'Moara Grecilor',
      'Moara Domnească',
      'Moara Carp',
      'Moacşa',
      'Mlenăuți',
      'Mizil',
      'Mitrofani',
      'Mitreni',
      'Mitocu Dragomirnei',
      'Mitoc',
      'Mislea',
      'Misentea',
      'Mischii',
      'Mișca',
      'Mâsca',
      'Mârzăneşti',
      'Mârţeşti',
      'Mirşid',
      'Mârşani',
      'Mârşa',
      'Miroslovești',
      'Miroslăvești',
      'Miroslava',
      'Miroşi',
      'Mironeasa',
      'Miron Costin',
      'Mironu',
      'Mireşu Mare',
      'Mireșu Mare',
      'Mirceștii Noi',
      'Mirceşti',
      'Călăraşi',
      'Mircea Vodă',
      'Mirăslău',
      'Miorcani',
      'Mânzăleşti',
      'Mintiu Gherlii',
      'Minieri',
      'Mineri',
      'Mândruloc',
      'Mândrești',
      'Mândra',
      'Mânău',
      'Mânăstireni',
      'Mănăstirea Humorului',
      'Mânăstirea Caşin',
      'Mănăstirea',
      'Mânăstirea',
      'Miloşeşti',
      'Milişăuţi',
      'Mileanca',
      'Milcovul',
      'Milcovățu',
      'Milcoiu',
      'Milaş',
      'Mijlocenii Bârgăului',
      'Mija',
      'Mihoveni',
      'Miheşu de Câmpie',
      'Mihalţ',
      'Mihălceni',
      'Mihălăşeni',
      'Mihai Vodă',
      'Mihai Viteazu',
      'Mihail Kogălniceanu',
      'Mihăileşti',
      'Mihăileni',
      'Mihai Bravu',
      'Mihăiești',
      'Mihăeşti',
      'Miercurea Sibiului',
      'Miercurea Nirajului',
      'Miercurea-Ciuc',
      'Micula',
      'Micleşti',
      'Micfalău',
      'Miceştii de Câmpie',
      'Micești',
      'Miceşti',
      'Micăsasa',
      'Mica',
      'Meziad',
      'Meteş',
      'Meseşenii de Jos',
      'Merişani',
      'Merișani',
      'Merii Petchii',
      'Meri',
      'Merghindeal',
      'Mereşti',
      'Merenii de Sus',
      'Merenii de Jos',
      'Mereni',
      'Merei',
      'Mera',
      'Melineşti',
      'Mehadica',
      'Mehadia',
      'Medieşu Aurit',
      'Mediaş',
      'Medgidia',
      'Măzănăești',
      'Maxut',
      'Măxineni',
      'Maxenu',
      'Mavrodin',
      'Măureni',
      'Mătești',
      'Matei',
      'Mateeşti',
      'Matca',
      'Mățău',
      'Mătăsaru',
      'Mătăsari',
      'Mătăcina',
      'Măstăcani',
      'Mastacăn',
      'Maşloc',
      'Mărunțișu',
      'Mărunţei',
      'Măru',
      'Mărtiniş',
      'Mărtineşti',
      'Marpod',
      'Măriței',
      'Mărişelu',
      'Mărişel',
      'Marin',
      'Mărgineni Slobozia',
      'Mărgineni-Munteni',
      'Mărginenii de Sus',
      'Mărginenii de Jos',
      'Mărgineni',
      'Marginea',
      'Margina',
      'Marghita',
      'Mărgău',
      'Mărgăriteşti',
      'Marga',
      'Mareș',
      'Mărculești',
      'Mărcești',
      'Marca',
      'Măraşu',
      'Mărășești',
      'Mărăcineni',
      'Mara',
      'Manoleasa',
      'Mangalia',
      'Măneşti',
      'Măneciu-Ungureni',
      'Mănăștur',
      'Mănăştiur',
      'Manasia',
      'Mănăilești',
      'Mamaia-Sat',
      'Măluşteni',
      'Malu Spart',
      'Malu Roșu',
      'Mălureni',
      'Malu Mare',
      'Malu cu Flori',
      'Malu',
      'Malovăţ',
      'Malnaş',
      'Mălini',
      'Mălâncrav',
      'Măldăreşti',
      'Măldăeni',
      'Malcoci',
      'Malaia',
      'Mal',
      'Mailat',
      'Măieruş',
      'Măieru',
      'Măicăneşti',
      'Maia',
      'Mahmudia',
      'Măguri-Răcătău',
      'Măguri',
      'Măgureni',
      'Măgurele',
      'Măgura Ilvei',
      'Măgura',
      'Magula',
      'Maglavit',
      'Măgireşti',
      'Măgherani',
      'Măgeşti',
      'Mogești',
      'Măerişte',
      'Mădulari',
      'Mădârjac',
      'Măderat',
      'Mădăras',
      'Mădăraş',
      'Mădăraș',
      'Măcin',
      'Măceşu de Sus',
      'Măceşu de Jos',
      'Macea',
      'Lupşanu',
      'Lupşa',
      'Lupeni',
      'Lupac',
      'Lunguleţu',
      'Lungeşti',
      'Lungani',
      'Lunga',
      'Luncșoara',
      'Luncoiu de Jos',
      'Luncaviţa',
      'Lunca Priporului',
      'Lunca Prahovei',
      'Luncani',
      'Lunca Mureşului',
      'Lunca Leșului',
      'Lunca la Tisa',
      'Lunca Jariștei',
      'Lunca Ilvei',
      'Lunca de Sus',
      'Lunca de Jos',
      'Lunca Corbului',
      'Lunca Cetățuii',
      'Lunca Cernii de Jos',
      'Lunca Bradului',
      'Lunca Banului',
      'Lunca',
      'Luna de Sus',
      'Luminiș',
      'Lumina',
      'Luizi-Călugăra',
      'Luica',
      'Lugoj',
      'Lugaşu de Jos',
      'Lueta',
      'Luduş',
      'Ludoş',
      'Ludeşti',
      'Ludași',
      'Luciu',
      'Lucieni',
      'Lucăceni',
      'Lozna',
      'Lovrin',
      'Lopătari',
      'Lopadea Nouă',
      'Loloiasca',
      'Logrești Moșteni',
      'Logreşti',
      'Loamneş',
      'Livezi-Vale',
      'Livezile',
      'Livezi',
      'Livezeni',
      'Livadia',
      'Livadea',
      'Livada de Bihor',
      'Livada',
      'Liubcova',
      'Liteni',
      'Liţa',
      'Lișteava',
      'Lisa',
      'Lipovu',
      'Lipovăţ',
      'Lipova',
      'Lipniţa',
      'Lipia',
      'Lipăneşti',
      'Izvoru',
      'Limpeziș',
      'Limanu',
      'Liliești',
      'Lilieci',
      'Lieşti',
      'Liebling',
      'Licurici',
      'Libotin',
      'Leu',
      'Letea Veche',
      'Letca Veche',
      'Letca Nouă',
      'Leţcani',
      'Letca',
      'Leşu',
      'Lespezi',
      'Lereşti',
      'Leordina',
      'Leordeni',
      'Leorda',
      'Lenauheim',
      'Lemnia',
      'Leliceni',
      'Leleşti',
      'Lelese',
      'Leleasca',
      'Leicești',
      'Lehliu-Gară',
      'Lehliu',
      'Lechinţa',
      'Lazuri de Beiuş',
      'Lazuri',
      'Lăzărești',
      'Lăzăreni',
      'Lăzarea',
      'Laza',
      'Laslea',
      'Largu',
      'Larga',
      'Lăpuşnicu Mare',
      'Lăpuşnicel',
      'Lăpușel',
      'Lăpuş',
      'Lăpugiu de Jos',
      'Lapoș',
      'Lăpoș',
      'Lanurile',
      'Lancrăm',
      'Lămășeni',
      'Laloşu',
      'Lădeşti',
      'Lăcusteni',
      'Lacu Sărat',
      'Lăculețe',
      'Lăceni',
      'Jurilovca',
      'Jupânești',
      'Jupâneşti',
      'Jugureni',
      'Jugureanu',
      'Jugur',
      'Josenii Bârgăului',
      'Joseni',
      'Jorăşti',
      'Jorăști',
      'Joldești',
      'Joiţa',
      'Jiu-Paroșeni',
      'Jitia',
      'Jirov',
      'Jirlău',
      'Jina',
      'Jimbolia',
      'Jilavele',
      'Jilava',
      'Jijila',
      'Jieni',
      'Jidvei',
      'Jidoștița',
      'Jichişu de Jos',
      'Jibou',
      'Jiblea Veche',
      'Jibert',
      'Jiana Veche',
      'Jiana',
      'Jegălia',
      'Jebel',
      'Jariştea',
      'Jamu Mare',
      'Jabenița',
      'Izvoru Dulce',
      'Izvoru de Sus',
      'Izvoru Crişului',
      'Izvoru Bârzii',
      'Izvoru Berheciului',
      'Izvoarele Sucevei',
      'Izvoarele',
      'Izvoare',
      'Izvin',
      'Izimșa',
      'Izbiceni',
      'Iveşti',
      'Ivănești',
      'Iugani',
      'Viile Tecii',
      'Iteşti',
      'Isverna',
      'Istria',
      'Islaz',
      'Iscroni',
      'Işalniţa',
      'Isaccea',
      'Irești',
      'Iratoşu',
      'Ipoteşti',
      'Ipotești',
      'Ipatele',
      'Ip',
      'Iordăcheanu',
      'Ion Roată',
      'Ion Neculce',
      'I. L. Caragiale',
      'Ioneşti',
      'Ionești',
      'Ion Corvin',
      'Ionășeni',
      'Iojib',
      'Intregalde',
      'Întorsura Buzăului',
      'Întorsura',
      'Însurăţei',
      'Inotești',
      'Ineu',
      'Independenţa',
      'Imper',
      'Ilva Mică',
      'Ilva Mare',
      'Iloviţa',
      'Ilovăț',
      'Ilișești',
      'Ilieni',
      'Ilia',
      'Ileanda',
      'Ileana',
      'Ilba',
      'Igriș',
      'Igoiu',
      'Igneşti',
      'Ighiu',
      'Ighișu Nou',
      'Ighiel',
      'Igești',
      'Iezeru',
      'Ieud',
      'Iernuțeni',
      'Iernut',
      'Iecea Mică',
      'Iecea Mare',
      'Ideciu de Jos',
      'Icuseşti',
      'Icușeni',
      'Icoana',
      'Iclod',
      'Iclănzel',
      'Ibrianu',
      'Ibănești-Pădure',
      'Ibăneşti',
      'Ibănești',
      'Iazurile',
      'Iazu Nou',
      'Iazu',
      'Iaslovăț',
      'Iaşi',
      'Iara',
      'Iapa',
      'Ianoșda',
      'Iancu Jianu',
      'Ianca',
      'Iana',
      'Iadăra',
      'Iacobeni',
      'Iablaniţa',
      'Huta Certeze',
      'Husnicioara',
      'Huşi',
      'Husasău de Tinca',
      'Hurjuieni',
      'Huruiești',
      'Hurezani',
      'Hunia',
      'Hunedoara',
      'Humoreni',
      'Humulești',
      'Humele',
      'Hulubeşti',
      'Huedin',
      'Hudeşti',
      'Hotarele',
      'Hotar',
      'Horodnic de Sus',
      'Horodnic de Jos',
      'Horodniceni',
      'Horoatu Crasnei',
      'Horleşti',
      'Horia',
      'Horgeşti',
      'Horezu',
      'Hopârta',
      'Homorodu de Jos',
      'Homorod',
      'Homorâciu',
      'Homocea',
      'Holt',
      'Holod',
      'Holboca',
      'Holbav',
      'Hoghiz',
      'Hoghilag',
      'Hodoșa',
      'Hodoşa',
      'Hodora',
      'Hodoni',
      'Hodod',
      'Hodac',
      'Hociungi',
      'Hoceni',
      'Hlipiceni',
      'Hlăpești',
      'Hârtop',
      'Hârtoape',
      'Hârtieşti',
      'Hârşova',
      'Hârseşti',
      'Hârşeni',
      'Hârlău',
      'Hinova',
      'Hilişeu-Horia',
      'Hidişelu de Sus',
      'Hida',
      'Herla',
      'Hereclean',
      'Herculian',
      'Herăști',
      'Hemeiuș',
      'Heleșteni',
      'Helegiu',
      'Heci',
      'Havârna',
      'Haţeg',
      'Hășmaș',
      'Hărțăgani',
      'Hărmăneștii Vechi',
      'Hărmăneasa',
      'Hărman',
      'Hărău',
      'Hanu Conachi',
      'Hănțești',
      'Hangu',
      'Hăneşti',
      'Hamcearca',
      'Halmeu',
      'Halmăşd',
      'Hălmagiu',
      'Hălmăgel',
      'Halânga',
      'Hălchiu',
      'Hălăuceşti',
      'Hălărești',
      'Hăghig',
      'Hăghiac',
      'Hăbeni',
      'Gvardinița',
      'Guşoeni',
      'Guruieni',
      'Gurghiu',
      'Gurbediu',
      'Gurbăneşti',
      'Gurba',
      'Gura Vulcanei',
      'Gura Viţioarei',
      'Gura Văii',
      'Gura Vadului',
      'Gura Teghii',
      'Gura Şuţii',
      'Gura Suhașului',
      'Gura Râului',
      'Gura Putnei',
      'Gura Padinii',
      'Gura Ocniței',
      'Gura Ialomiței',
      'Gura Humorului',
      'Gurahonţ',
      'Gura Foii',
      'Gura Căluiu',
      'Gura Caliţei',
      'Gura Beliei',
      'Gulia',
      'Gugești',
      'Gugeşti',
      'Grunji',
      'Grumăzeşti',
      'Gruiu',
      'Gruia',
      'Grozeşti',
      'Grozăvești',
      'Groșii Țibleșului',
      'Groşi',
      'Groși',
      'Groșani',
      'Gropșani',
      'Gropniţa',
      'Grojdibodu',
      'Griviţa',
      'Grinţieş',
      'Grindu',
      'Greoni',
      'Greci',
      'Greceşti',
      'Greceanca',
      'Greblești',
      'Grebenişu de Câmpie',
      'Greaca',
      'Grebănu',
      'Gratia',
      'Grăniceşti',
      'Grăniceri',
      'Grămeşti',
      'Grajduri',
      'Grăjdana',
      'Grădiştea',
      'Gradiștea',
      'Grădiștea',
      'Grădinari',
      'Grădina',
      'Grabăț',
      'Gottlob',
      'Gostinu',
      'Goştinari-Văcăreşti',
      'Gostinari',
      'Gostavăţu',
      'Goșmani',
      'Goruni',
      'Goruna',
      'Goruia',
      'Gornet-Cricov',
      'Gornet',
      'Gorneşti',
      'Gorgota',
      'Gorganu',
      'Goreni',
      'Gorbăneşti',
      'Gorban',
      'Goranu',
      'Gologanu',
      'Goleşti',
      'Golești',
      'Golăiești',
      'Goicea',
      'Gohor',
      'Gogoşu',
      'Gogoşari',
      'Goești',
      'Goiești',
      'Godineşti',
      'Godeni',
      'Godeanu',
      'Glogova',
      'Glodenii Gândului',
      'Glodeni',
      'Glodeanu-Siliştea',
      'Glodeanu-Sărat',
      'Glod',
      'Glina',
      'Glimboca',
      'Glăvăneşti',
      'Giuvărăşti',
      'Giurtelecu Șimleului',
      'Giurgiu',
      'Giurgiţa',
      'Giurgeni',
      'Giulvăz',
      'Giuleşti',
      'Giubega',
      'Girov',
      'Giroc',
      'Gârnic',
      'Gârliciu',
      'Gârleni',
      'Gârla-Mare',
      'Girișu de Criș',
      'Gârdani',
      'Gârda de Sus',
      'Gârcov',
      'Gârcina',
      'Gârceni',
      'Gârbovi',
      'Gârbova',
      'Gârbou',
      'Gârbești',
      'Gârbău',
      'Gângiova',
      'Gâlgău Almaşului',
      'Gâlgău',
      'Gâldău',
      'Gilău',
      'Gighera',
      'Giera',
      'Gâdinţi',
      'Giarmata-Vii',
      'Giarmata',
      'Ghizela',
      'Ghizdăvești',
      'Ghiroda',
      'Ghirdoveni',
      'Ghioșești',
      'Ghioroiu',
      'Ghioroc',
      'Ghiorac',
      'Ghinești',
      'Ghindeni',
      'Ghindari',
      'Ghindăreşti',
      'Ghindăoani',
      'Ghimpețeni',
      'Ghimpați',
      'Ghimeş-Făget',
      'Ghimeș',
      'Ghimbav',
      'Ghilad',
      'Ghighișeni',
      'Ghidigeni',
      'Ghidici',
      'Ghidfalău',
      'Gheaba',
      'Gherteniș',
      'Gherţa Mică',
      'Gherța Mare',
      'Ghermănești',
      'Gherla',
      'Gherghiţa',
      'Ghergheşti',
      'Ghergheasa',
      'Gherăseni',
      'Gherăeşti',
      'Gheorgheni',
      'Gheorghieni',
      'Gheorghe Lazăr',
      'Onesti',
      'Gheorghe Doja',
      'Ghelinţa',
      'Ghelari',
      'Gheja',
      'Gheboieni',
      'Gheboaia',
      'Gepiu',
      'Gioseni',
      'George Enescu',
      'Geoagiu',
      'Gemeni',
      'Gemenele',
      'Gemenea Brătulești',
      'Gelu',
      'Geamăna',
      'Geaca',
      'Găzărie',
      'Gavojdia',
      'Găvănești',
      'Găujani',
      'Gătaia',
      'Garvăn',
      'Garoafa',
      'Gărăgău',
      'Dâlga-Gară',
      'Găneşti',
      'Gănești',
      'Găneasa',
      'Galșa',
      'Galoșpetreu',
      'Galiciuica',
      'Galicea Mare',
      'Galicea',
      'Găleşti',
      'Galda de Jos',
      'Gălbinaşi',
      'Gălbinași',
      'Galbenu',
      'Galbeni',
      'Gălăuţaş',
      'Galaţii Bistriţei',
      'Galaţi',
      'Gălăţeni',
      'Gălășești',
      'Gălăneşti',
      'Găiseni',
      'Găinești',
      'Găiceana',
      'Găgeşti',
      'Găgești',
      'Găgeni',
      'Găeşti',
      'Furtunești',
      'Furculești',
      'Furcenii Noi',
      'Fundu Răcăciuni',
      'Fundu Moldovei',
      'Fundulea',
      'Fundeni',
      'Fundata',
      'Fulga de Sus',
      'Fulga de Jos',
      'Frunzi',
      'Fruntișeni',
      'Frumuşiţa',
      'Frumuşica',
      'Frumușelu',
      'Frumuşani',
      'Frumosu',
      'Frumoasa',
      'Frecăţei',
      'Fratoștița',
      'Frăteşti',
      'Frătăuţii Vechi',
      'Frătăuţii Noi',
      'Frata',
      'Frăsinet',
      'Frasin',
      'Forotic',
      'Forău',
      'Forăşti',
      'Folteşti',
      'Foglaş',
      'Foieni',
      'Foeni',
      'Focuri',
      'Focșani',
      'Floroaia',
      'Florica',
      'Floreşti',
      'Florești',
      'Flămânzi',
      'Fizeşu Gherlii',
      'Fizeș',
      'Fitioneşti',
      'Fâstâci',
      'Fișer',
      'Fârţăneşti',
      'Fârliug',
      'Fârdea',
      'Fântânele',
      'Fântâna Mare',
      'Finta Mare',
      'Finiş',
      'Filipeştii de Târg',
      'Filipeştii de Pădure',
      'Filipeşti',
      'Filipeni',
      'Filioara',
      'Filiaşi',
      'Filiaș',
      'Filia',
      'Fildu de Jos',
      'Fierbinţi-Târg',
      'Fierbinții de Sus',
      'Fierbinții de Jos',
      'Fierbinți',
      'Fieni',
      'Fibiș',
      'Fetești-Gară',
      'Fetești',
      'Feteşti',
      'Ferești',
      'Ferestrău-Oituz',
      'Feneș',
      'Felnac',
      'Feliceni',
      'Feleacu',
      'Feldru',
      'Feldioara',
      'Feisa',
      'Făureşti',
      'Făurei',
      'Fărtăţeşti',
      'Fărcașu de Jos',
      'Fărcaș',
      'Fărcăşeşti',
      'Fărcășeni',
      'Fărcaşele',
      'Fărcaşa',
      'Fărău',
      'Faraoaní',
      'Fărăgău',
      'Fălticeni',
      'Fălcoiu',
      'Fălciu',
      'Falcău',
      'Falaștoaca',
      'Făgetu',
      'Făgeţelu',
      'Făget',
      'Făgăraș',
      'Făclia',
      'Făcăi',
      'Făcăeni',
      'Ezeriş',
      'Estelnic',
      'Eşelniţa',
      'Ernei',
      'Eremitu',
      'Erbiceni',
      'Iepureşti',
      'Epureni',
      'Enisala',
      'Emil Racoviță',
      'Eliseni',
      'Eftimie Murgu',
      'Eforie Sud',
      'Eforie Nord',
      'Eforie',
      'Iedera de Sus',
      'Iedera de Jos',
      'Durneşti',
      'Dunăreni',
      'Cârna',
      'Dumitrița',
      'Dumitrești',
      'Dumitra',
      'Dumeşti',
      'Dumeni',
      'Dumbrăvița',
      'Dumbrăviţa',
      'Dumbrăveşti',
      'Dumbrăveni',
      'Dumbrava Roşie',
      'Dumbrava de Sus',
      'Dumbrava',
      'Dulceşti',
      'Dulcești',
      'Dudu',
      'Dudeştii Vechi',
      'Dudeștii Noi',
      'Dudeşti',
      'Dudașu',
      'Duda',
      'Dubova',
      'Drobeta-Turnu Severin',
      'Băile Drânceni',
      'Drânceni',
      'Dridu',
      'Drauț',
      'Dranovățu',
      'Drănic',
      'Drajna Nouă',
      'Drajna de Sus',
      'Drajna de Jos',
      'Drăguţeşti',
      'Drăguşeni',
      'Drăgușeni',
      'Drăguș',
      'Drăgugești',
      'Dragu',
      'Drăgoteşti',
      'Dragosloveni',
      'Dragoș Vodă',
      'Dragomireşti-Vale',
      'Dragomirești-Deal',
      'Dragomireşti',
      'Dragomir',
      'Drăgoeşti-Snagov',
      'Drăgoiești',
      'Drăgoeşti',
      'Drăgoești',
      'Dragodănești',
      'Dragodana',
      'Drăghinești',
      'Drăghici',
      'Drăghiceni',
      'Drăgeşti',
      'Drăgăşani',
      'Drăganu-Olteni',
      'Drăgăneşti-Vlaşca',
      'Drăgăneşti-Olt',
      'Drăgăneşti de Vede',
      'Drăgănești',
      'Drăgăneşti',
      'Drăgăneasa',
      'Dragalina',
      'Drăgăești Ungureni',
      'Draxini',
      'Dracșani',
      'Drăcşani',
      'Dracea',
      '23 August',
      'Doştat',
      'Dorolţ',
      'Dorohoi',
      'Dorobanţu',
      'Dorobanțu',
      'Dorobanți',
      'Dorneşti',
      'Dorna Cândrenilor',
      'Dor Mărunt',
      'Dorgoş',
      'Domnița',
      'Domnești-Târg',
      'Domneşti-Sârbi',
      'Domneşti',
      'Domnești',
      'Domaşnea',
      'Domănești',
      'Doljeşti',
      'Dolheștii Mici',
      'Dolheștii-Mari',
      'Dolhești',
      'Dolheşti',
      'Dolhasca',
      'Doiceşti',
      'Dognecea',
      'Dofteana',
      'Doftana',
      'Dodești',
      'Dodeni',
      'Ștei',
      'Doclin',
      'Dochia',
      'Dobrușa',
      'Dobrun',
      'Dobrovăţ',
      'Dobrotu',
      'Dobrotinet',
      'Dobroteşti',
      'Dobrotești',
      'Dobroteasa',
      'Dobrosloveni',
      'Dobromir',
      'Dobroeşti',
      'Dobrița',
      'Dobrin',
      'Dobridor',
      'Dobric',
      'Dobreţu',
      'Dobreşti',
      'Dobreni',
      'Dobra',
      'Doboșeni',
      'Dobârlău',
      'Dobârceni',
      'Doba',
      'Doanca',
      'Ditrău',
      'Dițești',
      'Dârza',
      'Dârvari',
      'Dârlos',
      'Dârjiu',
      'Dioşti',
      'Dioșod',
      'Diosig',
      'Dângeni',
      'Hurdugi',
      'Dâmbu',
      'Dâmbroca',
      'Dâmbovicioara',
      'Dâmbău',
      'Dimăcheni',
      'Dieci',
      'Diculești',
      'Dichiseni',
      'Dezna',
      'Dezmir',
      'Deveselu',
      'Devesel',
      'Deva',
      'Deta',
      'Deseşti',
      'Desa',
      'Derșida',
      'Dersca',
      'Derna',
      'Deparați',
      'Denta',
      'Densuş',
      'Demacușa',
      'Beznea',
      'Deleşti',
      'Deleni',
      'Dejești',
      'Deja',
      'Dej',
      'Dedulești',
      'Dedrad',
      'Deda',
      'Decindeni',
      'Decebal',
      'Dealu Morii',
      'Dealu Mare',
      'Dealu Frumos',
      'Dealu Dănicei',
      'Dealu Aluniș',
      'Dealu',
      'Deaj',
      'Davideşti',
      'Dascălu',
      'Dăscălești',
      'Darova Nouă',
      'Darova',
      'Dărmăneşti',
      'Dărăști-Vlașca',
      'Dărăşti-Ilfov',
      'Darabani',
      'Dara',
      'Dăneţi',
      'Dăneşti',
      'Daneş',
      'Dăneasa',
      'Dancu',
      'Dănciuleşti',
      'Dănceu',
      'Dămuc',
      'Dămoc',
      'Dămieneşti',
      'Dămăcușeni',
      'Dalnic',
      'Dalboșeț',
      'Daia Română',
      'Daia',
      'Dagâţa',
      'Dăești',
      'Dăeşti',
      'Dăeni',
      'Dăbuleni',
      'Dăbâca',
      'Cuza Vodă',
      'Cuzăplac',
      'Cuzap',
      'Cuvin',
      'Cut',
      'Cuzdrioara',
      'Curtuișeni',
      'Curtişoara',
      'Curtici',
      'Curteşti',
      'Curtea de Argeş',
      'Curtea',
      'Curcani',
      'Curăţele',
      'Cupşeni',
      'Cungrea',
      'Cumpăna',
      'Culciu Mic',
      'Cujmir',
      'Cugir',
      'Cuejdiu',
      'Cudalbi',
      'Cucuteni',
      'Cucuruzu',
      'Cucuieți',
      'Cucueți',
      'Cuciulata',
      'Cuci',
      'Cucerdea',
      'Cuca',
      'Cubulcut',
      'Crușovu',
      'Crușeț',
      'Crucişor',
      'Crucea',
      'Crovu',
      'Croitori',
      'Crizbav',
      'Crivina',
      'Crivești',
      'Crivăț',
      'Cristuru Secuiesc',
      'Cristur',
      'Cristolţ',
      'Criştioru de Jos',
      'Cristineşti',
      'Cristian',
      'Cristeștii Ciceului',
      'Cristeşti',
      'Cristești',
      'Crişeni',
      'Crişcior',
      'Crişan',
      'Crângurile de Sus',
      'Crângu',
      'Crângeni',
      'Crâmpoia',
      'Criciova',
      'Cricău',
      'Crevenicu',
      'Crevedia Mare',
      'Crevedia',
      'Crețești',
      'Creţeni',
      'Creaca',
      'Crasna Vișeului',
      'Crasna',
      'Craiva',
      'Craiova',
      'Crăieşti',
      'Craidorolţ',
      'Crăiești',
      'Crăciunești',
      'Crăciuneşti',
      'Crăciunelu de Jos',
      'Crăciunei',
      'Cracăul Negru',
      'Crăcăoani',
      'Cozmeşti',
      'Cozmești',
      'Cozmeni',
      'Cozma',
      'Cozieni',
      'Cozia',
      'Covrigi',
      'Covasna',
      'Covăsinţ',
      'Cotu Vameș',
      'Coţuşca',
      'Cotumba',
      'Coțofenii din Față',
      'Coţofenii din Dos',
      'Coţofăneşti',
      'Cotnari',
      'Cotmeana',
      'Coteşti',
      'Cotenești',
      'Coteana',
      'Coțatcu',
      'Coșula',
      'Costuleni',
      'Costişa',
      'Costineşti',
      'Costâna',
      'Costeştii din Vale',
      'Costeștii din Deal',
      'Costești',
      'Costeşti',
      'Coşteiu',
      'Costache Negri',
      'Coşoveni',
      'Cosoba',
      'Coșna',
      'Cosmina de Jos',
      'Cosmeștii-Vale',
      'Cosmeşti',
      'Cosâmbeşti',
      'Coşeşti',
      'Coşereni',
      'Coşeiu',
      'Coşbuc',
      'Coșani',
      'Corund',
      'Corunca',
      'Coropceni',
      'Coroisânmartin',
      'Coroieşti',
      'Coroieni',
      'Corod',
      'Corocăiești',
      'Cornuțel',
      'Cornu Luncii',
      'Cornu de Sus',
      'Cornu de Jos',
      'Corni-Albești',
      'Corni',
      'Cornetu',
      'Corneşti',
      'Cornești',
      'Cornereva',
      'Cornea',
      'Cornăţelu',
      'Cornățel',
      'Cormaia',
      'Corlătești',
      'Corlăteni',
      'Corlăţel',
      'Cordun',
      'Cordău',
      'Cordăreni',
      'Corcova',
      'Corbu Nou',
      'Corbu',
      'Corbiţa',
      'Corbii Mari',
      'Corbi',
      'Corbeni',
      'Corbeanca',
      'Corbasca',
      'Corabia',
      'Copşa Mică',
      'Copalnic Mănăştur',
      'Copălău',
      'Copaciu',
      'Copăceni',
      'Copăcelu',
      'Copăcele',
      'Copăcel',
      'Conțești',
      'Conţeşti',
      'Constantin Daicoviciu',
      'Constanţa',
      'Conop',
      'Conduratu',
      'Condeești',
      'Conceşti',
      'Comoșteni',
      'Comloşu Mare',
      'Comişani',
      'Comarnic',
      'Comarna',
      'Comani',
      'Comănești',
      'Comăneşti',
      'Comandău',
      'Comanda',
      'Comanca',
      'Comana de Sus',
      'Comana de Jos',
      'Comana',
      'Colţi',
      'Coltău',
      'Colonia Fabricii',
      'Colonia Bod',
      'Coloneşti',
      'Colibași',
      'Colibaşi',
      'Colelia',
      'Colceag',
      'Colacu',
      'Cojocna',
      'Cojasca',
      'Cogeasca',
      'Cogealac',
      'Codlea',
      'Codăeşti',
      'Cocu',
      'Cocoreni',
      'Cocorăștii Mislii',
      'Cocorăștii Colț',
      'Cocora',
      'Coconi',
      'Cociuba Mare',
      'Cociu',
      'Cochirleanca',
      'Cobadin',
      'Coaș',
      'Coarnele Caprei',
      'Coada Izvorului',
      'Cluj-Napoca',
      'Cloșani',
      'Clocotici',
      'Clit',
      'Clinceni',
      'Clejani',
      'Cleja',
      'Cleanov',
      'Cizer',
      'Ciutelec',
      'Ciușlea',
      'Ciuruleasa',
      'Ciurila',
      'Ciurea',
      'Ciupercenii Vechi',
      'Ciupercenii Noi',
      'Ciuperceni',
      'Ciumești',
      'Ciumeghiu',
      'Ciumbrud',
      'Ciumani',
      'Ciulnița',
      'Ciulniţa',
      'Ciugud',
      'Ciugheș',
      'Ciudanoviţa',
      'Ciucurova',
      'Ciucsângeorgiu',
      'Ciuchici',
      'Ciucea',
      'Ciucani',
      'Ciuani',
      'Câţcău',
      'Cisnădie',
      'Cișmele',
      'Cislău',
      'Cârţişoara',
      'Cârţa',
      'Cârniceni',
      'Cârlogani',
      'Cârligi',
      'Cârligele',
      'Cârlibaba',
      'Cârjoaia',
      'Cârjiţi',
      'Cârja',
      'Cireşu',
      'Cireșoaia',
      'Cârcea',
      'Ciprian Porumbescu',
      'Cipău',
      'Ciorteşti',
      'Cioroiași',
      'Ciorogârla',
      'Cioroboreni',
      'Ciorăști',
      'Ciorăşti',
      'Cioranii de Sus',
      'Cioranii de Jos',
      'Ciorani',
      'Ciomăgeşti',
      'Ciolpani',
      'Ciohorăni',
      'Ciofrângeni',
      'Ciofliceni',
      'Ciocârlia de Sus',
      'Ciocârlia',
      'Ciocile',
      'Ciochiuța',
      'Ciochina',
      'Ciocani',
      'Ciocănești',
      'Cicănești',
      'Ciocăneşti',
      'Ciocănari',
      'Ciobanu',
      'Cintei',
      'Cândeşti Vale',
      'Cândeşti',
      'Cincu',
      'Câmpurile de Jos',
      'Câmpuri',
      'Câmpurelu',
      'Câmpulung Moldovenesc',
      'Câmpulung la Tisa',
      'Câmpulung',
      'Câmpineanca',
      'Câmpina',
      'Câmpia Turzii',
      'Câmpeni',
      'Câmpani',
      'Câmpani de Pomezeu',
      'Câlnic',
      'Cilieni',
      'Cilibia',
      'Câinenii Mici',
      'Cidreag',
      'Ciclova-Română',
      'Cicârlău',
      'Ciceu-Mihăiești',
      'Ciceu-Giurgeşti',
      'Ciceu',
      'Ciacova',
      'Chiuza',
      'Chiuiești',
      'Chițorani',
      'Chițoc',
      'Chitila',
      'Chișoda',
      'Chişlaz',
      'Chişineu-Criş',
      'Chisindia',
      'Chiselet',
      'Chișcăreni',
      'Chiscani',
      'Chirpăr',
      'Chirnogi',
      'Chirnogeni',
      'Chiriacu',
      'Chircești',
      'Chiraftei',
      'Chiojdu',
      'Chiojdeni',
      'Chiojdeanca',
      'Chiochiş',
      'Chintinici',
      'Chinteni',
      'Chiliile',
      'Chilii',
      'Chilia Veche',
      'Chiheru de Jos',
      'Chieşd',
      'Chier',
      'Chichiş',
      'Chibed',
      'Chiajna',
      'Chevereşu Mare',
      'Cheţani',
      'Cheț',
      'Chesinț',
      'Cheșereu',
      'Chereluș',
      'Cherechiu',
      'Chendu',
      'Chendrea',
      'Chelința',
      'Cheia',
      'Checea',
      'Cezieni',
      'Cetăţeni',
      'Cetatea de Baltă',
      'Cetatea',
      'Cetate',
      'Cetariu',
      'Cervenia',
      'Ceru-Băcăinţi',
      'Certeze',
      'Cerţeşti',
      'Certeju de Sus',
      'Cernu',
      'Cernişoara',
      'Cernica',
      'Cernetu',
      'Cerneți',
      'Cerneşti',
      'Cernele',
      'Cernavodă',
      'Cernăteşti',
      'Cernat',
      'Cerna',
      'Cermei',
      'Cergău Mic',
      'Cergău Mare',
      'Didești',
      'Cerdac',
      'Ceardac',
      'Cerchezu',
      'Cerbăl',
      'Cerăt',
      'Ceraşu',
      'Ceptura de Sus',
      'Ceptura de Jos',
      'Cepleniţa',
      'Ceparii Pământeni',
      'Cepari',
      'Cenei',
      'Cenade',
      'Cenad',
      'Celaru',
      'Ceica',
      'Cehu Silvaniei',
      'Cehei',
      'Cehal',
      'Cegani',
      'Cefa',
      'Ceuașu de Câmpie',
      'Ceauru',
      'Ceatalchioi',
      'Ceanu Mare',
      'Ceamurlia de Sus',
      'Ceamurlia de Jos',
      'Ceahlău',
      'Ceacu',
      'Cazasu',
      'Căzăneşti',
      'Cazaci',
      'Cavnic',
      'Cavadineşti',
      'Căuaş',
      'Cătunele',
      'Cătina',
      'Cățelu',
      'Căteasca',
      'Catanele',
      'Catane',
      'Cătămărești-Deal',
      'Cataloi',
      'Catalina',
      'Caţa',
      'Castrele Traiane',
      'Castranova',
      'Castelu',
      'Căstău',
      'Cașoca',
      'Caşin',
      'Casimcea',
      'Căşeiu',
      'Căscioarele',
      'Cartojani',
      'Cărpiniș',
      'Cărpiniş',
      'Cărpinet',
      'Carpen',
      'C.A. Rosetti',
      'C.a. Rosetti',
      'Carei',
      'Carcaliu',
      'Cărbuneşti',
      'Cărbunari',
      'Caraula',
      'Carastelec',
      'Caraşova',
      'Cărășeu',
      'Caransebeş',
      'Carani',
      'Cărand',
      'Caraclău',
      'Caracal',
      'Căpuşu Mare',
      'Capu Piscului',
      'Capu Codrului',
      'Capu Câmpului',
      'Căprioru',
      'Căpreni',
      'Caporal Alexa',
      'Căpleni',
      'Căpâlniţa',
      'Căpâlna',
      'Căpeni',
      'Căpățânești',
      'Căpățânenii Pământeni',
      'Căneşti',
      'Cămin',
      'Cămărzana',
      'Cămăraşu',
      'Camăr',
      'Calvini',
      'Călui',
      'Călugăreni',
      'Calopăr',
      'Calomfirești',
      'Călmăţuiu',
      'Călineşti-Oaş',
      'Călinești',
      'Călineşti',
      'Călina',
      'Călimăneşti',
      'Căldărăști',
      'Căldăraru',
      'Călăţele',
      'Călan',
      'Calafindeşti',
      'Calafat',
      'Călacea',
      'Cajvana',
      'Căiuţi-Sat',
      'Căiuți',
      'Căianu Mic',
      'Căianu',
      'Cadea',
      'Căciulați',
      'Cacica',
      'Căbeşti',
      'Buzoeşti',
      'Buznea',
      'Buziaş',
      'Buzescu',
      'Buzău',
      'Buza',
      'Buturugeni',
      'Butoieşti',
      'Butimanu',
      'Buteni',
      'Butea',
      'Bustuchin',
      'Buşteni',
      'Bușca',
      'Burzuc',
      'Burueneşti',
      'Buruienești',
      'Bursuceni',
      'Burla',
      'Burjuc',
      'Burila Mare',
      'Buriaș',
      'Burca',
      'Bunteşti',
      'Bunila',
      'Bungetu',
      'Bunești',
      'Buneşti',
      'Bumbeşti-Jiu',
      'Bulzeștii de Sus',
      'Bulzeşti',
      'Bulz',
      'Buliga',
      'Bulgăruș',
      'Bulbucata',
      'Bujoru',
      'Bujoreni',
      'Târgu Bujor',
      'Buhuşi',
      'Buhoci',
      'Buhalnița',
      'Bughea de Sus',
      'Bughea de Jos',
      'Buftea',
      'Buești',
      'Buduslău',
      'Budureasa',
      'Budila',
      'Budeşti',
      'Budești',
      'Budeni',
      'Budeasa Mică',
      'Budeasa',
      'Budăi',
      'Budacu de Sus',
      'Budacu de Jos',
      'Buda',
      'Bucharest',
      'Bucureşci',
      'Bucu',
      'Bucșoaia',
      'Bucșești',
      'Bucșenești-Lotași',
      'Bucșenești',
      'Bucşani',
      'Bucovineni',
      'Bucovăț',
      'Bucovăţ',
      'Bucova',
      'Bucov',
      'Bucoşniţa',
      'Bucium-Orlea',
      'Buciumi',
      'Buciumeni',
      'Bucium',
      'Bucinişu',
      'Buchin',
      'Bucești',
      'Buceş',
      'Bucerdea-Grânoasă',
      'Bucecea',
      'Brusturoasa',
      'Brusturi',
      'Bruiu',
      'Broșteni',
      'Broşteni',
      'Broscăuţi',
      'Brodoc',
      'Brodina',
      'Brâncoveni',
      'Brâncoveneşti',
      'Brâncoveanca',
      'Brânceni',
      'Brezoi',
      'Brezoaele',
      'Brezoaia',
      'Brezniţa Ocol',
      'Brezniţa-Motru',
      'Bretea Română',
      'Breţcu',
      'Brestovăț',
      'Brehuiești',
      'Brebu Nou',
      'Brebu Mânăstirei',
      'Brebu Megieșesc',
      'Brebu',
      'Brebeni',
      'Breb',
      'Breazu',
      'Breaza de Sus',
      'Breaza de Jos',
      'Breaza',
      'Breasta',
      'Brazii de Sus',
      'Brazii',
      'Rădulești',
      'Brazi',
      'Bratovoești',
      'Brătila',
      'Brătești',
      'Brateş',
      'Brateiu',
      'Bratca',
      'Brastavățu',
      'Braşov',
      'Braniştea',
      'Braniștea',
      'Brăniștari',
      'Brănişca',
      'Braneț',
      'Brăneşti',
      'Bran',
      'Braloştiţa',
      'Brăila',
      'Brăhăşeştii de Sus',
      'Brăhășești',
      'Bragadiru',
      'Brăeşti',
      'Brăiești',
      'Brăduţ',
      'Brăduleţ',
      'Bradu',
      'Brădicești',
      'Brădeşti',
      'Brădeni',
      'Brădeanu',
      'Brad',
      'Brabova',
      'Brabeți',
      'Bozovici',
      'Bozioru',
      'Bozieni',
      'Boureni',
      'Botoşeşti-Paia',
      'Botoşani',
      'Botoşana',
      'Botoroaga',
      'Botiza',
      'Botiz',
      'Botești',
      'Boţeşti',
      'Boteni',
      'Boşorod',
      'Bosia',
      'Bosanci',
      'Borsec',
      'Borşa',
      'Borş',
      'Borosoaia',
      'Boroşneu Mare',
      'Borod',
      'Boroaia',
      'Borlova',
      'Borleşti',
      'Borduşani',
      'Bordeşti',
      'Bordenii Mari',
      'Bordei Verde',
      'Bordeasca Veche',
      'Borcut',
      'Borcea',
      'Borca',
      'Borăscu',
      'Borănești',
      'Bolvașnița',
      'Bolovăniș',
      'Boloteşti',
      'Bolintin Vale',
      'Bolintin Deal',
      'Boldur',
      'Boldu',
      'Boldeşti-Scăeni',
      'Boldeşti',
      'Bolboşi',
      'Bolătău',
      'Boiu Mare',
      'Boiu',
      'Boița',
      'Boişoara',
      'Boinești',
      'Boianu Mare',
      'Boian',
      'Bohotin',
      'Bogza',
      'Boghiș',
      'Boghicea',
      'Bogheşti',
      'Bogei',
      'Bogdan Vodă',
      'Bogdăniţa',
      'Bogdăneşti',
      'Bogdănești',
      'Bogdand',
      'Bogdana',
      'Bogda',
      'Bogaţi',
      'Bogata',
      'Bodoc',
      'Bodeștii de Jos',
      'Bodeşti',
      'Bodești',
      'Bod',
      'Bocsig',
      'Bocşa',
      'Bocicoiu Mare',
      'Bobulești',
      'Bobota',
      'Bobolia',
      'Bobohalma',
      'Boboiești',
      'Boboc',
      'Bobâlna',
      'Bobiceşti',
      'Blânzi',
      'Blândiana',
      'Blândeşti',
      'Blideşti',
      'Blejoi',
      'Blejeşti',
      'Bălcaciu',
      'Blăjeni',
      'Blăjel',
      'Blăjani',
      'Blaj',
      'Blăgeşti',
      'Bixad',
      'Bivolărie',
      'Bivolari',
      'Bistriţa Bârgăului',
      'Bistriţa',
      'Bistrița',
      'Bistreț',
      'Bistra Mureșului',
      'Bistra',
      'Bisoca',
      'Bâscoveni',
      'Bâscenii de Sus',
      'Bâscenii de Jos',
      'Bâsca Rozilei',
      'Bâsca Chiojdului',
      'Bârzești',
      'Bârzava',
      'Bârza',
      'Bârseşti',
      'Bârsău de Sus',
      'Bârsăneşti',
      'Bârsana',
      'Bârsa',
      'Bârnova',
      'Bârna',
      'Bârlogu',
      'Bârlad',
      'Bârla',
      'Bârghiş',
      'Birda',
      'Bircii',
      'Birchiş',
      'Bârca',
      'Bâra',
      'Bâlvăneşti',
      'Bâlteni',
      'Bâlta',
      'Biliești',
      'Biled',
      'Bâldana',
      'Bilciureşti',
      'Bilca',
      'Bilbor',
      'Biia',
      'Biharia',
      'Biertan',
      'Bâcu',
      'Bâcleș',
      'Bichiş',
      'Bichigiu',
      'Bicazu Ardelean',
      'Bicaz-Chei',
      'Bicaz',
      'Bezdead',
      'Beuca',
      'Bethausen',
      'Beștepe',
      'Berzunţi',
      'Berzovia',
      'Berzasca',
      'Berveni',
      'Berteştii de Jos',
      'Bertea',
      'Berlişte',
      'Berleşti',
      'Beriu',
      'Berislăveşti',
      'Berghin',
      'Berezeni',
      'Berevoeşti',
      'Berești',
      'Bereşti-Tazlău',
      'Bereşti-Sat',
      'Bereşti-Bistriţa',
      'Bereni',
      'Beregsău Mare',
      'Berchișești',
      'Berceni',
      'Berca',
      'Berbești',
      'Berbeşti',
      'Bengești',
      'Benesat',
      'Beltiug',
      'Beliu',
      'Beliş',
      'Belin-Vale',
      'Belinţ',
      'Belin',
      'Beleţi',
      'Belciugatele',
      'Belceşti',
      'Beiuş',
      'Beiu',
      'Beidaud',
      'Beica de Jos',
      'Beclean',
      'Beciu',
      'Becicherecu Mic',
      'Bechet',
      'Beceni',
      'Beba Veche',
      'Bazna',
      'Băuţar',
      'Bătrâni',
      'Bătrâna',
      'Batoş',
      'Batârăşti',
      'Bătești',
      'Bătarci',
      'Batăr',
      'Băţanii Mari',
      'Bata',
      'Băseşti',
      'Başcov',
      'Basarabi',
      'Barza',
      'Baru Mic',
      'Baru',
      'Barticești',
      'Bârgăuani',
      'Barcea',
      'Barcani',
      'Bărcăneşti',
      'Bărbuleţu',
      'Bărbulești',
      'Bărbăteşti',
      'Barați',
      'Bărăștii de Vede',
      'Bărăști',
      'Baraolt',
      'Bărăganul',
      'Bărăganu',
      'Bărăbanț',
      'Bara',
      'Banloc',
      'Băniţa',
      'Bănişor',
      'Bănia',
      'Bănești',
      'Băneşti',
      'Băneasa',
      'Band',
      'Bancu',
      'Banca',
      'Bălușești',
      'Băluşeni',
      'Bălțești',
      'Bădeana',
      'Bălteni',
      'Balta Verde',
      'Bălţaţi',
      'Bălţăteşti',
      'Balta Doamnei',
      'Balta Albă',
      'Balta',
      'Balşa',
      'Balș',
      'Balş',
      'Baloteşti',
      'Bălnaca',
      'Balintești',
      'Balinţ',
      'Bălileşti',
      'Băleşti',
      'Băleni Sârbi',
      'Băleni',
      'Baldovinești',
      'Baldovineşti',
      'Balda',
      'Bălcești',
      'Bălceşti',
      'Bălcăuţi',
      'Balcani',
      'Balc',
      'Bălăuşeri',
      'Bălăşeşti',
      'Bălănești',
      'Bălan',
      'Balaciu',
      'Bălăciţa',
      'Balaci',
      'Bălăceanu',
      'Bălăceanca',
      'Bălăceana',
      'Bălăbănești',
      'Băla',
      'Bala',
      'Bajura',
      'Băjești',
      'Băiuţ',
      'Băiţa de sub Codru',
      'Băița',
      'Băiţa',
      'Băişoara',
      'Băișești',
      'Băile Tuşnad',
      'Băileşti',
      'Băile Olăneşti',
      'Băile Herculane',
      'Băile Govora',
      'Băile Borșa',
      'Băiculeşti',
      'Băicoi',
      'Baia Sprie',
      'Baia Mare',
      'Baia de Fier',
      'Baia de Criş',
      'Baia de Arieş',
      'Baia de Aramă',
      'Baia',
      'Bahnea',
      'Bahna',
      'Băgaciu',
      'Băduleasa',
      'Bădoși',
      'Bădeuți',
      'Bădești',
      'Bădeni',
      'Bacova',
      'Baciu',
      'Băcioiu',
      'Băcia',
      'Băceşti',
      'Bacea',
      'Bacău',
      'Băcani',
      'Băcălești',
      'Babța',
      'Băbiciu',
      'Babeţi',
      'Băbeni-Oltețu',
      'Băbeni',
      'Băbana',
      'Băbăiţa',
      'Babadag',
      'Baba Ana',
      'Azuga',
      'Axintele',
      'Axente Sever',
      'Avrig',
      'Avram Iancu',
      'Avrămeşti',
      'Avrămeni',
      'Auşeu',
      'Augustin',
      'Aţintiş',
      'Atid',
      'Aţel',
      'Asuaju de Sus',
      'Aştileu',
      'Aşchileu Dorna',
      'Asău',
      'Arsura',
      'Arpașu de Sus',
      'Arpaşu de Jos',
      'Aroneanu',
      'Armeniş',
      'Armăşeşti',
      'Ariniş',
      'Arieşeni',
      'Ariceștii Zeletin',
      'Ariceştii-Rahtivani',
      'Arghira',
      'Argetoaia',
      'Argeșelu',
      'Argel',
      'Arefu',
      'Ardusat',
      'Ardud',
      'Ardeoani',
      'Arcuș',
      'Archiş',
      'Arcani',
      'Arbore',
      'Arad',
      'Araci',
      'Aprozi',
      'Apostolache',
      'Apoldu de Jos',
      'Apold',
      'Apele Vii',
      'Apateu',
      'Apaţa',
      'Apalina',
      'Apahida',
      'Apa Asău',
      'Apa',
      'Aninoasa',
      'Anina',
      'Anieș',
      'Anghelești',
      'Andrieşeni',
      'Andrid',
      'Andrei Șaguna',
      'Andreiaşu de Jos',
      'Andreești',
      'Andrăşeşti',
      'Amzacea',
      'Amaru',
      'Amărăştii de Sus',
      'Amărăştii de Jos',
      'Amărăşti',
      'Amara',
      'Alunu',
      'Alunișu',
      'Aluniş',
      'Alțâna',
      'Alparea',
      'Almaşu Mare',
      'Almaşu',
      'Almaş',
      'Almăj',
      'Alma',
      'Alimpeşti',
      'Alimănești',
      'Aliman',
      'Alexeni',
      'Alexandru Vlăhuţă',
      'Alexandru Odobescu',
      'Alexandru I. Cuza',
      'Alexandria',
      'Aleşd',
      'Albota',
      'Albota de Jos',
      'Albiș',
      'Albeștii Ungureni',
      'Albeşti-Paleologu',
      'Albești-Muru',
      'Albeştii Pământeni',
      'Albeşti',
      'Albeni',
      'Alba Iulia',
      'Albac',
      'Alba',
      'Alămor',
      'Aiudul de Sus',
      'Aiud',
      'Aiton',
      'Aita Mare',
      'Agrișu Mare',
      'Agriș',
      'Agrij',
      'Agrieș',
      'Agnita',
      'Agârbiciu',
      'Agighiol',
      'Agigea',
      'Aghireșu-Fabrici',
      'Aghireșu',
      'Aghireș',
      'Agăş',
      'Agapia',
      'Afumaţi',
      'Adunații-Copăceni',
      'Adunaţi',
      'Adjudu Vechi',
      'Adjudeni',
      'Adjud',
      'Adâncata',
      'Adânca',
      'Adea',
      'Adășeni',
      'Adămuş',
      'Adamclisi',
      'Acriș',
      'Acâş',
      'Acăţari',
      'Abrud-Sat',
      'Abrud',
      'Abrămuţ',
      'Abram',
      '1 Decembrie',
      'Vrani',
      'Vetrişoaia',
      'Moieciu de Jos',
      'Bucuresti',
      'Mârșa',
      'Paşcani',
      'Dorna-Arini',
      'Mioveni',
      'Uileacu de Beiuș',
      'Albeştii de Argeş',
      'Crișan',
      'Horea',
      'Frumușeni',
      'Dragoslavele',
      'Sighișoara',
      'Vințu de Jos',
      'Oarja',
      'Bijghir',
      'Sâniob',
      'Baranca',
      'Racoș',
      'Gropeni',
      'Constantin Brâncoveanu',
      'Bonțida',
      'Luna',
      'Culmea',
      'Valea Voievozilor',
      'Ocolna',
      'Hulubești',
      'Gurasada',
      'Toaca',
      'Ion Creangă',
      'Urzica',
      'Tarna Mare',
      'Călmățuiu de Sus',
      'Drăcșenei',
      'I. C. Brătianu',
      'Maliuc',
      'Sector 1',
      'Sector 2',
      'Sector 3',
      'Sector 4',
      'Sector 5',
      'Sector 6',
      'Haneș Mină',
    ],
  },
  {
    country: 'Serbia',
    cities: [
      'Zvečka',
      'Žujince',
      'Zrenjanin',
      'Žitorađa',
      'Žitište',
      'Zemun',
      'Zaječar',
      'Žagubica',
      'Žabari',
      'Žabalj',
      'Vršac',
      'Vrnjačka Banja',
      'Vranjska Banja',
      'Vranje',
      'Vranić',
      'Vračar',
      'Vojvoda Stepa',
      'Vojka',
      'Vlasotince',
      'Vlajkovac',
      'Vladimirovac',
      'Vladičin Han',
      'Vilovo',
      'Veliko Središte',
      'Veliko Gradište',
      'Veliki Gaj',
      'Aleksandrovo',
      'Velika Plana',
      'Velika Moštanica',
      'Velika Greda',
      'Varvarin',
      'Uzdin',
      'Umka',
      'Uljma',
      'Ugrinovci',
      'Ub',
      'Tutin',
      'Turija',
      'Trstenik',
      'Trgovište',
      'Torda',
      'Topola',
      'Tomaševac',
      'Toba',
      'Titel',
      'Taraš',
      'Svrljig',
      'Svilajnac',
      'Sutjeska',
      'Surdulica',
      'Surduk',
      'Surčin',
      'Stubline',
      'Stepojevac',
      'Stari Lec',
      'Stari Banovci',
      'Stara Pazova',
      'Stajićevo',
      'Srpski Itebej',
      'Srpska Crnja',
      'Sremčica',
      'Sopot',
      'Soko Banja',
      'Smederevska Palanka',
      'Smederevo',
      'Šimanovci',
      'Ševica',
      'Senta',
      'Seleuš',
      'Sefkerin',
      'Sečanj',
      'Sanad',
      'Samoš',
      'Samoljica',
      'Sakule',
      'Šajkaš',
      'Sajan',
      'Rušanj',
      'Ripanj',
      'Rekovac',
      'Ražanj',
      'Ravni Topolovac',
      'Rakovica',
      'Rajince',
      'Radovnica',
      'Radojevo',
      'Radenka',
      'Rača',
      'Prokuplje',
      'Preševo',
      'Požega',
      'Požarevac',
      'Plandište',
      'Pirot',
      'Petrovac',
      'Perlez',
      'Pavliš',
      'Paraćin',
      'Pančevo',
      'Padina',
      'Padej',
      'Ovča',
      'Ostružnica',
      'Ostojićevo',
      'Orlovat',
      'Opovo',
      'Obrenovac',
      'Novo Miloševo',
      'Novi Slankamen',
      'Novi Pazar',
      'Kozjak',
      'Novi Kozarci',
      'Novi Kneževac',
      'Novi Karlovci',
      'Novi Itebej',
      'Novi Beograd',
      'Novi Bečej',
      'Novi Banovci',
      'Nova Pazova',
      'Nova Crnja',
      'Niš',
      'Nikolinci',
      'Neuzina',
      'Negotin',
      'Nakovo',
      'Adorjan',
      'Mošorin',
      'Mol',
      'Mokrin',
      'Mladenovac',
      'Miratovac',
      'Mionica',
      'Mihajlovo',
      'Merošina',
      'Melenci',
      'Medveđa',
      'Međa',
      'Margita',
      'Malo Crniće',
      'Mala Moštanica',
      'Majdanpek',
      'Lukićevo',
      'Lugavčina',
      'Lučani',
      'Lokve',
      'Lok',
      'Ljukovo',
      'Ljig',
      'Leskovac',
      'Lebane',
      'Lazarevo',
      'Lazarevac',
      'Lapovo',
      'Lajkovac',
      'Kuštilj',
      'Kuršumlija',
      'Kupinovo',
      'Kumane',
      'Kučevo',
      'Kruševac',
      'Krčedin',
      'Kraljevo',
      'Krajišnik',
      'Kragujevac',
      'Kovin',
      'Kovilj',
      'Kovačica',
      'Konak',
      'Knićanin',
      'Klek',
      'Kladovo',
      'Kikinda',
      'Kanjiža',
      'Jermenovci',
      'Jazovo',
      'Jaša Tomić',
      'Jarkovac',
      'Janošik',
      'Jagodina',
      'Izbište',
      'Ivanjica',
      'Inđija',
      'Ilandža',
      'Idvor',
      'Iđoš',
      'Hetin',
      'Hajdučica',
      'Gudurica',
      'Grocka',
      'Grabovac',
      'Gornji Milanovac',
      'Gornji Breg',
      'Golubinci',
      'Golubac',
      'Gardinovci',
      'Gadžin Han',
      'Farkaždin',
      'Elemir',
      'Ečka',
      'Ðurđevo',
      'Duboka',
      'Donji Milanovac',
      'Doljevac',
      'Dobrica',
      'Dobanovci',
      'Dimitrovgrad',
      'Despotovac',
      'Debeljača',
      'Čurug',
      'Ćuprija',
      'Čukarica',
      'Crna Trava',
      'Crepaja',
      'Čortanovci',
      'Čoka',
      'Ćićevac',
      'Čestereg',
      'Čenta',
      'Čačak',
      'Bujanovac',
      'Botoš',
      'Bosilegrad',
      'Bor',
      'Boljevci',
      'Boljevac',
      'Boka',
      'Bojnik',
      'Bočar',
      'Blace',
      'Biljača',
      'Beška',
      'Belgrade',
      'Belo Blato',
      'Belegiš',
      'Bela Palanka',
      'Bela Crkva',
      'Torak',
      'Bečmen',
      'Bečej',
      'Batočina',
      'Bašaid',
      'Barice',
      'Barič',
      'Baranda',
      'Barajevo',
      'Banatsko Veliko Selo',
      'Banatsko Karađorđevo',
      'Banatski Karlovac',
      'Banatski Dvor',
      'Banatski Despotovac',
      'Banatska Topola',
      'Bačko Petrovo Selo',
      'Bačko Gradište',
      'Arilje',
      'Aranđelovac',
      'Aradac',
      'Alibunar',
      'Aleksinac',
      'Aleksandrovac',
      'Ada',
      'Raška',
      'Niška Banja',
      'Sokolovica',
      'Rusko Selo',
      'Voždovac',
      'Zvezdara',
      'Prislonica',
      'Knić',
      'Savski Venac',
      'Palilula',
      'Stari Grad',
      'Zminjak',
      'Zmajevo',
      'Vrdnik',
      'Vrbas',
      'Vladimirci',
      'Višnjićevo',
      'Veternik',
      'Varna',
      'Valjevo',
      'Uzveće',
      'Užice',
      'Tršić',
      'Temerin',
      'Tabanović',
      'Subotica',
      'Štitar',
      'Stepanovićevo',
      'Stanišić',
      'Sremski Karlovci',
      'Sremska Mitrovica',
      'Srbobran',
      'Sonta',
      'Sombor',
      'Sjenica',
      'Sinošević',
      'Šid',
      'Ševarice',
      'Šašinci',
      'Salaš Noćajski',
      'Salaš Crnobarski',
      'Šabac',
      'Rumenka',
      'Ruma',
      'Riđica',
      'Ribari',
      'Ravno Selo',
      'Ravnje',
      'Radenković',
      'Putinci',
      'Prnjavor',
      'Prijepolje',
      'Prigrevica',
      'Priboj',
      'Platičevo',
      'Petrovaradin',
      'Petkovica',
      'Pećinci',
      'Osečina',
      'Odžaci',
      'Obrovac',
      'Novo Selo',
      'Novi Sad',
      'Nova Varoš',
      'Nikinci',
      'Metković',
      'Mali Zvornik',
      'Mali Iđoš',
      'Majur',
      'Maglić',
      'Mačvanska Mitrovica',
      'Ljubovija',
      'Lipolist',
      'Lipnički Šor',
      'Lešnica',
      'Kupusina',
      'Kulpin',
      'Kula',
      'Krupanj',
      'Krivaja',
      'Kosjerić',
      'Kolut',
      'Koceljeva',
      'Klenje',
      'Klenak',
      'Kisač',
      'Kamenica',
      'Joševa',
      'Jevremovac',
      'Jelenča',
      'Jarebice',
      'Jarak',
      'Jadranska Lešnica',
      'Irig',
      'Hrtkovci',
      'Grabovci',
      'Gornja Bukovica',
      'Glušci',
      'Gložan',
      'Gakovo',
      'Dublje',
      'Drenovac',
      'Draginje',
      'Doroslovo',
      'Donji Dobrić',
      'Dobrić',
      'Despotovo',
      'Crna Bara',
      'Čokešina',
      'Čelarevo',
      'Čajetina',
      'Bukor',
      'Buđanovci',
      'Brdarica',
      'Bosut',
      'Bogosavac',
      'Bogojevo',
      'Bogatić',
      'Beočin',
      'Belotić',
      'Banovo Polje',
      'Bajina Bašta',
      'Badovinci',
      'Bački Petrovac',
      'Bački Breg',
      'Bačka Topola',
      'Bačka Palanka',
      'Bač',
      'Apatin',
      'Pocerski Pričinović',
      'Ðurići',
      'Zlatibor',
      'Sokolovo Brdo',
      'Osječenik',
      'Rumska',
      'Knjazevac',
      'Sumulicë',
      'Lagja e Poshtme',
    ],
  },
  {
    country: 'Russia',
    cities: [
      'Udomlya',
      'Sosnovka',
      'Sasovo',
      'Novozavidovskiy',
      'Krasnolesnyy',
      'Kosterevo',
      'Zyuzino',
      'Zyukayka',
      'Zykovo',
      'Zyablikovo',
      'Zvony',
      'Zverevo',
      'Zvenigovo',
      'Zvenigorod',
      'Zuyevka',
      'Zubtsov',
      'Zubovo',
      'Zubova Polyana',
      'Zolotukhino',
      'Zolotkovo',
      'Zol’noye',
      'Znamenskoye',
      'Znamensk',
      'Znamenka',
      'Zmiyëvka',
      'Zmeyskaya',
      'Zlynka',
      'Zlatoust',
      'Zirgan',
      'Zimovniki',
      'Zilair',
      'Zhulebino',
      'Zhukovskiy',
      'Zhukovo',
      'Zhukovka',
      'Zhizdra',
      'Zhiryatino',
      'Zhirnovsk',
      'Zhirnov',
      'Zhilëvo',
      'Zhiletovo',
      'Zhiguli',
      'Zhigulevsk',
      'Zheshart',
      'Zherdevka',
      'Zhemtala',
      'Zheleznovodsk',
      'Zheleznogorsk',
      'Zheleznodorozhnyy',
      'Zhdankovskiy',
      'Zhavoronki',
      'Zharkovskiy',
      'Zhankhoteko',
      'Zhadovka',
      'Zernograd',
      'Zenzeli',
      'Zemlyansk',
      'Zemetchino',
      'Zelyony Gorod',
      'Zelenokumsk',
      'Zelenogradskiy',
      'Zelenogradsk',
      'Zelenograd',
      'Zelenogorsk',
      'Zelenodolsk',
      'Zelenoborskiy',
      'Zelenets',
      'Zelenchukskaya',
      'Zayukovo',
      'Zaymishche',
      'Zavoronezhskoye',
      'Zavolzh’ye',
      'Zavolzhsk',
      'Masalovka',
      'Zavidovo',
      'Zavety Il’icha',
      'Zavetnoye',
      'Zaterechnyy',
      'Zasosna',
      'Zasechnoye',
      'Zarubino',
      'Zarechnyy',
      'Zaraysk',
      'Zaprudnya',
      'Zapolyarnyy',
      'Zaplavnoye',
      'Zapadnaya Dvina',
      'Zaokskiy',
      'Zandak',
      'Zamoskvorech’ye',
      'Zamishevo',
      'Zalukokoazhe',
      'Zalegoshch’',
      'Zakharovo',
      'Zakan-Yurt',
      'Zainsk',
      'Zagoryanskiy',
      'Zadonsk',
      'Zabolotovka',
      'Yuzhnyy-Kospashskiy',
      'Yuzhno-Sukhokumsk',
      'Yuzha',
      'Yushkozero',
      'Yuryuzan’',
      'Yur’yev-Pol’skiy',
      'Yur’yevets',
      'Yurovka',
      'Yurla',
      'Yurino',
      'Yumaguzino',
      'Yukhnov',
      'Yukamenskoye',
      'Yugo-Kamskiy',
      'Yuganets',
      'Yug',
      'Yubileynyy',
      'Yoshkar-Ola',
      'Yeyskoye Ukrepleniye',
      'Yeysk',
      'Yessentukskaya',
      'Yessentuki',
      'Yertsevo',
      'Yershovo',
      'Yershov',
      'Yershichi',
      'Yermolino',
      'Yermolayevo',
      'Yermish’',
      'Yermekeyevo',
      'Yepifan’',
      'Yenotayevka',
      'Yemva',
      'Yemtsa',
      'Yemetsk',
      'Yelshanka',
      'Yelovo',
      'Yel’nya',
      'Yëlkino',
      'Yelkhovka',
      'Yelizavetinskoye',
      'Yelizavetinskaya',
      'Yelizavetino',
      'Yelizavetinka',
      'Yelets',
      'Yel’digino',
      'Yelat’ma',
      'Yelan’-Kolenovskiy',
      'Yelan’-Koleno',
      'Yelan’',
      'Yelabuga',
      'Yekimovichi',
      'Yekaterinovka',
      'Yegor’yevsk',
      'Yegorlykskaya',
      'Yefremov',
      'Yefimovskiy',
      'Yedrovo',
      'Yazykovo',
      'Yayva',
      'Yavas',
      'Yasnyy',
      'Yasnogorsk',
      'Yashkul’',
      'Yashalta',
      'Yasenskaya',
      'Yasenevo',
      'Yartsevo',
      'Yaroslavskaya',
      'Yaroslavl',
      'Yaroslavichi',
      'Yaropolets',
      'Yarensk',
      'Yarega',
      'Yaransk',
      'Yar',
      'Yantikovo',
      'Yanis’yarvi',
      'Yanishpole',
      'Yandyki',
      'Yanaul',
      'Novaya Maka',
      'Yamkino',
      'Yambirno',
      'Yam',
      'Yalkhoy-Mokhk',
      'Yaksatovo',
      'Yakovlevskoye',
      'Yakovlevo',
      'Yakhroma',
      'Yagry',
      'Yadrin',
      'Yablonovskiy',
      'Vyyezdnoye',
      'Vytegra',
      'Vysotskoye',
      'Vysotsk',
      'Vysokovsk',
      'Vysokinichi',
      'Vysokaya Gora',
      'Vyshniy Volochëk',
      'Vyshneye Dolgoye',
      'Vyshkov',
      'Vysha',
      'Vyselki',
      'Vyritsa',
      'Vypolzovo',
      'Vyksa',
      'Vykhino-Zhulebino',
      'Vygonichi',
      'Vydropuzhsk',
      'Vychegodskiy',
      'Vyborg',
      'Vybor',
      'Vyazovaya',
      'Vyazniki',
      'Vyaz’ma',
      'Vyatskiye Polyany',
      'Vyartsilya',
      'Vurnary',
      'Vuktyl',
      'Vsevolozhsk',
      'Voznesen’ye',
      'Voznesenskoye',
      'Voznesenskaya',
      'Vozhega',
      'Voyvozh',
      'Voyskovitsy',
      'Votkinsk',
      'Vostryakovo',
      'Vostochnyy',
      'Voskresenskoye',
      'Voskresensk',
      'Vorsma',
      'Vorsha',
      'Vorotynets',
      'Voroshnëvo',
      'Vorontsovka',
      'Voronezh',
      'Altuf’yevskiy',
      'Vorob’yovo',
      'Vorobyovka',
      'Volzhskiy',
      'Volzhsk',
      'Volya',
      'Vol’sk',
      'Volovo',
      'Volot',
      'Volosovo',
      'Voloshka',
      'Volokonovka',
      'Volokolamsk',
      'Vologda',
      'Volodarskogo',
      'Volodarskiy',
      'Volodarsk',
      'Volochayevskoye',
      'Vol’nyy Aul',
      'Volkhovskiy',
      'Volkhov',
      'Nagornyy',
      'Volgorechensk',
      'Volgo-Kaspiyskiy',
      'Volgograd',
      'Volgodonsk',
      'Volga',
      'Vokhtoga',
      'Vokhma',
      'Vodstroy',
      'Yutsa',
      'Vodnyy',
      'Novovladykino',
      'Vladimirskoye',
      'Vladimirskaya',
      'Vladimir',
      'Vladikavkaz',
      'Vizinga',
      'Vityazevo',
      'Vistino',
      'Visim',
      'Vishnyakovskiye Dachi',
      'Vinsady',
      'Vinogradovo',
      'Vinogradnyy',
      'Vinnitsy',
      'Vilya',
      'Vilovatovo',
      'Vilenka',
      'Vidyayevo',
      'Vidnoye',
      'Vichuga',
      'Veydelevka',
      'Vetluzhskiy',
      'Vetluga',
      'Ves’yegonsk',
      'Veshnyaki',
      'Veshkayma',
      'Veshenskaya',
      'Veshchevo',
      'Vesëlyy',
      'Verkhozim',
      'Verkhov’ye',
      'Verkhovazh’ye',
      'Verkhoshizhem’ye',
      'Verkhnyaya Tura',
      'Verkhnyaya Toyma',
      'Verkhnyaya Tishanka',
      'Verkhnyaya Maksakovka',
      'Kishcha',
      'Verkhnyaya Khava',
      'Verkhnyaya Balkariya',
      'Verkhniy Uslon',
      'Verkhniy Tagil',
      'Lakkha Nëvre',
      'Verkhniy Mamon',
      'Verkhniy Lomov',
      'Verkhniy Landekh',
      'Verkhniy Kurkuzhin',
      'Tyrnyauz',
      'Verkhniy Fiagdon',
      'Verkhniye Tatyshly',
      'Verkhniye Sergi',
      'Verkhniye Osel’ki',
      'Verkhniye Kigi',
      'Verkhniye Achaluki',
      'Verkhniy Baskunchak',
      'Verkhniy Avzyan',
      'Verkhneye Kazanishche',
      'Verkhneyarkeyevo',
      'Verkhneural’sk',
      'Verkhnetulomskiy',
      'Verkhnedneprovskiy',
      'Verkhnechusovskiye Gorodki',
      'Vereya',
      'Vereshchagino',
      'Verbilki',
      'Ventsy',
      'Venëv',
      'Vel’ye',
      'Vel’sk',
      'Velizh',
      'Velikovechnoye',
      'Velikooktyabr’skiy',
      'Velikodvorskiy',
      'Velikiy Ustyug',
      'Velikiye Luki',
      'Velikent',
      'Vedeno',
      'Vazhiny',
      'Vavozh',
      'Vatutino',
      'Vatutinki',
      'Vasyurinskaya',
      'Vaskelovo',
      'Vasil’yevskiy Mokh',
      'Vasil’sursk',
      'Varygino',
      'Varnavino',
      'Varlamovo',
      'Varenikovskaya',
      'Vardane',
      'Valuyki',
      'Valerik',
      'Valerianovsk',
      'Valday',
      'Vakhtan',
      'Vakhrushi',
      'Vagonoremont',
      'Vadinsk',
      'Vad',
      'Vacha',
      'Uzunovo',
      'Uzlovaya',
      'Uyemskiy',
      'Uvary',
      'Uvarovo',
      'Uvarovka',
      'Uva',
      'Utsmiyurt',
      'Utorgosh',
      'Utevka',
      'Utamysh',
      'Usvyaty',
      'Usukhchay',
      'Ustyuzhna',
      'Ust’ye',
      'Ust-Tsilma',
      'Ust’-Shonosha',
      'Ust’-Luga',
      'Ust’-Labinsk',
      'Ust’-Kulom',
      'Ust’-Kishert’',
      'Ust’-Katav',
      'Ust’-Kachka',
      'Ust’-Izhora',
      'Ust’-Dzheguta',
      'Ust’-Donetskiy',
      'Uspenskoye',
      'Uspenskaya',
      'Usol’ye',
      'Usogorsk',
      'Usman’',
      'Usisha',
      'Urzhum',
      'Uryv-Pokrovka',
      'Uryupinsk',
      'Urvan’',
      'Urussu',
      'Urus-Martan',
      'Urukh',
      'Urshel’skiy',
      'Urozhaynoye',
      'Urmary',
      'Urman',
      'Urma',
      'Urkarakh',
      'Uritsk',
      'Uren’',
      'Urdoma',
      'Urazovo',
      'Urazovka',
      'Uralets',
      'Upornaya',
      'Untsukul’',
      'Uni',
      'Undory',
      'Unecha',
      'Umet',
      'Umba',
      'Ulyanovsk',
      'Ul’yanovka',
      'Ulu-Telyak',
      'Ulukulevo',
      'Ullubiyaul',
      'Ulluaya',
      'Ukhta',
      'Ukholovo',
      'Uinskoye',
      'Ugra',
      'Uglyanets',
      'Uglovka',
      'Uglich',
      'Ugleural’skiy',
      'Uglerodovskiy',
      'Uglegorskiy',
      'Ufimskiy',
      'Ufa',
      'Udobnaya',
      'Udimskiy',
      'Udel’naya',
      'Uchkulan',
      'Uchkeken',
      'Uchaly',
      'Tyulyachi',
      'Tyul’gan',
      'Tyarlevo',
      'Tver',
      'Tuzha',
      'Tuymazy',
      'Tutayev',
      'Turki',
      'Turgenevo',
      'Tunoshna',
      'Tumbotino',
      'Tumak',
      'Tuma',
      'Tul’skiy',
      'Tula',
      'Tukan',
      'Tugolesskiy Bor',
      'Tuchkovo',
      'Tubinskiy',
      'Tuapse',
      'Tsurib',
      'Tsivil’sk',
      'Tsimlyansk',
      'Tsil’na',
      'Tsentral’nyy',
      'Tsentoroy',
      'Tselina',
      'Tsagan Aman',
      'Trunovskoye',
      'Trudobelikovskiy',
      'Trudfront',
      'Trubchevsk',
      'Troyel’ga',
      'Trosna',
      'Troparëvo',
      'Troitskoye',
      'Troitsko-Pechorsk',
      'Troitskiy',
      'Troitskaya',
      'Troitsk',
      'Tpig',
      'Tovarkovskiy',
      'Tovarkovo',
      'Totskoye',
      'Tot’ma',
      'Tosno',
      'Torzhok',
      'Toropets',
      'Torfyanoy',
      'Torbeyevo',
      'Tonshayevo',
      'Tonkino',
      'Tomsino',
      'Tomilino',
      'Tomarovka',
      'Tolyatti',
      'Tolstoy-Yurt',
      'Tolstopal’tsevo',
      'Tolmachevo',
      'Tolbazy',
      'Toksovo',
      'Tlyustenkhabl’',
      'Tlyarata',
      'Tishchenskoye',
      'Tirlyanskiy',
      'Timashëvsk',
      'Tim',
      'Tikhvin',
      'Tikhoretsk',
      'Teykovo',
      'Adygeysk',
      'Tetyushi',
      'Teryayevo',
      'Terskol',
      'Terskaya',
      'Tersa',
      'Ternevskaya',
      'Ternovka',
      'Teribërka',
      'Tereze',
      'Terekli-Mekteb',
      'Terek',
      'Terbuny',
      'Tyoply Stan',
      'Tëploye',
      'Tëplaya Gora',
      'Ten’gushevo',
      'Temyasovo',
      'Temryuk',
      'Temnikov',
      'Tëmkino',
      'Temizhbekskaya',
      'Temirgoyevskaya',
      'Temiraul',
      'Tekstil’shchiki',
      'Tbilisskaya',
      'Taytsy',
      'Tatsinskiy',
      'Tatishchevo',
      'Tatarskaya Pishlya',
      'Tatarskaya Kargala',
      'Tatarka',
      'Tashla',
      'Tarusa',
      'Tarumovka',
      'Tarskoye',
      'Tarnogskiy Gorodok',
      'Tarki',
      'Tarkhovka',
      'Taremskoye',
      'Tarasovskiy',
      'Tarasovka',
      'Tambovka',
      'Tambov',
      'Taman’',
      'Tamala',
      'Talovyy',
      'Talovskiy',
      'Talitsy',
      'Taldom',
      'Talashkino',
      'Agidel’',
      'Taganrog',
      'Taganskiy',
      'Syzran’',
      'Syumsi',
      'Syrtych',
      'Syrskoye',
      'Syrostan',
      'Syntul',
      'Synya',
      'Sylva',
      'Syktyvkar',
      'Sychëvo',
      'Sychëvka',
      'Syava',
      'Syas’stroy',
      'Syamzha',
      'Svobody',
      'Svobodnyy',
      'Sviyazhsk',
      'Sviblovo',
      'Svetogorsk',
      'Svetlyy Yar',
      'Svetlyy',
      'Svetlograd',
      'Svetlogorsk',
      'Sverdlovskiy',
      'Sven’',
      'Svecha',
      'Svatkovo',
      'Suzëmka',
      'Suzdal’',
      'Suvorovskaya',
      'Suvorov',
      'Suslonger',
      'Susanino',
      'Surskoye',
      'Sursk',
      'Surovikino',
      'Surovatikha',
      'Surkhakhi',
      'Surgut',
      'Surazh',
      'Sura',
      'Suponevo',
      'Suoyarvi',
      'Sunzha',
      'Suna',
      'Sulak',
      'Suksun',
      'Sukkozero',
      'Sukko',
      'Sukhodol',
      'Sukhobezvodnoye',
      'Sukhinichi',
      'Sukhaya Buyvola',
      'Sudzha',
      'Sudogda',
      'Sudislavl’',
      'Suda',
      'Subkhankulovo',
      'Stupino',
      'Stulovo',
      'Strunino',
      'Strugi-Krasnyye',
      'Stromyn’',
      'Stroitel’',
      'Strogino',
      'Strizhi',
      "Strel'na",
      'Strelka',
      'Strelitsa',
      'Storozhevaya',
      'Stolbovaya',
      'Stolbishchi',
      'Stodolishche',
      'Sterlitamak',
      'Sterlibashevo',
      'Stepnoye',
      'Stepantsevo',
      'Stavrovo',
      'Stavropol’',
      'Staryy Urukh',
      'Staryy Oskol',
      'Staryye Atagi',
      'Staryy Cherek',
      'Starozhilovo',
      'Staroyur’yevo',
      'Staroye Drozhzhanoye',
      'Starovelichkovskaya',
      'Staroutkinsk',
      'Starotitarovskaya',
      'Starotimoshkino',
      'Starosubkhangulovo',
      'Staroshcherbinovskaya',
      'Staropavlovskaya',
      'Staronizhestebliyevskaya',
      'Staromyshastovskaya',
      'Starominskaya',
      'Staroleushkovskaya',
      'Starokucherganovka',
      'Starokorsunskaya',
      'Starodzhereliyevskaya',
      'Starodubskoye',
      'Starodub',
      'Staroderevyankovskaya',
      'Starocherkasskaya',
      'Starobaltachevo',
      'Staritsa',
      'Staraya Vichuga',
      'Staraya Toropa',
      'Staraya Sunzha',
      'Staraya Stanitsa',
      'Staraya Russa',
      'Staraya Poltavka',
      'Staraya Mayna',
      'Staraya Ladoga',
      'Staraya Kupavna',
      'Staraya Kulatka',
      'Staraya Derevnya',
      'Staraya',
      'Star’',
      'Stanovoye',
      'Srednyaya Yelyuzan’',
      'Srednyaya Akhtuba',
      'Sredniy Ikorets',
      'Spokoynaya',
      'Spitsevka',
      'Spirovo',
      'Spiridonovka',
      'Spas-Zaulok',
      'Spassk-Ryazanskiy',
      'Spasskoye-Lutovinovo',
      'Spasskoye',
      'Spas-Klepiki',
      'Spas-Demensk',
      'Sozimskiy',
      'Sovetskoye',
      'Kashkhatau',
      'Shatoy',
      'Khebda',
      'Sovetskiy',
      'Sovetskaya',
      'Sovetsk',
      'Sotnikovskoye',
      'Sosnovyy Bor',
      'Sosnovskoye',
      'Sosnovoborsk',
      'Sosnovo',
      'Sosnovaya Polyana',
      'Sosnogorsk',
      'Soskovo',
      'Sosenskiy',
      'Sortavala',
      'Sorochinsk',
      'Sonkovo',
      'Somovo',
      'Solyanka',
      'Sol’vychegodsk',
      'Soluno-Dmitriyevskoye',
      'Sol’tsy',
      'Solotcha',
      'Solomenskoye',
      'Solntsevo',
      'Solnechnoye',
      'Solnechnogorsk',
      'Sol’-Iletsk',
      'Solikamsk',
      'Soligalich',
      'Solginskiy',
      'Soldatskaya',
      'Soldato-Aleksandrovskoye',
      'Sokol’skoye',
      'Sokolovyy',
      'Sokolovskoye',
      'Sokolovo-Kundryuchenskiy',
      'Sokol’niki',
      'Sokol',
      'Sofrino',
      'Sochi',
      'Sobinka',
      'Snegiri',
      'Smyshlyayevka',
      'Smolino',
      'Smolenskaya',
      'Smolensk',
      'Slobodskoy',
      'Slobodka',
      'Sloboda',
      'Slavyansk-na-Kubani',
      'Slavsk',
      'Slashchevskaya',
      'Slantsy',
      'Skuratovskiy',
      'Skoropuskovskiy',
      'Skorodnoye',
      'Skopin',
      'Skolkovo',
      'Skhodnya',
      'Sizyy Bugor',
      'Siverskiy',
      'Siva',
      'Siukh',
      'Sitniki',
      'Sista-Palkino',
      'Sinyavskoye',
      'Sinyavino',
      'Sinodskoye',
      'Novosin’kovo',
      'Sinegorskiy',
      'Sindor',
      'Sim',
      'Silikatnyy',
      'Sigayevo',
      'Sibay',
      'Shvartsevskiy',
      'Shuyskoye',
      'Shuya',
      'Shuvalovo',
      'Shushary',
      'Shun’ga',
      'Shumyachi',
      'Shumikhinskiy',
      'Shumeyka',
      'Shumerlya',
      'Shugurovo',
      'Shudayag',
      'Mikhaylovsk',
      'Shovgenovskiy',
      'Shonguy',
      'Sholokhovskiy',
      'Kvartsitnyy',
      'Shlissel’burg',
      'Shkurinskaya',
      'Shirokovskiy',
      'Shirochanka',
      'Shiringushi',
      'Shipitsyno',
      'Shimsk',
      'Shimorskoye',
      'Shilovo',
      'Shil’da',
      'Shikhazany',
      'Shikhany',
      'Shigony',
      'Sheremet’yevskiy',
      'Shepsi',
      'Shentala',
      'Shenkursk',
      'Shemysheyka',
      'Shemursha',
      'Shemordan',
      'Shemëtovo',
      'Sheltozero',
      'Shëlkovskaya',
      'Sheksna',
      'Shedok',
      'Shebekino',
      'Shchukino',
      'Shchigry',
      'Shcherbinka',
      'Shchelkovo',
      'Shchëkino',
      'Shcheglovo',
      'Shaturtorf',
      'Shatura',
      'Shatsk',
      'Shatki',
      'Shatalovo',
      'Shar’ya',
      'Sharlyk',
      'Sharkan',
      'Sharanga',
      'Sharan',
      'Shangaly',
      'Shamkhal-Termen',
      'Shamkhal',
      'Shamary',
      'Shalya',
      'Shalushka',
      'Shal’skiy',
      'Shali',
      'Shalazhi',
      'Shalakusha',
      'Shakhun’ya',
      'Shakhty',
      'Shakhta',
      'Shakhovskaya',
      'Shafranovo',
      'Shaburnovo',
      'Shablykino',
      'Shabel’skoye',
      'Shaami-Yurt',
      'Sevsk',
      'Severskaya',
      'Severo-Zadonsk',
      'Severoural’sk',
      'Severomorsk',
      'Severodvinsk',
      'Tretiy Severnyy',
      'Severnyy-Kospashskiy',
      'Severnyy',
      'Severnoye',
      'Setun’',
      'Sestroretsk',
      'Seshcha',
      'Serzhen’-Yurt',
      'Sertolovo',
      'Serpukhov',
      'Sernur',
      'Sernovodsk',
      'Sergokala',
      'Sergiyev Posad',
      'Sergach',
      'Serëdka',
      'Seredeyskiy',
      'Serebryanyye Prudy',
      'Serdobsk',
      'Serafimovskiy',
      'Serafimovich',
      'Sennoy',
      'Sengiley',
      'Semkhoz',
      'Semiluki',
      'Semiletka',
      'Semikarakorsk',
      'Semibratovo',
      'Semënovskoye',
      'Semënov',
      'Selyatino',
      'Selty',
      'Sel’tso',
      'Selizharovo',
      'Selishche',
      'Selezni',
      'Segezha',
      'Sedkyrkeshch',
      'Sechenovo',
      'Sebrovo',
      'Sebezh',
      'Sazonovo',
      'Savvinskaya Sloboda',
      'Savinskaya',
      'Savino',
      'Savinka',
      'Savasleyka',
      'Satka',
      'Satis',
      'Satinka',
      'Sasykoli',
      'Sary-Tyuz',
      'Sars',
      'Sarov',
      'Sarmanovo',
      'Sarmakovo',
      'Saratovskaya',
      'Saratov',
      'Sarapul',
      'Sarany',
      'Saransk',
      'Sarana',
      'Saraktash',
      'Sarai',
      'Sapozhok',
      'Sapërnyy',
      'Sapernoye',
      'Saint Petersburg',
      'Sandovo',
      'Sandata',
      'Sanchursk',
      'Samur',
      'Samoylovka',
      'Samoded',
      'Sambek',
      'Samashki',
      'Samarskoye',
      'Samara',
      'Saltykovka',
      'Sal’sk',
      'Salmi',
      'Salavat',
      'Sakmara',
      'Sakharovo',
      'Sagopshi',
      'Safonovo',
      'Sadovoye',
      'Sachkovichi',
      'Sabnova',
      'Rzhev',
      'Rzhavki',
      'Rzhanitsa',
      'Rzhaksa',
      'Ryzdvyanyy',
      'Rybnoye',
      'Rybnaya Sloboda',
      'Rybinsk',
      'Rybatskoye',
      'Ryazhsk',
      'Ryazanskaya',
      'Ryazan’',
      'Ryabovo',
      'Ruzayevka',
      'Ruza',
      'Rutul',
      'Russko-Vysotskoye',
      'Russkiy Kameshkir',
      'Ruskeala',
      'Rumyantsevo',
      'Rudnya',
      'Rudnichnyy',
      'Rublëvo',
      'Rtishchevo',
      'Rozhdestveno',
      'Rovnoye',
      'Roven’ki',
      'Rostov-na-Donu',
      'Rostov',
      'Rostokino',
      'Rossosh’',
      'Roslyakovo',
      'Roslavl’',
      'Roshni-Chu',
      'Roshchino',
      'Mariyenburg',
      'Roshal’',
      'Romodanovo',
      'Romanovskaya',
      'Romanovka',
      'Rogovskaya',
      'Rogovatoye',
      'Rognedino',
      'Rogachëvo',
      'Rodnikovskaya',
      'Rodniki',
      'Rodionovo-Nesvetayskaya',
      'Rodionovo-Nesvetaiskoye',
      'Rochegda',
      'Revyakino',
      'Revda',
      'Reutov',
      'Reshetnikovo',
      'Reshetikha',
      'Repnoye',
      'Repino',
      'Remontnoye',
      'Redkino',
      'Rechitsy',
      'Razumnoye',
      'Razliv',
      'Rayevskiy',
      'Rayevskaya',
      'Rassvet',
      'Rasskazovo',
      'Rasshevatskaya',
      'Ramon’',
      'Rameshki',
      'Ramenskoye',
      'Ramenki',
      'Rakitnoye',
      'Rakhmanovo',
      'Radovitskiy',
      'Radumlya',
      'Raditsa-Krylovka',
      'Radishchevo',
      'Radchenko',
      'Rabotki',
      'Pytalovo',
      'Pyshchug',
      'Pyra',
      'Pychas',
      'Pyatovskiy',
      'Pyatnitskoye',
      'Pyatigorskiy',
      'Pyatigorsk',
      'Pyaozerskiy',
      'Putyatino',
      'Puteyets',
      'Pustoshka',
      'Pushkinskiye Gory',
      'Pushkino',
      'Pushkin',
      'Pushchino',
      'Purshevo',
      'Purekh',
      'Puksoozero',
      'Pugachev',
      'Pudozh',
      'Pudem',
      'Puchezh',
      'Psyzh',
      'Psygansu',
      'Pskov',
      'Pshekhskaya',
      'Pshada',
      'Psedakh',
      'Psebay',
      'Pryazha',
      'Pryamitsyno',
      'Prudy',
      'Protvino',
      'Pronsk',
      'Proletarskiy',
      'Proletarsk',
      'Proletariy',
      'Kipen’',
      'Prokhorovka',
      'Prokhladnyy',
      'Prochnookopskaya',
      'Priyutovo',
      'Priyutnoye',
      'Privolzhskoye',
      'Privolzhskiy',
      'Privolzhsk',
      'Privodino',
      'Priupskiy',
      'Pristen’',
      'Priozërsk',
      'Primorka',
      'Primorsko-Akhtarsk',
      'Primorsk',
      'Primalkinskoye',
      'Prikubanskiy',
      'Prigorodnyy',
      'Prigorodnoye',
      'Prigorodka',
      'Pridonskoy',
      'Pregradnoye',
      'Pregradnaya',
      'Prechistoye',
      'Pravokumskoye',
      'Pravdinskiy',
      'Pravdinsk',
      'Praskoveya',
      'Pozhva',
      'Poyakonda',
      'Povorino',
      'Povenets',
      'Povarovo',
      'Poshekhon’ye',
      'Kotlovka',
      'Annino',
      'Poselki',
      'Port-Katon',
      'Porosozero',
      'Porkhov',
      'Poretskoye',
      'Porech’ye-Rybnoye',
      'Poputnaya',
      'Popovo',
      'Ponyri Vtoryye',
      'Pontonnyy',
      'Ponezhukay',
      'Ponazyrevo',
      'Pomary',
      'Polyarnyye Zori',
      'Polyarnyy',
      'Polyane',
      'Polyany',
      'Polotnyanyy Zavod',
      'Polessk',
      'Poldnëvoye',
      'Polazna',
      'Pokrovskoye-Streshnëvo',
      'Pokrovskoye',
      'Pokrovo-Prigorodnoye',
      'Pokrov',
      'Pokoynoye',
      'Pokhvistnevo',
      'Poim',
      'Pogar',
      'Podyuga',
      'Podstepki',
      'Podsolnechnoye',
      'Podporozh’ye',
      'Podosinovets',
      'Podolsk',
      'Podnov’ye',
      'Podlesnyy',
      'Podlesnoye',
      'Podkumskiy',
      'Podkletnoye',
      'Podgorodnyaya Pokrovka',
      'Podgornoye',
      'Podgornaya',
      'Podgorenskiy',
      'Poddor’ye',
      'Podborki',
      'Pochinok',
      'Pochinki',
      'Pochep',
      'Plyussa',
      'Ryazanskiy',
      'Pleshanovo',
      'Plesetsk',
      'Plës',
      'Plekhanovo',
      'Plavsk',
      'Platonovka',
      'Platnirovskaya',
      'Plastunovskaya',
      'Planovskoye',
      'Pizhma',
      'Pizhanka',
      'Pitkyaranta',
      'Piterka',
      'Pitelino',
      'Pistsovo',
      'Pirogovskiy',
      'Pirogovo',
      'Pionerskiy',
      'Pinyug',
      'Pinerovka',
      'Pinega',
      'Pindushi',
      'Pil’na',
      'Pikalëvo',
      'Pichayevo',
      'Petushki',
      'Petrozavodsk',
      'Petrov Val',
      'Petrovsk',
      'Petrovskiy',
      'Petrovskaya',
      'Petrovo-Dal’neye',
      'Petro-Slavyanka',
      'Petropavlovskaya',
      'Petrodvorets',
      'Peterhof',
      'Pestyaki',
      'Pestretsy',
      'Pestravka',
      'Pestovo',
      'Pesochnyy',
      'Pesochnoye',
      'Peskovka',
      'Peski',
      'Peshkovo',
      'Peshki',
      'Peschanokopskoye',
      'Pervouralsk',
      'Pervomayskoye',
      'Pervomayskiy',
      'Bakhchivandzhi',
      'Pervomayskaya',
      'Pervomaysk',
      'Pervoavgustovskiy',
      'Perm',
      'Pereyaslovskaya',
      'Perevoz',
      'Perevolotskiy',
      'Perevoloki',
      'Pereslavl’-Zalesskiy',
      'Perepravnaya',
      'Peremyshl’',
      'Perelyub',
      'Pereleshinskiy',
      'Pereleshino',
      'Peredovaya',
      'Novo-Peredelkino',
      'Penza',
      'Peno',
      'Pelagiada',
      'Pechory',
      'Pechora',
      'Pechersk',
      'Pechenga',
      'Pavshino',
      'Pavlovskiy Posad',
      'Pavlovskiy',
      'Pavlovskaya Sloboda',
      'Pavlovskaya',
      'Pavlovsk',
      'Pavlovo',
      'Pavlovka',
      'Pavlodol’skaya',
      'Pavino',
      'Pavelets',
      'Pashkovskiy',
      'Pashiya',
      'Pasegovo',
      'Partizan',
      'Parma',
      'Parkovyy',
      'Pargolovo',
      'Parfino',
      'Parfen’yevo',
      'Paraul',
      'Paran’ga',
      'Pankovka',
      'Panino',
      'Pamyat’ Parizhskoy Kommuny',
      'Pallasovka',
      'Palkino',
      'Palekh',
      'Pachelma',
      'Staryye Ozinki',
      'Ozherel’ye',
      'Ozëry',
      'Ozërsk',
      'Ozërnyy',
      'Ozerki',
      'Ovoshchi',
      'Overyata',
      'Otrado-Kubanskoye',
      'Otradnyy',
      'Otradnoye',
      'Otradnaya',
      'Otkaznoye',
      'Osyno',
      'Ostrovtsy',
      'Ostrovskoye',
      'Ostrov',
      'Ostrogozhsk',
      'Oster',
      'Ostashkov',
      'Ostashëvo',
      'Ostankinskiy',
      'Osinovo',
      'Osinki',
      'Osel’ki',
      'Osa',
      'Orud’yevo',
      'Orsk',
      'Orshanka',
      'Orsha',
      'Orlovskiy',
      'Orlovo',
      'Orlovka',
      'Orichi',
      'Orgtrud',
      'Orenburg',
      'Orël',
      'Orekhovo-Zuyevo',
      'Orekhovo-Borisovo Severnoye',
      'Ordzhonikidzevskiy',
      'Orda',
      'Oranzherei',
      'Opochka',
      'Opechenskiy Posad',
      'Oparino',
      'Opalikha',
      'Onega',
      'Omutninsk',
      'Olym',
      'Olonets',
      'Ol’khovatka',
      'Ol’gino',
      'Ol’ginskaya',
      'Ol’ginka',
      'Olenino',
      'Olenegorsk',
      'Okulovka',
      'Tsotsin-Yurt',
      'Oktyabr’skiy',
      'Takhtamukay',
      'Oktyabr’sk',
      'Oksovskiy',
      'Okhansk',
      'Odoyev',
      'Odintsovo',
      'Ochër',
      'Ochakovo-Matveyevskoye',
      'Obukhovo',
      'Obsharovka',
      'Oboyan’',
      'Obninsk',
      'Oblivskaya',
      'Nyuksenitsa',
      'Nytva',
      'Nyrob',
      'Nyazepetrovsk',
      'Nyandoma',
      'Nurma',
      'Nurlat',
      'Nuradilovo',
      'Nudol’',
      'Nozhay-Yurt',
      'Novyy Yegorlyk',
      'Novyy Ropsk',
      'Novyy Rogachik',
      'Novyy Oskol',
      'Novyy Nekouz',
      'Novyy Karachay',
      'Novyye Lyady',
      'Novyye Kuz’minki',
      'Novyye Gorki',
      'Novyye Cherëmushki',
      'Novyye Burasy',
      'Novyye Atagi',
      'Novyy Byt',
      'Novyy Buyan',
      'Novy',
      'Novozybkov',
      'Novozhivotinnoye',
      'Novozavedennoye',
      'Novoye Leushino',
      'Novoye Devyatkino',
      'Novoye',
      'Novovyazniki',
      'Novovoronezh',
      'Novovelichkovskaya',
      'Novouzensk',
      'Novoutkinsk',
      'Novoul’yanovsk',
      'Novoukrainskoye',
      'Novoukrainskiy',
      'Novotroitskaya',
      'Novotroitsk',
      'Novotitarovskaya',
      'Novo-Talitsy',
      'Novospasskoye',
      'Novosokol’niki',
      'Novosmolinskiy',
      'Novosil’',
      'Novosheshminsk',
      'Novoshcherbinovskaya',
      'Novoshakhtinsk',
      'Novosergiyevka',
      'Novosemeykino',
      'Novoselitskoye',
      'Novorzhev',
      'Novorudnyy',
      'Novorozhdestvenskaya',
      'Novorossiysk',
      'Novopokrovskaya',
      'Novopokrovka',
      'Novopodrezkovo',
      'Novoplatnirovskaya',
      'Novopistsovo',
      'Novopetrovskoye',
      'Novopavlovsk',
      'Novoorsk',
      'Novo-Nikol’skoye',
      'Novonikolayevskiy',
      'Novomyshastovskaya',
      'Novomoskovsk',
      'Novominskaya',
      'Novomikhaylovskiy',
      'Novomichurinsk',
      'Novaya Malykla',
      'Novomalorossiyskaya',
      'Novoleushkovskaya',
      'Novolakskoye',
      'Novolabinskaya',
      'Novokuz’minki',
      'Novokuybyshevsk',
      'Novokubansk',
      'Novokorsunskaya',
      'Novokhovrino',
      'Novokhopërsk',
      'Novokayakent',
      'Novyy Izborsk',
      'Novoivanovskoye',
      'Novogurovskiy',
      'Oyskhara',
      'Grebnevo',
      'Novogireyevo',
      'Novogagatli',
      'Novodzhereliyevskaya',
      'Novodvinsk',
      'Novodugino',
      'Novodmitriyevskaya',
      'Novoderevyankovskaya',
      'Novocherkassk',
      'Novocheremshansk',
      'Novocheboksarsk',
      'Novoblagodarnoye',
      'Novobeysugskaya',
      'Novobessergenovka',
      'Novobelokatay',
      'Novobataysk',
      'Novoanninskiy',
      'Novoalekseyevskaya',
      'Novoaleksandrovsk',
      'Novki',
      'Velikiy Novgorod',
      'Novaya Usman’',
      'Novaya Tavolzhanka',
      'Novaya Mayna',
      'Novaya Lyada',
      'Novaya Ladoga',
      'Novaya Derevnya',
      'Novaya Chigla',
      'Nolinsk',
      'Nogir',
      'Noginsk',
      'Nizhnyaya Tura',
      'Nizhnepavlovka',
      'Nizhnyaya Maktama',
      'Nizhnyaya Irga',
      'Nizhniy Ufaley',
      'Nizhny Tagil',
      'Nizhniy Odes',
      'Nizhniy Novgorod',
      'Nizhniy Mamon',
      'Nizhniy Lomov',
      'Nizhniy Kislyay',
      'Nizhniye Vyazovyye',
      'Nizhniye Sergi',
      'Nizhniye Achaluki',
      'Nizhniy Dzhengutay',
      'Nizhniy Chir',
      'Nizhniy Cherek',
      'Nizhniy Baskunchak',
      'Nizhniy Arkhyz',
      'Nizhneye Kazanishche',
      'Nizhnetroitskiy',
      'Nizhnekamsk',
      'Nizhneivkino',
      'Nizhnedevitsk',
      'Niny',
      'Nikulino',
      'Nikol’skoye-na-Cheremshane',
      'Nikol’skoye',
      'Nikol’sk',
      'Nikologory',
      'Nikolo-Berëzovka',
      'Nikolina Gora',
      'Nikolayevskaya',
      'Nikolayevsk',
      'Nikolayevka',
      'Nikola-Lenivets',
      'Nikel',
      'Nezlobnaya',
      'Nezhinka',
      'Neya',
      'Nevinnomyssk',
      'Neverkino',
      'Nevel’',
      'Nesterovskaya',
      'Nesterov',
      'Nerl’',
      'Nerekhta',
      'Nemchinovka',
      'Neman',
      'Nema',
      'Nelidovo',
      'Nelazskoye',
      'Nekrasovskoye',
      'Nekrasovskiy',
      'Nekrasovskaya',
      'Nekrasovka',
      'Neklyudovo',
      'Nekhayevskiy',
      'Neftekamsk',
      'Neftegorsk',
      'Nedvigovka',
      'Nebug',
      'Nebolchi',
      'Nazran’',
      "Nazar'yevo",
      'Navoloki',
      'Navlya',
      'Navashino',
      'Naurskaya',
      'Alpatovo',
      'Natyrbovo',
      'Naryshkino',
      "Nar'yan-Mar",
      'Nartkala',
      'Nartan',
      'Narovchat',
      'Naro-Fominsk',
      'Narimanov',
      'Nalchik',
      'Nakhabino',
      'Nagutskoye',
      'Nagorsk',
      'Lakha Nëvre',
      'Nadvoitsy',
      'Nadezhda',
      'Nachalovo',
      'Naberezhnyye Chelny',
      'Myurego',
      'Mytishchi',
      'Myskhako',
      'Myshkin',
      'Myatlevo',
      'Myaksa',
      'Muyezerskiy',
      'Mutsalaul',
      'Muslyumovo',
      'Murygino',
      'Murom',
      'Murmino',
      'Murmashi',
      'Murmansk',
      'Murino',
      'Murashi',
      'Muranovo',
      'Mumra',
      'Mullovka',
      'Mulino',
      'Mukhtolovo',
      'Mugi',
      'Muchkapskiy',
      'Mtsensk',
      'Mstikhino',
      'Mstera',
      'Mrakovo',
      'Mozhga',
      'Mozhaysk',
      'Mozdok',
      'Mostovskoy',
      'Mostishche',
      'Moscow',
      'Moskovskoye',
      'Moshenskoye',
      'Mosal’sk',
      'Morshansk',
      'Morozovsk',
      'Morki',
      'Morgaushi',
      'Mordovo',
      'Mordino',
      'Monino',
      'Monchegorsk',
      'Monastyrshchina',
      'Molokovo',
      'Molodezhnoye',
      'Molochnoye',
      'Mokshan',
      'Mokrous',
      'Mochalishche',
      'Mshinskaya',
      'Mizur',
      'Mitrofanovka',
      'Miskindzha',
      'Mishkino',
      'Misheronskiy',
      'Mirskoy',
      'Mirnyy',
      'Mineralnye Vody',
      'Mindyak',
      'Milyutinskaya',
      'Miloslavskoye',
      'Millerovo',
      'Mikun’',
      'Mikulino',
      'Mikhnëvo',
      'Mikhaylovskoye',
      'Mikhaylovskaya',
      'Mikhaylovka',
      'Mikhaylov',
      'Mikhalkovo',
      'Michurinskoye',
      'Michurinsk',
      'Miatli',
      'Mglin',
      'Mga',
      'Mezinovskiy',
      'Mezhevoy',
      'Mezhdurechensk',
      'Mezen’',
      'Metallostroy',
      'Mesyagutovo',
      'Mesker-Yurt',
      'Meshchovsk',
      'Meshcherino',
      'Menzelinsk',
      'Mendeleyevskiy',
      'Mendeleyevsk',
      'Mendeleyevo',
      'Melikhovo',
      'Meleuz',
      'Melenki',
      'Melikhovskaya',
      'Melekhovo',
      'Medyn’',
      'Medvezh’yegorsk',
      'Medvezh’i Ozëra',
      'Medvedovskaya',
      'Medvedok',
      'Medveditskiy',
      'Medvedevo',
      'Mednogorsk',
      'Mechetinskaya',
      'Mayskoye',
      'Mayskiy',
      'Mayrtup',
      'Mayna',
      'Maykor',
      'Maykopskoye',
      'Maykop',
      'Mayachnyy',
      'Matyushkino',
      'Matveyevskoye',
      'Matveyev Kurgan',
      'Matveyevka',
      'Matrosy',
      'Maslovka',
      'Maslova Pristan’',
      'Maromitsa',
      'Marks',
      'Markovo',
      'Mari-Turek',
      'Mar’ino',
      'Mariinskiy Posad',
      'Marfino',
      'Marevo',
      'Manturovo',
      'Manaskent',
      'Manas',
      'Mamontovka',
      'Mamedkala',
      'Mamadysh',
      'Yaroslavskiy',
      'Malyye Derbety',
      'Malyshevo',
      'Maloye Verevo',
      'Maloyaroslavets',
      'Maloarkhangel’sk',
      'Malmyzh',
      'Malka',
      'Malino',
      'Malgobek',
      'Malaya Vishera',
      'Malaya Serdoba',
      'Malaya Purga',
      'Malaya Dubna',
      'Malakhovka',
      'Makushino',
      'Maksatikha',
      'Makhalino',
      'Makhachkala',
      'Makar’yev',
      'Magnitogorsk',
      'Magnitka',
      'Maginsk',
      'Magaramkent',
      'Madzhalis',
      'Lyudinovo',
      'Lyubytino',
      'Lyubuchany',
      'Lyubovsho',
      'Lyubokhna',
      'Lyublino',
      'Lyubim',
      'Lyubertsy',
      'Lyuban’',
      'Lytkarino',
      'Lysyye Gory',
      'Lys’va',
      'Lysogorskaya',
      'Lyskovo',
      'Lyaskelya',
      'Lyangasovo',
      'Lyamino',
      'Lyambir’',
      'L’vovskiy',
      'Luzhniki',
      'Luza',
      'Luppolovo',
      'Lunino',
      'Lukoyanov',
      'Lukovetskiy',
      'Luknovo',
      'Lukino',
      'Lukhovka',
      'Lukhovitsy',
      'Lukh',
      'Lugovaya',
      'Luga',
      'Lubyany',
      'Lozhki',
      'Loyga',
      'Lovozero',
      'Lovlinskaya',
      'Loukhi',
      'Lotoshino',
      'Losino-Petrovskiy',
      'Losevo',
      'Lopatinskiy',
      'Lopatino',
      'Lopandino',
      'Lomovka',
      'Lomonosov',
      'Lomintsevskiy',
      'Lokot’',
      'Loknya',
      'Log',
      'Lodeynoye Pole',
      'Lobnya',
      'Lobanovo',
      'Livny',
      'Livenka',
      'Liski',
      'Lisiy Nos',
      'Lipin Bor',
      'Lipetsk',
      'Linda',
      'Liman',
      'Likino-Dulevo',
      'Likhoy',
      'Likhovskoy',
      'Likhoslavl’',
      'Likhobory',
      'Lianozovo',
      'L’govskiy',
      'Lezhnevo',
      'Lev Tolstoy',
      'Levokumskoye',
      'Levokumka',
      'Levikha',
      'Levashevo',
      'Levashi',
      'Levashovo',
      'Letnyaya Stavka',
      'Letnik',
      'Letka',
      'Lesogorskiy',
      'Lesnyye Polyany',
      'Lesnoy Gorodok',
      'Lesnoye',
      'Komendantsky aerodrom',
      'Lesnoy',
      'Leskolovo',
      'Leshukonskoye',
      'Lermontovo',
      'Lermontov',
      'Leonovo',
      'Leninskoye',
      'Leninskiye Gory',
      'Leninskiy',
      'Leninsk',
      'Leninogorsk',
      'Tsaritsyno',
      'Leninkent',
      'Leningradskaya',
      'Yubileyny',
      'Leninaul',
      'Lenina',
      'Lefortovo',
      'Ledmozero',
      'Lechinkay',
      'Lebyazh’ye',
      'Lebedyan’',
      'Lazarevskoye',
      'Latnaya',
      'Lashma',
      'Laptëvo',
      'Lal’sk',
      'Lakinsk',
      'Lakhtinskiy',
      'Lakhdenpokh’ya',
      'Laishevo',
      'Ladushkin',
      'Ladozhskaya',
      'Ladovskaya Balka',
      'Labinsk',
      'Kyzyl-Oktyabr’skiy',
      'Islamey',
      'Dugulubgey',
      'Kyzburun Pervyy',
      'Kyakhulay',
      'Kvarkeno',
      'Kuzovatovo',
      'Kuznetsk',
      'Kuznechnoye',
      'Kuznechikha',
      'Kuz’minskiye Otverzhki',
      'Kuz’minki',
      'Kuzino',
      'Kuzhorskaya',
      'Kuzhenkino',
      'Kuzhener',
      'Kuyvozi',
      'Kuyeda',
      'Kuybyshevskiy Zaton',
      'Kuybyshevo',
      'Kuvshinovo',
      'Kuvandyk',
      'Kuskovo',
      'Kushva',
      'Kushnarënkovo',
      'Kushelevka',
      'Kushchëvskaya',
      'Kusa',
      'Kur’yanovo',
      'Kurumoch',
      'Kurskaya',
      'Kursk',
      'Kursavka',
      'Kurovskoye',
      'Kurmanayevka',
      'Kurlovo',
      'Nizhniy Kurkuzhin',
      'Kurkino',
      'Kurkent',
      'Kurganinsk',
      'Kurdzhinovo',
      'Kurchatov',
      'Kurchaloy',
      'Kurba',
      'Kurakh',
      'Kupchino',
      'Kupavna',
      'Kungur',
      'Kumysh',
      'Kumylzhenskaya',
      'Kumukh',
      'Kumertau',
      'Kumëny',
      'Kultayevo',
      'Kuloy',
      'Kulotino',
      'Kuli',
      'Kuleshovka',
      'Kulebaki',
      'Kulary',
      'Kukushtan',
      'Kukoboy',
      'Kukmor',
      'Kugesi',
      'Kudymkar',
      'Kudinovo',
      'Kudeyevskiy',
      'Kudepsta',
      'Kuchugury',
      'Kubinka',
      'Kuba-Taba',
      'Kubanskiy',
      'Kubachi',
      'Kstovo',
      'Kshenskiy',
      'Krymsk',
      'Krym',
      'Krylovskaya',
      'Kruglolesskoye',
      'Krotovka',
      'Kropotkin',
      'Kropachëvo',
      'Kronstadt',
      'Kromy',
      'Krivyanskaya',
      'Krivtsovo',
      'Kresty',
      'Kresttsy',
      'Kremenki',
      'Krechevitsy',
      'Kratovo',
      'Krasyukovskaya',
      'Krasnyy Yar',
      'Krasnyy Tkach',
      'Krasnyy Sulin',
      'Biryulëvo Zapadnoye',
      'Krasnyy Profintern',
      'Krasnyy Oktyabr’',
      'Krasnyy Luch',
      'Krasnyy Kut',
      'Krasnyy Kurgan',
      'Krasnyy Klyuch',
      'Krasnyy Kholm',
      'Krasnyy Gulyay',
      'Krasnyye Tkachi',
      'Krasnyye Chetai',
      'Krasnyye Barrikady',
      'Krasnye Baki',
      'Krasnyy Bor',
      'Krasnyy Bogatyr’',
      'Krasnoznamensk',
      'Krasnozavodsk',
      'Krasnoye Selo',
      'Krasnoye-na-Volge',
      'Krasnoye',
      'Krasnoyarskiy',
      'Krasnoyarskaya',
      'Krasnovka',
      'Krasnovishersk',
      'Krasnoufimsk',
      'Krasnoslobodsk',
      'Golovinskiy',
      'Krasnomayskiy',
      'Krasnokumskoye',
      'Krasnokholm',
      'Krasnokamsk',
      'Krasnogvardeyskoye',
      'Krasnogvardeyets',
      'Krasnogorskoye',
      'Krasnogorskiy',
      'Krasnogorsk',
      'Krasnogorodsk',
      'Krasnofarfornyy',
      'Krasnodar',
      'Krasnoborsk',
      'Krasnoarmeyskoye',
      'Krasnoarmeyskiy',
      'Krasnoarmeyskaya',
      'Krasnoarmeysk',
      'Krasnaya Zarya',
      'Krasnaya Yaruga',
      'Presnenskiy',
      'Krasnaya Poyma',
      'Krasnaya Polyana',
      'Krasnaya Gorka',
      'Krasnaya Gorbatka',
      'Krasnaya Gora',
      'Kraskovo',
      'Krasavino',
      'Krapivna',
      'Krasnokholmskiy',
      'Koz’modem’yansk',
      'Kozlovo',
      'Kozlovka',
      'Kozhva',
      'Kozhukhovo',
      'Kozeyevo',
      'Kozel’sk',
      'Koygorodok',
      'Kovylkino',
      'Kovrov',
      'Kovernino',
      'Kovdor',
      'Kotovsk',
      'Kotovo',
      'Kotlyarevskaya',
      'Kotlas',
      'Kotel’nikovo',
      'Kotel’niki',
      'Kotel’nich',
      'Kostrovo',
      'Kostroma',
      'Kostomuksha',
      'Kostino',
      'Kostek',
      'Kosmynino',
      'Koslan',
      'Kosino',
      'Koshki',
      'Koshekhabl’',
      'Kosaya Gora',
      'Kosa',
      'Korzhovka-Golubovka',
      'Korzhevskiy',
      'Koryazhma',
      'Kortkeros',
      'Korsakovo',
      'Korotoyak',
      'Korocha',
      'Korobitsyno',
      'Kormëzhka',
      'Korenovsk',
      'Korenëvo',
      'Korablino',
      'Koptëvo',
      'Kopor’ye',
      'Kopanskaya',
      'Konyshevka',
      'Konstantinovskoye',
      'Konstantinovskiy',
      'Konstantinovskaya',
      'Konstantinovsk',
      'Konstantinovo',
      'Konosha',
      'Konokovo',
      'Konobeyevo',
      'Konëvo',
      'Kondrovo',
      'Kondopoga',
      'Kondol’',
      'Konakovo',
      "Komsomol'skoye",
      'Komsomol’skiy',
      'Komsomol’sk',
      'Komsomolets',
      'Kommunarka',
      'Kommunar',
      'Komarovo',
      'Komarikhinskiy',
      'Komarichi',
      'Kolyubakino',
      'Kolyshley',
      'Koltushi',
      'Koltubanovskiy',
      'Kolpny',
      'Kolpino',
      'Yanino-1',
      'Kolomyagi',
      'Kolomna',
      'Kolomenskoye',
      'Kologriv',
      'Kolodeznyy',
      'Kolobovo',
      'Kol’chugino',
      'Kolchanovo',
      'Kola',
      'Koksovyy',
      'Kokoshkino',
      'Kokorevka',
      'Kokino',
      'Kokhma',
      'Kodino',
      'Kochkurovo',
      'Kochevo',
      'Kobrinskoye',
      'Knyaginino',
      'Klyavlino',
      'Klintsy',
      'Klin',
      'Klimovsk',
      'Klimovo',
      'Kletskaya',
      'Kletnya',
      'Kizner',
      'Kizlyar',
      'Kizilyurt',
      'Kizema',
      'Kizel',
      'Kiyevskoye',
      'Kiyasovo',
      'Kitovo',
      'Kislyakovskaya',
      'Kislovodsk',
      'Kishpek',
      'Kirzhach',
      'Kirya',
      'Kirsanov',
      'Kirs',
      'Kirovskiy',
      'Kirovskaya',
      'Kirovsk',
      'Kirovo-Chepetsk',
      'Kirov',
      'Kiritsy',
      'Kirishi',
      'Kirillov',
      'Kirgiz-Miyaki',
      'Kireyevsk',
      'Kingisepp',
      'Kineshma',
      'Kinel’-Cherkassy',
      'Kinel’',
      'Kimry',
      'Kimovsk',
      'Kil’mez’',
      'Kilemary',
      'Preobrazhenskaya',
      'Kiknur',
      'Kikerino',
      'Kideksha',
      'Kichmengskiy Gorodok',
      'Khvoynaya',
      'Khvorostyanka',
      'Khvatovka',
      'Khvastovichi',
      'Khvalynsk',
      'Khvalovo',
      'Khunzakh',
      'Khumalag',
      'Khuchni',
      'Khryashchevka',
      'Khrenovoye',
      'Khrabrovo',
      'Khotynets',
      "Khot'kovo",
      'Khosta',
      'Khoroshëvo-Mnevniki',
      'Khorlovo',
      'Khomutovo',
      'Khomutovka',
      'Kholm-Zhirkovskiy',
      'Kholmskiy',
      'Kholmogory',
      'Kholm',
      'Khokhryaki',
      'Khokhlovo',
      'Khodz’',
      'Khlevnoye',
      'Khiv',
      'Khislavichi',
      'Khimki',
      'Khelyulya',
      'Khatukay',
      'Khasavyurt',
      'Kharovsk',
      'Kharlu',
      'Kharitonovo',
      'Kharabali',
      'Khanskaya',
      'Khanino',
      'Khamamatyurt',
      'Orlov',
      'Khadzhalmakhi',
      'Khadyzhensk',
      'Khabez',
      'Kez',
      'Kevsala',
      'Kesova Gora',
      'Kerva',
      'Kerchevskiy',
      'Kenzhe',
      'Kemlya',
      'Kem’',
      'Kazinka',
      'Kazanskaya',
      'Kazan',
      'Kazaki',
      'Kayasula',
      'Kayakent',
      'Kavkazskiy',
      'Kavkazskaya',
      'Katyr-Yurt',
      "Katyn'",
      'Katunino',
      'Katav-Ivanovsk',
      'Kasumkent',
      'Kastornoye',
      'Kastanayevo',
      'Kaspiyskiy',
      'Kaspiysk',
      'Kasimov',
      'Kashirskoye',
      'Kashira',
      'Kashin',
      'Kashary',
      'Karsun',
      'Karpushikha',
      'Karpogory',
      'Karmaskaly',
      'Karinskoye',
      'Kargopol’',
      'Kargalinskaya',
      'Kardzhin',
      'Kardymovo',
      'Kardonikskaya',
      'Kardailovo',
      'Karata',
      'Karalat',
      'Karakulino',
      'Karaidel’',
      'Karagay',
      'Karagach',
      'Karachev',
      'Karachayevsk',
      'Karabulak',
      'Karabudakhkent',
      'Karabash',
      'Karabanovo',
      'Kapustin Yar',
      'Kapotnya',
      'Kantyshevo',
      'Kantemirovka',
      'Kangly',
      'Kanevskaya',
      'Kanelovskaya',
      'Kandry',
      'Kandalaksha',
      'Kanayevka',
      'Kanash',
      'Kanadey',
      'Kamyzyak',
      'Kamyshla',
      'Kamyshin',
      'Kamyshevatskaya',
      'Kaminskiy',
      'Kameshkovo',
      'Kamensk-Shakhtinskiy',
      'Kamenskiy',
      'Kamenolomni',
      'Kamennomostskoye',
      'Kamennomostskiy',
      'Kamennogorsk',
      'Kamenka',
      'Kamenetskiy',
      'Kambileyevskoye',
      'Kambarka',
      'Kama',
      'Kalyazin',
      'Kaluga',
      'Kaltasy',
      'Kalnibolotskaya',
      'Kalinovskoye',
      'Kalinovskaya',
      'Kalino',
      'Kalininskaya',
      'Kalininsk',
      'Korolev',
      'Kaliningrad',
      'Kalininaul',
      'Kalinin',
      'Kalikino',
      'Kalevala',
      'Kalashnikovo',
      'Kalach-na-Donu',
      'Kalach',
      'Kakhun',
      'Kagal’nitskaya',
      'Kafyr-Kumukh',
      'Kadyy',
      'Kaduy',
      'Kadoshkino',
      'Kadom',
      'Kadnikov',
      'Kachkanar',
      'Kabardinka',
      'Kabanovo',
      'Kabakovo',
      'Izoplit',
      'Izobil’nyy',
      'Iznoski',
      'Izmaylovo',
      'Izmalkovo',
      'Izhma',
      'Izhevskoye',
      'Izhevsk',
      'Izborsk',
      'Izberbash',
      'Ivot',
      'Ivnya',
      'Ivanteyevka',
      'Ivanovskoye',
      'Ivanovo',
      'Ivanishchi',
      'Ivanino',
      'Ivangorod',
      'Itum-Kali',
      'Isyangulovo',
      'Istra',
      'Issad',
      'Issa',
      'Ispravnaya',
      'Ishnya',
      'Ishley',
      'Ishkhoy-Yurt',
      'Ishimbay',
      'Isheyevka',
      'Ishcherskaya',
      'Isakly',
      'Is',
      'Irkliyevskaya',
      'Irganay',
      'Irgakly',
      'Ipatovo',
      'Ostrovnoy',
      'Inzhavino',
      'Inzer',
      'Inza',
      'Insar',
      'Inozemtsevo',
      'Inderka',
      'Imeni Zhelyabova',
      'Imeni Vorovskogo',
      'Imeni Vladimira Il’icha Lenina',
      'Imeni Tsyurupy',
      'Imeni Sverdlova',
      'Imeni Stepana Razina',
      'Imeni Morozova',
      'Gereykhanovskoye',
      'Imeni Babushkina',
      'Il’skiy',
      'Ilovlya',
      'Ilovka',
      'Il’inskoye-Khovanskoye',
      'Il’inskoye',
      'Il’insko-Podomskoye',
      'Il’inskiy Pogost',
      'Il’inskiy',
      'Ilyinogorsk',
      'Il’ichëvo',
      'Il’ich',
      'Ilek',
      'Iksha',
      'Ikryanoye',
      'Ikon-Khalk',
      'Iki-Burul',
      'Igra',
      'Ignatovka',
      'Iglino',
      'Idritsa',
      'Ibresi',
      'Gzhel’',
      'Elin-Yurt',
      'Gvardeysk',
      'Gus’-Zheleznyy',
      'Gus’-Khrustal’nyy',
      'Gusevskiy',
      'Gusev',
      'Gur’yevsk',
      'Gurbuki',
      'Gunib',
      'Gundorovskiy',
      'Gundelen',
      'Gumrak',
      'Gul’kevichi',
      'Gukovo',
      'Gudermes',
      'Gubskaya',
      'Gubkin',
      'Gubden',
      'Gubakha',
      'Gryazovets',
      'Gryazi',
      'Grushevskaya',
      'Martan-Chu',
      'Groznyy',
      'Grivenskaya',
      'Gritsovskiy',
      'Grigoropolisskaya',
      'Gribanovskiy',
      'Gremyachinsk',
      'Gremyachevo',
      'Grecheskoye',
      'Grebenskaya',
      'Grazhdanskoye',
      'Grazhdanka',
      'Grayvoron',
      'Grakhovo',
      'Grabovo',
      'Goyty',
      'Gostilitsy',
      'Gostagayevskaya',
      'Goryachiy Klyuch',
      'Goryachevodskiy',
      'Abram Mys',
      'Gorskaya',
      'Gorshechnoye',
      'Gorokhovets',
      'Gorodovikovsk',
      'Gorodoviki',
      'Gorodnya',
      'Gorodishche',
      'Gorodets',
      'Gornyy Balykley',
      'Gornyy',
      'Gornyatskiy',
      'Gornozavodsk',
      'Gorki Vtoryye',
      'Gorki-Leninskiye',
      'Goritsy',
      'Goreloye',
      'Gorelovo',
      'Gorelki',
      'Gordeyevka',
      'Gorbunki',
      'Gorbatovka',
      'Gorbatov',
      'Goragorskiy',
      'Golynki',
      'Gol’yanovo',
      'Golubitskaya',
      'Golovino',
      'Golovchino',
      'Golitsyno',
      'Gagatli',
      'Gofitskoye',
      'Glushkovo',
      'Glubokoye',
      'Glubokiy',
      'Glotovka',
      'Glinka',
      'Glinishchevo',
      'Glembochino',
      'Glebychevo',
      'Glazunovka',
      'Glazov',
      'Glafirovka',
      'Gizel’',
      'Girvas',
      'Girey',
      'Gimry',
      'Gigant',
      'Gidrotorf',
      'Giaginskaya',
      'Gerzel’-Aul',
      'Germenchuk',
      'Germenchik',
      'Gergebil’',
      'Georgiyevskoye',
      'Georgiyevskaya',
      'Georgiyevsk',
      'Georgiyevka',
      'Gelendzhik',
      'Gekhi',
      'Gdov',
      'Gayny',
      'Gayduk',
      'Gay',
      'Gavrilov-Yam',
      'Gavrilov Posad',
      'Gavrilovka Vtoraya',
      'Gatchina',
      'Garbolovo',
      'Gaptsakh',
      'Gamovo',
      'Galyugayevskaya',
      'Galitsy',
      'Galich',
      'Gagino',
      'Gagarin',
      'Gadzhiyevo',
      'Furmanov',
      'Fryazino',
      'Fryazevo',
      'Fryanovo',
      'Froly',
      'Frolovo',
      'Frolishchi',
      'Fosforitnyy',
      'Fornosovo',
      'Foki',
      'Firsanovka',
      'Firovo',
      'Filimonki',
      'Fili',
      'Ferzikovo',
      'Fershampenuaz',
      'Ferapontovo',
      'Fedurnovo',
      'Fëdorovskoye',
      'Fatezh',
      'Fastovetskaya',
      'Falënki',
      'Fakel',
      'Ezhva',
      'Etoka',
      'Essoyla',
      'Ertil’',
      'Erpeli',
      'Engel’-Yurt',
      'Engels',
      'Energetik',
      'Enem',
      'Emmaus',
      'El’ton',
      'Elista',
      'Elektrougli',
      'Elektrostal’',
      'Elektrogorsk',
      'El’brus',
      'Ekazhevo',
      'Edissiya',
      'Dzhubga',
      'Dzhiginka',
      'Dzhayrakh',
      'Dzhalka',
      'Dzhalil’',
      'Dzerzhinskiy',
      'Dzerzhinsk',
      'Dyurtyuli',
      'Dyshne-Vedeno',
      'Dylym',
      'Dyat’kovo',
      'Dvortsy',
      'Dvinskoy',
      'Duvan',
      'Dorgeli',
      'Duminichi',
      'Dulyapino',
      'Dukhovshchina',
      'Dukhovnitskoye',
      'Dubrovka',
      'Dubrovitsy',
      'Dubovyy Umët',
      'Dubovskoye',
      'Dubovka',
      'Dubna',
      'Dubki',
      'Duba-Yurt',
      'Druzhnaya Gorka',
      'Druzhinino',
      'Druzhba',
      'Drezna',
      'Drakino',
      'Doschatoye',
      'Dorokhovo',
      'Dorogomilovo',
      'Dorogobuzh',
      'Donskoye',
      'Donskoy',
      'Donetsk',
      'Dondukovskaya',
      'Domodedovo',
      'Dombarovskiy',
      'Dolzhanskaya',
      'Dolgorukovo',
      'Dolgoprudnyy',
      'Dobryatino',
      'Dobryanka',
      'Dobrun’',
      'Dobroye',
      'Dobrinka',
      'Dno',
      'Dneprovskaya',
      'Dmitrovsk-Orlovskiy',
      'Dmitrovskiy Pogost',
      'Dmitrov',
      'Dmitriyevskoye',
      'Dmitriyevskaya',
      'Dmitriyevka',
      'Divnoye',
      'Divnomorskoye',
      'Diveyevo',
      'Dinskaya',
      'Dimitrovgrad',
      'Digora',
      'Deyskoye',
      'Devitsa',
      'Detchino',
      'Desnogorsk',
      'Deshovki',
      'Dergachi',
      'Derbent',
      'Demyansk',
      'Dem’yanovo',
      'Demikhovo',
      'Demidov',
      'Dedovsk',
      'Dedovichi',
      'Dedino',
      'Dedenëvo',
      'Debesy',
      'Davydovo',
      'Davydovka',
      'Davydkovo',
      'Davlekanovo',
      'Darovskoy',
      'Dankov',
      'Danki',
      'Danilovka',
      'Danilov',
      'Dal’neye Konstantinovo',
      'Dalakovo',
      'Dagestanskiye Ogni',
      'Dachnoye',
      'Chusovoy',
      'Churovichi',
      'Chupa',
      'Chukhloma',
      'Chufarovo',
      'Chudovo',
      'Chuchkovo',
      'Chontaul',
      'Chkalovskiy',
      'Chkalovsk',
      'Chistoye',
      'Chistopol’',
      'Chishmy',
      'Chirkey',
      'Chiri-Yurt',
      'Chikola',
      'Chesnokovka',
      'Cherusti',
      'Chertkovo',
      'Chyorny Yar',
      'Chernyshkovskiy',
      'Chernyanka',
      'Chernyakhovsk',
      'Chernushka',
      'Chernukha',
      'Chernoyerkovskaya',
      'Chernomorskiy',
      'Chernolesskoye',
      'Chernoistochinsk',
      'Chernogolovka',
      'Chernitsyno',
      'Cherneya',
      'Chërnaya Kholunitsa',
      'Chornoye',
      'Chern’',
      'Chermoz',
      'Chermen',
      'Cherkizovo',
      'Cherkessk',
      'Cherkasskoye',
      'Cherepovets',
      'Cherepet’',
      'Cheremushskiy',
      'Cherëmushki',
      'Cherëmukhovo',
      'Cheremshan',
      'Cheremisinovo',
      'Cherdyn’',
      'Cherdakly',
      'Chemodanovka',
      'Chelno-Vershiny',
      'Chelbasskaya',
      'Chekmagush',
      'Chekhov',
      'Chekalin',
      'Chegem Vtoroy',
      'Chegem',
      'Chechen-Aul',
      'Chebsara',
      'Cheboksary',
      'Chaykovskiy',
      'Chaykovskaya',
      'Chastyye',
      'Chashnikovo',
      'Chaplygin',
      'Chapayevsk',
      'Chamzinka',
      'Chamlykskaya',
      'Chaltyr',
      'Chagoda',
      'Chaadayevka',
      'Bytosh’',
      'Bystrogorskiy',
      'Bylym',
      'Bykovo',
      'Buzuluk',
      'Buzdyak',
      'Buynaksk',
      'Buy',
      'Buturlinovka',
      'Buturlino',
      'Burtunay',
      'Burmakino',
      'Burlatskoye',
      'Buribay',
      'Burevestnik',
      'Burayevo',
      'Bolgar',
      'Bulgakovo',
      'Buinsk',
      'Buguruslan',
      'Bugulma',
      'Budogoshch’',
      'Budënnovsk',
      'Bryukhovetskaya',
      'Bryansk',
      'Brusyanka',
      'Bronnitsy',
      'Breytovo',
      'Nogamerzin-Yurt',
      'Bratovshchina',
      'Brateyevo',
      'Borzoy',
      'Borskoye',
      'Borovsk',
      'Borovoy',
      'Borovichi',
      'Borok',
      'Borodinskiy',
      'Orekhovo-Borisovo Yuzhnoye',
      'Borisovka',
      'Borisova Griva',
      'Borisoglebskiy',
      'Borisoglebsk',
      'Borinskoye',
      'Borgustanskaya',
      'Bor',
      'Bamut',
      'Bol’shoy Khomutets',
      'Bol’shoy Karay',
      'Bol’shoye Skuratovo',
      'Bol’shoye Selo',
      'Bol’shoye Murashkino',
      'Bol’shoye Kozino',
      'Bol’shoye Gryzlovo',
      'Bol’shoye Boldino',
      "Bol'shiye Vyazëmy",
      'Bolshevo',
      'Bol’shaya Setun’',
      'Bol’shaya Martynovka',
      'Bol’shaya Izhora',
      'Bolokhovo',
      'Bologoye',
      'Bolkhov',
      'Bolgatovo',
      'Boksitogorsk',
      'Bokovskaya',
      'Bokino',
      'Boguchar',
      'Bogovarovo',
      'Bogoslovka',
      'Bogorodskoye',
      'Bogorodsk',
      'Bogoroditsk',
      'Bogolyubovo',
      'Bogatyye Saby',
      'Bogatyr’',
      'Bogatoye',
      'Bobrov',
      'Blizhne-Pesochnoye',
      'Blechepsin',
      'Blagoyevo',
      'Blagoveshchensk',
      'Blagodatnoye',
      'Blagodarnyy',
      'Bizhbulyak',
      'Bisert’',
      'Biryulëvo',
      'Birsk',
      'Bilyarsk',
      'Bilimbay',
      'Bibirevo',
      'Bezopasnoye',
      'Bezhta',
      'Bezhetsk',
      'Bezhanitsy',
      'Bezenchuk',
      'Betlitsa',
      'Bessonovka',
      'Besskorbnaya',
      'Besleneyevskaya',
      'Besleney',
      'Beslan',
      'Beshpagir',
      'Bershet’',
      'Bernovo',
      'Berezniki',
      'Berëznik',
      'Berezayka',
      'Berezanskaya',
      'Berëza',
      'Bereslavka',
      'Berendeyevo',
      'Berdyaush',
      'Benoy-Yurt',
      'Belyy Gorodok',
      'Belyye Stolby',
      'Belyye Berega',
      'Belyy',
      'Belyayevka',
      'Belozërsk',
      'Beloye',
      'Belousovo',
      'Beloretsk',
      'Ilaskhan-Yurt',
      'Belorechensk',
      'Beloozërskiy',
      'Beloostrov',
      'Beloomut',
      'Belomorsk',
      'Belogor’ye',
      'Belinskiy',
      'Belgorod',
      'Belgatoy',
      'Belëv',
      'Belebey',
      'Belaya Rechka',
      'Belaya Kholunitsa',
      'Belaya Kalitva',
      'Belaya Glina',
      'Belaya Berëzka',
      'Belaya',
      'Bekovo',
      'Bekhteyevka',
      'Bekeshevskaya',
      'Begunitsy',
      'Begichevskiy',
      'Spassk',
      'Bedeyeva Polyana',
      'Bazarnyy Syzgan',
      'Bazarnyy Karabulak',
      'Bazarnyye Mataki',
      'Baymak',
      'Bavtugay',
      'Bavly',
      'Bavleny',
      'Batyrevo',
      'Baturinskaya',
      'Batetskiy',
      'Botayurt',
      'Bataysk',
      'Botashyurt',
      'Bashmakovo',
      'Barysh',
      'Barybino',
      'Baryatino',
      'Barvikha',
      'Barsukovskaya',
      'Barsuki',
      'Barda',
      'Barashevo',
      'Baranchinskiy',
      'Bammatyurt',
      'Baltay',
      'Baltasi',
      'Balezino',
      'Balashov',
      'Balashikha',
      'Balasheyka',
      'Balakovo',
      'Balakirevo',
      'Balakhonovskoye',
      'Novaya Balakhna',
      'Balabanovo',
      'Baksheyevo',
      'Baksanenok',
      'Baksan',
      'Bakhilovo',
      'Bakaly',
      'Bakal',
      'Bagrationovsk',
      'Bagramovo',
      'Bagayevskaya',
      'Bachi-Yurt',
      'Babynino',
      'Babushkin',
      'Babugent',
      'Babino',
      'Babayurt',
      'Babayevo',
      'Azovskaya',
      'Azov',
      'Ayutinskiy',
      'Aysha',
      'Aykino',
      'Avtury',
      'Avtovo',
      'Avsyunino',
      'Avdon',
      'Aushiger',
      'Atyashevo',
      'Atkarsk',
      'Atig',
      'Ateptsevo',
      'Atemar',
      'Atamanskaya',
      'Astrakhan',
      'Assinovskaya',
      'Askino',
      'Ashukino',
      'Ashitkovo',
      'Ashil’ta',
      'Asha',
      'Asekeyevo',
      'Asanovo',
      'Arzgir',
      'Arzamas',
      'Ar’ya',
      'Arti',
      'Arsk',
      'Arshan’',
      'Arsen’yevo',
      'Arsaki',
      'Armavir',
      'Arkul’',
      'Arkhonskaya',
      'Arkhipovka',
      'Arkhipo-Osipovka',
      'Arkhangel’skoye',
      'Arkhangel’sk',
      'Arkadak',
      'Argun',
      'Argudan',
      'Ardon’',
      'Ardon',
      'Ardatov',
      'Arbazh',
      'Apsheronsk',
      'Aprelevka',
      'Apatity',
      'Apastovo',
      'Antropovo',
      'Antipovka',
      'Ansalta',
      'Anopino',
      'Anna',
      'Andzhiyevskiy',
      'Andreyevskoye',
      'Andreyevo',
      'Andreyevka',
      'Endirey',
      'Andreapol’',
      'Andi',
      'Anastasiyevskaya',
      'Anastasiyevka',
      'Anapskaya',
      'Anapa',
      'Amzya',
      'Amin’yevo',
      'Altukhovo',
      'Altud',
      'Alnashi',
      'Al’met’yevsk',
      'Almaznyy',
      'Alleroy',
      'Alkhazurovo',
      'Alkhan-Yurt',
      'Alkhan-Kala',
      'Ali-Yurt',
      'Alikovo',
      'Ali-Berdukovskiy',
      'Aleksin',
      'Alekseyevskoye',
      'Alekseyevskaya',
      'Alekseyevka',
      'Aleksandrovskoye',
      'Aleksandrovskaya',
      'Aleksandrovka',
      'Aleksandrovsk',
      'Aleksandrov Gay',
      'Aleksandrov',
      'Aleksandro-Nevskiy',
      'Aleksandriyskaya',
      'Aleksandriya',
      'Alatyr’',
      'Alandskoye',
      'Alakurtti',
      'Alabushevo',
      'Ak”yar',
      'Akusha',
      'Aktyubinskiy',
      'Russkiy Aktash',
      'Aktanysh',
      'Levoberezhnyy',
      'Aksay',
      'Aksarayskiy',
      'Aksakovo',
      'Aknada',
      'Staraya Akkermanovka',
      'Akhunovo',
      'Akhtyrskiy',
      'Akhty',
      'Akhtubinsk',
      'Akhtanizovskaya',
      'Agvali',
      'Agryz',
      'Agronom',
      'Agoy',
      'Ageyevo',
      'Agapovka',
      'Agalatovo',
      'Afrikanda',
      'Afonino',
      'Afipskiy',
      'Adyge-Khabl',
      'Adler',
      'Adil’-Yangiyurt',
      'Adamovka',
      'Achkhoy-Martan',
      'Achit',
      'Achisu',
      'Achikulak',
      'Abzakovo',
      'Abrau-Dyurso',
      'Abramovka',
      'Abinsk',
      'Abdulino',
      'Abadzekhskaya',
      'Vasil’yevo',
      'Ryl’sk',
      'Gedzhukh',
      'Snezhnogorsk',
      'Kokrek',
      'Kontenko',
      'Vyshestebliyevskaya',
      'Vostochny',
      'Kara-Tyube',
      'Neftekumsk',
      'Imeni Pervogo Maya',
      'Rabocheostrovsk',
      'Lutkun',
      'Gerga',
      'Alagir',
      'Yuzhnyy',
      'Kovalevskoye',
      'Persianovka',
      'Karamakhi',
      'Alkhan-Churt',
      'Novyy Khushet',
      'Khambi-Irze',
      'Orël-Izumrud',
      'Vysokoye',
      'Zavetnyy',
      'Yasnaya Polyana',
      'Ivanovka',
      'Rozhdestvenskiy',
      'Vorotynsk',
      'Chupryakovo',
      'Razvilka',
      'Dagomys',
      'Gornoye Loo',
      'Bugry',
      'Ferma',
      'Gorod Shebekino',
      'Korkmaskala',
      "Bol'shoe Isakovo",
      'Maloye Isakovo',
      'Surok',
      'Partsa',
      'Udarnyy',
      'Lepley',
      'Atlashevo',
      'Belidzhi',
      'Khazar',
      'Zaozërsk',
      'Pervoye Maya',
      'Pasha',
      'Kondratovo',
      'Ileza',
      'Trëkhgornyy',
      'Zvëzdnyy',
      'Bologoye-4',
      'Dvubratskiy',
      'Kochubeyevskoye',
      'Novoterskiy',
      'Zmeyka',
      'Svetlopolyansk',
      'Net’inka',
      'Putëvka',
      'Kazachka',
      'Vnukovo',
      'Moskovskiy',
      'Zhitnevo',
      'Zubutli-Miatli',
      'Shamil’kala',
      'Novyy Sulak',
      'Novyy Kostek',
      'Usinsk',
      'Krasnyy Kommunar',
      'Mednogorskiy',
      'Khankala',
      'Pravokubanskiy',
      'Novyye Lapsary',
      'Raduzhnyy',
      'Priladozhskiy',
      'Molochnyy',
      'Iskateley',
      'Tsibanobalka',
      'Chinar',
      'Petra-Dubrava',
      'Povolzhskiy',
      'Verkhnebakanskiy',
      'Kochubey',
      'Nezhinskiy',
      'Tyube',
      'Novyy Chirkey',
      'Uchkent',
      'Mursalimkino',
      'Belozërnyy',
      'Sovkhoznyy',
      'Biorki',
      'Galashki',
      'Staryy Malgobek',
      'Krasnobrodskiy',
      'Zyuzel’skiy',
      'Zyryanskoye',
      'Zverinogolovskoye',
      'Zudilovo',
      'Zonal’noye',
      'Zmeinogorsk',
      'Zelënyy Bor',
      'Zelenogorskiy',
      'Zelenoborsk',
      'Zdvinsk',
      'Zaykovo',
      'Zav’yalovo',
      'Zavodouspenskoye',
      'Zavodoukovsk',
      'Zaton',
      'Zarinsk',
      'Zalesovo',
      'Yuzhnoural’sk',
      'Yushala',
      'Yurty',
      'Yurginskoye',
      'Yurgamysh',
      'Yurga',
      'Yetkul’',
      'Yertarskiy',
      'Yermakovskoye',
      'Yeniseysk',
      'Yemel’yanovo',
      'Yemanzhelinsk',
      'Yemanzhelinka',
      'Yelanskiy',
      'Yekaterinburg',
      'Yaya',
      'Yashkino',
      'Yar-Sale',
      'Yarovoye',
      'Yarkovo',
      'Yalutorovsk',
      'Yagunovskiy',
      'Yagunovo',
      'Vvedenskoye',
      'Vorkuta',
      'Vorgashor',
      'Volchikha',
      'Volchansk',
      'Vlasikha',
      'Vkhodnoy',
      'Vishnëvogorsk',
      'Vinzili',
      'Vikulovo',
      'Veseloyarsk',
      'Vershina Tei',
      'Verkh-Suetka',
      'Verkhotur’ye',
      'Verkh-Tula',
      'Verkhnyaya Sysert’',
      'Verkhnyaya Sinyachikha',
      'Verkhnyaya Salda',
      'Verkhnyaya Pyshma',
      'Verkhnyaya Inta',
      'Verkhniy Ufaley',
      'Verkhniy Bekhtemir',
      'Verkh-Neyvinskiy',
      'Verkhneye Dubrovo',
      'Verkh-Katunskoye',
      'Verkh-Chebula',
      'Vengerovo',
      'Velikopetrovka',
      'Varna',
      'Vargashi',
      'Vakhrushevo',
      'Vagay',
      'Uzhur',
      'Uyar',
      'Uvel’skiy',
      'Uvat',
      'Usyatskoye',
      'Ust’yanka',
      'Ulagan',
      'Ust’-Tarka',
      'Ust’-Koksa',
      'Ust’-Kan',
      'Ust’-Kalmanka',
      'Ust’-Ishim',
      'Ust’-Isha',
      'Ust’-Charyshskaya Pristan’',
      'Ust’-Bagaryak',
      'Ust’-Abakan',
      'Uspenka',
      'Urengoy',
      'Uray',
      'Ural',
      'Uporovo',
      'Uk',
      'Uglovskoye',
      'Ubinskoye',
      'Tyumentsevo',
      'Tyumen',
      'Tyukhtet',
      'Tyukalinsk',
      'Tyubuk',
      'Tyazhinskiy',
      'Turukhansk',
      'Turochak',
      'Turinskaya Sloboda',
      'Turinsk',
      'Turgoyak',
      'Turan',
      'Tuim',
      'Tugulym',
      'Tsementnyy',
      'Tsementnozavodskiy',
      'Tselinnoye',
      'Trudarmeyskiy',
      'Topol’noye',
      'Topki',
      'Topchikha',
      'Toora-Khem',
      'Tomsk',
      'Tolmachëvo',
      'Togur',
      'Togul',
      'Toguchin',
      'Tobolsk',
      'Tisul’',
      'Tinskoy',
      'Timiryazevskoye',
      'Teya',
      'Tevriz',
      'Temirtau',
      'Tegul’det',
      'Teeli',
      'Tazovsky',
      'Tayzhina',
      'Tayshet',
      'Tayginka',
      'Tayga',
      'Tavricheskoye',
      'Tavda',
      'Tatarsk',
      'Tashtyp',
      'Tashtagol',
      'Tashara',
      'Tashanta',
      'Taseyevo',
      'Tarko-Sale',
      'Tara',
      'Talnakh',
      'Tal’menka',
      'Talitsa',
      'Tabuny',
      'Tabory',
      'Sysert’',
      'Suzun',
      'Sut-Khol’',
      'Suslovo',
      'Sumkino',
      'Sukpak',
      'Sukhoy Log',
      'Sukhobuzimskoye',
      'Stukovo',
      'Strezhevoy',
      'Staryy Togul',
      'Staryy Nadym',
      'Staropyshminsk',
      'Staropesterevo',
      'Starobachaty',
      'Staroaleyskoye',
      'Starobelokurikha',
      'Stantsionno-Oyashinskiy',
      'Srostki',
      'Sredneuralsk',
      'Sos’va',
      'Sorsk',
      'Sorokino',
      'Solton',
      'Soloneshnoye',
      'Sokur',
      'Sokolovo',
      'Smolenskoye',
      'Slavgorodskoye',
      'Slavgorod',
      'Sladkovo',
      'Shushenskoye',
      'Shumskiy',
      'Shumikha',
      'Shul’gin Log',
      'Shubenka',
      'Shitkino',
      'Shirokaya Rechka',
      'Shira',
      'Shipunovo',
      'Sherkaly',
      'Sheregesh',
      'Sherbakul’',
      'Shelabolikha',
      'Sheberta',
      'Shebalino',
      'Shchelkun',
      'Shatrovo',
      'Sharypovo',
      'Shalinskoye',
      'Shakhi',
      'Shagonar',
      'Shadrinsk',
      'Shabrovskiy',
      'Severo-Yeniseyskiy',
      'Severka',
      'Serov',
      'Selezyan',
      'Novoseleznëvo',
      'Sayansk',
      'Sayanogorsk',
      'Saryg-Sep',
      'Sargazy',
      'Sargatskoye',
      'Saranpaul’',
      'Sannikovo',
      'Samus’',
      'Samagaltay',
      'Salym',
      'Salekhard',
      'Salair',
      'Safakulevo',
      'Russkinskiye',
      'Russkaya Polyana',
      'Rubtsovsk',
      'Roza',
      'Rostovka',
      'Romanovo',
      'Rodino',
      'Rezh',
      'Reftinskiy',
      'Rebrikha',
      'Razdolinsk',
      'Pyshma',
      'Prosvet',
      'Promyshlennyy',
      'Promyshlennovskiy',
      'Promyshlennaya',
      'Prokudskoye',
      'Prokop’yevsk',
      'Pritomskiy',
      'Predivinsk',
      'Poykovskiy',
      'Potanino',
      'Pospelikha',
      'Polysayevo',
      'Polunochnoye',
      'Poltavka',
      'Polovinnoye',
      'Polevskoy',
      'Poletayevo',
      'Podsosnovo',
      'Podsineye',
      'Plast',
      'Pirovskoye',
      'Pioner',
      'Petukhovo',
      'Petropavlovskoye',
      'Petrokamenskoye',
      'Pelym',
      'Pavlogradka',
      'Partizanskoye',
      'Parizh',
      'Parabel’',
      'Pankrushikha',
      'Pangody',
      'Ovsyanka',
      'Ous',
      'Osinniki',
      'Orlik',
      'Ordynskoye',
      'Onokhino',
      'Onguday',
      'Omutinskiy',
      'Omsk',
      'Okoneshnikovo',
      'Odesskoye',
      'Obukhovskoye',
      'Ob’',
      'Nyda',
      'Nyagan',
      'Noyabrsk',
      'Novyy Urengoy',
      'Novaya Zaimka',
      'Novovarshavka',
      'Novotyryshkino',
      'Novosineglazovskiy',
      'Novosilikatnyy',
      'Novosibirsk',
      'Novopesterevo',
      'Novoomskiy',
      'Novolugovoye',
      'Novokuznetsk',
      'Novogornyy',
      'Novochernorechenskiy',
      'Novobiryusinskiy',
      'Novoasbest',
      'Novoaltaysk',
      'Novoagansk',
      'Novikovo',
      'Novichikha',
      'Novaya Lyalya',
      'Norilsk',
      'Nizhnyaya Tavda',
      'Souzga',
      'Nizhnyaya Salda',
      'Nizhnyaya Poyma',
      'Nizhnyaya Omka',
      'Nizhniy Ingash',
      'Nizhnevartovsk',
      'Nizhneudinsk',
      'Nikolo-Pavlovskoye',
      'Nikitinskiy',
      'Neyvo-Shaytanskiy',
      'Neyvo-Rudyanka',
      'Nev’yansk',
      'Gal’bshtadt',
      'Nefteyugansk',
      'Nazyvayevsk',
      'Nazarovo',
      'Nauchnyy Gorodok',
      'Nalobikha',
      'Nadym',
      'Myski',
      'Muzhi',
      'Muromtsevo',
      'Mundybash',
      'Mugur-Aksy',
      'Motygino',
      'Moshkovo',
      'Moryakovskiy Zaton',
      'Mortka',
      'Monetnyy',
      'Molchanovo',
      'Mokrousovo',
      'Mochishche',
      'Minusinsk',
      'Miasskoye',
      'Miass',
      'Mezhdurechenskiy',
      'Mel’nikovo',
      'Melioratorov',
      'Megion',
      'Mayma',
      'Maslyanino',
      'Martyush',
      'Martynovo',
      'Mariinsk',
      'Manzya',
      'Manzherok',
      'Mamontovo',
      'Malysheva',
      'Malougrenevo',
      'Malinovoye Ozero',
      'Lyubinskiy',
      'Luzino',
      'Lugovskoy',
      'Losinyy',
      'Lokosovo',
      'Lobva',
      'Listvyanskiy',
      'Listvyagi',
      'Linëvo',
      'Lesosibirsk',
      'Lesogorsk',
      'Lesnikovo',
      'Leninsk-Kuznetsky',
      'Labytnangi',
      'Kyzyl-Mazhalyk',
      'Kyzyl-Khaya',
      'Kyzyl',
      'Kytmanovo',
      'Kyshtym',
      'Kyshtovka',
      'Kvitok',
      'Kuzedeyevo',
      'Kuybyshev',
      'Kurtamysh',
      'Kurgan',
      'Kuragino',
      'Kupino',
      'Kungurtug',
      'Kunashak',
      'Kuminskiy',
      'Kulunda',
      'Kudryashovskiy',
      'Krutinka',
      'Krutikha',
      'Krutaya Gorka',
      'Krivosheino',
      'Krivodanovka',
      'Krasnozërskoye',
      'Krasnoyarsk',
      'Krasnoyarka',
      'Krasnoural’sk',
      'Krasnoturinsk',
      'Krasnoturansk',
      'Krasnoshchekovo',
      'Krasnoobsk',
      'Krasnokamensk',
      'Krasnogvardeyskiy',
      'Krapivinskiy',
      'Kozhevnikovo',
      'Koyelga',
      'Kosikha',
      'Koshurnikovo',
      'Kosh-Agach',
      'Kormilovka',
      'Korkino',
      'Kopeysk',
      'Kondinskoye',
      'Yugorsk',
      'Kommunisticheskiy',
      'Kolyvan’',
      'Kol’tsovo',
      'Kolpashevo',
      'Kolosovka',
      'Kodinsk',
      'Kochki',
      'Kochenëvo',
      'Klyuchi',
      'Klyuchevsk',
      'Kiselëvsk',
      'Kirovgrad',
      'Khulimsunt',
      'Khudoyelanskoye',
      'Khovu-Aksy',
      'Kharp',
      'Khanty-Mansiysk',
      'Khandagayty',
      'Khabary',
      'Ketovo',
      'Kemerovo',
      'Kedrovoye',
      'Kedrovka',
      'Kazanskoye',
      'Kazachinskoye',
      'Kaz',
      'Kayyerkan',
      'Kataysk',
      'Kasli',
      'Kartaly',
      'Karpinsk',
      'Kargat',
      'Kargasok',
      'Karatuzskoye',
      'Karasuk',
      'Karagayla',
      'Kansk',
      'Kanashevo',
      'Kamyshlov',
      'Kamensk-Ural’skiy',
      'Mys-Kamennyy',
      'Kamen’-na-Obi',
      'Kaltay',
      'Kaltan',
      'Kalmanka',
      'Kalinovo',
      'Kalachinsk',
      'Kaa-Khem',
      'Izumrud',
      'Izhmorskiy',
      'Ivdel’',
      'Itatskiy',
      'Iskitim',
      'Ishim',
      'Isetskoye',
      'Iset’',
      'Irtyshskiy',
      'Irsha',
      'Irbit',
      'Irbeyskoye',
      'Inya',
      'Inta',
      'Inskoy',
      'Ilanskiy',
      'Ikovka',
      'Igrim',
      'Igarka',
      'Idrinskoye',
      'Gryaznovskoye',
      'Grishkovka',
      'Gramoteino',
      'Gornyy Shchit',
      'Gornyak',
      'Gornopravdinsk',
      'Gorno-Altaysk',
      'Plotnikovo',
      'Gon’ba',
      'Golyshmanovo',
      'Glyadyanskoye',
      'Gari',
      'Filimonovo',
      'Erzin',
      'Elekmonar',
      'Dzerzhinskoye',
      'Dvurechensk',
      'Dudinka',
      'Dubrovino',
      'Dubinino',
      'Dorogino',
      'Dolgoderevenskoye',
      'Divnogorsk',
      'Dikson',
      'Degtyarsk',
      'Dalmatovo',
      'Chunskiy',
      'Chunoyar',
      'Chulym',
      'Choya',
      'Chistogorskiy',
      'Chesma',
      'Chervishevo',
      'Chernogorsk',
      'Cherlak',
      'Cherga',
      'Cherepanovo',
      'Cheremshanka',
      'Cheremnoye',
      'Novobirilyussy',
      'Chemal',
      'Chelyabinsk',
      'Chebarkul’',
      'Charyshskoye',
      'Chany',
      'Chadan',
      'Bystryy Istok',
      'Bystryanka',
      'Butka',
      'Burla',
      'Bulanash',
      'Brodokalmak',
      'Bredy',
      'Borovskiy',
      'Borovikha',
      'Borodino',
      'Bol’sherech’ye',
      'Bolotnoye',
      'Boguchany',
      'Bograd',
      'Bogotol',
      'Bogdanovich',
      'Bogashevo',
      'Bogandinskiy',
      'Bobrovskiy',
      'Bobrovka',
      'Blagoveshchenka',
      'Biysk',
      'Biskamzha',
      'Biryusinsk',
      'Beya',
      'Beryozovsky',
      'Berëzovskiy',
      'Berëzovo',
      'Berëzovka',
      'Beregovoy',
      'Berdsk',
      'Belyy Yar',
      'Belyashi',
      'Belozërskoye',
      'Beloyarskiy',
      'Beloyarsk',
      'Belovo',
      'Belokurikha',
      'Belogorsk',
      'Bayunovskiye Klyuchi',
      'Baykit',
      'Bay-Khaak',
      'Baykalovo',
      'Bayevo',
      'Bastan',
      'Barzas',
      'Baryshevo',
      'Barsovo',
      'Barnaul',
      'Barabinsk',
      'Baraba',
      'Balgazyn',
      'Balakhta',
      'Bakchar',
      'Bagan',
      'Bachatskiy',
      'Azovo',
      'Azanka',
      'Aya',
      'Atagay',
      'Askiz',
      'Asino',
      'Asbestovskiy',
      'Asbest',
      'Artyshta',
      'Artybash',
      'Artëmovskiy',
      'Aromashevo',
      'Armizonskoye',
      'Argayash',
      'Aramil',
      'Anzhero-Sudzhensk',
      'Anton’yevka',
      'Antipino',
      'Andra',
      'Alzamay',
      'Altayskoye',
      'Aleysk',
      'Alapayevsk',
      'Aktash',
      'Aksarka',
      'Ak-Dovurak',
      'Akademgorodok',
      'Agirish',
      'Aginskoye',
      'Achinsk',
      'Abaza',
      'Abatskoye',
      'Aban',
      'Abalak',
      'Abakan',
      'Abagur',
      'Ursk',
      'Snezhinsk',
      'Shuvakish',
      'Istok',
      'Priob’ye',
      'Il’ichevo',
      'Tanzybey',
      'Ozersk',
      'Novoural’sk',
      'Seversk',
      'Lokomotivnyy',
      'Solnechnyy',
      'Kedrovyy',
      'Sibirskiy',
      'Malinovskiy',
      'Novyye Zori',
      'Gubkinskiy',
      'Purpe',
      'Iogach',
      'Raduzhny',
      'Turtas',
      'Muravlenko',
      'Starokamyshinsk',
      'Timiryazevskiy',
      'Bazhovo',
      'Lyantor',
      'Sadovyy',
      'Uyskoye',
      'Krasnyy Chikoy',
      'Zima',
      'Zhireken',
      'Zhigansk',
      'Zhigalovo',
      'Zhemchug',
      'Zheleznogorsk-Ilimskiy',
      'Zhatay',
      'Zharikovo',
      'Zeya',
      'Zavodskoy',
      'Zavitinsk',
      'Zalari',
      'Zakamensk',
      'Zaigrayevo',
      'Zabaykal’sk',
      'Ytyk-Kyuyël’',
      'Yerofey Pavlovich',
      'Yerbogachen',
      'Yelantsy',
      'Yekaterinoslavka',
      'Yedogon',
      'Yakutsk',
      'Yakovlevka',
      'Vysokogornyy',
      'Rechka-Vydrino',
      'Vydrino',
      'Vyazemskiy',
      'Vrangel’',
      'Vozdvizhenka',
      'Vostok',
      'Volochayevka Vtoraya',
      'Vol’no-Nadezhdinskoye',
      'Volchanets',
      'Vladivostok',
      'Vladimiro-Aleksandrovskoye',
      'Vitim',
      'Vilyuysk',
      'Vikhorevka',
      'Vidim',
      'Vershino-Shakhtaminskiy',
      'Vershino-Darasunskiy',
      'Verkh-Usugli',
      'Verkhoyansk',
      'Verkhnevilyuysk',
      'Vanavara',
      'Ust’-Uda',
      'Ust’-Ordynskiy',
      'Ust-Maya',
      'Ust-Kuyga',
      'Ust’-Kut',
      'Ust’-Karsk',
      'Ust’-Ilimsk',
      'Ust’-Barguzin',
      'Ussuriysk',
      'Usol’ye-Sibirskoye',
      'Ushumun',
      'Urusha',
      'Urik',
      'Ulety',
      'Ulan-Ude',
      'Uglovoye',
      'Uglekamensk',
      'Udachny',
      'Tyrma',
      'Tyret’ Pervaya',
      'Tynda',
      'Tygda',
      'Turuntayevo',
      'Turka',
      'Tura',
      'Tulyushka',
      'Tulun',
      'Trudovoye',
      'Topolëvo',
      'Tommot',
      'Tokur',
      'Tiksi',
      'Fokino',
      'Terney',
      'Tel’ma',
      'Tayturka',
      'Tavrichanka',
      'Novonukutskiy',
      'Tataurovo',
      'Tarbagatay',
      'Tankhoy',
      'Taldan',
      'Talakan',
      'Taksimo',
      'Takhtamygda',
      'Tabaga',
      'Svirsk',
      'Svetlaya',
      'Suntar',
      'Sretensk',
      'Srednebelaya',
      'Spassk-Dal’niy',
      'Sotnikovo',
      'Sosnovo-Ozerskoye',
      'Solovjevsk',
      'Smolyaninovo',
      'Smolenshchina',
      'Smolenka',
      'Smidovich',
      'Slyudyanka',
      'Slavyanka',
      'Skovorodino',
      'Sivakovka',
      'Sivaki',
      'Shtykovo',
      'Shkotovo',
      'Shirokiy',
      'Shimanovsk',
      'Shilka',
      'Shestakovo',
      'Sheragul',
      'Shelopugino',
      'Shelekhov',
      'Sharalday',
      'Shamanka',
      'Severomuysk',
      'Severobaykal’sk',
      'Seryshevo',
      'Sergeyevka',
      'Serebryanyy Bor',
      'Selikhino',
      'Selenduma',
      'Nizhniy Sayantuy',
      'Saskylakh',
      'Sangar',
      'Russkiy',
      'Rudnyy',
      'Rudnogorsk',
      'Rudnaya Pristan’',
      'Romny',
      'Rettikhovka',
      'Razdol’noye',
      'Raychikhinsk',
      'Putyatin',
      'Primorskiy',
      'Priiskovyy',
      'Priargunsk',
      'Priamurskiy',
      'Preobrazheniye',
      'Poyarkovo',
      'Pos’yet',
      'Popova',
      'Pokrovsk',
      'Pokrovka',
      'Pokosnoye',
      'Pogranichnyy',
      'Plastun',
      'Pivovarikha',
      'Petrovsk-Zabaykal’skiy',
      'Petropavlovka',
      'Perfilovo',
      'Peleduy',
      'Partizansk',
      'Oyëk',
      'Onokhoy',
      'Olovyannaya',
      'Ol’ga',
      'Olenyok',
      'Olyokminsk',
      'Okino-Klyuchi',
      'Nyurba',
      'Novyy Zagan',
      'Novyy Uoyan',
      'Novosysoyevka',
      'Novoshakhtinskiy',
      'Novoraychikhinsk',
      'Novopavlovka',
      'Novonikol’sk',
      'Novokruchininskiy',
      'Novokizhinginsk',
      'Novobureyskiy',
      'Novaya Igirma',
      'Novaya Bryan’',
      'Nizhniy Tsasuchey',
      'Nizhniy Kuranakh',
      'Nizhniy Bestyakh',
      'Nizhneangarsk',
      'Nikolayevskiy',
      'Never',
      'Neryungri',
      'Nerchinskiy Zavod',
      'Nerchinsk',
      'Naushki',
      'Namtsy',
      'Nakhodka',
      'Mukhorshibir’',
      'Mukhen',
      'Mugun',
      'Monastyrishche',
      'Mokhsogollokh',
      'Mogzon',
      'Mogoytuy',
      'Mogocha',
      'Mnogovershinnyy',
      'Mnogoudobnoye',
      'Mishelevka',
      'Mirny',
      'Meget',
      'Mayya',
      'Markha',
      'Manzurka',
      'Mamakan',
      'Mama',
      'Makkaveyevo',
      'Magistral’nyy',
      'Magdagachi',
      'Magan',
      'Lyalichi',
      'Luchki',
      'Luchegorsk',
      'Londoko',
      'Livadiya',
      'Litovko',
      'Listvyanka',
      'Lipovtsy',
      'Lesozavodsk',
      'Lermontovka',
      'Lensk',
      'Lebedinyy',
      'Lazo',
      'Kysyl-Syr',
      'Kyren',
      'Kyra',
      'Kyakhta',
      'Kuytun',
      'Kutulik',
      'Kurumkan',
      'Kurort-Darasun',
      'Kultuk',
      'Kul’dur',
      'Kudara-Somon',
      'Krasnorechenskiy',
      'Kraskino',
      'Kotik',
      'Korfovskiy',
      'Konstantinovka',
      'Komsomolsk-on-Amur',
      'Kokuy',
      'Knyaze-Volkonskoye',
      'Knevichi',
      'Klyuchevskiy',
      'Klichka',
      'Kizhinga',
      'Kitoy',
      'Kirensk',
      'Kimil’tey',
      'Kichera',
      'Khuzhir',
      'Khurba',
      'Khrebtovaya',
      'Khorol’',
      'Khorinsk',
      'Khor',
      'Khonkholoy',
      'Kholtoson',
      'Kholbon',
      'Khingansk',
      'Khilok',
      'Khatassy',
      'Khatanga',
      'Khandyga',
      'Khabarovsk',
      'Kavalerovo',
      'Karymskoye',
      'Kangalassy',
      'Kamen’-Rybolov',
      'Kalga',
      'Kalanguy',
      'Kadaya',
      'Kachug',
      'Kabansk',
      'Izvestkovyy',
      'Ivolginsk',
      'Irkutsk',
      'Imeni Poliny Osipenko',
      'Il’ka',
      'Ilir',
      'Il’inka',
      'Ikey',
      'Gusinoye Ozero',
      'Gusinoozyorsk',
      'Guran',
      'Gornyye Klyuchi',
      'Gornorechenskiy',
      'Gorin',
      'Gazimurskiy Zavod',
      'Gadaley',
      'Ekimchan',
      'Dzhida',
      'Dzhebariki-Khaya',
      'Dunay',
      'Dul’durga',
      'Drovyanaya',
      'Dostoyevka',
      'Domna',
      'Dauriya',
      'Darasun',
      'Dalnerechensk',
      'Churapcha',
      'Chumikan',
      'Chul’man',
      'Chuguyevka',
      'Chkalovskoye',
      'Chita',
      'Chernyshevskiy',
      'Chernyshevsk',
      'Chernyshëvka',
      'Chernigovka',
      'Cheremkhovo',
      'Chegdomyn',
      'Chara',
      'Bureya',
      'Bukachacha',
      'Budagovo',
      'Borzya',
      'Borogontsy',
      'Bol’shoy Kunaley',
      'Bol’shoy Kamen’',
      'Bol’shaya Tura',
      'Bol’shaya Rechka',
      'Bokhan',
      'Bodaybo',
      'Birobidzhan',
      'Birakan',
      'Bira',
      'Bikin',
      'Bichura',
      'Berkakit',
      'Berëzovyy',
      'Bereznyaki',
      'Berdigestyakh',
      'Baykal’sk',
      'Bayangol',
      'Bayanday',
      'Batagay-Alyta',
      'Batagay',
      'Barguzin',
      'Barabash',
      'Balyaga',
      'Baley',
      'Balakhninskiy',
      'Balagansk',
      'Baklashi',
      'Bagdarin',
      'Badar',
      'Bada',
      'Babstovo',
      'Aykhal',
      'Ayan',
      'Atamanovka',
      'Artëm',
      'Arsen’yev',
      'Arkhara',
      'Anuchino',
      'Angarsk',
      'Amurzet',
      'Amursk',
      'Amga',
      'Amazar',
      'Aleksandrovskiy Zavod',
      'Aldan',
      'Aksha',
      'Afanas’yeva',
      'Deputatsky',
      'Novyy Urgal',
      'Dalnegorsk',
      'Bratsk',
      'Kuanda',
      'Novaya Chara',
      'Yantal’',
      'Khabarovsk Vtoroy',
      'Markova',
      'Ust’-Kamchatsk Staryy',
      'Vilyuchinsk',
      'Zyryanka',
      'Yuzhno-Sakhalinsk',
      'Yuzhno-Kurilsk',
      'Yelizovo',
      'Yagodnoye',
      'Yablochnyy',
      'Vanino',
      'Vakhrushev',
      'Ust’-Omchug',
      'Ust-Nera',
      'Uptar',
      'Uglezavodsk',
      'Uglegorsk',
      'Tymovskoye',
      'Tungor',
      'Tomari',
      'Tilichiki',
      'Tigil’',
      'Talaya',
      'Susuman',
      'Srednekolymsk',
      'Sovetskaya Gavan’',
      'Smirnykh',
      "Sinegor'ye",
      'Sinegorsk',
      'Shebunino',
      'Shakhtersk',
      'Seymchan',
      'Severo-Kuril’sk',
      'Pravda',
      'Poronaysk',
      'Pevek',
      'Petropavlovsk-Kamchatsky',
      'Paratunka',
      'Palatka',
      'Palana',
      'Ozernovskiy',
      'Otrada',
      'Ossora',
      'Orotukan',
      'Omsukchan',
      'Ola',
      'Okhotsk',
      'Okha',
      'Nogliki',
      'Nikolayevsk-on-Amure',
      'Nevel’sk',
      'Myaundzha',
      'Mil’kovo',
      'Mgachi',
      'Makarov',
      'Mago',
      'Magadan',
      'Lososina',
      'Leonidovo',
      'Lazarev',
      'Kuril’sk',
      'Kozyrëvsk',
      'Korsakov',
      'Kholodnyy',
      'Kholmsk',
      'Gastello',
      'Evensk',
      'Esso',
      'Dukat',
      'Dolinsk',
      'De-Kastri',
      'Chokurdakh',
      'Cherskiy',
      'Bykov',
      'Boshnyakovo',
      'Bilibino',
      'Beringovskiy',
      'Belaya Gora',
      'Atlasovo',
      'Arman’',
      'Aniva',
      'Anadyr',
      'Aleksandrovsk-Sakhalinskiy',
      'Yantarnyy',
      'Mamonovo',
      'Baltiysk',
      'Provideniya',
      'Lorino',
      'Lavrentiya',
      'Egvekinot',
      'Logovskoye',
      'Elitnyy',
      'Magas',
      'Vas’kovo',
      'Isakogorka',
      'Kurilovo',
      'Shishkin Les',
      'Posëlok Mar’ino',
      'Mosrentgen',
      'Zarech’ye',
      'Povedniki',
      'Chertanovo Yuzhnoye',
      'Sredniy',
      'Zagor’ye',
      'Orekhovo-Borisovo',
      'Pad’ Mel’nichnaya',
      'Imeni M. I. Kalinina',
      'Metrogorodok',
      'Novyy',
      'Chistyye Bory',
      'Zarya',
      'Stan-Bekhtemir',
      'Tsiolkovskiy',
      'Kogalym',
      'Pyt-Yakh',
      'Langepas',
      'Severomorsk-3',
      'Kievskiy',
      'Obolensk',
      'Nezhdaninskoe',
      'Vzmorye',
      'Shikotan',
      'Peresvet',
      'Blyasino',
      'Pribrezhnyy',
      'Nizhnesortymskiy',
      'Pokachi',
      'Izluchinsk',
      'Kusak',
      'Sudoverf’',
      'Singapay',
      'Norkino',
      'Shevlyakovo',
      'Noginsk-9',
      'Staroye Arakchino',
      'Sagan-Nur',
      'Voznesenskiy',
      'Emar',
      'Algatuy',
      'Kurortnyy',
      'Chernaya Rechka',
      'Untolovo',
      'Petrogradka',
      "Vasyl'evsky Ostrov",
      'Parnas',
      'Kalininskiy',
      'Krasnogvargeisky',
      'Admiralteisky',
      'Krestovskiy ostrov',
      'Akademicheskoe',
      'Finlyandskiy',
      'Centralniy',
      'Tonshalovo',
      'Svetlanovskiy',
      'Sampsonievskiy',
      'Vostochnoe Degunino',
      'Dzerzhinsky',
      'Blagovetschenskaya',
      'Podosinki',
      'Avtopoligon',
      'Budenovetc',
      'Gorshkovo',
      "Ol'yavidovo",
      'Filippovka',
      'LMS',
      'Krasnaya Pahra',
      'Poselok Turisticheskogo pansionata Klyazminskoe vodohranilische',
      'Novoorlovsk',
      'Obruchevo',
      'Petrovskoye',
      'Tanais',
      'Semiozerje',
      'Fedorovskiy',
      'Vysokiy',
      'Vniissok',
      'Solnechniy',
      'Roschinskiy',
      'Korotchaevo',
      'Slavniy',
      "Mezgor'e",
      'Stantsia Staritsa',
      'Zagorskie Dali',
      'Im. Telmana Posyolok',
      'Ult-Ugun',
      'Pravohettinskiy',
      'Loza',
    ],
  },
  {
    country: 'Rwanda',
    cities: [
      'Rwamagana',
      'Musanze',
      'Nzega',
      'Kigali',
      'Kibuye',
      'Kibungo',
      'Gitarama',
      'Gikongoro',
      'Cyangugu',
      'Gisenyi',
      'Byumba',
      'Butare',
      'Nyanza',
      'Eglise Catholique, Centrale GIKO',
    ],
  },
  {
    country: 'Saudi Arabia',
    cities: [
      'Yanbu',
      'Unaizah',
      'Umluj',
      'Umm as Sāhik',
      'Turaif',
      'Turabah',
      'At Tūbī',
      'Tārūt',
      'Tanūmah',
      'Tabuk',
      'Ţubarjal',
      'Tabālah',
      'Sulţānah',
      'Sayhāt',
      'Şāmitah',
      'Sakakah',
      'Şafwá',
      'Şabyā',
      'Raḩīmah',
      'Rābigh',
      'Qal‘at Bīshah',
      'Najrān',
      'Mulayjah',
      'Mislīyah',
      'Marāt',
      'Mecca',
      'Khamis Mushait',
      'Julayjilah',
      'Jizan',
      'Jeddah',
      "Ha'il",
      'Hafar Al-Batin',
      'Farasān',
      'Duba',
      'Buraydah',
      'Abqaiq',
      'Badr Ḩunayn',
      'Az Zulfī',
      'Dhahran',
      'Aţ Ţaraf',
      'Ta’if',
      'As Sulayyil',
      'Sājir',
      'As Saffānīyah',
      'Riyadh',
      'Ar Rass',
      'Arar',
      'Qurayyat',
      'Al Wajh',
      'Al-`Ula',
      'Al Qurayn',
      'Qaisumah',
      'Al Qaţīf',
      'Al Qārah',
      'Al Muţayrifī',
      'Al Munayzilah',
      'Al Mubarraz',
      'Al Mindak',
      'Al Mithnab',
      'Al Markaz',
      'Al Majma‘ah',
      'Medina',
      'Khobar',
      'Al Kharj',
      'Al Khafjī',
      'Al Jumūm',
      'Al Jubayl',
      'Al Jarādīyah',
      'Al Jafr',
      'Al Hufūf',
      'Al Bukayrīyah',
      'Al Baţţālīyah',
      'Al Bahah',
      'Al Arţāwīyah',
      'Al Awjām',
      'Afif',
      'Adh Dhibiyah',
      'Ad Dilam',
      'Ad Dawādimī',
      'Ad Darb',
      'Dammam',
      'Abū ‘Arīsh',
      'Abha',
      'Şuwayr',
      'Wed Alnkil',
      'Tumayr',
      'Al Fuwayliq',
      'Al Majāridah',
      'Ain AlBaraha',
      'Al Muwayh',
      'Al Hadā',
      'Ash Shafā',
      'Mizhirah',
      'shokhaibٍ',
      'Sabt Alalayah',
    ],
  },
  {
    country: 'Solomon Islands',
    cities: [
      'Tulagi',
      'Malango',
      'Honiara',
      'Gizo',
      'Buala',
      'Auki',
      'Lata',
      'Kirakira',
      'Taro',
    ],
  },
  {
    country: 'Seychelles',
    cities: [
      'Victoria',
      'Takamaka',
      'Port Glaud',
      'Cascade',
      'Bel Ombre',
      'Beau Vallon',
      'Anse Royale',
      'Anse Boileau',
      'La Passe',
    ],
  },
  {
    country: 'Sudan',
    cities: [
      'Dongola',
      'Zalingei',
      'Wagar',
      'Wad Rāwah',
      'Wad Medani',
      'Wad az Zāki',
      'Umm Ruwaba',
      'Umm Kaddadah',
      'Um Jar Al Gharbiyya',
      'Omdurman',
      'Tokār',
      'Tandaltī',
      'Talodi',
      'Singa',
      'Shendi',
      'Sawākin',
      'Sinnar',
      'Rabak',
      'Nyala',
      'Merowe',
      'Marabba',
      'Maiurno',
      'Kutum',
      'Kosti',
      'Kurmuk',
      'Kuraymah',
      'Kināna',
      'Kassala',
      'Karmah an Nuzul',
      'Kadugli',
      'Gebeit',
      'Jalqani',
      'Doka',
      'Dilling',
      'Port Sudan',
      'Burri Al Drayssah',
      'Berber',
      'Bārah',
      'Atbara',
      'As Sūkī',
      'Ar Ruseris',
      'Ar Rahad',
      'Aroma',
      'Argo',
      'An Nuhūd',
      'El Obeid',
      'Al Qiţena',
      'Al Qadarif',
      'Al Masallamiyya',
      'Al Mijlad',
      'El Matama',
      'Al Manāqil',
      'Al Lagowa',
      'Khartoum',
      'Al Kiremit al ‘Arakiyyīn',
      'Al Kawa',
      'Geneina',
      'Al Hilāliyya',
      'Al Ḩawātah',
      'Al Hasaheisa',
      'Al Fūlah',
      'El Fasher',
      'El Bauga',
      'Ad Douiem',
      'El Daein',
      'Ad Dindar',
      'Ed Damer',
      'Ad-Damazin',
      'Ad Dabbah',
      'Abū Zabad',
      'Abu Jibeha',
      'Burri Al Shreef',
      'Nasir Extension',
      'Al Manshiya',
      'Al Giref Gharb',
      'Najaru قرية نجرو',
      'Gereida',
      'Burri Al Lamab',
      'El Fula',
      'Burri Al Mahas',
      'Nagaro',
      'Riyadh',
      'ِAl Taif',
      'Garden City',
    ],
  },
  {
    country: 'Sweden',
    cities: [
      'Viken',
      'Ursviken',
      'Umeå',
      'Töre',
      'Täfteå',
      'Storvik',
      'Södra Sunderbyn',
      'Skelleftehamn',
      'Skellefteå',
      'Råneå',
      'Sävar',
      'Rosvik',
      'Rolfs',
      'Roknäs',
      'Robertsfors',
      'Röbäck',
      'Sävast',
      'Piteå',
      'Pajala',
      'Övertorneå',
      'Överkalix',
      'Obbola',
      'Norrfjärden',
      'Malmberget',
      'Luleå',
      'Långsele',
      'Kiruna',
      'Kalix',
      'Kåge',
      'Hortlax',
      'Holmsund',
      'Haparanda',
      'Gammelstad',
      'Gällivare',
      'Forsbacka',
      'Ersmark',
      'Byske',
      'Burträsk',
      'Bureå',
      'Boliden',
      'Boden',
      'Bergsviken',
      'Bergnäset',
      'Backa',
      'Älvsbyn',
      'Alunda',
      'Anneberg',
      'Björklinge',
      'Marieberg',
      'Ystad',
      'Vrigstad',
      'Vittsjö',
      'Vislanda',
      'Viskafors',
      'Visby',
      'Virserum',
      'Vintrosa',
      'Vinslöv',
      'Vinninga',
      'Vingåker',
      'Vindeln',
      'Vimmerby',
      'Vilhelmina',
      'Vikingstad',
      'Vikarbyn',
      'Vi',
      'Vibble',
      'Vetlanda',
      'Vellinge',
      'Vejbystrand',
      'Veinge',
      'Veddige',
      'Veberöd',
      'Växjö',
      'Vaxholm',
      'Vattholma',
      'Västra Frölunda',
      'Västervik',
      'Västerås',
      'Vasastan',
      'Vårsta',
      'Värnamo',
      'Vargön',
      'Vårgårda',
      'Vårby',
      'Varberg',
      'Vara',
      'Vansbro',
      'Vännäsby',
      'Vännäs',
      'Vänersborg',
      'Vallentuna',
      'Vallda',
      'Valla',
      'Valje',
      'Valdemarsvik',
      'Valbo',
      'Vålberg',
      'Vagnhärad',
      'Vaggeryd',
      'Vadstena',
      'Uppsala',
      'Upplands Väsby',
      'Hjärup',
      'Ulricehamn',
      'Uddevalla',
      'Tyringe',
      'Tygelsjö',
      'Tvååker',
      'Tumba',
      'Tullinge',
      'Trosa',
      'Trönninge',
      'Trollhättan',
      'Gimo',
      'Trelleborg',
      'Trekanten',
      'Träslövsläge',
      'Tranemo',
      'Tranås',
      'Torup',
      'Torslanda',
      'Torshälla',
      'Torsby',
      'Torsås',
      'Tormestorp',
      'Torekov',
      'Töreboda',
      'Tomelilla',
      'Tollarp1',
      'Tollarp',
      'Töcksfors',
      'Tjuvkil',
      'Tingsryd',
      'Timrå',
      'Timmernabben',
      'Tillberga',
      'Tierp',
      'Tidaholm',
      'Tibro',
      'Tenhult',
      'Teckomatorp',
      'Tärnsjö',
      'Tanumshede',
      'Tallboda',
      'Täby',
      'Taberg',
      'Svenljunga',
      'Sveg',
      'Svedala',
      'Svärtinge',
      'Svärdsjö',
      'Svängsta',
      'Svanesund',
      'Svalsta',
      'Svalöv',
      'Surte',
      'Surahammar',
      'Sunne',
      'Sundsvall',
      'Sundsbruk',
      'Sundbyberg',
      'Styrsö',
      'Sturkö',
      'Sturefors',
      'Strövelstorp',
      'Strömsund',
      'Strömstad',
      'Strömsnäsbruk',
      'Strömma',
      'Strängnäs',
      'Storvreta',
      'Storuman',
      'Storfors',
      'Storebro',
      'Stora Höga',
      'Storå',
      'Stöpen',
      'Stockvik',
      'Stockholm',
      'Stockaryd',
      'Stigtomta',
      'Stenungsund',
      'Stenstorp',
      'Stenkullen',
      'Steninge',
      'Stenhamra',
      'Stehag',
      'Stavsnäs',
      'Stångby',
      'Stallarholmen',
      'Staffanstorp',
      'Gamla Stan',
      'Sösdala',
      'Sorsele',
      'Sörforsa',
      'Söråker',
      'Sölvesborg',
      'Solna',
      'Sollentuna',
      'Sollefteå',
      'Sollebrunn',
      'Södra Vi',
      'Södra Sandby',
      'Södertälje',
      'Södermalm',
      'Söderköping',
      'Söderhamn',
      'Söderfors',
      'Smygehamn',
      'Smögen',
      'Smedjebacken',
      'Smedby',
      'Smålandsstenar',
      'Slottsbron',
      'Skutskär',
      'Skurup',
      'Skultuna',
      'Skultorp',
      'Skrea',
      'Skövde',
      'Skottsund',
      'Sköllersta',
      'Skogstorp',
      'Skoghall',
      'Skivarp',
      'Skinnskatteberg',
      'Skillingaryd',
      'Skepplanda',
      'Skegrie',
      'Skattkärr',
      'Skärhamn',
      'Skåre',
      'Skärblacka',
      'Skara',
      'Skänninge',
      'Sjuntorp',
      'Sjömarken',
      'Sjöbo',
      'Simrishamn',
      'Siljansnäs',
      'Sigtuna',
      'Sibbhult',
      'Segeltorp',
      'Sävsjö',
      'Sävja',
      'Sätofta',
      'Sätila',
      'Säter',
      'Särö',
      'Sandviken',
      'Sandarne',
      'Sandared',
      'Saltsjöbaden',
      'Sålanda',
      'Sala',
      'Säffle',
      'Rydsgård',
      'Rydebäck',
      'Rydaholm',
      'Ryd',
      'Rottne',
      'Rosersberg',
      'Rönninge',
      'Ronneby',
      'Rönnäng',
      'Romme',
      'Rödeby',
      'Rinkabyholm',
      'Rindö',
      'Rimforsa',
      'Rimbo',
      'Resarö',
      'Reftele',
      'Rävlanda',
      'Rättvik',
      'Råtorp',
      'Råsunda',
      'Ramnäs',
      'Perstorp',
      'Pershagen',
      'Påskallavik',
      'Partille',
      'Pålsboda',
      'Påarp',
      'Oxie',
      'Oxelösund',
      'Överum',
      'Östra Ljungby',
      'Östhammar',
      'Östervåla',
      'Östersund',
      'Östermalm',
      'Österbymo',
      'Österbybruk',
      'Ösmo',
      'Oskarström',
      'Oskarshamn',
      'Osby',
      'Örsundsbro',
      'Orsa',
      'Örnsköldsvik',
      'Ornäs',
      'Örkelljunga',
      'Öregrund',
      'Örebro',
      'Örbyhus',
      'Önnestad',
      'Olstorp',
      'Olofström',
      'Olofstorp',
      'Öjersjö',
      'Ödsmål',
      'Ödeshög',
      'Odensjö',
      'Odensbacken',
      'Ödåkra',
      'Öckerö',
      'Ockelbo',
      'Nynäshamn',
      'Nykvarn',
      'Nyköping',
      'Nyhammar',
      'Nybro',
      'Nossebro',
      'Norsjö',
      'Norrtälje',
      'Norrköping',
      'Norra Åsum',
      'Nordmaling',
      'Norberg',
      'Nora',
      'Nolby',
      'Njurundabommen',
      'Nättraby',
      'Näsum',
      'Nässjö',
      'Nacka',
      'Myggenäs',
      'Munkfors',
      'Munkedal',
      'Munka-Ljungby',
      'Mullsjö',
      'Motala',
      'Mörtnäs',
      'Mörrum',
      'Morgongåva',
      'Mörbylånga',
      'Mörarp',
      'Mora',
      'Mönsterås',
      'Mölnlycke',
      'Mölndal',
      'Mölnbo',
      'Mölltorp',
      'Molkom',
      'Moheda',
      'Mockfjärd',
      'Mjölby',
      'Mjällby',
      'Mellerud',
      'Mellbystrand',
      'Matfors',
      'Märsta',
      'Markaryd',
      'Mariestad',
      'Marieholm',
      'Mariefred',
      'Mariannelund',
      'Mantorp',
      'Malung',
      'Malmslätt',
      'Malmö',
      'Malmköping',
      'Malmbäck',
      'Målilla',
      'Malå',
      'Lysekil',
      'Lycksele',
      'Lund',
      'Ludvika',
      'Lövstalöt',
      'Lönsboda',
      'Lomma',
      'Lödöse',
      'Löddeköpinge',
      'Löberöd',
      'Ljusne',
      'Ljusdal',
      'Ljungskile',
      'Ljungsbro',
      'Ljunghusen',
      'Ljungbyholm',
      'Ljungbyhed',
      'Ljungby',
      'Lit',
      'Linköping',
      'Linghem',
      'Lindsdal',
      'Lindome',
      'Lindö',
      'Lindholmen',
      'Lindesberg',
      'Limmared',
      'Lilla Edet',
      'Lidköping',
      'Lidingö',
      'Lessebo',
      'Lerum',
      'Lenhovda',
      'Leksand',
      'Laxå',
      'Långvik',
      'Långshyttan',
      'Länghem',
      'Landvetter',
      'Landskrona',
      'Landsbro',
      'Lammhult',
      'Laholm',
      'Lagan',
      'Kvissleby',
      'Kvidinge',
      'Kvicksund',
      'Kvänum',
      'Kungsör',
      'Kungsholmen',
      'Kungshamn',
      'Kungsbacka',
      'Kungsängen',
      'Kungälv',
      'Kummelnäs',
      'Kumla',
      'Krylbo',
      'Krokom',
      'Krokek',
      'Kristinehamn',
      'Kristianstad',
      'Kristdala',
      'Kramfors',
      'Kopparmora',
      'Kopparberg',
      'Köpmanholmen',
      'Köpingebro',
      'Köping',
      'Kolsva',
      'Kolbäck',
      'Kode',
      'Knivsta',
      'Knislinge',
      'Knäred',
      'Klippan',
      'Klintehamn',
      'Klågerup',
      'Kista',
      'Kisa',
      'Kinna',
      'Kimstad',
      'Kilafors',
      'Kil',
      'Kaxholmen',
      'Kävlinge',
      'Katrineholm',
      'Karlstad',
      'Karlskrona',
      'Karlskoga',
      'Karlshamn',
      'Karlsborg',
      'Karlholmsbruk',
      'Kalmar',
      'Kallinge',
      'Kållered',
      'Källby',
      'Kågeröd',
      'Jursla',
      'Jörlanda',
      'Jordbro',
      'Jonstorp',
      'Jönköping',
      'Jokkmokk',
      'Johannedal',
      'Järvsö',
      'Järpen',
      'Järna',
      'Järbo',
      'Jämshög',
      'Jämjö',
      'Jakobsberg',
      'Irsta',
      'Insjön',
      'Ingelstad',
      'Iggesund',
      'Hyltebruk',
      'Hyllinge',
      'Husum',
      'Huskvarna',
      'Hunnebostrand',
      'Hultsfred',
      'Hudiksvall',
      'Huddinge',
      'Hovsta',
      'Hovmantorp',
      'Höviksnäs',
      'Hova',
      'Horred',
      'Hörnefors',
      'Horndal',
      'Hörby',
      'Höör',
      'Hönö',
      'Hölö',
      'Höllviken',
      'Hököpinge',
      'Hökåsen',
      'Högsby',
      'Höganäs',
      'Hofterup',
      'Hofors',
      'Hjuvik',
      'Hjo',
      'Hjärnarp',
      'Hittarp',
      'Hindås',
      'Hillerstorp',
      'Hestra',
      'Herrljunga',
      'Herrestad',
      'Henån',
      'Hemse',
      'Hemmesta',
      'Helsingborg',
      'Hedesunda',
      'Hedemora',
      'Heby',
      'Haverdal',
      'Hästveda',
      'Hasslö',
      'Hässleholm',
      'Harplinge',
      'Härnösand',
      'Haninge',
      'Handen',
      'Hanaskog',
      'Hammerdal',
      'Hammarstrand',
      'Hammar',
      'Halmstad',
      'Hallstavik',
      'Hallstahammar',
      'Hallsberg',
      'Hällevik',
      'Hälleforsnäs',
      'Hällefors',
      'Hällbybrunn',
      'Hällabrottet',
      'Häljarp',
      'Hagfors',
      'Habo',
      'Gusum',
      'Gustavsberg',
      'Gunnebo',
      'Gullspång',
      'Gullbrandstorp',
      'Grycksbo',
      'Grums',
      'Grillby',
      'Grebo',
      'Grebbestad',
      'Grästorp',
      'Gränna',
      'Grängesberg',
      'Gråbo',
      'Götene',
      'Göteborg',
      'Göta',
      'Gnosjö',
      'Gnesta',
      'Gnarp',
      'Glumslöv',
      'Glommen',
      'Glimåkra',
      'Gislaved',
      'Getinge',
      'Genarp',
      'Gemla',
      'Gävle',
      'Gärsnäs',
      'Garphyttan',
      'Gantofta',
      'Gånghester',
      'Gamleby',
      'Gamla Uppsala',
      'Gagnef',
      'Fyllinge',
      'Furulund',
      'Frövi',
      'Frösakull',
      'Fritsla',
      'Fristad',
      'Frillesås',
      'Fränsta',
      'Förslöv',
      'Forsheda',
      'Forshaga',
      'Forserum',
      'Floby',
      'Flen',
      'Fjugesta',
      'Fjärås kyrkby',
      'Fjälkinge',
      'Fittja',
      'Finspång',
      'Filipstad',
      'Fellingsbro',
      'Färlöv',
      'Färjestaden',
      'Färila',
      'Färgelanda',
      'Falun',
      'Falköping',
      'Falkenberg',
      'Fagersta',
      'Eslöv',
      'Eskilstuna',
      'Eriksberg',
      'Enköping',
      'Enebyberg',
      'Enbacka',
      'Emmaboda',
      'Ellös',
      'Eksjö',
      'Ekshärad',
      'Ekerö',
      'Ekenässjön',
      'Ekeby-Almby',
      'Ekängen',
      'Edsbyn',
      'Ed',
      'Dorotea',
      'Donsö',
      'Djursholm',
      'Djurö',
      'Djurås',
      'Diseröd',
      'Dingtuna',
      'Delsbo',
      'Deje',
      'Degerfors',
      'Degeberga',
      'Dals Långed',
      'Dalsjöfors',
      'Dalby',
      'Dalarö',
      'Charlottenberg',
      'Bunkeflostrand',
      'Bua',
      'Brunna',
      'Brunn',
      'Brunflo',
      'Bromölla',
      'Bromma',
      'Broby',
      'Bro',
      'Brevik',
      'Bredbyn',
      'Bredaryd',
      'Brastad',
      'Brålanda',
      'Bräkne-Hoby',
      'Bräcke',
      'Braås',
      'Boxholm',
      'Borlänge',
      'Borgholm',
      'Borensberg',
      'Borås',
      'Bor',
      'Bollstabruk',
      'Bollnäs',
      'Bollmora',
      'Bollebygd',
      'Bodafors',
      'Boo',
      'Blomstermåla',
      'Blentarp',
      'Bjuv',
      'Bjursås',
      'Bjurholm',
      'Björneborg',
      'Björlanda',
      'Björkö',
      'Björboholm',
      'Bjästa',
      'Bjärred',
      'Bjärnum',
      'Billingsfors',
      'Billesholm',
      'Billeberga',
      'Billdal',
      'Bergsjö',
      'Bergshamra',
      'Hoverberg',
      'Bergkvara',
      'Bergeforsen',
      'Bergby',
      'Berg',
      'Bengtsfors',
      'Båstad',
      'Bårslöv',
      'Barkarö',
      'Bara',
      'Bankeryd',
      'Bålsta',
      'Bälinge',
      'Axvall',
      'Avesta',
      'Åtvidaberg',
      'Åstorp',
      'Asmundtorp',
      'Askersund',
      'Åsele',
      'Åseda',
      'Åsbro',
      'Åsa',
      'Ås',
      'Arvika',
      'Arvidsjaur',
      'Årsunda',
      'Årsta',
      'Arnö',
      'Arlöv',
      'Ärla',
      'Arjeplog',
      'Årjäng',
      'Arbrå',
      'Arboga',
      'Annelund',
      'Ankarsrum',
      'Angered',
      'Ängelholm',
      'Ånge',
      'Aneby',
      'Anderstorp',
      'Anderslöv',
      'Åmotfors',
      'Åmål',
      'Älvkarleby',
      'Alvesta',
      'Älvdalen',
      'Älvängen',
      'Älta',
      'Alsike',
      'Älmsta',
      'Älmhult',
      'Alingsås',
      'Alfta',
      'Åled',
      'Alafors',
      'Åkers Styckebruk',
      'Åkersberga',
      'Åkarp',
      'Åhus',
      'Åby',
      'Abborrberget',
      'Västra Hagen',
      'Enhagen-Ekbacken',
      'Saxtorpsskogen',
      'Andalen',
      'Nolvik',
      'Fisksätra',
      'Ekeby',
      'Skanör med Falsterbo',
      'Ingared',
      'Majorna',
      'Onsala',
      'Akalla',
      'Tensta',
      'Björkskatan',
      'Alby',
      'Hammarkullen',
      'Rannebergen',
      'Eriksbo',
      'Gårdsten',
      'Lövgärdet',
      'Åre',
      'Marielund',
    ],
  },
  {
    country: 'Singapore',
    cities: ['Singapore', 'Geylang', 'Woodlands', 'Marine Parade'],
  },
  {
    country: 'Saint Helena',
    cities: ['Georgetown', 'Edinburgh of the Seven Seas', 'Jamestown'],
  },
  {
    country: 'Slovenia',
    cities: [
      'Žužemberk',
      'Žiri',
      'Zgornji Duplek',
      'Zgornje Pirniče',
      'Zgornje Jezersko',
      'Zgornje Gorje',
      'Zgornje Bitnje',
      'Zgornja Polskava',
      'Zgornja Kungota',
      'Zgornja Hajdina',
      'Žetale',
      'Železniki',
      'Zavrč',
      'Žalec',
      'Zagorje ob Savi',
      'Zabukovica',
      'Vuzenica',
      'Vrtojba',
      'Vrhnika',
      'Vransko',
      'Vojnik',
      'Vodice',
      'Vnanje Gorice',
      'Vitomarci',
      'Vitanje',
      'Vipava',
      'Videm',
      'Veržej',
      'Verd',
      'Velike Lašče',
      'Velika Polana',
      'Turnišče',
      'Trzin',
      'Tržič',
      'Trnovska Vas',
      'Trnovlje pri Celju',
      'Trebnje',
      'Trbovlje',
      'Topolšica',
      'Sveti Tomaž',
      'Tolmin',
      'Velenje',
      'Tišina',
      'Tabor',
      'Sv. Duh',
      'Sv. Anton',
      'Sv. Ana v Slov. Goricah',
      'Štore',
      'Starše',
      'Središče ob Dravi',
      'Spodnji Duplek',
      'Spodnje Hoče',
      'Spodnja Idrija',
      'Šoštanj',
      'Solčava',
      'Sodražica',
      'Šmartno pri Slovenj Gradcu',
      'Šmartno pri Litiji',
      'Šmartno ob Paki',
      'Šmarjeta',
      'Šmarje pri Jelšah',
      'Šmarje-Sap',
      'Šmarca',
      'Slovenske Konjice',
      'Slovenska Bistrica',
      'Slovenj Gradec',
      'Škofljica',
      'Škofja Loka',
      'Škocjan',
      'Sežana',
      'Sevnica',
      'Šentvid pri Stični',
      'Šentrupert na Dolenjskem',
      'Šentjur',
      'Šentjernej',
      'Šentilj v Slov. Goricah',
      'Senovo',
      'Šenčur',
      'Šempeter v Savinj. Dolini',
      'Šempeter pri Gorici',
      'Šempas',
      'Semič',
      'Selnica ob Muri',
      'Šalovci',
      'Ruše',
      'Rogoza',
      'Rogatec',
      'Rogašovci',
      'Rogaška Slatina',
      'Ribnica na Pohorju',
      'Ribnica',
      'Renče',
      'Rečica ob Savinji',
      'Razvanje',
      'Ravne na Koroškem',
      'Ravne',
      'Rakičan',
      'Rakek',
      'Radovljica',
      'Radomlje',
      'Radlje ob Dravi',
      'Radenci',
      'Radeče',
      'Rače',
      'Puconci',
      'Ptuj',
      'Prvačina',
      'Prevalje',
      'Preserje pri Radomljah',
      'Preddvor',
      'Prebold',
      'Pragersko',
      'Postojna',
      'Portorož',
      'Polzela',
      'Poljčane',
      'Podvelka',
      'Podlehnik',
      'Podčetrtek',
      'Pivka',
      'Piran',
      'Pesnica',
      'Pekre',
      'Pameče',
      'Osilnica',
      'Ormož',
      'Oplotnica',
      'Odranci',
      'Novo Mesto',
      'Nova Vas',
      'Nova Gorica',
      'Notranje Gorice',
      'Naklo',
      'Muta',
      'Murska Sobota',
      'Mozirje',
      'Moste',
      'Morje',
      'Moravske Toplice',
      'Moravče',
      'Mokronog',
      'Mojstrana',
      'Mlaka pri Kranju',
      'Mislinja',
      'Mirna Peč',
      'Mirna',
      'Miren',
      'Miklavž na Dravskem Polju',
      'Mežica',
      'Metlika',
      'Mengeš',
      'Mekinje',
      'Medvode',
      'Markovci',
      'Maribor',
      'Makole',
      'Majšperk',
      'Lukovica pri Domžalah',
      'Luče',
      'Lovrenc na Pohorju',
      'Loški Potok',
      'Lokavec',
      'Log pri Brezovici',
      'Ljutomer',
      'Ljubno ob Savinji',
      'Ljubljana',
      'Ljubečna',
      'Litija',
      'Lipovci',
      'Limbuš',
      'Leskovec pri Krškem',
      'Lesce',
      'Lendava',
      'Lenart v Slov. Goricah',
      'Laško',
      'Kuzma',
      'Krško',
      'Kromberk',
      'Krog',
      'Križevci pri Ljutomeru',
      'Kranjska Gora',
      'Kranj',
      'Kozje',
      'Kotlje',
      'Kostanjevica na Krki',
      'Koper',
      'Komen',
      'Kokrica',
      'Kočevje',
      'Kobilje',
      'Kobarid',
      'Kidričevo',
      'Kanal',
      'Kamnik',
      'Kamnica',
      'Juršinci',
      'Jurovski Dol',
      'Sveti Jurij',
      'Jesenice',
      'Izola',
      'Izlake',
      'Ivančna Gorica',
      'Ilirska Bistrica',
      'Ig',
      'Idrija',
      'Hrvatini',
      'Hrušica',
      'Hrib-Loški Potok',
      'Hrastnik',
      'Hrastje',
      'Hotinja Vas',
      'Horjul',
      'Hodoš',
      'Grosuplje',
      'Sv. Trojica v Slov. Goricah',
      'Grad',
      'Gotovlje',
      'Gornji Petrovci',
      'Gornji Grad',
      'Gornja Radgona',
      'Gorišnica',
      'Gorenja Vas',
      'Straža',
      'Golnik',
      'Gančani',
      'Fram',
      'Dravograd',
      'Dragomer',
      'Dornava',
      'Domžale',
      'Dol pri Ljubljani',
      'Dol pri Hrastniku',
      'Dolenjske Toplice',
      'Dobrovo',
      'Dobrovnik',
      'Dobrova',
      'Dobrna',
      'Dobje pri Planini',
      'Dob',
      'Divača',
      'Destrnik',
      'Deskle',
      'Dekani',
      'Črnomelj',
      'Črna na Koroškem',
      'Črenšovci',
      'Černelavci',
      'Cerkvenjak',
      'Cerknica',
      'Cerklje na Gorenjskem',
      'Celje',
      'Cankova',
      'Britof',
      'Brezovica pri Ljubljani',
      'Brežice',
      'Bresternica',
      'Braslovče',
      'Bovec',
      'Borovnica',
      'Bohinjska Bistrica',
      'Bled',
      'Bistrica pri Tržiču',
      'Bistrica ob Sotli',
      'Bilje',
      'Benedikt',
      'Beltinci',
      'Bakovci',
      'Apače',
      'Ajdovščina',
      'Razkrižje',
      'Solkan',
      'Slovenski Javornik',
      'Koroška Bela',
      'Cerkno',
      'Leskova Dolina',
      'Logatec',
      'Ankaran',
      'Videm pri Ptuju',
      'Legen',
      'Zreče',
      'Radizel',
      'Selnica ob Dravi',
      'Lucija',
      'Jagodje',
      'Pobegi',
      'Spodnje Škofije',
      'Rožna Dolina',
      'Kisovec',
      'Cirkulane',
      'Komenda',
      'Nazarje',
      'Kostel',
      'Vir',
      'Lavrica',
      'Seča',
      'Prade',
      'Zasip',
      'Bistrica ob Dravi',
    ],
  },
  {
    country: 'Svalbard and Jan Mayen',
    cities: ['Longyearbyen', 'Olonkinbyen'],
  },
  {
    country: 'Slovakia',
    cities: [
      'Žehra',
      'Ždiar',
      'Vysoké Tatry',
      'Vyšné Ružbachy',
      'Vrbov',
      'Vranov nad Topľou',
      'Vinné',
      'Veľké Kapušany',
      'Trebišov',
      'Tornaľa',
      'Svit',
      'Svidník',
      'Stropkov',
      'Štrba',
      'Strážske',
      'Stará Ľubovňa',
      'Spišské Podhradie',
      'Spišská Nová Ves',
      'Spišská Belá',
      'Sobrance',
      'Snina',
      'Sečovce',
      'Sabinov',
      'Rožňava',
      'Rimavská Sobota',
      'Revúca',
      'Prešov',
      'Poprad',
      'Podolínec',
      'Nová Lesná',
      'Moldava nad Bodvou',
      'Michalovce',
      'Medzilaborce',
      'Medzev',
      'Ľubica',
      'Lipany',
      'Levoča',
      'Krompachy',
      'Košice',
      'Kežmarok',
      'Kavečany',
      'Jelšava',
      'Humenné',
      'Giraltovce',
      'Gelnica',
      'Dobšiná',
      'Čierna nad Tisou',
      'Chlmec',
      'Bardejov',
      'Zvolen',
      'Zlaté Moravce',
      'Žilina',
      'Žiar nad Hronom',
      'Želiezovce',
      'Žarnovica',
      'Vrútky',
      'Vrbové',
      'Vráble',
      'Veľký Meder',
      'Veľký Krtíš',
      'Lehota pod Vtáčnikom',
      'Tvrdošín',
      'Tužina',
      'Turzovka',
      'Turčianske Teplice',
      'Trstená',
      'Trnava',
      'Trenčín',
      'Trenčianske Teplice',
      'Topoľčany',
      'Tlmače',
      'Tisovec',
      'Terchová',
      'Svodín',
      'Svätý Jur',
      'Svätý Anton',
      'Šurany',
      'Štúrovo',
      'Stupava',
      'Stará Turá',
      'Smolenice',
      'Sliač',
      'Sládkovičovo',
      'Skalica',
      'Senica',
      'Senec',
      'Šamorín',
      'Šaľa',
      'Šahy',
      'Ružomberok',
      'Rajec',
      'Púchov',
      'Prievidza',
      'Pribylina',
      'Pravenec',
      'Považská Bystrica',
      'Poruba',
      'Poltár',
      'Piešťany',
      'Pezinok',
      'Partizánske',
      'Oravský Podzámok',
      'Nové Zámky',
      'Nové Mesto nad Váhom',
      'Nováky',
      'Nová Dubnica',
      'Nová Baňa',
      'Nižná',
      'Nitrianske Pravno',
      'Nitra',
      'Nemšová',
      'Nedožery - Brezany',
      'Námestovo',
      'Myjava',
      'Modra',
      'Martin',
      'Marianka',
      'Malacky',
      'Lúčky',
      'Lučenec',
      'Liptovský Mikuláš',
      'Liptovský Hrádok',
      'Levice',
      'Leopoldov',
      'Lazany',
      'Kysucké Nové Mesto',
      'Krupina',
      'Kremnica',
      'Krasňany',
      'Kováčová',
      'Komárno',
      'Kolárovo',
      'Kľačno',
      'Kanianka',
      'Jarovce',
      'Ivanka pri Dunaji',
      'Ilava',
      'Hybe',
      'Hurbanovo',
      'Hrochoť',
      'Hriňová',
      'Holíč',
      'Hlohovec',
      'Handlová',
      'Gbely',
      'Galanta',
      'Gabčíkovo',
      'Fiľakovo',
      'Bratislava - Vajnory',
      'Dunajská Streda',
      'Dudince',
      'Dubnica nad Váhom',
      'Dolný Kubín',
      'Detva',
      'Čierny Balog',
      'Čadca',
      'Čachtice',
      'Bytča',
      'Brezová pod Bradlom',
      'Brezno',
      'Bratislava',
      'Bojnice',
      'Banská Štiavnica',
      'Banská Bystrica',
      'Bánovce nad Bebravou',
      'Oravská Lesná',
      'Dunajská Lužná',
      'Vinosady',
    ],
  },
  {
    country: 'Sierra Leone',
    cities: [
      'Zimmi',
      'Yonibana',
      'Yengema',
      'Wima',
      'Waterloo',
      'Tongole',
      'Tombu',
      'Tombodu',
      'Tintafor',
      'Tefeya',
      'Sumbuya',
      'Simbakoro',
      'Serabu',
      'Seidu',
      'Segbwema',
      'Sawkta',
      'Rotifunk',
      'Rokupr',
      'Potoru',
      'Port Loko',
      'Koyima',
      'Pepel',
      'Pendembu',
      'Panguma',
      'Giehun',
      'Moyamba',
      'Motema',
      'Mogbwemo',
      'Mobai',
      'Masoyila',
      'Masingbi',
      'Masaka',
      'Manowa',
      'Mange',
      'Mamboma',
      'Mambolo',
      'Makeni',
      'Makali',
      'Magburaka',
      'Lunsar',
      'Loma',
      'Largo',
      'Kukuna',
      'Koribundu',
      'Koidu',
      'Kenema',
      'Kayima',
      'Kassiri',
      'Kambia',
      'Kamakwie',
      'Kailahun',
      'Kabala',
      'Jojoima',
      'Hastings',
      'Hangha',
      'Gorahun',
      'Gberia Fotombu',
      'Gandorhun',
      'Freetown',
      'Foindu',
      'Daru',
      'Buedu',
      'Bunumbu',
      'Bumpe',
      'Bumbuna',
      'Bonthe',
      'Bomi',
      'Boajibu',
      'Bo',
      'Blama',
      'Binkolo',
      'Bindi',
      'Barma',
      'Baoma',
      'Palima',
      'Baiima',
      'Alikalia',
      'Konakridee',
      'Gbewebu',
      'Pujehun',
      'Goderich',
      'Kent',
    ],
  },
  {
    country: 'San Marino',
    cities: [
      'Serravalle',
      'San Marino',
      'Poggio di Chiesanuova',
      'Monte Giardino',
      'Fiorentino',
      'Faetano',
      'Domagnano',
      'Borgo Maggiore',
      'Acquaviva',
    ],
  },
  {
    country: 'Senegal',
    cities: [
      'Ziguinchor',
      'Vélingara',
      'Touba',
      'Tionk Essil',
      'Tiébo',
      'Tiadiaye',
      'Thiès Nones',
      'Thiès',
      'Tambacounda',
      'Sokone',
      'Sémé',
      'Sédhiou',
      'Saint-Louis',
      'Rosso',
      'Richard-Toll',
      'Ranérou',
      'Pout',
      'Pourham',
      'Polel Diaoubé',
      'Pikine',
      'Passi',
      'Oussouye',
      'Ouro Sogui',
      'Waoundé',
      'Nioro du Rip',
      'Nguékhokh',
      'Ndofane',
      'Ndioum',
      'Ndibène Dahra',
      'Mékhé',
      'Mbaké',
      'Matam',
      'Marsassoum',
      'Louga',
      'Koungheul',
      'Kolda',
      'Khombole',
      'Kédougou',
      'Kayar',
      'Kaolack',
      'Kanel',
      'Kaffrine',
      'Joal-Fadiout',
      'Guinguinéo',
      'Guéoul',
      'Goléré',
      'Gandiaye',
      'Foundiougne',
      'Fatick',
      'Diourbel',
      'Diofior',
      'Diawara',
      'Dara',
      'Dakar',
      'Bignona',
      'Adéane',
      'Warang',
      'Mermoz Boabab',
      'N’diareme limamoulaye',
    ],
  },
  {
    country: 'Somalia',
    cities: [
      'Yeed',
      'Xuddur',
      'Xarardheere',
      'Wanlaweyn',
      'Waajid',
      'Tayeeglow',
      'Saacow',
      'Qoryooley',
      'Qandala',
      'Oodweyne',
      'Mogadishu',
      'Marka',
      'Mahaddayweyne',
      'Luuq',
      'Lughaye',
      'Las Khorey',
      'Kismayo',
      'Jilib',
      'Jawhar',
      'Jamaame',
      'Jalalaqsi',
      'Iskushuban',
      'Hobyo',
      'Hargeysa',
      'Garoowe',
      'Garbahaarrey',
      'Gaalkacyo',
      'Eyl',
      'Dujuuma',
      'Diinsoor',
      'Dhuusamarreeb',
      'Ceerigaabo',
      'Ceeldheer',
      'Ceelbuur',
      'Ceek',
      'Caluula',
      'Cadale',
      'Adado',
      'Buurhakaba',
      'Buur Gaabo',
      'Buulobarde',
      'Burao',
      'Bu’aale',
      'Bosaso',
      'Bereeda',
      'Berbera',
      'Beledweyne',
      'Baidoa',
      'Bargaal',
      'Bandarbeyla',
      'Baardheere',
      'Afgooye',
      'Laascaanood',
      'Baki',
      'Baligubadle',
      'Beled Hawo',
    ],
  },
  {
    country: 'Suriname',
    cities: [
      'Wageningen',
      'Totness',
      'Paramaribo',
      'Onverwacht',
      'Nieuw Nickerie',
      'Nieuw Amsterdam',
      'Moengo',
      'Meerzorg',
      'Mariënburg',
      'Lelydorp',
      'Groningen',
      'Brownsweg',
      'Brokopondo',
      'Albina',
    ],
  },
  {
    country: 'South Sudan',
    cities: [
      'Yirol',
      'Yei',
      'Yambio',
      'Winejok',
      'Wau',
      'Aweil',
      'Torit',
      'Tonj',
      'Tambura',
      'Rumbek',
      'Raja',
      'Maridi',
      'Malakal',
      'Leer',
      'Kuacjok',
      'Kodok',
      'Kapoeta',
      'Kafia Kingi',
      'Juba',
      'Gogrial',
      'Pajok',
      'Bor',
      'Bentiu',
    ],
  },
  {
    country: 'São Tomé and Príncipe',
    cities: [
      'Trindade',
      'São Tomé',
      'São João dos Angolares',
      'Santo António',
      'Guadalupe',
    ],
  },
  {
    country: 'El Salvador',
    cities: [
      'Zaragoza',
      'Zacatecoluca',
      'Victoria',
      'Usulután',
      'Tonacatepeque',
      'Texistepeque',
      'Tenancingo',
      'Tecoluca',
      'Tacuba',
      'Suchitoto',
      'Soyapango',
      'Sonzacate',
      'Sonsonate',
      'Sociedad',
      'Sesori',
      'Sensuntepeque',
      'San Vicente',
      'Santo Tomás',
      'Santiago Nonualco',
      'Santiago de María',
      'Santa Rosa de Lima',
      'Santa Elena',
      'Santa Ana',
      'San Sebastián',
      'San Salvador',
      'San Rafael Oriente',
      'San Pedro Masahuat',
      'San Pablo Tacachico',
      'San Miguel',
      'San Martín',
      'San Marcos',
      'San Juan Opico',
      'San Francisco Menéndez',
      'San Francisco',
      'San Antonio del Monte',
      'San Alejo',
      'San Agustín',
      'Rosario de Mora',
      'Quezaltepeque',
      'Puerto El Triunfo',
      'Pasaquina',
      'Panchimalco',
      'Ozatlán',
      'Olocuilta',
      'Nuevo Cuscatlán',
      'Santa Tecla',
      'Nueva Guadalupe',
      'Nueva Esparta',
      'Nueva Concepción',
      'Nahuizalco',
      'Moncagua',
      'Metapán',
      'Mejicanos',
      'Lolotique',
      'La Unión',
      'La Libertad',
      'Jujutla',
      'Jucuarán',
      'Jucuapa',
      'Juayúa',
      'Jocoro',
      'Jiquilisco',
      'Izalco',
      'Intipucá',
      'Ilopango',
      'Guazapa',
      'Guaymango',
      'Guatajiagua',
      'El Tránsito',
      'El Rosario',
      'El Paisnal',
      'El Congo',
      'Delgado',
      'Cuscatancingo',
      'Corinto',
      'Conchagua',
      'Concepción de Ataco',
      'Concepción Batres',
      'Cojutepeque',
      'Coatepeque',
      'Ciudad Barrios',
      'Ciudad Arce',
      'Chirilagua',
      'Chinameca',
      'Chapeltique',
      'Chalchuapa',
      'Chalatenango',
      'Candelaria de La Frontera',
      'Cacaopera',
      'Berlín',
      'Ayutuxtepeque',
      'Atiquizaya',
      'Armenia',
      'Apopa',
      'Apastepeque',
      'Antiguo Cuscatlán',
      'Anamorós',
      'Ahuachapán',
      'Aguilares',
      'Acajutla',
    ],
  },
  {
    country: 'Sint Maarten',
    cities: ['Philipsburg', 'Lower Prince’s Quarter', 'Cul de Sac', 'Koolbaai'],
  },
  {
    country: 'Syria',
    cities: [
      '‘Ayn Ḩalāqīm',
      'Al Karāmah',
      '‘Arīqah',
      'Aş Şūrah aş Şaghīrah',
      'Mūḩ Ḩasan',
      'Yabrūd',
      'Wādī al ‘Uyūn',
      '‘Uqayribāt',
      'Ţayyibat al Imām',
      'Tasīl',
      'Ţarţūs',
      'Tall Tamr',
      'Tall Salḩab',
      'Tall Rif‘at',
      'Tallkalakh',
      'Tall Ḩamīs',
      'Talldaww',
      'Tall aḑ Ḑamān',
      'Tall Abyaḑ',
      'Tālīn',
      'Tallbīsah',
      'Taftanāz',
      'Ţafas',
      'Tadmur',
      'Tādif',
      'Şūrān',
      'As Suqaylibīyah',
      'Subaykhān',
      'Şlinfah',
      'Şirrīn ash Shamālīyah',
      'Sirghāyā',
      'Sinjār',
      'Shuyūkh Taḩtānī',
      'Shīn',
      'Shaykh Miskīn',
      'Shaykh al Ḩadīd',
      'Shaţḩah',
      'Sharān',
      'Shaqqā',
      'Shahbā',
      'Şaydnāyā',
      'Sa‘sa‘',
      'Sarmīn',
      'Sarāqib',
      'Sulūk',
      'Salqīn',
      'Şalkhad',
      'As Salamīyah',
      'Şalākhid',
      'Şaḩnāyā',
      'Şāfītā',
      'Şadad',
      'Şabbūrah',
      'As Sab‘ Biyār',
      'Ar Riqāmā',
      'Rasm al Ḩarmal',
      'Ra’s al ‘Ayn',
      'Rankūs',
      'Rājū',
      'Al Quţaylibīyah',
      'Qūrqīnā',
      'Al Qaḩţānīyah',
      'Qaţanā',
      'Qasţal Ma‘āf',
      'Jāsim',
      'Qārah',
      'Al Qamşīyah',
      'Qal‘at al Maḑīq',
      'Qadsayyā',
      'Nubl',
      'Nawá',
      'Al Muzayrīb',
      'Al Muzayri‘ah',
      'Al Jalā’',
      'Mūrak',
      'Mukharram al Fawqānī',
      'Muḩradah',
      'Muḩambal',
      'Mazra‘at Bayt Jinn',
      'Maşyāf',
      'Maskanah',
      'Mashtá al Ḩulw',
      'Mas‘adah',
      'Markadah',
      'Māri‘',
      'Manbij',
      'Ma‘lūlā',
      'Malaḩ',
      'Mahīn',
      'Ma‘dān',
      'Maḑāyā',
      'Al Ma‘baţlī',
      'Ma‘arratmişrīn',
      'Ma‘arrat an Nu‘mān',
      'Kuwayris Sharqī',
      'Kurnāz',
      'Kinnsibbā',
      'Rabī‘ah',
      'Khushām',
      'Khirbat Tīn Nūr',
      'Al Qabw',
      'Khirbat al Ma‘azzah',
      'Khirbat Ghazālah',
      'Khān Shaykhūn',
      'Khanāşir',
      'Khān Arnabah',
      'Al Khafsah',
      'Al Ghandūrah',
      'Kassab',
      'Ra’s al Khashūfah',
      'Al Karīmah',
      'Kafr Zaytā',
      'Kafr Takhārīm',
      'Kafranbel',
      'Kafr Lāhā',
      'Kafr Baţnā',
      'Kafr Şaghīr',
      'Junaynat Raslān',
      'Jubb Ramlah',
      'Jubb al Jarrāḩ',
      'Al Jīzah',
      'Jisr ash Shughūr',
      'Jinnīyah',
      'Jayrūd',
      'Jawbat Burghāl',
      'Ar Rā‘ī',
      'Al Jarnīyah',
      'Jaramānā',
      'Jarābulus',
      'Jindayris',
      'Jablah',
      'Izra‘',
      '‘Irbīn',
      'Inkhil',
      'Idlib',
      'Ḩisyā’',
      'Ḩīsh',
      'Homs',
      'Ḩimmīn',
      'Ḩarrān al ‘Awāmīd',
      'Ḩuraytān',
      'Ḩārim',
      'Ḩarf al Musaytirah',
      'Ḩarbinafsah',
      'Ḩarastā',
      'Ḩammām Wāşil',
      'Ḩamāh',
      'Ḩalfāyā',
      'Aleppo',
      'Ḩadīdah',
      'Al Ḩāḑir',
      'Ghabāghib',
      'Al Furqlus',
      'Al Fākhūrah',
      'Duwayr Raslān',
      'Ad Duraykīsh',
      'Aḑ Ḑumayr',
      'Dūmā',
      'Damascus',
      'Dhībbīn',
      'Dhībān',
      'Dayr Ḩāfir',
      'Deir ez-Zor',
      'Dayr ‘Aţīyah',
      'Dayr al ‘Aşāfīr',
      'Dārat ‘Izzah',
      'Darkūsh',
      'Ad Darbāsīyah',
      'Dārayyā',
      'Dar‘ā',
      'Dā‘il',
      'Buşrá ash Shām',
      'Buq‘ātā',
      'Bulbul',
      'Brummānat al Mashāyikh',
      'Bi’r al Ḩulw al Wardīyah',
      'Binnish',
      'Bdāmā',
      'Ar Rawḑah',
      'Batabo',
      'Barrī ash Sharqī',
      'Bāniyās',
      'Banān',
      'Babīlā',
      'Az Ziyārah',
      'Az Zarbah',
      'Az Zabadānī',
      'I‘zāz',
      '‘Ayn ‘Īsá',
      '‘Ayn at Tīnah',
      'Sabbah',
      '‘Ayn Shiqāq',
      '‘Ayn ash Sharqīyah',
      '‘Ayn al Fījah',
      '‘Ayn al Bayḑā',
      '‘Ayn al ‘Arab',
      '‘Awaj',
      'At Tibnī',
      'Aţ Ţawāḩīn',
      'At Tamāni‘ah',
      'At Tall',
      'Ath Thawrah',
      'As-Suwayda',
      'As Sūsah',
      'As Sukhnah',
      'As Sīsnīyah',
      'Al Mazra‘ah',
      'As Sawdā',
      'Aş Şanamayn',
      '‘Assāl al Ward',
      'Aş Şafşāfah',
      'As Safīrah',
      'As Sabkhah',
      'As Si‘in',
      'Ash Shaykh Badr',
      'Ash Shajarah',
      'Ash Shaddādah',
      'Arwād',
      'Ar Ruḩaybah',
      'Ar Rastan',
      'Ar Raqqah',
      'Armanāz',
      'Arīḩā',
      'An Nashābīyah',
      'An Nabk',
      '‘Āmūdā',
      'Al Ghanţū',
      'Al Quţayfah',
      'Al Quşayr',
      'Al Qurayyā',
      'Al Qunayţirah',
      'Al Qaryatayn',
      'Al Qardāḩah',
      'Al Qāmishlī',
      'Al Qadmūs',
      'Al Mushannaf',
      'Al Musayfirah',
      'Al Mulayḩah',
      'Mismīyah',
      'Al Mayādīn',
      'Al Mālikīyah',
      'Latakia',
      'Al Kiswah',
      'An Nāşirah',
      'Al Jawādīyah',
      'Al Jānūdīyah',
      'Al Hinādī',
      'Al Ḩawl',
      'Al Ḩawāsh',
      'Al Ḩasakah',
      'Al Ḩirāk',
      'Al Ḩamrā’',
      'Al Ḩamīdīyah',
      'Al Ḩājib',
      'Al Ḩaffah',
      'Al Ghizlānīyah',
      'Al Ghāriyah',
      'Al Buşayrah',
      'Al Bāriqīyah',
      'Al Bahlūlīyah',
      'Al Bāb',
      'Al Atārib',
      'Al ‘Ashārah',
      'Al ‘Arīmah',
      'Al ‘Annāzah',
      'Akhtarīn',
      'Iḩsim',
      '‘Afrīn',
      'Ad Dīmās',
      'Ad Dānā',
      'Ad Dālīyah',
      'Abū Qalqal',
      'Ālbū Kamāl',
      'Hajīn',
      'Abū az̧ Z̧uhūr',
      'Al Ya‘rubīyah',
      '‘Arīshah',
      'Kaff al-Jaa',
      '‘Ayn an Nasr',
      'Nāḩiyat as Sab‘ Biyār',
      'Al Kasrah',
      'Al Ḩajar al Aswad',
      'Al Manşūrah',
      'Bayt Yāshūţ',
      'Tremseh',
      'Qenterê',
      'Kafr Mūsá Al-Hmidiya',
    ],
  },
  {
    country: 'Swaziland',
    cities: [
      'Tshaneni',
      'Siteki',
      'Sidvokodvo',
      'Piggs Peak',
      'Nsoko',
      'Nhlangano',
      'Mhlume',
      'Mhlambanyatsi',
      'Mbabane',
      'Manzini',
      'Malkerns',
      'Lobamba',
      'Lavumisa',
      'Kwaluseni',
      'Kubuta',
      'Hluti',
      'Hlatikulu',
      'Darkton',
      'Bulembu',
      'Big Bend',
      'Bhunya',
      'Vuvulane',
      'Ntfonjeni',
    ],
  },
  { country: 'Turks and Caicos Islands', cities: ['Cockburn Town'] },
  {
    country: 'Chad',
    cities: [
      'Iriba',
      'Goz Béïda',
      'Goz-Beida',
      'Fada',
      'Biltine',
      'Am-Timan',
      'Adré',
      'Abéché',
      'Sarh',
      'Pala',
      'Oum Hadjer',
      'Ngama',
      "N'Djamena",
      'Moussoro',
      'Moundou',
      'Mongo',
      'Moïssala',
      'Melfi',
      'Mboursou Léré',
      'Massenya',
      'Massakory',
      'Massaguet',
      'Mao',
      'Laï',
      'Kyabé',
      'Koumra',
      'Kelo',
      'Guelendeng',
      'Gounou Gaya',
      'Goundi',
      'Faya-Largeau',
      'Dourbali',
      'Doba',
      'Bousso',
      'Bongor',
      'Bol',
      'Bokoro',
      'Bitkine',
      'Béré',
      'Benoy',
      'Beïnamar',
      'Béboto',
      'Bébédja',
      'Bardaï',
      'Ati',
      'Aozou',
      'Amdjarass',
      'Ati Est',
      'Ati Nord',
    ],
  },
  { country: 'French Southern Territories', cities: ['Port-aux-Français'] },
  {
    country: 'Togo',
    cities: [
      'Vogan',
      'Tsévié',
      'Tchamba',
      'Tabligbo',
      'Sotouboua',
      'Sokodé',
      'Notsé',
      'Niamtougou',
      'Sansanné-Mango',
      'Lomé',
      'Kpalimé',
      'Pagouda',
      'Kara',
      'Kandé',
      'Dapaong',
      'Bassar',
      'Bafilo',
      'Badou',
      'Atakpamé',
      'Aného',
      'Amlamé',
    ],
  },
  {
    country: 'Thailand',
    cities: [
      'Don Sak',
      'Thung Sai',
      'Tham Phannara',
      'Ban Talat Yai',
      'Ban Talat Nua',
      'Sam Roi Yot',
      'Nopphitam',
      'Lam Thap',
      'Ban Huai I Huak',
      'Phrao',
      'Mae Taeng',
      'Mae Ai',
      'Phetchaburi',
      'Mae Chai',
      'Yan Ta Khao',
      'Wiang Sa',
      'Wiang Pa Pao',
      'Wiang Haeng',
      'Wiang Chai',
      'Wat Phleng',
      'Wang Wiset',
      'Wang Nuea',
      'U Thong',
      'Umphang',
      'Trang',
      'Thung Yai',
      'Thung Tako',
      'Thung Song',
      'Thung Saliam',
      'Thong Pha Phum',
      'Thoen',
      'Tha Song Yang',
      'Tha Sala',
      'Tha Sae',
      'Thap Than',
      'Thap Sakae',
      'Thap Put',
      'Tha Phae',
      'Tha Muang',
      'Tha Maka',
      'Thalang',
      'Thai Mueang',
      'Tha Chang',
      'Tha Chana',
      'Takua Thung',
      'Takua Pa',
      'Tak',
      'Surat Thani',
      'Sukhothai',
      'Suan Phueng',
      'Sop Prap',
      'Soem Ngam',
      'Si Sawat',
      'Si Satchanalai',
      'Si Samrong',
      'Si Nakhon',
      'Sikao',
      'Si Banphot',
      'Sawi',
      'Sawankhalok',
      'Sawang Arom',
      'Saraphi',
      'San Sai',
      'San Pa Tong',
      'San Kamphaeng',
      'Sangkhla Buri',
      'Samoeng',
      'Sam Ngao',
      'Sai Yok',
      'Sai Ngam',
      'Ron Phibun',
      'Ratchaburi',
      'Ranong',
      'Pran Buri',
      'Prachuap Khiri Khan',
      'Plai Phraya',
      'Tha Kham',
      'Phuket',
      'Phrom Khiri',
      'Phrasaeng',
      'Phran Kratai',
      'Photharam',
      'Phop Phra',
      'Phipun',
      'Phayao',
      'Phato',
      'Phanom Thuan',
      'Phanom',
      'Phang Nga',
      'Phan',
      'Pathio',
      'Pa Sang',
      'Palian',
      'Pak Tho',
      'Pai',
      'Om Koi',
      'Nong Ya Plong',
      'Nong Kha Yang',
      'Nong Chang',
      'Ngao',
      'Nakhon Si Thammarat',
      'Na Bon',
      'Mueang Pan',
      'Mae Tha',
      'Mae Suai',
      'Mae Sot',
      'Mae Sai',
      'Mae Ramat',
      'Mae Phrik',
      'Mae Mo',
      'Mae La Noi',
      'Mae Hong Son',
      'Mae Chan',
      'Mae Chaem',
      'Long',
      'Li',
      'La-Un',
      'Lat Yao',
      'Lao Khwan',
      'Lan Saka',
      'Lan Sak',
      'Lan Krabue',
      'La-ngu',
      'Lang Suan',
      'Lamphun',
      'Lampang',
      'Lamae',
      'Kui Buri',
      'Kra Buri',
      'Krabi',
      'Ko Yao',
      'Kong Ra',
      'Kong Krailat',
      'Ko Lanta',
      'Ko Kha',
      'Khura Buri',
      'Khun Yuam',
      'Khlong Thom',
      'Khlong Lan',
      'Khlong Khlung',
      'Tha Khanon',
      'Khiri Mat',
      'Khian Sa',
      'Khao Yoi',
      'Khao Phanom',
      'Khanu Woralaksaburi',
      'Kathu',
      'Kapong',
      'Kapoe',
      'Kantang',
      'Kanchanadit',
      'Kanchanaburi',
      'Kamphaeng Phet',
      'Huai Yot',
      'Huai Khot',
      'Hua Hin',
      'Hot',
      'Hang Dong',
      'Hang Chat',
      'Fang',
      'Dok Kham Tai',
      'Doi Tao',
      'Dan Chang',
      'Damnoen Saduak',
      'Chumphon',
      'Chom Thong',
      'Chom Bueng',
      'Chiang Rai',
      'Chiang Mai',
      'Chiang Dao',
      'Chawang',
      'Chaiya',
      'Chai Buri',
      'Chae Hom',
      'Cha-am',
      'Bo Phloi',
      'Wiang Nuea',
      'Wang Chin',
      'Wang Chao',
      'Thung Wa',
      'Thung Hua Chang',
      'Ban Tak',
      'Ko Samui',
      'Rawai',
      'Ban Rai',
      'Ban Pong',
      'Banphot Phisai',
      'Ban Phru Nai',
      'Nuea Khlong',
      'Nong Prue',
      'Nong Mamong',
      'Noen Kham',
      'Ban Na San',
      'Ban Na Doem',
      'Ban Na',
      'Ban Mai Khao',
      'Ban Lat',
      'Ban Laem',
      'Ban Ko Kaeo',
      'Ban Khao Lak',
      'Ban Kha',
      'Ban Kata',
      'Ban Karon',
      'Kaeng Krachan',
      'Huai Krachao',
      'Ban Hong',
      'Bang Saphan Noi',
      'Bang Saphan',
      'Bang Phae',
      'Bang Khon Thi',
      'Bang Khan',
      'Don Tum',
      'Doi Lo',
      'Dan Makham Tia',
      'Ban Dan Lan Hoi',
      'Ban Ao Nang',
      'Ao Luek',
      'Amphawa',
      'Sa Bot',
      'Phatthana Nikhom',
      'Nong Kung Si',
      'Na Dun',
      'Ko Pha Ngan',
      'Yang Si Surat',
      'Mueang Yang',
      'Siao',
      'Samran',
      'Thepharak',
      'Sak Lek',
      'Phayu',
      'Pha Khao',
      'Non Suwan',
      'Ban Nong Wua So',
      'Nong Wua So',
      'Nong Na Kham',
      'Nong Muang',
      'Nong Hin',
      'Nong Hi',
      'Na Yung',
      'Na Tan',
      'Ban Nam Yuen',
      'Na Mom',
      'Mueang Chan',
      'Ban Mai',
      'Lam Sonthi',
      'Ban Huai Thalaeng',
      'Huai Rat',
      'Ban Haet',
      'Fao Rai',
      'Ban Dan',
      'Charoen Sin',
      'Ban Khlong Bang Sao Thong',
      'Waeng',
      'Thepha',
      'Tak Fa',
      'Prakhon Chai',
      'Na Klang',
      'Bacho',
      'Yi-ngo',
      'Yasothon',
      'Yaring',
      'Yan Nawa',
      'Yang Talat',
      'Yang Chum Noi',
      'Yarang',
      'Yala',
      'Yaha',
      'Wiset Chaichan',
      'Wihan Daeng',
      'Wichian Buri',
      'Watthana Nakhon',
      'Wat Sing',
      'Wat Bot',
      'Warin Chamrap',
      'Warichaphum',
      'Wapi Pathum',
      'Wan Yai',
      'Wanon Niwat',
      'Wang Thong',
      'Wang Saphung',
      'Wang Sam Mo',
      'Wang Noi',
      'Wang Nam Yen',
      'Wang Hin',
      'Wang Chan',
      'Wang Sai Phun',
      'Waeng Yai',
      'Waeng Noi',
      'Uttaradit',
      'Uthumphon Phisai',
      'Uthai Thani',
      'Uthai',
      'Udon Thani',
      'Ubon Ratchathani',
      'Khuean Ubonrat',
      'Tron',
      'Trat',
      'Trakan Phut Phon',
      'Thung Fon',
      'Thon Buri',
      'Thoeng',
      'Thep Sathit',
      'Tha Yang',
      'Tha Wung',
      'Thawat Buri',
      'Tha Wang Pha',
      'Tha Uthen',
      'Tha Tum',
      'That Phanom',
      'Tha Tako',
      'Tha Ruea',
      'Tha Pla',
      'Thap Khlo',
      'Thanyaburi',
      'Than To',
      'Tha Mai',
      'Tha Luang',
      'Tha Li',
      'Tha Khantho',
      'Tha Bo',
      'Ta Phraya',
      'Taphan Hin',
      'Tao Ngoi',
      'Tan Sum',
      'Tamot',
      'Taling Chan',
      'Takhli',
      'Tak Bai',
      'Suwannaphum',
      'Suwannakhuha',
      'Surin',
      'Suphan Buri',
      'Sung Noen',
      'Sung Men',
      'Ban Su-ngai Pa Di',
      'Su-ngai Kolok',
      'Sukhirin',
      'So Phisai',
      'Song Phi Nong',
      'Songkhla',
      'Song',
      'Somdet',
      'Soeng Sang',
      'Si Thep',
      'Si That',
      'Si Songkhram',
      'Si Sakhon',
      'Si Sa Ket',
      'Si Rattana',
      'Si Racha',
      'Si Prachan',
      'Sing Buri',
      'Si Mueang Mai',
      'Si Maha Phot',
      'Si Khoraphum',
      'Sikhio',
      'Si Chomphu',
      'Si Chiang Mai',
      'Si Bun Rueang',
      'Senangkhanikhom',
      'Sena',
      'Selaphum',
      'Seka',
      'Sawang Daen Din',
      'Sawaeng Ha',
      'Satun',
      'Satuek',
      'Sattahip',
      'Sathing Phra',
      'Saraburi',
      'Sao Hai',
      'Sapphaya',
      'Sanom',
      'Sankhaburi',
      'Sangkhom',
      'Sang Khom',
      'Sangkha',
      'Sanam Chai Khet',
      'Samut Songkhram',
      'Samut Sakhon',
      'Samut Prakan',
      'Samrong Thap',
      'Sam Phran',
      'Samphanthawong',
      'Sam Ngam',
      'Sam Ko',
      'Sam Khok',
      'Sam Chuk',
      'Sakon Nakhon',
      'Sa Kaeo',
      'Sai Noi',
      'Sai Mun',
      'Sai Buri',
      'Sahatsakhan',
      'Sadao',
      'Saba Yoi',
      'Rueso',
      'Rong Kwang',
      'Rong Kham',
      'Roi Et',
      'Renu Nakhon',
      'Rayong',
      'Rattaphum',
      'Rattanaburi',
      'Rat Burana',
      'Rasi Salai',
      'Ranot',
      'Ra-ngae',
      'Raman',
      'Pueai Noi',
      'Pua',
      'Prathai',
      'Prasat',
      'Prang Ku',
      'Pakham',
      'Prachin Buri',
      'Prachantakham',
      'Pong Nam Ron',
      'Pong',
      'Pom Prap Sattru Phai',
      'Pluak Daeng',
      'Pla Pak',
      'Plaeng Yao',
      'Phu Wiang',
      'Phutthaisong',
      'Phu Ruea',
      'Phu Pha Man',
      'Phu Luang',
      'Phu Kradueng',
      'Phu Khiao',
      'Phrom Phiram',
      'Phrom Buri',
      'Phra Yuen',
      'Phra Pradaeng',
      'Phra Phutthabat',
      'Phra Nakhon Si Ayutthaya',
      'Phra Khanong',
      'Phrai Bueng',
      'Phrae',
      'Pho Thong',
      'Pho Thale',
      'Pho Sai',
      'Phon Sai',
      'Phon Phisai',
      'Phon Charoen',
      'Phon',
      'Chai Wari',
      'Phitsanulok',
      'Phimai',
      'Phichit',
      'Phichai',
      'Phibun Mangsahan',
      'Phetchabun',
      'Phen',
      'Phayuha Khiri',
      'Phayakkhaphum Phisai',
      'Phatthalung',
      'Phasi Charoen',
      'Phan Thong',
      'Phanom Sarakham',
      'Phanom Phrai',
      'Phanna Nikhom',
      'Phang Khon',
      'Phanat Nikhom',
      'Phana',
      'Phak Hai',
      'Phai Sali',
      'Phachi',
      'Pa Bon',
      'Phaya Mengrai',
      'Pattani',
      'Pa Tio',
      'Pathum Thani',
      'Pathum Rat',
      'Panare',
      'Pa Mok',
      'Pak Thong Chai',
      'Pak Phli',
      'Pak Phayun',
      'Pak Phanang',
      'Pak Kret',
      'Pak Khat',
      'Pak Chong',
      'Pa Daet',
      'Ongkharak',
      'Non Thai',
      'Mueang Nonthaburi',
      'Non Sung',
      'Non Sang',
      'Non Sa-at',
      'Non Kho',
      'Nong Yai',
      'Nong Sung',
      'Nong Suea',
      'Nong Saeng',
      'Nong Ruea',
      'Nong Phok',
      'Nong Phai',
      'Nong Ki',
      'Nong Khai',
      'Nong Khaem',
      'Nong Khae',
      'Nong Hong',
      'Nong Han',
      'Nong Chok',
      'Nong Chik',
      'Nong Bun Nak',
      'Nong Bua Rawe',
      'Nong Bua Lamphu',
      'Nong Bua Daeng',
      'Nong Bua',
      'Noen Maprang',
      'Nikhom Nam Un',
      'Nikhom Kham Soi',
      'Na Wa',
      'Na Thawi',
      'Narathiwat',
      'Na Pho',
      'Na Noi',
      'Nang Rong',
      'Nan',
      'Na Muen',
      'Nam Som',
      'Nam Phong',
      'Nam Pat',
      'Na Mon',
      'Nam Nao',
      'Nam Kliang',
      'Nakhon Thai',
      'Nakhon Sawan',
      'Nakhon Ratchasima',
      'Nakhon Phanom',
      'Nakhon Pathom',
      'Nakhon Nayok',
      'Nakhon Luang',
      'Nakhon Chai Si',
      'Na Kae',
      'Na Chueak',
      'Na Chaluai',
      'Mukdahan',
      'Mueang Suang',
      'Muang Sam Sip',
      'Min Buri',
      'Moeiwadi',
      'Mayo',
      'Manorom',
      'Mancha Khiri',
      'Makham',
      'Mai Kaen',
      'Maha Sarakham',
      'Maha Rat',
      'Maha Chana Chai',
      'Mae Charim',
      'Lop Buri',
      'Lom Sak',
      'Lom Kao',
      'Loeng Nok Tha',
      'Loei',
      'Lat Lum Kaeo',
      'Lat Bua Luang',
      'Lap Lae',
      'Lam Plai Mat',
      'Lam Luk Ka',
      'Lamduan',
      'Lahan Sai',
      'Laem Sing',
      'Laem Ngop',
      'Kut Khaopun',
      'Kut Chum',
      'Kut Chap',
      'Kut Bak',
      'Kusuman',
      'Kumphawapi',
      'Kuchinarai',
      'Bangkok',
      'Krok Phra',
      'Krathum Baen',
      'Krasang',
      'Krasae Sin',
      'Kranuan',
      'Kosum Phisai',
      'Ko Si Chang',
      'Klaeng',
      'Khun Han',
      'Khu Mueang',
      'Khu Khan',
      'Khuan Niang',
      'Khuan Khanun',
      'Khuan Kalong',
      'Khueang Nai',
      'Khuan Don',
      'Kho Wang',
      'Khon Sawan',
      'Khon San',
      'Khon Kaen',
      'Khong Chiam',
      'Khong',
      'Khon Buri',
      'Khok Si Suphan',
      'Khok Samrong',
      'Khok Pho',
      'Khok Charoen',
      'Khlung',
      'Khlong Yai',
      'Khlong San',
      'Khlong Luang',
      'Khlong Hat',
      'Khemarat',
      'Khao Wong',
      'Khao Saming',
      'Khao Chaison',
      'Kham Thale So',
      'Kham Ta Kla',
      'Kham Sakae Saeng',
      'Kham Muang',
      'Kham Khuean Kaeo',
      'Khamcha-i',
      'Kaset Wisai',
      'Kaset Sombun',
      'Kao Liao',
      'Kantharawichai',
      'Kanthararom',
      'Kantharalak',
      'Krong Pi Nang',
      'Su-ngai Padi',
      'Ka Bang',
      'Cho-airong',
      'Kamphaeng Saen',
      'Kamalasai',
      'Kalasin',
      'Kaeng Khoi',
      'Kaeng Khro',
      'Kae Dam',
      'Kabin Buri',
      'In Buri',
      'Hua Taphan',
      'Hua Sai',
      'Huai Thap Than',
      'Huai Thalaeng',
      'Huai Phueng',
      'Huai Mek',
      'Hat Yai',
      'Hankha',
      'Fak Tha',
      'Dusit',
      'Don Tan',
      'Dong Luang',
      'Don Chedi',
      'Doembang Nangbuat',
      'Det Udom',
      'Den Chai',
      'Dan Sai',
      'Dan Khun Thot',
      'Chum Phuang',
      'Chumphon Buri',
      'Chum Phae',
      'Chonnabot',
      'Chon Daen',
      'Chon Buri',
      'Chom Phra',
      'Chok Chai',
      'Chian Yai',
      'Chiang Yuen',
      'Chiang Saen',
      'Chiang Klang',
      'Chiang Khong',
      'Chiang Khan',
      'Chiang Kham',
      'Cha-uat',
      'Chaturaphak Phiman',
      'Chatturat',
      'Chat Trakan',
      'Chanuman',
      'Chanthaburi',
      'Chanae',
      'Chana',
      'Chaloem Phra Kiat',
      'Chaiyo',
      'Chaiyaphum',
      'Chaiwan',
      'Chai Nat',
      'Chai Badan',
      'Chachoengsao',
      'Buri Ram',
      'Buntharik',
      'Bueng Sam Phan',
      'Bueng Khong Long',
      'Bueng Kan',
      'Bua Yai',
      'Bua Chet',
      'Bo Thong',
      'Bo Rai',
      'Borabue',
      'Bo Kluea',
      'Betong',
      'Wang Pong',
      'Wang Muang',
      'Ban Thai Tan',
      'Ban Thaen',
      'Song Khwae',
      'Ban Selaphum',
      'Sawang Wirawong',
      'Ban Sang',
      'Ban Sam Chuk',
      'Ratchasan',
      'Ban Phue',
      'Ban Phraek',
      'Ban Pho',
      'Ban Phe',
      'Pattaya',
      'Ban Phan Don',
      'Ban Phai',
      'Ban Phaeo',
      'Ban Phaeng',
      'Pak Chom',
      'Nong Muang Khai',
      'Nong Don',
      'Na Yia',
      'Na Yai Am',
      'Na Thom',
      'Ban Nang Sata',
      'Na Khu',
      'Na Haeo',
      'Na Di',
      'Ban Muang',
      'Muak Lek',
      'Ban Mo',
      'Ban Mi',
      'Mae Lan',
      'Chiang Muan',
      'Ban Luang',
      'Ban Lueam',
      'Lao Suea Kok',
      'Ban Lam Luk Ka',
      'Kut Rang',
      'Ban Kruat',
      'Ban Ko Lan',
      'Ban Khwao',
      'Khok Sung',
      'Bang Rachan',
      'Ban Khok',
      'Khlong Khuean',
      'Khlong Hoi Khong',
      'Khao Chakan',
      'Ban Khai',
      'Kap Choeng',
      'Kapho',
      'Kaeng Sanam Nang',
      'Kaeng Hang Maeo',
      'Bang Yai',
      'Bang Sai',
      'Bang Rakam',
      'Bang Rak',
      'Bang Racham',
      'Bang Pla Ma',
      'Bang Pakong',
      'Bang Pa-in',
      'Bang Pahan',
      'Bang Nam Priao',
      'Bang Mun Nak',
      'Bang Len',
      'Bang Lamung',
      'Bang Kruai',
      'Bang Krathum',
      'Bangkok Yai',
      'Bangkok Noi',
      'Bang Khun Thian',
      'Bang Khla',
      'Bang Kapi',
      'Bang Bua Thong',
      'Bang Bo',
      'Bang Ban',
      'Ban Fang',
      'Ban Dung',
      'Don Phut',
      'Ban Chang',
      'Chamni',
      'Chakkarat',
      'Ban Bueng',
      'Bueng Na Rang',
      'Ban Talat Bueng',
      'Bang Na',
      'Bang Klam',
      'Bang Kaeo',
      'Ban Bang Kadi Pathum Thani',
      'Bang Bo District',
      'Lue Amnat',
      'Bamnet Narong',
      'At Samat',
      'Aranyaprathet',
      'Ang Thong',
      'Amnat Charoen',
      'Akat Amnuai',
      'Samrong',
      'Bueng Bun',
      'Song Dao',
      'Thung Yang Daeng',
      'Amphoe Sikhiu',
      'Phanom Dong Rak',
      'Saladan',
      'Wichit',
      'Koh Tao',
      'Bang Sue',
      'Don Mueang',
      'Salaya',
      'Khlong Toei',
      'Patong',
      'Chun',
      'Thung Chang',
      'Lat Krabang',
      'Bang Khae',
      'Bang Kho Laem',
      'Bueng Kum',
      'Chatuchak',
      'Huai Khwang',
      'Khan Na Yao',
      'Khlong Sam Wa',
      'Lat Phrao',
      'Pathum Wan',
      'Phaya Thai',
      'Ratchathewi',
      'Sai Mai',
      'Saphan Sung',
      'Sathon',
      'Suan Luang',
      'Thawi Watthana',
      'Thung Khru',
      'Wang Thonglang',
      'Watthana',
      'Sop Pong',
      'Ban Chalong',
      'Ban Ratsada',
      'Mueang Phuket',
      'Bang Sao Thong',
      'Ko Chang Tai',
      'Nai Harn',
      'Mae Fa Luang',
      'Wiang Chiang Rung',
      'Wiang Kaen',
      'Khun Tan',
      'Mae Lao',
      'Suk Samran',
      'Ban Takhun',
      'Takuk Nuea',
      'Wiang Nong Long',
      'Mae Poen',
      'Pang Sila Thong',
      'Kosamphi Nakhon',
      'Ban Thi',
      'Manang',
      'Ratsada',
      'Phra Phrom',
      'Chulabhorn',
      'Chang Klang',
      'Nong Yasai',
      'Chum Ta Bong',
      'Mae Wong',
      'Bueng Samakkhi',
      'Phu Kam Yao',
      'Sawaengha',
      'Doem Bang Nang Buat',
      'Chumsaeng',
      'Wachira Barami',
      'Thong Saen Khan',
      'Laplae',
      'Santi Suk',
      'Phu Phiang',
      'Nikhom Phattana',
      'Ko Chan',
      'Si Mahosot',
      'Phakdi Chumphon',
      'Khao Chamao',
      'Tha Takiap',
      'Wang Nam Khiao',
      'Prachamtakham',
      'Sap Yai',
      'Erawan',
      'Khao Khitchakut',
      'Soi Dao',
      'Wang Sombun',
      'Noen Sa-nga',
      'Khok Pho Chai',
      'Na Wang',
      'Suwanna Khuha',
      'Non Din Daeng',
      'Pa Kham',
      'Lam Thamen Chai',
      'Non Daeng',
      'Ban Mai Chaiyaphot',
      'Non Sila',
      'Ubonratana',
      'Ban Na Muang',
      'Sa Khrai',
      'Phlapphla Chai',
      'Si Somdet',
      'Khong Chai',
      'Chuen Chom',
      'Sam Sung',
      'Ku Kaeo',
      'Phibun Rak',
      'Pakkhat',
      'Phonphisai',
      'Si Narong',
      'Non Narai',
      'Khwao Sinarin',
      'Thung Khao Luang',
      'Chiang Khwan',
      'Changhan',
      'Don Chan',
      'Phu Phan',
      'Si Wilai',
      'Phu Sing',
      'Pho Si Suwan',
      'Sila Lat',
      'Thai Charoen',
      'Phon Na Kaeo',
      'Wang Yang',
      'Nathom',
      'Thung Si Udom',
      'Nam Khun',
      'Benchalak',
      'Pathum Ratchawongsa',
      'Don Mot Daeng',
      'Mae Wang',
      'Khao Kho',
      'Phra Thong Kham',
      'Wiang Kao',
      'Khaen Dong',
      'Sirindhorn',
      'Sida',
      'Srinagarindra',
      'Sam Chai',
      'Dong Charoen',
      'Chaem Luang',
      'Rattanawapi',
      'Hat Samran',
      'Ban Pa Pae',
      'Bung Khla',
      'Chai Prakan',
      'Doi Luang',
      'Mae On',
      'Sop Moei',
      'Bua Lai',
      'Phra Samut Chedi',
      'Singhanakhon',
      'Phu Sang',
      'Mae Hi',
      'Pa Phayom',
      'Na Yong',
      'Pho Tak',
      'Ko Kut',
      'Bang Bon',
      'Din Daeng',
      'Phra Nakhon',
      'Na Kluea',
    ],
  },
  {
    country: 'Tajikistan',
    cities: [
      'Yovon',
      'Hulbuk',
      'Vorukh',
      'Varzob',
      'Vanj',
      'Vakhsh',
      'Istaravshan',
      'Tavildara',
      'Tartiki',
      'Tagob',
      'Tajikobod',
      'Sovet',
      'Shŭrobod',
      'Shahrinav',
      'Shahriston',
      'Shahritus',
      'Sarikhosor',
      'Rŭshon',
      'Roshtqal’a',
      'Roghun',
      'Bokhtar',
      'Panj',
      'Panjakent',
      'Farkhor',
      'Orzu',
      'Vahdat',
      'Ismoili Somoní',
      'Obikiik',
      'Obigarm',
      'Norak',
      'Novobod',
      'Murghob',
      'Moskva',
      'Somoniyon',
      'Mŭ’minobod',
      'Bokhtariyon',
      'Abdurahmoni Jomí',
      'Kŭlob',
      'Darband',
      'Kolkhozobod',
      'Kirov',
      'Khovaling',
      'Khorugh',
      'Khodzha-Maston',
      'Karakenja',
      'Levakant',
      'Qalaikhumb',
      'Qubodiyon',
      'Ishqoshim',
      'Hisor',
      'Rasht',
      'Gharavŭtí',
      'Ghonchí',
      'Fayzobod',
      'Jirghatol',
      'Jilikŭl',
      'Dŭstí',
      'Dushanbe',
      'Danghara',
      'Chubek',
      'Boshchorbogh',
      'Bŭstonqal’a',
      'Boshkengash',
      'Baljuvon',
      'Ayní',
      'Tursunzoda',
      'Bahorí',
      'Avzikent',
      'Zafarobod',
      'Taboshar',
      'Shŭrob',
      'Proletar',
      'Palos',
      'Pakhtakoron',
      'Neftobod',
      'Nov',
      'Quruqsoy',
      'Kim',
      'Khujand',
      'Qayroqqum',
      'Konsoy',
      'Konibodom',
      'Isfara',
      'Ghafurov',
      'Bŭston',
      'Shaydon',
      'Oltintopkan',
      'Adrasmon',
      'Buston',
      'Mehron',
      'Moskovskiy',
    ],
  },
  {
    country: 'Tokelau',
    cities: ['Fale old settlement', 'Nukunonu', 'Atafu Village'],
  },
  {
    country: 'East Timor',
    cities: [
      'Viqueque',
      'Suai',
      'Same',
      'Pante Makasar',
      'Metinaro',
      'Maubara',
      'Manatutu',
      'Maliana',
      'Likisá',
      'Dili',
      'Baukau',
      'Ainaro',
      'Aileu',
      'Lospalos',
      'Gleno',
      'Venilale',
    ],
  },
  {
    country: 'Turkmenistan',
    cities: [
      'Balkanabat',
      'Kaka',
      'Serdar',
      'Gumdag',
      'Bereket',
      'Magtymguly',
      'Arçabil',
      'Abadan',
      'Baharly',
      'Ashgabat',
      'Änew',
      'Yylanly',
      'Tagta',
      'Akdepe',
      'Türkmenbaşy',
      'Köneürgench',
      'Boldumsaz',
      'Daşoguz',
      'Yolöten',
      'Tejen',
      'Seydi',
      'Saýat',
      'Murgab',
      'Mary',
      'Kerki',
      'Serhetabat',
      'Gowurdak',
      'Farap',
      'Türkmenabat',
      'Bayramaly',
      'Gazojak',
    ],
  },
  {
    country: 'Tunisia',
    cities: [
      'Matmata',
      'Zaouiet Kountech',
      'Zaouiat Djedidi',
      'Zahānah',
      'Zaghouan',
      'Wadi Maliz',
      'Oued Lill',
      'Touza',
      'Tunis',
      'Tozeur',
      'Tataouine',
      'Testour',
      'Tamaghzah',
      'Thala',
      'Takelsa',
      'Tajerouine',
      'Tabursuq',
      'Tabarka',
      'Sousse',
      'Siliana',
      'Sidi Alouane',
      'Sidi Bouzid',
      'Sidi Bou Rouis',
      'Sidi Bou Ali',
      'Sidi Ben Nour',
      'Sidi el Hani',
      'Sidi Bou Saïd',
      'Chorbane',
      'Chebika',
      'Sakiet Sidi Youssef',
      'Skanes',
      'Salakta',
      'Sejenane',
      'Sfax',
      'Sbiba',
      'La Sebala du Mornag',
      'Remada',
      'Rafrāf',
      'Radès',
      'Ksour Essaf',
      'Ksibet el Mediouni',
      'Korbous',
      'Korba',
      'Kélibia',
      'Goubellat',
      'Kebili',
      'Ksar Hellal',
      'Carthage',
      'El Fahs',
      'Galaat el Andeless',
      'Gafour',
      'Gafsa',
      'Gabès',
      'Nibbar',
      'Nefta',
      'Nabeul',
      'Midoun',
      'Mateur',
      'Msaken',
      'Menzel Salem',
      'Menzel Kamel',
      'Menzel Jemil',
      'Menzel Heurr',
      'Mennzel Bou Zelfa',
      'Menzel Bourguiba',
      'Menzel Abderhaman',
      'Manouba',
      'Melloulèche',
      'Maktar',
      'Medjez el Bab',
      'Medenine',
      'Lemta',
      'Kesra',
      'Kalaat Khasba',
      'Jendouba',
      'Jilma',
      'Zarzis',
      'Jemna',
      'Djemmal',
      'Djebeniana',
      'Houmt El Souk',
      'Harqalah',
      'Hammam Sousse',
      'Hammam-Lif',
      'La Goulette',
      'Haffouz',
      'Rhar el Melah',
      'Ghardimaou',
      'Douz',
      'Degache',
      'Douar Tindja',
      'Dar Chabanne',
      'Bou Arkoub',
      'Bou Attouche',
      'Bou Arada',
      'Bir Ali Ben Khalifa',
      'Bir el Hafey',
      'Ben Gardane',
      'Ben Arous',
      'Bizerte',
      'Beni Khiar',
      'Beni Khalled',
      'Beni Kheddache',
      'Beni Hassane',
      'Banbalah',
      'Béja',
      'Zouila',
      'Skhira',
      'Sbikha',
      'Seïada',
      'As Sars',
      'As Sanad',
      'Sahline',
      'Chebba',
      'Ariana',
      'Ar Rudayyif',
      'Er Regueb',
      'Rohia',
      'Ouardenine',
      'El Ksour',
      'Kairouan',
      'Kasserine',
      'Gremda',
      'El Golaa',
      'Monastir',
      'La Mohammedia',
      'El Mida',
      'Metlaoui',
      'Mezzouna',
      'Al Matlīn',
      'Mesdour',
      'Al Marsá',
      'El Maamoura',
      'Mahdia',
      'Le Krib',
      'El Kef',
      'El Jem',
      'El Haouaria',
      'Erriadh',
      'Hammamet',
      'El Hamma',
      'Fernana',
      'El Battan',
      'Bekalta',
      'El Alia',
      'Akouda',
      'Douane',
      'Port el Kantaoui',
      'Jedelienne',
      'Rouhia',
      'Ezzouhour',
      'Kondhar',
    ],
  },
  {
    country: 'Tonga',
    cities: [
      'Vaini',
      'Pangai',
      '‘Ohonua',
      'Nuku‘alofa',
      'Neiafu',
      'Lapaha',
      'Kolonga',
      'Hihifo',
      'Haveluloto',
      'Tatakamotonga',
    ],
  },
  {
    country: 'Turkey',
    cities: [
      'Demre',
      'Zeytinliova',
      'Zeytindağ',
      'Zara',
      'Yunak',
      'Yumurtalık',
      'Yüksekova',
      'Salat',
      'Kozak',
      'Yücebağ',
      'Yozgat',
      'Yoncalı',
      'Yolboyu',
      'Yolbaşı',
      'Yolağzı',
      'Yıldızeli',
      'Yiğityolu',
      'Yiğitler',
      'Yeşilyurt',
      'Yeşilyazı',
      'Yeşilova',
      'Yeşilli',
      'Erzin',
      'Yeşilhisar',
      'Gölcük',
      'Yeşildere',
      'Yeşilalan',
      'Yerköy',
      'Yerkesik',
      'Yenişarbademli',
      'Yenişakran',
      'Yenipınar',
      'Yenipazar',
      'Yenimehmetli',
      'Yeniköy',
      'Yenicekale',
      'Didim',
      'Yenifoça',
      'Yenifakılı',
      'Altpınar',
      'Yeniceoba',
      'Yenice',
      'Yenibaşak',
      'Reşadiye',
      'Yeditepe',
      'Yedisu',
      'Yazyurdu',
      'Yazıhan',
      'Yayvantepe',
      'Yaylı',
      'Yaylak',
      'Yayladere',
      'Yayladağı',
      'Yayla',
      'Yaygın',
      'Yavuzeli',
      'Yavu',
      'Yatağan',
      'Yarpuzlu',
      'Yarpuz',
      'Yarma',
      'Yardımcı',
      'Yarbasan',
      'Yaprakbaşı',
      'Yanarsu',
      'Yamaç',
      'Yalvaç',
      'Yalınca',
      'Yalıkavak',
      'Yalıhüyük',
      'Yalangöz',
      'Yakapınar',
      'Payas',
      'Yahyalı',
      'Yahşihan',
      'Yağcılar',
      'Viranşehir',
      'Varto',
      'Van',
      'Uzunkuyu',
      'Uzungeçit',
      'Cimin',
      'Üzümlü',
      'Yeşilüzümlü',
      'Uşak',
      'Urla',
      'Ürgüp',
      'Şanlıurfa',
      'Umurlu',
      'Umraniye',
      'Uluyatır',
      'Ulukışla',
      'Uludere',
      'Arsuz',
      'Uluborlu',
      'Ulubey',
      'Ulaş',
      'Ula',
      'Üçtepe',
      'Üçpınar',
      'Üçkavak',
      'Üçdam',
      'Tuzlukçu',
      'Tuzlagözü',
      'Tutak',
      'Tut',
      'Turunçova',
      'Türkoğlu',
      'Turgutlu',
      'Turgut',
      'Tunceli',
      'Sütlüce',
      'Tufanbeyli',
      'Torbalı',
      'Toptepe',
      'Toprakkale',
      'Topaklı',
      'Pınarbaşı',
      'Tomarza',
      'Toklar',
      'Tire',
      'Tercan',
      'Tepeüstü',
      'Tepehan',
      'Tepecikören',
      'Tepecik',
      'Tepebaşı',
      'Tepealtı',
      'Tepe',
      'Temelli',
      'Tekman',
      'Tekirova',
      'Tefenni',
      'Tecirli',
      'Tavşanlı',
      'Tavas',
      'Tatvan',
      'Taşucu',
      'Taşpınar',
      'Taşlıçay',
      'Taşlı',
      'Taşkent',
      'Taşdelen',
      'Bakırdağı',
      'Taşağıl',
      'Tarsus',
      'Taraksu',
      'Tanyeri',
      'Tanır',
      'Talas',
      'Tahtaköprü',
      'Tahir',
      'Suvarlı',
      'Şenocak',
      'Sütçüler',
      'Susurluk',
      'Suruç',
      'Avine',
      'Sürgü',
      'Sulusaray',
      'Sülüklü',
      'Suluçem',
      'Sultanhisar',
      'Sultanhanı',
      'Sultandağı',
      'Süleymanlı',
      'Sulak',
      'Şuhut',
      'Sorgun',
      'Soma',
      'Solhan',
      'Söke',
      'Söğütlü',
      'Kindirip',
      'Söğüt',
      'Sivrihisar',
      'Sivrice',
      'Siverek',
      'Sivaslı',
      'Sivas',
      'Şirvan',
      'Tililan',
      'Şırnak',
      'Sındırgı',
      'Sincik',
      'Sincan',
      'Sinanpaşa',
      'Sinanköy',
      'Simav',
      'Silvan',
      'Silopi',
      'Sille',
      'Silifke',
      'Siirt',
      'Seyitgazi',
      'Seyhan',
      'Seydişehir',
      'Sevinç',
      'Servi',
      'Serinyol',
      'Serik',
      'Şereflikoçhisar',
      'Şenyurt',
      'Şenoba',
      'Şenköy',
      'Senirkent',
      'Şemdinli',
      'Selimiye',
      'Side',
      'Selendi',
      'Selçuk',
      'Selçikler',
      'Seksenören',
      'Sekili',
      'Seki',
      'Şekerli',
      'Şehit Nusretbey',
      'Seferihisar',
      'Şefaatlı',
      'Savur',
      'Savcılı',
      'Kayacık',
      'Savaştepe',
      'Sason',
      'Saruhanlı',
      'Edremit',
      'Şarkışla',
      'Şarkîkaraağaç',
      'Sarız',
      'Sarıyahşi',
      'Sarıveliler',
      'Sarısu',
      'Sarıoğlan',
      'Sarıköy',
      'Sarıkonak',
      'Sarıkemer',
      'Sarıkaya',
      'Sarıgöl',
      'Sarıçam',
      'Sarıbeyler',
      'Sarayönü',
      'Sarayköy',
      'Saraykent',
      'Saray',
      'Şaphane',
      'Sandıklı',
      'Sancak',
      'Samsat',
      'Şamlı',
      'Şambayat',
      'Samandağ',
      'Salihli',
      'Salavatlı',
      'Sakçagöz',
      'Saimbeyli',
      'Sağkaya',
      'Sağınlı',
      'Sabuncu',
      'Reyhanlı',
      'Reis',
      'Refahiye',
      'Pütürge',
      'Pulumer',
      'Pozantı',
      'Poyraz',
      'Polatlı',
      'Pınarönü',
      'Pınarlar',
      'Pınardere',
      'Pervari',
      'Pertek',
      'Akpazar',
      'Pazaryeri',
      'Pazarören',
      'Pazarlar',
      'Pazarköy',
      'Pazarcık',
      'Payallar',
      'Patnos',
      'Pasinler',
      'Pamukören',
      'Pamukkale',
      'Palu',
      'Palamutlu',
      'Özpınar',
      'Özonak',
      'Özdere',
      'Özbek',
      'Özalp',
      'Oymataş',
      'Oyalı',
      'Ovakışla',
      'Ovakent',
      'Ovakavağı',
      'Ovacık',
      'Ovabağ',
      'Otlukbeli',
      'Osmanpaşa',
      'Osmaniye',
      'Osmancalı',
      'Ortaköy',
      'Ortaklar',
      'Ortyakent Yahşi',
      'Ortaca',
      'Ortabağ',
      'Orhaneli',
      'Örenşehir',
      'Örencik',
      'Ören',
      'Öncül',
      'Ömerli',
      'Ömerköy',
      'Okçular',
      'Oğuzeli',
      'Oğuz',
      'Ödemiş',
      'Obruk',
      'Nusaybin',
      'Nurhak',
      'Nurettin',
      'Nizip',
      'Niğde',
      'Nevşehir',
      'Nazimiye',
      'Nazilli',
      'Demirci',
      'Narlıdere',
      'Narlı',
      'Narince',
      'Mutki',
      'Mut',
      'Musabeyli',
      'Muş',
      'Mürşitpınar',
      'Mursal',
      'Muratlı',
      'Murat',
      'Muradiye',
      'Güneşli',
      'Karaova',
      'Muğla',
      'Mucur',
      'Mordoğan',
      'Elmalıdere',
      'Mollakendi',
      'Minare',
      'Balveren',
      'Milas',
      'Mihalıçcık',
      'Midyat',
      'Kırmızıköprü',
      'Mezraa',
      'Mezitli',
      'Meydankapı',
      'Meydan',
      'Söylemez',
      'Mersin',
      'Mermer',
      'Mercimekkale',
      'Meram',
      'Menemen',
      'Mazıdağı',
      'Mazgirt',
      'Marmaris',
      'Mardin',
      'Mansurlu',
      'Manisa',
      'Manavgat',
      'Mamak',
      'Malazgirt',
      'Malatya',
      'Mahmutlar',
      'Mahmudiye',
      'Mağaralı',
      'Kırobası',
      'Maden',
      'Lice',
      'Levent',
      'Kuzucubelen',
      'Kuyulusebil',
      'Kuyucak',
      'Kutlubey',
      'Kütahya',
      'Kuşsarayı',
      'Kuşkayası',
      'Kuşadası',
      'Kuruçay',
      'Kurthasanlı',
      'Kurtalan',
      'Kurşunlu',
      'Sarıkavak',
      'Beykonak',
      'Kumluca',
      'Kumlu',
      'Kumköy',
      'Kumdanlı',
      'Kumçatı',
      'Kuluncak',
      'Kulu',
      'Kulp',
      'Kula',
      'Küçüksu',
      'Küçükkuyu',
      'Küçükbahçe',
      'Kozluk',
      'Kozan',
      'Kozaklı',
      'Kovancılar',
      'Kösreli',
      'Köşk',
      'Köseli',
      'Kösefakılı',
      'Korucu',
      'Korkuteli',
      'Korkut',
      'Köprüören',
      'Köprülü',
      'Köprüköy',
      'Köprübaşı',
      'Konyaaltı',
      'Konya',
      'Konalga',
      'Konakpınar',
      'Konaklı',
      'Göcek',
      'Kocatepe',
      'Koçarlı',
      'Kocapınar',
      'Koçali',
      'Kocaköy',
      'Kocakoç',
      'Kocahasanlı',
      'Kocaaliler',
      'Kızılyaka',
      'Kızıltepe',
      'Kızılsu',
      'Kızılören',
      'Kızılkaya',
      'Serinhisar',
      'Kızılcasöğüt',
      'Kızılcadağ',
      'Kızılcabölük',
      'Kızılağaç',
      'Kışlak',
      'Kırşehir',
      'Kırkgeçit',
      'Kırkağaç',
      'Kırka',
      'Kırıkkale',
      'Kırıkhan',
      'Kireç',
      'Kiraz',
      'Kınık',
      'Kilis',
      'Kılbasan',
      'Kılavuz',
      'Kiğı',
      'Keskin',
      'Kepsut',
      'Kemerhisar',
      'Kemer',
      'Kemalpaşa',
      'Kemaliye',
      'Kemah',
      'Keles',
      'Kelekçi',
      'Keçiborlu',
      'Keban',
      'Kazımkarabekir',
      'Kazanlı',
      'Kazancı',
      'Kayseri',
      'Kaypak',
      'Kaynar',
      'Kaynakkaya',
      'Kaymaz',
      'Kaymakçı',
      'Kayapınar',
      'Kayapa',
      'Kayalıpınar',
      'Salmanlı',
      'Kayadibi',
      'Kayabağlar',
      'Kavaklıdere',
      'Kavakbaşı',
      'Kavak',
      'Hazar',
      'Kavacık',
      'Kasımlar',
      'Kaş',
      'Karşıyaka',
      'Aladağ',
      'Karpuzlu',
      'Karlıova',
      'Karıncalı',
      'Kargı',
      'Karkamış',
      'Karayün',
      'Karayazı',
      'Karatoprak',
      'Karataş',
      'Karapınar',
      'Karaoğlanlı',
      'Karaoğlan',
      'Karamanlı',
      'Karaman',
      'Karalar',
      'Karakoyunlu',
      'Karaköy',
      'Ağrı',
      'Karaköprü',
      'Karakoçan',
      'Karakeçili',
      'Karakeçi',
      'Karaisalı',
      'Karahasanlı',
      'Karahallı',
      'Karadirek',
      'Karaçoban',
      'Karaçay',
      'Karacaören',
      'Karacasu',
      'Karacadağ',
      'Karaburun',
      'Karaağıl',
      'Kanlıavşar',
      'Kangal',
      'Kandilli',
      'Kaman',
      'Kalkım',
      'Kalkan',
      'Kabahaydar',
      'Kale',
      'Kâhta',
      'Kahramanmaraş',
      'Kadışehri',
      'Kadirli',
      'Kadınhanı',
      'Kabala',
      'İzmir',
      'İvrindi',
      'Isparta',
      'Yuntdağ',
      'İslahiye',
      'İskenderun',
      'Işıklar',
      'İshaklı',
      'İşgören',
      'İscehisar',
      'İsabeyli',
      'İnönü',
      'İncirliova',
      'İncesu',
      'İmranlı',
      'İmamoğlu',
      'İlyaslı',
      'Ilıca',
      'Ilıcalar',
      'İliç',
      'Ilgın',
      'İkizce',
      'İkiköprü',
      'İhsaniye',
      'Iğdır',
      'İdil',
      'İçören',
      'İçmeler',
      'İçme',
      'Hüyük',
      'Hozat',
      'Hotamış',
      'Horsunlu',
      'Honaz',
      'Hocalar',
      'Gyundyukoru',
      'Selçuklu',
      'Hizan',
      'Hisarcık',
      'Hisar',
      'Hınıs',
      'Himmetdede',
      'Hilvan',
      'Hilal',
      'Hıdırbey',
      'Hıdırbaba',
      'Hekimhan',
      'Hekimdağ',
      'Hazro',
      'Haymana',
      'Haydarlı',
      'Havran',
      'Hatunsaray',
      'Hatip',
      'Hassa',
      'Alacakaya',
      'Hasköy',
      'Hasbek',
      'Hasankeyf',
      'Hasançelebi',
      'Hasanbeyli',
      'Düziçi',
      'Harran',
      'Harput',
      'Harmanlı',
      'Harmancık',
      'Harbiye',
      'Han',
      'Hankendi',
      'Hani',
      'Hanköy',
      'Hamzalı',
      'Hamur',
      'Hamdibey',
      'Halkapınar',
      'Halitpaşa',
      'Halilçavuş',
      'Halfeti',
      'Tuzla',
      'Hafik',
      'Hadim',
      'Hacılar',
      'Hacıbektaş',
      'Aydınlar',
      'Haberli',
      'Güzelyurt',
      'Güzelsu',
      'Güzeloluk',
      'Güzelbağ',
      'Güvercinlik',
      'Gürün',
      'Gürpınar',
      'Güroymak',
      'Güre',
      'Günyüzü',
      'Güney',
      'Güneren',
      'Gündoğmuş',
      'Gümüşsu',
      'Gümüşkent',
      'Gümüşakar',
      'Gülyazı',
      'Gülveren',
      'Gülşehir',
      'Gülpınar',
      'Gülnar',
      'Güllük',
      'Güllü',
      'Yenisu',
      'Güleçler',
      'Başköy',
      'Gücük',
      'Güçlükonak',
      'Gözpınar',
      'Gözne',
      'Gözler',
      'Gözeli',
      'Göynük',
      'Payamlı',
      'Bisbin',
      'Göreme',
      'Gördes',
      'Gönen',
      'Gömeç',
      'Gölova',
      'Gölören',
      'Gölmarmara',
      'Göllü',
      'Gölhisar',
      'Gölgelikonak',
      'Göldüzü',
      'Gölbaşı',
      'Göktepe',
      'Göksun',
      'Göksu',
      'Gökoğlan',
      'Gökdere',
      'Gökçeyazı',
      'Gökçeören',
      'Gökçen',
      'Gökçekoru',
      'Gökçedağ',
      'Gökçebağ',
      'Gökçe',
      'Gökbudak',
      'Göçbeyli',
      'Girmeli',
      'Geyikpınar',
      'Geyikli',
      'Gevaş',
      'Gesi',
      'Germencik',
      'Geriş',
      'Gerger',
      'Gercüş',
      'Gencek',
      'Genç',
      'Gemerek',
      'Gelinkaya',
      'Gelendost',
      'Gelembe',
      'Gediz',
      'Gedikler',
      'Timar',
      'Gedikbaşı',
      'Geçitli',
      'Gebiz',
      'Gazipaşa',
      'Gaziemir',
      'Gaziantep',
      'Foça',
      'Finike',
      'Fındık',
      'Fevzipaşa',
      'Fethiye',
      'Felahiye',
      'Feke',
      'Ezine',
      'Eymirli',
      'Evrenseki',
      'Evciler',
      'Etimesgut',
      'Etili',
      'Eşme',
      'Eskişehir',
      'Eskil',
      'Esendere',
      'Eşen',
      'Erzurum',
      'Erzincan',
      'Eruh',
      'Ertuğrul',
      'Ermenek',
      'Erköklü',
      'Erkilet',
      'Ergani',
      'Erentepe',
      'Ereğli',
      'Erdemli',
      'Erciş',
      'Erçek',
      'Bulucan',
      'Emirgazi',
      'Emirdağ',
      'Emiralem',
      'Emet',
      'Elvanlı',
      'Güneysınır',
      'Elmalı',
      'Elmadağ',
      'Eleşkirt',
      'Elbistan',
      'Elbeyli',
      'Elbaşı',
      'Elazığ',
      'Ekinözü',
      'Eğirdir',
      'Eğil',
      'Kocasinan',
      'Düzova',
      'Dutluca',
      'Duruca',
      'Dursunbey',
      'Durak',
      'Dumlupınar',
      'Sumbas',
      'Döşemealtı',
      'Dorutay',
      'Dorumali',
      'Dörtyol',
      'Dombay',
      'Domaniç',
      'Doğubayazıt',
      'Doğanyol',
      'Doğansu',
      'Doğanşehir',
      'Doğanpınar',
      'Doğanköy',
      'Doğankent',
      'Doğankavak',
      'Doğanhisar',
      'Doğanca',
      'Doğanbeyli',
      'Doğanbey',
      'Dodurga',
      'Diyarbakır',
      'Diyadin',
      'Divriği',
      'Direkli',
      'Dinek',
      'Dinar',
      'Dilektepe',
      'Dikili',
      'Dicle',
      'Develi',
      'Devecikonağı',
      'Derinkuyu',
      'Derik',
      'Dereyanı',
      'Turanlı',
      'Derebucak',
      'Dere',
      'Derbent',
      'Denizli',
      'Demiryol',
      'Adala',
      'Deliktaş',
      'Delice',
      'Deliçay',
      'Değirmendere',
      'Dedeli',
      'Dazkırı',
      'Davutlar',
      'Davulga',
      'Datça',
      'Darıkent',
      'Dargeçit',
      'Darende',
      'Danişment',
      'Damlacık',
      'Dalyan',
      'Dallıbahçe',
      'Dalama',
      'Dağyolu',
      'Dağlıca',
      'Dağkızılca',
      'Serhatta',
      'Dağ',
      'Dağbaşı',
      'Dağardı',
      'Dadaşköy',
      'Çüngüş',
      'Çumra',
      'Menderes',
      'Cumaçay',
      'Akziyaret',
      'Çukurca',
      'Özvatan',
      'Düvertepe',
      'Hakkâri',
      'Çolaklı',
      'Çokak',
      'Çobanlar',
      'Cizre',
      'Çivril',
      'Çırpı',
      'Çine',
      'Çınaraltı',
      'Çınar',
      'Evren',
      'Cihanbeyli',
      'Çığlı',
      'Çığır',
      'Çiğil',
      'Çiftlikköyü',
      'Çiftlik',
      'Melikgazi',
      'Çifteler',
      'Çiftehan',
      'Çiçekli',
      'Çiçekdağı',
      'Ceylanpınar',
      'Ceyhan',
      'Çevrimova',
      'Cevizlik',
      'Cevizli',
      'Çatalan',
      'Çetinkaya',
      'Çeşme',
      'Çermik',
      'Cengerli',
      'Çemişgezek',
      'Çeltikçi',
      'Çeltik',
      'Çelikhan',
      'Çelebi',
      'Celâlli',
      'Çaylı',
      'Çaylarbaşı',
      'Çaylar',
      'Çayırlı',
      'Çayıralan',
      'Çaybağı',
      'Çay',
      'Çavuşlu',
      'Çavuş',
      'Çavdır',
      'Çavdarhisar',
      'Çatalçam',
      'Çatalarmut',
      'Çatakköprü',
      'Çatak',
      'Çat',
      'Çardak',
      'Çandır',
      'Çandarlı',
      'Çan',
      'Çamlıyayla',
      'Çamlıdere',
      'Anamas',
      'Çameli',
      'Çamardı',
      'Çaltılıbük',
      'Çalpınar',
      'Çalışkan',
      'Çaldıran',
      'Çal',
      'Çakırlar',
      'Çakırhüyük',
      'Çakırbeyli',
      'Menteşe',
      'Çağlayancerit',
      'Çağlayan',
      'Çağlarca',
      'Çağış',
      'Büyükyurt',
      'Nikfer',
      'Büyük Kardeş',
      'Boğaziçi',
      'Armutlu',
      'Burhaniye',
      'Buharkent',
      'Burdur',
      'Burç',
      'Bünyan',
      'Buldan',
      'Bulanık',
      'Bucakkışla',
      'Buca',
      'Bucak',
      'Bozyazı',
      'Bozüyük',
      'Boztepe',
      'Bozova',
      'Bozoğlak',
      'Bozkurt',
      'Bozkır',
      'Bozhüyük',
      'Bozdoğan',
      'Bozcaada',
      'Bozburun',
      'Bozarmut',
      'Bozan',
      'Bozalan',
      'Bostancı',
      'Bornova',
      'Borlu',
      'Bor',
      'Bolvadin',
      'Bölükyazı',
      'Bölme',
      'Böğürtlen',
      'Boğazören',
      'Boğazlıyan',
      'Boğazkent',
      'Bodrum',
      'Bitlis',
      'Bismil',
      'Birgi',
      'Birecik',
      'Bingöl',
      'Binatlı',
      'Bigadiç',
      'Beytüşşebap',
      'Beyşehir',
      'Beypınarı',
      'Beylikova',
      'Beyçayırı',
      'Beyağaç',
      'Kızılin',
      'Beşpınar',
      'Besni',
      'Beşkonak',
      'Beşiri',
      'Bergama',
      'Belören',
      'Belevi',
      'Belen',
      'Demirtaş',
      'Belek',
      'Bekirhan',
      'Bekilli',
      'Behram',
      'Beğendik',
      'Bedirli',
      'Bayramiç',
      'Bayraklı',
      'Baykan',
      'Bayır',
      'Bayındır',
      'Bayat',
      'Batman',
      'Başyurt',
      'Başpınar',
      'Başmakçı',
      'Baskil',
      'Başkavak',
      'Başkale',
      'Yoğun',
      'Barla',
      'Barıştepe',
      'Banaz',
      'Balya',
      'Balpınar',
      'Balpayam',
      'Balışeyh',
      'Balıkesir',
      'Balcı',
      'Balaban',
      'Bala',
      'Baklan',
      'Bahşılı',
      'Bahçesaray',
      'Bahçe',
      'Bağpınar',
      'Bağlıca',
      'Bağkonak',
      'Bağışlı',
      'Bağgöze',
      'Bağdere',
      'Bağarası',
      'Bademli',
      'Babayağmur',
      'Babatorun',
      'Babadağ',
      'Ayvalık',
      'Ayvacık',
      'Ayrancı',
      'Tillo',
      'Aydınkonak',
      'İbradı',
      'Aydıncık',
      'Aydın',
      'Avsallar',
      'Avanos',
      'Atça',
      'Atburgazı',
      'Atabey',
      'Aslanapa',
      'Aşkale',
      'Aşağıpınarbaşı',
      'Aşağıçiğil',
      'Aşağı Beğdeş',
      'Arslanköy',
      'Arıcak',
      'Arguvan',
      'Argıthanı',
      'Aras',
      'Yüreğir',
      'Arapgir',
      'Aran',
      'Aralık',
      'Araban',
      'Antalya',
      'Antakya',
      'Ankara',
      'Andırın',
      'Andaç',
      'Anamur',
      'Ambar',
      'Altınyayla',
      'Altınyaka',
      'Altıntaş',
      'Altınözü',
      'Altınova',
      'Altınoluk',
      'Altınkum',
      'Altunhisar',
      'Altınekin',
      'Alpu',
      'Alpköy',
      'Aliağa',
      'Albayrak',
      'Alaşehir',
      'Alanya',
      'Alakamış',
      'Alaçatı',
      'Turluk',
      'Alacahan',
      'Aktuzla',
      'Aktepe',
      'Aksu',
      'Akseki',
      'Akşehir',
      'Aksaray',
      'Akşar',
      'Akpınar',
      'Akören',
      'Akköy',
      'Akkışla',
      'Akkent',
      'Akıncılar',
      'Akıncı',
      'Akhisar',
      'Akdere',
      'Akdağmadeni',
      'Akçay',
      'Akçapınar',
      'Akçaova',
      'Akçakent',
      'Akçakale',
      'Akçadağ',
      'Akarsu',
      'Ahmetli',
      'Ahlat',
      'Ahırlı',
      'Ağlasun',
      'Ağın',
      'Akcakışla',
      'Ağaçören',
      'Ağaçlı',
      'Gülağaç',
      'Ağabeyli',
      'Afyonkarahisar',
      'Afşin',
      'Adıyaman',
      'Adilcevaz',
      'Adana',
      'Adaklı',
      'Acırlı',
      'Acıpınar',
      'Acıpayam',
      'Acıgöl',
      'Acarlar',
      'Çardaklı',
      'Ballı',
      'Yemişli',
      'Gündeş',
      'Nurdağı',
      'Küçükkendirci',
      'Güvenç',
      'Aşağı Karafakılı',
      'Çırtıman',
      'Kızkalesi',
      'Kuzeytepe',
      'Kastal',
      'Açıkdere',
      'Toygarlı',
      'Gümüşgöze',
      'Dursunlu',
      'Aşağıokçular',
      'Boynuyoğun',
      'Uzunbağ',
      'Büyükçat',
      'Balıklıdere',
      'Hacıpaşa',
      'Samankaya',
      'Anayazı',
      'Karasüleymanlı',
      'Büyük Dalyan',
      'Günyazı',
      'Uzunkavak',
      'Denizciler',
      'Basaran',
      'Doruklu',
      'Akdiken',
      'Bulutlu',
      'Pekmezli',
      'Koyunluca',
      'Yukarı Taşyalak',
      'Çıplak',
      'Batikent',
      'Türkler',
      'Başyayla',
      'Dalaman',
      'Zonguldak',
      'Zile',
      'Zeytinlik',
      'Zeytinburnu',
      'Zeytinbağı',
      'Yuvacık',
      'Yusufeli',
      'Yürük',
      'Yomra',
      'Yoğuntaş',
      'Yığılca',
      'Yeşilvadi',
      'Yeşilce',
      'Yenişehir',
      'Yeniçağa',
      'Yavuzkemal',
      'Körfez',
      'Yapraklı',
      'Yalova',
      'Yalnızçam',
      'Yalakdere',
      'Yakuplu',
      'Yakakent',
      'Yağmurdere',
      'Yağlıdere',
      'Vize',
      'Vezirköprü',
      'Vezirhan',
      'Velimeşe',
      'Veliköy',
      'Vakfıkebir',
      'Uzunköprü',
      'Uzunisa',
      'Uzungöl',
      'Uzundere',
      'Üsküp',
      'Üsküdar',
      'Uruş',
      'Ünye',
      'Umurbey',
      'Ulus',
      'Ulaşlı',
      'Uğurludağ',
      'Tuzluca',
      'Tütüncü',
      'Gülyalı',
      'Türkeli',
      'Turhal',
      'Trabzon',
      'Tosya',
      'Torul',
      'Tortum',
      'Topçam',
      'Tonya',
      'Tokat',
      'Tirebolu',
      'Terme',
      'Tekkiraz',
      'Tekkeköy',
      'Tekke',
      'Tekirdağ',
      'Teke',
      'Tavşancıl',
      'Tatkavaklı',
      'Taşova',
      'Taşköprü',
      'Taraklı',
      'Taflan',
      'Tacir',
      'Susuzmüsellim',
      'Susuz',
      'Suşehri',
      'Sürmene',
      'Sungurlu',
      'Suluova',
      'Sultanköy',
      'Süloğlu',
      'Sulakyurt',
      'Sölöz',
      'Söğütpınar',
      'Söğütalan',
      'Şişli',
      'Sırpsındığı',
      'Şiran',
      'Sinop',
      'Sinekli',
      'Sinekçi',
      'Silivri',
      'Şile',
      'Seyitler',
      'Şeyhli',
      'Seydim',
      'Seydiler',
      'Şevketiye',
      'Sergen',
      'Şerefiye',
      'Serdivan',
      'Şenpazar',
      'Şenkaya',
      'Selimpaşa',
      'Selim',
      'Şebin Karahisar',
      'Seben',
      'Şavşat',
      'Şarköy',
      'Sarıkamış',
      'Sarıbuğday',
      'Saraydüzü',
      'Saraycık',
      'Sapanca',
      'Samsun',
      'Samandıra',
      'Şalpazarı',
      'Şahin',
      'Safranbolu',
      'Şabanözü',
      'Rize',
      'Pursaklar',
      'Posof',
      'Piraziz',
      'Pınarhisar',
      'Perşembe',
      'Pendik',
      'Pehlivanköy',
      'Peçenek',
      'Pazaryolu',
      'Pazar',
      'Pamukova',
      'Ovacuma',
      'Yenikonak',
      'Osmaneli',
      'Osmancık',
      'Hemşin',
      'Ortakent',
      'Ortacalar',
      'Orta',
      'Ormanlı',
      'Orhangazi',
      'Ordu',
      'Olur',
      'Oltu',
      'Öğdem',
      'Of',
      'Niksar',
      'Narman',
      'Narlıca',
      'Nallıhan',
      'Mustafakemalpaşa',
      'Mudurnu',
      'Mudanya',
      'Mimarsinan',
      'Mihalgazi',
      'Meydancık',
      'Mesudiye',
      'Merzifon',
      'Meriç',
      'Mengen',
      'Mecitözü',
      'Mecidiye',
      'Marmara Ereğlisi',
      'Marmaracık',
      'Marmara',
      'Manyas',
      'Maltepe',
      'Malkara',
      'Mahmut Şevket Paşa',
      'Mahmutbey',
      'Maçka',
      'Lüleburgaz',
      'Lapseki',
      'Lâlapaşa',
      'Ladik',
      'Kuzyaka',
      'Kurucaşile',
      'Kurtkale',
      'Küre',
      'Küplü',
      'Kumru',
      'Kumburgaz',
      'Kumbağ',
      'Kullar',
      'Küçükkumla',
      'Küçükdere',
      'Kozlu',
      'Kozcağız',
      'Koyulhisar',
      'Kovanlık',
      'Kötek',
      'Köseköy',
      'Köse',
      'Koruköy',
      'Korgun',
      'Korgan',
      'Konuralp',
      'Kolay',
      'Saltukova',
      'Kofçaz',
      'Kocadere',
      'Kocaali',
      'Kızılırmak',
      'Kızılcahamam',
      'Kıyıköy',
      'Kışlaköy',
      'Kırklareli',
      'Kırcasalih',
      'Dikmen',
      'Kırbaşı',
      'Kirazlı',
      'Kınalı',
      'Kilimli',
      'Kılıçkaya',
      'Kılıç',
      'Kıbrıscık',
      'Kestel',
      'Keşap',
      'Keşan',
      'Kemerburgaz',
      'Kelkit',
      'Kefken',
      'Kazan',
      'Kaytazdere',
      'Kaynaşlı',
      'Kaynarca',
      'Kaymas',
      'Kavaklı',
      'Kastamonu',
      'Beycuma',
      'Kars',
      'Karayaka',
      'Karaurgan',
      'Karasu',
      'Karasu Mahallesi',
      'Karaşar',
      'Karapürçek',
      'Oğuzlar',
      'Karamürsel',
      'Karakurt',
      'Karakasım',
      'Karadere',
      'Karacaköy',
      'Karacabey',
      'Karabulduk',
      'Karabük',
      'Karabiga',
      'Kaptanpaşa',
      'Kapaklı',
      'Kandıra',
      'Kâmil',
      'Kalkandere',
      'Kalecik',
      'Kağızman',
      'Evreşe',
      'Kadıköy',
      'Kabataş',
      'Kabalı',
      'Kabadüz',
      'İznik',
      'İzmit',
      'İyidere',
      'Istanbul',
      'İspir',
      'İskilip',
      'İpsala',
      'İntepe',
      'Boyalı',
      'İnhisar',
      'İnegol',
      'İnecik',
      'İnece',
      'İnebolu',
      'Gökçeada',
      'Yarhisar',
      'Ilgaz',
      'İkizören',
      'İkizdere',
      'İhsangazi',
      'İğneada',
      'İğdir',
      'İbriktepe',
      'Horasan',
      'Hopa',
      'Filyos',
      'Hereke',
      'Hendek',
      'Nurluca',
      'Güzelkent',
      'Hayrat',
      'Hayrabolu',
      'Havza',
      'Havsa',
      'Hasayaz',
      'Hanönü',
      'Hanak',
      'Hamzabey',
      'Hamidiye',
      'Dedeköy',
      'Hamamözü',
      'Hacıhamza',
      'Dokurcun',
      'Çayırova',
      'Güvem',
      'Gürsu',
      'Gürgentepe',
      'Güneysu',
      'Kırık',
      'Güneyce',
      'Gündoğdu',
      'Gümüşova',
      'Gümüşhane',
      'Gümüşhacıköy',
      'Gümüşçay',
      'Gümüş',
      'Güdül',
      'Akkaya',
      'Göynücek',
      'Görükle',
      'Görele',
      'Gölyaka',
      'Gölpazarı',
      'Göllüce',
      'Kömürlü',
      'Gölköy',
      'Göle',
      'Murgul',
      'Gökçeli',
      'Gökçesu',
      'Gökçekent',
      'Gökçebey',
      'Göbel',
      'Giresun',
      'Geyve',
      'Gerze',
      'Gerede',
      'Gemlik',
      'Gelibolu',
      'Gediksaray',
      'Gebze',
      'Gaziler',
      'Fındıklı',
      'Ferizli',
      'Fatsa',
      'Eynesil',
      'Eymir',
      'Eğerci',
      'Eskipazar',
      'Gözyeri',
      'Esiroğlu',
      'Esenyurt',
      'Esenler',
      'Esençay',
      'Erfelek',
      'Erenler',
      'Erdek',
      'Erbaa',
      'Enez',
      'Eminönü',
      'Eldivan',
      'Eflani',
      'Edirne',
      'Edincik',
      'Eceabat',
      'Düzköy',
      'Düzce',
      'Durusu',
      'Durağan',
      'Dumlu',
      'Dörtdivan',
      'Dökmetepe',
      'Doğruyol',
      'Doğanyurt',
      'Doğantepe',
      'Doğanşar',
      'Doğançay',
      'Dikbıyık',
      'Digor',
      'Devrekani',
      'Devrek',
      'Destek',
      'Dernekpazarı',
      'Derince',
      'Derepazarı',
      'Dereli',
      'Dereköy',
      'Derecik',
      'Büyükderbent',
      'Demirözü',
      'Demirkent',
      'Darıca',
      'Dambaslar',
      'Damal',
      'Daday',
      'Çubuk',
      'Çorum',
      'Çorlu',
      'Çöpköy',
      'Çırçır',
      'Çınarcık',
      'Çilimli',
      'Çıldır',
      'Çiftlikköy',
      'Cide',
      'Cerrah',
      'Çerkezköy',
      'Çerkeş',
      'Çerçiler',
      'Cemilbey',
      'Çekmeköy',
      'Çekerek',
      'Kürtün',
      'Çaykara',
      'Çayırhan',
      'Çayeli',
      'Çaycuma',
      'Çaybaşı',
      'Çatalzeytin',
      'Çatalca',
      'Çarşıbaşı',
      'Çarşamba',
      'Çanta',
      'Çankırı',
      'Çanakkale',
      'Çanakçı',
      'Çamoluk',
      'Çamlıkaya',
      'Çamlıhemşin',
      'Çamlıbel',
      'Camili',
      'Çamaş',
      'Çalı',
      'Çakırlı',
      'Çakırca',
      'Çakıralan',
      'Laçin',
      'Büyükköy',
      'Büyükkarıştıran',
      'Büyükçekmece',
      'Büyükçavuşlu',
      'Adalar',
      'Bursa',
      'Sarıcakaya',
      'Bulancak',
      'Buğdaylı',
      'Boyalık',
      'Boyalıca',
      'Boyabat',
      'Borçka',
      'Bolu',
      'Bolayır',
      'Bolaman',
      'Boğazkale',
      'Boğazkaya',
      'Eminbey',
      'Bilecik',
      'Biga',
      'Asarcık',
      'Beypazarı',
      'Beyazköy',
      'Beşikdüzü',
      'Bereketli',
      'Bayramören',
      'Bayburt',
      'Başgedikler',
      'Başçiftlik',
      'Bartın',
      'Bandırma',
      'Banarlı',
      'Ondokuzmayıs',
      'Balıklıçeşme',
      'Balçık',
      'Bakacak',
      'Bahçecik',
      'Bağcılar',
      'Bafra',
      'Babaeski',
      'Azdavay',
      'Aydıntepe',
      'Ezinepazarı',
      'Aybastı',
      'Ayaş',
      'Ayancık',
      'Atkaracalar',
      'Aşağı Irmaklar',
      'Artvin',
      'Artova',
      'Arsin',
      'Arpaçay',
      'Arnavutköy',
      'Arıt',
      'Arifiye',
      'Arhavi',
      'Çamiçi',
      'Ardeşen',
      'Ardanuç',
      'Ardahan',
      'Araklı',
      'Araç',
      'Amasya',
      'Amasra',
      'Alucra',
      'Almus',
      'Alıcık',
      'Alaplı',
      'Alanyurt',
      'Alaçam',
      'Alaca',
      'Akyurt',
      'Akyazı',
      'Akyaka',
      'Aksakal',
      'Mollafeneri',
      'Akmeşe',
      'Akkuş',
      'Akdağ',
      'Akçakoca',
      'Akçaabat',
      'Ağva',
      'Ağlı',
      'Adapazarı',
      'Abana',
      'Espiye',
      'Celâliye',
      'Muratbey',
      'Halıdere',
      'Merter Keresteciler',
      'güngören merter',
      'Turgutreis',
      'Karatay',
      'Sarigerme',
      'Ölüdeniz',
      'Arıköy',
      'Çatalhöyük',
      'Adalan',
      'Ataşehir',
      'Başakşehir',
      'Beylikdüzü',
      'Çankaya',
      'Yaniklar',
      'Battalgazi',
      'Güce',
      'Çatalpınar',
      'Şehitkamil',
      'Şahinbey',
      'Bahçelievler',
      'Sultangazi',
      'Sultanbeyli',
      'Sancaktepe',
      'Kamışlı',
      'Çayırkent',
      'Salıpazarı',
      'Karabağlar',
      'Muratpaşa',
      'Okurcalar',
      'Cumayeri',
      'Polateli',
      'Kazımpaşa',
      'Termal',
      'Yıldırım',
      'Osmangazi',
      'Büyükorhan',
      'Akçasır',
      'Taşburun',
      'Bağlar',
      'Sur',
      'Palandöken',
      'Yakutiye',
      'Odunpazarı',
      'Nilüfer',
      'Merkez',
      'İlkadım',
      'Kepez',
      'Ümraniye',
      'Altınordu',
      'Kürecik',
      'Merkezefendi',
    ],
  },
  {
    country: 'Trinidad and Tobago',
    cities: [
      'Tunapuna',
      'Tabaquite',
      'Siparia',
      'Scarborough',
      'Sangre Grande',
      'San Fernando',
      'Rio Claro',
      'Princes Town',
      'Port of Spain',
      'Point Fortin',
      'Petit Valley',
      'Peñal',
      'Paradise',
      'Mucurapo',
      'Mon Repos',
      'Marabella',
      'Laventille',
      'Diego Martin',
      'Debe',
      'Couva',
      'Chaguanas',
      'Arouca',
      'Arima',
    ],
  },
  {
    country: 'Tuvalu',
    cities: [
      'Toga Village',
      'Tanrake Village',
      'Kulia Village',
      'Funafuti',
      'Asau Village',
      'Savave Village',
      'Alapi Village',
      'Fakaifou Village',
    ],
  },
  {
    country: 'Taiwan',
    cities: [
      'Douliu',
      'Yujing',
      'Yuanlin',
      'Donggang',
      'Taoyuan',
      'Taitung',
      'Taipei',
      'Taibao',
      'Xinying',
      'Tainan',
      'Fengyuan',
      'Taichung',
      'Daxi',
      'Banqiao',
      'Puli',
      'Pizitou',
      'Pingtung',
      'Nantou',
      'Miaoli',
      'Magong',
      'Lugu',
      'Fengshan',
      'Kaohsiung',
      'Yilan',
      'Hualien City',
      'Xindian',
      'Hsinchu',
      'Hengchun',
      'Zhubei',
      'Jiufen',
      'Jincheng',
      'Keelung',
      'Jiayi Shi',
      'Chang-hua',
      'Taoyuan City',
      'Nangan',
      'Zhongxing New Village',
    ],
  },
  {
    country: 'Tanzania',
    cities: [
      'Zanzibar',
      'Wete',
      'Vwawa',
      'Vikindu',
      'Uyovu',
      'Uwelini',
      'Uvinza',
      'Utete',
      'Usoke',
      'Ushirombo',
      'Usevia',
      'Usagara',
      'Usa River',
      'Urambo',
      'Ulenje',
      'Tunduma',
      'Tumbi',
      'Tukuyu',
      'Tinde',
      'Tarime',
      'Tanga',
      'Tabora',
      'Sumbawanga',
      'Soni',
      'Songwa',
      'Somanda',
      'Sokoni',
      'Sirari',
      'Singida',
      'Sikonge',
      'Shinyanga',
      'Shelui',
      'Sepuka',
      'Same',
      'Rulenge',
      'Rujewa',
      'Puma',
      'Poli',
      'Pangani',
      'Old Shinyanga',
      'Nzega',
      'Nyanguge',
      'Nyamuswa',
      'Nyalikungu',
      'Nyakahanga',
      'Nyakabindi',
      'Nsunga',
      'Nshamba',
      'Nkove',
      'Nkoaranga',
      'Njombe',
      'Nguruka',
      'Ngudu',
      'Ngorongoro',
      'Ngerengere',
      'Ngara',
      'Nganane',
      'Ndungu',
      'Ndago',
      'Nansio',
      'Nangwa',
      'Namanyere',
      'Namanga',
      'Nakatunguru',
      'Naberera',
      'Mwembe',
      'Mwaya',
      'Mwanza',
      'Mwanga',
      'Mwadui',
      'Mvomero',
      'Musoma',
      'Muriti',
      'Mungaa',
      'Muleba',
      'Muheza',
      'Mugumu',
      'Mugango',
      'Mtwango',
      'Mto wa Mbu',
      'Mtinko',
      'Mtimbira',
      'Mtambile',
      'Msowero',
      'Msanga',
      'Mpwapwa',
      'Mpanda',
      'Moshi',
      'Morogoro',
      'Monduli',
      'Mlowo',
      'Mlimba',
      'Mlangali',
      'Mlandizi',
      'Mlalo',
      'Mkuranga',
      'Mkokotoni',
      'Mwandiga',
      'Misungwi',
      'Misasi',
      'Mikumi',
      'Micheweni',
      'Mhango',
      'Mgandu',
      'Mbumi',
      'Mbulu',
      'Mbuguni',
      'Mbeya',
      'Mazinde',
      'Matui',
      'Matonga',
      'Matamba',
      'Matai',
      'Maswa',
      'Masumbwe',
      'Masoko',
      'Maramba',
      'Maneromango',
      'Malya',
      'Malinyi',
      'Malangali',
      'Malampaka',
      'Makuyuni',
      'Makungu',
      'Makumbako',
      'Makanya',
      'Majengo',
      'Mahonda',
      'Mahenge',
      'Mahanje',
      'Kihangara',
      'Magugu',
      'Magomeni',
      'Magole',
      'Mafinga',
      'Mabamba',
      'Mabama',
      'Lushoto',
      'Lupiro',
      'Lugoba',
      'Longido',
      'Liwale',
      'Lembeni',
      'Lalago',
      'Laela',
      'Kyela',
      'Kyaka',
      'Kwakoa',
      'Kongwa',
      'Kondoa',
      'Konde',
      'Koani Ndogo',
      'Koani',
      'Kiwira',
      'Kiwengwa',
      'Kisiwani',
      'Kishapu',
      'Kisesa',
      'Kisarawe',
      'Kisanga',
      'Kirya',
      'Kirando',
      'Kiomboi',
      'Kintinku',
      'Kingori',
      'Kimamba',
      'Kilosa',
      'Kilindoni',
      'Kilimatinde',
      'Kijini',
      'Kihurio',
      'Kigoma',
      'Kidodi',
      'Kidatu',
      'Kibondo',
      'Kibiti',
      'Kibaya',
      'Kibara',
      'Kibakwe',
      'Kibaha',
      'Katumba',
      'Katoro',
      'Katesh',
      'Katerero',
      'Kasulu',
      'Kasamwa',
      'Karema',
      'Kiratu',
      'Kandete',
      'Kamachumu',
      'Kaliua',
      'Kakonko',
      'Kahama',
      'Kabanga',
      'Izazi',
      'Itumba',
      'Itigi',
      'Issenye',
      'Isaka',
      'Iringa',
      'Ipinda',
      'Inyonga',
      'Ilula',
      'Ilongero',
      'Ilembula',
      'Ilembo',
      'Ikwiriri',
      'Ikungi',
      'Igurusi',
      'Igurubi',
      'Igunga',
      'Igugunu',
      'Ifakara',
      'Hedaru',
      'Geita',
      'Geiro',
      'Gamba',
      'Galappo',
      'Endasak',
      'Dunda',
      'Dongobesh',
      'Dodoma',
      'Dar es Salaam',
      'Dareda',
      'Chimala',
      'Chato',
      'Chanika',
      'Chalinze',
      'Chala',
      'Chake Chake',
      'Butiama',
      'Buseresere',
      'Bungu',
      'Bunda',
      'Bukonyo',
      'Bukoba',
      'Bukene',
      'Bugene',
      'Bugarama',
      'Biharamulo',
      'Basotu',
      'Bashanet',
      'Bariadi',
      'Bagamoyo',
      'Babati',
      'Arusha',
      'Tingi',
      'Tandahimba',
      'Songea',
      'Ruangwa',
      'Nyangao',
      'Newala Kisimani',
      'Nanyamba',
      'Nanhyanga',
      'Nangomba',
      'Nanganga',
      'Namikupa',
      'Namalenga',
      'Nachingwea',
      'Mtwara',
      'Mtama',
      'Mingoyo',
      'Mbinga',
      'Mbekenyera',
      'Mbamba Bay',
      'Matiri',
      'Masuguru',
      'Masasi',
      'Maposeni',
      'Manda',
      'Mahuta',
      'Madimba',
      'Lulindi',
      'Lukuledi',
      'Luchingu',
      'Liuli',
      'Lindi',
      'Kitangari',
      'Kitama',
      'Kigonsera',
      'Chiungutwa',
      'Merelani',
      'Nungwi',
      'Kisasa',
    ],
  },
  {
    country: 'Ukraine',
    cities: [
      'Olenevka',
      'Urzuf',
      'Lebedyn',
      'Staryi Dobrotvir',
      'Druzhkivka',
      'Pavlivka',
      'Zhydachiv',
      'Vasylivka',
      'Kovyl’noye',
      'Ivano-Frankove',
      'Hvozdavka Druha',
      'Zybiny',
      'Zvenihorodka',
      'Zuya',
      'Zuhres',
      'Zorkino',
      'Zorynsk',
      'Zolotyy Potik',
      'Zolotoye Pole',
      'Zolotonosha',
      'Zolochiv',
      'Znamyanka Druha',
      'Znamyanka',
      'Mostove',
      'Zmiiv',
      'Zlynka',
      'Zymohiria',
      'Zimino',
      'Zhytomyr',
      'Zhvanets',
      'Zhuravno',
      'Zhuravki',
      'Zhuravka',
      'Zhuravychi',
      'Blahodatne',
      'Karakurt',
      'Yezupil',
      'Petrovirivka',
      'Zhovkva',
      'Zhmerynka',
      'Zavodske',
      'Zherebkove',
      'Zhemchuzhina',
      'Zhelyabovka',
      'Zhovti Vody',
      'Zhdeniyevo',
      'Zhashkiv',
      'Stara Zhadova',
      'Zghurivka',
      'Zernovoye',
      'Zinkiv',
      'Zemlyanichnoye',
      'Ahronomiya',
      'Zelyonoye',
      'Zelenivka',
      'Zelenohirske',
      'Zelenogorskoye',
      'Zelena',
      'Zdolbuniv',
      'Zdovbytsia',
      'Zboriv',
      'Zbarazh',
      'Zavetnoye',
      'Zavet-Leninskiy',
      'Zavallya',
      'Zavodivka',
      'Karolino-Buhaz',
      'Zatoka',
      'Zatyshshya',
      'Zastavna',
      'Zorya',
      'Zarozhany',
      'Zarichchia',
      'Zarichne',
      'Zarechnoye',
      'Zaporizhzhya',
      'Zaozyornoye',
      'Zamostia',
      'Zaliztsi',
      'Zaliznyy Port',
      'Zaliznychne',
      'Zalesnoye',
      'Zalishchyky',
      'Zakupne',
      'Zahvizdia',
      'Zahnitkiv',
      'Zachepylivka',
      'Zabolotiv',
      'Yuzhne',
      'Yuzhynets',
      'Yuryivka',
      'Yurkivka',
      'Bunhe',
      'Yevpatoriya',
      'Yevhenivka',
      'Yermakovo',
      'Yerky',
      'Yenakiieve',
      'Yemilchyne',
      'Yemelyanovka',
      'Yelyzavethradka',
      'Yelanets',
      'Yavoriv',
      'Yavkyne',
      'Yats’kivka',
      'L’govskoye',
      'Yastrubynove',
      'Yasky',
      'Yasinya',
      'Yasynuvata',
      'Yasenove Druhe',
      'Yarove',
      'Yaroslavka',
      'Yarmolyntsi',
      'Yarkoye Pole',
      'Yarkoye',
      'Yampil’',
      'Yampil',
      'Yamnytsia',
      'Yalta',
      'Yahotyn',
      'Yahilnytsia',
      'Yabluniv',
      'Yablunytsia',
      'Vyshchetarasivka',
      'Vysokopillya',
      'Vysokyi',
      'Vyshkovo',
      'Vyshhorod',
      'Dneprovka',
      'Vypasne',
      'Vynohradiv',
      'Vylok',
      'Vylkove',
      'Vyhoda',
      'Vvedenka',
      'Vradiyivka',
      'Voznesensk',
      'Voznesenka',
      'Voznesenka Persha',
      'Voyutychi',
      'Voynyliv',
      'Voykovo',
      'Voyevodske',
      'Vozsiyatske',
      'Voskresenske',
      'Vorzel',
      'Vorozhba',
      'Voronovytsya',
      'Voronivka',
      'Voronkiv',
      'Vorokhta',
      'Vorobyovo',
      'Volovets',
      'Voloka',
      "Nikol's’ke",
      'Volodarka',
      'Volochysk',
      'Vilne',
      'Volnovakha',
      'Vilnohirsk',
      'Vovkovyntsi',
      'Vovchynets',
      'Staryy Vovchynets',
      'Vovchansk',
      'Voinka',
      'Vodiane',
      'Vlasivka',
      'Vladislavovka',
      'Volodymyr-Volynskyi',
      'Volodymyrivka',
      'Volodymyrets',
      'Vyzyrka',
      'Vyzhnytsya',
      'Vyzhenka',
      'Vitrianka',
      'Vytylivka',
      'Vysunsk',
      'Vyshneve',
      'Vishennoye',
      'Vishnyovka',
      'Vyshnivets',
      'Vishnevchik',
      'Vynohradove',
      'Vinogradovo',
      'Vynohradivka',
      'Vynohradne',
      'Vinogradnoye',
      'Vynohradar',
      'Vinnytsya',
      'Vinkivtsi',
      'Vilnyansk',
      'Vilino',
      'Vikno',
      'Veselyy Kut',
      'Veselynove',
      'Vesele',
      'Veselovka',
      'Vesela Dolyna',
      'Verkhivtseve',
      'Verkhovyna',
      'Verkhorechye',
      'Verkhnia Syrovatka',
      'Verchnia Rozhanka',
      'Verkhnia Bilka',
      'Verkhnyachka',
      'Verkhniy Yasenov',
      'Verkhniy Rohachyk',
      'Verkhni Petrivtsi',
      'Verkhnie Synevydne',
      'Verkhnotoretske',
      'Verkhnesadovoye',
      'Verkhnodniprovsk',
      'Veresayevo',
      'Verenchanka',
      'Shevchenkove',
      'Verblyany',
      'Verbivka',
      'Vendychany',
      'Velykoploske',
      'Velyka Mykhaylivka',
      'Velykyy Bychkiv',
      'Velykokomarivka',
      'Velykodolynske',
      'Velykyi Liubin',
      'Velykyy Kuchuriv',
      'Velykyi Kliuchiv',
      'Velyki Sorochyntsi',
      'Velyki Mosty',
      'Velyki Korovyntsi',
      'Velyki Kopani',
      'Velyki Birky',
      'Velykyi Dalnyk',
      'Velykyi Burluk',
      'Velykyy Bereznyy',
      'Velyka Pysarivka',
      'Velyka Novosilka',
      'Velyka Mechetnya',
      'Velyka Lepetykha',
      'Velyka Dymerka',
      'Velyka Buda',
      'Velyka Berezovytsya',
      'Velyka Bilozerka',
      'Velyka Bahachka',
      'Velyka Oleksandrivka',
      'Velykosillya',
      'Vatutine',
      'Vasyshcheve',
      'Vasilyevka',
      'Vasyliv',
      'Vasylkiv',
      'Vashkivtsi',
      'Vary',
      'Varvarivka',
      'Varva',
      'Vapnyarka',
      'Vanchikivtsi',
      'Valky',
      'Valyava',
      'Batiovo',
      'Uzyn',
      'Uzhgorod',
      'Uyutnoye',
      'Uvarovo',
      'Utkivka',
      'Utkonosivka',
      'Ustynivka',
      'Ustyluh',
      'Ust-Chorna',
      'Uspenivka',
      'Uspenka',
      'Usatove',
      'Urozhaynoye',
      'Uralo-Kavkaz',
      'Nyzhni Petrivtsi',
      'Uman',
      'Blahovishchenske',
      'Ulychne',
      'Ulaniv',
      'Ukromnoye',
      'Ukrainsk',
      'Ukrainka',
      'Ukrayinka',
      'Uhryniv',
      'Uhniv',
      'Uglovoye',
      'Vuhlehirsk',
      'Udobne',
      'Tyvriv',
      'Tysovets',
      'Tysmenytsya',
      'Tysmenychany',
      'Tynne',
      'Desna',
      'Tyachiv',
      'Tuzly',
      'Turiatka',
      'Turka',
      'Turiisk',
      "Tur'i Remety",
      'Turgenevka',
      'Turbiv',
      'Tulchyn',
      'Tukhlia',
      'Oleshky',
      'Tsybulivka',
      'Tsybli',
      'Tsvetochnoye',
      'Tsvitkove',
      'Tsybuliv',
      'Tselinnoye',
      'Tsebrykove',
      'Tsarychanka',
      'Truskavets',
      'Trudove',
      'Troyitske',
      'Trostianets',
      'Trostyanets',
      'Troyitsko-Safonove',
      'Trypillia',
      'Trykraty',
      'Trykhaty',
      'Tryduby',
      'Trokhizbenka',
      'Trebukhiv',
      'Trapivka',
      'Toshkivka',
      'Torkanivka',
      'Chystyakove',
      'Topory',
      'Toporivtsi',
      'Topaly',
      'Tomashpil',
      'Tomakivka',
      'Tovste',
      'Tokmak',
      'Tokarevo',
      'Tochylove',
      'Tlumach',
      'Tyshkivka',
      'Tymoshivka',
      'Tetiiv',
      'Terpinnia',
      'Terny',
      'Ternivka',
      'Ternovka',
      'Ternuvate',
      'Ternopil',
      'Ternavka',
      'Teresva',
      'Terebovlya',
      'Terebleche',
      'Teplovka',
      'Irmino',
      'Teplytsia',
      'Teplyk',
      'Teofipol',
      'Maslovo',
      'Tenistoye',
      'Boykivske',
      'Tovtry',
      'Tatarbunary',
      'Klepinino',
      'Tarutyne',
      'Tarashcha',
      'Tarakaniv',
      'Tankovoye',
      'Sary-Bash',
      'Tamaryne',
      'Talne',
      'Talalaivka',
      'Taborivka',
      'Tabaky',
      'Tabachnoye',
      'Syrove',
      'Sievierodonetsk',
      'Sychavka',
      'Svyatogorsk',
      'Svitiaz',
      'Druzhba',
      'Svetloye',
      'Svitlovodsk',
      'Svitlodolynske',
      'Svetlodarsk',
      'Dovzhansk',
      'Ivanove',
      'Svatove',
      'Svarychiv',
      'Svalyava',
      'Suvorovskoye',
      'Suvorove',
      'Sutysky',
      'Susanino',
      'Sursko-Mykhailivka',
      'Sumy',
      'Sukhyi Lyman',
      'Sukhovolya',
      'Sukhoverkhiv',
      'Sukholuzhzhia',
      'Stepnohirsk',
      'Sudova Vyshnia',
      'Sudak',
      'Sucheveny',
      'Subottsi',
      'Studenok',
      'Stryi',
      'Striukove',
      'Strumok',
      'Stroyntsi',
      'Strogonovka',
      'Stryzhavka',
      'Striletskyy Kut',
      'Strabychovo',
      'Stizhkivske',
      'Storozhnytsia',
      'Storozhynets',
      'Stolbovoye',
      'Stetseva',
      'Stepove',
      'Botanicheskoye',
      'Stepnoye',
      'Stepkivka',
      'Stepanivka',
      'Stebnyk',
      'Stebliv',
      'Staiky',
      'Stavrove',
      'Stavkove',
      'Stavyshche',
      'Stavchany',
      'Staryi Sambir',
      'Staryi Saltiv',
      'Staryy Merchyk',
      'Staryi Krym',
      'Stary Krym',
      'Stari Troyany',
      'Stari Kuty',
      'Stari Broskivtsi',
      'Stari Bohorodchany',
      'Staroye Selo',
      'Starosillya',
      'Starokostyantyniv',
      'Starokozache',
      'Starobesheve',
      'Starobilsk',
      'Starychi',
      'Starohorozhene',
      'Temriuk',
      'Stara Vyzhivka',
      'Stara Ushytsya',
      'Stara Tsarychanka',
      'Stara Sil',
      'Stara Synyava',
      'Stara Nekrasivka',
      'Murafa',
      'Stara Kulna',
      'Stanislavka',
      'Stanislav',
      'Stanytsia Luhanska',
      'Stanivtsi',
      'Stal’noye',
      'Stalnivtsi',
      'Stakhanovka',
      'Kadiyivka',
      'Serednie Vodiane',
      'Serednye',
      'Sribne',
      'Spaske',
      'Spas',
      'Sovetskiy',
      'Sosnytsya',
      'Solotvyno',
      'Solotvyn',
      'Solonka',
      'Solonytsivka',
      'Solnechnogorskoye',
      'Solnechnaya Dolina',
      'Solone',
      'Voznesenske',
      'Sokolinoye',
      'Ilyinka',
      'Sokyryany',
      'Sokal',
      'Sofiyivka',
      'Sofiivka',
      'Sobolivka',
      'Sniatyn',
      'Snyachiv',
      'Snihurivka',
      'Snizhne',
      'Smotrych',
      'Smoline',
      'Smila',
      'Sloviansk',
      'Slov`yanoserbsk',
      'Slobidka',
      'Sloboda',
      'Slavyanskoye',
      'Slavuta',
      'Slavske',
      'Slavnoye',
      'Slavhorod',
      'Slatyne',
      'Skvortsovo',
      'Skvyra',
      'Skole',
      'Skhidnytsia',
      'Skelivka',
      'Skalistoye',
      'Skalat',
      'Skala-Podilska',
      'Skadovsk',
      'Sizovka',
      'Syvaske',
      'Sytkivtsi',
      'Syniukhyn Brid',
      'Synevyrska Poliana',
      'Synevyr',
      'Synelnykove',
      'Simferopol',
      'Simeiz',
      'Shyshkivtsi',
      'Shumsk',
      'Shubranets',
      'Shturmovoye',
      'Shramkivka',
      'Shpola',
      'Shpykiv',
      'Shostka',
      'Sholokhove',
      'Shklo',
      'Mykhaylivka',
      'Shyshaky',
      'Shyryayeve',
      'Shyrivtsi',
      'Shyroke',
      'Pshenichnoye',
      'Shyrokolanivka',
      'Shypyntsi',
      'Shylivtsi',
      'Partizany',
      'Sheshory',
      'Sloboda-Komarivtsi',
      'Shershentsi',
      'Shepetivka',
      'Shelkovichnoye',
      'Shebutyntsi',
      'Snovsk',
      'Shchyrets',
      'Shcherbynivka',
      'Shcherbanka',
      'Shcherbani',
      'Shchebetovka',
      'Shatsk',
      'Sharhorod',
      'Shalyhyne',
      'Shakhtarsk',
      'Shabo',
      'Siversk',
      'Sevastopol',
      'Serpneve',
      'Serhiyi',
      'Serhiyivka',
      'Serhiivka',
      'Seredyna-Buda',
      'Serebrianka',
      'Serebryanka',
      'Serebriia',
      'Serby',
      'Serbka',
      'Serbychany',
      'Sentianivka',
      'Sencha',
      'Semisotka',
      'Semypolky',
      'Simeikyne',
      'Semenivka',
      'Seliatyn',
      'Selyshche',
      'Selydove',
      'Silets',
      'Sekretarka',
      'Sekretarivka',
      'Siedove',
      'Sedniv',
      'Sebyne',
      'Shchastia',
      'Savran',
      'Savyntsi',
      'Sataniv',
      'Sarny',
      'Sarazhinka',
      'Sarata',
      'Sambir',
      'Salkove',
      'Saki',
      'Sakhnovshchyna',
      'Safyany',
      'Sadzhavka',
      'Sadove',
      'Sadovoye',
      'Yurkivtsi',
      'Rzhyshchiv',
      'Rzhavyntsi',
      'Rynhach',
      'Rykhtychi',
      'Rybakivka',
      "Rybach'e",
      'Ruzhyn',
      'Ruskoivanivka',
      'Ruska Poliana',
      'Rusakovka',
      'Rukshyn',
      'Rudne',
      'Rudnytsya',
      'Rudky',
      'Ruch’i',
      'Rubizhne',
      'Rozvadiv',
      'Rozivka',
      'Rozhnyativ',
      'Rozhniv',
      'Rozhyshche',
      'Rozdil',
      'Rozdilna',
      'Roylyanka',
      'Kurakhove',
      'Rivne',
      'Rovenky',
      'Roztoky',
      'Rososhany',
      'Rozkishna',
      'Rozkishne',
      'Roskoshnoye',
      'Ropcha',
      'Romodan',
      'Romny',
      'Romashkino',
      'Romankivtsi',
      'Roksolany',
      'Rokosovo',
      'Rokytne',
      'Rohatyn',
      'Rohan',
      'Rodnikovo',
      'Rodynske',
      'Rodatychi',
      'Revne',
      'Reshetylivka',
      'Repuzhyntsi',
      'Ripky',
      'Ridkivtsi',
      'Richky',
      'Rechka',
      'Rozdory',
      'Razdol’noye',
      'Rava-Ruska',
      'Ratne',
      'Rozsypne',
      'Rashkiv',
      'Ralivka',
      'Rakiv Lis',
      'Rakoshyno',
      'Rakhiv',
      'Rafalivka',
      'Radushne',
      'Radisnyy Sad',
      'Radisne',
      'Radomyshl',
      'Radens’k',
      'Radekhiv',
      'Radcha',
      'Pyatykhatky',
      'Pyatikhatka',
      'Puzhaykovo',
      'Putyvl',
      'Putyla',
      'Pustomyty',
      'Pushkino',
      'Pukhivka',
      'Pryluky',
      'Pryamobalka',
      'Prudianka',
      'Prudy',
      'Prosyana',
      'Prostornoye',
      "P'yatypill'ya",
      'Dyakivtsi',
      'Pryyutivka',
      'Pryvillia',
      'Pryvilne',
      'Privetnoye',
      'Pryshyb',
      'Pryozerne',
      'Priozyornoye',
      'Sartana',
      'Prymorske',
      'Prymors’ke',
      'Primorskiy',
      'Prymorsk',
      'Prylymanske',
      'Prykolotne',
      'Prybuzhzhya',
      'Prybuzhany',
      'Prybuzske',
      'Pryazovske',
      'Pryvoroky',
      'Preobrazhenka',
      'Pravda',
      'Potelych',
      'Poroshkovo',
      'Porohy',
      'Popilnia',
      'Popasna',
      'Poninka',
      'Pomichna',
      'Pomoriany',
      'Polianka',
      'Polyanetske',
      'Poliana',
      'Poltava',
      'Polonne',
      'Polohy',
      'Pokrovske',
      'Pokrovka',
      'Ivanivka',
      'Pokotylivka',
      'Pohreby',
      'Pohrebyshche',
      'Pohorilivka',
      'Podvirne',
      'Pidvolochysk',
      'Pidvynohradiv',
      'Pidkamin',
      'Pidhirtsi',
      'Pidhorodne',
      'Pidhorodna',
      'Pidhirne',
      'Pidhaytsi',
      'Pidbuzh',
      'Poshtove',
      'Pochayiv',
      'Pobuzke',
      'Pobednoye',
      'Pnikut',
      'Pniv',
      'Plodovoye',
      'Plavni',
      'Koktebel',
      'Plakhtiyivka',
      'Pivdenne',
      'Pishcha',
      'Pysarivka',
      'Pyriatyn',
      'Pyrizhna',
      'Pionerskoye',
      'Pylypets',
      'Petrivske',
      'Petrovske',
      'Petrove',
      'Petrivka',
      'Petrovka',
      'Petropavlivka',
      'Petrodolynske',
      'Kurisove',
      'Petrykivka',
      'Petrikov',
      'Petrivsk',
      'Petranka',
      'Piskivka',
      'Peschanoye',
      'Pishchanka',
      'Pischchana',
      'Pervomayskoye',
      'Pervomaiskyi',
      'Pervomaisk',
      'Pervomaysk',
      'Pershotravneve',
      'Manhush',
      'Pershotravensk',
      'Perovo',
      'Perkivtsi',
      'Pereiaslav',
      'Perevalsk',
      "Pereval'noye",
      'Pereshchepyne',
      'Peresichna',
      'Peresadivka',
      'Peremyshliany',
      'Perehonivka',
      'Perehinske',
      'Perechyn',
      'Perebykivtsi',
      'Pechenizhyn',
      'Pavlysh',
      'Pavlohrad',
      'Pasytsely',
      'Pashkivtsi',
      'Pasichna',
      'Parutyne',
      'Rykove',
      'Partizanskoye',
      'Parkhomivka',
      'Makariv Yar',
      'Parafiivka',
      'Paniutyne',
      'Pantayivka',
      'Panka',
      'Ozheve',
      'Ozerne',
      'Ozerna',
      'Ovruch',
      'Ovidiopol',
      'Otyniya',
      'Ostrovskoye',
      'Ostrivne',
      'Ostroh',
      'Ostrytsya',
      'Oster',
      'Osypenko',
      'Osychky',
      'Oshykhliby',
      'Orzhytsya',
      'Orzhiv',
      'Orlovskoye',
      'Orlivka',
      'Orlinoye',
      'Orikhivka',
      'Orikhiv',
      'Pokrov',
      'Ordzhonikidze',
      'Orativ',
      'Oprysheny',
      'Opishnya',
      'Onufriyivka',
      'Onokivtsi',
      'Olyka',
      'Olshanske',
      'Vilshanka',
      'Vilshana',
      'Olyshivka',
      'Olevsk',
      'Olesko',
      'Oleshnyk',
      'Olenivka',
      'Oleksandriya',
      'Oleksandrivka',
      'Okunevka',
      'Oktyabr’skoye',
      "Oktyabr'skoye",
      'Okhotskoye',
      'Okhotnikovo',
      'Odessa',
      'Ochakiv',
      'Obukhiv',
      'Obroshyne',
      'Obodivka',
      'Obertyn',
      'Nyrkiv',
      'Nuino',
      'Novyi Yarychiv',
      'Novyi Svit',
      'Novy Svet',
      'Novyi Starodub',
      'Novi Troyany',
      'Novi Sanzhary',
      'Novi Petrivtsi',
      'Novi Broskivtsi',
      'Novi Bilokorovychi',
      'Novyy Buh',
      'Novozhilovka',
      'Novoyelizavetivka',
      'Novoyehorivka',
      'Nove Davydkovo',
      'Dubki',
      'Novovorontsovka',
      'Novovolynsk',
      'Novovasylivka',
      'Novoukrayinka',
      'Novotroyitske',
      'Kobzartsi',
      'Novosvitlivka',
      'Novostepnoye',
      'Novoselytsya',
      'Novosilske',
      'Novoselovskoye',
      'Novoselivka',
      'Novosyolovka',
      'Mamayvtsi',
      'Novoselytsia',
      'Novosamarka',
      'Novopskov',
      'Novopoltavka',
      'Novopokrovka',
      'Poltavka',
      'Novopetrivske',
      'Novopetrivka',
      'Novopavlovka',
      'Novomykolayivka',
      'Novomykolaivka',
      'Novonikolayevka',
      'Novomoskovsk',
      'Novomyrhorod',
      'Novokrymskoye',
      'Novokrasne',
      'Novoivanovka',
      'Novohrad-Volynskyi',
      'Novohuivynske',
      'Novohryhorivka',
      'Novogrigoryevka',
      'Novohradkivka',
      'Hreyhove',
      'Novofedorivka',
      'Novodruzhesk',
      'Novodonetske',
      'Voskhod',
      'Novodnistrovsk',
      'Novoborysivka',
      'Novobohdanivka',
      "Novoazovs'k",
      'Novoaidar',
      'Novoarkhanhelsk',
      'Novoandreyevka',
      'Novooleksiyivka',
      'Novytsia',
      'Novhorod-Siverskyi',
      'Novhorodka',
      'Nove-Misto',
      'Nova Vodolaha',
      'Nova Praha',
      'Nova Odesa',
      'Nova Nekrasivka',
      'Nova Mayachka',
      'Nova Kakhovka',
      'Nova Ivanivka',
      'Nova Haleshchyna',
      'Novoestoniia',
      'Nova Dofinivka',
      'Nova Borova',
      'Nova Ushytsya',
      'Lashkivka',
      'Nosivka',
      'Nyzy',
      'Nizhyn',
      'Nyzhnia Krynka',
      'Nyzhnia Duvanka',
      'Nyzhni Stanivtsi',
      'Nyzhni Sirohozy',
      'Nyzhnye Selyshche',
      'Nizhnegorskiy',
      'Nikopol',
      'Mykolayivka-Novorosiyska',
      'Kryve Ozero Druhe',
      'Mykolaivka',
      'Mykolayivka',
      'Nikolayevka',
      'Nevytske',
      'Netishyn',
      'Nesvoya',
      'Nestoita',
      'Koshulyany',
      'Nerushay',
      'Nerubayske',
      'Neresnytsia',
      'Nepolokivtsi',
      'Nemyriv',
      'Nelypivtsi',
      'Nelipyno',
      'Nekrasovka',
      'Nedryhailiv',
      'Nedoboyvtsi',
      'Nechayane',
      'Nebyliv',
      'Naydenovka',
      'Navaria',
      'Nasypnoe',
      'Narodychi',
      'Nahirne',
      'Nadvirna',
      'Nadrichne',
      'Nadlymanske',
      'Naddnipryanske',
      'Mysovoe',
      'Shchyolkino',
      'Mykolaiv',
      'Mykolayiv',
      'Muzhiievo',
      'Muskatnoye',
      'Murovani Kurylivtsi',
      'Murovane',
      'Muravlivka',
      'Mukachevo',
      "Mostys'ka",
      'Mospyne',
      'Moshny',
      'Moshanets',
      'Morskoye',
      'Morshyn',
      'Monastyryska',
      'Monastyryshche',
      'Moloha',
      'Molodohvardiisk',
      'Molodiia',
      'Molodizhne',
      'Molodyozhnoye',
      'Molochnoye',
      'Molochansk',
      'Molnytsya',
      'Mohyliv-Podilskyy',
      'Mlyniv',
      'Mliiv',
      'Mizoch',
      'Miusynsk',
      'Mityayevo',
      'Mitrofanovka',
      'Myrivka',
      'Myronivka',
      'Mirny',
      'Myrne',
      'Myrnopillia',
      'Myrhorod',
      'Mynai',
      'Milove',
      'Miliyeve',
      'Mykulyntsi',
      'Mikhaylovka',
      'Mykhaylo-Laryne',
      'Mikhalkove',
      'Mykhalcha',
      'Myhove',
      'Petrashivka',
      'Myhiia',
      'Michurinskoye',
      'Mezhvodnoye',
      'Velyki Mezhyrichi',
      'Chudei',
      'Mizhhirya',
      'Mezhova',
      'Mezenivka',
      'Mishkovo-Pohorilove',
      'Merefa',
      'Minyaylivka',
      'Zhuravlyovka',
      'Oleksiyvka',
      'Mena',
      'Melnytsya-Podilska',
      'Melitopol',
      'Melioratyvne',
      'Melekyne',
      'Medzhybizh',
      'Medvedevo',
      'Medvedevka',
      'Medenychi',
      'Mazanka',
      'Mayskoye',
      'Mayaky',
      'Matviivka',
      'Matroska',
      'Massandra',
      'Mashivka',
      'Maryevka',
      'Maryanivka',
      'Marshyntsi',
      'Markivka',
      'Markova',
      'Mariupol',
      'Marynivka',
      "Mar'yinka",
      'Krinichnoye',
      'Marhanets',
      'Marfovka',
      'Mar’yanovka',
      'Mardarivka',
      'Marazliyivka',
      'Maniava',
      'Mankivka',
      'Manchenky',
      'Mamalyha',
      'Maly Mayak',
      'Malyy Kuchuriv',
      'Muromskoye',
      'Malyatyntsi',
      'Malorechenskoye',
      'Malokaterynivka',
      'Malokakhovka',
      'Malodolynske',
      'Malyntsi',
      'Novoorzhytske',
      'Malynivka',
      'Malyn',
      'Maliyivka',
      'Mala Vyska',
      'Stantsiyne',
      'Mala Tokmachka',
      'Mala Danylivka',
      'Mala Bilozerka',
      'Yantarnoye',
      'Makiv',
      'Makiivka',
      'Makariv',
      'Bayraky',
      'Mahdalynivka',
      'Magazinka',
      'Mahala',
      'Liutizh',
      'Lyubotyn',
      'Liuboml',
      'Liubomyrka',
      'Lyubymivka',
      'Liubeshiv',
      'Liubech',
      'Liubar',
      'Lysyanka',
      'Lysa Hora',
      'Larzhanka',
      'Lviv',
      'Luzhany',
      'Lutuhyne',
      'Lutsk',
      'Lutovynivka',
      'Lukavtsi',
      'Lukovytsya',
      'Lukiv',
      'Lukanivka',
      'Luhansk',
      'Lugovoye',
      'Luhyny',
      'Luhanske',
      'Luchistoye',
      'Lubny',
      'Lozove',
      'Lozovoye',
      'Lozova',
      'Lozuvatka',
      'Lozno-Oleksandrivka',
      'Lotskyne',
      'Loshchynivka',
      'Lopukhiv',
      'Lopatyn',
      'Lomachyntsi',
      'Lokhvytsya',
      'Lokachi',
      'Lobanovo',
      'Lyubashivka',
      'Livadia',
      'Litvinenkovo',
      'Lityn',
      'Lysychovo',
      'Lysychansk',
      'Lysets',
      'Lypovets',
      'Lypova Dolyna',
      'Lypniazhka',
      'Lypetske',
      'Lypcha',
      'Lymany',
      'Lymanske',
      'Lymans’ke',
      'Lyman',
      'Lykhivka',
      'Livyntsi',
      'Letychiv',
      'Lisovi Sorochyntsi',
      'Lisne',
      'Lisky',
      'Lepetykha',
      'Lenkivtsi',
      'Leninskoye',
      'Lenino',
      'Mariia',
      'Lazurne',
      'Lazi',
      'Lazeshchyna',
      'Lanivtsi',
      'Lanchyn',
      'Ladyzhyn',
      'Ladan',
      'Labushne',
      'Kyiv',
      'Kvasy',
      'Bilmak',
      'Kuibyshevo',
      'Kuyalnyk',
      'Kuty',
      'Kutsurub',
      'Kutkivtsi',
      'Kuteinykove',
      'Kushuhum',
      'Kushnytsia',
      'Kuriachi Lozy',
      'Kurakhovo',
      'Kupiansk',
      'Kupka',
      'Kumari',
      'Kulynychi',
      'Kulykivka',
      'Kulykiv',
      'Kulishivka',
      'Tokarivka',
      'Kuchurhan',
      'Kryzhopil',
      'Kryzhanivka',
      'Kryvyy Rih',
      'Hrushivka',
      'Krymskoye',
      'Krymka',
      'Kruty',
      'Krutoiarivka',
      'Krutenky',
      'Kruhlyk',
      'Krolevets',
      'Kryve Ozero',
      'Kryva Luka',
      'Kryva Hora',
      'Kryva Balka',
      'Kryva',
      'Krynychne',
      'Krinichnaya',
      'Krynychky',
      'Krest’yanovka',
      'Kripenskyi',
      'Tatariv',
      'Kreminna',
      'Kremenets',
      'Kremenchuk',
      'Krayneye',
      'Krasnyi Mak',
      'Krasnyi Luch',
      'Krasnyi Kut',
      'Krasnoznamenka',
      'Krasne',
      'Krasnoyarskoye',
      'Krasnotorka',
      'Krasnosilka',
      'Krasnopillia',
      'Krasnopillya',
      'Yany Kapu',
      'Krasnopavlivka',
      'Krasnolesye',
      'Krasnokutsk',
      'Krasnokamenka',
      'Krasnoyilsk',
      'Krasnogvardeyskoye',
      'Krasnohrad',
      'Krasnohorivka',
      'Krasnogorka',
      'Krasnoflotskoye',
      'Teple',
      'Sorokyne',
      'Krasnoarmeyskoye',
      'Pokrovsk',
      'Krasnen’koye',
      "Krasna Zor'ka",
      'Krymskaya Roza',
      'Krasnaya Polyana',
      'Krasna',
      'Krasyliv',
      'Kramatorsk',
      'Kozyrka',
      'Kozova',
      'Kozliv',
      'Kozhanka',
      'Kozelshchyna',
      'Kozelets',
      'Kozyryany',
      'Kivsharivka',
      'Kovel',
      'Kovalivka',
      'Horbova',
      'Kotsiubynske',
      'Podilsk',
      'Kotlyareve',
      'Kotlovyna',
      'Kotelva',
      'Kotel’nikovo',
      'Koteleve',
      'Kostyantynivka',
      'Kostryzhivka',
      'Kostopil',
      'Kostyntsi',
      'Kosiv',
      'Kosmach',
      'Koson',
      'Koriukivka',
      'Korytne',
      'Korsuntsi',
      'Korsun-Shevchenkivskyy',
      'Koroviia',
      'Korotych',
      'Korostyshiv',
      'Korosten',
      'Koropets',
      'Korop',
      'Korolevo',
      'Kornyn',
      'Korniyivka',
      'Kormovoye',
      'Korets',
      'Koreiz',
      'Korchivtsi',
      'Kopychyntsi',
      'Kopaihorod',
      'Kostiantynivka',
      'Konotop',
      'Konoplyane',
      'Kinetspil',
      'Kondratyevo',
      'Slobozhans’ke',
      "Kal'mius'ke",
      'Kompaniyivka',
      'Dobroslav',
      'Komarivtsi',
      'Komariv',
      'Komarno',
      'Kol’tsovo',
      'Koloski',
      'Koloniya Zastav’ye',
      'Kolomyya',
      'Kolomak',
      'Kolodeznoye',
      'Kolodenka',
      'Kolochava',
      'Kulevcha',
      'Kolinkivtsy',
      'Kol’chugino',
      'Kolchyno',
      'Kamyanyy Mist',
      'Kodyma',
      'Kodra',
      'Kochetok',
      'Kobyzhcha',
      'Kobyletska Polyana',
      'Kobolchyn',
      'Kobleve',
      'Kobelyaky',
      'Knyazhichi',
      'Klivodyn',
      'Klishkivtsi',
      'Zrub-Komarivskyy',
      'Klevan',
      'Klenovyi',
      'Klembivka',
      'Klavdiievo-Tarasove',
      'Kivertsi',
      'Kitsman',
      'Shirokoye',
      'Kyslytsya',
      'Podviryvka',
      'Kyselivka',
      'Kyseliv',
      'Kyryakivka',
      'Kerstentsi',
      'Obukhivka',
      'Khrestivka',
      'Kirovskoye',
      'Holubivka',
      'Kropyvnytskyy',
      'Kirovo',
      'Kyrnychky',
      'Kyrnasivka',
      'Kyrykivka',
      'Kiliya',
      'Khyriv',
      'Khust',
      'Khriatska',
      'Khrystoforivka',
      'Khrystynivka',
      'Khotiv',
      'Khotyn',
      'Khorostkiv',
      'Horoshivtsi',
      'Khorosheve',
      'Khorol',
      'Kholodna Balka',
      'Kholmy',
      'Kholmske',
      'Khodoriv',
      'Khmelnytskyi',
      'Khmilnyk',
      'Khymchyn',
      'Kherson',
      'Yarivka',
      'Khartsyzk',
      'Kharkiv',
      'Hodyliv',
      'Kerch',
      'Kelmentsi',
      'Kehychivka',
      'Kozatske',
      'Kozyatyn',
      'Kazanka',
      'Kozachi Laheri',
      'Kaydaki',
      'Katiuzhanka',
      'Katerynopil',
      'Karnaukhivka',
      'Karlivka',
      'Soledar',
      'Karapyshi',
      'Karapchiv',
      'Kaplivka',
      'Kapitanivka',
      'Kaniv',
      'Komysh-Zorya',
      'Komyshnya',
      'Komyshivka',
      'Komyshuvakha',
      'Komyshany',
      'Kamyanske',
      'Kamyianets-Podilskyy',
      'Kamiani Potoky',
      'Kamyane',
      'Kamiane',
      'Kamyana',
      'Kamin-Kashyrskyi',
      'Kamyanka-Dniprovska',
      'Kamyanka',
      "Kam'yanka",
      'Kamianytsia',
      'Kalush',
      'Kalyny',
      'Kalynivka',
      'Kalinovka',
      'Kalynivske',
      'Kalinino',
      'Kalcheva',
      'Kalanchak',
      'Mirnoye',
      'Kakhovka',
      'Kairy',
      'Kaharlyk',
      'Kadubivtsi',
      'Kacha',
      'Izyumovka',
      'Izium',
      'Izyaslav',
      'Izvaryne',
      'Izobil’noye',
      'Izmail',
      'Yizhivtsi',
      'Iza',
      'Ivashkiv',
      'Ivanivtsi',
      'Skosarivka',
      'Ivanovka',
      'Ivanopil',
      'Ivano-Frankivsk',
      'Ivankiv',
      'Ivankivtsi',
      'Ispas',
      'Ishun’',
      'Isayeve',
      'Irshava',
      'Irpin',
      'Yosypivka',
      'Yordaneshty',
      'Ilovays’k',
      'Chornomors’k',
      'Ilarionove',
      'Illintsi',
      'Illinka',
      'Ilychyovo',
      'Ilyichevo',
      'Ichnia',
      'Hvizdivtsi',
      'Hradenytsi',
      'Horodok',
      'Horlivka',
      'Dachne',
      'Hlyboka',
      'Hlukhiv',
      'Hvizdets',
      'Hvizd',
      'Hvardiyske',
      'Hvardiiske',
      'Huty',
      'Husyatyn',
      'Gurzuf',
      'Huryivka',
      'Hupalivka',
      'Hulyaypole',
      'Hubynykha',
      'Grushevka',
      'Hrubna',
      'Hrozyntsi',
      'Hrytsiv',
      'Hryshkivtsi',
      'Grishino',
      'Hrymayliv',
      'Gresovskiy',
      'Hrebinka',
      'Hrebenyky',
      'Hradyzk',
      'Hrabove',
      'Hostomel',
      'Hoshcha',
      'Hirske',
      'Lesnovka',
      'Horokhivske',
      'Horodnia',
      'Horodnytsia',
      'Horodnye',
      'Horodyshche',
      'Horodenka',
      'Hirnyk',
      'Hornostayivka',
      'Gornostayevka',
      'Horishni Sherivtsi',
      'Horinchovo',
      'Kyrylivka',
      'Golubinka',
      'Holovyne',
      'Holovanivsk',
      'Holoby',
      'Holmivskyi',
      'Holma',
      'Hola Prystan',
      'Zelenyy Hay',
      'Hoholeve',
      'Hodynivka',
      'Hnivan',
      'Hnizdychne',
      'Hnizdychiv',
      'Hnidyn',
      'Hlukhivtsi',
      'Hlyboke',
      'Hlobyne',
      'Hlynytsya',
      'Hlevakha',
      'Glazovka',
      'Hlavani',
      'Hertsa',
      'Henichesk',
      'Hayvoron',
      'Haysyn',
      'Gaspra',
      'Handrabury',
      'Halytsynove',
      'Halych',
      'Hadyach',
      'Furmanivka',
      'Zakharivka',
      'Shtormovoye',
      'Partenit',
      'Frunze',
      'Frontovoye',
      'Foros',
      'Fontanka',
      'Filatovka',
      'Feodosiya',
      'Fastiv',
      'Faraonivka',
      'Eskhar',
      'Buran',
      'Enerhodar',
      'Dzvinogrud',
      'Mirnovka',
      'Dzhankoi',
      'Romaniv',
      'Toretsk',
      'Demnya',
      'Dymka',
      'Dymer',
      'Dvorichna',
      'Dunayivtsi',
      'Duliby',
      'Dudchany',
      'Dubrovytsia',
      'Duboviazivka',
      'Dubivtsi',
      'Dubove',
      'Dubno',
      'Dubliany',
      'Trybukhivtsi',
      'Drohobych',
      'Drobysheve',
      'Drahovo',
      'Drachyntsi',
      'Drabiv',
      'Dovbysh',
      'Doroshivka',
      'Doroshivtsi',
      'Yaremche',
      'Donetsk',
      'Domanivka',
      'Dovzhok',
      'Dolyna',
      'Dolynske',
      'Dolyns’ke',
      'Dolynska',
      'Dolinnoye',
      'Dolynyany',
      'Dovhe',
      'Dokuchaievsk',
      'Dobrianka',
      'Dobrushino',
      'Dobre',
      'Dobroye',
      'Dobrovelychkivka',
      'Dobropillia',
      'Dobrynivtsi',
      'Dobromyl',
      'Dobrooleksandrivka',
      'Dnipro',
      'Dnistrivka',
      'Dnipryany',
      'Dniprovske',
      'Dniprovka',
      'Dniprorudne',
      'Dmytrivka',
      'Delzhyler',
      'Dmitrovka',
      'Dyviziya',
      'Dynivtsy',
      'Oleksandriyske',
      'Myrnohrad',
      'Dykhtynets',
      'Dykanka',
      'Desantne',
      'Derhachi',
      'Valya Kuzmyna',
      'Derazhnya',
      'Raukhivka',
      'Demydove',
      'Delyatyn',
      'Dihtiari',
      'Debaltseve',
      'Davydivka',
      'Davydivtsi',
      'Dashiv',
      'Dalnyk',
      'Dalekoye',
      'Dachnoye',
      'Chutove',
      'Chulakivka',
      'Chuhuiv',
      'Chuhynka',
      'Chudniv',
      'Chortkiv',
      'Chopovychi',
      'Chop',
      'Krasnoye',
      'Chkalovo',
      'Chistopolye',
      'Chisten’koye',
      'Chunkiv',
      'Chynadiyovo',
      'Chyhyryn',
      'Oskil',
      'Esman',
      'Chervone',
      'Chervonopartyzansk',
      'Chervonohryhorivka',
      'Chervonohrad',
      'Pulyny',
      'Kubey',
      'Radyvyliv',
      'Chervona Sloboda',
      'Chortomlyk',
      'Cherniatyn',
      'Chornianka',
      'Chernyakhivka',
      'Cherniakhiv',
      'Chornukhyne',
      'Chornukhy',
      'Chernozemnoye',
      'Chornorudka',
      'Chernopolye',
      'Chernomorskoye',
      'Chornomorka',
      'Chornoliztsi',
      'Chornohuzy',
      'Chornobaivka',
      'Chornobay',
      'Cherniiv',
      'Chernivtsi',
      'Chernihiv',
      'Chernihivka',
      'Chernelytsya',
      'Cherneche',
      'Chornivka',
      'Chorna',
      'Cherlenivka',
      'Cherkasy',
      'Cherkaske',
      'Chereshenka',
      'Cheresh',
      'Cherepkivtsi',
      'Cheponosy',
      'Chemerivtsi',
      'Chelyadinovo',
      'Chechelnyk',
      'Chaykino',
      'Chasiv Yar',
      'Chaplynka',
      'Slobozhanske',
      'Kalynove',
      'Viitivka',
      'Voskresenka',
      'Chapayevka',
      'Chahor',
      'Bytkiv',
      'Bystrytsia',
      'Chernyshevo',
      'Bykivka',
      'Buzovytsya',
      'Buzinove',
      'Bushtyno',
      'Buryn',
      'Dolishniy Shepit',
      'Burshtyn',
      'Burlacha Balka',
      'Burylove',
      'Pozharskoye',
      'Buky',
      'Vesnyane',
      'Bukachivtsi',
      'Bouzke',
      'Buzke',
      'Budy',
      'Budenets',
      'Budey',
      'Buda',
      'Buchach',
      'Bucha',
      'Bryukhovychi',
      'Brianka',
      'Brusyliv',
      'Brovary',
      'Broska',
      'Broshniv-Osada',
      'Brody',
      'Brodetske',
      'Brylivka',
      'Bratslav',
      'Bratske',
      'Brayiliv',
      'Boiarka',
      'Boiany',
      'Boianchuk',
      'Nikita',
      'Borzna',
      'Borshchiv',
      'Borivtsi',
      'Borova',
      'Boromlia',
      'Borodianka',
      'Borodino',
      'Boryspil',
      'Borysivka',
      'Boryslav',
      'Bilshivtsi',
      'Velyka Balka',
      'Bolhrad',
      'Martynivske',
      'Bolekhiv',
      'Bohuslav',
      'Bohorodchany',
      'Bohodukhiv',
      'Bohdanivka',
      'Inhulka',
      'Bohdan',
      'Bahate',
      'Bogatoye',
      'Bochkivtsi',
      'Bobrynets',
      'Bobrovytsia',
      'Bibrka',
      'Bobryk Pershyy',
      'Bobivtsi',
      'Bobovo',
      'Blyzniuky',
      'Blizhneye',
      'Velykyi Buialyk',
      'Bilhorod-Dnistrovskyi',
      'Bila Tserkva',
      'Bezimenne',
      'Bezliudivka',
      'Shchaslyve',
      'Donskoye',
      'Bershad',
      'Bernove',
      'Berlohy',
      'Beryslav',
      'Berezovo',
      'Berezivka',
      'Berezovka',
      'Bereznyky',
      'Bereznehuvate',
      'Berezna',
      'Berizky',
      'Berezyne',
      'Berezhany',
      'Berezanka',
      'Berezan',
      'Berestechko',
      'Berehove',
      'Beregovoye',
      'Berehomet',
      'Velyki Berehy',
      'Berdychiv',
      'Berdyansk',
      'Bendzary',
      'Belz',
      'Bili Oslavy',
      'Bilyayivka',
      'Bilozerka',
      'Bile',
      "Bilovods'k",
      'Bilousivka',
      'Bilopillia',
      'Bilolissya',
      'Bilokurakyne',
      'Inkerman',
      'Bilohirya',
      'Bilohirsk',
      'Bilohorivka',
      'Bilky',
      'Bilytske',
      'Belinskoye',
      'Bilyne',
      'Bilyky',
      'Bilenke',
      'Bila Krynytsia',
      'Bila Krynytsya',
      'Belaya',
      'Bilivtsi',
      'Bekhtery',
      'Bazaryanka',
      'Bazaliya',
      'Bairachky',
      'Trudovoye',
      'Baybuzivka',
      'Barturyn',
      'Batal’noye',
      'Bashtankiv',
      'Bashtanka',
      'Baryshivka',
      'Barvinkove',
      'Bilozirka',
      'Barativka',
      'Baranivka',
      'Sinitsyno',
      'Baraboi',
      'Bar',
      'Bannivka',
      'Banyliv',
      'Banyliv-Pidhirnyy',
      'Balta',
      'Balovne',
      'Balky',
      'Balkivtsi',
      'Balamutivka',
      'Balakliia',
      'Balaklava',
      'Balabyne',
      'Baksha',
      'Bakhmach',
      'Bakhchysarai',
      'Bahrynivka',
      'Bahna',
      'Baherove',
      'Babyn',
      'Babanka',
      'Azovskoye',
      'Abrikosovo',
      'Avhustivka',
      'Avdiivka',
      'Avdiyivka',
      'Auly',
      'Ostanino',
      'Askaniya-Nova',
      'Artsyz',
      'Bakhmut',
      'Artemivsk',
      'Zalizne',
      'Skorokhodove',
      'Aromatnoye',
      'Armyansk',
      'Arkhanhelske',
      'Arbuzynka',
      'Apostolove',
      'Antratsyt',
      'Antonivka',
      'Antoniny',
      'Andrushky',
      'Andrushivka',
      'Andriyevo-Ivanivka',
      'Andriyivka',
      'Andriivka',
      'Ananyiv',
      'Amvrosiivka',
      'Amurskoye',
      'Alushta',
      'Alupka',
      'Oleksiivka',
      'Alekseyevka',
      'Aleksandrovka',
      'Lisove',
      'Alchevsk',
      'Andreyevka',
      'Yakymivka',
      'Akimovka',
      'Okhtyrka',
      'Ilichanka',
      'Agrarnoye',
      'Lekarstvennoye',
      'Aeroflotskiy',
      'Adzhamka',
      'Abrikosovka',
      'Lupareve',
      'Kopashnovo',
      'Narkevychi',
      'Kirove',
      'Orshivtsi',
      'Brusenky',
      'Verkhni Stanivtsi',
      'Brusnytsia',
      'Novosel’skoye',
      'Orekhovo',
      'Kosy',
      'Perekhrestove',
      'Okny',
      'Borshchi',
      'Sterche',
      'Smyha',
      'Tayirove',
      'Teplodar',
      'Tavriysk',
      'Horishni Plavni',
      'Slavutych',
      'Kurskoye',
      'Rovnoye',
      'Kashtanovoye',
      'Levadki',
      'Beloglinka',
      'Komsomol’skoye',
      'Malen’koye',
      'Solnechnoye',
      'Fontany',
      'Zalesye',
      'Konstantinovka',
      'Mel’nichnoye',
      'Drofino',
      'Kostochkovka',
      'Nekrasovo',
      'Uvarovka',
      'Listvennoye',
      'Vol’noye',
      'Roshchino',
      'Suvorovo',
      'Izumrudnoye',
      'Kholmovka',
      'Pakharevka',
      'Luganskoye',
      'Yasnopolyanskoye',
      'Novoozyornoye',
      'Chernovo',
      'Kukushkino',
      'Senokosnoye',
      'Bratskoye',
      'Sovkhoznoye',
      'Pochetnoye',
      'Kamenolomnia',
      'Vladimirovka',
      'Geroyskoye',
      'Zhuravli',
      'Chervonoye',
      'Novofedorovka',
      'Shkol’noye',
      'Vinnitskoye',
      'Prudovoye',
      'Kashtany',
      'Solnechniy',
      'Polyushko',
      'Zheleznodorozhnoye',
      'Denyshi',
      'Yuzhnoukrayinsk',
      'Varash',
      'Kremidivka',
      "Hlyns'k",
      "Novoyavorivs'k",
      'Sofiyivska Borschagivka',
      'Motovylivka',
      'Kriukivschina',
      'Horenka',
      'Chabany',
      'Prolisky',
      'Partyzanske',
      'Soltanivka',
      'Lativka',
      'Chornomorske',
      'Khlibodarske',
      'Avanhard',
      'Nova Dolyna',
      'Lymanka',
      'Maiory',
      'Kalahliia',
      'Krasna Kosa',
      'Salhany',
      'Brytivka',
      'Aviatorske',
      'Rozkvit',
      'Sakharnaya Golovka',
      'Nove',
      'Hlybochok',
      'Nova Pryluka',
      'Zelenyi Hai',
      'Tsentralne',
      'Mykolayivske',
      'Polihon',
      'Husiatyn',
      'Novi Chobruchi',
      'Troiandove',
      'Tarasivtsi',
      'Reni',
      'Dubynove',
      'Dovhopillya',
      'Prypruttya',
      'Dranytsya',
      'Dumeny',
      'Kostychany',
      'Vartykivtsi',
      'Vladychna',
      'Pervomayske',
    ],
  },
  {
    country: 'Uganda',
    cities: [
      'Zombo',
      'Yumbe',
      'Wobulenzi',
      'Wakiso',
      'Tororo',
      'Soroti',
      'Sironko',
      'Serere',
      'Sembabule',
      'Rukungiri',
      'Rubirizi',
      'Rubanda',
      'Rakai',
      'Pallisa',
      'Paidha',
      'Pader Palwo',
      'Nyachera',
      'Ntungamo',
      'Ntoroko',
      'Nsika',
      'Njeru',
      'Ngora',
      'Nebbi',
      'Namutumba',
      'Namasuba',
      'Nakasongola',
      'Nakaseke',
      'Mukono',
      'Muhororo',
      'Mubende',
      'Mpigi',
      'Moyo',
      'Moroto',
      'Mityana',
      'Mitoma',
      'Mbarara',
      'Mbale',
      'Mayuge',
      'Masindi Port',
      'Masindi',
      'Masaka',
      'Maracha',
      'Lyantonde',
      'Lwengo',
      'Luwero',
      'Lugazi',
      'Lira',
      'Kyotera',
      'Kyenjojo',
      'Kyegegwa',
      'Kumi',
      'Kotido',
      'Koboko',
      'Kitgum',
      'Kisoro',
      'Kiryandongo',
      'Kiruhura',
      'Kireka',
      'Kilembe',
      'Kigorobya',
      'Kibuku',
      'Kiboga',
      'Kibingo',
      'Kibale',
      'Kayunga',
      'Katakwi',
      'Kasese',
      'Kapchorwa',
      'Kanungu',
      'Kanoni',
      'Kamwenge',
      'Kamuli',
      'Kampala',
      'Kalungu',
      'Kaliro',
      'Kalangala',
      'Kakumiro',
      'Kajansi',
      'Kagadi',
      'Kaberamaido',
      'Kabale',
      'Kaabong',
      'Jinja',
      'Iganga',
      'Ibanda',
      'Hoima',
      'Gulu',
      'Gombe',
      'Fort Portal',
      'Entebbe',
      'Dokolo',
      'Byakabanda',
      'Bwizibwera',
      'Bweyogerere',
      'Buyende',
      'Buwenge',
      'Butebo',
      'Butaleja',
      'Busia',
      'Bushenyi',
      'Busembatia',
      'Bundibugyo',
      'Bulisa',
      'Bulambuli',
      'Bukwa',
      'Bukomansimbi',
      'Bukedea',
      'Buikwe',
      'Bugiri',
      'Bugembe',
      'Bududa',
      'Budaka',
      'Binyiny',
      'Arua',
      'Apac',
      'Amuria',
      'Amudat',
      'Amolatar',
      'Alebtong',
      'Adjumani',
      'Abim',
      'Nakapiripirit',
      'Pader',
      'Margherita',
      'Nwoya',
      'Isingiro',
      'Manafwa',
      'Oyam',
      'Amuru',
      'Namayingo',
      'Kitamilo',
      'Kyankwanzi',
      'Kole',
      'Otuke',
      'Napak',
      'Luuka Town',
      'Buhweju',
      'Agago',
      'Lamwo',
      'Kampala Central Division',
    ],
  },
  {
    country: 'United States',
    cities: [
      'Bay Minette',
      'Edna',
      'Bayou La Batre',
      'Henderson',
      'Natalia',
      'Bear Creek',
      'Fort Hunt',
      'Yorktown',
      'Brighton',
      'Ocean View',
      'Berry',
      'Trinity',
      'Villas',
      'Bessemer',
      'Aurora',
      'New Pekin',
      'Stockton',
      'Paducah',
      'Monticello',
      'Red Chute',
      'Jessup',
      'Birmingham',
      'Delhi Hills',
      'Turpin Hills',
      'Lugoff',
      'Blountsville',
      'Blue Ridge',
      'Buda',
      'Boaz',
      'Brent',
      'Brewton',
      'Bridgeport',
      'Brook Highland',
      'Brookside',
      'Brookwood',
      'Brundidge',
      'Butler',
      'Bynum',
      'Cahaba Heights',
      'Calera',
      'Camden',
      'Carbon Hill',
      'Carrollton',
      'Cedar Bluff',
      'Center Point',
      'Centre',
      'Centreville',
      'Chalkville',
      'Chatom',
      'Chelsea',
      'Cherokee',
      'Chickasaw',
      'Childersburg',
      'Choccolocco',
      'Citronelle',
      'Clanton',
      'Clay',
      'Clayton',
      'Cleveland',
      'Clio',
      'Coaling',
      'Collinsville',
      'Columbiana',
      'Concord',
      'Coosada',
      'Cordova',
      'Cottonwood',
      'Cowarts',
      'Creola',
      'Crossville',
      'Cullman',
      'Dadeville',
      'Daleville',
      'Danville',
      'Daphne',
      'Dauphin Island',
      'Deatsville',
      'Decatur',
      'Demopolis',
      'Dora',
      'Dothan',
      'Double Springs',
      'East Brewton',
      'East Florence',
      'Eclectic',
      'Elberta',
      'Enterprise',
      'Eufaula',
      'Eutaw',
      'Evergreen',
      'Fairfield',
      'Fairhope',
      'Falkville',
      'Fayette',
      'Fayetteville',
      'Flint City',
      'Florala',
      'Florence',
      'Flomaton',
      'Foley',
      'Forestdale',
      'Fort Deposit',
      'Fort Payne',
      'Frisco City',
      'Fultondale',
      'Fyffe',
      'Gadsden',
      'Gardendale',
      'Geneva',
      'Georgiana',
      'Glencoe',
      'Good Hope',
      'Goodwater',
      'Gordo',
      'Grand Bay',
      'Grayson Valley',
      'Graysville',
      'Greensboro',
      'Greenville',
      'Grove Hill',
      'Guin',
      'Gulf Shores',
      'Guntersville',
      'Hackleburg',
      'Haleyville',
      'Hamilton',
      'Hanceville',
      'Harpersville',
      'Hartford',
      'Hartselle',
      'Harvest',
      'Hayden',
      'Hayneville',
      'Hazel Green',
      'Headland',
      'Heflin',
      'Helena',
      'Henagar',
      'Hokes Bluff',
      'Holt',
      'Holtville',
      'Homewood',
      'Hoover',
      'Horton',
      'Hueytown',
      'Huguley',
      'Huntsville',
      'Indian Springs Village',
      'Inverness',
      'Irondale',
      'Jackson',
      'Jacksonville',
      'Jasper',
      'Jemison',
      'Kimberly',
      'Kinsey',
      'Ladonia',
      'Lafayette',
      'Lake Purdy',
      'Lake View',
      'Lanett',
      'Leeds',
      'Leesburg',
      'Level Plains',
      'Linden',
      'Lincoln',
      'Lineville',
      'Lipscomb',
      'Livingston',
      'Locust Fork',
      'Loxley',
      'Luverne',
      'Madison',
      'Malvern',
      'Marbury',
      'Margaret',
      'Marion',
      'Meadowbrook',
      'Meridianville',
      'Midfield',
      'Midland City',
      'Mignon',
      'Millbrook',
      'Minor',
      'Mobile',
      'Monroeville',
      'Montevallo',
      'Montgomery',
      'Moody',
      'Moores Mill',
      'Morris',
      'Moulton',
      'Moundville',
      'Mount Olive',
      'Mount Vernon',
      'Mountain Brook',
      'Munford',
      'Muscle Shoals',
      'New Brockton',
      'New Hope',
      'New Market',
      'Newton',
      'North Bibb',
      'Northport',
      'Odenville',
      'Ohatchee',
      'Opelika',
      'Oneonta',
      'Opp',
      'Orange Beach',
      'Owens Cross Roads',
      'Oxford',
      'Ozark',
      'Pelham',
      'Pell City',
      'Phenix City',
      'Phil Campbell',
      'Piedmont',
      'Pike Road',
      'Pine Level',
      'Pinson',
      'Pleasant Grove',
      'Point Clear',
      'Prattville',
      'Priceville',
      'Prichard',
      'Ragland',
      'Rainbow City',
      'Rainsville',
      'Red Bay',
      'Reform',
      'Rehobeth',
      'Riverside',
      'Roanoke',
      'Robertsdale',
      'Rock Creek',
      'Rockford',
      'Rogersville',
      'Russellville',
      'Saks',
      'Samson',
      'Saraland',
      'Sardis City',
      'Satsuma',
      'Scottsboro',
      'Selma',
      'Semmes',
      'Sheffield',
      'Shelby',
      'Slocomb',
      'Smiths Station',
      'Smoke Rise',
      'Southside',
      'Spanish Fort',
      'Springville',
      'Steele',
      'Stevenson',
      'Stewartville',
      'Sulligent',
      'Sumiton',
      'Summerdale',
      'Sylacauga',
      'Sylvan Springs',
      'Sylvania',
      'Talladega',
      'Tallassee',
      'Tarrant',
      'Taylor',
      'Theodore',
      'Thomasville',
      'Thorsby',
      'Tillmans Corner',
      'Town Creek',
      'Troy',
      'Trussville',
      'Tuscaloosa',
      'Tuscumbia',
      'Tuskegee',
      'Union Springs',
      'Uniontown',
      'Valley',
      'Valley Grande',
      'Vandiver',
      'Vance',
      'Vernon',
      'Vestavia Hills',
      'Vincent',
      'Warrior',
      'Weaver',
      'Webb',
      'Wedowee',
      'West Blocton',
      'Westover',
      'Wetumpka',
      'Whitesboro',
      'Wilsonville',
      'Winfield',
      'Woodstock',
      'York',
      'Alexander',
      'Alma',
      'Arkadelphia',
      'Arkansas City',
      'Ash Flat',
      'Ashdown',
      'Atkins',
      'Augusta',
      'Austin',
      'Bald Knob',
      'Barling',
      'Batesville',
      'Bay',
      'Beebe',
      'Bella Vista',
      'Benton',
      'Bentonville',
      'Berryville',
      'Bethel Heights',
      'Blytheville',
      'Bono',
      'Booneville',
      'Brinkley',
      'Brookland',
      'Bryant',
      'Bull Shoals',
      'Cabot',
      'Calico Rock',
      'Caraway',
      'Carlisle',
      'Cave City',
      'Cave Springs',
      'Cedarville',
      'Centerton',
      'Charleston',
      'Cherokee Village',
      'Clarendon',
      'Clarksville',
      'Clinton',
      'Coal Hill',
      'Conway',
      'Corning',
      'Crossett',
      'Dardanelle',
      'De Queen',
      'De Witt',
      'Dermott',
      'Des Arc',
      'Diaz',
      'Dierks',
      'Dover',
      'Dumas',
      'Earle',
      'East End',
      'El Dorado',
      'Elkins',
      'Elm Springs',
      'England',
      'Eudora',
      'Eureka Springs',
      'Fairfield Bay',
      'Farmington',
      'Flippin',
      'Fordyce',
      'Forrest City',
      'Fort Smith',
      'Gassville',
      'Gentry',
      'Gibson',
      'Glenwood',
      'Gosnell',
      'Goshen',
      'Gravel Ridge',
      'Gravette',
      'Green Forest',
      'Greenbrier',
      'Greenland',
      'Greenwood',
      'Gurdon',
      'Hamburg',
      'Hampton',
      'Harrisburg',
      'Harrison',
      'Haskell',
      'Hazen',
      'Heber Springs',
      'Highland',
      'Holiday Island',
      'Hope',
      'Horatio',
      'Horseshoe Bend',
      'Hot Springs',
      'Hot Springs Village',
      'Hoxie',
      'Hughes',
      'Johnson',
      'Jonesboro',
      'Judsonia',
      'Kensett',
      'Lake City',
      'Lake Hamilton',
      'Lake Village',
      'Lamar',
      'Landmark',
      'Lavaca',
      'Leachville',
      'Lepanto',
      'Lewisville',
      'Little Flock',
      'Little Rock',
      'London',
      'Lonoke',
      'Lowell',
      'Luxora',
      'Magnolia',
      'Manila',
      'Mansfield',
      'Marianna',
      'Marked Tree',
      'Marmaduke',
      'Marshall',
      'Marvell',
      'Maumelle',
      'Mayflower',
      'McAlmont',
      'McCrory',
      'McGehee',
      'Melbourne',
      'Mena',
      'Midway',
      'Mineral Springs',
      'Monette',
      'Morrilton',
      'Mount Ida',
      'Mountain View',
      'Mountain Home',
      'Mulberry',
      'Murfreesboro',
      'Nashville',
      'Newark',
      'Newport',
      'North Crossett',
      'North Little Rock',
      'Ola',
      'Osceola',
      'Paragould',
      'Paris',
      'Parkin',
      'Pea Ridge',
      'Perryville',
      'Piggott',
      'Pine Bluff',
      'Piney',
      'Pocahontas',
      'Pottsville',
      'Prairie Creek',
      'Prairie Grove',
      'Prescott',
      'Rector',
      'Redfield',
      'Rison',
      'Rockwell',
      'Rogers',
      'Salem',
      'Searcy',
      'Shannon Hills',
      'Sheridan',
      'Siloam Springs',
      'Smackover',
      'Springdale',
      'Stamps',
      'Star City',
      'Stuttgart',
      'Sulphur Springs',
      'Texarkana',
      'Tontitown',
      'Trumann',
      'Tuckerman',
      'Van Buren',
      'Vilonia',
      'Waldo',
      'Waldron',
      'Walnut Ridge',
      'Ward',
      'Warren',
      'West Crossett',
      'West Fork',
      'West Helena',
      'West Memphis',
      'White Hall',
      'Wrightsville',
      'Wynne',
      'Yellville',
      'Adams Morgan',
      'Bloomingdale',
      'Chevy Chase',
      'Shaw',
      'Washington',
      'Bear',
      'Bellefonte',
      'Bethany Beach',
      'Blades',
      'Bridgeville',
      'Cheswold',
      'Claymont',
      'Delaware City',
      'Delmar',
      'Edgemoor',
      'Elsmere',
      'Felton',
      'Georgetown',
      'Glasgow',
      'Harrington',
      'Highland Acres',
      'Hockessin',
      'Kent Acres',
      'Laurel',
      'Lewes',
      'Long Neck',
      'Middletown',
      'Milford',
      'Millsboro',
      'Milton',
      'New Castle',
      'North Star',
      'Pike Creek',
      'Pike Creek Valley',
      'Rehoboth Beach',
      'Riverview',
      'Rodney Village',
      'Seaford',
      'Selbyville',
      'Smyrna',
      'Townsend',
      'Wilmington',
      'Wilmington Manor',
      'Wyoming',
      'Aberdeen',
      'Alachua',
      'Allapattah',
      'Altamonte Springs',
      'Alturas',
      'Alva',
      'Andover',
      'Anna Maria',
      'Apalachicola',
      'Apollo Beach',
      'Apopka',
      'Arcadia',
      'Archer',
      'Asbury Lake',
      'Astatula',
      'Astor',
      'Atlantic Beach',
      'Atlantis',
      'Auburndale',
      'Aventura',
      'Avon Park',
      'Azalea Park',
      'Babson Park',
      'Bagdad',
      'Bal Harbour',
      'Baldwin',
      'Balm',
      'Bartow',
      'Bay Harbor Islands',
      'Bay Hill',
      'Bay Pines',
      'Bayonet Point',
      'Bayshore Gardens',
      'Beacon Square',
      'Bee Ridge',
      'Belle Glade',
      'Belle Glade Camp',
      'Belle Isle',
      'Belleair',
      'Belleair Beach',
      'Belleair Bluffs',
      'Belleview',
      'Bellview',
      'Beverly Hills',
      'Big Coppitt Key',
      'Big Pine Key',
      'Biscayne Park',
      'Bithlo',
      'Black Diamond',
      'Blountstown',
      'Boca Del Mar',
      'Boca Pointe',
      'Boca Raton',
      'Bokeelia',
      'Bonifay',
      'Bonita Springs',
      'Bowling Green',
      'Boyette',
      'Boynton Beach',
      'Bradenton',
      'Bradenton Beach',
      'Brandon',
      'Bristol',
      'Broadview Park',
      'Bronson',
      'Brookridge',
      'Brooksville',
      'Broward Estates',
      'Brownsville',
      'Buckhead Ridge',
      'Buckingham',
      'Buenaventura Lakes',
      'Bunche Park',
      'Bunnell',
      'Burnt Store Marina',
      'Bushnell',
      'Butler Beach',
      'Callahan',
      'Callaway',
      'Campbell',
      'Cantonment',
      'Cape Canaveral',
      'Cape Coral',
      'Carol City',
      'Carrabelle',
      'Carrollwood',
      'Carrollwood Village',
      'Carver Ranches',
      'Casselberry',
      'Cedar Grove',
      'Celebration',
      'Center Hill',
      'Century',
      'Charlotte Harbor',
      'Charlotte Park',
      'Chattahoochee',
      'Cheval',
      'Chiefland',
      'Chipley',
      'Christmas',
      'Chuluota',
      'Citra',
      'Citrus Hills',
      'Citrus Park',
      'Citrus Ridge',
      'Citrus Springs',
      'Clarcona',
      'Clearwater',
      'Clermont',
      'Clewiston',
      'Cocoa',
      'Cocoa Beach',
      'Coconut Creek',
      'Coconut Grove',
      'Combee Settlement',
      'Cooper City',
      'Coral Gables',
      'Coral Springs',
      'Coral Terrace',
      'Cortez',
      'Country Walk',
      'Country Club',
      'Crawfordville',
      'Crescent City',
      'Crestview',
      'Crooked Lake Park',
      'Cross City',
      'Crystal Lake',
      'Crystal River',
      'Crystal Springs',
      'Cudjoe Key',
      'Cutler',
      'Cutler Ridge',
      'Cypress Gardens',
      'Cypress Lake',
      'Cypress Quarters',
      'Dade City',
      'Dania Beach',
      'Davenport',
      'Davie',
      'Daytona Beach',
      'Daytona Beach Shores',
      'DeFuniak Springs',
      'DeLand',
      'De Leon Springs',
      'DeBary',
      'Deerfield Beach',
      'Delray Beach',
      'Deltona',
      'Desoto Lakes',
      'Destin',
      'Doctor Phillips',
      'Doral',
      'Dundee',
      'Dunedin',
      'Dunnellon',
      'Eagle Lake',
      'East Lake',
      'East Milton',
      'East Naples',
      'East Palatka',
      'East Pensacola Heights',
      'East Perrine',
      'Eastpoint',
      'Eatonville',
      'Edgewater',
      'Edgewood',
      'Eglin Village',
      'Egypt Lake-Leto',
      'El Portal',
      'Elfers',
      'Ellenton',
      'Englewood',
      'Ensley',
      'Estero',
      'Eustis',
      'Fairview Shores',
      'Feather Sound',
      'Fellsmere',
      'Fern Park',
      'Fernandina Beach',
      'Ferry Pass',
      'Fish Hawk',
      'Five Points',
      'Flagami',
      'Flagler Beach',
      'Fleming Island',
      'Floral City',
      'Florida City',
      'Florida Ridge',
      'Forest City',
      'Fort Lauderdale',
      'Fort Meade',
      'Fort Myers',
      'Fort Myers Beach',
      'Fort Myers Shores',
      'Fort Pierce',
      'Fort Walton Beach',
      'Fountainebleau',
      'Freeport',
      'Frostproof',
      'Fruit Cove',
      'Fruitland Park',
      'Fruitville',
      'Fuller Heights',
      'Fussels Corner',
      'Gainesville',
      'Gandy',
      'Gateway',
      'Gibsonia',
      'Gibsonton',
      'Gifford',
      'Gladeview',
      'Glenvar Heights',
      'Golden Gate',
      'Golden Glades',
      'Goldenrod',
      'Gonzalez',
      'Gotha',
      'Goulding',
      'Goulds',
      'Graceville',
      'Green Cove Springs',
      'Greenacres City',
      'Gretna',
      'Grove City',
      'Groveland',
      'Gulf Breeze',
      'Gulf Gate Estates',
      'Gulfport',
      'Haines City',
      'Hallandale Beach',
      'Harbor Bluffs',
      'Harbour Heights',
      'Harlem',
      'Harlem Heights',
      'Havana',
      'Haverhill',
      'Hawthorne',
      'Heathrow',
      'Hernando',
      'Hernando Beach',
      'Hialeah',
      'Hialeah Gardens',
      'High Springs',
      'Highland Beach',
      'Highland City',
      'Hiland Park',
      "Hill 'n Dale",
      'Hilliard',
      'Hillsboro Beach',
      'Hobe Sound',
      'Holden Heights',
      'Holiday',
      'Holley',
      'Holly Hill',
      'Hollywood',
      'Holmes Beach',
      'Homestead',
      'Homosassa',
      'Homosassa Springs',
      'Howey-in-the-Hills',
      'Hudson',
      'Hunters Creek',
      'Hypoluxo',
      'Immokalee',
      'Indialantic',
      'Indian Harbour Beach',
      'Indian River Estates',
      'Indian River Shores',
      'Indian Rocks Beach',
      'Indian Shores',
      'Indiantown',
      'Inglis',
      'Interlachen',
      'Inwood',
      'Iona',
      'Islamorada',
      'Isle of Normandy',
      'Ives Estates',
      'Jacksonville Beach',
      'Jan-Phyl Village',
      'Jasmine Estates',
      'Jensen Beach',
      'June Park',
      'Juno Beach',
      'Jupiter',
      'Kathleen',
      'Kendale Lakes',
      'Kendall',
      'Kendall Green',
      'Kenneth City',
      'Kensington Park',
      'Key Biscayne',
      'Key Largo',
      'Key West',
      'Keystone',
      'Keystone Heights',
      'Kings Point',
      'Kissimmee',
      'LaBelle',
      'Lacoochee',
      'Lady Lake',
      'Laguna Beach',
      'Lake Alfred',
      'Lake Belvedere Estates',
      'Lake Butler',
      'Lake Clarke Shores',
      'Lake Forest',
      'Lake Helen',
      'Lake Lorraine',
      'Lake Lucerne',
      'Lake Magdalene',
      'Lake Panasoffkee',
      'Lake Park',
      'Lake Placid',
      'Lake Sarasota',
      'Lake Mary',
      'Lake Wales',
      'Lake Worth',
      'Lake Worth Corridor',
      'Lakeland',
      'Lakeland Highlands',
      'Lakes by the Bay',
      'Lakeside',
      'Lakewood Park',
      "Land O' Lakes",
      'Lantana',
      'Largo',
      'Lauderdale Lakes',
      'Lauderdale-by-the-Sea',
      'Lauderhill',
      'Lealman',
      'Lecanto',
      'Lehigh Acres',
      'Leisure City',
      'Lely',
      'Lely Resort',
      'Lighthouse Point',
      'Limestone Creek',
      'Live Oak',
      'Lochmoor Waterway Estates',
      'Lockhart',
      'Longboat Key',
      'Longwood',
      'Loughman',
      'Lower Grand Lagoon',
      'Loxahatchee Groves',
      'Lutz',
      'Lynn Haven',
      'Macclenny',
      'Madeira Beach',
      'Maitland',
      'Malabar',
      'Malone',
      'Manasota Key',
      'Manatee Road',
      'Mango',
      'Mangonia Park',
      'Marathon',
      'Marco',
      'Marco Island',
      'Margate',
      'Mary Esther',
      'Masaryktown',
      'Mascotte',
      'Mayo',
      'McGregor',
      'Meadow Woods',
      'Medulla',
      'Melbourne Beach',
      'Melrose Park',
      'Memphis',
      'Merritt Island',
      'Mexico Beach',
      'Miami',
      'Miami Beach',
      'Miami Gardens',
      'Miami Lakes',
      'Miami Shores',
      'Miami Springs',
      'Micco',
      'Middleburg',
      'Mims',
      'Minneola',
      'Miramar',
      'Miramar Beach',
      'Molino',
      'Montverde',
      'Moore Haven',
      'Mount Dora',
      'Mount Plymouth',
      'Myrtle Grove',
      'Naples',
      'Naples Manor',
      'Naples Park',
      'Naranja',
      'Navarre',
      'Neptune Beach',
      'New Port Richey',
      'New Smyrna Beach',
      'Newberry',
      'Niceville',
      'Nocatee',
      'Nokomis',
      'Norland',
      'North Andrews Gardens',
      'North Bay Village',
      'North Brooksville',
      'North DeLand',
      'North Fort Myers',
      'North Key Largo',
      'North Lauderdale',
      'North Miami',
      'North Miami Beach',
      'North Palm Beach',
      'North Port',
      'North Redington Beach',
      'North River Shores',
      'North Sarasota',
      'Oak Hill',
      'Oak Ridge',
      'Oakland',
      'Oakland Park',
      'Ocala',
      'Ocean City',
      'Ocean Ridge',
      'Ocoee',
      'Odessa',
      'Ojus',
      'Okeechobee',
      'Oldsmar',
      'Olga',
      'Olympia Heights',
      'Opa-locka',
      'Orange City',
      'Orange Park',
      'Orangetree',
      'Oriole Beach',
      'Orlando',
      'Orlovista',
      'Ormond Beach',
      'Ormond-by-the-Sea',
      'Osprey',
      'Oviedo',
      'Pace',
      'Pahokee',
      'Palatka',
      'Palm Aire',
      'Palm Bay',
      'Palm Beach',
      'Palm Beach Gardens',
      'Palm Beach Shores',
      'Palm City',
      'Palm Coast',
      'Palm Harbor',
      'Palm Springs',
      'Palm Springs North',
      'Palm Valley',
      'Palmetto',
      'Palmetto Bay',
      'Palmetto Estates',
      'Palmona Park',
      'Panama City',
      'Panama City Beach',
      'Paradise Heights',
      'Parker',
      'Parkland',
      'Pebble Creek',
      'Pelican Bay',
      'Pembroke Park',
      'Pembroke Pines',
      'Pensacola',
      'Perry',
      'Pierson',
      'Pine Castle',
      'Pine Hills',
      'Pine Island Center',
      'Pine Island Ridge',
      'Pine Manor',
      'Pine Ridge',
      'Pinecrest',
      'Pinellas Park',
      'Pinewood',
      'Placid Lakes',
      'Plant City',
      'Plantation',
      'Plantation Mobile Home Park',
      'Poinciana',
      'Point Baker',
      'Polk City',
      'Pompano Beach',
      'Pompano Beach Highlands',
      'Ponce Inlet',
      'Ponte Vedra Beach',
      'Port Charlotte',
      'Port LaBelle',
      'Port Orange',
      'Port Richey',
      'Port Saint Joe',
      'Port Saint John',
      'Port Saint Lucie',
      'Port Salerno',
      'Pretty Bayou',
      'Princeton',
      'Progress Village',
      'Punta Gorda',
      'Punta Gorda Isles',
      'Punta Rassa',
      'Quincy',
      'Redington Beach',
      'Redington Shores',
      'Richmond Heights',
      'Richmond West',
      'Ridge Manor',
      'Ridge Wood Heights',
      'Ridgecrest',
      'River Park',
      'Riviera Beach',
      'Rock Island',
      'Rockledge',
      'Roseland',
      'Rotonda West',
      'Royal Palm Beach',
      'Royal Palm Estates',
      'Ruskin',
      'Safety Harbor',
      'Saint Augustine',
      'Saint Augustine Beach',
      'Saint Augustine Shores',
      'Saint Cloud',
      'Saint George',
      'Saint James City',
      'Saint Leo',
      'Saint Pete Beach',
      'St. Petersburg',
      'Samoset',
      'San Antonio',
      'San Carlos Park',
      'Sanibel',
      'Sanford',
      'Sarasota',
      'Sarasota Springs',
      'Satellite Beach',
      'Sawgrass',
      'Schall Circle',
      'Scott Lake',
      'Seaside',
      'Sebastian',
      'Sebring',
      'Seffner',
      'Seminole',
      'Seminole Manor',
      "Sewall's Point",
      'Shady Hills',
      'Sharpes',
      'Siesta Key',
      'Silver Lake',
      'Silver Springs',
      'Silver Springs Shores',
      'Sky Lake',
      'Sneads',
      'South Bay',
      'South Beach',
      'South Bradenton',
      'South Apopka',
      'South Brooksville',
      'South Daytona',
      'South Gate Ridge',
      'South Highpoint',
      'South Miami',
      'South Miami Heights',
      'South Palm Beach',
      'South Pasadena',
      'South Patrick Shores',
      'South Sarasota',
      'South Venice',
      'Southchase',
      'Southgate',
      'Southwest Ranches',
      'Spring Hill',
      'Springfield',
      'Starke',
      'Steinhatchee',
      'Stock Island',
      'Stuart',
      'Sugarmill Woods',
      'Sun City Center',
      'Suncoast Estates',
      'Sunny Isles Beach',
      'Sunrise',
      'Sunset',
      'Sunshine Ranches',
      'Surfside',
      'Sweetwater',
      'Taft',
      'Tallahassee',
      'Tamarac',
      'Tamiami',
      'Tampa',
      'Tangelo Park',
      'Tangerine',
      'Tarpon Springs',
      'Tavares',
      'Tavernier',
      'Taylor Creek',
      'Tedder',
      'Temple Terrace',
      'Tequesta',
      'Terra Mar',
      'The Crossings',
      'The Hammocks',
      'The Meadows',
      'The Villages',
      'Thonotosassa',
      'Three Lakes',
      'Three Oaks',
      'Tice',
      'Tierra Verde',
      'Tiger Point',
      'Timber Pines',
      'Titusville',
      "Town 'n' Country",
      'Treasure Island',
      'Trenton',
      'Twin Lakes',
      'Umatilla',
      'Union Park',
      'University Park',
      'Upper Grand Lagoon',
      'Valparaiso',
      'Valrico',
      'Vamo',
      'Venice',
      'Venice Gardens',
      'Vero Beach',
      'Villages of Oriole',
      'Villano Beach',
      'Vineyards',
      'Virginia Gardens',
      'Wabasso Beach',
      'Wahneta',
      'Wallace',
      'Warm Mineral Springs',
      'Warrington',
      'Washington Park',
      'Watertown',
      'Wauchula',
      'Wedgefield',
      'Weeki Wachee Gardens',
      'Wekiwa Springs',
      'Wellborn',
      'Wellington',
      'Wesley Chapel',
      'West Bradenton',
      'Westchase',
      'West DeLand',
      'West Gate',
      'West Hollywood',
      'West Little River',
      'West Melbourne',
      'West Miami',
      'West Palm Beach',
      'West Park',
      'West Pensacola',
      'West Perrine',
      'West Samoset',
      'West Vero Corridor',
      'West and East Lealman',
      'Westchester',
      'Weston',
      'Westview',
      'Westwood Lake',
      'Wewahitchka',
      'Whiskey Creek',
      'White City',
      'Whitfield',
      'Wildwood',
      'Williamsburg',
      'Williston',
      'Williston Highlands',
      'Willow Oak',
      'Wilton Manors',
      'Wimauma',
      'Windermere',
      'Winston',
      'Winter Beach',
      'Winter Garden',
      'Winter Haven',
      'Winter Park',
      'Winter Springs',
      'Woodlawn Beach',
      'Woodville',
      'Wright',
      'Yalaha',
      'Youngstown',
      'Yulee',
      'Zellwood',
      'Zephyrhills',
      'Zolfo Springs',
      'Abbeville',
      'Acworth',
      'Adairsville',
      'Adel',
      'Alamo',
      'Albany',
      'Alpharetta',
      'Alto',
      'Americus',
      'Appling',
      'Aragon',
      'Arcade',
      'Arlington',
      'Ashburn',
      'Athens',
      'Atlanta',
      'Auburn',
      'Austell',
      'Avondale Estates',
      'Bainbridge',
      'Ball Ground',
      'Barnesville',
      'Baxley',
      'Belvedere Park',
      'Berkeley Lake',
      'Blackshear',
      'Blairsville',
      'Blakely',
      'Bogart',
      'Bonanza',
      'Boston',
      'Bowdon',
      'Braselton',
      'Bremen',
      'Brookhaven',
      'Brooklet',
      'Broxton',
      'Brunswick',
      'Buchanan',
      'Buena Vista',
      'Buford',
      'Byron',
      'Cairo',
      'Calhoun',
      'Camilla',
      'Canton',
      'Carnesville',
      'Cartersville',
      'Cave Spring',
      'Cedartown',
      'Centerville',
      'Chamblee',
      'Chatsworth',
      'Chattanooga Valley',
      'Chester',
      'Chickamauga',
      'Clarkesville',
      'Clarkston',
      'Claxton',
      'Cochran',
      'College Park',
      'Colquitt',
      'Columbus',
      'Comer',
      'Commerce',
      'Conley',
      'Conyers',
      'Cordele',
      'Cornelia',
      'Country Club Estates',
      'Covington',
      'Cumming',
      'Cusseta',
      'Cuthbert',
      'Dacula',
      'Dahlonega',
      'Dalton',
      'Dallas',
      'Danielsville',
      'Darien',
      'Davisboro',
      'Dawson',
      'Dawsonville',
      'Deenwood',
      'Demorest',
      'Dock Junction',
      'Donalsonville',
      'Doraville',
      'Douglas',
      'Douglasville',
      'Druid Hills',
      'Dublin',
      'Duluth',
      'Dunwoody',
      'East Dublin',
      'East Griffin',
      'East Point',
      'East Newnan',
      'Eastman',
      'Eatonton',
      'Edison',
      'Elberton',
      'Ellaville',
      'Ellijay',
      'Emerson',
      'Enigma',
      'Euharlee',
      'Evans',
      'Experiment',
      'Fair Oaks',
      'Fairburn',
      'Fairview',
      'Fitzgerald',
      'Flowery Branch',
      'Folkston',
      'Forest Park',
      'Forsyth',
      'Fort Gaines',
      'Fort Oglethorpe',
      'Fort Valley',
      'Franklin',
      'Franklin Springs',
      'Garden City',
      'Glennville',
      'Gordon',
      'Grantville',
      'Gray',
      'Grayson',
      'Gresham Park',
      'Griffin',
      'Grovetown',
      'Gumlog',
      'Guyton',
      'Hahira',
      'Hannahs Mill',
      'Hapeville',
      'Hardwick',
      'Hartwell',
      'Hawkinsville',
      'Hazlehurst',
      'Hephzibah',
      'Hiawassee',
      'Hinesville',
      'Hiram',
      'Hogansville',
      'Holly Springs',
      'Homer',
      'Homerville',
      'Hoschton',
      'Indian Springs',
      'Irwinton',
      'Isle of Hope',
      'Jefferson',
      'Jeffersonville',
      'Jesup',
      'Kennesaw',
      'Kingsland',
      'Knoxville',
      'LaGrange',
      'LaFayette',
      'Lakeview',
      'Lakeview Estates',
      'Lavonia',
      'Lawrenceville',
      'Lexington',
      'Lilburn',
      'Lincolnton',
      'Lindale',
      'Lithia Springs',
      'Lithonia',
      'Locust Grove',
      'Loganville',
      'Lookout Mountain',
      'Louisville',
      'Lovejoy',
      'Ludowici',
      'Lula',
      'Lumber City',
      'Lumpkin',
      'Lyons',
      'Mableton',
      'Macon',
      'Manchester',
      'Marietta',
      'Marshallville',
      'Martinez',
      'Maysville',
      'McCaysville',
      'McDonough',
      'McRae',
      'Meigs',
      'Metter',
      'Milledgeville',
      'Millen',
      'Monroe',
      'Montezuma',
      'Morgan',
      'Morrow',
      'Mount Airy',
      'Moultrie',
      'Mount Zion',
      'Mountain City',
      'Mountain Park',
      'Nahunta',
      'Nelson',
      'Newnan',
      'Nicholls',
      'Nicholson',
      'Norcross',
      'North Atlanta',
      'North Decatur',
      'North Druid Hills',
      'Oakwood',
      'Ocilla',
      'Oglethorpe',
      'Omega',
      'Panthersville',
      'Peachtree City',
      'Peachtree Corners',
      'Pearson',
      'Pembroke',
      'Pine Mountain',
      'Pooler',
      'Port Wentworth',
      'Porterdale',
      'Powder Springs',
      'Preston',
      'Putney',
      'Quitman',
      'Raoul',
      'Ray City',
      'Redan',
      'Reed Creek',
      'Reidsville',
      'Remerton',
      'Reynolds',
      'Richland',
      'Richmond Hill',
      'Rincon',
      'Ringgold',
      'Riverdale',
      'Rochelle',
      'Rockmart',
      'Rome',
      'Roswell',
      'Rossville',
      'Royston',
      'Russell',
      'Rydal',
      'St. Marys',
      'Saint Simon Mills',
      'Saint Simons Island',
      'Sandersville',
      'Sandy Springs',
      'Sardis',
      'Savannah',
      'Scottdale',
      'Senoia',
      'Shannon',
      'Skidaway Island',
      'Snellville',
      'Social Circle',
      'Soperton',
      'Sparks',
      'Sparta',
      'Statenville',
      'Statesboro',
      'Statham',
      'Stockbridge',
      'Stone Mountain',
      'Sugar Hill',
      'Summerville',
      'Sunnyside',
      'Suwanee',
      'Swainsboro',
      'Sylvester',
      'Talbotton',
      'Tallapoosa',
      'Temple',
      'Tennille',
      'Thomaston',
      'Thomson',
      'Thunderbolt',
      'Tifton',
      'Toccoa',
      'Trion',
      'Tucker',
      'Twin City',
      'Tybee Island',
      'Tyrone',
      'Unadilla',
      'Union City',
      'Union Point',
      'Unionville',
      'Valdosta',
      'Varnell',
      'Vidalia',
      'Vienna',
      'Villa Rica',
      'Vinings',
      'Wadley',
      'Walnut Grove',
      'Walthourville',
      'Warner Robins',
      'Warrenton',
      'Watkinsville',
      'Waycross',
      'Waynesboro',
      'West Point',
      'Whitemarsh Island',
      'Willacoochee',
      'Wilmington Island',
      'Winder',
      'Winterville',
      'Woodbine',
      'Wrens',
      'Young Harris',
      'Zebulon',
      'Albers',
      'Albion',
      'Alorton',
      'Altamont',
      'Alton',
      'Anna',
      'Arcola',
      'Arthur',
      'Ashland',
      'Assumption',
      'Atwood',
      'Aviston',
      'Barry',
      'Beckemeyer',
      'Bement',
      'Benld',
      'Bethalto',
      'Bethany',
      'Belleville',
      'Blue Mound',
      'Breese',
      'Bunker Hill',
      'Cahokia',
      'Cambria',
      'Carbondale',
      'Carlinville',
      'Carlyle',
      'Carmi',
      'Carrier Mills',
      'Carterville',
      'Casey',
      'Caseyville',
      'Central City',
      'Centralia',
      'Cerro Gordo',
      'Chatham',
      'Chrisman',
      'Christopher',
      'Cobden',
      'Columbia',
      'Crainville',
      'De Soto',
      'Divernon',
      'Du Quoin',
      'Dupo',
      'East Alton',
      'East Saint Louis',
      'Edinburg',
      'Edwardsville',
      'Effingham',
      'Eldorado',
      'Elizabethtown',
      'Energy',
      'Fairmont City',
      'Fairview Heights',
      'Flora',
      'Freeburg',
      'Germantown',
      'Gillespie',
      'Glen Carbon',
      'Girard',
      'Godfrey',
      'Golconda',
      'Goreville',
      'Grandview',
      'Granite City',
      'Grayville',
      'Greenfield',
      'Greenup',
      'Griggsville',
      'Hardin',
      'Harristown',
      'Herrin',
      'Hillsboro',
      'Holiday Shores',
      'Ina',
      'Jerome',
      'Jerseyville',
      'Johnston City',
      'Kincaid',
      'Lebanon',
      'Leland Grove',
      'Litchfield',
      'Long Creek',
      'Lovington',
      'Marissa',
      'Martinsville',
      'Maryville',
      'Mascoutah',
      'Mattoon',
      'McLeansboro',
      'Meredosia',
      'Metropolis',
      'Millstadt',
      'Mitchell',
      'Morrisonville',
      'Mound City',
      'Mount Carmel',
      'Mount Sterling',
      'Moweaqua',
      'Murphysboro',
      'Neoga',
      'New Athens',
      'New Baden',
      'New Berlin',
      'Norris City',
      "O'Fallon",
      'Oblong',
      'Odin',
      'Okawville',
      'Olney',
      'Palestine',
      'Pana',
      'Pawnee',
      'Payson',
      'Pinckneyville',
      'Pittsfield',
      'Pontoon Beach',
      'Ramsey',
      'Red Bud',
      'Riverton',
      'Robinson',
      'Rochester',
      'Roodhouse',
      'Rosewood Heights',
      'Rosiclare',
      'Roxana',
      'Royalton',
      'Saint Elmo',
      'Saint Jacob',
      'Sandoval',
      'Sesser',
      'Shawneetown',
      'Shelbyville',
      'Sherman',
      'Shiloh',
      'Smithton',
      'South Jacksonville',
      'South Roxana',
      'Southern View',
      'Staunton',
      'Steeleville',
      'Sullivan',
      'Sumner',
      'Swansea',
      'Taylorville',
      'Teutopolis',
      'Toledo',
      'Tolono',
      'Tuscola',
      'Upper Alton',
      'Valmeyer',
      'Vandalia',
      'Villa Grove',
      'Virden',
      'Virginia',
      'Wamac',
      'Warrensburg',
      'Waterloo',
      'Wayne City',
      'Waverly',
      'West Frankfort',
      'Williamsville',
      'Winchester',
      'Windsor',
      'Wood River',
      'Worden',
      'Zeigler',
      'Avon',
      'Bargersville',
      'Bedford',
      'Beech Grove',
      'Bicknell',
      'Bloomfield',
      'Bloomington',
      'Boonville',
      'Brazil',
      'Bright',
      'Broad Ripple',
      'Brooklyn',
      'Brookville',
      'Brownsburg',
      'Brownstown',
      'Cambridge City',
      'Cannelton',
      'Carmel',
      'Cayuga',
      'Chandler',
      'Charlestown',
      'Cloverdale',
      'Connersville',
      'Corydon',
      'Country Squire Lakes',
      'Crothersville',
      'Cumberland',
      'Dale',
      'Darmstadt',
      'Dillsboro',
      'Edinburgh',
      'Ellettsville',
      'English',
      'Evansville',
      'Fairview Park',
      'Farmersburg',
      'Ferdinand',
      'Fishers',
      'Fort Branch',
      'Fortville',
      'French Lick',
      'Galena',
      'Greencastle',
      'Greendale',
      'Greensburg',
      'Hagerstown',
      'Hanover',
      'Haubstadt',
      'Henryville',
      'Hidden Valley',
      'Huntingburg',
      'Indianapolis',
      'Ingalls',
      'Jasonville',
      'Knightstown',
      'Lawrence',
      'Lawrenceburg',
      'Liberty',
      'Linton',
      'Loogootee',
      'McCordsville',
      'Melody Hill',
      'Meridian Hills',
      'Middlebury',
      'Milan',
      'Monrovia',
      'Mooresville',
      'Morristown',
      'New Albany',
      'New Palestine',
      'New Whiteland',
      'Newburgh',
      'North Madison',
      'North Terre Haute',
      'North Vernon',
      'Oak Park',
      'Oakland City',
      'Odon',
      'Oolitic',
      'Orleans',
      'Osgood',
      'Owensville',
      'Paoli',
      'Pendleton',
      'Petersburg',
      'Pittsboro',
      'Plainfield',
      'Poseyville',
      'Princes Lakes',
      'Richmond',
      'Rising Sun',
      'Rockport',
      'Rockville',
      'Rushville',
      'Saint Paul',
      'Santa Claus',
      'Scottsburg',
      'Seelyville',
      'Sellersburg',
      'Seymour',
      'Shelburn',
      'Shoals',
      'Southport',
      'Speedway',
      'Spencer',
      'Sunman',
      'Tell City',
      'Terre Haute',
      'Trafalgar',
      'Versailles',
      'Vevay',
      'Vincennes',
      'Warren Park',
      'West Terre Haute',
      'Westport',
      'Whiteland',
      'Whitestown',
      'Worthington',
      'Zionsville',
      'Abilene',
      'Anthony',
      'Arma',
      'Atchison',
      'Baldwin City',
      'Basehor',
      'Baxter Springs',
      'Bellaire',
      'Belle Plaine',
      'Beloit',
      'Bonner Springs',
      'Buhler',
      'Burlington',
      'Caldwell',
      'Caney',
      'Chanute',
      'Chapman',
      'Cheney',
      'Cherryvale',
      'Chetopa',
      'Clay Center',
      'Coffeyville',
      'Coldwater',
      'Colwich',
      'Concordia',
      'Conway Springs',
      'Cottonwood Falls',
      'Council Grove',
      'Derby',
      'Douglass',
      'Edgerton',
      'Ellinwood',
      'Ellis',
      'Ellsworth',
      'Elwood',
      'Emporia',
      'Erie',
      'Eureka',
      'Fairway',
      'Fort Scott',
      'Fredonia',
      'Frontenac',
      'Gardner',
      'Garnett',
      'Goddard',
      'Grandview Plaza',
      'Great Bend',
      'Halstead',
      'Harper',
      'Haven',
      'Hays',
      'Haysville',
      'Herington',
      'Hesston',
      'Hiawatha',
      'Hill City',
      'Hoisington',
      'Holton',
      'Howard',
      'Humboldt',
      'Hutchinson',
      'Independence',
      'Inman',
      'Iola',
      'Jetmore',
      'Junction City',
      'Kansas City',
      'Kechi',
      'Kingman',
      'Kinsley',
      'Kiowa',
      'La Crosse',
      'La Cygne',
      'Lansing',
      'Larned',
      'Leavenworth',
      'Leawood',
      'Lenexa',
      'Lindsborg',
      'Louisburg',
      'Lyndon',
      'Maize',
      'Manhattan',
      'Mankato',
      'Marysville',
      'McPherson',
      'Medicine Lodge',
      'Merriam',
      'Minneapolis',
      'Mission',
      'Mission Hills',
      'Moundridge',
      'Mulvane',
      'Neodesha',
      'Ness City',
      'Nickerson',
      'North Newton',
      'Norton',
      'Ogden',
      'Olathe',
      'Osage City',
      'Osawatomie',
      'Osborne',
      'Oskaloosa',
      'Oswego',
      'Ottawa',
      'Overbrook',
      'Overland Park',
      'Paola',
      'Park City',
      'Parsons',
      'Peabody',
      'Phillipsburg',
      'Pittsburg',
      'Plainville',
      'Pleasanton',
      'Prairie Village',
      'Pratt',
      'Roeland Park',
      'Rose Hill',
      'Sabetha',
      'Saint John',
      'Saint Marys',
      'Salina',
      'Sedan',
      'Sedgwick',
      'Seneca',
      'Shawnee',
      'Smith Center',
      'Solomon',
      'South Hutchinson',
      'Sterling',
      'Tonganoxie',
      'Towanda',
      'Topeka',
      'Valley Center',
      'Valley Falls',
      'Victoria',
      'WaKeeney',
      'Wamego',
      'Wathena',
      'Wellsville',
      'Westmoreland',
      'Westwood',
      'Wichita',
      'Yates Center',
      'Alexandria',
      'Anchorage',
      'Annville',
      'Audubon Park',
      'Barbourmeade',
      'Barbourville',
      'Bardstown',
      'Bardwell',
      'Beattyville',
      'Beaver Dam',
      'Beechwood Village',
      'Bellevue',
      'Berea',
      'Brandenburg',
      'Breckinridge Center',
      'Brodhead',
      'Brooks',
      'Buckner',
      'Buechel',
      'Burkesville',
      'Cadiz',
      'Calvert City',
      'Camargo',
      'Campbellsville',
      'Campton',
      'Catlettsburg',
      'Claryville',
      'Clay City',
      'Cloverport',
      'Coal Run Village',
      'Cold Spring',
      'Coldstream',
      'Corbin',
      'Crescent Springs',
      'Crestview Hills',
      'Crestwood',
      'Crittenden',
      'Cynthiana',
      'Dawson Springs',
      'Dayton',
      'Dixon',
      'Douglass Hills',
      'Dry Ridge',
      'Earlington',
      'Eddyville',
      'Edmonton',
      'Elk Creek',
      'Elkfork',
      'Elkton',
      'Eminence',
      'Erlanger',
      'Fairdale',
      'Falmouth',
      'Fern Creek',
      'Flatwoods',
      'Flemingsburg',
      'Fort Mitchell',
      'Fort Thomas',
      'Fort Wright',
      'Francisville',
      'Frankfort',
      'Frenchburg',
      'Fulton',
      'Graymoor-Devondale',
      'Guthrie',
      'Hardinsburg',
      'Harlan',
      'Harrodsburg',
      'Hawesville',
      'Hazard',
      'Hebron',
      'Hebron Estates',
      'Hendron',
      'Hickman',
      'Highland Heights',
      'Highview',
      'Hillview',
      'Hindman',
      'Hodgenville',
      'Hopkinsville',
      'Horse Cave',
      'Hurstbourne Acres',
      'Hurstbourne',
      'Hyden',
      'Indian Hills',
      'Indian Hills Cherokee Section',
      'Inez',
      'Ironville',
      'Irvine',
      'Irvington',
      'Jamestown',
      'Jeffersontown',
      'Jenkins',
      'Knottsville',
      'La Center',
      'La Grange',
      'Lakeside Park',
      'Lancaster',
      'Lebanon Junction',
      'Ledbetter',
      'Leitchfield',
      'Lewisport',
      'Lexington-Fayette',
      'Livermore',
      'Louisa',
      'Ludlow',
      'Madisonville',
      'Masonville',
      'Massac',
      'Mayfield',
      'McKee',
      'Meads',
      'Middlesboro',
      'Heritage Creek',
      'Morehead',
      'Morganfield',
      'Morgantown',
      'Mount Olivet',
      'Mount Washington',
      'Munfordville',
      'Murray',
      'Newburg',
      'Nicholasville',
      'North Corbin',
      'Northfield',
      'Nortonville',
      'Oak Grove',
      'Oakbrook',
      'Okolona',
      'Olive Hill',
      'Orchard Grass Hills',
      'Owensboro',
      'Owenton',
      'Owingsville',
      'Paintsville',
      'Park Hills',
      'Pewee Valley',
      'Pikeville',
      'Pine Knot',
      'Pineville',
      'Pioneer Village',
      'Plano',
      'Pleasure Ridge Park',
      'Prestonsburg',
      'Prospect',
      'Providence',
      'Raceland',
      'Radcliff',
      'Reidland',
      'Russell Springs',
      'Ryland Heights',
      'Saint Matthews',
      'Saint Regis Park',
      'Salyersville',
      'Sandy Hook',
      'Scottsville',
      'Sebree',
      'Shepherdsville',
      'Shively',
      'Silver Grove',
      'Simpsonville',
      'Smithland',
      'Somerset',
      'South Shore',
      'Saint Dennis',
      'Stanford',
      'Stanton',
      'Stearns',
      'Sturgis',
      'Taylor Mill',
      'Taylorsville',
      'Tompkinsville',
      'Union',
      'Van Lear',
      'Valley Station',
      'Vanceburg',
      'Verona',
      'Villa Hills',
      'Vine Grove',
      'Walton',
      'Warsaw',
      'Watterson Park',
      'West Buechel',
      'West Liberty',
      'Whitesburg',
      'Whitley City',
      'Wickliffe',
      'Wilder',
      'Williamstown',
      'Wilmore',
      'Windy Hills',
      'Worthington Hills',
      'Wurtland',
      'Abita Springs',
      'Addis',
      'Ama',
      'Amelia',
      'Amite',
      'Arabi',
      'Arnaudville',
      'Avondale',
      'Baker',
      'Ball',
      'Banks Springs',
      'Barataria',
      'Basile',
      'Bastrop',
      'Batchelor',
      'Baton Rouge',
      'Bawcomville',
      'Bayou Cane',
      'Bayou Gauche',
      'Bayou Vista',
      'Belle Chasse',
      'Belle Rose',
      'Bernice',
      'Berwick',
      'Blanchard',
      'Bogalusa',
      'Bossier City',
      'Bourg',
      'Boutte',
      'Breaux Bridge',
      'Bridge City',
      'Broussard',
      'Brownsfield',
      'Brusly',
      'Bunkie',
      'Cade',
      'Cameron',
      'Campti',
      'Carencro',
      'Carlyss',
      'Carville',
      'Catahoula',
      'Cecilia',
      'Central',
      'Chackbay',
      'Chalmette',
      'Charenton',
      'Chauvin',
      'Church Point',
      'Claiborne',
      'Clarks',
      'Colfax',
      'Convent',
      'Cottonport',
      'Coushatta',
      'Crowley',
      'Cullen',
      'Cut Off',
      'DeQuincy',
      'DeRidder',
      'Delcambre',
      'Delhi',
      'Denham Springs',
      'Des Allemands',
      'Destrehan',
      'Deville',
      'Donaldsonville',
      'Dulac',
      'Duson',
      'Eastwood',
      'Eden Isle',
      'Edgard',
      'Elmwood',
      'Elton',
      'Erath',
      'Erwinville',
      'Estelle',
      'Eunice',
      'Farmerville',
      'Ferriday',
      'Franklinton',
      'French Settlement',
      'Galliano',
      'Gardere',
      'Garyville',
      'Glenmora',
      'Golden Meadow',
      'Gonzales',
      'Grambling',
      'Gramercy',
      'Grand Isle',
      'Grand Point',
      'Gueydan',
      'Hackberry',
      'Hahnville',
      'Hammond',
      'Harahan',
      'Harrisonburg',
      'Harvey',
      'Haughton',
      'Haynesville',
      'Houma',
      'Inniswold',
      'Iota',
      'Iowa',
      'Jean Lafitte',
      'Jeanerette',
      'Jena',
      'Jennings',
      'Jonesville',
      'Kaplan',
      'Kenner',
      'Kentwood',
      'Killian',
      'Kinder',
      'Krotz Springs',
      'Labadieville',
      'Lacombe',
      'Lake Arthur',
      'Lake Charles',
      'Lake Providence',
      'Lakeshore',
      'Laplace',
      'Larose',
      'Lawtell',
      'Lecompte',
      'Leesville',
      'Leonville',
      'Livonia',
      'Lockport',
      'Lockport Heights',
      'Logansport',
      'Luling',
      'Lutcher',
      'Mamou',
      'Mandeville',
      'Mansura',
      'Many',
      'Maringouin',
      'Marksville',
      'Marrero',
      'Mathews',
      'Maurice',
      'Melville',
      'Meraux',
      'Merrydale',
      'Merryville',
      'Metairie',
      'Metairie Terrace',
      'Minden',
      'Minorca',
      'Montegut',
      'Montz',
      'Morgan City',
      'Moss Bluff',
      'Napoleonville',
      'Natalbany',
      'Natchitoches',
      'New Iberia',
      'New Llano',
      'New Orleans',
      'New Roads',
      'New Sarpy',
      'Newellton',
      'Norco',
      'North Vacherie',
      'Oak Hills Place',
      'Oakdale',
      'Oberlin',
      'Old Jefferson',
      'Olla',
      'Opelousas',
      'Ossun',
      'Paradis',
      'Patterson',
      'Paulina',
      'Pearl River',
      'Pierre Part',
      'Pine Prairie',
      'Plaquemine',
      'Ponchatoula',
      'Port Allen',
      'Port Barre',
      'Port Sulphur',
      'Poydras',
      'Prairieville',
      'Presquille',
      'Prien',
      'Rayne',
      'Rayville',
      'Reserve',
      'Richwood',
      'River Ridge',
      'Rosepine',
      'Ruston',
      'Saint Francisville',
      'Saint Gabriel',
      'Saint Joseph',
      'Saint Martinville',
      'Saint Rose',
      'Schriever',
      'Scott',
      'Shenandoah',
      'Shreveport',
      'Sibley',
      'Simmesport',
      'Slidell',
      'Sorrento',
      'South Vacherie',
      'Springhill',
      'Sterlington',
      'Stonewall',
      'Sulphur',
      'Supreme',
      'Swartz',
      'Tallulah',
      'Terrytown',
      'Thibodaux',
      'Timberlane',
      'Urania',
      'Vienna Bend',
      'Village Saint George',
      'Ville Platte',
      'Vinton',
      'Violet',
      'Vivian',
      'Waggaman',
      'Walker',
      'Watson',
      'Welsh',
      'West Ferriday',
      'West Monroe',
      'Westlake',
      'Westwego',
      'Westminster',
      'White Castle',
      'Winnfield',
      'Winnsboro',
      'Woodmere',
      'Woodworth',
      'Zachary',
      'Youngsville',
      'Zwolle',
      'Accokeek',
      'Adamstown',
      'Adelphi',
      'Algonquin',
      'Annapolis',
      'Arbutus',
      'Arden on the Severn',
      'Arnold',
      'Aspen Hill',
      'Baden',
      'Baltimore',
      'Baltimore Highlands',
      'Ballenger Creek',
      'Bartonsville',
      'Bel Air',
      'Beltsville',
      'Bennsville',
      'Berlin',
      'Berwyn Heights',
      'Bethesda',
      'Bladensburg',
      'Boonsboro',
      'Bowie',
      'Bowleys Quarters',
      'Braddock Heights',
      'Brandywine',
      'Brentwood',
      'Brock Hall',
      'Brooklyn Park',
      'Brookmont',
      'Bryans Road',
      'Buckeystown',
      'Burtonsville',
      "Butcher's Hill",
      'Cabin John',
      'California',
      'Calverton',
      'Cambridge',
      'Camp Springs',
      'Cape Saint Claire',
      'Capitol Heights',
      'Carney',
      'Catonsville',
      'Cavetown',
      'Charlotte Hall',
      'Charles Village',
      'Chesapeake Beach',
      'Chesapeake Ranch Estates',
      'Chestertown',
      'Cheverly',
      'Chevy Chase Village',
      'Chillum',
      'Clarksburg',
      'Clover Hill',
      'Cloverly',
      'Cockeysville',
      'Colesville',
      'Colmar Manor',
      'Coral Hills',
      'Cottage City',
      'Cresaptown',
      'Crofton',
      'Crisfield',
      'Croom',
      'Crownsville',
      'Damascus',
      'Darnestown',
      'Davidsonville',
      'Deale',
      'Denton',
      'Derwood',
      'District Heights',
      'Drum Point',
      'Dundalk',
      'Dunkirk',
      'Dunkirk Town Center',
      'East Riverdale',
      'Easton',
      'Edgemere',
      'Edmonston',
      'Eldersburg',
      'Elkridge',
      'Ellicott City',
      'Emmitsburg',
      'Essex',
      'Fairland',
      'Fairmount Heights',
      'Fallston',
      'Federalsburg',
      'Ferndale',
      'Forest Glen',
      'Forest Heights',
      'Forestville',
      'Fort George G Mead Junction',
      'Fort Washington',
      'Four Corners',
      'Frederick',
      'Friendly',
      'Friendship Village',
      'Frostburg',
      'Fruitland',
      'Gaithersburg',
      'Gambrills',
      'Garrett Park',
      'Garrison',
      'Glassmanor',
      'Glen Burnie',
      'Glenarden',
      'Glenmont',
      'Glenn Dale',
      'Golden Beach',
      'Grasonville',
      'Green Haven',
      'Green Valley',
      'Greenbelt',
      'Gwynn Oak',
      'Halfway',
      'Hampstead',
      'Hancock',
      'Queen Anne',
      'Havre de Grace',
      'Herald Harbor',
      'Highfield-Cascade',
      'Hillandale',
      'Hillcrest Heights',
      'Hillsmere Shores',
      'Hughesville',
      'Hunt Valley',
      'Huntingtown',
      'Huntingtown Town Center',
      'Hurlock',
      'Hyattsville',
      'Ilchester',
      'Indian Head',
      'Jarrettsville',
      'Joppatowne',
      'Keedysville',
      'Kemp Mill',
      'Kensington',
      'Kettering',
      'Kingstown',
      'Kingsville',
      'La Plata',
      'La Vale',
      'Lake Arbor',
      'Lake Shore',
      'Landover',
      'Landover Hills',
      'Langley Park',
      'Lanham',
      'Lansdowne',
      'Layhill',
      'Leonardtown',
      'Lexington Park',
      'Linganore',
      'Linthicum',
      'Lochearn',
      'Lonaconing',
      'Londontowne',
      'Long Beach',
      'Lusby',
      'Lutherville',
      'Marlboro Meadows',
      'Marlow Heights',
      'Marlton',
      'Maryland City',
      'Maugansville',
      'Mays Chapel',
      'Mechanicsville',
      'Mellwood',
      'Middle River',
      'Milford Mill',
      'Mitchellville',
      'Montgomery Village',
      'Morningside',
      'Mount Rainier',
      'Mountain Lake Park',
      'Myersville',
      'New Carrollton',
      'New Windsor',
      'North Beach',
      'North Bel Air',
      'North Bethesda',
      'North East',
      'North Kensington',
      'North Laurel',
      'North Potomac',
      'Ocean Pines',
      'Odenton',
      'Overlea',
      'Owings',
      'Owings Mills',
      'Oxon Hill',
      'Parkville',
      'Parole',
      'Pasadena',
      'Perry Hall',
      'Perryman',
      'Pikesville',
      'Pittsville',
      'Pleasant Hills',
      'Pocomoke City',
      'Point of Rocks',
      'Poolesville',
      'Potomac',
      'Potomac Heights',
      'Potomac Park',
      'Prince Frederick',
      'Princess Anne',
      'Pumphrey',
      'Randallstown',
      'Redland',
      'Reisterstown',
      'Ridgely',
      'Riva',
      'Riverdale Park',
      'Robinwood',
      'Rock Hall',
      'Rosaryville',
      'Rosedale',
      'Rossmoor',
      'Saint Charles',
      'Saint James',
      'Saint Michaels',
      'Salisbury',
      'Savage',
      'Scaggsville',
      'Seabrook',
      'Seat Pleasant',
      'Selby-on-the-Bay',
      'Severn',
      'Severna Park',
      'Shady Side',
      'Silver Hill',
      'Silver Spring',
      'Smithsburg',
      'Snow Hill',
      'Solomons',
      'South Bel Air',
      'South Gate',
      'South Kensington',
      'South Laurel',
      'Spencerville',
      'Spring Ridge',
      'Stevensville',
      'Suitland',
      'Sykesville',
      'Takoma Park',
      'Taneytown',
      'Temple Hills',
      'Thurmont',
      'Timonium',
      'Towson',
      'Trappe',
      'Travilah',
      'Upper Marlboro',
      'Urbana',
      'Waldorf',
      'Walker Mill',
      'Walkersville',
      'West Elkridge',
      'West Laurel',
      'West Ocean City',
      'Westernport',
      'Westphalia',
      'Wheaton',
      'White Marsh',
      'White Oak',
      'Williamsport',
      'Woodlawn',
      'Woodmore',
      'Woodsboro',
      'Adrian',
      'Advance',
      'Affton',
      'Anderson',
      'Appleton City',
      'Archie',
      'Ash Grove',
      'Ava',
      'Ballwin',
      'Barnhart',
      'Battlefield',
      'Bel-Nor',
      'Bel-Ridge',
      'Belle',
      'Bellefontaine Neighbors',
      'Belton',
      'Berkeley',
      'Bernie',
      'Billings',
      'Bismarck',
      'Black Jack',
      'Blue Springs',
      'Bolivar',
      'Bonne Terre',
      'Bourbon',
      'Branson',
      'Breckenridge Hills',
      'Bridgeton',
      'Brookfield',
      'Buffalo',
      'Byrnes Mill',
      'Cabool',
      'Calverton Park',
      'Camdenton',
      'Cape Girardeau',
      'Carl Junction',
      'Carthage',
      'Caruthersville',
      'Cassville',
      'Castle Point',
      'Cedar Hill',
      'Chaffee',
      'Charlack',
      'Chesterfield',
      'Chillicothe',
      'Clarkson Valley',
      'Clarkton',
      'Claycomo',
      'Clever',
      'Cole Camp',
      'Cool Valley',
      'Cottleville',
      'Country Club Hills',
      'Country Club Village',
      'Crane',
      'Creve Coeur',
      'Crocker',
      'Crystal City',
      'Cuba',
      'Dardenne Prairie',
      'Dellwood',
      'Des Peres',
      'Desloge',
      'Dexter',
      'Doniphan',
      'Duenweg',
      'Duquesne',
      'East Independence',
      'East Prairie',
      'El Dorado Springs',
      'Eldon',
      'Ellisville',
      'Elsberry',
      'Elvins',
      'Esther',
      'Excelsior Springs',
      'Fair Grove',
      'Fenton',
      'Ferguson',
      'Festus',
      'Flat River',
      'Florissant',
      'Four Seasons',
      'Fredericktown',
      'Gallatin',
      'Gerald',
      'Gideon',
      'Gladstone',
      'Glasgow Village',
      'Glendale',
      'Goodman',
      'Gower',
      'Grain Valley',
      'Granby',
      'Gray Summit',
      'Green Park',
      'Hallsville',
      'Hanley Hills',
      'Hannibal',
      'Harrisonville',
      'Hartville',
      'Hayti',
      'Hazelwood',
      'Herculaneum',
      'Hermann',
      'Hermitage',
      'Higginsville',
      'High Ridge',
      'Hillsdale',
      'Holden',
      'Hollister',
      'Holts Summit',
      'Houston',
      'Humansville',
      'Imperial',
      'Ironton',
      'Jefferson City',
      'Joplin',
      'Kearney',
      'Kennett',
      'Keytesville',
      'Kimberling City',
      'Kingston',
      'Kirkwood',
      'Kissee Mills',
      'Knob Noster',
      'La Monte',
      'Ladue',
      'Lake Lotawana',
      'Lake Ozark',
      'Lake Saint Louis',
      'Lake Winnebago',
      'Lakeshire',
      'Lathrop',
      'Lawson',
      'Leadwood',
      "Lee's Summit",
      'Lemay',
      'Licking',
      'Lilbourn',
      'Linneus',
      'Lone Jack',
      'Louisiana',
      'Malden',
      'Maplewood',
      'Marble Hill',
      'Marceline',
      'Marionville',
      'Marlborough',
      'Marshfield',
      'Marthasville',
      'Maryland Heights',
      'Mehlville',
      'Merriam Woods',
      'Mexico',
      'Moberly',
      'Moline Acres',
      'Monett',
      'Monroe City',
      'Montgomery City',
      'Moscow Mills',
      'Mountain Grove',
      'Murphy',
      'Neosho',
      'Nevada',
      'New Franklin',
      'New Haven',
      'New London',
      'New Madrid',
      'Nixa',
      'Noel',
      'Normandy',
      'North Kansas City',
      'Northwoods',
      'Oakville',
      'Olivette',
      'Oran',
      'Oregon',
      'Oronogo',
      'Osage Beach',
      'Overland',
      'Pacific',
      'Pagedale',
      'Palmyra',
      'Peculiar',
      'Pevely',
      'Pierce City',
      'Pine Lawn',
      'Platte City',
      'Plattsburg',
      'Pleasant Hill',
      'Pleasant Valley',
      'Poplar Bluff',
      'Portageville',
      'Potosi',
      'Purdy',
      'Raymore',
      'Raytown',
      'Republic',
      'Rich Hill',
      'Rock Hill',
      'Rolla',
      'Saint Ann',
      'Saint Clair',
      'Saint Johns',
      'St. Louis',
      'Saint Martins',
      'Saint Peters',
      'Saint Robert',
      'Sainte Genevieve',
      'Sappington',
      'Sarcoxie',
      'Scott City',
      'Sedalia',
      'Senath',
      'Shelbina',
      'Shell Knob',
      'Shrewsbury',
      'Sikeston',
      'Slater',
      'Smithville',
      'Spanish Lake',
      'Steelville',
      'Stover',
      'Strafford',
      'Sugar Creek',
      'Sunset Hills',
      'Sweet Springs',
      'Taos',
      'Terre du Lac',
      'Thayer',
      'Tipton',
      'Town and Country',
      'University City',
      'Valley Park',
      'Velda Village',
      'Velda Village Hills',
      'Vinita Park',
      'Wardsville',
      'Warson Woods',
      'Waynesville',
      'Weatherby Lake',
      'Webb City',
      'Webster Groves',
      'Weldon Spring',
      'Wellston',
      'Wentzville',
      'West Plains',
      'Willard',
      'Willow Springs',
      'Winona',
      'Woodson Terrace',
      'Wright City',
      'Ackerman',
      'Amory',
      'Arnold Line',
      'Baldwyn',
      'Bay Saint Louis',
      'Bay Springs',
      'Beechwood',
      'Belmont',
      'Belzoni',
      'Biloxi',
      'Bruce',
      'Bude',
      'Byhalia',
      'Byram',
      'Caledonia',
      'Calhoun City',
      'Carriere',
      'Clarksdale',
      'Cleary',
      'Collins',
      'Como',
      'Conehatta',
      'Corinth',
      "D'Iberville",
      'De Kalb',
      'De Lisle',
      'Derma',
      'Diamondhead',
      'Drew',
      'Duck Hill',
      'Durant',
      'Edwards',
      'Escatawpa',
      'Eupora',
      'Flowood',
      'Forest',
      'Friars Point',
      'Gautier',
      'Grenada',
      'Gulf Hills',
      'Gulf Park Estates',
      'Guntown',
      'Hattiesburg',
      'Hickory Hills',
      'Hollandale',
      'Horn Lake',
      'Hurley',
      'Indianola',
      'Itta Bena',
      'Iuka',
      'Jonestown',
      'Kearney Park',
      'Kiln',
      'Kosciusko',
      'Lambert',
      'Latimer',
      'Leakesville',
      'Leland',
      'Lucedale',
      'Lumberton',
      'Lyman',
      'Lynchburg',
      'Magee',
      'Mantachie',
      'Marks',
      'Mayersville',
      'McComb',
      'Meadville',
      'Mendenhall',
      'Meridian',
      'Metcalfe',
      'Mikoma',
      'Moorhead',
      'Morton',
      'Moss Point',
      'Mound Bayou',
      'Natchez',
      'Nellieburg',
      'Nettleton',
      'New Augusta',
      'North Tunica',
      'Ocean Springs',
      'Olive Branch',
      'Pascagoula',
      'Pass Christian',
      'Pearl',
      'Pearlington',
      'Pelahatchie',
      'Petal',
      'Philadelphia',
      'Picayune',
      'Pickens',
      'Plantersville',
      'Pontotoc',
      'Poplarville',
      'Port Gibson',
      'Prentiss',
      'Purvis',
      'Raleigh',
      'Rawls Springs',
      'Raymond',
      'Richton',
      'Ridgeland',
      'Ripley',
      'Rolling Fork',
      'Ruleville',
      'Saltillo',
      'Saucier',
      'Senatobia',
      'Sharon',
      'Southaven',
      'Saint Martin',
      'Starkville',
      'Summit',
      'Sumrall',
      'Sunflower',
      'Tchula',
      'Terry',
      'Tunica',
      'Tupelo',
      'Tutwiler',
      'Tylertown',
      'University',
      'Vancleave',
      'Vardaman',
      'Vicksburg',
      'Wade',
      'Walls',
      'Walthall',
      'Water Valley',
      'Waveland',
      'Wesson',
      'West Gulfport',
      'West Hattiesburg',
      'Wiggins',
      'Yazoo City',
      'Ahoskie',
      'Albemarle',
      'Andrews',
      'Angier',
      'Apex',
      'Archdale',
      'Archer Lodge',
      'Asheboro',
      'Asheville',
      'Avery Creek',
      'Ayden',
      'Badin',
      'Bakersville',
      'Balfour',
      'Banner Elk',
      'Barker Heights',
      'Bayboro',
      'Bayshore',
      'Beaufort',
      'Belhaven',
      'Belville',
      'Benson',
      'Bent Creek',
      'Bermuda Run',
      'Bessemer City',
      'Bethel',
      'Bethlehem',
      'Beulaville',
      'Biltmore Forest',
      'Biscoe',
      'Black Mountain',
      'Bladenboro',
      'Blowing Rock',
      'Boiling Spring Lakes',
      'Boiling Springs',
      'Bolivia',
      'Boone',
      'Brevard',
      'Brices Creek',
      'Broad Creek',
      'Broadway',
      'Brogden',
      'Bryson City',
      'Buies Creek',
      'Burgaw',
      'Burnsville',
      'Butner',
      'Buxton',
      'Cajahs Mountain',
      'Calabash',
      'Cape Carteret',
      'Carolina Beach',
      'Carolina Shores',
      'Carrboro',
      'Cary',
      'Castle Hayne',
      'Cedar Point',
      'Chadbourn',
      'Chapel Hill',
      'Charlotte',
      'Cherryville',
      'China Grove',
      'Claremont',
      'Clemmons',
      'Clyde',
      'Coats',
      'Connelly Springs',
      'Conover',
      'Cornelius',
      'Cove Creek',
      'Cramerton',
      'Creedmoor',
      'Cricket',
      'Cullowhee',
      'Currituck',
      'Dana',
      'Danbury',
      'Davidson',
      'Denver',
      'Dobson',
      'Drexel',
      'Dunn',
      'Durham',
      'East Flat Rock',
      'East Rockingham',
      'East Spencer',
      'Eastover',
      'Eden',
      'Edenton',
      'Edneyville',
      'Elizabeth City',
      'Elkin',
      'Ellerbe',
      'Elm City',
      'Elon',
      'Elroy',
      'Emerald Isle',
      'Enfield',
      'Enochville',
      'Erwin',
      'Etowah',
      'Fairfield Harbour',
      'Fairmont',
      'Fairplains',
      'Farmville',
      'Fearrington Village',
      'Flat Rock',
      'Fletcher',
      'Forest Oaks',
      'Foscoe',
      'Four Oaks',
      'Franklinville',
      'Fremont',
      'Fuquay-Varina',
      'Gamewell',
      'Garner',
      'Gastonia',
      'Gatesville',
      'Gaston',
      'Gibsonville',
      'Glen Alpine',
      'Glen Raven',
      'Goldsboro',
      'Gorman',
      'Graham',
      'Granite Falls',
      'Granite Quarry',
      'Green Level',
      'Grifton',
      'Half Moon',
      'Halifax',
      'Hamlet',
      'Harkers Island',
      'Havelock',
      'Haw River',
      'Hayesville',
      'Hemby Bridge',
      'Hendersonville',
      'Hertford',
      'Hickory',
      'High Point',
      'Hildebran',
      'Hillsborough',
      'Holly Ridge',
      'Hoopers Creek',
      'Hope Mills',
      'Horse Shoe',
      'Huntersville',
      'Icard',
      'Indian Trail',
      'James City',
      'Kannapolis',
      'Kenansville',
      'Kenly',
      'Kernersville',
      'Kill Devil Hills',
      'King',
      'Kings Grant',
      'Kings Mountain',
      'Kinston',
      'Kitty Hawk',
      'Knightdale',
      'Kure Beach',
      'Lake Junaluska',
      'Lake Lure',
      'Lake Norman of Catawba',
      'Lake Waccamaw',
      'Landis',
      'Laurel Hill',
      'Laurel Park',
      'Laurinburg',
      'Lenoir',
      'Lillington',
      'Locust',
      'Longview',
      'Lowesville',
      'Lucama',
      'Maggie Valley',
      'Maiden',
      'Manteo',
      'Mar-Mac',
      'Mars Hill',
      'Marshville',
      'Marvin',
      'Masonboro',
      'Matthews',
      'Maury',
      'Maxton',
      'Mayodan',
      'McLeansville',
      'Mebane',
      'Midland',
      'Millers Creek',
      'Mills River',
      'Mint Hill',
      'Mocksville',
      'Moravian Falls',
      'Morehead City',
      'Morganton',
      'Morrisville',
      'Mount Gilead',
      'Mount Holly',
      'Mount Pleasant',
      'Moyock',
      'Murraysville',
      'Nags Head',
      'Navassa',
      'Neuse Forest',
      'New Bern',
      'Newland',
      'Norlina',
      'North Wilkesboro',
      'Northlakes',
      'Norwood',
      'Oak Island',
      'Oakboro',
      'Pilot Mountain',
      'Pine Knoll Shores',
      'Pinebluff',
      'Pinehurst',
      'Pinetops',
      'Piney Green',
      'Plain View',
      'Pleasant Garden',
      'Plymouth',
      'Polkton',
      'Princeville',
      'Pumpkin Center',
      'Raeford',
      'Ramseur',
      'Randleman',
      'Ranlo',
      'Red Oak',
      'Red Springs',
      'Rhodhiss',
      'Richlands',
      'River Bend',
      'River Road',
      'Roanoke Rapids',
      'Robbins',
      'Robbinsville',
      'Robersonville',
      'Rockfish',
      'Rockingham',
      'Rocky Point',
      'Rocky Mount',
      'Rolesville',
      'Roseboro',
      'Rowland',
      'Roxboro',
      'Royal Pines',
      'Rural Hall',
      'Rutherford College',
      'Rutherfordton',
      'Saint Pauls',
      'Sawmills',
      'Saxapahaw',
      'Scotland Neck',
      'Sea Breeze',
      'Seagate',
      'Seven Lakes',
      'Shallotte',
      'Sharpsburg',
      'Sherrills Ford',
      'Siler City',
      'Skippers Corner',
      'Smithfield',
      'Sneads Ferry',
      'South Gastonia',
      'South Henderson',
      'South Rosemary',
      'Southern Pines',
      'Southern Shores',
      'Southmont',
      'Spindale',
      'Spring Hope',
      'Spring Lake',
      'Spruce Pine',
      'Saint Stephens',
      'Stallings',
      'Stanfield',
      'Stanley',
      'Statesville',
      'Stedman',
      'Stokesdale',
      'Stoneville',
      'Stony Point',
      'Summerfield',
      'Sunset Beach',
      'Surf City',
      'Swannanoa',
      'Swanquarter',
      'Swansboro',
      'Swepsonville',
      'Sylva',
      'Tabor City',
      'Tarboro',
      'Thurmond',
      'Toast',
      'Tobaccoville',
      'Trent Woods',
      'Troutman',
      'Tryon',
      'Tyro',
      'Valdese',
      'Valley Hill',
      'Vander',
      'Wadesboro',
      'Wake Forest',
      'Walkertown',
      'Wallburg',
      'Walnut Cove',
      'Wanchese',
      'Waxhaw',
      'Weaverville',
      'Weddington',
      'Welcome',
      'Weldon',
      'Wentworth',
      'Wendell',
      'West Canton',
      'West Jefferson',
      'West Marion',
      'West Raleigh',
      'Whispering Pines',
      'White Plains',
      'Whiteville',
      'Wilkesboro',
      'Williamston',
      'Wilson',
      'Wilsons Mills',
      'Wingate',
      'Winston-Salem',
      'Winton',
      'Woodfin',
      'Wrightsboro',
      'Wrightsville Beach',
      'Yadkinville',
      'Yanceyville',
      'Absecon',
      'Alloway',
      'Atco',
      'Atlantic City',
      'Audubon',
      'Avalon',
      'Barnegat',
      'Barrington',
      'Bayville',
      'Beach Haven',
      'Beach Haven West',
      'Beachwood',
      'Beckett',
      'Bellmawr',
      'Blackwood',
      'Brigantine',
      'Brooklawn',
      'Browns Mills',
      'Buena',
      'Cape May Court House',
      'Cape May',
      'Carneys Point',
      'Cedar Glen Lakes',
      'Cherry Hill',
      'Cherry Hill Mall',
      'Chesilhurst',
      'Cinnaminson',
      'Clementon',
      'Collings Lakes',
      'Collingswood',
      'Country Lake Estates',
      'Crestwood Village',
      'Dover Beaches North',
      'Dover Beaches South',
      'Echelon',
      'Egg Harbor City',
      'Ellisburg',
      'Elmer',
      'Erma',
      'Estell Manor',
      'Fairton',
      'Folsom',
      'Forked River',
      'Gibbsboro',
      'Gibbstown',
      'Glassboro',
      'Glendora',
      'Gloucester City',
      'Golden Triangle',
      'Greentree',
      'Haddon Heights',
      'Haddonfield',
      'Hammonton',
      'Holiday Heights',
      'Island Heights',
      'Kingston Estates',
      'Laurel Lake',
      'Lavallette',
      'Lawnside',
      'Laurel Springs',
      'Leisuretowne',
      'Lindenwold',
      'Linwood',
      'Manahawkin',
      'Maple Shade',
      'Margate City',
      'Mays Landing',
      'Medford Lakes',
      'Millville',
      'Mount Ephraim',
      'Mount Laurel',
      'Mullica Hill',
      'Mystic Island',
      'National Park',
      'Newfield',
      'North Beach Haven',
      'North Cape May',
      'North Wildwood',
      'Oaklyn',
      'Ocean Acres',
      'Ocean Gate',
      'Olivet',
      'Paulsboro',
      'Pemberton',
      'Pemberton Heights',
      'Penns Grove',
      'Pennsauken',
      'Pennsville',
      'Pine Beach',
      'Pine Hill',
      'Pine Ridge at Crestwood',
      'Pitman',
      'Pleasantville',
      'Pomona',
      'Port Norris',
      'Port Republic',
      'Presidential Lakes Estates',
      'Ramblewood',
      'Rio Grande',
      'Rosenhayn',
      'Runnemede',
      'Sea Isle City',
      'Seabrook Farms',
      'Seaside Heights',
      'Seaside Park',
      'Sewell',
      'Ship Bottom',
      'Sicklerville',
      'Silver Ridge',
      'Somerdale',
      'Somers Point',
      'South Toms River',
      'South Vineland',
      'Stratford',
      'Swedesboro',
      'Toms River',
      'Tuckerton',
      'Turnersville',
      'Ventnor City',
      'Victory Lakes',
      'Vincentown',
      'Vineland',
      'Waretown',
      'Wenonah',
      'West Cape May',
      'Westville',
      'Wildwood Crest',
      'Wood-Lynne',
      'Woodbury',
      'Woodbury Heights',
      'Woodstown',
      'Amberley',
      'Arcanum',
      'Ashville',
      'Batavia',
      'Beavercreek',
      'Beckett Ridge',
      'Bellbrook',
      'Belpre',
      'Beverly',
      'Bexley',
      'Blacklick Estates',
      'Blanchester',
      'Blue Ash',
      'Bridgetown',
      'Buckeye Lake',
      'Byesville',
      'Canal Winchester',
      'Chauncey',
      'Cherry Grove',
      'Cheviot',
      'Choctaw Lake',
      'Cincinnati',
      'Circleville',
      'Cleves',
      'Coal Grove',
      'Commercial Point',
      'Covedale',
      'Crooksville',
      'Crystal Lakes',
      'Day Heights',
      'Deer Park',
      'Dent',
      'Devola',
      'Dillonvale',
      'Dry Run',
      'Dunlap',
      'Eaton',
      'Elmwood Place',
      'Enon',
      'Etna',
      'Evendale',
      'Fairborn',
      'Fairfax',
      'Fairfield Beach',
      'Farmersville',
      'Finneytown',
      'Fort McKinley',
      'Franklin Furnace',
      'Fruit Hill',
      'Gallipolis',
      'Glouster',
      'Golf Manor',
      'Grandview Heights',
      'Green Meadows',
      'Greenhills',
      'Groesbeck',
      'Groveport',
      'Harbor Hills',
      'Highpoint',
      'Holiday Valley',
      'Huber Heights',
      'Hunter',
      'Kenwood',
      'Kings Mills',
      'Lake Darby',
      'Landen',
      'Lewisburg',
      'Lincoln Heights',
      'Lincoln Village',
      'Lisbon',
      'Lithopolis',
      'Lockland',
      'Logan',
      'Logan Elm Village',
      'Loveland',
      'Loveland Park',
      'Lucasville',
      'Mack',
      'Madeira',
      'Mariemont',
      'Mason',
      'McArthur',
      'McConnelsville',
      'Miami Heights',
      'Miamisburg',
      'Miamitown',
      'Middleport',
      'Millersport',
      'Monfort Heights',
      'Moraine',
      'Mount Healthy',
      'Mount Healthy Heights',
      'Mount Orab',
      'Mount Repose',
      'Nelsonville',
      'New Boston',
      'New Burlington',
      'New Carlisle',
      'New Concord',
      'New Lebanon',
      'New Lexington',
      'New Matamoras',
      'New Miami',
      'New Paris',
      'New Richmond',
      'New Vienna',
      'Newtown',
      'North College Hill',
      'North Fork Village',
      'North Zanesville',
      'Northbrook',
      'Northgate',
      'Northridge',
      'Obetz',
      'Park Layne',
      'Pataskala',
      'Peebles',
      'Pickerington',
      'Piketon',
      'Pleasant Run',
      'Pleasant Run Farm',
      'Pomeroy',
      'Portsmouth',
      'Powhatan Point',
      'Reading',
      'Reno',
      'Reynoldsburg',
      'Rosemount',
      'Ross',
      'Roseville',
      'Rossmoyne',
      'Sabina',
      'Saint Bernard',
      'Salem Heights',
      'Sciotodale',
      'Shadyside',
      'Sharonville',
      'Shawnee Hills',
      'Sherwood',
      'Silverton',
      'Sixteen Mile Stand',
      'Skyline Acres',
      'South Bloomfield',
      'South Charleston',
      'South Lebanon',
      'South Point',
      'South Zanesville',
      'Springboro',
      'Summerside',
      'Terrace Park',
      'The Plains',
      'The Village of Indian Hill',
      'Thornport',
      'Tipp City',
      'Trotwood',
      'Upper Arlington',
      'Urbancrest',
      'Washington Court House',
      'West Alexandria',
      'West Carrollton City',
      'West Milton',
      'West Portsmouth',
      'West Union',
      'Wetherington',
      'Wheelersburg',
      'Whitehall',
      'Wilberforce',
      'Withamsville',
      'Woodsfield',
      'Xenia',
      'Yellow Springs',
      'Zanesville',
      'Ada',
      'Afton',
      'Altus',
      'Anadarko',
      'Antlers',
      'Apache',
      'Arapaho',
      'Ardmore',
      'Arkoma',
      'Arnett',
      'Atoka',
      'Barnsdall',
      'Bartlesville',
      'Beggs',
      'Bethel Acres',
      'Bixby',
      'Blackwell',
      'Boley',
      'Bray',
      'Bristow',
      'Broken Arrow',
      'Broken Bow',
      'Burns Flat',
      'Bushyhead',
      'Byng',
      'Cache',
      'Caddo',
      'Carnegie',
      'Catoosa',
      'Central High',
      'Checotah',
      'Cheyenne',
      'Chickasha',
      'Choctaw',
      'Chouteau',
      'Claremore',
      'Cleora',
      'Colbert',
      'Comanche',
      'Copeland',
      'Cordell',
      'Coweta',
      'Crescent',
      'Cushing',
      'Cyril',
      'Davis',
      'Del City',
      'Dewey',
      'Dickson',
      'Drumright',
      'Duncan',
      'Edmond',
      'El Reno',
      'Elgin',
      'Elk City',
      'Enid',
      'Erick',
      'Fort Gibson',
      'Geary',
      'Geronimo',
      'Glenpool',
      'Goldsby',
      'Granite',
      'Grove',
      'Hall Park',
      'Harrah',
      'Hartshorne',
      'Healdton',
      'Heavener',
      'Hennessey',
      'Henryetta',
      'Hinton',
      'Hobart',
      'Holdenville',
      'Hollis',
      'Hominy',
      'Hugo',
      'Idabel',
      'Inola',
      'Jay',
      'Jenks',
      'Jones',
      'Justice',
      'Kellyville',
      'Kiefer',
      'Kingfisher',
      'Konawa',
      'Krebs',
      'Langston',
      'Laverne',
      'Lawton',
      'Lindsay',
      'Lone Grove',
      'Longtown',
      'Luther',
      'Madill',
      'Mangum',
      'Mannford',
      'Marlow',
      'Maud',
      'McAlester',
      'McCord',
      'McLoud',
      'Medford',
      'Meeker',
      'Midwest City',
      'Minco',
      'Moore',
      'Mooreland',
      'Mounds',
      'Muldrow',
      'Muskogee',
      'Mustang',
      'Newcastle',
      'Newkirk',
      'Nichols Hills',
      'Nicoma Park',
      'Ninnekah',
      'Noble',
      'Norman',
      'Nowata',
      'Oakhurst',
      'Oilton',
      'Okarche',
      'Okeene',
      'Okemah',
      'Oklahoma City',
      'Okmulgee',
      'Oologah',
      'Owasso',
      'Panama',
      'Park Hill',
      'Pawhuska',
      'Pauls Valley',
      'Perkins',
      'Pink',
      'Pocola',
      'Ponca City',
      'Poteau',
      'Prague',
      'Pryor',
      'Purcell',
      'Quinton',
      'Ringling',
      'Roland',
      'Rush Springs',
      'Sallisaw',
      'Sand Springs',
      'Sapulpa',
      'Sayre',
      'Shattuck',
      'Skiatook',
      'Slaughterville',
      'Snyder',
      'Sperry',
      'Spiro',
      'Stigler',
      'Stillwater',
      'Stilwell',
      'Stroud',
      'Tahlequah',
      'Talihina',
      'Taloga',
      'Tecumseh',
      'Texanna',
      'The Village',
      'Thomas',
      'Tishomingo',
      'Tonkawa',
      'Tulsa',
      'Turley',
      'Tuttle',
      'Verdigris',
      'Vian',
      'Vinita',
      'Wagoner',
      'Walters',
      'Warr Acres',
      'Warner',
      'Watonga',
      'Waukomis',
      'Waurika',
      'Weatherford',
      'Wetumka',
      'Wewoka',
      'Wilburton',
      'Wister',
      'Woodward',
      'Wynnewood',
      'Yale',
      'Yukon',
      'Abbottstown',
      'Airville',
      'Aldan',
      'Atglen',
      'Biglerville',
      'Bonneauville',
      'Boothwyn',
      'Broomall',
      'Caln',
      'Carroll Valley',
      'Chambersburg',
      'Chester Heights',
      'Christiana',
      'Clifton Heights',
      'Coatesville',
      'Collingdale',
      'Colwyn',
      'Conestoga',
      'Dallastown',
      'Darby',
      'Drexel Hill',
      'Dunbar',
      'East Berlin',
      'East Lansdowne',
      'East Uniontown',
      'East York',
      'Eddystone',
      'Fairchance',
      'Folcroft',
      'Gap',
      'Gettysburg',
      'Glen Rock',
      'Glenolden',
      'Grantley',
      'Guilford Siding',
      'Hopwood',
      'Jacobus',
      'Kennett Square',
      'Lake Heritage',
      'Lake Meade',
      'Lampeter',
      'Lima',
      'Littlestown',
      'Luzerne',
      'Marcus Hook',
      'Masontown',
      'McConnellsburg',
      'McSherrystown',
      'Media',
      'Mercersburg',
      'Meyersdale',
      'Millbourne',
      'Millersville',
      'Mont Alto',
      'New Freedom',
      'New Oxford',
      'North York',
      'Oliver',
      'Parkesburg',
      'Parkside',
      'Penn Wynne',
      'Pennsport',
      'Pennville',
      'Point Marion',
      'Prospect Park',
      'Quarryville',
      'Red Lion',
      'Ridley Park',
      'Scotland',
      'Sharon Hill',
      'South Connellsville',
      'South Coatesville',
      'South Uniontown',
      'Spring Grove',
      'Spry',
      'State Line',
      'Stewartstown',
      'Stonybrook',
      'Strasburg',
      'Susquehanna Trails',
      'Swarthmore',
      'Thorndale',
      'Toughkenamon',
      'Trainer',
      'Upland',
      'Valley View',
      'Wayne Heights',
      'Waynesburg',
      'Weigelstown',
      'West Chester',
      'West Grove',
      'West York',
      'Wharton',
      'Whitman',
      'Willow Street',
      'Woodlyn',
      'Yoe',
      'Yeadon',
      'Yorklyn',
      'Aiken',
      'Allendale',
      'Arial',
      'Awendaw',
      'Bamberg',
      'Barnwell',
      'Batesburg',
      'Batesburg-Leesville',
      'Belvedere',
      'Bennettsville',
      'Bishopville',
      'Blackville',
      'Blacksburg',
      'Bluffton',
      'Blythewood',
      'Bonneau Beach',
      'Brookdale',
      'Burnettown',
      'Burton',
      'Calhoun Falls',
      'Cane Savannah',
      'Catawba',
      'Cayce',
      'Chapin',
      'Cheraw',
      'City View',
      'Clemson',
      'Clover',
      'Cowpens',
      'Dalzell',
      'Darlington',
      'Denmark',
      'Dentsville',
      'Dillon',
      'Due West',
      'Dunean',
      'Easley',
      'East Gaffney',
      'East Sumter',
      'Edgefield',
      'Edisto',
      'Estill',
      'Eureka Mill',
      'Fairforest',
      'Five Forks',
      'Folly Beach',
      'Forest Acres',
      'Forestbrook',
      'Fort Mill',
      'Fountain Inn',
      'Gaffney',
      'Gantt',
      'Gloverville',
      'Golden Grove',
      'Goose Creek',
      'Graniteville',
      'Great Falls',
      'Greer',
      'Hanahan',
      'Hardeeville',
      'Hartsville',
      'Hilton Head Island',
      'Hilton Head',
      'Honea Path',
      'Homeland Park',
      'Hopkins',
      'India Hook',
      'Inman Mills',
      'Irmo',
      'Irwin',
      'Isle of Palms',
      'Iva',
      'Joanna',
      'Johnsonville',
      'Johnston',
      'Judson',
      'Kershaw',
      'Kiawah Island',
      'Kingstree',
      'Ladson',
      'Lake Secession',
      'Lake Wylie',
      'Lakewood',
      'Lancaster Mill',
      'Landrum',
      'Langley',
      'Latta',
      'Laurel Bay',
      'Laurens',
      'Lesslie',
      'Lincolnville',
      'Little River',
      'Loris',
      'Manning',
      'Mauldin',
      'McColl',
      'McCormick',
      'Meggett',
      'Moncks Corner',
      'Mullins',
      'Murphys Estates',
      'Murrells Inlet',
      'Myrtle Beach',
      'New Ellenton',
      'Ninety Six',
      'North Augusta',
      'North Charleston',
      'North Hartsville',
      'North Myrtle Beach',
      'Northlake',
      'Orangeburg',
      'Pacolet',
      'Pageland',
      'Pamplico',
      'Pineridge',
      'Port Royal',
      'Powdersville',
      'Privateer',
      'Prosperity',
      'Ravenel',
      'Red Bank',
      'Red Hill',
      'Ridgeville',
      'Roebuck',
      'Saint Andrews',
      'Saint Stephen',
      'Saluda',
      'Sangaree',
      'Sans Souci',
      'Saxon',
      'Seabrook Island',
      'Seven Oaks',
      'Shell Point',
      'Socastee',
      'South Congaree',
      'South Sumter',
      'Southern Shops',
      'Spartanburg',
      'Stateburg',
      'Sullivans Island',
      'Sumter',
      'Surfside Beach',
      'Taylors',
      'Tega Cay',
      'Tigerville',
      'Timmonsville',
      'Travelers Rest',
      'Utica',
      'Varnville',
      'Wade Hampton',
      'Walhalla',
      'Walterboro',
      'Ware Shoals',
      'Warrenville',
      'Wedgewood',
      'Wellford',
      'West Columbia',
      'Whitmire',
      'Wilkinson Heights',
      'Winnsboro Mills',
      'Woodfield',
      'Woodruff',
      'Adamsville',
      'Alcoa',
      'Algood',
      'Apison',
      'Ashland City',
      'Banner Hill',
      'Bartlett',
      'Baxter',
      'Bean Station',
      'Belle Meade',
      'Bells',
      'Blaine',
      'Blountville',
      'Bluff City',
      'Bon Aqua Junction',
      'Bradford',
      'Brentwood Estates',
      'Bruceton',
      'Burns',
      'Byrdstown',
      'Caryville',
      'Celina',
      'Chattanooga',
      'Chuckey',
      'Church Hill',
      'Clifton',
      'Coalfield',
      'Collegedale',
      'Collierville',
      'Colonial Heights',
      'Condon',
      'Cookeville',
      'Coopertown',
      'Cornersville',
      'Cowan',
      'Cross Plains',
      'Crump',
      'Dandridge',
      'Decaturville',
      'Decherd',
      'Dodson Branch',
      'Dresden',
      'Dyer',
      'Dyersburg',
      'Eagleton Village',
      'East Brainerd',
      'East Chattanooga',
      'East Cleveland',
      'East Ridge',
      'Elizabethton',
      'Erin',
      'Estill Springs',
      'Fairfield Glade',
      'Fairmount',
      'Fall Branch',
      'Falling Water',
      'Farragut',
      'Fincastle',
      'Forest Hills',
      'Gainesboro',
      'Gatlinburg',
      'Gleason',
      'Goodlettsville',
      'Gordonsville',
      'Green Hill',
      'Greenback',
      'Greeneville',
      'Grimsley',
      'Gruetli-Laager',
      'Halls',
      'Harriman',
      'Harrogate',
      'Hickory Withe',
      'Hohenwald',
      'Hopewell',
      'Huntingdon',
      'Jacksboro',
      'Jellico',
      'Johnson City',
      'Jonesborough',
      'Kenton',
      'Kimball',
      'Kingsport',
      'Kingston Springs',
      'La Vergne',
      'LaFollette',
      'Rocky Top',
      'Lake Tansi',
      'Lakesite',
      'Lenoir City',
      'Lone Oak',
      'Loretto',
      'Loudon',
      'Luttrell',
      'Martin',
      'Mascot',
      'Maynardville',
      'McEwen',
      'McKenzie',
      'McMinnville',
      'Medina',
      'Middle Valley',
      'Midtown',
      'Millington',
      'Monteagle',
      'Monterey',
      'Mosheim',
      'Mount Juliet',
      'New Johnsonville',
      'New South Memphis',
      'New Tazewell',
      'New Union',
      'Newbern',
      'Nolensville',
      'Norris',
      'Obion',
      'Oliver Springs',
      'Oneida',
      'Pegram',
      'Pigeon Forge',
      'Pine Crest',
      'Piperton',
      'Pleasant View',
      'Portland',
      'Powells Crossroads',
      'Pulaski',
      'Red Boiling Springs',
      'Ridgetop',
      'Roan Mountain',
      'Rockwood',
      'Rural Hill',
      'Rutherford',
      'Rutledge',
      'Sale Creek',
      'Selmer',
      'Sevierville',
      'Sewanee',
      'Shackle Island',
      'Signal Mountain',
      'Sneedville',
      'Soddy-Daisy',
      'Somerville',
      'South Carthage',
      'South Cleveland',
      'South Fulton',
      'South Pittsburg',
      'Spring City',
      'Spurgeon',
      'Surgoinsville',
      'Tazewell',
      'Tennessee Ridge',
      "Thompson's Station",
      'Three Way',
      'Tiptonville',
      'Tracy City',
      'Tullahoma',
      'Tusculum',
      'Unicoi',
      'Vonore',
      'Walden',
      'Walnut Hill',
      'Wartburg',
      'White Bluff',
      'White House',
      'White Pine',
      'Whitwell',
      'Wildwood Lake',
      'Asherton',
      'Abram',
      'Addison',
      'Alamo Heights',
      'Aldine',
      'Aledo',
      'Alice',
      'Alief',
      'Allen',
      'Alton North (historical)',
      'Alvarado',
      'Alvin',
      'Alvord',
      'Ames',
      'Anahuac',
      'Anderson Mill',
      'Angleton',
      'Annetta',
      'Anson',
      'Aransas Pass',
      'Archer City',
      'Argyle',
      'Atascocita',
      'Aubrey',
      'Azle',
      'Bacliff',
      'Baird',
      'Balch Springs',
      'Balcones Heights',
      'Ballinger',
      'Bandera',
      'Bangs',
      'Barrett',
      'Barton Creek',
      'Bartonville',
      'Baytown',
      'Beach City',
      'Beaumont',
      'Bee Cave',
      'Beeville',
      'Bellmead',
      'Bellville',
      'Benavides',
      'Benbrook',
      'Benjamin',
      'Bertram',
      'Bevil Oaks',
      'Big Sandy',
      'Bishop',
      'Blanco',
      'Blossom',
      'Boerne',
      'Bogata',
      'Boling',
      'Bolivar Peninsula',
      'Bonham',
      'Boyd',
      'Brady',
      'Brazoria',
      'Breckenridge',
      'Brenham',
      'Briar',
      'Briarcliff',
      'Brookshire',
      'Brookside Village',
      'Brownsboro',
      'Bruceville-Eddy',
      'Brownwood',
      'Brushy Creek',
      'Bryan',
      'Buchanan Dam',
      'Bullard',
      'Bulverde',
      'Buna',
      'Bunker Hill Village',
      'Burkburnett',
      'Burleson',
      'Burnet',
      'Cactus',
      'Caddo Mills',
      'Calvert',
      'Cameron Park',
      'Cameron Park Colonia',
      'Camp Swift',
      'Canyon Lake',
      'Carrizo Springs',
      'Castle Hills',
      'Castroville',
      'Cedar Park',
      'Center',
      'Central Gardens',
      'César Chávez',
      'Channelview',
      'Chico',
      'China',
      'Cibolo',
      'Cinco Ranch',
      'Cisco',
      'Citrus City',
      'Clear Lake Shores',
      'Cleburne',
      'Cloverleaf',
      'Clute',
      'Cockrell Hill',
      'Coldspring',
      'Coleman',
      'College Station',
      'Colleyville',
      'Combes',
      'Combine',
      'Comfort',
      'Conroe',
      'Converse',
      'Cooper',
      'Coppell',
      'Copper Canyon',
      'Copperas Cove',
      'Corpus Christi',
      'Corrigan',
      'Corsicana',
      'Cottonwood Shores',
      'Cotulla',
      'Crandall',
      'Crockett',
      'Crosby',
      'Cross Mountain',
      'Crowell',
      'Cuero',
      'Cut and Shoot',
      'Cypress',
      'Daingerfield',
      'Dalworthington Gardens',
      'De Leon',
      'DeCordova',
      'DeSoto',
      'Denison',
      'Deweyville',
      'Diboll',
      'Dickinson',
      'Doffing',
      'Donna',
      'Doolittle',
      'Double Oak',
      'Dripping Springs',
      'Duncanville',
      'Eagle Mountain',
      'Early',
      'East Bernard',
      'Eastland',
      'Edcouch',
      'Edgecliff Village',
      'El Campo',
      'El Cenizo',
      'El Lago',
      'Electra',
      'Elkhart',
      'Elmendorf',
      'Elsa',
      'Emory',
      'Encantada-Ranchito-El Calaboz',
      'Ennis',
      'Escobares',
      'Euless',
      'Evadale',
      'Everman',
      'Fair Oaks Ranch',
      'Fairchilds',
      'Falcon Lake Estates',
      'Falfurrias',
      'Fannett',
      'Farmers Branch',
      'Fate',
      'Ferris',
      'Fifth Street',
      'Flatonia',
      'Floresville',
      'Flower Mound',
      'Forest Hill',
      'Forney',
      'Fort Worth',
      'Frankston',
      'Fredericksburg',
      'Freer',
      'Fresno',
      'Friendswood',
      'Frisco',
      'Fulshear',
      'Galena Park',
      'Galveston',
      'Ganado',
      'Garden Ridge',
      'Garfield',
      'Garland',
      'George West',
      'Gholson',
      'Giddings',
      'Gilmer',
      'Gladewater',
      'Glen Rose',
      'Glenn Heights',
      'Godley',
      'Goldthwaite',
      'Goliad',
      'Granbury',
      'Grand Prairie',
      'Grand Saline',
      'Granger',
      'Granite Shoals',
      'Grapeland',
      'Grapevine',
      'Greatwood',
      'Green Valley Farms',
      'Gregory',
      'Groves',
      'Groveton',
      'Gun Barrel City',
      'Gunter',
      'Hallettsville',
      'Haltom City',
      'Harker Heights',
      'Harlingen',
      'Haslet',
      'Hawkins',
      'Hearne',
      'Heath',
      'Hebbronville',
      'Hedwig Village',
      'Heidelberg',
      'Helotes',
      'Hemphill',
      'Hempstead',
      'Henrietta',
      'Hewitt',
      'Hickory Creek',
      'Hico',
      'Hidalgo',
      'Hideaway',
      'Highland Park',
      'Highland Village',
      'Highlands',
      'Hill Country Village',
      'Hilltop Lakes',
      'Hitchcock',
      'Holiday Lakes',
      'Holland',
      'Holliday',
      'Hollywood Park',
      'Hondo',
      'Honey Grove',
      'Hooks',
      'Hornsby Bend',
      'Horseshoe Bay',
      'Howe',
      'Hubbard',
      'Hudson Bend',
      'Hudson Oaks',
      'Hughes Springs',
      'Humble',
      'Hunters Creek Village',
      'Huntington',
      'Hurst',
      'Hutchins',
      'Hutto',
      'Ingleside',
      'Ingram',
      'Iowa Colony',
      'Iowa Park',
      'Irving',
      'Italy',
      'Itasca',
      'Jacinto City',
      'Jamaica Beach',
      'Jarrell',
      'Jersey Village',
      'Jewett',
      'Jollyville',
      'Jones Creek',
      'Josephine',
      'Joshua',
      'Jourdanton',
      'Junction',
      'Justin',
      'Karnes City',
      'Kaufman',
      'Katy',
      'Keene',
      'Keller',
      'Kemah',
      'Kemp',
      'Kenedy',
      'Kennedale',
      'Kempner',
      'Kerens',
      'Kerrville',
      'Kilgore',
      'Killeen',
      'Kirby',
      'Kirbyville',
      'Knox City',
      'Kountze',
      'Krugerville',
      'Krum',
      'Kyle',
      'La Blanca',
      'La Coste',
      'La Feria',
      'La Grulla',
      'La Homa',
      'La Joya',
      'La Marque',
      'La Paloma',
      'La Porte',
      'La Pryor',
      'La Vernia',
      'La Villa',
      'Lacy-Lakeview',
      'Lago Vista',
      'Laguna Heights',
      'Laguna Park',
      'Laguna Vista',
      'Lake Brownwood',
      'Lake Dallas',
      'Lake Jackson',
      'Lake Kiowa',
      'Lake Dunlap',
      'Lakehills',
      'Lakeway',
      'Lampasas',
      'Laredo',
      'Las Lomas',
      'Lasara',
      'Laureles',
      'Lavon',
      'League City',
      'Leakey',
      'Leander',
      'Leon Valley',
      'Leonard',
      'Liberty City',
      'Liberty Hill',
      'Little Elm',
      'Little River-Academy',
      'Llano',
      'Llano Grande',
      'Lone Star',
      'Lopezville',
      'Lorena',
      'Los Fresnos',
      'Los Indios',
      'Lost Creek',
      'Lowry Crossing',
      'Lucas',
      'Lufkin',
      'Lyford',
      'Lytle',
      'Mabank',
      'Malakoff',
      'Manchaca',
      'Manor',
      'Manvel',
      'Marble Falls',
      'Markham',
      'Marlin',
      'Mart',
      'Martindale',
      'Mathis',
      'Mauriceville',
      'McAllen',
      'McKinney',
      'McLendon-Chisholm',
      'McQueeney',
      'Meadowlakes',
      'Meadows Place',
      'Melissa',
      'Menard',
      'Mercedes',
      'Mesquite',
      'Mexia',
      'Midlothian',
      'Midway North',
      'Midway South',
      'Mila Doce',
      'Milam',
      'Mineola',
      'Mineral Wells',
      'Mission Bend',
      'Missouri City',
      'Mont Belvieu',
      'Montague',
      'Monte Alto',
      'Morgans Point Resort',
      'Muenster',
      'Munday',
      'Muniz',
      'Murillo Colonia',
      'Nacogdoches',
      'Nash',
      'Nassau Bay',
      'Navasota',
      'Nederland',
      'Needville',
      'New Braunfels',
      'New Summerfield',
      'New Territory',
      'New Waverly',
      'Nixon',
      'Nocona',
      'Nolanville',
      'North Alamo',
      'North Richland Hills',
      'Northcrest',
      'Nurillo',
      'Oak Leaf',
      'Oak Point',
      'Oak Ridge North',
      'Oak Trail Shores',
      'Odem',
      'Old River-Winfree',
      'Olivarez',
      'Olmito',
      'Olmos Park',
      'Onalaska',
      'Onion Creek',
      'Orange',
      'Orange Grove',
      'Ore City',
      'Overton',
      'Ovilla',
      'Oyster Creek',
      'Paint Rock',
      'Palacios',
      'Palmer',
      'Palmhurst',
      'Palmview',
      'Palmview South',
      'Palo Pinto',
      'Panorama Village',
      'Pantego',
      'Patton Village',
      'Pearland',
      'Pearsall',
      'Pecan Acres',
      'Pecan Grove',
      'Pecan Plantation',
      'Penitas',
      'Perezville',
      'Pflugerville',
      'Pharr',
      'Pilot Point',
      'Pine Island',
      'Pinewood Estates',
      'Piney Point Village',
      'Pleak',
      'Plum Grove',
      'Ponder',
      'Port Aransas',
      'Port Arthur',
      'Port Isabel',
      'Port Lavaca',
      'Port Neches',
      "Port O'Connor",
      'Porter Heights',
      'Poteet',
      'Poth',
      'Pottsboro',
      'Powderly',
      'Prairie View',
      'Premont',
      'Primera',
      'Progreso',
      'Prosper',
      'Quanah',
      'Queen City',
      'Quinlan',
      'Rancho Alegre',
      'Rancho Viejo',
      'Ranger',
      'Raymondville',
      'Red Lick',
      'Redwater',
      'Redwood',
      'Refugio',
      'Rendon',
      'Rhome',
      'Ricardo',
      'Richardson',
      'Richland Hills',
      'Riesel',
      'Rio Bravo',
      'Rio Grande City',
      'Rio Hondo',
      'River Oaks',
      'Robstown',
      'Rockdale',
      'Rockwall',
      'Rollingwood',
      'Roman Forest',
      'Roma-Los Saenz',
      'Rosebud',
      'Rosenberg',
      'Rosharon',
      'Round Rock',
      'Rowlett',
      'Royse City',
      'Runaway Bay',
      'Runge',
      'Rusk',
      'Sabinal',
      'Sachse',
      'Saginaw',
      'Saint Hedwig',
      'Saint Jo',
      'Salado',
      'Sam Rayburn',
      'San Augustine',
      'San Benito',
      'San Carlos',
      'San Diego',
      'San Juan',
      'San Leon',
      'San Marcos',
      'San Saba',
      'Sanger',
      'Sansom Park',
      'Santa Anna',
      'Santa Fe',
      'Santa Rosa',
      'Sarita',
      'Scenic Oaks',
      'Schertz',
      'Schulenburg',
      'Scissors',
      'Seadrift',
      'Seagoville',
      'Sealy',
      'Seguin',
      'Serenada',
      'Seven Points',
      'Shady Hollow',
      'Shady Shores',
      'Shamrock',
      'Shavano Park',
      'Sheldon',
      'Shepherd',
      'Sherwood Shores',
      'Shiner',
      'Shoreacres',
      'Sienna Plantation',
      'Siesta Shores',
      'Silsbee',
      'Sinton',
      'Sour Lake',
      'South Alamo',
      'South Houston',
      'South Padre Island',
      'Southlake',
      'Southmayd',
      'Southside Place',
      'Splendora',
      'Spring',
      'Spring Valley',
      'Springtown',
      'Stafford',
      'Stamford',
      'Stephenville',
      'Stockdale',
      'Stowell',
      'Sugar Land',
      'Sullivan City',
      'Sunnyvale',
      'Sweeny',
      'Taft Southwest (historical)',
      'Talty',
      'Tatum',
      'Taylor Lake Village',
      'Teague',
      'Tenaha',
      'Terrell',
      'Terrell Hills',
      'Texas City',
      'The Colony',
      'The Hills',
      'The Woodlands',
      'Three Rivers',
      'Throckmorton',
      'Tilden',
      'Timberwood Park',
      'Timpson',
      'Tom Bean',
      'Tomball',
      'Tool',
      'Trophy Club',
      'Troup',
      'Tye',
      'Tyler',
      'Uhland',
      'Universal City',
      'Uvalde',
      'Uvalde Estates',
      'Valley Mills',
      'Van',
      'Van Alstyne',
      'Van Vleck',
      'Venus',
      'Vidor',
      'Von Ormy',
      'Waco',
      'Wake Village',
      'Waller',
      'Waskom',
      'Watauga',
      'Waxahachie',
      'Webster',
      'Weimar',
      'Wells Branch',
      'Weslaco',
      'West',
      'West Lake Hills',
      'West Livingston',
      'West Orange',
      'West Sharyland',
      'West Tawakoni',
      'West University Place',
      'Westworth',
      'White Settlement',
      'Whitehouse',
      'Whitewright',
      'Whitney',
      'Wichita Falls',
      'Wild Peach Village',
      'Willis',
      'Willow Park',
      'Wills Point',
      'Wilmer',
      'Wimberley',
      'Windemere',
      'Windcrest',
      'Winnie',
      'Winters',
      'Wolfe City',
      'Woodbranch',
      'Woodcreek',
      'Woodway',
      'Wortham',
      'Wyldwood',
      'Wylie',
      'Yoakum',
      'Zapata',
      'Abingdon',
      'Accomac',
      'Adwolf',
      'Altavista',
      'Amelia Court House',
      'Amherst',
      'Annandale',
      'Appalachia',
      'Apple Mountain Lake',
      'Appomattox',
      'Aquia Harbour',
      'Baileys Crossroads',
      'Bassett',
      'Basye',
      'Bealeton',
      'Belle Haven',
      'Bellwood',
      'Bensley',
      'Big Stone Gap',
      'Blackstone',
      'Bland',
      'Bluefield',
      'Bon Air',
      'Boydton',
      'Bracey',
      'Brambleton',
      'Brandermill',
      'Bridgewater',
      'Brightwood',
      'Broadlands',
      'Brookneal',
      'Buckhall',
      'Bull Run',
      'Bull Run Mountain Estates',
      'Burke',
      'Cana',
      'Cape Charles',
      'Castlewood',
      'Central Garage',
      'Chamberlayne',
      'Chantilly',
      'Charles City',
      'Charlotte Court House',
      'Charlottesville',
      'Chase City',
      'Chatmoss',
      'Chesapeake',
      'Chesterfield Court House',
      'Chilhowie',
      'Chincoteague',
      'Christiansburg',
      'Claypool Hill',
      'Clifton Forge',
      'Clintwood',
      'Coeburn',
      'Colonial Beach',
      'Countryside',
      'Courtland',
      'Crewe',
      'Crimora',
      'Crozet',
      'Culpeper',
      'Dahlgren',
      'Dale City',
      'Deltaville',
      'Dinwiddie',
      'Dooms',
      'Dranesville',
      'Dryden',
      'Dulles Town Center',
      'Dumfries',
      'Dumbarton',
      'Dunn Loring',
      'East Hampton',
      'East Lexington',
      'East Highland Park',
      'Eastville',
      'Ettrick',
      'Exmore',
      'Fairfax Station',
      'Fairlawn',
      'Falls Church',
      'Ferrum',
      'Fishersville',
      'Floris',
      'Floyd',
      'Fort Lee',
      'Franconia',
      'Front Royal',
      'Galax',
      'Gate City',
      'Glade Spring',
      'Glen Allen',
      'Gloucester Courthouse',
      'Gloucester Point',
      'Goochland',
      'Greenbriar',
      'Grottoes',
      'Grundy',
      'Hampden Sydney',
      'Hayfield',
      'Haymarket',
      'Heathsville',
      'Henry Fork',
      'Herndon',
      'Highland Springs',
      'Hillsville',
      'Hollins',
      'Hollymead',
      'Honaker',
      'Horse Pasture',
      'Hurt',
      'Hybla Valley',
      'Idylwood',
      'Independent Hill',
      'Isle of Wight',
      'Jolivue',
      'Kenbridge',
      'Kilmarnock',
      'King George',
      'King William',
      'King and Queen Court House',
      'Kings Park',
      'Lake Barcroft',
      'Lake Monticello',
      'Lake Ridge',
      'Laymantown',
      'Lincolnia',
      'Linton Hall',
      'Loch Lomond',
      'Lorton',
      'Lovettsville',
      'Lovingston',
      'Lowes Island',
      'Lunenburg',
      'Luray',
      'Lyndhurst',
      'Madison Heights',
      'Manassas',
      'Manassas Park',
      'Mantua',
      'Massanetta Springs',
      'Massanutten',
      'Matoaca',
      'McLean',
      'Merrifield',
      'Merrimac',
      'Montclair',
      'Montrose',
      'Montross',
      'Motley',
      'Mount Hermon',
      'Mount Jackson',
      'Mountain Road',
      'Narrows',
      'Nellysford',
      'New Baltimore',
      'Newington',
      'Newport News',
      'Nokesville',
      'Norfolk',
      'North Shore',
      'North Springfield',
      'Oakton',
      'Occoquan',
      'Onancock',
      'Pannill Fork',
      'Passapatanzy',
      'Patrick Springs',
      'Pearisburg',
      'Pennington Gap',
      'Pimmit Hills',
      'Poquoson',
      'Portsmouth Heights',
      'Potomac Mills',
      'Powhatan',
      'Prices Fork',
      'Prince George',
      'Purcellville',
      'Radford',
      'Raven',
      'Ravensworth',
      'Reston',
      'Rosslyn',
      'Ruckersville',
      'Rural Retreat',
      'Rushmere',
      'Rustburg',
      'Saltville',
      'Sandston',
      'Seven Corners',
      'Shawnee Land',
      'Shawsville',
      'Shenandoah Farms',
      'Short Pump',
      'South Boston',
      'South Hill',
      'South Riding',
      'South Suffolk',
      'Spotsylvania',
      'Spotsylvania Courthouse',
      'Stanardsville',
      'Stanleytown',
      'Stephens City',
      'Stuarts Draft',
      'Sudley',
      'Suffolk',
      'Sugarland Run',
      'Surry',
      'Sussex',
      'Tappahannock',
      'Timberlake',
      'Timberville',
      'Triangle',
      'Tuckahoe',
      'Tysons Corner',
      'Union Hall',
      'University Center',
      'Virginia Beach',
      'Warm Springs',
      'Wattsville',
      'Weber City',
      'West Lynchburg',
      'West Springfield',
      'Weyers Cave',
      'Wolf Trap',
      'Woodbridge',
      'Wyndham',
      'Wytheville',
      'Yorkshire',
      'Alderson',
      'Alum Creek',
      'Ansted',
      'Barboursville',
      'Barrackville',
      'Beaver',
      'Beckley',
      'Belington',
      'Berkeley Springs',
      'Blennerhassett',
      'Bluewell',
      'Bradley',
      'Brush Fork',
      'Buckhannon',
      'Ceredo',
      'Chapmanville',
      'Charles Town',
      'Cheat Lake',
      'Clendenin',
      'Coal City',
      'Coal Fork',
      'Crab Orchard',
      'Craigsville',
      'Cross Lanes',
      'Culloden',
      'Daniels',
      'Despard',
      'Eleanor',
      'Elizabeth',
      'Elkview',
      'Fairlea',
      'Fort Ashby',
      'Gilbert Creek',
      'Glenville',
      'Grafton',
      'Granville',
      'Grantsville',
      'Hamlin',
      'Harrisville',
      'Hurricane',
      'Kenova',
      'Keyser',
      'Kingwood',
      'Lavalette',
      'Lesage',
      'Lubeck',
      'Mabscott',
      'MacArthur',
      'Mallory',
      'Mannington',
      'Marlinton',
      'Marmet',
      'Martinsburg',
      'McMechen',
      'Middlebourne',
      'Monongah',
      'Moorefield',
      'Moundsville',
      'Mount Hope',
      'Mullens',
      'New Martinsville',
      'Nitro',
      'Nutter Fort',
      'Oceana',
      'Paden City',
      'Parkersburg',
      'Pennsboro',
      'Philippi',
      'Pinch',
      'Point Pleasant',
      'Rainelle',
      'Rand',
      'Ranson',
      'Ravenswood',
      'Romney',
      'Ronceverte',
      'Saint Albans',
      'Shady Spring',
      'Shannondale',
      'Shepherdstown',
      'Shinnston',
      'Sissonville',
      'Sistersville',
      'Sophia',
      'Stanaford',
      'Stonewood',
      'Summersville',
      'Sutton',
      'Teays Valley',
      'Terra Alta',
      'Tornado',
      'Wayne',
      'Webster Springs',
      'Welch',
      'White Sulphur Springs',
      'Wiley Ford',
      'Williamson',
      'Elba',
      'Elmore',
      'Linn',
      'Villa Ridge',
      'Merchantville',
      'Oak Valley',
      'Coalgate',
      'Dilley',
      'Devine',
      'Wallis',
      'New Kent',
      'Bay City',
      'Wise',
      'Alabaster',
      'Albertville',
      'Alexander City',
      'Aliceville',
      'Andalusia',
      'Anniston',
      'Arab',
      'Argo',
      'Ashford',
      'Atmore',
      'Attalla',
      'Ballplay',
      'South Coventry',
      'Tariffville',
      'Cos Cob',
      'Coventry Lake',
      'Glendale Heights',
      'Lena',
      'Cromwell',
      'East Falmouth',
      'North Andover',
      'Revere',
      'West Barnstable',
      'Alfred',
      'Danielson',
      'Rockland',
      'Inkster',
      'Edina',
      'Yardville',
      'Camillus',
      'Rush',
      'Wayland',
      'Watervliet',
      'East Brooklyn',
      'Cleveland Heights',
      'Genoa',
      'East Haddam',
      'East Hartford',
      'East Haven',
      'East Norwalk',
      'Brussels',
      'Ellington',
      'Gales Ferry',
      'Glastonbury',
      'Glastonbury Center',
      'Greenwich',
      'Groton',
      'Guilford',
      'Hamden',
      'Hazardville',
      'Heritage Village',
      'Higganum',
      'Jewett City',
      'Kent',
      'Killingly Center',
      'Killingworth',
      'Lake Pocotopaug',
      'Ledyard',
      'Long Hill',
      'Mansfield City',
      'Meriden',
      'Montville Center',
      'Moodus',
      'Moosup',
      'Mystic',
      'Naugatuck',
      'New Britain',
      'New Canaan',
      'New Fairfield',
      'New Milford',
      'New Preston',
      'Niantic',
      'Noank',
      'North Branford',
      'North Granby',
      'North Grosvenor Dale',
      'North Haven',
      'North Stamford',
      'Norwalk',
      'Norwich',
      'Old Greenwich',
      'Old Mystic',
      'Old Saybrook',
      'Pawcatuck',
      'Pemberwick',
      'Poquonock Bridge',
      'Preston City',
      'Putnam',
      'Quinebaug',
      'Ridgefield',
      'Salmon Brook',
      'Saybrook Manor',
      'Shelton',
      'Sherwood Manor',
      'Somers',
      'South Windham',
      'South Windsor',
      'South Woodstock',
      'Southbury',
      'Southwood Acres',
      'Stafford Springs',
      'Storrs',
      'Suffield Depot',
      'Terramuggus',
      'Terryville',
      'Thompson',
      'Thompsonville',
      'Tolland',
      'Torrington',
      'Trumbull',
      'Uncasville',
      'Wallingford',
      'Waterbury',
      'Wauregan',
      'Weatogue',
      'West Hartford',
      'West Haven',
      'West Simsbury',
      'West Torrington',
      'Wethersfield',
      'Willimantic',
      'Wilton',
      'Winchester Center',
      'Windham',
      'Windsor Locks',
      'Winsted',
      'Wolcott',
      'Woodmont',
      'Ackley',
      'Akron',
      'Albia',
      'Algona',
      'Allison',
      'Alta',
      'Altoona',
      'Anamosa',
      'Ankeny',
      'Aplington',
      'Arnolds Park',
      'Asbury',
      'Atlantic',
      'Avoca',
      'Belmond',
      'Bettendorf',
      'Blue Grass',
      'Bondurant',
      'Britt',
      'Buffalo (historical)',
      'Camanche',
      'Carroll',
      'Carter Lake',
      'Cascade',
      'Cedar Falls',
      'Cedar Rapids',
      'Chariton',
      'Clarinda',
      'Clarion',
      'Clear Lake',
      'Clive',
      'Columbus Junction',
      'Conrad',
      'Coon Rapids',
      'Coralville',
      'Council Bluffs',
      'Cresco',
      'Creston',
      'Dakota City',
      'Dallas Center',
      'Decorah',
      'Des Moines',
      'Dike',
      'Dubuque',
      'Dyersville',
      'Dysart',
      'Eagle Grove',
      'Earlham',
      'Eldora',
      'Eldridge',
      'Elk Run Heights',
      'Elkader',
      'Ely',
      'Emmetsburg',
      'Epworth',
      'Estherville',
      'Evansdale',
      'Fairbank',
      'Farley',
      'Fort Dodge',
      'Fort Madison',
      'George',
      'Gilbert',
      'Glidden',
      'Greene',
      'Grimes',
      'Grinnell',
      'Grundy Center',
      'Guthrie Center',
      'Guttenberg',
      'Hartley',
      'Hawarden',
      'Holstein',
      'Hull',
      'Huxley',
      'Ida Grove',
      'Iowa City',
      'Iowa Falls',
      'Jewell',
      'Kalona',
      'Keokuk',
      'Keosauqua',
      'Kingsley',
      'La Porte City',
      'Lake Mills',
      'Lamoni',
      'Le Claire',
      'Le Mars',
      'Lenox',
      'Leon',
      'Lone Tree',
      'Madrid',
      'Maharishi Vedic City',
      'Manly',
      'Manson',
      'Mapleton',
      'Maquoketa',
      'Marcus',
      'Marengo',
      'Marshalltown',
      'Mason City',
      'Mediapolis',
      'Melcher-Dallas',
      'Missouri Valley',
      'Monona',
      'Mount Ayr',
      'Moville',
      'Muscatine',
      'Nashua',
      'New Hampton',
      'New Sharon',
      'Nora Springs',
      'North English',
      'North Liberty',
      'Northwood',
      'Oelwein',
      'Onawa',
      'Osage',
      'Ottumwa',
      'Palo',
      'Panora',
      'Park View',
      'Paullina',
      'Pella',
      'Peosta',
      'Postville',
      'Prairie City',
      'Primghar',
      'Reinbeck',
      'Remsen',
      'Robins',
      'Rock Rapids',
      'Rock Valley',
      'Rockwell City',
      'Sac City',
      'Saint Ansgar',
      'Sanborn',
      'Saylorville',
      'Sergeant Bluff',
      'Shell Rock',
      'Sidney',
      'Sigourney',
      'Sioux Center',
      'Sioux City',
      'Solon',
      'Spirit Lake',
      'State Center',
      'Storm Lake',
      'Story City',
      'Strawberry Point',
      'Tama',
      'Tiffin',
      'Traer',
      'Tripoli',
      'University Heights',
      'Urbandale',
      'Van Meter',
      'Villisca',
      'Walcott',
      'Walford',
      'Wapello',
      'Waukee',
      'Waukon',
      'Webster City',
      'Wellman',
      'West Branch',
      'West Burlington',
      'West Des Moines',
      'Windsor Heights',
      'Winterset',
      'Albany Park',
      'Alsip',
      'Amboy',
      'Antioch',
      'Arlington Heights',
      'Astoria',
      'Bannockburn',
      'Barrington Hills',
      'Beach Park',
      'Beardstown',
      'Beecher',
      'Belmont Cragin',
      'Belvidere',
      'Bensenville',
      'Berwyn',
      'Big Rock',
      'Blue Island',
      'Bolingbrook',
      'Boulder Hill',
      'Bourbonnais',
      'Braidwood',
      'Bridgeview',
      'Brighton Park',
      'Broadview',
      'Buffalo Grove',
      'Bull Valley',
      'Burbank',
      'Burnham',
      'Burr Ridge',
      'Calumet City',
      'Calumet Park',
      'Camp Point',
      'Capron',
      'Carbon Cliff',
      'Carol Stream',
      'Carpentersville',
      'Catlin',
      'Champaign',
      'Channahon',
      'Channel Lake',
      'Chebanse',
      'Chenoa',
      'Cherry Valley',
      'Chicago',
      'Chicago Heights',
      'Chicago Lawn',
      'Chicago Ridge',
      'Cicero',
      'Clarendon Hills',
      'Coal Valley',
      'Colchester',
      'Colona',
      'Cortland',
      'Crest Hill',
      'Crete',
      'Crystal Lawns',
      'Danvers',
      'Davis Junction',
      'DeKalb',
      'Deerfield',
      'Delavan',
      'Depue',
      'Des Plaines',
      'Diamond',
      'Dixmoor',
      'Dolton',
      'Downers Grove',
      'Durand',
      'Dwight',
      'Earlville',
      'East Dundee',
      'East Dubuque',
      'East Garfield Park',
      'East Hazel Crest',
      'East Moline',
      'East Peoria',
      'El Paso',
      'Elburn',
      'Elk Grove Village',
      'Elmhurst',
      'Elmwood Park',
      'Evanston',
      'Evergreen Park',
      'Fairbury',
      'Farmer City',
      'Fisher',
      'Flanagan',
      'Flossmoor',
      'Ford Heights',
      'Forest Lake',
      'Forrest',
      'Forreston',
      'Fox Lake',
      'Fox Lake Hills',
      'Fox River Grove',
      'Frankfort Square',
      'Franklin Park',
      'Gage Park',
      'Gages Lake',
      'Galesburg',
      'Galva',
      'Geneseo',
      'Germantown Hills',
      'Gibson City',
      'Gilberts',
      'Gilman',
      'Glasford',
      'Glen Ellyn',
      'Glenview',
      'Goodings Grove',
      'Grand Boulevard',
      'Grandwood Park',
      'Grant Park',
      'Grayslake',
      'Green Oaks',
      'Green Rock',
      'Gridley',
      'Gurnee',
      'Hainesville',
      'Hampshire',
      'Hanna City',
      'Hanover Park',
      'Harvard',
      'Harwood Heights',
      'Hawthorn Woods',
      'Hazel Crest',
      'Hennepin',
      'Henry',
      'Herscher',
      'Heyworth',
      'Highwood',
      'Hillcrest',
      'Hillside',
      'Hinckley',
      'Hinsdale',
      'Hodgkins',
      'Hoffman Estates',
      'Homer Glen',
      'Hometown',
      'Hoopeston',
      'Huntley',
      'Hyde Park',
      'Indian Head Park',
      'Ingalls Park',
      'Irving Park',
      'Island Lake',
      'Johnsburg',
      'Joliet',
      'Kankakee',
      'Kenilworth',
      'Kewanee',
      'Kildeer',
      'Kirkland',
      'Knollwood',
      'La Grange Park',
      'La Harpe',
      'La Salle',
      'Lacon',
      'Ladd',
      'Lake Barrington',
      'Lake Bluff',
      'Lake Catherine',
      'Lake Summerset',
      'Lake Villa',
      'Lake Zurich',
      'Lake in the Hills',
      'Lake of the Woods',
      'Lakemoor',
      'Lakewood Shores',
      'Lanark',
      'Le Roy',
      'Lemont',
      'Lewistown',
      'Libertyville',
      'Lily Lake',
      'Lincoln Park',
      'Lincoln Square',
      'Lincolnshire',
      'Lincolnwood',
      'Lindenhurst',
      'Lisle',
      'Logan Square',
      'Lombard',
      'Long Grove',
      'Long Lake',
      'Loves Park',
      'Lower West Side',
      'Lynwood',
      'Machesney Park',
      'Mackinaw',
      'Macomb',
      'Mahomet',
      'Malta',
      'Manito',
      'Manteno',
      'Maple Park',
      'Maroa',
      'Marquette Heights',
      'Marseilles',
      'Matteson',
      'Maywood',
      'McCullom Lake',
      'McHenry',
      'McKinley Park',
      'Medinah',
      'Mendota',
      'Merrionette Park',
      'Metamora',
      'Minier',
      'Minonk',
      'Minooka',
      'Mokena',
      'Moline',
      'Momence',
      'Monee',
      'Monmouth',
      'Morgan Park',
      'Morrison',
      'Morton Grove',
      'Mount Carroll',
      'Mount Greenwood',
      'Mount Morris',
      'Mount Prospect',
      'Mount Pulaski',
      'Mundelein',
      'Naperville',
      'Nauvoo',
      'Near North Side',
      'Near South Side',
      'New City',
      'New Lenox',
      'Niles',
      'Normal',
      'Norridge',
      'North Aurora',
      'North Barrington',
      'North Center',
      'North Chicago',
      'North Lawndale',
      'North Pekin',
      'North Peoria',
      'North Riverside',
      'Oak Brook',
      'Oak Forest',
      'Oak Lawn',
      'Oakbrook Terrace',
      'Oakwood Hills',
      'Oglesby',
      'Olympia Fields',
      'Onarga',
      'Oquawka',
      'Orion',
      'Orland Hills',
      'Orland Park',
      'Palatine',
      'Palos Heights',
      'Palos Hills',
      'Palos Park',
      'Park Forest',
      'Park Ridge',
      'Paxton',
      'Pecatonica',
      'Pekin',
      'Peoria',
      'Peoria Heights',
      'Peotone',
      'Peru',
      'Philo',
      'Phoenix',
      'Pingree Grove',
      'Pistakee Highlands',
      'Polo',
      'Pontiac',
      'Poplar Grove',
      'Port Barrington',
      'Port Byron',
      'Portage Park',
      'Posen',
      'Preston Heights',
      'Prophetstown',
      'Prospect Heights',
      'Rantoul',
      'Richton Park',
      'River Forest',
      'River Grove',
      'Riverwoods',
      'Rock Falls',
      'Rockton',
      'Rogers Park',
      'Rolling Meadows',
      'Romeoville',
      'Roscoe',
      'Roselle',
      'Rosemont',
      'Round Lake',
      'Round Lake Beach',
      'Round Lake Heights',
      'Round Lake Park',
      'Saint Anne',
      'St. Charles',
      'Sandwich',
      'Sauk Village',
      'Savanna',
      'Savoy',
      'Schaumburg',
      'Schiller Park',
      'Shorewood',
      'Silvis',
      'Skokie',
      'Sleepy Hollow',
      'Somonauk',
      'South Barrington',
      'South Beloit',
      'South Chicago',
      'South Chicago Heights',
      'South Elgin',
      'South Holland',
      'South Lawndale',
      'South Pekin',
      'Steger',
      'Stickney',
      'Stillman Valley',
      'Stone Park',
      'Streamwood',
      'Streator',
      'Sugar Grove',
      'Sycamore',
      'Third Lake',
      'Thomasboro',
      'Thornton',
      'Tilton',
      'Tinley Park',
      'Toluca',
      'Toulon',
      'Tower Lake',
      'Tremont',
      'Twin Grove',
      'Uptown',
      'Venetian Village',
      'Vernon Hills',
      'Villa Park',
      'Volo',
      'Wadsworth',
      'Walnut',
      'Wasco',
      'Washburn',
      'Waterman',
      'Watseka',
      'Wauconda',
      'Waukegan',
      'West Chicago',
      'West Dundee',
      'West Elsdon',
      'West Englewood',
      'West Garfield Park',
      'West Lawn',
      'West Peoria',
      'West Ridge',
      'West Town',
      'Western Springs',
      'Westmont',
      'Wheeling',
      'Willowbrook',
      'Wilmette',
      'Winnebago',
      'Winnetka',
      'Winthrop Harbor',
      'Wonder Lake',
      'Wood Dale',
      'Woodridge',
      'Worth',
      'Yorkville',
      'Zion',
      'Angola',
      'Argos',
      'Attica',
      'Avilla',
      'Bass Lake',
      'Battle Ground',
      'Berne',
      'Brookston',
      'Burns Harbor',
      'Cedar Lake',
      'Chesterton',
      'Churubusco',
      'Columbia City',
      'Crawfordsville',
      'Crown Point',
      'Culver',
      'DeMotte',
      'Delphi',
      'East Chicago',
      'Farmland',
      'Fish Lake',
      'Fort Wayne',
      'Fowler',
      'Frankton',
      'Garrett',
      'Gary',
      'Gas City',
      'Goodland',
      'Grabill',
      'Greentown',
      'Griffith',
      'Gulivoire Park',
      'Hartford City',
      'Hudson Lake',
      'Huntertown',
      'Indian Heights',
      'Kendallville',
      'Kentland',
      'Kingsford Heights',
      'Kokomo',
      'Koontz Lake',
      'Kouts',
      'Knox',
      'LaPorte',
      'Lagrange',
      'Lake Dalecarlia',
      'Lake Station',
      'Lakes of the Four Seasons',
      'Lapel',
      'Leo-Cedarville',
      'Ligonier',
      'Lynn',
      'Markle',
      'Merrillville',
      'Michigan City',
      'Mishawaka',
      'Monon',
      'Montpelier',
      'Morocco',
      'Muncie',
      'Munster',
      'Nappanee',
      'New Chicago',
      'Noblesville',
      'North Judson',
      'North Manchester',
      'North Webster',
      'Ogden Dunes',
      'Ossian',
      'Otterbein',
      'Parker City',
      'Pierceton',
      'Portage',
      'Porter',
      'Redkey',
      'Remington',
      'Rensselaer',
      'Rome City',
      'Roselawn',
      'Russiaville',
      'Schererville',
      'Shadeland',
      'Simonton Lake',
      'South Bend',
      'South Haven',
      'South Whitley',
      'Sweetser',
      'Syracuse',
      'Thorntown',
      'Trail Creek',
      'Tri-Lakes',
      'Veedersburg',
      'Wabash',
      'Wakarusa',
      'Walkerton',
      'Wanatah',
      'West Lafayette',
      'Westfield',
      'Whiting',
      'Winamac',
      'Winona Lake',
      'Wolcottville',
      'Woodburn',
      'Abington',
      'Acton',
      'Acushnet',
      'Adams',
      'Agawam',
      'Amesbury',
      'Amherst Center',
      'Ashburnham',
      'Ashby',
      'Ashfield',
      'Assonet',
      'Athol',
      'Attleboro',
      'Ayer',
      'Baldwinville',
      'Barnstable',
      'Barre',
      'Becket',
      'Belchertown',
      'Bellingham',
      'Berkley',
      'Bernardston',
      'Beverly Cove',
      'Billerica',
      'Bliss Corner',
      'Bolton',
      'Bondsville',
      'Bourne',
      'Boxborough',
      'Boxford',
      'Boylston',
      'Brewster',
      'Brimfield',
      'Brockton',
      'Brookline',
      'Buckland',
      'Buzzards Bay',
      'Carver',
      'Charlemont',
      'Charlton',
      'Chelmsford',
      'Chicopee',
      'Cochituate',
      'Cohasset',
      'Colrain',
      'Cordaville',
      'Cotuit',
      'Dedham',
      'Dennis',
      'Dennis Port',
      'Dighton',
      'Dracut',
      'Dudley',
      'Dunstable',
      'Duxbury',
      'East Bridgewater',
      'East Brookfield',
      'East Dennis',
      'East Douglas',
      'East Harwich',
      'East Longmeadow',
      'East Pepperell',
      'East Sandwich',
      'Eastham',
      'Easthampton',
      'Edgartown',
      'Erving',
      'Everett',
      'Fairhaven',
      'Fall River',
      'Fiskdale',
      'Fitchburg',
      'Foxborough',
      'Framingham',
      'Framingham Center',
      'Freetown',
      'Gill',
      'Gloucester',
      'Great Barrington',
      'Hadley',
      'Hamilton Worcester',
      'Hampden',
      'Hanson',
      'Harwich',
      'Harwich Port',
      'Hatfield',
      'Head of Westport',
      'Hingham',
      'Holbrook',
      'Holliston',
      'Holyoke',
      'Hopedale',
      'Hopkinton',
      'Housatonic',
      'Hubbardston',
      'Hyannis',
      'Ipswich',
      'Jamaica Plain',
      'Lanesborough',
      'Lee',
      'Leicester',
      'Leominster',
      'Leverett',
      'Littleton Common',
      'Longmeadow',
      'Lynnfield',
      'Manchester-by-the-Sea',
      'Marblehead',
      'Marshfield Hills',
      'Marstons Mills',
      'Mashpee',
      'Mattapoisett',
      'Maynard',
      'Medfield',
      'Medway',
      'Melrose',
      'Mendon',
      'Methuen',
      'Middleborough',
      'Middleton',
      'Millbury',
      'Millers Falls',
      'Millis',
      'Monson',
      'Monument Beach',
      'Nahant',
      'Nantucket',
      'Natick',
      'Needham',
      'New Bedford',
      'New Marlborough',
      'Newburyport',
      'North Adams',
      'North Amherst',
      'North Brookfield',
      'North Chicopee',
      'North Eastham',
      'North Falmouth',
      'North Lakeville',
      'North Pembroke',
      'North Plymouth',
      'North Reading',
      'North Scituate',
      'North Seekonk',
      'North Westport',
      'Northampton',
      'Northborough',
      'Northbridge',
      'Norwell',
      'Oak Bluffs',
      'Oakham',
      'Ocean Grove',
      'Onset',
      'Osterville',
      'Otis',
      'Pepperell',
      'Phillipston',
      'Plympton',
      'Pocasset',
      'Provincetown',
      'Randolph',
      'Raynham',
      'Raynham Center',
      'Rehoboth',
      'Rowley',
      'Royalston',
      'Rutland',
      'Sagamore',
      'Saugus',
      'Scituate',
      'Seekonk',
      'Shelburne',
      'Shelburne Falls',
      'Sherborn',
      'Shirley',
      'Shutesbury',
      'Smith Mills',
      'South Amherst',
      'South Ashburnham',
      'South Deerfield',
      'South Dennis',
      'South Hadley',
      'South Lancaster',
      'South Peabody',
      'South Yarmouth',
      'Southampton',
      'Southborough',
      'Southbridge',
      'Southwick',
      'Stoneham',
      'Stoughton',
      'Stow',
      'Sturbridge',
      'Sudbury',
      'Sunderland',
      'Swampscott',
      'Taunton',
      'Teaticket',
      'Templeton',
      'Tewksbury',
      'Topsfield',
      'Truro',
      'Turners Falls',
      'Tyngsboro',
      'Upton',
      'Uxbridge',
      'Vineyard Haven',
      'Wakefield',
      'Wales',
      'Walpole',
      'Waltham',
      'Ware',
      'Wareham Center',
      'Wellesley',
      'Wellfleet',
      'Wenham',
      'West Boylston',
      'West Bridgewater',
      'West Chatham',
      'West Concord',
      'West Brookfield',
      'West Dennis',
      'West Falmouth',
      'West Newbury',
      'West Stockbridge',
      'West Tisbury',
      'West Wareham',
      'West Yarmouth',
      'Westborough',
      'Westford',
      'Westhampton',
      'Weweantic',
      'Weymouth',
      'Whately',
      'White Island Shores',
      'Whitinsville',
      'Wilbraham',
      'Winchendon',
      'Winthrop',
      'Woburn',
      'Worcester',
      'Wrentham',
      'Yarmouth',
      'Yarmouth Port',
      'Appleton',
      'Arundel',
      'Bangor',
      'Bar Harbor',
      'Bath',
      'Belfast',
      'Belgrade',
      'Biddeford',
      'Boothbay',
      'Boothbay Harbor',
      'Brewer',
      'Bridgton',
      'Brownfield',
      'Brownville',
      'Buckfield',
      'Bucksport',
      'Calais',
      'Canaan',
      'Cape Neddick',
      'Caribou',
      'Castine',
      'Cherryfield',
      'Chesterville',
      'Chisholm',
      'Corinna',
      'Cornish',
      'Cornville',
      'Cumberland Center',
      'Damariscotta',
      'Deer Isle',
      'Dixfield',
      'Dixmont',
      'Dover-Foxcroft',
      'East Machias',
      'East Millinocket',
      'Eastport',
      'Eddington',
      'Edgecomb',
      'Eliot',
      'Falmouth Foreside',
      'Farmingdale',
      'Fort Fairfield',
      'Fort Kent',
      'Frenchville',
      'Friendship',
      'Fryeburg',
      'Gardiner',
      'Gorham',
      'Gouldsboro',
      'Greenbush',
      'Hallowell',
      'Harpswell Center',
      'Hermon',
      'Hodgdon',
      'Hollis Center',
      'Houlton',
      'Howland',
      'Jonesport',
      'Kenduskeag',
      'Kennebunk',
      'Kennebunkport',
      'Kingfield',
      'Kittery Point',
      'Kittery',
      'Lake Arrowhead',
      'Levant',
      'Lewiston',
      'Limerick',
      'Limestone',
      'Limington',
      'Lisbon Falls',
      'Livermore Falls',
      'Lovell',
      'Machias',
      'Machiasport',
      'Madawaska',
      'Mechanic Falls',
      'Milbridge',
      'Millinocket',
      'Milo',
      'Minot',
      'New Gloucester',
      'Nobleboro',
      'Norridgewock',
      'North Bath',
      'North Berwick',
      'North Windham',
      'Norway',
      'Ogunquit',
      'Old Orchard Beach',
      'Old Town',
      'Orland',
      'Orono',
      'Orrington',
      'Owls Head',
      'Palermo',
      'Parsonsfield',
      'Patten',
      'Penobscot',
      'Phippsburg',
      'Pittston',
      'Poland',
      'Presque Isle',
      'Readfield',
      'Rumford',
      'Sabattus',
      'Saco',
      'Sangerville',
      'Scarborough',
      'Searsmont',
      'Shapleigh',
      'Skowhegan',
      'South Berwick',
      'South Eliot',
      'South Paris',
      'South Portland',
      'South Portland Gardens',
      'South Sanford',
      'South Thomaston',
      'Springvale',
      'Steep Falls',
      'Stetson',
      'Steuben',
      'Stockton Springs',
      'Stonington',
      'Strong',
      'Swanville',
      'Topsham',
      'Turner',
      'Vassalboro',
      'Veazie',
      'Vinalhaven',
      'Waldoboro',
      'Waterboro',
      'Waterville',
      'Wells Beach Station',
      'West Paris',
      'West Scarborough',
      'Westbrook',
      'Whitefield',
      'Winslow',
      'Winterport',
      'Wiscasset',
      'Woolwich',
      'York Beach',
      'York Harbor',
      'Algonac',
      'Allegan',
      'Allen Park',
      'Almont',
      'Alpena',
      'Ann Arbor',
      'Argentine',
      'Armada',
      'Atlantic Mine',
      'Au Sable',
      'Auburn Hills',
      'Bad Axe',
      'Baraga',
      'Battle Creek',
      'Beaverton',
      'Belding',
      'Benton Harbor',
      'Benton Heights',
      'Berrien Springs',
      'Beulah',
      'Big Rapids',
      'Bingham Farms',
      'Birch Run',
      'Blissfield',
      'Bloomfield Hills',
      'Boyne City',
      'Bridgman',
      'Brown City',
      'Brownlee Park',
      'Burt',
      'Byron Center',
      'Cadillac',
      'Canadian Lakes',
      'Capac',
      'Carleton',
      'Caro',
      'Carson City',
      'Cass City',
      'Cassopolis',
      'Cedar Springs',
      'Center Line',
      'Charlevoix',
      'Cheboygan',
      'Chesaning',
      'Clare',
      'Clawson',
      'Clinton Township',
      'Coloma',
      'Colon',
      'Comstock Park',
      'Constantine',
      'Coopersville',
      'Corunna',
      'Croswell',
      'Crystal Falls',
      'Cutlerville',
      'Davison',
      'DeWitt',
      'Dearborn',
      'Dearborn Heights',
      'Detroit',
      'Detroit Beach',
      'Dimondale',
      'Dollar Bay',
      'Dowagiac',
      'Eagle River',
      'East Grand Rapids',
      'East Jordan',
      'East Lansing',
      'East Tawas',
      'Eastpointe',
      'Eaton Rapids',
      'Ecorse',
      'Edgemont Park',
      'Edmore',
      'Edwardsburg',
      'Elk Rapids',
      'Escanaba',
      'Essexville',
      'Evart',
      'Fair Plain',
      'Farmington Hills',
      'Fennville',
      'Ferrysburg',
      'Flint',
      'Flushing',
      'Fowlerville',
      'Frankenmuth',
      'Fraser',
      'Freeland',
      'Fruitport',
      'Gaylord',
      'Gibraltar',
      'Gladwin',
      'Goodrich',
      'Grand Blanc',
      'Grand Haven',
      'Grand Ledge',
      'Grand Rapids',
      'Grandville',
      'Grass Lake',
      'Grayling',
      'Greilickville',
      'Grosse Ile',
      'Grosse Pointe',
      'Grosse Pointe Farms',
      'Grosse Pointe Park',
      'Grosse Pointe Shores',
      'Grosse Pointe Woods',
      'Gwinn',
      'Hamtramck',
      'Harbor Beach',
      'Harbor Springs',
      'Harper Woods',
      'Hart',
      'Haslett',
      'Hastings',
      'Hazel Park',
      'Hemlock',
      'Holly',
      'Houghton',
      'Houghton Lake',
      'Howard City',
      'Howell',
      'Hubbard Lake',
      'Hudsonville',
      'Huntington Woods',
      'Imlay City',
      'Indian River',
      'Ionia',
      'Iron Mountain',
      'Iron River',
      'Ironwood',
      'Ishpeming',
      'Ithaca',
      'Jenison',
      'Kalamazoo',
      'Kalkaska',
      'Keego Harbor',
      'Kent City',
      'Kilmanagh',
      'Kingsford',
      "L'Anse",
      'Laingsburg',
      'Lake Fenton',
      'Lake Michigan Beach',
      'Lake Odessa',
      'Lake Orion',
      'Lakewood Club',
      'Lambertville',
      'Lapeer',
      'Lathrup Village',
      'Laurium',
      'Leslie',
      'Ludington',
      'Luna Pier',
      'Manistee',
      'Mancelona',
      'Manistique',
      'Manton',
      'Marcellus',
      'Marine City',
      'Marlette',
      'Marquette',
      'Mattawan',
      'Melvindale',
      'Menominee',
      'Michigan Center',
      'Middleville',
      'Mio',
      'Morenci',
      'Mount Clemens',
      'Munising',
      'Muskegon',
      'Muskegon Heights',
      'Napoleon',
      'Negaunee',
      'New Buffalo',
      'Newaygo',
      'North Branch',
      'North Muskegon',
      'Northview',
      'Northville',
      'Norton Shores',
      'Novi',
      'Okemos',
      'Ontonagon',
      'Orchard Lake',
      'Ortonville',
      'Otsego',
      'Ovid',
      'Owosso',
      'Parchment',
      'Paw Paw',
      'Paw Paw Lake',
      'Pearl Beach',
      'Petoskey',
      'Pigeon',
      'Pinckney',
      'Pinconning',
      'Plainwell',
      'Pleasant Ridge',
      'Port Huron',
      'Potterville',
      'Prudenville',
      'Quinnesec',
      'Rapid City',
      'Ravenna',
      'Redford',
      'Reed City',
      'Reese',
      'River Rouge',
      'Rochester Hills',
      'Rogers City',
      'Romeo',
      'Romulus',
      'Roosevelt Park',
      'Roscommon',
      'Royal Oak',
      'Saint Helen',
      'Saint Ignace',
      'Saint Louis',
      'Saline',
      'Sand Lake',
      'Sandusky',
      'Saranac',
      'Sault Ste. Marie',
      'Schoolcraft',
      'Scottville',
      'Sebewaing',
      'Shields',
      'Skidway Lake',
      'South Gull Lake',
      'South Lyon',
      'South Monroe',
      'South Rockwood',
      'Southfield',
      'Spring Arbor',
      'Saint Clair Shores',
      'Stambaugh, Iron River',
      'Standish',
      'Sterling Heights',
      'Swartz Creek',
      'Sylvan Lake',
      'Tawas City',
      'Temperance',
      'Traverse City',
      'Trowbridge Park',
      'Twin Lake',
      'Vandercook Lake',
      'Vassar',
      'Lake Isabella',
      'Wacousta',
      'Walled Lake',
      'Waterford',
      'Webberville',
      'West Ishpeming',
      'Westland',
      'White Cloud',
      'White Pigeon',
      'Whitmore Lake',
      'Wixom',
      'Wolf Lake',
      'Wolverine Lake',
      'Woodhaven',
      'Woodland Beach',
      'Wyandotte',
      'Ypsilanti',
      'Zeeland',
      'Zilwaukee',
      'Aitkin',
      'Albert Lea',
      'Anoka',
      'Apple Valley',
      'Arden Hills',
      'Atwater',
      'Babbitt',
      'Bagley',
      'Baudette',
      'Bayport',
      'Becker',
      'Bemidji',
      'Big Lake',
      'Birchwood',
      'Blooming Prairie',
      'Blue Earth',
      'Braham',
      'Brainerd',
      'Branch',
      'Breezy Point',
      'Brooklyn Center',
      'Canby',
      'Cannon Falls',
      'Carlton',
      'Center City',
      'Champlin',
      'Chanhassen',
      'Chaska',
      'Chatfield',
      'Chisago City',
      'Circle Pines',
      'Clara City',
      'Cloquet',
      'Cokato',
      'Coleraine',
      'Collegeville',
      'Cologne',
      'Columbia Heights',
      'Corcoran',
      'Cottage Grove',
      'Crookston',
      'Cross Lake',
      'Crystal',
      'Dassel',
      'Deephaven',
      'Delano',
      'Detroit Lakes',
      'Dilworth',
      'Dodge Center',
      'Dundas',
      'Eagan',
      'East Bethel',
      'East Grand Forks',
      'East Gull Lake',
      'Eden Prairie',
      'Eden Valley',
      'Elbow Lake',
      'Elk River',
      'Esko',
      'Eveleth',
      'Excelsior',
      'Eyota',
      'Falcon Heights',
      'Faribault',
      'Fergus Falls',
      'Fosston',
      'Frazee',
      'Fridley',
      'Fulda',
      'Glyndon',
      'Golden Valley',
      'Goodhue',
      'Goodview',
      'Grand Marais',
      'Grand Meadow',
      'Grant',
      'Hallock',
      'Ham Lake',
      'Harris',
      'Hawley',
      'Hector',
      'Hermantown',
      'Hibbing',
      'Howard Lake',
      'Hoyt Lakes',
      'International Falls',
      'Inver Grove Heights',
      'Isanti',
      'Ivanhoe',
      'Janesville',
      'Jordan',
      'Kasson',
      'Keewatin',
      'Kenyon',
      'La Crescent',
      'Lake Crystal',
      'Lake Elmo',
      'Zimmerman',
      'Lake Saint Croix Beach',
      'Lakefield',
      'Lakeville',
      'Lauderdale',
      'Le Center',
      'Le Sueur',
      'Lester Prairie',
      'Lindstrom',
      'Lino Lakes',
      'Little Canada',
      'Little Falls',
      'Long Prairie',
      'Lonsdale',
      'Madelia',
      'Madison Lake',
      'Mahnomen',
      'Mahtomedi',
      'Mantorville',
      'Maple Grove',
      'Maple Lake',
      'Maple Plain',
      'Mayer',
      'Menahga',
      'Mendota Heights',
      'Milaca',
      'Minneota',
      'Minnetonka',
      'Minnetonka Mills',
      'Minnetrista',
      'Montevideo',
      'Moose Lake',
      'Mora',
      'Mound',
      'Mounds View',
      'Mountain Iron',
      'Mountain Lake',
      'New Brighton',
      'New Prague',
      'New Richland',
      'New Ulm',
      'New York Mills',
      'Nicollet',
      'Nisswa',
      'North Mankato',
      'North Oaks',
      'North Saint Paul',
      'Norwood (historical)',
      'Norwood Young America',
      'Oak Park Heights',
      'Oakport',
      'Olivia',
      'Oronoco',
      'Osakis',
      'Osseo',
      'Owatonna',
      'Park Rapids',
      'Parkers Prairie',
      'Paynesville',
      'Pelican Rapids',
      'Pequot Lakes',
      'Perham',
      'Pierz',
      'Pine City',
      'Pipestone',
      'Plainview',
      'Prior Lake',
      'Proctor',
      'Red Lake',
      'Red Lake Falls',
      'Red Wing',
      'Redby',
      'Redwood Falls',
      'Renville',
      'Rice',
      'Richfield',
      'Robbinsdale',
      'Roseau',
      'Rush City',
      'Rushford',
      'Saint Anthony',
      'Saint Augusta',
      'Saint Bonifacius',
      'Saint Francis',
      'Saint Louis Park',
      'Saint Michael',
      'Saint Paul Park',
      'Saint Peter',
      'Sandstone',
      'Sartell',
      'Sauk Centre',
      'Sauk Rapids',
      'Scandia',
      'Shafer',
      'Shakopee',
      'Sherburn',
      'Shoreview',
      'Silver Bay',
      'Slayton',
      'Sleepy Eye',
      'South Saint Paul',
      'Spicer',
      'Spring Lake Park',
      'Spring Park',
      'Stacy',
      'Staples',
      'Starbuck',
      'Taylors Falls',
      'Thief River Falls',
      'Tonka Bay',
      'Tracy',
      'Truman',
      'Two Harbors',
      'Vadnais Heights',
      'Wabasha',
      'Waconia',
      'Wadena',
      'Waite Park',
      'Wanamingo',
      'Warroad',
      'Waseca',
      'Wayzata',
      'Wells',
      'West Coon Rapids',
      'West Saint Paul',
      'White Bear Lake',
      'Willmar',
      'Windom',
      'Young America (historical)',
      'Zumbrota',
      'Grant City',
      'Kahoka',
      'King City',
      'Kirksville',
      'Rock Port',
      'Stanberry',
      'Tarkio',
      'Ashley',
      'Belcourt',
      'Cando',
      'Carrington',
      'Casselton',
      'Cavalier',
      'Cooperstown',
      'Devils Lake',
      'Ellendale',
      'Fargo',
      'Fessenden',
      'Finley',
      'Forman',
      'Fort Totten',
      'Grand Forks',
      'Horace',
      'Lakota',
      'Langdon',
      'Larimore',
      'Mayville',
      'Minnewaukan',
      'New Rockford',
      'Oakes',
      'Park River',
      'Rugby',
      'Shell Valley',
      'Valley City',
      'Wahpeton',
      'West Fargo',
      'Ainsworth',
      'Arapahoe',
      'Atkinson',
      'Beatrice',
      'Beaver City',
      'Bennington',
      'Blair',
      'Burwell',
      'Butte',
      'Chalco',
      'Cozad',
      'Creighton',
      'David City',
      'Eagle',
      'Elkhorn',
      'Falls City',
      'Friend',
      'Fullerton',
      'Gibbon',
      'Grand Island',
      'Greeley',
      'Hartington',
      'Holdrege',
      'La Vista',
      'Loup City',
      'Macy',
      'Nebraska City',
      'Neligh',
      'North Bend',
      "O'Neill",
      'Omaha',
      'Ord',
      'Papillion',
      'Pawnee City',
      'Pender',
      'Pierce',
      'Plattsmouth',
      'Ponca',
      'Ralston',
      'Red Cloud',
      'Schuyler',
      'Seward',
      'South Sioux City',
      'Springview',
      'Stromsburg',
      'Superior',
      'Tekamah',
      'Wahoo',
      'Weeping Water',
      'Wilber',
      'Wisner',
      'Wymore',
      'Yutan',
      'Alstead',
      'Antrim',
      'Barnstead',
      'Boscawen',
      'Bow Bog',
      'Candia',
      'Canterbury',
      'Center Harbor',
      'Chichester',
      'Colebrook',
      'Contoocook',
      'Deering',
      'Derry',
      'Derry Village',
      'East Concord',
      'East Kingston',
      'East Merrimack',
      'Epping',
      'Epsom',
      'Exeter',
      'Fitzwilliam',
      'Francestown',
      'Freedom',
      'Gilford',
      'Gilmanton',
      'Goffstown',
      'Grantham',
      'Hampton Beach',
      'Hampton Falls',
      'Henniker',
      'Hill',
      'Holderness',
      'Hooksett',
      'Jaffrey',
      'Laconia',
      'Lempster',
      'Littleton',
      'Londonderry',
      'Lyme',
      'Lyndeborough',
      'Madbury',
      'Meredith',
      'Merrimack',
      'Mont Vernon',
      'Moultonborough',
      'New Durham',
      'New Ipswich',
      'Newbury',
      'Newmarket',
      'North Conway',
      'North Haverhill',
      'North Hampton',
      'Northumberland',
      'Nottingham',
      'Orford',
      'Ossipee',
      'Peterborough',
      'Pinardville',
      'Plaistow',
      'Rindge',
      'Rollinsford',
      'Rumney',
      'Rye',
      'Sanbornton',
      'Sanbornville',
      'Sandown',
      'Somersworth',
      'South Hooksett',
      'Stratham Station',
      'Sunapee',
      'Suncook',
      'Swanzey',
      'Tamworth',
      'Tuftonboro',
      'Unity',
      'Weare',
      'West Swanzey',
      'Wilmot',
      'Wolfeboro',
      'Woodsville',
      'Allentown',
      'Alpine',
      'Alpha',
      'Asbury Park',
      'Atlantic Highlands',
      'Avenel',
      'Avon-by-the-Sea',
      'Basking Ridge',
      'Bayonne',
      'Beattystown',
      'Bedminster',
      'Belford',
      'Belmar',
      'Bergenfield',
      'Berkeley Heights',
      'Bernardsville',
      'Bogota',
      'Boonton',
      'Bordentown',
      'Bound Brook',
      'Bradley Beach',
      'Bradley Gardens',
      'Brass Castle',
      'Brielle',
      'Budd Lake',
      'Califon',
      'Carlstadt',
      'Carteret',
      'Cedar Glen West',
      'Clark',
      'Clearbrook Park',
      'Cliffside Park',
      'Cliffwood Beach',
      'Closter',
      'Colonia',
      'Colts Neck',
      'Cranbury',
      'Crandon Lakes',
      'Cranford',
      'Cresskill',
      'Delanco',
      'Demarest',
      'Denville',
      'Dunellen',
      'Dumont',
      'East Brunswick',
      'East Freehold',
      'East Hanover',
      'East Newark',
      'East Orange',
      'East Rutherford',
      'Eatontown',
      'Edgewater Park',
      'Englewood Cliffs',
      'Englishtown',
      'Essex Fells',
      'Ewing',
      'Fair Haven',
      'Fair Lawn',
      'Fanwood',
      'Finderne',
      'Florham Park',
      'Fords',
      'Flemington',
      'Fort Dix',
      'Franklin Lakes',
      'Freehold',
      'Frenchtown',
      'Garwood',
      'Glen Gardner',
      'Glen Ridge',
      'Green Knoll',
      'Groveville',
      'Hackensack',
      'Hackettstown',
      'Hamilton Square',
      'Haledon',
      'Harrington Park',
      'Hasbrouck Heights',
      'Haworth',
      'Heathcote',
      'Helmetta',
      'High Bridge',
      'Highland Lake',
      'Hightstown',
      'Ho-Ho-Kus',
      'Hoboken',
      'Hopatcong',
      'Hopatcong Hills',
      'Iselin',
      'Jamesburg',
      'Jersey City',
      'Keansburg',
      'Kearny',
      'Kendall Park',
      'Kenvil',
      'Keyport',
      'Kinnelon',
      'Lake Como',
      'Lake Hiawatha',
      'Lake Hopatcong',
      'Lake Mohawk',
      'Lake Telemark',
      'Lakehurst',
      'Landing',
      'Laurence Harbor',
      'Leisure Knoll',
      'Leisure Village',
      'Leisure Village East',
      'Leonardo',
      'Leonia',
      'Lincroft',
      'Little Ferry',
      'Little Silver',
      'Lodi',
      'Long Branch',
      'Long Valley',
      'Madison Park',
      'Mahwah',
      'Manasquan',
      'Manville',
      'Marlboro',
      'Matawan',
      'Mendham',
      'Mercerville',
      'Metuchen',
      'Middlebush',
      'Middlesex',
      'Midland Park',
      'Milltown',
      'Monmouth Beach',
      'Monmouth Junction',
      'Montvale',
      'Moonachie',
      'Morganville',
      'Morris Plains',
      'Mount Arlington',
      'Mountain Lakes',
      'Mountainside',
      'Navesink',
      'Neptune City',
      'Netcong',
      'New Brunswick',
      'New Egypt',
      'New Providence',
      'North Arlington',
      'North Bergen',
      'North Caldwell',
      'North Haledon',
      'North Middletown',
      'North Plainfield',
      'Northvale',
      'Nutley',
      'Oceanport',
      'Ogdensburg',
      'Old Bridge',
      'Old Tappan',
      'Oradell',
      'Palisades Park',
      'Paramus',
      'Parsippany',
      'Passaic',
      'Paterson',
      'Peapack',
      'Pennington',
      'Perth Amboy',
      'Pine Lake Park',
      'Piscataway',
      'Plainsboro Center',
      'Point Pleasant Beach',
      'Pompton Lakes',
      'Port Monmouth',
      'Port Reading',
      'Princeton Junction',
      'Princeton Meadows',
      'Rahway',
      'Ramtown',
      'Raritan',
      'Ridgefield Park',
      'Ridgewood',
      'Ringwood',
      'River Edge',
      'River Vale',
      'Robertsville',
      'Rochelle Park',
      'Rockaway',
      'Roebling',
      'Roselle Park',
      'Rumson',
      'Saddle River',
      'Saddle Brook',
      'Sayreville',
      'Sayreville Junction',
      'Scotch Plains',
      'Sea Bright',
      'Sea Girt',
      'Secaucus',
      'Sewaren',
      'Shark River Hills',
      'Short Hills',
      'Singac',
      'Sixmile Run',
      'Society Hill',
      'South Amboy',
      'South Belmar',
      'South Bound Brook',
      'South Old Bridge',
      'South Orange',
      'South Plainfield',
      'South River',
      'Spotswood',
      'Spring Lake Heights',
      'Stanhope',
      'Strathmore',
      'Succasunna',
      'Teaneck',
      'Ten Mile Run',
      'Tenafly',
      'Tinton Falls',
      'Totowa',
      'Twin Rivers',
      'Union Beach',
      'Upper Montclair',
      'Upper Saddle River',
      'Victory Gardens',
      'Vista Center',
      'Voorhees',
      'Vernon Valley',
      'Waldwick',
      'Wallington',
      'Wanaque',
      'Wanamassa',
      'Watchung',
      'Weehawken',
      'West Belmar',
      'West Freehold',
      'West Long Branch',
      'West Milford',
      'West New York',
      'Woodland Park',
      'Whippany',
      'White Horse',
      'White Meadow Lake',
      'Whitehouse Station',
      'Willingboro',
      'Wood-Ridge',
      'Woodcliff Lake',
      'Wyckoff',
      'Yorketown',
      'Adams Center',
      'Airmont',
      'Albertson',
      'Alabama',
      'Alden',
      'Alexandria Bay',
      'Allegany',
      'Amagansett',
      'Amityville',
      'Amsterdam',
      'Angola on the Lake',
      'Apalachin',
      'Aquebogue',
      'Ardsley',
      'Armonk',
      'Arrochar',
      'Arverne',
      'Averill Park',
      'Babylon',
      'Baiting Hollow',
      'Baldwin Harbor',
      'Baldwinsville',
      'Ballston Lake',
      'Ballston Spa',
      'Balmville',
      'Bardonia',
      'Barnum Island',
      'Barryville',
      'Bath Beach',
      'Bay Park',
      'Bay Shore',
      'Baxter Estates',
      'Bay Wood',
      'Baychester',
      'Bayside',
      'Beacon',
      'Bedford Hills',
      'Belle Harbor',
      'Bellerose',
      'Bellerose Terrace',
      'Bellmore',
      'Bellport',
      'Bensonhurst',
      'Bergen',
      'Bergen Beach',
      'Bernhards Bay',
      'Bethpage',
      'Big Flats',
      'Billington Heights',
      'Binghamton',
      'Black River',
      'Blasdell',
      'Blauvelt',
      'Blossvale',
      'Blue Point',
      'Bohemia',
      'Borough Park',
      'Branchport',
      'Brewerton',
      'Brewster Hill',
      'Briarcliff Manor',
      'Bridgehampton',
      'Brighton Beach',
      'Brightwaters',
      'Brinckerhoff',
      'Broad Channel',
      'Broadalbin',
      'Brockport',
      'Brocton',
      'The Bronx',
      'Bronxville',
      'Brooklyn Heights',
      'Bushwick',
      'Calcium',
      'Cambria Heights',
      'Canajoharie',
      'Canandaigua',
      'Canarsie',
      'Canastota',
      'Canisteo',
      'Carle Place',
      'Castleton-on-Hudson',
      'Catskill',
      'Cayuga Heights',
      'Cazenovia',
      'Cedarhurst',
      'Celoron',
      'Center Moriches',
      'Centereach',
      'Centerport',
      'Central Islip',
      'Central Square',
      'Central Valley',
      'Chadwicks',
      'Champlain',
      'Chappaqua',
      'Cheektowaga',
      'Chenango Bridge',
      'Chestnut Ridge',
      'Chinatown',
      'Chittenango',
      'Churchville',
      'Cincinnatus',
      'City Island',
      'Clarence',
      'Clarence Center',
      'Clark Mills',
      'Clarkson',
      'Clifton Park',
      'Clifton Springs',
      'Clinton Corners',
      'Clintondale',
      'Clymer',
      'Cobleskill',
      'Cohoes',
      'Cold Spring Harbor',
      'College Point',
      'Colonie',
      'Commack',
      'Coney Island',
      'Congers',
      'Constantia',
      'Copiague',
      'Coram',
      'Cornwall',
      'Corona',
      'Country Knolls',
      'Coxsackie',
      'Crompond',
      'Cropseyville',
      'Croton-on-Hudson',
      'Crown Heights',
      'Crugers',
      'Cumberland Head',
      'Cutchogue',
      'Cypress Hills',
      'Dannemora',
      'Dansville',
      'Deansboro',
      'Delevan',
      'Depew',
      'Deposit',
      'Dix Hills',
      'Dobbs Ferry',
      'Dolgeville',
      'Dongan Hills',
      'Douglaston',
      'Dover Plains',
      'Dyker Heights',
      'East Amherst',
      'East Atlantic Beach',
      'East Aurora',
      'East Elmhurst',
      'East Farmingdale',
      'East Flatbush',
      'East Garden City',
      'East Glenville',
      'East Greenbush',
      'East Hills',
      'East Islip',
      'East Ithaca',
      'East Meadow',
      'East Massapequa',
      'East Moriches',
      'East New York',
      'East Northport',
      'East Norwich',
      'East Patchogue',
      'East Quogue',
      'East Rochester',
      'East Rockaway',
      'East Setauket',
      'East Shoreham',
      'East Syracuse',
      'East Tremont',
      'East Village',
      'East Williston',
      'Eastchester',
      'Eatons Neck',
      'Eggertsville',
      'Elbridge',
      'Ellenville',
      'Elma Center',
      'Elmira',
      'Elmira Heights',
      'Elmont',
      'Elmsford',
      'Eltingville',
      'Emerson Hill',
      'Endicott',
      'Endwell',
      'Fairport',
      'Falconer',
      'Fallsburg',
      'Far Rockaway',
      'Farmingville',
      'Firthcliffe',
      'Fishkill',
      'Flanders',
      'Flatbush',
      'Flatlands',
      'Floral Park',
      'Florida',
      'Flower Hill',
      'Fonda',
      'Fordham',
      'Fort Edward',
      'Fort Hamilton',
      'Fort Montgomery',
      'Fort Plain',
      'Fort Salonga',
      'Fort Wadsworth',
      'Franklin Square',
      'Fresh Meadows',
      'Frewsburg',
      'Galeville',
      'Garden City Park',
      'Garden City South',
      'Gang Mills',
      'Gardnertown',
      'Gasport',
      'Glasco',
      'Glen Cove',
      'Glen Head',
      'Glen Oaks',
      'Glens Falls',
      'Glenwood Landing',
      'Gloversville',
      'Goldens Bridge',
      'Gordon Heights',
      'Gouverneur',
      'Gowanda',
      'Grandyle Village',
      'Gravesend',
      'Great Kills',
      'Great Neck',
      'Great Neck Gardens',
      'Great Neck Plaza',
      'Great River',
      'Great Neck Estates',
      'Greece',
      'Green Island',
      'Greenburgh',
      'Greenlawn',
      'Greenpoint',
      'Greenport',
      'Greenvale',
      'Greenwood Lake',
      'Grymes Hill',
      'Hagaman',
      'Halesite',
      'Hampton Bays',
      'Hampton Manor',
      'Hannawa Falls',
      'Harbor Isle',
      'Harris Hill',
      'Hartsdale',
      'Hastings-on-Hudson',
      'Hauppauge',
      'Haverstraw',
      'Haviland',
      'Head of the Harbor',
      "Hell's Kitchen",
      'New Hempstead',
      'Herkimer',
      'Heritage Hills',
      'Herricks',
      'Hewlett',
      'Hewlett Harbor',
      'Hicksville',
      'Highland Falls',
      'Highland Mills',
      'Hillside Lake',
      'Hilton',
      'Holcomb',
      'Holtsville',
      'Honeoye Falls',
      'Hoosick Falls',
      'Hornell',
      'Horseheads',
      'Howard Beach',
      'Hudson Falls',
      'Huguenot',
      'Huntington Bay',
      'Huntington Station',
      'Hunts Point',
      'Ilion',
      'Irondequoit',
      'Island Park',
      'Islandia',
      'Islip',
      'Islip Terrace',
      'Jackson Heights',
      'Jamaica',
      'Jamesport',
      'Jefferson Heights',
      'Jericho',
      'Johnstown',
      'Kaser',
      'Katonah',
      'Keeseville',
      'Kenmore',
      'Kerhonkson',
      'Kew Gardens',
      'Keuka Park',
      'Kiantone',
      'Kinderhook',
      'Kings Bridge',
      'Kirkville',
      'Kiryas Joel',
      'Lackawanna',
      'Lake Carmel',
      'Lake Erie Beach',
      'Lake Grove',
      'Lake Katrine',
      'Lake Luzerne',
      'Lake Mohegan',
      'Lake Pleasant',
      'Lake Ronkonkoma',
      'Lake Success',
      'Larchmont',
      'Latham',
      'Lattingtown',
      'Laurel Hollow',
      'Laurelton',
      'Levittown',
      'Lido Beach',
      'Lincolndale',
      'Lindley',
      'Little Neck',
      'Little Valley',
      'Liverpool',
      'Livingston Manor',
      'Lloyd Harbor',
      'Locust Valley',
      'Long Island City',
      'Lorenz Park',
      'Lowville',
      'Lynbrook',
      'Lyncourt',
      'Macedon',
      'Mahopac',
      'Malverne',
      'Mamaroneck',
      'Manhasset',
      'Manhasset Hills',
      'Manlius',
      'Manorhaven',
      'Manorville',
      'Marbletown',
      'Mariners Harbor',
      'Maspeth',
      'Massapequa',
      'Massapequa Park',
      'Massena',
      'Mastic',
      'Mastic Beach',
      'Mattituck',
      'Mattydale',
      'Maybrook',
      'McGraw',
      'McKownville',
      'Mechanicstown',
      'Mechanicville',
      'Menands',
      'Merrick',
      'Middle Island',
      'Middle Village',
      'Middleburgh',
      'Midland Beach',
      'Mill Neck',
      'Miller Place',
      'Minetto',
      'Mineville',
      'Minoa',
      'Mohawk',
      'Monsey',
      'Montauk',
      'Montebello',
      'Montour Falls',
      'Moravia',
      'Moriches',
      'Morris Heights',
      'Morris Park',
      'Morrisania',
      'Mott Haven',
      'Mount Ivy',
      'Mount Kisco',
      'Mount Sinai',
      'Mountain Lodge Park',
      'Munsey Park',
      'Munsons Corners',
      'Myers Corner',
      'Muttontown',
      'Nanuet',
      'Napanoch',
      'Nassau',
      'Nedrow',
      'Neponsit',
      'Nesconset',
      'New Cassel',
      'New Dorp',
      'New Dorp Beach',
      'New Hartford',
      'New Hyde Park',
      'New Paltz',
      'New Rochelle',
      'New Springville',
      'New Square',
      'New York City',
      'Newfane',
      'Niagara Falls',
      'Niskayuna',
      'Nissequogue',
      'Niverville',
      'North Amityville',
      'North Babylon',
      'North Bay Shore',
      'North Bellmore',
      'North Bellport',
      'North Boston',
      'North Collins',
      'North Elba',
      'North Gates',
      'North Great River',
      'North Hills',
      'North Lindenhurst',
      'North Massapequa',
      'North Merrick',
      'North New Hyde Park',
      'North Patchogue',
      'North Sea',
      'North Syracuse',
      'North Tonawanda',
      'North Valley Stream',
      'North Wantagh',
      'Northwest Harbor',
      'Noyack',
      'Nunda',
      'Nyack',
      'Oakfield',
      'Oceanside',
      'Olcott',
      'Old Bethpage',
      'Old Brookville',
      'Old Westbury',
      'Olean',
      'Ontario',
      'Orange Lake',
      'Orchard Park',
      'Oriskany',
      'Ossining',
      'Otisville',
      'Owego',
      'Oyster Bay',
      'Oyster Bay Cove',
      'Ozone Park',
      'Painted Post',
      'Palenville',
      'Park Slope',
      'Parkchester',
      'Patchogue',
      'Pawling',
      'Peach Lake',
      'Peekskill',
      'Pelham Manor',
      'Penn Yan',
      'Perth',
      'Phelps',
      'Philmont',
      'Piermont',
      'Pine Bush',
      'Pine Plains',
      'Pittsford',
      'Plainedge',
      'Plandome',
      'Plandome Heights',
      'Plattekill',
      'Plattsburgh',
      'Poestenkill',
      'Point Lookout',
      'Port Chester',
      'Port Dickinson',
      'Port Ewen',
      'Port Henry',
      'Port Jefferson',
      'Port Jefferson Station',
      'Port Jervis',
      'Port Morris',
      'Port Richmond',
      'Port Washington',
      'Port Washington North',
      'Potsdam',
      'Poughkeepsie',
      'Pound Ridge',
      'Purchase',
      'Putnam Lake',
      'Queens Village',
      'Queens',
      'Queensbury',
      'Ransomville',
      'Rapids',
      'Ravena',
      'Red Hook',
      'Red Oaks Mill',
      'Rego Park',
      'Rhinebeck',
      'Richfield Springs',
      'Ridge',
      'Riverhead',
      'Rockaway Point',
      'Rockville Centre',
      'Roessleville',
      'Ronkonkoma',
      'Roosevelt',
      'Rosebank',
      'Roslyn',
      'Roslyn Estates',
      'Roslyn Harbor',
      'Roslyn Heights',
      'Rotterdam',
      'Rouses Point',
      'Rye Brook',
      'Sackets Harbor',
      'Sag Harbor',
      'Saint Johnsville',
      'Salamanca',
      'Sands Point',
      'Saranac Lake',
      'Saratoga Springs',
      'Saugerties',
      'Sayville',
      'Scarsdale',
      'Schenectady',
      'Schoharie',
      'Schuylerville',
      'Scotchtown',
      'Scotia',
      'Sea Cliff',
      'Searingtown',
      'Selden',
      'Seneca Falls',
      'Seneca Knolls',
      'Sheepshead Bay',
      'Shelter Island',
      'Shelter Island Heights',
      'Shenorock',
      'Sherburne',
      'Sherrill',
      'Shinnecock Hills',
      'Shokan',
      'Shortsville',
      'Shrub Oak',
      'Silver Creek',
      'Skaneateles',
      'Sloan',
      'Sloatsburg',
      'Smithtown',
      'Sodus',
      'Solvay',
      'Sound Beach',
      'South Corning',
      'South Fallsburg',
      'South Farmingdale',
      'South Floral Park',
      'South Glens Falls',
      'South Hempstead',
      'South Huntington',
      'South Lockport',
      'South Nyack',
      'South Ozone Park',
      'South Valley Stream',
      'Southold',
      'Spackenkill',
      'Sparkill',
      'Sparrow Bush',
      'Spencerport',
      'Springfield Gardens',
      'Springs',
      'Spuyten Duyvil',
      'Saint Bonaventure',
      'Stapleton',
      'Staten Island',
      'Steinway',
      'Stewart Manor',
      'Stone Ridge',
      'Stony Brook',
      'Stottville',
      'Suffern',
      'Sylvan Beach',
      'Syosset',
      'Tappan',
      'Tarrytown',
      'Terrace Heights',
      'Thiells',
      'Thornwood',
      'Throgs Neck',
      'Ticonderoga',
      'Tillson',
      'Tivoli',
      'Tonawanda',
      'Town Line',
      'Tribes Hill',
      'Trumansburg',
      'Tupper Lake',
      'Uniondale',
      'Unionport',
      'University Gardens',
      'Upper Brookville',
      'Upper Nyack',
      'Vails Gate',
      'Valatie',
      'Valhalla',
      'Valley Cottage',
      'Valley Stream',
      'Van Nest',
      'Verplanck',
      'Vestal',
      'Victor',
      'Village Green',
      'Village of the Branch',
      'Viola',
      'Volney',
      'Voorheesville',
      'Wading River',
      'Wallkill',
      'Walton Park',
      'Wampsville',
      'Wantagh',
      'Wanakah',
      'Wappingers Falls',
      'Warwick',
      'Washington Heights',
      'Washington Mills',
      'Washingtonville',
      'Water Mill',
      'Watkins Glen',
      'Wawarsing',
      'Weedsport',
      'Wesley Hills',
      'West Albany',
      'West Babylon',
      'West Bay Shore',
      'West Carthage',
      'West Elmira',
      'West End',
      'West Glens Falls',
      'West Haverstraw',
      'West Hempstead',
      'West Henrietta',
      'West Hills',
      'West Hurley',
      'West Islip',
      'West Nyack',
      'West Sand Lake',
      'West Sayville',
      'West Seneca',
      'Westbury',
      'Westerleigh',
      'Westhampton Beach',
      'Westmere',
      'Weston Mills',
      'Westons Mills',
      'Westvale',
      'Wheatley Heights',
      'Whitestone',
      'Williston Park',
      'Woodrow',
      'Woodside',
      'Wurtsboro',
      'Wyandanch',
      'Wykagyl',
      'Wynantskill',
      'Yaphank',
      'Yonkers',
      'Yorktown Heights',
      'Zena',
      'Alliance',
      'Ansonia',
      'Antwerp',
      'Apple Creek',
      'Archbold',
      'Ashtabula',
      'Austintown',
      'Avon Center',
      'Avon Lake',
      'Ballville',
      'Barberton',
      'Bay Village',
      'Bedford Heights',
      'Beechwood Trails',
      'Bellefontaine',
      'Boardman',
      'Bolindale',
      'Boston Heights',
      'Botkins',
      'Bradner',
      'Bratenahl',
      'Brecksville',
      'Brilliant',
      'Broadview Heights',
      'Brook Park',
      'Brookfield Center',
      'Bucyrus',
      'Calcutta',
      'Canal Fulton',
      'Canfield',
      'Cardington',
      'Carey',
      'Centerburg',
      'Chagrin Falls',
      'Chardon',
      'Chesterland',
      'Churchill',
      'Collinwood',
      'Columbus Grove',
      'Conneaut',
      'Continental',
      'Convoy',
      'Copley',
      'Coshocton',
      'Craig Beach',
      'Crestline',
      'Cridersville',
      'Curtice',
      'Cuyahoga Falls',
      'De Graff',
      'Defiance',
      'Delaware',
      'Delphos',
      'Delta',
      'Dennison',
      'Deshler',
      'Doylestown',
      'East Canton',
      'East Liverpool',
      'East Palestine',
      'Eastlake',
      'Eaton Estates',
      'Elida',
      'Elyria',
      'Euclid',
      'Fairport Harbor',
      'Findlay',
      'Fort Loramie',
      'Fort Recovery',
      'Fort Shawnee',
      'Fostoria',
      'Frazeysburg',
      'Gahanna',
      'Galion',
      'Gambier',
      'Garfield Heights',
      'Garrettsville',
      'Gates Mills',
      'Geneva-on-the-Lake',
      'Gibsonburg',
      'Glandorf',
      'Glenmoor',
      'Gnadenhutten',
      'Green',
      'Green Springs',
      'Haskins',
      'Holgate',
      'Hough',
      'Howland Center',
      'Huber Ridge',
      'Huron',
      'Jackson Center',
      'Kalida',
      'Kirtland',
      'La Croft',
      'Lakemore',
      'Leavittsburg',
      'Leetonia',
      'Leipsic',
      'Lewis Center',
      'Liberty Center',
      'Lorain',
      'Lordstown',
      'Loudonville',
      'Lowellville',
      'Luckey',
      'Macedonia',
      'Maple Heights',
      'Martins Ferry',
      'Massillon',
      'Masury',
      'Maumee',
      'Mayfield Heights',
      'McDonald',
      'McKinley Heights',
      'Mechanicsburg',
      'Mentor',
      'Mentor-on-the-Lake',
      'Middleburg Heights',
      'Middlefield',
      'Millersburg',
      'Mineral Ridge',
      'Minerva',
      'Minerva Park',
      'Mingo Junction',
      'Minster',
      'Mogadore',
      'Moreland Hills',
      'Morgandale',
      'Munroe Falls',
      'New Bremen',
      'New California',
      'New Middletown',
      'New Philadelphia',
      'New Waterford',
      'Newburgh Heights',
      'Newcomerstown',
      'Newton Falls',
      'North Baltimore',
      'North Canton',
      'North Kingsville',
      'North Lewisburg',
      'North Olmsted',
      'North Randall',
      'North Ridgeville',
      'North Royalton',
      'Oak Harbor',
      'Olmsted Falls',
      'Orrville',
      'Orwell',
      'Ottawa Hills',
      'Painesville',
      'Pandora',
      'Parma',
      'Parma Heights',
      'Paulding',
      'Payne',
      'Pemberville',
      'Pepper Pike',
      'Perry Heights',
      'Perrysburg',
      'Pioneer',
      'Piqua',
      'Plain City',
      'Port Clinton',
      'Portage Lakes',
      'Powell',
      'Reminderville',
      'Richville',
      'Rittman',
      'Roaming Shores',
      'Rocky River',
      'Rossford',
      'Russells Point',
      'Saint Clairsville',
      'Saint Henry',
      'Saint Paris',
      'Salineville',
      'Sawyerwood',
      'Seven Hills',
      'Seville',
      'Shaker Heights',
      'Sheffield Lake',
      'Shreve',
      'South Canal',
      'South Euclid',
      'South Russell',
      'Steubenville',
      'Stony Prairie',
      'Streetsboro',
      'Strongsville',
      'Struthers',
      'Stryker',
      'Sugarcreek',
      'Sunbury',
      'Swanton',
      'Tallmadge',
      'Tiltonsville',
      'Toronto',
      'Tuscarawas',
      'Twinsburg',
      'Uhrichsville',
      'Upper Sandusky',
      'Vermilion',
      'Van Wert',
      'Vermilion-on-the-Lake',
      'Wakeman',
      'Walbridge',
      'Walton Hills',
      'Wapakoneta',
      'Warrensville Heights',
      'Wauseon',
      'West Hill',
      'West Salem',
      'West Unity',
      'Westerville',
      'Westfield Center',
      'Willoughby',
      'Willoughby Hills',
      'Willowick',
      'Wintersville',
      'Wolfhurst',
      'Wooster',
      'Acme',
      'Alburtis',
      'Aliquippa',
      'Alleghenyville',
      'Allison Park',
      'Almedia',
      'Ambler',
      'Ambridge',
      'Amity Gardens',
      'Ancient Oaks',
      'Apollo',
      'Archbald',
      'Aspinwall',
      'Avis',
      'Avonia',
      'Back Mountain',
      'Baidland',
      'Bakerstown',
      'Bala Cynwyd',
      'Bally',
      'Barnesboro',
      'Bear Rocks',
      'Beaver Falls',
      'Beaverdale',
      'Bell Acres',
      'Belle Vernon',
      'Ben Avon',
      'Bensalem',
      'Bentleyville',
      'Bethel Park',
      'Big Beaver',
      'Birchwood Lakes',
      'Birdsboro',
      'Black Lick',
      'Blandon',
      'Blawnox',
      'Bloomsburg',
      'Blossburg',
      'Blue Ball',
      'Blue Bell',
      'Boalsburg',
      'Boswell',
      'Bowmansville',
      'Boyertown',
      'Brackenridge',
      'Braddock',
      'Braddock Hills',
      'Bradford Woods',
      'Bressler',
      'Breinigsville',
      'Brickerville',
      'Brockway',
      'Brodheadsville',
      'Browntown',
      'Bryn Athyn',
      'Bryn Mawr',
      'Burgettstown',
      'Calumet',
      'Cambridge Springs',
      'Camp Hill',
      'Campbelltown',
      'Canadensis',
      'Canonsburg',
      'Castanea',
      'Castle Shannon',
      'Catasauqua',
      'Catawissa',
      'Cementon',
      'Centre Hall',
      'Cetronia',
      'Chalfont',
      'Charleroi',
      'Cheltenham',
      'Chester Springs',
      'Chesterbrook',
      'Cheswick',
      'Chevy Chase Heights',
      'Chicora',
      'Chinchilla',
      'Clairton',
      'Clarks Green',
      'Clarks Summit',
      'Claysburg',
      'Clearfield',
      'Cleona',
      'Coaldale',
      'Cochranton',
      'Collinsburg',
      'Colonial Park',
      'Conemaugh',
      'Conashaugh Lakes',
      'Connellsville',
      'Conshohocken',
      'Conyngham',
      'Coopersburg',
      'Coplay',
      'Coraopolis',
      'Cornwells Heights',
      'Corry',
      'Coudersport',
      'Crafton',
      'Cresson',
      'Cressona',
      'Croydon',
      'Curtisville',
      'Curwensville',
      'Davidsville',
      'Delmont',
      'Devon',
      'Dewart',
      'Dickson City',
      'Dillsburg',
      'Donora',
      'Dormont',
      'Dorneyville',
      'Downingtown',
      'Dravosburg',
      'Dresher',
      'DuBois',
      'Duboistown',
      'Duncannon',
      'Duncansville',
      'Dunmore',
      'Dunnstown',
      'Dupont',
      'Duryea',
      'Eagleville',
      'Earlston',
      'East Bangor',
      'East Berwick',
      'East Conemaugh',
      'East Earl',
      'East Greenville',
      'East McKeesport',
      'East Petersburg',
      'East Pittsburgh',
      'East Stroudsburg',
      'East Washington',
      'Eastlawn Gardens',
      'Ebensburg',
      'Economy',
      'Edgeworth',
      'Edinboro',
      'Effort',
      'Egypt',
      'Elim',
      'Elizabethville',
      'Elkland',
      'Ellport',
      'Ellwood City',
      'Elverson',
      'Elysburg',
      'Emerald Lakes',
      'Emigsville',
      'Emmaus',
      'Emporium',
      'Emsworth',
      'Enhaut',
      'Enlow',
      'Enola',
      'Ephrata',
      'Espy',
      'Evans City',
      'Evansburg',
      'Exton',
      'Factoryville',
      'Fairless Hills',
      'Falls Creek',
      'Farrell',
      'Faxon',
      'Feasterville',
      'Fellsburg',
      'Fernway',
      'Fivepointville',
      'Fleetwood',
      'Flourtown',
      'Flying Hills',
      'Ford City',
      'Forty Fort',
      'Foster Brook',
      'Fountain Hill',
      'Fox Chapel',
      'Fox Run',
      'Frackville',
      'Freemansburg',
      'Friedens',
      'Galeton',
      'Gallitzin',
      'Garden View',
      'Gastonville',
      'Geistown',
      'Gilbertsville',
      'Girardville',
      'Glassport',
      'Glen Lyon',
      'Glenside',
      'Glenshaw',
      'Gold Key Lake',
      'Green Tree',
      'Greenock',
      'Grill',
      'Halfway House',
      'Hallam',
      'Hallstead',
      'Harleigh',
      'Harleysville',
      'Harveys Lake',
      'Hasson Heights',
      'Hatboro',
      'Hazleton',
      'Hellertown',
      'Hemlock Farms',
      'Hershey',
      'Highspire',
      'Hilldale',
      'Hiller',
      'Hokendauqua',
      'Hollidaysburg',
      'Homer City',
      'Honesdale',
      'Honey Brook',
      'Horsham',
      'Houserville',
      'Hughestown',
      'Hummels Wharf',
      'Hummelstown',
      'Hyde',
      'Indian Mountain Lake',
      'Indiana',
      'Industry',
      'Inkerman',
      'Intercourse',
      'Ivyland',
      'Jacksonwald',
      'Jeannette',
      'Jefferson Hills',
      'Jenkintown',
      'Jermyn',
      'Jersey Shore',
      'Jim Thorpe',
      'Johnsonburg',
      'Kane',
      'Kenhorst',
      'Kenmar',
      'King of Prussia',
      'Kittanning',
      'Kulpmont',
      'Kulpsville',
      'Kutztown',
      'Lafayette Hill',
      'Laflin',
      'Lake Wynonah',
      'Lakemont',
      'Landisville',
      'Langhorne',
      'Langhorne Manor',
      'Lansdale',
      'Lansford',
      'Laporte',
      'Larksville',
      'Latrobe',
      'Laureldale',
      'Laurys Station',
      'Lawnton',
      'Lawrence Park',
      'Lawson Heights',
      'Leechburg',
      'Leesport',
      'Leetsdale',
      'Lehighton',
      'Lemoyne',
      'Lenape Heights',
      'Leola',
      'Level Green',
      'Light Street',
      'Linglestown',
      'Linntown',
      'Lionville',
      'Lititz',
      'Lock Haven',
      'Lorane',
      'Lower Allen',
      'Lower Burrell',
      'Loyalhanna',
      'Lykens',
      'Macungie',
      'Mahanoy City',
      'Manheim',
      'Maple Glen',
      'Marianne',
      'Marienville',
      'Mars',
      'Marshallton',
      'Matamoras',
      'Maytown',
      'McAdoo',
      'McConnellstown',
      'McGovern',
      'McKees Rocks',
      'McKeesport',
      'McMurray',
      'Meadowood',
      'Mercer',
      'Mifflinburg',
      'Mifflintown',
      'Mifflinville',
      'Milesburg',
      'Mill Hall',
      'Millvale',
      'Milroy',
      'Minersville',
      'Mohnton',
      'Monaca',
      'Monessen',
      'Monongahela',
      'Montgomeryville',
      'Montoursville',
      'Moosic',
      'Moscow',
      'Mount Cobb',
      'Mount Holly Springs',
      'Mount Joy',
      'Mount Lebanon',
      'Mount Oliver',
      'Mount Penn',
      'Mount Pocono',
      'Mount Union',
      'Mount Wolf',
      'Mountain Top',
      'Mountainhome',
      'Mountville',
      'Muhlenberg Park',
      'Muncy',
      'Munhall',
      'Mundys Corner',
      'Murrysville',
      'Muse',
      'Myerstown',
      'Nanticoke',
      'Nanty Glo',
      'Narberth',
      'Nazareth',
      'Nescopeck',
      'Nesquehoning',
      'New Beaver',
      'New Berlinville',
      'New Bloomfield',
      'New Columbia',
      'New Eagle',
      'New Cumberland',
      'New Kensington',
      'New Holland',
      'New Stanton',
      'New Wilmington',
      'Newmanstown',
      'Newtown Grant',
      'Newville',
      'Nicetown-Tioga',
      'Norristown',
      'North Apollo',
      'North Belle Vernon',
      'North Braddock',
      'North Charleroi',
      'North Catasauqua',
      'North Versailles',
      'North Wales',
      'North Warren',
      'Northern Cambria',
      'Oak Hills',
      'Oakmont',
      'Ohioville',
      'Oil City',
      'Old Forge',
      'Old Orchard',
      'Oley',
      'Olyphant',
      'Orchard Hills',
      'Oreland',
      'Orwigsburg',
      'Osceola Mills',
      'Palmdale',
      'Palmer Heights',
      'Palmerton',
      'Palo Alto',
      'Paradise',
      'Park Forest Village',
      'Patton',
      'Paxtang',
      'Paxtonia',
      'Pen Argyl',
      'Penbrook',
      'Penn Hills',
      'Penndel',
      'Pennsburg',
      'Pennside',
      'Penryn',
      'Perkasie',
      'Perryopolis',
      'Philipsburg',
      'Phoenixville',
      'Pine Grove',
      'Pine Grove Mills',
      'Pitcairn',
      'Pittsburgh',
      'Plains',
      'Pleasant Gap',
      'Plum',
      'Plumsteadville',
      'Plymouth Meeting',
      'Pocono Pines',
      'Port Carbon',
      'Port Allegany',
      'Port Vue',
      'Pottsgrove',
      'Pottstown',
      'Progress',
      'Punxsutawney',
      'Quakertown',
      'Radnor',
      'Pocono Ranch Lands',
      'Rankin',
      'Raubsville',
      'Reamstown',
      'Reiffton',
      'Reinholds',
      'Rennerdale',
      'Renovo',
      'Reservoir',
      'Reynolds Heights',
      'Reynoldsville',
      'Rheems',
      'Richboro',
      'Richlandtown',
      'Ridgway',
      'River View Park',
      'Roaring Spring',
      'Robesonia',
      'Roseto',
      'Rothsville',
      'Royersford',
      'Russellton',
      'Saint Lawrence',
      'Salix',
      'Salunga',
      'Sanatoga',
      'Sand Hill',
      'Sandy',
      'Saw Creek',
      'Saxonburg',
      'Saylorsburg',
      'Schlusser',
      'Schnecksville',
      'Schoeneck',
      'Schuylkill Haven',
      'Schwenksville',
      'Scranton',
      'Selinsgrove',
      'Sellersville',
      'Seven Fields',
      'Sewickley',
      'Shamokin',
      'Shamokin Dam',
      'Sharpsville',
      'Shavertown',
      'Shenandoah Heights',
      'Shillington',
      'Shinglehouse',
      'Shiremanstown',
      'Shippensburg',
      'Shoemakersville',
      'Simpson',
      'Sinking Spring',
      'Skippack',
      'Skyline View',
      'Slatington',
      'Slippery Rock',
      'Smethport',
      'Souderton',
      'South Greensburg',
      'South Pottstown',
      'South Temple',
      'South Waverly',
      'South Williamsport',
      'Southwest Greensburg',
      'Spangler',
      'Speers',
      'Spinnerstown',
      'Spring House',
      'Spring Mount',
      'State College',
      'Steelton',
      'Stiles',
      'Stoneboro',
      'Stony Creek Mills',
      'Stormstown',
      'Stowe',
      'Stroudsburg',
      'Sturgeon',
      'Summit Hill',
      'Sun Valley',
      'Sunrise Lake',
      'Susquehanna',
      'Swartzville',
      'Swissvale',
      'Swoyersville',
      'Tacony',
      'Tamaqua',
      'Tannersville',
      'Tarentum',
      'Tatamy',
      'Telford',
      'Terre Hill',
      'Throop',
      'Tinicum',
      'Tionesta',
      'Topton',
      'Towamensing Trails',
      'Tower City',
      'Trafford',
      'Treasure Lake',
      'Trevorton',
      'Trevose',
      'Trexlertown',
      'Trooper',
      'Trucksville',
      'Tullytown',
      'Tunkhannock',
      'Turtle Creek',
      'Upper Saint Clair',
      'Valley Green',
      'Vandergrift',
      'Village Shires',
      'Vinco',
      'Walnutport',
      'Warminster Heights',
      'Watsontown',
      'Waymart',
      'Weatherly',
      'Wellsboro',
      'Wernersville',
      'Wescosville',
      'Wesleyville',
      'West Conshohocken',
      'West Easton',
      'West Fairview',
      'West Hamburg',
      'West Hazleton',
      'West Homestead',
      'West Kittanning',
      'West Leechburg',
      'West Mayfield',
      'West Mifflin',
      'West Newton',
      'West Norriton',
      'West Pittston',
      'West Reading',
      'West View',
      'West Wyoming',
      'West Wyomissing',
      'Whitaker',
      'White Haven',
      'Whitehall Township',
      'Wilkes-Barre',
      'Wilkinsburg',
      'Willow Grove',
      'Wilmerding',
      'Wind Gap',
      'Windber',
      'Wolfdale',
      'Womelsdorf',
      'Woodbourne',
      'Woodland Heights',
      'Wormleysburg',
      'Woxall',
      'Wyncote',
      'Wyndmoor',
      'Wyomissing',
      'Wyomissing Hills',
      'Yardley',
      'Yeagertown',
      'Youngwood',
      'Zelienople',
      'Ashaway',
      'Central Falls',
      'Chepachet',
      'Coventry',
      'Cranston',
      'Cumberland Hill',
      'East Greenwich',
      'East Providence',
      'Foster',
      'Hope Valley',
      'Narragansett',
      'Narragansett Pier',
      'New Shoreham',
      'North Kingstown',
      'North Providence',
      'North Smithfield',
      'Pascoag',
      'Pawtucket',
      'Tiverton',
      'West Warwick',
      'Westerly',
      'Woonsocket',
      'Armour',
      'Baltic',
      'Beresford',
      'Britton',
      'Brookings',
      'Chamberlain',
      'Crooks',
      'Dakota Dunes',
      'De Smet',
      'Dell Rapids',
      'Elk Point',
      'Faulkton',
      'Flandreau',
      'Fort Thompson',
      'Freeman',
      'Garretson',
      'Highmore',
      'Kennebec',
      'Lake Andes',
      'Lennox',
      'Milbank',
      'Miller',
      'North Sioux City',
      'Parkston',
      'Plankinton',
      'Platte',
      'Sioux Falls',
      'Sisseton',
      'Tea',
      'Tyndall',
      'Vermillion',
      'Volga',
      'Wagner',
      'Wessington Springs',
      'Winner',
      'Yankton',
      'Bellows Falls',
      'Bridport',
      'Brattleboro',
      'Chittenden',
      'Danby',
      'Enosburg Falls',
      'Essex Junction',
      'Ferrisburgh',
      'Guildhall',
      'Hinesburg',
      'Lyndonville',
      'Manchester Center',
      'Middlebury (village)',
      'Moretown',
      'North Bennington',
      'North Hero',
      'Pawlet',
      'Poultney',
      'Pownal',
      'Richford',
      'Saint Johnsbury',
      'South Barre',
      'South Burlington',
      'Starksboro',
      'Townshend',
      'Vergennes',
      'West Brattleboro',
      'West Rutland',
      'White River Junction',
      'Winooski',
      'Abbotsford',
      'Algoma',
      'Allouez',
      'Amery',
      'Antigo',
      'Ashwaubenon',
      'Aztalan',
      'Balsam Lake',
      'Baraboo',
      'Barneveld',
      'Barron',
      'Barton',
      'Belgium',
      'Bevent',
      'Big Bend',
      'Black Creek',
      'Black Earth',
      'Black River Falls',
      'Bloomer',
      'Bohners Lake',
      'Bonduel',
      'Boscobel',
      'Boyceville',
      'Brice Prairie',
      'Brillion',
      'Brothertown',
      'Brown Deer',
      'Browns Lake',
      'Cadott',
      'Camp Lake',
      'Campbellsport',
      'Cashton',
      'Cato',
      'Cedarburg',
      'Chetek',
      'Chilton',
      'Chippewa Falls',
      'Clintonville',
      'Colby',
      'Combined Locks',
      'Cornell',
      'Crandon',
      'Cuba City',
      'Cudahy',
      'Dakota',
      'Dane',
      'De Forest',
      'De Pere',
      'Delafield',
      'Delavan Lake',
      'Dickeyville',
      'Dodgeville',
      'Dousman',
      'East Troy',
      'Eau Claire',
      'Edgar',
      'Elm Grove',
      'Fall Creek',
      'Fennimore',
      'Fond du Lac',
      'Fontana',
      'Fort Atkinson',
      'Fox Point',
      'Franksville',
      'Frederic',
      'French Island',
      'Galesville',
      'Genoa City',
      'Gillett',
      'Glenmore',
      'Glenwood City',
      'Grantsburg',
      'Green Bay',
      'Green Lake',
      'Hales Corners',
      'Hartland',
      'Hayward',
      'Hilbert',
      'Holmen',
      'Horicon',
      'Hortonville',
      'Howards Grove',
      'Hustisford',
      'Ixonia',
      'Johnson Creek',
      'Juneau',
      'Kaukauna',
      'Kenosha',
      'Keshena',
      'Kewaskum',
      'Kewaunee',
      'Kiel',
      'Kohler',
      'Kronenwetter',
      'Lac du Flambeau',
      'Ladysmith',
      'Lake Delton',
      'Lake Geneva',
      'Lake Hallie',
      'Lake Koshkonong',
      'Lake Nebagamon',
      'Lake Ripley',
      'Lake Wazeecha',
      'Lake Wisconsin',
      'Lake Wissota',
      'Lamartine',
      'Lannon',
      'Little Chute',
      'Little Round Lake',
      'Lomira',
      'Loyal',
      'Luck',
      'Luxemburg',
      'Manawa',
      'Manitowoc',
      'Maple Bluff',
      'Marinette',
      'Markesan',
      'Mauston',
      'Mazomanie',
      'McFarland',
      'Menasha',
      'Menomonee Falls',
      'Menomonie',
      'Mequon',
      'Merrill',
      'Merton',
      'Milwaukee',
      'Mineral Point',
      'Mishicot',
      'Mondovi',
      'Montello',
      'Mosinee',
      'Mount Horeb',
      'Mukwonago',
      'Muscoda',
      'Muskego',
      'Nashotah',
      'Neenah',
      'Neillsville',
      'Nekoosa',
      'New Glarus',
      'New Holstein',
      'New Lisbon',
      'Niagara',
      'North Fond du Lac',
      'North Hudson',
      'North La Crosse',
      'North Prairie',
      'Oak Creek',
      'Oconomowoc',
      'Oconto',
      'Oconto Falls',
      'Okauchee Lake',
      'Omro',
      'Oostburg',
      'Orfordville',
      'Oshkosh',
      'Paddock Lake',
      'Pardeeville',
      'Park Falls',
      'Pell Lake',
      'Peshtigo',
      'Pewaukee',
      'Phillips',
      'Platteville',
      'Pleasant Prairie',
      'Plover',
      'Port Edwards',
      'Potter Lake',
      'Powers Lake',
      'Poynette',
      'Prairie du Chien',
      'Prairie du Sac',
      'Racine',
      'Random Lake',
      'Redgranite',
      'Reedsburg',
      'Reedsville',
      'Rhinelander',
      'Rib Mountain',
      'Rice Lake',
      'Richland Center',
      'Ripon',
      'Rio',
      'River Falls',
      'River Hills',
      'Roberts',
      'Rosendale',
      'Rothschild',
      'Roxbury',
      'Saint Croix Falls',
      'Sauk City',
      'Saukville',
      'Schofield',
      'Shawano',
      'Sheboygan',
      'Sheboygan Falls',
      'Shell Lake',
      'Shorewood Hills',
      'Shullsburg',
      'Siren',
      'Slinger',
      'South Milwaukee',
      'Spooner',
      'Spring Green',
      'Stevens Point',
      'Strum',
      'Sturgeon Bay',
      'Sturtevant',
      'Suamico',
      'Sun Prairie',
      'Tainter Lake',
      'Theresa',
      'Thiensville',
      'Thorp',
      'Tichigan',
      'Tomah',
      'Tomahawk',
      'Trempealeau',
      'Turtle Lake',
      'Two Rivers',
      'Union Grove',
      'Viroqua',
      'Walworth',
      'Waukesha',
      'Waunakee',
      'Waupaca',
      'Waupun',
      'Wausau',
      'Wautoma',
      'Wauwatosa',
      'West Baraboo',
      'West Allis',
      'West Bend',
      'West Milwaukee',
      'Westby',
      'Weyauwega',
      'Whitefish Bay',
      'Whitewater',
      'Williams Bay',
      'Wind Lake',
      'Wind Point',
      'Winneconne',
      'Wisconsin Dells',
      'Wisconsin Rapids',
      'Wittenberg',
      'Wrightstown',
      'Benwood',
      'Follansbee',
      'Hooverson Heights',
      'Newell',
      'Weirton',
      'Weirton Heights',
      'Wellsburg',
      'South Duxbury',
      'West Kennebunk',
      'Nowthen',
      'Castleton',
      'Blue Hills',
      'East Windsor',
      'Branford',
      'Canton Valley',
      'Cheshire',
      'Fillmore',
      'Cool',
      'Bisbee',
      'Black Canyon City',
      'South Taft',
      'Vine Hill',
      'Blackwater',
      'Mona',
      'Buckeye',
      'Bullhead City',
      'Bylas',
      'Cactus Flat',
      'Camp Verde',
      'Canyon Day',
      'Carefree',
      'Casa Blanca',
      'Casa Grande',
      'Casas Adobes',
      'Catalina',
      'Catalina Foothills',
      'Cave Creek',
      'Chinle',
      'Chino Valley',
      'Cibecue',
      'Cienega Springs',
      'Clarkdale',
      'Claypool',
      'Colorado City',
      'Congress',
      'Coolidge',
      'Cordes Lakes',
      'Corona de Tucson',
      'Deer Valley',
      'Desert Hills',
      'Dilkon',
      'Dolan Springs',
      'Drexel Heights',
      'Eagar',
      'East Sahuarita',
      'Ehrenberg',
      'El Mirage',
      'Eloy',
      'First Mesa',
      'Flagstaff',
      'Flowing Wells',
      'Fort Defiance',
      'Fortuna Foothills',
      'Fountain Hills',
      'Gila Bend',
      'Globe',
      'Gold Camp',
      'Goodyear',
      'Grand Canyon',
      'Grand Canyon Village',
      'Guadalupe',
      'Houck',
      'Huachuca City',
      'Joseph City',
      'Kachina Village',
      'Kaibito',
      'Kayenta',
      'Lake Havasu City',
      'Lake Montezuma',
      'Laveen',
      'LeChee',
      'Litchfield Park',
      'Lukachukai',
      'Mammoth',
      'Many Farms',
      'Marana',
      'Maricopa',
      'Maryvale',
      'Meadview',
      'Mesa',
      'Mescal',
      'Mohave Valley',
      'Mountainaire',
      'Naco',
      'New River',
      'Nogales',
      'Oracle',
      'Oro Valley',
      'Page',
      'Paradise Valley',
      'Parks',
      'Paulden',
      'Peach Springs',
      'Peridot',
      'Picture Rocks',
      'Pima',
      'Pine',
      'Pinetop-Lakeside',
      'Pirtleville',
      'Prescott Valley',
      'Quartzsite',
      'Queen Creek',
      'Rio Verde',
      'Rio Rico',
      'Sacaton',
      'Safford',
      'Sahuarita',
      'Saint David',
      'Salome',
      'San Luis',
      'San Manuel',
      'Scottsdale',
      'Sedona',
      'Sells',
      'Show Low',
      'Sierra Vista',
      'Snowflake',
      'Somerton',
      'South Tucson',
      'Springerville',
      'Star Valley',
      'Sun City',
      'Sun City West',
      'Sun Lakes',
      'Surprise',
      'Swift Trail Junction',
      'Tanque Verde',
      'Tempe',
      'Tempe Junction',
      'Thatcher',
      'Three Points',
      'Tolleson',
      'Tombstone',
      'Tonto Basin',
      'Tortolita',
      'Tuba City',
      'Tubac',
      'Tucson',
      'Tucson Estates',
      'Vail',
      'Wellton',
      'Whetstone',
      'White Mountain Lake',
      'Whiteriver',
      'Wickenburg',
      'Willcox',
      'Williams',
      'Willow Valley',
      'Window Rock',
      'Youngtown',
      'Yuma',
      'Adelanto',
      'Agoura',
      'Agoura Hills',
      'Agua Dulce',
      'Aguanga',
      'Ahwahnee',
      'Alameda',
      'Alhambra',
      'Aliso Viejo',
      'Alondra Park',
      'Alpaugh',
      'Alta Sierra',
      'Altadena',
      'Alum Rock',
      'American Canyon',
      'Amesti',
      'Anaheim',
      'Angels Camp',
      'Angwin',
      'Antelope',
      'Anza',
      'Aptos',
      'Arbuckle',
      'Armona',
      'Aromas',
      'Arroyo Grande',
      'Artesia',
      'Arvin',
      'Atascadero',
      'Atherton',
      'Auberry',
      'Auburn Lake Trails',
      'August',
      'Avenal',
      'Avila Beach',
      'Avocado Heights',
      'Azusa',
      'Bakersfield',
      'Baldwin Park',
      'Banning',
      'Barstow',
      'Barstow Heights',
      'Bay Point',
      'Bear Valley Springs',
      'Bell',
      'Bell Gardens',
      'Bellflower',
      'Ben Lomond',
      'Benicia',
      'Bermuda Dunes',
      'Berry Creek',
      'Bethel Island',
      'Big Bear City',
      'Big Bear Lake',
      'Big Pine',
      'Big River',
      'Biggs',
      'Biola',
      'Blackhawk',
      'Blythe',
      'Bodega Bay',
      'Bodfish',
      'Bolinas',
      'Bonita',
      'Bonny Doon',
      'Bonsall',
      'Boron',
      'Boronda',
      'Borrego Springs',
      'Bostonia',
      'Boulder Creek',
      'Boyle Heights',
      'Boyes Hot Springs',
      'Bradbury',
      'Brawley',
      'Brea',
      'Brisbane',
      'Broadmoor',
      'Brooktrails',
      'Buellton',
      'Buena Park',
      'Burlingame',
      'Buttonwillow',
      'Bystrom',
      'Cabazon',
      'Calabasas',
      'Calexico',
      'California City',
      'Calipatria',
      'Calistoga',
      'Calimesa',
      'Callender',
      'Camarillo',
      'Cambrian Park',
      'Camino',
      'Camp Meeker',
      'Campo',
      'Canoga Park',
      'Canyon Country',
      'Capitola',
      'Carlsbad',
      'Carmel Valley Village',
      'Carmel-by-the-Sea',
      'Carmichael',
      'Carpinteria',
      'Carson',
      'Caruthers',
      'Casa Conejo',
      'Castaic',
      'Castro Valley',
      'Cathedral City',
      'Cayucos',
      'Cedar Ridge',
      'Ceres',
      'Century City',
      'Cerritos',
      'Channel Islands Beach',
      'Charter Oak',
      'Cherryland',
      'China Lake Acres',
      'Chino',
      'Chino Hills',
      'Chowchilla',
      'Chualar',
      'Chula Vista',
      'Citrus',
      'Citrus Heights',
      'Clear Lake Riviera',
      'Clearlake',
      'Clearlake Oaks',
      'Clovis',
      'Coachella',
      'Coalinga',
      'Coarsegold',
      'Cobb',
      'Colma',
      'Colton',
      'Colusa',
      'Compton',
      'Copperopolis',
      'Coronado',
      'Corralitos',
      'Corte Madera',
      'Costa Mesa',
      'Cotati',
      'Coto De Caza',
      'Covelo',
      'Covina',
      'Crest',
      'Culver City',
      'Cupertino',
      'Daly City',
      'Dana Point',
      'Day Valley',
      'Del Mar',
      'Del Monte Forest',
      'Del Aire',
      'Del Rey',
      'Del Rey Oaks',
      'Del Rio',
      'Denair',
      'Descanso',
      'Desert Hot Springs',
      'Desert Shores',
      'Desert View Highlands',
      'Diablo',
      'Diamond Bar',
      'Diamond Springs',
      'Dinuba',
      'Discovery Bay',
      'Dogtown',
      'Dollar Point',
      'Dos Palos',
      'Downey',
      'Downieville',
      'Duarte',
      'Dunnigan',
      'Earlimart',
      'East Rancho Dominguez',
      'East Foothills',
      'East Hemet',
      'East La Mirada',
      'East Los Angeles',
      'East Oakdale',
      'East Palo Alto',
      'East Pasadena',
      'East Porterville',
      'East Quincy',
      'East Richmond Heights',
      'East San Gabriel',
      'East Sonora',
      'El Cajon',
      'El Centro',
      'El Cerrito',
      'El Cerrito Corona',
      'El Dorado Hills',
      'El Granada',
      'El Monte',
      'El Rio',
      'El Segundo',
      'El Sobrante',
      'El Verano',
      'Elk Grove',
      'Elverta',
      'Emerald Lake Hills',
      'Emeryville',
      'Empire',
      'Encinitas',
      'Encino',
      'Escalon',
      'Escondido',
      'Esparto',
      'Eucalyptus Hills',
      'Fairbanks Ranch',
      'Fairmead',
      'Fallbrook',
      'Firebaugh',
      'Florin',
      'Foothill Farms',
      'Foothill Ranch',
      'Forest Meadows',
      'Forest Ranch',
      'Foresthill',
      'Fort Bragg',
      'Fort Irwin',
      'Foster City',
      'Fountain Valley',
      'Frazier Park',
      'French Camp',
      'Galt',
      'Garden Acres',
      'Garden Grove',
      'Gardena',
      'Garnet',
      'Gilroy',
      'Glen Avon',
      'Gold River',
      'Golden Hills',
      'Goleta',
      'Grand Terrace',
      'Granite Bay',
      'Granite Hills',
      'Grass Valley',
      'Graton',
      'Green Acres',
      'Greenacres',
      'Grover Beach',
      'Guerneville',
      'Gustine',
      'Hacienda Heights',
      'Half Moon Bay',
      'Hamilton City',
      'Hanford',
      'Harbison Canyon',
      'Hawaiian Gardens',
      'Healdsburg',
      'Heber',
      'Hemet',
      'Herald',
      'Hercules',
      'Hermosa Beach',
      'Hesperia',
      'Hidden Hills',
      'Hidden Meadows',
      'Hidden Valley Lake',
      'Highgrove',
      'Home Garden',
      'Home Gardens',
      'Homeland',
      'Hughson',
      'Huntington Beach',
      'Huntington Park',
      'Idyllwild',
      'Imperial Beach',
      'Indian Wells',
      'Indio',
      'Inglewood',
      'Interlaken',
      'Inyokern',
      'Ione',
      'Irwindale',
      'Isla Vista',
      'Jamul',
      'Joshua Tree',
      'Julian',
      'Kelseyville',
      'Kennedy',
      'Kentfield',
      'Kerman',
      'Kernville',
      'Kettleman City',
      'Keyes',
      'Kings Beach',
      'Kingsburg',
      'Knightsen',
      'Koreatown',
      'La Cañada Flintridge',
      'La Habra',
      'La Habra Heights',
      'La Jolla',
      'La Mesa',
      'La Mirada',
      'La Palma',
      'La Presa',
      'La Puente',
      'La Quinta',
      'La Riviera',
      'La Selva Beach',
      'La Verne',
      'Ladera',
      'Ladera Heights',
      'Ladera Ranch',
      'Laguna',
      'Laguna Hills',
      'Laguna Niguel',
      'Laguna Woods',
      'Lake Elsinore',
      'Lake Los Angeles',
      'Lake Nacimiento',
      'Lake San Marcos',
      'Lake Wildwood',
      'Lake of the Pines',
      'Lakeland Village',
      'Lakeport',
      'Lamont',
      'Larkspur',
      'Las Flores',
      'Laton',
      'Lawndale',
      'Laytonville',
      'Le Grand',
      'Lebec',
      'Lemon Grove',
      'Lemoore',
      'Lenwood',
      'Leona Valley',
      'Lexington Hills',
      'Linda',
      'Littlerock',
      'Lockeford',
      'Loma Linda',
      'Loma Rica',
      'Lomita',
      'Lompico',
      'Lompoc',
      'Lone Pine',
      'Loomis',
      'Los Alamitos',
      'Los Alamos',
      'Los Altos',
      'Los Altos Hills',
      'Los Angeles',
      'Los Banos',
      'Los Gatos',
      'Los Olivos',
      'Los Osos',
      'Los Serranos',
      'Lost Hills',
      'Lower Lake',
      'Loyola',
      'Lucerne',
      'Lucerne Valley',
      'Madera',
      'Madera Acres',
      'Magalia',
      'Malibu',
      'Mammoth Lakes',
      'Manhattan Beach',
      'Manteca',
      'Marin City',
      'Marina',
      'Marina del Rey',
      'Mariposa',
      'Maxwell',
      'Mayflower Village',
      'Mead Valley',
      'Meadow Vista',
      'Mecca',
      'Meiners Oaks',
      'Menifee',
      'Menlo Park',
      'Mentone',
      'Merced',
      'Mesa Verde',
      'Midpines',
      'Midway City',
      'Mill Valley',
      'Millbrae',
      'Milpitas',
      'Minkler',
      'Jurupa Valley',
      'Mira Mesa',
      'Mira Monte',
      'Mission Canyon',
      'Mission District',
      'Mission Viejo',
      'Modesto',
      'Mojave',
      'Mono Vista',
      'Montalvin',
      'Montara',
      'Monte Rio',
      'Monte Sereno',
      'Montecito',
      'Monterey Park',
      'Moorpark',
      'Morada',
      'Moraga',
      'Moreno Valley',
      'Morgan Hill',
      'Morongo Valley',
      'Morro Bay',
      'Moss Beach',
      'Mountain House',
      'Mountain Ranch',
      'Mountain View Acres',
      'Murphys',
      'Murrieta',
      'Murrieta Hot Springs',
      'Muscoy',
      'Napa',
      'National City',
      'Needles',
      'Nevada City',
      'Newman',
      'Newport Beach',
      'Nice',
      'Niland',
      'Nipomo',
      'North Auburn',
      'North Edwards',
      'North El Monte',
      'North Fair Oaks',
      'North Highlands',
      'North Hollywood',
      'North Richmond',
      'Novato',
      'Nuevo',
      'Oak View',
      'Oakley',
      'Oasis',
      'Occidental',
      'Oceano',
      'Oildale',
      'Ojai',
      'Olivehurst',
      'Orange Cove',
      'Orangevale',
      'Orcutt',
      'Orinda',
      'Orosi',
      'Oroville',
      'Oxnard',
      'Pacheco',
      'Pacifica',
      'Pacific Grove',
      'Pajaro',
      'Palm Desert',
      'Palos Verdes Estates',
      'Paramount',
      'Parksdale',
      'Parkway',
      'Parkwood',
      'Parlier',
      'Pasatiempo',
      'Paso Robles',
      'Pedley',
      'Penn Valley',
      'Penngrove',
      'Perris',
      'Petaluma',
      'Phelan',
      'Pico Rivera',
      'Pine Mountain Club',
      'Pine Valley',
      'Pinole',
      'Piru',
      'Pismo Beach',
      'Pixley',
      'Piñon Hills',
      'Placentia',
      'Placerville',
      'Planada',
      'Plumas Lake',
      'Pollock Pines',
      'Port Hueneme',
      'Portola',
      'Portola Hills',
      'Porterville',
      'Portola Valley',
      'Poway',
      'Prunedale',
      'Quail Valley',
      'Quartz Hill',
      'Rainbow',
      'Ramona',
      'Rancho Calaveras',
      'Rancho Cordova',
      'Rancho Cucamonga',
      'Rancho Mirage',
      'Rancho Murieta',
      'Rancho Palos Verdes',
      'Rancho Penasquitos',
      'Rancho San Diego',
      'Rancho Santa Fe',
      'Rancho Santa Margarita',
      'Redlands',
      'Redondo Beach',
      'Redwood City',
      'Redwood Shores',
      'Redwood Valley',
      'Reedley',
      'Rialto',
      'Richgrove',
      'Ridgemark',
      'Rio Del Mar',
      'Rio Linda',
      'Riverbank',
      'Rocklin',
      'Rodeo',
      'Rohnert Park',
      'Rolling Hills',
      'Rolling Hills Estates',
      'Romoland',
      'Rosamond',
      'Rosemead',
      'Rowland Heights',
      'Rubidoux',
      'Running Springs',
      'Sacramento',
      'Saint Helena',
      'Salida',
      'Salinas',
      'Salton City',
      'San Andreas',
      'San Anselmo',
      'San Antonio Heights',
      'San Bernardino',
      'San Bruno',
      'San Clemente',
      'San Diego Country Estates',
      'San Dimas',
      'San Fernando',
      'San Francisco',
      'San Gabriel',
      'San Jacinto',
      'San Joaquin',
      'San Joaquin Hills',
      'San Jose',
      'San Juan Bautista',
      'San Juan Capistrano',
      'San Leandro',
      'San Lorenzo',
      'San Luis Obispo',
      'San Marino',
      'San Martin',
      'San Mateo',
      'San Miguel',
      'San Pablo',
      'San Pasqual',
      'San Pedro',
      'San Rafael',
      'San Ramon',
      'Santa Ana',
      'Santa Barbara',
      'Santa Clara',
      'Santa Clarita',
      'Santa Cruz',
      'Santa Fe Springs',
      'Santa Margarita',
      'Santa Maria',
      'Santa Monica',
      'Santa Paula',
      'Santa Susana',
      'Santa Venetia',
      'Santa Ynez',
      'Santee',
      'Saranap',
      'Saratoga',
      'Saticoy',
      'Sausalito',
      'Scotts Valley',
      'Sea Ranch',
      'Seacliff',
      'Seal Beach',
      'Searles Valley',
      'Sebastopol',
      'Sedco Hills',
      'Seeley',
      'Seven Trees',
      'Shackelford',
      'Shadow Hills',
      'Shafter',
      'Shandon',
      'Sherman Oaks',
      'Shingle Springs',
      'Sierra Madre',
      'Signal Hill',
      'Simi Valley',
      'Sky Valley',
      'Soda Bay',
      'Solana Beach',
      'Soledad',
      'Solvang',
      'Sonoma',
      'Sonora',
      'Soquel',
      'Sorrento Valley',
      'Soulsbyville',
      'South Dos Palos',
      'South El Monte',
      'South Lake Tahoe',
      'South Oroville',
      'South San Francisco',
      'South San Gabriel',
      'South San Jose Hills',
      'South Whittier',
      'South Yuba City',
      'Squaw Valley',
      'Stallion Springs',
      'Strawberry',
      'Studio City',
      'Suisun',
      'Summerland',
      'Sunland',
      'Sunnyslope',
      'Sutter',
      'Sutter Creek',
      'Taft Heights',
      'Tahoe Vista',
      'Tahoma',
      'Talmage',
      'Tamalpais Valley',
      'Tara Hills',
      'Tarpey Village',
      'Tehachapi',
      'Temecula',
      'Temelec',
      'Temple City',
      'Terra Bella',
      'Thermal',
      'Thermalito',
      'Thousand Oaks',
      'Thousand Palms',
      'Tiburon',
      'Tierra Buena',
      'Topanga',
      'Toro Canyon',
      'Torrance',
      'Trabuco Canyon',
      'Truckee',
      'Tujunga',
      'Tulare',
      'Tuolumne City',
      'Turlock',
      'Tustin',
      'North Tustin',
      'Twain Harte',
      'Twentynine Palms',
      'Ukiah',
      'Upper Lake',
      'Vacaville',
      'Val Verde',
      'Valencia',
      'Valinda',
      'Valle Vista',
      'Vallejo',
      'Valley Springs',
      'Van Nuys',
      'Vandenberg Village',
      'Ventura',
      'Victorville',
      'Visalia',
      'Vista',
      'Vista Santa Rosa',
      'Waldon',
      'Walnut Creek',
      'Walnut Park',
      'Watsonville',
      'Weedpatch',
      'West Athens',
      'West Carson',
      'West Rancho Dominguez',
      'West Covina',
      'West Menlo Park',
      'West Puente Valley',
      'West Sacramento',
      'Westlake Village',
      'Westmorland',
      'Wheatland',
      'Whittier',
      'Wildomar',
      'Willits',
      'Willows',
      'Winter Gardens',
      'Wofford Heights',
      'Woodacre',
      'Woodcrest',
      'Woodlake',
      'Woodland',
      'Woodland Hills',
      'Wrightwood',
      'Yorba Linda',
      'Yosemite Lakes',
      'Yountville',
      'Yuba City',
      'Yucaipa',
      'Yucca Valley',
      'Acres Green',
      'Alamosa',
      'Applewood',
      'Arvada',
      'Aspen',
      'Basalt',
      'Battlement Mesa',
      'Bayfield',
      'Bennett',
      'Black Forest',
      'Broomfield',
      'Byers',
      'Cañon City',
      'Carriage Club',
      'Castle Pines',
      'Castle Rock',
      'Cedaredge',
      'Centennial',
      'Cherry Hills Village',
      'Cheyenne Wells',
      'Cimarron Hills',
      'Coal Creek',
      'Colorado Springs',
      'Columbine',
      'Columbine Valley',
      'Commerce City',
      'Conejos',
      'Creede',
      'Crested Butte',
      'Cripple Creek',
      'Del Norte',
      'Dove Creek',
      'Durango',
      'Eads',
      'El Jebel',
      'Ellicott',
      'Fairplay',
      'Federal Heights',
      'Fort Carson',
      'Fountain',
      'Fruita',
      'Fruitvale',
      'Genesee',
      'Gleneagle',
      'Glenwood Springs',
      'Golden',
      'Grand Junction',
      'Greenwood Village',
      'Gunnison',
      'Gypsum',
      'Highlands Ranch',
      'Idaho Springs',
      'Ken Caryl',
      'Kittredge',
      'La Junta',
      'Las Animas',
      'Leadville',
      'Limon',
      'Loma',
      'Mancos',
      'Manitou Springs',
      'Minturn',
      'Monte Vista',
      'Monument',
      'Mountain Village',
      'Northglenn',
      'Orchard Mesa',
      'Orchard City',
      'Ordway',
      'Ouray',
      'Pagosa Springs',
      'Palisade',
      'Palmer Lake',
      'Parachute',
      'Penrose',
      'Perry Park',
      'Ponderosa Park',
      'Pueblo',
      'Pueblo West',
      'Rifle',
      'Rocky Ford',
      'Roxborough Park',
      'Saguache',
      'Sherrelwood',
      'Silt',
      'Silverthorne',
      'Snowmass Village',
      'Southglenn',
      'Stonegate',
      'Stratmoor',
      'Telluride',
      'The Pinery',
      'Todd Creek',
      'Towaoc',
      'Trinidad',
      'Walsenburg',
      'Welby',
      'Westcliffe',
      'Wheat Ridge',
      'Woodmoor',
      'Dodge City',
      'Gove',
      'Hugoton',
      'Lakin',
      'Leoti',
      'Liberal',
      'Meade',
      'Satanta',
      'Sharon Springs',
      'Sublette',
      'Tribune',
      'Ulysses',
      'Agua Fria',
      'Alamogordo',
      'Albuquerque',
      'Angel Fire',
      'Arenas Valley',
      'Arroyo Seco',
      'Aztec',
      'Bayard',
      'Belen',
      'Berino',
      'Bernalillo',
      'Black Rock',
      'Boles Acres',
      'Bosque Farms',
      'Capitan',
      'Carnuel',
      'Carrizozo',
      'Chama',
      'Chaparral',
      'Chimayo',
      'Church Rock',
      'Corrales',
      'Crownpoint',
      'Deming',
      'Doña Ana',
      'Dulce',
      'El Cerro',
      'El Rancho',
      'El Valle de Arroyo Seco',
      'Eldorado at Santa Fe',
      'Elephant Butte',
      'Española',
      'Estancia',
      'Flora Vista',
      'Fort Sumner',
      'Gallup',
      'Grants',
      'Hagerman',
      'Hatch',
      'Hobbs',
      'Jal',
      'Jarales',
      'Jemez Pueblo',
      'La Cienega',
      'La Huerta',
      'La Luz',
      'La Mesilla',
      'La Puebla',
      'La Union',
      'Las Cruces',
      'Las Vegas',
      'Lee Acres',
      'Lordsburg',
      'Los Chavez',
      'Los Lunas',
      'Los Ranchos de Albuquerque',
      'Loving',
      'McIntosh',
      'Meadow Lake',
      'Mescalero',
      'Mesilla',
      'Moriarty',
      'Mosquero',
      'Nambe',
      'Navajo',
      'North Valley',
      'Paradise Hills',
      'Pecos',
      'Peralta',
      'Placitas',
      'Ponderosa Pine',
      'Portales',
      'Questa',
      'Radium Springs',
      'Ranchos de Taos',
      'Raton',
      'Rio Rancho',
      'Ruidoso',
      'Ruidoso Downs',
      'San Felipe Pueblo',
      'San Ysidro',
      'Sandia Heights',
      'Sandia Knolls',
      'Santa Clara Pueblo',
      'Santa Teresa',
      'Santo Domingo Pueblo',
      'Shiprock',
      'Silver City',
      'Socorro',
      'South Valley',
      'Sunland Park',
      'Taos Pueblo',
      'Texico',
      'Thoreau',
      'Tierra Amarilla',
      'Tome',
      'Truth or Consequences',
      'Tucumcari',
      'Tularosa',
      'Upper Fruitland',
      'Vado',
      'Waterflow',
      'White Rock',
      'White Sands',
      'Zuni Pueblo',
      'Boulder City',
      'Bunkerville',
      'Caliente',
      'Cold Springs',
      'Fallon',
      'Fernley',
      'Gardnerville',
      'Gardnerville Ranchos',
      'Goldfield',
      'Incline Village',
      'Johnson Lane',
      'Kingsbury',
      'Laughlin',
      'Lemmon Valley',
      'McGill',
      'Moapa Town',
      'Moapa Valley',
      'Mogul',
      'North Las Vegas',
      'Pahrump',
      'Pioche',
      'Sandy Valley',
      'Smith',
      'Spanish Springs',
      'Stagecoach',
      'Sunrise Manor',
      'Tonopah',
      'Verdi',
      'Virginia City',
      'Yerington',
      'Boise City',
      'Goodwell',
      'Guymon',
      'Hooker',
      'Abernathy',
      'Amarillo',
      'Aspermont',
      'Big Spring',
      'Booker',
      'Borger',
      'Bovina',
      'Brackettville',
      'Bushland',
      'Canadian',
      'Canutillo',
      'Canyon',
      'Channing',
      'Childress',
      'Cienegas Terrace',
      'Claude',
      'Clint',
      'Crosbyton',
      'Dalhart',
      'Denver City',
      'Dickens',
      'Dimmitt',
      'Eagle Pass',
      'Earth',
      'Eidson Road',
      'Elm Creek',
      'Fabens',
      'Farwell',
      'Floydada',
      'Fort Clark Springs',
      'Fort Davis',
      'Fort Hancock',
      'Fort Stockton',
      'Friona',
      'Fritch',
      'Gail',
      'Grape Creek',
      'Hale Center',
      'Hereford',
      'Homestead Meadows North',
      'Homestead Meadows South',
      'Horizon City',
      'Idalou',
      'Iraan',
      'Jayton',
      'Kermit',
      'Lamesa',
      'Las Quintas Fronterizas',
      'Las Quintas Fronterizas Colonia',
      'Levelland',
      'Littlefield',
      'Lockney',
      'Lorenzo',
      'Lubbock',
      'Marfa',
      'Matador',
      'McCamey',
      'Merkel',
      'Mertzon',
      'Monahans',
      'Muleshoe',
      'Olton',
      'Ozona',
      'Pampa',
      'Panhandle',
      'Perryton',
      'Post',
      'Presidio',
      'Ransom Canyon',
      'Robert Lee',
      'Roby',
      'Rocksprings',
      'Rosita North',
      'Rosita South',
      'Rotan',
      'San Angelo',
      'San Elizario',
      'Sanderson',
      'Seagraves',
      'Seth Ward',
      'Shallowater',
      'Sierra Blanca',
      'Slaton',
      'Socorro Mission Number 1 Colonia',
      'Spearman',
      'Sterling City',
      'Stinnett',
      'Sundown',
      'Sunray',
      'Tahoka',
      'Tornillo',
      'Tulia',
      'Val Verde Park',
      'Van Horn',
      'Vega',
      'West Odessa',
      'Westway',
      'Wheeler',
      'Wink',
      'Wolfforth',
      'Blanding',
      'Carbonville',
      'Castle Dale',
      'Cedar City',
      'East Carbon City',
      'Enoch',
      'Ephraim',
      'Ferron',
      'Fountain Green',
      'Genola',
      'Helper',
      'Hildale',
      'Ivins',
      'Kanab',
      'LaVerkin',
      'Loa',
      'Manti',
      'Moab',
      'Moroni',
      'Nephi',
      'Orangeville',
      'Panguitch',
      'Parowan',
      'Price',
      'Santaquin',
      'Spring Glen',
      'Toquerville',
      'West Sedona',
      'West Bishop',
      'Paonia',
      'Cimarron',
      'Pojoaque',
      'Beatty',
      'Anton',
      'Gruver',
      'Ralls',
      'Spur',
      'Centerfield',
      'Ajo',
      'Anthem',
      'Apache Junction',
      'Arivaca Junction',
      'Arizona City',
      'Avra Valley',
      'Ahwatukee Foothills',
      'Big Park',
      'Wray',
      'Rathdrum',
      'Beach',
      'Ketchikan',
      'Little Cottonwood Creek Valley',
      'Lea Hill',
      'Metlakatla',
      'Sitka',
      'Wrangell',
      'Arcata',
      'Bayview',
      'Blue Lake',
      'Burney',
      'Central Valley (historical)',
      'Cutten',
      'Dunsmuir',
      'Fortuna',
      'Gerber',
      'Happy Camp',
      'Hayfork',
      'Humboldt Hill',
      'Hydesville',
      'Johnstonville',
      'Los Molinos',
      'McCloud',
      'McKinleyville',
      'Mount Shasta',
      'Myrtletown',
      'Palo Cedro',
      'Red Bluff',
      'Redding',
      'Redway',
      'Rio Dell',
      'Shasta',
      'Shasta Lake',
      'Shingletown',
      'Susanville',
      'Weed',
      'Willow Creek',
      'Yreka',
      'Aristocrat Ranchettes',
      'Ault',
      'Berthoud',
      'Boulder',
      'Brush',
      'Campion',
      'Craig',
      'Dacono',
      'Estes Park',
      'Firestone',
      'Fort Collins',
      'Fort Lupton',
      'Fort Morgan',
      'Gilcrest',
      'Hot Sulphur Springs',
      'Julesburg',
      'Keenesburg',
      'Kersey',
      'Kremmling',
      'Lochbuie',
      'Longmont',
      'Mead',
      'Milliken',
      'Niwot',
      'Rangely',
      'Severance',
      'Steamboat Springs',
      'American Falls',
      'Ammon',
      'Arco',
      'Ashton',
      'Blackfoot',
      'Boise',
      'Bonners Ferry',
      'Buhl',
      'Burley',
      'Challis',
      'Chubbuck',
      "Coeur d'Alene",
      'Council',
      'Dalton Gardens',
      'Driggs',
      'Dubois',
      'Emmett',
      'Filer',
      'Fort Hall',
      'Glenns Ferry',
      'Gooding',
      'Grangeville',
      'Hailey',
      'Hansen',
      'Heyburn',
      'Homedale',
      'Idaho City',
      'Idaho Falls',
      'Kamiah',
      'Kellogg',
      'Ketchum',
      'Kuna',
      'Lapwai',
      'Lewiston Orchards',
      'Malad City',
      'Marsing',
      'McCall',
      'Moreland',
      'Nampa',
      'New Plymouth',
      'Nezperce',
      'Orofino',
      'Osburn',
      'Paul',
      'Payette',
      'Plummer',
      'Pocatello',
      'Ponderay',
      'Post Falls',
      'Priest River',
      'Rexburg',
      'Rigby',
      'Rupert',
      'Saint Maries',
      'Salmon',
      'Sandpoint',
      'Shelley',
      'Shoshone',
      'Soda Springs',
      'Star',
      'Sugar City',
      'Twin Falls',
      'Tyhee',
      'Ucon',
      'Weiser',
      'Absarokee',
      'Anaconda',
      'Big Sky',
      'Big Timber',
      'Bigfork',
      'Bozeman',
      'Broadus',
      'Browning',
      'Chinook',
      'Choteau',
      'Circle',
      'Clancy',
      'Colstrip',
      'Columbia Falls',
      'Crow Agency',
      'Cut Bank',
      'Deer Lodge',
      'East Helena',
      'East Missoula',
      'Ekalaka',
      'Fort Belknap Agency',
      'Fort Benton',
      'Glendive',
      'Harlowton',
      'Havre',
      'Hysham',
      'Kalispell',
      'Lame Deer',
      'Libby',
      'Lockwood',
      'Lolo',
      'Miles City',
      'Missoula',
      'Montana City',
      'North Browning',
      'Orchard Homes',
      'Pablo',
      'Plentywood',
      'Polson',
      'Red Lodge',
      'Ronan',
      'Roundup',
      'Ryegate',
      'Scobey',
      'Seeley Lake',
      'South Browning',
      'Thompson Falls',
      'Three Forks',
      'West Glendive',
      'West Yellowstone',
      'Whitefish',
      'Wibaux',
      'Winnett',
      'Wolf Point',
      'Amidon',
      'Belfield',
      'Bottineau',
      'Bowbells',
      'Bowman',
      'Fort Yates',
      'Hettinger',
      'Kenmare',
      'Killdeer',
      'Mandan',
      'McClusky',
      'Medora',
      'Mohall',
      'Mott',
      'New Town',
      'Parshall',
      'Surrey',
      'Tioga',
      'Towner',
      'Velva',
      'Watford City',
      'Benkelman',
      'Chadron',
      'Chappell',
      'Gering',
      'Gothenburg',
      'Hayes Center',
      'McCook',
      'Mullen',
      'North Platte',
      'Ogallala',
      'Scottsbluff',
      'Stockville',
      'Sutherland',
      'Thedford',
      'Valentine',
      'Battle Mountain',
      'Carlin',
      'Elko',
      'Jackpot',
      'Lovelock',
      'Spring Creek',
      'West Wendover',
      'Winnemucca',
      'Aloha',
      'Amity',
      'Athena',
      'Aumsville',
      'Baker City',
      'Bandon',
      'Banks',
      'Barview',
      'Bend',
      'Cannon Beach',
      'Canyon City',
      'Canyonville',
      'Cascade Locks',
      'Cave Junction',
      'Cedar Hills',
      'Cedar Mill',
      'Central Point',
      'Chenoweth',
      'Clackamas',
      'Clatskanie',
      'Coburg',
      'Coos Bay',
      'Coquille',
      'Corvallis',
      'Creswell',
      'Culp Creek',
      'Depoe Bay',
      'Deschutes River Woods',
      'Donald',
      'Drain',
      'Dunes City',
      'Eagle Point',
      'Estacada',
      'Eugene',
      'Forest Grove',
      'Fossil',
      'Gearhart',
      'Gervais',
      'Glide',
      'Gold Hill',
      'Grand Ronde',
      'Grants Pass',
      'Gresham',
      'Happy Valley',
      'Harbor',
      'Heppner',
      'Hermiston',
      'Hines',
      'Hood River',
      'Irrigon',
      'Island City',
      'Jennings Lodge',
      'John Day',
      'Joseph',
      'Keizer',
      'Klamath Falls',
      'La Grande',
      'La Pine',
      'Lake Oswego',
      'Lents',
      'Lincoln Beach',
      'Lincoln City',
      'Madras',
      'Merlin',
      'Metzger',
      'Mill City',
      'Milton-Freewater',
      'Milwaukie',
      'Molalla',
      'Moro',
      'Mount Angel',
      'Mount Hood Village',
      'Mulino',
      'Myrtle Creek',
      'Myrtle Point',
      'Newberg',
      'North Plains',
      'North Portland',
      'Nyssa',
      'Oakridge',
      'Odell',
      'Oregon City',
      'Pacific City',
      'Philomath',
      'Pilot Rock',
      'Port Orford',
      'Prineville',
      'Rainier',
      'Raleigh Hills',
      'Redmond',
      'Reedsport',
      'Riddle',
      'Rockcreek',
      'Rockaway Beach',
      'Rogue River',
      'Rose Lodge',
      'Roseburg',
      'Saint Helens',
      'Scappoose',
      'Shady Cove',
      'Siletz',
      'Sisters',
      'Stayton',
      'Sublimity',
      'Sunriver',
      'Sutherlin',
      'Sweet Home',
      'Talent',
      'Tangent',
      'Terrebonne',
      'The Dalles',
      'Tigard',
      'Tillamook',
      'Tri-City',
      'Troutdale',
      'Tualatin',
      'Vale',
      'Veneta',
      'Vernonia',
      'Waldport',
      'West Linn',
      'West Slope',
      'Willamina',
      'Wood Village',
      'Yamhill',
      'Yoncalla',
      'Belle Fourche',
      'Bison',
      'Box Elder',
      'Colonial Pine Hills',
      'Custer',
      'Deadwood',
      'Dupree',
      'Eagle Butte',
      'Fort Pierre',
      'Kadoka',
      'Lead',
      'Lemmon',
      'Mobridge',
      'Murdo',
      'North Eagle Butte',
      'North Spearfish',
      'Oglala',
      'Onida',
      'Philip',
      'Pierre',
      'Porcupine',
      'Rapid Valley',
      'Selby',
      'Spearfish',
      'Timber Lake',
      'White River',
      'Ballard',
      'Bluffdale',
      'Bountiful',
      'Brigham City',
      'Canyon Rim',
      'Coalville',
      'Cottonwood Heights',
      'Daniel',
      'Draper',
      'Duchesne',
      'East Millcreek',
      'Elk Ridge',
      'Erda',
      'Farr West',
      'Francis',
      'Fruit Heights',
      'Heber City',
      'Herriman',
      'Holladay',
      'Honeyville',
      'Hooper',
      'Hyrum',
      'Kamas',
      'Kaysville',
      'Kearns',
      'Layton',
      'Lehi',
      'Lindon',
      'Maeser',
      'Magna',
      'Marriott-Slaterville',
      'Midvale',
      'Millcreek',
      'Mount Olympus',
      'Mountain Green',
      'Nibley',
      'North Logan',
      'North Ogden',
      'North Salt Lake',
      'Oquirrh',
      'Orem',
      'Provo',
      'River Heights',
      'Roy',
      'Salt Lake City',
      'Sandy Hills',
      'Snyderville',
      'South Jordan Heights',
      'South Jordan',
      'South Ogden',
      'South Salt Lake',
      'South Weber',
      'South Willard',
      'Spanish Fork',
      'Stansbury park',
      'Summit Park',
      'Tooele',
      'Tremonton',
      'Uintah',
      'Vernal',
      'Washington Terrace',
      'Wendover',
      'West Bountiful',
      'West Jordan',
      'West Mountain',
      'West Valley City',
      'Wolf Creek',
      'Woods Cross',
      'Ahtanum',
      'Airway Heights',
      'Alderton',
      'Alderwood Manor',
      'Allyn',
      'Ames Lake',
      'Anacortes',
      'Artondale',
      'Asotin',
      'Basin City',
      'Belfair',
      'Benton City',
      'Birch Bay',
      'Bonney Lake',
      'Bothell',
      'Boulevard Park',
      'Bremerton',
      'Brier',
      'Browns Point',
      'Brush Prairie',
      'Buckley',
      'Burien',
      'Camano',
      'Camas',
      'Canterwood',
      'Carnation',
      'Cascade Valley',
      'Cashmere',
      'Cathcart',
      'Cathlamet',
      'Central Park',
      'Chehalis',
      'Chelan',
      'Chewelah',
      'Cle Elum',
      'Clearview',
      'Clyde Hill',
      'College Place',
      'Colville',
      'Connell',
      'Cosmopolis',
      'Cottage Lake',
      'Coulee Dam',
      'Country Homes',
      'Coupeville',
      'Dallesport',
      'Darrington',
      'Desert Aire',
      'Dishman',
      'DuPont',
      'Duvall',
      'East Port Orchard',
      'East Renton Highlands',
      'East Wenatchee',
      'East Wenatchee Bench',
      'Eastgate',
      'Edmonds',
      'Electric City',
      'Elk Plain',
      'Ellensburg',
      'Elma',
      'Enetai',
      'Entiat',
      'Enumclaw',
      'Esperance',
      'Everson',
      'Fairwood',
      'Fall City',
      'Felida',
      'Federal Way',
      'Fern Prairie',
      'Fife',
      'Fife Heights',
      'Fircrest',
      'Five Corners',
      'Fobes Hill',
      'Fords Prairie',
      'Forks',
      'Fox Island',
      'Frederickson',
      'Friday Harbor',
      'Gig Harbor',
      'Gleed',
      'Gold Bar',
      'Goldendale',
      'Grand Coulee',
      'Grand Mound',
      'Hansville',
      'Hazel Dell',
      'Hockinson',
      'Home',
      'Hoquiam',
      'Issaquah',
      'Kalama',
      'Kelso',
      'Kennewick',
      'Kettle Falls',
      'Key Center',
      'Kingsgate',
      'Kittitas',
      'Lacey',
      'Lake Forest Park',
      'Lake Stevens',
      'Liberty Lake',
      'Lochsloy',
      'Lofall',
      'Longbranch',
      'Longview Heights',
      'Lynden',
      'Lynnwood',
      'Mabton',
      'Maltby',
      'Maple Valley',
      'Martha Lake',
      'Mattawa',
      'McCleary',
      'McMillin',
      'Meadow Glade',
      'Meadowdale',
      'Medical Lake',
      'Mercer Island',
      'Mill Creek',
      'Mill Plain',
      'Millwood',
      'Minnehaha',
      'Mirrormont',
      'Montesano',
      'Moses Lake',
      'Mount Vista',
      'Mountlake Terrace',
      'Mukilteo',
      'Napavine',
      'Navy Yard City',
      'Nooksack',
      'Normandy Park',
      'North Creek',
      'North Puyallup',
      'North Yelm',
      'Ocean Park',
      'Ocean Shores',
      'Okanogan',
      'Olympia',
      'Omak',
      'Opportunity',
      'Orchards',
      'Orting',
      'Othello',
      'Pasco',
      'Point Roberts',
      'Port Angeles',
      'Port Ludlow',
      'Port Orchard',
      'Port Townsend',
      'Poulsbo',
      'Prairie Ridge',
      'Prosser',
      'Pullman',
      'Puyallup',
      'Ravensdale',
      'Renton',
      'Riverbend',
      'Ritzville',
      'Royal City',
      'Salmon Creek',
      'Sammamish',
      'SeaTac',
      'Seabeck',
      'Seattle',
      'Sedro-Woolley',
      'Selah',
      'Sequim',
      'Shoreline',
      'Silverdale',
      'Sisco Heights',
      'Smokey Point',
      'Snohomish',
      'Snoqualmie',
      'Soap Lake',
      'Southworth',
      'Spanaway',
      'Spokane',
      'Spokane Valley',
      'Stanwood',
      'Steilacoom',
      'Sudden Valley',
      'Sultan',
      'Sumas',
      'Suquamish',
      'Tacoma',
      'Tanner',
      'Tenino',
      'Tieton',
      'Tonasket',
      'Toppenish',
      'Tracyton',
      'Trentwood',
      'Tukwila',
      'Tulalip',
      'Tulalip Bay',
      'Tumwater',
      'Union Gap',
      'University Place',
      'Vancouver',
      'Vashon',
      'Veradale',
      'Venersborg',
      'Waitsburg',
      'Walla Walla',
      'Wapato',
      'Warden',
      'Warm Beach',
      'Washougal',
      'Wauna',
      'Wenatchee',
      'West Lake Sammamish',
      'West Lake Stevens',
      'West Longview',
      'West Pasco',
      'West Richland',
      'West Valley',
      'West Wenatchee',
      'White Center',
      'White Salmon',
      'Winlock',
      'Bainbridge Island',
      'Wollochet',
      'Woodinville',
      'Woods Creek',
      'Yacolt',
      'Yakima',
      'Yarrow Point',
      'Yelm',
      'Zillah',
      'Bar Nunn',
      'Basin',
      'Casper',
      'Cody',
      'Ethete',
      'Fort Washakie',
      'Gillette',
      'Glenrock',
      'Green River',
      'Greybull',
      'Guernsey',
      'Hoback',
      'Kemmerer',
      'Lander',
      'Laramie',
      'Lusk',
      'Marbleton',
      'Mills',
      'Moorcroft',
      'Moose Wilson Road',
      'North Rock Springs',
      'Pine Bluffs',
      'Pinedale',
      'Rafter J Ranch',
      'Ranchettes',
      'Rawlins',
      'Rock Springs',
      'South Greeley',
      'South Park',
      'Sundance',
      'Thermopolis',
      'Worland',
      'Vineyard',
      'Fruitdale',
      'Gold Beach',
      'American Fork',
      'Palouse',
      'Haines',
      'Kahalu‘u',
      'Kahuku',
      'Kahului',
      'Kailua',
      'Kailua-Kona',
      'Kalāheo',
      'Kalaoa',
      'Kaneohe',
      'Kapa‘a',
      'Kapaau',
      'Kaunakakai',
      'Kea‘au',
      'Kealakekua',
      'Kekaha',
      'Kēōkea',
      'Kīhei',
      'Kīlauea',
      'Koloa',
      'Kualapu‘u',
      'Kurtistown',
      'Lahaina',
      'Lā‘ie',
      'Lanai City',
      'Lawai',
      'Leilani Estates',
      'Lihue',
      'Mā‘ili',
      'Mākaha',
      'Mākaha Valley',
      'Makakilo City',
      'Makawao',
      'Maunawili',
      'Mililani Town',
      'Mokulēia',
      'Nānākuli',
      'Nanawale Estates',
      '‘Ōma‘o',
      'Orchidlands Estates',
      'Pāhala',
      'Paia',
      'Pāpa‘ikou',
      'Pearl City',
      'Pepeekeo',
      'Puhi',
      'Pukalani',
      'Punalu‘u',
      'Punaluu',
      'Pupukea',
      'Village Park',
      'Volcano',
      'Wahiawā',
      'Waialua',
      'Waianae',
      'Waikapū',
      'Waikoloa',
      'Wailea',
      'Wailua',
      'Wailuku',
      'Waimalu',
      'Waimanalo',
      'Waimānalo Beach',
      'Waimea',
      'Wainaku',
      'Waipahu',
      'Waipio',
      'Waipi‘o Acres',
      'Whitmore Village',
      '‘Ele‘ele',
      '‘Ewa Beach',
      '‘Ewa Gentry',
      '‘Ewa Villages',
      'Fern Acres',
      'Hālawa',
      'Hālawa Heights',
      'Hale‘iwa',
      'Hana',
      'Hanamā‘ulu',
      'Hanapēpē',
      'Hanapēpē Heights',
      'Hau‘ula',
      'Hawaiian Beaches',
      'Hawaiian Acres',
      'Hawaiian Paradise Park',
      'Hawaiian Ocean View',
      'Hāwī',
      'He‘eia',
      'Hilo',
      'Hōlualoa',
      'Honalo',
      'Honoka‘a',
      'Honolulu',
      '‘Aiea',
      '‘Āhuimanu',
      'Iroquois Point',
      'Ka‘a‘awa',
      'Kā‘anapali',
      'Kaanapali Landing',
      'Ainaloa',
      'Anahola',
      'Captain Cook',
      'Chevak',
      'Cohoe',
      'College',
      'Diamond Ridge',
      'Dillingham',
      'Dutch Harbor',
      'Ester',
      'Fairbanks',
      'Fritz Creek',
      'Girdwood',
      'Healy',
      'Hooper Bay',
      'Kalifornsky',
      'Kenai',
      'King Cove',
      'Kodiak',
      'Kotzebue',
      'Lazy Mountain',
      'Meadow Lakes',
      'Nikiski',
      'Nome',
      'North Pole',
      'Prudhoe Bay',
      'Ridgeway',
      'Sand Point',
      'Soldotna',
      'Tanaina',
      'Tok',
      'Unalaska',
      'Valdez',
      'Wasilla',
      'Willow',
      'Akutan',
      'Anchor Point',
      'Badger',
      'Barrow',
      'Johns Creek',
      'Grant-Valkaria',
      'Helena-West Helena',
      'Summerset',
      'Cutler Bay',
      'Morningside Heights',
      'East Harlem',
      'Alafaya',
      'New Fairview',
      'Cobb Island',
      'St Johnsbury',
      'Briarwood',
      'Stevenson Ranch',
      'Holly Lake Ranch',
      'Village of Campton Hills',
      'Kapolei',
      'Elko New Market',
      'South Blooming Grove',
      'Dewey-Humboldt',
      'City of Milford (balance)',
      'Peppermill Village',
      'Boulevard Gardens',
      'Roosevelt Gardens',
      'City of Sammamish',
      'Las Maravillas',
      'Peaceful Valley',
      'Rancho Tehama Reserve',
      'Rio Communities',
      'Silver Firs',
      'Verde Village',
      'Chattahoochee Hills',
      'Gramercy Park',
      'Desert Edge',
      'Fox Chase',
      'Saint Augustine South',
      'Captains Cove',
      'Weston Lakes',
      'North Castle',
      'Saddle Brooke',
      'Kew Gardens Hills',
      'Conning Towers-Nautilus Park',
      'Deep River Center',
      'Essex Village',
      'Guilford Center',
      'Madison Center',
      'New Hartford Center',
      'Northwest Harwinton',
      'Old Saybrook Center',
      'Oxoboxo River',
      'Plainfield Village',
      'Simsbury Center',
      'Wallingford Center',
      'Westbrook Center',
      'Woodbury Center',
      'Acushnet Center',
      'Braintree',
      'Green Harbor-Cedar Crest',
      'Harwich Center',
      'Tilton-Northfield',
      'Newport East',
      'Wakefield-Peacedale',
      'Mansfield Center',
      'Marion Center',
      'Mattapoisett Center',
      'Middleborough Center',
      'Millis-Clicquot',
      'Monson Center',
      'North Attleborough Center',
      'Northwest Harwich',
      'Norton Center',
      'Ocean Bluff-Brant Rock',
      'Bethlehem Village',
      'Branford Center',
      'Central Waterford',
      'Cheshire Village',
      'Chester Center',
      'Fairview-Ferndale',
      'Fort Belvoir',
      'Fort Drum',
      'Aberdeen Proving Ground',
      'Andrews AFB',
      'Ashton-Sandy Spring',
      'Beaverdam Lake-Salisbury Mills',
      'Bel Air North',
      'Bel Air South',
      'Brittany Farms-Highlands',
      'Carmel Hamlet',
      'Carnot-Moon',
      'Cecil-Bishop',
      'Conneaut Lakeshore',
      'Cortland West',
      'Dover Base Housing',
      'East Hampton North',
      'McGuire AFB',
      'Mercerville-Hamilton Square',
      'Moorestown-Lenola',
      'Mount Gay-Shamrock',
      'Naval Academy',
      'New Castle Northwest',
      'North Ballston Spa',
      'Northeast Ithaca',
      'Northwest Harborcreek',
      'Northwest Ithaca',
      'Oxon Hill-Glassmanor',
      'Paramount-Long Meadow',
      'Plattsburgh West',
      'Pymatuning Central',
      'Quantico Station',
      'Remsenburg-Speonk',
      'Rising Sun-Lebanon',
      'Rosendale Village',
      'Leisure World',
      'Fountainhead-Orchard Hills',
      'Gates-North Gates',
      'Glens Falls North',
      'Greater Upper Marlboro',
      'Greenport West',
      'Holiday City South',
      'Holiday City-Berkeley',
      'Homeacre-Lyndora',
      'Horseheads North',
      'Jamestown West',
      'West Falls Church',
      'Jefferson Valley-Yorktown',
      'Lanham-Seabrook',
      'Lebanon South',
      'Leisure Village West-Pine Lake Park',
      'Leith-Hatfield',
      'Lutherville-Timonium',
      'Lynnwood-Pricedale',
      'Saugerties South',
      'Setauket-East Setauket',
      'Shanor-Northvue',
      'South Park Township',
      'Suitland-Silver Hill',
      'Village Green-Green Ridge',
      'Weissport East',
      'West Caldwell',
      'Whitesboro-Burleigh',
      'Whittingham',
      'Wickerham Manor-Fisher',
      'Wilson-Conococheague',
      'Woodside East',
      'Fort Leonard Wood',
      'Fort Riley North',
      'Grand Forks Air Force Base',
      'Granville South',
      'Grissom Air Force Base',
      'K. I. Sawyer Air Force Base',
      'Legend Lake',
      'Level Park-Oak Park',
      'Manitou Beach-Devils Lake',
      'Minot Air Force Base',
      'Montrose-Ghent',
      'Offutt Air Force Base',
      'Saginaw Township North',
      'Scott Air Force Base',
      'Shorewood-Tower Hills-Harbert',
      'Barnes Lake-Millers Lake',
      'Champion Heights',
      'Comstock Northwest',
      'West Bloomfield Township',
      'Whiteman Air Force Base',
      'Wright-Patterson AFB',
      'Dade City North',
      'De Land Southwest',
      'East Bronson',
      'East Lake-Orient Park',
      'Eglin Air Force Base',
      'Fort Campbell North',
      'Fort Knox',
      'Fort Pierce North',
      'Fort Pierce South',
      'Fort Polk North',
      'Fort Polk South',
      'Fort Rucker',
      'Fort Stewart',
      'Greater Northdale',
      'Hutchinson Island South',
      'Inverness Highlands North',
      'Inverness Highlands South',
      'Bellair-Meadowbrook Terrace',
      'Candler-McAfee',
      'Cocoa West',
      'Columbus Air Force Base',
      'Samsula-Spruce Creek',
      'Sandalfoot Cove',
      'Selmont-West Selmont',
      'Slater-Marietta',
      'Southeast Arcadia',
      'Tyndall Air Force Base',
      'Underwood-Petersville',
      'Vero Beach South',
      'Watts Mills',
      'West End-Cobb Town',
      'Zephyrhills North',
      'Zephyrhills South',
      'Zephyrhills West',
      'Kendall West',
      'Kings Bay Base',
      'Lake Mack-Forest Hills',
      'Lake Murray of Richland',
      'Meridian Station',
      'Monarch Mill',
      'Nassau Village-Ratliff',
      'New Port Richey East',
      'North Weeki Wachee',
      'Palm River-Clair Mel',
      'Redstone Arsenal',
      'Robins Air Force Base',
      'Clarkston Heights-Vineland',
      'Air Force Academy',
      'Alamosa East',
      'Antelope Valley-Crestview',
      'Aptos Hills-Larkin Valley',
      'Arden-Arcade',
      'Ault Field',
      'Bangor Trident Base',
      'Beale Air Force Base',
      'Bertsch-Oceanview',
      'Village of Oak Creek (Big Park)',
      'Black Point-Green Point',
      'Bonadelle Ranchos-Madera Ranchos',
      'Bonner-West Riverside',
      'Bret Harte',
      'Bryn Mawr-Skyway',
      'Camp Pendleton North',
      'Camp Pendleton South',
      'Cannon Air Force Base',
      'Casa de Oro-Mount Helix',
      'Cascade-Chipita Park',
      'Central Heights-Midland City',
      'Challenge-Brownsville',
      'Edwards Air Force Base',
      'El Cerro Mission',
      'Erlands Point-Kitsap Lake',
      'Fairchild Air Force Base',
      'Lucas Valley-Marinwood',
      'Malmstrom Air Force Base',
      'Maple Heights-Lake Desire',
      'March Air Force Base',
      'Marietta-Alderwood',
      'McChord Air Force Base',
      'Fetters Hot Springs-Agua Caliente',
      'Florence-Graham',
      'Fort Bliss',
      'Fort Hood',
      'Joint Base Lewis McChord',
      'Fox Farm-College',
      'Garden Home-Whitford',
      'Gold Canyon',
      'Gunbarrel',
      'Heber-Overgaard',
      'Helena Valley Northeast',
      'Helena Valley Northwest',
      'Helena Valley Southeast',
      'Helena Valley West Central',
      'Helena West Side',
      'Highlands-Baywood Park',
      'Hilmar-Irwin',
      'Holloman Air Force Base',
      'Idyllwild-Pine Cove',
      'Inglewood-Finn Hill',
      'La Crescenta-Montrose',
      'Lackland Air Force Base',
      'Lagunitas-Forest Knolls',
      'Lake Marcel-Stillwater',
      'Lake Morton-Berrydale',
      'Lakeland North',
      'Lakeland South',
      'Larkfield-Wikiup',
      'Laughlin Air Force Base',
      'Leadville North',
      'Lemoore Station',
      'Dixon Lane-Meadow Creek',
      'Dollar Corner',
      'East Hill-Meridian',
      'Sierra Vista Southeast',
      'Skyline-Ganipa',
      'Smith Valley',
      'South Wenatchee',
      'Moses Lake North',
      'Nellis Air Force Base',
      'New Kingman-Butler',
      'North Lakeport',
      'Oatfield',
      'Ohkay Owingeh',
      'Oroville East',
      'Otis Orchards-East Farms',
      'Picnic Point-North Lynnwood',
      'Poplar-Cotton Center',
      'Port Angeles East',
      'Port Hadlock-Irondale',
      'Roseburg North',
      'Security-Widefield',
      'Tsaile',
      'Union Hill-Novelty Hill',
      'Valencia West',
      'Vandenberg Air Force Base',
      'View Park-Windsor Hills',
      'Walla Walla East',
      'West Clarkston-Highland',
      'West Haven-Sylvan',
      'West Modesto',
      'West Pleasant View',
      'West Side Highway',
      'West Whittier-Los Nietos',
      'Westhaven-Moonstone',
      'Yosemite Valley',
      'Summerlin South',
      'Sunnyside-Tahoe City',
      'Taft Mosswood',
      'Tamalpais-Homestead Valley',
      'Tanglewilde-Thompson Place',
      'Haiku-Pauwela',
      'Hickam Field',
      'Honaunau-Napoopoo',
      'Kahaluu-Keauhou',
      'Marine Corps Base Hawaii - MCBH',
      'Makakilo',
      'Napili-Honokowai',
      'Schofield Barracks',
      'Royal Kunia',
      'Waihee-Waiehu',
      'Wailua Homesteads',
      'Deltana',
      'Eielson Air Force Base',
      'Farm Loop',
      'Fishhook',
      'Knik-Fairview',
      'Kodiak Station',
      'Lakes',
      'Salcha',
      'Sutton-Alpine',
      'Susitna North',
      'National Harbor',
      'Tri-Cities',
      'San Tan Valley',
      'Carlisle-Rockledge',
      'Emerald Mountain',
      'Highland Lakes',
      'Farmers Loop',
      'Avenue B and C',
      'Centennial Park',
      'Donovan Estates',
      'Golden Shores',
      'North Fork',
      'Scenic',
      'Six Shooter Canyon',
      'Acalanes Ridge',
      'Fruitridge Pocket',
      'Monument Hills',
      'Old Fig Garden',
      'Red Corral',
      'Sun Village',
      'Upper Bear Creek',
      'Connerton',
      'Flagler Estates',
      'Heritage Pines',
      'Horizon West',
      'Island Walk',
      'Key Vista',
      'Meadow Oaks',
      'Oakleaf Plantation',
      'Pasadena Hills',
      'Quail Ridge',
      'Verona Walk',
      'Viera East',
      'Viera West',
      'East Honolulu',
      'Ocean Pointe',
      'The Galena Territory',
      'Smithville-Sanders',
      'McConnell AFB',
      'Marlboro Village',
      'Queenland',
      'LaBarque Creek',
      'Old Jamestown',
      'East Valley',
      'Topaz Ranch Estates',
      'East Franklin',
      'Franklin Center',
      'Upper Pohatcong',
      'Vernon Center',
      'Keeler Farm',
      'West Hammond',
      'Fort Covington Hamlet',
      'Merritt Park',
      'Northchase',
      'Eagleview',
      'Mowbray Mountain',
      'Tellico Village',
      'Las Palmas II',
      'Paloma Creek',
      'Paloma Creek South',
      'Providence Village',
      'Travis Ranch',
      'Silver Summit',
      "Boswell's Corner",
      'Kings Park West',
      'Loudoun Valley Estates',
      'Southern Gateway',
      'Bothell East',
      'Bothell West',
      'Bunk Foss',
      'Eastmont',
      'Klahanie',
      'Larch Way',
      'Mill Creek East',
      'Monroe North',
      'North Fort Lewis',
      'Prairie Heights',
      'Summit View',
      'Wilderness Rim',
      'Matheny',
      'Patterson Tract',
      'Teviston',
      'Hidden Spring',
      'Circle D-KC Estates',
      'Rio Vista',
      'Shorewood Forest',
      'Cordry Sweetwater Lakes',
      'Dove Valley',
      'Doe Valley',
      'Enchanted Hills',
      'Oak Cliff Place',
      'Detroit-Shoreway',
      'Clark-Fulton',
      'Beech Mountain Lakes',
      'The Hideout',
      'Wallenpaupack Lake Estates',
      'Penn Estates',
      'Sierra View',
      'The Acreage',
      'Eastvale',
      'Beaver Dam Lake',
      'Contra Costa Centre',
      'Belmont Estates',
      'Siesta Acres',
      'West Greenwich',
      'Warren Township',
      'Castle Pines North',
      'Fairfield Heights',
      'Chicago Loop',
      'Greater Grand Crossing',
      'Auburn Gresham',
      'Financial District',
      'Sunset Park',
      'South Kingstown',
      'Noe Valley',
      'Visitacion Valley',
      'Notre Dame',
      'Western Lake',
      'Star Valley Ranch',
      'Shaw Heights',
      'Northdale',
      'Lake Stickney',
      'Phoenix Lake',
      'Picnic Point',
      'Roma',
      'Tanglewilde',
      'Tiki Island',
      'Devens',
      'Lake Holiday',
      'Prestbury',
      'Lake Camelot',
      'Heritage Lake',
      'Watchtower',
      'Cherry Creek',
      'Holly Hills',
      'Lake Panorama',
      'Lake Lakengren',
      'Quail Creek',
      'Plum Creek',
      'Big Bass Lake',
      'Toftrees',
      'Lake Latonka',
      'Buckhorn',
      'Silver Lakes',
      'Spring Valley Lake',
      'Hide-A-Way Lake',
      'Tunica Resorts',
      'Colony Park',
      'Greenfields',
      'Kula',
      'Ko Olina',
      'Firing Range',
      'West Warrenton',
      'Hill Air Force Base',
      'Elmendorf Air Force Base',
      'Hot Springs National Park',
      'Dixiana',
      'Shoal Creek',
      'Little Rock Air Force Base',
      'Cranberry Township',
      'St Marys',
      'Pantops',
      'Pryor Creek',
      'Echo Park',
      'Sugarcreek Police Dept',
      'Scotts Mill',
      'Maine',
      'Washington Street Courthouse Annex',
      'Allenstown',
      'Bay Harbor',
      'St. Johns',
      'Stonecrest',
      'Cortlandt Manor',
      'Valley Glen',
      'New Century, KS',
      'Encanto',
      'James Island',
      'Communications Hill',
      'Reynoldstown',
    ],
  },
  {
    country: 'Uruguay',
    cities: [
      'Young',
      'Villa Sara',
      'Villa del Carmen',
      'Vichadero',
      'Vergara',
      'Velázquez',
      '25 de Mayo',
      '25 de Agosto',
      'Colonia Valdense',
      'Tupambaé',
      'Trinidad',
      'Treinta y Tres',
      'Tranqueras',
      'Tomás Gomensoro',
      'Toledo',
      'Tarariras',
      'Tambores',
      'Tala',
      'Tacuarembó',
      'Villa Soriano',
      'Solís de Mataojo',
      'Soca',
      'Pueblo Sequeira',
      'Sauce',
      'Sarandí Grande',
      'Sarandí del Yi',
      'Santiago Vázquez',
      'Santa Teresa',
      'Santa Rosa',
      'Santa Lucía',
      'Santa Clara de Olimar',
      'Santa Catalina',
      'Santa Bernardina',
      'San Ramón',
      'San José de Mayo',
      'San Javier',
      'San Jacinto',
      'San Félix',
      'San Carlos',
      'San Bautista',
      'San Antonio',
      'Salto',
      'Rosario',
      'Rodríguez',
      'Rocha',
      'Rivera',
      'Río Branco',
      'Rafael Perazza',
      'Quebracho',
      'Punta del Este',
      'Pueblo Centenario',
      'Progreso',
      'Porvenir',
      'Estación Porvenir',
      'Piriápolis',
      'Piedras Coloradas',
      'Paysandú',
      'Paso de los Toros',
      'Paso de Carrasco',
      'Pando',
      'Pan de Azúcar',
      'Palmitas',
      'Ombúes de Lavalle',
      'Nuevo Berlín',
      'Nueva Palmira',
      'Nueva Helvecia',
      'Montevideo',
      'Montes',
      'Minas de Corrales',
      'Minas',
      'Migues',
      'Mercedes',
      'Melo',
      'Mariscala',
      'Maldonado',
      'Los Cerrillos',
      'Libertad',
      'Las Toscas',
      'Las Piedras',
      'Lascano',
      'La Paz',
      'La Paloma',
      'La Floresta',
      'Juan L. Lacaze',
      'José Pedro Varela',
      'José Enrique Rodó',
      'José Batlle y Ordóñez',
      'Joaquín Suárez',
      'Juanicó',
      'Isidoro Noblía',
      'Guichón',
      'General Enrique Martínez',
      'Fray Bentos',
      'Florida',
      'Florencio Sánchez',
      'Empalme Olmos',
      'Ecilda Paullier',
      'Durazno',
      'Dolores',
      'Delta del Tigre',
      'Curtina',
      'Villa Constitución',
      'Colonia del Sacramento',
      'Chui',
      'Alejandro Gallinal',
      'Cebollatí',
      'Casupá',
      'Castillos',
      'Carmelo',
      'Cardona',
      'Cardal',
      'Canelones',
      'Blanquillo',
      'Bella Unión',
      'Belén',
      'Baltasar Brum',
      'Atlántida',
      'Artigas',
      'Aiguá',
      'Aguas Corrientes',
      'Aceguá',
      'Puntas de Valdéz',
      'Colonia Nicolich',
      'Barra de Carrasco',
      'Pajas Blancas',
      'Costa Azul',
      'Barros Blancos',
    ],
  },
  {
    country: 'Uzbekistan',
    cities: [
      'Nukus',
      'Mŭynoq',
      'Qanliko‘l',
      'Qŭnghirot',
      'Xo‘jayli Shahri',
      'Kegeyli Shahar',
      'Qozonketkan',
      'Oltinko‘l',
      'Oqmang‘it',
      'Qo‘ng‘irot Shahri',
      'Chimboy Shahri',
      'Mo‘ynoq Shahri',
      'Shumanay Shahri',
      'Jondor Shaharchasi',
      'Zomin Shaharchasi',
      'Nishon Tumani',
      'Usmat Shaharchasi',
      'Urgut Shahri',
      'Mug‘lon Shahar',
      'Tirmiz',
      'Toyloq Qishlog’i',
      'Nurobod Shahri',
      'Sho‘rchi',
      'Shahrisabz',
      'Shohimardon',
      'Samarkand',
      'Romitan Shahri',
      'Qarshi',
      'Payariq Shahri',
      'Muborak',
      'Yangi Mirishkor',
      'Loyish Shaharchasi',
      'Kitob',
      'Kattaqo’rg’on Shahri',
      'Koson',
      'Qorovulbozor',
      'Karakul’',
      'Qorako‘l Shahri',
      'Kogon Shahri',
      'Ishtixon Shahri',
      'G‘uzor',
      'Galaosiyo Shahri',
      'Juma Shahri',
      'Jomboy Shahri',
      'Denov',
      'Dahbed',
      'Chiroqchi',
      'Chelak',
      'Charxin',
      'Bulung’ur Shahri',
      'Bukhara',
      'Beshkent',
      'Boysun',
      'Olot Shahri',
      'Oqtosh Shahri',
      'Oqtosh',
      'Qorovulbozor Shahri',
      'Qorashina',
      'Shahrisabz Shahri',
      'Koson Shahri',
      'Kitob Shahri',
      'Muborak Shahri',
      'Beshkent Shahri',
      'G‘uzor Shahri',
      'Qamashi Shahri',
      'Chiroqchi Shahri',
      'Yakkabog‘ Shahri',
      'Yangi-Nishon Shahri',
      'Ravon',
      'Ziyodin Shaharchasi',
      'Zarbdor Shaharchasi',
      'Zafarobod Shaharchasi',
      'Zafar',
      'G’oliblar Qishlog’i',
      'Yozyovon',
      'Yaypan',
      'Yangiyŭl',
      'Yangiyer',
      'Yangirabot',
      'Yangiqo‘rg‘on',
      'Yangiqishloq Shaharchasi',
      'Yangibozor',
      'Yangibozor Qishlog’i',
      'Yangiariq',
      'Yangiobod',
      'Vodil',
      'Vobkent Shahri',
      'Uychi',
      'Ŭrtaowul',
      'Urganch',
      'Dashtobod',
      'Uchtepa Qishlog’i',
      'Uchqŭrghon Shahri',
      'Uchko‘prik',
      'Tŭytepa',
      'To‘rqao‘rg‘on',
      'Toshloq',
      'Tashkent',
      'Toshbuloq',
      'Tomdibuloq',
      'Taxtako‘pir',
      'Sirdaryo',
      'Shovot',
      'Shahrixon',
      'Shofirkon Shahri',
      'Salor',
      'Rishton',
      'Qo’shko’pir',
      'Qo‘qon',
      'Piskent',
      'Poytug‘',
      'Payshamba Shahri',
      'Parkent',
      'Pop',
      'Paxtakor Shahri',
      'Paxtaobod',
      'Olmaliq',
      'Ohangaron',
      'Nurota',
      'Novyy Turtkul’',
      'Navoiy',
      'Navbahor',
      'Namangan',
      'Marhamat',
      'Marg‘ilon',
      'Manghit',
      'Asaka',
      'Qiziltepa',
      'Kuyganyor',
      'Quvasoy',
      'Qo‘rg‘ontepa',
      'Yangi Marg‘ilon',
      'Kirguli',
      'Qibray',
      'Xonqa',
      'Xo‘jaobod',
      'Xiva',
      'Hamza',
      'Haqqulobod',
      'Kosonsoy',
      'Qorao‘zak',
      'Konimex',
      'Jizzax',
      'Iskandar',
      'Hazorasp',
      'Gurlan',
      'Guliston',
      'G’ijduvon Shahri',
      'Gazli',
      'G‘azalkent',
      'G’allaorol Shahri',
      'Gagarin Shahri',
      'Fergana',
      'Amir Timur',
      'Do’stlik Shahri',
      'Dardoq',
      'Dang‘ara',
      'Chust',
      'Chirchiq',
      'Chinoz',
      'Kyzyldzhar',
      'Chortoq',
      'Buloqboshi',
      'Bo‘ka',
      'Beshrabot',
      'Beshariq',
      'Beruniy',
      'Bektemir',
      'Bekobod',
      'Baliqchi',
      'Bag‘dod',
      'Bog’ot',
      'Angren',
      'Andijon',
      'Oltiariq',
      'Oxunboboyev',
      'Oqoltin',
      'Oyim',
      'Quva',
      'Beruniy Shahri',
      'To‘rtko‘l Shahri',
      'Bo‘ston Shahri',
      'Mang‘it Shahri',
      'Qorovul',
      'Uchquduq Shahri',
      'Karmana Shahri',
      'Qiziltepa Shahri',
      'Nurota Shahri',
      'Kosonsoy Shahri',
      'Chortoq Shahri',
      'Pop Shahri',
      'Tŭragŭrghon Shahri',
      'Langar',
      'Sultonobod',
      'Eskiarab',
      'Tinchlik',
      'Qo’shrabod',
    ],
  },
  { country: 'Vatican City', cities: ['Vatican City'] },
  {
    country: 'Saint Vincent and the Grenadines',
    cities: [
      'Port Elizabeth',
      'Layou',
      'Kingstown',
      'Georgetown',
      'Chateaubelair',
      'Byera Village',
      'Biabou',
      'Barrouallie',
      'Kingstown Park',
    ],
  },
  {
    country: 'Venezuela',
    cities: [
      'La Asunción',
      'Caricuao',
      'Tocuyito',
      'El Chaparro',
      'Anaco',
      'Santa Cruz de Guacas',
      'El Real',
      'Puerto Vivas',
      'La Mula',
      'Los Guasimitos',
      'Alto Barinas',
      'Zea',
      'Zaraza',
      'Yumare',
      'Yaritagua',
      'Yaracal',
      'Yaguaraparo',
      'Villa de Cura',
      'Villa Bruzual',
      'Las Casitas del Vegon de Nutrias',
      'Valle de La Pascua',
      'Valle de Guanape',
      'Valera',
      'Valencia',
      'Urumaco',
      'Ureña',
      'Uracoa',
      'Urachiche',
      'Upata',
      'Umuquena',
      'Turmero',
      'Tunapuy',
      'Tumeremo',
      'Tucupita',
      'Tucupido',
      'Tucaní',
      'Tucacas',
      'Trujillo',
      'Tovar',
      'Torunos',
      'Torondoy',
      'Tocuyo de La Costa',
      'Tocópero',
      'Tinaquillo',
      'Tinaco',
      'Timotes',
      'Tía Juana',
      'Temblador',
      'Táriba',
      'Tabay',
      'Soledad',
      'Socopó',
      'Siquisique',
      'Sinamaica',
      'Seboruco',
      'Sarare',
      'Santo Domingo',
      'San Timoteo',
      'Santa Teresa del Tuy',
      'Santa Rosa',
      'Santa Rita',
      'Santa María de Ipire',
      'Santa María de Caparo',
      'Santa Lucía',
      'Santa Isabel',
      'Santa Inés',
      'Santa Elena de Uairén',
      'Santa Elena de Arenales',
      'Santa Cruz de Mora',
      'Santa Cruz de Mara',
      'Santa Cruz de Bucaral',
      'Santa Cruz',
      'Santa Catalina',
      'Santa Bárbara',
      'Santa Apolonia',
      'Santa Ana',
      'San Simón',
      'San Silvestre',
      'San Rafael de Onoto',
      'Carvajal',
      'San Rafael de Canaguá',
      'San Rafael',
      'San Pedro de Coche',
      'San Pablo',
      'San Mateo',
      'San Luis',
      'San Lorenzo',
      'San Juan de Manapiare',
      'San Juan de los Morros',
      'San Juan de los Cayos',
      'Colón',
      'San Juan Bautista',
      'San José de Barlovento',
      'San José de Guanipa',
      'San José de Aerocuar',
      'San Joaquín',
      'San Francisco de Yare',
      'San Francisco',
      'San Fernando de Atabapo',
      'San Felipe',
      'San Diego',
      'San Cristóbal',
      'San Casimiro',
      'San Carlos de Río Negro',
      'San Carlos del Zulia',
      'San Carlos',
      'Sanare',
      'San Antonio del Táchira',
      'San Antonio de Los Altos',
      'San Antonio del Golfo',
      'San Antonio',
      'Sabaneta',
      'Sabana Grande',
      'Sabana de Parra',
      'Sabana de Mendoza',
      'Rubio',
      'La Villa del Rosario',
      'Río Chico',
      'Río Caribe',
      'Quiriquire',
      'Quíbor',
      'Queniquea',
      'Quebrada Seca',
      'Punto Fijo',
      'Punta Gorda',
      'Punta de Piedras',
      'Punta de Piedra',
      'Punta de Mata',
      'Punta Cardón',
      'Puerto Píritu',
      'Puerto La Cruz',
      'Puerto de Nutrias',
      'Puerto Cumarebo',
      'Puerto Cabello',
      'Puerto Ayacucho',
      'Pueblo Nuevo',
      'Pueblo Nuevo El Chivo',
      'Pueblo Llano',
      'Pregonero',
      'Porlamar',
      'Píritu',
      'Petare',
      'Pedregal',
      'Pedraza La Vieja',
      'Pedernales',
      'Pariaguán',
      'Paraíso de Chabasquén',
      'La Plaza Paraguachi',
      'Papelón',
      'Pampatar',
      'Pampanito',
      'Pampán',
      'Palo Negro',
      'Palmira',
      'Palmasola',
      'Ospino',
      'Ortiz',
      'Onoto',
      'Ocumare del Tuy',
      'Ocumare de la Costa',
      'Obispos',
      'Nueva Bolivia',
      'Nirgua',
      'Naiguatá',
      'Naguanagua',
      'Mucuchíes',
      'Motatán',
      'Morón',
      'Monte Carmelo',
      'Montalbán',
      'Mirimire',
      'Miranda',
      'Michelena',
      'Mérida',
      'Mene Grande',
      'Mene de Mauroa',
      'Masparrito',
      'Maroa',
      'Maripa',
      'Marigüitar',
      'Mariara',
      'Maracay',
      'Maracaibo',
      'Maporal',
      'Mapire',
      'Mamporal',
      'Maiquetía',
      'Macuto',
      'Machiques',
      'Los Teques',
      'Los Rastrojos',
      'Los Guayos',
      'Los Dos Caminos',
      'Lobatera',
      'Libertad',
      'Canaguá',
      'Capacho Viejo',
      'La Yuca',
      'La Victoria',
      'La Vela de Coro',
      'Veguitas',
      'La Unión',
      'Las Vegas',
      'Las Tejerías',
      'Las Mercedes',
      'La Sabana',
      'La Quebrada',
      'La Plata',
      'La Paragua',
      'La Luz',
      'Lagunillas',
      'La Guaira',
      'La Esmeralda',
      'La Cruz de Taratara',
      'La Concepción',
      'Concepción',
      'Chameta',
      'Caramuca',
      'La Azulita',
      'Kanavayén',
      'Judibana',
      'Juan Griego',
      'Jacura',
      'Irapa',
      'Higuerote',
      'Güiria',
      'Güigüe',
      'Guayabal',
      'Guatire',
      'Guasipati',
      'Guarenas',
      'Guaraque',
      'Guanta',
      'Guanarito',
      'Guanare',
      'Guama',
      'Guadarrama',
      'Guacara',
      'Farriar',
      'Escuque',
      'Encontrados',
      'El Vigía',
      'El Valle del Espíritu Santo',
      'El Toro',
      'El Tocuyo',
      'El Tigre',
      'El Sombrero',
      'El Socorro',
      'Los Roques',
      'El Regalo',
      'San Rafael del Piñal',
      'El Pilar',
      'El Paradero',
      'El Pao',
      'El Palmer',
      'El Palmar',
      'Elorza',
      'Lecherías',
      'El Limón',
      'El Hatillo',
      'El Dorado',
      'El Dividive',
      'El Corozo',
      'El Consejo',
      'El Cobre',
      'El Cantón',
      'El Callao',
      'El Baúl',
      'Ejido',
      'Duaca',
      'Dolores',
      'Dabajuro',
      'Curiapo',
      'Curbatí',
      'Cúpira',
      'Cumanacoa',
      'Cumaná',
      'Cúa',
      'Coro',
      'Coloncito',
      'Cojedes',
      'Cocorote',
      'Clarines',
      'Ciudad Piar',
      'Ciudad Ojeda',
      'Ciudad Guayana',
      'Ciudad De Nutrias',
      'Ciudad Bolivia',
      'Ciudad Bolívar',
      'Churuguara',
      'Chivacoa',
      'Chiquinquirá',
      'Chichiriviche',
      'Chejendé',
      'Charallave',
      'Chaguaramas',
      'Chacao',
      'Tacarigua',
      'Caucagüito',
      'Caucagua',
      'Catia La Mar',
      'Casigua El Cubo',
      'Casanay',
      'Carúpano',
      'Carrizal',
      'Carora',
      'Caripito',
      'Caripe',
      'Cariaco',
      'Carache',
      'Caracas',
      'Caraballeda',
      'Capitanejo',
      'Canape Capatárida',
      'Cantaura',
      'Campo Elías',
      'Camatagua',
      'Camaguán',
      'Calderas',
      'Calabozo',
      'Caja Seca',
      'Caicara',
      'Caicara del Orinoco',
      'Cagua',
      'Cabure',
      'Cabudare',
      'Cabimas',
      'Bum Bum',
      'Bruzual',
      'Boraure',
      'Boconoito',
      'Boconó',
      'Boca de Uchire',
      'Boca de Río',
      'Boca de Aroa',
      'Bobures',
      'Biscucuy',
      'Biruaca',
      'Betijoque',
      'Bejuma',
      'Baruta',
      'Barrancas',
      'Barquisimeto',
      'Barinitas',
      'Barinas',
      'Barcelona',
      'Barbacoas',
      'Bailadores',
      'Bachaquero',
      'Aroa',
      'Arismendi',
      'Aricagua',
      'Arenas',
      'Araya',
      'Araure',
      'Arauquita',
      'Aragua',
      'Aragua de Barcelona',
      'Altamira',
      'Altagracia de Orituco',
      'Los Puertos de Altagracia',
      'Aguasay',
      'Achaguas',
      'Acarigua',
      'Abejales',
      'Las Mesas',
      'San José de Bolívar',
      'Isla Ratón',
      'San Josecito',
      'Cordero',
      'Sierra Imataca',
      'San José de Guaribe',
      'Maturín',
      'Delicias',
      'La Fría',
      'La Grita',
      'Capacho Nuevo',
      'La Tendida',
      'Agua Blanca',
      'El Playón',
      'Arapuey',
      'Macapo',
      'El Cafetal',
      'La Dolorita',
      'Guasdualito',
      'San Fernando de Apure',
      'San Juan de Payara',
      'San Sebastián',
      'Santa Cruz de los Taques',
      'Independencia',
      'El Junko',
    ],
  },
  { country: 'British Virgin Islands', cities: ['Road Town'] },
  {
    country: 'U.S. Virgin Islands',
    cities: ['Charlotte Amalie', 'Cruz Bay', 'Saint Croix'],
  },
  {
    country: 'Vietnam',
    cities: [
      'Yên Vinh',
      'Yên Thành',
      'Yên Ninh',
      'Thị Trấn Yên Minh',
      'Yến Lạc',
      'Yên Lạc',
      'Yên Châu',
      'Yên Bái',
      'Xuân Mai',
      'Cái Đôi Vàm',
      'Trảng Bom',
      'Gia Ray',
      'Vũng Tàu',
      'Vũng Liêm',
      'Vụ Bản',
      'Võ Xu',
      'Vị Thanh',
      'Vĩnh Yên',
      'Thị Trấn Vĩnh Tuy',
      'Vĩnh Thuận',
      'Vĩnh Thạnh',
      'Vĩnh Long',
      'Vĩnh Lộc',
      'Vĩnh Châu',
      'Vĩnh Bình',
      'Vinh',
      'Việt Trì',
      'Văn Quan',
      'Vạn Giã',
      'Vân Đình',
      'Văn Điển',
      'Vân Canh',
      'Thành Phố Uông Bí',
      'Tuy Phước',
      'Tuy Hòa',
      'Tuyên Quang',
      'Hòa Bình',
      'Tư Nghĩa',
      'Thị Trấn Trùng Khánh',
      'Tri Tôn',
      'Trà Vinh',
      'Trà Ôn',
      'Trảng Bàng',
      'Thị Trấn Trạm Tấu',
      'Trà My',
      'Tràm Chim',
      'Trà Cú',
      'Trà Bồng',
      'Tĩnh Gia',
      'Tiểu Cần',
      'Tiên Phước',
      'Thủ Thừa',
      'Thường Xuân',
      'Thủ Đức',
      'Thủ Dầu Một',
      'Thọ Xuân',
      'Thốt Nốt',
      'Thới Lai',
      'Thới Bình',
      'Ái Tử',
      'Thị Trấn Thất Khê',
      'Than Uyên',
      'Phú Lộc',
      'Thanh Sơn',
      'Thạnh Phú',
      'Ho Chi Minh City',
      'Thanh Nê',
      'Thanh Hóa',
      'Thanh Chương',
      'Thanh Bình',
      'Thái Nguyên',
      'Thái Bình',
      'Kim Tân',
      'Thạch Hà',
      'Tây Ninh',
      'Tây Hồ',
      'Tây Đằng',
      'Đại Tân',
      'Đầm Dơi',
      'Tân Kỳ',
      'Tánh Linh',
      'Tân Hiệp',
      'Tân Châu',
      'Tân An',
      'Tam Kỳ',
      'Tam Đảo',
      'Tam Bình',
      'Thị Trấn Tà Lùng',
      'Sơn Tịnh',
      'Sơn Tây',
      'Sơn Hà',
      'Sơn Dương',
      'Sơn La',
      'Sông Mã',
      'Sông Cầu',
      'Sóc Trăng',
      'Thị Trấn Sìn Hồ',
      'Si Ma Cai',
      'Sa Pa',
      'Sa Dec',
      'Rạch Giá',
      'Mường Chiên',
      'Quỳnh Côi',
      'Qui Nhon',
      'Quỳ Hợp',
      'Quy Đạt',
      'Quỳ Châu',
      'Quế Sơn',
      'Kim Sơn',
      'Quan Hóa',
      'Quảng Yên',
      'Thị Trấn Quảng Uyên',
      'Ba Đồn',
      'Quán Hàu',
      'Quảng Ngãi',
      'Plei Kần',
      'Pleiku',
      'Phù Yên',
      'Phú Vang',
      'Phú Túc',
      'Phủ Thông',
      'Phú Thọ',
      'Dương Đông',
      'Phú Phong',
      'Đại Đồng',
      'Phước An',
      'Phù Ninh',
      'Phụng Hiệp',
      'Phù Mỹ',
      'Phủ Lý',
      'Phú Khương',
      'Phúc Yên',
      'Phù Cát',
      'Đại Cường',
      'Phong Thổ',
      'Phong Điền',
      'Thị Trấn Phố Lu',
      'Phố Châu',
      'Bến Sung',
      'Phát Diệm',
      'Phan Thiết',
      'Phan Rang-Tháp Chàm',
      'Ô Môn',
      'Thị Trấn Nước Hai',
      'Núi Đèo',
      'Đại Quang',
      'Ninh Hòa',
      'Ninh Giang',
      'Ninh Bình',
      'Như Quỳnh',
      'Nho Quan',
      'Nhơn Trạch',
      'Nha Trang',
      'Nhà Bè',
      'Nhà Bàng',
      'Thị Trấn Nguyên Bình',
      'Ngô Đồng',
      'Ngọc Lặc',
      'Tiền Hải',
      'Nghi Xuân',
      'Quán Hành',
      'Nghĩa Lộ',
      'Nghĩa Hành',
      'Nga Sơn',
      'Vân Tùng',
      'Thị Trấn Ngải Giao',
      'Thị Trấn Na Sầm',
      'Nam Định',
      'Nam Đàn',
      'Năm Căn',
      'Mỹ Xuyên',
      'Mỹ Tho',
      'Mỹ Thọ',
      'Mỹ Lộc',
      'Thạnh Mỹ',
      'Mường Nhé',
      'Mường Lay',
      'Thị Trấn Mường Khương',
      'Mường Lát',
      'Mù Cang Chải',
      'Móng Cái',
      'Mộ Đức',
      'Mộc Châu',
      'Mỏ Cày',
      'Minh Lương',
      'Minh Long',
      'Thị Trấn Mèo Vạc',
      'Ma Đa Gui',
      'Long Xuyên',
      'Long Thành',
      'Long Phú',
      'Long Mỹ',
      'Lộc Ninh',
      'Lộc Bình',
      'Liễu Đề',
      'Liên Hương',
      'Kiến Giang',
      'Lấp Vò',
      'Lao Chải',
      'Lào Cai',
      'Lạng Sơn',
      'Thị Trấn Phước Bửu',
      'Lũng Hồ',
      'Lang Chánh',
      'Thị Trấn Lai Vung',
      'Lái Thiêu',
      'Lai Cách',
      'La Gi',
      'Lắk',
      'Lạc Dương',
      'Mường Xén',
      'Kỳ Anh',
      'Kon Tum',
      'Kinh Môn',
      'Kim Bài',
      'Kiên Lương',
      'Khe Sanh',
      'Thị Trấn Khánh Yên',
      'Kẻ Sặt',
      'Kế Sách',
      'Hữu Lũng',
      'Hương Trà',
      'Hương Khê',
      'Hương Canh',
      'Hưng Yên',
      'Huế',
      'Hồ Xá',
      'Bình Long',
      'Hạ Long',
      'Hòn Đất',
      'Hoi An',
      'Hóc Môn',
      'Hòa Vang',
      'Hòa Mạc',
      'Hoài Ân',
      'Hiệp Đức',
      'Hà Tĩnh',
      'Hà Tiên',
      'Hanoi',
      'Thị Trấn Thanh Nhật',
      'Hà Lam',
      'Haiphong',
      'Hải Lăng',
      'Hải Dương',
      'Hạ Hòa',
      'Hà Giang',
      'Hà Đông',
      'Gò Quao',
      'Gò Công',
      'Giồng Riềng',
      'Gio Linh',
      'Gia Nghĩa',
      'Duy Xuyên',
      'Đưc Trọng',
      'Đức Thọ',
      'Đức Phong',
      'Đức Phổ',
      'Đắk Mil',
      'Đồng Xuân',
      'Đồng Xoài',
      'Thị Trấn Đồng Văn',
      'Đồng Mỏ',
      'Thị Trấn Đông Khê',
      'Đồng Hới',
      'Ðông Hà',
      'Thị Trấn Đồng Đăng',
      'Anh Son',
      'Đoan Hùng',
      'Đình Lập',
      'Di Linh',
      'Diên Khánh',
      'Diễn Châu',
      'Dien Bien Phu',
      'Điện Bàn',
      'Diêm Điền',
      'Dĩ An',
      'Dầu Tiếng',
      'Thị Trấn Đất Đỏ',
      'Da Nang',
      'Ðà Lạt',
      'Đắk Song',
      'Đại Lộc',
      'Đắk Tô',
      'Củng Sơn',
      'Củ Chi',
      'Cờ Đỏ',
      'Côn Sơn',
      'Con Cuông',
      'Cổ Lóa',
      'Chúc Sơn',
      'Chũ',
      'Chơn Thành',
      'Chợ Mới',
      'Chợ Lách',
      'Giồng Trôm',
      'Chợ Gạo',
      'Chợ Chu',
      'Chí Thạnh',
      'Chi Nê',
      'Chí Linh',
      'Huyện Chiêm Hóa',
      'Châu Đốc',
      'Cầu Kè',
      'Cầu Giát',
      'Cát Bà',
      'Cao Thượng',
      'Cao Lãnh',
      'Cao Bằng',
      'Cần Thơ',
      'Cần Giờ',
      'Cần Đước',
      'Cần Giuộc',
      'Cẩm Xuyên',
      'Cẩm Thủy',
      'Cam Ranh',
      'Cẩm Phả Mines',
      'Cẩm Phả',
      'Cam Lộ',
      'Cà Mau',
      'Cái Tàu Hạ',
      'Cái Răng',
      'Cái Nước',
      'Cái Nhum',
      'Buôn Trấp',
      'Buôn Ma Thuột',
      'Buôn Hồ',
      'Hoàn Lão',
      'Bồng Sơn',
      'Bù Đốp',
      'Bình Thủy',
      'Bình Sơn',
      'Bình Gia',
      'Bình Đại',
      'Tân Túc',
      'Bỉm Sơn',
      'Biên Hòa',
      'Bến Tre',
      'Bến Cầu',
      'Ba Tri',
      'Ba Tơ',
      'Thị Trấn Phố Ràng',
      'Bảo Lộc',
      'Thị Trấn Bảo Lạc',
      'Bẩn Yên Nhân',
      'Ít Ong',
      'Thị Trấn Thuận Châu',
      'Bắc Yên',
      'Bắc Sơn',
      'Bắc Ninh',
      'Bạc Liêu',
      'Bắc Hà',
      'Bắc Giang',
      'Bắc Kạn',
      'Tân Trụ',
      'Ấp Tân Ngãi',
      'An Hòa',
      'An Nhơn',
      'An Lão',
      'An Dương',
      'An Châu',
      'An Biên',
      'A Lưới',
      'Quận Bảy',
      'Tam Điệp',
      'Châu Thành',
      'Uyen Hung',
      'Yên Lập',
      'Thanh Ba',
      'Sông Thao',
      'Hưng Hóa',
      'Phong Châu',
      'Thị Trấn Tân Yên',
      'Tân Việt',
      'Pác Miầu',
      'Lập Thạch',
      'Núi Sập',
      'Tầm Vu',
      'Gia Bình',
      'Thứa',
      'Chờ',
      'Phố Mới',
      'Hồ',
      'Lim',
      'Cung Kiệm',
      'Thị Trấn Gành Hào',
      'Thị Trấn Ngan Dừa',
      'Thị Trấn Phước Long',
      'Thị Trấn Giá Rai',
      'Thị Trấn Hòa Bình',
      'Cái Dầu',
      'Cầu Gồ',
      'Đồi Ngô',
      'Neo',
      'Bích Động',
      'Thắng',
      'Bộc Bố',
      'Bằng Lũng',
      'Thị Trấn Long Điền',
      'Mường Chà',
      'Thị Trấn Tủa Chùa',
      'Điện Biên Đông',
      'Thị Trấn Điện Biên',
      'Tam Đường',
      'Lai Châu',
      'Thị Trấn Mường Tè',
      'Thị Trấn Tam Sơn',
      'Thị Trấn Vị Xuyên',
      'Thị Trấn Việt Lâm',
      'Thị Trấn Việt Quang',
      'Yên Phú',
      'Thị Trấn Vinh Quang',
      'Yên Bình',
      'Thị Trấn Thông Nông',
      'Thị Trấn Xuân Hoà',
      'Thị Trấn Hùng Quốc',
      'Thị Trấn Cao Lộc',
      'Thị Trấn Vĩnh Lộc',
      'Sơn Thịnh',
      'Cổ Phúc',
      'Thị Trấn Yên Thế',
      'Thị Trấn Na Hang',
      'Bình Minh',
      'An Phú',
      'Phú Mỹ',
      'Thị Trấn Phú Mỹ',
      'Bát Xát',
      'Hát Lót',
      'Quảng Xương',
      'Nông Cống',
      'Quan Sơn',
      'Yên Cát',
      'Bút Sơn',
      'Cành Nàng',
      'Hậu Lộc',
      'Triệu Sơn',
      'Quán Lào',
      'Hà Trung',
      'Rừng Thông',
      'Vạn Hà',
      'Đà Bắc',
      'Mường Khến',
      'Hàng Trạm',
      'Bo',
      'Lương Sơn',
      'Kỳ Sơn',
      'Cao Phong',
      'Me',
      'Thiên Tồn',
      'Yên Thịnh',
      'Xuân Trường',
      'Yên Định',
      'Lâm',
      'Gôi',
      'Nam Giang',
      'Cổ Lễ',
      'Quế',
      'Vĩnh Trụ',
      'Bình Mỹ',
      'Thanh Lưu',
      'Hưng Hà',
      'Vũ Thư',
      'Đông Hưng',
      'Thành Phố Bà Rịa',
      'Thị Trấn Tuần Giáo',
      'Mường Ảng',
      'Tiên Lãng',
      'Núi Đối',
      'Quảng Hà',
      'Trới',
      'Cô Tô',
      'Thanh Miện',
      'Nam Sách',
      'Gia Lộc',
      'Tứ Kỳ',
      'Thanh Hà',
      'Phú Thái',
      'Đu',
      'Hương Sơn',
      'Chùa Hang',
      'Thanh Thủy',
      'Lâm Thao',
      'Mê Linh',
      'Vĩnh Tường',
      'Hợp Hòa',
      'Văn Giang',
      'Yên Mỹ',
      'Khoái Châu',
      'Lương Bằng',
      'Ân Thi',
      'Trần Cao',
      'Vương',
      'Liên Quan',
      'Phúc Thọ',
      'Phùng',
      'Trôi',
      'Quốc Oai',
      'Thường Tín',
      'Phú Xuyên',
      'Đại Nghĩa',
      'Sóc Sơn',
      'Đông Anh',
      'Cầu Diễn',
      'Trâu Quỳ',
      'Cầu Giấy',
      'Thanh Xuân',
      'Đống Đa',
      'Hoàn Kiếm',
      'Hai BàTrưng',
      'Hưng Nguyên',
      'Vũ Quang',
      'Nghèn',
      'Đồng Lê',
      'Krông Klang',
      'Khe Tre',
      'Sịa',
      'Thanh Khê',
      'Cẩm Lệ',
      'Sơn Trà',
      'Liên Chiểu',
      'Ngũ Hành Sơn',
      'Prao',
      'Khâm Đức',
      'Núi Thành',
      'Phú Ninh',
      'Tây Trà',
      'Lý Sơn',
      'Đắk Glei',
      'Sa Thầy',
      'Đắk Hà',
      'Đắk Rve',
      'Măng Đen',
      'Đăk Đoa',
      'K Bang',
      'Kông Chro',
      'Ia Pa',
      'A Yun Pa',
      'Chư Sê',
      'Chư Prông',
      'Chư Ty',
      'Ia Kha',
      'Phú Hòa',
      'Kon Dơng',
      'Hai Riêng',
      'Ea Súp',
      'Ea Drăng',
      'Krông Năng',
      'Quảng Phú',
      'Ea Kar',
      'Krông Kmar',
      'M’Đrăk',
      'Khánh Vĩnh',
      'Tô Hạp',
      'Cam Lâm',
      'Ea T’ling',
      'Đắk Mâm',
      'Đinh Văn',
      'Lộc Thắng',
      'Đồng Nai',
      'Đạ Tẻh',
      'Bác Ái',
      'Tân Sơn',
      'Phước Dân',
      'Khánh Hải',
      'Chợ Lầu',
      'Ma Lâm',
      'Thuận Nam',
      'Phú Quý',
      'Tân Phú',
      'Vĩnh An',
      'Thống Nhất',
      'Long Khánh',
      'Tân Biên',
      'Hòa Thành',
      'Gò Dầu',
      'Dương Minh Châu',
      'Tân Hưng',
      'Vĩnh Hưng',
      'Tân Thạnh',
      'Thạnh Hóa',
      'Đông Thành',
      'Hậu Nghĩa',
      'Mỹ Phước',
      'Tân Hòa',
      'Thứ Mười Một',
      'Sa Rài',
      'Long Hồ',
      'Duyên Hải',
      'Nàng Mau',
      'Ngã Bảy',
      'Ngã Sáu',
      'Một Ngàn',
      'Cù Lao Dung',
      'Huỳnh Hữu Nghĩa',
      'U Minh',
      'Trần Văn Thời',
      'Ngọc Hiển',
      'Quận Hai',
      'Quận Chín',
      'Quận Bốn',
      'Quận Sáu',
      'Quận Năm',
      'Quận Một',
      'Quận Phú Nhuận',
      'Quận Tân Phú',
      'Quận Mười Một',
      'Quận Mười',
      'Quận Ba',
      'Quận Bình Thạnh',
      'Hoàng Sa',
      'Thị Trấn Tân Thành',
      'Khóm 2',
      'Quận Đức Thịnh',
      'Quận Đức Thành',
      'Quận Lấp Vò',
      'Huyện Lâm Hà',
      'Huyện Bảo Lâm',
      'Xã Phúc Thọ',
      'Xã Tân Hà',
      'Xã Tân Văn',
      'Xã Hoài Đức',
      'Xã Tân Thanh',
      "Xã Đạ K'Nàng",
      'Phú Quốc',
      'thị xã Quảng Trị',
      'Quang Minh',
      'Thuận An',
    ],
  },
  {
    country: 'Vanuatu',
    cities: [
      'Sola',
      'Port-Vila',
      'Port-Olry',
      'Norsup',
      'Luganville',
      'Lakatoro',
      'Isangel',
      'Saratamata',
    ],
  },
  { country: 'Wallis and Futuna', cities: ['Leava', 'Mata-Utu', 'Alo'] },
  {
    country: 'Samoa',
    cities: [
      'Vaiusu',
      'Vailima',
      'Vailoa',
      'Solosolo',
      'Siusega',
      'Satupa‘itea',
      'Satapuala',
      'Samamea',
      'Safotulafai',
      'Safotu',
      'Nofoali‘i',
      'Mulifanua',
      'Matavai',
      'Malie',
      'Lufilufi',
      'Lotofagā',
      'Leulumoega',
      'Gataivai',
      'Fasito‘outa',
      'Faleula',
      'Falefa',
      'Apia',
      'Afega',
      'Asau',
    ],
  },
  {
    country: 'Kosovo',
    cities: [
      'Zvečan',
      'Zubin Potok',
      'Vushtrri',
      'Vitina',
      'Ferizaj',
      'Suva Reka',
      'Štrpce',
      'Shtime',
      'Srbica',
      'Irzniq',
      'Ranilug',
      'Prizren',
      'Pristina',
      'Prilep',
      'Podujeva',
      'Peqan',
      'Peć',
      'Partesh',
      'Pagaruša',
      'Orahovac',
      'Obiliq',
      'Novo Brdo',
      'Nishor',
      'Mamuša',
      'Mališevo',
      'Llazicë',
      'Lumbardhi',
      'Lëbushë',
      'Lipljan',
      'Leshan',
      'Leposaviq',
      'Kosuriq',
      'Mitrovicë',
      'Kamenica',
      'Kosovo Polje',
      'Klokot',
      'Klina',
      'Kačanik',
      'Junik',
      'Istok',
      'Isniq',
      'Strellc i Epërm',
      'Lluka e Eperme',
      'Gjilan',
      'Glogovac',
      'Gllogjan',
      'Dubova (Driloni)',
      'Dranoc',
      'Dragash',
      'Strellc i Ulët',
      'Gjinoc',
      'Hani i Elezit',
      'Deçan',
      'Gjakovë',
      'Budakovo',
      'Papraqan',
      'Glanica',
      'Lloqan',
    ],
  },
  {
    country: 'Yemen',
    cities: [
      'Daḩasuways',
      'Ash Shiḩr',
      'Al Ghaylah',
      'Judaydah',
      'Ḑawrān',
      'Najd al Jamā‘ī',
      'Z̧almah al ‘Ulyā',
      'Sūq ar Rubū‘',
      'Ash Shuqayrah',
      'Al Masālīyah',
      'Ar Rawnah',
      'Ar Ramādī',
      'Al Aḩad',
      'Aḑ Ḑil‘',
      'Sūq Şirwāḩ',
      'Majzar',
      'Bidbadah',
      'Matwaḩ',
      'Şuwayr',
      'Waḑarah',
      'Al Mirwāḩ',
      'Sūq Shamar',
      'Mustabā’',
      'An Naz̧īr',
      'Aţ Ţawīlah',
      'Zinjibār',
      'Zarājah',
      'Az Zāhir',
      'Zabīd',
      'Yufrus',
      'Yarīm',
      'Washḩah',
      'Wa‘lān',
      'Dhī Jalāl',
      '‘Usaylān',
      '‘Ubāl',
      'Thamūd',
      'Tarīm',
      'Taiz',
      'As Suwaydā',
      'Al ‘Inān',
      'Abs',
      'Şīf',
      'Shiḩan as Suflá',
      'Shibām',
      'Ash Sharyah',
      'Sharas',
      'Shahārat al Ghīs',
      'Sayyān',
      'Sayḩūt',
      'Şanā’',
      'Sanaa',
      'Saḩar',
      'Sāh',
      "Sa'dah",
      'Riḩāb',
      'Radā‘',
      'Ar Raydah',
      'Raydah',
      'Ruḑūm',
      'Qalansīyah',
      'Qishn',
      'Qa‘ţabah',
      'Nişāb',
      'Al Musaymīr',
      'Mukayrās',
      'Mūdīyah',
      'Markaz Mudhaykirah',
      'Minwakh',
      'Mayfa‘ah',
      'Mīdī',
      'Mawza‘',
      'Māwiyah',
      'Al Jawl',
      "Ma'rib",
      'Manākhah',
      'Milāḩ',
      'Al Malāḩīţ',
      'Al Māfūd',
      'Mafḩaq',
      'Al Madīd',
      'Ma‘bar',
      'Al Ma‘allā’',
      'Lawdar',
      'Laḩij',
      'Kuḩlān ‘Affār',
      'Ku‘aydinah',
      'Crater',
      'Kitāf',
      'Kilmia',
      'Khawr Maksar',
      'Khawrah',
      'Khamir',
      'Juban',
      'Jiḩānah',
      'Jiblah',
      'Jawf al Maqbābah',
      'Ja‘ār',
      'Ibb',
      'Ḩūth',
      'As Salāsil',
      'Al ‘Abr',
      'Ḩays',
      'Ḩayfān',
      'Ḩawf',
      'Ḩaşwayn',
      'Ḩarīb',
      'Ḩaraḑ',
      'Ḩammām ‘Alī',
      'Ḩajjah',
      'Madīnat Lab‘ūs',
      'Hadibu',
      'Ḩabīl al Jabr',
      'Al Ḩabīlayn',
      'Ḩabbān',
      'Ḩabābah',
      'Ghayl Bā Wazīr',
      'Dhubāb',
      'Dhī Nā‘im',
      'Dhaybīn',
      'Dhī as Sufāl',
      'Dhamār',
      'Ḑawrān ad Daydah',
      'Dār an Nāşir',
      'Burūm',
      'Bayt ‘Adhāqah',
      'As Sūq al Jadīd',
      'Bayt al Faqīh',
      'Bayḩān',
      'Banī Ḩujjāj',
      'Banī Bakr',
      'Bājil',
      'Az Zuhrah',
      'Az Zaydīyah',
      'Al Ḩajab',
      'Al ‘Awābil',
      'At Turbah',
      'At Tuḩaytā’',
      'At Tawāhī',
      'Ataq',
      'As Sūdah',
      'As Sayyānī',
      'Aş Şawma‘ah',
      'Al Bilād',
      'As Sawādīyah',
      'Aş Şalīf',
      'Aş Şa‘īd',
      'Aş Şafaqayn',
      'As Saddah',
      'Ash Shaykh ‘Uthmān',
      'Ash Shaţţ',
      'Ash Shāhil',
      'Ash Shaghādirah',
      'Asdās',
      'Marbāţ',
      'Ar Rawḑah',
      'An Nādirah',
      '‘Amrān',
      '‘Amd',
      'Al Wuday‘',
      'Al ‘Udayn',
      'Al Qurḩ',
      'Al Qanāwiş',
      'Al Qaflah',
      'Qaryat al Qābil',
      'Āl Ma‘ūdah',
      'Al Munīrah',
      'Mukalla',
      'Al Misrākh',
      'Al Milāḩ',
      'Al Mighlāf',
      'Markaz al Marīr',
      'Al Marāwi‘ah',
      'Al Manşūrīyah',
      'Al Manşūrah',
      'Al Makhādir',
      'Al Maḩwīt',
      'Al Maḩfid',
      'Al Maḩābishah',
      'Al Madu',
      'Al Luḩayyah',
      'Kirsh',
      'Al Khawkhah',
      'Al Jurbah',
      'Al Jarrāḩī',
      'Al Ḩusayn',
      'Ḩurayḑah',
      'Al Ḩudaydah',
      'Al Ḩazm',
      'Al Qaţan',
      'Al Ḩarf',
      'Ḩajar al Mashā’ikh',
      'Al Ghayz̧ah',
      'Al Burayqah',
      'Al Bayda',
      'Al Bāţinah',
      'Al ‘Āqir',
      'Aḩwar',
      'Ad Durayhimī',
      'Ad Dīs ash Sharqīyah',
      'Ad Dimnah',
      'Ad Dann',
      'Az̧ Z̧alī‘ah',
      'Aḑ Ḑāli‘',
      'Aḑ Ḑaḩī',
      'Markaz Bilād aţ Ţa‘ām',
      'Al Jum‘ah',
      'Al Ḩadīyah',
      'Kusmah',
      'Al Jabīn',
      'Riqāb',
      'As Sukhnah',
      'Ar Rujum',
      'Al Khamīs',
      'Al ‘Urrah',
      'Al Maghrabah',
      'Aş Şarārah',
      'An Nāşirah',
      'Mabyan',
      'Matnah',
      'Al Ḩayfah',
      'Bayt Marrān',
      'Al Nashmah',
      'Al Ḩashwah',
      'Sāqayn',
      'Al Maḩjal',
      'Rajūzah',
      'Al Khirāb',
      'Al Ḩumaydāt',
      'Al Madān',
      'Al ‘Ashshah',
      'Ḩişn Banī Sa‘d',
      'Al Matūn',
      'Al Maşlūb',
      'Al Ghayl',
      'Al Jamīmah',
      'Ḩabūr',
      'Ar Rawḑ',
      'Ar Rubū‘',
      'Maqbanah',
      'Ḑubāh',
      'Ar Riḑā’ī',
      'Al Mayfa’ah',
      'Ar Raḑmah',
      'Ḩammām Damt',
      'Zakhim',
      'Maswarah',
      'Ţawr al Bāḩah',
      'Kushar',
      'Aslam',
      'Ḩaydān',
      'Al Miftāḩ',
      'Aden',
      'Madghil',
      'Al Ḩarjah',
      'Banī al ‘Awwām',
      'Banī an Nahārī',
      'Al Hijrah',
      'Sūq al Khamīs',
      'Al Mashāf',
      'Al Kharāb',
      'Al ‘Ayn',
      'Qaryat ad Da‘īs',
      'Ḩazm al ‘Udayn',
      'An Najd',
      'Raḩabah',
      'Al ‘Amūd',
      'Al ‘Aqabah',
      'Al Qurayshīyah',
      'Ḩawrah',
      'Ash Shawātī',
      'Al Ḩamdī',
      'At Taḩāluf',
      'Suḩayl Shibām',
      'Al Khāniq',
      'Bi’r Ḩāt',
      'Ḩadādah',
      'Rumāh',
      'Al Shirūj',
      'Jawl al Majma‘',
      'Sūq Sibāḩ',
      'Al Aqţa‘',
      'Bāqim as Sūq',
      'Maţarah',
      'Khimār',
      'Ḩabīl ar Raydah',
      'Al ‘Ulayb',
      'Aţ Ţalḩ',
      'Sirār',
      'Khayrān',
      'Al Ḩuşūn',
      'Jayshān',
      'Aş Şalw',
      'Raşad',
      'Saḩḩab',
      'Majz',
      'AL-khashā upper',
      'Al-Medy Village, قرية المدي',
      'Village of ALAMRAH',
      'Jadder Cemetery',
      'Ahl Mohammed bin Ahmed',
      'Al Qabyal',
      'Al Madakisha',
      'CPF',
    ],
  },
  {
    country: 'Mayotte',
    cities: [
      'Pamandzi',
      'Mamoudzou',
      'Dzaoudzi',
      'Dembeni',
      'Acoua',
      'Bandraboua',
      'Mtsamboro',
      "M'Tsangamouji",
      'Tsingoni',
      'Koungou',
      'Ouangani',
      'Chiconi',
      'Sada',
      'Bandrélé',
      'Chirongui',
      'Bouéni',
      'Kani Kéli',
    ],
  },
  {
    country: 'South Africa',
    cities: [
      'Roodepoort',
      'Zoar',
      'Zeerust',
      'Zastron',
      'Wolmaransstad',
      'White River',
      'Witbank',
      'Winburg',
      'Willowmore',
      'Williston',
      'Whittlesea',
      'Westonaria',
      'Wesselsbron',
      'Welkom',
      'Wartburg',
      'Warrenton',
      'Warmbaths',
      'Arniston',
      'Vryheid',
      'Vryburg',
      'Vredefort',
      'Vrede',
      'Volksrust',
      'Virginia',
      'Villiers',
      'Viljoenskroon',
      'Victoria West',
      'Vereeniging',
      'Ventersdorp',
      'Ventersburg',
      'Van Wyksvlei',
      'Vanderbijlpark',
      'Utrecht',
      'Upington',
      'Mthatha',
      'eMkhomazi',
      'Ulundi',
      'Uitenhage',
      'Tzaneen',
      'Tugela Ferry',
      'Trompsburg',
      'Thulamahashi',
      'Thohoyandou',
      'Theunissen',
      'Thabazimbi',
      'Thaba Nchu',
      'Tembisa',
      'Taung',
      'Tarkastad',
      'Swellendam',
      'Sundumbili',
      'Stutterheim',
      'Stilfontein',
      'KwaDukuza',
      'Standerton',
      'Springs',
      'Soweto',
      'Somerset East',
      'Siyabuswa',
      'Senekal',
      'Secunda',
      'Scottburgh',
      'Schweizer-Reneke',
      'Sasolburg',
      'Saint Lucia Estuary',
      'Rustenburg',
      'Riversdale',
      'Ritchie',
      'Richmond',
      'Richards Bay',
      'Reitz',
      'Randfontein',
      'Randburg',
      'Qumbu',
      'Queenstown',
      'Queensdale',
      'Prince Albert',
      'Prieska',
      'Pretoria',
      'Mokopane',
      'Potchefstroom',
      'Postmasburg',
      'Port Shepstone',
      'Port Saint John’s',
      'Port Elizabeth',
      'Port Alfred',
      'Pongola',
      'Plettenberg Bay',
      'Pinetown',
      'Piet Retief',
      'Polokwane',
      'Pietermaritzburg',
      'Phuthaditjhaba',
      'Phalaborwa',
      'Petrusville',
      'Peddie',
      'Paulpietersburg',
      'Parys',
      'Pampierstad',
      'Oudtshoorn',
      'Orkney',
      'Orania',
      'Orange Farm',
      'Modimolle',
      'Noupoort',
      'Nongoma',
      'Nkowakowa',
      'Nkandla',
      'Nigel',
      'Newcastle',
      'Nelspruit',
      'Ndwedwe',
      'Mutale',
      'Muldersdriseloop',
      'Mtubatuba',
      'Mpumalanga',
      'Mpophomeni',
      'Mount Frere',
      'Mount Ayliff',
      'Mossel Bay',
      'Mooirivier',
      'Montagu',
      'Mondlo',
      'Molteno',
      'Mogwase',
      'Modderfontein',
      'Mmabatho',
      'Mkuze',
      'Middelburg',
      'Meyerton',
      'Melmoth',
      'Matatiele',
      'Marquard',
      'Margate',
      'Marble Hall',
      'Mankoeng',
      'Makapanstad',
      'Maile',
      'Mahikeng',
      'Maclear',
      'Mabopane',
      'Lydenburg',
      'Louis Trichardt',
      'Lindley',
      'Lichtenburg',
      'Libode',
      'Lebowakgomo',
      'Laingsburg',
      'Ladysmith',
      'Lady Grey',
      'Lady Frere',
      'Ladybrand',
      'Ladismith',
      'KwaMbonambi',
      'Kutloanong',
      'Kuruman',
      'Kruisfontein',
      'Krugersdorp',
      'Kroonstad',
      'Kriel',
      'Koster',
      'Koppies',
      'Komga',
      'Komatipoort',
      'Kokstad',
      'Koffiefontein',
      'Knysna',
      'Klerksdorp',
      'Kirkwood',
      'Kimberley',
      'Kenhardt',
      'Kathu',
      'Kareedouw',
      'Kakamas',
      'Jozini',
      'Johannesburg',
      'Jansenville',
      'Jan Kempdorp',
      'Jane Furse',
      'Izingolweni',
      'Ixopo',
      'Impendle',
      'Ilinge',
      'Howick',
      'Hopetown',
      'Hoopstad',
      'Hoedspruit',
      'Hluhluwe',
      'Hlabisa',
      'Himeville',
      'Hennenman',
      'Hendrina',
      'Heilbron',
      'Heidelberg',
      'Hartswater',
      'Harrismith',
      'Harding',
      'Groblershoop',
      'Groblersdal',
      'Greytown',
      'Grahamstown',
      'Graaff-Reinet',
      'Glencoe',
      'Giyani',
      'Germiston',
      'George',
      'Ga-Rankuwa',
      'Ganyesa',
      'Ga-Kgapane',
      'Fraserburg',
      'Frankfort',
      'Fort Beaufort',
      'Fochville',
      'Flagstaff',
      'Ficksburg',
      'Estcourt',
      'eSikhaleni',
      'Eshowe',
      'Ermelo',
      'Empangeni',
      'eMbalenhle',
      'Elliot',
      'Ekuvukeni',
      'Edenvale',
      'East London',
      'Durban',
      'Dundee',
      'Duiwelskloof',
      'Driefontein',
      'Douglas',
      'Dordrecht',
      'Dewetsdorp',
      'De Rust',
      'Deneysville',
      'Dendron',
      'Delmas',
      'Delareyville',
      'De Aar',
      'Dannhauser',
      'Daniëlskuil',
      'Cullinan',
      'Creighton',
      'Cradock',
      'Colesberg',
      'Cofimvaba',
      'Clocolan',
      'Christiana',
      'Carolina',
      'Carnarvon',
      'Carletonville',
      'Camperdown',
      'Calitzdorp',
      'Cala',
      'Butterworth',
      'Burgersfort',
      'Burgersdorp',
      'Bultfontein',
      'Bronkhorstspruit',
      'Brits',
      'Breyten',
      'Bredasdorp',
      'Brandvlei',
      'Brandfort',
      'Brakpan',
      'Botshabelo',
      'Bothaville',
      'Boshof',
      'Boksburg',
      'Bochum',
      'Bloemhof',
      'Bloemfontein',
      'Bizana',
      'Bhisho',
      'Bethlehem',
      'Bethal',
      'Bergville',
      'Berea',
      'Benoni',
      'Belfast',
      'Beaufort West',
      'Barkly West',
      'Barkly East',
      'Barberton',
      'Ballito',
      'Balfour',
      'Ashton',
      'Apel',
      'Allanridge',
      'Aliwal North',
      'Alice',
      'Alberton',
      'Albertina',
      'Adelaide',
      'Ekangala',
      'Midrand',
      'Centurion',
      'Worcester',
      'Wellington',
      'Vredendal',
      'Vredenburg',
      'Stellenbosch',
      'Stanford',
      'Springbok',
      "Simon's Town",
      'Saldanha',
      'Rosebank',
      'Robertson',
      'Port Nolloth',
      'Pofadder',
      'Piketberg',
      'Paarl',
      'Moorreesburg',
      'Malmesbury',
      'Lansdowne',
      'Kraaifontein',
      'Hermanus',
      'Grabouw',
      'Garies',
      'Constantia',
      'Claremont',
      'Clanwilliam',
      'Ceres',
      'Cape Town',
      'Calvinia',
      'Caledon',
      'Bergvliet',
      'Atlantis',
      'Sunset Beach',
      'Eden Glen',
      'Rondebosch',
      'Newlands',
      'Bela-Bela',
      'Lephalale',
      'Musina',
      'Midstream',
      'Mandeni',
      'Retreat',
      'Diepsloot',
      'Hardys Memories of Africa',
      'Eastleigh',
      'Riverlea',
      'Eden Glen Ext 60',
      'Thornhill',
      'Khaya Mnandi',
    ],
  },
  {
    country: 'Zambia',
    cities: [
      'Nchelenge',
      'Nakonde',
      'Mpulungu',
      'Mporokoso',
      'Mbala',
      'Kawambwa',
      'Kaputa',
      'Zambezi',
      'Solwezi',
      'Sinazongwe',
      'Siavonga',
      'Sesheke',
      'Serenje',
      'Senanga',
      'Samfya',
      'Petauke',
      'Nyimba',
      'Ndola',
      'Namwala',
      'Nakambala',
      'Mwinilunga',
      'Mwense',
      'Mungwi',
      'Mumbwa',
      'Mufumbwe',
      'Mufulira',
      'Mpongwe',
      'Mpika',
      'Monze',
      'Mongu',
      'Mkushi',
      'Mazabuka',
      'Mansa',
      'Maamba',
      'Luwingu',
      'Lusaka',
      'Lundazi',
      'Lukulu',
      'Luanshya',
      'Luangwa',
      'Livingstone',
      'Limulunga',
      'Kitwe',
      'Kataba',
      'Kasempa',
      'Kasama',
      'Kapiri Mposhi',
      'Kaoma',
      'Kansanshi',
      'Kalulushi',
      'Kalengwa',
      'Kalabo',
      'Kafue',
      'Kabwe',
      'Kabompo',
      'Isoka',
      'Gwembe',
      'Chongwe',
      'Choma',
      'Chipata',
      'Chinsali',
      'Chingola',
      'Chililabombwe',
      'Chibombo',
      'Chambishi',
      'Chama',
      'Chadiza',
    ],
  },
  {
    country: 'Zimbabwe',
    cities: [
      'Zvishavane',
      'Victoria Falls',
      'Shurugwi',
      'Shangani',
      'Shamva',
      'Ruwa',
      'Rusape',
      'Redcliff',
      'Raffingora',
      'Plumtree',
      'Penhalonga',
      'Odzi',
      'Nyazura',
      'Nyanga',
      'Norton',
      'Mvurwi',
      'Mvuma',
      'Mutoko',
      'Mutare',
      'Murehwa',
      'Mount Darwin',
      'Mhangura',
      'Mazowe',
      'Masvingo',
      'Mashava',
      'Marondera',
      'Macheke',
      'Lupane',
      'Lalapanzi',
      'Kwekwe',
      'Karoi',
      'Kariba',
      'Kamativi Mine',
      'Kadoma',
      'Inyati',
      'Insiza',
      'Hwange',
      'Headlands',
      'Harare',
      'Gweru',
      'Gwanda',
      'Gokwe',
      'Glendale',
      'Filabusi',
      'Esigodini',
      'Dorowa Mining Lease',
      'Dete',
      'Concession',
      'Chivhu',
      'Chirundu',
      'Chiredzi',
      'Chipinge',
      'Chinhoyi',
      'Chimanimani',
      'Chegutu',
      'Chakari',
      'Centenary',
      'Bulawayo',
      'Binga',
      'Bindura',
      'Beitbridge',
      'Beatrice',
      'Banket',
      'Epworth',
      'Chitungwiza',
    ],
  },
];

export default allCities;
