import React, { useState } from 'react';

import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DragIndicatorTwoToneIcon from '@mui/icons-material/DragIndicatorTwoTone';

import { ReactComponent as EnglishIcon } from '../../images/languagesIcons/English.svg';
import { ReactComponent as FrenchIcon } from '../../images/languagesIcons/France.svg';
import { ReactComponent as SpanishIcon } from '../../images/languagesIcons/Spain.svg';
import { ReactComponent as SwahiliIcon } from '../../images/languagesIcons/Swahili.svg';
import { ReactComponent as Portuguese } from '../../images/languagesIcons/Portuguese.svg';
import { ReactComponent as ArabicIcon } from '../../images/languagesIcons/Arabic.svg';
import { ReactComponent as HindiIcon } from '../../images/languagesIcons/Hindi.svg';

import { Draggable } from 'react-beautiful-dnd';
import { addBrTagInLine } from '../helpers/parseLineBreak';
import { processTextInLink } from '../helpers/linksProcessing';
import { deleteAd, editAd } from '../../api/admin';
import { setAds, showLoader } from '../../redux/slices/appSlice';
import { useDispatch } from 'react-redux';
import { getAds } from '../../api/user';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
// import { makeStyles } from '@material-ui/core/styles';


interface Props {
  index: number;
  item: any;
  setItemsArray: (items: any) => void;
  itemsArray: any;
  setSelectedAdIndex: (index: number | undefined) => void;
  setModalStep: (step: number) => void;
  setSelectedChips: (chips: any) => void;
  setAddedLanguages: (chips: any) => void;
}

// const useDraggingStyles = makeStyles({
//   draggingListItem: {
//     background: '#FFF',
//     // maxWidth: '916px',
//     overflow: 'hidden',
//   },
//   listItem: {
//     cursor: 'default !important',
//   }
// });

const AdsItem = ({ index, item, setItemsArray, itemsArray, setSelectedAdIndex, setModalStep, setAddedLanguages,  setSelectedChips}: Props) => {
  // const classes = useDraggingStyles();

  const dispatch = useDispatch()

  const { allInterests: chips } = useSelector((state: RootState) => state.app);

  return (
    <Draggable draggableId={item.id + ''} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? 'AdsModal-item__wrapper AdsModal-item__wrapper--drag' : 'AdsModal-item__wrapper'}
        >
          <div className={`AdsModal-item__drag ${item?.isDisabled ? 'AdsModal-item__drag--disabled' : ''}`}>
            <DragIndicatorTwoToneIcon />
          </div>

          <div className={`AdsModal-item ${item?.isDisabled ? 'AdsModal-item--disabled' : ''}`} >
            <div className='AdsModal-item__left'>
              <img className='AdsModal-item__img' src={item?.img} alt="ads-img" />
              <div className='AdsModal-item__languages'>
                {item?.addedLanguages.map((lang: any, index: number) => {
                  if (lang.code === 'en') {
                    return <EnglishIcon key={index} />
                  }
                  if (lang.code === 'fr') {
                    return <FrenchIcon key={index} />
                  }
                  if (lang.code === 'es') {
                    return <SpanishIcon key={index} />
                  }
                  if (lang.code === 'sw') {
                    return <SwahiliIcon key={index} />
                  }
                  if (lang.code === 'pt') {
                    return <Portuguese key={index} />
                  }
                  if (lang.code === 'ar') {
                    return <ArabicIcon key={index} />
                  }
                  if (lang.code === 'hi') {
                    return <HindiIcon key={index} />
                  }
                })}
              </div>
            </div>

            <div className='AdsModal-item__main'>
              <div className='AdsModal-item__title'>
                {item?.addedLanguages.find((lang: any) => lang.code === 'en')?.title || item?.addedLanguages[0]?.title}
              </div>
              <div className='AdsModal-item__text'>
                {
                  processTextInLink(addBrTagInLine(
                    item?.addedLanguages.find((lang: any) => lang.code === 'en')?.descr || item?.addedLanguages[0]?.descr
                  ))
                }
              </div>
            </div>

            <div className='AdsModal-item__menu'>
              <div className='AdsModal-item__disabled'>
                {item?.isDisabled && 'disabled'}
                <div className='AdsModal-item__btn'>
                  {item?.isDisabled ? (
                    <RemoveRedEyeTwoToneIcon onClick={() => {
                      dispatch(showLoader(true))
                      editAd({ ...item, isDisabled: false })
                        .then((res) => {
                          if (res.status === 200) {
                            const newArr = JSON.parse(JSON.stringify(itemsArray))
                            newArr[index].isDisabled = false;
                            setItemsArray(newArr);
                          }
                        })
                        .finally(() => {
                          dispatch(showLoader(false))
                        })
                    }} />
                  ) : (
                    <VisibilityOffTwoToneIcon onClick={() => {
                      dispatch(showLoader(true))
                      editAd({ ...item, isDisabled: true })
                        .then((res) => {
                          if (res.status === 200) {
                            const newArr = JSON.parse(JSON.stringify(itemsArray))
                            newArr[index].isDisabled = true;
                            setItemsArray(newArr)
                          }
                        })
                        .finally(() => {
                          dispatch(showLoader(false))
                        })
                    }} />
                  )}
                </div>
              </div>

              <div className='AdsModal-item__btn'>
                <EditTwoToneIcon onClick={() => {
                  setSelectedAdIndex(index);
                  setAddedLanguages(itemsArray[index].addedLanguages);
                  setSelectedChips(itemsArray[index].selectAll ? chips.filter((item: Chip) => !item.isArchived) : itemsArray[index].interests)
                  setModalStep(1)
                }} />
              </div>
              <div className='AdsModal-item__btn'>
                <DeleteTwoToneIcon onClick={() => {
                  dispatch(showLoader(true))
                  deleteAd({ adsId: item.id })
                    .then((res) => {
                      if (res.status === 200) {
                        const newArr = JSON.parse(JSON.stringify(itemsArray))
                        newArr.splice(index, 1);
                        setItemsArray(newArr)
                      }
                    })
                    .finally(() => {
                      dispatch(showLoader(false))
                      getAds().then((res: any) => {
                        if(res.status === 200){
                          dispatch(setAds(res.data));
                        }
                      })
                    })
                }} />
              </div>
            </div>

            <div className="AdsModal-item__counter">
              {item?.counterClicks || 0} clicks
            </div>
          </div>
        </div >
      )}
    </Draggable >
  )
}

export default AdsItem;