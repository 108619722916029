import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useWindowDimensions from '../../hooks/widthHook';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import './ReportReasons.scss';

export const ReportReasons: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams() || '';
  const { reportedUserReasons } = useSelector((state: RootState) => state.admin);

  const closeReportReasons = () => {
    searchParams.delete('report-reasons');
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const dateConvert = (created: string | undefined) => {
    if (created) {
      const date = new Date(created).toString().split(' ')[2];
      const month = new Date(created).toString().split(' ')[1];
      const time = new Date(created).toString().split(' ')[4].slice(0, 5);

      return `${date} ${month} at ${time}`;
    } else {
      return '';
    }
  };

  return (
    <div>
      <ModalWrapper
        width={(useWindowDimensions().width > 768) ? '607px' : '100%'}
        isMobile={(useWindowDimensions().width <= 768) && true}>
        <ModalContainer
          isMobile={(useWindowDimensions().width <= 768) && true}
        >
          <ModalHeader
            textHeader={`Report Reasons (${reportedUserReasons.length})`}
            onClose={closeReportReasons}
          />
          <ul className="ReportReasons__list">
            {reportedUserReasons?.map((reason: any) => (
              <li
                className="ReportReasons__wrapper"
                key={reason.id}
              >
                <div className="ReportReasons__container-user">
                  <div className="ReportReasons__container-avatar">
                    <img
                      className="ReportReasons__avatar"
                      src={reason?.user.profileAvatar}
                      alt=""
                    />
                  </div>

                  <div className="ReportReasons__title-wrapper">
                    <div
                    // onClick={() => useNavigateToTop(`/profile/${reason.reasonId}`)}
                    >
                      <h2 className="ReportReasons__title">
                        {`${reason?.user.organizationName}`
                          || `${reason?.user.firstName} ${reason?.user.lastName}`}
                      </h2>
                    </div>
                    <h4 className="ReportReasons__created">
                      {dateConvert(reason?.created_at)}
                    </h4>
                  </div>
                </div>

                <div className="ReportReasons__reason-text">{reason.reason}</div>
              </li>
            ))}
          </ul>
          <ModalFooter isMobile={(useWindowDimensions().width <= 768)}>
            <MainButton
              text='Close'
              onClick={closeReportReasons}
            />
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>
    </div>
  )
}
