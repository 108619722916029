import { customAxios } from '../api/api';
import { API } from '../api/api';

// ======================================================================== //
const CreateNewTypePost_API = `${API}/api/admin/create-new-post-type`; //post
const ArchivePostType_API = `${API}/api/admin/archive-post-type`; // put
const CreateNewInterest_API = `${API}/api/admin/create-new-interest`; // post
const ArchiveInterest_API = `${API}/api/admin/archive-interest`; // put
const EditInterest_API = `${API}/api/admin/edit-tags-n-interests`; // put
// ======================================================================== //

export async function createNewPostTypeAPI(data: NewPostTypeParams) {
  return await customAxios.post(
    CreateNewTypePost_API,
    { ...data, alias: 'string' });
}

export async function archivePostTypeAPI(postTypeId: number) {
  return await customAxios.put(ArchivePostType_API, { id: postTypeId });
}

export async function createNewInterestAPI(data: NewInterestParams) {
  return await customAxios.post(CreateNewInterest_API, data);
}

export async function archiveInterestAPI(interestId: number) {
  return await customAxios.put(ArchiveInterest_API, { interestId });
}

export async function editInterestAPI(data: any) {
  return await customAxios.put(EditInterest_API, data);
}

// ============= Reported On & Disable Request ============= 

export async function deactiveProfileAPI(userId: string) {
  return await customAxios.post(`${API}/api/admin/deactive-profile`, { userId });
}

export async function activeProfileAPI(userId: string) {
  return await customAxios.post(`${API}/api/admin/active-profile`, { userId });
}

export async function getReportedProfilesAPI(skip: number) {

  return await customAxios.get(`${API}/api/admin/get-reported-profiles?take=10&skip=${skip}`);
}

export async function getDeactiveProfilesAPI(skip: number) {
  return await customAxios.get(`${API}/api/admin/get-deactive-profiles?take=10&skip=${skip}`);
}

export async function getReportedUserReasonsAPI(userId: string) {
  return await customAxios.get(`${API}/api/admin/get-reasons-for-users?userId=${userId}`);
}

export async function getReportedCommentsAPI(skip: number) {
  return await customAxios.get(`${API}/api/admin/get-report-comments?take=5&skip=${skip || 0}`);
}

export async function getReportedReasonsToCommentAPI(id: number) {
  return await customAxios.get(`${API}/api/admin/get-reasons-for-comments?commentId=${id}`);
}

export async function skipReportToCommentAPI(data: any) {
  console.log('data', data)
  return await customAxios.delete(`${API}/api/admin/skip-report-comment`, { data });
}

export async function deleteReportedCommentAPI(data: any) {
  console.log('data', data)
  return await customAxios.delete(`${API}/api/admin/delete-report-comment`, { data });
}


// ============= FAQ ============= 

export async function getPrivacyPolicy() {
  return await customAxios.get(`${API}/api/get-privacy`);
}

export async function getTermsOfUse() {
  return await customAxios.get(`${API}/api/get-terms`);
}

export async function postPrivacyPolicy(data: any) {
  return await customAxios.post(`${API}/api/admin/create-privacy`, data)
    .then(data => console.log(data))
    .catch(err => console.log(err))
}

export async function postTermsOfUse(data: any) {
  return await customAxios.post(`${API}/api/admin/create-terms`, data)
    .then(data => console.log(data))
    .catch(err => console.log(err))
}

// Ads - must return Promise
export async function deleteAd(data: { adsId: number }) {
  return customAxios.delete(`${API}/api/adv/delete-ads`, { data })
}

export async function createAd(data: any) {
  return customAxios.post(`${API}/api/adv/create-ads`, data)
}

export async function editAd(data: any) {
  const preparedData = JSON.parse(JSON.stringify(data));
  delete preparedData.counterClicks;
  delete preparedData.counter_clicks;
  delete preparedData.created_at;
  delete preparedData.updated_at;
  delete preparedData.position;

  return customAxios.put(`${API}/api/adv/edit-ad`, preparedData)
}

export async function updateAdsPositon(data: any) {
  return customAxios.put(`${API}/api/adv/change-positions`, data)
}

export async function getAllUsersEmails() {
  return await customAxios.get(`${API}/api/admin/get-all-users-emails`);
}

export async function getAllFakeAccs() {
  return await customAxios.get(`${API}/api/admin/get-all-fake-accoutns`);
}

export async function deleteFakeAccs(data: {id: string}) {
  return await customAxios.delete(`${API}/api/admin/delete-fake-accoutns`, { data });
}