import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getIcon } from '../helpers/getIcon';
import ModalContainer from '../ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../shared/Buttons/MainButton/MainButton';
import useWindowDimensions from '../../hooks/widthHook';
import mail from '../../images/modal-mail.png';
import { setSignUpMailModal } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import './SignUpMailModal.scss';

function SignUpMailModal() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useSelector((state: RootState) => state.profile);

  const { width } = useWindowDimensions();
  const iconWidth = width > 1100 ? '56' : '32';
  const modalWidth = width > 1100 ? '698px' : '327px';

  return (
    <ModalWrapper width={modalWidth}>
      <ModalContainer congrats>
        <ModalHeader textHeader='' smallCross onClose={() => dispatch(setSignUpMailModal(false))}></ModalHeader>
        <div className="signUp-mail">
          {getIcon(mail, 'congrats!', iconWidth)}
          {language === 'ar' ?
            <>
              <h3 className="signUp-mail__title" dir="rtl">
                {formatMessage({ id: 'checkMail' })}
              </h3>
              <p className="signUp-mail__subtitle" dir="rtl">
                {formatMessage({ id: 'checkMailText' })}
              </p>
              <p className="signUp-mail__description" dir="rtl">
                {formatMessage({ id: 'checkMailThanks' })} <span style={{color: "#791186"}}>Wizwork!</span>
              </p>
            </> :
            <> 
              <h3 className="signUp-mail__title">
                {formatMessage({ id: 'checkMail' })}
              </h3>
              <p className="signUp-mail__subtitle">
                {formatMessage({ id: 'checkMailText' })}
              </p>
              <p className="signUp-mail__description">
                {formatMessage({ id: 'checkMailThanks' })} <span style={{color: "#791186"}}>Wizwork!</span>
              </p>
            </>
          }
          {/* <ModalFooter>
            <MainButton
              type="button"
              text={formatMessage({ id: 'ok' })}
              onClick={() => dispatch(setSignUpMailModal(false))}
            />
          </ModalFooter> */}
        </div>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default SignUpMailModal;
