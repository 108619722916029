import React, { Component, ReactNode } from 'react';
import ErrorIndicator from './ErrorIndicator';

interface ErrorBoundaryProps {
  children?: ReactNode;
  error?: boolean;
  forHomePage?: boolean;
  forRelaxPage?: boolean;
  withGoButton?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(): ErrorBoundaryState {
    return ({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const {
      error = false,
      forHomePage = false,
      forRelaxPage = false,
      withGoButton = true,
      children,
    } = this.props;

    if (hasError || error) {
      return (
        <ErrorIndicator
          forHomePage={forHomePage}
          forRelaxPage={forRelaxPage}
          withGoButton={withGoButton}
        />);
    }
    return children;
  }
}
