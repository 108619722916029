import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../redux/store';
import { createComment, getComments } from '../../redux/slices/postsSlice';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import TextAreaComments from '../shared/TextAreaComments/TextAreaComments';
import Comment from './Comment/Comment';
import './Comments.scss';
import { useLocation } from 'react-router';
import { ShareButton } from '../shared/Buttons/ShareButton/ShareButton';

interface Props {
  post: Post;
  showShareBtn?: boolean
}

const Comments: React.FC<Props> = ({ post, showShareBtn }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { userPosts, groupPosts, feedPosts } = useSelector((state: RootState) => state.posts);
  const { profileAvatar, anotherUserId } = useSelector((state: RootState) => state.profile);
  const lan = localStorage.getItem('lang') || 'en';

  const [skipCounter, setSkipCounter] = useState(0)

  const [commetnsLength, setCommetnsLength] = useState(
    post?.comments?.length || 0,
  );

  const [showComments, setShowComments] = useState(false);
  const [showFeedComments, setShowFeedComments] = useState(false);
  const [conterFeedComments, setCounterFeedComments] = useState(
    post?.counter_comments || 0
  );

  useEffect(() => {
    setCommetnsLength(post?.comments ? post?.comments?.length : 0);
    setCounterFeedComments(post?.counter_comments || 0);
  }, [userPosts, post?.comments, post?.counter_comments, groupPosts, feedPosts]);

  // ===== CREATE COMMENT =====
  const [commentText, setCommentText] = useState('');
  const createCommentFunc = () => {
    if (commentText?.trim()?.length === 0) {
      toast.info(formatMessage({ id: 'commentCantBeEmpty' }));
      return;
    }

    const commentObj = {
      id: Number(post?.id),
      text: commentText.trim()
    } as any;

    if ((anotherUserId?.idOfPost === post?.id || anotherUserId?.idOfPost === post?.originalPost?.id)
      && anotherUserId?.user) {
      commentObj.anotherUserId = anotherUserId?.user;
    };

    dispatch(createComment(commentObj));
    setCommentText('');
  };

  return (
    <>
      <div
        className="write-comment"
        style={{
          flexDirection: lan === 'ar' ? 'row-reverse' : 'row'
        }}
      >
        <div className="write-comment-avatar-container">
          <img
            className="write-comment-avatar"
            src={(anotherUserId?.image
              && (anotherUserId?.idOfPost === post?.originalPost?.id || anotherUserId?.idOfPost === post?.id))
              ? anotherUserId?.image 
              : profileAvatar}
            alt="avatar"
          />
        </div>

        <div className="write-comment-input-container">
          <TextAreaComments
            placeholder={formatMessage({ id: 'writeComment' })}
            value={commentText}
            setValue={setCommentText}
            createComment={createCommentFunc}
          />

          <SendTwoToneIcon
            className="write-comment-send"
            onClick={createCommentFunc}
            style={{
              right: lan === 'ar' ? 'none' : '17px',
              left: lan === 'ar' ? '17px' : 'none',
              transform: 'translateY(-65%)'
            }}
          />
        </div>
      </div>


      <div className='comment-bottom-btn'>
        <div
          className="showComments"
          onClick={() => {
            if (commetnsLength) {
              setShowComments(!showComments);
              setSkipCounter(0)
            }
            setShowFeedComments(!showFeedComments);
            !showFeedComments
              && dispatch(getComments({ postId: post?.id, take: 5, skip: 0 }));
          }}
        >
          <div
            className="showComments-show-comments-text title"
            style={{
              cursor: 'pointer',
              textAlign: lan === 'ar' ? 'right' : 'left'
            }}
          >
            {!showFeedComments &&
              conterFeedComments !== 0 &&
              `${formatMessage({ id: 'show' })} ` +
              `${conterFeedComments} ` +
              `${formatMessage({ id: 'comment-s' })}`
            }
            {showFeedComments &&
              conterFeedComments !== 0 &&
              formatMessage({ id: 'hideCommentsMain' })}
            {
              conterFeedComments === 0 &&
              formatMessage({ id: 'noComments' })}
          </div>
        </div>

        {showShareBtn && <ShareButton post={post} />}
      </div>

      {showFeedComments && (
        <>
          {post?.comments?.map((comment, index) => (
            <Comment
              comment={comment}
              key={index}
            />
          ))}
        </>
      )}

      {
        showFeedComments &&
        commetnsLength !== 0 && (
          <h3
            onClick={() => {
              setSkipCounter(prev => prev + 5)
              showFeedComments && dispatch(getComments({
                postId: post?.id, take: 5, skip: skipCounter + 5
              }));
            }}
            className={`showComments-show-comments-text${(post.totalComments &&
              (commetnsLength === post.totalComments)) ?
              ' showComments-show-comments-text--disabled' : ''}`}
            style={{
              textAlign: lan === 'ar' ? 'right' : 'left'
            }}
          >
            {formatMessage({ id: 'showMore' })}
          </h3>
        )}
    </>
  );
};

export default Comments;
