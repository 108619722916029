import { Cloudinary } from "@cloudinary/url-gen";
import { Cloudinary_NAME } from '../../constants';

export async function getAdvancedVideoPropertiesVideo(url: string) {

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: `${Cloudinary_NAME}`
    },
    url: {
      analytics: false
    }
  });

  const urlArr = url.split('/');
  const nameOfVideo = urlArr.pop();

  // Use the video with public ID, 'docs/walking_talking'.
  const myVideo = cld.video(nameOfVideo?.split('.')[0]);
  myVideo.format('mp4');
  const urlVidMp4 = myVideo.toURL();
  
  // console.log(nameOfVideo?.split('.')[0]);
  // console.log('nameOfVideo', nameOfVideo?.split('.')[0]);

  return await saveVid(urlVidMp4);
}

async function saveVid (url: string) {
  const res = await fetch(url, {
    method: 'GET'
  });
  const blob = await res.blob();
  const urlVid = window.URL.createObjectURL(
    new Blob([blob], {
      type: 'video/mp4'
    }),
  );
  return urlVid;
}