import React, { ReactNode } from 'react';
import './ModalFooter.scss';

interface ModalFooterProps {
  children: ReactNode,
  isMobile?: boolean,
}

function ModalFooter({ children, isMobile }: ModalFooterProps) {
  return (
    <div className={`ModalFooter${isMobile ? ' ModalFooter--mobile' : ''}`}>
      {children}
    </div>
  );
}

export default ModalFooter;
