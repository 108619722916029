import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ErrorBoundary from '../../../components/ErrorBoundary';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import { DeactivateModal } from './DeactivateModal/DeactivateModal';

export const DeactivateSettings = () => {
  const { formatMessage } = useIntl()
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className='accountSettings'>
        <div className='accountSettings-title'>
          {formatMessage({ id: 'deactivateAccount' })}
        </div>
        <ErrorBoundary>
          <div className='accountSettings-footer'>
            <MainButton
              text={formatMessage({ id: 'sendRequest' })}
              type="submit"
              onClick={() => { setShowModal(true) }}
            />
          </div>
        </ErrorBoundary>
      </div>
      <DeactivateModal showModal={showModal} setShowModal={setShowModal} />
    </>

  );
};
