import { customAxios } from '../api/api';
import { API } from '../api/api';

const Interests_API = `${API}/api/interests`; //get
const Groups_API = `${API}/api/groups`; //get

export async function getInterestsAPI() {
  return await customAxios.get<Chip[]>(Interests_API);
}

export async function getGroupsAPI() {
  return await customAxios.get(`${Groups_API}/get-info`);
}

export async function getPostsTypeAPI() {
  return await customAxios.get(`${Groups_API}/get-types-of-posts`);
}

export async function getTypeOfInterestsAPI() {
  return await customAxios.get<Chip[]>(`${Groups_API}/types-of-interests`);
}

export async function getInterestsAreaAPI() {
  return await customAxios.get<Chip[]>(`${Groups_API}/interests-area`);
}

export async function getRegionalActivismAPI() {
  return await customAxios.get<Chip[]>(`${Groups_API}/regional-activism`);
}

export async function getStrategiesAndSolutionsAPI() {
  return await customAxios.get<Chip[]>(`${Groups_API}/strategies-solutions-networking`);
}

export async function getVoiceAmplifiedAPI() {
  return await customAxios.get<Chip[]>(`${Groups_API}/voice-amplified`);
}

export async function getTypeOfPostAPI() {
  return await customAxios.get<GetTypeOfPost[]>(`${Groups_API}/get-type-of-posts`);
}

export async function getPostInterestsAPI(postId: number) {
  return await customAxios.get(`${API}/api/posts/get-post-interests?postId=${postId}`);
}