import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../hooks/widthHook';
import ModalContainer from '../../../components/ModalConstructor/ModalContainer/ModalContainer';
import ModalFooter from '../../../components/ModalConstructor/ModalFooter/ModalFooter';
import ModalHeader from '../../../components/ModalConstructor/ModalHeader/ModalHeader';
import ModalWrapper from '../../../components/ModalConstructor/ModalWrapper/ModalWrapper';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { getReportedReasonsToComment } from '../../../redux/slices/adminSlice';
import { useNavigateToTop } from '../../../components/helpers/useNavigateToTop';
import { getPostReportReasons } from '../../../redux/slices/postsSlice';
import './CommentReportReasons.scss';

type Props = {
  reportedComment: ReportedComment | number;
  setCommentReportReasonsModal: Dispatch<SetStateAction<boolean>>;
};

export const CommentReportReasons = ({
  reportedComment,
  setCommentReportReasonsModal,
}: Props) => {
  const navigateToTop = useNavigateToTop();
  const dispatch = useDispatch<AppDispatch>();
  const [reasons, setReasons] = useState<any>([]);

  const { postReportReasons } = useSelector(
    (state: RootState) => state.posts,
  );
  const { reportedCommentsReasonsInfo } = useSelector(
    (state: RootState) => state.admin,
  );

  useEffect(() => {
    if (typeof reportedComment === "number") {
      dispatch(getPostReportReasons(reportedComment));
    } else {
      dispatch(getReportedReasonsToComment(reportedComment?.comment?.id));
    }
  }, [reportedComment]);

  useEffect(() => {
    if (typeof reportedComment === 	"number") {
      setReasons(postReportReasons);
    } else {
      setReasons(reportedCommentsReasonsInfo);
    }
  }, [postReportReasons, reportedCommentsReasonsInfo]);

  const dateConvert = (created: string | undefined) => {
    if (created) {
      const date = new Date(created).toString().split(' ')[2];
      const month = new Date(created).toString().split(' ')[1];
      const time = new Date(created).toString().split(' ')[4].slice(0, 5);

      return `${date} ${month} at ${time}`;
    } else {
      return '';
    }
  };

  return (
    <div>
      <ModalWrapper
        width={useWindowDimensions().width > 768 ? '607px' : '100%'}
        isMobile={useWindowDimensions().width <= 768 && true}
      >
        <ModalContainer isMobile={useWindowDimensions().width <= 768 && true}>
          <ModalHeader
            textHeader={`Report Reasons (${reasons?.length})`}
            onClose={() => setCommentReportReasonsModal(false)}
          />
          <ul className="CommentsReportReasons__list">
            {reasons ? (
              <>
                {reasons?.map((reason: any) => (
                  <li
                    className="CommentsReportReasons__wrapper"
                    key={reason.id}
                  >
                    <div className="CommentsReportReasons__container-user">
                      <div className="CommentsReportReasons__container-avatar">
                        <img
                          className="CommentsReportReasons__avatar"
                          src={reason.user.profileAvatar}
                          alt="avatar"
                        />
                      </div>

                      <div className="CommentsReportReasons__title-wrapper">
                        <div
                          onClick={() =>
                            navigateToTop(`/profile/${reason.user.id}`)
                          }
                        >
                          <h2 className="CommentsReportReasons__title">
                            {`${reason?.user.organizationName}` ||
                              `${reason?.user.firstName} ${reason?.user.lastName}`}
                          </h2>
                        </div>
                        <h4 className="CommentsReportReasons__created">
                          {dateConvert(reason?.created_at)}
                        </h4>
                      </div>
                    </div>

                    <div className="CommentsReportReasons__reason-text">
                      {reason.reason}
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <div>There are no reported comment reasons</div>
            )}
          </ul>
          <ModalFooter isMobile={useWindowDimensions().width <= 768}>
            <MainButton
              text="Close"
              onClick={() => setCommentReportReasonsModal(false)}
            />
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>
    </div>
  );
};
