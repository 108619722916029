import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../redux/store';
import TextArea from '../../../components/shared/TextArea/TextArea';
import { useSelector } from 'react-redux';
import { addPrivacyPolicy, getPrivPolicy } from '../../../redux/slices/adminSlice';
import { parseLineBreak, parsePrivacyText } from '../../../components/helpers/parseLineBreak';
import './PrivacyPolicy.scss';

export const PrivacyPolicyFaq: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [textFieldOpen, setTextFieldOpen] = useState<boolean>(false);
  const [privacyText, setPrivacyText] = useState<any>('');
  const [privacyValue, setPrivacyValue] = useState<any>('');

  const { isAdmin } = useSelector((state: RootState) => state.profile);
  const { privacy } = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    dispatch(getPrivPolicy());
  }, []);

  useEffect(() => {
    setPrivacyText(parsePrivacyText(privacy));
    const newValue = privacy.map((word: string) => {
      if (word.includes('https://') || word.includes('http://') || word.includes('www.')) {
        return word;
      } else {
        return word.replaceAll('/', ',');
      }
    }).join("\r\n");
    setPrivacyValue(newValue);
  }, [privacy]);

  const saveText = () => {
    setTextFieldOpen(false);
    dispatch(addPrivacyPolicy({
      text: parseLineBreak(privacyValue)
    }));
    setPrivacyText(parsePrivacyText(parseLineBreak(privacyValue)));
  };

  return (
    <div className='faq'>
      <div className='faq__header'>
        <div className='faq-title'>
          Privacy Policy
        </div>
        {isAdmin && (<button
          className={textFieldOpen ? 'privacy-policy__btn privacy-policy__btn--disable' : 'privacy-policy__btn '}
          type='button'
          onClick={() => setTextFieldOpen(true)}
        >
          Edit Text
        </button>)
        }
      </div>
      
      {isAdmin ? (
        <div className='privacy-policy'>
          {textFieldOpen ? (
            <>
              <TextArea
                type={'privacy'}
                value={privacyValue}
                setValue={setPrivacyValue}
              />
              <div className='privacy-policy__btns'>
                <button
                  className='privacy-policy__btn'
                  type='button'
                  onClick={() => setTextFieldOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className='privacy-policy__btn privacy-policy__btn--colorfull'
                  type='button'
                  onClick={saveText}
                >
                  Save Changes
                </button>
              </div>
            </>
          ) : (
            <div
              className='privacy-policy__text-value'  
            >
              {privacyText}
            </div>
          )} 
        </div>
      ) : (
        <div
          className='privacy-policy__text-value'  
        >
          {privacyText}
        </div>
      )}
    </div>
  );
};
