import axios from 'axios';
import { toast } from 'react-toastify';
import { API, customAxios } from './api';
import { intl } from '../localization'

const Posts_API = `${API}/api/posts`;

export const getPostsApi = async (data: GetUserPost) => {
  const url = new URL(`${API}/api/posts`);
  // for (const key in data) {
  //   url.searchParams.set(key, data[key]);
  // }
  url.searchParams.set('userId', data.userId);
  url.searchParams.set('take', data.take.toString() || '5');
  url.searchParams.set('skip', data.skip.toString() || '0');
  const posts = await customAxios.get(url.href);

  return posts.data;
};

export const getPostApi = async (postId: string) => {
  const post = await customAxios.get(`${API}/api/posts/get-post-by-id?postId=${postId}`);

  return post.data;
};

export const getMorePostsApi = (limit: number) => {
  return customAxios
    .get(`${API}/api/posts?page=1&limit=${limit}`)
    .then(response => {
      return response.data.items;
    })
    .catch(error => {
      console.error(error);
    });
};

export async function getFeedPostsAPI(skip: number) {
  return await customAxios.get(`${API}/api/feed/get-posts?take=5&skip=${skip}`);
}

export async function createPostAPI(data: CreatePost) {
  const {
    title, description, interests, typeId, attachment, group, answers, showEmail, email, delay, userId
  } = data;

  return await customAxios.post(`${Posts_API}/create-post`, {
    title, description, interests, typeId, attachment, group, answers, showEmail, email, delay, userId
  });
}

export async function editPostAPI(data: EditPost) {
  const {
    postId,
    title,
    description,
    interests,
    attachment,
    answers,
    showEmail,
    email,
  } = data;

  return await customAxios.put(`${Posts_API}`, {
    postId,
    title,
    description,
    interests,
    answers,
    attachment,
    showEmail,
    email,
  });
}

export const postVoteApi = (answerId: number) => {
  return customAxios
    .post(`${API}/api/posts/answer`, { answerId })
    .then(response => {
      return response.data;
    })
};

export const postReactionAPI = (type: string, postId: number, anotherUserId?: string) => {
  return customAxios
    .post(`${API}/api/posts/reaction`, { type, postId, anotherUserId})
    .then(response => {
      return response.data;
    })
};

export const postDeleteAPI = (postId: number) => {
  return customAxios
    .delete(`${API}/api/posts`, {
      data: {
        postId,
      },
    })
    .then(response => {
      return response;
    })
};

export const postShareAPI = (postId: number, description: string) => {
  return customAxios
    .post(`${API}/api/posts/share`, {
      postId,
      description,
    })
    .then(response => {
      if(response.status === 200){
        const message = intl.intl().formatMessage({ id: 'postShared' })
        toast.info(message);
      }
      return response;
    })
};

// =====Comments====Subcomments=====Liking========================

export async function createCommentPostAPI(data: Comment) {
  const { id, text, anotherUserId} = data;
  return await customAxios.post(`${Posts_API}/comment`, {
    postId: id,
    text: text,
    anotherUserId
  });
}

export async function editCommentPostAPI(data: Comment) {
  const { id, text } = data;
  return await customAxios.put(`${Posts_API}/comment`, {
    commentId: id,
    text: text,
  });
}

export async function deleteCommentPostAPI(data: Comment) {
  return await customAxios.delete(`${Posts_API}/comment`, { data });
}

export async function createSubcommentPostAPI(data: Comment) {
  const { id, text, anotherUserId } = data;
  return await customAxios.post(`${Posts_API}/comment`, {
    commentId: id,
    text: text,
    anotherUserId
  });
}

// https://wizwork-dev.herokuapp.com/api/posts/like
// Example Value Schema
// {
//   "type": "LIKE",   or ("DISLIKE")
//   "commentId": 0
// }

export async function likePostAPI(data: Liking) {
  return await customAxios.post(`${Posts_API}/like`, {
    commentId: data.id,
    type: data.type,
    anotherUserId: data.anotherUserId
  });
}

export async function bookmarkPostAPI(data: BookmarkPost) {
  return await customAxios.post(`${Posts_API}/bookmark`, {
    postId: data.postId,
    isBookmarked: data.isBookmarked,
  });
}

export async function getBookmarkedPostsAPI(skip: number) {
  return await customAxios.get<Post[]>(`${Posts_API}/get-bookmark-posts?take=5&skip=${skip}`);
}

export async function reportPost(data: { postId: number; reason: string }) {
  return await customAxios.post(`${Posts_API}/report-post`, data)
    .then(res => {
      if(res.status === 200){
        const message = intl.intl().formatMessage({ id: 'dataSent' })
        toast.success(message)
      }
      return res;
    });
}

export async function reportComment(data: { commentId: number; reason: string }) {
  return await customAxios.post(`${Posts_API}/report-comments`, data)
    .then(res => {
      const message = intl.intl().formatMessage({ id: 'dataSent' })
      toast.success(message)
    });
}

export async function getCommentsAPI(data: any) {
  const url = new URL(`${Posts_API}/get-comments-to-post`);
  url.searchParams.set('postId', data.postId);
  url.searchParams.set('take', data.take.toString());
  url.searchParams.set('skip', data.skip.toString());
  const posts = await customAxios.get(url.href);

  return posts.data;
}

export async function getSubcommentsAPI(data: any) {
  const url = new URL(`${Posts_API}/get-subcomments-to-post`);
  url.searchParams.set('commentId', data.commentId);
  url.searchParams.set('take', data.take.toString());
  url.searchParams.set('skip', data.skip.toString());
  const posts = await customAxios.get(url.href);

  return posts.data;
}

export async function getPostsReports(skip: number) {
  return await customAxios.get(`${API}/api/admin/get-report-posts?take=5&skip=${skip}`)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error(error);
    });
};

export async function deleteReportPost(postId: number) {
  
  return await customAxios
    .delete(`${API}/api/admin/delete-report-post`, {
      data: {
        postId,
      },
    })
    .then(res => {
      return res.data
    })
    .catch(error => {
      console.error(error);
    });
};

export async function ignorReportPost(data: any) {
  return await customAxios
    .post(`${API}/api/admin/skip-report-post`, data)
    .then(res => {
      return res.data
    })
    .catch(error => {
      console.error(error);
    });
};

export async function getReportsReasons(id: number) {
  return await customAxios.get(`${API}/api/admin/get-reasons-for-posts?postId=${id}`)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.error(error);
    });
};

export async function getPostInterestsAPI(postId: number) {
  return await customAxios.get(`${API}/api/posts/get-post-interests?postId=${postId}`);
}

export async function getPostSharedAPI(postId: number) {
  return await customAxios.get(`${API}/api/posts/get-post-shared?postId=${postId}`);
}

export async function getPostReactionsAPI(postId: number) {
  return await customAxios.get(`${API}/api/posts/get-post-reactions?postId=${postId}`);
}

// ===== Translation ======================== 

export async function translateText(data: any) {
  return await axios.post('https://api.nlpcloud.io/v1/gpu/nllb-200-3-3b/translation',
    data,
    {
      headers: {
        "Authorization": 'Token c7b0c018b3a13962f497e27d008fe5ddc9048d21',
        "Content-Type": 'application/json'
      }
    }
  )
};
