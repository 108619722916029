import React, { useEffect, useState } from 'react';
import './CustomChip.scss';

interface Props {
  mobile?: boolean,
  label: string;
  src: string;
  id: string;
  isArchived: boolean;
  defaultChecked?: boolean;
  onClick?: (checked?: boolean) => void;
  // setSelectedChips?: (cb: (chips: SelectedChip[]) => SelectedChip[]) => void;
    setSelectedChips?: any;
}

const CustomChip = ({ mobile, label, defaultChecked, src, id, isArchived, onClick = () => { }, setSelectedChips = () => { } }: Props) => {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);


  const chipAction = () => {
    if (!checked) {
      setSelectedChips((prev: SelectedChip[]) => {
        const newArr = [...prev];
        newArr.push({ title: label, url: src, id, isArchived });
        return newArr;
      });
    } else {
      setSelectedChips((prev: SelectedChip[]) => {
        const newArr = [...prev];
        newArr.splice(newArr.findIndex(item => item.title === label), 1);
        return newArr;
      });
    }
    onClick(!checked);
    setChecked(!checked);
  };

  return (
    <div
      className={`chip ${checked ? 'chip--checked' : ''}`}
      onClick={chipAction}
      style={{
        width: mobile ? '100%' : '',
      }}
    >
      {src && <img src={src} alt="chip" />}
      <span>{label}</span>
    </div >
  );
};

export default CustomChip;
