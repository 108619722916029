import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { AppDispatch, RootState } from '../../../redux/store';
import { setSendPassword } from '../../../redux/slices/authSlice';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import MainButton from '../../../components/shared/Buttons/MainButton/MainButton';
import AuthLogo from '../AuthLogo/AuthLogo';
import './ForgotPasswordPage.scss';

const ForgotPasswordPage: FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  // const [sendPassword, setSendPassword] = useState(false);

  const { sendPassword } = useSelector((state: RootState) => state.auth);

  const handleClick = () => {
    navigate('/sign-in');
    dispatch(setSendPassword(false));
  };

  return (
    <>
      <AuthContainer step={1} language={true}>
        {sendPassword ? (
          <div className="authContainerWrapper-forgotPassword">
            <div className="sendPasswordWrapper">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 16,
                }}
              >
                <div className="checkEmailWrapper">
                  <span className="checkEmail">{formatMessage({ id: 'checkEmail' })}</span>
                </div>
                <div
                  className="forgotPasswordText"
                  style={{
                    textAlign: 'center',
                    marginBottom: 16,
                  }}
                >
                  {formatMessage({ id: 'resetPasswordMessage' })} <br />
                  <b
                    style={{
                      color: 'rgba(54, 54, 54, 1)',
                      textAlign: 'center',
                    }}
                  >
                    {resetPasswordEmail}
                  </b>
                </div>
                <MainButton text={formatMessage({ id: 'ok' })} type="button" onClick={handleClick} />
              </div>
            </div>
          </div>
        ) : (
          <div className="authContainerWrapper-forgotPassword">
            <AuthLogo />
            <div className="authForm">
              <div className="forgotPasswordFormWrapper">
                <ForgotPasswordForm
                  // setSendPassword={setSendPassword}
                  setResetPasswordEmail={setResetPasswordEmail}
                  resetPasswordEmail={resetPasswordEmail}
                />
                <div className="forgotPasswordLinkWrapper">
                  <span>{formatMessage({ id: 'rememberPassword' })}</span>{' '}
                  <b className="forgotPasswordLink">
                    <Link to="/sign-in">{formatMessage({ id: 'login' })}</Link>
                  </b>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthContainer>
    </>
  );
};

export default ForgotPasswordPage;
