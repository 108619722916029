import { customAxios } from '../api/api';
import { API } from '../api/api';

const Posts_API = `${API}/api/posts/get-post-by-filters`; //get
const Profile_API = `${API}/api/groups/get-group-or-interest-info`; //get
const Follow_API = `${API}/api/user/follow-group-or-interest`; //post
const TagsAndInterests_API = `${API}/api/admin/edit-tags-n-interests`; //put

export async function getPostsWithGroup(data: any) {
  return await customAxios.get(Posts_API, { params: data });
}

export async function getGroupProfile(data: any) {
  return await customAxios.get(Profile_API, { params: data });
}

export async function followGroup(data: any){
  return await customAxios.post(Follow_API, data);
}

export async function EditTagsAndInterests(data: any){
  return await customAxios.put(TagsAndInterests_API, data);
}