import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone';
import { bookmarkPost, removeBookmarkedPost } from '../../../../redux/slices/postsSlice';
import { AppDispatch, RootState } from '../../../../redux/store';
import './Bookmark.scss'

type Props = {
  postId: number;
  type: string;
  defaultState?: boolean
}

export const Bookmark: React.FC<Props> = ({ postId, type, defaultState }) => {
  const [bookmarkIsActive, setBookmarkIsActive] = useState(defaultState);
  const [searchParams] = useSearchParams() || '';
  const takePosts = searchParams.get('take') || '';
  const { id } = useSelector((state: RootState) => state.profile);
  const { bookmarkedPostsFeed } = useSelector((state: RootState) => state.posts);

  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  const handleBookmarkedPost = () => {
    setBookmarkIsActive(!bookmarkIsActive);
    dispatch(bookmarkPost({ postId, isBookmarked: !bookmarkIsActive }));
    dispatch(removeBookmarkedPost(postId));
  }

  useEffect(() => {
    if (type === 'saved') {
      setBookmarkIsActive(true);
    } else if (type !== 'home'){
      setBookmarkIsActive(bookmarkedPostsFeed
        ?.some((post: any) => post.userId.id === id && post.postId.id === postId));
    }
  }, [bookmarkedPostsFeed, type, id, postId]);

  return (
    <div
      className="post__header-more-btn-container"
      onClick={() => handleBookmarkedPost()}
    >
      {bookmarkIsActive
        ? (
          <Tooltip
            arrow
            placement="top"
            leaveDelay={500}
            title={formatMessage({ id: 'postSaved' })}
          >
            <IconButton sx={{ color: '#620C6D' }}>
              <BookmarkTwoToneIcon className="post__header-more-btn" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            arrow
            placement="top"
            enterDelay={500}
            leaveDelay={500}
            title={formatMessage({ id: 'savePost' })}
          >
            <IconButton color="secondary">
              <BookmarkBorderIcon className="post__header-more-btn" />
            </IconButton>
          </Tooltip>
        )}
    </div>
  )
}
